import React, { useState, useEffect } from "react";
import {UpgradeView} from '../upgrade-demo/UpgradeView';
import {
    MainWrapper,
    UpgradePopWrapper,
    UpgradePopWrap,
    UpgradePopHeader,
    HowCloseBtn,
    UpgradePopBody,
    UpgradePopColWrap,
    UpgradePopCol,
    UpgradePopCard,
    UpgradeCardHeader,
    UpgradeCardBody,
    UpgradeCardRow,
    UpgradeCol,
    UpgradeCard,
    UpgradeCardFooter,
} from "./roadmapstyles";
import {
    closeIcon, dataIcon, rankIcon, insightIcon, insertIcon,
    methodIcon, deliverIcon, goalIcon , asmtIcon, startegyIcon,lossIcon,lossCalIcon,
    plantDataInputIcon, dataAggregationIcon, dataCalibrationIcon,
} from "./roadmapsvg";

export const RoadmapAsmtUpgradeView = (props) => {
    const {openPricePop, setOpenPricePop} = props;
    const [showUpgradePlant,setShowUpgradePlant] = useState(false);
    const Data = [
        {
            main_img: asmtIcon ,
            main_title: `IR4.0 Technology Assessment`,
            price: `€ 1500`,
            inside_card: [
                {
                    img: dataIcon,
                    title: `Data`,
                    pop_txt: `3500 data points acquired through SmarterChains Assessment.`,
                },
                {
                    img: rankIcon,
                    title: `Ranking`,
                    pop_txt: `SmarterChains ranks plant’s capabilities across 10 Industry 4.0 Dimensions.`,
                },
                {
                    img: insightIcon,
                    title: `Insights`,
                    pop_txt: `For each Industry 4.0 Dimension, we provide data-driven Insights that unveil strength & opportunity areas.`,
                },
                {
                    img: insertIcon,
                    title: `Index`,
                    pop_txt: `SmarterChains Index is derived from the 10 Industry 4.0 Dimensions.`,
                },
            ],
        },
        
        {
            main_img: startegyIcon ,
            main_title: `IR4.0 Strategies`,
            price: `€ 1500`,
            inside_card: [
                {
                    img: goalIcon,
                    title: `Goal`,
                    pop_txt: `Identify key loss opportunities and define solutions to drive efficiency improvements`,
                },
                {
                    img: methodIcon,
                    title: `Methodology`,
                    pop_txt: `Loss and financial analysis, solution selection and cost-benefit analysis of the selected solutions `,
                },
                {
                    img: deliverIcon,
                    title: `Deliverables`,
                    pop_txt: `Industry 4.0 Roadmap with technology and organizational solutions, their cost-benefit analysis and 3 vendors for each solution`,
                },
            ],
        },
    ]
    
    const handleUpgradeButton = ()=>{
        setShowUpgradePlant(true);
    }
    return (
        <>
            <MainWrapper data-component="MainWrapper">
            {openPricePop &&
                <UpgradePopWrapper data-component="UpgradePopWrapper">
                    <UpgradePopWrap data-component="UpgradePopWrap">
                        <UpgradePopHeader data-component="UpgradePopHeader">
                            <span className="head-txt">Upgrade Now</span>
                            <div className="upgrade-right-head">
                                <HowCloseBtn data-component="HowCloseBtn" onClick={() => setOpenPricePop(false)}>
                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                </HowCloseBtn>
                            </div>
                        </UpgradePopHeader>
                        <UpgradePopBody data-component="UpgradePopBody">
                            <p>
                                Hey there! Upgrade and set structured steps towards digital transformation by launching your Industry 4.0 Roadmap
                            </p>
                            <UpgradePopColWrap data-component="UpgradePopColWrap">
                                {Data.map((item,index)=>(
                                    <UpgradePopCol data-component="UpgradePopCol" key={index}>
                                        <UpgradePopCard data-component="UpgradePopCard">
                                            <UpgradeCardHeader data-component="UpgradeCardHeader">
                                                <i className="head-icon" dangerouslySetInnerHTML={{ __html: item.main_img }}></i>
                                                <span>{item.main_title}</span>
                                            </UpgradeCardHeader>
                                            <UpgradeCardBody data-component="UpgradeCardBody">
                                                <UpgradeCardRow data-component="UpgradeCardRow">
                                                    {item?.inside_card?.map((card_item,cardIndex)=>(    
                                                        <UpgradeCol data-component="UpgradeCol">
                                                            <UpgradeCard data-component="UpgradeCard" key={cardIndex}>
                                                                <i className="card-icon" dangerouslySetInnerHTML={{ __html: card_item.img }}></i>  
                                                                <span className="card-txt">{card_item.title}</span> 
                                                                <div className="pop-tooltip">
                                                                    <p className="pop-txt">{card_item.pop_txt}</p>
                                                                </div>
                                                            </UpgradeCard>
                                                    </UpgradeCol>
                                                    ))}
                                                </UpgradeCardRow>
                                            </UpgradeCardBody>
                                        </UpgradePopCard>
                                    </UpgradePopCol>
                                ))}
                            </UpgradePopColWrap>
                        </UpgradePopBody>
                        <UpgradeCardFooter data-component="UpgradeCardFooter">
                            <button className="footer-cart-btn" onClick={()=>handleUpgradeButton()}>
                                    <span className="foot-text">Upgrade</span>
                                    <span className="price">€ 3000</span>
                            </button>
                        </UpgradeCardFooter>
                    </UpgradePopWrap>
                </UpgradePopWrapper>
            }  
            {showUpgradePlant && <UpgradeView openPop={showUpgradePlant} setOpenPop={setShowUpgradePlant}/>}
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

