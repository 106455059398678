/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * .scss for ===> styling css file for this page.
  * MLWalkthroughQuesView ===> walkthrough component
  
**/

import React, { useEffect } from "react";
import "./maintenance-losses-page.scss";
import "./maintenance-losses-circle.scss";
import $ from "jquery";
import { useState } from 'react';
import { MLWalkthroughQuesView } from './maintenance-losses-walkthrough'
//MaintenanceLossesPageView component start
export const MaintenanceLossesPageView = (props) => {
  //defining variables here
  const [activeSlide, setActiveSlide] = useState(1);
  const [sideSlide, setSideSlide] = useState(false);
  const [isRightPopup, setIsRightPopup] = useState(null);
  const [sideSlideIcon, setSideSlideIcon] = useState("fade info");
  const addStep2 = () => {
    let val = sideSlideIcon;
    if (val == 'slide-icon-change info')
      setSideSlideIcon(' none info')
    else {
      setSideSlideIcon('slide-icon-change info')
    }
  }
  const [shown, setShown] = useState(false);
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.closest('.conected_to_popup')) {
        setShown(false);
      }
    });
  });

  const [APDList, setAPDList] = useState([
    {
      name: `Gathering & analyzing machine condition data (i.e. vibration analysis)`,
      editable: false
    },
    {
      name: `Cleaning, Inspecting, Lubricating assets`,
      editable: false
    },
    {
      name: `Repairing and testing equipment (i.e. PM events)`,
      editable: false
    },
    {
      name: `Initiating & reporting work orders`,
      editable: false
    },
    {
      name: `Planning & scheduling maintenance activities`,
      editable: false
    },
    {
      name: `Accessing work instructions`,
      editable: false
    },
    {
      name: `Writing standards and documenting procedures (SOPs, 5W1H)`,
      editable: false
    },
    {
      name: `Receiving, managing & controlling spare part inventory`,
      editable: false
    },
    {
      name: `Procurement of spare parts`,
      editable: false
    },
    {
      name: `Daily meetings, breaks & KPI review`,
      editable: false
    },

  ]);
  //function to handle right sectio
  const HandleRightSec = (val) => {
    if (isRightPopup === val) {
      setIsRightPopup(null);
    } else {
      setIsRightPopup(val);
    }
  }
  //function for delete button
  const HandleDelBtnEvent = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(item);
    setIsRemovePopup(true);
  }

  useEffect(() => {
    // Get the modal
    var modal = document.getElementById("myModal");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    img.onclick = function (e) {
      modal.style.display = "block";
      modalImg.src = e.target.src;
      //captionText.innerHTML = .alt; 
    }

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    }

    // When the user clicks on div, open the popup
    function myFunction() {
      var popup = document.getElementById("myPopup");
      popup.classList.toggle("show");
    }
    // When the user scroll on div, open the shadow
    $('.training-content').scroll(
      () => {
        var scroll = $('.training-content').scrollTop();
        if (scroll > 0) {
          $(".outside_card").addClass("active");
        }
        else {
          $(".outside_card").removeClass("active");
        }
      }
    );




    $(document).on('change', '.ques-num', function (event) {
      var inputval = parseInt(this.value);
      if ($(this).hasClass('percentage1') && inputval > 100) {
        inputval = 100;
        $(this).val(inputval);
      }
      var qtype = $(this).attr('data-qtype');
      if (qtype == 1) {
      } else {
        if (inputval) {
          $(this).val(inputval + '%');
        } else if (inputval == 0) {
          $(this).val(inputval + '%');
        }
      }


    })


  }, [])
  return (
    <>
      {/* main wrapper has four sections
          maintenance-losses-top
          maintenance-losses-top-two
          maintenance-losses-bottom-group
      */}
      <section className="maintenance-losses prod-1">
         {/* this section has one row */}
        <div className="maintenance-losses-top">
          {/* row has been divided into three parts
              maintenance-losses-lft-col
              maintenance-losses-mid-col
              la-ques-rgt-col
          */}
          <div class="maintenance-losses-row">
            {/* this section has 
                back button
                page title
                category text
            */}
            <div className="maintenance-losses-lft-col">
              <h1 className="h1-btn">
                <a className="back-link" href="/">
                  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><rect x={2} y={2} width={24} height={24} rx={2} fill="white" /></g><path d="M19 7.4303L17.4833 6L9 14L17.4833 22L19 20.5697L12.0334 14L19 7.4303Z" fill="#7D868C" /><defs><filter id="filter0_d" x={0} y={0} width={28} height={28} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity={0} result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset /><feGaussianBlur stdDeviation={1} /><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" /><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" /><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" /></filter></defs></svg>
                </a>
                <span>Maintenance Losses</span>
              </h1>
              <h4 className="h4-dull">Key Plant Functions</h4>
              <div className="value_of_progress">
                <p>Progress:</p>
                <div className="bar-wrapper">
                  <span>0%</span>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: 0 }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                </div>
              </div>
            </div>
             {/* this section has assigned user list */}
            <div className="maintenance-losses-mid-col">
              <div className="Assign">
                <div className="conected_to_popup">
                  <div className="popup"  >
                    {
                      <button onClick={() => setShown(!shown)}>
                        <div className="upward man_assign" >
                          <img src={require("../../assets/img/fte-breakdown/man.svg").default} alt="digital_maturity" />
                          <ul>
                            <li className="grey"> AB </li>
                            <li className="blue"> BC </li>
                            <li className="yellow"> JW </li>
                          </ul>

                        </div>
                      </button>
                    }
                    {
                      shown ? <div className="assigned-user-popup1" >
                        <span className="tooltip" />
                        <div className="assignd-user-popup-wrap">
                          <div className="pop-wrap">
                            <div className="img-box">
                              <ul><li className="grey"> AB </li></ul>
                            </div>
                            <div className="user-name">
                              <span className="name">Anette Black</span>
                              <span className="designation">Team Leader</span>
                            </div>
                          </div>
                          <div className="pop-wrap">
                            <div className="img-box">
                              <ul><li className="blue"> BC </li></ul>
                            </div>
                            <div className="user-name">
                              <span className="name">Bessie Cooper</span>
                              <span className="designation">Plant Engineer</span>
                            </div>
                          </div>
                          <div className="pop-wrap">
                            <div className="img-box">
                              <ul><li className="yellow"> JW </li></ul>
                            </div>
                            <div className="user-name">
                              <span className="name">Jenny Wilson</span>
                              <span className="designation">Chief Engineer</span>
                            </div>
                          </div>
                        </div>
                      </div> : null
                    }
                  </div>
                </div>
                <div className="direction_arrows">
                  <div className="upward">
                    <img src={require("../../assets/img/manufacturing-cost/upward.svg").default} alt="digital_maturity" />
                    <p>Upload Excel file</p>
                  </div>
                  <div className="downword">
                    <img src={require("../../assets/img/manufacturing-cost/downword.svg").default} alt="digital_maturity" />
                    <p>Download to Excel</p>
                  </div>
                </div>
              </div>
            </div>
            {/* this section has
                popup
                unlock button/ Data Submitted Button
            */}
            <div className="maintenance-losses-rgt-col">
              <div className="top_close_box">
                <div className="brain_idea">
                  <img id="myImg" className="notification-img" src={require("../../assets/img/maintenance_losses/bulb.svg").default} alt="digital_maturity" />
                  <div id="myModal" className="modal" style={{ display: 'none' }}>
                    <div className="inside_popup">
                      <img id="img01" className="modal-content" src={require("../../assets/img/maintenance_losses/bulb.svg").default} alt="digital_maturity" />
                      <p>Please provide the breakdown of the key activities taking place by the Maintenance or Engineering function employees.</p><p>
                        <span className="close"><img src={require("../../assets/img/maintenance_losses/close.svg").default} alt="digital_maturity" /></span>
                      </p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* this section has two subsections
            outside_card work_space
            empty
        */} 
        <div className="maintenance-losses-top-two">
           {/* wrapper has two parts 
              total_maintenance
              what_percentage
          */}
          <div className="work_space">
            {/* this section has
                text
                question
            */}
            <div className="total_maintenance ">
              <div className="text-area">Total FTEs working on Maintenance: <span> 25</span> FTE</div>
              <div className="what_per text-area">What % of your Maintenance FTEs time is spent on:</div>
            </div>
            {/* this section has percentage circle */}
            <div className="what_percentage">
              <div className="total"> Total </div>
              <SvgCircle value={10} />
            </div>
          </div>
          <div className="empty" />
        </div>
        {/* inside this section section-wrapper (inside_cards)*/}
        <div className="maintenance-losses-mid-wrapper">
           {/* this section has 
                side-slid-icon-button
                percentage
            */}
          <div className="inside_cards">
            {APDList.map((items, i) => {
              return (<div className="notice">
                <button title={items.name} className={`side-slid-icon-button ${isRightPopup === (i + 1) && 'active'}`} onClick={() => HandleRightSec(i + 1)}>
                  <div className="info">
                    <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                    </svg>
                  </div>
                  <div className="add-subcat-wrap">
                    <div className={`input-wrap ${!items.editable && 'w-100'}`}><p className="text-area">{items.name}</p></div>
                    {items.editable && <button className="del-btn"
                      onClick={(e) => {

                        HandleDelBtnEvent(e, {
                          name: items.name,
                          index: i
                        });
                      }}>
                      <img src={require('../../assets/img/operator-effort-loss/delete-icon.svg').default} alt="Delete" />
                    </button>}
                  </div>

                </button>
                <div className="percentage"><input type="text" name="percentage" placeholder="%" className="ques-num percentage1" defaultValue="%" /></div>
              </div>)
            }
            )
            }

          </div>
        </div>
        {/* this section has buttons */}
        <div className="maintenance-losses-bottom-group">
          <div className="bottom_fade" />
          <div className="add_new"> + Add new subcategory </div>
          <button>
            <div className="submit">Submit</div>
          </button>
        </div>
      </section>
      {/* right sidebar popup starts
          popup has three parts   
          top-part
          content
          submit
      */}
      <div className={`maintenance-losses-activities ${isRightPopup && 'active'}`}>
        <div className="top-part">
          <img src={require('../../assets/img/maintenance_losses/double-chat.svg').default} alt="chat" />
          <div className="close"><button onClick={() => HandleRightSec(null)}><img src={require('../../assets/img/maintenance_losses/close.svg').default} alt="chat" /></button></div>
        </div>
        <span>Planned maintenance activities</span>
        <p>The % of time a production process or production line is not available for production due to scheduled maintenance activities.</p>
        <p className="leave">Leave a Comment</p>
        <div className="leave-box">In case needed, please provide additional information about your data input (assumptions, clarifications)</div>
        <div className="submit">
          <button>Submit </button>
        </div>
      </div>
      {/* Walkthrough component call */}
      {activeSlide &&
        <MLWalkthroughQuesView activeSlide={activeSlide} setActiveSlide={setActiveSlide}  {...props} />
      }
    </>
  );
};
// svgcircle component which is used for progress circle
const SvgCircle = (props) => {
  const { value } = props;
  return (
    <>
      <svg viewBox="0 0 36 36">
        <text
          className="percent__text"
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="10"
        >
          {value}
          <tspan fontSize="5">%</tspan>
        </text>
        <path
          className="circle-bg"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke="#E6E6E6"
          strokeWidth="3.5"
          fill="none"
        ></path>
        <path
          className="circle"
          strokeDasharray={`${value}, 100`}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={
            value < 80 && value > 0
              ? "#BDBDBD"
              : value > 79
                ? "#40D30D"
                : "transparent"
          }
          strokeWidth="3.5"
          fill="none"
        ></path>
      </svg>
      {props.tooltipText && <div className="svg-tooltip">
        {props.tooltipText}
      </div>}
    </>
  );
};
