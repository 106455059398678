/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * PrepareHomeView ===> custom view component

**/

import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { QuickStart } from "../quickstart/quickstart-ctrl";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { PrepareHomeView } from './home-view';
import { PrepareHomeViewSiri } from './home-view-siri';
import serviceProvider from '../../services/axios'
//PrepareHomeCtrl component
export const PrepareHomeCtrl = (props) => {
    const [results, setResults] = useState([]);
    const [resultstask, setResultsTask] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    const [emailfilter, setEmailfilter] = useState(localStorage.getItem("selectedEmail") || "");
    const [filterPlants, setFilterPlants] = useState(localStorage.getItem("selectedPlant") || "");
    const [crfilter, setCrfilter] = useState(localStorage.getItem("selectedCR") || "");
    const [locationfilter, setLocationfilter] = useState(localStorage.getItem("selectedCity") || "");
    const [locationCountryfilter, setLocationCountryfilter] = useState(localStorage.getItem("selectedCountry") || "");
    const [validatefilter, setValidatefilter] = useState(localStorage.getItem("selectedStatus") || "");
    const [filterPlantsTask, setFilterPlantsTask] = useState("");
    const [onrefresh, setOnrefresh] = useState(false);
    const [setuppage, setsetuppage] = useState(0);
    const user = JSON.parse(localStorage.getItem("userData"));
    const login = localStorage.getItem("isLoggedIn");
    const usid = user ? user.uid : 0;
    const progress = user ? user.progress : 0;
    const plant_slug = user ? user.plant_slug : 0;
    const gid = user ? user.group_id : 0;
    if (!login) {
        return <Redirect to="/sign-in" />;
    }
    if (progress<100 && (gid == 14 || gid == 2)) {
         return <Redirect to={`/assessment/${plant_slug}`} />;
    }
    useEffect(() => {
        props.handleExpandFal();
        const search = async () => {
            const apiUrl = `/home?param=homedata&filterPlants=${filterPlants}&crf=${crfilter}&lf=${locationfilter}&countryfilter=${locationCountryfilter}&vlf=${validatefilter}&emailfilter=${emailfilter}&uid=${usid}`;
            const { data } = await serviceProvider.get(apiUrl, true, {});
            setDefaultData(data);
            setResults(data);
        };
        search();
    }, [filterPlants,crfilter,validatefilter,locationfilter,emailfilter, locationCountryfilter,onrefresh,setuppage]);
    useEffect(() => {
        const search = async () => {
            const apiUrl = `/Taskcentershome?param=task&filterPlants=${filterPlantsTask}&uid=${usid}&gid=${gid}`;
            const { data } = await serviceProvider.get(apiUrl, true, {});
            setResultsTask(data);
        };
        search();
    }, [filterPlantsTask, onrefresh]);


    return (
        <>
            {/* link css */}
            <Helmet>
                <title>Home | SmarterChains</title>
                <meta name="description" content="Home" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="stylesheet" href={require('./home.scss').require} />
            </Helmet>
            <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
            {/* <PrepareHomeView {...props}/>*/}
            {/* custom view component */}

            {gid == 16 ?
                <PrepareHomeViewSiri homeData={results} resultstask={resultstask} defaultData={defaultData} onFilter={setFilterPlants} onemailfilter={setEmailfilter} oncrFilter={setCrfilter} onvalidateFilter={setValidatefilter} onlocationFilter={setLocationfilter} onlocationcountryFilter={setLocationCountryfilter} onrefresh={onrefresh} setsetuppage={setsetuppage} setuppage={setuppage} setOnrefresh={setOnrefresh} onFilterPlantsTask={setFilterPlantsTask}  {...props} />
                :
                <PrepareHomeView homeData={results} resultstask={resultstask} defaultData={defaultData} onFilter={setFilterPlants} onrefresh={onrefresh} setOnrefresh={setOnrefresh} onFilterPlantsTask={setFilterPlantsTask}  {...props} />
            }
        </>
    )
}