import styled from "styled-components";


export const MainWrapper = styled.div`
        padding: 3rem 3rem 5.6rem 3rem;
        max-width: 100%; 
        color: #4D4D4F;
        @media(max-width: 740px){
            padding: 3rem 1rem;
        }
        *{
            line-height:150%;
        }
        p{
            color: #4D4D4F;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 4rem;
            max-width: 95.7rem;
        }
`;

export const H1 = styled.h1`
    color:#4D4D4F;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 5rem;
    margin-bottom: 1rem;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; 
    margin: 0 -1.2rem;
    width: 90%;
    @media(max-width: 740px){
        margin: 0;
        width: 100%;
        padding-bottom:100px;
    }
`
export const InfoTooltip = styled.div`
    position:absolute;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: -0.028rem;
    background:#fff;
    z-index:1;
    width:30.8rem;
    top: 0;
    left: 3rem;
    box-shadow: 0px 0px 0.8rem rgba(21, 27, 38, 0.12);
    padding:1.6rem;
    border-radius:0.8rem;
    visibility:hidden;
    opacity:0;
    transition: unset;
    transform: translateY(-100%);
    padding-bottom: 1rem;
    &.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    .tooltip-text{
        margin-bottom: 0;
        max-height: 20rem;
        padding-right: 0.5rem;
        overflow-y: auto;
    }
    @media(max-width:740px){
        left: auto;
        right: 0;
        top: 3rem;
        max-width: 280px;
    }
`;
export const Column = styled.div`
    border-bottom: 0.1rem solid #F0F0F0;
    padding: 1.6rem 1.2rem;
    width: 40%;
    @media(max-width: 740px){
        width: 100%;
    }
    @media(max-width: 1200px){
        &:nth-child(2n){
            ${InfoTooltip}{
                left: auto;
                right: 0;
                top: 3rem; 
                @media(max-width: 740px){
                    left: auto;
                    right: 0;
                    top: 3rem;
                    max-width: 280px;
                }           
            }
        }
    }
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12),
    &:nth-child(13),
    &:nth-child(14){
        ${InfoTooltip}{
            transform: translateY(-100%);
            top: 20px;
        }
    }
    
`
export const P = styled.div`
    position:relative;
    display: flex;
    align-items: center;
    label{
        cursor: pointer;
        display: inline-flex;
    }
    .info-wrapper{
        position: relative;
        top: 1px;
        @media(max-width:700px){
            position: unset;
        }
        &.active{
            overflow: visible;
        }
    }
    span{
        color: #4D4D4F;
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.028rem;
    }
    .info-icon-i{
        display:inline-flex;
        top: 0;
        margin-left:0.4rem;
        cursor:pointer;
        position:relative;
        svg{
            width:1.6rem;
            *{
                transition:0.25s;
            }
            .circle{
                fill:#fff;
            }
        }
        &:hover,&.active{
            svg{
                .circle{
                    fill:#118acb;
                }
                .line-i{
                    stroke:#fff;
                }
            }
        }
    }
    input{
        height: 1.6rem;
        width: 1.6rem;
        border-color: #7D868C;
        display: inline-flex;
        margin-right: 1.2rem;

    }
`;



export const KPICard = styled.div``

export const KPICardTitle = styled.h3`
    color: #4D4D4F;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.5rem;
`

export const KPICardText = styled.p`
    color: #4D4D4F;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;
`
export const SubmitButton = styled.button`
    display:inline-flex;
    align-items:center;
    justify-content:center;
    color: #FFF;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    position:fixed;
    bottom: 1rem;
    right:3rem;
    min-width:29.7rem;
    min-height:6rem;
    padding:0.5rem;
    background:${({ disabled }) => disabled ? '#CBCFD2' : '#118ACB'};
    cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    border-radius:0.5rem;
    z-index:10;
    @media(max-width:768px){
        bottom: 0px;
        width: calc(100% - 30px);
        left: 50%;
        transform: translateX(-50%);
    }
`
export const AfterSubmitButton = styled.button`
    min-width: 30.7rem;
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
    justify-content: center;
    background: #E5F5F1;
    min-height: 6rem;
    position:fixed;
    bottom: 1rem;
    gap: 1.2rem;
    right:3rem;
    i{
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    span{
        color: #43A047;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: normal;
    }
    @media(max-width: 768px){
        width: calc(100% - 30px);
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        bottom: 0;
    }
`

export const ErrorMsgDiv=styled.div`
       position:fixed;
       top:100px;
       max-width:100%;
       width:280px;
       height:80px;
       left:50%;
       top:50%;
       box-shadow: 0rem 0.168rem 0.672rem rgba(21, 27, 38, 0.1);
       transform:translate(-50%,-50%);
       opacity:${({active})=>active?'1':'0'};
       visibility:${({active})=>active?'visible':'hidden'};
       transition:0.25s ease-in;
       z-index:9999;
       background:#fff;
       border-radius:5px;
       padding:15px 20px;
       border:1px solid currentColor;
       color:${({green})=> green? '#4caf50' : '#ff8383'};
       font-size:14px;
       font-weight:500;
       display:flex;
       text-align:center;
       align-items:center;
       gap:10px;
       p{
        margin-bottom:0px;
        flex:1;
        color:inherit;
        font:inherit;
        font-weight:inherit;
       }
`;