/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * serviceProvider for ===> working with api

**/

import React, { useState,useEffect } from "react";
import { RoadmapTabView } from "./RoadmapTabView";
import { useParams,Redirect } from 'react-router-dom';
import serviceProvider from '../../services/axios';
//PurchaseController start
export const RoadmapTabCtrl = (props) => {
		const {slug1,slug2,section } = useParams();
		const [onTab, setOnTab] = useState(0);
		const [results, setResults] = useState(false);
		const user = JSON.parse(localStorage.getItem("userData"));
		const login = localStorage.getItem("isLoggedIn");
		const uid = user ? user.uid : 0;
		const progress = user ? user.progress : 0;
		const gid = user ? user.group_id : 0;
		if(!login) {
			return <Redirect to="/sign-in" />;
		}
		if(progress<100 && (gid == 14 || gid == 2)) {
			return <Redirect to={`/assessment/${plant_slug}`} />;
		}

		useEffect(() => {
			const search = async () => {
				const apiUrl = `/roadmap?param=results&uid=${uid}&slug=${slug1}&sid=${slug2}`;
				const { data } = await serviceProvider.get(apiUrl, true, {});
				setResults(data);
			};
			search();
		}, []);
		
		if(results) {
			return (
			  <>
				<RoadmapTabView dataResults={results} gid={gid} onTab={Number(section) || onTab} {...props}/>
			  </>
			);
		}else{
				return (
					<>
					<div></div>
					</>
				);
		}     
};
