/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * CourseDetailView ===> custom view component
  * .scss for ===> styling css file for this page.
  * CourseOverviewSingleView ===> custom view component

**/
import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router'
import serviceProvider from '../../services/axios'
import { CourseOverviewSingleView } from "./course-overview-single-view";
import { CourseDetailView } from "./course-detail-view";
import "./course-detail.scss";
import { WalkthroughView } from "./walkthrough-view";
import "./course-overview.scss";
//CourseOverviewSingleControler start
export const CourseOverviewSingleControler = (props) => {
	//defining variables here
	const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	const urlElements = window.location.href.split('/')
	const tid = (urlElements[4]);
	const sid = getLastItem(window.location.pathname);
	const [results, setResults] = useState([]);
	const [completed, setCompleted] = useState(sid);
	const [allCompleted, setAllCompleted] = useState(0);
	const [submitQues, setSubmitQues] = useState(0);
	const [submitAns, setSubmitAns] = useState(0);
	const [onTab, setOnTab] = useState(0);
	const [showVideo, setShowVideo] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const [hashvalue, setHashvalue] = useState(window.location.hash);
	const [resultsquiz, setResultsquiz] = useState([]);
	const [sortBy, setSortBy] = useState(0);
	const [filterBy, setFilterBy] = useState(0);
	const [searchBy, setSearchBy] = useState('');
	const [catBy, setCatBy] = useState('');
	useEffect(() => {
		const search = async () => {
			const tid = window.location.pathname.split('/')[2];
			const apiUrl = `/item?param=trainingquiz&slug=${tid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResultsquiz(data);
		};
		search();
	}, [tid, completed, setRedirect, allCompleted, submitQues, submitAns, sortBy, filterBy, searchBy, catBy]);
	useEffect(() => {

		if (hashvalue.includes("#q&a")) {
			setOnTab(1);
			const str = hashvalue.split('&');
			setHashvalue(str[2]);
		}

		props.handleExpandFal();
	}, []);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		setRedirect(false);
		const search = async () => {
			const apiUrl = `/Itemdetails?param=mytraining&slug=${tid}&uid=${usid}&current=${completed}&sortby=${sortBy}&filterby=${filterBy}&catby=${catBy}&text=${searchBy}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResults(data);
		};
		search();
	}, [tid, completed, setRedirect, allCompleted, submitQues, submitAns, sortBy, filterBy, searchBy, catBy]);

	if (redirect) {
		return <Redirect to={redirect} />;
	}

	if (!results.length) {
		return null;
	
	} else if (results[0].block == 1) {
		return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
	} else if (results[0].has_paid == 0) {
		return <CourseDetailView showVideo={showVideo} courseData={results} {...props} />;		
	} else {

		return (

			<>
				<CourseOverviewSingleView
					quizData={resultsquiz}
					courseData={results}
					onTab={onTab}
					onComplete={setCompleted}
					onAllComplete={setAllCompleted}
					onSubmitQues={setSubmitQues}
					onSubmitAns={setSubmitAns}
					submitAns={submitAns}
					onSortBy={setSortBy}
					onFilterBy={setFilterBy}
					onSearchOpen={setSearchBy}
					onRedirect={setRedirect}
					sid={completed}
					tid={tid}
					sortb={sortBy}
					filterb={filterBy}
					catb={catBy}
					onCatBy={setCatBy}
					textb={searchBy}
					hashvalue={hashvalue}
					setHashvalue={setHashvalue}
					{...props}
				/>
			</>
		);
	}
};
