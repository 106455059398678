/** imported components
 
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api

**/


import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import serviceProvider from '../../services/axios';
import { Helmet } from "react-helmet";

// FTE Walkthrough Component start
export const FTEWalkthroughQuesView = (props) => {
  //Defining variables here
  const { activeSlide, setActiveSlide } = props;
  const users = JSON.parse(localStorage.getItem("userData"));
  const group_id = users ? users.group_id:''; /*if 14 it's simple user else 2 or 11 for admin*/
  const UserName = users ? users.name : "First Name";
  const uid = users ? users.uid : "";
  // Function to close slider
  const handleClose = () => {
    var close = document.querySelector('.div-z99');
    var findClass = document.querySelector('.fte-breakdown').contains(close);
    var closePop = document.querySelector('.right-sidebar .close-btn');
    if (findClass == true) {
      close.classList.remove('div-z99');
    }
    serviceProvider
      .post(
        "/home?type=setlineEfftour",
        JSON.stringify({ uid: uid, fte: 'fte' }),
        true
      )
      .then((response) => {
        setActiveSlide(null);
        if (closePop != null) {
          closePop.click();
        }
      });

  }
  // Function to navigate slider from one to next
  const HandleSlider = (val) => {
    setActiveSlide(val);
}
// Conditions to add and remove class from requirement
  useEffect(() => {
    // var div = document.querySelector('.la-ques-button-group .button-wrap');
    var activeGreen = document.querySelector('.submitted-scnv-2126');
    // var span = document.querySelector('.la-ques-wrapper').contains(div);
    var submitData = document.querySelector('.fte-breakdown').contains(activeGreen);
    var openPop = document.querySelector('.submenu .total-number .info');
    var closePop = document.querySelector('.right-sidebar .close-btn');

    if (submitData === true && group_id!=14) {
      setActiveSlide(null);
    }
    else {

      if (activeSlide === 1) {
      }
      if (activeSlide === 2) {
        document.querySelector('.prod-1')?.classList.add('div-z99');
      }
      else {
        document.querySelector('.prod-1')?.classList.remove('div-z99');
      }
      if (activeSlide === 3) {
        document.querySelector('.prod-2')?.classList.add('div-z99');
      }
      else {
        document.querySelector('.prod-2')?.classList.remove('div-z99');
      }
      if (activeSlide === 4) {
        document.querySelector('.prod-3')?.classList.add('div-z99');
        openPop?.click();
      }
      else {
        document.querySelector('.prod-3')?.classList.remove('div-z99');
        closePop?.click();
      }
      if (activeSlide === 5) {
        document.querySelector('.prod-4')?.classList.add('div-z99');
        document.querySelector('.submitted-scnv-2126')?.classList.add('div-z99');
      }
      else {
        document.querySelector('.prod-4')?.classList.remove('div-z99');
        document.querySelector('.submitted-scnv-2126')?.classList.remove('div-z99');
      }
    }
  }, [activeSlide]);

 useEffect(()=>{
    var scrollDiv=document.querySelector('.fte-breakdown .fte-breakdown-mid');
    if(scrollDiv){
      scrollDiv.style.overflow='hidden';
    }
    return()=>{
      if(scrollDiv){
        scrollDiv.style.overflow='';
      }
    }
  },[]);

  return (
    <>
      {/* link css */}
      <Helmet>
        <link rel="stylesheet" href={require('./fte-breakdown-walkthrough.scss').default} />
      </Helmet>
      {/* link css end*/}
      <ShowContent active={activeSlide} item={1}>
        <div className="walkthrough first">
          <div className="confirmation-form">
            {/* walkthrough wrapper start */}
            <div className="submit-form step-1">
              {/* walkthrough wrapper divided in two parts */}
              <div className="submit-content">
                 {/* left part is containing gif image related to walkthrough step. */}
                <div className="col-left"><img src={require('../../assets/img/general-info-walkthrough/loss-analysis-step-1.gif').default} alt="Step-1" /></div>
                {/* right part has 
                    heading , 
                    text and  
                    buttons navigate from one to next, next to previous and close. 
                */}
                <div className="col-right">
                  <div className="col-right-header">
                    <span>1 of 5</span>
                    <button className="close-submit-form" onClick={handleClose}>
                      <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                    </button>
                  </div>
                  <div className="name name-class"><p >Hi {UserName},</p></div>
                  <p className="text">Welcome to the Industry 4.0 Loss Analysis.​</p>
                  <p className="txt">We want to quickly show you the IR4.0 Loss Analysis data collection.</p>
                  <p className="text">You can skip at any time.​​</p>
                  <div className="card-footer">
                    <div className="card-footer-section">
                      <div className="card-footer-left">
                        <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                        <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                        <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                        <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                        <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                      </div>
                      <div className="card-footer-right">
                        <button onClick={() => handleClose()} className="prev-link link">No thanks</button>
                        <button className="let link solid" onClick={() => setActiveSlide(2)}>Let’s go</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ShowContent>
        <ShowContent active={activeSlide} item={2}>
          <div className="walkthrough">
            <div className="confirmation-form loss-walk1">
              {/* walkthrough wrapper start */}
              <div className="submit-form">
                {/* walkthrough wrapper divided in two parts */}
                <div className="submit-content">
                  {/* left part is containing gif image related to walkthrough step. */}
                  <div className="col-left"><img src={require('../../assets/img/general-info-walkthrough/loss-analysis-step-3.gif').default} alt="Step-1" /></div>
                  {/* right part has 
                      heading , 
                      text and  
                      buttons navigate from one to next, next to previous and close. 
                  */}
                  <div className="col-right">
                    <div className="col-right-header">
                      <span>2 of 5</span>
                      <button className="close-submit-form" onClick={handleClose}>
                        <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                      </button>
                    </div>
                    <div className="name"><p>Questions</p></div>
                    <p className="text">For each question, you need to provide data based on the selected fiscal year.</p>
                    <div className="card-footer">
                      <div className="card-footer-section">
                        <div className="card-footer-left">
                          <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                        </div>
                        <div className="card-footer-right">
                          <button className="link" onClick={() => setActiveSlide(1)}>Previous</button>
                          <button className="link solid" onClick={() => setActiveSlide(3)}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ShowContent>
        <ShowContent active={activeSlide} item={3}>
          <div className="walkthrough">
            <div className="confirmation-form loss-walk2">
              {/* walkthrough wrapper start */}
              <div className="submit-form">
                {/* walkthrough wrapper divided in two parts */}
                <div className="submit-content">
                  {/* left part is containing gif image related to walkthrough step. */}
                  <div className="col-left"><img src={require('../../assets/img/general-info-walkthrough/loss-analysis-step-4-finance.gif').default} alt="Step-1" /></div>
                  {/* right part has 
                      heading , 
                      text and  
                      buttons navigate from one to next, next to previous and close. 
                  */}
                  <div className="col-right">
                    <div className="col-right-header">
                      <span>3 of 5</span>
                      <button className="close-submit-form" onClick={handleClose}>
                        <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                      </button>
                    </div>
                    <div className="name"><p >Your Input</p></div>
                    <p className="text">Please keep in mind that only the blue cells can be edited and require your input.</p>
                      <p className="text">Your input is automatically saved.</p>
                    <div className="card-footer">
                      <div className="card-footer-section">
                        <div className="card-footer-left">
                          <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                        </div>
                        <div className="card-footer-right">
                          <button className="link" onClick={() => setActiveSlide(2)}>Previous</button>
                          <button className="link solid" onClick={() => setActiveSlide(4)}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ShowContent>
        <ShowContent active={activeSlide} item={4}>
          <div className="walkthrough">
            <div className="confirmation-form loss-walk3">
              {/* walkthrough wrapper start */}
              <div className="submit-form">
                {/* walkthrough wrapper divided in two parts */}
                <div className="submit-content">
                  {/* left part is containing gif image related to walkthrough step. */}
                  <div className="col-left"><img src={require('../../assets/img/general-info-walkthrough/loss-analysis-step-5-finance.gif').default} alt="Step-1" /></div>
                  {/* right part has 
                      heading , 
                      text and  
                      buttons navigate from one to next, next to previous and close. 
                  */}
                  <div className="col-right">
                    <div className="col-right-header">
                      <span>4 of 5</span>
                      <button className="close-submit-form" onClick={handleClose}>
                        <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                      </button>
                    </div>
                    <div className="name"><p>Help</p></div>
                    <p className="text">You can click the i icon for more information.</p>
                    <p className="text">If that is not enough, you can select the support button.</p>
                    <div className="card-footer">
                      <div className="card-footer-section">
                        <div className="card-footer-left">
                          <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                        </div>
                        <div className="card-footer-right">
                          <button className="link" onClick={() => setActiveSlide(3)}>Previous</button>
                          <button className="link solid" onClick={() => {setActiveSlide(5)}}>Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ShowContent>
        <ShowContent active={activeSlide} item={5}>
          <div className="walkthrough">
            <div className="confirmation-form loss-walk4">
              {/* walkthrough wrapper start */}
              <div className="submit-form">
                {/* walkthrough wrapper divided in two parts */}
                <div className="submit-content">
                  {/* left part is containing gif image related to walkthrough step. */}
                  <div className="col-left"><img src={require('../../assets/img/general-info-walkthrough/loss-analysis-step-6-finance.gif').default} alt="Step-1" /></div>
                  {/* right part has 
                      heading , 
                      text and  
                      buttons navigate from one to next, next to previous and close. 
                  */}
                  <div className="col-right">
                    <div className="col-right-header">
                      <span>5 of 5</span>
                      <button className="close-submit-form" onClick={handleClose}>
                        <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                      </button>
                    </div>
                    <div className="name"><p>Submit Data</p></div>
                    <p className="text">Following the completion of the section, submit your data.</p>
                    <p className="text">After submission you will not be able to edit the section.</p>
                    <div className="card-footer">
                      <div className="card-footer-section">
                        <div className="card-footer-left">
                          <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                          <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                        </div>
                        <div className="card-footer-right">
                          <button className="link" onClick={() => setActiveSlide(4)}>Previous</button>
                          <button className="let-start link solid" onClick={() => handleClose()}>Let's Start</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ShowContent>
      {/* la-ques-wrapper end */}
    </>
  )
}

// Function to show content
const ShowContent = (props) => {
  return (
    <>
      {props.active === props.item &&
        <>
          {props.children}
        </>
      }
    </>
  )
}