/** imported components

  * makeStyles for ===> styling material-ui component.
  * serviceProvider for ===> working with api.
  * Moment for ==> date library.
  * arraySort for ==> for dynamic breadcrumb.

**/
import React, { useState, useEffect } from "react";
import arraySort from "array-sort";
import serviceProvider from "../../services/axios";
import { Tabs, Tab, Collapse, Avatar, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import Moment from "react-moment";
// TicketsView start
export const TicketsView = (props) => {
  // defining variable
  const ticketDataItems = props.ticketData;
  if (!ticketDataItems.length) {
    return null;
  }
  const ticketDataItem = props.ticketData[0];
  const ticketsData = ticketDataItem.tickets;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const uid = ticketDataItem.uid;
  const [timer, setTimer] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [isClearAllFilter, setIsClearAllFilter] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [isResolvePopup, setIsResolvePopup] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isReopenPopup, setIsReopenPopup] = useState(false);
  const [c1Checked, setC1Checked] = useState(true);
  const [c2Checked, setC2Checked] = useState(true);
  const [c3Checked, setC3Checked] = useState(true);
  const [c4Checked, setC4Checked] = useState(true);
  const [c5Checked, setC5Checked] = useState(true);
  const tableData = [];
  if (ticketsData.length) {
    ticketsData.map((data) => {
      tableData.push({
        id: data.id,
        cid: data.course_id,
        cslug: data.course_slug,
        ticketId: "SCT-" + data.tid,
        plantName: data.plant_name,
        product: "Training",
        section: data.course_name,
        subsection: data.section_name,
        subslug: data.slug,
        answers: data.answers,
        status: data.answers.length ? "progress" : "open",
        date: data.datecreated,
        shortname: data.shortname,
        name: data.name,
        last_name: data.last_name,
        question: data.question,
        draft: data.draft,
        isDelBtn: false,
        isResolvedBtn: false,
        isReOpenBtn: false,
      });
    });
  }

  const [tableRowData, setTableRowData] = useState(tableData);
  useEffect(() => {
    setTableRowData(tableData);
  }, [ticketsData]);
  // function HandleResolvePopup
  const HandleResolvePopup = () => {
    setIsResolvePopup(!isResolvePopup);
  };
  // function HandleDeletePopup
  const HandleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
  };
  // function HandleReopenPopup
  const HandleReopenPopup = () => {
    setIsReopenPopup(!isReopenPopup);
  };
  // function HandleCollapseAccordion
  const HandleCollapseAccordion = (index) => {
    if (activeAccordion === index) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };
  // function handleChange
  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };
  // function searchTicket
  const searchTicket = (val) => {
    const sval = val.toLowerCase();
    var countchecked = document.querySelectorAll(
      "input.filter-check:checked"
    ).length;
    if (countchecked > 0) {
      setIsClearAllFilter(false);
      var selected = [];
      var chks = document.getElementsByName("filters");
      for (var i = 0; i < chks.length; i++) {
        if (chks[i].checked) {
          selected.push(chks[i].value);
        }
      }
      var result = tableData.filter(function (item) {
        return selected.indexOf(item.status) > -1;
      });
      if (selected.indexOf("all") != -1) {
        var filterData = tableData.filter((item) => {
          let rgx = new RegExp(sval);
          return rgx.test(item.ticketId.toLowerCase());
        });
      } else {
        var filterData = result.filter((item) => {
          let rgx = new RegExp(sval);
          return rgx.test(item.ticketId.toLowerCase());
        });
      }
    } else {
      var filterData = tableData.filter((item) => {
        let rgx = new RegExp(sval);
        return rgx.test(item.ticketId.toLowerCase());
      });
    }
    if (sval) {
      setTableRowData(filterData);
      setIsClearAllFilter(false);
    } else {
      setTableRowData(tableData);
      setIsClearAllFilter(true);
    }
  };
  // function clearFilter
  const clearFilter = () => {
    var checkboxes = document.getElementsByName("filters");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
    setIsClearAllFilter(true);
    setC1Checked(false);
    setC2Checked(false);
    setC3Checked(false);
    setC4Checked(false);
    setC5Checked(false);
    document.getElementById("searchTicket").value = "";
    setTableRowData(tableData);
  };
  // function filtercheck
  const filtercheck = () => {
    var searchText = document.getElementById("searchTicket").value;
    const stval = searchText.toLowerCase();
    var filterData = tableData.filter((item) => {
      let rgx = new RegExp(stval);
      return rgx.test(item.ticketId.toLowerCase());
    });
    setC1Checked(false);
    setC2Checked(false);
    setC3Checked(false);
    setC4Checked(false);
    setC5Checked(false);
    var countchecked = document.querySelectorAll(
      "input.filter-check:checked"
    ).length;
    if (countchecked > 0) {
      setIsClearAllFilter(false);
      var selected = [];
      var chks = document.getElementsByName("filters");
      for (var i = 0; i < chks.length; i++) {
        if (chks[i].checked) {
          if (chks[i].value == "all") {
            setC1Checked(true);
          }
          if (chks[i].value == "completed") {
            setC2Checked(true);
          }
          if (chks[i].value == "progress") {
            setC3Checked(true);
          }
          if (chks[i].value == "open") {
            setC4Checked(true);
          }
          if (chks[i].value == "cancel") {
            setC5Checked(true);
          }
          selected.push(chks[i].value);
        }
      }
      if (stval) {
        var result = filterData.filter(function (item) {
          return selected.indexOf(item.status) > -1;
        });
      } else {
        var result = tableData.filter(function (item) {
          return selected.indexOf(item.status) > -1;
        });
      }
      if (selected.indexOf("all") != -1) {
        if (stval) {
          setTableRowData(filterData);
        } else {
          setTableRowData(tableData);
        }
      } else {
        setTableRowData(result);
      }
    } else {
      if (stval) {
        setTableRowData(filterData);
      } else {
        setTableRowData(tableData);
      }
      setIsClearAllFilter(true);
    }
  };
  // function sortData
  const sortData = (e, type, order) => {
    // console.log(e.target.classList);
    // [].forEach.call(document.querySelectorAll("sortImg"), function (el) {
    // 	el.classList.remove("active");
    // });
    // e.target.classList.add("active");
    const hasorder = order == "desc" ? true : false;
    var searchText = document.getElementById("searchTicket").value;
    const stval = searchText.toLowerCase();
    var filterData = tableData.filter((item) => {
      let rgx = new RegExp(stval);
      return rgx.test(item.ticketId.toLowerCase());
    });
    var countchecked = document.querySelectorAll(
      "input.filter-check:checked"
    ).length;
    if (countchecked > 0) {
      setIsClearAllFilter(false);
      var selected = [];
      var chks = document.getElementsByName("filters");
      for (var i = 0; i < chks.length; i++) {
        if (chks[i].checked) {
          selected.push(chks[i].value);
        }
      }
      if (stval) {
        var result = filterData.filter(function (item) {
          return selected.indexOf(item.status) > -1;
        });
      } else {
        var result = tableData.filter(function (item) {
          return selected.indexOf(item.status) > -1;
        });
      }
      if (selected.indexOf("all") != -1) {
        // console.log("11");
        if (stval) {
          var sortedArray = arraySort(filterData, type, { reverse: hasorder });
          setTableRowData(filterData);
        } else {
          var sortedArray = arraySort(tableData, type, { reverse: hasorder });
          setTableRowData(tableData);
        }
      } else {
        var sortedArray = arraySort(result, type, { reverse: hasorder });
        // console.log("12");
        setTableRowData(result);
      }
    } else {
      if (stval) {
        var filterData = tableData.filter((item) => {
          let rgx = new RegExp(stval);
          return rgx.test(item.ticketId.toLowerCase());
        });
        var sortedArray = arraySort(filterData, type, { reverse: hasorder });
        // console.log("13");
        setTableRowData(filterData);
      } else {
        setIsClearAllFilter(false);
        var sortedArray = arraySort(tableData, type, { reverse: hasorder });
        // console.log("14");
        setTableRowData(tableData);
      }
    }
  };

  const HandleFilterData = (e) => {};
  // function LoadMore
  const LoadMore = () => {
    setLimit(limit + 1);
  };
  // function showReplybox
  const showReplybox = (rid) => {
    const element = document.getElementById("ansreplybox-" + rid);
    element.classList.toggle("hidebox");
  };
  // function submitAnswers
  const submitAnswers = (event) => {
    const qid = event.target.id;
    var ans = document.getElementById("txtReplyMsg-" + qid).value;
    if (ans) {
      serviceProvider
        .post(
          "/ticket?type=saveanswer",
          JSON.stringify({ uid: uid, qid: qid, ans: ans }),
          true
        )
        .then((response) => {
          // setTimer(timer + 1);
          props.onSubmitAns(response.data);
        });
    }
  };
  // function submitReply
  const submitReply = (event) => {
    const aid = event.target.id;
    var ans = document.getElementById("ansReplyMsg-" + aid).value;
    if (ans) {
      serviceProvider
        .post(
          "/ticket?type=savereply",
          JSON.stringify({ uid: uid, aid: aid, ans: ans }),
          true
        )
        .then((response) => {
          // setTimer(timer + 1);
          props.onSubmitAns(response.data);
        });
    }
  };
  // function saveDraft
  const saveDraft = (event) => {
    const qid = event.target.id;
    var ans = document.getElementById("txtReplyMsg-" + qid).value;
    if (ans) {
      serviceProvider
        .post(
          "/ticket?type=savedraft",
          JSON.stringify({ uid: uid, qid: qid, ans: ans, is_draft: 1 }),
          true
        )
        .then((response) => {
          // setTimer(timer + 1);
          props.onSubmitAns(response.data);
        });
    }
  };
  // function a11yProps
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }
  // function TabPanel
  const TabPanel = (props) => {
    const { index, activeTabIndex } = props;
    return <>{index === activeTabIndex ? <> {props.children}</> : ""}</>;
  };
  // tableDataBody start
  const tableDataBody = tableRowData.length ? (
    tableRowData.map(
      (item, index) =>
        index < limit && (
          <>
            <div
              key={item.id}
              className={
                index === activeAccordion
                  ? "tickets-table-row active"
                  : "tickets-table-row"
              }
            >
              <div className="tickets-col tickets-date-col">
                <p>
                  <Moment format="DD-MM-YYYY">{item.date}</Moment>
                </p>
              </div>
              <div className="tickets-col tickets-id-col">
                <p>{item.ticketId}</p>
              </div>
              <div className="tickets-col tickets-palnt-col">
                <p>{item.plantName}</p>
              </div>
              <div className="tickets-col tickets-product-col">
                <p>{item.product}</p>
              </div>
              <div className="tickets-col tickets-section-col">
                <p>{item.section}</p>
              </div>
              <div className="tickets-col tickets-section-col">
                <p>{item.subsection}</p>
              </div>

              <div className="tickets-col tickets-status-col">
                {item.status == "open" && <p className="status open">open</p>}
                {item.status == "progress" && (
                  <p className="status progress">in progress</p>
                )}
                {item.status == "closed" && (
                  <p className="status closed">closed</p>
                )}
                {item.status == "hold" && (
                  <p className="status hold">on hold</p>
                )}
                {item.status == "cancel" && (
                  <p className="status cancel">cancelled</p>
                )}
              </div>
              <div className="tickets-col tickets-ddl-col">
                <button
                  className={index === activeAccordion ? "active" : ""}
                  onClick={() => HandleCollapseAccordion(index)}
                >
                  <img
                    src={
                      require("../../assets/img/tickets/table-ddl-icon.svg")
                        .default
                    }
                    alt="toggle"
                  />
                </button>
              </div>
            </div>
            <Collapse in={index === activeAccordion}>
              <div className="table-collapse-div">
                <div className="table-collapse-row mb-9rem">
                  <div className="img-div"></div>
                  <div className="middle-div">
                    <div className="middle-div-row">
                      <Link
                        to={`/training-courses/${item.cslug}/${item.subslug}#q&a`}
                        className="btn-blue "
                      >
                        View Ticket
                      </Link>
                      {item.isDelBtn && (
                        <button className="ml-auto common-btn del-btn">
                          <div className="icon-div">
                            <img
                              src={
                                require("../../assets/img/tickets/delete-icon.svg")
                                  .default
                              }
                            />
                          </div>
                          <span>Delete Ticket</span>
                        </button>
                      )}
                      {item.isResolvedBtn && (
                        <button className="common-btn resolved-btn">
                          <div className="icon-div">
                            <img
                              src={
                                require("../../assets/img/tickets/resolved-icon.svg")
                                  .default
                              }
                            />
                          </div>
                          <span>Mark Resolved</span>
                        </button>
                      )}
                      {item.isReOpenBtn && (
                        <button className="ml-auto common-btn re-open-btn">
                          <div className="icon-div">
                            <img
                              src={
                                require("../../assets/img/tickets/re-open-icon.svg")
                                  .default
                              }
                            />
                          </div>
                          <span>Re-Open Ticket</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="right-div"></div>
                </div>

                <ul className="list-ul">
                  <li className="list-li">
                    <div className="table-collapse-row mb-41rem">
                      <div className="img-div">
                        <Avatar>
                          <span>{ticketDataItem.shortname}</span>
                        </Avatar>
                      </div>
                      <div className="middle-div">
                        <textarea
                          id={`txtReplyMsg-${item.id}`}
                          className="textarea"
                          placeholder="Write your query"
                          defaultValue={item.draft && item.draft}
                        ></textarea>
                      </div>
                      <div className="right-div">
                        <button
                          className="btn-blue"
                          id={item.id}
                          onClick={(e) => submitAnswers(e)}
                        >
                          Post
                        </button>
                        <button
                          className="save-drft-btn"
                          id={item.id}
                          onClick={(e) => saveDraft(e)}
                        >
                          Save Draft
                        </button>
                      </div>
                    </div>

                    <div className="table-collapse-row mb-9rem">
                      <div className="img-div">
                        <Avatar>
                          <span>{item.shortname}</span>
                        </Avatar>
                      </div>
                      {/* img-div end */}
                      <div className="middle-div">
                        <div className="text-div">
                          <p>{item.question}</p>
                        </div>
                      </div>
                      {/* middle-div end */}
                      <div className="right-div">
                        <p>
                          <Moment format="Do MMMM, YYYY">{item.date}</Moment>
                        </p>
                        <button className="reply-btn">
                          <img
                            src={
                              require("../../assets/img/tickets/reply.svg")
                                .default
                            }
                            alt="true"
                          />
                        </button>
                      </div>
                    </div>
                    <ul>
                      <li>
                        {item.answers.length ? (
                          item.answers.map((ans) => (
                            <div key={ans.id}>
                              <div className="table-collapse-row mb-9rem">
                                <div className="img-div">
                                  <Avatar>
                                    <span>{ans.shortname}</span>
                                  </Avatar>
                                </div>
                                <div className="middle-div">
                                  <div className="text-div">
                                    <p>{ans.answer}</p>
                                  </div>
                                </div>
                                <div className="right-div">
                                  <p>
                                    <Moment format="Do MMMM, YYYY">
                                      {ans.datecreated}
                                    </Moment>
                                  </p>
                                  <button
                                    className="reply-btn"
                                    onClick={() => showReplybox(ans.id)}
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/tickets/reply.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </button>
                                </div>
                              </div>
                              <ul
                                id={`ansreplybox-${ans.id}`}
                                className={ans.replies.length ? "" : "hidebox"}
                              >
                                <li>
                                  <div className="table-collapse-row reply-li-row">
                                    <div className="img-div">
                                      <Avatar>
                                        <span>{ticketDataItem.shortname}</span>
                                      </Avatar>
                                    </div>
                                    <div className="middle-div">
                                      <textarea
                                        id={`ansReplyMsg-${ans.id}`}
                                        className="textarea"
                                        placeholder="Reply to this thread"
                                      ></textarea>
                                    </div>
                                    <div className="right-div">
                                      <button
                                        className="btn-blue"
                                        id={ans.id}
                                        onClick={(e) => submitReply(e)}
                                      >
                                        Reply
                                      </button>
                                    </div>
                                  </div>
                                  {ans.replies.length ? (
                                    ans.replies.map((rep) => (
                                      <div key={rep.id}>
                                        <div className="table-collapse-row mb-9rem">
                                          <div className="img-div">
                                            <Avatar>
                                              <span>{rep.shortname}</span>
                                            </Avatar>
                                          </div>
                                          <div className="middle-div">
                                            <div className="text-div">
                                              <p>{rep.answer}</p>
                                            </div>
                                          </div>
                                          <div className="right-div">
                                            <p>
                                              <Moment format="Do MMMM, YYYY">
                                                {rep.datecreated}
                                              </Moment>
                                            </p>
                                            {/* <button className="reply-btn" onClick={() => showReplybox(rep.id)}>
																						<img
																							src={require("../../assets/img/tickets/reply.svg").default}
																							alt="true"
																						/>
																					</button> */}
                                          </div>
                                        </div>
                                        <ul
                                          id={`ansreplybox-${rep.id}`}
                                          className="hidebox"
                                        >
                                          <li>
                                            <div className="table-collapse-row reply-li-row">
                                              <div className="img-div">
                                                <Avatar>
                                                  <span>
                                                    {ticketDataItem.shortname}
                                                  </span>
                                                </Avatar>
                                              </div>
                                              <div className="middle-div">
                                                <textarea
                                                  id={`ansReplyMsg-${rep.id}`}
                                                  className="textarea"
                                                  placeholder="Reply to this thread"
                                                ></textarea>
                                              </div>
                                              <div className="right-div">
                                                <button
                                                  className="btn-blue"
                                                  id={rep.id}
                                                  onClick={(e) =>
                                                    submitReply(e)
                                                  }
                                                >
                                                  Reply
                                                </button>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </li>
                              </ul>
                            </div>
                          ))
                        ) : (
                          <></>
                        )}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Collapse>
          </>
        )
    )
  ) : (
    <div className="tickets-table-row">
      <div className="tickets-col tickets-empty-col">
        <p>No Tickets</p>
      </div>
    </div>
  );

  return (
    <div className="tickets-page-main">
      {/* support section start */}
      <div className="tickets-top-sec">
        <div className="tickets-page-continer">
          <div className="row align-center mb-4rem">
            <div className="col1">
              <h2 className="h1">Support Center</h2>
            </div>
            <div className="col2">
              <img
                src={require("../../assets/img/tickets/dots.svg").default}
                alt="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="tab-tbt-col">
              <Tabs value={activeTabIndex} onChange={handleChange}>
                <Tab
                  icon={
                    <div className="tab-btn">
                      <div className="icon">
                        <img
                          className="active"
                          src={
                            require("../../assets/img/tickets/my-tickets-active.svg")
                              .default
                          }
                          alt="true"
                        />
                        <img
                          className="inactive"
                          src={
                            require("../../assets/img/tickets/my-tickets-inactive.svg")
                              .default
                          }
                          alt="true"
                        />
                      </div>
                      <div className="tab-text">My Tickets</div>
                    </div>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  className="btn-inactive"
                  icon={
                    <div className="tab-btn">
                      <div className="icon">
                        {/* <img
													className="active"
													src={require("../../assets/img/tickets/learn-and-res-active.svg").default}
													alt="true"
												/>
												<img
													className="inactive"
													src={require("../../assets/img/tickets/learn-and-res-inactive.svg").default}
													alt="true"
												/> */}
                      </div>
                      <div className="tab-text" style={{ color: "#f4f6fc" }}>
                        Learning & Resources
                      </div>
                    </div>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </div>
            <div className="find-input-col">
              <label className="find-input-wrapper">
                <span className="icon">
                  <img
                    src={
                      require("../../assets/img/tickets/find-icon.svg").default
                    }
                    alt="true"
                  />
                </span>
                <input
                  onChange={(e) => searchTicket(e.target.value)}
                  id="searchTicket"
                  placeholder="Find Tickets by ID"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* tickets-top-sec end */}
      <div className="panel-wrapper">
        <TabPanel index={0} activeTabIndex={activeTabIndex}>
          <div className="tickets-page-continer">
            <div className="tickets-tabpanel">
              <div className="row align-center filter-card-row">
                <div className="filter-card">
                  <label className="filter-input">
                    <input
                      type="checkbox"
                      className="filter-check"
                      name="filters"
                      value="all"
                      checked={c1Checked}
                      onChange={filtercheck}
                    />
                    <span className="icon">
                      <img
                        src={
                          require("../../assets/img/tickets/input-uncheck.svg")
                            .default
                        }
                        alt="false"
                        className="unchecked"
                      />
                      <img
                        src={
                          require("../../assets/img/tickets/input-check.svg")
                            .default
                        }
                        alt="true"
                        className="checked"
                      />
                    </span>
                    <span className="input-text">All</span>
                  </label>
                  <label className="filter-input">
                    <input
                      type="checkbox"
                      className="filter-check"
                      name="filters"
                      value="completed"
                      checked={c2Checked}
                      onChange={filtercheck}
                    />
                    <span className="icon">
                      <img
                        src={
                          require("../../assets/img/tickets/input-uncheck.svg")
                            .default
                        }
                        alt="false"
                        className="unchecked"
                      />
                      <img
                        src={
                          require("../../assets/img/tickets/input-check.svg")
                            .default
                        }
                        alt="true"
                        className="checked"
                      />
                    </span>
                    <span className="input-text">Completed</span>
                  </label>
                  <label className="filter-input">
                    <input
                      type="checkbox"
                      className="filter-check"
                      name="filters"
                      value="progress"
                      checked={c3Checked}
                      onChange={filtercheck}
                    />
                    <span className="icon">
                      <img
                        src={
                          require("../../assets/img/tickets/input-uncheck.svg")
                            .default
                        }
                        alt="false"
                        className="unchecked"
                      />
                      <img
                        src={
                          require("../../assets/img/tickets/input-check.svg")
                            .default
                        }
                        alt="true"
                        className="checked"
                      />
                    </span>
                    <span className="input-text">In-Progress</span>
                  </label>
                  <label className="filter-input">
                    <input
                      type="checkbox"
                      className="filter-check"
                      name="filters"
                      value="open"
                      checked={c4Checked}
                      onChange={filtercheck}
                    />
                    <span className="icon">
                      <img
                        src={
                          require("../../assets/img/tickets/input-uncheck.svg")
                            .default
                        }
                        alt="false"
                        className="unchecked"
                      />
                      <img
                        src={
                          require("../../assets/img/tickets/input-check.svg")
                            .default
                        }
                        alt="true"
                        className="checked"
                      />
                    </span>
                    <span className="input-text">Open</span>
                  </label>
                  <label className="filter-input">
                    <input
                      type="checkbox"
                      className="filter-check"
                      name="filters"
                      value="cancel"
                      checked={c5Checked}
                      onChange={filtercheck}
                    />
                    <span className="icon">
                      <img
                        src={
                          require("../../assets/img/tickets/input-uncheck.svg")
                            .default
                        }
                        alt="false"
                        className="unchecked"
                      />
                      <img
                        src={
                          require("../../assets/img/tickets/input-check.svg")
                            .default
                        }
                        alt="true"
                        className="checked"
                      />
                    </span>
                    <span className="input-text">Cancelled</span>
                  </label>
                  <button
                    className="clear-all-btn"
                    onClick={clearFilter}
                    disabled={isClearAllFilter}
                  >
                    <span className="icon">
                      <img
                        src={
                          require("../../assets/img/common-img/close-icon-dark.svg")
                            .default
                        }
                        alt="true"
                      />
                    </span>
                    <span>Clear All</span>
                  </button>
                </div>
                <p className="txt-7tickets">
                  {tableRowData.length} Total Tickets
                </p>
              </div>
              {/* row end */}
              <div className="tickets-table-overflow">
                <div className="tickets-table">
                  <div className="tickets-table-header">
                    <div className="tickets-table-row">
                      <div className="tickets-col tickets-date-col">
                        <span>Date</span>
                        <div className="sort-btn-wrapper">
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "date", "asc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/up-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "date", "desc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/down-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="tickets-col tickets-id-col">
                        <span>Ticket ID</span>
                        <div className="sort-btn-wrapper">
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "ticketId", "asc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/up-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "ticketId", "desc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/down-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="tickets-col tickets-palnt-col">
                        <span>Plant Name</span>
                        <div className="sort-btn-wrapper">
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "plantName", "asc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/up-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "plantName", "desc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/down-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="tickets-col tickets-product-col">
                        <span>Product</span>
                        <div className="sort-btn-wrapper">
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "product", "asc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/up-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "product", "desc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/down-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="tickets-col tickets-section-col">
                        <span>Section</span>
                        <div className="sort-btn-wrapper">
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "section", "asc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/up-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "section", "desc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/down-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="tickets-col tickets-subsection-col">
                        <span>Sub Section</span>
                        <div className="sort-btn-wrapper">
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "subsection", "asc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/up-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "subsection", "desc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/down-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="tickets-col tickets-status-col">
                        <span>Status</span>
                        <div className="sort-btn-wrapper">
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "status", "asc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/up-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                          <button
                            className="sortClass"
                            onClick={(e) => sortData(e, "status", "desc")}
                          >
                            <img
                              className="sortImg"
                              src={
                                require("../../assets/img/tickets/down-sort.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="tickets-col tickets-ddl-col"></div>
                    </div>
                  </div>
                  {/* tickets-table-header end */}
                  <div className="tickets-table-body">{tableDataBody}</div>
                </div>
              </div>
              {ticketsData.length > limit ? (
                <div className="load-more-div">
                  <button onClick={() => LoadMore()}>+ Load more</button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel index={1} activeTabIndex={activeTabIndex}>
          Learning & Resources
        </TabPanel>
      </div>
      {/* Resolve popup */}
      <Modal
        open={isResolvePopup}
        onClose={HandleResolvePopup}
        className="tickets-popup"
      >
        <div className="popup-content">
          <header className="header">
            <p>Mark Resolve</p>
            <button className="close-btn" onClick={HandleResolvePopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-dark.svg")
                    .default
                }
              />
            </button>
          </header>
          <section className="body-content">
            <p>Are you sure you want to Resolve this Ticket?</p>
            <div className="btn-wrapper">
              <button className="blue-border">Cancel</button>
              <button className="blue-btn">Resolve</button>
            </div>
          </section>
        </div>
      </Modal>
      {/* Delete popup */}
      <Modal
        open={isDeletePopup}
        onClose={HandleDeletePopup}
        className="tickets-popup"
      >
        <div className="popup-content">
          <header className="header">
            <p>Delete Ticket</p>
            <button className="close-btn" onClick={HandleDeletePopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-dark.svg")
                    .default
                }
              />
            </button>
          </header>
          <section className="body-content">
            <p>Are you sure you want to Delete this Ticket?</p>
            <div className="btn-wrapper">
              <button className="blue-border">Cancel</button>
              <button className="blue-btn">Delete</button>
            </div>
          </section>
        </div>
      </Modal>
      {/* Re-Open popup */}
      <Modal
        open={isReopenPopup}
        onClose={HandleReopenPopup}
        className="tickets-popup"
      >
        <div className="popup-content">
          <header className="header">
            <p>Re-Open Ticket</p>
            <button className="close-btn" onClick={HandleReopenPopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-dark.svg")
                    .default
                }
              />
            </button>
          </header>
          <section className="body-content">
            <p>Are you sure you want to Re-Open this Ticket?</p>
            <div className="btn-wrapper">
              <button className="blue-border">Cancel</button>
              <button className="blue-btn">Re-Open</button>
            </div>
          </section>
        </div>
      </Modal>
    </div>
  );
};
