/** imported components
 
  * useState, useEffect  ===> React hooks (useState is a way to get/set value)
  * $, { isEmptyObject } ===> component from jquery which is used in file.
  * RangeSlide           ===> Custom component from range slider which is used for multirange question format.

**/

import React, { useState, useEffect } from "react";
import $, { isEmptyObject } from 'jquery';
// import Plyr from "plyr-react";
import { Link } from "react-router-dom";
import { RangeSlide } from "../range-slider/range-slider";


export const MultiRange = (props) => {

	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}


	const quesDataItem = props.quesData[0];

	
	
	// const [activeRightTab, setActiveRightTab] = useState(null);
	// const [clickedCardNo, setclickedCardNo] = useState(null);
	const [allRangeValueArr, setAllRangeValueArr] = useState({})
	const {activeRightTab, setActiveRightTab} = props;
	const {qlength, setQlenth} = props;
	const getRangeValue = (e) => {
		// const oid = e.target.idv;
		const name = e.target.id;
		const val = e.target.value;
		let tmpArr = {
			...allRangeValueArr,
			[name]: val,
			// option_id : oid,

		};
		// console.log(oid);
		setAllRangeValueArr(tmpArr);
	};
	 const multirange = quesDataItem.answers.map((item, index) => {
		var value = 0;
		if (quesDataItem.savedans.length) {
			var val = quesDataItem.savedans;
			var newval = JSON.parse(val);
			
			var fvalue = newval.filter(x => x.option_id === item.id);
			if(typeof fvalue[0] !== "undefined"){
			 value = fvalue[0].line_id;
			}else{
				value=0;
				}
		}
		
	return (
			// wrapper for multi range answer format which is containing range slider.
			<div className="multi-progress-ans-wrap">
				{/* answer card which is divided into two parts. First part is having range slider and second part is containing info icon. */}
				<div className="choose-one-card" key={index}>
					<div className="card-left" style={{pointerEvents:quesDataItem.is_datasubmitted==1?'none':''}}>
						<div className="lft-text-div">
							<p>{item.option_name}</p>
						</div>
						<div className="lft-progressbar-div">
							<div className="lft-progressbar">
								<RangeSlide
									id={item.id}
									getValue={getRangeValue}
									value={value}
									showLabel={false}
									
								/>
							</div>
							<div className="label">
								{allRangeValueArr[`${item.id}`] ? allRangeValueArr[`${item.id}`] : value ? value : 0}%
							</div>
						</div>
					</div>
					<div className={props.clickedCardNo === index ? props.activeRightTab == 0 ? "card-right active" :"card-right" : "card-right"}>
						<img
							className="inactive"
							src={require("../../assets/img/multi-choice/non-active-info.svg").default}
							alt="true"
							onClick={() => props.HandleClickedCardAction(index, item.answer_description,item.option_name)}
						/>
						<img
							src={require("../../assets/img/multi-choice/active-info.svg").default}
							onClick={() => props.HandleClickedCardAction(index, item.answer_description,item.option_name)}
							className="active"
							alt="true"
						/>
					</div>
				</div>					
			</div>
		);
	});

	useEffect(()=>{
		let sch = document.querySelector('.slide-show')?.scrollHeight;
	let cch = document.querySelector('.slide-show')?.clientHeight;
if(sch>cch){
// console.log("t")
	$('.slide-show').scroll(function (event) {
		var scroll = $('.slide-show').scrollTop();
		// console.log(scroll?.scrollHeight > scroll?.clientHeight)
		if (scroll > 0) {
			// $('.multi-chk-ans-wrap').addClass('fixed-top');
			// console.log("hello");
			setQlenth('active');
		} else {
			// console.log("hello1")
			setQlenth('');
			// $('.multi-chk-ans-wrap').removeClass('fixed-top');
		}
	});
}
		// console.log(tmpObj);
		// setAllRangeValueArr(tmpObj3);
	},[])

	
		return (
			<>
				<div className="slide-show">{multirange}</div>
			</>
		);
	
};
