import React,{useState,useEffect} from 'react';
import {CompanyDetailView} from './CompanyDetailView';
import serviceProvider from '../../services/axios'
import {
    Redirect
} from "react-router-dom";


export const CompanyDetailCtrl =(props)=>{

    const [results, setResults] = useState([]);
    const user = JSON.parse(localStorage.getItem("userData"));
    const login = localStorage.getItem("isLoggedIn");
    const usid = user ? user.uid : 0;
    const slug = props.match.params.slug
    if (!login) {
        return <Redirect to="/sign-in" />;
    }
    useEffect(() => {
        const search = async () => {
            const apiUrl = `/profile?param=companydetails&slug=${slug}&uid=${usid}`;
            const { data } = await serviceProvider.get(apiUrl, true, {});
            setResults(data);
        };
        search();
    }, []);

    

    if(results.length) {
		
                return <CompanyDetailView slug={slug} results={results}  {...props} />;
   
    }else{
                return (
                        <>
                            <div></div>
                        </>
                     );
    
    }


};
