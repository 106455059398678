import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router';
import serviceProvider from '../../services/axios'
import { QuickStart } from "../quickstart/quickstart-ctrl";
import { HomeView } from "./home-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";

export const HomeControler = (props) => {
	const [results, setResults] = useState([]);
	const [getstatus, setResultsstatus] = useState([]);
	const [tourComplete, setTourComplete] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const group_id = JSON.parse(localStorage.getItem("group_id"));
	const usid = user ? user.uid : 0;

	const login = localStorage.getItem("isLoggedIn");
    
    if (!login) {
		return <Redirect to="/sign-in" />;
	}

	useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
			const apiUrl = `/home?param=homedata&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResults(data);

			const apiUrl1 = `/home?param=verifydata&uid=${usid}`;
			const  data1  = await serviceProvider.get(apiUrl1,true,{});
		
			 setResultsstatus(data1.data);
		};
	
		
	
		search();
	}, [tourComplete]);
	
    if(1) {
	    return <Redirect to="/home" />;
    }
	if(group_id == 14) {
	    return <Redirect to="/home" />;
    }
	return (
		<>
			<Helmet>
				<title>Home | SmarterChains</title>
				<meta name="description" content="Home" />
			</Helmet>
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<HomeView homeData={results} onTourComplete={setTourComplete} verification = {getstatus} {...props} />
			<QuickStart />
		</>
	);
};
