import styled from "styled-components";

export const MainWrapper = styled.div`
  background: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 0rem 1.5rem 0rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
`;

/*top header css*/
export const SectionWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  padding-top: 0.5rem;
`;

export const LeftSection = styled.div`
  width: 50%;
  border-right: 0.1rem solid #cdd2d4;
  height: 100%;
  flex-direction: column;
  display: flex;
  ${
    "" /* .carddragging{
    position:static !important;
    transform:none !important;
  } */
  }
`;

export const RightSection = styled.div`
  width: 50%;
  height: 100%;
  flex-direction: column;
  display: flex;
`;

export const SectionHeader = styled.div`
  min-height: 10rem;
  padding: 0rem 3rem;
  p{
    font-weight: 400;
font-size: 1.4rem;
line-height: 1.7rem;
margin-top: 1.2rem;
color: #7d868c;
  }
`;

export const ContentBody = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0rem 2rem;
  .blue-txt {
    color: #118acb;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;
export const ContentFooter = styled.div`
display: flex;
justify-content: end;
background: #ffffff;
padding: 1.5rem;
width: 100%;
.proceed-btn {
  min-width: 11.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.5rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #118acb;
  border: 0.1rem solid #118acb;
  outline: 0 !important;
  background: #fff;
  border-radius: 0.4rem;
  transition: 0.25s ease-in-out;
  margin: 0 0.5rem;
  &:hover {
    background: #118acb;
    color: #ffffff;
  }
}

.prev-btn{
  min-width: 11.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.5rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #7d868c;
  border: 0.1rem solid #7d868c;
  outline: 0 !important;
  background: #fff;
  border-radius: 0.4rem;
  transition: 0.25s ease-in-out;
  margin: 0 0.5rem;
  &:hover{
      background: #7d868c;
      border-color: #7d868c;
      color: #ffffff;
  }
}
`;
export const HeaderTitle = styled.h2`
  font-size: 1.6rem;
  color: #118acb;
`;
export const FilterBoxHeader = styled.div`
  border-bottom: 0.1rem solid #ccc;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  align-items: center;
  ${HeaderTitle} {
    color: #4d4d4f;
    font-size: 1.6rem;
  }
`;
export const ScenarioSectionWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
export const TabButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 3px 4px 0px rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  height: 6rem;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-left: 5rem;
  position: relative;
`;
export const TBRow = styled.div`
  display: flex;
  width: 100%;
`;
export const TBLeftCol = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  width: 53%;
  .rmap-txt {
    color: #7d868c;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.7rem;
    padding-left: 1rem;
    padding-right: 2rem;
    flex: 1 1;
  }
  .drag-btn {
    width: 5.5rem;
    position: absolute;
    left: 0;
    top: -0.01rem;
    bottom: -0.01rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.blue {
      border-radius: 0.8rem;
      background: linear-gradient(124.38deg, #139bd6 33.4%, #078ad0 72.76%);
      color: #fff;
      font-size: 1.6rem;
      font-weight: 500;
      position: absolute;
      left: -0.15rem;
      top: -0.05rem;
      bottom: -0.05rem;
    }
    &.disabled{
      border-radius: 0.8rem;
      background: transparent;
      color: #4d4d4f;
      font-size: 1.6rem;
      font-weight: 500;
      position: absolute;
      left: -0.15rem;
      top: -0.05rem;
      bottom: -0.05rem;
      border-right: 0.1rem solid #ebf5fa !important;
      }
    i {
      display: inline-block;
      cursor: grab;
      width: 0.9rem;
    }
  }
  .status-wrap {
    min-width: 10.5rem;
  }
 
`;
export const TBRightCol = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  justify-content: right;
  width: 47%;
`;
export const TabContentWraaper = styled.div`
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #7d868c;
  position: relative;
  padding: 1.5rem;
  // Css for tabs
  .MuiBox-root {
    padding: 2.4rem 0 0 2.4rem !important;
  }
  .MuiTabs-root {
    align-items: center;
    position: relative;
    .MuiButtonBase-root {
      min-width: 15rem;
      width: max-content;
      .MuiTab-wrapper {
        font-size: 1.4rem !important;
        text-transform: capitalize;
      }
    }
    .PrivateTabIndicator-root-1 {
      background-color: #118acb !important;
      padding: 0.2rem;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
    .MuiButtonBase-root {
      &.MuiTabScrollButton-root {
        &.MuiTabs-scrollButtons {
          &.MuiTabs-scrollButtonsDesktop {
            height: 2.4rem !important;
            width: 2.4rem !important;
            min-width: unset;
            background: #fff;
            .MuiSvgIcon-root {
              &.MuiSvgIcon-fontSizeSmall {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .MuiTabs-scroller {
    border-bottom: 0.1rem solid #dee2e6;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;
export const LossWrap = styled.div`
  border: 0.1rem solid #ebf5fa;
  border-radius: 0.4rem;
  padding: 1.2rem 0.5rem;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.12); 
  margin-bottom: 3rem;
`;
export const LossRow = styled.div`
  display: flex;
  align-items: center;
`;
export const LossCol = styled.div`
  border-right: 0.1rem solid #ebf5fa;
  flex: 1 1;
  padding: 0 2.5rem;
  .text {
    display: block;
    font-size: 1.4rem;
    text-align: center;
    &.orange {
      color: #eb5757;
    }
    &.green {
      color: #27ae60;
    }
    &.blue {
      color: #118acb;
    }
  }
  &:last-child {
    border-right: 0;
  }
`;
export const ProceedPopWrapper = styled.div`
  background: rgba(21, 27, 38, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
`;
export const ProceedPopCard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 6px 8px rgba(152, 164, 170, 0.17);
  border-radius: 0.8rem;
  min-height: 20rem;
  width: 45rem;
  p {
    font-size: 1.4rem;
    font-weight: 400;
    padding-left: 2rem;
    padding-bottom: 2rem;
  }
`;
export const PopHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem 2rem 2rem;
  span {
    color: #118acb;
    font-size: 1.6rem;
    font-weight: 400;
  }
`;
export const PopFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  .save-btn,.leave-btn {
    min-height: 4rem;
    min-width: 11.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #ffffff;
    background: linear-gradient(91.83deg, #139bd6 12.22%, #0789cf 100.41%);
    border: 0.1rem solid #118acb;
    border-radius: 0.4rem;
    &.transparent {
      background: transparent;
      border-color: #118acb;
      color: #118acb;
      margin-left: 2rem;
    }
  }
`;
export const QNABtn = styled.div`
  display: flex;
  padding: 0;
  min-height: 3.5rem;
  cursor: pointer;
  &:first-child {
    padding-top: 0;
  }
  .q-txt {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #4d4d4f;
  }
  .q-desc {
    font-weight: 500;
    font-size: 1.4rem;
    color: #4d4d4f;
  }
`;
export const QNACard = styled.div`
  padding: 1.2rem;
  &.active {
    background: #fafcfd;
    border: 0.1rem solid #e5f0ff !important;
    border-radius: 1.2rem !important;
    ${QNABtn} {
      .q-txt {
        color: #118acb;
      }
      .q-desc {
        color: #118acb;
      }
    }
  }
`;
export const QNAWrap = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
    .bd-bottom {
      border-bottom: 0.1rem solid #e5f0ff;
    }
    .bd-right {
      border-right: 0.1rem solid #e5f0ff;
    }
    tr {
      th {
        color: #4d4d4f;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0.5rem;
        &:first-child {
          text-align: left;
        }
      }
      td {
        text-align: center;
        padding: 0.5rem;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
`;
export const ScenarioContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
`;

export const ScenarioButton = styled.button`
            border:0.1rem solid ${({ active }) =>
              active ? "#ececec" : "#4d4d4f"};
            border-radius:0.4rem;
            background:#fff;
            box-shadow: ${({ active }) =>
              active ? "rgba(0, 0, 0, 0.25)" : "transparent"} 0px 0px 0.8rem;
            positon:relative;
            width:100%;
            display:flex;
            align-items:center;
            padding:1.3rem 1.6rem;
            text-align:left;
            font-size:1.4rem;
            font-weight:600;
            transition:0.25s ease-in;
            span{
                flex:1;
                color: #7d868c;
            }
            i{
                display:inline-block;
                svg{
                    width:1.6rem;
                    path:border-bottom:${({ active }) => ("#" ? 0 : "")};
                }
            }
`;

export const ScenarioDropdownBox = styled.div`
  position: absolute;
  bottom: -1rem;
  transform: translateY(100%);
  background: #fff;
  z-index: 9;
  padding: 1.5rem 1rem;
  border-radius: 0.4rem;
  border-top: 0;
  width: 100%;
  box-shadow: ${({ show }) => (show ? "rgba(0, 0, 0, 0.25)" : "transparent")}
    0px 0px 0.8rem;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 30rem;
  overflow: auto;
  transition: 0.25s ease-in;
`;

export const ScenarioCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const ScenarioCardClose = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const ScenarioCardContent = styled.div`
  font-size: 1.4rem;
  color: #7d868c;
  .btn-wrapper-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1.5rem;
    button {
      flex: 1;
    }
  }
  .error-show{
    display: block;
    width: 100%;
    color: red;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding-top: 1rem;
  }
`;

export const ScenarioInput = styled.input`
  border: 0.1rem solid #ccc;
  border-radius: 0.4rem;
  width: 100%;
  height: 4.5rem;
  outline: 0;
  padding: 1.5rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: #4d4d4f;
`;

export const ScenarioLabel = styled.label`
  position: relative;
  display: block;
  user-select: none;
  margin-bottom: 5rem;
  span {
    position: absolute;
    background: #fff;
    top: 0;
    color: #4d4d4f;
    display: inline-block;
    padding: 0.2rem;
    left: 1.3rem;
    transform: translateY(-50%);
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

export const SaveScenarioButton = styled.button`
  background: ${({ borderBtn }) => (borderBtn ? "#fff" : "#118acb")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ borderBtn }) => (borderBtn ? "#118acb" : "#fff")};
  border: 0.1rem solid #118acb;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.5rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 500;
  outline: 0;
`;

export const ScenarioList = styled.button`
  background: #fff;
  padding: 1rem;
  transition: 0.25s ease-in;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 2rem;
  font-size: 1.4rem;
  color: #4d4d4f;
  font-weight: 600;
  span {
    flex: 1;
  }
  i {
    display: inline-block;
    line-height: 0;
    position: relative;
    svg {
      width: 2rem;
    }
    &:before {
      content: attr(data-title);
      display: ${({ noTitle }) => (!noTitle ? "inline-flex" : "none")};
      background: rgb(77, 77, 79);
      border-radius: 0.2rem;
      position: absolute;
      min-height: 1.5rem;
      z-index: 1;
      font-style: normal;
      font-size: 1.2rem;
      font-weight: 500;
      right: 0px;
      top: -0.5rem;
      align-items: center;
      padding: 0.4rem 0.5rem;
      color: #fff;
      border-radius: 0.2rem;
      transform: translateY(-100%);
      opacity: 0;
      pointer-events: none;
      transition: 0.25s ease-in;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  &:hover {
    background: #ebf5fa;
  }
`;

export const ScenarioModal = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ show }) => (!show ? "hidden" : "visible")};
`;

export const ScenarionModalCard = styled.section`
  width: 50rem;
  min-height: 10rem;
  padding: 2.5rem;
  background: #fff;
  border-radius: 0.8rem;
`;
// End Scenario
export const RecoverablePopWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    padding: 2.5rem 2rem;
    max-width: 100%;
    margin: auto;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    color: #7d868c;
    margin-bottom: 2rem;
`
export const RecoverCloseBtn = styled.div`
    cursor: pointer;
    width: 3rem;
    i{
        display: inline-block;
        height: 3rem;
        width: 3rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
`
export const InfoIcon = styled.div``
export const RecoverPopCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p{
        color: #118acb;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0 2rem;
        line-height: 2rem;
    }
`
export const RightAlignTextWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    padding-bottom: 0.5rem;
`
export const ScndTabButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid #ebf5fa;
    -webkit-box-shadow: 0px 3px 12px rgba(21, 27, 38, 0.03);
    border-radius: 0.8rem;
    height: 6rem;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-left: 5rem;
    position: relative;
    ${TBRow}{
        justify-content: space-between;
    }
    ${TBLeftCol}{
        padding-right: 1rem;
        width: auto;
        .rmap-txt{
            padding-right: 0;
        }
    }
    ${TBRightCol}{
        padding-left: 1rem;
        width: auto;
    }
    ${RightAlignTextWrap}{
        flex-wrap: unset;
    }
`