/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  *  Modal ===> component from UI used to work as popup

**/
import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
//Onboarding component start
export const HomeTour = (props) => {
	//defining variables here
	const user = JSON.parse(localStorage.getItem("userData"));
	const name = user ? user.name : "";
	
	const firstSlideData = [
		{
			img: "home-step1.gif",
			title: `Hi ${name}, welcome to SmarterChains platform`,
			paragraph: [`We are the One Stop Shop for intelligently creating IR4.0 plant strategy and educate your manufacturing teams.We want to quickly show you around in this quick platform tour. You can skip at any time.`],
		},
		{
			img: "home-step2.gif",
			title: `Top global manufacturing companies trusts us ​`,
			paragraph: [`20% of the Top 25 Gartner Supply Chain and many Fortune 500 companies are SmarterChains Customers trust us `,`We provide a standarized and scalable platform and framework to collaboratively define their IR4.0 strategy and educate their teams`],
		},
		{
			img: "home-step3.svg",
			title: `Platform Products for Manufacturing Teams​`,
			paragraph: [`The platform provides your manufacturing teams the tools to:`,`<b>1. EDUCATE</b> - Train your manufacturing teams around IR4.0 solutions`, 
			`<b>2. PREPARE</b> - Self - Assess your plant's IR4.0 technological adoption and value potential with IR4.0 solutions .`,
			`<b>3. DEFINE</b> - Self - Define your plant's IR4.0 roadmap strategy and business case`,],
		},
		
	];
	const { isOnboarding } = props;
	const TotalFirstSlides = firstSlideData.length;
	const [fistSlideIndex, setFistSlideIndex] = useState(0);
	// function to handle first slide
	const HandleFirstSlide = (ev) => {
		if (ev === "prev" && fistSlideIndex > 0) {
			let tmpVal = fistSlideIndex - 1;
			setFistSlideIndex(tmpVal);
		} else if (ev === "next" && fistSlideIndex < TotalFirstSlides - 1) {
			let tmpVal = fistSlideIndex + 1;
			setFistSlideIndex(tmpVal);
		} else if (ev !== "next" && ev !== "prev") {
			setFistSlideIndex(ev);
		}
	};
	//function to close
	const HandleClose = () => {
		props.handleClose();
	};
	return (
		<Modal open={isOnboarding} className={`onboarding-modal bg-op-white`}>
			<>
				<div className="first-type-modal">
					{firstSlideData.map((item, index) => (
						/* card has been divided into
							button
							header-1
							content-1
							footer-1
						*/
						<div className="card-1" key={index} style={{ display: index !== fistSlideIndex ? "none" : "" }}>
							{/* close button */}
							<button className="close-btn" onClick={HandleClose}>
								<img src={require(`../../assets/img/common-img/close-icon-blue.svg`).default} alt="X" />
							</button>
							{/* this section has image */}
							<div className="header-1">
								<div className="div-img">
									<img src={require(`../../assets/img/platform-onboarding-tour/${item.img}`).default} alt="true" />
								</div>
							</div>
							{/* header-1 end */}
							{/*	this section has 
								title
								paragraph 
							*/}
							<div className="content-1">
								<h2 className="title-h1">{item.title}</h2>
								{item.paragraph.map(p=>( <p dangerouslySetInnerHTML={{__html:p}}></p>))}
							</div>
							{/* content-1 end */}
							{/* footer has
								dots section to navigate
								previous button
								next button
							*/}
							<div className="footer-1">
								<div className="dots-sec">
									<button onClick={() => HandleFirstSlide(0)} disabled={fistSlideIndex === 0 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(1)} disabled={fistSlideIndex === 1 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(2)} disabled={fistSlideIndex === 2 ? true : false}></button>
								</div>
								{fistSlideIndex === 0 && <button onClick={HandleClose} className="btn-blue-link">Skip</button>}
								{fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides - 1 && (
									<button onClick={() => HandleFirstSlide("prev")} className="btn-blue-link">Previous</button>
								)}
								{fistSlideIndex === TotalFirstSlides - 1 && <button onClick={HandleClose} className="btn-blue-link">No thanks</button>}
								{fistSlideIndex === 0 && (
									<button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
										Take tour
									</button>
								)}
								{fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides - 1 && (
									<button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
										Next
									</button>
								)}
								{fistSlideIndex === TotalFirstSlides - 1 && (
									<button className="btn-blue" onClick={()=> props.setCurrentTour(props.tourArray[1])}>
										Show me the  Products
									</button>
								)}
							</div>
							{/* footer-1 end */}
						</div>
					))}
				</div>
			</>
		</Modal>
	);
};
