import styled from "styled-components";

export const MainWrapper = styled.div`
   padding:2.9rem;
`

export const ChartDivWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(21, 27, 38, 0.03);
    border-radius: 8px;
    padding:20px 24px;
    margin-bottom:20px;
    @media(max-width: 991px){
      overflow:auto;
  }
`;
export const ChartTitle = styled.h2`
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #4D4D4F;
    padding-left:2.4rem;
    @media(max-width: 575px){
      padding-left:0rem;
  }
`
export const CartTitleWrap=styled.div`
   display:flex;
   gap:1.5rem;
   flex-wrap:wrap;
   align-items:center;
   margin-bottom: 2rem;
`;
export const ChartSubTtile =styled.div`
    color:#4D4D4F;
    font-size:1.4rem;
    position: relative;
    top: 0.2rem;
`
export const ChartDiv = styled.div`
@media(max-width: 740px){
    width:850px;
}
.percent-text{
    display:block;
    text-align:center;
    font-weight:700;
  }
  .ract-bars{
    cursor:pointer;
    &.NA-value{
      cursor:not-allowed;
      fill:#7D868C !important;
    }
  }
  .section-text{
    display:block;
    text-align:center;
    font-size:1.1rem;
  }
  .inactive{
    opacity:0.5;
  }
  .stroke-dasharray{
    stroke-dasharray: 5,5;
    stroke:#1487E3 !important;
  }
  .y-axis-percent-txt{
    opacity:0;
  }
  .y-axis-percent-txt.t-3,.y-axis-percent-txt.t-0,.y-axis-percent-txt.t-10{
    opacity:1 !important;
  }
  
`
export const PopupDiv = styled.div`
  position:relative;
  user-select:none;
`;
export const PopupTitle = styled.h2`
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: #4D4D4F;
        margin-bottom:0.6rem;
`;

export const PoupDetail = styled.p`
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #7D868C;
`;

export const ScoreRow = styled.div`
       display:flex;
       font-weight: 400;
       font-size: 18px;
       line-height: 22px;
       color: #4D4D4F;
       .score_title{
         padding:14px 0px;
         width:300px;
         align-items:center;
         display:inline-flex;
         gap:8px;
         @media (max-width: 740px) {
           padding-right:8px;
         }
         &:before{
            content:'';
            width:4px;
            height:22px; 
            background:#118ACB;
            display:inline-block;
        }
        &.green:before{
           background:#118ACB;
        }
       }
       .score_text{
        padding:14px 0px;
        width:100px;
        display:inline-flex;
        align-items:center;
       }
       &:not(:last-child){
            span{
                border-bottom:1px solid #F0F0F0;
            }
        }
        .view-link-wrap{
          align-items:center;
          display:none;
        }
      .view-course-btn{
            display: inline-block;
            border-radius: 0.4rem;
            background: #3D90DD;
            padding: 1rem 2rem;
            color: #FFF;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.8rem;
            letter-spacing: 0.028rem;
            border: 0;
        }
`;

export const ActionDiv = styled.div`
      position: absolute;
      top:0;
      right:0;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      gap:12px;
      display:none;
      i{
        display:inline-block;
        cursor:pointer;
        svg:hover{
            path{
                stroke:#139BD6;
            }
        }
      }

      .action-row{
        display:flex;
        gap:15px;
      }
`

export const ScoreTextDiv=styled.div`
   display:flex;
   flex-wrap:wrap;
   gap:1.5rem;
   color:var(--gray);
   font-size:1.6rem;
   margin-bottom:54px;
   font-style:italic;
   color:#4D4D4F;
   @media(max-width: 768px){
    flex-wrap: wrap;
    p{
      display: block;
      &.border{
        display: none;
      }
    }
   }
`;