import styled from 'styled-components';

const bgIcon=`<svg width="163" height="180" viewBox="0 0 163 180" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="143.87" y="0.140067" width="135.466" height="179.62" rx="11.9" transform="rotate(52.935 143.87 0.140067)" stroke="#E7EBF8" stroke-width="0.2"/>
<rect x="140.586" y="75.4202" width="75.4" height="75.4" rx="11.7" transform="rotate(52.935 140.586 75.4202)" stroke="#E7EBF8" stroke-width="0.6"/>
<rect x="138.961" y="40.2801" width="111.675" height="121.365" rx="11.8" transform="rotate(52.935 138.961 40.2801)" stroke="#E7EBF8" stroke-width="0.4"/>
</svg>
`

const svgBase64 = btoa(bgIcon);


export const MainWrapper = styled.section`
    padding: 3rem;
    display: flex;
    flex-direction: column;
`;
export const UpgradePopWrap = styled.div`
    background: #2C55A0;
    border: 0.06rem dashed #2C55A0;
    border-radius: 0.8rem;
    width: 29.2rem;
    position: fixed;
    right: 1rem;
    max-height: calc(100vh - 10rem);
    top: 9rem;
    overflow-y: auto;
    z-index: 99;
`
export const UpgradePop = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1.6rem;
    &::after {
        content: '';
        background-image: url("data:image/svg+xml;base64,${svgBase64}");
        background-size: contain;
        background-repeat: no-repeat;
        width: 13.5rem;
        background-position: bottom right;
        position: absolute;
        bottom: 3px;
        right: 0;
        height: 15rem;
        z-index: -1;
    }
`
export const UpgradePopHead = styled.div`
    height: 8rem;
    display: flex;
    align-items: center;
    position: relative;
    .asmt-img{
        height: 8rem;
        display: inline-block;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .head-txt{
        padding-left: 1.2rem;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #FFFFFF;
        display: inline-block;
    }
    button{
        display: block;
        height: 2rem;
        width: 2rem;
        position: absolute;
        right: 0.8rem;
        top: 0.8rem;
        .close-img{
            display: inline-block;
            height: 100%;
            width: 100%;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
    
`
export const UpgradePopBody = styled.div`
    padding-top: 2.2rem;
    flex: 1;
    .main-text{
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #FFFFFF;
        padding-bottom: 0.6rem;
        border-bottom: 0.06rem solid #E7EBF8;
    }
`
export const UpgradePopFooter = styled.div`
    background: #0D2C54;
    border-radius: 0px 0px 0.8rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.8rem;
    padding: 1.7rem 0;
    margin-left: -1.6rem;
    a{
        cursor: pointer;
        display: inline-block;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #FFFFFF;
    }
`
export const BodyTextWrap = styled.div`

    padding-top: 1.6rem;
    .upper-wrap{
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;
        .side-icon{
            margin-right: 0.8rem;
            display: inline-block;
            height: 1.6rem;
            width: 1.6rem;
            svg{
                height: 100%;
                width: 100%;
            }
        }
        span{
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.5rem;
            color: #FFFFFF;
        }
    }
    p{
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3rem;
        color: #FFFFFF;
    }
    .triangle-icon{
        display: inline-block;
    }
    &:nth-child(2){
        .triangle-icon{
            padding-left: 2rem;
        }
    }
    &:nth-child(3){
        .triangle-icon{
            padding-left: 2.8rem;
        }
    }
    &:nth-child(4){
        .triangle-icon{
            padding-left: 3.6rem;
        }
    }
    &:nth-child(5){
        .triangle-icon{
            display: none !important;
        }
    }
`
export const ButtonWrap = styled.div`
    margin: 1.6rem 0 3.2rem 0;
    text-align: center;
    .get-btn{
        background: #ffffff;
        border-color: transparent;
        box-shadow: 0px 0px 8px rgba(21, 27, 38, 0.12);
        border-radius: 6.2rem;
        padding: 0.8rem 1.6rem;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: center;
        color: #2C55A0;
        &:hover{
            background: linear-gradient(147deg, #118ACB 0%, #0473AE 100%);
            color: #ffffff;
        }
    }
`