/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * data from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * MaintenanceLossesPagePlantView ===> custom view component
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api

**/
import React from "react";
import serviceProvider from '../../services/axios';
import { Helmet } from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useEffect, useState } from 'react';
import { useHistory, Link, Redirect } from "react-router-dom";
import { MaintenanceLossesPagePlantView } from "./maintenance-losses-page-plant-view";
import { data } from "jquery";
//MaintenanceLossesPagePlantController start
export const MaintenanceLossesPagePlantController = (props) => {
  //defining variables here
  const segments = window.location.pathname.slice(1).split("/");
  const slug = segments[3];
  const sid = segments[4];
  const [results, setResults] = useState([]);
  const [refadd, setRefadd] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const group_id = user ? user.group_id : 0;
  var linepagename;
  const [unlockbutton, setUnlockbutton] = useState(false);
  if(sid==107){
    linepagename ="Maintenance Losses";
  }else if(sid==106){
    linepagename ="IT Losses";
  }else if(sid==102){
    linepagename ="Warehouse Losses";
  }else if(sid==104){
    linepagename ="EHS Losses";
  }else {
    linepagename ="Quality Losses";
  } 
  useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
			setRefadd(false);
      
    };
		search();
    
	}, [refadd,unlockbutton]);
  
 	if (results.length) {
		if(results[0].block == 1){
			var pp = "/not-found";
			return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		if(sid == 103 || sid == 101 || sid == 108){
			var pp = "/not-found";
			return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		
		if(results[0].unassign == 1){
			
			return <Redirect to="/user-unassign" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		
		if(results[0].is_locked == 1){
			if(group_id == 14){
				return <Redirect to={`/lossanalysis/section/${slug}`} />;
			}else{
				return <Redirect to={`/loss-analysis-admin/section/${slug}`} />;
				}
			return (
					<>
						<div></div>
					</>
			);
		}
		if(window.location.hash != '' && (results[0].isLocks == 1 || results[0].has_businesscase == 1)){
			var pp = "/not-found";
			return <Redirect to="/not-found" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		else{
			return (
				<>
					{/* Page Title */}
					<Helmet>
						<title>{linepagename} | SmarterChains</title>
						<meta name="description" content="Loss Analysis" />
					</Helmet>
					<MaintenanceLossesPagePlantView refadd={refadd} setRefadd={setRefadd} setUnlockbutton={setUnlockbutton}  slug={slug} linepagename={linepagename} quesResult={results} {...props} />
				</>
			);
		}	
	}
	else{
		return (
			<>
				<div></div>
			</>
		);
	
	}

} 

