/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $, { isEmptyObject } from jquery ===> component from jquery to use jquery for the page.
  * AssignSectionPopup ===> component for assign user.
  * Helmet ===> for add specific title & CSS for the page.
  * .scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * LAWalkthroughQuesView ===> walkthrough view component
  * LossSupportView ===> view component of loss support

**/

import React, { useState, useEffect, useRef } from 'react';
import { Link,Redirect } from 'react-router-dom';
import $, { isEmptyObject } from 'jquery';
// import { serialize } from "react-serialize";
import serviceProvider from '../../services/axios'
import { Helmet } from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { array } from 'prop-types';
import { AssignSectionPopup } from "../loss-analysis-admin-page/loss-assing-popup";
import './la-ques-new.scss';
import { LAWalkthroughQuesView } from './la-questionnaires-walkthrough'
import { LossSupportView } from '../la-support/la-support-view';
//invite user component
import { InviteUserList } from '../invite-user-list-component/InviteUserList';
//Three dots component
import { ThreeDotsComponent } from '../three-dots-component/ThreeDotsComponent';
// import how to use popup component
import { HowToUseCtrl } from '../lossanalysis-how-to-use-popup/HowToUseCtrl';
//LossanalysisPlantQuesView component start
export const LossanalysisPlantQuesView = (props) => {
    //defining variables here
    const quesDatas = props.quesResult;
    if (!quesDatas.length) {
        return null;
    }
    const quesData = props.quesResult[0];
    const pid = quesData.pid;
    const { value } = props;
    // Modal Show
    const [count, setCount] = useState(0);
    const [isShown, setIsShown] = useState(false);
    const [isAssignDdl, setIsAssignDdl] = useState(false);
    const [isSubmitPopup, setIsSubmitPopup] = useState(false);
    const [isRemovePopup, setIsRemovePopup] = useState(false);
    const [isRightPopup, setIsRightPopup] = useState(0);
    const [isAddCol, setIsAddCol] = useState(false);
    const [delItem, setDelItem] = useState(null);
    const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
    const [tmpAssignUser, setTmpAssignUser] = useState("");
    const [assignedUsers, setAssignedUsers] = useState({
        inviteData: [],
    });
    const [cate, setCate] = useState();
    const [secname, setSecName] = useState();
    const [hashvalue, setHashvalue] = useState(window.location.hash);
    // const APDList = quesData.questions;
    const groupid = (localStorage.getItem("group_id"));
	
    // Unlock section popup start
    const [isUnlockPopup, setIsUnlockPopup] = useState(false);
    // end
    // Loss support variables start
    const [isLossSupport, setIsLossSupport] = useState(false);
    const [isTicket, setIsTicket] = useState(true);
    //  end
    // Data not availble on line
    const [isLineFreeze, setIsLineFreeze] = useState(false);
    //end
    if (quesData.ans != []) {
        var answer = quesData.ans;
    }
    if (quesData.userans != [] && quesData.userans != "[]") {
        var useranswer = quesData.userans;
    }
    let newques1 = quesData.userquestions;
    let newques = quesData.questions;
    const progress = quesData.Secprogress;
    const islock = quesData.isLocks;
    const invitedmeblist = quesData.sectioninvitedmeber;
    const APDList1 = newques.slice(2);
    const APDList2 = quesData.userquestions;
    const [APDList, setAPDList] = useState(APDList1);
    const [ans, setans] = useState([]);
    let [tmArr, settmArr] = useState([]);
    let [totalval, setTotalVal] = useState(0);
    const [totalper1, setTotalPer1] = useState(0);
    const [forcalArr, setForcalArr] = useState([]);
    const [rightdata, setRightData] = useState({});
    const [activeError, setActiveError] = useState(false);
    const productionLine1 = quesData.bcaselines;
    const [delPop, setDelPop] = useState(false);
    const [isEditComment, setIsEditComment] = useState(null);
    const [tmpCmntData, setTmpCmntData] = useState({});
    //variable for submit temproary data
    const [txtCmntInput, setTxtCmntInput] = useState('');
    // functions and variables for comment start
    const [isCommentCicked, setIsCommentCicked] = useState(false);
    const [activeSlide, setActiveSlide] = useState(1);
    const [comment, setComment] = useState([])
    const [isQuesType, setIsQuesType] = useState(1);
    const [sendquestion, setSendquestion] = useState(1);
    /* ThreeDotsComponent varibale */
    const [isThreeDotmenu, setIsThreeDotmenu] = useState(false);
    const [isAvailablePopup, setIsAvailablePopup] = useState(false);
    const [isNotAvailablePopup, setIsNotAvailablePopup] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [tmpSingleId, setTmpSingleId] = useState(false);
    const [singleRowFreezArray, setSingleRowFreezArray] = useState([]);
    /*ThreeDotsComponent varibale end*/

    /*---------- ThreeDotsComponent function start -------*/

    // function to show and hide menu-data-available popup and menu-data-not-available popup  
    const handleAvailabilityPopups = (val, type, singleId) => {
        setIsThreeDotmenu(false);
        setIsLineFreeze(false);
        if (val == false) {
            setIsAvailablePopup(true);
        } else if (val == true) {
            setIsNotAvailablePopup(true);
        }
        if (type == 'single') {
            setTmpSingleId(singleId);
        }
		
		
		let mn = 0;
		let mnp = 0;
		productionLine1.forEach((el) => {
				
			if(!$('.inputproductionline-'+el.id).find('.input').hasClass("freeze-input")) {
						mn = mn+parseInt(el.module);
						mnp = mnp+$('.inputproductionline-'+el.id).find('.input').val()*el.module; 
			}
				
		});
		
		let tt = (Math.round(mnp/mn));
		if(isNaN(tt)){ tt=0; }
		setTotalVal(tt);
				
		
		
		
		
    }
    // function to reset data-available popup
    const resetAvailablePopup = () => {
        setIsAvailablePopup(false);
        setTmpSingleId(false);
    }
    // function to reset data-not-available popup
    const resetNotAvailablePopup = () => {
        setIsNotAvailablePopup(false);
        setTmpSingleId(false);
    }
    // function to make data  not available 
    const setIsAvailableFalse = () => {
        if (!tmpSingleId) {
            setIsAvailable(false);
            setSingleRowFreezArray([]);
        } else {
            if (isAvailable) {
                let tmpFilterArr = productionLine1.filter(el => el.id != tmpSingleId);
                let tmpArr = [];
                tmpFilterArr.forEach((el) => {
                    tmpArr.push(el.id);
                });
                setSingleRowFreezArray(tmpArr);
                setIsAvailable(false);
            } else {
                let tmpArr = singleRowFreezArray.filter(id => id != tmpSingleId);
                setSingleRowFreezArray(tmpArr);
            }
        }
		resetAvailablePopup();
		//send on server api start
		let lines_na=0;
		if(tmpSingleId != false){
			lines_na=tmpSingleId;
		}
		serviceProvider.post("/businesscaseplantloss?type=dataavailble",JSON.stringify({uid: usid, pid: quesData.pid, sid: sid, ssid: ssid,lines_na:lines_na,all_lines:productionLine1}),true).then((response) => {
                    props.setRefadd(true);
        });
		//send on server api end
    }
    // function to make data available 
    const setIsAvailableTrue = () => {
		
        if (!tmpSingleId) {
            let tmpArr = [];
            productionLine1.forEach((el) => {
                tmpArr.push(el.id);
            });
            setSingleRowFreezArray(tmpArr);
            setIsAvailable(true);
        } else {
            let tmpArr = [...singleRowFreezArray];
            tmpArr.push(tmpSingleId);
            setSingleRowFreezArray(tmpArr);
            if (productionLine1.length === tmpArr.length) {
                setIsAvailable(true);
            }
        }
		 resetNotAvailablePopup();
		//send on server api start
		let lines_na=0;
		if(tmpSingleId != false){
			lines_na=tmpSingleId;
			$('.inputproductionline-'+tmpSingleId).find('.la-ques-input').val('');
			if(ssid != 5){ 
			    let mn = 0;
				let mnp = 0;
			 	let minus = $('.inputproductionline-'+tmpSingleId).find('.input').val(); 
            	$('.inputproductionline-'+tmpSingleId).find('.input').val(''); 
				productionLine1.forEach((el) => {
                		if(el.id == tmpSingleId){
							minus = minus*el.module;
						}else{
							 if (!$('.inputproductionline-'+el.id).find('.input').hasClass("freeze-input")) {
   								mn = mn+parseInt(el.module);
							    mnp = mnp+$('.inputproductionline-'+el.id).find('.input').val()*el.module; 
								}
							}
            	});
				
				let tt = (Math.round(mnp/mn));
				if(isNaN(tt)){ tt=0; }
				setTotalVal(tt);
				
			}
			
		}else{
			$('.la-ques-input').val(''); 
			if(ssid != 5){
				$('.input').val('');
			}
			setTotalVal(0);
		}
		
        serviceProvider.post("/businesscaseplantloss?type=datanotavailble",JSON.stringify({uid: usid, pid: quesData.pid, sid: sid, ssid: ssid,lines_na:lines_na,all_lines:productionLine1}),true).then((response) => {
                     props.setRefadd(true);
					
        });
		//send on server api end
		
		
    }

    //function to make single data 

     const setIsSingleAvailableFalse = (tmpSingleId) => {
		
        if (!tmpSingleId) {
            setIsAvailable(false);
            setSingleRowFreezArray([]);
        } else {
            if (isAvailable) {
                let tmpFilterArr = productionLine1.filter(el => el.id != tmpSingleId);
                let tmpArr = [];
                tmpFilterArr.forEach((el) => {
                    tmpArr.push(el.id);
                });
                setSingleRowFreezArray(tmpArr);
                setIsAvailable(false);
            } else {
                let tmpArr = singleRowFreezArray.filter(id => id != tmpSingleId);
                setSingleRowFreezArray(tmpArr);
            }
        }
        resetAvailablePopup();
        setIsLineFreeze(false);
        //send on server api start
        let lines_na=0;
        if(tmpSingleId != false){
            lines_na=tmpSingleId;
        }
		
		
		
		
		
        serviceProvider.post("/businesscaseplantloss?type=dataavailble",JSON.stringify({uid: usid, pid: quesData.pid, sid: sid, ssid: ssid,lines_na:lines_na,all_lines:productionLine1}),true).then((response) => {
                    props.setRefadd(true);
					
					let mn = 0;
		let mnp = 0;
					productionLine1.forEach((el) => {
				
			if(!$('.inputproductionline-'+el.id).find('.input').hasClass("freeze-input")) {
						mn = mn+parseInt(el.module);
						mnp = mnp+$('.inputproductionline-'+el.id).find('.input').val()*el.module; 
			}
				
		});
		
		let tt = (Math.round(mnp/mn));
		if(isNaN(tt)){ tt=0; }
		setTotalVal(tt);
        });
        //send on server api end
    }
    
    const setIsSingleAvailableTrue = (tmpSingleId) => {
        if (!tmpSingleId) {
            let tmpArr = [];
            productionLine1.forEach((el) => {
                tmpArr.push(el.id);
            });
            setSingleRowFreezArray(tmpArr);
            setIsAvailable(true);
        } else {
            let tmpArr = [...singleRowFreezArray];
            tmpArr.push(tmpSingleId);
            setSingleRowFreezArray(tmpArr);
            if (productionLine1.length === tmpArr.length) {
                setIsAvailable(true);
            }
        }
         resetNotAvailablePopup();
         setIsLineFreeze(false);
        //send on server api start
        let lines_na=0;
        if(tmpSingleId != false){
            lines_na=tmpSingleId;
            $('.inputproductionline-'+tmpSingleId).find('.la-ques-input').val('');
            if(ssid != 5){ 
			 let mn = 0;
				let mnp = 0;
			    let minus = $('.inputproductionline-'+tmpSingleId).find('.input').val(); 
            	$('.inputproductionline-'+tmpSingleId).find('.input').val('');
				productionLine1.forEach((el) => {
                		if(el.id == tmpSingleId){
							minus = minus*el.module;
						}else{
							if (!$('.inputproductionline-'+el.id).find('.input').hasClass("freeze-input")) {
   								mn = mn+parseInt(el.module);
							    mnp = mnp+$('.inputproductionline-'+el.id).find('.input').val()*el.module; 
							}	  
						}
						
            	}); 
				
				let tt = (Math.round(mnp/mn));
				if(isNaN(tt)){ tt=0; }
				setTotalVal(tt);
				
            }
            
        }else{
            $('.la-ques-input').val(''); 
            if(ssid != 5){
                $('.input').val('');
            }
           setTotalVal(0);
        }
        
        serviceProvider.post("/businesscaseplantloss?type=datanotavailble",JSON.stringify({uid: usid, pid: quesData.pid, sid: sid, ssid: ssid,lines_na:lines_na,all_lines:productionLine1}),true).then((response) => {
                     colorChange();props.setRefadd(true);
                     
        });
        //send on server api end
    } 
    //single line freez function
    const handleSingleRowFreezPopup = (id) => {
        if (isLineFreeze != id) {
            setIsLineFreeze(id);
        } else {
            setIsLineFreeze(false);
        }
    }
    /*------------- ThreeDotsComponent ended----------*/


    // map for invited member start
    let s1 = [];
    let s1name = [];
    let s1AavatarColor = [];
    invitedmeblist.map((item, index) => {
        // console.log(quesData.section.id);
        if (item.invited_sub_category == quesData.subsection.id) {

            if (item.name != null) {
                s1.push(`${item.name[0]}${item.last_name[0]}`);
                s1name.push(`${item.name} ${item.last_name}`);
            } else {
                s1.push(`${item.email[0]}`);
                s1name.push(`${item.email}`);
            }
            s1AavatarColor.push(item.avcolor);
        }
    })
    // Function for total calculation
    const totalCalc = (arr) => {
        // console.log(arr);

        let newArr = arr[0];
        settmArr(newArr);
        let valAr = productionLine1.length;
        let sum = 0;
		let summodule = 0;
        for (let i = 0; i < valAr; i++) {
			if (!$('.inputproductionline-'+productionLine1[i].id).find('.input').hasClass("freeze-input")) {
										summodule = summodule+parseInt(productionLine1[i].module);
				}
			
            if (newArr[i] != "") {
                let nnew1;
                if (newArr[i] > 168) {
                    nnew1 = 168 * productionLine1[i].module;
                } else {
                    nnew1 = newArr[i] * productionLine1[i].module;
                }
                sum = sum + parseInt(nnew1);
            }

        }
		if(summodule == 0){ summodule=1;} 
		setTotalVal(Math.round(sum/summodule));
        
    }
    
    const [messge, setMassge] = useState('');
    // scnv-1292
    const [tmpEditData, setTmpEditData] = useState('');
    const [isEditdata, setIsEditdata] = useState(false);
    // Function to close all dropdown list
    const closdeAllDdl = () => {
        let dllList = document.querySelectorAll('.del-btn.three-dot-btn');
        dllList.forEach((item, index) => {
            item.classList.remove('active');
        });
    }
    // Function to edit dropdown list
    const handleEditDdl = (e, index) => {
        closdeAllDdl();
        if (e.currentTarget.parentElement.classList.contains('active')) {
            e.currentTarget.parentElement.classList.remove('active');

        } else {
            e.currentTarget.parentElement.classList.add('active');

        }
    }
    //Function to edit data
    const handleEditdata = (data, index) => {

        setIsEditdata(index);
        if (isEditdata > -1) {
            closdeAllDdl();
            let inputAdd = document.querySelector(`#editListInput${index}`);
            inputAdd.value = data.question;
            setTmpEditData({
                data,
                index
            });
        }
    }
    //scnv-1292 end
    //Function to handle right section
    const HandleRightSec = async (e, val, type, item = 0) => {
        e.preventDefault();

        setComment([]);
        const apiUrl = `/businesscaseplantloss?param=commentshow&qid=${item?.id}&uid=${quesData.uid}&pid=${quesData.pid}`;
        const { data } = await serviceProvider.get(apiUrl, true, {});

        if (!isEmptyObject(data)) {
            let tempDataArr = []

            data.forEach((item) => {
                let fullDateCreate = new Date(item.datecreated);
                let fullDateEdit = new Date(item.dateupdated);
                let tmpObjArrD = {
                    id: item.id,
                    uid: item.user_id,
                    cirName: item.usersn,
                    comment: item.comment,
                    editedDate: `${fullDateEdit.getDate()}/${fullDateEdit.getMonth()}/${fullDateEdit.getFullYear().toString().substr(-2)}`,
                    editedTime: fullDateEdit.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                    commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth()}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
                    commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                    isEdited: item.is_edited,
                }
                tempDataArr.push(tmpObjArrD);
            })

            setComment(tempDataArr);
        }
        setIsQuesType(type);
        setSendquestion(item?.question);
        if (isRightPopup === val) {
            setIsRightPopup(null);
        }
        else {

            setRightData({ name: item?.question, des: item?.question_description, qid: item?.id })
            setIsRightPopup(val);

            setIsLossSupport(false);
        }

    }
    // Function to handle right section close
    const HandleRightSecClose = (e, val) => {
        e.preventDefault();
        setIsRightPopup(null);
        setIsLossSupport(false);

    }
    const segments = window.location.pathname.slice(1).split("/");
    const slug = segments[2];
    const sid = segments[3];
    const ssid = segments[4];
    const [results, setResults] = useState([]);
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    const [allqueval, setAllqueval] = useState([]);
    const [allqueobj, setAllqueobj] = useState({});
    const [sumColArr, setSumColArr] = useState([]);
    const [inputcolor, setInputcolor] = useState('');
    const totalsubmit = sumColArr.reduce((a, b = 0) => { return a + b }, 0);
    let checktotalforsub = 0;
    productionLine1.map((item, index) => {
        if (item.p == 100) {
            checktotalforsub = checktotalforsub + item.p;
        }
		
    })
	if(checktotalforsub !=0){
	checktotalforsub = checktotalforsub+(quesData.lines_na.length*100);
	}
    checktotalforsub = (checktotalforsub / productionLine1.length);
    const edithandle = (e) => {
        setIsAssignSectionPopup(true);
        setCate(sid);
        setSecName(props.pname);

    }

    const sumofPercen1 = (Arr1) => {
        let neArray = Object.values(Arr1);

        let valAc = answer.length;
        let valAr = productionLine1.length;
        let ttarr = [];
        let nAA = [];
        productionLine1.map((item, index) => {
            ttarr.push(index);
        })
        for (let i = 0; i < valAr; i++) {
            let sum = 0;
            let nA = [0];
            for (let j = 2; j < valAc; j++) {

                if (neArray[j][i] != "") {
                    let nnew = neArray[j][i].slice(0, -1);
                    if (i == ttarr[i]) {
                        if (isNaN(parseInt(nnew))) {
                            nnew = 0;
                        }
                        sum = sum + parseInt(nnew);

                        nA.push(nnew);
                    }

                }
            }
            nAA.push(sum);
        }
        setSumColArr(nAA);
    }

    const sumofPercen = (Arr) => {
        let neArray = Arr;
        let valAc = answer.length;
        let valAr = productionLine1.length;

        let ttarr = [];
        let nAA = [];
        productionLine1.map((item, index) => {
            ttarr.push(index);

        })
        for (let i = 0; i < valAr; i++) {
            let sum = 0;
            for (let j = 2; j < valAc; j++) {
                if (typeof neArray[j][i] != undefined && !neArray[j][i] == "") {
                    if (i == ttarr[i]) {
                        let nnew = neArray[j][i].slice(0, -1);
                        sum = sum + parseInt(nnew);
                    }
                }
            }
            nAA.push(sum);

        }

        setSumColArr(nAA);
    }
    //sum of percentage end

    const colorChange = () => {
		let newArr = [];
		
        productionLine1.map((item, index) => {
            if (tmArr[index] != '' && tmArr[index] != 'undefined') {
                newArr.push(true);
            }

        })
		let count_0 = 0;
		$('.input').each(function(){
    		if($(this).val() != ''){
				count_0++;
			}
		});
		let count_100 = 0;
		let total = 0;
		$('.la-ques-input').each(function(){
    		if($(this).val() != ''){
				total = total+parseInt($(this).val());
			}
		});
		if((total/count_0) == 100){
			count_100=count_0;
		}
        if (ssid != 5) {
			
			if((count_100 == count_0 ) && count_0 !=0 && checktotalforsub == 100 ){
				setInputcolor('green');
            } else if (!count_0) {
                setInputcolor('brg');
            } else {
                setInputcolor('blue');
            }
        } else {
            if (totalMW > 0 && checktotalforsub != 100) {
                setInputcolor('blue');
            }
            else if (totalMW > 0 && checktotalforsub == 100) {
                setInputcolor('green');
            } else if(checktotalforsub == 100){
				setInputcolor('green');
				console.log(checktotalforsub);
				}else{
					console.log(inputcolor);
					setInputcolor('brg');
					}
			
			
        }
       
	 
	   

    }
    const onKeyVal = (e) => {

        var ch = String.fromCharCode(e.which);
        // console.log(ch)
        if (!(/[0-9]/.test(ch))) {
            e.preventDefault();

        }
    }

    const maxNumber = (e) => {
        let val1 = e.target.value;
        if (ssid != 5) {
            if (val1 > 168) {
                e.target.value = 168;
            } else {
                e.target.value = val1;
            }
            submitAns(e);
        }

    }

    const changeval = (e) => {

        let val = e.target.value;

        let val1 = val.split('%');
        // console.log(val1);

        if (val1[0]) {
            e.target.value = val1[0];
        }

    }

    const submitAnsplus = (e) => {
        e.preventDefault();
        serviceProvider
            .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
            .then((res) => {
                // console.log(res);
            })
        if (e.type == 'blur') {
            let val1 = parseInt(e.target.value);
            let val;

            if (val1 || val1 == 0) {
                val = $(e.target).val(val1 + '%');

            }
            else if (isNaN(val1)) {
                val = $(e.target).val();
            }
            submitAns(e);
        }
    }
    let inpval;
    const submitAns = (e, sigid, ql) => {

        e.preventDefault();
        serviceProvider
            .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
            .then((res) => {
                // console.log(res);
            })

        let tempObj = {};
        let temArray1 = [];

        newques.map((item, index) => {

            let name = `ques` + item.id;

            const f1 = document.getElementById("f1");

            const data = new FormData(f1).getAll(name);
            // console.log(data);

            tempObj = { ...tempObj, [name]: data }

            // if(inpval!=''){


            if (item.id == sigid) {
                productionLine1.forEach((item2, index2) => {
                    if (ql == index2) {
                        inpval = e.target.value;

                        temArray1.push(data);

                        totalCalc(temArray1);

                    }
                })
            }
            // }
        })
        setAllqueobj(tempObj);
        if (temArray1 != undefined) {
            setAllqueval(temArray1);
        }

    }

    const Finalsubmit = (e) => {

        serviceProvider
            .post(
                "/businesscaseplantloss?type=lockanswer",
                JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, allqueobj }, true)
            )
            .then((response) => {
                location.reload();

            });

    }
    // end submit answer
    const HandleDelBtnEvent = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        setDelItem(item);
        setIsRemovePopup(true);
        //scnv-1292
        closdeAllDdl();
        //scnv-1292 end
    }

    var totalMW = 0;
    productionLine1.map((item, i) => {
        if (quesData.materialwaste[item.id] != 0) {
            totalMW = parseInt(parseInt(totalMW) + parseInt(quesData.materialwaste[item.id]));
            item.linevalue = quesData.materialwaste[item.id];

        }
    });
    if (isNaN(totalMW)) {
        totalMW = 0;
    }
    const AddNewsubCategory = (e) => {

        var question = document.getElementById('AddNewsubCategory').value;
        if (question != '') {
            serviceProvider
                .post(
                    "/businesscaseplantloss?type=savequestion",
                    JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, savequestion: question, qlayout: 1, qtype: 2, qtab: 0, allqueobj }, true)
                )
                .then((response) => {
                    props.setRefadd(true);

                });
            serviceProvider
                .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
                .then((res) => {

                })

        }
    }
    //update user question
    const updateUserQue = (updateques, newqid) => {

        // if (question != '') {
        serviceProvider
            .post(
                "/businesscaseplantloss?type=edituserquestion",
                JSON.stringify({ slug: slug, nqid: newqid, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, savequestion: updateques, qlayout: 1, qtype: 2, qtab: 0, allqueobj })
            )
            .then((response) => {


            });
    }
    // delet user question
    const deleteUserQue = (newqid) => {

        serviceProvider
            .post(
                "/businesscaseplantloss?type=deleteuserquestion",
                JSON.stringify({ slug: slug, nqid: newqid, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid }),
                true
            )
            .then((response) => {

            });
    }
    // Fixed
    useEffect(() => {
        // Fixed Top Js start
        $('.la-scroll-1621').scroll(function (event) {
            var scroll = $('.la-scroll-1621').scrollTop();
            if (scroll > 0) {
                $('.fixed-row-column').addClass('fixed-top');
            } else {
                $('.fixed-row-column').removeClass('fixed-top');
            }
        });

        let scrollDiv = document.querySelector('.la-scroll-1621');
        let scrollLeftDiv = document.querySelector('.la-scroll-left-1621');
        let timeOut = '';
        scrollDiv.onscroll = () => {
            clearTimeout(timeOut)
            let fixedTopDiv = document.querySelectorAll('.fixed-row-column');
            fixedTopDiv[0].classList.add('opacity-0');
            fixedTopDiv[1].classList.add('opacity-0');
            fixedTopDiv.forEach((item, index) => {
                item.style.top = scrollDiv.scrollTop + 'px';
            })
            timeOut = setTimeout(() => {
                fixedTopDiv[0].classList.remove('opacity-0');
                fixedTopDiv[1].classList.remove('opacity-0');
            }, 66);

            scrollLeftDiv.scrollTop = scrollDiv.scrollTop;
        }
        // Fixed Top Js end
        document.addEventListener('click', (e) => {
            if (!e.target.closest(".la-assign-cir-wrapper")) {
                setIsAssignDdl(false);
            }
            if (!e.target.closest('.fixed-row-column .table-heading')) {
                setIsLineFreeze(false);
            }
        })
        //map for answer valuestart
        let tempval = [];
		let tmpArr = [];
		let tmpArrna =[];
        answer.map((item, index) => {
            if (item.length != 0) {
                tempval.push(JSON.parse(item.answer));
            }
        })
		
		let summodule=0;
		if(quesData.is_naavaliable){ 
				let na=[...quesData.is_naavaliable];
				 tmpArrna = [...singleRowFreezArray];
				  na.forEach((item)=>{
					tmpArrna.push(parseInt(item.lines_na));
				});
				tmpArrna = tmpArrna.filter(val => val !== 0);
			if(tmpArrna.length == productionLine1.lenght){
				
				 setTotalVal('N/A');
			}	
		}
        if (tempval.length > 0) {
            setans(tempval);
			let summodule=0;
			if(quesData.is_naavaliable){
					let is_naavaliable=[...quesData.is_naavaliable];
					 tmpArr = [...singleRowFreezArray];
					is_naavaliable.forEach((item)=>{
						tmpArr.push(parseInt(item.lines_na));
					});
         	}
			
			
			
            tempval.map((item1, index1) => {
                if (index1 == 0) {
                    var vv = item1;
                    let tmArr1 = [];
                    vv.map((item1, index) => {
                            productionLine1.map((pitem, pindex) => {
									if (index == pindex) { 
										if (tmpArr.includes(parseInt(pitem.id))) { 
											//summodule = summodule+parseInt(pitem.module);
										}else{
											summodule = summodule+parseInt(pitem.module);
											}
										if (item1 != "") {
											tmArr1.push(parseInt(item1 * pitem.module));
										}
									}
                            })
                    })
					
                    var total = tmArr1.reduce((a, b = 0) => {
                        return a + b
                    }, 0);
					
					
					if(summodule == 0){ summodule=1;}
					setTotalVal(Math.round(total/summodule));
                    settmArr(tmArr1);

                }

            });

        }


        return async () => {
            try {
                if (Array.isArray(props.quesResult) && props.quesResult.length) {

                    const { uid, pid: plant_id, has_sub_section, subsection, section } = props.quesResult[0];
                    let data = { uid, section_id: section['id'], type: 2, status: 0, plant_id }

                    if (section['has_sub_section'] === "1") {
                        data['sub_section_id'] = subsection['id'];
                    }
                    else {
                        data['sub_section_id'] = 0
                    }
                    await serviceProvider.post('/sectionlock', data, true);
                }
            } catch (err) {
                console.error(err)
            }
        }

    }, [])
    useEffect(() => {
        colorChange();
    }, [totalval, checktotalforsub, sumColArr])
    
    ////useEffect by variable 
    useEffect(() => {

        var userques = {};

        var valP = "";
        //$(".alluques").each(function () {
        APDList2.map((item, index) => {
            var nm = [];
            //  alert(item.id)
            $(".ques" + item.id).each(function () {
                // nm = $("input[name=ques"+item.id+"]").attr("name");
                valP = $(this).val();
                valP = valP.replace("%", "")
                if (valP != '') {
                    nm.push(valP);
                } else {
                    nm.push("");
                }
            });
            userques["ques" + item.id] = nm;
        });
        // console.log("useeff2")
        if (!isEmptyObject(allqueobj)) {
            serviceProvider
                .post(
                    "/businesscaseplantloss?type=saveuseranswer",
                    JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, userques: userques, allqueobj }),
                    true
                )
                .then((response) => {
                    props.setRefadd(true);
                });
        }                                

    }, [allqueobj, inpval])



    const fixColumn = () => {
        let parentLeft = document.querySelector('.la-bottom-left-col');
        let parentRight = document.querySelector('.la-bottom-right-col');
        let leftColumn = document.querySelectorAll('.la-bottom-left-col .la-common-left-col');
        let rightColumn = document.querySelectorAll('.la-bottom-right-col .la-right-input-row');
        leftColumn.forEach((item, index) => {
            let itemHeight = item.offsetHeight;
            if (rightColumn[index]) {
                rightColumn[index].style.minHeight = itemHeight + "px";
            }
        });
        let fixedLeftColumn = document.querySelectorAll('.la-bottom-left-col .fixed-row-column.left');
        let fixedRightColumn = document.querySelectorAll('.la-bottom-right-col .fixed-row-column.rgt');
        fixedRightColumn.forEach((item, index) => {
            let itemHeight = item.offsetHeight;
            fixedLeftColumn[index].style.minHeight = itemHeight + "px";
            parentLeft.style.paddingTop = itemHeight + "px";
            parentRight.style.paddingTop = itemHeight + "px";
        });
    }
    // const scrollBottomAfterAdd = () => {
    //     const divElement = document.querySelector('.la-ques-wrapper .la-ques-bottom-wrapper');
    //     let scrollDiv = document.querySelector('.la-scroll-1621');
    //     document.querySelector('#AddNewsubCategory').focus();

    //     if (scrollDiv) {
    //         scrollDiv.scrollTop = scrollDiv.scrollHeight;
    //     }
    // };
    const tChange = (e) => {
        if (e.target.value != '') {
            setActiveError(false);
        } else {
            setActiveError(true);
        }
    }

    //scnv-1292
    const AddLsit = () => {
        let e = document.querySelector('#AddNewsubCategory');
        if (e.value.length > 0) {
            AddNewsubCategory(); // for add userquestion save
            e.value = '';
            scrollBottomAfterAdd();
            setIsAddCol(false);

        } else {
            setActiveError(true);
        }
    }
    const EditList = () => {
        //alert(tmpEditData.index);
        let e = document.querySelector(`#editListInput${tmpEditData.index}`);
        if (e.value.length > 0) {
            let name = e.value;
            let tmpArr = [...APDList2];
            tmpArr[tmpEditData.index] = {
                ...tmpArr[tmpEditData.index],
                question: name
            };
            //setAPDList(tmpArr);
            updateUserQue(name, tmpEditData.data.id);
            e.value = '';
            setTmpEditData('');
            setIsEditdata(false)
            props.setRefadd(true);
        }
    }

    const RemoveList = () => {
        let index = delItem.index;
        deleteUserQue(delItem.qid);
        props.setRefadd(true);
    }

    // New changes of questionniare page start
    //popup changes start

    const HandleNotePop = () => {
        let temp = document.querySelector('.la-card-wrapper');
        if (temp.classList.contains('active')) {
            temp.classList.remove('active');
        }
        else {
            temp.classList.add('active');
        }
    }
    const removeNotePop = () => {
        let tempr = document.querySelector('.la-card-wrapper');
        tempr.classList.remove('active');
        if (window.innerWidth < 1201) {
            tempr.classList.add('active');
        }
    }

    // const handleSubmitBtnPos = () => {
    //     let lftDiv = document.querySelector('.la-bottom-left-col');
    //     let rgtDiv = document.querySelector('.la-bottom-right-col .la-ques-table-col');
    //     let whole = document.querySelector('.la-ques-bottom-wrapper');
    //     let bothSecWidth = lftDiv.offsetWidth + ((rgtDiv.offsetWidth + 12) * productionLine1.length);
    //     let btnWrap = document.querySelector('.la-ques-button-group');

    //     if (bothSecWidth <= window.outerWidth) {
    //         btnWrap.style.width = bothSecWidth + 'px';

    //     }
    // }
     const lineClamp = () => {
        let lftDiv = document.querySelectorAll('.line-name-popwrap h3');
        let addClass = document.querySelectorAll('.line-namepopup')
        for (let i = 0; i < lftDiv.length; i++) {
            if (lftDiv[i]?.offsetHeight < lftDiv[i]?.scrollHeight) {
                addClass[i]?.classList.add('active');
            }
          }
    }
    // Popup Functionality Start 10-11-2021
    //popup show
    const ShowDelPopup = (data, ind) => {
        setTmpCmntData({
            ...data, index: ind
        })
        setDelPop(!delPop);
    }
    //delete functionality start 
    const deletData = () => {
        const tmpArr = comment.filter((item, index) => item.id !== tmpCmntData.id);
        serviceProvider
            .post(
                "/businesscaseplantloss?type=deletecomment",
                JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, qid: rightdata?.qid }),
                true
            )
            .then((response) => {
                // console.log(response);

            });
        setComment(tmpArr);
        setDelPop(false);
        setTmpCmntData({});
    }
    const editComntData = (data, ind) => {
        setTmpCmntData({
            ...data, index: ind
        });
        let tmpTextArea = document.getElementById(`comment-editTxtarea-${data.id}`);
        tmpTextArea.value = data.comment;
        setIsEditComment(data.id);

    }

    const handleTextAreaOnChange = (e) => {
        let val = e.target.value;
        setTxtCmntInput(val);
    }
    // Save submit data
    const saveCmntData = () => {

        let tmpTextArea = document.getElementById('addComment');
        let tmpCmntArr = [...comment];
        let fullDateCreate = new Date();
        // console.log(fullDateCreate.getMonth()+1);
        let tmpObj = {
            id: comment.length + 1,
            uid: usid,
            cirName: user.sn,
            comment: txtCmntInput,
            editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
            editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
            commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
            commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
            isEdited: 0

        }
        serviceProvider
            .post(
                "/businesscaseplantloss?type=savecomment",
                JSON.stringify({ uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: txtCmntInput }),
                true
            )
            .then((response) => {
                // console.log(response);

            });
        tmpCmntArr.unshift(tmpObj);
        setComment(tmpCmntArr);
        setTxtCmntInput('');
        tmpTextArea.style.height = 30 + 'px';
        setIsCommentCicked(false);
    }
    // Save Edit Data Functionality
    const saveEditData = (index) => {
        let tmpcmntArr = [...comment];
        let tmpTextArea = document.getElementById(`comment-editTxtarea-${tmpCmntData.id}`);
        let fullDateCreate = new Date();
        let tmpObj = {
            ...tmpCmntData,
            comment: tmpTextArea.value,
            editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
            editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
            isEdited: 1
        };
        serviceProvider
            .post(
                "/businesscaseplantloss?type=saveeditcomment",
                JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: tmpTextArea.value }),
                true
            )
            .then((response) => {
                // console.log(response);

            });

        tmpcmntArr[index] = tmpObj;
        setComment(tmpcmntArr);
        setIsEditComment('');
        // console.log(tmpcmntArr);
    }
    // Save Edit Data Functionality End

    // Functionality related to production line name logic (SCNV-3120) 
    const showTooltip = (e,i) =>{
        let singleColWidth=document.querySelector(`.la-ques-table-row.second .la-ques-table-col`);
        let offsetPos =  e.currentTarget.getBoundingClientRect();
        let rightSectionWidth=document.querySelector('.la-bottom-right-col').offsetWidth;
        let tooltipPop = e.currentTarget.querySelector('.line-namepopup');
        var scroll = $('.la-scroll-1621').scrollTop();
        if (tooltipPop){
            let tmpDeductionWidth=0;
            if(singleColWidth){
                      tmpDeductionWidth=singleColWidth.offsetWidth * i;
            }
            tooltipPop.style.maxWidth=`${rightSectionWidth-100}px`;
            tooltipPop.style.top = offsetPos.top - (tooltipPop.offsetHeight + 10) + 'px';
            if(offsetPos.left-tmpDeductionWidth<tooltipPop.offsetWidth){
                tooltipPop.style.left = offsetPos.left-tmpDeductionWidth + 'px';
            }else{
                 tooltipPop.style.left = (offsetPos.left + e.currentTarget.offsetWidth) - tooltipPop.offsetWidth   + 'px';
            }
            // tooltipPop.style.left = (tooltipPop.offsetWidth + sidebarWidth) - offsetPos.left + 'px';

            if (scroll > 0) {
                $('.la-bottom-left-col').addClass('z-index-active');
            }
        }
    }
    const hideTooltip = (e) =>{
        $('.la-bottom-left-col').removeClass('z-index-active');
    }

    //End SCNV-3120

    useEffect(() => {

        if (isEditdata > -1) {
            let inputAdd = document.querySelector(`#editListInput${isEditdata}`);
            inputAdd?.focus();
        }
    }, [isEditdata])
    // Popup Functionality End 10-11-2021
    useEffect(() => {
        fixColumn();
        lineClamp();
        let resizeTextArea = document.querySelectorAll('.resize-cmnt-area');
        resizeTextArea.forEach((item) => {
            item.addEventListener('input', () => {
                item.style.height = 'auto';
                let itemHeight = item.scrollHeight;
                if (itemHeight > 30) {
                    item.style.height = itemHeight + 'px';
                }
                if (itemHeight > 136) {
                    item.classList.remove('txtScrollHide');
                }
                else {
                    item.classList.add('txtScrollHide');
                }

            })
        })
        window.onresize = () => {
            fixColumn();
        }
        // handleSubmitBtnPos();
        

        //scnv-1292
        document.onclick = (e) => {


            let valdiv = document.querySelector('#AddNewsubCategory');
            //    let valdiv1 =  document.querySelector('.editinputuser');
            //    if(valdiv1){
            //     document.querySelector('.editinputuser').focus();
            //    }
            if (valdiv) {
                document.querySelector('#AddNewsubCategory').focus();
            }
            //    
            if (!e.target.closest('.btn-link') && !e.target.closest('.del-btn') && !e.target.closest('#AddNewsubCategory')) {
                setIsAddCol(false);
                if (e.pointerId > -1) {
                    setIsEditdata(false);
                }
                setActiveError(false);
                if (valdiv) {
                    valdiv.value = '';
                }
                // valdiv.value='';
            }

            if (!e.target.closest('.three-dot-btn')) {
                closdeAllDdl();
            }
        }

        //scnv-1292 end

    }, [APDList, APDList2])
    React.useEffect(() => {
        if (hashvalue && hashvalue != null) {
            var abc = hashvalue.replace("#", "");
            var myArray = abc.split("-");
            $('body').find('#idfc' + myArray[0]).click();
            setIsLossSupport(false)
        }
        if (Array.isArray(invitedmeblist)) {
            // setinvite-data
            let tmpArr = { ...assignedUsers };
            let tmpInviteData = invitedmeblist.filter((item) => item.invited_sub_category == quesData.subsection.id);
            tmpArr['inviteData'] = tmpInviteData;
            setAssignedUsers(tmpArr);
        }
    }, [])

    useEffect(() => {
         serviceProvider
                .post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:inputcolor }),
                    true
                )
                .then((response) => {
                });
    }, [inputcolor])
     /*useEffect for threedot component*/ 
     React.useEffect(()=>{
		 if(quesData.lines_na.length == productionLine1.length){
			setIsAvailable(true);
		}
        if(quesData.is_naavaliable){
            let is_naavaliable=[...quesData.is_naavaliable];
            let tmpArr = [...singleRowFreezArray];
            is_naavaliable.forEach((item)=>{
                tmpArr.push(item.lines_na);
            });
            setSingleRowFreezArray(tmpArr);
            if (productionLine1.length === tmpArr.length) {
                setIsAvailable(true);
            }
         }
     },[]);

	if(hashvalue && hashvalue != null && isAvailable == true) {
			return <Redirect to="/not-found" />;	
	}


    return (
        <>
            {/* {console.log("returen1", groupid)} */}
            {/* breadcrumbs */}
            <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{quesData.plant_name}</BreadcrumbsItem>
            <BreadcrumbsItem to={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>IR4.0 Loss Analysis</BreadcrumbsItem>
            <BreadcrumbsItem to="#">{props.pname}</BreadcrumbsItem>
            {/* css for styling */}
            <Helmet>
                <link rel="stylesheet" href={require('./la-questionnaires.scss').default} />
            </Helmet>
            {/* Main wrapper which is divided into three sections
                la-ques-top
                la-ques-bottom-wrapper
                la-ques-button-group
            */}
            <section className="la-ques-wrapper la-scroll-wrapper prod-6">
                <div className="la-ques-top">
                    {/* Top section has three parts
                        la-ques-lft-col
                        la-ques-mid-col
                        la-ques-right-col
                    */}
                    <div className="la-ques-row">
                        {/* This section contains
                            back button
                            page title
                            category title
                        */}
                        <div className="la-ques-lft-col">
                            <h1 className="h1-btn">
                                <span className="title">
                                    {props.pname}
                                </span>
                                <div className="edit-pop" style={{ visibility: islock == 1 ? 'hidden' : '' }}>
                                    <ThreeDotsComponent
                                        open={isThreeDotmenu}
                                        setOpen={setIsThreeDotmenu}
                                        isAvailable={isAvailable}
                                        setIsAvailable={setIsAvailable}
                                        isAvailablePopup={isAvailablePopup}
                                        setIsAvailablePopup={setIsAvailablePopup}
                                        isNotAvailablePopup={isNotAvailablePopup}
                                        setIsNotAvailablePopup={setIsNotAvailablePopup}
                                        setIsAvailableFalse={setIsAvailableFalse}
                                        setIsAvailableTrue={setIsAvailableTrue}
                                        resetAvailablePopup={resetAvailablePopup}
                                        resetNotAvailablePopup={resetNotAvailablePopup}
                                        handleAvailabilityPopups={handleAvailabilityPopups}
                                        plant_id={pid}
                                        section_id={sid}
                                        sub_section_id={quesData.subsection.id}
										lockalldaata={quesData.lockall}
										 {...props}
                                    />
                                </div>
                            </h1>
                            <h4 className="h4-dull">
                                Line Efficiency Losses
                            </h4>
                            <div style={{ display: 'none' }}> <Progressbar value={progress} /></div>
                        </div>
                        {/* This section contains assigned user list*/}
                        <div className="la-ques-mid-col">
                            <div style={{/* display: groupid == 14 ? 'none' : '' */}} className="la-assign-cir-wrapper">
                                <div className="la-assign-cir-group">
                                    <InviteUserList
                                        data={assignedUsers.inviteData}
                                        setData={setAssignedUsers}
                                        handlePopup={edithandle}
										open={isAssignSectionPopup}
                                        isEditAllow={groupid != 14 ? true : false}
										catagory={sid}
										subcatagory={quesData.subsection.id}
										pid={pid}
										uid={quesData.uid}
										sectionName={props.pname}
										plantName={quesData.plant_name}
										 {...props}
                                    />
									 
                                    <div>
                                        <button style={{ display: 'none' }} className="la-upload">
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-questionnaires/upload-icon.svg').default} alt="upload" />
                                            </div>
                                            <span>
                                                Upload Excel file
                                            </span>
                                        </button>
                                    </div>
                                    <div>
                                        <button style={{ display: 'none' }} className="la-download">
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-questionnaires/download-icon.svg').default} alt="download" />
                                            </div>
                                            <span>
                                                Download to Excel
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* this section has two elements
                            popup
                            unlock button
                        */}
                        <div className="la-ques-rgt-col">
                            {/*<div className={`la-card-wrapper ${islock == 1 ? '': 'active'}`}>
                                <button className="image-wrap" style={{display: `${islock==1? 'none': ''}`}}  onClick={() => HandleNotePop()}>*/}
                            <div className={`la-card-wrapper active ${quesData.subsection.id!=5?'la-dot-pulse':''} 
                            ${islock > 0 || activeSlide && ((ssid == 1 && quesData.islineff != 1) || (ssid == 2 && quesData.islineff1 != 1) || (ssid == 3 && quesData.islineff2 != 1) || (ssid == 4 && quesData.islineff3 != 1) || (ssid == 5 && quesData.islineff4 != 1)) ? '':'pulse-active'}

                            `}>
                                <button className="image-wrap" onClick={() => HandleNotePop()}>
                                    <img src={require('../../assets/img/la-questionnaires/bulb.svg').default} alt="Bulb" />
                                </button>
                                <div className="content-box">
                                    <p className="content">{quesData.subsection.description}</p>
                                    <button to="/" className="close-btn"><img src={require('../../assets/img/la-questionnaires/close.svg').default} alt="close" onClick={() => removeNotePop()} /></button>
                                </div>
                            </div>
                            {(islock == 1) &&
                                <>
                                    {/* scnv-2126 start */}
                                    <div className='scnv2126-btn-wrapper'>
                                        <div>
                                            <div className="submitted-scnv-2126 prod-7">
                                                <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: dataSubmitsvg }}></span>
                                                <span>Data Submitted</span>
                                            </div>
                                        </div>
                                        <div style={{ display: (groupid == 2 || groupid == 11) && quesData.lockall == 0 ? '' : 'none' }}>
                                            <button className="submitted-scnv-2126 btn-unclock" onClick={() => setIsUnlockPopup(true)}>
                                                <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: lockSvg }}></span>
                                                <span>Unlock Section</span>
                                            </button>
                                        </div>
                                    </div>
                                    {/* scnv-2126 end */}
                                </>
                            }
                            <div className="la-ques-button-group" style={{ display: isAvailable ? 'none' : '' }}>
                                <div className="la-common-left-col 1">
                                    {islock == 0 && 
                                    (<>
                                            <div className="button-wrap prod-7">
                                                <button disabled={islock > 0 ? true : false} className={`btn-link submit active ${islock > 0 ? 'disabled' : ''}   ${ssid == 5 ? (checktotalforsub != 100) ? 'disabled' : '' : (checktotalforsub != 100 || inputcolor != 'green') ? 'disabled' : ''}`} onClick={() => { if (ssid == 5) { if (checktotalforsub == 100) { setIsSubmitPopup(true) } } else { if (checktotalforsub == 100 && inputcolor == 'green') { setIsSubmitPopup(true) } } }}>Submit Data</button>
                                            </div>
                                        </>)
                                    }
                                </div>
                            </div>
							
							
                            <div className="available-btn-wrapper" style={{ display: isAvailable ? '' : 'none' }}>
                                <button className="not-available-btn">
                                    <img src={require('../../assets/img/maintenance_losses/data-unavailable.svg').default} alt="Bulb" />
                                    <span>Data Not Available</span>
                                </button>
								{quesData.lockall == 0 && (<>  
                                <button className="available-btn" onClick={() => handleAvailabilityPopups(false)}>Data Available</button>
								</>)}
                            </div> 
                        </div>
                    </div>
                    {/* la-ques-row */}
                </div>
                {/* Top section end */}
                <form id={"f1"} className="la-ques-bottom-wrapper">
                    <div className="la-ques-bottom">
                        {/* Middle Wrapper divided into two parts 
                             la-bottom-left-col
                             la-bottom-right-col
                        */}
                        <div className="la-ques-bottom-row">
                            {/* This section has been divided into two horizontal parts 
                                fixed-row-column
                                la-common-left-col
                            */}
                            <div className="la-bottom-left-col la-scroll-left-1621 prod-1">
                                <div className="fixed-row-column left">
                                    <div className={`average-planned-downtime-card down-time-filled ${ssid == 5 ? totalMW > 0 && checktotalforsub != 100 ? 'blue' : totalMW <= 0 ? '' : 'green' : inputcolor}`}>
                                        <div className="card-header">
                                            <h2>{newques[0].question}</h2>
                                        </div>
                                        <div className="card-footer">
                                            <div className="number-box">
                                                {/* { ssid==4 || ssid==5 ? '' : <span className="num-label">Total:</span> } */}
                                                <strong className="number">{ssid == 5 ? Number(totalMW).toLocaleString('en-US') : (isAvailable == true ?'N/A':totalval)}</strong>
                                                <span className="text"> {props.pname == 'Material Waste' ? `${quesData?.customData.currency}/Week` : `Hrs/Week`} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="side-ques-wrap">
                                        <span className="ques-label">{`${newques[1].question}:`}</span>
                                    </div>
                                </div>
                                {APDList.map((items, i) => {
                                    return (<div className="la-common-left-col fix">
                                        <button title={items.question} id={`idf${items.id}`} type='button' className={`side-subcategory noneditable ${isRightPopup === (items.id) && 'active'}`}>
                                            <div className="image-box" id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, 1, items)}>
                                                <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                </svg>
                                            </div>
                                            <div className="add-subcat-wrap">
                                                <div className={`input-wrap`}><p className="text" title={items.question} onClick={(e) => HandleRightSec(e, items.id, 1, items)}>{items.question}</p></div>

                                            </div>

                                        </button>
                                    </div>)
                                }
                                )}
                                {APDList2.map((items, i) => {

                                    return (<div className="la-common-left-col fix">
                                        <button title={items.question} type='button' id={`idf${items.id}`} className={`side-subcategory ${isRightPopup === (items.id) && 'active'}`}>
                                            <div className="image-box" id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, 2, items)}>
                                                <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                </svg>
                                            </div>
                                            {isEditdata !== i &&
                                                <div className="add-subcat-wrap">
                                                    <div className={`input-wrap ${!items.is_editable && 'w-100'}`}><p className="text" title={items.question}>{items.question}</p></div>
                                                    {items.is_editable &&
                                                        <div className="del-btn three-dot-btn" onClick={(e) => e.stopPropagation()}>
                                                            <button type='button' onClick={(e) => handleEditDdl(e)}>
                                                                <EditCirSVg />
                                                            </button>
                                                            <div className="ed-del-list">
                                                                <button type='button' className="inner-edit-btn"
                                                                    onClick={() => handleEditdata(items, i)}>Edit</button>
                                                                <button type='button'
                                                                    className="inner-del-btn"
                                                                    onClick={(e) => {
                                                                        HandleDelBtnEvent(e, {
                                                                            name: items.question,
                                                                            index: i,
                                                                            qid: items.id
                                                                        });
                                                                    }}>Delete</button>
                                                            </div>
                                                        </div>}
                                                </div>}
                                            <div className="add-subcat-wrap" style={{ display: isEditdata === i ? 'flex' : 'none' }} onClick={(e) => e.stopPropagation()}>
                                                <div className="input-wrap">
                                                    <input type="text" placeholder="Other - please specify" name="sub-cat" id={`editListInput${i}`} className="input editinputuser" /></div>
                                                <button type='button' className="del-btn edit-btn"
                                                    onClick={() => EditList()}>
                                                    <AddSvg />
                                                </button>
                                            </div>
                                        </button>
                                    </div>)
                                }
                                )}

                                <div className="la-common-left-col" style={{visibility: 'hidden' }}>
                                    <div className="side-subcategory">
                                        <div className="image-box">
                                            <img src={require('../../assets/img/la-questionnaires/notification-icon.svg').default} alt="notification" />
                                        </div>
                                        <div className="add-subcat-wrap">
                                            <div className="input-wrap">
                                                <input type='text' placeholder="Other - please specify" name="sub-cat" id="AddNewsubCategory" onChange={(e) => { tChange(e) }} className="input" /></div>

                                            {/* <button className="del-btn" onClick={() => setIsAddCol(false)}>
                                                <img src={require('../../assets/img/la-questionnaires/delete-icon.svg').default} alt="Delete" />
                                            </button> */}
                                            <button className="del-btn add-btn" type='button' onClick={() =>
                                                AddLsit()}>
                                                <AddSvg />
                                            </button>
                                            <div className="provide-data" style={{ visibility: activeError ? '' : 'hidden' }}>Please provide data</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* This section has been divided into two horizontal parts 
                                fixed-row-column
                                la-ques-table-col
                            */}
                            <div className="la-bottom-right-col prod-2 la-scroll-1621">
                                <div className="la-ques-table-wrapper prod-3">
                                    <div className="fixed-row-column rgt">
                                        <div className="la-ques-table-row first">
                                            {productionLine1.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>

                                                        <div className={`table-heading ${(isAvailable || singleRowFreezArray.indexOf(item.id) != -1) && 'freeze-input'} ${isLineFreeze == item.id && 'active'}`}
                                                            onClick={() => handleSingleRowFreezPopup(item.id)}
                                                            style={{ pointerEvents: islock == 1 || isAvailable ? 'none' : '' }}
                                                        >
                                                            <div className = "line-name-popwrap" onMouseEnter={(e)=>showTooltip(e,i)} onMouseLeave={hideTooltip} onTouchStart={(e)=>showTooltip(e,i)}>
                                                                <h3>{item.line_name}</h3>
                                                                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0 0L5 5L10 0H0Z" fill="#CBCFD2" />
                                                                </svg>
                                                                <div className="line-namepopup">
                                                                   <p className="long-content">{item.line_name}</p> 
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="data-unavailable-popup" onClick={(e) => e.stopPropagation()}>
                                                                <div className="popup-content-wrap">
                                                                    <p className="not-freez">If you don't collect data for this line, select the "Data Not Available" button.</p>
                                                                    <p className="freez">If you collect this data, select the "Data Available" button.</p>
                                                                    <div className="not-avail">
                                                                        {/*
                                                                            <button type="button" className="not-avail-btn not-freez" onClick={() => handleAvailabilityPopups(true, 'single', item.id)}>Data Not Available</button>
                                                                            <button type="button" className="not-avail-btn freez" onClick={() => handleAvailabilityPopups(false, 'single', item.id)}>Data Available</button>
                                                                        */}
                                                                        <button 
                                                                        type="button" 
                                                                        className="not-avail-btn not-freez" 
                                                                        onClick={() =>{
                                                                        productionLine1.length-1 == singleRowFreezArray.length ? 
                                                                            handleAvailabilityPopups(true, 'single', item.id)
                                                                            :
                                                                            setIsSingleAvailableTrue(item.id)
                                                                            
                                                                         }
                                                                       }
                                                                        >
                                                                            Data Not Available
                                                                        </button>
                                                                        <button 
                                                                        type="button" 
                                                                        className="not-avail-btn freez" 
                                                                        onClick={() =>{setIsSingleAvailableFalse(item.id)}
                                                                        }>
                                                                         Data Available
                                                                        </button> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="la-ques-table-row second">
                                            {!isEmptyObject(answer) && productionLine1.map((item, i) => {
                                                //    console.log(ssid);
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className={`hour-week-wrap wrong-input-val ${quesData.subsection.id!=5?'la-dot-pulse':''} ${islock > 0 || activeSlide && ((ssid == 1 && quesData.islineff != 1) || (ssid == 2 && quesData.islineff1 != 1) || (ssid == 3 && quesData.islineff2 != 1) || (ssid == 4 && quesData.islineff3 != 1) || (ssid == 5 && quesData.islineff4 != 1)) ? '':'pulse-active'} inputproductionline-${item.id} ${item.inputClass}`}>
                                                            <input disabled={ssid == 5 ? true : islock > 0 ? true : false} className={`input selected-input ${(isAvailable || singleRowFreezArray.indexOf(item.id) != -1) && 'freeze-input'}`} onBlur={(e) => { submitAns(e, newques[0].id, i), colorChange(), maxNumber(e) }} defaultValue={(ssid == 5) ? (item.linevalue > 0 ? Number(item.linevalue).toLocaleString('en-US') : "") : (ans.length > 0 ? ans[0][i] : "")} onChange={(e) => { submitAns(e, newques[0].id, i), colorChange() }} onKeyPress={(e) => { onKeyVal(e) }} name={`ques` + newques[0].id} data-qidl={`que-` + newques[0].id + `-${i}`} data-qid={`que-` + newques[0].id} type="text" placeholder={props.pname == 'Material Waste' ? `${quesData?.customData.currency}/Week` : `Hrs/Week`} maxLength="3" />
                                                            <span class="floating-label">{props.pname == 'Material Waste' ? `${quesData?.customData.currency}/Week` : `Hrs/Week`}</span>
                                                            <div className="error-mssg">Invalid Data</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className="la-ques-table-row third prod-4">
                                            {productionLine1.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className={`svg-wrap ${(isAvailable || singleRowFreezArray.indexOf(item.id) != -1) && 'freeze-input'}`}>
                                                            <div className="svg-wrapper">
                                                                <SvgCir value={item.p != undefined ? item.p : 0} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    {APDList.map((items, i1) => <div className={`la-ques-table-row la-right-input-row fix tr-row${i1}`} key={i1}>

                                        {!isEmptyObject(answer) && productionLine1.map((item, i2) => {
											
                                            return (
											
                                                <div className={`la-ques-table-col `} key={i2}>
                                                    <div className={`la-ques-input-wrap inputproductionline-${item.id} ${item.inputClass}`} >
                                                        <input type="text" disabled={islock > 0 ? true : false} name={`ques` + APDList[i1]?.id} onChange={() => { colorChange() }} onBlur={(e) => { submitAnsplus(e), colorChange() }} onKeyPress={(e) => { onKeyVal(e) }} defaultValue={ans.length > 0 && typeof ans[0] != 'undefined' ?  (typeof ans[i1 + 2] != 'undefined'? ans[i1 + 2][i2]:"")  : ""} onFocus={(e) => { changeval(e), colorChange() }} className={`la-ques-input ques-num ${(isAvailable || singleRowFreezArray.indexOf(item.id) != -1) && 'freeze-input'}`} placeholder="%" maxLength="3" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    )}
                                    {APDList2.map((items, i1) => <div className={`la-ques-table-row la-right-input-row fix tr-row${i1}`} key={i1}>
                                        {!isEmptyObject(useranswer) && productionLine1.map((item, i2) => {
                                            var ans11 = [];
                                            if (quesData.userans[i1].answer != "[]" && typeof quesData.userans[i1].answer != 'undefined') {
                                                ans11 = $.parseJSON(quesData.userans[i1].answer);
                                            }
                                            return (
                                                <div className={`la-ques-table-col `} key={i2}>
                                                    <div className={`la-ques-input-wrap inputproductionline-${item.id} ${item.inputClass}`} >
                                                        <input type="text" disabled={islock > 0 ? true : false}
                                                            name={`ques` + APDList2[i1]?.id + '[]'}
                                                            onChange={() => { colorChange() }}
                                                            onBlur={(e) => { submitAnsplus(e), colorChange() }}
                                                            onKeyPress={(e) => { onKeyVal(e) }} defaultValue={ans11 != "[]" && typeof ans11[i2] != 'undefined' && ans11[i2] != '' ? ans11[i2] + "%" : ""} onFocus={(e) => { changeval(e), colorChange() }}
                                                            className={`${(isAvailable || singleRowFreezArray.indexOf(item.id) != -1) && 'freeze-input'} la-ques-input ques-num ques` + APDList2[i1]?.id}
                                                            placeholder="%" maxLength="3" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {/* Bottom section which contains submit button */}
                {/* <div className="la-ques-button-group">
                    <div className="la-common-left-col">
                        {islock == 0 && (<>
                            <div className="button-wrap prod-5">
                                <button className="btn-link" onClick={() => { if (islock == 0) { setIsAddCol(true) }; scrollBottomAfterAdd(); }}>+ Add new subcategory</button>
                            </div>
                            <div className="button-wrap prod-7">

                                <button disabled={islock > 0 ? true : false} className={`btn-link submit active ${islock > 0 ? 'disabled' : ''}   ${ssid == 5 ? (checktotalforsub != 100) ? 'disabled' : '' : (checktotalforsub != 100 || inputcolor != 'green') ? 'disabled' : ''}`} onClick={() => { if (ssid == 5) { if (checktotalforsub == 100) { setIsSubmitPopup(true) } } else { if (checktotalforsub == 100 && inputcolor == 'green') { setIsSubmitPopup(true) } } }}>Submit</button>

                            </div></>)
                        }
                    </div>
                </div> */}

                {/* submit popup starts here */}
                <div className={`submit-pop-wrap ${isSubmitPopup && 'active'}`}>
                    <div className="popup-wrapper submit-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn" onClick={() => { setIsSubmitPopup(false) }}>
                                <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-questionnaires/unsaved.svg").default} alt="Unsaved" className="unsave-img" />
                            </div>
                            <div className="content-box">
                                <p className="text">By submitting the data you will no longer have the opportunity for further editing. </p>
                                <p className="text">Are you sure you want to proceed?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn cancel-button primary" onClick={() => { setIsSubmitPopup(false) }}>Cancel</button>
                                <button onClick={(e) => Finalsubmit(e)} type="submit" className="btn submit-btn secondary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Delete popup starts here */}
                <div className={`delete-pop-wrap ${isRemovePopup && 'active'}`}>
                    <div className="popup-wrapper del-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn" onClick={() => { setIsRemovePopup(false) }}>
                                <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-questionnaires/pop-del-icon.svg").default} alt="Delete" className="del-img" />
                            </div>
                            <div className="content-box">
                                <p className="subcat-title text">{delItem?.name}</p>
                                <p className="text">Are you sure you want to remove this subcategory?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn keep-button primary" onClick={() => { setIsRemovePopup(false) }}>No, keep it</button>
                                <button type="submit" className="btn remove-btn secondary" onClick={() => { RemoveList(); setIsRemovePopup(false); setIsRightPopup(false) }}>Yes, remove</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Right Sidebar Popup starts here */}
                <div className={`right-sidebar ${isRightPopup && 'active'}`}>
                    {isRightPopup != 0 && (
                        <LossSupportView
                            popupHeadTxt={rightdata.name}
                            des={rightdata.des}
                            isLossSupport={isLossSupport}
                            HandleRightSecClose={HandleRightSecClose}
                            close={setIsLossSupport}
                            isTicket={isTicket}
                            setIsTicket={setIsTicket}
                            slug={slug}
                            sid={sid}
                            pid={pid}
                            uid={usid}
                            ssid={ssid}
                            qid={isRightPopup}
                            type={isQuesType}
                            question={sendquestion}
                            islock={islock}
							isAvailable={isAvailable}
                            {...props}
                        />)}
                </div>
            </section>
            {/* la-ques-wrapper end */}
            {/* Assign section popup starts */}
            <AssignSectionPopup
                assignedUsers={assignedUsers}
                setAssignedUsers={setAssignedUsers}
                catagory={cate}
                subcatagory={quesData.subsection.id}
                pid={pid}
                sectionName={secname}
                plantName={quesData.plant_name}
                tmpAssignUser={'inviteData'}
                open={isAssignSectionPopup}
                setOpen={setIsAssignSectionPopup}
                {...props}
            />
            {/* Wlakthrough component call */}
            {activeSlide && ((ssid == 1 && quesData.islineff != 1) || (ssid == 2 && quesData.islineff1 != 1) || (ssid == 3 && quesData.islineff2 != 1) || (ssid == 4 && quesData.islineff3 != 1) || (ssid == 5 && quesData.islineff4 != 1)) &&
                <LAWalkthroughQuesView activeSlide={activeSlide} ssid={ssid} setActiveSlide={setActiveSlide}  {...props} />
            }
            {/* Unlock confirmation popup component call */}
            <ConfirmationUnlockPopup isOpen={isUnlockPopup} setOpen={setIsUnlockPopup} setUnlockbutton={props.setUnlockbutton} pid={pid} sid={101} ssid={quesData.subsection.id} />
            {/* How to use popup component */}
            <div className="how-to-use-ctrl-wrap">
                <HowToUseCtrl sectionName={props.pname}/>
            </div>
        </>

    )
}
//Function for progressbar
const Progressbar = ({ value }) => {
    return (
        <div className="la-progress-box">
            <p>Progress:</p>
            <div className="bar-wrapper">
                <span>{`${value}%`}</span>
                <div className="bar blue" style={{ width: `${value}%` }}></div>
            </div>
        </div>
    )
}
// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCir = (props) => {
    const { value } = props;
    return (
        <>
            <svg viewBox="0 0 36 36">
                <text
                    className="percent__text"
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontSize="8"
                    fontWeight="normal"
                >
                    <tspan fontSize="10" dominant-baseline="middle">
                        {value}%
                    </tspan>
                </text>
                <path
                    className="circle-bg"
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    stroke="#e6e6e6"
                    strokeWidth="4"
                    fill="none"
                ></path>
                <path
                    className="circle"
                    strokeDasharray={`${value}, 100`}
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    stroke={
                        value < 100 && value > 0
                            ? "#B0B0B0"
                            : value === 100
                                ? "#4CAF50"
                                : value === 0 ?
                                    "transparent"
                                    : "#FF6666"
                        // : "transparent"
                    }
                    strokeWidth="4"
                    fill="none"
                ></path>
            </svg>
        </>
    )
}
// Function to add svg
const AddSvg = () => {
    return (
        <svg width="{22}" height="{22}" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9167 6.41634H10.0833V10.083H6.41668V11.9163H10.0833V15.583H11.9167V11.9163H15.5833V10.083H11.9167V6.41634ZM11 1.83301C5.94001 1.83301 1.83334 5.93967 1.83334 10.9997C1.83334 16.0597 5.94001 20.1663 11 20.1663C16.06 20.1663 20.1667 16.0597 20.1667 10.9997C20.1667 5.93967 16.06 1.83301 11 1.83301ZM11 18.333C6.95751 18.333 3.66668 15.0422 3.66668 10.9997C3.66668 6.95717 6.95751 3.66634 11 3.66634C15.0425 3.66634 18.3333 6.95717 18.3333 10.9997C18.3333 15.0422 15.0425 18.333 11 18.333Z" fill="#45B097" />
        </svg>
    )
}
//Function to edit svg
const EditCirSVg = () => {
    return (
        <svg width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.5001" cy="5.36634" r="1.53333" fill="#7D868C" />
            <circle cx="11.4996" cy="11.5001" r="1.53333" fill="#7D868C" />
            <circle cx="11.4996" cy="17.6329" r="1.53333" fill="#7D868C" />
        </svg>
    )
}
//defining svg images here for unlock comfirmation popup
let dataSubmitsvg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11" cy="11" r="10.5" fill="#CAE4CB" stroke="#43A047"/>
<path d="M8.83863 14.0039L5.83524 11.0005L4.8125 12.0161L8.83863 16.0422L17.4815 7.39932L16.466 6.38379L8.83863 14.0039Z" fill="#43A047"/>
</svg>`

const lockSvg = `<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.7142 7.16667H12.7618V5.2619C12.7618 2.63333 10.6285 0.5 7.99991 0.5C5.37134 0.5 3.238 2.63333 3.238 5.2619V7.16667H2.28562C1.238 7.16667 0.380859 8.02381 0.380859 9.07143V18.5952C0.380859 19.6429 1.238 20.5 2.28562 20.5H13.7142C14.7618 20.5 15.619 19.6429 15.619 18.5952V9.07143C15.619 8.02381 14.7618 7.16667 13.7142 7.16667ZM7.99991 15.7381C6.95229 15.7381 6.09515 14.881 6.09515 13.8333C6.09515 12.7857 6.95229 11.9286 7.99991 11.9286C9.04753 11.9286 9.90467 12.7857 9.90467 13.8333C9.90467 14.881 9.04753 15.7381 7.99991 15.7381ZM10.9523 7.16667H5.04753V5.2619C5.04753 3.63333 6.37134 2.30952 7.99991 2.30952C9.62848 2.30952 10.9523 3.63333 10.9523 5.2619V7.16667Z" fill="#118ACB"/>
</svg>
`
//Function to confirm unlock popup
const ConfirmationUnlockPopup = (props) => {
    const finalUnlock = (id) => {
        props.setOpen(false);
        serviceProvider
            .post(
                "/businesscaseplantloss?type=unlocksection",
                JSON.stringify({ pid: props.pid, sid: props.sid, ssid: props.ssid }),
                true
            )
            .then((response) => {
                location.reload();
            });
    }
    return (
        <>
            {/* Wrapper that contains 
            popup text
            btn-wrapper ==> for cancel/delete action
        */}
            <div className='unlock-popup-wrapper2126' style={{ visibility: props.isOpen ? '' : 'hidden' }}>
                <div className='popup-content2126'>
                    <div className='body-popup2126'>
                        <p>Are you sure, you would like to unlock the section?</p>
                    </div>
                    <div className='popup-btn-wrapper2126'>
                        <button className='p-2126-btn cancel' onClick={() => props.setOpen(false)}>Cancel</button>
                        <button className='p-2126-btn yes' onClick={() => { finalUnlock(props.ssid) }}>Yes, unlock</button>
                    </div>
                </div>
            </div>
        </>
    )
}
// scnv-2126