import styled from "styled-components";

export const TBRightCol = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  justify-content: right;
  width: 47%;
  @media(max-width:600px){
    width: 100%;
    padding-top:20px;
  }
`;

export const LeftSection = styled.div`
  width: 50%;
  border-right: 0.1rem solid #cdd2d4;
  height: 100%;
  flex-direction: column;
  display: flex;
  @media(max-width:976px){
    width: 100%;
    height:max-content;
    border-right: 0;
    position:relative;
    padding-top:50px;
  }
  .added-roadmap-btn{
    display:none;
    @media(max-width:976px){
        display:inline-flex;
        font-size:14px;
        align-items:center;
        justify-content:center;
        border-radius:5px;
        padding:12px 15px;
        background: linear-gradient(131.53deg,#8686DF 14.79%,#6464C8 85.39%);
        color:#fff;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;    
        z-index:9;
    }
    @media(max-width:575px){
          width:100%;
    }
  }
  ${
    "" /* .carddragging{
    position:static !important;
    transform:none !important;
  } */
  }
`;
export const LossWrap = styled.div`
  border: 0.1rem solid #ebf5fa;
  border-radius: 0.4rem;
  padding: 1.2rem 0.5rem;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.12); 
  margin-bottom: 3rem;
  @media(max-width:575px){
    padding: 0px 10px;
  }
`;
export const MainWrapper = styled.div`
  background: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 0rem 1.5rem 0rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  &.step-four{
    ${LeftSection}{
      @media(max-width: 976px){
        padding-top: 0;
      }
    }
  }
  &.step-scnd{
    ${LeftSection}{
      @media(max-width: 976px){
        padding-top: 0;
      }
    }
    ${TBRightCol}{
      @media(max-width: 976px){
        padding-top: 0;
        padding-bottom: 5px;
      }
    }
  }
  &.step-third{
    ${LeftSection}{
      @media(max-width: 976px){
        padding-top: 0;
      }
    }
    ${TBRightCol}{
      @media(max-width: 976px){
        padding-top: 0;
        padding-bottom: 5px;
      }
    }
  }
`;

/*top header css*/
export const SectionWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  padding-top: 0.5rem;
  @media(max-width:976px){
    flex-direction:column;
    overflow:auto;
  }
`;


export const RightSection = styled.div`
  width: 50%;
  height: 100%;
  flex-direction: column;
  display: flex;
  .sm-close-popup-btn{
    display:none;
    position:absolute;
    right:15px;
    top:10px; 
    line-height:0;
  }
  @media(max-width:976px){
    width: 100%;
    height:calc(100vh - 100px);
    position:fixed;
    z-index:999;
    background:#fff;
    width:100%;
    left:0;
    bottom:0;
    padding-top:45px;
    visibility:${({smActive})=>smActive?'visible':'hidden'};
    opacity:${({smActive})=>smActive?'1':'0'};
    transition:0.25s;
    &:after{
      content:'';
      position:fixed;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index:-2;
      display:block;
      background:rgba(0,0,0,0.2);
    }
    &:before{
      content:'';
      position:fixed;
      right:0;
      bottom:0;
      left:0;
      height:inherit;
      z-index:-1;
      display:block;
      background:#fff;
    }
    .sm-close-popup-btn{{
      display:inline-block;
    }
  }
`;

export const SectionHeader = styled.div`
  min-height: 10rem;
  padding: 0rem 3rem;
  @media (max-width: 976px) {
    min-height: 6rem;
  }
  p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-top: 1.2rem;
    color: #7d868c;
  }
  .default-scenario {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: 1.2rem;
    input {
      cursor: pointer;
      display: inline-block;
      height: 1.4rem;
      width: 1.4rem;
    }
    span {
      display: inline-block;
      font-weight: 400;
      flex: 1;
      font-size: 1.4rem;
      line-height: 0;
      color: #7d868c;
      @media(max-width: 740px){
        line-height: normal;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 5px 15px;
  }
`;


export const ContentFooter = styled.div`
display: flex;
justify-content: end;
background: #ffffff;
padding: 1.5rem;
width: 100%;
.proceed-btn {
  min-width: 11.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.5rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #118acb;
  border: 0.1rem solid #118acb;
  outline: 0 !important;
  background: #fff;
  border-radius: 0.4rem;
  transition: 0.25s ease-in-out;
  margin: 0 0.5rem;
  &:hover {
    background: #118acb;
    color: #ffffff;
  }
}

.prev-btn{
  min-width: 11.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0.5rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #7d868c;
  border: 0.1rem solid #7d868c;
  outline: 0 !important;
  background: #fff;
  border-radius: 0.4rem;
  transition: 0.25s ease-in-out;
  margin: 0 0.5rem;
  &:hover{
      background: #7d868c;
      border-color: #7d868c;
      color: #ffffff;
  }
}
`;
export const HeaderTitle = styled.h2`
  font-size: 1.6rem;
  color: #118acb;
  flex:1;
`;
export const FilterBoxHeader = styled.div`
  border-bottom: 0.1rem solid #ccc;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  align-items: center;
  ${HeaderTitle} {
    color: #4d4d4f;
    font-size: 1.6rem;
  }
`;
export const ScenarioSectionWrapper = styled.div`
  display: flex;
  gap: 1rem;
  @media(max-width:575px){
    flex-direction:column;
  }
`;
export const TabButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 3px 4px 0px rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  height: 6rem;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-left: 5rem;
  position: relative;
  @media(max-width:1500px){
    height:auto;
  }
  @media(max-width:976px){
    padding:12px 0px 12px 10px;
  }
  @media(max-width:600px){
    height:max-content;
    padding-bottom:0px;
  }
`;
export const TBRow = styled.div`
  display: flex;
  width: 100%;
  @media(max-width:600px){
    flex-direction:column;
  }
`;
export const TBLeftCol = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  width: 53%;
  @media(max-width:600px){
    width:100%;
  }
  .rmap-txt {
    color: #7d868c;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.7rem;
    padding-left: 1rem;
    padding-right: 2rem;
    flex: 1 1;
    display: flex;
    align-items: center;
    gap: 0.3rem; 
    .red-dot{
      display: inline-flex;
      width: 0.4rem;
      height: 0.4rem;
      background: #DB324D;
      border-radius: 50%;
    }
  }
  .drag-btn {
    width: 5.5rem;
    position: absolute;
    left: 0;
    top: -0.01rem;
    bottom: -0.01rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @media(max-width:976px){
      display:none;
    }
    &.blue {
      border-radius: 0.8rem;
      background: linear-gradient(124.38deg, #139bd6 33.4%, #078ad0 72.76%);
      color: #fff;
      font-size: 1.6rem;
      font-weight: 500;
      position: absolute;
      left: -0.15rem;
      top: -0.05rem;
      bottom: -0.05rem;
      pointer-events: none;
    }
    &.disabled{
      border-radius: 0.8rem;
      background: transparent;
      color: #4d4d4f;
      font-size: 1.6rem;
      font-weight: 500;
      position: absolute;
      left: -0.15rem;
      top: -0.05rem;
      bottom: -0.05rem;
      border-right: 0.1rem solid #ebf5fa !important;
      }
    i {
      display: inline-block;
      cursor: grab;
      width: 0.9rem;
    }
  }
  .status-wrap {
    min-width: 10.5rem;
    @media(max-width:600px){
      min-width: auto;
    }
  }
 
`;

export const ContentBody = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0rem 2rem;
  @media(max-width:600px){
    padding: 0px 15px;
    ${TabButton}{
      &.disabled{
        padding-top:0px !important;
        ${TBRightCol}{
          padding-top:0px !important;
        }
      }
    }
  }
  .blue-txt {
    color: #118acb;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;
export const TabContentWraaper = styled.div`
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #7d868c;
  position: relative;
  padding: 1.5rem;
  // Css for tabs
  .MuiBox-root {
    padding: 2.4rem 0 0 2.4rem !important;
    @media(max-width: 740px){
      padding: 10px 0 0 10px !important;
    }
  }
  .MuiTabs-root {
    align-items: center;
    position: relative;
    .MuiButtonBase-root {
      min-width: 15rem;
      width: max-content;
      @media (max-width: 575px) {
        min-width: 11rem;
      }
      .MuiTab-wrapper {
        font-size: 1.4rem !important;
        text-transform: capitalize;
      }
    }
    .PrivateTabIndicator-root-1 {
      background-color: #118acb !important;
      padding: 0.2rem;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
    .MuiButtonBase-root {
      &.MuiTabScrollButton-root {
        &.MuiTabs-scrollButtons {
          &.MuiTabs-scrollButtonsDesktop {
            height: 2.4rem !important;
            width: 2.4rem !important;
            min-width: unset;
            background: #fff;
            .MuiSvgIcon-root {
              &.MuiSvgIcon-fontSizeSmall {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .MuiTabs-scroller {
    border-bottom: 0.1rem solid #dee2e6;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;

export const LossRow = styled.div`
  display: flex;
  align-items: center;
  @media(max-width:575px){
    flex-direction: column;
  }
`;
export const LossCol = styled.div`
  border-right: 0.1rem solid #ebf5fa;
  flex: 1 1;
  padding: 0 2.5rem;
  .upgrade-lock-need-wrapper{
    display:flex;
    width:100%;
    justify-content:center;
    @media(max-width: 740px){
      padding-top: 5px;
    }
    i{
      inline-block;
    }
  }
  @media(max-width: 976px){
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
  }
  @media(max-width:575px){
    flex:unset;
    width:100%;
    padding:10px;
    &:not(:last-child){
      border-bottom: 0.1rem solid #ebf5fa;
    }
    border-right: 0rem;
    text-align: center;
  }
  .text {
    display: block;
    font-size: 1.4rem;
    text-align: center;
    @media(max-width: 976px){
      width: auto;
    }
    &.orange {
      color: #eb5757;
      @media(max-width: 976px){
        width: 100%;
      }
    }
    &.green {
      color: #27ae60;
      @media(max-width: 976px){
        width: 100%;
      }
    }
    &.blue {
      color: #118acb;
      @media(max-width: 976px){
        width: 100%;
      }
    }
    @media(max-width:575px){
      display:inline;
      &:after{
        content:' ';
      }
    }
  }
  &:last-child {
    border-right: 0;
  }
`;
export const ProceedPopWrapper = styled.div`
  background: rgba(21, 27, 38, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
`;
export const ProceedPopCard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0 6px 8px rgba(152, 164, 170, 0.17);
  border-radius: 0.8rem;
  min-height: 20rem;
  width: 45rem;
  @media(max-width: 740px){
    border-radius: 0;
    bottom: 0;
    transform: unset;
    left: 0;
    min-height: unset;
    height: auto;
    top: auto;
    width: 100%;
  }
  p {
    font-size: 1.4rem;
    font-weight: 400;
    padding-left: 2rem;
    padding-bottom: 2rem;
    @media(max-width: 740px){
      padding-left: 10px;
    }
  }
`;
export const PopHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem 2rem 2rem;
  @media(max-width: 740px){
    padding-left: 10px;
    padding-right: 10px;
  }
  span {
    color: #118acb;
    font-size: 1.6rem;
    font-weight: 400;
  }
`;
export const PopFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem;
  @media(max-width: 740px){
    padding-left: 10px;
    padding-right: 10px;
  }
  .save-btn,.leave-btn {
    min-height: 4rem;
    min-width: 11.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #ffffff;
    background: linear-gradient(91.83deg, #139bd6 12.22%, #0789cf 100.41%);
    border: 0.1rem solid #118acb;
    border-radius: 0.4rem;
    &.transparent {
      background: transparent;
      border-color: #118acb;
      color: #118acb;
      margin-left: 2rem;
    }
  }
`;
export const QNABtn = styled.div`
  display: flex;
  padding: 0;
  min-height: 3.5rem;
  cursor: pointer;
  &:first-child {
    padding-top: 0;
  }
  .q-txt {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #4d4d4f;
  }
  .q-desc {
    font-weight: 500;
    font-size: 1.4rem;
    color: #4d4d4f;
  }
`;
export const QNACard = styled.div`
  padding: 1.2rem;
  &.active {
    background: #fafcfd;
    border: 0.1rem solid #e5f0ff !important;
    border-radius: 1.2rem !important;
    ${QNABtn} {
      .q-txt {
        color: #118acb;
      }
      .q-desc {
        color: #118acb;
      }
    }
  }
`;
export const QNAWrap = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
    .bd-bottom {
      border-bottom: 0.1rem solid #e5f0ff;
    }
    .bd-right {
      border-right: 0.1rem solid #e5f0ff;
    }
    tr {
      th {
        color: #4d4d4f;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0.5rem;
        &:first-child {
          text-align: left;
        }
      }
      td {
        text-align: center;
        padding: 0.5rem;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
`;
export const ScenarioContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fff;
`;

export const ScenarioButton = styled.button`
            border:0.1rem solid ${({ active }) =>
              active ? "#ececec" : "#4d4d4f"};
            border-radius:0.4rem;
            background:#fff;
            box-shadow: ${({ active }) =>
              active ? "rgba(0, 0, 0, 0.25)" : "transparent"} 0px 0px 0.8rem;
            positon:relative;
            width:100%;
            display:flex;
            align-items:center;
            padding:1.3rem 1.6rem;
            text-align:left;
            font-size:1.4rem;
            font-weight:600;
            transition:0.25s ease-in;
            span{
                flex:1;
                color: #7d868c;
            }
            i{
                display:inline-block;
                svg{
                    width:1.6rem;
                    path:border-bottom:${({ active }) => ("#" ? 0 : "")};
                }
            }
`;

export const ScenarioDropdownBox = styled.div`
  position: absolute;
  bottom: -1rem;
  transform: translateY(100%);
  background: #fff;
  z-index: 9;
  padding: 1.5rem 1rem;
  border-radius: 0.4rem;
  border-top: 0;
  width: 100%;
  box-shadow: ${({ show }) => (show ? "rgba(0, 0, 0, 0.25)" : "transparent")}
    0px 0px 0.8rem;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 30rem;
  overflow: auto;
  transition: 0.25s ease-in;
`;

export const ScenarioCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

export const ScenarioCardClose = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const ScenarioCardContent = styled.div`
  font-size: 1.4rem;
  color: #7d868c;
  .btn-wrapper-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1.5rem;
    button {
      flex: 1;
    }
  }
  .error-show{
    display: block;
    width: 100%;
    color: red;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding-top: 1rem;
  }
`;

export const ScenarioInput = styled.input`
  border: 0.1rem solid #ccc;
  border-radius: 0.4rem;
  width: 100%;
  height: 4.5rem;
  outline: 0;
  padding: 1.5rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: #4d4d4f;
`;

export const ScenarioLabel = styled.label`
  position: relative;
  display: block;
  user-select: none;
  margin-bottom: 5rem;
  @media(max-width: 740px){
    margin-bottom: 20px;
  }
  span {
    position: absolute;
    background: #fff;
    top: 0;
    color: #4d4d4f;
    display: inline-block;
    padding: 0.2rem;
    left: 1.3rem;
    transform: translateY(-50%);
    font-size: 1.2rem;
    font-weight: 600;
  }
`;

export const SaveScenarioButton = styled.button`
  background: ${({ borderBtn }) => (borderBtn ? "#fff" : "#118acb")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ borderBtn }) => (borderBtn ? "#118acb" : "#fff")};
  border: 0.1rem solid #118acb;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.5rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  font-weight: 500;
  outline: 0;
`;

export const ScenarioList = styled.button`
  background: ${({active})=>active?'#ebf5fa':'#fff'};
  padding: 1rem;
  transition: 0.25s ease-in;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 2rem;
  font-size: 1.4rem;
  color: #4d4d4f;
  font-weight: 600;
  position:relative;
  &.has-default-scenario{
    padding-bottom:2rem;
  }
  .default-scenario-text{
    color:#4caf50;
    font-size:1.2rem;
    bottom:0.5rem;
    display: inline-block;
    border-left: 1px solid #ccc;
    padding-left: 10px;
    margin-left: 10px;
    font-weigth:500;
    @media(max-width: 350px){
      padding-left: 0;
      margin-left: 0;
      border-left: 0;
    }
  }
  span {
    flex: 1;
  }
  i {
    display: inline-block;
    line-height: 0;
    position: relative;
    svg {
      width: 2rem;
    }
    &:before {
      content: attr(data-title);
      display: ${({ noTitle }) => (!noTitle ? "inline-flex" : "none")};
      background: rgb(77, 77, 79);
      border-radius: 0.2rem;
      position: absolute;
      min-height: 1.5rem;
      z-index: 1;
      font-style: normal;
      font-size: 1.2rem;
      font-weight: 500;
      right: 0px;
      top: -0.5rem;
      align-items: center;
      padding: 0.4rem 0.5rem;
      color: #fff;
      border-radius: 0.2rem;
      transform: translateY(-100%);
      opacity: 0;
      pointer-events: none;
      transition: 0.25s ease-in;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  &:hover {
    background: #ebf5fa;
  }
`;

export const ScenarioModal = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ show }) => (!show ? "hidden" : "visible")};
  @media(max-width:575px){
    bottom: 0;
  }
`;

export const ScenarionModalCard = styled.section`
  width: 50rem;
  min-height: 10rem;
  padding: 2.5rem;
  background: #fff;
  border-radius: 0.8rem;
  @media(max-width:575px){
    max-width:100%;
    width: 100%;
    border-radius:0px;
    position: absolute;
    bottom: 0;
  }
`;
// End Scenario
export const RecoverablePopWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    padding: 2.5rem 2rem;
    max-width: 100%;
    margin: auto;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    color: #7d868c;
    margin-bottom: 2rem;
    @media(max-width: 740px){
      padding: 1.5rem;
    }
`
export const RecoverCloseBtn = styled.div`
    cursor: pointer;
    width: 3rem;
    i{
        display: inline-block;
        height: 3rem;
        width: 3rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
`
export const InfoIcon = styled.div``
export const RecoverPopCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p{
        color: #118acb;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0 2rem;
        line-height: 2rem;
        @media(max-width: 740px){
          font-size: 12px;
        }
    }
`
export const RightAlignTextWrap = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: right;
    padding-bottom: 0.5rem;
    @media(max-width:1100px){
      flex-wrap:wrap;
    }
    @media(max-width: 976px){
      justify-content: flex-start;
    }
`
export const ScndTabButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid #ebf5fa;
    -webkit-box-shadow: 0px 3px 12px rgba(21, 27, 38, 0.03);
    border-radius: 0.8rem;
    min-height: 6rem;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-left: 5rem;
    position: relative;
    @media(max-width:1500px){
      height:auto;
    }
    @media(max-width:976px){
      min-height: auto;
      padding:12px 0px 12px 10px;
    }
    @media(max-width:600px){
      height:max-content;
      padding-bottom:0px;
    }
    ${TBRow}{
        justify-content: space-between;
    }
    ${TBLeftCol}{
        padding-right: 1rem;
        width: auto;
        .rmap-txt{
            padding-right: 0;
        }
    }
    ${TBRightCol}{
        padding-left: 1rem;
        width: auto;
    }
    ${RightAlignTextWrap}{
        flex-wrap: unset;
    }
`

export const DefaultScenarioPopupWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(125, 134, 140, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`
export const DefaultScenarioPop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 0.6rem;
  min-height: 24rem;
  padding: 4.4rem 1.9rem 4.4rem 1.5rem;
  border: 0.2rem solid #118acb;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fefefe;
  filter: drop-shadow 0.5rem 0.5rem 3rem rgba(77, 77, 79, 0.4)
  width: 63rem;
  @media(max-width: 740px){
    border-radius: 0;
    width: 100%;
    transform: unset;
    left: 0;
    bottom: 0;
  }
  h2{
    font-size: 1.8rem;
    color: #0d2c54;
    font-weight: 500;
    line-height: 2.8rem;
    margin-bottom: 0;
  }

  p{
    font-size: 1.6rem;
    margin-top: 1.6rem;
    color: rgba(13, 44, 84, 1);
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 0;
  }
  .button-section{
    margin-top: 4rem;
    display: flex;
    gap: 1.8rem;
    @media(max-width: 740px){
      flex-wrap: wrap;
    }
    button{
      min-width: 15rem;
      height: 4.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.4rem;
      border: 0.1rem solid #118acb;
      font-size: 1.5rem;
      font-weight: 500;
      &.submit{
        background: #118acb;
        color: #fff;
        padding: 1.2rem 1.6rem;
      }
      &.cancel{
        color: #118acb;
      }
    }
  }
`
export const LoaderPopupWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-image: url(${require(`../../assets/img/roadmap-img/loader-bg-img.svg`).default});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  &:before{
    content: '';
    display: block;
    background: #ffffff;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
`
export  const LoaderPop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display:flex;
  flex-direction:column;
  align-items:center;
`

export const NewCardWrap = styled.div`
  margin-bottom: 3rem;
  ${TabButton}{
    background: linear-gradient(90deg, #D8FAFD 0%, #F9FEFF 100%); 
  }
`

export const ExistingCardWrap = styled.div``

export const LeftTopHeading = styled.h2`
  color: #455A64;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 0.8rem;

`
export const LeftTopText = styled.p`
  color: #607D8B;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1.2rem;
  i{
    display: inline-flex;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background: #DB324D;
  }
`