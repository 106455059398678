/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * yupResolver for ===> React hooks for form validation 
  * useForm for ===> React hooks for form validation 

**/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Redirect, Link } from "react-router-dom";
import "./auth.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import serviceProvider from "../../services/axios";

//component of Signup
export const Signup = (props) => {
  // form validation rules
  const [notM, setnotM] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [isAuthRootFontCss, setIsAuthRootFontCss] = useState(true);
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().required("Email is required").email("Email is invalid"),
    job_title: yup.string().required("Job Title is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        // /^(?=.*[@$!%*#?&])(?=.*[0-9])[A-Za-z\d@$!%*#?&]{8,}$/,
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain at least 8 characters, one uppercase, one number and one special character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  // login variable defined for get value from local storage
  const login = localStorage.getItem("isLoggedIn");
  if (login) {
    return <Redirect to="/home" />;
  }

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, errors, setError } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Function will be call onChange event of password input
  const passChange = (event) => {
    var element = document.getElementById("newError");
    if (event.target.value == "") {
      element.classList.remove("singuppassword");
    } else {
      element.classList.add("singuppassword");
    }
  };
  // Funtion for toggle password
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  // Funtion for toggle confirm password
  const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
  };
  // Funtion for clear error
  const clearInput = () => {
    setnotM("");
  };
  // Function call when submit the Signup form.
  const onSubmit = (data) => {
    serviceProvider
      .post("/user?type=signup", JSON.stringify(data))
      .then((response) => {
        if (response.data.status === 200) {
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem(
            "group_id",
            JSON.stringify(response.data.data.group_id)
          );
          localStorage.setItem(
            "access_token",
            JSON.stringify(response.data.data.access_token)
          );
		  localStorage.setItem(
            "gettimezone",
            JSON.stringify(response.data.data.gettimezone)
          );
          localStorage.setItem(
            "refresh_token",
            JSON.stringify(response.data.data.refresh_token)
          ); 
		  localStorage.setItem(
            "noti",
            JSON.stringify(response.data.data.noti)
          );
          window.location = "/home";
        }
        if (response.data.status === "failed") {
          //alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
          setnotM("Please choose a different email");
          return setError("email", "email", "please choose a different email");
        }
      });
  };
  
useEffect(() => {
  return () => {
    setIsAuthRootFontCss(false);
  }
},[]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign Up | SmarterChains</title>
        <meta name="description" content="Sign Up" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {isAuthRootFontCss && (
          <link
            rel="stylesheet"
            href={require("./authroot-fontsize.css").default}
          ></link>
        )}
      </Helmet>
      {/* 
          Main container. Here we placed Sign in form with logo.
          Devide container in 2 parts left & right section.
          Logo & Hero are in left side part section.
          Sign in form fields are in right side section.
      */}
      <div className="auth__form--wrapp signup2021-06">
        {/* Logo + hero image */}
        <Grid item xs={12} sm={4} md={4} className="auth__form--left">
          <div className="auth__form--logo">
            <img src={require("../../assets/img/auth/sc-logo.svg").default} />
          </div>
        </Grid>
        {/* Signup form  */}
        <Grid item xs={12} sm={8} md={8} className="auth__form--right">
          <div className="wrapper sign-in">
            <div className="col-6 Signup-form">
              <h1>Start your Industry 4.0 journey today</h1>
              <form className="signup-box" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <div className="col-12">
                    <label>Email</label>
                    <input
                      placeholder="Email Address"
                      onClick={clearInput}
                      name="email"
                      type="text"
                      ref={register}
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <label className="emailError error">
                      {notM}
                      {errors.email?.message}
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-6">
                    <label>First Name</label>
                    <input
                      placeholder="First Name"
                      name="firstName"
                      type="text"
                      ref={register}
                      className={`form-control ${
                        errors.firstName ? "is-invalid" : ""
                      }`}
                    />
                    <label className="error">{errors.firstName?.message}</label>
                  </div>
                  <div className="col-6">
                    <label>Last Name</label>
                    <input
                      name="lastName"
                      placeholder="Last Name"
                      type="text"
                      ref={register}
                      className={`form-control ${
                        errors.lastName ? "is-invalid" : ""
                      }`}
                    />
                    <label className="error">{errors.lastName?.message}</label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-12">
                    <label>Job Title</label>
                    <input
                      placeholder="Job Title"
                      name="job_title"
                      type="text"
                      ref={register}
                      className={`form-control ${
                        errors.job_title ? "is-invalid" : ""
                      }`}
                    />
                    <label className="error">{errors.job_title?.message}</label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-6" id="newError">
                    <label>Password</label>
                    <input
                      name="password"
                      className="password-p"
                      placeholder="********"
                      type={passwordShown ? "text" : "password"}
                      ref={register}
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      onChange={passChange}
                    />
                    <span
                      onClick={togglePasswordVisiblity}
                      className={`form-control ${
                        passwordShown
                          ? "fa fa-fw field-icon toggle-password fa-eye"
                          : "fa fa-fw field-icon toggle-password fa-eye-slash"
                      }`}
                    />
                    <label className="error">{errors.password?.message}</label>
                  </div>
                  <div className="col-6">
                    <label>Confirm Password</label>
                    <input
                      name="confirmPassword"
                      className="password-p"
                      placeholder="********"
                      type={passwordShown1 ? "text" : "password"}
                      ref={register}
                      className={`form-control ${
                        errors.confirmPassword ? "is-invalid" : ""
                      }`}
                    />
                    <span
                      onClick={togglePasswordVisiblity1}
                      className={`form-control ${
                        passwordShown1
                          ? "fa fa-fw field-icon toggle-password fa-eye"
                          : "fa fa-fw field-icon toggle-password fa-eye-slash"
                      }`}
                    />
                    <label className="error">
                      {errors.confirmPassword?.message}
                    </label>
                  </div>
                </div>
                <p className="terms-privacy-text">
                  By clicking on Sign Up, you agree to SmarterChains’
                  <a
                    href="//www.smarterchains.com/terms-of-service"
                    className="services"
                    target="_blank"
                  >
                    Terms of service,
                  </a>
                  <a
                    href="//www.smarterchains.com/website-terms-of-use"
                    className="pp_terms"
                    target="_blank"
                  >
                    Terms of use
                  </a>
                  and
                  <a
                    href="//www.smarterchains.com/privacy-policy"
                    className="pp_pps ap"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
                <button type="submit" className="signup" color="success">
                  Sign Up
                </button>
                <span>
                  Have an account?
                  <Link to="/sign-in" className="log_in_b">
                    Log In
                  </Link>
                </span>
              </form>
            </div>
          </div>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Signup;
