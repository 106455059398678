import styled from "styled-components";
export const MainWrapper = styled.div`
.industry40-main-wrapper {
    padding-left: 5.4rem;
    max-width: 115rem;
    padding-top: 3.2rem;
    padding-bottom: 5.9rem;
    padding-right: 1.5rem;
	@media(max-width: 740px){
		padding: 20px;
		width: 100%;
	}
	.industry-frame-wrapper {
		display: flex;
		flex-wrap: wrap;
		//justify-content: space-between;
		@media(max-width: 740px){
			flex-wrap: wrap;
			gap: 20px;
		}
		.industry-frame1 {
			font-weight: normal;
			font-size: 1.4rem;
			line-height: 150%;
			color: #4d4d4f;
			width: 51.4rem;
			margin-right:4.9rem;
			&.top-section{
				width: 100%;
				.ind-p {
					margin-bottom: 1rem;
					max-width: 63rem;
				}
			}
			@media(max-width: 740px){
				width: 100%;
			}
			.h1 {
				font-weight: 600;
				font-size: 3.2rem;
				line-height: 3.9rem;
				color: #4d4d4f;
				margin-bottom: 1.4rem;
			}
			.ind-p {
				margin-bottom: 1rem;
				max-width: 49.7rem;
			}
			.industry-frame1-cir-div {
				width: 51.4rem;
				height: 51.4rem;
				background: rgba(45, 156, 219, 0.47);
				border-radius: 50%;
				position: relative;
				margin-top: 4rem;
				display: flex;
				justify-content: center;
				padding: 2rem 1.5rem;
				@media(max-width: 740px){
					border-radius: 0;
					height: auto;
					padding-top: 40px;
					padding-bottom: 40px;
					width: 100%;
				}
				.cir-hover-tooltip {
					width: 17.1rem;
					background: #fff;
					border-radius: 0.6rem;
					padding: 0.9rem 1.2rem;
					position: absolute;
					font-weight: 500;
					font-size: 1.2rem;
					line-height: 1.5rem;
					text-align: center;
					color: #4d4d4f;
					top: 3rem;
					left: -3rem;
					opacity: 0;
					visibility: hidden;
					transition: 0.25s;
					box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.1);
					@media(max-width: 740px){
						top: 60px;
						left: 0;
						z-index: 1;
					}
					&.active {
						opacity: 1;
						visibility: visible;
						transition: 0.25s;
					}
					* {
						position: relative;
					}
					&:before {
						content: "";
						width: 1rem;
						height: 1rem;
						background: #fff;
						transform: scaleY(2) rotate(45deg);
						bottom: 0.21rem;
						right: 2.3rem;
						position: absolute;
						display: block;
						border-radius: 0.05rem;
					}
				}
				&:before {
					content: "";
					width: 35.453rem;
					height: 35.453rem;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: rgba(45, 156, 219, 0.47);
					@media(max-width: 740px){
						content: unset;
					}
				}
				&:after {
					content: "";
					width: 19.4rem;
					height: 19.4rem;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: rgba(45, 156, 219, 1);
				}
				.cir-btn-wrapper-ul {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					width: 35.453rem;
					position: relative;
					z-index: 1;
					@media(max-width: 740px){
						gap: 50px;
					}
					.cir-btn-wrapper-li {
						display: flex;
						justify-content: center;
						width: 15.4rem;
						position: relative;
						&.w-100 {
							width: 100%;
						}
						@media(max-width: 740px){
							width: 100%;
						}
						.btn {
							height: 4.8rem;
							width: 15.4rem;
							background: #ffffff;
							box-shadow: 0 0 0 0.6rem rgba(255, 255, 255, 0.3);
							display: inline-flex;
							justify-content: center;
							align-items: center;
							font-weight: 700;
							font-size: 1.5rem;
							text-align: center;
							line-height: 120%;
							color: #4d4d4f;
							padding: 0.549rem 0.778rem;
							border-radius: 0.6rem;
							&.active{
								box-shadow: 0 0 0 0.6rem #118ACB;
							}
						}
						&:nth-child(1) {
							bottom: 1rem;
						}
						&:nth-child(4) {
							top: 0.5rem;
							.btn {
								width: 14rem;
							}
						}
						&:nth-child(5) {
							.btn {
								width: 14rem;
							}
						}
						&:nth-child(6) {
							.btn {
								width: 14rem;
							}
						}
						&:nth-child(7) {
							bottom: 0.5rem;
							.btn {
								width: 14rem;
							}
						}
						&:nth-child(10) {
							top: 1rem;
						}
					}
				}
			}
		}
		.industry-frame2 {
			max-width: 48.1rem;
			padding-top: 4rem;
			@media(max-width: 740px){
				padding-top: 40px;
				// position: fixed;
				// z-index: 1;
				// background: #fff;
				// left: 0;
				// right: 0;
				// bottom: 0;
				// padding: 4rem 2.5rem 4rem 2.5rem;
				// width: 100%;
				// max-width: 100%;
			}
			.industry-frame2-card {
				margin-top: 0rem;
				&:not(:first-child) {
					margin-top: 3.6rem;
				}
				& > header {
					display: flex;
					align-items: center;
					margin-bottom: 0.2rem;
					.img-wrap {
						width: 6rem;
						img {
							max-width: 5rem;
						}
					}
					.card-head-text {
						width: calc(100% - 6rem);
						.txt-dull {
							font-weight: 600;
							font-size: 1.4rem;
							line-height: 1.7rem;
							letter-spacing: 0.02em;
							text-transform: uppercase;
							color: rgba(77, 77, 79, 0.7);
							margin-bottom: 0.3rem;
						}
						.h1 {
							font-weight: bold;
							font-size: 1.8rem;
							line-height: 2.2rem;
							color: #4d4d4f;
						}
					}
				}
				& > section {
					padding-left: 6rem;
					font-size: 1.4rem;
					line-height: 150%;
					color: rgba(77, 77, 79, 0.7);
					@media(max-width: 740px){
						padding-left: 0;
					}
					p {
						max-width: 28.7rem;
						margin-bottom: 1rem;
					}
				}
			}
			.dimension-btn-clicked-content {
				position: relative;
				@media(max-width: 740px){
					height: auto;
					overflow: auto;
					position:fixed;
					z-index: 110;
					top:60px;
					bottom:0;
					left:0;
					right:0;
					width:100%;
					&.active:before {
						content: '';
						display: block;
						background: rgba(0,0,0,0.2);
						position: fixed;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
				.over-flow-auto-wrapper {
					height: max-content;
					overflow-x: hidden;
					overflow-y: visible;
					padding-bottom: 1rem;
					@media(max-width:740px){
						z-index:1;
						position:relative;
						padding:20px 15px;
						background:#fff;
						height: 100%;
					}
					.industry-frame2-card {
						margin-top: 0;
						& > header {
							margin-bottom: 0.7rem;
						}
						& > section {
							color: #4d4d4f;
							p {
								max-width: 100%;
							}
							.btn-wrapper {
								padding-top: 1.5rem;
								display: flex;
								align-items: center;
								.btn-learn-more {
									display: inline-flex;
									align-items: center;
									justify-content: center;
									background: linear-gradient(91.83deg, #139bd6 12.22%, #0789cf 100.41%);
									border:1px solid #118ACB;
									border-radius: 0.5rem;
									padding: 1.6rem 3.1rem;
									min-width: 16.4rem;
									font-weight: 600;
									font-size: 1.5rem;
									line-height: 1.8rem;
									display: flex;
									align-items: center;
									text-align: center;
									color: #ffffff;
									margin-right: 1.6rem;
									animation: impulse 1.5s infinite;
									&.wt-btn{
										color: #118acb;
										background: #ffffff;
										border-color: #118acb;
										animation: none;
									}
									@keyframes impulse {
										0% {
											box-shadow: 0 0 0 0 rgba(19, 155, 214, 1);
										}
										70% {
											box-shadow: 0 0 0 10px rgba(19, 155, 214, 0);
										}
										100% {
											box-shadow: 0 0 0 50px rgba(19, 155, 214, 0);
										}
									}
								}
								.btn-watch-course {
									font-weight: 600;
									font-size: 1.5rem;
									line-height: 1.8rem;
									display: inline-flex;
									align-items: center;
									text-align: center;
									color: #118acb;
								}
							}
						}
						.course-vid-wrapper{
							margin-bottom:1.6rem;
							a{
								&.course-vid-thmb{
									display:block;
									position:relative;
									background: #F6F7FD;
									z-index:0;
									img{
										max-width: 100%;
										height: auto;
									}
									.ply-btn{
										background:#43A047;
										width:4.5rem;
										height:4.5rem;
										border-radius:50%;
										position:absolute;
										top:50%;
										left:50%;
										transform:translate(-50%,-50%);
										z-index:1;
										display:flex;
										align-items:center;
										justify-content:center;
										pointer-events:none;
										svg{
											width: 1.8rem;
											height: 1.8rem;
											position: relative;
    										left: 5%;
									    }
									}
								}
							}
						}
					}
				}

				.blue-box {
					background: #ddebff;
					border-radius: 0.5rem;
					padding: 1.1rem 1.4rem 1.1rem 8.6rem;
					width: 38.6rem;
					color: #4d4d4f;
					position: absolute;
					left: -2.6rem;
					top: 40.5rem;
					@media(max-width: 1024px){
						left: auto !important;
						top: 54.5rem !important;
						right: 1rem;
					}
					@media(max-width: 740px){
						position: relative;
						margin-top: 20px;
						width: 100%;
						top: auto !important;
						left: auto !important;
						right: auto !important;
					}
					&:before {
						content: "";
						height: 1.5rem;
						width: 1.5rem;
						display: inline-block;
						background: #ddebff;
						border-radius: 0.2rem;
						position: absolute;
						left: 0.2rem;
						transform: scalex(2) rotate(45deg);
					}
					.h1 {
						font-weight: 600;
						font-size: 1.6rem;
						margin-bottom: 0.9rem;
						line-height: 140%;
					}
					p {
						font-size: 1.4rem;
						line-height: 140%;
						margin-bottom: 0.9rem;
						.b {
							font-weight: 600;
							a{
								color: #4d4d4f !important;
							}
						}
					}
					.img-div {
						position: absolute;
						left: 1.18rem;
						top: 1.13rem;
						img {
							max-width: 6.382rem;
						}
					}
				}
			}
		}
	}
}
.industry-frame-sec {
	display: none;
	&.active {
		display: block;
	}
}
.dimention-close-btn{
	display:none;
	position:absolute;
	right:15px;
	top:15px;
	border:1px solid #4d4d4f;
	border-radius:50%;
	background:#fff;
	width:25px;
	height:25px;
	line-height:0;
	svg {
		    width: 10px;
			path{
				fill:#4d4d4f;
			}
		}
}
@media (max-width:1024px){
	.industry40-main-wrapper .industry-frame-wrapper .industry-frame2 .dimension-btn-clicked-content .over-flow-auto-wrapper .industry-frame2-card > section .btn-wrapper{
        flex-wrap: wrap !important;
    }
    .industry40-main-wrapper .industry-frame-wrapper .industry-frame2 .dimension-btn-clicked-content .over-flow-auto-wrapper .industry-frame2-card > section .btn-wrapper .btn-watch-course{
        margin-top: 20px !important;
    }
}
@media(max-width:740px){
	.industry40-main-wrapper .industry-frame-wrapper .industry-frame2 .dimension-btn-clicked-content .over-flow-auto-wrapper .industry-frame2-card > section .btn-wrapper{
        flex-wrap: nowrap !important;
    }
	.dimention-close-btn{
		display:flex;
		align-items:center;
		justify-content:center;
		z-index:1;
	}
}
@media(max-width:370px){
	.industry40-main-wrapper .industry-frame-wrapper .industry-frame2 .dimension-btn-clicked-content .over-flow-auto-wrapper .industry-frame2-card > section .btn-wrapper{
        flex-wrap: wrap !important;
    }
}
`

export const PopupContent = styled.div`
.vid__wrapper{
	position:relative;
	.popup-close-btn{
		 width:2.5rem;
		 height:2.5rem;
		 position:absolute;
		 top:1rem;
		 right:1rem;
		 background:#fff;
		 border-radius:0.4rem;
		 display:flex;
		 align-items:center;
		 justify-content:center;
		 line-height: 0;
		 z-index:1;
		 box-shadow: 0px 0px 5px 0.6px rgba(0,0,0,.1);
		 svg{
			width:1.4rem;
		 }
	}
  }
`
	