/** imported components

  * NotificationView ===> custom view component.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { NotificationView } from "./notification-view";
//NotificationController start
export const NotificationController = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const [refresh, setRefresh] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	let timezone = localStorage.getItem("gettimezone");
	timezone = timezone.replace(/^"(.*)"$/, '$1');
	useEffect(() => {
		const search = async () => {
			const apiUrl = `/notification?type=all&uid=${usid}&currentTimeZone=${timezone}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, [refresh]);
	//custom view component
	return <NotificationView notificationData={results} setHasNewNotification={props.setHasNewNotification} onRefresh={setRefresh} {...props} />;
};
