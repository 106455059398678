export const closeSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<rect width="32" height="32" rx="2" fill="#F0F0F0"/>
<path d="M11.1 22.3C10.7134 22.6866 10.0866 22.6866 9.7 22.3C9.3134 21.9134 9.3134 21.2866 9.7 20.9L13.8929 16.7071C14.2834 16.3166 14.2834 15.6834 13.8929 15.2929L9.7 11.1C9.3134 10.7134 9.3134 10.0866 9.7 9.7C10.0866 9.3134 10.7134 9.3134 11.1 9.7L15.2929 13.8929C15.6834 14.2834 16.3166 14.2834 16.7071 13.8929L20.9 9.7C21.2866 9.3134 21.9134 9.3134 22.3 9.7C22.6866 10.0866 22.6866 10.7134 22.3 11.1L18.1071 15.2929C17.7166 15.6834 17.7166 16.3166 18.1071 16.7071L22.3 20.9C22.6866 21.2866 22.6866 21.9134 22.3 22.3C21.9134 22.6866 21.2866 22.6866 20.9 22.3L16.7071 18.1071C16.3166 17.7166 15.6834 17.7166 15.2929 18.1071L11.1 22.3Z" fill="#0D2C54"/>
</svg>`
export const imageUploadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
<rect x="0.5" y="0.5" width="79" height="79" rx="7.5" fill="white" stroke="#CCD6E8"/>
<mask id="mask0_531_21969" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="24" y="24" width="32" height="32">
  <rect x="24" y="24" width="32" height="32" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_531_21969)">
  <path d="M38.666 41.3346H30.666V38.668H38.666V30.668H41.3327V38.668H49.3327V41.3346H41.3327V49.3346H38.666V41.3346Z" fill="#2C55A0"/>
</g>
</svg>`

export const crossIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<circle cx="12" cy="12" r="11.5" fill="white" stroke="#4D4D4F"/>
<mask id="mask0_531_20825" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16">
  <rect x="4" y="4" width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_531_20825)">
  <path d="M8.26732 16.6654L7.33398 15.732L11.0673 11.9987L7.33398 8.26536L8.26732 7.33203L12.0007 11.0654L15.734 7.33203L16.6673 8.26536L12.934 11.9987L16.6673 15.732L15.734 16.6654L12.0007 12.932L8.26732 16.6654Z" fill="#4D4D4F"/>
</g>
</svg>`

export const zoomCloseIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<rect width="32" height="32" rx="4" fill="white"/>
<mask id="mask0_531_19937" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
  <rect width="32" height="32" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_531_19937)">
  <path d="M8.53268 25.3346L6.66602 23.468L14.1327 16.0013L6.66602 8.53464L8.53268 6.66797L15.9993 14.1346L23.466 6.66797L25.3327 8.53464L17.866 16.0013L25.3327 23.468L23.466 25.3346L15.9993 17.868L8.53268 25.3346Z" fill="#4D4D4F"/>
</g>
</svg>`

