import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HomeTour } from "./home-tour";

export const HomeTourView = (props) => {
	const [tabIndex, setTabIndex] = useState(0);
	const HandleViewTab = (index) => {
		setTabIndex(index);
	};
	const [isOnboarding, setIsOnboarding] = useState(true);
	const HandleOnboadering = () => {
		setIsOnboarding(!isOnboarding);
	};
	return (
		<>
			<div className="home-index main-course-page">
				<div className="view_btn">
					<button className={tabIndex === 0 ? "view_btn_wrap active" : "view_btn_wrap"} onClick={() => HandleViewTab(0)}>
						Grid View
						<span className="view_icons">
							<img src={require("../../assets/img/home-index/grid_icon.svg").default} className="imd_grid_icon" alt="true" />
						</span>
					</button>
					<button className={tabIndex === 1 ? "view_btn_wrap active" : "view_btn_wrap"} onClick={() => HandleViewTab(1)}>
						List View
						<span className="view_icons">
							<img src={require("../../assets/img/home-index/list_icon.svg").default} className="imd_grid_icon" alt="true" />
						</span>
					</button>
				</div>
				{tabIndex === 0 ? (
					<>
						{/*grid view*/}
						<section className="main-training-sec">
							<h2 className="h2">
								<img
									src={require("../../assets/img/home-index/my-training-icon.svg").default}
									className="imd_partnership_logo"
									alt="true"
								/>
								My Trainings
							</h2>
							<div className="train-card-row-continer">
								<div className="training-card-row">
									<div className="training-card-col">
										<div className="master_wrapper">
											<div className="training_card_wrapp">
												<div className="trn_img_wrapper">
													<img src="/static/media/course-symb.e538e87b.svg" className="course_symb" alt="true" />
												</div>
												<p>
													Introduction to Industry 4.0
													<a href="#" className="strt_course_lnk">
														+ Continue course
													</a>
												</p>
											</div>
										</div>
									</div>{" "}
									{/* col end */}
									<div className="training-card-col">
										<div className="master_wrapper">
											<div className="training_card_wrapp">
												<div className="trn_img_wrapper">
													<img
														src={require("../../assets/img/home-index/course_sections-icon-1.svg").default}
														className="course_symb"
														alt="true"
													/>
												</div>
												<p>
													Available training courses
													<a href="#" className="strt_course_lnk">
														+ See All
													</a>
												</p>
											</div>
										</div>
									</div>{" "}
									{/* col end */}
									<div className="training-card-col">
										<div className="master_wrapper">
											<div className="training_card_wrapp">
												<div className="trn_img_wrapper">
													<img
														src={require("../../assets/img/home-index/course_sections-icon-2.svg").default}
														className="course_symb"
														alt="true"
													/>
												</div>
												<p>
													Training Courses
													<a href="#" className="strt_course_lnk">
														+ Watch product tour
													</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						{/* main-training-sec end   */}
						<section className="my-assessment main-training-sec">
							<h2 className="h2">
								<img
									src={require("../../assets/img/home-index/my-assessment-icon.svg").default}
									className="imd_partnership_logo"
									alt="true"
								/>
								My Assessment
							</h2>
							<div className="train-card-row-continer">
								<div className="training-card-row">
									<div className="training-card-col large">
										<div className="master_wrapper l-card">
											<div className="l-cont">
												<p>
													Claim your Free limited Digital Maturity Assessment
													<span> for the Factory of the Future Leadership</span>
												</p>
											</div>
											<div className="r-img">
												<img
													src={require("../../assets/img/home-index/claim.svg").default}
													className="claim_symb"
													alt="true"
												/>
											</div>
										</div>
									</div>{" "}
									{/* col end */}
									<div className="training-card-col">
										<div className="master_wrapper">
											<div className="training_card_wrapp">
												<div className="trn_img_wrapper bg-asst-blue">
													<img
														src={require("../../assets/img/home-index/porduct-tour-card.svg").default}
														className="course_symb"
														alt="true"
													/>
												</div>
												<p>
													Digital Maturity Assessment
													<Link to="/assessment-tour" className="strt_course_lnk">
														+ Watch product tour
													</Link>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</>
				) : (
					tabIndex === 1 && (
						<>
							{/*List view*/}
							<section className="main-training-sec">
								<h2 className="h2">
									<img
										src={require("../../assets/img/home-index/my-training-icon.svg").default}
										className="imd_partnership_logo"
										alt="true"
									/>
									My trainings
								</h2>
								<div className="indx-home-list-ul">
									<div className="indx-home-list-li">
										<span className="right-angle">
											<img src={require("../../assets/img/home-index/green-right-arrow.svg").default} alt="true" />
										</span>
										<span className="text green">
											Start your
											<a href="#" className="link">
												Introduction to Industry 4.0
											</a>
											training course
										</span>
										<span className="work-status green">To do</span>
									</div>
									<div className="indx-home-list-li">
										<span className="right-angle">
											<img src={require("../../assets/img/home-index/green-right-arrow.svg").default} alt="true" />
										</span>
										<span className="text green">
											Watch the
											<a href="#" className="link">
												Training Courses Product Tour
											</a>
										</span>
										<span className="work-status green">To do</span>
									</div>
								</div>
							</section>
							{/* main-training-sec end   */}
							<section className="my-assessment main-training-sec">
								<h2 className="h2">
									<img
										src={require("../../assets/img/home-index/my-assessment-icon.svg").default}
										className="imd_partnership_logo"
										alt="true"
									/>
									My Assessment
								</h2>
								<div className="indx-home-list-ul">
									<div className="indx-home-list-li">
										<span className="right-angle">
											<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
										</span>
										<span className="text blue">
											Claim the
											<a href="#" className="link">
												Free limited Digital Maturity Assessment
											</a>
											section
										</span>
										<span className="work-status green">To do</span>
									</div>
									<div className="indx-home-list-li">
										<span className="right-angle">
											<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
										</span>
										<span className="text blue">
											Watch the
											<a href="#" className="link">
												Digital Maturity Assessment Product Tour
											</a>
										</span>
										<span className="work-status green">To do</span>
									</div>
								</div>
							</section>
						</>
					)
				)}
			</div>
			<HomeTour isOnboarding={isOnboarding} HandleOnboadering={HandleOnboadering} />
			{/* onboarding walkthrough end*/}
		</>
	);
};
