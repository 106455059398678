import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import {LearningCenterView} from './LearningCenterView';
import serviceProvider from '../../services/axios'

export const LearningCenterController = (props) => {

    const [results, setResults] = useState([]);
    const [defaultData,setDefaultData]=useState([]);
    const [filterFaq, setFilterFaq] = useState(""); 
    const user = JSON.parse(localStorage.getItem("userData"));
	const login = localStorage.getItem("isLoggedIn");
    const usid = user ? user.uid : 0;
	const progress = user ? user.progress : 0;
    const plant_slug = user ? user.plant_slug : 0;
    const gid = user ? user.group_id : 0;
	if (progress<100 && (gid == 14 || gid == 2)) {
         return <Redirect to={`/assessment/${plant_slug}`} />;
    }
    useEffect(() => {
        props.handleExpandFal();
        const search = async () => {
            const apiUrl = `/home?param=faqdata&filterFaq=${filterFaq}&uid=${usid}`;
            const { data } = await serviceProvider.get(apiUrl,true,{});
            setDefaultData(data);
            setResults(data);
        };
        search();
    }, [filterFaq]);

    return (
        <>
            <LearningCenterView faqData={results} defaultData={defaultData} onFilter={setFilterFaq} {...props} />
        </>
    );
}

