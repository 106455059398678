import React, { useState, useEffect, useRef } from 'react';
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import {
    MainWrapper,
    HowToUseBtn,
    HowToUsePopWrapper,
    HowToUsePopWrap,
    HowToUsePopHeader,
    HowCloseBtn,
    HowToUsePopBody,
    HowToUseHeadBottom,
    HowToUseToolTipWrap,
    HowToUseTooltip,

} from './styles';
import {
    closeIcon, howIcon, mobileIcon,
} from './svg';

export const HowToUseView = (props) => {
    const {arabicUrl=require(`../../assets/video/Industry-4.0-Sustainable-Development-Webinar.mp4`).default, 
           englishUrl,
           assignUrl=require(`../../assets/video/IIoT-Solutions-Platform-How-to-invite-assign-users-SIRI-Assessments.mp4`).default,
           emailUrl=require(`../../assets/video/IIoT-Solutions-Platform-How-invitees-find-email-and-join-SIRI-Assessments.mp4`).default,
           dataUrl=require(`../../assets/video/IIoT-Solutions-Platform-After-Data-Submission-SIRI-Assessments.mp4`).default
        } = props;
    const [openPop, setOpenPop] = useState(false);
    const [isShareAutoPlay, setIsShareAutoPlay] = useState(true);
    const [tooltipPop,setTooltipPop] = useState(false);
    const plyrRef = React.useRef();
    const [activeBtn, setActiveBtn]= useState(`english`);
    const {signUp} = props;
    const handleVideoPopup = (data)=>{
        setOpenPop(true);
        setTooltipPop(false);
        setActiveBtn(data);
    }
    const handleClosePop =()=>{
        setOpenPop(false);
        setTooltipPop(false);
    }

    return (
        <>
            <MainWrapper data-component="MainWrapper">
                <HowToUseToolTipWrap data-component="HowToUseToolTipWrap" className={signUp===`sign-up`?`signup`:''}>
                    <HowToUseBtn data-component="HowToUseBtn" onClick={() => {signUp===`sign-up`?setOpenPop(true):setTooltipPop(!tooltipPop)}}>
                        <i className="icon-svg sm-hide" dangerouslySetInnerHTML={{ __html: howIcon }}></i>
                        <span className='how-txt sm-hide'>How to use</span>
                        <i className="icon-svg sm-visible" dangerouslySetInnerHTML={{ __html: mobileIcon }}></i>
                    </HowToUseBtn>
                    {tooltipPop &&
                        <HowToUseTooltip data-component="HowToUseTooltip">
                            <div className='tooltip-text'>
                                <button onClick={()=> handleVideoPopup(`assignInvite`)}>How to invite / assign users</button>
                                <button onClick={()=>handleVideoPopup(`emailJoin`)}>How invitees find email and join</button>
                                <button onClick={()=>handleVideoPopup(`dataSubmission`)}>What you get after Data Submission</button>
                            </div>
                        </HowToUseTooltip>
                    }
                </HowToUseToolTipWrap>
                {openPop &&
                    <HowToUsePopWrapper data-component="HowToUsePopWrapper">
                            <HowToUsePopWrap data-component="HowToUsePopWrap">
                                <HowToUsePopHeader data-component="HowToUsePopHeader">
                                    <span className='head-txt'>
                                        {activeBtn === `assignInvite`?`How to invite / assign users`
                                            : activeBtn === `emailJoin`?`How invitees find email and join`
                                            : activeBtn === `dataSubmission`?`What you get after Data Submission`
                                            :`IIoT Solutions Platform Sign Up Process (Assessment)`
                                            }
                                    </span>
                                    <HowCloseBtn data-component="HowCloseBtn" onClick={() => handleClosePop()}>
                                        <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                    </HowCloseBtn>
                                </HowToUsePopHeader>
                                <HowToUseHeadBottom data-component="HowToUseHeadBottom">
                                    <p>
                                            {activeBtn === `assignInvite`?`Learn how to invite and assign colleagues to conduct Assessment`
                                            : activeBtn === `emailJoin`?`Learn how the invitees receive email and can sign up to the platform`
                                            : activeBtn === `dataSubmission`?`Learn on what to expect after the data is submitted for Assessment`
                                            :`Learn how to sign up in the platform and complete Assessment`
                                            }
                                    </p>
                                    <div className='button-wrap'>
                                        <button className={`btn dark-btn`} onClick={()=>setActiveBtn(`english`)}>English</button>
                                        <button className={`btn ${activeBtn === `arabic`?'dark-btn':`light-btn`}`} onClick={()=>setActiveBtn(`arabic`)} style={{display:'none'}}>Arabic</button>
                                    </div>
                                </HowToUseHeadBottom>
                                <HowToUsePopBody data-component="HowToUsePopBody">
                                <div className="video-pop">
                                    <Plyr
                                    ref={plyrRef}
                                    source={{
                                        type: "video",
                                        sources: [
                                        {
                                            src: activeBtn===`arbic`?arabicUrl
                                            : activeBtn === `assignInvite`?assignUrl
                                            : activeBtn === `emailJoin`?emailUrl
                                            : activeBtn === `dataSubmission`?dataUrl
                                            :englishUrl,
                                            type: "video/mp4",
                                        },
                                        ],
                                        autoplay: isShareAutoPlay,
                                    }}
                                    options={{
                                        controlshidden: true,
                                    }}
                                    />
                                </div>
                                </HowToUsePopBody>
                            </HowToUsePopWrap>
                    </HowToUsePopWrapper>
                    }
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

