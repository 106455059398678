import React, { memo } from 'react';
import './pwerbi-view-style.scss';
import { barChart1, barChart2, donutChart } from './powerBi-svg'
const PoweBiChartView = (props) => {
    const headerBox = [
        {
            p: 'Plant Name',
            h2: 'Demo Plant'
        },
        {
            p: 'SKU',
            h2: '543'
        },
        {
            p: 'Total FTEs',
            h2: '178 FTEs'
        },
        {
            p: 'Production Volume',
            h2: '67th Tons'
        },
        {
            p: 'Cost of Conversion incl. Waste',
            h2: '115 Mn USD'
        },
        {
            p: 'Digital Maturity Index',
            h2: '44%'
        },
        {
            p: 'Digital Maturity Index',
            h2: '44%'
        },
    ]
    const TransformationFilter = ['Acceleration', 'Amplification', 'Foundation'];
    const productionFilter = [
        '360 Factory Visibility',
        'Tech-Augmented Workforce',
        'Data-Driven Maintenance',
        '',
        'Digital-Ready Infrastructure',
        '',
        'Intelligent Quality'
    ];
    const roadmapFilter = [
        'Augmented Reality (AR) Remote Support',
        'Energy Management Software (EMS)',
        'Computerised Maintenance Manag...',
        'Industrial Connectivity Infrastructure',
        'Condition Monitoring Systems',
        'Industrial Tablets',
        'Connected Workforce Platform',
        'Machine Vision',
        'Data Lake',
        'Predictive Maintenance Analytics',
        'Digital Twin',
        'Predictive Quality Analytics'
    ];
    return (
        <section className='powerbi-walkthourgh_30-03-2023'>
            <section className='powerbi-header'>
                {headerBox.map((item, index) => (
                    <div className="header-box" key={index}>
                        <p>{item.p}</p>
                        <h2>{item.h2}</h2>
                    </div>
                ))}
            </section>
            {/* header end */}

            <section className='powerbi-contents'>
                <div className='pwb-row'>
                    <div className='pwb-left'>
                        <div className='pwb-left-row margin-15rem'>
                            <h2 style={{ textAlign: 'center' }}> Transformation Stage Filter </h2>
                            {TransformationFilter.map((item, index) => (
                                <div className='pwb-card1' key={index}>
                                    <p>
                                        {item}
                                    </p>
                                </div>
                            ))}
                        </div>
                        {/*pwb -card1  row end */}
                        <div className='pwb-left-row' style={{ flex: 1 }}>
                            <h2> Production Process Filter </h2>
                            {productionFilter.map((item, index) => (
                                <div className='pwb-card2' style={{ visibility: item ? '' : 'hidden' }} key={index}>
                                    <p>
                                        {item}
                                    </p>
                                </div>
                            ))}
                        </div>
                        {/*pwb -card1  row end */}

                    </div>
                    <div className='pwb-mid'>
                        <div class="pwb-mid-card">
                            <div dangerouslySetInnerHTML={{ __html: barChart1 }}></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* powerbi-contents end */}

            <section className='powerbi-contents'>
                <div className='pwb-row'>
                    <div className='pwb-left'>
                        <div className='pwb-left-row' style={{ flex: 1 }}>
                            <h2> Roadmap Step Filter </h2>
                            {roadmapFilter.map((item, index) => (
                                <div className='pwb-card2' style={{ visibility: item ? '' : 'hidden' }} key={index}>
                                    <p>
                                        {item}
                                    </p>
                                </div>
                            ))}
                        </div>
                        {/*pwb -card1  row end */}

                    </div>
                    <div className='pwb-mid'>
                        <div class="pwb-mid-card">
                            <div dangerouslySetInnerHTML={{ __html: barChart2 }}></div>
                        </div>
                    </div>
                    <div className='pwb-right'>
                        <div class="pwb-right-card">
                            <div dangerouslySetInnerHTML={{ __html: donutChart }}></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* powerbi-contents end */}
        </section>
    );
}

export default memo(PoweBiChartView);

