/** imported components
  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * Helmet ===> for add specific title & CSS for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  *  DigitalPlanView ===> View component of this page

**/


import React, { useState, useEffect } from "react";
import { QuickStart } from "../quickstart/quickstart-ctrl";
import { DigitalPlanView } from "./digital-plan-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import "./digital-plan.scss";
//DigitalPlan component start
export const DigitalPlanController = (props) => {
	// defining variables here
	const [onTab, setOnTab] = useState(0);
	const hashvalue = window.location.hash;
	//Conditions for tabs
	useEffect(() => {
		if (hashvalue == "#startup") {
			setOnTab(1);
		}
		if (hashvalue == "#transforming") {
			setOnTab(2);
		}
		if (hashvalue == "#accelerating") {
			setOnTab(3);
		}
		if (hashvalue == "#thriving") {
			setOnTab(4);
		}
		props.handleExpandFal();
	}, []);

	return (
		<>
			{/* Page Title */}
			<Helmet>
				<title>Industry 4.0 Plant Archetypes | SmarterChains</title>
				<meta name="description" content="Industry 4.0 Plant Archetypes" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/learning-center">Resource Center</BreadcrumbsItem>
			<BreadcrumbsItem to="/digital-plant">Industry 4.0 Plant Archetypes</BreadcrumbsItem>
			{/* View component call */}
			<DigitalPlanView onTab={onTab} setOnTab={setOnTab} {...props} />
			
		</>
	);
};
