import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { Link, Redirect } from "react-router-dom";
import { MaturityDashboardAdminView } from "./maturity-dashboard-admin-view";
export const MaturityDashboardAdminController = (props) => {
	
	const tid = "Full-Maturity-Assessment";
	const tid2 = "Loss-Analysis";
	const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
	const [result, setResult] = useState(0)

	const [resp, setGitData] = useState({  fma: null,asu:[{assessment_user: 0, lossanalysisUser: 0,trainingUser: 0}] });
	if (1) {
    // window.location = "/cartmat-Full-Maturity-Assessment";
    return <Redirect to="/home" />;
  }
	
	useEffect(() => {
		    props.handleExpandFal();
			const fetchData = async () => {
					 const apiUrl1 = `/item?param=myplans&slug=${tid2}`;
					 const temp1 = await serviceProvider.get(apiUrl1, true, {});
					 
					 const apiUrl2 = `/item?param=myplans&slug=${tid}`;
					 const temp2 = await serviceProvider.get(apiUrl2,true,{});
					 
					 const apiUrl3 = `/plants/totalUser?user_id=${usid}&type=assessmentUser`;
					 const temp3 = await serviceProvider.get(apiUrl3,true, {});

					 const apiUrl4 = `/home?param=lossprogressadmin&uid=${usid}`;
					 const temp4 = await serviceProvider.get(apiUrl4,true,{});
					
					
					setResult(temp4.data[0]?.lossadminprogress);
			         setGitData({ lossbuy: temp1.data, fmabuy: temp2.data, asu:temp3.data[0] });
			};

    fetchData();
  }, []);
	
	
	
	
	//console.log("resp11",resp.loss)
  return <MaturityDashboardAdminView resp={resp} lossprogresResult={result} {...props} />
};
