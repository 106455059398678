/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * .scss for ===> styling css file for this page.

**/
import React, { useState, useEffect, useRef } from "react";
// import { VideoPopupView } from "./video-popup-view";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import "./video-popup.scss";
// const VideoPopupController = ({ label, options, selected, onSelectedChange }) => {
//VideoPopupControlle start
export const VideoPopupController = ({ popupState, url }) => {
	//defining variables here
	const [open, setOpen] = useState(popupState);
	const ref = useRef();
	useEffect(() => {
		if (url) {
			setOpen(true);
		}
	}, [popupState]);
	useEffect(() => {
		const onBodyClick = (event) => {
			if (ref.current && ref.current.contains(event.target)) {
				return;
			} else {
				setOpen(false);
			}
		};

		document.body.addEventListener("click", onBodyClick);
		// cleanup
		return () => {
			document.body.removeEventListener("click", onBodyClick);
		};
	}, []);
	return (
		// this section has videopopup
		<div className={`videobox ${open ? "visible active" : ""}`}>
			{/* this section has popup content */}
			<div className="videoPopup">
				{/* this section has player */}
				<div ref={ref} className="videoPopup-content">
					{/* <div style={{ marginBottom: "1rem", textAlign: "right" }}>
						<button onClick={() => setOpen(!open)}>
							<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="true" />
						</button>
					</div> */}
					<Plyr
						source={{
							type: "video",
							sources: [
								{
									src: url,
									type: "video/mp4",
								},
							],
						}}
						options={{
							controls: ["play-large", "play", "progress", "current-time", "duration", "mute", "volume", "settings", "fullscreen"],
						}}
					/>
				</div>
			</div>
		</div>
	);
};
