/** imported components

  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * SupportTilteImg,
    CrossImg,
    CheckBox,
    ThreeDots,
    ViewCollapseIcon,
    SqureChk,
    SmarterChainsLogo,
    supportQuestion,
    rightArrow, ===> svg images component


**/

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import $, { isEmptyObject } from "jquery";
import { Collapse, Modal } from "@material-ui/core";
import { Helmet } from "react-helmet";
import serviceProvider from "../../services/axios";
import { apiEndpoints } from "../../services/api-endpoints";
import {
  SupportTilteImg,
  CrossImg,
  CheckBox,
  ThreeDots,
  ViewCollapseIcon,
  SqureChk,
  SmarterChainsLogo,
  supportQuestion,
  rightArrow,
} from "./svg";
//support options variable
const supportOptions = [
  {
    key: 1,
    value: "The reason code is not clear",
    hasSub: false,
    commentRequired: false,
  },
  {
    key: 2,
    value: "I would like to report a visual bug",
    hasSub: false,
    commentRequired: true,
  },
  {
    key: 3,
    value: "I would like to provide additional information",
    hasSub: false,
    commentRequired: true,
  },
];
//LossSupportView component start
export const LossSupportView = (props) => {
  //defining variables here
  const { close, isTicket, setIsTicket, slug, sid, ssid, qid, pid, uid } =
    props;
  const [isEdit, setIsEdit] = useState(false);
  const [isSupActDdl, setIsSupActDdl] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isSubAns, setIsSubAns] = useState(false);
  const [isDelPopup, setIsDelPopup] = useState(false);
  const [tempDelid, setTempDelid] = useState(0);
  const [tempCmntDelid, setTempCmntDelid] = useState(0);
  const [clickedTicketDetail, setClickedTicketDetail] = useState("");
  const [loadSupportOptions, setLoadSupportOptions] = useState([
    ...supportOptions,
  ]);
  const [error, setError] = useState("");
  const [commentRequired, setCommentRequired] = useState(false);
  const [payload, setPayload] = useState({
    ques_id: 0,
    user_id: props.uid,
    plant_id: props.pid,
    feedon: "",
    feedans: [],
    comment: false,
    fbdbid: 0,
    status: 1,
  });
  const [chat, setChat] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hashvalue, setHashvalue] = useState(window.location.hash);
  useEffect(() => {
    getFeedbacks();
    setError("");
    setIsSubAns(false);
    let latestPayload = { ...payload, feedon: [] };
    setPayload({ ...latestPayload, comment: "" });
    setIsTicket(false);
    close(false);
  }, [qid]);
  //Function to handle dropdown list
  const HandleDdl = (val) => {
    if (val === isSupActDdl) {
      setIsSupActDdl(false);
    } else {
      setIsSupActDdl(val);
    }
  };
  //Delete function
  const deleteFn = () => {
    if (tempDelid) {
      setIsDelPopup(false);
      setClickedTicketDetail("");
    } else if (tempCmntDelid) {
      let tmpClickdata = clickedTicketDetail;
      console.log(tmpClickdata);
      let tmpArry = clickedTicketDetail.comments.filter(
        (item, index) => item.id != tempCmntDelid
      );
      console.log(tmpArry);
      tmpClickdata.comments = tmpArry;
      console.log(tmpClickdata);
      setClickedTicketDetail(tmpClickdata);

      setIsDelPopup(false);
    }
  };
  //Function to add comment
  const addComment = (e) => {
    setPayload({ ...payload, comment: e.target.value });
  };
  //Resize functionality for textarea start
  let resize = (text) => {
    text.style.height = "5.8rem";
    text.style.height = text.scrollHeight + "px";
  };
  const addChat = (e) => {
    setChat(e.target.value);
    if (e.target.scrollHeight > 58) {
      setTimeout(() => {
        resize(e.target);
      }, 0);
    }
  };
  const HandleTextResize = (e) => {
    if (e.scrollHeight > 58) {
      setTimeout(() => {
        console.log("h");
        resize(e);
      }, 0);
    }
  };
  //end
  //Function to handle edit reply
  const handleEditRplyTxt = (id, action, msg) => {
    let replyticket = document.getElementById(id);
    console.log(replyticket);
    switch (action) {
      case "open":
        replyticket.classList.add("edit");
        const input = replyticket.querySelector(".sup-reply-div textarea");
        input.value = msg;
        input.focus();
        input.selectionStart = input.value.length;
        input.selectionEnd = input.value.length;
        HandleTextResize(replyticket.querySelector(".sup-reply-div textarea"));
        break;

      case "close":
        replyticket.classList.remove("edit");
        break;
      default:
        "";
    }
    setIsSupActDdl(false);
  };
  //Function to save edit reply
  const saveEditRply = async (cardId, cmnt, cmntIndx) => {
    let replyticket = document.getElementById(cardId);
    let txtVal = replyticket.querySelector(".sup-reply-div textarea").value;
    const tmpCmntDetail = { ...clickedTicketDetail };
    let tmpCmntArray = [...clickedTicketDetail.comments];
    tmpCmntArray[cmntIndx].msg = txtVal;
    tmpCmntDetail.comments = tmpCmntArray;
    setClickedTicketDetail(tmpCmntDetail);
    replyticket.classList.remove("edit");

    try {
      if (txtVal) {
        const { id } = clickedTicketDetail;
        await serviceProvider.post(
          "/losssupport/editfeedbackcomment",
          {
            qid: props.qid,
            pid: props.pid,
            uid: props.uid,
            ticket_id: cmnt.id,
            msg: txtVal,
          },
          true
        );
        const response = await serviceProvider.post(
          "/losssupport/getcommentsforticket",
          {
            section_id: props.sid,
            sub_section_id: props.ssid,
            type: props.type,
            qid: qid,
            pid: props.pid,
            uid: props.uid,
            ticket_id: cmnt.id,
          },
          true
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  //Function to handle edit
  const HandleEdit = (id) => {
    setError("");
    const feedback = feedbacks.find((feed) => feed.id === id);
    setPayload({
      ...payload,
      feedon: feedback.feedon,
      feedans: feedback.feedans,
      comment: feedback.comment,
      fbdbid: +id,
    });
    supportOptions.forEach((option) => {
      if (option.value === feedback.feedon) setIsSubAns(option.key);
    });
    setIsSupActDdl(false);
    setIsTicket(true);
    setIsEdit(true);
    setClickedTicketDetail("");
  };
  //Function to handle delete
  const HandleDelete = (val, type) => {
    // scnv-2251
    if (type == "cmnt") {
      setTempCmntDelid(+val);
      setIsSupActDdl(false);
      setIsDelPopup(true);
    }
    // scnv-2251
    else {
      setTempDelid(+val);
      setIsSupActDdl(false);
      setIsDelPopup(true);
    }
  };
  //Function to handle details collapse
  const HandleDetailsCollapse = (id) => {
    let tmpObj = { ...isCollapse };
    let name = [`collapse${id}`];
    let val = !tmpObj[name];
    setIsCollapse({
      ...tmpObj,
      [name]: val,
    });
  };
  // Function to handle sub answer list
  const HandleSubAnsList = (e) => {
    let val = e.target.value;
    console.log(val);
    const option = supportOptions.find((opt) => opt.key === +val);
    if (option) {
      let latestPayload = { ...payload, feedon: option.value };
      if (+val !== 2) latestPayload = { ...latestPayload, feedans: [] };
      setPayload(latestPayload);
    }
    setIsSubAns(val);
    setError("");
    setCommentRequired(false);
  };
  const itemStatus = {
    1: "orange",
    2: "yellow",
    3: "green",
    4: "blue",
  };
  //Function to handle helpfull
  const HandleHelpfull = async (e, id, type) => {
    let targetTmp = e.currentTarget;
    targetTmp.parentElement.style.display = "none";
    await serviceProvider.post(
      "/losssupport/helpfull",
      { ticket_id: id, type: type },
      true
    );
  };
  // functionality-03-07-2022 start
  const cleanUpPayload = () =>
    setPayload({
      ...payload,
      feedon: "",
      feedans: [],
      comment: false,
      fbdbid: 0,
      status: 1,
    });
  //Function for new ticket
  const newTicket = () => {
    setIsTicket(true);
    cleanUpPayload();
  };
  //Function to get feedbacks
  const getFeedbacks = async () => {
    if (!feedbacks.length) {
      setIsTicket(true);
    }

    const response = await serviceProvider.post(
      "/losssupport",
      {
        qid: props.qid,
        pid: props.pid,
        uid: props.uid,
        section_id: props.sid,
        sub_section_id: props.ssid,
        type: props.type,
      },
      true
    );
    const { data: supportDetails } = response;
    if (supportDetails) {
      const { feedbacks } = supportDetails;
      if (Array.isArray(feedbacks));
      setFeedbacks(feedbacks);
      if (feedbacks?.length) {
        setIsTicket(false);
      } else {
        setIsTicket(true);
      }
      setLoading(false);

      if (hashvalue && hashvalue != null) {
        var abc = hashvalue.replace("#", "");
        var myArray = abc.split("-");
        window.location.hash='';
        setHashvalue(null);
        document.getElementById("support-blue-box-np").click();
        $("body").find("#adddd" + myArray[1]).click();
      }
    }
    setIsEdit(false);
  };
  //Function to get tickte details
  const getTicketDetails = async (item) => {
    const response = await serviceProvider.post(
      "/losssupport/getcommentsforticket",
      { qid: qid, pid: props.pid, uid: props.uid, ticket_id: item.id },
      true
    );
    const { data } = response;
    setClickedTicketDetail({ ...item, comments: data });
    setIsSupActDdl(false);
  };
  //Function to add feedback comment
  const addFeedbackComment = async () => {
    try {
      const { id } = clickedTicketDetail;
      await serviceProvider.post(
        "/losssupport/savefeedbackcomment",
        {
          section_id: props.sid,
          sub_section_id: props.ssid,
          type: props.type,
          qid: qid,
          pid: props.pid,
          uid: props.uid,
          ticket_id: id,
          msg: chat,
        },
        true
      );
      const response = await serviceProvider.post(
        "/losssupport/getcommentsforticket",
        {
          section_id: props.sid,
          sub_section_id: props.ssid,
          type: props.type,
          qid: qid,
          pid: props.pid,
          uid: props.uid,
          ticket_id: +id,
        },
        true
      );
      const { data: comments } = response;
      setClickedTicketDetail({ ...clickedTicketDetail, comments });
      setChat("");
    } catch (err) {
      console.error(err);
    }
  };
  //Function to save feedback
  const saveFeedback = async () => {
    try {
      payload["comment"] = payload["comment"] ? payload["comment"] : "";
      payload["ques_id"] = props.qid;
      payload["type"] = props.type;
      payload["section_id"] = props.sid;
      payload["sub_section_id"] = props.ssid;
      payload["question"] = props.question;

      const response = await serviceProvider.post(
        "/losssupport/savefeedback",
        payload,
        true
      );
      const { data } = response;

      if (data === 1) {
        setError("This ticket already exists.");
      } else {
        cleanUpPayload();
        getFeedbacks();
      }
    } catch (err) {
      console.error(err);
    }
  };
  //Function to delete
  const deleteFb = async () => {
    try {
      if (tempDelid) {
        await serviceProvider.post(
          "/losssupport/deletefb",
          { ticket_id: tempDelid },
          true
        );
        setIsDelPopup(false);
        setClickedTicketDetail("");
        cleanUpPayload();
        getFeedbacks();
      } else if (tempCmntDelid) {
        await serviceProvider.post(
          "/losssupport/deletefbc",
          { ticket_id: tempCmntDelid },
          true
        );
        getTicketDetails(clickedTicketDetail);
        setIsDelPopup(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  //Function for submit
  const handleSubmit = () => {
    const { feedans, comment } = payload;
    let hasErrors = false;
    let errorCase = "feedans";
    setError("");
    setCommentRequired(false);
    switch (+isSubAns) {
      case 1:
        if (!comment) {
          hasErrors = false;
          errorCase = "";
        }
        break;
      case 2:
        if (!comment) {
          hasErrors = true;
          errorCase = "comment";
        }
        break;
      case 3:
        if (!comment) {
          hasErrors = true;
          errorCase = "comment";
        }
        break;
      default:
    }

    if (payload["feedon"] === "") {
      hasErrors = true;
      errorCase = "feedon";
    }
    if (!hasErrors) {
      saveFeedback();
    } else {
      if (errorCase === "comment") setCommentRequired(true);
      else if (errorCase === "feedans") setError("Please select an answer");
      else setError("Please select at least one option");
    }
    //setIsTicket(false);
  };

  React.useEffect(() => {
    document.querySelector("body").onclick = (e) => {
      if (!e.target.closest(".ddl-action")) {
        setIsSupActDdl(false);
      }
    };
    if (hashvalue && hashvalue != null) {
      if (props.isAvailable == false) {
        document.getElementById("support-blue-box-np").click();
        getFeedbacks();
      }
    }

    document.onclick = (e) => {
      if (e.target.closest(".refreshReply")) {
        var hrefs = e.target
          .toString()
          .match(/(((https?:\/\/)|(www\.))[^\s]+)/g);
        if (hrefs.length) {
          var href = hrefs[hrefs.length - 1];
          if (window.location.href == href) {
            location.reload();
          } else {
            if (href.indexOf("#") > -1) {
              window.location.href = href;
              location.reload();
            }
          }
        }
      }
      if (!e.target.closest(".three-ddl")) {
        HandleDdl(false);
      }
    };
  }, []);

  if (hashvalue && hashvalue != null && props.isAvailable == true) {
    return <Redirect to="/not-found" />;
  }

  return (
    <>
      {/* css for styling page */}
      <Helmet>
        <link rel="stylesheet" href={require("./la-support.scss").default} />
      </Helmet>
      {/* popup main wrapper contains two sections
				pop-heading-wrap
				popup
			*/}
      <div className="right-sidebar-scroller">
        {/* popup heading wrapper contains two sections
					more-info-btn
					close-btn
				*/}
        <div className="pop-heading-wrap">
          <div className="moreinfo-btn">
            <img
              src={
                require("../../assets/img/la-questionnaires/more-info.svg")
                  .default
              }
              alt="Info"
              className="info-img"
            />
            <span className="text">More Information</span>
          </div>
          <button
            className="close-btn"
            onClick={(e) => props.HandleRightSecClose(e, null)}
          >
            <img
              src={
                require("../../assets/img/la-questionnaires/close-black.svg")
                  .default
              }
              alt="Close"
              className="close-img"
            />
          </button>
        </div>
        {/* this section contains two subsections 
					content-box
					support-blue-box
				*/}
        <div className="popup">
          <div className="content-box">
            <h3 className="popup-head">{props.popupHeadTxt}</h3>
            <p
              className="text"
              dangerouslySetInnerHTML={{ __html: props.des }}
            ></p>
          </div>
          {/* SCNV 1930 Loss Support Start Here */}

          {props.islock == 0 && props.isAvailable == false && (
            <div
              className="support-blue-box support-blue-box-np"
              id="support-blue-box-np"
              onClick={() => {
                close(true);
                getFeedbacks();
              }}
            >
              <div className="support-blue-img support-btn inactive">
                <span
                  dangerouslySetInnerHTML={{ __html: supportQuestion }}
                  className="blue-img"
                ></span>
                <span className="text">Support</span>
              </div>
              <p>Ask for help or provide additional information</p>
              <div className="right-arrow">
                <span dangerouslySetInnerHTML={{ __html: rightArrow }}></span>
              </div>
            </div>
          )}

          {/* loss support card wrapper has one card asst-sup-card */}
          {props.isLossSupport && (
            <div className="loss-support-wrapper">
              {!clickedTicketDetail ? (
                <>
                  {/* this section has three sub sections
									sup-card-header
									sup-card-body
									sup-card-footer
								*/}
                  <div className="asst-sup-card">
                    {/* header has two elements 
										heading 
										close-button
									*/}
                    <div className="sup-card-header">
                      <div className="sup-h1-heading">
                        <span
                          className="sup-heading-icon"
                          dangerouslySetInnerHTML={{
                            __html: SupportTilteImg,
                          }}
                        ></span>
                        <span>Support</span>
                        <button
                          className="sup-close-icon"
                          onClick={() => {
                            setIsTicket(false);
                            close(false);
                            setIsSupActDdl(false);
                            setIsCollapse(false);
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: CrossImg }}
                          ></span>
                        </button>
                      </div>
                    </div>
                    {/* sup-card-header end */}
                    {/* this section has 
										title
										question and their options checklist
										s1-ticket-card
									*/}
                    <div className="sup-card-body hover-ticket">
                      {isTicket ? (
                        <>
                          <h2 className="sup-body-title">
                            {props.popupHeadTxt}
                          </h2>
                          <p className="sup-body-h2">
                            {!!error && (
                              <>
                                <span className="sup-error-msg">{error}</span>{" "}
                                <br />
                              </>
                            )}
                            How can we help you?
                          </p>

                          {loadSupportOptions.map((option) => (
                            <div>
                              <label className="sup-checkbox">
                                <div className="svg-icon-div">
                                  <input
                                    type="radio"
                                    name="ansRad"
                                    value={option.key}
                                    checked={option.value === payload.feedon}
                                    onChange={(e) => HandleSubAnsList(e)}
                                  />
                                  <div
                                    className="svg-icon"
                                    dangerouslySetInnerHTML={{
                                      __html: CheckBox,
                                    }}
                                  ></div>
                                </div>
                                <div className="svg-text">
                                  <span>{option.value}</span>
                                </div>
                              </label>
                            </div>
                          ))}

                          <div className="additional-cmnt-div">
                            <h2 className="cmt-h2">Additional Comments</h2>
                            <div
                              className={`cmnt-txtarea-wrapper-div ${
                                commentRequired ? "show-error" : ""
                              }`}
                            >
                              <textarea
                                className="cmnt-txtarea"
                                onChange={addComment}
                              >
                                {payload.comment ? payload.comment : ""}
                              </textarea>
                              {commentRequired && (
                                <span className="error-msg">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <h2 className="sup-body-title">
                            {props.popupHeadTxt}
                          </h2>
                          <p className="sup-body-p"></p>
                          <div className="sup-mragin-min">
                            {feedbacks.map((item, ind) => (
                              /* this section has two subsections 
															header-ticket
															body-ticket
														*/
                              <div
                                className="s1-ticket-card"
                                id={`adddd${item.id}`}
                                onClick={() => getTicketDetails(item)}
                                style={{ cursor: "pointer" }}
                              >
                                <div className="header-ticket">
                                  <div className="lft-sec">
                                    <button
                                      className="ticket-url"
                                      id={`ad${item.id}`}
                                    >
                                      #{item.id}
                                    </button>
                                    <div
                                      className={`ddl-action ${
                                        isSupActDdl === item.id ? "active" : ""
                                      }`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      {item.is_replyed == 0 &&
                                        itemStatus[item.status] !== "green" && (
                                          <button
                                            className="three-ddl"
                                            onClick={() => HandleDdl(item.id)}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: ThreeDots,
                                              }}
                                            ></span>
                                          </button>
                                        )}

                                      <div className="ddl-list">
                                        <button
                                          className="btn-act"
                                          onClick={() => HandleEdit(item.id)}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn-act"
                                          onClick={() => HandleDelete(item.id)}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                    {+item.is_edited === 1 ? (
                                      <span className="edited">Edited</span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="rgt-sec">
                                    <span
                                      className={`status-tag ${
                                        item.is_replyed == 1 && item.status != 3
                                          ? itemStatus[4]
                                          : itemStatus[item.status]
                                      }`}
                                    >
                                      {itemStatus[item.status] === "green"
                                        ? "RESOLVED"
                                        : item.is_replyed == 0 &&
                                          itemStatus[item.status] === "yellow"
                                        ? "IN PROGRESS"
                                        : item.is_replyed == 1 &&
                                          itemStatus[4] === "blue"
                                        ? "ANSWERED"
                                        : "NEW"}
                                    </span>
                                  </div>
                                </div>
                                <div className="body-ticket">
                                  <p className="body-ticket-type">
                                    {item.feedon}
                                  </p>

                                  {item.comment && (
                                    <Collapse
                                      in={isCollapse[`collapse${item.id}`]}
                                    >
                                      <div className="body-ticket-detail">
                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                          {item.comment}
                                        </p>
                                      </div>
                                    </Collapse>
                                  )}

                                  {item.comment && (
                                    <div className="view-details-div">
                                      <button
                                        className={
                                          isCollapse[`collapse${item.id}`]
                                            ? "view-collapse collapsed"
                                            : "view-collapse"
                                        }
                                        onClick={(e) => {
                                          HandleDetailsCollapse(item.id);
                                          e.stopPropagation();
                                        }}
                                      >
                                        {isCollapse[`collapse${item.id}`]
                                          ? "View Less"
                                          : "View Details"}
                                        <span
                                          className="ddl-ico"
                                          dangerouslySetInnerHTML={{
                                            __html: ViewCollapseIcon,
                                          }}
                                        ></span>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    {/* sup-card-body end */}
                    {/* this section has four buttons 
										and buttons come conditionally
									*/}
                    <div className="sup-card-footer">
                      {!isEdit ? (
                        <>
                          {isTicket ? (
                            <button
                              className="btn-sup-sub a"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          ) : (
                            <button
                              className="btn-sup-sub b"
                              onClick={newTicket}
                            >
                              + New Ticket
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            className="btn-sup-cancel"
                            onClick={() => {
                              setIsEdit(false);
                              setIsTicket(false);
                              cleanUpPayload();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-sup-sub c"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </>
                      )}
                    </div>
                    {/* sup-card-footer end */}
                  </div>
                  {/* asst-sup-card 1 end*/}
                </>
              ) : (
                <>
                  <div className="asst-sup-card">
                    <div className="sup-card-header pl-less">
                      <div className="sup-h1-heading">
                        <button
                          className="sup-d-back"
                          onClick={() => {
                            setClickedTicketDetail("");
                            setHashvalue(null);
                            setIsCollapse(false);
                          }}
                        >
                          <img
                            src={
                              require("../../assets/img/assessment-support/back-btn.svg")
                                .default
                            }
                            alt=""
                          />
                        </button>
                        <span>#{clickedTicketDetail.id}</span>
                      </div>
                    </div>
                    {/* sup-card-header end */}
                    <div className="sup-card-body pb-2rem">
                      <h2 className="sup-body-title">{props.popupHeadTxt}</h2>
                      <div className="sup-mragin-min">
                        {/* This section has two parts 
												header ticket
												body ticket
											*/}
                        <div className="s1-ticket-card">
                          <div className="header-ticket">
                            <div className="lft-sec">
                              <span className="ticket-url">
                                #{clickedTicketDetail.id}
                              </span>
                              <div
                                className={`ddl-action ${
                                  isSupActDdl === `d${clickedTicketDetail.id}`
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {clickedTicketDetail.is_replyed == 0 &&
                                  itemStatus[clickedTicketDetail.status] !==
                                    "green" && (
                                    <button
                                      className="three-ddl"
                                      onClick={() =>
                                        HandleDdl(`d${clickedTicketDetail.id}`)
                                      }
                                    >
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: ThreeDots,
                                        }}
                                      ></span>
                                    </button>
                                  )}

                                <div className="ddl-list">
                                  <button
                                    className="btn-act"
                                    onClick={() =>
                                      HandleEdit(clickedTicketDetail.id)
                                    }
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="btn-act"
                                    onClick={() =>
                                      HandleDelete(clickedTicketDetail.id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                              {clickedTicketDetail.is_edited == 1 ? (
                                <span className="edited">Edited</span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="rgt-sec">
                              <span
                                className={`status-tag ${
                                  clickedTicketDetail.is_replyed == 1 &&
                                  clickedTicketDetail.status != 3
                                    ? itemStatus[4]
                                    : itemStatus[clickedTicketDetail.status]
                                }`}
                              >
                                {itemStatus[clickedTicketDetail.status] ===
                                "green"
                                  ? "RESOLVED"
                                  : clickedTicketDetail.is_replyed == 0 &&
                                    itemStatus[clickedTicketDetail.status] ===
                                      "yellow"
                                  ? "IN PROGRESS"
                                  : clickedTicketDetail.is_replyed == 1 &&
                                    itemStatus[4] === "blue"
                                  ? "ANSWERED"
                                  : "NEW"}
                              </span>
                            </div>
                          </div>
                          <div className="body-ticket">
                            <p className="body-ticket-type">
                              {clickedTicketDetail.feedon}
                            </p>
                            <p className="body-ticket-title">
                              {clickedTicketDetail.smallTitle}
                            </p>
                            {clickedTicketDetail.comment && (
                              <Collapse
                                in={
                                  isCollapse[
                                    `collapse_click${clickedTicketDetail.id}`
                                  ]
                                }
                              >
                                <div className="body-ticket-detail">
                                  <p>{clickedTicketDetail.comment}</p>
                                </div>
                              </Collapse>
                            )}

                            {clickedTicketDetail.comment && (
                              <div className="view-details-div">
                                <button
                                  className={
                                    isCollapse[
                                      `collapse_click${clickedTicketDetail.id}`
                                    ]
                                      ? "view-collapse collapsed"
                                      : "view-collapse"
                                  }
                                  onClick={() =>
                                    HandleDetailsCollapse(
                                      `_click${clickedTicketDetail.id}`
                                    )
                                  }
                                >
                                  {isCollapse[
                                    `collapse_click${clickedTicketDetail.id}`
                                  ]
                                    ? "View Less"
                                    : "View Details"}
                                  <span
                                    className="ddl-ico"
                                    dangerouslySetInnerHTML={{
                                      __html: ViewCollapseIcon,
                                    }}
                                  ></span>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {clickedTicketDetail.comments.length > 0 && (
                          <div className="s1-ticket-card">
                            <div className="body-ticket">
                              {!!clickedTicketDetail.comments.length && (
                                <div className="wrap-reply">
                                  {clickedTicketDetail.comments.map(
                                    (comment, cmntIndx) => (
                                      <>
                                        {!!+comment.is_admin && (
                                          <>
                                            <div className="sa-reply-logo">
                                              {comment.is_admin && (
                                                <img
                                                  src={
                                                    require("../../assets/img/assessment-support/logo-smarterchains.svg")
                                                      .default
                                                  }
                                                  alt="SmarterChains"
                                                />
                                              )}
                                            </div>
                                            <p className="reply-sa-txt a">
                                              {comment.msg}
                                            </p>
                                            {comment.is_helpful == 0 && (
                                              <div className="was-helpfull">
                                                <span>
                                                  Was this answer helpful?{" "}
                                                </span>
                                                <button
                                                  className="btn-usefull"
                                                  onClick={(e) =>
                                                    HandleHelpfull(
                                                      e,
                                                      comment.id,
                                                      1
                                                    )
                                                  }
                                                >
                                                  Yes
                                                </button>
                                                <button
                                                  className="btn-usefull"
                                                  onClick={(e) =>
                                                    HandleHelpfull(
                                                      e,
                                                      comment.id,
                                                      2
                                                    )
                                                  }
                                                >
                                                  No
                                                </button>
                                              </div>
                                            )}
                                          </>
                                        )}
                                        {!+comment.is_admin && (
                                          <div
                                            className="replied-ticket"
                                            id={`cmnt-${cmntIndx}`}
                                          >
                                            <div className="user-title-cir">
                                              {!!comment.profile_pic && (
                                                <>
                                                  <div className="sa-reply-logo">
                                                    <img
                                                      className="img_cir_aqa"
                                                      src={`${apiEndpoints.baseUrl}/assets/user-profile/${comment.profile_pic}`}
                                                      alt={
                                                        comment.name +
                                                        " " +
                                                        comment.surname
                                                      }
                                                    />
                                                  </div>
                                                  <span className="user-n">
                                                    {comment.name +
                                                      " " +
                                                      comment.surname}
                                                  </span>
                                                </>
                                              )}
                                              {!comment.profile_pic && (
                                                <>
                                                  <div
                                                    className={`cir-user ${comment.avcolor}`}
                                                  >
                                                    {comment.name.charAt(0) +
                                                      comment.surname.charAt(0)}
                                                  </div>
                                                  <span className="user-n">
                                                    {comment.name +
                                                      " " +
                                                      comment.surname}
                                                  </span>
                                                </>
                                              )}
                                              <div
                                                className={`ddl-action ${
                                                  isSupActDdl === comment.id
                                                    ? "active"
                                                    : ""
                                                }`}
                                              >
                                                {comment.reply_id == 0 && (
                                                  <button
                                                    className="three-ddl"
                                                    onClick={() =>
                                                      HandleDdl(comment.id)
                                                    }
                                                  >
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html: ThreeDots,
                                                      }}
                                                    ></span>
                                                  </button>
                                                )}
                                                <div className="ddl-list">
                                                  <button
                                                    className="btn-act"
                                                    onClick={() =>
                                                      handleEditRplyTxt(
                                                        `cmnt-${cmntIndx}`,
                                                        "open",
                                                        comment.msg
                                                      )
                                                    }
                                                  >
                                                    Edit
                                                  </button>
                                                  <button
                                                    className="btn-act"
                                                    onClick={() =>
                                                      HandleDelete(
                                                        comment.id,
                                                        "cmnt"
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="rp-text-div">
                                              <div className="sup-reply-div">
                                                <textarea
                                                  className="txt-sup-reply"
                                                  type="text"
                                                  placeholder="Reply"
                                                  onChange={(e) =>
                                                    HandleTextResize(e.target)
                                                  }
                                                ></textarea>
                                              </div>
                                              <div className="rply-btn-wrp">
                                                <button
                                                  className="btn-edt edt-s"
                                                  onClick={() =>
                                                    saveEditRply(
                                                      `cmnt-${cmntIndx}`,
                                                      comment,
                                                      cmntIndx
                                                    )
                                                  }
                                                >
                                                  Save
                                                </button>
                                                <button
                                                  className="btn-edt edt-c"
                                                  onClick={() =>
                                                    handleEditRplyTxt(
                                                      `cmnt-${cmntIndx}`,
                                                      "close"
                                                    )
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                            <p className="reply-sa-txt">
                                              {comment.msg}
                                            </p>
                                          </div>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              )}
                              {clickedTicketDetail.comments.length && (
                                <div className="sup-reply-div">
                                  <textarea
                                    className="txt-sup-reply"
                                    type="text"
                                    placeholder="Reply"
                                    value={chat}
                                    onChange={(e) => addChat(e)}
                                  ></textarea>
                                  <button
                                    className="send-rep-btn"
                                    onClick={addFeedbackComment}
                                    disabled={chat.length > 0 ? false : true}
                                    style={{
                                      opacity: chat.length > 0 ? 1 : 0.6,
                                    }}
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/assessment-support/send-reply.svg")
                                          .default
                                      }
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* sup-card-body end */}
                  </div>
                  {/* asst-sup-card 2 end */}
                </>
              )}
              {/* Delete popup */}
              {isDelPopup && (
                <div className="sup-ticket-bgpopup">
                  <div className="sup-ticket-delpopup">
                    <p className="txt-popup">
                      Are you sure you would like to delete this ticket
                      permanently?
                    </p>
                    <div className="popup-btn-div-flex">
                      <button
                        className="btn-act"
                        onClick={() => {
                          setTempDelid(0);
                          setIsDelPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button className="btn-act del" onClick={deleteFb}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* loss support card end*/}
          {/* SCNV 1930 Loss Support End Here */}
        </div>
      </div>
    </>
  );
};

{
  /* SCNV 2155 Loss Support start */
}

{
  /* SCNV 2155 Loss Support start */
}
