import styled from 'styled-components';


export const MainWrapper = styled.section`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    `;
export const UpgradePopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 9999;
    z-index: 2147483649;
`
export const UpgradePopWrap = styled.div`
  position: absolute;
  width: 118rem;
  height: calc(100vh - 1.5rem);
  background: #ffffff;
  border: 0.1rem solid #f0f0f0;
  border-radius: 2.4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  // @media(max-height: 567px){
  //     max-height: calc(100vh - 1rem);
  // }
  // @media(max-width:1024px){
  //     width: 700px;
  //     max-height: 500px;
  // }
  @media (max-width: 740px) {
    width: 100%;
    border-radius: 0;
    height: 100vh;
  }
`;
export const UpgradePopHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 0.06rem solid #CCD6E8;
    align-items: center;
    padding: 2.4rem;
    .head-txt{
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: #2c55a0;
    }
`
export const HowCloseBtn = styled.button`
    display: inline-block;
    border: unset;
    box-shadow: unset;
    height: 3.2rem;
    width: 3.2rem;
    svg{
        width: 100%;
        height: 100%;
    }
`
export const UpgradePopBody = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`
export const TabContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`
export const TabLeftSection = styled.div`
  overflow-y: auto;
  scrollbar-width: thisn;
  padding-top: 2.4rem;
  width: 27rem;
  border-right: 0.1rem solid #ccd6e8;
  /*positon is used for js please don't remove it*/
  position: relative;
  @media (max-width: 740px) {
    width: 100%;
    display: flex;
    overflow: visible;
  }
`;
export const TabRightSection = styled.div`
  padding-left: 2.4rem;
  padding-top: 2.4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 740px) {
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    height: calc(100vh - 160px);
    width: calc(100% - 10px);
  }
`;
export const TabRightSectionWrap = styled.div`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  p {
    color: #4d4d4f;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;
    &.plan-heading{
      max-width: 79.2rem;
    } 
    @media (max-width: 740px) {
      padding-right: 1.5rem;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.8rem;
    }
  }
  .combo-checked-wrap {
    border-radius: 0.8rem;
    border: 0.1rem solid #d3e6ff;
    background: #fafcfd;
    max-width: 79.2rem;
    padding: 2rem;
    display: none;
    @media (max-width: 740px) {
      margin-right: 1.5rem;
      padding-bottom: 0rem;
      margin-bottom: 0;
    }
    &.active {
      display: block;
    }
    .top-row {
      border-bottom: 0.06rem solid #7d868c;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.6rem;
      width: 70%;
      @media (max-width: 740px) {
        width: 100%;
      }
      .input-col {
        width: 3%;
        @media (max-width: 740px) {
          width: 4%;
          display: inline-flex;
        }
      }
      .user-num {
        text-align: center;
        width: 17%;
        span {
          display: inline-block;
          color: #0d2c54;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          @media (max-width: 740px) {
            font-size: 1.2rem;
          }
        }
      }
      .amount {
        text-align: center;
        width: 25%;
        @media (max-width: 740px) {
          width: 15%;
        }
        span {
          display: inline-block;
          color: #0d2c54;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
        }
      }
      .course-name {
        text-align: center;
        width: 48.5%;
        @media (max-width: 740px) {
          text-align: left;
          width: 55%;
        }
        span {
          display: inline-block;
          color: #0d2c54;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          @media (max-width: 740px) {
            font-size: 1.2rem;
          }
        }
      }
    }
    .bottom-wrap {
      padding-top: 1.6rem;
      .top-row {
        border: 0;
        margin-bottom: 0.8rem;
      }
      .course-name {
        border-right: 0.3rem solid #e6e6e6;
        align-items: center;
        display: flex;
        @media (max-width: 740px) {
          border-right: 0;
          justify-content: space-between;
        }
      }
    }
  }
  .blank-combo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
      padding: 2.4rem 0;
    }
    button {
      color: #0d2c54;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem;
      border-radius: 0.4rem;
      background: #e7ebf8;
      height: 3.6rem;
      width: 14rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #0d2c54;
        color: #ffffff;
      }
    }
  }
  .second-tab-data {
    display: none;
    &.active {
      display: block;
    }
  }
`;
export const TabTxtWrap = styled.div`
    border-left: 0.6rem solid #118acb;
    padding-left: 1.2rem;
    margin-top: auto;
    display: flex;
    min-height: 5.5rem;
    align-items: center;
`
export const TabBtn = styled.button`
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
  color: ${({ active }) => (active ? "#ffffff" : "#0D2C54")};
  background-color: ${({ active }) =>
    active ? "#2c55a0 !important" : "transparent"};
  text-transform: capitalize;
  text-align: left;
  border-color: transparent;
  width: 100%;
  a {
    display: block;
    padding: 2rem;
    @media (max-width: 740px) {
      padding: 2rem 1.5rem;
    }
  }
  @media (max-width: 740px) {
    //background-color: #e5f0ff;
    background-color: ${({ active }) =>
      active ? "#2c55a0 !important" : "#e5f0ff"};
    width: 50%;
    font-size: 1.4rem;
    text-align: center;
  }
`;
export const RoadMethodTab = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  @media (max-width: 740px) {
    flex-direction: column;
  }
`;
export const RoadTab = styled.div`
    cursor: pointer;
    background: #F0F0F0;
    border-radius: 0.4rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #0D2C54;
    letter-spacing: 0.02em;
    padding: 0.8rem 1.6rem;
    margin-right: 1.6rem;
    width: max-content;
    &.active{
        background: #2C55A0;
        color: #ffffff;
    }
`;
export const TabHeadWrap = styled.div`
    display: flex;
    margin-bottom: 3.4rem;
`;
export const UpgradeFooter =  styled.div`
    position: relative;
`
export const TotalWrapper = styled.div`
    min-width: 27rem;
    position: absolute;
    right: 2.4rem;
    bottom: 3.2rem;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    @media (max-width: 740px) {
        min-width: 100%;
        right: 0;
        bottom: 0;
        border-radius: 0;
    }
    .subtotal{
        color: #000;
        font-size: 1.8rem;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        line-height: 1.8rem;
        margin-bottom: 2.4rem;
        justify-content: center;
        @media (max-width: 740px) {
            margin-bottom: 0;
            padding: 1rem;
            background: #fff;
        }
        span{
            display: inline-block;
            margin-left: 1.2rem;
            color: #0d2c54;
            font-size: 1.6rem;
            padding-bottom: 0.2rem;
            border-bottom: 0.1rem solid #118acb;
        }
    }
    .blue-cart-btn{
        border-radius: 0.4rem;
        background: linear-gradient(147deg, #139BD6 0%, #0789CF 100%);
        border-color: transparent;
        color: #FFF;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.8rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 6rem;
        width: 31.4rem;
        @media (max-width: 740px) {
          width: 100%;
          border-radius: 0;
        }
        i{
            display; inline-block;
            height: 2.4rem;
            width: 2.4rem;
            margin-right: 0.8rem;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
    .green-cart-btn{
        border-radius: 0.4rem;
        background: #43A047;
        border-color: transparent;
        color: #FFF;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.8rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 6rem;
        width: 31.4rem;
        @media (max-width: 740px) {
          width: 100%;
          border-radius: 0;
        }
        i{
            display; inline-block;
            height: 2.4rem;
            width: 2.4rem;
            margin-right: 0.8rem;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
`;
export const TotalWrap = styled.div`
  border-radius: 0.4rem;
  background: #43a047;
  padding: 2.2rem 2.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
      border-radius: 0;
      justify-content: center;
  }
  .total {
    border-right: 0.1rem solid #fff;
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding-right: 1.6rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8rem;
    span {
      display: inline-block;
      margin-left: 1.2rem;
      font-size: 2rem;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .cart-btn {
    padding-left: 1.6rem;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8rem;
    i {
      padding-left: 0.5rem;
      display: inline-block;
      height: 1.6rem;
      width: 1.6rem;
    }
  }
`;
export const InfoWrapper = styled.div`
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
  margin-left: 0.8rem;
  @media (max-width: 740px) {
    height: 1.3rem;
    width: 1.3rem;
  }
  .info-btn {
    display: inline-block;
    height: 100%;
    width: 100%;
    i {
      display: inline-block;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  .info-popup {
    border-radius: 0.8rem;
    border: 0.1rem solid #f0f0f0;
    background: #fff;
    box-shadow: 0.2rem 0.4rem 0.6rem 0px rgba(0, 0, 0, 0.12);
    width: 40rem;
    padding: 1.6rem;
    position: absolute;
    top: -2rem;
    left: 2.9rem;
    z-index: 2;
    @media(max-width: 740px){
      width: 300px;
      position: absolute;
      top: 2rem;
      left: auto;
      right: 0;
    }
    @media(max-width: 575px){
      padding: 10px;
      width: 195px;
      position: absolute;
      left: calc(100% - 120px);
    }
    .pop-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.6rem;
      border-bottom: 0.1rem solid #e6e6e6;
      @media(max-width: 575px){
        padding-bottom: 10px;
      }
      span {
        color: #0d2c54;
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8rem;
      }
      .info-close-btn {
        display: inline-block;
        height: 2rem;
        width: 2rem;
        i {
          display: inline-block;
          width: 100%;
          height: 100%;
          svg {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
    .pop-body {
      padding-top: 1.6rem;
      @media(max-width: 575px){
        max-height: 70px;
        overflow-y: auto;
        padding-top: 10px;
      }
      p {
        color: #4d4d4f;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        margin-bottom: 1rem;
        text-align: left;
        &:last-child {
          margin-bottom: 0;
        }
        @media(max-width: 575px){
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 5px;
        }
      }
    }
  }
`;
export const ComboPopWrapper = styled.div`
  padding-right: 2.4rem;
  @media (max-width: 740px) {
    padding-right: 0;
  }
`;
export const ComboPopCard = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid #d3e6ff;
  background: #fafcfd;
  padding: 1.6rem 1.6rem 0.8rem 1.6rem;
  margin-bottom: 1.6rem;
  max-width: 79.2rem;
  @media(max-width: 740px){
    max-width: 100%;
    padding: 6px 6px 3px 6px;
  }
  .bottom-text {
    color: #0d2c54;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.8rem;
    margin-top: 2rem;
    margin-bottom: 0;
    @media (max-width: 740px) {
      margin-top: 0rem;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;
export const ComboHead = styled.div`
  .combo-del-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    .combo-delete-btn {
      display: inline-flex;
      align-items: center;
      min-width: 9rem;
      width: max-content;
      border-radius: 0.4rem;
      border: 0.1rem solid #db324d;
      background: #fff;
      color: #db324d;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.8rem;
      min-height: 3.4rem;
      justify-content: center;
    }
    .combo-title {
      color: #2c55a0;
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.8rem;
      padding-left: 0.5rem;
      border-left: 0.4rem solid #2c55a0;
    }
  }

  .selected-title {
    display: flex;
    align-items: center;
    color: #0d2c54;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    .count-dropdown {
      display: inline-block;
      margin-left: 1rem;
      width: 7.3rem;
    }
    @media (max-width: 740px) {
      justify-content: space-between;
      padding-right: 0;
    }
  }
`;
export const ComboBody = styled.div`
  position: relative;
  .combo-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    ${InfoWrapper} {
      margin-left: 0;
      margin-right: 1.6rem;
      height: 1.7rem;
      width: 1.7rem;
      @media(max-width: 740px){
        height: 1.3rem;
        width: 1.3rem;
      }
      i {
        height: 100% !important;
      }
    }
    input {
      display: inline-block;
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 1.6rem;
      @media (max-width: 740px) {
        height: 1.3rem;
        width: 1.3rem;
        margin-right: 0.5rem;
      }
    }
    .text {
      display: inline-flex;
      align-items: center;
      min-width: 30rem;
      @media (max-width: 740px) {
        min-width: 207px;
        > i:first-child {
          display: none;
        }
      }
      i {
        display: inline-block;
        height: 3.4rem;
        width: 3.4rem;
        @media (max-width: 740px) {
          display: flex;
          align-items: center;
        }
        svg {
          height: 100%;
          width: 100%;
        }
      }
      span {
        display: inline-block;
        margin: 0 0.8rem;
        color: #0d2c54;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        @media (max-width: 740px) {
          font-size: 11px;
          margin: 0 0.8rem 0 0rem;
        }
      }
      @media (max-width: 740px) {
        .info-btn {
          display: inline-flex;
          align-items: center;
          height: 100%;
          width: 100%;
          i {
            display: inline-block;
            svg {
              height: 1.3rem;
              width: 1.3rem;
            }
          }
        }
      }
    }
    .amount-txt,
    .price-txt {
      display: inline-block;
      color: #0d2c54;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.8rem;
      padding-left: 1.6rem;
      border-left: 0.1rem solid #e6e6e6;
    }
  }
  .subtotal {
    color: #000;
    font-size: 1.8rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    line-height: 1.8rem;
    position: absolute;
    right: 2.5rem;
    bottom: 5.8rem;
    @media (max-width: 740px) {
      position: relative;
      bottom: 0;
      left: 0;
      right: auto;
      display: flex;
      align-items: center;
      justify-content: end;
      padding-right: 0;
      font-size: 1.4rem;
    }
    span {
      display: inline-block;
      margin-left: 1.2rem;
      color: #0d2c54;
      font-size: 1.6rem;
      padding-bottom: 0.2rem;
      border-bottom: 0.1rem solid #118acb;
      @media (max-width: 740px) {
        font-size: 1.4rem;
        padding-bottom: 0rem;
      }
    }
  }
`;
export const AddComboBtn = styled.button`
  background: transparent;
  border-bottom: 0.1rem solid;
  border-color: transparent;
  display: inline-block;
  color: #2c55a0;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.8rem;
  margin-top: 1.7rem;
  &:hover {
    border-color: #118acb;
    color: #118acb;
  }
  @media (max-width: 740px) {
    display: flex;
    margin-top: 0rem;
    margin-left: auto;
  }
`;