import React from 'react';
import {AssessorNoteView } from './AssessorNoteView';
export const AssessorNoteCtrl = (props) => {
    return (
        <>
            <AssessorNoteView {...props}/>
        </>
    );
}

