export const asmtHeadIcon = `<svg width="64" height="80" viewBox="0 0 64 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_7_1497)">
<path d="M0 0H64V67.1772C64 68.0149 63.4779 68.7639 62.6918 69.0537L33.6708 79.7527C33.237 79.9126 32.7613 79.9173 32.3245 79.766L1.3455 69.0374C0.540128 68.7585 0 67.9998 0 67.1475V0Z" fill="#5677B3"/>
</g>
<g filter="url(#filter1_d_7_1497)">
<path d="M39.3014 28.1377C38.4371 28.1476 37.611 28.509 36.9998 29.1444C36.3886 29.7798 36.0411 30.6388 36.0315 31.5373C36.0299 31.9842 36.1134 32.427 36.2771 32.8402C36.4409 33.2535 36.6817 33.6289 36.9856 33.9449C37.2896 34.261 37.6507 34.5113 38.0481 34.6815C38.4456 34.8518 38.8716 34.9385 39.3014 34.9369C40.1657 34.9269 40.9918 34.5656 41.603 33.9302C42.2141 33.2948 42.5617 32.4359 42.5713 31.5373C42.5729 31.0904 42.4894 30.6476 42.3257 30.2344C42.1619 29.8212 41.9211 29.4457 41.6171 29.1297C41.3132 28.8137 40.9521 28.5633 40.5546 28.3931C40.1572 28.2228 39.7313 28.136 39.3014 28.1377Z" fill="white"/>
<path d="M48.8906 31.0916L47.6659 29.8184C47.1743 29.3812 46.6501 28.9853 46.0983 28.6343C44.1117 27.2917 41.7932 26.5771 39.4238 26.5771C37.0544 26.5771 34.736 27.2917 32.7494 28.6343C32.1976 28.9853 31.6734 29.3812 31.1818 29.8184C30.743 30.2098 30.3336 30.6354 29.9571 31.0916C29.8504 31.2135 29.7913 31.3724 29.7913 31.5373C29.7913 31.7021 29.8504 31.861 29.9571 31.9829L31.1818 33.2562C31.6478 33.6928 32.1475 34.0888 32.6759 34.4403C32.6954 34.4403 32.7141 34.4483 32.7279 34.4627C32.7416 34.477 32.7494 34.4964 32.7494 34.5167C34.7302 35.8728 37.0521 36.5903 39.4238 36.5793C41.7937 36.5777 44.1123 35.8612 46.0983 34.5167C46.1178 34.5167 46.1365 34.5086 46.1503 34.4943C46.164 34.48 46.1718 34.4605 46.1718 34.4403C46.6739 34.071 47.1638 33.7018 47.6659 33.2562C48.1047 32.8648 48.5141 32.4391 48.8906 31.9829C48.9624 31.8463 49 31.693 49 31.5373C49 31.3815 48.9624 31.2283 48.8906 31.0916ZM46.7596 32.2757C46.331 32.645 45.8411 33.0143 45.339 33.3835C45.3195 33.3835 45.3008 33.3915 45.2871 33.4058C45.2733 33.4202 45.2655 33.4396 45.2655 33.4599C43.4975 34.6765 41.4215 35.3191 39.3014 35.3061C37.183 35.3063 35.1104 34.6648 33.3372 33.4599C33.3177 33.4599 33.299 33.4518 33.2852 33.4375C33.2715 33.4232 33.2637 33.4038 33.2637 33.3835C32.7581 33.061 32.2825 32.6901 31.8431 32.2757C31.5509 32.0675 31.2873 31.8191 31.0593 31.5373C31.3047 31.2735 31.5666 31.0268 31.8431 30.7988C32.2717 30.4295 32.7739 30.0603 33.2637 29.691C35.0214 28.4379 37.1027 27.7673 39.234 27.7673C41.3653 27.7673 43.4466 28.4379 45.2043 29.691C45.7099 30.0136 46.1855 30.3844 46.6249 30.7988C46.9158 31.0039 47.1758 31.2528 47.3965 31.5373C47.2431 31.8252 47.0245 32.07 46.7596 32.2503V32.2757Z" fill="white"/>
<path d="M32.2718 27.6287C32.6101 27.3763 32.9663 27.1507 33.3372 26.9539C35.1749 25.8558 37.2553 25.2723 39.3749 25.2605C41.4958 25.2622 43.5786 25.8464 45.4125 26.9539L46.478 27.6287C46.5902 27.691 46.7095 27.738 46.8332 27.7688C46.9344 27.7715 47.0346 27.7456 47.1229 27.6941C47.2113 27.6426 47.2846 27.5672 47.3353 27.476C47.3839 27.4082 47.4188 27.3308 47.4378 27.2485C47.4567 27.1663 47.4594 27.0809 47.4457 26.9975C47.4319 26.9142 47.4021 26.8346 47.3578 26.7636C47.3136 26.6927 47.2559 26.6318 47.1883 26.5847C46.8227 26.3158 46.4424 26.0692 46.0493 25.8462C44.0092 24.6573 41.7136 24.0223 39.3749 24C37.0169 23.9997 34.701 24.6499 32.6637 25.8844C32.2687 26.0802 31.8881 26.3057 31.5247 26.5592C31.4575 26.6051 31.4001 26.6648 31.356 26.7347C31.3118 26.8045 31.282 26.883 31.2682 26.9654C31.2544 27.0477 31.2571 27.1321 31.2759 27.2134C31.2948 27.2946 31.3294 27.371 31.3778 27.4378C31.4144 27.5222 31.4698 27.5963 31.5393 27.6542C31.6089 27.7121 31.6906 27.7519 31.7779 27.7706C31.8651 27.7892 31.9554 27.7861 32.0413 27.7614C32.1271 27.7368 32.2061 27.6913 32.2718 27.6287Z" fill="white"/>
<path d="M17.637 49.9999H20.9803C21.1492 49.9999 21.3112 49.9301 21.4306 49.806C21.5501 49.6818 21.6172 49.5134 21.6172 49.3378V38.3369C21.6188 38.2489 21.6035 38.1615 21.5722 38.0797C21.541 37.9979 21.4943 37.9233 21.435 37.8605C21.3757 37.7977 21.305 37.7478 21.2269 37.7137C21.1488 37.6796 21.065 37.6621 20.9803 37.6621H17.637C17.5523 37.6621 17.4685 37.6796 17.3904 37.7137C17.3123 37.7478 17.2416 37.7977 17.1823 37.8605C17.123 37.9233 17.0763 37.9979 17.045 38.0797C17.0138 38.1615 16.9985 38.2489 17.0001 38.3369V49.3378C17.0001 49.5134 17.0672 49.6818 17.1867 49.806C17.3061 49.9301 17.4681 49.9999 17.637 49.9999Z" fill="white"/>
<path d="M22.8662 31.6271V49.338C22.8662 49.5136 22.9333 49.682 23.0527 49.8061C23.1722 49.9303 23.3341 50.0001 23.503 50.0001H26.8341C26.9188 50.0017 27.0029 49.9859 27.0816 49.9534C27.1603 49.9208 27.2319 49.8724 27.2924 49.8107C27.3528 49.7491 27.4009 49.6755 27.4336 49.5943C27.4664 49.5131 27.4832 49.426 27.4832 49.338V31.6271C27.4832 31.5391 27.4664 31.4519 27.4336 31.3707C27.4009 31.2895 27.3528 31.216 27.2924 31.1544C27.2319 31.0927 27.1603 31.0442 27.0816 31.0117C27.0029 30.9792 26.9188 30.9633 26.8341 30.965H23.503C23.3341 30.965 23.1722 31.0347 23.0527 31.1589C22.9333 31.2831 22.8662 31.4515 22.8662 31.6271Z" fill="white"/>
<path d="M28.7571 38.0313V49.3377C28.7571 49.5133 28.8242 49.6817 28.9436 49.8059C29.063 49.93 29.225 49.9998 29.3939 49.9998H32.7373C32.9062 49.9998 33.0682 49.93 33.1876 49.8059C33.307 49.6817 33.3741 49.5133 33.3741 49.3377V38.0313C33.3741 37.8557 33.307 37.6872 33.1876 37.5631C33.0682 37.4389 32.9062 37.3691 32.7373 37.3691H29.3939C29.2329 37.3925 29.0837 37.4697 28.9687 37.5892C28.8538 37.7087 28.7795 37.8639 28.7571 38.0313Z" fill="white"/>
<path d="M34.7334 41.8506V49.3373C34.7334 49.5129 34.8005 49.6813 34.9199 49.8054C35.0394 49.9296 35.2013 49.9994 35.3702 49.9994H38.7136C38.8825 49.9994 39.0445 49.9296 39.1639 49.8054C39.2833 49.6813 39.3504 49.5129 39.3504 49.3373V41.8506C39.3504 41.675 39.2833 41.5066 39.1639 41.3824C39.0445 41.2582 38.8825 41.1885 38.7136 41.1885H35.3702C35.2092 41.2118 35.06 41.2891 34.945 41.4086C34.8301 41.528 34.7558 41.6832 34.7334 41.8506Z" fill="white"/>
</g>
<defs>
<filter id="filter0_i_7_1497" x="0" y="0" width="64" height="80.876" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_7_1497"/>
</filter>
<filter id="filter1_d_7_1497" x="13" y="24" width="40" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_1497"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7_1497" result="shape"/>
</filter>
</defs>
</svg>
`
export const crossIcon = `<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_344_16892)">
<mask id="mask0_344_16892" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_344_16892)">
<path d="M5.61552 15.5557C5.45932 15.7119 5.20605 15.7119 5.04984 15.5557L4.44886 14.9547C4.29265 14.7985 4.29265 14.5452 4.44886 14.389L8.54984 10.2881C8.70605 10.1318 8.70605 9.87858 8.54984 9.72237L4.44886 5.62138C4.29265 5.46517 4.29265 5.21191 4.44886 5.0557L5.04984 4.45472C5.20605 4.29851 5.45932 4.29851 5.61553 4.45472L9.71651 8.5557C9.87272 8.71191 10.126 8.71191 10.2822 8.5557L14.3832 4.45472C14.5394 4.29851 14.7926 4.29851 14.9489 4.45472L15.5498 5.0557C15.706 5.21191 15.706 5.46517 15.5498 5.62138L11.4489 9.72237C11.2926 9.87858 11.2926 10.1318 11.4489 10.2881L15.5498 14.389C15.706 14.5452 15.706 14.7985 15.5498 14.9547L14.9489 15.5557C14.7926 15.7119 14.5394 15.7119 14.3832 15.5557L10.2822 11.4547C10.126 11.2985 9.87272 11.2985 9.71651 11.4547L5.61552 15.5557Z" fill="white"/>
</g>
</g>
<defs>
<filter id="filter0_d_344_16892" x="0.331787" y="4.33789" width="19.3352" height="19.335" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_344_16892"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_344_16892" result="shape"/>
</filter>
</defs>
</svg>
`
export const bookMark = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1463" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1463)">
<path d="M9.33325 14.667V13.2503L12.7666 9.80033L14.1999 11.2003L10.7499 14.667H9.33325ZM14.6833 10.7337L13.2666 9.31699L13.7333 8.85033C13.8666 8.71699 14.0277 8.65033 14.2166 8.65033C14.4055 8.65033 14.561 8.71699 14.6833 8.85033L15.1499 9.33366C15.2721 9.46699 15.3333 9.62533 15.3333 9.80866C15.3333 9.99199 15.2721 10.1448 15.1499 10.267L14.6833 10.7337ZM2.66659 13.3337C2.29992 13.3337 1.98603 13.2031 1.72492 12.942C1.46381 12.6809 1.33325 12.367 1.33325 12.0003V4.00033C1.33325 3.63366 1.46381 3.31977 1.72492 3.05866C1.98603 2.79755 2.29992 2.66699 2.66659 2.66699H6.66659L7.99992 4.00033H13.3333C13.6999 4.00033 14.0138 4.13088 14.2749 4.39199C14.536 4.6531 14.6666 4.96699 14.6666 5.33366V7.28366C14.3221 7.28366 13.9833 7.32533 13.6499 7.40866C13.3166 7.49199 13.0277 7.65588 12.7833 7.90033L7.38325 13.3337H2.66659Z" fill="white"/>
</g>
</svg>
`
export const dataIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1469" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1469)">
<path d="M4.66658 9.33333C4.85547 9.33333 5.01381 9.26944 5.14159 9.14167C5.26936 9.01389 5.33325 8.85556 5.33325 8.66667C5.33325 8.47778 5.26936 8.31944 5.14159 8.19167C5.01381 8.06389 4.85547 8 4.66658 8C4.4777 8 4.31936 8.06389 4.19159 8.19167C4.06381 8.31944 3.99992 8.47778 3.99992 8.66667C3.99992 8.85556 4.06381 9.01389 4.19159 9.14167C4.31936 9.26944 4.4777 9.33333 4.66658 9.33333ZM4.66658 6.66667C4.85547 6.66667 5.01381 6.60278 5.14159 6.475C5.26936 6.34722 5.33325 6.18889 5.33325 6C5.33325 5.81111 5.26936 5.65278 5.14159 5.525C5.01381 5.39722 4.85547 5.33333 4.66658 5.33333C4.4777 5.33333 4.31936 5.39722 4.19159 5.525C4.06381 5.65278 3.99992 5.81111 3.99992 6C3.99992 6.18889 4.06381 6.34722 4.19159 6.475C4.31936 6.60278 4.4777 6.66667 4.66658 6.66667ZM5.99992 9.33333H11.9999V8H5.99992V9.33333ZM5.99992 6.66667H11.9999V5.33333H5.99992V6.66667ZM5.33325 14V12.6667H2.66659C2.29992 12.6667 1.98603 12.5361 1.72492 12.275C1.46381 12.0139 1.33325 11.7 1.33325 11.3333V3.33333C1.33325 2.96667 1.46381 2.65278 1.72492 2.39167C1.98603 2.13056 2.29992 2 2.66659 2H13.3333C13.6999 2 14.0138 2.13056 14.2749 2.39167C14.536 2.65278 14.6666 2.96667 14.6666 3.33333V11.3333C14.6666 11.7 14.536 12.0139 14.2749 12.275C14.0138 12.5361 13.6999 12.6667 13.3333 12.6667H10.6666V14H5.33325Z" fill="white"/>
</g>
</svg>
`
export const insertIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1481" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1481)">
<path d="M4.66667 11.3333H6V6.66667H4.66667V11.3333ZM7.33333 11.3333H8.66667V4.66667H7.33333V11.3333ZM10 11.3333H11.3333V8.66667H10V11.3333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333Z" fill="white"/>
</g>
</svg>
`
export const benchIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1475" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1475)">
<path d="M1.33325 4.00033V0.666992H4.66658V2.00033H2.66659V4.00033H1.33325ZM13.3333 4.00033V2.00033H11.3333V0.666992H14.6666V4.00033H13.3333ZM1.33325 15.3337V12.0003H2.66659V14.0003H4.66658V15.3337H1.33325ZM11.3333 15.3337V14.0003H13.3333V12.0003H14.6666V15.3337H11.3333ZM4.66658 13.3337C4.29992 13.3337 3.98603 13.2031 3.72492 12.942C3.46381 12.6809 3.33325 12.367 3.33325 12.0003V4.00033C3.33325 3.63366 3.46381 3.31977 3.72492 3.05866C3.98603 2.79755 4.29992 2.66699 4.66658 2.66699H11.3333C11.6999 2.66699 12.0138 2.79755 12.2749 3.05866C12.536 3.31977 12.6666 3.63366 12.6666 4.00033V12.0003C12.6666 12.367 12.536 12.6809 12.2749 12.942C12.0138 13.2031 11.6999 13.3337 11.3333 13.3337H4.66658ZM5.99992 6.66699H9.99992V5.33366H5.99992V6.66699ZM5.99992 8.66699H9.99992V7.33366H5.99992V8.66699ZM5.99992 10.667H9.99992V9.33366H5.99992V10.667Z" fill="white"/>
</g>
</svg>
`
export const bgIcon=`<svg width="163" height="180" viewBox="0 0 163 180" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="143.87" y="0.140067" width="135.466" height="179.62" rx="11.9" transform="rotate(52.935 143.87 0.140067)" stroke="#E7EBF8" stroke-width="0.2"/>
<rect x="140.586" y="75.4202" width="75.4" height="75.4" rx="11.7" transform="rotate(52.935 140.586 75.4202)" stroke="#E7EBF8" stroke-width="0.6"/>
<rect x="138.961" y="40.2801" width="111.675" height="121.365" rx="11.8" transform="rotate(52.935 138.961 40.2801)" stroke="#E7EBF8" stroke-width="0.4"/>
</svg>
`
export const triangleIcon=`<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0L6 8L0 -5.24537e-07L12 0Z" fill="#5677B3"/>
</svg>`