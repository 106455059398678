export const stepdata =  
    {
        "filter": [],
        "siriDimension": [
            {
                "id": "1",
                "dimension_name": "Process - Operations - Vertical Integration",
                "band_id": "3",
                "band_name": "Digital",
                "band": "Band 2",
                "order_by": "3",
                "selected_band": {
                    "id": 3,
                    "name": "Band 2"
                }
            },
            {
                "id": "2",
                "dimension_name": "Process - Supply Chain - Horizontal Integration",
                "band_id": "12",
                "band_name": "Intelligent",
                "band": "Band 5 ",
                "order_by": "6",
                "selected_band": {
                    "id": 6,
                    "name": "Band 5"
                }
            },
            {
                "id": "3",
                "dimension_name": "Process - Product Life Cycle - Integrated Product Life Cycle",
                "band_id": "13",
                "band_name": "Undefined",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "4",
                "dimension_name": "Technology - Automation - Shop Floor",
                "band_id": "19",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "5",
                "dimension_name": "Technology - Automation - Enterprise",
                "band_id": "25",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "6",
                "dimension_name": "Technology - Automation - Facility",
                "band_id": "31",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "7",
                "dimension_name": "Technology - Connectivity - Shop Floor",
                "band_id": "37",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "8",
                "dimension_name": "Technology - Connectivity - Enterprise\n",
                "band_id": "43",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "9",
                "dimension_name": "Technology - Connectivity - Facility",
                "band_id": "49",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "10",
                "dimension_name": "Technology - Intelligence - Shop Floor",
                "band_id": "55",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "11",
                "dimension_name": "Technology - Intelligence - Enterprise",
                "band_id": "64",
                "band_name": "Diagnostic",
                "band": "Band 3 ",
                "order_by": "4",
                "selected_band": {
                    "id": 4,
                    "name": "Band 3"
                }
            },
            {
                "id": "12",
                "dimension_name": "Technology - Intelligence - Facility",
                "band_id": "67",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "13",
                "dimension_name": "Organization - Talent Readiness - Workforce Learning and Development",
                "band_id": "73",
                "band_name": "Informal",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "14",
                "dimension_name": "Organization - Talent Readiness - Leadership Competency\n",
                "band_id": "79",
                "band_name": "Unfamiliar",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "15",
                "dimension_name": "Organization - Structure and Management - Inter- and Intra- Company\n\nCollaboration",
                "band_id": "85",
                "band_name": "Informal",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            },
            {
                "id": "16",
                "dimension_name": "Organization - Structure and Management - Strategy and Governance",
                "band_id": "91",
                "band_name": "None",
                "band": "Band 0 ",
                "order_by": "1",
                "selected_band": {
                    "id": 1,
                    "name": "Band 0"
                }
            }
        ],
    }
