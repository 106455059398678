/** imported components

  * $ from jquery ===> component from jquery to use jquery for the page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import serviceProvider from '../../services/axios'
//AdminWalkthroughView component start
export const AdminWalkthroughView = (props) => {
  //Defining variables here
  const users = JSON.parse(localStorage.getItem("userData"));
  const UserName = users ? users.name : "First Name";
  const uid = users ? users.uid : "";
  const [sliderStatus, setSliderStatus] = useState(1);
  //function to handle slider
  const HandleSlider = (val) => {
    setSliderStatus(val);
  }
  //Function to close walkthrough
  const handleClose = () => {
    let mainDiv=document.querySelector('.training-main-wrapper');
    let contentDiv=document.querySelector('.training-main-wrapper .training-content');
    if(mainDiv){
       mainDiv.classList.remove('walkthrough--working');
    }
    if(contentDiv){
       contentDiv.classList.remove('content--no-scroll');
    }
    serviceProvider.post(
      "/home?type=setlossadmintour",
      JSON.stringify({ uid: uid }),
      true
    )
      .then((response) => {
        setSliderStatus(0);
      });
  }
  //Function to add and remove classes for walkthrough
  useEffect(() => {
    let headerBlur = document.querySelector('.header-right-frame');
      if(headerBlur && sliderStatus) {headerBlur.style.zIndex='0'}
      else if(headerBlur){
        headerBlur.style.zIndex=''
      }
    if (sliderStatus === 1) {
      let walkStep2= document.querySelectorAll('.walk-step-2');
      walkStep2.forEach((item)=>{
        item.classList.add('div-z99');
      });
    }
    else {
      let walkStep2= document.querySelectorAll('.walk-step-2');
      walkStep2.forEach((item)=>{
        item.classList.remove('div-z99');
      });
    }
    if (sliderStatus === 2) {
      document.querySelector('.walk-step-1').classList.add('div-z99');
    }
    else {
      document.querySelector('.walk-step-1').classList.remove('div-z99');
    }
    if (sliderStatus === 3) {
      document.querySelector('.walk-step3').classList.add('div-z99');
    }
    else {
      document.querySelector('.walk-step3').classList.remove('div-z99');
    }
  }, [sliderStatus]);


  useEffect(() => {
      return ()=>{
        let headerBlur = document.querySelector('.header-right-frame');
        if(headerBlur)  headerBlur.style.zIndex='';
      }
  },[]);
  useEffect(() => {
      let mainDiv=document.querySelector('.training-main-wrapper');
      let contentDiv=document.querySelector('.training-main-wrapper .training-content');
      if(mainDiv){
        mainDiv.classList.add('walkthrough--working');
      }
      if(contentDiv){
        contentDiv.classList.add('content--no-scroll');
      }
      return ()=>{
                   if(mainDiv){
                      mainDiv.classList.remove('walkthrough--working');
                    }
                    if(contentDiv){
                      contentDiv.classList.remove('content--no-scroll');
                    }
              }
  },[])
  return (
    <>
      {/* link css */}
      <link rel="stylesheet" href={require('./admin-walkthrough.scss').default} />
      {/* link css end*/}
      <section className="general-information-walkthrough">
        <div className="general-information-top">
          {/*---------------------  Walkthrough Wrapper  ------------------------*/}
          <div className="general-info-walkthrough">
            {sliderStatus === 1 ?
              <div className="g-walkthrough-screen1">
                 {/* walkthrough wrapper start */}
                <div className="g-walkthrough-wrapper">
                  {/* walkthrough wrapper divided in two parts */}
                  <div className="g-walk-row">
                    {/* left part is containing gif image related to walkthrough step. */}
                    <div className="g-walk-col-left">
                      <img src={require("../../assets/img/la-admin/admin-step1.gif").default} alt="Question Step 1" />
                    </div>
                    {/* right part has 
                        heading , 
                        text and  
                        buttons navigate from one to next, next to previous and close. */}
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>1 of 3</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/la-admin/walk-close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Hi <span>{UserName},</span></h4>
                          <p className="txt">This is the Industry 4.0 IR4.0 Loss Analysis home page.</p>
                          <p className="txt">Here you can see all Sections and monitor the overall progress.​ </p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button className="prev-link link" onClick={handleClose}>Skip</button>
                              <button onClick={() => { HandleSlider(2) }} className="let-link link solid">Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {sliderStatus === 2 ?
              <div className="g-walkthrough-screen2 gstep">
                <div className="g-walkthrough-wrapper">
                  <div className="g-walk-row">
                    <div className="g-walk-col-left"><img src={require("../../assets/img/la-admin/admin-step2.gif").default} alt="Question Walkthrough" className="close-img" /></div>
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>2 of 3</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/la-admin/walk-close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Collaboration</h4>
                          {/*comment2*/}
                          <p className="txt">By clicking the assign icon on each section or sub-section, you can invite your colleagues to fill-in the data.</p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button onClick={() => { HandleSlider(1) }} className="prev-link link">Previous</button>
                              <button onClick={() => { HandleSlider(3) }} className="let-link link solid">Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {sliderStatus === 3 ?
              <div className="g-walkthrough-screen3 gstep">
                <div className="g-walkthrough-wrapper">
                  <div className="g-walk-row">
                    <div className="g-walk-col-left"><img src={require("../../assets/img/la-admin/admin-step3.gif").default} alt="Question Walkthrough" className="close-img" /></div>
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>3 of 3</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/la-admin/walk-close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Submit Data</h4>
                          {/*comment2*/}
                          <p className="txt">When all Sections are completed, you will need to finalize the submission using the <span className="highlight-txt">‘Submit Data’</span> button.​</p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button onClick={() => { HandleSlider(2) }} className="prev-link link">Previous</button>
                              <button onClick={() => handleClose()} className="let link solid">Let’s Start</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
          </div>
        </div>
      </section>
    </>
  );
};


