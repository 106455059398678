/** imported components

  * LossAnalysisSetupPagePlantView ===> custom view component
  * LossAnalysisSetupPagePlantOnlyView ===> custom view component
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React from "react";
import serviceProvider from '../../services/axios'
import { useEffect, useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LossAnalysisSetupPagePlantView } from "./loss-analysis-setup-page-plant-view";
import { LossAnalysisSetupPagePlantOnlyView } from "./loss-analysis-setup-page-plant-only-view";
import {Helmet} from 'react-helmet';
export const LossAnalysisSetupPagePlantController = (props) => {
  //defining variables here
  const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
  const slug = getLastItem(window.location.pathname);
  const [results, setResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const group_id = user ? user.group_id:0;

  useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=section&slug=${slug}&uid=${usid}&from=setup`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			if(data){
				setResults(data);
			}
		};
		search();
	}, []);
if(results?.length && results[0].block == 1){ 
return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);

}else if(results?.length && results[0].customData?.length == 0 && group_id!=14){ 
  return (
		<>
			<Helmet>
				<link rel="stylesheet" href={require('./loss-analysis-setup-page.scss').default} />
				<title>Loss Analysis Setup | SmarterChains</title>
        		<meta name="description" content="Loss Setup" />
			</Helmet>
		  	<LossAnalysisSetupPagePlantView sectionData={results} {...props} />    
		</>
  );
 }else{
	 return (
		<>
		{/* css for styling */}
		<Helmet>
		  <link rel="stylesheet" href={require('./loss-analysis-setup-page.scss').default} />
		  <title>Loss Analysis Setup | SmarterChains</title>
          <meta name="description" content="Loss Setup" />
		</Helmet>
		{/* custom view component */}
		<LossAnalysisSetupPagePlantOnlyView sectionData={results} {...props} />    
		</>
 	 );
	 
  }
};