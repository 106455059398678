/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  
**/

import React, { useCallback, useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from 'react-router'
import serviceProvider from '../../services/axios'
import "./quiz.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import $ from "jquery";
//QuizView component start
export const QuizView = (props) => {
	const [quizOn, setQuizOn] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [isBlock,setIsBlock]= useState(true);
	var totSelected = [];
	useEffect(() => {
		var element = document.getElementById("quizForm");
		if (typeof element != "undefined" && element != null) {
			totSelected = [];
			element.reset();
		}
	}, []);
	const CloseQuizPopup = () => {
		setQuizOn(false);
	}
	const quizDataItems = props.quizData;
	if (!quizDataItems.length) {
		return null;
	}
	const quizDataItem = props.quizData[0];
	
	if (quizDataItem.block == 1) {
		 return <Redirect to="/404" />;
	}

	if (quizDataItem.has_paid == 0) {
		window.location = "/";
		<Redirect to={`/training-courses/${quizDataItem.course_slug}`} />;
	}
	const saveForm = () => {
		let formData = new FormData(document.getElementById("quizForm"));
		serviceProvider.post("/item?type=savequiz", formData,true).then((response) => {
			// console.log(response.data);
		});
	};
	//function to submit form
	const submitForm = () => {
		let formData = new FormData(document.getElementById("quizForm"));
		serviceProvider.post("/item?type=submitquiz", formData,true).then((response) => {
			// console.log(response.data);
			props.onSubmitQuiz(response.data);
			props.onSubmitQuizShow(true);
		});
	};
	quizDataItem.questions.map((ques) => {
		if (ques.has_answered == 1) {
			totSelected.push(ques.id);
		}
	});
	//function to change value
	const changeValue = (qid) => {
		//
		var countchecked = document.querySelectorAll("input.inp-" + qid + ":checked").length;
		var element = document.getElementById("quiz__sec__" + qid);
		var aindex = totSelected.indexOf(qid);
		if (countchecked > 0) {
			if (aindex == -1) {
				totSelected.push(qid);
			}
			element.classList.add("selected");
			
		} else {
			if (aindex > -1) {
				totSelected.splice(aindex, 1);
			}
			element.classList.remove("selected");
			
		}
		
		var countchecked = document.getElementsByClassName("selected").length;
    	if(countchecked == quizDataItem.questions.length) {
      		setDisabled(false);
    	}else{
		 setDisabled(true);
		}
	};
	const Quizes = quizDataItem.questions.map((ques, index) => {
		return (
			/* this card has
				header
				ans_div
			*/ 
			<div key={ques.id} id={`quiz__sec__${ques.id}`} className={ques.has_answered == 1 ? "quiz__body__card selected" : "quiz__body__card"}>
				{/* header has
					no__div
					middle__div
					last__div
				*/}
				<header>
					{/* this section has
						text 
						image1 
						image2
					*/}
					<div className="no__div">
						<span>{index + 1}</span>
						<img className="green" src={require("../../assets/img/quiz/done-green.svg").default} alt="true" />
						<img className="red" src={require("../../assets/img/quiz/cancel-red.svg").default} alt="true" />
					</div>
					{/* no__div end */}
					{/* this section has question */}
					<div className="middle__div">
						<p className="b__text">{ques.question}</p>
					</div>
					{/* middle__div end */}
					{/* this section has point */}
					<div className="last__div">1 point</div>
					{/* last__div end */}
				</header>
				{/* this section has answer format */}
				<div className="ans__div" onClick={() => changeValue(ques.id)}>
					{ques.ques_type == 1 ? (
						ques.answers.length ? (
							ques.answers.map((ans) => (
								<div key={ans.id}>
									<label className="quiz__radio">
										{/* defaultChecked */}
										<input
											type="radio"
											className={`inp-${ques.id}`}
											name={`ques-${ques.id}[]`}
											value={ans.id}
											defaultChecked={ans.is_checked ? "checked" : ""}
										/>
										<span className="icon"></span>
										<span className="wrong__icon">
											<img src={require("../../assets/img/quiz/wrong-icon.svg").default} alt="true" />
										</span>
										<span className="labe__text">{ans.option_name}</span>
									</label>
								</div>
							))
						) : (
							<></>
						)
					) : ques.answers.length ? (
						ques.answers.map((ans) => (
							<div key={ans.id}>
								<label className="quiz__chkbox">
									<input
										type="checkbox"
										className={`inp-${ques.id}`}
										name={`ques-${ques.id}[]`}
										value={ans.id}
										defaultChecked={ans.is_checked ? "checked" : ""}
									/>
									<span className="icon">
										<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
									</span>
									<span className="correct__icon">
										<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
									</span>
									<span className="wrong__icon">
										<img src={require("../../assets/img/quiz/wrong-icon.svg").default} alt="true" />
									</span>
									<span className="labe__text">{ans.option_name}</span>
								</label>
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div>
		);
	});
	return (
		<React.Fragment>
			{/* Page title */}
			<Helmet>
				<title>{quizDataItem.course_name} Quiz | SmarterChains</title>
				<meta name="description" content="Training Courses" />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/training-courses">Training Courses</BreadcrumbsItem>
			<BreadcrumbsItem to={`/training-courses/${quizDataItem.course_slug}`}>{quizDataItem.course_name}</BreadcrumbsItem>
			<BreadcrumbsItem to="#">Quiz</BreadcrumbsItem>
			{/* main wrapper has
				quiz__left__wrapp
				quiz__right__wrapp
			*/}
			<div className="quiz__main__wrapper">
				{/* this section has
					quiz__header
					quizform
					quiz__footer
				*/}
				<div className="quiz__left__wrapp">
					{/* this section has
						quiz__header__content
						quiz__header__img
					*/}
					<div className="quiz__header">
						{/* this section has
							heading
							large-text
							small-text
						*/}
						<div className="quiz__header__content">
							<h2 className="h1">
								{/* Removal of back button SCNV-3211 */}
								{/* <Link to={`/training-courses/${quizDataItem.course_slug}`} className="back__link" id="backLink">
									<img src={require("../../assets/img/quiz/back-arrow.svg").default} alt="true" />
								</Link> */}
								<span>{quizDataItem.course_name}</span>
							</h2>
							<p className="quiz__large">
								The quiz consists of 10 questions and each one rewards you with 1 point.<br/>Score 80% or higher
								to get your certificate.
							</p>
							<p className="small">
								Total points:<span>{quizDataItem.questions.length}</span>
							</p>
						</div>
						{/* this section has image */}
						<div className="quiz__header__img">
							<img
								src={require(`../../assets/img/training-quiz/tqicon-${quizDataItem.id}.svg`).default}
								alt={quizDataItem.course_name}
							/>
						</div>
					</div>
					{/* quiz__header end */}
					{/* form has  
						input
						quiz__body
					*/}
					<form id="quizForm">
						<input type="hidden" name="uid" value={quizDataItem.uid} />
						<input type="hidden" name="cid" value={quizDataItem.id} />
						<div className="quiz__body">{Quizes}</div>
					</form>
					{/* this section has submit button */}
					<div className="quiz__footer">
						{totSelected.length == quizDataItem.questions.length ? (
							<button onClick={submitForm} id="btn__quiz_submit__btn" className="btn__submit">
								Submit
							</button>
						) : (
							<button onClick={submitForm} id="btn__quiz_submit__btn" className="btn__submit" disabled={disabled}>
								Submit
							</button>
						)}

					</div>
				</div>
				{/* quiz__left__wrapp  end */}
				{/* this section has one card */}
				<div className="quiz__right__wrapp" style={{ display: "none" }}>
					{/* card has
						percenatage circle
						Retake Quiz
					*/}
					<div className="card">
						<div className="svg__cir__wrapp">
							<SvgCircle value={60} />
						</div>

						<button className="btn__retake__quiz">Retake Quiz</button>
					</div>
				</div>
				{/* quiz__right__wrapp end */}
			</div>
			{/* quiz__main__wrapper end */}

			{/* unsaved file popup start */}
			{props.isAnsPoup && (
				<div className="unsaved__ans__popup">
					{/* this section has
						close-button
						text
						btn-wrapper
					*/}
					<div className="popup__content">
						<button className="close_btn" onClick={props.HandleUnsavedAnsPopup}>
							<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="true" />
						</button>

						<p className="popup__text">
							<img src={require("../../assets/img/quiz/unsaved-file.svg").default} alt="true" />
							<span>You have unsaved answers. Do you want to Save your progress?</span>
						</p>
						<div className="btn__wrapper">
							<button className="no__thanks__btn">No thanks</button>
							<button className="save__ans__btn">Save my answers</button>
						</div>
					</div>
				</div>
			)}
			{/* unsaved file popup end */}
			{/* quiz popup start */} 
			<LeavePageConfirmPopup quizOn={quizOn} showSideBar={props.showSideBar} setQuizOn={setQuizOn} isValue={isBlock} />
		</React.Fragment>
	);
};
//percentage circle for progress
const SvgCircle = (props) => {
	const { value } = props;
	return (
		<svg viewBox="0 0 36 36">
			<text className="percent__text" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="700">
				{value}
				<tspan fontSize="5">%</tspan>
			</text>
			<path
				className="circle-bg"
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke="#EBF5FA"
				strokeWidth="2.4"
				fill="none"
			></path>
			<path
				className="circle"
				strokeDasharray={`${value}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke={value < 80 && value > 0 ? "#FFBC1F" : value > 79 ? "#40D30D" : "transparent"}
				strokeWidth="2.4"
				fill="none"
			></path>
		</svg>
	);
};

//LeavePageConfirmPopup component
const LeavePageConfirmPopup = (props) => {
	//defining variables here
	const { quizOn, setQuizOn, isValue,showSideBar } = props;
	const history = useHistory();
	const [currentPath, setCurrentPath] = useState("");
	const ProceedFunc = useCallback(async () => {
		history.block(() => { });
		showSideBar(true)
		setQuizOn(false)
		let formData = new FormData(document.getElementById("quizForm"));
		serviceProvider.post("/item?type=submitquiz", formData,true).then((response) => {
		});
		history.push(currentPath);		
	}, [currentPath]);
	useEffect(() => {
		
		$(document).on('click','.upgrd-not-list ul li a',function(e){
				 var hr = e.target.href;
				 var no_o = hr.replaceAll("http://educateprepare.karamalegkos.com", "");
				 setCurrentPath(no_o);
			     setQuizOn(true);
				 return false;
		}) 
		if (isValue) {
			history.block((prompt) => {
				setCurrentPath(prompt.pathname);
				setQuizOn(true);
				return "true";
			});
		} else {
			history.block(() => { });
		}
		return ()=>{
			history.block(() => { });
		}
	}, [isValue]);
	return  (
		<>
			<div className="quiz-screen" style={{display:quizOn?'grid':'none'}}>
				<div className="quiz-content" >
					{/* this section has
						button 
						file
						text
						btn-section
					*/}
					<span className="close-submit-form">
						<button onClick={() => { setQuizOn(false) }}>
							<img src={require("../../assets/img/quiz/close-quiz.svg").default} alt="fte-breakdown" />
						</button>
					</span>
					<div className="file"><img src={require("../../assets/img/quiz/ex-sign.svg").default} alt="fte-breakdown" /></div>
					<p>If you leave the quiz now, your progress will be lost. You will be able to retake the quiz after 8 hours.</p>
					
					<div className="what-to-do">
						<button onClick={() => setQuizOn(false)}>Cancel</button>
						<button className="final" onClick={() => ProceedFunc()}>Proceed</button>
					</div>
				</div>
			</div>
		</>
	)
}