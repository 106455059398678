/** imported components

  * serviceProvider for ==> working with api.
  * CartContext for ==> add item into the cart.
  * scss for ==> styling css file for this page.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.
  * Helmet ==> for add specific title & CSS for the page.
  * PaymentSuccessView ==> for customm component.

**/
import React, { useState, useEffect, Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { number } from "yup";
import { PaymentSuccessView } from "./payment-success-view";
import "../cart.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
// PaymentSuccessControler start
export const PaymentSuccessControler = (props) => {
  // defining variables
  const [redirect, setIsRedirect] = useState(false);
  useEffect(() => {
    //setTimeout(() => {
    //setIsRedirect(true);
    //}, 10000);
  }, []);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const paid_ammount = JSON.parse(localStorage.getItem("payData"));
  return redirect ? (
    <Redirect to="/training-courses" />
  ) : (
    <>
      {/* Page title */}
      <Helmet>
        <title>Order Summary | SmarterChains</title>
        <meta name="description" content="Order Summary" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/payment_success">Order Summary</BreadcrumbsItem>
      {/* View component start */}
      <PaymentSuccessView
        tansection_no={paid_ammount.txn_id}
        paid_ammount={paid_ammount.total_payment}
        orderSummary={paid_ammount.Items}
        isFirstBuy={paid_ammount.is_free}
        uid={usid}
        {...props}
      />
    </>
  );
};
