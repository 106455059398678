export const crossIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.34353 6.50028C3.90961 6.06636 3.90961 5.36285 4.34353 4.92893C4.77744 4.49502 5.48096 4.49502 5.91488 4.92893L18.4857 17.4997C18.9196 17.9336 18.9196 18.6372 18.4857 19.0711C18.0517 19.505 17.3482 19.505 16.9143 19.0711L4.34353 6.50028Z" fill="#7D868C"/>
<path d="M4.34353 19.0711C3.90961 18.6372 3.90961 17.9336 4.34353 17.4997L16.9143 4.92893C17.3482 4.49502 18.0517 4.49502 18.4857 4.92893C18.9196 5.36285 18.9196 6.06637 18.4857 6.50028L5.91488 19.0711C5.48096 19.505 4.77744 19.505 4.34353 19.0711Z" fill="#7D868C"/>
</svg>
`
export const arrowLeft = `<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 15L0.999999 8L8 1" stroke="#7D868C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
export const arrowRight = `<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L8 8L1 15" stroke="#7D868C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>

`