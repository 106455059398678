/** imported components

  * chapter.scss ===> component for styling the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with 
  * ChapterPlantView ===> View file of chapter component
 
**/


import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { ChapterPlantView } from "./chapter-plant-view";
import "./chapter.scss";
import serviceProvider from '../../services/axios';
// ChpaterPlant component start
export const ChapterPlantController = (props) => {
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
// console.log(props.match.params.slug);
	const slug = props.match.params.slug
	
	const [onTab, setOnTab] = useState(0);
	useEffect(() => {
		const search = async () => {
			const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const cid = getLastItem(window.location.pathname);
			const apiUrl = `/assessmentmaturity?param=chapter&slug=${slug}&id=${cid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {} );
			setResults(data);
		};
		search();
	}, []);
	
	if (results.length) {
		
		
			
			if(results[0].editining == 1){
						
				        return <Redirect to={`/assessment/${results[0].slug}`} />;
						return (
								<>
									<div></div>
								</>
						); 
			}else if(results[0].block == 'invalid_plant'){			
					return <Redirect to="/404" />;
						return (
								<>
									<div></div>
								</>
						);		
			}else if(results[0].block == 'block'){
				
						return <Redirect to="/user-unassign" />;
						return (
								<>
									<div></div>
								</>
						);	
			}else if(results[0].no_line == 0 && results[0].cid==9 && results[0].open_line==1){
				
						return <Redirect to={`/assessment/${results[0].slug}?open_line`} />;
						return (
								<>
									<div></div>
								</>
						);				
			}else{
					
					return <ChapterPlantView slug={slug} sectionData={results} onTab={onTab} {...props} />;
			}
			
			
	}else{
					return (
					<>
						<div></div>
					</>
				);
		
		}
	
	
	
	
	
	
	
};
