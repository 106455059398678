

/** imported components

    * ./svg => imported for all svg elements  
    * ./styles => imported for using of all created styled components.
    * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React, { useState, useEffect } from 'react';
import {
    WalkthroughContainer, WalkthroughWrapper,
    WalkthroughCard,
    WalkSection,
    WalkTopRow,
    WalkTopLeftCol, WalkTopRightCol, WalkMidRow,
    WalkFooter, WalkFooterLeftCol, WalkFooterRightCol,
    DotsSection, ButtonWrapper

} from './walkthrough-styled.js';
import { SaveChangesButton } from './styles.js';
import serviceProvider from '../../services/axios';


export const LossAssumptionWalkthroughView = (props) => {
    const {setIsResetDefaultButton,isSingleLayout, setIsReasonEditMode, setIsSetupType, setIsReasonCodePopUp, setWalkthroughReasonButtonShow} = props;
    const [ activeSlide, setActiveSlide ] = useState(1);
    const user = JSON.parse(localStorage.getItem("userData"));
 
    const uid = user ? user.uid : 0;
    // Function to navigate slider from one to next
    const multipleLineData = [
        {
            title: `Loss Assumptions Setup`,
            details: [
                `This is loss ‘Loss Assumptions Setup’ pop-up. Here, you will have to chose the <b>‘Setup Type’.</b>`,
                `The Loss% figures can be customized in 2 ways. Firstly, <b>‘One figure for all Lines’</b> and <b>‘Line-wise individually’</b>.`
            ]
        },
        {
            title: `Setup Type: One figure for all Lines`,
            details: [
                `This is  ‘One figure for all Lines Setup’ pop-up. Here, you can see all the questions under a specific Sub-Losses Section , for ex: Planned Downtime.`,
                `The Loss% of these each questions can be edited here.`
            ]
        },
        {
            title: `Questions`,
            details: [
                `All the question under a Sub-Losses Section is visible here. In order to modify the <b>Loss %</b> of questions, you have to select <b>‘Edit Figures’</b> button to enter the edit mode.`
            ]
        },
        {
            title: `Questions: Save Changes`,
            details: [
                `After making the modifications do save the changes made by selecting ‘Save Changes’ button.`
            ]
        },
        {
            title: `Reason Codes`,
            details: [
                `Every question further have ‘Reason Codes’ which constitutes the Loss %.`,
                `In order to view the Reason Codes of a question, you will have to select the <b>View</b> button under the Reason Codes column.`
            ]
        },
        {
            title: `Reset Default figures`,
            details: [
                `You can reverse back all the modifications made by selecting the <b>‘Reset Default figures’</b> button. The figures will roll back to it default figures. Please note that all the reason codes added or modified will also be rolled back to default.`
            ]
        },
        {
            title: `Reason Codes: Loss %`,
            details: [
                `Here you can see the Reason codes attributed to a question.`,
                `Select the ‘Edit Reason Codes’ button to modify the Loss % of each Reason Codes. You can also add a new reason code by selecting ‘Add Reason Code’.`
            ]
        },
        {
            title: `Edit Reason Codes`,
            details: [
                `After modifying the Loss % of Reason Codes and select the <b>‘Save Changes’</b> button.`
            ]
        },
        {
            title: `Add Reason Code`,
            details: [
                `You can add a reason code here. Select the ‘Save Reason Code’ button once you have entered the Loss %.`
            ]
        },
        {
            title: `Comments`,
            details: [
                `You can see the number of comments on each Reason Codes. Select on the ‘View’ button to see the comments.`
            ]
        },
        {
            title: `Comments`,
            details: [
                `You can see all the comments and add your comments by selecting the ‘New Comment’ button.`
            ]
        },
        {
            title: `Reset Reason Codes`,
            details: [
                `You can reverse back all the modifications made in Reason Code of a question by selecting the <b>‘Reset Reason Codes’</b> button. The reason codes will roll back to it default figures. Please note that all the reason codes added will be removed.`
            ]
        },
        {
            title: `Setup Type: Line-wise Individually`,
            details: [
                `This is  ‘Line-wise Individually’ pop-up. Here, you can see all the <b>‘Production Lines’</b> for each questions under a specific Sub-Losses Section , for ex: Planned Downtime.`,
                `The Loss% of these each Production Lines per question can be edited here.`
            ]
        },
        
    ]
    const singleLineData = [
        {
            title: `Setup Type: One figure for all Lines`,
            details: [
                `This is  ‘One figure for all Lines Setup’ pop-up. Here, you can see all the questions under a specific Sub-Losses Section , for ex: Planned Downtime.`,
                `The Loss% of these each questions can be edited here.`
            ]
        },
        {
            title: `Questions`,
            details: [
                `All the question under a Sub-Losses Section is visible here. In order to modify the <b>Loss %</b> of questions, you have to select <b>‘Edit Figures’</b> button to enter the edit mode.`
            ]
        },
        {
            title: `Questions: Save Changes`,
            details: [
                `After making the modifications do save the changes made by selecting ‘Save Changes’ button.`
            ]
        },
        {
            title: `Reason Codes`,
            details: [
                `Every question further have ‘Reason Codes’ which constitutes the Loss %.`,
                `In order to view the Reason Codes of a question, you will have to select the <b>View</b> button under the Reason Codes column.`
            ]
        },
        {
            title: `Reset Default figures`,
            details: [
                `You can reverse back all the modifications made by selecting the <b>‘Reset Default figures’</b> button. The figures will roll back to it default figures. Please note that all the reason codes added or modified will also be rolled back to default.`
            ]
        },
        {
            title: `Reason Codes: Loss %`,
            details: [
                `Here you can see the Reason codes attributed to a question.`,
                `Select the ‘Edit Reason Codes’ button to modify the Loss % of each Reason Codes. You can also add a new reason code by selecting ‘Add Reason Code’.`
            ]
        },
        {
            title: `Edit Reason Codes`,
            details: [
                `After modifying the Loss % of Reason Codes and select the <b>‘Save Changes’</b> button.`
            ]
        },
        {
            title: `Add Reason Code`,
            details: [
                `You can add a reason code here. Select the ‘Save Reason Code’ button once you have entered the Loss %.`
            ]
        },
        {
            title: `Comments`,
            details: [
                `You can see the number of comments on each Reason Codes. Select on the ‘View’ button to see the comments.`
            ]
        },
        {
            title: `Comments`,
            details: [
                `You can see all the comments and add your comments by selecting the ‘New Comment’ button.`
            ]
        },
        {
            title: `Reset Reason Codes`,
            details: [
                `You can reverse back all the modifications made in Reason Code of a question by selecting the <b>‘Reset Reason Codes’</b> button. The reason codes will roll back to it default figures. Please note that all the reason codes added will be removed.`
            ]
        },  
    ]
    const finalData = isSingleLayout?singleLineData:multipleLineData;

    const handleClose = () => {
        setActiveSlide(null);
        let highlightArea = document.querySelectorAll('.highlight-area');
        highlightArea.forEach(i=>{
            i?.classList.remove('highlight-area');
        });
        setIsResetDefaultButton(false);
        setIsReasonEditMode(false);
        setIsReasonCodePopUp(false);
        setWalkthroughReasonButtonShow(false);
        setIsSetupType(isSingleLayout?1:false);
        setTimeout(()=>{
            let popBackground = document.querySelector("[data-component = PopupCard]");
            popBackground?.classList.remove('walkthrough-active');
        },1);

        serviceProvider
        .post(
          "/businesscaseplantloss?type=loss_assumption",
          JSON.stringify({ uid: uid }),
          true
        )
        .then((response) => { });


    }
    
    let popBackground = document.querySelector("[data-component = PopupCard]");
    let oneFigureLayout = document.querySelector("[data-component = SetUpCardWrapOne]");
    let questionCardHeader = ``;
    let questionCardBodyRow = ``;
    let questionCardPercentCol = ``;
    let reasonCodeCol = ``;
    let resetDefaultButton = ``;
    let viewButton = ``;
    let addReasonButton = ``;
    let editReasonButton = ``;
    let saveChangesButton = ``;
    let reasonMidCol = ``;
    let reasonRightCol = ``;
    let closeBtn = ``;

    const handleMultiLineWalkthrough = ()=>{
        // main container background
        if(activeSlide>1){
            questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
            questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
            questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
            reasonCodeCol = questionCardHeader?.querySelectorAll("[data-component=QuestionsCardCol2]");
            resetDefaultButton = document.querySelector("[data-component=ResetDefaultButton]");
        }
        if(activeSlide>6){
            viewButton = document.querySelector("[data-component=ViewButton]");
            addReasonButton = document.querySelector('.add-reason-btn');
            editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
            saveChangesButton = document.querySelector("[data-component=SaveChangesButton]");
            reasonMidCol = document.querySelector("[data-component=ReasonCodeTableBody] [data-component=ReasonCodeTableMidCol]");
            reasonRightCol = document.querySelector("[data-component=ReasonCodeTableBody] [data-component=ReasonCodeTableRightCol]");
        }
        popBackground?.classList.add('walkthrough-active');
        switch(activeSlide){
            case 1:
                popBackground?.classList.remove('walkthrough-active');
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(false);
                setIsSetupType(false);
            break;
            case 2:
                popBackground?.classList.remove('walkthrough-active');
                oneFigureLayout?.click();
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(false);
            break;
            case 3:
                questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                // variable
                questionCardHeader?.classList.add('highlight-area');
                questionCardBodyRow?.classList.add('highlight-area');
                editReasonButton?.classList.add('highlight-area');
                if(questionCardPercentCol){
                    questionCardPercentCol[0]?.classList.remove('highlight-area');
                    questionCardPercentCol[1]?.classList.remove('highlight-area');
                }
                if(reasonCodeCol){
                    reasonCodeCol[1]?.classList.remove('highlight-area');
                }
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(false);
            break;  
            case 4:
                questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                // variable
                questionCardHeader?.classList.remove('highlight-area');
                questionCardBodyRow?.classList.remove('highlight-area');
                editReasonButton?.classList.remove('highlight-area');
                if(questionCardPercentCol){
                    questionCardPercentCol[0]?.classList.add('highlight-area');
                    questionCardPercentCol[1]?.classList.remove('highlight-area');
                }
                if(reasonCodeCol){
                    reasonCodeCol[1]?.classList.remove('highlight-area');
                }
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(false);
            break;
            case 5:
                questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                reasonCodeCol = questionCardHeader?.querySelectorAll("[data-component=QuestionsCardCol2]");
                questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                // variable
                questionCardHeader?.classList.remove('highlight-area');
                questionCardBodyRow?.classList.remove('highlight-area');
                editReasonButton?.classList.remove('highlight-area');
                if(questionCardPercentCol){
                    questionCardPercentCol[0]?.classList.remove('highlight-area');
                    questionCardPercentCol[1]?.classList.add('highlight-area');
                }
                if(reasonCodeCol){
                    reasonCodeCol[1]?.classList.add('highlight-area');
                }
                setIsResetDefaultButton(false);
                setWalkthroughReasonButtonShow(false);
            break;
            case 6:
                setTimeout(()=>{
                    questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                    reasonCodeCol = questionCardHeader?.querySelectorAll("[data-component=QuestionsCardCol2]");
                    questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                    questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                    // variable
                    if(reasonCodeCol){
                        reasonCodeCol[1]?.classList.remove('highlight-area');
                    }
                    if(questionCardPercentCol){
                        questionCardPercentCol[1]?.classList.remove('highlight-area');
                    }
                    document.querySelector("[data-component=ResetDefaultButton]")?.classList.add('highlight-area');
                },1)
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(true);
                setIsReasonCodePopUp(false);
            break;
            case 7:
                setIsResetDefaultButton(false);
                viewButton?.click();
                setTimeout(()=>{
                    addReasonButton = document.querySelector('.add-reason-btn');
                    editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                    // variable
                    editReasonButton?.classList.add('highlight-area');
                    addReasonButton?.classList.add('highlight-area');
                },0);
                setWalkthroughReasonButtonShow(false);
                setIsReasonEditMode(false);
            break;
            case 8:
                addReasonButton?.classList.remove('highlight-area');
                editReasonButton?.click();
                reasonMidCol?.classList.add('highlight-area');
                setWalkthroughReasonButtonShow(false);
                setTimeout(()=>{
                    saveChangesButton = document.querySelector("[data-component=SaveChangesButton]");
                    saveChangesButton?.classList.add('highlight-area');
                },0);
                
            break;
            case 9:
                reasonMidCol?.classList.add('highlight-area');
                saveChangesButton?.classList.remove('highlight-area');
                reasonRightCol?.classList.remove('highlight-area');
                setWalkthroughReasonButtonShow(false);
            break;
            case 10:
                reasonMidCol?.classList.remove('highlight-area');
                setWalkthroughReasonButtonShow(false);
                reasonRightCol?.classList.add('highlight-area');
                editReasonButton?.click();
                closeBtn = document.querySelector('#commentPopup [data-component=PopupCloseButton]');
                if(closeBtn){
                    closeBtn?.click();
                }
            break;
            case 11:
                reasonMidCol?.classList.remove('highlight-area');
                reasonRightCol?.classList.remove('highlight-area');
                setWalkthroughReasonButtonShow(false);
                let viewBtn = document.querySelector("[data-component=ReasonCodeTableRightCol] .view-btn");
                viewBtn?.click();
            break;
            case 12:
                closeBtn = document.querySelector('#commentPopup [data-component=PopupCloseButton]');
                closeBtn?.click();
                setWalkthroughReasonButtonShow(true);
                setTimeout(()=>{
                    resetDefaultButton = document.querySelector("[data-component=ResetDefaultButton]");
                    resetDefaultButton?.classList.add('highlight-area');
                },1);
            break;
            case 13:
                setIsSetupType(2);
                setIsReasonCodePopUp(false);
                setIsReasonEditMode(false);
                popBackground?.classList.remove('walkthrough-active');
            break;
            default:
                break;
        }
    }
    const handleSingleLineWalkthrough = ()=>{
        // main container background
        if(activeSlide>1){
            questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
            questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
            questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
            reasonCodeCol = questionCardHeader?.querySelectorAll("[data-component=QuestionsCardCol2]");
            resetDefaultButton = document.querySelector("[data-component=ResetDefaultButton]");
        }
        if(activeSlide>6){
            viewButton = document.querySelector("[data-component=ViewButton]");
            addReasonButton = document.querySelector('.add-reason-btn');
            editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
            saveChangesButton = document.querySelector("[data-component=SaveChangesButton]");
            reasonMidCol = document.querySelector("[data-component=ReasonCodeTableBody] [data-component=ReasonCodeTableMidCol]");
            reasonRightCol = document.querySelector("[data-component=ReasonCodeTableBody] [data-component=ReasonCodeTableRightCol]");
        }
        popBackground?.classList.add('walkthrough-active');
        switch(activeSlide){
            case 1:
                popBackground?.classList.remove('walkthrough-active');
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(false);
                setIsSetupType(1);
                setIsReasonCodePopUp(false);
                setIsReasonEditMode(false);
            break;
            case 2:
                questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                // variable
                questionCardHeader?.classList.add('highlight-area');
                questionCardBodyRow?.classList.add('highlight-area');
                editReasonButton?.classList.add('highlight-area');
                if(questionCardPercentCol){
                    questionCardPercentCol[0]?.classList.remove('highlight-area');
                    questionCardPercentCol[1]?.classList.remove('highlight-area');
                }
                if(reasonCodeCol){
                    reasonCodeCol[1]?.classList.remove('highlight-area');
                }
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(false);
                setIsReasonEditMode(false);
            break;  
            case 3:
                questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                // variable
                questionCardHeader?.classList.remove('highlight-area');
                questionCardBodyRow?.classList.remove('highlight-area');
                editReasonButton?.classList.remove('highlight-area');
                if(questionCardPercentCol){
                    questionCardPercentCol[0]?.classList.add('highlight-area');
                    questionCardPercentCol[1]?.classList.remove('highlight-area');
                }
                if(reasonCodeCol){
                    reasonCodeCol[1]?.classList.remove('highlight-area');
                }
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(false);
                setIsReasonEditMode(false);
            break;
            case 4:
                questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                reasonCodeCol = questionCardHeader?.querySelectorAll("[data-component=QuestionsCardCol2]");
                questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                // variable
                questionCardHeader?.classList.remove('highlight-area');
                questionCardBodyRow?.classList.remove('highlight-area');
                editReasonButton?.classList.remove('highlight-area');
                if(questionCardPercentCol){
                    questionCardPercentCol[0]?.classList.remove('highlight-area');
                    questionCardPercentCol[1]?.classList.add('highlight-area');
                }
                if(reasonCodeCol){
                    reasonCodeCol[1]?.classList.add('highlight-area');
                }
                setIsResetDefaultButton(false);
                setWalkthroughReasonButtonShow(false);
                setIsReasonEditMode(false);
            break;
            case 5:
                setTimeout(()=>{
                    questionCardPercentCol = questionCardBodyRow?.querySelectorAll("[data-component=QuestionsCardCol2]");
                    reasonCodeCol = questionCardHeader?.querySelectorAll("[data-component=QuestionsCardCol2]");
                    questionCardHeader = document.querySelector("[data-component=QuestionsCardHeader]");
                    questionCardBodyRow = document.querySelector("[data-component=QuestionsCardBody] [data-component=QuestionsCardRow]");
                    // variable
                    if(reasonCodeCol){
                        reasonCodeCol[1]?.classList.remove('highlight-area');
                    }
                    if(questionCardPercentCol){
                        questionCardPercentCol[1]?.classList.remove('highlight-area');
                    }
                    document.querySelector("[data-component=ResetDefaultButton]")?.classList.add('highlight-area');
                },1)
                setWalkthroughReasonButtonShow(false);
                setIsResetDefaultButton(true);
                setIsReasonCodePopUp(false);
                setIsReasonEditMode(false);
            break;
            case 6:
                reasonMidCol = document.querySelector("[data-component=ReasonCodeTableBody] [data-component=ReasonCodeTableMidCol]");
                if(reasonMidCol){
                    reasonMidCol?.classList.remove('highlight-area');
                }
                setIsResetDefaultButton(false);
                viewButton = document.querySelector("[data-component=ViewButton]");
                if(viewButton){
                    viewButton?.click();
                }
                setTimeout(()=>{
                    addReasonButton = document.querySelector('.add-reason-btn');
                    editReasonButton = document.querySelector("[data-component=EditFiguresButton]");
                    // variable
                    editReasonButton?.classList.add('highlight-area');
                    addReasonButton?.classList.add('highlight-area');
                },0);
                setWalkthroughReasonButtonShow(false);
                setIsReasonEditMode(false);
            break;
            case 7:
                addReasonButton?.classList.remove('highlight-area');
                editReasonButton?.click();
                reasonMidCol?.classList.add('highlight-area');
                setWalkthroughReasonButtonShow(false);
                setTimeout(()=>{
                    saveChangesButton = document.querySelector("[data-component=SaveChangesButton]");
                    saveChangesButton?.classList.add('highlight-area');
                },0);
                
            break;
            case 8:
                reasonMidCol?.classList.add('highlight-area');
                saveChangesButton?.classList.remove('highlight-area');
                reasonRightCol?.classList.remove('highlight-area');
                setWalkthroughReasonButtonShow(false);
                setIsReasonEditMode(false);
            break;
            case 9:
                reasonMidCol?.classList.remove('highlight-area');
                setWalkthroughReasonButtonShow(false);
                reasonRightCol?.classList.add('highlight-area');
                editReasonButton?.click();
                closeBtn = document.querySelector('#commentPopup [data-component=PopupCloseButton]');
                if(closeBtn){
                    closeBtn?.click();
                }
                setIsReasonEditMode(false);
            break;
            case 10:
                reasonMidCol?.classList.remove('highlight-area');
                reasonRightCol?.classList.remove('highlight-area');
                setWalkthroughReasonButtonShow(false);
                let viewBtn = document.querySelector("[data-component=ReasonCodeTableRightCol] .view-btn");
                viewBtn?.click();
                setIsReasonEditMode(false);
            break;
            case 11:
                closeBtn = document.querySelector('#commentPopup [data-component=PopupCloseButton]');
                closeBtn?.click();
                setWalkthroughReasonButtonShow(true);
                setTimeout(()=>{
                    resetDefaultButton = document.querySelector("[data-component=ResetDefaultButton]");
                    resetDefaultButton?.classList.add('highlight-area');
                },1);
                setIsReasonEditMode(false);
            break;
            default:
            break;
        }
    }
    const handleSlideDots = (currentIndex)=>{
        let previousIndex = activeSlide;
        let dotBtn = document.querySelectorAll('.dotBtn')
        if(currentIndex>previousIndex){
            for(let i=previousIndex+1;i<currentIndex;i++){
                setTimeout(()=>{
                    // let tmpIndex = i;
                    // console.log(dotBtn[tmpIndex]);
                    // dotBtn[tmpIndex]?.click();
                    setActiveSlide(prv=>prv+1);
                },50);
            }
        }
        // if(currentIndex<previousIndex){
        //     for(let i=previousIndex;i=currentIndex;i--){
        //         setTimeout(()=>{
        //             setActiveSlide(prv=>i);
        //         },100);
        //     }
        // } 
               
    }

    useEffect(() => {
        if(isSingleLayout){
            handleSingleLineWalkthrough();
        }
        else{
            handleMultiLineWalkthrough();
        }
    }, [activeSlide]);

    useEffect(()=>{
        return()=>{
            handleClose();
        }
    },[])


    return (
        <>
            {finalData?.map((item, ind) => (
                <ShowContent active={activeSlide} item={ind + 1} key={ind}>
                    <WalkthroughContainer data-component='WalkthroughContainer' style={{ "display": "block" }}>
                        <WalkthroughWrapper data-component="WalkthroughWrapper"  className={`container-${ind+1} ${isSingleLayout?`single-${ind+1}`:`multi-${ind+1}`}`}>
                            <WalkthroughCard data-component="WalkthroughCard">
                                <WalkSection data-component="WalkSection">
                                    <WalkTopRow data-component="WalkTopRow">
                                        <WalkTopLeftCol data-component="WalkTopLeftCol">
                                            <span>{ind + 1} of {finalData.length} </span>
                                        </WalkTopLeftCol>
                                        <WalkTopRightCol data-component="WalkTopRightCol">
                                            <span className="img" onClick={() => handleClose()}>
                                                <i className='close-icon' dangerouslySetInnerHTML={{ __html: crossSvg }}></i>
                                            </span>
                                        </WalkTopRightCol>
                                    </WalkTopRow>
                                    <WalkMidRow data-component="WalkMidRow">
                                        <h2><span>{item.title}</span></h2>
                                        {item.details.map((paragraph, index) => (
                                            <p key={index} dangerouslySetInnerHTML={{__html:paragraph}}/>
                                        ))}
                                        
                                    </WalkMidRow>
                                    <WalkFooter data-component="WalkFooter">
                                        <WalkFooterLeftCol data-component="WalkFooterLeftCol">
                                            <DotsSection data-component="DotsSection">
                                                {finalData?.map((_, index) => (
                                                    <button className="circle dotBtn" disabled={activeSlide === index + 1 ? true : false} style={{pointerEvents:'none',visibility:'hidden',opacity:'0'}}>
                                                        <i className='close-icon' dangerouslySetInnerHTML={{ __html: circleSvg }}></i>
                                                    </button>))
                                                }
                                            </DotsSection>
                                        </WalkFooterLeftCol>
                                        <WalkFooterRightCol data-component="WalkFooterRightCol">
                                            <ButtonWrapper data-component="ButtonWrapper">
                                                {ind == 0 ?
                                                    <>
                                                        <button className="btn-link" onClick={() => handleClose()}>
                                                            Exit
                                                        </button>
                                                        <button className="solid-btn-link start" onClick={() => setActiveSlide(activeSlide+1)}>
                                                            Let’s go
                                                        </button>
                                                    </>
                                                    : ind == (finalData.length - 1) ?
                                                        <>
                                                            <button className="btn-link" onClick={() => setActiveSlide(1)}>
                                                                Restart
                                                            </button>
                                                            <button className="solid-btn-link start" onClick={() => handleClose()}>
                                                                Exit
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button className="btn-link" onClick={() => setActiveSlide(activeSlide-1)}>
                                                                Previous
                                                            </button>
                                                            <button className="solid-btn-link start" onClick={() => setActiveSlide(activeSlide+1)}>
                                                                Next
                                                            </button>
                                                        </>
                                                }
                                            </ButtonWrapper>
                                        </WalkFooterRightCol>
                                    </WalkFooter>
                                </WalkSection>
                            </WalkthroughCard>
                        </WalkthroughWrapper>
                    </WalkthroughContainer>
                </ShowContent>))
            }
            {/* MainWrapper ended */}
        </>
    );
}

// Function to show content
const ShowContent = (props) => {
    return (
        <>
            {props.active === props.item &&
                <>
                    {props.children}
                </>
            }
        </>
    )
}

// Walkthrough SVG

const crossSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 7.33333C5.63181 6.96514 5.63181 6.36819 6 6C6.36819 5.63181 6.96514 5.63181 7.33333 6L18 16.6667C18.3682 17.0349 18.3682 17.6318 18 18C17.6318 18.3682 17.0349 18.3682 16.6667 18L6 7.33333Z" fill="#118ACB"/>
<path d="M6 18C5.63181 17.6318 5.63181 17.0349 6 16.6667L16.6667 6C17.0349 5.63181 17.6318 5.63181 18 6C18.3682 6.36819 18.3682 6.96514 18 7.33333L7.33333 18C6.96514 18.3682 6.36819 18.3682 6 18Z" fill="#118ACB"/>
</svg>
`
const circleSvg = `<svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
</svg>   
`

