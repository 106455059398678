import React, { useState } from 'react';
import { Modal, Collapse, TextField } from '@material-ui/core'
import { Link } from 'react-router-dom';
import './maturity-dashboard-popups.scss';
import serviceProvider from '../../services/axios'
const SelectBox = (props) => {
    const { options, selectedVal, setSelectedVal, HandleTooltip } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [tmpOption, setTmpOption] = useState(options);
    const [serchInput, setSerchInput] = useState('');
    const HandleOpen = (e) => {
        if (e == 'close') {
            setIsOpen(false);
        } else {
            setIsOpen(!isOpen);
            HandleTooltip();
        }
    }

    const HandleValue = (val) => {
        setSelectedVal(val);
        HandleOpen('close');         // close dropdown list
        setSerchInput('');          // null search input value;
        setTmpOption(options);      // reset all options;
    }
    const HandleSearch = (e) => {
        let val = e.target.value;
        setSerchInput(val);
        if (val) {
            let filterArr = options.filter((item) => item.name.toLowerCase().startsWith(val.toLowerCase()));
            setTmpOption(filterArr);
        } else {
            setTmpOption(options);
        }

    }
    window.onclick = (e) => {
        // close dropdown list on click outside;
        if (!e.target.closest('.input-field.select') && !e.target.closest('.ms-select-options')) {
            HandleOpen('close');
        }
    }
    return (
        <>
            <label className={`label ${isOpen ? 'active' : ''}`}>
                <span className="input-title">Country</span>
                <button className={`input-field select ${selectedVal ? 'selected' : ''}`} onClick={HandleOpen}>
                    <span>{selectedVal ? selectedVal.name : 'Pick a country'}</span>
                    <svg className="ddl-arrow" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 1L5.75736 5.24264L1.51471 1" stroke="#7D868C" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                </button>
                <div className="ms-tooltip">
                    <p className="center-p">
                        Select the Country in which your plant is located.
                    </p>
                </div>
            </label>
            <div className="ms-select-options">
                <div className="ms-select-serch">
                    <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.53022 8.46966C8.82311 8.17677 9.29798 8.17677 9.59088 8.46966L12.5302 11.409C12.8231 11.7019 12.8231 12.1768 12.5302 12.4697C12.2373 12.7626 11.7624 12.7626 11.4696 12.4697L8.53022 9.53032C8.23732 9.23743 8.23732 8.76255 8.53022 8.46966Z" fill="#7D868C" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5ZM5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11Z" fill="#7D868C" />
                    </svg>
                    <input type="text" placeholder="Search" value={serchInput} onChange={(e) => HandleSearch(e)} />
                </div>
                <div className="list-wrapper">
                    {
                        tmpOption.length > 0 ?
                            tmpOption.map((item, index) => <span className="list-link" onClick={() => HandleValue(item)} key={index}>{item.name}</span>)
                            :
                            <span className="list-link no-result-found">no result found</span>
                    }
                </div>
            </div>

        </>
    )
}
export const MaturityPlantSetupPopup = (props) => {
    const countryList = [

        { value: "AF", name: "Afghanistan" },
        { value: "AL", name: "Albania" },
        { value: "DZ", name: "Algeria" },
        { value: "AS", name: "American Samoa" },
        { value: "AD", name: "Andorra" },
        { value: "AO", name: "Angola" },
        { value: "AI", name: "Anguilla" },
        { value: "AQ", name: "Antarctica" },
        { value: "AG", name: "Antigua and Barbuda" },
        { value: "AR", name: "Argentina" },
        { value: "AM", name: "Armenia" },
        { value: "AW", name: "Aruba" },
        { value: "AU", name: "Australia" },
        { value: "AT", name: "Austria" },
        { value: "AZ", name: "Azerbaijan" },
        { value: "BS", name: "Bahamas (the)" },
        { value: "BH", name: "Bahrain" },
        { value: "BD", name: "Bangladesh" },
        { value: "BB", name: "Barbados" },
        { value: "BY", name: "Belarus" },
        { value: "BE", name: "Belgium" },
        { value: "BZ", name: "Belize" },
        { value: "BJ", name: "Benin" },
        { value: "BM", name: "Bermuda" },
        { value: "BT", name: "Bhutan" },
        { value: "BO", name: "Bolivia (Plurinational State of)" },
        { value: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
        { value: "BA", name: "Bosnia and Herzegovina" },
        { value: "BW", name: "Botswana" },
        { value: "BV", name: "Bouvet Island" },
        { value: "BR", name: "Brazil" },
        { value: "IO", name: "British Indian Ocean Territory (the)" },
        { value: "BN", name: "Brunei Darussalam" },
        { value: "BG", name: "Bulgaria" },
        { value: "BF", name: "Burkina Faso" },
        { value: "BI", name: "Burundi" },
        { value: "CV", name: "Cabo Verde" },
        { value: "KH", name: "Cambodia" },
        { value: "CM", name: "Cameroon" },
        { value: "CA", name: "Canada" },
        { value: "KY", name: "Cayman Islands (the)" },
        { value: "CF", name: "Central African Republic (the)" },
        { value: "TD", name: "Chad" },
        { value: "CL", name: "Chile" },
        { value: "CN", name: "China" },
        { value: "CX", name: "Christmas Island" },
        { value: "CC", name: "Cocos (Keeling) Islands (the)" },
        { value: "CO", name: "Colombia" },
        { value: "KM", name: "Comoros (the)" },
        { value: "CD", name: "Congo (the Democratic Republic of the)" },
        { value: "CG", name: "Congo (the)" },
        { value: "CK", name: "Cook Islands (the)" },
        { value: "CR", name: "Costa Rica" },
        { value: "HR", name: "Croatia" },
        { value: "CU", name: "Cuba" },
        { value: "CW", name: "Curaçao" },
        { value: "CY", name: "Cyprus" },
        { value: "CZ", name: "Czechia" },
        { value: "CI", name: "Côte d'Ivoire" },
        { value: "DK", name: "Denmark" },
        { value: "DJ", name: "Djibouti" },
        { value: "DM", name: "Dominica" },
        { value: "DO", name: "Dominican Republic (the)" },
        { value: "EC", name: "Ecuador" },
        { value: "EG", name: "Egypt" },
        { value: "SV", name: "El Salvador" },
        { value: "GQ", name: "Equatorial Guinea" },
        { value: "ER", name: "Eritrea" },
        { value: "EE", name: "Estonia" },
        { value: "SZ", name: "Eswatini" },
        { value: "ET", name: "Ethiopia" },
        { value: "FK", name: "Falkland Islands (the) [Malvinas]" },
        { value: "FO", name: "Faroe Islands (the)" },
        { value: "FJ", name: "Fiji" },
        { value: "FI", name: "Finland" },
        { value: "FR", name: "France" },
        { value: "GF", name: "French Guiana" },
        { value: "PF", name: "French Polynesia" },
        { value: "TF", name: "French Southern Territories (the)" },
        { value: "GA", name: "Gabon" },
        { value: "GM", name: "Gambia (the)" },
        { value: "GE", name: "Georgia" },
        { value: "DE", name: "Germany" },
        { value: "GH", name: "Ghana" },
        { value: "GI", name: "Gibraltar" },
        { value: "GR", name: "Greece" },
        { value: "GL", name: "Greenland" },
        { value: "GD", name: "Grenada" },
        { value: "GP", name: "Guadeloupe" },
        { value: "GU", name: "Guam" },
        { value: "GT", name: "Guatemala" },
        { value: "GG", name: "Guernsey" },
        { value: "GN", name: "Guinea" },
        { value: "GW", name: "Guinea-Bissau" },
        { value: "GY", name: "Guyana" },
        { value: "HT", name: "Haiti" },
        { value: "HM", name: "Heard Island and McDonald Islands" },
        { value: "VA", name: "Holy See (the)" },
        { value: "HN", name: "Honduras" },
        { value: "HK", name: "Hong Kong" },
        { value: "HU", name: "Hungary" },
        { value: "IS", name: "Iceland" },
        { value: "IN", name: "India" },
        { value: "ID", name: "Indonesia" },
        { value: "IR", name: "Iran (Islamic Republic of)" },
        { value: "IQ", name: "Iraq" },
        { value: "IE", name: "Ireland" },
        { value: "IM", name: "Isle of Man" },
        { value: "IL", name: "Israel" },
        { value: "IT", name: "Italy" },
        { value: "JM", name: "Jamaica" },
        { value: "JP", name: "Japan" },
        { value: "JE", name: "Jersey" },
        { value: "JO", name: "Jordan" },
        { value: "KZ", name: "Kazakhstan" },
        { value: "KE", name: "Kenya" },
        { value: "KI", name: "Kiribati" },
        { value: "KP", name: "Korea (the Democratic People's Republic of)" },
        { value: "KR", name: "Korea (the Republic of)" },
        { value: "KW", name: "Kuwait" },
        { value: "KG", name: "Kyrgyzstan" },
        { value: "LA", name: "Lao People's Democratic Republic (the)" },
        { value: "LV", name: "Latvia" },
        { value: "LB", name: "Lebanon" },
        { value: "LS", name: "Lesotho" },
        { value: "LR", name: "Liberia" },
        { value: "LY", name: "Libya" },
        { value: "LI", name: "Liechtenstein" },
        { value: "LT", name: "Lithuania" },
        { value: "LU", name: "Luxembourg" },
        { value: "MO", name: "Macao" },
        { value: "MG", name: "Madagascar" },
        { value: "MW", name: "Malawi" },
        { value: "MY", name: "Malaysia" },
        { value: "MV", name: "Maldives" },
        { value: "ML", name: "Mali" },
        { value: "MT", name: "Malta" },
        { value: "MH", name: "Marshall Islands (the)" },
        { value: "MQ", name: "Martinique" },
        { value: "MR", name: "Mauritania" },
        { value: "MU", name: "Mauritius" },
        { value: "YT", name: "Mayotte" },
        { value: "MX", name: "Mexico" },
        { value: "FM", name: "Micronesia (Federated States of)" },
        { value: "MD", name: "Moldova (the Republic of)" },
        { value: "MC", name: "Monaco" },
        { value: "MN", name: "Mongolia" },
        { value: "ME", name: "Montenegro" },
        { value: "MS", name: "Montserrat" },
        { value: "MA", name: "Morocco" },
        { value: "MZ", name: "Mozambique" },
        { value: "MM", name: "Myanmar" },
        { value: "NA", name: "Namibia" },
        { value: "NR", name: "Nauru" },
        { value: "NP", name: "Nepal" },
        { value: "NL", name: "Netherlands (the)" },
        { value: "NC", name: "New Caledonia" },
        { value: "NZ", name: "New Zealand" },
        { value: "NI", name: "Nicaragua" },
        { value: "NE", name: "Niger (the)" },
        { value: "NG", name: "Nigeria" },
        { value: "NU", name: "Niue" },
        { value: "NF", name: "Norfolk Island" },
        { value: "MP", name: "Northern Mariana Islands (the)" },
        { value: "NO", name: "Norway" },
        { value: "OM", name: "Oman" },
        { value: "PK", name: "Pakistan" },
        { value: "PW", name: "Palau" },
        { value: "PS", name: "Palestine, State of" },
        { value: "PA", name: "Panama" },
        { value: "PG", name: "Papua New Guinea" },
        { value: "PY", name: "Paraguay" },
        { value: "PE", name: "Peru" },
        { value: "PH", name: "Philippines (the)" },
        { value: "PN", name: "Pitcairn" },
        { value: "PL", name: "Poland" },
        { value: "PT", name: "Portugal" },
        { value: "PR", name: "Puerto Rico" },
        { value: "QA", name: "Qatar" },
        { value: "MK", name: "Republic of North Macedonia" },
        { value: "RO", name: "Romania" },
        { value: "RU", name: "Russian Federation (the)" },
        { value: "RW", name: "Rwanda" },
        { value: "RE", name: "Réunion" },
        { value: "BL", name: "Saint Barthélemy" },
        { value: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
        { value: "KN", name: "Saint Kitts and Nevis" },
        { value: "LC", name: "Saint Lucia" },
        { value: "MF", name: "Saint Martin (French part)" },
        { value: "PM", name: "Saint Pierre and Miquelon" },
        { value: "VC", name: "Saint Vincent and the Grenadines" },
        { value: "WS", name: "Samoa" },
        { value: "SM", name: "San Marino" },
        { value: "ST", name: "Sao Tome and Principe" },
        { value: "SA", name: "Saudi Arabia" },
        { value: "SN", name: "Senegal" },
        { value: "RS", name: "Serbia" },
        { value: "SC", name: "Seychelles" },
        { value: "SL", name: "Sierra Leone" },
        { value: "SG", name: "Singapore" },
        { value: "SX", name: "Sint Maarten (Dutch part)" },
        { value: "SK", name: "Slovakia" },
        { value: "SI", name: "Slovenia" },
        { value: "SB", name: "Solomon Islands" },
        { value: "SO", name: "Somalia" },
        { value: "ZA", name: "South Africa" },
        { value: "GS", name: "South Georgia and the South Sandwich Islands" },
        { value: "SS", name: "South Sudan" },
        { value: "ES", name: "Spain" },
        { value: "LK", name: "Sri Lanka" },
        { value: "SD", name: "Sudan (the)" },
        { value: "SR", name: "Suriname" },
        { value: "SJ", name: "Svalbard and Jan Mayen" },
        { value: "SE", name: "Sweden" },
        { value: "CH", name: "Switzerland" },
        { value: "SY", name: "Syrian Arab Republic" },
        { value: "TW", name: "Taiwan" },
        { value: "TJ", name: "Tajikistan" },
        { value: "TZ", name: "Tanzania, United Republic of" },
        { value: "TH", name: "Thailand" },
        { value: "TL", name: "Timor-Leste" },
        { value: "TG", name: "Togo" },
        { value: "TK", name: "Tokelau" },
        { value: "TO", name: "Tonga" },
        { value: "TT", name: "Trinidad and Tobago" },
        { value: "TN", name: "Tunisia" },
        { value: "TR", name: "Turkey" },
        { value: "TM", name: "Turkmenistan" },
        { value: "TC", name: "Turks and Caicos Islands (the)" },
        { value: "TV", name: "Tuvalu" },
        { value: "UG", name: "Uganda" },
        { value: "UA", name: "Ukraine" },
        { value: "AE", name: "United Arab Emirates (the)" },
        { value: "GB", name: "United Kingdom of Great Britain and Northern Ireland (the)" },
        { value: "UM", name: "United States Minor Outlying Islands (the)" },
        { value: "US", name: "United States of America (the)" },
        { value: "UY", name: "Uruguay" },
        { value: "UZ", name: "Uzbekistan" },
        { value: "VU", name: "Vanuatu" },
        { value: "VE", name: "Venezuela (Bolivarian Republic of)" },
        { value: "VN", name: "Viet Nam" },
        { value: "VG", name: "Virgin Islands (British)" },
        { value: "VI", name: "Virgin Islands (U.S.)" },
        { value: "WF", name: "Wallis and Futuna" },
        { value: "EH", name: "Western Sahara" },
        { value: "YE", name: "Yemen" },
        { value: "ZM", name: "Zambia" },
        { value: "ZW", name: "Zimbabwe" },
        { value: "AX", name: "Åland Islands" }
    ]
    const [isFirstTime, setIsFirstTime] = useState({
        first: false,
        second: true,
        third: true,
        fourth: true,
        fifth: true,
        sixth: true
    });
    const [otherAdmin, setOtherAdmin] = useState(false);
    const [popupFlowStep, setPopupFlowSetup] = useState(1);
    const [addFormData, setAddFormData] = useState({
        formPlantName: '',
        formCountry: '',
        formCity: '',
        formPlantAdmin: '',
        formPlantSize: ''
    });
    const [errors,setErrors] = useState([]);


    const handleClose = () => {
        props.setOpen(!props.open);
    }
    
    const HandleForm = (val, name) => {
        const { formPlantName, formCountry, formCity, formPlantAdmin, formPlantSize } = addFormData;
        let tmpObj = { ...addFormData, [name]: val };
        setAddFormData(tmpObj);
        if(!val) {
            setErrors([...errors,name]);
        }else{ 
            setErrors(errors.filter(st=>st !==name))
            
        }
        if (name == 'formPlantName' && val) {
            setIsFirstTime((prevObj) => {
                return {
                    ...prevObj,
                    second: false
                }
            });
        }
        if (name == 'formCountry' && val) {
            setIsFirstTime((prevObj) => {
                return {
                    ...prevObj,
                    third: false
                }
            });
        }
        if (name == 'formCity' && val) {
            setIsFirstTime((prevObj) => {
                return {
                    ...prevObj,
                    fourth: false
                }
            });
        }
        if (name == 'formPlantAdmin' && val) {
            setIsFirstTime((prevObj) => {
                return {
                    ...prevObj,
                    fifth: false
                }
            });
        }
        if (name == 'formPlantSize') {
            setIsFirstTime((prevObj) => {
                return {
                    ...prevObj,
                    sixth: false
                }
            });
        }
    }
    //console.log(errors)
    const AddPlant = () => {


       serviceProvider.post(`/plants/index`,JSON.stringify(addFormData),true).then(res=>console.log(res)).catch(err=>console.log(err))
   
   
   
    }
    console.log(errors);
    return (
        <>
            <Modal open={props.open}
                // onClose={handleClose}
                className="maturity-dashboard-popup">
                <div className="ms-plant-setup-popup">
                    <div className="ms-plant-setup-card">
                        <div className="ms-popup-header">
                            <h2 className="h1">
                                Add new plant
                            </h2>
                            <button className="close-btn" onClick={handleClose}>
                                <img src={require('../../assets/img/maturity-dashboard/cancel-dark.svg').default} alt="X" />
                            </button>
                        </div>
                        {/* ms-popup-header end */}
                        <div className="ms-popup-content">
                            <div className="ms-form-wrapper">
                                <div className={`ms-input-div ${popupFlowStep == 1 ? 'active' : ''}`}>

                                    <label className="label">
                                        <span className="input-title">Plant Name</span>
                                        <input type="text" className={`${(errors.indexOf("formPlantName") > -1) ? '"ms-error' : ""} input-field`} value={addFormData.formPlantName} placeholder="Your Plant" 
                                        onChange={(e) => {
                                            HandleForm(e.target.value, 'formPlantName');
                                        }} 
                                        onFocus={
                                            ()=>setPopupFlowSetup(1)
                                        }
                                        />
                                       
                                        <div className="ms-tooltip">
                                            <p className="center-p">
                                                Enter your plant's name. We recommend to use the company's name followed by a single reference like "Plant".
                                            </p>
                                            <div className="divider-yellow"></div>
                                            <div className="action-p">
                                                <p className="div-img">
                                                    <img src={require('../../assets/img/maturity-dashboard/report-problem.svg').default} alt="true" />
                                                </p>
                                                <span>
                                                    Please note that you will not be able to change the plant name after this step.
                                                </span>
                                            </div>
                                        </div>

                                    </label>
                                    <label className={`${(errors.indexOf("formPlantName") > -1) ?'error': ""}`}>{(errors.indexOf("formPlantName") > -1) ? "Plant Name is required" : null}</label>
                                        
                                    <p className="ms-error">You’ve unlocked the Full Digital Maturity Assessment!</p>
                                </div>
                                {/* plant name input end */}
                                <div className={`ms-input-div ${!isFirstTime.second ? '' : 'overlay-blur'} ${popupFlowStep == 2 ? 'active' : ''}`}>
                                    <SelectBox
                                        selectedVal={addFormData.formCountry}
                                        setSelectedVal={(e) => {
                                            HandleForm(e, 'formCountry');
                                        }}
                                        HandleTooltip={() => setPopupFlowSetup(2)}
                                        options={countryList}
                                    />
                                    <label className={`${(errors.indexOf("formCountry") > -1) ?'error': ""}`}>{(errors.indexOf("formCountry") > -1) ? "Country is required" : null}</label>
                                        
                                </div>
                                {/* country dropdown list end*/}
                                <div className={`ms-input-div ${!isFirstTime.third ? '' : 'overlay-blur'} ${popupFlowStep == 3 ? 'active' : ''}`}>
                                    <label className="label">
                                        <span className="input-title">City</span>
                                        <input type="text" className="input-field" placeholder="City" value={addFormData.formCity} onChange={(e) => HandleForm(e.target.value, 'formCity')}
                                            onFocus={
                                                () => setPopupFlowSetup(3)
                                            } />
                                            
                                        <div className="ms-tooltip">
                                            <p className="center-p">
                                                Select the City in which your plant is located.
                                            </p>
                                        </div>
                                    </label>
                                    <label className={`${(errors.indexOf("formCity") > -1) ?'error': ""}`}>{(errors.indexOf("formCity") > -1) ? "City is required" : null}</label>
                                         
                                </div>
                                {/* City input end */}
                                <div className={`ms-input-div ${!isFirstTime.fourth ? '' : 'overlay-blur'} ${popupFlowStep == 4 ? 'active' : ''}`}>
                                    <p className="ms-rad-title">Select Plant Admin:</p>
                                    <div className="label">
                                        <label className="ms-rad">
                                            <input type="radio" name="formPlantAdmin" value="yourself" onChange={(e) => { HandleForm(e.target.value, 'formPlantAdmin'); setOtherAdmin(false); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(4)
                                                }
                                            />
                                            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx={8} cy={8} r="5.5" />
                                            </svg>
                                            <span>
                                                Yourself
                                            </span>
                                        </label>
                                        <label className="ms-rad">
                                            <input type="radio" name="formPlantAdmin" onChange={(e) => { HandleForm('', 'formPlantAdmin'); setOtherAdmin(true); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(4)
                                                }
                                            />
                                            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx={8} cy={8} r="5.5" />
                                            </svg>
                                            <span>
                                                Other
                                            </span>
                                        </label>
                                        <div className="ms-tooltip">
                                            <p className="center-p">
                                                Enter your plant's name. We recommend to use the company's name followed by a single reference like "Plant".
                                            </p>
                                            <div className="divider-yellow"></div>
                                            <div className="action-p">
                                                <p className="div-img">
                                                    <img src={require('../../assets/img/maturity-dashboard/report-problem.svg').default} alt="true" />
                                                </p>
                                                <span>
                                                    Note: Plant Admins can edit and set-up Loss Analysis. Selecting another user, will remove edit rights from your account.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Collapse in={otherAdmin} timeout={80}>
                                        <label className="label ms-mt-14">
                                            <input type="text" className="input-field" placeholder="Type their email address here" value={addFormData.formPlantAdmin} onChange={(e) => { HandleForm(e.target.value, 'formPlantAdmin'); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(4)
                                                }
                                            />
                                        </label>
                                    </Collapse>
                                    <label className={`${(errors.indexOf("formPlantAdmin") > -1) ?'error': ""}`}>{(errors.indexOf("formPlantAdmin") > -1) ? "Admin Plant is required" : null}</label>
                                    
                                </div>
                                {/* Plant Admin end */}
                                <div className={`ms-input-div FTE ${!isFirstTime.fifth ? '' : 'overlay-blur'} ${popupFlowStep == 5 ? 'active' : ''}`}>
                                    <p className="ms-rad-title">Plant size (FTEs):</p>
                                    <div className="label">
                                        <label className="ms-rad">
                                            <input type="radio" value={"< 100"} name="formPlantSize" onChange={(e) => { HandleForm(e.target.value, 'formPlantSize'); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(5)
                                                }
                                            />
                                            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx={8} cy={8} r="5.5" />
                                            </svg>
                                            <span>
                                                {"< 100"}
                                            </span>
                                        </label>
                                        <label className="ms-rad">
                                            <input type="radio" value={"100 - 200"} name="formPlantSize" onChange={(e) => { HandleForm(e.target.value, 'formPlantSize'); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(5)
                                                }
                                            />
                                            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"

                                            >
                                                <circle cx={8} cy={8} r="5.5" />
                                            </svg>
                                            <span>
                                                {"100 - 200"}
                                            </span>
                                        </label>
                                        <label className="ms-rad">
                                            <input type="radio" value={"200 - 500"} name="formPlantSize" onChange={(e) => { HandleForm(e.target.value, 'formPlantSize'); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(5)
                                                }
                                            />
                                            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx={8} cy={8} r="5.5" />
                                            </svg>
                                            <span>
                                                {"200 - 500"}
                                            </span>
                                        </label>
                                        <label className="ms-rad">
                                            <input type="radio" value={"500 - 1000"} name="formPlantSize" onChange={(e) => { HandleForm(e.target.value, 'formPlantSize'); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(5)
                                                }
                                            />
                                            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx={8} cy={8} r="5.5" />
                                            </svg>
                                            <span>
                                                {"500 - 1000"}
                                            </span>
                                        </label>
                                        <label className="ms-rad">
                                            <input type="radio" value={"> 1000"} name="formPlantSize" onChange={(e) => { HandleForm(e.target.value, 'formPlantSize'); }}
                                                onFocus={
                                                    () => setPopupFlowSetup(5)
                                                }
                                            />
                                            <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx={8} cy={8} r="5.5" />
                                            </svg>
                                            <span>
                                                {"> 1000"}
                                            </span>
                                        </label>
                                        <div className="ms-tooltip">
                                            <p className="center-p">
                                                Select the correct Plant size, based on the number of employees.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Plant Admin end */}
                                <div className={`ms-footer-btn-wrapper ${!isFirstTime.sixth ? '' : 'overlay-blur'} ${popupFlowStep == 6 ? 'active' : ''}`}  >
                                    <button className="ms-add-btn" onClick={AddPlant} onMouseEnter={() => {
                                        setPopupFlowSetup(6)
                                    }}>
                                        + Add Plant
                                    </button>
                                    <div className="ms-tooltip">
                                        <p className="center-p">
                                            Select Add Plant to create your plant and start your Loss Analysis Exercise.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ms-popup-content end */}
                    </div>
                </div>
                {/* "ms-plant-setup-popup end */}
            </Modal>

        </>
    )
}
// MaturityPlantSetupPopup end
export const MaturitySubmitDataPopup = (props) => {

    const [isSubmitLoadding, setIsSubmitLoadding] = useState(false);
    const handleClose = () => {
        props.setOpen(!props.open);
    }
    const HandleSubmit = () => {
        setIsSubmitLoadding(true);
        setTimeout(() => {
            setIsSubmitLoadding(false);
            handleClose();
        }, 3000);
    }
    return (
        <>
            <Modal open={props.open}
                // onClose={handleClose}
                className="maturity-dashboard-popup">
                <div className="ms-submit-data-popup">
                    <div className="ms-submit-card">
                        <div className="before-submit" style={{ visibility: isSubmitLoadding ? 'hidden' : 'visible' }}>
                            <button className="close-btn" onClick={handleClose}>
                                <img src={require('../../assets/img/maturity-dashboard/blue-close-icon.svg').default} alt="" X />
                            </button>
                            <div className="ms-submit-header">
                                <img className="ms-unsaved" src={require('../../assets/img/maturity-dashboard/ms-unsaved.svg').default} alt="true" />
                            </div>
                            <div className="ms-submit-content">
                                <p>
                                    By submitting the data you will no longer have the opportunity for further editing. Please make sure that the data provided are in their most updated form.
                                </p>
                                <p className="bold-p">Are you sure you want to proceed?</p>
                            </div>
                            <div className="ms-submit-footer">
                                <button className="btn-cancel" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button className="btn-submit" onClick={HandleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div className="submit-loadding" style={{ visibility: !isSubmitLoadding ? 'hidden' : 'visible' }}>
                            <img src={require('../../assets/img/maturity-dashboard/submit-animation.png').default} alt="loadding..." />
                            <p className="loadding-text">
                                Submitting and uploading your data...
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export const AssignSectionPopup = (props) => {

    const demoData = [
        {
            name: 'Anna Smith',
            circleName: 'as',
        },
        {
            name: 'Bratt Hemsworth',
            circleName: 'bh',
        },
        {
            name: 'Catherine Goldsmith',
            circleName: 'cg',
        },
        {
            name: 'Danielle Stevens ',
            circleName: 'ds',
        },
        {
            name: 'Ellen Kelley  ',
            circleName: 'ek',
        },
        {
            name: 'Fabian Erizzo ',
            circleName: 'fe',
        },]
    const [msAssignSearch, setMsAssignSearch] = useState('');
    const [invitedMember, setInvitedMember] = useState(demoData);
    const [isInvite, setIsInvite] = useState(false);
    const [multiEmailVal, setMultiEmailVal] = useState([]);
    const [singleEmail, setSingleEmail] = useState("");
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [selectedOwners, setSelectedOwners] = useState([]);
    const HandleSelectedOwners = (val) => {
        let tmpArr = [...selectedOwners];
        if (tmpArr.indexOf(val.name) === -1) {
            tmpArr.unshift(val.name);
            setSelectedOwners(tmpArr);
        } else {
            let filterArr = tmpArr.filter((item, index) => {
                return item !== val.name;
            });
            setSelectedOwners(filterArr);
        }
    }
    const RemoveOwner = (tmpIndex) => {
        let filterArr = selectedOwners.filter((item, index) => {
            return tmpIndex !== index;
        });
        setSelectedOwners(filterArr);
    }
    const HandleSingleEmailValue = (e) => {
        let tmpValue = e.target.value;
        setSingleEmail(tmpValue);
    };
    const AddMultiEmail = (e) => {
        let tmpArr = [...multiEmailVal];
        const emailRegx =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (e.key === "Enter" && emailRegx.test(String(singleEmail).toLowerCase()) && tmpArr.indexOf(singleEmail) === -1) {
            const addEmail = singleEmail;
            tmpArr.push(addEmail);
            setSingleEmail("");
            setMultiEmailVal(tmpArr);
        }
    };
    const RemoveEmail = (e) => {
        let filterEmail = multiEmailVal.filter((item, index) => {
            return e !== index;
        });
        setMultiEmailVal(filterEmail);
    };
    const handleClose = () => {
        props.setOpen(!props.open);
        setIsInvite(false);
    }
    const HandleValue = (e) => {
        let val = e.target.value;
        setMsAssignSearch(val);
        let filterArr = invitedMember.filter((item, index) => {
            return item.name.toLowerCase().startsWith(val.toLowerCase());
        });
        if (val.length > 0) {
            setInvitedMember(filterArr);
        } else {
            setInvitedMember(demoData);
        }
    }
    const HandleInvite = () => {
        setIsEmailSent(true);
        setTimeout(() => {
            setIsEmailSent(false);
            handleClose();
        }, 2000)
    }
    return (
        <Modal open={props.open}
            // onClose={handleClose}
            className="maturity-dashboard-popup">
            <div className="ms-assign-section-popup">
                <div className="ms-assign-section-card">
                    {!isEmailSent ?
                        <>
                            <div className="ms-assign-header">
                                <span>Assign team member to [Section name]</span>
                                <button className="close-btn" onClick={handleClose}>
                                    <img src={require('../../assets/img/maturity-dashboard/cancel-dark.svg').default} alt="X" />
                                </button>
                            </div>
                            {/* ms-assign-header end */}
                            <div className="ms-assign-content">
                                <div className="ms-select-owner-div" style={{ display: isInvite ? 'none' : '' }}>
                                    <div className={`ms-assign-search-box ${msAssignSearch.length > 0 ? 'active' : ''}`}>
                                        <label className="ms-assign-search">
                                            <img src={require('../../assets/img/maturity-dashboard/ms-assign-search.svg').default} alt="true" />
                                            <input type="text" placeholder="Search.." onChange={(e) => HandleValue(e)} value={msAssignSearch} />
                                        </label>
                                    </div>
                                    {/* ms-assign-search-box end */}
                                    <div className="ms-invite-div">
                                        <p>Select Owners</p>
                                        <button className="btn-invite" onClick={() => { setIsInvite(true) }}>
                                            Email Invite
                                        </button>
                                    </div>
                                    <div className="contact-empty-div" style={{ display: invitedMember.length > 0 ? 'none' : '' }}>
                                        <p>Your contact list is empty.</p>
                                        <p>Click on <span className="blue-link">Email Invite</span> to assign owners.</p>
                                    </div>

                                    <div className="ms-assign-scroll-div">
                                        <div className="selected-member-ul" style={{ display: selectedOwners.length > 0 ? '' : 'none' }}>
                                            {selectedOwners.map((item, index) => <div className="selected-member-li">
                                                <span>{item}</span>
                                                <button className="remove-list" onClick={() => RemoveOwner(index)}>
                                                    <img src={require('../../assets/img/maturity-dashboard/email-list-cancel.svg').default} alt="X" />
                                                </button>
                                            </div>)}
                                        </div>

                                        <div className="select-owner-text" style={{ display: selectedOwners.length > 0 ? '' : 'none' }}>
                                            Select Owners
                                        </div>
                                        <div className="ms-cir-list-wrapper">
                                            {invitedMember.map((item, index) => <div className={`ms-cir-li ${selectedOwners.indexOf(item.name) !== -1 && 'active'}`} onClick={() => HandleSelectedOwners(item)} key={index}>
                                                <div className={`ms-cir-div cir-${item.name[0].toLocaleLowerCase()}`}>{item.circleName}</div>
                                                <div className="ms-cir-name">
                                                    <span>{item.name} </span>
                                                </div>
                                                <img className="selected-icon" src={require('../../assets/img/maturity-dashboard/cir-select-chk.svg').default} />
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                                {/* ms-select-owner-div end */}
                                <div className="ms-invite-member-div" style={{ display: !isInvite ? 'none' : '' }}>
                                    <div className={`ms-assign-search-box ${msAssignSearch.length > 0 ? 'active' : ''}`}>
                                        <label className="ms-assign-search">
                                            <img src={require('../../assets/img/maturity-dashboard/ms-assign-search.svg').default} alt="true" />
                                            <input type="text" placeholder="Search.." onChange={(e) => HandleValue(e)} value={msAssignSearch} />
                                        </label>
                                    </div>
                                    {/* ms-assign-search-box end */}
                                    <div className="ms-invite-div">
                                        <p>Email Invite</p>
                                    </div>
                                    <div className="email-input-wrapper">
                                        <label className="label">
                                            <span className="input-title">Email</span>
                                            <input type="text" value={singleEmail} onChange={(e) => HandleSingleEmailValue(e)} onKeyPress={(e) => AddMultiEmail(e)} className="input-field" placeholder="Type e-mail address" />
                                        </label>
                                    </div>
                                    <div className="ms-assign-scroll-div">
                                        <div className="selected-member-ul">
                                            {multiEmailVal.map((item, index) => <div className="selected-member-li">
                                                <span>{item}</span>
                                                <button className="remove-list" onClick={() => RemoveEmail(index)}>
                                                    <img src={require('../../assets/img/maturity-dashboard/email-list-cancel.svg').default} alt="X" />
                                                </button>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                                {/* ms-select-owner-div end */}
                            </div>
                            {/* ms-assign-content end */}
                            <div className="ms-assign-footer">
                                {!isInvite ?
                                    <>
                                        <button className="ms-assign-btn cancel" onClick={handleClose}>
                                            Cancel
                                        </button>
                                        <button className="ms-assign-btn blue">
                                            Assign
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className="ms-assign-btn cancel" onClick={() => setIsInvite(false)}>
                                            Back
                                        </button>
                                        <button className="ms-assign-btn blue" onClick={() => HandleInvite()}>
                                            Send Invite
                                        </button>
                                    </>
                                }

                            </div>
                        </>
                        :
                        <div className="invite-successful">
                            <img src={require('../../assets/img/maturity-dashboard/done-check.png').default} alt="invite successfully" />
                            <p>
                                Email invite successfully sent!
                            </p>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    )
}

