/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * ResulttourView ===> custom view component
  * serviceProvider for ===> working with api

**/
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { ResulttourView } from "./resulttour-view";
//ResulttourController start
export const ResulttourController = (props) => {
  const user = JSON.parse(localStorage.getItem("userData"));
 useEffect(() => {
		props.handleExpandFal();
	}, []);
	
	
	const login = localStorage.getItem("isLoggedIn");
	if (!login) {
		return <Redirect to="/sign-in" />;
	}
	
  return <ResulttourView {...props} />;
};
