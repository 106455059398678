import React from 'react';
import { HowToUseView } from './HowToUseView';
export const HowToUseCtrl = (props) => {
    return (
        <>
            <HowToUseView {...props}/>
        </>
    );
}

