export const newCirIcon=`<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11" cy="11" r="10.5" fill="white" stroke="#118ACB"/>
<path d="M11.2831 9.94842C12.7426 9.94842 13.9246 8.84121 13.9246 7.47421C13.9246 6.10721 12.7426 5 11.2831 5C9.82364 5 8.64155 6.10721 8.64155 7.47421C8.64155 8.84121 9.82364 9.94842 11.2831 9.94842ZM11.2831 11.1855C9.51986 11.1855 6 12.0144 6 13.6597V14.8968H16.5662V13.6597C16.5662 12.0144 13.0463 11.1855 11.2831 11.1855Z" fill="#118ACB"/>
<ellipse cx="16.2263" cy="14.4026" rx="2.77363" ry="2.59792" fill="white"/>
<rect x="15.8301" y="12.5469" width="0.792465" height="3.71132" fill="#118ACB"/>
<rect x="18.2075" y="14.0303" width="0.742263" height="3.96232" transform="rotate(90 18.2075 14.0303)" fill="#118ACB"/>
</svg>
`;

export const lockSectionIcon=`<svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 3.33333H6.5V2.38095C6.5 1.06667 5.38 0 4 0C2.62 0 1.5 1.06667 1.5 2.38095V3.33333H1C0.45 3.33333 0 3.7619 0 4.28571V9.04762C0 9.57143 0.45 10 1 10H7C7.55 10 8 9.57143 8 9.04762V4.28571C8 3.7619 7.55 3.33333 7 3.33333ZM4 7.61905C3.45 7.61905 3 7.19048 3 6.66667C3 6.14286 3.45 5.71429 4 5.71429C4.55 5.71429 5 6.14286 5 6.66667C5 7.19048 4.55 7.61905 4 7.61905ZM5.55 3.33333H2.45V2.38095C2.45 1.56667 3.145 0.904762 4 0.904762C4.855 0.904762 5.55 1.56667 5.55 2.38095V3.33333Z" fill="#DB324D" fill-opacity="0.9"/>
</svg>
`;

export const unassignDotIcon=`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" class="bg-circle" fill="#7D868C"/>
<circle cx="8" cy="8" r="1" class="dot-circle" fill="white"/>
<circle cx="8" cy="12" r="1" class="dot-circle" fill="white"/>
<circle cx="8" cy="4" r="1"  class="dot-circle" fill="white"/>
</svg>
`;