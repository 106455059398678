/** imported components

  * WlakthroughView ===> custom view component

**/
import React from "react";
import { WlakthroughView } from "./walkthrough-view";
//WalkthroughController start
export const WalkthroughController = (props) => {
	//defining variables here
	const [open, setOpen] = React.useState(true);
	//function to handle open
	const handleOpen = () => {
		setOpen(true);
	};
	//function to handle close
	const handleClose = () => {
		setOpen(false);
	};
	return <WlakthroughView open={open} handleOpen={handleOpen} handleClose={handleClose} {...props} />;
};
