import styled from 'styled-components'
import { Link } from 'react-router-dom';


export const MainWrapper = styled.section`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    height:100%;
    `;

export const HowToUseBtn = styled.button`
    padding: 1.1rem 1.6rem;
    background: linear-gradient(131.53deg, #8686DF 14.79%, #6464C8 85.39%);
    box-shadow: 0px 0px 8px rgba(21, 27, 38, 0.12);
    border-radius: 7.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    .how-txt{
        display: inline-block;
        font-weight: 400;
        font-size: 1.6rem;
        color: #FFFFFF;
        margin-right: 0.8rem;
    }
    i{
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        svg{
            height: 100%;
            width: 100%
        }
    }
    &:hover{
        background: #6464C8;
    }
`
export const HowToUsePopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 99;
`
export const HowToUsePopWrap = styled.div`
    position: absolute;
    width: 99.2rem;
    max-height: calc(100vh - 15rem);
    background: #FFFFFF;
    border: 0.1rem solid #F0F0F0;
    border-radius: 2.4rem;
    padding: 2.4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    @media(max-height:567px) {
        max-height:calc(100vh - 1rem);
    }
    @media screen and (max-width: 1024px){
        max-height: 500px;
        width: 700px;
    }
`
export const HowToUsePopHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 0.06rem solid #CBCFD2;
    .head-txt{
        display: block;
        font-weight: 600;
        font-size: 18px;
        color: #0D2C54;
    }
`
export const HowCloseBtn = styled.button`
    display: inline-block;
    border: unset;
    box-shadow: unset;
    height: 3.2rem;
    width: 3.2rem;
    svg{
        width: 100%;
        height: 100%;
    }
`
export const HowToUsePopBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
`
export const TabHead = styled.div`
    padding: 2.4rem 0;
`
export const TabWrap = styled.div`
    background: #2C55A0;
    border-radius: 0.4rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 0.8rem 1.6rem;
    color: #ffffff;
    width: max-content;
`
export const TabContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-color: #D3E6FF #ffffff;
`
export const TabRow = styled.div`
    border-top: 0.1rem solid #CBCFD2;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem;
    width: 100%;
    &:nth-child(1){
        border-top: 0;
    }
`
export const TabFirstCol = styled.div`
    margin-bottom: 1.6rem;
    width: auto;
    flex: 1;
    padding: 0 1.2rem;
    .card-data{
        background: ${({blue,orange,green}) => blue?'#118acb':orange?'#F4845F':green?'#45B097':''};
        border-radius: 0.8rem;
        padding: 1.4rem 1.2rem;
        .heading{
            display: block;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 1.8rem;
            color: #FFFFFF;
            margin-bottom: 0.8rem;
        }
        .text{
            display: block;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.8rem;
            color: #FFFFFF;
        }
    }
    &:first-child{
        flex: unset;
        width: 38.6rem;
    }
`
export const TabCol = styled.div`
    border-right: 0.1rem solid #CBCFD2;
    width: auto;
    flex: 1;
    padding: 1.6rem 1.2rem;
    p{
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: #4D4D4F;
        &:nth-of-type(2),&:nth-of-type(3){
            margin-top: 1.6rem;
        }
        b{
            font-weight: 600;
        }
    }
    &:last-child{
        border-right: 0;
    }
    .para{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
    &:first-child{
        flex: unset;
        width: 38.6rem;
    }
`
export const NumWrap = styled.div`
    display: flex;
    align-items: center;
`
export const LeftNumCol = styled.div`
    height: 7.2rem;
    width: 7.2rem;
    background: #E5F0FF;
    border-radius: 0.4rem;
    color: #118acb;
    font-weight: 500;
    font-size: 2.4rem;
    display: flex; 
    justify-content: center;
    align-items: center;
`
export const RightTextCol = styled.span`
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-left: 1.6rem;
`