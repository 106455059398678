import styled from 'styled-components'
import { Link } from 'react-router-dom';

export const BlueFooter = styled.div`
    background: #139bd6;
    padding: 1.5rem 5rem 1.5rem 2rem;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;
    left: 0;
    @media(max-width:575px){
        display:none;
    }
    .MuiTabs-root{
        min-height: auto !important;
        .MuiTabs-scrollable{
            padding-left: 2.4rem;
            padding-right: 2.4rem;
        }
    }
    .MuiTabs-flexContainer{
        .tab-btn{
            background: transparent !important;
            pointer-events: auto;
            border-radius: 0.5rem;
            font-weight: 700 !importamt;
            font-size: 1.2rem !important;
            line-height: 1.7rem !important;
            min-width: unset !important;
            width: max-content !important;
            min-height: 2.8rem !important;
            color: #ffffff !important;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            padding: 0 1.4rem;  
            text-transform: capitalize;
            &.active{
                background: rgba(13, 44, 84, 0.3) !important;
                pointer-events: none;
            }  
        }
    }
    .MuiButtonBase-root{
        &.MuiTabScrollButton-root{
            &.MuiTabs-scrollButtons{
                &.MuiTabs-scrollButtonsDesktop{
                    height: 2.4rem !important;
                    width: 2.4rem !important;
                    min-width: unset;
                    background: #fff;
                    .MuiSvgIcon-root{
                        fill: #4d4d4f !important;
                        &.MuiSvgIcon-fontSizeSmall{
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            } 
        }
    } 
`
export const TabButton = styled.button`
    background-color:${({active})=>active?'rgba(13, 44, 84, 0.3)':'transparent'};
    pointer-events: ${({active})=>active?'none':'auto'};
    border-radius: 0.5rem;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.7rem;
    min-width: 17.3rem;
    min-height: 2rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.4rem;
    `
export const ActionLink = styled(Link)`
    position: relative;
    height: 2.8rem;
    display: inline-flex;
    align-items: center;
    padding-right: 1.2rem;
    color: #ffffff;
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding-left: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
`
export const FooterLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 14rem);
`