import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { QuickStart } from "../quickstart/quickstart-ctrl";
import { HomeTourView } from "./home-tour-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";

export const HomeTourControler = (props) => {
	const [results, setResults] = useState([]);
	const [tourComplete, setTourComplete] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		props.handleExpand();
		const search = async () => {
			const apiUrl = `/home?param=homedata&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, [tourComplete]);
	return (
		<>
			<Helmet>
				<title>Home | SmarterChains</title>
				<meta name="description" content="Home" />
			</Helmet>
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<HomeTourView {...props} />
			<QuickStart {...props}/>
		</>
	);
};
