/** imported components

  * serviceProvider for ===> working with api.
  * CartContext for ==> add item into the cart.
  * React Stripe for ==> for Stripe is a payment service.

**/
import React, { useContext, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import serviceProvider from "../../services/axios";
import { apiEndpoints } from '../../services/api-endpoints';
// PaymentMaturitySuccessView start
export const PaymentMaturitySuccessView = (props) => {
  // defining variables
  const { cartItems } = useContext(CartContext);
  const {
    total,
    discount,
    discountPrice,
    itemCount,
    increase,
    decrease,
    removeProduct,
    clearCart,
  } = useContext(CartContext);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  useEffect(() => {
    serviceProvider
      .post("/cart?type=usergroupupdate", JSON.stringify({ uid: usid }), true)
      .then((response) => {
        console.log(response);
      });
  }, []);
  return (
    <div className="cart__page__wrapper">
      {/* 
	  	  Container there are 2 section
	   	  1. Payment successful message box
		    2. Order Summary detail 
	    */}
      <div className="payment__success">
        {/* 1. Payment successful start */}
        <div className="payment__col1">
          <div className="payment__s__card">
            <div className="payment__s__card__body">
              <img
                src={
                  require("../../assets/img/cart-page/payment_success_icon.gif")
                    .default
                }
                className="chk__success"
                alt="true"
              />

              <h2 className="h1">Payment Successful!</h2>
              <p className="p">Transaction Number: {props.tansection_no}</p>
            </div>
            {/* payment__s__card__body end */}
            <div className="payment__s__card__footer">
              <div className="content">
                <div className="f_lft">
                  <p>Amount Paid:</p>
                </div>
                <div className="f_rgt">
                  <p>€{props.paid_ammount}</p>
                </div>
              </div>
            </div>
            {/* payment__s__card__footer end */}
          </div>
          {/* payment__s__card end */}
          <div className="payment-success-bottom">
            <img
              src={
                require("../../assets/img/cart-page/payment-success-bottom.svg")
                  .default
              }
              alt="payment-success-bottom img"
            />
          </div>
        </div>
        {/* Order Summary start */}
        <div className="payment__col2">
          <h2 className="h1">Order Summary</h2>
          <div className="order__sam__list__wrapp">
            {props.orderSummary.map((item, index) => {
              return (
                <div className="order__sam__list" key={index}>
                  {/* <p className="b">{item}</p> */}
                  {/* <p>Training Course</p> */}
                </div>
              );
            })}
          </div>
          <div className="pay_btn_sec">
            <Link
              to="/maturity-dashboard-admin"
              className="send__btn pay_goto_btn"
            >
              Go to Dashboard
            </Link>
            <a
              href={`${apiEndpoints.baseUrl}/certificate/invoicetxnmaturity/${props.uid}/${props.tansection_no}`}
              target="_blank"
              className="send__btn pay_down_btn"
            >
              Download Invoice
            </a>
          </div>
        </div>
        {/* payment__col2 end */}
      </div>
      {/*payment__success end*/}
    </div>
  );
};
