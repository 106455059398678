import styled from "styled-components";
import { Link } from "react-router-dom";

// Walkthrough Styles
export const WalkthroughContainer = styled.div`
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom:  0;
  height: 100%;
  width: 100%;
  z-index: 999;
`;
export const WalkSection = styled.div`
  padding: 2rem 3.2rem 3.2rem 3.2rem;
  width: 100%;
`;
export const WalkthroughCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &.tooltip-class{
    position: relative;
    &::after{
      content: " ";
      position: absolute;
      top: 3.8rem;
      right: 100%;
      border-width: 6px 10px 6px 8px;
      border-style: solid;
      border-color: transparent #fff transparent transparent; 
      @media(max-width:575px){
        display:none;
      }
    }
  }
  `;
export const WalkthroughWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 0.8rem;
  filter: drop-shadow(5px 5px 30px rgba(77, 77, 79, 0.4));
  width: 48.5rem;
  height: 23.9rem;
  z-index: 999;
  @media(max-width:575px){
    width:calc(100% - 30px);
  }
  &.container-1{
    left: 45rem;
    transform: unset;
  }
  &.container-2{
    left: 30rem;
    transform: unset;
    @media(max-width:575px){
        left:10px;
        top:185px !important;
    }
  }
  &.container-3{
    left: 40rem;
    transform: unset;
    @media(max-width:575px){
        left:10px;
        top:185px !important;
    }
  }
  &.container-4{
    left: 24rem;
    transform: unset;
    @media(max-width:575px){
        left: 15px;
        transform: translatey(50px);
  }

`;

export const WalkTopRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 1.8rem;
`;
export const WalkTopLeftCol = styled.div`
span{
    display: inline-block;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #4D4D4F;
    opacity: 0.5;
    @media(max-width: 1200px){
        font-size: 11px;
    }
}
`;
export const WalkTopRightCol = styled.div`
margin-right: -1.2rem;
.img{
    display: inline-block;
    cursor: pointer;
    height: 2.4rem;
    width: 2.4rem;
    @media (max-width: 1200px){
        height: 24px;
        width: 24px;
    }
}
`;
export const WalkMidRow = styled.div`
h2{
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #4D4D4F;
    margin-bottom: 1.1rem;
    text-transform: capitalize;
    @media(max-width: 1200px){
        font-size: 14px;
        margin-bottom: 11px;
    }
}
p{
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #4D4D4F;
    margin-bottom: 2rem;
    min-height: 8.6rem;
    @media(max-width: 1200px){
        font-size: 12px;
        margin-bottom: 20px;
    }
    &:last-child{
    margin-bottom: 0;
    }
}
`;
export const WalkFooter = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;
export const WalkFooterLeftCol = styled.div`
`;
export const WalkFooterRightCol = styled.div`
  .solid-btn-link{
    min-width: 6.8rem;
    @media(max-width: 1200px){
      min-width: 75px;
    }
  }
  .start{
    min-width: 9.4rem;
    @media(max-width: 1200px){
      min-width: 105px;
    }
  }
`;
export const DotsSection = styled.div`
display: flex;
align-items: center;
height: 8px;
.circle{
    display: inline-block;
    margin-right: 1rem;
    height: 100%;
    .close-icon{
        display: flex;
        height: 4px;
        width: 4px;
    }
    
    svg{
        height: 100%;
        width: 100%;
    }
    &:disabled {
        transition: 0.25s;
        .close-icon{
            height: 8px;
            width: 8px; 
        }
        svg{
            circle{
                fill: #139bd6;
            }
        }
    }
    &:last-child{
        margin-right: 0;
    }
}
`;
export const ButtonWrapper = styled.div`
button{
    font-weight: 600;
    font-size: 1.2rem;
    border: none;
    box-shadow: none;
    padding: 0.9rem 1rem;
    @media(max-width: 1200px){
        font-size: 11px;
        padding: 6px 7px;
    }
    &.solid-btn-link{
        background: linear-gradient(91.83deg, #139BD6 12.22%, #0789CF 100.41%); 
        border-radius: 0.4rem;
        border: none;
        color: #ffffff;
        margin-left: 2.2rem;
        min-width: 6.8rem;
        @media(max-width: 1200px){
          margin-left: 10px;
        }
    }
    &.start{
      min-width: 9.4rem;
    }
    &.btn-link{
        color: #118acb;
        padding-left: 0;
        padding-right: 0;
    }
}
`;

/*My task Filter css start*/
export const MytaskFilterContainer = styled.div`
       width:30.7rem;
       background: #FFFFFF;
       border: 0.1rem solid #118ACB;
       border-radius: 0.8rem;
       overflow:hidden;
       position:absolute;
       z-index:5;
       @media(max-width: 740px){
        display: none;
       }
   `;
export const MytaskFilterButton = styled.div`
       width:100%;
       padding:1rem 2rem;
       display:flex;
       align-items:center;
       gap:1.3rem;
       font-weight: 500;
       font-size: 1.4rem;
       color:#2C55A0;
       transition:0.15s ease-in;
       border-bottom:0.1rem solid transparent;
       background:#E5F0FF;
       span{
        flex:1;
       }
       i{
         display:inline-block;
            &.ddl-angle{
                cursor:pointer;
                svg{
                    width:1rem;
                    height:0.7rem;
                    transition:0.15s ease-in;
                    rotate:${({ active }) => active ? '180deg' : '0deg'};
                    path{
                        fill:#2C55A0;
                    }
                }
            }
            &.drag-icon{
                cursor: grab;
                line-height:0;
                user-select:none;
                transition:0.15s ease-in;
                svg{
                    width:2rem;
                    height:3.2rem;
                    rect{
                        fill:transparent;
                    }
                    circle{
                        fill:#A6A7AD;
                    }
                }
                &:hover{
                    svg{
                        rect{
                            fill:#fff;
                        }
                        circle{
                            fill:#2C55A0;
                        }
                    }
                }
        }
       }
       &:hover{
        background: #D3E6FF;
       }
       
   `;

export const TaskDropdownBox = styled.div`
  border-top:0.1rem solid #118ACB;
  min-height:10rem;
  padding:1.6rem 0.8rem 1.6rem 1.6rem;
  max-height:44rem;
  overflow-Y:scroll;
  overflow-X:hidden;
  .divider{
    border-bottom:0.1rem solid #F0F0F0;
    margin:2rem 0rem;
  }
  .all-plant-search{
    width:100%;
  }
`;

export const TaskDropdownTitle = styled.h2`
    font-weight: 500;
    font-size: 1.4rem;
    color: #2C55A0;
    margin-bottom:1.2rem;
    b{
        font-weight:600;
    }
`

export const AllPlantButton = styled.button`
   display:flex;
   justify-content:space-between;
   background:  ${({ active }) => active ? '#E5F0FF' : '#fff'};
   border-radius: 0.4rem;
   padding:1rem 1.2rem; 
   color: ${({ active }) => active ? '#0D2C54' : '#0D2C54'};
   font-weight: 400;
   font-size: 1.4rem;
   width:100%;
   text-align:left;
   border:0.1rem solid #A6A7AD;
   border-bottom-left-radius: ${({ active }) => active ? '0rem' : '0.4rem'};
   border-bottom-right-radius: ${({ active }) => active ? '0rem' : '0.4rem'};
   span{
    // flex:1;
   }
   i{
    display:inline-block;
    &.ddl-angle{
        cursor:pointer;
        svg{
            width:1rem;
            height:0.7rem;
            transition:0.15s ease-in;
            rotate:${({ active }) => active ? '180deg' : '0deg'};
            path{
                fill:${({ active }) => '#0D2C54'};
            }
        }
        @media(max-width:575px){
            transform: rotate(-90deg);
        }
    }
   }
   &:hover{
    background: #E5F0FF;
   }
`
export const TotalTaskButtonWrap = styled.div`
  display:flex;
  flex-direction:column;
  gap:0.8rem;
`
export const TotalTaskButton = styled(AllPlantButton)`
transition:0.15s ease-in;
span{
    border-bottom:0.1rem solid transparent;
}
.text-align-right{
    text-align:right;
}
&:hover{
    span{
        border-color: #118ACB;
    }
}
`;

export const AllPlantCollapseBox = styled.div`
    border: 0.06rem solid #F0F0F0;
    border-top: 0rem;
    border-radius: 0px 0px 0.4rem 0.4rem;
    max-height:30.5rem;
    overflow:auto;
    display:flex;
    flex-direction:column;
    max-height:400px;
    .sm-view{
        display:none;
        padding:1.2rem;
        font-size: 28px;
        font-weight: 700;
    }
    @media(max-width:575px){
        max-height:90vh;
        height:90vh;
        .sm-view{
            display:flex;
            justify-content:space-between;
        }
    }
`
export const SearchInputWrapper = styled.label`
   position:relative;
   padding:1.2rem;
   i{
    pointer-events:none;
    display:inline-block;
    position:absolute;
    left:2rem;
    top:50%;
    line-height:0;
    transform:translateY(-50%);
    svg{
        width:1.6rem;
        height:1.6rem;
    }
   }
`
export const SearchInput = styled.input`
   position:realtive;
   width:100%;
   padding:0.7rem 0.7rem 0.7rem 3.2rem;
   background: #FFFFFF;
   border: 0.06rem solid #CBCFD2;
   border-radius: 0.4rem;
   outline:0;
   font-weight: 400;
   font-size: 1.4rem;
   color:#7D868C;
   &::placeholder{
    color:#7D868C;
   }
`
export const PlantCheckBoxWrapper = styled.div`
   padding:1.2rem 1.4rem;
   display:flex;
   flex-direction:column;
   gap:0.8rem;
   flex:1;
   overflow:auto;
`
export const PlantCheckBox = styled.label`
    positon:relative;
    padding:0.7rem;
    display:flex;
    gap:1rem;
    cursor:pointer;
    transition:0.15s ease-in;
    align-items:center;
    span{
        font-size:1.4rem;
    }
    .chk-icon{
            line-height:0;
            svg{
                 width:1.4rem;
                 height:1.4rem; 
                rect{
                    fill:transparent;
                    stroke-width:0.06rem;
                }
                path{
                    fill:transparent;
                }
            }
    }
     input{
        display:inline-block;
        position:absolute;
        top:0;
        left:0;
        height:0;
        width:0;
        opacity:0;
        &:checked ~ {
            .chk-icon{
                svg{
                   rect{
                       fill:#118ACB;
                   }
                   path{
                       fill:#fff;
                   }
               }
            }
        }
     }
     &:hover{
        background:#E5F0FF;
     }
`

export const DropdownBox = styled.div`
  position:absolute;
  z-index:99;
  width:100%;
  background:#fff;
  @media(max-width:575px){
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 90vh;
    z-index:200;
  }
`

export const ApplyFilterBtnDiv=styled.div`
   padding:2rem;
   display:flex;
   justify-content:flex-end;
   box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.08);
   @media(max-width:575px){
     box-shadow:none;
     justify-content:center;
     padding:10px;
   }
`;
export const ApplyFilterButton=styled.div`
    border-radius: 0.4rem;
    background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
    padding:1rem 2rem;
    color:#FFF;
    font-size:1.4rem;
    @media(max-width:575px){
        width:328px;
        max-width:calc(100% - 30px);
      }
`
/*My task Filter css end*/ 


