/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Link ===> link from react component to use for as an anchor

**/
import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { decrypter, crypter } from "../../webutils/webutils";
import { apiEndpoints } from "../../services/api-endpoints";
import { HomePageWalkthroughView } from "./HomePageWalkthroughView";
import { MyTaskFilter } from './MyTaskFilter';
import { Collapse } from '@material-ui/core';
import serviceProvider from "../../services/axios";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
// import allPlantsDemoData from "./all_plants_demo_data.js";
import { AddPlantPopViewCtrl } from '../upgrade-add-plant-popup/AddPlantPopView-ctrl.js';
import {
  arrowSvg, calibratorSvg, upgradeSvg, plantEditIcon, plantDelIcon, angleIcon, StatusOvalSvg,
  closeSvg, upgradeCloseSvg, noPlantSvg, collapseArrow, skillArrow, unitarBannerCloseSvg, trainingCloseSvg,
  playSvg, uniTarBannerWrapperSvg, uniPlayIconSvg, unitarInfoSvg, unitarCheckSvg, unitarArrow, unitarExploreCloseSvg, minBtnSvg, downloadHoverBtn,downloadIcon
} from "./svg";
//PrepareHomeView component start
export const PrepareHomeView = (props) => {
  const history = useHistory();
  //defining variables here
  const homeDataItems = props.homeData;
  if (!homeDataItems.length) {
    return null;
  }
  if (props.homeData[0].platform_onboarding == 0) {
    serviceProvider
      .post(
        "/home?type=platformonboarding",
        JSON.stringify({ uid: props.homeData[0].uid }),
        true
      )
      .then((response) => { });
    return <Redirect to="/platform-onboarding-tour" />;
  }
  const defaultFilterList = props.defaultData[0];
  const homeDataItem = props.homeData[0];
  const taskdata = props.resultstask[0];
  const isHomeTour = homeDataItem.home_tour;
  const is_free_course = homeDataItem.is_free_course;
  const isPlatformTrainingBtn = homeDataItem.home_plateform_training_button;
  const courses = homeDataItem.courses;
  const paidcourses = homeDataItem.paidcourses;
  const uid = homeDataItem.uid;
  const groupid = localStorage.getItem("group_id");
  let broughttrainging = 0;
  let avaltrainging = 0;
  courses?.map((items, index) => {
    if (items.has_paid == 0) {
      avaltrainging = avaltrainging + 1;
    }
    if (items.has_paid == 1) {
      broughttrainging = broughttrainging + 1;
    }
  });
  const statuses = {
    completed: "status completed",
    "quiz pending": "status ed_quiz",
    "in progress": "status inprogress",
    "to do": "status todo",
    "": "status todo",
  };
  //map for four courses
  const fourcourses = paidcourses?.map((course, index) => {
    if (index < 2) {
      return (
        <div key={course.id} className="prepare-col 1">
          <Link
            className="prp-card"
            to={
              homeDataItem.is_first_training == 1
                ? "/course-walkthrough"
                : course.cardStatus.toLowerCase() === "quiz pending"
                  ? homeDataItem.is_first_quiz == 1
                    ? `/training-courses/${course.course_slug}`
                    : `/training-courses/${course.course_slug}`
                  : `/training-courses/${course.course_slug}`
            }
          >
            <div className="prp-img-div blue-bg">
              <img
                src={
                  require(`../../assets/img/training-card/${course.course_icon}`)
                    .default
                }
                className="icon-img"
                alt={course.course_name}
              />
              {"cardStatus" in course && (
                <span className={statuses[course.cardStatus.toLowerCase()]}>
                  {course.cardStatus && course.cardStatus !== ""
                    ? course.cardStatus
                    : "To Do"}
                </span>
              )}
            </div>
            <div className="prp-text-div">{course.course_name}</div>
          </Link>
        </div>
      );
    }
  });
  //map all courses
  const allcourses = paidcourses?.map((course) => {
    return (
      <>
        <div key={course.id} className="prepare-col 2">
          <Link
            className="prp-card"
            to={
              homeDataItem.is_first_training == 1
                ? "/course-walkthrough"
                : course.cardStatus.toLowerCase() === "quiz pending"
                  ? homeDataItem.is_first_quiz == 1
                    ? `/training-courses/${course.course_slug}`
                    : `/training-courses/${course.course_slug}`
                  : `/training-courses/${course.course_slug}`
            }
          >
            <div className="prp-img-div blue-bg">
              <img
                src={
                  require(`../../assets/img/training-card/${course.course_icon}`)
                    .default
                }
                className="icon-img"
                alt={course.course_name}
              />
              {"cardStatus" in course && (
                <span className={statuses[course.cardStatus.toLowerCase()]}>
                  {course.cardStatus && course.cardStatus !== ""
                    ? course.cardStatus
                    : "To Do"}
                </span>
              )}
            </div>
            <div className="prp-text-div">{course.course_name}</div>
          </Link>
        </div>
      </>
    );
  });
  //all courses listing
  const allcoursesliting = paidcourses?.map((course) => {
    return (
      <div key={course.id} className="indx-home-list-li">
        <span className="right-angle">
          <img
            src={
              require("../../assets/img/home-index/green-right-arrow.svg")
                .default
            }
            alt="true"
          />
        </span>
        <span className="text green">
          {course.cardStatus.toLowerCase() === "completed"
            ? "Go to your"
            : course.cardStatus.toLowerCase() === "quiz pending"
              ? "Continue your"
              : course.cardStatus.toLowerCase() === "in progress"
                ? "Continue your"
                : "Start your"}
          <Link
            to={
              course.cardStatus.toLowerCase() === "quiz pending"
                ? `/training-courses/${course.course_slug}`
                : `/training-courses/${course.course_slug}`
            }
            className="link"
          >
            {course.course_name}
          </Link>
          {course.cardStatus.toLowerCase() === "quiz pending"
            ? "training quiz"
            : "training course"}
        </span>
        <span
          className={
            course.cardStatus.toLowerCase() === "completed"
              ? "work-status green"
              : course.cardStatus.toLowerCase() === "quiz pending"
                ? "work-status orange"
                : course.cardStatus.toLowerCase() === "in progress"
                  ? "work-status orange"
                  : "work-status green"
          }
        >
          {course.cardStatus.toLowerCase() === "completed"
            ? "Completed"
            : course.cardStatus.toLowerCase() === "quiz pending"
              ? "In Progress"
              : course.cardStatus.toLowerCase() === "in progress"
                ? "In Progress"
                : "To Do"}
        </span>
      </div>
    );
  });
  //walkthrough variable
  const [isFreeCourse, setIsFreeCourse] = useState(is_free_course);
  const [activeSlide, setActiveSlide] = useState(1);
  const [allPlants, setAllPlants] = useState(defaultFilterList.all_plants);
  const [filterAllPlants, setFilterAllPlants] = useState(homeDataItem.all_plants);
  const [filterPlant, setFilterPlant] = useState([]);
  const [isFilterDdl, setIsFilterDdl] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState([]);
  const [filterSearchInput, setFilterSearchInput] = useState("");
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [ismPopup, setIsmPopup] = useState(false);
  const [islPopup, setIslPopup] = useState(false);
  const [addPlantMsgPopup, setAddPlantMsgPopup] = useState(false);
  //Upgrade Add Plant Popup
  const [showComponent, setShowComponent] = useState(false);
  // variable to open popup according to the upgrade status
  const [upgradeStatus, setUpgradeStatus] = useState(homeDataItem.upgraded);
  // Collapse Array
  const [collapseArray, setCollapseArray] = useState(homeDataItem.collapseArray);
  // variables for notification popup
  const [isStrComPopup, setIsStrComPopup] = useState(false);
  const [isStrLossPopup, setIsStrLossPopup] = useState(false);
  const [isStrAsmtPopup, setIsStrAsmtPopup] = useState(false);
  const [isStrSiriPopup, setIsStrSiriPopup] = useState(false);
  const [isStrSiriComPopup, setIsStrSiriComPopup] = useState(false);
  // end
  const [isAllCourse, setIsAllCourse] = useState(false);
  const [deletePopupId, setDeletePopupId] = useState(false);
  const [editPopupId, setEditPopupId] = useState(false);
  const [editPopupInput, setEditPopupInput] = useState('');
  //New variables for logistic training
  const [showPlatformTrainingBtn, setShowPlatformTrainingBtn] = useState(true);
  const [selectedDistributionPlant, setSelectedDistributionPlant] = useState(
    []
  );
  const [isAllLogisticCourse, setIsAllLogisticCourse] = useState(false);
  const [appliedDistributionFilter, setAppliedDistributionFilter] = useState([]);
  const [filterSearchDistributionInput, setFilterDistributionSearchInput] =
    useState("");
  const [filterDistributionPlant, setFilterDistributionPlant] = useState([]);
  // Congratulations Popup
  const [congratsPopOpen, setCongratsPopOpen] = useState(false);




  //function for view all course
  const HandleAllCourseView = () => {
    setIsAllCourse(!isAllCourse);
  };
  // Open Add plant popup
  const openSidebarAddPlantPopup = () => {
    let sidebarAddPopupBtn = document.querySelector(
      ".ng-sidebar-wrapper .ed_addpl_btn"
    );

    sidebarAddPopupBtn?.click();
  };
  //Function for view all courses in logistics training
  const HandleAllLogisticCourseView = () => {
    setIsAllLogisticCourse(!isAllLogisticCourse);
  };
  //Function for filter SCNv-2283
  const handleFilterDdl = (val) => {
    if (val == isFilterDdl) {
      setIsFilterDdl(null);
    } else {
      setIsFilterDdl(val);
    }
  };
  // function to clear all selected filters
  const clearAllFilter = () => {
    setSelectedPlant([]);
  };
  // SCNV-2283 Distribution cemter filter changes
  const clearAllDistributionFilter = () => {
    setSelectedDistributionPlant([]);
  };
  //Function to handle popup
  const HandlePopup = () => {
    setIsPopup(true);
  };
  //function to handle popup
  const HandlePopupm = () => {
    setIsmPopup(true);
  };
  //function to handle popup
  const HandlePopuNo = () => {
    return true;
  };
  //function to handle popup
  const HandlePopupl = () => {
    setIslPopup(true);
  };
  //function to handle loss popup
  const HandleLossPopupStr = () => {
    setIsStrLossPopup(true);
    // history.push('/roadmap/step1');
  };
  //function to handle Assessment popup
  const HandleAsmtPopupStr = () => {
    setIsStrAsmtPopup(true);
    // history.push('/roadmap/step1');
  };
  //function to handle Assessment & loss common popup
  const HandleAsmtLossPopupStr = () => {
    setIsStrComPopup(true);
    // history.push('/roadmap/step1');
  };
  // Function to handle collapse content for plants CDP-481
  const handleCollapse = (id) => {
    let filterArray = collapseArray.filter(i => i !== id);
    if (collapseArray.includes(id)) {
      setCollapseArray(filterArray);

      serviceProvider.post("/home?type=statusopenclose", JSON.stringify({ id: id, is_open: 0 }), true).then((response) => { });

    }
    else {
      filterArray.push(id);
      setCollapseArray(filterArray);
      serviceProvider.post("/home?type=statusopenclose", JSON.stringify({ id: id, is_open: 1 }), true).then((response) => { });
    }

  }
  //function to handle input
  const handleInput = (val) => {
    if (val == "all" && selectedPlant.length < allPlants.length) {
      let tmpArr = [];
      allPlants.forEach((el) => {
        tmpArr.push(el.id);
      });
      setSelectedPlant(tmpArr);
    } else if (val == "all" && selectedPlant.length === allPlants.length) {
      setSelectedPlant([]);
    } else if (selectedPlant.indexOf(val) === -1) {
      let tmpArr = [...selectedPlant];
      tmpArr.push(val);
      setSelectedPlant(tmpArr);
    } else if (selectedPlant.indexOf(val) > -1) {
      let tmpArr = selectedPlant.filter((item) => item !== val);
      setSelectedPlant(tmpArr);
    }
  };

  //function to apply filter
  const applyFilter = () => {
    setFilterSearchInput("");
    props.onFilter(selectedPlant);
    setAppliedFilter(selectedPlant);
    setIsFilterDdl(false);
  };
  //function to handle filter search
  const handleFilterSearch = (e) => {
    let val = e.target.value;
    setFilterSearchInput(val);
    if (val.length > 0) {
      let tmpFilterArr = allPlants.filter((item) => {
        let tmpStr = item.plant_name.toLocaleLowerCase();
        let tmpVal = val.toLocaleLowerCase();
        return tmpStr.startsWith(tmpVal);
      });
      setFilterPlant(tmpFilterArr);
    } else {
      setFilterPlant(allPlants);
    }
  };
  //function to clear search filter
  const clearSerachFilter = () => {
    setFilterSearchInput("");
  };
  // SCNV-2283 Distribution center filter changes for logistics training
  const applyDistributionFilter = () => {
    setFilterSearchInput("");
    props.onFilter(selectedDistributionPlant);
    setAppliedDistributionFilter(selectedDistributionPlant);
    setIsFilterDdl(false);
  };
  //search filter changes for logistics training

  const RedirectToResourcePage = () => {
    serviceProvider
      .post(
        "/home?type=sethomeplateformtrainingbtn",
        JSON.stringify({ uid: uid }),
        true
      )
      .then((response) => { });
    setShowPlatformTrainingBtn(false);
    history.push("learning-center/5");
  };
  // SCNV-2283 Distribution cemter filter changes end
  // home tour close function
  const HandleHomeTourWalhthrough = () => {
    serviceProvider
      .post("/home?type=sethometour", JSON.stringify({ uid: uid }), true)
      .then((response) => { });
  };

  const handleShowDeleteModal = (id) => {
    setDeletePopupId(id);
  };
  const handleShowEditModal = (id, name) => {
    setEditPopupId(id);
    setEditPopupInput(name);
  };
  const deletePlantFunction = () => {
    let tmpFilterPlant = allPlants.filter(
      (item) => item.id !== deletePopupId
    );
    setAllPlants(tmpFilterPlant);

    let tmpFilterPlant1 = selectedPlant.filter(
      (item) => item !== deletePopupId
    );
    setSelectedPlant(tmpFilterPlant1);



    let id = deletePopupId;
    serviceProvider
      .post("/home?type=deleteplant", JSON.stringify({ id: id }), true)
      .then((response) => { props.setOnrefresh(!props.onrefresh); });
    setDeletePopupId(false);

  };



  // close edit plant popup
  const closeEditPopup = () => {
    setEditPopupId(false);
    setEditPopupInput('');
  }
  // rename plant name function
  const renamePlantNameFunction = () => {
    let tmpArr = [...filterAllPlants];
    let ind = filterAllPlants.findIndex(item => item.id === editPopupId)
    let updatedObj = {
      ...tmpArr[ind],
      plant_name: editPopupInput.trim()
    }
    tmpArr[ind] = updatedObj;
    //update in filter
    let tmpArrupdate = [...allPlants];
    let indupdate = allPlants.findIndex(item => item.id === editPopupId)
    let objupdateid = {
      ...tmpArrupdate[indupdate],
      plant_name: editPopupInput.trim()
    }
    tmpArrupdate[indupdate] = objupdateid;
    setAllPlants(tmpArrupdate);
    //update in filter

    serviceProvider
      .post("/plants?type=updateplant", JSON.stringify({ updatedObj }), true)
      .then((response) => {
        setFilterAllPlants(tmpArr);
        props.setOnrefresh(!props.onrefresh);
        //window.location.reload(false);
      });
    closeEditPopup();
  }
  // to check plant name is valid or not
  function validateName(str) {
    /* regular expression that matches a string that does not start with 
    a space and does not contain one space after another space:*/
    const pattern = /^(?! )(?!.*  )[^\s].*$/;
    return pattern.test(str);
  }
  // Function to scroll page for adding new plant CDP-444
  const highlightNewPlant = () => {
    let tempArr = [...filterAllPlants];
    // tempArr[tempArr.length-1]={...tempArr[tempArr.length-1],new:true};
    // setFilterAllPlants(tempArr);
    let training_content_div = document.querySelector('.training-content');
    let new_plant = document.querySelector('.highlight-new-plant');
    if (new_plant && filterAllPlants) {
      let plant_id = tempArr[tempArr.length - 1].id;
      setTimeout(() => {
        training_content_div.scrollTop = new_plant.getBoundingClientRect().top;
        // code to remove highlight after 10sec
        setTimeout(() => {
          new_plant.classList.remove('highlight-new-plant');
          serviceProvider.post("/home?type=hidenew", JSON.stringify({ plant_id: plant_id }), true).then((response) => { });
        }, 10000)
        // end code to remove highlight after 10sec
      }, 1000);
    }
  }
  // Function to handle upgrade popup 
  // Handle upgrade add plant
  const handleAddPlant = () => {
    setShowComponent(!showComponent);
  }

  const handleOpenUpgradePopup = () => {
    let upgradeBtn = document.querySelector('.header-icons-li.upgrade .upgrade-btn');
    if (upgradeBtn) {
      upgradeBtn.click();
    }
  }
  const handleOpenContactUSPopup = () => {
    let upgradeBtn = document.querySelector('.header-icons-li.upgrade .contact-btn');
    if (upgradeBtn) {
      upgradeBtn.click();
    }
  }

  const handleSiriPopup = (p) => {
    if (p) {
      setIsStrSiriPopup(true)
    } else {
      setIsStrSiriComPopup(true);
    }
  }
  // Handle loss training
  const handleLossTraining = () => {
    history.push('/product-training/loss-analysis/introduction');
  }
  // Handle Strategy training
  const handleStrategytraining = () => {
    history.push('/product-training/industry-4-0-roadmap/introduction');
  }
  // handle Congrats Popup
  const handleCongratsPopup = () => {
    setCongratsPopOpen(true);
    serviceProvider
      .post(
        "/home?type=setclaimfree",
        JSON.stringify({ uid: uid }),
        true
      )
      .then((response) => { });
  }
  useEffect(() => {
    setSelectedPlant(appliedFilter);
    //SCNV-2283 Filter changes
    setSelectedDistributionPlant(appliedDistributionFilter);
  }, [isFilterDdl]);

  useEffect(() => {
    setFilterAllPlants(homeDataItem.all_plants);
    document.onclick = (e) => {
      if (!e.target.closest(".prp-filter-wrapper")) {
        handleFilterDdl(false);
      }
    };
  }, [props.homeData]);
  // Single time useEffect CDP-444
  useEffect(() => {
    highlightNewPlant();
    localStorage.removeItem(`upgradeSection`);
    localStorage.removeItem(`plantName`);
    localStorage.removeItem(`plantId`);
    localStorage.removeItem(`comboIdget`);
  }, []);
  return (
    <>
      {/* main wrapper */}
      <div className="prepare-home-wrapper">
        {/* page heading */}
        <h1 className="prepare-top-h1 walk-1">
          <img
            src={
              require("../../assets/img/prepare-home/my-trainging-h1.svg")
                .default
            }
            alt="true"
          />
          <span>My Trainings</span>
        </h1>
        {/* Added wrapper for manufacturing courses */}
        <div className="manufacturing-course-wraper">
          {/* training course heading */}
          {/* <div className="training-course-heading">
            <h2>Manufacturing Courses</h2>
          </div> */}
          {/* row has been divided in col */}
          <div className="prepare-row">
            {/* this section has card */}
            {(groupid != 15 || homeDataItem.is_skills_access == 1) && (
              <div className="prepare-col">
                <a
                  className={`prp-card ${homeDataItem.skill_assessment_status === "To Do"
                    ? "skill-active"
                    : ""
                    }`}
                  href="/skill-assessment"
                >
                  <div className="prp-img-div blue-bg">
                    <img
                      src={
                        require("../../assets/img/training-card/skills-assessment.svg")
                          .default
                      }
                      className="icon-img"
                      alt="IR4.0 Skills Assessment"
                    />
                    <span
                      className={
                        homeDataItem.skill_assessment_status === "Completed"
                          ? "status completed"
                          : homeDataItem.skill_assessment_status ===
                            "In Progress"
                            ? "status inprogress"
                            : "status todo"
                      }
                    >
                      {homeDataItem.skill_assessment_status}
                    </span>
                  </div>
                  <div className="prp-text-div">IR4.0 Skills Assessment</div>

                  {(homeDataItem.skill_assessment_status === "Completed" ||
                    homeDataItem.any_skill_completed == 1) && (
                      <div className="prp-text-div">
                        <Link
                          to={"/skill-assessment-analytics"}
                          className="analytic-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <span className="re-text">VIEW ANALYTICS</span>
                          <span
                            className="re-img"
                            dangerouslySetInnerHTML={{ __html: calibratorSvg }}
                          ></span>
                        </Link>
                      </div>
                    )}
                </a>
                {homeDataItem.skill_assessment_status === "To Do" && (
                  <div className="intro-skill-assessment-card" bis_skin_checked="1">
                    <div className="intro-skill-wrap" bis_skin_checked="1">
                      <i className="skill-icon" dangerouslySetInnerHTML={{ __html: skillArrow }}></i>
                      <div className="text-wrap" bis_skin_checked="1">
                        <span>PARTICIPATE IN THE 1st INDUSTRY 4.0 SKILLS STUDY</span>
                        <p className="intro-skill-wrap-p">1. <span>Take the Assessment</span></p>
                        <p className="intro-skill-wrap-p">2. <span>Invite your colleagues</span> </p>
                        <p className="intro-skill-wrap-p">3. <span>See how your IR4.0 Skillset  Benchmarks</span></p>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}
            {(broughttrainging == 1 && isFreeCourse == 1) ?
              <>
                {allcourses}
              </>
              :
              ''
            }
            <div className="prepare-col">
              <a
                className='prp-card'
                href="/learning-center"
              >
                <div className="prp-img-div blue-bg resource-center">
                  <img
                    src={
                      require("../../assets/img/prepare-home/resource-card-img.svg")
                        .default
                    }
                    className="icon-img"
                    alt="IR4.0 Skills Assessment"
                  />

                </div>
                <div className="prp-text-div">Resource Center</div>
              </a>
            </div>

            {broughttrainging == 1 ? (
              <>
                {/* {allcourses} */}
                <div className="prepare-col 3">
                  <div className="prp-card" style={{ cursor: 'pointer' }} onClick={() => { //handleOpenUpgradePopup()
                    history.push('/learn-and-upgrade')
                  }
                  }>
                    <div className="prp-img-div blue-bg available-training-course">
                      <img
                        src={
                          require("../../assets/img/home-index/course_sections-icon-1.svg")
                            .default
                        }
                        className="icon-img"
                        alt="true"
                      />
                      <span class="status learn-upgrade">LEARN MORE & UPGRADE</span>
                    </div>
                    <div className="prp-text-div">
                      Available training courses
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {!isAllCourse ? (
                  <>
                    {fourcourses}
                    {broughttrainging > 3 && (
                      <div className="prepare-col 3 view-all-cards">
                        <div
                          className="btn-show-all-course-wrapper"
                          onClick={HandleAllCourseView}
                        >
                          <div className="view-img-div">
                            <img
                              src={
                                require("../../assets/img/home-index/btn-view-all-course.svg")
                                  .default
                              }
                              alt="view-img"
                            />
                          </div>
                          <p className="prp-text-div">
                            + View all courses
                            {/* See All of my courses */}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {allcourses}
                    <div className="prepare-col 3 view-all-cards">
                      <div
                        className="btn-show-all-course-wrapper"
                        onClick={HandleAllCourseView}
                      >
                        <div className="view-img-div">
                          <img
                            src={
                              require("../../assets/img/home-index/view-less-icon.svg")
                                .default
                            }
                            alt="view-img"
                          />
                        </div>
                        <p className="prp-text-div">- View less courses</p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {(broughttrainging == 1 && isFreeCourse == 0) &&
              <div className="prepare-col">
                <a className='prp-card' href="#" onClick={() => handleCongratsPopup()}>
                  <div className="prp-img-div green-border-bg">
                    <img
                      src={
                        require("../../assets/img/prepare-home/factory-future.svg")
                          .default
                      }
                      className="icon-img"
                      alt="Factory Future"
                    />
                    <span class="status free-course">CLAIM FOR FREE</span>
                  </div>
                  <div className="prp-text-div">1 hour free IR4.0 training</div>
                </a>
              </div>
            }
          </div>

        </div>
        {/* Added wrapper for logistics courses */}
        <div style={{ display: "none" }} className="logistic-course-wraper">
          <div className="training-course-heading">
            <h2>Logistics Courses</h2>
          </div>
          <div className="prepare-row">
            <div className="prepare-col 0">
              <a className="prp-card" href="/skill-assessment">
                <div className="prp-img-div blue-bg">
                  <img
                    src={
                      require("../../assets/img/training-card/skills-assessment.svg")
                        .default
                    }
                    className="icon-img"
                    alt="Skills Assessment"
                  />
                  <span
                    className={
                      homeDataItem.skill_assessment_status === "Completed"
                        ? "status completed"
                        : homeDataItem.skill_assessment_status === "In Progress"
                          ? "status inprogress"
                          : "status todo"
                    }
                  >
                    {homeDataItem.skill_assessment_status}
                  </span>
                </div>
                <div className="prp-text-div">Skills Assessment</div>
              </a>
            </div>
            {broughttrainging == 1 ? (
              <>
                {allcourses}
                <div className="prepare-col 3">
                  <Link to="/training-courses" className="prp-card">
                    <div className="prp-img-div blue-bg">
                      <img
                        src={
                          require("../../assets/img/home-index/course_sections-icon-1.svg")
                            .default
                        }
                        className="icon-img"
                        alt="true"
                      />
                    </div>
                    <div className="prp-text-div">
                      Available training courses
                    </div>
                  </Link>
                </div>
              </>
            ) : (
              <>
                {/* Condition For logistic course */}
                {!isAllLogisticCourse ? (
                  <>
                    {fourcourses}
                    {broughttrainging > 3 && (
                      <div className="prepare-col 3 view-all-cards">
                        <div
                          className="btn-show-all-course-wrapper"
                          onClick={HandleAllLogisticCourseView}
                        >
                          <div className="view-img-div">
                            <img
                              src={
                                require("../../assets/img/home-index/btn-view-all-course.svg")
                                  .default
                              }
                              alt="view-img"
                            />
                          </div>
                          <p className="prp-text-div">
                            + View all courses
                            {/* See All of my courses */}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>{allcourses}</>
                )}
              </>
            )}
            {homeDataItem.training_tour == 0 && broughttrainging < 2 && (
              <div className="prepare-col 4">
                <Link to="/training-walkthrough" className="prp-card">
                  <div className="prp-img-div blue-bg">
                    <img
                      src={
                        require("../../assets/img/home-index/course_sections-icon-2.svg")
                          .default
                      }
                      className="icon-img"
                      alt="true"
                    />
                  </div>
                  <div className="prp-text-div">Training Courses</div>
                </Link>
              </div>
            )}
          </div>
        </div>


        {/* Added wrapper for new category MY Assets and also divided it into two subcategories */}
        <div className="assets-wrapper">
          {/* Main Category Heading */}
          <h1 className="prepare-top-h1 walk-2">
            <img
              src={require("../../assets/img/prepare-home/measure.svg").default}
            />
            <span className="myplant-h1">My Plants</span>
            {/* <img
              src={
                require("../../assets/img/sidebar-img/plant-add.svg").default
              }
              alt="Add"
              className="ed_addpl_btn"
              onClick={openSidebarAddPlantPopup}
            /> */}

            <div className="prepare-assets-heading">
              {/* <img
                    src={
                      require("../../assets/img/prepare-home/measure.svg").default
                    }
                    alt="true"
                  /> */}

              {(homeDataItem.total_plant > 1) && (
                <div className="prp-filter-wrapper">
                  <button
                    className={`prp-fltr-btn ${isFilterDdl == 1
                      ? "active"
                      : selectedPlant.length > 0
                        ? "default"
                        : ""
                      }`}
                    onClick={() => handleFilterDdl(1)}
                  >
                    <span>
                      {selectedPlant.length > 0
                        ? `Plant Selected (${selectedPlant.length})`
                        : "All Plants"}
                    </span>
                    <span className="down-arrow-icon down">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z"
                          fill="#455A64"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={`prp-filter-box ${isFilterDdl == 1 ? "active" : ""
                      }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="header-top">
                      <div
                        className={`prp-search-box ${filterSearchInput.length > 0 ? "active" : ""
                          }`}
                      >
                        <label>
                          <svg
                            className="srch-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.45341 0C2.89291 0 0 2.88889 0 6.44445C0 10 2.89291 12.8889 6.45341 12.8889C7.98887 12.8889 9.41307 12.3556 10.5035 11.4444L14.8651 15.8C15.1321 16.0667 15.5549 16.0667 15.7997 15.8C16.0668 15.5333 16.0668 15.1333 15.7997 14.8667L11.4381 10.5111C12.3505 9.4 12.8846 7.97778 12.8846 6.44445C12.9068 2.88889 10.0139 0 6.45341 0ZM6.45341 1.33333C9.27955 1.33333 11.5716 3.62222 11.5716 6.44445C11.5716 9.26667 9.27955 11.5556 6.45341 11.5556C3.62726 11.5556 1.33519 9.26667 1.33519 6.44445C1.33519 3.6 3.62726 1.33333 6.45341 1.33333Z"
                              fill="#A6A7AD"
                            />
                          </svg>

                          <input
                            type="text"
                            placeholder="Start typing to search"
                            value={filterSearchInput}
                            onChange={(e) => handleFilterSearch(e)}
                          />
                          <button
                            className="clear-all-btn"
                            onClick={clearSerachFilter}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy={8} r={8} fill="#7D868C" />
                              <path
                                d="M11.7172 5.08856C11.8734 4.93235 11.8734 4.67908 11.7172 4.52287L11.4771 4.28284C11.3209 4.12663 11.0677 4.12663 10.9114 4.28284L8.28284 6.91144C8.12663 7.06765 7.87337 7.06765 7.71716 6.91144L5.08856 4.28284C4.93235 4.12663 4.67908 4.12663 4.52287 4.28284L4.28284 4.52287C4.12663 4.67908 4.12663 4.93235 4.28284 5.08856L6.91144 7.71716C7.06765 7.87337 7.06765 8.12663 6.91144 8.28284L4.28284 10.9114C4.12663 11.0677 4.12663 11.3209 4.28284 11.4771L4.52287 11.7172C4.67908 11.8734 4.93235 11.8734 5.08856 11.7172L7.71716 9.08856C7.87337 8.93235 8.12663 8.93235 8.28284 9.08856L10.9114 11.7172C11.0677 11.8734 11.3209 11.8734 11.4771 11.7172L11.7172 11.4771C11.8734 11.3209 11.8734 11.0677 11.7172 10.9114L9.08856 8.28284C8.93235 8.12663 8.93235 7.87337 9.08856 7.71716L11.7172 5.08856Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="body-list">
                      {filterSearchInput.length < 1 ? (
                        <>
                          {/* selected plant list map */}
                          {allPlants.map((item, i) => (
                            <div key={i}>
                              {selectedPlant.length === allPlants.length &&
                                i === 0 &&
                                filterSearchInput.length < 1 && (
                                  <label className="chk-label">
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onClick={() => handleInput("all")}
                                    />
                                    <span className="svg-icon">
                                      <img
                                        src={
                                          require("../../assets/img/prepare-home/chk-white.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <span className="plant-chk-txt 1">
                                      All Plants
                                    </span>
                                  </label>
                                )}
                              {selectedPlant.indexOf(item.id) > -1 &&
                                selectedPlant.length > 0 &&
                                selectedPlant.length < allPlants.length &&
                                i === 0 && (
                                  <span className="plant-bold-txt">
                                    SELECTED PLANTS
                                  </span>
                                )}
                              {selectedPlant.indexOf(item.id) > -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    value={item}
                                    onClick={() => handleInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 2">
                                    {item.plant_name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* selected plant list map end*/}
                          {/* all plants list map and unselected list map*/}
                          {allPlants.map((item, i) => (
                            <div key={i}>
                              {selectedPlant.length == 0 && i === 0 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleInput("all")}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 3">
                                    All Plants
                                  </span>
                                </label>
                              )}

                              {selectedPlant.length > 0 &&
                                selectedPlant.length < allPlants.length &&
                                i == 0 && (
                                  <span className="plant-bold-txt">
                                    OTHER PLANTS
                                  </span>
                                )}

                              {selectedPlant.indexOf(item.id) === -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    id={`plantInput${i}`}
                                    onChange={() => handleInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 4">
                                    {item.plant_name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* all plants list map and unselected list map end*/}
                        </>
                      ) : (
                        <>
                          {filterPlant.map((item, i) => (
                            <label className="chk-label">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  selectedPlant.indexOf(item.id) > -1
                                }
                                value={item.id}
                                onChange={() => handleInput(item.id)}
                              />
                              <span className="svg-icon">
                                <img
                                  src={
                                    require("../../assets/img/prepare-home/chk-white.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="plant-chk-txt 5">
                                {item.plant_name}
                              </span>
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                    {/* body-list end */}
                    <div className="footer-bottom">
                      <button className="btn-ft clear" onClick={clearAllFilter}>
                        Clear all
                      </button>
                      <button className="btn-ft apply" onClick={applyFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* prp-filter-box end */}
                </div>
              )}
            </div>
          </h1>
          {/* First Subcategory will be Plants wrapper */}
          <div className="plants-wrapper">
            {homeDataItem.total_plant > 0 && (
              <div className="prp-plant-div">
                {filterAllPlants.map((plant, repIndex) => (
                  <>
                    {/* Plase add class {className="prepare-row highlight-new-plant"} to highlight new plant CDP-444 */}
                    <div className={`prepare-row ${plant.is_new == 1 ? '' : ''}`}>
                      {/* I have added one class named 'plant-name-row' */}
                      <div className="prepare-full-col plant-name-row">
                        <div className="plant-name-div">
                          <span className="plant-img">
                            <img
                              src={`${apiEndpoints.baseUrl}/assets/img/country/${plant.slug}.svg`}
                              alt=""
                            />
                          </span>
                          <span className="plant-txt">
                            <span>{plant.plant_name}</span>
                            {groupid == 2 && (
                              <i
                                className="renameIcon"
                                dangerouslySetInnerHTML={{ __html: plantEditIcon }}
                                data-title="Rename"
                                onClick={() => handleShowEditModal(plant.id, plant.plant_name)}
                              ></i>
                            )}


                          </span>
                        </div>
                        {/* Collapse button CDP-444 */}
                        <button className={`collapse-btn ${collapseArray.includes(plant.id) ? 'active' : ''}`} onClick={() => handleCollapse(plant.id)}>
                          <i className='angle-icon' dangerouslySetInnerHTML={{ __html: collapseArrow }}></i>
                        </button>
                      </div>
                      {/* Collapse content CDP-444 */}
                      <Collapse in={collapseArray.includes(plant.id)}>
                        <div className="prepare-full-col">
                          <div className="prp-inner-card-row">
                            {plant.is_completed == 0 && (
                              <div
                                className={`prepare-col plants ${plant.mat == 0 && "disabled"
                                  }`}
                              >
                                <PlantCard
                                  cardType="PREPARE"
                                  title="Assessment"
                                  status={
                                    (plant.combo_id_get && !plant.combo_id_get?.includes('2001')) ? 'upgrade'
                                      :
                                      plant.is_datasubmitted == 0
                                        ? plant.maturity_total_progress == 0
                                          ? 0
                                          : 1
                                        : `${plant.maturity_total_progress}`
                                  }
                                  getaccessLink={`/assessment-tour`}
                                  getAcessButton={plant.combo_id_get?.includes('2001') ? false :
                                    true}
                                  progress={plant.maturity_total_progress}
                                  type="dma"
                                  colorClass="blue-bg"
                                  gourl={
                                    plant.mat == 0
                                      ? ""
                                      : `assessment/${plant.plant_slug}`
                                  }
                                  otherFunction={
                                    plant.mat == 0 ? HandlePopupm : HandlePopuNo
                                  }
                                  plantId={plant.id}
                                  upgradeSection='assessment'
                                  plantName={plant.plant_name}
                                  comboIdget={plant.combo_id_get}
                                />
                              </div>
                            )}

                            {plant.is_completed == 1 && (
                              <>
                                {/* Siri card Col start */}
                                <div className={`prepare-col plants disabled`} onClick={() => handleSiriPopup(plant.is_completed != 1)}>
                                  <PlantCard
                                    cardType="PREPARE"
                                    title="Assessment"
                                    callibratorButton={true}
                                    callibratorLink={`/assessment/${plant.plant_slug}`}
                                    status={plant.is_siricompleted}
                                    type={'siri'}
                                    colorClass="blue-bg"
                                    isRoadmapDiveTxt={"Assessment"}
                                    plantId={plant.id}
                                    upgradeSection='strategy'
                                    plantName={plant.plant_name}
                                    comboIdget={plant.combo_id_get}
                                    downloadReportUrl = {plant.siri_files?`${homeDataItem.base_url}assets/siri-files/${plant.siri_files}`:false}
                                  />
                                </div>
                                {/* Siri card Col end */}
                              </>

                            )}
                            {/* Loss analysis cards start */}
                            {(1 == 1) && (
                              <>
                                {(uid != 591 && uid != 710 && uid != 756) && uid != 716 && groupid != 14 ?
                                  <div
                                    className={`prepare-col plants ${plant.loss == 0 && "disabled"
                                      }`}
                                  >
                                    <PlantCard
                                      cardType="PREPARE"
                                      title="IR4.0 Loss Analysis"
                                      status='upgrade'
                                      progress={0}
                                      colorClass="blue-bg blue-corner"
                                      type="loss"
                                      plantId={plant.id}
                                      upgradeSection='loss'
                                      plantName={plant.plant_name}
                                      comboIdget={plant.combo_id_get}
                                      otherFunction={() => handleLossTraining()}
                                      // getaccessLink={`/assessment-tour`}
                                      getAcessButton={true}
                                    />

                                  </div>
                                  :
                                  <>
                                    {plant.has_businesscase == 0 && (
                                      <div
                                        className={`prepare-col plants ${plant.loss == 0 && "disabled"
                                          }`}
                                      >
                                        <PlantCard
                                          cardType="PREPARE"
                                          title="IR4.0 Loss Analysis"
                                          status={
                                            plant.is_losssdatasubmitted == 0
                                              ? plant.loss_total_progress == 0
                                                ? 0
                                                : 1
                                              : `${plant.loss_total_progress}`
                                          }
                                          progress={plant.loss_total_progress}
                                          colorClass="blue-bg"
                                          type="loss"
                                          gourl={
                                            plant.loss == 0 ? "" : `${plant.bclines}`
                                          }
                                          otherFunction={
                                            plant.loss == 0 ? HandlePopupl : HandlePopuNo
                                          }
                                        />
                                      </div>
                                    )}

                                    {plant.has_businesscase == 1 && (
                                      <div
                                        className={`prepare-col plants ${homeDataItem.hideuser == 1 &&
                                          plant.mat == 0 &&
                                          plant.loss == 0 &&
                                          "disabled"
                                          }`}
                                      >
                                        <PlantCard
                                          cardType="PREPARE"
                                          title="IR4.0 Loss Analysis"
                                          status={"results-ready"}
                                          callibratorButton={true}
                                          callibratorLink={`/loss-analysis-admin/section/${plant.plant_slug}`}
                                          colorClass="blue-bg"
                                          type="loss"
                                          isRoadmapResult="Identified Losses"
                                          savings={plant.identified_losses}
                                          gourl={
                                            homeDataItem.hideuser == 1 &&
                                              plant.mat == 0 &&
                                              plant.loss == 0
                                              ? ""
                                              : `lossresults/${plant.plant_slug}/0`
                                          }
                                          otherFunction={
                                            homeDataItem.hideuser == 1 &&
                                              plant.mat == 0 &&
                                              plant.loss == 0
                                              ? HandlePopupl
                                              : HandlePopuNo
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                }
                              </>
                            )}
                            {/* Loss analysis cards end 
                            {(uid != 591 && uid != 710 && uid != 756) && uid != 716 && groupid != 14 ?
                              <div className={`prepare-col plants`}>
                                <PlantCard
                                  cardType="DEFINE"
                                  title="IR4.0 Strategy"
                                  status={'upgrade'}
                                  isRoadmapDiveTxt={"$-- Annual Savings"}
                                  colorClass="orange-bg orange-corner"
                                  plantId={plant.id}
                                  upgradeSection='strategy'
                                  plantName={plant.plant_name}
                                  comboIdget={plant.combo_id_get}
                                  otherFunction={() => handleStrategytraining()}
                                  getAcessButton={true}
                                />
                              </div>
                              : plant.has_businesscase == 1 &&
                              <div className={`prepare-col plants`}>
                                <PlantCard
                                  cardType="DEFINE"
                                  title="IR4.0 Strategy"
                                  status={plant.is_roadmapcompleted == 1 ? 100 : plant.is_roadmapcompleted == 2 ? 55 : 0}
                                  colorClass="orange-bg"
                                  gourl={plant.is_roadmapcompleted == 1 ? `roadmap-result/${plant.plant_slug}/${plant.sid}` : `/roadmap-complete/step1/${plant.plant_slug}/${plant.sid}`}
                                  callibratorButton={plant.is_roadmapcompleted == 1 ? true : false}
                                  callibratorLink={`/roadmap-complete/step1/${plant.plant_slug}/${plant.sid}`}
                                />
                              </div>
                            }*/}
                            {(uid == 591 || uid == 710 || uid == 756 || uid == 716) ? 
                            <>
                            {plant.has_businesscase == 1 &&
                              plant.is_completed == 1 &&
                              plant.roadmap_results == 0 && groupid != 14 && (
                                <div className={`prepare-col plants `}>
                                  <PlantCard
                                    cardType="DEFINE"
                                    title="IR4.0 Strategy"
                                    status={`${plant.sc_text}`}
                                    colorClass="orange-bg"
                                    gourl={
                                      homeDataItem.hideuser == 1 &&
                                        plant.mat == 0 &&
                                        plant.loss == 0
                                        ? ""
                                        : `roadmapconfigurator/${crypter(
                                          plant.plant_slug
                                        )}`
                                    }
                                  />
                                </div>
                              )}

                            {plant.has_businesscase == 1 &&
                              plant.is_completed == 1 &&
                              plant.roadmap_results == 1 && groupid != 14 && (
                                <div className={`prepare-col plants`}>
                                  <PlantCard
                                    cardType="DEFINE"
                                    title="IR4.0 Strategy Results"
                                    status={"results-ready"}
                                    callibratorButton={true}
                                    callibratorLink={`/roadmapconfigurator/${crypter(
                                      plant.plant_slug
                                    )}`}
                                    colorClass="orange-bg"
                                    isRoadmapResult="Annual Savings"
                                    savings={plant.annual_savings}
                                    directurl={
                                      homeDataItem.hideuser == 1 &&
                                        plant.mat == 0 &&
                                        plant.loss == 0
                                        ? ""
                                        : `${apiEndpoints.baseUrl}/automation/sceneriobc/${plant.plant_slug}/${plant.sc_id}`
                                    }
                                  />
                                </div>
                              )}                      
                            </>: <div className={`prepare-col plants`}>
                                <PlantCard
                                  cardType="DEFINE"
                                  title="IR4.0 Strategy"
                                  status={'upgrade'}
                                  isRoadmapDiveTxt={"$-- Annual Savings"}
                                  colorClass="orange-bg orange-corner"
                                  plantId={plant.id}
                                  upgradeSection='strategy'
                                  plantName={plant.plant_name}
                                  comboIdget={plant.combo_id_get}
                                  otherFunction={() => handleStrategytraining()}
                                  getAcessButton={true}
                                />
                              </div>  
                             }  
                            {plant.is_siricompleted == 'validated' && plant.is_roadmapcompleted==1 && groupid != 14 && (
                             <div className={`prepare-col plants`}>
                                <PlantCard
                                  cardType="DEFINE"
                                  title="Technology Roadmap"
                                  isRoadmapDiveTxt={"IR4.0 Strategy"}
                                  status={100}
                                  colorClass="orange-bg"
                                  gourl={`roadmap-result/${plant.plant_slug}/${plant?.sid2}`}
                                  downloadPdfBtn = {true}
                                  downloadPdfUrl = {`${homeDataItem.base_url}certificate/downloadResults/${plant.plant_slug}/${plant?.sid2}/${plant.id}`}
                                />
                              </div>    
                                )}

                            <div className="border-bottom-1"></div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </>
                ))}
                {/* prepare-row 1 end */}
              </div>
            )}
            {/* No Plant Div */}
            {(filterAllPlants.length == 0 || homeDataItem.total_plant == 0) && (
              <div className="no-plant">
                <div className="no-plant-img" dangerouslySetInnerHTML={{ __html: noPlantSvg }}></div>
                <h2>No plant added</h2>
                {groupid != 14 && (
                  <p>Please add plant to get access to Platform Tools</p>
                )}
                {groupid != 14 && (

                  <>
                    {upgradeStatus === 0 &&
                      <div>
                        <button className="add-plant" onClick={() => setAddPlantMsgPopup(true)}>Add Plant</button>
                      </div>
                    }
                    {upgradeStatus === 1 &&
                      <div>
                        <button className="add-plant" onClick={handleAddPlant}>Add Plant</button>
                      </div>
                    }
                  </>
                )}
              </div>
            )}
          </div>
          {/* Second Subcategory will be Distribution Center wrapper */}

        </div>
        {activeSlide && isHomeTour == 0 && (
          <HomePageWalkthroughView
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            closeFunction={HandleHomeTourWalhthrough}
            {...props}
          />
        )}

        {/* {showPlatformTrainingBtn && isPlatformTrainingBtn == 0 && (
          <button className="platform-button" onClick={RedirectToResourcePage}>
            <span className="platform-text">Platform Trainings</span>
            <i
              className="arrow-icon"
              dangerouslySetInnerHTML={{ __html: arrowSvg }}
            ></i>
          </button>
        )} */}
      </div>
      {/* MyTaskFilter */}
      <MyTaskFilter allPlants={homeDataItem.all_plants_without_filter} taskdata={taskdata} onFilterPlantsTask={props.onFilterPlantsTask} {...props} />
      {/* business case popup component */}
      <BussinessCasePopup isPopup={isPopup} setIsPopup={setIsPopup} />
      {/* MatCasePopup component */}
      <MatCasePopup ismPopup={ismPopup} setIsmPopup={setIsmPopup} />
      {/* Loss case popup component */}
      <LossCasePopup islPopup={islPopup} setIslPopup={setIslPopup} />

      {/* delete Plant popup */}
      <div className={`delete-plant-popup ${deletePopupId && "active"}`}>
        <div className="delete-popup-card">
          <h2>Are you sure, you would like delete this Plant?</h2>
          <p>
            All the progress made will be lost permanently and can not be
            retrieved.
          </p>
          <div className="delete-popup-button-wrapper">
            <button
              className="btn-cancel"
              onClick={() => handleShowDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn-yes-del" onClick={deletePlantFunction}>
              Yes, delete
            </button>
          </div>
        </div>
      </div>
      {/* edit popup*/}
      <div className={`edit-plant-popup ${editPopupId && "active"}`}>
        <div className="edit-plant-card">
          <div className="edit-plant-popup-header">
            <h2>Rename Plant</h2>
            <button className="close_btn" onClick={() => { closeEditPopup() }}>
              <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
            </button>
          </div>
          {/* header end */}

          <div className="edit-plant-popup-body">
            <label className="edit-plant-input-wrapper">
              <span className="input-label">Plant Name</span>
              <input value={editPopupInput} placeholder="Plant Name"
                onChange={(e) => setEditPopupInput(e.target.value)}
              />
            </label>
            <p className={`plant-name-error ${(!validateName(editPopupInput) && editPopupInput.length > 0) && 'active'}`}>
              The plant name does not allow multiple consecutive spaces and can not start with a space.
            </p>
          </div>
          {/* body end */}
          <div className="edit-plant-popup-footer">
            <button className={`btn_save ${validateName(editPopupInput) && 'active'}`} onClick={renamePlantNameFunction}>
              Save Changes
            </button>
          </div>
          {/*  footer end */}
        </div>
        {/*  card end */}
      </div>
      {/* edit popup edit*/}
      {/* Notification Popup */}
      {/* Popup when assessment and loss data are not submitted */}
      <StartegyPopup isStrPopup={isStrComPopup} setIsStrPopup={setIsStrComPopup} title={`Please submit your IR4.0 Technology Assessment & IR4.0 Loss Analysis to access IR 4.0 Strategy tool`} />
      {/* Popup when loss data is not submitted */}
      <StartegyPopup isStrPopup={isStrLossPopup} setIsStrPopup={setIsStrLossPopup} title={`Please submit your IR4.0 Loss Analysis to access IR 4.0 Strategy tool`} />
      {/* Popup when assessment is not submitted */}
      <StartegyPopup isStrPopup={isStrAsmtPopup} setIsStrPopup={setIsStrAsmtPopup} title={`Please submit your IR4.0 Technology Assessment input to access IR 4.0 Strategy tool`} />
      <StartegyPopup isStrPopup={isStrSiriPopup} setIsStrPopup={setIsStrSiriPopup} title={`Please submit your IR4.0 Technology Assessment input to access Assessment.`} heading={`Assessment is not available`} />
      <StartegyPopup isStrPopup={isStrSiriComPopup} setIsStrPopup={setIsStrSiriComPopup} title={`Your Smart Manufacturing Assessment data have been sent to the Assessor to plan the visit to your factory`} />
      {/* Upgrade Popup */}

      {showComponent && <AddPlantPopViewCtrl showAddPlantPopup={showComponent} setShowAddPlantPopup={setShowComponent} />}
      {/* Home Upgrade Add Plant Popup */}
      {addPlantMsgPopup && <UpgradeAddPopup openUpgradePopup={addPlantMsgPopup} setOpenUpgradePopup={setAddPlantMsgPopup} />}
      {/* Unitar Popup */}
      <UnitarResourcePopup />
      <CongratsPopup open={congratsPopOpen} setOpen={setCongratsPopOpen} setIsFreeCourse={setIsFreeCourse} history={history} />
    </>

  );
};

const TraingingCard = (props) => {
  const { title, headerImg, url, status, colorClass, otherFunction } = props;
  const callOtherFunction = () => {
    if (otherFunction) {
      otherFunction();
    }
  };
  return (
    <>
      <ConditionalTag url={url} otherFunction={callOtherFunction}>
        <div className={`prp-img-div ${colorClass}`}>
          <img src={headerImg} className="icon-img" alt="Img" />
          {status == 100 ? (
            <span className="status completed">COMPLETED</span>
          ) : status > 0 && status < 100 ? (
            <span className="status inprogress">IN PROGRESS</span>
          ) : status == 0 ? (
            <span className="status todo">To do</span>
          ) : (
            ""
          )}
        </div>
        <div className="prp-text-div">
          {title}
          {/* max-character 49*/}
        </div>
      </ConditionalTag>
    </>
  );
};
//plant card component
const PlantCard = (props) => {
  const {
    title,
    url,
    directurl,
    status,
    customStatus,
    colorClass,
    cardType,
    type,
    progress,
    lossReduction,
    isRoadmapDiveTxt,
    isRoadmapResult,
    savings,
    otherFunction,
    callibratorButton,
    getAcessButton,
    callibratorLink,
    downloadPdfUrl,
    getaccessLink,
    downloadPdfBtn,
    plantId,
    upgradeSection,
    plantName,
    comboIdget,
    downloadReportUrl,
  } = props;
  const callOtherFunction = () => {
    if (otherFunction) {
      otherFunction();
    }
  };
  let gid = localStorage.getItem("group_id");
  return (
    <>
      <ConditionalTag
        url={props.gourl}
        directurl={directurl}
        otherFunction={callOtherFunction}
      >
        <div className={`prp-img-div ${colorClass}`}>
          <div className="prp-card-img">
            <p>{cardType}</p>
            <img
              src={
                require(`../../assets/img/prepare-home/${type == "siri"
                  ? `siri-assessment.svg`
                  : type == "dma"
                    ? `assessment-icon.svg`
                    : type == "loss"
                      ? "loss-icon.svg"
                      : type == "execute"
                        ? "prepare-execute.svg"
                        : type == "techAdop"
                          ? "tech-adoption-icon.svg"
                          : "business-icon.svg"
                  }`).default
              }
              alt="Img"
            />
          </div>
          <div className="prp-card-porogress-div">
            {/* if roadmap deep dive text  in props*/}
            {isRoadmapDiveTxt && (
              <span className="txt-normal">{isRoadmapDiveTxt}</span>
            )}

            {/* if progress is 0 or greater than 0 in props  */}
            {progress > -1 && progress !== "undefined" ? (
              <span className="txt-normal">Progress</span>
            ) : (
              ""
            )}
            {progress > -1 && <span className="progress-num">{progress}%</span>}

            {/* loss reduction */}
            {/* {lossReduction > -1 && lossReduction !== "undefined" ? (
              <span className="txt-normal">Loss Reduction</span>
            ) : (
              ""
            )}
            {lossReduction > -1 && (
              <span className="progress-num">{lossReduction}%</span>
            )} */}
            {/* loss reduction end */}

            {isRoadmapResult && (
              <>
                <span className="progress-num result">{savings}</span>
                <span className="txt-normal">{isRoadmapResult}</span>
              </>
            )}
          </div>
          {/* Chnages related to new update in card */}
          {status == 'upgrade' ?
            // (<span className="status upgrade">UPGRADE</span>)
            (
              <div className="learn-more-text-wrap">
                <i dangerouslySetInnerHTML={{ __html: playSvg }}></i>
                <span>Learn More with our Product training & Case Studies</span>
              </div>
            )
            :
            status == 100 ? (
              <div className="status-wrap">
                <span className="status completed">COMPLETED</span>
                {
                  downloadPdfBtn ? 
                    <a href={downloadPdfUrl} download onClick={(e)=>{e.stopPropagation();}}>
                      <i dangerouslySetInnerHTML={{__html:downloadHoverBtn}}></i>    
                      <span className="download-tooltip">Download Results</span>
                    </a>
                    :
                  ''
                }
              </div>
            ) : status > 0 && status < 100 ? (
              <span className="status inprogress">IN PROGRESS</span>
            ) : status == 0 ? (
              <span className="status todo">To do</span>
            ) : status == "not-started" ? (
              <span className="status not-started">NOT STARTED</span>
            ) : status == "results-ready" ? (
              <span className="status results-ready">RESULTS READY</span>
            ) : status == "review-ready" ? (
              <span className="status results-ready">REVIEW READY</span>
            ) : status == "validated" ? (
              <span className="status results-ready">Validated</span>
            ) : (
              ""
            )}
          {/* custom status */}
          {customStatus ? (
            <span className="status completed">{customStatus}</span>
          ) : (
            ""
          )}
        </div>
        <div className="prp-text-div">
          {title}
          {callibratorButton && gid != 14 && (
            <Link
              to={callibratorLink}
              className="recalibrate-btn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="re-text">RE-CALIBRATE</span>{" "}
              <span
                className="re-img"
                dangerouslySetInnerHTML={{ __html: calibratorSvg }}
              ></span>
            </Link>
          )}
          {/* max-character 49*/}
          {downloadReportUrl &&
            <a className="recalibrate-btn download-report-btn" href={downloadReportUrl} download="myw3schoolsimage.jpg" target="_blank" onClick={(e)=>{e.stopPropagation();}}>
              <span className="re-text">DOWNLOAD SIRI REPORT</span>{" "}
              <span className="re-img" dangerouslySetInnerHTML={{ __html: downloadIcon }}></span>
            </a>
          }
          {getAcessButton && gid != 14 && (
            <Link
              to={getaccessLink}
              className="getaccess-btn"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                localStorage.setItem(`upgradeSection`, upgradeSection);
                // Condition for Upgrade Input Check
                localStorage.setItem(`plantName`, plantName);
                localStorage.setItem(`plantId`, plantId);
                localStorage.setItem(`comboIdget`, JSON.stringify(comboIdget));
                // document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
                document.querySelector('.header-icons-li.upgrade .contact-btn')?.click();
              }}
            >
              <span className="re-text">GET ACCESS</span>{" "}
              <span
                className="re-img"
                dangerouslySetInnerHTML={{ __html: upgradeSvg }}
              ></span>
            </Link>
          )}
        </div>
      </ConditionalTag>
    </>
  );
};
//conditional tag component
const ConditionalTag = (props) => {
  const { url, otherFunction, directurl } = props;
  return (
    <>
      {directurl ? (
        <a href={directurl} className="prp-card">
          {props.children}
        </a>
      ) : url ? (
        <Link to={url} className="prp-card">
          {props.children}
        </Link>
      ) : (
        <div className="prp-card" onClick={otherFunction}>
          {props.children}
        </div>
      )}
    </>
  );
};
//Popup components
const BussinessCasePopup = (props) => {
  const { isPopup, setIsPopup } = props;
  //function to close
  const handleClose = () => {
    setIsPopup(false);
  };
  return (
    <div className={`prp-businesscase-popup-wrap ${isPopup ? "active" : ""}`}>
      {/* wrapper has
          close-btn
          pop-body-wrap
      */}
      <div className="prp-businesscase-popup">
        <button className="close-btn" onClick={handleClose}>
          <img
            src={
              require("../../assets/img/prepare-home/close-popup.svg").default
            }
            alt="Close Button"
          />
        </button>
        {/* pop-body-wrap has three sections
            img-box
            heading
            text 
        */}
        <div className="pop-body-wrap">
          <div className="img-box">
            <img
              src={
                require("../../assets/img/prepare-home/businesscase-pop.svg")
                  .default
              }
              alt="Businesscase"
            />
          </div>
          <h1 className="heading">
            Your Business case results are not ready yet.
          </h1>
          <p className="text">
            We will notify you when they will be available.
          </p>
          <button className="ok-btn" onClick={handleClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
const MatCasePopup = (props) => {
  const { ismPopup, setIsmPopup } = props;
  const handleClosem = () => {
    setIsmPopup(false);
  };
  return (
    <div className={`prp-businesscase-popup-wrap ${ismPopup ? "active" : ""}`}>
      {/* wrapper has
          close-btn
          pop-body-wrap
      */}
      <div className="prp-businesscase-popup">
        <button className="close-btn" onClick={handleClosem}>
          <img
            src={
              require("../../assets/img/prepare-home/close-popup.svg").default
            }
            alt="Close Button"
          />
        </button>
        {/* pop-body-wrap has three sections
            img-box
            heading
            text 
        */}
        <div className="pop-body-wrap">
          <div className="img-box">
            <img
              src={
                require("../../assets/img/prepare-home/businesscase-pop.svg")
                  .default
              }
              alt="Businesscase"
            />
          </div>
          <h1 className="heading">
            Your Maturity Assessment results are not ready yet.
          </h1>
          <p className="text">
            We will notify you when they will be available.
          </p>
          <button className="ok-btn" onClick={handleClosem}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
const LossCasePopup = (props) => {
  const { islPopup, setIslPopup } = props;
  const handleClosel = () => {
    setIslPopup(false);
  };
  return (
    <div className={`prp-businesscase-popup-wrap ${islPopup ? "active" : ""}`}>
      {/* wrapper has
          close-btn
          pop-body-wrap
      */}
      <div className="prp-businesscase-popup">
        {/* this section has image */}
        <button className="close-btn" onClick={handleClosel}>
          <img
            src={
              require("../../assets/img/prepare-home/close-popup.svg").default
            }
            alt="Close Button"
          />
        </button>
        {/* pop-body-wrap has three sections
            img-box
            heading
            text 
        */}
        <div className="pop-body-wrap">
          <div className="img-box">
            <img
              src={
                require("../../assets/img/prepare-home/businesscase-pop.svg")
                  .default
              }
              alt="Businesscase"
            />
          </div>
          <h1 className="heading">
            Your Loss Analysis results are not ready yet.
          </h1>
          <p className="text">
            We will notify you when they will be available.
          </p>
          <button className="ok-btn" onClick={handleClosel}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
// Disabled Roadmap notification popup
const StartegyPopup = (props) => {
  const { isStrPopup, setIsStrPopup, title } = props;
  const handleCloseStr = () => {
    setIsStrPopup(false);
  };
  return (
    <>
      <div className={`strategy-popup-wrapper ${isStrPopup ? "active" : ""}`}>
        <div className="startegy-pop-card">
          {/* <h2>{props.heading || `IR4.0 Strategy tool not available`}</h2> */}
          <p>{props.title}</p>
          <button onClick={handleCloseStr}>Okay</button>
        </div>
      </div>
    </>
  );
};
// Upgrade Add Plant popup
const UpgradeAddPopup = (props) => {
  const { openUpgradePopup, setOpenUpgradePopup } = props;
  const handleCloseUpgrade = () => {
    setOpenUpgradePopup(false);
  }
  const handleUpgradePopupClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
  }
  return (
    <>
      <div className="upgrade-popup-wrapper">
        <div className="upgrade-pop-card">
          <div className="upgrade-plant-head">
            <span className="upgrade-home-text">Add Plant</span>
            <button className="upgrade-close-btn" onClick={() => handleCloseUpgrade()}>
              <i dangerouslySetInnerHTML={{ __html: upgradeCloseSvg }}></i>
            </button>
          </div>
          <div className="upgrade-pop-body">
            <p>You don’t have any credits to add plant.<br /> Explore our plans and upgrade now</p>
            <button className="upgrade-now" onClick={(e) => { handleUpgradePopupClick(e); handleCloseUpgrade(e); }}>Upgrade Now</button>
          </div>
        </div>
      </div>
    </>
  );
}

// Unitar Resource Popup
const UnitarResourcePopup = (props) => {
  const [unitarVideoPopup, setUnitarVideoPopup] = useState(false);
  const plyrRef = React.useRef();
  const [isShareAutoPlay, setIsShareAutoPlay] = useState(true);
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const handleMinIcon = () => {
    setIsActive(!isActive);
  };


  return (
    <>
      <div className="unitar-resource-main-wrapper">
        <div className={`unitar-resource-pop-wrapper ${isActive ? 'active-min-class' : ''}`}>
          <div className="unitar-resource-pop-card">
            <div className="unitar-resource-pop-header">
              <div className="unitar-pop-head-left">
                <div className="unitar-text">
                  <span className="bold-text">Industry 4.0 For Sustainable Development Webinar</span>
                </div>
              </div>
              <button className="unitar-min-btn" onClick={() => handleMinIcon()}>
                <i dangerouslySetInnerHTML={{ __html: minBtnSvg }}></i>
              </button>
            </div>
            <div className="unitar-resource-pop-body">
              <div className="image-container" onClick={() => setUnitarVideoPopup(true)}>
                <i className="banner-img" dangerouslySetInnerHTML={{ __html: uniTarBannerWrapperSvg }}></i>
              </div>
              <p>
                Watch the launch webinar of the pioneering learning
                collaboration for advancing the Sustainable
                Development Goals with IR4.0 education
              </p>
            </div>
            <div className="unitar-resource-pop-footer">
              <Link to="/learning-center" className="unitar-view-link">View Webcast Resources</Link>
            </div>
          </div>
        </div>
        <button className={`unitar-max-btn ${isActive ? 'active-max-class' : ''}`} onClick={() => handleMinIcon()}>
          Industry 4.0 For Sustainable Development Webinar
        </button>
      </div>
      {/* Unitar Resource Video Popup */}

      {
        unitarVideoPopup &&
        <div className="unitar-video-pop-wrapper">
          <div className="unitar-video-pop-wrap">
            <div className="unitar-video-pop-head">
              <span className="unitar-video-title">Industry 4.0 For Sustainable Development Webinar </span>
              <div className="unitar-right-btn">
                {/* <button className="unitar-bound-btn">
                  <i dangerouslySetInnerHTML={{__html: unitarBoundSvg}}></i>
                </button> */}
                <button className="unitar-close-btn" onClick={() => setUnitarVideoPopup(false)}>
                  <i dangerouslySetInnerHTML={{ __html: unitarBannerCloseSvg }}></i>
                </button>
              </div>
            </div>
            <div className="unitar-video-pop-body">
              <p>Watch the launch webinar of the pioneering learning collaboration for advancing the Sustainable Development Goals with IR4.0 education</p>
              <div className="video-pop">
                <Plyr
                  ref={plyrRef}
                  source={{
                    type: "video",
                    sources: [
                      {
                        src: require(`../../assets/video/Industry-4.0-Sustainable-Development-Webinar.mp4`).default,
                        type: "video/mp4",
                      },
                    ],
                    autoplay: isShareAutoPlay,
                  }}
                  options={{
                    controlshidden: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

      }
    </>
  )
}

// Congratulations Popup
const CongratsPopup = (props) => {
  const { open, setOpen, setIsFreeCourse, history } = props;

  const handleClose = () => {
    setOpen(false);
    setIsFreeCourse(1);
  }

  const goTrainingFunction = () => {

    history.push(`/training-courses/introduction-to-industry-40/introduction`);

  }

  return (
    <>
      {open &&
        <div className="congrats-popup-wrapper">
          <div className="congrats-popup">
            <div className="text-wrap">
              <h3>Congratulations!</h3>
              <p>You have unlocked 1 hour <span>FREE</span> Introduction to Industry 4.0 Training Course</p>
            </div>
            <div className="footer-wrap">
              <button className="close-btn btn" onClick={() => handleClose()}>Close</button>
              <button className="training-btn btn" onClick={goTrainingFunction}>Go to Training Course</button>
            </div>
          </div>
        </div>
      }
    </>
  )
}
