/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * LtExportView ===> custom view component

**/
import React from "react";
import {useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LtExportView } from "./lt-export-view";
//LtExportController start
export const LtExportController = (props) => {
  //defining variables here
  const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
  const slug = getLastItem(window.location.pathname);
  const [results, setResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
 
  useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=export&slug=${slug}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResults(data);
		};
		search();
	}, []);

  return (
    <>
      {/* custom view component */}
      <LtExportView slug={slug}   sectionData={results} {...props} />
    </>
  );
};
