import axios from "axios";
import { apiEndpoints } from './api-endpoints'

//const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';

const getTokenFromStorage = () => {
  const data = JSON.parse(localStorage.getItem("access_token"));
  return data;
}

var api = axios.create({
  baseURL: apiEndpoints.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    //'Authorization': 'Bearer ' + token
  }
});

async function get(endPoint, authRequired, params) {
  let headers
  if (authRequired) {
    headers = {
      headers: {
        'Authorization': 'Bearer ' + getTokenFromStorage()
      },
      params: params
    }
  }
  return api.get(endPoint, headers)
}

async function post(endPoint, data, authRequired = true) {
  let headers
  if (authRequired) {
    headers = {
      headers: {
        'Authorization': 'Bearer ' + getTokenFromStorage()
      }
    }
  }
  return api.post(endPoint, data, headers)
}

async function put(endPoint, data, authRequired = true) {
  let headers
  if (authRequired) {
    headers = {
      headers: {
        'Authorization': 'Bearer ' + getTokenFromStorage()
      }
    }
  }
  return api.put(endPoint, data, headers)
}

async function remove(endPoint, authRequired = true) {
  let headers
  if (authRequired) {
    headers = {
      headers: {
        'Authorization': 'Bearer ' + getTokenFromStorage()
      }
    }
  }
  return api.delete(endPoint, headers)
}

api.interceptors.response.use(function (response) {
  return response;
},
  async err => {
  const error = err.response;
  const originalConfig = err.config;
  
  // if error is 401 
  if (error && error?.status === 401) {
    try{
      if(originalConfig.url === '/auth/verify') throw 'refresh token expired'
      const userData = JSON.parse(localStorage.getItem("userData"))
      if (!userData.uid){ throw 'no uid';}
      const refreshToken = JSON.parse(localStorage.getItem("refresh_token"))
      if(!refreshToken){ throw 'no refresh token';}

      const headers = {
        headers: {
          'Authorization': 'Bearer ' + refreshToken
        }
      }
     
      const response = await api.post("/auth/verify", {
        uid : userData.uid
      },headers);
      const {access_token, refresh_token } = response.data;

      localStorage.setItem('access_token', JSON.stringify(access_token));
      localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
      originalConfig.headers.Authorization = `Bearer ${access_token}`
      return api(originalConfig)

    }catch(err){
      console.log(err)
      onLogoutHandler();
      window.location.href='/sign-in';
    }


  }else{
    return Promise.reject(err);
  }
});

const onLogoutHandler = () => {
  localStorage.clear();
};


export default {
  get,
  post,
  put,
  remove
};