/** imported components
 
  * $ from jquery  ===> component from jquery to use jquery in the page.
  * useEffect 	   ===> React hooks (useState is a way to get/set value)

**/

import React from "react";
import $ from "jquery";
import { useEffect } from "react";

// SingleSelect component starts here
export const SingleSelect = (props) => {
	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}
	const quesDataItem = props.quesData[0];
	// console.log(quesDataItem);
	const changeValue = (type) => {
		// if (type == 1) {
		// 	$(".chksss[data-type='0']").prop("checked", false);
		// } else {
		// 	$(".chksss[data-type='1']").prop("checked", false);
		// }
	};
	const {qlength, setQlenth} = props;
	const {activeRightTab, setActiveRightTab} = props;
	const checklist = quesDataItem.answers.map((item, index) => {
		var active = 0;
		if (quesDataItem.savedans.length) {
			var ansarr = quesDataItem.savedans.split(",");
			if (ansarr.indexOf(item.id) != -1) {
				active = 1;
			}
		}
		return (
			/*  Here is anwer wrapper card which has been divided into two parts. Right part will come condtionally. 
				Left part having the options for selection.
				Right part is having the info image*/
			<div className="choose-one-card" key={index}>
				<input
					className="chk-input"
					name="singlebox"
					id={`chk${item.id}`}
					defaultChecked={active ? "checked" : ""}
					defaultValue={item.id}
					data-type={item.is_none}
					type="radio"
					onClick={() => changeValue(item.is_none)}
				/>
				<label htmlFor={`chk${item.id}`} className="card-left" style={{pointerEvents:quesDataItem.is_datasubmitted==1?'none':''}}>
					<p>{item.option_name}</p>
				</label>
				{item.answer_description && (
					<div className={props.clickedCardNo === index ? props.activeRightTab == 0 ? "card-right active" :"card-right" : "card-right"}>
						<img
							className="inactive"
							src={require("../../assets/img/multi-choice/non-active-info.svg").default}
							alt="true"
							onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)}
						/>
						<img
							src={require("../../assets/img/multi-choice/active-info.svg").default}
							onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)}
							className="active"
							alt="true"
						/>
					</div>
				)}
			</div>
		);
	});
	useEffect(() => {
		let sch = document.querySelector('.single-chk-ans-wrap')?.scrollHeight;
		let cch = document.querySelector('.single-chk-ans-wrap')?.clientHeight;
if(sch>cch){
	// console.log("t")
		$('.single-chk-ans-wrap').scroll(function (event) {
            var scroll = $('.single-chk-ans-wrap').scrollTop();
			// console.log(scroll?.scrollHeight > scroll?.clientHeight)
            if (scroll > 0) {
                // $('.multi-chk-ans-wrap').addClass('fixed-top');
				// console.log("hello");
				setQlenth('active');
            } else {
				// console.log("hello1")
				setQlenth('');
                // $('.multi-chk-ans-wrap').removeClass('fixed-top');
            }
        });
	}
	}, [])
	return (
		<>
			{/* wrapper containing checklist for the single check answer format */}
			<div className="single-chk-ans-wrap">{checklist}</div>
		</>
	);
};
