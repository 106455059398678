/** imported components
 
  * makeStyles for ===> styling material-ui component.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect.useRef ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * PropTypes ===> component from react to ensure type safety of receiving props
  * Typography, Box ===> material ui components
  * DragDropContext, Draggable, Droppable ===> component from react to use for drag functions
  * LossAnalysisSetupWalkthrough ===> walkthrough component 

**/

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useState, useRef, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListItemSecondaryAction } from "@material-ui/core";
import { LossAnalysisSetupWalkthrough } from './loss-analysis-setup-walkthrough'
//LossAnalysisSetupPageView component starts here
export const LossAnalysisSetupPageView = (props) => {
  //defining variables here
  const [activeSlide, setActiveSlide] = useState(1);
  const demoDdlData = [
    {
      name: 'Fhg',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg2',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg3',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg4',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg5',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg6',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg7',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg8',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg9',
      img: 'gbp-flag.svg'
    },
    {
      name: 'Fhg10',
      img: 'gbp-flag.svg'
    },


  ]
  const [productionData1, setProductionData1] = useState([
      {
          id: 'row0'
        },
         {
          id: 'row1'
        },
         {
          id: 'row2'
        },

    ]);
  const [filterDdl, setFilterDdl] = useState(demoDdlData);
  const [currencyDdl, setCurrencyDdl] = useState(false);
  const [tmpCurrency, setTmpCurrency] = useState();
  const [productionData2, setProductionData2] = useState({
    units: 'Tons',
    currency: 'EUR'
  });
  let prodAllInput = [];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  //Styling variable
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: 224,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));

  const classes = useStyles();
  const [timeValue, setTimeValue] = React.useState(0);
  //function to add row on click
  const AddRowOnClick = () => {
    let tmpArr = [...productionData1];
    tmpArr.push({
      id: `row${productionData1.length}`
    });
    setProductionData1(tmpArr);
    // console.log(productionData1);
  }
  //function to delete row on click
  const DeleteRowOnClick = (index) => {
    let tmpArr = productionData1.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setProductionData1(tmpArr);
  }
  //Function to handle after drag
  const HandleAfterDrag = (data) => {
    const lastIndex = data.destination?.index;
    const firstindex = data.source?.index;
    const tmpArr = [...productionData1];
    const firstElement = {...tmpArr[firstindex]};
    const lastElement = {...tmpArr[lastIndex]};
    tmpArr[firstindex] = lastElement;
    tmpArr[lastIndex] = firstElement;
    if (lastIndex||lastIndex==0) { setProductionData1(tmpArr) }

  }
//Function to handle value on change
  const HandleValueOnChange = (e, singleData, name) => {
    const val = e.target.value;
    const tmpArr = [];
    productionData1.forEach((item, i) => {
      if (item.id === singleData.id) {
        let tmpData = {
          ...singleData,
          [name]: val
        }
        tmpArr.push(tmpData);
      } else {
        tmpArr.push(item);
      }
    });
    setProductionData1(tmpArr);
  }

//Function to get value on next1
  const GetValueOnNext1 = () => {
    setTimeValue(1);
    setActiveSlide(3);
  }
  //Function handle production value
  const handleProduction2Val = (e, name) => {
    let val = e.target.value;
    if (!val) {
      val = val = e.currentTarget.getAttribute('data-value');
    } else if (name == 'currency') {
      setTmpCurrency();
    }

    let tmpObj = { ...productionData2, [name]: val }
    if (val) {
      setProductionData2(tmpObj);
    } else if (name == 'units') {
      setProductionData2({ ...productionData2, [name]: 'Tons' })
    } else if (name == 'currency') {
      setProductionData2({ ...productionData2, [name]: 'EUR' })
    }
  }
  const GetValueOnNext2 = () => {
    setTimeValue(2);
  }

  const ddlFilterInput = useRef();
  //function to fliter dropdown list
  const FilterDdlList = (e) => {
    e.stopPropagation();
    let val = ddlFilterInput.current.value;

    if (val.length > 0) {
      let fiterData = demoDdlData.filter((item) => {
        return item.name.toLowerCase().startsWith(val.toLowerCase());
      });
      setFilterDdl(fiterData);
    } else {
      setFilterDdl(demoDdlData);
    }
  }
  useEffect(() => {
  }, []);
  return (
    <>
       {/* Main wrapper on loss analysis setup page */}
      <div className="loss-analysis-setup-wrapper">
        <div className="loss-setup-tabs">
           {/* left timeline from page to page */}
          <div className="loss-setups-time-line">
            <div className={`loss-setups-li ${timeValue == 0 && 'inprogress'} ${timeValue > 0 && 'done'}`}>
              <div className="ls-t-cir">
                <span>1</span>
                <img src={require('../../assets/img/loss-analysis-setup-img/done-chk-white.svg').default} alt="" />
              </div>
              <div className="ls-t-text">
                Production
                processes
              </div>
            </div>
            <div className={`loss-setups-li ${timeValue == 1 && 'inprogress'} ${timeValue > 1 && 'done'}`}>
              <div className="ls-t-cir">
                <span>2</span>
                <img src={require('../../assets/img/loss-analysis-setup-img/done-chk-white.svg').default} alt="" />
              </div>
              <div className="ls-t-text">
                Unit of Measurement
              </div>
            </div>
            <div className={`loss-setups-li ${timeValue == 2 && 'inprogress'} ${timeValue > 2 && 'done'}`}>
              <div className="ls-t-cir">
                <span>3</span>
                <img src={require('../../assets/img/loss-analysis-setup-img/done-chk-white.svg').default} alt="" />
              </div>
              <div className="ls-t-text">
                Fiscal Year
              </div>
            </div>
          </div>
          <TabPanel value={timeValue} index={0} className="tab-panel-wrapper">
            {/* loss-setup-row divided in two parts
              md-col-9
              md-col3
            */}
            <div className="loss-setup-row">
              {/* column has four parts
                  heading
                  sub-heading
                  text 
                  production-process-table-wrapper
              */}
              <div className="loss-setup-col left md-col-9">
                <h1 className="page-title">Loss Analysis Setup</h1>
                <h2 className="form-heading">Production processes</h2>
                <p className="sub-text">Please list the main production areas of the plant along with their number of lines.</p>
                {/* 
                    In this section has table with four columns
                */}
                <div className="production-process-table-wrapper">
                  <div className="prod-1">
                    <div className="table-row first-row">
                      <div className="table-col left">
                        <span className="table-heading">#</span>
                      </div>
                      <div className="table-col mid">
                        <span className="table-heading">Production process</span>
                      </div>
                      <div className="table-col mid2">
                        <span className="table-heading">Number of lines</span>
                      </div>
                      <div className="table-col right"></div>
                    </div>
                    <DragDropContext
                      onDragEnd={(params) => HandleAfterDrag(params)}
                    >
                      <div>
                        <Droppable
                          droppableId='droppable-1'>
                          {(provided, _) => (

                            <div ref={provided.innerRef}
                              {...provided.droppableProps}>
                              {productionData1.map((item, index) => {
                                return (
                                  <Draggable
                                    key={`draggable${index}`}
                                    index={index}
                                    draggableId={`draggableId${index}`}
                                    data={item}
                                  >
                                    {
                                      (provided, snapshot) => (
                                        <div className={`table-row ${snapshot.isDragging && 'dragging'}`
                                        } ref={provided.innerRef} {...provided.draggableProps}>
                                          <div className="table-col left">
                                            <span className="table-heading">{index + 1}</span>
                                          </div>
                                          <div className="table-col mid">
                                            <div className="table-input-wrap">
                                              <input
                                                autoComplete="off" className="error-select"
                                                id={`prodNameInput${item.id}`} 
                                                onBlur={(e) => HandleValueOnChange(e, item, 'prodNameInput')}
                                                type="text"
                                                defaultValue={item['prodNameInput']}
                                                placeholder="Name of process e.g. packing" />
                                                <div className="error-text-mssg">Error Helper text</div>
                                            </div>
                                          </div>
                                          <div className="table-col mid2">
                                            <div className="table-input-wrap">
                                              <input type="text"
                                                autoComplete="off" className="error-select"
                                                id={`prodLineInput${item.id}`}
                                                defaultValue={item['prodLineInput']}
                                                placeholder="No. of Lines" onBlur={(e) => HandleValueOnChange(e, item, 'prodLineInput')} />
                                                <div className="error-text-mssg">Error Helper text</div>
                                            </div>
                                          </div>
                                          <div className="table-col right">
                                            <div className="table-image-wrap">
                                              <button className="delete-btn" onClick={() => DeleteRowOnClick(index)}>
                                                <img
                                                  src={
                                                    require("../../assets/img/loss-analysis-setup-img/delete-icon.svg")
                                                      .default
                                                  }
                                                  alt="delete-icon"
                                                />
                                              </button>
                                              <div className="drag-btn" {...provided.dragHandleProps}>
                                                <img
                                                  src={
                                                    require("../../assets/img/loss-analysis-setup-img/drag.svg")
                                                      .default
                                                  }
                                                  alt="drag"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>

                      </div>
                    </DragDropContext>
                  </div>
                  <div className="table-row" id="add-row">
                    <div className="button-wrap">
                      <button className="btn-link" onClick={() => {
                        AddRowOnClick()
                      }}>+ Add new production Process</button>
                    </div>
                  </div>
                  <div className="table-row">
                    <div className="button-wrap next">
                      <button className="btn-link disable-blue"
                        disabled={productionData1.length > 0 ? false : true}
                        onClick={GetValueOnNext1}>Next</button>
                    </div>
                  </div>
                </div>
              </div>
               {/* this section has two parts
                  btn-wrap
                  insight-card-wrapper
              */}
              <div className="loss-setup-col left md-col-3">
                 {/* this section has button */}
                <div className="btn-wrap schedule">
                  <button type="submit">Schedule a call</button>
                </div>
                {/* card is divided into two parts
                      inside-card-heading-wrap
                      content-wrapper
                  */}
                <div className="insight-card-wrapper">
                  <div className="insight-card">
                    {/* this section has two parts
                        heading 
                        image
                    */}
                    <div className="insight-card-heading-wrap">
                      <h3>Insight</h3>
                      <img
                        src={
                          require("../../assets/img/loss-analysis-setup-img/bulb.svg")
                            .default
                        }
                        alt="Bulb"
                      />
                    </div>
                    {/* this section has text */}
                    <div className="content-wrapper">
                      <p>In the <span className="highlight-text">"Production Process"</span> column,
                        please group your production lines based on common technology and product category. </p>
                      <p>In the <span className="highlight-text">"Number of Lines"</span> column please provide
                        the number of lines that are constituting each grouped production process.</p>
                      <p>The Loss Analysis data collection is going to be based on the grouped
                        production processes you define in this interface. Thus, please set up the production
                        processes based on the internal structure you report line efficiency losses.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={timeValue} index={1} className="tab-panel-wrapper second measurement-panel">
            {/* loss-setup-row divided in two parts
              md-col-9
              md-col3
            */}
            <div className="loss-setup-row">
                {/* This section has 
                  title 
                  sub-heading
                  text
              */}
              <div className="loss-setup-col left md-col-9">
                <h1 className="page-title">Loss Analysis Setup</h1>
                <div className="prod-2">
                  <h2 className="form-heading">Production volume</h2>
                  <p className="sub-text">Please select the Production Volume Unit of Measurement (UoM) used for reporting.</p>
                   {/* row has three parts
                      measurment-col
                  */}
                  <div className="measurement-row">
                       {/* Each col has input-wrap 
                        inside input-wrap , input has different value 
                    */}
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <input type="button" value="Tons" onClick={(e) => handleProduction2Val(e, 'units')} className={productionData2.units === "Tons" && 'selected'} />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">

                        <input type="button" value="Units" onClick={(e) => handleProduction2Val(e, 'units')} className={productionData2.units === "Units" && 'selected'} />

                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <input type="text" defaultValue={productionData2.units === "Units" || productionData2.units === "Tons" ? '' : productionData2.units} placeholder="Other"
                          className={productionData2.units !== "Units" && productionData2.units !== "Tons" && 'selected'}
                          onBlur={(e) => {
                            handleProduction2Val(e, 'units',)
                          }} />
                      </div>
                    </div>
                  </div>
                  <h2 className="form-heading">Official business currency</h2>
                  <p className="sub-text">Please select the Currency Unit of Measurement (UoM) used for reporting.</p>
                  {/* row has five parts
                      measurment-col
                  */}
                  <div className="measurement-row second">
                    {/* col has input-wrap */}
                    <div className="measurement-col">
                      {/* It has been divided in two parts
                          las-flag
                          button
                      */}
                      <div className="input-wrap">
                        {/* in this section has image */}
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/eur-flag.svg').default} alt="true" />
                        </span>
                        <input type="button" value="EUR"
                          onClick={(e) => {
                            handleProduction2Val(e, 'currency');
                          }}
                          className={productionData2.currency === "EUR" && 'selected'}
                        />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/usd-flag.svg').default} alt="true" />
                        </span>
                        <input type="button" value="USD" onClick={(e) => handleProduction2Val(e, 'currency')} className={productionData2.currency === "USD" && 'selected'} />

                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/gbp-flag.svg').default} alt="true" />
                        </span>
                        <input type="button" value="GBP" onClick={(e) => handleProduction2Val(e, 'currency')} className={productionData2.currency === "GBP" && 'selected'} />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/chf-flag.svg').default} alt="true" />
                        </span>
                        <input type="button" value="CHF" onClick={(e) => handleProduction2Val(e, 'currency')} className={productionData2.currency === "CHF" && 'selected'} />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <div className="las-ddl">
                          {!tmpCurrency ?
                            <button className={`btn-dlas ${currencyDdl && 'active'}`} onClick={() => setCurrencyDdl(!currencyDdl)}>
                              <span>Other</span>
                              <svg viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.9873 1.5L4.99986 5.48744L1.01242 1.5" stroke="#4D4D4F" strokeWidth="1.5" strokeLinecap="round" />
                              </svg>
                            </button>
                            :
                            <div onClick={() => setCurrencyDdl(!currencyDdl)}>
                              <span className="las-flag">
                                <img src={
                                  require(`../../assets/img/loss-analysis-setup-img/${tmpCurrency.img}`).default} alt="true"
                                />
                              </span>
                              <input type="button" value={tmpCurrency.name} className='selected' />
                            </div>
                          }
                          {/* top button end */}
                          <ul className={`las-ddl-ul ${currencyDdl && 'active'}`}>
                            <li className="las-ddl-li">
                              <label>
                                <img src={require('../../assets/img/loss-analysis-setup-img/ddl-search.svg').default} />
                                <input type="text" ref={ddlFilterInput} defaultValue="" placeholder="Search" onBlur={(e) => FilterDdlList(e)} />
                              </label>
                            </li>

                            {filterDdl.map((item, index) => (<li className="las-ddl-li" key={index}>
                              <button
                                data-value={item.name}
                                onClick={(e) => {
                                  // setTmpCurrency(item);
                                  handleProduction2Val(e, 'currency');
                                  setCurrencyDdl(false);
                                }}>
                                <span className="li-flag">
                                  <img src={
                                    require(`../../assets/img/loss-analysis-setup-img/${item.img}`).default} alt="true"
                                  />
                                </span>
                                <span className="las-txt">
                                  {item.name}
                                </span>
                              </button>
                            </li>))
                            }
                          </ul>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* This section has two buttons */}
                <div className="measurement-row button-wrap">
                  <div className="button transparent">
                    <button type="submit" className="back-btn" onClick={() => setTimeValue(0)}>Back</button>
                  </div>
                  <div className="button solid">
                    <button type="submit" className="next-btn" onClick={() => {setTimeValue(2);
                        setActiveSlide(4);}}>Next</button>
                  </div>
                </div>
              </div>
              <div className="loss-setup-col left md-col-3">
                <div className="btn-wrap schedule">
                  <button type="submit">Schedule a call</button>
                </div>
                <div className="insight-card-wrapper">
                  <div className="insight-card">
                    <div className="insight-card-heading-wrap">
                      <h3>Insight</h3>
                      <img
                        src={
                          require("../../assets/img/loss-analysis-setup-img/bulb.svg")
                            .default
                        }
                        alt="Bulb"
                      />
                    </div>
                    <div className="content-wrapper">
                      <p>Continue the Setup with the <span className="highlight-text">Units of Measurement</span> that you use for internal reporting to business unit.</p>
                      <p>Please select the official Unit of Measurement used for business unit reporting.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={timeValue} index={2} className="tab-panel-wrapper third measurement-panel">
            {/* Row has two parts
              md-col-9
              md-col-3
            */}
            <div className="loss-setup-row">
              {/* column has 
                  title
                  heading
                  text
                  measurement-row
              */}
              <div className="loss-setup-col left md-col-9">
                <h1 className="page-title">Loss Analysis Setup</h1>
                <div className="prod-3">
                  <h2 className="form-heading">Fiscal Year</h2>
                  <p className="sub-text">Please choose the full fiscal year you would like to assess.</p>
                  {/* this section has col */}
                  <div className="measurement-row">
                    {/* col has input-wrap */}
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <input type="text" placeholder="e.g. 2020" />
                      </div>
                    </div>
                  </div>
                  {/* This section has two buttons */}
                  <div className="measurement-row button-wrap">
                    <div className="button transparent">
                      <button type="submit" className="back-btn" onClick={() => setTimeValue(1)}>Back</button>
                    </div>
                    <div className="button solid hide-class">
                      <button type="submit" className="next-btn">Next</button>
                    </div>
                    <div className="button solid submit">
                      <button type="submit" className="submit-btn disable-blue">Submit</button>
                    </div>
                    <div className="button solid goto">
                      <button type="submit" className="goto-btn">Go to Loss Analysis</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="loss-setup-col left md-col-3">
                <div className="btn-wrap schedule">
                  <button type="submit">Schedule a call</button>
                </div>
                <div className="insight-card-wrapper">
                  <div className="insight-card">
                    <div className="insight-card-heading-wrap">
                      <h3>Insight</h3>
                      <img
                        src={
                          require("../../assets/img/loss-analysis-setup-img/bulb.svg")
                            .default
                        }
                        alt="Bulb"
                      />
                    </div>
                    <div className="content-wrapper">
                      <p>The Loss Analysis exercise is going to be based on the last <span className="highlight-text">full fiscal year</span> you have reported your plant's results.</p>
                      <p>Please avoid providing a year which has not yet closed officially for the plant/company.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
        {/*----------- submit-popup ----------------*/}
        <div className="submit-popup active hide-class">
          <div className="popup-wrapper submitdata-popup">
              <div className="close-btn-wrap">
                  <button className="close-btn">
                      <img src={require("../../assets/img/la-admin/close-pop.svg").default} alt="Close" className="close-img" />
                  </button>
              </div>
              <div className="popup">
                  <div className="content-box">
                      <h2 className="heading">Submiting Data</h2>
                      <p className="text">Upon submitting, you will not be able to edit the Plant's Loss data.</p>
                      <p className="text">Do you want to proceed and submit your data?</p>
                  </div>
                  <div className="button-wrapper">
                      <button type="submit" className="btn cancel-button primary">Cancel</button>
                      <button type="submit" className="btn submit-btn secondary">Submit</button>
                  </div>
              </div>
          </div>
      </div>
      </div>
      {/* Walkthrough component call */}
      {activeSlide &&
        <LossAnalysisSetupWalkthrough activeSlide={activeSlide} setActiveSlide={setActiveSlide} setProductionData1={setProductionData1} {...props} />
      }
    </>
  );
};