/** imported components

  * makeStyles for ===> styling material-ui component.
  * CartContext for ==> add item into the cart.
  * Modal for ==> for making modal popup.
  * openPopupWidget for ==> for calendly.

**/
import { Collapse } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { openPopupWidget } from "react-calendly";
import Modal from "@material-ui/core/Modal";
import { CartContext } from "../../contexts/CartContext";
// PlansPageView start
export const PlansPageView = (props) => {
  // defining variables here
  const plansdata = props.pldata;
  const plansdataloss = props.pllossdata;
  const buybothdata = props.buybothdata;
  const user = JSON.parse(localStorage.getItem("userData"));
  const group_id = JSON.parse(localStorage.getItem("group_id"));
  const [isBuyPlantPopup, setIsBuyPlantPopup] = useState(false);
  // call onclick
  window.onclick = (e) => {
    if (
      !e.target.closest(".normal-text-i-img img") &&
      !e.target.closest(".plan3-i-img img")
    ) {
      HandleToolTip(null);
    }
  };

  const [isCustmTooltip, setIsCustmTooltip] = useState(null);
  // function HandleToolTip
  const HandleToolTip = (val) => {
    if (val === isCustmTooltip) {
      setIsCustmTooltip(null);
    } else {
      setIsCustmTooltip(val);
    }
  };
  // function CustomButtonContact
  const CustomButtonContact = ({ url, pageSettings, prefill, utm }) => {
    const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
    return (
      <a className="plan2-blue-border-btn" onClick={onClick}>
        Contact Us
      </a>
    );
  };
  // function CustomButton
  const CustomButton = ({ url, pageSettings, prefill, utm }) => {
    const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
    return (
      <a
        className="plan2-blue-border-btn"
        onClick={() => setIsBuyPlantPopup(true)}
      >
        Buy
      </a>
    );
  };

  return (
    <div className="plans-page-main">
      <section className="weblive-plan-sec2">
        <div className="bg-plan">
          <div className="container">
            <div className="plant-dots">
              <img
                src={
                  require("../../assets/img/plans-page/plan-dots.svg").default
                }
              />
            </div>
            <div className="plant-dots2">
              <img
                src={
                  require("../../assets/img/plans-page/plan-dots2.svg").default
                }
              />
            </div>
          </div>
        </div>
        {/* bg-plan end */}
        <div className="container px4">
          <div className="plan2-card-overflow">
            <div className="plan2-card-wrapper">
              {/* quickstart plan section start */}
              <div className="plan2-card">
                <div className="plan2-card-head">
                  <div className="palnt2-img-div">
                    <img
                      src={
                        require("../../assets/img/plans-page/plan-quickstart.svg")
                          .default
                      }
                    />
                  </div>
                  <h2 className="blue-txt">quickstart</h2>
                  <div className="plan2-card-h1 margin-minus">
                    {/* Start for Free */}
                    Your Current Plan
                  </div>
                  <p>
                    Get Started fast, simple and easy with our{" "}
                    <span className="b">Free Intro Course.</span> Gain access to
                    additional resources and start exploring.
                  </p>
                </div>
                {/* plan2-card-head end*/}
                <div className="plan2-card-body">
                  <ul className="border-list">
                    <li>
                      Manufacturing <span className="blue">Professionals</span>
                    </li>
                    <li>
                      <div className="li-p-flex">
                        <div className="li-p-flex-img">
                          <img
                            src={
                              require("../../assets/img/plans-page/plan-li-users.svg")
                                .default
                            }
                          />
                        </div>
                        <p>1 User</p>
                      </div>
                    </li>
                    <li>
                      <div className="li-p-flex">
                        <div className="li-p-flex-img">
                          <img
                            src={
                              require("../../assets/img/plans-page/plan-li-plants.svg")
                                .default
                            }
                          />
                        </div>
                        <p>0 Plants</p>
                      </div>
                    </li>
                    <li>
                      <p className="last-p-li-txt">Includes</p>
                    </li>
                  </ul>
                  {/* border-list */}
                  <h4 className="plan2-dot-list-h4">
                    Industry 4.0 Online Training Courses​
                  </h4>
                  <ul className="plan2-dot-list green">
                    <li>
                      <p>Accelerate your Industry 4.0 knowledge</p>
                    </li>
                    <li>
                      <p>Amplify technology understanding</p>
                    </li>
                    <li>
                      <p>Develop the right mindset</p>
                    </li>
                  </ul>
                  {/* plan2-dot-list green end */}
                  <h4 className="plan2-dot-list-h4">
                    Limited Digital Maturity Assessment​
                  </h4>
                  <ul className="plan2-dot-list blue">
                    <li>
                      <p>
                        Get a glimpse of your plant’s Industry 4.0
                        characteristics
                      </p>
                    </li>
                    <li>
                      <p>
                        Explore the Factory of the Future Leadership
                        benchmarking
                      </p>
                    </li>
                  </ul>
                  {/* plan2-dot-list green end */}
                  {/* <div className="plan2-btn-wrapper">
                                        <a className="plan2-blue-btn">
                                            Your Current Plan
              </a>
                                    </div> */}
                </div>
                {/* plan2-card-body end */}
              </div>
              {/* STARTUP plan section start */}
              <div className="plan2-card">
                <div className="plan2-card-head">
                  <div className="palnt2-img-div">
                    <img
                      src={
                        require("../../assets/img/plans-page/plan-advanced.svg")
                          .default
                      }
                    />
                  </div>
                  <h2 className="blue-txt">STARTUP</h2>
                  <div className="plan2-card-h1">Scale as you go</div>
                  <p>
                    Upgrade any time. Unlock the value from Quickstart plan and{" "}
                    <span className="b"> start strategizing</span> towards an
                    Industry 4.0 ready future.
                  </p>
                </div>
                {/* plan2-card-head end*/}
                <div className="plan2-card-body">
                  <ul className="border-list">
                    <li>
                      <span className="blue">Factories </span>
                      that want to Start Fast
                    </li>
                    <li>
                      <div className="li-p-flex">
                        <div className="li-p-flex-img">
                          <img
                            src={
                              require("../../assets/img/plans-page/plan-li-users.svg")
                                .default
                            }
                          />
                        </div>
                        <p>1 Plant Team</p>
                      </div>
                    </li>
                    <li>
                      <div className="li-p-flex">
                        <div className="li-p-flex-img">
                          <img
                            src={
                              require("../../assets/img/plans-page/plan-li-plants.svg")
                                .default
                            }
                          />
                        </div>
                        <p>1 Plant</p>
                      </div>
                    </li>
                    <li>
                      <p className="last-p-li-txt">
                        Everything in
                        <span className="blue"> Quickstart</span> plus
                      </p>
                    </li>
                  </ul>
                  {/* border-list */}
                  <h4 className="plan2-dot-list-h4">
                    Full Digital Maturity Assessment
                  </h4>
                  <ul className="plan2-dot-list green">
                    <li>
                      <p>Learn where you stand</p>
                    </li>
                    <li>
                      <p>See how your plant scores</p>
                    </li>
                    <li>
                      <p>Benchmark internally and externally</p>
                    </li>
                  </ul>
                  {/* plan2-dot-list green end */}
                  <h4 className="plan2-dot-list-h4">
                    Industry 4.0 Loss Analysis​​
                  </h4>
                  <ul className="plan2-dot-list blue">
                    <li>
                      <p>Identify the main loss drivers</p>
                    </li>
                    <li>
                      <p>Visualize the opportunities</p>
                    </li>
                  </ul>
                  {/* plan2-dot-list green end */}
                  <div className="plan2-btn-wrapper">
                    {group_id == 11 ? (
                      ""
                    ) : (
                      <CustomButton url="https://calendly.com/smarterchains/startup" />
                    )}
                  </div>
                </div>
                {/* plan2-card-body end */}
              </div>
              {/* enterprise plan section start */}
              <div className="plan2-card">
                <div className="plan2-card-head">
                  <div className="palnt2-img-div">
                    <img
                      src={
                        require("../../assets/img/plans-page/plan-enterprise.svg")
                          .default
                      }
                    />
                  </div>
                  <h2 className="blue-txt">enterprise</h2>
                  <div className="plan2-card-h1">
                    Accelerate your Industry 4.0 capabilities
                  </div>
                  <p>
                    Get the <span className="b">full potential!</span> Use our
                    virtual capability accelerator to{" "}
                    <span className="b">exponentially</span> create value{" "}
                    <span className="b">enterprise-wide.</span>
                  </p>
                </div>
                {/* plan2-card-head end*/}
                <div className="plan2-card-body">
                  <ul className="border-list">
                    <li>
                      Kick-start <span className="blue">Enterprise</span>​
                      Digital transformation
                    </li>
                    <li>
                      <div className="li-p-flex">
                        <div className="li-p-flex-img">
                          <img
                            src={
                              require("../../assets/img/plans-page/plan-li-users.svg")
                                .default
                            }
                          />
                        </div>
                        <p>Unlimited Users</p>
                      </div>
                    </li>
                    <li>
                      <div className="li-p-flex">
                        <div className="li-p-flex-img">
                          <img
                            src={
                              require("../../assets/img/plans-page/plan-li-plants.svg")
                                .default
                            }
                          />
                        </div>
                        <p>Unlimited Plants</p>
                      </div>
                    </li>
                    <li>
                      <p className="last-p-li-txt">
                        Everything in
                        <span className="blue"> Startup</span> plus
                      </p>
                    </li>
                  </ul>
                  {/* border-list */}
                  <h4 className="plan2-dot-list-h4">
                    Plant &amp; Enterprise Strategy
                  </h4>
                  <ul className="plan2-dot-list green">
                    <li>
                      <p>Define your transformation strategy</p>
                    </li>
                    <li>
                      <p>Create your Roadmap and Business Case</p>
                    </li>
                    <li>
                      <p>Discover Industry 4.0 Vendors</p>
                    </li>
                  </ul>
                  {/* plan2-dot-list green end */}
                  <h4 className="plan2-dot-list-h4">Program Management​​</h4>
                  <ul className="plan2-dot-list blue">
                    <li>
                      <p>Execute your transformation strategy</p>
                    </li>
                    <li>
                      <p>Scale enterprise​ learning</p>
                    </li>
                    <li>
                      <p>Track Savings</p>
                    </li>
                  </ul>
                  {/* plan2-dot-list green end */}
                  <div className="plan2-btn-wrapper">
                    <CustomButtonContact
                      url="https://calendly.com/smarterchains/enterprise"
                      pageSettings={{
                        minWidth: "320px",
                        height: "630px",
                      }}
                    />
                  </div>
                </div>
                {/* plan2-card-body end */}
              </div>
              {/* plan2-card 3 end */}
            </div>
            {/* plan2-card-wrapper end */}
          </div>
        </div>
      </section>
      {/* Plan detail section start */}
      <section className="weblive-plan-sec3">
        <div className="container  px0">
          <div className="plan3-row">
            <div className="plan3-col" />
            <div className="plan3-col">
              <div className="plan3-i-row green">
                <div
                  className={
                    isCustmTooltip === 0
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={(e) => HandleToolTip(0)}
                  />
                  <div className="hover-blue-box">
                    Available Individually.
                    <br />
                    Limited functionality for 1 Plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">quickstart</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row orange">
                <div
                  className={
                    isCustmTooltip === 1
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => {
                      HandleToolTip(1);
                    }}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; individually.
                    <br />
                    Full functionality for 1 plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">STARTUP</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row yellow">
                <div
                  className={
                    isCustmTooltip === 2
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(2)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; Individually.
                    <br />
                    Full functionality for many plants.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">enterprise</h2>
              </div>
            </div>
          </div>
          {/* plan row end 1*/}
          <div className="plan3-row thead">
            <div className="plan3-col">
              <span className="thead-btn-type-text green">
                Industry 4.0 Online Training Courses
              </span>
            </div>
            <div className="plan3-col">
              <p>
                On-Demand
                <br />1 User
              </p>
            </div>
            <div className="plan3-col">
              <p>
                On-Demand
                <br />
                Many Users - 1 Plant
              </p>
            </div>
            <div className="plan3-col">
              <p>
                On-Demand
                <br />
                Many Users - Many Plants
              </p>
            </div>
          </div>
          {/* thead end 1*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 3
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => {
                      HandleToolTip(3);
                    }}
                  />
                  <div className="hover-blue-box">
                    Key Industry 4.0 Insights educational video from IMD Digital
                    Supply Chain Executive Course.
                  </div>
                </div>
                <p>
                  <span>Industry 4.0 Approach - IMD Intro</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 1 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 4
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(4)}
                  />
                  <div className="hover-blue-box">
                    Prepare for the digital transformation journey and get
                    exposed to Industry 4.0 characteristics.
                  </div>
                </div>
                <p>
                  <span>Intro to Industry 4.0</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 2 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 5
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(5)}
                  />
                  <div className="hover-blue-box">
                    Ask questions, network with peers and let our in-house
                    experts interact with you.
                  </div>
                </div>
                <p>
                  <span>Community Q&amp;A</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 3 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 6
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(6)}
                  />
                  <div className="hover-blue-box">
                    We gamified the experience to get certified, so that you
                    don't only answer questions, but you have fun too.
                  </div>
                </div>
                <p>
                  <span>Quiz</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 4 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 7
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(7)}
                  />
                  <div className="hover-blue-box">
                    Showcase your Industry 4.0 readiness and earn a certificate
                    for each training course you complete.
                  </div>
                </div>
                <p>
                  <span>Certification</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 5 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 8
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(8)}
                  />
                  <div className="hover-blue-box">
                    Advance your technology specific understanding​ with our
                    Industry 4.0 Dimensions Training
                  </div>
                </div>
                <p>
                  <span>15 Hrs Training Sessions</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <p>Available to purchase</p>
            </div>
            <div className="plan3-col">
              <p>Available to purchase for Teams &amp; Individually</p>
            </div>
            <div className="plan3-col">
              <p>Available to purchase for Teams &amp; Individually</p>
            </div>
          </div>
          {/* tbody end 6 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 9
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(9)}
                  />
                  <div className="hover-blue-box">
                    A Group Assignment: Building an Industry 4.0 Roadmap, while
                    illustrating concrete use cases for several key
                    technologies.​
                  </div>
                </div>
                <p>
                  <span>Workshop: How to Build an Industry 4.0 Roadmap</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <p>Available to purchase for Teams</p>
            </div>
            <div className="plan3-col">
              <p>Available to purchase for Teams</p>
            </div>
          </div>
          {/* tbody end 7 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 10
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(10)}
                  />
                  <div className="hover-blue-box">
                    Following workshop completion, 1hr – Follow up call to
                    discuss the results and Q&A on Teams' queries.
                  </div>
                </div>
                <p>
                  <span>1 Hour Monthly Q&amp;A</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 8 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 11
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(11)}
                  />
                  <div className="hover-blue-box">
                    Access course materials for all purchased courses via
                    SmarterChains platform.
                  </div>
                </div>
                <p>
                  <span>24/7 Access to Course Materials</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 9 */}
          <div className="plan3-row">
            <div className="plan3-col" />
            <div className="plan3-col">
              <div className="plan3-i-row green">
                <div
                  className={
                    isCustmTooltip === 12
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(12)}
                  />
                  <div className="hover-blue-box">
                    Available Individually.
                    <br />
                    Limited functionality for 1 Plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">quickstart</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row orange">
                <div
                  className={
                    isCustmTooltip === 13
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(13)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; individually.
                    <br />
                    Full functionality for 1 plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">STARTUP</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row yellow">
                <div
                  className={
                    isCustmTooltip === 14
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(14)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; Individually.
                    <br />
                    Full functionality for many plants.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">enterprise</h2>
              </div>
            </div>
          </div>
          {/* plan row end 2*/}
          <div className="plan3-row thead">
            <div className="plan3-col">
              <span className="thead-btn-type-text blue">
                Digital Maturity Assessment
              </span>
            </div>
            <div className="plan3-col">
              <p>
                Self
                <br />1 Plant
              </p>
            </div>
            <div className="plan3-col">
              <p>
                Self or Guided
                <br />1 Plant
              </p>
            </div>
            <div className="plan3-col">
              <p>
                Self or Guided
                <br />
                Many Plants
              </p>
            </div>
          </div>
          {/* thead end 2*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 15
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(15)}
                  />
                  <div className="hover-blue-box">
                    Benchmark your Industry 4.0 capabilities and see how your
                    plant scores.
                  </div>
                </div>
                <p>
                  <span>Digital Maturity Assessment</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <p>Limited for 1 Dimension</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
          </div>
          {/* tbody end 10 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 16
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(16)}
                  />
                  <div className="hover-blue-box">
                    Access the results to identify where you stand versus the
                    Industry and plan your transformation.
                  </div>
                </div>
                <p>
                  <span>Access To Results: Digital Maturity Assessment</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
          </div>
          {/* tbody end 11 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 17
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(17)}
                  />
                  <div className="hover-blue-box">
                    Access the enterprise results via a consolidated dashboard,
                    covering common themes and opportunities.
                  </div>
                </div>
                <p>
                  <span>
                    Access To Results: Enterprise Digital Maturity Report
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 12 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 18
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(18)}
                  />
                  <div className="hover-blue-box">
                    In accordance with our Framework, the Index is further
                    analysed into the 10 Dimensions.
                  </div>
                </div>
                <p>
                  <span>Dimension Scores</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <p>Limited for 1 Dimension</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
          </div>
          {/* tbody end 13 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 19
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(19)}
                  />
                  <div className="hover-blue-box">
                    Uncover benchmarking capabilities per Dimension for your
                    operations within the enterprise and the industry.
                  </div>
                </div>
                <p>
                  <span>Dimensions Benchmarking</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <p>Limited for 1 Dimension</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
          </div>
          {/* tbody end 14 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 20
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(20)}
                  />
                  <div className="hover-blue-box">
                    Each Dimension is benchmarked within the enterprise and with
                    each plant.
                  </div>
                </div>
                <p>
                  <span>
                    10 Dimensions Solution Adoption Insights - Internal
                    Benchmarking
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col"></div>
            <div className="plan3-col"></div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
          </div>
          {/* tbody end 15 */}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 21
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(21)}
                  />
                  <div className="hover-blue-box">
                    Each Dimension is benchmarked within the industry you
                    operate.
                  </div>
                </div>
                <p>
                  <span>
                    10 Dimensions Solution Adoption Insights - External
                    Benchmarking
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <p>Limited for 1 Dimension</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
          </div>
          {/* tbody end 16*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 22
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(22)}
                  />
                  <div className="hover-blue-box">
                    A description is provided for capability along with adoption
                    rates amongst your company and industry.
                  </div>
                </div>
                <p>
                  <span>Solution Technology description</span>
                </p>
              </div>
            </div>
            <div className="plan3-col">
              <p>Limited for 1 Dimension</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
            <div className="plan3-col">
              <p>Full</p>
            </div>
          </div>
          {/* tbody end 17*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 24
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(24)}
                  />
                  <div className="hover-blue-box">
                    Have a question? Our experts are standing by to get in touch
                    with you.
                  </div>
                </div>
                <p>
                  <span>Virtual I4.0 Expert Chat Support (24Hrs Response)</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 18*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 25
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(25)}
                  />
                  <div className="hover-blue-box">
                    Send us your request and we will reply instantly through our
                    integrated support system.
                  </div>
                </div>
                <p>
                  <span>Virtual I4.0 Expert Live Support</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 19*/}
          <div className="plan3-row">
            <div className="plan3-col" />
            <div className="plan3-col">
              <div className="plan3-i-row green">
                <div
                  className={
                    isCustmTooltip === 26
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(26)}
                  />
                  <div className="hover-blue-box">
                    Available Individually.
                    <br />
                    Limited functionality for 1 Plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">quickstart</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row orange">
                <div
                  className={
                    isCustmTooltip === 27
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(27)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; individually.
                    <br />
                    Full functionality for 1 plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">STARTUP</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row yellow">
                <div
                  className={
                    isCustmTooltip === 28
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(28)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; Individually.
                    <br />
                    Full functionality for many plants.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">enterprise</h2>
              </div>
            </div>
          </div>
          {/* plan row end 3*/}
          <div className="plan3-row thead">
            <div className="plan3-col">
              <span className="thead-btn-type-text blue">
                Industry 4.0 Loss Analysis
              </span>
            </div>
            <div className="plan3-col">
              <p>
                Self
                <br />1 Plant
              </p>
            </div>
            <div className="plan3-col">
              <p>
                Self or Guided
                <br />1 Plant
              </p>
            </div>
            <div className="plan3-col">
              <p>
                Self or Guided
                <br />
                Many Plants
              </p>
            </div>
          </div>
          {/* thead end 3*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 29
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(29)}
                  />
                  <div className="hover-blue-box">
                    Identify the main loss drivers of your manufacturing
                    operations.
                  </div>
                </div>
                <p>
                  <span>Industry 4.0 Loss Analysis</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 20*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 30
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(30)}
                  />
                  <div className="hover-blue-box">
                    Identify line efficiency losses on a production process
                    level.
                  </div>
                </div>
                <p>
                  <span>Line Efficiency Losses</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 21*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 31
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(31)}
                  />
                  <div className="hover-blue-box">
                    Identify operator effort losses on a production process
                    level.
                  </div>
                </div>
                <p>
                  <span>Operator Effort Losses</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 22*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 32
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(32)}
                  />
                  <div className="hover-blue-box">
                    Identify losses on key plant functions. (i.e. quality,
                    maintenance)
                  </div>
                </div>
                <p>
                  <span>Key Plant Functions</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 23*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 33
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(33)}
                  />
                  <div className="hover-blue-box">
                    Loss analysis verification ensured by financial data.
                  </div>
                </div>
                <p>
                  <span>Financial Data Ensurance</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 24*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 34
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(34)}
                  />
                  <div className="hover-blue-box">
                    Dedicated Power BI interface for loss visualization and
                    reporting purposes.
                  </div>
                </div>
                <p>
                  <span>Access to Results: Top Losses</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 25*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 35
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(35)}
                  />
                  <div className="hover-blue-box">
                    Access the enterprise results via a consolidated dashboard,
                    covering common opportunity losses.
                  </div>
                </div>
                <p>
                  <span>
                    Access to Results: Enterprise Loss Analysis Report
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 26*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 36
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(36)}
                  />
                  <div className="hover-blue-box">
                    Provide your comment and questions through dedicated comment
                    functionality.
                  </div>
                </div>
                <p>
                  <span>Virtual I4.0 Expert Comment Support</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 27*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 37
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(37)}
                  />
                  <div className="hover-blue-box">
                    Have a question? Our experts are standing by to get in touch
                    with you.
                  </div>
                </div>
                <p>
                  <span>Virtual I4.0 Expert Chat Support (24Hrs Response)</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 28*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 38
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(38)}
                  />
                  <div className="hover-blue-box">
                    Send us your request and we will reply instantly through our
                    integrated support system.
                  </div>
                </div>
                <p>
                  <span>Virtual I4.0 Expert Live Support</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 29*/}
          <div className="plan3-row">
            <div className="plan3-col" />
            <div className="plan3-col">
              <div className="plan3-i-row green">
                <div
                  className={
                    isCustmTooltip === 39
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(39)}
                  />
                  <div className="hover-blue-box">
                    Available Individually.
                    <br />
                    Limited functionality for 1 Plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">quickstart</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row orange">
                <div
                  className={
                    isCustmTooltip === 40
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(40)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; individually.
                    <br />
                    Full functionality for 1 plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">STARTUP</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row yellow">
                <div
                  className={
                    isCustmTooltip === 41
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(41)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; Individually.
                    <br />
                    Full functionality for many plants.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">enterprise</h2>
              </div>
            </div>
          </div>
          {/* plan row end 4*/}
          <div className="plan3-row thead">
            <div className="plan3-col">
              <span className="thead-btn-type-text orange">Business Case</span>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <p>
                Plant Business Case
                <br />1 Plant
              </p>
            </div>
            <div className="plan3-col">
              <p>
                Enterprise-wide Business Case
                <br />
                Many Plants
              </p>
            </div>
          </div>
          {/* thead end 4*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 42
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(42)}
                  />
                  <div className="hover-blue-box">
                    Design your Industry 4.0 site-specific transformation
                    strategy.
                  </div>
                </div>
                <p>
                  <span>Up to 15-Step Industry 4.0 Roadmap</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 30*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 43
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(43)}
                  />
                  <div className="hover-blue-box">
                    Discover Industry 4.0 Vendors for each proposed solution on
                    the Industry 4.0 Roadmap.
                  </div>
                </div>
                <p>
                  <span>Vendor Solutions per Roadmap Step</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 31*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 44
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(44)}
                  />
                  <div className="hover-blue-box">
                    Get a concrete cost-benefit analysis for each proposed
                    roadmap step and visualize the opportunities.
                  </div>
                </div>
                <p>
                  <span>Cost-benefit Analysis per Roadmap Steps</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 32*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 45
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(45)}
                  />
                  <div className="hover-blue-box">
                    Granular Power BI interface and consolidated dashboard
                    covering the key results and business case output.
                  </div>
                </div>
                <p>
                  <span>Access to Results: Business Case Dashboard</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 33*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 46
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(46)}
                  />
                  <div className="hover-blue-box">
                    Gain access to a plant-specific report that highlights the
                    roadmap steps towards digital transformation.
                  </div>
                </div>
                <p>
                  <span>
                    Access to Results: Plant Industry 4.0 Roadmap report
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 34*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 47
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(47)}
                  />
                  <div className="hover-blue-box">
                    Receive a consolidated Industry 4.0 Roadmap report that
                    summarizes your upcoming enterprise structured steps.
                  </div>
                </div>
                <p>
                  <span>
                    Access to Results: Enterprise Industry 4.0 Strategy
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 35*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 48
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(48)}
                  />
                  <div className="hover-blue-box">
                    Have a question? Send us your request and our team will get
                    in touch.
                  </div>
                </div>
                <p>
                  <span>Email Support</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 36*/}
          <div className="plan3-row">
            <div className="plan3-col" />
            <div className="plan3-col">
              <div className="plan3-i-row green">
                <div
                  className={
                    isCustmTooltip === 49
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(49)}
                  />
                  <div className="hover-blue-box">
                    Available Individually.
                    <br />
                    Limited functionality for 1 Plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">quickstart</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row orange">
                <div
                  className={
                    isCustmTooltip === 50
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(50)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; individually.
                    <br />
                    Full functionality for 1 plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">STARTUP</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row yellow">
                <div
                  className={
                    isCustmTooltip === 51
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(51)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; Individually.
                    <br />
                    Full functionality for many plants.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">enterprise</h2>
              </div>
            </div>
          </div>
          {/* plan row end 5*/}
          <div className="plan3-row thead">
            <div className="plan3-col">
              <span className="thead-btn-type-text yellow">MasterPlanner</span>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col" />
            <div className="plan3-col" />
          </div>
          {/* thead end 5*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 52
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(52)}
                  />
                  <div className="hover-blue-box">
                    Execution tracking software for your Industry 4.0
                    implementation to scale learnings across enterprise.
                  </div>
                </div>
                <p>
                  <span>Program Management Workflow</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col"> </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 37*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 53
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(53)}
                  />
                  <div className="hover-blue-box">
                    Ready to use step-by-step templates for implementation of
                    Industry 4.0 technologies.
                  </div>
                </div>
                <p>
                  <span>
                    Industry 4.0 Technologies Implementation Templates
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col"> </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 38*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 54
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(54)}
                  />
                  <div className="hover-blue-box">
                    Access an easy to grasp consolidated view of your
                    enterprise's past, present and future projects.
                  </div>
                </div>
                <p>
                  <span>Enterprise Consolidated Project Dashboard</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col"> </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 39*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 55
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(55)}
                  />
                  <div className="hover-blue-box">
                    Select Table view to get a more condensed view - great for
                    viewing a ton of information at once.
                  </div>
                </div>
                <p>
                  <span>Project Table View</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col"> </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 40*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 56
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(56)}
                  />
                  <div className="hover-blue-box">
                    Select Dashboard view to see how many tasks are running
                    late, how many are in each task group, their priority and
                    the number of tasks each person is assigned to.
                  </div>
                </div>
                <p>
                  <span>Project Dashboard View</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col"> </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 41*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 57
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(57)}
                  />
                  <div className="hover-blue-box">
                    A dedicated project plan that contains the actions and
                    milestones to be completed for the execution of the Business
                    Case project with SmarterChains.
                  </div>
                </div>
                <p>
                  <span>Project Plan for Business Case Execution</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col"> </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 42*/}
          <div className="plan3-row">
            <div className="plan3-col" />
            <div className="plan3-col">
              <div className="plan3-i-row green">
                <div
                  className={
                    isCustmTooltip === 58
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(58)}
                  />
                  <div className="hover-blue-box">
                    Available Individually.
                    <br />
                    Limited functionality for 1 Plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">quickstart</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row orange">
                <div
                  className={
                    isCustmTooltip === 59
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(59)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; individually.
                    <br />
                    Full functionality for 1 plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">STARTUP</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row yellow">
                <div
                  className={
                    isCustmTooltip === 60
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(60)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; Individually.
                    <br />
                    Full functionality for many plants.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">enterprise</h2>
              </div>
            </div>
          </div>
          {/* plan row end 6*/}
          <div className="plan3-row thead">
            <div className="plan3-col">
              <span className="thead-btn-type-text yellow">
                Savings Tracking
              </span>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col" />
            <div className="plan3-col" />
          </div>
          {/* thead end 6*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 61
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(61)}
                  />
                  <div className="hover-blue-box">
                    Repeat the loss analysis methodology to identify the updated
                    main loss drivers of your manufacturing operations.
                  </div>
                </div>
                <p>
                  <span>
                    Repeat Loss Analysis during specific time intervals
                  </span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 43*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 62
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(62)}
                  />
                  <div className="hover-blue-box">
                    Track your Industry 4.0 Savings, upon Roadmap steps
                    implementation; visualize results on a dedicated Power BI
                    interface.
                  </div>
                </div>
                <p>
                  <span>Access Results: Savings Tracking</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 44*/}
          <div className="plan3-row">
            <div className="plan3-col" />
            <div className="plan3-col">
              <div className="plan3-i-row green">
                <div
                  className={
                    isCustmTooltip === 63
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(63)}
                  />
                  <div className="hover-blue-box">
                    Available Individually.
                    <br />
                    Limited functionality for 1 Plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">quickstart</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row orange">
                <div
                  className={
                    isCustmTooltip === 64
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(64)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; individually.
                    <br />
                    Full functionality for 1 plant.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">STARTUP</h2>
              </div>
            </div>
            <div className="plan3-col">
              <div className="plan3-i-row yellow">
                <div
                  className={
                    isCustmTooltip === 65
                      ? "plan3-i-img cstm-tooltip"
                      : "plan3-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(65)}
                  />
                  <div className="hover-blue-box">
                    Available for Teams &amp; Individually.
                    <br />
                    Full functionality for many plants.
                  </div>
                </div>
                <h2 className="plan3-h1-blue">enterprise</h2>
              </div>
            </div>
          </div>
          {/* plan row end 7*/}
          <div className="plan3-row thead">
            <div className="plan3-col">
              <span className="thead-btn-type-text purple">Add-ons</span>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col" />
            <div className="plan3-col" />
          </div>
          {/* thead end 7*/}
          <div className="plan3-row tbody">
            <div className="plan3-col">
              <div className="normal-text-i">
                <div
                  className={
                    isCustmTooltip === 66
                      ? "normal-text-i-img cstm-tooltip"
                      : "normal-text-i-img"
                  }
                >
                  <img
                    alt="yes"
                    src={
                      require("../../assets/img/plans-page/paln3-information.svg")
                        .default
                    }
                    onClick={() => HandleToolTip(66)}
                  />
                  <div className="hover-blue-box">
                    Integrate to Microsoft Teams
                  </div>
                </div>
                <p>
                  <span>Microsoft Teams Integration</span>
                </p>
              </div>
            </div>
            <div className="plan3-col" />
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
            <div className="plan3-col">
              <img
                alt="yes"
                src={
                  require("../../assets/img/plans-page/plan-table-green-cir.svg")
                    .default
                }
              />
            </div>
          </div>
          {/* tbody end 45*/}
        </div>
      </section>
      {/* All plans include section start */}
      <section className="weblive-plan-sec4">
        <div className="container px8">
          <div className="plan4-row">
            <div className="plan4-col-12">
              <h2 className="plan4-h1">All plans include</h2>
            </div>
            <div className="plan4-col">
              <div className="plan4-col-img">
                <img
                  src={
                    require("../../assets/img/plans-page/plan4-col1-img.svg")
                      .default
                  }
                />
              </div>
              <div className="plan4-col-text">
                <p>Learning Resources</p>
              </div>
            </div>
            {/* plan4-col end */}
            <div className="plan4-col">
              <div className="plan4-col-img">
                <img
                  src={
                    require("../../assets/img/plans-page/plan4-col2-img.svg")
                      .default
                  }
                />
              </div>
              <div className="plan4-col-text">
                <p>Get Started Guide</p>
              </div>
            </div>
            {/* plan4-col end */}
            <div className="plan4-col">
              <div className="plan4-col-img">
                <img
                  src={
                    require("../../assets/img/plans-page/plan4-col3-img.svg")
                      .default
                  }
                />
              </div>
              <div className="plan4-col-text">
                <p>Collaboration</p>
              </div>
            </div>
            {/* plan4-col end */}
            <div className="plan4-col">
              <div className="plan4-col-img">
                <img
                  src={
                    require("../../assets/img/plans-page/plan4-col4-img.svg")
                      .default
                  }
                />
              </div>
              <div className="plan4-col-text">
                <p>Email Support</p>
              </div>
            </div>
            {/* plan4-col end */}
            <div className="plan4-col">
              <div className="plan4-col-img">
                <img
                  src={
                    require("../../assets/img/plans-page/plan4-col5-img.svg")
                      .default
                  }
                />
              </div>
              <div className="plan4-col-text">
                <p>Priority Support Response</p>
              </div>
            </div>
            {/* plan4-col end */}
            <div className="plan4-col">
              <div className="plan4-col-img">
                <img
                  src={
                    require("../../assets/img/plans-page/plan4-col6-img.svg")
                      .default
                  }
                />
              </div>
              <div className="plan4-col-text">
                <p>Flexibility - Change Plan Any Time</p>
              </div>
            </div>
            {/* plan4-col end */}
          </div>
        </div>
      </section>
      {/* Buy plan popup component start */}
      <BuyPlanPopup
        open={isBuyPlantPopup}
        setOpen={setIsBuyPlantPopup}
        setplansdata={plansdata}
        setplansdata1={plansdataloss}
        setbuyboth={buybothdata}
        setAddPlantPopup={setIsBuyPlantPopup}
        {...props}
      />
      {/* <MaturityAddUserPopup open={isAddUserPopup} setOpen={setIsAddUserPopup} setAddPlantPopup={setIsAddPlantPopup} {...props} /> */}
    </div>
  );
};
// BuyPlanPopup component
export const BuyPlanPopup = (props) => {
  const getpdata = props.setplansdata;
  const getpdata1 = props.setplansdata1;
  const getpdata2 = props.setbuyboth;

  const bothbuy = [getpdata, getpdata1];
  console.log("bothbuy", bothbuy);
  console.log("getpdata", getpdata);
  console.log("getpdata1", getpdata1);
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleOtherFunction = () => {
    handleClose();
    props.setAddPlantPopup(true);
  };
  // getpdata.sections.map((section, index) => {
  //     totalTime = totalTime + parseInt(section.section_duration);
  // });
  const { addProduct, cartItems, increase } = useContext(CartContext);
  // const isInCart = (props) => {
  //     return !!cartItems.find((item) => item.id === getpdata.id);
  // };
  return (
    <>
      {/* Buy plan popup modal start */}
      <Modal
        open={props.open}
        onClose={handleClose}
        className="maturity-admin-popup"
      >
        <div className="buy-plan-wrap">
          <Link
            to="/cartmat-Full-Maturity-Assessment"
            className="buy-plan-opt-btn"
            onClick={() => addProduct(getpdata)}
          >
            Buy Digital Maturity Assessment
          </Link>
          <Link
            to="/cartmat-Loss-Analysis"
            className="buy-plan-opt-btn"
            onClick={() => addProduct(getpdata1)}
          >
            Buy Loss Analysis
          </Link>
          <Link
            to="/cartmat-Maturity-and-Loss-Analysis-with-Business-Case"
            className="buy-plan-opt-btn"
            onClick={() => addProduct(getpdata2)}
          >
            Buy Both with Business Case
          </Link>
          <Link to="/" className="buy-plan-opt-btn buy-plan-opt-cancel">
            Cancel
          </Link>
        </div>
      </Modal>
    </>
  );
};
