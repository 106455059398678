import styled from 'styled-components'

export const PopupContainer = styled.div`
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(125,134,140,.3);
  z-index:999;
  backdrop-filter: blur(2px);
  display:flex;
  align-items:center;
  justify-content:center;
  @media(max-width: 740px){
    align-items: flex-end;
  }
`

export const PopupCard = styled.div`
    width:110.2rem;
    max-width:110.2rem;
    background:#fff;
    height: 65.1rem;
    max-height: 90%;
    border-radius: 2.4rem;
    border: 1px solid #E5F0FF;
    display:flex;
    flex-direction:column;
    position:relative;
    padding-bottom:2rem;
    @media(max-width: 740px){
        border-radius: 0;
        height: calc(100vh - 80px);
    }
`
export const PopupCardHeader = styled.div`
    border-bottom: 0.1rem solid #CCD6E8;
    padding: 2.4rem;
    display: flex;
    align-items: center;
`
export const PopupCardBody = styled.div`
    flex:1;
    overflow:auto;
    padding:2.4rem;
    position:relative;
`
export const HeaderButtons = styled.div`
    display:flex;
    align-items:center;
    gap:1.6rem;
`
export const PopupCloseButton = styled.button`
    line-height:0;
    i{
        display:inline-block;
    } 
`
export const HeaderH1 = styled.div`
    flex: 1;
    dispaly: flex;
    span{
        display: block;
        color: #2C55A0;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.8rem; /* 100% */
    }
`
export const PopupCardBodyRow = styled.div`
    display: flex;
    height: 100%;
    gap: 2.4rem;
    @media(max-width: 740px){
        flex-direction: column-reverse;
    }
`
export const PopupCardLeftCol = styled.div`
    display: flex;
    flex-direction: column;
    width: 24.6rem;
`
export const PopupCardRightCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    .no-preview-box{
        flex: 1;
    }
    .right-head-text{
        display: block;
        color: #4D4D4F;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1.6rem;
    }
    .no-preview-box{
        background: #4d4d4f;
        flex: 1;
        .no-preview-wrap{
            background: #fff;
            margin: 0 auto;
            height: 100%;
            width: calc(100% - 1.6rem);
            span{
                color: #FFF;
                text-align: center;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: normal;
                display: block;
                padding: 1.4rem 0;
                background-color: #4d4d4f;
            }
        }
    }
`
export const PopupCardLeftHead = styled.div`
    flex: 1;
    .upload-title{
        color: #4D4D4F;
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1.6rem;
    }
    .upload-text{
        color: #7D868C;
        display: block;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 1.6rem;
    }
    .input-wrap{
        .upload-btn{
            display: inline-flex;
            cursor: pointer;
            border-radius: 0.4rem;
            border: 0.1rem solid #118ACB;
            background: #FFF;
            min-width: 14rem;
            min-height: 3.1rem;
            align-items: center;
            justify-content: center;
            color: #118ACB;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 1.4rem;
        }
        input{
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }
    
    .uploaded-content{
        display: flex;
        gap: 0.6rem;
        align-items: center;
        margin-bottom: 1.4rem;
        i{
            display: inline-block;
            width: 2.4rem;
            svg{
                width: 100%;
            }
        }
        .right-content{
            flex: 1;
            .file-name{
                color: #546E7A;
                display: block;
                font-size: 1.2rem;
                font-weight: 500;
                line-height: normal;
            }
            .file-size{
                color: #90A4AE;
                display: block;
                font-size: 1.2rem;
                font-weight: 400;
                line-height: normal;
            }
        }
        .close-btn{
            display: block;
            height: 1.4rem;
            width: 1.4rem;
            line-height: 0;
            i{
                display: block;
                height: 100%;
                width: 100%;
                svg{
                    height: 100%;
                    width: 100%:
                }
            }
        }
    }
`
export const PopupCardLeftFooter = styled.div`
    .publish-btn{
        border-radius: 0.4rem;
        background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
        color: #FFF;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: normal;
        min-height: 4.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .published-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 4.1rem;
        background: transparent;
        border: 0;
        gap: 0.8rem;
        width: 100%;
        i{
            display: block;
            width: 1.6rem;
            svg{ 
                width: 100%;
            }
        }
        span{
            color: #43A047;
            display: block;
            font-family: Inter;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.014rem;
        }
    }
`

export const CenteredPopupCard = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    bacground:#fff;
    width: 63rem;
    max-width:calc(100% - 3rem);
    z-index: 1;
    border-radius: 0.6rem;
    border: 0.1rem solid #118ACB;
    background: #FFF;
    box-shadow: 0px 0px 0.8rem 0px rgba(21, 27, 38, 0.12);
    padding:2.4rem;
    text-align:center;
    .centered-popup-h2{
        font-size: 1.8rem;
        font-weight: 500;
        color:#0D2C54;
        margin-bottom:1.8rem;
    }
    .centered-popup-p{
        font-size: 1.6rem;
        font-weight: 500;
        color:#4D4D4F;
    }
`
export const CenteredPopupFooter = styled.div`
    display:flex;
    justify-content:center;
    gap:1.5rem;
    margin:3.8rem 0rem 2rem 0rem;
`
export const CenteredPopupCancelButton = styled.button`
    border-radius: 0.4rem;
    border: 0.1rem solid rgba(219, 50, 77, 1);
    background:'#fff'; 
    color: rgba(219, 50, 77, 1);
    font-size: 1.5rem;
    font-weight: 500;
    padding:1rem 2.5rem;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    min-width:15rem;
    &:hover{
        background: rgba(250, 226, 230, 1);
    }
`
export const CenteredPopupProceedlButton = styled.button`
    border-radius: 0.4rem;
    border: 0.1rem solid rgba(219, 50, 77, 1);
    background: rgba(219, 50, 77, 1); 
    color:#fff;
    font-size: 1.5rem;
    font-weight: 500;
    padding:1rem 2.5rem;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    min-width:15rem;
    &:hover{
        background: rgba(194, 25, 52, 1);
    }
`
export const Loader = styled.div`
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`