/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $,{ isEmptyObject } from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * FinancialValidationPageController ===> Component call for this page
  * GeneralWalkthroughQuesView  ===> Component call for walkthrough
  * .scss for ===> styling css file for this page.
  * AssignSectionPopup ===> popup for assigning user
  * LossSupportView ===> Support component of loss

**/

import React, { useEffect } from "react";
import "./general-information-page.scss";
import $, { isEmptyObject } from "jquery";
import serviceProvider from '../../services/axios'
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { AssignSectionPopup } from "../loss-analysis-admin-page/loss-assing-popup";
import { FinancialValidationPageController } from "../financial-validation/financial-validation-page-ctrl";
import { GeneralWalkthroughQuesView } from './general-information-walkthrough'
// Loss support section start 
import { LossSupportView } from '../la-support/la-support-view';
// end
import { InviteUserList } from '../invite-user-list-component/InviteUserList'
// How to use popup
import { HowToUseCtrl } from '../lossanalysis-how-to-use-popup/HowToUseCtrl'

//GeneralInformationPagePlantView component start
export const GeneralInformationPagePlantView = (props) => {
  //Defining variables here
  const quesDatas = props.quesResult;
  if (!quesDatas.length) {
    return null;
  }
  const quesData = props.quesResult[0];
  const pid = quesData.pid;
  const units = quesData.customData.unit;
  const currency = quesData.customData.currency;
  const [shiftcombo, setShiftCombo] = useState('')
  const islock = quesData.isLocks;
  const [isRightPopup, setIsRightPopup] = useState(0);
  //variable for general-information walkthrough
  const [activeSlide, setActiveSlide] = useState(1);
  //end
  const [sideSlideIcon, setSideSlideIcon] = useState("fade info");
  const addStep2 = () => {
    let val = sideSlideIcon;
    if (val == 'slide-icon-change info')
      setSideSlideIcon(' none info')
    else {
      setSideSlideIcon('slide-icon-change info')
    }
  }
  const APDList = quesData.questions;
  const progress = quesData.Secprogress;
  const answers = quesData.ans;
  const year = quesData.customData.fiscal_year;
  const [allqueobj, setAllqueobj] = useState({});
  const [ans, setans] = useState([]);
  const [rightdata, setRightData] = useState({});
  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
  const [tmpAssignUser, setTmpAssignUser] = useState("");
  const [assignedUsers, setAssignedUsers] = useState({
    inviteData: [],
});
  const [cate, setCate] = useState();
  const [secname, setSecName] = useState();
  const [hashvalue, setHashvalue] = useState(window.location.hash);
  const [checkVal, setCheckval] = useState([]);
  const [newAns, setNewAns] = useState([]);
  const [subbtnDisable, setSubbtnDisable] = useState(true);
  const [openfinvalidation, setOpenFinValidation] = useState(quesData.open_fin_validation); 
  const [validationpopup, setValidationPopup] = useState(false); // for migration
  // scnv-2126 start
  const [isUnlockPopup, setIsUnlockPopup] = useState(false);
  // scnv-2126 end
  // scnv-2157 start
  const [isLossSupport, setIsLossSupport] = useState(false);
  const [isTicket, setIsTicket] = useState(true);
  // scnv-2157 end
  const invitedmeblist = quesData.sectioninvitedmeber;

  const segments = window.location.pathname.slice(1).split("/");
  const slug = segments[2];
  const sid = segments[3];
  const ssid = segments[4];
  const groupid = (localStorage.getItem("group_id"));
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const [isSubmitPopup, setIsSubmitPopup] = useState(false);


  // Popup Functions variables start
  // var to show or hide delete popup 
  const [delPop, setDelPop] = useState(false);
  //variable for edit comment
  const [isEditComment, setIsEditComment] = useState(null);
  //variable for store temproary data
  const [tmpCmntData, setTmpCmntData] = useState({});
  //variable for submit temproary data
  const [txtCmntInput, setTxtCmntInput] = useState('');
  // functions and variables for comment start
  const [isCommentCicked, setIsCommentCicked] = useState(false);
  const [comment, setComment] = useState([]);
  const [isQuesType, setIsQuesType] = useState(1);
  const [sendquestion, setSendquestion] = useState(1);
  // Popup Functions variables end
  let s1 = [];
  let s1name = [];
  let s1AavatarColor = [];
  invitedmeblist.map((item, index) => {
    if (item.invited_sub_category == quesData.subsection.id) {
      if (item.name != null) {
        s1.push(`${item.name[0]}${item.last_name[0]}`);
        s1name.push(`${item.name} ${item.last_name}`);
      } else {
        s1.push(`${item.email[0]}`);
        s1name.push(`${item.email}`);
      }
      s1AavatarColor.push(item.avcolor);
    }

  })
  const [errormessage, setErrorMessage] = useState(false);

  // Function to handle edit 
  const edithandle = (e) => {
    setIsAssignSectionPopup(true);
    setCate(sid);
    setSecName(props.pname);
  }
  const [messge, setMessge] = useState('');
  const commentmess = (e) => {
    setMessge(e.target.value);
  }

  const commentsub = (e) => {

    serviceProvider
      .post(
        "/businesscaseplantloss?type=savecomment",
        JSON.stringify({ uid: usid, pid: quesData.pid, qid: rightdata?.qid, message: messge }),
        true
      )
      .then((response) => {


      });
    setMessge('');

  }
  //Function to check value
  const checkValue = (valuen = 0) => {

    let inputg = document.querySelectorAll('.inputgen');
    let tempArr = [];
	let n=0;
    inputg.forEach((item) => {
      if (item.value) {
        tempArr.push(item.value);
		n=1;
      }
    })
    if (inputg.length == tempArr.length) {
      setSubbtnDisable(false);
    } else if (valuen?.length == inputg.length) {
      setSubbtnDisable(false);
    } else {
      setSubbtnDisable(true);
    }
	 //alert(shiftcombo);
		  //  alert(subbtnDisable);
		  if(subbtnDisable == false && shiftcombo<=24){
		  serviceProvider
                .post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:100 }),
                    true
                )
                .then((response) => {
                });
		  }else if(n==1){
			   serviceProvider.post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:50 }),
                    true
                )
                .then((response) => {
                });
			  
			  
			  
			  }
  }

  //Function for final submission
  const Finalsubmit = (e) => {

    serviceProvider
      .post(
        "/businesscaseplantloss?type=lockanswer",
        JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, allqueobj }),
        true
      )
      .then((response) => {

        location.reload();
      });

  }

  const HandleRightSec = async (e, val, item = 0) => {
    e.preventDefault();

    setComment([]);
    const apiUrl = `/businesscaseplantloss?param=commentshow&qid=${item?.id}&uid=${quesData.uid}&pid=${quesData.pid}`;
    const { data } = await serviceProvider.get(apiUrl, true, {});

    if (!isEmptyObject(data)) {
      let tempDataArr = []

      data.forEach((item) => {
        let fullDateCreate = new Date(item.datecreated);
        let fullDateEdit = new Date(item.dateupdated);
        let tmpObjArrD = {
          id: item.id,
          uid: item.user_id,
          cirName: item.usersn,
          comment: item.comment,
          editedDate: `${fullDateEdit.getDate()}/${fullDateEdit.getMonth()}/${fullDateEdit.getFullYear().toString().substr(-2)}`,
          editedTime: fullDateEdit.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
          commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth()}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
          commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
          isEdited: item.is_edited,
        }
        tempDataArr.push(tmpObjArrD);
      })

      setComment(tempDataArr);
    }
    setSendquestion(item?.question);
    setRightData({ name: item?.question, des: item?.question_description, qid: item?.id })
    if (isRightPopup === val) {
      setIsRightPopup(null);
    } else {

      setRightData({ name: item?.question, des: item?.question_description, qid: item?.id })
      setIsRightPopup(item?.id);
      setIsLossSupport(false);

    }
  }
  //Function to close right section
  const HandleRightSecClose = (e, val) => {
    e.preventDefault();
    setIsRightPopup(null);
    setIsLossSupport(false)
  }
  //Function for Delete button
  const HandleDelBtnEvent = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(item);
    setIsRemovePopup(true);
  }
  //Function for submit answerformat
  const submitAnsformat = (e, uuuum) => {
    e.preventDefault();
    var uuom = '';

    if (e.target.name == 'ques122' || e.target.name == 'ques123' || e.target.name == 'ques197' || e.target.name == 'ques198') {
      uuom = '%';
      if (e.target.value > 100) {
        e.target.value = 100;
      } else {
        e.target.value;
      }

    } else if (e.target.name == 'ques117') {
      if (e.target.value > 365) {
        e.target.value = 365;
      } else {
        e.target.value;
      }
    } else {
      uuom = units;
    }

    if (e.target.value.includes(' ' + uuom)) {
      document.querySelector('input[name=' + e.target.name + ']').value = e.target.value;

      if (e.target.value != '') {
        if (e.target.name == 'ques120') {
          document.querySelector('input[name=' + e.target.name + ']').value = Number(e.target.value.replace(/\D/g, '').toLocaleString('en-US')) + ' ' + uuuum;
        }

      }

    }
    else {
      if (e.target.value != '') {
        if (e.target.name == 'ques122' || e.target.name == 'ques123' || e.target.name == 'ques197' || e.target.name == 'ques198') {
          document.querySelector('input[name=' + e.target.name + ']').value = Number(e.target.value.replace(/\D/g, '')) + uuom;
        } else if (e.target.name == 'ques120' || e.target.name == 'ques121' || e.target.name == 'ques169' || e.target.name == 'ques170' || e.target.name == 'ques171') {
          document.querySelector('input[name=' + e.target.name + ']').value = Number(e.target.value).toLocaleString('en-US') + ' ' + uuuum;
        } else {
          document.querySelector('input[name=' + e.target.name + ']').value = Number(e.target.value.replace(/\D/g, '').toLocaleString('en-US')) + ' ' + uuuum;
        }

      }
    }



    let tempObj = {};
    APDList.map((item, index) => {

      let name = `ques` + item.id;

      const f1 = document.getElementById("f1");

      const data = new FormData(f1).getAll(name);

      tempObj = { ...tempObj, [name]: data }

    })

    setAllqueobj(tempObj);



  }

  const [shown, setShown] = useState(false);
  // Function to change vallue
  const changeval = (e, unnnom) => {
    // console.log(unnnom);
    if (unnnom) {
      let val = e.target.value;
      let name = e.target.name;
      let val1 = val.split(unnnom);

      if (val1[0] && name == 'ques171') {
        var vv = val1[0].replace(/,/g, "");
        e.target.value = vv.trim();
      } else {
        var vv = val1[0].replace(/\D/g, "");
        e.target.value = vv.trim();

      }
    }


  }
  //Function for on key value
  const onKeyVal = (e) => {

    var ch = String.fromCharCode(e.which);

    if (e.target.name == 'ques171') {
      var charCode = (e.which) ? e.which : e.keyCode;

      if (charCode == 46) {
        //Check if the text already contains the . character
        if (e.target.value.indexOf('.') === -1) {
          return true;
        } else {
          e.preventDefault();
        }
      } else {

        if (charCode > 31 &&
          (charCode < 48 || charCode > 57))
          e.preventDefault();
      }


    }
    else if (!(/[0-9]/.test(ch))) {
      e.preventDefault();

    }
  }
  const submitAns = (e) => {
    e.preventDefault();
    serviceProvider
      .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
      .then((res) => {
        // console.log(res);
      })
    let tempObj = {};
    APDList.map((item, index) => {

      let name = `ques` + item.id;


      const f1 = document.getElementById("f1");

      const data = new FormData(f1).getAll(name);
      // console.log(name);
      tempObj = { ...tempObj, [name]: data }

    })
    // console.log(tempObj);
    setAllqueobj(tempObj);
  }

  //start useEffect here
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.closest('.conected_to_popup')) {
        setShown(false);
      }
    });
  });

  useEffect(() => {


    // Get the modal
    var modal = document.getElementById("myModal");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");



    // When the user clicks on div, open the popup
    function myFunction() {
      var popup = document.getElementById("myPopup");
      popup.classList.toggle("show");
    }

    // When the user scroll on div, open the shadow
    $('.general-information-mid').scroll(
      () => {
        var scroll = $('.general-information-mid').scrollTop();
        if (scroll > 0) {
          $(".outside_card").addClass("active");
        }
        else {
          $(".outside_card").removeClass("active");
        }
      }
    );

    $(function () {
      $('.tage1 input,textarea').focus(function () {
        $(this).data('placeholder', $(this).attr('placeholder'))
          .attr('placeholder', '');
      }).blur(function () {
        $(this).attr('placeholder', $(this).data('placeholder'));
      });
    });



    $(document).on('change', '.ques-num', function (event) {
      var inputval = parseInt(this.value);
      if ($(this).hasClass('percentage1') && inputval > 100) {
        inputval = 100;
        $(this).val(inputval);
      }
      var qtype = $(this).attr('data-qtype');
      if (qtype == 1) {
      } else {
        if (inputval) {
          $(this).val(inputval + '%');
        } else if (inputval == 0) {
          $(this).val(inputval + '%');
        }
      }


    })
    if (islock > 0) {
      setSubbtnDisable(true);
    } else {
      setSubbtnDisable(false);
    }

    let tempval = [];
    // console.log(isEmptyObject(answer));
    //   let ans1 = answer.length;
    let newAnsVAl = [];
    answers.map((item, index) => {
      if (item.length != 0) {
        // console.log(JSON.parse(item.answer)[0]);
        let ansVal = JSON.parse(item.answer)[0];
        if (ansVal != '') {
          newAnsVAl.push(ansVal);
        }
        // console.log(item);
        tempval.push(JSON.parse(item.answer));
      }
    })


    if (tempval.length > 0) {
      setans(tempval);
    }
    // setNewAns(newAnsVAl);
    checkValue(newAnsVAl);
    setShiftCombo(quesData.shiftcombo);
    return async () => {
      try {
        if (Array.isArray(props.quesResult) && props.quesResult.length) {

          const { uid, pid: plant_id, has_sub_section, subsection, section } = props.quesResult[0];
          let data = { uid, section_id: section['id'], type: 2, status: 0, plant_id }

          if (section['has_sub_section'] === "1") {
            data['sub_section_id'] = subsection['id'];
          }
          else {
            data['sub_section_id'] = 0
          }
          await serviceProvider.post('/sectionlock', data, true);
        }
      } catch (err) {
        console.error(err)
      }
    }

  }, [])

  // useEffect for variable
  useEffect(() => {
    // console.log("useeff2")
    if (!isEmptyObject(allqueobj)) {
      serviceProvider
        .post(
          "/businesscaseplantloss?type=saveuseranswer",
          JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, allqueobj }),
          true
        )
        .then((response) => {
          
          setShiftCombo(response.data[0].shiftcombo);
			 serviceProvider
        .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
        .then((res) => {
          
        })
        });
     

    }

    // setShiftCombo(quesData.shiftcombo);

  }, [allqueobj])

  //popup changes start
  // Function to handle Note popup
  const HandleNotePop = () => {
    let temp = document.querySelector('.la-card-wrapper');
    if (temp.classList.contains('active')) {
      temp.classList.remove('active');
    }
    else {
      temp.classList.add('active');
    }
  }
  // Function to remove note popup
  const removeNotePop = () => {
    let tempr = document.querySelector('.la-card-wrapper');
    tempr.classList.remove('active');
    if (window.innerWidth < 1201) {
      tempr.classList.add('active');
    }
  }
  // Popup Functionality Start 
  //popup show
  const ShowDelPopup = (data, ind) => {
    setTmpCmntData({
      ...data, index: ind
    })
    setDelPop(!delPop);
  }
  //delete functionality start 
  const deletData = () => {
    const tmpArr = comment.filter((item, index) => item.id !== tmpCmntData.id);
    serviceProvider
      .post(
        "/businesscaseplantloss?type=deletecomment",
        JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, qid: rightdata?.qid }),
        true
      )
      .then((response) => {
        // console.log(response);

      });

    setComment(tmpArr);
    setDelPop(false);
    setTmpCmntData({});
  }
  //delete functionality end
  //edit functionality start
  const editComntData = (data, ind) => {
    setTmpCmntData({
      ...data, index: ind
    });
    let tmpTextArea = document.getElementById(`comment-editTxtarea-${data.id}`);
    tmpTextArea.value = data.comment;
    setIsEditComment(data.id);

  }
  //edit functionaliy end
  //Handle Comment textarea on change
  const handleTextAreaOnChange = (e) => {
    let val = e.target.value;
    setTxtCmntInput(val);
  }
  // Save submit data
  const saveCmntData = () => {

    let tmpTextArea = document.getElementById('addComment');
    let tmpCmntArr = [...comment];
    let fullDateCreate = new Date();
    // console.log(fullDateCreate.getMonth()+1);
    let tmpObj = {
      id: comment.length + 1,
      uid: usid,
      cirName: user.sn,
      comment: txtCmntInput,
      editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
      editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
      commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      isEdited: 0

    }
    serviceProvider
      .post(
        "/businesscaseplantloss?type=savecomment",
        JSON.stringify({ uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: txtCmntInput }),
        true
      )
      .then((response) => {
        // console.log(response);

      });
    tmpCmntArr.unshift(tmpObj);
    setComment(tmpCmntArr);
    setTxtCmntInput('');
    tmpTextArea.style.height = 30 + 'px';
    setIsCommentCicked(false);
  }
  // Save Edit Data Functionality
  const saveEditData = (index) => {
    let tmpcmntArr = [...comment];
    let tmpTextArea = document.getElementById(`comment-editTxtarea-${tmpCmntData.id}`);
    let fullDateCreate = new Date();
    let tmpObj = {
      ...tmpCmntData,
      comment: tmpTextArea.value,
      editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
      editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      isEdited: 1
    };
    serviceProvider
      .post(
        "/businesscaseplantloss?type=saveeditcomment",
        JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: tmpTextArea.value }),
        true
      )
      .then((response) => {
        // console.log(response);

      });

    tmpcmntArr[index] = tmpObj;
    setComment(tmpcmntArr);
    setIsEditComment('');
    // console.log(tmpcmntArr);
  }
  // Save Edit Data Functionality End
  // Popup Functionality End 10-11-2021
  useEffect(() => {
    let resizeTextArea = document.querySelectorAll('.resize-cmnt-area');
    resizeTextArea.forEach((item) => {
      item.addEventListener('input', () => {
        item.style.height = 'auto';
        let itemHeight = item.scrollHeight;
        if (itemHeight > 30) {
          item.style.height = itemHeight + 'px';
        }
        if (itemHeight > 136) {
          item.classList.remove('txtScrollHide');
        }
        else {
          item.classList.add('txtScrollHide');
        }

      })
    })
  }, [])

  React.useEffect(() => {
    if (hashvalue && hashvalue != null) {
      var abc = hashvalue.replace("#", "");
      var myArray = abc.split("-");
      $('body').find('#idfc' + myArray[0]).click();
      setIsLossSupport(false)
    }
    if(Array.isArray(invitedmeblist)){
      // setinvite-data
      let tmpArr={...assignedUsers};
       let tmpInviteData= invitedmeblist.filter((item) => item.invited_sub_category == quesData.subsection.id);
       tmpArr['inviteData']=tmpInviteData;
       setAssignedUsers(tmpArr);
    }
  }, [])

  return (
    <>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{quesData.plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem to={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>IR4.0 Loss Analysis</BreadcrumbsItem>
      <BreadcrumbsItem to="#">General Information</BreadcrumbsItem>
      {/* General information Main wrapper which contains four section 
          general-information-top
          general-information-top-two
          general-information-mid
          general-information-bottom
        */}
      <section className="general-information prod-3">
        {/* Top section has also three sections 
            First section has page title
            Second section has assigned users list and popup
            Third section has unlock button and popup
        */}
        <div className="general-information-top">
          <div className="general-information-row">
            <div className="general-information-lft-col">
              <h1 className="h1-btn">
                {/* <a className="back-link" href={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>
                  <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><rect x={2} y={2} width={24} height={24} rx={2} fill="white" /></g><path d="M19 7.4303L17.4833 6L9 14L17.4833 22L19 20.5697L12.0334 14L19 7.4303Z" fill="#7D868C" /><defs><filter id="filter0_d" x={0} y={0} width={28} height={28} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity={0} result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset /><feGaussianBlur stdDeviation={1} /><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" /><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" /><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" /></filter></defs></svg>
                </a> */}
                General Information
              </h1>
              <h4 className="h4-dull">
                <span className="text">Finance Data</span>
                <span className="mid-border"></span>
                <div className="text total">{`${year} Actual`}</div>
              </h4>
              <div className="value_of_progress" style={{ display: "none" }}>
                <p>Progress:</p>
                <div className="bar-wrapper">
                  <span>{progress}%</span>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: progress }} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                </div>
              </div>
            </div>


            <div
              style={{/* display: groupid == 14 ? 'none' : '' */}}
              className="general-information-mid-col">
              <div className="Assign">
                        <InviteUserList
                            data={assignedUsers.inviteData}
                            setData={setAssignedUsers}
                            handlePopup={edithandle}
							open={isAssignSectionPopup}
                            isEditAllow={groupid != 14 ? true : false}
                            catagory={sid}
                            subcatagory={quesData.subsection.id}
                            pid={pid}
                            uid={quesData.uid}
                            sectionName={quesData.subsection.sub_section_name}
                            plantName={quesData.plant_name}
							 {...props}
                        />
                <div style={{ visibility: 'hidden' }} className="direction_arrows">
                  <div className="upward">
                    <img src={require("../../assets/img/manufacturing-cost/upward.svg").default} alt="digital_maturity" />
                    <p>Upload Excel file</p>
                  </div>
                  <div className="downword">
                    <img src={require("../../assets/img/manufacturing-cost/downword.svg").default} alt="digital_maturity" />
                    <p>Download to Excel</p>
                  </div>
                </div>
              </div>
            </div>

            {/* la-ques-mid-col end */}
            <div className="general-rgt-col" style={{ position: 'relative' }}>

              {/* <div className={`la-card-wrapper ${islock == 1 ? '' : 'active'}`}>
                <button className="image-wrap" style={{ display: `${islock == 1 ? 'none' : ''}` }} onClick={() => HandleNotePop()}> */}
              <div className={`la-card-wrapper active`}>
                <button className="image-wrap" onClick={() => HandleNotePop()}>
                  <img src={require('../../assets/img/general-information/bulb.svg').default} alt="Bulb" />
                </button>
                <div className="content-box">
                  <p className="content">{quesData.subsection.description}</p>
                  <button to="/" className="close-btn"><img src={require('../../assets/img/general-information/close.svg').default} alt="close" onClick={() => removeNotePop()} /></button>
                </div>
              </div>
              {islock == 1 &&
                <>

                  {/* scnv-2126 start */}
                  <div className='scnv2126-btn-wrapper'>
                    <div>
                      <div className="submitted-scnv-2126">
                        <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: dataSubmitsvg }}></span>
                        <span>Data Submitted</span>
                      </div>
                    </div>
                    <div style={{ display: (groupid == 2 || groupid == 11) && quesData.lockall == 0 ? '' : 'none' }}>
                      <button className="submitted-scnv-2126 btn-unclock" onClick={() => setIsUnlockPopup(true)}>
                        <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: lockSvg }}></span>
                        <span>Unlock Section</span>
                      </button>
                    </div>
                  </div>
                  {/* scnv-2126 end */}
                </>
              }
              <div className="submit-page">
                {islock == 0 &&
                  <div className="prod-4"><button disabled={islock > 0 ? true : false} className={`btn-link submit ${islock > 0 ? 'disabled' : ''} ${subbtnDisable == false && shiftcombo <= 24 ? '' : 'disabled'} active`} onClick={() => { if (subbtnDisable == false && shiftcombo <= 24) { if(openfinvalidation == false){setIsSubmitPopup(true); setValidationPopup(false)}else{   setIsSubmitPopup(false); setValidationPopup(true) } } }}>Submit Data</button></div>
                }
              </div>
            </div>
            {/* la-ques-right-col end */}
          </div>
        </div>
        {/* This sections has two subsections and it is fixed when scroll
             left section has loss questions
             right section has year
          */}
        {/* <div className="general-information-top-two">
          <div className="outside_card">
            <div className="work_space">
              <div className="what_percentage">
                <div className="total">Loss Questions:</div>
              </div>
              <div className="what_percentage actual-position">
                <div className="total">{`${year} Actual`} </div>
              </div>
            </div>
            <div className="empty" />
          </div>
        </div> */}
        {/* This section has been divided into two parts
            First part has questions
            Second part has inputs
        */}
        <div className="general-information-mid prod-2">
          <div className="white_space prod-1">

            <div className="inside_cards">
              <form id={"f1"}>
                {!isEmptyObject(answers) && APDList.map((items, i) => {
                  return (

                    <div className="notice">
                      <div title={items.question} className={"side-slid-icon-button"}>
                        <div className={`info ${isRightPopup === (items.id) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, items)}>
                          <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                          </svg>
                        </div>
                        <div className="add-subcat-wrap">
                          <div className={`input-wrap ${!items.editable && 'w-100'}`}><p className="text-area" onClick={(e) => HandleRightSec(e, items.id, items)}>{items.question}</p></div>
                          {items.editable && <button className="del-btn"
                            onClick={(e) => {

                              HandleDelBtnEvent(e, {
                                name: items.question,
                                index: i
                              });
                            }}>
                            <img src={require('../../assets/img/operator-effort-loss/delete-icon.svg').default} alt="Delete" />
                          </button>}
                        </div>

                      </div>
                      <div className="percentage">
                        <input type="text" disabled={islock > 0 ? true : false} name={`ques${items.id}`} defaultValue={ans.length > 0 ? ans[i] : ""} className={`${items.class} inputgen`} onKeyPress={(e) => { onKeyVal(e) }} onChange={() => checkValue()} onBlur={(e) => { checkValue(), submitAnsformat(e, items.id == '120' ? units : items.uom) }} onFocus={(e) => { changeval(e, items.id == '120' ? units : items.uom) }} placeholder={items.id == '120' ? units : items.uom} maxLength={items.uom == '%' ? '3' : items.uom == 'Shifts'} />

                        <div className='tmcerror' style={{ visibility: `ques${items.id}` == 'ques118' && shiftcombo > 24 ? '' : 'hidden' }} >Shift * hours per shift is over 24</div>
                      </div>

                    </div>

                  )
                }
                )
                }

              </form>
            </div>

          </div>
        </div>
        {/* This section has submit button */}
        
        {/* submit popup starts here */}
        <div className={`submit-pop-wrap ${isSubmitPopup && 'active'}`}>
          <div className="popup-wrapper submit-popup">
            {/* Close button to close the popup */}
            <div className="close-btn-wrap">
              <button className="close-btn" onClick={() => { setIsSubmitPopup(false) }}>
                <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
              </button>
            </div>
            <div className="popup">
              {/* this section has image */}
              <div className="img-box">
                <img src={require("../../assets/img/la-questionnaires/unsaved.svg").default} alt="Unsaved" className="unsave-img" />
              </div>
              {/* This section has text */}
              <div className="content-box">
                <p className="text">By submitting the data you will no longer have the opportunity for further editing.  </p>
                <p className="text">Are you sure you want to proceed?</p>
              </div>
              {/* This section has buttons */}
              <div className="button-wrapper">
                <button type="submit" className="btn cancel-button primary" onClick={() => { setIsSubmitPopup(false) }}>Cancel</button>
                <button onClick={(e) => { setIsSubmitPopup(false); setValidationPopup(true) }} type="submit" className="btn submit-btn secondary">Submit</button>
              </div>
            </div>
          </div>
        </div>
        {/* Right Sidebar Popup starts here */}
        <div className={`right-sidebar ${isRightPopup && 'active'}`}>
          {/* Loss Support component */}
          {isRightPopup != 0 && (
            <LossSupportView
              popupHeadTxt={rightdata.name}
              des={rightdata.des}
              isLossSupport={isLossSupport}
              HandleRightSecClose={HandleRightSecClose}
              close={setIsLossSupport}
              isTicket={isTicket}
              setIsTicket={setIsTicket}
              pid={pid}
              sid={108}
              ssid={6}
              qid={isRightPopup}
              uid={quesData.uid}
              type={isQuesType}
              question={sendquestion}
              islock={islock}
			  isAvailable={false}
              {...props}
            />)}
        </div>
      </section>
      {/* Assign popup call for assign user */}
      <AssignSectionPopup
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        catagory={cate}
        subcatagory={6}
        pid={pid}
        sectionName={quesData.subsection.sub_section_name}
        plantName={quesData.plant_name}
        tmpAssignUser={'inviteData'}
        open={isAssignSectionPopup}
        setOpen={setIsAssignSectionPopup}
        {...props}
      />
      {/* Validation popup call conditionally */}
      {validationpopup &&
        <FinancialValidationPageController pid={pid} setOpenFinValidation={setOpenFinValidation} setValidationPopup={setValidationPopup} units={currency} sid={108} ssid={6} Finalsubmit={Finalsubmit} {...props} />
      }
      {/* General Information Page Walkthrough */}
      {activeSlide && quesData.gerneltour != 1 &&
        <GeneralWalkthroughQuesView activeSlide={activeSlide} setActiveSlide={setActiveSlide}  {...props} />
      }
      {/* ConfirmationUnlockPopup  Component*/}
      <ConfirmationUnlockPopup isOpen={isUnlockPopup} setUnlockbutton={props.setUnlockbutton} setOpen={setIsUnlockPopup} pid={pid} sid={108} ssid={6} />
      {/* How to use popup */}
      <div className="how-to-use-ctrl-wrap">
      <HowToUseCtrl sectionName={`General Information`}/>
    </div>
    </>
  );
};

// svg images define here
let dataSubmitsvg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11" cy="11" r="10.5" fill="#CAE4CB" stroke="#43A047"/>
<path d="M8.83863 14.0039L5.83524 11.0005L4.8125 12.0161L8.83863 16.0422L17.4815 7.39932L16.466 6.38379L8.83863 14.0039Z" fill="#43A047"/>
</svg>`

const lockSvg = `<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.7142 7.16667H12.7618V5.2619C12.7618 2.63333 10.6285 0.5 7.99991 0.5C5.37134 0.5 3.238 2.63333 3.238 5.2619V7.16667H2.28562C1.238 7.16667 0.380859 8.02381 0.380859 9.07143V18.5952C0.380859 19.6429 1.238 20.5 2.28562 20.5H13.7142C14.7618 20.5 15.619 19.6429 15.619 18.5952V9.07143C15.619 8.02381 14.7618 7.16667 13.7142 7.16667ZM7.99991 15.7381C6.95229 15.7381 6.09515 14.881 6.09515 13.8333C6.09515 12.7857 6.95229 11.9286 7.99991 11.9286C9.04753 11.9286 9.90467 12.7857 9.90467 13.8333C9.90467 14.881 9.04753 15.7381 7.99991 15.7381ZM10.9523 7.16667H5.04753V5.2619C5.04753 3.63333 6.37134 2.30952 7.99991 2.30952C9.62848 2.30952 10.9523 3.63333 10.9523 5.2619V7.16667Z" fill="#118ACB"/>
</svg>
`
// Function to show/hide confirmation unlock popup
const ConfirmationUnlockPopup = (props) => {
  const finalUnlock = (id) => {
    props.setOpen(false);
    serviceProvider
      .post(
        "/businesscaseplantloss?type=unlocksection",
        JSON.stringify({ pid: props.pid, sid: props.sid, ssid: props.ssid }),
        true
      )
      .then((response) => {
        location.reload();
      });
  }
  return (
    <>
      {/* Unlock popup wrapper starts here */}
      <div className='unlock-popup-wrapper2126' style={{ visibility: props.isOpen ? '' : 'hidden' }}>
        <div className='popup-content2126'>
          {/* this section has text */}
          <div className='body-popup2126'>
            <p>Are you sure, you would like to unlock the section?</p>
          </div>
          {/* this section has buttons */}
          <div className='popup-btn-wrapper2126'>
            <button className='p-2126-btn cancel' onClick={() => props.setOpen(false)}>Cancel</button>
            <button className='p-2126-btn yes' onClick={() => { finalUnlock(props.ssid) }}>Yes, unlock</button>
          </div>
        </div>
      </div>
    </>

  )
}
