import React,{useState,useEffect} from 'react';
import {KPIView} from './KPIView';
import serviceProvider from '../../services/axios';

export const KPICtrl =(props)=>{

    const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
	const segments = window.location.pathname.slice(1).split("/");
    const plant_slug = (typeof segments[1] !== "undefined" ? segments[1] : 0);
    const chapter_id = (typeof segments[2] !== "undefined" ? segments[2] : 0);

    useEffect(() => {
        const search = async () => {
          const apiUrl = `/assessmentmaturity?param=kpipage&slug=${plant_slug}&id=${chapter_id}&uid=${usid}`;
          const { data } = await serviceProvider.get(apiUrl, true, {});
          setResults(data);
        };
        search();
      },[]);


    if(results.length) {
                return (
                <>
                    <KPIView  results={results} {...props}/>
                </>
                );
    }else{
            return (
                <>
                <div></div>
                </>
            );

    }
}

