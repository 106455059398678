import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { MaturityPlantSetupPopup, MaturitySubmitDataPopup, AssignSectionPopup } from './maturity-dashboard-popup';
import "./maturity-dashboard-page.scss";
import serviceProvider from '../../services/axios'
export const MaturityDashboardPageView = (props) => {




  //console.log(props.homeData);

  //  const [tabResults, setTabResults] = useState([]);
  // const homeDataItems = props.homeData;
	// if (!homeDataItems.length) {
	// 	return null;
	// }
	// const homeDataItem = props.homeData[0];
	// const courses = homeDataItem.courses;
	// const paidcourses = homeDataItem.paidcourses;
	// const uid = homeDataItem.uid;
	// // const sid = props.sid ? props.sid : homeCurrentItem.id;
	// let broughttrainging = 0;
	// let avaltrainging = 0;
	// courses.map((items, index) => {
	// 	if (items.has_paid == 0) {
	// 		avaltrainging = avaltrainging + 1;
	// 	}
	// 	if (items.has_paid == 1) {
	// 		broughttrainging = broughttrainging + 1;
	// 	}
	// });

  // console.log(courses);


  


  const [isAddPlantPopup, setIsAddPlantPopup] = useState(false);
  const [isSubmitDataPopup, setIsSubmitDataPopup] = useState(false);
  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);

  const [maturityData, setMaturityData] = useState([]) 
  const [lossData, setLossData] = useState([])
  const [pdata, setPdata]= useState([]);
  const [PlantDeatils, setPlantDeatils] = useState([]) 
  const [plantidset, setPlantId] = useState([])
  const [catprogress, setCatprogress]= useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;

  useEffect(() => {
	
		const search1 = async () => {
        const result1 = await serviceProvider.get(`/home?param=matsection&uid=${usid}`,true,{});
         const data1 = result1.data;

         if(data1!=0){
        //  console.log(data1)
          setMaturityData(data1);
         }
        
        // const options = data1.map(d => ({
        // "invited_category": d.invited_category,
        //  "invited_for": d.invited_for
        //    }))
        // if(data1==0){
          const result1loss = await serviceProvider.get(`/home?param=losssection&uid=${usid}`,true,{});
          const datan2 = result1loss.data;
           const data2 = datan2[0].data;
          //  console.log(data2);
         const pdata = datan2[0].pdata;
          if(data2!=0){

            setPdata(pdata);
            console.log(pdata)
         setLossData(data2);
        }
        //  console.log(data2);
        // }
        //   else{
            
        //   }
       
        // console.log("sectionid-",data1)
        let invitedUserid;
        if(data1!=0){
          invitedUserid = data1[0].invited_by
        }else if(data2!=0){
          invitedUserid = data2[0].invited_by
        }

       
        setPlantId(invitedUserid);
        const result6 = await serviceProvider.get(`/plants/PlantDetails?user_id=${invitedUserid}`,true,{});
        setPlantDeatils(result6.data[0]);
       
        
        const result7 = await serviceProvider.get(`/home?param=progresscheck&uid=${usid}&pid=${result6.data[0].id}`,true,{});
       const data3 = result7.data;
       setCatprogress(data3);
      
		};
		search1();
	}, []);


 

  // useEffect(() => {
	
	
  //   const timer = setTimeout(() => {
  //     loadplntdetails();
  //   }, 5000);
	
	// }, []);
  const groupid = (localStorage.getItem("group_id"));
  
  const [layout,setLayout]=useState(1);

// if(maturityData.length!=0 && lossData.length==0){
//   setLayout(1)
// }else if(maturityData.length==0 && lossData.length!=0){
//   setLayout(2)
// }else if(maturityData.length!=0 && lossData.length!=0){
//   setLayout(3)
// }else{
//   setLayout(4)
// }

// const categoryprog = catprogress[0].catpr;
// console.log(catprogress)

  const HandleHoverMouseEnter = (e) => {
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const divposX = e.target.getBoundingClientRect().left;
    const divposY = e.target.getBoundingClientRect().top;
    if (screenW <= divposX + 500) {
      e.target.classList.add("right-hover");
    }
    if (screenH <= divposY + 200) {
      e.target.classList.add("bottom-hover");
    }
  };


  const HandleDdl = (e) => {
    let tmpTarget = e.currentTarget.parentElement;
    if (!tmpTarget.classList.contains('active')) {
      CloseAllDdl();
    }
    tmpTarget.classList.toggle('active');
  }
  const HandleHoverMouseLeave = (e) => {
    e.target.classList.remove("right-hover", "bottom-hover");
  };
  const CloseAllDdl = () => {
    let tmpTarget = document.querySelectorAll('.ms-maturity-d-more');
    tmpTarget.forEach((item, index) => {
      item.classList.remove('active');
    })
  }
  window.onclick = (e) => {
    if (!e.target.closest(".ms-maturity-d-more")) {
      CloseAllDdl();
    }
    if (e.target.classList.contains("ddl-cir")) {
      e.target.classList.toggle("active");
    }
    if (
      !e.target.closest(".ddl-cir") &&
      document.querySelector(".ddl-cir.active")
    ) {
      document.querySelector(".ddl-cir.active").classList.remove("active");
    }
  };
  return (
    <>
    {/* {   console.log(maturityData.length) , console.log(lossData),   console.log("cat-",catprogress[0]?.catpr)} */}
      <div className="maturity-dashboard-page-wrapper">
        
        
        {/* {
          layout==1?
          <LayOut1 CloseAllDdl={CloseAllDdl} PlantDeatils = {PlantDeatils} catpr={catprogress[0]?.catpr} maturityData={maturityData} homeData={props.homeData} setIsAssignSectionPopup={setIsAssignSectionPopup} />
          :
          
          layout==2?
          <LayOut2 CloseAllDdl={CloseAllDdl} {...props} lossData={lossData} PlantDeatils = {PlantDeatils}/>
          :
          layout==3?
          <LayOut3 CloseAllDdl={CloseAllDdl} {...props} /> 
          :
        <LayOut4 CloseAllDdl={CloseAllDdl} {...props} />
        }
         */}



        {   
          groupid!='2'?
          maturityData.length!=0 && lossData.length==0 ?
          <LayOut1 CloseAllDdl={CloseAllDdl} PlantDeatils = {PlantDeatils} catpr={catprogress[0]?.catpr} secpr={pdata} maturityData={maturityData} homeData={props.homeData} setIsAssignSectionPopup={setIsAssignSectionPopup} />
          :
          lossData.length!=0 && maturityData.length==0 ?
          <LayOut2 CloseAllDdl={CloseAllDdl} {...props} lossData={lossData} secpr={pdata} PlantDeatils = {PlantDeatils} /> 
          :
          lossData.length!=0 && maturityData.length!=0?
          <LayOut3 CloseAllDdl={CloseAllDdl} PlantDeatils = {PlantDeatils} lossData={lossData} secpr={pdata} catpr={catprogress[0]?.catpr} maturityData={maturityData} homeData={props.homeData} {...props} />
          :
          
          // window.location='/'
          // lossData.length!=0 && maturityData.length!=0 &&
            <LayOut4 CloseAllDdl={CloseAllDdl}  PlantDeatils = {PlantDeatils} {...props} />
            :
            window.location='/'
        }



        {/* {layout===1? */}
        {/* {maturityData.length!=0 && lossData.length==0 &&
        <LayOut1 CloseAllDdl={CloseAllDdl} PlantDeatils = {PlantDeatils} catpr={catprogress[0]?.catpr} maturityData={maturityData} homeData={props.homeData} setIsAssignSectionPopup={setIsAssignSectionPopup} />
        } */}

        {/* :layout===2?*/}
        
        {/* { 
        lossData.length!=0 && maturityData.length==0 &&
        <LayOut2 CloseAllDdl={CloseAllDdl} {...props} lossData={lossData} PlantDeatils = {PlantDeatils} /> } */}
       {/* :layout===3?*/}
       {/* {lossData.length!=0 && maturityData.length!=0 &&
        <LayOut3 CloseAllDdl={CloseAllDdl} PlantDeatils = {PlantDeatils} lossData={lossData} catpr={catprogress[0]?.catpr} maturityData={maturityData} homeData={props.homeData} {...props} />} */}
        {/*:*/}
        {/* <LayOut4 CloseAllDdl={CloseAllDdl} {...props} /> */}
        {/*} */}
      </div>
      {/*---popups----*/}
      <MaturityPlantSetupPopup open={isAddPlantPopup} setOpen={setIsAddPlantPopup} {...props} />
      <MaturitySubmitDataPopup open={isSubmitDataPopup} setOpen={setIsSubmitDataPopup} {...props} />
      <AssignSectionPopup open={isAssignSectionPopup} setOpen={setIsAssignSectionPopup}  {...props} />
    </>
  );
};
const SvgCircle = (props) => {
  const { value } = props;
  return (
    <>
      <svg viewBox="0 0 36 36">
        <text
          className="percent__text"
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="10"
        >
          {value}
          <tspan fontSize="5">%</tspan>
        </text>
        <path
          className="circle-bg"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke="#E6E6E6"
          strokeWidth="3.5"
          fill="none"
        ></path>
        <path
          className="circle"
          strokeDasharray={`${value}, 100`}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={
            value < 80 && value > 0
              ? "#BDBDBD"
              : value > 79
                ? "#40D30D"
                : "transparent"
          }
          strokeWidth="3.5"
          fill="none"
        ></path>
      </svg>
      {props.tooltipText && <div className="svg-tooltip">
        {props.tooltipText}
      </div>}
    </>
  );
};





const LayOut1=(props)=>{

 const {CloseAllDdl}=props; 
//  const [c1Status,setC1Status]=useState('');
//  const [c1Class,setC1Class]=useState('');
//  console.log(props.homeData);

 const homeDataItems = props.homeData;
	if (!homeDataItems.length) {
		return null;
	}
	const homeDataItem = props.homeData[0];
	const courses = homeDataItem.courses;
	const paidcourses = homeDataItem.paidcourses;
	const uid = homeDataItem.uid;
	// const sid = props.sid ? props.sid : homeCurrentItem.id;
	let broughttrainging = 0;
	let avaltrainging = 0;
	courses.map((items, index) => {
		if (items.has_paid == 0) {
			avaltrainging = avaltrainging + 1;
		}
		if (items.has_paid == 1) {
			broughttrainging = broughttrainging + 1;
		}
	});
        
        
       // console.log(paidcourses);

  const industrycourses = paidcourses.map((course, index) => {
		if (index < 1) {
			return (
				<div key={course.id} className="training-card-col">
					<Link
						to={
							homeDataItem.is_first_training == 1
								? "/course-walkthrough"
								: course.cardStatus.toLowerCase() === "quiz pending"
								? homeDataItem.is_first_quiz == 1
									? "/quiz-walkthrough"
									: `/training-quiz/${course.course_slug}`
								: `/training-courses/${course.course_slug}`
						}
					>
						<div className="master_wrapper">
							<div className="training_card_wrapp">
								<div className="trn_img_wrapper">
									<img
										src={require(`../../assets/img/training-card/${course.course_icon}`).default}
										className="course_symb"
										alt={course.course_name}
									/>
									{course.cardStatus && (
										<span
											className={
												course.cardStatus.toLowerCase() === "completed"
													? "ed_status ed_completed"
													: course.cardStatus.toLowerCase() === "quiz pending"
													? "ed_status ed_quiz"
													: course.cardStatus.toLowerCase() === "in progress" && "ed_status ed_inprogress"
											}
										>
											{course.cardStatus}
										</span>
									)}
								</div>
								<p>
									{course.course_name}
									<span className="strt_course_lnk">
										{course.cardStatus.toLowerCase() === "completed"
											? "+ Go to course"
											: course.cardStatus.toLowerCase() === "quiz pending"
											? "+ Start quiz"
											: course.cardStatus.toLowerCase() === "in progress"
											? "+ Continue course"
											: "+ Start course"}
									</span>
								</p>
							</div>
						</div>
					</Link>
				</div>
			);
		}
	});

  // console.log(courses);
  const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
  // useEffect(()=>{
    
    let pit = 0;
     if(props.catpr){
    
      let sum = 0;

    for (let i = 0; i < props.catpr.length; i++) {
        sum += props.catpr[i].catprog;
    }
    let count = props.catpr.length;
    let totalsum = sum;
    let calcperc = (totalsum/(count*100))*100;
    // console.log(calcperc);
    pit= Math.round(calcperc);
     }
  //   var cpr = props.catpr;
  //   cpr.map((item,index)=>{
  //     if(item==0) {
  //      setC1Status('todo');
  //      setC1Clas('deafult')
  //     }
  //     else if(item>0 && item<100) {
  //      setC1Status('inprogress');
  //      setC1Class('orange')
  //     }
  //     else{
  //      setC1Status('completed');
  //      setC1Clas('green')
  //     }

  //   })
   
    
  // },[]);

  return(
    <>
        {/* regular user First time only assigned assessment */}
        <div className="maturity-dashboard-page-row">
          <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn green">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/my-training.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>My Trainings</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft">
                <div className="training-card-row">
                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/training-course.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Training Courses"
                          />
                          {/* <span className="ed_status ed_quiz">Quiz Pending</span> */}
                        </div>
                        <p>
                          Training Courses
                          <Link className="strt_course_lnk" to="/training-walkthrough">
                            + Watch product tour
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  {industrycourses}
                 
                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/available-training-course.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Available training courses"
                          />
                          {/* <span className="ed_status ed_completed">
                          Completed
                        </span> */}
                        </div>
                        <p>
                          Available training courses
                          <a className="strt_course_lnk" href="/training-courses">
                            + See All
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* My training sec end */}
          <div className="maturity-dashboard-sec-1 md-plant w-3">
            <div className="loss-top-tab">
              <span className="tab-type-btn orange">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/plants-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Plants</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    <div className="ms-maturity-ddl">
                      <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                      <button className="btn-link" onClick={() => { CloseAllDdl(); setIsAddPlantPopup(true) }}>Add more plants</button>
                    </div>
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="md_plant_wrap">
              <div className="md-row pb-20">
                <div className="md-col md-lft">
                  <h2 className="h2">  {props.PlantDeatils ? props.PlantDeatils.plant_name : 'Plant Name' }</h2>
                  <div className="md-row">
                    <div className="md-col-4 md-inner-lft">
                      <div className="md-inner-plant-detail">
                        <h2 className="plant-loc">{props.PlantDeatils ? props.PlantDeatils.city_name + ', ' +props.PlantDeatils.country  : 'Location' }</h2>
                        <h2 className="plant-ct">  {props.PlantDeatils ? props.PlantDeatils.plant_size : '' } FTE</h2>
                        <h2 className="plant-admin">Plant Admin:</h2>
                        <h2 className="md_user">{props.PlantDeatils.name} {props.PlantDeatils.surname}</h2>
                      </div>
                    </div>
                    <div className="md-col-8 md-inner-rgt">
                      <div className="md-row">
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col active">
                            <div className="status-with-btn">{/*active*/}
                              <SvgCircle value={pit} tooltipText="Overall Progress"></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Digital Maturity <br />Assessment</h3>
                          </div>
                        </div>
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col">
                            <div className="status-with-btn">
                              <SvgCircle value={0}></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Loss <br /> Analysis</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Plant end */}
          <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/digital-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Digital Maturity Assessment</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>

                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
               
                 { props.maturityData!=0 && props.maturityData.map((matdata,mindex) => {

                   let pStatus =0;
                   if(props.catpr){
                     pStatus= Number(props.catpr[mindex]?.catprog); 
                   }

                  
                   return(
                 <>
              
                  <div className="training-card-col">
                    <div className="loss-analysis-card">
                      <div className="card-body">
                        <div className="img-div">
                        {/* require(`../../assets/img/maturity-dashboard/${matdata.bg_image}`) */}
                          <img
                            src={
                              require(`../../assets/img/maturity-dashboard-adm/${matdata.maturity_img}`)
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>{matdata.category_name}</h2>
                          {/* <Link to={`/assessment/chapter/${props.PlantDeatils.plant_name}`} className="start-section-link" onClick={() => props.setIsAssignSectionPopup(true)}>
                            + Start section
                          </Link> */}
                          <Link to={`/assessment/chapter/${props.PlantDeatils.plant_slug}/${matdata.id}`} className="start-section-link" target="_blank">
                          {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                          </Link>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className={`status ${pStatus==0?'':pStatus>0 && pStatus<100?'orange':'green'}`}>{pStatus==0?'To Do':pStatus>0 && pStatus<100?'In Progress':'completed'}</div>
                        <div className="cir-sec">
                          <span className={`cir cir-${user.sn.slice(0, -1)}`}>
                          {user.sn}
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
               
                 
            </>)}
)}
                </div>
                <div className="md_go-assessment-lnk">
                  <Link className="go-assess-lnk" to={`/assessment/${props.PlantDeatils.plant_slug}`}>
                    Go to Assessment
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
          <div className="maturity-dashboard-sec-1 w-3">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/loss-analysis-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Loss Analysis</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
                  <div className="training-card-col half-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp w-100">
                        <div className="trn_img_wrapper loss_bg">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/loss-label-icon.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Training Courses"
                          />
                          {/* <span className="ed_status ed_quiz">Quiz Pending</span> */}
                        </div>
                        <p>
                          Loss Analysis
                          <Link className="strt_course_lnk" to="/loss-analysis-tour">
                            + Watch product tour
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
        </div>
        
    </>
  )
}
const LayOut2=(props)=>{
  const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
  let losspit = 0;
     if(props.secpr){
    
      let sum = 0;

    for (let i = 0; i < props.secpr.length; i++) {
      if(props.secpr[i].progress!='') {
        // console.log(typeof props.secpr[i].progress);
      sum += Number(props.secpr[i].progress);
         }   // console.log(props.secpr[i].progress);
    }
    let lcount = props.secpr.length;
    let ltotalsum = sum;
    let losscalcperc = (ltotalsum/(lcount*100))*100;
    // console.log(calcperc);
    losspit= Math.round(losscalcperc);
    // console.log(ltotalsum); 
  }
  const {CloseAllDdl}=props;
  return(
    <>
       {/* regular user First time only assigned Loss analysis */}
       <div className="maturity-dashboard-page-row">
          <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn green">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/my-training.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>My Trainings</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft">
                <div className="training-card-row">
                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper bg_green">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/training-course.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Training Courses"
                          />
                          {/* <span className="ed_status ed_quiz">Quiz Pending</span> */}
                        </div>
                        <p>
                          Training Courses
                          <Link className="strt_course_lnk" to="/training-walkthrough">
                            + Watch product tour
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>




                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/industry-ico.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Introduction to Industry 4.0"
                          />
                          <span className="md_status md_todo">To do</span>
                        </div>
                        <p>
                          Introduction to Industry 4.0
                          <a className="strt_course_lnk" href="/training-courses">
                            + Start course
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/available-training-course.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Available training courses"
                          />
                          {/* <span className="ed_status ed_completed">
                          Completed
                        </span> */}
                        </div>
                        <p>
                          Available training courses
                          <a className="strt_course_lnk" href="/training-courses">
                            + See All
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* My training sec end */}
          <div className="maturity-dashboard-sec-1 md-plant w-3">
            <div className="loss-top-tab">
              <span className="tab-type-btn orange">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/plants-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Plants</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    <div className="ms-maturity-ddl">
                      <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                      <button className="btn-link" onClick={() => { CloseAllDdl(); setIsAddPlantPopup(true) }}>Add more plants</button>
                    </div>
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="md_plant_wrap">
              <div className="md-row pb-20">
                <div className="md-col md-lft">
                  <h2 className="h2"> {props.PlantDeatils ? props.PlantDeatils.plant_name : 'Plant Name' }</h2>
                  <div className="md-row">
                    <div className="md-col-4 md-inner-lft">
                      <div className="md-inner-plant-detail">
                      <h2 className="plant-loc">{props.PlantDeatils ? props.PlantDeatils.city_name + ', ' +props.PlantDeatils.country  : 'Location' }</h2>
                        <h2 className="plant-ct">  {props.PlantDeatils ? props.PlantDeatils.plant_size : '' } FTE</h2>
                        <h2 className="plant-admin">Plant Admin:</h2>
                        <h2 className="md_user">{props.PlantDeatils.name} {props.PlantDeatils.surname}</h2>
                      </div>
                    </div>
                    <div className="md-col-8 md-inner-rgt">
                      <div className="md-row">
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col">
                            <div className="status-with-btn">{/*active*/}
                              <SvgCircle value={0} tooltipText="Overall Progress"></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Digital Maturity <br />Assessment</h3>
                          </div>
                        </div>
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col active">
                            <div className="status-with-btn">
                              <SvgCircle value={losspit}></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Loss <br /> Analysis</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Plant end */}
          <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/loss-analysis-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Loss Analysis</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>

                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">

                { props.lossData.length>0 &&  props.lossData.map((lossdata, lindex) =>  
                
                {
                   let pStatus =0;
                  if(props.secpr){
                    // console.log(props.secpr[lindex]?.progress);
                    pStatus= Number(props.secpr[lindex]?.progress); 
                  }
                  
                
                return ( <>
                 
                               
                 
                 
                  <div className="training-card-col">
                    <div className="loss-analysis-card small">
                      <div className="card-body">
                        <div className="img-div">

                        <img
                            src={
                              require(`../../assets/img/loss-analysis-icons/${lossdata.section_img}`).default
                            }
                            alt="Industry 4.0 Vision"
                          />
                          {/* <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-loss-card-img.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          /> */}
                        </div>
                        <div className="txt-div">
                        <h2>{lossdata.section_name}</h2>
                        {/* {`/assessment/chapter/${props.PlantDeatils.plant_slug}/${lossdata.id}`} */}
                    {
                          
                     ((lossdata.id==105) || (lossdata.id==106) || (lossdata.id==104) || (lossdata.id==102) || (lossdata.id==107))?
                          <Link to={`/keyplant/${lossdata.section_slug}/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}`} className="start-section-link" target="_blank">
                          {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                            {/* + Start section */}
                          </Link>
                          :
                          ((lossdata.id==108))?
                          <Link to={`/general-information/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}/6`} className="start-section-link" target="_blank">
                             {/* + Start section */}
                             {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                           </Link>
                           :
                           ((lossdata.id==101))?
                           <Link to={`/lossanalysisques/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}/1`} className="start-section-link" target="_blank">
                              {/* + Start section */}
                              {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                            </Link>
                            :
                          <Link to={`/operator-effort-losses/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}`} className="start-section-link" target="_blank">
                          {/* + Start section */}
                          {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                        </Link>
                        }
                        </div>
                      </div>
                      <div className="card-footer">
                      <div className={`status ${pStatus==0?'':pStatus>0 && pStatus<100?'orange':'green'}`}>{pStatus==0?'To Do':pStatus>0 && pStatus<100?'In Progress':'completed'}</div>
                        <div className="cir-sec">
                          <span className={`cir cir-${user.sn.slice(0,-1)}`}>
                          {user.sn}
                          </span>
                        </div>
                      </div>
                      {/* 
                     */}
                    </div>
                  </div>

</>
                )})}

                  {/* training-card-colend */} </div>
                <div className="md_go-assessment-lnk">
                  <Link className="go-assess-lnk" to={`/lossanalysis/section/${props.PlantDeatils.plant_slug}`}>
                    Go to Loss Analysis
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
          <div className="maturity-dashboard-sec-1 w-3">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/digital-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Digital Maturity Assessment</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
                  <div className="training-card-col half-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp w-100">
                        <div className="trn_img_wrapper loss_bg">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/digital-label-icon-first.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Training Courses"
                          />
                          {/* <span className="ed_status ed_quiz">Quiz Pending</span> */}
                        </div>
                        <p>
                        Digital Maturity
                        <br/> Assessment 
                          <Link className="strt_course_lnk" to="/assessment-tour">
                            + Watch product tour
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
        </div>
        
    </>
  )
}
const LayOut3=(props)=>{
  const {CloseAllDdl}=props;

  const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
// console.log(user);

  let pit = 0;
     if(props.catpr){
    
      let sum = 0;

    for (let i = 0; i < props.catpr.length; i++) {
        sum += props.catpr[i].catprog;
    }
    let count = props.catpr.length;
    let totalsum = sum;
    let calcperc = (totalsum/(count*100))*100;
    // console.log(calcperc);
    pit= Math.round(calcperc);
     }

     let losspit = 0;
     if(props.secpr){
    
      let sum = 0;

    for (let i = 0; i < props.secpr.length; i++) {
      if(props.secpr[i].progress!='') {
        // console.log(typeof props.secpr[i].progress);
      sum += Number(props.secpr[i].progress);
         }   // console.log(props.secpr[i].progress);
    }
    let lcount = props.secpr.length;
    let ltotalsum = sum;
    let losscalcperc = (ltotalsum/(lcount*100))*100;
    // console.log(calcperc);
    losspit= Math.round(losscalcperc);
    // console.log(ltotalsum); 
  }



  return(
    <>
       {/* regular user First time assigned both assessment & Loss analysis sections */}
       <div className="maturity-dashboard-page-row">
        <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/digital-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Digital Maturity Assessment</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>

                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
                
                {props.maturityData!=0 && props.maturityData.map((matdata,mindex) => {

let pStatus =0;
if(props.catpr){
  pStatus= Number(props.catpr[mindex]?.catprog); 
}


return(
<>

<div className="training-card-col">
 <div className="loss-analysis-card">
   <div className="card-body">
     <div className="img-div">
     {/* require(`../../assets/img/maturity-dashboard/${matdata.bg_image}`) */}
       <img
         src={
           require(`../../assets/img/maturity-dashboard-adm/${matdata.maturity_img}`)
             .default
         }
         alt="Industry 4.0 Vision"
       />
     </div>
     <div className="txt-div">
       <h2>{matdata.category_name}</h2>
       {/* <Link to={`/assessment/chapter/${props.PlantDeatils.plant_name}`} className="start-section-link" onClick={() => props.setIsAssignSectionPopup(true)}>
         + Start section
       </Link> */}
       <Link to={`/assessment/chapter/${props.PlantDeatils.plant_slug}/${matdata.id}`} className="start-section-link" target="_blank">
       {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
       </Link>
     </div>
   </div>
   <div className="card-footer">
     <div className={`status ${pStatus==0?'':pStatus>0 && pStatus<100?'orange':'green'}`}>{pStatus==0?'To Do':pStatus>0 && pStatus<100?'In Progress':'completed'}</div>
     <div className="cir-sec">
       <span className={`cir cir-${user.sn.slice(0,-1)}`}>
       {user.sn}
       </span>
     </div>
   </div>
   
 </div>
</div>


</>)}
)}


                  {/* <div className="training-card-col">
                    <div className="loss-analysis-card">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/industry-vision-dm.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Industry 4.0 Vision</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="training-card-col">
                    <div className="loss-analysis-card">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/opex.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Operational Excellence (OpEx)</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="training-card-col">
                    <div className="loss-analysis-card">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/cybersecurity.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Cybersecurity and Data Architecture</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div> */}
                </div>
                <div className="md_go-assessment-lnk mb-12px">
                  <Link className="go-assess-lnk" to={`/assessment/${props.PlantDeatils.plant_slug}`}>
                    Go to Assessment
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
          <div className="maturity-dashboard-sec-1 md-plant w-3">
            <div className="loss-top-tab">
              <span className="tab-type-btn orange">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/plants-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Plants</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    <div className="ms-maturity-ddl">
                      <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                      <button className="btn-link" onClick={() => { CloseAllDdl(); setIsAddPlantPopup(true) }}>Add more plants</button>
                    </div>
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="md_plant_wrap">
              <div className="md-row pb-20">
                <div className="md-col md-lft">
                <h2 className="h2">  {props.PlantDeatils ? props.PlantDeatils.plant_name : 'Plant Name' }</h2>
                  {/* <h2 className="h2">Nescafe's plant</h2> */}
                  <div className="md-row">
                    <div className="md-col-4 md-inner-lft">
                      <div className="md-inner-plant-detail">
                      <h2 className="plant-loc">{props.PlantDeatils ? props.PlantDeatils.city_name + ', ' +props.PlantDeatils.country  : 'Location' }</h2>
                        <h2 className="plant-ct">  {props.PlantDeatils ? props.PlantDeatils.plant_size : '' } FTE</h2>
                        <h2 className="plant-admin">Plant Admin:</h2>
                        <h2 className="md_user">{props.PlantDeatils.name} {props.PlantDeatils.surname}</h2>
                        {/* <h2 className="plant-loc">London, UK</h2>
                        <h2 className="plant-ct"> 1000 FTE</h2>
                        <h2 className="plant-admin">Plant Admin:</h2>
                        <h2 className="md_user">George Kapor</h2> */}
                      </div>
                    </div>
                    <div className="md-col-8 md-inner-rgt">
                      <div className="md-row">
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col active">
                            <div className="status-with-btn">{/*active*/}
                              <SvgCircle value={pit} tooltipText="Overall Progress"></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Digital Maturity <br />Assessment</h3>
                          </div>
                        </div>
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col active">
                            <div className="status-with-btn">
                              <SvgCircle value={losspit}></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Loss <br /> Analysis</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Plant end */}
          <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/loss-analysis-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Loss Analysis</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>

                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
                 
                { props.lossData.length>0 &&  props.lossData.map((lossdata, lindex) =>  
                
                {
                   let pStatus =0;
                  if(props.secpr){
                    console.log(props.secpr[lindex]?.progress);
                    pStatus= Number(props.secpr[lindex]?.progress); 
                  }
                  
                
                return ( <>
                 
                               
                 
                 
                  <div className="training-card-col">
                    <div className="loss-analysis-card small">
                      <div className="card-body">
                        <div className="img-div">

                        <img
                            src={
                              require(`../../assets/img/loss-analysis-icons/${lossdata.section_img}`).default
                            }
                            alt="Industry 4.0 Vision"
                          />
                          {/* <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-loss-card-img.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          /> */}
                        </div>
                        <div className="txt-div">
                        <h2>{lossdata.section_name}</h2>
                        {/* {`/assessment/chapter/${props.PlantDeatils.plant_slug}/${lossdata.id}`} */}
                    {
                          
                     ((lossdata.id==105) || (lossdata.id==106) || (lossdata.id==104) || (lossdata.id==102) || (lossdata.id==107))?
                          <Link to={`/keyplant/${lossdata.section_slug}/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}`} className="start-section-link" target="_blank">
                          {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                            {/* + Start section */}
                          </Link>
                          :
                          ((lossdata.id==108))?
                          <Link to={`/general-information/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}/6`} className="start-section-link" target="_blank">
                             {/* + Start section */}
                             {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                           </Link>
                           :
                           ((lossdata.id==101))?
                           <Link to={`/lossanalysisques/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}/1`} className="start-section-link" target="_blank">
                              {/* + Start section */}
                              {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                            </Link>
                            :
                          <Link to={`/operator-effort-losses/questionaire/${props.PlantDeatils.plant_slug}/${lossdata.id}`} className="start-section-link" target="_blank">
                          {/* + Start section */}
                          {pStatus==0?'+ Start section':pStatus>0 && pStatus<100?'+ Continue Section':'+ View Section'}
                        </Link>
                        }
                        </div>
                      </div>
                      <div className="card-footer">
                      <div className={`status ${pStatus==0?'':pStatus>0 && pStatus<100?'orange':'green'}`}>{pStatus==0?'To Do':pStatus>0 && pStatus<100?'In Progress':'completed'}</div>
                        <div className="cir-sec">
                          <span className={`cir cir-${user.sn.slice(0,-1)}`}>
                          {user.sn}
                          </span>
                        </div>
                      </div>
                      {/* 
                     */}
                    </div>
                  </div>

</>
                )})}

                  {/* <div className="training-card-col">
                    <div className="loss-analysis-card small">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-loss-card-img.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Unplanned Downtime</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  
                  <div className="training-card-col">
                    <div className="loss-analysis-card small">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-loss-card-img.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Unplanned Downtime</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="training-card-col">
                    <div className="loss-analysis-card small">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-loss-card-img.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Unplanned Downtime</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                     
                    </div>
                  </div> */}
                 
                 
                </div>
                <div className="md_go-assessment-lnk">
                  <Link className="go-assess-lnk" to={`/lossanalysis/section/${props.PlantDeatils.plant_slug}`}>
                    Go to Loss Analysis
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
          <div className="maturity-dashboard-sec-1 w-3">
          <div className="loss-top-tab">
              <span className="tab-type-btn green">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/my-training.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>My Trainings</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
                  <div className="training-card-col half-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper bg_green w-100">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-my-train.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Training Courses"
                          />
                          {/* <span className="ed_status ed_quiz">Quiz Pending</span> */}
                        </div>
                        <p>
                        Training Courses
                          <Link className="strt_course_lnk" to="/training-walkthrough">
                            + Watch product tour
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* col-1 end */}
                  <div className="training-card-col half-col pl-0">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp w-100">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/industry-ico.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Introduction to Industry 4.0"
                          />
                          <span className="md_status md_todo">To do</span>
                        </div>
                        <p>
                          Introduction to Industry 4.0
                          <a className="strt_course_lnk" href="/training-courses">
                            + Start course
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                 
                {/* col end */}
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
        </div>        
    </>
  )
}
const LayOut4=(props)=>{
  const {CloseAllDdl}=props;
  return(
    <>
       {/* regular user First time assigned both assessment & Loss analysis sections + plus training courses */}
       <div className="maturity-dashboard-page-row">
          <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn green">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/my-training.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>My Trainings</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft">
                <div className="training-card-row">
                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/training-course.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Training Courses"
                          />
                          {/* <span className="ed_status ed_quiz">Quiz Pending</span> */}
                        </div>
                        <p>
                          Training Courses
                          <Link className="strt_course_lnk" to="/training-walkthrough">
                            + Watch product tour
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/industry-ico.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Introduction to Industry 4.0"
                          />
                          <span className="md_status md_todo">To do</span>
                        </div>
                        <p>
                          Introduction to Industry 4.0
                          <a className="strt_course_lnk" href="/training-courses">
                            + Start course
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="training-card-col">
                    <div className="master_wrapper">
                      <div className="training_card_wrapp">
                        <div className="trn_img_wrapper">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/available-training-course.svg")
                                .default
                            }
                            className="course_symb"
                            alt="Available training courses"
                          />
                          {/* <span className="ed_status ed_completed">
                          Completed
                        </span> */}
                        </div>
                        <p>
                          Available training courses
                          <a className="strt_course_lnk" href="/training-courses">
                            + See All
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md_go-assessment-lnk my-0"><a className="go-assess-lnk" href="/assessment">Go to Assessment</a></div>
              </div>
            </div>
          </div>
          {/* My training sec end */}
          <div className="maturity-dashboard-sec-1 md-plant w-3">
            <div className="loss-top-tab">
              <span className="tab-type-btn orange">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/plants-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Plants</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    <div className="ms-maturity-ddl">
                      <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                      <button className="btn-link" onClick={() => { CloseAllDdl(); setIsAddPlantPopup(true) }}>Add more plants</button>
                    </div>
                  </div>


                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="md_plant_wrap flex-1">
              <div className="md-row pb-20">
                <div className="md-col md-lft">
                  <h2 className="h2">Nescafe's plant</h2>
                  <div className="md-row">
                    <div className="md-col-4 md-inner-lft">
                      <div className="md-inner-plant-detail">
                        <h2 className="plant-loc">London, UK</h2>
                        <h2 className="plant-ct"> 1000 FTE</h2>
                        <h2 className="plant-admin">Plant Admin:</h2>
                        <h2 className="md_user">George Kapor</h2>
                      </div>
                    </div>
                    <div className="md-col-8 md-inner-rgt">
                      <div className="md-row">
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col active">
                            <div className="status-with-btn">{/*active*/}
                              <SvgCircle value={0} tooltipText="Overall Progress"></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Digital Maturity <br />Assessment</h3>
                          </div>
                        </div>
                        <div className="md-col-6">
                          <div className="md-inner-rgt-col">
                            <div className="status-with-btn">
                              <SvgCircle value={0}></SvgCircle>
                              <div className="ms-submit-tooltip" style={{ display: 'none' }}>
                                <p>The Assessment is completed!</p>
                                <button className="btn-submit">Submit</button>
                              </div>
                              <div className="completed-status-div">
                                <span className="ms-status">Completed</span>
                                <button className="btn-view-res">View Results</button>
                              </div>
                            </div>
                            <h3 className="h3">Loss <br /> Analysis</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Plant end */}
          <div className="maturity-dashboard-sec-1 w-7">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/digital-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Digital Maturity Assessment</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>

                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
                  <div className="training-card-col">
                    <div className="loss-analysis-card">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/opex.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Operational Excellence (OpEx)</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="training-card-col">
                    <div className="loss-analysis-card">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/opex.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Operational Excellence (OpEx)</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="training-card-col">
                    <div className="loss-analysis-card">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/cybersecurity.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Cybersecurity and Data Architecture</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="md_go-assessment-lnk mb-12px">
                  <Link className="go-assess-lnk" to={`/assessment/${props.PlantDeatils.plant_slug}`}>
                    Go to Assessment
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
          <div className="maturity-dashboard-sec-1 w-3">
            <div className="loss-top-tab">
              <span className="tab-type-btn blue">
                <p>
                  <img
                    src={
                      require("../../assets/img/maturity-dashboard/loss-analysis-label-icon.svg")
                        .default
                    }
                    alt="true"
                  />
                  <span>Loss Analysis</span>
                </p>
                <div className="maturity-d-rgt-action-icon">
                  <div className="ms-maturity-d-more">
                    <button className="btn-dots" onClick={(e) => HandleDdl(e)}>
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        className="maturity-d-more-icon"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                          fill="#78909C"
                        />
                      </svg>
                    </button>
                    {/*<div className="ms-maturity-ddl">
                                                   <button className="btn-link" onClick={CloseAllDdl}>Edit Plant Admin</button>
                                                   <button className="btn-link" onClick={CloseAllDdl}>Add more plants</button>
                                             </div> */}
                  </div>

                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    className="maturity-d-toggle-icon"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 2.83L8.17 6L9.58 4.59L5 0L0.410004 4.59L1.83 6L5 2.83ZM5 15.17L1.83 12L0.420004 13.41L5 18L9.59 13.41L8.17 12L5 15.17Z"
                      fill="#78909C"
                    />
                  </svg>
                </div>
              </span>
            </div>
            {/* loss-top-tab end */}
            <div className="maturity-dashboard-row pb-20">
              <div className="maturity-dashboard-lft pl-14rem">
                <div className="training-card-row">
                  <div className="training-card-col half-col">
                    <div className="loss-analysis-card small  w-100">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-loss-card-img.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Unplanned Downtime</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      {/* 
                     */}
                    </div>
                  </div>
                  {/* training-card-colend */}
                  <div className="training-card-col half-col">
                    <div className="loss-analysis-card small w-100">
                      <div className="card-body">
                        <div className="img-div">
                          <img
                            src={
                              require("../../assets/img/maturity-dashboard/md-loss-card-img.svg")
                                .default
                            }
                            alt="Industry 4.0 Vision"
                          />
                        </div>
                        <div className="txt-div">
                          <h2>Unplanned Downtime</h2>
                          <span className="start-section-link">
                            + Start section
                          </span>
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="status ">to do</div>
                        <div className="cir-sec">
                          <span className="cir cir-c">
                            cb
                          </span>
                        </div>
                      </div>
                      {/* 
                     */}
                    </div>
                  </div>
                  {/* training-card-colend */}
                </div>
                <div className="md_go-assessment-lnk loss-my">
                  <Link className="go-assess-lnk" to="/assessment">
                    Go to Loss Analysis
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* loss-analysis-sec-1 end */}
        </div>
        
    </>
  )
}