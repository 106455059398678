import React, { useRef, useState } from "react";
import serviceProvider from "../../services/axios";
import {
    PopupContainer,
    PopupCard,
    PopupCardHeader,
    PopupCardBody,
    HeaderH1,
    PopupCloseButton,
    HeaderButtons,
    PopupCardBodyRow,
    PopupCardLeftCol,
    PopupCardRightCol,
    PopupCardLeftHead,
    PopupCardLeftFooter,
    CenteredPopupCard,
    CenteredPopupFooter,
    CenteredPopupCancelButton,
    CenteredPopupProceedlButton,
    Loader,
} from './upload-styles'
import { closeSvg, browseSvg, uploadCloseSvg, tickSvg } from "./upload-pop-svg";
export const UploadFilePopView = (props) => {
    const {handleClosePopup=()=>{},plant,base_url} = props;
    const pid = plant.id;
    const [selectedFile, setSelectedFile] = useState({"name":plant?.siri_files || null,"size":plant?.siri_size || null});
    const [isPublished, setIsPublished] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [pdfSrc, setPdfSrc] = useState(plant?.siri_files ? `${base_url}assets/siri-files/${plant.siri_files}`:null);
    const [error,seErrorMsg] = useState('');
    const fileInputRef = useRef(null);
    const [progress,setProgress] = useState(0);
    const showSize = (fileSizeInBytes) => {
        if (fileSizeInBytes < 1024 * 1024) {
            // If size is less than 1 MB, display in kilobytes
            return (fileSizeInBytes / 1024).toFixed(2) + ' KB';
        } else {
            // If size is 1 MB or greater, display in megabytes
            return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setProgress(prv=>true);
        if (file && file.type === 'application/pdf') {
            setPdfSrc(url);
            setSelectedFile(file);
            setIsPublished(prv=>1);
        } else {
            seErrorMsg('Please select a valid PDF file.');
        }
        setProgress(prv=>false);
    };

    const handleDelete = () => {
        fileInputRef.current.value = '';
        setSelectedFile(null);
        setPdfSrc(null);
        setIsDelete(false);
        const formData = new FormData();
        formData.append("pid",pid);
           
        serviceProvider
            .post("/profile?type=deletesirifile", formData, true)
            .then((response) => {
                props.setsetuppage(prv=>!prv);
            });
    }

    const closeMainPopup = () => {
        handleClosePopup();
    }

    const publishFunction = (val)=>{
        setIsPublished(prv=>val);
        setTimeout(()=>{setIsPublished(prv=>false); closeMainPopup();},2000);

         //service provider
            const user = JSON.parse(localStorage.getItem("userData"));
            const uid = user ? user.uid : 0;
            const formData = new FormData();
            formData.append("pid",pid);
            formData.append("uid", uid);
            formData.append("profilePic", selectedFile, selectedFile.name);
            serviceProvider
            .post("/profile?type=updatesirifile", formData, true)
            .then((response) => {
                props.setsetuppage(prv=>!prv);
            });

            //service provider

    }

    // 08-01-2024
    return (
        <PopupContainer data-component="PopupContainer">
            <PopupCard data-component="PopupCard">
                <PopupCardHeader data-component="PopupCardHeader">
                    <HeaderH1 data-component="HeaderH1">
                        <span>Upload Report</span>
                    </HeaderH1>
                    <HeaderButtons data-component="HeaderButtons">
                        <PopupCloseButton data-component="PopupCloseButton" onClick={closeMainPopup}>
                            <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </PopupCloseButton>
                    </HeaderButtons>
                </PopupCardHeader>
                <PopupCardBody data-component="PopupCardBody">
                    <PopupCardBodyRow data-component="PopupCardBodyRow">
                        <PopupCardLeftCol data-component="PopupCardLeftCol">
                            <PopupCardLeftHead data-component="PopupCardLeftHead">
                                <span className="upload-title">Upload file</span>
                                {progress ? <Loader/>:''}
                                {selectedFile?.name ?
                                    <>
                                        <div className="uploaded-content">
                                            <i dangerouslySetInnerHTML={{ __html: browseSvg }}></i>
                                            <p className="right-content">
                                                <span className="file-name">{selectedFile.name}</span>
                                                <span className="file-size">{showSize(selectedFile.size)}</span>
                                            </p>
                                            <button className="close-btn" onClick={() => setIsDelete(true)}>
                                                <i dangerouslySetInnerHTML={{ __html: uploadCloseSvg }}></i>
                                            </button>
                                        </div>
                                        <div className="input-wrap">
                                            <input type="file" accept=".pdf" id="upload" ref={fileInputRef} onChange={handleFileChange} />
                                            <label className="upload-btn" htmlFor="upload">
                                                Re-upload Report
                                            </label>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span className="upload-text">Upload a PDF File</span>
                                        <div className="input-wrap">
                                            <input type="file" accept=".pdf" id="upload" ref={fileInputRef} onChange={handleFileChange} />
                                            <label className="upload-btn" htmlFor="upload">
                                                Upload Report
                                            </label>
                                        </div>
                                    </>

                                }

                            </PopupCardLeftHead>
                            <PopupCardLeftFooter data-component="PopupCardLeftFooter">
                                {selectedFile &&
                                    <>
                                        {isPublished === 0 ?
                                            <button className="published-btn">
                                                <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>
                                                <span>Report Published</span>
                                            </button>
                                            :
                                            isPublished === 1 ?
                                                <button className="publish-btn" onClick={() => publishFunction(0)}>Publish Report</button>
                                                :
                                                ''
                                        }
                                    </>
                                }
                            </PopupCardLeftFooter>
                        </PopupCardLeftCol>
                        <PopupCardRightCol data-component="PopupCardRightCol">
                            <span className="right-head-text">Preview</span>
                            {selectedFile?.name ?
                                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                        <div style={{ position: 'relative', width: '100%', flex: 1, border: '0.05rem solid #4d4d4f', borderBottom: '0' }}>
                                            <div id="pdf-viewer" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                                                {pdfSrc && (
                                                    <object data={pdfSrc} type="application/pdf" width="100%" height="100%">
                                                        <p>This browser does not support PDFs. Please download the PDF to view it.</p>
                                                    </object>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="no-preview-box">
                                        <div className="no-preview-wrap">
                                            <span>No file uploaded</span>
                                        </div>
                                    </div>
                            }
                        </PopupCardRightCol>
                        {isDelete &&
                            <CenteredPopupCard data-component="CenteredPopupCard">
                                <h2 className="centered-popup-h2">
                                    Are you sure to delete Report?
                                </h2>
                                <p className="centered-popup-p">
                                    The published report will not be available and will be lost permanently
                                </p>
                                <CenteredPopupFooter data-component="CenteredPopupFooter">
                                    <CenteredPopupCancelButton data-component="CenteredPopupCancelButton" onClick={() => setIsDelete(false)}>
                                        Cancel
                                    </CenteredPopupCancelButton>
                                    <CenteredPopupProceedlButton data-component="CenteredPopupProceedlButton" onClick={() => handleDelete()}>
                                        Yes, delete
                                    </CenteredPopupProceedlButton>
                                </CenteredPopupFooter>
                            </CenteredPopupCard >
                        }
                    </PopupCardBodyRow>
                </PopupCardBody>
            </PopupCard>
        </PopupContainer>
    );
}

