/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * Plyr for ==> plugin for video player.
  * Collapse for ==> collapse animation for elements.
  * Modal for ==> modal popup.
  * pure-react-carousel for ==> carousel.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.

**/
import React, { useState } from "react";
import Plyr from "plyr-react";
import serviceProvider from "../../services/axios";
import "plyr-react/dist/plyr.css";
import { Collapse, Modal } from "@material-ui/core";
import {
  CarouselProvider,
  Dot,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { Link } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
// FrameworkView Start
export const FrameworkView = (props) => {
  // defining variables here
  const [isCirTooltip, setIsCirTooltip] = useState(false);
  const [currentContent, setCurrentContent] = useState(false);
  const [isvideoPopup, setIsvideoPopup] = useState(false);
  const [isSliderIndex, setIsSliderIndex] = useState(0);
  const [isAllCourse, setIsAllCourse] = useState(false);
  const [isRightSec, setIsRightSec] = useState(false);
  const [open, setOpen] = useState(1);
  const homeDataItems = props.homeData;
  if (!homeDataItems.length) {
    return null;
  }
  const homeDataItem = props.homeData[0];
  const courses = homeDataItem.courses;
  const uid = homeDataItem.uid;
  // This Page

  // Function for Handle Circle Tooltip
  const HandleCirTooltip = () => {
    setIsCirTooltip(true);
  };
  // Function for Handle Current Content
  const HandleCurrentContent = (indx) => {
    setCurrentContent(true);
    if (currentContent === indx) {
      // setCurrentContent(null)
    } else {
      // setCurrentContent(indx)
    }
  };
  // Function Handle All Course View
  const HandleAllCourseView = () => {
    setIsAllCourse(!isAllCourse);
  };
  // Function Handle Video Popup
  const HandleVideoPopup = () => {
    setIsvideoPopup(!isvideoPopup);
    serviceProvider
      .post("/home?type=setindustry", JSON.stringify({ uid: uid }), true)
      .then((response) => {
        props.onTourComplete("industry");
      });
  };
  // Function of handle Index
  const handleIndex = (e) => {
    if (e === "add" && isSliderIndex < 4) {
      let tmp = 1 + isSliderIndex;
      setIsSliderIndex(tmp);
    }
    if (e === "subs" && isSliderIndex > 0) {
      let tmp = isSliderIndex - 1;
      setIsSliderIndex(tmp);
    }
  };
  // Function for Handle Open State
  const HandleOpenState = (index) => {
    if (index === open) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  };
  // Function for Handle Right Sec
  const HandleRightSec = () => {
    setIsRightSec(!isRightSec);
  };
  return (
    <>
      {/* 
	  	Container devide in 2 major section 
			1. Methodology Overview
			2. Factory of the Future Framework
	  */}
      <div className="home-index main-course-page learning-resources">
        <div className="intro">
          <h1>Factory of the Future Framework</h1>
          <p>
            SmarterChains developed a 4 step Journey to guide you in a systemic
            and
            <br /> easy to understand way to create your Factory of the Future.
          </p>
        </div>
        {/* Methodology Overview section start */}
        <div className="methodology-overview">
          <h2>Methodology Overview</h2>
          <span>By Phases</span>
          <div className="Phases-section">
            <div className="phase-box educate">
              <figure>
                <img
                  src={
                    require("../../assets/img/learning/educate-icon.svg")
                      .default
                  }
                  alt="educate-icon"
                />
              </figure>
              <h3>Educate</h3>
              <label>Step 1</label>
              <div className="info-sec">
                <div className="frame_para">
                  <p>Learn about game-changing Industry 4.0 solutions</p>
                </div>
                <ul>
                  <li>
                    <h4>Learn</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-training-courses"
                    >
                      IR4.0 Training Courses
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                  <li style={{visibility:'hidden'}}>
                    <h4>Upskill</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-academia"
                    >
                      Academia
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="you-here">
                <button type="button">You are here</button>
              </div>
            </div>
            <div className="phase-box prepare">
              <figure>
                <img
                  src={
                    require("../../assets/img/learning/prepare-icon.svg")
                      .default
                  }
                  alt="prepare-icon"
                />
              </figure>
              <h3>Prepare</h3>
              <label>Step 2</label>
              <div className="info-sec">
                <div className="frame_para">
                  <p>
                    Learn about how you benchmark and where are the
                    opportunities
                  </p>
                </div>
                <ul>
                  <li>
                    <h4>measure</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-assessment"
                    >
                        IR4.0 Technology Assessment and Benchmarking
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <h4>analyse</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-loss-analysis"
                    >
                      IR4.0 Loss Analysis
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="you-here" style={{ display: "none" }}>
                <button type="button">You are here</button>
              </div>
            </div>
            <div className="phase-box define">
              <figure>
                <img
                  src={
                    require("../../assets/img/learning/define-icon.svg").default
                  }
                  alt="prepare-icon"
                />
              </figure>
              <h3>Define</h3>
              <label>Step 3</label>
              <div className="info-sec">
                <div className="frame_para">
                  <p>
                    Learn how to create value exponentially and comprehensively
                  </p>
                </div>
                <ul>
                  <li>
                    <h4>plant &amp; enterprise strategy</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-roadmap"
                    >
                      IR4.0 Strategy and Business Case
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <h4>vendors</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-vendor-solutions"
                    >
                      Vendor Solutions
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="you-here" style={{ display: "none" }}>
                <button type="button">You are here</button>
              </div>
            </div>
            <div className="phase-box execute">
              <figure>
                <img
                  src={
                    require("../../assets/img/learning/execute-icon.svg")
                      .default
                  }
                  alt="prepare-icon"
                />
              </figure>
              <h3>Execute</h3>
              <label>Step 4</label>
              <div className="info-sec">
                <div className="frame_para">
                  <p>Learn to execute effectively and systematically</p>
                </div>
                <ul style={{ display: "none" }}>
                  <li>
                    <h4>masterplan</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-masterplanner"
                    >
                      Masterplanner Program Management
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <h4>brainstorm</h4>
                    <a
                      target="_blank"
                      href="https://www.smarterchains.com/industry-40-user-stories"
                    >
                      User Stories
                      <img
                        src={
                          require("../../assets/img/learning/info-icon.svg")
                            .default
                        }
                        alt="info-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="you-here" style={{ display: "none" }}>
                <button type="button">You are here</button>
              </div>
            </div>
          </div>
        </div>
        {/* Factory of the Future Framework start */}
        <div className="lr-second-view">
          <h3>Factory of the Future Framework</h3>
          <div className="detail-area">
            <div className="detail-area-left">
              <ul>
                <li>
                  <figure className="frame-educate">
                    <img
                      src={
                        require("../../assets/img/learning/educate-green.svg")
                          .default
                      }
                      alt="educate"
                    />
                  </figure>
                  <h4>1. Educate</h4>
                  <p>
                    your team with our online Industry 4.0 Training Courses and
                    bring your people to the same level of understanding to
                    embrace and lead the change.
                  </p>
                </li>
                <li>
                  <figure className="prepare">
                    <img
                      src={
                        require("../../assets/img/learning/prepare-icon.svg")
                          .default
                      }
                      alt="prepare"
                    />
                  </figure>
                  <h4>2. Prepare</h4>
                  <p>
                    your digital transformation by measuring your digital
                    maturity and benchmark your capabilities. Obtain a 360° view
                    of your plants’ operations loss drivers.
                  </p>
                </li>
                <li>
                  <figure className="define">
                    <img
                      src={
                        require("../../assets/img/learning/define-icon.svg")
                          .default
                      }
                      alt="Define"
                    />
                  </figure>
                  <h4>3. Define</h4>
                  <p>
                    your plant and enterprise strategy for Industry 4.0, and
                    determine the value and implementation characteristics.
                    Discover the Industry 4.0 Vendor Marketplace.
                  </p>
                </li>
                <li>
                  <figure className="execute">
                    <img
                      src={
                        require("../../assets/img/learning/execute-icon.svg")
                          .default
                      }
                      alt="execute"
                    />
                  </figure>
                  <h4>4. Execute</h4>
                  <p>
                    your digital transformation strategy, scale learnings across
                    the enterprise and drive internal adoption.​
                  </p>
                </li>
              </ul>
            </div>
            <div className="detail-area-right">
              <img
                src={
                  require("../../assets/img/learning/methodology-4-step.svg")
                    .default
                }
                alt="methodology-4-step"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Quick start section for platform tour & walkthroughs */}
      <section
        className={isRightSec ? "home-index-right active" : "home-index-right"}
      >
        <div className="card">
          <header>
            <h2 className="h1">SmarterChains Quickstart</h2>
            <Progress value={homeDataItem.tourpercentage} />
            <button className="close-btn" onClick={HandleRightSec}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-gray.svg")
                    .default
                }
                alt="close"
              />
            </button>
          </header>
          <section>
            <ListCard
              index={1}
              HandleOpenState={HandleOpenState}
              open={open}
              isCompleted={homeDataItem.platform_tour == 1 ? true : false}
              headerTitle="Watch platform walkthrough"
            >
              <div className="list-card-content type_1">
                <p>
                  Accelerate your Industry 4.0 capabilities! Learn more about
                  SmarterChains platform in this quick tour.
                </p>
                <div className="type-1-card">
                  <div className="type-1-col-1 quikstart-1">
                    <img
                      src={
                        require("../../assets/img/home-index/quikstart-1.svg")
                          .default
                      }
                      alt="true"
                    />
                  </div>
                  <div className="type-1-col-2">
                    <div className="top_content">
                      <p>Learn more about SmarterChains Platform</p>
                    </div>
                    <div className="bottom_content">
                      <button>+ Watch platform tour</button>
                    </div>
                  </div>
                </div>
              </div>
            </ListCard>

            <ListCard
              index={2}
              HandleOpenState={HandleOpenState}
              open={open}
              isCompleted={homeDataItem.industry_tour == 1 ? true : false}
              headerTitle="Learn about Industry 4.0 "
            >
              <div className="list-card-content type_1">
                <p className="lai40-text">
                  Check SmarterChains CEO teaching
                  <span className="b"> key Industry 4.0 insights</span> to IMD
                  Digital Supply Chain, one of the world's top digital Supply
                  Chain Executive courses.
                </p>
                <button className="lai40-btn" onClick={HandleVideoPopup}>
                  <img
                    src={
                      require("../../assets/img/home-index/quikstart-2.svg")
                        .default
                    }
                    alt="true"
                  />
                </button>
              </div>
            </ListCard>
            <ListCard
              index={3}
              HandleOpenState={HandleOpenState}
              open={open}
              isCompleted={homeDataItem.training_tour == 1 ? true : false}
              headerTitle="Learn about Industry 4.0 Training Courses"
            >
              <div className="list-card-content type_1">
                <p>
                  Accelerate your Industry 4.0 capabilities! Learn more about
                  SmarterChains platform in this quick tour.
                </p>
                <div className="type-1-card">
                  <div className="type-1-col-1 quikstart-3">
                    <img
                      src={
                        require("../../assets/img/home-index/quikstart-3.svg")
                          .default
                      }
                      alt="true"
                    />
                  </div>
                  <div className="type-1-col-2">
                    <div className="top_content">
                      <p className="dull">educate</p>
                    </div>
                    <div className="bottom_content">
                      <p>Industry 4.0 Training Courses</p>
                      <Link to="/training-courses/introduction-to-industry-40#walkthrough">
                        + Watch platform tour
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </ListCard>
            <ListCard
              index={4}
              HandleOpenState={HandleOpenState}
              open={open}
              isCompleted={homeDataItem.assessment_tour == 1 ? true : false}
              headerTitle="Learn about Digital Maturity Assessment"
            >
              <div className="list-card-content type_1">
                <p>
                  See how your plant scores! Learn more about our Digital
                  Maturity Assessment in this product tour.
                </p>

                <div className="type-1-card">
                  <div className="type-1-col-1 quikstart-4">
                    <img
                      src={
                        require("../../assets/img/home-index/quikstart-4.svg")
                          .default
                      }
                      alt="true"
                    />
                  </div>
                  <div className="type-1-col-2">
                    <div className="top_content">
                      <p className="dull">prepare</p>
                    </div>
                    <div className="bottom_content">
                      <p>Digital Maturity Assessmen</p>
                      <button>+ Watch platform tour</button>
                    </div>
                  </div>
                </div>
              </div>
            </ListCard>
            <ListCard
              index={5}
              HandleOpenState={HandleOpenState}
              open={open}
              isCompleted={homeDataItem.business_tour == 1 ? true : false}
              headerTitle="Learn about Loss Analysis"
            >
              <div className="list-card-content type_1">
                <p>
                  Identify and track losses! Learn more about our Industry 4.0
                  Loss Analysis in this product tour.
                </p>
                <div className="type-1-card">
                  <div className="type-1-col-1 quikstart-4">
                    <img
                      src={
                        require("../../assets/img/home-index/quikstart-5.svg")
                          .default
                      }
                      alt="true"
                    />
                  </div>
                  <div className="type-1-col-2">
                    <div className="top_content">
                      <p className="dull">prepare</p>
                    </div>
                    <div className="bottom_content">
                      <p>Industry 4.0 Loss Analysis</p>
                      <button>+ Watch platform tour</button>
                    </div>
                  </div>
                </div>
              </div>
            </ListCard>
          </section>
        </div>
      </section>
    </>
  );
};
// Function for Progress bar status
const Progress = ({ value }) => {
  return (
    <div className="progress__wrapper">
      <div
        className="progress_status"
        style={{ width: `${value < 100 ? value : value + 0.5}%` }}
      ></div>
    </div>
  );
};
// Function for List card
const ListCard = (props) => {
  const {
    open,
    index,
    isCompleted,
    headerTitle,
    children,
    HeaderComponent,
    HandleOpenState,
  } = props;
  return (
    <div className="list-card">
      <div className={open === index ? "header active" : "header"}>
        {!HeaderComponent ? (
          <div className="header-row">
            <div className="col_1">
              {isCompleted ? (
                <img
                  src={
                    require("../../assets/img/home-index/completed-chk.svg")
                      .default
                  }
                  alt="true"
                />
              ) : (
                <img
                  src={
                    require("../../assets/img/home-index/uncompleted-chk.svg")
                      .default
                  }
                  alt="true"
                />
              )}
            </div>
            <div className="col_2">
              <p className="h1">{headerTitle}</p>

              <button
                className="ddl-btn"
                onClick={() => HandleOpenState(index)}
              >
                <img
                  src={
                    require("../../assets/img/home-index/ddl-arrow.svg").default
                  }
                  alt="true"
                />
              </button>
            </div>
          </div>
        ) : (
          <HeaderComponent />
        )}
      </div>
      {/* header end */}
      <Collapse in={props.open == props.index}>
        <>{children}</>
      </Collapse>
    </div>
  );
};
