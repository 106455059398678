/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * Plyr ===> third party plugin
  * FacebookShareButton, LinkedinShareButton, TwitterShareButton ===> component from react-share 

**/
import React, { Component, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { HandlePageOnClick } from "../../webutils/webutils";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import serviceProvider from "../../services/axios";
import { apiEndpoints } from '../../services/api-endpoints';
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import $ from "jquery";
// training progressbar
const TrainProgressBar = (props) => {
  return (
    <>
      {/* this section has
				t__progressbar__left
				t__progressbar__right
				t__progressbar__space
			*/}
      <div className="t__progressbar__wrapper">
        {/* this section has text */}
        <div className="t__progressbar__left">
          <p>Progress:</p>
        </div>
        {/* this section has progressbar */}
        <div className="t__progressbar__right">
          <div className="t__progress__relative">
            <div
              className={
                props.value < 100 && props.value > 0
                  ? "t__progress__status__wrapper progress"
                  : props.value === 100
                    ? "t__progress__status__wrapper completed"
                    : "t__progress__status__wrapper not__start"
              }
            >
              <div
                className="t__progress__status"
                style={{ width: `${props.value}%` }}
              ></div>
            </div>
            <div className="t__progress__txt">{props.value}%</div>
          </div>
        </div>
        {/* this section is for spacing */}
        <div className="t__progressbar__space"></div>
        {/* this section has text */}
        <div className="t__progressbar__left">
          <p>
            Done:<span className="b">{props.doneTime}</span>
          </p>
        </div>
        {/* this section has time */}
        <div className="t__progressbar__right">
          <p>
            Left:<span className="b">{props.leftTime}</span>
          </p>
        </div>
      </div>
    </>
  );
};

// content list component
const ContentList = (props) => {
  return (
    <a
      href={
        props.status !== "none"
          ? `/training-courses/${props.course_slug}/${props.section_slug}`
          : "javascript:void(0)"
      }
      onClick={props.callBackFunction}
    >
      {/* this section has
				course icon
				course text
			*/}
      <li className="course__inner__li">
        <div className="course__inner__li__icon">
          <div className="course__inner__li__icon">
            {props.status === "play" ? (
              <img
                src={
                  require("../../assets/img/course-overview/vid_play.svg")
                    .default
                }
                className="vid__play"
                alt="vid_play_img"
              />
            ) : props.status === "complete" ? (
              <img
                src={
                  require("../../assets/img/course-overview/vid_completed.svg")
                    .default
                }
                className="vid-completed"
                alt="vid_completed_img"
              />
            ) : props.status === "unlock" ? (
              <img
                src={
                  require("../../assets/img/course-overview/vid_unlock.svg")
                    .default
                }
                className="vid-completed"
                alt="vid_completed_img"
              />
            ) : (
              <img
                src={
                  require("../../assets/img/course-overview/vid_lock.svg")
                    .default
                }
                className="vid_lock"
                alt="vid_lock_img"
              />
            )}
          </div>
        </div>
        {/* this section has 
					title 
					time
				*/}
        <div className="course__inner__li__txt">
          <p className="h1__title">{props.listTitle}</p>
          <p className="time__text">{props.listTime}</p>
        </div>
      </li>
    </a>
  );
};

// TrainTabPane component
//progressbar circle component
const SvgCircle = (props) => {
  const { value } = props;
  return (
    <svg viewBox="0 0 36 36">
      <text
        className="percent__text"
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="10"
      >
        {value}
        <tspan fontSize="5">%</tspan>
      </text>
      <path
        className="circle-bg"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke="#EBF5FA"
        strokeWidth="2.4"
        fill="none"
      ></path>
      <path
        className="circle"
        strokeDasharray={`${value}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={
          value < 80 && value > 0
            ? "#FFBC1F"
            : value > 79
              ? "#40D30D"
              : "transparent"
        }
        strokeWidth="2.4"
        fill="none"
      ></path>
    </svg>
  );
};
// CourseOverviewSingleView component start here
export const CourseOverviewSingleView = (props) => {
  //defining variables here
  const [isLockedVidPopup, setIsLockedVidPopup] = useState(false);
  const [isStateChange, setIsStateChange] = useState(false);
  const [isCongratulationsPopup, setIsCongratulationsPopup] = useState(false);
  const [vidHeight, setVidHeight] = useState(400);
  const [vidLoader, setvidLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [playStatus, setplayStatus] = useState(1);
  let history = useHistory();
  const vidDiv = useRef();
  const courseDataItems = props.courseData;
  if (!courseDataItems.length) {
    return null;
  }
  const courseDataItem = props.courseData[0];
  const quizDataItem = props.quizData[0];
  const courseCurrentItem = courseDataItem.current;
  const uid = courseDataItem.uid;
  const sid = courseCurrentItem.id;
  const [timeWatch, settimeWatch] = useState(courseDataItem.total_time_watch);
  const [currtimeWatch, setcurrtimeWatch] = useState(
    courseCurrentItem.time_watched
  );
  const [activeTabIndex, setActiveTabIndex] = useState(props.onTab);
  useEffect(() => {
    let timer;
    const player = new Plyr("#player", {
      controls: [
        "play-large",
        "rewind",
        "fast-forward",
        "play",
        "progress",
        "current-time",
        "duration",
        "mute",
        "volume",
        "settings",
        "fullscreen",
      ],
    });
    window.player = player;
    if (count) {
      if (
        courseCurrentItem.section_duration <= courseCurrentItem.time_watched
      ) {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=0,10000`,
              type: "video/mp4",
            },
          ],
          autoplay: true,
        };
      } else {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=${courseCurrentItem.time_watched},10000`,
              type: "video/mp4",
            },
          ],
          autoplay: true,
        };
      }
    } else {
      if (
        courseCurrentItem.section_duration <= courseCurrentItem.time_watched &&
        !+courseCurrentItem.has_seen
      ) {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=0,10000`,
              type: "video/mp4",
            },
          ],
          autoplay: false,
        };
      } else {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=${courseCurrentItem.time_watched},10000`,
              type: "video/mp4",
            },
          ],
          autoplay: false,
        };
      }
    }
    player.once("canplay", (event) => {
      const currentTime = courseCurrentItem.time_watched
        ? courseCurrentItem.time_watched
        : 0;
      player.currentTime = currentTime;
    });
    if (courseCurrentItem.has_seen == null || courseCurrentItem.has_seen == 0) {
      serviceProvider.post(
        "/item?type=sethasseen",
        JSON.stringify({ uid: uid, sid: sid }),
        true
      );
    }
    player.on("play", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }
      var time = player.currentTime;
      setplayStatus(1);
      /**
       * SCNV-2669
       * This condition has been implemented for removing flickering image issue on Safari and other browser
       * Issue was coming because of video play status and it's always coming when we were playing the video.
       */
      // if(playStatus != 1){
      //   setplayStatus(1);
      // }
      // end SCNV-2669
      props.setHashvalue(null);
      if (
        parseInt(courseCurrentItem.section_duration) >
        parseInt(courseCurrentItem.time_watched)
      ) {
        serviceProvider.post(
          "/itemdetails?type=settime",
          JSON.stringify({ uid: uid, sid: secid, time: time }),
          true
        );
      }
    });
    player.on("pause", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }
      var time = player.currentTime;
      setplayStatus(0);
      if (
        parseInt(courseCurrentItem.section_duration) >
        parseInt(courseCurrentItem.time_watched)
      ) {
        serviceProvider.post(
          "/itemdetails?type=settime",
          JSON.stringify({ uid: uid, sid: secid, time: time }),
          true
        );
      }
    });
    player.on("timeupdate", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }

      var currtimeWatch = 0;
      if (document.getElementById("currtimeWatch")) {
        var currtimeWatch = document.getElementById("currtimeWatch").value;
      }
      var playStatus = 0;
      if (document.getElementById("playStatus")) {
        var playStatus = document.getElementById("playStatus").value;
      }

      var time = player.currentTime;
      if (time > currtimeWatch) {
        if (
          parseInt(courseCurrentItem.section_duration) >
          parseInt(courseCurrentItem.time_watched)
        ) {
          //settimeWatch(courseDataItem.total_time_watch+time-courseCurrentItem.time_watched);
          setcurrtimeWatch(time);
        }
      }
      if (playStatus == 0) {
        player.pause();
      }
      serviceProvider.post(
        "/itemdetails?type=settime",
        JSON.stringify({ uid: uid, sid: secid, time: time }),
        true
      );
      serviceProvider
        .post(
          "/itemdetails?type=gettotaltimewatch",
          JSON.stringify({ uid: uid, course_id: props.courseData[0].id }),
          true
        )
        .then((response) => {
          if (response) {
            var time = parseInt(response.data);
            settimeWatch(time);
          }
        });
    });
    player.on("enterfullscreen", (event) => {
      document
        .querySelector(".plyr__controls__item.plyr__control")
        .classList.add("plyr__control--pressed");
    });
    player.on("exitfullscreen", (event) => {
      serviceProvider
        .post(
          "/itemdetails?type=gettotaltimewatch",
          JSON.stringify({ uid: uid, course_id: props.courseData[0].id }),
          true
        )
        .then((response) => {
          if (response) {
            var time = parseInt(response.data);
            settimeWatch(time);
          }
        });
      setVidHeight(event.detail.plyr.media.clientHeight);
    });

    player.on("playing", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }
      var time = player.currentTime;
      setplayStatus(1);
      /**
       * SCNV-2669
       * This condition has been implemented for removing flickering image issue on Safari and other browser
       * Issue was coming because of video play status and it's always coming when we were playing the video.
       */
      // if(playStatus != 1){
      //   setplayStatus(1);
      // }
      // end SCNV-2669
      if (
        parseInt(courseCurrentItem.section_duration) >
        parseInt(courseCurrentItem.time_watched)
      ) {
        serviceProvider.post(
          "/itemdetails?type=settime",
          JSON.stringify({ uid: uid, sid: secid, time: time }),
          true
        );
      }
    });
    player.on("ended", (event) => {
      var secid = document.getElementById("sectionid").value;
      var time = player.currentTime;
      var timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');
      setcurrtimeWatch(0);
      serviceProvider.post(
        "/itemdetails?type=settime",
        JSON.stringify({ uid: uid, sid: secid, time: time }),
        true
      );
      serviceProvider
        .post(
          "/itemdetails?type=sethascomplete",
          JSON.stringify({ uid: uid, sid: secid, timezone: timezone }),
          true
        )
        .then((response) => {
          serviceProvider
            .post(
              "/itemdetails?type=gettotaltimewatch",
              JSON.stringify({ uid: uid, course_id: props.courseData[0].id }),
              true
            )
            .then((response) => {
              if (response) {
                var time = parseInt(response.data);
                settimeWatch(time);
              }
            });

          if (response.data.islast == 1) {
            if (response.data.watched == 0) {
              setIsCongratulationsPopup(!isCongratulationsPopup);
            }
          } else {
            const vurl = `${apiEndpoints.baseUrl}/assets/videos/${response.data.video_url}`;
            player.source = {
              type: "video",
              sources: [{ src: vurl, type: "video/mp4" }],
              autoplay: true,
            };
            props.onComplete(response.data.nsid);
            history.push(response.data.redirect);
          }
        });
    });

    //setVidHeight(player.media.clientHeight+(player.media.clientHeight/100)*12);

    player.on("loadeddata", (event) => {
      setVidHeight(event.detail.plyr.media.clientHeight);

      if (props.hashvalue && props.hashvalue != null) {
        let targetCard = document.querySelectorAll(".qna__tabpane__card__li");
        let tmpInd = false;
        targetCard.forEach((item, index1) => {
          if (item.getAttribute("id") === props.hashvalue) {
            document.querySelector(`#${props.hashvalue}`).click();
            tmpInd = true;
            $(".training-content").animate(
              {
                scrollTop:
                  document.querySelector(".training-content").offsetHeight -
                  100,
              },
              "slow"
            );
          }
        });
      }
    });
    return () => {
      setCount(1);
      // clearTimeout(vidTimeout);
      //player.destroy();
      // setvidLoader(false);
      if (isStateChange) {
        player.destroy();
        setIsStateChange(false);
      }
    };
  }, [sid, props.tid, courseCurrentItem.id, isStateChange]);
  //Function to handle play and previous
  const HandlePlayPrevious = (index) => {
    serviceProvider
      .post(
        "/itemdetails?type=getreqvid",
        JSON.stringify({ uid: uid, sid: index }),
        true
      )
      .then((response) => {
        const vurl = `${apiEndpoints.baseUrl}/assets/videos/${response.data.video_url}`;
        player.source = {
          type: "video",
          sources: [{ src: vurl, type: "video/mp4" }],
          autoplay: true,
        };
      });
    props.onComplete(index);
    setIsStateChange(true);
  };
  //SCNV-2669
  const TrainTabPane = (props) => {
    return <>{props.item == activeTabIndex && <>{props.children}</>} </>;
  };

  //function to handle tab change
  const HandleTabChange = (index) => {
    setActiveTabIndex(index);
    setplayStatus(0);

    $(".training-content").animate(
      {
        scrollTop:
          document.querySelector(".training-content").offsetHeight - 100,
      },
      "slow"
    );
  };
  //Function to handle play previous popup
  const HandlePlayPreviousPopup = (index) => {
    props.onComplete(-1);
    props.onAllComplete(1);
    setIsCongratulationsPopup(!isCongratulationsPopup);
  };
  //function to handle lock video popup
  const HandleLockVidPopup = () => {
    setIsLockedVidPopup(!isLockedVidPopup);
    return false;
  };
  //fucntion to handle congratulation popup
  const HandleCongratulationsPopup = () => {
    props.onAllComplete(1);
    setIsCongratulationsPopup(!isCongratulationsPopup);
  };
  const HandleValueOnChange = (e) => {
    return true;
  };
  //function to handle submit question
  const submitQuestion = () => {
    var ques = document.getElementById("txtSendMsg").value.trim();
    var sid = document.getElementById("sectionid").value;
    var timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');
    if (ques) {
      serviceProvider
        .post(
          "/itemdetails?type=savequestion",
          JSON.stringify({
            uid: uid,
            cid: courseDataItem.id,
            sid: sid,
            ques: ques,
            timezone: timezone,
          }),
          true
        )
        .then((response) => {
          document.getElementById("txtSendMsg").value = "";
          props.onSubmitQues(response.data);
        });
    }
  };
  //function to handle submit answers
  const submitAnswers = (event) => {
    const qid = event.target.id;
    if (document.getElementById("txtReplyMsg-" + qid) != null) {
      var ans = document.getElementById("txtReplyMsg-" + qid).value.trim();
      var timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');
      if (ans) {
        serviceProvider
          .post(
            "/item?type=saveanswer",
            JSON.stringify({ uid: uid, qid: qid, ans: ans, timezone: timezone }),
            true
          )
          .then((response) => {
            props.onSubmitAns(response.data);
          });
      }
    }
  };
  //function to sort submit answer
  const submitSortBy = (event) => {
    props.onSortBy(event);
  };
  //function to filter submit answer
  const submitFilterBy = (event) => {
    props.onFilterBy(event);
  };
  //function to open search
  const searchOpen = (event) => {
    if (event.key === "Enter") {
      props.onSearchOpen(event.target.value);
    }
  };
  //function to close searach
  const searchClose = (event) => {
    props.onSearchOpen("");
  };
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    if (d == 0) {
      return "0m";
    }
    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
  };
  const secondsToHmOnly = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    if (d == 0) {
      return "0m";
    }
    var hDisplay = h == 0 ? "0h " : h + "h ";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    var sDisplay = "";
    return hDisplay + mDisplay + sDisplay;
  };
  let totalTime = 0;
  let doneTime = 0;
  const courseDataItemSections = courseDataItem.sections.map(
    (section, index) => {
      totalTime = totalTime + parseInt(section.section_duration);

      if (section.has_complete == 1) {
        doneTime = doneTime + parseInt(section.section_duration);
      } else {
        doneTime = doneTime + parseInt(section.time_watched);
      }

      if (section.id == sid) {
        var status =
          section.id == courseCurrentItem.id && courseDataItem.currentsid != 0
            ? "play"
            : courseDataItem.allcomplete == 1
              ? "complete"
              : "play";
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status={status}
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
          />
        );
      } else if (section.has_seen == 1 && section.has_complete == 0) {
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status="unlock"
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
            callBackFunction={() => HandlePlayPrevious(section.id)}
          />
        );
      } else if (section.has_seen == 1 && section.has_complete == 1) {
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status="complete"
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
            callBackFunction={() => HandlePlayPrevious(section.id)}
          />
        );
      } else {
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status="none"
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
            callBackFunction={HandleLockVidPopup}
          />
        );
      }
    }
  );
  const courseDataItemDetails = courseDataItem.details.map((detail) => {
    return (
      <div key={detail.id} className="wyl__col">
        <p className="wyl__chk__text">
          <span className="wyl__chk__icon">
            <img
              src={
                require("../../assets/img/course-detail/checkmark.svg").default
              }
              alt="true"
            />
          </span>
          <span>{detail.detail}</span>
        </p>
      </div>
    );
  });

  let leftTime = totalTime - timeWatch;
  let completePerc = 0;
  if (timeWatch) {
    totalTime = parseInt(totalTime);
    if (parseInt(totalTime) <= parseInt(timeWatch)) {
      leftTime = 0;
      completePerc = 100;
    } else {
      completePerc = Math.round((timeWatch / totalTime) * 100);
    }
  }

  return (
    <React.Fragment>
      {/* page title */}
      <Helmet>
        <title>{courseDataItem.course_name} | SmarterChains</title>
        <meta name="description" content={courseDataItem.meta_desc} />
        <meta
          name="og:image"
          content={`${apiEndpoints.baseUrl}/assets/img/meta/${courseDataItem.meta_img}`}
        />
      </Helmet>
      {/* braedcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/training-courses">IR4.0 Training Courses</BreadcrumbsItem>
      <BreadcrumbsItem to="#">{courseDataItem.course_name}</BreadcrumbsItem>
      {/* this wrapper has
				course__overview__head
				course__overview__wrapper
			*/}
      <div className="master_wrapper course__overview">
        {/* this section has
					head__left__frame
					head__right__frame
				*/}
        <div className="course__overview__head">
          {/* this section has
						heading\subheading
					*/}
          <div className="head__left__frame">
            <h1 className="course__overView__h1">
              {/* Removal of back button SCNV-3211 */}
              {/* <span
                className="course__back__btn"
                onClick={() => {
                  HandlePageOnClick("/training-courses", { ...props });
                }}
              >
                <img
                  className="course__back__btn_img"
                  src={
                    require("../../assets/img/course-detail/back-course.svg")
                      .default
                  }
                  alt="true"
                />
              </span> */}
              <span className={`${props.sid}-${courseCurrentItem.id}-${sid}`}>
                {courseDataItem.course_name}
              </span>
            </h1>
            <h2 className="course__overView__h1_small">
              {courseDataItem.id == 11
                ? "INDUSTRY 4.0 INTRODUCTORY COURSE"
                : "INDUSTRY 4.0 DIMENSION TRAININGS"}
            </h2>
          </div>
          {/* this section has progress bar */}
          <div className="head__right__frame">
            <TrainProgressBar
              value={completePerc}
              doneTime={secondsToHms(timeWatch)}
              leftTime={secondsToHms(leftTime)}
            />
          </div>
        </div>
        {/* this section has
					course__inner__top__sec
					course__inner__tab__sec
				*/}
        <div className="course__inner__wrapper">
          {/* this section has
						course__inner__top__left
						course__inner__top__right
					*/}
          <div className="course__inner__top__sec">
            {/* this section has list */}
            <div
              className="course__inner__top__left"
              style={{
                maxHeight: `${screen.width > 768 ? vidHeight : "400"}px`,
              }}
            >
              <div className="course__inner__ul__wrapper">
                <div className="course__inner__ul__head">
                  <div className="course__inner__ul__head__h1">
                    <div className="head__h1__icon">
                      <img
                        src={
                          require("../../assets/img/course-overview/content_head.svg")
                            .default
                        }
                        alt="true"
                      />
                    </div>
                    <div className="head__h1__text">
                      <span>Contents</span>
                    </div>
                  </div>
                </div>

                <ul className="course__inner__ul">{courseDataItemSections}</ul>
                {/* vid-loader  */}
                {/* <div className="vid-loader"></div> */}
              </div>
            </div>
            {/* this section has
							inputs 
							video
						*/}
            <div className="course__inner__top__right" ref={vidDiv}>
              {/* <VideoPlayer videoSrc={videoSrc} /> */}
              <input
                id="sectionid"
                value={sid}
                readOnly
                style={{ display: "none" }}
              />
              <input
                id="playStatus"
                value={playStatus}
                readOnly
                style={{ display: "none" }}
              />
              <input
                id="isStateChange"
                value={isStateChange}
                readOnly
                style={{ display: "none" }}
              />
              <input
                id="currtimeWatch"
                value={currtimeWatch}
                readOnly
                style={{ display: "none" }}
              />
              <video id="player" controls crossorigin playsInline></video>
            </div>

            {/* {vidLoader && <div className="vid-loader"></div>} */}
          </div>

          <div className="course__inner__tab__sec">
            {/* this section has tabs */}
            <div className="course__inner__tabs">
              <button
                className={
                  activeTabIndex === 0
                    ? "course__inner__tab__btn active"
                    : "course__inner__tab__btn"
                }
                onClick={() => HandleTabChange(0)}
                type="button"
              >
                <span className="course__inner__tab__img">
                  <img
                    src={
                      require("../../assets/img/course-overview/tab_overview_active.svg")
                        .default
                    }
                    className="active"
                  />
                  <img
                    src={
                      require("../../assets/img/course-overview/tab_overview_inactive.svg")
                        .default
                    }
                    className="inactive"
                  />
                </span>
                <span>Overview</span>
              </button>

              <button style={{ display: "none" }}
                className={
                  activeTabIndex === 1
                    ? "course__inner__tab__btn active"
                    : "course__inner__tab__btn"
                }
                onClick={() => HandleTabChange(1)}
                type="button"
              >
                <span className="course__inner__tab__img">
                  <img
                    src={
                      require("../../assets/img/course-overview/tab_qna_active.svg")
                        .default
                    }
                    className="active"
                  />
                  <img
                    src={
                      require("../../assets/img/course-overview/tab_qna_inactive.svg")
                        .default
                    }
                    className="inactive"
                  />
                </span>
                <span>Questions & Answers</span>
              </button>

              <button
                className={
                  activeTabIndex === 2
                    ? "course__inner__tab__btn active"
                    : "course__inner__tab__btn"
                }
                onClick={() => HandleTabChange(2)}
                type="button"
              >
                <span className="course__inner__tab__img">
                  <img
                    src={
                      require("../../assets/img/course-overview/tab_quiz_active.svg")
                        .default
                    }
                    className="active"
                  />
                  <img
                    src={
                      require("../../assets/img/course-overview/tab_quiz_inactive.svg")
                        .default
                    }
                    className="inactive"
                  />
                </span>
                <span>Quiz</span>
              </button>
            </div>

            <div className="course__inner__tab__panels">
              <TrainTabPane item={0} activeTabIndex={activeTabIndex} {...props}>
                {/* this section has tab content */}
                <div className="course__overview__tabpane">
                  {/* this section has
										course__overview__frame__left
										course__overview__frame__right
									*/}
                  <div className="course__overview__frame__wrapper overview_tabpane_frame">
                    {/* thisi section has card */}
                    <div className="course__overview__frame__left">
                      {/* this section has
												heading 
												row
											*/}
                      <div className="course__tabpane__card abt__course">
                        <h3 className="h1">About this course</h3>
                        {/* this section has 
													images
												*/}
                        <div className="abt__course__row">
                          <div className="abt__course__col__lft">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: courseDataItem.course_description,
                              }}
                            ></div>
                          </div>
                          <div className="abt__course__col__rgt">
                            <img
                              src={
                                require(`../../assets/img/training-card/inner-${courseDataItem.course_icon}`)
                                  .default
                              }
                              alt={courseDataItem.course_name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* this section has card */}
                    <div className="course__overview__frame__right">
                      {/* this section has row*/}
                      <div className="share__course__card">
                        {/* this section has
													share_course_txt
													share_course_link
												*/}
                        <div className="share__course__row">
                          <span className="share__course__txt">
                            Share this course:
                          </span>
                          <div className="share__course__link">
                            <LinkedinShareButton
                              quote=""
                              url={courseDataItem.social_url}
                            >
                              <img
                                className="linkedin"
                                src={
                                  require("../../assets/img/social-media/linked-in.svg")
                                    .default
                                }
                                alt="share_linked_in"
                              />
                            </LinkedinShareButton>
                          </div>
                          <div className="share__course__link">
                            <FacebookShareButton
                              quote=""
                              url={courseDataItem.social_url}
                            >
                              <img
                                className="facebook"
                                src={
                                  require("../../assets/img/social-media/facebook.svg")
                                    .default
                                }
                                alt="share_facebook"
                              />
                            </FacebookShareButton>
                          </div>
                          <div className="share__course__link">
                            <TwitterShareButton
                              quote=""
                              url={courseDataItem.social_url}
                            >
                              <img
                                className="twitter"
                                src={
                                  require("../../assets/img/social-media/twitter.svg")
                                    .default
                                }
                                alt="share_twitter"
                              />
                            </TwitterShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* this section has
											title
											image
											social icons
											about instructor
										 */}
                    <div
                      className="course__overview__frame__right"
                      style={{ display: "none" }}
                    >
                      <div className="course__tabpane__card instructor">
                        <h3 className="title">Instructor</h3>
                        <Avatar
                          alt={courseDataItem.i_name}
                          className="instructor__img"
                          src={
                            require(`../../assets/img/course-instructor/${courseDataItem.i_avatar}`)
                              .default
                          }
                        />
                        <div className="inst__social__icons">
                          <a href={courseDataItem.i_linkedin} target="_blank">
                            <img
                              src={
                                require("../../assets/img/social-media/linked-in.svg")
                                  .default
                              }
                              alt="linked_in"
                            />
                          </a>
                        </div>
                        <div className="about__instructor">
                          <p className="inst__name">{courseDataItem.i_name}</p>
                          <p className="inst__about__text">
                            {courseDataItem.i_position}
                          </p>
                          <p className="inst__about__text i_from">
                            {courseDataItem.i_from}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="course__overview__frame__left">
                      <div className="course__tabpane__card wyl">
                        <h3 className="h1">What you'll learn</h3>
                        <div className="wyl__row">{courseDataItemDetails}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </TrainTabPane>
              <TrainTabPane item={1} activeTabIndex={activeTabIndex} {...props}>
                <div className="course__overview__tabpane">
                  {/* this section has question and answer */}
                  <div className="course__overview__frame__wrapper qna__tabpane__frame">
                    
                  </div>
                </div>
              </TrainTabPane>
              <TrainTabPane item={2} activeTabIndex={activeTabIndex} {...props}>
                <div className="course__overview__tabpane">
                  {courseDataItem.score < 80 ? (
                    // this section has two frames
                    <div className="course__overview__frame__wrapper quiz__tabpane__frame">
                      {/* this section has
												heading
												quiz_card_body
												link
											 */}
                      <div className="course__overview__frame__left">
                        <div className="course__tabpane__card quiz__card">
                          <h3 className="h1">
                            Quiz: {courseDataItem.course_name}
                          </h3>
                          <div className="quiz__card__body">
                            <p>
                              Test your knowledge with the course Quiz and get
                              your Certificate. Unlock the Quiz by watching all
                              the course sections. Score 80% or higher to get
                              your certificate. You can retake the Quiz after 8
                              hours in case you failed.
                            </p>
                          </div>
                          <Link
                            courseDataItem
                            to={
                              courseDataItem.is_first_quiz == 1
                                ? `/quiz-walkthrough#${courseDataItem.course_slug}`
                                : `/training-quiz/${courseDataItem.course_slug}`
                            }
                            className={
                              completePerc == 100
                                ? "quiz__card__btn"
                                : "quiz__card__btn disabled"
                            }
                          >
                            <span className="img__span">
                              <img
                                src={
                                  require("../../assets/img/course-overview/cir-quiz-btn.svg")
                                    .default
                                }
                                alt="true"
                              />
                            </span>
                            {courseDataItem.certificateUrl != "" ? (
                              <span>Retake Quiz</span>
                            ) : (
                              <span>Take Quiz</span>
                            )}
                          </Link>
                        </div>
                      </div>
                      {/* this section has
											 	heading
												percentage circle
												text
											 */}
                      <div className="course__overview__frame__right">
                        <div className="course__tabpane__card your__score__card">
                          <h3 className="h1">Quiz Score</h3>
                          <div className="your__score__cir">
                            <SvgCircle value={courseDataItem.score} />
                          </div>
                          {/* when value is less than  100 and greater than 0*/}
                          <p></p>
                          {/* when value is 0*/}
                          {courseDataItem.score >= 0  && courseDataItem.certificateUrl != "" && (
                            <p>You didn't pass this time</p>
                          )}
                          
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="course__overview__frame__wrapper quiz__tabpane__frame">
                      <div className="course__overview__frame__left">
                        <div className="course__tabpane__card congrats__main__card">
                          <div className="cong__card">
                            <div className="cong__card__img">
                              <img
                                className=""
                                src={
                                  require("../../assets/img/course-overview/congrats-completed.svg")
                                    .default
                                }
                                alt="true"
                              />
                            </div>
                            {/* this section has
																text
																link
															*/}
                            <div className="cong__card__content">
                              <p className="cong_blue_text">Congratulations!</p>
                              <p>You've successfully completed the Quiz:</p>
                              <p className="b">{courseDataItem.course_name}</p>

                              {courseDataItem.certificateUrlAll != "" ? (
                             <><a
                              target="_blank"
                              href={courseDataItem.certificateUrlAll}
                              className="cong__btue__btn"
                            >
                              Download your certificate
                            </a></> 
                            ) : (
                              <></>
                            )} 

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="course__overview__frame__right">
                        {/* this section has
													heading
													percentage circle
													link
												*/}
                        <div className="course__tabpane__card your__score__card course__hover__card">
                          <h3 className="h1">Your Score is</h3>
                          <div className="your__score__cir">
                            <SvgCircle value={courseDataItem.score} />
                          </div>
                          <p>You have passed the Quiz!</p>
                          <Link
                            to={`/training-quiz/${courseDataItem.course_slug}`}
                            className="quiz__card__hv_btn"
                          >
                            See the Quiz Results
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TrainTabPane>
            </div>
          </div>
        </div>
      </div>

      {/* {props.isLockedVidPopup ? <LockedVideoPoup HandleLockVidPopup={props.HandleLockVidPopup} /> : ""} */}
      {/* locked video popup start */}
      <div
        className={`locked__video__parent ${isLockedVidPopup ? "visible active" : ""
          }`}
      >
        <div className="locked__video__popup">
          {/* this section has
						close button
						image
						text
						ok button
					*/}
          <div className="popup__content">
            <button className="close__popup" onClick={HandleLockVidPopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-blue.svg")
                    .default
                }
              />
            </button>
            <img
              src={
                require("../../assets/img/course-overview/vid_lock.svg").default
              }
              className="popup__top__img"
              alt="true"
            />
            <p>
              Please complete the previous course section to be able to access
              this video
            </p>
            <button className="popup__btn" onClick={HandleLockVidPopup}>
              OK
            </button>
          </div>
        </div>
      </div>
      {/* congratulation popup start */}
      <div
        className={`congratulation__popup__parent ${isCongratulationsPopup ? "visible active" : ""
          }`}
      >
        <div className="congratulation__popup">
          {/* this section has
						close button
						image
						div
					*/}
          <div className="congratulation__content">
            <button className="close__popup" onClick={HandlePlayPreviousPopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-blue.svg")
                    .default
                }
              />
            </button>
            <img
              className="congrats_img"
              src={
                require("../../assets/img/course-overview/congrats__top__img.gif")
                  .default
              }
              alt="true"
            />
            {/* this section has
							txt
							btn-wrapper
						*/}
            <div>
              <p className="congratulation__blue">Congratulations!</p>
              <p>You've successfully completed the Course:</p>
              <p className="b">{courseDataItem.course_name}</p>
              <div className="cong__btn__wrapper">
                <div className="cong__btn__col">
                  <button
                    className="cong__btn__blue__border"
                    onClick={HandlePlayPreviousPopup}
                  >
                    I'll do the Quiz later
                  </button>
                </div>
                <div className="cong__btn__col">
                  <Link
                    to={
                      courseDataItem.is_first_quiz == 1
                        ? `/quiz-walkthrough#${courseDataItem.course_slug}`
                        : `/training-quiz/${courseDataItem.course_slug}`
                    }
                    className="cong__btn__blue"
                  >
                    Take the Quiz
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
//QNAUpdate component start
const QNAUpdate = (props) => {
  //defining variables her
  const courseDataItem = props.courseData[0];
  const questDataItem = props.courseData[0];
  const [searchVal, setSearchVal] = React.useState(props.textb);
  const [filterOpen, setFilterOpen] = React.useState(null);
  const [isReportPopup, setIsReportPopup] = React.useState(false);
  const [isCardReply, setIsCardReply] = React.useState(0);
  const [likeObj, setLikeObj] = React.useState({});
  const [qnaAllSectionDdlVal, setQnaAllSectionDdlVal] = React.useState(
    props.catb
  );
  const [isDel566, setIsDel566] = React.useState(false);
  const [reportCardId, setReportCardId] = React.useState(null);
  const [isMoreReply, setIsMoreReply] = React.useState({});
  const searchInput = React.useRef();
  const user = JSON.parse(localStorage.getItem("userData"));
  const avatarColor = user ? user.avcolor : '';
  const [tmpReplyObj, setTmpReplyObj] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [pageData, setPageData] = useState([]);
  const [tmpTelCardId, setTmpTelCardId] = useState(null);
  const [tmpAnsReplyCount, setTmpAnsReplyCount] = useState({});
  const [tmpAnsReplyData, setTmpAnsReplyData] = useState({});
  const [tmpAnsSubReplyCount, setTmpAnsSubReplyCount] = useState({});
  const [tmpAnsSubReplyData, setTmpAnsSubReplyData] = useState({});
  const [sucessImg, setSucessImg] = useState(false);
  const [editTextAreaArr,setEditTextAreaArr]=useState({});
  const [publicText, setPublicText] = useState("");
  const textareaRef = useRef();
  const perPageItem = 10;
  const uid = user ? user.uid : 0;
  let currentHash = "";

  // This function used for manage reply and add-reply btn disabled status (07-03-2023) 
	const handleReplyBtnDisbaledStatus = (e)=>{
		const val = e.target.value.trim();
		const pElement	= e.target.parentElement;
		const replyBtn	= pElement.querySelector('.handle-btn-disabled');
		if(val.length>0){
			replyBtn.style.pointerEvents='all';
			replyBtn.style.opacity=1;
		}else{
			replyBtn.style.pointerEvents='none';
			replyBtn.style.opacity=0.6;
		}
	}

  //function to handle like click
  const HandleLikeClick = (e, id, valnew, type, itemid) => {
    let targetSameClass = document.querySelectorAll(`.${id}`);
    if (!e.target.classList.contains("liked")) {
      for (let i = 0; i < targetSameClass.length; i++) {
        targetSameClass[i].classList.add("liked");
      }
      var timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');
      //update in table
      serviceProvider
        .post(
          "/itemdetails?type=saveliked",
          JSON.stringify({
            uid: questDataItem.uid,
            item_id: itemid,
            item_type: type,
            timezone: timezone,
          }),
          true
        )
        .then((response) => {
          let val = parseInt(response.data);
          let tmpObj = { ...likeObj, [id]: val };
          setLikeObj(tmpObj);
        });
      //update in table
    } else {
      for (let i = 0; i < targetSameClass.length; i++) {
        targetSameClass[i].classList.remove("liked");
      }
      //remove from table
      serviceProvider
        .post(
          "/itemdetails?type=removeliked",
          JSON.stringify({
            uid: questDataItem.uid,
            item_id: itemid,
            item_type: type,
          }),
          true
        )
        .then((response) => {
          let val = parseInt(response.data);
          let tmpObj = { ...likeObj, [id]: val };
          setLikeObj(tmpObj);
        });
      //remove from table
    }

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  //function to handle input value
  const HandleInputValue = (e) => {
    let val = e.target.value;
    setSearchVal(val);
  };
  //function to handle search
  const HandleSearch = () => {
    if (searchVal.length == 0) {
      searchInput.current.focus();
    } else {
      setSearchVal("");
      props.handleClosePress();
      searchInput.current.focus();
    }
  };
  const setIsShown = () => {
    if (props.hashvalue == null) {
      props.setplayStatus(0);
    }
  };
  //fucntion to handle report popup
  const HandleReportPopup = (type, id) => {
    if (type || id) {
      setReportCardId({
        id: id,
        type: type,
      });
    }
    setIsReportPopup(!isReportPopup);
  };
  //function to handle card
  const HandleCard = (value, rClass,quesId) => {
    if (rClass) {
      let targetHighlightDiv = document.querySelectorAll(".d-flex-box");
      targetHighlightDiv.forEach((item) => {
        if (item.classList.contains("highlighttc")) {
          item.classList.remove("highlighttc");
        }
      });
    }

    setIsCardReply(value);
    if(value == 0){
      let tmpQn=document.querySelector(`#backQna-566-${quesId}`);
      tmpQn?.classList.remove('active');
    }
    if (value == 2) {
      setTimeout(() => {
        textareaRef.current.select();
      }, 100);

     
      let topPos = document.querySelector('.btn-publish').offsetTop;
      if (!topPos) {
        setTimeout(() => {
          $('.training-content').animate({
            scrollTop: document.querySelector('.btn-publish').offsetTop
          },
            'slow');
        }, 100)
      } else {
        $('.training-content').animate({
          scrollTop: topPos
        },
          'slow');
      }
    }
    //if (value == 2) {
    //			textareaRef.current.select();
    //			let topPos=document.querySelector('.btn-publish').offsetTop;
    //			if(!topPos){
    //				setTimeout(()=>{
    //					$('.training-content').animate({
    //						scrollTop:document.querySelector('.btn-publish').offsetTop
    //					},
    //					'slow');
    //					document.querySelector('#txtSendMsg').focus();
    //				},100)
    //			}else{
    //				$('.training-content').animate({
    //					scrollTop:topPos
    //				},
    //				'slow');
    //			}
    //
    //		}
  };

  // function to close all replybox to on click back to all questions button
  const BackToCloseRepyBox =()=>{
	    let allReplyBox=document.querySelectorAll('.s-566-cmnt-wrapper');
	    allReplyBox?.forEach(box=>{
	      if(box){
	        box.classList.remove('active','reply-active','border-important');
	      }
	    })
	}

  // funtion to close 3dotDdl
  const CloseDdl3dots = () => {
    const btnThreeDot = document.querySelectorAll(
      ".scnv-566-3dots .btn-566-3dot"
    );
    btnThreeDot.forEach((item) => {
      item.classList.remove("active");
    });
  };
  // funtion to toggle 3dotDdl
  const HandleDdl3dots = (e) => {
    // const btnThreeDot = document.querySelectorAll('.scnv-566-3dots .btn-566-3dot');
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
    } else {
      CloseDdl3dots();
      e.currentTarget.classList.add("active");
    }
  };
  //function to handle edit popup
  const HandleEditPopup = (id,quesId,action) => {
    let targetDiv = document.getElementById(id);
    if (action == "open") {
      targetDiv.classList.remove("reply-active");
      targetDiv.classList.add("active");
      // Focus when we edit a question start
      const allTextArea = document.querySelector(`#textareaAlltextareaBack-${quesId}`);
      const backTextArea = document.querySelector(`#textareaBacktextareaBack-${quesId}`);
      const end = allTextArea.value.length;
      const backEnd = backTextArea.value.length;
      allTextArea.setSelectionRange(end, end);
      allTextArea.focus();
      backTextArea.setSelectionRange(backEnd, backEnd);
      backTextArea.focus();
      targetDiv.querySelector('.s-566-cmnt-textarea').focus();
      //end
    } else if (action == "close") {
      targetDiv.classList.remove("active");
    } else {
      targetDiv.classList.remove("reply-active");
      targetDiv.classList.toggle("active");
      let txtArea=targetDiv.querySelector('.s-566-cmnt-textarea');
      const end = txtArea.value.length;
      txtArea.setSelectionRange(end, end)
      txtArea.focus();
    }
  };
  //function to handle reply popup
  const HandleReplyPopup = (id, ansId, action) => {
    let targetDiv = document.getElementById(id);
    if (action == "open") {
      document
        .querySelector(".s-566-cmnt-wrapper.reply-active")
        ?.classList?.remove("reply-active");
      targetDiv.classList.remove("active", "border-important");
      targetDiv.classList.add("reply-active", "border-important");
      document.querySelector(`#rep566-textarea-${ansId}`).focus();
    } else if (action == "close") {
      targetDiv.classList.remove("reply-active", "border-important");
    } else {
      document
        .querySelector(".s-566-cmnt-wrapper.reply-active")
        ?.classList?.remove("reply-active");
      targetDiv.classList.remove("active", "border-important");
      targetDiv.classList.toggle("active", "border-important");
    }
  };
  //function to save edit answer
  const SaveEditAns = (textAreaId, paraId, editedClass) => {
    let targetTextarea = document.getElementById(textAreaId);
    let targetPara = document.getElementById(paraId);
    let targetEdited = document.querySelectorAll(`.${editedClass}`);
    let tmpVal = targetTextarea.value.trim();
    targetTextarea.defaultValue = tmpVal;
    targetPara.innerHTML = tmpVal;
    targetEdited.forEach((item) => {
      item.style.display = "inline-flex";
    });
    serviceProvider
      .post(
        "/itemdetails?type=updateans",
        JSON.stringify({ id: paraId.split("-")[1], data: tmpVal }),
        true
      )
      .then((response) => { });
  };
  //function to save edit question
  const SaveEditQues = (textAreaClass, paraClass, textAreaId, editedClass) => {
    let targetTextarea = document.getElementById(textAreaId);
    let targetTextareaByClass = document.querySelectorAll(`.${textAreaClass}`);
    let targetPara = document.querySelectorAll(`.${paraClass}`);
    let targetEdited = document.querySelectorAll(`.${editedClass}`);
    let tmpId = paraClass.split("-")[1];
    let tmpVal = targetTextarea.value.trim();

    targetEdited.forEach((item) => {
      item.style.display = "inline-flex";
    });
    targetTextareaByClass.forEach((item) => {
      item.value = tmpVal;
    });
    targetPara.forEach((item) => {
      item.innerHTML = tmpVal;
    });

    targetTextarea.defaultValue = tmpVal;

    serviceProvider
      .post(
        "/itemdetails?type=updateques",
        JSON.stringify({ id: tmpId, data: tmpVal }),
        true
      )
      .then((response) => { });
    //props.onSubmitQues(1);
  };
  //functio to delete record
  const DeleteRecord = () => {
    const type = isDel566.split("-")[0];
    const id = isDel566.split("-")[1];
    serviceProvider
      .post(
        "/itemdetails?type=deleteqa",
        JSON.stringify({ t: type, id: id }),
        true
      )
      .then((response) => {
        setIsDel566(false);
        if (tmpTelCardId) {
          tmpTelCardId.forEach((item) => {
            let liLength =
              $(document.getElementById(item)).find("ul").children("li")
                .length - 1;
            $(document.getElementById(item)).remove();
            if (item.indexOf("backQnaAll") > -1) {
              HandleCard(0, true);
            }
            let tmpkey1 = Object.keys(tmpAnsReplyData)[0];
            let tmpkey2 = Object.keys(tmpAnsSubReplyData)[0];
            //  added if tmpkey1 with &&
            if (
              tmpkey1 &&
              tmpkey1.length > 0 &&
              item.indexOf("allQnaback-566-") > -1
            ) {
              let tmpobj = {
                ...tmpAnsReplyCount,
                [tmpkey1]: Number(tmpAnsReplyData[tmpkey1] + liLength),
              };
              setTmpAnsReplyCount(tmpobj);
              //  added if tmpkey1 with &&
            } else if (tmpkey1 && tmpkey1.length > 0) {
              let tmpobj = {
                ...tmpAnsReplyCount,
                [tmpkey1]: Number(tmpAnsReplyData[tmpkey1] + 1),
              };
              setTmpAnsReplyCount(tmpobj);
            }
            if (tmpkey2?.length > 0) {
              let tmpobj = {
                ...tmpAnsSubReplyCount,
                [tmpkey2]: Number(tmpAnsSubReplyData[tmpkey2] + 1),
              };
              setTmpAnsSubReplyCount(tmpobj);
            }
            setTmpAnsReplyData({});
            setTmpAnsSubReplyData({});
          });
        }
        props.onSubmitQues(id);
      });
  };
  //function to reply post
  const PostReply = (quesCardId, ansId, textAreaId) => {
    let textarea = document.getElementById(textAreaId);
    var timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');
    serviceProvider
      .post(
        "/itemdetails?type=savereply",
        JSON.stringify({ uid: uid, ans_id: ansId, answer: textarea.value.trim(), timezone: timezone }),
        true
      )
      .then((response) => {
        // props.onSubmitAns(quesCardId);
        const data = { ...response.data[0], avcolor: avatarColor };
        let tmpArry = [...tmpReplyObj];
        tmpArry.push(data);
        setTmpReplyObj(tmpArry);
        textarea.value = "";
        const replyBtns	= document.querySelectorAll('.make-disabled-after-savereply');
        replyBtns.forEach(e =>{
          e.style.pointerEvents='none';
			    e.style.opacity=0.6;
        })
      });
  };
  // fiunction to edit post reply
  const PostEditReply = (textAreaId, paraId, editedClass) => {
    let targetTextarea = document.getElementById(textAreaId);
    let targetPara = document.getElementById(paraId);
    let tmpVal = targetTextarea.value.trim();
    targetTextarea.defaultValue = tmpVal;
    targetPara.innerHTML = tmpVal;
    let targetEdited = document.querySelectorAll(`.${editedClass}`);
    targetEdited.forEach((item) => {
      item.style.display = "inline-flex";
    });
    serviceProvider
      .post(
        "/itemdetails?type=updateansreply",
        JSON.stringify({ id: paraId.split("-")[1], data: tmpVal }),
        true
      )
      .then((response) => { });
  };
  //fucntion for helpful
  const WasHelpFul = (val, ansId, divId) => {
    document.getElementById(divId).style.display = "none";
    serviceProvider
      .post(
        "/itemdetails?type=washelpful",
        JSON.stringify({ uid: uid, ans_id: ansId, item_type: val }),
        true
      )
      .then((response) => { });
  };
  //function to handle follow reply
  const HandleFollowReply = (e, userId, quesId) => {
    if (!e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.add("active");
      serviceProvider
        .post(
          "/itemdetails?type=reportfollow",
          JSON.stringify({ uid: userId, question_id: quesId, type: 1 }),
          true
        )
        .then((response) => { });
    } else {
      e.currentTarget.classList.remove("active");
      serviceProvider
        .post(
          "/itemdetails?type=reportfollow",
          JSON.stringify({ uid: userId, question_id: quesId, type: 0 }),
          true
        )
        .then((response) => { });
    }
  };
  const PaginationFunction = () => {
    const tmpPageTotal = Math.round(
      questDataItem.questions.length / perPageItem
    );
    setTotalPage(tmpPageTotal);
    const tmpData = questDataItem.questions; //.slice(0,pagination*perPageItem);
    setPageData(tmpData);
    const scrollDiv = document.querySelector(".training-content");
    // scrollDiv.addEventListener('scroll',()=>{
    // 	if(Math.round(scrollDiv.scrollTop) >= (scrollDiv.scrollHeight - scrollDiv.clientHeight)){
    // 		const tmpIncreasePage=pagination+1;
    // 		const tmpData=questDataItem.questions.slice(0,tmpIncreasePage*perPageItem);
    // 		setPagination(tmpIncreasePage);
    // 		setPageData(tmpData);
    // 	}
    // });
  };
  const setQuestionForHash = () => {
    if (props.hashvalue != null) {
      if (currentHash !== props.hashvalue) {
        currentHash = props.hashvalue;
        const ele = document.querySelector(`#${props.hashvalue}`);

        if (ele != null) {
          ele.click();
          $(".training-content").animate(
            {
              scrollTop:
                document.querySelector(".training-content").offsetHeight - 100,
            },
            "slow"
          );
        } else {
          if (ele != null) {
            setIsCardReply(3);
          }
        }
      }
    }
  };
  useEffect(() => {
    //setQuestionForHash()
  }, [props.hashvalue]);

  React.useEffect(() => {
    // pagination
    // PaginationFunction();
    // HandleDdl3dots();
    document.onclick = (e) => {
      // when click ouside from scnv-566-3dots div
      if (!e.target.closest(".scnv-566-3dots")) {
        CloseDdl3dots();
      }
    };
    // for resize text area height dynamically
    document.querySelectorAll(".s-566-cmnt-textarea").forEach((item) => {
      item.addEventListener("input", (e) => {
        item.style.height = "5.5rem"; // 5.5rem is default size
        item.style.height = item.scrollHeight / 10 + "rem";
      });
    });
    // for resize text area height dynamically

    // when user card id is in url ex- research-development-in-industry-40#q&a&allQna-566-148

    //setQuestionForHash();
    //$('.training-content').animate({
    //scrollTop: document.querySelector('.training-content').offsetHeight - 100
    //},
    //'slow');

    if (props.submitAns) {
    }
  }, []);

  return (
    <>
      <div className="edu-qa-wrapper">
        <div className="course__inner__tab__sec">
          <div className="course__inner__tab__panels">
            <div className="course__overview__tabpane">
              <div className="course__overview__frame__wrapper qna__tabpane__frame">
                {/* search and filter sec  */}
                <div className="course__overview__frame__left">
                  <div className="s-search-with-btn">
                    <div className="srch-box-col">
                      <div
                        className={`qna-search-box ${searchVal.length > 0 && "active"
                          }`}
                      >
                        <input
                          type="text"
                          value={searchVal}
                          onChange={(e) => HandleInputValue(e)}
                          ref={searchInput}
                          onKeyPress={props.handleKeyPress}
                          placeholder="Search questions that can help you"
                        />
                        <button onClick={HandleSearch}>
                          <img
                            src={
                              require("../../assets/img/common-img/qna-search.svg")
                                .default
                            }
                            className="inactive"
                            alt="true"
                          />
                          <img
                            src={
                              require("../../assets/img/common-img/qna-cancel-src.svg")
                                .default
                            }
                            className="active"
                            alt="true"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="srch-submit-col">
                      <button
                        className={`qna-btn-submit add-question ${isCardReply === 2 ? "active" : ""
                          }`}
                        onMouseEnter={() => setIsShown()}
                        onClick={() => HandleCard(2)}
                      >
                        Submit a new question
                      </button>
                    </div>
                  </div>
                  <div className="qna-filter-wrapper">
                    <div className="qna-filter-col">
                      <QnaChekBoxFilter
                        id="qnaChkFilter1"
                        title="All Sections"
                        value={qnaAllSectionDdlVal}
                        setValue={setQnaAllSectionDdlVal}
                        options={courseDataItem.sections}
                        {...props}
                      />
                    </div>
                    <div className="qna-filter-col">
                      <QnaFilter
                        itemNumber={1}
                        ActiveItem={filterOpen}
                        setActiveItem={setFilterOpen}
                        title={
                          props.sortb == 1
                            ? "Sort by most recent"
                            : props.sortb == 2
                              ? "Sort by most upvoted"
                              : "Sort By"
                        }
                        getvalue={(e) => props.onSort(e.value)}
                        options={[
                          {
                            title: "Sort by most recent",
                            value: "1",
                          },
                          {
                            title: "Sort by most upvoted",
                            value: "2",
                          },
                        ]}
                        {...props}
                      />
                    </div>
                    <div className="qna-filter-col">
                      <QnaFilter
                        itemNumber={2}
                        ActiveItem={filterOpen}
                        setActiveItem={setFilterOpen}
                        title={
                          props.filterb == 3
                            ? "Show all questions"
                            : props.filterb == 4
                              ? "Show questions I asked"
                              : props.filterb == 1
                                ? "Show questions I follow"
                                : "Filter Questions"
                        }
                        getvalue={(e) => props.onFilter(e.value)}
                        options={[
                          {
                            title: "Show all questions",
                            value: "3",
                          },
                          {
                            title: "Show questions I asked",
                            value: "4",
                          },
                          {
                            title: "Show questions I follow",
                            value: "1",
                          },
                        ]}
                        {...props}
                      />
                    </div>
                  </div>
                </div>
                {/* search and filter sec  */}
                <div
                  className="course__overview__frame__left"
                  style={{ display: isCardReply === 0 ? "" : "none" }}
                >
                  <h2 className="h2">
                    All questions in this course
                    <span>({questDataItem.total_ques})</span>
                  </h2>
                  <div className="course__tabpane__card">
                    <ul id="qna-80" className="qna__tabpane__card__ul">
                      {/* questDataItem.questions */}
                      {questDataItem.questions.map((ques, index) => {

                        return (
                          <li
                            className="qna__tabpane__card__li s-566-cmnt-wrapper"
                            id={`allQna-566-${ques.id}`}
                            onClick={() => HandleCard(ques.id)}
                          >
                            <div className="s-566-cmnt-1 d-flex-box">
                              <div className="MuiBox-root MuiBox-root-2 commented__box">
                                <header className="MuiBox-root MuiBox-root-4 commented__head">
                                  <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                    <div
                                      className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${ques.profile_pic ? 'cir-transparent' : ques.avcolor}`}
                                    >
                                      {!ques.profile_pic ?
                                        <>
                                          {ques.shortname}
                                        </>
                                        :
                                        <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${ques.profile_pic}`} />

                                      }
                                    </div>
                                    <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                      <span>
                                        {ques.name} {ques.last_name}
                                      </span>
                                      <span
                                        className={`s566-edited-text quesedit-566-${ques.id}`}
                                        style={{
                                          display:
                                            ques.is_edited == 1 ? "" : "none",
                                        }}
                                      >
                                        <svg
                                          viewBox="0 0 6 6"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle cx={3} cy={3} r={3} />
                                        </svg>
                                        <label>Edited</label>
                                      </span>
                                    </p>
                                  </section>
                                </header>
                                <div className="MuiBox-root MuiBox-root-12 commented__body">
                                  <p className={`txt qnaAllTxt-${ques.id}`}>
                                    {ques.question}
                                  </p>
                                </div>
                                <section className="MuiBox-root MuiBox-root-10 cmnt__head__rgt">
                                  <span className="cmnt__head__rgt-date gray">
                                    {ques.datecreated_default}
                                  </span>
                                  <span className="from-text">
                                    Section: <label>{ques.section_name}</label>
                                  </span>
                                </section>
                              </div>
                              <div className="qa_share_like_wrap">
                                <button
                                  className={`commented__forward__btn ${ques.like} likeFront${ques.id} `}
                                  onClick={(e) =>
                                    HandleLikeClick(
                                      e,
                                      `likeFront${ques.id}`,
                                      ques.total_votes,
                                      1,
                                      ques.id
                                    )
                                  }
                                >
                                  <span className="commented__forward__btn-count">
                                    {likeObj[`likeFront${ques.id}`] > -1
                                      ? likeObj[`likeFront${ques.id}`]
                                      : ques.total_votes}
                                  </span>
                                  <img
                                    className="inactive-icon"
                                    src={
                                      require("../../assets/img/common-img/qa-like-icon.svg")
                                        .default
                                    }
                                  />
                                  <img
                                    className="active-icon"
                                    src={
                                      require("../../assets/img/common-img/qa-liked-icon.svg")
                                        .default
                                    }
                                  />
                                </button>
                                <button className="commented__forward__btn">
                                  <span className="commented__forward__btn-count">
                                    {ques.total_ans +
                                      tmpReplyObj.filter(
                                        (item) => item.ques_id === ques.id
                                      ).length -
                                      (tmpAnsReplyCount[`remAns${ques.id}`]
                                        ? tmpAnsReplyCount[`remAns${ques.id}`]
                                        : 0)}
                                  </span>
                                  <img
                                    src={
                                      require("../../assets/img/common-img/qa-reply-icon.svg")
                                        .default
                                    }
                                  />
                                </button>
                                {/* scnv-566 */}
                                <div
                                  className="scnv-566-3dots"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {ques.user_id === uid ? (
                                    <>
                                      <button
                                        className="btn-566-3dot"
                                        onClick={HandleDdl3dots}
                                      >
                                        <svg
                                          width={30}
                                          height={30}
                                          viewBox="0 0 30 30"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            className="hover-cir"
                                            cx={15}
                                            cy={15}
                                            r={15}
                                          />
                                          <circle
                                            cx={15}
                                            cy={7}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={15}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={23}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                        </svg>
                                      </button>
                                      <div className="ul-566-3dots">
                                        <button
                                          className="btn-s566 edit-btn"
                                          onClick={() => {
                                            CloseDdl3dots();
                                            HandleEditPopup(
                                              `allQna-566-${ques.id}`,ques.id,
                                              "open"
                                            );
                                          }}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn-s566 del-btn a"
                                          onClick={() => {
                                            CloseDdl3dots();
                                            setIsDel566(`ques-${ques.id}`);
                                            setTmpTelCardId([
                                              `allQna-566-${ques.id}`,
                                            ]);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btn-566-3dot ans"
                                        onClick={HandleDdl3dots}
                                      >
                                        <svg
                                          width={30}
                                          height={30}
                                          viewBox="0 0 30 30"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            className="hover-cir"
                                            cx={15}
                                            cy={15}
                                            r={15}
                                          />
                                          <circle
                                            cx={15}
                                            cy={7}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={15}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={23}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                        </svg>
                                      </button>
                                      <div className="ul-566-3dots reps">
                                        <button
                                          className="btn-s566"
                                          onClick={() => {
                                            CloseDdl3dots();
                                            HandleReportPopup("ques", ques.id);
                                          }}
                                        >
                                          <svg
                                            width={15}
                                            height={17}
                                            viewBox="0 0 15 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M8.8335 2.50033L7.91683 0.666992H0.583496V16.2503H2.41683V9.83366H7.00016L7.91683 11.667H14.3335V2.50033H8.8335ZM12.5002 9.83366H8.8335L7.91683 8.00033H2.41683V2.50033H7.00016L7.91683 4.33366H12.5002V9.83366Z"
                                              fill="#4D4D4F"
                                            />
                                          </svg>
                                          <span>Report Abuse</span>
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* scnv-566 end*/}
                              </div>
                            </div>
                            <div
                              className="s-566-cmnt-2 d-flex-box"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="MuiBox-root MuiBox-root-2 commented__box">
                                <header className="MuiBox-root MuiBox-root-4 commented__head">
                                  <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                    <div
                                      className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${ques.profile_pic ? 'cir-transparent' : ques.avcolor}`}
                                    >
                                      {!ques.profile_pic ?
                                        <>
                                          {ques.shortname}
                                        </>
                                        :
                                        <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${ques.profile_pic}`} />

                                      }
                                    </div>
                                    <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                      <span>
                                        {ques.name} {ques.last_name}
                                      </span>
                                    </p>
                                  </section>
                                </header>
                                <div className="MuiBox-root MuiBox-root-12 commented__body">
                                  <textarea
                                    className={`s-566-cmnt-textarea ${`textareaAlltextareaBack-${ques.id}`}`}
                                    id={`textareaAlltextareaBack-${ques.id}`}
                                    defaultValue={ques.question}
                                    onChange={(e) => 
                                      {
                                        handleReplyBtnDisbaledStatus(e)
                                      }
                                    }
                                  ></textarea>
                                  <div className="s-566-textarea-btns">
                                    <button
                                      disabled={editTextAreaArr[`textareaAlltextareaBack-${ques.id}`]?.length==0?true:false}
                                      className="btn save handle-btn-disabled"
                                      onClick={() => {
                                        HandleEditPopup(
                                          `allQna-566-${ques.id}`,ques.id,
                                          "close"
                                        );
                                        SaveEditQues(
                                          `textareaAlltextareaBack-${ques.id}`,
                                          `qnaAllTxt-${ques.id}`,
                                          `textareaAlltextareaBack-${ques.id}`,
                                          `quesedit-566-${ques.id}`
                                        );
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn cancel"
                                      onClick={() => {
                                        HandleEditPopup(
                                          `allQna-566-${ques.id}`,ques.id,
                                          "close"
                                        );
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* view question wrap end */}
                {questDataItem.questions.map((ques) => {
                  return (
                    <div
                      className="course__overview__frame__left back-to-q"
                      style={{ display: isCardReply === ques.id ? "" : "none" }}
                      id={`backQnaAll-${ques.id}`}
                    >
                      <button
                        className="h2"
                        onClick={() => {
                          HandleCard(0, true,ques.id);
                          setIsMoreReply({});
                          BackToCloseRepyBox();
                        }}
                      >
                        <img
                          className="qa-back_arrow"
                          src={
                            require("../../assets/img/common-img/qa-arrow_back.svg")
                              .default
                          }
                        />
                        Back to all questions
                      </button>
                      <div className="course__tabpane__card">
                        <ul id="qna-80" className="qna__tabpane__card__ul">
                          <li
                            className="qna__tabpane__card__li s-566-cmnt-wrapper"
                            id={`backQna-566-${ques.id}`}
                          >
                            <div
                              className={`s-566-cmnt-1 d-flex-box flex-wrap ${props.hashvalue ? "highlighttc" : ""
                                }`}
                            >
                              <div className="MuiBox-root MuiBox-root-2 commented__box">
                                <header className="MuiBox-root MuiBox-root-4 commented__head">
                                  <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                    <div
                                      className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${ques.profile_pic ? 'cir-transparent' : ques.avcolor}`}
                                    >
                                      {!ques.profile_pic ?
                                        <>
                                          {ques.shortname}
                                        </>
                                        :

                                        <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${ques.profile_pic}`} />
                                      }
                                    </div>
                                    <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                      <span>
                                        {ques.name} {ques.last_name}
                                      </span>
                                      <span
                                        className={`s566-edited-text quesedit-566-${ques.id}`}
                                        style={{
                                          display:
                                            ques.is_edited == 1 ? "" : "none",
                                        }}
                                      >
                                        <svg
                                          viewBox="0 0 6 6"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle cx={3} cy={3} r={3} />
                                        </svg>
                                        <label>Edited</label>
                                      </span>
                                    </p>
                                  </section>
                                </header>
                                <div className="MuiBox-root MuiBox-root-12 commented__body">
                                  <p className={`txt qnaAllTxt-${ques.id}`}>
                                    {ques.question}
                                  </p>
                                </div>
                                <section className="MuiBox-root MuiBox-root-10 cmnt__head__rgt">
                                  <span className="cmnt__head__rgt-date gray">
                                    {ques.datecreated_default}
                                  </span>
                                  <span className="from-text">
                                    Section: <label>{ques.section_name}</label>
                                  </span>
                                </section>
                              </div>
                              <div className="qa_share_like_wrap">
                                <button
                                  className={`commented__forward__btn ${ques.like} likeFront${ques.id}`}
                                  onClick={(e) =>
                                    HandleLikeClick(
                                      e,
                                      `likeFront${ques.id}`,
                                      ques.total_votes,
                                      1,
                                      ques.id
                                    )
                                  }
                                >
                                  <span className="commented__forward__btn-count">
                                    {likeObj[`likeFront${ques.id}`] > -1
                                      ? likeObj[`likeFront${ques.id}`]
                                      : ques.total_votes}
                                  </span>
                                  <img
                                    className="inactive-icon"
                                    src={
                                      require("../../assets/img/common-img/qa-like-icon.svg")
                                        .default
                                    }
                                  />
                                  <img
                                    className="active-icon"
                                    src={
                                      require("../../assets/img/common-img/qa-liked-icon.svg")
                                        .default
                                    }
                                  />
                                </button>
                                {/* scnv-566 */}
                                <div
                                  className="scnv-566-3dots"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {ques.user_id === uid ? (
                                    <>
                                      <button
                                        className="btn-566-3dot"
                                        onClick={HandleDdl3dots}
                                      >
                                        <svg
                                          width={30}
                                          height={30}
                                          viewBox="0 0 30 30"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            className="hover-cir"
                                            cx={15}
                                            cy={15}
                                            r={15}
                                          />
                                          <circle
                                            cx={15}
                                            cy={7}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={15}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={23}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                        </svg>
                                      </button>
                                      <div className="ul-566-3dots">
                                        <button
                                          className="btn-s566 edit-btn"
                                          onClick={() => {
                                            CloseDdl3dots();
                                            HandleEditPopup(
                                              `backQna-566-${ques.id}`,ques.id,
                                              "open"
                                            );
                                          }}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn-s566 del-btn a"
                                          onClick={() => {
                                            CloseDdl3dots();
                                            setIsDel566(`ques-${ques.id}`);
                                            setTmpTelCardId([
                                              `allQna-566-${ques.id}`,
                                              `backQnaAll-${ques.id}`,
                                            ]);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btn-566-3dot ans"
                                        onClick={HandleDdl3dots}
                                      >
                                        <svg
                                          width={30}
                                          height={30}
                                          viewBox="0 0 30 30"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            className="hover-cir"
                                            cx={15}
                                            cy={15}
                                            r={15}
                                          />
                                          <circle
                                            cx={15}
                                            cy={7}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={15}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                          <circle
                                            cx={15}
                                            cy={23}
                                            r={2}
                                            fill="#7D868C"
                                          />
                                        </svg>
                                      </button>
                                      <div className="ul-566-3dots reps">
                                        <button
                                          className="btn-s566"
                                          onClick={() => {
                                            CloseDdl3dots();
                                            HandleReportPopup("ques", ques.id);
                                          }}
                                        >
                                          <svg
                                            width={15}
                                            height={17}
                                            viewBox="0 0 15 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M8.8335 2.50033L7.91683 0.666992H0.583496V16.2503H2.41683V9.83366H7.00016L7.91683 11.667H14.3335V2.50033H8.8335ZM12.5002 9.83366H8.8335L7.91683 8.00033H2.41683V2.50033H7.00016L7.91683 4.33366H12.5002V9.83366Z"
                                              fill="#4D4D4F"
                                            />
                                          </svg>
                                          <span>Report Abuse</span>
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* scnv-566 end*/}
                              </div>

                              <div className="qa-rep-foll-wrap">
                                <span className={`qa-reply repid-${ques.id}`}>
                                  {ques.total_ans +
                                    tmpReplyObj.filter(
                                      (item) => item.ques_id === ques.id
                                    ).length -
                                    (tmpAnsReplyCount[`remAns${ques.id}`]
                                      ? tmpAnsReplyCount[`remAns${ques.id}`]
                                      : 0)}{" "}
                                  {ques.total_ans +
                                    tmpReplyObj.filter(
                                      (item) => item.ques_id === ques.id
                                    ).length -
                                    (tmpAnsReplyCount[`remAns${ques.id}`]
                                      ? tmpAnsReplyCount[`remAns${ques.id}`]
                                      : 0) >
                                    1
                                    ? "Replies"
                                    : "Reply"}
                                </span>
                                {ques.user_id != uid && (
                                  <button
                                    className={`qa-follow ${ques.follow == 1 ? "active" : ""
                                      }`}
                                    onClick={(e) =>
                                      HandleFollowReply(e, uid, ques.id)
                                    }
                                  >
                                    <span className="qa-follow-icon">
                                      <svg
                                        className="qa-follow-in"
                                        width={16}
                                        height={12}
                                        viewBox="0 0 16 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5.50013 9.47292L2.02513 5.99792L0.841797 7.17292L5.50013 11.8312L15.5001 1.83125L14.3251 0.65625L5.50013 9.47292Z"
                                          fill="#4D4D4F"
                                        />
                                      </svg>
                                    </span>
                                    <span className="inactive">
                                      Follow Replies
                                    </span>
                                    <span className="active">Following</span>
                                  </button>
                                )}
                              </div>
                            </div>

                            <div
                              className="s-566-cmnt-2 d-flex-box"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="MuiBox-root MuiBox-root-2 commented__box">
                                <header className="MuiBox-root MuiBox-root-4 commented__head">
                                  <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                    <div
                                      className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${ques.profile_pic ? 'cir-transparent' : ques.avcolor}`}
                                    >
                                      {!ques.profile_pic ?
                                        <>
                                          {ques.shortname}
                                        </>
                                        :

                                        <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${ques.profile_pic}`} />
                                      }
                                    </div>
                                    <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                      <span>
                                        {ques.name} {ques.last_name}
                                      </span>
                                    </p>
                                  </section>
                                </header>
                                <div className="MuiBox-root MuiBox-root-12 commented__body">
                                  <textarea
                                    className={`s-566-cmnt-textarea ${`textareaAlltextareaBack-${ques.id}`}`}
                                    id={`textareaBacktextareaBack-${ques.id}`}
                                    defaultValue={ques.question}
                                    onChange={(e) => 
                                      {
                                        handleReplyBtnDisbaledStatus(e)
                                      }
                                    }
                                  ></textarea>
                                  <div className="s-566-textarea-btns">
                                    <button
                                      disabled={editTextAreaArr[`textareaBacktextareaBack-${ques.id}`]?.length==0?true:false}
                                      className="btn save handle-btn-disabled"
                                      onClick={() => {
                                        HandleEditPopup(
                                          `backQna-566-${ques.id}`,ques.id,
                                          "close"
                                        );
                                        SaveEditQues(
                                          `textareaAlltextareaBack-${ques.id}`,
                                          `qnaAllTxt-${ques.id}`,
                                          `textareaBacktextareaBack-${ques.id}`,
                                          `quesedit-566-${ques.id}`
                                        );
                                      }}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn cancel"
                                      onClick={() => {
                                        HandleEditPopup(
                                          `backQna-566-${ques.id}`,ques.id,
                                          "close"
                                        );
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>

                          {ques.answers.length ? (
                            ques.answers.map((ans, repIndex) => (
                              <>
                                {/* scnv-566 */}
                                <li
                                  className="qna__tabpane__card__li s-566-cmnt-wrapper"
                                  id={`allQnaback-566-${ans.id}`}
                                >
                                  <div className="s-566-cmnt-1 d-flex-box">
                                    <div className="MuiBox-root MuiBox-root-2 commented__box">
                                      <header className="MuiBox-root MuiBox-root-4 commented__head">
                                        <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                          {/* <div
                                            className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault`}
                                          >
                                            {!ans.profile_pic ?
                                              <div className={`header-profile-cir ${ans.avcolor}`}>
                                                {ans.shortname}
                                              </div>
                                              :
                                              <div className={`header-profile-cir`}>
											
                                               <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${ans.profile_pic}`}/>
                                              </div>}
                                          </div> */}
                                          <div
                                            className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${ans.profile_pic ? 'cir-transparent' : ans.avcolor}`}
                                          >
                                            {!ans.profile_pic ?
                                              <>
                                                {ans.shortname}
                                              </>
                                              :
                                              <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${ans.profile_pic}`} />

                                            }
                                          </div>
                                          <p className="MuiBox-root MuiBox-root-8 cmnt__head__name ansr">
                                            <span>
                                              {ans.name} {ans.last_name}
                                            </span>
                                            <span
                                              className={`s566-edited-text ansedit-566-${ans.id}`}
                                              style={{
                                                display:
                                                  ans.is_edited == 1
                                                    ? ""
                                                    : "none",
                                              }}
                                            >
                                              <svg
                                                viewBox="0 0 6 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              <label>Edited</label>
                                            </span>
                                          </p>
                                        </section>
                                      </header>
                                      <div className="MuiBox-root MuiBox-root-12 commented__body">
                                        <p
                                          className="txt"
                                          id={`qnaAnsTxt-${ans.id}`}
                                        >
                                          {ans.answer}
                                        </p>
                                      </div>
                                      <section className="MuiBox-root MuiBox-root-10 cmnt__head__rgt">
                                        <span
                                          className="cmnt__head__rgt-date gray"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            HandleReplyPopup(
                                              `singleQnabackRply-566-${ans.id}`,
                                              ans.id,
                                              "open"
                                            );
                                          }}
                                        >
                                          Reply
                                        </span>
                                        <span className="cmnt__head__rgt-date gray">
                                          {ans.datecreated_default}
                                        </span>
                                        <span className="cmnt__head__rgt-date gray">
                                          {ans.reply_ans.length +
                                            tmpReplyObj.filter(
                                              (item) => item.ans_id === ans.id
                                            ).length -
                                            (tmpAnsSubReplyCount[
                                              `remSubAns${ans.id}`
                                            ] &&
                                              tmpAnsSubReplyCount[
                                              `remSubAns${ans.id}`
                                              ] > 0
                                              ? tmpAnsSubReplyCount[
                                              `remSubAns${ans.id}`
                                              ]
                                              : 0)}{" "}
                                          {ans.reply_ans.length +
                                            tmpReplyObj.filter(
                                              (item) => item.ans_id === ans.id
                                            ).length -
                                            (tmpAnsSubReplyCount[
                                              `remSubAns${ans.id}`
                                            ] &&
                                              tmpAnsSubReplyCount[
                                              `remSubAns${ans.id}`
                                              ] > 0
                                              ? tmpAnsSubReplyCount[
                                              `remSubAns${ans.id}`
                                              ]
                                              : 0) >
                                            1
                                            ? "Replies"
                                            : "Reply"}
                                        </span>
                                        <span className="from-text">
                                          Section:{" "}
                                          <label>{ques.section_name}</label>
                                        </span>
                                      </section>
                                      {ques.user_id === uid &&
                                        ans.user_id != uid &&
                                        ans.opted == 0 ? (
                                        <div
                                          className="was-helpful-div"
                                          id={`wasHelpFul${ans.id}`}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <p className="text-blu">
                                            Was this answer helpful?
                                          </p>
                                          <button
                                            className="link"
                                            onClick={() =>
                                              WasHelpFul(
                                                1,
                                                ans.id,
                                                `wasHelpFul${ans.id}`
                                              )
                                            }
                                          >
                                            Yes
                                          </button>
                                          <button
                                            className="link"
                                            onClick={() =>
                                              WasHelpFul(
                                                2,
                                                ans.id,
                                                `wasHelpFul${ans.id}`
                                              )
                                            }
                                          >
                                            No
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="qa_share_like_wrap">
                                      <button
                                        className={`commented__forward__btn ${ans.like} likeReply${ans.id}`}
                                        onClick={(e) =>
                                          HandleLikeClick(
                                            e,
                                            `likeReply${ans.id}`,
                                            ans.total_votes,
                                            2,
                                            ans.id
                                          )
                                        }
                                      >
                                        <span className="commented__forward__btn-count">
                                          {likeObj[`likeReply${ans.id}`] > -1
                                            ? likeObj[`likeReply${ans.id}`]
                                            : ans.total_votes}
                                        </span>
                                        <img
                                          className="inactive-icon"
                                          src={
                                            require("../../assets/img/common-img/qa-like-icon.svg")
                                              .default
                                          }
                                        />
                                        <img
                                          className="active-icon"
                                          src={
                                            require("../../assets/img/common-img/qa-liked-icon.svg")
                                              .default
                                          }
                                        />
                                      </button>
                                      {/* scnv-566 */}
                                      <div
                                        className="scnv-566-3dots"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        {ans.user_id === uid ? (
                                          <>
                                            <button
                                              className="btn-566-3dot ans"
                                              onClick={HandleDdl3dots}
                                            >
                                              <svg
                                                width={30}
                                                height={30}
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <circle
                                                  className="hover-cir"
                                                  cx={15}
                                                  cy={15}
                                                  r={15}
                                                />
                                                <circle
                                                  cx={15}
                                                  cy={7}
                                                  r={2}
                                                  fill="#7D868C"
                                                />
                                                <circle
                                                  cx={15}
                                                  cy={15}
                                                  r={2}
                                                  fill="#7D868C"
                                                />
                                                <circle
                                                  cx={15}
                                                  cy={23}
                                                  r={2}
                                                  fill="#7D868C"
                                                />
                                              </svg>
                                            </button>
                                            <div className="ul-566-3dots">
                                              <button
                                                className="btn-s566 edit-btn"
                                                onClick={() => {
                                                  CloseDdl3dots();
                                                  HandleEditPopup(
                                                    `allQnaback-566-${ans.id}`,
                                                    "open"
                                                  );
                                                }}
                                              >
                                                Edit
                                              </button>
                                              <button
                                                className="btn-s566 del-btn b"
                                                onClick={() => {
                                                  CloseDdl3dots();
                                                  setIsDel566(`ans-${ans.id}`);
                                                  setTmpTelCardId([
                                                    `allQnaback-566-${ans.id}`,
                                                  ]);
                                                  setTmpAnsReplyData({
                                                    [`remAns${ques.id}`]:
                                                      tmpAnsReplyCount[
                                                        `remAns${ques.id}`
                                                      ]
                                                        ? tmpAnsReplyCount[
                                                        `remAns${ques.id}`
                                                        ]
                                                        : 0,
                                                  });
                                                }}
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="btn-566-3dot ans"
                                              onClick={HandleDdl3dots}
                                            >
                                              <svg
                                                width={30}
                                                height={30}
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <circle
                                                  className="hover-cir"
                                                  cx={15}
                                                  cy={15}
                                                  r={15}
                                                />
                                                <circle
                                                  cx={15}
                                                  cy={7}
                                                  r={2}
                                                  fill="#7D868C"
                                                />
                                                <circle
                                                  cx={15}
                                                  cy={15}
                                                  r={2}
                                                  fill="#7D868C"
                                                />
                                                <circle
                                                  cx={15}
                                                  cy={23}
                                                  r={2}
                                                  fill="#7D868C"
                                                />
                                              </svg>
                                            </button>
                                            <div className="ul-566-3dots reps">
                                              <button
                                                className="btn-s566"
                                                onClick={() => {
                                                  CloseDdl3dots();
                                                  HandleReportPopup(
                                                    "ans",
                                                    ans.id
                                                  );
                                                }}
                                              >
                                                <svg
                                                  width={15}
                                                  height={17}
                                                  viewBox="0 0 15 17"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M8.8335 2.50033L7.91683 0.666992H0.583496V16.2503H2.41683V9.83366H7.00016L7.91683 11.667H14.3335V2.50033H8.8335ZM12.5002 9.83366H8.8335L7.91683 8.00033H2.41683V2.50033H7.00016L7.91683 4.33366H12.5002V9.83366Z"
                                                    fill="#4D4D4F"
                                                  />
                                                </svg>
                                                <span>Report Abuse</span>
                                              </button>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      {/* scnv-566 end*/}
                                    </div>
                                  </div>
                                  <div
                                    className="s-566-cmnt-2 d-flex-box"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <div className="MuiBox-root MuiBox-root-2 commented__box">
                                      <header className="MuiBox-root MuiBox-root-4 commented__head">
                                        <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                          <div
                                            className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${ans.profile_pic ? 'cir-transparent' : ans.avcolor}`}
                                          >
                                            {!ans.profile_pic ?
                                              <>
                                                {ans.shortname}
                                              </>
                                              :
                                              <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${ans.profile_pic}`} />

                                            }
                                          </div>
                                          <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                            <span>
                                              {ans.name} {ans.last_name}
                                            </span>
                                          </p>
                                        </section>
                                      </header>
                                      <div className="MuiBox-root MuiBox-root-12 commented__body">
                                        <textarea
                                          className="s-566-cmnt-textarea"
                                          id={`textareaBacktextareaBack-${ans.id}`}
                                          defaultValue={ans.answer}
                                          onChange={(e) => 
                                            {
                                              handleReplyBtnDisbaledStatus(e)
                                            }
                                          }
                                        ></textarea>
                                        <div className="s-566-textarea-btns">
                                          <button
                                            className="btn save handle-btn-disabled"
                                            onClick={() => {
                                              HandleEditPopup(
                                                `allQnaback-566-${ans.id}`,
                                                "close"
                                              );
                                              SaveEditAns(
                                                `textareaBacktextareaBack-${ans.id}`,
                                                `qnaAnsTxt-${ans.id}`,
                                                `ansedit-566-${ans.id}`
                                              );
                                            }}
                                          >
                                            Save
                                          </button>
                                          <button
                                            className="btn cancel"
                                            onClick={() => {
                                              HandleEditPopup(
                                                `allQnaback-566-${ans.id}`,
                                                "close"
                                              );
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* s-566-reply-ul start */}
                                  <ul
                                    className={`s-566-reply-ul ${ans.reply_ans.length > 1 ? "pt-2rm" : ""
                                      }`}
									  style={{borderTop:ans.reply_ans.length==0?'0':'', paddingBottom:ans.reply_ans.length==0?'0':''}}
                                  >
                                    {/* <ul className={`s-566-reply-ul ${(ans.reply_ans.length > 1) || (tmpReplyObj.length>1 && !ans.reply_ans.length) ? 'pt-2rm':''}`}> */}
                                    <li className="load-replies-btn-li">
                                      <button
                                        className="load-replies-btn"
                                        onClick={() =>
                                          setIsMoreReply({
                                            ...isMoreReply,
                                            [`rp${ans.id}`]:
                                              !isMoreReply[`rp${ans.id}`],
                                          })
                                        }
                                      >
                                        {isMoreReply[`rp${ans.id}`]
                                          ? "Hide replies"
                                          : "Load previous replies"}
                                      </button>
                                    </li>

                                    {ans.reply_ans.length
                                      ? ans.reply_ans.map(
                                        (reply_ans_list, repIndex) => (
                                          <>
                                            {!isMoreReply[`rp${ans.id}`] ? (
                                              <>
                                                {repIndex ==
                                                  ans.reply_ans.length -
                                                  1 && (
                                                    <li
                                                      className={`qna__tabpane__card__li s-566-cmnt-wrapper ${ans.reply_ans.length ==
                                                        1 && "no-after"
                                                        }`}
                                                      id={`allQnabackRply-566-${reply_ans_list.id}`}
                                                    >
                                                      <div className="s-566-cmnt-1 d-flex-box">
                                                        <div className="MuiBox-root MuiBox-root-2 commented__box">
                                                          <header className="MuiBox-root MuiBox-root-4 commented__head">
                                                            <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                                              {/* <div
                                                                className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault`}
                                                              >
                                                                {!reply_ans_list.profile_pic ?
                                                                  <div className={`header-profile-cir ${reply_ans_list.avcolor}`}>
                                                                    {reply_ans_list.shortname}
                                                                  </div>
                                                                  :
                                                                  <div className={`header-profile-cir`}>
																  <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${reply_ans_list.profile_pic}`}/>
                                                                                                                                      </div>}
                                                              </div> */}
                                                              <div
                                                                className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault   ${reply_ans_list.profile_pic ? 'cir-transparent' : reply_ans_list.avcolor}`}
                                                              >
                                                                {!reply_ans_list.profile_pic ?
                                                                  <>
                                                                    {reply_ans_list.shortname}
                                                                  </>
                                                                  :

                                                                  <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${reply_ans_list.profile_pic}`} />
                                                                }
                                                              </div>
                                                              <p className="MuiBox-root MuiBox-root-8 cmnt__head__name arn">
                                                                <span>
                                                                  {
                                                                    reply_ans_list.name
                                                                  }{" "}
                                                                  {
                                                                    reply_ans_list.last_name
                                                                  }
                                                                </span>
                                                                <span
                                                                  className={`s566-edited-text rplyansedit-566-${reply_ans_list.id}`}
                                                                  style={{
                                                                    display:
                                                                      reply_ans_list.is_edited ==
                                                                        1
                                                                        ? ""
                                                                        : "none",
                                                                  }}
                                                                >
                                                                  <svg
                                                                    viewBox="0 0 6 6"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <circle
                                                                      cx={3}
                                                                      cy={3}
                                                                      r={3}
                                                                    />
                                                                  </svg>
                                                                  <label>
                                                                    Edited
                                                                  </label>
                                                                </span>
                                                              </p>
                                                            </section>
                                                          </header>
                                                          <div className="MuiBox-root MuiBox-root-12 commented__body">
                                                            <p className="txt">
                                                              <span className="mentioned-user">
                                                                {ans.name}{" "}
                                                                {ans.last_name}
                                                              </span>
                                                              <span
                                                                id={`qnaAnsRplyTxt-${reply_ans_list.id}`}
                                                              >
                                                                {
                                                                  reply_ans_list.answer
                                                                }
                                                              </span>
                                                            </p>
                                                          </div>
                                                          <section className="MuiBox-root MuiBox-root-10 cmnt__head__rgt">
                                                            <span
                                                              className="cmnt__head__rgt-date gray"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                HandleReplyPopup(
                                                                  `singleQnabackRply-566-${ans.id}`,
                                                                  ans.id,
                                                                  "open"
                                                                );
                                                              }}
                                                            >
                                                              Reply
                                                            </span>
                                                            <span className="cmnt__head__rgt-date gray">
                                                              {
                                                                reply_ans_list.datecreated_default
                                                              }
                                                            </span>
                                                            <span className="from-text">
                                                              Section:{" "}
                                                              <label>
                                                                {
                                                                  reply_ans_list.section_name
                                                                }
                                                              </label>
                                                            </span>
                                                          </section>
                                                        </div>

                                                        <div className="qa_share_like_wrap">
                                                          <button
                                                            className={`commented__forward__btn rpl ${reply_ans_list.like} likeAns${reply_ans_list.id}`}
                                                            onClick={(e) =>
                                                              HandleLikeClick(
                                                                e,
                                                                `likeAns${reply_ans_list.id}`,
                                                                reply_ans_list.total_votes,
                                                                3,
                                                                reply_ans_list.id
                                                              )
                                                            }
                                                          >
                                                            <span className="commented__forward__btn-count">
                                                              {likeObj[
                                                                `likeAns${reply_ans_list.id}`
                                                              ] > -1
                                                                ? likeObj[
                                                                `likeAns${reply_ans_list.id}`
                                                                ]
                                                                : reply_ans_list.total_votes}
                                                            </span>

                                                            <img
                                                              className="inactive-icon"
                                                              src={
                                                                require("../../assets/img/common-img/qa-like-icon.svg")
                                                                  .default
                                                              }
                                                            />
                                                            <img
                                                              className="active-icon"
                                                              src={
                                                                require("../../assets/img/common-img/qa-liked-icon.svg")
                                                                  .default
                                                              }
                                                            />
                                                          </button>
                                                          {/* scnv-566 */}
                                                          <div
                                                            className="scnv-566-3dots"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                            }}
                                                          >
                                                            {reply_ans_list.user_id ===
                                                              uid ? (
                                                              <>
                                                                <button
                                                                  className="btn-566-3dot"
                                                                  onClick={
                                                                    HandleDdl3dots
                                                                  }
                                                                >
                                                                  <svg
                                                                    width={30}
                                                                    height={30}
                                                                    viewBox="0 0 30 30"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <circle
                                                                      className="hover-cir"
                                                                      cx={15}
                                                                      cy={15}
                                                                      r={15}
                                                                    />
                                                                    <circle
                                                                      cx={15}
                                                                      cy={7}
                                                                      r={2}
                                                                      fill="#7D868C"
                                                                    />
                                                                    <circle
                                                                      cx={15}
                                                                      cy={15}
                                                                      r={2}
                                                                      fill="#7D868C"
                                                                    />
                                                                    <circle
                                                                      cx={15}
                                                                      cy={23}
                                                                      r={2}
                                                                      fill="#7D868C"
                                                                    />
                                                                  </svg>
                                                                </button>
                                                                <div className="ul-566-3dots">
                                                                  <button
                                                                    className="btn-s566 edit-btn"
                                                                    onClick={() => {
                                                                      CloseDdl3dots();
                                                                      HandleEditPopup(
                                                                        `allQnabackRply-566-${reply_ans_list.id}`,
                                                                        "open"
                                                                      );
                                                                    }}
                                                                  >
                                                                    Edit
                                                                  </button>
                                                                  <button
                                                                    className="btn-s566 del-btn c"
                                                                    onClick={() => {
                                                                      CloseDdl3dots();
                                                                      setIsDel566(
                                                                        `rep-${reply_ans_list.id}`
                                                                      );
                                                                      setTmpTelCardId(
                                                                        [
                                                                          `allQnabackRply-566-${reply_ans_list.id}`,
                                                                        ]
                                                                      );
                                                                      setTmpAnsReplyData(
                                                                        {
                                                                          [`remAns${ques.id}`]:
                                                                            tmpAnsReplyCount[
                                                                              `remAns${ques.id}`
                                                                            ]
                                                                              ? tmpAnsReplyCount[
                                                                              `remAns${ques.id}`
                                                                              ]
                                                                              : 0,
                                                                        }
                                                                      );
                                                                      setTmpAnsSubReplyData(
                                                                        {
                                                                          [`remSubAns${ans.id}`]:
                                                                            tmpAnsSubReplyCount[
                                                                              `remSubAns${ans.id}`
                                                                            ]
                                                                              ? tmpAnsSubReplyCount[
                                                                              `remSubAns${ans.id}`
                                                                              ]
                                                                              : 0,
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    Delete
                                                                  </button>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <button
                                                                  className="btn-566-3dot ans"
                                                                  onClick={
                                                                    HandleDdl3dots
                                                                  }
                                                                >
                                                                  <svg
                                                                    width={30}
                                                                    height={30}
                                                                    viewBox="0 0 30 30"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <circle
                                                                      className="hover-cir"
                                                                      cx={15}
                                                                      cy={15}
                                                                      r={15}
                                                                    />
                                                                    <circle
                                                                      cx={15}
                                                                      cy={7}
                                                                      r={2}
                                                                      fill="#7D868C"
                                                                    />
                                                                    <circle
                                                                      cx={15}
                                                                      cy={15}
                                                                      r={2}
                                                                      fill="#7D868C"
                                                                    />
                                                                    <circle
                                                                      cx={15}
                                                                      cy={23}
                                                                      r={2}
                                                                      fill="#7D868C"
                                                                    />
                                                                  </svg>
                                                                </button>
                                                                <div className="ul-566-3dots reps">
                                                                  <button
                                                                    className="btn-s566"
                                                                    onClick={() => {
                                                                      CloseDdl3dots();
                                                                      HandleReportPopup(
                                                                        "ans_reply",
                                                                        reply_ans_list.id
                                                                      );
                                                                    }}
                                                                  >
                                                                    <svg
                                                                      width={15}
                                                                      height={
                                                                        17
                                                                      }
                                                                      viewBox="0 0 15 17"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <path
                                                                        d="M8.8335 2.50033L7.91683 0.666992H0.583496V16.2503H2.41683V9.83366H7.00016L7.91683 11.667H14.3335V2.50033H8.8335ZM12.5002 9.83366H8.8335L7.91683 8.00033H2.41683V2.50033H7.00016L7.91683 4.33366H12.5002V9.83366Z"
                                                                        fill="#4D4D4F"
                                                                      />
                                                                    </svg>
                                                                    <span>
                                                                      Report
                                                                      Abuse
                                                                    </span>
                                                                  </button>
                                                                </div>
                                                              </>
                                                            )}
                                                          </div>
                                                          {/* scnv-566 end*/}
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="s-566-cmnt-2 d-flex-box"
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                      >
                                                        <div className="MuiBox-root MuiBox-root-2 commented__box">
                                                          <header className="MuiBox-root MuiBox-root-4 commented__head">
                                                            <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                                              {/* <div
                                                                className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault`}
                                                              >
                                                                {!questDataItem.profile_pic ?
                                                                  <div className={`header-profile-cir ${questDataItem.avcolor}`}>
                                                                    {questDataItem.shortname}
                                                                  </div>
                                                                  :
                                                                  <div className={`header-profile-cir`}>
																  <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`}/>
                                                                   
                                                                  </div>}
                                                              </div> */}
                                                              <div
                                                                className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${questDataItem.profile_pic ? 'cir-transparent' : questDataItem.avcolor}`}
                                                              >
                                                                {!questDataItem.profile_pic ?
                                                                  <>
                                                                    {questDataItem.shortname}
                                                                  </>
                                                                  :

                                                                  <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`} />
                                                                }
                                                              </div>
                                                              <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                                                <span>
                                                                  {
                                                                    questDataItem.name
                                                                  }{" "}
                                                                  {
                                                                    questDataItem.last_name
                                                                  }
                                                                </span>
                                                              </p>
                                                            </section>
                                                          </header>
                                                          <div className="MuiBox-root MuiBox-root-12 commented__body">
                                                            <textarea
                                                              className="s-566-cmnt-textarea 1"
                                                              id={`textareaRplytextareaBack-${reply_ans_list.id}`}
                                                              defaultValue={
                                                                reply_ans_list.answer
                                                              }
                                                              onChange={(e) => 
                                                                {
                                                                  handleReplyBtnDisbaledStatus(e)
                                                                }
                                                              }
                                                            ></textarea>
                                                            <div className="s-566-textarea-btns">
                                                              <button
                                                                disabled={editTextAreaArr[`textareaRplytextareaBack-${reply_ans_list.id}`]?.length==0?true:false}
                                                                className="btn save handle-btn-disabled"
                                                                onClick={() => {
                                                                  HandleEditPopup(
                                                                    `allQnabackRply-566-${reply_ans_list.id}`,
                                                                    "close"
                                                                  );
                                                                  PostEditReply(
                                                                    `textareaRplytextareaBack-${reply_ans_list.id}`,
                                                                    `qnaAnsRplyTxt-${reply_ans_list.id}`,
                                                                    `rplyansedit-566-${reply_ans_list.id}`
                                                                  );
                                                                }}
                                                              >
                                                                Save
                                                              </button>
                                                              <button
                                                                className="btn cancel"
                                                                onClick={() => {
                                                                  HandleEditPopup(
                                                                    `allQnabackRply-566-${reply_ans_list.id}`,
                                                                    "close"
                                                                  );
                                                                }}
                                                              >
                                                                Cancel
                                                              </button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )}
                                                {/* qna__tabpane__card__li end 1 */}
                                              </>
                                            ) : (
                                              <>
                                                <li
                                                  className={`qna__tabpane__card__li s-566-cmnt-wrapper ${ans.reply_ans.length ==
                                                    1 && "no-after"
                                                    }`}
                                                  id={`allQnabackRply-566-${reply_ans_list.id}`}
                                                >
                                                  <div className="s-566-cmnt-1 d-flex-box">
                                                    <div className="MuiBox-root MuiBox-root-2 commented__box">
                                                      <header className="MuiBox-root MuiBox-root-4 commented__head">
                                                        <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                                          <div
                                                            className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault    ${reply_ans_list.profile_pic ? 'cir-transparent' : reply_ans_list.avcolor}`}
                                                          >
                                                            {!reply_ans_list.profile_pic ?
                                                              <>
                                                                {reply_ans_list.shortname}
                                                              </>
                                                              :
                                                              <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${reply_ans_list.profile_pic}`} />

                                                            }
                                                          </div>
                                                          <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                                            <span>
                                                              {
                                                                reply_ans_list.name
                                                              }{" "}
                                                              {
                                                                reply_ans_list.last_name
                                                              }
                                                            </span>
                                                            <span
                                                              className={`s566-edited-text rplyansedit-566-${reply_ans_list.id}`}
                                                              style={{
                                                                display:
                                                                  reply_ans_list.is_edited ==
                                                                    1
                                                                    ? ""
                                                                    : "none",
                                                              }}
                                                            >
                                                              <svg
                                                                viewBox="0 0 6 6"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <circle
                                                                  cx={3}
                                                                  cy={3}
                                                                  r={3}
                                                                />
                                                              </svg>
                                                              <label>
                                                                Edited
                                                              </label>
                                                            </span>
                                                          </p>
                                                        </section>
                                                      </header>
                                                      <div className="MuiBox-root MuiBox-root-12 commented__body">
                                                        <p className="txt">
                                                          <span className="mentioned-user">
                                                            {ans.name}{" "}
                                                            {ans.last_name}
                                                          </span>
                                                          <span
                                                            id={`qnaAnsRplyTxt-${reply_ans_list.id}`}
                                                          >
                                                            {
                                                              reply_ans_list.answer
                                                            }
                                                          </span>
                                                        </p>
                                                      </div>
                                                      <section className="MuiBox-root MuiBox-root-10 cmnt__head__rgt">
                                                        <span
                                                          className="cmnt__head__rgt-date gray"
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            HandleReplyPopup(
                                                              `singleQnabackRply-566-${ans.id}`,
                                                              ans.id,
                                                              "open"
                                                            );
                                                          }}
                                                        >
                                                          Reply
                                                        </span>
                                                        <span className="cmnt__head__rgt-date gray">
                                                          {
                                                            reply_ans_list.datecreated_default
                                                          }
                                                        </span>
                                                        <span className="from-text">
                                                          Section:{" "}
                                                          <label>
                                                            {
                                                              reply_ans_list.section_name
                                                            }
                                                          </label>
                                                        </span>
                                                      </section>
                                                    </div>

                                                    <div className="qa_share_like_wrap">
                                                      <button
                                                        className={`commented__forward__btn rpl ${reply_ans_list.like} likeAns${reply_ans_list.id}`}
                                                        onClick={(e) =>
                                                          HandleLikeClick(
                                                            e,
                                                            `likeAns${reply_ans_list.id}`,
                                                            reply_ans_list.total_votes,
                                                            3,
                                                            reply_ans_list.id
                                                          )
                                                        }
                                                      >
                                                        <span className="commented__forward__btn-count">
                                                          {likeObj[
                                                            `likeAns${reply_ans_list.id}`
                                                          ] > -1
                                                            ? likeObj[
                                                            `likeAns${reply_ans_list.id}`
                                                            ]
                                                            : reply_ans_list.total_votes}
                                                        </span>

                                                        <img
                                                          className="inactive-icon"
                                                          src={
                                                            require("../../assets/img/common-img/qa-like-icon.svg")
                                                              .default
                                                          }
                                                        />
                                                        <img
                                                          className="active-icon"
                                                          src={
                                                            require("../../assets/img/common-img/qa-liked-icon.svg")
                                                              .default
                                                          }
                                                        />
                                                      </button>
                                                      {/* scnv-566 */}
                                                      <div
                                                        className="scnv-566-3dots"
                                                        onClick={(e) => {
                                                          e.stopPropagation();
                                                        }}
                                                      >
                                                        {reply_ans_list.user_id ===
                                                          uid ? (
                                                          <>
                                                            <button
                                                              className="btn-566-3dot"
                                                              onClick={
                                                                HandleDdl3dots
                                                              }
                                                            >
                                                              <svg
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <circle
                                                                  className="hover-cir"
                                                                  cx={15}
                                                                  cy={15}
                                                                  r={15}
                                                                />
                                                                <circle
                                                                  cx={15}
                                                                  cy={7}
                                                                  r={2}
                                                                  fill="#7D868C"
                                                                />
                                                                <circle
                                                                  cx={15}
                                                                  cy={15}
                                                                  r={2}
                                                                  fill="#7D868C"
                                                                />
                                                                <circle
                                                                  cx={15}
                                                                  cy={23}
                                                                  r={2}
                                                                  fill="#7D868C"
                                                                />
                                                              </svg>
                                                            </button>
                                                            <div className="ul-566-3dots">
                                                              <button
                                                                className="btn-s566 edit-btn"
                                                                onClick={() => {
                                                                  CloseDdl3dots();
                                                                  HandleEditPopup(
                                                                    `allQnabackRply-566-${reply_ans_list.id}`,
                                                                    "open"
                                                                  );
                                                                }}
                                                              >
                                                                Edit
                                                              </button>
                                                              <button
                                                                className="btn-s566 del-btn c"
                                                                onClick={() => {
                                                                  CloseDdl3dots();
                                                                  setIsDel566(
                                                                    `rep-${reply_ans_list.id}`
                                                                  );
                                                                  setTmpTelCardId(
                                                                    [
                                                                      `allQnabackRply-566-${reply_ans_list.id}`,
                                                                    ]
                                                                  );
                                                                  setTmpAnsReplyData(
                                                                    {
                                                                      [`remAns${ques.id}`]:
                                                                        tmpAnsReplyCount[
                                                                          `remAns${ques.id}`
                                                                        ]
                                                                          ? tmpAnsReplyCount[
                                                                          `remAns${ques.id}`
                                                                          ]
                                                                          : 0,
                                                                    }
                                                                  );
                                                                  setTmpAnsSubReplyData(
                                                                    {
                                                                      [`remSubAns${ans.id}`]:
                                                                        tmpAnsSubReplyCount[
                                                                          `remSubAns${ans.id}`
                                                                        ]
                                                                          ? tmpAnsSubReplyCount[
                                                                          `remSubAns${ans.id}`
                                                                          ]
                                                                          : 0,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                Delete
                                                              </button>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <button
                                                              className="btn-566-3dot ans"
                                                              onClick={
                                                                HandleDdl3dots
                                                              }
                                                            >
                                                              <svg
                                                                width={30}
                                                                height={30}
                                                                viewBox="0 0 30 30"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <circle
                                                                  className="hover-cir"
                                                                  cx={15}
                                                                  cy={15}
                                                                  r={15}
                                                                />
                                                                <circle
                                                                  cx={15}
                                                                  cy={7}
                                                                  r={2}
                                                                  fill="#7D868C"
                                                                />
                                                                <circle
                                                                  cx={15}
                                                                  cy={15}
                                                                  r={2}
                                                                  fill="#7D868C"
                                                                />
                                                                <circle
                                                                  cx={15}
                                                                  cy={23}
                                                                  r={2}
                                                                  fill="#7D868C"
                                                                />
                                                              </svg>
                                                            </button>
                                                            <div className="ul-566-3dots reps">
                                                              <button
                                                                className="btn-s566"
                                                                onClick={() => {
                                                                  CloseDdl3dots();
                                                                  HandleReportPopup(
                                                                    "ans_reply",
                                                                    reply_ans_list.id
                                                                  );
                                                                }}
                                                              >
                                                                <svg
                                                                  width={15}
                                                                  height={17}
                                                                  viewBox="0 0 15 17"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    d="M8.8335 2.50033L7.91683 0.666992H0.583496V16.2503H2.41683V9.83366H7.00016L7.91683 11.667H14.3335V2.50033H8.8335ZM12.5002 9.83366H8.8335L7.91683 8.00033H2.41683V2.50033H7.00016L7.91683 4.33366H12.5002V9.83366Z"
                                                                    fill="#4D4D4F"
                                                                  />
                                                                </svg>
                                                                <span>
                                                                  Report Abuse
                                                                </span>
                                                              </button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </div>
                                                      {/* scnv-566 end*/}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="s-566-cmnt-2 d-flex-box"
                                                    onClick={(e) =>
                                                      e.stopPropagation()
                                                    }
                                                  >
                                                    <div className="MuiBox-root MuiBox-root-2 commented__box">
                                                      <header className="MuiBox-root MuiBox-root-4 commented__head">
                                                        <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                                          {/* <div
                                                            className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault`}
                                                          >
                                                            {!questDataItem.profile_pic ?
                                                              <div className={`header-profile-cir ${questDataItem.avcolor}`}>
                                                                {questDataItem.shortname}
                                                              </div>
                                                              :
                                                              <div className={`header-profile-cir`}>
															   <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`}/>
                                                                
                                                              </div>}
                                                          </div> */}
                                                          <div
                                                            className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault   ${questDataItem.profile_pic ? 'cir-transparent' : questDataItem.avcolor}`}
                                                          >
                                                            {!questDataItem.profile_pic ?
                                                              <>
                                                                {questDataItem.shortname}
                                                              </>
                                                              :
                                                              <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`} />

                                                            }
                                                          </div>
                                                          <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                                            <span>
                                                              {
                                                                questDataItem.name
                                                              }{" "}
                                                              {
                                                                questDataItem.last_name
                                                              }
                                                            </span>
                                                          </p>
                                                        </section>
                                                      </header>
                                                      <div className="MuiBox-root MuiBox-root-12 commented__body">
                                                        <textarea
                                                          className="s-566-cmnt-textarea 2"
                                                          id={`textareaRplytextareaBack-${reply_ans_list.id}`}
                                                          defaultValue={
                                                            reply_ans_list.answer
                                                          }
                                                          onChange={(e) => 
                                                            {
                                                              handleReplyBtnDisbaledStatus(e)
                                                            }
                                                          }
                                                        ></textarea>
                                                        <div className="s-566-textarea-btns">
                                                          <button
                                                            disabled={editTextAreaArr[`textareaRplytextareaBack-${reply_ans_list.id}`]?.length==0?true:false}
                                                            className="btn save handle-btn-disabled"
                                                            onClick={() => {
                                                              HandleEditPopup(
                                                                `allQnabackRply-566-${reply_ans_list.id}`,
                                                                "close"
                                                              );
                                                              PostEditReply(
                                                                `textareaRplytextareaBack-${reply_ans_list.id}`,
                                                                `qnaAnsRplyTxt-${reply_ans_list.id}`,
                                                                `rplyansedit-566-${reply_ans_list.id}`
                                                              );
                                                            }}
                                                          >
                                                            Save
                                                          </button>
                                                          <button
                                                            className="btn cancel"
                                                            onClick={() => {
                                                              HandleEditPopup(
                                                                `allQnabackRply-566-${reply_ans_list.id}`,
                                                                "close"
                                                              );
                                                            }}
                                                          >
                                                            Cancel
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                                {/* qna__tabpane__card__li end 1 */}
                                              </>
                                            )}
                                          </>
                                        )
                                      )
                                      : ""}
                                    {/* show on reply data on without refresh */}
                                    {tmpReplyObj.map(
                                      (reply_ans_list, repIndex) => (
                                        <>
                                          {/* {isMoreReply[`rp${ans.id}`]  || (!isMoreReply[`rp${ans.id}`]  && !ans.reply_ans.length)? */}
                                          {tmpReplyObj.length ? (
                                            <>
                                              {reply_ans_list.ans_id ==
                                                ans.id && (
                                                  <li
                                                    className={`qna__tabpane__card__li s-566-cmnt-wrapper ${ans.reply_ans.length == 1 &&
                                                      "no-after"
                                                      }`}
                                                    id={`allQnabackRply-566-${reply_ans_list.id}`}
                                                    style={{
                                                      display:
                                                        !isMoreReply[
                                                          `rp${ans.id}`
                                                        ] && !ans.reply_ans.length
                                                          ? ""
                                                          : "",
                                                    }}
                                                  >
                                                    <div className="s-566-cmnt-1 d-flex-box">
                                                      <div className="MuiBox-root MuiBox-root-2 commented__box">
                                                        <header className="MuiBox-root MuiBox-root-4 commented__head">
                                                          <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                                            <div
                                                              className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${reply_ans_list.profile_pic ? 'cir-transparent' : reply_ans_list.avcolor}`}
                                                            >
                                                              {!reply_ans_list.profile_pic ?
                                                                <>
                                                                  {reply_ans_list.shortname}
                                                                </>
                                                                :
                                                                <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${reply_ans_list.profile_pic}`} />

                                                              }
                                                            </div>
                                                            <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                                              <span>
                                                                {
                                                                  reply_ans_list.name
                                                                }{" "}
                                                                {
                                                                  reply_ans_list.last_name
                                                                }
                                                              </span>
                                                              <span
                                                                className={`s566-edited-text rplyansedit-566-${reply_ans_list.id}`}
                                                                style={{
                                                                  display:
                                                                    reply_ans_list.is_edited ==
                                                                      1
                                                                      ? ""
                                                                      : "none",
                                                                }}
                                                              >
                                                                <svg
                                                                  viewBox="0 0 6 6"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <circle
                                                                    cx={3}
                                                                    cy={3}
                                                                    r={3}
                                                                  />
                                                                </svg>
                                                                <label>
                                                                  Edited
                                                                </label>
                                                              </span>
                                                            </p>
                                                          </section>
                                                        </header>
                                                        <div className="MuiBox-root MuiBox-root-12 commented__body">
                                                          <p className="txt">
                                                            <span className="mentioned-user">
                                                              {ans.name}{" "}
                                                              {ans.last_name}
                                                            </span>
                                                            <span
                                                              id={`qnaAnsRplyTxt-${reply_ans_list.id}`}
                                                            >
                                                              {
                                                                reply_ans_list.answer
                                                              }
                                                            </span>
                                                          </p>
                                                        </div>
                                                        <section className="MuiBox-root MuiBox-root-10 cmnt__head__rgt">
                                                          <span
                                                            className="cmnt__head__rgt-date gray"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              HandleReplyPopup(
                                                                `singleQnabackRply-566-${ans.id}`,
                                                                ans.id,
                                                                "open"
                                                              );
                                                            }}
                                                          >
                                                            Reply
                                                          </span>
                                                          <span className="cmnt__head__rgt-date gray">
                                                            {
                                                              reply_ans_list.datecreated_default
                                                            }
                                                          </span>
                                                          <span className="from-text">
                                                            Section:{" "}
                                                            <label>
                                                              {
                                                                reply_ans_list.section_name
                                                              }
                                                            </label>
                                                          </span>
                                                        </section>
                                                      </div>

                                                      <div className="qa_share_like_wrap">
                                                        <button
                                                          className={`commented__forward__btn rpl ${reply_ans_list.like} likeAns${reply_ans_list.id}`}
                                                          onClick={(e) =>
                                                            HandleLikeClick(
                                                              e,
                                                              `likeAns${reply_ans_list.id}`,
                                                              reply_ans_list.total_votes,
                                                              3,
                                                              reply_ans_list.id
                                                            )
                                                          }
                                                        >
                                                          <span className="commented__forward__btn-count">
                                                            {likeObj[
                                                              `likeAns${reply_ans_list.id}`
                                                            ] > -1
                                                              ? likeObj[
                                                              `likeAns${reply_ans_list.id}`
                                                              ]
                                                              : reply_ans_list.total_votes}
                                                          </span>

                                                          <img
                                                            className="inactive-icon"
                                                            src={
                                                              require("../../assets/img/common-img/qa-like-icon.svg")
                                                                .default
                                                            }
                                                          />
                                                          <img
                                                            className="active-icon"
                                                            src={
                                                              require("../../assets/img/common-img/qa-liked-icon.svg")
                                                                .default
                                                            }
                                                          />
                                                        </button>
                                                        {/* scnv-566 */}
                                                        <div
                                                          className="scnv-566-3dots"
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                          }}
                                                        >
                                                          {reply_ans_list.user_id ===
                                                            uid ? (
                                                            <>
                                                              <button
                                                                className="btn-566-3dot"
                                                                onClick={
                                                                  HandleDdl3dots
                                                                }
                                                              >
                                                                <svg
                                                                  width={30}
                                                                  height={30}
                                                                  viewBox="0 0 30 30"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <circle
                                                                    className="hover-cir"
                                                                    cx={15}
                                                                    cy={15}
                                                                    r={15}
                                                                  />
                                                                  <circle
                                                                    cx={15}
                                                                    cy={7}
                                                                    r={2}
                                                                    fill="#7D868C"
                                                                  />
                                                                  <circle
                                                                    cx={15}
                                                                    cy={15}
                                                                    r={2}
                                                                    fill="#7D868C"
                                                                  />
                                                                  <circle
                                                                    cx={15}
                                                                    cy={23}
                                                                    r={2}
                                                                    fill="#7D868C"
                                                                  />
                                                                </svg>
                                                              </button>
                                                              <div className="ul-566-3dots">
                                                                <button
                                                                  className="btn-s566 edit-btn"
                                                                  onClick={() => {
                                                                    CloseDdl3dots();
                                                                    HandleEditPopup(
                                                                      `allQnabackRply-566-${reply_ans_list.id}`,
                                                                      "open"
                                                                    );
                                                                  }}
                                                                >
                                                                  Edit
                                                                </button>
                                                                <button
                                                                  className="btn-s566 del-btn c"
                                                                  onClick={() => {
                                                                    CloseDdl3dots();
                                                                    setIsDel566(
                                                                      `rep-${reply_ans_list.id}`
                                                                    );
                                                                    setTmpTelCardId(
                                                                      [
                                                                        `allQnabackRply-566-${reply_ans_list.id}`,
                                                                      ]
                                                                    );
                                                                    setTmpAnsReplyData(
                                                                      {
                                                                        [`remAns${ques.id}`]:
                                                                          tmpAnsReplyCount[
                                                                            `remAns${ques.id}`
                                                                          ]
                                                                            ? tmpAnsReplyCount[
                                                                            `remAns${ques.id}`
                                                                            ]
                                                                            : 0,
                                                                      }
                                                                    );
                                                                    setTmpAnsSubReplyData(
                                                                      {
                                                                        [`remSubAns${ans.id}`]:
                                                                          tmpAnsSubReplyCount[
                                                                            `remSubAns${ans.id}`
                                                                          ]
                                                                            ? tmpAnsSubReplyCount[
                                                                            `remSubAns${ans.id}`
                                                                            ]
                                                                            : 0,
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  Delete
                                                                </button>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <button
                                                                className="btn-566-3dot ans"
                                                                onClick={
                                                                  HandleDdl3dots
                                                                }
                                                              >
                                                                <svg
                                                                  width={30}
                                                                  height={30}
                                                                  viewBox="0 0 30 30"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <circle
                                                                    className="hover-cir"
                                                                    cx={15}
                                                                    cy={15}
                                                                    r={15}
                                                                  />
                                                                  <circle
                                                                    cx={15}
                                                                    cy={7}
                                                                    r={2}
                                                                    fill="#7D868C"
                                                                  />
                                                                  <circle
                                                                    cx={15}
                                                                    cy={15}
                                                                    r={2}
                                                                    fill="#7D868C"
                                                                  />
                                                                  <circle
                                                                    cx={15}
                                                                    cy={23}
                                                                    r={2}
                                                                    fill="#7D868C"
                                                                  />
                                                                </svg>
                                                              </button>
                                                              <div className="ul-566-3dots reps">
                                                                <button
                                                                  className="btn-s566"
                                                                  onClick={() => {
                                                                    CloseDdl3dots();
                                                                    HandleReportPopup(
                                                                      "ans_reply",
                                                                      reply_ans_list.id
                                                                    );
                                                                  }}
                                                                >
                                                                  <svg
                                                                    width={15}
                                                                    height={17}
                                                                    viewBox="0 0 15 17"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      d="M8.8335 2.50033L7.91683 0.666992H0.583496V16.2503H2.41683V9.83366H7.00016L7.91683 11.667H14.3335V2.50033H8.8335ZM12.5002 9.83366H8.8335L7.91683 8.00033H2.41683V2.50033H7.00016L7.91683 4.33366H12.5002V9.83366Z"
                                                                      fill="#4D4D4F"
                                                                    />
                                                                  </svg>
                                                                  <span>
                                                                    Report Abuse
                                                                  </span>
                                                                </button>
                                                              </div>
                                                            </>
                                                          )}
                                                        </div>
                                                        {/* scnv-566 end*/}
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="s-566-cmnt-2 d-flex-box"
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                    >
                                                      <div className="MuiBox-root MuiBox-root-2 commented__box">
                                                        <header className="MuiBox-root MuiBox-root-4 commented__head">
                                                          <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">

                                                            <div
                                                              className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault   ${questDataItem.profile_pic ? 'cir-transparent' : questDataItem.avcolor}`}
                                                            >
                                                              {!questDataItem.profile_pic ?
                                                                <>
                                                                  {questDataItem.shortname}
                                                                </>
                                                                :
                                                                <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`} />

                                                              }
                                                            </div>
                                                            <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                                              <span>
                                                                {
                                                                  questDataItem.name
                                                                }{" "}
                                                                {
                                                                  questDataItem.last_name
                                                                }
                                                              </span>
                                                            </p>
                                                          </section>
                                                        </header>
                                                        <div className="MuiBox-root MuiBox-root-12 commented__body">
                                                          <textarea
                                                            className="s-566-cmnt-textarea 3"
                                                            id={`textareaRplytextareaBack-${reply_ans_list.id}`}
                                                            defaultValue={
                                                              reply_ans_list.answer
                                                            }
                                                            onChange={(e) => 
                                                              {
                                                                handleReplyBtnDisbaledStatus(e)
                                                              }
                                                            }
                                                          ></textarea>
                                                          <div className="s-566-textarea-btns">
                                                            <button
                                                              disabled={editTextAreaArr[`textareaRplytextareaBack-${reply_ans_list.id}`]?.length==0?true:false}
                                                              className="btn save handle-btn-disabled"
                                                              onClick={() => {
                                                                HandleEditPopup(
                                                                  `allQnabackRply-566-${reply_ans_list.id}`,
                                                                  "close"
                                                                );
                                                                PostEditReply(
                                                                  `textareaRplytextareaBack-${reply_ans_list.id}`,
                                                                  `qnaAnsRplyTxt-${reply_ans_list.id}`,
                                                                  `rplyansedit-566-${reply_ans_list.id}`
                                                                );
                                                              }}
                                                            >
                                                              Save
                                                            </button>
                                                            <button
                                                              className="btn cancel"
                                                              onClick={() => {
                                                                HandleEditPopup(
                                                                  `allQnabackRply-566-${reply_ans_list.id}`,
                                                                  "close"
                                                                );
                                                              }}
                                                            >
                                                              Cancel
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                )}
                                              {/* qna__tabpane__card__li end 1 */}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )
                                    )}
                                    {/* show on reply data on without refresh end */}

                                    <li
                                      className="qna__tabpane__card__li s-566-cmnt-wrapper no-before no1"
                                      id={`singleQnabackRply-566-${ans.id}`}
                                    >
                                      {/* reply popup */}
                                      <div
                                        className="s-566-cmnt-2 d-flex-box s-566-cmnt-reply"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {/* this section has 
																					header
																					commented_body 
																				*/}
                                        <div className="MuiBox-root MuiBox-root-2 commented__box">
                                          <header className="MuiBox-root MuiBox-root-4 commented__head">
                                            {/* this section has
																							name-circle
																							head_name
																							text
																						*/}
                                            <section className="MuiBox-root MuiBox-root-6 cmnt__head__lft">
                                              <div
                                                className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${questDataItem.profile_pic ? 'cir-transparent' : `${avatarColor}`}`}
                                              >
                                                {!questDataItem.profile_pic ?
                                                  <>
                                                    {questDataItem.shortname}
                                                  </>
                                                  :
                                                  <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`} />

                                                }
                                              </div>
                                              <p className="MuiBox-root MuiBox-root-8 cmnt__head__name">
                                                <span>
                                                  {questDataItem.name}{" "}
                                                  {questDataItem.last_name}
                                                </span>
                                              </p>
                                              <p className="star-sa-text"></p>
                                            </section>
                                          </header>
                                          {/* this section has 
																						textarea 
																						button
																					*/}
                                          <div className="MuiBox-root MuiBox-root-12 commented__body">
                                            <textarea
                                              className="s-566-cmnt-textarea 4"
                                              id={`rep566-textarea-${ans.id}`}
                                              onChange={(e) => 
                                                {
                                                  handleReplyBtnDisbaledStatus(e)
                                                }
                                              }
                                            ></textarea>
                                            <div className="s-566-textarea-btns">
                                              <button
                                                style={{opacity:0.6,pointerEvents:'none'}}
                                                disabled={editTextAreaArr[`rep566-textarea-${ans.id}`]?.length==0?true:false}
                                                className="btn save handle-btn-disabled make-disabled-after-savereply"
                                                onClick={() => {
                                                  HandleReplyPopup(
                                                    `singleQnabackRply-566-${ans.id}`,
                                                    ans.id,
                                                    "close"
                                                  );
                                                  PostReply(
                                                    `allQna-566-${ques.id}`,
                                                    ans.id,
                                                    `rep566-textarea-${ans.id}`
                                                  );
                                                }}
                                              >
                                                Reply
                                              </button>
                                              <button
                                                className="btn cancel"
                                                onClick={() => {
                                                  HandleReplyPopup(
                                                    `singleQnabackRply-566-${ans.id}`,
                                                    "close"
                                                  );
                                                }}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* reply popup  end*/}
                                    </li>
                                  </ul>
                                  {/* s-566-reply-ul end */}
                                </li>
                                {/* scnv-566 */}
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                          <li className="qna__tabpane__card__li comment-reply-wrap">
                            <div className="course__tabpane__card send__comment__card">
                              <div className="send__comment__card__content">
                                {/* this section has
																	circle name
																	reply
																*/}
                                <div className="name__cir__wrapper">
                                  {/* <div
                                    className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault`}
                                  >
                                    {!questDataItem.profile_pic ?
                                      <div className={`header-profile-cir ${avatarColor}`}>
                                        {questDataItem.shortname}
                                      </div>
                                      :
                                      <div className={`header-profile-cir`}>
									  <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`}/>
                                       
                                      </div>}
                                  </div> */}
                                  <div
                                    className={`MuiAvatar-root MuiAvatar-circle name__cir  MuiAvatar-colorDefault  ${questDataItem.profile_pic ? 'cir-transparent' : `${avatarColor}`}`}
                                  >
                                    {!questDataItem.profile_pic ?
                                      <>
                                        {questDataItem.shortname}
                                      </>
                                      :

                                      <LazyLoadImage className='h__profile__img' src={`${apiEndpoints.baseUrl}/assets/user-profile/${questDataItem.profile_pic}`} />
                                    }
                                  </div>
                                  {/* this section has 
																		textarea 
																		button
																	*/}
                                  <div className="txtReplyWrap280621">
                                    <textarea
                                      id={`txtReplyMsg-${ques.id}`}
                                      placeholder="Write your response"
                                      className="text__send__msg text__reply__msgs"
                                      defaultValue={""}
                                      onChange={(e)=>handleReplyBtnDisbaledStatus(e)}
                                    />
                                    <div className="send__btn__div">
                                      <button
                                        style={{opacity:0.6,pointerEvents:'none'}}
                                        className="send__btn handle-btn-disabled"
                                        id={ques.id}
                                        onClick={props.onAnswer}
                                      >
                                        Add Reply
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
                {/* course overview frame end */}
                {/* this section has 
									button
									snq-content
								*/}
                <div
                  className="course__overview__frame__left submit-new-question"
                  style={{ display: isCardReply === 2 ? "" : "none" }}
                >
                  <button
                    className="h2"
                    onClick={() => {
                      HandleCard(0);
                      BackToCloseRepyBox();
                    }}
                  >
                    <img
                      className="qa-back_arrow"
                      src={
                        require("../../assets/img/common-img/qa-arrow_back.svg")
                          .default
                      }
                    />
                    Back to all questions
                  </button>
                  {/* this section has
										snq-ul-div
									 */}
                  <div className="snq-content">
                    {/* this section has 
											title 
											list
										*/}
                    <div className="snq-ul-div">
                      <p className="ul-title">
                        {/* Here are some tips before you submit your question: */}
                        Tips on raising a question
                      </p>
                      <ul>
                        <li>
                          Use the search box to make sure your question is
                          unique
                        </li>
                        <li>
                          Keep your question relevant to this IR4.0 training course
                        </li>
                        {/* <li>
													Provide further explanation if needed
												</li> */}
                      </ul>
                    </div>
                    {/* this section has
											text
											textarea
											button
										*/}
                    <div className="snq-text-area">
                      <p className="snq-text-area-h1">Enter Question</p>
                      <textarea
                        ref={textareaRef}
                        className="snq-textarea"
                        id="txtSendMsg"
                        defaultValue=""
                        placeholder="Please enter your question here"
                        onChange={(e)=>setPublicText(e.target.value.trim())}
                      ></textarea>
                      <button
                        disabled={publicText.length==0} style={{opacity:publicText.length==0?0.6:1}}
                        className="btn-publish 1"
                        onClick={props.onQuestion}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </div>
                {/* submit-new-question end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDel566 && (
        <div className="ddl3dots-delete-popup">
          {/* this card has
					text
					button wrapper
				*/}
          <div className="ddl3dots-del-card">
            <p className="del-text">
              Are you sure that you would like to delete this question?
            </p>
            <div className="del-btn-wrapper">
              <button
                className="btn-del cancel"
                onClick={() => {
                  setIsDel566(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-del delete"
                onClick={() => {
                  DeleteRecord();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {/* ReportAbusePopup call */}
      <ReportAbusePopup
        open={isReportPopup}
        uid={uid}
        HandleReportPopup={HandleReportPopup}
        reportCardId={reportCardId}
        setReportCardId={setReportCardId}
        sucessImg={sucessImg}
        setSucessImg={setSucessImg}
        {...props}
      />
    </>
  );
};

// filter-component
const QnaFilter = (props) => {
  //defining variables here
  const { itemNumber, ActiveItem, setActiveItem, options, title, getvalue } =
    props;
  const ddlbox = React.useRef(null);
  const [btnTitle, setBtnTitle] = React.useState(title);
  //function to handle value
  const HandleValue = (e, val) => {
    // setBtnTitle(val.title);
    setActiveItem(null);
    getvalue(val);
  };
  //function to handle open state
  const HandleOpenState = (e) => {
    if (e === ActiveItem) {
      setActiveItem(null);
    } else {
      setActiveItem(e);
    }
  };
  React.useEffect(() => {
    setBtnTitle(title);
  }, [title]);
  // window.onclick = (e) => {
  //   if (!e.target.closest(".qna-filter-ddl-box")) {
  //     setActiveItem(null);
  //   }
  // };
  React.useEffect(() => {
    document.onclick = (e) => {
      if (!e.target.closest(".qna-filter-ddl-box")) {
        setActiveItem(null);
      }
    };
  }, [ActiveItem]);

  // window.onclick = (e) => {
  //   if (!e.target.closest(".qna-filter-ddl-box")) {
  //     setActiveItem(null);
  //   }
  // };
  return (
    <>
      {/* this section has
				top-button
				filter-ddl-list
			*/}
      <div
        className={`qna-filter-ddl-box ${itemNumber === ActiveItem ? "active" : ""
          }`}
        ref={ddlbox}
      >
        <button className="top-btn" onClick={() => HandleOpenState(itemNumber)}>
          <span>{btnTitle}</span>
          <svg
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.41 0.590088L6 5.17009L10.59 0.590088L12 2.00009L6 8.00009L0 2.00009L1.41 0.590088Z" />
          </svg>
        </button>
        <div className="filter-ddl-list">
          {options.map((item, index) => (
            <button key={index} onClick={(e) => HandleValue(e, item)}>
              {item.title}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};
// report abuse popup
const ReportAbusePopup = (props) => {
  //defining variables here
  const [ddlFilter, setDdlFilter] = React.useState(null); // to open and close dropdown list
  const [issue, setIssue] = React.useState(null); // to set issue value;
  const [isError, setIsError] = useState(false);
  const [isSucess, setIsSucess] = useState(false);
  const reportImpLoader = React.useRef();
  //function to handle submit
  const HandleSubmit = (val) => {
    const issue_details = document.getElementById("reportAbuseTextarea").value;
    if (issue_details.length !== 0 && val == 4) {
      // props.HandleReportPopup();
      serviceProvider
        .post(
          "/itemdetails?type=reportabuse",
          JSON.stringify({
            uid: props.uid,
            item_id: props.reportCardId.id,
            item_type: props.reportCardId.type,
            issue_type: issue.value,
            issue_details: issue_details,
          }),
          true
        )
        .then((response) => { });
      setIsSucess(true);
    } else if (val == 4 && issue_details.length === 0) {
      setIsError(true);
    } else if (val && val !== 4) {
      // props.HandleReportPopup();
      serviceProvider
        .post(
          "/itemdetails?type=reportabuse",
          JSON.stringify({
            uid: props.uid,
            item_id: props.reportCardId.id,
            item_type: props.reportCardId.type,
            issue_type: issue.value,
            issue_details: issue_details,
          }),
          true
        )
        .then((response) => { });
      setIsSucess(true);
    }
  };
  React.useEffect(() => {
    setDdlFilter(null);
    setIssue(null);
  }, [props.reportCardId]);
  React.useEffect(() => {
    if (issue?.value !== 4) {
      setIsError(false);
    }

    return () => {
      setIsSucess(false);
    };
  }, [issue]);
  useEffect(() => {
    if (isSucess) {
      if (props.sucessImg) {
        reportImpLoader.current.src =
          require(`../../assets/img/course-overview/done-check.gif`).default;
      } else {
        reportImpLoader.current.src =
          require(`../../assets/img/course-overview/done-check-switch.gif`).default;
      }
    }
    return () => {
      props.setSucessImg(!props.sucessImg);
    };
  }, [isSucess, props.open]);
  return (
    <>
      <div
        className="qnaReportAbuse-popup"
        style={{ display: props.open ? "" : "none" }}
      >
        <div className="inner-wrapper">
          {/* card has
						heading
						text
						form
						action-wrapper
					*/}
          <div className="card">
            <h1>
              <span>Report Abuse</span>
              <button onClick={props.HandleReportPopup}>
                <svg
                  width="{14}"
                  height="{14}"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.635934 2.05025C0.24541 1.65973 0.24541 1.02656 0.635934 0.636039C1.02646 0.245515 1.65962 0.245515 2.05015 0.636039L13.3639 11.9497C13.7544 12.3403 13.7544 12.9734 13.3639 13.364C12.9733 13.7545 12.3402 13.7545 11.9496 13.364L0.635934 2.05025Z"
                    fill="#4D4D4F"
                  />
                  <path
                    d="M0.635934 13.364C0.24541 12.9734 0.245409 12.3403 0.635934 11.9497L11.9496 0.636039C12.3402 0.245515 12.9733 0.245515 13.3639 0.636039C13.7544 1.02656 13.7544 1.65973 13.3639 2.05025L2.05015 13.364C1.65962 13.7545 1.02646 13.7545 0.635934 13.364Z"
                    fill="#4D4D4F"
                  />
                </svg>
              </button>
            </h1>
            {!isSucess ? (
              <>
                <p>
                  Flagged content is reviewed by SmarterChains staff to
                  determine whether it violates Terms of Service or Community
                  Guidelines
                </p>
                <div className="qna-form-ctrl">
                  <label>Issue type</label>
                  <div className="qna-filter-wrapper form-content-wrapper">
                    <QnaFilter
                      itemNumber={3}
                      ActiveItem={ddlFilter}
                      title={issue ? issue.title : "Select an issue"}
                      setActiveItem={setDdlFilter}
                      getvalue={setIssue}
                      options={[
                        {
                          title: "Spam",
                          value: "1",
                        },
                        {
                          title: "Inappropriate Language",
                          value: "2",
                        },
                        {
                          title: "Racist Language",
                          value: "3",
                        },
                        {
                          title: "Other",
                          value: "4",
                        },
                      ]}
                      {...props}
                    />
                  </div>
                </div>
                <div className="qna-form-ctrl">
                  <label>Issue details</label>
                  <textarea
                    placeholder={`Mention your issue in detail ${issue?.value == 4 ? "(mandatory)*" : "(optional)"
                      }`}
                    id="reportAbuseTextarea"
                    onChange={() => setIsError(false)}
                  ></textarea>
                  {isError && issue?.value == 4 && (
                    <p className="error-qna-text-area">
                      *This field can not be left blank
                    </p>
                  )}
                </div>
                <div className="qna-action-btn-wrapper">
                  <button
                    className="btn-blue-txt"
                    onClick={props.HandleReportPopup}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-blue-bg"
                    onClick={() => HandleSubmit(issue?.value)}
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <div className="success-div-566">
                <div className="imgdiv-s566">
                  <img ref={reportImpLoader} alt="true" />
                </div>
                <div className="txt-success-p">
                  Your report has been submitted!
                </div>
                <button
                  className="goback-link566"
                  onClick={props.HandleReportPopup}
                >
                  Go Back
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

// QnaCheckBoxFilterBox
const QnaChekBoxFilter = (props) => {
  const { title, options, id, value, setValue } = props;
  const [isDdl, setIsDdl] = React.useState(false);
  const HandleDdl = () => {
    setIsDdl(!isDdl);
    if(document.querySelector('.qna-checkfilter-box').classList.contains('active')){
      document.querySelector('.qna-filter-ddl-box').classList.remove('active');
    }
  };

  let isSelectedAll = false; //variable to toggle all check box selction;

  // function will be call on  onChange event of single checkbox
  const SignleCheckFunc = () => {
    let allChk = document.getElementById(`${id}-all-select`);
    let totalChk = document.getElementsByName(id);
    let tmpArr = [];
    totalChk.forEach((element, index) => {
      if (element.checked == true) {
        tmpArr.push(index);
      }
    });
    if (tmpArr.length === totalChk.length) {
      allChk.checked = true;
      isSelectedAll = true;
    } else {
      allChk.checked = false;
      isSelectedAll = false;
    }
  };

  // function will be call on  onChange event of All selected checkbox
  const SelectAllChkBoxFunc = () => {
    let allChk = document.getElementById(`${id}-all-select`);
    let totalChk = document.getElementsByName(id);
    let tmpArr = [];
    totalChk.forEach((element, index) => {
      if (element.checked == true) {
        tmpArr.push(index);
      }
    });
    if (tmpArr.length !== totalChk.length) {
      totalChk.forEach((element, index) => {
        element.checked = true;
      });
      allChk.checked = true;
      isSelectedAll = true;
    } else {
      totalChk.forEach((element, index) => {
        element.checked = false;
      });
      isSelectedAll = false;
    }
  };

  // function will be call on  onClick event of applybutton
  const ApplyBtnFunction = () => {
    let totalChk = document.getElementsByName(id);
    let tmpArr = [];
    totalChk.forEach((element, index) => {
      if (element.checked == true) {
        tmpArr.push(element.value);
      }
    });
    props.onCatBy(tmpArr);
    setValue(tmpArr);
    setIsDdl(false);
  };
  
  window.onclick = (e) => {
    if (!e.target.closest(".qna-checkfilter-box")) {
      setIsDdl(false);
    }
  };

  return (
    <>
      {/* this section has
				button
				ul-card
			*/}
      <div className={`qna-checkfilter-box ${isDdl && "active"}`} id={id}>
        <button className="btn-toggle-ddl" onClick={HandleDdl}>
          <span>
            {options.length==value.length ? title : value.length > 0 ? `${value.length} Section${value.length>1?'s':''} Selected` : title}
          </span>
          <svg
            width={12}
            height={8}
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z" />
          </svg>
        </button>
        {isDdl && (
          <div className="qna-checkfilter-ul">
            {/* this section has
						ul-card-body
						ul-card-footer
					*/}
            <div className="ul-card">
              <div className="ul-card-body">
                <label className="qna-chklabel">
                  <input
                    type="checkBox"
                    id={`${id}-all-select`}
                    onChange={SelectAllChkBoxFunc}
                    defaultChecked={
                      options.length==value.length? true : false
                    }
                  />
                  <svg
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width={15}
                      height={15}
                      rx="2.5"
                      stroke="#118ACB"
                    />
                    <path
                      d="M4 7.5L6.71716 10.2172C6.87337 10.3734 7.12663 10.3734 7.28284 10.2172L12 5.5"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="2.13333"
                        y1="-3.60002"
                        x2="16.6803"
                        y2="-3.13472"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#118ACB" />
                        <stop offset={1} stopColor="#0473AE" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span>{title}</span>
                </label>

                {options.map((item, index) => (
                  <label className="qna-chklabel" key={index}>
                    <input
                      type="checkBox"
                      name={id}
                      onChange={SignleCheckFunc}
                      value={item.id}
                      defaultChecked={
                        value.indexOf(item.id) != -1 ? true : false
                      }
                    />
                    <svg
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width={15}
                        height={15}
                        rx="2.5"
                        stroke="#118ACB"
                      />
                      <path
                        d="M4 7.5L6.71716 10.2172C6.87337 10.3734 7.12663 10.3734 7.28284 10.2172L12 5.5"
                        stroke="white"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="2.13333"
                          y1="-3.60002"
                          x2="16.6803"
                          y2="-3.13472"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#118ACB" />
                          <stop offset={1} stopColor="#0473AE" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span>{item.section_name}</span>
                  </label>
                ))}
              </div>
              <div className="ul-card-footer">
                <button className="btn-cancel" onClick={() => setIsDdl(false)}>
                  Cancel
                </button>
                <button className="btn-apply" onClick={ApplyBtnFunction}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
