import styled from "styled-components";

export const PageWrapper = styled.main`
    color:#4d4d4f;
    display: flex;
    flex-direction:column;
    height:100%;
    overflow:hidden;
`

export const Headding = styled.h1`
   font-size:2.6rem;
   font-weight:600;
   margin-bottom:0.6rem;
`;

export const HeaddingText = styled.p`
   font-size:1.2rem;
   font-weight:500;
   margin-bottom:0.8rem;
`; 

export const  ContentContainer = styled.section`
        padding:3rem;
        flex:1;
        overflow:auto;
`;

export const DimentionRowWrapper = styled.div`
    max-width:83.5rem;
`;

export const DimentionRow = styled.div`
          display:flex;
          border-bottom:0.1rem solid #E6E6E6;
          gap:1.6rem;
`;

export const DimentionCol1 = styled.div`
          flex:1;
          padding:1.6rem 0rem;
          .wrapper{
            display:flex;
            gap:1.2rem;
            align-items:center;
          }
          .number-box{
            background: linear-gradient(91.83deg, #118ACB 12.22%, #0473AE 100.41%);
            width:4rem;
            height:4rem;
            border-radius:0.4rem;
            color:#FFF;
            font-size:1.4rem;
            font-weight:600;
            display:flex;
            align-items:center;
            justify-content:center;
          }
          .text-box{
            font-size:1.4rem;
            font-weight:400;
            color:#4D4D4F;
            flex:1;
          }
`;

export const DimentionCol2 = styled.div`
          width:12rem;
          padding:1.6rem 0rem;
          &.flex{
            display:flex;
            align-items:center;
          }
          .includes-pdf-div{
            position:relative;
            display:flex;
            align-items:center;
            gap:0.8rem;
            cursor:pointer;
            font-size:1.4rem;
            user-select:none;
            &.disabled{
              cursor: not-allowed;
              opacity:0.7;
            }
            input{
              display:none;
              color:#4D4D4F;
            }
            i{
              display:inline-block;
              line-height:0;
              svg{
                 width:1.2rem;
                 height:1.2rem;
              }
            }
          }
`;

export const DimensionHead = styled.div`
          text-align:center;
          font-weight:500;
          font-size:1.4rem;
`;

export const ContentFooter = styled.section`
        padding:2rem 3rem;
        min-height:8rem;
        background:#fff;
        display:flex;
        gap:1.6rem;
        .btn-modify{
            background: linear-gradient(91.83deg, #139BD6 12.22%, #0789CF 100.41%);
            color:#fff;
            padding:1rem 2.1rem;
            border-radius:0.4rem;
            transition:0.25s;
            &:hover {
                background: linear-gradient(91.83deg, #139BD6 12.22%, #0789CF 100.41%);
            }
            &:disabled {
               background: rgba(203, 207, 210, 1) !important;
               cursor: not-allowed;
            }
        }
        .btn-discard{
            background:#fff;
            padding:1rem 2.1rem;
            border-radius:0.4rem;
            border:0.1rem solid currentColor;
            color:#7D868C;
            transition:0.25s;
            margin-left:auto;
            &:disabled {
               color:#CBCFD2;
               cursor: not-allowed;
            }
        }

        .changes-saved{
          display:inline-flex;
          align-items:center;
          font-size:2rem;
          font-weight:500;
          gap:1.6rem;
          height:4.8rem;
          padding:0.2rem 2.4rem;
          box-shadow:0px 0.4rem 0.4rem rgba(77, 77, 79, 0.25);
          color:#43A047;
          border:0.06rem solid currentColor;
          border-radius:0.4rem;
          margin-left:auto;
          i{
            line-height:0;
          }
        }
`;

// -------------------------------------------------

export const DropdownComponent = styled.div`
      width:12rem;
      .dropdown-wrapper {
          display: inline-block;
          position: relative;
          width: 100%;
      }
      .dropdown-wrapper.disabled{
        cursor:not-allowed;
      }
      .dropdown-wrapper.disabled .option-toggle-btn{
        pointer-events:none;
        opacity: 0.8;
      }

      .dropdown-wrapper .ddl-icon{
        position:absolute;
        pointer-events:none;
        right:0.8rem;
        top:50%;
        transform:translateY(-50%);
        svg{
           width:1.2rem;
           height:0.8rem;
           transition:0.2s;
        }
      }

     .dropdown-wrapper.top.show-options .ddl-icon svg{
         rotate:180deg;
      }

      .selection-options {
          list-style: none;
          padding: 0;
          display: none;
          position: absolute;
          z-index: 2;
          width: 100%;
          background-color: #ffff;
          bottom: 0;
          transform: translateY(100%);
          border:0.1rem solid #CBCFD2;
          border-top:0rem;
          border-bottom-right-radius:0.4rem;
          border-bottom-left-radius:0.4rem;
          overflow:auto;
      }

      .dropdown-wrapper.top .selection-options {
        border-bottom-right-radius:0rem;
        border-bottom-left-radius:0rem;
        border-top-right-radius:0.4rem;
        border-top-left-radius:0.4rem;
        border-top:0.1rem solid #CBCFD2;
        border-bottom:0;
        top:0rem;
        bottom:unset;
        transform:translateY(-100%);
      }

      .dropdown-wrapper.show-options .selection-options {
          display: block;
      }

      .option-toggle-btn {
          width: 100%;
          font-size:1.4rem;
          border:0.1rem solid #CBCFD2;
          border-radius:0.4rem;
          background:#fff;
          color:rgba(69, 90, 100, 1);
          height: 4rem;
          display:inline-flex;
          align-items:center;
          padding:0.6rem 2rem 0.6rem 0.6rem;
      }
      .dropdown-wrapper.show-options .option-toggle-btn{
        border-bottom-right-radius:0rem;
        border-bottom-left-radius:0rem;
      } 
      .dropdown-wrapper.top.show-options .option-toggle-btn{
        border-top-right-radius:0rem;
        border-top-left-radius:0rem;
        border-bottom-right-radius:0.4rem;
        border-bottom-left-radius:0.4rem;
      } 

      .selection-option {
          cursor: pointer;
          padding:0.2rem 0.8rem;
          transition:0.25s;
          font-size:1.4rem;
          min-height:4rem;
          display: flex;
            align-items: center;
            justify-content: space-between;
          &:not(:last-child){
            border-bottom:0.1rem solid #CBCFD2;
          }
          &:hover{
            background:#E5F0FF;
          }
          &.selected{
            background:#E5F5F1 !important;
            pointer-events:none;
            i{
              display:inline-block;
              svg{
                width:1.2rem;
                height:0.9rem;
              }
            }
          }
      }
`;