export const hideEye = `<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1C1.48818 1.78109 2.01936 2.4477 2.58078 3M13 1C12.5118 1.78105 11.9808 2.44771 11.4194 3M11.4194 3L13 4.73429M11.4194 3C10.5581 3.84737 9.62539 4.42546 8.66667 4.73429M2.58078 3L1 4.73429M2.58078 3C3.44212 3.84736 4.37485 4.42546 5.33359 4.73429M8.66667 4.73429L9.33333 7M8.66667 4.73429C7.56705 5.08851 6.43321 5.0885 5.33359 4.73429M5.33359 4.73429L4.66667 7" stroke="#026EC2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const showEye = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
<path d="M1 7C1.48818 7.78109 2.01936 8.4477 2.58078 9M13 7C12.5118 7.78105 11.9808 8.44771 11.4194 9M11.4194 9L13 10.7343M11.4194 9C10.5581 9.84737 9.62539 10.4255 8.66667 10.7343M2.58078 9L1 10.7343M2.58078 9C3.44212 9.84736 4.37485 10.4255 5.33359 10.7343M8.66667 10.7343L9.33333 13M8.66667 10.7343C7.56705 11.0885 6.43321 11.0885 5.33359 10.7343M5.33359 10.7343L4.66667 13" stroke="#026EC2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 7C12.5118 6.21891 11.9806 5.5523 11.4192 5M0.999999 7C1.48815 6.21895 2.0192 5.55228 2.58058 5M2.58058 5L1 3.26571M2.58058 5C3.4419 4.15263 4.37461 3.57454 5.33333 3.26571M11.4192 5L13 3.26571M11.4192 5C10.5579 4.15264 9.62515 3.57454 8.66641 3.26571M5.33333 3.26571L4.66667 0.999999M5.33333 3.26571C6.43295 2.91149 7.56679 2.9115 8.66641 3.26571M8.66641 3.26571L9.33333 1" stroke="#026EC2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="7" cy="7" r="2.5" stroke="#026EC2"/>
</svg>`;

export const backArrow = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="2" fill="#F0F0F0"/>
<path d="M12.4242 18.5832C12.1893 18.814 11.8123 18.8123 11.5795 18.5795L5.42426 12.4243C5.18995 12.1899 5.18995 11.8101 5.42426 11.5757L11.5795 5.42053C11.8123 5.18768 12.1893 5.18601 12.4242 5.41679L12.8151 5.80077C13.0529 6.03446 13.0546 6.41725 12.8188 6.65304L9.37114 10.1007C8.99316 10.4787 9.26086 11.125 9.7954 11.125H18.4C18.7314 11.125 19 11.3936 19 11.725V12.275C19 12.6064 18.7314 12.875 18.4 12.875H9.7954C9.26086 12.875 8.99316 13.5213 9.37114 13.8993L12.8188 17.347C13.0546 17.5827 13.0529 17.9655 12.8151 18.1992L12.4242 18.5832Z" fill="#4D4D4F"/>
</svg>
`;
export const arrowDown = `<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 5.89059L0.25 1.64059L1.24167 0.648926L4.5 3.90726L7.75833 0.648926L8.75 1.64059L4.5 5.89059Z" fill="#7D868C"/>
</svg>

`;
export const alertIcon=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_5_69094" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
  <rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_5_69094)">
  <circle cx="10" cy="10" r="8" fill="#DB324D"/>
  <path d="M10 9.33398L10 14.0007" stroke="white" stroke-linecap="round"/>
  <path d="M10 6L10 6.66667" stroke="white" stroke-linecap="round"/>
</g>
</svg>`

export const greenCheckIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_19_14173" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
  <rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_19_14173)">
  <path d="M8.83329 13.8327L14.7083 7.95768L13.5416 6.79102L8.83329 11.4993L6.45829 9.12435L5.29163 10.291L8.83329 13.8327ZM9.99996 18.3327C8.84718 18.3327 7.76385 18.1139 6.74996 17.6764C5.73607 17.2389 4.85413 16.6452 4.10413 15.8952C3.35413 15.1452 2.76038 14.2632 2.32288 13.2493C1.88538 12.2355 1.66663 11.1521 1.66663 9.99935C1.66663 8.84657 1.88538 7.76324 2.32288 6.74935C2.76038 5.73546 3.35413 4.85352 4.10413 4.10352C4.85413 3.35352 5.73607 2.75977 6.74996 2.32227C7.76385 1.88477 8.84718 1.66602 9.99996 1.66602C11.1527 1.66602 12.2361 1.88477 13.25 2.32227C14.2638 2.75977 15.1458 3.35352 15.8958 4.10352C16.6458 4.85352 17.2395 5.73546 17.677 6.74935C18.1145 7.76324 18.3333 8.84657 18.3333 9.99935C18.3333 11.1521 18.1145 12.2355 17.677 13.2493C17.2395 14.2632 16.6458 15.1452 15.8958 15.8952C15.1458 16.6452 14.2638 17.2389 13.25 17.6764C12.2361 18.1139 11.1527 18.3327 9.99996 18.3327Z" fill="#43A047"/>
</g>
</svg>`