/** imported components

  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * Link for ==> for render anchor.
  * Modal for ==> for popup.

**/
import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { Link } from "react-router-dom";
import { Modal } from "@material-ui/core";
import {QuizView} from './t-quiz-walkthrough-view'
// QuizWalkthrough Start
export const QuizWalkthrough = (porps) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const urlElements = window.location.href.split("#");
  const hashvalue = urlElements[1];

  const usid = user ? user.uid : 0;
  useEffect(() => {
    serviceProvider
      .post("/home?type=setfirstquizseen", JSON.stringify({ uid: usid }), true)
      .then((response) => {
        // console.log(response);
      });
  }, []);
  return (
    <>
      <link rel="stylesheet" href={require('./quiz-walktrhough.scss').require} />
      <div
        className="course-wt-bg training_rquiz_walkthrough"
        style={{zIndex:'unset'}}
      >
        <QuizView/>
        {/* single-walkthrough-modal start */}
        <Modal open={true} className="single-walkthrough-modal edited">
          <div className="single-wlakthrough-content">
            <Link to={`/training-quiz/${hashvalue}`} className="clsoe-btn">
              <img
                src={
                  require("../../assets/img/common-img/close-icon-gray.svg")
                    .default
                }
                alt="X"
              />
            </Link>
            <div className="card">
              <div className="div-img">
                <img
                  src={
                    require("../../assets/img/common-img/quiz-wlk-img.svg")
                      .default
                  }
                  alt="true"
                />
              </div>
              {/* div-img end */}
              <div className="div-txt">
                <div className="card-content">
                  <h2 className="heading-h1">Welcome to the Quiz</h2>
                  <p className="para-p">
                    Successfully complete the quiz by answering the
                    questionnaire to get your certificate. In case you don’t
                    pass, you can come back to retake the quiz.
                  </p>
                </div>
                <div className="card-footer">
                  <Link
                    to={`/training-quiz/${hashvalue}`}
                    className="btn-got-it"
                  >
                    Got it
                  </Link>
                </div>
              </div>
              {/* div-img end */}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
