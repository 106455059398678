import React, { useState, useEffect } from "react";
import {SiriDimensionView} from './SiriDimensionView';
import serviceProvider from '../../services/axios';

export const SiriDimensionCtrl = (props) => {

    const [results, setResults] = useState([]);
    const [update, setUpdate] = useState(false);
	const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
	const segments = window.location.pathname.slice(1).split("/");
    const plant_slug = (typeof segments[1] !== "undefined" ? segments[1] : 0);
    const dimen_slug = (typeof segments[2] !== "undefined" ? segments[2] : 0);

    useEffect(() => {
        const search = async () => {
          const apiUrl = `/siri?param=question&slug=${plant_slug}&dimen_slug=${dimen_slug}&uid=${usid}`;
          const { data } = await serviceProvider.get(apiUrl, true, {});
          setResults(data);
        };
        search();
      },[update]);


   
        if(results.length) {
                return (
                <>
                    <SiriDimensionView siriData={results} setUpdate={setUpdate} {...props}/>
                </>
                );
        }else{
            return (
                <>
                <div></div>
                </>
            );

        }
}