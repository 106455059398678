/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Moment ===> component for the moment date library.
  * useReactToPrint ===> For functional components, use the useReactToPrint hook
  * FacebookShareButton, LinkedinShareButton, TwitterShareButton ===> component from react-share

**/

import React, { useState, useRef } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import QRCode from "qrcode.react";
//CertificatesTabPanel component start
export const CertificatesTabPanel = (props) => {
  //defining variables here
  const [isCertificatePopup, setIsCertificatePopup] = useState(0);
  const HandleCertificatePopup = (index) => {
    setIsCertificatePopup(index);
  };
  const profileDataItems = props.profileData;
  if (!profileDataItems.length) {
    return null;
  }
  const profileDataItem = props.profileData[0];
  const courses = profileDataItem.courses;
  const uid = profileDataItem.uid;
  let completedtrainging = 0;
  const certifiedCourses = [];
  const certificateData = [];
  courses?.map((data) => {
    if (data.cardStatus == "Completed" && data.id == 11 && profileDataItem.all_quiz==1) {
        completedtrainging = completedtrainging + 1;
        certificateData.push({
          id: data.id,
          title: data.course_name,
          from: "SmarterChains in colloraton with UNITAR",
          completedDate: data.datecreated,
          issue_date: data.issue_date,
        });
        certifiedCourses.push(data);
    }
  });

  return (
    <>
      {/* tab wrapper has certificate_row */}
      <div className="certificate__tab">
        {/* row is further divided into col */}
        <div className="certificate__row">
          {!completedtrainging ? (
            /*
                            this section has card
                        */
            <div className="certificate__col">
              {/* this card has
                                card_header
                                card_body
                                card_footer
                            */}
              <div className="certificate__card">
                {/* this section has image */}
                <div className="card__header">
                  <img
                    src={
                      require("../../assets/img/profile/certificate-card.svg")
                        .default
                    }
                    alt="true"
                    className="certificate-card-img"
                  />
                </div>
                {/* this section has content */}
                <div className="card__body pb-35">
                  <p className="mb-14">Sorry, no certificates found. </p>
                  <p className="b">Please complete a Course</p>
                </div>
                {/* this section has button */}
                <div className="card__footer">
                  <Link to="/training-courses" className="btn__footer__blue">
                    Start Now
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            certificateData?.map((item, index) => (
              <div className="certificate__col" key={index}>
                {/* this card has
                                    card_header
                                    card_body
                                    card_footer
                                */}
                <div className="certificate__card">
                  {/* this section has 
                                        certificate
                                        title
                                    */}
                  <div className="card__header">
                    <p className="label">Certificate</p>
                    {/* <p className="title">{item.title}</p> */}
                    <p className="title">
                      Industry 4.0 for Sustainable Development
                    </p>
                  </div>
                  {/* this section has content */}
                  <div className="card__body">
                    <div className="content">
                      <p className="label"> From </p>
                      <p className="body_text">{item.from}</p>
                    </div>
                  </div>
                  {/* this section has
                                        date
                                        view-btn
                                    */}
                  <div className="card__footer">
                    <p className="label">
                      Completed on &nbsp;
                      <Moment format="DD/MM/YYYY">{item.completedDate}</Moment>
                    </p>
                    <button
                      className="btn__footer__blue"
                      onClick={() => {
                        HandleCertificatePopup(item.id);
                      }}
                    >
                      View certificate
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div>
        {courses?.map((course, index) => {
          return (
            <div
              key={course.id}
              style={{
                display: isCertificatePopup == course.id ? "block" : "none",
              }}
            >
              <Certificates
                HandleCertificatePopup={HandleCertificatePopup}
                qrValue={course.cirtificate_qr_url}
                certificateId={course.cirtificate_id}
                name={`${profileDataItem.name} ${profileDataItem.last_name}`}
                date={course.datecreated}
                courseName={course.course_name}
                issueOn={course.issue_on}
                issueYear={course.issueYear}
                issueMonth={course.issueMonth}
                qr_code_file={course.qr_code_file}
                downloadUrl={course.certificateUrl}
                issue_date={course.issue_date}
                prevId={index === 0 ? 0 : courses[index - 1].id}
                nextId={
                  index === courses.length - 1 ? 0 : courses[index + 1].id
                }
                id={`certifcate-sec-${course.id}`}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

//function for certificate
const Certificates = (props) => {
  //defining variables here
  const [isLinkCopy, setIsLinkCopy] = useState(false);
  const [isAddManually, setIsAddManually] = useState(false);
  const componentRef1 = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef1.current,
  });
  const CopyText = (index) => {
    const copylink = document.getElementById(index);
    copylink.select();
    document.execCommand("copy");
    setIsLinkCopy(true);
    setTimeout(() => {
      setIsLinkCopy(false);
    }, 2000);
  };
  return (
    <>
      {/* main wrapper */}
      <div className="certificate-210621-main">
        {/* section has 
                    close-btn
                    card-header
                    card-body
                */}
        <div className="content-210621">
          <button
            className="close-btn"
            onClick={() => {
              props.HandleCertificatePopup(0);
              setIsAddManually(false);
            }}
          >
            <img
              src={
                require("../../assets/img/common-img/certificate-close.svg")
                  .default
              }
              alt="true"
            />
          </button>
          {/* close-btn end */}
          {/* header has 
                        heading 
                        previous button
                        next button
                    */}
          <div className="card-header">
            <h1>
              {/*Factory of the Future Leadership Training Course*/}
              {/* {props.courseName} Training Course */}
              Industry 4.0 for Sustainable Development
            </h1>
          </div>
          {/* card-header end */}

         
          <div className="card-body">
            
            <div className="card-body-left">
              <div className="issued-text">
                <span>Issued On: {props.issueOn}</span>
                <div className="green-txt">
                  <img
                    src={
                      require("../../assets/img/common-img/verified-green-cir.svg")
                        .default
                    }
                  />
                  <p>
                    <a href={props.qrValue} target="_blank">
                      Verified Certificate
                    </a>
                  </p>
                </div>
              </div>
              {/* issued-text end */}
              <div ref={componentRef1}>
                <div className="certificate-box-wrapper">
                  <div className="certificate-box">
                    <div className="logo-div">
                      <img
                        src={
                          require("../../assets/img/common-img/certificate-logo.svg")
                            .default
                        }
                        alt="smarterchains"
                      />
                    </div>
                    <h1>Certificate of Completion</h1>
                    <h5>This is to certify that</h5>
                    <h2 className="name">{props.name}</h2>
                    <h5>has successfully completed</h5>
                    <p className="course-name">
                      Industry 4.0 for Sustainable Development
                    </p>
                    <div className="date-and-sign-wrapper">
                      <p className="date">
                        <Moment format="DD/MM/YYYY">{props.date}</Moment>
                        <span>Date</span>
                      </p>
                      <p className="sign-img">
                        <img
                          src={
                            require("../../assets/img/common-img/certificate-sign.svg")
                              .default
                          }
                          alt="true"
                        />
                        <span>Vasileios Karamalegkos, CEO</span>
                      </p>
                    </div>
                    <div className="qr-code-div">
                      <img src={props.qr_code_file} alt="true" />
                      <p>Certificate ID: {props.certificateId}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sharing-div">
                {/* sharing components */}
                <TwitterShareButton
                  className="btn-share"
                  quote=""
                  url='https://unitar.smarterchains.com/'
                >
                  <img
                    src={
                      require("../../assets/img/common-img/twitter-certificate.svg")
                        .default
                    }
                    className="twitter"
                    alt="twitter"
                  />
                </TwitterShareButton>
                <LinkedinShareButton
                  className="btn-share"
                  quote=""
                  url='https://unitar.smarterchains.com/'
                >
                  <img
                    src={
                      require("../../assets/img/common-img/linkedin-certificate.svg")
                        .default
                    }
                    className="linkedin"
                    alt="linkedin"
                  />
                </LinkedinShareButton>
                <FacebookShareButton
                  className="btn-share"
                  quote=""
                  url='https://unitar.smarterchains.com/'
                >
                  <img
                    src={
                      require("../../assets/img/common-img/facebook-certificate.svg")
                        .default
                    }
                    className="facebook"
                    alt="facebook"
                  />
                </FacebookShareButton>
                <div
                  className={`share-link-box ${isLinkCopy && "copied"}`}
                  onClick={() => CopyText(props.qrValue)}
                >
                  {/* this section has
                                        input 
                                        copy-btn
                                        link-copy-msg
                                    */}
                  <input
                    className="input"
                    id={props.qrValue}
                    defaultValue={props.qrValue}
                  />
                  <span className="copy-btn">copy</span>
                  {/* this section has
                                        image 
                                        text
                                    */}
                  <div
                    className={`link-copy-msg ${isLinkCopy && "copied"}`}
                    style={{ "pointer-events": "none" }}
                  >
                    <img
                      src={
                        require("../../assets/img/common-img/link-copied.svg")
                          .default
                      }
                      alt="true"
                    />
                    <span>Link copied successfully</span>
                  </div>
                </div>
              </div>
              {/* social icons */}
            </div>
            {/* card-body-left end */}
            <div
              className="card-body-right"
              style={{ display: isAddManually ? "none" : "block" }}
            >
              {/* this section has
                                link
                                button
                                heading
                                show-case-txt
                                link
                                button                          
                            */}
              <a
                target="_blank"
                href={props.downloadUrl}
                className="rgt-btn download"
              >
                <div>
                  <svg
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.6665 10.0001C1.6665 5.40008 5.39984 1.66675 9.99984 1.66675C14.5998 1.66675 18.3332 5.40008 18.3332 10.0001C18.3332 14.6001 14.5998 18.3334 9.99984 18.3334C5.39984 18.3334 1.6665 14.6001 1.6665 10.0001ZM14.9425 8.9425L16 10L10 16L4 10L5.065 8.935L9.25 13.1275V4H10.75V13.1275L14.9425 8.9425Z"
                    />
                  </svg>
                </div>
                <span>Download Certificate</span>
              </a>
              {/* rgt btn end */}
              <button className="rgt-btn print" onClick={handlePrint}>
                <div>
                  <svg
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15.8328 6.66667H4.16614C2.7828 6.66667 1.66614 7.78333 1.66614 9.16667V14.1667H4.99947V17.5H14.9995V14.1667H18.3328V9.16667C18.3328 7.78333 17.2161 6.66667 15.8328 6.66667ZM13.3328 15.8333H6.66614V11.6667H13.3328V15.8333ZM15.8328 10C15.3745 10 14.9995 9.625 14.9995 9.16667C14.9995 8.70833 15.3745 8.33333 15.8328 8.33333C16.2911 8.33333 16.6661 8.70833 16.6661 9.16667C16.6661 9.625 16.2911 10 15.8328 10ZM14.9995 2.5H4.99947V5.83333H14.9995V2.5Z" />
                  </svg>
                </div>
                <span>Print Certificate</span>
              </button>
              {/* rgt-btn end */}
              <h2 className="show-case-text">Showcase your skill</h2>
              <p className="show-case-dullTxt">
                Grow your career by adding this course to your LinkedIn.
              </p>
              <a
                className="rgt-btn add"
                target="_blank"
                href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Industry 4.0 for Sustainable Development&organizationId=10940742&issueYear=${props.issueYear}&issueMonth=${props.issueMonth}&expirationYear=&expirationMonth=&certUrl=https%3A%2F%2Fsmarterchains.com%2Fcertificate-verification%2F${props.certificateId}
&certId=${props.certificateId}`}
              >
                <div>
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" />
                  </svg>
                </div>
                <span>Add to LinkedIn profile</span>
              </a>
              {/* rgt-btn end */}
              <button
                className="btn-certificate"
                onClick={() => setIsAddManually(true)}
              >
                <img
                  src={
                    require("../../assets/img/common-img/add-manually.svg")
                      .default
                  }
                  alt="true"
                />
                <span>Add Certificate manually</span>
              </button>
            </div>
            {/* card-body-right end */}
            <div
              className="card-body-add-manually"
              style={{ display: !isAddManually ? "none" : "block" }}
            >
              {/* card has 
                                heading
                                list
                            */}
              <div className="card">
                {/* h1 has
                                    text
                                    button
                                */}
                <h1>
                  <span>Add Certificate manually</span>
                  <button onClick={() => setIsAddManually(false)}>
                    <img
                      src={
                        require("../../assets/img/common-img/certificate-blue-close.svg")
                          .default
                      }
                      alt="true"
                    />
                  </button>
                </h1>
                <ul>
                  <li>
                    <h2>Name of Course:</h2>
                    {/* <p>{props.courseName}</p> */}
                    <p>Industry 4.0 for Sustainable Development</p>
                  </li>
                  <li>
                    <h2>Issuing Organization:</h2>
                    <p>SmarterChains</p>
                  </li>
                  <li>
                    <h2>Issuing Date:</h2>
                    <p>{props.issue_date}</p>
                  </li>
                  <li>
                    <h2>Credential ID:</h2>
                    <p>{props.certificateId}</p>
                  </li>
                  <li>
                    <h2>Credential URL:</h2>
                    <p className="link">{props.qrValue}</p>
                  </li>
                </ul>
              </div>
            </div>
            {/* card-body-add-manually end */}
          </div>
          {/* card-body end */}
        </div>
      </div>
    </>
  );
};
