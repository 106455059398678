/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * Industry40View  ===> custom view component
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss ===> styling css file for this page.

**/

import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { QuickStart } from "../quickstart/quickstart-ctrl";
import { Industry40View } from "./industry-40-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import "./industry40.scss";
//Industry40Controller start
export const Industry40Controller = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const [tourComplete, setTourComplete] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
			const apiUrl = `/home?param=homedata&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, [tourComplete]);

	return (
		<>
			{/* Page Title */}
			<Helmet>
				<title>Industry 4.0 Dimensions | SmarterChains</title>
				<meta name="description" content="Industry 4.0 Dimensions" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/learning-center">Resource Center</BreadcrumbsItem>
			<BreadcrumbsItem to="/industry-40-dimensions">Industry 4.0 Dimensions</BreadcrumbsItem>
			{/* custom view component */}
			<Industry40View homeData={results} onTourComplete={setTourComplete} {...props} />
			
		</>
	);
};
