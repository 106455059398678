
import React, { useState, useEffect, useRef, memo } from "react";
const StepFourView = (props) => {
  return (
    <>
      <div className="container-fluid px-0">
        <div className="row outer-row no-gutters px-0">
          <div className="add-team-member-poup-outer" id="select_plant_name" style={{ display: 'none' }}>
          </div>
          <section className="col asst-right-sec" style={{ paddingLeft: '4vw' }}>
            {/* content header end  */}
            <div className="asst-scroll">
              <div className="asst-abt-content-body">
                <div className="row no-gutters px-0">
                  <div className="col asst-abt-left">
                    <div className="d-flex admin-page-empty-wrapper no-assesed-wrapper bc-master-wrapper">
                      <div className="col admin-page-empty-left">
                        <div className="admin-page-card">
                          <div className="row adm-card-header d-flex position-relative">
                            <div className="col-6 adm-col justify-content-start adm_col_lft_wrap">
                              <div className="d-flex rmap-header-right-wrapper align-items-center">
                                <div className="col-8 px-0">
                                  Roadmap Steps Selected
                                </div>
                                <div className="col-4 pr-2">
                                  <div className="bc-filter-sec">
                                    <div className="d-flex justify-content-end">
                                      <button className="rmap-btn open-filter" data-target="#filpopup">
                                        <img src={require("../../assets/img/roadmap-img/filter-btn.svg").default} className="btn-save-img" />
                                        {/* <span>Filters</span> */}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* col end */}
                            <div className="col-6 adm-col justify-content-center border-0 adm_col_rgt_wrap overflow-visible">
                              <div className="d-flex rmap-header-right-wrapper align-items-center">
                                <div className="col-12 px-0">
                                  <div className="bc-scenario-box " id="scenarioBox1">
                                    <button className="bc-scenario-box-header btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                                      <span className="scid-230">Roadmap Example</span>
                                      <svg className="ddl-arrow" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.175 0L5 3.7085L8.825 0L10 1.1417L5 6L0 1.1417L1.175 0Z" fill="#4D4D4F" />
                                      </svg>
                                    </button>
                                  </div>
                                  <div className="add-team-member-poup-outer scenario_pop" id="add_scenario" style={{ display: 'none' }}>
                                    <div className="add-team-poup-flex-div">
                                      <div className="add-team-poup-content">
                                        <div className="d-flex align-items-center">
                                          <input type="hidden" id="invitePeoplepid" defaultValue />
                                          <div className="w-75">
                                            <h1 className="h1-blue-txt">Add Scenario
                                              <span id="add-pop-plant-name" />
                                            </h1>
                                          </div>
                                          <div className="w-25 text-right">
                                            <a href="javascript:void(0)" className="close-custom-poup" data-target="#select_plant_name">
                                            <img src={require("../../assets/img/roadmap-img/close-poup-blue-icon.svg").default} className="btn-close-img" alt />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="form-group email-id-row add-team-email-row add-sc-row">
                                          <label htmlFor="scname" className="add-team-email-label">Scenario
                                            Name</label>
                                          <input type="text" id="scname" placeholder="Default Scenario" autoComplete="off" />
                                        </div>
                                        <div className="add-team-member-poup-tab-wrapper">
                                          <button className="popup-invite-btn add-scen-btn" data-pid={18}>Save Scenario</button>
                                        </div>
                                        <p id="addScErr" className="bc-box-err" style={{ display: 'none' }} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="add-team-member-poup-outer scenario_pop" id="rename_scenario" style={{ display: 'none' }}>
                                    <div className="add-team-poup-flex-div">
                                      <div className="add-team-poup-content">
                                        <div className="d-flex align-items-center">
                                          <input type="hidden" id="invitePeoplepid" defaultValue />
                                          <div className="w-75">
                                            <h1 className="h1-blue-txt">Rename Scenario
                                              <span id="add-pop-plant-name" />
                                            </h1>
                                          </div>
                                          <div className="w-25 text-right">
                                            <a href="javascript:void(0)" className="close-custom-poup" data-target="#select_plant_name">
                                              <img src={require("../../assets/img/roadmap-img/close-poup-blue-icon.svg").default} className="btn-close-img" alt />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="form-group email-id-row add-team-email-row re-sc-row">
                                          <label htmlFor="scname_re" className="add-team-email-label">Scenario
                                            Name</label>
                                          <input type="text" id="scname_re" defaultValue />
                                          <input type="hidden" id="scid_re" defaultValue />
                                        </div>
                                        <div className="add-team-member-poup-tab-wrapper">
                                          <button className="popup-invite-btn re-scen-btn" data-pid={18}>Rename Scenario</button>
                                        </div>
                                        <p id="reScErr" className="bc-box-err" style={{ display: 'none' }} />
                                      </div>
                                    </div>
                                  </div>
                                  {/* active-scenario-box end */}
                                </div>
                                {/* col-4 end  */}
                                <div className="col-3 px-0">
                                  <div className="d-flex justify-content-end">
                                    {/* <button class="save-rmap-btn save-roadmap">
                                                                                                              <span>Save roadmap</span>
                                                                                                          </button> */}
                                    {/* btn save raodmap */}
                                  </div>
                                  {/* flex end */}
                                </div>
                                {/* col-8 end */}
                              </div>
                              {/* rmap header right wrapper */}
                            </div>
                            {/* col end */}
                          </div>
                          {/*card header end */}
                          <div className="adm-card-body pb-0 p-0 position-relative border-0">
                            <div className="d-flex h-100">
                              <div className="col-6 ramp-col-p bc_lft-cont_wrap">
                                <div className="rm-techlist-wrapper" id="rp-area">
                                  <div className="left-add-scroll">
                                    <div className="accordion" id="rmapr">
                                      <div id="node-32" attr-name="Industry 4.0 Strategy & Masterplan" attr-id={32} attr-order={1} attr-investment={0} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card node-parent card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span className="rmap-rght-no">1</span>
                                            </a>
                                            <p className="rmap-txt">
                                              Industry 4.0 Strategy &amp;
                                              Masterplan </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green"><span className="l-reco l-reco-32" data-val>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange"><span className="l-inv l-inv-32" data-val={0}>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment d-none">
                                                  Loss: <cdiv className="orange">
                                                    <span className="l-loss l-loss-32" data-val={0}>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-32" aria-expanded="false" aria-controls="removenodemodal-32">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                            </a>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={32}>
                                              <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="node-24" attr-name="Digital Skills Cultivation" attr-id={24} attr-order={2} attr-investment={0} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card node-parent card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span className="rmap-rght-no">2</span>
                                            </a>
                                            <p className="rmap-txt">
                                              Digital Skills Cultivation </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green"><span className="l-reco l-reco-24" data-val>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange"><span className="l-inv l-inv-24" data-val={0}>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment d-none">
                                                  Loss: <cdiv className="orange">
                                                    <span className="l-loss l-loss-24" data-val={0}>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-24" aria-expanded="false" aria-controls="removenodemodal-24">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                            </a>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={24}>
                                              <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="node-23" attr-name="Site Digital Champion" attr-id={23} attr-order={3} attr-investment={37000} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card node-parent card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span className="rmap-rght-no">3</span>
                                            </a>
                                            <p className="rmap-txt">
                                              Site Digital Champion </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green"><span className="l-reco l-reco-23" data-val>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange"><span className="l-inv l-inv-23" data-val={37000}>37,000</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment d-none">
                                                  Loss: <cdiv className="orange">
                                                    <span className="l-loss l-loss-23" data-val={0}>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-23" aria-expanded="false" aria-controls="removenodemodal-23">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                            </a>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={23}>
                                              <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="node-25" attr-name="IT/OT Organization Integration" attr-id={25} attr-order={4} attr-investment={150000} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card node-parent card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span className="rmap-rght-no">4</span>
                                            </a>
                                            <p className="rmap-txt">
                                              IT/OT Organization Integration </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green"><span className="l-reco l-reco-25" data-val>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange"><span className="l-inv l-inv-25" data-val={150000}>150,000</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment d-none">
                                                  Loss: <cdiv className="orange">
                                                    <span className="l-loss l-loss-25" data-val={0}>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-25" aria-expanded="false" aria-controls="removenodemodal-25">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                            </a>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={25}>
                                              <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="node-27" attr-name="Edge Computing & Edge Gateways/Devices" attr-id={27} attr-order={5} attr-investment={150000} attr-recoverable="834.79032" attr-loss="13913.172" data-filter="type1 priority1" className="rmap-parent-card node-parent card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span className="rmap-rght-no">5</span>
                                            </a>
                                            <p className="rmap-txt">
                                              Edge Computing &amp; Edge
                                              Gateways/Devices </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green"><span className="l-reco l-reco-27" data-val="834.79032">835</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange"><span className="l-inv l-inv-27" data-val={150000}>150,000</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment d-none">
                                                  Loss: <cdiv className="orange">
                                                    <span className="l-loss l-loss-27" data-val="13913.172">13,913</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-27" aria-expanded="false" aria-controls="removenodemodal-27">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                            </a>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={27}>
                                              <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="node-30" attr-name="Industrial Tablets" attr-id={30} attr-order={6} attr-investment={571000} attr-recoverable="236492.4133621704" attr-loss="3129554.7312601916" data-filter="type1 priority1" className="rmap-parent-card node-parent card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span className="rmap-rght-no">6</span>
                                            </a>
                                            <p className="rmap-txt">
                                              Industrial Tablets </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green"><span className="l-reco l-reco-30" data-val="236492.4133621704">236,492</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange"><span className="l-inv l-inv-30" data-val={571000}>571,000</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment d-none">
                                                  Loss: <cdiv className="orange">
                                                    <span className="l-loss l-loss-30" data-val="3129554.7312601916">3,129,555</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-30" aria-expanded="false" aria-controls="removenodemodal-30">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                            </a>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={30}>
                                              <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div id="node-37" attr-name="Connected Workforce Platform" attr-id={37} attr-order={7} attr-investment={675000} attr-recoverable="1075105.0851266505" attr-loss="8350847.987869731" data-filter="type1 priority1" className="rmap-parent-card node-parent card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span className="rmap-rght-no">7</span>
                                            </a>
                                            <p className="rmap-txt">
                                              Connected Workforce Platform </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green"><span className="l-reco l-reco-37" data-val="1075105.0851266505">1,075,105</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange"><span className="l-inv l-inv-37" data-val={675000}>675,000</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment d-none">
                                                  Loss: <cdiv className="orange">
                                                    <span className="l-loss l-loss-37" data-val="8350847.987869731">8,350,848</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-37" aria-expanded="false" aria-controls="removenodemodal-37">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                            </a>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={37}>
                                              <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* col 6 */}
                              <div className="col-6 ramp-col-p bc_rgt-cont_wrap bc_rec_calib">
                                <div id="dropzonearea" className="droparea" data-drop-target="true">
                                  <div className="rc-data-sec">
                                    <p className="bc_mn_head">Final Roadmap Steps</p>
                                    <div className="row loss_total_val">
                                      <div className="col-3">
                                        Total Losses<br />Identified:
                                        <div className="bc_tot_spa red"><span id="totalloss" data-totalloss="41979738.65320638">41,979,739</span>
                                          <cspan>$</cspan>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        Total Roadmap<br />Recoverable:
                                        <div className="bc_tot_spa green"><span id="totalrecoverable" data-totalrecoverable="3749234.0241629905">3,749,234</span>
                                          <cspan>$</cspan>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        % of Recoverable<br />Losses:
                                        <div className="bc_tot_spa blue"><span id="totallossper" data-totallossper="8.9310562157391">9</span><span>%</span>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        Total Roadmap<br />Investment:
                                        <div className="bc_tot_spa orange"><span id="totalinvestment" data-totalinvestment={3108000}>3,108,000</span>
                                          <cspan>$</cspan>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="rmapq" className="pt-0 add-scroll">
                                    <div className="bc_remove_info loss_total_val mx-bc-tl row">
                                      <div className="col-12 bc_alert_msg">
                                        <span className="bc_astriq"><img src={require("../../assets/img/roadmap-img/bc-astriq.svg").default} /></span>
                                        <p>Click on the + icon to move the Roadmap step
                                          to the right column, to finalize its
                                          position. Two new
                                          tabs have been added in each Capability, so
                                          make sure to fill in Vendor and
                                          Implementation data.</p>
                                        <a href="javascript:void(0)" className="bc_close_alert"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default} /></a>
                                      </div>
                                    </div>
                                    <div className="accordion" id="roadmap-nodes">
                                      <div id="remove-node-32" attr-name="Industry 4.0 Strategy & Masterplan" attr-id={32} attr-order={1} attr-investment={0} attr-recoverable={0} attr-loss={0} className="card active switch dragged-active" draggable="false">
                                        <div className="rmap-card card-header">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge" draggable="false">
                                              <span className="rmap-rght-no">1</span>
                                            </a>
                                            <p className="rmap-txt">
                                              Industry 4.0 Strategy &amp;
                                              Masterplan </p>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <div className="bc_pro_data">
                                              <ul className="list-unstyled">
                                                <li className="recoverable">
                                                  Recoverable: <cdiv className="green">
                                                    <span>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                                <li className="investment">
                                                  Investment: <cdiv className="orange">
                                                    <span>0</span>
                                                    <cspan>$</cspan>
                                                  </cdiv>
                                                </li>
                                              </ul>
                                            </div>
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn remove-node" attr-id={32} attr-nodeid="node-32" attr-investment={0} attr-recoverable={0} attr-loss={0} draggable="false">
                                              <img src={require("../../assets/img/roadmap-img/bc-rmap-minus.svg").default} className="bc_open" draggable="false" />

                                            </a>
                                            <a href="javascript:void(0)" className="btn btn-header-link" data-toggle="collapse" data-target="#removenodemodal-32" aria-expanded="true" aria-controls="removenodemodal-32" draggable="false">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" draggable="false" />
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" draggable="false" />
                                            </a>
                                          </div>
                                        </div>
                                        <div id="removenodemodal-32" className="collapse show" aria-labelledby="node-32" data-parent="#roadmap-nodes" style={{}}>
                                          <div className="card-body">
                                            <ul className="nav nav-tabs" role="tablist">
                                              <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#rm-description-32" role="tab" draggable="false" aria-selected="false">Description</a>
                                              </li>
                                              <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#rm-loss-32" role="tab" draggable="false">Losses
                                                  &amp;
                                                  Recoverables</a>
                                              </li>
                                              <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#rm-vendor-32" role="tab" draggable="false">Vendors</a>
                                              </li>
                                              <li className="nav-item">
                                                <a className="nav-link active show" data-toggle="tab" href="#rm-timeline-32" role="tab" draggable="false" aria-selected="true">Implementation</a>
                                              </li>
                                            </ul>
                                            <div className="tab-content pb-0">
                                              <div className="tab-pane" id="rm-description-32" role="tabpanel">
                                                <p>Define an Industry 4.0
                                                  site-specific strategy and
                                                  deploy it across the site
                                                </p>
                                                <p>Make sure all personnel is
                                                  aligned with the site's
                                                  Industry 4.0 plans &amp;
                                                  transformation
                                                  expectations
                                                </p>
                                                <p>Connect the strategy to the
                                                  site's business needs with
                                                  clear actions to drive
                                                  progress
                                                </p>
                                                <p>Create a digital organization
                                                  design that clearly defines
                                                  roles &amp; responsibilities
                                                </p>
                                                <p>Assign a leader for digital
                                                  transformation initiatives
                                                  with clear budget ownership
                                                </p>
                                                <p>Incorporate the Industry 4.0
                                                  strategy into the site's
                                                  vision &amp; manufacturing
                                                  capability
                                                  Masterplan
                                                </p>
                                                <p>A solid Masterplan should:
                                                </p>
                                                <ul>
                                                  <li>Set out the
                                                    transformation
                                                    priorities</li>
                                                  <li>Identify the resource
                                                    requirements for each
                                                    initiative</li>
                                                  <li>Set clear actions &amp;
                                                    accountabilities</li>
                                                  <li>Provide well-defined
                                                    update reviews for all
                                                    organization
                                                    participants of the
                                                    Industry 4.0
                                                    strategy</li>
                                                  <li>Have clear actionable
                                                    links with the site’s
                                                    overall vision &amp;
                                                    strategy</li>
                                                </ul>
                                                <p className="bc_head_h4">Benefits
                                                </p>
                                                <ul className="mb-08vw">
                                                  <li>Provide clear direction
                                                    of digitization and
                                                    automation to company’s
                                                    enterprise future</li>
                                                  <li>Engage and inspire the
                                                    organization </li>
                                                  <li>Drive systemic
                                                    progression &amp; change
                                                    management towards
                                                    Industry 4.0</li>
                                                  <li>Drive productivity
                                                    through organizational
                                                    innovation</li>
                                                  <li>Adhere to a single
                                                    unified set of rules
                                                    &amp; procedures</li>
                                                  <li>Discover new
                                                    opportunities to
                                                    automate manual
                                                    operations</li>
                                                  <li>Discover new
                                                    opportunities to
                                                    digitize paper-based
                                                    processes</li>
                                                </ul>
                                                <div className="bc_leadership">
                                                  {/* <img src={require("../../assets/img/roadmap-img/bc-12.svg").default} className="bc_close"/> */}
                                                  Factory of the Future
                                                  Leadership
                                                </div>
                                              </div>
                                              <div className="tab-pane loss_recover_wrapper" id="rm-loss-32" role="tabpanel">
                                                <div className="accordion loss_recover_wrap" id="loss-32">
                                                </div>
                                                {/* loss total value */}
                                                <div className="row loss_total_val">
                                                  <div className="col-4">
                                                    Associated <br /> Losses:
                                                    <span className="red">0
                                                      <cspan>$</cspan>
                                                    </span>
                                                  </div>
                                                  <div className="col-4">
                                                    Total Technology <br />
                                                    Recoverable:
                                                    <span className="green">0
                                                      <cspan>$</cspan>
                                                    </span>
                                                  </div>
                                                  <div className="col-4">
                                                    % of Total <br />
                                                    Recovered Losses:
                                                    <span className="blue">0%</span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="tab-pane" id="rm-vendor-32" role="tabpanel">
                                                <div className="check-box-gallery">
                                                  <div className="gallery-card">
                                                    <div className="gallery-card-body">
                                                      <figure className="open-vendor-detail" data-nid={32} data-vid={174}>
                                                        {/* <img className="logo-vendor" src="assets/img/loading.gif" data-src="assets/vendors/SmarterChains.png" draggable="false" /> */}
                                                      </figure>
                                                      <label className="block-check">
                                                        <input type="checkbox" className="checkbox vendor-check vendor-check-32" data-nid={32} defaultValue={174} defaultChecked />
                                                        <span className="checkmark">SmarterChains</span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="vendor-detail vendor-pop-32-174" id="vendor-pop-32-174">
                                                    <h3 className="vendor-name">
                                                      SmarterChains
                                                      <button type="button" className="close close-vendor-pop">
                                                        <img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default} alt="cross-blue" draggable="false" />
                                                      </button>
                                                    </h3>
                                                    <div className="tab-area">
                                                      <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                          <a className="nav-link active" data-toggle="tab" href="#rm-investment-32-174" role="tab" draggable="false">Investment</a>
                                                        </li>
                                                        <li className="nav-item">
                                                          <a className="nav-link" data-toggle="tab" href="#rm-recoverable-32-174" role="tab" draggable="false">Recoverable</a>
                                                        </li>
                                                      </ul>
                                                      {/* Tab panes */}
                                                      <div className="tab-content">
                                                        <div className="tab-pane investment-vtab active" id="rm-investment-32-174" role="tabpanel">
                                                          <div className="investment-details">
                                                            <div className="list_invester">
                                                              <span className="invester ">CAPEX</span>
                                                              <span className="d-value form-group">
                                                                <div className="input-group inp_disabled">
                                                                  <input type="text" defaultValue={0} disabled />
                                                                  <div className="input-group-append">
                                                                    <span className="input-group-text">$</span>
                                                                  </div>
                                                                </div>
                                                              </span>
                                                            </div>
                                                            <div className="list_invester">
                                                              <span className="invester">Starting
                                                                OPEX</span>
                                                              <span className="d-value form-group">
                                                                <div className="input-group inp_disabled">
                                                                  <input type="text" defaultValue={0} disabled />
                                                                  <div className="input-group-append">
                                                                    <span className="input-group-text">$</span>
                                                                  </div>
                                                                </div>
                                                              </span>
                                                            </div>
                                                            <div className="list_invester upfront">
                                                              <span className="invester">Upfront
                                                                Investment</span>
                                                              <span className="d-value form-group">
                                                                <div className="input-group inp_disabled">
                                                                  <input type="text" defaultValue={0} disabled />
                                                                  <div className="input-group-append">
                                                                    <span className="input-group-text">$</span>
                                                                  </div>
                                                                </div>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="list_invester going-opex">
                                                            <span className="invester">Going
                                                              OPEX</span>
                                                            <span className="d-value form-group">
                                                              <div className="input-group inp_disabled">
                                                                <input type="text" defaultValue={0} disabled />
                                                                <div className="input-group-append">
                                                                  <span className="input-group-text">$</span>
                                                                </div>
                                                              </div>
                                                            </span>
                                                          </div>
                                                          <div className="no-user">
                                                            <label>No.
                                                              of
                                                              Users:</label>
                                                            <input type="text" defaultValue={0} disabled />
                                                          </div>
                                                        </div>
                                                        <div className="tab-pane recoverable-vtab" id="rm-recoverable-32-174" role="tabpanel">
                                                          <div id="accordion-32-174">
                                                            {/* This code for display recoverable section*/}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <p className="vendor-err vendor-err-32" style={{ display: 'none' }}>You
                                                  can select a maximum 3
                                                  Vendors</p>
                                              </div>
                                              <div className="tab-pane active show" id="rm-timeline-32" role="tabpanel">
                                                <div className="implementation">
                                                  <h3>Implementation Timeline
                                                  </h3>
                                                  <h4>Reccommended
                                                    implementation period:
                                                    <span>1 quarter once per
                                                      year</span>
                                                  </h4>
                                                  <div className="timeline-box tlb-448">
                                                    <h5>
                                                      <span className="tlb-heading-32">
                                                        Timeline 1
                                                      </span>
                                                      <button className="delete del-tlb-btn" data-tlb={448} data-nid={32}>
                                                        <img src={require("../../assets/img/roadmap-img/delete-icon.svg").default} className="bc_close" />
                                                      </button>
                                                    </h5>
                                                    <ul>
                                                      <li>
                                                        <span>Start of
                                                          Implementation</span>
                                                        <select className="imp-start-sel imp-start-sel-32" data-nid={32} data-tlb={448}>
                                                          <option value={11} selected>
                                                            Year 1 -
                                                            Q1
                                                          </option>
                                                          <option value={12}>
                                                            Year 1 -
                                                            Q2
                                                          </option>
                                                          <option value={13}>
                                                            Year 1 -
                                                            Q3
                                                          </option>
                                                          <option value={14}>
                                                            Year 1 -
                                                            Q4
                                                          </option>
                                                          <option value={21}>
                                                            Year 2 -
                                                            Q1
                                                          </option>
                                                          <option value={22}>
                                                            Year 2 -
                                                            Q2
                                                          </option>
                                                          <option value={23}>
                                                            Year 2 -
                                                            Q3
                                                          </option>
                                                          <option value={24}>
                                                            Year 2 -
                                                            Q4
                                                          </option>
                                                          <option value={31}>
                                                            Year 3 -
                                                            Q1
                                                          </option>
                                                          <option value={32}>
                                                            Year 3 -
                                                            Q2
                                                          </option>
                                                          <option value={33}>
                                                            Year 3 -
                                                            Q3
                                                          </option>
                                                          <option value={34}>
                                                            Year 3 -
                                                            Q4
                                                          </option>
                                                          <option value={41}>
                                                            Year 4 -
                                                            Q1
                                                          </option>
                                                          <option value={42}>
                                                            Year 4 -
                                                            Q2
                                                          </option>
                                                          <option value={43}>
                                                            Year 4 -
                                                            Q3
                                                          </option>
                                                          <option value={44}>
                                                            Year 4 -
                                                            Q4
                                                          </option>
                                                          <option value={51}>
                                                            Year 5 -
                                                            Q1
                                                          </option>
                                                          <option value={52}>
                                                            Year 5 -
                                                            Q2
                                                          </option>
                                                          <option value={53}>
                                                            Year 5 -
                                                            Q3
                                                          </option>
                                                          <option value={54}>
                                                            Year 5 -
                                                            Q4
                                                          </option>
                                                        </select>
                                                      </li>
                                                      <li>
                                                        <span>End of
                                                          Implementation</span>
                                                        <select className="imp-end-sel imp-end-sel-32" data-nid={32} data-tlb={448}>
                                                          <option value={11}>
                                                            Year 1 -
                                                            Q1
                                                          </option>
                                                          <option value={12}>
                                                            Year 1 -
                                                            Q2
                                                          </option>
                                                          <option value={13}>
                                                            Year 1 -
                                                            Q3
                                                          </option>
                                                          <option value={14} selected>
                                                            Year 1 -
                                                            Q4
                                                          </option>
                                                          <option value={21}>
                                                            Year 2 -
                                                            Q1
                                                          </option>
                                                          <option value={22}>
                                                            Year 2 -
                                                            Q2
                                                          </option>
                                                          <option value={23}>
                                                            Year 2 -
                                                            Q3
                                                          </option>
                                                          <option value={24}>
                                                            Year 2 -
                                                            Q4
                                                          </option>
                                                          <option value={31}>
                                                            Year 3 -
                                                            Q1
                                                          </option>
                                                          <option value={32}>
                                                            Year 3 -
                                                            Q2
                                                          </option>
                                                          <option value={33}>
                                                            Year 3 -
                                                            Q3
                                                          </option>
                                                          <option value={34}>
                                                            Year 3 -
                                                            Q4
                                                          </option>
                                                          <option value={41}>
                                                            Year 4 -
                                                            Q1
                                                          </option>
                                                          <option value={42}>
                                                            Year 4 -
                                                            Q2
                                                          </option>
                                                          <option value={43}>
                                                            Year 4 -
                                                            Q3
                                                          </option>
                                                          <option value={44}>
                                                            Year 4 -
                                                            Q4
                                                          </option>
                                                          <option value={51}>
                                                            Year 5 -
                                                            Q1
                                                          </option>
                                                          <option value={52}>
                                                            Year 5 -
                                                            Q2
                                                          </option>
                                                          <option value={53}>
                                                            Year 5 -
                                                            Q3
                                                          </option>
                                                          <option value={54}>
                                                            Year 5 -
                                                            Q4
                                                          </option>
                                                        </select>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                  <div className="rm-append-tlb-32">
                                                  </div>
                                                  <div className="add-timeline add-tlb add-tlb-32" data-nid={32} data-tlb-count={1}>
                                                    <img src={require("../../assets/img/roadmap-img/add-timeline.svg").default} alt="add-timeline" draggable="false" />
                                                    Add
                                                    timeline
                                                  </div>
                                                  <div className="transform-stage">
                                                    <h5>Transformation Stage
                                                    </h5>
                                                    <div className="dropdown">
                                                      <button id="dLabel" className="dropdown-select t-stage-btn t-stage-btn-32" data-nid={32} type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        Foundation Stage
                                                        <span className="caret" />
                                                      </button>
                                                      <ul className="dropdown-menu t-stage t-stage-32" aria-labelledby="dLabel">
                                                        <li className="t-stage-li" data-val={1} data-nid={32}>
                                                          Foundation
                                                          Stage</li>
                                                        <li className="t-stage-li" data-val={2} data-nid={32}>
                                                          Acceleration
                                                          Stage</li>
                                                        <li className="t-stage-li" data-val={3} data-nid={32}>
                                                          Amplification
                                                          Stage</li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="appendNodes" />
                                      <div className="card card_disabled">
                                        <div className="card-header" id="rmaphead1">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span id="static-node" className="rmap-rght-no">16</span>
                                            </a>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id attr-nodeid>
                                              <img src={require("../../assets/img/roadmap-img/bc-rmap-minus.svg").default} />
                                            </a>
                                            <a href="#" className="btn btn-header-link collapsed">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="bc_proceed" className="bc_proceed_btn_wrap">
                                      <a href="automation/krakow-test/step3/230" className="btn all_btn_white bc-prev-btn proceed-btn">Previous</a>
                                      <a href="javascript:void(0)" className="btn all_btn_white proceed-btn proceed-btn-pop" style={{ display: 'none' }}>Proceed</a>
                                      <a data-url="automation/sceneriobc/krakow-test/230" id="step-4-process" className="btn all_btn_white proceed-btn proceed-btn-direct">Proceed</a>
                                    </div>
                                    {/* rmap card end 2 */}
                                  </div>
                                </div>
                              </div>
                              {/* col 6  */}
                            </div>
                            {/* d felx end */}
                          </div>
                          {/* card body end */}
                        </div>
                        {/* admin page card end */}
                      </div>
                      {/* left sec */}
                    </div>
                    {/*admin-page-empty-wrapper end */}
                  </div>
                  {/*asst-abt-left end*/}
                </div>
                {/* row end */}
              </div>
              {/* asst content body end*/}
            </div>
            {/* asst-scroll end */}
          </section>
          {/* right sec end */}
        </div>
        {/* outer row end */}
      </div>


    </>
  );
};

export default memo(StepFourView);