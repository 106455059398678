/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/

import React, { useState, useEffect, memo } from 'react';
import { Modal, Collapse, TextField } from '@material-ui/core'
import $ from 'jquery';
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import './maturity-admin-popup.scss';
import serviceProvider from '../../services/axios'
//selectbox component
const SelectBox = (props) => {
  const { options, selectedVal, setSelectedVal, HandleTooltip, labelName } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [tmpOption, setTmpOption] = useState(options);
  const [serchInput, setSerchInput] = useState('');
  //function to handle open
  const HandleOpen = (e) => {
    if (e == 'close') {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
      HandleTooltip();
    }
  }
  //function to handle value
  const HandleValue = (val) => {
    setSelectedVal(val);
    HandleOpen('close');         // close dropdown list
    setSerchInput('');          // null search input value;
    setTmpOption(options);      // reset all options;
  }
  //function to handle search
  const HandleSearch = (e) => {
    let val = e.target.value;
    setSerchInput(val);
    if (val) {
      let filterArr = options.filter((item) => item.name.toLowerCase().startsWith(val.toLowerCase()));
      setTmpOption(filterArr);
    } else {
      setTmpOption(options);
    }

  }
  window.onclick = (e) => {
    // close dropdown list on click outside;
    if (!e.target.closest('.input-field.select') && !e.target.closest('.ms-select-options')) {
      HandleOpen('close');
    }
  }
  return (
    <>
      {/* this section has
          title
          button
          ms-tooltip
          ms-select-options
      */}
      <label className={`label ${isOpen ? 'active' : ''}`}>
        <span className="input-title">{labelName ? labelName : 'Country'}</span>
        <button className={`input-field select ${selectedVal ? 'selected' : ''}`} onClick={HandleOpen}>
          <span>{selectedVal ? selectedVal.name : 'Pick a country'}</span>
          <svg className="ddl-arrow" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L5.75736 5.24264L1.51471 1" stroke="#7D868C" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </button>
        <div className="ms-tooltip">
          <p className="center-p">
            Select the {labelName ? labelName : 'Country'} in which your plant is located.
          </p>
        </div>
      </label>
      <div className="ms-select-options">
        <div className="ms-select-serch">
          <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.53022 8.46966C8.82311 8.17677 9.29798 8.17677 9.59088 8.46966L12.5302 11.409C12.8231 11.7019 12.8231 12.1768 12.5302 12.4697C12.2373 12.7626 11.7624 12.7626 11.4696 12.4697L8.53022 9.53032C8.23732 9.23743 8.23732 8.76255 8.53022 8.46966Z" fill="#7D868C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5ZM5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11Z" fill="#7D868C" />
          </svg>
          <input type="text" placeholder="Search" value={serchInput} onChange={(e) => HandleSearch(e)} />
        </div>
        <div className="list-wrapper">
          {
            tmpOption.length > 0 ?
              tmpOption.map((item, index) => <span className="list-link" onClick={() => HandleValue(item)} key={index}>{item.name}</span>)
              :
              <span className="list-link no-result-found">no result found</span>
          }
        </div>
      </div>

    </>
  )
}
//SelectCityBox component
const SelectCityBox = (props) => {
  //defining variables here
  const { options, selectedVal, setSelectedVal, HandleTooltip, labelName } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [tmpCityOption, setTmpCityOption] = useState(options);
  const [serchInput, setSerchInput] = useState('');
  // console.log(options)
  //function to handle open
  const HandleOpen = (e) => {
    if (e == 'close') {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
      HandleTooltip();
    }
  }
  //function to handle value
  const HandleValue = (val) => {
    setSelectedVal(val);
    HandleOpen('close');         // close dropdown list
    setSerchInput('');          // null search input value;
    setTmpCityOption(options);      // reset all options;
  }
  //function to handle search
  const HandleSearch = (e) => {
    let val = e.target.value;
    setSerchInput(val);
    if (val) {
      let filterArr = options.filter((item) => item.name.toLowerCase().startsWith(val.toLowerCase()));
      setTmpCityOption(filterArr);
    } else {
      setTmpCityOption(options);
    }

  }
  useEffect(
    () => {
      setTmpCityOption(options);
    }, [options]
  )
  window.onclick = (e) => {
    // close dropdown list on click outside;
    if (!e.target.closest('.input-field.select') && !e.target.closest('.ms-select-options')) {
      HandleOpen('close');
    }
  }
  // console.log(tmpCityOption)
  return (
    <>
      {/* this section has
          title
          button
          tootip
      */}
      <label className={`label ${isOpen ? 'active' : ''}`}>
        <span className="input-title">{labelName ? labelName : 'City'}</span>
        <button className={`input-field select ${selectedVal ? 'selected' : ''}`} onClick={HandleOpen}>
          <span>{selectedVal ? selectedVal.name : 'Pick a City'}</span>
          <svg className="ddl-arrow" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L5.75736 5.24264L1.51471 1" stroke="#7D868C" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </button>
        <div className="ms-tooltip">
          <p className="center-p">
            Select the {labelName ? labelName : 'City'} in which your plant is located.
          </p>
        </div>
      </label>
      {/* select options wrapper has
          ms-select-search
          list-wrapper
      */}
      <div className="ms-select-options">
        <div className="ms-select-serch">
          <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.53022 8.46966C8.82311 8.17677 9.29798 8.17677 9.59088 8.46966L12.5302 11.409C12.8231 11.7019 12.8231 12.1768 12.5302 12.4697C12.2373 12.7626 11.7624 12.7626 11.4696 12.4697L8.53022 9.53032C8.23732 9.23743 8.23732 8.76255 8.53022 8.46966Z" fill="#7D868C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5ZM5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11Z" fill="#7D868C" />
          </svg>
          <input type="text" placeholder="Search" value={serchInput} onChange={(e) => HandleSearch(e)} />
        </div>
        <div className="list-wrapper">
          {

            tmpCityOption.length > 0 ?
              tmpCityOption.map((item, index) => <span className="list-link" onClick={() => HandleValue(item)} key={index}>{item.name}</span>)
              :
              <span className="list-link no-result-found">no result found</span>
          }
        </div>
      </div>

    </>
  )
}
//MaturityPlantSetupPopup component start
export const MaturityPlantSetupPopup = (props) => {
  //defining variables here
  const [contt, setContt] = useState([]);
  const handleChange = (e) => {
    let id = e.target.formCity;
    setContt({ ...state, [id]: value })
  }
  const [countries, setCountries] = useState([])
 useEffect(() => {
    loadCountries();
  }, []);

  const loadCountries = async () => {
    const result = await serviceProvider.get("/plants/countries",true,{});
    const data = result.data
     if (data && Array.isArray(data)) {
        const options = data.map(d => ({
          "value": d.id,
          "name": d.country
        }))
        setCountries(options);
      }
   };
  const [isFirstTime, setIsFirstTime] = useState({
    first: false,
    second: true,
    third: true,
    fourth: true,
    fifth: true,
    sixth: true
  });
  const [otherAdmin, setOtherAdmin] = useState(false);
  const [popupFlowStep, setPopupFlowSetup] = useState(1);
  const [addFormData, setAddFormData] = useState({
    formPlantName: '',
    formCountry: '',
    formCity: '',
    formPlantAdmin: '',
    formPlantSize: ''
  });
  const [errors, setErrors] = useState([]);
//function to handle close
  const handleClose = () => {
    props.setOpen(!props.open);
    setIsFirstTime({
      first: false,
      second: true,
      third: true,
      fourth: true,
      fifth: true,
      sixth: true
    });
    setAddFormData({
      formPlantName: '',
      formCountry: '',
      formCity: '',
      formPlantAdmin: '',
      formPlantSize: ''
    });
    setErrors(errors.filter(st => st !== 'formPlantName'));
    setErrors(errors.filter(st => st !== 'formPlantAdmin'));
    setOtherAdmin(false);
  }
//function to handle form
  const HandleForm = (val, name) => {
    const { formPlantName, formCountry, formCity, formPlantAdmin, formPlantSize } = addFormData;
    let tmpObj = { ...addFormData, [name]: val };
    setAddFormData(tmpObj);
    if(name=='formPlantAdmin' && val=='yourself'){
      document.getElementById("addplantbtn").disabled = false;
      document.getElementById("adding-plant-error").style.display = "none";
      $('#add-plan-btn-parent').removeClass('overlay-blur');
    }else if(name=='formPlantAdmin' && val!='yourself'){
      const emailRegx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      document.getElementById("adding-plant-error").style.display = "block";
      if (emailRegx.test(String(val).toLowerCase()))
      {
        setErrors(errors.filter(st => st !== name))
        document.getElementById("addplantbtn").disabled = false;
        $('#add-plan-btn-parent').removeClass('overlay-blur');
      }
      else {
        setErrors([...errors, name]);
        document.getElementById("addplantbtn").disabled = true;
        $('#add-plan-btn-parent').addClass('overlay-blur');
      }
    }

    
    if (name == 'formPlantName') {
      if(val){
        setIsFirstTime((prevObj) => {
          return {
            ...prevObj,
            second: false
          }
        });
        setErrors(errors.filter(st => st !== name));
      }else{
        setErrors([...errors, name]);
      }
    }
    
    if (name == 'formCountry' && val) {
      setIsFirstTime((prevObj) => {
        return {
          ...prevObj,
          third: false
        }
      });


    }
    if (name == 'formCity' && val) {
      setIsFirstTime((prevObj) => {
        return {
          ...prevObj,
          fourth: false
        }
      });
    }
    /*
    if (name == 'formPlantAdmin' && val) {
      setIsFirstTime((prevObj) => {
        return {
          ...prevObj,
          fourth: false
        }
      });
    }*/
    if (name == 'formPlantSize') {
      setIsFirstTime((prevObj) => {
        return {
          ...prevObj,
          sixth: false
        }
      });
    }
  }
  const [cities, setCities] = useState([])
  useEffect(
    () => {
      if (addFormData) {
        if (addFormData.formCountry) {
          let asd = addFormData ? addFormData.formCountry ? addFormData.formCountry.value : null : null;
          if (asd) {
            serviceProvider.get(`/plants/CitiesByCountry?country_id=${asd}`,true,{}).then(res => {
             
              const data = res.data
              if (data && Array.isArray(data)) {
                const options = data.map(d => ({
                  "value": d.id,
                  "name": d.city_name
                }))
                //setCities(options);
              } else {
                //setCities({});
              }

            }).catch(err => console.log(err))
          }
        }

      }

    }, [addFormData]
  )
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const usname = user ? user.name : 0;
  //function to add plant
  	const AddPlant = () => {
		$('#add-plan-btn-parent').addClass('overlay-blur');
    	serviceProvider.post(`/plants?type=addnewplant&uid=${usid}&username=${usname}`, JSON.stringify(addFormData),true).then((response) => {
      		window.location = "/home";
    	});
  	}

  return (
    <>
      <Modal open={props.open}
        // onClose={handleClose}
        className="maturity-admin-popup">
          {/* this section has card */}
        <div className="ms-plant-setup-popup">
          {/* card has
              ms-popup-header
              ms-popup-content
          */}
          <div className="ms-plant-setup-card">
            {/* header has
                heading
                close button
            */}
            <div className="ms-popup-header">
              <h2 className="h1">
                Add Plant
              </h2>
              <button className="close-btn" onClick={handleClose}>
                <img src={require('../../assets/img/maturity-dashboard/cancel-dark.svg').default} alt="X" />
              </button>
            </div>
            {/* ms-popup-header end */}
            <div className="ms-popup-content">
              {/* this wrapper has
                  inputs
                  footer-btn-wrapper
              */}
              <div className="ms-form-wrapper">
                <div className={`ms-input-div ${popupFlowStep == 1 ? 'active' : ''}`}>

                  <label className="label">
                    <span className="input-title plant-name">Plant Name</span>
                    <input type="text" className={`${(errors.indexOf("formPlantName") > -1) ? '"ms-error' : ""} input-field`} value={addFormData.formPlantName} placeholder="Your Plant"
                      onChange={(e) => {
                        HandleForm(e.target.value, 'formPlantName');
                      }}
                      onFocus={
                        () => setPopupFlowSetup(1)
                      }
                    />

                    <div className="ms-tooltip">
                      <p className="center-p">
                        Enter your plant's name. 
                      {/*We recommend to use the company's name followed by a single reference like "Plant".*/}
                      </p>
                      <div className="divider-yellow"></div>
                      <div className="action-p">
                        <p className="div-img">
                          <img src={require('../../assets/img/maturity-dashboard/report-problem.svg').default} alt="true" />
                        </p>
                        <span>
                          Please note that you will not be able to change it after this step.
                        </span>
                      </div>
                    </div>

                  </label>
                  <label className={`${(errors.indexOf("formPlantName") > -1) ? 'error' : ""}`}>{(errors.indexOf("formPlantName") > -1) ? "Plant Name is required" : null}</label>

                  <p className="ms-error">You’ve unlocked the Full Digital Maturity Assessment!</p>
                </div>
                {/* plant name input end */}
                <div className={`ms-input-div ${!isFirstTime.second ? '' : 'overlay-blur'} ${popupFlowStep == 2 ? 'active' : ''}`}>
                  <SelectBox
                    selectedVal={addFormData.formCountry}
                    setSelectedVal={(e) => {
                      HandleForm(e, 'formCountry');
                    }}
                    HandleTooltip={() => setPopupFlowSetup(2)}
                    options={countries}
                    labelName="Country"
                  />
                  <label className={`${(errors.indexOf("formCountry") > -1) ? 'error' : ""}`}>{(errors.indexOf("formCountry") > -1) ? "Country is required" : null}</label>

                </div>
                {/* country dropdown list end*/}
                
                {/* City input end */}
                <div className={`ms-input-div ${!isFirstTime.third ? '' : 'overlay-blur'} ${popupFlowStep == 4 ? 'active' : ''}`}>
                 {/* <p className="ms-rad-title">Select Plant Admin:</p>*/}
                  <div className="label">
                     {/*<label className="ms-rad">*/}
                      <input type="hidden" name="formPlantAdmin" value="yourself" 
                        onFocus={
                          () => setPopupFlowSetup(4)
                        }
                      />
                     {/* <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx={8} cy={8} r="5.5" />
                      </svg>
                      <span>
                        Yourself
                      </span>
                    </label>
                    <label className="ms-rad">
                      <input type="radio" name="formPlantAdmin" onChange={(e) => { HandleForm('', 'formPlantAdmin'); setOtherAdmin(true); }}
                        onFocus={
                          () => setPopupFlowSetup(4)
                        }
                      />
                      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx={8} cy={8} r="5.5" />
                      </svg>
                      <span>
                        Other
                      </span>
                    </label>
                    <div className="ms-tooltip">
                      <p className="center-p">
                        Assign the Plant Admin, the person responsible to lead the project.
                      </p>
                      <div className="divider-yellow"></div>
                      <div className="action-p">
                        <p className="div-img">
                          <img src={require('../../assets/img/maturity-dashboard/report-problem.svg').default} alt="true" />
                        </p>
                        <span>
                          Note: Plant Admins can edit and set-up Production Lines. Selecting another user, will remove edit rights from your account.
                        </span>
                      </div>
                      </div>*/}
                  </div>
                 {/* <Collapse in={otherAdmin} timeout={80}>
                    <label className="label ms-mt-14">
                      <input type="text" className="input-field" placeholder="Type their email address here" value={addFormData.formPlantAdmin} onChange={(e) => { HandleForm(e.target.value, 'formPlantAdmin'); }}
                        onFocus={
                          () => setPopupFlowSetup(4)
                        }
                      />
                    </label>
                  </Collapse>
                  <label id="adding-plant-error" className={`${(errors.indexOf("formPlantAdmin") > -1) ? 'error' : ""}`}>{(errors.indexOf("formPlantAdmin") > -1) ? "Plant Admin is required" : null}</label>
                      */}
                </div>
                {/* Plant Admin end */}
                <div id="add-plan-btn-parent" className={`ms-footer-btn-wrapper ${!isFirstTime.third ? '' : 'overlay-blur'} ${popupFlowStep == 6 ? 'active' : ''}`}  >
                  <button className="ms-add-btn"  disabled={!addFormData.formPlantName} id="addplantbtn" onClick={AddPlant} >
                    + Add Plant
                  </button>
                  <div className="ms-tooltip">
                    <p className="center-p">
                      Select Add Plant to create your plant.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* ms-popup-content end */}
          </div>
        </div>
        {/* "ms-plant-setup-popup end */}
      </Modal>

    </>
  )
}
// MaturityPlantSetupPopup end
//MaturitySubmitDataPopup component start
export const MaturitySubmitDataPopup = (props) => {
  //defining variables here
  const [isSubmitLoadding, setIsSubmitLoadding] = useState(false);
  //function to handle close
  const handleClose = () => {
    props.setOpen(!props.open);
  }
  //function for submit
  const HandleSubmit = () => {
    setIsSubmitLoadding(true);
    setTimeout(() => {
      setIsSubmitLoadding(false);
      handleClose();
    }, 3000);
  }
  return (
    <>
      <Modal open={props.open}
        // onClose={handleClose}
        className="maturity-admin-popup">
          {/* this section has card */}
        <div className="ms-submit-data-popup">
          {/* card has two sections
              before-submit
              submit-loadding
          */}
          <div className="ms-submit-card">
            {/* this section has
                close button
                ms-submit-header
                ms-submit-content
                ms-submit-footer
            */}
            <div className="before-submit" style={{ visibility: isSubmitLoadding ? 'hidden' : 'visible' }}>
              <button className="close-btn" onClick={handleClose}>
                <img src={require('../../assets/img/maturity-dashboard/blue-close-icon.svg').default} alt="" X />
              </button>
              <div className="ms-submit-header">
                <img className="ms-unsaved" src={require('../../assets/img/maturity-dashboard/ms-unsaved.svg').default} alt="true" />
              </div>
              <div className="ms-submit-content">
                <p>
                  By submitting the data you will no longer have the opportunity for further editing. Please make sure that the data provided are in their most updated form.
                </p>
                <p className="bold-p">Are you sure you want to proceed?</p>
              </div>
              <div className="ms-submit-footer">
                <button className="btn-cancel" onClick={handleClose}>
                  Cancel
                </button>
                <button className="btn-submit" onClick={HandleSubmit}>
                  Submit
                </button>
              </div>
            </div>
            {/* this section has
                image
                text
            */}
            <div className="submit-loadding" style={{ visibility: !isSubmitLoadding ? 'hidden' : 'visible' }}>
              <img src={require('../../assets/img/maturity-dashboard/submit-animation.png').default} alt="loadding..." />
              <p className="loadding-text">
                Submitting and uploading your data...
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
//AssignSectionPopup start
export const AssignSectionPopup = (props) => {
  //defining variables here
  const demoData = [
    {
      id: '1',
      name: 'Anna Smiths',
      circleName: 'as',
      invited: []
    },
    {
      id: '2',
      name: 'Bratt Hemsworth',
      circleName: 'bh',
      invited: []
    },
    {
      id: '3',
      name: 'Catherine Goldsmith',
      circleName: 'cg',
      invited: []
    },
    {
      id: '4',
      name: 'Danielle Stevens ',
      circleName: 'ds',
      invited: []
    },
    {
      id: '5',
      name: 'Ellen Kelley  ',
      circleName: 'ek',
      invited: []
    },
    {
      id: '6',
      name: 'Fabian Erizzo ',
      circleName: 'fe',
      invited: []
    },]
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const [UserListData, setUserListData] = useState([])
  const [invitedMember, setInvitedMember] = useState([]);
  useEffect(() => {
   // loadUserList();
  }, []);
  const loadUserList = async () => {
    const result = await serviceProvider.get(`/plants/UserList?user_id=${usid}`,true,{});
    const data = result.data
    
    const data1 = data[0].uerstatus;
    if(data1!="" && data1!=undefined){

   
    const options = data1.map(d => ({
      "id": d.id,
      "name": d.name + " " + d.last_name,
      "circleName": d.name.charAt(0) + "" + d.last_name.charAt(0),
      "email": d.email,
      "invited": []
    }))

    setUserListData(options);
    setInvitedMember(options);
  }
  };
  //console.log(UserListData);
  const [msAssignSearch, setMsAssignSearch] = useState('');
  const [isInvite, setIsInvite] = useState(false);
  const [multiEmailVal, setMultiEmailVal] = useState([]);
  const [singleEmail, setSingleEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [assignTmpId, setAssignTmpId] = useState(null);
  const [assignEmailTmpId, setAssignEmailTmpId] = useState('');
  //console.log("assignEmailTmpId", assignEmailTmpId)
  const [multiAssign, setMultiAssign] = useState({});
  const [multiEmailAssign, setMultiEmailAssign] = useState({});
  const [tmpItem, setTmpItem] = useState('');
  const [tmpEmailItem, setTmpEmailItem] = useState('');
  const [isUpDatedDesign, setIsUpDatedDesign] = useState(false);
  const [tmp2Id, setTmp2Id] = useState('');
  const HandleSelectedOwners = (e, id) => {

    e.stopPropagation();
    let tmpArr = [...selectedOwners];
    let currentItem = invitedMember.filter((item, index) => {
      return item.id === id;
    });
    let currentData = tmpArr.filter((item, index) => {
      return item.id === id;
    });
    // console.log("first", multiAssign);
    if (currentData.length == 0) {
      tmpArr.unshift({ ...currentItem[0], 'invite': [...multiAssign[`cirAssignPopup${id}`]] });
      setSelectedOwners(tmpArr);
    }
  }
  //function to remove owner
  const RemoveOwner = (e, id) => {
    e?.stopPropagation();
    let filterArr = selectedOwners.filter((item, index) => {
      return item.id !== id;
    });
    setSelectedOwners(filterArr);
    setMultiAssign({ ...multiAssign, [`cirAssignPopup${id}`]: [] })
  }
  //function to handle single email value
  const HandleSingleEmailValue = (e) => {
    let tmpValue = e.target.value;
    setSingleEmail(tmpValue);
  };
  //function to add multiple emails
  const AddMultiEmail = (e) => {
    let tmpArr = [...multiEmailVal];
    const emailRegx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

   
    if (e.key === "Enter" && emailRegx.test(String(singleEmail).toLowerCase()) && userExists(tmpArr,singleEmail) === false) {
      const addEmail = singleEmail;

      let tmpObj = {
        id: multiEmailVal.length,
        name: addEmail,
        invited: []
      }
      tmpArr.push(tmpObj);
      setSingleEmail("");
      setMultiEmailVal(tmpArr);
    }
  };
 //function for existing users
 const  userExists = (tmpArr,name) =>{
    return tmpArr.some(function(el) {
      return el.name === name;
    }); 
  };
  //function to remove email
  const RemoveEmail = (e, id) => {
   
    e.stopPropagation();
    let filterEmail = multiEmailVal.filter((item, index) => {
      return item.id !== id;
    }); 
   
    setMultiEmailVal(filterEmail);
  };
  //function to handle close
  const handleClose = () => {
    props.setOpen(!props.open);
    setIsInvite(false);
    setIsUpDatedDesign(false);
  }
  //function to handle value
  const HandleValue = (e) => {
    let val = e.target.value;
    setMsAssignSearch(val);
    let filterArr = invitedMember.filter((item, index) => {
      return item.name.toLowerCase().startsWith(val.toLowerCase());
    });
    if (val.length > 0) {
      setInvitedMember(filterArr);
    } else {
      setInvitedMember(UserListData);
    }
  }
  //function to handle invite
  const HandleInvite = (e) => {
   
    if (multiEmailVal != '') {
     
        
     
      setIsEmailSent(true);
      setTimeout(() => {
        setIsEmailSent(false);
        handleClose();
      }, 2000)

 if(props.assessmentPopup == "MaturityPopup"){
  const sectionName = "maturityAnalysis";
      serviceProvider.post("/plants?type=send_team_invite", JSON.stringify({ email: multiEmailVal, uid: usid, role: 1, plants: plantid,sectionName:sectionName, emailby:"multival" }),true).then((response) => {
       
      });
    }
    else{
     
      const sectionName = "lossAnalysis";
      serviceProvider.post("/plants?type=send_team_invite", JSON.stringify({ email: multiEmailVal, uid: usid, role: 1, plants: plantid,sectionName:sectionName, emailby:"multival" }),true).then((response) => {
       
      });
    }
    }
  }
  // to open multi assign popup
  const HandleMultiAssignPopup = (id) => {
    setAssignTmpId(id);
  }
  const HandleMultiEmailAssignPopup = (email) => {
   
    setAssignEmailTmpId(email);
  }
  // function to set multiassign ddl box 
  const HandleMultiAssign = (name) => {
    let tmpTargetInput = document.getElementsByName(name);
    let tmpArr = [];
    tmpTargetInput.forEach((item, index) => {
      if (item.checked === true) {
        tmpArr.push(item.value);
      }
    });

    setMultiAssign((prev) => {
      const prvObj = { ...prev, [name]: tmpArr }
      return prvObj;
    });
  }
  const HandleMultiEmailAssign = (name) => {
    let tmpTargetInput = document.getElementsByName(name);
    let tmpArr = [];
    tmpTargetInput.forEach((item, index) => {
     
      if (item.checked === true) {

        tmpArr.push(item.value);
      }
    });
    setMultiEmailAssign((prev) => {
      const prvObj = { ...prev, [name]: tmpArr }
      return prvObj;
    });
  }
  //function to assign on click
  const HandleAssignOnClick = (e) => {
    
        
     if(selectedOwners.length>0){
      setIsEmailSent(true);
      setTimeout(() => {
        setIsEmailSent(false);
        handleClose();
      }, 2000)

 if(props.assessmentPopup == "MaturityPopup"){
  const sectionName = "maturityAnalysis";
      serviceProvider.post("/plants?type=send_team_assing", JSON.stringify({ email: selectedOwners, uid: usid, role: 1, plants: plantid,sectionName:sectionName, emailby:"selowner" })).then((response) => {
       
      });
    }
    else{
     
      const sectionName = "lossAnalysis";
      serviceProvider.post("/plants?type=send_team_assing", JSON.stringify({ email: selectedOwners, uid: usid, role: 1, plants: plantid,sectionName:sectionName, emailby:"selowner" }),true).then((response) => {
       
      });
    }
  }
  }
  const HandleOnClickMultiAssign = (e, id, varname) => {
  //  console.log(multiAssign[varname]?.length)
   
    if (multiAssign[varname]?.length) {
      HandleSelectedOwners(e, id);
    }
    setAssignTmpId(null);
  }
  //function to handle mutliple assign emails on click
  const HandleOnClickMultiEmailAssign = (e, id, varname) => {

    // console.log(varname);

    let currentData = multiEmailVal.filter((item) => item.id == id);
    
    let tmpObj = { ...currentData[0], 'invited': multiEmailAssign[varname] };
    let tmpArr = [...multiEmailVal];
    tmpArr[id] = tmpObj;
    // console.log('type2 arry', tmpArr);
    setMultiEmailVal(tmpArr);
    setAssignEmailTmpId(null);
  }
  const value= props.assessmentPopup;
  // console.log("assessmentPopup",props.assessmentPopup)
  const [AssessmentData, setAssessmentData] = useState([])
  useEffect(() => {
    if(value =="MaturityPopup" ){
      //loadAssessmentData();
    }else{
      //loadAssessmentData1();
    }
  }, [value]);

  //function to load assessment data
  const loadAssessmentData = async () => {
    const result = await serviceProvider.get(`/plants/AssessmentData`,true,{});
    const data = result.data;
   
    setAssessmentData(data);
  };
//function to load assessment data1
  const loadAssessmentData1 = async () => {
    const result = await serviceProvider.get(`/plants/AssessmentLossData`,true,{});
    const data = result.data;
    
    setAssessmentData(data);
  };  
const [plantid, setCompanyPlantId] = useState("");
//function to handle next emails
const HandleNextEmails = (e) =>{
  setIsUpDatedDesign(true); 
    setIsInvite(false);	
  if(singleEmail!="" && multiEmailVal.length==0){
  let tmpArr = [...multiEmailVal];
  const emailRegx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  if (emailRegx.test(String(singleEmail).toLowerCase()) && userExists(tmpArr,singleEmail) === false) {
    const addEmail = singleEmail;

    let tmpObj = {
      id: multiEmailVal.length,
      name: addEmail,
      invited: []
    }
    tmpArr.push(tmpObj);
    setSingleEmail("");
    setMultiEmailVal(tmpArr);
    setIsUpDatedDesign(true); 
    setIsInvite(false);	
   
  }
  
  }

 
 

}
 

  
  return (
    <Modal open={props.open}
      // onClose={handleClose}
      className="maturity-admin-popup">
        {/* popup has card */}
      <div className="ms-assign-section-popup">
        {/* card has 
            ms-assign-header
            ms-assign-content
        */}
        <div className="ms-assign-section-card">
          {!isEmailSent ?
            <>
              {/* header has
                  title
                  close button
              */}
              <div className="ms-assign-header">
                <span>{props.assessmentPopup == "MaturityPopup" ? "Assign team member to Maturity Assessment" : "Assign team member to Loss Analysis"}</span>
                <button className="close-btn" onClick={handleClose}>
                  <img src={require('../../assets/img/maturity-dashboard/cancel-dark.svg').default} alt="X" />
                </button>
              </div>
              {/* ms-assign-header end */}
              {/* this section has 
                  ms-select-owner-div
                  ms-invite-member-div
                  cir-assign-popup
              */}
              <div className="ms-assign-content">
                {/* this section has
                    search-box
                    ms-invite-div
                    ms-assign-scroll-div
                    contact-empty-div
                    error
                 */}
                <div className="ms-select-owner-div" style={{ display: isInvite ? 'none' : '' }}>
                  <div className={`ms-assign-search-box ${msAssignSearch.length > 0 ? 'active' : ''}`}>
                    <label className="ms-assign-search">
                      <img src={require('../../assets/img/maturity-dashboard/ms-assign-search.svg').default} alt="true" />
                      <input type="text" placeholder="Search.." onChange={(e) => HandleValue(e)} value={msAssignSearch} />
                    </label>
                  </div>
                  {/* ms-assign-search-box end */}
                  {!isUpDatedDesign ?
                    <>
                      {/* this section has
                          title
                          button
                      */}
                      <div className="ms-invite-div">
                        <p>Select Owners</p>
                        <button className="btn-invite" onClick={() => { setIsInvite(true) }}>
                          Email Invite
                        </button>
                      </div>
                      {/* this section has texy */}
                      <div className="contact-empty-div" style={{ display: invitedMember.length > 0 ? 'none' : '' }}>
                        <p>Your contact list is empty.</p>
                        <p>Click on <span className="blue-link">Email Invite</span> to assign owners.</p>
                      </div>
                      {/* this section has users */}
                      <div className="ms-assign-scroll-div">
                        <div className="selected-member-ul" style={{ display: selectedOwners.length > 0 ? '' : 'none' }}>
                          {selectedOwners.map((item, index) => <div className="selected-member-li" key={index}>
                            <span>{item.name}</span>
                            <button className="remove-list" onClick={(e) => RemoveOwner(e, item.id)}>
                              <img src={require('../../assets/img/maturity-dashboard/email-list-cancel.svg').default} alt="X" />
                            </button>
                          </div>)}
                        </div>
                        <div className="select-owner-text" style={{ display: selectedOwners.length > 0 ? '' : 'none' }}>
                          Select Owners
                        </div>
                        {/* ms-cir-list-wrapper start */}
                        <div className="ms-cir-list-wrapper">
                          {
                            invitedMember.map((item, index) => <div className={`ms-cir-li ${multiAssign[`cirAssignPopup${item.id}`]?.length > 0 && 'active'}`}
                              onClick={(e) => {
                                HandleMultiAssignPopup(`cirAssignPopup${item.id}`);
                                setTmpItem(item.id);
                              }}
                              key={index} id={`ms2CirLi${index}`}>
                              <div className={`ms-cir-div cir-${item.name[0].toLocaleLowerCase()}`}>{item.circleName}</div>
                              <div className="ms-cir-name">
                                <span>{item.name} </span>
                                {/* new update */}
                                {multiAssign[`cirAssignPopup${item.id}`]?.length > 0 &&
                                  <div className="user-assign-email-ddl">
                                    <div className="button">
                                      {
                                        multiAssign[`cirAssignPopup${item.id}`] ?
                                          multiAssign[`cirAssignPopup${item.id}`].length
                                          : '0'}
                                      <span className="span">Assigned</span>
                                    </div>
                                  </div>}
                                {/* new update end*/}
                              </div>
                              <button className="selected-icon">
                                <img src={require('../../assets/img/maturity-dashboard/cir-select-chk.svg').default} />
                              </button>


                              <button className="remove-selected" onClick={(e) => RemoveOwner(e, item.id)} >
                                <img src={require('../../assets/img/maturity-dashboard/remove-selected.svg').default} />
                              </button>

                            </div>)}
                        </div>
                        {/* ms-cir-list-wrapper ended */}
                      </div>
                    </>
                    :
                    <>
                      <div className="ms-invite-div">
                        <p>Select Owners</p>
                      </div>
                      <div className="ms-assign-scroll-div">
                        <div className="selected-member-ul">
                          {multiEmailVal.map((item, index) =>
                            <div className="selected-member-li" key={index}>
                              <span>{item.name}</span>
                              <button className="remove-list" onClick={(e) => RemoveEmail(e, index)}>
                                <img src={require('../../assets/img/maturity-dashboard/email-list-cancel.svg').default} alt="X" />
                              </button>
                            </div>)}
                        </div>
                        {/* selected-member-ul end */}
                        <div className="ms-cir-list-wrapper new232108">
                          {
                            multiEmailVal.map((item, index) => <div className={`ms-cir-li active`} key={index} onClick={(e) => {
                              // console.log("item list-", item)
                              HandleMultiEmailAssignPopup(`cir2AssignPopup${item.id}`);
                              // setTmpEmailItem(item);
                              setTmp2Id(index);
                              setTmpEmailItem(item.id);

                            }} key={index} id={`msCir2Li${index}`}>
                              <div className="ms-cir-name">
                                <span>{item.name}</span>

                                {/* new update */}
                                <div className="user-assign-email-ddl">
                                  <div className="button">
                                    {item.invited.length > 0 &&
                                      item.invited.length
                                    }
                                    <span className="span">

                                      {
                                        item.invited.length > 0 ?
                                          "Assigned"
                                          :
                                          'Assigned Section'}
                                    </span>
                                  </div>
                                </div>
                                {/* new update end*/}
                              </div>
                              <button className="selected-icon">
                                <img src={require('../../assets/img/maturity-dashboard/cir-select-chk.svg').default} />
                              </button>
                              <button className="remove-selected" onClick={(e) => RemoveEmail(e, index)} >
                                <img src={require('../../assets/img/maturity-dashboard/remove-selected.svg').default} />
                              </button>

                            </div>)}
                        </div>
                        {/* ms-cir-list-wrapper ended */}
                      </div>
                      {/* error meassage wrapper */}
                      <p className="new232108-error">*Assign sections to each owners </p>
                    </>

                  }
                </div>
                {/* ms-select-owner-div end */}
                {/* this section has
                    ms-invite-div
                    email-input-wrapper
                    ms-assign-scroll-div
                */}
                <div className="ms-invite-member-div" style={{ display: !isInvite ? 'none' : '' }}>
                  <div className={`ms-assign-search-box ${msAssignSearch.length > 0 ? 'active' : ''}`}>
                    <label className="ms-assign-search">
                      <img src={require('../../assets/img/maturity-dashboard/ms-assign-search.svg').default} alt="true" />
                      <input type="text" placeholder="Search.." onChange={(e) => HandleValue(e)} value={msAssignSearch} />
                    </label>
                  </div>
                  {/* ms-assign-search-box end */}
                  <div className="ms-invite-div">
                    <p>Email Invite</p>
                  </div>
                  <div className="email-input-wrapper">
                    <label className="label">
                      <span className="input-title">Email</span>
                      <input name="emailid" type="text" value={singleEmail} onChange={(e) => HandleSingleEmailValue(e)} onKeyPress={(e) => AddMultiEmail(e)} className="input-field" placeholder="Type e-mail address" />
                    </label>
                  </div>
                  <div className="ms-assign-scroll-div">
                    <div className="selected-member-ul">
                      {multiEmailVal.map((item, index) =>
                        <div className="selected-member-li" key={index}>
                          <span>{item.name}</span>
                          <button className="remove-list" onClick={(e) => RemoveEmail(e, index)}>
                            <img src={require('../../assets/img/maturity-dashboard/email-list-cancel.svg').default} alt="X" />
                          </button>
                        </div>)}
                    </div>
                    {/* selected-member-ul end */}
                    {/* ms-cir-list-wrapper start */}
                  </div>
                </div>
                {/* ms-select-owner-div end */}

                {assignTmpId &&
                  <div className="cir-assign-popup" id={assignTmpId}>
                    {/* this section has
                        heading
                        list-wrapper
                        assign-btn-wrapper
                    */}
                    <div className="assign-popup-content">
                      <h1>{ props.assessmentPopup == "MaturityPopup" ?"Maturity Assessment Sections":"Loss Assessment Sections"}</h1>
                      <div className="list-wrapper">

                        {AssessmentData.map((ass, i) => (
                          <label>
                            <input value={ass.id} name={assignTmpId} type="checkbox" onChange={() => HandleMultiAssign(assignTmpId)} defaultChecked={multiAssign[assignTmpId]?.indexOf(ass.id) >= 0} />
                            <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y={1} width={11} height={11} rx="1.5" fill="#118ACB" stroke="#118ACB" />
                              <path d="M4.5002 8.59981L2.4002 6.49981L1.7002 7.19981L4.5002 9.99981L10.5002 3.9998L9.8002 3.2998L4.5002 8.59981Z" fill="white" />
                            </svg>
                            <span>
                              {ass.section_name}
                            </span>
                          </label>
                        ))}
                      </div>
                      <div className="assign-btn-wrapper">
                        <button className="ms-assign-btn cancel" onClick={() => setAssignTmpId(null)}>
                          Cancel
                        </button>
                        <button className="ms-assign-btn blue" onClick={(e) => {
                          HandleOnClickMultiAssign(e, tmpItem, assignTmpId);
                        }}>
                          Assign
                        </button>
                      </div>
                    </div>
                  </div>}

                {assignEmailTmpId &&

                  <div className="cir-assign-popup" id={assignEmailTmpId}>
                    <div className="assign-popup-content">
                      <h1>{ props.assessmentPopup == "MaturityPopup" ?"Maturity Assessment Sections":"Loss Assessment Sections"}</h1>
                      <div className="list-wrapper">
                      {/* {console.log(multiEmailVal)} */}
                        {AssessmentData.map((ass, i) => (

                          <label>
                            <input value={ass.id} name={assignEmailTmpId} type="checkbox"
                              onChange={() => HandleMultiEmailAssign(assignEmailTmpId)}
                              defaultChecked={multiEmailVal[tmp2Id].invited?.indexOf(ass.id) >= 0}
                            />
                            <svg width={12} height={13} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.5" y={1} width={11} height={11} rx="1.5" fill="#118ACB" stroke="#118ACB" />
                              <path d="M4.5002 8.59981L2.4002 6.49981L1.7002 7.19981L4.5002 9.99981L10.5002 3.9998L9.8002 3.2998L4.5002 8.59981Z" fill="white" />
                            </svg>
                            <span>
                              {ass.section_name}
                            </span>
                          </label>
                        ))}
                      </div>
                      <div className="assign-btn-wrapper">
                        <button className="ms-assign-btn cancel" onClick={() => setAssignEmailTmpId(null)}>
                          Cancel
                        </button>
                        <button className="ms-assign-btn blue" onClick={(e) => {
                          HandleOnClickMultiEmailAssign(e, tmpEmailItem, assignEmailTmpId);
                        }}>
                          Assign
                        </button>
                      </div>
                    </div>
                  </div>}

                {/* cir-assign-popup end */}
              </div>
              {/* ms-assign-content end */}
              {/* footer has buttons which will come conditionally */}
              <div className="ms-assign-footer">
                {!isInvite ?
                  <>
                    <button className="ms-assign-btn cancel" onClick={handleClose}>
                      Cancel
                    </button>
                    {!isUpDatedDesign ?
                      <button className="ms-assign-btn blue" onClick={(e) => { HandleAssignOnClick(e) }}>
                        Assign
                      </button>
                      :
                      <button className="ms-assign-btn blue btn-disabled" onClick={(e) => HandleInvite(e)}>
                        Invite
                      </button>
                    }
                  </>
                  :
                  <>
                    <button className="ms-assign-btn cancel" onClick={() => setIsInvite(false)}>
                      Back
                    </button>
                    <button className="ms-assign-btn blue" onClick={(e) => HandleNextEmails(e) }>
                      Next
                    </button>
                  </>
                }

              </div>
            </>
            :
            // invite successful section
            <div className="invite-successful">
              <img src={require('../../assets/img/maturity-dashboard/done-check.png').default} alt="invite successfully" />
              <p>
                Email invite successfully sent!
              </p>
            </div>
          }
        </div>
      </div>
    </Modal>
  )
}


export const MaturityAddUserPopup = (props) => {
  //function to handle close
  const handleClose = () => {
    props.setOpen(false);
  }
  //function to handle other function
  const handleOtherFunction = () => {
    handleClose();
    props.setAddPlantPopup(true);
  }
  /*code to skip user plant popup*/ 
  useEffect(()=>{
    if(props.open){
      handleOtherFunction();
    }
  },[props.open])
  
  return (
    <>
      <Modal open={props.open}
        // onClose={handleClose}
        className="maturity-admin-popup">
          {/* this section has card */}
        <div className="ms-add-user-popup">
          {/* popup has
              ms-popup-header
              ms-popup-content
          */}
          <div className="ms-add-user-card">
            {/* header has
                h1
                close-btn
            */}
            <div className="ms-popup-header">
              <h2 className="h1">
                Add Users
              </h2>
              <button className="close-btn" onClick={handleClose}>
                <img src={require('../../assets/img/maturity-dashboard/cancel-dark.svg').default} alt="X" />
              </button>
            </div>
            {/* ms-popup-header end */}
            {/* this section has
                italic-text
                text
                image
            */}
            <div className="ms-popup-content">
              <h2 className="italic-text">Can’t invite users!</h2>
              <p className="p-width-span">
                Please <span>Setup Plant</span> in order to proceed with inviting users.
              </p>
              <div className="img-with-btn">
                <img src={require('../../assets/img/maturity-dashboard-adm/add-plant-popup.svg').default} alt="true" />
                <button className="btn-setup-plant" onClick={handleOtherFunction}>
                  Setup Plant
                </button>
              </div>
            </div>
            {/* ms-popup-content end */}
          </div>
        </div>
        {/* "ms-plant-setup-popup end */}
      </Modal>

    </>
  )
}


