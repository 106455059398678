/** imported components
 
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Link ===> Component from hook to use as anchor tag
  * .scss ===> styling css file for this page

**/

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './la-ques-new.scss';
import './la-questionnaires.scss';

//LossanalysisQuesView component start
export const LossanalysisQuesView = (props) => {
    const { value } = props;
    // Modal Show
    const [isShown, setIsShown] = useState(false);
    const [isAssignDdl, setIsAssignDdl] = useState(false);
    const [isSubmitPopup, setIsSubmitPopup] = useState(false);
    const [isRemovePopup, setIsRemovePopup] = useState(false);
    const [isRightPopup, setIsRightPopup] = useState(null);
    const [isAddCol, setIsAddCol] = useState(false);
    const [delItem, setDelItem] = useState(null);
    const [APDList, setAPDList] = useState([
        {
            name: `Planned maintenance activities`,
            editable: false
        },
        {
            name: `Product changeovers (brand, product size)`,
            editable: false
        },
        {
            name: `Cleaning, Inspection, Lubrication (CIL)`,
            editable: false
        },
        {
            name: `Machine start up / shutdown`,
            editable: false
        },
        {
            name: `Training activities`,
            editable: false
        },
        {
            name: `Operational stoppages`,
            editable: false
        },
        {
            name: `Breaks & meetings`,
            editable: false
        },
        {
            name: `My new subcategory added`,
            editable: false
        },

    ]);
    const [productionLine, setProductionLine] = useState(
        ['Long Production Line 1',
            'Production Line 2',
            'Production Line 3',
            'Production Line 4',
            'Production Line 5',
            'Production Line 6',
        ]
    )
    // scnv-1292
    const [tmpEditData, setTmpEditData] = useState('');
    const [isEditdata, setIsEditdata] = useState(false);
    //Function close Dropdown list
    const closdeAllDdl = () => {
        let dllList = document.querySelectorAll('.del-btn.three-dot-btn');
        dllList.forEach((item, index) => {
            item.classList.remove('active');
        });
    }
    //Function to edit dropdown list
    const handleEditDdl = (e) => {
        closdeAllDdl();
        if (e.currentTarget.parentElement.classList.contains('active')) {
            e.currentTarget.parentElement.classList.remove('active');
        } else {
            e.currentTarget.parentElement.classList.add('active');
        }
    }
    //Function to handle edit data
    const handleEditdata = (data, index) => {
        setIsEditdata(index);
        if(isEditdata>-1){closdeAllDdl();
        let inputAdd = document.querySelector(`#editListInput${index}`);
         inputAdd.focus();
         inputAdd.value = data.name;
        setTmpEditData({
            data,
            index
        });}
    }
    //Function to handle right section
    const HandleRightSec = (val) => {
        if (isRightPopup === val) {
            setIsRightPopup(null);
        } else {
            setIsRightPopup(val);
        }
    }
    //Function to handle delete button
    const HandleDelBtnEvent = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        setDelItem(item);
        setIsRemovePopup(true);
        //scnv-1292
        closdeAllDdl();
        //scnv-1292 end
    }
    useEffect(() => {

        // Fixed Top Js start

        $('.la-scroll-1621').scroll(function (event) {
            var scroll = $('.la-scroll-1621').scrollTop();
            if (scroll > 0) {
                $('.fixed-row-column').addClass('fixed-top');
            } else {
                $('.fixed-row-column').removeClass('fixed-top');
            }
        });

        let scrollDiv = document.querySelector('.la-scroll-1621');
        let scrollLeftDiv = document.querySelector('.la-scroll-left-1621');
        let timeOut = '';
        
        // scrollDiv.onscroll = () => {
        //     clearTimeout(timeOut)
        //     let fixedTopDiv = document.querySelectorAll('.fixed-row-column');
        //     fixedTopDiv[0].classList.add('opacity-0');
        //     fixedTopDiv[1].classList.add('opacity-0');
        //     fixedTopDiv.forEach((item, index) => {
        //         item.style.top = scrollDiv.scrollTop + 'px';
        //     })
        //     timeOut = setTimeout(() => {
        //         fixedTopDiv[0].classList.remove('opacity-0');
        //         fixedTopDiv[1].classList.remove('opacity-0');
        //     }, 66);

        //     scrollLeftDiv.scrollTop = scrollDiv.scrollTop; 
        //     console.log(scrollDiv.scrollTop);

        // }
        // scrollLeftDiv.onscroll = () => {
            
        //     clearTimeout(timeOut)
        //     let fixedTopDiv = document.querySelectorAll('.fixed-row-column');
        //     fixedTopDiv[0].classList.add('opacity-0');
        //     fixedTopDiv[1].classList.add('opacity-0');
        //     fixedTopDiv.forEach((item, index) => {
        //         item.style.top = scrollDiv.scrollTop + 'px';
        //     })
        //     timeOut = setTimeout(() => {
        //         fixedTopDiv[0].classList.remove('opacity-0');
        //         fixedTopDiv[1].classList.remove('opacity-0');
        //     });

        //     scrollDiv.scrollTop = scrollLeftDiv.scrollTop; 

        //     console.log(scrollDiv.scrollTop);


        // }
        // Fixed Top Js end

        document.addEventListener('click', (e) => {
            if (!e.target.closest(".la-assign-cir-wrapper")) {
                setIsAssignDdl(false);
            }
        })


    }, [])
    //Function to display left and right column at same horizontal alignment
    const fixColumn = () => {
        let parentLeft = document.querySelector('.la-bottom-left-col');
        let parentRight = document.querySelector('.la-bottom-right-col');
        let leftColumn = document.querySelectorAll('.la-bottom-left-col .la-common-left-col');
        let rightColumn = document.querySelectorAll('.la-bottom-right-col .la-right-input-row');
        leftColumn.forEach((item, index) => {
            let itemHeight = item.offsetHeight;
            if (rightColumn[index]) {
                rightColumn[index].style.minHeight = itemHeight + "px";
            }
        });
        let fixedLeftColumn = document.querySelectorAll('.la-bottom-left-col .fixed-row-column.left');
        let fixedRightColumn = document.querySelectorAll('.la-bottom-right-col .fixed-row-column.rgt');
        fixedRightColumn.forEach((item, index) => {
            let itemHeight = item.offsetHeight;
            fixedLeftColumn[index].style.minHeight = itemHeight + "px";
            parentLeft.style.paddingTop = itemHeight + "px";
            parentRight.style.paddingTop = itemHeight + "px";
        });
    }
    //Function to scroll page at bottom for add new subcategory
    const scrollBottomAfterAdd = () => {
        const divElement = document.querySelector('.la-ques-wrapper .la-ques-bottom-wrapper');
        let scrollDiv = document.querySelector('.la-scroll-1621');
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
        console.log(scrollDiv.scrollHeight);
    };

    //scnv-1292
    const AddLsit = () => {
        let e = document.querySelector('#addListInput');
        if (e.value.length > 0) {
            let name = e.value;
            let tmpObj = {
                name,
                editable: true
            }
            let tmpArr = [...APDList];
            tmpArr.push(tmpObj);
            setAPDList(tmpArr);
            e.value = '';
            scrollBottomAfterAdd();
            setIsAddCol(false);
        }
    }
    const EditList = () => {
        let e = document.querySelector(`#editListInput${tmpEditData.index}`);
        if (e.value.length > 0) {
            let name = e.value;
            let tmpArr = [...APDList];
            tmpArr[tmpEditData.index] = {
                ...tmpArr[tmpEditData.index],
                name: name
            };
            setAPDList(tmpArr);
            e.value = '';
            setTmpEditData('');
            setIsEditdata(false)
        }
    }
    //scnv-1292 end
    const RemoveList = () => {
        let index = delItem.index;
        let tmpArr = APDList.filter((item, i) => i !== index);
        console.log(tmpArr);
        setAPDList(tmpArr);
    }
    const handleSubmitBtnPos = () => {
        let lftDiv = document.querySelector('.la-bottom-left-col');
        let rgtDiv = document.querySelector('.la-bottom-right-col .la-ques-table-col');
        let whole = document.querySelector('.la-ques-bottom-wrapper');
        let bothSecWidth = lftDiv?.offsetWidth + ((rgtDiv?.offsetWidth + 12) * productionLine.length);
        let btnWrap = document.querySelector('.la-ques-button-group');
        //console.log(lftDiv.offsetWidth + rgtDiv.offsetWidth);
        if (bothSecWidth <= window.outerWidth) {
            btnWrap.style.width = bothSecWidth + 'px';
        }
    }
    useEffect(() => {
        fixColumn();
        window.onresize = () => {
            fixColumn();
        }
        handleSubmitBtnPos();
        //scnv-1292
        document.onclick = (e) => {
            if (!e.target.closest('.three-dot-btn')) {
                closdeAllDdl();
            }
        }
        //scnv-1292 end
    }, [APDList])

    return (
        <>
            {/* Main wrapper which is divided into three sections
                la-ques-top
                la-ques-bottom-wrapper
                la-ques-button-group
            */}
            <section className="la-ques-wrapper la-scroll-wrapper">
                <div className="la-ques-top">
                    {/* Top section has three parts
                        la-ques-lft-col
                        la-ques-mid-col
                        la-ques-right-col
                    */}
                    <div className="la-ques-row">
                        {/* This section contains
                            back button
                            page title
                            category title
                        */}
                        <div className="la-ques-lft-col">
                            <h1 className="h1-btn" style={{fontSize:"2.2rem"}}>
                                <Link to="/" className="back-link">
                                    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d)">
                                            <rect x={2} y={2} width={24} height={24} rx={2} fill="white" />
                                        </g>
                                        <path d="M19 7.4303L17.4833 6L9 14L17.4833 22L19 20.5697L12.0334 14L19 7.4303Z" fill="#7D868C" />
                                        <defs>
                                            <filter id="filter0_d" x={0} y={0} width={28} height={28} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset />
                                                <feGaussianBlur stdDeviation={1} />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </Link>
                                <span className="plan-txt"style={{fontSize:"2.2rem"}}>
                                    Planned Downtime
                                </span>
                            </h1>
                            <h4 className="h4-dull">
                                Line Efficiency Losses
                            </h4>
                            <Progressbar value={10} />
                        </div>
                        {/* This section contains assigned user list*/}
                        <div className="la-ques-mid-col">
                            <div className="la-assign-cir-wrapper">
                                <div className="la-assign-cir-group">
                                    <div className="la-assign-cir-wrapper">
                                        <button className="la-assign" onClick={() => setIsAssignDdl(!isAssignDdl)}>
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-questionnaires/assign-icon.svg').default} alt="assign" />
                                            </div>
                                            <span>
                                                Assign
                                            </span>
                                            <div className="la-profile-cir-ul">
                                                <div className="la-profile-cir cir-a">
                                                    <span>Ab</span>
                                                    {/* <img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                </div>
                                                <div className="la-profile-cir cir-c">
                                                    <span>ca</span>
                                                </div>
                                                <div className="la-profile-cir cir-d">
                                                    <span>da</span>
                                                </div>
                                            </div>
                                        </button>
                                        {/* ddl */}
                                        <div className={`assigned-user-popup ${isAssignDdl && 'active'}`}>
                                            <span className="tooltip"></span>
                                            <div className="assignd-user-popup-wrap">
                                                <div className="pop-wrap">
                                                    <div className="img-box">
                                                        <div className="image-div">
                                                            <span className="us-name">AC</span>
                                                        </div>
                                                    </div>
                                                    <div className="user-name">
                                                        <span className="name">Vera Kennedy</span>
                                                        <span className="designation">Plant Engineer</span>
                                                    </div>
                                                </div>
                                                <div className="pop-wrap">
                                                    <div className="img-box">
                                                        <div className="image-div">
                                                            <span className="us-name">AB</span>
                                                        </div>
                                                    </div>
                                                    <div className="user-name">
                                                        <span className="name">Vera Kennedy</span>
                                                        <span className="designation">Plant Engineer</span>
                                                    </div>
                                                </div>
                                                <div className="pop-wrap edit">
                                                    <button className="edit-team">
                                                        <img
                                                            src={
                                                                require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                    .default
                                                            }
                                                            alt="Assign User" className="assign-user"
                                                        />
                                                        <span className="name">Edit Team</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="la-upload">
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-questionnaires/upload-icon.svg').default} alt="upload" />
                                            </div>
                                            <span>
                                                Upload Excel file
                                            </span>
                                        </button>
                                    </div>
                                    <div>
                                        <button className="la-download">
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-questionnaires/download-icon.svg').default} alt="download" />
                                            </div>
                                            <span>
                                                Download to Excel
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                       {/* this section has two elements
                            popup
                            unlock button
                        */}
                        <div className="la-ques-rgt-col">
                            <div className="image-wrapper" onClick={() => setIsShown(!isShown)}>
                                <img src={require('../../assets/img/la-questionnaires/bulb.svg').default} alt="Bulb" className="notification-img" />
                            </div>
                            {isShown && (
                                <div className="la-card-wrapper">
                                    <div className="image-wrap">
                                        <img src={require('../../assets/img/la-questionnaires/bulb.svg').default} alt="Bulb" />
                                    </div>
                                    <div className="content-box">
                                        <p className="content">To provide the average Hours/Week, please consider the hours of planned
                                            downtime as an average of the individual lines grouped for the last full production year.</p>
                                        <button to="/" className="close-btn"><img src={require('../../assets/img/la-questionnaires/close.svg').default} alt="close" onClick={() => setIsShown(false)} /></button>
                                    </div>
                                </div>
                            )}
                            <div className="submitted-data active">
                                <img src={require('../../assets/img/la-questionnaires/done.svg').default}/>
                                Data Submitted
                            </div>
                        </div>
                    </div>
                    {/* la-ques-row */}
                </div>
                 {/* Top section end */}
                <div className="la-ques-bottom-wrapper">
                    <div className="la-ques-bottom">
                         {/* Middle Wrapper divided into two parts 
                             la-bottom-left-col
                             la-bottom-right-col
                        */}
                        <div className="la-ques-bottom-row">
                             {/* This section has been divided into two horizontal parts 
                                fixed-row-column
                                la-common-left-col
                            */}
                            <div className="la-bottom-left-col la-scroll-left-1621">
                                <div className="fixed-row-column left">
                                    {/*--- <div className="average-planned-downtime-card down-time-filled blue"> Use this one for blue color ----*/}
                                    <div className="average-planned-downtime-card">
                                        <div className="card-header">
                                            <h2>Average Planned Downtime</h2>
                                        </div>
                                        <div className="card-footer">
                                            <div className="number-box">
                                                <span className="num-label">Total:</span>
                                                <strong className="number">0</strong>
                                                <span className="text">Avg Hrs/Week</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="side-ques-wrap">
                                        <span className="ques-label">What % of Planned Downtime is on average due to:</span>
                                    </div>
                                </div>
                                {APDList.map((items, i) => {
                                    return (<div className="la-common-left-col fix">
                                        <button className={`side-subcategory ${isRightPopup === (i + 1) && 'active'}`} onClick={() => HandleRightSec(i + 1)}>
                                            <div className="image-box">
                                                <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                </svg>
                                            </div>
                                            {isEditdata !== i &&
                                                <div className="add-subcat-wrap">
                                                    <div className={`input-wrap ${!items.editable && 'w-100'}`}><p className="text" title={items.name}>{items.name}</p></div>
                                                    {items.editable &&
                                                        <div className="del-btn three-dot-btn" onClick={(e) => e.stopPropagation()}>
                                                            <button onClick={(e) => handleEditDdl(e)}>
                                                                <EditCirSVg />
                                                            </button>
                                                            <div className="ed-del-list">
                                                                <button className="inner-edit-btn"
                                                                    onClick={() => handleEditdata(items, i)}>Edit</button>
                                                                <button
                                                                    className="inner-del-btn"
                                                                    onClick={(e) => {
                                                                        HandleDelBtnEvent(e, {
                                                                            name: items.name,
                                                                            index: i
                                                                        });
                                                                    }}>Delete</button>
                                                            </div>
                                                        </div>}
                                                </div>}
                                                <div className="add-subcat-wrap" style={{display:isEditdata===i?'flex':'none'}} onClick={(e)=>e.stopPropagation()}>
                                                    <div className="input-wrap">
                                                        <input placeholder="Other - please specify" name="sub-cat" id={`editListInput${i}`} className="input" /></div>
                                                    <button className="del-btn edit-btn"
                                                        onClick={() => EditList()}>
                                                        <AddSvg />
                                                    </button>
                                                </div>
                                        </button>
                                    </div>)
                                }
                                )}

                                <div className="la-common-left-col" style = {{visibility : isAddCol ? '' : 'hidden'}}>
                                    <div className="side-subcategory">
                                        <div className="image-box">
                                            <img src={require('../../assets/img/la-questionnaires/notification-icon.svg').default} alt="notification" />
                                        </div>
                                        <div className="add-subcat-wrap">
                                            <div className="input-wrap">
                                                <input placeholder="Other - please specify" name="sub-cat" id="addListInput" className="input" /></div>

                                            <button className="del-btn add-btn" onClick={() =>
                                                AddLsit()}>
                                                <AddSvg />
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* This section has been divided into two horizontal parts 
                                fixed-row-column
                                la-ques-table-col
                            */}
                            <div className={`la-bottom-right-col la-scroll-1621 ${isAddCol ? 'scrollBottomAddPadding': ''} `}>
                                <div className="la-ques-table-wrapper">
                                    <div className="fixed-row-column rgt">
                                        <div className="la-ques-table-row first">
                                            {productionLine.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className="table-heading">
                                                            <h3>{item}</h3>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="la-ques-table-row second">
                                            {productionLine.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className="hour-week-wrap wrong-input-val">
                                                            <input className="input selected-input blue" type="text" placeholder="Hrs/Week" />
                                                            <span class="floating-label">Hrs/Week</span>
                                                            <div className="error-mssg">Invalid Data</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="la-ques-table-row third">
                                            {productionLine.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className="svg-wrap">
                                                            <div className="svg-wrapper">
                                                                <SvgCir value={0} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    </div>
                                    {APDList.map((items, i1) => <div className={`la-ques-table-row la-right-input-row fix tr-row${i1}`} key={i1}>
                                        {productionLine.map((item, i2) => {
                                            return (
                                                <div className="la-ques-table-col" key={i2}>
                                                    {/*---------   <div className="la-ques-input-wrap active-blue"> Add this one for active input  ----------*/}
                                                    {/*---------   <div className="la-ques-input-wrap active-green"> Add this one for filled input value  ----------*/}
                                                    <div className="la-ques-input-wrap">
                                                        <input type="text" className="la-ques-input" placeholder="%" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    )}
                                </div>
                                {/* <div class="la-ques-table-row la-right-input-row fix tr-row3" style={{visibility: 'hidden', opacity: 0}}><h1 className="hi">hi</h1></div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Bottom section which contains submit button */}
                <div className="la-ques-button-group" style={{visibility:"hidden"}}>
                    <div className="la-common-left-col">
                        <div className="button-wrap">
                            <button className="btn-link" onClick={() => { setIsAddCol(true); scrollBottomAfterAdd(); }}></button>
                        </div>
                        <div className="button-wrap">
                            <button className="" onClick={() => { setIsSubmitPopup(true) }}></button>
                        </div>
                    </div>
                </div>
                {/* submit popup starts here */}
                <div className={`submit-pop-wrap ${isSubmitPopup && 'active'}`}>
                    <div className="popup-wrapper submit-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn" onClick={() => { setIsSubmitPopup(false) }}>
                                <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-questionnaires/unsaved.svg").default} alt="Unsaved" className="unsave-img" />
                            </div>
                            <div className="content-box">
                                <p className="text">By submitting the data you will no longer have the opportunity for further editing. Please make sure that the data provided are in their most updated form. </p>
                                <p className="text">Are you sure you want to proceed?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn cancel-button primary" onClick={() => { setIsSubmitPopup(false) }}>Cancel</button>
                                <button type="submit" className="btn submit-btn secondary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Delete popup starts here */}
                <div className={`delete-pop-wrap ${isRemovePopup && 'active'}`}>
                    <div className="popup-wrapper del-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn" onClick={() => { setIsRemovePopup(false) }}>
                                <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-questionnaires/pop-del-icon.svg").default} alt="Delete" className="del-img" />
                            </div>
                            <div className="content-box">
                                <p className="subcat-title text">{delItem?.name}</p>
                                <p className="text">Are you sure you want to remove this subcategory?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn keep-button primary" onClick={() => { setIsRemovePopup(false) }}>No, keep it</button>
                                <button type="submit" className="btn remove-btn secondary" onClick={() => { RemoveList(); setIsRemovePopup(false); }}>Yes, remove</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Right Sidebar Popup starts here */}
                <div className={`right-sidebar ${isRightPopup && 'active'}`}>
                    <div className="pop-heading-wrap">
                        <div className="chat-btn">
                            <img src={require("../../assets/img/la-questionnaires/info-icon.svg").default} alt="Info" className="info-img" />
                        </div>
                        <button className="close-btn" onClick={() => HandleRightSec(null)}>
                            <img src={require("../../assets/img/la-questionnaires/close-pop-gray.svg").default} alt="Close" className="close-img" />
                        </button>
                    </div>
                    <div className="popup">
                        <div className="content-box">
                            <h3 className="popup-head">Planned maintenance activities</h3>
                            <p className="text">The % of time a production process or production line is not available for production due to scheduled maintenance activities.</p>
                        </div>
                        <div className="comment-box">
                            <h3 className="cmnt-head">Leave a Comment</h3>
                            <textarea className="text-box">In case needed, please provide additional information about your data input (assumptions, clarifications)</textarea>
                        </div>
                        <div className="button-wrapper">
                            <button type="submit" className="btn submit-btn secondary">Submit</button>
                        </div>
                    </div>
                </div>
            </section>
            {/* la-ques-wrapper end */}
        </>
    )

}
//Function for progressbar
const Progressbar = ({ value }) => {
    return (
        <div className="la-progress-box">
            <p>Progress:</p>
            <div className="bar-wrapper">
                <span>{`${value}%`}</span>
                <div className="bar blue" style={{ width: `${value}%` }}></div>
            </div>
        </div>
    )
}
// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCir = (props) => {
    const { value } = props;
    return (
        <>
            <svg viewBox="0 0 36 36">
                <text
                    className="percent__text"
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontSize="8"
                    fontWeight="normal"
                >

                    <tspan fontSize="10">
                        {value} %
                    </tspan>
                </text>
                <path
                    className="circle-bg"
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    stroke="#e6e6e6"
                    strokeWidth="4"
                    fill="none"
                ></path>
                <path
                    className="circle"
                    strokeDasharray={`${value}, 100`}
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    stroke={
                        value < 100 && value > 0
                            ? "#FF6666"
                            : value === 100
                                ? "#4CAF50"
                                : "transparent"
                    }
                    strokeWidth="4"
                    fill="none"
                ></path>
            </svg>

        </>
    )
}

//Defining svg images
const AddSvg = () => {
    return (
        <svg width="{22}" height="{22}" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9167 6.41634H10.0833V10.083H6.41668V11.9163H10.0833V15.583H11.9167V11.9163H15.5833V10.083H11.9167V6.41634ZM11 1.83301C5.94001 1.83301 1.83334 5.93967 1.83334 10.9997C1.83334 16.0597 5.94001 20.1663 11 20.1663C16.06 20.1663 20.1667 16.0597 20.1667 10.9997C20.1667 5.93967 16.06 1.83301 11 1.83301ZM11 18.333C6.95751 18.333 3.66668 15.0422 3.66668 10.9997C3.66668 6.95717 6.95751 3.66634 11 3.66634C15.0425 3.66634 18.3333 6.95717 18.3333 10.9997C18.3333 15.0422 15.0425 18.333 11 18.333Z" fill="#45B097" />
        </svg>
    )
}

const EditCirSVg = () => {
    return (
        <svg width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.5001" cy="5.36634" r="1.53333" fill="#7D868C" />
            <circle cx="11.4996" cy="11.5001" r="1.53333" fill="#7D868C" />
            <circle cx="11.4996" cy="17.6329" r="1.53333" fill="#7D868C" />
        </svg>
    )
}