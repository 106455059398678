/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api

**/
import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Redirect, Link } from "react-router-dom";
import "./auth.scss";
import { Helmet } from "react-helmet";
import serviceProvider from "../../services/axios";

// class component of Signin
export class Signininvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      msg: "",
      isLoading: false,
      redirect: false,
      errMsgEmail: "",
      errMsgPwd: "",
      errMsg: "",
      passwordShown: false,
    };
  }

  // Funtion to change value onChange event for Email & Password
  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  // Funtion calling for Login button to validate data
  onSignInHandler = () => {
    this.setState({ isLoading: true });
    serviceProvider
      .post(
        "/user?type=signin",
        JSON.stringify({
          email: this.state.email,
          password: this.state.password,
        })
      )
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data.status == 200) {
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem(
            "group_id",
            JSON.stringify(response.data.data.group_id)
          );
          localStorage.setItem(
            "access_token",
            JSON.stringify(response.data.data.access_token)
          );
		  localStorage.setItem(
            "gettimezone",
            JSON.stringify(response.data.data.gettimezone)
          );
          localStorage.setItem(
            "refresh_token",
            JSON.stringify(response.data.data.refresh_token)
          );
		  localStorage.setItem(
            "noti", 
            JSON.stringify(response.data.data.noti)
          );
          let seurlori = window.location.search;
          let seurlresult = seurlori.replace("?", "");
          if (seurlresult != "") {
            let t = window.location.hash;
            seurlresult = seurlresult + t;
            window.location = "/" + seurlresult;
          } else {
            window.location = "/home";
          }
        }
        if (
          response.data.status == "failed" &&
          response.data.success == "undefined"
        ) {
          this.setState({
            errMsgEmail: response.data.validation_error.email,
            errMsgPwd: response.data.validation_error.password,
          });
          setTimeout(() => {
            this.setState({ errMsgEmail: "", errMsgPwd: "" });
          }, 5000);
        } else if (
          response.data.status === "failed" &&
          response.data.success === false
        ) {
          this.setState({
            errMsg: response.data.message,
          });
          setTimeout(() => {
            this.setState({ errMsg: "" });
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Funtion call when press ENTER button from keyboard
  onKeyPress = (e) => {
    if (e.which === 13) {
      this.onSignInHandler();
    }
  };
  
   async check(){
     const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
     const invitation_code = getLastItem(window.location.pathname);
	 if (invitation_code) {
		const apiUrl = `/user?param=getidsignin&invitation_code=${invitation_code}`;
        const { data } = await serviceProvider.get(apiUrl);
		this.setState({email : data});
		
	 }
  }
  
  componentDidMount() {
   this.check();
  }
 
  
  render() {
    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
	
    const login = localStorage.getItem("isLoggedIn");
    if (login) {
      let seurlori = window.location.search;
      let seurlresult = seurlori.replace("?", "");
      if (seurlresult != "") {
        let t = window.location.hash;
        seurlresult = seurlresult + t;
        return <Redirect to={`/${seurlresult}`} />;
      } else {
        return <Redirect to="/home" />;
      }
    }else{
		
		}
    const isLoading = this.state.isLoading;
	
	
	
	
	

    return (
      <React.Fragment>
        <Helmet>
          <title>Sign In | SmarterChains</title>
          <meta name="description" content="Sign In" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {/* 
          Main container. Here we placed Sign in form with logo.
          Devide container in 2 parts left & right section.
          Logo & Hero are in left side part section.
          Sign in form fields are in right side section.
        */}
        <div className="auth__form--wrapp">
          {/* Logo + hero image */}
          <Grid item xs={12} sm={4} md={4} className="auth__form--left">
            <div className="auth__form--logo">
              <img src={require("../../assets/img/auth/sc-logo.svg").default} />
            </div>
          </Grid>
          {/* Sign in form  */}
          <Grid item xs={12} sm={12} md={8} className="auth__form--right">
            <section>
              <div className="auth__form-sm--logo">
                <img
                  src={require("../../assets/img/auth/sc-logo.svg").default}
                />
              </div>
              <div className="wrapper sign-in">
                <div className="col-6 Signup-form">
                  <h1>Welcome back</h1>
                  <p>
                    Log in with the data you entered during your registration.
                  </p>
                  <form className="signup-box">
                    <div className="form-group">
                      <div className="col-12">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          value={this.state.email}
                          onChange={this.onChangehandler}
                          onKeyPress={this.onKeyPress}
                        />
                        <label className="error">{this.state.errMsgEmail}</label>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-12">
                        <label>Password</label>
                        <input
                          className="password-p"
                          type={!this.state.passwordShown ? "password" : "text"}
                          name="password"
                          placeholder="********"
                          value={this.state.password}
                          onChange={this.onChangehandler}
                          onKeyPress={this.onKeyPress}
                        />
                        <span
                          onClick={() =>
                            this.setState({
                              passwordShown: !this.state.passwordShown,
                            })
                          }
                          className={`form-control ${
                            this.state.passwordShown
                              ? "fa fa-fw field-icon toggle-password fa-eye"
                              : "fa fa-fw field-icon toggle-password fa-eye-slash"
                          }`}
                        />
                        <label className="error">{this.state.errMsgPwd}</label>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="signup"
                      color="success"
                      onClick={this.onSignInHandler}
                    >
                      Login
                    </button>
                    <span>
                      Don't have an account?
                      <Link className="log_in_b" to="/sign-up">
                        Sign up
                      </Link>
                      <br />
                      <Link className="log_in_b" to="/forgot-password">
                        Forgot password?
                      </Link>
                    </span>
                    <p className="terms-privacy-text">
                      By clicking on Login, you agree to SmarterChains’
                      <a
                        href="//www.smarterchains.com/terms-of-service"
                        className="services"
                        target="_blank"
                      >
                        Terms of service,
                      </a>
                      <a
                        href="//www.smarterchains.com/website-terms-of-use"
                        className="terms"
                        target="_blank"
                      >
                        Terms of use
                      </a>
                      and
                      <a
                        href="//www.smarterchains.com/privacy-policy"
                        className="privacy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </form>
                </div>
              </div>
            </section>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
