/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api 

**/

import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Redirect, Link } from "react-router-dom";
import "./auth.scss";
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios'

export class Forgotpassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			msg: "",
			isLoading: false,
			redirect: false,
			isUpdate: false,
			errMsgEmail: "",
			errMsg: "",
		};
	}

	// function to change value onChange event 
	onChangehandler = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		let data = {};
		data[name] = value;
		this.setState(data);
	};

	// function to Signin form
	onSignInHandler = () => {
		this.setState({ isLoading: true });
		serviceProvider
			.post(
				"/user?type=forgotpassword",
				JSON.stringify({
					email: this.state.email,
				})
			)
			.then((response) => {
				this.setState({ isLoading: false });
				if (response.data.status == 200) {
					// localStorage.setItem("isLoggedIn", false);
					// this.setState({
					//msg: response.data.message,
					//redirect: true,
					// });
					this.setState({ isUpdate: true });
				}
				if (response.data.status == "failed" && response.data.success == "undefined") {
					this.setState({
						errMsgEmail: response.data.validation_error.email,
					});
					setTimeout(() => {
						this.setState({ errMsgEmail: "" });
					}, 5000);
				} else if (response.data.status === "failed" && response.data.success === false) {
					this.setState({
						errMsg: response.data.message,
					});
					setTimeout(() => {
						this.setState({ errMsg: "" });
					}, 2000);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	render() {
		if (this.state.redirect) {
			return <Redirect to="/home" />;
		}
		const login = localStorage.getItem("isLoggedIn");
		if (login) {
			return <Redirect to="/home" />;
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>Forgot Password | SmarterChains</title>
					<meta name="description" content="Forgot Password" />
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				<div className="auth__form--wrapp">
					{/*started left section image + logo */}
					<Grid item xs={12} sm={4} md={4} className="auth__form--left">
						<div className="auth__form--logo">
							<img src={require("../../assets/img/auth/sc-logo.svg").default} />
						</div>
					</Grid>
					{/*ended left section image + logo */}

					{/*started right section and form layout */}
					<Grid item xs={12} sm={8} md={8} className="auth__form--right">
						<section>
							<div className="auth__form-sm--logo">
								<img
								src={require("../../assets/img/auth/sc-logo.svg").default}
								/>
							</div>
							<div className="wrapper sign-in">
								{/* forgot password form div started*/}
								<div
									className="col-6 Signup-form forgot__pass forgot__pass__form"
									style={{ display: this.state.isUpdate ? "none" : "block" }}
								>
									<Link className="back" to="/sign-in">
										<img src={require("../../assets/img/auth/back.svg").default} />
										Back
									</Link>

									<h1>Reset Password</h1>
									<p>
										Enter the email associated with your account and we'll send you an email with instructions to reset your password.
									</p>
									{/* signup form started */}
									<form className="signup-box">
										<div className="form-group">
											<div className="col-12">
												<label>Email</label>
												<input
													type="email"
													name="email"
													placeholder="Email Address"
													value={this.state.email}
													onChange={this.onChangehandler}
												/>
												<label className="error">{this.state.errMsgEmail}</label>
											</div>
										</div>
										<button type="button" className="signup" color="success" onClick={this.onSignInHandler}>
											Send Instructions
										</button>
									</form>
								</div>
								{/* forgot password form div ended */}

								{/* Start email submission msg */}
								
								{/* Check your email text div  started*/}
								<div
									className="col-6 Signup-form forgot__pass forgot__pass__msg"
									style={{ display: this.state.isUpdate ? "block" : "none" }}
								>
									<div className="message-sent">
										<figure>
											<img src={require("../../assets/img/auth/sent-email.svg").default} />
										</figure>
										<h2>Check your email</h2>
										<p>
											We have sent you an email with instructions to
											<br /> recover your password.
										</p>
									</div>
								</div>
								{/* Check your email text div  ended */}
							</div>
						</section>
						{/*sign in main wrapper ended*/}
					</Grid>
					{/*ended right section and form layout */}
				</div>
			</React.Fragment>
		);
	}
}
