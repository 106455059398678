import React, { useState, useEffect } from 'react';
import { Collapse } from '@material-ui/core';
import {
    MainWrapper,
    HowToUseBtn,
    HowToUsePopWrapper,
    HowToUsePopWrap,
    HowToUsePopHeader,
    HowCloseBtn,
    HowToUsePopBody,
    TabContentWrap,
    TabLeftSection,
    TabRightSection,
    TabBtn,
    TabRightSectionWrap,
    TabImgWrap,
    TabTxtWrap,
    ZoomImageContainer,
    TabHeadWrap,
    RoadCatTab,
    RoadTab,
    RoadMethodTab,
    BtnWrap,
    TabContentWrapper,
} from './styles';
import {
    closeIcon, zoomSvg, howIcon,  materialSvg, minorSvg, operatorSvg,  speedSvg, unplannedSvg,plantAsumptionSvg,businessAsumptionSvg,prevBtn,nextBtn,
    tobaccoOneSvg,tobaccoTwoSvg,tobaccoThreeSvg,tobaccoFourSvg,packagedOneSvg,packagedTwoSvg,packagedThreeSvg,packagedFourSvg,homeOneSvg,homeTwoSvg,homeThreeSvg,
    homeFourSvg,iceOneSvg,iceTwoSvg,iceThreeSvg,iceFourSvg,plantOneSvg,plantTwoSvg,plantThreeSvg,plantFourSvg,customerFourSvg,customerOneSvg,customerTwoSvg,customerThreeSvg,
} from './svg';

export const HowToUseView = (props) => {
    const { sectionName } = props;
    const data = [
        {
            id: 1,
            title: `TOBACCO PLANT`,
            fte: `1107` ,
            sku: `521`,
            production_volume: `66.8 Bn Sticks` ,
            production_cost: `£ 675Mn`,
            digital_maturity: `47%`,
            products: `FMC & NGP`,
            slider: [{
                slide_id: 1,
                slide_img: tobaccoOneSvg,
                slide_title: `IR4.0 Roadmap Solutions and Timeline`,
                slide_text: `This is the final roadmap solutions output and indicative timeline implementation`,
            },
            {
                slide_id: 2,
                slide_img: tobaccoTwoSvg,
                slide_title: `IR4.0 Savings from the roadmap implementation`,
                slide_text: `This is the projected savings ( recoverables) on each key operational area and activity in the plant`,
            },
            {
                slide_id: 3,
                slide_img: tobaccoThreeSvg,
                slide_title: `IR4.0 Investments required to implement the defined roadmap`,
                slide_text: ` This is the various roadmap investments ( ongoing & starting opex , capex) required`,
            },
            {
                slide_id: 4,
                slide_img: tobaccoFourSvg,
                slide_title: `IR4.0 Cost - benefit & payout for the roadmap Implementation`,
                slide_text: `Cashflow projection , cost -benefit & NPG analysis of the total roadmap and each step`,
            },
            ],
        },
        {
            id: 2,
            title: `ICE CREAM PLANT`,
            fte: `625` ,
            sku: `995`,
            production_volume: `18.9 Mn Cases` ,
            production_cost: `$ 76.8Mn`,
            digital_maturity: `35%`,
            products: `FMC & NGP`,
            slider: [{
                slide_id: 1,
                slide_img: iceOneSvg,
                slide_title: `IR4.0 Roadmap Solutions and Timeline`,
                slide_text: `This is the final roadmap solutions output and indicative timeline implementation`,
            },
            {
                slide_id: 2,
                slide_img: iceTwoSvg,
                slide_title: `IR4.0 Savings from the roadmap implementation`,
                slide_text: `This is the projected savings ( recoverables) on each key operational area and activity in the plant`,
            },
            {
                slide_id: 3,
                slide_img: iceThreeSvg,
                slide_title: `IR4.0 Investments required to implement the defined roadmap`,
                slide_text: ` This is the various roadmap investments ( ongoing & starting opex , capex) required`,
            },
            {
                slide_id: 4,
                slide_img: iceFourSvg,
                slide_title: `IR4.0 Cost - benefit & payout for the roadmap Implementation`,
                slide_text: `Cashflow projection , cost -benefit & NPG analysis of the total roadmap and each step`,
            },
            ],
        },
        {
            id: 3,
            title: `HOME-CARE PRODUCT PLANT`,
            fte: `348` ,
            sku: `451`,
            production_volume: `22,544 Tonnes` ,
            production_cost: `£ 15Mn`,
            digital_maturity: `26%`,
            products: `CPG Products`,
            slider: [{
                slide_id: 1,
                slide_img: homeOneSvg,
                slide_title: `IR4.0 Roadmap Solutions and Timeline`,
                slide_text: `This is the final roadmap solutions output and indicative timeline implementation`,
            },
            {
                slide_id: 2,
                slide_img: homeTwoSvg,
                slide_title: `IR4.0 Savings from the roadmap implementation`,
                slide_text: `This is the projected savings ( recoverables) on each key operational area and activity in the plant`,
            },
            {
                slide_id: 3,
                slide_img: homeThreeSvg,
                slide_title: `IR4.0 Investments required to implement the defined roadmap`,
                slide_text: ` This is the various roadmap investments ( ongoing & starting opex , capex) required`,
            },
            {
                slide_id: 4,
                slide_img: homeFourSvg,
                slide_title: `IR4.0 Cost - benefit & payout for the roadmap Implementation`,
                slide_text: `Cashflow projection , cost -benefit & NPG analysis of the total roadmap and each step`,
            },
            ],
        },
        {
            id: 4,
            title: `CONSUMER ELECTRONICS PLANT`,
            fte: `1398` ,
            sku: `487`,
            production_volume: `34.6 Billion Sticks` ,
            production_cost: `£ 208Mn`,
            digital_maturity: `55%`,
            products: `Tobacco RRPs`,
            slider: [{
                slide_id: 1,
                slide_img: customerOneSvg,
                slide_title: `IR4.0 Roadmap Solutions and Timeline`,
                slide_text: `This is the final roadmap solutions output and indicative timeline implementation`,
            },
            {
                slide_id: 2,
                slide_img: customerTwoSvg,
                slide_title: `IR4.0 Savings from the roadmap implementation`,
                slide_text: `This is the projected savings ( recoverables) on each key operational area and activity in the plant`,
            },
            {
                slide_id: 3,
                slide_img: customerThreeSvg,
                slide_title: `IR4.0 Investments required to implement the defined roadmap`,
                slide_text: ` This is the various roadmap investments ( ongoing & starting opex , capex) required`,
            },
            {
                slide_id: 4,
                slide_img: customerFourSvg,
                slide_title: `IR4.0 Cost - benefit & payout for the roadmap Implementation`,
                slide_text: `Cashflow projection , cost -benefit & NPG analysis of the total roadmap and each step`,
            },
            ],
        },
        {
            id: 5,
            title: `PACKAGED FOODS PLANT`,
            fte: `534` ,
            sku: `120`,
            production_volume: `185.9 Tonnes` ,
            production_cost: `$ 132Mn`,
            digital_maturity: `22%`,
            products: `Food & Beverage Products`,
            slider: [{
                slide_id: 1,
                slide_img: packagedOneSvg,
                slide_title: `IR4.0 Roadmap Solutions and Timeline`,
                slide_text: `This is the final roadmap solutions output and indicative timeline implementation`,
            },
            {
                slide_id: 2,
                slide_img: packagedTwoSvg,
                slide_title: `IR4.0 Savings from the roadmap implementation`,
                slide_text: `This is the projected savings ( recoverables) on each key operational area and activity in the plant`,
            },
            {
                slide_id: 3,
                slide_img: packagedThreeSvg,
                slide_title: `IR4.0 Investments required to implement the defined roadmap`,
                slide_text: ` This is the various roadmap investments ( ongoing & starting opex , capex) required`,
            },
            {
                slide_id: 4,
                slide_img: packagedFourSvg,
                slide_title: `IR4.0 Cost - benefit & payout for the roadmap Implementation`,
                slide_text: `Cashflow projection , cost -benefit & NPG analysis of the total roadmap and each step`,
            },
            ],
        },
        {
            id: 6,
            title: `BIO-PHARMA PLANT`,
            fte: `439` ,
            sku: `28`,
            production_volume: `169K Kg` ,
            production_cost: `£ 50.5Mn`,
            digital_maturity: `40%`,
            slider: [{
                slide_id: 1,
                slide_img: plantOneSvg,
                slide_title: `IR4.0 Roadmap Solutions and Timeline`,
                slide_text: `This is the final roadmap solutions output and indicative timeline implementation`,
            },
            {
                slide_id: 2,
                slide_img: plantTwoSvg,
                slide_title: `IR4.0 Savings from the roadmap implementation`,
                slide_text: `This is the projected savings ( recoverables) on each key operational area and activity in the plant`,
            },
            {
                slide_id: 3,
                slide_img: plantThreeSvg,
                slide_title: `IR4.0 Investments required to implement the defined roadmap`,
                slide_text: ` This is the various roadmap investments ( ongoing & starting opex , capex) required`,
            },
            {
                slide_id: 4,
                slide_img: plantFourSvg,
                slide_title: `IR4.0 Cost - benefit & payout for the roadmap Implementation`,
                slide_text: `Cashflow projection , cost -benefit & NPG analysis of the total roadmap and each step`,
            },
            ],
        },
        
    ]
    const methodData =[
        {
            id: 1,
            title: `PLANT ASSUMPTIONS`,
            img: plantAsumptionSvg,
        },
        {
            id: 2,
            title: `FINANCIAL / VENDOR ASSUMPTIONS`,
            img: businessAsumptionSvg,
        },
    ]
    const [openPop, setOpenPop] = useState(false);
    const [activeData, setActiveData] = useState({});
    const [RoadMethodData , setRoadMethodData] = (useState(methodData[0]))
    const [zoomImage, setZoomImage] = useState(false);
    const [zoomData,setZoomData] =  useState(0);
    const [isActive, setIsActive] = useState(1);
    const [activeCollapse1,setActiveCollapse1] = useState(false);
    const [activeSlide,setActiveSlide] = useState(0);

    useEffect(() => {
        if (sectionName) {
            let index = data.findIndex(d => d.title.toLowerCase() === sectionName.toLowerCase());
            let leftSec = document.querySelector('[data-component="TabLeftSection"]');
            let tabBtn = document.querySelectorAll('[data-component="TabBtn"]');
            let tmpCollapseId = 0;
            if (index > -1) {
                let topPos = tabBtn[index]?.offsetTop;
                if (leftSec) {
                    leftSec.scrollTop = topPos;
                }
                setActiveData(data[index]);
                setActiveCollapse1(data[index].id);
                setActiveSlide(0);
            }
        } else {
            setActiveData(data[0]);
            setActiveCollapse1(data[0].id);
            setActiveSlide(0);
        }
        
    }, [openPop]);

    const setSliderData = (index)=>{
        setActiveSlide(index);
    }
    const handleRoadTab = (index) => {
        setIsActive(index);
    };
    const handleInsideTab = (item)=>{
        setActiveSlide(0);
        setActiveData(item); 
        setActiveCollapse1(prv=>{let tmpData = prv===item.id?false:item.id; return tmpData});
    }
    return (
        <>
            <MainWrapper data-component="MainWrapper">
                <HowToUseBtn data-component="HowToUseBtn" onClick={() => setOpenPop(true)}>
                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: howIcon }}></i>
                    <span className='how-txt'>How to use</span>
                </HowToUseBtn>
                {openPop ?
                    <HowToUsePopWrapper data-component="HowToUsePopWrapper">
                        
                            <HowToUsePopWrap data-component="HowToUsePopWrap" active={zoomImage===false}>
                                <HowToUsePopHeader data-component="HowToUsePopHeader">
                                    <span className='head-txt'>How to use Roadmap Configurator</span>
                                    <HowCloseBtn data-component="HowCloseBtn" onClick={() => setOpenPop(false)}>
                                        <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                    </HowCloseBtn>
                                </HowToUsePopHeader>
                                <HowToUsePopBody data-component="HowToUsePopBody">
                                    <TabContentWrap data-component="TabContentWrap">
                                        <TabHeadWrap data-component="TabHeadWrap">
                                            <RoadTab
                                                data-component="RoadTab"
                                                className={isActive == 1 ? "active" : ""}
                                                onClick={(e) => handleRoadTab(1)}
                                            >
                                                IR 4.0 Plant Roadmaps
                                            </RoadTab>
                                            <RoadTab
                                                data-component="RoadTab"
                                                className={isActive == 2 ? "active" : ""}
                                                onClick={(e) => handleRoadTab(2)}
                                            >
                                                Business Case Assumptions
                                            </RoadTab>
                                        </TabHeadWrap>
                                        <RoadCatTab data-component="RoadCatTab" open={isActive == 1 ? "open" : ""}>
                                            <TabLeftSection data-component="TabLeftSection">
                                                {data.map((item, index) => (
                                                    <TabBtn
                                                        data-component="TabBtn"
                                                        active={activeData.id === item.id}
                                                        key={index}
                                                        as='div'
                                                    >
                                                        <a onClick={() => handleInsideTab(item)}>{item.title}</a>
                                                        <Collapse in={item.id===activeCollapse1} timeout="auto" unmountOnExit>
                                                            <p class="plant-list-itme">FTEs: {item.fte}</p>
                                                            <p class="plant-list-itme">SKUs: {item.sku}</p>
                                                            <p class="plant-list-itme">Production Volume: {item.production_volume}</p>
                                                            <p class="plant-list-itme">Production Cost: {item.production_cost}</p>
                                                            <p class="plant-list-itme">Digital Maturity: {item.digital_maturity}</p>
                                                            {item.products&&<p class="plant-list-itme">Products: {item.products}</p>}
                                                            <p class="plant-button-wrap">
                                                                <button class="use_roadmap" data-id="1">Use this Roadmap</button>
                                                            </p>
                                                        </Collapse>
                                                    </TabBtn>
                                                ))
                                                }
                                            </TabLeftSection>
                                            <TabRightSection data-component="TabRightSection">
                                            {activeData?.slider?.map((slideItem, slideIndex) => (
                                                <TabRightSectionWrap data-component="TabRightSectionWrap" active={slideIndex===activeSlide}>
                                                    <TabImgWrap data-component="TabImgWrap">
                                                        <div className="Road-svg" dangerouslySetInnerHTML={{ __html: slideItem.slide_img }}></div>
                                                        <button className='zoom-btn' onClick={() => {setZoomImage(true); setZoomData(slideItem.slide_img)}}>
                                                            <i className="icon-svg" dangerouslySetInnerHTML={{ __html: zoomSvg }}></i>
                                                        </button>
                                                    </TabImgWrap>
                                                    
                                                    <TabTxtWrap data-component="TabTextWrap">
                                                        <TabContentWrapper data-component="TabContentWrapper">
                                                            <div className='step-text-wrap'>
                                                                <span className='blue-span'>{slideIndex+1}/{activeData?.slider?.length}</span>
                                                                <p className='blue-text'>{slideItem.slide_title}</p>
                                                            </div>
                                                            <p className='gray-text'>{slideItem.slide_text}</p>
                                                        </TabContentWrapper>
                                                        <BtnWrap data-component="BtnWrap">
                                                           <button className={`next-prev-btn ${slideIndex===0?'disabled':'active'}`} onClick={()=>{setSliderData(slideIndex===0?0:slideIndex-1)}}>
                                                                <i className="icon-svg" dangerouslySetInnerHTML={{ __html: prevBtn }}></i>
                                                           </button>
                                                           <button className={`next-prev-btn ${slideIndex===activeData?.slider?.length-1?'disabled':'active'}`} onClick={()=>{setSliderData(slideIndex===activeData?.slider?.length-1?activeData?.slider?.length-1:slideIndex+1)}}>
                                                                <i className="icon-svg" dangerouslySetInnerHTML={{ __html: nextBtn }}></i>
                                                           </button> 
                                                        </BtnWrap>
                                                    </TabTxtWrap>
                                                </TabRightSectionWrap>
                                            ))}
                                            </TabRightSection>
                                        </RoadCatTab>
                                        <RoadMethodTab data-component="RoadMethodTab" open={isActive == 2 ? "open" : ""}>
                                            <TabLeftSection data-component="TabLeftSection">
                                                {methodData.map((item, index) => (
                                                    <TabBtn
                                                        data-component="TabBtn"
                                                        key={index}
                                                        active={RoadMethodData.id === item.id}
                                                        onClick={() => setRoadMethodData(item) }
                                                    >
                                                       <a>{item.title}</a>
                                                    </TabBtn>
                                                ))
                                                }
                                            </TabLeftSection>
                                            <TabRightSection data-component="TabRightSection">
                                                <TabRightSectionWrap data-component="TabRightSectionWrap" active={true}>
                                                    <TabImgWrap data-component="TabImgWrap">
                                                        <div className="Road-cat-svg" dangerouslySetInnerHTML={{ __html: RoadMethodData.img }}></div>
                                                        <button className='zoom-btn' onClick={() => {setZoomImage(true); setZoomData(RoadMethodData.img)}}>
                                                            <i className="icon-svg" dangerouslySetInnerHTML={{ __html: zoomSvg }}></i>
                                                        </button>
                                                    </TabImgWrap>
                                                    <TabTxtWrap data-component="TextWrap">

                                                    </TabTxtWrap>
                                                </TabRightSectionWrap>
                                            </TabRightSection>
                                        </RoadMethodTab>
                                    </TabContentWrap>
                                </HowToUsePopBody>
                            </HowToUsePopWrap>
                            
                            <ZoomImageContainer data-component="ZoomImageContainer" active={zoomImage}>
                                 <HowCloseBtn data-component="HowCloseBtn" onClick={() => setZoomImage(false)}>
                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                </HowCloseBtn>
                                <div className='zoom-image-wrap' dangerouslySetInnerHTML={{ __html: zoomData}}></div>
                            </ZoomImageContainer>
                        
                    </HowToUsePopWrapper>
                    : ''}
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

