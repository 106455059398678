import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ScenarioDropdown from "./ScenarioDropdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { HowToUseCtrl } from '../roadmap-how-to-use/HowToUseCtrl'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Redirect, Link, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios';

// Images that we have used on page
import {
  filterSvg,
  closeSvg,
  dragIcon,
  addBtn,
  collapseIcon,
  collapseOpenIcon,
  minusIcon,
  digitalSvg,
  vendorCloseSvg,
  accordSvg,
  checkboxSvg,
  radioBtnSvg,
  closeTechSvg,
  blackSvg,
  upgradeLockIcon,
  siriArrowSvg,
  ddlArrow,
  filterCheckboxSvg,
  noDescSvg,
  descPopCloseSvg,
  fileSvg,
  greenCheckSvg,
  dimentionsTabDdlIcon,
  dimentionsSelctedIcon,
} from "./svg-step1";
// Components that we have used in page
import {
  AccordionHeader,
  SaveRoadmapButton,
  HeaderFilterWrapper,
  ButtonWrapper,
  TopSectionBtn,
  FilterWrapper,
  FilterButton,
  FilterBox,
  FilterCloseButton,
  FilterBoxContent,
  FilterBoxContentTitle,
  FilterCheckBoxWrapper,
  FilterBoxLable,
  FilterBoxContentWrap,
  FilterBoxInput,
  FilterButtonWrapper,
  ResetFilterButton,
  ApplyFilterButton,
  MainCardWrapper,
  TabCardWrapper,
  TabContentWraaper,
  NAButton,
  RightAlignTextWrapper,
  RightAlignTextWrap,
  HeadText,
  SubText,
  AddDragBtn,
  CollapseBtn,
  TabPanelContent,
  VendorWrap,
  VendorRow,
  VendorCol,
  VendorCard,
  VendorText,
  InnerTabWrapper,
  InnerTBLeftCol,
  InnerTBRightCol,
  LossTableWrap,
  LossScrollTableWrap,
  VendorClickImg,
  VendorModalWrap,
  VendorModalHeader,
  VendorCloseBtn,
  VendorTabWrap,
  VendorTabs,
  VendorTab,
  VendorTabPanel,
  AccordionTabWrapper,
  AccordImg,
  MaintainWrap,
  MaintainRow,
  LeftMainCol,
  RightMainCol,
  ListInvestWrap,
  ListRow,
  UpfrontListRow,
  ListValWrap,
  UserWrap,
  GoListWrap,
  RightTabCardWrapper,

  TechnologyPopWrapper,
  TechnologyPopWrap,
  TechnologyPopHead,
  TechnologyPopBody,
  TechnologyPopBodyWrap,
  UpgradeLock,
  FilterTabWrap,
  FilterTabContentWrap,
  FilterTab,
  FilterSiriTabContentWrap,
  DimensionTab,
  FilterAllDimentionButton,
  FilterDimensionBox,

  DescriptionTabPanel,
  EmptyDescriptionWrapper,
  EmptyDescriptionCard,
  DescriptionPopupContainer,
  DescriptionPopupCard,
  DescriptionPopupCardHeader,
  DescriptionPopupCloseButton,
  HeaderButtons,
  HeaderH1,
  DescriptionCardPopupBody,
  TextAreaWrapper,
  DescriptionPopupFooter,
  DescriptionPopupBtnWrap,
  VendorPopWrapper,
  VendorPopCard,
  VendorPopBody,
  VendorPopRow,
  VendorPopLeft,
  VendorPopRight,
  LabelWrap,
  AddVendorBtn,
  DimensionTabRow,
  DimensionTabCol1,
  DimensionTabCol2,
  DimensionTabCol3,
  DimensionTabTitle
} from "./Roadmap-style-step1";

import {
  MainWrapper,
  SectionWrapper,
  LeftSection,
  RightSection,
  SectionHeader,
  ContentBody,
  ContentFooter,
  FilterBoxHeader,
  HeaderTitle,
  ScenarioSectionWrapper,
  TabButton,
  TBRow,
  TBLeftCol,
  TBRightCol,
  LossWrap,
  LossCol,
  LossRow,
  ProceedPopWrapper,
  ProceedPopCard,
  PopHead,
  PopFooter,
  QNAWrap,
  QNACard,
  QNABtn,
  DefaultScenarioPop,
  DefaultScenarioPopupWrapper,
  LoaderPopupWrap,
  LoaderPop,

  NewCardWrap,
  ExistingCardWrap,
  LeftTopHeading,
  LeftTopText,
} from "./common-roadmap-style";
export const RoadmapStep1View = (props) => {
  const stepdata = props.stepData;
  const user = JSON.parse(localStorage.getItem("userData"));
  const uid = user ? user.uid : 0;
  const history = useHistory();
  //variable declaration
  const [showFilter, setShowFilter] = useState(false);
  const [showDimensionFilter, setShowDimensionFilter] = useState(false);
  const [checkedFilter, setCheckedFilter] = useState([]);
  const [scenarioList, setScenarioList] = useState(stepdata.getAllScenario);
  const [isMakeDefault, setIsMakeDefault] = useState(false);
  const [rightCards, setRightCards] = useState(stepdata.usernodes);
  // Collapse
  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [qnaOpen, setQnaOpen] = React.useState(false);
  // Collapse Card
  const [lossLeftDataOpen, setLossLeftDataOpen] = React.useState(false);
  const [lossRightDataOpen, setLossRightDataOpen] = React.useState(false);
  const [openUpgradePop, setOpenUpgradePop] = useState(true)
  //vendor tabs
  const [vendorTab, setVendorTab] = useState(1)
  const [isVendorModal, setIsVendorModal] = useState(false);
  const [isVendorRightModal, setIsVendorRightModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isTechModal, setIsTechModal] = useState(false);
  const [infoPopOpen, setInfoPopOpen] = useState(true);
  const [showUpgradePrice, setShowUpgradePrice] = useState(true);
  const [showSmRightSection, setShowSmRightSection] = useState(false);

  // Card array declaration
  const [leftCards, setLeftCards] = useState([]);
  // New Left Card
  // new added
  const [newLeftOpen, setNewLeftOpen] = React.useState(false);
  const [isEmptyDescription, setIsEmptyDescription] = useState(true);
  const [isDescPop, setIsDescPop] = useState(false);
  const [isNoVendor, setIsNoVendor] = useState(true);
  const [isAddVendorPop, setIsAddVendorPop] = useState(false);
  const [fileAttachment, setFileAttachment] = useState({});
  const [isVendorAddBtn, setIsVendorAddBtn] = useState(true);
  const [isVendorDataSavedBtn, setIsVendorDataSavedBtn] = useState(false);
  const [newLeftCards, setNewLeftCards] = useState([
    {
      id: `100`,
      card_title: `Automated Guided Vehicles (AGVs)`,
      status: `HAS`,
      recoverable_value: `0`,
      investment: `18000`,
      card_type: `newLeft`,
    },
  ]);
  // end
  //const [draggingData, setDraggingData] = useState('');
  //filter function
  const [leftValue, setLeftValue] = React.useState(`l0-0`);
  const [rightValue, setRightValue] = React.useState(`r0-0`);
  // Siri Filter Variables
  const [isActive, setIsActive] = useState(2);
  const [isDefaultScenarioPopup, setIsDefaultScenarioPopup] = useState(false);


  const [bands, setBands] = useState(
    [
      {
        id: 1,
        name: `Band 0`,
      },
      {
        id: 2,
        name: `Band 1`,
      },
      {
        id: 3,
        name: `Band 2`,
      },
      {
        id: 4,
        name: `Band 3`,
      },
      {
        id: 5,
        name: `Band 4`,
      },
      {
        id: 6,
        name: `Band 5`,
      },
      {
        id: 0,
        name: `No Bands`,
      },
    ])
  const [siriDimension, setSiriDimension] = useState(stepdata.siriDimension);
  const [dimensionCollapse, setDimensionCollapse] = useState([]);
  const [dimensionFilterCollapse, setDimensionFilterCollapse] = useState([]);
  const [siriDimensionCollapse, setSiriDimensionCollapse] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [siriInputsData, setSiriInputsData] = useState({});
  const [applyFilterData, setApplyFilterData] = useState({});
  const [dimensionCheckedArray, setDimensionCheckedArray] = useState(stepdata?.filter || []);
  const [dimensionCheckedApplyArray, setDimensionCheckedApplyArray] = useState(stepdata?.filter || []);
  const [selectedScenarioByNode, setSelectedScenarioByNode] = useState({});
  const [selectedScenarioButtonData, setSelectedScenarioButtonData] = useState({})
  const makeDefaultRef = useRef();
  const defaultFilterArr = useRef([]);
  const demoDimArry = [
    {
      id: 1,
      name: `Process - Operations - Vertical Integration`,
    },
    {
      id: 2,
      name: `Technology - Automation - Shop Floor`,
    },
    {
      id: 3,
      name: `Technology - Automation - Enterprise`,
    },
  ]
  // Tabs Function
  const handleLeftChange = (event, newValue) => {
    setLeftValue(newValue);
    setIsVendorModal(false);
  };
  const handleRightChange = (event, newValue) => {
    setRightValue(newValue);
    setIsVendorRightModal(false);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>

      </>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  // end
  // Accordion start
  const [expanded, setExpanded] = React.useState(false);
  const HandleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  const Accordion = (props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  );

  const AccordionSummary = (props) => (
    <MuiAccordionSummary expandIcon={''} {...props} />
  );
  const handleUpgradePopupClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
    setShowUpgradePrice(false);
  }
  const AccordionDetails = (MuiAccordionDetails);

  const yesMakeDefaultFunction = () => {
    setIsDefaultScenarioPopup(false);
    setIsLoader(true);
    let tmpArr = scenarioList.map(i => {
      let tmpObj = i
      if (isMakeDefault.id === tmpObj.id) {
        tmpObj.is_default = 1;
      }
      else {
        tmpObj.is_default = false;
      }
      return tmpObj;
    });
    serviceProvider.post("/roadmap?param=makeDefaultScenario", JSON.stringify({ pid: stepdata.plant_id, sid: isMakeDefault.id })).then((response) => {


      window.location.href = '/roadmap/step1/' + stepdata.plant_slug + "/" + isMakeDefault.id;

    });
    setScenarioList(tmpArr);
  }
  const handleSaveRoadmap = () => {
    setIsLoader(`Give us a moment while we set up your IR4.0 strategy tool for your factory`);
    serviceProvider.post("/roadmap?param=saveroadmap", JSON.stringify({ data: rightCards, uid: uid, pid: stepdata.plant_id, slug: stepdata.plant_slug })).then((response) => {

    });
    setTimeout(() => {
      setIsLoader(false);
    }, 3000);
  }
  // end
  // Filter start
  const closeFilter = () => {
    let allCheckBox = document.querySelectorAll('[name="filterCheckBox"]');
    let tmpArr = [];
    //code to check the vlaue of input does exist in the checkedFilter' array and behalf of this we will check and uncheck the input.

    setSiriInputsData(defaultFilterArr.current);
    setShowFilter(false);
  };

  const resetFilter = () => {
    setSiriInputsData(defaultFilterArr.current);
    setDimensionCheckedArray([]);
    setDimensionCheckedApplyArray([]);
    setSiriDimension(stepdata?.siriDimension);
    props.setfilter(0);
    serviceProvider.post("/roadmap?param=addfilter", JSON.stringify({ pid: stepdata.plant_id, sid: stepdata.sid, filter: [] })).then((response) => { });
  };


  const applyFilter = () => {
    setApplyFilterData(siriInputsData);
    setShowFilter(false);
    props.setfilter(Object.values(siriInputsData));
  };

  const handleDimensionCheckBoxApplyFilter = (callApi = true) => {
    let tmpFilterObj = { ...siriInputsData };
    dimensionCheckedArray.forEach(i => {
      let tmpVal = document.querySelector(`[name="dim-band${i}"]:checked`);
      if (tmpVal) {
        tmpFilterObj[i] = tmpVal.value;
      }
    });
    let tmpFilterSiriDimesion = stepdata?.siriDimension?.filter(i => dimensionCheckedArray?.includes(i.id));
    setSiriInputsData(tmpFilterObj);
    if (dimensionCheckedArray?.length) {
      setSiriDimension(tmpFilterSiriDimesion);
      props.setfilter(Object.values(tmpFilterObj));
    } else {
      setSiriDimension(stepdata?.siriDimension);
      props.setfilter(0);
    }
    setDimensionCheckedApplyArray(dimensionCheckedArray);
    setShowDimensionFilter(false);
    if (callApi) {
      serviceProvider.post("/roadmap?param=addfilter", JSON.stringify({ pid: stepdata.plant_id, sid: stepdata.sid, filter: dimensionCheckedArray })).then((response) => { })
    };
  }

  const handleDimensionCheckBox = (e, val) => {
    let tmpTarget = e.target;
    if (val == "all") {
      if (stepdata?.siriDimension?.length === dimensionCheckedArray?.length) {
        setDimensionCheckedArray([]);
      } else {
        let tmpArr = stepdata?.siriDimension?.map(i => i.id);
        setDimensionCheckedArray(tmpArr);
      }
    } else {
      if (dimensionCheckedArray?.includes(val)) {
        let tmpFilterArr = dimensionCheckedArray?.filter(i => i != val);
        setDimensionCheckedArray(tmpFilterArr);
      } else {
        let tmpArr = [...dimensionCheckedArray];
        tmpArr.push(val);
        setDimensionCheckedArray(tmpArr);
      }
    }
  }

  const closeDimensionFilter = () => {
    setDimensionCheckedArray(dimensionCheckedApplyArray);
    setShowDimensionFilter(false);
    //closeFilter();
  };
  // Hnadle Filter Tabs
  const handleFilterTab = (index) => {
    setIsActive(index);
  };

  const handleFilterOnchangeData = (dimension_id, band_id) => {
    let tmpData = { ...siriInputsData };
    tmpData[dimension_id] = band_id;
    setSiriInputsData(tmpData);
  }
  // Function to handle siri tab wrapper
  const getNextBandsAccordingToSelectedBands = (id, array = [...bands]) => {
    let tmpIndex = array?.findIndex(i => i.id === id);
    if (tmpIndex > -1) {
      let tmpFilterArray = array?.filter((item, index) => index > tmpIndex);
      return tmpFilterArray;
    }
    else {
      return array;
    }
  }
  // Function to handle dimension collapse
  const handleDimensionCollapse = (id) => {

    if (dimensionCollapse?.includes(id)) {
      let tmpFilter = dimensionCollapse?.filter(i => i != id);
      setDimensionCollapse(tmpFilter);
    }
    else {
      let tmpArr = [...dimensionCollapse];
      tmpArr.push(id);
      setDimensionCollapse(tmpArr);
    }
  }
  const handleDimensionFilterCollapse = (id) => {

    if (dimensionFilterCollapse?.includes(id)) {
      let tmpFilter = dimensionFilterCollapse?.filter(i => i != id);
      setDimensionFilterCollapse(tmpFilter);
    }

    else {
      let tmpArr = [...dimensionFilterCollapse]
      tmpArr.push(id);
      setDimensionFilterCollapse(tmpArr);
    }
  }

  const handleSiriDimensionCollapse = (id) => {
    if (siriDimensionCollapse?.includes(id)) {
      let tmpFilter = siriDimensionCollapse?.filter(i => i != id);
      setSiriDimensionCollapse(tmpFilter);
    }
    else {
      let tmpArr = [...siriDimensionCollapse];
      tmpArr.push(id);
      setSiriDimensionCollapse(tmpArr);
    }
  }

  // drag and drop function
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    // If dropped outside the droppable area, do nothing
    if (!destination) return;
    // If dropped in the right area, add the card and disable the left card
    if (destination.droppableId === "right" && source.droppableId != "right") {
      let tmpCard = leftCards[source.index];
      if (source.droppableId === `newNode`) {
        tmpCard = newLeftCards[source.index];
        setNewLeftCards((newLeftCards) =>
          newLeftCards.map((card, index) =>
            index === source.index ? { ...card, disabled: true } : card
          )
        );
        newLeftCards.forEach((card, index) => {
          if (card.id === tmpCard.id) {
            setNewLeftOpen(false);
          }
        }
        )
      }
      else {
        setRightCards((rightCards) => [
          ...rightCards,
          { ...tmpCard },
        ]);
        setLeftCards((leftCards) =>
          leftCards.map((card, index) =>
            index === source.index ? { ...card, disabled: true } : card
          )
        );
        leftCards.forEach((card, index) => {
          if (card.id === tmpCard.id) {
            setLeftOpen(false);
          }
        }
        )
      }
    }
    if (destination.droppableId === "right" && source.droppableId == "right") {
      let tmpCards = [...rightCards];
      // let tmpDestinationElement = tmpCards[destination.index];
      let tmpSourceElement = tmpCards[source.index];
      let tmpFilterCards = tmpCards.filter(i => i.id != tmpSourceElement.id);

      tmpFilterCards.splice(destination.index, 0, tmpSourceElement);
      setRightCards(tmpFilterCards);

      rightCards.forEach((card, index) => {
        if (card.id === tmpSourceElement.id) {
          setRightOpen(false);
        }
      }
      )
    }

  };
  // Function to add cards on right side
  const addRightCard = (index, e, type) => {
    if (type === `newLeft`) {
      setRightCards((rightCards) => [
        ...rightCards,
        { ...newLeftCards[index] },
      ]);
      setNewLeftCards((newLeftCards) =>
        newLeftCards.map((card, i) =>
          i === index ? { ...card, disabled: true } : card
        )
      );
      newLeftCards.forEach((card, i) => {
        if (i === index) {
          setNewLeftOpen(false);
        }
      }
      )
    }
    else {
      setRightCards((rightCards) => [
        ...rightCards,
        { ...leftCards[index] },
      ]);
      setLeftCards((leftCards) =>
        leftCards.map((card, i) =>
          i === index ? { ...card, disabled: true } : card
        )
      );
      leftCards.forEach((card, i) => {
        if (i === index) {
          setLeftOpen(false);
        }
      }
      )
    }

    e.stopPropagation();
    e.preventDefault();
  }

  const saveRoadmap = () => {
    serviceProvider.post("/roadmap?param=saveroadmap", JSON.stringify({ data: rightCards, uid: uid, pid: stepdata.plant_id, slug: stepdata.plant_slug })).then((response) => {

      window.location.href = '/roadmap/final-step/' + stepdata.plant_slug + "/" + response.data;

    });
    setOpenPopup(false)
  }

  // end
  // Function to remove cards on right side
  const removeRightCard = (id, e, type) => {
    if (type === `newLeft`) {
      let tmpFilterArray = rightCards.filter((card) =>
        card.id !== id
      )
      setRightCards(tmpFilterArray);
      setNewLeftCards((newLeftCards) =>
        newLeftCards.map((card) =>
          card.id === id ? { ...card, disabled: false } : card
        )
      );
    }
    else {
      let array_default = [32, 23, 31, 24];
      let has_item = array_default.includes(parseInt(id));
      if (has_item == false) {
        let tmpFilterArray = rightCards.filter((card) =>
          card.id !== id
        )
        setRightCards(tmpFilterArray);
        setLeftCards((leftCards) =>
          leftCards.map((card) =>
            card.id === id ? { ...card, disabled: false } : card
          )
        );
        serviceProvider.post("/roadmap?param=removeroadmap", JSON.stringify({ id: id, uid: uid, pid: stepdata.plant_id })).then((response) => {

        });
        e.stopPropagation();
        e.preventDefault();
      } else {


      }
    }
  }
  // end
  // Function to open and close tabs on left
  const handleLeftClick = (id, index) => {
    setLeftValue(`l0-${index}`);
    setVendorTab(1);
    setIsVendorModal(false);
    if (leftOpen === id) {
      setLeftOpen(false);
    }
    else {
      setLeftOpen(id);
    }
  };
  // Function to open and close new tabs on left
  const handleNewLeftClick = (id, index) => {
    setLeftValue(`newL0-${index}`);
    setVendorTab(1);
    // setIsVendorModal(false);
    if (newLeftOpen === id) {
      setNewLeftOpen(false);
    }
    else {
      setNewLeftOpen(id);
    }
    setLeftOpen(false);
  };
  // Function to open and close tabs on right
  const handleRightClick = (id, index) => {
    setVendorTab(1);
    setRightValue(`r0-${index}`);
    setIsVendorRightModal(false);
    if (rightOpen === id) {
      setRightOpen(false);
    }
    else {
      setRightOpen(id);
    }
  };
  // Funtion to open and close inner tabs on left
  const handleLeftInnerTabClick = (val) => {
    if (lossLeftDataOpen === val) {
      setLossLeftDataOpen(false);
    }
    else {
      setLossLeftDataOpen(val);
    }
  };
  // Funtion to open and close inner tabs on right
  const handleRightInnerTabClick = (val) => {
    if (lossRightDataOpen === val) {
      setLossRightDataOpen(false);
    }
    else {
      setLossRightDataOpen(val);
    }
  };
  // Funtion to open technology/feature popup
  const handleTabRightClick = () => {
    setIsTechModal(true);
  }
  // Function to open and close inner QNA tab
  const handleQnaTabClick = (val) => {
    if (qnaOpen === val) {
      setQnaOpen(false);
    }
    else {
      setQnaOpen(val);
    }
  }
  // New Functions 
  // Add Vendor Popup
  const handleAddVendor = () => {
    setIsVendorAddBtn(false);
    setIsVendorDataSavedBtn(true)
    setTimeout(() => {
      setIsVendorDataSavedBtn(false);
    }, 2000);
    setTimeout(() => {
      setIsAddVendorPop(false);
    }, 2500)
  }
  // browse file
  const handleFileAttachment = (e) => {
    const file = e.target.files[0]; // Assuming single file upload
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (file && allowedTypes.includes(file.type)) {
      const url = URL.createObjectURL(file);
      setFileAttachment({ url, name: file.name });
    } else {

    }
  }

  const handleAddDescription = (item) => {
    setIsDescPop(true);
  }

  let handleSelectedScenario = (val, key) => {
    setSelectedScenarioByNode(prv => {
      return {
        ...prv,
        [key]: val
      }
    })
  }

  let handleSelectedScenarioButtonData = (val, key) => {
    let data = { ...selectedScenarioButtonData };
    let tmpArr =  data[key] || [];
    if (tmpArr && tmpArr?.includes(val)) {
      tmpArr = tmpArr.filter((i) => i  !== val);
    } else {
      tmpArr.push(val);
    }
    setSelectedScenarioButtonData(prv => {
      return {
        ...prv,
        [key]: tmpArr
      }
    });
    // console.clear();
    // console.warn(tmpArr);
  }

  let handleRemoveDimensionDataFromDB = (node_id, dim_id) => {
    serviceProvider.post("/roadmap?param=removeband", JSON.stringify({ plant_id: stepdata.plant_id, scenario_id: stepdata.sid, node_id: node_id, future_dim: dim_id })).then((response) => {

    });
  }

  let handleStoreDimensionDataInDB = (node_id, dim_id, current_band,future_band) => {

    serviceProvider.post("/roadmap?param=updateband", JSON.stringify({ plant_id: stepdata.plant_id, scenario_id: stepdata.sid, node_id: node_id, future_band: future_band, future_dim: dim_id, current_band })).then((response) => {

    });
  }

 let firstTimeSetSelectedDimData=()=>{
  
  let obj = {}
  rightCards?.forEach((node) => {
      let key = node.id;
      let selectedArr = node?.futurebands?.filter(i=> i.band_selected==1)?.map(i=>i.id);
      obj[key] = selectedArr;
  });
  setSelectedScenarioButtonData(obj);
  // console.clear();
  // console.log(obj);
 }
  
 useEffect(() => {
    // console.clear();
    let tmpObj = {}
    stepdata?.siriDimension?.forEach(i => {
      let tmpArr = getNextBandsAccordingToSelectedBands(i?.selected_band?.id, bands);
      tmpObj[i.id] = tmpArr[0].id;
    });
    defaultFilterArr.current = tmpObj;
    setSiriInputsData(tmpObj);

    //setLeftCards(tmpArray);
    if (stepdata?.filter?.length) {
      setTimeout(() => {
        handleDimensionCheckBoxApplyFilter(false);
      }, 1);
    }
  
    document.addEventListener("click", (e) => {
      let target = e.target;
      // close filter on click outside
      if (!target.closest('[data-component="FilterWrapper"]')) {
        closeFilter();
        closeDimensionFilter();
      }
    });

 }, []);


 useEffect(()=>{
  // set the dimension tab selected value
  firstTimeSetSelectedDimData();
 },[rightCards]);


  useEffect(() => {
    setLeftCards(stepdata.nodes);
  }, [props.stepData]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/home">{stepdata.plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem to="#">IR4.0 Roadmap Solutions</BreadcrumbsItem>
      {/* End */}

      <DragDropContext onDragEnd={handleOnDragEnd}>

        <MainWrapper data-component="MainWrapper" className="first-step">

          <SectionWrapper data-component="SectionWrapper">
            <LeftSection data-component="LeftSection">
              <SectionHeader data-component="SectionHeader">
                <ButtonWrapper
                  data-component="ButtonWrapper"
                  style={{ display: "none" }}
                >
                  <TopSectionBtn disabled data-component="TopSectionBtn">
                    Calculate Losses
                  </TopSectionBtn>
                  <TopSectionBtn data-component="TopSectionBtn">
                    Calculate Recoverables
                  </TopSectionBtn>
                  <TopSectionBtn data-component="TopSectionBtn">
                    Calculate Investment
                  </TopSectionBtn>
                </ButtonWrapper>

                <HeaderFilterWrapper data-component="HeaderFilterWrapper">
                  <HeaderTitle data-component="HeaderTitle">
                    IR4.0 Roadmap Solutions
                  </HeaderTitle>
                  {/* -------------------- HeaderTitle end ---------------- */}
                  <FilterWrapper data-component="FilterWrapper">
                    <FilterAllDimentionButton
                      data-component="FilterAllDimentionButton"
                      active={showDimensionFilter}
                      onClick={() => { setShowDimensionFilter(!showDimensionFilter); setShowFilter(false); }}
                      className="all-dim-btn"
                    >
                      All Dimensions
                      <i dangerouslySetInnerHTML={{ __html: ddlArrow }}></i>
                    </FilterAllDimentionButton>
                    <FilterDimensionBox data-component="FilterDimensionBox" show={showDimensionFilter}>
                      <FilterBoxHeader data-component="FilterBoxHeader">
                        <HeaderTitle data-component="HeaderTitle">
                          Select Dimensions:
                        </HeaderTitle>
                        <FilterCloseButton
                          data-component="HeaderTitle"
                          onClick={closeDimensionFilter}
                        >
                          <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </FilterCloseButton>
                      </FilterBoxHeader>
                      <FilterBoxContent data-component="FilterBoxContent">

                        <FilterBoxContentWrap data-component="FilterBoxContentWrap">
                          <FilterSiriTabContentWrap data-component="FilterSiriTabContentWrap" open={isActive == 2 ? "open" : ""}>
                            <div className="dimension-wrapper">
                              <div className="dimension-card">
                                <div className="top-wrap">
                                  <label>
                                    <input className="filter-checkbox" type="checkbox" checked={stepdata?.siriDimension?.length === dimensionCheckedArray?.length} onChange={(e) => handleDimensionCheckBox(e, 'all')} />
                                    <i dangerouslySetInnerHTML={{ __html: filterCheckboxSvg }}></i>
                                  </label>
                                  <div className="content-wrap">
                                    <p className="band-title">All Dimensions</p>
                                  </div>
                                </div>
                              </div>
                              {
                                stepdata?.siriDimension?.map((item, index) => (
                                  <div className="dimension-card" key={index}>
                                    <div className="top-wrap">
                                      <label>
                                        <input className="filter-checkbox" type="checkbox" checked={dimensionCheckedArray?.includes(item.id)} onChange={(e) => handleDimensionCheckBox(e, item.id)} />
                                        <i dangerouslySetInnerHTML={{ __html: filterCheckboxSvg }}></i>
                                      </label>
                                      <span className="count">{index + 1}</span>
                                      <div className="content-wrap">
                                        <p className="band-title">{item.dimension_name}</p>
                                        <span className="current-title">
                                          Current Status: {item?.selected_band?.name}
                                        </span>
                                      </div>
                                      <i className={`dimension-btn ${dimensionFilterCollapse?.includes(item.id) ? `active` : ''}`} dangerouslySetInnerHTML={{ __html: siriArrowSvg }} onClick={() => handleDimensionFilterCollapse(item.id)}></i>
                                    </div>
                                    <Collapse in={dimensionFilterCollapse?.includes(item.id)}>
                                      <div className="bottom-wrap">
                                        {getNextBandsAccordingToSelectedBands(item?.selected_band?.id, bands)?.map((band, band_index) => (
                                          <FilterBoxLable data-component="FilterBoxLable" key={band_index}>
                                            <input
                                              value={band.id}
                                              name={`dim-band${item.id}`}
                                              type="radio"
                                              checked={siriInputsData[item.id] == band.id}
                                              onChange={() => handleFilterOnchangeData(item.id, band.id)}
                                            />
                                            <span className="icon">
                                              <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                                            </span>
                                            <span className="filter-name">{band.name}</span>
                                          </FilterBoxLable>
                                        ))
                                        }
                                      </div>
                                    </Collapse>
                                  </div>
                                ))
                              }
                            </div>
                          </FilterSiriTabContentWrap>
                        </FilterBoxContentWrap>
                      </FilterBoxContent>
                      <FilterButtonWrapper data-component="FilterButtonWrapper">
                        <ApplyFilterButton onClick={handleDimensionCheckBoxApplyFilter} data-component="ApplyFilterButton">
                          Apply Filters
                        </ApplyFilterButton>
                      </FilterButtonWrapper>
                    </FilterDimensionBox>
                  </FilterWrapper>
                  {/* dimension filter */}
                  <FilterWrapper data-component="FilterWrapper">
                    <FilterButton
                      data-component="FilterButton"
                      active={showFilter}
                      onClick={() => { setShowFilter(!showFilter); setShowDimensionFilter(false); }}
                    >
                      <i dangerouslySetInnerHTML={{ __html: filterSvg }}></i>
                    </FilterButton>
                    <FilterBox data-component="FilterBox" show={showFilter}>
                      <FilterBoxHeader data-component="FilterBoxHeader">
                        <HeaderTitle data-component="HeaderTitle">
                          Available Capabilities Filters
                        </HeaderTitle>
                        <FilterCloseButton
                          data-component="HeaderTitle"
                          onClick={closeFilter}
                        >
                          <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </FilterCloseButton>
                      </FilterBoxHeader>
                      <FilterBoxContent data-component="FilterBoxContent">
                        <FilterTabWrap data-component="FilterTabWrap">
                          {/* <FilterTab
                            data-component="FilterTab"
                            className={isActive == 1 ? "active" : ""}
                            onClick={(e) => handleFilterTab(1)}
                          >
                            SmarterChains
                          </FilterTab> */}
                          <FilterTab
                            data-component="FilterTab"
                            className={isActive == 2 ? "active" : ""}
                            onClick={(e) => handleFilterTab(2)}
                          >
                            IIOT
                          </FilterTab>
                        </FilterTabWrap>
                        <FilterBoxContentWrap data-component="FilterBoxContentWrap">
                          <FilterTabContentWrap data-component="FilterTabContentWrap" style={{ display: 'none' }} open={isActive == 1 ? "open" : ""}>
                            <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                              Type
                            </FilterBoxContentTitle>
                            <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="type1"
                                  name="filterCheckBox"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Technology</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="type2"
                                  name="filterCheckBox"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Organization</span>
                              </FilterBoxLable>
                              {/* Organization Input end */}

                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="type3"
                                  name="filterCheckBoth"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Both</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                            </FilterCheckBoxWrapper>
                            <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                              Technology
                            </FilterBoxContentTitle>
                            <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="tech1"
                                  name="filterCheckBox"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Has</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="tech2"
                                  name="filterCheckBox"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Has not</span>
                              </FilterBoxLable>
                              {/* Organization Input end */}

                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="tech3"
                                  name="filterCheckBoth"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Partial</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                            </FilterCheckBoxWrapper>
                            <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                              Recoverables
                            </FilterBoxContentTitle>
                            <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="recover1"
                                  name="recover"
                                  type="radio"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                                </span>
                                <span>High to Low</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="recover2"
                                  name="recover"
                                  type="radio"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                                </span>
                                <span>Low to High</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                            </FilterCheckBoxWrapper>
                            <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                              Dimension
                            </FilterBoxContentTitle>
                            <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper" className="halfWidth">
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim1"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Factory of the Future Leadership</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim2"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Digital-Ready Infrastructure</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim3"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>360° Factory Visibility</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim4"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Intelligent Quality</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim5"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Automated Material Flow</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim6"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Data-Driven Maintenance</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim7"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Zero Touches Production</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim8"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Sustainability</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim9"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Tech-Augmented Workforce</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="dim10"
                                  name="dimension"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Digital Organization</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                            </FilterCheckBoxWrapper>

                            <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                              Loss Categories
                            </FilterBoxContentTitle>
                            <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper" className="halfWidth">
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss1"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Planned Downtime</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss2"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Unplanned Downtime</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss3"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Minor Stops</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss4"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Speed Losses</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss5"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Material Waste</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss6"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Operator Losses</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss7"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Maintenance Losses</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss8"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>IT Losses</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss9"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>EHS Losses</span>
                              </FilterBoxLable>
                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss10"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Quality Losses</span>
                              </FilterBoxLable>

                              <FilterBoxLable data-component="FilterBoxLable">
                                <input
                                  value="loss11"
                                  name="losses"
                                  type="checkbox"
                                />
                                <span className="icon">
                                  <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                </span>
                                <span>Warehouse Losses</span>
                              </FilterBoxLable>
                              {/* technology Input end */}
                            </FilterCheckBoxWrapper>
                          </FilterTabContentWrap>
                          <FilterSiriTabContentWrap data-component="FilterSiriTabContentWrap" open={isActive == 2 ? "open" : ""}>
                            <span className="title">Dimensions</span>
                            <div className="dimension-wrapper">
                              {siriDimension.map((item, index) => (
                                <div className="dimension-card" key={index} style={{ display: dimensionCheckedApplyArray.length === 0 || dimensionCheckedApplyArray?.includes(item.id) ? '' : 'none' }}>
                                  <div className="top-wrap">
                                    <span className="count">{index + 1}</span>
                                    <div className="content-wrap">
                                      <p className="band-title">{item.dimension_name}</p>
                                      <span className="current-title">
                                        Current Status: {item?.selected_band?.name}
                                      </span>
                                    </div>
                                    <i className={`dimension-btn ${dimensionCollapse?.includes(item.id) ? `active` : ''}`} dangerouslySetInnerHTML={{ __html: siriArrowSvg }} onClick={() => handleDimensionCollapse(item.id)}></i>
                                  </div>
                                  <Collapse in={dimensionCollapse?.includes(item.id)}>
                                    <div className="bottom-wrap">
                                      {getNextBandsAccordingToSelectedBands(item?.selected_band?.id, bands)?.map((band, band_index) => (
                                        <FilterBoxLable data-component="FilterBoxLable" key={band_index}>
                                          <input
                                            value={band.id}
                                            name={`band${item.id}`}
                                            type="radio"
                                            checked={siriInputsData[item.id] == band.id}
                                            onChange={() => handleFilterOnchangeData(item.id, band.id)}
                                          />
                                          <span className="icon">
                                            <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                                          </span>
                                          <span className="filter-name">{band.name}</span>
                                        </FilterBoxLable>
                                      ))
                                      }
                                    </div>
                                  </Collapse>
                                </div>
                              ))
                              }
                            </div>
                          </FilterSiriTabContentWrap>
                        </FilterBoxContentWrap>
                      </FilterBoxContent>
                      <FilterButtonWrapper data-component="FilterButtonWrapper">
                        <ResetFilterButton onClick={resetFilter} data-component="ResetFilterButton">
                          Reset Filters
                        </ResetFilterButton>
                        <ApplyFilterButton onClick={applyFilter} data-component="ApplyFilterButton">
                          Apply Filters
                        </ApplyFilterButton>
                      </FilterButtonWrapper>
                    </FilterBox>
                  </FilterWrapper>
                  {/* -------------------- FilterWrapper end ---------------- */}
                  <button className="added-roadmap-btn" onClick={() => setShowSmRightSection(true)}>{rightCards?.length} Added Roadmap</button>
                </HeaderFilterWrapper>
                {/* -------------------- HeaderFilterWrapper end ---------------- */}
                {/* -------------------- HeaderFilterWrapper end ---------------- */}
              </SectionHeader>
              <ContentBody data-component="ContentBody">
                <Droppable droppableId="left">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <ExistingCardWrap data-component="ExistingCardWrap">
                        {leftCards.map((card, index) => (
                          <Draggable
                            key={card.id}
                            draggableId={card.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={`card${snapshot.isDragging ? "dragging" : ""
                                  }`}
                                {...provided.draggableProps}

                                ref={provided.innerRef}
                                style={{
                                  ...provided.draggableProps.style,
                                  pointerEvents: card.disabled ? "none" : "all",
                                  userSelect: card.disabled ? "none" : "all",
                                }}
                              >
                                <MainCardWrapper
                                  data-component="MainCardWrapper"
                                  disabled={card.disabled}
                                >
                                  <TabCardWrapper data-component="TabCardWrapper">
                                    <TabButton data-component="TabButton">
                                      <TBRow data-component="TBRow">
                                        <TBLeftCol data-component="TBLeftCol">
                                          <div
                                            className="drag-btn"
                                            {...provided.dragHandleProps}
                                          >
                                            <i
                                              dangerouslySetInnerHTML={{
                                                __html: dragIcon,
                                              }}
                                            ></i>
                                          </div>
                                          <p className='rmap-txt'>
                                            {card.name}
                                          </p>
                                          <div className="status-wrap">
                                            {card.status === `NA` &&
                                              <NAButton data-component="NAButton" status>N/A</NAButton>
                                            }
                                            {card.status === `HAS` &&
                                              <NAButton data-component="NAButton" status={`has`}>Has</NAButton>
                                            }
                                            {card.status == `HAS NOT` &&
                                              <NAButton data-component="NAButton" status={`hasnot`}>Has Not</NAButton>
                                            }
                                            {card.status == `PARTIALLY` &&
                                              <NAButton data-component="NAButton" status={`partially`}>PARTIALLY</NAButton>
                                            }
                                          </div>
                                        </TBLeftCol>
                                        <TBRightCol data-component="TBRightCol">
                                          <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Recoverable: </HeadText>
                                              <UpgradeLock data-component="UpgradeLock">
                                                <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                                                <div className="upgrade-tooltip">
                                                  <span>Get IR4.0 Loss Analysis</span>
                                                </div>
                                              </UpgradeLock>
                                              <SubText data-component="SubText" green style={{ display: 'none' }}>€</SubText>
                                            </RightAlignTextWrap>
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Investment: </HeadText>
                                              <UpgradeLock data-component="UpgradeLock">
                                                <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                                                <div className="upgrade-tooltip">
                                                  <span>Get IR4.0 Loss Analysis</span>
                                                </div>
                                              </UpgradeLock>
                                              <SubText data-component="SubText" style={{ display: 'none' }} orange>€</SubText>
                                            </RightAlignTextWrap>
                                          </RightAlignTextWrapper>
                                          <AddDragBtn data-component="AddDragBtn" onClick={(e) => addRightCard(index, e)}>
                                            <i dangerouslySetInnerHTML={{ __html: addBtn }}></i>
                                          </AddDragBtn>
                                          <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftClick(card.id, index)} className={`${leftOpen === card.id ? 'active' : ''}`}>
                                            {leftOpen === card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                          </CollapseBtn>
                                        </TBRightCol>
                                      </TBRow>
                                    </TabButton>
                                    <Collapse in={leftOpen === card.id} timeout="auto">
                                      <TabContentWraaper data-component="TabContentWraaper">
                                        <Tabs
                                          orientation="horizontal"
                                          variant="scrollable"
                                          value={leftValue}
                                          onChange={handleLeftChange}
                                          aria-label="Vertical tabs example"

                                        >
                                          <Tab value={`l0-${index}`} label="Description" {...a11yProps(0)} />
                                          <Tab value={`l1-${index}`} label="IIOT" {...a11yProps(1)} />
                                          {/*<Tab value={`l2-${index}`} label="Vendors" {...a11yProps(2)} />
                                          <Tab value={`l3-${index}`} label="Q&A" {...a11yProps(3)} />
                                          <Tab onClick={handleTabRightClick} value={`l4-${index}`} label="Technologies / Features " {...a11yProps(4)} /> */}
                                        </Tabs>

                                        <TabPanel value={leftValue} index={`l0-${index}`}>
                                          <TabPanelContent data-component="TabPanelContent">
                                            {card.description ?
                                              <div dangerouslySetInnerHTML={{ __html: card.description }} />
                                              :
                                              <h1>Not available</h1>
                                            }

                                          </TabPanelContent>
                                        </TabPanel>
                                        <TabPanel value={leftValue} index={`l1-${index}`}>
                                          {card?.siri_dimensions && card?.siri_dimensions?.length > 0 ?
                                            <DimensionTab data-component="DimensionTab">
                                              {
                                                card?.siri_dimensions?.map((item, index) => (
                                                  <div className={`dimension-card ${item?.active ? `active` : ''}`} key={index}>
                                                    <div className="top-wrap">
                                                      <span className="count">{index + 1}</span>
                                                      <div className="content-wrap">
                                                        <p className="band-title">{item.dimension_name}</p>
                                                      </div>
                                                      <div className="right-section">
                                                        <span className="active-text">ACTIVE</span>
                                                        <i className={`dimension-btn ${siriDimensionCollapse?.includes(`${item.id}${card.id}`) ? `active` : ``}`} dangerouslySetInnerHTML={{ __html: siriArrowSvg }} onClick={() => handleSiriDimensionCollapse(`${item.id}${card.id}`)}></i>
                                                      </div>
                                                    </div>
                                                    <Collapse in={siriDimensionCollapse?.includes(`${item.id}${card.id}`)}>
                                                      <div className="bottom-wrap">
                                                        {getNextBandsAccordingToSelectedBands(item.id, bands)?.map((band, band_index) => (
                                                          <FilterBoxLable data-component="FilterBoxLable" key={band_index} disabled style={{ display: band.id == 0 ? 'none' : '' }} >
                                                            <input
                                                              value={band.id}
                                                              name={`band${item.id}`}
                                                              type="radio"
                                                              defaultChecked={band.name == `Band ${item.selected_band_main}`}
                                                              disabled
                                                            />
                                                            <span className="icon">
                                                              <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                                                            </span>
                                                            <span className="filter-name">{band.name}</span>
                                                          </FilterBoxLable>
                                                        ))
                                                        }
                                                      </div>
                                                    </Collapse>
                                                  </div>
                                                ))
                                              }
                                            </DimensionTab>
                                            :
                                            <h1>Not available</h1>
                                          }

                                        </TabPanel>
                                        {/* <TabPanel value={leftValue} index={`l1-${index}`}>
                                          <InnerTabWrapper data-component="InnerTabWrapper">
                                            <TBRow data-component="TBRow">
                                              <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                                <p className="inner-tab-txt">Operator Effort Losses</p>
                                              </InnerTBLeftCol>
                                              <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                                <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                                  <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                    <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                    <SubText data-component="SubText" green>2,813€</SubText>
                                                  </RightAlignTextWrap>
                                                  <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                    <HeadText data-component="HeadText">Investment: </HeadText>
                                                    <SubText data-component="SubText" orange>177,000€</SubText>
                                                  </RightAlignTextWrap>
                                                </RightAlignTextWrapper>
                                                <p className='recovery-txt'>
                                                  <span>8% </span>
                                                  of category <br />losses Recovered
                                                </p>
                                                <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftInnerTabClick(`loss0-${index}`)} className={`${lossLeftDataOpen === `loss0-${index}` ? 'active' : ''}`}>
                                                  {lossLeftDataOpen === `loss0-${index}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                </CollapseBtn>
                                              </InnerTBRightCol>
                                            </TBRow>
                                          </InnerTabWrapper>
                                          <Collapse in={lossLeftDataOpen === `loss0-${index}`} timeout="auto">
                                            <LossTableWrap data-component="LossTableWrap">
                                              <LossScrollTableWrap data-component="LossScrollTableWrap">
                                                <table className='responsive-loss-table'>
                                                  <thead>
                                                    <tr>
                                                      <th className='loss-table-col'>Sublosses</th>
                                                      <th className='loss-table-col'>
                                                        Identified<br /> Losses
                                                      </th>
                                                      <th className='loss-table-col'>Recoverable</th>
                                                      <th className='loss-table-col'>% of Subloss<br />recoverable </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td className='loss-table-col'>Reviewing production information to ensure quality compliant product release</td>
                                                      <td className='loss-table-col orange'>78,139<span>€</span></td>
                                                      <td className='loss-table-col green'>6,312<span>€</span></td>
                                                      <td className='loss-table-col blue'>8%</td>
                                                    </tr>
                                                    <tr>
                                                      <td className='loss-table-col bottom black'>Total</td>
                                                      <td className='loss-table-col bottom black'>78,139<span>€</span></td>
                                                      <td className='loss-table-col bottom black'>6,312<span>€</span></td>
                                                      <td className='loss-table-col bottom black'></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </LossScrollTableWrap>
                                            </LossTableWrap>
                                          </Collapse>
                                          <LossWrap data-component="LossWrap">
                                            <LossRow data-component="LossRow">
                                              <LossCol data-component="LossCol">
                                                <span className="text">Associated</span>
                                                <span className="text">Losses:</span>
                                                <span className="text green">8,943 €</span>
                                              </LossCol>
                                              <LossCol data-component="LossCol">
                                                <span className="text">Total Technology</span>
                                                <span className="text"> Recoverable:</span>
                                                <span className="text orange">1,288 €</span>
                                              </LossCol>
                                              <LossCol data-component="LossCol">
                                                <span className="text">% of Total</span>
                                                <span className="text"> Recovered Losses:</span>
                                                <span className="text blue">12%</span>
                                              </LossCol>
                                            </LossRow>
                                          </LossWrap>
                                        </TabPanel>
                                        <TabPanel value={leftValue} index={`l2-${index}`}>
                                          <VendorWrap data-component="VendorWrap">
                                            <VendorRow data-component="VendorRow">
                                              <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                  <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorModal(true)}>
                                                    <img src={require("../../assets/img/roadmap-page/dematic.png").default} />
                                                  </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                  Dematic
                                                </VendorText>
                                              </VendorCol>
                                              <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                  <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorModal(true)}>
                                                    <img src={require("../../assets/img/roadmap-page/jbt.png").default} />
                                                  </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                  JBT
                                                </VendorText>
                                              </VendorCol>
                                              <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                  <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorModal(true)}>
                                                    <img src={require("../../assets/img/roadmap-page/toyota.png").default} />
                                                  </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                  Toyota Material Handling
                                                </VendorText>
                                              </VendorCol>
                                              <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                  <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorModal(true)}>
                                                    <img src={require("../../assets/img/roadmap-page/linde.png").default} />
                                                  </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                  Linde Material Handling
                                                </VendorText>
                                              </VendorCol>
                                              <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                  <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorModal(true)}>
                                                    <img src={require("../../assets/img/roadmap-page/electric.png").default} />
                                                  </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                  Elettric80
                                                </VendorText>
                                              </VendorCol>
                                            </VendorRow>
                                            {isVendorModal ?
                                              <VendorModalWrap data-component="VendorModalWrap">
                                                <VendorModalHeader data-component="VendorModalHeader">
                                                  <h3 className='vendor-name'>JBT</h3>
                                                  <VendorCloseBtn data-component="VendorCloseBtn" onClick={() => { setIsVendorModal(false); setVendorTab(1); }}>
                                                    <i dangerouslySetInnerHTML={{ __html: vendorCloseSvg }}></i>
                                                  </VendorCloseBtn>
                                                </VendorModalHeader>
                                                <VendorTabWrap data-component="VendorTabWrap">
                                                  <VendorTabs data-component="VendorTabs" >
                                                    <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 1 && 'active'}`} onClick={() => setVendorTab(1)}>Investment</VendorTab>
                                                    <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 2 && 'active'}`} onClick={() => setVendorTab(2)}> Recoverable </VendorTab>
                                                  </VendorTabs>
                                                  {vendorTab == 2 ?
                                                    <VendorTabPanel data-component="VendorTabPanel">
                                                      <AccordionTabWrapper data-component="AccordionTabWrapper">
                                                        <Accordion expanded={expanded === `panel1-${index}`} onChange={HandleChange(`panel1-${index}`)}>
                                                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                            <AccordionHeader data-component="AccordionHeader">
                                                              <AccordImg data-component="AccordImg">
                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                              </AccordImg>
                                                              <span className="accord-txt">Maintenance Losses</span>
                                                            </AccordionHeader>
                                                            <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                            <MaintainWrap data-component="MaintainWrap">
                                                              <MaintainRow data-component="MaintainRow">
                                                                <LeftMainCol data-component="LeftMainCol">
                                                                  <AccordImg data-component="AccordImg">
                                                                    <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                  </AccordImg>
                                                                  <span className="accord-txt">Maintenance Losses</span>
                                                                </LeftMainCol>
                                                                <RightMainCol data-component="RightMainCol">
                                                                  <span className="main-val">10%</span>
                                                                </RightMainCol>
                                                              </MaintainRow>
                                                              <MaintainRow data-component="MaintainRow">
                                                                <LeftMainCol data-component="LeftMainCol">
                                                                  <AccordImg data-component="AccordImg">
                                                                    <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                  </AccordImg>
                                                                  <span className="accord-txt">Maintenance Losses</span>
                                                                </LeftMainCol>
                                                                <RightMainCol data-component="RightMainCol">
                                                                  <span className="main-val">10%</span>
                                                                </RightMainCol>
                                                              </MaintainRow>
                                                            </MaintainWrap>
                                                          </AccordionDetails>
                                                        </Accordion>
                                                        <Accordion expanded={expanded === `panel2-${index}`} onChange={HandleChange(`panel2-${index}`)}>
                                                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                            <AccordionHeader data-component="AccordionHeader">
                                                              <AccordImg data-component="AccordImg">
                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                              </AccordImg>
                                                              <span className="accord-txt">Maintenance Losses</span>
                                                            </AccordionHeader>
                                                            <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                            <MaintainWrap data-component="MaintainWrap">
                                                              <MaintainRow data-component="MaintainRow">
                                                                <LeftMainCol data-component="LeftMainCol">
                                                                  <AccordImg data-component="AccordImg">
                                                                    <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                  </AccordImg>
                                                                  <span className="accord-txt">Line Efficiency Losses</span>
                                                                </LeftMainCol>
                                                                <RightMainCol data-component="RightMainCol">
                                                                  <span className="main-val">10%</span>
                                                                </RightMainCol>
                                                              </MaintainRow>
                                                              <MaintainRow data-component="MaintainRow">
                                                                <LeftMainCol data-component="LeftMainCol">
                                                                  <AccordImg data-component="AccordImg">
                                                                    <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                  </AccordImg>
                                                                  <span className="accord-txt">Maintenance Losses</span>
                                                                </LeftMainCol>
                                                                <RightMainCol data-component="RightMainCol">
                                                                  <span className="main-val">10%</span>
                                                                </RightMainCol>
                                                              </MaintainRow>
                                                            </MaintainWrap>
                                                          </AccordionDetails>
                                                        </Accordion>
                                                      </AccordionTabWrapper>
                                                    </VendorTabPanel>
                                                    :
                                                    <VendorTabPanel data-component="VendorTabPanel">
                                                      <ListInvestWrap data-component="ListInvestWrap">
                                                        <ListRow data-component="ListRow">
                                                          <p className='blue-txt'>CAPEX</p>
                                                          <ListValWrap data-component="ListValWrap">
                                                            <span className='money-txt'>375,000</span>
                                                            <span className='money-val'>€</span>
                                                          </ListValWrap>
                                                        </ListRow>
                                                        <ListRow data-component="ListRow">
                                                          <p className='blue-txt'>Starting OPEX</p>
                                                          <ListValWrap data-component="ListValWrap">
                                                            <span className='money-txt'>60,000</span>
                                                            <span className='money-val'>€</span>
                                                          </ListValWrap>
                                                        </ListRow>
                                                        <UpfrontListRow data-component="UpfrontListRow">
                                                          <p className='black-txt'>Upfront Investment</p>
                                                          <ListValWrap data-component="ListValWrap">
                                                            <span className='money-txt'>435,000</span>
                                                            <span className='money-val'>€</span>
                                                          </ListValWrap>
                                                        </UpfrontListRow>
                                                      </ListInvestWrap>
                                                      <GoListWrap data-component="GoListWrap">
                                                        <p className='blue-txt'>Going OPEX</p>
                                                        <ListValWrap data-component="ListValWrap">
                                                          <span className='money-txt'>60,000</span>
                                                          <span className='money-val'>€</span>
                                                        </ListValWrap>
                                                      </GoListWrap>
                                                      <UserWrap data-component="UserWrap">
                                                        <span className='user-txt'>No. of Users:</span>
                                                        <span className='user-num'>5</span>
                                                      </UserWrap>
                                                    </VendorTabPanel>
                                                  }
                                                </VendorTabWrap>
                                              </VendorModalWrap>
                                              : ''}
                                          </VendorWrap>
                                        </TabPanel>
                                        <TabPanel value={leftValue} index={`l3-${index}`}>
                                          <QNACard data-component="QNACard" className={`${qnaOpen === `q0-${index}` ? 'active' : ''}`}>
                                            <QNABtn data-component="QNABtn" onClick={() => handleQnaTabClick(`q0-${index}`)}>
                                              <span className="q-txt">1.</span>
                                              <p className="q-desc">
                                                How do you remove waste from your shop floor?
                                              </p>
                                            </QNABtn>
                                            <Collapse in={qnaOpen === `q0-${index}`} timeout="auto">
                                              <QNAWrap data-component="QNAWrap">
                                                <table>
                                                  <tbody>
                                                    <tr>
                                                      <th className="bd-bottom bd-right"></th>
                                                      <th className="th-align bd-bottom bd-right">All Lines</th>
                                                      <th className="th-align bd-bottom bd-right">No Lines</th>
                                                      <th className="th-align bd-bottom bd-right">Some Li.</th>
                                                      <th className="th-align bd-bottom ">Skipped</th>
                                                    </tr>
                                                    <tr>
                                                      <th className="bd-bottom bd-right">
                                                        <span>A.</span>Cardboard Loading
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                    <tr>
                                                      <th className="bd-bottom bd-right">
                                                        <span>B.</span>Case Forming and Sealing
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                    <tr>
                                                      <th class="bd-bottom bd-right">
                                                        <span>C.</span>Pick and place items into boxes
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                    <tr>
                                                      <th class="bd-bottom bd-right">
                                                        <span>D.</span>Document inserting
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </QNAWrap>
                                            </Collapse>
                                          </QNACard>
                                          <QNACard data-component="QNACard" className={`${qnaOpen === `q1-${index}` ? 'active' : ''}`}>
                                            <QNABtn data-component="QNABtn" onClick={() => handleQnaTabClick(`q1-${index}`)}>
                                              <span className="q-txt">2.</span>
                                              <p className="q-desc">How would you best describe your case forming process?</p>
                                            </QNABtn>
                                            <Collapse in={qnaOpen === `q1-${index}`} timeout="auto">
                                              <QNAWrap data-component="QNAWrap">
                                                <table>
                                                  <tbody>
                                                    <tr>
                                                      <th className="bd-bottom bd-right"></th>
                                                      <th className="th-align bd-bottom bd-right">All Lines</th>
                                                      <th className="th-align bd-bottom bd-right">No Lines</th>
                                                      <th className="th-align bd-bottom bd-right">Some Li.</th>
                                                      <th className="th-align bd-bottom ">Skipped</th>
                                                    </tr>
                                                    <tr>
                                                      <th className="bd-bottom bd-right">
                                                        <span>A.</span>
                                                        Manual. Our operators form the cases by hand
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                    <tr>
                                                      <th className="bd-bottom bd-right">
                                                        <span>B.</span>
                                                        Assisted. A special workbench helps the operators form the cardboards easily
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                    <tr>
                                                      <th class="bd-bottom bd-right">
                                                        <span>C.</span>
                                                        Automated. A dedicated machine forms the cases and feeds them to the line
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                    <tr>
                                                      <th class="bd-bottom bd-right">
                                                        <span>D.</span>Document inserting
                                                      </th>
                                                      <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                      <td className="bd-bottom bd-right"></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </QNAWrap>
                                            </Collapse>
                                          </QNACard>
                                        </TabPanel> */}
                                      </TabContentWraaper>
                                    </Collapse>
                                  </TabCardWrapper>
                                </MainCardWrapper>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </ExistingCardWrap>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </ContentBody>
            </LeftSection>
            <RightSection data-component="RightSection" smActive={showSmRightSection}>
              <button className="sm-close-popup-btn" onClick={() => setShowSmRightSection(false)}>
                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
              </button>
              <SectionHeader data-component="SectionHeader">
                <ScenarioSectionWrapper data-component="ScenarioSectionWrapper">
                  <ScenarioDropdown
                    options={scenarioList}
                    stepdata={stepdata}
                    setOptions={setScenarioList}
                    setStatus={props.setStatus}
                    callBackOnchange={(obj) => {
                      setIsMakeDefault(obj);
                    }}
                  />
                  <SaveRoadmapButton
                    data-component="SaveRoadmapButton"
                    disabled={false}
                    onClick={() => handleSaveRoadmap()}
                  >
                    Save Roadmap
                  </SaveRoadmapButton>
                </ScenarioSectionWrapper>
                {isMakeDefault ?
                  <label className="default-scenario">
                    <input ref={makeDefaultRef} type="checkbox" onChange={() => setIsDefaultScenarioPopup(true)} />
                    <span>Make this your default scenario to be displayed on Home page</span>
                  </label>
                  :
                  <p>This is your default scenario to be displayed on Home page</p>
                }
              </SectionHeader>

              <ContentBody data-component="ContentBody">
                <h3 className="blue-txt">Roadmap</h3>
                <LossWrap data-component="LossWrap">
                  <LossRow data-component="LossRow">
                    <LossCol data-component="LossCol">
                      <span className="text">Total Losses</span>
                      <span className="text">Identified:</span>
                      <div className="upgrade-lock-need-wrapper">
                        <UpgradeLock data-component="UpgradeLock">
                          <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                          <div className="upgrade-tooltip">
                            <span>Get IR4.0 Loss Analysis</span>
                          </div>
                        </UpgradeLock>
                      </div>
                      <span className="text orange" style={{ display: 'none' }}>1,145,160 €</span>

                    </LossCol>
                    <LossCol data-component="LossCol">
                      <span className="text">Total Roadmap</span>
                      <span className="text"> Recoverable:</span>
                      <div className="upgrade-lock-need-wrapper">
                        <div className="upgrade-lock-need-wrapper">
                          <UpgradeLock data-component="UpgradeLock">
                            <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                            <div className="upgrade-tooltip">
                              <span>Get IR4.0 Loss Analysis</span>
                            </div>
                          </UpgradeLock>
                        </div>
                      </div>
                      <span className="text green" style={{ display: 'none' }}>156,899 €</span>

                    </LossCol>
                    <LossCol data-component="LossCol">
                      <span className="text">% of Recoverable</span>
                      <span className="text"> Losses:</span>
                      <div className="upgrade-lock-need-wrapper">
                        <UpgradeLock data-component="UpgradeLock">
                          <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                          <div className="upgrade-tooltip">
                            <span>Get IR4.0 Loss Analysis</span>
                          </div>
                        </UpgradeLock>
                      </div>
                      <span className="text blue" style={{ display: 'none' }}>11%</span>

                    </LossCol>
                    <LossCol data-component="LossCol">
                      <span className="text">Total Roadmap</span>
                      <span className="text">Investment:</span>
                      <div className="upgrade-lock-need-wrapper">
                        <UpgradeLock data-component="UpgradeLock">
                          <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                          <div className="upgrade-tooltip">
                            <span>Get IR4.0 Loss Analysis</span>
                          </div>
                        </UpgradeLock>
                      </div>
                      <span className="text orange" style={{ display: 'none' }}>3364000 €</span>

                    </LossCol>
                  </LossRow>
                </LossWrap>

                <Droppable droppableId="right">

                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ minHeight: "100%" }}

                    >
                      {rightCards.map((card, index) => (
                        <div
                          key={card.id}
                          className={`card${card.disabled ? " disabled" : ""}`}
                        >
                          <MainCardWrapper
                            key={index}
                            data-component="MainCardWrapper"
                          >
                            <RightTabCardWrapper data-component="RightTabCardWrapper">
                              <Draggable
                                key={card.id}
                                draggableId={`card${card.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className={`card${snapshot.isDragging ? "dragging" : ""
                                      }`}
                                    {...provided.draggableProps}

                                    ref={provided.innerRef}
                                    style={{
                                      ...provided.draggableProps.style,
                                      pointerEvents: card.disabled ? "none" : "all",
                                      userSelect: card.disabled ? "none" : "all",
                                    }}
                                  >
                                    <TabButton data-component="TabButton" {...provided.dragHandleProps} as={`div`}>
                                      <TBRow data-component="TBRow">
                                        <TBLeftCol data-component="TBLeftCol">
                                          <button className="drag-btn blue">
                                            <span>{index + 1}</span>
                                          </button>
                                          <p className='rmap-txt'>
                                            {card.name}
                                          </p>
                                        </TBLeftCol>
                                        <TBRightCol data-component="TBRightCol">
                                          <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Recoverable: </HeadText>
                                              <UpgradeLock data-component="UpgradeLock">
                                                <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                                                <div className="upgrade-tooltip">
                                                  <span>Get IR4.0 Loss Analysis</span>
                                                </div>
                                              </UpgradeLock>
                                              <SubText data-component="SubText" green style={{ display: 'none' }}>€</SubText>
                                            </RightAlignTextWrap>
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Investment: </HeadText>
                                              <UpgradeLock data-component="UpgradeLock">
                                                <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                                                <div className="upgrade-tooltip">
                                                  <span>Get IR4.0 Loss Analysis</span>
                                                </div>
                                              </UpgradeLock>
                                              <SubText data-component="SubText" orange style={{ display: 'none' }}>€</SubText>
                                            </RightAlignTextWrap>
                                          </RightAlignTextWrapper>
                                          <AddDragBtn data-component="AddDragBtn" onClick={(e) => removeRightCard(card.id, e)}>
                                            <i dangerouslySetInnerHTML={{ __html: minusIcon }}></i>
                                          </AddDragBtn>
                                          <CollapseBtn data-component="CollapseBtn" onClick={() => handleRightClick(card.id, index)} className={`${rightOpen === card.id ? 'active' : ''}`}>
                                            {rightOpen === card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                          </CollapseBtn>
                                        </TBRightCol>
                                      </TBRow>
                                    </TabButton>
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                              <Collapse in={rightOpen === card.id} timeout="auto">
                                <TabContentWraaper data-component="TabContentWraaper">
                                  <Tabs
                                    orientation="horizontal"
                                    variant="scrollable"
                                    value={rightValue}
                                    onChange={handleRightChange}
                                    aria-label="Vertical tabs example"

                                  >
                                    <Tab value={`r0-${index}`} label="Description" {...a11yProps(0)} />
                                    <Tab value={`r1-${index}`} label="IIOT" {...a11yProps(1)} />
                                    {/* <Tab value={`r1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                                    <Tab value={`r2-${index}`} label="Vendors" {...a11yProps(2)} /> */}
                                    <Tab value={`r2-${index}`} label="Dimensions" {...a11yProps(2)} />
                                  </Tabs>

                                  <TabPanel value={rightValue} index={`r0-${index}`}>
                                    <TabPanelContent data-component="TabPanelContent">
                                      {card.description ?
                                        <div dangerouslySetInnerHTML={{ __html: card.description }} />
                                        :
                                        <h1>Not available</h1>
                                      }

                                    </TabPanelContent>
                                  </TabPanel>
                                  <TabPanel value={rightValue} index={`r1-${index}`}>
                                    {card?.siri_dimensions && card?.siri_dimensions?.length > 0 ?
                                      <DimensionTab data-component="DimensionTab">
                                        {
                                          card?.siri_dimensions?.map((item, index) => (
                                            <div className={`dimension-card ${item?.active ? `active` : ''}`} key={index}>
                                              <div className="top-wrap">
                                                <span className="count">{index + 1}</span>
                                                <div className="content-wrap">
                                                  <p className="band-title">{item.dimension_name}</p>
                                                </div>
                                                <div className="right-section">
                                                  <span className="active-text">ACTIVE</span>
                                                  <i className={`dimension-btn ${siriDimensionCollapse?.includes(`${item.id}${card.id}`) ? `active` : ``}`} dangerouslySetInnerHTML={{ __html: siriArrowSvg }} onClick={() => handleSiriDimensionCollapse(`${item.id}${card.id}`)}></i>
                                                </div>
                                              </div>
                                              <Collapse in={siriDimensionCollapse?.includes(`${item.id}${card.id}`)}>
                                                <div className="bottom-wrap">
                                                  {getNextBandsAccordingToSelectedBands(item.id, bands)?.map((band, band_index) => (
                                                    <FilterBoxLable data-component="FilterBoxLable" key={band_index} disabled style={{ display: band.id == 0 ? 'none' : '' }} >
                                                      <input
                                                        value={band.id}
                                                        name={`band${item.id}`}
                                                        type="radio"
                                                        defaultChecked={band.name == `Band ${item.selected_band_main}`}
                                                        disabled
                                                      />
                                                      <span className="icon">
                                                        <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                                                      </span>
                                                      <span className="filter-name">{band.name}</span>
                                                    </FilterBoxLable>
                                                  ))
                                                  }
                                                </div>
                                              </Collapse>
                                            </div>
                                          ))
                                        }
                                      </DimensionTab>
                                      :
                                      <h1>Not available</h1>
                                    }

                                  </TabPanel>
                                  <TabPanel value={rightValue} index={`r2-${index}`}>
                                    <DimensionTabTitle data-component="imensionTabTitle">
                                      {card?.futurebands?.length > 0 ?
                                        <>Select a <b>Dimension</b> and it’s <b>Future Band</b> for Roadmap:</>
                                        :
                                        <b>No Dimension available for this node</b>
                                      }
                                    </DimensionTabTitle>
                                    {card?.futurebands?.length > 0 ?
                                      <DimensionTabRow data-component="DimensionTabRow">
                                        <DimensionTabCol1 data-component="DimensionTabCol1">

                                        </DimensionTabCol1>
                                        <DimensionTabCol2 data-component="DimensionTabCol2">
                                          <b>Current Band</b>
                                        </DimensionTabCol2>
                                        <DimensionTabCol2 data-component="DimensionTabCol2">
                                          <b>Future Band</b>
                                        </DimensionTabCol2>
                                        <DimensionTabCol3 data-component="DimensionTabCol3">

                                        </DimensionTabCol3>
                                        <DimensionTabCol3 data-component="DimensionTabCol3">

                                        </DimensionTabCol3>
                                      </DimensionTabRow>
                                      :
                                      <></>
                                    }
                                    {card?.futurebands?.map((dim, s_index) => {
                                      let tmpSlectedDim = (selectedScenarioButtonData[card.id] !== undefined && selectedScenarioButtonData[card.id]?.includes(dim?.id)) || (selectedScenarioButtonData[card.id] === undefined && dim?.band_selected==1);
                                      let getSelectedBand = dim.band.find(i => i.id == dim?.current_band);
                                      let getSelectedFutureBand = dim.band.find(i => i.id == dim?.future_band);
                                      let tmpSelectedBand = selectedScenarioByNode[card.id + '_' + dim.id] !== undefined ? selectedScenarioByNode[card.id + '_' + dim.id] : getSelectedBand;
                                      let tmpSelectedFutureBand = selectedScenarioByNode[card.id + '_' + dim.id + 'f'] !== undefined ? selectedScenarioByNode[card.id + '_' + dim.id + 'f'] : getSelectedFutureBand;
                                      return (
                                        <DimensionTabRow data-component="DimensionTabRow" key={card.id + '_' + dim.id}>
                                          <DimensionTabCol1 data-component="DimensionTabCol1">
                                            {s_index + 1}. {dim.name}
                                          </DimensionTabCol1>
                                          <DimensionTabCol2 data-component="DimensionTabCol2">
                                            <Dropdown
                                              options={dim?.band || []}
                                              defaultSelection={tmpSelectedBand}
                                              getValueOnChange={(val) => { handleSelectedScenario(val, card.id + '_' + dim.id); handleStoreDimensionDataInDB(card.id, dim.id, val.id, tmpSelectedFutureBand.id); }}
                                              disabled={!tmpSlectedDim}
                                            />
                                          </DimensionTabCol2>
                                          <DimensionTabCol2 data-component="DimensionTabCol2">
                                            <Dropdown
                                              options={dim?.band || []}
                                              defaultSelection={tmpSelectedFutureBand}
                                              getValueOnChange={(val) => { handleSelectedScenario(val, card.id + '_' + dim.id + 'f'); handleStoreDimensionDataInDB(card.id, dim.id,tmpSelectedBand.id,val.id);}}
                                              disabled={!tmpSlectedDim}
                                            />
                                          </DimensionTabCol2>
                                          <DimensionTabCol3 data-component="DimensionTabCol3">
                                            {tmpSlectedDim ?
                                              <p className="selected-text">
                                                <i dangerouslySetInnerHTML={{ __html: dimentionsSelctedIcon }}></i>Selected
                                              </p>
                                              :
                                              <button className="row-select-btn" onClick={() => { handleSelectedScenarioButtonData(dim.id, card.id, tmpSelectedBand); handleStoreDimensionDataInDB(card.id, dim.id, tmpSelectedBand.id,tmpSelectedFutureBand.id) }}>
                                                Select
                                              </button>
                                            }
                                          </DimensionTabCol3>
                                          <DimensionTabCol3 data-component="DimensionTabCol3">
                                            {tmpSlectedDim ?
                                              <button className="row-remove-btn" onClick={() => {
                                                handleSelectedScenarioButtonData(dim.id, card.id);
                                                handleRemoveDimensionDataFromDB(card.id, dim.id);
                                              }}>
                                                Remove
                                              </button>
                                              :
                                              <></>
                                            }
                                          </DimensionTabCol3>
                                        </DimensionTabRow>
                                      )
                                    })
                                    }
                                  </TabPanel>
                                  {/* <TabPanel value={rightValue} index={`r1-${index}`}>
                                    <InnerTabWrapper data-component="InnerTabWrapper">
                                      <TBRow data-component="TBRow">
                                        <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                          <p className="inner-tab-txt">Operator Effort Losses</p>
                                        </InnerTBLeftCol>
                                        <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                          <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Recoverable: </HeadText>
                                              <SubText data-component="SubText" green>2,813€</SubText>
                                            </RightAlignTextWrap>
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Investment: </HeadText>
                                              <SubText data-component="SubText" orange>177,000€</SubText>
                                            </RightAlignTextWrap>
                                          </RightAlignTextWrapper>
                                          <p className='recovery-txt'>
                                            <span>8% </span>
                                            of category <br />losses Recovered
                                          </p>
                                          <CollapseBtn data-component="CollapseBtn" onClick={() => handleRightInnerTabClick(`loss0-${index}`)} className={`${lossRightDataOpen === `loss0-${index}` ? 'active' : ''}`}>
                                            {lossRightDataOpen === `loss0-${index}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                          </CollapseBtn>
                                        </InnerTBRightCol>
                                      </TBRow>
                                    </InnerTabWrapper>
                                    <Collapse in={lossRightDataOpen === `loss0-${index}`} timeout="auto">
                                      <LossTableWrap data-component="LossTableWrap">
                                        <LossScrollTableWrap data-component="LossScrollTableWrap">
                                          <table className='responsive-loss-table'>
                                            <thead>
                                              <tr>
                                                <th className='loss-table-col'>Sublosses</th>
                                                <th className='loss-table-col'>
                                                  Identified<br /> Losses
                                                </th>
                                                <th className='loss-table-col'>Recoverable</th>
                                                <th className='loss-table-col'>% of Subloss<br />recoverable </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td className='loss-table-col'>Reviewing production information to ensure quality compliant product release</td>
                                                <td className='loss-table-col orange'>78,139<span>€</span></td>
                                                <td className='loss-table-col green'>6,312<span>€</span></td>
                                                <td className='loss-table-col blue'>8%</td>
                                              </tr>
                                              <tr>
                                                <td className='loss-table-col bottom black'>Total</td>
                                                <td className='loss-table-col bottom black'>78,139<span>€</span></td>
                                                <td className='loss-table-col bottom black'>6,312<span>€</span></td>
                                                <td className='loss-table-col bottom black'></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </LossScrollTableWrap>
                                      </LossTableWrap>
                                    </Collapse>
                                    <LossWrap data-component="LossWrap">
                                      <LossRow data-component="LossRow">
                                        <LossCol data-component="LossCol">
                                          <span className="text">Associated</span>
                                          <span className="text">Losses:</span>
                                          <span className="text green">8,943 €</span>
                                        </LossCol>
                                        <LossCol data-component="LossCol">
                                          <span className="text">Total Technology</span>
                                          <span className="text"> Recoverable:</span>
                                          <span className="text orange">1,288 €</span>
                                        </LossCol>
                                        <LossCol data-component="LossCol">
                                          <span className="text">% of Total</span>
                                          <span className="text"> Recovered Losses:</span>
                                          <span className="text blue">12%</span>
                                        </LossCol>
                                      </LossRow>
                                    </LossWrap>
                                  </TabPanel>
                                  <TabPanel value={rightValue} index={`r2-${index}`}>
                                    <VendorWrap data-component="VendorWrap">
                                      <VendorRow data-component="VendorRow">
                                        <VendorCol data-component="VendorCol">
                                          <VendorCard data-component="VendorCard">
                                            <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorRightModal(true)}>
                                              <img src={require("../../assets/img/roadmap-page/dematic.png").default} />
                                            </VendorClickImg>
                                          </VendorCard>
                                          <VendorText data-component="VendorText">
                                            Dematic
                                          </VendorText>
                                        </VendorCol>
                                        <VendorCol data-component="VendorCol">
                                          <VendorCard data-component="VendorCard">
                                            <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorRightModal(true)}>
                                              <img src={require("../../assets/img/roadmap-page/jbt.png").default} />
                                            </VendorClickImg>
                                          </VendorCard>
                                          <VendorText data-component="VendorText">
                                            JBT
                                          </VendorText>
                                        </VendorCol>
                                        <VendorCol data-component="VendorCol">
                                          <VendorCard data-component="VendorCard">
                                            <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorRightModal(true)}>
                                              <img src={require("../../assets/img/roadmap-page/toyota.png").default} />
                                            </VendorClickImg>
                                          </VendorCard>
                                          <VendorText data-component="VendorText">
                                            Toyota Material Handling
                                          </VendorText>
                                        </VendorCol>
                                        <VendorCol data-component="VendorCol">
                                          <VendorCard data-component="VendorCard">
                                            <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorRightModal(true)}>
                                              <img src={require("../../assets/img/roadmap-page/linde.png").default} />
                                            </VendorClickImg>
                                          </VendorCard>
                                          <VendorText data-component="VendorText">
                                            Linde Material Handling
                                          </VendorText>
                                        </VendorCol>
                                        <VendorCol data-component="VendorCol">
                                          <VendorCard data-component="VendorCard">
                                            <VendorClickImg data-componenet='VendorClickImg' onClick={() => setIsVendorRightModal(true)}>
                                              <img src={require("../../assets/img/roadmap-page/electric.png").default} />
                                            </VendorClickImg>
                                          </VendorCard>
                                          <VendorText data-component="VendorText">
                                            Elettric80
                                          </VendorText>
                                        </VendorCol>
                                      </VendorRow>
                                      {isVendorRightModal ?
                                        <VendorModalWrap data-component="VendorModalWrap">
                                          <VendorModalHeader data-component="VendorModalHeader">
                                            <h3 className='vendor-name'>JBT</h3>
                                            <VendorCloseBtn data-component="VendorCloseBtn" onClick={() => { setIsVendorRightModal(false); setVendorTab(1); }}>
                                              <i dangerouslySetInnerHTML={{ __html: vendorCloseSvg }}></i>
                                            </VendorCloseBtn>
                                          </VendorModalHeader>
                                          <VendorTabWrap data-component="VendorTabWrap">
                                            <VendorTabs data-component="VendorTabs" >
                                              <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 1 && 'active'}`} onClick={() => setVendorTab(1)}>Investment</VendorTab>
                                              <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 2 && 'active'}`} onClick={() => setVendorTab(2)}> Recoverable </VendorTab>
                                            </VendorTabs>
                                            {vendorTab == 2 ?
                                              <VendorTabPanel data-component="VendorTabPanel">
                                                <AccordionTabWrapper data-component="AccordionTabWrapper">
                                                  <Accordion expanded={expanded === 'panel1'} onChange={HandleChange('panel1')}>
                                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                      <AccordionHeader data-component="AccordionHeader">
                                                        <AccordImg data-component="AccordImg">
                                                          <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                        </AccordImg>
                                                        <span className="accord-txt">Maintenance Losses</span>
                                                      </AccordionHeader>
                                                      <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <MaintainWrap data-component="MaintainWrap">
                                                        <MaintainRow data-component="MaintainRow">
                                                          <LeftMainCol data-component="LeftMainCol">
                                                            <AccordImg data-component="AccordImg">
                                                              <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                            </AccordImg>
                                                            <span className="accord-txt">Maintenance Losses</span>
                                                          </LeftMainCol>
                                                          <RightMainCol data-component="RightMainCol">
                                                            <span className="main-val">10%</span>
                                                          </RightMainCol>
                                                        </MaintainRow>
                                                        <MaintainRow data-component="MaintainRow">
                                                          <LeftMainCol data-component="LeftMainCol">
                                                            <AccordImg data-component="AccordImg">
                                                              <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                            </AccordImg>
                                                            <span className="accord-txt">Maintenance Losses</span>
                                                          </LeftMainCol>
                                                          <RightMainCol data-component="RightMainCol">
                                                            <span className="main-val">10%</span>
                                                          </RightMainCol>
                                                        </MaintainRow>
                                                      </MaintainWrap>
                                                    </AccordionDetails>
                                                  </Accordion>
                                                  <Accordion expanded={expanded === 'panel2'} onChange={HandleChange('panel2')}>
                                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                      <AccordionHeader data-component="AccordionHeader">
                                                        <AccordImg data-component="AccordImg">
                                                          <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                        </AccordImg>
                                                        <span className="accord-txt">Maintenance Losses</span>
                                                      </AccordionHeader>
                                                      <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                      <MaintainWrap data-component="MaintainWrap">
                                                        <MaintainRow data-component="MaintainRow">
                                                          <LeftMainCol data-component="LeftMainCol">
                                                            <AccordImg data-component="AccordImg">
                                                              <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                            </AccordImg>
                                                            <span className="accord-txt">Line Efficiency Losses</span>
                                                          </LeftMainCol>
                                                          <RightMainCol data-component="RightMainCol">
                                                            <span className="main-val">10%</span>
                                                          </RightMainCol>
                                                        </MaintainRow>
                                                        <MaintainRow data-component="MaintainRow">
                                                          <LeftMainCol data-component="LeftMainCol">
                                                            <AccordImg data-component="AccordImg">
                                                              <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                            </AccordImg>
                                                            <span className="accord-txt">Maintenance Losses</span>
                                                          </LeftMainCol>
                                                          <RightMainCol data-component="RightMainCol">
                                                            <span className="main-val">10%</span>
                                                          </RightMainCol>
                                                        </MaintainRow>
                                                      </MaintainWrap>
                                                    </AccordionDetails>
                                                  </Accordion>
                                                </AccordionTabWrapper>
                                              </VendorTabPanel>
                                              :
                                              <VendorTabPanel data-component="VendorTabPanel">
                                                <ListInvestWrap data-component="ListInvestWrap">
                                                  <ListRow data-component="ListRow">
                                                    <p className='blue-txt'>CAPEX</p>
                                                    <ListValWrap data-component="ListValWrap">
                                                      <span className='money-txt'>375,000</span>
                                                      <span className='money-val'>€</span>
                                                    </ListValWrap>
                                                  </ListRow>
                                                  <ListRow data-component="ListRow">
                                                    <p className='blue-txt'>Starting OPEX</p>
                                                    <ListValWrap data-component="ListValWrap">
                                                      <span className='money-txt'>60,000</span>
                                                      <span className='money-val'>€</span>
                                                    </ListValWrap>
                                                  </ListRow>
                                                  <UpfrontListRow data-component="UpfrontListRow">
                                                    <p className='black-txt'>Upfront Investment</p>
                                                    <ListValWrap data-component="ListValWrap">
                                                      <span className='money-txt'>435,000</span>
                                                      <span className='money-val'>€</span>
                                                    </ListValWrap>
                                                  </UpfrontListRow>
                                                </ListInvestWrap>
                                                <GoListWrap data-component="GoListWrap">
                                                  <p className='blue-txt'>Going OPEX</p>
                                                  <ListValWrap data-component="ListValWrap">
                                                    <span className='money-txt'>60,000</span>
                                                    <span className='money-val'>€</span>
                                                  </ListValWrap>
                                                </GoListWrap>
                                                <UserWrap data-component="UserWrap">
                                                  <span className='user-txt'>No. of Users:</span>
                                                  <span className='user-num'>5</span>
                                                </UserWrap>
                                              </VendorTabPanel>
                                            }
                                          </VendorTabWrap>
                                        </VendorModalWrap>
                                        : ''}
                                    </VendorWrap>
                                  </TabPanel> */}
                                </TabContentWraaper>
                              </Collapse>
                            </RightTabCardWrapper>
                          </MainCardWrapper>
                        </div>
                      ))}
                      <TabButton data-component="TabButton" className="disabled">
                        <TBRow data-component="TBRow">
                          <TBLeftCol data-component="TBLeftCol">
                            <button className="drag-btn disabled">
                              <span>{rightCards.length + 1}</span>
                            </button>
                            <p className='rmap-txt'>
                            </p>
                          </TBLeftCol>
                          <TBRightCol data-component="TBRightCol">


                            <RightAlignTextWrapper data-component="RightAlignTextWrapper">

                            </RightAlignTextWrapper>
                            <AddDragBtn data-component="AddDragBtn">
                              <i dangerouslySetInnerHTML={{ __html: minusIcon }}></i>
                            </AddDragBtn>
                            <CollapseBtn data-component="CollapseBtn">
                              <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>
                            </CollapseBtn>
                          </TBRightCol>
                        </TBRow>
                      </TabButton>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </ContentBody>
              <ContentFooter data-component="ContentFooter">
                <button className='proceed-btn' onClick={() => setOpenPopup(true)}>Proceed</button>
                {openPopup ?
                  <ProceedPopWrapper data-component="ProceedPopWrapper">
                    <ProceedPopCard data-component="ProceedPopCard">
                      <PopHead data-component="PopHead">
                        <span>Caution</span>
                        <button className="close-pop" onClick={() => setOpenPopup(false)}>
                          <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </button>
                      </PopHead>
                      <p className='pop-txt'>You have unsaved changes. Please save roadmap before proceeding.</p>
                      <PopFooter data-component="PopFooter">
                        <button className='save-btn solid' onClick={saveRoadmap}>Save & Proceed</button>
                        <button className='leave-btn transparent' onClick={() => setOpenPopup(false)}>Leave</button>
                      </PopFooter>
                    </ProceedPopCard>
                  </ProceedPopWrapper> : ''}
              </ContentFooter>
            </RightSection>
          </SectionWrapper>
        </MainWrapper>
        {/* Upgrade Wrapper */}
        {/* Roadmap Pricing popup */}
      </DragDropContext>
      {/* How to use popup */}
      <div className="how-to-use-ctrl-wrap">

      </div>
      {/* Technology/Feature Popup */}
      {isTechModal ?
        <TechnologyPopWrapper data-component="TechnologyPopWrapper">
          <TechnologyPopWrap data-component="TechnologyPopWrap>">
            <TechnologyPopHead data-component="TechnologyPopHead">
              <h3>Roadmap Steps : Augmented Reality (AR) Remote Support</h3>
              <button className="close-popup" onClick={() => setIsTechModal(false)}>
                <i dangerouslySetInnerHTML={{ __html: closeTechSvg }}></i>
              </button>
            </TechnologyPopHead>
            <TechnologyPopBody data-component="TechnologyPopBody">
              <TechnologyPopBodyWrap data-component="TechnologyPopBodyWrap">
                <table className="table-wrap">
                  <thead>
                    <tr className="tab-names">
                      <th className="border-curve">Parent Technology </th>
                      <th>Technology</th>
                      <th>Feature</th>
                      <th>Status</th>
                      <th>Industry Adoption</th>
                      <th>Company Adoption</th>
                      <th>BIC Adoption</th>
                      <th>l1</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>3D Printing</td>
                      <td>3D Printing Use</td>
                      <td>NA</td>
                      <td>
                        <p class="status has-not">HAS NOT</p>
                      </td>
                      <td>0%</td>
                      <td>0%</td>
                      <td>3%</td>
                      <td><p class="status has-not">HAS NOT</p></td>
                    </tr>
                    <tr>
                      <td>3D Printing</td>
                      <td>3D Printing Use</td>
                      <td>NA</td>
                      <td>
                        <p class="status has">HAS</p>
                      </td>
                      <td>0%</td>
                      <td>0%</td>
                      <td>3%</td>
                      <td><p class="status has">HAS</p></td>
                    </tr>
                    <tr>
                      <td>3D Printing</td>
                      <td>3D Printing Use</td>
                      <td>NA</td>
                      <td>
                        <p class="status partial">Partially</p>
                      </td>
                      <td>0%</td>
                      <td>0%</td>
                      <td>3%</td>
                      <td><p class="status partial">Partially</p></td>
                    </tr>
                    <tr>
                      <td>3D Printing</td>
                      <td>3D Printing Use</td>
                      <td>NA</td>
                      <td>
                        <p class="status na">NA</p>
                      </td>
                      <td>0%</td>
                      <td>0%</td>
                      <td>3%</td>
                      <td><p class="status na">NA</p></td>
                    </tr>
                  </tbody>
                </table>
              </TechnologyPopBodyWrap>
            </TechnologyPopBody>
          </TechnologyPopWrap>
        </TechnologyPopWrapper>
        : ''
      }
      {isDefaultScenarioPopup &&
        <DefaultScenarioPopupWrapper data-component="DefaultScenarioPopupWrapper">
          <DefaultScenarioPop data-component="DefaultScenarioPop">
            <h2>Would you like to make this your Default Scenario?</h2>
            <p>The default scenario’s progress and results will displayed on Homepage</p>
            <div class="button-section">
              <button class="cancel" onClick={() => { setIsDefaultScenarioPopup(false); makeDefaultRef.current.checked = false; }}>Cancel</button>
              <button class="submit yes" id="making-default-scenario" onClick={yesMakeDefaultFunction}>Yes, make this my Default Scenario </button>
            </div>
          </DefaultScenarioPop>
        </DefaultScenarioPopupWrapper>
      }

      {isLoader &&
        <LoaderPopupWrap data-component="LoaderPopupWrap">
          <LoaderPop data-component="LoaderPop">
            <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
            <h1 style={{ marginTop: '15px', textAlign: 'center', visibility: typeof isLoader == 'string' ? '' : 'hidden' }}>{isLoader}</h1>
          </LoaderPop>
        </LoaderPopupWrap>

      }
      {/* Add Description Popup */}
      {isDescPop &&
        <DescriptionPopupContainer data-component="DescriptionPopupContainer">
          <DescriptionPopupCard data-component="DescriptionPopupCard">
            <DescriptionPopupCardHeader data-component="DescriptionPopupCardHeader">
              <HeaderH1 data-component="HeaderH1">
                <p>Description: 5G</p>
              </HeaderH1>
              <HeaderButtons data-component="HeaderButtons">
                <DescriptionPopupCloseButton data-component="DescriptionPopupCloseButton" onClick={() => setIsDescPop(false)}>
                  <i dangerouslySetInnerHTML={{ __html: descPopCloseSvg }}></i>
                </DescriptionPopupCloseButton>
              </HeaderButtons>
            </DescriptionPopupCardHeader>
            <DescriptionCardPopupBody data-component="DescriptionCardPopupBody">
              <TextAreaWrapper data-component="TextAreaWrapper">
                <div value="0" index="2" class="tab-text-panel">
                  <textarea placeholder="Type your text here..."></textarea>
                </div>
              </TextAreaWrapper>
              <DescriptionPopupFooter data-component="DescriptionPopupFooter">
                <DescriptionPopupBtnWrap data-component="DescriptionPopupBtnWrap">
                  <button className="btn discard-btn">DISCARD</button>
                  <button className="btn save-btn">SAVE</button>
                </DescriptionPopupBtnWrap>
              </DescriptionPopupFooter>
            </DescriptionCardPopupBody>
          </DescriptionPopupCard>
        </DescriptionPopupContainer>
      }
      {/* Add vendor Popup */}
      {isAddVendorPop &&
        <VendorPopWrapper data-component="VendorPopWrapper">
          <VendorPopCard data-component="VendorPopCard">
            <DescriptionPopupCardHeader data-component="DescriptionPopupCardHeader">
              <HeaderH1 data-component="HeaderH1">
                <p>Add Vendor</p>
              </HeaderH1>
              <HeaderButtons data-component="HeaderButtons">
                <DescriptionPopupCloseButton data-component="DescriptionPopupCloseButton" onClick={() => setIsAddVendorPop(false)}>
                  <i dangerouslySetInnerHTML={{ __html: descPopCloseSvg }}></i>
                </DescriptionPopupCloseButton>
              </HeaderButtons>
            </DescriptionPopupCardHeader>
            <VendorPopBody data-component="VendorPopBody">
              <VendorPopRow data-component="VendorPopRow">
                <VendorPopLeft data-component="VendorPopLeft">
                  <span className="title">Vendor Image</span>
                  <div className="uploaded-file-content" >
                    <div className="file-img-wrap" >
                      {Object.keys(fileAttachment).length > 0 ? (
                        <img src={fileAttachment.url} alt={fileAttachment.name} />
                      )
                        :
                        <i dangerouslySetInnerHTML={{ __html: fileSvg }}></i>
                      }
                    </div>
                  </div>
                  <label className="attachment-input-wrap">
                    <div className="attachment-input">
                      <button className="upload-btn" onClick={() => document.getElementById('fileInput').click()}>{Object.keys(fileAttachment).length > 0 ? `Re-upload` : `Upload`}</button>
                      <input id="fileInput" placeholder="Add files" type="file" multiple style={{ width: '0px', height: '0px', transform: 'scale(0)', overflow: 'hidden', display: 'none' }} onChange={(e) => handleFileAttachment(e)} />
                    </div>
                  </label>

                </VendorPopLeft>
                <VendorPopRight data-component="VendorPopRight">
                  <LabelWrap data-component="LabelWrap">
                    <label className="label-card">
                      <span className="label-title">Vendor Name</span>
                      <input placeholder="Enter vendor name" />
                    </label>
                    <label className="label-card">
                      <span className="label-title">Vendor Webpage</span>
                      <input placeholder="Enter vendor webpage link (URL)" />
                    </label>
                  </LabelWrap>
                </VendorPopRight>
              </VendorPopRow>
              <DescriptionPopupFooter data-component="DescriptionPopupFooter">
                <DescriptionPopupBtnWrap data-component="DescriptionPopupBtnWrap">
                  {isVendorAddBtn &&
                    <>
                      <button className="btn discard-btn">DISCARD</button>
                      <button className="btn save-btn" onClick={() => handleAddVendor()}>ADD Vendor</button>
                    </>
                  }
                  {isVendorDataSavedBtn &&
                    <AddVendorBtn data-component="AddVendorBtn">
                      <i dangerouslySetInnerHTML={{ __html: greenCheckSvg }}></i>
                      <span>Vendor Added</span>
                    </AddVendorBtn>
                  }
                </DescriptionPopupBtnWrap>
              </DescriptionPopupFooter>
            </VendorPopBody>
          </VendorPopCard>
        </VendorPopWrapper>
      }
    </>
  );
};

// export default DragDropContainer;


const Dropdown = memo((props) => {
  const { options, defaultSelection = false, getValueOnChange = () => { }, disabled } = props || {}
  const [selected, setSelected] = useState(false);
  const handleList = (e) => {
    let targetElement = e?.target;
    let mainParent = targetElement?.parentElement;
    let optionsList = mainParent.querySelector('.selection-options');
    let singleOptions = optionsList.querySelectorAll('.selection-option');
    let input = mainParent.querySelector('.store-value-input');
    let openOptionList = document.querySelector('.dropdown-wrapper.show-options');
    if (!mainParent.classList.contains('show-options')) {
      openOptionList?.classList?.remove('show-options');
    }
    mainParent?.classList?.toggle('show-options');
    singleOptions?.forEach(li => {
      li.addEventListener('click', function (e) {
        input.setAttribute("value", e.target.dataset.value);
        let tmpObj = {
          id: e.target.dataset.value,
          name: e.target.dataset.name
        };
        setSelected(prv => tmpObj);
        getValueOnChange(tmpObj);
        mainParent.classList.remove('show-options');
      })
    });
  }
  useEffect(() => {
    setSelected(defaultSelection);
    document.addEventListener('click', function (e) {
      if (!e.target.closest('.dropdown-wrapper')) {
        let openOptionList = document.querySelector('.dropdown-wrapper.show-options');
        openOptionList?.classList?.remove('show-options');
      }
    });
  }, []);

  return (
    <>
      <div className={`dropdown-wrapper ${disabled ? 'disabled' : ''}`}>
        <input type="hidden" name="" value="select-1" className="store-value-input" />
        <i className="ddl-icon" dangerouslySetInnerHTML={{ __html: dimentionsTabDdlIcon }}></i>
        <button className="option-toggle-btn" onClick={handleList}>{selected?.name || 'Select'} </button>
        <ul className="selection-options">
          {options?.map((item, index) => (
            item.id ?
              <li key={index} data-value={item.id} data-name={item.name} className={`selection-option ${selected?.id == item.id ? 'selected' : ''}`}>
                {item.name}
                {selected?.id == item.id ? <i dangerouslySetInnerHTML={{ __html: dimentionsSelctedIcon }}></i> : <></>}
              </li>
              :
              <>
              </>
          ))
          }
        </ul>
      </div >
    </>
  )
});