import styled from "styled-components";


export const MainWrapper = styled.div`
        padding:3rem 3rem 3rem 3rem;
        max-width:58rem;
        color:#4D4D4F;
        *{
            line-height:150%;
        }
        @media(max-width: 768px){
            padding-bottom: 60px;
        }
        @media(max-width: 740px){
            padding-bottom: 100px;
        }
`;

export const H1 = styled.h1`
    color:#4D4D4F;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 150%;
    margin-bottom:2rem;
`;

export const Row = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:#4D4D4F;
    font-size: 1.6rem;
    font-weight: 400;
    gap:1rem;
    border-bottom:0.1rem solid #F0F0F0;
    padding:1.2rem 0rem; 
    @media(max-width: 740px){
        font-size: 14px;
    }
    
`
export const P = styled.p`
    position:relative;
    .info-icon-i{
            display:inline-flex;
            top:0.4rem;
            margin-left:0.4rem;
            cursor:pointer;
            position:relative;
            svg{
                width:1.6rem;
                *{
                    transition:0.25s;
                }
                .circle{
                    fill:#fff;
                }
            }
            &:hover,&.active{
                svg{
                    .circle{
                        fill:#118acb;
                    }
                    .line-i{
                        stroke:#fff;
                    }
                }
            }
        }
`;

export const InfoTooltip = styled.div`
    position:absolute;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: -0.028rem;
    background:#fff;
    z-index:1;
    width:30.8rem;
    top:0;
    right:-1rem;
    transform:translateX(100%);
    filter: drop-shadow(0px 0px 8px rgba(21, 27, 38, 0.12));
    padding:1.6rem;
    border-radius:0.8rem;
    visibility:hidden;
    opacity:0;
    transition:all 0.2s;
    max-height: 300px;
    overflow: auto;
    &.active{
        visibility:visible;
        opacity:1;
    }
    @media(max-width:740px){
        max-height: 200px;
        right: unset;
        transform: translateY(${({ lastRow }) => lastRow ? '-100%' : '30px'});
        left: 0;
    }
`;

export const InputWrapper = styled.div`
    input{
        padding:0.6rem 0.8rem;
        border-radius:0.4rem;
        border: 0.4px solid #7D868C;
        background: #FFF;
        width:8rem;
        height:3.2rem;
        text-align:right;
        outline:0;
        &:placeholder{
            color:#CBCFD2;
        }
        &:disabled{
            border-width: 0;
            background: #f4f4f4;
        }
    }
`;

export const SubmitButton = styled.button`
    display:inline-flex;
    align-items:center;
    justify-content:center;
    color: #FFF;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    position:fixed;
    bottom:9.7rem;
    right:3rem;
    min-width:29.7rem;
    min-height:6rem;
    padding:0.5rem;
    background:${({ disabled }) => disabled ? '#CBCFD2' : '#118ACB'};
    cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    border-radius:0.5rem;
    z-index:10;
    @media(max-width:768px){
        bottom: 0px;
        width: calc(100% - 30px);
        left: 50%;
        transform: translateX(-50%);
    }
`
export const AfterSubmitButton = styled.button`
    min-width: 30.7rem;
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
    justify-content: center;
    background: #E5F5F1;
    min-height: 6rem;
    position:fixed;
    bottom: 9.7rem;
    gap: 1.2rem;
    right:3rem;
    i{
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    span{
        color: #43A047;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: normal;
    }
    @media(max-width: 768px){
        width: calc(100% - 30px);
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        bottom: 0;
    }
`

export const ErrorMsgDiv = styled.div`
       position:fixed;
       top:100px;
       max-width:100%;
       width:280px;
       height:80px;
       left:50%;
       top:50%;
       box-shadow:0rem 0.168rem 0.672rem rgba(21, 27, 38, 0.1);
       transform:translate(-50%,-50%);
       opacity:${({ active }) => active ? '1' : '0'};
       visibility:${({ active }) => active ? 'visible' : 'hidden'};
       transition:0.25s ease-in;
       z-index:9999;
       background:#fff;
       border-radius:5px;
       padding:15px 20px;
       border:1px solid currentColor;
       color:${({ green }) => green ? '#4caf50' : '#ff8383'};
       font-size:14px;
       font-weight:500;
       display:flex;
       text-align:center;
       align-items:center;
       gap:10px;
       p{
        margin-bottom:0px;
        flex:1;
        color:inherit;
        font:inherit;
        font-weight:inherit;
       }
`;