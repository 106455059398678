/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * Helmet ===> for add specific title & CSS for the page.

**/
import React from 'react';
import {Helmet} from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
//EadgeCaseView component start
export const EadgeCaseView = () => {
    return (
        <>
            {/* specific css for this page */}
            <Helmet>
                <link rel="stylesheet" href={require('./edge.scss').default} />
            </Helmet>
            {/* Breadcrumbs */}
            <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">Error Page</BreadcrumbsItem>
            {/* Main wrapper */}
            <div className='edge-case-main'>
                {/* Content section taht has image and text */}
                <div className='edge-case-content'>
                    <span className='lock-svg' dangerouslySetInnerHTML={{__html:lockSvg}}></span>
                    <p>The content you are looking for is no longer available.</p>
                </div>
            </div>
        </>
    )
}

// Defining locksvg variable
const lockSvg = `<svg width="82" height="103" viewBox="0 0 82 103" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_624_68063)">
<path d="M19 40.9782V43.5657H28.5544V36.4781C28.5544 29.0293 28.7223 20.7801 34.2584 15.7966C36.8192 13.4914 40.4413 11.6573 45.5 11.5926C51.389 11.5172 55.3636 14.2233 57.9882 17.3644C62.466 22.7231 62.6259 30.2115 62.6259 37.1947V43.5657H72V36.9278C72 29.1821 71.5602 21.1918 67.7456 14.4506C66.3883 12.0522 64.6897 9.76626 62.6259 8.21765C57.3124 4.2306 52.1786 1.95588 45.5 2.00065C38.9548 2.04452 34.1863 3.74964 28.9835 8.21765C27.9508 9.1045 26.9854 10.1682 26.0935 11.3253C19.7394 19.569 19 30.5699 19 40.9782Z" fill="#7D868C"/>
<path d="M21.7259 36.8186V44H28.5736V32.9488C28.5736 27.9987 28.6959 22.8965 31.0115 18.5214C33.442 13.929 38.1367 8.80122 46.9543 9.00055C55.779 9.20002 60.5299 14.6801 63.0083 19.5019C65.3565 24.0704 65.5152 29.3493 65.5152 34.486V44H72.1827V36.5138C72.1827 29.2023 71.7598 21.7101 68.4496 15.1908C67.1082 12.549 65.3874 9.9787 63.2584 8.28317C58.1999 4.25453 53.3125 1.95608 46.9543 2.00131C40.7232 2.04564 35.8097 4.37707 30.8219 8.28317C28.7269 9.92377 27.017 12.3893 25.6684 14.9461C22.1636 21.5914 21.7259 29.3057 21.7259 36.8186Z" fill="#E6E6E6"/>
<rect x="10" y="43.0743" width="70" height="49.9257" fill="#F58B00"/>
<rect x="16.3959" y="43.0743" width="63.6041" height="49.9257" fill="#FFBC1F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.4823 69.9257C50.9457 68.7112 52.6396 66.1812 52.6396 63.257C52.6396 59.1504 49.2988 55.8213 45.1777 55.8213C41.0566 55.8213 37.7157 59.1504 37.7157 63.257C37.7157 66.1856 39.4147 68.7188 41.8841 69.9311L40.5584 79.0137H49.9746L48.4823 69.9257Z" fill="#0D2C54"/>
</g>
<defs>
<filter id="filter0_d_624_68063" x="0" y="0" width="82" height="103" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="-4" dy="4"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_624_68063"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_624_68063" result="shape"/>
</filter>
</defs>
</svg>`