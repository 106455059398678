/** imported components

  * Helmet ===> for add specific title & CSS for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React from "react";
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const LossAnalysisAdminPageView = (props) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (!e.target.closest(".la-assign-cir-wrapper")) {
                setIsShown(false);
            }
        })
    }, [])

    return (
        <>
        {/* css for styling page */}
        <Helmet>
            <link href={require("./loss-analysis-admin-page.scss").default} />
        </Helmet>
            {/* Loss Analysis main wrapper Start */}
            <div className="loss-analysis-category-wapper admin">
                 {/* row has been divided into four parts
                    heading-btn-wrap
                    md-col-12
                    md-col-8
                    md-col-4
                */}
                <div className="loss-analysis-category-row">
                    {/* this wrapper has two elements
                        page-heading
                        button-wrap
                    */}
                    <div className="heading-btn-wrap">
                        <h1 className="page-heading">Choose a loss analysis category below: </h1>
                        <div className="button-wrap">
                            <button className="view-btn btn">View Setup Page</button>
                            <a href="" target="_blank" className="schedule-btn btn">Download Export</a>
                            <button className="submitted-btn btn hide-class">
                                <img className="check" src={require("../../assets/img/la-admin/checked.svg").default} alt="production" />Data Submitted
                            </button>
                        </div>
                    </div>
                    {/* in this section has 
                        button 
                        popup
                        progressbar
                    */}
                    <div className="loss-analysis-category-col md-col-12 border-bottom padding-left-0 padding-r0">
                        <div className="loss-category-progress-wrap">
                            <div className="loss-analysis-category-button-wrap blue">
                                <div className="img-wrap">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/production.svg")
                                                .default
                                        }
                                        alt="production"
                                    />
                                </div>
                                <span className="text">Prouction Process Level</span>
                                <div class="note">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/notify.svg")
                                                .default
                                        }
                                        alt="Notes"
                                    />
                                </div>
                                <div className="card-note-popup">
                                    <div className="card-note-tooltip-wrap dark-blue">
                                        <div className="card-note-tooltip">
                                            <h2>Production Process Level</h2>
                                            <p>The average time that the production process or production line is not
                                                operating due to planned non-production activities that need to take place. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overall-progress-bar">
                                <span className="text">Overall Progress:</span>
                                <div className="progress-wrap">
                                    <span className="percent-value">0%</span>
                                    <div className="progress">
                                        <div className="bar"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     {/* This section has two parts 
                        loss-analysis-category-button-wrap 
                        loss-cat-card-row
                    */}
                    <div className="loss-analysis-category-col md-col-8">
                        <div className="loss-analysis-category-button-wrap disabled-btn gray">
                            <div className="left-text-wrap">
                                <span className="text">Line Efficiency Losses</span>
                                <div class="note">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/disabled.svg")
                                                .default
                                        }
                                        alt="Disabled"
                                    />
                                    <div className="card-note-popup">
                                        <div className="card-note-tooltip-wrap dark-gray">
                                            <div className="card-note-tooltip">
                                                <h2>Line Efficiency Losses</h2>
                                                <p>Employees (headcount) working as direct labor
                                                    (line operators, and technicians) on the production lines.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="la-assign-cir-wrapper wrap">
                                <div className="la-assign-cir-group">
                                    <div className="la-assign-cir-wrapper">
                                        <button className="la-assign" onClick={() => setIsShown(!isShown)}>
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-admin/assign-icon.svg').default} alt="assign" />
                                            </div>
                                            <div className="la-profile-cir-ul">
                                                <div className="la-profile-cir cir-a">
                                                    <span>Ab</span>
                                                    {/* <img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                </div>
                                                <div className="la-profile-cir cir-c">
                                                    <span>ca</span>
                                                </div>
                                                <div className="la-profile-cir cir-d">
                                                    <span>da</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                                <div className={`assigned-user-popup ${isShown && 'active'}`}>
                                    <span className="tooltip"></span>
                                    <div className="assignd-user-popup-wrap">
                                        <div className="pop-wrap">
                                            <div className="img-box">
                                                <div className="image-div">
                                                    <span className="us-name">AB</span>
                                                </div>
                                            </div>
                                            <div className="user-name">
                                                <span className="name">Anette Black</span>
                                                <span className="designation">Team Leader</span>
                                            </div>
                                        </div>
                                        <div className="pop-wrap">
                                            <div className="img-box">
                                                <div className="image-div">
                                                    <span className="us-name">AB</span>
                                                </div>
                                            </div>
                                            <div className="user-name">
                                                <span className="name">Anette Black</span>
                                                <span className="designation">Team Leader</span>
                                            </div>
                                        </div>
                                        <div className="pop-wrap">
                                            <div className="img-box">
                                                <div className="image-div">
                                                    <span className="us-name">AB</span>
                                                </div>
                                            </div>
                                            <div className="user-name">
                                                <span className="name">Anette Black</span>
                                                <span className="designation">Team Leader</span>
                                            </div>
                                        </div>
                                        <div className="pop-wrap edit">
                                            <button className="edit-team">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                            .default
                                                    }
                                                    alt="Assign User" className="assign-user"
                                                />
                                                <span className="name">Edit Team</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* divided further into columns */}
                        <div className="loss-cat-card-row line">
                            <div className="loss-cat-card-col mb-1 padding-left-0">
                                {/* This section has three parts
                                    header
                                    card-body
                                    card-footer
                                    common structure for all loss-cat-card cards
                                */}
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/plan.svg")
                                                        .default
                                                }
                                                alt="Planned"
                                            />
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Planned Downtime</h2>
                                                        <p>The time a production process or line is not operating due
                                                            to planned non-production activities that need to take place.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Planned Downtime</span>
                                            <a href="#" className="btn-link">+ Continue Section</a>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                            <Link to="/" className="bottom-btn-link green completed">COMPLETED</Link>
                                            <Link to="/" className="bottom-btn-link green review">IN REVIEW</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <div className="img-box">
                                                    <div className="image-div">
                                                        <span className="us-name">AB</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap">
                                                        <div className="img-box">
                                                            <div className="image-div">
                                                                <span className="us-name">AB</span>
                                                            </div>
                                                        </div>
                                                        <div className="user-name">
                                                            <span className="name">Anette Black</span>
                                                            <span className="designation">Team Leader</span>
                                                        </div>
                                                    </div>
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/unplanned-downtime.svg")
                                                        .default
                                                }
                                                alt="UnPlanned"
                                            />
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Unplanned Downtime</h2>
                                                        <p>The time a production process or line is not operating due to unplanned events.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Unplanned Downtime</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <div className="img-box">
                                                    <div className="image-div">
                                                        <span className="us-name">AB</span>
                                                    </div>
                                                </div>

                                                <div className="img-box">
                                                    <div className="image-div">
                                                        <span className="us-name">AB</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap">
                                                        <div className="img-box">
                                                            <div className="image-div">
                                                                <span className="us-name">AB</span>
                                                            </div>
                                                        </div>
                                                        <div className="user-name">
                                                            <span className="name">Anette Black</span>
                                                            <span className="designation">Team Leader</span>
                                                        </div>
                                                    </div>
                                                    <div className="pop-wrap">
                                                        <div className="img-box">
                                                            <div className="image-div">
                                                                <span className="us-name">AB</span>
                                                            </div>
                                                        </div>
                                                        <div className="user-name">
                                                            <span className="name">Anette Black</span>
                                                            <span className="designation">Team Leader</span>
                                                        </div>
                                                    </div>
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1 padding-r0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/minor-stops.svg")
                                                        .default
                                                }
                                                alt="Minor"
                                            />
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Minor Stops</h2>
                                                        <p>The time the production process or line is not operating due to
                                                            small stops (usually with a duration of less than 5 minutes).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Minor Stops</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <div className="img-box">
                                                    <div className="image-div">
                                                        <span className="us-name">AB</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap">
                                                        <div className="img-box">
                                                            <div className="image-div">
                                                                <span className="us-name">AB</span>
                                                            </div>
                                                        </div>
                                                        <div className="user-name">
                                                            <span className="name">Anette Black</span>
                                                            <span className="designation">Team Leader</span>
                                                        </div>
                                                    </div>
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col padding-left-0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/speed-losses.svg")
                                                        .default
                                                }
                                                alt="Speed Losses"
                                            />
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Planned Downtime</h2>
                                                        <p>The time a production process or line is not operating due
                                                            to planned non-production activities that need to take place.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-note-popup blue">
                                            <div className="card-note-tooltip-wrap">
                                                <div className="card-note-tooltip">
                                                    <h2>Speed Losses</h2>
                                                    <p>The time the production process or line is not operating
                                                        due to small stops (usually with a duration of less than 5 minutes).</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Speed Losses</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <div className="img-box">
                                                    <div className="image-div">
                                                        <span className="us-name">AB</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap">
                                                        <div className="img-box">
                                                            <div className="image-div">
                                                                <span className="us-name">AB</span>
                                                            </div>
                                                        </div>
                                                        <div className="user-name">
                                                            <span className="name">Anette Black</span>
                                                            <span className="designation">Team Leader</span>
                                                        </div>
                                                    </div>
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/material-waste.svg")
                                                        .default
                                                }
                                                alt="Material Waste"
                                            />
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Material Waste</h2>
                                                        <p>The cost of units scrapped during the production process leading to material waste.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Material Waste</span>
                                            <a href="#" className="btn-link">+ Start Section</a>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <div className="img-box">
                                                    <div className="image-div">
                                                        <span className="us-name">AB</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap">
                                                        <div className="img-box">
                                                            <div className="image-div">
                                                                <span className="us-name">AB</span>
                                                            </div>
                                                        </div>
                                                        <div className="user-name">
                                                            <span className="name">Anette Black</span>
                                                            <span className="designation">Team Leader</span>
                                                        </div>
                                                    </div>
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* This section has two parts 
                        loss-analysis-category-button-wrap 
                        loss-cat-card-row
                    */}
                    <div className="loss-analysis-category-col md-col-4 padding-r0">
                        <div className="loss-analysis-category-button-wrap disabled-btn gray padding-r0">
                            <div className="left-text-wrap">
                                <span className="text">Operator Effort Losses</span>
                                <div class="note">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/disabled.svg")
                                                .default
                                        }
                                        alt="Disabled"
                                    />
                                    <div className="card-note-popup">
                                        <div className="card-note-tooltip-wrap dark-gray">
                                            <div className="card-note-tooltip">
                                                <h2>Operator Effort Losses</h2>
                                                <p>Employees (headcount) working as direct labor
                                                    (line operators, and technicians) on the production lines.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="la-assign-cir-wrapper wrap">
                                <div className="la-assign-cir-group">
                                    <div className="la-assign-cir-wrapper">
                                        <button className="la-assign">
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-questionnaires/assign-icon.svg').default} alt="assign" />
                                            </div>
                                            <span className="assign-txt">Assign</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="assigned-user-popup">
                                    <span className="tooltip"></span>
                                    <div className="assignd-user-popup-wrap">
                                        <div className="pop-wrap edit">
                                            <button className="edit-team">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                            .default
                                                    }
                                                    alt="Assign User" className="assign-user"
                                                />
                                                <span className="name">Edit Team</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loss-cat-card-row operator row-padding right-col line">
                            <div className="loss-cat-card-col padding-left-0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/operator-losses.svg")
                                                        .default
                                                }
                                                alt="Operator -Losses"
                                            />
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Operator Losses</h2>
                                                        <p>The time a production process or line is not operating
                                                            due to planned non-production activities that need to take place.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Operator Losses</span>
                                            <a href="#" className="btn-link continue">+ Continue Section</a>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Second row starts */}
                <div className="loss-analysis-category-row">
                    <div className="loss-analysis-category-col md-col-8">
                        <div className="loss-analysis-category-col border-bottom margin-left">
                            <div className="loss-analysis-assign-wrap">
                                <div className="loss-analysis-category-button-wrap green">
                                    <div className="img-wrap">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/key-plant.svg")
                                                    .default
                                            }
                                            alt="production"
                                        />
                                    </div>
                                    <span className="text">Key Plant Functions</span>
                                    <div class="note">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/note-green.svg")
                                                    .default
                                            }
                                            alt="Notes"
                                        />
                                    </div>
                                    <div className="card-note-popup">
                                        <div className="card-note-tooltip-wrap dark-green">
                                            <div className="card-note-tooltip">
                                                <h2>Key Plant Functions</h2>
                                                <p>The average time that the production process or production
                                                    line is not operating due to planned non-production activities that need to take place. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="la-assign-cir-wrapper wrap">
                                    <div className="la-assign-cir-group">
                                        <div className="la-assign-cir-wrapper">
                                            <button className="la-assign">
                                                <div className="img-div">
                                                    <img src={require('../../assets/img/la-questionnaires/assign-icon.svg').default} alt="assign" />
                                                </div>
                                                <span className="assign-txt">Assign</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="assigned-user-popup">
                                        <span className="tooltip"></span>
                                        <div className="assignd-user-popup-wrap">
                                            <div className="pop-wrap edit">
                                                <button className="edit-team">
                                                    <img
                                                        src={
                                                            require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                .default
                                                        }
                                                        alt="Assign User" className="assign-user"
                                                    />
                                                    <span className="name">Edit Team</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loss-cat-card-row bottom">
                            <div className="loss-cat-card-col mb-1 padding-left-0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/maintenance-losses.svg")
                                                        .default
                                                }
                                                alt="Maintenance Losses"
                                            />
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>Maintenance Losses</h2>
                                                        <p>The key routine activities taking place by the Maintenance or
                                                            Engineering function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Maintenance Losses</span>
                                            <a href="#" className="btn-link">+ Continue Section</a>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/it-losses.svg")
                                                        .default
                                                }
                                                alt="IT Losses"

                                            />
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>IT Losses</h2>
                                                        <p>The key routine activities taking place by the IT function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">IT Losses</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1 padding-r0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/warehouse-losses.svg")
                                                        .default
                                                }
                                                alt="Warehouse-Losses"
                                            />
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>Warehouse Losses</h2>
                                                        <p>The key routine activities taking place by the Warehouse or
                                                            Material Handling function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Warehouse Losses</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col padding-left-0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/EHS-losses.svg")
                                                        .default
                                                }
                                                alt="EHS Losses"
                                            />
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>EHS Losses</h2>
                                                        <p>The key routine activities taking place by the EHS function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">EHS Losses</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/quality-losses.svg")
                                                        .default
                                                }
                                                alt="Quality"
                                            />
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>Quality Losses</h2>
                                                        <p>The key routine activities taking place by
                                                            the Quality function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Quality Losses</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="loss-analysis-category-col md-col-4">
                        <div className="loss-analysis-category-col border-bottom margin-left">
                            <div className="loss-analysis-assign-wrap">
                                <div className="loss-analysis-category-button-wrap orange">
                                    <div className="img-wrap">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/finance-data.svg")
                                                    .default
                                            }
                                            alt="finance-data"
                                        />
                                    </div>
                                    <span className="text">Finance Data</span>
                                    <div class="note">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/note-orange.svg")
                                                    .default
                                            }
                                            alt="Notes"
                                        />
                                    </div>
                                    <div className="card-note-popup">
                                        <div className="card-note-tooltip-wrap dark-orange">
                                            <div className="card-note-tooltip">
                                                <h2>Finance Data</h2>
                                                <p>The average time that the production process or production line is not
                                                    operating due to planned non-production activities that need to take place. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="la-assign-cir-wrapper wrap">
                                    <div className="la-assign-cir-group">
                                        <div className="la-assign-cir-wrapper">
                                            <button className="la-assign">
                                                <div className="img-div">
                                                    <img src={require('../../assets/img/la-questionnaires/assign-icon.svg').default} alt="assign" />
                                                </div>
                                                <span className="assign-txt">Assign</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="assigned-user-popup">
                                        <span className="tooltip"></span>
                                        <div className="assignd-user-popup-wrap">
                                            <div className="pop-wrap edit">
                                                <button className="edit-team">
                                                    <img
                                                        src={
                                                            require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                .default
                                                        }
                                                        alt="Assign User" className="assign-user"
                                                    />
                                                    <span className="name">Edit Team</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="loss-cat-card-row bottom row-padding right-col">
                            <div className="loss-cat-card-col mb-1 padding-left-0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/general-information.svg")
                                                        .default
                                                }
                                                alt="general-information"
                                            />
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap orange">
                                                    <div className="card-note-tooltip">
                                                        <h2>General Information</h2>
                                                        <p>The average time the production process or production line is not
                                                            operating due to small stops (usually with a duration of less that 5 minutes).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">General Information</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1 padding-r0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/FTE-breakdown.svg")
                                                        .default
                                                }
                                                alt="FTE-breakdown"

                                            />
                                            <div className="card-note-popup middle">
                                                <div className="card-note-tooltip-wrap orange">
                                                    <div className="card-note-tooltip">
                                                        <h2>FTE breakdown</h2>
                                                        <p>The average time the production process or production line is not
                                                            operating due to small stops (usually with a duration of less that 5 minutes).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">FTE breakdown</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col padding-left-0">
                                <div className="loss-cat-card">
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/manufacturing-cost.svg")
                                                        .default
                                                }
                                                alt="Manufacturing Cost"
                                            />
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap orange">
                                                    <div className="card-note-tooltip">
                                                        <h2>Total Manufacturing Cost</h2>
                                                        <p>The key financial figures that constitute the Total Manufacturing
                                                            Cost of the Plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Manufacturing Cost</span>
                                        </div>
                                    </div>
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <Link to="/" className="bottom-btn-link red">To Do</Link>
                                        </div>
                                        <div className="right-section">
                                            <div className="assigned-user-div">
                                                <img
                                                    src={
                                                        require("../../assets/img/la-admin/assign-icon.svg")
                                                            .default
                                                    }
                                                    alt="One user"
                                                />
                                            </div>
                                            <div className="assigned-user-popup">
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    <div className="pop-wrap edit">
                                                        <button className="edit-team">
                                                            <img
                                                                src={
                                                                    require("../../assets/img/la-questionnaires/assign-userl.svg")
                                                                        .default
                                                                }
                                                                alt="Assign User" className="assign-user"
                                                            />
                                                            <span className="name">Edit Team</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*POPUP HTML starts here*/}
                {/* congrats popup starts */}
                <div className="congrats-popup active hide-class">
                    <div className="popup-wrapper congrats">
                        <div className="close-btn-wrap">
                            <button className="close-btn">
                                <img src={require("../../assets/img/la-admin/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-admin/congrats.gif").default} alt="Congrats" className="congrats" />
                            </div>
                            <div className="content-box">
                                <h2 className="heading">Congratulations!</h2>
                                <p className="text">All Loss Analysis Sections have been successfully completed. Now you can Submit your Data to SmarterChains.</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn review-button primary">I’ll review first</button>
                                <button type="submit" className="btn submit-data secondary">Submit Data</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* submit popup starts */}
                <div className="submit-popup active hide-class">
                    <div className="popup-wrapper submitdata-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn">
                                <img src={require("../../assets/img/la-admin/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="content-box">
                                <h2 className="heading">Submiting Data</h2>
                                <p className="text">Upon submitting, you will not be able to edit the Plant's Loss data.</p>
                                <p className="text">Do you want to proceed and submit your data?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn cancel-button primary">Cancel</button>
                                <button type="submit" className="btn submit-btn secondary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* final submit popup starts */}
                <div className="final-submit-popup active hide-class">
                    <div className="popup-wrapper final-submit">
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-admin/submit-pop-img.gif").default} alt="Congrats" className="congrats" />
                            </div>
                            <div className="content-box">
                                <p className="text">Submitting and uploading your data...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
