import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ScenarioDropdown from "./ScenarioDropdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { HowToUseCtrl } from '../roadmap-how-to-use/HowToUseCtrl'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Redirect, Link, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios';

// Images that we have used on page
import {
  filterSvg,
  closeSvg,
  dragIcon,
  addBtn,
  collapseIcon,
  collapseOpenIcon,
  minusIcon,
  digitalSvg,
  vendorCloseSvg,
  accordSvg,
  checkboxSvg,
  radioBtnSvg,
  closeTechSvg,
  blackSvg,
  upgradeLockIcon,
  siriArrowSvg,
} from "./svg-step1";
// Components that we have used in page
import {
  AccordionHeader,
  SaveRoadmapButton,
  HeaderFilterWrapper,
  ButtonWrapper,
  TopSectionBtn,
  FilterWrapper,
  FilterButton,
  FilterBox,
  FilterCloseButton,
  FilterBoxContent,
  FilterBoxContentTitle,
  FilterCheckBoxWrapper,
  FilterBoxLable,
  FilterBoxContentWrap,
  FilterBoxInput,
  FilterButtonWrapper,
  ResetFilterButton,
  ApplyFilterButton,
  MainCardWrapper,
  TabCardWrapper,
  TabContentWraaper,
  NAButton,
  RightAlignTextWrapper,
  RightAlignTextWrap,
  HeadText,
  SubText,
  AddDragBtn,
  CollapseBtn,
  TabPanelContent,
  VendorWrap,
  VendorRow,
  VendorCol,
  VendorCard,
  VendorText,
  InnerTabWrapper,
  InnerTBLeftCol,
  InnerTBRightCol,
  LossTableWrap,
  LossScrollTableWrap,
  VendorClickImg,
  VendorModalWrap,
  VendorModalHeader,
  VendorCloseBtn,
  VendorTabWrap,
  VendorTabs,
  VendorTab,
  VendorTabPanel,
  AccordionTabWrapper,
  AccordImg,
  MaintainWrap,
  MaintainRow,
  LeftMainCol,
  RightMainCol,
  ListInvestWrap,
  ListRow,
  UpfrontListRow,
  ListValWrap,
  UserWrap,
  GoListWrap,
  RightTabCardWrapper,

  TechnologyPopWrapper,
  TechnologyPopWrap,
  TechnologyPopHead,
  TechnologyPopBody,
  TechnologyPopBodyWrap,
  UpgradeLock,
  FilterTabWrap,
  FilterTabContentWrap,
  FilterTab,
  FilterSiriTabContentWrap,
  DimensionTab,
} from "./Roadmap-style-step1";

import {
  MainWrapper,
  SectionWrapper,
  LeftSection,
  RightSection,
  SectionHeader,
  ContentBody,
  ContentFooter,
  FilterBoxHeader,
  HeaderTitle,
  ScenarioSectionWrapper,
  TabButton,
  TBRow,
  TBLeftCol,
  TBRightCol,
  LossWrap,
  LossCol,
  LossRow,
  ProceedPopWrapper,
  ProceedPopCard,
  PopHead,
  PopFooter,
  QNAWrap,
  QNACard,
  QNABtn,
  DefaultScenarioPop,
  DefaultScenarioPopupWrapper,
  LoaderPopupWrap,
  LoaderPop,
} from "./common-roadmap-style";

export const RoadmapStepFull1View = (props) => {
  const stepdata = props.stepData;
  const user = JSON.parse(localStorage.getItem("userData"));
  const uid = user ? user.uid : 0;
  const history = useHistory();
  //variable declaration
  const [showFilter, setShowFilter] = useState(false);
  const [checkedFilter, setCheckedFilter] = useState([]);
  const [scenarioList, setScenarioList] = useState(stepdata.getAllScenario);
  const [isMakeDefault, setIsMakeDefault] = useState(false);
  const [rightCards, setRightCards] = useState(stepdata.usernodes);
  // Collapse
  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [qnaOpen, setQnaOpen] = React.useState(false);
  // Collapse Card
  const [lossLeftDataOpen, setLossLeftDataOpen] = React.useState(false);
  const [lossRightDataOpen, setLossRightDataOpen] = React.useState(false);
  const [openUpgradePop, setOpenUpgradePop] = useState(true)
  //vendor tabs
  const [vendorTab, setVendorTab] = useState(1)
  const [isVendorModal, setIsVendorModal] = useState(false);
  const [isVendorRightModal, setIsVendorRightModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isTechModal, setIsTechModal] = useState(false);
  const [infoPopOpen, setInfoPopOpen] = useState(true);
  const [showUpgradePrice, setShowUpgradePrice] = useState(true);
  const [showSmRightSection, setShowSmRightSection] = useState(false);
  // Card array declaration
  const [leftCards, setLeftCards] = useState([]);
  //const [draggingData, setDraggingData] = useState('');
  //filter function
  const [leftValue, setLeftValue] = React.useState(`l0-0`);
  const [rightValue, setRightValue] = React.useState(`r0-0`);
  // Siri Filter Variables
  const [isActive, setIsActive] = useState(2);
  const [isDefaultScenarioPopup, setIsDefaultScenarioPopup] = useState(false);

  //var filter code 24-11-2023
 
  const [selectedFilterObj, setSelectedFilterObj] = useState({
    type: [],//props.filter5
    technology: [],//props.filter1
    recoverables: props.filter4,
    investment: props.filter3,
    dimension: [],//props.filter
    lossCategories: []////props.filter2
  });
  const [applyFilterObj, setApplyFilterObj] = useState({
    type: [],
    technology: [],
    recoverables: 0,
    investment: 0,
    dimension: [],
    lossCategories: []
  });
  //var filter code 24-11-2023 end

  const [bands, setBands] = useState(
    [
      {
        id: 1,
        name: `Band 0`,
      },
      {
        id: 2,
        name: `Band 1`,
      },
      {
        id: 3,
        name: `Band 2`,
      },
      {
        id: 4,
        name: `Band 3`,
      },
      {
        id: 5,
        name: `Band 4`,
      },
      {
        id: 6,
        name: `Band 5`,
      },
      {
        id: 0,
        name: `No Bands`,
      },
    ])
  const [siriDimension, setSiriDimension] = useState(stepdata.siriDimension);
  const [dimensionCollapse, setDimensionCollapse] = useState([]);
  const [siriDimensionCollapse, setSiriDimensionCollapse] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [siriInputsData, setSiriInputsData] = useState({});
  const [applyFilterData, setApplyFilterData] = useState({});
  const makeDefaultRef = useRef();
  const defaultFilterArr = useRef([])



  // Tabs Function
  const handleVendorPopupData = (val, type) => {
    if (type == 'right') {
      setIsVendorRightModal(val)
    } else {
      setIsVendorModal(val);
    }
  }
  const handleLeftChange = (event, newValue) => {
    setLeftValue(newValue);
    setIsVendorModal(false);
  };
  const handleRightChange = (event, newValue) => {
    setRightValue(newValue);
    setIsVendorRightModal(false);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>

      </>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  // end
  // Accordion start
  const [expanded, setExpanded] = React.useState(false);

  const HandleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  const Accordion = (props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  );

  const AccordionSummary = (props) => (
    <MuiAccordionSummary expandIcon={''} {...props} />
  );
  const handleUpgradePopupClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
    setShowUpgradePrice(false);
  }
  const AccordionDetails = (MuiAccordionDetails);


  const yesMakeDefaultFunction = () => {
    setIsDefaultScenarioPopup(false);
    setIsLoader(true);
    let tmpArr = scenarioList?.map(i => {
      let tmpObj = i
      if (isMakeDefault.id === tmpObj.id) {
        tmpObj.is_default = 1;
      }
      else {
        tmpObj.is_default = false;
      }
      return tmpObj;
    });
    const tmp_sid = isMakeDefault.id || stepdata.sid;
    serviceProvider.post("/roadmapcomplete?param=makeDefaultScenario", JSON.stringify({ pid: stepdata.plant_id, sid: tmp_sid })).then((response) => {
      let redirectUrl = '/roadmap-complete/step1/' + stepdata.plant_slug + "/" + tmp_sid;
      history.push(redirectUrl);
      props.setUpdateData(prv=>!prv);
    });
    setScenarioList(tmpArr);
  }
  const handleSaveRoadmap = () => {
    setIsLoader(`Give us a moment while we set up your IR4.0 strategy tool for your factory`);
    serviceProvider.post("/roadmapcomplete?param=saveroadmap", JSON.stringify({ data: rightCards, uid: uid, pid: stepdata.plant_id, slug: stepdata.plant_slug,investment:rightTopData.investment,recoverable:rightTopData.recoverable,lossPercent:rightTopData.lossPercent,loss:stepdata.planttotalloss })).then((response) => {

    });
    setTimeout(() => {
      setIsLoader(false);
    }, 3000);
  }
  // end

  // filter code 24-11-2023
  const closeFilter = () => {
    let allCheckBox = document.querySelectorAll('[name="filterCheckBox"]');
    let bothCheckBox = document.querySelector('[name="filterCheckBoth"]');
    let tmpArr = [];
    //code to check the vlaue of input does exist in the checkedFilter' array and behalf of this we will check and uncheck the input.
    allCheckBox.forEach((chk) => {
      if (checkedFilter.includes(chk.value)) {
        chk.checked = true;
        tmpArr.push(chk.value);
      } else {
        chk.checked = false;
      }
    });
    setSelectedFilterObj(applyFilterObj);
    setShowFilter(false);
  };

  const handleChangeOnFilterInput = (e, name) => {
    let inputType = e.target.type;
    let val = e.target.value;
    if (inputType == "checkbox") {
      // type filter code start
      if (name == "type" && val == "3") {
        let tmpValue = selectedFilterObj[name]?.includes(val) ? [] : [val]
        setSelectedFilterObj(prv => {
          return {
            ...prv,
            [name]: tmpValue
          }
        });
      }
      else if (name == "type" && val != "3") {
        let tmpValue = selectedFilterObj[name]?.filter(i => i != "3");
        let tmpArr = tmpValue?.includes(val) ? tmpValue?.filter(i => i != val) : [...tmpValue, val];
        setSelectedFilterObj(prv => {
          return {
            ...prv,
            [name]: [...tmpArr]
          }
        });
      }
      // type filter code end
      // other check boxes filter code start
      else if (selectedFilterObj[name]?.includes(val)) {
        let tmpFilter = selectedFilterObj[name]?.filter(i => i != val);
        setSelectedFilterObj(prv => {
          return {
            ...prv,
            [name]: tmpFilter
          }
        });
      } else {
        let tmpFilter = [...selectedFilterObj[name]];
        tmpFilter.push(val);
        setSelectedFilterObj(prv => {
          return {
            ...prv,
            [name]: tmpFilter
          }
        });
      }
      // other check boxes filter code end
    }
    // radio button filter code start
    else {
      setSelectedFilterObj(prv => {
        return {
          ...prv,
          [name]: val
        }
      });
    }
    // radio button filter code end
  };

  const resetFilter = () => {
    setSelectedFilterObj(
      {
        type: [],
        technology: [],
        recoverables: 0,
        investment: 0,
        dimension: [],
        lossCategories: []
      }
    );
    props.setfilter(0); //dimension
    props.setfilter1(0); //technology
    props.setfilter2(0); //lossCategories
    props.setfilter3(0); //investment
    props.setfilter4(0); //recoverables
    props.setfilter5(0); //type
    props.setfiltercall(!props.filtercall);
  };

  const applyFilter = () => {
      setApplyFilterObj(selectedFilterObj);
      console.clear();
      console.log(selectedFilterObj);
      props.setfilter(selectedFilterObj.dimension.join(',')); //dimension
      props.setfilter1(selectedFilterObj.technology.join(',')); //technology
      props.setfilter2(selectedFilterObj.lossCategories.join(',')); //lossCategories
      props.setfilter3(selectedFilterObj.investment); //investment
      props.setfilter4(selectedFilterObj.recoverables); //recoverables
      props.setfilter5(selectedFilterObj.type.join(',')); //type

      props.setfiltercall(!props.filtercall);
      setShowFilter(false);
  };

  // filter code 24-11-2023 end
  // Hnadle Filter Tabs
  const handleFilterTab = (index) => {
    setIsActive(index);
  };

  const handleFilterOnchangeData = (dimension_id, band_id) => {
    let tmpData = { ...siriInputsData };
    tmpData[dimension_id] = band_id;
    setSiriInputsData(tmpData);
  }

  // Function to handle siri tab wrapper
  const getNextBandsAccordingToSelectedBands = (id, array = [...bands]) => {
    let tmpIndex = array?.findIndex(i => i.id === id);
    if (tmpIndex > -1) {
      let tmpFilterArray = array?.filter((item, index) => index > tmpIndex);
      return tmpFilterArray;
    }
    else {
      return array;
    }
  }

  // Function to handle dimension collapse
  const handleDimensionCollapse = (id) => {
    // console.log(id);
    if (dimensionCollapse?.includes(id)) {
      let tmpFilter = dimensionCollapse?.filter(i => i != id);
      setDimensionCollapse(tmpFilter);
    }
    else {
      let tmpArr = [...dimensionCollapse];
      tmpArr.push(id);
      setDimensionCollapse(tmpArr);
    }
  }

  const handleSiriDimensionCollapse = (id) => {
    if (siriDimensionCollapse?.includes(id)) {
      let tmpFilter = siriDimensionCollapse?.filter(i => i != id);
      setSiriDimensionCollapse(tmpFilter);
    }
    else {
      let tmpArr = [...siriDimensionCollapse];
      tmpArr.push(id);
      setSiriDimensionCollapse(tmpArr);
    }
  }

  // drag and drop function
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    // If dropped outside the droppable area, do nothing
    if (!destination) return;
    // If dropped in the right area, add the card and disable the left card
    if (destination.droppableId === "right") {
      let tmpCard = leftCards[source.index];
      setRightCards((rightCards) => [
        ...rightCards,
        { ...tmpCard },
      ]);
      setLeftCards((leftCards) =>
        leftCards?.map((card, index) =>
          index === source.index ? { ...card, disabled: true } : card
        )
      );
      leftCards.forEach((card, index) => {
        if (card.id === tmpCard.id) {
          setLeftOpen(false);
        }
      }
      )
    }
  };

  // Function to add cards on right side
  const addRightCard = (index, e) => {
    setRightCards((rightCards) => [
      ...rightCards,
      { ...leftCards[index] },
    ]);
    setLeftCards((leftCards) =>
      leftCards?.map((card, i) =>
        i === index ? { ...card, disabled: true } : card
      )
    );
    leftCards.forEach((card, i) => {
      if (i === index) {
        setLeftOpen(false);
      }
    }
    )

    e.stopPropagation();
    e.preventDefault();
  }

  const saveRoadmap = () => {
    serviceProvider.post("/roadmapcomplete?param=saveroadmap", JSON.stringify({ data: rightCards, uid: uid,sid: stepdata.sid, pid: stepdata.plant_id, slug: stepdata.plant_slug,investment:rightTopData.investment,recoverable:rightTopData.recoverable,lossPercent:rightTopData.lossPercent,loss:stepdata.planttotalloss })).then((response) => {
      let redirectUrl = '/roadmap-complete/step2/' + stepdata.plant_slug + "/" + stepdata.sid;
      history.push(redirectUrl);
      //window.location.href = '/roadmap-complete/step2/' + stepdata.plant_slug + "/" + response.data;
      // window.location.href = '/roadmap-complete/final-step/' + stepdata.plant_slug + "/" + response.data;
    });
    setOpenPopup(false)
  }

  const rightTopData = useMemo(() => {
    let investment = rightCards?.reduce((total, i) => total + Number(i?.upfrontInvestment?.replace(/,/g, '') || 0), 0);
    let recoverable = rightCards?.reduce((total, i) => total + Number(i?.totalrecoverable?.replace(/,/g, '') || 0), 0);
    let lossPercent = (recoverable / Number(stepdata?.planttotalloss?.replace(/,/g, '') || 1)) * 100;
    return {
      investment,
      recoverable,
      lossPercent: (lossPercent || 0)?.toFixed(0)
    }
  }, [rightCards]);
  // end
  // Function to remove cards on right side
  const removeRightCard = (id, e) => {
    let tmpFilterArray = rightCards.filter((card) =>
      card.id !== id
    )
    setRightCards(tmpFilterArray);
    setLeftCards((leftCards) =>
      leftCards?.map((card) =>
        card.id === id ? { ...card, disabled: false } : card
      )
    );
    if (rightOpen === id) {
      setRightOpen(false);
    }
    serviceProvider.post("/roadmapcomplete?param=removeroadmap", JSON.stringify({ id: id, uid: uid, pid: stepdata.plant_id })).then((response) => {

    });
    e.stopPropagation();
    e.preventDefault();
  }

  // end
  // Function to open and close tabs on left
  const handleLeftClick = (id, index) => {
    setLeftValue(`l0-${index}`);
    setVendorTab(1);
    setIsVendorModal(false);
    if (leftOpen === id) {
      setLeftOpen(false);
    }
    else {
      setLeftOpen(id);
    }
  };

  // Function to open and close tabs on right
  const handleRightClick = (id, index) => {
    setVendorTab(1);
    setRightValue(`r0-${index}`);
    setIsVendorRightModal(false);
    if (rightOpen === id) {
      setRightOpen(false);
    }
    else {
      setRightOpen(id);
    }
  };

  // Funtion to open and close inner tabs on left
  const handleLeftInnerTabClick = (val) => {
    if (lossLeftDataOpen === val) {
      setLossLeftDataOpen(false);
    }
    else {
      setLossLeftDataOpen(val);
    }
  };

  // Funtion to open and close inner tabs on right
  const handleRightInnerTabClick = (val) => {
    if (lossRightDataOpen === val) {
      setLossRightDataOpen(false);
    }
    else {
      setLossRightDataOpen(val);
    }
  };

  // Funtion to open technology/feature popup
  const handleTabRightClick = async (ind) => {
    setIsLoader(true);
    const result = await serviceProvider.get(`/roadmapapi?param=getpopup&nid=${ind.id}&slug=${stepdata.plant_slug}`);
    if (result.data) {
      setIsTechModal({ ...result.data, index: ind.index });
    }
    setIsLoader(prv => false);
    // setIsTechModal(ind);
    // alert(ind.id);
  }

  // Function to open and close inner QNA tab
  const handleQnaTabClick = (val) => {
    if (qnaOpen === val) {
      setQnaOpen(false);
    }
    else {
      setQnaOpen(val);
    }
  }

  // useEffct
  useEffect(() => {
    document.addEventListener("click", (e) => {
      let target = e.target;
      // close filter on click outside
      if (!target.closest('[data-component="FilterWrapper"]')) {
        closeFilter();
      }
    });
  });

  useEffect(() => {
    // console.clear();
    let tmpObj = {}
    stepdata?.siriDimension?.forEach(i => {
      let tmpArr = getNextBandsAccordingToSelectedBands(i?.selected_band?.id, bands);
      tmpObj[i.id] = tmpArr[0].id;
    });
    defaultFilterArr.current = tmpObj;
    setSiriInputsData(tmpObj);

    //setLeftCards(tmpArray);
    setSelectedFilterObj({
      type: props.filter5?.toString()?.split(',') || [],
      technology:props.filter1?.toString()?.split(',') || [],
      recoverables: props.filter4,
      investment: props.filter3,
      dimension: props.filter?.toString()?.split(',') || [],//props.filter
      lossCategories:props.filter2?.toString()?.split(',') || []////props.filter2
    })
    setApplyFilterObj({
      type: props.filter5?.toString()?.split(',') || [],
      technology:props.filter1?.toString()?.split(',') || [],
      recoverables: props.filter4,
      investment: props.filter3,
      dimension: props.filter?.toString()?.split(',') || [],//props.filter
      lossCategories:props.filter2?.toString()?.split(',') || []////props.filter2
    })
  }, [])


  useEffect(() => {
    setLeftCards(stepdata.nodes);
  }, [props.stepData])
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/home">{stepdata.plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem to="#">IR4.0 Roadmap Solutions</BreadcrumbsItem>
      {/* End */}

      <DragDropContext onDragEnd={handleOnDragEnd}>

        <MainWrapper data-component="MainWrapper" className="first-step">

          <SectionWrapper data-component="SectionWrapper">
            <LeftSection data-component="LeftSection">
              <SectionHeader data-component="SectionHeader">
                <ButtonWrapper
                  data-component="ButtonWrapper"
                  style={{ display: "none" }}
                >
                  <TopSectionBtn disabled data-component="TopSectionBtn">
                    Calculate Losses
                  </TopSectionBtn>
                  <TopSectionBtn data-component="TopSectionBtn">
                    Calculate Recoverables
                  </TopSectionBtn>
                  <TopSectionBtn data-component="TopSectionBtn">
                    Calculate Investment
                  </TopSectionBtn>
                </ButtonWrapper>

                <HeaderFilterWrapper data-component="HeaderFilterWrapper">
                  <HeaderTitle data-component="HeaderTitle">
                    IR4.0 Roadmap Solutions
                  </HeaderTitle>
                  {/* -------------------- HeaderTitle end ---------------- */}

                  <FilterWrapper data-component="FilterWrapper">
                    <FilterButton
                      data-component="FilterButton"
                      active={showFilter}
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <i dangerouslySetInnerHTML={{ __html: filterSvg }}></i>
                    </FilterButton>
                    <FilterBox data-component="FilterBox" show={showFilter}>
                      <FilterBoxHeader data-component="FilterBoxHeader">
                        <HeaderTitle data-component="HeaderTitle">
                          Available Capabilities Filters
                        </HeaderTitle>
                        <FilterCloseButton
                          data-component="HeaderTitle"
                          onClick={closeFilter}
                        >
                          <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </FilterCloseButton>
                      </FilterBoxHeader>
                      <FilterBoxContent data-component="FilterBoxContent">
                        <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                          Type
                        </FilterBoxContentTitle>
                        <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="1"
                              name="filterCheckBox"
                              type="checkbox"
                              checked={selectedFilterObj.type.includes('1')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'type', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Technology</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="2"
                              name="filterCheckBox"
                              type="checkbox"
                              checked={selectedFilterObj.type.includes('2')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'type', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Organization</span>
                          </FilterBoxLable>
                          {/* Organization Input end */}

                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="3"
                              name="filterCheckBoth"
                              type="checkbox"
                              checked={selectedFilterObj.type.includes('3')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'type', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Both</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                        </FilterCheckBoxWrapper>
                        <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                          Technology
                        </FilterBoxContentTitle>
                        <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="1"
                              name="filterCheckBox"
                              type="checkbox"
                              checked={selectedFilterObj.technology.includes('1')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'technology', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Has</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="3"
                              name="filterCheckBox"
                              type="checkbox"
                              checked={selectedFilterObj.technology.includes('3')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'technology', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Has not</span>
                          </FilterBoxLable>
                          {/* Organization Input end */}

                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="2"
                              name="filterCheckBoth"
                              type="checkbox"
                              checked={selectedFilterObj.technology.includes('2')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'technology', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Partial</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                        </FilterCheckBoxWrapper>
                        <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                          Recoverables
                        </FilterBoxContentTitle>
                        <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="1"
                              name="recover"
                              type="radio"
                              checked={selectedFilterObj.recoverables == "1"}
                              onChange={(e) => handleChangeOnFilterInput(e, 'recoverables', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                            </span>
                            <span>High to Low</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="2"
                              name="recover"
                              type="radio"
                              checked={selectedFilterObj.recoverables == "2"}
                              onChange={(e) => handleChangeOnFilterInput(e, 'recoverables', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                            </span>
                            <span>Low to High</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                        </FilterCheckBoxWrapper>
                        <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                          Investment
                        </FilterBoxContentTitle>
                        <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="1"
                              name="invest"
                              type="radio"
                              checked={selectedFilterObj.investment == '1'}
                              onChange={(e) => handleChangeOnFilterInput(e, 'investment', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                            </span>
                            <span>High to Low</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="2"
                              name="invest"
                              type="radio"
                              checked={selectedFilterObj.investment == '2'}
                              onChange={(e) => handleChangeOnFilterInput(e, 'investment', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                            </span>
                            <span>Low to High</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                        </FilterCheckBoxWrapper>
                        <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                          Dimension
                        </FilterBoxContentTitle>
                        <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper" className="halfWidth">
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="12"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('12')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Factory of the Future Leadership</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="4"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('4')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Digital-Ready Infrastructure</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="1"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('1')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>360° Factory Visibility</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="5"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('5')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Intelligent Quality</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="2"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('2')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Automated Material Flow</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="3"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('3')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Data-Driven Maintenance</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="10"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('10')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Zero Touches Production</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="7"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('7')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Sustainability</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="8"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('8')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Tech-Augmented Workforce</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="11"
                              name="dimension"
                              type="checkbox"
                              checked={selectedFilterObj.dimension.includes('11')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'dimension', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Digital Organization</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                        </FilterCheckBoxWrapper>

                        <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                          Loss Categories
                        </FilterBoxContentTitle>
                        <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper" className="halfWidth">
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="1"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('1')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Planned Downtime</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="2"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('2')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Unplanned Downtime</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="3"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('3')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Minor Stops</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="4"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('4')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Speed Losses</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="5"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('5')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Material Waste</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="103"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('103')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Operator Losses</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="107"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('107')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Maintenance Losses</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="106"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('108')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>IT Losses</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="104"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('104')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>EHS Losses</span>
                          </FilterBoxLable>
                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="105"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('105')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Quality Losses</span>
                          </FilterBoxLable>

                          <FilterBoxLable data-component="FilterBoxLable">
                            <input
                              value="102"
                              name="losses"
                              type="checkbox"
                              checked={selectedFilterObj.lossCategories.includes('102')}
                              onChange={(e) => handleChangeOnFilterInput(e, 'lossCategories', e.value)}
                            />
                            <span className="icon">
                              <i dangerouslySetInnerHTML={{ __html: checkboxSvg }} className="check-svg"></i>
                            </span>
                            <span>Warehouse Losses</span>
                          </FilterBoxLable>
                          {/* technology Input end */}
                        </FilterCheckBoxWrapper>
                      </FilterBoxContent>
                      <FilterButtonWrapper data-component="FilterButtonWrapper">
                        <ResetFilterButton onClick={resetFilter}>
                          Reset Filters
                        </ResetFilterButton>
                        <ApplyFilterButton onClick={applyFilter}>
                          Apply Filters
                        </ApplyFilterButton>
                      </FilterButtonWrapper>
                    </FilterBox>
                  </FilterWrapper>
                  {/* -------------------- FilterWrapper end ---------------- */}
                  <button className="added-roadmap-btn" onClick={() => setShowSmRightSection(true)}>{rightCards?.length} Added Roadmap</button>
                </HeaderFilterWrapper>
                {/* -------------------- HeaderFilterWrapper end ---------------- */}
                {/* -------------------- HeaderFilterWrapper end ---------------- */}
              </SectionHeader>
              <ContentBody data-component="ContentBody">
                <Droppable droppableId="left">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {leftCards?.map((card, index) => (
                        <Draggable
                          key={card.id}
                          draggableId={card.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`card${snapshot.isDragging ? "dragging" : ""
                                }`}
                              {...provided.draggableProps}

                              ref={provided.innerRef}
                              style={{
                                ...provided.draggableProps.style,
                                pointerEvents: card.disabled ? "none" : "all",
                                userSelect: card.disabled ? "none" : "all",
                              }}
                            >
                              <MainCardWrapper
                                data-component="MainCardWrapper"
                                disabled={card.disabled}
                              >
                                <TabCardWrapper data-component="TabCardWrapper">
                                  <TabButton data-component="TabButton">
                                    <TBRow data-component="TBRow">
                                      <TBLeftCol data-component="TBLeftCol">
                                        <div
                                          className="drag-btn"
                                          {...provided.dragHandleProps}
                                        >
                                          <i
                                            dangerouslySetInnerHTML={{
                                              __html: dragIcon,
                                            }}
                                          ></i>
                                        </div>
                                        <p className='rmap-txt'>
                                          {card.name}
                                        </p>
                                        <div className="status-wrap">
                                          {card.bcNodesHasNotHas == 5 &&
                                            <NAButton data-component="NAButton" status>N/A</NAButton>
                                          }
                                          {card.bcNodesHasNotHas == 1 &&
                                            <NAButton data-component="NAButton" status={`has`}>Has</NAButton>
                                          }
                                          {card.bcNodesHasNotHas == 0 &&
                                            <NAButton data-component="NAButton" status={`hasnot`}>Has Not</NAButton>
                                          }
                                          {card.bcNodesHasNotHas == 2 &&
                                            <NAButton data-component="NAButton" status={`partially`}>PARTIALLY</NAButton>
                                          }
                                        </div>
                                      </TBLeftCol>
                                      <TBRightCol data-component="TBRightCol">
                                        <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                          <RightAlignTextWrap data-component="RightAlignTextWrap">
                                            <HeadText data-component="HeadText">Recoverable: </HeadText>
                                            <SubText data-component="SubText" green>SR {card.totalrecoverable}</SubText>
                                          </RightAlignTextWrap>
                                          <RightAlignTextWrap data-component="RightAlignTextWrap">
                                            <HeadText data-component="HeadText">Investment: </HeadText>
                                            <SubText data-component="SubText" orange>SR {card.upfrontInvestment}</SubText>
                                          </RightAlignTextWrap>
                                        </RightAlignTextWrapper>
                                        <AddDragBtn data-component="AddDragBtn" onClick={(e) => addRightCard(index, e)}>
                                          <i dangerouslySetInnerHTML={{ __html: addBtn }}></i>
                                        </AddDragBtn>
                                        <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftClick(card.id, index)} className={`${leftOpen === card.id ? 'active' : ''}`}>
                                          {leftOpen === card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                        </CollapseBtn>
                                      </TBRightCol>
                                    </TBRow>
                                  </TabButton>
                                  <Collapse in={leftOpen === card.id} timeout="auto">
                                    <TabContentWraaper data-component="TabContentWraaper">
                                      <Tabs
                                        orientation="horizontal"
                                        variant="scrollable"
                                        value={leftValue}
                                        onChange={handleLeftChange}
                                        aria-label="Vertical tabs example"

                                      >
                                        <Tab value={`l0-${index}`} label="Description" {...a11yProps(0)} />
                                        <Tab value={`l1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                                        {card?.vendors?.length > 0 && <Tab value={`l2-${index}`} label="Vendors" {...a11yProps(2)} />}
                                        {card?.checkQAExist && <Tab value={`l3-${index}`} label="Q&A" {...a11yProps(3)} />}
                                        <Tab value={`l4-${index}`} onClick={() => handleTabRightClick({ ...card, index })} label="Technologies / Features " {...a11yProps(4)} />
                                      </Tabs>

                                      <TabPanel value={leftValue} index={`l0-${index}`}>
                                        <TabPanelContent data-component="TabPanelContent">
                                          {card.description ?
                                            <div dangerouslySetInnerHTML={{ __html: card.description }} />
                                            :
                                            <h1>Not available</h1>
                                          }
                                        </TabPanelContent>
                                      </TabPanel>

                                      <TabPanel value={leftValue} index={`l1-${index}`}>

                                        {Array.isArray(card?.sublossdata) &&
                                          card?.sublossdata?.map((sublossItem, sIndex) => (
                                            <div key={sIndex}>
                                              <InnerTabWrapper data-component="InnerTabWrapper">
                                                <TBRow data-component="TBRow">
                                                  <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                                    <p className="inner-tab-txt">{sublossItem?.lossCategorytext}</p>
                                                  </InnerTBLeftCol>
                                                  <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                                    <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                                      <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Total Category Losses: </HeadText>
                                                        <SubText data-component="SubText" orange>{sublossItem?.totallosscategory}SR</SubText>
                                                      </RightAlignTextWrap>
                                                      <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                        <SubText data-component="SubText" green>{sublossItem?.totalcatrecoverable}SR</SubText>
                                                      </RightAlignTextWrap>

                                                    </RightAlignTextWrapper>
                                                    <p className='recovery-txt'>
                                                      <span>{sublossItem?.perlosscatrec == 'nan' ? 0 : sublossItem?.perlosscatrec}% </span>
                                                      of category <br />losses Recovered
                                                    </p>
                                                    <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftInnerTabClick(`loss0-${index}-${sIndex}`)} className={`${lossLeftDataOpen === `loss0-${index}-${sIndex}` ? 'active' : ''}`}>
                                                      {lossLeftDataOpen === `loss0-${index}-${sIndex}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                    </CollapseBtn>
                                                  </InnerTBRightCol>
                                                </TBRow>
                                              </InnerTabWrapper>
                                              <Collapse in={lossLeftDataOpen === `loss0-${index}-${sIndex}`} timeout="auto">
                                                <LossTableWrap data-component="LossTableWrap">
                                                  <LossScrollTableWrap data-component="LossScrollTableWrap">
                                                    <table className='responsive-loss-table'>
                                                      <thead>
                                                        <tr>
                                                          <th className='loss-table-col'>Sublosses</th>
                                                          <th className='loss-table-col'>
                                                            Identified<br /> Losses
                                                          </th>
                                                          <th className='loss-table-col'>Recoverable</th>
                                                          <th className='loss-table-col'>% of Subloss<br />recoverable </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {Array.isArray(sublossItem?.subloss) && sublossItem?.subloss?.map((subloss) => (
                                                          <tr>
                                                            <td className='loss-table-col'>{subloss?.subloss_category_text}</td>
                                                            <td className='loss-table-col orange' dangerouslySetInnerHTML={{ __html: subloss?.totalsublossrecoverable + '<span>SR</span>' }}></td>
                                                            <td className='loss-table-col green' dangerouslySetInnerHTML={{ __html: subloss?.totalsubcatrecoverable + '<span>SR</span>' }}></td>
                                                            <td className='loss-table-col blue'>{subloss?.lossrecoverdp}%</td>
                                                          </tr>
                                                        ))
                                                        }

                                                        <tr>
                                                          <td className='loss-table-col bottom black'>Total</td>
                                                          <td className='loss-table-col bottom black'>{sublossItem?.totallosscategory}<span>SR</span></td>
                                                          <td className='loss-table-col bottom black'>{sublossItem?.totalcatrecoverable}<span>SR</span></td>
                                                          <td className='loss-table-col bottom black'></td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </LossScrollTableWrap>
                                                </LossTableWrap>
                                              </Collapse>
                                            </div>
                                          ))
                                        }


                                        <LossWrap data-component="LossWrap">
                                          <LossRow data-component="LossRow">
                                            <LossCol data-component="LossCol" width={33.33}>
                                              <span className="text">Associated</span>
                                              <span className="text">Losses:</span>
                                              <span className="text green">{card.totalloss} SR</span>
                                            </LossCol>
                                            <LossCol data-component="LossCol" width={33.33}>
                                              <span className="text">Total Technology</span>
                                              <span className="text"> Recoverable:</span>
                                              <span className="text orange">{card.totalrecoverable} SR</span>
                                            </LossCol>
                                            <LossCol data-component="LossCol" width={33.33}>
                                              <span className="text">% of Total</span>
                                              <span className="text"> Recovered Losses:</span>
                                              <span className="text blue">{card.totallossrecoverdp?.toFixed(2)}%</span>
                                            </LossCol>
                                          </LossRow>
                                        </LossWrap>
                                      </TabPanel>
                                      <TabPanel value={leftValue} index={`l2-${index}`}>
                                        <VendorWrap data-component="VendorWrap">
                                          <VendorRow data-component="VendorRow">
                                            {Array.isArray(card?.vendors) &&
                                              card?.vendors?.map((vendor, vendorIndex) => (
                                                <VendorCol data-component="VendorCol" key={vendorIndex} onClick={() => {
                                                  handleVendorPopupData(vendor);
                                                }}>
                                                  <VendorCard data-component="VendorCard">
                                                    <VendorClickImg data-componenet='VendorClickImg'>
                                                      <img src={vendor?.img ? vendor?.img : require("../../assets/img/roadmap-page/dematic.png").default} />
                                                    </VendorClickImg>
                                                  </VendorCard>
                                                  <VendorText data-component="VendorText">
                                                    {vendor?.name}
                                                  </VendorText>
                                                </VendorCol>
                                              ))
                                            }
                                          </VendorRow>
                                          {isVendorModal ?
                                            <VendorModalWrap data-component="VendorModalWrap">
                                              <VendorModalHeader data-component="VendorModalHeader">
                                                <h3 className='vendor-name'>
                                                  {isVendorModal?.name}
                                                </h3>
                                                <VendorCloseBtn data-component="VendorCloseBtn" onClick={() => { setIsVendorModal(false); setVendorTab(1); }}>
                                                  <i dangerouslySetInnerHTML={{ __html: vendorCloseSvg }}></i>
                                                </VendorCloseBtn>
                                              </VendorModalHeader>
                                              <VendorTabWrap data-component="VendorTabWrap">
                                                <VendorTabs data-component="VendorTabs" >
                                                  <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 1 && 'active'}`} onClick={() => setVendorTab(1)}>Investment</VendorTab>
                                                  <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 2 && 'active'}`} onClick={() => setVendorTab(2)}> Recoverable </VendorTab>
                                                </VendorTabs>
                                                {vendorTab == 2 ?
                                                  <VendorTabPanel data-component="VendorTabPanel">
                                                    <AccordionTabWrapper data-component="AccordionTabWrapper">
                                                      {isVendorModal?.lossescat?.map((vRecoverable, vIndex) => (
                                                        <Accordion expanded={expanded === `panel2-${index}-${vIndex}`} onChange={HandleChange(`panel2-${index}-${vIndex}`)}>
                                                          <AccordionSummary aria-controls={`panel-l${index}${vIndex}d-content`} id={`panel-l${index}${vIndex}-header`}>
                                                            <AccordionHeader data-component="AccordionHeader">
                                                              <AccordImg data-component="AccordImg">
                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                              </AccordImg>
                                                              <span className="accord-txt">{vRecoverable?.name_loss}</span>
                                                            </AccordionHeader>
                                                            <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                            <MaintainWrap data-component="MaintainWrap">
                                                              {vRecoverable?.vendorRecoverables?.map((vInnerRecoverable, VInerIndex) => (
                                                                <MaintainRow data-component="MaintainRow" key={VInerIndex}>
                                                                  <LeftMainCol data-component="LeftMainCol">
                                                                    <AccordImg data-component="AccordImg">
                                                                      <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                    </AccordImg>
                                                                    <span className="accord-txt">{vInnerRecoverable?.loss_category_text}</span>
                                                                  </LeftMainCol>
                                                                  <RightMainCol data-component="RightMainCol">
                                                                    <span className="main-val">{vInnerRecoverable?.lower_recoverable * 100}%</span>
                                                                  </RightMainCol>
                                                                </MaintainRow>
                                                              ))

                                                              }
                                                            </MaintainWrap>
                                                          </AccordionDetails>
                                                        </Accordion>
                                                      ))
                                                      }
                                                    </AccordionTabWrapper>
                                                  </VendorTabPanel>
                                                  :
                                                  <VendorTabPanel data-component="VendorTabPanel">
                                                    <ListInvestWrap data-component="ListInvestWrap">
                                                      <ListRow data-component="ListRow">
                                                        <p className='blue-txt'>CAPEX</p>
                                                        <ListValWrap data-component="ListValWrap">
                                                          <span className='money-txt'>{isVendorModal?.investdata?.capex}</span>
                                                          <span className='money-val'>SR</span>
                                                        </ListValWrap>
                                                      </ListRow>
                                                      <ListRow data-component="ListRow">
                                                        <p className='blue-txt'>Starting OPEX</p>
                                                        <ListValWrap data-component="ListValWrap">
                                                          <span className='money-txt'>{isVendorModal?.investdata?.starting_opex}</span>
                                                          <span className='money-val'>SR</span>
                                                        </ListValWrap>
                                                      </ListRow>
                                                      <UpfrontListRow data-component="UpfrontListRow">
                                                        <p className='black-txt'>Upfront Investment</p>
                                                        <ListValWrap data-component="ListValWrap">
                                                          <span className='money-txt'>{isVendorModal?.investdata?.investment}</span>
                                                          <span className='money-val'>SR</span>
                                                        </ListValWrap>
                                                      </UpfrontListRow>
                                                    </ListInvestWrap>
                                                    <GoListWrap data-component="GoListWrap">
                                                      <p className='blue-txt'>Going OPEX</p>
                                                      <ListValWrap data-component="ListValWrap">
                                                        <span className='money-txt'>{isVendorModal?.investdata?.ongoing_opex}</span>
                                                        <span className='money-val'>SR</span>
                                                      </ListValWrap>
                                                    </GoListWrap>
                                                    <UserWrap data-component="UserWrap">
                                                      <span className='user-txt'>No. of Users:</span>
                                                      <span className='user-num'>{isVendorModal?.investdata?.unit_qty}</span>
                                                    </UserWrap>
                                                  </VendorTabPanel>
                                                }
                                              </VendorTabWrap>
                                            </VendorModalWrap>
                                            : ''}
                                        </VendorWrap>
                                      </TabPanel>
                                      <TabPanel value={leftValue} index={`l3-${index}`}>
                                        {Array.isArray(card?.checkQuestionsQA) &&
                                          card?.checkQuestionsQA?.map((qAnda, qAndaInd) => (
                                            <QNACard data-component="QNACard" key={qAndaInd} className={`${qnaOpen === `q0-${index}-${qAndaInd}` ? 'active' : ''}`}>
                                              <QNABtn data-component="QNABtn" onClick={() => handleQnaTabClick(`q0-${index}-${qAndaInd}`)}>
                                                <span className="q-txt">{qAndaInd + 1}.</span>
                                                <p className="q-desc">
                                                  {qAnda.question}
                                                </p>
                                              </QNABtn>
                                              <Collapse in={qnaOpen === `q0-${index}-${qAndaInd}`} timeout="auto">
                                                <QNAWrap data-component="QNAWrap">
                                                  <table data-layout={qAnda?.question_layout}>
                                                    <tbody>
                                                      <QAndATable
                                                        layout={Number(qAnda?.question_layout)} data={qAnda?.answers || []}
                                                        vlq={qAnda?.vlq}
                                                        ans_selected_array={qAnda?.ans_selected_array || []}
                                                        ans_visible_array={qAnda?.ans_visible_array || []}
                                                        ans_selected_12={qAnda?.ans_selected_12}
                                                        lines={qAnda?.lines}
                                                        ans_selected_value_18={qAnda?.ans_selected_value_18 || []}
                                                        ans_selected_array_18={qAnda?.ans_selected_array_18 || []}
                                                        sarrs={qAnda?.sarrs || []}
                                                        lcount={qAnda?.lcount}
                                                        scount={qAnda?.scount}
                                                        ans_selected_array17={qAnda?.ans_selected_array17 || []}
                                                      />
                                                    </tbody>
                                                  </table>
                                                </QNAWrap>
                                              </Collapse>
                                            </QNACard>
                                          ))
                                        }


                                      </TabPanel>
                                    </TabContentWraaper>
                                  </Collapse>
                                </TabCardWrapper>
                              </MainCardWrapper>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </ContentBody>
            </LeftSection>
            <RightSection data-component="RightSection" smActive={showSmRightSection}>
              <button className="sm-close-popup-btn" onClick={() => setShowSmRightSection(false)}>
                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
              </button>
              <SectionHeader data-component="SectionHeader">
                <ScenarioSectionWrapper data-component="ScenarioSectionWrapper">
                  <ScenarioDropdown
                    options={scenarioList}
                    stepdata={stepdata}
                    setOptions={setScenarioList}
                    setStatus={props.setStatus}
                    setUpdateData={props.setUpdateData}
                    callBackOnchange={(obj) => {
                      setIsMakeDefault(obj);
                    }}
                  />
                  <SaveRoadmapButton
                    data-component="SaveRoadmapButton"
                    disabled={false}
                    onClick={() => handleSaveRoadmap()}
                  >
                    Save Roadmap
                  </SaveRoadmapButton>
                </ScenarioSectionWrapper>
                {stepdata.isDefault == 0 ?
                  <label className="default-scenario">
                    <input ref={makeDefaultRef} type="checkbox" onChange={() => setIsDefaultScenarioPopup(true)} />
                    <span>Make this your default scenario to be displayed on Home page</span>
                  </label>
                  :
                  <p>This is your default scenario to be displayed on Home page</p>
                }
              </SectionHeader>

              <ContentBody data-component="ContentBody">
                <h3 className="blue-txt">Roadmap</h3>
                <LossWrap data-component="LossWrap">
                  <LossRow data-component="LossRow">
                    <LossCol data-component="LossCol">
                      <span className="text">Total Losses</span>
                      <span className="text">Identified:</span>
                      <span className="text orange">SR {stepdata.planttotalloss} </span>

                    </LossCol>
                    <LossCol data-component="LossCol">
                      <span className="text">Total Roadmap</span>
                      <span className="text"> Recoverable:</span>
                      <span className="text green">SR {rightTopData?.recoverable?.toLocaleString()}</span>

                    </LossCol>
                    <LossCol data-component="LossCol">
                      <span className="text">% of Recoverable</span>
                      <span className="text"> Losses:</span>
                      <span className="text blue">{rightTopData?.lossPercent}%</span>

                    </LossCol>
                    <LossCol data-component="LossCol">
                      <span className="text">Total Roadmap</span>
                      <span className="text">Investment:</span>
                      <span className="text orange">SR {rightTopData?.investment?.toLocaleString()}</span>

                    </LossCol>
                  </LossRow>
                </LossWrap>

                <Droppable droppableId="right">

                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{ minHeight: "100%" }}
                      onDragOver={() => console.log('over')}
                    >
                      {rightCards?.map((card, index) => (
                        <div
                          key={card.id}
                          className={`card${card.disabled ? " disabled" : ""}`}
                        >
                          <MainCardWrapper
                            key={index}
                            data-component="MainCardWrapper"
                          >
                            <RightTabCardWrapper data-component="RightTabCardWrapper">

                              <TabButton data-component="TabButton">
                                <TBRow data-component="TBRow">
                                  <TBLeftCol data-component="TBLeftCol">
                                    <button className="drag-btn blue">
                                      <span>{index + 1}</span>
                                    </button>
                                    <p className='rmap-txt'>
                                      {card.name}
                                    </p>
                                  </TBLeftCol>
                                  <TBRightCol data-component="TBRightCol">
                                    <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                      <RightAlignTextWrap data-component="RightAlignTextWrap">
                                        <HeadText data-component="HeadText">Recoverable: </HeadText>
                                        <SubText data-component="SubText" green>SR {card.totalrecoverable}</SubText>
                                      </RightAlignTextWrap>
                                      <RightAlignTextWrap data-component="RightAlignTextWrap">
                                        <HeadText data-component="HeadText">Investment: </HeadText>
                                        <SubText data-component="SubText" orange>SR {card.upfrontInvestment}</SubText>
                                      </RightAlignTextWrap>
                                    </RightAlignTextWrapper>
                                    <AddDragBtn data-component="AddDragBtn" onClick={(e) => removeRightCard(card.id, e)}>
                                      <i dangerouslySetInnerHTML={{ __html: minusIcon }}></i>
                                    </AddDragBtn>
                                    <CollapseBtn data-component="CollapseBtn" onClick={() => handleRightClick(card.id, index)} className={`${rightOpen === card.id ? 'active' : ''}`}>
                                      {rightOpen === card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                    </CollapseBtn>
                                  </TBRightCol>
                                </TBRow>
                              </TabButton>
                              <Collapse in={rightOpen === card.id} timeout="auto">
                                <TabContentWraaper data-component="TabContentWraaper">
                                  <Tabs
                                    orientation="horizontal"
                                    variant="scrollable"
                                    value={rightValue}
                                    onChange={handleRightChange}
                                    aria-label="Vertical tabs example"

                                  >

                                    <Tab value={`r0-${index}`} label="Description" {...a11yProps(0)} />
                                    <Tab value={`r1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                                    {card?.vendors?.length > 0 && <Tab value={`r2-${index}`} label="Vendors" {...a11yProps(2)} />}

                                  </Tabs>

                                  <TabPanel value={rightValue} index={`r0-${index}`}>
                                    <TabPanelContent data-component="TabPanelContent">
                                      {card.description ?
                                        <div dangerouslySetInnerHTML={{ __html: card.description }} />
                                        :
                                        <h1>Not available</h1>
                                      }
                                    </TabPanelContent>
                                  </TabPanel>
                                  <TabPanel value={rightValue} index={`r1-${index}`}>
                                    {Array.isArray(card?.sublossdata) &&
                                      card?.sublossdata?.map((sublossItem, sIndex) => (
                                        <div key={sIndex}>
                                          <InnerTabWrapper data-component="InnerTabWrapper">
                                            <TBRow data-component="TBRow">
                                              <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                                <p className="inner-tab-txt">{sublossItem?.lossCategorytext}</p>
                                              </InnerTBLeftCol>
                                              <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                                <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                                  <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                    <HeadText data-component="HeadText">Total Category Losses: </HeadText>
                                                    <SubText data-component="SubText" orange>{sublossItem?.totallosscategory}SR</SubText>
                                                  </RightAlignTextWrap>
                                                  <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                    <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                    <SubText data-component="SubText" green>{sublossItem?.totalcatrecoverable}SR</SubText>
                                                  </RightAlignTextWrap>
                                                </RightAlignTextWrapper>
                                                <p className='recovery-txt'>
                                                  <span>{sublossItem?.perlosscatrec == 'nan' ? 0 : sublossItem?.perlosscatrec}% </span>
                                                  of category <br />losses Recovered
                                                </p>
                                                <CollapseBtn data-component="CollapseBtn" onClick={() => handleRightInnerTabClick(`loss0-${index}`)} className={`${lossRightDataOpen === `loss0-${index}` ? 'active' : ''}`}>
                                                  {lossRightDataOpen === `loss0-${index}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                </CollapseBtn>
                                              </InnerTBRightCol>
                                            </TBRow>
                                          </InnerTabWrapper>
                                          <Collapse in={lossRightDataOpen === `loss0-${index}`} timeout="auto">
                                            <LossTableWrap data-component="LossTableWrap">
                                              <LossScrollTableWrap data-component="LossScrollTableWrap">
                                                <table className='responsive-loss-table'>
                                                  <thead>
                                                    <tr>
                                                      <th className='loss-table-col'>Sublosses</th>
                                                      <th className='loss-table-col'>
                                                        Identified<br /> Losses
                                                      </th>
                                                      <th className='loss-table-col'>Recoverable</th>
                                                      <th className='loss-table-col'>% of Subloss<br />recoverable </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {Array.isArray(sublossItem?.subloss) && sublossItem?.subloss?.map((subloss) => (
                                                      <tr>
                                                        <td className='loss-table-col'>{subloss?.subloss_category_text}</td>
                                                        <td className='loss-table-col orange' dangerouslySetInnerHTML={{ __html: subloss?.totalsublossrecoverable + '<span>SR</span>' }}></td>
                                                        <td className='loss-table-col green' dangerouslySetInnerHTML={{ __html: subloss?.totalsubcatrecoverable + '<span>SR</span>' }}></td>
                                                        <td className='loss-table-col blue'>{subloss?.lossrecoverdp}%</td>
                                                      </tr>
                                                    ))
                                                    }

                                                    <tr>
                                                      <td className='loss-table-col bottom black'>Total</td>
                                                      <td className='loss-table-col bottom black'>{sublossItem?.totallosscategory}<span>SR</span></td>
                                                      <td className='loss-table-col bottom black'>{sublossItem?.totalcatrecoverable}<span>SR</span></td>
                                                      <td className='loss-table-col bottom black'></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </LossScrollTableWrap>
                                            </LossTableWrap>
                                          </Collapse>
                                        </div>
                                      ))
                                    }
                                    <LossWrap data-component="LossWrap">
                                      <LossRow data-component="LossRow">
                                        <LossCol data-component="LossCol">
                                          <span className="text">Associated</span>
                                          <span className="text">Losses:</span>
                                          <span className="text green">{card.totalloss} SR</span>
                                        </LossCol>
                                        <LossCol data-component="LossCol">
                                          <span className="text">Total Technology</span>
                                          <span className="text"> Recoverable:</span>
                                          <span className="text orange">{card.totalrecoverable} SR</span>
                                        </LossCol>
                                        <LossCol data-component="LossCol">
                                          <span className="text">% of Total</span>
                                          <span className="text"> Recovered Losses:</span>
                                          <span className="text blue">{card.totallossrecoverdp?.toFixed(2)}%</span>
                                        </LossCol>
                                      </LossRow>
                                    </LossWrap>
                                  </TabPanel>
                                  <TabPanel value={rightValue} index={`r2-${index}`}>
                                    <VendorWrap data-component="VendorWrap">
                                      <VendorRow data-component="VendorRow">
                                        {Array.isArray(card?.vendors) &&
                                          card?.vendors?.map((vendor, vendorIndex) => (
                                            <VendorCol data-component="VendorCol" key={vendorIndex} onClick={() => handleVendorPopupData(vendor, 'right')}>
                                              <VendorCard data-component="VendorCard">
                                                <VendorClickImg data-componenet='VendorClickImg'>
                                                  <img src={vendor?.img ? vendor?.img : require("../../assets/img/roadmap-page/dematic.png").default} />
                                                </VendorClickImg>
                                              </VendorCard>
                                              <VendorText data-component="VendorText">
                                                {vendor?.name}
                                              </VendorText>
                                            </VendorCol>
                                          ))
                                        }
                                      </VendorRow>
                                      {isVendorRightModal ?
                                        <VendorModalWrap data-component="VendorModalWrap">
                                          <VendorModalHeader data-component="VendorModalHeader">
                                            <h3 className='vendor-name'>{isVendorRightModal?.name}</h3>
                                            <VendorCloseBtn data-component="VendorCloseBtn" onClick={() => { setIsVendorRightModal(false); setVendorTab(1); }}>
                                              <i dangerouslySetInnerHTML={{ __html: vendorCloseSvg }}></i>
                                            </VendorCloseBtn>
                                          </VendorModalHeader>
                                          <VendorTabWrap data-component="VendorTabWrap">
                                            <VendorTabs data-component="VendorTabs" >
                                              <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 1 && 'active'}`} onClick={() => setVendorTab(1)}>Investment</VendorTab>
                                              <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 2 && 'active'}`} onClick={() => setVendorTab(2)}> Recoverable </VendorTab>
                                            </VendorTabs>
                                            {vendorTab == 2 ?
                                              <VendorTabPanel data-component="VendorTabPanel">
                                                <AccordionTabWrapper data-component="AccordionTabWrapper">
                                                  {isVendorRightModal?.lossescat?.map((vRecoverable, vIndex) => (
                                                    <Accordion expanded={expanded === `panel2-r-${index}-${vIndex}`} onChange={HandleChange(`panel2-r-${index}-${vIndex}`)}>
                                                      <AccordionSummary aria-controls={`panel-r${index}${vIndex}d-content`} id={`panel-r${index}${vIndex}-header`}>
                                                        <AccordionHeader data-component="AccordionHeader">
                                                          <AccordImg data-component="AccordImg">
                                                            <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                          </AccordImg>
                                                          <span className="accord-txt">{vRecoverable?.name_loss}</span>
                                                        </AccordionHeader>
                                                        <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                      </AccordionSummary>
                                                      <AccordionDetails>
                                                        <MaintainWrap data-component="MaintainWrap">
                                                          {vRecoverable?.vendorRecoverables?.map((vInnerRecoverable, VInerIndex) => (
                                                            <MaintainRow data-component="MaintainRow" key={VInerIndex}>
                                                              <LeftMainCol data-component="LeftMainCol">
                                                                <AccordImg data-component="AccordImg">
                                                                  <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                </AccordImg>
                                                                <span className="accord-txt">{vInnerRecoverable?.loss_category_text}</span>
                                                              </LeftMainCol>
                                                              <RightMainCol data-component="RightMainCol">
                                                                <span className="main-val">{vInnerRecoverable?.lower_recoverable * 100}%</span>
                                                              </RightMainCol>
                                                            </MaintainRow>
                                                          ))

                                                          }
                                                        </MaintainWrap>
                                                      </AccordionDetails>
                                                    </Accordion>
                                                  ))
                                                  }

                                                </AccordionTabWrapper>
                                              </VendorTabPanel>
                                              :
                                              <VendorTabPanel data-component="VendorTabPanel">
                                                <ListInvestWrap data-component="ListInvestWrap">
                                                  <ListRow data-component="ListRow">
                                                    <p className='blue-txt'>CAPEX</p>
                                                    <ListValWrap data-component="ListValWrap">
                                                      <span className='money-txt'>{isVendorRightModal?.investdata?.capex}</span>
                                                      <span className='money-val'>SR</span>
                                                    </ListValWrap>
                                                  </ListRow>
                                                  <ListRow data-component="ListRow">
                                                    <p className='blue-txt'>Starting OPEX</p>
                                                    <ListValWrap data-component="ListValWrap">
                                                      <span className='money-txt'>{isVendorRightModal?.investdata?.starting_opex}</span>
                                                      <span className='money-val'>SR</span>
                                                    </ListValWrap>
                                                  </ListRow>
                                                  <UpfrontListRow data-component="UpfrontListRow">
                                                    <p className='black-txt'>Upfront Investment</p>
                                                    <ListValWrap data-component="ListValWrap">
                                                      <span className='money-txt'>{isVendorRightModal?.investdata?.investment}</span>
                                                      <span className='money-val'>SR</span>
                                                    </ListValWrap>
                                                  </UpfrontListRow>
                                                </ListInvestWrap>
                                                <GoListWrap data-component="GoListWrap">
                                                  <p className='blue-txt'>Going OPEX</p>
                                                  <ListValWrap data-component="ListValWrap">
                                                    <span className='money-txt'>{isVendorRightModal?.investdata?.ongoing_opex}</span>
                                                    <span className='money-val'>SR</span>
                                                  </ListValWrap>
                                                </GoListWrap>
                                                <UserWrap data-component="UserWrap">
                                                  <span className='user-txt'>No. of Users:</span>
                                                  <span className='user-num'>{isVendorRightModal?.investdata?.unit_qty}</span>
                                                </UserWrap>
                                              </VendorTabPanel>
                                            }
                                          </VendorTabWrap>
                                        </VendorModalWrap>
                                        : ''}
                                    </VendorWrap>
                                  </TabPanel>
                                </TabContentWraaper>
                              </Collapse>
                            </RightTabCardWrapper>
                          </MainCardWrapper>
                        </div>
                      ))}
                      <TabButton data-component="TabButton" className="disabled">
                        <TBRow data-component="TBRow">
                          <TBLeftCol data-component="TBLeftCol">
                            <button className="drag-btn disabled">
                              <span>{rightCards?.length + 1}</span>
                            </button>
                            <p className='rmap-txt'>
                            </p>
                          </TBLeftCol>
                          <TBRightCol data-component="TBRightCol">


                            <RightAlignTextWrapper data-component="RightAlignTextWrapper">

                            </RightAlignTextWrapper>
                            <AddDragBtn data-component="AddDragBtn">
                              <i dangerouslySetInnerHTML={{ __html: minusIcon }}></i>
                            </AddDragBtn>
                            <CollapseBtn data-component="CollapseBtn">
                              <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>
                            </CollapseBtn>
                          </TBRightCol>
                        </TBRow>
                      </TabButton>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </ContentBody>
              <ContentFooter data-component="ContentFooter">
                {rightCards?.length > 0 ?
                  <button className='proceed-btn' onClick={() => setOpenPopup(true)}>Proceed</button>
                  :
                  <button className='proceed-btn' disabled>Proceed</button>
                }
                {openPopup ?
                  <ProceedPopWrapper data-component="ProceedPopWrapper">
                    <ProceedPopCard data-component="ProceedPopCard">
                      <PopHead data-component="PopHead">
                        <span>Caution</span>
                        <button className="close-pop" onClick={() => setOpenPopup(false)}>
                          <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </button>
                      </PopHead>
                      <p className='pop-txt'>You have unsaved changes. Please save roadmap before proceeding.</p>
                      <PopFooter data-component="PopFooter">
                        <button className='save-btn solid' onClick={saveRoadmap}>Save & Proceed</button>
                        <button className='leave-btn transparent' onClick={() => setOpenPopup(false)}>Leave</button>
                      </PopFooter>
                    </ProceedPopCard>
                  </ProceedPopWrapper> : ''}
              </ContentFooter>
            </RightSection>
          </SectionWrapper>
        </MainWrapper>
        {/* Upgrade Wrapper */}
        {/* Roadmap Pricing popup */}
      </DragDropContext>
      {/* How to use popup */}
      <div className="how-to-use-ctrl-wrap">

      </div>
      {/* Technology/Feature Popup */}
      {isTechModal.index >= 0 && isTechModal !== false ?
        <TechnologyPopWrapper data-component="TechnologyPopWrapper">
          <TechnologyPopWrap data-component="TechnologyPopWrap>">
            <TechnologyPopHead data-component="TechnologyPopHead">
              <h3>Roadmap Steps : Augmented Reality (AR) Remote Support</h3>
              <button className="close-popup" onClick={() => { setIsTechModal(false); setLeftValue(`l0-${isTechModal.index}`); }}>
                <i dangerouslySetInnerHTML={{ __html: closeTechSvg }}></i>
              </button>
            </TechnologyPopHead>
            <TechnologyPopBody data-component="TechnologyPopBody">
              <TechnologyPopBodyWrap data-component="TechnologyPopBodyWrap">
                <table className="table-wrap">
                  <thead>
                    <tr className="tab-names">
                      <th className="border-curve">Parent Technology</th>
                      <th>Technology</th>
                      <th>Feature</th>
                      <th>Status</th>
                      <th>Industry Adoption</th>
                      <th>Company Adoption</th>
                      <th>BIC Adoption</th>
                      {isTechModal?.allLines?.map((th, th_ind) => (
                        <th key={th_ind}>{th?.line_name}</th>
                      ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(isTechModal?.alltechFeatureTdata) &&
                      isTechModal?.alltechFeatureTdata?.map((tr, tr_ind) =>
                      (
                        <tr key={tr_ind}>
                          <td>{tr.parent_tech}</td>
                          <td>{tr.tech_name}</td>
                          <td>{tr.feature_name}</td>
                          <td>
                            <p class={`status ${tr.techHas == 'HAS NOT' ? `has-not` : tr.techHas == 'HAS' ? 'has' : tr.techHas == 'Partially' ? 'partial' : tr.techHas == 'NA' ? 'na' : ''}`}>
                              {tr.techHas}
                            </p>
                          </td>
                          <td>{tr.industryAdoption}</td>
                          <td>{tr.companyAdoption}</td>
                          <td>{tr.bicAdoption}</td>
                          {isTechModal?.allLines?.map((td, td_ind) => (
                            <td key={td_ind}>
                              <p class={`status ${tr[td?.line_name] == 'HAS NOT' ? `has-not` : tr[td?.line_name] == 'HAS' ? 'has' : tr[td?.line_name] == 'Partially' ? 'partial' : tr[td?.line_name] == 'NA' ? 'na' : ''}`}>
                                {tr[td?.line_name]}
                              </p>
                            </td>
                          ))
                          }

                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </TechnologyPopBodyWrap>
            </TechnologyPopBody>
          </TechnologyPopWrap>
        </TechnologyPopWrapper>
        : ''
      }
      {isDefaultScenarioPopup &&
        <DefaultScenarioPopupWrapper data-component="DefaultScenarioPopupWrapper">
          <DefaultScenarioPop data-component="DefaultScenarioPop">
            <h2>Would you like to make this your Default Scenario?</h2>
            <p>The default scenario’s progress and results will displayed on Homepage</p>
            <div class="button-section">
              <button class="cancel" onClick={() => { setIsDefaultScenarioPopup(false); makeDefaultRef.current.checked = false; }}>Cancel</button>
              <button class="submit yes" id="making-default-scenario" onClick={yesMakeDefaultFunction}>Yes, make this my Default Scenario </button>
            </div>
          </DefaultScenarioPop>
        </DefaultScenarioPopupWrapper>
      }
      {isLoader &&
        <LoaderPopupWrap data-component="LoaderPopupWrap">
          <LoaderPop data-component="LoaderPop">
            <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
            <h1 style={{ marginTop: '15px', textAlign: 'center', visibility: typeof isLoader == 'string' ? '' : 'hidden' }}>{isLoader}</h1>
          </LoaderPop>
        </LoaderPopupWrap>

      }
    </>
  );
};

// export default DragDropContainer;


const QAndATable = ({ layout, data, vlq, ans_selected_array, ans_visible_array, ans_selected_12, lines, ans_selected_value_18, ans_selected_array_18, sarrs, lcount, scount, ans_selected_array17 }) => {
  function getAlphabet(startIndex) {
    const startCharCode = 'A'.charCodeAt(0);
    const endCharCode = 'Z'.charCodeAt(0);

    const targetIndex = startCharCode + startIndex - 1;

    if (targetIndex >= startCharCode && targetIndex <= endCharCode) {
      const targetLetter = String.fromCharCode(targetIndex);
      return targetLetter;
    } else {
      return '';
    }
  }
  switch (layout) {
    // done-1
    case 1:
      return (
        <>

          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">Yes</th>
            <th scope="col" class="th-align bd-bottom bd-right">No</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {data?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.option_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && ans_selected_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && !ans_selected_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom ">
                {(vlq == 0 && !ans_selected_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      );
      break;
    // done-4
    case 4:
      return (
        <>

          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">Has</th>
            <th scope="col" class="th-align bd-bottom bd-right">Has Not</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {data?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.option_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && ans_selected_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && !ans_selected_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom ">
                {(vlq == 0 && !ans_selected_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      )
      break;
    // done-8
    case 8:
      return (
        <>

          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">Has</th>
            <th scope="col" class="th-align bd-bottom bd-right">Has Not</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {data?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.option_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && ans_selected_array?.includes(tr?.id) && ans_visible_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && !ans_selected_array?.includes(tr?.id) && ans_visible_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom ">
                {(vlq == 0 || !ans_visible_array?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      );
      break;
    // done-12
    case 12:
      return (
        <>

          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">Answered</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {data?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.option_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {vlq == 1 && `${ans_selected_12}%`}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {vlq == 0 && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      )
      break;
    // done-15
    case 15:
      return (
        <>
          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">Answered</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {data?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.option_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {ans_selected_array_18?.includes(tr.id) && `${ans_selected_value_18[tr.id]}%`}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {!(ans_selected_array_18?.includes(tr.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      )
      break;
    // done-16
    case 16:
      return (
        <>
          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">Has</th>
            <th scope="col" class="th-align bd-bottom bd-right">Has Not</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {lines?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.line_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && sarrs?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && !sarrs?.includes(tr?.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom ">
                {(vlq == 0) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      )
      break;
    // done-
    case 17:
      return (
        <>
          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">All Lines</th>
            <th scope="col" class="th-align bd-bottom bd-right">No Lines</th>
            <th scope="col" class="th-align bd-bottom bd-right">Some Li.</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {data?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.option_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {((scount == lcount) && scount > 0) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 1 && scount == 0 && lcount > 0) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(scount != 0 && (lcount > scount) && (ans_selected_array17?.includes(tr?.id))) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {(vlq == 0) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      )
      break;
    // done-18
    case 18:
      return (
        <>
          <tr data-layout={layout}>
            <th scope="col" class="bd-bottom bd-right"> </th>
            <th scope="col" class="th-align bd-bottom bd-right">Answered</th>
            <th scope="col" class="th-align bd-bottom ">Skipped</th>
          </tr>
          {data?.map((tr, tr_ind) => (
            <tr key={tr_ind}>
              <th scope="col" class="bd-bottom bd-right">{getAlphabet(tr_ind + 1)}. {tr?.option_name}</th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {ans_selected_array_18?.includes(tr.id) && `${ans_selected_value_18[tr.id]}%`}
              </th>
              <th scope="col" class="th-align bd-bottom bd-right">
                {!(ans_selected_array_18?.includes(tr.id)) && <i dangerouslySetInnerHTML={{ __html: blackSvg }}></i>}
              </th>
            </tr>
          ))}
        </>
      )
      break;

    default:
      return (<></>)
      break;
  }
}