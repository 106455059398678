export const closeIcon=`<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<rect x="0.2" y="0.2" width="31.6" height="31.6" rx="3.8" fill="#E7EBF8" stroke="#6464C8" stroke-width="0.4"/>
<mask id="mask0_1305_30644" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
  <rect width="32" height="32" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1305_30644)">
  <path d="M9.74142 23.8586C9.66332 23.9367 9.53668 23.9367 9.45858 23.8586L8.14142 22.5414C8.06332 22.4633 8.06332 22.3367 8.14142 22.2586L14.2586 16.1414C14.3367 16.0633 14.3367 15.9367 14.2586 15.8586L8.14142 9.74142C8.06332 9.66332 8.06332 9.53668 8.14142 9.45858L9.45858 8.14142C9.53668 8.06332 9.66332 8.06332 9.74142 8.14142L15.8586 14.2586C15.9367 14.3367 16.0633 14.3367 16.1414 14.2586L22.2586 8.14142C22.3367 8.06332 22.4633 8.06332 22.5414 8.14142L23.8586 9.45858C23.9367 9.53668 23.9367 9.66332 23.8586 9.74142L17.7414 15.8586C17.6633 15.9367 17.6633 16.0633 17.7414 16.1414L23.8586 22.2586C23.9367 22.3367 23.9367 22.4633 23.8586 22.5414L22.5414 23.8586C22.4633 23.9367 22.3367 23.9367 22.2586 23.8586L16.1414 17.7414C16.0633 17.6633 15.9367 17.6633 15.8586 17.7414L9.74142 23.8586Z" fill="#6464C8"/>
</g>
</svg>
`
export const howIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_1311_32769" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1311_32769)">
  <path d="M4.00024 21.9999C3.45024 21.9999 2.97941 21.804 2.58774 21.4124C2.19608 21.0207 2.00024 20.5499 2.00024 19.9999V9.99988C2.00024 9.44988 2.19608 8.97904 2.58774 8.58738C2.97941 8.19571 3.45024 7.99988 4.00024 7.99988H20.0002C20.5502 7.99988 21.0211 8.19571 21.4127 8.58738C21.8044 8.97904 22.0002 9.44988 22.0002 9.99988V19.9999C22.0002 20.5499 21.8044 21.0207 21.4127 21.4124C21.0211 21.804 20.5502 21.9999 20.0002 21.9999H4.00024ZM10.0002 18.9999L16.0002 14.9999L10.0002 10.9999V18.9999ZM4.00024 6.99988V4.99988H20.0002V6.99988H4.00024ZM7.00024 3.99988V1.99988H17.0002V3.99988H7.00024Z" fill="white"/>
</g>
</svg>
`
export const mobileIcon =`<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
<circle cx="24" cy="24" r="24" fill="url(#paint0_linear_1341_36389)"/>
<mask id="mask0_1341_36389" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="24" height="24">
  <rect x="12" y="12" width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1341_36389)">
  <path d="M16.0002 33.9999C15.4502 33.9999 14.9794 33.8041 14.5877 33.4124C14.196 33.0207 14.0002 32.5499 14.0002 31.9999V21.9999C14.0002 21.4499 14.196 20.9791 14.5877 20.5874C14.9794 20.1957 15.4502 19.9999 16.0002 19.9999H32.0002C32.5502 19.9999 33.021 20.1957 33.4127 20.5874C33.8044 20.9791 34.0002 21.4499 34.0002 21.9999V31.9999C34.0002 32.5499 33.8044 33.0207 33.4127 33.4124C33.021 33.8041 32.5502 33.9999 32.0002 33.9999H16.0002ZM22.0002 30.9999L28.0002 26.9999L22.0002 22.9999V30.9999ZM16.0002 18.9999V16.9999H32.0002V18.9999H16.0002ZM19.0002 15.9999V13.9999H29.0002V15.9999H19.0002Z" fill="white"/>
</g>
<defs>
  <linearGradient id="paint0_linear_1341_36389" x1="6.13889" y1="8.18484" x2="41.9478" y2="39.9026" gradientUnits="userSpaceOnUse">
    <stop stop-color="#8686DF"/>
    <stop offset="1" stop-color="#6464C8"/>
  </linearGradient>
</defs>
</svg>`