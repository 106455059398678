import styled from "styled-components";

export const LeftSection = styled.div`
  width: 50%;
  border-right: 0.1rem solid #cdd2d4;
  height: 100%;
  flex-direction: column;
  display: flex;
  @media (max-width: 976px) {
    padding-top: 0;
  }
`;

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding:3rem 0rem 1.5rem 0rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    background: #ffffff;
    &.step-four{
        padding-top: 1rem;
    }
`;

/*top header css*/
export const SectionWrapper = styled.div`
    display:flex;
    height:100%;
    overflow:hidden;
    padding-top:0.5rem;
`;



export const RightSection = styled.div`
   width:50%;
   height:100%;
   flex-direction: column;
   display: flex;
`;

export const SectionHeader = styled.div`
    min-height: 7rem;
    padding: 0rem 3rem 2rem 3rem;
    p{
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin-top: 1.2rem;
        color: #7d868c;
    }
`;

export const ContentBody = styled.div`
    flex:1;
    overflow:auto;
    padding: 0rem 3.5rem;
    .blue-txt{
        color: #118acb;
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
`;
export const ContentFooter = styled.div`
    display: flex;
    justify-content: end;
    background: #ffffff;
    padding: 1.5rem;    
    width: 100%;
    .proceed-btn{
        min-width: 11.6rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1.2rem 0.5rem;
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: #118acb;
        border: 0.1rem solid #118acb;
        outline: 0 !important;
        background: #fff;
        border-radius: 0.4rem;
        transition: 0.25s ease-in-out;
        margin: 0 0.5rem;
        &:hover{
            background: #118acb;
            color: #ffffff;
        }
    }
    .prev-btn{
        min-width: 11.6rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1.2rem 0.5rem;
        font-size: 1.6rem;
        line-height: 1.8rem;
        color: ##7d868c;
        border: 0.1rem solid #7d868c;
        outline: 0 !important;
        background: #fff;
        border-radius: 0.4rem;
        transition: 0.25s ease-in-out;
        margin: 0 0.5rem;
        &:hover{
            background: #7d868c;
            border-color: #7d868c;
            color: #ffffff;
        }
    }
`
export const TopSectionBtn = styled.button`
    background:${({ disabled }) => disabled ? '#cbcfd2' : '#0789cf'};
    color:${({ disabled }) => disabled ? '#7d868c ' : '#fff'};
    pointer-events:${({ disabled }) => disabled ? 'none ' : 'all'};
    border:0.1rem solid ${({ disabled }) => disabled ? '#cbcfd2' : '#0789cf'};
    padding:1.5rem 2rem;
    border-radius:0.6rem;
    font-size:1.6rem;
    display:inline-block;
`;

export const ButtonWrapper = styled.div`
    display:flex;
    gap:2rem;
    margin-bottom:3rem;
`;

export const HeaderTitle = styled.h2`
   font-size: 1.4rem;
   color: #118acb;
`;

export const ScenarioSectionWrapper = styled.div`
    display:flex;
    gap:3rem;
`;
//Left Card Wrapper Deepika
export const MainCardWrapper = styled.div`
    pointer-events:${({disabled})=>disabled?'none':'all'};
    opacity:${({disabled})=>disabled?0.5:1};
    *{
        user-select: none;
    }
        
`
export const TabCardWrapper = styled.div`
    margin-bottom: 1rem;
`
export const TabButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 0.1rem solid #ebf5fa;
	-webkit-box-shadow: 0px 3px 12px rgba(21, 27, 38, 0.03);
	border-radius: 0.8rem;
	height: 6rem;
	padding: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	background-color: #fff;
    padding-left: 5rem;
    position: relative;
`
export const TBRow = styled.div`
    display: flex;
    width: 100%;
`
export const TBLeftCol = styled.div`
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    width: 53%;
    .rmap-txt{
        color: #7d868c;
        font-weight: 500;
	    font-size: 1.4rem;
	    line-height: 1.7rem;
        padding-left: 1rem;
        padding-right: 2rem;
        flex: 1 1;
    }
    .drag-btn{
        width: 5.5rem;
        position: absolute;
        left: 0;
        top: -0.01rem;
        bottom: -0.01rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &.blue{
            border-radius: 0.8rem;
            background: linear-gradient(124.38deg, #139bd6 33.4%, #078ad0 72.76%);
            color: #fff;
            font-size: 1.6rem;
            font-weight: 500;
            position: absolute;
            left: -.15rem;
            top: -.05rem;
            bottom: -.05rem;
        }
        i{
            display: inline-block;
            height: 1.5rem;
            width: 0.9rem;
        }
    }
`
export const TBRightCol = styled.div`
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    justify-content: right;
    width: 47%;
`
export const TabContentWraaper = styled.div`
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #7d868c;
  position: relative;
  padding: 1.5rem;
  // Css for tabs
  .MuiBox-root {
    padding: 2.4rem 0 0 2.4rem !important;
  }
  .MuiTabs-root {
    align-items: center;
    position: relative;
    .MuiButtonBase-root {
      min-width: 15rem;
      width: max-content;
      @media (max-width: 575px) {
        min-width: 11rem;
      }
      .MuiTab-wrapper {
        font-size: 1.4rem !important;
        text-transform: capitalize;
      }
    }
    .PrivateTabIndicator-root-1 {
      background-color: #118acb !important;
      padding: 0.2rem;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
    .MuiButtonBase-root {
      &.MuiTabScrollButton-root {
        &.MuiTabs-scrollButtons {
          &.MuiTabs-scrollButtonsDesktop {
            height: 2.4rem !important;
            width: 2.4rem !important;
            min-width: unset;
            background: #fff;
            .MuiSvgIcon-root {
              &.MuiSvgIcon-fontSizeSmall {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .MuiTabs-scroller {
    border-bottom: 0.1rem solid #dee2e6;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;
export const RightAlignTextWrapper = styled.div`
    padding-right: 1rem;
`
export const RightAlignTextWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    padding-bottom: 0.5rem;
`
export const HeadText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: #cbcfd2;
  padding-right: 0.5rem;
`;
export const SubText = styled.span`
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 0.5rem;
    color: ${({ green, orange, red }) => green ? '#27ae60' : orange ? '#f2994a' : red ? '#eb5757' : ''};
`
export const AddDragBtn = styled.button`
  border-right: 0.15rem solid #ebf5fa;
  border-left: 0.15rem solid #ebf5fa;
  padding: 0.5rem 2rem;
  @media (max-width: 575px) {
    padding: 0.5rem 1.3rem;
  }
  i {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    svg {
      height: 100%;
      width: 100%;
    }
  }
`;
export const CollapseBtn = styled.button`
  transition: unset !important;
  padding: 2rem;
  @media (max-width: 575px) {
    padding: 2rem 1.3rem;
  }
  i {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  &.active {
    background: #118acb;
  }
`;
export const RightTabCardWrapper = styled.div`
    margin-bottom: 2rem;
`

export const TabPanelContent = styled.div`
    p{
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
    ul{
        padding-left: 3.5rem;
        margin-bottom: 1rem;
        li{
            font-size: 1.3rem;
            list-style-type: disc;
        }
    }
    .bc_leadership{
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        i{
            display: inline-block;
            height: 2.4rem;
            width: 2.4rem;
            margin-right: 1rem;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }`
export const LossTopWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    border-radius: 0.4rem;
    padding: 1.2rem 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    margin: 0 4rem 3rem 4rem;
`
export const LossWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    border-radius: 0.4rem;
    padding: 1.2rem 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    margin-bottom: 3rem;
`
export const LossRow = styled.div`
    display: flex;
    align-items: center;
`
export const LossCol = styled.div`
    border-right: 0.1rem solid #ebf5fa;
    flex: 1 1;
    padding: 0 2rem;
    .text{
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        text-align: center;
        &.orange{
            margin-top: 0.5rem;
            color: #eb5757;
        }
        &.green{
            margin-top: 0.5rem;
            color: #27ae60;
        }
        &.blue{
            margin-top: 0.5rem;
            color: #118acb;
        }
    }
    &:last-child{
        border-right: 0;
    }
`
export const VendorText = styled.span`
    display: block;
    color: #4d4d4f;
    margin-top: 1rem;
    font-size: 1.4rem;
`
//Left Card Wrapper Deepika


// Scenario realted styled component
export const ScenarioContainer = styled.div`
    position:relative;
    width:100%;
    background:#fff;
`;

export const ScenarioButton = styled.button`
    border:0.1rem solid ${({ active }) => active ? '#ccc' : '#4d4d4f'};
    border-radius:0.4rem;
    background:#fff;
    box-shadow: ${({ active }) => active ? 'rgba(0, 0, 0, 0.25)' : 'transparent'} 0px 0px 0.8rem;
    positon:relative;
    width:100%;
    display:flex;
    align-items:center;
    padding:1.3rem 1.6rem;
    text-align:left;
    font-size:1.4rem;
    font-weight:600;
    transition:0.25s ease-in;
    span{
        flex:1;
    }
    i{
        display:inline-block;
        svg{
            width:1.6rem;
            path:border-bottom:${({ active }) => '#' ? 0 : ''};
        }
    }
`;

export const ScenarioDropdownBox = styled.div`
    position:absolute;
    bottom:-1rem;
    transform:translateY(100%);
    background:#fff;
    z-index:9;
    padding:1.5rem 1rem;
    border-radius:0.4rem;
    border-top:0;
    width:100%;
    box-shadow: ${({ show }) => show ? 'rgba(0, 0, 0, 0.25)' : 'transparent'} 0px 0px 0.8rem;
    visibility:${({ show }) => show ? 'visible' : 'hidden'};
    opacity:${({ show }) => show ? 1 : 0};
    display:flex;
    flex-direction:column;
    gap:0.5rem;
    max-height:30rem;
    overflow:auto;
    transition:0.25s ease-in;
`;

export const ScenarioCardHeader = styled.div`
   display:flex;
   align-items:center;
   justify-content:space-between;
   margin-bottom:3rem;
`;

export const ScenarioCardClose = styled.button`
   display:inline-flex;
   align-items:center;
   justify-content:center;
`;

export const ScenarioCardContent = styled.div`
    font-size:1.4rem;
    color:#7d868c;
    .btn-wrapper-container{
        display:flex;
        justify-content:space-between;
        gap:2rem;
        margin-top: 1.5rem;
        button{
                flex:1;
        }
    }    
`;

export const ScenarioInput = styled.input`
   border:0.1rem solid #ccc;
   border-radius:0.4rem;
   width:100%;
   height:4.5rem;
   outline:0;
   padding:1.5rem;
   font-weight:500;
   font-size:1.4rem;
   color:#4d4d4f;
   margin-top: 5rem;
`;

export const ScenarioLabel = styled.label`
   position:relative;
   display:block;
   user-select:none;
   margin-bottom:5rem;
   span{
    position:absolute;
    background:#fff;
    top:0;
    color:#4d4d4f;
    display:inline-block;
    padding:0.2rem;
    left: 1.3rem;
    transform: translateY(-50%);
    font-size:1.2rem;
    font-weight:600;
   }
`;

export const SaveScenarioButton = styled.button`
    background:${({ borderBtn }) => borderBtn ? '#fff' : '#118acb'};
    opacity:${({ disabled }) => disabled ? 0.8 : 1};
    cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    color:${({ borderBtn }) => borderBtn ? '#118acb' : '#fff'};
    border:.1rem solid #118acb;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4.5rem;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    outline: 0;
`;

export const ScenarioList = styled.button`
    background: #fff;
    padding: 1rem;
    transition: 0.25s ease-in;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 2rem;
    font-size: 1.4rem;
    color:#4d4d4f;
    font-weight: 600;
    span{
        flex: 1;
    }
    i{
        display: inline-block;
        line-height: 0;
        position: relative;
        svg{
            width: 2rem;
        }
        &:before{
            content: attr(data-title);
            display:${({ noTitle }) => !noTitle ? 'inline-flex' : 'none'};
            background: rgb(77, 77, 79);
            border-radius: 0.2rem;
            position: absolute;
            min-height: 1.5rem;
            z-index: 1;
            font-style: normal;
            font-size: 1.2rem;
            font-weight: 500;
            right: 0px;
            top:-0.5rem;
            align-items: center;
            padding: 0.4rem 0.5rem;
            color: #fff;
            border-radius: 0.2rem;
            transform: translateY(-100%);
            opacity: 0;
            pointer-events: none;
            transition: 0.25s ease-in;
        }
        &:hover{
            &:before{
                opacity: 1;
            }
        }
    }
    &:hover {
        background: #ebf5fa;
    }
`;

    export const ScenarioModal = styled.section`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility:${({ show }) => !show ? 'hidden' : 'visible'}
`;

export const ScenarionModalCard = styled.section`
    width: 50rem;
    min-height: 10rem;
    padding: 2.5rem;
    background: #fff;
    border-radius: 0.8rem;
`;
// End Scenario
export const InnerTabWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid #ebf5fa;
    -webkit-box-shadow: 0px 3px 12px rgba(21, 27, 38, 0.03);
    border-radius: 0.8rem;
    height: 6rem;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    margin-bottom: 2rem;
    flex-wrap: wrap;
`
export const InnerTBRightCol = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .recovery-txt{
        border-left: 0.15rem solid #ebf5fa;
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: #cbcfd2;
        span{
            color: #118acb;
            display: inline-block;
            margin-right: 0.4rem;
        }
    }
`
export const LossTableWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
    box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
    border-radius: 0.8rem;
    padding: 1.5rem;
    display: block;
    margin-bottom: 2rem;
`
export const LossScrollTableWrap = styled.div`
    overflow-x: auto;
    width: 100%;
    .responsive-loss-table{
        border-collapse: collapse;
        // width: 55rem;
        thead{
            th.loss-table-col{
                color: #118acb;
                border-bottom: 0 !important;
            }
        }
        .loss-table-col{
            color: #828282;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 1rem 1.5rem;
            text-align: left;
            border-bottom: 0.1rem solid #f0f0f0;
            border-right: 0.1rem solid #f0f0f0;
            &:last-child{
                border-right: 0;
            }
            &.bottom{
                background: #ebf5fa;
                color: #4d4d4f;
                border-bottom: 0;
            }
            &.orange{
                margin-top: 1rem;
                color: #eb5757;
            }
            &.green{
                margin-top: 1rem;
                color: #27ae60;
            }
            &.blue{
                margin-top: 1rem;
                color: #118acb;
            }
        }
    }
`

export const ProceedPopWrapper = styled.div`
    background: rgba(21, 27, 38, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
`
export const ProceedPopCard = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0 6px 8px rgba(152,164,170,0.17);
    border-radius: 0.8rem;
    min-height: 20rem;
    width: 45rem;
    p{
        font-size: 1.4rem;
        font-weight: 400;
        padding-left: 2rem;
        padding-bottom: 2rem;
    }
`
export const PopHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem 2rem 2rem;
    span{
        color: #118acb;
        font-size: 1.6rem;
        font-weight: 400;
    }
`
export const PopFooter = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 0 2rem;
    button{
        min-height: 4rem;
        min-width: 11.6rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #ffffff;
        background: linear-gradient(91.83deg, #139bd6 12.22%, #0789cf 100.41%);
        border: 0.1rem solid #118acb;
        border-radius: 0.4rem;
        &.transparent{
            background: transparent;
            border-color: #118acb;
            color: #118acb;
            margin-left: 2rem;
        }
    }
`
export const RecoverablePopWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    padding: 2.5rem 2rem;
    max-width: 100%;
    margin: auto;
    border-radius: 0.4rem;
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    color: #7d868c;
    margin-bottom: 2rem;
`
export const RecoverCloseBtn = styled.div`
    cursor: pointer;
    width: 3rem;
    i{
        display: inline-block;
        height: 3rem;
        width: 3rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
`
export const InfoIcon = styled.div``
export const RecoverPopCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p{
        color: #118acb;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0 2rem;
        line-height: 2rem;
    }
`
export const ScndRgtTabWrapper = styled.div`
    margin-bottom: 2rem;
    padding: 1.1rem 0;
`
export const VendorTabPanel = styled.div``
export const RightHeadingWrap = styled.div`
    padding: 1rem 0;
    display: flex;
    align-items: center;
    border-bottom: 0.3rem solid #e9e9e9;
    justify-content: space-between;
    h3{
        font-size: 1.6rem;
        color: #118acb;
        font-weight: 600;
    }
`
export const RightCloseBtn = styled.button`
    background: transparent;
    border: 0;
    height: 3.5rem;
    width: 3.5rem;
    i{
        display: inline-block;
        height: 100%;
        width: 100%;
        svg{
            height: 100%;
            width:100%;
        }
    }

    @media(max-width: 976px){
       display:none;
    }

`
export const ImplementationWrap = styled.div`
    margin-top: 3rem;
    h5{
        color: #151b26;
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
    p{
        color: #4d4d4f;
        font-size: 1.2rem;
        font-weight: 500;
        span{
            color: #118acb;
        }
    }
`
export const TimeLineWrap = styled.div`

`
export const TimeLineCard = styled.div`
    box-shadow: 0px 0px 0.7rem rgba(0, 0, 0, 0.12);
    border-radius: 0.4rem;
    padding: 1rem;
    margin-top: 2rem;
    h6{
        color: #151b26;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 2.5rem;
    }
    .text-wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        &:last-child{
            margin: 0;
        }
        span{
            color: #151b26;
            display: inline-block;
            font-size: 1.4rem;
            font-weight: 600;
        }
        select{
            width: 12.5rem;
            height: 4rem;
            border-radius: 0.8rem;
            background-color: #fff;
            border: solid 0.1rem #f0f0f0;
            text-align: left;
            padding: 0.5rem;
            color: #4d4d4f;
            font-size: 1.4rem;
            font-weight: 500;
        }
    }
`
export const AddTimeLine = styled.button`
    display: flex;
    align-items: center;
    padding-left: 2rem;
    margin-top: 2.5rem;
    i{
        display: inline-block;
        height: 1.9rem;
        width: 1.8rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    span{
        color: #4d4d4f;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 500;
        margin-left: 1rem;
    }

`
export const TransFormWrap = styled.div`
h6{
    color: #151b26;
    font-size: 1.6rem;
    font-weight: 600;
    margin: 3rem 0 1.5rem 0;
}
.dropdown {
    position: relative;
    display:inline-block;
    @media(max-width:740px){
        display: block;
    }
    .dropdown-header {
        color: #118acb;
        font-size: 1.6rem;
        font-weight: 500;
        padding: 1.5rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 35rem;
        border-radius: 0.5rem;
        border: 0.1rem solid #f0f0f0; 
        background-color: white;
        @media(max-width:740px){
            max-width:100%;
            width: 100%;
        }
        .icon-svg{
            display: inline-block;
            height: 2.5rem;
            width: 2.5rem;
            svg{
                transform: rotate(0deg);
                height: 100%;
                width: 100%;
                transition: 0.25s;
            }
            &.open{
                svg{
                    transform: rotate(180deg);
                }
            }
        }
    }
    .dropdown-body {
        width: 35rem;
        border-radius: 0.5rem;
        border: 0.1rem solid #f0f0f0; 
        background-color: white;
        padding: 0.5rem;
        border-top: 0.1rem solid #E5E8EC;
        display: none;
        top: -0.6rem;
        transform: translateY(-100%);
        max-height:450px;
        left:0;
        position:absolute;
        overflow:auto;
        z-index:5;
        @media(max-width:400px){
            max-width:100%;
        }
        &.open {
            display: block;
        }
        .dropdown-item {
            font-size: 1.4rem;
            cursor: pointer;
            padding: 1rem 2rem;
            border-top: solid 0.1rem #f0f0f0;
            color: #606060;
            margin-bottom: 0px !important;
            &:hover{
                color: #118acb;
            }
            &:first-child{
                border: 0;
            }
        }
        &::hover {
            cursor: pointer;
        }
    }
  }
`

export const UpgradeLock = styled.div`
position: relative;
@media(max-width:1366px){
    i svg{
        width:65px;
    }
  }
.upgrade-tooltip{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  z-index:1;
  span{
    display: block;
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 0.4rem;
    padding: 0.8rem 1.6rem;
    background: #2C55A0;
    width: max-content;
  }
}
.upgrade-lock-icon{
  display: block;
  height: 100%;
  width: 100%;
  svg{
    height: 100%;
    width: 100%;
  }
}
&:hover{
  .upgrade-tooltip{
    opacity: 1;
    visibility: visible;
  }
}
`