import React, { useState, useMemo, useEffect, useContext } from "react";
import CountDropdown from "./CountDropdown";
import { useHistory } from 'react-router-dom';
import { CartContext } from "../../contexts/CartContext";
import {
    MainWrapper,
    UpgradePopWrapper,
    UpgradePopWrap,
    UpgradePopHeader,
    HowCloseBtn,
    UpgradePopBody,
    TabContentWrap,
    //TabLeftSection,
    TabRightSection,
    TabBtn,
    TabRightSectionWrap,
    RoadMethodTab,
    UpgradeFooter,
    TotalWrapper,
    TotalWrap,
    InfoWrapper,
    ComboPopWrapper,
    ComboPopCard,
    ComboHead,
    ComboBody,
    AddComboBtn,
} from "./styles";
import {
    closeIcon, arrowIcon, infoIcon, infoCloseBtn, blankSvg, asmtIcon, lossIcon, roadmapIcon, cartIcon, addedCartIcon
} from "./svg";


export const UpgradeView = (props) => {

    const upgradeSection = localStorage.getItem(`upgradeSection`);
    const plantName = localStorage.getItem(`plantName`);
    const plantId = localStorage.getItem(`plantId`);
    const comboIdget = localStorage.getItem(`comboIdget`);

    const upgradeData = [
        {
            id: 1,
            course_name: `IR4.0 Training Courses`,
            content: `Create your own combo’s by selecting the number of plants and customise the platform tools needed for these plants:`,
            img: `1`,
        },
        {
            id: 2,
            course_name: `Platform Tools`,
            content: `Create your own combo’s by selecting the number of plants and customise the platform tools needed for these plants:`,
            img: `2`,
        },
    ]

    const defaultComboData = [
        {
            id: 2001,
            ref_id: 2001,
            img: asmtIcon,
            course_name: `IR 4.0 Technology Assessment`,
            price: 1500,
            plant_id:plantId||0,
            count: 1,
            value: 0,
            combo_id: 1001,
            type: `assessment`,
            info: [`Most effective way to measure Technological & Organizational capabilities at scale while engaging all the organisation:`,
                `1. Plant Team Engagement`,
                `2. Automated Data Analysis`,
                `3. IR4.0 Index & Benchmarking`,
                `4. IR4.0 Insights & Benchmarking`,
            ],
        },
        {
            id: 2002,
            ref_id: 2002,
            img: lossIcon,
            course_name: `IR 4.0 Loss Analysis`,
            type: `loss`,
            price: 1500,
            plant_id:plantId||0,
            count: 1,
            value: 0,
            combo_id: 1001,
            info: [`Most effective way to identify all the loss drivers of your manufacturing operations and engage the organisation at scale.`,
                `1. Plant Team Engagement`,
                `2. Automated Data Analysis`,
                `3. IR4.0 Index & Benchmarking`,
                `4. IR4.0 Insights & Benchmarking`,
            ],
        },
        {
            id: 2003,
            ref_id: 2003,
            img: roadmapIcon,
            course_name: `IR 4.0 Strategies`,
            price: 1500,
            plant_id:plantId||0,
            count: 1,
            value: 0,
            combo_id: 1001,
            type: `strategy`,
            info: [`Our Industry 4.0 Roadmap addresses these challenges by providing:`,
                `1. Technologies that directly tackle your losses  `,
                `2. Organizational capabilities to support the change`,
                `3. Vendor Solutions`,
                `4. Cost-benefit analysis for proposed solutions`,
                `5. Overall Size of the Prize`,
                `6. At this stage SmarterChains helps organizations drive Build or Buy decisions`,
            ],
        },
    ]

    const comboData = [
        {
            id: 1,
            ref_id: 0,
            img: '',
            course_name: `Digital-Ready Infrastructure`,
            value: `1`,
            combo_id: 0,
            type: ``,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `Systems that safeguard and enable the plant’s operations, ensure reliable and secure data streaming acting as a key enabler of Industry 4.0 transformation.`,
        },
        {
            id: 2,
            ref_id: 0,
            img: '',
            course_name: `Tech-Augmented Workforce`,
            combo_id: 0,
            type: ``,
            value: `2`,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `Technologies that assist employees in their tasks by augmenting their skillset and optimizing workflows.These technologies provide access to information and expertise and are opening new horizons in productivity, efficiency and innovation, democratizing value creation.`,
        },
        {
            id: 3,
            ref_id: 0,
            img: '',
            course_name: `Factory of the Future Leadership`,
            combo_id: 0,
            type: ``,
            value: `3`,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `Leadership behaviours and strategies that inspire, support and offer guidance in order to accelerate and guarantee the digital transformation’s success across the organization.`,
        },
        {
            id: 4,
            ref_id: 0,
            img: '',
            course_name: `Digital Organization`,
            combo_id: 0,
            type: ``,
            value: `4`,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `Structures and policies that provide the agility to design the interventions required to organizational culture, development of employee’s skills and new talent acquisition.`,
        },
        {
            id: 5,
            ref_id: 0,
            img: '',
            course_name: `Zero-Touches Production`,
            combo_id: 0,
            type: ``,
            value: `5`,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `Hardware, software, and practices, that eliminate human effort in production execution and streamline the production system towards lights-off operations.`,
        },
        {
            id: 6,
            ref_id: 0,
            img: '',
            course_name: `Data-Driven Maintenance`,
            combo_id: 0,
            type: ``,
            value: `6`,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `The implementation of methods and systems to proactively manage maintenance based on data, such as equipment condition metrics and historical events.`,
        },
        {
            id: 7,
            ref_id: 0,
            img: '',
            course_name: `Automated Material Flow`,
            combo_id: 0,
            type: ``,
            value: `7`,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `Implementation of machinery & systems that automate & optimize material handling processes throughout the manufacturing facility.`,
        },
        {
            id: 8,
            ref_id: 0,
            img: '',
            course_name: `360ᵒ Factory Visibility`,
            combo_id: 0,
            type: ``,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            value: `8`,
            info: `Systems that combine data from various areas of the value chain, eliminating silos & accelerating decision-making. They include software that covers several departments, such as ERP and Plant Manager Dashboards and integrations between these systems.`,
        },
        {
            id: 9,
            ref_id: 0,
            img: '',
            course_name: `Intelligent Quality`,
            combo_id: 0,
            type: ``,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            value: `9`,
            info: `Effective integration of sensors, software, and techniques that leverage real-time data to manage quality control and quality assurance.`,
        },
        {
            id: 10,
            ref_id: 0,
            img: '',
            course_name: `Sustainability`,
            combo_id: 0,
            type: ``,
            value: `10`,
            price: 1,
            plant_id:plantId||0,
            count: 1,
            info: `Methods, systems, behaviours and Industry 4.0 technologies that help create products and solutions that address the needs of the present without compromising the ability of future generations to meet their needs.Typical technologies in this area are Energy Management Software (EMS) and Environmental, Health & Safety (EHS) Software..`,
        },
    ]
    const [comboDataPopup, setComboDataPopup] = useState([defaultComboData]);
    const { openPop, setOpenPop } = props;
    const [RoadMethodData, setRoadMethodData] = (useState({}));
    const [checkedInput, setCheckedInput] = useState([]);
    const [greenCart, setGreenCart] = useState(false);
    const [blueCart, setBlueCart] = useState(false);
    const [totalCart, setTotalCart] = useState(true);
    const [blankCombo, setBlankCombo] = useState(false);
    const [comboSubTotal, setComboSubTotal] = useState({});
    const [trainingSubTotal, setTrainingSubTotal] = useState(0);
    const [informationPopup, setInformationPopup] = useState(null);
    const [informationScndPopup, setInformationScndPopup] = useState(null);
    const [comboDataCount, setComboDataCount] = useState({});
    const [trainingCount, setTrainingCount] = useState({});
    const [selectedComboData, setSelectedComboData] = useState([]);
    const [selectedTrainingData, setSelectedTrainingData] = useState([]);
    const [comboCheckInputs, setComboCheckInput] = useState({ 1001: [] });
    const history = useHistory();
    const { addProduct, addProductCombo, cartItems, increase } = useContext(CartContext);
    const addComboData = () => {
        let tmpArray = [...comboDataPopup];
        let tmpDefaultArr = [...defaultComboData];
        let tmpChckInputs = { ...comboCheckInputs };
        let tmp_combo_id = new Date().getTime();
        tmpDefaultArr.forEach((item, index) => {
            tmpDefaultArr[index].combo_id = tmp_combo_id;
            tmpDefaultArr[index].id = tmp_combo_id + index;
        })
        tmpChckInputs[tmp_combo_id] = [];
        tmpArray.push(tmpDefaultArr);
        setComboCheckInput(tmpChckInputs);
        setComboDataPopup(tmpArray);
    }
    const delComboData = (ind, comboId) => {
        if (comboDataPopup.length > 0) {
            const tmpArray = [...comboDataPopup];
            // const deletedCombo = tmpArray.shift(); 
            const filterArray = tmpArray.filter((item, i) => item[0].combo_id != comboId);
            const updatedComboSubTotal = { ...comboSubTotal };
            delete updatedComboSubTotal[`combo${ind}Input`];
            // console.log(filterArray);
            setComboSubTotal(updatedComboSubTotal);
            setComboDataPopup(filterArray);
        }
    }
    // Function to check individual input
    const handleInputChecked = (val) => {

        if (checkedInput.includes(val)) {
            let tmpArray = checkedInput.filter(item => item != val);
            setCheckedInput(tmpArray);
        }
        else {
            let tmpArray = [...checkedInput];
            tmpArray.push(val);
            setCheckedInput(tmpArray);
        }
    }
    // Function to check all input
    const handleAllInputCheck = () => {
        let tmpSelectedData = [];
        let allInputClass = document.querySelectorAll('.trainingSubTotalInput');

        // code for check/uncheck inputs
        if (checkedInput.length === comboData.length) {
            setCheckedInput([]);
            setTrainingSubTotal(prv => { return 0 });
        }
        else {
            let tmpArray = [];
            let tmpSubTotal = 0;
            comboData.forEach((item, index) => {
                let keyName = allInputClass[index].getAttribute(`id`);
                let tmpData = JSON.parse(allInputClass[index].getAttribute('data-value'));
                let tmpTrainingCount = trainingCount[keyName] || 1;
                tmpArray.push(item.value);
                tmpSubTotal += ((item.price) * tmpTrainingCount);
                tmpSelectedData.push(tmpData);
            });
            setCheckedInput(tmpArray);
            setTrainingSubTotal(prv => tmpSubTotal);
        }

        setSelectedTrainingData(tmpSelectedData);

    }
    // handle Selected Combo data
    const handleSelectedCombo = (e, item, index) => {
        let isChecked = e.checked;
        if (isChecked) {
            let tmpArr = [...selectedComboData];
            let tmpIndex = tmpArr.findIndex(i => (i.combo_id === item.combo_id && i.id === item.id));
            if (tmpIndex === -1) {
                tmpArr.push({ ...item, count: comboDataCount[`combo${index}Input`] || 1 });
            } else {
                tmpArr[tmpIndex] = { ...item, count: comboDataCount[`combo${index}Input`] || 1 };
            }

            setSelectedComboData(tmpArr);
        } else {
            let filterArray = [];
            selectedComboData.forEach(i => {
                if (i.combo_id == item.combo_id && i.id == item.id) {
                    // nothing 
                } else {
                    filterArray.push(i);
                }
            })
            setSelectedComboData(filterArray);
        }
    }
    // handle check and Uncheck combo inputs
    const handleCheckUncheckCourse = (tmpKey, tmpIndex) => {
        let tmpObj = { ...comboCheckInputs };
        let tmpArr = tmpObj[tmpKey];
        if (tmpArr?.includes(tmpIndex)) {
            let filterArr = tmpArr.filter(i => i != tmpIndex);
            tmpObj[tmpKey] = filterArr;
        } else {
            tmpArr?.push(tmpIndex);
            tmpObj[tmpKey] = tmpArr;
        }
        setComboCheckInput(tmpObj);
    }
    // Function to handle combo subtotal
    const handleSubTotal = (e, val, comboArray) => {

        let tmpClass = e.getAttribute('class');
        let tmpComboIndex = e.getAttribute('data-ComboIndex');
        let tmpCombo = comboArray ? comboArray[tmpComboIndex] : comboDataPopup[tmpComboIndex];
        let allInputs = document.querySelectorAll(`.${tmpClass}`);
        let comboCount = val || comboDataCount[tmpClass] || 1;
        let tmpSubTotal = 0;
        allInputs.forEach((item, index) => {
            if (item.checked === true) {
                tmpSubTotal += tmpCombo[index].price;
            }
            // console.log(tmpSubTotal,comboCount);
        });
        setComboSubTotal(prv => {
            return { ...prv, [tmpClass]: tmpSubTotal * comboCount };
        });
    }
    // Function to handle training subtotal
    const handleTrainingSubTotal = (e, val, trainKeyName) => {
        let allInputs = document.querySelectorAll(`.trainingSubTotalInput`);
        let tmpArray = { ...comboData };
        let tmpSubTotal = 0;
        let tmpSelectedData = [];
        allInputs.forEach((item, index) => {
            let keyName = item.id;
            let tmpData = JSON.parse(item.getAttribute('data-value'));
            let tmpTrainingCount = trainingCount[keyName] || 1;
            // Condition for single input and all checked input
            if (item.checked === true) {

                if (trainKeyName === keyName) {
                    tmpSubTotal += (tmpArray[index].price * val);
                    tmpSelectedData.push({ ...tmpData, count: Number(val || 1) });
                }
                else {
                    tmpSubTotal += (tmpArray[index].price * tmpTrainingCount);
                    tmpSelectedData.push({ ...tmpData, count: tmpTrainingCount });
                }
            }
        });

        setSelectedTrainingData(tmpSelectedData);
        setTrainingSubTotal(tmpSubTotal);
    }
    // Variable for final total
    const cartTotal = useMemo(() => {
        let tmpCartTotal = trainingSubTotal;
        Object.values(comboSubTotal).forEach((item) => {
            tmpCartTotal += item;
        });
        return tmpCartTotal;
    }, [comboSubTotal, trainingSubTotal, comboDataCount, trainingCount]);

    const handleInfoPopup = (val) => {
        setInformationPopup(val);
    }
    const handleInfoScndPopup = (val) => {
        setInformationScndPopup(val);
    }
    // Function to handle combo count dropdown
    const handleComboCount = (val, index, comboArray, selectedData) => {
        setComboDataCount(prv => { return { ...prv, [`combo${index}Input`]: val } });
        let tmpCommonClass = document.querySelectorAll(`[name=comboInput]`);
        let tmpComboData = [...comboArray];
        let tmpSelectedData = [...selectedData];
        if (tmpCommonClass) {
            tmpCommonClass.forEach((item) => {
                if (item.classList == `combo${index}Input`) {
                    handleSubTotal(item, val, comboArray);
                }
            })
        }
        tmpComboData[index].forEach(i => {
            i.count = Number(val);
        });

        tmpSelectedData.forEach((i, ind) => {
            if (tmpComboData[index][0].combo_id === i.combo_id) {
                tmpSelectedData[ind].count = Number(val);
            }
        });


        setComboDataPopup(tmpComboData);
        setSelectedComboData(tmpSelectedData);
        setComboDataCount(tmpComboData);

    };
    // Function to handle training count dropdown
    const handleTrainingCount = (val, keyName) => {
        setTrainingCount(prv => { return { ...prv, [keyName]: val } });
        let tmpCommonClass = document.querySelectorAll(`.trainingSubTotalInput`);
        if (tmpCommonClass) {
            tmpCommonClass.forEach((item) => {
                if (item.id == keyName) {
                    handleTrainingSubTotal(item, val, keyName);
                }
            })
        }
    }
    // Function to handle total cart
    const handleTotalCart = () => {
        console.log(selectedComboData);
        console.log(selectedTrainingData);
        let tmpComboIdArr = [];
        let tmpComboArr = [];
        selectedTrainingData.forEach((item) => {
            addProduct(item);
        });

        // end code for filter array according to combo id

        // console.log(tmpComboArr);
        selectedComboData.forEach(item => {
            addProduct(item);
        })
        // addProduct(courseDataItem);
        setGreenCart(true);
        setTotalCart(false);
        setBlankCombo(true);
        setTimeout(() => { setGreenCart(false); setBlueCart(true); }, 1000);

    }
    const handleBlueCart = () => {
        setOpenPop(false);
        setGreenCart(false);
        setTotalCart(false);
        setBlueCart(false);
        history.push('/cart');
    }
    //end

    const closePopup = () => {
        setOpenPop(false);
        let upgradeBtn = document.querySelector('.header-icons-li.upgrade .upgrade-btn');
        if (upgradeBtn) {
            upgradeBtn.removeAttribute('data-active-tab');
        }
        localStorage.removeItem(`upgradeSection`);
        localStorage.removeItem(`plantName`);
        localStorage.removeItem(`plantId`);
        localStorage.removeItem(`comboIdget`);
    }
    useEffect(() => {
        let tmpCommonClass = document.querySelectorAll(`[name=comboInput]`);
        if (tmpCommonClass) {
            tmpCommonClass?.forEach((item) => {
                let tmpVar = item.getAttribute('data-type');
                if (upgradeSection == tmpVar) {
                   item.click();
                    setTimeout(() => {
                        handleSubTotal(item);
                    }, 200);
                }
            })
        }

        // // to active tab 
        // let upgradeBtn = document.querySelector('.header-icons-li.upgrade .upgrade-btn');
        // if (upgradeBtn) {
        //     let tmpIndx = upgradeBtn.getAttribute('data-active-tab');
        //     if (tmpIndx == '0') {
        //         setRoadMethodData(upgradeData[tmpIndx]);
        //     } else {
        //         setRoadMethodData(upgradeData[1]);
        //     }
        // }

        return () =>{
            localStorage.removeItem(`upgradeSection`);
            localStorage.removeItem(`plantName`);
            localStorage.removeItem(`plantId`);
            localStorage.removeItem(`comboIdget`);
        }
    }, [])

    return (
      <>
        <MainWrapper data-component="MainWrapper">
          {openPop && (
            <UpgradePopWrapper data-component="UpgradePopWrapper">
              <UpgradePopWrap data-component="UpgradePopWrap">
                <UpgradePopHeader data-component="UpgradePopHeader">
                  <span className="head-txt">
                    {/* {RoadMethodData.id === 2 ? `Upgrade` : `Choose your plan`} */}
                    Upgrade
                  </span>
                  <HowCloseBtn
                    data-component="HowCloseBtn"
                    onClick={() => closePopup()}
                  >
                    <i
                      className="icon-svg"
                      dangerouslySetInnerHTML={{ __html: closeIcon }}
                    ></i>
                  </HowCloseBtn>
                </UpgradePopHeader>
                <UpgradePopBody data-component="UpgradePopBody">
                  <TabContentWrap data-component="TabContentWrap">
                    <RoadMethodTab data-component="RoadMethodTab">
                      {/*<TabLeftSection data-component="TabLeftSection">
                                             {upgradeData.map((item, index) => (
                                                <TabBtn
                                                    data-component="TabBtn"
                                                    key={index}
                                                    active={RoadMethodData.id === item.id}
                                                    onClick={() => { setRoadMethodData(item); }}
                                                    className={`upgrade-tab-btn-${index}`}
                                                >
                                                    <a>{item.course_name}</a>
                                                </TabBtn>
                                            ))
                                            } 
                                            <TabBtn data-component="TabBtn" className={`upgrade-tab-btn-1`} active>
                                                <a>Platform Tools</a>
                                            </TabBtn>
                                        </TabLeftSection>*/}
                      <TabRightSection data-component="TabRightSection">
                        <TabRightSectionWrap
                          data-component="TabRightSectionWrap"
                          active={true}
                        >
                          {/* <p>{RoadMethodData.content}</p> */}
                          <p className="plan-heading">
                            Create your own combo's by selecting the number of
                            plants and customise the platform tools needed for
                            these plants:
                          </p>

                          {/* <div className={`combo-checked-wrap ${RoadMethodData.id === 1 ? `active` : ''}`}>
                                                    <div className="top-row">
                                                        <div className="input-col">
                                                            <input type="checkbox" name="input-checkbox" onChange={(e) => { handleAllInputCheck(); }} checked={checkedInput.length === comboData.length} />
                                                        </div>
                                                        <div className="course-name">
                                                            <span>Course Name</span>
                                                        </div>
                                                        <div className="user-num">
                                                            <span>No. of Users</span>
                                                        </div>
                                                        <div className="amount">
                                                            <span>Amount</span>
                                                        </div>
                                                    </div>
                                                    <div className="bottom-wrap">
                                                        {comboData.map((item, index) => (
                                                            <div className="top-row" key={index}>
                                                                <div className="input-col">
                                                                    <input id={`trainingCount${index}`} type="checkbox" name="input-demo" className="trainingSubTotalInput" onChange={(e) => { handleInputChecked(item.value); handleTrainingSubTotal(e) }} checked={checkedInput.includes(item.value)} data-value={JSON.stringify(item)} />
                                                                </div>
                                                                <div className="course-name">
                                                                    <span>{item.course_name}</span>
                                                                    <InfoWrapper data-component="InfoWrapper">
                                                                        <button className="info-btn" onClick={() => handleInfoPopup(index)}>
                                                                            <i dangerouslySetInnerHTML={{ __html: infoIcon }}></i>
                                                                        </button>
                                                                        {informationPopup === index &&
                                                                            <div className="info-popup">
                                                                                <div className="pop-head">
                                                                                    <span>More information</span>
                                                                                    <button className="info-close-btn" onClick={() => setInformationPopup(false)}>
                                                                                        <i dangerouslySetInnerHTML={{ __html: infoCloseBtn }}></i>
                                                                                    </button>
                                                                                </div>
                                                                                <div className="pop-body">
                                                                                    <p>{item.info}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </InfoWrapper>

                                                                </div>
                                                                <div className="user-num" style={{ pointerEvents: checkedInput.includes(item.value) ? `all` : 'none' }}>
                                                                    <CountDropdown onChange={(val) => { handleTrainingCount(val, `trainingCount${index}`); }} defaultValue={1} />
                                                                </div>
                                                                <div className="amount">
                                                                    <span>{item.price}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div> */}

                          <div className={`second-tab-data active`}>
                            {blankCombo ? (
                              <div className="blank-combo-wrap">
                                <i
                                  dangerouslySetInnerHTML={{ __html: blankSvg }}
                                ></i>
                                <p>
                                  Combo’s created by you are added to the cart,
                                  <br />
                                  Create new Combo here
                                </p>
                                <button
                                  onClick={() => {
                                    setBlankCombo(false);
                                    setBlueCart(false);
                                    setGreenCart(false);
                                    setTotalCart(true);
                                  }}
                                >
                                  + Add Combo
                                </button>
                              </div>
                            ) : (
                              <ComboPopWrapper data-component="ComboPopWrapper">
                                {comboDataPopup.map(
                                  (combo, comboPopupIndex) => (
                                    <ComboPopCard
                                      data-component="ComboPopCard"
                                      key={comboPopupIndex}
                                    >
                                      <ComboHead data-component="ComboHead">
                                        <div className="combo-del-wrap">
                                          <span className="combo-title">
                                            {comboPopupIndex == 0 &&
                                            upgradeSection
                                              ? plantName
                                              : `Combo ${
                                                  plantName
                                                    ? comboPopupIndex
                                                    : comboPopupIndex + 1
                                                }`}
                                          </span>
                                          {comboPopupIndex != 0 && (
                                            <button
                                              className="combo-delete-btn"
                                              onClick={() =>
                                                delComboData(
                                                  comboPopupIndex,
                                                  combo[0].combo_id
                                                )
                                              }
                                            >
                                              Delete
                                            </button>
                                          )}
                                        </div>
                                        {comboPopupIndex == 0 &&
                                        upgradeSection ? (
                                          ""
                                        ) : (
                                          <p className="selected-title">
                                            Select number of Plants:
                                            <div className="count-dropdown">
                                              <CountDropdown
                                                onChange={(val) =>
                                                  handleComboCount(
                                                    val,
                                                    comboPopupIndex,
                                                    comboDataPopup,
                                                    selectedComboData
                                                  )
                                                }
                                                defaultValue={combo[0].count}
                                                valueFromMain={combo[0].count}
                                              />
                                            </div>
                                          </p>
                                        )}
                                      </ComboHead>
                                      <ComboBody data-component="ComboBody">
                                        {combo.map((item, index) => (
                                          <div key={index}>
                                            {!(
                                              comboIdget?.includes(
                                                `200${index + 1}`
                                              ) && comboPopupIndex == 0
                                            ) ? (
                                              <div className="combo-row">
                                                <input
                                                  type="checkbox"
                                                  name="comboInput"
                                                  className={`combo${comboPopupIndex}Input`}
                                                  onChange={(e) => {
                                                    handleSubTotal(
                                                      e.target,
                                                      item.count
                                                    );
                                                    handleSelectedCombo(
                                                      e.target,
                                                      item,
                                                      comboPopupIndex
                                                    );
                                                    handleCheckUncheckCourse(
                                                      combo[0].combo_id,
                                                      index
                                                    );
                                                  }}
                                                  data-ComboIndex={
                                                    comboPopupIndex
                                                  }
                                                  data-type={item.type}
                                                  checked={comboCheckInputs[
                                                    combo[0].combo_id
                                                  ]?.includes(index)}
                                                />
                                                <div className="text">
                                                  <i
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.img,
                                                    }}
                                                  ></i>
                                                  <span>
                                                    {item.course_name}
                                                  </span>
                                                  <InfoWrapper data-component="InfoWrapper">
                                                    <button
                                                      className="info-btn"
                                                      onClick={() =>
                                                        handleInfoScndPopup(
                                                          `${comboPopupIndex}${index}`
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        dangerouslySetInnerHTML={{
                                                          __html: infoIcon,
                                                        }}
                                                      ></i>
                                                    </button>
                                                    {informationScndPopup === `${comboPopupIndex}${index}` && (
                                                      <div className="info-popup">
                                                        <div className="pop-head">
                                                          <span>
                                                            More information
                                                          </span>
                                                          <button
                                                            className="info-close-btn"
                                                            onClick={() =>
                                                              setInformationScndPopup(
                                                                false
                                                              )
                                                            }
                                                          >
                                                            <i
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  infoCloseBtn,
                                                              }}
                                                            ></i>
                                                          </button>
                                                        </div>
                                                        <div className="pop-body">
                                                          {item.info.map(
                                                            (p) => (
                                                              <p
                                                                dangerouslySetInnerHTML={{
                                                                  __html: p,
                                                                }}
                                                              ></p>
                                                            )
                                                          )}
                                                        </div>
                                                      </div>
                                                    )}
                                                  </InfoWrapper>
                                                </div>
                                                <span className="price-txt">
                                                  {item.price}
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ))}
                                        <p className="subtotal">
                                          Sub Total:{" "}
                                          <span>
                                            €{" "}
                                            {comboSubTotal[
                                              `combo${comboPopupIndex}Input`
                                            ]
                                              ? comboSubTotal[
                                                  `combo${comboPopupIndex}Input`
                                                ]
                                              : 0}
                                          </span>
                                        </p>
                                        <p className="bottom-text">
                                          *IR4.0 Strategies needs to be clubbed
                                          with IR 4.0 Technology Assessment and
                                          IR4.0 Loss Analysis or both.{" "}
                                        </p>
                                      </ComboBody>
                                    </ComboPopCard>
                                  )
                                )}
                                <AddComboBtn
                                  data-component="AddComboBtn"
                                  onClick={addComboData}
                                >
                                  + Add Combo
                                </AddComboBtn>
                              </ComboPopWrapper>
                            )}
                          </div>
                        </TabRightSectionWrap>
                      </TabRightSection>
                    </RoadMethodTab>
                  </TabContentWrap>
                </UpgradePopBody>
                <UpgradeFooter data-component="UpgradeFooter">
                  <TotalWrapper data-component="TotalWrapper">
                    {RoadMethodData.id === 1 && (
                      <p className="subtotal">
                        Sub Total: <span>€ {trainingSubTotal}</span>
                      </p>
                    )}
                    {totalCart && (
                      <TotalWrap
                        data-component="TotalWrap"
                        as="button"
                        onClick={() => handleTotalCart()}
                      >
                        <p className="total">
                          Total: <span>€ {cartTotal}</span>
                        </p>
                        <div className="cart-btn">
                          Add to Cart{" "}
                          <i
                            dangerouslySetInnerHTML={{ __html: arrowIcon }}
                          ></i>
                        </div>
                      </TotalWrap>
                    )}
                    {greenCart && (
                      <button
                        className="green-cart-btn"
                        onClick={() => {
                          setBlueCart(true);
                          setTotalCart(false);
                          setGreenCart(false);
                        }}
                      >
                        <i
                          dangerouslySetInnerHTML={{ __html: addedCartIcon }}
                        ></i>{" "}
                        Added to Cart
                      </button>
                    )}
                    {blueCart && (
                      <button
                        className="blue-cart-btn"
                        onClick={() => {
                          handleBlueCart();
                        }}
                      >
                        <i dangerouslySetInnerHTML={{ __html: cartIcon }}></i>{" "}
                        Go to Cart
                      </button>
                    )}
                  </TotalWrapper>
                </UpgradeFooter>
              </UpgradePopWrap>
            </UpgradePopWrapper>
          )}
        </MainWrapper>
        {/* MainWrapper ended */}
      </>
    );
}

