import React, { useRef, useState, useEffect } from "react";
import { LossAssumptionResultSetupCtrl } from "../LossAssumptionResultSetup/LossAssumptionResultSetupCtrl";
import { useParams, Redirect } from 'react-router-dom';
import serviceProvider from '../../services/axios';
import {
    PopupContainer,
    PopupCard,
    PopupCardHeader,
    PopupCardBody,
    HeaderH1,
    PopupCloseButton,
    HeaderButtons,
    SelectScenarioPopupWrapper,
    SelectScenarioRow,
    SelectScenarioCol,
    SelectScenarioCard,
    SeclectScenarioHeader,
    SelectScenarioFooter,
    AddScenarioPopupWrapper,
    AddScenarioCard,
    AddInputWrap,
    AddScenarioFooter,
    BackBtnWrap,
    TopLossCategorySection,
    LossCatPopupWrapper,
    LossCatPopup,
    LossCatPopupCard,
    LossCatgoryImageLabel,
    LossMainCard,
    LossMainRow,
    LossMainCol,
    LossSubCard,
    LossSubRow,
    LossSubCol,
    TooltipWrap,
    ToolTipCard,
    ScenarioWrapper,
    ScenarioHead,
    GeneratReportButton,
    ChangesSavedButton,
    LoaderPopupWrap,
    LoaderPop
} from './loss-result-styles';
import {
    closeSvg,
    backBtnSvg,
    reviewScenarioSvg,
    createScenarioSvg,
    infoSvg,
    productionSvg,
    plannedSvg,
    unplannedSvg,
    minorSvg,
    speedSvg,
    materialSvg,
    operatorSvg,
    maintenanceLossesSvg,
    ehsLossesSvg,
    itLossesSvg,
    warehouseLossesSvg,
    qualityLossesSvg,
    keyPlantSvg,
    savedChangesSvg
} from "./svg";
export const LossResultSetupPopView = (props) => {
    const { nullPage, reviewScenario, closeFunction = () => { }, isSelectScenarioPopOpen, slug = props.slug, plantName = props.plant_name, pid, setIsResonCodePopup, setScenarioArr, scenarioArr, isRecoverableButtonClick = { isRecoverableButtonClick } } = props || {};
    const closeMainPopup = () => {
        closeFunction(false);
        localStorage.removeItem('isReasonCodePopUp');
        localStorage.removeItem('popuptype');
    }
    const user = JSON.parse(localStorage.getItem("userData"));
    const uid = user ? user.uid : 0;
    const [reviewScenarioPopup, setReviewScenarioPopup] = useState(false);
    const [selectScenarioPopup, setIsSelectScenarioPopup] = useState(isSelectScenarioPopOpen == 'add' || isSelectScenarioPopOpen == `incomplete-setup` ? false : true);
    const [addScenarioPopup, setIsAddScenarioPopup] = useState(isSelectScenarioPopOpen == 'add' ? true : isSelectScenarioPopOpen == `incomplete-setup` ? false : false);
    const [updateScenarioPopup, setIsUpdateScenarioPopup] = useState(false);
    const [backToUpdateScenarioPopup, setIsbackToUpdateScenarioPopup] = useState(false);
    const [lossAssumptionPopupShow, setLossAssumptionPopupShow] = useState(false);
    const [lossCatPopup, setIslossCatPopup] = useState(isSelectScenarioPopOpen == `incomplete-setup` ? true : false);
    const [lossAssumptionPopupData, setLossAssumptionPopupData] = useState({});
    // variable to use in assumption popup (to close save button)
    const [isSaveReasonEditMode, setIsSaveReasonEditMode] = useState(false);
    const [pageValue, setPageValue] = useState('');
    const [senarioErr, setSenarioErr] = useState(false);
    const [tmpScenario, setTmpScenario] = useState(reviewScenario || nullPage || '');
    const [isSaved, setIsSaved] = useState(false);
    const [loading, setLoading] = useState(false);
    const lossMaincCatData = [
        {
            id: 1,
            main_title: `Production Process Level`,
            main_img: productionSvg,
            main_info: infoSvg,
            main_color: `blue`,
            tooltip_title: `Production Process Level`,
            tooltip_text: `The average time that the production process or production line is not operating due to planned non-production activities that need to take place. `,
            sub_loss_cat: [
                {
                    id: 1,
                    title: `Line Efficiency Losses`,
                    sub_loss_cat_list: [
                        {
                            id: 1,
                            title: `Planned Downtime`,
                            img: plannedSvg,
                            tooltip_title: `Planned Downtime`,
                            tooltip_text: `The time a production process or line is not operating due to planned non-production activities that need to take place.`,
                            sid: 101,
                            ssid: 1,
                        },
                        {
                            id: 2,
                            title: `Unplanned Downtime`,
                            img: unplannedSvg,
                            tooltip_title: `Unplanned Downtime`,
                            tooltip_text: `The time a production process or line is not operating due to unplanned events.`,
                            sid: 101,
                            ssid: 2,
                        },
                        {
                            id: 3,
                            title: `Minor Stops`,
                            img: minorSvg,
                            tooltip_title: `Minor Stops`,
                            tooltip_text: `The time the production process or line is not
                            operating due to small stops (usually with a
                            duration of less than 5 minutes).`,
                            sid: 101,
                            ssid: 3,
                        },
                        {
                            id: 4,
                            title: `Speed Losses`,
                            img: speedSvg,
                            tooltip_title: `Speed Losses`,
                            tooltip_text: `The time the production process or line is running
                            slower than the Ideal Cycle Time (e.g. less than
                            design speed).`,
                            sid: 101,
                            ssid: 4,
                        },
                        {
                            id: 5,
                            title: `Material Waste`,
                            img: materialSvg,
                            tooltip_title: `Material Waste`,
                            tooltip_text: `The cost of units scrapped during the production
                            process leading to material waste.`,
                            sid: 101,
                            ssid: 5,
                        },
                    ]
                },
                {
                    id: 2,
                    title: `Operator Effort Losses`,
                    sub_loss_cat_list: [
                        {
                            id: 103,
                            title: `Operator Losses`,
                            img: operatorSvg,
                            tooltip_class: `middle`,
                            tooltip_title: `Operator Losses`,
                            tooltip_text: `The key routine activities taking place by the
                            direct labor (line operators/technicians) on the
                            production lines including any 3rd party
                            contractors.`,
                            sid: 103,
                            ssid: 0,
                        },
                    ]
                },
            ]
        },
        {
            id: 2,
            main_title: `Key Plant Functions`,
            main_img: keyPlantSvg,
            main_info: infoSvg,
            main_color: `green`,
            tooltip_title: `Key Plant Functions`,
            tooltip_text: `Consists of key activities taking place by the main supporting functions of the plant operations.`,
            sub_loss_cat: [
                {
                    id: 1,
                    title: `Key Plant Functions`,
                    sub_loss_cat_list: [
                        {
                            id: 107,
                            title: `Maintenance Losses`,
                            img: maintenanceLossesSvg,
                            tooltip_title: `Maintenance Losses`,
                            tooltip_text: `The key routine activities taking place by the Maintenance or Engineering function of the plant.`,
                            sid: 107,
                            ssid: 0,
                        },
                        {
                            id: 106,
                            title: `IT Losses`,
                            img: itLossesSvg,
                            tooltip_title: `IT Losses`,
                            tooltip_text: ` The key routine activities taking place by the IT function of the plant.`,
                            sid: 106,
                            ssid: 0,
                        },
                        {
                            id: 102,
                            title: `Warehouse Losses`,
                            img: warehouseLossesSvg,
                            tooltip_title: `Warehouse Losses`,
                            tooltip_text: `The key routine activities taking place by the Warehouse or Material Handling function of the plant.`,
                            sid: 102,
                            ssid: 0,
                        },
                        {
                            id: 104,
                            title: `EHS Losses`,
                            img: ehsLossesSvg,
                            tooltip_title: `EHS Losses`,
                            tooltip_text: `The key routine activities taking place by the EHS function of the plant.`,
                            tooltip_class: `middle`,
                            sid: 104,
                            ssid: 0,
                        },
                        {
                            id: 105,
                            title: `Quality Losses`,
                            img: qualityLossesSvg,
                            tooltip_title: `Quality Losses`,
                            tooltip_text: `The key routine activities taking place by the Quality function of the plant.`,
                            sid: 105,
                            ssid: 0,
                        },
                    ]
                },
            ]
        },
    ]
    const backToMainPopup = (type) => {
        if (type === `addScenario`) {
            setIslossCatPopup(false);
            setIsAddScenarioPopup(false);
            let popupType = localStorage.getItem(`popuptype`);
            if (popupType) {
                setIsSelectScenarioPopup(false);
                setIsUpdateScenarioPopup(true);
            }
            else {
                setIsSelectScenarioPopup(true);
                setIsUpdateScenarioPopup(false);
            }
            if (backToUpdateScenarioPopup) {
                setIsUpdateScenarioPopup(true);
                setIsAddScenarioPopup(false);
                setIsSelectScenarioPopup(false);
                setIslossCatPopup(false);
            }
            // else{
            //     setIsUpdateScenarioPopup(false);
            //     setIsAddScenarioPopup(false);
            //     setIsSelectScenarioPopup(true);
            //     setIslossCatPopup(false);
            // }
        }
        if (type === `lossCat`) {

            if (reviewScenarioPopup) {
                setIsAddScenarioPopup(false);
                setIsSelectScenarioPopup(true);
                setIslossCatPopup(false);
            }
            else {
                setIslossCatPopup(false);
                setIsSelectScenarioPopup(false);
                setIsAddScenarioPopup(true);
            }

        }
        if (type === `updatescenario`) {
            let tmpData = localStorage.getItem('isReasonCodePopUp');
            setLossAssumptionPopupShow(JSON.parse(tmpData));
            setIslossCatPopup(true);
            setIsSelectScenarioPopup(false);
            setIsAddScenarioPopup(false);
            setIsUpdateScenarioPopup(false);
        }
    }
    const handleCreateScenario = () => {
        setIsAddScenarioPopup(true);
        setIsSelectScenarioPopup(false);
        setIsUpdateScenarioPopup(false);
        localStorage.setItem(`popuptype`, `true`);
    }

    const handleReviewScenario = () => {
        setIslossCatPopup(true);
        setIsSelectScenarioPopup(false);
        setIsUpdateScenarioPopup(false);
        setIsAddScenarioPopup(false);
    }

    const handleVendorFunction = () => {
        if (pageValue) {
            serviceProvider.post("/Businesscaseplantloss?type=savescenarioloss", JSON.stringify({ pid: pid, uid: uid, name: pageValue })).then((response) => {
                let sid = response.data;
                setTmpScenario({
                    id: sid,
                    name: pageValue,
                    status: 0
                });
                setScenarioArr(prv => {
                    return [
                        ...prv,
                        {
                            id: sid,
                            name: pageValue,
                            status: 0
                        }
                    ]
                })
                setIslossCatPopup(true);
                setIsAddScenarioPopup(false);
                setPageValue('');
            });

        }
    }

    const handlePageName = (e) => {
        let val = e.target.value || '';
        let isScenarioExist = scenarioArr?.filter(i => i.name.toLowerCase() == val.toLowerCase());
        setPageValue(val);
        if (isScenarioExist?.length > 0) {
            setSenarioErr('Senarion already exists.');
        } else if (senarioErr) {
            setSenarioErr(false);
        }


    }

    const handleSelectWithBackToReasonCodePopup = () => {
        let tmpData = localStorage.getItem('isReasonCodePopUp');
        setLossAssumptionPopupShow(JSON.parse(tmpData));
        setIsSaveReasonEditMode(`saved`);
    }

    const handleDiscardValue = () => {
        setPageValue('');
    }


    const generateReportFunction = () => {
        setLoading(prv => true);
        serviceProvider
            .post(
                "/LossCalculation/calculatelossapi/",
                { uid: uid, slug: slug, sc_id: tmpScenario.id },
                true
            )
            .then((response) => {
                //recover
                serviceProvider
                    .post(
                        "/RecoverableCalculation/index/",
                        { uid: uid, slug: slug },
                        true
                    )
                    .then((response) => {
                        window.location = `/lossresults/${slug}/${tmpScenario.id}`;
                        setIsSaved(true);
                        setTimeout(() => {
                            closeFunction(false);
                            setIsSaved(prv => false);
                        }, 3000)
                        setLoading(prv => false);

                    });
                //recover
            });



    }


    // 08-01-2024
    return (
        <>
            <PopupContainer data-component="PopupContainer" style={{ display: !lossAssumptionPopupShow ? `` : `none` }}>
                <PopupCard data-component="PopupCard">
                    <PopupCardHeader data-component="PopupCardHeader">
                        <HeaderH1 data-component="HeaderH1">
                            {addScenarioPopup ?
                                <BackBtnWrap data-component="BackBtnWrap">
                                    {isSelectScenarioPopOpen != `add` && isSelectScenarioPopOpen != 'new-result' ?
                                        <button className="back-btn" onClick={() => backToMainPopup(`addScenario`)}>
                                            <i dangerouslySetInnerHTML={{ __html: backBtnSvg }}></i>
                                            <span>Back</span>
                                        </button>
                                        :
                                        <></>
                                    }

                                    <p>Add Scenario</p>
                                </BackBtnWrap>
                                :
                                lossCatPopup ?
                                    <BackBtnWrap data-component="BackBtnWrap">
                                        {isSelectScenarioPopOpen != `incomplete-setup` &&
                                            <button className="back-btn" onClick={() => backToMainPopup(`lossCat`)}>
                                                <i dangerouslySetInnerHTML={{ __html: backBtnSvg }}></i>
                                                <span>Back</span>
                                            </button>
                                        }
                                        <p>{plantName}: Loss Elimination Set Up </p>
                                    </BackBtnWrap>
                                    :
                                    updateScenarioPopup ?
                                        <BackBtnWrap data-component="BackBtnWrap">
                                            <button className="back-btn" onClick={() => { backToMainPopup(`updatescenario`); setIsSaveReasonEditMode(prv => !prv); }}>
                                                <i dangerouslySetInnerHTML={{ __html: backBtnSvg }}></i>
                                                <span>Back</span>
                                            </button>
                                            <p>{plantName}: Update Current Recoverable Loss </p>
                                        </BackBtnWrap>
                                        :
                                        <p>{plantName}: Loss Recoverable's Review</p>
                            }
                        </HeaderH1>
                        <HeaderButtons data-component="HeaderButtons">
                            <PopupCloseButton data-component="PopupCloseButton" onClick={closeMainPopup}>
                                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                            </PopupCloseButton>
                        </HeaderButtons>
                    </PopupCardHeader>
                    <PopupCardBody data-component="PopupCardBody">
                        {selectScenarioPopup &&
                            <SelectScenarioPopupWrapper data-component="SelectScenarioPopupWrapper">
                                <ScenarioWrapper data-component="ScenarioWrapper">
                                    <span className="label">Current Scenario</span>
                                    <ScenarioHead data-component="ScenarioHead">
                                        <span className="scenario-name">{tmpScenario.name || `Loss Analysis (Default)`}</span>
                                    </ScenarioHead>
                                </ScenarioWrapper>
                                <SelectScenarioRow data-component="SelectScenarioRow">
                                    <SelectScenarioCol data-component="SelectScenarioCol">
                                        <SelectScenarioCard data-component="SelectScenarioCard">
                                            <SeclectScenarioHeader data-component="SeclectScenarioHeader">
                                                <i dangerouslySetInnerHTML={{ __html: reviewScenarioSvg }}></i>
                                                <span>Review Current Scenario</span>
                                            </SeclectScenarioHeader>
                                            <div className="text-wrap">
                                                <p className="text">Continue using the same scenario and make changes to the Loss Elimination Setup</p>
                                                <p className="text">Please note: Changes made will have an irreversible impact. We suggest you to create a new scenario instead.</p>
                                            </div>
                                            <SelectScenarioFooter data-component="SelectScenarioFooter">
                                                <button className="select-btn" onClick={() => { handleReviewScenario(); setReviewScenarioPopup(true); closeFunction(`review`); }}>SELECT</button>
                                            </SelectScenarioFooter>
                                        </SelectScenarioCard>
                                    </SelectScenarioCol>
                                    <SelectScenarioCol data-component="SelectScenarioCol">
                                        <SelectScenarioCard data-component="SelectScenarioCard">
                                            <SeclectScenarioHeader data-component="SeclectScenarioHeader">
                                                <i dangerouslySetInnerHTML={{ __html: createScenarioSvg }}></i>
                                                <span>Create New Scenario</span>
                                            </SeclectScenarioHeader>
                                            <div className="text-wrap">
                                                <p className="text">By choosing this option the existing results will be copied.</p>
                                                <p className="text">You can make changes in the Loss Elimination Setup.</p>
                                            </div>
                                            <SelectScenarioFooter data-component="SelectScenarioFooter">
                                                <button className="select-btn" onClick={() => { handleCreateScenario(); setReviewScenarioPopup(false); closeFunction(`add`); }}>SELECT</button>
                                            </SelectScenarioFooter>
                                        </SelectScenarioCard>
                                    </SelectScenarioCol>
                                </SelectScenarioRow>
                            </SelectScenarioPopupWrapper>
                        }
                        {addScenarioPopup &&
                            <AddScenarioPopupWrapper data-component="AddScenarioPopupWrapper">
                                <AddScenarioCard data-component="AddScenarioCard">
                                    <span className="card-head">Page Name</span>
                                    <AddInputWrap data-component="AddInputWrap">
                                        <input type="text" className={senarioErr ? 'input-error' : ''} placeholder="Enter page name" onChange={(e) => handlePageName(e)} value={pageValue} />
                                        <p className={`error ${senarioErr ? "show" : ""}`}>{senarioErr}</p>
                                    </AddInputWrap>
                                    <AddScenarioFooter data-component="AddScenarioFooter">
                                        <button className="discard-btn btn" onClick={() => handleDiscardValue()}>Discard</button>
                                        <button className={`vendor-btn btn ${(pageValue.length > 0 && !senarioErr) ? `active` : ``}`} onClick={() => handleVendorFunction()}>ADD SCENARIO</button>
                                    </AddScenarioFooter>
                                </AddScenarioCard>
                            </AddScenarioPopupWrapper>
                        }
                        {lossCatPopup &&
                            <LossCatPopupWrapper data-component="LossCatPopupWrapper">
                                <LossCatPopup data-component="LossCatPopup">
                                    <ScenarioWrapper data-component="ScenarioWrapper">
                                        <span className="label">Current Scenario</span>
                                        <ScenarioHead data-component="ScenarioHead">
                                            <span className="scenario-name">{tmpScenario.name || `Loss Analysis (Default)`}</span>
                                        </ScenarioHead>
                                    </ScenarioWrapper>
                                    <LossCatPopupCard data-component="LossCatPopupCard">
                                        <p>Choose the loss category to view / modify Loss Estimation</p>
                                        {lossMaincCatData.map((item, index) => (
                                            <TopLossCategorySection data-component="TopLossCategorySection" key={index}>
                                                <LossCatgoryImageLabel data-component="LossCatgoryImageLabel" className={`${item.main_color === `blue` ? `blue-class` : item.main_color === `green` ? `green-class` : ''}`}>
                                                    <i dangerouslySetInnerHTML={{ __html: item.main_img }} className="loss-icon"></i>
                                                    <span className="label">{item.main_title}</span>
                                                    <i dangerouslySetInnerHTML={{ __html: infoSvg }} className="info-icon"></i>
                                                    <TooltipWrap data-component="TooltipWrap">
                                                        <ToolTipCard data-component="ToolTipCard">
                                                            <h3 className="title">{item.tooltip_title}</h3>
                                                            <p className="text">{item.tooltip_text}</p>
                                                        </ToolTipCard>
                                                    </TooltipWrap>
                                                </LossCatgoryImageLabel>
                                                <LossMainRow data-component="LossMainRow" className={`${item.main_color === `blue` ? `blue-class` : item.main_color === `green` ? `green-class` : ''}`}>
                                                    {item.sub_loss_cat.map((sub_item, sub_index) => (
                                                        <LossMainCol data-component="LossMainCol">
                                                            <span className="loss-main-label">{sub_item.title}</span>
                                                            <LossMainCard data-component="LossMainCard">
                                                                <LossSubRow data-component="LossSubRow">
                                                                    {sub_item.sub_loss_cat_list.map((sub_cat_item, sub_cat_index) => (
                                                                        <LossSubCol data-component="LossSubCol">
                                                                            <LossSubCard data-component="LossSubCard" onClick={() => { setLossAssumptionPopupData(sub_cat_item); setLossAssumptionPopupShow(true) }}>
                                                                                <i dangerouslySetInnerHTML={{ __html: sub_cat_item.img }}></i>
                                                                                <span className="loss-text">{sub_cat_item.title}</span>
                                                                                <TooltipWrap data-component="TooltipWrap" className={`${sub_cat_item.tooltip_class === `middle` ? `right-tooltip` : ``}`}>
                                                                                    <ToolTipCard data-component="ToolTipCard">
                                                                                        <h3 className="title">{sub_cat_item.tooltip_title}</h3>
                                                                                        <p className="text">{sub_cat_item.tooltip_text}</p>
                                                                                    </ToolTipCard>
                                                                                </TooltipWrap>
                                                                            </LossSubCard>
                                                                        </LossSubCol>
                                                                    ))}
                                                                </LossSubRow>
                                                            </LossMainCard>
                                                        </LossMainCol>
                                                    ))}
                                                </LossMainRow>
                                            </TopLossCategorySection>
                                        ))}

                                    </LossCatPopupCard>
                                </LossCatPopup>
                            </LossCatPopupWrapper>
                        }
                        {updateScenarioPopup &&
                            <SelectScenarioPopupWrapper data-component="SelectScenarioPopupWrapper">
                                <ScenarioWrapper data-component="ScenarioWrapper">
                                    <span className="label">Current Scenario</span>
                                    <ScenarioHead data-component="ScenarioHead">
                                        <span className="scenario-name">{tmpScenario.name || `Loss Analysis (Default)`}</span>
                                    </ScenarioHead>
                                </ScenarioWrapper>
                                <p className="update-loss-text">The
                                    {/*<span className="bold-text"> [Line Name] - Loss Ideal State</span> for <span className="bold-text">'Loss THEME 1'</span> will be increased from <span className="bold-text">30% to 33%</span>. This will have an impact on the overall loss elimination for Planned downtime. */}
                                    {updateScenarioPopup.text}
                                </p>
                                <SelectScenarioRow data-component="SelectScenarioRow">
                                    <SelectScenarioCol data-component="SelectScenarioCol">
                                        <SelectScenarioCard data-component="SelectScenarioCard">
                                            <SeclectScenarioHeader data-component="SeclectScenarioHeader">
                                                <i dangerouslySetInnerHTML={{ __html: reviewScenarioSvg }}></i>
                                                <span>Save on the existing scenario</span>
                                            </SeclectScenarioHeader>
                                            <div className="text-wrap">
                                                <p className="text">By continuing to save changes made on the existing scenario will have an irreversible impact.</p>
                                                <p className="text"> We suggest you to create a new scenario instead.</p>
                                            </div>
                                            <SelectScenarioFooter data-component="SelectScenarioFooter">
                                                <button className="select-btn" onClick={() => {
                                                    updateScenarioPopup.proceedFunction();
                                                    handleSelectWithBackToReasonCodePopup();
                                                    setIsbackToUpdateScenarioPopup(false);
                                                }}>SELECT</button>
                                            </SelectScenarioFooter>
                                        </SelectScenarioCard>
                                    </SelectScenarioCol>
                                    <SelectScenarioCol data-component="SelectScenarioCol">
                                        <SelectScenarioCard data-component="SelectScenarioCard">
                                            <SeclectScenarioHeader data-component="SeclectScenarioHeader">
                                                <i dangerouslySetInnerHTML={{ __html: createScenarioSvg }}></i>
                                                <span>Create New Scenario</span>
                                            </SeclectScenarioHeader>
                                            <div className="text-wrap">
                                                <p className="text">By choosing this option the changes you have made in the Loss Elimination Setup will be applied to a new scenario.</p>
                                            </div>
                                            <SelectScenarioFooter data-component="SelectScenarioFooter">
                                                <button className="select-btn" onClick={() => { handleCreateScenario(); setIsbackToUpdateScenarioPopup(true); }}>SELECT</button>
                                            </SelectScenarioFooter>
                                        </SelectScenarioCard>
                                    </SelectScenarioCol>
                                </SelectScenarioRow>
                            </SelectScenarioPopupWrapper>
                        }
                    </PopupCardBody>
                    {((isSelectScenarioPopOpen == 'add' || nullPage || reviewScenario) && !isSaved && lossCatPopup) &&
                        <GeneratReportButton data-component="GeneratReportButton" onClick={() => generateReportFunction()}>
                            Generate report
                        </GeneratReportButton>
                    }
                    {isSaved &&
                        <ChangesSavedButton data-component="ChangesSavedButton" disabled>
                            <i dangerouslySetInnerHTML={{ __html: savedChangesSvg }} /> Report generated successfully
                        </ChangesSavedButton>
                    }
                    {loading &&
                        <LoaderPopupWrap data-component="LoaderPopupWrap">
                            <LoaderPop data-component="LoaderPop">
                                <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
                            </LoaderPop>
                        </LoaderPopupWrap>
                    }
                </PopupCard>
            </PopupContainer>
            <div style={{ display: lossAssumptionPopupShow ? `` : `none` }}>
                <LossAssumptionResultSetupCtrl
                    pid={pid}
                    tmpScenario={tmpScenario}
                    setIsUpdateScenarioPopup={setIsUpdateScenarioPopup}
                    setLossAssumptionPopupShow={setLossAssumptionPopupShow}
                    setIsAddScenarioPopup={setIsAddScenarioPopup}
                    setIslossCatPopup={setIslossCatPopup}
                    setIsSelectScenarioPopup={setIsSelectScenarioPopup}
                    closeFunction={closeMainPopup}
                    plantName={plantName}
                    lossAssumptionPopupData={lossAssumptionPopupData}
                    isSaveReasonEditMode={isSaveReasonEditMode}
                    resonCodePopupData={(typeof lossAssumptionPopupShow !== 'boolean' && lossAssumptionPopupShow) ? lossAssumptionPopupShow : false}
                />
            </div>
        </>
    )
}
