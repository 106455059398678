/** imported components
 
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * LimitedResultView ===> custom view component

**/
import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { LimitedResultView } from "./limitedresultplant-view";
//LimitedResultPlantController start
export const LimitedResultPlantController = (props) => {
  //defining variables here
  const [results, setResults] = useState([]);
  const [refresh, setRefresh] = useState("");
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  useEffect(() => {
    props.handleExpandFal();
    serviceProvider
      .post(
        "/home?type=setfirstresultseen",
        JSON.stringify({ uid: usid }),
        true
      )
      .then((response) => {
        // console.log(response);
      });
    const search = async () => {
      const apiUrl = `/results?param=result&uid=${usid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults(data);
    };
    search();
  }, []);
  return <LimitedResultView resultsData={results} {...props} />;
};
