import React from "react";
// import './style-bkp.css'
export const Preloader = ({
  preLoaderMsg = "Give us a moment while we generate your IR4.0 loss analysis results for your factory",
  whiteBG=false
}) => {
  return (
    <div className={`preloader active ${whiteBG ? 'preloader-bg-white':''}`}>
      <div className="pre-wrap-content">
        <img
          src={require("../../assets/img/common-img/pre-loader.gif").default}
          width="230px"
          alt="true"
        />
        <p className="pre-txt">{preLoaderMsg}</p>
      </div>
    </div>
  );
};
