import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import serviceProvider from '../../services/axios';
import qnaSIRIGuidanceData from './qnaSIRIGuidanceData.js'
import {
    closeSvg, closePopSvg, openPopSvg, tickSvg,
} from './qna-svg.js';

import {
    SiriQNAPopWrapper,
    SiriQNAPopWrap,
    SiriQNAHead,
    Title,
    SiriQNABody,
    TabContentWraaper,
    TabPanelWrapper,
    TABQNAHead,
    TabAnsTable,
    TabAnsRow,
    TabAnsCol,
    TabValueCol,
    TextAreaWrapper,
    ImageWrapper,
    FooterWrap,
    ZoomImageContainer,
} from './siri-qna-style.js';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <>{children}</>}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const SiriQNAPopView = (props) => {
    const [value, setValue] = React.useState(0);
    const [qnaDataDataOpen, setQnaDataOpen] = React.useState({ asmtArray: false, guideArray: false });
    const { open, setOpen = () => { }, dataList, dismention_index } = props;
    //function to handle change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [AsmtArray, setAsmtArray] = useState([]);
    const [AsmtArraystatus, setAsmtArraystatus] = useState(0);
    const [guideObj, setGuideObj] = useState({});

    const handleLeftInnerTabClick = (val, sectionName) => {
        if (qnaDataDataOpen[sectionName] === val) {
            setQnaDataOpen(prv => {
                return { ...prv, [sectionName]: false };
            });
        }
        else {
            setQnaDataOpen(prv => {
                return { ...prv, [sectionName]: val };
            });
        }
    };

    useEffect(() => {
        const getQAN = async () => {
            const apiUrl = `/siri?param=assignedqa&plant_id=${dataList?.plant_id}&bandcid=${dataList.band_criteria[0].id}`;
            const { data } = await serviceProvider.get(apiUrl, true, {});
            const data1 = Object.values(data);

            if (data1?.length == 0) {
                setAsmtArraystatus(1);
            }
            setAsmtArray(data1);
        };
        getQAN();
    }, []);
    useEffect(() => {
        let tmpObj = qnaSIRIGuidanceData?.find(i => i.dimension == dismention_index) || [];
        setGuideObj(tmpObj.bandList[dataList.count] || {});
    }, [dismention_index])
    return (
        <>
            {open &&
                <SiriQNAPopWrapper data-component="SiriQNAPopWrapper">
                    <SiriQNAPopWrap data-component="SiriQNAPopWrap">
                        <SiriQNAHead data-component="SiriQNAHead">
                            <Title data-component="Title">{dataList.band} - {dataList.band_name}: {dataList.band_criteria[0].name}</Title>
                            <button className='close-btn' onClick={() => setOpen(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                            </button>
                        </SiriQNAHead>
                        <SiriQNABody data-component="SiriQNABody">
                            <TabContentWraaper data-component="TabContentWraaper">
                                <Tabs
                                    orientation="horizontal"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"

                                >
                                    <Tab label="Assessment " {...a11yProps(0)} />
                                    <Tab label="Guidance" {...a11yProps(1)} />
                                </Tabs>
                            </TabContentWraaper>
                            <TabPanelWrapper data-component="TabPanelWrapper">
                                <TabPanel value={value} index={0} className="tab-panel">
                                    {(AsmtArraystatus == 1 && AsmtArray?.length == 0) && <h1>No questions associated.Please discuss with your Assessment Administrator.</h1>}
                                    {AsmtArray?.map((item, index) => (
                                        <div key={index} className='tab-wrapper'>
                                            <TABQNAHead data-component="TABQNAHead" onClick={() => handleLeftInnerTabClick(item.id, `asmtArray`)} className={`${qnaDataDataOpen === `qna0-${index}` ? 'active' : ''}`}>
                                                <p>Q{index + 1}. {item.question}</p>
                                                {qnaDataDataOpen.asmtArray === item.id ? <i dangerouslySetInnerHTML={{ __html: openPopSvg }}></i> : <i dangerouslySetInnerHTML={{ __html: closePopSvg }}></i>}
                                            </TABQNAHead>
                                            <Collapse in={true} timeout="auto">
                                                <TabAnsTable data-component="TabAnsTable">
                                                    <TabAnsRow data-component="TabAnsRow">
                                                        <TabAnsCol data-component="TabAnsCol"><p>ANSWERS</p></TabAnsCol>
                                                        <TabValueCol data-component="TabValueCol"><p>HAS</p></TabValueCol>
                                                        <TabValueCol data-component="TabValueCol"><p>HAS NOT</p></TabValueCol>
                                                        <TabValueCol data-component="TabValueCol"><p>SKIPPED</p></TabValueCol>
                                                    </TabAnsRow>
                                                    {item.answers.map((ans, index) => (
                                                        <TabAnsRow data-component="TabAnsRow" className={index == 0 ? `bottom-row` : ''}>
                                                            <TabAnsCol data-component="TabAnsCol">
                                                                <p>{index + 1}. {ans.answer}</p>
                                                            </TabAnsCol>
                                                            <TabValueCol data-component="TabValueCol">
                                                                {ans.selected == `has` && <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>}
                                                            </TabValueCol>
                                                            <TabValueCol data-component="TabValueCol">
                                                                {ans.selected == `hasnot` && <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>}
                                                            </TabValueCol>
                                                            <TabValueCol data-component="TabValueCol">
                                                                {ans.selected == `skipped` && <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>}
                                                            </TabValueCol>
                                                        </TabAnsRow>
                                                    ))}
                                                </TabAnsTable>
                                            </Collapse>
                                        </div>
                                    ))}
                                </TabPanel>
                                <TabPanel value={value} index={1} className="tab-panel">
                                    <Collapse in={true} timeout="auto">
                                        <h1 className='example-text-title'>Example</h1>
                                        {dataList?.example?.map((i, ind) => (
                                            <div className='example-text-div' key={ind}>
                                                <p className='example-text'>
                                                    {i}
                                                </p>
                                            </div>
                                        ))}
                                    </Collapse>
                                </TabPanel>
                            </TabPanelWrapper>
                        </SiriQNABody>
                    </SiriQNAPopWrap>
                </SiriQNAPopWrapper>
            }
        </>
    )
}