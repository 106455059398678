import React, { useEffect, useRef, useState, useMemo } from "react";
import serviceProvider from '../../services/axios';
import { Helmet } from 'react-helmet';
import {
    PopupContainer,
    PopupCard,
    PopupCardHeader,
    PopupCardBody,
    HeaderH1,
    PopupCloseButton,
    QuestionsCard,
    QuestionsCardHeader,
    QuestionsCardBody,
    QuestionsCardRow,
    QuestionsCardCol1,
    QuestionsCardCol2,
    HeaderButtons,
    ResetDefaultButton,
    EditFiguresButton,
    LossInput,
    ViewButton,
    SaveChangesButton,
    ChangesSavedButton,
    CenteredPopupCard,
    CenteredPopupCancelButton,
    CenteredPopupProceedlButton,
    CenteredPopupFooter,
    BackBtnWrap,
    ReasonCodePopBody,
    ReasonCodePopup,
    ReasonCodePopHead,
    ReasonCodeTableHead,
    ReasonCodeTableRow,
    ReasonCodeTableLeftCol,
    ReasonCodeTableMidCol,
    ReasonCodeTableRightCol,
    ReasonCodeTableBody,
    ReasonCodeTableFooter,
    ReasonCodeTableRightDelCol,
    AddReasonBox,
    SetupDropDown,
    SetUpCard,
    SetUpCardRow,
    SetUpCardCol,
    SetUpCardWrap,
    SetUpImageWrap,
    IndividualQuestionsCard,
    IndividualQuestionCardWrap,
    IndividualPopupCardBody,
    LossNotificationPopup,
    LossNotificationPopupCard,
    LossNotificationPopupHead,
    LossNotificationPopupFooter,
    LossNotificationPopupList,
    ScenarioWrapper,
    ScenarioHead,
    ThingsToKeepPopupContainer,
    ThingsToKeepPopupCard,
    ThingsToKeepHeader,
    ThingsToKeepPopBody,
    ThingsPopLeftSec,
    TechDescPopupContainer,
    TechDescPopupCard,
    TechDescHeader,
    TechDescPopBody,
    TechDescPopLeftSec,
    LossThemeDescPopupContainer,
    DropdownBox,
    DropdownOption,
    InfoMsg
} from './styles'
import {
    closeSvg,
    savedChangesSvg,
    viewButtonSvg,
    backBtnSvg,
    dropdownIcon,
    tickSvg,
    oneFigureSvg,
    multipleLineSvg,
    bulbSvg,
    notificationBulbSvg,
    closeTechSvg,
    blueBulbSvg,
    techHeadIcon,
    infoIconSvg,
    moreInfoHeadIcon,
    descSvg
} from "./svg";
import { LossAssumptionCommentPopupCtrl } from './LossAssumptionCommentPopupCtrl.js';
import { LossAssumptionWalkthroughView } from './LossAssumptionWalkthroughView';



export const LossAssumptionResultSetupView = (props) => {
    // 08-01-2024
    const res = props.resultssetup || [{}];
    const defres = res[0].questions;
    const defres_reset = res[0].questions_reset;
    const resd = res[0].edited;
    const all_lines = res[0].all_lines || [];
    const all_lines_reset = res[0].all_lines_reset || [];
    let defaultQuestions = defres;
    const { isSetUpType, setIsSetupType, lossAssumptionPopupData, setIsUpdateScenarioPopup, setLossAssumptionPopupShow, setIsAddScenarioPopup, setIslossCatPopup, setIsSelectScenarioPopup, tmpScenario, pid } = props;
    const { ssid, sid } = lossAssumptionPopupData || {};
    const scid = tmpScenario.id;
    const [isEditMode, setIsEditMode] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isResetDefaultButton, setIsResetDefaultButton] = useState(resd);
    const [questions, setQuestions] = useState(defaultQuestions);
    const [questionsReset, setQuestionsReset] = useState(defres_reset);
    const [quesValues, setQuesValues] = useState({});
    const [quesMultiLineValues, setQuesMultiLineValues] = useState({});
    const [isThingToKeepPopupOpen, setIsThingToKeepPopupOpen] = useState(false);
    const [isTechnologyDescriptionPopOpen, setIsTechnologyDescriptionPopOpen] = useState(false);
    const [isLossThemeDescriptionPopOpen, setIsLossThemeDescriptionPopOpen] = useState(false);
    const existingReasons = res[0].defaultreason;
    const [existingCurrentReasons, setExistingCurrentReasons] = useState(existingReasons);
    //reason popup variables
    const centeredPopupObj = useRef({});
    const [isCenterdPopup, setIsCenterdPopup] = useState(false);
    const [isReasonCodePopUp, setIsReasonCodePopUp] = useState(false);
    const [isAddreasonInput, setIsAddreasonInput] = useState(false);
    const [newReasonText, setNewReasonText] = useState('');
    const [newReasonPercent, setNewReasonPercent] = useState('');
    const [isReasonEditMode, setIsReasonEditMode] = useState(false);
    const [reasonValues, setReasonValues] = useState({});
    const [commnetsCount, setCommentCounts] = useState({});
    const [dropdownReasons, setDropdownReasons] = useState(existingReasons || []);
    const [dropdownActive, setDropdownActive] = useState(false);
    // New Variables
    const [isLossGotBtn, setIsLossGotBtn] = useState(res[0].message)
    // variable for walkthrough
    const [walkthroughReasonButtonShow, setWalkthroughReasonButtonShow] = useState(false);
    const [isFirstTimeWalkthroughShow, setIsFirstTimeWalkthroughShow] = useState(res[0].walkthrough);
    const [includesCheckObj, setIncludesCheckObj] = useState({});
    const [autoFocus, setAutoFocus] = useState(false);
    const [autoEditValue, setAutoEditValue] = useState(false);
    const [focusInput, setFocusInput] = useState(false);
    const totalPercent = useRef();
    const reasonCode = [
        {
            id: 1,
            reason_title: `Training`,
            percent: `12%`,
            comments: 10,
        },
        {
            id: 2,
            reason_title: `Technology`,
            percent: `12%`,
            comments: 8,
        },
        {
            id: 3,
            reason_title: `New tooling`,
            percent: `12%`,
            comments: 4,
        },
    ];
    // Variables
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [{
        title: 'One figure for all Lines',
        value: 1,
    },
    {
        title: 'Line-wise individually',
        value: 2,
    },
    ];
    // comment popup variable
    const [isCommentPopup, setIsCommentPopup] = useState(false);
    // comment popup functions
    const openCommentPopup = (item, count, node_id) => {
        setIsCommentPopup({ item, scid, count, pid: props.pid, node_id });
    }
    const closeCommentPopup = (item, questionId) => {
        setIsCommentPopup(false);
    }

    const closeMainPopup = () => {
        props.closeFunction();
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option, val) => {
        setSelectedOption(option);
        setIsOpen(false);
        handlePopupLayout(val);
        setIsCenterdPopup(false);
    };

    function removeNonNumericCharacters(inputString) {
        var cleanedString = inputString.replace(/[^\d.]/g, '').trim();

        // Convert the string to a number
        var numberValue = parseFloat(cleanedString);

        // Check if the number is greater than 100
        if (numberValue > 100) {
            numberValue = 100; // Limit to 100
        }

        // Convert the number back to string with precision rules
        var formattedString = numberValue.toString();
        var decimalIndex = formattedString.indexOf('.');
        if (decimalIndex !== -1) {
            var decimalPart = formattedString.substring(decimalIndex + 1);
            if (decimalPart.length > 2) {
                formattedString = formattedString.substring(0, decimalIndex + 3);
            }
        }

        return formattedString == 'NaN' ? 0 : formattedString;
    }

    const handleOnChangeEditValue = (e, item) => {
        if (isEditMode) {
            let tmpObj = { ...quesValues };
            let val = e.target.value;
            if (val <= 100) {
                tmpObj[item.id] = val;
            }
            setQuesValues(tmpObj);
        }
    }

    const handleOnFocusValue = (e, item) => {
        if (isEditMode) {
            let val = removeNonNumericCharacters(e.target.value);
            let tmpObj = { ...quesValues };
            tmpObj[item.id] = val;
            setQuesValues(tmpObj);
        }
    }

    const handleOnBlurValue = (e, item) => {
        if (isEditMode) {
            let val = removeNonNumericCharacters(e.target.value);
            let tmpObj = { ...quesValues };
            tmpObj[item.id] = `${val || 0}%`;
            setQuesValues(tmpObj);
        }
    }

    const handleSavedChanges = () => {
        // quesValues is the variable to store updated data of the questions loss line
        setIsEditMode(false);
        serviceProvider.post("/businesscaseplantloss?type=saveassumption",
            JSON.stringify({ uid: props.uid, pid: props.pid, sid: sid, ssid: ssid, multi: 0, ques: quesValues, scid: scid })).then((response) => {

            });
        setIsResetDefaultButton(true);
        setIsCenterdPopup(false);
        setIsSaved(prv => 'Changes Saved');
        setTimeout(() => { setIsSaved(prv => false) }, 2000);
    }

    const handleResetDefaultFigure = () => {
        let tmpObj = { ...quesValues };
        questionsReset?.forEach(i => {
            tmpObj[i.id] = `${i.loss[0]}`;
        });
        serviceProvider.post("/businesscaseplantloss?type=saveassumption",
            JSON.stringify({ uid: props.uid, pid: props.pid, sid: sid, ssid: ssid, multi: 0, ques: tmpObj, scid: scid })).then((response) => {
            });
        setQuesValues(tmpObj);
        setIsResetDefaultButton(false);
        setIsCenterdPopup(false);
        setIsSaved(prv => 'Reset Completed');
        setTimeout(() => { setIsSaved(prv => false) }, 2000);
    }

    const handleCenterdPopup = (type, data) => {
        let tmpObj = {}
        switch (type) {
            // save change figure function
            case "saveChanges":
                tmpObj = {
                    heading: 'Update Loss Ideal State',
                    text: `The Loss Ideal State will be changed and this will have an impact on the overall Loss Elimination Set Up  for ${props.plantName}`,
                    isDelete: false,
                    proceedFunction: isSetUpType == 1 ? handleSavedChanges : handleMultiLineSavedChanges,
                    cancelFunction: () => setIsCenterdPopup(false)
                }
                // setIsEditMode(false);
                centeredPopupObj.current = tmpObj;
                break;

            // reset default figure function
            case "resetDefaultFigure":
                tmpObj = {
                    heading: 'Reset Default Figures',
                    text: `This will reset all the changes made under ‘${props.plantName}’ and the figures will roll back to the default numbers.`,
                    isDelete: false,
                    proceedFunction: isSetUpType == 1 ? handleResetDefaultFigure : handleMultiLineResetDefaultFigure,
                    cancelFunction: () => setIsCenterdPopup(false)
                }
                setIsEditMode(false);
                centeredPopupObj.current = tmpObj;
                break;
            // Change Setup function
            case "setupChange":
                tmpObj = {
                    heading: 'Change Setup Type',
                    text: `Are you sure to change the setup type to <b> ‘One figure for all Lines’? </b> All the figures / modifications for all lines will roll back to it’s default value.`,
                    isDelete: false,
                    proceedFunction: () => selectOption(data.option, data.val),
                    cancelFunction: () => setIsCenterdPopup(false)
                }
                setIsEditMode(false);
                centeredPopupObj.current = tmpObj;
                break;
            // save reason changes
            case "saveReasonChanges":
                tmpObj = {
                    heading: 'Update Loss %',
                    text: `The Loss % will be changed and this will have an impact on the overall Loss Elimination Set Up  for ${props.plantName}`,
                    isDelete: false,
                    proceedFunction: () => handleSaveReasonCodeChanges(questions, isReasonCodePopUp),
                    cancelFunction: () => setIsCenterdPopup(false)
                }
                // setIsEditMode(false);
                centeredPopupObj.current = tmpObj;
                break;

            // delete default figure function
            case "deleteReason":
                const { tmpQuestions, item, reasonId, reason_title } = data;
                tmpObj = {
                    heading: 'Delete Loss Elimination Actions',
                    text: `Are you sure to delete <b>‘${reason_title}’</b> Loss Elimination Actions?`,
                    isDelete: true,
                    proceedFunction: () => deleteResonCodeFunction(tmpQuestions, item, reasonId),
                    cancelFunction: () => setIsCenterdPopup(false)
                }
                centeredPopupObj.current = tmpObj;
                break;

            // reset default reasonCode function
            case "resetReasonCode":
                tmpObj = {
                    heading: 'Reset Loss Elimination Actions',
                    text: `This will reset all the ‘Loss Elimination Actions’ and you will loose all the new Loss Elimination Actions added in ‘Loss THEME ${isReasonCodePopUp?.index}’`,
                    isDelete: false,
                    proceedFunction: () => handleResetReasonCodes(questions, isReasonCodePopUp),
                    cancelFunction: () => setIsCenterdPopup(false)
                }
                setIsEditMode(false);
                centeredPopupObj.current = tmpObj;
                break;
            default:
                break;

        }
        setIsCenterdPopup(data || false);

    }

    // function to handle popup layouts
    const handlePopupLayout = (val, tmpSelectedObj) => {
        if (tmpSelectedObj) {
            setSelectedOption(tmpSelectedObj.title);
        }
        setIsSetupType(val);
    }

    // Function to convert in substring
    const convertSubstring = (str, length) => {
        let finalStr = `${str.substring(0, length)}${str.length > length ? '...' : ''}`;
        return finalStr;
    }

    // function to multiline 
    const handleMultiLineOnChangeEditValue = (e, ques_id, multiLineValues, line_index) => {
        if (isEditMode) {
            let tmpObj = { ...quesMultiLineValues };
            let val = e.target.value;
            let tmpArr = [...multiLineValues];
            tmpArr.splice(line_index, 1, val);
            if (val <= 100) {
                tmpObj[ques_id] = [...tmpArr];
            }
            setQuesMultiLineValues(tmpObj);
        }
    }

    const handleMultiLineOnFocusValue = (e, ques_id, multiLineValues, line_index) => {
        if (isEditMode) {
            let tmpObj = { ...quesMultiLineValues };
            let val = removeNonNumericCharacters(e.target.value);
            let tmpArr = [...multiLineValues];
            tmpArr.splice(line_index, 1, val);
            tmpObj[ques_id] = [...tmpArr];
            setQuesMultiLineValues(tmpObj);
        }
    }

    const handleMultiLineOnBlurValue = (e, ques_id, multiLineValues, line_index) => {
        if (isEditMode) {
            let tmpObj = { ...quesMultiLineValues };
            let val = removeNonNumericCharacters(e.target.value);
            let tmpArr = [...multiLineValues];
            tmpArr.splice(line_index, 1, `${val || 0}%`);
            tmpObj[ques_id] = [...tmpArr];
            setQuesMultiLineValues(tmpObj);
        }
    }

    const handleMultiLineSavedChanges = () => {
        // quesValues is the variable to store updated data of the questions loss line
        setIsEditMode(false);
        serviceProvider.post("/businesscaseplantloss?type=saveassumption",
            JSON.stringify({ uid: props.uid, pid: props.pid, sid: sid, ssid: ssid, multi: 1, ques: quesMultiLineValues, scid: scid })).then((response) => {
            });
        setIsResetDefaultButton(true);
        setIsCenterdPopup(false);
        setIsSaved(prv => 'Changes Saved');
        setTimeout(() => { setIsSaved(prv => false) }, 2000);
    }

    const handleMultiLineResetDefaultFigure = () => {
        let tmpObj = {};
        questionsReset?.forEach(i => {
            tmpObj[i.id] = [...i.loss];
        });
        serviceProvider.post("/businesscaseplantloss?type=saveassumption",
            JSON.stringify({ uid: props.uid, pid: props.pid, sid: sid, ssid: ssid, multi: 1, ques: tmpObj, scid: scid })).then((response) => {
            });
        setQuesMultiLineValues(tmpObj);
        setIsResetDefaultButton(false);
        setIsCenterdPopup(false);
        setIsSaved(prv => 'Reset Completed');
        setTimeout(() => { setIsSaved(prv => false) }, 2000);
    }

    // reason popup functions
    const handleOnChangeReasonEditValue = (e, item, ques_id) => {
        if (isReasonEditMode) {
            let tmpObj = { ...reasonValues };
            let val = e.target.value;
            if (val <= 100) {
                tmpObj[`${ques_id}_${item.id}`] = val;
            }
            let tmpTotalPercent = Object.values(tmpObj)?.reduce((item, currentValue) => {
                let tmpVal = removeNonNumericCharacters(currentValue + '%');
                return Number(tmpVal) + item;
            }, 0);

            if (tmpTotalPercent <= 100) {
                if (totalPercent.current) totalPercent.current.value = tmpTotalPercent + '%';
                setReasonValues(tmpObj);
                setFocusInput(prv => false);
            } else {
                setAutoEditValue(true);
                setTimeout(() => setAutoEditValue(prv => false), 8000);
                if (totalPercent.current) totalPercent.current.value = tmpTotalPercent + '%';
                setReasonValues(tmpObj);
                setFocusInput(prv => `${ques_id}_${item.id}`);
            }
        }
    }

    const handleOnFocusReasonValue = (e, item, ques_id) => {
        if (isReasonEditMode) {
            let tmpObj = { ...reasonValues };
            let val = removeNonNumericCharacters(e.target.value);
            if (val <= 100) {
                tmpObj[`${ques_id}_${item.id}`] = val;
            }
            setReasonValues(tmpObj);
        }
    }

    const handleOnBlurReasonValue = (e, item, ques_id) => {
        setAutoFocus(prv => false);
        if (isReasonEditMode) {
            let val = removeNonNumericCharacters(e.target.value);
            let tmpObj = { ...reasonValues };
            tmpObj[`${ques_id}_${item.id}`] = `${val || 0}%`;
            setReasonValues(tmpObj);
        };
    }

    const handleOpenReasonPopup = (data) => {
        let tmpData = { reasonCode: [...reasonCode], ...data }
        let tmpArr = tmpData?.reasonCode?.map(i => i.reason_title.toLowerCase().trim());
        let filteredArr = [];
        existingReasons?.forEach(i => {
            if (!tmpArr?.includes(i.title.toLowerCase())) {
                filteredArr.push(i);
            }
        });

        setExistingCurrentReasons(filteredArr);
        setDropdownReasons(filteredArr);
        setIsReasonCodePopUp({ reasonCode: [...reasonCode], ...data });
        let tmpObj = { ...reasonValues };
        data?.reasonCode?.forEach(i => {
            let key = `${data.id}_${i.id}`;
            tmpObj[key] = i.percent;
        });
        setReasonValues(tmpObj);
        setIsEditMode(false);
    }

    const addNewReasonCodeFunction = async (tmpQuestions, item) => {
        let tmpReasonValues = { ...reasonValues };
        let questionId = item.id;
        let tmpItem = item;
        let tmpArr = tmpQuestions;
        let val = newReasonText;
        let curentIndex = tmpArr?.findIndex(i => i.id === questionId);
        let tmpId = new Date().getTime();
        let tmpPercent = removeNonNumericCharacters(newReasonPercent || '0');
        let node_id = 0
        let tmpReasonCode = {
            id: tmpId,
            reason_title: val,
            percent: tmpPercent + '%',
            comments: 0,
            node_id: node_id,
            description: '',
            is_included: false,
        }
        const response = await serviceProvider.post("/businesscaseplantloss?type=addreason",
            JSON.stringify({ uid: props.uid, pid: props.pid, qid: questionId, ques: [tmpReasonCode], scid: scid, node_id: node_id, description: '' }));
        // .then((response) => {

        //     let newId = response.data[0].insert_id;
        //     tmpReasonCode.id = newId;
        // });
        let newId = response.data[0].insert_id;
        tmpItem.reasonCode.push({ ...tmpReasonCode, id: newId });
        tmpArr[curentIndex].reasonCode = tmpItem.reasonCode;
        tmpItem.reason_edited = 1;
        tmpArr[curentIndex].reason_edited = 1;
        tmpReasonValues[`${questionId}_${tmpId}`] = `0%`;
        setIsReasonCodePopUp({ ...tmpItem });
        setReasonValues({ ...tmpReasonValues });
        setQuestions([...tmpArr]);
        setIsAddreasonInput(false);
        setNewReasonText('')
        setNewReasonPercent('0%')
        setAutoFocus(prv => tmpItem?.reasonCode?.length - 1);
        setTimeout(() => setAutoFocus(prv => false), 8000);
    }

    const saveReasonCodeChangesFunction = async (tmpQuestions, item, title, node_id, description) => {
        let tmpReasonValues = { ...reasonValues };
        let questionId = item.id;
        let tmpItem = item;
        let tmpArr = tmpQuestions;
        let val = title;
        let curentIndex = tmpArr?.findIndex(i => i.id === questionId);
        let tmpId = new Date().getTime();
        let tmpPercent = removeNonNumericCharacters(newReasonPercent || '0');
        let tmpReasonCode = {
            id: tmpId,
            reason_title: val,
            percent: tmpPercent + '%',
            comments: 0,
            node_id: node_id,
            description: description
        }
        const response = await serviceProvider.post("/businesscaseplantloss?type=addreason",
            JSON.stringify({ uid: props.uid, pid: props.pid, qid: questionId, ques: [tmpReasonCode], scid: scid, node_id: node_id, description: description }))
        // .then((response) => {

        //     let newId = response.data[0].insert_id;
        //     tmpReasonCode.id = newId;
        // });
        let newId = response.data[0].insert_id;
        tmpItem.reasonCode.push({ ...tmpReasonCode, id: newId });
        tmpArr[curentIndex].reasonCode = tmpItem.reasonCode;
        tmpItem.reason_edited = 1;
        tmpArr[curentIndex].reason_edited = 1;
        tmpReasonValues[`${questionId}_${tmpId}`] = `0%`;
        setIsReasonCodePopUp({ ...tmpItem });
        setReasonValues(tmpReasonValues);
        setQuestions([...tmpArr]);
        setIsAddreasonInput(false);
        setNewReasonText('')
        setNewReasonPercent('0%');
        setAutoFocus(prv => tmpItem?.reasonCode?.length - 1);
        setTimeout(() => setAutoFocus(prv => false), 8000);
    }

    const deleteResonCodeFunction = (tmpQuestions, item, reasonId) => {
        let questionId = item.id;
        let tmpItem = item;
        let tmpArr = tmpQuestions;
        let filterArr = tmpItem?.reasonCode?.filter((i) => i.id != reasonId);
        let curentIndex = tmpArr?.findIndex(i => i.id === questionId);
        tmpItem.reasonCode = filterArr;
        tmpArr[curentIndex].reasonCode = filterArr;
        setIsReasonCodePopUp(prv => { return { ...tmpItem } });
        setQuestions(prv => [...tmpArr]);
        setIsCenterdPopup(prv => false);
        serviceProvider.post("/businesscaseplantloss?type=deletereason",
            JSON.stringify({ reasonId: reasonId })).then((response) => {
            });
    }
    const handleSearchList = (val) => {
        setNewReasonText(val);
        if (!val) {
            setDropdownReasons(existingCurrentReasons || []);
        } else if (val) {
            let tmpVal = val.toLowerCase();
            let tmpFilterList = existingCurrentReasons.filter(i => {
                let tmpTitle = i.title.toLowerCase();
                let condition = tmpTitle.startsWith(tmpVal);
                return condition;
            })
            setDropdownReasons(tmpFilterList || []);
        }
    }

    const backToMainPopup = (val) => {
        setIsEditMode(false);
        setAutoFocus(prv => false);
        setIsReasonCodePopUp(false);
        setIsAddreasonInput(false);
        setIsReasonEditMode(false);
        setReasonValues({});
        setIsLossThemeDescriptionPopOpen(false);
        localStorage.removeItem('isReasonCodePopUp');
        if (val === `close`) {
            setIsSetupType(false);
        }
        if (val === `close1`) {
            setLossAssumptionPopupShow(false);
            setIslossCatPopup(true);
            setIsUpdateScenarioPopup(false);
        }
    }

    const handleSaveReasonCodeChanges = (tmpQuestions, item) => {
        let questionId = item.id;
        let tmpItem = item;
        let tmpArr = tmpQuestions;
        let curentIndex = tmpArr?.findIndex(i => i.id === questionId);
        let tmpReasonCode = item.reasonCode.map(i => {
            return {
                ...i,
                percent: reasonValues[`${questionId}_${i.id}`]
            }
        });
        tmpItem.reasonCode = [...tmpReasonCode];
        tmpArr[curentIndex].reasonCode = tmpItem.reasonCode;
        tmpItem.reason_edited = 1;
        tmpArr[curentIndex].reason_edited = 1;
        setIsReasonCodePopUp({ ...tmpItem });
        setQuestions([...tmpArr]);
        setIsCenterdPopup(false);
        setIsCenterdPopup(false);
        setIsReasonEditMode(false);
        serviceProvider.post("/businesscaseplantloss?type=savereason",
            JSON.stringify({ uid: props.uid, pid: props.pid, qid: questionId, ques: tmpItem.reasonCode, scid: scid })).then((response) => {
                setIsSaved(`Changes Saved`);
                setTimeout(() => {
                    setIsSaved(prv => false);
                }, 2000)
            });
    }

    const handleResetReasonCodes = (tmpQuestions, item) => {
        let questionId = item.id;
        let tmpItem = item;
        let tmpArr = tmpQuestions;
        let curentIndex = tmpArr?.findIndex(i => i.id === questionId);
        let defaultReasonCode = [...questionsReset[curentIndex]?.reasonCode];
        tmpItem.reasonCode = [...defaultReasonCode];
        tmpArr[curentIndex].reasonCode = defaultReasonCode;
        tmpItem.reason_edited = 0;
        tmpArr[curentIndex].reason_edited = 0;
        let tmpReasonCodeValue = {};
        defaultReasonCode?.forEach(i => {
            tmpReasonCodeValue[`${questionId}_${i.id}`] = i.percent;
        });
        setIsReasonCodePopUp({ ...tmpItem });
        setQuestions([...tmpArr]);
        setIsCenterdPopup(false);;
        setReasonValues({ ...tmpReasonCodeValue });

        serviceProvider.post("/businesscaseplantloss?type=resetreason",
            JSON.stringify({ uid: props.uid, pid: props.pid, qid: questionId, ques: defaultReasonCode, scid: scid })).then((response) => {
            });
    }

    const totalReasonPercent = useMemo(() => {
        let tmpVal = 0;
        if (isReasonCodePopUp) {
            tmpVal = isReasonCodePopUp?.reasonCode?.reduce((item, currentValue) => {
                let tmpVal = removeNonNumericCharacters(currentValue.percent);
                return Number(tmpVal) + item;
            }, 0);
            let tmpReasonCode = {}
            isReasonCodePopUp?.reasonCode?.forEach(i => {
                const questionId = isReasonCodePopUp.id;
                tmpReasonCode[`${questionId}_${i.id}`] = i.percent;
            });
            setReasonValues(prv => {
                let tmpObj = {
                    ...tmpReasonCode,
                    ...prv,
                }
                tmpVal = Object.values(tmpObj)?.reduce((item, currentValue) => {
                    let tmpVal = removeNonNumericCharacters(currentValue + '%');
                    return Number(tmpVal) + item;
                }, 0);
                return tmpObj;
            });
            console.log(tmpVal);
            setTimeout(() => {
                if (totalPercent.current) totalPercent.current.value = (tmpVal || 0) + '%';
            }, 100);
        }
        return (tmpVal || 0) + '%';
    }, [isReasonCodePopUp]);

    const handleUpdateLossPopup = () => {
        const tmpObj = {
            text: `The Loss % will be changed and this will have an impact on the overall Loss Elimination Set Up  for ${props.plantName}`,
            proceedFunction: () => {
                handleSaveReasonCodeChanges(questions, isReasonCodePopUp)
            },
        }
        setIsUpdateScenarioPopup(tmpObj);
        setIsAddScenarioPopup(false);
        setIsSelectScenarioPopup(false);
        setIslossCatPopup(false);
        localStorage.setItem('isReasonCodePopUp', JSON.stringify(isReasonCodePopUp));
        setIsReasonCodePopUp(false);
        setLossAssumptionPopupShow(false);
        setIsReasonEditMode(false);
    }

    // Function to handle technology Popup
    const handleTechnologyPop = (val) => {
        if (val === isTechnologyDescriptionPopOpen) {
            setIsTechnologyDescriptionPopOpen(false);
        }
        else {
            setIsTechnologyDescriptionPopOpen(val);
        }
    }

    // Function to handle Sub Loss Description Popup
    const handleSubLossDescriptionPopup = (val) => {
        if (val.id === isLossThemeDescriptionPopOpen) {
            setIsLossThemeDescriptionPopOpen(false);
        }
        else {
            setIsLossThemeDescriptionPopOpen(val);
        }
    }

    // Function to handle Got it Button
    const handleGotBtn = () => {
        setIsLossGotBtn(false);
        serviceProvider.post("/businesscaseplantloss?type=updatemessage",
            JSON.stringify({ scid: scid })).then((response) => {
            });
    }

    // Function to handle solution function
    const handleIncluedSolutionFunction = (key, val) => {
        setIncludesCheckObj(prv => {
            return {
                ...prv,
                [key]: val
            }
        });

        serviceProvider.post("/businesscaseplantloss?type=modifyreason",
            JSON.stringify({ reasonId: key, val: val })).then((response) => {
            });
    }

    useEffect(() => {
        setIsReasonCodePopUp(props.resonCodePopupData || false);
    }, [props.resonCodePopupData])

    useEffect(() => {
        if (isSetUpType == 1) {
            // this for singlelines 
            let tmpObj = { ...quesValues };
            questions?.forEach(i => {
                tmpObj[i.id] = `${i.loss[0]}`;
            });
            setQuesValues(tmpObj);
        } else if (isSetUpType == 2) {
            // this for multiplelines 
            let tmpObj = { ...quesValues };
            questions?.forEach(i => {
                let val = i.loss || [];
                tmpObj[i.id] = [...val];
            });
            setQuesMultiLineValues(tmpObj);

        }
    }, [isSetUpType]);

    useEffect(() => {
        setIsReasonEditMode(false);
        if (props.isSaveReasonEditMode === `saved`) {
            handleSavedChanges();
        }
    }, [props.isSaveReasonEditMode]);

    useEffect(() => {
        document.addEventListener('click', function (e) {
            if (!e.target.closest('[data-component="DropdownBox"]') && !e.target.closest('.add-reason-input-label') && !e.target.closest('.add-reason-btn')) {
                setIsAddreasonInput(false);
            }
        })
    }, [])
    // 08-01-2024
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <PopupContainer data-component="PopupContainer">
                <PopupCard data-component="PopupCard">
                    <PopupCardHeader data-component="PopupCardHeader">
                        <HeaderH1 data-component="HeaderH1">
                            {isReasonCodePopUp ?
                                <BackBtnWrap data-component="BackBtnWrap">
                                    <button className="back-btn" onClick={() => backToMainPopup()}>
                                        <i dangerouslySetInnerHTML={{ __html: backBtnSvg }}></i>
                                        <span>Back</span>
                                    </button>
                                    <p>{props.plantName}: Loss Elimination Actions</p>
                                </BackBtnWrap>
                                :
                                isSetUpType ?
                                    <BackBtnWrap data-component="BackBtnWrap">
                                        <button className="back-btn" onClick={() => backToMainPopup(`close`)}>
                                            <i dangerouslySetInnerHTML={{ __html: backBtnSvg }}></i>
                                            <span>Back</span>
                                        </button>
                                        <p>{props.plantName}: {lossAssumptionPopupData.title}</p>
                                    </BackBtnWrap>
                                    :
                                    <BackBtnWrap data-component="BackBtnWrap">
                                        <button className="back-btn" onClick={() => backToMainPopup(`close1`)}>
                                            <i dangerouslySetInnerHTML={{ __html: backBtnSvg }}></i>
                                            <span>Back</span>
                                        </button>
                                        <p>{props.plantName}: {lossAssumptionPopupData.title}</p>
                                    </BackBtnWrap>
                            }
                        </HeaderH1>
                        <HeaderButtons data-component="HeaderButtons">
                            {isSetUpType ?
                                <>
                                    {!isReasonCodePopUp ?
                                        <>
                                            {(props.sid == 101 || props.sid == 103) &&
                                                <SetupDropDown data-component="SetupDropDown">
                                                    <div className="setup-wrap">
                                                        <span className="text">Setup Type:</span>
                                                        <button onClick={toggleDropdown}>
                                                            {selectedOption || 'Select an option'}
                                                            <i dangerouslySetInnerHTML={{ __html: dropdownIcon }}></i>
                                                            {isOpen && (
                                                                <ul>
                                                                    {options.map((option, index) => (
                                                                        <li key={index} onClick={() => { handleCenterdPopup('setupChange', { option: option.title, val: option.value }); }} className={`drop-option ${isSetUpType === option.value ? 'active' : ''}`}>
                                                                            <span>{option.title}</span>
                                                                            <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </button>
                                                    </div>
                                                </SetupDropDown>
                                            }
                                            {isResetDefaultButton ?
                                                <ResetDefaultButton data-component="ResetDefaultButton" onClick={() => handleCenterdPopup('resetDefaultFigure', {})}>
                                                    Reset Default Figures
                                                </ResetDefaultButton>
                                                : ''
                                            }
                                            {!isEditMode ?
                                                <EditFiguresButton data-component="EditFiguresButton" onClick={() => setIsEditMode(true)}>
                                                    Edit Figures
                                                </EditFiguresButton>
                                                : ''
                                            }
                                        </>
                                        :
                                        <>

                                            {isReasonCodePopUp.reason_edited || walkthroughReasonButtonShow ?
                                                <ResetDefaultButton data-component="ResetDefaultButton" onClick={() => handleCenterdPopup('resetReasonCode', {})}>
                                                    Reset Loss Elimination Actions
                                                </ResetDefaultButton>
                                                : ''
                                            }

                                            {!isReasonEditMode ?
                                                <EditFiguresButton data-component="EditFiguresButton" onClick={() => setIsReasonEditMode(true)}>
                                                    Edit Loss Elimination Actions
                                                </EditFiguresButton>
                                                :
                                                ''
                                            }
                                        </>

                                    }
                                </>
                                : ''}
                            <PopupCloseButton data-component="PopupCloseButton" onClick={closeMainPopup}>
                                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                            </PopupCloseButton>
                        </HeaderButtons>
                    </PopupCardHeader>
                    {autoFocus ?
                        <InfoMsg data-component="InfoMsg">
                            The operation to integrate the Reason code with a default value of 0 has been gracefully completed. To modify this initial setting, simply click on the 'Edit Loss Elimination Actions' button, which will allow you to tailor the value to your specific requirements.</InfoMsg>
                        :
                        ''
                    }
                    {autoEditValue ?
                        <InfoMsg data-component="InfoMsg">
                            The cumulative total of Loss Eliminations must gracefully adhere to the upper limit of 100%, ensuring harmony and balance within the system..</InfoMsg>
                        :
                        ''
                    }
                    {/* First Setup Selection View */}
                    {!isSetUpType ?
                        <PopupCardBody data-component="PopupCardBody" className="loss-notification-popup">
                            <ScenarioWrapper data-component="ScenarioWrapper">
                                <span className="label">Current Scenario</span>
                                <ScenarioHead data-component="ScenarioHead">
                                    <span className="scenario-name">{tmpScenario.name || `Loss Analysis (Default)`}</span>
                                </ScenarioHead>
                            </ScenarioWrapper>
                            <SetUpCard data-component="SetUpCard">
                                <h2>Setup Type:</h2>
                                <p>How would you like to input your Financial  Loss Elimination Set Up ??</p>
                                <SetUpCardRow data-component="SetUpCardRow">
                                    <SetUpCardCol data-component="SetUpCardCol">
                                        <SetUpCardWrap data-component="SetUpCardWrapOne" onClick={() => handlePopupLayout(1, options[0])}>
                                            <SetUpImageWrap data-component="SetUpImageWrap">
                                                <i dangerouslySetInnerHTML={{ __html: oneFigureSvg }}></i>
                                            </SetUpImageWrap>
                                            <p className="text">One Financial Loss Elimination number for all the production lines</p>
                                        </SetUpCardWrap>
                                    </SetUpCardCol>
                                    <SetUpCardCol data-component="SetUpCardCol">
                                        <SetUpCardWrap data-component="SetUpCardWrapMulti" onClick={() => handlePopupLayout(2, options[1])}>
                                            <SetUpImageWrap data-component="SetUpImageWrap">
                                                <i dangerouslySetInnerHTML={{ __html: multipleLineSvg }}></i>
                                            </SetUpImageWrap>
                                            <p className="text">Unique Financial Loss Elimination number per production line</p>
                                        </SetUpCardWrap>
                                    </SetUpCardCol>
                                </SetUpCardRow>
                            </SetUpCard>
                            <LossNotificationPopup data-component="LossNotificationPopup" className={`${isLossGotBtn ? `active` : ``}`}>
                                <LossNotificationPopupCard data-component="LossNotificationPopupCard" className={`${isLossGotBtn ? `active` : ``}`}>
                                    <LossNotificationPopupHead data-component="LossNotificationPopupHead">
                                        <i dangerouslySetInnerHTML={{ __html: bulbSvg }}></i>
                                        <span>The Tool gives you as user the freedom to change those
                                            recoverable Loss Elimination Set Up as you see fit following
                                            your internal company manufacturing strategy. </span>
                                    </LossNotificationPopupHead>
                                    <p class="loss-text">From our experience the best ways to
                                        benchmark how much of the Plant Activity might
                                        be considered a Loss can be through:</p>
                                    <LossNotificationPopupList data-component="LossNotificationPopupList">
                                        <div class="list-item"> 1. Consultation with your internal experts </div>
                                        <div class="list-item"> 2. Discussions with your OEM's </div>
                                        <div class="list-item"> 3. Insights from your consulting partners </div>
                                        <div class="list-item"> 4. Insights from your technology vendors </div>
                                        <div class="list-item"> 5. Institutional knowledge & experience </div>
                                    </LossNotificationPopupList>
                                    <LossNotificationPopupFooter data-component="LossNotificationPopupFooter">
                                        {isLossGotBtn && <button class="got-btn" onClick={() => handleGotBtn()}>OK. Got it.</button>}
                                    </LossNotificationPopupFooter>
                                </LossNotificationPopupCard>
                            </LossNotificationPopup>
                        </PopupCardBody>
                        :
                        isSetUpType === 1 ?
                            <PopupCardBody data-component="PopupCardBody">
                                <ScenarioWrapper data-component="ScenarioWrapper">
                                    <span className="label">Current Scenario</span>
                                    <ScenarioHead data-component="ScenarioHead">
                                        <span className="scenario-name">{tmpScenario.name || `Loss Analysis (Default)`}</span>
                                    </ScenarioHead>
                                </ScenarioWrapper>
                                {isReasonCodePopUp ?
                                    <ReasonCodePopup data-component="ReasonCodePopup">
                                        <ReasonCodePopHead data-component="ReasonCodePopHead">
                                            <span>Loss THEME</span>
                                            <p>{isReasonCodePopUp?.index}. {isReasonCodePopUp?.question}</p>
                                        </ReasonCodePopHead>
                                        <ReasonCodePopBody data-component="ReasonCodePopBody">
                                            <ReasonCodeTableHead data-component="ReasonCodeTableHead">
                                                <ReasonCodeTableRow data-component="ReasonCodeTableRow">
                                                    <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol">
                                                        <span className="reason-head">Loss Elimination Actions</span>
                                                    </ReasonCodeTableLeftCol>
                                                    <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol"></ReasonCodeTableMidCol>
                                                    <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                        <span className="reason-head">Current Recoverable Loss</span>
                                                    </ReasonCodeTableMidCol>
                                                    <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol">
                                                        <span className="reason-head">Comments</span>
                                                    </ReasonCodeTableRightCol>
                                                    <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol"></ReasonCodeTableRightDelCol>
                                                </ReasonCodeTableRow>
                                            </ReasonCodeTableHead>
                                            <ReasonCodeTableBody data-component="ReasonCodeTableBody">
                                                {isReasonCodePopUp?.reasonCode?.map((item, index) => (
                                                    <ReasonCodeTableRow data-component="ReasonCodeTableRow" key={index} data-id={item.id}>
                                                        <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol">
                                                            <span className="reason-text">{index + 1}. {item.reason_title}</span>

                                                            {item.node_id == 0 &&
                                                                <label className="include-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            includesCheckObj[item.id] != undefined ?
                                                                                includesCheckObj[item.id]
                                                                                : item.is_add_solution == 1
                                                                        }
                                                                        onChange={(e) => handleIncluedSolutionFunction(item.id, e.target.checked)}
                                                                        key={`s-reason-check-${item.id}`}
                                                                    />
                                                                    <span>Include as solution in roadmap</span>
                                                                </label>
                                                            }
                                                        </ReasonCodeTableLeftCol>
                                                        <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                            {item.node_id != 0 &&
                                                                <button className={`tech-desc-btn ${isTechnologyDescriptionPopOpen.id === item.id ? `active` : ``}`} onClick={() => handleTechnologyPop(item)}>Tech Description</button>
                                                            }
                                                        </ReasonCodeTableMidCol>
                                                        <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                            <input className={`reason-input ${(focusInput == `${isReasonCodePopUp.id}_${item.id}` && totalPercent.current?.value?.replace('%', '') > 100) ? 'error' : ''}`}
                                                                value={reasonValues[`${isReasonCodePopUp?.id}_${item.id}`]}
                                                                onChange={(e) => handleOnChangeReasonEditValue(e, item, isReasonCodePopUp?.id)}
                                                                onFocus={(e) => handleOnFocusReasonValue(e, item, isReasonCodePopUp?.id)}
                                                                onBlur={(e) => handleOnBlurReasonValue(e, item, isReasonCodePopUp?.id)}
                                                                disabled={!isReasonEditMode}
                                                            />
                                                        </ReasonCodeTableMidCol>
                                                        <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol">
                                                            <span className="reason-text">{commnetsCount[`${isReasonCodePopUp.id}_${item.id}`] || item.comments} comments</span>
                                                            <button className="view-btn" onClick={() => openCommentPopup(item, index + 1)}>View</button>
                                                        </ReasonCodeTableRightCol>
                                                        <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol">
                                                            {item.node_id == 0 ?
                                                                <button className="del-btn" onClick={() => handleCenterdPopup('deleteReason', { tmpQuestions: questions, item: isReasonCodePopUp, reasonId: item.id, reason_title: item.reason_title })}>Delete</button>
                                                                : ''
                                                            }
                                                        </ReasonCodeTableRightDelCol>
                                                    </ReasonCodeTableRow>
                                                ))}

                                                {!isAddreasonInput ?
                                                    <button className="add-reason-btn" onClick={() => setIsAddreasonInput(true)}>+ Add Loss Elimination Action</button>
                                                    :
                                                    <AddReasonBox data-component="AddReasonBox">
                                                        <ReasonCodeTableRow data-component="ReasonCodeTableRow">
                                                            <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol" style={{ position: "relative" }}>
                                                                <label className="add-reason-input-label">
                                                                    <span className="add-reason-count-text">{isReasonCodePopUp?.reasonCode?.length + 1}.</span>
                                                                    <input
                                                                        value={newReasonText}
                                                                        onChange={(e) => handleSearchList(e.target.value)}
                                                                        onFocus={(e) => setDropdownActive(true)}
                                                                        className={`add-reason-text-input ${dropdownActive ? 'active' : ''}`}
                                                                        autoFocus={true}
                                                                        placeholder="Enter Loss Elimination Actions Name" />
                                                                </label>
                                                                <button className="save-reason-button"
                                                                    disabled={(newReasonText && existingReasons?.find(i => i.title?.toLocaleLowerCase() == newReasonText?.toLocaleLowerCase())) || !newReasonText}
                                                                    onClick={() => addNewReasonCodeFunction(questions, isReasonCodePopUp)}>
                                                                    Add
                                                                </button>
                                                                <DropdownBox
                                                                    data-component="DropdownBox"
                                                                    active={dropdownActive ? true : false}
                                                                >
                                                                    {dropdownReasons?.map((li, li_ind) => (
                                                                        <DropdownOption key={li_ind} data-component="DropdownOption"
                                                                            onClick={() => saveReasonCodeChangesFunction(questions, isReasonCodePopUp, li.title, li.node_id, li.description)}>
                                                                            {li.title}
                                                                        </DropdownOption>
                                                                    ))
                                                                    }
                                                                </DropdownBox>
                                                            </ReasonCodeTableLeftCol>
                                                            <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                                <input className="reason-input" placeholder='0%'
                                                                    value={newReasonPercent}
                                                                    onFocus={(e) => setNewReasonPercent(removeNonNumericCharacters(e.target.value || 0))}
                                                                    onChange={(e) => setNewReasonPercent(prv => removeNonNumericCharacters(e.target.value <= 100 ? e.target.value : prv))}
                                                                    onBlur={(e) => setNewReasonPercent(`${e.target.value || 0}%`)}
                                                                />
                                                            </ReasonCodeTableMidCol>
                                                            <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol" />
                                                            <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol" />
                                                        </ReasonCodeTableRow>
                                                    </AddReasonBox>
                                                }
                                            </ReasonCodeTableBody>
                                            <ReasonCodeTableFooter data-component="ReasonCodeTableFooter">
                                                <ReasonCodeTableRow data-component="ReasonCodeTableRow">
                                                    <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol">
                                                        <span className="reason-total">TOTAL</span>
                                                    </ReasonCodeTableLeftCol>
                                                    <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                        <input className={`reason-input ${totalPercent.current?.value?.replace('%', '') > 100 ? 'error' : ''}`} ref={totalPercent} disabled />
                                                    </ReasonCodeTableMidCol>
                                                    <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol">
                                                        {/* <span className="reason-text">{isReasonCodePopUp?.reasonCode?.map((cmnt, ind) => ind + 1).reduce((a, c) => a + c, 0)} comments</span>
                                                        <button className="view-btn">View</button> */}
                                                    </ReasonCodeTableRightCol>
                                                    <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol"></ReasonCodeTableRightDelCol>
                                                </ReasonCodeTableRow>
                                            </ReasonCodeTableFooter>
                                        </ReasonCodePopBody>
                                    </ReasonCodePopup>
                                    :
                                    <QuestionsCard data-component="QuestionsCard">
                                        <QuestionsCardHeader data-component="QuestionsCardHeader">
                                            <QuestionsCardRow data-component="QuestionsCardRow">
                                                <QuestionsCardCol1 data-component="QuestionsCardCol1">
                                                    <span>Loss Themes</span>
                                                    <button className="side-pop-btn" onClick={() => setIsThingToKeepPopupOpen(true)}>
                                                        <i dangerouslySetInnerHTML={{ __html: notificationBulbSvg }}></i>
                                                        <span>Thing to keep in mind!</span>
                                                    </button>
                                                </QuestionsCardCol1>
                                                <QuestionsCardCol2 data-component="QuestionsCardCol2">
                                                    Loss Ideal State
                                                </QuestionsCardCol2>
                                                <QuestionsCardCol2 data-component="QuestionsCardCol2">
                                                    Loss Elimination Actions
                                                </QuestionsCardCol2>
                                            </QuestionsCardRow>
                                        </QuestionsCardHeader>
                                        {/*Questions Header end */}
                                        <QuestionsCardBody data-component="QuestionsCardBody">
                                            {questions?.map((ques, ques_index) => (
                                                <QuestionsCardRow data-component="QuestionsCardRow" key={ques_index}>
                                                    <QuestionsCardCol1 data-component="QuestionsCardCol1">
                                                        <div className="ques-info-col">
                                                            <p>{ques_index + 1}. {ques.question}</p>
                                                            <button className={`info-btn ${isLossThemeDescriptionPopOpen.id === ques.id ? `active` : ``}`} onClick={() => handleSubLossDescriptionPopup(ques)}>
                                                                <i dangerouslySetInnerHTML={{ __html: infoIconSvg }}></i>
                                                            </button>
                                                        </div>
                                                    </QuestionsCardCol1>
                                                    <QuestionsCardCol2 data-component="QuestionsCardCol2">
                                                        <LossInput type='text' value={`${quesValues[ques.id] !== undefined ? quesValues[ques.id] : ques.loss}`}
                                                            data-component="LossInput" disabled={!isEditMode}
                                                            onChange={(e) => handleOnChangeEditValue(e, ques)}
                                                            onFocus={(e) => handleOnFocusValue(e, ques)}
                                                            onBlur={(e) => handleOnBlurValue(e, ques)}
                                                        />
                                                    </QuestionsCardCol2>
                                                    <QuestionsCardCol2 data-component="QuestionsCardCol2">
                                                        <ViewButton
                                                            data-component="ViewButton"
                                                            onClick={() => { handleOpenReasonPopup({ ...ques, index: ques_index + 1 }) }}
                                                        >
                                                            <span>View</span>
                                                            <i dangerouslySetInnerHTML={{ __html: viewButtonSvg }} />
                                                        </ViewButton>
                                                    </QuestionsCardCol2>
                                                </QuestionsCardRow>))
                                            }
                                        </QuestionsCardBody>
                                        {/*Questions Body end */}
                                    </QuestionsCard>
                                }
                            </PopupCardBody>
                            :
                            isSetUpType === 2 ?
                                <IndividualPopupCardBody data-component="IndividualPopupCardBody">
                                    <ScenarioWrapper data-component="ScenarioWrapper">
                                        <span className="label">Current Scenario</span>
                                        <ScenarioHead data-component="ScenarioHead">
                                            <span className="scenario-name">{tmpScenario.name || `Loss Analysis (Default)`}</span>
                                        </ScenarioHead>
                                    </ScenarioWrapper>
                                    {isReasonCodePopUp ?
                                        <ReasonCodePopup data-component="ReasonCodePopup">
                                            <ReasonCodePopHead data-component="ReasonCodePopHead">
                                                <span>LOSS THEMES</span>
                                                <p>{isReasonCodePopUp?.index}. {isReasonCodePopUp?.question}</p>
                                            </ReasonCodePopHead>
                                            <ReasonCodePopBody data-component="ReasonCodePopBody">
                                                <ReasonCodeTableHead data-component="ReasonCodeTableHead">
                                                    <ReasonCodeTableRow data-component="ReasonCodeTableRow">
                                                        <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol">
                                                            <span className="reason-head">Loss Elimination Actions</span>
                                                        </ReasonCodeTableLeftCol>
                                                        <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol"></ReasonCodeTableMidCol>
                                                        <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                            <span className="reason-head">Current Recoverable Loss</span>
                                                        </ReasonCodeTableMidCol>
                                                        <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol">
                                                            <span className="reason-head">Comments</span>
                                                        </ReasonCodeTableRightCol>
                                                        <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol"></ReasonCodeTableRightDelCol>
                                                    </ReasonCodeTableRow>
                                                </ReasonCodeTableHead>
                                                <ReasonCodeTableBody data-component="ReasonCodeTableBody">
                                                    {isReasonCodePopUp?.reasonCode?.map((item, index) => (
                                                        <ReasonCodeTableRow data-component="ReasonCodeTableRow" key={index} data-id={item.id}>
                                                            <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol">
                                                                <span className="reason-text">{index + 1}. {item.reason_title}</span>
                                                                {item.node_id == 0 &&
                                                                    <label className="include-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                includesCheckObj[item.id] != undefined ?
                                                                                    includesCheckObj[item.id]
                                                                                    : item.is_add_solution == 1
                                                                            }
                                                                            onChange={(e) => handleIncluedSolutionFunction(item.id, e.target.checked)}
                                                                            key={`s-reason-check-${item.id}`}
                                                                        />
                                                                        <span>Include as solution in roadmap</span>
                                                                    </label>
                                                                }
                                                            </ReasonCodeTableLeftCol>
                                                            <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                                {item.node_id != 0 &&
                                                                    <button className={`tech-desc-btn ${isTechnologyDescriptionPopOpen.id === item.id ? `active` : ``}`} onClick={() => handleTechnologyPop(item)}>Tech Description</button>
                                                                }
                                                            </ReasonCodeTableMidCol>
                                                            <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                                <input className={`reason-input ${(focusInput == `${isReasonCodePopUp.id}_${item.id}` && totalPercent.current?.value?.replace('%', '') > 100) ? 'error' : ''}`}
                                                                    value={reasonValues[`${isReasonCodePopUp?.id}_${item.id}`]}
                                                                    onChange={(e) => handleOnChangeReasonEditValue(e, item, isReasonCodePopUp?.id)}
                                                                    onFocus={(e) => handleOnFocusReasonValue(e, item, isReasonCodePopUp?.id)}
                                                                    onBlur={(e) => handleOnBlurReasonValue(e, item, isReasonCodePopUp?.id)}
                                                                    disabled={!isReasonEditMode}
                                                                />
                                                            </ReasonCodeTableMidCol>
                                                            <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol">
                                                                <span className="reason-text">{commnetsCount[`${isReasonCodePopUp.id}_${item.id}`] || item.comments} comments</span>
                                                                <button className="view-btn" onClick={() => openCommentPopup(item, index + 1)}>View</button>
                                                            </ReasonCodeTableRightCol>
                                                            <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol">
                                                                {item.node_id == 0 ?
                                                                    <button className="del-btn" onClick={() => handleCenterdPopup('deleteReason', { tmpQuestions: questions, item: isReasonCodePopUp, reasonId: item.id, reason_title: item.reason_title })}>Delete</button>
                                                                    : ''
                                                                }
                                                            </ReasonCodeTableRightDelCol>
                                                        </ReasonCodeTableRow>
                                                    ))}

                                                    {!isAddreasonInput ?
                                                        <button className="add-reason-btn" onClick={() => setIsAddreasonInput(true)}>+ Add Loss Elimination Actions</button>
                                                        :
                                                        <AddReasonBox data-component="AddReasonBox">
                                                            <ReasonCodeTableRow data-component="ReasonCodeTableRow">
                                                                <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol" style={{ position: 'relative' }}>
                                                                    <label className="add-reason-input-label">
                                                                        <span className="add-reason-count-text">{isReasonCodePopUp?.reasonCode?.length + 1}.</span>
                                                                        <input
                                                                            value={newReasonText}
                                                                            onChange={(e) => handleSearchList(e.target.value)}
                                                                            onFocus={(e) => setDropdownActive(true)}
                                                                            className={`add-reason-text-input ${dropdownActive ? 'active' : ''}`}
                                                                            autoFocus={true}
                                                                            placeholder="Enter Loss Elimination Actions Name" />
                                                                    </label>
                                                                    <button className="save-reason-button"
                                                                        disabled={(newReasonText && existingReasons?.find(i => i.title?.toLocaleLowerCase() == newReasonText?.toLocaleLowerCase())) || !newReasonText}
                                                                        onClick={() => addNewReasonCodeFunction(questions, isReasonCodePopUp)}>
                                                                        Add
                                                                    </button>
                                                                    <DropdownBox
                                                                        data-component="DropdownBox"
                                                                        active={dropdownActive ? true : false}
                                                                    >
                                                                        {dropdownReasons?.map((li, li_ind) => (
                                                                            <DropdownOption key={li_ind} data-component="DropdownOption"
                                                                                onClick={() => saveReasonCodeChangesFunction(questions, isReasonCodePopUp, li.title, li.node_id, li.description)}>
                                                                                {li.title}
                                                                            </DropdownOption>
                                                                        ))
                                                                        }
                                                                    </DropdownBox>
                                                                </ReasonCodeTableLeftCol>
                                                                <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                                    <input className="reason-input" placeholder='0%'
                                                                        value={newReasonPercent}
                                                                        onFocus={(e) => setNewReasonPercent(removeNonNumericCharacters(e.target.value))}
                                                                        onChange={(e) => setNewReasonPercent(removeNonNumericCharacters(e.target.value))}
                                                                        onBlur={(e) => setNewReasonPercent(`${e.target.value || 0}%`)}
                                                                    />
                                                                </ReasonCodeTableMidCol>
                                                                <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol" />
                                                                <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol" />
                                                            </ReasonCodeTableRow>
                                                        </AddReasonBox>
                                                    }
                                                </ReasonCodeTableBody>
                                                <ReasonCodeTableFooter data-component="ReasonCodeTableFooter">
                                                    <ReasonCodeTableRow data-component="ReasonCodeTableRow">
                                                        <ReasonCodeTableLeftCol data-component="ReasonCodeTableLeftCol">
                                                            <span className="reason-total">TOTAL</span>
                                                        </ReasonCodeTableLeftCol>
                                                        <ReasonCodeTableMidCol data-component="ReasonCodeTableMidCol">
                                                        <input className={`reason-input ${totalPercent.current?.value?.replace('%', '') > 100 ? 'error' : ''}`} ref={totalPercent} disabled />
                                                        </ReasonCodeTableMidCol>
                                                        <ReasonCodeTableRightCol data-component="ReasonCodeTableRightCol">
                                                            {/* <span className="reason-text">{isReasonCodePopUp?.reasonCode?.map((cmnt, ind) => ind + 1).reduce((a, c) => a + c, 0)} comments</span>
                                                            <button className="view-btn">View</button> */}
                                                        </ReasonCodeTableRightCol>
                                                        <ReasonCodeTableRightDelCol data-component="ReasonCodeTableRightDelCol"></ReasonCodeTableRightDelCol>
                                                    </ReasonCodeTableRow>
                                                </ReasonCodeTableFooter>
                                            </ReasonCodePopBody>
                                        </ReasonCodePopup>
                                        :
                                        <IndividualQuestionsCard data-component="IndividualQuestionsCard">
                                            <IndividualQuestionCardWrap data-component="IndividualQuestionCardWrap">
                                                <QuestionsCardHeader data-component="QuestionsCardHeader">
                                                    <QuestionsCardRow data-component="QuestionsCardRow">
                                                        <QuestionsCardCol1 data-component="QuestionsCardCol1">
                                                            Loss THEMES
                                                        </QuestionsCardCol1>
                                                        {all_lines?.map((line, line_index) => (
                                                            <QuestionsCardCol2 data-component="QuestionsCardCol2" key={line_index}>
                                                                {convertSubstring(`${line.line_name}`, 25)}
                                                            </QuestionsCardCol2>
                                                        ))}
                                                        <QuestionsCardCol2 data-component="QuestionsCardCol2">
                                                            Loss Elimination Actions
                                                        </QuestionsCardCol2>
                                                    </QuestionsCardRow>
                                                </QuestionsCardHeader>
                                                {/*Questions Header end */}
                                                <QuestionsCardBody data-component="QuestionsCardBody">
                                                    {questions?.map((ques, ques_index) => (
                                                        <QuestionsCardRow data-component="QuestionsCardRow" key={ques_index}>
                                                            <QuestionsCardCol1 data-component="QuestionsCardCol1">
                                                                <div className="ques-info-col">
                                                                    <p>{ques_index + 1}. {ques.question}</p>
                                                                    <button className={`info-btn ${isLossThemeDescriptionPopOpen.id === ques.id ? `active` : ``}`} onClick={() => handleSubLossDescriptionPopup(ques)}>
                                                                        <i dangerouslySetInnerHTML={{ __html: infoIconSvg }}></i>
                                                                    </button>
                                                                </div>
                                                            </QuestionsCardCol1>

                                                            {all_lines?.map((line, line_index) => (
                                                                <QuestionsCardCol2 data-component="QuestionsCardCol2" key={line_index}>
                                                                    {quesMultiLineValues[ques.id] &&
                                                                        <LossInput type='text'
                                                                            value={`${quesMultiLineValues[ques.id][line_index]}`}
                                                                            onChange={(e) => handleMultiLineOnChangeEditValue(e, ques.id, quesMultiLineValues[ques.id], line_index)}
                                                                            onFocus={(e) => handleMultiLineOnFocusValue(e, ques.id, quesMultiLineValues[ques.id], line_index)}
                                                                            onBlur={(e) => handleMultiLineOnBlurValue(e, ques.id, quesMultiLineValues[ques.id], line_index)}
                                                                            data-component="LossInput" disabled={!isEditMode} />
                                                                    }
                                                                </QuestionsCardCol2>
                                                            ))}
                                                            <QuestionsCardCol2 data-component="QuestionsCardCol2">
                                                                <ViewButton
                                                                    data-component="ViewButton"
                                                                    onClick={() => { handleOpenReasonPopup({ ...ques, index: ques_index + 1 }) }}
                                                                >
                                                                    <span>View</span>
                                                                    <i dangerouslySetInnerHTML={{ __html: viewButtonSvg }} />
                                                                </ViewButton>
                                                            </QuestionsCardCol2>

                                                        </QuestionsCardRow>))
                                                    }
                                                </QuestionsCardBody>
                                                {/*Questions Body end */}
                                            </IndividualQuestionCardWrap>
                                        </IndividualQuestionsCard>
                                    }
                                </IndividualPopupCardBody>
                                : ''
                    }
                    {(isEditMode && !isCenterdPopup) &&
                        <SaveChangesButton data-component="SaveChangesButton" className="first-save-btn" onClick={() => { handleCenterdPopup('saveChanges', {}) }}>
                            Save Changes
                        </SaveChangesButton>
                    }

                    {(isReasonEditMode && !isCenterdPopup) &&
                        <SaveChangesButton data-component="SaveChangesButton" className="scnd-save-btn"
                            onClick={() => {
                                if (totalPercent.current?.value?.replace('%', '') <= 100) {
                                    handleCenterdPopup('saveReasonChanges', {})
                                }
                            }}
                            //  onClick={() => { handleUpdateLossPopup() }}
                            disabled={totalPercent.current?.value?.replace('%', '') > 100 ? true : false}
                        >
                            Save Changes
                        </SaveChangesButton>
                    }

                    {isSaved &&
                        <ChangesSavedButton data-component="ChangesSavedButton" disabled>
                            <i dangerouslySetInnerHTML={{ __html: savedChangesSvg }} />{isSaved || `Changes Saved`}
                        </ChangesSavedButton>
                    }
                    {isCenterdPopup &&
                        <CenteredPopup data={centeredPopupObj.current} />
                    }

                    {isCommentPopup && <LossAssumptionCommentPopupCtrl data={isCommentPopup} closeFunction={closeCommentPopup} commnetsCount={commnetsCount} setCommentCounts={setCommentCounts} id={`${isReasonCodePopUp.id}_${isCommentPopup.item.id}`} />}
                    {/* {!isFirstTimeWalkthroughShow ? <LossAssumptionWalkthroughView setIsResetDefaultButton={setIsResetDefaultButton} setWalkthroughReasonButtonShow={setWalkthroughReasonButtonShow} setIsReasonEditMode={setIsReasonEditMode} setIsSetupType={setIsSetupType} setIsReasonCodePopUp={setIsReasonCodePopUp} isSingleLayout={props.sid != 101 && props.sid != 103} /> : ''} */}
                    {isThingToKeepPopupOpen && <ThingsToKeepPopup isKeepPopOpen={isThingToKeepPopupOpen} setIsKeepPopOpen={setIsThingToKeepPopupOpen} />}
                    {isTechnologyDescriptionPopOpen && <TechnologyDescriptionPopup isTechDescPopOpen={isTechnologyDescriptionPopOpen} setIsTechDescPopOpen={setIsTechnologyDescriptionPopOpen} />}
                    {isLossThemeDescriptionPopOpen && <LossThemeDescriptionPopup isLossThemeDescPopOpen={isLossThemeDescriptionPopOpen} setIsLossThemeDescPopOpen={setIsLossThemeDescriptionPopOpen} />}
                </PopupCard>
            </PopupContainer >
        </>
    )
}



const CenteredPopup = (props) => {
    const { heading, text, isDelete = false, cancelFunction, proceedFunction } = props.data || {};
    return (
        <>
            <CenteredPopupCard data-component="CenteredPopupCard" isDelete={isDelete}>
                <h2 className="centered-popup-h2">
                    {heading || `Update Loss Ideal State`}
                </h2>
                <p className="centered-popup-p" dangerouslySetInnerHTML={{ __html: text || 'text' }} />

                <CenteredPopupFooter data-component="CenteredPopupFooter">

                    <CenteredPopupCancelButton isDelete={isDelete} data-component="CenteredPopupCancelButton" onClick={cancelFunction}>
                        Cancel
                    </CenteredPopupCancelButton>
                    <CenteredPopupProceedlButton isDelete={isDelete} data-component="CenteredPopupProceedlButton" onClick={proceedFunction}>
                        Yes, {isDelete ? 'delete' : 'proceed'}
                    </CenteredPopupProceedlButton>
                </CenteredPopupFooter>
            </CenteredPopupCard >
        </>
    )
}

const ThingsToKeepPopup = (props) => {
    const { isKeepPopOpen, setIsKeepPopOpen } = props;
    return (
        <>
            {isKeepPopOpen &&
                <ThingsToKeepPopupContainer data-component="ThingsToKeepPopupContainer">
                    <ThingsToKeepPopupCard data-component="ThingsToKeepPopupCard">
                        <ThingsToKeepHeader data-component="ThingsToKeepHeader">
                            <ThingsPopLeftSec data-component="ThingsPopLeftSec">
                                <i dangerouslySetInnerHTML={{ __html: blueBulbSvg }}></i>
                                <span>Thing to keep in mind!</span>
                            </ThingsPopLeftSec>
                            <button className="close-btn" onClick={() => setIsKeepPopOpen(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeTechSvg }}></i>
                            </button>
                        </ThingsToKeepHeader>
                        <ThingsToKeepPopBody data-component="ThingsToKeepPopBody">
                            <p>The Tool gives you as user the freedom to
                                change those recoverable Loss Elimination Set Up  as you see fit
                                following your internal company manufacturing strategy.</p>
                            <p>From our experience the best ways to benchmark how much of the
                                Plant Activity might be considered a Loss can be through:</p>
                            <div className="list">
                                <div className="list-item">1. Consultation with your internal experts</div>
                                <div className="list-item">2. Discussions with your OEM's</div>
                                <div className="list-item">3. Insights from your consulting partners</div>
                                <div className="list-item">4. Insights from your technology vendors</div>
                                <div className="list-item">5. Institutional knowledge & experience</div>
                            </div>
                        </ThingsToKeepPopBody>
                    </ThingsToKeepPopupCard>
                </ThingsToKeepPopupContainer>
            }
        </>
    )
}

// Technology Description Popup

const TechnologyDescriptionPopup = (props) => {
    const { isTechDescPopOpen, setIsTechDescPopOpen } = props;
    return (
        <>
            {isTechDescPopOpen &&
                <TechDescPopupContainer data-component="TechDescPopupContainer">
                    <TechDescPopupCard data-component="TechDescPopupCard">
                        <TechDescHeader data-component="TechDescHeader">
                            <TechDescPopLeftSec data-component="TechDescPopLeftSec">
                                <i dangerouslySetInnerHTML={{ __html: techHeadIcon }}></i>
                                <span>Technology Description</span>
                            </TechDescPopLeftSec>
                            <button className="close-btn" onClick={() => setIsTechDescPopOpen(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeTechSvg }}></i>
                            </button>
                        </TechDescHeader>
                        <TechDescPopBody data-component="TechDescPopBody">
                            <div className="tech-body-card" dangerouslySetInnerHTML={{ __html: isTechDescPopOpen.description }}>

                            </div>
                        </TechDescPopBody>
                    </TechDescPopupCard>
                </TechDescPopupContainer>
            }
        </>
    )
}

// Loss Themes Description Popup

const LossThemeDescriptionPopup = (props) => {
    const { isLossThemeDescPopOpen, setIsLossThemeDescPopOpen } = props;
    return (
        <>
            {isLossThemeDescPopOpen &&
                <LossThemeDescPopupContainer data-component="LossThemeDescPopupContainer">
                    <TechDescPopupCard data-component="TechDescPopupCard">
                        <TechDescHeader data-component="TechDescHeader">
                            <TechDescPopLeftSec data-component="TechDescPopLeftSec">
                                <i dangerouslySetInnerHTML={{ __html: moreInfoHeadIcon }}></i>
                                <span>More information</span>
                            </TechDescPopLeftSec>
                            <button className="close-btn" onClick={() => setIsLossThemeDescPopOpen(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeTechSvg }}></i>
                            </button>
                        </TechDescHeader>
                        <TechDescPopBody data-component="TechDescPopBody">

                            <div className="tech-body-card">
                                <h2 className="desc-head" dangerouslySetInnerHTML={{ __html: isLossThemeDescPopOpen?.question }}></h2>
                                <div className="tech-body-card" dangerouslySetInnerHTML={{ __html: isLossThemeDescPopOpen?.question_description }}></div>
                            </div>
                        </TechDescPopBody>
                    </TechDescPopupCard>
                </LossThemeDescPopupContainer>
            }
        </>
    )
}