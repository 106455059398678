/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  
**/
import React, { useContext, useEffect } from "react";
import "./skills-assessment-page.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { AssessmentWalkthroughview } from './skill-assessment-walkthrough-view.js'
import { AssignSectionPopup } from "./assessment-assign-popup";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { CartContext } from "../../contexts/CartContext";
//SkillsAssessmentPageView component start
export const SkillsAssessmentPageView = (props) => {
  const quizDataItems = props.quizData;
  if (!quizDataItems.length) {
    return null;
  }
  const user = localStorage.getItem("group_id");
  const group_id = user ? user : 0;
  const quizDataItem = props.quizData[0];
  const inviteteam = quizDataItem.invitedMembers;
  //defining variables here
  const [APDList, setAPDList] = useState(quizDataItem.skills);
  const [isAsstWalktrough, setIsAsstWalktrough] = useState(1);
  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
  const [tmpAssignUser, setTmpAssignUser] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);


  const handleAssignPoup = (e) => {
    setIsAssignSectionPopup(true);
  };
  // Add to cart function
  const { addProduct, cartItems, increase } = useContext(CartContext);
  const isInCart = (id) => {
    return !!cartItems.find((item) => item.id === id);
  };
  const addToCart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.classList.add('added-cart-btn');
  }

  useEffect(() => {
    setAssignedUsers(inviteteam);
  }, []);


  return (
    <>
      {/* this section has page title */}
      <Helmet>
        <title>Skills Assessment | SmarterChains</title>
        <meta name="description" content="Training Courses" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Skills Assessment</BreadcrumbsItem>
      <section className="skillsassessment">
        <div className="all-sellection-main">
          {/* heading */}
          <h1 className="course__overView__h1 skills">
            <span className="skills">Skills Assessment</span>
          </h1>

          {APDList?.map((items, i) => {
            return (
              // Replaced class 'active' from 'disable-card' for Not Available status also add class in "not-available" className="skill-assessment-status "
              /* this section has
                 left-img-box
                 text-box
                 angle-box
                 sellection-card-popup
              */
              <a data-index={0} href={`/skill-assessment/${items.course_slug}`} className={`all-sellection-card ${items.cardClass === `nnotavailable` ? 'disable-card' : 'active'}`} >
                <div className="left-img-box">
                  <img src={require(`../../assets/img/skillsassessment/Course-Icons${items.id}.svg`).default} alt={items.course_slug} />
                </div>
                {/* this section has
                    text-left
                    text-cir
                */}
                <div className="text-box">
                  {/* this section has
                      heading
                      text
                  */}
                  <div className="text-left">
                    <h2 className="h1">
                      <span>{items.course_name} Assessment</span>
                    </h2>
                    {/* Added classes for status change 'to-do' for To Do, 'inprogress' for In Progress, 'complete' for Completed */}
                    <div className="skill-bottom-status">
                      <p className={`skill-assessment-status ${items.cardClass}`}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" className="status" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6" cy="6" r="6" fill="#DB324D" />
                        </svg>
                        <span className="res-text">{items.cardStatus}</span>
                      </p>
                      {(items.cardStatus === 'Completed' && items.Score < 70) &&
                        <>
                          <span className="recommend-text">Training recommended</span>
                          <Link to={`/training-courses/${items.course_slug}`} className="skill-view-btn">View Course</Link>

                          {/* Add class 'added-cart-btn' conditionally */}
                          {quizDataItem?.paid_training?.includes(String(items.id)) === false &&
                            <button className={`skill-cart-btn   ${isInCart(items.id) ? 'added-cart-btn' : 'added-cart-btn1'}`} onClick={(e) => { addProduct(items); addToCart(e); }}>
                              <span className="not-added-cart">Add to Cart</span>
                              <div className="added-cart">
                                <i dangerouslySetInnerHTML={{ __html: checkSvg }}></i>
                                <span>Added to Cart</span>
                              </div>
                            </button>
                          }

                        </>
                      }
                    </div>
                  </div>
                  <div className="text-cir">
                    <svg viewBox="0 0 36 36">
                      <text
                        className="percent__text"
                        x="50%"
                        y="38%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontSize={6}
                        fontWeight={400}
                      >
                        Score
                      </text>
                      <text
                        className="percent__text"
                        x="50%"
                        y="63%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontSize={6}
                        fontWeight={700}
                      >
                        {`${items.Score}${items.Score == '-' ? '' : '%'}`}
                      </text>
                      <path
                        className="circle-bg"
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831  a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke={
                          items.Score === 0 ? '#EBF5FA'
                            : items.Score < 70 && items.Score > 0 ? '#FAE2E5'
                              : items.Score >= 70 ? '#E5F5F1'
                                : '#EBF5FA'
                        }
                        strokeWidth="2.4"
                        fill="none"
                      />
                      <path
                        className="circle"
                        strokeDasharray={`${items.Score == '-' ? 0 : items.Score}, 100`}
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke={
                          items.Score < 70 ? '#E25B71'
                            : items.Score >= 70 ? '#45B097' : ''
                        }
                        strokeWidth="2.4"
                        fill="none"
                      />
                    </svg>
                  </div>
                </div>
                {/* this section has navigation */}
                <div className="angle-box">
                  <img
                    src={
                      require(`../../assets/img/skillsassessment/right-angle.svg`)
                        .default
                    }
                    alt="true"
                  />
                </div>
                <div className="sellection-card-popup false ">
                  <h2>{items.course_name} Assessment</h2>
                  <div>

                    <p dangerouslySetInnerHTML={{ __html: items.skill_desc }}></p>

                  </div>
                </div>
              </a>
            );
          })}
        </div>
      </section>
      {/*AssessmentWalkthroughview component*/}
      <AssignSectionPopup
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        tmpAssignUser={tmpAssignUser}
        open={isAssignSectionPopup}
        setOpen={setIsAssignSectionPopup}
        setUpDataPage={props.setUpData}
        course={1}
        catagory={1}
        {...props}
      />
      {(isAsstWalktrough && quizDataItem.skill_assessment1 == 0) && <AssessmentWalkthroughview setIsAsstWalktrough={setIsAsstWalktrough} />}
    </>
  );
};

const checkSvg = `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_2012_107087" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
<rect y="0.5" width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_2012_107087)">
<path d="M8.83464 14.3346L14.7096 8.45964L13.543 7.29297L8.83464 12.0013L6.45964 9.6263L5.29297 10.793L8.83464 14.3346ZM10.0013 18.8346C8.84852 18.8346 7.76519 18.6159 6.7513 18.1784C5.73741 17.7409 4.85547 17.1471 4.10547 16.3971C3.35547 15.6471 2.76172 14.7652 2.32422 13.7513C1.88672 12.7374 1.66797 11.6541 1.66797 10.5013C1.66797 9.34852 1.88672 8.26519 2.32422 7.2513C2.76172 6.23741 3.35547 5.35547 4.10547 4.60547C4.85547 3.85547 5.73741 3.26172 6.7513 2.82422C7.76519 2.38672 8.84852 2.16797 10.0013 2.16797C11.1541 2.16797 12.2374 2.38672 13.2513 2.82422C14.2652 3.26172 15.1471 3.85547 15.8971 4.60547C16.6471 5.35547 17.2409 6.23741 17.6784 7.2513C18.1159 8.26519 18.3346 9.34852 18.3346 10.5013C18.3346 11.6541 18.1159 12.7374 17.6784 13.7513C17.2409 14.7652 16.6471 15.6471 15.8971 16.3971C15.1471 17.1471 14.2652 17.7409 13.2513 18.1784C12.2374 18.6159 11.1541 18.8346 10.0013 18.8346Z" fill="#43A047"/>
</g>
</svg>
`


