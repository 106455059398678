/** imported components
 	
	* Link ===> Used for anchor tag.

* */

import React from "react";
import {Link} from 'react-router-dom';

//AllQuestionView component starts 
export const AllQuestionView = (props) => {
	return (
		<>
			{/* Wrapper for all questions are containing heading and text. */}
			<div className="all-qna-wrapper7-21">
				<h1 className="top-h1">
					Answered questions
				</h1>
				<p className="top-p">
					Please note that changing any input might result in the loss of subsequent answers. You are advised to reach to the section's end, if you decide to do changes.
				</p>
				{/* Qns and answer card wrapper */}
				<div className="all-qna-cards-wrap">
					<h1 class="card-h1">1. Company-wide Capabilities</h1>
					{/* Card is containing number-section, text-section and arrow-section */}
					<Link className="all-qna-card">
						<div className="no-sec">1</div>
						<div className="text-sec">
							<span>
								Do you have a formal Industry 4.0 strategy as a part of the corporate strategy?
							</span>
						</div>
						<div className="arrow-sec">
							<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
							</svg>
						</div>
					</Link>
					{/* Card is containing number-section, text-section and arrow-section */}
					<Link className="all-qna-card">
						<div className="no-sec">2</div>
						<div className="text-sec">
							<span>
								What is the focus of your Industry 4.0 strategy
							</span>
						</div>
						<div className="arrow-sec">
							<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
							</svg>
						</div>
					</Link>
					{/* Card is containing number-section, text-section and arrow-section */}
					<Link className="all-qna-card">
						<div className="no-sec">3</div>
						<div className="text-sec">
							<span>
								How do you approach digital manufacturing at a company level?
							</span>
						</div>
						<div className="arrow-sec">
							<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
							</svg>
						</div>
					</Link>
					{/* Card is containing number-section, text-section and arrow-section */}
					<Link className="all-qna-card">
						<div className="no-sec">4</div>
						<div className="text-sec">
							<span>
								Do you have a company-wide Center of Excellence (CoE) that supports local im...
							</span>
						</div>
						<div className="arrow-sec">
							<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
							</svg>
						</div>
					</Link>
					{/*cards end*/}

				</div>
				{/*box 1 end*/}
				<div className="all-qna-cards-wrap">
					<h1 class="card-h1">2. Sustainibility Strategy</h1>
					{/* Card is containing number-section, text-section and arrow-section */}
					<Link className="all-qna-card">
						<div className="no-sec">1</div>
						<div className="text-sec">
							<span>
								To what extent is sustainibility part of your company’s strategy?
							</span>
						</div>
						<div className="arrow-sec">
								<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
									</svg>
						</div>
					</Link>
					{/* Card is containing number-section, text-section and arrow-section */}
					<Link className="all-qna-card">
						<div className="no-sec">2</div>
						<div className="text-sec">
							<span>
								What is the focus of your Industry 4.0 strategy
							</span>
						</div>
						<div className="arrow-sec">
								<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
									</svg>
						</div>
					</Link>
					{/*cards end*/}

				</div>
				{/*box 2 end*/}
			</div>
		</>
	);
};
