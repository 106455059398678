/** imported files

# serviceProvider ===>from axios for api call  
# SolutionController ===> custom component
# Helmet ===> for specific title  for pages
# BreadcrumbsItem ===> for dynamic breadcrumbs
# d3 ===> for data visualization
# svg.js ===> for using svg images
**/


import { current } from 'immer';
import React from 'react';
import serviceProvider from '../../services/axios'
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SolutionController } from "./solution-ctrl";
import { powerSvg, moneySvg, factorySvg, digitalOrgSvg, techSvg, digitalReadySvg, dataDrivenSvg, intelligentSvg, visibleSvg, materialSvg, zeroSvg, dataFlowSvg } from './svg.js';
import * as d3 from "d3";
import $ from 'jquery';


export const BusinessCaseView = (props) => {
    // variable declaration 
    const bDatas = props.vResult;
    if (!bDatas.length) {
        return null;
    }
    const bData = props.vResult[0];
    const block = bData.block;
    bData.cs = '$';
    if (block == 'block') {
        return <Redirect to="/home" />;
    }

    //variables for tabs
    const [activeTab1, setActiveTab1] = useState(1);
    const [activeTab2, setActiveTab2] = useState(1);
    const [activeTab3, setActiveTab3] = useState(1);

    const [activePopUp, setActivePopUp] = useState(false);
    const [activeID, setActiveID] = useState(0);
    // shubhesh 24-11-2021
    const [hoverInactive, setHoverInactive] = useState(false);
    const HandleHoverInactive = (val) => {
        setHoverInactive(val);
    }
    // shubhesh 24-11-2021
    // const yAxis = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    // shubhesh 07-12-2021
    const [isKeyFinds, setIsKeyFinds] = useState(false);
    const [yAxis, setYAxis] = useState();
    // shubhesh 07-12-2021
    const data_raw = [
        bData.analysis.map((item, index) => {
            return {
                Category: item.category,
                id: item.id,
                Identified_Losses: `${parseFloat(item.loss).toFixed(1)}`,
                Recoverable_Potential: `${item.recoverable}`,
                recoverd: `${Math.round((item.recoverable / item.loss) * 100)}`,
            };
        }),
    ];
    const data = data_raw[0];

    //Popup Js start
    const HandlePopup = (id) => {
        setActiveID(id);

        setActivePopUp(true);
    }
    var totcolinv = 0;
    var totcolrec = 0;
    var totcolnpv = 0;

    var totcoly1 = 0;
    var totcoly2 = 0;
    var totcoly3 = 0;
    var totcoly4 = 0;
    var totcoly5 = 0;
    var solutions_list = 1;
    // shubhesh 07-12-2021
    useEffect(() => {
        let tmpArr = [];
        data.forEach((item, index) => {
            tmpArr.push(Number(item['Identified_Losses']));
            tmpArr.push(Number(item['Recoverable_Potential']));
        });
        let maxVal = d3.max(tmpArr);
        let tmpYAxis = [];
        let inc = maxVal < 10 ? 1 : 10;

        for (let i = 0; i <= maxVal; i += inc) {
            tmpYAxis.push(i);
        }
        // if(maxVal<=100){
        //     let lastVal=tmpYAxis[tmpYAxis.length-1];
        //     tmpYAxis.push(lastVal+10);
        // }
        let lastVal = tmpYAxis[tmpYAxis.length - 1];
        tmpYAxis.push(lastVal + inc);
        setYAxis(tmpYAxis);
    }, []);

    // shubhesh 07-12-2021	
    //end

    return (
        <>
            <Helmet>
                <link rel="stylesheet" href={require('./bussiness-case.scss').default} />
            </Helmet>

            <BreadcrumbsItem to="/">{bData.plant_name}</BreadcrumbsItem>
            <BreadcrumbsItem to="#"> Business case</BreadcrumbsItem>
            {/*business case main wrapper started*/}
            <section className="business-case-main-wrapper">
                <div className="busc-top-section">
                    <div className="busc-top-row">
                        <div className="busc-top-col">
                            <div className="bold-text card">
                                <span className="black-txt">Current State</span>
                                <button onClick={() => setIsKeyFinds(true)}>Key Findings</button>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="conversion-text card">
                                <span className="blue-txt">{bData.cs}{parseFloat(bData.keydata.current_tmc)} <span className="small-txt">Mn</span></span>
                                <span className="normal-txt">Conversion Cost <br />incl. Waste</span>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="percent-txt card">
                                <span className="blue-txt percent">{bData.indexscore}%</span>
                                <div className="normal-txt">
                                    <span className="left-txt">Digital Maturity <br />Index</span>
                                    <Link to={`/result/${props.slug}`} style={{ lineHeight: 0 }}>
                                        <img src={require("../../assets/img/business-case-img/arrow-right-blue.svg").default} alt="Blue Arrow" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="percent-blue-card card">
                                <span className="blue-txt">{bData.cs}{parseFloat(bData.keydata.current_tli)} <span className="small-txt">Mn</span></span>
                                <span className="normal-txt">Total Losses<br /> Identified</span>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="prod-text card">
                                <span className="blue-txt">{parseFloat(bData.keydata.current_production_vol)} <span className="small-txt">{bData.keydata.current_production_vol_unit}</span></span>
                                <span className="normal-txt">Production Volume</span>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="bold-text card">
                                <span className="black-txt">Future State</span>
                                {/*<button>Takeaways</button>*/}
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="green-txt first card">
                                <div className="number-col">
                                    <span className="blue-txt">{bData.cs}{parseFloat(bData.keydata.future_tmc)}  <span className="small-txt">Mn</span></span>
                                </div>
                                <div className="img-text-col">
                                    <img src={require("../../assets/img/business-case-img/green-polygon.svg").default} alt="Polygon" />
                                    <span className="txt">{parseFloat(bData.keydata.future_tmc_change)}<span className="small-txt">%(-{bData.cs}{(parseFloat(bData.keydata.current_tmc) - parseFloat(bData.keydata.future_tmc)).toFixed(1)} Mn )</span> </span>
                                </div>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="green-txt second card">
                                <div className="number-col">
                                    <span className="blue-txt percent">{parseFloat(bData.keydata.future_maturity)}%</span>
                                </div>
                                <div className="img-text-col">
                                    <img className="increment-img" src={require("../../assets/img/business-case-img/green-polygon.svg").default} alt="Polygon" />
                                    <span className="txt">{bData.keydata.future_maturity_change}<span className="small-txt"> increase</span> </span>
                                </div>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="green-txt third card">
                                <div className="number-col">
                                    <span className="blue-txt percent"><>$</>{parseFloat(bData.keydata.future_tli)}<span className="small-txt"> Mn</span> </span>
                                </div>
                                <div className="img-text-col">
                                    <img className="" src={require("../../assets/img/business-case-img/green-polygon.svg").default} alt="Polygon" />
                                    <p className="txt">{parseFloat(bData.keydata.future_tli_change)}<span className="small-txt">% </span></p>

                                </div>
                            </div>
                        </div>
                        <div className="busc-top-col">
                            <div className="gray-text card">
                                <span className="gray-txt">{bData.cs}{parseFloat(bData.keydata.total_investment)} <span className="small-txt">Mn</span></span>
                                <span className="normal-txt">{bData.keydata.year}-year Investment</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/*top section div ended*/}
                <div className="busc-middle-section">
                    <div className="busc-mid-row">
                        <div className="left-col">
                            <div className="tab-title-section">
                                <span className="title">Industry 4.0 Roadmap</span>
                                <div className="tabgroup">
                                    <button className={`btn ${activeTab1 == 1 ? 'active' : ''}`} onClick={() => setActiveTab1(1)}>Dimension Roadmap</button>
                                    <button className={`btn ${activeTab1 == 2 ? 'active' : ''}`} onClick={() => setActiveTab1(2)}>Solution Roadmap</button>
                                </div>
                            </div>
                            <TabPane index={1} activeIndex={activeTab1} {...props}>
                                <div className="tab-detail-section dimension-tab">
                                    {bData.roadmap.map((items, i) => {
                                        return (
                                            <div className="business-tab-col" onMouseEnter={() => HandleHoverInactive({ i })} onMouseLeave={() => HandleHoverInactive(false)}>
                                                <div className="img-box">

                                                    <img className="stat-img" src={require(`../../assets/img/business-case-img/bc-icon/bci-${items.tech_id}.svg`).default} alt="Loss-Analysis-img" />
                                                    <img className="hover-img" src={require(`../../assets/img/business-case-img/bc-icon/bci-${items.tech_id}-hover.svg`).default} alt="Loss-Analysis-img" />
                                                </div>
                                                <p className="tab-text">{items.name}</p>
                                                <div className="right-img-box"><div className="imoney-img" dangerouslySetInnerHTML={{ __html: moneySvg }}></div>
                                                    <span className="text">{bData.cs}{items.investment} Mn</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </TabPane>
                            <TabPane index={2} activeIndex={activeTab1} {...props}>
                                <div className="tab-detail-section solution-tab">

                                    <div className="foundation-col solution-col">
                                        {bData.solution.length > 0 && <span className="left-tab-txt">Foundations</span>}
                                        {bData.solution.length > 0 && bData.solution.map((items, i) => {
                                            if (items.node_type == 1) {
                                                return (
                                                    <div className="business-tab-col first-tab" onMouseEnter={() => HandleHoverInactive(i + 1)} onMouseLeave={() => HandleHoverInactive(false)} onClick={() => HandlePopup(items.id)}>
                                                        <div className="img-box">
                                                            {i + 1}
                                                        </div>
                                                        <p className="tab-text">{items.name}</p>
                                                        <div className="right-img-box">
                                                            <div className="imoney-img" dangerouslySetInnerHTML={{ __html: moneySvg }}></div>
                                                            <span className="text">{bData.cs}{items.investment} Mn</span>
                                                        </div>
                                                    </div>
                                                )
                                                solutions_list = i + 1;
                                            }

                                        })}
                                    </div>
                                    <div className="acc-col solution-col">
                                        {bData.solution.length > 0 && <span className="left-tab-txt">Acceleration</span>}
                                        {bData.solution.length > 0 && bData.solution.map((items, i) => {
                                            if (items.node_type == 2) {
                                                return (
                                                    <div className="business-tab-col" onMouseEnter={() => HandleHoverInactive(i + 1)} onMouseLeave={() => HandleHoverInactive(false)} onClick={() => HandlePopup(items.id)}>
                                                        <div className="img-box">
                                                            {i + 1}
                                                        </div>
                                                        <p className="tab-text">{items.name}</p>
                                                        <div className="right-img-box">
                                                            <div className="imoney-img" dangerouslySetInnerHTML={{ __html: moneySvg }}></div>
                                                            <span className="text">{bData.cs}{items.investment} Mn</span>
                                                        </div>
                                                    </div>
                                                )
                                                solutions_list = i + 1;
                                            }

                                        })}
                                    </div>
                                    <div className="ampli-col solution-col">
                                        {bData.solution.length > 0 && <span className="left-tab-txt">Amplification</span>}
                                        {bData.solution.length > 0 && bData.solution.map((items, i) => {
                                            if (items.node_type == 3) {
                                                return (
                                                    <div className="business-tab-col" onMouseEnter={() => HandleHoverInactive(i + 1)} onMouseLeave={() => HandleHoverInactive(false)} onClick={() => HandlePopup(items.id)}>
                                                        <div className="img-box">
                                                            {i + 1}
                                                        </div>
                                                        <p className="tab-text">{items.name}</p>
                                                        <div className="right-img-box">
                                                            <div className="imoney-img" dangerouslySetInnerHTML={{ __html: moneySvg }}></div>
                                                            <span className="text">{bData.cs}{items.investment} Mn</span>
                                                        </div>
                                                    </div>
                                                )
                                                solutions_list = i + 1;
                                            }
                                        })}
                                    </div>

                                </div>
                            </TabPane>

                        </div>
                        <div className="right-col">
                            <div className="tab-title-section">
                                <span className="title">Analysis Charts</span>
                                <div className="tabgroup">
                                    <a href={`/powerbi/bresults/${props.slug}`} className="power-btn" dangerouslySetInnerHTML={{ __html: powerSvg }}>

                                    </a>
                                    <button className={`btn loss ${activeTab2 == 1 ? 'active' : ''}`} onClick={() => setActiveTab2(1)}>
                                        Losses & Recoverables
                                    </button>
                                    <button className={`btn ${activeTab2 == 2 ? 'active' : ''}`} onClick={() => setActiveTab2(2)}>
                                        Timeline
                                    </button>
                                </div>
                            </div>
                            <>
                                <TabPane index={1} activeIndex={activeTab2} {...props}>
                                    {yAxis && <BarChart yAxis={yAxis} data={data} {...props} />}
                                </TabPane>
                                <TabPane index={2} activeIndex={activeTab2} {...props}>
                                    <TimeLineTable hoverInactive={hoverInactive} timesolution={bData.solution} {...props} />
                                </TabPane>
                            </>
                        </div>
                    </div>
                </div>
                {/*middle section div ended*/}
                <div className="busc-bottom-section">
                    <div className="busc-bottom-row">
                        <div className="left-col">
                            <h2 className="top-heading">Investment Breakdown</h2>
                            <div className="investment-card">


                                <div className="invest-card-row">
                                    <span className="number">{bData.cs}{parseFloat(bData.keydata.total_investment)}M</span>
                                    <p className="invest-txt">Total Investment ({bData.keydata.year}-year)</p>
                                </div>
                                {bData.rbreakdowm.length > 0 && bData.rbreakdowm.map((items, i) => {
                                    return (
                                        <div className="invest-card-row">
                                            <p className="progress-left-txt">{items.name}</p>
                                            <div className="progress-bar-col">
                                                <div className="progress-bar-row">
                                                    <div className="progress-bar" style={{ width: `${items.breakdown}%` }} ></div>
                                                    <span className="pro-txt">{items.breakdown}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }


                            </div>
                        </div>
                        <div className="right-col">
                            <div className="tab-title-section">
                                <span className="title">Cost-Benefit Analysis</span>
                                <div className="tabgroup">
                                    <button className={`btn ${activeTab3 == 1 ? 'active' : ''}`} onClick={() => setActiveTab3(1)}>
                                        By Dimension
                                    </button>
                                    <button className={`btn ${activeTab3 == 2 ? 'active' : ''}`} onClick={() => setActiveTab3(2)}>
                                        By Year
                                    </button>
                                </div>
                            </div>
                            <TabPane index={1} activeIndex={activeTab3} {...props}>
                                <div className="analysis-table by-dimension">
                                    <div className="analysis-row">
                                        <div className="heading col">
                                            <p className="anlyis-txt">Industry 4.0 Dimension</p>
                                            <p className="anlyis-txt">{bData.keydata.year}-year Investment</p>
                                            <p className="anlyis-txt">5-year Recoverable</p>
                                            <p className="anlyis-txt">{bData.keydata.year}-year NPV</p>
                                        </div>
                                    </div>

                                    {bData.roadmap.length > 0 && bData.roadmap.map((items, i) => {
                                        totcolinv = totcolinv + parseFloat(items.col_investment);
                                        totcolrec = totcolrec + parseFloat(items.col_recovarble);
                                        totcolnpv = totcolnpv + parseFloat(items.col_npv);
                                        return (
                                            <div className="analysis-row">
                                                <div className="col">
                                                    <p className="anlyis-txt">{items.name}</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.col_investment} Mn</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.col_recovarble} Mn</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.col_npv} Mn</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {bData.roadmap.length > 0 && <div className="analysis-row">
                                        <div className="total col">
                                            <p className="anlyis-txt">Total</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcolinv).toFixed(2)} Mn</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcolrec).toFixed(2)} Mn</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcolnpv).toFixed(2)} Mn</p>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </TabPane>
                            <TabPane index={2} activeIndex={activeTab3} {...props}>
                                <div className="analysis-table by-year">
                                    <div className="analysis-row">
                                        <div className="heading col">
                                            <p className="anlyis-txt">Roadmap Impact</p>
                                            <p className="anlyis-txt">Year 1</p>
                                            <p className="anlyis-txt">Year 2</p>
                                            <p className="anlyis-txt">Year 3</p>
                                            <p className="anlyis-txt">Year 4</p>
                                            <p className="anlyis-txt">Year 5</p>

                                        </div>
                                    </div>

                                    {bData.cbayear.length > 0 && bData.cbayear.map((items, i) => {
                                        totcoly1 = totcoly1 + parseFloat(items.year1);
                                        totcoly2 = totcoly2 + parseFloat(items.year2);
                                        totcoly3 = totcoly3 + parseFloat(items.year3);
                                        totcoly4 = totcoly4 + parseFloat(items.year4);
                                        totcoly5 = totcoly5 + parseFloat(items.year5);

                                        return (
                                            <div className="analysis-row">
                                                <div className="col">
                                                    <p className="anlyis-txt">{items.col}</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.year1} Mn</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.year2} Mn</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.year3} Mn</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.year4} Mn</p>
                                                    <p className="anlyis-txt">{bData.cs}{items.year5} Mn</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }




                                    <div className="analysis-row">
                                        <div className="total col">
                                            <p className="anlyis-txt">Cash Flow</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcoly1).toFixed(2)} Mn</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcoly2).toFixed(2)} Mn</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcoly3).toFixed(2)} Mn</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcoly4).toFixed(2)} Mn</p>
                                            <p className="anlyis-txt">{bData.cs}{parseFloat(totcoly5).toFixed(2)} Mn</p>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>

                        </div>
                    </div>
                </div>
                {/*bottom section div ended*/}
                {activePopUp &&
                    <SolutionController pid={bData.pid} slug={props.slug} activePopUp={activePopUp} setActivePopUp={setActivePopUp} id={activeID} {...props} />
                }
            </section>
            {/*business case main wrapper ended*/}
            <KeyFindingsPopus isOpen={isKeyFinds} datakey={bData.keyfinding} setIsOpen={setIsKeyFinds} />
        </>
    )
}

/*----------------TabPane component started------------*/
const TabPane = (props) => {
    const { index, activeIndex, children } = props;
    return (
        <>
            {index === activeIndex && <>{children} </>}
        </>
    )
}
/*----------------TimeLineTable component started------------*/
const TimeLineTable = (props) => {
    const { hoverInactive } = props;
    const timesolution = props.timesolution;
    return (
        <div className="time-line-table">
            <div className="t-head">
                <div className="t-row">
                    <div className="t-col col-no">#</div>
                    <div className="t-col col-tech">Technology</div>
                    <div className="t-col col-year">Year 1</div>
                    <div className="t-col col-year">Year 2</div>
                    <div className="t-col col-year">Year 3</div>
                    <div className="t-col col-year">Year 4</div>
                    <div className="t-col col-year">Year 5</div>
                </div>
            </div>
            {/* t-head end */}
            <div className="t-body">
                {timesolution.length > 0 && timesolution.map((items, i) => {
                    if (items.node_type == 1) {
                        return (
                            <div className={`t-row ${hoverInactive != (i + 1) && hoverInactive ? 'inactive' : ''}`}>
                                <div className="t-col col-no">{i + 1}</div>
                                <div className="t-col col-tech">{items.name}</div>
                                <div className="t-col col-year">
                                    {items.ty1 > 0 && <div className="progress green">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty2 > 0 && <div className="progress green">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty3 > 0 && <div className="progress green">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty4 > 0 && <div className="progress green">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty5 > 0 && <div className="progress green">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                })
                }
                {timesolution.length > 0 && timesolution.map((items, i) => {
                    if (items.node_type == 2) {
                        return (
                            <div className={`t-row ${hoverInactive != (i + 1) && hoverInactive ? 'inactive' : ''}`}>
                                <div className="t-col col-no">{i + 1}</div>
                                <div className="t-col col-tech">{items.name}</div>
                                <div className="t-col col-year">
                                    {items.ty1 > 0 && <div className="progress red">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty2 > 0 && <div className="progress red">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty3 > 0 && <div className="progress red">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty4 > 0 && <div className="progress red">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty5 > 0 && <div className="progress red">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                })
                }
                {timesolution.length > 0 && timesolution.map((items, i) => {
                    if (items.node_type == 3) {
                        return (
                            <div className={`t-row ${hoverInactive != (i + 1) && hoverInactive ? 'inactive' : ''}`}>
                                <div className="t-col col-no">{i + 1}</div>
                                <div className="t-col col-tech">{items.name}</div>
                                <div className="t-col col-year">
                                    {items.ty1 > 0 && <div className="progress blue">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty2 > 0 && <div className="progress blue">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty3 > 0 && <div className="progress blue">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty4 > 0 && <div className="progress blue">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                                <div className="t-col col-year">
                                    {items.ty5 > 0 && <div className="progress blue">
                                        <span style={{ width: '100%' }}></span>
                                    </div>
                                    }
                                </div>
                            </div>
                        )
                    }
                })
                }
            </div>
            {/* t-body end */}
        </div>
    )
}

/*----------------BarChart component started----------------*/
const BarChart = (props) => {
    const { data, yAxis } = props;
    let drawChart = (id) => {
        let chartDiv = document.querySelector(id);
        let svgWidth = chartDiv.clientWidth;
        let svgHeight = chartDiv.clientHeight;
        let barColor = ['#2D9CDB', '#40D30D']
        let rx = 8;
        let ry = 8;
        let margin = {
            left: 35,
            bottom: 100,
            innerLeft: 35
        }
        let graphArea = {
            height: svgHeight - margin.bottom
        }
        let groupH = (graphArea.height / yAxis.length) * (yAxis.length - 1)
        let svg = d3.selectAll(id).append('svg');
        let singleBarGroup = 56;
        let barWidth = 2;
        let topVal = yAxis[yAxis.length - 1]
        if (svgWidth < (data.length * (singleBarGroup + margin.left))) {
            svgWidth = (data.length * (singleBarGroup + margin.left)) + 50
        }
        svg.attr('width', svgWidth);
        svg.attr('height', svgHeight);
        let mainG = svg.append('g')
            .attr('id', 'mainGroup')
            .attr('transform', 'translate(50, 25)');
        // FUNTION TO WRAP TEXT 
        let wrappText = (text) => {
            return text.split(' ');
        }
        const scaleY = d3.scaleLinear()
            .domain([0, topVal])
            .range([0, groupH])
        //bars
        let barsG = mainG.append('g')
            .attr('id', 'bars-g')
            .attr('transform', 'translate(25, 0)')
            .selectAll('lines')
            .data(data)
            .enter()
            .append('g')
            .attr('class', 'grouped-bar')
            .style('cursor', 'pointer')
            .attr('transform', (d, i) => `translate(${(singleBarGroup + margin.left) * i},0)`)
            .on('mouseenter', function (ev, d) {
                $('#bars-g .grouped-bar .bar,#bars-g .grouped-bar .bar-text').css('opacity', '0.5')
                $(this).children('.bar').css('opacity', '1')
                $(this).children('.bar-text').css('opacity', '1')
                $(`${id} .la-chart-tooltip .l-ident-txt span`).text(`$${d["Identified_Losses"]} Mn`);
                $(`${id} .la-chart-tooltip .l-reco-txt span`).text(`${d["recoverd"]}` + '%');
                $(`${id} .la-chart-tooltip`)
                    .addClass('active')
                    .css({
                        left: ev.x + 'px',
                        top: ev.y + 'px',
                    });
                if ((ev.clientX + $(`${id} .la-chart-tooltip`).width() + singleBarGroup + margin.left) > window.innerWidth) {
                    $(`${id} .la-chart-tooltip`)
                        .addClass('right')
                }
            })
            .on('mouseleave', (ev) => {
                $('#bars-g .grouped-bar .bar,#bars-g .grouped-bar .bar-text').css('opacity', '1')
                $(`${id} .la-chart-tooltip`)
                    .removeClass('active')
                $(`${id} .la-chart-tooltip`)
                    .removeClass('right')
            });
        // grouped bars
        barsG.selectAll('bar')
            .data(
                (d) => {
                    let tmpArr = [];
                    Object.keys(d).map((item) => {
                        if (item == "Identified_Losses" || item == "Recoverable_Potential") {
                            tmpArr.push(d[item]);
                        }
                    })
                    return tmpArr;
                })
            .enter()
            .append('path')
            .attr('class', 'bar')
            .attr('d', (d, i) => {
                return `
            M${(barWidth + margin.innerLeft) * i},${graphArea.height - scaleY(d)} 
            h${barWidth}
            a${rx},${ry} 0 0 1 ${rx},${ry}
            v${scaleY(d)} v${rx}
            h-${rx} h-${barWidth} h-${rx} 
            v-${rx} v-${scaleY(d)}
            a${rx},${ry} 0 0 1 ${rx},-${ry}z`
            })
            .attr('fill', (d, i) => barColor[i])
        // y axis 
        mainG.append('g')
            .attr('id', 'yAxis-g')
            .attr('transform', 'translate(-15, 3)')
            .selectAll('text')
            .data(yAxis)
            .enter()
            .append('text')
            .attr('class', (d, i) => { return `y-no y-no-${i}` })
            .attr('y', (d, i) => { return graphArea.height - (graphArea.height / yAxis.length) * i })
            .attr('text-anchor', 'end')
            .text((d, i) => d);
        // x axis lines
        mainG.append('g')
            .attr('id', 'xAxis-g')
            .selectAll('lines')
            .data(yAxis)
            .enter()
            .append('path')
            .attr('d', (d, i) => {
                return `M0 ${graphArea.height - ((graphArea.height) / yAxis.length) * i}
                        H${svgWidth}`
            })
            .attr('class', (d, i) => { return `x-line x-line-${i}` });
        // bottom text
        let rectG = barsG.append('g').attr('class', 'rct-g');
        rectG
            .append('rect')
            .attr('width', singleBarGroup + 1)
            .attr('height', 30)
            .attr('fill', "#fff")
            .attr('transform', (d, i) => `translate(-9,${graphArea.height})`);
        rectG
            .append('text')
            .attr('transform', (d, i) => `translate(17,${graphArea.height})`)
            .attr('class', 'x-bottom')
            .attr('text-anchor', 'middle')
            .selectAll('tspan')
            .data((d) => wrappText(d.Category))
            .enter()
            .append('tspan')
            .attr('y', 12)
            .attr('x', 0)
            .attr('dy', (d, i) => `${1 * i}em`)
            .text((d, i) => d)
        // grouped bars text
        barsG.selectAll('.bar-text')
            .data(
                (d) => {
                    let tmpArr = [];
                    Object.keys(d).map((item) => {
                        if (item == "Identified Losses" || item == "Recoverable Potential") {
                            tmpArr.push(d[item]);
                        }
                    })
                    return tmpArr;
                })
            .enter()
            .append('text')
            .text((d) => `$${d} Mn`)
            .attr('x', (d, i) => { return (barWidth + margin.innerLeft) * i })
            .attr('y', (d, i) => { return (graphArea.height) - scaleY(d) })
            .attr('transform', 'translate(-15,-8)')
            .attr('class', 'bar-text')
    }
    useEffect(() => {
        // chart js function call
        drawChart('#lAChart')
        // chart js function call end
    }, [])
    return (
        <div className="la-chart-wrap">
            {/* chart div started */}
            <div className="la-chart" id='lAChart'>
                <div className="la-chart-labelbox">
                    <p className="label">
                        <span className="cir">
                            <svg width={7} height={7} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width={7} height={7} rx="3.5" fill="#2D9CDB" />
                            </svg>
                        </span>
                        <span>Identified Losses</span>
                    </p>
                    <p className="label">
                        <span className="cir">
                            <svg width={7} height={7} viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width={7} height={7} rx="3.5" fill="#3FDE74" />
                            </svg>
                        </span>
                        <span>Recoverable Potential</span>
                    </p>

                </div>
                {/* chart label box div ended */}

                <div className="la-chart-tooltip">
                    <p className="l-ident-txt">
                        <span></span>
                        &nbsp;
                        Losses Identified
                    </p>
                    <p className="l-reco-txt">
                        <span></span>
                        &nbsp;
                        Recoverable Potential
                    </p>
                </div>
                {/* chart tooltip div ended */}
            </div>
            {/* chart div ended */}
        </div>
    )
}

/*----------------KeyFindingsPopus component started--------*/
const KeyFindingsPopus = (props) => {
    var db = props.datakey;
    return (
        <>
            <div className={`key-finds-popup ${props.isOpen ? 'active' : ''}`}>
                <div className="key-finds-container">
                    <header>
                        <p className="title-h1">Key Findings - Loss Analysis</p>
                        <button className="close-btn" onClick={() => props.setIsOpen(false)}>
                            <img src={require("../../assets/img/business-case-img/close.svg").default} alt="Polygon" />
                        </button>
                    </header>
                    {/* header section ended */}
                    <section>

                        {db.length > 0 && db.map((items, i) => {


                            return (
                                <div className="inner-card">
                                    <div className="left-box">{i + 1}</div>
                                    <div className="right-box">
                                        <p className="text-b">{items.heading}</p>
                                        <p>{items.text}</p>
                                    </div>
                                </div>

                            )
                        })
                        }


                    </section>
                    {/* content section ended */}
                </div>
                {/* key finds container div ended */}
            </div>
            {/* popup main div ended */}
        </>
    )


}