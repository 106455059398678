/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.

**/

import React from "react";
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
//LtExportView component starts here
export const LtExportView = (props) => {
  //defining variables here
  const sectionItem = props.sectionData;
 // console.log(sectionItem);
  /*{sectionItem.exporturl}*/
  return (
    <>
    {/* main wrapper  has three parts
      downtime-left
      button-wrap (2 elements)
    */}
      <div className="lt-export-wrap">
        {/* inside this div one button-group
            button-group has download-btn
        */}
        <div className="downtime-left bcr-export">
          <div className="button-group">
            <a className="download-btn" href={sectionItem.exporturl}>
              Download
            </a>
          </div>
        </div>
         {/*
            button-group has download-btn
        */}
        <div className="button-group bt-grp-nl">
          <a className="download-btn" href={sectionItem.exportlossurl}>
            Export Loss Calculation
          </a>
        </div>
         {/*
            button-group has download-btn
        */}
        <div className="button-group bt-grp-nl">
            <a className="download-btn" href={sectionItem.recoverable}>
              Export Recoverable
            </a>
        </div>
      </div>
      {/* css for styling */}
      <Helmet>
        <link href={require("./lt-export.scss").default} />
      </Helmet>
    </>
  );
};
