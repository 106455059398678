/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * CpgstudyView for ==> custom view component.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.
  * yupResolver for ==> React hooks for form validation.

**/
import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { QuickStart } from "../quickstart/quickstart-ctrl";
import { CpgstudyView } from "./cpgstudy-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import "./cpgstudy.scss";

// CpgstudyController Start
export const CpgstudyController = (props) => {
  // defining variables here
  const [results, setResults] = useState([]);
  const [tourComplete, setTourComplete] = useState("");
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  useEffect(() => {
    props.handleExpandFal();
    const search = async () => {
      const apiUrl = `/home?param=homedata&uid=${usid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults(data);
    };
    search();
  }, [tourComplete]);

  return (
    <>
      {/* Page title */}
      <Helmet>
        <title>CPG Industry Study | SmarterChains</title>
        <meta name="description" content="CPG Industry Study" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/learning-center">Resource Center</BreadcrumbsItem>
      <BreadcrumbsItem to="/cpgstudy">CPG Industry Study</BreadcrumbsItem>
      {/* View component start */}
      <CpgstudyView
        homeData={results}
        onTourComplete={setTourComplete}
        {...props}
      />
    </>
  );
};
