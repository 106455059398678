export const closeIcon=`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="4" fill="#E7EBF8"/>
<path d="M11.1 22.3C10.7134 22.6866 10.0866 22.6866 9.7 22.3C9.3134 21.9134 9.3134 21.2866 9.7 20.9L13.8929 16.7071C14.2834 16.3166 14.2834 15.6834 13.8929 15.2929L9.7 11.1C9.3134 10.7134 9.3134 10.0866 9.7 9.7C10.0866 9.3134 10.7134 9.3134 11.1 9.7L15.2929 13.8929C15.6834 14.2834 16.3166 14.2834 16.7071 13.8929L20.9 9.7C21.2866 9.3134 21.9134 9.3134 22.3 9.7C22.6866 10.0866 22.6866 10.7134 22.3 11.1L18.1071 15.2929C17.7166 15.6834 17.7166 16.3166 18.1071 16.7071L22.3 20.9C22.6866 21.2866 22.6866 21.9134 22.3 22.3C21.9134 22.6866 21.2866 22.6866 20.9 22.3L16.7071 18.1071C16.3166 17.7166 15.6834 17.7166 15.2929 18.1071L11.1 22.3Z" fill="#2C55A0"/>
</svg>
`
export const dataIcon =`<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="bookmark_manager">
<mask id="mask0_1_52857" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52857)">
<path id="bookmark_manager_2" d="M23.335 36.668V33.1263L31.9183 24.5013L35.5016 28.0013L26.8766 36.668H23.335ZM36.71 26.8346L33.1683 23.293L34.335 22.1263C34.6683 21.793 35.0711 21.6263 35.5433 21.6263C36.0155 21.6263 36.4044 21.793 36.71 22.1263L37.8766 23.3346C38.1822 23.668 38.335 24.0638 38.335 24.5221C38.335 24.9805 38.1822 25.3624 37.8766 25.668L36.71 26.8346ZM6.66829 33.3346C5.75163 33.3346 4.96691 33.0082 4.31413 32.3555C3.66135 31.7027 3.33496 30.918 3.33496 30.0013V10.0013C3.33496 9.08464 3.66135 8.29991 4.31413 7.64714C4.96691 6.99436 5.75163 6.66797 6.66829 6.66797H16.6683L20.0016 10.0013H33.335C34.2516 10.0013 35.0364 10.3277 35.6891 10.9805C36.3419 11.6332 36.6683 12.418 36.6683 13.3346V18.2096C35.8072 18.2096 34.96 18.3138 34.1266 18.5221C33.2933 18.7305 32.5711 19.1402 31.96 19.7513L18.46 33.3346H6.66829Z" fill="#90A4AE"/>
</g>
</g>
</svg>
`
export const rankIcon=`<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="dvr">
<mask id="mask0_1_52862" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52862)">
<path id="dvr_2" d="M11.6683 23.3333C12.1405 23.3333 12.5363 23.1736 12.8558 22.8542C13.1752 22.5347 13.335 22.1389 13.335 21.6667C13.335 21.1944 13.1752 20.7986 12.8558 20.4792C12.5363 20.1597 12.1405 20 11.6683 20C11.1961 20 10.8002 20.1597 10.4808 20.4792C10.1613 20.7986 10.0016 21.1944 10.0016 21.6667C10.0016 22.1389 10.1613 22.5347 10.4808 22.8542C10.8002 23.1736 11.1961 23.3333 11.6683 23.3333ZM11.6683 16.6667C12.1405 16.6667 12.5363 16.5069 12.8558 16.1875C13.1752 15.8681 13.335 15.4722 13.335 15C13.335 14.5278 13.1752 14.1319 12.8558 13.8125C12.5363 13.4931 12.1405 13.3333 11.6683 13.3333C11.1961 13.3333 10.8002 13.4931 10.4808 13.8125C10.1613 14.1319 10.0016 14.5278 10.0016 15C10.0016 15.4722 10.1613 15.8681 10.4808 16.1875C10.8002 16.5069 11.1961 16.6667 11.6683 16.6667ZM15.0016 23.3333H30.0016V20H15.0016V23.3333ZM15.0016 16.6667H30.0016V13.3333H15.0016V16.6667ZM13.335 35V31.6667H6.66829C5.75163 31.6667 4.96691 31.3403 4.31413 30.6875C3.66135 30.0347 3.33496 29.25 3.33496 28.3333V8.33333C3.33496 7.41667 3.66135 6.63194 4.31413 5.97917C4.96691 5.32639 5.75163 5 6.66829 5H33.335C34.2516 5 35.0364 5.32639 35.6891 5.97917C36.3419 6.63194 36.6683 7.41667 36.6683 8.33333V28.3333C36.6683 29.25 36.3419 30.0347 35.6891 30.6875C35.0364 31.3403 34.2516 31.6667 33.335 31.6667H26.6683V35H13.335Z" fill="#90A4AE"/>
</g>
</g>
</svg>
`
export const insightIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="document_scanner">
<mask id="mask0_1_52867" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52867)">
<path id="document_scanner_2" d="M3.33496 10.0013V1.66797H11.6683V5.0013H6.66829V10.0013H3.33496ZM33.335 10.0013V5.0013H28.335V1.66797H36.6683V10.0013H33.335ZM3.33496 38.3346V30.0013H6.66829V35.0013H11.6683V38.3346H3.33496ZM28.335 38.3346V35.0013H33.335V30.0013H36.6683V38.3346H28.335ZM11.6683 33.3346C10.7516 33.3346 9.96691 33.0082 9.31413 32.3555C8.66135 31.7027 8.33496 30.918 8.33496 30.0013V10.0013C8.33496 9.08464 8.66135 8.29991 9.31413 7.64714C9.96691 6.99436 10.7516 6.66797 11.6683 6.66797H28.335C29.2516 6.66797 30.0364 6.99436 30.6891 7.64714C31.3419 8.29991 31.6683 9.08464 31.6683 10.0013V30.0013C31.6683 30.918 31.3419 31.7027 30.6891 32.3555C30.0364 33.0082 29.2516 33.3346 28.335 33.3346H11.6683ZM15.0016 16.668H25.0016V13.3346H15.0016V16.668ZM15.0016 21.668H25.0016V18.3346H15.0016V21.668ZM15.0016 26.668H25.0016V23.3346H15.0016V26.668Z" fill="#90A4AE"/>
</g>
</g>
</svg>
`
export const insertIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="insert_chart">
<mask id="mask0_1_52872" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52872)">
<path id="insert_chart_2" d="M11.6667 28.3333H15V16.6667H11.6667V28.3333ZM18.3333 28.3333H21.6667V11.6667H18.3333V28.3333ZM25 28.3333H28.3333V21.6667H25V28.3333ZM8.33333 35C7.41667 35 6.63194 34.6736 5.97917 34.0208C5.32639 33.3681 5 32.5833 5 31.6667V8.33333C5 7.41667 5.32639 6.63194 5.97917 5.97917C6.63194 5.32639 7.41667 5 8.33333 5H31.6667C32.5833 5 33.3681 5.32639 34.0208 5.97917C34.6736 6.63194 35 7.41667 35 8.33333V31.6667C35 32.5833 34.6736 33.3681 34.0208 34.0208C33.3681 34.6736 32.5833 35 31.6667 35H8.33333Z" fill="#90A4AE"/>
</g>
</g>
</svg>
`
export const goalIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="ads_click">
<mask id="mask0_1_52900" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52900)">
<path id="ads_click_2" d="M19.4999 29.9997C16.8333 29.8608 14.5833 28.833 12.7499 26.9163C10.9166 24.9997 9.99992 22.6941 9.99992 19.9997C9.99992 17.2219 10.9721 14.8608 12.9166 12.9163C14.861 10.9719 17.2221 9.99967 19.9999 9.99967C22.6944 9.99967 24.9999 10.9163 26.9166 12.7497C28.8333 14.583 29.861 16.8423 29.9999 19.5275L27.0833 18.6247C26.7592 16.9395 25.9444 15.5436 24.6388 14.4372C23.3333 13.3307 21.787 12.7774 19.9999 12.7774C17.9999 12.7774 16.2962 13.4811 14.8888 14.8885C13.4814 16.296 12.7777 17.9997 12.7777 19.9997C12.7777 21.7682 13.3309 23.3099 14.4374 24.6247C15.5439 25.9395 16.9397 26.759 18.6249 27.083L19.4999 29.9997ZM21.4999 36.583C21.2499 36.62 20.9999 36.6432 20.7499 36.6525C20.4999 36.6617 20.2499 36.6663 19.9999 36.6663C17.6944 36.6663 15.5277 36.2288 13.4999 35.3538C11.4721 34.4788 9.70825 33.2913 8.20825 31.7913C6.70825 30.2913 5.52075 28.5275 4.64575 26.4997C3.77075 24.4719 3.33325 22.3052 3.33325 19.9997C3.33325 17.6941 3.77075 15.5275 4.64575 13.4997C5.52075 11.4719 6.70825 9.70801 8.20825 8.20801C9.70825 6.70801 11.4721 5.52051 13.4999 4.64551C15.5277 3.77051 17.6944 3.33301 19.9999 3.33301C22.3055 3.33301 24.4721 3.77051 26.4999 4.64551C28.5277 5.52051 30.2916 6.70801 31.7916 8.20801C33.2916 9.70801 34.4791 11.4719 35.3541 13.4997C36.2291 15.5275 36.6666 17.6941 36.6666 19.9997C36.6666 20.2497 36.662 20.4997 36.6527 20.7497C36.6434 20.9997 36.6203 21.2497 36.5833 21.4997L33.8888 20.6663V19.9997C33.8888 16.1293 32.5416 12.8469 29.8472 10.1524C27.1527 7.45798 23.8703 6.11076 19.9999 6.11076C16.1296 6.11076 12.8471 7.45798 10.1527 10.1524C7.45822 12.8469 6.111 16.1293 6.111 19.9997C6.111 23.87 7.45822 27.1525 10.1527 29.8469C12.8471 32.5414 16.1296 33.8886 19.9999 33.8886H20.6666L21.4999 36.583ZM34.2083 37.4997L27.0833 30.3747L24.9999 36.6663L19.9999 19.9997L36.6666 24.9997L30.3749 27.083L37.4999 34.208L34.2083 37.4997Z" fill="#90A4AE"/>
</g>
</g>
</svg>
`
export const methodIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="page_info">
<mask id="mask0_1_52905" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52905)">
<path id="page_info_2" d="M29.1667 34.1663C27.5556 34.1663 26.1806 33.5969 25.0417 32.458C23.9028 31.3191 23.3333 29.9441 23.3333 28.333C23.3333 26.7219 23.9028 25.3469 25.0417 24.208C26.1806 23.0691 27.5556 22.4997 29.1667 22.4997C30.7778 22.4997 32.1528 23.0691 33.2917 24.208C34.4306 25.3469 35 26.7219 35 28.333C35 29.9441 34.4306 31.3191 33.2917 32.458C32.1528 33.5969 30.7778 34.1663 29.1667 34.1663ZM5 29.9997V26.6663H20V29.9997H5ZM10.8333 17.4997C9.22222 17.4997 7.84722 16.9302 6.70833 15.7913C5.56944 14.6525 5 13.2775 5 11.6663C5 10.0552 5.56944 8.68023 6.70833 7.54134C7.84722 6.40245 9.22222 5.83301 10.8333 5.83301C12.4444 5.83301 13.8194 6.40245 14.9583 7.54134C16.0972 8.68023 16.6667 10.0552 16.6667 11.6663C16.6667 13.2775 16.0972 14.6525 14.9583 15.7913C13.8194 16.9302 12.4444 17.4997 10.8333 17.4997ZM20 13.333V9.99967H35V13.333H20Z" fill="#90A4AE"/>
</g>
</g>
</svg>
`
export const deliverIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="lab_profile">
<mask id="mask0_1_52910" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
<rect id="Bounding box" width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52910)">
<path id="lab_profile_2" d="M13.3334 19.9997H26.6667V16.6663H13.3334V19.9997ZM13.3334 13.333H26.6667V9.99967H13.3334V13.333ZM33.2501 34.1247L26.5001 25.333C26.0279 24.6941 25.4445 24.2011 24.7501 23.8538C24.0556 23.5066 23.3056 23.333 22.5001 23.333H6.66675V6.66634C6.66675 5.74967 6.99314 4.96495 7.64591 4.31217C8.29869 3.6594 9.08341 3.33301 10.0001 3.33301H30.0001C30.9167 3.33301 31.7015 3.6594 32.3542 4.31217C33.007 4.96495 33.3334 5.74967 33.3334 6.66634V33.333C33.3334 33.4719 33.3265 33.6038 33.3126 33.7288C33.2987 33.8538 33.2779 33.9858 33.2501 34.1247ZM10.0001 36.6663C9.08341 36.6663 8.29869 36.34 7.64591 35.6872C6.99314 35.0344 6.66675 34.2497 6.66675 33.333V26.6663H22.5001C22.7779 26.6663 23.0348 26.7288 23.2709 26.8538C23.507 26.9788 23.7084 27.1525 23.8751 27.3747L30.8751 36.5413C30.7362 36.5969 30.5904 36.6316 30.4376 36.6455C30.2848 36.6594 30.139 36.6663 30.0001 36.6663H10.0001Z" fill="#90A4AE"/>
</g>
</g>
</svg>
`
export const asmtIcon = `<svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_1_52844)">
  <path d="M0 32L-1.39876e-06 0L32.8915 -1.43773e-06C33.7292 -1.47435e-06 34.4782 0.522137 34.768 1.30819L39.7527 14.8292C39.9126 15.263 39.9173 15.7387 39.766 16.1755L34.7517 30.6545C34.4728 31.4599 33.7141 32 32.8618 32L0 32Z" fill="#6464C8"/>
</g>
<g filter="url(#filter1_d_1_52844)">
  <path d="M23.9377 10.5459C23.3976 10.552 22.8812 10.7744 22.4992 11.1654C22.1173 11.5564 21.9 12.085 21.8941 12.638C21.893 12.913 21.9452 13.1855 22.0476 13.4398C22.1499 13.6941 22.3004 13.9251 22.4904 14.1196C22.6804 14.3141 22.906 14.4681 23.1545 14.5729C23.4029 14.6776 23.6691 14.731 23.9377 14.73C24.4779 14.7239 24.9942 14.5015 25.3762 14.1105C25.7582 13.7195 25.9754 13.1909 25.9814 12.638C25.9824 12.3629 25.9303 12.0904 25.8279 11.8362C25.7256 11.5819 25.575 11.3508 25.3851 11.1564C25.1951 10.9619 24.9694 10.8078 24.721 10.7031C24.4726 10.5983 24.2064 10.5449 23.9377 10.5459Z" fill="white"/>
  <path d="M29.9312 12.3641L29.1658 11.5805C28.8585 11.3115 28.5309 11.0679 28.186 10.8519C26.9444 10.0257 25.4954 9.58594 24.0145 9.58594C22.5336 9.58594 21.0846 10.0257 19.843 10.8519C19.4981 11.0679 19.1705 11.3115 18.8632 11.5805C18.589 11.8214 18.3331 12.0833 18.0978 12.3641C18.0311 12.4391 17.9941 12.5369 17.9941 12.6383C17.9941 12.7398 18.0311 12.8375 18.0978 12.9126L18.8632 13.6961C19.1545 13.9648 19.4668 14.2085 19.797 14.4248C19.8092 14.4248 19.8209 14.4297 19.8295 14.4386C19.8381 14.4474 19.843 14.4593 19.843 14.4718C21.081 15.3063 22.5322 15.7479 24.0145 15.7411C25.4957 15.7401 26.9448 15.2992 28.186 14.4718C28.1982 14.4718 28.2099 14.4668 28.2185 14.458C28.2271 14.4492 28.232 14.4373 28.232 14.4248C28.5458 14.1976 28.852 13.9703 29.1658 13.6961C29.44 13.4553 29.6959 13.1933 29.9312 12.9126C29.9761 12.8285 29.9996 12.7342 29.9996 12.6383C29.9996 12.5425 29.9761 12.4482 29.9312 12.3641ZM28.5994 13.0928C28.3315 13.32 28.0253 13.5472 27.7115 13.7745C27.6993 13.7745 27.6876 13.7794 27.679 13.7882C27.6704 13.797 27.6656 13.809 27.6656 13.8215C26.5606 14.5702 25.2631 14.9656 23.938 14.9576C22.614 14.9577 21.3186 14.5629 20.2104 13.8215C20.1982 13.8215 20.1865 13.8165 20.1779 13.8077C20.1693 13.7989 20.1644 13.7869 20.1644 13.7745C19.8484 13.576 19.5512 13.3478 19.2765 13.0928C19.0939 12.9646 18.9292 12.8118 18.7867 12.6383C18.9401 12.476 19.1037 12.3242 19.2765 12.1839C19.5444 11.9566 19.8583 11.7294 20.1644 11.5022C21.263 10.731 22.5638 10.3183 23.8959 10.3183C25.2279 10.3183 26.5287 10.731 27.6273 11.5022C27.9433 11.7007 28.2406 11.9289 28.5152 12.1839C28.697 12.3101 28.8595 12.4632 28.9974 12.6383C28.9015 12.8155 28.7649 12.9661 28.5994 13.0771V13.0928Z" fill="white"/>
  <path d="M19.5453 10.2331C19.7567 10.0777 19.9793 9.93888 20.2112 9.8178C21.3597 9.142 22.66 8.78293 23.9847 8.77569C25.3103 8.77673 26.612 9.13623 27.7582 9.8178L28.4241 10.2331C28.4942 10.2714 28.5688 10.3003 28.6461 10.3193C28.7094 10.3209 28.772 10.305 28.8272 10.2733C28.8825 10.2416 28.9283 10.1952 28.9599 10.1391C28.9903 10.0973 29.0121 10.0497 29.024 9.9991C29.0359 9.94849 29.0375 9.89594 29.0289 9.84464C29.0204 9.79334 29.0017 9.74437 28.974 9.70069C28.9464 9.65702 28.9103 9.61956 28.8681 9.59058C28.6396 9.42511 28.4019 9.27337 28.1562 9.13613C26.8811 8.4045 25.4464 8.01375 23.9847 8C22.5109 7.9998 21.0635 8.39996 19.7902 9.15963C19.5433 9.28011 19.3054 9.41891 19.0784 9.57491C19.0364 9.60313 19.0005 9.63988 18.9729 9.68287C18.9453 9.72586 18.9266 9.77418 18.918 9.82486C18.9094 9.87553 18.9111 9.92746 18.9228 9.97746C18.9346 10.0275 18.9563 10.0745 18.9865 10.1156C19.0094 10.1675 19.044 10.2131 19.0875 10.2487C19.1309 10.2843 19.182 10.3089 19.2366 10.3204C19.2911 10.3318 19.3475 10.3299 19.4012 10.3147C19.4548 10.2996 19.5042 10.2716 19.5453 10.2331Z" fill="white"/>
  <path d="M10.3981 23.9997H12.4877C12.5933 23.9997 12.6945 23.9568 12.7691 23.8804C12.8438 23.804 12.8857 23.7003 12.8857 23.5923V16.8225C12.8867 16.7683 12.8772 16.7145 12.8576 16.6642C12.8381 16.6138 12.8089 16.568 12.7719 16.5293C12.7348 16.4907 12.6906 16.4599 12.6418 16.439C12.593 16.418 12.5406 16.4072 12.4877 16.4072H10.3981C10.3452 16.4072 10.2928 16.418 10.244 16.439C10.1952 16.4599 10.151 16.4907 10.1139 16.5293C10.0769 16.568 10.0477 16.6138 10.0282 16.6642C10.0086 16.7145 9.99906 16.7683 10.0001 16.8225V23.5923C10.0001 23.7003 10.042 23.804 10.1167 23.8804C10.1913 23.9568 10.2925 23.9997 10.3981 23.9997Z" fill="white"/>
  <path d="M13.6677 12.6937V23.5927C13.6677 23.7007 13.7097 23.8044 13.7843 23.8808C13.859 23.9572 13.9602 24.0001 14.0657 24.0001H16.1477C16.2006 24.0012 16.2532 23.9914 16.3024 23.9714C16.3515 23.9514 16.3963 23.9215 16.4341 23.8836C16.4719 23.8456 16.5019 23.8004 16.5224 23.7504C16.5428 23.7005 16.5534 23.6468 16.5534 23.5927V12.6937C16.5534 12.6395 16.5428 12.5858 16.5224 12.5359C16.5019 12.4859 16.4719 12.4407 16.4341 12.4028C16.3963 12.3648 16.3515 12.335 16.3024 12.3149C16.2532 12.2949 16.2006 12.2852 16.1477 12.2862H14.0657C13.9602 12.2862 13.859 12.3291 13.7843 12.4056C13.7097 12.482 13.6677 12.5856 13.6677 12.6937Z" fill="white"/>
  <path d="M17.3494 16.635V23.5928C17.3494 23.7009 17.3913 23.8045 17.4659 23.8809C17.5406 23.9573 17.6418 24.0002 17.7474 24.0002H19.837C19.9425 24.0002 20.0438 23.9573 20.1184 23.8809C20.1931 23.8045 20.235 23.7009 20.235 23.5928V16.635C20.235 16.5269 20.1931 16.4233 20.1184 16.3469C20.0438 16.2705 19.9425 16.2275 19.837 16.2275H17.7474C17.6468 16.2419 17.5535 16.2894 17.4816 16.363C17.4098 16.4365 17.3634 16.532 17.3494 16.635Z" fill="white"/>
  <path d="M21.0845 18.9846V23.5918C21.0845 23.6999 21.1264 23.8035 21.2011 23.8799C21.2757 23.9563 21.3769 23.9992 21.4825 23.9992H23.5721C23.6777 23.9992 23.7789 23.9563 23.8535 23.8799C23.9282 23.8035 23.9701 23.6999 23.9701 23.5918V18.9846C23.9701 18.8765 23.9282 18.7729 23.8535 18.6965C23.7789 18.6201 23.6777 18.5771 23.5721 18.5771H21.4825C21.3819 18.5915 21.2886 18.6391 21.2168 18.7126C21.1449 18.7861 21.0985 18.8816 21.0845 18.9846Z" fill="white"/>
</g>
<defs>
  <filter id="filter0_i_1_52844" x="0" y="0" width="39.8762" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_52844"/>
  </filter>
  <filter id="filter1_d_1_52844" x="6" y="8" width="27.9995" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_52844"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_52844" result="shape"/>
  </filter>
</defs>
</svg>`

export const startegyIcon = `<svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_1_52889)">
  <path d="M0 32L-1.39876e-06 0L32.8915 -1.43773e-06C33.7292 -1.47435e-06 34.4782 0.522137 34.768 1.30819L39.7527 14.8292C39.9126 15.263 39.9173 15.7387 39.766 16.1755L34.7517 30.6545C34.4728 31.4599 33.7141 32 32.8618 32L0 32Z" fill="#6464C8"/>
</g>
<path d="M23.9714 17.5134C24.3428 17.5134 24.6571 17.3437 24.8285 17.0043C25.8 15.3073 27 13.0728 27 11.9981C27 10.3576 25.6285 9 23.9714 9C22.3143 9 20.9429 10.3576 20.9429 11.9981C20.9429 13.0728 22.1143 15.279 23.1143 17.0043C23.2857 17.3154 23.6286 17.5134 23.9714 17.5134ZM23.9714 9.79194C25.2285 9.79194 26.2571 10.8102 26.2571 12.0546C26.2571 12.7334 25.4857 14.4587 24.2 16.6649C24.1428 16.7497 24.0286 16.778 24 16.778C23.9714 16.778 23.8571 16.7497 23.8 16.6649C22.5143 14.4587 21.7429 12.7052 21.7429 12.0546C21.7143 10.7819 22.7143 9.79194 23.9714 9.79194Z" fill="white"/>
<path d="M25.4001 12.0265C25.4001 11.2346 24.7429 10.6406 24.0001 10.6406C23.2001 10.6406 22.6001 11.2911 22.6001 12.0265C22.6001 12.7902 23.2572 13.4124 24.0001 13.4124C24.7429 13.4124 25.4001 12.8185 25.4001 12.0265ZM23.3144 12.0265C23.3144 11.6588 23.6287 11.376 23.9715 11.376C24.3144 11.376 24.6286 11.6871 24.6286 12.0265C24.6286 12.3942 24.3144 12.677 23.9715 12.677C23.6001 12.677 23.3144 12.3942 23.3144 12.0265Z" fill="white"/>
<path d="M13.0284 16.0713C12.2284 16.0713 11.6284 16.7218 11.6284 17.4572C11.6284 18.2208 12.2856 18.8431 13.0284 18.8431C13.7713 18.8431 14.4284 18.1926 14.4284 17.4572C14.4284 16.7218 13.7998 16.0713 13.0284 16.0713ZM13.0284 18.136C12.657 18.136 12.3713 17.8249 12.3713 17.4855C12.3713 17.1178 12.6855 16.8349 13.0284 16.8349C13.3998 16.8349 13.6855 17.1461 13.6855 17.4855C13.6855 17.8249 13.3998 18.136 13.0284 18.136Z" fill="white"/>
<path d="M13.0285 14.4873C11.3714 14.4873 10 15.8449 10 17.4854C10 18.5601 11.1714 20.7663 12.1714 22.4916C12.3428 22.8027 12.6857 23.0007 13.0285 23.0007C13.4 23.0007 13.7142 22.831 13.8857 22.4916C14.8571 20.7946 16.0571 18.5601 16.0571 17.4854C16.0571 15.8166 14.7142 14.4873 13.0285 14.4873ZM13.2285 22.0956C13.1714 22.2087 13.0571 22.2087 13.0285 22.2087C13 22.2087 12.8857 22.1805 12.8285 22.0956C11.5143 19.8895 10.7428 18.1642 10.7428 17.4854C10.7428 16.2409 11.7714 15.2227 13.0285 15.2227C14.2857 15.2227 15.3142 16.2409 15.3142 17.4854C15.2857 18.1642 14.5142 19.8895 13.2285 22.0956Z" fill="white"/>
<path d="M23.9141 18.5027H19.3427C18.657 18.5027 18.1142 17.9653 18.1142 17.2865C18.1142 16.6077 18.657 16.0703 19.3427 16.0703H20.6284C20.857 16.0703 20.9998 15.9006 20.9998 15.7026C20.9998 15.5047 20.8284 15.335 20.6284 15.335H19.3427C18.2284 15.335 17.3427 16.2118 17.3427 17.3148C17.3427 18.4179 18.2284 19.2947 19.3427 19.2947H23.8855C24.5712 19.2947 25.1141 19.8321 25.1141 20.5109C25.1141 21.1897 24.5712 21.7271 23.8855 21.7271H15.7713C15.5428 21.7271 15.3999 21.8968 15.3999 22.0948C15.3999 22.321 15.5713 22.4624 15.7713 22.4624H23.9427C25.057 22.4624 25.9427 21.5856 25.9427 20.4826C25.9141 19.3795 24.9998 18.5027 23.9141 18.5027Z" fill="white"/>
<defs>
  <filter id="filter0_i_1_52889" x="0" y="0" width="39.8762" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_52889"/>
  </filter>
</defs>
</svg>`

export const plantDataInputIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_52342" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
  <rect width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52342)">
  <path d="M23.335 36.668V33.1263L31.9183 24.5013L35.5016 28.0013L26.8766 36.668H23.335ZM36.71 26.8346L33.1683 23.293L34.335 22.1263C34.6683 21.793 35.0711 21.6263 35.5433 21.6263C36.0155 21.6263 36.4044 21.793 36.71 22.1263L37.8766 23.3346C38.1822 23.668 38.335 24.0638 38.335 24.5221C38.335 24.9805 38.1822 25.3624 37.8766 25.668L36.71 26.8346ZM6.66829 33.3346C5.75163 33.3346 4.96691 33.0082 4.31413 32.3555C3.66135 31.7027 3.33496 30.918 3.33496 30.0013V10.0013C3.33496 9.08464 3.66135 8.29991 4.31413 7.64714C4.96691 6.99436 5.75163 6.66797 6.66829 6.66797H16.6683L20.0016 10.0013H33.335C34.2516 10.0013 35.0364 10.3277 35.6891 10.9805C36.3419 11.6332 36.6683 12.418 36.6683 13.3346V18.2096C35.8072 18.2096 34.96 18.3138 34.1266 18.5221C33.2933 18.7305 32.5711 19.1402 31.96 19.7513L18.46 33.3346H6.66829Z" fill="#90A4AE"/>
</g>
</svg>`

export const dataAggregationIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_52347" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
  <rect width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52347)">
  <path d="M25.0016 35V30H18.335V13.3333H15.0016V18.3333H3.33496V5H15.0016V10H25.0016V5H36.6683V18.3333H25.0016V13.3333H21.6683V26.6667H25.0016V21.6667H36.6683V35H25.0016Z" fill="#90A4AE"/>
</g>
</svg>`

export const dataCalibrationIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_52352" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
  <rect width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52352)">
  <path d="M5 35V26.6667H8.33333V31.6667H13.3333V35H5ZM26.6667 35V31.6667H31.6667V26.6667H35V35H26.6667ZM20 31.6667C16.7778 31.6667 14.0278 30.5278 11.75 28.25C9.47222 25.9722 8.33333 23.2222 8.33333 20C8.33333 16.7778 9.47222 14.0278 11.75 11.75C14.0278 9.47222 16.7778 8.33333 20 8.33333C23.2222 8.33333 25.9722 9.47222 28.25 11.75C30.5278 14.0278 31.6667 16.7778 31.6667 20C31.6667 23.2222 30.5278 25.9722 28.25 28.25C25.9722 30.5278 23.2222 31.6667 20 31.6667ZM5 13.3333V5H13.3333V8.33333H8.33333V13.3333H5ZM31.6667 13.3333V8.33333H26.6667V5H35V13.3333H31.6667Z" fill="#90A4AE"/>
</g>
</svg>`

export const lossCalIcon = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_52357" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
  <rect width="40" height="40" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_52357)">
  <path d="M12.3334 36.6667L10 34.3333L21.5 22.7917L27.3334 28.625L35.9584 20L38.3334 22.375L27.3334 33.3333L21.5 27.5L12.3334 36.6667ZM6.66671 35C5.75004 35 4.96532 34.6736 4.31254 34.0208C3.65976 33.3681 3.33337 32.5833 3.33337 31.6667V8.33333C3.33337 7.41667 3.65976 6.63194 4.31254 5.97917C4.96532 5.32639 5.75004 5 6.66671 5H30C30.9167 5 31.7014 5.32639 32.3542 5.97917C33.007 6.63194 33.3334 7.41667 33.3334 8.33333V15.3333H6.66671V35Z" fill="#90A4AE"/>
</g>
</svg>`

export const lossIcon = `<svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_1_52331)">
  <path d="M0 32L-1.39876e-06 0L32.8915 -1.43773e-06C33.7292 -1.47435e-06 34.4782 0.522137 34.768 1.30819L39.7527 14.8292C39.9126 15.263 39.9173 15.7387 39.766 16.1755L34.7517 30.6545C34.4728 31.4599 33.7141 32 32.8618 32L0 32Z" fill="#6464C8"/>
</g>
<g filter="url(#filter1_d_1_52331)">
  <path d="M15.7961 18.8002H17.3052C17.4562 18.8002 17.5819 18.6712 17.5819 18.5164V13.5631C17.5819 13.4083 17.4562 13.2793 17.3052 13.2793H15.7961C15.6452 13.2793 15.5194 13.4083 15.5194 13.5631V18.5164C15.5194 18.6712 15.6452 18.8002 15.7961 18.8002Z" fill="white"/>
  <path d="M18.1621 12.273V18.5163C18.1621 18.6711 18.2879 18.8001 18.4388 18.8001H19.9479C20.0989 18.8001 20.2246 18.6711 20.2246 18.5163V12.273C20.2246 12.1183 20.0989 11.9893 19.9479 11.9893H18.4388C18.2879 11.9893 18.1621 12.1183 18.1621 12.273Z" fill="white"/>
  <path d="M20.627 13.4352V18.5175C20.627 18.6723 20.7527 18.8012 20.9036 18.8012H22.4128C22.5637 18.8012 22.6895 18.6723 22.6895 18.5175V13.4352C22.6895 13.2804 22.5637 13.1514 22.4128 13.1514H20.9036C20.7527 13.1514 20.627 13.2804 20.627 13.4352Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1996 22.2934C22.8223 22.2934 25.7591 19.2425 25.7591 15.4791C25.7591 11.7157 22.8223 8.66481 19.1996 8.66481C15.5768 8.66481 12.64 11.7157 12.64 15.4791C12.64 19.2425 15.5768 22.2934 19.1996 22.2934ZM19.1996 22.9582C23.1758 22.9582 26.3991 19.6097 26.3991 15.4791C26.3991 11.3485 23.1758 8 19.1996 8C15.2234 8 12 11.3485 12 15.4791C12 19.6097 15.2234 22.9582 19.1996 22.9582Z" fill="white"/>
  <rect width="0.677962" height="4.34201" rx="0.338981" transform="matrix(0.693515 -0.720442 0.693515 0.720442 23.5195 20.8721)" fill="white"/>
</g>
<defs>
  <filter id="filter0_i_1_52331" x="0" y="0" width="39.8762" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_52331"/>
  </filter>
  <filter id="filter1_d_1_52331" x="8" y="8" width="22.8633" height="23.8574" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_52331"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_52331" result="shape"/>
  </filter>
</defs>
</svg>`