import React, { useEffect, useState } from 'react';
import { Redirect,useParams } from 'react-router-dom';
import serviceProvider from '../../services/axios';
import {DimensionAndSolutionsMappingView } from './DimensionAndSolutionsMappingView';
export const DimensionAndSolutionsMappingCtrl = (props) => {

    const [discard,setDiscard] = useState(true);
    const handleDiscard = ()=>{
        setDiscard(prv=>false);
       setTimeout(()=>{
        setDiscard(prv=>true);
       },100);
    }
    const user = JSON.parse(localStorage.getItem("userData"));
    const login = localStorage.getItem("isLoggedIn");
    let { slug } = useParams();
    const [results, setResults] = useState(false);
    const uid = user ? user.uid : 0;

    useEffect(() => {
        const search = async () => {
            const apiUrl = `/Roadmap?param=globalfutureband&uid=${uid}&slug=${slug}`;
            const { data } = await serviceProvider.get(apiUrl, true, {});
            setResults(data);
        };
        search();
    }, []);


    if(results) {
    return (
        <>
            {discard ?
                <DimensionAndSolutionsMappingView uid={uid} dataResults={results} handleDiscard={handleDiscard} {...props}/>
                :
                <></>
            }
        </>
    );
    }else{

        return (
            <>
            <div></div>
            </>
        );
    }
}

