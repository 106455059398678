/** imported components
 
  * serviceProvider for ===> working with api 
  * AllSectionsView for ===> to show view part of all-section page
  * BreadcrumbsItem     ===> React component for breadcrumbs
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  
**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { AllSectionsView } from "./all-sections-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

export const AllSectionsController = (props) => {

	// Here we are fetching the userdata and after that we are storing the user id.

	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		serviceProvider.post("/home?type=setfirstassessmentseen", JSON.stringify({ uid: usid }),true).then((response) => {
			// console.log(response);
		});
	}, []);
	return (
		<>
			{/* Breadcrumbs that we are using for the page */}
			
			<BreadcrumbsItem to="/#my-palnts">My Plants</BreadcrumbsItem>
			<BreadcrumbsItem to="/#your-palnts">Your Plant Name</BreadcrumbsItem>
			<BreadcrumbsItem to="/#all-sections">All Sections</BreadcrumbsItem>
			<AllSectionsView {...props} />
		</>
	);
};
