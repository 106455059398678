/** imported components
 
  * Plyr   ===> third party plugin
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Modal ===> component from material ui for popups.
  * Link for ===> component from react to uise as anchor tag
  * .scss ===> styling css file for this page.

**/

import React, { Component, useState, useEffect, useRef } from "react";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import { Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import { apiEndpoints } from '../../services/api-endpoints'
//Industry40View component start
export const Industry40View = (props) => {
	// defining variables here
	const [isCirTooltip, setIsCirTooltip] = useState(true);
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [istourPopup, setIstourPopup] = useState(false);
	const reportImpLoader = React.useRef();
	const [url, setUrl] = useState("");
	const homeDataItems = props.homeData;
	if (!homeDataItems.length) {
		return null;
	}
	const homeDataItem = props.homeData[0];
	const courses = homeDataItem.courses;
	//Function to handle tour popup
	const HandleTourPopup = (vurl) => {
		console.log(vurl);
		setUrl(vurl);
		setIstourPopup(true);
	};
	//Function to close tour popup
	const CloseTourPopup = () => {
		setUrl("");
		setIstourPopup(false);
	};
	//Function to handle current content
	const HandleCurrentContent = (index) => {
		setActiveTabIndex(index);
	};
	//Function to handle circle tooltip
	const HandleCirTooltip = () => {
		setIsCirTooltip(false);
	};
	console.log(istourPopup);
	return (
		// Industry main wrapper start
		<div className="industry40-main-wrapper">
			{/* Main wrapper has been divided into two parts
				industry-frame1
				industry-frame2 
			 */}
			<div className="industry-frame-wrapper">
				{/* This left section contains
					heading,sub-heading
					cicle section
				*/}
				<div className="industry-frame1">
					<h2 className="h1">Industry 4.0 Dimensions</h2>
					<p className="ind-p">
						The 10 Industry 4.0 Dimensions is the central piece of SmarterChains Framework developed to rank plant’s capabilities.
					</p>
					{/* this section contains list in a circle */}
					<div className="industry-frame1-cir-div">
						<ul className="cir-btn-wrapper-ul">
							<li className="cir-btn-wrapper-li w-100">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(1);
									}}
								>
									<p>Factory of the Future Leadership</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(6);
									}}
								>
									<p>Digital-Ready Infrastructure</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(7);
									}}
								>
									<p>360° Factory Visibility</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li w-100">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(8);
									}}
								>
									<p>Intelligent Quality</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(5);
									}}
								>
									<p>Automated Material Flow</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(3);
									}}
								>
									<p>Data-Driven Maintenance</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li w-100">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(10);
									}}
								>
									<p>Zero Touches Production</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(4);
									}}
								>
									<p>Sustainability</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(9);
									}}
								>
									<p>Tech-Augmented Workforce</p>
								</button>
							</li>
							<li className="cir-btn-wrapper-li w-100">
								<button
									className="btn"
									onClick={() => {
										HandleCirTooltip();
										HandleCurrentContent(2);
									}}
								>
									<p>Digital Organization</p>
								</button>
							</li>
						</ul>
						<div className={"cir-hover-tooltip" + `${isCirTooltip ? " active" : ""}`}>
							<p>Click on each Dimension to learn more</p>
						</div>
					</div>
				</div>
				{/* This right section contains cards */}
				<div className="industry-frame2">
					<div className={activeTabIndex === 0 ? "industry-frame-sec active" : "industry-frame-sec"}>
						{/* Card has been divided into two sections
							header ==> header has image and text
							section has subtext 
						*/}
						<div className="industry-frame2-card">
							<header>
								<div className="img-wrap">
									<img src={require("../../assets/img/industry-40/people-enablers.svg").default} className="" alt="" />
								</div>
								<div className="card-head-text">
									<p className="txt-dull">People enablers</p>
									<h3 className="h1">Leadership and Organization</h3>
								</div>
							</header>
							<section>
								<p>This is what makes the different parts in the Framework work in harmony.</p>
							</section>
						</div>
						{/*card end*/}
						<div className="industry-frame2-card">
							<header>
								<div className="img-wrap">
									<img src={require("../../assets/img/industry-40/tech-enablers.svg").default} className="" alt="" />
								</div>
								<div className="card-head-text">
									<p className="txt-dull">tech enablers</p>
									<h3 className="h1">Technology and Infrastructure</h3>
								</div>
							</header>
							<section>
								<p>The specific technology solutions that ensure the functionality of other functions.</p>
							</section>
						</div>
						{/*card end*/}
						<div className="industry-frame2-card">
							<header>
								<div className="img-wrap">
									<img src={require("../../assets/img/industry-40/processes-enablers.svg").default} className="" alt="" />
								</div>
								<div className="card-head-text">
									<p className="txt-dull">Process Enablers</p>
									<h3 className="h1">Core Functions of a plant</h3>
								</div>
							</header>
							<section>
								<p>Includes the processes that drive the conversions of raw materials into finished products.</p>
							</section>
						</div>
						{/*card end*/}
					</div>
					{courses.map((course) => {
						return (
							<div
								key={course.id}
								id={`dimension-sec-${course.id}`}
								className={
									activeTabIndex == course.id
										? "dimension-btn-clicked-content industry-frame-sec active"
										: "dimension-btn-clicked-content industry-frame-sec"
								}

							>
								{/* this section contains card */}
								<div className="over-flow-auto-wrapper">
									{/* Card has been divided into two sections
										header ==> header has image and text
										section has two subsection ===> text & btn-wrapper
									*/}
									<div className="industry-frame2-card">
										<header>
											<button className="dimention-close-btn" onClick={() => setActiveTabIndex(0)}>
												<i dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
											</button>
											<div className="img-wrap">
												<img alt="true"
													src=
													{(course.id == 1 || course.id == 2) ? require(`../../assets/img/industry-40/people-enablers.svg`).default
														: (course.id == 3 || course.id == 5 || course.id == 8 || course.id == 10) ? require(`../../assets/img/industry-40/processes-enablers.svg`).default
															: (course.id == 4 || course.id == 7 || course.id == 9 || course.id == 6) ? require(`../../assets/img/industry-40/tech-enablers.svg`).default
																: ""}
												/>
											</div>
											<div className="card-head-text">
												<p className="txt-dull">
													{(course.id == 1 || course.id == 2) ? "PEOPLE ENABLERS​" : ""}
													{(course.id == 3 || course.id == 5 || course.id == 8 || course.id == 10) ? "PROCESS ENABLERS​​" : ""}
													{(course.id == 4 || course.id == 7 || course.id == 9 || course.id == 6) ? "TECH ENABLERS​" : ""}
												</p>
												<h3 className="h1">{course.course_name}</h3>
											</div>
										</header>
										<section>
											<div dangerouslySetInnerHTML={{ __html: course.course_description }}></div>
											<div className="btn-wrapper">
												<Link to={`/training-courses/${course.course_slug}`} className="btn-learn-more">
													Learn more
												</Link>
												<button
													className="btn-watch-course"
													onClick={() =>
														HandleTourPopup(`${apiEndpoints.baseUrl}/assets/videos/${course.firstVideo}`)
													}
												>
													+ Watch Intro Course
												</button>
											</div>
										</section>
									</div>
								</div>
								{/* In this section two subsections
									img-div ==> contains image
									h1==> contains heading
								*/}
								<div className="blue-box">
									<div className="img-div">
										<img src={require("../../assets/img/industry-40/queston-mark-sc.svg").default} alt="true" />
									</div>
									<h2 className="h1">Which is the Dimension you want to learn more about?</h2>
									<p>
										<span className="b">
											<Link to="/training-courses">Find your next training</Link>
										</span>
										<span> and enable new knowledge discovery around Industry 4.0.</span>
									</p>
								</div>
							</div>
						);
					})}
				</div>
				{/* Tour popup */}
				<Modal
					open={istourPopup}
					onClose={CloseTourPopup}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					className="quick-start-vid-popup"
				>
					<div className="vid__wrapper">
						<Plyr
							source={{
								type: "video",
								sources: [
									{
										src: url,
										type: "video/mp4",
									},
								],
							}}
							options={{
								controlshidden: true,
								autoplay: true,
							}}
						/>
					</div>
				</Modal>
			</div>
		</div>
	);
};

const closeIcon = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.1 13.3C1.7134 13.6866 1.0866 13.6866 0.7 13.3V13.3C0.313401 12.9134 0.313401 12.2866 0.7 11.9L4.89289 7.70711C5.28342 7.31658 5.28342 6.68342 4.89289 6.29289L0.7 2.1C0.3134 1.7134 0.313401 1.0866 0.7 0.7V0.7C1.0866 0.313401 1.7134 0.313401 2.1 0.7L6.29289 4.89289C6.68342 5.28342 7.31658 5.28342 7.70711 4.89289L11.9 0.7C12.2866 0.3134 12.9134 0.313401 13.3 0.7V0.7C13.6866 1.0866 13.6866 1.7134 13.3 2.1L9.10711 6.29289C8.71658 6.68342 8.71658 7.31658 9.10711 7.70711L13.3 11.9C13.6866 12.2866 13.6866 12.9134 13.3 13.3V13.3C12.9134 13.6866 12.2866 13.6866 11.9 13.3L7.70711 9.10711C7.31658 8.71658 6.68342 8.71658 6.29289 9.10711L2.1 13.3Z" fill="#118ACB"/>
</svg>
`