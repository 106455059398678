import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import "plyr-react/dist/plyr.css";
import { Collapse, Modal } from "@material-ui/core";
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext } from "pure-react-carousel";
import { Link } from "react-router-dom";
import { QuickBetaPopupController } from '../quick-beta-popup/quick-beta-popup-ctrl.js'
import "pure-react-carousel/dist/react-carousel.es.css";
import "./home.scss";
export const HomeView = (props) => {

	const [tabIndex, setTabIndex] = useState(0);
	const [isSliderPopup, setIsSliderPopup] = useState(false);
	const [isSliderIndex, setIsSliderIndex] = useState(0);
	const [isAllCourse, setIsAllCourse] = useState(false);
	const [ishoverVal, setIshoverBtn] = useState(true);
	const [isInvitePopup, setIsInvitePopup] = useState(false);
	const [singleEmail, setSingleEmail] = useState("");
	const [multiEmailVal, setMultiEmailVal] = useState("");
	const [isSendEmailClick, setIsSendEmailClick] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [isQuickBetaPopup, setIsQuickBetaPopup] = useState(false);
	

	const homeDataItems = props.homeData;
	if (!homeDataItems.length) {
		return null;
	}
	const homeDataItem = props.homeData[0];
	const courses = homeDataItem.courses;
	const paidcourses = homeDataItem.paidcourses;
	const uid = homeDataItem.uid;
	
	let broughttrainging = 0;
	let avaltrainging = 0;
	courses.map((items, index) => {
		if (items.has_paid == 0) {
			avaltrainging = avaltrainging + 1;
		}
		if (items.has_paid == 1) {
			broughttrainging = broughttrainging + 1;
		}
	});

	const fourcourses = paidcourses.map((course, index) => {
		if (index < 3) {
			return (
				<div key={course.id} className="training-card-col">
					<Link
						to={
							homeDataItem.is_first_training == 1
								? "/course-walkthrough"
								: course.cardStatus.toLowerCase() === "quiz pending"
									? homeDataItem.is_first_quiz == 1
										? "/quiz-walkthrough"
										: `/training-courses/${course.course_slug}`
									: `/training-courses/${course.course_slug}`
						}
					>
						<div className="master_wrapper">
							<div className="training_card_wrapp">
								<div className="trn_img_wrapper">
									<img
										src={require(`../../assets/img/training-card/${course.course_icon}`).default}
										className="course_symb"
										alt={course.course_name}
									/>
									{course.cardStatus && (
										<span
											className={
												course.cardStatus.toLowerCase() === "completed"
													? "ed_status ed_completed"
													: course.cardStatus.toLowerCase() === "quiz pending"
														? "ed_status ed_quiz"
														: course.cardStatus.toLowerCase() === "in progress" && "ed_status ed_inprogress"
											}
										>
											{course.cardStatus}
										</span>
									)}
								</div>
								<p>
									{course.course_name}

								</p>
							</div>
						</div>
					</Link>
				</div>
			);
		}
	});
	const allcourses = paidcourses.map((course) => {
		return (
			<div key={course.id} className="training-card-col">
				<Link
					to={
						homeDataItem.is_first_training == 1
							? "/course-walkthrough"
							: course.cardStatus.toLowerCase() === "quiz pending"
								? homeDataItem.is_first_quiz == 1
									? "/quiz-walkthrough"
									: `/training-courses/${course.course_slug}`
								: `/training-courses/${course.course_slug}`
					}
				>
					<div className="master_wrapper">
						<div className="training_card_wrapp">
							<div className="trn_img_wrapper">
								<img
									src={require(`../../assets/img/training-card/${course.course_icon}`).default}
									className="course_symb"
									alt={course.course_name}
								/>
								{course.cardStatus && (
									<span
										className={
											course.cardStatus.toLowerCase() === "completed"
												? "ed_status ed_completed"
												: course.cardStatus.toLowerCase() === "quiz pending"
													? "ed_status ed_quiz"
													: course.cardStatus.toLowerCase() === "in progress" && "ed_status ed_inprogress"
										}
									>
										{course.cardStatus}
									</span>
								)}
							</div>
							<p>
								{course.course_name}

							</p>
						</div>
					</div>
				</Link>
			</div>
		);
	});
	const allcoursesliting = paidcourses.map((course) => {
		return (
			<div key={course.id} className="indx-home-list-li">
				<span className="right-angle">
					<img src={require("../../assets/img/home-index/green-right-arrow.svg").default} alt="true" />
				</span>
				<span className="text green">
					{course.cardStatus.toLowerCase() === "completed"
						? "Go to your"
						: course.cardStatus.toLowerCase() === "quiz pending"
							? "Continue your"
							: course.cardStatus.toLowerCase() === "in progress"
								? "Continue your"
								: "Start your"}
					<Link
						to={
							course.cardStatus.toLowerCase() === "quiz pending"
								? `/training-courses/${course.course_slug}`
								: `/training-courses/${course.course_slug}`
						}
						className="link"
					>
						{course.course_name}
					</Link>
					{course.cardStatus.toLowerCase() === "quiz pending" ? "training quiz" : "training course"}
				</span>
				<span
					className={
						course.cardStatus.toLowerCase() === "completed"
							? "work-status green"
							: course.cardStatus.toLowerCase() === "quiz pending"
								? "work-status orange"
								: course.cardStatus.toLowerCase() === "in progress"
									? "work-status orange"
									: "work-status green"
					}
				>
					{course.cardStatus.toLowerCase() === "completed"
						? "Completed"
						: course.cardStatus.toLowerCase() === "quiz pending"
							? "In Progress"
							: course.cardStatus.toLowerCase() === "in progress"
								? "In Progress"
								: "To Do"}
				</span>
			</div>
		);
	});



	const HandleAllCourseView = () => {
		setIsAllCourse(!isAllCourse);
	};
	const HandleSliderPopup = () => {
		setIsSliderPopup(!isSliderPopup);
		setIsSliderIndex(0);
	};
	const handleIndex = (e) => {
		if (e === "add" && isSliderIndex < 4) {
			let tmp = 1 + isSliderIndex;
			setIsSliderIndex(tmp);
		}
		if (e === "subs" && isSliderIndex > 0) {
			let tmp = isSliderIndex - 1;
			setIsSliderIndex(tmp);
		}
	};
	const HandleViewTab = (index) => {
		setTabIndex(index);
	};

	// invitepopup----


	const HandleInvitePopup = () => {
		setEmailError(false);
		setIsInvitePopup(!isInvitePopup);
	};
	const HandleMultiEmailValue = (e) => {
		setMultiEmailVal(e);
		if (e.length > 0) {
			setIshoverBtn(false);
		} else {
			setIshoverBtn(true);
		}
		setIsSendEmailClick(true);
	};

	const disableFunc = () => {
		const newmultiEmailVal = [...multiEmailVal];
		const emailRegx =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (singleEmail.length !== 0 && emailRegx.test(String(singleEmail).toLowerCase())) {
			setIshoverBtn(false);

			const addEmail = singleEmail;
			newmultiEmailVal.push(addEmail);
			setMultiEmailVal(newmultiEmailVal);
			setSingleEmail("");
			// console.log(2);	
		}
		else if (multiEmailVal.length > 0 && singleEmail.length !== 0 && emailRegx.test(String(singleEmail).toLowerCase())) {
			setIshoverBtn(false);
			const addEmail = singleEmail;
			newmultiEmailVal.push(addEmail);
			setMultiEmailVal(newmultiEmailVal);
			// setSingleEmail("");
			// setSingleEmail("");
			// console.log(5);
		}
		else {
			if (multiEmailVal.length > 0) {
				setIshoverBtn(false);
				// console.log(3);
			} else {
				setIshoverBtn(true);
				// console.log(4);
			}
		}

	};

	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const multiEmailValSend = (email) => {
		serviceProvider.post("/user?type=inviteuser", { email: email, uid: usid }, true).then((response) => {
			console.log(response.data);
			setEmailError(response.data.validation_error);
			setMultiEmailVal("");
			setSingleEmail("");
			// setTimeout(() => {
			// 	setEmailError(false);
			// }, 5000);
		});
	};
	// invitepopup------

	return (
		<>
			<div className="home-index main-course-page">
				<div className="view_btn">
					<button className={tabIndex === 0 ? "view_btn_wrap active" : "view_btn_wrap"} onClick={() => HandleViewTab(0)}>
						Grid View
						<span className="view_icons">
							<img src={require("../../assets/img/home-index/grid_icon.svg").default} className="imd_grid_icon" alt="true" />
						</span>
					</button>
					<button className={tabIndex === 1 ? "view_btn_wrap active" : "view_btn_wrap"} onClick={() => HandleViewTab(1)}>
						List View
						<span className="view_icons">
							<img src={require("../../assets/img/home-index/list_icon.svg").default} className="imd_grid_icon" alt="true" />
						</span>
					</button>
				</div>
				{tabIndex === 0 ? (
					<>
						{/*grid view*/}
						<section className="main-training-sec">
							<h2 className="h2">
								<img
									src={require("../../assets/img/home-index/my-training-icon.svg").default}
									className="imd_partnership_logo"
									alt="true"
								/>
								My Trainings
							</h2>
							<div className="train-card-row-continer">
								<div className="training-card-row">
									{broughttrainging == 1 ? (
										<>
											{/* OFFER - POPUP TRIGGER CARD */}
												<div class="training-card-col">
													<Link onClick={() => setIsQuickBetaPopup(true)}>
														<div class="master_wrapper">
															<div class="training_card_wrapp">
																<div class="trn_img_wrapper offer_img_wrap">
																	<img
																		src={require(`../../assets/img/training-card/offer-img.svg`).default}
																		className="course_symb"
																		alt="Offer Popup"
																	/>
																</div>
																<p>
																	Save up to 500€
																	<span className="strt_course_lnk" style={{visibility:'hidden'}}>+ Start Inviting Now</span>
																</p>
															</div>
														</div>
													</Link>
												</div>
											{/* OFFER - POPUP TRIGGER CARD */}
											{allcourses}
											<div className="training-card-col">
												<Link to="/training-courses">
													<div className="master_wrapper">
														<div className="training_card_wrapp">
															<div className="trn_img_wrapper">
																<img
																	src={require("../../assets/img/home-index/course_sections-icon-1.svg").default}
																	className="course_symb"
																	alt="true"
																/>
															</div>
															<p>
																Available training courses
															</p>
														</div>
													</div>
												</Link>
											</div>
										</>
									) : (
										<>
											{!isAllCourse ? (
												<div className="train-card-row-continer">
													<div className="training-card-row">
													{/* OFFER - POPUP TRIGGER CARD */}
														
													{/* OFFER - POPUP TRIGGER CARD */}
														{fourcourses}
														{broughttrainging > 3 && (
															<div className="btn-show-all-course-wrapper" onClick={HandleAllCourseView}>
																<img
																	src={require("../../assets/img/home-index/btn-view-all-course.svg").default}
																	alt="view-img"
																/>
																<p>+ View all courses</p>
															</div>
														)}
													</div>
												</div>
											) : (
												<div className="train-card-row-continer">
													<div className="training-card-row">
													{/* OFFER - POPUP TRIGGER CARD */}
														<div class="training-card-col">
															<Link onClick={() => setIsQuickBetaPopup(true)}>
																<div class="master_wrapper">
																	<div class="training_card_wrapp">
																		<div class="trn_img_wrapper offer_img_wrap">
																			<img
																				src={require(`../../assets/img/training-card/offer-img.svg`).default}
																				className="course_symb"
																				alt="Offer Popup"
																			/>
																		</div>
																		<p>
																			Save up to 500€
																			<span className="strt_course_lnk" style={{visibility:'hidden'}}>+ Start Inviting Now</span>
																		</p>
																	</div>
																</div>
															</Link>
														</div>
													{/* OFFER - POPUP TRIGGER CARD */}
													{allcourses}
													</div>
												</div>
											)}
										</>
									)}
									{homeDataItem.training_tour == 0 && broughttrainging < 2 && (
										<div className="training-card-col">
											<Link to="/training-walkthrough">
												<div className="master_wrapper">
													<div className="training_card_wrapp">
														<div className="trn_img_wrapper">
															<img
																src={require("../../assets/img/home-index/course_sections-icon-2.svg").default}
																className="course_symb"
																alt="true"
															/>
														</div>
														<p>
															Training Courses

														</p>
													</div>
												</div>
											</Link>
										</div>
									)}

								</div>
							</div>
						</section>
						<section className="my-assessment main-training-sec">
							<h2 className="h2">
								<img
									src={require("../../assets/img/home-index/my-assessment-icon.svg").default}
									className="imd_partnership_logo"
									alt="true"
								/>
								My Assessment
							</h2>
							<div className="train-card-row-continer">
								<div className="training-card-row">
									{homeDataItem.invited_by != 0 && (
										<div class="training-card-col">
											<Link to={homeDataItem.is_first_result == 0 ? "/view-result-walkthrough" : "/view-result"}>
												<div class="master_wrapper">
													<div class="training_card_wrapp">
														<div class="trn_img_wrapper">
															<img
																src={require(`../../assets/img/training-card/fof-leadership.svg`).default}
																className="course_symb"
																alt="Factory of the Future Leadership"
															/>
														</div>
														<p>
															{homeDataItem.invshortname}’s Factory of the Future Leadership

														</p>
													</div>
												</div>
											</Link>
										</div>
									)}
									{homeDataItem.hasresult == 0 &&
										(broughttrainging == 1 ? (
											homeDataItem.invited_by == 0 ? (
												<div className="training-card-col cursor-pointer large" onClick={HandleSliderPopup}>
													<div className="master_wrapper l-card">
														<div className="l-cont">
															<p>
																Claim your Free limited Digital Maturity Assessment
																<span> for the Factory of the Future Leadership</span>
															</p>
														</div>
														<div className="r-img">
															<img
																src={require("../../assets/img/home-index/claim.svg").default}
																className="claim_symb"
																alt="true"
															/>
														</div>
													</div>
												</div>
											) : (
												<div class="training-card-col training-card-small" onClick={HandleSliderPopup}>
													<div class="master_wrapper">
														<div class="training_card_wrapp">
															<div class="trn_img_wrapper">
																<img
																	src={require("../../assets/img/home-index/inv-claim.svg").default}
																	className="claim_symb"
																	alt="true"
																/>
															</div>
															<p>
																Claim your Free limited Digital Maturity Assessment
																<span> for the Factory of the Future Leadership</span>
															</p>
														</div>
													</div>
												</div>
											)
										) : (
											<div className="training-card-col">
												<Link to={homeDataItem.is_first_assessment == 1 ? "/assessment-walkthrough" : "/assessment"}>
													<div className="master_wrapper">
														<div className="training_card_wrapp">
															<img
																src={require("../../assets/img/home-index/vision_icon.svg").default}
																className="course_symb"
																alt="true"
															/>
															{/* <div className="trn_img_wrapper">
														<img
															src={require("../../assets/img/home-index/opex_icon.svg").default}
															className="course_symb"
															alt="true"
														/>
													</div> */}
															<p>
																Industry 4.0 Vision

															</p>
														</div>
													</div>
												</Link>
											</div>
										))}
									{homeDataItem.hasresult == 1 && (
										<>
											<div className="training-card-col">
												<Link to={homeDataItem.is_first_result == 0 ? "/result-walkthrough" : "/result"}>
													<div className="master_wrapper">
														<div className="training_card_wrapp">
															<div className="trn_img_wrapper">
																<img
																	src={require("../../assets/img/home-index/factory_future_icon.svg").default}
																	className="course_symb"
																	alt="true"
																/>
															</div>
															<p>
																Factory of the Future Leadership Results

															</p>
														</div>
													</div>
												</Link>
											</div>
											<div className="training-card-col">
												<div className="master_wrapper cursor-pointer">
													<div className="training_card_wrapp">
														<div className="trn_img_wrapper ed_yellow_bg">
															<img
																src={require("../../assets/img/home-index/share_assessment.svg").default}
																className="course_symb"
																alt="true"
															/>
														</div>
														<p onClick={HandleInvitePopup}>
															Share your Assessment Results
														</p>
													</div>
												</div>
											</div>
										</>
									)}
									{homeDataItem.assessment_tour == 0 && (
										<div className="training-card-col">
											<Link to="/assessment-tour">
												<div className="master_wrapper">
													<div className="training_card_wrapp">
														<div className="trn_img_wrapper bg-asst-blue">
															<img
																src={require("../../assets/img/home-index/porduct-tour-card.svg").default}
																className="course_symb"
																alt="true"
															/>
														</div>
														<p>
															Digital Maturity Assessment

														</p>
													</div>
												</div>
											</Link>
										</div>
									)}
								</div>
							</div>
						</section>
					</>
				) : (
					tabIndex === 1 && (
						<>
							{/*List view*/}
							<section className="main-training-sec">
								<h2 className="h2">
									<img
										src={require("../../assets/img/home-index/my-training-icon.svg").default}
										className="imd_partnership_logo"
										alt="true"
									/>
									My Trainings
								</h2>
								<div className="indx-home-list-ul">
									<div className="indx-home-list-li">
										<span className="right-angle">
											<img src={require("../../assets/img/home-index/green-right-arrow.svg").default} alt="true" />
										</span>
										<span className="text green">
										Save up to 500€
											<Link onClick={() => setIsQuickBetaPopup(true)} className="link">
											 Start Inviting Now
											</Link>
										</span>
										{/* <span className="work-status green" style>To do</span> */}
									</div>

									{allcoursesliting}
									{homeDataItem.training_tour == 0 && (
										<div className="indx-home-list-li">
											<span className="right-angle">
												<img src={require("../../assets/img/home-index/green-right-arrow.svg").default} alt="true" />
											</span>
											<span className="text green">
												Watch the
												<Link to="/training-walkthrough" className="link">
													Training Courses Product Tour
												</Link>
											</span>
											<span className="work-status green">To do</span>
										</div>
									)}
								</div>
							</section>
							<section className="my-assessment main-training-sec">
								<h2 className="h2">
									<img
										src={require("../../assets/img/home-index/my-assessment-icon.svg").default}
										className="imd_partnership_logo"
										alt="true"
									/>
									My Assessment
								</h2>
								<div className="indx-home-list-ul">
									{homeDataItem.invited_by != 0 && (
										<div className="indx-home-list-li">
											<span className="right-angle">
												<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
											</span>
											<span className="text blue">
												View
												<Link
													to={homeDataItem.is_first_result == 0 ? "/view-result-walkthrough" : "/view-result"}
													className="link"
												>
													{homeDataItem.invshortname}’s Factory of the Future Leadership
												</Link>
												results
											</span>
											<span className="work-status green">To do</span>
										</div>
									)}
									{homeDataItem.hasresult == 0 &&
										(broughttrainging == 1 ? (
											<div className="indx-home-list-li">
												<span className="right-angle">
													<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
												</span>
												<span className="text blue">
													Claim the
													<a href="#" className="link" onClick={HandleSliderPopup}>
														Free limited Digital Maturity Assessment
													</a>
													section
												</span>
												<span className="work-status green">To do</span>
											</div>
										) : (
											<div className="indx-home-list-li">
												<span className="right-angle">
													<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
												</span>
												<span className="text blue">
													Start the
													<Link
														to={homeDataItem.is_first_assessment == 1 ? "/assessment-walkthrough" : "/assessment"}
														className="link"
													>
														Industry 4.0 Vision
													</Link>
													section
												</span>
												<span className="work-status green">To do</span>
											</div>
										))}
									{homeDataItem.hasresult == 1 && (
										<>
											<div className="indx-home-list-li">
												<span className="right-angle">
													<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
												</span>
												<span className="text blue">
													Review the
													<Link to={homeDataItem.is_first_result == 0 ? "/result-walkthrough" : "/result"} className="link">
														Factory of the Future Leadership
													</Link>
													results
												</span>
												<span className="work-status green">To do</span>
											</div>
											<div className="indx-home-list-li">
												<span className="right-angle">
													<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
												</span>
												<span className="text blue">
													Invite people to see your Review the
													<a href="#" className="link" onClick={HandleInvitePopup}>
														Assessment Results
													</a>
												</span>
												<span className="work-status green">To do</span>
											</div>
										</>
									)}

									{homeDataItem.assessment_tour == 0 && (
										<div className="indx-home-list-li">
											<span className="right-angle">
												<img src={require("../../assets/img/home-index/blue-right-arrow.svg").default} alt="true" />
											</span>
											<span className="text blue">
												Watch the
												<Link to="/assessment-tour" className="link">
													Digital Maturity Assessment Product Tour
												</Link>
											</span>
											<span className="work-status green">To do</span>
										</div>
									)}
								</div>
							</section>
						</>
					)
				)}
			</div>
			{/*quick-start-vid-popup end*/}
			<Modal
				open={isSliderPopup}
				// onClose={props.handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="home-index-slider-popup"
			>
				<div className="home-index-modal-slider">
					<button className="close-btn" onClick={HandleSliderPopup}>
						<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="close" />
					</button>
					<CarouselProvider naturalSlideWidth={100} naturalSlideHeight={80} totalSlides={4} dragEnabled={false} isPlaying={false}>
						<Slider>
							<Slide className="slide" index={0}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/home-index/step1-assessment-results.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">1 of 4</h4>
										<h2 className="title">Claim your Free limited Assessment</h2>
										<p>
											Unlock this offer by purchasing one or more Training courses. Watch this short tour to understand what is
											the value of our limited offer and learn more about the assessment.
										</p>
									</div>
								</div>
							</Slide>
							<Slide className="slide" index={1}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/home-index/step2-assessment-results.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">2 of 4</h4>
										<h2 className="title">What is the value</h2>
										<p>
											Answer a few questions - no preparation needed and get a glimpse of your plant’s Industry 4.0
											characteristics. Learn more about the Factory of the Future Framework, Industry 4.0 Dimensions and
											Benchmarking.
										</p>
									</div>
								</div>
							</Slide>
							<Slide className="slide" index={2}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/home-index/step3-assessment-results.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">3 of 4</h4>
										<h2 className="title">Our recommendation</h2>
										<p>
											For maximum engagement, we recommend the Factory of the
											Future Leadership course, as participants get exposed to
											leadership strategies that accelerate a successful digital
											transformation.
										</p>
									</div>
								</div>
							</Slide>
							<Slide className="slide" index={3}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/home-index/step4-assessment-results.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">4 of 4</h4>
										<h2 className="title">What you get</h2>
										<p>Complete the limited Assessment and invite your colleagues to view the results together.</p>
									</div>
								</div>
							</Slide>
						</Slider>
						{isSliderIndex < 3 ? (
							<div className="modal__slider__footer">
								<div className="dots__section">
									<Dot slide={0} className="dot"></Dot>
									<Dot slide={1} className="dot"></Dot>
									<Dot slide={2} className="dot"></Dot>
									<Dot slide={3} className="dot"></Dot>
								</div>
								<div className="btn__section">
									{isSliderIndex === 0 ? (
										<button className="btn__prv" onClick={HandleSliderPopup}>
											Skip
										</button>
									) : (
										<ButtonBack className="btn__prv" onClick={() => handleIndex("subs")}>
											Previous
										</ButtonBack>
									)}
									<ButtonNext className="btn__nxt" onClick={() => handleIndex("add")}>
										Next
									</ButtonNext>
								</div>
							</div>
						) : (
							<div className="modal-last-footer">
								{/* <ButtonBack className="btn-view-course" onClick={()=>handleIndex("subs")}>
							View Courses
							</ButtonBack> */}
								<Link to="/training-courses" className="btn-view-course">
									View Courses
								</Link>

								<Link to="/assessment-tour" className="btn-watch">
									Watch Assessment product tour
								</Link>
							</div>
						)}
					</CarouselProvider>
				</div>
			</Modal>
			{/* slider-popup end*/}
			<Modal
				open={isInvitePopup}
				// onClose={HandleInvitePopup}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="invite-popup"
			>
				<div className="invite-popup-content">
					<div className="invite-popup-card">
						<div className="invite-popup-tooltip">Now that you finished, Invite your colleagues to view the Results together.</div>
						<div className="header">
							<p className="header-text">Invite People</p>
							<button className="close-btn" onClick={HandleInvitePopup}>
								<img src={require("../../assets/img/common-img/close-icon-dark.svg").default} alt="close" />
							</button>
						</div>
						<div className="body">
							<MultiEmailComponent GetValue={HandleMultiEmailValue} setSingleEmail={setSingleEmail} singleEmail={singleEmail} multiEmailVal={multiEmailVal} setEmailError={setEmailError} />
							<button

								className="send-invite-btn"
								onClick={() => multiEmailValSend(multiEmailVal)}
								// disabled={multiEmailVal.length > 0  ? false : true}
								disabled={ishoverVal}
								onMouseOver={disableFunc}
								style={{ pointerEvents: 'all' }}

							>
								<img src={require("../../assets/img/common-img/send-icon.svg").default} alt="true" />
								<span>Send Invite</span>
							</button>
							<p className="inv-email-error">{emailError}</p>
						</div>
					</div>
				</div>
			</Modal>
			{/*invite popup end*/}
			{/*quick beta popup*/}

			<QuickBetaPopupController open={isQuickBetaPopup} setOpen={setIsQuickBetaPopup} />
		</>
	);
};

const MultiEmailComponent = (props) => {
	const multiEmail = [...props.multiEmailVal];

	const HandleSingleEmailValue = (e) => {
		let tmpValue = e.target.value;
		if (tmpValue[0] !== " ") {
			props.setSingleEmail(tmpValue);
			props.setEmailError(false);
		}

	};
	const AddMultiEmail = (e) => {

		let tmpArr = [...multiEmail];
		const emailRegx =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if ((e.charCode == 13 || e.charCode == 32) && emailRegx.test(String(props.singleEmail).toLowerCase())) {

			const addEmail = props.singleEmail;
			tmpArr.push(addEmail);
			props.setSingleEmail("");
			// setMultiEmail(tmpArr);
			props.GetValue(tmpArr);

		}
	};
	const RemoveEmail = (e) => {
		let filterEmail = multiEmail.filter((item, index) => {
			return e !== index;
		});
		// setMultiEmail(filterEmail);
		props.GetValue(filterEmail);
	};
	return (
		<div className="multi-email-wrapper">
			<label className="label">Email</label>
			<div className="all-email-box" style={{ marginBottom: multiEmail.length > 0 && ".4rem" }}>
				{multiEmail.map((item, index) => (
					<div className="enter-email-box" key={index}>
						<p className="email-name" title={item}>
							{item}
						</p>
						<button onClick={() => RemoveEmail(index)}>
							<img src={require("../../assets/img/common-img/remove-email.svg").default} alt="X" />
						</button>
					</div>
				))}
			</div>
			<input
				className="input"
				type="text"
				onChange={(e) => HandleSingleEmailValue(e)}
				onKeyPress={(e) => AddMultiEmail(e)}
				value={props.singleEmail}
				placeholder="Type one or more email addresses"
			/>
		</div>
	);
};
