import React, { useState,useEffect } from 'react';
import './result-walkthrough.scss'
import { Modal } from '@material-ui/core';
import { Link } from 'react-router-dom'
export const ResultWalkthrough = (props) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const HandleSlides = (val) => {
        setActiveSlide(val);
        if(val===0){
            document.querySelector('.training-content').scrollTop=0;
            document.querySelector('#rs-slide-2').classList.add('rswlk-z-9999');
            document.querySelector('.header-page-link-ul').classList.add('rswlk-z-9999');
            document.querySelector('#highlight_tab').classList.add('rswlk-z-9999');
        }else{
            document.querySelector('#rs-slide-2').classList.remove('rswlk-z-9999');
            document.querySelector('.header-page-link-ul').classList.remove('rswlk-z-9999');
            document.querySelector('#highlight_tab').classList.remove('rswlk-z-9999');
        }
        if(val===1){
            document.querySelector('.training-content').scrollTop=500;
            document.querySelector('#highlightTab2').classList.add('rswlk-z-9999');
			//document.querySelector('.sddata-12').classList.add('rswlk-z-9999');
        }else{
            document.querySelector('#highlightTab2').classList.remove('rswlk-z-9999');
        }

        if(val===2){
            if(window.innerWidth>1401){
                document.querySelector('.training-content').scrollTop=1100;
            }else if(window.innerWidth>1280 && window.innerWidth<1401){
                 document.querySelector('.training-content').scrollTop=1000;
            }else if(window.innerWidth>1150 && window.innerWidth<1280){
                 document.querySelector('.training-content').scrollTop=880;
            }else if(window.innerWidth>900 && window.innerWidth<1150){
                 document.querySelector('.training-content').scrollTop=770;
            }else{
                     document.querySelector('.training-content').scrollTop=680;
            }
            
            document.querySelector('#highlight3h1').classList.add('rswlk-z-9999');
            document.querySelector('#strengthHighlight').classList.add('rswlk-z-9999');
        }else{
            document.querySelector('#highlight3h1').classList.remove('rswlk-z-9999');
            document.querySelector('#strengthHighlight').classList.remove('rswlk-z-9999');
         }
    }
    useEffect(()=>{
        HandleSlides(0);
    },[]);
    // component willunmount call when componetn will be remove;
     useEffect(()=>{
        return () => {
            const z99= document.querySelectorAll('.rswlk-z-9999');
            for(let i=0; i<z99.length; i++ ){
                z99[i].classList.remove('rswlk-z-9999');
            }
          }   
         },[]);
    return (
        <Modal open={true} className="result-walkthrough-modal">
            <div className="result-wlakthrough-content">
                <div className={`card ${activeSlide === 0 && ' active'}`}>
                    <Link to="/result" className="clsoe-btn">
                        <img src={require('../../assets/img/common-img/close-icon-gray.svg').default} alt="X" />
                    </Link>
                    <div className="div-img">
                        <img src={require('../../assets/img/result-walkthrough/step1.gif').default} alt="true" />
                    </div>
                    {/* div-img end */}
                    <div className="div-txt">
                        <div className="card-content">
                            <h3 className="dull-text">1 of 3</h3>
                            <h2 className="heading-h1">
                                Assessment Results1
                            </h2>
                            <p className="para-p">
                                Upgrade your plan to get your overall Index Score, see under which archetype your plant falls and benchmark between your company and industry.
                            </p>
                        </div>
                        <div className="card-footer">
                            <div className="dots-sec">
                                <button onClick={() => HandleSlides(0)} disabled={activeSlide === 0 ? true : false}></button>
                                <button onClick={() => HandleSlides(1)} disabled={activeSlide === 1 ? true : false}></button>
                                <button onClick={() => HandleSlides(2)} disabled={activeSlide === 2 ? true : false}></button>
                            </div>
                            <Link className="btn-prv" to="/result">
                                Exit walkthrough
                            </Link>
                            <button className="btn-nxt" onClick={() => HandleSlides(1)}>
                                Next
                            </button>
                        </div>
                    </div>
                    {/* div-img end */}
                </div>
                {/* step 1 end */}
                <div className={`card ${activeSlide === 1 && ' active'}`}>
                    <Link to="/result" className="clsoe-btn">
                        <img src={require('../../assets/img/common-img/close-icon-gray.svg').default} alt="X" />
                    </Link>
                    <div className="div-img">
                        <img src={require('../../assets/img/result-walkthrough/step2.gif').default} alt="true" />
                    </div>
                    {/* div-img end */}
                    <div className="div-txt">
                        <div className="card-content">
                            <h3 className="dull-text">2 of 3</h3>
                            <h2 className="heading-h1">
                                Index per Dimension
                            </h2>
                            <p className="para-p">
                                Here you can see your Index analysed in our 10 dimensions framework and benchmark between your company and industry.
                            </p>
                        </div>
                        <div className="card-footer">
                            <div className="dots-sec">
                                <button onClick={() => HandleSlides(0)} disabled={activeSlide === 0 ? true : false}></button>
                                <button onClick={() => HandleSlides(1)} disabled={activeSlide === 1 ? true : false}></button>
                                <button onClick={() => HandleSlides(2)} disabled={activeSlide === 2 ? true : false}></button>
                            </div>
                            <button className="btn-prv" onClick={() => HandleSlides(0)}>
                                Previous
                            </button>
                            <button className="btn-nxt" onClick={() => HandleSlides(2)}>
                                Next
                            </button>
                        </div>
                    </div>
                    {/* div-img end */}
                </div>
                {/* step 2 end */}
                <div className={`card ${activeSlide === 2 && ' active'}`}>
                    <Link to="/result" className="clsoe-btn">
                        <img src={require('../../assets/img/common-img/close-icon-gray.svg').default} alt="X" />
                    </Link>
                    <div className="div-img">
                        <img src={require('../../assets/img/result-walkthrough/step3.gif').default} alt="true" />
                    </div>
                    {/* div-img end */}
                    <div className="div-txt">
                        <div className="card-content">
                            <h3 className="dull-text">3 of 3</h3>
                            <h2 className="heading-h1">
                                Strength and Opportunity
                            </h2>
                            <p className="para-p">
                                For each Dimension you gain access to insights about the adoption rates of relevant technologies, organizational constructs or leadership attributes within your company or the whole Industry.
                            </p>
                        </div>
                        <div className="card-footer">
                            <div className="dots-sec">
                                <button onClick={() => HandleSlides(0)} disabled={activeSlide === 0 ? true : false}></button>
                                <button onClick={() => HandleSlides(1)} disabled={activeSlide === 1 ? true : false}></button>
                                <button onClick={() => HandleSlides(2)} disabled={activeSlide === 2 ? true : false}></button>
                            </div>
                            <button className="btn-prv" onClick={() => HandleSlides(1)}>
                                Previous
                            </button>
                            <Link to="/result" className="btn-nxt">
                                Close
                            </Link>

                        </div>
                    </div>
                    {/* div-img end */}
                </div>
                {/* step 2 end */}
            </div>

        </Modal>
    )
}