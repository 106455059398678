import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import { PlatformOnboradingTour } from '../components/platform-onboarding-tour/platform-onboarding-tour'
// import {createBrowserHistory} from 'history'
import { SinglePageLayout } from "../layouts/single-page-layout";
import { TrainingsControler } from "../components/trainings/trainings-ctrl";
import { HomeControler } from "../components/home/home-ctrl";
import { HomeTourControler } from "../components/home-tour/home-tour-ctrl";
//import { HomeIndexControler } from "../components/home-index/home-index-ctrl";
// **Login Pages**
import { Signin } from "../components/SignIn/Signin";
import { Signininvite } from "../components/SignIn/Signininvite";
import { Signup } from "../components/SignIn/Signup";
import { Signupinvite } from "../components/SignIn/Signupinvite";
import { Signupreferral } from "../components/SignIn/Signupreferral";
import { Forgotpassword } from "../components/SignIn/Forgotpassword";
import { Resetpassword } from "../components/SignIn/Resetpassword";
// **Training Pages**
import { CourseDetailControler } from "../components/training-courses/course-detail-ctrl";
import { CourseOverviewControler } from "../components/training-courses/course-overview-ctrl";
import { CourseOverviewSingleControler } from "../components/training-courses/course-overview-single-ctrl";
import { ProductTrainingSingleControler } from "../components/training-courses/product-training-single-ctrl";
import { MaturityDashboardPageController } from "../components/maturity-dashboard-page/maturity-dashboard-page-ctrl";

import { QuizController } from "../components/quiz/quiz-ctrl";
import { TrainingWalkthroughController } from "../components/training-walkthrough/training-walkthrough-ctrl";
// **Resources Pages**
import { Industry40Controller } from "../components/industry-40/industry-40-ctrl";
import { LearnAndUpgradeController } from "../components/learn-and-upgrade/learn-and-upgrade-ctrl";
import { DigitalPlanController } from "../components/digital-plan/digital-plan-ctrl";
import { FrameworkController } from "../components/framework/framework-ctrl";
import { CpgstudyController } from "../components/cpgstudy/cpgstudy-ctrl";
// **Ticket Pages**
import { TicketsController } from "../components/tickets/tickets-ctrl";
// **Plans Page**
import { PlansPageController } from "../components/plans-page/plans-page-ctrl";
// **Profile Pages**
import { ProfileController } from "../components/profile/profile-ctrl";
import { PurchaseController } from "../components/profile/purchase-ctrl";
import { CertificateController } from "../components/profile/certificate-ctrl";
import { SubscriptionController } from "../components/profile/subscription-ctrl";
// **Cart Pages**
import { CartControler } from "../components/cart-page/cart-ctrl";
import { CartMatControler } from "../components/cartmaturity-page/cartmaturity-ctrl";
import { CartBillControler } from "../components/bill-page/bill-ctrl";
import { CartBillMaturityControler } from "../components/billmaturity-page/billmaturity-ctrl";
import { CartPaymentControler } from "../components/payment-page/payment-ctrl";
import { CartMatPaymentControler } from "../components/paymentmaturity-page/paymentmaturity-ctrl";
import { PaymentSuccessControler } from "../components/payment-success/payment-success-ctrl";
import { PaymentMatSuccessControler } from "../components/paymentmaturity-success/paymentmaturity-success-ctrl";
//LossAnalysis
import { LossAnalysisSetupPageController } from "../components/loss-analysis-setup-page/loss-analysis-setup-page-ctrl";
import { LossAnalysisSetupPagePlantController } from "../components/loss-analysis-setup-page/loss-analysis-setup-page-plant-ctrl";
import { LossAnalysisAdminPageController } from "../components/loss-analysis-admin-page/loss-analysis-admin-page-ctrl";
import { LossadminPlantController } from "../components/loss-analysis-admin-page/lossadmin-plant-ctrl";
import { LossanalysisQuesController } from "../components/la-questionnaires/la-questionnaires-ctrl";
import { LossanalysisPlantQuesController } from "../components/la-questionnaires/la-questionnaires-plant-ctrl";
import { LossAnalysisPagePlantController } from "../components/loss-analysis-page/loss-analysis-page-plant-ctrl";
import { LossPoweBiResultCtrl } from '../components/LossPoweBiResult/LossPoweBiResultCtrl'
// losssection
import { OperatorEffortController } from "../components/operator-effort-losses/operator-effort-ctrl";
import { OperatorEffortPlantController } from "../components/operator-effort-losses/operator-effort-plant-ctrl";
import { MaintenanceLossesPageController } from "../components/maintenance-losses/maintenance-losses-page-ctrl.js";
import { MaintenanceLossesPagePlantController } from "../components/maintenance-losses/maintenance-losses-page-plant-ctrl.js";
import { GeneralInformationPageController } from "../components/general-information/general-information-page-ctrl.js";
import { GeneralInformationPagePlantController } from "../components/general-information/general-information-page-plant-ctrl.js";
import { FteBreakdownPageController } from "../components/fte-breakdown/fte-breakdown-page-ctrl.js";
import { ManufacturingCostPageController } from "../components/manufacturing-cost/manufacturing-cost-page-ctrl.js";
import { ManufacturingCostPagePlantController } from "../components/manufacturing-cost/manufacturing-cost-page-plant-ctrl.js";
// **Assessment Pages**
import { AssessmentController } from "../components/assessment/assessment-ctrl";
import { AssessmentPlantController } from "../components/assessment/assessmentplant-ctrl";
import { ChapterController } from "../components/assessment/chapter-ctrl";
import { ChapterPlantController } from "../components/assessment/chapter-plant-ctrl";
import { AllquestionsController } from "../components/assessment/allquestions-ctrl";
import { AllquestionsPlantController } from "../components/assessment/allquestions-plant-ctrl";
import { QuestionsController } from "../components/assessment/question-ctrl";
import { QuestionsPlantController } from "../components/assessment/question-plant-ctrl";
// **Walkthrough Pages**
import { WalkthroughController } from "../components/walk-through/walkthrough-ctrl";
import { WalkthroughController1 } from "../components/assessment-walk-through/walkthrough1-ctrl";
import { ResulttourController } from "../components/resulttour/resulttour-ctrl";
// **Static Pages**
import { MaturityDashboardAdminController } from "../components/maturity-dashboard-admin/maturity-dashboard-admin-ctrl";
import { AdminWalkthroughController } from "../components/admin-walkthrough/admin-walkthrough-ctrl";
import { AllSectionsController } from "../components/all-sections/all-sections-ctrl";
import { ResultController } from "../components/result/result-ctrl";
import { ResultPlantController } from "../components/result/resultplant-ctrl";
import { ResultInviteController } from "../components/result/resultinvite-ctrl";
import { ResultWalkthroughController } from "../components/result-walkthrough/result-walkthrough-ctrl";
import { ViewResultWalkthroughController } from "../components/result-walkthrough/view-result-walkthrough-ctrl";
import { TrainingCourseWalkthrough } from "../components/course-overview-walkthrough/training-course-walkthrough";
import { QuizWalkthrough } from "../components/course-overview-walkthrough/quiz-walkthrough";
import { VerificationLinkSent } from "../components/SignIn/confirm-pass.js";
import { ThankYouEamilConfirm } from "../components/SignIn/thankYou-pass.js";
import { LossAnalysisTour } from "../components/loss-analysis-tour/loss-analysis-tour";
import { AllQuestionView } from "../components/assessment/all-qes-view.js";
import { PrepareHomeCtrl } from "../components/prepare-home-page/home-ctrl";
import { LimitedResultPlantController } from "../components/limited-result/limitedresultplant-ctrl";

//business-case
import { BusinessCaseCtrl } from "../components/business-case/business-case-ctrl";
import { PowerBiLossAnalysisController } from "../components/powerbi-loss-analysis/powerbi-loss-analysis-ctrl";
import { ResultsPowerBiController } from "../components/results-power-bi/results-powerbi-ctrl";
import { PowerBiController } from "../components/power-bi/powerbi-ctrl";
import { LtExportController } from "../components/lt-export/lt-export-ctrl";
import { EadgeCaseView } from "../components/edge-case/EadgeCase";
// **End
// SCNV 2179 start

import { SkillController } from "../components/skill-assessment/skill-ctrl";
import { SkillsAssessmentPageController } from "../components/skill-assessment/skills-assessment-page-ctrl";
import { Page404 } from "../components/page-404/page-404.js";
import { UnassignView } from "../components/unassign-page/unassign-view";
import { TaskCenterController } from "../components/task-center-pages/TaskCenterController";
// SCNV 2179 end
// Loss Result Tour
import { LossResultTour } from "../components/loss-result-tour/loss-result-tour";
//Loss Result Tour end
import Weblive from "../components/weblive";
import Roadmapconfigurator from "../components/roadmapconfigurator";
/*learning resourses start*/

import { LearningCenterController } from "../components/learning-resources-center-page/LearningCenterController";

import { LearningTrainingWalkthrough } from "../components/learning-resource-walkthrough/training-walkthrough-view.js";

import { LearningAssessmentWalkthrough } from "../components/learning-resource-walkthrough/assessment-walkthrough.js";
import { OnboardingAssessmentTour } from "../components/onboarding-assessment-tour/assessment-walkthrough.js";

import { LearningAssessmentResultWalkthrough } from "../components/learning-resource-walkthrough/AssessmentResultWalkthrough.js";

import { LearningLossAnalysisWalkthrough } from "../components/learning-resource-walkthrough/loss-analysis-walkthrough.js";

import { WhitePaperController } from "../components/white-paper/white-paper-ctrl";

import { LearningProductTourView } from "../components/learning-resource-product-tour/learning-product-tour-view";
/*learning resourses end*/
import { SiriMainCtrl } from '../components/siri-assessment/SiriMainCtrl'
import { SiriDimensionCtrl } from '../components/siri-assessment/SiriDimensionCtrl'
import { SkillAssessmentAnalyticsCtrl } from "../components/SkillAssessmentAnalytics/SkillAssessmentAnalyticsCtrl";
import { NewSignUpCtrl } from "../components/new-signup/NewSignUpCtrl";
// Roadmap
import { RoadmapStep1View } from '../components/Roadmap-steps/RoadmapStep1View'
import { RoadmapStepScndView } from '../components/Roadmap-steps/RoadmapStepScndView'
import { RoadmapStepThirdView } from '../components/Roadmap-steps/RoadmapStepThirdView'
import { RoadmapStepFourView } from '../components/Roadmap-steps/RoadmapStepFourView'
// Self serve changes
import { AddPlantPopViewCtrl } from "../components/upgrade-add-plant-popup/AddPlantPopView-ctrl";
import { AddPlantPopView } from "../components/upgrade-add-plant-popup/AddPlantPopView";
import { NewSignInCtrl } from "../components/new-signin/NewSignInCtrl";
import { UpgradeView } from "../components/upgrade-demo/UpgradeView";
import { RoadmapUpgradeView } from "../components/roadmap-upgrade/RoadmapUpgradeView";
// New Assessment Pages
import { KPICtrl } from "../components/KPISelection/KPICtrl";
import { PlantCostCtrl } from "../components/PlantCost/PlantCostCtrl";
import { PlantCostPercentValueCtrl } from "../components/PlantCostPercentValue/PlantCostPercentValueCtrl";
// Company Detail Page
import { CompanyDetailCtrl } from "../components/company-detail/CompanyDeatilCtrl";
// Roadmap
import { RoadmapStep1Ctrl } from '../components/Roadmap-final-steps/RoadmapStep1Ctrl'
import { RoadmapStepFourCtrl } from '../components/Roadmap-final-steps/RoadmapStepFourCtrl'
import { RoadmapTabCtrl } from "../components/roadmap-result/RoadmapTabCtrl";

import { RoadmapStepFull1Ctrl } from '../components/Roadmap-full-final-steps/RoadmapStepFull1Ctrl'
import { RoadmapStepFullFourCtrl } from '../components/Roadmap-full-final-steps/RoadmapStepFullFourCtrl'
import { RoadmapStepThirdCtrl as RoadmapStepFullThirdCtrl } from '../components/Roadmap-full-final-steps/RoadmapStepThirdCtrl'
import { RoadmapStepScndCtrl as RoadmapFullStepScndCtrl } from '../components/Roadmap-full-final-steps/RoadmapStepScndCtrl'
import { PowerBiBresultsController } from "../components/power-bi-bresults/powerbi-bresults-ctrl";
import { DimensionAndSolutionsMappingCtrl } from "../components/DimensionAndSolutionsMapping/DimensionAndSolutionsMappingCtrl";
// end
export const Root = (props) => {
  const login = localStorage.getItem("isLoggedIn");
  const [isBrowserSupport, setIsBrowserSupport] = React.useState(true);
  const [ip, setIP] = React.useState();
  const citylist = [
    "Iraq",
    "Myanmar",
    "Zimbabwe",
    "Sudan",
    "Belarus",
    "North Korea ",
    "Lebanon",
    "Iran",
    "Somalia",
    "Guinea",
    "Libya",
    "Syria",
    "Guinea-Bissau",
    "Central African Republic",
    "Yemen",
    "Burundi",
    "South Sudan",
    "Mali",
    "Venezuela",
    "Nicaragua",
    "Democratic Republic of The Congo",
    "Ukraine",
    "Afghanistan",
  ];
  const getData = async () => {
    // const res = await axios.get('https://geolocation-db.com/json/')
    // const {data} = await axios.get('http://185.19.30.43/educatetraining/api/ipblock?param=ipblock')
    // console.log(data);
    // console.log(res.data.country);
    // setIP(res.data.country)
  };
  React.useEffect(() => {
    let userAgentString = window.navigator.userAgent;
    // Detect Internet Explorer
    let IExplorerAgent =
      userAgentString.indexOf("MSIE") > -1 ||
      userAgentString.indexOf("rv:") > -1;
    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
    if (IExplorerAgent) {
      //setIsBrowserSupport(false);
    }
    getData();
  }, []);
  return (
    <>
      {/* {console.log(citylist.includes(ip)==false), console.log(ip) */}
      {citylist.includes("India") == false ? (
        <>
          {isBrowserSupport ? (
            <Router getUserConfirmation={() => { }}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={HomeControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/home-tour"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={HomeTourControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/quickstartopen"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={HomeControler}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Login Pages */}
                <Route path="/sign-in" component={Signin}></Route>
                <Route
                  path="/sign-in-invite/:id"
                  component={Signininvite}
                ></Route>

                <Route
                  path="/forgot-password"
                  component={Forgotpassword}
                ></Route>
                <Route
                  path="/update-password/:id"
                  component={Resetpassword}
                ></Route>
                <Route
                  path="/sign-up-invite/:id"
                  component={Signupinvite}
                ></Route>
                <Route
                  path="/sign-up-referral/:id"
                  component={Signupreferral}
                ></Route>
                <Route path="/weblivedata/:id" component={Weblive} />
                <Route path="/weblivedata" component={Weblive} />
                <Route
                  path="/roadmapconfigurator/:id"
                  component={Roadmapconfigurator}
                />
                {/* Training Pages */}
                <Route
                  exact
                  path="/training-courses"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={TrainingsControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/training-courses/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CourseOverviewControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/training-courses/:1/:2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CourseOverviewSingleControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/product-training/:1/:2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ProductTrainingSingleControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/training-details/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CourseDetailControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/training-quiz/:id"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={QuizController}
                        quiz_page={true}
                        {...props}
                      />
                    );
                  }}
                />
                {/* SCNV 2179 start */}
                <Route
                  exact
                  path="/skill-assessment/:id"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={SkillController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/skill-assessment"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={SkillsAssessmentPageController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* SCNV 2179 end */}
                <Route
                  exact
                  path="/training-walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={TrainingWalkthroughController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Resources Pages */}
                <Route
                  exact
                  path="/framework"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={FrameworkController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/loss-analysis-admin"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress="true"
                        isSidebar="true"
                        children={LossAnalysisAdminPageController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/loss-analysis-admin/section/:slug"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={false}
                        isSidebar="true"
                        children={LossadminPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/lossanalysisques"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={LossanalysisQuesController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/lossanalysisques/questionaire/:slug/:sec/:q"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={false}
                        isSidebar="true"
                        children={LossanalysisPlantQuesController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/lossanalysis/section/:slug"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={false}
                        isSidebar="true"
                        children={LossAnalysisPagePlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/loss-analysis-setup"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LossAnalysisSetupPageController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/operator-effort-losses/questionaire/:slug/:que"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={OperatorEffortPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/operator-effort-losses"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={OperatorEffortController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/keyplant/:loasspages/questionaire/:slug/:que"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={false}
                        children={MaintenanceLossesPagePlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/maintenance-losses"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={false}
                        children={MaintenanceLossesPageController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/general-information/questionaire/:slug/:sec/:q"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={false}
                        children={GeneralInformationPagePlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/general-information"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={false}
                        children={GeneralInformationPageController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/fte-breakdown/questionaire/:slug/:sec/:q"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={false}
                        children={FteBreakdownPageController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/manufacturing-cost/questionaire/:slug/:sec/:q"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={false}
                        children={ManufacturingCostPagePlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/manufacturing-cost"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={false}
                        children={ManufacturingCostPageController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/loss-analysis-setup/:slug"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LossAnalysisSetupPagePlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessment/:slug"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={true}
                        children={AssessmentPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/maturity-dashboard"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={MaturityDashboardPageController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/maturity-dashboard-admin"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={MaturityDashboardAdminController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Roadmap */}
                <Route
                  exact
                  path="/roadmap/step1/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStep1Ctrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap/final-step/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStepFourCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap-result/:slug1/:slug2/:section?"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapTabCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/industry-40-dimensions"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={Industry40Controller}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/learn-and-upgrade"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LearnAndUpgradeController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/digital-plant"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={DigitalPlanController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/cpgstudy"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CpgstudyController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Ticket Pages */}
                <Route
                  exact
                  path="/support-center"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={TicketsController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Plans Page */}
                <Route
                  exact
                  path="/plans"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PlansPageController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Profile Pages */}
                <Route
                  exact
                  path="/profile"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ProfileController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/purchase-history"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PurchaseController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/certificates"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CertificateController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/subscription"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={SubscriptionController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Cart Pages */}
                <Route
                  exact
                  path="/cart"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CartControler}
                        {...props}
                      />
                    );
                  }}
                />
                {/* //update by educate */}
                <Route
                  exact
                  path="/cartmat:id"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CartMatControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/billinginfo"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CartBillControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/billingmaturity"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CartBillMaturityControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/maturityasspayment"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CartMatPaymentControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/payment"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={CartPaymentControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/payment_success"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PaymentSuccessControler}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/paymentmat_success"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PaymentMatSuccessControler}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Assessment Pages */}
                <Route
                  exact
                  path="/assessment"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={AssessmentController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessment/chapter/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={true}
                        isSidebar={true}
                        children={ChapterController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessment/questions/:slug/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={true}
                        isSidebar={true}
                        children={AllquestionsPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessment/questions/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={true}
                        isSidebar={true}
                        children={AllquestionsController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessment/chapter/:slug/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={true}
                        isSidebar={true}
                        children={ChapterPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* //plant wise */}
                <Route
                  exact
                  path="/assessment/start/:slug/:1?/:2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={true}
                        isSidebar={true}
                        children={QuestionsPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessmentlim/start/:1?/:2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isHeaderProgress={true}
                        isSidebar={true}
                        children={QuestionsController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Static Pages */}
                <Route
                  exact
                  path="/walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={WalkthroughController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/quizrnd"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={QuizView}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessment-tour"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={WalkthroughController1}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/result-tour"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ResulttourController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/assessment-walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        isHeaderProgress={true}
                        children={AllSectionsController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/result/:plant"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ResultPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/limited-result"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LimitedResultPlantController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/result"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ResultController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/view-result"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ResultInviteController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/result-walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ResultWalkthroughController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/view-result-walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ViewResultWalkthroughController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/course-walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={TrainingCourseWalkthrough}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/quiz-walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={QuizWalkthrough}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/email-sent"
                  render={(props) => {
                    return <VerificationLinkSent {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/email-sent"
                  render={(props) => {
                    return <VerificationLinkSent {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/email-confirmation/:id"
                  render={(props) => {
                    return <ThankYouEamilConfirm {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/loss-analysis-tour"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LossAnalysisTour}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/home"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PrepareHomeCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/businesscase/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={BusinessCaseCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/losspowerbi"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PowerBiLossAnalysisController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/resultspowerbi"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={ResultsPowerBiController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/lossresults/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LossPoweBiResultCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/powerbi/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PowerBiController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/ltexport/:1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LtExportController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/task-center"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={TaskCenterController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/not-found"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={EadgeCaseView}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/user-unassign"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={UnassignView}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/loss-result-tour"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LossResultTour}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/learning-center/:tab_number?"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={LearningCenterController}
                        {...props}
                      />
                    );
                  }}
                />
                {/*learning walkthroughs*/}
                <Route
                  exact
                  path="/learning-training-walkthrough"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={LearningTrainingWalkthrough}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/learning-assessment-walkthrough/:section?"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={LearningAssessmentWalkthrough}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/onboarding-assessment-tour/:section?"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={OnboardingAssessmentTour}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/learning-assessment-result-walkthrough/:section?"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={LearningAssessmentResultWalkthrough}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/learning-loss-walkthrough/:section?"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={LearningLossAnalysisWalkthrough}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  isSidebar="true"
                  path="/whitepaper"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={WhitePaperController}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/learning-product-tour"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={LearningProductTourView}
                        {...props}
                      />
                    );
                  }}
                />
                {/* platform onboarding */}
                <Route
                  exact
                  path="/platform-onboarding-tour"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PlatformOnboradingTour}
                        {...props}
                      />
                    );
                  }} />

                <Route
                  exact
                  path="/siri-assessment/:slug"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={SiriMainCtrl}
                        {...props}
                      />
                    );
                  }} />
                <Route
                  exact
                  path="/siri-dimension/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={SiriDimensionCtrl}
                        {...props}
                      />
                    );
                  }} />
                <Route
                  exact
                  path="/skill-assessment-analytics"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={SkillAssessmentAnalyticsCtrl}
                        {...props}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/sign-up"
                  render={(props) => {
                    return (
                      <NewSignUpCtrl
                        {...props}
                      />
                    );
                  }}
                />
                {/* Roadmap */}
                <Route
                  exact
                  path="/roadmap/step1"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStep1View}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap/step2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStepScndView}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap/step3"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStepThirdView}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap/step4"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStepFourView}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Self Serve */}
                <Route
                  exact
                  path="/new-signin"
                  render={(props) => {
                    return (
                      <NewSignInCtrl
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/upgrade-add-plant"
                  render={(props) => {
                    return (
                      <AddPlantPopViewCtrl
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/price-upgrade-popup"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={UpgradeView}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap-upgrade"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapUpgradeView}
                        {...props}
                      />
                    );
                  }}
                />
                 {/* Roadmap Full*/}
                 <Route
                  exact
                  path="/roadmap-complete/step1/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStepFull1Ctrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap-complete/step2/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapFullStepScndCtrl}
                        {...props}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/roadmap-complete/step3/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStepFullThirdCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/roadmap-complete/final-step/:slug1/:slug2"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={RoadmapStepFullFourCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/dimension-and-solutions-mapping/:slug"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={DimensionAndSolutionsMappingCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                 <Route
                  exact
                  path="/powerbi/:slug1/:slug2/:slug3"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={PowerBiBresultsController}
                        {...props}
                      />
                    );
                  }}
                />
                {/* Compnay Detail Page */}
                <Route
                  exact
                  path="/company-detail/:slug"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={CompanyDetailCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                {/* New Assessment Pages(Plant Cost, Plant Cost Percent Value, KPI Selection) */}
                <Route
                  exact
                  path="/kpi-selection/:slug/:id"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={KPICtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/plant-cost/:slug/:id"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={PlantCostCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/plant-cost-percent-value/:slug/:id"
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar="true"
                        children={PlantCostPercentValueCtrl}
                        {...props}
                      />
                    );
                  }}
                />
                {/* end */}
                <Route
                  render={(props) => {
                    return (
                      <SinglePageLayout
                        isSidebar={true}
                        children={Page404}
                        {...props}
                      />
                    );
                  }}
                />
              </Switch>
            </Router>
          ) : (
            <BlockOnSomeBrowser />
          )}
        </>
      ) : (
        <BlockOnSomeCity />
      )}
    </>
  );
};

let BlockOnSomeBrowser = () => {
  return (
    <div className="block-on-blowsers-wrapper">
      <div className="block-on-logo">
        <img
          src={
            require("../assets/img/sidebar-img/team-header-logo.svg").default
          }
          alt="Samrterchains"
        />
      </div>
      <div className="block-on-text">
        <div className="p-wrapper">
          <p>Dear user,</p>

          <p>
            Please use one of the recommended web browsers (Chrome, Edge) to log
            into the platform.
          </p>
        </div>
      </div>
    </div>
  );
};

let BlockOnSomeCity = () => {
  return (
    <div className="block-on-blowsers-wrapper">
      <div className="block-on-logo">
        <img
          src={
            require("../assets/img/sidebar-img/team-header-logo.svg").default
          }
          alt="Samrterchains"
        />
      </div>
      <div className="block-on-text">
        <div className="p-wrapper">
          <p>Dear user,</p>

          <p>We are sorry. Our services are not available in your Country.</p>
        </div>
      </div>
    </div>
  );
};
