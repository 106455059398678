/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * CartBillView for ==> custom component.
  * BreadcrumbsItem for ==> dynamic breadcrumbs.

**/
import React, { Component } from "react";
import { number } from "yup";
import { Redirect } from "react-router";
import serviceProvider from "../../services/axios";
import { CartBillView } from "./bill-view";
import "../cart.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";

// country list variable
const defaultCountryList = [
  { id: "4", name: "Afghanistan", code: "AF" },
  { id: "8", name: "Albania", code: "AL" },
  { id: "12", name: "Algeria", code: "DZ" },
  { id: "20", name: "Andorra", code: "AD" },
  { id: "24", name: "Angola", code: "AO" },
  { id: "32", name: "Argentina", code: "AR" },
  { id: "51", name: "Armenia", code: "AM" },
  { id: "533", name: "Aruba", code: "AW" },
  { id: "36", name: "Australia", code: "AU" },
  { id: "40", name: "Austria", code: "AT" },
  { id: "31", name: "Azerbaijan", code: "AZ" },
  { id: "44", name: "Bahamas", code: "BS" },
  { id: "48", name: "Bahrain", code: "BH" },
  { id: "50", name: "Bangladesh", code: "BD" },
  { id: "56", name: "Belgium", code: "BE" },
  { id: "68", name: "Bolivia", code: "BO" },
  { id: "70", name: "Bosnia and Herzegovina", code: "BA" },
  { id: "76", name: "Brazil", code: "BR" },
  { id: "100", name: "Bulgaria", code: "BG" },
  { id: "116", name: "Cambodia", code: "KH" },
  { id: "120", name: "Cameroon", code: "CM" },
  { id: "124", name: "Canada", code: "CA" },
  { id: "140", name: "Central African Republic", code: "CF" },
  { id: "896", name: "Chile", code: "CHL" },
  { id: "156", name: "China", code: "CN" },
  { id: "170", name: "Colombia", code: "CO" },
  { id: "174", name: "Comoros", code: "KM" },
  { id: "188", name: "Costa Rica", code: "CR" },
  { id: "191", name: "Croatia", code: "HR" },
  { id: "192", name: "Cuba", code: "CU" },
  { id: "196", name: "Cyprus", code: "CY" },
  { id: "203", name: "Czech Republic", code: "CZ" },
  { id: "262", name: "Denmark", code: "DK" },
  { id: "626", name: "Dominican Republic", code: "DO" },
  { id: "218", name: "Ecuador", code: "EC" },
  { id: "818", name: "Egypt", code: "EG" },
  { id: "222", name: "El Salvador", code: "SV" },
  { id: "233", name: "Estonia", code: "EE" },
  { id: "231", name: "Ethiopia", code: "ET" },
  { id: "242", name: "Fiji", code: "FJ" },
  { id: "246", name: "Finland", code: "FI" },
  { id: "250", name: "France", code: "FR" },
  { id: "268", name: "Georgia", code: "GE" },
  { id: "276", name: "Germany", code: "DE" },
  { id: "288", name: "Ghana", code: "GH" },
  { id: "292", name: "Gibraltar", code: "GI" },
  { id: "300", name: "Greece", code: "GR" },
  { id: "320", name: "Guatemala", code: "GT" },
  { id: "332", name: "Haiti", code: "HT" },
  { id: "340", name: "Honduras", code: "HN" },
  { id: "344", name: "Hong Kong", code: "HK" },
  { id: "348", name: "Hungary", code: "HU" },
  { id: "352", name: "Iceland", code: "IS" },
  { id: "356", name: "India", code: "IN" },
  { id: "360", name: "Indonesia", code: "ID" },
  { id: "364", name: "Iran", code: "IR" },
  { id: "368", name: "Iraq", code: "IQ" },
  { id: "372", name: "Ireland", code: "IE" },
  { id: "376", name: "Israel", code: "IL" },
  { id: "380", name: "Italy", code: "IT" },
  { id: "384", name: "Ivory Coast", code: "CI" },
  { id: "388", name: "Jamaica", code: "JM" },
  { id: "392", name: "Japan", code: "JP" },
  { id: "400", name: "Jordan", code: "JO" },
  { id: "398", name: "Kazakhstan", code: "KZ" },
  { id: "404", name: "Kenya", code: "KE" },
  { id: "855", name: "Kosovo", code: "XK" },
  { id: "414", name: "Kuwait", code: "KW" },
  { id: "417", name: "Kyrgyzstan", code: "KG" },
  { id: "418", name: "Laos", code: "LA" },
  { id: "428", name: "Latvia", code: "LV" },
  { id: "422", name: "Lebanon", code: "LB" },
  { id: "434", name: "Libya", code: "LY" },
  { id: "440", name: "Lithuania", code: "LT" },
  { id: "442", name: "Luxembourg", code: "LU" },
  { id: "446", name: "Macao", code: "MO" },
  { id: "450", name: "Madagascar", code: "MG" },
  { id: "458", name: "Malaysia", code: "MY" },
  { id: "462", name: "Maldives", code: "MV" },
  { id: "470", name: "Malta", code: "MT" },
  { id: "484", name: "Mexico", code: "MX" },
  { id: "498", name: "Moldova", code: "MD" },
  { id: "492", name: "Monaco", code: "MC" },
  { id: "496", name: "Mongolia", code: "MN" },
  { id: "499", name: "Montenegro", code: "ME" },
  { id: "504", name: "Morocco", code: "MA" },
  { id: "508", name: "Mozambique", code: "MZ" },
  { id: "104", name: "Myanmar", code: "MM" },
  { id: "528", name: "Netherlands", code: "NL" },
  { id: "554", name: "New Zealand", code: "NZ" },
  { id: "897", name: "Nicaragua", code: "NIC" },
  { id: "566", name: "Nigeria", code: "NG" },
  { id: "408", name: "North Korea", code: "KP" },
  { id: "578", name: "Norway", code: "NO" },
  { id: "512", name: "Oman", code: "OM" },
  { id: "586", name: "Pakistan", code: "PK" },
  { id: "591", name: "Panama", code: "PA" },
  { id: "598", name: "Papua New Guinea", code: "PG" },
  { id: "600", name: "Paraguay", code: "PY" },
  { id: "604", name: "Peru", code: "PE" },
  { id: "608", name: "Philippines", code: "PH" },
  { id: "616", name: "Poland", code: "PL" },
  { id: "620", name: "Portugal", code: "PT" },
  { id: "630", name: "Puerto Rico", code: "PR" },
  { id: "634", name: "Qatar", code: "QA" },
  { id: "178", name: "Republic of the Congo", code: "CG" },
  { id: "642", name: "Romania", code: "RO" },
  { id: "643", name: "Russia", code: "RU" },
  { id: "682", name: "Saudi Arabia", code: "SA" },
  { id: "686", name: "Senegal", code: "SN" },
  { id: "688", name: "Serbia", code: "RS" },
  { id: "702", name: "Singapore", code: "SG" },
  { id: "703", name: "Slovakia", code: "SK" },
  { id: "705", name: "Slovenia", code: "SI" },
  { id: "706", name: "Somalia", code: "SO" },
  { id: "710", name: "South Africa", code: "ZA" },
  { id: "898", name: "South Korea", code: "KOR" },
  { id: "724", name: "Spain", code: "ES" },
  { id: "144", name: "Sri Lanka", code: "LK" },
  { id: "752", name: "Sweden", code: "SE" },
  { id: "756", name: "Switzerland", code: "CH" },
  { id: "760", name: "Syria", code: "SY" },
  { id: "158", name: "Taiwan", code: "TW" },
  { id: "762", name: "Tajikistan", code: "TJ" },
  { id: "764", name: "Thailand", code: "TH" },
  { id: "780", name: "Trinidad and Tobago", code: "TT" },
  { id: "788", name: "Tunisia", code: "TN" },
  { id: "792", name: "Turkey", code: "TR" },
  { id: "795", name: "Turkmenistan", code: "TM" },
  { id: "800", name: "Uganda", code: "UG" },
  { id: "804", name: "Ukraine", code: "UA" },
  { id: "784", name: "United Arab Emirates", code: "AE" },
  { id: "826", name: "United Kingdom", code: "GB" },
  { id: "840", name: "United States", code: "US" },
  { id: "858", name: "Uruguay", code: "UY" },
  { id: "860", name: "Uzbekistan", code: "UZ" },
  { id: "862", name: "Venezuela", code: "VE" },
  { id: "704", name: "Vietnam", code: "VN" },
  { id: "887", name: "Yemen", code: "YE" },
  { id: "716", name: "Zimbabwe", code: "ZW" },
];

export class CartBillControler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contryList: defaultCountryList,
      profileData: [],
      billCountry: "",
      billDdl: false,
      redirect: false,
      paidItem: 0,
    };
  }

  // funtion to open and close dropdown list
  HandleBillDdlOnClick = (e) => {
    if (e === "close") {
      this.setState({
        billDdl: false,
      });
    } else {
      let val = !this.state.billDdl;
      this.setState({
        billDdl: val,
      });
    }
  };
  // funtion to select  billinfo form country value
  HandleBillDdlValueOnClick = (val) => {
    this.setState({
      billCountry: val,
    });
  };
  RedirectClick = () => {
    this.setState({
      redirect: true,
    });
  };

  // funcion to filter country on search
  HandleOnSearch = (e) => {
    const val = e.target.value.toLowerCase();
    const filterData = defaultCountryList.filter((item) => {
      return item.name.toLowerCase().startsWith(val);
    });
    if (val) {
      this.setState({
        contryList: filterData,
      });
    } else {
      this.setState({
        contryList: defaultCountryList,
      });
    }
  };
  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    const apiUrl = `/profile?param=profile&uid=${usid}`;
    const { data } = await serviceProvider.get(apiUrl, true, {});
    this.setState({ profileData: data });
    this.setState({ billCountry: data[0].country_id });
  }

  // HandleValueOnChange function for payment page input page change

  render() {
    if (this.state.redirect) {
      return <Redirect to="/payment" />;
    }
    const user = JSON.parse(localStorage.getItem("userData"));
    const billingData = JSON.parse(localStorage.getItem("billingData"));
    return (
      <>
        <Helmet>
          <title>My Cart | SmarterChains</title>
          <meta name="description" content="My Cart" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
        <BreadcrumbsItem to="/cart">My Cart</BreadcrumbsItem>
        <CartBillView
          HandleBillDdlValueOnClick={this.HandleBillDdlValueOnClick}
          HandleBillDdlOnClick={this.HandleBillDdlOnClick}
          billDdl={this.state.billDdl}
          billCountry={this.state.billCountry}
          profileData={this.state.profileData}
          HandleOnSearch={this.HandleOnSearch}
          RedirectClick={this.RedirectClick}
          contryList={this.state.contryList}
          redirect={this.state.redirect}
          paidItem={this.state.paidItem}
          {...this.props}
        />
      </>
    );
  }
}
