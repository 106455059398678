/** imported components

  * makeStyles for ===> styling material-ui component.
  * Link for ==> React hooks for form validation.
  * Animated for ==> for dynamic breadcrumb.

**/
import React from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";

// start TrainingCardView
export const TrainingCardView = (props) => {
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h == 0 ? "0h " : h + "h ";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    var sDisplay = "";
    return hDisplay + mDisplay + sDisplay;
  };
  // document.querySelectorAll(".training_card_wrapp").addEventListener("touchstart", myFunction);
  // function myFunction() {
  //   document.querySelectorAll(".trn_avail_training_popwrap").classList.add('active');
  // }

  return (
    <div
      className="master_wrapper"
      onMouseEnter={props.HandleHoverState}
      onMouseLeave={props.HandleHoverState}
    >
      {props.data.isAvailable ? (
        // Training card
        <div className="training_card_wrapp">
          <div className="trn_img_wrapper">
            <img src={props.data.cardImg} className="course_symb" alt="true" />
            {props.data.isImd == 1 ? (
              <span className="trn_imd_logo">
                <img
                  src={
                    require("../../assets/img/training-card/imd-partnership-logo.svg")
                      .default
                  }
                  className="imd_partnership_logo"
                  alt="true"
                />
              </span>
            ) : (
              ""
            )}
          </div>
          <p>{props.data.cardTitle && props.data.cardTitle}</p>
          <ul>
            <li>
              {props.data.cardDuration && secondsToHms(props.data.cardDuration)}
            </li>
            <li>
              {props.data.cardStatus && (
                <span
                  className={
                    props.data.cardStatus.toLowerCase() === "completed"
                      ? "completed"
                      : props.data.cardStatus.toLowerCase() === "quiz pending"
                      ? "quizpending"
                      : props.data.cardStatus.toLowerCase() === "in progress" &&
                        "inprogress"
                  }
                >
                  {props.data.cardStatus}
                </span>
              )}
            </li>
          </ul>

          <div className="trn_avail_training_poupwrapper">
            <Animated
              animationIn="zoomIn"
              animationOut="zoomOut"
              animationInDuration={400}
              animationOutDuration={600}
              isVisible={props.isHover}
            >
              <div className="trn_avail_training_popwrap">
                <h3>{props.data.cardTitle}</h3>
                <p>{props.data.availableText}</p>
                {props.data.availableTechnology && (
                  <div>
                    <h4>Technologies you will learn about:</h4>
                    <p className="italic">{props.data.availableTechnology}</p>
                  </div>
                )}
                <Link
                  to={
						props.data.isFirstTraining == 1
						? "/course-walkthrough"
						: `training-courses/${props.data.cardSlug}`
					}
                  className="trn_button"
                >
                  Learn more 
                </Link>
              </div>
            </Animated>
          </div>
        </div>
      ) : (
        <Link
          to={
				props.data.isFirstTraining == 1
				? "/course-walkthrough"
				: `training-courses/${props.data.cardSlug}`
			}
          className="training_card_link"
        >
          {/* Training card */}
          <div className="training_card_wrapp">
            <div className="trn_img_wrapper">
              <img
                src={props.data.cardImg}
                className="course_symb"
                alt="true"
              />
              {props.data.isImd == 1 ? (
                <span className="trn_imd_logo">
                  <img
                    src={
                      require("../../assets/img/training-card/imd-partnership-logo.svg")
                        .default
                    }
                    className="imd_partnership_logo"
                    alt="true"
                  />
                </span>
              ) : (
                ""
              )}
            </div>
            <p>{props.data.cardTitle && props.data.cardTitle}</p>
            <ul>
              <li>
                {props.data.cardDuration &&
                  secondsToHms(props.data.cardDuration)}
              </li>
              <li>
                {props.data.cardStatus && (
                  <span
                    className={
                      props.data.cardStatus.toLowerCase() === "completed"
                        ? "completed"
                        : props.data.cardStatus.toLowerCase() === "quiz pending"
                        ? "quizpending"
                        : props.data.cardStatus.toLowerCase() ===
                            "in progress" && "inprogress"
                    }
                  >
                    {props.data.cardStatus}
                  </span>
                )}
                {props.data.cardStatus == "" && (
                  <span className="todo">To Do</span>
                )}
              </li>
            </ul>

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 2,
              }}
            >
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={400}
                animationOutDuration={400}
                isVisible={props.isHover}
              >
                <div className="trn_hover_wrap animate__bounce">
                  <span className="trn_hover_link">Go to IR4.0 training</span>
                </div>
              </Animated>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};
