/*
plugin materialui used
*/


import React from 'react';
import serviceProvider from '../../services/axios';
import { useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Grid, createTheme } from '@material-ui/core';
import { CirImg } from './svg.js'
export const WhitePaperView = (props) => {
    const history = useHistory();
	const user = JSON.parse(localStorage.getItem("userData"));
	const uid = user ? user.uid : 0;
	
    const downloadHistry = () => {
		serviceProvider.post("/itemdetails?type=whitepaper", JSON.stringify({ uid: uid }),true).then((response) => {});
	}

    React.useEffect(() => {
        let svgEle = document.querySelectorAll('.wrapper-svg-div svg .clickable-path');
        svgEle.forEach((item) => {
            item.onclick = (e) => {
                let itemId = e.currentTarget.getAttribute('data-id');
                switch (itemId) {
                    case "pathExcute":
                        history.push('/framework')
                        break;
                    case 'pathDefine':
                        history.push('/framework')
                        break;
                    case 'pathPrepare':
                        history.push('/framework')
                        break;
                    case 'pathEducate':
                        history.push('/framework')
                        break;
                    case 'frameWorkBtn':
                        history.push('/framework')
                        break;
                    case 'btnDimention10':
                        history.push('/industry-40-dimensions')
                        break;
                    default: return 0;
                }
            }
        })

    }, [])
    return (
        <>
            {/*---------------------- breradcrumbitem started -------------------------*/}
            <BreadcrumbsItem to="/" >Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/learning-center">Resource Center</BreadcrumbsItem>
            <BreadcrumbsItem to="/whitepaper" >Industry 4.0 Methodology</BreadcrumbsItem>
            {/*---------------------- breradcrumbitem ended -------------------------*/}
            {/* white-paper-page-container start */}
            <div className='white-page-container'>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={7}>
                        <div className='white-page-title-text-container'>
                            <h1 className='white-page-h1'>
                                An Industry 4.0 Framework to create Smart and Sustainable Factories
                            </h1>
                            <p className='white-page-p'>
                                 The SmarterChains white paper, sponsored by EY, presents how the manufacturing digital transformation challenges can be addressed through a standard, award-winning methodology to create smart and sustainable factories through a four-step methodological approach.​
                            </p>
                        </div>
                    </Grid>
                    {/* grid item 1 ended */}
                    <Grid item xs={12} sm={12} md={1}></Grid>
                    {/* grid item 2 ended */}
                    <Grid item xs={12} sm={12} md={4}>
                        <div className='download-white-paper-box'>
                            <div className="img-box">
                                <div className="ey-logo">
                                    <img className="logo-img" src={require('../../assets/img/white-paper/ey-logo.svg').default} alt="img" />
                                </div>
                                <div className="download-img">
                                    <img className="cir-download-img" src={require('../../assets/img/white-paper/download-pdf.svg').default} alt="img" />
                                </div>
                            </div>
                            <p>
                                Get the white paper now!
                            </p>
                            <div className="btn-pdf-div">
                                <a className="btn-download" onClick={() => downloadHistry()} href={require('./White paper-SC-10.pdf').default} download>
                                    <span>Download</span>
                                    <img src={require('../../assets/img/white-paper/arrow-download.svg').default} />
                                </a>
                            </div>
                        </div>
                    </Grid>
                    {/* grid item 3 ended */}
                    <Grid item xs={12} sm={12} md={4}>
                        <div className='white-list-div'>
                            <h2 className='list-title'>Discover how to create smart and sustaibable factories</h2>
                            <ul className='white-ul'>
                                <li>
                                    <span className='before-li'>
                                        <img src={require('../../assets/img/white-paper/li-list.svg').default} />
                                    </span>
                                    <div>
                                        <p>
                                            Which are the organizational advantages the digital transformation journeys can unlock?
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <span className='before-li'>
                                        <img src={require('../../assets/img/white-paper/li-list.svg').default} />
                                    </span>
                                    <div>
                                        <p>
                                            Which are the major challenges manufacturers face in adopting Industry 4.0?
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <span className='before-li'>
                                        <img src={require('../../assets/img/white-paper/li-list.svg').default} />
                                    </span>
                                    <div>
                                        <p>
                                            Is it possible to create a continuous educational paradigm to scale learnings?
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <span className='before-li'>
                                        <img src={require('../../assets/img/white-paper/li-list.svg').default} />
                                    </span>
                                    <div>
                                        <p>
                                            How can data and technology leverage an agile, integrated manufacturing ecosystem?
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <span className='before-li'>
                                        <img src={require('../../assets/img/white-paper/li-list.svg').default} />
                                    </span>
                                    <div>
                                        <p>
                                            How can we democratize Industry 4.0 to be accessible for all companies?
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    {/* grid item 4 ended */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className='wrapper-svg-div' dangerouslySetInnerHTML={{ __html: CirImg }}>
                        </div>
                    </Grid>
                    {/* grid item 5 ended */}
                </Grid>
                {/*-------------- grid container ended ----------------*/}
            </div>
            {/* white-paper-page-container end */}

        </>
    )
}