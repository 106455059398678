export const unassignImage=`<svg width="268" height="200" viewBox="0 0 268 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M268 175.74H0V175.874H268V175.74Z" fill="#E6E6E6"/>
<path d="M203.323 180.686H184.885V180.82H203.323V180.686Z" fill="#E6E6E6"/>
<path d="M225.006 180.295H214.629V180.429H225.006V180.295Z" fill="#E6E6E6"/>
<path d="M132.58 180.631H118.816V180.765H132.58V180.631Z" fill="#E6E6E6"/>
<path d="M142.515 182.529H133.114V182.663H142.515V182.529Z" fill="#E6E6E6"/>
<path d="M77.545 179.279H49.1423V179.413H77.545V179.279Z" fill="#E6E6E6"/>
<path d="M127.028 151.798H23.5318C22.721 151.797 21.9439 151.474 21.3711 150.899C20.7983 150.324 20.4766 149.545 20.4766 148.734V3.03812C20.4836 2.23083 20.8085 1.45893 21.3805 0.890081C21.9525 0.321234 22.7256 0.00138293 23.5318 0H127.028C127.84 0 128.618 0.322914 129.192 0.897705C129.766 1.4725 130.089 2.25208 130.089 3.06495V148.734C130.089 149.546 129.766 150.326 129.192 150.901C128.618 151.476 127.84 151.798 127.028 151.798ZM23.5318 0.107354C22.7565 0.108776 22.0135 0.418179 21.4658 0.967649C20.9182 1.51712 20.6106 2.26176 20.6106 3.03812V148.734C20.6106 149.51 20.9182 150.255 21.4658 150.804C22.0135 151.353 22.7565 151.663 23.5318 151.664H127.028C127.804 151.663 128.547 151.354 129.096 150.804C129.644 150.255 129.953 149.51 129.955 148.734V3.03812C129.953 2.26127 129.644 1.51664 129.096 0.967321C128.547 0.418004 127.804 0.108773 127.028 0.107354H23.5318Z" fill="transparent"/>
<path d="M242.98 151.798H139.478C138.667 151.797 137.889 151.474 137.315 150.899C136.742 150.325 136.419 149.546 136.417 148.734V3.03812C136.426 2.23034 136.752 1.45844 137.325 0.889746C137.898 0.321047 138.671 0.00136784 139.478 0H242.98C243.785 0.00279362 244.557 0.323269 245.127 0.891965C245.698 1.46066 246.022 2.23176 246.029 3.03812V148.734C246.03 149.545 245.709 150.322 245.137 150.897C244.565 151.471 243.79 151.796 242.98 151.798ZM139.478 0.107354C138.702 0.108773 137.959 0.418004 137.41 0.967321C136.862 1.51664 136.553 2.26127 136.551 3.03812V148.734C136.553 149.51 136.862 150.255 137.41 150.804C137.959 151.354 138.702 151.663 139.478 151.664H242.98C243.755 151.663 244.499 151.354 245.047 150.804C245.596 150.255 245.905 149.51 245.906 148.734V3.03812C245.905 2.26127 245.596 1.51664 245.047 0.967321C244.499 0.418004 243.755 0.108773 242.98 0.107354H139.478Z" fill="transparent"/>
<path d="M197.083 113.791H189.622C188.693 113.791 187.939 114.546 187.939 115.476V127.833C187.939 128.764 188.693 129.518 189.622 129.518H197.083C198.013 129.518 198.766 128.764 198.766 127.833V115.476C198.766 114.546 198.013 113.791 197.083 113.791Z" fill="#F0F0F0"/>
<path d="M217.621 117.906H211.098C210.887 117.906 210.717 118.077 210.717 118.287V118.717C210.717 118.927 210.887 119.098 211.098 119.098H217.621C217.831 119.098 218.001 118.927 218.001 118.717V118.287C218.001 118.077 217.831 117.906 217.621 117.906Z" fill="#F0F0F0"/>
<path d="M170.675 120.768C170.675 120.768 170.943 129.517 181.175 129.517C191.407 129.517 191.681 120.768 191.681 120.768H170.675Z" fill="#E6E6E6"/>
<path d="M145.218 109.205H160.703V106.145H145.218V109.205Z" fill="#E6E6E6"/>
<path d="M237.689 106.158H160.703V109.218H237.689V106.158Z" fill="#F0F0F0"/>
<path d="M145.218 132.588H160.703V129.528H145.218V132.588Z" fill="#E6E6E6"/>
<path d="M237.689 129.516H160.703V132.575H237.689V129.516Z" fill="#F0F0F0"/>
<path d="M150.805 109.205H147.948V175.722H150.805V109.205Z" fill="#E6E6E6"/>
<path d="M163.56 109.205H160.703V175.722H163.56V109.205Z" fill="#F0F0F0"/>
<path d="M227.784 109.205H224.927V175.722H227.784V109.205Z" fill="#E6E6E6"/>
<path d="M237.69 109.205H234.833V175.722H237.69V109.205Z" fill="#F0F0F0"/>
<path d="M108.457 171.701H36.2468V175.732H108.457V171.701Z" fill="#E6E6E6"/>
<path d="M85.4691 120.871H73.8647C73.487 120.869 73.1252 120.718 72.8581 120.451C72.591 120.184 72.4404 119.821 72.439 119.443C72.439 119.064 72.589 118.7 72.8562 118.431C73.1235 118.163 73.4861 118.011 73.8647 118.01H85.4691C85.8478 118.011 86.2104 118.163 86.4776 118.431C86.7448 118.7 86.8949 119.064 86.8949 119.443C86.8935 119.821 86.7428 120.184 86.4757 120.451C86.2087 120.718 85.8468 120.869 85.4691 120.871Z" fill="#F0F0F0"/>
<path d="M85.4691 96.2086H73.8647C73.4861 96.2072 73.1235 96.0556 72.8562 95.787C72.589 95.5184 72.439 95.1546 72.439 94.7755C72.4404 94.3972 72.591 94.0349 72.8581 93.7674C73.1252 93.4999 73.487 93.3491 73.8647 93.3477H85.4691C85.8468 93.3491 86.2087 93.4999 86.4757 93.7674C86.7428 94.0349 86.8935 94.3972 86.8949 94.7755C86.8949 95.1546 86.7448 95.5184 86.4776 95.787C86.2104 96.0556 85.8478 96.2072 85.4691 96.2086Z" fill="#F0F0F0"/>
<path d="M85.4691 145.525H73.8647C73.487 145.524 73.1252 145.373 72.8581 145.105C72.591 144.838 72.4404 144.475 72.439 144.097C72.439 143.718 72.589 143.354 72.8562 143.086C73.1235 142.817 73.4861 142.665 73.8647 142.664H85.4691C85.8478 142.665 86.2104 142.817 86.4776 143.086C86.7448 143.354 86.8949 143.718 86.8949 144.097C86.8935 144.475 86.7428 144.838 86.4757 145.105C86.2087 145.373 85.8468 145.524 85.4691 145.525Z" fill="#F0F0F0"/>
<path d="M134.009 200C191.405 200 237.934 197.28 237.934 193.924C237.934 190.568 191.405 187.848 134.009 187.848C76.6123 187.848 30.0835 190.568 30.0835 193.924C30.0835 197.28 76.6123 200 134.009 200Z" fill="#F0F0F0"/>
<path d="M69.881 74.7493C69.4093 75.8228 68.8894 76.9823 68.4338 78.1148C67.9782 79.2474 67.5065 80.4122 67.0938 81.5716C66.2469 83.8319 65.5679 86.1517 65.0623 88.5121L64.9069 89.3709L64.8533 89.693C64.8533 89.7842 64.8533 89.9023 64.8211 90.0097C64.8007 90.5323 64.8115 91.0557 64.8533 91.577C64.9551 92.7096 65.1374 93.9227 65.3893 95.1358C65.8395 97.5674 66.413 100.074 66.9973 102.543L64.2476 103.617C62.9658 101.277 61.8742 98.8374 60.9834 96.3221C60.514 95.0129 60.1344 93.6733 59.847 92.3124C59.6861 91.5595 59.5857 90.7948 59.5469 90.0258C59.5469 89.8111 59.5469 89.6071 59.5469 89.3763C59.5469 89.1455 59.5469 88.8878 59.5844 88.7375L59.6916 87.664C59.8517 86.2814 60.097 84.91 60.4259 83.5577C60.7261 82.2158 61.112 80.9114 61.4979 79.6232C61.8838 78.3349 62.3662 77.0681 62.8754 75.8228C63.1274 75.2002 63.4114 74.5829 63.658 73.9656C63.9046 73.3483 64.194 72.7579 64.537 72.0977L69.881 74.7493Z" fill="#FDA982"/>
<path d="M66.8507 101.807L68.9411 103.852L64.3797 105.999C64.3797 105.999 63.2756 104.061 64.042 102.311L66.8507 101.807Z" fill="#FDA982"/>
<path d="M70.626 106.911L66.7722 108.489L64.3816 105.993L68.9376 103.867L70.626 106.911Z" fill="#FDA982"/>
<path d="M192.898 46.5522C187.935 36.2437 179.201 28.2432 168.507 24.2092C157.813 20.1752 145.978 20.4172 135.457 24.8849C124.936 29.3526 116.536 37.7035 111.997 48.2062C107.458 58.709 107.129 70.5581 111.078 81.2973C107.497 82.5533 103.96 83.9006 100.444 85.2908C95.3731 87.3055 90.349 89.4097 85.3713 91.6032C80.3973 93.8094 75.4446 96.0584 70.5402 98.4095C68.08 99.5635 65.6519 100.782 63.2024 101.963C61.9281 102.511 60.7736 103.305 59.8041 104.298C59.2795 104.848 58.8175 105.455 58.4266 106.107C57.9496 106.878 57.5884 107.715 57.3546 108.592C56.9513 110.172 57.1257 111.844 57.8463 113.306C58.5669 114.768 59.7861 115.923 61.2835 116.563C62.115 116.912 62.9923 117.14 63.8885 117.239C64.6454 117.325 65.4095 117.325 66.1665 117.239C67.542 117.072 68.8713 116.636 70.0793 115.956C72.5073 114.727 74.9515 113.541 77.3635 112.28C82.1875 109.816 87.0115 107.245 91.7765 104.636C96.5416 102.027 101.312 99.2952 105.964 96.4932C109.18 94.5447 112.429 92.5533 115.612 90.4707C121.726 100.136 131.319 107.076 142.403 109.851C153.486 112.627 165.211 111.025 175.148 105.379C185.085 99.7334 192.473 90.4753 195.779 79.5235C199.085 68.5718 198.058 56.7659 192.909 46.5522H192.898ZM168.532 97.8298C162.303 100.909 155.302 102.069 148.414 101.164C141.527 100.259 135.062 97.3284 129.838 92.7438C124.613 88.1593 120.863 82.1263 119.062 75.4076C117.261 68.689 117.49 61.5864 119.72 54.998C121.949 48.4096 126.08 42.6312 131.589 38.3936C137.098 34.1559 143.738 31.6492 150.669 31.1904C157.6 30.7317 164.512 32.3415 170.53 35.8163C176.548 39.2911 181.402 44.4749 184.478 50.7121C186.521 54.8544 187.729 59.359 188.033 63.9688C188.337 68.5787 187.732 73.2034 186.251 77.5788C184.77 81.9542 182.443 85.9946 179.403 89.4693C176.362 92.9441 172.669 95.785 168.532 97.8298Z" fill="#1487E3"/>
<path opacity="0.3" d="M192.898 46.5522C187.935 36.2437 179.201 28.2432 168.507 24.2092C157.813 20.1752 145.978 20.4172 135.457 24.8849C124.936 29.3526 116.536 37.7035 111.997 48.2062C107.458 58.709 107.129 70.5581 111.078 81.2973C107.497 82.5533 103.96 83.9006 100.444 85.2908C95.3731 87.3055 90.349 89.4097 85.3713 91.6032C80.3973 93.8094 75.4446 96.0584 70.5402 98.4095C68.08 99.5635 65.6519 100.782 63.2024 101.963C61.9281 102.511 60.7736 103.305 59.8041 104.298C59.2795 104.848 58.8175 105.455 58.4266 106.107C57.9496 106.878 57.5884 107.715 57.3546 108.592C56.9513 110.172 57.1257 111.844 57.8463 113.306C58.5669 114.768 59.7861 115.923 61.2835 116.563C62.115 116.912 62.9923 117.14 63.8885 117.239C64.6454 117.325 65.4095 117.325 66.1665 117.239C67.542 117.072 68.8713 116.636 70.0793 115.956C72.5073 114.727 74.9515 113.541 77.3635 112.28C82.1875 109.816 87.0115 107.245 91.7765 104.636C96.5416 102.027 101.312 99.2952 105.964 96.4932C109.18 94.5447 112.429 92.5533 115.612 90.4707C121.726 100.136 131.319 107.076 142.403 109.851C153.486 112.627 165.211 111.025 175.148 105.379C185.085 99.7334 192.473 90.4753 195.779 79.5235C199.085 68.5718 198.058 56.7659 192.909 46.5522H192.898ZM168.532 97.8298C162.303 100.909 155.302 102.069 148.414 101.164C141.527 100.259 135.062 97.3284 129.838 92.7438C124.613 88.1593 120.863 82.1263 119.062 75.4076C117.261 68.689 117.49 61.5864 119.72 54.998C121.949 48.4096 126.08 42.6312 131.589 38.3936C137.098 34.1559 143.738 31.6492 150.669 31.1904C157.6 30.7317 164.512 32.3415 170.53 35.8163C176.548 39.2911 181.402 44.4749 184.478 50.7121C186.521 54.8544 187.729 59.359 188.033 63.9688C188.337 68.5787 187.732 73.2034 186.251 77.5788C184.77 81.9542 182.443 85.9946 179.403 89.4693C176.362 92.9441 172.669 95.785 168.532 97.8298Z" fill="#4D4D4F"/>
<path d="M84.7021 55.5626C84.7021 55.8793 84.8683 56.137 85.0773 56.137C85.2864 56.137 85.4525 55.8793 85.4525 55.5626C85.4525 55.2459 85.2864 54.9883 85.0773 54.9883C84.8683 54.9883 84.7021 55.2674 84.7021 55.5626Z" fill="#0D2C54"/>
<path d="M84.8464 56.1367C85.2258 57.1089 85.7362 58.0245 86.3633 58.8581C86.1465 59.0135 85.8984 59.1194 85.6363 59.1686C85.3743 59.2177 85.1047 59.2089 84.8464 59.1426V56.1367Z" fill="#FF6666"/>
<path d="M83.5924 53.8022C83.5625 53.8022 83.533 53.7954 83.506 53.7824C83.4791 53.7694 83.4555 53.7505 83.4369 53.727C83.409 53.686 83.3979 53.6359 83.4058 53.5869C83.4138 53.538 83.4403 53.4939 83.4798 53.464C83.7159 53.2847 83.9917 53.1652 84.2838 53.1157C84.5759 53.0661 84.8756 53.0881 85.1575 53.1795C85.1828 53.1864 85.2064 53.1986 85.2267 53.2152C85.247 53.2318 85.2637 53.2525 85.2755 53.2759C85.2874 53.2993 85.2942 53.325 85.2955 53.3512C85.2969 53.3775 85.2927 53.4037 85.2833 53.4282C85.2738 53.4527 85.2594 53.475 85.2409 53.4936C85.2223 53.5122 85.2001 53.5266 85.1756 53.5361C85.1511 53.5455 85.1249 53.5497 85.0987 53.5484C85.0725 53.5471 85.0469 53.5403 85.0235 53.5284C84.7996 53.4596 84.5625 53.4457 84.3321 53.4877C84.1018 53.5298 83.8848 53.6265 83.6996 53.77C83.6678 53.7911 83.6305 53.8023 83.5924 53.8022Z" fill="#0D2C54"/>
<path d="M73.8666 57.957C74.4026 60.8395 74.9922 66.1267 72.9768 68.0537C72.9768 68.0537 73.7647 70.9791 79.1194 70.9791C85.0154 70.9791 81.928 68.0537 81.928 68.0537C78.712 67.2807 78.8031 64.8975 79.3606 62.6538L73.8666 57.957Z" fill="#FDA982"/>
<path d="M70.5753 69.576C71.2131 68.583 70.1411 66.1729 70.1411 66.1729C70.1411 66.1729 79.6176 63.7842 83.4929 67.4504C84.2969 68.2072 83.4071 69.3237 83.4071 69.3237L70.5753 69.576Z" fill="#1487E3"/>
<path opacity="0.1" d="M70.5753 69.576C71.2131 68.583 70.1411 66.1729 70.1411 66.1729C70.1411 66.1729 79.6176 63.7842 83.4929 67.4504C84.2969 68.2072 83.4071 69.3237 83.4071 69.3237L70.5753 69.576Z" fill="#4D4D4F"/>
<path d="M54.8952 179.087C54.3967 179.087 53.5981 178.255 53.3622 177.943C53.3521 177.928 53.3467 177.91 53.3467 177.892C53.3467 177.874 53.3521 177.856 53.3622 177.841C53.3709 177.826 53.3834 177.814 53.3985 177.805C53.4136 177.797 53.4307 177.793 53.448 177.793C53.507 177.793 54.8202 177.911 55.1793 178.378C55.2183 178.427 55.2462 178.485 55.261 178.546C55.2758 178.607 55.2772 178.671 55.265 178.732C55.2604 178.812 55.2311 178.889 55.1811 178.951C55.1312 179.014 55.0631 179.059 54.9863 179.081L54.8952 179.087ZM53.6517 178.013C54.043 178.464 54.6701 178.974 54.9381 178.909C54.9381 178.909 55.0453 178.883 55.0774 178.711C55.0845 178.675 55.0833 178.638 55.074 178.603C55.0648 178.567 55.0476 178.535 55.0238 178.507C54.6268 178.206 54.149 178.03 53.6517 178.002V178.013Z" fill="#1487E3"/>
<path d="M53.707 178.021C53.6108 178.031 53.5138 178.031 53.4176 178.021C53.4006 178.02 53.3844 178.013 53.3711 178.003C53.3577 177.992 53.3478 177.978 53.3425 177.962C53.335 177.947 53.3311 177.93 53.3311 177.914C53.3311 177.897 53.335 177.88 53.3425 177.865C53.38 177.833 54.168 177.012 54.7629 177.012C54.8341 177.01 54.9048 177.024 54.9704 177.052C55.036 177.079 55.095 177.121 55.1435 177.173C55.1968 177.218 55.2334 177.279 55.247 177.348C55.2607 177.416 55.2506 177.487 55.2185 177.549C55.0631 177.849 54.243 178.021 53.707 178.021ZM53.6373 177.833C54.1733 177.833 54.9505 177.667 55.0792 177.431C55.0792 177.431 55.1167 177.361 55.0309 177.275C54.9997 177.241 54.9615 177.214 54.9189 177.197C54.8763 177.179 54.8304 177.171 54.7844 177.173C54.345 177.274 53.9456 177.504 53.6373 177.833Z" fill="#1487E3"/>
<path d="M90.2635 191.278C89.8572 191.275 89.452 191.237 89.0521 191.165C89.0323 191.161 89.0144 191.151 89.0016 191.135C88.9889 191.119 88.9821 191.099 88.9824 191.079C88.9794 191.061 88.9819 191.042 88.9895 191.024C88.9971 191.007 89.0096 190.993 89.0253 190.983C89.0843 190.95 90.4564 190.161 91.1157 190.344C91.1769 190.358 91.2345 190.384 91.2852 190.421C91.3359 190.458 91.3785 190.504 91.4105 190.558C91.4525 190.618 91.475 190.689 91.475 190.762C91.475 190.835 91.4525 190.907 91.4105 190.966C91.2551 191.208 90.7673 191.278 90.2635 191.278ZM89.3576 191.025C90.0866 191.133 91.0836 191.127 91.2658 190.87C91.2926 190.838 91.314 190.779 91.2658 190.666C91.2249 190.599 91.1595 190.551 91.0836 190.532C90.6655 190.424 89.8347 190.779 89.3576 191.025Z" fill="#1487E3"/>
<path d="M89.0744 191.176C89.0569 191.181 89.0383 191.181 89.0208 191.176C89.006 191.166 88.9944 191.152 88.9877 191.136C88.9809 191.12 88.9794 191.102 88.9833 191.084C89.0196 190.526 89.2338 189.993 89.5943 189.565C89.6673 189.502 89.7529 189.456 89.8454 189.429C89.9379 189.402 90.0351 189.395 90.1303 189.41C90.3876 189.41 90.4627 189.565 90.4787 189.667C90.5484 190.113 89.6104 190.966 89.1334 191.181L89.0744 191.176ZM90.0339 189.565C89.9159 189.564 89.8017 189.606 89.7123 189.683C89.4092 190.022 89.2215 190.449 89.1763 190.902C89.6587 190.607 90.3019 189.941 90.2483 189.673C90.2483 189.673 90.2483 189.581 90.066 189.565H90.0339Z" fill="#1487E3"/>
<path d="M84.7412 191.087H88.6808L89.0721 181.957H85.1325L84.7412 191.087Z" fill="#FDA982"/>
<path d="M49.6646 175.399L52.9556 177.557L60.2237 171.411L56.9274 169.248L49.6646 175.399Z" fill="#FDA982"/>
<path d="M53.4966 177.367L50.0769 174.559C50.0165 174.51 49.9407 174.482 49.8625 174.482C49.7843 174.482 49.7085 174.51 49.6481 174.559L46.8287 176.776C46.7606 176.83 46.7056 176.899 46.6678 176.978C46.63 177.057 46.6104 177.143 46.6104 177.23C46.6104 177.317 46.63 177.403 46.6678 177.482C46.7056 177.56 46.7606 177.629 46.8287 177.683C48.0401 178.644 48.6779 179.052 50.1841 180.287C51.1114 181.043 53.4001 183.362 54.6918 184.409C55.9836 185.456 57.1682 184.329 56.7394 183.786C54.8151 181.344 54.0594 179.256 53.8396 177.962C53.8016 177.729 53.6796 177.517 53.4966 177.367Z" fill="#0D2C54"/>
<path d="M88.6648 190.629H84.3768C84.2986 190.629 84.2228 190.656 84.1616 190.705C84.1003 190.753 84.0572 190.821 84.0391 190.897L83.2619 194.402C83.2424 194.487 83.2424 194.576 83.262 194.661C83.2816 194.745 83.3202 194.825 83.375 194.893C83.4297 194.96 83.4992 195.015 83.578 195.052C83.6569 195.089 83.7431 195.107 83.8301 195.106C85.3791 195.079 87.6196 194.987 89.5707 194.987C91.8487 194.987 93.8211 195.111 96.4904 195.111C98.0984 195.111 98.5594 193.474 97.8787 193.329C94.802 192.653 92.2828 192.583 89.6243 190.935C89.339 190.746 89.0068 190.64 88.6648 190.629Z" fill="#0D2C54"/>
<path d="M65.2169 69.235C61.8026 70.8453 59.8569 81.6344 59.8569 81.6344L68.2025 84.9677C69.424 81.9806 70.4115 78.9028 71.1558 75.7621C72.3029 70.7917 68.7117 67.571 65.2169 69.235Z" fill="#0D2C54"/>
<path d="M69.6484 75.315C67.5848 77.4084 66.6415 81.5094 66.2288 84.1771L68.228 84.9769C69.4149 82.0746 70.3824 79.0871 71.1224 76.0397C70.9456 74.9017 70.506 74.4455 69.6484 75.315Z" fill="#4D4D4F"/>
<path d="M87.9846 69.1562C87.9846 69.1562 92.1761 73.9012 88.2205 103.241H67.161C67.0163 100.02 69.0477 84.2126 65.9282 68.9576C68.2556 68.5057 70.6101 68.208 72.9766 68.0665C75.9577 67.8893 78.9467 67.8893 81.9278 68.0665C83.9718 68.2725 85.9968 68.6368 87.9846 69.1562Z" fill="#0D2C54"/>
<path d="M89.9055 80.5406L86.3572 74.416C85.6723 77.2736 85.3926 80.2136 85.5264 83.1493C85.6229 85.2534 87.5471 93.0473 89.2784 94.1852C89.7378 89.652 89.9472 85.0969 89.9055 80.5406Z" fill="#4D4D4F"/>
<path d="M90.3993 73.3477C90.9353 75.9564 91.4713 78.6295 92.0448 81.2489C92.6183 83.8683 93.2133 86.4985 93.9047 89.0374C94.0816 89.6654 94.2477 90.3096 94.4407 90.9108L94.5854 91.3724L94.6551 91.6032V91.5764C94.6176 91.528 94.5747 91.5227 94.6069 91.5764C94.8271 91.8173 95.1088 91.9936 95.4216 92.0863C95.9283 92.2751 96.4501 92.4206 96.9813 92.5211C99.5155 92.9233 102.081 93.0904 104.646 93.0203L105.155 95.9403C104.437 96.1711 103.767 96.3375 103.06 96.5039C102.352 96.6703 101.655 96.8099 100.942 96.9172C99.4675 97.1631 97.972 97.2638 96.4775 97.2178C95.6468 97.1954 94.8206 97.0875 94.0119 96.8957C92.9837 96.6705 92.0175 96.2215 91.1818 95.5807C90.636 95.1393 90.1856 94.5915 89.8579 93.9703C89.7721 93.8093 89.6971 93.6268 89.6274 93.4711L89.5309 93.2189L89.3326 92.6821C89.0593 92.0004 88.8341 91.3294 88.5876 90.6585C87.6817 87.9746 86.926 85.2639 86.2613 82.5586C85.5967 79.8533 85.0232 77.1534 84.5461 74.3783L90.3993 73.3477Z" fill="#FDA982"/>
<path d="M82.733 72.0373C81.6234 75.6391 86.1205 86.3798 86.1205 86.3798L95.077 84.1254C95.077 84.1254 94.734 78.543 92.6918 74.1146C89.6152 67.4372 84.0622 67.7164 82.733 72.0373Z" fill="#0D2C54"/>
<path d="M104.581 93.0209L109.041 92.9082L106.897 97.5566C106.897 97.5566 105.08 97.7982 103.59 95.3451L102.019 93.4664L103.825 93.1068C104.074 93.0544 104.327 93.0256 104.581 93.0209Z" fill="#FDA982"/>
<path d="M111.853 93.7724L109.301 97.6264L106.895 97.5566L109.039 92.9082L111.853 93.7724Z" fill="#FDA982"/>
<path d="M89.0796 181.957L88.8759 186.664H84.9363L85.14 181.957H89.0796Z" fill="#DC8056"/>
<path d="M56.9217 169.248L60.2181 171.411L56.4661 174.578L53.175 172.42L56.9217 169.248Z" fill="#DC8056"/>
<path d="M74.1202 54.8912C74.356 58.7935 74.2971 61.0963 76.2642 63.0984C79.2229 66.1204 84.0416 64.333 84.9742 60.4146C85.8157 56.9041 85.2904 51.107 81.4741 49.5503C80.6321 49.21 79.7174 49.0894 78.8161 49.2C77.9148 49.3106 77.0563 49.6487 76.3211 50.1826C75.586 50.7164 74.9984 51.4286 74.6134 52.2522C74.2285 53.0758 74.0587 53.9839 74.1202 54.8912Z" fill="#FDA982"/>
<path d="M69.5228 54.2045C70.4929 57.7633 72.7763 61.8642 75.7297 62.1273C79.3959 62.4493 81.0521 58.6544 81.5345 54.736C83.7321 53.2276 82.5797 51.569 84.7827 51.3597C87.6128 51.0913 86.7069 45.9061 82.9817 45.0741C82.9817 45.0741 84.2306 47.409 81.6096 46.2281C79.1169 45.0418 76.3477 44.5611 73.6017 44.8379C73.6017 44.8379 76.9464 46.5126 73.6768 47.2587C70.4072 48.0049 69.4317 50.0231 70.9378 51.2416C70.9378 51.2469 68.8849 51.8642 69.5228 54.2045Z" fill="#0D2C54"/>
<path d="M79.3117 55.5413C79.3837 56.5114 79.7737 57.4304 80.4212 58.1554C81.2788 59.1055 82.1739 58.5151 82.324 57.361C82.4634 56.325 82.1793 54.5805 81.1287 54.0545C80.0782 53.5285 79.242 54.3712 79.3117 55.5413Z" fill="#FDA982"/>
<path d="M80.7489 103.248C80.7489 103.248 84.5866 131.079 81.5636 142.233C76.7717 159.893 58.4513 174.799 58.4513 174.799L52.239 170.719C52.239 170.719 67.842 158.132 69.7501 143.344C71.4653 130.07 67.1613 114.015 67.1613 103.242L80.7489 103.248Z" fill="#1487E3"/>
<path opacity="0.1" d="M80.7489 103.248C80.7489 103.248 84.5866 131.079 81.5636 142.233C76.7717 159.893 58.4513 174.799 58.4513 174.799L52.239 170.719C52.239 170.719 67.842 158.132 69.7501 143.344C71.4653 130.07 67.1613 114.015 67.1613 103.242L80.7489 103.248Z" fill="#4D4D4F"/>
<path d="M82.3446 119.142C81.3229 117.97 80.1982 116.892 78.9839 115.922C79.6378 124.956 80.1738 138.337 79.5788 147.844C80.3788 146.021 81.0452 144.143 81.5728 142.224C83.0468 136.775 82.8913 127.366 82.3446 119.142Z" fill="#1487E3"/>
<path opacity="0.3" d="M82.3446 119.142C81.3229 117.97 80.1982 116.892 78.9839 115.922C79.6378 124.956 80.1738 138.337 79.5788 147.844C80.3788 146.021 81.0452 144.143 81.5728 142.224C83.0468 136.775 82.8913 127.366 82.3446 119.142Z" fill="#4D4D4F"/>
<path d="M60.9634 173.401C60.9902 173.401 58.5568 175.011 58.5568 175.011L51.771 170.556L53.915 168.779L60.9634 173.401Z" fill="#1487E3"/>
<path opacity="0.3" d="M60.9634 173.401C60.9902 173.401 58.5568 175.011 58.5568 175.011L51.771 170.556L53.915 168.779L60.9634 173.401Z" fill="#4D4D4F"/>
<path d="M88.226 103.242C88.226 103.242 94.1541 128.417 94.5722 140.446C95.0439 153.929 90.3432 185.738 90.3432 185.738H83.67C83.67 185.738 82.8499 153.682 82.5551 141.401C82.2281 128.009 74.3811 103.242 74.3811 103.242H88.226Z" fill="#1487E3"/>
<path opacity="0.1" d="M88.226 103.242C88.226 103.242 94.1541 128.417 94.5722 140.446C95.0439 153.929 90.3432 185.738 90.3432 185.738H83.67C83.67 185.738 82.8499 153.682 82.5551 141.401C82.2281 128.009 74.3811 103.242 74.3811 103.242H88.226Z" fill="#4D4D4F"/>
<path d="M91.618 183.076C91.6501 183.076 91.2428 185.846 91.2428 185.846H83.1277L82.908 183.371L91.618 183.076Z" fill="#1487E3"/>
<path opacity="0.3" d="M91.618 183.076C91.6501 183.076 91.2428 185.846 91.2428 185.846H83.1277L82.908 183.371L91.618 183.076Z" fill="#4D4D4F"/>
<path d="M190.809 79.0586C191.264 79.488 191.624 79.853 191.999 80.261C192.374 80.6689 192.728 81.0661 193.071 81.4794C193.768 82.3007 194.422 83.1649 195.054 84.0345C196.317 85.7788 197.441 87.6203 198.415 89.5417L198.613 89.9336C198.71 90.1232 198.791 90.3207 198.854 90.524C198.984 90.9168 199.063 91.3244 199.09 91.7371C199.125 92.4649 199.025 93.1928 198.795 93.8842C198.404 95.0325 197.822 96.1067 197.075 97.0619C195.728 98.7941 194.139 100.323 192.358 101.603L191.023 100.068C191.65 99.3324 192.304 98.5433 192.889 97.7543C193.483 96.9898 194.021 96.1824 194.497 95.3388C194.941 94.6027 195.27 93.8026 195.472 92.9663C195.541 92.6671 195.554 92.3576 195.51 92.0538C195.49 91.9516 195.458 91.8523 195.413 91.7586C195.393 91.7174 195.37 91.6779 195.343 91.6405L195.172 91.3506C194.138 89.6627 193.016 88.0304 191.811 86.4607C191.216 85.6716 190.578 84.9148 189.935 84.1686C189.619 83.7929 189.292 83.4279 188.965 83.0629C188.638 82.6979 188.284 82.3329 188 82.0484L190.809 79.0586Z" fill="#FDA982"/>
<path d="M191.536 99.6275L187.532 98.9727L189.628 102.483C189.628 102.483 192.206 102.392 192.94 100.873L191.536 99.6275Z" fill="#FDA982"/>
<path d="M184.57 100.594L186.478 103.444L189.624 102.483L187.529 98.9727L184.57 100.594Z" fill="#FDA982"/>
<path d="M191.341 191.234C191.069 191.258 190.797 191.184 190.575 191.025C190.508 190.962 190.458 190.882 190.431 190.793C190.404 190.705 190.4 190.611 190.419 190.521C190.423 190.463 190.443 190.407 190.476 190.36C190.509 190.313 190.554 190.276 190.607 190.252C191.073 190.043 192.295 190.827 192.435 190.918C192.451 190.928 192.463 190.942 192.47 190.96C192.476 190.977 192.477 190.996 192.472 191.014C192.47 191.033 192.462 191.05 192.45 191.064C192.437 191.077 192.421 191.086 192.403 191.09C192.055 191.178 191.699 191.226 191.341 191.234ZM190.805 190.402C190.766 190.396 190.726 190.396 190.687 190.402C190.664 190.414 190.644 190.431 190.63 190.453C190.615 190.475 190.607 190.5 190.607 190.526C190.593 190.586 190.594 190.648 190.611 190.708C190.628 190.767 190.66 190.821 190.703 190.864C190.907 191.047 191.432 191.073 192.135 190.934C191.732 190.668 191.28 190.487 190.805 190.402Z" fill="#1487E3"/>
<path d="M192.411 191.093H192.363C191.983 190.873 191.248 190.02 191.339 189.553C191.357 189.479 191.397 189.413 191.455 189.365C191.513 189.316 191.585 189.288 191.661 189.284C191.733 189.274 191.807 189.28 191.877 189.302C191.946 189.325 192.01 189.362 192.063 189.413C192.502 189.8 192.508 190.959 192.508 191.023C192.507 191.04 192.502 191.055 192.493 191.07C192.485 191.084 192.473 191.095 192.46 191.104L192.411 191.093ZM191.725 189.483H191.682C191.564 189.483 191.538 189.547 191.527 189.596C191.473 189.869 191.929 190.524 192.309 190.83C192.319 190.38 192.19 189.938 191.94 189.563C191.908 189.533 191.869 189.511 191.828 189.497C191.786 189.483 191.742 189.478 191.698 189.483H191.725Z" fill="#1487E3"/>
<path d="M173.07 191.235C172.728 191.268 172.385 191.181 172.1 190.989C172.036 190.931 171.986 190.86 171.956 190.779C171.925 190.699 171.914 190.612 171.923 190.527C171.927 190.475 171.943 190.424 171.971 190.38C172 190.336 172.038 190.3 172.084 190.275C172.583 189.996 174.18 190.811 174.362 190.913C174.379 190.924 174.392 190.94 174.401 190.958C174.409 190.976 174.413 190.996 174.41 191.015C174.407 191.034 174.398 191.052 174.385 191.065C174.371 191.079 174.354 191.087 174.335 191.091C173.919 191.179 173.496 191.228 173.07 191.235ZM172.357 190.403C172.296 190.399 172.235 190.412 172.181 190.441C172.161 190.45 172.145 190.465 172.134 190.483C172.122 190.501 172.116 190.522 172.116 190.543C172.108 190.599 172.114 190.656 172.132 190.709C172.151 190.763 172.182 190.811 172.223 190.849C172.47 191.069 173.129 191.107 174.019 190.956C173.5 190.68 172.938 190.493 172.357 190.403Z" fill="#1487E3"/>
<path d="M174.32 191.092H174.277C173.8 190.878 172.852 190.019 172.927 189.552C172.927 189.444 173.023 189.31 173.286 189.283C173.382 189.273 173.479 189.282 173.571 189.311C173.663 189.34 173.748 189.387 173.822 189.45C174.326 189.868 174.422 190.953 174.422 191.001C174.424 191.017 174.422 191.034 174.415 191.049C174.408 191.064 174.398 191.077 174.384 191.087C174.364 191.096 174.342 191.098 174.32 191.092ZM173.361 189.482H173.302C173.13 189.482 173.12 189.568 173.114 189.589C173.071 189.863 173.714 190.545 174.186 190.84C174.14 190.381 173.952 189.948 173.65 189.6C173.569 189.531 173.467 189.489 173.361 189.482Z" fill="#1487E3"/>
<path d="M180.657 66.7148C180.501 69.141 180.083 74.0686 181.943 75.432C181.943 75.432 181.605 77.9172 177.216 78.4969C172.392 79.125 174.595 76.3982 174.595 76.3982C177.151 75.4212 176.824 73.4728 176.127 71.6907L180.657 66.7148Z" fill="#FDA982"/>
<path d="M170.681 62.4348C170.654 62.4348 170.628 62.4291 170.604 62.418C170.58 62.4069 170.559 62.3906 170.542 62.3704C170.388 62.1883 170.194 62.0448 169.976 61.9516C169.757 61.8584 169.519 61.8179 169.282 61.8336C169.257 61.8382 169.231 61.8376 169.206 61.8319C169.181 61.8262 169.158 61.8155 169.137 61.8004C169.117 61.7853 169.1 61.7662 169.087 61.7442C169.074 61.7223 169.065 61.6979 169.062 61.6726C169.056 61.6221 169.07 61.5712 169.101 61.531C169.132 61.4909 169.178 61.4646 169.228 61.4579C169.531 61.4322 169.835 61.4804 170.115 61.5982C170.395 61.7159 170.643 61.8999 170.836 62.1342C170.86 62.1622 170.874 62.1962 170.879 62.2322C170.884 62.2683 170.879 62.305 170.863 62.3381C170.848 62.3712 170.824 62.3993 170.794 62.4193C170.763 62.4392 170.728 62.4502 170.692 62.4509L170.681 62.4348Z" fill="#0D2C54"/>
<path d="M169.845 64.8105C169.654 65.5804 169.361 66.3208 168.971 67.0113C169.15 67.1077 169.348 67.1635 169.551 67.1746C169.754 67.1858 169.956 67.152 170.145 67.0757L169.845 64.8105Z" fill="#FF6666"/>
<path d="M170.057 64.227C170.1 64.5491 169.966 64.8282 169.757 64.8604C169.548 64.8926 169.339 64.6457 169.301 64.3236C169.264 64.0016 169.392 63.7225 169.601 63.6956C169.81 63.6688 170.019 63.8835 170.057 64.227Z" fill="#0D2C54"/>
<path d="M169.692 63.684L168.893 63.5605C168.893 63.5605 169.365 64.1134 169.692 63.684Z" fill="#0D2C54"/>
<path d="M178.743 191.014H174.723L174.455 181.701H178.475L178.743 191.014Z" fill="#FDA982"/>
<path d="M196.994 191.014H192.974L191.683 181.701H195.703L196.994 191.014Z" fill="#FDA982"/>
<path d="M192.731 190.553H197.244C197.321 190.552 197.395 190.579 197.454 190.63C197.512 190.68 197.55 190.75 197.56 190.827L198.096 194.407C198.106 194.497 198.097 194.588 198.069 194.675C198.041 194.761 197.995 194.841 197.934 194.908C197.873 194.975 197.799 195.029 197.716 195.066C197.633 195.103 197.544 195.121 197.453 195.121C195.882 195.094 195.121 195.003 193.138 195.003C191.916 195.003 190.131 195.126 188.448 195.126C186.765 195.126 186.674 193.457 187.376 193.306C190.522 192.63 191.021 191.696 192.077 190.8C192.261 190.647 192.491 190.56 192.731 190.553Z" fill="#0D2C54"/>
<path d="M174.587 190.553H179.095C179.172 190.552 179.247 190.579 179.307 190.629C179.366 190.68 179.405 190.75 179.416 190.827L179.952 194.407C179.962 194.497 179.953 194.588 179.925 194.675C179.897 194.761 179.851 194.841 179.79 194.908C179.73 194.975 179.655 195.029 179.572 195.066C179.489 195.103 179.4 195.121 179.309 195.121C177.739 195.094 176.977 195.003 174.994 195.003C173.772 195.003 171.242 195.126 169.559 195.126C167.876 195.126 167.785 193.457 168.487 193.306C171.633 192.63 172.872 191.696 173.933 190.8C174.117 190.647 174.347 190.56 174.587 190.553Z" fill="#0D2C54"/>
<path d="M174.455 181.701L174.595 186.505H178.615L178.475 181.701H174.455Z" fill="#DC8056"/>
<path d="M195.703 181.701H191.683L192.353 186.505H196.367L195.703 181.701Z" fill="#DC8056"/>
<path d="M180.708 61.6941C180.981 65.6769 181.18 67.357 179.454 69.649C176.854 73.0897 171.912 72.5583 170.454 68.7365C169.141 65.2958 168.916 59.3645 172.572 57.2818C173.376 56.8203 174.284 56.5721 175.211 56.5609C176.138 56.5498 177.052 56.7759 177.867 57.2179C178.682 57.6598 179.371 58.303 179.868 59.0864C180.366 59.8698 180.655 60.7674 180.708 61.6941Z" fill="#FDA982"/>
<path d="M183.028 65.3965C185.258 66.6418 186.914 63.5715 186.914 63.5715C186.914 63.5715 182.916 64.0063 183.275 61.6231C183.747 58.4615 181.485 54.6182 175.814 55.112C175.498 55.1389 175.197 55.1765 174.903 55.2248C174.309 54.943 173.638 54.8702 172.997 55.0183C172.357 55.1664 171.786 55.5266 171.376 56.0406C169.768 56.1748 167.597 58.0965 169.071 60.6354C169.174 60.3437 169.335 60.0754 169.543 59.8463C169.666 61.3332 170.963 63.695 172.582 63.8506C172.93 65.885 171.579 68.2414 172.046 70.367C172.737 73.432 177.899 74.1244 177.899 74.1244C177.721 73.7417 177.655 73.3163 177.708 72.8974C177.761 72.4786 177.931 72.0833 178.199 71.7573C181.705 73.3676 184.015 71.7948 184.015 71.7948C181.624 70.9038 182.348 69.315 182.348 69.315C183.173 69.682 184.108 69.7142 184.957 69.4049C185.806 69.0956 186.502 68.4688 186.898 67.6563C186.104 67.6941 185.316 67.5008 184.63 67.0998C183.943 66.6988 183.387 66.1072 183.028 65.3965Z" fill="#0D2C54"/>
<path d="M173.988 64.4601C173.998 64.8423 173.932 65.2226 173.794 65.5791C173.656 65.9357 173.448 66.2613 173.184 66.5374C172.477 67.2727 171.608 66.7574 171.373 65.8181C171.164 64.97 171.281 63.569 172.203 63.1825C172.406 63.1027 172.626 63.0749 172.842 63.1017C173.059 63.1285 173.265 63.209 173.442 63.336C173.62 63.4629 173.762 63.6322 173.858 63.8286C173.953 64.0249 173.998 64.242 173.988 64.4601Z" fill="#FDA982"/>
<path d="M177.314 101.569C177.314 101.569 178.333 128.064 180.756 143.619C182.707 156.191 191.374 185.563 191.374 185.563H196.852C196.852 185.563 192.333 157.194 191.342 144.768C188.833 113.319 195.346 106.566 189.198 100.018L177.314 101.569Z" fill="#0D2C54"/>
<path d="M181.901 109.95C181.901 109.95 179.425 109.52 177.978 114.244C178.514 123.192 179.43 135.065 180.765 143.621C181.06 145.527 181.51 147.824 182.062 150.342C181.017 135.226 181.901 109.95 181.901 109.95Z" fill="#0D2C54"/>
<path d="M173.049 102.13C173.049 102.13 168.536 131.443 168.799 143.709C169.072 156.468 173.741 185.577 173.741 185.577H179.17C179.17 185.577 178.313 157.224 178.79 144.696C179.326 131.035 184.895 100.59 184.895 100.59L173.049 102.13Z" fill="#0D2C54"/>
<path d="M197.647 185.815H190.363L189.795 183.367L197.593 183.421L197.647 185.815Z" fill="#1487E3"/>
<path opacity="0.2" d="M197.647 185.815H190.363L189.795 183.367L197.593 183.421L197.647 185.815Z" fill="#4D4D4F"/>
<path d="M188.925 75.447C191.021 75.651 194.205 81.6145 194.205 81.6145L188.915 85.6778C188.915 85.6778 184.535 81.8292 185.055 79.666C185.597 77.4169 186.915 75.2591 188.925 75.447Z" fill="#1487E3"/>
<path opacity="0.1" d="M188.925 75.447C191.021 75.651 194.205 81.6145 194.205 81.6145L188.915 85.6778C188.915 85.6778 184.535 81.8292 185.055 79.666C185.597 77.4169 186.915 75.2591 188.925 75.447Z" fill="#4D4D4F"/>
<path d="M187.346 78.0193C186.812 77.8751 186.259 77.8136 185.706 77.8368C185.425 78.4237 185.208 79.0391 185.058 79.6726C184.522 81.8196 188.917 85.6844 188.917 85.6844L189.308 85.3838L187.346 78.0193Z" fill="#1487E3"/>
<path opacity="0.3" d="M187.346 78.0193C186.812 77.8751 186.259 77.8136 185.706 77.8368C185.425 78.4237 185.208 79.0391 185.058 79.6726C184.522 81.8196 188.917 85.6844 188.917 85.6844L189.308 85.3838L187.346 78.0193Z" fill="#4D4D4F"/>
<path d="M171.254 83.7392C169.726 87.6093 168.118 91.5438 166.837 95.4247C166.751 95.6716 166.682 95.9078 166.607 96.1493L166.424 96.7451C166.375 96.9206 166.375 97.1065 166.424 97.2819C166.727 97.953 167.207 98.5284 167.813 98.9459C169.415 100.097 171.161 101.034 173.007 101.732L172.524 103.707C170.254 103.407 168.059 102.69 166.049 101.592C164.812 100.93 163.805 99.9085 163.16 98.6614C162.79 97.8643 162.661 96.976 162.79 96.1064C162.803 95.9999 162.82 95.8942 162.844 95.7897L162.898 95.5643L162.989 95.1563C163.053 94.8826 163.117 94.6195 163.187 94.3512C163.723 92.2041 164.409 90.1643 165.133 88.1407C165.856 86.1171 166.612 84.1203 167.464 82.1289L171.254 83.7392Z" fill="#FDA982"/>
<path d="M168.002 78.3178C168.002 78.3178 166.19 79.2357 173.051 102.14L189.227 100.025C188.091 93.4654 187.555 89.4021 188.932 75.4568C186.613 75.2904 184.284 75.2904 181.964 75.4568C179.495 75.6006 177.039 75.9235 174.616 76.423C171.512 77.1154 168.002 78.3178 168.002 78.3178Z" fill="#1487E3"/>
<path opacity="0.1" d="M168.002 78.3178C168.002 78.3178 166.19 79.2357 173.051 102.14L189.227 100.025C188.091 93.4654 187.555 89.4021 188.932 75.4568C186.613 75.2904 184.284 75.2904 181.964 75.4568C179.495 75.6006 177.039 75.9235 174.616 76.423C171.512 77.1154 168.002 78.3178 168.002 78.3178Z" fill="#4D4D4F"/>
<path d="M189.347 99.0242L190.274 100.371C190.343 100.479 190.226 100.608 190.043 100.634L173.079 102.857C172.934 102.857 172.8 102.819 172.779 102.728L172.446 101.295C172.42 101.198 172.538 101.096 172.698 101.075L189.079 98.9275C189.128 98.9197 189.178 98.9244 189.225 98.9413C189.272 98.9582 189.314 98.9867 189.347 99.0242Z" fill="#1487E3"/>
<path opacity="0.2" d="M189.347 99.0242L190.274 100.371C190.343 100.479 190.226 100.608 190.043 100.634L173.079 102.857C172.934 102.857 172.8 102.819 172.779 102.728L172.446 101.295C172.42 101.198 172.538 101.096 172.698 101.075L189.079 98.9275C189.128 98.9197 189.178 98.9244 189.225 98.9413C189.272 98.9582 189.314 98.9867 189.347 99.0242Z" fill="#4D4D4F"/>
<path d="M187.376 101.144L187.815 101.085C187.901 101.085 187.96 101.02 187.949 100.966L187.499 99.1093C187.482 99.0776 187.454 99.0528 187.421 99.0391C187.388 99.0254 187.351 99.0236 187.317 99.0341L186.877 99.0878C186.786 99.0878 186.727 99.1522 186.743 99.2112L187.193 101.063C187.204 101.122 187.29 101.154 187.376 101.144Z" fill="#0D2C54"/>
<path d="M176.74 102.527L177.18 102.468C177.265 102.468 177.33 102.404 177.314 102.35L176.863 100.493C176.846 100.462 176.819 100.437 176.786 100.422C176.753 100.408 176.716 100.404 176.681 100.412L176.242 100.472C176.156 100.472 176.092 100.536 176.108 100.59L176.558 102.447C176.569 102.527 176.654 102.56 176.74 102.527Z" fill="#0D2C54"/>
<path d="M168.76 86.0705C169.205 88.1425 169.833 90.7673 170.749 94.0899L170.947 85.668L168.76 86.0705Z" fill="#1487E3"/>
<path opacity="0.3" d="M168.76 86.0705C169.205 88.1425 169.833 90.7673 170.749 94.0899L170.947 85.668L168.76 86.0705Z" fill="#4D4D4F"/>
<path d="M168.003 78.3029C165.993 79.1134 164.251 86.0002 164.251 86.0002L171.166 89.3711C171.166 89.3711 174.505 80.9491 173.009 79.3066C171.514 77.6641 170.319 77.3743 168.003 78.3029Z" fill="#1487E3"/>
<path opacity="0.1" d="M168.003 78.3029C165.993 79.1134 164.251 86.0002 164.251 86.0002L171.166 89.3711C171.166 89.3711 174.505 80.9491 173.009 79.3066C171.514 77.6641 170.319 77.3743 168.003 78.3029Z" fill="#4D4D4F"/>
<path d="M180.1 185.815H172.816L172.248 183.367L180.357 183.421L180.1 185.815Z" fill="#1487E3"/>
<path opacity="0.2" d="M180.1 185.815H172.816L172.248 183.367L180.357 183.421L180.1 185.815Z" fill="#4D4D4F"/>
<path d="M173.118 101.052L172.533 100.811C172.39 100.752 172.233 100.733 172.08 100.755C171.926 100.776 171.781 100.838 171.659 100.934C171.537 101.03 171.443 101.157 171.386 101.301C171.328 101.445 171.31 101.602 171.333 101.756L171.756 104.552C171.777 104.692 171.828 104.826 171.906 104.944C171.984 105.061 172.087 105.16 172.208 105.233C172.329 105.306 172.465 105.351 172.606 105.365C172.746 105.379 172.888 105.362 173.021 105.314C173.234 105.237 173.433 105.127 173.611 104.987C173.805 104.826 173.958 104.622 174.059 104.39C174.159 104.159 174.204 103.908 174.19 103.656L174.131 102.496C174.12 102.183 174.019 101.88 173.839 101.623C173.659 101.367 173.409 101.168 173.118 101.052Z" fill="#FDA982"/>
</svg>
`