import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HomeTour } from "./home-tour";
import './home.scss';
import "./home-walkthrough.scss"
import {
	arrowSvg, calibratorSvg, upgradeSvg, plantEditIcon, plantDelIcon, angleIcon,StatusOvalSvg,
	closeSvg, upgradeCloseSvg, noPlantSvg, collapseArrow, skillArrow, unitarBannerCloseSvg, trainingCloseSvg,
	playSvg,
	uniTarBannerWrapperSvg, uniPlayIconSvg, unitarInfoSvg, unitarCheckSvg, unitarArrow, unitarExploreCloseSvg, minBtnSvg
  } from "./svg";

export const HomeTourView = (props) => {
	const [tabIndex, setTabIndex] = useState(0);
	const HandleViewTab = (index) => {
		setTabIndex(index);
	};
	const [isOnboarding, setIsOnboarding] = useState(true);
	const HandleOnboadering = () => {
		setIsOnboarding(!isOnboarding);
	};
	useEffect(() => {
		console.log('ctrl',props);
	},[]);
	return (
		<>
			<div className="prepare-home-wrapper">
				{/* page heading */}
				<h1 className="prepare-top-h1 walk-1">
				<img
					src={
					require("../../assets/img/prepare-home/my-trainging-h1.svg")
						.default
					}
					alt="true"
				/>
				<span>My Trainings</span>
				</h1>
				{/* Added wrapper for manufacturing courses */}
				<div className="manufacturing-course-wraper" >
				{/* training course heading */}
				{/* <div className="training-course-heading">
					<h2>Manufacturing Courses</h2>
				</div> */}
				{/* row has been divided in col */}
				<div className="prepare-row">
					{/* this section has card */}
					<div className="prepare-col 0">
					{/* card has two sections
						prp-img-div
						prp-text-div
					*/}
					<a className="prp-card" href="/skill-assessment">
						{/* this section has
							image
							status 
						*/}
						<div className="prp-img-div green-bg">
						<img
							src={
							require("../../assets/img/training-card/digital-organization.svg")
								.default
							}
							className="icon-img"
							alt="Skills Assessment"
						/>
						<span
							className= "status todo"
						>
							TO DO
						</span>
						</div>
						<div className="prp-text-div">IR4.0 Skills Assessment</div>
					</a>
					</div>
					<div className="prepare-col">
						<a
							className='prp-card'
							href="/learning-center"
						>
							<div className="prp-img-div blue-bg resource-center">
							<img
								src={
								require("../../assets/img/prepare-home/resource-card-img.svg")
									.default
								}
								className="icon-img"
								alt="IR4.0 Skills Assessment"
							/>

							</div>
							<div className="prp-text-div">Resource Center</div>
						</a>
					</div>
					<div className="prepare-col 3">
						<Link to="/training-courses" className="prp-card">
							<div className="prp-img-div blue-bg">
							<img
								src={
								require("../../assets/img/home-index/course_sections-icon-1.svg")
									.default
								}
								className="icon-img"
								alt="true"
							/>
							<span class="status upgrade">Upgrade</span>
							</div>
							<div className="prp-text-div">
							Available training courses
							</div>
						</Link>
					</div>
					{/*-------------------- analitics card ----------------------*/}
				</div>
				</div>
				{/* Added wrapper for new category MY Assets and also divided it into two subcategories */}
				<div className="assets-wrapper">
				{/* Main Category Heading */}
				<h1 class="prepare-top-h1 walk-2">
					<img
					src={require("../../assets/img/prepare-home/measure.svg").default}
					/>
					<span>My Plants</span>
				</h1>
				{/* First Subcategory will be Plants wrapper */}
				<div className="plants-wrapper">
					
					<div className="prp-plant-div">
						<div className="prepare-assets-heading">
						{/* <img
							src={
							require("../../assets/img/prepare-home/measure.svg").default
							}
							alt="true"
						/> */}
						<div className="prp-plant-div">
							<div className="prepare-row">
							<div className="prepare-full-col plant-name-row">
                        <div className="plant-name-div">
                          <span className="plant-img">
                            <img
                              src={`https://apimim.smarterchains.com/assets/img/country/saudi-arabia.svg`}
                              alt=""
                            />
                          </span>
                          <span className="plant-txt">
                            <span>Demo Plant</span>
                           
                              <i
                                className="renameIcon"
                                dangerouslySetInnerHTML={{ __html: plantEditIcon }}
                                data-title="Rename"
                              ></i>
                          </span>
                        </div>
                        {/* Collapse button CDP-444 */}
                        <button className={`collapse-btn active`} >
                          <i className='angle-icon' dangerouslySetInnerHTML={{ __html: collapseArrow }}></i>
                        </button>
                      </div>
							<div className="prepare-full-col tour-col">
								<div className="prp-inner-card-row">
								<div className="prepare-col plants disabled">
									<PlantCard
									progress={0}
									cardType="PREPARE"
									title="Assessment"
									callibratorButton={true}
									status={`review-ready`}
									savings="0%"
									type="dma"
									colorClass="blue-bg"
									/>
								</div>
								<div className="prepare-col plants">
									<PlantCard
									cardType="PREPARE"
									progress={0}
									status={`upgrade`}
									title="IR4.0 Loss Analysis"
									getAcessButton={true}
									savings="0%"
									type="loss"
									colorClass="blue-bg blue-corner"
									/>
								</div>
								<div className="prepare-col plants">
									<PlantCard
									cardType="DEFINE"
									progress={0}
									title="IR4.0 Strategy"
									getAcessButton={true}
									status={`upgrade`}
									colorClass="orange-bg orange-corner"
									/>
								</div>
								</div>
							</div>
							</div>
						</div> 
						</div>
						{/* prepare-row 1 end */}
					</div>
				</div>
				</div>
				{/* <button className="platform-button">
				<span className="platform-text">Platform Trainings</span>
				<i className='arrow-icon' dangerouslySetInnerHTML={{ __html: arrowSvg }}></i>
				</button> */}
      		</div>
			<HomeTour isOnboarding={props.isTour} HandleOnboadering={()=>{}} {...props}/>
			{/* onboarding walkthrough end*/}
			<UnitarResourcePopup />
		</>
	);
};
const TraingingCard = (props) => {
	const { title, headerImg, url, status, colorClass, otherFunction } = props;
	const callOtherFunction = () => {
	  if (otherFunction) {
		otherFunction();
	  }
	};
	return (
	  <>
		<ConditionalTag url={url} otherFunction={callOtherFunction}>
		  <div className={`prp-img-div ${colorClass}`}>
			<img src={headerImg} className="icon-img" alt="Img" />
			{status == 100 ? (
			  <span className="status completed">COMPLETED</span>
			) : status > 0 && status < 100 ? (
			  <span className="status inprogress">IN PROGRESS</span>
			) : status == 0 ? (
			  <span className="status todo">To do</span>
			) : (
			  ""
			)}
		  </div>
		  <div className="prp-text-div">
			{title}
			<div className="prp-text-div">
          {title}
          {callibratorButton &&
            <Link

              className="recalibrate-btn"
              
            >
              <span className="re-text">RE-CALIBRATE</span>{" "}
              <span
                className="re-img"
                dangerouslySetInnerHTML={{ __html: calibratorSvg }}
              ></span>
            </Link>
          }
          {/* max-character 49*/}
          {getAcessButton && 
		  <Link
              className="getaccess-btn"
              
            >
              <span className="re-text">GET ACCESS</span>{" "}
              <span
                className="re-img"
                dangerouslySetInnerHTML={{ __html: upgradeSvg }}
              ></span>
            </Link>
          }
        </div>
		  </div>
		</ConditionalTag>
	  </>
	);
  };
  //plant card component
  const PlantCard = (props) => {
	const {
	  title,
	  url,
	  status,
	  customStatus,
	  colorClass,
	  cardType,
	  type,
	  progress,
	  lossReduction,
	  callibratorButton,
	  getAcessButton,
	  isRoadmapDiveTxt,
	  isRoadmapResult,
	  savings,
	  otherFunction,
	} = props;
	const callOtherFunction = () => {
	  if (otherFunction) {
		otherFunction();
	  }
	};
	return (
	  <>
		<ConditionalTag url={props.gourl} otherFunction={callOtherFunction}>
		  <div className={`prp-img-div ${colorClass}`}>
			<div className="prp-card-img">
			  <p>{cardType}</p>
			  <img
				src={
				  require(`../../assets/img/prepare-home/${type == "dma"
					? `assessment-icon.svg`
					: type == "loss"
					  ? "loss-icon.svg"
					  : type == "execute"
						? "prepare-execute.svg"
						: type == "techAdop"
						  ? "tech-adoption-icon.svg"
						  : "business-icon.svg"
  
					}`).default
				}
				alt="Img"
			  />
			</div>
			<div className="prp-card-porogress-div">
			  {/* if roadmap deep dive text  in props*/}
			  {isRoadmapDiveTxt && (
				<span className="txt-normal">{isRoadmapDiveTxt}</span>
			  )}
  
			  {/* if progress is 0 or greater than 0 in props  */}
			  {progress > -1 && progress !== "undefined" ? (
				<span className="txt-normal">Progress</span>
			  ) : (
				""
			  )}
			  {progress > -1 && <span className="progress-num">{progress}%</span>}
  
			  {/* loss reduction */}
			  {/* {lossReduction > -1 && lossReduction !== "undefined" ? (
				<span className="txt-normal">Loss Reduction</span>
			  ) : (
				""
			  )}
			  {lossReduction > -1 && (
				<span className="progress-num">{lossReduction}%</span>
			  )} */}
			  {/* loss reduction end */}
  
			  {isRoadmapResult && (
				<>
				  <span className="progress-num result">{savings}</span>
				  <span className="txt-normal">{isRoadmapResult}</span>
				</>
			  )}
			</div>
			{status == 'upgrade' ?
            // (<span className="status upgrade">UPGRADE</span>)
            (
              <div className="learn-more-text-wrap">
                <i dangerouslySetInnerHTML={{__html:playSvg}}></i>
                <span>Learn More with our Product training & Case Studies</span>
              </div>
            ):
			status == 100 ? (
			  <span className="status completed">COMPLETED</span>
			) : status > 0 && status < 100 ? (
			  <span className="status inprogress">IN PROGRESS</span>
			) : status == 0 ? (
			  <span className="status todo">To do</span>
			) : status == "not-started" ? (
			  <span className="status not-started">NOT STARTED</span>
			) : status == "results-ready" ? (
			  <span className="status results-ready">RESULTS READY</span>
			): status == "review-ready" ? (
				<span className="status results-ready">REVIEW READY</span>
			  )  : (
			  ""
			)}
			{/* custom status */}
			{customStatus ? (
			  <span className="status completed">{customStatus}</span>
			) : (
			  ""
			)}
		  </div>
		  <div className="prp-text-div">
			{title}
			{/* max-character 49*/}
		  </div>
		</ConditionalTag>
	  </>
	);
  };
  //conditional tag component
  const ConditionalTag = (props) => {
	const { url, otherFunction } = props;
	return (
	  <>
		{url ? (
		  <Link to={url} className="prp-card">
			{props.children}
		  </Link>
		) : (
		  <div className="prp-card" onClick={otherFunction}>
			{props.children}
		  </div>
		)}
	  </>
	);
  };

  // Unitar Resource Popup
const UnitarResourcePopup = (props) => {
	
	return (
	  <>
		<div className="unitar-resource-main-wrapper">
		  <div className={`unitar-resource-pop-wrapper`}>
			<div className="unitar-resource-pop-card">
			  <div className="unitar-resource-pop-header">
				<div className="unitar-pop-head-left">
				  <div className="unitar-text">
					<span className="bold-text">Industry 4.0 For Sustainable Development Webinar</span>
				  </div>
				</div>
				<button className="unitar-min-btn">
				  <i dangerouslySetInnerHTML={{ __html: minBtnSvg }}></i>
				</button>
			  </div>
			  <div className="unitar-resource-pop-body">
				<div className="image-container">
				  <i className="banner-img" dangerouslySetInnerHTML={{ __html: uniTarBannerWrapperSvg }}></i>
				</div>
				<p>
				  Watch the launch webinar of the pioneering learning 
				  collaboration for advancing the Sustainable 
				  Development Goals with IR4.0 education
				</p>
			  </div>
			  <div className="unitar-resource-pop-footer">
				<Link to="/learning-center" className="unitar-view-link">View Webcast Resources</Link>
			  </div>
			</div>
		  </div>
		  {/* <button className={`unitar-max-btn active-max-class`}>
			Industry 4.0 For Sustainable Development Webinar
		  </button> */}
		</div>
	  </>
	)
  }

