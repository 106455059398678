import styled from "styled-components";
import { Link } from "react-router-dom";

// Walkthrough Styles
export const WalkthroughContainer = styled.div`
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom:  0;
  height: 100%;
  width: 100%;
  z-index: 999;
`;
export const WalkSection = styled.div`
  padding: 2rem 3.2rem 3.2rem 3.2rem;
  width: 100%;
`;
export const WalkthroughCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &.task-modal-3{
    position: relative;
    &::after{
      content: " ";
      position: absolute;
      top: -0.6rem;
      right: 92%;
      border-width: 6px 10px 6px 8px;
      border-style: solid;
      border-color: transparent #fff transparent transparent; 
      transform: rotateZ(330deg);
    }
  }
  &.task-modal-1{
    position: relative;
    &::after{
      content: " ";
      position: absolute;
      top: unset;
      right: unset;
      bottom: 0.5rem;
      left: 2.5rem;
      border-width: 8px 14px 8px 10px;
      border-style: solid;
      transform: rotateZ(510deg);
      border-color: transparent #fff transparent transparent; 
    }
  }
  `;
export const WalkthroughWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 0.8rem;
  filter: drop-shadow(5px 5px 30px rgba(77, 77, 79, 0.4));
  width: 48.5rem;
  height: 24.4rem;
  z-index: 999;
  @media(max-width:700px){
     position:fixed !important;
     top:unset !important;
     bottom:15px !important;
     left:15px !important;
     width:auto !important;
     max-width:385px !important;
     transform:unset !important;
  }
  @media(max-width:400px){
    width:calc(100% - 30px) !important;
    max-width:calc(100% - 30px) !important;
 }
  &.container-1{
    
  }
  &.container-2{
    left: 31rem;
    top: auto;
    transform: unset;
    @media(max-width:700px){
      top:15px !important;
      bottom:unset !important;
      left:15px !important;
   }
  }
  &.container-3{
    right: 20rem;
    top: 20rem;
    transform: unset;
    left: auto;
  }

  
  &.container1 {
    transform: unset;
    left: 8.5rem;
    top: unset;
    bottom:  2.6rem;
    @media(max-width: 1200px){
      left: 60px;
      bottom: 132px;
    }
    @media(max-width: 1100px){
      left: 50%;
      transform: translateX(-50%);
      bottom: 310px;
    }
  }
  &.container2 {
    transform: unset;
    left: 8rem;
    top: 5rem;
  
    @media(max-width: 1200px){
      left: 60px;
      top: 60px;
    }
    @media(max-width: 1100px){
      left: 50%;
      transform: translateX(-50%);
      top: 170px;
    }
  }
  &.container3 {
    transform: unset;
    left: auto;
    right: 30rem;
    top: 18.2rem;
    
    @media(max-width: 1200px){
      right: 172px;
      top: 195px;
  
    }
    @media(max-width: 1100px){
      left: 50%;
      transform: translateX(-50%);
      top: auto;
      bottom: 94px;
    }
  }
  &.container4 {
    transform: unset;
    left: auto;
    right: 30rem;
    top: 27rem;
  
    @media(max-width: 1200px){
      right: 172px;
      top: 80px;
    }
    @media(max-width: 1100px){
      left: 50%;
      transform: translateX(-50%);
      top: 326px;
    }
  }
`;

export const WalkTopRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 1.8rem;
`;
export const WalkTopLeftCol = styled.div`
span{
    display: inline-block;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #4D4D4F;
    opacity: 0.5;
    @media(max-width: 1200px){
        font-size: 11px;
    }
}
`;
export const WalkTopRightCol = styled.div`
margin-right: -1.2rem;
.img{
    display: inline-block;
    cursor: pointer;
    height: 2.4rem;
    width: 2.4rem;
    @media (max-width: 1200px){
        height: 24px;
        width: 24px;
    }
}
`;
export const WalkMidRow = styled.div`
h2{
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #4D4D4F;
    margin-bottom: 1.8rem;
    text-transform: capitalize;
    @media(max-width: 1200px){
        font-size: 14px;
        margin-bottom: 11px;
    }
}
.first-txt{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 7.8rem;
  margin-bottom: 0.8rem;
}
p{
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #4D4D4F;
    @media(max-width: 1200px){
      font-size: 12px;
    }
}
`;
export const WalkFooter = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;
export const WalkFooterLeftCol = styled.div`
`;
export const WalkFooterRightCol = styled.div`
  .solid-btn-link{
    min-width: 6.8rem;
    @media(max-width: 1200px){
      min-width: 75px;
    }
  }
  .start{
    min-width: 9.4rem;
    @media(max-width: 1200px){
      min-width: 105px;
    }
  }
`;
export const DotsSection = styled.div`
display: flex;
align-items: center;
height: 8px;
.circle{
    display: inline-block;
    margin-right: 1rem;
    height: 100%;
    .close-icon{
        display: flex;
        height: 4px;
        width: 4px;
    }
    
    svg{
        height: 100%;
        width: 100%;
    }
    &:disabled {
        transition: 0.25s;
        .close-icon{
            height: 8px;
            width: 8px; 
        }
        svg{
            circle{
                fill: #139bd6;
            }
        }
    }
    &:last-child{
        margin-right: 0;
    }
}
`;
export const ButtonWrapper = styled.div`
button{
    font-weight: 600;
    font-size: 1.2rem;
    border: none;
    box-shadow: none;
    padding: 0.9rem 1rem;
    @media(max-width: 1200px){
        font-size: 11px;
        padding: 6px 7px;
    }
    &.btn-blue{
        background: linear-gradient(91.83deg, #139BD6 12.22%, #0789CF 100.41%); 
        border-radius: 0.4rem;
        border: none;
        color: #ffffff;
        margin-left: 2.2rem;
        min-width: 6.8rem;
        @media(max-width: 1200px){
          margin-left: 10px;
        }
    }
    &.start{
      min-width: 9.4rem;
    }
    &.btn-blue-link{
        color: #118acb;
        padding-left: 0;
        padding-right: 0;
    }
}
`;



