/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * Helmet ===> for add specific title & CSS for the page.

**/

import React, { useState, useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";

// AllquestionsPlantView component start
export const AllquestionsPlantView = (props) => {
	const sectionDataItems = props.sectionData;
	if (!sectionDataItems.length) {
		return null;
	}

	const sectionDataItem = props.sectionData[0];
	const block = sectionDataItem.block;
	if(block == 'block'){
		return <Redirect to="/home" />;
	}
	const catData = sectionDataItem.category;
	const chapterDataItem = sectionDataItem.chapters;
	console.log(props.sectionData[0].has_ans);
	if (!catData) {
		return null;
	}

	const slug = props.slug;
	
	const answeredQuestion = chapterDataItem.map((chapter, index) => {
				const count = index + 1;
				return (
					// wrapper that is containing header and card
						<div className="all-qna-cards-wrap">
							<h1 class="card-h1">{count}. {chapter.subcategory_name}</h1>
							
						{chapter.all_question.length ? (
							chapter.all_question.map((aq) => (
							<Link className="all-qna-card" to={`/assessment/start/${slug}/${chapter.id}/${aq.id}`}>
								<div className="no-sec">{aq.numb}</div>
								<div className="text-sec">
									<span>
										{aq.question}
									</span>
								</div>
								<div className="arrow-sec" >
										<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											  <path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
											</svg>
								</div>
							</Link>
							))	
							
						) : (
					<></>
				)}	

					</div>
					
				);
		
	});
	return (
		<>
			{/* Page Title */}
			<Helmet>
				<title>{catData.category_name} | SmarterChains</title>
				<meta name="description" content={`${catData.category_name} Assessment`} />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{sectionDataItem.plant_name}</BreadcrumbsItem>
			<BreadcrumbsItem to={`/assessment/${slug}`}>Assessment</BreadcrumbsItem>
			<BreadcrumbsItem to="#">{catData.category_name}</BreadcrumbsItem>
			{/* Wrapper that is containg heading and text and result will come conditional */}

			<div className="all-qna-wrapper7-21">
					<Link to={`/assessment/${slug}`} className="h2 sm-visible">
						<i dangerouslySetInnerHTML={{__html:arrowSvg}}></i>
						<span>{catData.category_name}</span>
					</Link>
					<h1 className="top-h1">
						Answered questions
					</h1>
					<p className="top-p">
						Please note that changing any input might result in the loss of subsequent answers. You are advised to reach to the section's end, if you decide to do changes.
					</p>
					
				    {answeredQuestion}
				    {props.sectionData[0].has_ans ===0  && <p className="all-qns-no-result">You haven't answered any question yet.</p>
					}
					
			</div>
		</>
	);
};

const arrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_1407_41957" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1407_41957)">
  <path d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z" fill="#0D2C54"/>
</g>
</svg>`
