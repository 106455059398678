/** imported components
 
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState } from "react";
import "./range-slider.scss";
//RangeSlide component start
export const RangeSlide = (props) => {
	//defining variables here
	const [rangeValue, setRangeValue] = useState(props.value);
	//funtion to handle range value
	const HandleRangeValue = (e) => {
		const tmpVal = e.target.value;
		setRangeValue(tmpVal);
		props.getValue(e);
	};
	return (
		<>
			{/* this section has two parts
				range-box
				input
			*/}
			<label className="custom-range-slide">
				{/* this section has progress bar */}
				<div className="range-box">
					<div className="progress-bg" style={{ width: `${rangeValue}%` }}>
						<div className="thumb" style={{ left: `calc(100% - ${(1.6 * rangeValue) / 100}rem)` }}>
							<img src={require("../../assets/img/common-img/progess-cir-bar-icon.svg").default} alt="." />
							{props.showLabel && <span className="range-label">{rangeValue}%</span>}
						</div>
					</div>
				</div>
				<input
					type="range"
					name="range"
					step="5"
					id={props.id}
					value={rangeValue}
					min="0"
					max="100"
					onChange={(e) => {
						HandleRangeValue(e);
					}}
				/>
			</label>
		</>
	);
};

/*given props */
/*
"step for give step in input"
"getValue" is a mathod to send value of range slider from this component to it's calling component;
"id" is required to access it's value with a uniq name;
"value" is for given to default value for first time;
"showLabel" is for show its label which show on the top of range thumb;
*/
