/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  
**/

import React, { useCallback, useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from 'react-router'
import serviceProvider from '../../services/axios'
// import "./quiz.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import $ from "jquery";
//QuizView component start
export const QuizView = (props) => {
	const [quizOn, setQuizOn] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [isBlock,setIsBlock]= useState(true);
	var totSelected = [];
	useEffect(() => {
		var element = document.getElementById("quizForm");
		if (typeof element != "undefined" && element != null) {
			// Exists.
			// console.log("111ssss");
			totSelected = [];
			element.reset();
		}
		let mainScrollDiv= document.querySelector('.training-content');
		let mainDiv=document.querySelector('.training-main-wrapper');
		mainDiv.classList.add('overlay-gray');
		mainScrollDiv.style.overflow='hidden';
	}, []);

	useEffect(() => {
		let mainScrollDiv= document.querySelector('.training-content');
		let mainDiv=document.querySelector('.training-main-wrapper');
		 mainScrollDiv.scrollTop=0;
		return()=>{
			
		    mainDiv.classList.remove('overlay-gray');
			  mainScrollDiv.style.overflow='';
				}
		
	}, []);
	const CloseQuizPopup = () => {
		setQuizOn(false);
	}
	const quizDataItems = [
		{
			"uid": "2453",
			"id": "11",
			"certificate_ref_id": "1",
			"course_name": "Introduction to Industry 4.0",
			"course_slug": "introduction-to-industry-40",
			"course_icon": "course-symb.svg",
			"course_snippet": "Our introductory course intends to make participants become aware of the philosophy evolution and prepare teams for the digital transformation journey, creating excitement and a pull approach.",
			"course_technology": "",
			"course_description": "An introduction to Industry 4.0 disruption and its effect in modern manufacturing.<br\/><br\/>Our introductory course intends to make participants become aware of the philosophy evolution and prepare teams for the digital transformation journey, creating excitement and a pull approach.",
			"skill_desc": "Industry 4.0 disruption and its effect in modern manufacturing that can initiate an exiting digital transformation journey.",
			"course_image": "Introduction-to-Industry-40-Training.png",
			"course_instructor": "1",
			"course_order": "1",
			"social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/introduction-to-industry-40",
			"price": "0",
			"meta_desc": "Industry 4.0 introductory course intends to make you become aware of the philosophy evolution and prepare teams for the digital transformation journey, creating excitement and a pull approach.​",
			"meta_img": "Introduction-to-Industry-40-Training.png\n",
			"is_imd": "1",
			"is_active": "1",
			"has_paid": 1,
			"certificateUrl": "",
			"has_opt": 0,
			"has_completed": 0,
			"score": -1,
			"dateretest": 0,
			"shortname": "ul",
			"name": "ui",
			"last_name": "ll",
			"questions": [
				{
					"id": "1",
					"course_id": "11",
					"question": "In which type of innovation Industry 4.0 technologies belong to?",
					"ques_type": "1",
					"ques_order": "1",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "1",
							"question_id": "1",
							"option_name": "Breakthrough innovation",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "2",
							"question_id": "1",
							"option_name": "Sustaining innovation",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "3",
							"question_id": "1",
							"option_name": "Disruptive innovation",
							"is_correct": "1",
							"is_checked": 0
						}
					]
				},
				{
					"id": "2",
					"course_id": "11",
					"question": "Which two factors have been arisen as new competitive priorities in Industry 4.0?",
					"ques_type": "2",
					"ques_order": "2",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "4",
							"question_id": "2",
							"option_name": "Cost",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "5",
							"question_id": "2",
							"option_name": "Quality",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "6",
							"question_id": "2",
							"option_name": "Time",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "7",
							"question_id": "2",
							"option_name": "Adaptability",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "8",
							"question_id": "2",
							"option_name": "Innovation",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "9",
							"question_id": "2",
							"option_name": "Flexibility",
							"is_correct": "0",
							"is_checked": 0
						}
					]
				},
				{
					"id": "3",
					"course_id": "11",
					"question": "Which of the following are new characteristics that Industry 4.0 brings?",
					"ques_type": "2",
					"ques_order": "3",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "10",
							"question_id": "3",
							"option_name": "Vertical and horizontal integrations",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "11",
							"question_id": "3",
							"option_name": "Cross disciplinary engineering",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "12",
							"question_id": "3",
							"option_name": "Lean manufacturing",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "13",
							"question_id": "3",
							"option_name": "Automated assembly systems",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "14",
							"question_id": "3",
							"option_name": "Acceleration through exponential technologies",
							"is_correct": "1",
							"is_checked": 0
						}
					]
				},
				{
					"id": "5",
					"course_id": "11",
					"question": "Do you agree that Industry 4.0 has increased the research on cybersecurity policies and privacy protection?",
					"ques_type": "1",
					"ques_order": "4",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "21",
							"question_id": "5",
							"option_name": "Yes",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "22",
							"question_id": "5",
							"option_name": "No",
							"is_correct": "0",
							"is_checked": 0
						}
					]
				},
				{
					"id": "6",
					"course_id": "11",
					"question": "What are the characteristics of workforce in Industry 4.0?",
					"ques_type": "2",
					"ques_order": "5",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "23",
							"question_id": "6",
							"option_name": "Variety of hard skills",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "24",
							"question_id": "6",
							"option_name": "Strong soft skills",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "25",
							"question_id": "6",
							"option_name": "Adaptability to new roles and work environments",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "26",
							"question_id": "6",
							"option_name": "Continual interdisciplinary learning",
							"is_correct": "1",
							"is_checked": 0
						}
					]
				},
				{
					"id": "7",
					"course_id": "11",
					"question": "What can factories do to serve the company's objective to attract the customer 4.0?",
					"ques_type": "2",
					"ques_order": "6",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "27",
							"question_id": "7",
							"option_name": "Develop the capabilities for personalized products and services",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "28",
							"question_id": "7",
							"option_name": "Develop fixed automated manufacturing processes for mass production",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "29",
							"question_id": "7",
							"option_name": "Increase the adaptability of manufacturing processes",
							"is_correct": "1",
							"is_checked": 0
						}
					]
				},
				{
					"id": "8",
					"course_id": "11",
					"question": "Is tracing the supplier's certifications and material standards associated with circular supply chain network?",
					"ques_type": "1",
					"ques_order": "7",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "31",
							"question_id": "8",
							"option_name": "Yes",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "32",
							"question_id": "8",
							"option_name": "No",
							"is_correct": "0",
							"is_checked": 0
						}
					]
				},
				{
					"id": "9",
					"course_id": "11",
					"question": "Which elements best describe the future of manufacturing?",
					"ques_type": "2",
					"ques_order": "8",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "33",
							"question_id": "9",
							"option_name": "Sustainability",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "34",
							"question_id": "9",
							"option_name": "Synchronized flow",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "35",
							"question_id": "9",
							"option_name": "Customization and personalization",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "36",
							"question_id": "9",
							"option_name": "Dark data",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "37",
							"question_id": "9",
							"option_name": "Zero-touch operations",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "38",
							"question_id": "9",
							"option_name": "Agility",
							"is_correct": "1",
							"is_checked": 0
						}
					]
				},
				{
					"id": "11",
					"course_id": "11",
					"question": "Which of the following are considered characteristics of the Factory of the Future?",
					"ques_type": "2",
					"ques_order": "9",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "41",
							"question_id": "11",
							"option_name": "Innovative",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "42",
							"question_id": "11",
							"option_name": "Integrative",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "43",
							"question_id": "11",
							"option_name": "Data-driven",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "44",
							"question_id": "11",
							"option_name": "Rigid",
							"is_correct": "0",
							"is_checked": 0
						},
						{
							"id": "45",
							"question_id": "11",
							"option_name": "Automated",
							"is_correct": "1",
							"is_checked": 0
						}
					]
				},
				{
					"id": "12",
					"course_id": "11",
					"question": "Is leadership one of the key pillars of the Factory of the Future?",
					"ques_type": "1",
					"ques_order": "10",
					"has_answered": 0,
					"is_correct": 0,
					"answers": [
						{
							"id": "46",
							"question_id": "12",
							"option_name": "Yes",
							"is_correct": "1",
							"is_checked": 0
						},
						{
							"id": "47",
							"question_id": "12",
							"option_name": "No",
							"is_correct": "0",
							"is_checked": 0
						}
					]
				}
			]
		}
	];
	if (!quizDataItems.length) {
		return null;
	}
	const quizDataItem = quizDataItems[0];
	/** Checking that loggedin user complete this course training or not. If not then redirect to course details page */
	//function to submit form
	
	quizDataItem.questions.map((ques) => {
		if (ques.has_answered == 1) {
			totSelected.push(ques.id);
		}
	});
	//function to change value
	const changeValue = (qid) => {
		//
		var countchecked = document.querySelectorAll("input.inp-" + qid + ":checked").length;
		var element = document.getElementById("quiz__sec__" + qid);
		var aindex = totSelected.indexOf(qid);
		if (countchecked > 0) {
			if (aindex == -1) {
				totSelected.push(qid);
			}
			element.classList.add("selected");
			
		} else {
			if (aindex > -1) {
				totSelected.splice(aindex, 1);
			}
			element.classList.remove("selected");
			
		}
		
		var countchecked = document.getElementsByClassName("selected").length;
    	if(countchecked == quizDataItem.questions.length) {
      		setDisabled(false);
    	}else{
		 setDisabled(true);
		}
	};
	const Quizes = quizDataItem.questions.map((ques, index) => {
		return (
			/* this card has
				header
				ans_div
			*/ 
			<div key={ques.id} id={`quiz__sec__${ques.id}`} className={ques.has_answered == 1 ? "quiz__body__card selected" : "quiz__body__card"}>
				{/* header has
					no__div
					middle__div
					last__div
				*/}
				<header>
					{/* this section has
						text 
						image1 
						image2
					*/}
					<div className="no__div">
						<span>{index + 1}</span>
						<img className="green" src={require("../../assets/img/quiz/done-green.svg").default} alt="true" />
						<img className="red" src={require("../../assets/img/quiz/cancel-red.svg").default} alt="true" />
					</div>
					{/* no__div end */}
					{/* this section has question */}
					<div className="middle__div">
						<p className="b__text">{ques.question}</p>
					</div>
					{/* middle__div end */}
					{/* this section has point */}
					<div className="last__div">1 point</div>
					{/* last__div end */}
				</header>
				{/* this section has answer format */}
				<div className="ans__div" onClick={() => changeValue(ques.id)}>
					{ques.ques_type == 1 ? (
						ques.answers.length ? (
							ques.answers.map((ans) => (
								<div key={ans.id}>
									<label className="quiz__radio">
										{/* defaultChecked */}
										<input
											type="radio"
											className={`inp-${ques.id}`}
											name={`ques-${ques.id}[]`}
											value={ans.id}
											defaultChecked={ans.is_checked ? "checked" : ""}
										/>
										<span className="icon"></span>
										<span className="wrong__icon">
											<img src={require("../../assets/img/quiz/wrong-icon.svg").default} alt="true" />
										</span>
										<span className="labe__text">{ans.option_name}</span>
									</label>
								</div>
							))
						) : (
							<></>
						)
					) : ques.answers.length ? (
						ques.answers.map((ans) => (
							<div key={ans.id}>
								<label className="quiz__chkbox">
									<input
										type="checkbox"
										className={`inp-${ques.id}`}
										name={`ques-${ques.id}[]`}
										value={ans.id}
										defaultChecked={ans.is_checked ? "checked" : ""}
									/>
									<span className="icon">
										<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
									</span>
									<span className="correct__icon">
										<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
									</span>
									<span className="wrong__icon">
										<img src={require("../../assets/img/quiz/wrong-icon.svg").default} alt="true" />
									</span>
									<span className="labe__text">{ans.option_name}</span>
								</label>
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div>
		);
	});
	return (
		<React.Fragment>
			{/* Page title */}
			<Helmet>
				<title>{quizDataItem.course_name} Quiz | SmarterChains</title>
				<meta name="description" content="Training Courses" />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/training-courses">Training Courses</BreadcrumbsItem>
			<BreadcrumbsItem to={`/training-courses/${quizDataItem.course_slug}`}>{quizDataItem.course_name}</BreadcrumbsItem>
			<BreadcrumbsItem to="#">Quiz</BreadcrumbsItem>
			{/* main wrapper has
				quiz__left__wrapp
				quiz__right__wrapp
			*/}
			<div className="quiz__main__wrapper walkthrough-2022" style={{pointerEvents:'none'}}>
				{/* this section has
					quiz__header
					quizform
					quiz__footer
				*/}
				<div className="quiz__left__wrapp">
					{/* this section has
						quiz__header__content
						quiz__header__img
					*/}
					<div className="quiz__header">
						{/* this section has
							heading
							large-text
							small-text
						*/}
						<div className="quiz__header__content">
							<h2 className="h1">
								{/* <Link to={`/training-courses/${quizDataItem.course_slug}`} className="back__link" id="backLink">
									<img src={require("../../assets/img/quiz/back-arrow.svg").default} alt="true" />
								</Link> */}
								<span>{quizDataItem.course_name}</span>
							</h2>
							<p className="quiz__large">
								The quiz consists of 10 questions and each one rewards you with 1 point.<br/>Score 80% or higher
								to get your certificate.
							</p>
							<p className="small">
								Total points:<span>{quizDataItem.questions.length}</span>
							</p>
						</div>
						{/* this section has image */}
						<div className="quiz__header__img">
							<img
								src={require(`../../assets/img/training-quiz/tqicon-${quizDataItem.id}.svg`).default}
								alt={quizDataItem.course_name}
							/>
						</div>
					</div>
					{/* quiz__header end */}
					{/* form has  
						input
						quiz__body
					*/}
					<form id="quizForm">
						<input type="hidden" name="uid" value={quizDataItem.uid} />
						<input type="hidden" name="cid" value={quizDataItem.id} />
						<div className="quiz__body">{Quizes}</div>
					</form>
					{/* this section has submit button */}
					<div className="quiz__footer">
						{totSelected.length == quizDataItem.questions.length ? (
							<button  className="btn__submit">
								Submit
							</button>
						) : (
							<button  className="btn__submit" disabled={disabled}>
								Submit
							</button>
						)}

					</div>
				</div>
				{/* quiz__left__wrapp  end */}
				{/* this section has one card */}
				<div className="quiz__right__wrapp" style={{ display: "none" }}>
					{/* card has
						percenatage circle
						Retake Quiz
					*/}
					<div className="card">
						<div className="svg__cir__wrapp">
							<SvgCircle value={60} />
						</div>

						<button className="btn__retake__quiz">Retake Quiz</button>
					</div>
				</div>
				{/* quiz__right__wrapp end */}
			</div>
			{/* quiz__main__wrapper end */}

			{/* unsaved file popup start */}
			{props.isAnsPoup && (
				<div className="unsaved__ans__popup">
					{/* this section has
						close-button
						text
						btn-wrapper
					*/}
					<div className="popup__content">
						<button className="close_btn" onClick={props.HandleUnsavedAnsPopup}>
							<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="true" />
						</button>

						<p className="popup__text">
							<img src={require("../../assets/img/quiz/unsaved-file.svg").default} alt="true" />
							<span>You have unsaved answers. Do you want to Save your progress?</span>
						</p>
						<div className="btn__wrapper">
							<button className="no__thanks__btn">No thanks</button>
							<button className="save__ans__btn">Save my answers</button>
						</div>
					</div>
				</div>
			)}
			{/* unsaved file popup end */}
			{/* quiz popup start */} 

		</React.Fragment>
	);
};
//percentage circle for progress
const SvgCircle = (props) => {
	const { value } = props;
	return (
		<svg viewBox="0 0 36 36">
			<text className="percent__text" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="700">
				{value}
				<tspan fontSize="5">%</tspan>
			</text>
			<path
				className="circle-bg"
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke="#EBF5FA"
				strokeWidth="2.4"
				fill="none"
			></path>
			<path
				className="circle"
				strokeDasharray={`${value}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke={value < 80 && value > 0 ? "#FFBC1F" : value > 79 ? "#40D30D" : "transparent"}
				strokeWidth="2.4"
				fill="none"
			></path>
		</svg>
	);
};

//LeavePageConfirmPopup component
