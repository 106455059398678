/** imported components

  * Helmet ===> for add specific title & CSS for the page.

**/
import React from "react";
import { Helmet } from "react-helmet";
// PowerBiLossAnalysisView start
export const PowerBiLossAnalysisView = (props) => {
  return (
    <>
      <div>Hi</div>
      {/* Page title */}
      <Helmet>
        <link href={require("./powerbi-loss-analysis.scss").default} />
      </Helmet>
    </>
  );
};
