import React, { useState, useEffect} from 'react';
import { LossPoweBiResultView } from './LossPoweBiResultView';
import { Redirect,useParams } from 'react-router-dom';
import serviceProvider from '../../services/axios';

export const LossPoweBiResultCtrl = (props) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const login = localStorage.getItem("isLoggedIn");
    let { slug1,slug2 } = useParams();
    const [results, setResults] = useState(false);
    const [factoryLineFilter, setFactoryLineFilter] = useState(0);
    const [lineFilter, setLineFilter] = useState(0);
    const [lossFilter, setLossFilter] = useState(0);
    const [scidfilter, setScidfilter] = useState(slug2);
    const [runfilter, setRunfilter] = useState(0);
    const uid = user ? user.uid : 0;

    if(!login) {
        return <Redirect to="/sign-in" />;
    }
    
    useEffect(() => {
        const search = async () => {
            const apiUrl = `/businesscaseplantloss?param=lossresults&uid=${uid}&slug=${slug1}&factoryLineFilter=${factoryLineFilter}&lineFilter=${lineFilter}&lossFilter=${lossFilter}&scidfilter=${scidfilter}`;
            const { data } = await serviceProvider.get(apiUrl, true, {});
            setResults(data);
        };
        search();
    }, [runfilter]);


    if(results) {
        return (
          <>
            <LossPoweBiResultView dataResults={results} runfilter={runfilter} setRunfilter={setRunfilter}  setFactoryLineFilter={setFactoryLineFilter} setScidfilter={setScidfilter} setLineFilter={setLineFilter} setLossFilter={setLossFilter} factoryLineFilter={factoryLineFilter} lineFilter={lineFilter} lossFilter={lossFilter} scidfilter={scidfilter} {...props}/>
          </>
        );
    }else{
            return (
                <>
                <div></div>
                </>
            );
    }     
};

  

