/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api

**/

import React, { useEffect } from "react";
import "./manufacturing-cost-page.scss";
import $ from "jquery";
import { useState } from "react";
import serviceProvider from "../../services/axios";
import { FinancialValidationPageController } from "../financial-validation/financial-validation-page-ctrl";
//ManufacturingCostPageView component start
export const ManufacturingCostPageView = (props) => {
  //defining variables here
  const i = () => {};
  const [shown, setShown] = useState(false);
  const [isRightPopup, setIsRightPopup] = useState(null);
  const [validationpopup, setValidationPopup] = useState(false); // for migration
  const [areaList, setAreaList] = useState([
    {
      name: `Making`,
      editable: false,
    },
    {
      name: `Packing`,
      editable: false,
    },
    {
      name: `Other (Material Waste not related to the above production areas/lines)`,
      editable: false,
    },
  ]);
  const [materialList, setMaterialList] = useState([
    {
      name: `Total Raw Material Waste as % of Total Material Waste Cost`,
      editable: false,
    },
    {
      name: `Total Wrapping and Pack Material Waste as % of Total Material Waste Cost`,
      editable: false,
    },
    {
      name: `Other Material Waste as % of Total Material Waste Cost`,
      editable: false,
    },
  ]);
  const [tmcCostList, setTmcCostList] = useState([
    {
      name: `Cost of Direct Materials`,
      editable: false,
    },
    {
      name: `Cost of Direct Labor`,
      editable: false,
    },
  ]);
  const [manufacturingCostList, setManufacturingCostList] = useState([
    {
      name: `Energy Cost (Electricity, Natural gas & other)`,
      feild: `EUR`,
      editable: false,
    },
    {
      name: `% of energy consumption cost related to Production`,
      feild: `%`,
      editable: false,
      class: `ques-num percentage1`,
    },
  ]);
  const [repairCostList, setRepairCostList] = useState([
    {
      name: `Total Spare Part & Consumable Cost related to Production`,
      editable: false,
    },
    {
      name: `Total Maintenance Cost for Utilities & other`,
      editable: false,
    },
  ]);

  const [laborCostList, setLaborCostList] = useState([
    {
      name: `Quality Labor Cost`,
      editable: false,
    },
    {
      name: `Maintenance Labor Cost`,
      editable: false,
    },
    {
      name: `Warehouse & Material Handling Labor Cost`,
      editable: false,
    },
    {
      name: `IT Labor cost`,
      editable: false,
    },
    {
      name: `Environment, Healt & Safety Labor Cost`,
      editable: false,
    },
    {
      name: `Other`,
      editable: false,
    },
  ]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".conected_to_popup")) {
        setShown(false);
      }
    });
  });
  //function for final submit
  const Finalsubmit = (e) => {
    serviceProvider
      .post(
        "/businesscaseplantloss?type=lockanswer",
        JSON.stringify({
          slug: slug,
          uid: usid,
          pid: quesData.pid,
          sid: sid,
          ssid: ssid,
          allqueobj,
        }),
        true
      )
      .then((response) => {
        location.reload();
      });
  };
  //Function to handle right sec
  const HandleRightSec = (val) => {
    if (isRightPopup === val) {
      setIsRightPopup(null);
    } else {
      setIsRightPopup(val);
    }
  };
  //function for delete button
  const HandleDelBtnEvent = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(item);
    setIsRemovePopup(true);
  };

  useEffect(() => {
    // Get the modal
    var modal = document.getElementById("myModal");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    img.onclick = function (e) {
      modal.style.display = "block";
      modalImg.src = e.target.src;
      //captionText.innerHTML = .alt;
    };

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };

    // When the user scroll on div, open the shadow
    $(".manufacturing-cost-mid").scroll(() => {
      var scroll = $(".manufacturing-cost-mid").scrollTop();
      if (scroll > 0) {
        $(".outside_card").addClass("active");
      } else {
        $(".outside_card").removeClass("active");
      }
    });

    $(".listree1  input").on("click", function () {
      $(".listree1").addClass("change");
    });
    $(".listree2 input").on("click", function () {
      $(".listree2").addClass("change");
    });

    function showParents() {
      $(".color-changing").removeClass("xyz");
      var len = $("input.selected")
        .parents(".color-changing")
        .addClass("xyz").length;
    }
    $(" input").click(function () {
      $(this).toggleClass("selected");
      showParents();
    });

    $(function () {
      $(".tage1 input,textarea")
        .focus(function () {
          $(this)
            .data("placeholder", $(this).attr("placeholder"))
            .attr("placeholder", "");
        })
        .blur(function () {
          $(this).attr("placeholder", $(this).data("placeholder"));
        });
    });

    $(document).on("change", ".ques-num", function (event) {
      var inputval = parseInt(this.value);
      if ($(this).hasClass("percentage1") && inputval > 100) {
        inputval = 100;
        $(this).val(inputval);
      }
      var qtype = $(this).attr("data-qtype");
      if (qtype == 1) {
      } else {
        if (inputval) {
          $(this).val(inputval + "%");
        } else if (inputval == 0) {
          $(this).val(inputval + "%");
        }
      }
    });
  }, []);

  return (
    <>
      {/* wrapper has three parts
         manufacturing-cost-top
         manufacturing-cost-top-two
         manufacturing-cost-mid
         manufacturing-cost-bottom

   */}
      <section className="manufacturing-cost">
        {/* this section has row */}
        <div className="manufacturing-cost-top">
          {/* row has been divided into three parts
            manufacturing-cost-lft-col
            manufacturing-cost-mid-col
            la-ques-rgt-col
         */}
          <div className="manufacturing-cost-row">
            {/* this section has 
               back button
               page title
               category text
            */}
            <div className="manufacturing-cost-lft-col">
              <h1 className="h1-btn">
                <a className="back-link" href="/">
                  <svg
                    width={28}
                    height={28}
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d)">
                      <rect
                        x={2}
                        y={2}
                        width={24}
                        height={24}
                        rx={2}
                        fill="white"
                      />
                    </g>
                    <path
                      d="M19 7.4303L17.4833 6L9 14L17.4833 22L19 20.5697L12.0334 14L19 7.4303Z"
                      fill="#7D868C"
                    />
                    <defs>
                      <filter
                        id="filter0_d"
                        x={0}
                        y={0}
                        width={28}
                        height={28}
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation={1} />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </a>
                <span>Manufacturing Cost</span>
              </h1>
              <h4 className="h4-dull">Finance Data</h4>
              <div className="value_of_progress">
                <p>Progress:</p>
                <div className="bar-wrapper">
                  <span>0%</span>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: 0 }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                </div>
              </div>
            </div>
             {/* this section has assigned user list */}
            <div className="manufacturing-cost-mid-col">
              <div className="Assign">
                <div className="conected_to_popup">
                  <div className="popup">
                    {
                      <button onClick={() => setShown(!shown)}>
                        <div className="upward man_assign">
                          <img
                            src={
                              require("../../assets/img/fte-breakdown/man.svg")
                                .default
                            }
                            alt="digital_maturity"
                          />

                          <ul>
                            <li className="grey"> AB </li>
                            <li className="blue"> BC </li>
                            <li className="yellow"> JW </li>
                          </ul>
                        </div>
                      </button>
                    }
                    {shown ? (
                      <div className="assigned-user-popup1">
                        <span className="tooltip" />
                        <div className="assignd-user-popup-wrap">
                          <div className="pop-wrap">
                            <div className="img-box">
                              <ul>
                                <li className="grey"> AB </li>
                              </ul>
                            </div>
                            <div className="user-name">
                              <span className="name">Anette Black</span>
                              <span className="designation">Team Leader</span>
                            </div>
                          </div>
                          <div className="pop-wrap">
                            <div className="img-box">
                              <ul>
                                <li className="blue"> BC </li>
                              </ul>
                            </div>
                            <div className="user-name">
                              <span className="name">Bessie Cooper</span>
                              <span className="designation">
                                Plant Engineer
                              </span>
                            </div>
                          </div>
                          <div className="pop-wrap">
                            <div className="img-box">
                              <ul>
                                <li className="yellow"> JW </li>
                              </ul>
                            </div>
                            <div className="user-name">
                              <span className="name">Jenny Wilson</span>
                              <span className="designation">
                                Chief Engineer
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="direction_arrows">
                  <div className="upward">
                    <img
                      src={
                        require("../../assets/img/manufacturing-cost/upward.svg")
                          .default
                      }
                      alt="digital_maturity"
                    />
                    <p>Upload Excel file</p>
                  </div>
                  <div className="downword">
                    <img
                      src={
                        require("../../assets/img/manufacturing-cost/downword.svg")
                          .default
                      }
                      alt="digital_maturity"
                    />
                    <p>Download to Excel</p>
                  </div>
                </div>
              </div>
            </div>
            {/* this section has
               popup
               unlock button/ Data Submitted Button
            */}
            <div className="manufacturing-cost-rgt-col">
              <div className="top_close_box">
                <div className="brain_idea">
                  <img
                    id="myImg"
                    className="notification-img"
                    src={
                      require("../../assets/img/manufacturing-cost/bulb.svg")
                        .default
                    }
                    alt="digital_maturity"
                  />
                  <div
                    id="myModal"
                    className="modal"
                    style={{ display: "none" }}
                  >
                    <div className="inside_popup">
                      <img
                        id="img01"
                        className="modal-content"
                        src={
                          require("../../assets/img/manufacturing-cost/bulb.svg")
                            .default
                        }
                        alt="digital_maturity"
                      />
                      <p>
                        Please provide the key financial values based on the
                        past fiscal year.
                      </p>
                      <p>
                        <span className="close">
                          <img
                            src={
                              require("../../assets/img/manufacturing-cost/close.svg")
                                .default
                            }
                            alt="digital_maturity"
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         {/* this section has one card */}
        <div className="manufacturing-cost-top-two">
            {/* card has two parts
                   work_space
                   empty 
               */}
          <div className="outside_card">
             {/* this section has two parts
                  heading
                  percentage circle
            */}
            <div className="work_space">
              <div className="what_percentage">
                <div className="total">Plant Data:</div>
              </div>
              <div className="what_percentage actual-position">
                <div className="total">2021 Actual</div>
              </div>
            </div>
            <div className="empty" />
          </div>
        </div>
        {/* this section has another wrapper */}
        <div className="manufacturing-cost-mid">
        {/* main wrapper of the list */}
          <div className="white_space">
             {/* inside this card there are
                  one main list
                  inside main list has one or more than one sublists
            */}
            <div className="inside_cards">
              <div class="listree1">
                <ul>
                  <li className="listree">
                    <div className="listree-menu-heading ">
                      <div className="work-type listing-input">
                        <button
                          className={`total-number  ${
                            isRightPopup === "h1" + i + 1 && "active"
                          }`}
                          onClick={() => HandleRightSec("h1" + i + 1)}
                        >
                          <div className="info">
                            <svg
                              width="{16}"
                              height="{16}"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                fill="#CBCFD2"
                              />
                            </svg>
                          </div>
                          <p>Total Material Waste Cost:</p>
                        </button>
                        <input
                          type="text"
                          name="percentage"
                          placeholder="EUR"
                          disabled
                        />
                      </div>
                    </div>
                    <ul className="listree-submenu">
                      <li className="submenu">
                        <div className="listree-submenu-heading color-changing">
                          <div className="work-type listing-input">
                            <button
                              className={`total-number  ${
                                isRightPopup === "subh1" + i + 1 && "active"
                              }`}
                              onClick={() => HandleRightSec("subh1" + i + 1)}
                            >
                              <div className="info">
                                <svg
                                  width="{16}"
                                  height="{16}"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                    fill="#CBCFD2"
                                  />
                                </svg>
                              </div>
                              <p>Production Areas/Lines:</p>
                            </button>
                          </div>
                          <ul className="listree-submenu-items">
                            {areaList.map((items, x) => {
                              return (
                                <li className="work-type listing-input">
                                  <button
                                    title={items.name}
                                    className={`side-slid-icon-button ${
                                      isRightPopup === x + 1 && "active"
                                    }`}
                                    onClick={() => HandleRightSec(x + 1)}
                                  >
                                    <div className="info">
                                      <svg
                                        width="{16}"
                                        height="{16}"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                          fill="#CBCFD2"
                                        />
                                      </svg>
                                    </div>
                                    <p>{items.name}</p>
                                  </button>
                                  <input
                                    type="text"
                                    name="percentage"
                                    placeholder="EUR"
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className="submenu">
                        <div className="listree-submenu-heading color-changing collapsible before bold exepend">
                          <div className="work-type listing-input after">
                            <button
                              className={`total-number  ${
                                isRightPopup === "subh2" + i + 1 && "active"
                              }`}
                              onClick={() => HandleRightSec("subh2" + i + 1)}
                            >
                              <div className="info">
                                <svg
                                  width="{16}"
                                  height="{16}"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                    fill="#CBCFD2"
                                  />
                                </svg>
                              </div>
                              <p>
                                Type of Waste as % of Total Material Waste Cost:
                              </p>
                            </button>
                          </div>
                          <ul className="listree-submenu-items">
                            {materialList.map((items, x) => {
                              return (
                                <li className="work-type listing-input">
                                  <button
                                    title={items.name}
                                    className={`side-slid-icon-button ${
                                      isRightPopup === "mlist" + x + 1 &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      HandleRightSec("mlist" + x + 1)
                                    }
                                  >
                                    <div className="info">
                                      <svg
                                        width="{16}"
                                        height="{16}"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                          fill="#CBCFD2"
                                        />
                                      </svg>
                                    </div>
                                    <p>{items.name}</p>
                                  </button>
                                  <input
                                    type="text"
                                    name="percentage"
                                    placeholder="%"
                                    className="ques-num percentage1"
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="listree2">
                <ul>
                  <li className="listree">
                    <div className="listree-menu-heading collapsed before ">
                      <div className="work-type listing-input after">
                        <button
                          className={`total-number  ${
                            isRightPopup === "h2" + i + 1 && "active"
                          }`}
                          onClick={() => HandleRightSec("h2" + i + 1)}
                        >
                          <div className="info">
                            <svg
                              width="{16}"
                              height="{16}"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                fill="#CBCFD2"
                              />
                            </svg>
                          </div>
                          <p>Total Manufacturing Cost (TMC):</p>
                        </button>
                        <input
                          type="text"
                          name="percentage"
                          placeholder="EUR"
                          disabled
                        />
                      </div>
                    </div>
                    <ul className="listree-submenu">
                      {tmcCostList.map((items, x) => {
                        return (
                          <li className="work-type listing-input">
                            <button
                              title={items.name}
                              className={`side-slid-icon-button ${
                                isRightPopup === "tmclist" + x + 1 && "active"
                              }`}
                              onClick={() => HandleRightSec("tmclist" + x + 1)}
                            >
                              <div className="info">
                                <svg
                                  width="{16}"
                                  height="{16}"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                    fill="#CBCFD2"
                                  />
                                </svg>
                              </div>
                              <p>{items.name}</p>
                            </button>
                            <input
                              type="text"
                              name="percentage"
                              placeholder="EUR"
                            />
                          </li>
                        );
                      })}
                      <li className="submenu">
                        <div className="listree-submenu-heading color-changing">
                          <div className="work-type listing-input after">
                            <button
                              className={`total-number  ${
                                isRightPopup === "subh3" + i + 1 && "active"
                              }`}
                              onClick={() => HandleRightSec("subh3" + i + 1)}
                            >
                              <div className="info">
                                <svg
                                  width="{16}"
                                  height="{16}"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                    fill="#CBCFD2"
                                  />
                                </svg>
                              </div>
                              <p>Cost of Manufacturing Overhead:</p>
                            </button>
                            <input
                              type="text"
                              name="percentage"
                              placeholder="EUR"
                              disabled
                            />
                          </div>
                          <ul className="listree-submenu-items">
                            {manufacturingCostList.map((items, x) => {
                              return (
                                <li className="work-type listing-input">
                                  <button
                                    title={items.name}
                                    className={`side-slid-icon-button ${
                                      isRightPopup === "mOverlist" + x + 1 &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      HandleRightSec("mOverlist" + x + 1)
                                    }
                                  >
                                    <div className="info">
                                      <svg
                                        width="{16}"
                                        height="{16}"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                          fill="#CBCFD2"
                                        />
                                      </svg>
                                    </div>
                                    <p>{items.name}</p>
                                  </button>
                                  <input
                                    type="text"
                                    name="percentage"
                                    placeholder={items.feild}
                                  />
                                </li>
                              );
                            })}
                            <li className="submenu">
                              <div className="listree-submenu-heading color-changing">
                                <div className="work-type listing-input after">
                                  <button
                                    className={`total-number  ${
                                      isRightPopup === "subh4" + i + 1 &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      HandleRightSec("subh4" + i + 1)
                                    }
                                  >
                                    <div className="info">
                                      <svg
                                        width="{16}"
                                        height="{16}"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                          fill="#CBCFD2"
                                        />
                                      </svg>
                                    </div>
                                    <p>Total Indirect Labor Cost:</p>
                                  </button>
                                  <input
                                    type="text"
                                    name="percentage"
                                    placeholder="EUR"
                                    disabled
                                  />
                                </div>
                                <ul className="listree-submenu-items">
                                  {laborCostList.map((items, x) => {
                                    return (
                                      <li className="work-type listing-input">
                                        <button
                                          title={items.name}
                                          className={`side-slid-icon-button ${
                                            isRightPopup ===
                                              "lcostlist" + x + 1 && "active"
                                          }`}
                                          onClick={() =>
                                            HandleRightSec("lcostlist" + x + 1)
                                          }
                                        >
                                          <div className="info">
                                            <svg
                                              width="{16}"
                                              height="{16}"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                                fill="#CBCFD2"
                                              />
                                            </svg>
                                          </div>
                                          <p>{items.name}</p>
                                        </button>
                                        <input
                                          type="text"
                                          name="percentage"
                                          placeholder="EUR"
                                        />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </li>
                            <li className="listree-submenu-heading before  tage1">
                              <div className="work-type listing-input after">
                                <button
                                  className={`total-number  ${
                                    isRightPopup === "subh5" + i + 1 && "active"
                                  }`}
                                  onClick={() =>
                                    HandleRightSec("subh5" + i + 1)
                                  }
                                >
                                  <div className="info">
                                    <svg
                                      width="{16}"
                                      height="{16}"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                        fill="#CBCFD2"
                                      />
                                    </svg>
                                  </div>
                                  <p>Equipment Depreciation</p>
                                </button>
                                <input
                                  type="text"
                                  name="percentage"
                                  placeholder="EUR"
                                />
                              </div>
                            </li>
                            <li className="submenu">
                              <div className="listree-submenu-heading collapsible before bold exepend color-changing">
                                <div className="work-type listing-input after">
                                  <button
                                    className={`total-number  ${
                                      isRightPopup === "subh6" + i + 1 &&
                                      "active"
                                    }`}
                                    onClick={() =>
                                      HandleRightSec("subh6" + i + 1)
                                    }
                                  >
                                    <div className="info">
                                      <svg
                                        width="{16}"
                                        height="{16}"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                          fill="#CBCFD2"
                                        />
                                      </svg>
                                    </div>
                                    <p>
                                      Repair & Maintenance Cost (i.e. Spare
                                      parts, tools, other than labor):
                                    </p>
                                  </button>
                                  <input
                                    type="text"
                                    name="percentage"
                                    placeholder="EUR"
                                  />
                                </div>
                                <ul className="listree-submenu-items">
                                  {repairCostList.map((items, x) => {
                                    return (
                                      <li className="work-type listing-input">
                                        <button
                                          title={items.name}
                                          className={`side-slid-icon-button ${
                                            isRightPopup === "rlist" + x + 1 &&
                                            "active"
                                          }`}
                                          onClick={() =>
                                            HandleRightSec("rlist" + x + 1)
                                          }
                                        >
                                          <div className="info">
                                            <svg
                                              width="{16}"
                                              height="{16}"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                                fill="#CBCFD2"
                                              />
                                            </svg>
                                          </div>
                                          <p>{items.name}</p>
                                        </button>
                                        <input
                                          type="text"
                                          name="percentage"
                                          placeholder="EUR"
                                        />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </li>
                            <li className="listree-submenu-heading  ">
                              <div className="work-type listing-input after">
                                <button
                                  className={`total-number  ${
                                    isRightPopup === "subh7" + i + 1 && "active"
                                  }`}
                                  onClick={() =>
                                    HandleRightSec("subh7" + i + 1)
                                  }
                                >
                                  <div className="info">
                                    <svg
                                      width="{16}"
                                      height="{16}"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                        fill="#CBCFD2"
                                      />
                                    </svg>
                                  </div>
                                  <p>Other</p>
                                </button>
                                <input
                                  type="text"
                                  name="percentage"
                                  placeholder="EUR"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* this section has submit button */}
        <div className="manufacturing-cost-bottom">
          <div className="submit-page">
            <button>
              <div className="submit">Submit</div>
            </button>
          </div>
        </div>
      </section>
      {/* right sidebar popup has four parts
         top-part
         content
         leave-box
         submit
      */}
      <div className={`maintenance-losses-activities ${isRightPopup && "active"}`} >
        <div className="top-part">
          <img
            src={
              require("../../assets/img/maintenance_losses/double-chat.svg")
                .default
            }
            alt="chat"
          />
          <div className="close">
            <button onClick={() => HandleRightSec(null)}>
              <img
                src={
                  require("../../assets/img/maintenance_losses/close.svg")
                    .default
                }
                alt="chat"
              />
            </button>
          </div>
        </div>
        <span>Planned maintenance activities</span>
        <p>
          The % of time a production process or production line is not available
          for production due to scheduled maintenance activities.
        </p>
        <p className="leave">Leave a Comment</p>
        <div className="leave-box">
          In case needed, please provide additional information about your data
          input (assumptions, clarifications)
        </div>
        <div className="submit">
          <button>Submit </button>
        </div>
      </div>
      {/* validation popup component */}
      {validationpopup && (
        <FinancialValidationPageController
          Finalsubmit={Finalsubmit}
          {...props}
        />
      )}
    </>
  );
};
