/** imported components

  * Moment ===> component from react-moment to interact with the date and time problem domain.
  * useState ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api

**/
import React, { useState } from "react";
import serviceProvider from '../../services/axios'
import Moment from "react-moment";
import { Link } from "react-router-dom";
export const NotificationView = (props) => {
	//defining variables here
	const [rate, setRate] = useState(1);
	const notificationDataItems = props.notificationData;
	if (!notificationDataItems.length) {
		return null;
	}
	const notificationDataItem = props.notificationData[0];
	const notificationData = notificationDataItem.notifications;
	const uid = notificationDataItem.uid;
	//Funtion to mark all notification read
	const markAllRead = () => { 
	    let unread=0;
		props.setHasNewNotification(0); 
		setRate(rate + 1);
		serviceProvider.post("/notification?type=markAllRead", JSON.stringify({ uid: uid }), true).then((response) => {
			props.onRefresh(rate);
		});
	};
	//Function to clear notifications
	const clearAll = () => {
		setRate(rate + 1);
		serviceProvider.post("/notification?type=clearAll", JSON.stringify({ uid: uid }),true).then((response) => {
			props.onRefresh(rate);
		});
		
	};
	//Function for mark unread
	const markUnread = (nid) => {
		setRate(rate + 1);
		serviceProvider.post("/notification?type=unread", JSON.stringify({ uid: uid, nid: nid }), true).then((response) => {
			props.onRefresh(rate);
		});
	};
	//Function for read notification
	const markRead = (nid) => {
		setRate(rate + 1);
		serviceProvider.post("/notification?type=read", JSON.stringify({ uid: uid, nid: nid }),true).then((response) => {
			props.onRefresh(rate);
		});
	};
	//Function for clear one comment
	const clearOne = (nid) => {
		setRate(rate + 1);
		serviceProvider.post("/notification?type=clearOne", JSON.stringify({ uid: uid, nid: nid }),true).then((response) => {
			props.onRefresh(rate);
		});
	};
	let unread=0;
	if (notificationData.length) {
		const notificationList = notificationData.map((data, index) => {
			
			unread = data.is_read == 0 ? unread+1 : unread;
			return (
				// notification wrapper
				<li key={data.id} className={data.is_read == 0 ? "not_li" : "not_li active_read"}>
					{/* In this section two parts
						description of notification
						time of notification 
					 */}
					<div className="lft-wrap">
						<p>
							{/* <span>Judit Bernat</span> mentioned you in a comment on
							<span>Assessment: Operational Excellence section</span> 
							<div onClick={() => markRead(data.id)} className="refreshReply" dangerouslySetInnerHTML={{ __html: data.description }}></div>*/}
							{data.is_read == 0 ? (
								<div onClick={() => markRead(data.id)} className="refreshReply " dangerouslySetInnerHTML={{ __html: data.description }}></div>
							) : (
								<div onClick={() => markRead(data.id)} className="refreshReply read_already" dangerouslySetInnerHTML={{ __html: data.description }}></div>
							)}
							
						</p>
						<span>
							{data.time_ago}
						</span>
					</div>
					{/* This section has three buttons
						cross button for clear notification
						mark unread/read notification both comes conditionally 
					*/}
					<div className="operate-icon">
						<span onClick={() => clearOne(data.id)} className="clear-notification">
							<img src={require("../../assets/img/top-header/cross.svg").default} alt="" className="cross-mark" />
							<div className="clear_notf_ttip">Clear notification</div>
						</span>
						{data.is_read == 0 ? (
							<span onClick={() => markRead(data.id)} className="mark-read">
								<div className="mark_unread_ttip">Mark as read</div>
							</span>
						) : (
						    //setUnread(1);
							<span onClick={() => markUnread(data.id)} className="mark-read">
								<div className="mark_unread_ttip">Mark as unread</div>
							</span>
						)}
					</div>
				</li>
			);
		});
		
		
		return (
			<>
				{/* Notification head divided into three parts 
					Notification title
					Mark all as read button
					Clear All button
				*/}
				<div className="upgrd-not-head">
					<span className="notification-ttl">Notifications</span>
					{unread>0 && 
					<button onClick={markAllRead} className="mark-ttl">
						Mark all as read
					</button>
					}
					<button onClick={clearAll} className="clear-ttl">
						Clear All
					</button>
					<button  className="close-btn s-10-2021" onClick={props.HandleNotificationPopup}>
							<img src={require("../../assets/img/top-header/cross.svg").default} alt=""/>
					</button>
				</div>
				{/* This section contain list of notifications */}
				<div className="upgrd-not-list">
					<ul>{notificationList}</ul>
				</div>
			</>
		);
	} else {
		return (
			<>
				{/* Zero notification head divided in two section 
					Left sectioin has notification title
					Right section has close button				
				*/}
				<div className="upgrd-not-head">
					<span className="notification-ttl">Notifications</span>
					<button  className="close-btn s-10-2021" onClick={props.HandleNotificationPopup}>
							<img src={require("../../assets/img/top-header/cross.svg").default} alt=""/>
					</button>
				</div>
				{/* When there is no notification then wrapper has
					image
					heading
					text
					related to zero notification
				*/}
				<div className="upgrd-not-list">
					<div className="zero_notification">
						<img src={require("../../assets/img/top-header/no_notification.svg").default} alt="" className="no_notification" />
						<h4>No notifications right now</h4>
						<p>
							When there's an activity on the platform, <br></br> we'll show the notifications here.
						</p>
					</div>
				</div>
			</>
		);
	}
};
