import React, { useState, useEffect } from 'react';
import serviceProvider from '../../services/axios'
import { useHistory, Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Collapse } from '@material-ui/core';
import { Helmet } from "react-helmet";
import { TaskCenterWalkthroughView } from "./TaskCenterWalkthroughView";
import { apiEndpoints } from '../../services/api-endpoints';
import {
    MainWrapper,
    Header,
    Heading,
    HeaderRight,
    HeaderRightButton,
    HeaderFilterDropdown,
    HeaderFilterButton,
    HeaderFilterCard,
    CardHeader,
    SearchBox,
    CardBody,
    CardFooter,
    ApplyFilterButton,
    FilterList,
    Container,
    ScrollWrapper,
    TableCard,
    TableCardeHeading,
    TableCardRow,
    TableCardHeader,
    TableCardBody,
    PlatFormToolCol,
    NameCol,
    UpdatedCol,
    AssignedCol,
    StatusCol,
    ActionCol,
    AssignedUser,
    ActionNeededLink,
    DropUserCard,
    StausLabel,
    TablePlantName,
    FlagLabel,
    PlantTableBody,
    PlantBtnWrap,
    AddBtn,
    AssignedToCol,
} from './styles';
import {
    refreshIcon,
    btnOvalIcon,
    downAngleIcon,
    searchIcon,
    serchClearIcon,
    checkBoxIcon,
    myLearningIcon,
    arrowForwardIcon,
    myPlantIcon, emptyPlantSvg,
    collapseArrow,
    mobileIcon,
    mobileCloseIcon,
} from './svg';

export const TaskCenterViewAssesor = (props) => {
    const resultItems = props.result;
    if (!resultItems.length) {
        return null;
    }
    const users = JSON.parse(localStorage.getItem("userData"));
    const group_id = users ? users.group_id : ''; /*if 14 it's simple user else 2 or 11 for admin*/
    // Walkthrough Modal
    const [activeSlide, setActiveSlide] = useState(0);
    const [showTCWalkthrough, setShowTCWalkthrough] = useState(true);
    // end
    // Collapse feature for plant CDP-481
    const [collapseArray, setCollapseArray] = useState([]);
    // dropdown CDP-481
    const [showDropUserCard, setShowDropUserCard] = useState(false);
    const myPlantList = resultItems[0].all_plants;
    const exist_plants = resultItems[0].exist_plants;
    const base_url = resultItems[0].base_url;
    const myLearningData = resultItems[0].all_skills_trainings;
    const total = resultItems[0].total;
    const completed = resultItems[0].completed;
    const inprogress = resultItems[0].inprogress;
    const todo = resultItems[0].todo;
    const taskcenterwlakthrough = resultItems[0].taskcenterwlakthrough;
    const plantdata = [{
        id: '1',
        name: 'Bridgewater',
        country: 'Athens'
    }
    ]
    const [myLearningFilterData, setMyLearningFilterData] = useState([]);
    const [myPlanFiltertList, setMyPlanFiltertList] = useState([]);
    const [filterArr, setFillterArr] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filterPlant, setFilterPlant] = useState([]);
    const [txtSearch, setTxtSearch] = useState('');
    const [checkedPlantFilter, setCheckedPlantFilter] = useState([]);
    const [appliedFilterArray, setAppliedFilterArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterTodoCount, setFilterTodoCount] = useState(-1);
    const [filterInprogressCount, setFilterInprogressCount] = useState(-1);
    const [filterCompletedCount, setFilterCompletedCount] = useState(-1);
    const [mobileActive, setMobileActive] = useState(true)
    const [page, setPage] = useState(1);
    const singlePagePlant = 4;
    const history = useHistory();
    // function to refresh page
    const refreshPage = () => {
        window.location.reload();
    }

    //handleSearch function
    const handleSearch = (e) => {
        let val = e.target.value;
        setTxtSearch(val);
        let tmpFilterData = myPlantList.filter((item, index) => item.plant_name.toLowerCase().startsWith(val.toLowerCase()));

        if (val.length == 0) {
            setFilterPlant(myPlantList);
        } else {
            setFilterPlant(tmpFilterData);
        }
    }

    // clear filter search
    const clearSearch = (e) => {
        setTxtSearch('');
        e?.stopPropagation();
        setFilterPlant(myPlantList);
    }

    // handle filter status
    const handleFilterTabel = (data) => {
        let tmpLearningData = [];
        let tmpPlantData = []
        let tmpCollapseArr = [...collapseArray];
        let tmpArr = [...filterArr];
        let getIndex = tmpArr.indexOf(data);
        if (getIndex == -1) {
            tmpArr.push(data);
            setFillterArr(tmpArr);
        } else {
            let removeItemArr = tmpArr.filter(item => item != data);
            tmpArr = removeItemArr;
            setFillterArr(removeItemArr);
        }
        // code to expand and collapse the plants according to filter 
        myPlantList?.forEach(plant => {
            if (plant.list.findIndex(list => tmpArr.includes(list.status)) > -1) {
                if (!tmpCollapseArr.includes(plant.id)) {
                    tmpCollapseArr.push(plant.id);
                }
            } else {
                let removePlantArr = tmpCollapseArr.filter(i => i != plant.id || appliedFilterArray.includes(plant.id));
                tmpCollapseArr = removePlantArr;
            }
        });
        setCollapseArray(tmpCollapseArr);
    }

    // handle dropdown Toggle
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    // handleCheck
    const handleCheck = (type) => {
        let allValueChk = document.querySelector('#chkAllPlant');
        let chkBoxs = document.getElementsByName('chkPlantFilter');
        let tmpValue = [];
        if (type == 'all') {
            if (allValueChk.checked == true) {
                chkBoxs.forEach(item => {
                    item.checked = true;
                    tmpValue.push(item.value);
                });
            } else {
                chkBoxs.forEach(item => { item.checked = false });
            }
        }
        else {
            let tmpArr = [];
            chkBoxs.forEach(item => {
                if (item.checked == true) {
                    tmpArr.push(item.value);
                    tmpValue.push(item.value);
                }
            });
            if (tmpArr.length == chkBoxs.length) {
                allValueChk.checked = true;
            } else {
                allValueChk.checked = false;
            }
        }
        setCheckedPlantFilter(tmpValue);
    }
    // apply filter function
    const applyFilter = () => {
        let tmpArr = [];
        let tmpCollapseArr = [...collapseArray];
        let allSelectedPlant = [...myLearningData];
        let chkBoxs = document.getElementsByName('chkPlantFilter');
        chkBoxs.forEach(item => {
            if (item.checked == true) {
                tmpArr.push(item.value);
                /*Start code to combine all list into "allSelectedPlant" */
                let tmpPlant = myPlantList.filter(d => d.id == item.value);
                tmpPlant.forEach(d => {
                    allSelectedPlant.push(...d.list);
                    if (!tmpCollapseArr.includes(d.id)) {
                        tmpCollapseArr.push(d.id);
                    }
                });
                /*End code to combine all list into "allSelectedPlant" */
            } else {
                // code to un collapse 
                let tmpPlant = myPlantList.find(d => d.id == item.value);
                let tmpRemovefilterArr = tmpCollapseArr.filter(i => i != item.value || tmpPlant?.list?.findIndex(itm => filterArr.includes(itm.status)) > -1);
                tmpCollapseArr = tmpRemovefilterArr;
            }
        })
        /*Start-> filter "allSelectedPlant" status wise*/
        let tmpTodo = allSelectedPlant.filter(d => d.status == 'todo');
        let tmpInProgress = allSelectedPlant.filter(d => d.status == 'inprogress');
        let tmpCompleted = allSelectedPlant.filter(d => d.status == 'completed');
        if (tmpArr.length > 0) {
            setFilterTodoCount(tmpTodo.length);
            setFilterInprogressCount(tmpInProgress.length);
            setFilterCompletedCount(tmpCompleted.length);
        } else {
            setFilterTodoCount(-1);
            setFilterInprogressCount(-1);
            setFilterCompletedCount(-1);
        }
        /*End-> fiter "allSelectedPlant" status wise*/
        setAppliedFilterArray(tmpArr);
        setIsDropdownOpen(false);
        setCollapseArray(tmpCollapseArr);
    }

    //this function will call on scroll "<Container>" 
    const addPlantOnScroll = (el) => {

        // let elementHeight=el.target.scrollHeight;
        // if((el.target.scrollTop+el.target.offsetHeight)+1>=elementHeight && myPlantList.length>myPlanFiltertList.length){
        //     let tmpPage=page+1;
        //     setLoading(true);
        //     setPage(tmpPage);
        //     setMyPlanFiltertList(myPlantList.slice(0,page*singlePagePlant));
        //     setTimeout(()=>{
        //         setLoading(false);
        //     }
        //     ,1000) 
        // }
    }
    //this useEffect will be call only first time

    const openSidebarAddPlantPopup = () => {
        let sidebarAddPopupBtn = document.querySelector('.ng-sidebar-wrapper .ed_addpl_btn');
        sidebarAddPopupBtn?.click();
    }
    // Function to handle collapse content for plants CDP-481
    const handleCollapse = (id) => {
        let filterArray = collapseArray.filter(i => i !== id);
        if (collapseArray.includes(id)) {
            setCollapseArray(filterArray);
        }
        else {
            filterArray.push(id);
            setCollapseArray(filterArray);
        }
    }
    // Function to handle dropdown for assigned to user CDP-481
    const handleDropUserCard = (id) => {
        if (showDropUserCard === id) {
            setShowDropUserCard(false);
        }
        else {
            setShowDropUserCard(id);
        }
    }

    // Mobile changes
    const handleTaskRowPopup = (val)=>{
        setMobileActive(val);
        let training_content = document.querySelector('.training-main-wrapper');
        training_content?.classList.add('task-mobile-active');
    }

    const handleClosePopup = ()=>{
        setMobileActive(false);
        let training_content = document.querySelector('.training-main-wrapper');
        training_content?.classList.remove('task-mobile-active');
        let tableCard = document.querySelectorAll("[data-component = TableCardBody]");
        tableCard?.forEach(item=>{
            item?.classList.remove('active');
        });
    }


    // 
    useEffect(() => {
        // function to collapse sidebar
        props.handleExpandFal();
        const urlParams = new URLSearchParams(window.location.search);
        if (myLearningData) {
            setMyLearningFilterData(myLearningData);
        }
        if (myPlantList) {
            // setMyPlanFiltertList(myPlantList.slice(0,page*singlePagePlant));
            setMyPlanFiltertList(myPlantList);
            setFilterPlant(myPlantList);
        }
        if (props.onfilter == 'todo') {
            handleFilterTabel("todo");

            if (urlParams.has('filter')) {
                history.replace('/task-center')
            }
        }
        if (props.onfilter == 'inprogress') {
            handleFilterTabel("inprogress");

            if (urlParams.has('filter')) {
                history.replace('/task-center')
            }
        }
        if (props.onfilter == 'completed') {
            handleFilterTabel("completed");

            if (urlParams.has('filter')) {
                history.replace('/task-center')
            }
        }

        // to set all Plant filter array from local storage;
        let tmpCheckFilterArr = localStorage.getItem('taskfilter');
        if (tmpCheckFilterArr) {
            setCheckedPlantFilter(JSON.parse(tmpCheckFilterArr));
            setAppliedFilterArray(JSON.parse(tmpCheckFilterArr));
             // code to top show todo,inprogress and completed count;
             let tmpArr = [];
             let allSelectedPlant = [...myLearningData];
             let chkBoxs = JSON.parse(tmpCheckFilterArr);
             chkBoxs.forEach(item => {
                 tmpArr.push(item);
                 let tmpPlant = myPlantList.filter(d => d.id == item);
                     tmpPlant.forEach(d => {
                         allSelectedPlant.push(...d.list);                            
                     });
             })
             /*Start-> filter "allSelectedPlant" status wise*/
             let tmpTodo = allSelectedPlant.filter(d => d.status == 'todo');
             let tmpInProgress = allSelectedPlant.filter(d => d.status == 'inprogress');
             let tmpCompleted = allSelectedPlant.filter(d => d.status == 'completed');
             if (tmpArr.length > 0) {
                 setFilterTodoCount(tmpTodo.length);
                 setFilterInprogressCount(tmpInProgress.length);
                 setFilterCompletedCount(tmpCompleted.length);
             } else {
                 setFilterTodoCount(-1);
                 setFilterInprogressCount(-1);
                 setFilterCompletedCount(-1);
             }
         //code to top show todo,inprogress and completed count end
            localStorage.setItem("taskfilter", []);
        };

        // to remove all plant filter array when the component is unmounted
        return () => {
            localStorage.removeItem('taskfilter');
        }

    }, [])

    //this useEffect will be call first time and when isDropdownOpen value will be change
    // assigned to user dropdown added here CDP-481
    useEffect(() => {
        document.onclick = (e) => {
            if (!e.target.closest('#ddlFilter') && isDropdownOpen) {
                toggleDropdown();
                clearSearch();
                setCheckedPlantFilter(appliedFilterArray);
            }
            // CDP-481
            if (!e.target.closest('.assigned-to-cir-wrapper')) {
                setShowDropUserCard(false)
            }
        }
    }, [isDropdownOpen])


    return (
        <>
            {/* this section has page title */}
            <Helmet>
                <title>My Tasks | SmarterChains</title>
                <meta name="description" content="My Tasks" />
                <meta name="viewport" content="width=device-width, initial-scale=1" /> 
            </Helmet>
            {/* breadcrumbs */}
            <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="#">My Tasks</BreadcrumbsItem>
            <MainWrapper data-component="MainWrapper">
                <Header data-component="Header">
                    <Heading data-component="Heading">My Tasks</Heading>
                    <HeaderRight data-component="HeaderRight" className="walk-3">
                        <HeaderRightButton data-component="HeaderRightButton" count onClick={refreshPage}>
                            Total tasks: <span className="count">{total}</span>
                            <i className='refresh' dangerouslySetInnerHTML={{ __html: refreshIcon }}></i>
                        </HeaderRightButton>
                        {/* HeaderRightButton ended*/}

                        <HeaderRightButton data-component="HeaderRightButton" onClick={() => handleFilterTabel('todo')} className={filterArr.indexOf('todo') > -1 ? 'active' : ''} red>
                            <i className='oval' dangerouslySetInnerHTML={{ __html: btnOvalIcon }}></i>
                            To Do: <span className="count">
                                {
                                    filterTodoCount == -1 ?
                                        todo
                                        :
                                        filterTodoCount
                                }
                            </span>
                        </HeaderRightButton>
                        {/* HeaderRightButton ended*/}

                        <HeaderRightButton data-component="HeaderRightButton" onClick={() => handleFilterTabel('inprogress')} className={filterArr.indexOf('inprogress') > -1 ? 'active' : ''} yellow>
                            <i className='oval' dangerouslySetInnerHTML={{ __html: btnOvalIcon }}></i>
                            In Progress: <span className="count">
                                {
                                    filterInprogressCount == -1 ?
                                        inprogress
                                        :
                                        filterInprogressCount
                                }
                            </span>
                        </HeaderRightButton>
                        {/* HeaderRightButton ended*/}

                        <HeaderRightButton data-component="HeaderRightButton" onClick={() => handleFilterTabel('completed')} className={filterArr.indexOf('completed') > -1 ? 'active' : ''} green>
                            <i className='oval' dangerouslySetInnerHTML={{ __html: btnOvalIcon }}></i>
                            Completed:  <span className="count">
                                {
                                    filterCompletedCount == -1 ?
                                        completed
                                        :
                                        filterCompletedCount
                                }
                            </span>
                        </HeaderRightButton>
                        {/* HeaderRightButton ended*/}

                        <HeaderFilterDropdown data-component="HeaderFilterDropdown" open={isDropdownOpen} id="ddlFilter" style={{ display: myPlantList?.length > 0 ? '' : 'none' }}>
                            <HeaderFilterButton data-component="HeaderFilterButton" onClick={toggleDropdown} className={isDropdownOpen ? 'active' : ''}>
                                {
                                    appliedFilterArray.length == 1 && appliedFilterArray.length != filterPlant.length ? `Plant Selected: ${appliedFilterArray.length}`
                                        : appliedFilterArray.length > 0 && appliedFilterArray.length != filterPlant.length ? `Plants Selected: ${appliedFilterArray.length}`
                                            : `All Plants`
                                }
                                <i className='refresh' dangerouslySetInnerHTML={{ __html: downAngleIcon }}></i>
                            </HeaderFilterButton>
                            <HeaderFilterCard data-component="HeaderFilterCard">
                                <CardHeader data-component="CardHeader">
                                    <SearchBox data-component="SearchBox">
                                        <i className='search--icon' dangerouslySetInnerHTML={{ __html: searchIcon }}></i>
                                        <input type="text" placeholder='Search Plants' onChange={handleSearch} value={txtSearch} />
                                        {txtSearch.length > 0 &&
                                            <i className='search--clear' dangerouslySetInnerHTML={{ __html: serchClearIcon }} onClick={(e) => clearSearch(e)} />
                                        }
                                    </SearchBox>
                                </CardHeader>
                                {/* CardHeader end  */}
                                <CardBody data-component="CardBody">
                                    <FilterList data-component="FilterList">
                                        <input type="checkbox" id="chkAllPlant"
                                            checked={filterPlant.length == checkedPlantFilter.length && filterPlant.length != 0}
                                            onChange={() => handleCheck('all')}
                                        />
                                        <i dangerouslySetInnerHTML={{ __html: checkBoxIcon }}></i>
                                        <span>All Plants</span>
                                    </FilterList>
                                    {filterPlant?.map((item, index) => (
                                        <FilterList data-component="FilterList" key={`filter${index}`}>
                                            <input type="checkbox" value={item.id} checked={checkedPlantFilter.indexOf(item.id) != -1} name="chkPlantFilter" onChange={handleCheck} />
                                            <i dangerouslySetInnerHTML={{ __html: checkBoxIcon }}></i>
                                            <span>{item.plant_name}</span>
                                        </FilterList>
                                    )
                                    )

                                    }
                                </CardBody>
                                {/* CardBody end  */}
                                <CardFooter data-component="CardFooter">
                                    <ApplyFilterButton data-component="ApplyFilterButton"
                                        onClick={() => applyFilter()}>
                                        Apply Filter
                                    </ApplyFilterButton>
                                </CardFooter>
                                {/* CardFooter end  */}
                            </HeaderFilterCard>
                        </HeaderFilterDropdown>
                    </HeaderRight>
                    {/* HeaderRight ended */}

                </Header>
                {/* Header ended */}
                <Container data-component="Container" onScroll={addPlantOnScroll}>
                    <ScrollWrapper data-component="ScrollWrapper">
                        
                        {/* CDP-481 changes */}
                        <TableCard data-component="TableCard" className={`walk-2 ${group_id != 14 ? 'with-assigned-to-col' : ''}`} style={{ display: myPlantList?.length > 0 ? '' : 'none' }}>
                            <TableCardeHeading data-component="TableCardeHeading" plant>
                                <i dangerouslySetInnerHTML={{ __html: myPlantIcon }}></i>
                                <span>MY PLANTS</span>
                            </TableCardeHeading>
                            {/* table card heading */}

                            {myPlanFiltertList?.map((plant, plant_index) => (
                                <TableCardRow data-component="TableCardRow" data-id={plant.id} key={plant.id + 'mp'}
                                    style={{ marginTop: `0px`, display: (appliedFilterArray.indexOf(plant.id) > -1 || appliedFilterArray.length == 0) ? '' : 'none' }}
                                >
                                    {/* CDP-481 changes */}
                                    <TablePlantName data-component="TablePlantName" onClick={() => handleCollapse(plant.id)} active={collapseArray.includes(plant.id)}>
                                        <span className="flag-img">
                                            <img src={`${base_url}${plant.slug}.svg`} alt="Flag Image" />
                                        </span>
                                        <p>
                                            <FlagLabel data-component="FlagLabel" head>{plant.plant_name} </FlagLabel>

                                        </p>
                                        <i className='angle-icon plants' dangerouslySetInnerHTML={{ __html: collapseArrow }}></i>
                                    </TablePlantName>
                                    {/*TablePlantName*/}
                                    {/* CDP-481 changes Added collapse*/}
                                    <Collapse in={collapseArray.includes(plant.id)}>
                                        <div className='collapse-content-wrapper'>
                                            <TableCardHeader data-component="TableCardHeader" className='sm-hide'>
                                                <PlatFormToolCol data-component="PlatFormToolCol">
                                                    Platform Tool
                                                </PlatFormToolCol>
                                                <NameCol data-component="NameCol">
                                                    Section Name
                                                </NameCol>
                                                <UpdatedCol data-component="UpdatedCol">
                                                    Updated
                                                </UpdatedCol>
                                                <AssignedCol data-component="AssignedCol">
                                                    Assigned By
                                                </AssignedCol>
                                                {/* Assigned to column added CDP-481 */}
                                                <AssignedToCol data-component="AssignedToCol">
                                                    Assigned To
                                                </AssignedToCol>
                                                <StatusCol data-component="StatusCol">
                                                    Status
                                                </StatusCol>
                                                <ActionCol data-component="ActionCol">
                                                    Action Needed
                                                </ActionCol>
                                            </TableCardHeader>
                                            {/* TableCardHeader end */}
                                            {
                                                (plant.list?.findIndex(i => i.status === 'todo') === -1 && filterArr.includes('todo')) && (plant.list?.findIndex(i => i.status === 'inprogress') === -1 && filterArr.includes('inprogress')) ?
                                                    <NameCol data-component="NameCol">
                                                        <span className='no-task-available'>To-Do and In-Progress tasks are not available.</span>
                                                    </NameCol> :
                                                    
                                                    (plant.list?.findIndex(i => i.status === 'inprogress') === -1 && filterArr.includes('inprogress')) && (plant.list?.findIndex(i => i.status === 'completed') === -1 && filterArr.includes('completed')) ?
                                                    <NameCol data-component="NameCol">
                                                        <span className='no-task-available'>In-Progress and Completed tasks are not available.</span>
                                                    </NameCol> :

                                                    (plant.list?.findIndex(i => i.status === 'todo') === -1 && filterArr.includes('todo')) && (plant.list?.findIndex(i => i.status === 'completed') === -1 && filterArr.includes('completed')) ?
                                                        <NameCol data-component="NameCol">
                                                            <span className='no-task-available'>To-Do and Completed tasks are not available.</span>
                                                        </NameCol> :
                                                        
                                                            (plant.list?.findIndex(i => i.status === 'todo') === -1 && filterArr.includes('todo')) ? <NameCol data-component="NameCol">
                                                                <span className='no-task-available'>To-Do tasks are not available.</span>
                                                            </NameCol> :
                                                                (plant.list?.findIndex(i => i.status === 'inprogress') === -1 && filterArr.includes('inprogress')) ?
                                                                    <NameCol data-component="NameCol">
                                                                        <span className='no-task-available'>In-Progress tasks are not available.</span>
                                                                    </NameCol>
                                                                    :
                                                                    (plant.list?.findIndex(i => i.status === 'completed') === -1 && filterArr.includes('completed')) ?
                                                                        <NameCol data-component="NameCol">
                                                                            <span className='no-task-available'> Completed tasks are not available.</span>
                                                                        </NameCol>
                                                                        :
                                                                        ''
                                            }
                                            {plant.list?.map((list, list_index) => (
                                                <>
                                                    <div  className={`sm-visible ${(filterArr.indexOf(list.status) > -1 || filterArr.length == 0) ? '' : 'hide-row' }`}>
                                                        <div className='sm-main-wrap'>
                                                            <div className='sm-main-row'>
                                                                <span className="title">{list.platform_tool}</span>
                                                                <span className='status-col'>
                                                                {
                                                                    list.status == 'completed' ?
                                                                        <StausLabel data-component="StausLabel" green>Completed</StausLabel>
                                                                        : list.status == 'inprogress' ?
                                                                            <StausLabel data-component="StausLabel" yellow>In Progress</StausLabel>
                                                                            :
                                                                            <StausLabel data-component="StausLabel" red>To Do</StausLabel>
                                                                }
                                                                </span>
                                                                <button className='close-btn' onClick={()=>handleTaskRowPopup(`${plant_index}${list_index}`)}>
                                                                    <i dangerouslySetInnerHTML={{__html:mobileIcon}}></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {mobileActive &&
                                                        <TableCardBody data-component="TableCardBody" key={list.id + '' + list_index}
                                                            style={{ display: (filterArr.indexOf(list.status) > -1 || filterArr.length == 0) ? '' : 'none' }}
                                                            className={mobileActive===`${plant_index}${list_index}`?'active':''}
                                                        >
                                                            <div className='heading-wrap sm-visible'>
                                                                <span>My Tasks</span>
                                                                <button className='close-pop' onClick={()=>handleClosePopup()}>
                                                                    <i dangerouslySetInnerHTML={{__html:mobileCloseIcon}} ></i>
                                                                </button>
                                                            </div>
                                                            <div class="sm-visible">
                                                                <TablePlantName data-component="TablePlantName" onClick={() => handleCollapse(plant.id)} active={collapseArray.includes(plant.id)}>
                                                                    <span className="flag-img">
                                                                        <img src={`${base_url}${plant.slug}.svg`} alt="Flag Image" />
                                                                    </span>
                                                                    <p>
                                                                        <FlagLabel data-component="FlagLabel" head>{plant.plant_name} </FlagLabel>

                                                                    </p>
                                                                </TablePlantName>
                                                            </div>
                                                            <PlatFormToolCol data-component="PlatFormToolCol">
                                                                <h2 className='sm-visible h2'>Platform Tool</h2>
                                                                {list.platform_tool}
                                                            </PlatFormToolCol>
                                                            <NameCol data-component="NameCol">
                                                                <h2 className='sm-visible h2'>Section Name</h2>
                                                                {list.name}
                                                            </NameCol>
                                                            <UpdatedCol data-component="UpdatedCol">
                                                                <h2 className='sm-visible h2'>Updated</h2>
                                                                {list.updated}
                                                            </UpdatedCol>
                                                            <AssignedCol data-component="AssignedCol">
                                                                {/* <AssignedUser data-component="AssignedUser">
                                                                        <i className={list.assigned_by_bg}>{list.assigned_by_sname}</i>
                                                                        <span>{list.assigned_by}</span>
                                                                    </AssignedUser> */}
                                                                <h2 className='sm-visible h2'>Assigned By</h2>
                                                                <AssignedUser data-component="AssignedUser">

                                                                    <i className={`${list.assigned_by_img ? 'cir-transparent' : list.assigned_by_bg}`}>

                                                                        {!list.assigned_by_img ?

                                                                            <div className={`taskcenter_assigned_sname`}>

                                                                                {list.assigned_by_sname}

                                                                            </div>

                                                                            :

                                                                            <img className="taskcenter_assigned__img_cir"

                                                                                src={`${apiEndpoints.baseUrl}/assets/user-profile/${list.assigned_by_img}`}

                                                                                alt={list.assigned_by_img} />

                                                                        }

                                                                    </i>

                                                                    <span>{list.assigned_by}</span>

                                                                </AssignedUser>
                                                            </AssignedCol>
                                                            {/* Assigned to column added CDP-481*/}
                                                            {group_id != 14 && list.invited_list.length > 0 &&
                                                                <>

                                                                    <AssignedToCol data-component="AssignedToCol">
                                                                        <h2 className='sm-visible h2'>Assigned To</h2>
                                                                        <div className="assigned-to-cir-wrapper">
                                                                            {list?.invited_list?.slice(0, 2)?.map((invt_list, index) => (
                                                                                <AssignedUser key={index} data-component="AssignedUser" onClick={() => handleDropUserCard(list.id + '' + list_index)}>
                                                                                    <i className={`${invt_list?.profile_pic ? 'cir-transparent' : invt_list?.avcolor}`}>
                                                                                        {!invt_list.profile_pic ?

                                                                                            <div className={`taskcenter_assigned_sname`}>
                                                                                                {
                                                                                                    invt_list.surname ?
                                                                                                        invt_list.name[0] + invt_list.surname[0] :
                                                                                                        invt_list.email[0]
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <img className="taskcenter_assigned__img_cir"
                                                                                                src={`${apiEndpoints.baseUrl}/assets/user-profile/${invt_list.profile_pic}`}
                                                                                                alt={invt_list.profile_pic} />
                                                                                        }
                                                                                    </i>
                                                                                </AssignedUser>
                                                                            ))}
                                                                            {list?.invited_list?.length > 2 &&
                                                                                <span className='assigned-to-count'
                                                                                    onClick={() => handleDropUserCard(list.id + '' + list_index)}
                                                                                >
                                                                                    +{list?.invited_list?.slice(2, list?.invited_list?.length)?.length}
                                                                                </span>
                                                                            }
                                                                            <span className={`tooltip ${showDropUserCard === list.id + '' + list_index ? 'active' : ''}`}>View users</span>

                                                                            <DropUserCard data-component="DropUserCard" active={showDropUserCard === list.id + '' + list_index}>
                                                                                <div className='max-height-container'>
                                                                                    <div className='user-listwrap'>
                                                                                        {/* user1 */}
                                                                                        {list?.invited_list?.map((invt_list, index) => (
                                                                                            <AssignedUser data-component="AssignedUser" key={index}>

                                                                                                <i className={`${invt_list?.profile_pic ? 'cir-transparent' : invt_list?.avcolor}`}>
                                                                                                    {!invt_list.profile_pic ?

                                                                                                        <div className={`taskcenter_assigned_sname`}>
                                                                                                            {
                                                                                                                invt_list.surname ?
                                                                                                                    invt_list.name[0] + invt_list.surname[0] :
                                                                                                                    invt_list.email[0]
                                                                                                            }
                                                                                                        </div>
                                                                                                        :
                                                                                                        <img className="taskcenter_assigned__img_cir"
                                                                                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${invt_list.profile_pic}`}
                                                                                                            alt={invt_list.profile_pic} />
                                                                                                    }
                                                                                                </i>

                                                                                                <div className='txt-wrap'>
                                                                                                    <span className='head'>{
                                                                                                        invt_list.surname ? invt_list.name
                                                                                                            :
                                                                                                            invt_list.email}
                                                                                                    </span>
                                                                                                    <span className='sub-txt'>{invt_list.role} </span>
                                                                                                </div>

                                                                                            </AssignedUser>
                                                                                        )
                                                                                        )
                                                                                        }
                                                                                        {/* user1 */}
                                                                                    </div>
                                                                                </div>
                                                                            </DropUserCard>
                                                                        </div>
                                                                    </AssignedToCol>
                                                                </>
                                                            }
                                                            {group_id != 14 && list.invited_list.length == 0 &&
                                                                <AssignedToCol data-component="AssignedToCol">
                                                                    <h2 className='sm-visible h2'>Assigned To</h2>
                                                                    <div className="assigned-to-cir-wrapper">

                                                                        <p>Not Yet</p>
                                                                    </div>
                                                                </AssignedToCol>
                                                            }
                                                            {/* Condition added for simple user */}
                                                            {group_id != 14 ? '' :
                                                                <AssignedToCol data-component="AssignedToCol">
                                                                    <h2 className='sm-visible h2'>Assigned To</h2>
                                                                    <AssignedUser data-component="AssignedUser">
                                                                        <i className={`${users.profile_pic ? 'cir-transparent' : users.avcolor}`}>
                                                                            {!users.profile_pic ?
                                                                                <div className={`taskcenter_assigned_sname`}>
                                                                                    {users.sn}
                                                                                </div>
                                                                                :
                                                                                <img className="taskcenter_assigned__img_cir"
                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${users.profile_pic}`}
                                                                                    alt={users.profile_pic} />
                                                                            }
                                                                        </i>
                                                                        <span>Me</span>
                                                                    </AssignedUser>
                                                                </AssignedToCol>
                                                            }
                                                            <StatusCol data-component="StatusCol">
                                                                <h2 className='sm-visible h2'>Status</h2>
                                                                {
                                                                    list.status == 'completed' ?
                                                                        <StausLabel data-component="StausLabel" green>Completed</StausLabel>
                                                                        : list.status == 'inprogress' ?
                                                                            <StausLabel data-component="StausLabel" yellow>In Progress</StausLabel>
                                                                            :
                                                                            <StausLabel data-component="StausLabel" red>To Do</StausLabel>
                                                                }



                                                            </StatusCol>
                                                            <ActionCol data-component="ActionCol">
                                                                <ActionNeededLink as="a" data-component="ActionNeededLink" href={list.action_url} style={{ pointerEvents: list.action != 'No Action Needed' ? '' : 'none' }}>
                                                                    <span>{list.action}</span>
                                                                    {list.action != 'No Action Needed' && <i dangerouslySetInnerHTML={{ __html: arrowForwardIcon }}></i>
                                                                    }
                                                                </ActionNeededLink>
                                                            </ActionCol>
                                                        </TableCardBody>
                                                    }
                                                </>
                                            ))
                                            }
                                            {/* TableCardBody end */}
                                        </div>
                                    </Collapse>
                                </TableCardRow>
                            ))
                            }
                        </TableCard>
                        {exist_plants == 0 && group_id != 14 ?
                            <TableCard data-component="TableCard" className="empty-walk">
                                <TableCardeHeading data-component="TableCardeHeading" plant>
                                    <i dangerouslySetInnerHTML={{ __html: myPlantIcon }}></i>
                                    <span>MY PLANTS</span>
                                </TableCardeHeading>
                                <PlantTableBody data-component="PlantTableBody">
                                    <div className='plant-svg' dangerouslySetInnerHTML={{ __html: emptyPlantSvg }}></div>
                                    <h2 className='plant-head'>No tasks available</h2>
                                </PlantTableBody>
                            </TableCard>
                            : ''
                        }
                    </ScrollWrapper>
                    {loading && <h3 className="loading-h3">Loading...</h3>}
                </Container>
                {/* Container */}
                {/* Task Center Walkthrough  */}
                {(showTCWalkthrough && taskcenterwlakthrough == 0) &&
                    <TaskCenterWalkthroughView
                        activeSlide={activeSlide}
                        setActiveSlide={setActiveSlide}
                        myPlantLength={myPlantList?.length}
                        exist_plants={exist_plants}
                        setShowTCWalkthrough={setShowTCWalkthrough}
                        {...props}
                    />
                }
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

