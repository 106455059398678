import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import {
    MainWrapper,
    UpgradePopWrapOne,
    UpgradePopSTep,
    UpgradePopHead,
    UpgradePopBody,
    BodyTextWrap,
    UpgradePopFooter,
    ButtonWrap,    
} from './Upgradestyles';

import{
    asmtHeadIcon,crossIcon,bookMark,triangleIcon, automatedIconLoss, operationaLoss, insightLoss,deliverIcon,goalIcon,methodIcon,strategyIcon, strategyMainIcon,
} from './upgradesvg';

export const UpgradePopView = (props) => {
    const {openPopup,setOpenPopup} = props;
    const slug = props.slug;
    const asmtData = [
        {
            id: 1,
            img: bookMark,
            title: `Plant Team Engagement `,
            text: `Engage all plant stakeholders in the loss analysis tool and get their plant activities input in a structured and scalable manner`,
        },
        {
            id: 2,
            img: automatedIconLoss,
            title: `Automated Data Analysis`,
            text: `Analyse all your operational activities and understand the activity costs that goes into your operations automatically`,
        },
        {
            id: 3,
            img: operationaLoss,
            title: `Operational Insights`,
            text: `Undestand your loss recovery opportunities that IR4.0 can provide as granular as operator activity/ production line level `,
        },
        {
            id: 4,
            img: insightLoss,
            title: `Financial Insights`,
            text: `Understand the actual cost of your Losses on an annual basis and drive clear initiatives prioritisation.`,
        },
    ]

    const strategyData = [
        {
            id: 1,
            img: goalIcon,
            title: `Goal`,
            text: `Identify key loss opportunities and define solutions to drive efficiency improvements`,
        },
        {
            id: 2,
            img: methodIcon,
            title: `Methodology`,
            text: `Loss and financial analysis, solution selection and cost-benefit analysis of the selected solutions`,
        },
        {
            id: 3,
            img: deliverIcon,
            title: `Deliverables`,
            text: `Industry 4.0 Roadmap with technology and organizational solutions, their cost-benefit analysis and 3 vendors for each solution
            `,
        },
    ]
    const final_map_data = slug === `loss-analysis` ? asmtData:strategyData;

    const handleUpgradePopupClick = (e,value)=>{
		e.stopPropagation();
		e.preventDefault();
        localStorage.setItem(`upgradeSection`, value);
		document.querySelector('.header-icons-li.upgrade .contact-btn')?.click();
	}
    const handleClose=()=>{
        document.querySelector('.training-main-wrapper').classList.remove('training-active');
        setOpenPopup(false);
    }
    useEffect(()=>{
        document.querySelector('.training-main-wrapper').classList.add('training-active');
        return()=>{
            handleClose();
        }
    },[])
    
    return (
        <>
            <MainWrapper data-component="MainWrapper">
                {openPopup&&
                    <UpgradePopWrapOne data-component="UpgradePopWrapOne">
                            <UpgradePopSTep data-component="UpgradePopSTep">
                                <UpgradePopHead data-component="UpgradePopHead">
                                    {slug === `loss-analysis` ?
                                    <>
                                        <i className='asmt-img' dangerouslySetInnerHTML={{ __html: asmtHeadIcon }}></i>
                                        <span className='head-txt'>IR4.0 Loss Analysis</span>
                                        <button onClick={()=>handleClose()}>
                                            <i className='close-img' dangerouslySetInnerHTML={{ __html: crossIcon }}></i>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <i className='asmt-img' dangerouslySetInnerHTML={{ __html: strategyMainIcon }}></i>
                                        <span className='head-txt'>IR4.0 Strategy</span>
                                        <button onClick={()=>handleClose()}>
                                            <i className='close-img' dangerouslySetInnerHTML={{ __html: crossIcon }}></i>
                                        </button>
                                    </>
                                    }
                                    
                                </UpgradePopHead>
                                <UpgradePopBody data-component="UpgradePopBody">
                                    {slug === `loss-analysis` ?
                                        <p className='main-text'>
                                            Most effective way to identify all the loss drivers of your 
                                            manufacturing operations and engage the organisation at scale
                                        </p>
                                    :
                                        <p className='main-text'>
                                            Following SmarterChains remote Assessment on Digital Readiness the next step 
                                            is to take structured steps towards digital transformation by launching your Industry 4.0 Roadmap.
                                        </p>
                                    }

                                    {final_map_data.map((item,index)=>(
                                        <BodyTextWrap data-component="BodyTextWrap" key={index}>
                                            <div className='upper-wrap'>
                                                <i className='side-icon' dangerouslySetInnerHTML={{ __html: item.img }}></i>
                                                <span>{item.title}</span>
                                            </div>
                                            <p>{item.text}</p>
                                            <i className='triangle-icon' dangerouslySetInnerHTML={{ __html: triangleIcon }}></i>
                                        </BodyTextWrap>
                                    ))}
                                    <ButtonWrap data-component="ButtonWrap">
                                    { slug === `loss-analysis`?
                                        <button className='get-btn' onClick={(e) => handleUpgradePopupClick(e,`loss`)}>
                                            Get IR4.0 Loss Analysis
                                        </button>
                                        :
                                        <button className='get-btn' onClick={(e) => handleUpgradePopupClick(e,`strategy`)}>
                                            Get IR4.0 Strategy  
                                        </button>
                                        }
                                    </ButtonWrap>
                                </UpgradePopBody>
                                <UpgradePopFooter data-component="UpgradePopFooter">
                                { slug === `loss-analysis` ?
                                    <Link to="/learning-loss-walkthrough/upgrade_loss_tour_link">View Product Tour</Link>
                                    :
                                    <Link to="/learning-product-tour">View Product Tour</Link>
                                }
                                </UpgradePopFooter>
                            </UpgradePopSTep>
                    </UpgradePopWrapOne>
                }
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

