import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ScenarioDropdown from "./ScenarioDropdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { HowToUseCtrl } from '../roadmap-how-to-use/HowToUseCtrl'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from 'react-router-dom';
import {UpgradeView} from '../upgrade-demo/UpgradeView';
import{RoadmapAsmtUpgradeView} from '../roadmap-upgrade/RoadmapAsmtUpgradeView'
// Images that we have used on page
import {
  filterSvg,
  closeSvg,
  dragIcon,
  addBtn,
  collapseIcon,
  collapseOpenIcon,
  minusIcon,
  digitalSvg,
  vendorCloseSvg,
  accordSvg,
  checkboxSvg,
  radioBtnSvg,
  closeTechSvg,
  blackSvg,
  
  goalIcon,
  methodIcon,
  deliverIcon,
  upgradeCloseIcon,
  upgradeLockIcon,
  upgradeRightLockIcon,
  upgradeInfoIcon,
  upgradeTopCloseIcon,
} from "./svg-step1";
// Components that we have used in page
import {
  AccordionHeader,
  SaveRoadmapButton,
  HeaderFilterWrapper,
  ButtonWrapper,
  TopSectionBtn,
  FilterWrapper,
  FilterButton,
  FilterBox,
  FilterCloseButton,
  FilterBoxContent,
  FilterBoxContentTitle,
  FilterCheckBoxWrapper,
  FilterBoxLable,
  FilterBoxInput,
  FilterButtonWrapper,
  ResetFilterButton,
  ApplyFilterButton,
  MainCardWrapper,
  TabCardWrapper,
  TabContentWraaper,
  NAButton,
  RightAlignTextWrapper,
  RightAlignTextWrap,
  HeadText,
  SubText,
  AddDragBtn,
  CollapseBtn,
  TabPanelContent,
  VendorWrap,
  VendorRow,
  VendorCol,
  VendorCard,
  VendorText,
  InnerTabWrapper,
  InnerTBLeftCol, 
  InnerTBRightCol,
  LossTableWrap,
  LossScrollTableWrap,
  VendorClickImg,
  VendorModalWrap,
  VendorModalHeader,
  VendorCloseBtn,
  VendorTabWrap,
  VendorTabs,
  VendorTab,
  VendorTabPanel,
  AccordionTabWrapper,
  AccordImg,
  MaintainWrap,
  MaintainRow,
  LeftMainCol,
  RightMainCol,
  ListInvestWrap,
  ListRow,
  UpfrontListRow,
  ListValWrap,
  UserWrap,
  GoListWrap,
  RightTabCardWrapper,
  
  TechnologyPopWrapper,
  TechnologyPopWrap,
  TechnologyPopHead,
  TechnologyPopBody,
  TechnologyPopBodyWrap,
  
  UpgradePopWrapper,
  UpgradePopCard,
  UpgradePopRow,
  UpgradePopCol,
  UpgradeInnerCard,
  UpgradeInnerHead, 
  UpgradeFooter,
  UpgradeLock,
  UpgradeInfoWrapper,
} from "./Roadmap-style-step1";

import{
  MainWrapper,
  SectionWrapper,
  LeftSection,
  RightSection,
  SectionHeader,
  ContentBody,
  ContentFooter,
  FilterBoxHeader,
  HeaderTitle,
  ScenarioSectionWrapper,
  TabButton,
  TBRow,
  TBLeftCol,
  TBRightCol,
  LossWrap,
  LossCol,
  LossRow,
  ProceedPopWrapper,
  ProceedPopCard,
  PopHead,
  PopFooter,
  QNAWrap,
  QNACard,
  QNABtn,
}from "./common-roadmap-style";
export const RoadmapStep1View = (props) => {
  //variable declaration
  const [showFilter, setShowFilter] = useState(false);
  const [checkedFilter, setCheckedFilter] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);
  const [rightCards, setRightCards] = useState([]);
  // Collapse
  const [leftOpen, setLeftOpen] = React.useState(false);
  const [rightOpen, setRightOpen] = React.useState(false);
  const [qnaOpen, setQnaOpen] = React.useState(false);
  // Collapse Card
  const [lossLeftDataOpen, setLossLeftDataOpen] = React.useState(false);
  const [lossRightDataOpen, setLossRightDataOpen] = React.useState(false);
  const [openUpgradePop, setOpenUpgradePop]=useState(true)
  //vendor tabs
  const [vendorTab,setVendorTab] = useState(1)
  const [isVendorModal,setIsVendorModal] = useState(false);
  const [isVendorRightModal,setIsVendorRightModal] = useState(false);
  const [openPopup,setOpenPopup] = useState(false);
  const [isTechModal,setIsTechModal] = useState(false);
  const [infoPopOpen,setInfoPopOpen] = useState(true);
  const [showUpgradePrice,setShowUpgradePrice] = useState(true);
// Card array declaration
const [leftCards, setLeftCards] = useState([
  { 
    id: `1`, 
    card_title: `Automated Guided Vehicles (AGVs)`,
    status: `HAS`,
    recoverable_value: `0`,
    investment: `17000`,
  },
  { 
    id: `2`, 
    card_title: `Blockchain`,
    status: `HAS NOT`,
    recoverable_value: `0`,
    investment: `1000`,
  },
  { 
    id: `3`, 
    card_title: `Cloud Infrastructure & Data Architecture `,
    status: `PARTIALLY`,
    recoverable_value: `0`,
    investment: `24000`,
  },
  { 
    id: `4`, 
    card_title: `Collaborative Robots`,
    status: `NA`,
    recoverable_value: `0`,
    investment: `1687000`,
  },
]);
  //const [draggingData, setDraggingData] = useState('');
  //filter function
  const [leftValue, setLeftValue] = React.useState(`l0-0`);
  const [rightValue, setRightValue] = React.useState(`r0-0`);
  // Tabs Function
  const handleLeftChange = (event, newValue) => {
    setLeftValue(newValue);
    setIsVendorModal(false);
  };
  const handleRightChange = (event, newValue) => {
    setRightValue(newValue);
    setIsVendorRightModal(false);
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value,index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
       
    </>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  // end
  // Accordion start
  const [expanded, setExpanded] = React.useState(false);

  const HandleChange =
  (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
  };
  
  const Accordion = (props) => (
      <MuiAccordion disableGutters elevation={0} square {...props} />
  );
  
  const AccordionSummary = (props) => (
    <MuiAccordionSummary expandIcon={''} {...props}/>
  );
  const handleUpgradePopupClick = (e)=>{
    e.stopPropagation();
    e.preventDefault();
    document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
    setShowUpgradePrice(false);
  }
  const AccordionDetails = (MuiAccordionDetails);
  // end
  // Filter start
  const closeFilter = () => {
    let allCheckBox = document.querySelectorAll('[name="filterCheckBox"]');
    let bothCheckBox = document.querySelector('[name="filterCheckBoth"]');
    let tmpArr = [];
    //code to check the vlaue of input does exist in the checkedFilter' array and behalf of this we will check and uncheck the input.
    allCheckBox.forEach((chk) => {
      if (checkedFilter.includes(chk.value)) {
        chk.checked = true;
        tmpArr.push(chk.value);
      } else {
        chk.checked = false;
      }
    });
    setShowFilter(false);
  };

  const resetFilter = () => {
    let allCheckBox = document.querySelectorAll('[name="filterCheckBox"]');
    let bothCheckBox = document.querySelector('[name="filterCheckBoth"]');
    allCheckBox.forEach((chk) => (chk.checked = false));
    bothCheckBox.checked = false;
  };


  const applyFilter = () => {
    let allCheckBox = document.querySelectorAll('[name="filterCheckBox"]');
    let tmpArr = [];
    allCheckBox.forEach((chk) => {
      if (chk.checked == true) {
        tmpArr.push(chk.value);
      }
    });
    setCheckedFilter(tmpArr);
    setShowFilter(false);
  };

  // drag and drop function
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    // If dropped outside the droppable area, do nothing
    if (!destination) return;
    // If dropped in the right area, add the card and disable the left card
    if (destination.droppableId === "right") {
      let tmpCard = leftCards[source.index];
      setRightCards((rightCards) => [
        ...rightCards,
        { ...tmpCard },
      ]);
      setLeftCards((leftCards) =>
        leftCards.map((card, index) =>
          index === source.index ? { ...card, disabled: true } : card
        )
      );
      leftCards.forEach((card, index) =>
        {
          if(card.id===tmpCard.id){
            setLeftOpen(false);  
          }
        } 
      )
    }
  };
  // Function to add cards on right side
  const addRightCard=(index,e)=>{
    setRightCards((rightCards) => [
      ...rightCards,
      { ...leftCards[index] },
    ]);
    setLeftCards((leftCards) =>
      leftCards.map((card, i) =>
        i === index ? { ...card, disabled: true } : card
      )
    );
    leftCards.forEach((card, i) =>
        {
          if(i === index){
            setLeftOpen(false);  
          }
        } 
      )
    e.stopPropagation();
    e.preventDefault();
  }
  // end
  // Function to remove cards on right side
  const removeRightCard=(id,e)=>{
    let tmpFilterArray = rightCards.filter((card) =>
      card.id !== id
    )
    setRightCards(tmpFilterArray);
    setLeftCards((leftCards) =>
      leftCards.map((card) =>
        card.id === id ? { ...card, disabled: false } : card
      )
    );
    e.stopPropagation();
    e.preventDefault();
  }
  // end
  // Function to open and close tabs on left
  const handleLeftClick = (id,index) => {
    setLeftValue(`l0-${index}`);
    setVendorTab(1);
    setIsVendorModal(false);
      if(leftOpen === id){
        setLeftOpen(false);  
      }
      else{
        setLeftOpen(id);
      }  
  };
  // Function to open and close tabs on right
  const handleRightClick = (id,index) => {
    setVendorTab(1);
    setRightValue(`r0-${index}`);
    setIsVendorRightModal(false);
    if(rightOpen === id){
      setRightOpen(false);  
    }
    else{
      setRightOpen(id);
    }
  };
  // Funtion to open and close inner tabs on left
  const handleLeftInnerTabClick = (val) => {
    if(lossLeftDataOpen === val){
      setLossLeftDataOpen(false);  
    }
    else{
      setLossLeftDataOpen(val);
    }
  };
  // Funtion to open and close inner tabs on right
  const handleRightInnerTabClick = (val) => {
    if(lossRightDataOpen === val){
      setLossRightDataOpen(false);  
    }
    else{
      setLossRightDataOpen(val);
    }
};
// Funtion to open technology/feature popup
const handleTabRightClick = ()=>{
  setIsTechModal(true);
}
// Function to open and close inner QNA tab
const handleQnaTabClick = (val)=>{
  if(qnaOpen === val){
    setQnaOpen(false);  
  }
  else{
    setQnaOpen(val);
  }
}
  // useEffct
  useEffect(() => {
    document.addEventListener("click", (e) => {
      let target = e.target;
      // close filter on click outside
      if (!target.closest('[data-component="FilterWrapper"]')) {
        closeFilter();
      }
    });
  });


  return (
    <>
    {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/home">Plant name</BreadcrumbsItem>
      <BreadcrumbsItem to="#">IR4.0 Roadmap Solutions</BreadcrumbsItem>
     {/* End */}

    <DragDropContext onDragEnd={handleOnDragEnd}>
      {/* <DragDropContainer /> */}
      {infoPopOpen &&
        <UpgradeInfoWrapper data-component="UpgradeInfoWrapper">
        <i className="upgrade-info-icon" dangerouslySetInnerHTML={{__html: upgradeInfoIcon}}></i>
        <span className="info-upgrade-txt">
          Measure Technological & Organizational capabilities at scale
        </span>
        <button className="upgrade-info-btn">
          <span>Add IR4.0 Technology Assessment to Cart   |   € 1500</span>
        </button>
        <button className="upgrade-info-close-btn" onClick={()=>setInfoPopOpen(false)}>
          <i dangerouslySetInnerHTML={{__html: upgradeTopCloseIcon}}></i>
        </button>
      </UpgradeInfoWrapper>
      }
      <MainWrapper data-component="MainWrapper">
        
        <SectionWrapper data-component="SectionWrapper">
          <LeftSection data-component="LeftSection">
            <SectionHeader data-component="SectionHeader">
              <ButtonWrapper
                data-component="ButtonWrapper"
                style={{ display: "none" }}
              >
                <TopSectionBtn disabled data-component="TopSectionBtn">
                  Calculate Losses
                </TopSectionBtn>
                <TopSectionBtn data-component="TopSectionBtn">
                  Calculate Recoverables
                </TopSectionBtn>
                <TopSectionBtn data-component="TopSectionBtn">
                  Calculate Investment
                </TopSectionBtn>
              </ButtonWrapper>

              <HeaderFilterWrapper data-component="HeaderFilterWrapper">
                <HeaderTitle data-component="HeaderTitle">
                  IR4.0 Roadmap Solutions
                </HeaderTitle>
                {/* -------------------- HeaderTitle end ---------------- */}

                <FilterWrapper data-component="FilterWrapper">
                  <FilterButton
                    data-component="FilterButton"
                    active={showFilter}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <i dangerouslySetInnerHTML={{ __html: filterSvg }}></i>
                  </FilterButton>
                  <FilterBox data-component="FilterBox" show={showFilter}>
                    <FilterBoxHeader data-component="FilterBoxHeader">
                      <HeaderTitle data-component="HeaderTitle">
                        Available Capabilities Filters
                      </HeaderTitle>
                      <FilterCloseButton
                        data-component="HeaderTitle"
                        onClick={closeFilter}
                      >
                        <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                      </FilterCloseButton>
                    </FilterBoxHeader>
                    <FilterBoxContent data-component="FilterBoxContent">
                      <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                        Type
                      </FilterBoxContentTitle>
                      <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="type1"
                            name="filterCheckBox"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Technology</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="type2"
                            name="filterCheckBox"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Organization</span>
                        </FilterBoxLable>
                        {/* Organization Input end */}

                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="type3"
                            name="filterCheckBoth"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Both</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                      </FilterCheckBoxWrapper>
                      <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                        Technology
                      </FilterBoxContentTitle>
                      <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="tech1"
                            name="filterCheckBox"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Has</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="tech2"
                            name="filterCheckBox"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Has not</span>
                        </FilterBoxLable>
                        {/* Organization Input end */}

                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="tech3"
                            name="filterCheckBoth"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Partial</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                      </FilterCheckBoxWrapper>
                      <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                        Recoverables
                      </FilterBoxContentTitle>
                      <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="recover1"
                            name="recover"
                            type="radio"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                          </span>
                          <span>High to Low</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="recover2"
                            name="recover"
                            type="radio"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                          </span>
                          <span>Low to High</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                      </FilterCheckBoxWrapper>
                      <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                        Investment
                      </FilterBoxContentTitle>
                      <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper">
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="invest1"
                            name="invest"
                            type="radio"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                          </span>
                          <span>High to Low</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="invest2"
                            name="invest"
                            type="radio"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                          </span>
                          <span>Low to High</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                      </FilterCheckBoxWrapper>
                      <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                        Dimension
                      </FilterBoxContentTitle>
                      <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper" className="halfWidth">
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim1"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Factory of the Future Leadership</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim2"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Digital-Ready Infrastructure</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim3"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>360° Factory Visibility</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim4"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Intelligent Quality</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim5"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Automated Material Flow</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim6"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Data-Driven Maintenance</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim7"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Zero Touches Production</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim8"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Sustainability</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim9"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Tech-Augmented Workforce</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="dim10"
                            name="dimension"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Digital Organization</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                      </FilterCheckBoxWrapper>
                      
                      <FilterBoxContentTitle data-component="FilterBoxContentTitle">
                        Loss Categories
                      </FilterBoxContentTitle>
                      <FilterCheckBoxWrapper data-component="FilterCheckBoxWrapper" className="halfWidth">
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss1"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Planned Downtime</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss2"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Unplanned Downtime</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss3"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Minor Stops</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss4"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Speed Losses</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss5"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Material Waste</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss6"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Operator Losses</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss7"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Maintenance Losses</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss8"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>IT Losses</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss9"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>EHS Losses</span>
                        </FilterBoxLable>
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss10"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Quality Losses</span>
                        </FilterBoxLable>
                        
                        <FilterBoxLable data-component="FilterBoxLable">
                          <input
                            value="loss11"
                            name="losses"
                            type="checkbox"
                          />
                          <span className="icon">
                            <i dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                          </span>
                          <span>Warehouse Losses</span>
                        </FilterBoxLable>
                        {/* technology Input end */}
                      </FilterCheckBoxWrapper>
                    </FilterBoxContent>
                    <FilterButtonWrapper data-component="FilterButtonWrapper">
                        <ResetFilterButton onClick={resetFilter}>
                          Reset Filters
                        </ResetFilterButton>
                        <ApplyFilterButton onClick={applyFilter}>
                          Apply Filters
                        </ApplyFilterButton>
                    </FilterButtonWrapper>
                  </FilterBox>
                </FilterWrapper>
                {/* -------------------- FilterWrapper end ---------------- */}
              </HeaderFilterWrapper>
              {/* -------------------- HeaderFilterWrapper end ---------------- */}
              {/* -------------------- HeaderFilterWrapper end ---------------- */}
            </SectionHeader>
            <ContentBody data-component="ContentBody">
              <Droppable droppableId="left">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {leftCards.map((card, index) => (
                      <Draggable
                        key={card.id}
                        draggableId={card.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={`card${
                              snapshot.isDragging ? "dragging" : ""
                            }`}
                            {...provided.draggableProps}
                            
                            ref={provided.innerRef}
                            style={{
                              ...provided.draggableProps.style,
                              pointerEvents: card.disabled ? "none" : "all",
                              userSelect:card.disabled ? "none" : "all",
                            }}
                          >
                            <MainCardWrapper
                              data-component="MainCardWrapper"
                              disabled={card.disabled}
                            >
                              <TabCardWrapper data-component="TabCardWrapper">
                                    <TabButton data-component="TabButton">
                                        <TBRow data-component="TBRow">
                                            <TBLeftCol data-component="TBLeftCol">
                                            <div
                                              className="drag-btn"
                                              {...provided.dragHandleProps}
                                            >
                                              <i
                                                dangerouslySetInnerHTML={{
                                                  __html: dragIcon,
                                                }}
                                              ></i>
                                            </div>
                                                <p className='rmap-txt'>
                                                    {card.card_title}                                                                            
                                                </p>
                                                <div className="status-wrap">
                                                  {card.status===`NA` &&
                                                  <NAButton data-component="NAButton" status>N/A</NAButton>
                                                  }
                                                  {card.status===`HAS`&&
                                                  <NAButton data-component="NAButton" status={`has`}>Has</NAButton>
                                                  }
                                                  {card.status==`HAS NOT`&&
                                                  <NAButton data-component="NAButton" status={`hasnot`}>Has Not</NAButton>
                                                  }
                                                  {card.status==`PARTIALLY`&&
                                                  <NAButton data-component="NAButton" status={`partially`}>PARTIALLY</NAButton>
                                                  }
                                                </div>
                                            </TBLeftCol>
                                            <TBRightCol data-component="TBRightCol">
                                                <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                                    <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                        <UpgradeLock data-component="UpgradeLock">
                                                          <i dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                                                          <div className="upgrade-tooltip">
                                                            <span>Get IR4.0 Loss Analysis</span>
                                                          </div>
                                                        </UpgradeLock>
                                                        <SubText data-component="SubText" green style={{"display": "none"}}>{card.recoverable_value}€</SubText>
                                                    </RightAlignTextWrap>
                                                    <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Investment: </HeadText>
                                                        <SubText data-component="SubText" orange>{card.investment}€</SubText>
                                                    </RightAlignTextWrap>
                                                </RightAlignTextWrapper>
                                                <AddDragBtn data-component="AddDragBtn" onClick={(e)=>addRightCard(index,e)}>
                                                    <i dangerouslySetInnerHTML={{ __html: addBtn }}></i>
                                                </AddDragBtn>
                                                <CollapseBtn data-component="CollapseBtn" onClick={()=>handleLeftClick(card.id,index)} className={`${leftOpen===card.id?'active':''}`}>
                                                    {leftOpen===card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i>  : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                </CollapseBtn>
                                            </TBRightCol>
                                        </TBRow>
                                    </TabButton>
                                    <Collapse in={leftOpen===card.id} timeout="auto">
                                        <TabContentWraaper data-component="TabContentWraaper">
                                            <Tabs
                                                    orientation="horizontal"
                                                    variant="scrollable"
                                                    value={leftValue}
                                                    onChange={handleLeftChange}
                                                    aria-label="Vertical tabs example"
                                                    
                                                    >
                                                    <Tab value={`l0-${index}`} label="Description" {...a11yProps(0)} />
                                                    <Tab value={`l1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                                                    <Tab value={`l2-${index}`} label="Vendors" {...a11yProps(2)} />
                                                    <Tab value={`l3-${index}`} label="Q&A" {...a11yProps(3)} />
                                                    <Tab onClick ={handleTabRightClick} value={`l4-${index}`} label="Technologies / Features " {...a11yProps(4)} />
                                            </Tabs>
                                        
                                        <TabPanel value={leftValue} index={`l0-${index}`}>
                                            <TabPanelContent data-component="TabPanelContent">
                                                <p>Moving forward with Industry 4.0, sensors, real-time location services & 
                                                workforce mobility become an integral part of manufacturing operations. Without reliable &
                                                effective industrial wireless, it’s difficult to imagine a true smart factory materialize. 
                                                In the Factory of the Future, a vast amount of sensors, devices, networks and protocols must 
                                                seamlessly interact to transport data over Bluetooth, Message Queuing Telemetry Transport, 
                                                Wi-Fi, Ethernet, narrowband cellular, and across the Wide Area Network, to routers, servers, 
                                                edge gateways and corporate data centres.</p>
                                                <p>The stack of network protocols must be:</p>
                                                <ul>
                                                    <li>Adaptive to constrained bandwidth</li>
                                                    <li>Power-efficient and low-interference</li>
                                                    <li>Secure across multiple system layers</li>
                                                </ul>
                                                <p>Similarly, networking equipment and software should maintain a fast and stable wireless 
                                                    connection in harsh industrial environments, and they should support the protocols, 
                                                    topologies and the sheer amount of data produced.
                                                </p>
                                                <p class="bc_head_h4">Benefits</p>
                                                <ul class="mb-08vw">
                                                    <li>Wireless networks give operations leaders access to vital production information across the site</li>
                                                    <li>Operators can track critical data on handheld devices, and receive real-time alerts from equipment</li>
                                                    <li>It is significantly more affordable than cable</li>
                                                    <li>It enables more flexibility in configuring the plant floor, which is critical when introducing new product lines or changing processes</li>
                                                </ul>
                                                <div class="bc_leadership">
                                                    <i dangerouslySetInnerHTML={{ __html: digitalSvg }}></i>
                                                    Digital-ready Infrastructure                                                                                    
                                                </div>
                                            </TabPanelContent>
                                        </TabPanel>
                                        <TabPanel value={leftValue} index={`l1-${index}`}>
                                            <InnerTabWrapper data-component="InnerTabWrapper">
                                                <TBRow data-component="TBRow">
                                                    <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                                        <p className="inner-tab-txt">Operator Effort Losses</p>
                                                    </InnerTBLeftCol>
                                                    <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                                        <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                                <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                                <SubText data-component="SubText" green>2,813€</SubText>
                                                            </RightAlignTextWrap>
                                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                                <HeadText data-component="HeadText">Investment: </HeadText>
                                                                <SubText data-component="SubText" orange>177,000€</SubText>
                                                            </RightAlignTextWrap>
                                                        </RightAlignTextWrapper>
                                                        <p className='recovery-txt'>
                                                            <span>8% </span>
                                                              of category <br/>losses Recovered
                                                        </p>
                                                        <CollapseBtn data-component="CollapseBtn" onClick={()=>handleLeftInnerTabClick(`loss0-${index}`)} className={`${lossLeftDataOpen===`loss0-${index}`?'active':''}`}>
                                                            {lossLeftDataOpen===`loss0-${index}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i>  : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                        </CollapseBtn>
                                                    </InnerTBRightCol>
                                                </TBRow>
                                            </InnerTabWrapper>
                                            <Collapse in={lossLeftDataOpen===`loss0-${index}`} timeout="auto">
                                                <LossTableWrap data-component="LossTableWrap">
                                                    <LossScrollTableWrap data-component="LossScrollTableWrap">
                                                        <table className='responsive-loss-table'>
                                                            <thead>
                                                                <tr>    
                                                                    <th className='loss-table-col'>Sublosses</th>
                                                                    <th className='loss-table-col'>
                                                                        Identified<br/> Losses 
                                                                    </th>
                                                                    <th className='loss-table-col'>Recoverable</th>
                                                                    <th className='loss-table-col'>% of Subloss<br/>recoverable </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>    
                                                                    <td className='loss-table-col'>Reviewing production information to ensure quality compliant product release</td>
                                                                    <td className='loss-table-col orange'>78,139<span>€</span></td>
                                                                    <td className='loss-table-col green'>6,312<span>€</span></td>
                                                                    <td className='loss-table-col blue'>8%</td>
                                                                </tr>
                                                                <tr>    
                                                                    <td className='loss-table-col bottom black'>Total</td>
                                                                    <td className='loss-table-col bottom black'>78,139<span>€</span></td>
                                                                    <td className='loss-table-col bottom black'>6,312<span>€</span></td>
                                                                    <td className='loss-table-col bottom black'></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </LossScrollTableWrap>
                                                </LossTableWrap>
                                            </Collapse>
                                            <LossWrap data-component="LossWrap">
                                                <LossRow data-component="LossRow">
                                                    <LossCol data-component="LossCol">
                                                        <span className="text">Associated</span>
                                                        <span className="text">Losses:</span>
                                                        <span className="text green">8,943 €</span>
                                                    </LossCol>
                                                    <LossCol data-component="LossCol">
                                                        <span className="text">Total Technology</span>
                                                        <span className="text"> Recoverable:</span>
                                                        <span className="text orange">1,288 €</span>
                                                    </LossCol>
                                                    <LossCol data-component="LossCol">
                                                        <span className="text">% of Total</span>
                                                        <span className="text"> Recovered Losses:</span>
                                                        <span className="text blue">12%</span>
                                                    </LossCol>
                                                </LossRow>
                                            </LossWrap>
                                        </TabPanel>
                                        <TabPanel value={leftValue} index={`l2-${index}`}>
                                            <VendorWrap data-component="VendorWrap">
                                                <VendorRow data-component="VendorRow">
                                                    <VendorCol data-component="VendorCol">
                                                        <VendorCard data-component="VendorCard">
                                                            <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorModal(true)}>
                                                                <img src={require("../../assets/img/roadmap-page/dematic.png").default } />
                                                            </VendorClickImg>
                                                        </VendorCard>
                                                        <VendorText data-component="VendorText">
                                                            Dematic
                                                        </VendorText>
                                                    </VendorCol>
                                                    <VendorCol data-component="VendorCol">
                                                        <VendorCard data-component="VendorCard">
                                                            <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorModal(true)}>
                                                                <img src={require("../../assets/img/roadmap-page/jbt.png").default } />
                                                            </VendorClickImg>
                                                        </VendorCard>
                                                        <VendorText data-component="VendorText">
                                                            JBT
                                                        </VendorText>
                                                    </VendorCol>
                                                    <VendorCol data-component="VendorCol">
                                                        <VendorCard data-component="VendorCard">
                                                            <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorModal(true)}>
                                                                <img src={require("../../assets/img/roadmap-page/toyota.png").default } />
                                                            </VendorClickImg>
                                                        </VendorCard>
                                                        <VendorText data-component="VendorText">
                                                            Toyota Material Handling
                                                        </VendorText>
                                                    </VendorCol>
                                                    <VendorCol data-component="VendorCol">
                                                        <VendorCard data-component="VendorCard">
                                                            <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorModal(true)}>
                                                                <img src={require("../../assets/img/roadmap-page/linde.png").default } />
                                                            </VendorClickImg>
                                                        </VendorCard>
                                                        <VendorText data-component="VendorText">
                                                            Linde Material Handling
                                                        </VendorText>
                                                    </VendorCol>
                                                    <VendorCol data-component="VendorCol">
                                                        <VendorCard data-component="VendorCard">
                                                            <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorModal(true)}>
                                                                <img src={require("../../assets/img/roadmap-page/electric.png").default } />
                                                            </VendorClickImg>
                                                        </VendorCard>
                                                        <VendorText data-component="VendorText">
                                                            Elettric80
                                                        </VendorText>
                                                    </VendorCol>
                                                </VendorRow>
                                                {isVendorModal?
                                                    <VendorModalWrap data-component="VendorModalWrap">
                                                        <VendorModalHeader data-component="VendorModalHeader">
                                                            <h3 className='vendor-name'>JBT</h3>
                                                            <VendorCloseBtn data-component="VendorCloseBtn" onClick = {() =>{ setIsVendorModal(false); setVendorTab(1);}}>
                                                                <i dangerouslySetInnerHTML={{ __html: vendorCloseSvg }}></i>
                                                            </VendorCloseBtn>
                                                        </VendorModalHeader>
                                                        <VendorTabWrap data-component="VendorTabWrap">
                                                            <VendorTabs data-component="VendorTabs" >
                                                                    <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab==1 && 'active'}`} onClick={()=>setVendorTab(1)}>Investment</VendorTab>
                                                                    <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 2 && 'active'}`} onClick={()=>setVendorTab(2)}> Recoverable </VendorTab>
                                                            </VendorTabs>
                                                            {vendorTab==2 ?
                                                                <VendorTabPanel data-component="VendorTabPanel">
                                                                    <AccordionTabWrapper data-component="AccordionTabWrapper">
                                                                        <Accordion expanded={expanded === `panel1-${index}`} onChange={HandleChange(`panel1-${index}`)}>
                                                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                                                <AccordionHeader data-component="AccordionHeader">
                                                                                    <AccordImg data-component="AccordImg">
                                                                                        <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                    </AccordImg>
                                                                                    <span className="accord-txt">Maintenance Losses</span>
                                                                                </AccordionHeader>
                                                                                <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <MaintainWrap data-component="MaintainWrap">
                                                                                    <MaintainRow data-component="MaintainRow">
                                                                                        <LeftMainCol data-component="LeftMainCol">
                                                                                            <AccordImg data-component="AccordImg">
                                                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                            </AccordImg>
                                                                                            <span className="accord-txt">Maintenance Losses</span>
                                                                                        </LeftMainCol>
                                                                                        <RightMainCol data-component="RightMainCol">
                                                                                            <span className="main-val">10%</span>
                                                                                        </RightMainCol>
                                                                                    </MaintainRow>
                                                                                    <MaintainRow data-component="MaintainRow">
                                                                                        <LeftMainCol data-component="LeftMainCol">
                                                                                            <AccordImg data-component="AccordImg">
                                                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                            </AccordImg>
                                                                                            <span className="accord-txt">Maintenance Losses</span>
                                                                                        </LeftMainCol>
                                                                                        <RightMainCol data-component="RightMainCol">
                                                                                            <span className="main-val">10%</span>
                                                                                        </RightMainCol>
                                                                                    </MaintainRow>
                                                                                </MaintainWrap>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                        <Accordion expanded={expanded === `panel2-${index}`} onChange={HandleChange(`panel2-${index}`)}>
                                                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                                                <AccordionHeader data-component="AccordionHeader">
                                                                                    <AccordImg data-component="AccordImg">
                                                                                        <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                    </AccordImg>
                                                                                    <span className="accord-txt">Maintenance Losses</span>
                                                                                </AccordionHeader>
                                                                                <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <MaintainWrap data-component="MaintainWrap">
                                                                                    <MaintainRow data-component="MaintainRow">
                                                                                        <LeftMainCol data-component="LeftMainCol">
                                                                                            <AccordImg data-component="AccordImg">
                                                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                            </AccordImg>
                                                                                            <span className="accord-txt">Line Efficiency Losses</span>
                                                                                        </LeftMainCol>
                                                                                        <RightMainCol data-component="RightMainCol">
                                                                                            <span className="main-val">10%</span>
                                                                                        </RightMainCol>
                                                                                    </MaintainRow>
                                                                                    <MaintainRow data-component="MaintainRow">
                                                                                        <LeftMainCol data-component="LeftMainCol">
                                                                                            <AccordImg data-component="AccordImg">
                                                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                            </AccordImg>
                                                                                            <span className="accord-txt">Maintenance Losses</span>
                                                                                        </LeftMainCol>
                                                                                        <RightMainCol data-component="RightMainCol">
                                                                                            <span className="main-val">10%</span>
                                                                                        </RightMainCol>
                                                                                    </MaintainRow>
                                                                                </MaintainWrap>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </AccordionTabWrapper>
                                                                </VendorTabPanel>
                                                                :
                                                                <VendorTabPanel data-component="VendorTabPanel">
                                                                    <ListInvestWrap data-component="ListInvestWrap">
                                                                        <ListRow data-component="ListRow">
                                                                            <p className='blue-txt'>CAPEX</p>
                                                                            <ListValWrap data-component="ListValWrap">
                                                                                <span className='money-txt'>375,000</span>
                                                                                <span className='money-val'>€</span>
                                                                            </ListValWrap>
                                                                        </ListRow>
                                                                        <ListRow data-component="ListRow">
                                                                            <p className='blue-txt'>Starting OPEX</p>
                                                                            <ListValWrap data-component="ListValWrap">
                                                                                <span className='money-txt'>60,000</span>
                                                                                <span className='money-val'>€</span>
                                                                            </ListValWrap>
                                                                        </ListRow>
                                                                        <UpfrontListRow data-component="UpfrontListRow">
                                                                            <p className='black-txt'>Upfront Investment</p>
                                                                            <ListValWrap data-component="ListValWrap">
                                                                                <span className='money-txt'>435,000</span>
                                                                                <span className='money-val'>€</span>
                                                                            </ListValWrap>
                                                                        </UpfrontListRow>
                                                                    </ListInvestWrap>
                                                                    <GoListWrap data-component="GoListWrap">
                                                                        <p className='blue-txt'>Going OPEX</p>
                                                                        <ListValWrap data-component="ListValWrap">
                                                                            <span className='money-txt'>60,000</span>
                                                                            <span className='money-val'>€</span>
                                                                        </ListValWrap>
                                                                    </GoListWrap>
                                                                    <UserWrap data-component="UserWrap">
                                                                        <span className='user-txt'>No. of Users:</span>
                                                                        <span className='user-num'>5</span>
                                                                    </UserWrap>
                                                                </VendorTabPanel>
                                                            }                 
                                                        </VendorTabWrap>
                                                    </VendorModalWrap>
                                                :''}
                                            </VendorWrap>
                                        </TabPanel>
                                        <TabPanel value={leftValue} index={`l3-${index}`}>
                                        <QNACard data-component="QNACard" className={`${qnaOpen===`q0-${index}`?'active':''}`}>
                                                <QNABtn data-component="QNABtn" onClick={()=>handleQnaTabClick(`q0-${index}`)}>
                                                    <span className="q-txt">1.</span>
                                                    <p className="q-desc">
                                                        How do you remove waste from your shop floor?               
                                                    </p>
                                                </QNABtn>
                                                <Collapse in={qnaOpen===`q0-${index}`} timeout="auto">
                                                  <QNAWrap data-component="QNAWrap">
                                                    <table>
                                                      <tbody>
                                                        <tr>
                                                          <th className="bd-bottom bd-right"></th>
                                                          <th className="th-align bd-bottom bd-right">All Lines</th>
                                                          <th className="th-align bd-bottom bd-right">No Lines</th>
                                                          <th className="th-align bd-bottom bd-right">Some Li.</th>
                                                          <th className="th-align bd-bottom ">Skipped</th>
                                                        </tr>
                                                        <tr>
                                                          <th className="bd-bottom bd-right">
                                                            <span>A.</span>Cardboard Loading
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                        <tr>
                                                          <th className="bd-bottom bd-right">
                                                            <span>B.</span>Case Forming and Sealing
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                        <tr>
                                                          <th class="bd-bottom bd-right">
                                                            <span>C.</span>Pick and place items into boxes
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                        <tr>
                                                          <th class="bd-bottom bd-right">
                                                            <span>D.</span>Document inserting
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </QNAWrap>
                                                </Collapse>
                                            </QNACard>
                                            <QNACard data-component="QNACard" className={`${qnaOpen===`q1-${index}`?'active':''}`}>
                                                <QNABtn data-component="QNABtn" onClick={()=>handleQnaTabClick(`q1-${index}`)}>
                                                    <span className="q-txt">2.</span>
                                                    <p className="q-desc">How would you best describe your case forming process?</p>
                                                </QNABtn>
                                                <Collapse in={qnaOpen===`q1-${index}`} timeout="auto">
                                                  <QNAWrap data-component="QNAWrap">
                                                    <table>
                                                      <tbody>
                                                        <tr>
                                                          <th className="bd-bottom bd-right"></th>
                                                          <th className="th-align bd-bottom bd-right">All Lines</th>
                                                          <th className="th-align bd-bottom bd-right">No Lines</th>
                                                          <th className="th-align bd-bottom bd-right">Some Li.</th>
                                                          <th className="th-align bd-bottom ">Skipped</th>
                                                        </tr>
                                                        <tr>
                                                          <th className="bd-bottom bd-right">
                                                            <span>A.</span>
                                                             Manual. Our operators form the cases by hand                                                                                 
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                        <tr>
                                                          <th className="bd-bottom bd-right">
                                                            <span>B.</span>
                                                            Assisted. A special workbench helps the operators form the cardboards easily                                                                                 
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                        <tr>
                                                          <th class="bd-bottom bd-right">
                                                            <span>C.</span>
                                                            Automated. A dedicated machine forms the cases and feeds them to the line                                                                                 
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                        <tr>
                                                          <th class="bd-bottom bd-right">
                                                            <span>D.</span>Document inserting
                                                          </th>
                                                          <td className="bd-bottom bd-right"><i dangerouslySetInnerHTML={{ __html: blackSvg }}></i></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                          <td className="bd-bottom bd-right"></td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </QNAWrap>
                                                </Collapse>
                                            </QNACard>
                                        </TabPanel>
                                        </TabContentWraaper>
                                    </Collapse>
                                </TabCardWrapper>
                            </MainCardWrapper>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </ContentBody>
          </LeftSection>
          <RightSection data-component="RightSection">
            <SectionHeader data-component="SectionHeader">
              <ScenarioSectionWrapper data-component="ScenarioSectionWrapper">
                <ScenarioDropdown
                  options={scenarioList}
                  setOptions={setScenarioList}
                />
                <SaveRoadmapButton
                  data-component="SaveRoadmapButton"
                  disabled={false}
                >
                  Save Roadmap
                </SaveRoadmapButton>
              </ScenarioSectionWrapper>
              <p>This is your default scenario to be displayed on Home page</p>
            </SectionHeader>

            <ContentBody data-component="ContentBody">
              <h3 className="blue-txt">Roadmap</h3>
              <LossWrap data-component="LossWrap">
                  <LossRow data-component="LossRow">
                      <LossCol data-component="LossCol">
                          <span className="text">Total Losses</span>
                          <span className="text">Identified:</span>
                          <span className="text orange" style={{display:"none"}}>1,145,160 €</span>
                          <UpgradeLock data-component="UpgradeLock">
                            <i dangerouslySetInnerHTML={{ __html: upgradeRightLockIcon }}></i>
                            <div className="upgrade-tooltip">
                              <span>Get IR4.0 Loss Analysis</span>
                            </div>  
                          </UpgradeLock>
                      </LossCol>
                      <LossCol data-component="LossCol">
                          <span className="text">Total Roadmap</span>
                          <span className="text"> Recoverable:</span>
                          <span className="text green" style={{display:"none"}}>156,899 €</span>
                          <UpgradeLock data-component="UpgradeLock">
                            <i dangerouslySetInnerHTML={{ __html: upgradeRightLockIcon }}></i>
                            <div className="upgrade-tooltip">
                              <span>Get IR4.0 Loss Analysis</span>
                            </div>
                          </UpgradeLock>
                      </LossCol>
                      <LossCol data-component="LossCol">
                          <span className="text">% of Recoverable</span>
                          <span className="text"> Losses:</span>
                          <span className="text blue" style={{display:"none"}}>11%</span>
                          <UpgradeLock data-component="UpgradeLock">
                            <i dangerouslySetInnerHTML={{ __html: upgradeRightLockIcon }}></i>
                            <div className="upgrade-tooltip">
                              <span>Get IR4.0 Loss Analysis</span>
                            </div>
                          </UpgradeLock>
                      </LossCol>
                      <LossCol data-component="LossCol">
                          <span className="text">Total Roadmap</span>
                          <span className="text">Investment:</span>
                          <span className="text orange" style={{display:"none"}}>3364000 €</span>
                          <UpgradeLock data-component="UpgradeLock">
                            <i dangerouslySetInnerHTML={{ __html: upgradeRightLockIcon }}></i>
                            <div className="upgrade-tooltip">
                              <span>Get IR4.0 Loss Analysis</span>
                            </div>  
                          </UpgradeLock>
                      </LossCol>
                  </LossRow>
              </LossWrap>
              {/* Upgrade Pop Wrapper start */}
              {openUpgradePop && 
                <UpgradePopWrapper data-component="UpgradePopWrapper">
                  <UpgradePopCard data-component="UpgradePopCard">
                    <h2 className="upsell-title">
                      Get access to Roadmap Solution - IR4.0 Strategies
                    </h2>
                    <p className="upsell-text">
                      Following SmarterChains remote Assessment on Digital Readiness 
                      the next step is to take structured steps towards digital transformation 
                      by launching your Industry 4.0 Roadmap.
                    </p>
                    <button className="upgrade-close-btn" onClick={()=>setOpenUpgradePop(false)}>
                      <i dangerouslySetInnerHTML={{__html: upgradeCloseIcon}}></i>
                    </button>
                    <UpgradePopRow data-component="UpgradePopRow">
                      <UpgradePopCol data-component="UpgradePopCol">
                        <UpgradeInnerCard data-component="UpgradeInnerCard">
                          <UpgradeInnerHead data-component="UpgradeInnerHead">
                            <i dangerouslySetInnerHTML={{__html: goalIcon}}></i>
                            <span className="head-text">Goal</span>
                          </UpgradeInnerHead>
                          <p className="upgrade-txt">
                            <b>Identify</b> key loss opportunities and <b>define</b> solutions to drive efficiency improvements
                          </p>
                        </UpgradeInnerCard>
                      </UpgradePopCol>
                      <UpgradePopCol data-component="UpgradePopCol">
                        <UpgradeInnerCard data-component="UpgradeInnerCard">
                          <UpgradeInnerHead data-component="UpgradeInnerHead">
                            <i dangerouslySetInnerHTML={{__html: methodIcon}}></i>
                            <span className="head-text">Methodology</span>
                          </UpgradeInnerHead>
                          <p className="upgrade-txt">
                            Loss and <b>financial analysis</b>, <b>solution selection</b> and <b>cost-benefit analysis</b> of the selected solutions
                          </p>
                        </UpgradeInnerCard>
                      </UpgradePopCol>
                      <UpgradePopCol data-component="UpgradePopCol">
                        <UpgradeInnerCard data-component="UpgradeInnerCard">
                          <UpgradeInnerHead data-component="UpgradeInnerHead">
                            <i dangerouslySetInnerHTML={{__html: deliverIcon}}></i>
                            <span className="head-text">Deliverables</span>
                          </UpgradeInnerHead>
                          <p className="upgrade-txt">
                            <b>Industry 4.0 Roadmap</b> with technology and organizational solutions, their <b>cost-benefit analysis</b> and <b>3 vendors</b> for each solution
                          </p>
                        </UpgradeInnerCard>
                      </UpgradePopCol>
                    </UpgradePopRow>
                    <UpgradeFooter data-component="UpgradeFooter">
                      <button className="main-cart-btn" onClick={(e) => handleUpgradePopupClick(e)}>
                        <span className="cart-txt">Upgrade</span>
                        <span className="cart-price">€ 1500</span>
                      </button>
                    </UpgradeFooter>
                  </UpgradePopCard>
                </UpgradePopWrapper>
              }
            {/* Upgrade Pop Wrapper end */}
              <Droppable droppableId="right">

                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ minHeight: "100%"}}
                    onDragOver={()=>console.log('over')}
                  >
                    {rightCards.map((card, index) => (
                      <div
                        key={card.id}
                        className={`card${card.disabled ? " disabled" : ""}`}
                      >
                        <MainCardWrapper
                          key={index}
                          data-component="MainCardWrapper"
                        >
                          <RightTabCardWrapper data-component="RightTabCardWrapper">
                            
                            <TabButton data-component="TabButton">
                                <TBRow data-component="TBRow">
                                    <TBLeftCol data-component="TBLeftCol">
                                        <button className="drag-btn blue">
                                            <span>{index+1}</span>
                                        </button>
                                        <p className='rmap-txt'>
                                            {card.card_title}                                                                            
                                        </p>
                                    </TBLeftCol>
                                    <TBRightCol data-component="TBRightCol">
                                        <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                <SubText data-component="SubText" green>{card.recoverable_value}€</SubText>
                                            </RightAlignTextWrap>
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                <HeadText data-component="HeadText">Investment: </HeadText>
                                                <SubText data-component="SubText" orange>{card.investment}€</SubText>
                                            </RightAlignTextWrap>
                                        </RightAlignTextWrapper>
                                        <AddDragBtn data-component="AddDragBtn" onClick={(e)=>removeRightCard(card.id,e)}>
                                            <i dangerouslySetInnerHTML={{ __html: minusIcon }}></i>
                                        </AddDragBtn>
                                        <CollapseBtn data-component="CollapseBtn" onClick={()=>handleRightClick(card.id,index)} className={`${rightOpen===card.id?'active':''}`}>
                                            {rightOpen===card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i>  : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                        </CollapseBtn>
                                    </TBRightCol>
                                </TBRow>
                            </TabButton>
                            <Collapse in={rightOpen===card.id} timeout="auto">
                                <TabContentWraaper data-component="TabContentWraaper">
                                    <Tabs
                                            orientation="horizontal"
                                            variant="scrollable"
                                            value={rightValue}
                                            onChange={handleRightChange}
                                            aria-label="Vertical tabs example"
                                            
                                            >
                                            <Tab value={`r0-${index}`} label="Description" {...a11yProps(0)} />
                                            <Tab value={`r1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                                            <Tab value={`r2-${index}`} label="Vendors" {...a11yProps(2)} />
                                    </Tabs>
                                
                                <TabPanel value={rightValue} index={`r0-${index}`}>
                                    <TabPanelContent data-component="TabPanelContent">
                                        <p>Moving forward with Industry 4.0, sensors, real-time location services & 
                                        workforce mobility become an integral part of manufacturing operations. Without reliable &
                                        effective industrial wireless, it’s difficult to imagine a true smart factory materialize. 
                                        In the Factory of the Future, a vast amount of sensors, devices, networks and protocols must 
                                        seamlessly interact to transport data over Bluetooth, Message Queuing Telemetry Transport, 
                                        Wi-Fi, Ethernet, narrowband cellular, and across the Wide Area Network, to routers, servers, 
                                        edge gateways and corporate data centres.</p>
                                        <p>The stack of network protocols must be:</p>
                                        <ul>
                                            <li>Adaptive to constrained bandwidth</li>
                                            <li>Power-efficient and low-interference</li>
                                            <li>Secure across multiple system layers</li>
                                        </ul>
                                        <p>Similarly, networking equipment and software should maintain a fast and stable wireless 
                                            connection in harsh industrial environments, and they should support the protocols, 
                                            topologies and the sheer amount of data produced.
                                        </p>
                                        <p class="bc_head_h4">Benefits</p>
                                        <ul class="mb-08vw">
                                            <li>Wireless networks give operations leaders access to vital production information across the site</li>
                                            <li>Operators can track critical data on handheld devices, and receive real-time alerts from equipment</li>
                                            <li>It is significantly more affordable than cable</li>
                                            <li>It enables more flexibility in configuring the plant floor, which is critical when introducing new product lines or changing processes</li>
                                        </ul>
                                        <div class="bc_leadership">
                                            <i dangerouslySetInnerHTML={{ __html: digitalSvg }}></i>
                                            Digital-ready Infrastructure                                                                                    
                                        </div>
                                    </TabPanelContent>
                                </TabPanel>
                                <TabPanel value={rightValue} index={`r1-${index}`}>
                                    <InnerTabWrapper data-component="InnerTabWrapper">
                                        <TBRow data-component="TBRow">
                                            <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                                <p className="inner-tab-txt">Operator Effort Losses</p>
                                            </InnerTBLeftCol>
                                            <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                                <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                                    <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                        <SubText data-component="SubText" green>2,813€</SubText>
                                                    </RightAlignTextWrap>
                                                    <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Investment: </HeadText>
                                                        <SubText data-component="SubText" orange>177,000€</SubText>
                                                    </RightAlignTextWrap>
                                                </RightAlignTextWrapper>
                                                <p className='recovery-txt'>
                                                    <span>8% </span>
                                                      of category <br/>losses Recovered
                                                </p>
                                                <CollapseBtn data-component="CollapseBtn" onClick={()=>handleRightInnerTabClick(`loss0-${index}`)} className={`${lossRightDataOpen===`loss0-${index}`?'active':''}`}>
                                                    {lossRightDataOpen===`loss0-${index}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i>  : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                </CollapseBtn>
                                            </InnerTBRightCol>
                                        </TBRow>
                                    </InnerTabWrapper>
                                    <Collapse in={lossRightDataOpen===`loss0-${index}`} timeout="auto">
                                        <LossTableWrap data-component="LossTableWrap">
                                            <LossScrollTableWrap data-component="LossScrollTableWrap">
                                                <table className='responsive-loss-table'>
                                                    <thead>
                                                        <tr>    
                                                            <th className='loss-table-col'>Sublosses</th>
                                                            <th className='loss-table-col'>
                                                                Identified<br/> Losses 
                                                            </th>
                                                            <th className='loss-table-col'>Recoverable</th>
                                                            <th className='loss-table-col'>% of Subloss<br/>recoverable </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>    
                                                            <td className='loss-table-col'>Reviewing production information to ensure quality compliant product release</td>
                                                            <td className='loss-table-col orange'>78,139<span>€</span></td>
                                                            <td className='loss-table-col green'>6,312<span>€</span></td>
                                                            <td className='loss-table-col blue'>8%</td>
                                                        </tr>
                                                        <tr>    
                                                            <td className='loss-table-col bottom black'>Total</td>
                                                            <td className='loss-table-col bottom black'>78,139<span>€</span></td>
                                                            <td className='loss-table-col bottom black'>6,312<span>€</span></td>
                                                            <td className='loss-table-col bottom black'></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </LossScrollTableWrap>
                                        </LossTableWrap>
                                    </Collapse>
                                    <LossWrap data-component="LossWrap">
                                        <LossRow data-component="LossRow">
                                            <LossCol data-component="LossCol">
                                                <span className="text">Associated</span>
                                                <span className="text">Losses:</span>
                                                <span className="text green">8,943 €</span>
                                            </LossCol>
                                            <LossCol data-component="LossCol">
                                                <span className="text">Total Technology</span>
                                                <span className="text"> Recoverable:</span>
                                                <span className="text orange">1,288 €</span>
                                            </LossCol>
                                            <LossCol data-component="LossCol">
                                                <span className="text">% of Total</span>
                                                <span className="text"> Recovered Losses:</span>
                                                <span className="text blue">12%</span>
                                            </LossCol>
                                        </LossRow>
                                    </LossWrap>
                                </TabPanel>
                                <TabPanel value={rightValue} index={`r2-${index}`}>
                                    <VendorWrap data-component="VendorWrap">
                                        <VendorRow data-component="VendorRow">
                                            <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                    <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorRightModal(true)}>
                                                        <img src={require("../../assets/img/roadmap-page/dematic.png").default } />
                                                    </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                    Dematic
                                                </VendorText>
                                            </VendorCol>
                                            <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                    <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorRightModal(true)}>
                                                        <img src={require("../../assets/img/roadmap-page/jbt.png").default } />
                                                    </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                    JBT
                                                </VendorText>
                                            </VendorCol>
                                            <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                    <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorRightModal(true)}>
                                                        <img src={require("../../assets/img/roadmap-page/toyota.png").default } />
                                                    </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                    Toyota Material Handling
                                                </VendorText>
                                            </VendorCol>
                                            <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                    <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorRightModal(true)}>
                                                        <img src={require("../../assets/img/roadmap-page/linde.png").default } />
                                                    </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                    Linde Material Handling
                                                </VendorText>
                                            </VendorCol>
                                            <VendorCol data-component="VendorCol">
                                                <VendorCard data-component="VendorCard">
                                                    <VendorClickImg data-componenet='VendorClickImg' onClick = {() => setIsVendorRightModal(true)}>
                                                        <img src={require("../../assets/img/roadmap-page/electric.png").default } />
                                                    </VendorClickImg>
                                                </VendorCard>
                                                <VendorText data-component="VendorText">
                                                    Elettric80
                                                </VendorText>
                                            </VendorCol>
                                        </VendorRow>
                                        {isVendorRightModal?
                                            <VendorModalWrap data-component="VendorModalWrap">
                                                <VendorModalHeader data-component="VendorModalHeader">
                                                    <h3 className='vendor-name'>JBT</h3>
                                                    <VendorCloseBtn data-component="VendorCloseBtn"  onClick = {() =>{ setIsVendorRightModal(false); setVendorTab(1);}}>
                                                        <i dangerouslySetInnerHTML={{ __html: vendorCloseSvg }}></i>
                                                    </VendorCloseBtn>
                                                </VendorModalHeader>
                                                <VendorTabWrap data-component="VendorTabWrap">
                                                    <VendorTabs data-component="VendorTabs" >
                                                            <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab==1 && 'active'}`} onClick={()=>setVendorTab(1)}>Investment</VendorTab>
                                                            <VendorTab data-component="VendorTab" className={`vendor-tab ${vendorTab == 2 && 'active'}`} onClick={()=>setVendorTab(2)}> Recoverable </VendorTab>
                                                    </VendorTabs>
                                                    {vendorTab==2 ?
                                                        <VendorTabPanel data-component="VendorTabPanel">
                                                            <AccordionTabWrapper data-component="AccordionTabWrapper">
                                                                <Accordion expanded={expanded === 'panel1'} onChange={HandleChange('panel1')}>
                                                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                                        <AccordionHeader data-component="AccordionHeader">
                                                                            <AccordImg data-component="AccordImg">
                                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                            </AccordImg>
                                                                            <span className="accord-txt">Maintenance Losses</span>
                                                                        </AccordionHeader>
                                                                        <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <MaintainWrap data-component="MaintainWrap">
                                                                            <MaintainRow data-component="MaintainRow">
                                                                                <LeftMainCol data-component="LeftMainCol">
                                                                                    <AccordImg data-component="AccordImg">
                                                                                        <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                    </AccordImg>
                                                                                    <span className="accord-txt">Maintenance Losses</span>
                                                                                </LeftMainCol>
                                                                                <RightMainCol data-component="RightMainCol">
                                                                                    <span className="main-val">10%</span>
                                                                                </RightMainCol>
                                                                            </MaintainRow>
                                                                            <MaintainRow data-component="MaintainRow">
                                                                                <LeftMainCol data-component="LeftMainCol">
                                                                                    <AccordImg data-component="AccordImg">
                                                                                        <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                    </AccordImg>
                                                                                    <span className="accord-txt">Maintenance Losses</span>
                                                                                </LeftMainCol>
                                                                                <RightMainCol data-component="RightMainCol">
                                                                                    <span className="main-val">10%</span>
                                                                                </RightMainCol>
                                                                            </MaintainRow>
                                                                        </MaintainWrap>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                                <Accordion expanded={expanded === 'panel2'} onChange={HandleChange('panel2')}>
                                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                                        <AccordionHeader data-component="AccordionHeader">
                                                                            <AccordImg data-component="AccordImg">
                                                                                <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                            </AccordImg>
                                                                            <span className="accord-txt">Maintenance Losses</span>
                                                                        </AccordionHeader>
                                                                        <span className="vendor-hidden-text">Vendor Recoverable</span>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <MaintainWrap data-component="MaintainWrap">
                                                                            <MaintainRow data-component="MaintainRow">
                                                                                <LeftMainCol data-component="LeftMainCol">
                                                                                    <AccordImg data-component="AccordImg">
                                                                                        <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                    </AccordImg>
                                                                                    <span className="accord-txt">Line Efficiency Losses</span>
                                                                                </LeftMainCol>
                                                                                <RightMainCol data-component="RightMainCol">
                                                                                    <span className="main-val">10%</span>
                                                                                </RightMainCol>
                                                                            </MaintainRow>
                                                                            <MaintainRow data-component="MaintainRow">
                                                                                <LeftMainCol data-component="LeftMainCol">
                                                                                    <AccordImg data-component="AccordImg">
                                                                                        <i dangerouslySetInnerHTML={{ __html: accordSvg }}></i>
                                                                                    </AccordImg>
                                                                                    <span className="accord-txt">Maintenance Losses</span>
                                                                                </LeftMainCol>
                                                                                <RightMainCol data-component="RightMainCol">
                                                                                    <span className="main-val">10%</span>
                                                                                </RightMainCol>
                                                                            </MaintainRow>
                                                                        </MaintainWrap>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </AccordionTabWrapper>
                                                        </VendorTabPanel>
                                                        :
                                                        <VendorTabPanel data-component="VendorTabPanel">
                                                            <ListInvestWrap data-component="ListInvestWrap">
                                                                <ListRow data-component="ListRow">
                                                                    <p className='blue-txt'>CAPEX</p>
                                                                    <ListValWrap data-component="ListValWrap">
                                                                        <span className='money-txt'>375,000</span>
                                                                        <span className='money-val'>€</span>
                                                                    </ListValWrap>
                                                                </ListRow>
                                                                <ListRow data-component="ListRow">
                                                                    <p className='blue-txt'>Starting OPEX</p>
                                                                    <ListValWrap data-component="ListValWrap">
                                                                        <span className='money-txt'>60,000</span>
                                                                        <span className='money-val'>€</span>
                                                                    </ListValWrap>
                                                                </ListRow>
                                                                <UpfrontListRow data-component="UpfrontListRow">
                                                                    <p className='black-txt'>Upfront Investment</p>
                                                                    <ListValWrap data-component="ListValWrap">
                                                                        <span className='money-txt'>435,000</span>
                                                                        <span className='money-val'>€</span>
                                                                    </ListValWrap>
                                                                </UpfrontListRow>
                                                            </ListInvestWrap>
                                                            <GoListWrap data-component="GoListWrap">
                                                                <p className='blue-txt'>Going OPEX</p>
                                                                <ListValWrap data-component="ListValWrap">
                                                                    <span className='money-txt'>60,000</span>
                                                                    <span className='money-val'>€</span>
                                                                </ListValWrap>
                                                            </GoListWrap>
                                                            <UserWrap data-component="UserWrap">
                                                                <span className='user-txt'>No. of Users:</span>
                                                                <span className='user-num'>5</span>
                                                            </UserWrap>
                                                        </VendorTabPanel>
                                                    }                 
                                                </VendorTabWrap>
                                            </VendorModalWrap>
                                        :''}
                                    </VendorWrap>
                                </TabPanel>
                                </TabContentWraaper>
                            </Collapse>
                          </RightTabCardWrapper>
                        </MainCardWrapper>
                      </div>
                    ))}
                    <TabButton data-component="TabButton" className="disabled">
                        <TBRow data-component="TBRow">
                            <TBLeftCol data-component="TBLeftCol">
                                <button className="drag-btn disabled">
                                    <span>{rightCards.length+1}</span>
                                </button>
                                <p className='rmap-txt'>                                                                  
                                </p>
                            </TBLeftCol>
                            <TBRightCol data-component="TBRightCol">
                              
                            
                                <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                 
                                </RightAlignTextWrapper>
                                <AddDragBtn data-component="AddDragBtn">
                                    <i dangerouslySetInnerHTML={{ __html: minusIcon }}></i>
                                </AddDragBtn>
                                <CollapseBtn data-component="CollapseBtn">
                                    <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>
                                </CollapseBtn>
                            </TBRightCol>
                        </TBRow>
                    </TabButton>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </ContentBody>
            <ContentFooter data-component="ContentFooter">
                <button className='proceed-btn' onClick={()=>setOpenPopup(true)}>Proceed</button>
                {openPopup?
                <ProceedPopWrapper data-component="ProceedPopWrapper">
                    <ProceedPopCard data-component="ProceedPopCard">
                        <PopHead data-component="PopHead">
                            <span>Caution</span>
                            <button className="close-pop" onClick={()=>setOpenPopup(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                            </button>
                        </PopHead>
                        <p className='pop-txt'>You have unsaved changes. Please save roadmap before proceeding.</p>
                        <PopFooter data-component="PopFooter">
                            <Link to="/roadmap/step2" className='save-btn solid'>Save & Proceed</Link>
                            <button className='leave-btn transparent'>Leave</button>
                        </PopFooter>
                    </ProceedPopCard>
                </ProceedPopWrapper>:''}
            </ContentFooter>
          </RightSection>
        </SectionWrapper>
      </MainWrapper>
      {/* Upgrade Wrapper */}
      {/* Roadmap Pricing popup */}
      {showUpgradePrice && <RoadmapAsmtUpgradeView openPricePop={showUpgradePrice} setOpenPricePop={setShowUpgradePrice}/>}
    </DragDropContext>
    {/* How to use popup */}
    <div className="how-to-use-ctrl-wrap">
      <HowToUseCtrl />
    </div>
    {/* Technology/Feature Popup */}
    {isTechModal ?
    <TechnologyPopWrapper data-component="TechnologyPopWrapper">
      <TechnologyPopWrap data-component="TechnologyPopWrap>">
        <TechnologyPopHead data-component="TechnologyPopHead">
          <h3>Roadmap Steps : Augmented Reality (AR) Remote Support</h3>
          <button className="close-popup" onClick={()=>setIsTechModal(false)}>
              <i dangerouslySetInnerHTML={{ __html: closeTechSvg }}></i>
          </button>
        </TechnologyPopHead>
        <TechnologyPopBody data-component="TechnologyPopBody">
          <TechnologyPopBodyWrap data-component="TechnologyPopBodyWrap">
            <table className="table-wrap">
              <thead>
                <tr className="tab-names">
                  <th className="border-curve">Parent Technology </th>
                  <th>Technology</th>
                  <th>Feature</th>
                  <th>Status</th>
                  <th>Industry Adoption</th>
                  <th>Company Adoption</th>
                  <th>BIC Adoption</th>
                  <th>l1</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td>3D Printing</td>
                    <td>3D Printing Use</td>
                    <td>NA</td>
                    <td>
                      <p class="status has-not">HAS NOT</p>  
                    </td>
                    <td>0%</td>
                    <td>0%</td>
                    <td>3%</td>         
                    <td><p class="status has-not">HAS NOT</p></td>                           
                </tr>
                <tr>
                    <td>3D Printing</td>
                    <td>3D Printing Use</td>
                    <td>NA</td>
                    <td>
                      <p class="status has">HAS</p>  
                    </td>
                    <td>0%</td>
                    <td>0%</td>
                    <td>3%</td>         
                    <td><p class="status has">HAS</p></td>                           
                </tr>
                <tr>
                    <td>3D Printing</td>
                    <td>3D Printing Use</td>
                    <td>NA</td>
                    <td>
                      <p class="status partial">Partially</p>  
                    </td>
                    <td>0%</td>
                    <td>0%</td>
                    <td>3%</td>         
                    <td><p class="status partial">Partially</p></td>                           
                </tr>
                <tr>
                    <td>3D Printing</td>
                    <td>3D Printing Use</td>
                    <td>NA</td>
                    <td>
                      <p class="status na">NA</p>  
                    </td>
                    <td>0%</td>
                    <td>0%</td>
                    <td>3%</td>         
                    <td><p class="status na">NA</p></td>                           
                </tr>
              </tbody>
            </table>
          </TechnologyPopBodyWrap>
        </TechnologyPopBody>
      </TechnologyPopWrap>
    </TechnologyPopWrapper>
    :''
    }
    </>
  );
};

// export default DragDropContainer;
