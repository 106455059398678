import React, { useState } from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { closeSvg} from './siri-contact-svg.js';
import {
    ContactPopupWrapper,
    ContactPopupCard,
    ContactPopupHeader,
    ContactPopupBody,
    ContactPopupFooter,
    LeftFooter,
    ThankYouPop,
    TopSection,
    BottomSection,
} from './siri-contact-style.js';
import serviceProvider from '../../services/axios';


export const SiriContactView = (props) => { 
    const [pFormError, setPFormError] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const {isContactPopup, setIsContactPopup} = props;
    const [value, setValue] = useState();
    const [text, setText] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const[valueLength,setValueLength]= useState(0);



    const handlePhoneChange = (phone) => {
        var element = document.getElementById("phoneNo");
        if (phone) {
          element.classList.remove("show-error");
          if (pFormError) {
            setPFormError(pFormError - 1);
          }
            setIsPhoneNumberValid(true);
          setValue(phone);
        } else {
          element.classList.add("show-error");
          setPFormError(pFormError + 1);
          setIsPhoneNumberValid(false);
        }
        if (value !== undefined) {
            // Convert 'value' to a string and count its length
            const valueAsString = value.toString();
            const lengthOfValue = valueAsString.length;
            setValueLength(lengthOfValue);
          } else {
          }

      }; 

    const handleTextChange = (event) => {
        const newText = event.target.value;
        setText(newText);
      };
    const handleSubmit = ()=>{
        setIsSubmit(true);
        let cta = localStorage.getItem("upgradeSection");
        serviceProvider
          .post("/user?type=contactus", JSON.stringify({'cta':cta,'user_id':props.user_id,'email':props.email,'first_name':props.first_name,'last_name':props.last_name,'message':text,'phone':value}))
          .then((response) => {
            localStorage.setItem(`upgradeSection`, null);
          });
    }

    return (
    <>
    {isContactPopup &&
        <ContactPopupWrapper data-component="ContactPopupWrapper">
            <ContactPopupCard data-component="ContactPopupCard">
                <ContactPopupHeader data-component="ContactPopupHeader">
                    <span className='contact'>Contact Us</span>
                    <button className='contact-close-btn' onClick={()=>setIsContactPopup(false)}>
                        <i dangerouslySetInnerHTML={{__html:closeSvg}}></i>
                    </button>
                </ContactPopupHeader>
                {isSubmit?
                    <ThankYouPop data-component="ThankYouPop">
                        <TopSection data-component="TopSection">
                            <img src={require("../../assets/img/siri-contact-img/contact.gif").default}/>
                            <span className='bold-text'>Thank you .</span>
                            <span className='normal-text'>We have received your message and we will reach out to you soon.</span>
                        </TopSection>
                        <BottomSection data-component="BottomSection">
                            <button className='ok-btn' onClick={()=>setIsContactPopup(false)}>Okay</button>
                        </BottomSection>    
                    </ThankYouPop>
                    :
                    <>
                        <ContactPopupBody data-component="ContactPopupBody">
                            <h2>Hi, how may we help you?</h2>
                            <textarea 
                                placeholder='Please let us know how we can help you'
                                value={text}
                                onChange={handleTextChange}
                            >
                            </textarea>
                            {/* <span className='how-can-hlep-txt'></span> */}
                        </ContactPopupBody>
                        <ContactPopupFooter data-component="ContactPopupFooter">
                            <LeftFooter data-component="LeftFooter">
                                <div id="phoneNo" className="profile__form__controller ">
                                    <PhoneInput
                                    specialLabel="Contact Number*"
                                    inputClass="contact__phone__input"
                                    country={"us"}
                                    value={value}
                                    inputProps={{ name: "phoneNo" }}
                                    onChange={(phone) => handlePhoneChange(phone)}
                                    />
                                    <p className="error">Enter valid Phone Number</p>
                                </div>
                            </LeftFooter>
                            <button className={`submit-btn ${ text.length>0 && valueLength>10 ? 'active' : ''}`} onClick={()=>handleSubmit()}>Submit</button>
                        </ContactPopupFooter>
                    </>
                }

            </ContactPopupCard>
        </ContactPopupWrapper>}
    </>
    )
}