/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * AssignSectionPopup ===> component for assign user
  * MLWalkthroughQuesView ===> walkthrough component
  * LossSupportView ===> support component

**/

import React, { useEffect, useState } from "react";
import { Link,Redirect } from 'react-router-dom';
import $, { isEmptyObject } from 'jquery';
import serviceProvider from '../../services/axios'
import { Helmet } from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { AssignSectionPopup } from "../loss-analysis-admin-page/loss-assing-popup";
import "./maintenance-losses-page.scss";
import "./maintenance-losses-circle.scss";
import { MLWalkthroughQuesView } from './maintenance-losses-walkthrough'
// loss support component start
import { LossSupportView } from '../la-support/la-support-view';
// end
import { InviteUserList } from '../invite-user-list-component/InviteUserList'
import {ThreeDotsComponent} from '../three-dots-component/ThreeDotsComponent'
// How to use popup component
import { HowToUseCtrl } from '../lossanalysis-how-to-use-popup/HowToUseCtrl'

//MaintenanceLossesPagePlantView component start
export const MaintenanceLossesPagePlantView = (props) => {
  //defining variables here
  const quesDatas = props.quesResult;
  if (!quesDatas.length) {
    return null;
  }
  const quesData = props.quesResult[0];
  const pid = quesData.pid;
  const [isAddCol, setIsAddCol] = useState(false);
  if (quesData.userans != [] && quesData.userans != "[]") {
    var useranswer = quesData.userans;
  }
  const answer = quesData.ans;
  const newques = quesData.questions;
  const islock = quesData.isLocks;
  const APDList1 = newques.slice(2);
  const APDList2 = quesData.userquestions;
  const [APDList, setAPDList] = useState(APDList1)
  const progress = quesData.Secprogress;
  const [ans, setans] = useState([]);
  let [tmArr, settmArr] = useState([]);
  let [totalval, setTotalVal] = useState(0);
  const [allqueobj, setAllqueobj] = useState({});
  const [isSubmitPopup, setIsSubmitPopup] = useState(false);
  const [activeError, setActiveError] = useState(false);
  const [hashvalue, setHashvalue] = useState(window.location.hash);
  // scnv-2126 start
  const [isUnlockPopup, setIsUnlockPopup] = useState(false);
  // scnv-2126 end
  // scnv-2157 start
  const [isLossSupport, setIsLossSupport] = useState(false);
  const [isTicket, setIsTicket] = useState(true);
  // scnv-2157 end
  const segments = window.location.pathname.slice(1).split("/");
  const pageslug = segments[1]
  const slug = segments[3] ? segments[3] : '';
  const sid = segments[4] ? segments[4] : 0;
  const ssid = segments[5] ? segments[5] : 0;
  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
  const [tmpAssignUser, setTmpAssignUser] = useState("");
  const [assignedUsers, setAssignedUsers] = useState({
        inviteData: [],
    });
  const [cate, setCate] = useState();
  const [secname, setSecName] = useState();
  const invitedmeblist = quesData.sectioninvitedmeber;
  //variable for walkthrough
  const [activeSlide, setActiveSlide] = useState(1);
  // const [results, setResults] = useState([]);
  const groupid = (localStorage.getItem("group_id"));
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const newpslug = pageslug.replace(/-/g, ' ').replace(/_/g, ' ');
  const str = newpslug.split(" ");
  const productionLine1 = quesData.bcaselines;
  // const nstr1 = str[0].charAt(0).toUpperCase() + str[0].slice(1);
  // const nstr2 = str[1].charAt(0).toUpperCase() + str[1].slice(1);
  const [sideSlide, setSideSlide] = useState(false);
  const [isRightPopup, setIsRightPopup] = useState(0);
  const [isQuesType, setIsQuesType] = useState(1);
  const [isRemovePopup, setIsRemovePopup] = useState(false);
  const [delItem, setDelItem] = useState(null);
  const [sideSlideIcon, setSideSlideIcon] = useState("fade info");
  const [rightdata, setRightData] = useState({});
  const [messge, setMessge] = useState('');
  // Popup Functions variables start
  // var to show or hide delete popup 
  const [delPop, setDelPop] = useState(false);
  //variable for edit comment
  const [isEditComment, setIsEditComment] = useState(null);
  //variable for store temproary data
  const [tmpCmntData, setTmpCmntData] = useState({});
  //variable for submit temproary data
  const [txtCmntInput, setTxtCmntInput] = useState('');
  // functions and variables for comment start
  const [isCommentCicked, setIsCommentCicked] = useState(false);
  const [sendquestion, setSendquestion] = useState(1);
  /*ThreeDotsComponent varibale*/  
  const [isThreeDotmenu,setIsThreeDotmenu]=useState(false);
  const [isAvailablePopup, setIsAvailablePopup] = useState(false);
  const [isNotAvailablePopup, setIsNotAvailablePopup] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  /*ThreeDotsComponent varibale end*/ 
  
  const [comment, setComment] = useState([
    // {
    //   id: 1,
    //   uid: 'c1',
    //   cirName: 'ci',
    //   comment: 'lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem',
    //   editedDate: '09/11/21',
    //   editedTime: '10:00 am',
    //   commentedDate: '10/11/21',
    //   commentedTime: '12:00 am',
    //   isEdited: 0,

    // },
    // {
    //   id: 2,
    //   uid: 'c2',
    //   cirName: 'sc',
    //   comment: 'lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem',
    //   editedDate: '08/11/21',
    //   editedTime: '11:00 am',
    //   commentedDate: '09/11/21',
    //   commentedTime: '03:00 am',
    //   isEdited: 0,
    // },
    // {
    //   id: 3,
    //   uid: 9,
    //   cirName: 'Bs',
    //   comment: 'lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem',
    //   editedDate: '10/11/21',
    //   editedTime: '11:00 am',
    //   commentedDate: '11/11/21',
    //   commentedTime: '02:00 am',
    //   isEdited: 0,
    // },
  ])

  /*---------- ThreeDotsComponent function start -------*/
          // function to freez and unfreez data
          const freezData=(selector,action)=>{
                let tartgetSelector=document.querySelectorAll(selector);

                if(tartgetSelector){
                  tartgetSelector.forEach((item)=>{
                    switch(action){
                      case 'add': item?.classList.add('freeze-input');
                                  console.log('add');
                      break;
                      case 'remove':item?.classList.remove('freeze-input');
                                    console.log('remove');
                      break;
                      default:''; 
                    }
                  })
                }

          }

          // function to show and hide menu-data-available popup and menu-data-not-available popup  
          const handleAvailabilityPopups = (val) => {
              setIsThreeDotmenu(false);
              if (val == false) {
                  //if clicked Data Available button
                  setIsAvailablePopup(true);
              } else if (val == true) {
                  //if clicked Data Not Available
                  setIsNotAvailablePopup(true);
              }
          }
          // function to reset data-available popup
          const resetAvailablePopup = () => {
                setIsAvailablePopup(false);
          }
          // function to reset data-not-available popup
          const resetNotAvailablePopup = () => {
                setIsNotAvailablePopup(false);
          }
        // function to make data  not available 
        const setIsAvailableFalse = () => {
            setIsAvailable(false);
            freezData(['.what_percentage','.notice .percentage'],'remove');
            resetAvailablePopup();
			setTotalVal(0);
			//send on server api start
			serviceProvider.post("/businesscaseplantloss?type=dataavailble",JSON.stringify({uid: usid, pid: quesData.pid, sid: sid, ssid: ssid,lines_na:0,all_lines:0}),true).then((response) => {
						// props.setRefadd(true);
			});
			//send on server api end
        }
        // function to make data available 
        const setIsAvailableTrue = () => {
            setIsAvailable(true);
            freezData(['.what_percentage','.notice .percentage'],'add');
            resetNotAvailablePopup();
			$('.ques-num').val(''); 
			setTotalVal(0);
			//send on server api start
			serviceProvider.post("/businesscaseplantloss?type=datanotavailble",JSON.stringify({uid: usid, pid: quesData.pid, sid: sid, ssid: ssid,lines_na:0,all_lines:0}),true).then((response) => {
					// props.setRefadd(true);
					 
					
			});
		//send on server api end
        }
  /*------------- ThreeDotsComponent ended----------*/ 
  // Popup Functions variables end
  const addStep2 = () => {
    let val = sideSlideIcon;
    if (val == 'slide-icon-change info')
      setSideSlideIcon(' none info')
    else {
      setSideSlideIcon('slide-icon-change info')
    }
  }
  // map for invited member start
  let s1 = [];
  let s1name = [];
  let s1AavatarColor = [];
  invitedmeblist.map((item, index) => {
    if (item.invited_category == quesData.section.id) {
      if(item.name != null){
            	s1.push(`${item.name[0]}${item.last_name[0]}`);
           	 	s1name.push(`${item.name} ${item.last_name}`);
			}else{
				s1.push(`${item.email[0]}`);
           	 	s1name.push(`${item.email}`);
				}
			 s1AavatarColor.push(item.avcolor);
    }
    // arrListofMember.push(item.invited_category);
  })
  // console.log(arrListofMember);

  // map for invited member end

  //Function to handle edit
  const edithandle = (e) => {
    setIsAssignSectionPopup(true);
    setCate(sid);
    setSecName(props.pname);
  }
  //Function to add new subcategory
  const AddNewsubCategory = (e) => {

    var question = document.getElementById('AddNewsubCategory').value;
    //alert(question);
    if (question != '') {
      serviceProvider
        .post(
          "/businesscaseplantloss?type=savequestion",
          JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, savequestion: question, qlayout: 1, qtype: 2, qtab: 0, allqueobj }),
          true
        )
        .then((response) => {

          props.setRefadd(true);
          // location.reload();

        });

    }
  }

  //update user question
  const updateUserQue = (newques, newqid) => {

    // if (question != '') {
    serviceProvider
      .post(
        "/businesscaseplantloss?type=edituserquestion",
        JSON.stringify({ slug: slug, nqid: newqid, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, savequestion: newques, qlayout: 1, qtype: 2, qtab: 0, allqueobj }),
        true
      )
      .then((response) => {
        // console.log(response);
        // location.reload();

      });
    // }
  }
  // delet user question
  const deleteUserQue = (newqid) => {
    // console.log(deletqid);
    // if (question != '') {
    serviceProvider
      .post(
        "/businesscaseplantloss?type=deleteuserquestion",
        JSON.stringify({ slug: slug, nqid: newqid, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid }),
        true
      )
      .then((response) => {
        // console.log(response);
        // location.reload();

      });
    // }
  }

  const [shown, setShown] = useState(false);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.closest('.conected_to_popup')) {
        setShown(false);
      }
    });
  });

  // real time Sum function


  const onKeyVal = (e) => {
    var ch = String.fromCharCode(e.which);
    if (!(/[0-9]/.test(ch))) {
      e.preventDefault();

    }
  }

  const sumofPercen1 = (Arr1) => {
    let neArray = Object.values(Arr1);
    let valAc = answer.length;
    let sum = 0;
    for (let j = 2; j < valAc; j++) {

      if (neArray[j] != "") {

        let nnew1 = neArray[j];
        let nnew = nnew1[0].slice(0, -1);
        if (isNaN(parseInt(nnew))) {
          nnew = 0;
        }
        sum = sum + parseInt(nnew);
      }

    }
    let checktotalforsub = 0;
    productionLine1.map((item, index) => {
      checktotalforsub = checktotalforsub + item.p;
    })
    checktotalforsub = (checktotalforsub);
    sum = parseInt(checktotalforsub);
    setTotalVal(sum);
	
	
	
  }
  const sumofPercen2 = () => {
    let sum = 0;
    let checktotalforsub = 0;
    productionLine1.map((item, index) => {
      checktotalforsub = checktotalforsub + item.p;
    })
    checktotalforsub = (checktotalforsub);
    sum = parseInt(checktotalforsub);
    setTotalVal(sum);
  }
  //Function to change value
  const changeval = (e) => {

    let val = e.target.value;

    let val1 = val.split('%');
    console.log(val1);

    if (val1[0]) {
      e.target.value = val1[0];
    }

  }
  //Function to plus submit Answer
  const submitAnsplus = (e, val11) => {
    e.preventDefault();
    if (e.type == 'blur') {
      serviceProvider
        .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}`, true, {})
        .then((res) => {
          console.log(res);
        })

      let val1 = parseInt(e.target.value);
      let val;

      if (val1 || val1 == 0) {
        val = $(e.target).val(val1 + '%');
        

      }
      else if (isNaN(val1)) {
        // console.log(val1);
        val = $(e.target).val();
       
      }
      // console.log(val);
      submitAns(e, val11);
    }
  }
  //Function to submit answer
  const submitAns = (e, sigid) => {
    e.preventDefault();
    serviceProvider
      .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}`, true, {})
      .then((res) => {
        console.log(res);
      })
    let val = e.target.value;
    let tempObj = {};
    let temArray1 = [];

    newques.map((item, index) => {

      let name = `ques` + item.id;

      const f1 = document.getElementById("mainloss");

      const data = new FormData(f1).getAll(name);

      tempObj = { ...tempObj, [name]: data }

    })

    setAllqueobj(tempObj);
    if (sigid) {
      if (!isEmptyObject(tempObj)) {
        sumofPercen1(tempObj);
      }

    }


  }
  // When the user scroll on div, open the shadow

  $('.maintenance-losses-mid-wrapper').scroll(

    () => {

      var scroll = $('.maintenance-losses-mid-wrapper').scrollTop();

      if (scroll > 0) {

        $(".outside_card").addClass("active");

      }

      else {

        $(".outside_card").removeClass("active");

      }

    }

  );
  // scnv-1292
  const [tmpEditData, setTmpEditData] = useState('');
  const [isEditdata, setIsEditdata] = useState(false);
  //function to close all dropdown list
  const closdeAllDdl = () => {
    let dllList = document.querySelectorAll('.del-btn.three-dot-btn');
    dllList.forEach((item, index) => {
      item.classList.remove('active');
    });
  }
  //Function to edit all dropdown list
  const handleEditDdl = (e) => {
    closdeAllDdl();
    if (e.currentTarget.parentElement.classList.contains('active')) {
      e.currentTarget.parentElement.classList.remove('active');
    } else {
      e.currentTarget.parentElement.classList.add('active');
    }
  }
  //Function to edit data
  const handleEditdata = (data, index) => {

    setIsEditdata(index);
    if (isEditdata > -1) {
      closdeAllDdl();
      let inputAdd = document.querySelector(`#editListInput${index}`);
      //  inputAdd.focus();
      inputAdd.value = data.question;
      setTmpEditData({
        data,
        index
      });
    }
  }
  //scnv-1292 end


  //scnv-1292
  // const scrollBottomAfterAdd = () => {
  //     // const divElement = document.querySelector('.la-ques-wrapper .la-ques-bottom-wrapper');
  //     setTimeout(() => {
  //         // divElement.scrollTop = divElement.scrollHeight;
  //     }, 300);
  //     // let e = document.querySelector('#AddNewsubCategory');
  //     // e.focus();
  // };

  //function to show/hide error
  const tChange = (e) => {
    if (e.target.value != '') {
      setActiveError(false);
    } else {
      setActiveError(true);
    }
  }
  //Function to add list
  const AddLsit = () => {

    let e = document.querySelector('#AddNewsubCategory');
    if (e.value.length > 0) {


      AddNewsubCategory(); // for add userquestion save

      e.value = '';
      scrollBottomAfterAdd();
      setIsAddCol(false);
      setActiveError(false);
    } else {
      setActiveError(true);
    }

  }
  //Function to edit list
  const EditList = () => {
    // console.log(tmpEditData);
    let e = document.querySelector(`#editListInput${tmpEditData.index}`);
    if (e.value.length > 0) {
      let name = e.value;
      let tmpArr = [...APDList];
      tmpArr[tmpEditData.index] = {
        ...tmpArr[tmpEditData.index],
        question: name
      };
      // setAPDList(tmpArr);
      updateUserQue(name, tmpEditData.data.id);
      e.value = '';
      setTmpEditData('');
      setIsEditdata(false)
      props.setRefadd(true);
    }
  }
  //scnv-1292 end
  //function to remove list
  const RemoveList = () => {
    let index = delItem.index;

    //let tmpArr = APDList.filter((item, i) => i !== index);

    // setAPDList(tmpArr);
    deleteUserQue(delItem.qid);
    props.setRefadd(true);
  }
  const commentmess = (e) => {
    setMessge(e.target.value);
  }
  //Function for sub comment
  const commentsub = (e) => {

    serviceProvider
      .post(
        "/businesscaseplantloss?type=savecomment",
        JSON.stringify({ uid: usid, pid: quesData.pid, qid: rightdata?.qid, message: messge }),
        true
      )
      .then((response) => {
        console.log(response);

      });
    setMessge('');
    // messge='';
    // location.reload();
  }
  //function for final submit
  const Finalsubmit = (e) => {

    serviceProvider
      .post(
        "/businesscaseplantloss?type=lockanswer",
        JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid }),
        true
      )
      .then((response) => {
        location.reload();

      });

  }
  //Function to handle right section
  const HandleRightSec = async (e, val, type, item = 0) => {
    e.preventDefault();
    setComment([]);
    const apiUrl = `/businesscaseplantloss?param=commentshow&qid=${item?.id}&uid=${quesData.uid}&pid=${quesData.pid}`;
    const { data } = await serviceProvider.get(apiUrl, true, {});
    // console.log(data[0]);
    // const data1 = data[0].comentdata;
    if (!isEmptyObject(data)) {
      let tempDataArr = []

      data.forEach((item) => {
        let fullDateCreate = new Date(item.datecreated);
        let fullDateEdit = new Date(item.dateupdated);
        let tmpObjArrD = {
          id: item.id,
          uid: item.user_id,
          cirName: item.usersn,
          comment: item.comment,
          editedDate: `${fullDateEdit.getDate()}/${fullDateEdit.getMonth()}/${fullDateEdit.getFullYear().toString().substr(-2)}`,
          editedTime: fullDateEdit.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
          commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth()}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
          commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
          isEdited: item.is_edited,
        }
        tempDataArr.push(tmpObjArrD);
      })

      setComment(tempDataArr);
    }
    setIsQuesType(type);
    setSendquestion(item?.question);
    if (isRightPopup === val) {
      setIsRightPopup(null);
    } else {
      setRightData({ name: item?.question, des: item?.question_description, qid: item?.id })
      setIsRightPopup(val);
      setIsLossSupport(false);
    }
  }
  //Function to close right section
  const HandleRightSecClose = (e, val) => {
    e.preventDefault();
    setIsRightPopup(null);
    setIsLossSupport(false);
  }
  //Function for delete button
  const HandleDelBtnEvent = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setDelItem(item);
    setIsRemovePopup(true);
    //scnv-1292
    closdeAllDdl();
    //scnv-1292 end
  }

  //Function to show right and left column on equal height
  const fixColumn = () => {
    let parentLeft = document.querySelector('.la-bottom-left-col');
    let parentRight = document.querySelector('.la-bottom-right-col');
    let leftColumn = document.querySelectorAll('.la-bottom-left-col .la-common-left-col');
    let rightColumn = document.querySelectorAll('.la-bottom-right-col .la-right-input-row');
    leftColumn.forEach((item, index) => {
      let itemHeight = item.offsetHeight;
      if (rightColumn[index]) {
        rightColumn[index].style.minHeight = itemHeight + "px";
      }
    });
    let fixedLeftColumn = document.querySelectorAll('.la-bottom-left-col .fixed-row-column.left');
    let fixedRightColumn = document.querySelectorAll('.la-bottom-right-col .fixed-row-column.rgt');
    fixedRightColumn.forEach((item, index) => {
      let itemHeight = item.offsetHeight;
      fixedLeftColumn[index].style.minHeight = itemHeight + "px";
      parentLeft.style.paddingTop = itemHeight + "px";
      parentRight.style.paddingTop = itemHeight + "px";
    });
  }
  //Scroll page to bottom when add new subcategory
  const scrollBottomAfterAdd = () => {
    const divElement = document.querySelector('.la-ques-wrapper .la-ques-bottom-wrapper');
    let scrollDiv = document.querySelector('.maintenance-losses-mid-wrapper');
    document.querySelector('#AddNewsubCategory').focus();
    if (scrollDiv) {
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    }
    // scrollDiv.scrollTop = scrollDiv.scrollHeight;
    // console.log(scrollDiv.scrollHeight);
    // setTimeout(() => {
    //     divElement.scrollTop = divElement.scrollHeight;
    // }, 300);
  };

  useEffect(() => {
    // Get the modal
    var modal = document.getElementById("myModal");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    // When the user scroll on div, open the shadow

    $('.maintenance-losses-mid-wrapper').scroll(

      () => {

        var scroll = $('.maintenance-losses-mid-wrapper').scrollTop();

        if (scroll > 0) {

          $(".maintenance-losses-top-two").addClass("active");

        }

        else {

          $(".maintenance-losses-top-two").removeClass("active");

        }

      }

    );
    

    // map and callculation for db
    let tempval = [];
    answer.map((item, index) => {
      if (item.length != 0) {
        if (item.answer[0] != "") {
          tempval.push(JSON.parse(item.answer));
        }
      }
    })
    if (APDList2.length > 0) {
      for (var i = 0; i < APDList2.length; i++) {
        tempval.push('');
      }
    }



    if (tempval.length > 0) {
      setans(tempval);
      //map for answer value end
      //counter start
      tempval.map((item1, index1) => {
        if (index1 > 1) {
          var vv = tempval.slice(2);
          let tmArr1 = [];
          vv.map((item1, index) => {
            if (item1 !== "") {
              if (isNaN(parseInt(item1))) {
                tmArr1.push(0)
              } else {
                tmArr1.push(parseInt(item1))
              }
            }
          })
          var total = tmArr1.reduce((a, b = 0) => { return a + b }, 0);
          let checktotalforsub = 0;
          productionLine1.map((item, index) => {
            checktotalforsub = checktotalforsub + item.p;
          })
		 
          checktotalforsub = (checktotalforsub);
          total = parseInt(checktotalforsub);
			if(quesData.is_naavaliable){
					setTotalVal(0);
			}else{
			  setTotalVal(total);
			}
          settmArr(tmArr1);
        }

      });

    }

	serviceProvider
                .post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:totalval }),
                    true
                )
                .then((response) => {
                });
  }, [props.refadd, APDList2, APDList])

  useEffect(() => {

    if (isEditdata > -1) {
      let inputAdd = document.querySelector(`#editListInput${isEditdata}`);
      inputAdd?.focus();
    }
  }, [isEditdata])
  // by variable
  useEffect(() => {
    var userques = {};

    var valP = "";
    //$(".alluques").each(function () {
    APDList2.map((item, index) => {
      var nm = [];
      valP = $("#ques" + item.id).val();
      valP = valP.replace("%", "")
      // alert(valP);
      if (valP != '') {
        nm.push(valP);
      } else {
        nm.push("");
      }

      userques["ques" + item.id] = nm;
    });
    fixColumn();
    if (!isEmptyObject(allqueobj)) {
      serviceProvider
        .post(
          "/businesscaseplantloss?type=saveuseranswer",
          JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, userques: userques, allqueobj }),
          true
        )
        .then((response) => {
			
          props.setRefadd(true);


        });
      serviceProvider
        .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}`.toUpperCase(), true, {})
        .then((res) => {

        })

    }

    //scnv-1292
    document.onclick = (e) => {
      let valdiv = document.querySelector('#AddNewsubCategory');

      if (valdiv) {
        document.querySelector('#AddNewsubCategory').focus();
      }

      if (!e.target.closest('.add_new') && !e.target.closest('.del-btn') && !e.target.closest('#AddNewsubCategory')) {
        setIsAddCol(false);
        if (e.pointerId > -1) {
          setIsEditdata(false);
        }
        setActiveError(false);
        if (valdiv) {
          valdiv.value = '';
        }
        // valdiv.value='';
      }
      if (!e.target.closest('.three-dot-btn')) {
        closdeAllDdl();
      }
    }
    //scnv-1292 end

  }, [allqueobj])

 
  //popup changes start
  //function to handle note popup
  const HandleNotePop = () => {
    let temp = document.querySelector('.la-card-wrapper');
    if (temp.classList.contains('active')) {
      temp.classList.remove('active');
    }
    else {
      temp.classList.add('active');
    }
  }
  //function to remove note popup
  const removeNotePop = () => {
    let tempr = document.querySelector('.la-card-wrapper');
    tempr.classList.remove('active');
    if (window.innerWidth < 1201){
      tempr.classList.add('active');
    }
  }
  // Popup Functionality Start 10-11-2021
  //popup show
  const ShowDelPopup = (data, ind) => {
    setTmpCmntData({
      ...data, index: ind
    })
    setDelPop(!delPop);
  }
  //delete functionality start 
  const deletData = () => {
    const tmpArr = comment.filter((item, index) => item.id !== tmpCmntData.id);
    serviceProvider
      .post(
        "/businesscaseplantloss?type=deletecomment",
        JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, qid: rightdata?.qid }),
        true
      )
      .then((response) => {
        console.log(response);

      });

    setComment(tmpArr);
    setDelPop(false);
    setTmpCmntData({});
  }

  //delete functionality end
  //edit functionality start
  const editComntData = (data, ind) => {
    setTmpCmntData({
      ...data, index: ind
    });
    let tmpTextArea = document.getElementById(`comment-editTxtarea-${data.id}`);
    tmpTextArea.value = data.comment;
    setIsEditComment(data.id);

  }
  //edit functionaliy end
  //Handle Comment textarea on change
  const handleTextAreaOnChange = (e) => {
    let val = e.target.value;
    setTxtCmntInput(val);
  }
  // Save submit data
  const saveCmntData = () => {

    let tmpTextArea = document.getElementById('addComment');
    let tmpCmntArr = [...comment];
    let fullDateCreate = new Date();
    // console.log(fullDateCreate.getMonth()+1);
    let tmpObj = {
      id: comment.length + 1,
      uid: usid,
      cirName: user.sn,
      comment: txtCmntInput,
      editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
      editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
      commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      isEdited: 0

    }
    serviceProvider
      .post(
        "/businesscaseplantloss?type=savecomment",
        JSON.stringify({ uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: txtCmntInput }),
        true
      )
      .then((response) => {
        console.log(response);

      });
    tmpCmntArr.unshift(tmpObj);
    setComment(tmpCmntArr);
    setTxtCmntInput('');
    tmpTextArea.style.height = 30 + 'px';
    setIsCommentCicked(false);
  }
  // Save Edit Data Functionality
  const saveEditData = (index) => {
    let tmpcmntArr = [...comment];
    let tmpTextArea = document.getElementById(`comment-editTxtarea-${tmpCmntData.id}`);
    let fullDateCreate = new Date();
    let tmpObj = {
      ...tmpCmntData,
      comment: tmpTextArea.value,
      editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
      editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      isEdited: 1
    };
    serviceProvider
      .post(
        "/businesscaseplantloss?type=saveeditcomment",
        JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: tmpTextArea.value }),
        true
      )
      .then((response) => {
        console.log(response);

      });

    tmpcmntArr[index] = tmpObj;
    setComment(tmpcmntArr);
    setIsEditComment('');
    // console.log(tmpcmntArr);
  }
  // Save Edit Data Functionality End
  // Popup Functionality End 10-11-2021
  useEffect(() => {
    let resizeTextArea = document.querySelectorAll('.resize-cmnt-area');
    resizeTextArea.forEach((item) => {
      item.addEventListener('input', () => {
        item.style.height = 'auto';
        let itemHeight = item.scrollHeight;
        if (itemHeight > 30) {
          item.style.height = itemHeight + 'px';
        }
        if (itemHeight > 136) {
          item.classList.remove('txtScrollHide');
        }
        else {
          item.classList.add('txtScrollHide');
        }

      })
    });

    return async () => {
      try {
        if (Array.isArray(props.quesResult) && props.quesResult.length) {

          const { uid, pid: plant_id, has_sub_section, subsection, section } = props.quesResult[0];
          let data = { uid, section_id: section['id'], type: 2, status: 0, plant_id }

          if (section['has_sub_section'] === "1") {
            data['sub_section_id'] = subsection['id'];
          }
          else {
            data['sub_section_id'] = 0
          }
          await serviceProvider.post('/sectionlock', data, true);
        }
      } catch (err) {
        console.error(err)
      }
    }

  }, [])

  React.useEffect(() => {
    if (hashvalue && hashvalue != null) {
      var abc = hashvalue.replace("#", "");
      var myArray = abc.split("-");
      $('body').find('#idfc' + myArray[0]).click();
      setIsLossSupport(false)
    }

   if(Array.isArray(invitedmeblist)){
      // setinvite-data
      let tmpArr={...assignedUsers};
       let tmpInviteData= invitedmeblist.filter((item) => item.invited_category == quesData.section.id);
       tmpArr['inviteData']=tmpInviteData;
       setAssignedUsers(tmpArr);
   }
  }, [])

  /*useEffect for threedot component*/ 
     React.useEffect(()=>{
        if(quesData.is_naavaliable){
            setIsAvailableTrue();
         }
     },[]);
	 
	 if(hashvalue && hashvalue != null && isAvailable == true) {
			return <Redirect to="/not-found" />;	
	}

  return (
    <>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{quesData.plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem to={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>IR4.0 Loss Analysis</BreadcrumbsItem>
      <BreadcrumbsItem to="#">{props.linepagename}</BreadcrumbsItem>
      {/* main wrapper has four sections
          maintenance-losses-top
          maintenance-losses-top-two
          maintenance-losses-bottom-group
      */}
      <section className="maintenance-losses prod-1">
        {/* this section has one row */}
        <div className="maintenance-losses-top">
          {/* row has been divided into three parts
              maintenance-losses-lft-col
              maintenance-losses-mid-col
              la-ques-rgt-col
          */}
          <div class="maintenance-losses-row">
            {/* this section has 
                back button
                page title
                category text
            */}
            <div className="maintenance-losses-lft-col">
              <h1 className="h1-btn">
                <span className="title">{quesData.title}</span>
                <div className="edit-pop prod-4" style={{ visibility: islock == 1 ? 'hidden' : '' }}>
                  <ThreeDotsComponent
                      open={isThreeDotmenu}
                      setOpen={setIsThreeDotmenu}
                      isAvailable={isAvailable}
                      setIsAvailable={setIsAvailable}
                      isAvailablePopup={isAvailablePopup}
                      setIsAvailablePopup={setIsAvailablePopup}
                      isNotAvailablePopup={isNotAvailablePopup}
                      setIsNotAvailablePopup={setIsNotAvailablePopup}
                      setIsAvailableFalse={setIsAvailableFalse}
                      setIsAvailableTrue={setIsAvailableTrue}
                      resetAvailablePopup={resetAvailablePopup}
                      resetNotAvailablePopup={resetNotAvailablePopup}
                      handleAvailabilityPopups={handleAvailabilityPopups}
                      plant_id={pid}
                      section_id={sid}
                      sub_section_id={0}
					  lockalldaata={quesData.lockall}
										 {...props}
                  />
                </div>
              </h1>
              <div className="bottom-title">Key Plant Functions</div>
              <div className="value_of_progress" style={{ display: "none" }}>
                <p>Progress:</p>
                <div className="bar-wrapper">
                  <span>{progress}%</span>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: progress }} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
                  </div>
                </div>
              </div>
            </div>
            {/* this section has assigned user list */}
            <div style={{/* display: groupid == 14 ? 'none' : '' */}} className="maintenance-losses-mid-col">
              <div className="Assign">
                    <InviteUserList
                        data={assignedUsers.inviteData}
                        setData={setAssignedUsers}
                        handlePopup={edithandle}
						            open={isAssignSectionPopup}
                        isEditAllow={groupid != 14 ? true : false}
                        catagory={sid}
                        subcatagory={quesData.subsection?.id}
                        pid={pid}
                        uid={quesData.uid}
                        sectionName={quesData.title}
                        plantName={quesData.plant_name}
						            {...props}
                    />
                <div style={{ visibility: 'hidden' }} className="direction_arrows">
                  <div className="upward">
                    <img src={require("../../assets/img/manufacturing-cost/upward.svg").default} alt="digital_maturity" />
                    <p>Upload Excel file</p>
                  </div>
                  <div className="downword">
                    <img src={require("../../assets/img/manufacturing-cost/downword.svg").default} alt="digital_maturity" />
                    <p>Download to Excel</p>
                  </div>
                </div>
              </div>
            </div>
            {/* this section has
                popup
                unlock button/ Data Submitted Button
            */}
            <div className="mainte-rgt-col" style={{ position: 'relative' }}>

              {/* <div className={`la-card-wrapper ${islock == 1 ? '' : 'active'}`}>
                <button className="image-wrap" style={{ display: `${islock == 1 ? 'none' : ''}` }} onClick={() => HandleNotePop()}> */}
              <div className={`la-card-wrapper active`}>
                <button className="image-wrap" onClick={() => HandleNotePop()}>

                  <img src={require('../../assets/img/maintenance_losses/bulb.svg').default} alt="Bulb" />
                </button>
                <div className="content-box">
                  <p className="content">{quesData.section.description}</p>
                  <button to="/" className="close-btn"><img src={require('../../assets/img/maintenance_losses/close.svg').default} alt="close" onClick={() => removeNotePop()} /></button>
                </div>
              </div>
              {islock == 0 && (
                <>
                  <div className="prod-6" style={{ display: !isAvailable?'':'none' }}><button disabled={islock > 0 ? true : false} className={`btn-link submit active ${islock > 0 ? 'disabled' : ''} ${totalval != 100 ? 'disabled' : ''}`} onClick={() => { if (totalval == 100) { setIsSubmitPopup(true) } }}>Submit Data</button></div>
                </>)
              }
              {(islock == 1) &&
                <>
                  
                  <div className='scnv2126-btn-wrapper'>
                    <div>
                      <div className="submitted-scnv-2126">
                        <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: dataSubmitsvg }}></span>
                        <span>Data Submitted</span>
                      </div>
                    </div>
                    <div style={{ display: (groupid == 2 || groupid == 11) && quesData.lockall == 0 ? '' : 'none' }}>
                      <button className="submitted-scnv-2126 btn-unclock" onClick={() => setIsUnlockPopup(true)}>
                        <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: lockSvg }}></span>
                        <span>Unlock Section</span>
                      </button>
                    </div>
                  </div>
                  {/* scnv-2126 end */}
                </>
              }
             
                            <div className="available-btn-wrapper" style={{ display: isAvailable ? '' : 'none' }}>
							
                                <button className="not-available-btn">
                                    <img src={require('../../assets/img/maintenance_losses/data-unavailable.svg').default} alt="Bulb" />
                                    <span>Data Not Available</span>
                                </button>
								{quesData.lockall == 0 && (<> 
                                <button className="available-btn" onClick={() => handleAvailabilityPopups(false)}>Data Available</button>
								</>)}
                            </div> 
            </div>
            {/* la-ques-right-col end */}
          </div>
        </div>
        {/* <div className="maintenance-losses-top-two">
          <div className="work_space"> */}

        {/* this section has two subsections
              outside_card work_space
              empty
          */}
        <div className="maintenance-losses-top-two">
          {/* wrapper has two parts 
              total_maintenance
              what_percentage
          */}
          <div className="outside_card work_space">
            {/* this section has
                text
                question
            */}
            <div className="total_maintenance ">
              <div className="text-area">{newques[0].question} <span> {quesData.fte ? Number(quesData.fte).toLocaleString('en-US') : 0}</span> FTEs</div>
              <div className="what_per text-area prod-1">{newques[1].question}</div>
            </div>
            {/* this section has percentage circle */}
            <div className="what_percentage prod-3">
              <div className="what_percentage_wrap">
                <div className="total"> Total </div>
                <SvgCircle value={totalval} />
              </div>
            </div>
          </div>
          <div className="empty" />
        </div>
        {/*  this section has form inside that section wrapper(inside_cards) */}
        <div className="maintenance-losses-mid-wrapper prod-2">
          <form id={"mainloss"}>
            {/* this section has 
                side-slid-icon-button
                percentage
            */}
            <div className="inside_cards">
              {!isEmptyObject(answer) && APDList.map((items, i) => {
                return (<div className="notice">
                  <button title={items.question} type='button' className={`side-slid-icon-button noneditable ${isRightPopup === (items.id) && 'active'}`} >
                    <div className="info" id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, 1, items)}>
                      <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                      </svg>
                    </div>
                    <div className="add-subcat-wrap">
                      <div className={`input-wrap`}><p className="text" title={items.question} onClick={(e) => HandleRightSec(e, items.id, 1, items)}>{items.question}</p></div>
                    </div>
                  </button>
                  <div className="percentage"><input type="text" disabled={islock > 0 ? true : false} name={`ques` + items.id} onFocus={(e) => { changeval(e) }} onBlur={(e) => { submitAnsplus(e, i + 2) }} onKeyPress={(e) => { onKeyVal(e) }} placeholder="%" className="ques-num percentage1" defaultValue={ans[i + 2] != "" ? ans[i + 2] : ""} maxLength="3" /></div>
                </div>)
              }
              )
              }

              {!isEmptyObject(useranswer) && APDList2.map((items, i) => {
                var ans11 = [];
                if (quesData.userans[i].answer != "[]" && typeof quesData.userans[i].answer != 'undefined') {
                  ans11 = $.parseJSON(quesData.userans[i].answer);
                }
                return (<div className="notice">
                  <button title={items.question} type='button' className={`side-slid-icon-button ${isRightPopup === (items.id) && 'active'}`} >
                    <div className="info" id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, 2, items)}>
                      <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                      </svg>
                    </div>


                    {isEditdata !== i &&
                      <div className="add-subcat-wrap">
                        <div className={`input-wrap ${!items.is_editable && 'w-100'}`}><p className="text" title={items.question}>{items.question}</p></div>
                        {items.is_editable &&
                          <div className="del-btn three-dot-btn" onClick={(e) => e.stopPropagation()}>
                            <button type='button' onClick={(e) => handleEditDdl(e)}>
                              <EditCirSVg />
                            </button>
                            <div className="ed-del-list">
                              <button type='button' className="inner-edit-btn"
                                onClick={() => handleEditdata(items, i)}>Edit</button>
                              <button type='button'
                                className="inner-del-btn"
                                onClick={(e) => {
                                  HandleDelBtnEvent(e, {
                                    name: items.question,
                                    index: i,
                                    qid: items.id
                                  });
                                }}>Delete</button>
                            </div>
                          </div>}
                      </div>}
                    <div className="add-subcat-wrap edit" style={{ display: isEditdata === i ? 'flex' : 'none' }} onClick={(e) => e.stopPropagation()}>
                      <div className={`input-wrap`} >
                        <input placeholder="Other - please specify" name="sub-cat" id={`editListInput${i}`} className="input" /></div>
                      <button type='button' className="del-btn edit-btn"
                        onClick={() => EditList()}>
                        <AddSvg />
                      </button>
                    </div>


                  </button>
                  <div className="percentage"><input type="text" disabled={islock > 0 ? true : false} name={`ques` + items.id} id={`ques` + items.id} onFocus={(e) => { changeval(e) }} onBlur={(e) => { submitAnsplus(e, i + 2) }} onKeyPress={(e) => { onKeyVal(e) }} placeholder="%" className="ques-num percentage1" defaultValue={ans11 != "[]" && typeof ans11[0] != 'undefined' && ans11[0] != '' ? ans11[0] + "%" : ""} maxLength="3" /></div>
                </div>)
              }
              )
              }
              {/*  add subcategory div */}
              <div className="la-common-left-col" style={{ visibility: isAddCol ? '' : 'hidden' }}>
                <div className="side-subcategory">
                  <div className="image-box">
                    <img src={require('../../assets/img/la-questionnaires/notification-icon.svg').default} alt="notification" />
                  </div>
                  <div className="add-subcat-wrap">
                    <div className="input-wrap">
                      <input placeholder="Other - please specify" name="sub-cat" id="AddNewsubCategory" onChange={(e) => { tChange(e) }}  /*onKeyDown={(e) => AddLsit(e)}*/ className="input" /></div>
                    {/* <button className="del-btn" onClick={() => setIsAddCol(false)}>
                      <img src={require('../../assets/img/la-questionnaires/delete-icon.svg').default} alt="Delete" />
                    </button> */}
                    <button className="del-btn add-btn" type='button' onClick={() =>
                      AddLsit()}>
                      <AddSvg />
                    </button>
                    <div className="provide-data" style={{ visibility: activeError ? '' : 'hidden' }}>Please provide data</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* this section has submit button */}
        <div className="maintenance-losses-bottom-group">
          {islock == 0 && (<>
            <div className="bottom_fade" />

            <div className="add_new"><a onClick={() => { if (islock == 0) { setIsAddCol(true) }; scrollBottomAfterAdd(); }}> + Add new subcategory </a></div>
            {/* { groupid==140000?<button disabled={true} className={`btn-link submit active disabled`} onClick={() => { if (totalval == 100) { setIsSubmitPopup(true) } }}>Submit</button>: */}
            <div className="prod-6"><button disabled={islock > 0 ? true : false} className={`btn-link submit active ${islock > 0 ? 'disabled' : ''} ${totalval != 100 ? 'disabled' : ''}`} onClick={() => { if (totalval == 100) { setIsSubmitPopup(true) } }}>Submit</button></div>
            {/* } */}
          </>)
          }
        </div>
        {/* submit popup starts here*/}
        <div className={`submit-pop-wrap ${isSubmitPopup && 'active'}`}>
          {/* wrapper has two parts
              close-btn-wrap
              popup
          */}
          <div className="popup-wrapper submit-popup">
            <div className="close-btn-wrap">
              <button className="close-btn" onClick={() => { setIsSubmitPopup(false) }}>
                <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
              </button>
            </div>
            {/* popup has three parts
                img-box
                content-box
                button-wrapper
            */}
            <div className="popup">
              <div className="img-box">
                <img src={require("../../assets/img/la-questionnaires/unsaved.svg").default} alt="Unsaved" className="unsave-img" />
              </div>
              <div className="content-box">
                <p className="text">By submitting the data you will no longer have the opportunity for further editing. </p>
                <p className="text">Are you sure you want to proceed?</p>
              </div>
              <div className="button-wrapper">
                <button type="submit" className="btn cancel-button primary" onClick={() => { setIsSubmitPopup(false) }}>Cancel</button>
                <button onClick={(e) => Finalsubmit(e)} type="submit" className="btn submit-btn secondary">Submit</button>
              </div>
            </div>
          </div>
        </div>


        {/* Delete popup starts here */}
        <div className={`delete-pop-wrap ${isRemovePopup && 'active'}`}>
          <div className="popup-wrapper del-popup">
            <div className="close-btn-wrap">
              <button className="close-btn" onClick={() => { setIsRemovePopup(false) }}>
                <img src={require("../../assets/img/operator-effort-loss/close-pop.svg").default} alt="Close" className="close-img" />
              </button>
            </div>
            <div className="popup">
              <div className="img-box">
                <img src={require("../../assets/img/operator-effort-loss/pop-del-icon.svg").default} alt="Delete" className="del-img" />
              </div>
              <div className="content-box">
                <p className="subcat-title text">{delItem?.name}</p>
                <p className="text">Are you sure you want to remove this subcategory?</p>
              </div>
              <div className="button-wrapper">
                <button type="submit" className="btn keep-button primary" onClick={() => { setIsRemovePopup(false) }}>No, keep it</button>
                <button type="submit" className="btn remove-btn secondary" onClick={() => { RemoveList(); setIsRemovePopup(false); setIsRightPopup(false); }}>Yes, remove</button>
              </div>
            </div>
          </div>
        </div>

        {/* Right Sidebar Popup starts here */}
        <div className={`right-sidebar ${isRightPopup && 'active'}`}>
          {/* support component call here */}
          {isRightPopup != 0 && (<LossSupportView
            popupHeadTxt={rightdata.name}
            des={rightdata.des}
            isLossSupport={isLossSupport}
            HandleRightSecClose={HandleRightSecClose}
            close={setIsLossSupport}
            isTicket={isTicket}
            setIsTicket={setIsTicket}
            pid={pid}
            sid={sid}
            ssid={0}
            qid={isRightPopup}
            uid={quesData.uid}
            type={isQuesType}
            question={sendquestion}
            islock={islock}
			isAvailable={isAvailable}
            {...props}
          />)}
        </div>
      </section>

      {/* Assign section popup component */}
      <AssignSectionPopup
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        catagory={cate}
        subcatagory={0}
        pid={pid}
        sectionName={quesData.title}
        plantName={quesData.plant_name}
        tmpAssignUser={'inviteData'}
        open={isAssignSectionPopup}
        setOpen={setIsAssignSectionPopup}
        {...props}
      />
      {/* Maintenamce losses walkthrough component */}
      {activeSlide && ((sid == 107 && quesData.kpftour != 1) || (sid == 106 && quesData.kpftour1 != 1) || (sid == 102 && quesData.kpftour2 != 1) || (sid == 104 && quesData.kpftour3 != 1) || (sid == 105 && quesData.kpftour4 != 1)) &&
        <MLWalkthroughQuesView activeSlide={activeSlide} sid={sid} setActiveSlide={setActiveSlide}  {...props} />
      }
      {/* confirmation unlock popup component */}
      <ConfirmationUnlockPopup isOpen={isUnlockPopup} setOpen={setIsUnlockPopup} setUnlockbutton={props.setUnlockbutton} pid={pid} sid={sid} ssid={0} />
      <div className="how-to-use-ctrl-wrap">
        <HowToUseCtrl sectionName={props.linepagename}/>
      </div>
    </>
  )
}
// svgcircle component which is used for progress circle
const SvgCircle = (props) => {
  const { value } = props;

  return (
    <>
      <svg viewBox="0 0 36 36" style={{ opacity: '1' }}>
        <text
          className="percent__text"
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="10"
        >
          {value}
          <tspan fontSize="5">%</tspan>
        </text>
        <path
          className="circle-bg"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke="#E6E6E6"
          strokeWidth="3.5"
          fill="none"
        ></path>
        <path
          className="circle"
          strokeDasharray={`${value}, 100`}
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={
            value < 100 && value > 0
              ? "#B0B0B0"
              : value === 100
                ? "#40D30D"
                // : "transparent"
                : "#FF6666"
          }
          strokeWidth="3.5"
          fill="none"
        ></path>
      </svg>
      {props.tooltipText && <div className="svg-tooltip">
        {props.tooltipText}
      </div>}
    </>
  );
};

//function to add svg
const AddSvg = () => {
  return (
    <svg width="{22}" height="{22}" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9167 6.41634H10.0833V10.083H6.41668V11.9163H10.0833V15.583H11.9167V11.9163H15.5833V10.083H11.9167V6.41634ZM11 1.83301C5.94001 1.83301 1.83334 5.93967 1.83334 10.9997C1.83334 16.0597 5.94001 20.1663 11 20.1663C16.06 20.1663 20.1667 16.0597 20.1667 10.9997C20.1667 5.93967 16.06 1.83301 11 1.83301ZM11 18.333C6.95751 18.333 3.66668 15.0422 3.66668 10.9997C3.66668 6.95717 6.95751 3.66634 11 3.66634C15.0425 3.66634 18.3333 6.95717 18.3333 10.9997C18.3333 15.0422 15.0425 18.333 11 18.333Z" fill="#45B097" />
    </svg>
  )
}
//function to edit circle svg
const EditCirSVg = () => {
  return (
    <svg width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.5001" cy="5.36634" r="1.53333" fill="#7D868C" />
      <circle cx="11.4996" cy="11.5001" r="1.53333" fill="#7D868C" />
      <circle cx="11.4996" cy="17.6329" r="1.53333" fill="#7D868C" />
    </svg>
  )
}


// defining svg here
let dataSubmitsvg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11" cy="11" r="10.5" fill="#CAE4CB" stroke="#43A047"/>
<path d="M8.83863 14.0039L5.83524 11.0005L4.8125 12.0161L8.83863 16.0422L17.4815 7.39932L16.466 6.38379L8.83863 14.0039Z" fill="#43A047"/>
</svg>`

const lockSvg = `<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.7142 7.16667H12.7618V5.2619C12.7618 2.63333 10.6285 0.5 7.99991 0.5C5.37134 0.5 3.238 2.63333 3.238 5.2619V7.16667H2.28562C1.238 7.16667 0.380859 8.02381 0.380859 9.07143V18.5952C0.380859 19.6429 1.238 20.5 2.28562 20.5H13.7142C14.7618 20.5 15.619 19.6429 15.619 18.5952V9.07143C15.619 8.02381 14.7618 7.16667 13.7142 7.16667ZM7.99991 15.7381C6.95229 15.7381 6.09515 14.881 6.09515 13.8333C6.09515 12.7857 6.95229 11.9286 7.99991 11.9286C9.04753 11.9286 9.90467 12.7857 9.90467 13.8333C9.90467 14.881 9.04753 15.7381 7.99991 15.7381ZM10.9523 7.16667H5.04753V5.2619C5.04753 3.63333 6.37134 2.30952 7.99991 2.30952C9.62848 2.30952 10.9523 3.63333 10.9523 5.2619V7.16667Z" fill="#118ACB"/>
</svg>
`
//Function to confirm unlock
const ConfirmationUnlockPopup = (props) => {
  const finalUnlock = (id) => {
    props.setOpen(false);
    serviceProvider
      .post(
        "/businesscaseplantloss?type=unlocksection",
        JSON.stringify({ pid: props.pid, sid: props.sid, ssid: props.ssid }),
        true
      )
      .then((response) => {
        location.reload();
      });
  }
  return (
    <>
      {/* unlock popup wrapper */}
      <div className='unlock-popup-wrapper2126' style={{ visibility: props.isOpen ? '' : 'hidden' }}>
        {/* popup-content has two parts
          body-popup
          popup-btn-wrapper
      */}
        <div className='popup-content2126'>
          <div className='body-popup2126'>
            <p>Are you sure, you would like to unlock the section?</p>
          </div>
          <div className='popup-btn-wrapper2126'>
            <button className='p-2126-btn cancel' onClick={() => props.setOpen(false)}>Cancel</button>
            <button className='p-2126-btn yes' onClick={() => { finalUnlock(props.ssid) }}>Yes, unlock</button>
          </div>
        </div>
      </div>
    </>

  )
}
// scnv-2126