/** imported files

# number ===>from yup plugin  
# motion ===> for animate component
# CartView ===> custom component of Cart view
# BreadcrumbsItem ===> for dynamic breadcrumbs
# Helmet ===> for specific title 


**/

import React, { Component, useState, useEffect } from "react";
import { number } from "yup";
import { CartView } from "./cart-view";
import "../cart.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";

/*country list variable*/
const defaultCountryList = [
	{
		id: 1,
		code: 202,
		name: "Afghanistan",
	},
	{
		id: 2,
		code: 202,
		name: "Albania",
	},
	{
		id: 3,
		code: 202,
		name: " Algeria",
	},
	{
		id: 4,
		code: 202,
		name: "Andorra",
	},
	{
		id: 5,
		code: 202,
		name: "United Kingdom",
	},
];

export class CartControler extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contryList: defaultCountryList,
			billCountry: "",
			paymentCountry: "",
			paymentDdl: false,
			billDdl: false,
			paidItem: 0,
		};
	}

	// funtion to open and close dropdown list
	HandleBillDdlOnClick = (e) => {
		if (e === "close") {
			this.setState({
				billDdl: false,
			});
		} else {
			let val = !this.state.billDdl;
			this.setState({
				billDdl: val,
			});
		}
	};
	// funtion to open and close dropdown list
	HandlePaymentDdlOnClick = (e) => {
		if (e === "close") {
			this.setState({
				paymentDdl: false,
			});
		} else {
			let val = !this.state.paymentDdl;
			this.setState({
				paymentDdl: val,
			});
		}
	};
	// funtion to select  billinfo form country value
	HandleBillDdlValueOnClick = (val) => {
		this.setState({
			billCountry: val,
		});
	};
	// funtion to select  payment form country value
	HandlePaymentDdlValueOnClick = (val) => {
		this.setState({
			paymentCountry: val,
		});
	};

	// funcion to filter country on search
	HandleOnSearch = (e) => {
		const val = e.target.value.toLowerCase();
		const filterData = this.state.contryList.filter((item) => {
			let rgx = new RegExp(val);
			return rgx.test(item.name.toLowerCase());
		});
		if (val) {
			this.setState({
				contryList: filterData,
			});
		} else {
			this.setState({
				contryList: defaultCountryList,
			});
		}
	};

	// HandleValueOnChange function for payment page input page change

	render() {
		const user = JSON.parse(localStorage.getItem("userData"));

		if(user == null){
			return null;

		}else{
			return (
				<>
					<Helmet>
						<title>My Cart | SmarterChains</title>
						<meta name="description" content="My Cart" />
						<meta name="viewport" content="width=device-width, initial-scale=1" /> 
					</Helmet>
					<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
					<BreadcrumbsItem to="/cart">My Cart</BreadcrumbsItem>
					<CartView
						paymentDdl={this.state.paymentDdl}
						HandlePaymentDdlOnClick={this.HandlePaymentDdlOnClick}
						paymentCountry={this.state.paymentCountry}
						HandlePaymentDdlValueOnClick={this.HandlePaymentDdlValueOnClick}
						HandleBillDdlValueOnClick={this.HandleBillDdlValueOnClick}
						billDdl={this.state.billDdl}
						HandleBillDdlOnClick={this.HandleBillDdlOnClick}
						billCountry={this.state.billCountry}
						HandleOnSearch={this.HandleOnSearch}
						contryList={this.state.contryList}
						paidItem={user.paidItem}
						{...this.props}
					/>
				</>
			);
		}

		
	}
}
