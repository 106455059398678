/** imported components

  * CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext, ButtonFirst ===> component from react for carousel
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { Redirect, Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext, ButtonFirst } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./training-walkthrough.scss";
import { Helmet } from "react-helmet";
import {CourseOverviewView} from './training-course-video';
import {TrainingsView} from './trainings-cards-view';
import {QuizViewReport} from './quiz-result-view';
//TrainingWalkthrough component start
export const LearningTrainingWalkthrough = (props) => {
	//defining variables here
	const TotalSlides = 5;
	const user = JSON.parse(localStorage.getItem("userData"));
	const uname = user ? user.name : "";
	const usid = user ? user.uid : 0;
	useEffect(() => {
		serviceProvider.post("/home?type=settraining", JSON.stringify({ uid: usid }), true).then((response) => {
			// console.log(response);
		});
	}, []);

	const login = localStorage.getItem("isLoggedIn");
	if (!login) {
		return <Redirect to="/sign-in" />;
	}
	const [slideIndex, setSlideIndex] = useState(0);
	//function to handle index
	const handleIndex = (e) => {
		if (e === "add" && slideIndex < 4) {
			let tmp = 1 + slideIndex;
			setSlideIndex(tmp);
			// console.log(slideIndex);
		}
		if (e === "subs" && slideIndex > 0) {
			let tmp = slideIndex - 1;
			setSlideIndex(tmp);
			// console.log(slideIndex);
		}
		if (e === "reset" && slideIndex > 0) {
			let tmp = 0;
			setSlideIndex(0);
			// console.log(slideIndex);
		}
	};
	const handleModal=()=>{
		props.handlePopup(false);
	}
	return (
		<>
		 {(slideIndex==0 || slideIndex==2 || slideIndex==4) && <CourseOverviewView slideIndex={slideIndex}/>}
		 {slideIndex==1 && <TrainingsView/>}
		 {slideIndex==3 && <QuizViewReport/>}
			<Modal
				open={true}
				// onClose={props.handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="walk__through__modal1 bg-transparent"
			>
				<>
					{/* this section has
						image
						username
					*/}
					<div className="walk-through-bg" style={{display:'none'}}>
						<img src={require(`../../assets/img/common-img/training-tour${slideIndex}.svg`).default} />
						<div>
							<p>{user.name}</p>
						</div>
					</div>
					<div className="modal__slider__wrapper">
						<CarouselProvider
							naturalSlideWidth={100}
							naturalSlideHeight={90}
							totalSlides={TotalSlides}
							dragEnabled={false}
							isPlaying={false}
						>
							<Slider>
								<Slide className="slide" index={0}>
									{/* this section has
										header
										slide_item_body
									*/}
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/common-img/slider-intro-course.gif").default} alt="true" />
										</header>
										{/* this section has 
											label
											title
											text
										*/}
										<div className="slide__item__body">
											<h4 className="label">1 of 5</h4>
											<h2 className="title">Free Intro Course</h2>
											<p>Hi {uname}, welcome to the Training tour!</p>
											<p>
												Learn the SmarterChains way! We want to quickly show you around and it will only take a minute. Get
												started today with our Free Introductory Course. You can skip at any time.
											</p>
										</div>
									</div>
								</Slide>

								<Slide className="slide" index={1}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/common-img/scale-on-demand.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">2 of 5</h4>
											<h2 className="title">Scale On-Demand</h2>
											<p>
												Gain 360° view of Industry 4.0 and advance your technology specific understanding​ with additional
												trainings. Watch each introduction for Free.
											</p>
										</div>
									</div>
								</Slide>
								<Slide className="slide" index={2}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/common-img/qna.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">3 of 5</h4>
											<h2 className="title">Q&A</h2>
											<p>
												Ask questions, network with peers and let our in-house experts and the community interact with you.
												Stay connected and come back to participate in the Q&A community and join the discussion.
											</p>
										</div>
									</div>
								</Slide>
								<Slide className="slide" index={3}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/common-img/integrated-quiz.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">4 of 5</h4>
											<h2 className="title">Integrated Quizzes</h2>
											<p>
												Get practical with our integrated quizzes that foster long-term knowledge retention. We gamified the
												experience so that you don’t only answer questions, but you have fun too.
											</p>
										</div>
									</div>
								</Slide>
								<Slide className="slide" index={4}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/common-img/congrats-completed.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">5 of 5</h4>
											<h2 className="title">Get Certified</h2>
											<p>
												Receive a digital certification following the successful completion of each Training Course and 
												showcase online your Industry 4.0 readiness.
											</p>
										</div>
									</div>
								</Slide>
							</Slider>
							{/* this section has 
								dots__section
								btn__section
							*/}
							<div className="modal__slider__footer">
								{/* section for navigation */}
								<div className="dots__section">
									<Dot slide={0} className="dot" onClick={()=>setSlideIndex(0)}></Dot>
									<Dot slide={1} className="dot" onClick={()=>setSlideIndex(1)}></Dot>
									<Dot slide={2} className="dot" onClick={()=>setSlideIndex(2)}></Dot>
									<Dot slide={3} className="dot" onClick={()=>setSlideIndex(3)}></Dot>
									<Dot slide={4} className="dot" onClick={()=>setSlideIndex(4)}></Dot>
								</div>
								<div className="btn__section">
									{slideIndex < 4 ? (
										<div className="btn__section">
											{slideIndex === 0 ? (
												<Link className="btn__prv" to="/learning-center">
													Skip
												</Link>
											) : (
												<ButtonBack className="btn__prv" onClick={() => handleIndex("subs")}>
													Previous
												</ButtonBack>
											)}
											<ButtonNext className="btn__nxt" onClick={() => handleIndex("add")}>
												{slideIndex === 0 ?"Let’s go":"Next"}
											</ButtonNext>
										</div>
									) : (
										<div className="btn__section">
											<ButtonFirst className="btn__prv" onClick={() => handleIndex("reset")}>
												Restart
											</ButtonFirst>
											<Link className="btn__nxt" to="/learning-center">
												Exit Tour
											</Link>
										</div>
									)}
								</div>
							</div>
						</CarouselProvider>
					</div>
					{/* this section has two parts
						footer-left
						footer-right
					*/}
					<div className="footer-area">
						<div className="footer-left"></div>
						{/* this section has link */}
						<div className="footer-right">
							<ul className="footer-page-link">
								<li>
									<Link  to="/learning-center">Exit this demo tour</Link>
								</li>
							</ul>
						</div>
					</div>
				</>
			</Modal>
		</>
	);
};
