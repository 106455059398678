export const closeIcon=`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="2" fill="#F0F0F0"/>
<path d="M11.1 22.3C10.7134 22.6866 10.0866 22.6866 9.7 22.3C9.3134 21.9134 9.3134 21.2866 9.7 20.9L13.8929 16.7071C14.2834 16.3166 14.2834 15.6834 13.8929 15.2929L9.7 11.1C9.3134 10.7134 9.3134 10.0866 9.7 9.7C10.0866 9.3134 10.7134 9.3134 11.1 9.7L15.2929 13.8929C15.6834 14.2834 16.3166 14.2834 16.7071 13.8929L20.9 9.7C21.2866 9.3134 21.9134 9.3134 22.3 9.7C22.6866 10.0866 22.6866 10.7134 22.3 11.1L18.1071 15.2929C17.7166 15.6834 17.7166 16.3166 18.1071 16.7071L22.3 20.9C22.6866 21.2866 22.6866 21.9134 22.3 22.3C21.9134 22.6866 21.2866 22.6866 20.9 22.3L16.7071 18.1071C16.3166 17.7166 15.6834 17.7166 15.2929 18.1071L11.1 22.3Z" fill="#0D2C54"/>
</svg>
`
export const arrowIcon=`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_5_72911" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_5_72911)">
<path d="M5.3499 14.6683L4.16656 13.485L9.6499 8.00163L4.16656 2.51829L5.3499 1.33496L12.0166 8.00163L5.3499 14.6683Z" fill="white"/>
</g>
</svg>
`
export const infoIcon=`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" fill="#F5F5F5"/>
<g id="Upgrade &#62; IR 4.0 Training Courses" clip-path="url(#clip0_1_13139)">
<rect width="1440" height="808" transform="translate(-660 -314)" fill="#FAFCFD"/>
<g id="Group 1891"><g id="White BG" filter="url(#filter0_d_1_13139)"><rect x="-567" y="-22" width="800" height="102" rx="8" fill="white"/></g></g>
<g id="Overlay" filter="url(#filter1_b_1_13139)"><rect x="-660" y="-314" width="1440" height="813" fill="#7D868C" fill-opacity="0.3"/></g>
<g id="Pop Up"><g clip-path="url(#clip1_1_13139)"><g id="Group 626346"><rect id="Container" x="-529.5" y="-235.5" width="1179" height="1643" rx="23.5" fill="white" stroke="#CCD6E8"/>
<g id="Frame 626408" clip-path="url(#clip2_1_13139)"><g id="Combo Card">
<path id="Rectangle 1629" d="M-243.5 -70C-243.5 -74.1421 -240.142 -77.5 -236 -77.5H540C544.142 -77.5 547.5 -74.1421 547.5 -70V415.5H-243.5V-70Z" fill="#FAFCFD" stroke="#D3E6FF"/>
<g id="Frame 626407"><g id="Tech Assessment"><g id="Frame 626404"><g id="More_info"><circle id="Ellipse 341" cx="8" cy="8" r="7.5" stroke="#7D868C"/>
<g id="Group 626493"><path id="Line 188" d="M8 7.33337L8 12" stroke="#7D868C" stroke-linecap="round"/><path id="Line 189" d="M8 4L8 4.66667" stroke="#7D868C" stroke-linecap="round"/></g></g></g></g></g></g></g></g></g></g></g>
<defs><filter id="filter0_d_1_13139" x="-571" y="-26" width="808" height="110" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/>
<feGaussianBlur stdDeviation="2"/><feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.105882 0 0 0 0 0.14902 0 0 0 0.12 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_13139"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_13139" result="shape"/></filter>
<filter id="filter1_b_1_13139" x="-664" y="-318" width="1448" height="821" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_13139"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1_13139" result="shape"/></filter>
<clipPath id="clip0_1_13139"><rect width="1440" height="808" fill="white" transform="translate(-660 -314)"/></clipPath>
<clipPath id="clip1_1_13139"><rect x="-530" y="-236" width="1180" height="652" rx="24" fill="white"/></clipPath>
<clipPath id="clip2_1_13139"><rect width="792" height="493" fill="white" transform="translate(-244 -78)"/></clipPath>
</defs>
</svg>
`
export const infoCloseBtn=`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_37597" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_37597)">
<path d="M5.61552 15.5557C5.45932 15.7119 5.20605 15.7119 5.04984 15.5557L4.44886 14.9547C4.29265 14.7985 4.29265 14.5452 4.44886 14.389L8.54984 10.2881C8.70605 10.1318 8.70605 9.87858 8.54984 9.72237L4.44886 5.62138C4.29265 5.46517 4.29265 5.21191 4.44886 5.0557L5.04984 4.45472C5.20605 4.29851 5.45932 4.29851 5.61552 4.45472L9.71651 8.5557C9.87272 8.71191 10.126 8.71191 10.2822 8.5557L14.3832 4.45472C14.5394 4.29851 14.7926 4.29851 14.9489 4.45472L15.5498 5.0557C15.706 5.21191 15.706 5.46517 15.5498 5.62138L11.4489 9.72237C11.2926 9.87858 11.2926 10.1318 11.4489 10.2881L15.5498 14.389C15.706 14.5452 15.706 14.7985 15.5498 14.9547L14.9489 15.5557C14.7926 15.7119 14.5394 15.7119 14.3832 15.5557L10.2822 11.4547C10.126 11.2985 9.87272 11.2985 9.71651 11.4547L5.61552 15.5557Z" fill="#7D868C"/>
</g>
</svg>
`
export const blankSvg=`<svg width="240" height="178" viewBox="0 0 240 178" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M240 156.424H0V156.544H240V156.424Z" fill="#EBEBEB"/>
<path d="M215.952 164.11H200.054V164.23H215.952V164.11Z" fill="#EBEBEB"/>
<path d="M158.986 165.409H154.814V165.529H158.986V165.409Z" fill="#EBEBEB"/>
<path d="M199.574 159.678H190.363V159.798H199.574V159.678Z" fill="#EBEBEB"/>
<path d="M45.912 160.479H25.1808V160.599H45.912V160.479Z" fill="#EBEBEB"/>
<path d="M53.2271 160.479H50.1887V160.599H53.2271V160.479Z" fill="#EBEBEB"/>
<path d="M108.072 162.495H63.1056V162.615H108.072V162.495Z" fill="#EBEBEB"/>
<path d="M113.76 135.115H21.0768C20.3507 135.113 19.6548 134.825 19.1419 134.314C18.6289 133.802 18.3408 133.109 18.3408 132.387V2.7042C18.3471 1.98564 18.638 1.29858 19.1503 0.792255C19.6626 0.285928 20.3549 0.00123093 21.0768 0H113.76C114.487 0 115.184 0.287423 115.698 0.79904C116.212 1.31066 116.501 2.00456 116.501 2.72809V132.387C116.501 133.11 116.212 133.804 115.698 134.316C115.184 134.827 114.487 135.115 113.76 135.115ZM21.0768 0.0955553C20.3826 0.0968209 19.7172 0.372218 19.2267 0.861297C18.7363 1.35038 18.4608 2.01317 18.4608 2.7042V132.387C18.4608 133.078 18.7363 133.74 19.2267 134.229C19.7172 134.719 20.3826 134.994 21.0768 134.995H113.76C114.455 134.994 115.121 134.719 115.612 134.23C116.103 133.741 116.38 133.078 116.381 132.387V2.7042C116.38 2.01274 116.103 1.34995 115.612 0.861005C115.121 0.372062 114.455 0.0968182 113.76 0.0955553H21.0768Z" fill="#EBEBEB"/>
<path d="M217.589 135.115H124.901C124.174 135.113 123.478 134.826 122.964 134.314C122.45 133.803 122.161 133.11 122.16 132.387V2.7042C122.168 1.98521 122.46 1.29815 122.973 0.791956C123.486 0.285761 124.178 0.00121751 124.901 0H217.589C218.31 0.00248658 219.001 0.287739 219.512 0.793931C220.023 1.30012 220.314 1.98647 220.32 2.7042V132.387C220.32 133.108 220.033 133.801 219.521 134.312C219.009 134.824 218.314 135.112 217.589 135.115ZM124.901 0.0955553C124.206 0.0968182 123.54 0.372062 123.049 0.861005C122.558 1.34995 122.281 2.01274 122.28 2.7042V132.387C122.281 133.078 122.558 133.741 123.049 134.23C123.54 134.719 124.206 134.994 124.901 134.995H217.589C218.284 134.994 218.949 134.719 219.441 134.23C219.932 133.741 220.208 133.078 220.21 132.387V2.7042C220.208 2.01274 219.932 1.34995 219.441 0.861005C218.949 0.372062 218.284 0.0968182 217.589 0.0955553H124.901Z" fill="#EBEBEB"/>
<path d="M145.474 13.75H38.7456V81.5462H145.474V13.75Z" fill="#E6E6E6"/>
<path d="M148.085 13.75H39.9647V81.5462H148.085V13.75Z" fill="#F0F0F0"/>
<path d="M145.474 81.5459H38.7456V86.3762H145.474V81.5459Z" fill="#E6E6E6"/>
<path d="M150.01 81.5459H46.7424C46.1059 81.5459 45.4954 81.7976 45.0454 82.2456C44.5953 82.6936 44.3424 83.3012 44.3424 83.9348C44.3411 84.5714 44.5926 85.1827 45.0422 85.6355C45.4917 86.0884 46.1029 86.346 46.7424 86.3523H150.01C150.654 86.3523 151.272 86.0976 151.727 85.6442C152.183 85.1909 152.438 84.5759 152.438 83.9348C152.431 83.2986 152.172 82.691 151.717 82.2438C151.262 81.7966 150.649 81.5459 150.01 81.5459Z" fill="#F0F0F0"/>
<path d="M144.432 77.9065V17.3916L43.6272 17.3916V77.9065H144.432Z" fill="#FAFAFA"/>
<path d="M50.6161 77.9065L72.0769 17.3916H82.2097L60.7489 77.9065H50.6161Z" fill="white"/>
<path d="M45.3167 57.377C45.3859 57.3757 45.4518 57.3475 45.5003 57.2984C45.5488 57.2492 45.5759 57.1831 45.5759 57.1142V19.7618C45.5759 19.6929 45.5488 19.6268 45.5003 19.5777C45.4518 19.5285 45.3859 19.5003 45.3167 19.499C45.2467 19.499 45.1796 19.5267 45.1301 19.576C45.0805 19.6253 45.0527 19.6921 45.0527 19.7618V57.1142C45.0527 57.1839 45.0805 57.2507 45.1301 57.3C45.1796 57.3493 45.2467 57.377 45.3167 57.377Z" fill="#F0F0F0"/>
<path d="M62.9232 77.9065L84.384 17.3916H88.3344L66.8736 77.9065H62.9232Z" fill="white"/>
<path d="M84.6481 77.9065L106.109 17.3916H116.242L94.7809 77.9065H84.6481Z" fill="white"/>
<path d="M111.581 77.9065L133.037 17.3916H136.987L115.526 77.9065H111.581Z" fill="white"/>
<path d="M144.427 77.9065V17.3916H143.962V77.9065H144.427Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M148.949 23.4152H42.2209L41.8849 18.8047H148.613L148.949 23.4152Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M148.949 26.793H42.2209L41.8849 22.1777H148.613L148.949 26.793Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M148.949 30.84H42.2209L41.8849 26.2295H148.613L148.949 30.84Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M148.949 35.3459H42.2209L41.8849 30.7354H148.613L148.949 35.3459Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M148.949 40.7501H42.2209L41.8849 36.1348H148.613L148.949 40.7501Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M148.949 46.8224H42.2209L41.8849 42.2119H148.613L148.949 46.8224Z" fill="#E6E6E6"/>
<path d="M213.744 100.458H198.418V156.42H213.744V100.458Z" fill="#E6E6E6"/>
<path d="M211.051 156.423H213.744V148.803H208.229L211.051 156.423Z" fill="#F0F0F0"/>
<path d="M147.912 100.458H132.586V156.42H147.912V100.458Z" fill="#E6E6E6"/>
<path d="M213.744 100.458H145.565V151.747H213.744V100.458Z" fill="#F0F0F0"/>
<path d="M204.59 117.055H154.714C152.771 117.055 150.908 116.287 149.534 114.92C148.161 113.553 147.389 111.698 147.389 109.764C147.389 107.831 148.161 105.976 149.534 104.609C150.908 103.242 152.771 102.474 154.714 102.474H166.598C167.562 102.473 168.517 102.662 169.407 103.032C170.297 103.401 171.104 103.942 171.782 104.624C173.222 106.057 174.922 106.845 176.63 106.845H182.678C183.986 106.828 185.255 106.407 186.312 105.641C186.725 105.356 187.114 105.036 187.474 104.686C188.86 103.292 190.744 102.5 192.715 102.483H204.59C206.531 102.486 208.391 103.254 209.764 104.62C211.136 105.986 211.908 107.838 211.91 109.769C211.908 111.701 211.136 113.553 209.764 114.918C208.391 116.284 206.531 117.053 204.59 117.055ZM154.714 102.985C152.907 102.985 151.175 103.699 149.897 104.971C148.62 106.242 147.902 107.966 147.902 109.764C147.902 111.563 148.62 113.287 149.897 114.558C151.175 115.83 152.907 116.544 154.714 116.544H204.59C206.395 116.542 208.125 115.827 209.4 114.557C210.676 113.287 211.394 111.565 211.397 109.769C211.396 107.973 210.678 106.25 209.402 104.98C208.126 103.71 206.395 102.996 204.59 102.994H192.715C190.88 103.012 189.125 103.75 187.834 105.049C187.457 105.425 187.047 105.767 186.61 106.071C185.465 106.896 184.092 107.348 182.678 107.366H176.64C174.792 107.366 172.987 106.544 171.427 104.977C170.799 104.346 170.051 103.845 169.227 103.502C168.404 103.16 167.52 102.982 166.627 102.98L154.714 102.985Z" fill="#E6E6E6"/>
<path d="M148.257 156.423H145.565V148.803H151.08L148.257 156.423Z" fill="#F0F0F0"/>
<path d="M204.59 133.395H154.714C152.771 133.395 150.908 132.627 149.534 131.26C148.161 129.892 147.389 128.038 147.389 126.104C147.389 124.171 148.161 122.316 149.534 120.949C150.908 119.582 152.771 118.813 154.714 118.813H166.598C167.562 118.812 168.517 119.002 169.407 119.371C170.297 119.74 171.104 120.281 171.782 120.963C173.222 122.397 174.922 123.185 176.63 123.185H182.678C183.986 123.167 185.255 122.747 186.312 121.981C186.724 121.696 187.111 121.376 187.469 121.026C188.855 119.628 190.742 118.834 192.715 118.818H204.59C206.531 118.821 208.391 119.589 209.764 120.955C211.136 122.321 211.908 124.173 211.91 126.104C211.909 128.037 211.138 129.89 209.765 131.257C208.393 132.624 206.532 133.393 204.59 133.395ZM154.714 119.325C152.907 119.325 151.175 120.039 149.897 121.31C148.62 122.582 147.902 124.306 147.902 126.104C147.902 127.902 148.62 129.627 149.897 130.898C151.175 132.17 152.907 132.884 154.714 132.884H204.59C206.391 132.881 208.117 132.17 209.392 130.905C210.667 129.64 211.388 127.925 211.397 126.133C211.394 124.337 210.676 122.615 209.4 121.345C208.125 120.075 206.395 119.361 204.59 119.358H192.715C190.877 119.374 189.12 120.115 187.829 121.417C187.452 121.79 187.044 122.13 186.61 122.435C185.465 123.26 184.092 123.712 182.678 123.73H176.64C174.792 123.73 172.987 122.908 171.427 121.341C170.799 120.71 170.051 120.209 169.227 119.866C168.404 119.523 167.52 119.346 166.627 119.344L154.714 119.325Z" fill="#E6E6E6"/>
<path d="M204.59 149.728H154.714C152.804 149.68 150.989 148.89 149.656 147.529C148.322 146.167 147.576 144.341 147.576 142.44C147.576 140.538 148.322 138.712 149.656 137.351C150.989 135.989 152.804 135.2 154.714 135.151H166.603C167.566 135.151 168.519 135.34 169.408 135.708C170.297 136.077 171.104 136.616 171.782 137.297C173.222 138.73 174.922 139.518 176.63 139.518H182.678C183.986 139.501 185.255 139.08 186.312 138.314C186.725 138.029 187.114 137.709 187.474 137.359C188.86 135.968 190.742 135.178 192.71 135.161H204.59C206.5 135.21 208.315 135.999 209.648 137.36C210.981 138.722 211.728 140.548 211.728 142.449C211.728 144.351 210.981 146.177 209.648 147.538C208.315 148.9 206.5 149.689 204.59 149.738V149.728ZM154.714 135.686C152.908 135.686 151.177 136.4 149.901 137.671C148.624 138.941 147.907 140.665 147.907 142.461C147.907 144.258 148.624 145.981 149.901 147.252C151.177 148.522 152.908 149.236 154.714 149.236H204.59C206.396 149.236 208.127 148.522 209.403 147.252C210.68 145.981 211.397 144.258 211.397 142.461C211.397 140.665 210.68 138.941 209.403 137.671C208.127 136.4 206.396 135.686 204.59 135.686H192.71C190.876 135.703 189.123 136.442 187.834 137.741C187.455 138.113 187.045 138.454 186.61 138.759C185.465 139.584 184.092 140.036 182.678 140.053H176.64C174.792 140.053 172.987 139.236 171.427 137.664C170.799 137.033 170.051 136.531 169.228 136.189C168.404 135.846 167.52 135.669 166.627 135.667L154.714 135.686Z" fill="#E6E6E6"/>
<path d="M203.03 24.4854H166.397V73.2566H203.03V24.4854Z" fill="#E6E6E6"/>
<path d="M205.579 24.4854H167.074V73.2566H205.579V24.4854Z" fill="#F0F0F0"/>
<path d="M202.354 67.6679V30.0766C202.354 28.7625 201.283 27.6973 199.963 27.6973L172.69 27.6973C171.369 27.6973 170.299 28.7625 170.299 30.0766V67.6679C170.299 68.9819 171.369 70.0472 172.69 70.0472H199.963C201.283 70.0472 202.354 68.9819 202.354 67.6679Z" fill="#E6E6E6"/>
<path d="M201.792 67.6679V30.0766C201.792 28.7625 200.722 27.6973 199.402 27.6973H172.69C171.369 27.6973 170.299 28.7625 170.299 30.0766V67.6679C170.299 68.9819 171.369 70.0472 172.69 70.0472H199.402C200.722 70.0472 201.792 68.9819 201.792 67.6679Z" fill="white"/>
<path d="M191.746 41.962L186.24 34.3988C186.219 34.3631 186.19 34.3334 186.154 34.3128C186.118 34.2921 186.077 34.2812 186.036 34.2812C185.995 34.2812 185.954 34.2921 185.918 34.3128C185.882 34.3334 185.853 34.3631 185.832 34.3988L180.341 41.9716C177.581 45.7938 177.581 51.9809 180.341 55.7888L185.832 63.3615C185.854 63.3961 185.885 63.4245 185.921 63.4442C185.957 63.4639 185.997 63.4742 186.038 63.4742C186.079 63.4742 186.12 63.4639 186.156 63.4442C186.192 63.4245 186.223 63.3961 186.245 63.3615L191.736 55.7888C194.506 51.9714 194.506 45.7747 191.746 41.962ZM180.758 42.521V55.2107C178.224 51.7182 178.224 46.0279 180.758 42.521ZM191.318 42.521C193.858 46.0231 193.858 51.7134 191.318 55.2107V42.521Z" fill="#F0F0F0"/>
<path d="M74.3809 99.6494H33.1873L35.7601 156.438H76.9537L74.3809 99.6494Z" fill="#F0F0F0"/>
<path d="M74.9808 99.6494H33.7872L36.3552 156.438H77.5488L74.9808 99.6494Z" fill="#F5F5F5"/>
<path d="M53.4623 156.424H68.6639V138.292H53.4623V156.424Z" fill="#F0F0F0"/>
<path d="M124.219 138.291H68.6639V156.423H124.219V138.291Z" fill="#F5F5F5"/>
<g style="mix-blend-mode:multiply" opacity="0.7">
<path d="M53.4625 141.856H124.378V140.614H53.4625V141.856Z" fill="#E6E6E6"/>
</g>
<path d="M52.6223 141.136H68.6639V137.538H52.6223V141.136Z" fill="#F0F0F0"/>
<path d="M125.059 137.541H68.6639V141.139H125.059V137.541Z" fill="#F5F5F5"/>
<path d="M58.5072 137.541H81.6V118.549H58.5072V137.541Z" fill="#E6E6E6"/>
<path d="M109.814 118.546H81.6V137.537H109.814V118.546Z" fill="#F0F0F0"/>
<g style="mix-blend-mode:multiply" opacity="0.7">
<path d="M58.5072 123.964H110.054V122.077H58.5072V123.964Z" fill="#E6E6E6"/>
</g>
<path d="M57.2304 122.869H81.6V114.178H57.2304V122.869Z" fill="#E6E6E6"/>
<path d="M111.086 114.179H81.6V122.869H111.086V114.179Z" fill="#F0F0F0"/>
<path d="M120 177.999C171.4 177.999 213.067 175.578 213.067 172.591C213.067 169.604 171.4 167.183 120 167.183C68.6003 167.183 26.9327 169.604 26.9327 172.591C26.9327 175.578 68.6003 177.999 120 177.999Z" fill="#F5F5F5"/>
<path opacity="0.6" d="M48.4177 53.6106C48.1489 52.1487 47.8832 50.6882 47.6208 49.2294L48.1008 49.1387C48.3648 50.6007 48.6337 52.0579 48.8977 53.5199L48.4177 53.6106Z" fill="#8686DF"/>
<path opacity="0.6" d="M49.2583 55.4174L48.7626 55.5078L49.2348 58.0738L49.7305 57.9834L49.2583 55.4174Z" fill="#8686DF"/>
<path opacity="0.6" d="M180.48 108.082L59.664 109.09C55.0608 85.5403 52.944 64.179 48.9696 40.5674C89.4192 41.6424 127.728 40.4718 168.178 41.5468C171.96 63.7633 176.061 85.9416 180.48 108.082Z" fill="#8686DF"/>
<path d="M60.3552 108.354C60.3259 108.354 60.2973 108.345 60.2745 108.326C60.2517 108.308 60.2361 108.282 60.2304 108.254C57.6048 94.8043 55.7664 81.7467 53.9904 69.1143C52.7328 60.1704 51.432 50.9207 49.848 41.4655C49.8445 41.4473 49.845 41.4286 49.8496 41.4106C49.8543 41.3927 49.8629 41.376 49.8749 41.3618C49.8869 41.3476 49.9019 41.3362 49.9188 41.3285C49.9358 41.3209 49.9542 41.3171 49.9728 41.3174L167.462 42.273C167.492 42.2744 167.52 42.2855 167.543 42.3045C167.565 42.3235 167.581 42.3493 167.587 42.3781C171.264 63.9114 175.291 85.7361 179.554 107.236C179.558 107.254 179.558 107.273 179.554 107.291C179.55 107.31 179.542 107.327 179.53 107.341C179.518 107.356 179.504 107.367 179.487 107.376C179.47 107.384 179.452 107.388 179.434 107.389L60.3552 108.378V108.354ZM50.1216 41.5658C51.696 50.978 53.0016 60.1991 54.24 69.0761C56.0112 81.6702 57.8448 94.6896 60.48 108.101L179.299 107.107C175.046 85.6883 171.038 63.9496 167.376 42.4975L50.1216 41.5658Z" fill="#FAFAFA"/>
<path opacity="0.5" d="M144.926 98.5506C141.637 82.3891 138.51 66.2068 135.547 50.0039L163.181 50.3479C166.115 66.3215 169.208 82.2728 172.459 98.2018L144.926 98.5506Z" fill="#FAFAFA"/>
<path d="M139.736 53.303L139.73 53.8047L150.342 53.919L150.348 53.4174L139.736 53.303Z" fill="#FAFAFA"/>
<path d="M139.98 54.6458L139.975 55.1475L148.115 55.2295L148.12 54.7279L139.98 54.6458Z" fill="#FAFAFA"/>
<path opacity="0.7" d="M157.891 54.4373C157.747 53.6633 158.189 53.047 158.88 53.0518C159.256 53.0901 159.607 53.2518 159.88 53.5114C160.153 53.7709 160.332 54.1135 160.387 54.4851C160.531 55.2591 160.09 55.8754 159.398 55.8707C159.022 55.8339 158.67 55.6728 158.396 55.4129C158.123 55.1531 157.945 54.8096 157.891 54.4373Z" fill="#8686DF"/>
<path opacity="0.7" d="M157.363 54.4326C157.162 53.3385 157.79 52.4546 158.774 52.4689C159.304 52.5195 159.801 52.7435 160.189 53.1058C160.576 53.4681 160.832 53.9482 160.915 54.4708C161.117 55.5649 160.488 56.4392 159.509 56.4344C158.979 56.3849 158.48 56.1613 158.092 55.7989C157.704 55.4365 157.447 54.9559 157.363 54.4326Z" fill="#8686DF"/>
<path opacity="0.7" d="M142.498 68.2166C141.832 64.713 141.174 61.2172 140.525 57.7295L161.03 57.9015C161.67 61.3606 162.325 64.8197 162.994 68.2788L142.498 68.2166Z" fill="#8686DF"/>
<path d="M160.181 58.5661C160.292 58.578 160.396 58.6254 160.477 58.7012C160.558 58.7771 160.613 58.8772 160.632 58.9866C161.147 61.729 161.666 64.4714 162.187 67.2138C162.197 67.2612 162.198 67.3101 162.189 67.3577C162.18 67.4053 162.161 67.4506 162.134 67.491C162.11 67.5274 162.076 67.5569 162.036 67.577C161.997 67.597 161.953 67.6069 161.909 67.6056L143.347 67.5387C143.236 67.5279 143.13 67.4809 143.048 67.405C142.966 67.329 142.911 67.2283 142.891 67.1183C142.363 64.3472 141.842 61.5777 141.326 58.8098C141.315 58.762 141.314 58.7125 141.322 58.6641C141.33 58.6157 141.348 58.5694 141.374 58.5279C141.401 58.4917 141.435 58.4624 141.476 58.4425C141.516 58.4225 141.56 58.4125 141.605 58.4132L160.181 58.5661ZM160.152 58.3511L141.571 58.1982C141.498 58.2014 141.426 58.2214 141.361 58.2567C141.297 58.292 141.241 58.3415 141.199 58.4016C141.157 58.4616 141.129 58.5305 141.118 58.603C141.107 58.6754 141.112 58.7495 141.134 58.8194C141.653 61.5904 142.176 64.3599 142.704 67.1278C142.73 67.2917 142.81 67.4423 142.932 67.5557C143.054 67.6691 143.21 67.7388 143.376 67.7537L161.938 67.8158C162.011 67.8124 162.082 67.7925 162.146 67.7575C162.21 67.7225 162.265 67.6735 162.307 67.614C162.35 67.5546 162.378 67.4863 162.389 67.4145C162.401 67.3426 162.396 67.2691 162.374 67.1995C161.853 64.4603 161.334 61.7194 160.819 58.977C160.793 58.8139 160.714 58.6638 160.593 58.5506C160.472 58.4373 160.317 58.3671 160.152 58.3511Z" fill="#FAFAFA"/>
<path opacity="0.2" d="M149.419 64.1258C149.223 63.0842 149.818 62.2386 150.749 62.2481C151.254 62.2969 151.728 62.511 152.097 62.8569C152.466 63.2028 152.709 63.6608 152.789 64.1592C152.986 65.2056 152.391 66.0703 151.464 66.0417C150.958 65.9929 150.483 65.7782 150.113 65.4315C149.743 65.0847 149.499 64.6255 149.419 64.1258Z" fill="black"/>
<path opacity="0.2" d="M144.269 62.0009C144.072 60.9546 144.667 60.0898 145.598 60.0898C146.102 60.141 146.575 60.3559 146.944 60.7013C147.312 61.0468 147.557 61.5035 147.638 62.0009C147.835 63.0473 147.24 63.8882 146.314 63.8882C145.813 63.8385 145.342 63.6272 144.973 63.2865C144.603 62.9458 144.356 62.4944 144.269 62.0009Z" fill="black"/>
<path opacity="0.2" d="M153.12 61.6745C152.923 60.6282 153.519 59.7634 154.445 59.7968C154.949 59.8464 155.423 60.0608 155.792 60.4065C156.161 60.7522 156.405 61.2099 156.485 61.7079C156.682 62.7495 156.091 63.5904 155.16 63.5856C154.656 63.5368 154.181 63.3227 153.812 62.9768C153.443 62.631 153.2 62.1729 153.12 61.6745Z" fill="black"/>
<path opacity="0.7" d="M147.466 93.5478C146.275 87.6425 145.107 81.7356 143.962 75.8271H164.443C165.582 81.6751 166.741 87.5199 167.918 93.3615L147.466 93.5478Z" fill="#8686DF"/>
<path opacity="0.1" d="M146.914 83.1326L146.309 80.0653L163.752 80.0127C163.949 81.0256 164.15 82.0385 164.347 83.0513L146.914 83.1326Z" fill="black"/>
<path opacity="0.1" d="M148.613 91.6983C148.406 90.6758 148.205 89.6534 147.998 88.6309L165.427 88.502C165.629 89.5148 165.835 90.5277 166.037 91.5406L148.613 91.6983Z" fill="black"/>
<path opacity="0.1" d="M147.758 87.4325C147.557 86.4101 147.354 85.3876 147.149 84.3652L164.587 84.2744C164.786 85.2873 164.986 86.3002 165.187 87.3131L147.758 87.4325Z" fill="black"/>
<path d="M142.939 70.2766L142.938 70.7783L156.825 70.8049L156.826 70.3032L142.939 70.2766Z" fill="#FAFAFA"/>
<path d="M154.258 72.2539H143.318V72.7556H154.258V72.2539Z" fill="#FAFAFA"/>
<path d="M105.25 99.296L105.206 99.0906C101.89 82.6886 98.6881 66.019 95.6881 49.5549L95.6353 49.2539L123.782 49.6027L123.821 49.8081C126.782 66.0525 129.95 82.464 133.238 98.6462L133.296 98.9472L105.25 99.296ZM96.2401 49.7651C99.2113 66.062 102.379 82.5548 105.662 98.7896L132.682 98.4504C129.432 82.4258 126.293 66.1623 123.36 50.1043L96.2401 49.7651Z" fill="#FAFAFA"/>
<path d="M99.9724 52.1536L99.9668 52.6553L105.443 52.7162L105.449 52.2145L99.9724 52.1536Z" fill="#FAFAFA"/>
<path d="M100.218 53.4954L100.213 53.9971L104.413 54.0408L104.418 53.5392L100.218 53.4954Z" fill="#FAFAFA"/>
<path d="M119.818 55.7168C119.382 55.6781 118.971 55.4953 118.652 55.1974C118.333 54.8995 118.123 54.5037 118.056 54.0732C118.007 53.8497 118.008 53.618 118.059 53.395C118.111 53.1719 118.211 52.963 118.354 52.7832C118.472 52.6467 118.62 52.5382 118.786 52.4655C118.952 52.3928 119.132 52.3577 119.314 52.3628C119.749 52.4025 120.158 52.5858 120.476 52.8836C120.794 53.1814 121.004 53.5766 121.07 54.0063C121.119 54.2288 121.118 54.4593 121.068 54.6814C121.017 54.9035 120.918 55.1118 120.778 55.2915C120.661 55.4314 120.514 55.5425 120.348 55.6163C120.181 55.6901 120 55.7245 119.818 55.7168ZM118.56 53.9872C118.609 54.3014 118.759 54.5912 118.987 54.8134C119.216 55.0356 119.51 55.1782 119.827 55.2199C119.931 55.2225 120.033 55.2015 120.127 55.1584C120.221 55.1153 120.304 55.0514 120.37 54.9714C120.463 54.849 120.527 54.7075 120.559 54.5571C120.591 54.4067 120.588 54.2512 120.552 54.1019C120.501 53.7884 120.351 53.4993 120.123 53.2774C119.895 53.0554 119.601 52.9123 119.285 52.8692C119.18 52.866 119.076 52.8862 118.981 52.9284C118.885 52.9706 118.8 53.0336 118.733 53.1129C118.642 53.2374 118.579 53.3801 118.55 53.5311C118.52 53.6821 118.523 53.8377 118.56 53.9872Z" fill="#FAFAFA"/>
<path d="M123.739 68.407L102.72 68.321L102.682 68.1156C102.003 64.5514 101.334 60.984 100.675 57.4134L100.622 57.1172L121.642 57.294L121.68 57.4994C122.336 61.0318 123 64.5609 123.672 68.0869L123.739 68.407ZM103.152 67.8432L123.13 67.9053C122.49 64.545 121.858 61.183 121.234 57.8195L101.237 57.6475C101.87 61.0461 102.509 64.4447 103.152 67.8432Z" fill="#FAFAFA"/>
<path d="M103.066 68.3218L102.816 67.887C108.922 64.3658 115.147 60.8255 121.32 57.376L121.565 57.8108C115.397 61.2507 109.176 64.791 103.066 68.3218Z" fill="#FAFAFA"/>
<path d="M123.322 68.3842C115.848 64.8439 108.278 61.2175 100.824 57.6151L101.045 57.166C108.499 60.7684 116.069 64.39 123.542 67.9255L123.322 68.3842Z" fill="#FAFAFA"/>
<path d="M107.774 94.1929L107.736 93.9874C106.531 87.9961 105.336 81.914 104.179 75.9036L104.122 75.6074H125.117L125.155 75.8129C126.307 81.7707 127.493 87.7907 128.683 93.7055L128.741 94.0065L107.774 94.1929ZM104.731 76.1091C105.859 81.9475 107.021 87.8623 108.187 93.6864L128.126 93.4905C126.974 87.7572 125.818 81.8854 124.699 76.0948L104.731 76.1091Z" fill="#FAFAFA"/>
<path d="M108.168 94.1218L107.794 93.7826C113.357 87.7387 119.054 81.6519 124.723 75.6797L125.093 76.0237C119.424 81.9863 113.726 88.0732 108.168 94.1218Z" fill="#FAFAFA"/>
<path d="M128.285 93.9387C120.288 88.0573 112.21 82.0421 104.285 76.0556L104.587 75.6543C112.527 81.6408 120.6 87.656 128.587 93.5326L128.285 93.9387Z" fill="#FAFAFA"/>
<path d="M103.388 70.1985L103.387 70.7002L117.274 70.7267L117.275 70.2251L103.388 70.1985Z" fill="#FAFAFA"/>
<path d="M114.715 72.2148H103.776V72.7165H114.715V72.2148Z" fill="#FAFAFA"/>
<g opacity="0.6">
<path d="M56.5824 48.762C59.0304 62.0027 61.5904 75.2211 64.2624 88.4173C64.992 92.0388 65.728 95.6619 66.4704 99.2866C66.5616 99.7166 67.1376 99.7119 67.0464 99.2866C64.336 86.0905 61.7248 72.8721 59.2128 59.6313C58.528 56.0066 57.848 52.3787 57.1728 48.7476C57.0912 48.3129 56.5152 48.3033 56.5968 48.7476L56.5824 48.762Z" fill="#FAFAFA"/>
<path d="M65.7023 98.6883C73.1455 98.5704 80.5967 98.5051 88.0559 98.4924C90.1583 98.4924 92.2623 98.4924 94.3679 98.4924C94.7423 98.4924 94.6415 97.8235 94.2671 97.8235C86.8047 97.8012 79.3487 97.8315 71.8991 97.9143C69.7967 97.9366 67.6975 97.9652 65.6015 98.0003C65.2319 98.0003 65.3327 98.6883 65.7023 98.6787V98.6883Z" fill="#FAFAFA"/>
<path d="M94.2336 98.3306C91.552 85.3065 88.9696 72.2712 86.4864 59.2248C85.7888 55.6478 85.12 52.0693 84.48 48.4892C84.3984 48.0592 83.8224 48.0496 83.8992 48.4892C86.328 61.5483 88.856 74.5948 91.4832 87.6285C92.2032 91.2022 92.9312 94.7744 93.6672 98.3449C93.7584 98.7749 94.3344 98.7654 94.2432 98.3449L94.2336 98.3306Z" fill="#FAFAFA"/>
<path d="M85.2096 49.1105C77.7408 49.05 70.2656 48.9305 62.784 48.7522C60.672 48.7012 58.56 48.6455 56.448 48.585C56.0736 48.585 56.1648 49.2538 56.5344 49.2634C64.0224 49.4736 71.504 49.6233 78.9792 49.7125C81.088 49.7412 83.1952 49.7635 85.3008 49.7794C85.6704 49.7794 85.584 49.1153 85.2096 49.1105Z" fill="#FAFAFA"/>
<path d="M64.6848 72.1442L77.8128 71.8528L81.2928 71.7763C81.6624 71.7763 81.5712 71.1027 81.2016 71.1074L67.6848 71.3989L64.5936 71.4706C64.224 71.4706 64.3152 72.1538 64.6848 72.1442Z" fill="#FAFAFA"/>
<path d="M61.1424 53.5632L68.6832 53.4676C69.0528 53.4676 68.9664 52.7892 68.5968 52.794L61.0512 52.8895C60.6864 52.8895 60.7728 53.568 61.1424 53.5632Z" fill="#FAFAFA"/>
<path d="M69.12 94.9915C74.9952 95.1109 80.7936 94.657 86.6304 94.5137C88.0704 94.4803 89.4816 94.4611 90.9168 94.4898C91.2864 94.4898 91.1856 93.8257 90.816 93.8209C84.936 93.7015 79.1376 94.1458 73.3008 94.2987C71.8608 94.3321 70.4496 94.356 69.0192 94.3274C68.6448 94.3274 68.7456 94.9963 69.12 95.001V94.9915Z" fill="#FAFAFA"/>
<path d="M65.7168 77.4006C72.792 77.219 79.8768 77.1044 86.9424 76.875L86.6064 76.5406C87.8016 82.4507 89.0688 88.356 90.1632 94.2708C90.24 94.696 90.816 94.696 90.7344 94.2708C89.64 88.356 88.3776 82.4555 87.1824 76.5454C87.173 76.4599 87.1346 76.3801 87.0735 76.3193C87.0124 76.2585 86.9323 76.2202 86.8464 76.2109C79.7808 76.4403 72.696 76.5502 65.6208 76.7269C65.2512 76.7269 65.3472 77.4149 65.7168 77.4054V77.4006Z" fill="#FAFAFA"/>
<path d="M69.2591 94.9424C73.9583 90.2554 78.5087 85.3869 83.1791 80.6808C84.4703 79.3861 85.7615 78.1056 87.0767 76.8586C87.3359 76.6054 86.9664 76.0273 86.7024 76.2805C81.9024 80.8911 77.2895 85.7739 72.6143 90.5756C71.3759 91.8512 70.1328 93.1221 68.8752 94.3739C68.616 94.6319 68.9903 95.2147 69.2543 94.952L69.2591 94.9424Z" fill="#FAFAFA"/>
<path d="M90.6191 94.332C83.9807 89.9939 77.5535 85.1158 71.0159 80.4766C69.2399 79.22 67.4591 77.9778 65.6543 76.7738C65.3087 76.5445 65.1023 77.1322 65.4479 77.3615C72.1295 81.8144 78.6095 86.7402 85.2047 91.3555C86.9279 92.5643 88.6607 93.7444 90.4175 94.9006C90.7631 95.1252 90.9647 94.547 90.6191 94.3177V94.332Z" fill="#FAFAFA"/>
<path d="M65.3856 76.4434C66.4128 82.5159 67.4736 88.6028 68.7792 94.6562C68.8752 95.0862 69.4464 95.0862 69.3552 94.6562C68.0448 88.6028 66.984 82.5207 65.9616 76.4434C65.8896 76.0134 65.3136 76.0087 65.3856 76.4434Z" fill="#FAFAFA"/>
<path d="M63.9263 68.8287C69.7967 68.9004 75.6191 68.6233 81.4655 68.542C82.9055 68.542 84.3167 68.5086 85.7519 68.542C86.1215 68.542 86.0303 67.8779 85.6559 67.8731C79.7855 67.7919 73.9631 68.069 68.1119 68.1503C66.6719 68.1503 65.2607 68.1837 63.8303 68.1503C63.4607 68.1503 63.5567 68.8239 63.9263 68.8287Z" fill="#FAFAFA"/>
<path d="M61.9728 57.9725C69.0576 57.8578 76.1472 57.7909 83.2272 57.6524L82.896 57.3179C83.6112 60.9634 84.3936 64.6136 84.9888 68.2685C85.056 68.6938 85.632 68.7033 85.5648 68.2685C84.9696 64.6136 84.1872 60.9681 83.472 57.3227C83.4336 57.1412 83.2992 56.9787 83.136 56.9835C76.056 57.1221 68.9664 57.1889 61.8816 57.2941C61.512 57.2941 61.5984 57.9773 61.9728 57.9725Z" fill="#FAFAFA"/>
<path d="M64.0655 68.7993C69.1343 65.9327 74.1167 62.894 79.1663 59.9939C80.5583 59.196 81.9599 58.4029 83.3663 57.6289C83.6783 57.4569 83.3087 56.874 82.9967 57.046C77.8559 59.8745 72.8207 62.9036 67.7519 65.8753C66.4031 66.6589 65.0495 67.4472 63.6911 68.2164C63.3791 68.3932 63.7535 68.9761 64.0655 68.7993Z" fill="#FAFAFA"/>
<path d="M85.3488 68.1556C79.1664 65.5087 73.0608 62.4557 66.9168 59.6034C65.2272 58.8198 63.5568 58.0506 61.8432 57.3101C61.4736 57.1524 61.4112 57.7878 61.776 57.9598C68.016 60.6736 74.1648 63.7552 80.3616 66.6028C82 67.3577 83.6416 68.0903 85.2864 68.8006C85.6512 68.9582 85.7088 68.3228 85.3488 68.1556Z" fill="#FAFAFA"/>
<path d="M61.6944 57.2391C62.232 60.9849 62.7984 64.7545 63.5904 68.4907C63.6816 68.9207 64.2576 68.9255 64.1664 68.4907C63.3744 64.7593 62.808 60.9896 62.2464 57.2439C62.184 56.8091 61.608 56.8043 61.6704 57.2439L61.6944 57.2391Z" fill="#FAFAFA"/>
<path d="M80.2416 51.208C79.9248 51.219 79.6243 51.3504 79.402 51.5753C79.1796 51.8001 79.0524 52.1013 79.0464 52.4168C79.0262 52.8038 79.0978 53.1902 79.2554 53.5446C79.413 53.899 79.6522 54.2115 79.9536 54.4569C80.2014 54.6562 80.5116 54.7625 80.8301 54.7573C81.1486 54.7521 81.4551 54.6356 81.696 54.4282C81.9281 54.2169 82.1027 53.9505 82.2034 53.654C82.3041 53.3574 82.3277 53.0402 82.272 52.7321C82.2358 52.5191 82.154 52.3163 82.0323 52.1375C81.9106 51.9586 81.7517 51.8078 81.5664 51.6953C81.24 51.4899 80.8032 51.2558 80.472 51.3896C80.3979 51.4248 80.3407 51.4876 80.3128 51.5646C80.2849 51.6415 80.2887 51.7263 80.3232 51.8005C80.3856 51.9677 80.568 52.0967 80.7072 52.0346C80.8464 51.9725 81.1392 52.1444 81.2976 52.24C81.3888 52.2814 81.4702 52.3416 81.5364 52.4165C81.6026 52.4914 81.6521 52.5794 81.6816 52.6748C81.7292 52.8729 81.7264 53.0798 81.6736 53.2766C81.6208 53.4734 81.5197 53.6541 81.3792 53.8023C81.2512 53.9441 81.0799 54.0401 80.8917 54.0755C80.7035 54.1109 80.5088 54.0837 80.3376 53.9982C80.1306 53.8608 79.9583 53.6777 79.8342 53.463C79.7102 53.2483 79.6377 53.008 79.6224 52.7608C79.6055 52.6559 79.6107 52.5487 79.6378 52.4459C79.6648 52.3432 79.713 52.2471 79.7794 52.164C79.8458 52.0808 79.9289 52.0123 80.0233 51.9629C80.1178 51.9135 80.2216 51.8842 80.328 51.8769C80.7024 51.8769 80.6112 51.2128 80.2416 51.208Z" fill="#FAFAFA"/>
</g>
<path d="M95.0832 44.1066C93.2832 44.0779 91.9008 45.6068 91.8624 47.6469C91.824 49.687 93.0576 52.2096 94.8768 53.1891C95.6737 53.6096 96.5953 53.7329 97.4755 53.5367C98.3556 53.3404 99.1364 52.8376 99.6768 52.1189C100.694 50.8432 101.261 48.4687 100.157 46.6818C99.8775 46.2274 99.5086 45.8339 99.0724 45.5252C98.6362 45.2165 98.1417 44.9989 97.6188 44.8855C97.0958 44.7721 96.5552 44.7653 96.0295 44.8654C95.5038 44.9656 95.004 45.1706 94.56 45.4682C94.2672 45.6785 94.632 46.2613 94.9296 46.0511C95.5961 45.5889 96.4082 45.3826 97.2156 45.4704C98.0231 45.5583 98.7712 45.9343 99.3216 46.5289C100.555 47.9192 100.238 50.0405 99.4464 51.2684C99.0802 51.8911 98.5235 52.381 97.8577 52.6663C97.1919 52.9517 96.4518 53.0177 95.7456 52.8546C94.0704 52.4246 92.7648 50.4037 92.496 48.5547C92.1888 46.4859 93.336 44.7086 95.1648 44.7325C95.5344 44.7325 95.448 44.0731 95.0784 44.0684L95.0832 44.1066Z" fill="#FAFAFA"/>
<path d="M122.29 85.2116C124.21 88.3267 127.79 89.7266 130.531 88.556C133.378 87.3425 135.302 83.7114 134.592 80.0039C133.906 76.4445 130.853 73.5396 127.834 72.5219C125.04 71.5664 121.258 72.0442 120.154 75.0732C118.886 78.6183 121.536 83.248 124.632 85.1734C124.982 85.3884 125.189 84.8198 124.843 84.6048C123.497 83.7519 122.392 82.5712 121.632 81.1744C120.869 79.7984 120.278 78.174 120.418 76.6738C120.725 73.2673 124.109 72.2926 126.888 72.9137C128.384 73.2824 129.771 73.9961 130.938 74.997C132.106 75.9979 133.02 77.2581 133.608 78.6757C134.995 82.0822 133.55 85.8184 131.16 87.4428C128.582 89.1915 124.762 88.169 122.731 84.8724C122.506 84.5045 122.054 84.839 122.285 85.2068L122.29 85.2116Z" fill="#FAFAFA"/>
<path d="M90.9216 71.1882C92.1508 70.9613 93.4036 70.8875 94.6512 70.9684C95.0256 70.9923 94.9344 70.3234 94.56 70.3043C93.262 70.2239 91.9591 70.3058 90.6816 70.548C90.5328 70.5766 90.4752 70.7964 90.5376 70.9541C90.5601 71.0341 90.6122 71.1027 90.6834 71.1461C90.7547 71.1895 90.8397 71.2045 90.9216 71.1882Z" fill="#FAFAFA"/>
<path d="M93.6385 69.2052C95.101 69.7696 96.5291 70.4189 97.9153 71.1498C97.8913 70.9587 97.8625 70.7675 97.8337 70.5764L95.1361 72.0098C95.0065 72.0814 95.0065 72.3251 95.0977 72.4875C95.1167 72.5225 95.1426 72.5534 95.1737 72.5783C95.2048 72.6033 95.2406 72.6219 95.279 72.633C95.3174 72.6441 95.3576 72.6475 95.3974 72.6431C95.4371 72.6386 95.4755 72.6263 95.5105 72.607L98.2081 71.1737C98.3905 71.0781 98.3233 70.6959 98.1265 70.6003C96.6917 69.8437 95.214 69.1705 93.7009 68.5841C93.3313 68.4408 93.2689 69.0858 93.6385 69.2291V69.2052Z" fill="#FAFAFA"/>
<path d="M130.037 71.2349C131.267 71.0119 132.519 70.9397 133.766 71.0199C134.141 71.0199 134.05 70.3892 133.675 70.3653C132.377 70.2882 131.075 70.3684 129.797 70.6042C129.648 70.6329 129.59 70.8479 129.653 71.0055C129.677 71.084 129.73 71.1508 129.801 71.1931C129.872 71.2354 129.956 71.2503 130.037 71.2349Z" fill="#FAFAFA"/>
<path d="M132.758 69.3013C134.217 69.8583 135.644 70.4963 137.031 71.2124C137.007 71.0261 136.978 70.8397 136.954 70.6486L134.251 72.0819C134.117 72.1536 134.122 72.3877 134.208 72.5263C134.248 72.596 134.313 72.6475 134.39 72.6698C134.467 72.6922 134.55 72.6835 134.621 72.6457L137.318 71.2124C137.501 71.1168 137.439 70.7346 137.242 70.6534C135.81 69.9091 134.335 69.2487 132.826 68.6754C132.451 68.5369 132.389 69.1532 132.758 69.3013Z" fill="#FAFAFA"/>
<path d="M207.192 159.174C207.24 159.181 207.288 159.181 207.336 159.174C207.84 159.174 208.397 158.395 208.685 157.946C208.695 157.931 208.701 157.912 208.701 157.894C208.701 157.875 208.695 157.857 208.685 157.841C208.674 157.827 208.659 157.816 208.642 157.809C208.625 157.802 208.607 157.8 208.589 157.803C208.43 157.841 207.019 158.152 206.842 158.625C206.822 158.677 206.816 158.733 206.826 158.787C206.835 158.842 206.859 158.893 206.894 158.935C206.961 159.048 207.067 159.133 207.192 159.174ZM208.397 158.056C207.994 158.644 207.6 159.012 207.331 159.012C207.272 159.013 207.214 158.999 207.163 158.97C207.111 158.942 207.068 158.9 207.038 158.849C207.022 158.832 207.011 158.81 207.006 158.786C207.002 158.762 207.005 158.738 207.014 158.716C207.106 158.458 207.84 158.195 208.397 158.056Z" fill="#8686DF"/>
<path d="M207.384 157.957C207.792 158.062 208.218 158.077 208.632 158C208.647 157.995 208.661 157.986 208.672 157.974C208.683 157.963 208.69 157.949 208.694 157.933C208.702 157.92 208.706 157.905 208.706 157.89C208.706 157.875 208.702 157.86 208.694 157.847C208.661 157.814 207.878 157.03 207.302 157.064C207.229 157.064 207.156 157.082 207.09 157.115C207.025 157.148 206.967 157.196 206.923 157.255C206.87 157.308 206.837 157.378 206.829 157.453C206.821 157.527 206.839 157.602 206.88 157.666C207.014 157.812 207.19 157.914 207.384 157.957ZM208.406 157.857C207.926 157.914 207.163 157.785 207.014 157.56C207.014 157.522 206.971 157.47 207.048 157.369C207.077 157.332 207.114 157.302 207.157 157.282C207.199 157.261 207.246 157.25 207.293 157.25C207.719 157.331 208.108 157.543 208.406 157.857Z" fill="#8686DF"/>
<path d="M184.646 168.807C185.063 168.799 185.477 168.753 185.885 168.669C185.903 168.665 185.92 168.655 185.933 168.642C185.946 168.628 185.954 168.611 185.957 168.592C185.96 168.573 185.957 168.554 185.949 168.537C185.94 168.519 185.926 168.505 185.909 168.497C185.726 168.401 184.128 167.598 183.653 167.866C183.608 167.891 183.571 167.927 183.545 167.97C183.519 168.014 183.505 168.064 183.504 168.114C183.495 168.199 183.506 168.284 183.537 168.363C183.568 168.442 183.617 168.512 183.682 168.568C183.967 168.754 184.307 168.838 184.646 168.807ZM185.573 168.535C184.704 168.678 184.051 168.645 183.806 168.43C183.764 168.393 183.731 168.346 183.711 168.293C183.691 168.241 183.684 168.184 183.691 168.129C183.691 168.109 183.695 168.089 183.704 168.072C183.714 168.054 183.727 168.039 183.744 168.028C184.003 167.885 184.915 168.224 185.573 168.535Z" fill="#8686DF"/>
<path d="M185.866 168.654C185.883 168.659 185.901 168.659 185.918 168.654C185.933 168.645 185.944 168.632 185.951 168.617C185.957 168.601 185.96 168.585 185.957 168.568C185.957 168.496 185.765 166.796 184.81 166.896C184.555 166.92 184.478 167.049 184.464 167.154C184.392 167.598 185.347 168.449 185.827 168.664L185.866 168.654ZM184.882 167.063C185.453 167.063 185.674 167.995 185.746 168.396C185.266 168.105 184.603 167.441 184.646 167.168C184.646 167.144 184.646 167.082 184.829 167.063H184.882Z" fill="#8686DF"/>
<path d="M199.411 59.0957C199.742 59.5735 200.006 59.9557 200.275 60.3905C200.544 60.8252 200.794 61.2409 201.048 61.6709C201.528 62.5309 202.008 63.4148 202.445 64.3034C203.321 66.0828 204.086 67.9146 204.734 69.7883C204.898 70.2661 205.037 70.7439 205.214 71.2216L205.277 71.4175C205.301 71.5131 205.33 71.6038 205.354 71.7042C205.399 71.9089 205.431 72.1164 205.45 72.3253C205.486 72.7933 205.431 73.264 205.286 73.7108C205.006 74.5239 204.458 75.2191 203.731 75.684C203.197 76.0239 202.614 76.2831 202.003 76.4533C200.968 76.7442 199.901 76.9048 198.826 76.931C198.312 76.931 197.803 76.931 197.299 76.931C196.795 76.931 196.301 76.8737 195.773 76.802L195.802 74.8097C196.685 74.6807 197.621 74.5183 198.494 74.3319C199.328 74.1523 200.141 73.8914 200.923 73.5532C201.226 73.4235 201.507 73.2496 201.758 73.0372C201.95 72.8652 201.95 72.7553 201.912 72.7744C201.874 72.7935 201.888 72.7744 201.874 72.7409L201.758 72.4543C201.586 72.0291 201.422 71.5991 201.24 71.1786C200.534 69.4777 199.742 67.8103 198.893 66.1763C198.461 65.3641 198 64.5662 197.549 63.7874C197.318 63.3925 197.083 63.0007 196.843 62.6121C196.608 62.2347 196.363 61.8238 196.138 61.5037L199.411 59.0957Z" fill="#FFC3BD"/>
<path d="M196.714 55.4776C199.421 55.9267 201.456 60.7331 201.456 60.7331L196.978 67.6035C196.978 67.6035 192.6 62.9787 193.022 59.0036C193.296 56.4188 194.054 55.0333 196.714 55.4776Z" fill="#8686DF"/>
<path opacity="0.6" d="M196.714 55.4776C199.421 55.9267 201.456 60.7331 201.456 60.7331L196.978 67.6035C196.978 67.6035 192.6 62.9787 193.022 59.0036C193.296 56.4188 194.054 55.0333 196.714 55.4776Z" fill="black"/>
<path opacity="0.2" d="M195.432 60.6483C196.08 61.9383 196.085 65.0821 196.037 66.4772C194.985 65.1785 194.137 63.7287 193.522 62.1772C194.203 60.892 195.005 59.8026 195.432 60.6483Z" fill="black"/>
<path d="M196.757 75.0526L194.928 72.1143L193.344 74.828C193.344 74.828 194.688 76.9398 196.349 76.8347L196.757 75.0526Z" fill="#FFC3BD"/>
<path d="M191.923 70.8428L189.998 71.9464L193.344 74.8274L194.928 72.1137L191.923 70.8428Z" fill="#FFC3BD"/>
<path d="M178.646 44.5137C178.714 44.8243 178.603 45.1157 178.402 45.1635C178.2 45.2113 177.979 45.0059 177.922 44.6857C177.864 44.3656 177.965 44.0885 178.166 44.036C178.368 43.9834 178.56 44.208 178.646 44.5137Z" fill="#263238"/>
<path d="M178.253 44.0394L177.456 44.0107C177.456 44.0107 177.97 44.4933 178.253 44.0394Z" fill="#263238"/>
<path d="M178.45 44.8818C178.327 45.6542 178.099 46.4064 177.773 47.1178C177.958 47.1915 178.157 47.2238 178.356 47.2122C178.555 47.2006 178.75 47.1456 178.925 47.0509L178.45 44.8818Z" fill="#ED847E"/>
<path d="M177.816 43.2469C177.852 43.2457 177.886 43.2342 177.915 43.2138C177.944 43.1935 177.966 43.1651 177.979 43.1323C178.059 42.9079 178.19 42.7049 178.361 42.5391C178.533 42.3734 178.74 42.2493 178.968 42.1767C178.992 42.1711 179.015 42.1608 179.035 42.1464C179.055 42.1321 179.071 42.114 179.084 42.0931C179.097 42.0723 179.106 42.0491 179.11 42.0249C179.114 42.0007 179.113 41.976 179.107 41.9522C179.102 41.9283 179.091 41.9058 179.077 41.886C179.062 41.8661 179.044 41.8493 179.023 41.8364C179.002 41.8235 178.979 41.8149 178.955 41.811C178.93 41.8071 178.906 41.808 178.882 41.8136C178.595 41.8994 178.332 42.0508 178.115 42.2558C177.897 42.4607 177.731 42.7134 177.629 42.9937C177.613 43.0407 177.615 43.092 177.636 43.1372C177.656 43.1824 177.693 43.2182 177.739 43.2374C177.763 43.2477 177.79 43.251 177.816 43.2469Z" fill="#263238"/>
<path d="M188.803 47.0127C188.443 49.3633 188.17 53.6442 189.883 55.0728C189.883 55.0728 189.341 57.4616 184.973 57.7817C180.173 58.121 182.573 55.5888 182.573 55.5888C185.165 54.7861 185.016 52.875 184.493 51.1072L188.803 47.0127Z" fill="#FFC3BD"/>
<path d="M176.534 63.2415C176.256 63.81 176.006 64.2878 175.718 64.7895C175.43 65.2911 175.152 65.745 174.854 66.2562C174.269 67.2118 173.621 68.1673 172.963 69.0655C171.633 70.9095 170.15 72.6388 168.528 74.2351L167.909 74.8275L167.755 74.9756L167.486 75.2049C167.28 75.3707 167.057 75.5133 166.819 75.6302C166.316 75.884 165.756 76.0088 165.192 75.9933C164.706 75.9757 164.228 75.8688 163.781 75.6779C163.109 75.38 162.494 74.9695 161.962 74.4644C161.51 74.04 161.089 73.5833 160.704 73.098C159.316 71.2734 158.178 69.2731 157.32 67.1497L159.091 66.2276C160.265 67.9402 161.58 69.5526 163.022 71.0483C163.354 71.3768 163.709 71.6817 164.083 71.9609C164.371 72.1854 164.696 72.3584 165.043 72.4721C165.158 72.5055 165.226 72.4721 165.23 72.4721C165.235 72.4721 165.182 72.4721 165.173 72.4721C165.183 72.4553 165.196 72.4407 165.211 72.4291L165.341 72.2857L165.864 71.7124C167.247 70.1607 168.529 68.5235 169.704 66.8104C170.294 65.9505 170.846 65.0666 171.398 64.1827C171.667 63.7384 171.936 63.2892 172.19 62.8401C172.445 62.391 172.709 61.918 172.92 61.5215L176.534 63.2415Z" fill="#FFC3BD"/>
<path d="M159.298 66.6304L159.005 63.458L156 65.1159C156 65.1159 156.48 67.5525 158.088 68.0112L159.298 66.6304Z" fill="#FFC3BD"/>
<path d="M156.941 61.9766L154.517 62.0387L155.986 65.1155L159.005 63.4577L156.941 61.9766Z" fill="#FFC3BD"/>
<path d="M190.306 168.391L186.36 168.682L185.184 159.662L189.13 159.37L190.306 168.391Z" fill="#FFC3BD"/>
<path d="M212.952 155.983L209.563 158.1L203.779 151.11L207.168 148.993L212.952 155.983Z" fill="#FFC3BD"/>
<path d="M208.526 157.36L211.723 154.206C211.776 154.151 211.848 154.116 211.925 154.109C212.002 154.102 212.079 154.123 212.141 154.168L214.934 156.256C215.006 156.313 215.064 156.384 215.105 156.465C215.145 156.547 215.168 156.636 215.172 156.726C215.175 156.817 215.158 156.907 215.123 156.991C215.088 157.075 215.035 157.15 214.968 157.212C213.83 158.291 213.23 158.755 211.824 160.145C210.96 160.996 209.779 162.329 208.584 163.49C207.389 164.651 206.184 163.585 206.587 163.012C208.358 160.351 208.07 159.362 208.219 158.01C208.24 157.763 208.349 157.533 208.526 157.36Z" fill="#263238"/>
<path d="M186.11 168.122H190.56C190.637 168.121 190.713 168.147 190.773 168.196C190.832 168.245 190.873 168.314 190.886 168.39L191.482 171.882C191.493 171.97 191.486 172.059 191.46 172.143C191.434 172.227 191.391 172.305 191.332 172.372C191.273 172.438 191.201 172.49 191.12 172.526C191.039 172.562 190.951 172.581 190.862 172.58C189.317 172.556 188.568 172.465 186.619 172.465C185.419 172.465 182.938 172.589 181.282 172.589C179.626 172.589 179.496 170.955 180.182 170.807C183.259 170.148 184.454 169.235 185.462 168.361C185.643 168.207 185.873 168.122 186.11 168.122Z" fill="#263238"/>
<path opacity="0.2" d="M185.189 159.662L185.837 164.673L189.763 164.224L189.134 159.37L185.189 159.662Z" fill="black"/>
<path opacity="0.2" d="M207.168 148.999L203.779 151.116L206.174 154.006L209.64 151.976L207.168 148.999Z" fill="black"/>
<path d="M194.933 79.3681L178.848 80.51C174.048 57.8539 175.93 57.3618 175.93 57.3618C177.16 56.8801 178.418 56.4732 179.698 56.1435L179.918 56.0909C180.965 55.8472 181.186 55.8186 182.578 55.5892C184.978 55.3217 187.171 55.1401 189.883 55.0732H190.402C190.882 55.0732 191.362 55.0732 191.842 55.0732C192.158 55.0732 192.475 55.0732 192.773 55.1067L193.795 55.1784C195.523 55.2978 196.752 55.4698 196.752 55.4698C194.198 68.9526 194.4 72.9325 194.933 79.3681Z" fill="#8686DF"/>
<path opacity="0.6" d="M194.933 79.3681L178.848 80.51C174.048 57.8539 175.93 57.3618 175.93 57.3618C177.16 56.8801 178.418 56.4732 179.698 56.1435L179.918 56.0909C180.965 55.8472 181.186 55.8186 182.578 55.5892C184.978 55.3217 187.171 55.1401 189.883 55.0732H190.402C190.882 55.0732 191.362 55.0732 191.842 55.0732C192.158 55.0732 192.475 55.0732 192.773 55.1067L193.795 55.1784C195.523 55.2978 196.752 55.4698 196.752 55.4698C194.198 68.9526 194.4 72.9325 194.933 79.3681Z" fill="black"/>
<path opacity="0.2" d="M177.557 62.7037L178.997 62.6416C178.786 64.5097 177.672 67.6152 176.731 69.5646C176.386 67.5101 176.131 65.7997 175.954 64.3759L177.557 62.7037Z" fill="black"/>
<path d="M176.491 57.1467C174.091 57.6245 171.835 61.9818 170.957 63.8786L174.797 69.6979C174.797 69.6979 180.245 63.6397 179.779 61.504C179.314 59.2776 177.662 56.9222 176.491 57.1467Z" fill="#8686DF"/>
<g opacity="0.6">
<path d="M176.491 57.1457C174.091 57.6235 171.835 61.9808 170.957 63.8776L174.797 69.6969C174.797 69.6969 180.245 63.6387 179.779 61.5031C179.314 59.2766 177.663 56.9212 176.491 57.1457Z" fill="black"/>
</g>
<path d="M188.866 41.117C189.461 44.963 189.792 46.5731 188.29 48.9907C186.024 52.6218 181.142 52.6457 179.405 49.091C177.845 45.8899 177.139 40.1471 180.547 37.7248C181.27 37.1784 182.124 36.8293 183.024 36.7119C183.924 36.5946 184.84 36.7131 185.68 37.0557C186.52 37.3982 187.256 37.9531 187.814 38.6653C188.373 39.3776 188.735 40.2226 188.866 41.117Z" fill="#FFC3BD"/>
<path d="M180.504 44.7668C179.462 47.1557 177.384 50.6339 179.294 52.76C179.294 52.76 179.294 52.1628 179.558 52.029C179.789 52.5546 188.563 54.2698 190.387 51.4175C190.43 51.6368 190.43 51.8622 190.387 52.0816C190.938 51.7329 191.357 51.2118 191.578 50.6003C191.798 49.9888 191.808 49.3216 191.606 48.7037C190.786 45.7654 191.746 39.3154 188.414 37.3231C185.453 35.5553 177.547 34.3083 180.504 44.7668Z" fill="#263238"/>
<path d="M182.678 36.4915C180.11 35.6315 177.854 37.4183 177.456 40.8392C178.517 42.2056 180.542 42.3108 180.542 42.3108L182.678 36.4915Z" fill="#263238"/>
<path d="M181.003 43.865C180.936 44.5853 180.648 45.2676 180.178 45.8191C179.53 46.5979 178.834 46.3399 178.704 45.5802C178.589 44.897 178.786 43.6691 179.592 43.1436C180.398 42.618 181.046 43.0576 181.003 43.865Z" fill="#FFC3BD"/>
<path d="M183.096 80.2078C183.096 80.2078 184.056 86.1943 185.294 94.0632C186.11 99.3187 187.037 105.425 187.862 111.163C188.342 114.445 188.77 117.613 189.134 120.432C191.198 136.413 204.715 153.551 204.715 153.551L209.659 150.718C209.659 150.718 201.691 135.73 199.622 120.804C195.427 90.4942 200.395 86.0653 194.933 79.3525L183.096 80.2078Z" fill="#8686DF"/>
<path opacity="0.2" d="M184.176 85.5876C184.483 87.9 184.872 90.8431 185.295 94.0824C186.111 99.3379 187.037 105.444 187.863 111.182C189.365 104.168 191.333 91.1727 186.562 86.481C185.626 85.5589 184.824 85.3057 184.176 85.5876Z" fill="black"/>
<path d="M204.23 154.044L210.403 150.504L209.093 148.134L202.181 152.147L204.23 154.044Z" fill="#8686DF"/>
<path opacity="0.7" d="M204.23 154.044L210.403 150.504L209.093 148.134L202.181 152.147L204.23 154.044Z" fill="#263238"/>
<path d="M179.088 80.5361C179.088 80.5361 174.36 111.295 175.138 123.187C175.949 135.561 184.93 163.435 184.93 163.435L190.618 162.704C190.618 162.704 185.093 135.638 185.035 123.435C184.968 110.134 191.606 79.6475 191.606 79.6475L179.088 80.5361Z" fill="#8686DF"/>
<path d="M183.715 163.762L191.832 162.715L191.611 159.725L182.904 161.182L183.715 163.762Z" fill="#8686DF"/>
<path opacity="0.7" d="M183.715 163.762L191.832 162.715L191.611 159.725L182.904 161.182L183.715 163.762Z" fill="#263238"/>
<path d="M195.134 78.4207L195.922 79.7871C195.984 79.897 195.854 80.0164 195.667 80.0308L178.8 81.2204C178.656 81.2204 178.531 81.1679 178.517 81.0771L178.31 79.6438C178.31 79.5482 178.421 79.4575 178.579 79.4431L194.88 78.3203C194.927 78.3148 194.976 78.321 195.02 78.3385C195.064 78.356 195.104 78.3843 195.134 78.4207Z" fill="#263238"/>
<path d="M193.022 80.3712L193.459 80.3426C193.546 80.3426 193.613 80.2852 193.603 80.2327L193.32 78.4028C193.32 78.3503 193.234 78.312 193.147 78.3168L192.71 78.3455C192.624 78.3455 192.557 78.4028 192.566 78.4554L192.85 80.2852C192.859 80.3378 192.96 80.3808 193.022 80.3712Z" fill="#8686DF"/>
<path d="M182.006 81.1789L182.443 81.1455C182.53 81.1455 182.592 81.0929 182.582 81.0404L182.299 79.2105C182.299 79.1532 182.213 79.115 182.126 79.1245L181.69 79.1532C181.603 79.1532 181.536 79.2057 181.546 79.2631L181.834 81.0929C181.838 81.1455 181.92 81.1837 182.006 81.1789Z" fill="#8686DF"/>
</svg>
`
export const asmtIcon = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.2" y="0.2" width="31.6" height="31.6" rx="3.8" fill="#E5F0FF" stroke="#33A4FF" stroke-width="0.4"/>
<path d="M19.1362 11.1914C18.669 11.1965 18.2224 11.3819 17.8921 11.7078C17.5617 12.0338 17.3738 12.4744 17.3687 12.9354C17.3678 13.1646 17.4129 13.3918 17.5014 13.6037C17.5899 13.8157 17.7201 14.0083 17.8844 14.1704C18.0487 14.3325 18.2439 14.461 18.4587 14.5483C18.6736 14.6356 18.9038 14.6801 19.1362 14.6793C19.6033 14.6742 20.0499 14.4888 20.3803 14.1629C20.7106 13.8369 20.8985 13.3963 20.9037 12.9354C20.9045 12.7061 20.8594 12.4789 20.7709 12.267C20.6824 12.055 20.5522 11.8624 20.3879 11.7003C20.2236 11.5382 20.0284 11.4098 19.8136 11.3224C19.5987 11.2351 19.3685 11.1906 19.1362 11.1914Z" fill="#33A4FF"/>
<path d="M24.3195 12.7045L23.6575 12.0514C23.3918 11.8271 23.1085 11.624 22.8102 11.4439C21.7364 10.7552 20.4831 10.3887 19.2024 10.3887C17.9216 10.3887 16.6684 10.7552 15.5946 11.4439C15.2963 11.624 15.013 11.8271 14.7472 12.0514C14.5101 12.2521 14.2887 12.4705 14.0853 12.7045C14.0276 12.7671 13.9956 12.8486 13.9956 12.9331C13.9956 13.0177 14.0276 13.0992 14.0853 13.1617L14.7472 13.8149C14.9991 14.0389 15.2692 14.2421 15.5549 14.4223C15.5654 14.4223 15.5755 14.4265 15.5829 14.4338C15.5904 14.4412 15.5946 14.4511 15.5946 14.4615C16.6653 15.1572 17.9204 15.5253 19.2024 15.5196C20.4834 15.5188 21.7367 15.1512 22.8102 14.4615C22.8207 14.4615 22.8308 14.4574 22.8383 14.45C22.8457 14.4427 22.8499 14.4327 22.8499 14.4223C23.1213 14.2329 23.3861 14.0435 23.6575 13.8149C23.8947 13.6141 24.116 13.3958 24.3195 13.1617C24.3583 13.0916 24.3787 13.013 24.3787 12.9331C24.3787 12.8533 24.3583 12.7746 24.3195 12.7045V12.7045ZM23.1677 13.312C22.936 13.5014 22.6712 13.6908 22.3998 13.8802C22.3892 13.8802 22.3791 13.8843 22.3717 13.8917C22.3642 13.899 22.3601 13.909 22.3601 13.9194C21.4044 14.5435 20.2822 14.8732 19.1362 14.8665C17.9911 14.8666 16.8708 14.5375 15.9123 13.9194C15.9018 13.9194 15.8917 13.9153 15.8842 13.9079C15.8768 13.9006 15.8726 13.8906 15.8726 13.8802C15.5993 13.7148 15.3422 13.5245 15.1047 13.312C14.9468 13.2052 14.8043 13.0777 14.681 12.9331C14.8137 12.7978 14.9552 12.6713 15.1047 12.5543C15.3364 12.3649 15.6078 12.1755 15.8726 11.9861C16.8227 11.3432 17.9478 10.9992 19.0998 10.9992C20.2518 10.9992 21.3769 11.3432 22.3269 11.9861C22.6003 12.1515 22.8573 12.3417 23.0948 12.5543C23.2521 12.6595 23.3927 12.7872 23.5119 12.9331C23.429 13.0809 23.3109 13.2064 23.1677 13.2989V13.312Z" fill="#33A4FF"/>
<path d="M15.3362 10.9279C15.5191 10.7984 15.7116 10.6827 15.9121 10.5817C16.9055 10.0184 18.03 9.71905 19.1757 9.71303C20.3222 9.71389 21.448 10.0136 22.4393 10.5817L23.0152 10.9279C23.0759 10.9598 23.1404 10.984 23.2072 10.9998C23.262 11.0011 23.3161 10.9879 23.3638 10.9614C23.4116 10.935 23.4513 10.8963 23.4786 10.8495C23.5049 10.8147 23.5238 10.775 23.534 10.7329C23.5443 10.6907 23.5457 10.6469 23.5383 10.6041C23.5309 10.5613 23.5147 10.5205 23.4908 10.4841C23.4669 10.4477 23.4357 10.4165 23.3992 10.3923C23.2016 10.2544 22.996 10.1279 22.7835 10.0135C21.6807 9.40359 20.4399 9.07787 19.1757 9.06641C17.9011 9.06624 16.6493 9.39981 15.5481 10.0331C15.3346 10.1335 15.1288 10.2492 14.9324 10.3792C14.8961 10.4028 14.865 10.4334 14.8412 10.4692C14.8173 10.5051 14.8012 10.5454 14.7938 10.5876C14.7863 10.6298 14.7877 10.6731 14.7979 10.7148C14.8081 10.7565 14.8269 10.7957 14.853 10.8299C14.8728 10.8732 14.9027 10.9113 14.9403 10.941C14.9779 10.9706 15.0221 10.9911 15.0693 11.0007C15.1164 11.0102 15.1652 11.0086 15.2116 10.996C15.258 10.9833 15.3007 10.96 15.3362 10.9279Z" fill="#33A4FF"/>
<path d="M7.42561 22.4033H9.23281C9.32411 22.4033 9.41167 22.3675 9.47622 22.3038C9.54078 22.2401 9.57705 22.1538 9.57705 22.0637V16.4204C9.57793 16.3752 9.56968 16.3304 9.55277 16.2884C9.53587 16.2465 9.51064 16.2082 9.47859 16.176C9.44654 16.1438 9.4083 16.1182 9.3661 16.1007C9.3239 16.0832 9.27858 16.0742 9.23281 16.0742H7.42561C7.37984 16.0742 7.33451 16.0832 7.29231 16.1007C7.25011 16.1182 7.21188 16.1438 7.17983 16.176C7.14777 16.2082 7.12255 16.2465 7.10565 16.2884C7.08874 16.3304 7.08048 16.3752 7.08136 16.4204V22.0637C7.08136 22.1538 7.11764 22.2401 7.18219 22.3038C7.24675 22.3675 7.33431 22.4033 7.42561 22.4033Z" fill="#33A4FF"/>
<path d="M10.252 12.9803V22.0658C10.252 22.1558 10.2882 22.2422 10.3528 22.3059C10.4173 22.3696 10.5049 22.4054 10.5962 22.4054H12.3968C12.4425 22.4063 12.488 22.3981 12.5306 22.3814C12.5731 22.3648 12.6118 22.3399 12.6445 22.3083C12.6771 22.2766 12.7031 22.2389 12.7208 22.1973C12.7385 22.1556 12.7476 22.1109 12.7476 22.0658V12.9803C12.7476 12.9352 12.7385 12.8905 12.7208 12.8488C12.7031 12.8072 12.6771 12.7695 12.6445 12.7378C12.6118 12.7062 12.5731 12.6813 12.5306 12.6646C12.488 12.648 12.4425 12.6398 12.3968 12.6407H10.5962C10.5049 12.6407 10.4173 12.6765 10.3528 12.7402C10.2882 12.8039 10.252 12.8903 10.252 12.9803Z" fill="#33A4FF"/>
<path d="M13.437 16.2635V22.0635C13.437 22.1536 13.4733 22.24 13.5378 22.3037C13.6024 22.3674 13.6899 22.4032 13.7812 22.4032H15.5885C15.6798 22.4032 15.7673 22.3674 15.8319 22.3037C15.8964 22.24 15.9327 22.1536 15.9327 22.0635V16.2635C15.9327 16.1734 15.8964 16.087 15.8319 16.0233C15.7673 15.9596 15.6798 15.9238 15.5885 15.9238H13.7812C13.6942 15.9358 13.6135 15.9754 13.5514 16.0367C13.4893 16.098 13.4491 16.1776 13.437 16.2635Z" fill="#33A4FF"/>
<path d="M16.6675 18.2225V22.063C16.6675 22.1531 16.7038 22.2395 16.7683 22.3032C16.8329 22.3669 16.9204 22.4027 17.0117 22.4027H18.8189C18.9102 22.4027 18.9978 22.3669 19.0623 22.3032C19.1269 22.2395 19.1632 22.1531 19.1632 22.063V18.2225C19.1632 18.1324 19.1269 18.046 19.0623 17.9823C18.9978 17.9186 18.9102 17.8828 18.8189 17.8828H17.0117C16.9247 17.8948 16.844 17.9344 16.7819 17.9957C16.7198 18.057 16.6796 18.1366 16.6675 18.2225Z" fill="#33A4FF"/>
</svg>
`
export const lossIcon=`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.2" y="0.2" width="31.6" height="31.6" rx="3.8" fill="#E5F0FF" stroke="#33A4FF" stroke-width="0.4"/>
<path d="M12.1079 18.7972H13.6764C13.8333 18.7972 13.964 18.6683 13.964 18.5135V13.5611C13.964 13.4063 13.8333 13.2773 13.6764 13.2773H12.1079C11.951 13.2773 11.8203 13.4063 11.8203 13.5611V18.5135C11.8203 18.6683 11.951 18.7972 12.1079 18.7972Z" fill="#33A4FF"/>
<path d="M14.5652 12.2701V18.5122C14.5652 18.667 14.6959 18.7959 14.8528 18.7959H16.4213C16.5782 18.7959 16.7089 18.667 16.7089 18.5122V12.2701C16.7089 12.1153 16.5782 11.9863 16.4213 11.9863H14.8528C14.6959 11.9863 14.5652 12.1153 14.5652 12.2701Z" fill="#33A4FF"/>
<path d="M17.1274 13.4322V18.5136C17.1274 18.6683 17.2582 18.7973 17.415 18.7973H18.9836C19.1404 18.7973 19.2711 18.6683 19.2711 18.5136V13.4322C19.2711 13.2774 19.1404 13.1484 18.9836 13.1484H17.415C17.2582 13.1484 17.1274 13.2774 17.1274 13.4322Z" fill="#33A4FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.645 22.2908C19.4104 22.2908 22.4628 19.2405 22.4628 15.4778C22.4628 11.715 19.4104 8.66469 15.645 8.66469C11.8797 8.66469 8.82726 11.715 8.82726 15.4778C8.82726 19.2405 11.8797 22.2908 15.645 22.2908ZM15.645 22.9555C19.7777 22.9555 23.1279 19.6076 23.1279 15.4778C23.1279 11.3479 19.7777 8 15.645 8C11.5123 8 8.16211 11.3479 8.16211 15.4778C8.16211 19.6076 11.5123 22.9555 15.645 22.9555Z" fill="#33A4FF"/>
<rect width="0.690863" height="4.42463" rx="0.345432" transform="matrix(0.707351 -0.706863 0.707351 0.706863 20.1348 20.8711)" fill="#33A4FF"/>
</svg>
`
export const roadmapIcon=`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.2" y="0.2" width="31.6" height="31.6" rx="3.8" fill="#F9F0ED" stroke="#FDA982" stroke-width="0.4"/>
<path d="M22.4425 17.7337C22.6489 17.7356 22.8517 17.6818 23.0281 17.5784C23.2044 17.4751 23.3472 17.3263 23.4403 17.1488C24.6116 15.1854 25.9999 12.6371 25.9999 11.4256C25.9999 10.5171 25.6251 9.64576 24.958 9.00334C24.2908 8.36091 23.386 8 22.4425 8C21.499 8 20.5941 8.36091 19.927 9.00334C19.2598 9.64576 18.885 10.5171 18.885 11.4256C18.885 12.6371 20.2733 15.1854 21.4446 17.1488C21.5377 17.3263 21.6805 17.4751 21.8569 17.5784C22.0332 17.6818 22.236 17.7356 22.4425 17.7337Z" fill="#F5845E"/>
<path d="M9.55726 16.1035C9.23121 16.1035 8.91248 16.1966 8.64137 16.3711C8.37027 16.5455 8.15896 16.7934 8.03418 17.0835C7.90941 17.3736 7.87676 17.6928 7.94037 18.0007C8.00398 18.3086 8.16099 18.5915 8.39155 18.8135C8.62211 19.0355 8.91585 19.1867 9.23564 19.248C9.55543 19.3092 9.88692 19.2778 10.1882 19.1576C10.4894 19.0375 10.7469 18.834 10.928 18.573C11.1091 18.3119 11.2058 18.005 11.2058 17.691C11.2058 17.27 11.0322 16.8662 10.723 16.5685C10.4138 16.2708 9.99449 16.1035 9.55726 16.1035ZM9.55726 18.443C9.40282 18.443 9.25184 18.3989 9.12342 18.3162C8.99501 18.2336 8.89491 18.1162 8.83581 17.9787C8.7767 17.8413 8.76123 17.6902 8.79137 17.5443C8.8215 17.3984 8.89587 17.2644 9.00509 17.1593C9.1143 17.0541 9.25344 16.9825 9.40492 16.9535C9.5564 16.9245 9.71341 16.9394 9.8561 16.9963C9.99879 17.0532 10.1208 17.1496 10.2066 17.2732C10.2924 17.3969 10.3382 17.5423 10.3382 17.691C10.3382 17.8904 10.2559 18.0817 10.1094 18.2227C9.963 18.3637 9.76437 18.443 9.55726 18.443Z" fill="#F5845E"/>
<path d="M9.55744 14.2676C8.61512 14.2713 7.71249 14.6334 7.04616 15.275C6.37983 15.9166 6.00381 16.7858 6 17.6932C6 18.9047 7.38827 21.453 8.55962 23.4164C8.66397 23.5847 8.8094 23.726 8.98329 23.8279C9.15717 23.9298 9.35426 23.9893 9.55744 24.0013C9.76286 23.9985 9.96376 23.9429 10.1392 23.84C10.3147 23.7371 10.4584 23.5909 10.5553 23.4164C11.7266 21.453 13.1149 18.9047 13.1149 17.6932C13.1168 17.2428 13.0261 16.7965 12.848 16.3801C12.6699 15.9637 12.4079 15.5853 12.0772 15.2668C11.7465 14.9484 11.3535 14.6961 10.9211 14.5246C10.4886 14.3531 10.0251 14.2658 9.55744 14.2676Z" fill="#F5845E"/>
<path d="M11.2972 17.5875C11.2972 17.2735 11.2005 16.9666 11.0193 16.7055C10.8382 16.4445 10.5807 16.241 10.2795 16.1208C9.97823 16.0007 9.64674 15.9693 9.32695 16.0305C9.00716 16.0918 8.71341 16.243 8.48286 16.465C8.2523 16.687 8.09529 16.9698 8.03167 17.2778C7.96806 17.5857 8.00072 17.9049 8.1255 18.195C8.25028 18.485 8.46158 18.733 8.73269 18.9074C9.00379 19.0818 9.32252 19.1749 9.64858 19.1749C10.0858 19.1749 10.5051 19.0077 10.8143 18.71C11.1235 18.4123 11.2972 18.0085 11.2972 17.5875Z" fill="#F9F0ED"/>
<path d="M22.3987 18.8608H17.0192C16.6356 18.8608 16.2678 18.7141 15.9966 18.4529C15.7254 18.1918 15.573 17.8376 15.573 17.4683C15.573 17.099 15.7254 16.7448 15.9966 16.4836C16.2678 16.2225 16.6356 16.0758 17.0192 16.0758H18.5376C18.5954 16.0788 18.6532 16.07 18.7072 16.0501C18.7613 16.0302 18.8104 15.9995 18.8513 15.9601C18.8922 15.9207 18.9241 15.8734 18.9448 15.8213C18.9655 15.7693 18.9745 15.7136 18.9714 15.658C18.9714 15.5472 18.9257 15.4409 18.8443 15.3626C18.763 15.2843 18.6526 15.2402 18.5376 15.2402H17.0192C16.3978 15.2402 15.802 15.4779 15.3626 15.901C14.9233 16.324 14.6765 16.8978 14.6765 17.4961C14.6765 18.0944 14.9233 18.6682 15.3626 19.0913C15.802 19.5143 16.3978 19.752 17.0192 19.752H22.3553C22.7389 19.752 23.1067 19.8987 23.3779 20.1599C23.6491 20.421 23.8014 20.7752 23.8014 21.1445C23.8014 21.5138 23.6491 21.868 23.3779 22.1292C23.1067 22.3903 22.7389 22.537 22.3553 22.537H12.7676C12.6525 22.537 12.5422 22.581 12.4608 22.6594C12.3795 22.7377 12.3337 22.844 12.3337 22.9548C12.3337 23.0656 12.3795 23.1718 12.4608 23.2502C12.5422 23.3285 12.6525 23.3725 12.7676 23.3725H22.3553C22.9767 23.3781 23.5748 23.1457 24.0182 22.7266C24.4616 22.3074 24.714 21.7358 24.7197 21.1375C24.7255 20.5393 24.4842 19.9633 24.0489 19.5363C23.6136 19.1093 23.02 18.8663 22.3987 18.8608V18.8608Z" fill="#F5845E"/>
<path d="M24.0972 11.5875C24.0972 11.2735 24.0005 10.9666 23.8194 10.7055C23.6382 10.4445 23.3808 10.241 23.0795 10.1208C22.7783 10.0007 22.4468 9.96925 22.127 10.0305C21.8072 10.0918 21.5135 10.243 21.2829 10.465C21.0523 10.687 20.8953 10.9698 20.8317 11.2778C20.7681 11.5857 20.8008 11.9049 20.9255 12.195C21.0503 12.485 21.2616 12.733 21.5327 12.9074C21.8038 13.0818 22.1226 13.1749 22.4486 13.1749C22.8859 13.1749 23.3052 13.0077 23.6144 12.71C23.9235 12.4123 24.0972 12.0085 24.0972 11.5875Z" fill="#F9F0ED"/>
</svg>

`
export const cartIcon=`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 2380">
<path id="Vector 37 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M0 0.648649C0 0.29041 0.282767 0 0.631579 0H3.54669C3.82852 0 4.07625 0.191779 4.15385 0.470039L5.6147 5.70811H23.3684C23.5635 5.70811 23.7476 5.8007 23.8673 5.95895C23.9869 6.1172 24.0286 6.32336 23.9803 6.51747L21.5252 16.3769C21.4537 16.664 21.2019 16.8649 20.9133 16.8649H7.88835C7.60651 16.8649 7.35879 16.6731 7.28118 16.3948L3.07062 1.2973H0.631579C0.282767 1.2973 0 1.00689 0 0.648649Z" fill="white"/>
<path id="Ellipse 34 (Stroke)" d="M10.8659 23.9998C12.3483 23.9998 13.5501 22.7656 13.5501 21.2431C13.5501 19.7206 12.3483 18.4863 10.8659 18.4863C9.3834 18.4863 8.18164 19.7206 8.18164 21.2431C8.18164 22.7656 9.3834 23.9998 10.8659 23.9998Z" fill="white"/>
<path id="Ellipse 35 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M18.3815 23.9998C19.8639 23.9998 21.0657 22.7656 21.0657 21.2431C21.0657 19.7206 19.8639 18.4863 18.3815 18.4863C16.899 18.4863 15.6973 19.7206 15.6973 21.2431C15.6973 22.7656 16.899 23.9998 18.3815 23.9998Z" fill="white"/>
</g>
</svg>
`
export const addedCartIcon =`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_44751" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_44751)">
  <path d="M10.602 16.6024L17.652 9.55244L16.252 8.15244L10.602 13.8024L7.75195 10.9524L6.35195 12.3524L10.602 16.6024ZM12.002 22.0024C10.6186 22.0024 9.31862 21.7399 8.10195 21.2149C6.88529 20.6899 5.82695 19.9774 4.92695 19.0774C4.02695 18.1774 3.31445 17.1191 2.78945 15.9024C2.26445 14.6858 2.00195 13.3858 2.00195 12.0024C2.00195 10.6191 2.26445 9.31911 2.78945 8.10244C3.31445 6.88577 4.02695 5.82744 4.92695 4.92744C5.82695 4.02744 6.88529 3.31494 8.10195 2.78994C9.31862 2.26494 10.6186 2.00244 12.002 2.00244C13.3853 2.00244 14.6853 2.26494 15.902 2.78994C17.1186 3.31494 18.177 4.02744 19.077 4.92744C19.977 5.82744 20.6895 6.88577 21.2145 8.10244C21.7395 9.31911 22.002 10.6191 22.002 12.0024C22.002 13.3858 21.7395 14.6858 21.2145 15.9024C20.6895 17.1191 19.977 18.1774 19.077 19.0774C18.177 19.9774 17.1186 20.6899 15.902 21.2149C14.6853 21.7399 13.3853 22.0024 12.002 22.0024Z" fill="white"/>
</g>
</svg>`