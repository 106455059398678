import React, { useState, useEffect } from "react";
import { RoadmapStepFullFourView } from './RoadmapStepFullFourView';
import { Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios';
import {
  LoaderPopupWrap,
  LoaderPop,
} from "./common-roadmap-style";
export const RoadmapStepFullFourCtrl = (props) => {
  const [results, setResults] = useState(false);
  const [status, setStatus] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const login = localStorage.getItem("isLoggedIn");
  const uid = user ? user.uid : 0;
  const progress = user ? user.progress : 0;
  const gid = user ? user.group_id : 0;
  const segments = window.location.pathname.slice(1).split("/");
  const plant_slug = (typeof segments[2] !== "undefined" ? segments[2] : 0);
  const sid = (typeof segments[3] !== "undefined" ? segments[3] : 0);

  if (!login) {
    return <Redirect to="/sign-in" />;
  }
  if (progress < 100 && (gid == 14 || gid == 2)) {
    return <Redirect to={`/assessment/${plant_slug}`} />;
  }

  useEffect(() => {
    const search = async () => {
      const apiUrl = `/roadmapcomplete?param=finalstep&uid=${uid}&slug=${plant_slug}&sid=${sid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults(data);
    };
    search();
  }, [status]);

  if (results) {
    return (
      <>
        <RoadmapStepFullFourView setStatus={setStatus} stepData={results} {...props} />
      </>
    );
  } else {
    return (
      <>
        <div>
          {/* <LoaderPopupWrap data-component="LoaderPopupWrap">
            <LoaderPop data-component="LoaderPop">
              <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
            </LoaderPop>
          </LoaderPopupWrap> */}
        </div>
      </>
    );
  }
}
