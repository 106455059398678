/** imported components

  * CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext ===> component from react for carousel
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/
import React from 'react';
import Modal from '@material-ui/core/Modal';
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import './walk-through.scss'
//WlakthroughView component start
export const WlakthroughView = (props) => {
    return (
        <>
            <Modal
                open={props.open}
                // onClose={props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="walk__through__modal"
            >   
                {/* main wrapper has
                    Slider
                    modal__slider__footer
                */}
                <div className="modal__slider__wrapper">
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={90}
                        totalSlides={5}
                        dragEnabled={false}
                        isPlaying={false}
                    >
                        <Slider>
                            <Slide className="slide" index={0}>
                                {/* this section has
                                    slide_item_head
                                    slide_item_body
                                */}
                                <div className="slide__item">
                                    {/* this section has image */}
                                    <header className="slide__item__head">
                                        <img src={require('../../assets/img/common-img/slider-intro-course.gif').default} alt="true" />
                                    </header>
                                    {/* this section has
                                        page count
                                        title 
                                        subheading
                                        text
                                    */}
                                    <div className="slide__item__body">
                                        <h4 className="label">1 of 5</h4>
                                        <h2 className="title">Free Intro Course</h2>
                                        <p>Hi [name], welcome to the Training tour!</p>
                                        <p>Learn the SmarterChains way! We want to quickly show you around and it will only take a minute. Get started today with our Free Introductory Course. You can skip at any time.</p>
                                    </div>
                                </div>
                            </Slide>

                            <Slide className="slide" index={1}>
                                <div className="slide__item">
                                    <header className="slide__item__head">
                                        
                                            <img src={require('../../assets/img/common-img/scale-on-demand.gif').default} alt="true" />
                                    </header>
                                    <div className="slide__item__body">
                                        <h4 className="label">2 of 5</h4>
                                        <h2 className="title">Scale On-Demand</h2>
                                        <p>Gain 360° view of Industry 4.0 and advance your technology specific understanding​ with additional trainings. Watch each introduction for Free.</p>
                                    </div>
                                </div>
                            </Slide>
                            <Slide className="slide" index={2}>
                                <div className="slide__item">
                                    <header className="slide__item__head">
                                            <img src={require('../../assets/img/common-img/qna.gif').default} alt="true" />
                                    </header>
                                    <div className="slide__item__body">
                                        <h4 className="label">3 of 5</h4>
                                        <h2 className="title">Q&A</h2>
                                        <p>Ask questions, network with peers and let our in-house experts and the community interact with you. Stay connected and come back to participate in the Q&A community and join the discussion.</p>
                                    </div>
                                </div>
                            </Slide>

                            <Slide className="slide" index={3}>
                                <div className="slide__item">
                                    <header className="slide__item__head">
                                            <img src={require('../../assets/img/common-img/integrated-quiz.gif').default} alt="true" />
                                    </header>
                                    <div className="slide__item__body">
                                        <h4 className="label">4 of 5</h4>
                                        <h2 className="title">Integrated Quizzes</h2>
                                        <p>Get practical with our integrated quizzes that foster long-term knowledge retention. We gamified the experience so that you don’t only answer questions, but you have fun too.</p>
                                    </div>
                                </div>
                            </Slide>
                            <Slide className="slide" index={4}>
                                <div className="slide__item">
                                    <header className="slide__item__head">
                                            <img src={require('../../assets/img/common-img/congrats-completed.gif').default} alt="true" />
                                    </header>
                                    <div className="slide__item__body">
                                        <h4 className="label">5 of 5</h4>
                                        <h2 className="title">Get Certified</h2>
                                        <p>Receive a digital certification following the successful completion of each Training Course and showcase your Industry 4.0 readiness.</p>
                                    </div>
                                </div>
                            </Slide>
                        </Slider>
                        {/* this section has
                            dots__section
                            btn__section
                        */}
                        <div className="modal__slider__footer">
                            <div className="dots__section">
                                <Dot slide={0} className="dot"></Dot>
                                <Dot slide={1} className="dot"></Dot>
                                <Dot slide={2} className="dot"></Dot>
                                <Dot slide={3} className="dot"></Dot>
                                <Dot slide={4} className="dot"></Dot>
                            </div>
                            <div className="btn__section">
                                <ButtonBack className="btn__prv">
                                    Previous
                                </ButtonBack>
                                <ButtonNext className="btn__nxt">
                                    Next
                                </ButtonNext>
                            </div>
                        </div>
                    </CarouselProvider>
                </div>
            </Modal>
        </>
    )
}