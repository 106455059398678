/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api

**/

import React from "react";
import serviceProvider from '../../services/axios'
import { useEffect, useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LossAnalysisPagePlantView } from "./loss-analysis-page-plant-view";
import {Helmet} from 'react-helmet';

//LossAnalysisPagePlantController start
export const LossAnalysisPagePlantController = (props) => {
  
 	 const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	const slug = getLastItem(window.location.pathname);
  
 	 const [results, setResults] = useState([]);
 	 const user = JSON.parse(localStorage.getItem("userData"));
	 const usid = user ? user.uid : 0;
 
	  useEffect(() => {
			const search = async () => {
				const apiUrl = `/businesscaseplantloss?param=usersection&slug=${slug}&uid=${usid}`;
				const { data } = await serviceProvider.get(apiUrl,true,{});
				setResults(data);
			};
			search();
		}, []);

  
  
  
if (results.length) {
		
		if(results[0].unassign == 1){
			
			return <Redirect to="/user-unassign" />;
			return (
					<>
						<div></div>
					</>
			);
		}else{
			return(
				 	<>
					  {/* Page Title */}
					  <Helmet>
						<title>Loss Analysis | SmarterChains</title>
						<meta name="description" content="Loss Analysis" />
					  </Helmet>
					  {/* Breadcrumbs */}
						<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
						  <BreadcrumbsItem to="#">Loss Analysis</BreadcrumbsItem>
					  {/* Custom view component */}
					  <LossAnalysisPagePlantView resultcat={results}  slug={slug} {...props} />    
					</>
			);
			
		}	
	}
	else{
		return (
			<>
				<div></div>
			</>
		);
		
	}

} 




