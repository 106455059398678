/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { ResultInviteView } from "./resultinvite-view";
// ResultInviteController start
export const ResultInviteController = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const [refresh, setRefresh] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.invited_by : 0;
	useEffect(() => {
		props.handleExpandFal();
		serviceProvider.post("/home?type=setfirstresultseen", JSON.stringify({ uid: usid }),true).then((response) => {
			// console.log(response);
		});
		const search = async () => {
			const apiUrl = `/results?param=result&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, []);
	
	
	return <ResultInviteView resultsData={results} {...props} />;
};
