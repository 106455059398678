/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useEffect } from 'react';
import serviceProvider from '../../services/axios'
//LossAnalysisSetupWalkthrough component start
export const LossAnalysisSetupWalkthrough = (props) => {
    //defining variables here
    const { activeSlide, setActiveSlide, setProductionData1 } = props;
    const user = JSON.parse(localStorage.getItem("userData"));
    const UserName = user ? user.name : "First Name";
    const uid = props.uid;
    //Function to close walkthrough
    const handleClose = (val) => {
        setActiveSlide(null);
        if (val == 1) {
            serviceProvider.post("/home?type=loss_setup1", JSON.stringify({ uid: uid }), true).then((response) => { });
        }
        if (val == 2) {
            serviceProvider.post("/home?type=loss_setup1", JSON.stringify({ uid: uid }), true).then((response) => { });
        }
        if (val == 3) {
            serviceProvider.post("/home?type=loss_setup2", JSON.stringify({ uid: uid }), true).then((response) => { });
        }
        if (val == 4) {
            serviceProvider.post("/home?type=loss_setup3", JSON.stringify({ uid: uid }), true).then((response) => { });
        }
    }
    //Conditions for add and remove class as per requirement of walkthrough
    useEffect(() => {
        if (activeSlide === 1 && props.loss_setup1 == 0) {
            // setProductionData1([{},{},{}]);
        }
        if (activeSlide === 2 && props.loss_setup1 == 0) {
            document.querySelector('.prod-1').classList.add('div-z99');
            document.querySelector('.bnt-op22').style.opacity = '0.6';
        }
        if (activeSlide === 3 && props.loss_setup2 == 0) {
            document.querySelector('.prod-2').classList.add('div-z99');
        }
        if (activeSlide === 4 && props.loss_setup3 == 0) {
            document.querySelector('.prod-3').classList.add('div-z99');
        }
        if (activeSlide === 1 && props.loss_setup1 != 0) {
            handleClose();
        } else if (activeSlide === 2 && props.loss_setup1 != 0) {
            handleClose();
        } else if (activeSlide === 3 && props.loss_setup2 != 0) {
            handleClose();
        } else if (activeSlide === 4 && props.loss_setup3 != 0) {
            handleClose();
        }
    }, [activeSlide]);
    useEffect(() => {
        // cleanup function----
        return () => {
            const Divz99 = document.querySelectorAll('div-z99');
            Divz99.forEach((item, index) => {
                item.classList.remove('div-z99');
            })
            // setProductionData1([]);
            handleClose();
        }
    }, []);


    return (
        <>
            {/* link css */}
            <link rel="stylesheet" href={require('./loss-analysis-setup-walkthrough.scss').default} />
            {/* link css end*/}
            <div className={`la-walkthrough-setup  j-center a-center ${activeSlide == 1 && "blur"}`}>
                {props.loss_setup1 == 0 &&
                    <ShowContent active={activeSlide} item={1}>
                        {/* this card has three parts
                                        close
                                        left-frame
                                        right-frame
                                    */}
                        <div active={activeSlide} className="la-wlakthrough-content la-content1">
                            {/* Close button to close walkthrough */}
                            <button className="close" onClick={() => handleClose(1)}>
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/close-btn.svg').default} />
                            </button>
                            {/* left frame has image */}
                            <div className="left-frame">
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/loss-setup-step1.gif').default} />
                            </div>
                            {/* right-frame has two parts
                                            rgt-flex
                                            btn-blue
                                        */}
                            <div className="right-frame">
                                {/* this section has two parts
                                                heading
                                                content 
                                             */}
                                <div className="rgt-flex">
                                    <h1 className="h1">
                                        Hi <span style={{ textTransform: 'capitalize' }}>{UserName}</span>,
                                    </h1>
                                    <p>
                                        Welcome to the  IR4.0 Loss Analysis Setup page.
                                    </p>
                                    <p>
                                        You need to provide some basic information about your plant, so we can set up the data collection.
                                    </p>
                                </div>
                                {/* this section has button */}
                                <button className="btn-blue" onClick={() => setActiveSlide(2)}>
                                    Let’s go
                                </button>
                            </div>
                        </div>
                        {/* la-wlakthrough-content end 1*/}
                    </ShowContent>
                }

                {props.loss_setup1 == 0 &&
                    <ShowContent active={activeSlide} item={2}>
                        <div className="la-wlakthrough-content la-content2">
                            <button className="close" onClick={() => handleClose(2)}>
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/close-btn.svg').default} />
                            </button>
                            <div className="left-frame">
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/loss-setup-step2.gif').default} />
                            </div>
                            <div className="right-frame">
                                <div className="rgt-flex">
                                    <h1 className="h1">
                                        Production Processes
                                    </h1>

                                    <p>
                                        Please provide the main production areas of the plant along with their number of lines/modules.
                                    </p>
                                </div>
                                <button className="btn-blue" onClick={() => handleClose(2)}>
                                    Got it
                                </button>
                            </div>
                        </div>
                        {/* la-wlakthrough-content end 2*/}
                    </ShowContent>
                }
                {props.loss_setup2 == 0 &&
                    <ShowContent active={activeSlide} item={3}>
                        <div className="la-wlakthrough-content la-content3">
                            <button className="close" onClick={() => handleClose(3)}>
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/close-btn.svg').default} />
                            </button>
                            <div className="left-frame">
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/loss-setup-step3.gif').default} />
                            </div>
                            <div className="right-frame">
                                <div className="rgt-flex">
                                    <h1 className="h1">
                                        Unit of Measurement
                                    </h1>

                                    <p>
                                        Please provide the units of measurement that you use for internal reporting.
                                    </p>
                                </div>
                                <button className="btn-blue" onClick={() => handleClose(3)}>
                                    Got it
                                </button>
                            </div>
                        </div>
                        {/* la-wlakthrough-content end 3 */}
                    </ShowContent>
                }

                {props.loss_setup3 == 0 &&
                    <ShowContent active={activeSlide} item={4}>
                        <div className="la-wlakthrough-content la-content3">
                            <button className="close" onClick={() => handleClose(4)}>
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/close-btn.svg').default} />
                            </button>
                            <div className="left-frame">
                                <img src={require('../../assets/img/loss-analysis-setup-walkthrough/loss-setup-step4.gif').default} />
                            </div>
                            <div className="right-frame">
                                <div className="rgt-flex">
                                    <h1 className="h1">
                                        Fiscal Year
                                    </h1>

                                    <p>
                                        Please choose the full fiscal year you would like to assess.
                                    </p>
                                    <p>​You are all set.</p>
                                </div>
                                <button className="btn-blue" onClick={() => handleClose(4)}>
                                    Got it
                                </button>
                            </div>
                        </div>
                        {/* la-wlakthrough-content end 4 */}
                    </ShowContent>
                }
            </div>
            {/* la-walkthrough-setup end 1 */}

        </>
    )
}

//Function to show content
const ShowContent = (props) => {
    return (
        <>
            {props.active === props.item &&
                <>
                    {props.children}
                </>
            }
        </>
    )
}
