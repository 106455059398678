/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * Collapse ===> component from material ui
  * Plyr ===> third party plugin
  * Modal ===> component from matrial UI used to work as popup

**/

import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import { Collapse, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./quickstart.scss";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Onboarding } from "./onboarding";
import { apiEndpoints } from '../../services/api-endpoints'
//QuickStartView component start
export const QuickStartView = (props) => {
	//defining variables here
	const homeDataItems = props.homeData;
	if (!homeDataItems.length) {
		return null;
	}
	const homeDataItem = props.homeData[0];
	const uid = homeDataItem.uid;
	const [open, setOpen] = useState(homeDataItem.is_open_tour);
	const [isRightSec, setIsRightSec] = useState(false);
	const [isvideoPopup, setIsvideoPopup] = useState(false);
	const [isOnboarding, setIsOnboarding] = useState(homeDataItem.is_first_login == 1 ? true : false);
	//Function to handle onboarding
	const HandleOnboarding = () => {
		setIsOnboarding(!isOnboarding);
		serviceProvider.post("/home?type=setplatform", JSON.stringify({ uid: uid }),true).then((response) => {
			props.onTourComplete("platform");
		});
	};
	//Function to handle video popup
	const HandleVideoPopup = () => {
		setIsvideoPopup(!isvideoPopup);
		serviceProvider.post("/home?type=setindustry", JSON.stringify({ uid: uid }),true).then((response) => {
			props.onTourComplete("industry");
		});
	};
	//Function for open state
	const HandleOpenState = (index) => {
		
		if (open === index) {
			setOpen(null);
		} else {
			setOpen(index);
		}
	};
	//Function to handle right section
	const HandleRightSec = (e) => {
		if(e==="close"){
			setIsRightSec(false);
		}else{
				setIsRightSec(!isRightSec);
		}
		
	};

	useEffect(() => {
		
		if(props.isquickstart)
		{
			setIsRightSec(true);
		}
		}, []);
	return (
		<>
			<section className={isRightSec ? "home-index-right active" : "home-index-right"}>
				{/* card has
					header
					section
				*/}
				<div className="card">
					{/* header has been divided into
						heading
						progress
						close button
					*/}
					<header>
						<h2 className="h1">SmarterChains Quickstart</h2>
						<Progress value={homeDataItem.tourpercentage} />
						<button className="close-btn" onClick={()=>HandleRightSec("close")}>
							<img src={require("../../assets/img/common-img/close-icon-gray.svg").default} alt="close" />
						</button>
					</header>
					<section>
						<ListCard
							index={1}
							HandleOpenState={HandleOpenState}
							open={open}
							isCompleted={homeDataItem.platform_tour == 1 ? true : false}
							headerTitle="Watch platform tour"
						>	
							{/* this section has
								content
								card
							*/}
							<div className="list-card-content type_1">
								<p>Accelerate your Industry 4.0 capabilities! Learn more about SmarterChains platform in this quick tour.</p>
								{/* section has been divided into
									type-1-col-1
									type-1-col-2
								*/}
								<div className="type-1-card" onClick={HandleOnboarding}>
									{/* this section has image */}
									<div className="type-1-col-1 quikstart-1">
										<img src={require("../../assets/img/home-index/quikstart-1.svg").default} alt="true" />
									</div>
									{/* this section has
										top-content
										bottom-content
									*/}
									<div className="type-1-col-2">
										{/* this section has text */}
										<div className="top_content">
											<p>Learn more about SmarterChains Platform</p>
										</div>
										{/* this section has button */}
										<div className="bottom_content">
											{/* <button>+ Watch platform tour</button> */}
										</div>
									</div>
								</div>
							</div>
						</ListCard>
						<ListCard
							index={2}
							HandleOpenState={HandleOpenState}
							open={open}
							isCompleted={homeDataItem.industry_tour == 1 ? true : false}
							headerTitle="Learn about Industry 4.0 "
						>
							<div className="list-card-content type_1" onClick={HandleVideoPopup}>
								<p className="lai40-text">
									Check SmarterChains CEO teaching<span className="b"> key Industry 4.0 insights</span> to IMD Digital Supply Chain,
									one of the world's top digital Supply Chain Executive courses.
								</p>
								<button className="lai40-btn">
									<img src={require("../../assets/img/home-index/quikstart-2.svg").default} alt="true" />
								</button>
							</div>
						</ListCard>
						<ListCard
							index={3}
							HandleOpenState={HandleOpenState}
							open={open}
							isCompleted={homeDataItem.training_tour == 1 ? true : false}
							headerTitle="Learn about Industry 4.0 Training Courses"
						>
							<div className="list-card-content type_1">
								<p>Learn the SmarterChains way! Learn more about our Industry 4.0 Training Courses in this product tour.</p>
								<Link to="/training-walkthrough" className="type-1-card">
									<div className="type-1-col-1 quikstart-3">
										<img src={require("../../assets/img/home-index/quikstart-3.svg").default} alt="true" />
									</div>
									<div className="type-1-col-2">
										<div className="top_content">
											<p className="dull">educate</p>
										</div>
										<div className="bottom_content">
											<p>Industry 4.0 Training Courses</p>
											{/* <button>+ Watch product tour</button> */}
										</div>
									</div>
								</Link>
							</div>
						</ListCard>
						<ListCard
							index={4}
							HandleOpenState={HandleOpenState}
							open={open}
							isCompleted={homeDataItem.assessment_tour == 1 ? true : false}
							headerTitle="Learn about Digital Maturity Assessment"
						>
							<div className="list-card-content type_1">
								<p>See how your plant scores! Learn more about our Digital Maturity Assessment in this product tour.</p>

								<Link to="/assessment-tour" className="type-1-card">
									<div className="type-1-col-1 quikstart-4">
										<img src={require("../../assets/img/home-index/quikstart-4.svg").default} alt="true" />
									</div>
									<div className="type-1-col-2">
										<div className="top_content">
											<p className="dull">prepare</p>
										</div>
										<div className="bottom_content">
											<p>Digital Maturity Assessment</p>
											{/* <button>+ Watch product tour</button> */}
										</div>
									</div>
								</Link>
							</div>
						</ListCard>
						<ListCard
							index={5}
							HandleOpenState={HandleOpenState}
							open={open}
							isCompleted={homeDataItem.business_tour == 1 ? true : false}
							headerTitle="Learn about Loss Analysis"
						>
							<div className="list-card-content type_1">
								<p>Identify and track losses! Learn more about our Industry 4.0 Loss Analysis in this product tour.</p>
								<Link to="/loss-analysis-tour" className="type-1-card">
									<div className="type-1-col-1 quikstart-4">
										<img src={require("../../assets/img/home-index/quikstart-5.svg").default} alt="true" />
									</div>
									<div className="type-1-col-2">
										<div className="top_content">
											<p className="dull">prepare</p>
										</div>
										<div className="bottom_content">
											<p>Industry 4.0 Loss Analysis</p>
											{/* <button>+ Watch product tour</button> */}
										</div>
									</div>
								</Link>
							</div>
						</ListCard>
					</section>
				</div>
			</section>
			<button className={!isRightSec ? "quick-start-btn active" : "quick-start-btn"} onClick={HandleRightSec}>
				<img src={require("../../assets/img/home-index/btn-quick-start.svg").default} alt="true" />
				<span>Quickstart</span>
			</button>
			{/*home index main-course-page end*/}
			{/* video popup for quickstart  */}
			<Modal
				open={isvideoPopup}
				onClose={HandleVideoPopup}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="quick-start-vid-popup"
			>
				<div className="vid__wrapper custom_vid__wrapper">
					<Plyr
						source={{
							type: "video",
							sources: [
								{
									src: `${apiEndpoints.baseUrl}/assets/videos/intro.mp4`,
									type: "video/mp4",
								},
							],
						}}
						options={{
							controlshidden: true,
						}}
					/>
				</div>
			</Modal>
			{/*quick-start-vid-popup end*/}

			{/*<Onboarding isOnboarding={isOnboarding} HandleOnboarding={HandleOnboarding} uid={uid} />*/}
		</>
	);
};
//progress component
const Progress = ({ value }) => {
	return (
		<div className="progress__wrapper">
			<div className="progress_status" style={{ width: `${value < 100 ? value : value + 0.5}%` }}></div>
		</div>
	);
};
//ListCard component
const ListCard = (props) => {
	const { open, index, isCompleted, headerTitle, children, HeaderComponent, HandleOpenState } = props;
	return (
		<div className="list-card">
			<div className={open === index ? "header active" : "header"} onClick={() => HandleOpenState(index)}>
				{!HeaderComponent ? (
					/*	this section has been divided into
						col-1
						col-2
					*/ 
					<div className="header-row">
						{/* this section has image */}
						<div className="col_1">
							{isCompleted ? (
								<img src={require("../../assets/img/home-index/completed-chk.svg").default} alt="true" />
							) : (
								<img src={require("../../assets/img/home-index/uncompleted-chk.svg").default} alt="true" />
							)}
						</div>
						{/* this section has
							heading
							dropdown button
						*/}
						<div className="col_2">
							<p className="h1">{headerTitle}</p>
							<span className="ddl-btn">
								<img src={require("../../assets/img/home-index/ddl-arrow.svg").default} alt="true" />
							</span>
						</div>
					</div>
				) : (
					<HeaderComponent />
				)}
			</div>
			{/* header end */}
			<Collapse in={props.open == props.index}>
				<>{children}</>
			</Collapse>
		</div>
	);
};
