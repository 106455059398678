import React, { useState, useEffect } from 'react';
import {
    MainWrapper,
    HowToUseBtn,
    HowToUsePopWrapper,
    HowToUsePopWrap,
    HowToUsePopHeader,
    HowCloseBtn,
    HowToUsePopBody,
    TabHead,TabWrap,
    TabContentWrap,
    TabRow,
    TabFirstCol,
    TabCol,
    NumWrap,
    LeftNumCol,
    RightTextCol,
    
} from './styles';
import {
    closeIcon,howIcon,plannedSvg,
} from './svg';

export const HowToUseView = () => {
    const[openPop,setOpenPop]=useState(false)
    const colData=[
        {
            id: 1,
            paragraph_first: `Factory of the Future Leadership`,
            paragraph_scnd:[`<b>Eager to explore</b>`, `Just <b>starting</b> Industry 4.0 journey`],
            paragraph_third: [`<b>Expanded</b> with Industry 4.0`,  `<b>Roles & budgets</b> are clearly <b>allocated</b>.`],
        },
        {
            id: 2,
            paragraph_first: `Digital Organization`,
            paragraph_scnd:[`<b>Standard Operational Excellence </b>culture`, `<b>Limited awareness </b> on Industry 4.0`],
            paragraph_third: [`<b>Centers of Excellence</b>`,  `Industry 4.0 <b>roadmap</b>`],
        },
        {
            id: 3,
            paragraph_first: `Data-Driven Maintenance`,
            paragraph_scnd:[`<b>Maintenance</b> based on <b>experience & OEMs instructions</b>`],
            paragraph_third: [`Early stages of <b>predictive maintenance</b>`],
        },
        {
            id: 4,
            paragraph_first: `Sustainability`,
            paragraph_scnd:[`<b>Limited integrations</b> between systems & technology`],
            paragraph_third: [`<b>All</b> systems <b>stream data</b> to a central data <b>repository</b>`],
        },
        {
            id: 5,
            paragraph_first: `Automated Material Flow`,
            paragraph_scnd:[`<b>Forklifts & pallet jacks</b> for material movements`],
            paragraph_third: [`<b>Automated</b> movements`,  `<b>WMS</b> coordinates the flow`],
        },
        {
            id: 6,
            paragraph_first: `Digital-ready Infrastructure`,
            paragraph_scnd:[`<b>Standard internet</b> connectivity`,`<b>Firewalls, VPNs</b>`],
            paragraph_third: [`<b>Industrial internet</b>`,`Cybersecurity <b>platform</b>`],
        },
        {
            id: 7,
            paragraph_first: `360° Factory Visibility`,
            paragraph_scnd:[`<b>Standard ERP</b>`, `<b>Few systems</b> for real-time operational visibility`],
            paragraph_third: [`<b>Cloud based ERP</b>`,  `<b>Many systems</b> for visibility of the plant`],
        },
        {
            id: 8,
            paragraph_first: `Intelligent Quality`,
            paragraph_scnd:[`<b>Machine vision systems</b> in production lines.`, `<b>LIMS</b> for quality operations`],
            paragraph_third: [`<b>Real-time</b> quality control`,  `<b>Automated</b> inspection systems`],
        },
        {
            id: 9,
            paragraph_first: `Tech-Augmented Workforce`,
            paragraph_scnd:[`Shopfloor <b>monitors</b>`, `<b>Visual & auditory</b> assistance`],
            paragraph_third: [`<b>Handhelds smart glasses</b>`,  `<b>VR training</b> for the workforce`],
        },
        {
            id: 10,
            paragraph_first: `Zero Touches Production`,
            paragraph_scnd:[`<b>Semi-automated</b> production`, `<b>SCADA & PLCs</b>`,`<b>Limited MES</b> capabilities`],
            paragraph_third: [`<b>Automated</b> production`,  `<b>Real-time</b> dashboards`, `<b>Extensive MES</b> capabilities`],
        },
    ]
    
    return (
        <>
            <MainWrapper data-component="MainWrapper">
               <HowToUseBtn data-component="HowToUseBtn" onClick={()=>setOpenPop(true)}>
                    <span className='how-txt'>How to use</span>
                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: howIcon }}></i>
               </HowToUseBtn>
               {openPop?
                <HowToUsePopWrapper data-component="HowToUsePopWrapper">
                        <HowToUsePopWrap data-component="HowToUsePopWrap">
                            <HowToUsePopHeader data-component="HowToUsePopHeader">
                                <span className='head-txt'>How to use IR4.0 Technology Assessment</span>
                                <HowCloseBtn data-component="HowCloseBtn" onClick={()=>setOpenPop(false)}>
                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                </HowCloseBtn>
                            </HowToUsePopHeader>
                            <HowToUsePopBody data-component="HowToUsePopBody">
                                <TabHead component="TabHead">
                                    <TabWrap component="TabWrap">IR4.0 Technology Insights</TabWrap>
                                </TabHead>
                                <TabContentWrap data-component="TabContentWrap">
                                    <TabRow data-component="TabRow"> 
                                        <TabFirstCol data-component="TabFirstCol" blue>
                                            <div className="card-data">
                                                <span className='heading'>Maturity Assessment</span>
                                                <span className='text'>IR 4.0 Maturity Sections</span>
                                            </div>
                                        </TabFirstCol>
                                        <TabFirstCol data-component="TabFirstCol" orange>
                                            <div className="card-data">
                                                <span className='heading'>Average Plant</span>
                                                <span className='text'>Maturity Index: 33%</span>
                                            </div>
                                        </TabFirstCol>
                                        <TabFirstCol data-component="TabFirstCol" green>
                                            <div className='card-data'>
                                                <span className='heading'>Best In Class Plant</span>
                                                <span className='text'>Maturity Index: 67%</span>
                                            </div>
                                        </TabFirstCol>
                                    </TabRow>
                                    {colData.map((item, index) => (
                                    <TabRow data-component="TabRow"> 
                                        <TabCol data-component="TabCol">
                                            <NumWrap data-component="NumWrap">
                                                <LeftNumCol data-component="LeftNumCol">{item.id}</LeftNumCol>
                                                <RightTextCol data-component="RightTextCol">{item.paragraph_first}</RightTextCol>
                                            </NumWrap>
                                        </TabCol>
                                        <TabCol data-component="TabCol">
                                            <div className='para'>
                                                {item.paragraph_scnd.map(p=>( <p dangerouslySetInnerHTML={{__html:p}}></p>))}  
                                            </div>
                                        </TabCol>
                                        <TabCol data-component="TabCol">
                                            <div className='para'>
                                                {item.paragraph_third.map(p=>( <p dangerouslySetInnerHTML={{__html:p}}></p>))}  
                                            </div>
                                        </TabCol>
                                    </TabRow>
                                    ))}
                                </TabContentWrap>
                            </HowToUsePopBody>
                        </HowToUsePopWrap>
                </HowToUsePopWrapper>
                :''}
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

