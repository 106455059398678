

/** imported components

    * ./svg => imported for all svg elements  
    * ./styles => imported for using of all created styled components.
    * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React, { useState, useEffect } from 'react';
import serviceProvider from "../../services/axios";
import {
    WalkthroughContainer, WalkthroughWrapper,
    WalkthroughCard,
    WalkSection,
    WalkTopRow,
    WalkTopLeftCol, WalkTopRightCol, WalkMidRow,
    WalkFooter, WalkFooterLeftCol, WalkFooterRightCol,
    DotsSection, ButtonWrapper

} from './walk-styles';


export const TaskCenterWalkthroughView = (props) => {
    //defining variables here
    const users = JSON.parse(localStorage.getItem("userData"));
    const group_id = users ? users.group_id : ''; /*if 14 it's simple user else 2 or 11 for admin*/
    const UserName = users ? users.name : "First Name";
    const trainingWrap = document.querySelector('.training-main-wrapper');
    // Walkthrough-data
    const adminData = [
        {
            title: `Welcome ${UserName},`,
            paragraph: `This is your task center page where you can easily check and keep track of the tasks you have been assigned for you to do`,
        },
        {
            title: `My Plants`,
            paragraph: `You can find all the Plants you have been assigned to provide your input on the various strategy tools. You can start/resume activities from here.`,
        },
        {
            title: `Filter`,
            paragraph: `You can filter the actions needed from your end on the basis of status and plants.`,
        },

    ];
    const userDataWithPlant = [
        {
            title: `Welcome ${UserName},`,
            paragraph: `This is your task center page where you can easily check and keep track of the tasks you have been assigned for you to do`,
        },
        {
            title: `My Plants`,
            paragraph: `You can find all the Plants you have been assigned to provide your input on the various strategy tools. You can start/resume activities from here.`,
        },
        {
            title: `Filter`,
            paragraph: `You can filter the actions needed from your end on the basis of status and plants.`,
        },

    ];
    const userDataWithoutPlant = [
        {
            title: `Welcome  ${UserName},`,
            paragraph: `This is your task center page where you can easily check and keep track of the tasks you have been assigned for you to do`,
        },
        {
            title: `Filter`,
            paragraph: `You can filter the actions needed from your end on the basis of status and plants.`,
        },

    ];
    const walkthroughData = (group_id == 14 && props.myPlantLength > 0) ? userDataWithPlant : group_id == 14 ? userDataWithoutPlant : adminData;
    const totalSlides = walkthroughData.length;
    // walkthrough-data-end
    const { activeSlide, setActiveSlide, setShowTCWalkthrough } = props;
    // Function to navigate slider from one to next
    const HandleSlider = (ev) => {
        if (ev === "prev" && activeSlide > 0) {
            let tmpVal = activeSlide - 1;
            setActiveSlide(tmpVal);
        } else if (ev === "next" && activeSlide < totalSlides - 1) {
            let tmpVal = activeSlide + 1;
            setActiveSlide(tmpVal);
        } else if (ev !== "next" && ev !== "prev") {
            setActiveSlide(ev);
        }
    }
    const HandleClose = () => {
        let highlighted_element_list = document.querySelectorAll('.walkthrough-active');
        highlighted_element_list?.forEach((item) => {
            item.classList.remove('walkthrough-active');
        })
        setShowTCWalkthrough(false);
        trainingWrap?.classList.remove('walkthrough-active');

        serviceProvider
            .post(
                "/home?type=taskcenterwlakthrough",
                JSON.stringify({ uid: users.uid }),
                true
            )
            .then((response) => {

            });
    };
    useEffect(() => {
        console.log(activeSlide);
        const highlightarea1 = document.querySelector('.walk-1');
        const highlightarea2 = document.querySelector('.walk-2');
        const highlightarea21 = document.querySelector('.empty-walk');
        const highlightarea3 = document.querySelector('.walk-3');
        const plant_tab = document.querySelector("[data-component = Container]");
        let container2 = document.querySelector('.container-2');
        let container3 = document.querySelector('.container-3');

        if (activeSlide === 1) {
            highlightarea2?.classList.add('walkthrough-active');
            highlightarea21?.classList.add('walkthrough-active');
        }
        else {
            highlightarea2?.classList.remove('walkthrough-active');
            highlightarea21?.classList.remove('walkthrough-active');
        }
        if (activeSlide === 2) {
            highlightarea3?.classList.add('walkthrough-active');
        }
        else {
            highlightarea3?.classList.remove('walkthrough-active');
        }
        // positioning of third card
        if (activeSlide === 1 && container2) {
            plant_tab.scrollTop = highlightarea1?.getBoundingClientRect().height;
            var tab_height = highlightarea1?.getBoundingClientRect().height + highlightarea1?.getBoundingClientRect().top;
            container2.style.top = tab_height + `px`;
                // container3.style.transform = 'translateY(-90%)';
        }
        else {
            plant_tab.scrollTop = 0;
        }
    }, [activeSlide])
    useEffect(() => {
        trainingWrap?.classList.add('walkthrough-active');
        // ceanup function
        return () => {
            let highlighted_element_list = document.querySelectorAll('.walkthrough-active');
            highlighted_element_list?.forEach((item) => {
                item.classList.remove('walkthrough-active');
            })
            setShowTCWalkthrough(false);
            trainingWrap?.classList.remove('walkthrough-active');
        }
    }, []);

    return (
        <>
            {walkthroughData.map((item, index) => (
                <div key={index} style={{ display: index !== activeSlide ? "none" : "" }}>
                    <WalkthroughContainer data-component='WalkthroughContainer'>
                        <WalkthroughWrapper data-component="WalkthroughWrapper" className={`container-${index + 1}`}>
                            <WalkthroughCard data-component="WalkthroughCard" className={`task-modal-${index}`}>
                                <WalkSection data-component="WalkSection">
                                    <WalkTopRow data-component="WalkTopRow">
                                        <WalkTopLeftCol data-component="WalkTopLeftCol">
                                            <span>{index + 1} of {totalSlides}</span>
                                        </WalkTopLeftCol>
                                        <WalkTopRightCol data-component="WalkTopRightCol">
                                            <span className="img" onClick={() => HandleClose()}>
                                                <i className='close-icon' dangerouslySetInnerHTML={{ __html: crossSvg }}></i>
                                            </span>
                                        </WalkTopRightCol>
                                    </WalkTopRow>
                                    <WalkMidRow data-component="WalkMidRow">
                                        <h2>{item.title}</h2>
                                        <div className="first-txt">
                                            <p>{item.paragraph}</p>
                                        </div>
                                    </WalkMidRow>
                                    <WalkFooter data-component="WalkFooter">
                                        <WalkFooterLeftCol data-component="WalkFooterLeftCol">
                                            <DotsSection data-component="DotsSection">
                                                {walkthroughData.map((item, index) => (
                                                    <button className="circle" onClick={() => { HandleSlider(index) }} disabled={activeSlide === index ? true : false}>
                                                        <i className='close-icon' dangerouslySetInnerHTML={{ __html: circleSvg }}></i>
                                                    </button>
                                                ))}
                                            </DotsSection>
                                        </WalkFooterLeftCol>
                                        <WalkFooterRightCol data-component="WalkFooterRightCol">
                                            <ButtonWrapper data-component="ButtonWrapper">
                                                {activeSlide === 0 && <button className="btn-blue-link" onClick={HandleClose}>No thanks</button>}
                                                {activeSlide > 0 && activeSlide < totalSlides - 1 && (
                                                    <button onClick={() => HandleSlider("prev")} className="btn-blue-link">Previous</button>
                                                )}

                                                {activeSlide === 0 && (
                                                    <button className="btn-blue" onClick={() => HandleSlider("next")}>
                                                        Let’s go
                                                    </button>
                                                )}
                                                {activeSlide > 0 && activeSlide < totalSlides - 1 && (
                                                    <button className="btn-blue" onClick={() => HandleSlider("next")}>
                                                        Next
                                                    </button>
                                                )}
                                                {activeSlide === totalSlides - 1 && (
                                                    <>
                                                        <button className="btn-blue-link" onClick={() => HandleSlider(0)}>
                                                            Restart
                                                        </button>
                                                        <button className="btn-blue" onClick={HandleClose}>
                                                            Let's Start
                                                        </button>
                                                    </>
                                                )}
                                            </ButtonWrapper>
                                        </WalkFooterRightCol>
                                    </WalkFooter>
                                </WalkSection>
                            </WalkthroughCard>
                        </WalkthroughWrapper>
                    </WalkthroughContainer>
                </div>
            ))}
            {/* MainWrapper ended */}
        </>
    );
}

// Walkthrough SVG

const crossSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 7.33333C5.63181 6.96514 5.63181 6.36819 6 6C6.36819 5.63181 6.96514 5.63181 7.33333 6L18 16.6667C18.3682 17.0349 18.3682 17.6318 18 18C17.6318 18.3682 17.0349 18.3682 16.6667 18L6 7.33333Z" fill="#118ACB"/>
<path d="M6 18C5.63181 17.6318 5.63181 17.0349 6 16.6667L16.6667 6C17.0349 5.63181 17.6318 5.63181 18 6C18.3682 6.36819 18.3682 6.96514 18 7.33333L7.33333 18C6.96514 18.3682 6.36819 18.3682 6 18Z" fill="#118ACB"/>
</svg>
`
const circleSvg = `<svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
</svg>   
`

