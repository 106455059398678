/** imported files

# serviceProvider ===>from axios for api call  
# BusinessCaseView ===> custom component

**/
import React from 'react';
import serviceProvider from '../../services/axios'
import { useEffect, useState } from 'react';
import {BusinessCaseView} from './business-case-view';
export const BusinessCaseCtrl=(props)=>{
	// variable declaration 
  const segments = window.location.pathname.slice(1).split("/");
  const slug = segments[1];
  const [bresults, setVresults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
 
 //useEffect call here
    useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=bdashboard&slug=${slug}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setVresults(data);
		};
		search();
	}, []);
	
	
     return(
         <>
              <BusinessCaseView vResult={bresults} slug={slug}  {...props}/>  
         </>
     )
}