import styled from "styled-components";

export const Icon = styled.i`
       display:inline-block;
`;

export const DropdownMainWrapper = styled.div`
       width: 100%;
       color:#4D4D4F;
       font-size:1.2rem;
       position:relative;
`;

export const DropdownButton = styled.button`
       padding: 0 1.2rem 0 0.8rem;
       position: relative;
       width: 100%;
       text-align: left;
       background: transparent;
       border: 0.06rem solid #7D868C;
       border-radius: 0.4rem;
       height: 3.2rem;
       display: flex;
       align-items: center;
       span{
                overflow:hidden;
                white-space:nowrap;
                display:block;
                text-overflow:ellipsis;
            }
       ${Icon}{
          position:absolute;
          right:0.8rem;
          top:50%;
          transform:translateY(-50%);
          svg{
            width:0.9rem;
          }
       }
       &.inactive{
        visibility:hidden;
       }
`;

export const DropdownInput = styled.input`
       padding:0.8rem 1.2rem 0.8rem 0.8rem;
       position:relative;
       width:100%;
       text-align:left;
       background:#fff;
       border:0.1rem solid #7D868C;
       border-radius:0.4rem;
       outline:none;
`;

export const DropdownListButton = styled.button`
      width:100%;
      text-align:left;
      transition:0.25s;
      background:#fff;
      padding:0.8rem 0.5rem;
      border-top:0.1rem solid #7D868C;
      &:hover{
        background:#F0F0F0;
      }
      &.active{
        background:#E7EBF8;
        color:#2C55A0;
      }
`
export const DropdownListWrapper = styled.div`
       position:absolute;
       top:0rem;
       z-index:9;
       width:100%;
       border:0.1rem solid #7D868C;
       border-radius:0.4rem;
       background: #ffffff;
       visibility:hidden;
       opacity:0;
       &.active{
          visibility:visible;
          opacity:1;
       }
       ${DropdownButton}{
           border:0;
       }
`
