/** imported components

  * AssessmentView  ===> this component having view of the page/view file of this component
  * assessment.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/


import React, { useState, useEffect } from "react";
import { AssessmentView } from "./assessment-view";
import "./assessment.scss";
import serviceProvider from '../../services/axios'

//Assessment component
export const AssessmentController = (props) => {
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		const search = async () => {
			const apiUrl = `/assessment?param=section&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
		};
		search();
	}, []);
	return <AssessmentView sectionData={results} {...props} />;
};
