import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios';
//import './loss-analysis-tour.scss';
import { Modal } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from 'react-helmet';
export const LossResultTour = () => {
    const [slide2Active, setSlide2Active] = useState(0);
    const [isSlide2, setIsSlide2] = useState(false);
    const slide2Total = 4;
    const user = JSON.parse(localStorage.getItem("userData"));
    const name = user ? user.name : "";
	const usid = user ? user.uid : 0;
    const HandleSlide2 = (val) => {
        setSlide2Active(val);
    }
    const HandleSlide2NextPrev = (e) => {

        if (e === "next" && slide2Active < slide2Total - 1) {
            const tmpval = slide2Active + 1;
            setSlide2Active(tmpval);
        } else if (slide2Active > 0) {
            const tmpval = slide2Active - 1;
            setSlide2Active(tmpval);
        }

    }
    const handleClose = () => {
        setSlide2Active(-1);
        var close = document.querySelector('.ng-sidebar');
        var close1 = document.querySelector('.training-header');
        close?.classList.remove('activ');
        close1?.classList.remove('activ');
    }
    useEffect(() => {
        var close = document.querySelector('.ng-sidebar');
        var close1 = document.querySelector('.training-header');
        close?.classList.add('activ');
        close1?.classList.add('activ');
    },[])

    return (
        <>
        <Helmet>
            <link rel="stylesheet" href={require('./loss-result-tour.scss').default} />
        </Helmet>
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
        <BreadcrumbsItem to="/p1">Loss Anaysis</BreadcrumbsItem>
		<BreadcrumbsItem to="/p1">Plant Results</BreadcrumbsItem>
        <div className="lsa-tour-main">
            <div className="lsa-tour-bg1-of4" style={{
                display: slide2Active !== 0 && 'none'
            }}></div>
            <div className="lsa-tour-bg2-of4" style={{
                display: slide2Active !== 1 && 'none'
            }}></div>
            <div className="lsa-tour-bg3-of4" style={{
                display: slide2Active !== 2 && 'none'
            }}></div>
            <div className="lsa-tour-bg4-of4" style={{
                display: slide2Active !== 3 && 'none'
            }}></div>

            <Modal open={true} className="lsa-tour-modal-1">
                <>
                    
                    {/* slide 1 end */}
                    <div className={`lsa-content-1 type-2 ${slide2Active === 0 && ' active'}`} id='LsaType2Content1'>
                        <div className="las-body-1">
                            <Link onClick={handleClose} className="close-btn" to="/">
                                <img src={require("../../assets/img/loss-result-tour/result-close.svg").default} alt="Close"/>
                            </Link>
                            <h6 className="lsa-dull">
                                1 of 4
                            </h6>
                            <h3 className="lsa-title-h1">
                                Loss Results
                            </h3>
                            <p>
                                This is a demo walkthrough of the loss results.
                            </p>
                            <p>
                                On top there is an overview of the key plant figures. 
                            </p>

                        </div>
                        <div className="las-footer-1">
                            <div className="dots-sec">
                                <button disabled={
                                    slide2Active == 0 ? true : false
                                } onClick={() => HandleSlide2(0)}></button>
                                <button disabled={
                                    slide2Active == 1 ? true : false
                                } onClick={() => HandleSlide2(1)}></button>
                                <button disabled={
                                    slide2Active == 2 ? true : false
                                } onClick={() => HandleSlide2(2)}></button>
                                <button disabled={
                                    slide2Active == 3 ? true : false
                                } onClick={() => HandleSlide2(3)}></button>
                            </div>
                            <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                Previous
                            </button>
                            <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                Next
                            </button>
                        </div>
                    </div>
                    {/* slide 2 end */}
                    <div className={`lsa-content-1 type-2 ${slide2Active === 1 && ' active'}`} id='LsaType2Content2'>
                        <div className="las-body-1">
                            <Link onClick={handleClose} className="close-btn" to="/">
                                <img src={require("../../assets/img/loss-result-tour/result-close.svg").default} alt="Close"/>
                            </Link>
                            <h6 className="lsa-dull">
                                2 of 4
                            </h6>
                            <h3 className="lsa-title-h1">
                                Filters
                            </h3>
                            <p>
                                On the left side there are filters, that you can use to drill down into the data.
                            </p>

                        </div>
                        <div className="las-footer-1">
                            <div className="dots-sec">
                                <button disabled={
                                    slide2Active == 0 ? true : false
                                } onClick={() => HandleSlide2(0)}></button>
                                <button disabled={
                                    slide2Active == 1 ? true : false
                                } onClick={() => HandleSlide2(1)}></button>
                                <button disabled={
                                    slide2Active == 2 ? true : false
                                } onClick={() => HandleSlide2(2)}></button>
                                <button disabled={
                                    slide2Active == 3 ? true : false
                                } onClick={() => HandleSlide2(3)}></button>
                            </div>
                            <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                Previous
                            </button>
                            <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                Next
                            </button>
                        </div>
                    </div>
                    {/* slide 3 end */}
                    <div className={`lsa-content-1 type-2 ${slide2Active === 2 && ' active'}`} id='LsaType2Content3'>
                        <div className="las-body-1">
                            <Link onClick={handleClose} className="close-btn" to="/">
                                <img src={require("../../assets/img/loss-result-tour/result-close.svg").default} alt="Close"/>
                            </Link>
                            <h6 className="lsa-dull">
                                3 of 4
                            </h6>
                            <h3 className="lsa-title-h1">
                                Losses per Category
                            </h3>
                            <p>
                                The vertical bar chart represents the core loss buckets on an annual basis.
                            </p>
                            <p>
                                The horizontal bar chart represents the sub-losses of the loss categories on an annual basis. 
                            </p>
                        </div>
                        <div className="las-footer-1">
                            <div className="dots-sec">
                                <button disabled={
                                    slide2Active == 0 ? true : false
                                } onClick={() => HandleSlide2(0)}></button>
                                <button disabled={
                                    slide2Active == 1 ? true : false
                                } onClick={() => HandleSlide2(1)}></button>
                                <button disabled={
                                    slide2Active == 2 ? true : false
                                } onClick={() => HandleSlide2(2)}></button>
                                <button disabled={
                                    slide2Active == 3 ? true : false
                                } onClick={() => HandleSlide2(3)}></button>
                            </div>
                            <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                Previous
                            </button>
                            <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                Next
                            </button>
                        </div>
                    </div>
                    {/* slide 4 end */}
                    <div className={`lsa-content-1 type-2 ${slide2Active === 3 && ' active'}`} id='LsaType2Content4'>
                        <div className="las-body-1">
                            <Link onClick={handleClose} className="close-btn" to="/">
                                <img src={require("../../assets/img/loss-result-tour/result-close.svg").default} alt="Close"/>
                            </Link>
                            <h6 className="lsa-dull">
                                4 of 4
                            </h6>
                            <h3 className="lsa-title-h1">
                                Losses per Process & Type
                            </h3>
                            <p>
                                The stacked bar chart represents the allocation of direct losses per production process.
                            </p>
                            <p>
                                The pie chart represents the distribution of the annual losses per loss type.
                            </p>
                        </div>
                        <div className="las-footer-1">
                            <div className="dots-sec">
                                <button disabled={
                                    slide2Active == 0 ? true : false
                                } onClick={() => HandleSlide2(0)}></button>
                                <button disabled={
                                    slide2Active == 1 ? true : false
                                } onClick={() => HandleSlide2(1)}></button>
                                <button disabled={
                                    slide2Active == 2 ? true : false
                                } onClick={() => HandleSlide2(2)}></button>
                                <button disabled={
                                    slide2Active == 3 ? true : false
                                } onClick={() => HandleSlide2(3)}></button>
                            </div>
                            <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                Previous
                            </button>
                            <Link to="/" className="btn-blue-bg" onClick={handleClose}>
                                Let’s Start
                            </Link>
                        </div>
                    </div>
                </>
            </Modal>
        </div>
        </>
    );
}

