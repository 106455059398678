/** imported components

  * CourseDetailLeft ===> custom component for left
  * CourseDetailRight ===> custom component for right

**/
import { compose } from "async";
import React from "react";
import { CourseDetailLeft } from "./course-detail-left";
import { CourseDetailRight } from "./course-detail-right";
export const CourseDetailView = (props) => {
	return (
		// main wrapper
		<div className="course-detail-main">
			{/* this section has
				course-detail-left
				course-detail-right
			*/}
			<div className="course-detail-frame-wrapper">
				<div className="course-detail-left">
					<CourseDetailLeft {...props} />
				</div>
				{/* course-details-left end */}
				<div className="course-detail-right">
					<CourseDetailRight {...props} />
				</div>
				{/* course-details-right end */}
			</div>
			{/* course-detail-frame-wrapper end */}
		</div>
		// course-details-main end
	);
};
