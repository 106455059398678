import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core'
import $ from "jquery";
import serviceProvider from '../../services/axios'
import { apiEndpoints } from '../../services/api-endpoints'
import { findIndex } from 'lodash';
import { CreateAccountPopup } from './create-account.js';
import { AssignAccountPop } from './assessor-styled.js';
import { hideEye, showEye } from './svg';

export const AssessorAssignPopup = (props) => {

    const categoryid = props.catagory;
    const { popen, setPopen, popupTitle, setPopupTitle = () => { } } = props;
    const assingPlant = props.plant;
    const company_id = assingPlant.company_id

    const pid = assingPlant.id;
    if (!pid) {
        return null;
    }
    const categName = props.catagoryName;
    const [msAssignSearch, setMsAssignSearch] = useState('');
    const [invitedMember, setInvitedMember] = useState([]);
    const [currentInvitedMember, setCurrentInvitedMember] = useState([]);
    const [totalMember, setTotalMember] = useState(0);
    const [isInvite, setIsInvite] = useState(false);
    const [multiEmailVal, setMultiEmailVal] = useState([]);
    const [singleEmail, setSingleEmail] = useState("");
    const [olduser, setOldUser] = useState([]);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [selectedOwners, setSelectedOwners] = useState([]);
    const [classstatus, setClassstaus] = useState(false);
    const [createAccountOpen, setCreateAccountOpen] = useState(false);
    const [viewPassword, setViewPassword] = useState('');
    //scnv-1680 changes
    const [footerVisible, setFooterVisible] = useState(false);
    const [filterMember, setFilterMember] = useState([]);
    const [reminderPopup, setReminderPopup] = useState(false);

    const HandleSelectedOwners = (val) => {
        // setFooterVisible(!footerVisible);
        let tmpArr = [...selectedOwners];

        if (tmpArr.indexOf(val.email) === -1) {
            tmpArr.push(val.email);
            setSelectedOwners(tmpArr);
            setOldUser(val.email);
        }
        else {
            let filterArr = tmpArr.filter((item, index) => {
                return item !== val.name;
            });
            //setSelectedOwners(filterArr);
            setOldUser(val.email);
            //setFooterVisible(!footerVisible);
        }
        setFilterMember([]);
        setMsAssignSearch('');
        document.querySelector('.ms-assign-search input')?.focus();
    }

    const RemoveOwner = (tmpIndex) => {
        let tempArr = [...selectedOwners];
        const filterData = tempArr.filter((item, index) => {
            return tmpIndex !== index;
        })
        setSelectedOwners(filterData);
        setMsAssignSearch('');
    }
    const HandleSingleEmailValue = (e) => {
        let tmpValue = e.target.value;
        setSelectedOwners(tmpValue);
    };
    const IncreseWidth = (e) => {
        /*scnv-SCNV-2947 start */
        let inputText = document.querySelector('.ms-select-owner-div .ms-assign-search-box input');
        if (inputText) {
            let val = inputText.value;
            if (selectedOwners.length > 0) {
                inputText.style.width = `${val.length + 3}ch`;
            } else {
                inputText.style.width = `100%`;
            }
        }
        /*scnv-SCNV-2947 start */
    }
    const AddMultiEmail = (e) => {
        // let tmpArr = [...multiEmailVal];
        let tmpArr = [...selectedOwners];
        const emailRegx =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;



        if ((e.charCode == 13 || e.charCode == 32) && emailRegx.test(String(msAssignSearch).toLowerCase()) && tmpArr.indexOf(msAssignSearch) === -1) {
            const addEmail = msAssignSearch;
            tmpArr.push(addEmail);
            setMsAssignSearch("");
            setSelectedOwners(tmpArr);
            setFilterMember([]);
            e.preventDefault();
        }
    };
    const RemoveEmail = (e) => {
        let filterEmail = multiEmailVal.filter((item, index) => {
            return e !== index;
        });
        setMultiEmailVal(filterEmail);
    };
    const handleClose = () => {
        setMsAssignSearch("");
        setIsInvite(false);
        setSelectedOwners([]);
        setInvitedMember([]);
        setTotalMember(0);
        setFilterMember([]);
        setPopen(false);
        setCreateAccountOpen(false);
    }

    const HandleValue = (e) => {
        let val = e.target.value;
        setMsAssignSearch(val);
        let filterArr = invitedMember.filter((item, index) => {
            return (item.name.toLowerCase().startsWith(val.toLowerCase()) || item.email.toLowerCase().startsWith(val.toLowerCase()));
        });

        if (val.length > 0) {
            setFilterMember(filterArr);

        } else {
            HandleSingleEmailValue(e);
            setSelectedOwners([...selectedOwners]);
            setFilterMember([]);
        }
        let suggestionsWrapper = document.querySelector('#suggestionsWrapper');
        let firstDiv = document.querySelector('.first-invite-popup');
        let msScrollDiv = document.querySelector('.ms-assign-scroll-div');
        if (window.getComputedStyle(msScrollDiv).display != 'none') {
            setTimeout(() => { suggestionsWrapper.style.top = msScrollDiv?.offsetTop + 'px'; }, 50);
        } else {
            setTimeout(() => { suggestionsWrapper.style.top = firstDiv?.offsetTop + 'px'; }, 50);
        }

    }



    useEffect(() => {
        IncreseWidth();
    }, [selectedOwners])

    /*scnv-SCNV-2947 start */
    useEffect(() => {
        let bodyTag = document.querySelector('body');
        bodyTag.onclick = (e) => {
            if (!e.target.closest('.selected-member-ul input') && !e.target.closest('.userlist')) {
                setFilterMember([]);
            }
            /*start SCNV-2928
             #if click outside of the input "search or add email" input */
            if (!e.target.closest('.ms-remove-list') && !e.target.closest('.selected-member-ul input')) {
                e.charCode = 13;
                AddMultiEmail(e);
            }

            /*end SCNV-2928*/
            if (e.target.closest('.ms-select-owner-div .ms-assign-search-box') && !e.target.closest('.ms-select-owner-div .ms-assign-search-box .selected-member-li')) {
                let input = document.querySelector('.ms-select-owner-div .ms-assign-search-box input');
                input?.focus();
            }
        }

    }, [filterMember]);
    /*scnv-SCNV-2947 end */
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;

    const HandleInvite = () => {
        let username = props.tmpAssignUser;
        let name = multiEmailVal[0];
        let tmpObj = {
            ...props.assignedUsers,
            [username]: name
        };
        if (multiEmailVal != '') {
            setIsEmailSent(true);
            setTimeout(() => {
                setIsEmailSent(false);
                handleClose();
            }, 2000)
            serviceProvider.post("/plants?type=assessment_team_invite_multi", JSON.stringify({ email: multiEmailVal, uid: usid, invitation_category: categoryid, role: 1, plants: props.plant_id }), true).then((response) => {

            });

        }

    }




    const handleAssign = () => {
        serviceProvider.post("/plants?type=siriinvite", JSON.stringify({ email: selectedOwners, uid: usid, teamName: selectedOwners[0], plant: pid }), true).then((response) => {
            setIsEmailSent(true);
            setTimeout(() => {
                setIsEmailSent(false);
                handleClose();
                props.setUpDataPage(!props.setuppage);
            }, 2000)

        });
       


    }
    // handle Replace Assessor Function
    const handleReplaceAssessor = () => {

    }

    const loadUserList = async () => {
        const result = await serviceProvider.get(`/plants/PopUpUsersSiri?plant_id=${pid}`);
        const data = result.data
        if (data != "" && data.length && Array.isArray(data)) {
            const options = data?.map(d => ({
                "id": d.id,
                "name": d.name ? d.name + " " + d.last_name : "Not Registered",
                "circleName": d.name ? d.name.charAt(0) + "" + d.last_name.charAt(0) : '',
                "invited": [],
                "invited_by_siri":d.invited_by_siri,
                "email": d.email,
                "password": d.password,
                "profile_pic": d.profile_pic,
                "res": d.name ? 1 : 0,
                "avcolor": d.avcolor
            }));
            let option2 = options;
            let tmpInviteUser = option2?.filter((item) => item.invited_by_siri == company_id);
            let tmpInviteUser2 = options.filter((v,i,a)=>a.findIndex(v2=>(v2.email===v.email))===i)
            setInvitedMember(tmpInviteUser2);
            setTotalMember(data.length)
            setCurrentInvitedMember(tmpInviteUser);
            if (props.popen) {
                setPopen(true);
            }
        } else {
            setInvitedMember([]);
            setCurrentInvitedMember([]);
            setTotalMember(0);
            if (props.popen) {
                setPopen(true);
            }
        }
    };
    const removeUserList = async (dataemail) => {
        const result = await serviceProvider.get(`/plants/removeInviteSiri?plant_id=${pid}&email=${dataemail}`);
    };
    // SCNV 1680 Changes

    const RemoveEmailList = (dataemail) => {
        removeUserList(dataemail);
        let username = props.tmpAssignUser;
        let tmpAssignUserArr = props.assignedUsers[username] ? [...props.assignedUsers[username]] : [];
        let tempArr = [...currentInvitedMember];
        const filterData = tempArr.filter((item, index) => {
            return item.email != dataemail
        })
        const filterUser = tmpAssignUserArr.filter(item => item.email != dataemail);
        let tmpObj = {
            ...props.assignedUsers,
            [username]: [...filterUser]
        };
        props.setUpDataPage(!props.setuppage);
        props.setAssignedUsers(tmpObj);

        setCurrentInvitedMember(filterData);
        setTotalMember(filterData.length);

    };

    // SCNV 1680 Changes End
    
    const handleCreate = () => {
        setCreateAccountOpen(true);
    }

    useEffect(() => {
        loadUserList();
    }, [props.popen]);

    return (
        <>
            <AssignAccountPop data-component="AssignAccountPop" as={Modal} open={popen}
                // onClose={handleClose}
                className="new-assessment-assign-popup">
                <div className="ms-assign-section-popup">
                    {!createAccountOpen ?
                        <div className="ms-assign-section-card">
                            {!isEmailSent ?
                                <>
                                    <div className="ms-assign-header">
                                        <div className="text-wrap">
                                            <span>Assign an Assessor to {assingPlant.plant_name}</span>
                                            <button className="close-btn" onClick={handleClose}>
                                                <img src={require('../../assets/img/maturity-dashboard/cancel-dark.svg').default} alt="X" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* ms-assign-header end */}
                                    <div className="ms-assign-content">
                                        <div className="ms-select-owner-div" style={{ display: isInvite ? 'none' : '' }}>
                                            <div className={`ms-assign-search-box ${msAssignSearch.length > 0 ? 'active' : ''}`} id="searchBox">
                                                <div className="ms-assign-search">
                                                    <span style={{ display: selectedOwners.length > 0 ? 'none' : '' }}>To</span>
                                                    <div className="selected-member-ul">
                                                        <span style={{ display: selectedOwners.length > 0 ? '' : 'none' }}>To</span>
                                                        {selectedOwners.map((item, index) => <div className="selected-member-li">
                                                            <span>{item}</span>
                                                            <button className="remove-list" onClick={() => RemoveOwner(index)}>
                                                                <img src={require('../../assets/img/maturity-dashboard/email-list-cancel.svg').default} alt="X" />
                                                            </button>
                                                        </div>)}
                                                        <input type="text" className={` ${selectedOwners.length > 0 && " active"}`} placeholder="Search or enter an email" onChange={(e) => { HandleValue(e); IncreseWidth(e) }} value={msAssignSearch} onKeyPress={(e) => AddMultiEmail(e)} disabled={currentInvitedMember?.length > 0}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ms-assign-search-box end */}
                                            <div className="userlist" id="suggestionsWrapper"
                                                style={{ display: filterMember.length > 0 ? '' : 'none' }}
                                            >

                                                {filterMember.map((item, index) => (
                                                    <div dataid={index} className={`ms-cir-li ${!item.id ? `not-registered` : ''} ${selectedOwners.indexOf(item.name) !== -1 && classstatus}`} onClick={(e) => { HandleSelectedOwners(item), RemoveEmail(index) }} key={index}>
                                                        <div >
                                                            {!item.profile_pic ?
                                                                <div className={`ms-cir-div ${item.avcolor}`} >
                                                                    {item.id ? item.circleName : item.email[0]}
                                                                </div>
                                                                :

                                                                <img className="ms-cir-div cir-transparent"

                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                    alt={item.profile_pic}
                                                                />

                                                            }
                                                        </div>

                                                        <div className="ms-cir-name">
                                                            <span className='text-capitalize'>{item.name}</span>
                                                        </div>
                                                        <div className="ms-cir-email">
                                                            <span>{item.email} </span>
                                                        </div>
                                                    </div>
                                                )
                                                )
                                                }
                                            </div>
                                            <div className="ms-assign-scroll-div" style={{ display: currentInvitedMember.length > 0 ? '' : 'none' }}>
                                                <div className="ms-cir-list-wrapper">
                                                    <div className="edit-list-wrap">
                                                        {/* <span className="count-member">Shared with {currentInvitedMember.length} members</span> */}
                                                        <span className="count-member">Assessor Assigned</span>

                                                        {currentInvitedMember.map((item, index) => (
                                                            <>
                                                                <div className='asmt-invite-li'>
                                                                    <div dataid={index}
                                                                        className={`ms-cir-li ${!item.id ? `not-registered` : ''} ${selectedOwners.indexOf(item.name) !== -1 && classstatus}`}
                                                                        key={index}
                                                                    >
                                                                        <div className='first-cir-row'>
                                                                            <div>
                                                                                {!item.profile_pic ?
                                                                                    <div className={`ms-cir-div ${item.avcolor}`} >
                                                                                        {item.id ? item.circleName : item.email[0]}
                                                                                    </div>
                                                                                    :

                                                                                    <img className="ms-cir-div cir-transparent"

                                                                                        src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                        alt={item.profile_pic}
                                                                                    />

                                                                                }
                                                                            </div>

                                                                            <div className="ms-cir-name"> <span className='text-capitalize'> {item.name} </span></div>
                                                                            <div className="ms-cir-email"><span> {item.email} </span></div>
                                                                        </div>
                                                                        <div className='scnd-cir-row'>
                                                                            <div className="ms-cir-psw">
                                                                                <span className='pasw'>{
                                                                                    !viewPassword ?
                                                                                        '*********'
                                                                                        :
                                                                                        item.password
                                                                                }
                                                                                </span>
                                                                                <i
                                                                                    dangerouslySetInnerHTML={{ __html: !viewPassword ? hideEye : showEye }} onClick={() => setViewPassword(!viewPassword)}
                                                                                />
                                                                            </div>
                                                                            <button className="ms-remove-list" onClick={() => RemoveEmailList(item.email)}>
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                        <img className="selected-icon" src={require('../../assets/img/maturity-dashboard/cir-select-chk.svg').default} />
                                                                    </div>

                                                                    {/* <div className='reminder-row'>
                                                                            <span className='remind-txt'>Reminder Sent: <i style={{fontStyle:'normal'}} className={`reminder-count-${item.iid}`}>{item.reminders}</i></span>
                                                                            {item.id ? '' :<button className='send-reminder' onClick={() => sendReminder(item.iid)}>Send Reminder</button>}
                                                                        </div> */}
                                                                </div>
                                                            </>
                                                        )
                                                        )
                                                        }
                                                    </div>
                                                    <div className="note-text">
                                                        <span className="label">Please note:</span>
                                                        <p>Only one user can be assigned as an assessor at a time.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="first-invite-popup" style={{ display: currentInvitedMember.length > 0 ? 'none' : '' }}>
                                                <div className="first-invite-popwrap">
                                                    <span>
                                                        No team member has been assigned yet!
                                                    </span>
                                                    <div className="image-wrapper">
                                                        <img src={require('../../assets/img/maturity-dashboard/no-user-assigned.svg').default} alt="X" />
                                                        <p>Search or enter an email to assign a team member.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ms-select-owner-div end */}
                                        {reminderPopup &&
                                            <div className='reminder-success-popup'>
                                                <div className='reminder-success-img'>
                                                    <img src={require('../../assets/img/skillsassessment/reminder-success.gif').default} />
                                                </div>
                                                <p>Email reminder sent successfully</p>
                                            </div>
                                        }
                                        {/* reminder success popup */}
                                    </div>
                                    {/* ms-assign-content end */}

                                    <div className="ms-assign-footer">
                                        {!isInvite ?
                                            <>
                                                <div className='btn-wrap'>

                                                    <button className='create-btn' style={{ visibility: currentInvitedMember?.length == 0 ? 'visible' : 'hidden' }} onClick={() => {currentInvitedMember?.length == 0 ? handleCreate() : ''}}>Create new account</button>

                                                    <div className='right-btn-wrap'>
                                                        <button className="ms-assign-btn cancel" onClick={handleClose}>
                                                            Cancel
                                                        </button>
                                                        <button className={`ms-assign-btn blue ${(selectedOwners.length > 0 && msAssignSearch.length == 0) ? '' : 'disabled'}`} onClick={() => { (selectedOwners.length > 0 && msAssignSearch.length == 0) && handleAssign() }}>
                                                            Assign
                                                        </button>
                                                        <button className="replace-assessor-btn" style={{ display: 'none' }} onClick={() => handleReplaceAssessor()}>Replace Assessor</button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <button className="ms-assign-btn cancel" onClick={() => setIsInvite(false)}>
                                                    Back
                                                </button>
                                                <button className="ms-assign-btn blue" onClick={() => HandleInvite()}>
                                                    Send Invite
                                                </button>
                                            </>
                                        }

                                    </div>

                                </>
                                :

                                <div className="invite-successful assign">
                                    <img src={require('../../assets/img/maturity-dashboard/admin-done-resolved.gif').default} alt="invite successfully" />
                                    <p>
                                        Assigned successfully
                                    </p>
                                </div>
                            }
                        </div>
                        :
                        createAccountOpen &&
                        <CreateAccountPopup
                            openPop={createAccountOpen}
                            setOpenPop={setCreateAccountOpen}
                            setPopen={setPopen}
                            popen={popen}
                            handleClose={handleClose}
                            setUpDataPage={props.setsetuppage}
                            setuppage={props.setuppage}
                            pid={pid}
                            {...props}
                        />
                    }
                </div>

            </AssignAccountPop>

        </>
    )
}

