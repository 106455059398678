/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * makeStyles for ===> styling material-ui component.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * Modal ===> component from UI used to work as popup
  * Accordion, AccordionSummary, AccordionDetails ===> component from material ui for accordion

**/
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Countdown from "react-countdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
//styling component
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		"& .MuiAccordion-root:before": {
			display: "none",
		},
		"& .MuiAccordionSummary-root": {
			padding: "0",
			minHeight: "0",
			"& .MuiAccordionSummary-content": {
				margin: "0",
			},
		},
		"& .MuiAccordionDetails-root": {
			padding: "0",
		},
	},
}));
//SkillViewReport component start
export const SkillViewReport = (props) => {
	//defining variables here
	const quizDataItems = props.quizData;
	if (!quizDataItems.length) {
		return null;
	}
	const [disabled, setDisabled] = useState(true);
	const [isQuizPopup, setIsQuizPopup] = useState(false);
	const [count, setCount] = useState(0);
	//function to handle quiz popup
	const HandleQuizPopup = () => {
		setIsQuizPopup(!isQuizPopup);
	};
	const quizDataItem = props.quizData[0];
	const group_id = localStorage.getItem("group_id");
	if (quizDataItem.block == 1 || (group_id==17)) {
        return <Redirect to="/404" />;
    }
	if (quizDataItem.has_paid == 0) {
		window.location = "/";
		<Redirect to={`/training-courses/${quizDataItem.course_slug}`} />;
	}
	const Quizes = quizDataItem.questions.map((ques, index) => {
		return (
			<Accordion
				key={ques.id}
				defaultExpanded={index == 0 ? true : false}
				className={ques.is_correct == 1 ? "quiz__body__card green" : "quiz__body__card red"}
			>
				<AccordionSummary>
					{/* header has
						no_div
						middle_div
						last_div
					*/}
					<header>
						<div className="no__div">
							<span>{index + 1}</span>
							<img className="green" src={require("../../assets/img/quiz/done-green.svg").default} alt="true" />
							<img className="red" src={require("../../assets/img/quiz/cancel-red.svg").default} alt="true" />
						</div>
						{/* no__div end */}

						<div className="middle__div">
							<p className="b__text">{ques.question}</p>
						</div>
						{/* middle__div end */}
						<div className="last__div">{ques.is_correct} point</div>
						{/* last__div end */}
					</header>
				</AccordionSummary>
				<AccordionDetails>
					{/* this div has answer format */}
					<div className="ans__div">
						{ques.ques_type == 1 ? (
							ques.answers.length ? (
								ques.answers.map((ans) => (
									<div key={ans.id}>
										<label className="quiz__radio">
											{/* defaultChecked */}
											<input
												type="radio"
												name={`ques-${ques.id}[]`}
												value={ans.id}
												defaultChecked={ans.is_checked ? "checked" : ""}
											/>
											{ques.is_correct == 0 && ans.is_checked == 1 ? (
												<span className="icon d-inlineBlock">
													{/* <img src={require("../../assets/img/quiz/wrong-icon.svg").default} alt="true" /> */}
												</span>
											) : ques.is_correct == 1 && ans.is_checked == 1 ? (
												<span className="icon cor__icon"></span>
											) : (
												<span className="icon"></span>
											)}
											<span className="labe__text">{ans.option_name}</span>
										</label>
									</div>
								))
							) : (
								<></>
							)
						) : ques.answers.length ? (
							ques.answers.map((ans) => (
								<div key={ans.id}>
									<label className="quiz__chkbox">
										<input
											type="checkbox"
											name={`ques-${ques.id}[]`}
											value={ans.id}
											defaultChecked={ans.is_checked ? "checked" : ""}
										/>
										{ques.is_correct == 1 && ans.is_checked == 1 ? (
											<span className="correct__icon d-inlineBlock">
												<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
											</span>
										) : ques.is_correct == 0 && ans.is_checked == 1 ? (
											<span className="wrong__icon d-inlineBlock">
												<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
											</span>
										) : (
											<span className="icon">
												<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
											</span>
										)}
										<span className="labe__text">{ans.option_name}</span>
									</label>
								</div>
							))
						) : (
							<></>
						)}
					</div>
				</AccordionDetails>
			</Accordion>
		);
	});
	const classes = useStyles();
	const resettest = () => {
		let uid = quizDataItem.uid;
		let cid = quizDataItem.id;
		serviceProvider.post("/skills?type=resettest", JSON.stringify({ uid: uid, cid: cid }),true).then((response) => {
			
			props.onSubmitQuiz(0);
		});
	};
	//SCNV 2179
	// const renderer = ({ hours, minutes, seconds, completed }) => {
	// 	if (completed) {
	// 		// Render a completed state
	// 		// console.log("sss");
	// 		setDisabled(false);
	// 		if (count == 0) {
	// 			setIsQuizPopup(true);
	// 			setCount(count + 1);
	// 		}
	// 		return (
	// 			<button onClick={resettest} className="btn__retake__quiz">
	// 				Retake Quiz
	// 			</button>
	// 		);
	// 	} else {
	// 		// Render a countdown
	// 		return (
	// 			<>
	// 				<h2 className="svg__cir__h2">You can retry in:</h2>
	// 				<h2 className="svg__cir__h1">
	// 					{hours}h {minutes}m {seconds}s
	// 				</h2>
	// 			</>
	// 		);
	// 	}
	// };

	return (
		<>
			{/* page title */}
			<Helmet>
				<title>{quizDataItem.course_name} Assessment | SmarterChains</title>
				<meta name="description" content="Training Courses" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			{/* breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/skill-assessment">
				Skills Assessment
			</BreadcrumbsItem>
			<BreadcrumbsItem to="#">
				{quizDataItem.course_name} Assessment
			</BreadcrumbsItem>
			{/* main wrapper has
				quiz__left__wrapp
				quiz__right__wrapp
			*/}
			<div className="skill__main__wrapper skill_report_main_wrapper">
				{/* quiz__left__wrapp has
					quiz__header
					quiz__body
				*/}
				<div className="quiz__left__wrapp">
					{/* this section has
						quiz__header__content
						quiz__header__img
					*/}
					<div className="quiz__header">
						{/* this section has
							heading
							small-text
						*/}
						<div className="quiz__header__content">
							<h2 className="h1">
								<Link to={`/training-courses/${quizDataItem.course_slug}`} className="back__link">
									<img src={require("../../assets/img/quiz/back-arrow.svg").default} alt="true" />
								</Link>
								<span>{quizDataItem.course_name} Assessment</span>
							</h2>
							<p className="small">
								Total points: <span>{quizDataItem.questions.length}</span>
							</p>
						</div>
						<div className="quiz__header__img">
							{/* 
							// 9-30-2021
							  <img src={require("../../assets/img/quiz/fof-quiz.svg").default} alt="true" /> 
							*/}

							<img src={require(`../../assets/img/training-quiz/tqicon-${quizDataItem.id}.svg`).default} alt="{quizDataItem.course_name}" />

						</div>
					</div>
					{/* quiz__header end */}
					<div className="quiz__body">
						<div className={classes.root}>{Quizes}</div>

						<div className="quiz__footer">
							{/* <button id="btn__quiz_submit__btn" className="btn__submit" disabled={disabled}>
								Retake Quiz
							</button> */}
						</div>
					</div>
				</div>
				{/* quiz__left__wrapp  end */}
				{quizDataItem.score < 80 ? (
					<div className="quiz__right__wrapp">
						{/* card has
							heading
							progress circle
						*/}
						<div className="card">
							<h2 className="card_heading">You scored</h2>
							<div className="svg__cir__wrapp">
								<SvgCircle value={quizDataItem.score} />
							</div>
							{/* <Countdown key={quizDataItem.score} date={quizDataItem.dateretest} renderer={renderer} /> */}
						</div>
					</div>
				) : (
					<div className="quiz__right__wrapp">
						{/* card has progress circle
							headings
						*/}
						<div className="card">
							<div className="svg__cir__wrapp">
								<SvgCircle value={quizDataItem.score} />
							</div>
							<h2 className="svg__cir__h2">Training completed</h2>
							<h2 className="svg__cir__h1 blue">Congrats!</h2>
						</div>
					</div>
				)}
				{/* complete */}
			</div>
			{/* quiz__main__wrapper end */}
			
			
			{/*score popup start*/}
			<Modal open={props.isCompletePopup} onClose={props.HandleCompletePopup} className="skill__complete__popup">
				{quizDataItem.score<0 ? (
					<div className="quiz__complete__inner">
						
					</div>
				) : quizDataItem.score >= 0 ? (
					<div className="quiz__score__inner">
						<button className="close_btn" onClick={props.HandleCompletePopup}>
							<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="true" />
						</button>
						<h2 className="title__h1">You scored</h2>
						<p className="txt__quiz__score">{quizDataItem.score}%</p>
						<p className="b">q</p>
						<p>q</p>
						<div className="btn__wrapp">
							<button className="btn__ok" onClick={props.HandleCompletePopup}>
								OK
							</button>
						</div>
					</div>
				) : (
					<></>
				)}
			</Modal>
			{/* unsaved file popup end */}
		</>
	);
};
//progress circle component
const SvgCircle = (props) => {
	const { value } = props;
	return (
		<svg viewBox="0 0 36 36">
			<text className="percent__text" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="700">
				{value}
				<tspan fontSize="5">%</tspan>
			</text>
			<path
				className="circle-bg"
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke="#EBF5FA"
				strokeWidth="2.4"
				fill="none"
			></path>
			<path
				className="circle"
				strokeDasharray={`${value}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke={value < 80 && value > 0 ? "#118acb" : value > 79 ? "#118acb" : "transparent"}
				strokeWidth="2.4"
				fill="none"
			></path>
		</svg>
	);
};
