/** imported components

  * PowerBiLossAnalysisView ===> for custom component.

**/
import React from "react";
import { PowerBiLossAnalysisView } from "./powerbi-loss-analysis-view";
// PowerBiLossAnalysisController start
export const PowerBiLossAnalysisController = (props) => {
  return (
    <>
      <PowerBiLossAnalysisView {...props} />
    </>
  );
};
