import styled from 'styled-components'
import { Link } from 'react-router-dom';


export const MainWrapper = styled.section`
    padding: 0;
    display: flex;
    flex-direction: column;
    `;


export const HowToUsePopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 9999;
`
export const HowToUsePopWrap = styled.div`
    position: absolute;
    width: 99.2rem;
    min-height: auto;
    overflow: auto;
    background: #FFFFFF;
    border: 0.1rem solid #F0F0F0;
    border-radius: 2.4rem;
    padding: 2.4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    @media(max-height: 567px){
        max-height: calc(100vh - 1rem);
    }
    @media(max-width: 1024px){
        width: 700px;
        max-height: 500px;
    }
    @media(max-width: 740px){
        max-height: 100%;
    }
    @media(max-width: 740px){
        border-radius: 0;
        padding: 20px 12px 32px 12px;
        bottom: 0;
        top: auto;
        left: auto;
        right: auto;
        transform: unset;
        min-height: auto;
        width: 100%;
    }
`
export const HowToUsePopHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.2rem;
    align-items: center;
    @media(max-width: 740px){
        padding-bottom: 14px;
    }
    .head-txt{
        border-left: 0.6rem solid #6464C8;
        padding-left: 0.8rem;
        color: #0D2C54;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 130%; /* 23.4px */
        display: block;
        @media(max-width: 740px){
            font-size: 16px;
        }
    }
`
export const HowCloseBtn = styled.button`
    display: inline-block;
    border: unset;
    box-shadow: unset;
    height: 3.2rem;
    width: 3.2rem;
    svg{
        width: 100%;
        height: 100%;
    }
    &:hover{
        svg{
            rect{
                fill: #2C55A0;
            }
            path{
                fill: #ffffff;
            }
        }
    }
`
export const HowToUsePopBody = styled.div`
    padding-bottom: 0.5rem;
    display: flex;
    flex: 1;
    .video-pop{
        min-height: 20rem;
        border-radius: 8px;
        border: 0.1rem solid #6464C8;
        background: rgba(77, 77, 79, 0.07);
        width: 100%;
        @media(max-width: 740px){
            min-height: 100px;
        }
        .plyr--video,.plyr__video-wrapper{
            border-radius: 0.8rem;
        }

    }
`
export const HowToUseHeadBottom = styled.div`
    padding-bottom: 0.8rem;
    @media(max-width: 740px){
        padding-bottom: 16px;
    }
    p{
        overflow: hidden;
        color: #4D4D4F;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.6rem; /* 114.286% */
        @media(max-width: 740px){
            font-size: 12px;
            padding-bottom: 16px;
        }
    }
    .button-wrap{
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
        @media(max-width: 740px){
            gap: 8px;
        }
        .btn{
            border-radius: 0.2rem;
            display: inline-block;
            padding: 0.6rem 1.2rem;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: normal;
            &.dark-btn{
                background: #6464C8;
                color: #fff;
            }
            &.light-btn{
                background: #E7EBF8;
                color: #6464C8;
            }
        }
    }
`
export const HowToUseTooltip = styled.div`
    background: #6464C8;
    border-radius: 0.8rem;
    padding: 0 1.6rem;
    position: absolute;
    width: 21.6rem;
    bottom: 6rem;
    right: 0;
    .tooltip-text{
        button{
            padding: 1.6rem 0;
            display: block;
            border-bottom: 0.1rem solid #ffffff;
            color: #FFF;
            text-align: left;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: normal;
            &:last-child{
                border: 0;
            }
        }
    }
    &:after{
        content: " ";
        position: absolute;
        top: 100%;
        right: 4rem;
        border-width: 1rem;
        border-style: solid;
        border-color: #6464C8 transparent transparent transparent;
    }
`
export const HowToUseBtn = styled.button`
    padding: 1.1rem 1.6rem;
    background: linear-gradient(131.53deg, #8686DF 14.79%, #6464C8 85.39%);
    box-shadow: 0px 0px 8px rgba(21, 27, 38, 0.12);
    border-radius: 7.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    transiton:0.25s ease-out;
    z-index:5;
    scale:1;
    position:relative;
    overflow:hidden;
    gap:0.8rem;
    
    .how-txt{
        display: inline-block;
        font-weight: 400;
        font-size: 1.6rem;
        color: #FFFFFF;
    }
    i{
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        svg{
            height: 100%;
            width: 100%
        }
    }
    &:after{
        content:"";
        display:block;
        background:#6464C8;
        z-index:-1;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        border-radius:inherit;
        transiton:0.25s ease-out;
        transform:translateY(5rem) translateX(-100%);
    }
    &:hover{
        scale:1.05;
        &:after{
            transform:translateY(0rem) translateX(0%);
        }
    }
    .sm-hide{
        display: inline-block;
    }
    .sm-visible{
        display: none;
    }
    @media(max-width: 740px){
        .sm-visible{
            display: inline-block;
        }
        .sm-hide{
            display: none;
        }
        height: 48px;
        width: 48px;
        padding: 0;
        i{
            height: 100%;
            width: 100%;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
`
export const HowToUseToolTipWrap = styled.div`
    position: fixed;
    max-width: 28rem;
    bottom: 3.4rem;
    right: 3.2rem;
    @media(max-width: 740px){
        right: 20px;
        bottom: 20px;
    }
`