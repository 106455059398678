import { useEffect } from "react";
import serviceProvider from '../../services/axios'
import { useHistory } from "react-router-dom";
import { decrypter} from  '../../webutils/webutils'
import { apiEndpoints } from '../../services/api-endpoints'


export default function Roadmapconfigurator(props){
    const history = useHistory();   

    


useEffect(async ()=>{
   const { match } = props;
   const { params } = match;
   if(!!params && 'id' in  params){
       const encodedEmail = params.id;
       const email = decrypter(encodedEmail)
       
    if(email){
        try{
          
            if(email){ 
				 window.open(`${apiEndpoints.baseUrl}/automation/${email}/step1`,"_self");
            }
        }catch(error){
            history.push('sign-in');
        }
    }else{
        history.push('sign-in');
    }
   
   }


},[])

return (<div />)

}