import React from 'react';
import { Helmet } from 'react-helmet';
import {unassignImage} from './svg';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
export const UnassignView = () => {
    return (
        <>
            <Helmet>
                <link  href={require('./unassign-page.scss').require}/>
            </Helmet>
            <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/"></BreadcrumbsItem>
            <div className='unassign-page'>
                        <div className='content-unassign'>
                                <span className='unassign-img' dangerouslySetInnerHTML={{__html:unassignImage}}></span>

                                <h1>Oops!... you no longer have access</h1>
                                <p>Please ask your admin to reassign you if needed</p>
                        </div>
            </div>
        </>
    );
}

