/** imported components

**/
import React, { useState } from "react";

export const CpgstudyView = (props) => {
  return (
    <>
      {/* 
          Main container. Here we placed CPG Study Content.
      */}
      <div className="home-index main-course-page cpg-industry-study">
        <div className="intro">
          <h1>CPG Industry Study</h1>
          <p>
            Read the findings of our Factory of the Future benchmarking study,
            revealing
            <br /> the levels of Industry 4.0 adoption among global
            manufacturing leaders in the
            <br /> consumer packaged goods (CPG) industry.
          </p>
        </div>
        <div className="future-bechmarking-study">
          <h2>Factory of the Future Benchmarking Study</h2>
          <div className="study-left">
            <ul>
              <li>
                What are the technological capabilities across the Industry?
              </li>
              <li>
                How many plants have achieved the Plant of the Future status?
              </li>
              <li>What are the key trends that distinguish the Leaders?</li>
              <li>Which Industry 4.0 solutions prevail?</li>
            </ul>
            {/*  Download CPG Study report button  */}
            <div className="get-full-study">
              <a
                target="_blank"
                href={
                  require("../../assets/pdf/fof-benchmarking-study.pdf").default
                }
                className="download-button"
                download
              >
                <figure>
                  <img
                    src={
                      require("../../assets/img/learning/brochure-icon.svg")
                        .default
                    }
                    alt="brochure-icon"
                  />
                </figure>
                <span>Get the Full Study</span>
              </a>
              <div className="title">
                <p>
                  <b>Data-driven Insights</b> for CPG companies{" "}
                  <b>on Industry 4.0 adoption</b>
                </p>
              </div>
            </div>
          </div>
          <div className="study-right">
            <ul>
              <li>
                <figure>
                  <img
                    src={
                      require("../../assets/img/learning/Plant-icon.svg")
                        .default
                    }
                    alt="Plant-icon"
                  />
                </figure>
                <h5>50</h5>
                <span>Plants</span>
              </li>
              <li>
                <figure>
                  <img
                    src={
                      require("../../assets/img/learning/production-lines-icon.svg")
                        .default
                    }
                    alt="production-lines-icon"
                  />
                </figure>
                <h5>327</h5>
                <span>
                  Production
                  <br /> Lines
                </span>
              </li>
              <li>
                <figure>
                  <img
                    src={
                      require("../../assets/img/learning/data-points-icon.svg")
                        .default
                    }
                    alt="data-points-icon"
                  />
                </figure>
                <h5>336k</h5>
                <span>Data Points</span>
              </li>
              <li>
                <figure>
                  <img
                    src={
                      require("../../assets/img/learning/manufacturing-experts-icon.svg")
                        .default
                    }
                    alt="manufacturing-experts-icon"
                  />
                </figure>
                <h5>500</h5>
                <span>Manufacturing Experts</span>
              </li>
              <li>
                <figure>
                  <img
                    src={
                      require("../../assets/img/learning/total-workforce-icon.svg")
                        .default
                    }
                    alt="total-workforce-icon"
                  />
                </figure>
                <h5>24,365</h5>
                <span>
                  Total
                  <br /> Workforce
                </span>
              </li>
              <li>
                <figure>
                  <img
                    src={
                      require("../../assets/img/learning/industry-types-icon.svg")
                        .default
                    }
                    alt="industry-types-icon"
                  />
                </figure>
                <h5>9</h5>
                <span>CPG Industry Types</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
