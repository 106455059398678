import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ScenarioDropdown from "./ScenarioDropdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios';

// images that we have used in page
import {
  angleIcon,
  closeSvg,
  collapseIcon,
  collapseOpenIcon,
  infoIcon,
  digitalSvg,
} from "./svg-step3";
// components that we have used in page
import {
  MainCardWrapper,
  TabCardWrapper,
  TabContentWraaper,
  RightAlignTextWrapper,
  HeadText,
  SubText,
  AddDragBtn,
  LossTopWrap,
  CollapseBtn,
  TabPanelContent,
  LossScrollTableWrap,
  InnerTabWrapper,
  InnerTBRightCol,
  LossTableWrap,
  ScndRgtTabWrapper,
  BlueRecoverText,
  CloseTabBtn,
  ListInvestWrap,
  ListRow,
  UpfrontListRow,
  ListValWrap,
  ListTableValWrap,
  UserWrap,
  GoListWrap,
  VendorTabPanel,
  ListInvestHead,
  InnerTBLeftCol
} from "./Roadmap-style-step3";
// common components that we have used in page
import {
  MainWrapper,
  SectionWrapper,
  LeftSection,
  RightSection,
  SectionHeader,
  ContentBody,
  ContentFooter,
  HeaderTitle,
  ScenarioSectionWrapper,
  TabButton,
  TBRow,
  TBLeftCol,
  TBRightCol,
  LossWrap,
  LossCol,
  LossRow,
  ProceedPopWrapper,
  ProceedPopCard,
  PopHead,
  PopFooter,
  RecoverablePopWrap,
  RecoverCloseBtn,
  InfoIcon,
  RecoverPopCard,
  ScndTabButton,
  RightAlignTextWrap,

  DefaultScenarioPop,
  DefaultScenarioPopupWrapper,
  LoaderPopupWrap,
  LoaderPop,
} from "./common-roadmap-style";

export const RoadmapStepThirdView = (props) => {
  //variable declaration
  const history = useHistory();
  const stepdata = props.stepData;
  const [isRecoverPop, setIsRecoverPop] = useState(true);
  const [scenarioList, setScenarioList] = useState(stepdata.getAllScenario);
  const [isActiveCard, setIsActiveCard] = useState(false);
  // Collapse
  const [leftOpen, setLeftOpen] = React.useState(false);
  const [lossLeftDataOpen, setLossLeftDataOpen] = React.useState(false);

  const [isMakeDefault, setIsMakeDefault] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isDefaultScenarioPopup, setIsDefaultScenarioPopup] = useState(false);
  const makeDefaultRef = useRef();

  // Recoverable popup variable
  const [openPopup, setOpenPopup] = useState(false);
  // Cards array declaration
  const [leftCards, setLeftCards] = useState(stepdata.usernodes || []);
  const [leftValue, setLeftValue] = React.useState(`l0-0`);
  const [rightInputsValue, setRightInputsValue] = useState({});
  const [planttotalinvestment, setplanttotalinvestment] = useState(stepdata?.planttotalinvestment);
  // Tabs Function
  const handleLeftChange = (event, newValue) => {
    setLeftValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>

      </>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  // Handle left tabs collapse
  const handleLeftClick = (id, index) => {
    setLeftValue(`l0-${index}`);
    if (leftOpen === id) {
      setLeftOpen(false);
    }
    else {
      setLeftOpen(id);
    }
  };
  // Handle inner tabs collapse
  const handleLeftInnerTabClick = (val) => {
    if (lossLeftDataOpen === val) {
      setLossLeftDataOpen(false);
    }
    else {
      setLossLeftDataOpen(val);
    }
  };
  // Handle right cards
  const handleRightCard = (item, count) => {
    setIsActiveCard({ ...item, count });
    let capex = `capex_${item?.id}`;
    let capex_adj = `capex_adj_${item?.id}`
    let starting_opex = `starting_opex_${item?.id}`;
    let starting_opex_adj = `starting_opex_adj_${item?.id}`;
    let upFront_investment = `upFront_investment_${item?.id}`;
    let upFront_investment_adj = `upFront_investment_adj_${item?.id}`;
    let ongoing_opex = `ongoing_opex_${item?.id}`;
    let ongoing_opex_adj = `ongoing_opex_adj_${item?.id}`;
    let unit = `unit_${item?.id}`;
    let unit_qty = `unit_qty_${item?.id}`;
    let unit_qty_adj = `unit_qty_adj_${item?.id}`;
    setRightInputsValue(prv => {
      return {
        ...prv,
        [capex]: prv[capex] || item?.investdata?.capex || 0,
        [capex_adj]: prv[capex_adj] || item?.investdata?.capex_adj || 0,
        [starting_opex]: prv[starting_opex] || item?.investdata?.starting_opex || 0,
        [starting_opex_adj]: prv[starting_opex_adj] || item?.investdata?.starting_opex_adj || 0,
        [upFront_investment]: Number(prv[capex] || item?.investdata?.capex || 0) + Number(prv[starting_opex] || item?.investdata?.starting_opex || 0),
        [upFront_investment_adj]: Number(prv[capex_adj] || item?.investdata?.capex_adj || 0) + Number(prv[starting_opex_adj] || item?.investdata?.starting_opex_adj || 0),
        [ongoing_opex]: prv[ongoing_opex] || item?.investdata?.ongoing_opex || 0,
        [ongoing_opex_adj]: prv[ongoing_opex_adj] || item?.investdata?.ongoing_opex_adj || 0,
        [unit]: prv[unit] || item?.investdata?.unit || 0,
        [unit_qty]: prv[unit_qty] || item?.investdata?.unit_qty || 0,
        [unit_qty_adj]: prv[unit_qty_adj] || item?.investdata?.unit_qty_adj || 0,
      }
    })
  }


  const yesMakeDefaultFunction = () => {
    setIsDefaultScenarioPopup(false);
    setIsLoader(true);
    let tmpArr = scenarioList.map(i => {
      let tmpObj = i
      if (isMakeDefault.id === tmpObj.id) {
        tmpObj.is_default = 1;
      }
      else {
        tmpObj.is_default = false;
      }
      return tmpObj;
    });
    const tmp_sid = isMakeDefault.id || stepdata.sid;
    serviceProvider.post("/roadmapcomplete?param=makeDefaultScenario", JSON.stringify({ pid: stepdata.plant_id, sid: tmp_sid })).then((response) => {

      let redirectUrl = '/roadmap-complete/step1/' + stepdata.plant_slug + "/" + tmp_sid;
      // window.location.href = '/roadmap-complete/step1/' + stepdata.plant_slug + "/" + isMakeDefault.id;
      history.push(redirectUrl);

    });
    setScenarioList(tmpArr);
  }

  const saveRoadmap = () => {
    let redirectUrl = '/roadmap-complete/final-step/' + stepdata.plant_slug + "/" + stepdata.sid;
    history.push(redirectUrl);
    setOpenPopup(false)
  }
  const handleRightInputsValue = (node_id, input_name, e) => {
    let name = e.target.id;
    let value = e.target.value || '';
    let tmpObj = { ...rightInputsValue };
    let capex = `capex_${node_id}`;
    let capex_adj = `capex_adj_${node_id}`
    let starting_opex = `starting_opex_${node_id}`;
    let starting_opex_adj = `starting_opex_adj_${node_id}`;
   
    let upFront_investment = `upFront_investment_${node_id}`;
    let upFront_investment_adj = `upFront_investment_adj_${node_id}`;
    let updatedObj = {
      ...tmpObj,
      [name]: value.replace(/[^0-9]/g, '')
    }
    setRightInputsValue(prv => {
      return {
        ...updatedObj,
        [upFront_investment]: Number(updatedObj[capex] || 0) + Number(updatedObj[starting_opex] || 0),
        [upFront_investment_adj]: Number(updatedObj[capex_adj] || 0) + Number(updatedObj[starting_opex_adj] || 0),
      }
    });

  }
  const handleRightInputsValueSubmit = (node_id, input_name, e) => {
    let capex = rightInputsValue[`capex_${node_id}`];
    let capex_adj = rightInputsValue[`capex_adj_${node_id}`];
    let starting_opex = rightInputsValue[`starting_opex_${node_id}`];
    let starting_opex_adj = rightInputsValue[`starting_opex_adj_${node_id}`];
    let upFront_investment = rightInputsValue[`upFront_investment_${node_id}`];
    let upFront_investment_adj = rightInputsValue[`upFront_investment_adj_${node_id}`];
    let ongoing_opex = rightInputsValue[`ongoing_opex_${node_id}`];
    let ongoing_opex_adj = rightInputsValue[`ongoing_opex_adj_${node_id}`];
    let unit = rightInputsValue[`unit_${node_id}`];
    let unit_qty = rightInputsValue[`unit_qty_${node_id}`];
    let unit_qty_adj = rightInputsValue[`unit_qty_adj_${node_id}`];
    let tmpobj = { capex, capex_adj, starting_opex, starting_opex_adj, upFront_investment_adj,upFront_investment, ongoing_opex, ongoing_opex_adj, unit, unit_qty, unit_qty_adj }
    let sid = stepdata.sid;
    let pid = stepdata.pid;
    serviceProvider.post("/roadmapcomplete?param=saveadjinvestment", JSON.stringify({ pid: pid, sid: sid, nid: node_id, value: tmpobj })).then((response) => {

      document.getElementById(`invst-${node_id}`).innerHTML =  upFront_investment_adj+'SR';
      
      setplanttotalinvestment(response.data);

    });
  }
  const handleRightInputsValueSubmitUnit = (node_id, input_name, e) => {
   
    let unit_qty = rightInputsValue[`unit_qty_${node_id}`];
    let unit_qty_adj = rightInputsValue[`unit_qty_adj_${node_id}`];
   
    let sid = stepdata.sid;
    let pid = stepdata.pid;
    serviceProvider.post("/roadmapcomplete?param=recalculateInvestdata", JSON.stringify({ pid: pid, sid: sid, nid: node_id, unit_qty: unit_qty,unit_qty_adj:unit_qty_adj })).then((response) => {
      
      var data = response.data;

      let capex = `capex_${node_id}`;
    let capex_adj = `capex_adj_${node_id}`
    let starting_opex = `starting_opex_${node_id}`;
    let starting_opex_adj = `starting_opex_adj_${node_id}`;
    let upFront_investment = `upFront_investment_${node_id}`;
    let upFront_investment_adj = `upFront_investment_adj_${node_id}`;
    let ongoing_opex = `ongoing_opex_${node_id}`;
    let ongoing_opex_adj = `ongoing_opex_adj_${node_id}`;
    let unit = `unit_${node_id}`;
    let unit_qty = `unit_qty_${node_id}`;
    let unit_qty_adj = `unit_qty_adj_${node_id}`;

      setRightInputsValue(prv => {
        return {
          ...prv,
          [capex]:data?.capex || 0,
          [capex_adj]:  data?.capex_adj  || 0,
          [starting_opex]: data?.starting_opex  || 0,
          [starting_opex_adj]:  data?.starting_opex_adj || 0,
          [upFront_investment]: Number( data?.investment|| 0),
          [upFront_investment_adj]: Number(data?.investment_adj|| 0),
          [ongoing_opex]:  data?.ongoing_opex  || 0,
          [ongoing_opex_adj]:  data?.ongoing_opex_adj  || 0,
          [unit]: prv[unit] || 0,
          [unit_qty]: data?.unit_qty  || 0,
          [unit_qty_adj]: data?.unit_qty_adj  || 0,
        }
      })
          
      document.getElementById(`invst-${node_id}`).innerHTML =  data?.investment+'SR';
      document.getElementById(`invstss-${node_id}`).innerHTML =   data?.investment+'SR';
      
      setplanttotalinvestment(data.planttotalinvestment);

    });
  }
  
  const clearRightData = (node_id)=>{
    let capex = rightInputsValue[`capex_${node_id}`];
    let capex_adj = rightInputsValue[`capex_${node_id}`];
    let starting_opex = rightInputsValue[`starting_opex_${node_id}`];
    let starting_opex_adj = rightInputsValue[`starting_opex_${node_id}`];
    let upFront_investment = rightInputsValue[`upFront_investment_${node_id}`];
    let upFront_investment_adj =  rightInputsValue[`upFront_investment_${node_id}`];
    let ongoing_opex = rightInputsValue[`ongoing_opex_${node_id}`];
    let ongoing_opex_adj = rightInputsValue[`ongoing_opex_adj_${node_id}`];
    let unit = rightInputsValue[`unit_${node_id}`];
    let unit_qty = rightInputsValue[`unit_qty_${node_id}`];
    let unit_qty_adj = rightInputsValue[`unit_qty_adj_${node_id}`];
    setRightInputsValue(prv=>{
      return{
        ...prv,
        [`capex_adj_${node_id}`]:capex_adj,
        [`starting_opex_adj_${node_id}`]:starting_opex_adj,
        [`upFront_investment_adj_${node_id}`]:upFront_investment_adj,
      }
    })
    let tmpobj = { capex, capex_adj, starting_opex, starting_opex_adj, upFront_investment, ongoing_opex, ongoing_opex_adj, unit, unit_qty, unit_qty_adj }
    let sid = stepdata.sid;
    let pid = stepdata.pid;
    serviceProvider.post("/roadmapcomplete?param=saveadjinvestment", JSON.stringify({ pid: pid, sid: sid, nid: node_id, value: tmpobj })).then((response) => {
    });
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/home">Plant name</BreadcrumbsItem>
      <BreadcrumbsItem to={`/roadmap-complete/step1/${stepdata.slug}/${stepdata.sid}`}>IR4.0 Roadmap Solutions</BreadcrumbsItem>
      <BreadcrumbsItem to={`/roadmap-complete/step2/${stepdata.slug}/${stepdata.sid}`}>IR4.0 Savings</BreadcrumbsItem>
      <BreadcrumbsItem to="#">IR4.0 Investment</BreadcrumbsItem>
      {/* End */}
      <MainWrapper data-component="MainWrapper" className="step-third">
        <SectionWrapper data-component="SectionWrapper">
          <LeftSection data-component="LeftSection">
            <SectionHeader data-component="SectionHeader">
              <HeaderTitle data-component="HeaderTitle">
                Roadmap
              </HeaderTitle>
            </SectionHeader>
            <LossTopWrap data-component="LossWrap">
              <LossRow data-component="LossRow">
                <LossCol data-component="LossCol">
                  <span className="text">Total Losses</span>
                  <span className="text">Identified:</span>
                  <span className="text orange">{stepdata?.planttotalloss || 0} SR</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">Total Roadmap</span>
                  <span className="text"> Recoverable:</span>
                  <span className="text green">{stepdata?.planttotalrecoverable || 0} SR</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">% of Recoverable</span>
                  <span className="text"> Losses:</span>
                  <span className="text blue">{stepdata?.planttotallossper || 0}%</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">Total Roadmap</span>
                  <span className="text">Investment:</span>
                  <span className="text orange">{planttotalinvestment || 0} SR</span>
                </LossCol>
              </LossRow>
            </LossTopWrap>
            <ContentBody data-component="ContentBody">
              <div>
                {leftCards.map((card, index) => (
                  <div
                    className="card"
                  >
                    <MainCardWrapper
                      data-component="MainCardWrapper"
                    >
                      <TabCardWrapper data-component="TabCardWrapper">
                        <TabButton data-component="TabButton">
                          <TBRow data-component="TBRow">
                            <TBLeftCol data-component="TBLeftCol">
                              <button
                                className="drag-btn blue"
                              >
                                <span>{index + 1}</span>
                              </button>
                              <p className='rmap-txt'>
                                {card.name}
                              </p>
                            </TBLeftCol>
                            <TBRightCol data-component="TBRightCol">
                              <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                <RightAlignTextWrap data-component="RightAlignTextWrap">
                                  <HeadText data-component="HeadText">Recoverable: </HeadText>
                                  <SubText data-component="SubText" green>{card.totalrecoverable}SR</SubText>
                                </RightAlignTextWrap>
                                <RightAlignTextWrap data-component="RightAlignTextWrap">
                                  <HeadText data-component="HeadText">Investment: </HeadText>
                                  <SubText data-component="SubText" id={`invst-${card.id}`} orange>{card.upfrontInvestment}SR</SubText>
                                </RightAlignTextWrap>
                              </RightAlignTextWrapper>
                              <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftClick(card.id, index)} className={`${leftOpen === card.id ? 'active' : ''}`}>
                                {leftOpen === card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                              </CollapseBtn>
                              <AddDragBtn data-component="AddDragBtn" onClick={() => handleRightCard(card, index + 1)}>
                                <i dangerouslySetInnerHTML={{ __html: angleIcon }}></i>
                              </AddDragBtn>
                            </TBRightCol>
                          </TBRow>
                        </TabButton>
                        <Collapse in={leftOpen === card.id} timeout="auto" unmountOnExit>
                          <TabContentWraaper data-component="TabContentWraaper">
                            <Tabs
                              orientation="horizontal"
                              variant="scrollable"
                              value={leftValue}
                              onChange={handleLeftChange}
                              aria-label="Vertical tabs example"

                            >
                              <Tab value={`l0-${index}`} label="Description" {...a11yProps(0)} />
                              <Tab value={`l1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                            </Tabs>

                            <TabPanel value={leftValue} index={`l0-${index}`}>
                              <TabPanelContent data-component="TabPanelContent">
                                <div dangerouslySetInnerHTML={{ __html: card?.description }}></div>
                              </TabPanelContent>
                            </TabPanel>

                            <TabPanel value={leftValue} index={`l1-${index}`}>
                              {Array.isArray(card?.sublossdata) &&
                                card?.sublossdata?.map((sublossItem, sIndex) => (
                                  <div key={sIndex}>
                                    <InnerTabWrapper data-component="InnerTabWrapper">
                                      <TBRow data-component="TBRow">
                                        <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                          <p className="inner-tab-txt">{sublossItem?.lossCategorytext}</p>
                                        </InnerTBLeftCol>
                                        <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                          <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Total Category Losses: </HeadText>
                                              <SubText data-component="SubText" orange>{sublossItem?.totallosscategory}SR</SubText>
                                            </RightAlignTextWrap>
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Recoverable: </HeadText>
                                              <SubText data-component="SubText" green>{sublossItem?.totalcatrecoverable}SR</SubText>
                                            </RightAlignTextWrap>
                                          </RightAlignTextWrapper>
                                          <p className='recovery-txt'>
                                            <span>{sublossItem?.perlosscatrec == 'nan' ? 0 : sublossItem?.perlosscatrec}% </span>
                                            of category <br />losses Recovered
                                          </p>
                                          <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftInnerTabClick(`loss0-${index}-${sIndex}`)} className={`${lossLeftDataOpen === `loss0-${index}-${sIndex}` ? 'active' : ''}`}>
                                            {lossLeftDataOpen === `loss0-${index}-${sIndex}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                          </CollapseBtn>
                                        </InnerTBRightCol>
                                      </TBRow>
                                    </InnerTabWrapper>
                                    <Collapse in={lossLeftDataOpen === `loss0-${index}-${sIndex}`} timeout="auto">
                                      <LossTableWrap data-component="LossTableWrap">
                                        <LossScrollTableWrap data-component="LossScrollTableWrap">
                                          <table className='responsive-loss-table'>
                                            <thead>
                                              <tr>
                                                <th className='loss-table-col'>Sublosses</th>
                                                <th className='loss-table-col'>
                                                  Identified<br /> Losses
                                                </th>
                                                <th className='loss-table-col'>Recoverable</th>
                                                <th className='loss-table-col'>% of Subloss<br />recoverable </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Array.isArray(sublossItem?.subloss) && sublossItem?.subloss?.map((subloss) => (
                                                <tr>
                                                  <td className='loss-table-col'>{subloss?.subloss_category_text}</td>
                                                  <td className='loss-table-col orange' dangerouslySetInnerHTML={{ __html: subloss?.totalsublossrecoverable + '<span>SR</span>' }}></td>
                                                  <td className='loss-table-col green' dangerouslySetInnerHTML={{ __html: subloss?.totalsubcatrecoverable + '<span>SR</span>' }}></td>
                                                  <td className='loss-table-col blue'>{subloss?.lossrecoverdp}%</td>
                                                </tr>
                                              ))
                                              }

                                              <tr>
                                                <td className='loss-table-col bottom black'>Total</td>
                                                <td className='loss-table-col bottom black'>{sublossItem?.totallosscategory}<span>SR</span></td>
                                                <td className='loss-table-col bottom black'>{sublossItem?.totalcatrecoverable}<span>SR</span></td>
                                                <td className='loss-table-col bottom black'></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </LossScrollTableWrap>
                                      </LossTableWrap>
                                    </Collapse>
                                  </div>
                                ))
                              }



                              <LossWrap data-component="LossWrap">
                                <LossRow data-component="LossRow">
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">Associated</span>
                                    <span className="text">Losses:</span>
                                    <span className="text green">{card.totalloss} SR</span>
                                  </LossCol>
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">Total Technology</span>
                                    <span className="text"> Recoverable:</span>
                                    <span className="text orange">{card.totalrecoverable} SR</span>
                                  </LossCol>
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">% of Total</span>
                                    <span className="text"> Recovered Losses:</span>
                                    <span className="text blue">{card.totallossrecoverdp?.toFixed(2)}%</span>
                                  </LossCol>
                                </LossRow>
                              </LossWrap>
                            </TabPanel>
                          </TabContentWraaper>
                        </Collapse>
                      </TabCardWrapper>
                    </MainCardWrapper>
                  </div>
                ))}
              </div>
            </ContentBody>
          </LeftSection>
          <RightSection data-component="RightSection" smActive={isActiveCard ? true : false}>
            <button className="sm-close-popup-btn" onClick={() => setIsActiveCard(false)}>
              <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
            </button>

            <SectionHeader data-component="SectionHeader">
              <ScenarioSectionWrapper data-component="ScenarioSectionWrapper">
                <ScenarioDropdown
                  options={scenarioList}
                  stepdata={stepdata}
                  setOptions={setScenarioList}
                  setStatus={props.setStatus}
                  callBackOnchange={(obj) => {
                    setIsMakeDefault(obj);
                  }}
                />
              </ScenarioSectionWrapper>
              {stepdata.isDefault == 0 ?
                <label className="default-scenario">
                  <input ref={makeDefaultRef} type="checkbox" onChange={() => setIsDefaultScenarioPopup(true)} />
                  <span>Make this your default scenario to be displayed on Home page</span>
                </label>
                :
                <p>This is your default scenario to be displayed on Home page</p>
              }
            </SectionHeader>
            <ContentBody data-component="ContentBody">
              <h3 className="blue-txt">IR4.0 Investment</h3>
              {/* Recoverable Popup */}
              {isRecoverPop &&
                <RecoverablePopWrap data-component="RecoverablePopWrap">
                  <RecoverPopCard data-component="RecoverPopCard">
                    <InfoIcon data-component="InfoIcon">
                      <i dangerouslySetInnerHTML={{ __html: infoIcon }}></i>
                    </InfoIcon>
                    <p>Click on the {`>`} icon to get more information and calibrate the Investment for each Roadmap step.</p>
                    <RecoverCloseBtn data-component="RecoverCloseBtn" onClick={() => setIsRecoverPop(false)}>
                      <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                    </RecoverCloseBtn>
                  </RecoverPopCard>
                </RecoverablePopWrap>
              }
              {/* Right side popu */}
              {isActiveCard &&
                <ScndRgtTabWrapper data-component="ScndRgtTabWrapper">
                  <ScndTabButton data-component="ScndTabButton">
                    <TBRow data-component="TBRow">
                      <TBLeftCol data-component="TBLeftCol">
                        <button className="drag-btn blue">
                          <span>{isActiveCard.count}</span>
                        </button>
                        <p className='rmap-txt'>
                          {isActiveCard.name}
                        </p>
                      </TBLeftCol>
                      <TBRightCol data-component="TBRightCol">
                        <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                          <RightAlignTextWrap data-component="RightAlignTextWrap">
                            <HeadText data-component="HeadText">Recoverable: </HeadText>.
                            <SubText data-component="SubText" green>{isActiveCard.totalrecoverable}SR</SubText>
                          </RightAlignTextWrap>
                          <RightAlignTextWrap data-component="RightAlignTextWrap">
                            <HeadText data-component="HeadText">Investment: </HeadText>
                            <SubText data-component="SubText" id={`invstss-${isActiveCard.id}`} orange>{isActiveCard.upfrontInvestment}SR</SubText>
                          </RightAlignTextWrap>
                        </RightAlignTextWrapper>
                        <BlueRecoverText data-component="BlueRecoverText">
                          <p className="loss_recov_det"><span>{isActiveCard?.totallossrecoverdp || 0}</span><span>%</span> of category <br />losses Recovered</p>
                        </BlueRecoverText>
                        <CloseTabBtn data-component="CloseTabBtn" className="sm-none" onClick={() => setIsActiveCard(false)}>
                          <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </CloseTabBtn>
                      </TBRightCol>
                    </TBRow>
                  </ScndTabButton>
                  <VendorTabPanel data-component="VendorTabPanel">
                    <ListInvestHead data-component="ListInvestHead">
                      <UserWrap data-component="UserWrap" style={{visibility:rightInputsValue[`unit_${isActiveCard?.id}`]?'':'hidden'}}>
                        <label className='user-txt'>{rightInputsValue[`unit_${isActiveCard?.id}`]}</label>
                        <input className='user-num'
                          id={`unit_qty_adj_${isActiveCard?.id}`}
                          key={`unit_qty_adj_${isActiveCard?.id}`}
                          value={rightInputsValue[`unit_qty_adj_${isActiveCard?.id}`]}
                          onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'unit_qty_adj', e)}
                          onBlur={(e) => handleRightInputsValueSubmitUnit(isActiveCard?.id, 'unit_qty_adj', e)}
                        />
                      </UserWrap>
                      <button className="clear" onClick={()=>clearRightData(isActiveCard?.id)}>Clear</button>
                    </ListInvestHead>
                    <ListInvestWrap data-component="ListInvestWrap">
                      <ListRow data-component="ListRow">
                        <p className='list-txt'></p>
                        <ListTableValWrap data-component="ListTableValWrap">
                          <span className="table-heading">Database Value</span>
                        </ListTableValWrap>
                        <ListTableValWrap data-component="ListTableValWrap">
                          <span className="table-heading">New Value</span>
                        </ListTableValWrap>
                      </ListRow>
                      <ListRow data-component="ListRow">
                        <p className='list-txt'>CAPEX</p>
                        <div className="sm-block">
                          <span className="table-heading">Database Value</span>
                        </div>
                        <ListValWrap as='label' data-component="ListValWrap" disabled>
                          <input
                            id={`capex_${isActiveCard?.id}`}
                            key={`capex_${isActiveCard?.id}`}
                            value={rightInputsValue[`capex_${isActiveCard?.id}`]}
                            onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'capex', e)}
                            onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'capex', e)}
                            className="input-value" disabled
                          />
                          <span className="money-icon">SR</span>
                        </ListValWrap>
                        <div className="sm-block">
                          <span className="table-heading">New Value</span>
                        </div>
                        <ListValWrap as='label' data-component="ListValWrap">
                          <input id={`capex_adj_${isActiveCard?.id}`}
                            key={`capex_adj_${isActiveCard?.id}`}
                            value={rightInputsValue[`capex_adj_${isActiveCard?.id}`]}
                            onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'capex_adj', e)}
                            onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'capex_adj', e)}
                            className="input-value"
                          />
                          <span className="money-icon">SR</span>
                        </ListValWrap>
                      </ListRow>
                      {/*  CAPEX Row end*/}
                      <ListRow data-component="ListRow">
                        <p className='list-txt'>Starting OPEX</p>
                        <div className="sm-block">
                          <span className="table-heading">Database Value</span>
                        </div>
                        <ListValWrap as='label' data-component="ListValWrap" disabled>
                          <input
                            id={`starting_opex_${isActiveCard?.id}`}
                            key={`starting_opex_${isActiveCard?.id}`}
                            value={rightInputsValue[`starting_opex_${isActiveCard?.id}`]}
                            onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'starting_opex', e)}
                            onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'starting_opex', e)}
                            className="input-value" disabled
                          />
                          <span className="money-icon">SR</span>
                        </ListValWrap>
                        <div className="sm-block">
                          <span className="table-heading">New Value</span>
                        </div>
                        <ListValWrap as='label' data-component="ListValWrap">
                          <input
                            id={`starting_opex_adj_${isActiveCard?.id}`}
                            key={`starting_opex_adj_${isActiveCard?.id}`}
                            value={rightInputsValue[`starting_opex_adj_${isActiveCard?.id}`]}
                            onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'starting_opex_adj', e)}
                            onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'starting_opex_adj', e)}
                            className="input-value" />
                          <span className="money-icon">SR</span>
                        </ListValWrap>
                      </ListRow>
                      {/*Starting OPEX Row end*/}
                      <UpfrontListRow data-component="UpfrontListRow">
                        <p className='black-txt'>Upfront Investment</p>
                        <div className="sm-block">
                          <span className="table-heading">Database Value</span>
                        </div>
                        <ListValWrap as='label' data-component="ListValWrap" disabled>
                          <input
                            id={`upFront_investment_${isActiveCard?.id}`}
                            key={`upFront_investment_${isActiveCard?.id}`}
                            value={rightInputsValue[`upFront_investment_${isActiveCard?.id}`]}
                            onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'upFront_investment', e)}
                            onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'upFront_investment', e)}
                            className="input-value" disabled
                          />
                          <span className="money-icon">SR</span>
                        </ListValWrap>
                        <div className="sm-block">
                          <span className="table-heading">New Value</span>
                        </div>
                        <ListValWrap as='label' data-component="ListValWrap" disabled>
                          <input
                            id={`upFront_investment_adj_${isActiveCard?.id}`}
                            key={`upFront_investment_adj_${isActiveCard?.id}`}
                            value={rightInputsValue[`upFront_investment_adj_${isActiveCard?.id}`]}
                            onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'upFront_investment_adj', e)}
                            onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'upFront_investment_adj', e)}
                            className="input-value" disabled
                          />
                          <span className="money-icon">SR</span>
                        </ListValWrap>
                      </UpfrontListRow>
                      {/*Upfront investment Row end*/}
                    </ListInvestWrap>
                    <GoListWrap data-component="GoListWrap">
                      <p className='list-txt'>Going OPEX</p>
                      <div className="sm-block">
                        <span className="table-heading">Database Value</span>
                      </div>
                      <ListValWrap as='label' data-component="ListValWrap" disabled>
                        <input
                          id={`ongoing_opex_${isActiveCard?.id}`}
                          Key={`ongoing_opex_${isActiveCard?.id}`}
                          value={rightInputsValue[`ongoing_opex_${isActiveCard?.id}`]}
                          onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'ongoing_opex', e)}
                          onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'ongoing_opex', e)}
                          className="input-value" disabled
                        />
                        <span className="money-icon">SR</span>
                      </ListValWrap>
                      <div className="sm-block">
                        <span className="table-heading">New Value</span>
                      </div>
                      <ListValWrap as='label' data-component="ListValWrap">
                        <input
                          id={`ongoing_opex_adj_${isActiveCard?.id}`}
                          key={`ongoing_opex_adj_${isActiveCard?.id}`}
                          value={rightInputsValue[`ongoing_opex_adj_${isActiveCard?.id}`]}
                          onChange={(e) => handleRightInputsValue(isActiveCard?.id, 'ongoing_opex_adj', e)}
                          onBlur={(e) => handleRightInputsValueSubmit(isActiveCard?.id, 'ongoing_opex_adj', e)}
                          className="input-value"
                        />
                        <span className="money-icon">SR</span>
                      </ListValWrap>
                    </GoListWrap>
                    {/*Going OPEX Row end*/}
                  </VendorTabPanel>
                </ScndRgtTabWrapper>
              }
            </ContentBody>
            <ContentFooter data-component="ContentFooter">
              <Link to={`/roadmap-complete/step2/${stepdata.slug}/${stepdata.sid}`} className='prev-btn'>Previous</Link>
              <a className='proceed-btn' onClick={() => setOpenPopup(true)}>Proceed</a>
              {/* Proceed popu */}
              {openPopup ?
                <ProceedPopWrapper data-component="ProceedPopWrapper">
                  <ProceedPopCard data-component="ProceedPopCard">
                    <PopHead data-component="PopHead">
                      <span>Caution</span>
                      <button className="close-pop" onClick={() => setOpenPopup(false)}>
                        <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                      </button>
                    </PopHead>
                    <p className='pop-txt'>You have unsaved changes. Please save roadmap before proceeding.</p>
                    <PopFooter data-component="PopFooter">
                      <button className='save-btn solid' onClick={saveRoadmap}>Save & Proceed</button>
                      <button className='leave-btn transparent' onClick={() => setOpenPopup(false)}>Leave</button>
                    </PopFooter>
                  </ProceedPopCard>
                </ProceedPopWrapper> : ''}
            </ContentFooter>
          </RightSection>
        </SectionWrapper>
      </MainWrapper>
      {isDefaultScenarioPopup &&
        <DefaultScenarioPopupWrapper data-component="DefaultScenarioPopupWrapper">
          <DefaultScenarioPop data-component="DefaultScenarioPop">
            <h2>Would you like to make this your Default Scenario?</h2>
            <p>The default scenario’s progress and results will displayed on Homepage</p>
            <div class="button-section">
              <button class="cancel" onClick={() => { setIsDefaultScenarioPopup(false); makeDefaultRef.current.checked = false; }}>Cancel</button>
              <button class="submit yes" id="making-default-scenario" onClick={yesMakeDefaultFunction}>Yes, make this my Default Scenario </button>
            </div>
          </DefaultScenarioPop>
        </DefaultScenarioPopupWrapper>
      }
      {isLoader &&
        <LoaderPopupWrap data-component="LoaderPopupWrap">
          <LoaderPop data-component="LoaderPop">
            <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
            <h1 style={{ marginTop: '15px', textAlign: 'center', visibility: typeof isLoader == 'string' ? '' : 'hidden' }}>{isLoader}</h1>
          </LoaderPop>
        </LoaderPopupWrap>

      }
    </>
  );
};

// export default DragDropContainer;
