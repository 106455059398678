/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * QuizView ===> custom view component
  * QuizViewReport ===> custom view component

**/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { QuizView } from "./quiz-view.js";
import { QuizViewReport } from "./quiz-view-report.js";
import "./quiz.scss";
//QuizController start
export const QuizController = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const [quizScore, setQuizScore] = useState(0);
	const [isAnsPoup, setIsAnsPopup] = useState(false);
	const [isCompletePopup, setIsCompletePopup] = useState(false);
	//function to handle unsaved answer popup
	const HandleUnsavedAnsPopup = () => {
		let tmpVal = !isAnsPoup;
		setIsAnsPopup(tmpVal);
	};
	//function to handle complete popup
	const HandleCompletePopup = () => {
		let tmpVal = !isCompletePopup;
		setIsCompletePopup(tmpVal);
	};
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		const search = async () => {
			const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const tid = getLastItem(window.location.pathname);
			const apiUrl = `/item?param=trainingquiz&slug=${tid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setQuizScore(data[0].score);
			setResults(data);
		};
		search();
	}, [quizScore]);
	console.log(quizScore);
	return quizScore < 0 ? (

		<QuizView
			quizData={results}
			onSubmitQuiz={setQuizScore}
			onSubmitQuizShow={setIsCompletePopup}
			isAnsPoup={isAnsPoup}
			HandleUnsavedAnsPopup={HandleUnsavedAnsPopup}
			{...props}
		/>
	) : (
		<QuizViewReport
			quizData={results}
			onSubmitQuiz={setQuizScore}
			isCompletePopup={isCompletePopup}
			HandleCompletePopup={HandleCompletePopup}
			{...props}
		/>
	);
};
