/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  *  Modal ===> component from UI used to work as popup

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
//Onboarding component start
export const Onboarding = (props) => {
	//defining variables here
	const user = JSON.parse(localStorage.getItem("userData"));
	const name = user ? user.name : "";
	useEffect(() => {
		serviceProvider.post("/home?type=setfirstloginseen", JSON.stringify({ uid: props.uid }),true).then((response) => {
			// props.onTourComplete("platform");
		});
	}, []);
	useEffect(() => {
		serviceProvider.post("/home?type=setplatform", JSON.stringify({ uid: props.uid }),true).then((response) => {
			//props.onTourComplete("platform");
		});
	}, []);
	
	const firstSlideData = [
		{
			img: "header-onboarding-1.gif",
			title: `Hi ${name}, welcome to SmarterChains platform`,
			paragraph: `We make Industry 4.0 happen, right here, right now. We want to quickly show you around in this quick platform tour. You can skip at any time.`,
		},
		{
			img: "header-onboarding-2.gif",
			title: `Top global manufacturing companies trust us​`,
			paragraph: `20% of the Top 25 Gartner Supply Chain and many Fortune 500 companies are SmarterChains Customers, who trust us with their Industry 4.0 transformation strategy.`,
		},
		{
			img: "header-onboarding-3.gif",
			title: `Pioneering Leadership Executive Education​`,
			paragraph: `After years of collaboration with IMD premier university, we won the prize for the best Industry 4.0 transformation case study. We also brought to life the first of its kind CPG study.​`,
		},
		{
			img: "header-onboarding-4.gif",
			title: `Accelerate your Industry 4.0 capabilities​`,
			paragraph: `Our unique virtual capability accelerator enables you to exponentially create value enterprise-wide as you move towards the 4 phases of the Industry 4.0 Journey.​`,
		},
	];
	const { HandleOnboarding, isOnboarding } = props;
	const TotalFirstSlides = firstSlideData.length;
	const [fistSlideIndex, setFistSlideIndex] = useState(0);
	// function to handle first slide
	const HandleFirstSlide = (ev) => {
		if (ev === "prev" && fistSlideIndex > 0) {
			let tmpVal = fistSlideIndex - 1;
			setFistSlideIndex(tmpVal);
		} else if (ev === "next" && fistSlideIndex < TotalFirstSlides - 1) {
			let tmpVal = fistSlideIndex + 1;
			setFistSlideIndex(tmpVal);
		} else if (ev !== "next" && ev !== "prev") {
			setFistSlideIndex(ev);
		}
	};
	//function to close
	const HandleClose = () => {
		HandleOnboarding();
		HandleFirstSlide(0);
	};
	return (
		<Modal open={isOnboarding} className={`onboarding-modal bg-op-white`}>
			<>
				<div className="first-type-modal">
					{firstSlideData.map((item, index) => (
						/* card has been divided into
							button
							header-1
							content-1
							footer-1
						*/
						<div className="card-1" key={index} style={{ display: index !== fistSlideIndex ? "none" : "" }}>
							{/* close button */}
							<button className="close-btn" onClick={HandleClose}>
								<img src={require(`../../assets/img/common-img/close-icon-blue.svg`).default} alt="X" />
							</button>
							{/* this section has image */}
							<div className="header-1">
								<div className="div-img">
									<img src={require(`../../assets/img/home-index/${item.img}`).default} alt="true" />
								</div>
							</div>
							{/* header-1 end */}
							{/*	this section has 
								title
								paragraph 
							*/}
							<div className="content-1">
								<h2 className="title-h1">{item.title}</h2>
								<p>{item.paragraph}</p>
							</div>
							{/* content-1 end */}
							{/* footer has
								dots section to navigate
								previous button
								next button
							*/}
							<div className="footer-1">
								<div className="dots-sec">
									<button onClick={() => HandleFirstSlide(0)} disabled={fistSlideIndex === 0 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(1)} disabled={fistSlideIndex === 1 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(2)} disabled={fistSlideIndex === 2 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(3)} disabled={fistSlideIndex === 3 ? true : false}></button>
								</div>
								{fistSlideIndex === 0 && <button onClick={HandleClose}>Skip</button>}
								{fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides - 1 && (
									<button onClick={() => HandleFirstSlide("prev")}>Previous</button>
								)}
								{fistSlideIndex === TotalFirstSlides - 1 && <button onClick={HandleClose}>No thanks</button>}
								{fistSlideIndex === 0 && (
									<button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
										Take tour
									</button>
								)}
								{fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides - 1 && (
									<button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
										Next
									</button>
								)}
								{fistSlideIndex === TotalFirstSlides - 1 && (
									<Link to="/home-tour" className="btn-blue">
										Show me around
									</Link>
								)}
							</div>
							{/* footer-1 end */}
						</div>
					))}
				</div>
			</>
		</Modal>
	);
};
