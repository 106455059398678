/** imported components
 
  * Helmet ===> for add specific title & CSS for the page.
  * LossanalysisQuesView for ===> custom view component

**/

import React from 'react';
import { Helmet } from 'react-helmet';
import {LossanalysisQuesView} from './la-questionnaires-view'
//LossanalysisQuesController start
export const LossanalysisQuesController=(props)=>{
  return(
      <>
        {/* Css for styling */}
        <Helmet>
            <link rel="stylesheet" href={require('./la-questionnaires.scss').default} />
        </Helmet>
        {/* custom view component */}
        <LossanalysisQuesView {...props}/>
      </>
  )

} 