/** imported components
 
  * QuestionView ===> view file of assessment component.
  * assessment.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import { QuestionView } from "./question-view";
import "./question.scss";
import serviceProvider from '../../services/axios'

//Qusetions Component start
export const QuestionsController = (props) => {
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const segments = window.location.pathname.slice(1).split("/");
	const usid = user ? user.uid : 0;
	console.log(segments);
	const [scid, setScid] = useState(segments[2]);
	const [qid, setQid] = useState(typeof segments[3] !== "undefined" ? segments[3] : 0);
	useEffect(() => {
		const search = async () => {
			// const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const apiUrl = `/assessment?param=start&scid=${scid}&qid=${qid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
		};
		search();
		// **cleanup**
		return () => {
			setResults([]);
		};
	}, [qid]);
	return <QuestionView quesData={results} onScid={setScid} onQid={setQid} {...props} />;
};
