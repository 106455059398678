import React, { useEffect, useState } from 'react';
import { NewSignInView } from './NewSignInView';


export const NewSignInCtrl = (props) => {
    return (
        <NewSignInView {...props}/>
    )
}

