import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ScenarioDropdown from "./ScenarioDropdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Link } from 'react-router-dom';
// images that we have used in page

import {
  angleIcon,
  closeSvg,
  collapseIcon,
  collapseOpenIcon,
  infoIcon,
  digitalSvg,
  addTimelineIcon,
  dropArrow,
  deleteIcon,
} from "./svg-step4";

// components that we have used in page

import {
  MainCardWrapper,
  TabCardWrapper,
  TabContentWraaper,
  RightAlignTextWrapper,
  HeadText,
  SubText,
  AddDragBtn,
  CollapseBtn,
  TabPanelContent,
  LossScrollTableWrap,
  InnerTabWrapper,
  InnerTBRightCol,
  LossTableWrap,
  ScndRgtTabWrapper,
  RightHeadingWrap,
  RightCloseBtn,
  TimeLineWrap,
  TimeLineCard,
  ImplementationWrap,
  AddTimeLine,
  LossTopWrap,
  TransFormWrap,
} from "./Roadmap-style-step4";

// common components that we have used in page

import{
  MainWrapper,
  SectionWrapper,
  LeftSection,
  RightSection,
  SectionHeader,
  ContentBody,
  ContentFooter,
  HeaderTitle,
  ScenarioSectionWrapper,
  TabButton,
  TBRow,
  TBLeftCol,
  TBRightCol,
  LossWrap,
  LossCol,
  LossRow,
  ProceedPopWrapper,
  ProceedPopCard,
  PopHead,
  PopFooter,  
  RecoverablePopWrap,
  RecoverCloseBtn,
  InfoIcon,
  RecoverPopCard, 
  ScndTabButton,
  RightAlignTextWrap,
}from "./common-roadmap-style";

export const RoadmapStepFourView = (props) => {
  //variable declaration
  const [isRecoverPop, setIsRecoverPop] = useState(true);
  const [scenarioList, setScenarioList] = useState([]);
  const [isActiveCard, setIsActiveCard] = useState(false);
  // Collapse variable
  const [leftOpen, setLeftOpen] = React.useState(false);;
  const [lossLeftDataOpen, setLossLeftDataOpen] = React.useState(false);
  // Popup
  const [openPopup,setOpenPopup] = useState(false);
  // array declaration
  const [leftCards, setLeftCards] = useState([
    { 
      id: `1`, 
      card_title: `Automated Guided Vehicles (AGVs)`,
      status: `HAS`,
      recoverable_value: `0`,
      investment: `17000`,
    },
    { 
      id: `2`, 
      card_title: `Blockchain`,
      status: `HAS NOT`,
      recoverable_value: `0`,
      investment: `1000`,
    },
    { 
      id: `3`, 
      card_title: `Cloud Infrastructure & Data Architecture `,
      status: `PARTIALLY`,
      recoverable_value: `0`,
      investment: `24000`,
    },
    { 
      id: `4`, 
      card_title: `Collaborative Robots`,
      status: `NA`,
      recoverable_value: `0`,
      investment: `1687000`,
    },
  ]);
  const [leftValue, setLeftValue] = React.useState(`l0-0`);
  // Timeline popup variables
  const [addTimeLine,setAddTimeLine]= useState([{id:`1`,start_of_implementation:`1`,end_of_implementation:`1`}]);
  // Tabs Function
  const handleLeftChange = (event, newValue) => {
    setLeftValue(newValue);
  };
 //Drodown start
 const data = [
    {
        id: 0, 
        label: "Foundation Stage"
    }, 
    {
        id: 1, 
        label: "Acceleration Stage"
    },
    {
        id: 2, 
        label: "Amplification Stage"
    },
];

 const [isOpen, setOpen] = useState(false);
 const [items, setItem] = useState(data);
 const [selectedItem, setSelectedItem] = useState(null);
 const toggleDropdown = () => setOpen(!isOpen);
 const handleItemClick = (id) => {
   selectedItem == id ? setSelectedItem(null) : setSelectedItem(id);
 }
 //End 
//  Tabs start
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value,index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
       
    </>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
// Tabs end
// Function to handle collapse
  const handleLeftClick = (id,index) => {
    setLeftValue(`l0-${index}`);
      if(leftOpen === id){
        setLeftOpen(false);  
      }
      else{
        setLeftOpen(id);
      }  
  };
// Function to handle inner collapse
  const handleLeftInnerTabClick = (val) => {
    if(lossLeftDataOpen === val){
      setLossLeftDataOpen(false);  
    }
    else{
      setLossLeftDataOpen(val);
    }
  };
// Function to handle popup
const handleRightCard =(item)=>{
  setIsActiveCard(item);
}
// Function to handle timeline
const handleAddTimeLine =()=>{
  let timestamp = new Date().getTime();
  setAddTimeLine(prv=>{
    return[
      ...prv,{id: timestamp, start_of_implementation:`1`, end_of_implementation:`1`}
    ]
  })
}
const handleRemoveTimeLine=(id)=>{
  let tmpFilteredArray = addTimeLine?.filter(item=>item.id!=id);
  setAddTimeLine(tmpFilteredArray);
}
  return (
    <>
    {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/home">Plant name</BreadcrumbsItem>
      <BreadcrumbsItem to="/roadmap-step1">IR4.0 Roadmap Solutions</BreadcrumbsItem>
      <BreadcrumbsItem to="/roadmap-step2">IR4.0 Savings</BreadcrumbsItem>
      <BreadcrumbsItem to="/roadmap-step3">IR4.0 Investment</BreadcrumbsItem>
      <BreadcrumbsItem to="#">IR4.0 Timeline Implementation</BreadcrumbsItem>
     {/* End */}
      <MainWrapper data-component="MainWrapper">
        <SectionWrapper data-component="SectionWrapper">
          <LeftSection data-component="LeftSection">
            <SectionHeader data-component="SectionHeader">
                <HeaderTitle data-component="HeaderTitle">
                  Roadmap
                </HeaderTitle>
            </SectionHeader>
            <LossTopWrap data-component="LossWrap">
                <LossRow data-component="LossRow">
                    <LossCol data-component="LossCol">
                        <span className="text">Total Losses</span>
                        <span className="text">Identified:</span>
                        <span className="text orange">1,145,160 €</span>
                    </LossCol>
                    <LossCol data-component="LossCol">
                        <span className="text">Total Roadmap</span>
                        <span className="text"> Recoverable:</span>
                        <span className="text green">156,899 €</span>
                    </LossCol>
                    <LossCol data-component="LossCol">
                        <span className="text">% of Recoverable</span>
                        <span className="text"> Losses:</span>
                        <span className="text blue">11%</span>
                    </LossCol>
                    <LossCol data-component="LossCol">
                        <span className="text">Total Roadmap</span>
                        <span className="text">Investment:</span>
                        <span className="text orange">3364000 €</span>
                    </LossCol>
                </LossRow>
            </LossTopWrap>
            <ContentBody data-component="ContentBody">
                  <div>
                    {leftCards?.map((card, index) => (
                          <div
                            className="card" key={index}
                          >
                            <MainCardWrapper
                              data-component="MainCardWrapper" 
                            >
                              <TabCardWrapper data-component="TabCardWrapper">
                                    <TabButton data-component="TabButton">
                                        <TBRow data-component="TBRow">
                                            <TBLeftCol data-component="TBLeftCol">
                                            <button
                                              className="drag-btn blue"
                                            >
                                              <span>{index+1}</span>
                                            </button>
                                                <p className='rmap-txt'>
                                                    {card.card_title}                                                                           
                                                </p>
                                            </TBLeftCol>
                                            <TBRightCol data-component="TBRightCol">
                                                <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                                    <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                        <SubText data-component="SubText" green>{card.recoverable_value}€</SubText>
                                                    </RightAlignTextWrap>
                                                    <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                        <HeadText data-component="HeadText">Investment: </HeadText>
                                                        <SubText data-component="SubText" orange>{card.investment}€</SubText>
                                                    </RightAlignTextWrap>
                                                </RightAlignTextWrapper>
                                                <CollapseBtn data-component="CollapseBtn" onClick={()=>handleLeftClick(card.id,index)} className={`${leftOpen===card.id?'active':''}`}>
                                                    {leftOpen===card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i>  : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                </CollapseBtn>
                                                <AddDragBtn data-component="AddDragBtn" onClick={()=>handleRightCard(card)}>
                                                    <i dangerouslySetInnerHTML={{ __html: angleIcon }}></i>
                                                </AddDragBtn>
                                            </TBRightCol>
                                        </TBRow>
                                    </TabButton>
                                    <Collapse in={leftOpen===card.id} timeout="auto" unmountOnExit>
                                        <TabContentWraaper data-component="TabContentWraaper">
                                            <Tabs
                                                    orientation="horizontal"
                                                    variant="scrollable"
                                                    value={leftValue}
                                                    onChange={handleLeftChange}
                                                    aria-label="Vertical tabs example"
                                                    
                                                    >
                                                    <Tab value={`l0-${index}`} label="Description" {...a11yProps(0)} />
                                                    <Tab value={`l1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                                            </Tabs>
                                        
                                        <TabPanel value={leftValue} index={`l0-${index}`}>
                                            <TabPanelContent data-component="TabPanelContent">
                                                <p>Moving forward with Industry 4.0, sensors, real-time location services & 
                                                workforce mobility become an integral part of manufacturing operations. Without reliable &
                                                effective industrial wireless, it’s difficult to imagine a true smart factory materialize. 
                                                In the Factory of the Future, a vast amount of sensors, devices, networks and protocols must 
                                                seamlessly interact to transport data over Bluetooth, Message Queuing Telemetry Transport, 
                                                Wi-Fi, Ethernet, narrowband cellular, and across the Wide Area Network, to routers, servers, 
                                                edge gateways and corporate data centres.</p>
                                                <p>The stack of network protocols must be:</p>
                                                <ul>
                                                    <li>Adaptive to constrained bandwidth</li>
                                                    <li>Power-efficient and low-interference</li>
                                                    <li>Secure across multiple system layers</li>
                                                </ul>
                                                <p>Similarly, networking equipment and software should maintain a fast and stable wireless 
                                                    connection in harsh industrial environments, and they should support the protocols, 
                                                    topologies and the sheer amount of data produced.
                                                </p>
                                                <p class="bc_head_h4">Benefits</p>
                                                <ul class="mb-08vw">
                                                    <li>Wireless networks give operations leaders access to vital production information across the site</li>
                                                    <li>Operators can track critical data on handheld devices, and receive real-time alerts from equipment</li>
                                                    <li>It is significantly more affordable than cable</li>
                                                    <li>It enables more flexibility in configuring the plant floor, which is critical when introducing new product lines or changing processes</li>
                                                </ul>
                                                <div class="bc_leadership">
                                                    <i dangerouslySetInnerHTML={{ __html: digitalSvg }}></i>
                                                    Digital-ready Infrastructure                                                                                    
                                                </div>
                                            </TabPanelContent>
                                        </TabPanel>
                                        <TabPanel value={leftValue} index={`l1-${index}`}>
                                            <InnerTabWrapper data-component="InnerTabWrapper">
                                                <TBRow data-component="TBRow">
                                                    <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                                        <RightAlignTextWrapper data-component="RightAlignTextWrapper">  
                                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                                <HeadText data-component="HeadText">Total Category Losses: </HeadText>
                                                                <SubText data-component="SubText" red>177,000€</SubText>
                                                            </RightAlignTextWrap>
                                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                                                <HeadText data-component="HeadText">Recoverable: </HeadText>
                                                                <SubText data-component="SubText" green>2,813€</SubText>
                                                            </RightAlignTextWrap>
                                                        </RightAlignTextWrapper>
                                                        <p className='recovery-txt'>
                                                            <span>8% </span>
                                                              of category <br/>losses Recovered
                                                        </p>
                                                        <CollapseBtn data-component="CollapseBtn" onClick={()=>handleLeftInnerTabClick(`loss0-${index}`)} className={`${lossLeftDataOpen===`loss0-${index}`?'active':''}`}>
                                                            {lossLeftDataOpen===`loss0-${index}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i>  : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                                        </CollapseBtn>
                                                    </InnerTBRightCol>
                                                </TBRow>
                                            </InnerTabWrapper>
                                            <Collapse in={lossLeftDataOpen===`loss0-${index}`} timeout="auto" unmountOnExit>
                                                <LossTableWrap data-component="LossTableWrap">
                                                    <LossScrollTableWrap data-component="LossScrollTableWrap">
                                                        <table className='responsive-loss-table'>
                                                            <thead>
                                                                <tr>    
                                                                    <th className='loss-table-col'>Sublosses</th>
                                                                    <th className='loss-table-col'>
                                                                        Identified<br/> Losses 
                                                                    </th>
                                                                    <th className='loss-table-col'>Recoverable</th>
                                                                    <th className='loss-table-col'>% of Subloss<br/>recoverable </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>    
                                                                    <td className='loss-table-col'>Reviewing production information to ensure quality compliant product release</td>
                                                                    <td className='loss-table-col orange'>78,139<span>€</span></td>
                                                                    <td className='loss-table-col green'>6,312<span>€</span></td>
                                                                    <td className='loss-table-col blue'>8%</td>
                                                                </tr>
                                                                <tr>    
                                                                    <td className='loss-table-col bottom black'>Total</td>
                                                                    <td className='loss-table-col bottom black'>78,139<span>€</span></td>
                                                                    <td className='loss-table-col bottom black'>6,312<span>€</span></td>
                                                                    <td className='loss-table-col bottom black'></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </LossScrollTableWrap>
                                                </LossTableWrap>
                                            </Collapse>
                                            <LossWrap data-component="LossWrap">
                                                <LossRow data-component="LossRow">
                                                    <LossCol data-component="LossCol">
                                                        <span className="text">Associated</span>
                                                        <span className="text">Losses:</span>
                                                        <span className="text green">8,943 €</span>
                                                    </LossCol>
                                                    <LossCol data-component="LossCol">
                                                        <span className="text">Total Technology</span>
                                                        <span className="text"> Recoverable:</span>
                                                        <span className="text orange">1,288 €</span>
                                                    </LossCol>
                                                    <LossCol data-component="LossCol">
                                                        <span className="text">% of Total</span>
                                                        <span className="text"> Recovered Losses:</span>
                                                        <span className="text blue">12%</span>
                                                    </LossCol>
                                                </LossRow>
                                            </LossWrap>
                                        </TabPanel>
                                        </TabContentWraaper>
                                    </Collapse>
                                </TabCardWrapper>
                            </MainCardWrapper>
                          </div>
                    ))}
                  </div>
            </ContentBody>
          </LeftSection>
          <RightSection data-component="RightSection">
            <SectionHeader data-component="SectionHeader">
              <ScenarioSectionWrapper data-component="ScenarioSectionWrapper">
                <ScenarioDropdown
                  options={scenarioList}
                  setOptions={setScenarioList}
                />
              </ScenarioSectionWrapper>
              <p>This is your default scenario to be displayed on Home page</p>
            </SectionHeader>
            <ContentBody data-component="ContentBody">
              <h3 className="blue-txt">IR4.0 Timeline Implementation</h3>
              {/* Recoverable Popup */}
              {isRecoverPop &&
                <RecoverablePopWrap data-component="RecoverablePopWrap">
                    <RecoverPopCard data-component="RecoverPopCard">
                        <InfoIcon data-component="InfoIcon">
                            <i dangerouslySetInnerHTML={{ __html: infoIcon }}></i>
                        </InfoIcon>
                        <p>Click on the {`>`} icon to add timelines and transformation stage for each Roadmap step.</p>
                        <RecoverCloseBtn data-component="RecoverCloseBtn" onClick = {() => setIsRecoverPop(false)}>
                            <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </RecoverCloseBtn>
                    </RecoverPopCard>
                </RecoverablePopWrap>
              }
              {/* Right side popup */}
              {isActiveCard &&
                <ScndRgtTabWrapper data-component="ScndRgtTabWrapper">
                    <RightHeadingWrap data-component="RightHeadingWrap">
                        <h3>{isActiveCard.card_title}</h3>
                        <RightCloseBtn data-component="RightCloseBtn" onClick={()=>setIsActiveCard(false)}>
                            <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </RightCloseBtn>
                    </RightHeadingWrap>
                    <ImplementationWrap data-component="ImplementationWrap">
                        <h5>Implementation Timeline</h5>
                        <p>Reccommended implementation period: <span>3 quarters</span></p>
                    </ImplementationWrap>
                    {addTimeLine?.map((timlineCard,index)=>(
                    <TimeLineWrap data-component="TimeLineWrap" key={index}>
                        <TimeLineCard data-component="TimeLineCard">
                            <div className="text-wrap">
                              <h6>Timeline {index+1}</h6>
                              <button className="del-btn" onClick={()=>handleRemoveTimeLine(timlineCard.id)}>
                                <i dangerouslySetInnerHTML={{ __html: deleteIcon }}></i>
                              </button>
                            </div>
                            
                            <div className="text-wrap">
                                <span>Start of Implementation</span>
                                <select>
                                    <option>Year 1 - Q1</option>
                                    <option>Year 1 - Q2</option>
                                    <option>Year 1 - Q3</option>
                                    <option>Year 1 - Q4</option>
                                </select>
                            </div>
                            <div className="text-wrap">
                                <span>End of Implementation</span>
                                <select>
                                    <option>Year 1 - Q1</option>
                                    <option>Year 1 - Q2</option>
                                    <option>Year 1 - Q3</option>
                                    <option>Year 1 - Q4</option>
                                </select>
                            </div>
                        </TimeLineCard>
                    </TimeLineWrap>
                    ))}
                    <AddTimeLine data-component="AddTimeLine" onClick={()=>handleAddTimeLine()}>
                        <i dangerouslySetInnerHTML={{ __html: addTimelineIcon }}></i>
                        <span>Add Timeline</span>
                    </AddTimeLine>
                    <TransFormWrap data-component="TransFormWrap">
                        <h6>Transformation Stage</h6>
                        <div className='dropdown'>
                            <div className='dropdown-header' onClick={toggleDropdown}>
                                {selectedItem ? items.find(item => item.id == selectedItem).label : "Select option"}
                                <i className={`icon-svg ${isOpen && "open"}`} dangerouslySetInnerHTML={{ __html: dropArrow }}></i>
                            </div>
                            <div className={`dropdown-body ${isOpen && 'open'}`}>
                                {items?.map(item => (
                                <div className={`dropdown-item ${selectedItem==item.id && 'active'}`} onClick={e => handleItemClick(e.target.id)} id={item.id}>
                                    {item.label}
                                </div>
                                ))}
                            </div>
                        </div>
                    </TransFormWrap>
                </ScndRgtTabWrapper>
              }
            </ContentBody>
            <ContentFooter data-component="ContentFooter">
                <Link to="/roadmap/step3" className='prev-btn'>Previous</Link>
                <button className='proceed-btn' onClick={()=>setOpenPopup(true)}>Proceed</button>
                {/* Proceed Popup */}
                {openPopup?
                <ProceedPopWrapper data-component="ProceedPopWrapper">
                    <ProceedPopCard data-component="ProceedPopCard">
                        <PopHead data-component="PopHead">
                            <span>Caution</span>
                            <button className="close-pop" onClick={()=>setOpenPopup(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                            </button>
                        </PopHead>
                        <p className='pop-txt'>You have unsaved changes. Please save roadmap before proceeding.</p>
                        <PopFooter data-component="PopFooter">
                            <button className='save-btn solid'>Save & Proceed</button>
                            <button className='leave-btn transparent'>Leave</button>
                        </PopFooter>
                    </ProceedPopCard>
                </ProceedPopWrapper>:''}
            </ContentFooter>
          </RightSection>
        </SectionWrapper>
      </MainWrapper>
    </>
  );
};

// export default DragDropContainer;
