/** imported components

  * Helmet ===> for add specific title & CSS for the page.
  * OperatorEffortView ===> custom view component.
 
**/
import React from 'react';
import { Helmet } from 'react-helmet';
import {OperatorEffortView} from './operator-effort-view'
//OperatorEffortController
export const OperatorEffortController=(props)=>{  
  return(
    <>
      {/* css for styling */}
      <Helmet>
          <link rel="stylesheet" href={require('./operator-effort.scss').default} />
      </Helmet>
      {/* custom view component */}
      <OperatorEffortView {...props}/>
    </>
  )
} 