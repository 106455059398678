/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * makeStyles for ===> styling material-ui component.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup
  * Accordion, AccordionSummary, AccordionDetails ===> component from material UI for accordion
  * Countdown ===> react component for countdown timer

**/
import React, { useState, useEffect,useLayoutEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Countdown from "react-countdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { apiEndpoints } from '../../services/api-endpoints';
//styling component
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		"& .MuiAccordion-root:before": {
			display: "none",
		},
		"& .MuiAccordionSummary-root": {
			padding: "0",
			minHeight: "0",
			"& .MuiAccordionSummary-content": {
				margin: "0",
			},
		},
		"& .MuiAccordionDetails-root": {
			padding: "0",
		},
	},
}));
 
//QuizViewReport component start
export const QuizViewReport = (props) => {
	const quizDataItems = [
    {
        "uid": "9",
        "id": "3",
        "certificate_ref_id": "4",
        "course_name": "Data-Driven Maintenance",
        "course_slug": "data-driven-maintenance",
        "course_icon": "data-driven-maintenance.svg",
        "course_snippet": "Participants will learn how Data-Driven Maintenance practices are key to driving operational efficiency and reducing downtime.",
        "course_technology": "Automatic Lubrication Systems, Condition Monitoring, CMMS, Predictive Maintenance, 3D Printing​.",
        "course_description": "The implementation of methods and systems to proactively manage maintenance based on data, such as equipment condition metrics and historical events.<br\/><br\/>Data-Driven Maintenance practices are key to driving operational efficiency and reducing downtime.",
        "course_image": "Data-driven-Maintenance-Training.png",
        "course_instructor": "5",
        "course_order": "4",
        "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/data-driven-maintenance",
        "price": "100",
        "meta_desc": "Learn how Data-Driven Maintenance practices are key to driving operational efficiency and reducing downtime.​",
        "meta_img": "Data-driven-Maintenance-Training.png",
        "is_imd": "0",
        "is_active": "1",
        "has_paid": 1,
        "certificateUrl": `${apiEndpoints.baseUrl}\/certificate\/generate\/OQ==\/Mw==`,
        "has_opt": "0",
        "has_completed": "1",
        "score": "100",
        "dateretest": "2021-10-05 22:03:22",
        "shortname": "AK",
        "name": "Amits",
        "last_name": "Kumar",
        "questions": [
            {
                "id": "60",
                "course_id": "3",
                "question": "What is the difference between prescriptive and predictive maintenance analytics?",
                "ques_type": "1",
                "ques_order": "1",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "229",
                        "question_id": "60",
                        "option_name": "Predictive maintenance analytics focus on understanding why something happened, building on the results of prescriptive analytics",
                        "is_correct": "0",
                        "is_checked": 0
                    },
                    {
                        "id": "230",
                        "question_id": "60",
                        "option_name": "Prescriptive maintenance analytics address what needs to be done next, building on the results of predictive analytics",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            },
            {
                "id": "61",
                "course_id": "3",
                "question": "What can Maintenance 4.0 offer to a manufacturing organization?",
                "ques_type": "2",
                "ques_order": "2",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "231",
                        "question_id": "61",
                        "option_name": "Increased asset availability",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "232",
                        "question_id": "61",
                        "option_name": "Increased data security",
                        "is_correct": "0",
                        "is_checked": 0
                    },
                    {
                        "id": "233",
                        "question_id": "61",
                        "option_name": "Asset health monitoring",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            },
            {
                "id": "63",
                "course_id": "3",
                "question": "Which of the following are true when bearings are lubricated automatically?",
                "ques_type": "2",
                "ques_order": "3",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "237",
                        "question_id": "63",
                        "option_name": "The need to replace bearings is minimized",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "238",
                        "question_id": "63",
                        "option_name": "Lubrication in some cases is excessive",
                        "is_correct": "0",
                        "is_checked": 0
                    },
                    {
                        "id": "239",
                        "question_id": "63",
                        "option_name": "Downtime due to faulty bearings is reduced",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            },
            {
                "id": "64",
                "course_id": "3",
                "question": "Which are some core functions of a Computerized Maintenance Management System (CMMS)? ",
                "ques_type": "2",
                "ques_order": "4",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "240",
                        "question_id": "64",
                        "option_name": "Procurement processing",
                        "is_correct": "0",
                        "is_checked": 1
                    },
                    {
                        "id": "241",
                        "question_id": "64",
                        "option_name": "Maintenance work tracking",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "242",
                        "question_id": "64",
                        "option_name": "Scheduling maintenance activities",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "243",
                        "question_id": "64",
                        "option_name": "Asset management",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "244",
                        "question_id": "64",
                        "option_name": "Parts and inventory management",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "245",
                        "question_id": "64",
                        "option_name": "Computing operational costs",
                        "is_correct": "0",
                        "is_checked": 0
                    }
                ]
            },
            {
                "id": "65",
                "course_id": "3",
                "question": "What is the difference between Computerized Maintenance Management Systems (CMMS) and Enterprise Asset Management (EAM)?",
                "ques_type": "1",
                "ques_order": "5",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "246",
                        "question_id": "65",
                        "option_name": "CMMS solutions include a full suite of functionalities for tracking assets and are often integrated with ERP for a complete view of a company’s expenses",
                        "is_correct": "0",
                        "is_checked": 0
                    },
                    {
                        "id": "247",
                        "question_id": "65",
                        "option_name": "EAM solutions include a full suite of functionalities for tracking assets and are often integrated with ERP for a complete view of a company’s expenses",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            },
            {
                "id": "66",
                "course_id": "3",
                "question": "Can you measure magnetic field distortions and current changes to identify cracks, corrosion and other defects by using ultrasonic monitoring? ",
                "ques_type": "1",
                "ques_order": "6",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "248",
                        "question_id": "66",
                        "option_name": "Yes",
                        "is_correct": "0",
                        "is_checked": 0
                    },
                    {
                        "id": "249",
                        "question_id": "66",
                        "option_name": "No",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            },
            {
                "id": "67",
                "course_id": "3",
                "question": "Which are the steps of the Condition Monitoring process?",
                "ques_type": "1",
                "ques_order": "7",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "250",
                        "question_id": "67",
                        "option_name": "Measurement, preprocessing, classification, feature extraction, condition classification",
                        "is_correct": "0",
                        "is_checked": 0
                    },
                    {
                        "id": "251",
                        "question_id": "67",
                        "option_name": "Measurement,  classification, preprocessing, feature extraction, condition classification",
                        "is_correct": "0",
                        "is_checked": 0
                    },
                    {
                        "id": "252",
                        "question_id": "67",
                        "option_name": "Measurement, preprocessing, feature extraction, classification, condition classification",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "253",
                        "question_id": "67",
                        "option_name": "Preprocessing, feature extraction, classification, measurement, condition classification",
                        "is_correct": "0",
                        "is_checked": 0
                    }
                ]
            },
            {
                "id": "68",
                "course_id": "3",
                "question": "What does Predictive Maintenance have to offer?",
                "ques_type": "2",
                "ques_order": "8",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "254",
                        "question_id": "68",
                        "option_name": "No need for sensors on equipment",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "255",
                        "question_id": "68",
                        "option_name": "Reduced equipment downtime",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "256",
                        "question_id": "68",
                        "option_name": "Eliminates all equipment breakdowns",
                        "is_correct": "0",
                        "is_checked": 1
                    },
                    {
                        "id": "257",
                        "question_id": "68",
                        "option_name": "Increased lifespan of assets",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            },
            {
                "id": "69",
                "course_id": "3",
                "question": "What is the difference of Predictive and Condition-based Maintenance?",
                "ques_type": "1",
                "ques_order": "9",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "258",
                        "question_id": "69",
                        "option_name": "Predictive Maintenance identifies if something is wrong in real-time conditions while Condition-based Maintenance focuses on detecting failures in the future",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "259",
                        "question_id": "69",
                        "option_name": "Condition-based Maintenance identifies if something is wrong in real-time conditions while Predictive Maintenance focuses on detecting failures in the future",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            },
            {
                "id": "71",
                "course_id": "3",
                "question": "Which of the following statements are true when comparing outsourcing and in-house 3D Printing?",
                "ques_type": "2",
                "ques_order": "10",
                "has_answered": 1,
                "is_correct": 1,
                "answers": [
                    {
                        "id": "262",
                        "question_id": "71",
                        "option_name": "Outsourcing 3D Printing requires upfront investment, while in-house does not",
                        "is_correct": "1",
                        "is_checked": 0
                    },
                    {
                        "id": "263",
                        "question_id": "71",
                        "option_name": "Outsourcing 3D Printing eliminates the demand for hiring or training operators, while in-house does not",
                        "is_correct": "1",
                        "is_checked": 1
                    },
                    {
                        "id": "264",
                        "question_id": "71",
                        "option_name": "In house 3D Printing comes with lower production costs when compared with outsourced",
                        "is_correct": "1",
                        "is_checked": 1
                    }
                ]
            }
        ]
    }
];
	if (!quizDataItems.length) {
		return null;
	}
	const [disabled, setDisabled] = useState(true);
	const [isQuizPopup, setIsQuizPopup] = useState(false);
	const [count, setCount] = useState(0);
	//function to handle quiz popup
	const HandleQuizPopup = () => {
		setIsQuizPopup(!isQuizPopup);
	};
	const quizDataItem = quizDataItems[0];
	if (quizDataItem.block == 1) {
		 return <Redirect to="/404" />;
	}

	if (quizDataItem.has_paid == 0) {
		window.location = "/";
		<Redirect to={`/training-courses/${quizDataItem.course_slug}`} />;
	}
	const Quizes = quizDataItem.questions.map((ques, index) => {
		return (
			// index == 0 ? true : false
			<Accordion key={ques.id} defaultExpanded={false} className={ques.is_correct == 1 ? "quiz__body__card green" : "quiz__body__card red"}>
				<AccordionSummary>
					{/* header has
						no_div
						middle_div
						last_div
					*/}
					<header>
						{/* this section has
							index
							image1
							image2
						*/}
						<div className="no__div">
							<span>{index + 1}</span>
							<img className="green" src={require("../../assets/img/quiz/done-green.svg").default} alt="true" />
							<img className="red" src={require("../../assets/img/quiz/cancel-red.svg").default} alt="true" />
						</div>
						{/* no__div end */}
						{/* this section has question*/}
						<div className="middle__div">
							<p className="b__text">{ques.question}</p>
						</div>
						{/* middle__div end */}
						{/* this section has point */}
						<div className="last__div">{ques.is_correct} point</div>
						{/* last__div end */}
					</header>
				</AccordionSummary>
				<AccordionDetails>
					{/* section for answers */}
					<div className="ans__div">
						{ques.ques_type == 1 ? (
							ques.answers.length ? (
								ques.answers.map((ans) => (
									<div key={ans.id}>
										<label className="quiz__radio">
											{/* defaultChecked */}
											<input
												type="radio"
												name={`ques-${ques.id}[]`}
												value={ans.id}
												defaultChecked={ans.is_checked ? "checked" : ""}
											/>
											{ques.is_correct == 0 && ans.is_checked == 1 ? (
												<span className="icon d-inlineBlock">
												</span>
											) : ques.is_correct == 1 && ans.is_checked == 1 ? (
												<span className="icon cor__icon"></span>
											) : (
												<span className="icon"></span>
											)}
											<span className="labe__text">{ans.option_name}</span>
										</label>
									</div>
								))
							) : (
								<></>
							)
						) : ques.answers.length ? (
							ques.answers.map((ans) => (
								<div key={ans.id}>
									<label className="quiz__chkbox">
										<input
											type="checkbox"
											name={`ques-${ques.id}[]`}
											value={ans.id}
											defaultChecked={ans.is_checked ? "checked" : ""}
										/>
										{ques.is_correct == 1 && ans.is_checked == 1 ? (
											<span className="correct__icon d-inlineBlock">
												<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
											</span>
										) : ques.is_correct == 0 && ans.is_checked == 1 ? (
											<span className="icon d-inlineBlock">
												<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
											</span>
										) : (
											<span className="icon">
												<img src={require("../../assets/img/quiz/chk-white.svg").default} alt="true" />
											</span>
										)}
										<span className="labe__text">{ans.option_name}</span>
									</label>
								</div>
							))
						) : (
							<></>
						)}
					</div>
				</AccordionDetails>
			</Accordion>
		);
	});
	const classes = useStyles();
	const resettest = () => {
		let uid = quizDataItem.uid;
		let cid = quizDataItem.id;
		serviceProvider.post("/item?type=resettest", JSON.stringify({ uid: uid, cid: cid }),true).then((response) => {
			// console.log(response.data);
			props.onSubmitQuiz(0);
		});
	};
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			// console.log("sss");
			setDisabled(false);
			let uid = quizDataItem.uid;
			let cid = quizDataItem.id;
			
			if (count == 0) {
				setIsQuizPopup(true);
				setCount(count + 1);
			}
			return (
				<button onClick={resettest} className="btn__retake__quiz">
					Retake Quiz
				</button>
			);
		} else {
			// Render a countdown
			return (
				<>
					<h2 className="svg__cir__h2">You can retry in:</h2>
					<h2 className="svg__cir__h1">
						{hours}h {minutes}m {seconds}s
					</h2>
				</>
			);
		}
	};

useLayoutEffect(()=>{
        let trainingScroll = document.querySelector('.training-content');
        trainingScroll.scrollTop = 0;
    })
	return (
		<>
			{/* page title */}
			<Helmet>
				<title>{quizDataItem.course_name} Quiz | SmarterChains</title>
				<meta name="description" content="Training Courses" />
			</Helmet>
			{/* breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/training-courses">Training Courses</BreadcrumbsItem>
			<BreadcrumbsItem to={`/training-courses/${quizDataItem.course_slug}`}>{quizDataItem.course_name}</BreadcrumbsItem>
			<BreadcrumbsItem to="#">Quiz</BreadcrumbsItem>
			{/* main wrapper has been divided into
				quiz__left__wrapp
				quiz__right__wrapp
			*/}
			<div className="quiz__main__wrapper">
				{/* this section has
					quiz__header
					quiz__body
				*/}
				<div className="quiz__left__wrapp">
					{/* this section has
						quiz__header__content
						quiz__header__img
					*/}
					<div className="quiz__header">
						{/* this section has
							link
							large text 
							small text
						*/}
						<div className="quiz__header__content">
							<h2 className="h1">
								{/* Removal of back button SCNV-3211 */}
								{/* <Link to={`/training-courses/${quizDataItem.course_slug}`} className="back__link">
									<img src={require("../../assets/img/quiz/back-arrow.svg").default} alt="true" />
								</Link> */}
								<span>{quizDataItem.course_name}</span>
							</h2>
							<p className="quiz__large">
								The quiz consists of 10 questions and each one rewards you with 1 point.<br/>Score 80% or higher
								to get your certificate.
							</p>
							<p className="small">
								Total points:<span>{quizDataItem.questions.length}</span>
							</p>
						</div>
						<div className="quiz__header__img">
							{/* 
							// 9-30-2021
							  <img src={require("../../assets/img/quiz/fof-quiz.svg").default} alt="true" /> 
							*/}
							
							<img src={require(`../../assets/img/training-quiz/tqicon-${quizDataItem.id}.svg`).default} alt="{quizDataItem.course_name}" />
						</div>
					</div>
					{/* quiz__header end */}
					{/* this section has quizes */}
					<div className="quiz__body">
						<div className={classes.root}>{Quizes}</div>
						{/* this section has button */}
						<div className="quiz__footer">
							<button id="btn__quiz_submit__btn" className="btn__submit" onClick={resettest} disabled={disabled}>
								Retake Quiz
							</button>
						</div>
					</div>
				</div>
				{/* quiz__left__wrapp  end */}
				{quizDataItem.score < 80 ? (
					<div className="quiz__right__wrapp">
						{/* card has
							progress circle
							countdown timer
						*/}
						<div className="card">
							<div className="svg__cir__wrapp">
								<SvgCircle value={quizDataItem.score} />
							</div>
							<Countdown key={quizDataItem.score} date={quizDataItem.dateretest} renderer={renderer} />
						</div>
					</div>
				) : (
					<div className="quiz__right__wrapp">
						{/* card has
							image
							heading
							subheading
						*/}
						<div className="card">
							<div className="svg__cir__wrapp">
								<SvgCircle value={quizDataItem.score} />
							</div>
							<h2 className="svg__cir__h2">Training completed</h2>
							<h2 className="svg__cir__h1 blue">Congrats!</h2>
						</div>
					</div>
				)}
				{/* complete */}
			</div>
			{/* quiz__main__wrapper end */}
			{/*quiz availble popup start*/}
			<Modal open={isQuizPopup} onClose={HandleQuizPopup} className="quiz__available__popup">
				{/* this popup has
					close-button
					heading
					text
					yes/no buttons
				
				*/}
				<div className="quiz__available__inner">
					<button className="close_btn" onClick={HandleQuizPopup}>
						<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="true" />
					</button>
					<h2 className="quiz__aval__h1">Quiz available!</h2>
					<h4 className="quiz__aval__h4">Do you want to retake the Quiz now? </h4>
					<div className="btn__wrapper">
						<button onClick={resettest} className="btn__yes__quiz__aval">
							Yes, I want to retry now
						</button>
						<button onClick={HandleQuizPopup} className="btn__no__quiz__aval">
							No, first I want to review my answers
						</button>
					</div>
				</div>
				{/* quiz__score__inner end */}
			</Modal>
			{/*score popup start*/}
			<Modal open={props.isCompletePopup} onClose={props.HandleCompletePopup} className="quiz__complete__popup">
				{quizDataItem.score > 79 ? (
					/* this popup has
						close-button
						heading
						text
						download-certificate button
					*/ 
					<div className="quiz__complete__inner">
						<button className="close_btn" onClick={props.HandleCompletePopup}>
							<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="true" />
						</button>
						<img src={require("../../assets/img/quiz/congrats-completed.svg").default} />
						<h2 className="quiz__aval__h1">Congratulations!</h2>
						<p className="quiz__aval__p">You've successfully completed the Quiz:</p>
						<h4 className="quiz__aval__h4">{quizDataItem.course_name}</h4>
						<div className="btn__wrapper">
							<a target="_blank" href={quizDataItem.certificateUrl} className="btn__yes__quiz__aval">
								Download your certificate
							</a>
						</div>
					</div>
				) : quizDataItem.score >= 0 ? (
					/* this popup has
						close-button
						heading
						text
						ok button
					*/ 
					<div className="quiz__score__inner">
						<button className="close_btn" onClick={props.HandleCompletePopup}>
							<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="true" />
						</button>
						<h2 className="title__h1">You scored</h2>
						<p className="txt__quiz__score">{quizDataItem.score}%</p>
						<p className="b">You have to score at least 80%</p>
						<p>You can retake the Quiz in 8 hours</p>
						<div className="btn__wrapp">
							<button className="btn__ok" onClick={props.HandleCompletePopup}>
								OK
							</button>
						</div>
					</div>
				) : (
					<></>
				)}
			</Modal>
			{/* unsaved file popup end */}
		</>
	);
};
//svgcircle component used to create progresss
const SvgCircle = (props) => {
	const { value } = props;
	return (
		<svg viewBox="0 0 36 36">
			<text className="percent__text" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="700">
				{value}
				<tspan fontSize="5">%</tspan>
			</text>
			<path
				className="circle-bg"
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke="#EBF5FA"
				strokeWidth="2.4"
				fill="none"
			></path>
			<path
				className="circle"
				strokeDasharray={`${value}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke={value < 80 && value > 0 ? "#FFBC1F" : value > 79 ? "#40D30D" : "transparent"}
				strokeWidth="2.4"
				fill="none"
			></path>
		</svg>
	);
};
