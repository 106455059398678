  import React, {useState, useEffect, useRef,memo} from "react";
  const StepScndView = (props) =>{
  return (
  <>
  <div className="container-fluid px-0">
    <div className="row outer-row no-gutters px-0">
      <div className="add-team-member-poup-outer" id="select_plant_name" style={{display: 'none'}}>
      </div>
      <section className="col asst-right-sec" style={{paddingLeft: '4vw'}}>
        {/* content header end  */}
        <div className="asst-scroll">
          <div className="asst-abt-content-body">
            <div className="row no-gutters px-0">
              <div className="col asst-abt-left">
                <div className="d-flex admin-page-empty-wrapper no-assesed-wrapper bc-master-wrapper">
                  <div className="col admin-page-empty-left">
                    <div className="admin-page-card">
                      <div className="row adm-card-header d-flex position-relative">
                        <div className="col-6 adm-col justify-content-start adm_col_lft_wrap">
                          <div className="d-flex rmap-header-right-wrapper align-items-center">
                            <div className="col-8 px-0">
                              Roadmap
                            </div>
                            <div className="col-4 pr-2">
                              <div className="bc-filter-sec">
                                <div className="d-flex justify-content-end">
                                  <button className="rmap-btn open-filter" data-target="#filpopup">
                                    <img src={require("../../assets/img/roadmap-img/filter-btn.svg").default} className="btn-save-img"/>
                                    {/* <span>Filters</span> */}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* col end */}
                        <div className="col-6 adm-col justify-content-center border-0 adm_col_rgt_wrap overflow-visible">
                          <div className="d-flex rmap-header-right-wrapper align-items-center">
                            <div className="col-12 px-0">
                              <div className="bc-scenario-box " id="scenarioBox1">
                                <button className="bc-scenario-box-header btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                                  <span className="scid-230">Roadmap Example</span>
                                  <svg className="ddl-arrow" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.175 0L5 3.7085L8.825 0L10 1.1417L5 6L0 1.1417L1.175 0Z" fill="#4D4D4F" />
                                  </svg>
                                </button>
                              </div>
                              <div className="add-team-member-poup-outer scenario_pop" id="add_scenario" style={{display: 'none'}}>
                                <div className="add-team-poup-flex-div">
                                  <div className="add-team-poup-content">
                                    <div className="d-flex align-items-center">
                                      <input type="hidden" id="invitePeoplepid" defaultValue />
                                      <div className="w-75">
                                        <h1 className="h1-blue-txt">Add Scenario
                                          <span id="add-pop-plant-name" />
                                        </h1>
                                      </div>
                                      <div className="w-25 text-right">
                                        <a href="javascript:void(0)" className="close-custom-poup" data-target="#select_plant_name">
                                          <img src={require("../../assets/img/roadmap-img/close-poup-blue-icon.svg").default} className="btn-close-img" alt />
                                        </a>
                                      </div>
                                    </div>
                                    <div className="form-group email-id-row add-team-email-row add-sc-row">
                                      <label htmlFor="scname" className="add-team-email-label">Scenario
                                        Name</label>
                                      <input type="text" id="scname" placeholder="Default Scenario" autoComplete="off" />
                                    </div>
                                    <div className="add-team-member-poup-tab-wrapper">
                                      <button className="popup-invite-btn add-scen-btn" data-pid={18}>Save Scenario</button>
                                    </div>
                                    <p id="addScErr" className="bc-box-err" style={{display: 'none'}} />
                                  </div>
                                </div>
                              </div>
                              <div className="add-team-member-poup-outer scenario_pop" id="rename_scenario" style={{display: 'none'}}>
                                <div className="add-team-poup-flex-div">
                                  <div className="add-team-poup-content">
                                    <div className="d-flex align-items-center">
                                      <input type="hidden" id="invitePeoplepid" defaultValue />
                                      <div className="w-75">
                                        <h1 className="h1-blue-txt">Rename Scenario
                                          <span id="add-pop-plant-name" />
                                        </h1>
                                      </div>
                                      <div className="w-25 text-right">
                                        <a href="javascript:void(0)" className="close-custom-poup" data-target="#select_plant_name">
                                          <img src={require("../../assets/img/roadmap-img/close-poup-blue-icon.svg").default} className="btn-close-img" alt />
                                        </a>
                                      </div>
                                    </div>
                                    <div className="form-group email-id-row add-team-email-row re-sc-row">
                                      <label htmlFor="scname_re" className="add-team-email-label">Scenario
                                        Name</label>
                                      <input type="text" id="scname_re" defaultValue />
                                      <input type="hidden" id="scid_re" defaultValue />
                                    </div>
                                    <div className="add-team-member-poup-tab-wrapper">
                                      <button className="popup-invite-btn re-scen-btn" data-pid={18}>Rename Scenario</button>
                                    </div>
                                    <p id="reScErr" className="bc-box-err" style={{display: 'none'}} />
                                  </div>
                                </div>
                              </div>
                              {/* active-scenario-box end */}
                            </div>
                            {/* col-4 end  */}
                            <div className="col-3 px-0">
                              <div className="d-flex justify-content-end">
                                {/* <button class="save-rmap-btn save-roadmap">
                                                                                                              <span>Save roadmap</span>
                                                                                                          </button> */}
                                {/* btn save raodmap */}
                              </div>
                              {/* flex end */}
                            </div>
                            {/* col-8 end */}
                          </div>
                          {/* rmap header right wrapper */}
                        </div>
                        {/* col end */}
                      </div>
                      {/*card header end */}
                      <div className="adm-card-body pb-0 p-0 position-relative border-0">
                        <div className="d-flex h-100">
                          <div className="col-6 ramp-col-p bc_lft-cont_wrap">
                            <div className="rm-techlist-wrapper" id="rp-area">
                              <div className="lft-rc-data-sec">
                                <div className="row loss_total_val">
                                  <div className="col-3">
                                    Total Losses<br />Identified:
                                    <div className="bc_tot_spa red"><span id="totalloss" data-totalloss="41979738.65320638">41,979,739</span>
                                      <cspan>$</cspan>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    Total Roadmap<br />Recoverable:
                                    <div className="bc_tot_spa green"><span id="totalrecoverable" data-totalrecoverable="3740338.3906567334">3,740,338</span>
                                      <cspan>$</cspan>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    % of Recoverable<br />Losses:
                                    <div className="bc_tot_spa blue"><span id="totallossper" data-totallossper="8.9098650401296">9</span><span>%</span>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    Total Roadmap<br />Investment:
                                    <div className="bc_tot_spa orange"><span id="totalinvestment" data-totalinvestment={3108000}>3,108,000</span>
                                      <cspan>$</cspan>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="left-add-scroll">
                                <div className="accordion" id="rmapr">
                                  <div id="node-32" attr-name="Industry 4.0 Strategy & Masterplan" attr-id={32} attr-order={1} attr-investment={0} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card card active switch dragged-active" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span className="rmap-rght-no">1</span>
                                        </a>
                                        <p className="rmap-txt">
                                          Industry 4.0 Strategy &amp;
                                          Masterplan </p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="l-reco l-reco-32" data-val>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="l-inv l-inv-32" data-val={0}>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="l-loss l-loss-32" data-val={0}>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-32" aria-expanded="false" aria-controls="removenodemodal-32">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close"/>
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open"/>
                                        </a>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={32}>
                                          <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="node-24" attr-name="Digital Skills Cultivation" attr-id={24} attr-order={2} attr-investment={0} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card card active switch dragged-active" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span className="rmap-rght-no">2</span>
                                        </a>
                                        <p className="rmap-txt">
                                          Digital Skills Cultivation </p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="l-reco l-reco-24" data-val>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="l-inv l-inv-24" data-val={0}>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="l-loss l-loss-24" data-val={0}>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-24" aria-expanded="false" aria-controls="removenodemodal-24">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close"/>
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open"/>
                                        </a>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={24}>
                                          <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="node-23" attr-name="Site Digital Champion" attr-id={23} attr-order={3} attr-investment={37000} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card card active switch dragged-active" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span className="rmap-rght-no">3</span>
                                        </a>
                                        <p className="rmap-txt">
                                          Site Digital Champion </p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="l-reco l-reco-23" data-val>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="l-inv l-inv-23" data-val={37000}>37,000</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="l-loss l-loss-23" data-val={0}>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-23" aria-expanded="false" aria-controls="removenodemodal-23">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close"/>
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open"/>
                                        </a>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={23}>
                                          <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="node-25" attr-name="IT/OT Organization Integration" attr-id={25} attr-order={4} attr-investment={150000} attr-recoverable attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card card active switch dragged-active" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span className="rmap-rght-no">4</span>
                                        </a>
                                        <p className="rmap-txt">
                                          IT/OT Organization Integration </p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="l-reco l-reco-25" data-val>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="l-inv l-inv-25" data-val={150000}>150,000</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="l-loss l-loss-25" data-val={0}>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-25" aria-expanded="false" aria-controls="removenodemodal-25">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close"/>
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open"/>
                                        </a>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={25}>
                                          <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="node-27" attr-name="Edge Computing & Edge Gateways/Devices" attr-id={27} attr-order={5} attr-investment={150000} attr-recoverable="834.79032" attr-loss="13913.172" data-filter="type1 priority1" className="rmap-parent-card card active switch dragged-active" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span className="rmap-rght-no">5</span>
                                        </a>
                                        <p className="rmap-txt">
                                          Edge Computing &amp; Edge
                                          Gateways/Devices </p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="l-reco l-reco-27" data-val="834.79032">835</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="l-inv l-inv-27" data-val={150000}>150,000</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="l-loss l-loss-27" data-val="13913.172">13,913</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-27" aria-expanded="false" aria-controls="removenodemodal-27">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close"/>
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open"/>
                                        </a>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={27}>
                                          <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="node-30" attr-name="Industrial Tablets" attr-id={30} attr-order={6} attr-investment={571000} attr-recoverable="236492.4133621704" attr-loss="3129554.7312601916" data-filter="type1 priority1" className="rmap-parent-card card active switch dragged-active" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span className="rmap-rght-no">6</span>
                                        </a>
                                        <p className="rmap-txt">
                                          Industrial Tablets </p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="l-reco l-reco-30" data-val="236492.4133621704">236,492</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="l-inv l-inv-30" data-val={571000}>571,000</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="l-loss l-loss-30" data-val="3129554.7312601916">3,129,555</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-30" aria-expanded="false" aria-controls="removenodemodal-30">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close"/>
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open"/>
                                        </a>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={30}>
                                          <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="node-37" attr-name="Connected Workforce Platform" attr-id={37} attr-order={7} attr-investment={675000} attr-recoverable="1075105.0851266505" attr-loss="8350847.987869731" data-filter="type1 priority1" className="rmap-parent-card card active switch dragged-active" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span className="rmap-rght-no">7</span>
                                        </a>
                                        <p className="rmap-txt">
                                          Connected Workforce Platform </p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="l-reco l-reco-37" data-val="1075105.0851266505">1,075,105</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="l-inv l-inv-37" data-val={675000}>675,000</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="l-loss l-loss-37" data-val="8350847.987869731">8,350,848</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#removenodemodal-37" aria-expanded="false" aria-controls="removenodemodal-37">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close"/>
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open"/>
                                        </a>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node show-rnode" attr-id={37}>
                                          <svg className="bc_right_arrow" width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.719217 14.8306C0.323288 14.4394 0.32294 13.8002 0.718442 13.4086L5.46318 8.71059C5.85838 8.31929 5.85838 7.68071 5.46318 7.2894L0.718442 2.59137C0.32294 2.19976 0.323289 1.56059 0.719217 1.16941L1.20001 0.69439C1.58953 0.30954 2.21613 0.309541 2.60566 0.694391L9.28 7.28864C9.67623 7.68012 9.67623 8.31988 9.28 8.71136L2.60566 15.3056C2.21613 15.6905 1.58953 15.6905 1.20001 15.3056L0.719217 14.8306Z" fill="#828282" />
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* col 6 */}
                          <div className="col-6 ramp-col-p bc_rgt-cont_wrap bc_rec_calib">
                            <div id="dropzonearea" className="droparea" data-drop-target="true">
                              <div id="roadmap-nodes" className="add-scroll h-100">
                                <p className="bc_mn_head">Recoverable Calibrator</p>
                                <div className="row loss_total_val bc_remove_info">
                                  <div className="col-12 bc_alert_msg">
                                    <span className="bc_astriq"><img src={require("../../assets/img/roadmap-img/bc-astriq.svg").default}/></span>
                                    <p>Click on the &gt; icon to get more
                                      information and calibrate the Recoverable
                                      for each Roadmap
                                      step.</p>
                                    <a href="javascript:void(0)" className="bc_close_alert"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                  </div>
                                </div>
                                <div className="accordion show-rsnode" id="rside-37" style={{}}>
                                  <div className="card">
                                    <div className="card-header" id="rmaphead1">
                                      <div className="rmap-card-content">
                                        {/* drag & drop */}
                                        <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                          <span>7</span>
                                        </a>
                                        {/* Tech title */}
                                        <p className="rmap-txt">Connected Workforce
                                          Platform</p>
                                      </div>
                                      <div className="add_expand_wrapper">
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">
                                              Recoverable: <cdiv className="green"><span className="r-reco r-reco-37" data-val="1075105.0851266505">1,075,105</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">
                                              Investment: <cdiv className="orange"><span className="r-inv r-inv-37" data-val={675000}>675,000</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment d-none">
                                              Loss: <cdiv className="orange">
                                                <span className="r-loss r-loss-37" data-val="8350847.987869731">8,350,848</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                          </ul>
                                        </div>
                                        {/* % loss recover */}
                                        <p className="loss_recov_det pr-3"><span className="r-lossperc r-lossperc-37" data-val="12.874202556295">13</span><span>%</span>
                                          of category <br />losses Recovered
                                        </p>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn remove-node hide-node" attr-id={37}>
                                          <img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="bc_losess_level_wrap">
                                      <div className="bc_rec_content_wrap mt-4">
                                        <label className="bc_com_label">Line
                                          Level</label>
                                        <div className="bc-scenario-sel bc_line_level" id="scenarioBox2">
                                          <select className="sel-line-level mt-3" attr-nid={37} name="line-level">
                                            <option value={24}>Primary
                                            </option>
                                            <option value={25}>S. BU1-4
                                            </option>
                                            <option value={26}>S. Cigarillos
                                            </option>
                                            <option value={27}>S. Filter
                                              Making</option>
                                          </select>
                                          {/* active-scenario-box end */}
                                        </div>
                                        <div className="bc_level_option_wrap mt-3">
                                          <ul className="list-unstyled line-lev-ul-37" id="line-lev-37-24">
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={24} attr-cid={103}>
                                                Operator Effort Losses
                                                <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={24} attr-cid={1}>
                                                Planned Downtime <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={24} attr-cid={4}>
                                                Speed Losses <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                          </ul>
                                          <div id="bc_losess-37-24-103" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>Primary</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Operator
                                                        Effort
                                                        Losses
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Accessing
                                                        work
                                                        assistance
                                                        instructions
                                                        (process
                                                        manuals,
                                                        SOPs)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="105,900" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={24} data-slcid={50} data-tloss="105900.39888399" defaultValue={10} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-24-50" disabled defaultValue="10,590" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <div id="bc_losess-37-24-1" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>Primary</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Planned
                                                        Downtime
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Cleaning,
                                                        Inspection,
                                                        Lubrication
                                                        (CIL)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="543,336" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={24} data-slcid={5} data-tloss="543335.53522998" defaultValue={14} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-24-5" disabled defaultValue="76,067" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Product
                                                        changeovers
                                                        (brand,
                                                        product
                                                        size)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="1,313,061" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={24} data-slcid={4} data-tloss="1313060.8768058" defaultValue={18} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-24-4" disabled defaultValue="236,351" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <div id="bc_losess-37-24-4" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>Primary</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Speed
                                                        Losses
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Inefficient
                                                        machine
                                                        settings/parameters
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="45,278" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={24} data-slcid={32} data-tloss="45277.961269165" defaultValue="3.45" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-24-32" disabled defaultValue="1,562" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Poor
                                                        lubrication
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="23,771" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={24} data-slcid={31} data-tloss="23770.929666312" defaultValue="6.06" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-24-31" disabled defaultValue="1,441" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <ul className="list-unstyled line-lev-ul-37" id="line-lev-37-25" style={{display: 'none'}}>
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={25} attr-cid={103}>
                                                Operator Effort Losses
                                                <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={25} attr-cid={1}>
                                                Planned Downtime <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                          </ul>
                                          <div id="bc_losess-37-25-103" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>S. BU1-4</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Operator
                                                        Effort
                                                        Losses
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Accessing
                                                        work
                                                        assistance
                                                        instructions
                                                        (process
                                                        manuals,
                                                        SOPs)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="247,009" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={25} data-slcid={50} data-tloss="247008.52200427" defaultValue="18.4" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-25-50" disabled defaultValue="45,450" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Product
                                                        rework
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="823,362" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={25} data-slcid={56} data-tloss="823361.74001424" defaultValue={6} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-25-56" disabled defaultValue="49,402" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <div id="bc_losess-37-25-1" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>S. BU1-4</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Planned
                                                        Downtime
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Cleaning,
                                                        Inspection,
                                                        Lubrication
                                                        (CIL)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="1,900,963" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={25} data-slcid={5} data-tloss="1900963.2011384" defaultValue={14} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-25-5" disabled defaultValue="266,135" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Product
                                                        changeovers
                                                        (brand,
                                                        product
                                                        size)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="607,252" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={25} data-slcid={4} data-tloss="607252.13369699" defaultValue={18} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-25-4" disabled defaultValue="109,305" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Training
                                                        activities
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="26,402" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={25} data-slcid={7} data-tloss="26402.266682478" defaultValue="11.67" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-25-7" disabled defaultValue="3,080" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <ul className="list-unstyled line-lev-ul-37" id="line-lev-37-26" style={{display: 'none'}}>
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={26} attr-cid={1}>
                                                Planned Downtime <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                          </ul>
                                          <div id="bc_losess-37-26-1" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>S. Cigarillos</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Planned
                                                        Downtime
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Cleaning,
                                                        Inspection,
                                                        Lubrication
                                                        (CIL)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="439,078" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={26} data-slcid={5} data-tloss="439077.69561648" defaultValue={14} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-26-5" disabled defaultValue="61,471" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Product
                                                        changeovers
                                                        (brand,
                                                        product
                                                        size)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="232,980" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={26} data-slcid={4} data-tloss="232980.00175568" defaultValue={18} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-26-4" disabled defaultValue="41,936" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Training
                                                        activities
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="17,922" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={26} data-slcid={7} data-tloss="17921.538596591" defaultValue="11.66" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-26-7" disabled defaultValue="2,091" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <ul className="list-unstyled line-lev-ul-37" id="line-lev-37-27" style={{display: 'none'}}>
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={27} attr-cid={103}>
                                                Operator Effort Losses
                                                <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                            <li>
                                              <a href="javascript:void(0)" className="bc_line_open_poup" attr-nid={37} attr-lid={27} attr-cid={1}>
                                                Planned Downtime <svg className="rgt-bl-arrow" width={5} height={8} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M0 7.06L3.05333 4L0 0.94L0.94 0L4.94 4L0.94 8L0 7.06Z" fill="#4D4D4F">
                                                  </path>
                                                </svg>
                                              </a>
                                            </li>
                                          </ul>
                                          <div id="bc_losess-37-27-103" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>S. Filter Making</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Operator
                                                        Effort
                                                        Losses
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Accessing
                                                        work
                                                        assistance
                                                        instructions
                                                        (process
                                                        manuals,
                                                        SOPs)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="60,297" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={27} data-slcid={50} data-tloss="60296.693081851" defaultValue="18.4" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-27-50" disabled defaultValue="11,095" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                          <div id="bc_losess-37-27-1" className="bc_losess_level_popup">
                                            <div className="bc_losess_level_popup_close_wrap">
                                              <a href="javascript:void(0)" className="bc_losess_close_btn"><img src={require("../../assets/img/roadmap-img/bc-close-icon.svg").default}/></a>
                                              <p>S. Filter Making</p>
                                              <div className="bc_data_table">
                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" className="bc_first_col">
                                                        Planned
                                                        Downtime
                                                      </th>
                                                      <th scope="col" className="bc_second_col">
                                                        Losses
                                                        <br />Per
                                                        Year
                                                      </th>
                                                      <th scope="col" className="bc_third_col">
                                                        % of
                                                        <br />Recoverable
                                                      </th>
                                                      <th scope="col" className="bc_fourth_col">
                                                        Recoverable
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <th scope="row">
                                                        Cleaning,
                                                        Inspection,
                                                        Lubrication
                                                        (CIL)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="413,339" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={27} data-slcid={5} data-tloss="413339.18962276" defaultValue={14} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-27-5" disabled defaultValue="57,867" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Product
                                                        changeovers
                                                        (brand,
                                                        product
                                                        size)
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="44,685" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={27} data-slcid={4} data-tloss="44685.317797055" defaultValue={18} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-27-4" disabled defaultValue="8,043" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th scope="row">
                                                        Training
                                                        activities
                                                      </th>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group red-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" disabled defaultValue="22,343" />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group blue-box">
                                                            <input type="text" className="form-control inp-perc perc-line-loss" data-snid={37} data-lid={27} data-slcid={7} data-tloss="22342.658898528" defaultValue={0} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">%</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="form-group">
                                                          <div className="input-group green-box inp_disabled">
                                                            <input type="text" className="form-control inp_disabled" id="inp-rec-37-27-7" disabled defaultValue={0} />
                                                            <div className="input-group-append">
                                                              <span className="input-group-text">$</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* /line level */}
                                    </div>
                                  </div>
                                </div>
                                <div id="bc_proceed" className="bc_proceed_btn_wrap">
                                  <a href="automation/krakow-test/step1/230" className="btn all_btn_white bc-prev-btn proceed-btn">Previous</a>
                                  <a href="automation/krakow-test/step3/230" className="btn all_btn_white proceed-btn">Proceed</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* col 6  */}
                        </div>
                        {/* d felx end */}
                      </div>
                      {/* card body end */}
                    </div>
                    {/* admin page card end */}
                  </div>
                  {/* left sec */}
                </div>
                {/*admin-page-empty-wrapper end */}
              </div>
              {/*asst-abt-left end*/}
            </div>
            {/* row end */}
          </div>
          {/* asst content body end*/}
        </div>
        {/* asst-scroll end */}
      </section>
      {/* right sec end */}
    </div>
    {/* outer row end */}
  </div>
  </>
  );
  };
  export default memo(StepScndView);