/** imported components
 
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * $ from jquery ===> component from jquery to use jquery for the page.

**/

import React, { useState, useEffect } from "react";
import $ from "jquery";

//IncrementDecrement Component
export const IncrementDecrement = (props) => {
	//variable decalaration
	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}
	const quesDataItem = props.quesData[0];
	const {activeRightTab, setActiveRightTab} = props;
	const {qlength, setQlenth} = props;
	const changeValue = (e) => {
		let targetInputId = e.target.getAttribute("data-target");
		let operationType = e.target.getAttribute("data-operation");
		let tmpVal = Number($(targetInputId).val());
		if (operationType == "plus") {
			$(targetInputId).val(tmpVal + 1);
		} else {
			tmpVal > 0 && $(targetInputId).val(tmpVal - 1);
		}
	};
	
	const checklist = quesDataItem.answers.map((item, index) => {
		var val = 0;
		var val_old = 0;
		if (quesDataItem.savedans.length) {
			var sarr = JSON.parse(quesDataItem.savedans);
			for (var i = 0; i < sarr.length; i++) {
				var value = sarr[i];
				if (value["option_id"] == item.id) {
					val = value["line_id"];
				}
			}
		}
		if (quesDataItem.question.id == 622) {
			var sarr = JSON.parse(quesDataItem.savedans621);
			for (var i = 0; i < sarr.length; i++) {
				var value = sarr[i];
				if (value["option_id"] == 2417) {
					val_old = value["line_id"];
				}
			}
		}
		return (
			// Answer wrapper for plus-minus format
			<div className="choose-one-card" key={index}>
				{/* left part whiuch is containing anwer type */}
				<div className="card-left" style={{pointerEvents:quesDataItem.is_datasubmitted==1?'none':''}}>
					{item.option_name && (
						<div className="lft-text-div">
							<p>{item.option_name}</p>
						</div>
					)}
					{/* div is containing plus minus buttons */}
					<div className="lft-plus-minus-div">
						<button
							className="min btn-plus-minus"
							data-target={`#text-${item.id}`}
							data-operation="minus"
							onClick={(e) => changeValue(e)}
						>
							-
						</button>
						<input
							id={`text-${item.id}`}
							data-id={item.id}
							type="text"
							defaultValue={val}
							onKeyPress={(event) => {
								if (!/[0-9]/.test(event.key)) {
								event.preventDefault();
								}
							}}
							className="mins-plus-text opplusminus label"
							name="line_value[]"
							old-value={val_old}
						/>
						<button className="add btn-plus-minus" data-target={`#text-${item.id}`} data-operation="plus" onClick={(e) => changeValue(e)}>
							+
						</button>
					</div>
				</div>
				{/* Right part is conditional */}
				{item.answer_description && (
					<div className={props.clickedCardNo === index ?  props.activeRightTab == 0 ? "card-right active" :"card-right" : "card-right"}>
						<img
							className="inactive"
							src={require("../../assets/img/multi-choice/non-active-info.svg").default}
							alt="true"
							onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)}
						/>
						<img
							src={require("../../assets/img/multi-choice/active-info.svg").default}
							onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)}
							className="active"
							alt="true"
						/>
					</div>
				)}
			</div>
		);
	});

	useEffect(()=>{
			let sch = document.querySelector('.plus-min-ans-wrap')?.scrollHeight;
			let cch = document.querySelector('.plus-min-ans-wrap')?.clientHeight;
				if(sch>cch){
					$('.plus-min-ans-wrap').scroll(function (event) {
						var scroll = $('.plus-min-ans-wrap').scrollTop();
						if (scroll > 0) {
							setQlenth('active');
						} else {
							setQlenth('');
						}
					});
				}
	},[])

	return (
		<>
			{/* Main questiona and answer wrapper for plus minus answer format */}
			<div className="plus-min-ans-wrap">{checklist}</div>
		</>
	);
};
