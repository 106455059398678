import { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { decrypter} from  '../../webutils/webutils'
import { apiEndpoints } from '../../services/api-endpoints'


export default function Weblive(props){
    const history = useHistory();   

    


useEffect(async ()=>{
   const { match } = props;
   const { params } = match;
   if(!!params && 'id' in  params){
       const encodedEmail = params.id;
       const email = decrypter(encodedEmail)
       
    if(email){
        try{
            const response = await axios.post(`${apiEndpoints.baseUrl}/weblive/validateemail`, {email});
            const { data: emailAvailable } = response;
            if(emailAvailable){
                history.push(`/sign-in?referralEmail=${encodedEmail}`);
            }else{
                history.push(`/sign-up?referralEmail=${encodedEmail}`);
            }
        }catch(error){
            history.push('sign-in');
        }
    }else{
        history.push('sign-in');
    }
   
   }


},[])

return (<div />)

}