/** imported components

	* $ from jquery ===> component from jquery to use jquery for the page.

**/

import React, { useState, useEffect} from "react";
import $ from "jquery";

//MultiChoiceAnswer component starts
export const MultiChoiceLines = (props) => {

	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}

	const quesDataItem = props.quesData[0];
	const quesId= quesDataItem?.question.id;

	const {allRangeValueArr, setAllRangeValueArr} = props;
	const {qlength, setQlenth} = props;
	const {activeRightTab, setActiveRightTab} = props;
	
	const getValue = (name, value) => {
		
		const tmpArr = {
			...allRangeValueArr,
			[name]: value,
		}
		setAllRangeValueArr(tmpArr);
			// console.log(tmpArr);
	}

	let tmpObj3={};
	var array1=[];
	
	const multichoice2 = quesDataItem.answers.map((item, index) => {
		array1.push(item.id);
	});
	
	const multichoice1 = quesDataItem.answers.map((item, index) => {
		
		var value1 = "";
		var number="";
		var keyid='';
		var totalSelectcheck ='';
		var a = new Array(); 
		var b = new Array();
		
		var lnl = [];
		var ln = item.lines;
		// changes 20-dec
		if( ln!=null){
			ln.map((lnv, ind)=>{
				lnl[ind] =  lnv.id;
			})
		}
		// changes 20-dec
		
		
		
		
		if (quesDataItem.savedans.length) {
			
			var val = quesDataItem.savedans;
			var newval = JSON.parse(val);
				newval.map((v, inde)=>{
					keyid =  v.option_id;
					value1 =  v.line_id;
					let newval1 = value1.split(",");
					//compare
					var matches = [];
					for ( var i = 0; i < lnl.length; i++ ) {
						for ( var e = 0; e < newval1.length; e++ ) {
							if ( lnl[i] === newval1[e] ) matches.push(lnl[i] );
						}
					}
					
					newval1 = matches;
					
					//compare
					a[v.option_id] = value1;

					if(index == inde){
						 if(value1 == ""){
							number = 1;
						}else if(typeof newval1 == 'object' && item.lines.length===newval1.length){
							number = 0;
						}else if(array1.includes(keyid)){
							number = 2;
							totalSelectcheck=newval1.length;
						}
						
					}
						
						let tmpObj1={...tmpObj3,[keyid]:newval1}
						
						tmpObj3=tmpObj1;
			})
			
		}
		
		
	
	
	
	
		useEffect(()=>{
			let sch = document.querySelector('.slide-lines')?.scrollHeight;
			let cch = document.querySelector('.slide-lines')?.clientHeight;
            if(sch>cch){
				$('.slide-lines').scroll(function (event) {
							if (scroll > 0) {
								setQlenth('active');
							} else {
								setQlenth('');
							}
				});
	       }
			setAllRangeValueArr(tmpObj3);
		},[])

		return (
		<>
			{/* choose-one-question end */}
			{item.lines && (	
					<div key={item.id}  className={`multi-tab-ans-wrap ${quesDataItem.savedans.length != 0 &&  'active'}`} data-index={index}>
						{/* Wrapper for multichoice answer format */}
						<div className="choose-one-card">
							{/* left card is containing the answer and their options */}
							<div className="card-left" style={{pointerEvents:quesDataItem.is_datasubmitted==1?'none':''}}>
								<p>
									{item.option_name}
									{/* {item.id} */}
									{item.index}
								</p>
							</div>
							{item.answer_description && (
							<div className={props.clickedCardNo === index ? props.activeRightTab == 0 ? "card-right active" :"card-right" : "card-right"}>
								<img className="inactive" src={require('../../assets/img/multi-choice/non-active-info.svg').default} alt="true" onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)} />
								<img src={require('../../assets/img/multi-choice/active-info.svg').default} onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)} className="active" alt="true" />
							</div>
							)}
							
							<CardBtnSec onErrorMsg={props.onErrorMsg} getValue={getValue} itemid={item.id} dataline={quesDataItem} anslines={item.lines} totalselcheck={totalSelectcheck} datavalue={value1} Itemindex={index}  activeBtn={number} />
							
						</div>												
					</div>
				)}  
		</>
	
	);
});
return (
	<>
		<div className="slide-lines">{multichoice1}</div>
	</>
);
};





const CardBtnSec = (props) => {
	const datalinb = props.dataline;
	
	const datalinc = props.anslines;
	// const pdatavalue = props.datavalue;
	// console.clear();

	// console.log(typeof pdatavalue);

	const [totalSelected, setTotalSelected] = useState(props.totalselcheck);
	const [activeBtn, setActiveBtn] = useState(props.activeBtn);
	const [isUpClass,setIsUpClass] = useState(false);
	/* variable to check any line is selected or not*/
    const [isChkSelect,setIsChkSelect]=useState(0);
	// const name = `ans${props.Itemindex}`;
	const [name,setName] = useState('');
	
	const valueAll = datalinc;
	const vArr = [];
	
	if (!datalinc) {
		return null;
	}
	
	valueAll.forEach((value, index) => {
		const valid = value.id;
		vArr.push(valid);
	});
	
	const HandleBtnActive = (e, ind) => {
		/*automatic scroll when click on the button*/ 
			let scrollTopDiv=document.querySelector('.slide-lines');
			let singleCard=document.querySelectorAll('.multi-tab-ans-wrap');
			let singleCardHeight=singleCard[props.Itemindex]?.offsetHeight;
			let singleCardTopPos=singleCard[props.Itemindex]?.offsetTop;
			if(e.target.closest('.card-btn-sec .ddl-wrapper')){
				scrollTopDiv.scrollTop=singleCardTopPos-singleCardHeight;
			}
			 
		/*end automatic scroll when click on the button*/
		/*conditon to click in the card to remove active class from other buttons if their selected lines is 0*/ 
		if(ind==2){
			let divClick=document.querySelectorAll('.multi-tab-ans-wrap');
			divClick?.forEach(div =>{
				let dataIndex=div.getAttribute('data-index');
					if(props.Itemindex!=dataIndex){
						div.click();
					}
			})
		}
		/*end conditon */ 
		if(ind==activeBtn && ind==2){
				setIsChkSelect(!isChkSelect)
		}else{
				setActiveBtn(ind);
		} 
		
		if (ind == 0) {
			// let tmpInputArr = document.getElementsByName(name);
			let tmpInputArr = document.getElementsByName(name);
			props.getValue(name, vArr);
			tmpInputArr.forEach((input)=>{
				input.checked=true;
			});
			setTotalSelected(vArr.length)
		} else if (ind == 1) {
			let tmpInputArr = document.getElementsByName(name);
			props.getValue(name, "");
			tmpInputArr.forEach((input)=>{
				input.checked=false;
			});
			setTotalSelected(0)
		} else {
			
			const ScreenH = window.innerHeight;
			const ScreenW = window.innerWidth;
			const ElementtopPos = e.clientY;
			// console.log(!e.target.parentElement.classList.contains('active') && document.querySelector('.ddl-wrapper.active'))
			if (!e.target.parentElement.classList.contains('active') && document.querySelector('.ddl-wrapper.active')) {
				document.querySelector('.ddl-wrapper.active').classList.remove('active');
			}else{} 
			e.target.parentElement.classList.toggle('active');
			// 
			if(singleCard.length<=2 && ScreenW<=1024){
				scrollTopDiv.scrollTop=scrollTopDiv.scrollHeight;
			}

		}
	}
	const HandleInputGroupValue = () => {
		let tmpInputArr = document.getElementsByName(name);
		let tmpArr = [];
		for (let i = 0; i < tmpInputArr.length; i++) {
			if (tmpInputArr[i].checked) {
				tmpArr.push(tmpInputArr[i].value);
			}
		}
		props.getValue(name, tmpArr);
		setTotalSelected(tmpArr.length);
		
		//scnv-3468 &&  scnv-3469
		if(tmpArr.length>0){
			setActiveBtn(2);
		}else{
			setActiveBtn(false);
		}
	}
	
	$(document).on('click','.mult-onclick', function(e){
                $(this).parent('.card-btn-sec').parent('.choose-one-card').parent('.multi-tab-ans-wrap').addClass('active');
				$('.choose-one-card').removeClass('asst-card-error');
				$('.ddl-list-wrap').removeClass('is-empty');
			     props.onErrorMsg("");
                 if($(this).hasClass('active')){
                	//$(this).removeClass('active');
				 }else{
					// $(this).addClass('active');
					 }
            })
  /*this useEffect will call first time and when isChkSelect value will be change*/	
					
	useEffect(()=>{
		// console.log(totalSelected);
		if(!totalSelected){
				//setActiveBtn(false);
		}		
	},[isChkSelect]);



  /* this useEffect will call every time when any state will change*/
	useEffect(()=>{
		
    // click outside close
	window.onclick=(e)=>{
			if (!e.target.closest('.ddl-wrapper') && document.querySelector('.ddl-wrapper.active')) {
				let tmpClickDiv=document.querySelectorAll('.mult-onclick.active');
				tmpClickDiv?.forEach((div)=>{
					div.parentElement.parentElement.click();
				})
			 }
			//scnv-3468 &&  scnv-3469
			if(activeBtn==2 && (totalSelected==0 || !totalSelected)){
								 setActiveBtn(false);
			}

			}
			
			/*$(".ddl-wrapper.hello").each(function () {
				var id = $(this).attr("id");
				var teamp=0; var teamp1=0;
				if( $(this).find("." + id).is(":checked")) {
					teamp=1; 
					if(!($(this).find(".ddl-btn").hasClass("active")) && $(this).find(".hidden").val()==2 ){
						$(this).find(".ddl-btn").addClass("active");
					}
				} else {
					if(teamp==0 && $(this).find(".hidden").val()!=2){ 
						$(this).find(".ddl-btn").removeClass("active");
						teamp1=1;
					}
					if(!($(this).find(".ddl-btn").hasClass("active")) && ($(this).hasClass("active")) && teamp1==0){
						console.log('add');
						$(this).find(".ddl-btn").addClass("active");
					}
				}
			  }); */
		let cnd = document.querySelectorAll('.multi-tab-ans-wrap')[props.Itemindex];
		if(cnd){
				cnd.onclick = (e) => {
				if (!e.target.closest('.ddl-wrapper') && document.querySelector('.ddl-wrapper.active')) {
						e.currentTarget.querySelector('.ddl-wrapper.active')?.classList.remove('active'); 				
						setIsChkSelect(!isChkSelect)
					}
				}
		}
				
	});

  /* this useEffect will call only first time when component will be render*/
	useEffect(()=>{
		setName(props.itemid);
		/*variable declare */
		let scrollTopDiv=document.querySelector('.slide-lines');
		let singleCard=document.querySelectorAll('.multi-tab-ans-wrap');
		const ScreenH = scrollTopDiv?.offsetHeight;
		let singleCardHeight=singleCard[props.Itemindex]?.offsetHeight;
		let singleCardTopPos=singleCard[props.Itemindex]?.offsetTop;
		/*------------------------------*/ 
		if ( document.querySelector('.ddl-wrapper.active')) {
			document.querySelector('.ddl-wrapper.active').classList.remove('active');
		}
        
		if (singleCardTopPos+singleCardHeight>ScreenH && props.Itemindex==singleCard.length-1 && props.Itemindex>1) {
				    setIsUpClass(true);
													
		}

						
	},[]);



	return (
		<>
			<div className="card-btn-sec" key={props.Itemindex} style={{pointerEvents:datalinb.is_datasubmitted==1?'none':''}} >
				<button className={`normal-btn allline mult-onclick ${activeBtn === 0 &&  'active'}`}  onClick={(e) => {
					HandleBtnActive(e, 0);
				}}>
					All Lines
				</button>
				<button className={`normal-btn noline mult-onclick ${activeBtn === 1 &&  'active'}`} onClick={(e) => {
					HandleBtnActive(e, 1);
				}}>
					No Lines
				</button>
				{/*scnv-3468 &&  scnv-3469*/}
				<div id={props.itemid} className={`ddl-wrapper  mult-onclick ${isUpClass?'up':''}`}>
				<input type="hidden" name="hidden" className="hidden" value={activeBtn}/>
					<button className={`normal-btn  ddl-btn ${activeBtn === 2 && 'active'}`}
						onClick={(e) => {
							HandleBtnActive(e, 2);
						}}
					>
						<span>
							{totalSelected > 0 ? `${totalSelected} Selected` : 'Some Lines'}
						</span>
						<svg width="1.3rem" height=".7rem" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect width="1.28096" height="8.83478" rx="0.640482" transform="matrix(0.73034 -0.683084 0.73034 0.683084 0 0.964844)" />
							<rect width="1.28096" height="8.96675" rx="0.640482" transform="matrix(0.730344 0.683079 -0.730344 0.683079 12.0643 0)" />
						</svg>
					</button>
					<div className="ddl-list-wrap">
						<ul>

				{datalinc.map((item, index)=>{
					
					var vali = datalinb.savedans;
					var itemid = [];
					if(vali!=''){
						var v1 = JSON.parse(vali);
						v1.map((item1, index1)=>{
							if(item1.line_id!='' && (item1.option_id == props.itemid)){
								itemid = item1.line_id.split(','); 
							}
							
						})
						;

					}

					return(
							<li key={index}>
								<label>
									<input name={name}  className={props.itemid} value={item.id} defaultChecked={itemid.includes(item.id)? true : false}   type="checkbox" onChange={HandleInputGroupValue} />
									<div className="chk-div">
										<img className="active-icon" src={require('../../assets/img/multi-choice/active-chk.svg').default} />
										<img className="inactive-icon" src={require('../../assets/img/multi-choice/non-active-chk.svg').default} />
									</div>
									<span className="chk-txt">
										{item.line_name}
									</span>
								</label>
							</li>)
							})}
							

						</ul>
					</div>
				</div>
			</div>
		</>
	)
}