import styled from "styled-components";

export const SiriDimensionFilterPopup = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(125, 134, 140, 0.30);
    backdrop-filter: blur(2px);
    display: ${({open})=> open?"flex":"none"};
    align-items: center;
    justify-content: center;
    z-index: 1300;
    @media(max-width: 575px){
      align-items: flex-end;
    }
`

export const FilterWrapper = styled.div`
  position: relative;
`;
export const FilterAllDimentionButton = styled.button`
  transition: 0.25s ease-in;
  background: "#fff";
  border:1px solid #7D868C;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding:1.2rem;
  border-radius: 0.4rem;
  transition: 0.25s ease-in;
  border-radius: 0.4rem;
  line-height: 0;
  color:#7D868C;
  margin-right:2rem;
  min-width:28rem;
  transition:0.25s;
  i {
    display: inline-block;
  }
  svg{
    transform:rotate(-90deg);
   
    path{
      fill:#7D868C;
      transition:0.25s;
    }
  }
  &:hover{
    background:#E5F0FF;
    color:#118ACB;
    border-color:currentcolor;
    svg path{
      fill: #118ACB;
    }
  }
`;
export const FilterDimensionBox = styled.div`
  width: 50rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.15);
  max-height: 80vh;
  border-radius: 0.7rem;
  height: calc(100vh - 30px);
  transition: 0.25s ease-in;
  background: #fff;
  display: flex;
  flex-direction: column;
  @media(max-width: 976px){
    left:unset;
    right:0;
  }
  @media(max-width: 740px){
    min-width: 100%;
    max-width: 450px;
  }
  @media(max-width: 575px){
    max-width: unset;
    border-radius: 0;
    width: 100%;
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 1.6rem;
  color: #118acb;
  flex:1;
  
`;
export const FilterBoxHeader = styled.div`
  border-bottom: 0.1rem solid #ccc;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  align-items: center;
  ${HeaderTitle} {
    color: #4d4d4f;
    font-size: 1.6rem;
  }
`;

export const FilterCloseButton = styled.button`
  i {
    display: inline-block;
    svg {
      height: 2.2rem;
      width: 2.2rem;
      path {
        fill: #4d4d4f;
      }
    }
  }
`;

export const FilterBoxContent = styled.div`
  flex: 1;
  overflow-x: hidden;
  padding: 0 0 1.6rem 0;
  display: flex;
  flex-direction: column;
  .select-dimension{
    padding: 1.5rem 0.5rem 0.5rem 1.5rem;
  }
`;
export const FilterBoxContentWrap = styled.div`
  flex: 1;
  overflow-y: auto;
`
export const FilterBoxLable = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? '' : 'pointer'};
  user-select: none;
  padding: 0 1.5rem;
  pointer-events: ${({ disabled }) => disabled ? 'none' : ''};
  width: 30%;
  @media(max-width:575px){
    width:100%;
  }
  span {
    color: #7d868c;
    display: inline-block;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.2rem;
    margin-left: 0.5rem;
  }
  input[type="checkbox"],
  input[type="radio"] {
    accent-color: #118acb;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }
  .icon {
    display: flex;
    height: 12px;
    width: 12px;
    margin: 0;
    align-items: center;
    i {
      display: flex;
      height: 2.2rem;
      width: 2rem;
      align-items: center;
      svg {
        height: 100%;
        width: 100%;
        circle{
          stroke: ${({ disabled }) => disabled ? '#959FA9' : '#118acb'};
        }
      }
    }
  }
  input[type="checkbox"] + .icon,
  input[type="radio"] + .icon {
    i {
      svg {
        path {
          fill: #ffffff;
          stroke: #118acb;
        }
        circle {
          &.first-circle {
            stroke: #ffffff;
            fill: #ffffff;
          }
        }
      }
    }
  }
  input[type="checkbox"]:checked + .icon,
  input[type="radio"]:checked + .icon {
    i {
      svg {
        path {
          fill: #118acb;
          stroke: #118acb;
        }
        circle {
          &.first-circle {
            stroke: #118acb;
            fill: #118acb;
          }
        }
      }
    }
  }
`;
export const FilterSiriTabContentWrap = styled.div`
  display: flex;
  flex-direction :column;
  padding: 0 1.5rem;
  @media(max-width: 740px){
    padding: 0 10px;
  }
  .title{
    color: #4D4D4F;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.2rem;
    display: block;
    padding-top: 2.4rem;
  }
  .dimension-card{
    margin-top: 0.8rem;
    padding: 0.8rem;
    border-radius: 0.8rem;
    border: 0.06rem solid #CBCFD2;
    background: #FFF;
    .top-wrap{
      display: flex;
      align-items: center;
      gap: 0.8rem;
      .count{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.4rem;
        background: #118ACB;
        color: #fff;
        height: 3.2rem;
        width: 3.2rem;
      }
      .content-wrap{
        flex: 1;
        .band-title{
          color: #0D2C54;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.5rem; /* 125% */
          margin: 0;
        }
        .current-title{
          display: block;
          color: #45B097;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
      .dimension-btn{
        cursor: pointer;
        display: inline-flex;
        height: 2.4rem;
        width: 2.4rem;
        &.active{
          svg{
            transform: rotate(180deg);
          }
        }
        svg{
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;
          height: 100%;
          width: 100%;
        }
      }
    }
    .bottom-wrap{
      padding-left: 2.5rem;
      padding-top: 1.2rem;
      @media(max-width: 740px){
        padding: 0;
        padding-top: 10px;
      }
      ${FilterBoxLable}{
        padding: 0 0.8rem;
        width: 25%;
        @media(max-width:575px){
          width: 33%;
          padding: 10px 0 0 0;
        }
        .filter-name{
          color: #4d4d4f;
          flex: 1;
        }
      }
    }
  }
  .filter-checkbox{
    position:absolute;
    height:0;
    width:0;
    opacity:0;
    &~ i{
      display:inline-block;
      cursor:pointer;
    }
    &:checked ~ i svg{
        path{
          fill:#118acb;
          stroke:#118acb;
        }
    }
  }
`
export const FilterButtonWrapper = styled.div`
  background: #ffffff;
  /* box-shadow: -0.4rem 0px 0.6rem rgba(0, 0, 0, 0.12); */
  border-radius: 0px 0px 1.6rem 1.6rem;
  border-top: 0.1rem solid #ccc;
  padding: 1.5rem 1.5rem;
  display: flex;
  gap: 1.5rem;
  justify-content: flex-end;
  @media(max-width: 575px){
    flex-wrap:wrap;
    gap:15px;
  }
`;
export const ResetFilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #4d4d4f;
  min-width: 16rem;
  min-height: 4rem;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #E6E6E6;
  background: #F0F0F0;
  &:hover{
    background: #E5F0FF;
    color: #118acb;
  }
  @media(max-width:400px){
    min-width:100%;
    max-width:100%;
  }
`;
export const ApplyFilterButton = styled.button`
  background: #118acb;
  border: 0.1rem solid #118acb;
  border-radius: 0.4rem;
  color: #fff;
  outline: 0;
  min-height: 4rem;
  font-weight: 500;
  font-size: 1.4rem;
  min-width: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.25s ease-in-out;
  &:hover {
    background: linear-gradient(92deg, #118ACB 12.22%, #0473AE 100.41%);
  }
  @media(max-width:400px){
    min-width:100%;
    max-width:100%;
  }
`;