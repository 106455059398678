import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { WlakthroughView1 } from "./walkthrough1-view";
export const WalkthroughController1 = (props) => {
	const [open, setOpen] = useState(true);
	const handleOpen = () => {
		setOpen(true);
	};

	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		serviceProvider.post("/home?type=setassessment", JSON.stringify({ uid: usid }),true).then((response) => {
			// console.log(response);
		});
	}, []);
	const handleClose = () => {
		setOpen(false);
	};
	return <WlakthroughView1 open={open} handleOpen={handleOpen} handleClose={handleClose} {...props} />;
};
