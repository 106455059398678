/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * Helmet ===> for add specific title & CSS for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/


import React, { useState, useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";

//AllQuestionView start
export const AllquestionsView = (props) => {
	//Defining variables here
	const sectionDataItems = props.sectionData;
	if (!sectionDataItems.length) {
		return null;
	}

	const sectionDataItem = props.sectionData[0];
	const catData = sectionDataItem.category;
	const chapterDataItem = sectionDataItem.chapters;
	console.log(props.sectionData[0].has_ans);
	if (!catData) {
		return null;
	}
	
	const answeredQuestion = chapterDataItem.map((chapter, index) => {
	const count = index + 1;
	return (
			<div className="all-qna-cards-wrap">
				<h1 class="card-h1">{count}. {chapter.subcategory_name}</h1>
				
				{chapter.all_question.length ? (
					chapter.all_question.map((aq) => (
					//card wrapper which containing number section,text section and arrow section
					<Link className="all-qna-card" to={`/assessment/start/${chapter.id}/${aq.id}`}>
						<div className="no-sec">{aq.numb}</div>
						<div className="text-sec">
							<span>
								{aq.question}
							</span>
						</div>
						<div className="arrow-sec">
							<svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0.749456 1.25054C1.14442 0.855581 1.78478 0.855581 2.17974 1.25054L8.92919 7.99999L2.17974 14.7494C1.78478 15.1444 1.14442 15.1444 0.749457 14.7494C0.354494 14.3545 0.354493 13.7141 0.749457 13.3192L6.06862 7.99999L0.749456 2.68083C0.354494 2.28587 0.354494 1.64551 0.749456 1.25054Z" fill="#90A4AE" />
							</svg>
						</div>
					</Link>
					))	
					
				) : (
					<></>
				)}	
			</div>
		);	
	});
	return (
		<>
		{/* Page Title */}
			<Helmet>
				<title>{catData.category_name} | SmarterChains</title>
				<meta name="description" content={`${catData.category_name} Assessment`} />
			</Helmet>
			{/* Breadcrumbs*/}
			<BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/assessment">Assessment</BreadcrumbsItem>
			<BreadcrumbsItem to="#">{catData.category_name}</BreadcrumbsItem>
			{/* Wrapper has started. It is containg heading and text. */}
			<div className="all-qna-wrapper7-21">
				<h1 className="top-h1">
					Answered questions
				</h1>
				<p className="top-p">
					Please note that changing any input might result in the loss of subsequent answers. You are advised to reach to the section's end, if you decide to do changes.
				</p>
				
				{answeredQuestion}
				{props.sectionData[0].has_ans ===0  && <p className="all-qns-no-result">You haven't answered any question yet.</p>
				}	
			</div>
		</>
	);
};
