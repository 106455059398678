/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * Plyr ===> third party plugin
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/
import React, { useState, useEffect } from "react";
import { HandlePageOnClick } from "../../webutils/webutils";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import { Modal } from "@material-ui/core";
import { apiEndpoints } from '../../services/api-endpoints';
//CourseDetailLeft component start
export const CourseDetailLeft = (props) => {
	//defining variables here
	const [isReadMore, setIsReadMore] = useState(false);
	const [url, setUrl] = useState("");
	const [popupState, setPopupState] = useState(false);
	//function to handle popup
	const handlePopup = (url) => {
		setUrl(url);
		setPopupState(!popupState);
	};
	//function to close tour popup
	const CloseTourPopup = () => {
		setUrl("");
		setPopupState(false);
	};
	//function to handle read more
	const handleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	//function to handle accordion
	const handleAccordion = (e) => {
		document.querySelector(`li[data-index="${e}"]`).classList.toggle("open");
	};
	const secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);

		var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
		var mDisplay = m > 0 ? m + (m == 1 ? "min " : "min ") : "";
		var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
		return hDisplay + mDisplay + sDisplay;
	};
	const courseDataItems = props.courseData;
	if (!courseDataItems.length) {
		return null;
	}
	const courseDataItem = props.courseData[0];
	const courseDataItemDetails = courseDataItem.details.map((detail) => {
		return (
			<li key={detail.id} className="wyl-li">
				<span className="wyl-li-icon">
					<img src={require("../../assets/img/course-detail/checkmark.svg").default} />
				</span>
				<span className="wyl-li-text">{detail.detail}</span>
			</li>
		);
	});
	const courseDataItemSections = courseDataItem.sections.map((section, index) => {
		if (index == 0) {
			const vurl = `${apiEndpoints.baseUrl}/assets/videos/${section.video_url}`;
			return (
				<li key={section.id} data-index={index}>
					<div className="trn_course_play_tab" onClick={() => handlePopup(vurl)}>
						<span className="trn_play_btn">
							<img src={require("../../assets/img/course-detail/play-intro-btn.svg").default} alt="true" />
						</span>
						{section.section_name}
						<span className="trn_time_count">{secondsToHms(section.section_duration)}</span>
					</div>
				</li>
			);
		} else {
			return (
				<li key={section.id} data-index={index}>
					<div className="trn_course_det_tab">
						<div className="trn_course_det_tab-link">
							<div className="trn_play_btn_arrow" onClick={() => handleAccordion(index)}>
								<span className="down_arrow">
									<img src={require("../../assets/img/course-detail/down_arrow.svg").default} alt="true" />
								</span>
								{section.section_name}
								<span className="trn_time_count">{secondsToHms(section.section_duration)}</span>
							</div>
						</div>
						<div className="trn_course_det_expand">
							<p>{section.section_detail}</p>
						</div>
					</div>
				</li>
			);
		}
	});
	return (
		<React.Fragment>
			{/* Page title */}
			<Helmet>
				<title>{courseDataItem.course_name} | SmarterChains</title>
				<meta name="description" content={courseDataItem.meta_desc} />
				<meta name="og:image" content={`${apiEndpoints.baseUrl}/assets/img/meta/${courseDataItem.meta_img}`} />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/training-courses">IR4.0 Training Courses</BreadcrumbsItem>
			<BreadcrumbsItem to="#">{courseDataItem.course_name}</BreadcrumbsItem>
			{/* wrapper has
				train-detail-title
				trainDetail
			*/}
			<div className="master_wrapper">
				{/* this section has
					course image
					course name
				*/}
				<h1 className="train-detail-title">
					{/* <img
						src={require("../../assets/img/course-detail/back-course.svg").default}
						className="back-arrow"
						alt="true"
						onClick={() => HandlePageOnClick("/training-courses", props)}
					/> */}
					{courseDataItem.course_name}
				</h1>
				{/* this section has rows */}
				<div className="trainDetail-pl-20">
					{/* row has
						train-col-lg-8
						train-col-lg-4
					*/}
					<div className="train-row trn_pb_3">
						<div className="train-col-lg-8">
							{/* this section has
								heading
								read-more-sec
							*/}
							<div className="train-col-lg-11 trn_abt_course">
								<h2 className="h2">About this course</h2>
								{/* <p className="train-detail-lft-p">
									<div dangerouslySetInnerHTML={{ __html: courseDataItem.course_description }}></div>
								</p> */}
								{/* this section has 
									text
									button
								*/}
								<div className={isReadMore ? "read-more-sec active" : "read-more-sec"}>
									<div className="read-more-sec-text">
										<p className="train-detail-lft-p">
											<div dangerouslySetInnerHTML={{ __html: courseDataItem.course_description }}></div>
										</p>
									</div>
									<button className="read-more-btn" onClick={handleReadMore}>
										{isReadMore ? <span className="read-less">Read Less</span> : <span className="read-more">Read More</span>}
									</button>
								</div>
							</div>
						</div>
						{/* this section has image */}
						<div className="train-col-lg-4 rgt-img-wrapp">
							<img
								src={require(`../../assets/img/training-card/inner-${courseDataItem.course_icon}`).default}
								className="trn-leadership"
								alt="true"
							/>
						</div>
					</div>
					<hr />
					{/* What you’ll learn */}
					<div className="train-row">
						{/* this section has two rows */}
						<div className="train-col-lg-12 tr_lrn_wrapp">
							{/* this row has text */}
							<div className="train-row">
								<div className="train-col-lg-12">
									<h2 className="h2">What you’ll learn</h2>
								</div>
							</div>
							{/* this row has course details */}
							<div className="train-row">
								<div className="train-col-lg-12">
									<ul className="train-detail-lft-p wyl-ul">{courseDataItemDetails}</ul>
								</div>
							</div>
						</div>
					</div>
					{/* Instructor */}
					<div className="train-row" style={{ display: "none" }}>
						{/* this section has two rows */}
						<div className="train-col-lg-12 tr_lrn_wrapp">
							{/* this row has heading */}
							<div className="train-row">
								<div className="train-col-lg-12">
									<h2 className="h2">Instructor</h2>
								</div>
							</div>
							{/* this rwo has col */}
							<div className="train-row">
								{/* this section has
									trn_instructr_pic
									trn_instructr_det
								*/}
								<div className="train-col-lg-12 trn_instructor_wrap">
									<div className="trn_instructor_pic">
										<img
											alt={courseDataItem.i_name}
											className="instructor__img"
											src={require(`../../assets/img/course-instructor/${courseDataItem.i_avatar}`).default}
										/>
									</div>
									<div className="trn_instructor_det">
										<p className="trn_inst_ttl">{courseDataItem.i_name}</p>
										<p className="trn_inst_post">{courseDataItem.i_position}</p>
										<p className="trn_inst_edu">
											{courseDataItem.i_from}
											{/* 
													{courseDataItem.i_avatar} */}
										</p>
										<span>
											<a href={courseDataItem.i_linkedin} target="_blank">
												<img src={require("../../assets/img/course-detail/linkedin.svg").default} alt="true" />
											</a>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* Course sections */}
					<div className="train-row">
						{/* thios section has two rows */}
						<div className="train-col-lg-12 tr_lrn_wrapp">
							{/* this row has heading */}
							<div className="train-row">
								<div className="train-col-lg-12">
									<h2 className="h2">Course sections</h2>
								</div>
							</div>
							{/* this section has
								course data item sections list
								button
							*/}
							<div className="train-row">
								<div className="train-col-lg-12 trn_course_det_wrapp">
									<ul>{courseDataItemSections}</ul>
									<button onClick={() => HandlePageOnClick("/training-courses", props)} className="btn-back-to-all">
										Back to All IR4.0 Trainings
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				open={popupState}
				onClose={CloseTourPopup}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="quick-start-vid-popup"
			>
				{/* video wrapper */}
				<div className="vid__wrapper">
					<Plyr
						source={{
							type: "video",
							sources: [
								{
									src: url,
									type: "video/mp4",
								},
							],
						}}
						options={{
							//controlshidden: true,
							autoplay: true,
							controls: [
								"play-large",
								"rewind",
								"fast-forward",
								"play",
								"progress",
								"current-time",
								"duration",
								"mute",
								"volume",
								"settings",
								"fullscreen",
							],
						}}
					/>
				</div>
			</Modal>
		</React.Fragment>
	);
};
