/** imported components

  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * CartContext for ==> add item into the cart.

**/
import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import serviceProvider from "../../services/axios";
import { CartContext } from "../../contexts/CartContext";
import { motion } from "framer-motion";
// CartViewMaturity Start
export const CartViewMaturity = (props) => {
  let { cartItems } = useContext(CartContext);
  // defining variables here
  const {
    total,
    discount,
    discountPrice,
    itemCount,
    increase,
    decrease,
    removeProduct,
  } = useContext(CartContext);
  // const variants = {
  //     visible: { opacity: 1, x: 0 },
  //     hidden: { opacity: 0, x: 100 },
  // };
  const getLastItem = (thePath) =>
    thePath.substring(thePath.lastIndexOf("/") + 1);
  const tid = getLastItem(window.location.href);
  const n = tid.slice(8);
  const coursename = n.replace(/-/g, " ").replace(/_/g, " ");

  // console.log("citems",cartItems)
  //  var setc1 = cartItems[0]['0'];

  //  var setc2 = cartItems[0]['1'];

  //  if(cartItems[0]['0']!=undefined && cartItems[0]['1']!=undefined){
  // 	if(cartItems[0]['0'].id==1 && cartItems[0]['1'].id==2){
  // 		setc1["quantity"]=1;
  // 		setc2["quantity"]=1;
  // 		setc1.id=1;
  // 		setc2.id=2;
  // 	    cartItems = [setc1,setc2];

  // 	   console.log(cartItems);
  // 	}
  // }

  // else{
  if (cartItems.length != 0) {
    cartItems[0].course_name = coursename;
    cartItems[0].course_slug = n;
    if (coursename == "Full Maturity Assessment") {
      cartItems[0].id = 1;
    } else if (coursename == "Maturity and Loss Analysis with Business Case") {
      cartItems[0].id = 3;
    } else {
      cartItems[0].id = 2;
    }
  }
  //}

  //console.log("cartpageval-",cartItems)
  const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 100 },
  };
  const [totalPaid, setTotalPaid] = useState(0);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  useEffect(() => {
    const search = async () => {
      const apiUrl = `/home?param=paiditem&uid=${coursename}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setTotalPaid(data);
    };
    search();
  }, []);

  return (
    <div className="cart__page__wrapper">
      {/* CART PAGE LEFT SECTION DIV STARTED*/}
      <div className="cart__page__left">
        <div className="cart__time__line">
          <div className="time__line__item inprogress">
            {" "}
            {/*add complete class when completed*/}
            <span>My cart</span>
          </div>
          <div className="time__line__item">
            <span>Billing Info</span>
          </div>
          <div className="time__line__item">
            <span>Payment</span>
          </div>
        </div>
        {/* cart__time__line end */}
        <div className="cart__left__content">
          <div className="cart__page__items">
            <h2 className="h1">My Cart</h2>
            <div className="cart__page__table">
              {itemCount >= 1 && (
                <header>
                  <div className="col__big">
                    <span>Item</span>
                  </div>
                  <div className="col__2">
                    <span>Price</span>
                  </div>
                  <div className="col__3"></div>
                </header>
              )}
              {itemCount == 0 && (
                <p className="empty__msg">Your cart is empty</p>
              )}

              {/* header end */}
              {cartItems.map((product) => (
                <section className="cart__items">
                  <div className="col__big">
                    <p className="b">{product.course_name}</p>
                    {/* {product.price!= 2000 && (
										<p className="normal__txt">Industry 4.0 Dimension Training Course</p>
										)} */}
                  </div>
                  <div className="col__2">
                    <p className="b text-uppercase">€{product.price}</p>
                  </div>
                  <div className="col__3">
                    <button
                      className="remove__btn"
                      onClick={() => removeProduct(product)}
                    >
                      <img
                        src={
                          require("../../assets/img/cart-page/remove.svg")
                            .default
                        }
                      />
                      <span>Remove</span>
                    </button>
                  </div>
                </section>
              ))}

              {/* section end */}
              {/* {(totalPaid <= 0 && itemCount >= 1) && (
								<section>
									<div className="col__big">
										<p className="b">Digital Maturity Assessment - Limited</p>
										<p className="normal__txt">Assessment of Industry 4.0 Vision</p>
									</div>
									<div className="col__2">
										<p className="b text-uppercase">FREE</p>
									</div>
									<div className="col__3">
										<div className="text-center">
											<img
												className="green-chk-cir"
												src={require("../../assets/img/cart-page/green-chk-cir.svg").default}
												alt="true"
											/>
										</div>
									</div>
								</section>
							)}
							{(totalPaid <= 0 && itemCount >= 1) && (
								<section>
									<div className="col__big">
										<p className="b">Results: Digital Maturity Assessment - Limited​​</p>
										<p className="normal__txt">Results of Factory of the Future Leadership</p>
									</div>
									<div className="col__2">
										<p className="b text-uppercase">FREE</p>
									</div>
									<div className="col__3">
										<div className="text-center">
											<img
												className="green-chk-cir"
												src={require("../../assets/img/cart-page/green-chk-cir.svg").default}
												alt="true"
											/>
										</div>
									</div>
								</section>
							)} */}

              <div className="add__another__course">
                {itemCount >= 1 && itemCount <= 9 && (
                  <Link to="/plans" className="btn__add__another">
                    + Add Product
                  </Link>
                )}
                {itemCount == 0 && (
                  <Link to="/training-courses" className="btn__add__another">
                    + Add courses
                  </Link>
                )}
              </div>
            </div>
            {/* cart__page__table end */}
          </div>
          {/* cart__page__items end */}

          {/* cart__page__items end */}

          {/*payment__items end */}
        </div>
      </div>
      {/* 
		  	#CART PAGE LEFT SECTION DIV ENDED
			#CART PAGE RIGHT SECTION DIV STARTED
		*/}
      <div className="cart__page__right">
        <div className="cart__page__box">
          {/* cart section start */}
          <motion.div
            initial="hidden"
            animate="visible"
            variants={list}
            className="train--cart-sec"
          >
            <motion.div variants={item} className="card__rgt--header">
              <h2 className="h2">Summary</h2>
              <motion.div
                variants={item}
                className="train-row cart__head--wrapp"
              >
                <div className="train-col-lg-6 cart__sub--head">Subtotal:</div>
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                  €{total}
                </div>
              </motion.div>
              <motion.div
                variants={item}
                className="train-row cart__head--wrapp"
              >
                <div className="train-col-lg-6 cart__sub--head">Discount:</div>
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                  {discount}%
                </div>
              </motion.div>
            </motion.div>
            {/* <motion.div variants={item} className="card__rgt--body">
							{itemCount > 0 && itemCount < 2 && 10 - totalPaid >= 1 && (
								<div className="train-row cart__head--wrapp cart__discount--wrapp-10">
									<div className="train-col-lg-6 cart__discount--head cart__discount--offer">Buy 2 or more Courses</div>
									<div className="train-col-lg-6 cart__discount--head cart__discount--badge">
										<span>Get 10% Discount</span>
									</div>
								</div>
							)}
							{itemCount > 0 && itemCount <= 4 && 10 - totalPaid >= 4 && (
								<div className="train-row cart__head--wrapp cart__discount--wrapp-15">
									<div className="train-col-lg-6 cart__discount--head cart__discount--offer">Buy 5 or more Courses</div>
									<div className="train-col-lg-6 cart__discount--head cart__discount--badge">
										<span>Get 15% Discount</span>
									</div>
								</div>
							)}
							{itemCount > 0 && itemCount <= 9 && 10 - totalPaid >= 9 && (
								<div className="train-row cart__head--wrapp cart__discount--wrapp-20">
									<div className="train-col-lg-6 cart__discount--head cart__discount--offer">Buy all 10 Courses</div>
									<div className="train-col-lg-6 cart__discount--head cart__discount--badge">
										<span>Get 20% Discount</span>
									</div>
								</div>
							)}
						</motion.div> */}
            <motion.div
              variants={item}
              className="card__rgt--footer train-container"
            >
              <div className="train-row cart__head--wrapp cart__head--wrapp-total">
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-lgt">
                  Total:
                </div>
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                  €{discountPrice}
                </div>
                <div className="train-col-lg-12 cart__sub--head">
                  {itemCount >= 1 && (
                    <Link to="/billingmaturity" className="btn-Checkout">
                      Checkout
                    </Link>
                  )}
                  {itemCount == 0 && (
                    <Link to="/training-courses" className="btn-Checkout">
                      + Add Product
                    </Link>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
          {/* checkout section start */}
        </div>
      </div>
    </div>
  );
};
