/** imported components
 
  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * makeStyles for ===> styling material-ui component.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect.useRef ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * PropTypes ===> component from react to ensure type safety of receiving props
  * Typography, Box ===> material ui components
  * DragDropContext, Draggable, Droppable ===> component from react to use for drag functions
  * LossAnalysisSetupWalkthrough ===> walkthrough component 

**/
import React from "react";
import { Link } from "react-router-dom";
import serviceProvider from '../../services/axios'
import $, { noConflict } from 'jquery';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useState, useRef, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListItemSecondaryAction } from "@material-ui/core";
import { LossAnalysisSetupWalkthrough } from './loss-analysis-setup-walkthrough'
//LossAnalysisSetupPagePlantOnlyView component starts here
export const LossAnalysisSetupPagePlantOnlyView = (props) => {
  //defining variables here
  const selectDataItems = props.sectionData;
  if(!selectDataItems.length){
    return null;
  }
  const selectDataItem = props.sectionData[0];
  if(typeof selectDataItem.bcaselines === 'undefined'){
    return null;
  }
  const bline = selectDataItem.bcaselines.length;
  const [activeSlide, setActiveSlide] = useState(1);
  const user = JSON.parse(localStorage.getItem("userData"));
  const group_id = user ? user.group_id:0;
  
  const demoDdlData = selectDataItem.currency;

  const bcaseline = selectDataItem.bcaselines;
  const customData = selectDataItem.customData;
  const [productionData1, setProductionData1] = useState([]);
  const [filterDdl, setFilterDdl] = useState(demoDdlData);
  const [filterinput, setFilterInput]=useState();
  const [currencyDdl, setCurrencyDdl] = useState(false);
  const [yearDdl, setYearDdl] = useState(false);
  const [tmpCurrency, setTmpCurrency] = useState();
  const [clickAbleEle, setClickAbleELe] = useState(null);
  const [productionData2, setProductionData2] = useState({
    units: customData.unit,
    currency: customData.currency
  });
  
  
  let prodAllInput = [];
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
//styling variable
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: 224,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));

  const classes = useStyles();
  const [timeValue, setTimeValue] = React.useState(0);
  //function to add row on click
  const AddRowOnClick = () => {
    let tmpArr = [...productionData1];
    tmpArr.push({
      id: `row${productionData1.length}`
    });
    setProductionData1(tmpArr);
   
  }
  //Function to delete row on click
  const DeleteRowOnClick = (index) => {
    let tmpArr = productionData1.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setProductionData1(tmpArr);
  }
  const [error, setError]= useState();
  const [error1, setError1]= useState();
  const [errory, setErrory]= useState("none");
  //Function to handle after drag
  const HandleAfterDrag = (data) => {
    const lastIndex = data.destination?.index;
    const firstindex = data.source?.index;
    const tmpArr = [...productionData1];

    if (lastIndex >= tmpArr.length) {
      var k = lastIndex - tmpArr.length + 1;
      while (k--) {
        tmpArr.push(undefined);
      }
  }
  tmpArr.splice(lastIndex, 0, tmpArr.splice(firstindex, 1)[0]);
  if (lastIndex || lastIndex==0) { setProductionData1(tmpArr) }

  }

  useEffect(()=> {
    if(clickAbleEle){
      const ele = document.getElementById(clickAbleEle);
      ele.focus()
    } 
	
   },[productionData1])
//Function to handle value on change
  const HandleValueOnChange = (e, singleData, name) => {
    let element =  e.relatedTarget ? e.relatedTarget.id: null
    setClickAbleELe(element)
    
    let val;
     const inval = `prodLineInput${singleData.id}`;
     var inpval = document.getElementById(inval).value;
 

      const re = /^[0-9\b]+$/; //rules
     
      if(re.test(inpval) || inpval == "") {
        setError("Enter Line. Numbers Only.");
        val = e.target.value;
      }
    
    const tmpArr = [];
    productionData1.forEach((item, i) => {
      if (item.id === singleData.id) {
        let tmpData = {
          ...singleData,
          [name]: val
        }
        tmpArr.push(tmpData);
      } else {
        tmpArr.push(item);
      }
    });
    
    setProductionData1(tmpArr);
  
  }
  const pid = selectDataItem.Plant_id;
  const uid = selectDataItem.uid;
  const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
  const slug = getLastItem(window.location.pathname);
  const [yearlist, setYearList] = useState('');
  const [btnval, setbtnval] = useState()
  const [isdisable, setisdisable] =  useState(false);
  const [isdisableun, setisdisableun] =  useState(false);
  const [lastshowpop, setlastshowpop] =  useState('hide-class');
 
//Function to handle year dropdown
  const handleYearDdl =()=>{
    setYearDdl(!yearDdl);
  }
  const [year, setYear] = useState("");
  //Function to get blank value on next1
  const GetValueOnNext1blank = () => {
    setTimeValue(1);
    setActiveSlide(3);
    if (customData.currency) {

      if (customData.currency == "EUR" || customData.currency == "USD" || customData.currency == "GBP" || customData.currency == "CHF") {
        setTmpCurrency()
        setisdisableun(true);
        setProductionData2({ units: customData.unit, currency: customData.currency })
      } else {
        setTmpCurrency({ img: `${customData.currency.toLowerCase()}-flag.svg`, name: customData.currency })
        setProductionData2({ units: customData.unit });
        setisdisableun(true);
      }
    } else {
      setisdisableun(false);
    }
  }
//Function to get value on next1
const GetValueOnNext1 = () => {

 
  var error= false;
  productionData1.forEach((item, index)=>{
    let tmpTarget = `${item.id}-error`;
    let tmpTargetline = `${item.id}-lineerror`;
    if(item.prodNameInput?.length){
      document.getElementById(tmpTarget).style.display="none";
      
      error= false;
    }else {
      document.getElementById(tmpTarget).style.display="block";
      setError1("Enter Production process.");
      error= true;
    }

    if(item.prodLineInput?.length){
      document.getElementById(tmpTargetline).style.display="none";
      error= false;
    }else{
      document.getElementById(tmpTargetline).style.display="block";
      setError("Enter Line. Numbers Only.");
      error= true;
    }
   
  })
if(!error){
      setTimeValue(1);
      setActiveSlide(3);
  }
    
}
//Function to handle production value
  const handleProduction2Val = (e, name) => {
    let val = e.target.value;
    if (!val) {
      val = val = e.currentTarget.getAttribute('data-value');
    } else if (name == 'currency') {
      setTmpCurrency();
    }

    let tmpObj = { ...productionData2, [name]: val }
    if (val) {
      setProductionData2(tmpObj);
    } else if (name == 'units') {
      setProductionData2({ ...productionData2, [name]: 'Tons' })
    } else if (name == 'currency') {
      setProductionData2({ ...productionData2, [name]: 'EUR' })
    }
  }
  //function to get value on next 2
  const GetValueOnNext2 = () => {
   if(customData.fiscal_year == ""){
    setTimeValue(2);
    setActiveSlide(4);
    // setYear("");
   }else{
    setTimeValue(2);
    setActiveSlide(4);
    // setYear(customData.fiscal_year);
   }
    
  }
//Function to get value on next3 blank
  const GetValueOnNext3blank = ()=>{
    if(group_id == 14){ 
    window.location = `/lossanalysis/section/${slug}`;
	 }else{
		 window.location = `/loss-analysis-admin/section/${slug}`;
		 }
  }

//Function to get value on next3
  const GetValueOnNext3= ()=>{
    
    if(year==""){
      setErrory("block");
    }else{
    serviceProvider
    .post(
      "/businesscaseplantloss?type=savelinesdata",
      JSON.stringify({ plantdata:productionData1, currency:productionData2.currency, unit:productionData2.units, plant_id:pid, uid:uid, year:year  }),true
    )
    .then((response) => {
		
      if(group_id == 14){ 
    window.location = `/lossanalysis/section/${slug}`;
	 }else{
		 window.location = `/loss-analysis-admin/section/${slug}`;
		 }
  
    }).catch((err)=>{console.log(err)});
  }
// }
  }
  const ddlFilterInput = useRef();
  //Function to handle dropdown list
  const FilterDdlList = (e) => {
    e.stopPropagation();
    let val = ddlFilterInput.current.value;
    setFilterInput(e.target.value);
    if (val.length > 0) {
      let fiterData = demoDdlData.filter((item) => {
        return item.name.toLowerCase().startsWith(val.toLowerCase());
      });
      setFilterDdl(fiterData);
    } else {
      setFilterDdl(demoDdlData);
    }
  }

 useEffect(() => {
let tmpObj = [];
bcaseline.map((item, index)=>{
  let tmpObj1 = {};
  // console.log(index);
  let idv = "row" + index;
let val = item.line_name;
let val1 = item.module;

tmpObj1 = { ...tmpObj1, id:idv, ["prodNameInput"]: val, ["prodLineInput"]:val1 };

tmpObj.push(tmpObj1);

})


if(bcaseline.length){
  setProductionData1(tmpObj);
  setisdisable(true);
 
}else{
  setisdisable(false);
  setProductionData1([{
    id: 'row0'
  },
   {
    id: 'row1'
  },
   {
    id: 'row2' 
  },]);
  
}

  // for year list
    
  let tempYear = [];
  let datenew = new Date();
  let comingYear = datenew.getFullYear() + 1;
  for(let i=comingYear; i>=comingYear-10; i--){
    tempYear.push(i);
  }

  setYearList(tempYear);
  if(customData.fiscal_year == "" || customData.fiscal_year==undefined){
       setYear(tempYear[1]);
   }else{
  
    setYear(customData.fiscal_year);
   }

  }, []);

  
  return (
    <>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/">{selectDataItem.plant_name}</BreadcrumbsItem>
      {bline == 0 ?
        <BreadcrumbsItem to={`/loss-analysis-setup/${slug}`}>Loss Analysis</BreadcrumbsItem> :   
		<BreadcrumbsItem to={group_id == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>Loss Analysis</BreadcrumbsItem>  
      }
      <BreadcrumbsItem to="#">Setup</BreadcrumbsItem>
      {/* Main wrapper on loss analysis setup page */}
      <div className="loss-analysis-setup-wrapper">
        <div className="loss-setup-tabs">
          {/* left timeline from page to page */}
          <div className="loss-setups-time-line">
            <div className={`loss-setups-li ${timeValue == 0 && 'inprogress'} ${timeValue > 0 && 'done'}`}>
              <div className="ls-t-cir">
                <span>1</span>
                <img src={require('../../assets/img/loss-analysis-setup-img/done-chk-white.svg').default} alt="" />
              </div>
              <div className="ls-t-text">
                Production
                processes
              </div>
            </div>
            <div className={`loss-setups-li ${timeValue == 1 && 'inprogress'} ${timeValue > 1 && 'done'}`}>
              <div className="ls-t-cir">
                <span>2</span>
                <img src={require('../../assets/img/loss-analysis-setup-img/done-chk-white.svg').default} alt="" />
              </div>
              <div className="ls-t-text">
                Unit of Measurement
              </div>
            </div>
            <div className={`loss-setups-li ${timeValue == 2 && 'inprogress'} ${timeValue > 2 && 'done'}`}>
              <div className="ls-t-cir">
                <span>3</span>
                <img src={require('../../assets/img/loss-analysis-setup-img/done-chk-white.svg').default} alt="" />
              </div>
              <div className="ls-t-text">
                Fiscal Year
              </div>
            </div>
          </div>
          <TabPanel value={timeValue} index={0} className="tab-panel-wrapper">
            {/* loss-setup-row divided in two parts
              md-col-9
              md-col3
            */}
            <div className="loss-setup-row">
              {/* column has four parts
                  heading
                  sub-heading
                  text 
                  production-process-table-wrapper
              */}
              <div className="loss-setup-col left md-col-9">
                <h1 className="page-title">Loss Analysis Setup</h1>
                <h2 className="form-heading">Production processes</h2>
                <p className="sub-text">Please list the main production areas of the plant along with their number of lines.</p>
                {/* 
                    In this section has table with four columns
                */}
                <div className="production-process-table-wrapper">
                  <div className="prod-1">
                    <div className="table-row first-row">
                      <div className="table-col left">
                        <span className="table-heading">#</span>
                      </div>
                      <div className="table-col mid">
                        <span className="table-heading">Production process</span>
                      </div>
                      <div className="table-col mid2">
                        <span className="table-heading">Number of lines</span>
                      </div>
                      <div className="table-col right"></div>
                    </div>
                    <DragDropContext
                      onDragEnd={(params) => HandleAfterDrag(params)}
                    >
                      <div>
                        <Droppable
                          droppableId='droppable-1'>
                          {(provided, _) => (

                            <div ref={provided.innerRef}
                              {...provided.droppableProps}>
                              {productionData1.map((item, index) => {
                                return (
                                  <Draggable
                                    key={`draggable${index}`}
                                    index={index}
                                    draggableId={`draggableId${index}`}
                                    data={item}
                                  >
                                    {
                                      (provided, snapshot) => (
                                        <div className={`table-row ${snapshot.isDragging && 'dragging'}`
                                        } ref={provided.innerRef} {...provided.draggableProps}>
                                          <div className="table-col left">
                                            <span className="table-heading">{index + 1}</span>
                                          </div>
                                          <div className="table-col mid">
                                            <div className="table-input-wrap">
                                              <input disabled={isdisable}
                                                autoComplete="off" className="error-select"
                                                id={`prodNameInput${item.id}`} 
                                                onBlur={(e) => HandleValueOnChange(e, item, 'prodNameInput')}
                                                type="text"
                                                defaultValue={item['prodNameInput']}
                                                placeholder="Name of process e.g. packing" />
                                                <div id={`${item.id}-error`} className="error-text-mssg">{error1}</div>
                                            </div>
                                          </div>
                                          <div className="table-col mid2">
                                            <div className="table-input-wrap">
                                              <input type="text" disabled={isdisable}
                                                autoComplete="off" className="error-select"
                                                id={`prodLineInput${item.id}`}
                                                defaultValue={item['prodLineInput']}
                                                placeholder="No. of Lines"
                                                onBlur={(e) => HandleValueOnChange(e, item, 'prodLineInput')} />
                                                <div id={`${item.id}-lineerror`} className="error-text-mssg" >{error}</div>
                                            </div>
                                          </div>
                                          {!isdisable && <div className="table-col right close"> 
											<div className="table-image-wrap">
                                              <button disabled={isdisable} className="delete-btn" onClick={() => DeleteRowOnClick(index)}>
                                                <img
                                                  src={
                                                    require("../../assets/img/loss-analysis-setup-img/delete-icon.svg")
                                                      .default
                                                  }
                                                  alt="delete-icon"
                                                />
                                              </button>
                                              <div className="drag-btn" {...provided.dragHandleProps}>
                                                <img
                                                  src={
                                                    require("../../assets/img/loss-analysis-setup-img/drag.svg")
                                                      .default
                                                  }
                                                  alt="drag"
                                                />
                                              </div>
                                            </div>
                                          </div>}
										  
										  
                                        </div>
                                      )
                                    }
                                  </Draggable>
                                )
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>

                      </div>
                    </DragDropContext>
                  </div>
                  {!isdisable &&<div className="table-row" id="add-row">
                    <div className="button-wrap">
                      <button disabled={isdisable} className="btn-link" onClick={() => {
                        AddRowOnClick()
                      }}>+ Add new production Process</button>
                    </div>
                  </div>}
                  <div className="table-row">
                    <div className="button-wrap next">
                      { bcaseline.length ?
                         <button className="btn-link disable-blue"
                         disabled={productionData1.length > 0 ? false : true}
                         onClick={GetValueOnNext1blank}>Next</button>
                     :
                      <button className="btn-link disable-blue"
                        disabled={productionData1.length > 0 ? false : true}
                        onClick={GetValueOnNext1}>Next</button>
                      }
                    </div>
                  </div>
				  
				  
                </div>
              </div>
               {/* this section has two parts
                  btn-wrap
                  insight-card-wrapper
              */}
              <div className="loss-setup-col left md-col-3">
                {/*hide schedule a call btn for scnv-2126*/}
                <div className="btn-wrap schedule" style={{visibility:'hidden'}}>
                  <button type="submit">Schedule a call</button>
                </div>
                {/* this wrapper has one card */}
                <div className="insight-card-wrapper">
                   {/* card is divided into two parts
                      inside-card-heading-wrap
                      content-wrapper
                  */}
                  <div className="insight-card">
                    {/* this section has two parts
                        heading 
                        image
                    */}
                    <div className="insight-card-heading-wrap">
                      <h3>Insight</h3>
                      <img
                        src={
                          require("../../assets/img/loss-analysis-setup-img/bulb.svg")
                            .default
                        }
                        alt="Bulb"
                      />
                    </div>
                     {/* this section has text */}
                    <div className="content-wrapper">
                      <p>In the <span className="highlight-text">"Production Process"</span> column,
                        please group your production lines based on common technology and product category. </p>
                      <p>In the <span className="highlight-text">"Number of Lines"</span> column please provide
                        the number of lines that are constituting each grouped production process.</p>
                      <p>The Loss Analysis data collection is going to be based on the grouped
                        production processes you define in this interface.</p>
						          <p> Thus, please set up the production
                        processes based on the internal structure you report line efficiency losses.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={timeValue} index={1} className="tab-panel-wrapper second measurement-panel">
            {/* loss-setup-row divided in two parts
              md-col-9
              md-col3
            */}
            <div className="loss-setup-row">
               {/* This section has 
                  title 
                  sub-heading
                  text
              */}
              <div className="loss-setup-col left md-col-9">
                <h1 className="page-title">Loss Analysis Setup</h1>
                <div className="prod-2">
                  <h2 className="form-heading">Production volume</h2>
                  <p className="sub-text">Please select the Production Volume Unit of Measurement (UoM) used for reporting.</p>
                  {/* row has three parts
                      measurment-col
                  */}
                  <div className="measurement-row">
                    {/* Each col has input-wrap 
                        inside input-wrap , input has different value 
                    */}
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <input type="button" value="Tons" disabled={isdisable} onClick={(e) => handleProduction2Val(e, 'units')} className={productionData2.units === "Tons" && 'selected'} />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <input type="button" value="Units" disabled={isdisable}  onClick={(e) => handleProduction2Val(e, 'units')} className={productionData2.units === "Units" && 'selected'} />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <input type="text" disabled={isdisable} defaultValue={productionData2.units === "Units" || productionData2.units === "Tons" ? '' : productionData2.units} placeholder="Other"
                          className={productionData2.units !== "Units" && productionData2.units !== "Tons" && 'selected'}
                          onBlur={(e) => {
                            handleProduction2Val(e, 'units',)
                          }} />
                      </div>
                    </div>
                  </div>
                  <h2 className="form-heading">Official business currency</h2>
                  <p className="sub-text">Please select the Currency Unit of Measurement (UoM) used for reporting.</p>
                   {/* row has five parts
                      measurment-col
                  */}
                  <div className="measurement-row second">
                     {/* col has input-wrap */}
                    <div className="measurement-col">
                      {/* It has been divided in two parts
                          las-flag
                          button
                      */}
                      <div className="input-wrap">
                        {/* in this section has image */}
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/eur-flag.svg').default} alt="true" />
                        </span>
                        <input type="button" disabled={isdisable} value="EUR"
                          onClick={(e) => {
                            handleProduction2Val(e, 'currency');
                          }}
                          className={productionData2.currency === "EUR" && 'selected'}
                        />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/usd-flag.svg').default} alt="true" />
                        </span>
                        <input type="button"  disabled={isdisable} value="USD" onClick={(e) => handleProduction2Val(e, 'currency')} className={productionData2.currency === "USD" && 'selected'} />

                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/gbp-flag.svg').default} alt="true" />
                        </span>
                        <input type="button" value="GBP" disabled={isdisable} onClick={(e) => handleProduction2Val(e, 'currency')} className={productionData2.currency === "GBP" && 'selected'} />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <span className="las-flag">
                          <img src={require('../../assets/img/loss-analysis-setup-img/chf-flag.svg').default} alt="true" />
                        </span>
                        <input type="button" disabled={isdisable} value="CHF" onClick={(e) => handleProduction2Val(e, 'currency')} className={productionData2.currency === "CHF" && 'selected'} />
                      </div>
                    </div>
                    <div className="measurement-col">
                      <div className="input-wrap">
                        <div className="las-ddl">
                          {!tmpCurrency ?
                            <button disabled={isdisable} className={`btn-dlas ${currencyDdl && 'active'}`} onClick={() => setCurrencyDdl(!currencyDdl)}>
                              <span>{ "Other"}</span>
                              <svg viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.9873 1.5L4.99986 5.48744L1.01242 1.5" stroke="#4D4D4F" strokeWidth="1.5" strokeLinecap="round" />
                              </svg>
                            </button>
                            :
                            <div onClick={() => setCurrencyDdl(!currencyDdl)}>
                               <span className="las-flag">
                                  <img src={
                                require(`../../assets/img/loss-analysis-setup-img/country/${tmpCurrency.img}`).default} alt="true"
                              />
                             
                             </span>
                             <input type="button" disabled={isdisableun} value={tmpCurrency.name} className='selected' /> 
                            
                            </div>
                          }
                          {/* top button end */}
                          <ul className={`las-ddl-ul ${currencyDdl && 'active'}`}>
                            <li className="las-ddl-li">
                              <label>
                                <img src={require('../../assets/img/loss-analysis-setup-img/ddl-search.svg').default} />
                                <input type="text" disabled={isdisable} ref={ddlFilterInput} value={filterinput} placeholder="Search" onChange={(e) => FilterDdlList(e)} autoFocus />
                              </label>
                            </li>

                            {filterDdl.map((item, index) => (<li className="las-ddl-li" key={index}>
                              <button
                                data-value={item.name}
                                onClick={(e) => {
                                  setTmpCurrency(item);
                                  handleProduction2Val(e, 'currency');
                                  setCurrencyDdl(false);
                                }}>
                                <span className="li-flag">
                                  <img src={
                                    require(`../../assets/img/loss-analysis-setup-img/country/${item.img}`).default} alt="true"
                                  />
                                </span>
                                <span className="las-txt">
                                  {item.name}
                                </span>
                              </button>
                            </li>))
                            }
                          </ul>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                 {/* This section has two buttons */}
                <div className="measurement-row button-wrap">
                  <div className="button transparent">
                    <button type="submit" className="back-btn" onClick={() => setTimeValue(0)}>Back</button>
                  </div>
                  <div className="button solid">
                    <button type="submit" className="next-btn" onClick={() => {GetValueOnNext2();
                        }}>Next</button>
                  </div>
                </div>
              </div>
              <div className="loss-setup-col left md-col-3">
                {/*hide schedule a call btn for scnv-2126*/}
                <div className="btn-wrap schedule" style={{visibility:'hidden'}}>
                  <button type="submit">Schedule a call</button>
                </div>
                <div className="insight-card-wrapper">
                  <div className="insight-card">
                    <div className="insight-card-heading-wrap">
                      <h3>Insight</h3>
                      <img
                        src={
                          require("../../assets/img/loss-analysis-setup-img/bulb.svg")
                            .default
                        }
                        alt="Bulb"
                      />
                    </div>
                    <div className="content-wrapper">
                      <p>Continue the Setup with the <span className="highlight-text">Units of Measurement</span> that you use for internal reporting to business unit.</p>
                      <p>Please select the official Unit of Measurement used for business unit reporting.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={timeValue} index={2} className="tab-panel-wrapper third measurement-panel">
             {/* Row has two parts
              md-col-9
              md-col-3
            */}
            <div className="loss-setup-row">
              {/* column has 
                  title
                  heading
                  text
                  measurement-row
              */}
              <div className="loss-setup-col left md-col-9">
                <h1 className="page-title">Loss Analysis Setup</h1>
                <div className="prod-3">
                  <h2 className="form-heading">Fiscal Year</h2>
                  <p className="sub-text">Please provide the last full fiscal year used for reporting internally.</p>
                  {/* this section has col */}
                  <div className="measurement-row">
                   {/* col has input-wrap */}
                     <div className="measurement-col">
                        {/* input-wrap has dropdown list */}
                      <div className="input-wrap">
                        <div className="las-ddl">
                            <button disabled={isdisable} className={`btn-dlas year  ${yearDdl && 'active'}`} onClick={()=> handleYearDdl() } >
                              <span>{year}</span>
                              <svg viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.9873 1.5L4.99986 5.48744L1.01242 1.5" stroke="#4D4D4F" strokeWidth="1.5" strokeLinecap="round" />
                              </svg>
                            </button>
                          <ul className={`las-ddl-ul ${yearDdl && 'active'}`}>
                            {yearlist && yearlist.map((item, index)=>{
                           
                            return (<li className="las-ddl-li" >
                              <button 
                               data-value={item}
                                onClick={()=>{
                                  setYear(item);
                                  setYearDdl(false);
                                  }}>
                                <span className="las-txt">
                                  {item}
                                </span>
                              </button>
                            </li>) })
                            }
                          </ul>

                        </div>

                      </div>
                    </div>
                  </div>
                  {/* This section has two buttons */}
                  <div className="measurement-row button-wrap">
                    <div className="button transparent">
                      <button type="submit" className="back-btn" onClick={() => setTimeValue(1)}>Back</button>
                    </div>
                    
                {!bcaseline.length ?
                    <div className="button solid">
                      <button type="submit" className={`submit-btn   ${year!="" && year!= undefined ?  '' : 'disable-blue'} `}  onClick={()=>{setlastshowpop('')}}>Submit</button>
                    </div> :
                     <div className="button solid">
                      <button type="submit" className="submit-btn" onClick={()=>{GetValueOnNext3blank()}} >Go to Loss Analysis</button>
                    </div>    
                                 }
                  </div>
                </div>
              </div>
              <div className="loss-setup-col left md-col-3">
                {/*hide schedule a call btn for scnv-2126*/}
                <div className="btn-wrap schedule" style={{visibility:'hidden'}}>
                      <button type="submit">Schedule a call</button>
                </div>
                <div className="insight-card-wrapper">
                  <div className="insight-card">
                    <div className="insight-card-heading-wrap">
                      <h3>Insight</h3>
                      <img
                        src={
                          require("../../assets/img/loss-analysis-setup-img/bulb.svg")
                            .default
                        }
                        alt="Bulb"
                      />
                    </div>
                    <div className="content-wrapper">
                      <p>The Loss Analysis exercise is going to be based on the last <span className="highlight-text">full fiscal year</span> you have reported your plant's results.</p>
                      <p>Please avoid providing a year which has not yet closed officially for the plant/company.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
        {/*----------- submit-popup ----------------*/}
        <div className={`submit-popup active ${lastshowpop}`}>
          <div className="popup-wrapper submitdata-popup">
              <div className="close-btn-wrap">
                  <button className="close-btn" onClick={()=>{setlastshowpop('hide-class')}}>
                      <img src={require("../../assets/img/la-admin/close-pop.svg").default} alt="Close" className="close-img" />
                  </button>
              </div>
              <div className="popup">
                  <div className="content-box">
                      <h2 className="heading">Submiting Data</h2>
                      <p className="text">Do you want to proceed and submit your data?</p>
                      <p className="text">Upon submitting, you will not be able to edit the Plant's Loss data.</p>
                      
                  </div>
                  <div className="button-wrapper">
                  <button type="submit" className="btn cancel-button primary" onClick={()=>{setlastshowpop('hide-class')}}>Cancel</button>
                      <button type="submit" className="btn submit-btn secondary" onClick={()=>{GetValueOnNext3()}} >Submit</button>
                  </div>
              </div>
          </div>
      </div>
      </div>
      {/* Walkthrough component call */}
      {activeSlide && !bline &&
        <LossAnalysisSetupWalkthrough activeSlide={activeSlide} setActiveSlide={setActiveSlide} setProductionData1={setProductionData1} {...props} />
      }
    </>
  );
};