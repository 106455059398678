/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * * as d3 ===> all component from d3 to create graph
  *  Modal ===> component from UI used to work as popup

**/

import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Collapse, Modal } from "@material-ui/core";
import { Helmet } from "react-helmet";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import $, { isEmptyObject } from "jquery";
import serviceProvider from "../../services/axios";
import "./result.scss";
import "./limited-result.scss";
import { openPopupWidget } from "react-calendly";
//ResultPlantView component start
export const ResultPlantLimitedView = (props) => {
  //defining variables here
  const resultsDataItems = props.resultsData;
  if (!resultsDataItems.length) {
    return null;
  }
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    console.log(resultsDataItem.isResult1, resultsDataItem.isResult);
    if (resultsDataItem.isResult1 != 1 && resultsDataItem.isResult != 1) {
      document.querySelector("#rs-slide-2").classList.add("rswlk-z-9999");
      document.querySelector("#highlight_tab").classList.add("rswlk-z-9999");
      document
        .querySelector(".header-page-link-ul")
        .classList.add("z-index9999");
      document
        .querySelectorAll("#globalChart .nes-chart-content .bar")
        .forEach((itm) => {
          itm.classList.add("tab-z999");
        });
      document.querySelector(".assess-ddata-12").style.display = "flex";
    }
  }, []);
  const [activeSlide, setActiveSlide] = useState(0);
  //function to handle slides
  const HandleSlides = (val) => {
    setActiveSlide(val);
    let winwidth = window.innerWidth;
    let objDiv = document.querySelector(".training-content");
    if (val === 0 && resultsDataItem.isResult1 != 1) {
      document.querySelector("#rs-slide-2").classList.add("rswlk-z-9999");
      document.querySelector("#highlight_tab").classList.add("rswlk-z-9999");
      document
        .querySelector(".header-page-link-ul")
        .classList.add("z-index9999");
      document
        .querySelectorAll("#globalChart .nes-chart-content .bar")
        .forEach((itm) => {
          itm.classList.add("tab-z999");
        });
    } else {
      document.querySelector("#rs-slide-2").classList.remove("rswlk-z-9999");
      document
        .querySelector(".header-page-link-ul")
        .classList.remove("z-index9999");
      document.querySelector("#highlight_tab").classList.remove("rswlk-z-9999");
      document
        .querySelectorAll("#globalChart .nes-chart-content .bar")
        .forEach((itm) => {
          itm.classList.remove("tab-z999");
        });
    }
    if (val === 1) {
      document.querySelector("#highlightTab2").classList.add("rswlk-z-9999");
      document.querySelector("#gchart").classList.add("rswlk-z-9999");
      objDiv.scrollTop = winwidth < 1300 ? 320 : winwidth == 1440 ? 350 : 400;
      document
        .querySelector(".training-content")
        .classList.add("scrolling-none");
      document.querySelector(".rswlk-z-9999 svg").classList.add("chart-height");
    } else {
      document.querySelector("#highlightTab2").classList.remove("rswlk-z-9999");
      document.querySelector("#gchart").classList.remove("rswlk-z-9999");
      objDiv.scrollTop = 0;
      document.querySelector(".bchart svg").classList.remove("chart-height");
      document
        .querySelector(".training-content")
        .classList.add("scrolling-none");
    }

    if (val === 2) {
      document
        .querySelector(".assess-ddata-12 #strengthHighlight")
        .classList.add("rswlk-z-9999");
      document
        .querySelector(".assess-ddata-12 #highlight3h1")
        .classList.add("rswlk-z-9999");
      objDiv.scrollTop =
        winwidth < 1300 && winwidth > 1220 ? 850 : winwidth <= 1220 ? 790 : 950;
    } else {
      document
        .querySelector(".assess-ddata-12 #highlight3h1")
        .classList.remove("rswlk-z-9999");
      document
        .querySelector(".assess-ddata-12 #strengthHighlight")
        .classList.remove("rswlk-z-9999");
    }
    // console.log(setActiveSlide);
  };
  const users = JSON.parse(localStorage.getItem("userData"));
  // const UserName = users ? users.name : "First Name";
  const uid = users ? users.uid : "";
  //function to close walkthrough
  const handelCloseWalkThrough = (activeSlide) => {
    let objDiv = document.querySelector(".training-content");
    document.querySelectorAll(".rswlk-z-9999").forEach((item) => {
      item.classList.remove("rswlk-z-9999");
    });
    document.querySelectorAll(".z-index9999").forEach((item) => {
      item.classList.remove("z-index9999");
    });

    document
      .querySelectorAll("#globalChart .nes-chart-content .bar")
      .forEach((itm) => {
        itm.classList.remove("tab-z999");
      });
    objDiv.scrollTop = 0;
    // console.log(activeSlide);
    serviceProvider
      .post(
        "/home?type=setresulttour",
        JSON.stringify({ uid: uid, slide: activeSlide }),
        true
      )
      .then((response) => {
        setIsOpen(false);
      });
    document
      .querySelector(".training-content")
      .classList.remove("scrolling-none");
    document.querySelector(".assess-ddata-12").style.display = "none";
  };
  const resultsDataItem = props.resultsData[0];

  const truetheme = resultsDataItem.truethemes;
  const flasetheme = resultsDataItem.falsethemes;
  const fof_score = resultsDataItem.fof_score;
  const [readmoreObj, setReadmoreObj] = useState({});
  const [firstTopTab, setFirstTopTab] = useState(1);
  const [graphTab, setGraphTab] = useState(1);
  const [rsPopup, setRsPopup] = useState(null);
  const [bottomLift, setBottomLift] = useState("");
  //function to handle open popup
  const HandlePOPUPopen = () => {
    setActiveSlide(2);
    setIsOpen(true);
    document.querySelectorAll(".s-result-link").forEach((item) => {
      item.classList.add("rswlk-z-9999");
    });
    //document.querySelectorAll(".s-result-link").classList.add("rswlk-z-9999");
    document.querySelector("#highlight3h1").classList.add("rswlk-z-9999");
  };
  //function to handle first tab
  const HandleFirstTab = (val) => {
    setFirstTopTab(val);
  };
  //function to handle graph tab
  const HandleGraphtTab = (val) => {
    setGraphTab(val);
    //drawChart();
  };
  const messagesEndRef = useRef(null);
  //function for bottom scroll
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [bottomLift]);
  const [isUpgradePopup, setIsUpgradePopup] = useState(false);
  // const [isLnrCollapse, setIsLnrCollapse] = useState(true);
  const HandleUpdgradePopup = () => {
    setIsUpgradePopup(!isUpgradePopup);
  };
  const FirePopup = ({ url, pageSettings, prefill, utm }) => {
    let pdata = JSON.parse(localStorage.getItem("userData"));
    prefill = { email: pdata.email, name: pdata.name };

    url =
      "https://calendly.com/smarterchains/1h-free-industry-4-0-consultation-call";
    openPopupWidget({ url, prefill, pageSettings, utm });
  };
  // chart code started
  const drawChart = () => {
    var dmwidth = 0;
    var paystatus = 1;
    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }
    function roundedRect(x, y, w, h, r, tl, tr, bl, br) {
      var retval;
      retval = "M" + (x + r) + "," + y;
      retval += "h" + (w - 2 * r);
      if (tr) {
        retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r;
      } else {
        retval += "h" + r;
        retval += "v" + r;
      }
      retval += "v" + (h - 2 * r);
      if (br) {
        retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
      } else {
        retval += "v" + r;
        retval += "h" + -r;
      }
      retval += "h" + (2 * r - w);
      if (bl) {
        retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
      } else {
        retval += "h" + -r;
        retval += "v" + -r;
      }
      retval += "v" + (2 * r - h);
      if (tl) {
        retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
      } else {
        retval += "v" + -r;
        retval += "h" + r;
      }
      retval += "z";
      return retval;
    }

    const sample1 = [
      resultsDataItem.dimensions.map((item, index) => {
        return {
          tech: item.group_name,
          id: `ddata-${item.group_id}`,
          value: `${Math.round(item.score_value)}`,
          color: "#0B8ED1",
        };
      }),
    ];
    const sample = sample1[0];

    const svgContainer = d3
      .select("#bchart")
      .html("")
      .append("svg")
      .attr("class", "svg");
    const margin = 60;
    //const width = 1100 - 2 * 40;
    const width = parseInt(d3.select("#bchart").style("width")) - 2 * 40;
    const height = 400 - 2 * margin;
    var btooltip = d3.select("#bchart").append("div").attr("class", "dtoolTip");
    const chart = svgContainer
      .append("g")
      .attr("transform", `translate(40, 50)`);

    const x = d3
      .scaleBand()
      .range([0, width])
      .paddingInner(0.1)
      .paddingOuter(0.3);

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.tech))
      .padding(0.4);

    dmwidth = xScale.bandwidth();
    dmwidth = Math.round(dmwidth);

    const yScale = d3.scaleLinear().range([height, 0]).domain([0, 100]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    const datasetavg_new = [
      resultsDataItem.pavgdimensions.map((item, index) => {
        return [item.group_name, `${Math.round(item.score_value)}`];
      }),
    ];

    const datasetbic_new = [
      resultsDataItem.pbestdimensions.map((item, index) => {
        return [item.group_name, `${Math.round(item.score_value)}`];
      }),
    ];

    const datasetavg = datasetavg_new[0];

    const datasetbic = datasetbic_new[0];

    const sgrad = chart
      .append("defs")
      .append("linearGradient")
      .attr("id", "myGradientl")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    sgrad
      .append("stop")
      .attr("offset", "10%")
      .attr("stop-color", "rgba(19, 156, 214, 0)");
    sgrad
      .append("stop")
      .attr("offset", "80%")
      .attr("stop-color", "rgba(19, 156, 214, 0.1)");
    var wrapSize = 90;
    var toppx = 190;
    if (width < 790 && width > 600) {
      wrapSize = 70;
    } else if (width < 600 && width > 450) {
      wrapSize = 50;
    } else if (width > 1365 && width < 2200) {
      wrapSize = 120;
      toppx = 140;
    } else if (width > 2200) {
      toppx = 150;
    }

    chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll(".tick text")
      .attr("class", function (d, i) {
        return "xlablel xt" + sample[i].id;
      })
      .call(wrap, wrapSize);

    chart
      .append("g")
      .attr("class", "y axis")
      .call(
        d3
          .axisLeft(yScale)
          .ticks(1)
          .tickFormat((d) => d + "%")
      );

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var darea = d3
      .area()
      .x(function (d) {
        return xScale(d[0]) + xScale.bandwidth() / 2;
      })
      .y0(yScale(0))
      .y1(function (d) {
        return yScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    chart
      .append("g")
      .append("path")
      .attr("fill", "url('#myGradientl')")
      .attr("class", "dash-line-area") // Assign a class for styling
      .attr("d", darea(datasetbic)); // 11. Calls the line generator

    const barGroups = chart.selectAll().data(sample).enter().append("g");

    barGroups
      .append("path")
      .attr("class", (g) => "bar ibar " + g.id + " s" + g.id + " vk-" + g.value)
      .attr("d", function (g) {
        if (g.value == 0) {
          return roundedRect(
            xScale(g.tech),
            yScale(2),
            xScale.bandwidth(),
            height - yScale(2),
            0,
            0,
            0
          );
        } else {
          return roundedRect(
            xScale(g.tech),
            yScale(g.value),
            xScale.bandwidth(),
            height - yScale(g.value),
            5,
            5,
            5
          );
        }
      })
      .style("fill", (g) => g.color)
      .on("mouseenter", function (d, i, n) {
        let bar21 = d3.select(this).node().getBBox();

        if (i.value != 0 || i.id == "ddata-12") {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          btooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html("<strong>" + i.tech + "</strong><br>" + "Click for details");
        } else if (1) {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          btooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html("<strong>" + i.tech + "</strong><br>" + "Click for details");
        } else {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          btooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html(
              "Buy the Full Assessment to<br>" + "see the complete results"
            );
        }
      })
      .on("mouseleave", function () {
        d3.selectAll(".ibar").attr("opacity", 1);
        btooltip.style("display", "none");
      })
      .on("click", function (d, i, n) {
        let ii = i.id;
        let groid = ii.replace("ddata-", "");
        console.log(groid);
        props.groupid(groid);
        if (paystatus != 3 || d.value != 0.0) {
          d3.selectAll(".ibar").attr("opacity", 1);
          d3.selectAll(".ibar").style("fill", "#0b8ed1");
          d3.select(".s" + d.id).style("fill", "#0B8ED1");
          d3.select(".g" + d.id).style("fill", "#0B8ED1");
          d3.selectAll(".ival").style("fill", "#B6B6B6");
          d3.selectAll(".xlablel").style("fill", "#7D868C");
          d3.selectAll(".t" + d.id).style("fill", "#000000");
          d3.selectAll(".xt" + d.id).style("fill", "#000000");
          $(".assess-modal").hide();
          $(".assess-" + i.id).show();
          setBottomLift(".assess-" + i.id);
        }
      });

    var line = d3
      .line()
      .x(function (d, i) {
        return xScale(d[0]) + xScale.bandwidth() / 2;
      })
      .y(function (d) {
        return yScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    chart
      .append("g")
      .append("path")
      .attr("class", "line") // Assign a class for styling
      .attr("d", line(datasetavg)); // 11. Calls the line generator

    var dline = d3
      .line()
      .x(function (d, i) {
        return xScale(d[0]) + xScale.bandwidth() / 2;
      })
      .y(function (d) {
        return yScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    chart
      .append("g")
      .append("path")
      .attr("class", "dash-line") // Assign a class for styling
      .attr("d", dline(datasetbic)); // 11. Calls the line generator

    const textGroups = chart.selectAll().data(sample).enter().append("g");

    textGroups
      .append("text")
      .attr("class", (a) => "value ival t" + a.id)
      .attr("x", (a) => xScale(a.tech) + xScale.bandwidth() / 2)
      .attr("y", (a) => yScale(a.value) - 10)
      .attr("text-anchor", "middle")
      .text((a) => (a.value == "0.00" ? `N/A` : `${a.value}%`));

    const svgcont = document.querySelector(".svg");
    const bar = document.querySelector(".bar");

    $(document).on("click", ".assess-close, .res-tab-btn2", function (e) {
      d3.selectAll(".ibar").style("fill", "#0b8ed1");
      d3.selectAll(".ibar").style("stroke", "#0b8ed1");
      //d3.selectAll(".ibar").style("fill", "#129ad5");
      d3.selectAll(".ival").style("fill", "#000000");
      d3.selectAll(".xlablel").style("fill", "#7D868C");
      $(".assess-modal").hide();
    });

    const gsample = sample1[0];
    const gsvgContainer = d3
      .select("#gchart")
      .html("")
      .append("svg")
      .attr("class", "svg");
    const gmargin = 60;
    //const width = 1100 - 2 * 40;
    const gwidth =
      parseInt(d3.select("#main_graph_div").style("width")) - 2 * 40;

    const gheight = 400 - 2 * gmargin;
    var tooltip = d3.select("#gchart").append("div").attr("class", "dtoolTip");
    const gchart = gsvgContainer
      .append("g")
      .attr("transform", `translate(40, 50)`);

    const gx = d3
      .scaleBand()
      .range([0, gwidth])
      .paddingInner(0.1)
      .paddingOuter(0.3);

    const gxScale = d3
      .scaleBand()
      .range([0, gwidth])
      .domain(gsample.map((s) => s.tech))
      .padding(0.4);

    const gyScale = d3.scaleLinear().range([gheight, 0]).domain([0, 100]);

    const gmakeYLines = () => d3.axisLeft().scale(gyScale);

    const gdatasetavg_new = [
      resultsDataItem.avgdimensions.map((item, index) => {
        return [item.group_name, `${Math.round(item.score_value)}`];
      }),
    ];

    const gdatasetbic_new = [
      resultsDataItem.bestdimensions.map((item, index) => {
        return [item.group_name, `${Math.round(item.score_value)}`];
      }),
    ];

    const gdatasetavg = gdatasetavg_new[0];

    const gdatasetbic = gdatasetbic_new[0];

    const gsgrad = gchart
      .append("defs")
      .append("linearGradient")
      .attr("id", "myGradientlg")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    gsgrad
      .append("stop")
      .attr("offset", "10%")
      .attr("stop-color", "rgba(63, 222, 116, 0)");
    gsgrad
      .append("stop")
      .attr("offset", "80%")
      .attr("stop-color", "rgba(63, 222, 116, 0.1)");

    var wrapSize = 90;
    var toppx = 70;
    if (gwidth < 790 && gwidth > 600) {
      wrapSize = 70;
    } else if (gwidth < 600 && gwidth > 450) {
      wrapSize = 50;
    } else if (gwidth > 1365) {
      wrapSize = 120;
      toppx = 100;
    }

    gchart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${gheight})`)
      .call(d3.axisBottom(gxScale))
      .selectAll(".tick text")
      .attr("class", function (d, i) {
        return "xlablel xt" + gsample[i].id;
      })
      .call(wrap, wrapSize);

    gchart
      .append("g")
      .attr("class", "y axis")
      .call(
        d3
          .axisLeft(gyScale)
          .ticks(1)
          .tickFormat((d) => d + "%")
      );

    gchart
      .append("g")
      .attr("class", "grid")
      .call(gmakeYLines().tickSize(-gwidth, 0, 0).tickFormat(""));

    var darea = d3
      .area()
      .x(function (d) {
        return gxScale(d[0]) + gxScale.bandwidth() / 2;
      })
      .y0(gyScale(0))
      .y1(function (d) {
        return gyScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    gchart
      .append("g")
      .append("path")
      .attr("fill", "url('#myGradientlg')")
      .attr("class", "dash-line-area") // Assign a class for styling
      .attr("d", darea(gdatasetbic)); // 11. Calls the line generator

    const gbarGroups = gchart.selectAll().data(gsample).enter().append("g");

    gbarGroups
      .attr("class", "g-bar")
      .append("path")
      .attr("class", (g) => "bar ibar " + g.id + " g" + g.id + " vk-" + g.value)
      .attr("d", function (g) {
        if (g.value == 0) {
          return roundedRect(
            xScale(g.tech),
            yScale(2),
            xScale.bandwidth(),
            height - yScale(2),
            0,
            0,
            0
          );
        } else {
          return roundedRect(
            xScale(g.tech),
            yScale(g.value),
            xScale.bandwidth(),
            height - yScale(g.value),
            5,
            5,
            5
          );
        }
      })
      .style("fill", (g) => g.color)
      .on("mouseenter", function (d, i, n) {
        let bar21 = d3.select(this).node().getBBox();

        if (i.value != 0 || i.id == "ddata-12") {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          tooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html("<strong>" + i.tech + "</strong><br>" + "Click for details");
        } else if (1) {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          tooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html("<strong>" + i.tech + "</strong><br>" + "Click for details");
        } else {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          tooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html(
              "Buy the Full Assessment to<br>" + "see the complete results"
            );
        }
      })
      .on("mouseleave", function () {
        d3.selectAll(".ibar").attr("opacity", 1);
        tooltip.style("display", "none");
      })
      .on("click", function (d, i, n) {
        let ii = i.id;
        // HandlePOPUPopen();
        let groid = ii.replace("ddata-", "");

        // localStorage.setItem("group_id",JSON.stringify(groid));
        props.groupid(groid);
        if (paystatus != 3 || d.value != 0.0) {
          d3.selectAll(".ibar").attr("opacity", 1);
          d3.selectAll(".ibar").style("fill", "#ebf5fa");
          d3.selectAll(".ibar").style("stroke", "#ebf5fa");
          d3.select(this).style("fill", "#0b8ed1");
          d3.select(this).style("stroke", "#0b8ed1");
          d3.select(".s" + d.id).style("fill", "#0B8ED1");
          d3.select(".g" + d.id).style("fill", "#0B8ED1");
          d3.selectAll(".ival").style("fill", "#B6B6B6");
          d3.selectAll(".xlablel").style("fill", "#7D868C");
          d3.selectAll(".t" + d.id).style("fill", "#000000");
          d3.selectAll(".xt" + d.id).style("fill", "#000000");
          $(".assess-modal").hide();
          $(".assess-" + i.id).show();
          setBottomLift(".assess-" + i.id);
        }
      });

    var line = d3
      .line()
      .x(function (d, i) {
        return gxScale(d[0]) + gxScale.bandwidth() / 2;
      })
      .y(function (d) {
        return gyScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    gchart
      .append("g")
      .append("path")
      .attr("class", "line gline") // Assign a class for styling
      .attr("d", line(gdatasetavg)); // 11. Calls the line generator

    var dline = d3
      .line()
      .x(function (d, i) {
        return gxScale(d[0]) + gxScale.bandwidth() / 2;
      })
      .y(function (d) {
        return gyScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    gchart
      .append("g")
      .append("path")
      .attr("class", "gdash-line") // Assign a class for styling
      .attr("d", dline(gdatasetbic)); // 11. Calls the line generator

    const gtextGroups = gchart.selectAll().data(gsample).enter().append("g");

    gtextGroups
      .append("text")
      .attr("class", (a) => "value ival t" + a.id)
      .attr("x", (a) => gxScale(a.tech) + gxScale.bandwidth() / 2)
      .attr("y", (a) => gyScale(a.value) - 10)
      .attr("text-anchor", "middle")
      .text((a) => (a.value == "0.00" ? `N/A` : `${a.value}%`));

    const gsvgcont = document.querySelector(".svg");
    const gbar = document.querySelector(".bar");

    $(".res-tab-btn2").click(function (e) {
      let tmptarget = e.target.getAttribute("data-target");
      $(".res-tab-content2,.res-tab-btn2").removeClass("active");
      $(e.target).addClass("active");
      $(tmptarget).addClass("active");
      $(tmptarget + "-line").addClass("active");
    });
    $("#seeBetweenCompany").removeClass("active");
    $(document).on("click", ".bar-prev-btn", function (e) {
      var did = $(this).data("goto");

      d3.select(".ddata-" + did).dispatch("click");
    });
    $(document).on("click", ".bar-next-btn", function (e) {
      var did = $(this).data("goto");

      d3.select(".ddata-" + did).dispatch("click");
    });
    $(document).on("click", ".open-ct-pop", function (e) {
      var popid = $(this).attr("data-id");
      $(".menu-description").removeClass("des-show");
      $("#ct-pop-" + popid).addClass("des-show");
    });
    $(document).on("click", ".dimension_closebtn", function (e) {
      $(".menu-description").removeClass("des-show");
    });
  };

  // chart code end
  window.onclick = (e) => {
    if (!e.target.closest(".rs-chart-blocks")) {
      setRsPopup(null);
    }
  };

  const [newclass, setNewclass] = useState();
  const [image, setimage] = useState();
  const [iclass, seticlass] = useState();
  const [istyle, setistyle] = useState();
  const [c1class, setc1class] = useState();
  const [c2class, setc2class] = useState();
  const [c3class, setc3class] = useState();
  const [p1class, setp1class] = useState();
  const [p2class, setp2class] = useState();
  const [p3class, setp3class] = useState();

  var parser = "";
  var xmlDoc = "";
  var p = "";
  var h3 = "";
  var li = "";

  const [count, setCount] = useState(1);
  const [temp, setTemp] = useState(0);
  const indexscore = resultsDataItem.indexscore;
  const lowestscore = resultsDataItem.lowestscore;
  const avgscore = resultsDataItem.avgscore;
  const bestscore = resultsDataItem.bestscore;
  const plowestscore = resultsDataItem.plowestscore;
  const pavgscore = resultsDataItem.pavgscore;
  const pbestscore = resultsDataItem.pbestscore;

  useEffect(() => {
    drawChart();

    // const lowestscore = resultsDataItem.lowestscore;
    // side image and color
    if (indexscore < 25) {
      setimage(require("../../assets/img/result-tour/startup.svg").default);
      setNewclass("red");
    } else if (indexscore > 24 && indexscore < 50) {
      setimage(
        require("../../assets/img/result-tour/transforming.svg").default
      );
      setNewclass("orange");
    } else if (indexscore > 49 && indexscore < 75) {
      setimage(
        require("../../assets/img/result-tour/accelerating.svg").default
      );
      setNewclass("yellow");
    } else {
      setimage(require("../../assets/img/result-tour/thriving.svg").default);
      setNewclass("green");
    }
    // popup box
    if (indexscore <= 5) {
      seticlass("left");
      setistyle("calc(0.06vw * 10)");
    } else if (indexscore >= 95) {
      seticlass("right");
      setistyle("calc(0.06vw * 180)");
    } else {
      seticlass("mid");
      setistyle("calc(0.06vw * 76)");
    }
    // bottom range 1
    if (lowestscore < 25) {
      setc1class("red");
    } else if (lowestscore > 24 && lowestscore < 50) {
      setc1class("orange");
    } else if (lowestscore > 49 && lowestscore < 75) {
      setc1class("yellow");
    } else if (lowestscore > 74 && lowestscore < 85) {
      setc1class("green");
    } else {
      setc1class("green right");
    }
    // bottom range 2
    if (avgscore < 25) {
      setc2class("red");
    } else if (avgscore > 24 && avgscore < 50) {
      setc2class("orange");
    } else if (avgscore > 49 && avgscore < 75) {
      setc2class("yellow");
    } else if (avgscore > 74 && avgscore < 85) {
      setc2class("green");
    } else {
      setc2class("green right");
    }
    // bottom range 3
    if (bestscore < 25) {
      setc3class("red");
    } else if (bestscore > 24 && bestscore < 50) {
      setc3class("orange");
    } else if (bestscore > 49 && bestscore < 75) {
      setc3class("yellow");
    } else if (bestscore > 74 && bestscore < 85) {
      setc3class("green");
    } else {
      setc3class("green right");
    }

    // bottom range 1
    if (plowestscore < 25) {
      setp1class("red");
    } else if (plowestscore > 24 && plowestscore < 50) {
      setp1class("orange");
    } else if (plowestscore > 49 && plowestscore < 75) {
      setp1class("yellow");
    } else if (plowestscore > 74 && plowestscore < 85) {
      setp1class("green");
    } else {
      setp1class("green right");
    }
    // bottom range 2
    if (pavgscore < 25) {
      setp2class("red");
    } else if (pavgscore > 24 && pavgscore < 50) {
      setp2class("orange");
    } else if (pavgscore > 49 && pavgscore < 75) {
      setp2class("yellow");
    } else if (pavgscore > 74 && pavgscore < 85) {
      setp2class("green");
    } else {
      setp2class("green right");
    }
    // bottom range 3
    if (pbestscore < 25) {
      setp3class("red");
    } else if (pbestscore > 24 && pbestscore < 50) {
      setp3class("orange");
    } else if (pbestscore > 49 && pbestscore < 75) {
      setp3class("yellow");
    } else if (pbestscore > 74 && pbestscore < 85) {
      setp3class("green");
    } else {
      setp3class("green right");
    }
  }, []);

  //clean up function
  useEffect(() => {
    return () => {
      document
        .querySelector(".training-content")
        ?.classList.remove("scrolling-none");
      document.querySelectorAll(".rswlk-z-9999").forEach((item) => {
        item?.classList.remove("rswlk-z-9999");
      });
      document.querySelectorAll(".z-index9999").forEach((item) => {
        item?.classList.remove("z-index9999");
      });
    };
  }, []);
  return (
    <>
      {/* Page Title */}
      <Helmet>
        <title>Result | SmarterChains</title>
        <meta name="description" content="Result" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Assessment</BreadcrumbsItem>
      <BreadcrumbsItem to="/result">Explore Results</BreadcrumbsItem>
      {/* results wrapper */}
      <div className="result-2442021 rs-open">
        {/* this section has row */}
        <div className="asst-abt-content-body result-page-body">
          {/* this section has col */}
          <div className="row no-gutters px-0">
            {/* this section has sec1 */}
            <div className="col asst-abt-left">
              {/* this section has
                  res-h1
                  sec-1-row
              */}
              <div className="sec1">
                <h1 className="res-h1 mb-0">
                  {resultsDataItem.PlantName
                    ? resultsDataItem.PlantName.charAt(0).toUpperCase() +
                      resultsDataItem.PlantName.slice(1)
                    : "Your Plant"}
                  ​
                </h1>
                <div className="d-flex sec-1-row flex-column">
                  <div className="row no-gutters px-0">
                    <div className="col">
                      <h2 className="res-h2">Main Index Score</h2>
                    </div>
                    {/* col end */}
                    <div className="col text-right" id="highlight_tab">
                      <button
                        className={`res-tab-btn ${
                          firstTopTab === 1 ? " active" : ""
                        }`}
                        onClick={() => {
                          HandleFirstTab(1);
                        }}
                      >
                        Industry Benchmarking
                      </button>
                      <button
                        className={`res-tab-btn ${
                          firstTopTab === 0 ? " active" : ""
                        }`}
                        onClick={() => {
                          if (1) {
                            HandleFirstTab(0);
                          } else {
                            if (isUpgradePopup == false) {
                              HandleFirstTab(1);
                            } else {
                              HandleFirstTab(0);
                            }

                            HandleUpdgradePopup();
                          }
                        }}
                      >
                        Company Benchmarking
                      </button>
                    </div>
                    {/* col end */}
                  </div>
                  {/* row end */}
                  <div
                    className={`res-tab-content ${
                      firstTopTab === 0 ? " active" : ""
                    }`}
                    id="nestChart"
                  >
                    <div className="row no-gutters res-mt-10">
                      <div className=" res-indx-div">
                        <div className={`res-percent-div ${newclass}`}>
                          <div className="row no-gutters px-0 align-items-center">
                            <div className="col">
                              <sapn className="indx-scr-txt">Index Score​</sapn>
                            </div>
                            <div className="col-1" />
                          </div>
                          <p className="percent-text">
                            {resultsDataItem.indexscore >= 0
                              ? resultsDataItem.indexscore + "%"
                              : "N/A"}{" "}
                          </p>
                          <p className="percent-para">
                            Your Index Score represents the overall Industry 4.0
                            maturity of your plant.
                          </p>
                          <p className="percent-para-acc">
                            <img src={image} alt="true" />
                          </p>
                        </div>
                      </div>
                      {/*res-indx-div end*/}
                      <div
                        id="rs-slide-3"
                        className="rs-slide-3 col nes-chart-sec"
                      >
                        <div className="nes-chart-content">
                          <h2 className="col-12 px-0 nes-chart-title">
                            Company Benchmarking
                          </h2>
                          <div className="d-flex position-relative w-100 res-p-13">
                            <div className="bar red">
                              <div className="rs-chart-blocks rs-startup">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk stratup-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(0)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/startup-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop stratup-pop"
                                  style={{
                                    display:
                                      rsPopup === 0 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/startup-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Start Up</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Paper-based processes</li>
                                    <li>Islands of Automation</li>
                                    <li>Simple ERP functionalities</li>
                                    <li>Manual material handling</li>
                                    <li>Integration of key business systems</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#startup"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 1 end */}
                            <div className="bar orange">
                              <div className="rs-chart-blocks rs-transforming">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk transforming-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(1)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/transforming-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop transforming-pop"
                                  style={{
                                    display:
                                      rsPopup === 1 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/transforming-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Transforming</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Mostly automated production</li>
                                    <li>
                                      Vertical integrations of key systems
                                    </li>
                                    <li>
                                      Internet connectivity &amp; standard
                                      cybersecurity
                                    </li>
                                    <li>
                                      Just starting the Industry 4.0 journey
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#transforming"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 2 end */}
                            <div className="bar yellow">
                              <div className="rs-chart-blocks rs-accelerating">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk accelerating-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(2)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/accelerating-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop accelerating-pop"
                                  style={{
                                    display:
                                      rsPopup === 2 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/accelerating-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Accelerating</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Modern, cloud-based systems</li>
                                    <li>Early stages of advanced analytics</li>
                                    <li>Industry 4.0 roadmap in place</li>
                                    <li>
                                      Digital training plans for key roles
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#accelerating"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 3 end */}
                            <div className="bar green">
                              <div className="rs-chart-blocks rs-thriving">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk thriving-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(3)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/thriving-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop thriving-pop"
                                  style={{
                                    display:
                                      rsPopup === 3 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/thriving-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Thriving</span> archetype:
                                  </p>
                                  <ul>
                                    <li>
                                      Modular &amp; adaptive manufacturing
                                    </li>
                                    <li>Autonomous decision-making systems</li>
                                    <li>Horizontal value-chain integrations</li>
                                    <li>Automation of changeovers</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#thriving"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 4 end */}

                            <div
                              className={`bottom-range ${p1class} short`}
                              style={{ left: `${plowestscore}%` }}
                            >
                              <div className="d-flex align-items-end red">
                                Lowest:
                                <span> {plowestscore}%</span>
                              </div>
                            </div>
                            {/*bttom-range 1 end*/}
                            <div
                              className={`bottom-range ${p2class}`}
                              style={{ left: `${pavgscore}%` }}
                            >
                              <div className="d-flex align-items-end">
                                Average:
                                <span> {pavgscore}%</span>
                              </div>
                            </div>
                            {/*bttom-range 2 end*/}
                            <div
                              className={`bottom-range ${p3class} long`}
                              style={{ left: `${pbestscore}%` }}
                            >
                              <div className="d-flex align-items-end">
                                Highest:
                                <span> {pbestscore}%</span>
                              </div>
                            </div>
                            <div
                              className={`show-progress ${iclass}`}
                              style={{
                                left: `calc(${indexscore}% - calc(0.06vw * 76))`,
                              }}
                            >
                              <p className="mb-0">
                                Your Company <span>{indexscore}%</span>
                              </p>
                              <div className="img-circle" />
                            </div>
                          </div>
                          {/* d-flex end */}
                        </div>
                        {/* nes  chart content end */}
                      </div>
                    </div>
                  </div>
                  {/*  nestle chart tab content end*/}
                  <div
                    className={`res-tab-content ${
                      firstTopTab === 1 ? " active" : ""
                    }`}
                    id="globalChart"
                  >
                    <div className="row no-gutters res-mt-10">
                      <div className="res-indx-div">
                        <div
                          className={`res-percent-div ${
                            resultsDataItem.isResult1 != 1 &&
                            resultsDataItem.isResult != 1
                              ? "rswlk-z-9999"
                              : ""
                          } ${newclass}`}
                          id="rs-slide-2"
                        >
                          <div className="row no-gutters px-0 align-items-center">
                            <div className="col">
                              <sapn className="indx-scr-txt">Index Score​</sapn>
                            </div>
                            <div className="col-1" />
                          </div>
                          <p className="percent-text">
                            {resultsDataItem.indexscore >= 0
                              ? resultsDataItem.indexscore + "%"
                              : "N/A"}
                          </p>
                          <p className="percent-para">
                            Your Index Score represents the overall Industry 4.0
                            maturity of your plant.
                          </p>
                          <p className="percent-para-acc">
                            <img src={image} alt="true" />
                          </p>
                        </div>
                      </div>
                      {/*res-indx-div end*/}
                      <div className="col nes-chart-sec">
                        <div className="nes-chart-content">
                          {/* <div className="get-your-indx-box">
														<p className="">Get Your Index Score</p>
														<p className="blue-txt">
															<Link to="/plans" target="_blank" className="btn-get-asst">
																+ Buy Full Assessment
															</Link>
														</p>
													</div> */}
                          <h2 className="col-12 px-0 nes-chart-title">
                            Industry Benchmarking
                          </h2>
                          <div className="d-flex position-relative w-100 res-p-13">
                            <div className="bar red">
                              <div className="rs-chart-blocks rs-startup">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk stratup-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(0)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/startup-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop stratup-pop"
                                  style={{
                                    display:
                                      rsPopup === 0 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/startup-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => {
                                        setRsPopup(null);
                                      }}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Start Up</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Paper-based processes</li>
                                    <li>Islands of Automation</li>
                                    <li>Simple ERP functionalities</li>
                                    <li>Manual material handling</li>
                                    <li>Integration of key business systems</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#startup"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 1 end */}
                            <div className="bar orange">
                              <div className="rs-chart-blocks rs-transforming">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk transforming-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(1)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/transforming-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop transforming-pop"
                                  style={{
                                    display:
                                      rsPopup === 1 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/transforming-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Transforming</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Mostly automated production</li>
                                    <li>
                                      Vertical integrations of key systems
                                    </li>
                                    <li>
                                      Internet connectivity &amp; standard
                                      cybersecurity
                                    </li>
                                    <li>
                                      Just starting the Industry 4.0 journey
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#transforming"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 2 end */}
                            <div className="bar yellow">
                              <div className="rs-chart-blocks rs-accelerating">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk accelerating-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(2)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/accelerating-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop accelerating-pop"
                                  style={{
                                    display:
                                      rsPopup === 2 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/accelerating-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Accelerating</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Modern, cloud-based systems</li>
                                    <li>Early stages of advanced analytics</li>
                                    <li>Industry 4.0 roadmap in place</li>
                                    <li>
                                      Digital training plans for key roles
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#accelerating"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 3 end */}
                            <div className="bar green">
                              <div className="rs-chart-blocks rs-thriving">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk thriving-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(3)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/thriving-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop thriving-pop"
                                  style={{
                                    display:
                                      rsPopup === 3 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/thriving-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Thriving</span> archetype:
                                  </p>
                                  <ul>
                                    <li>
                                      Modular &amp; adaptive manufacturing
                                    </li>
                                    <li>Autonomous decision-making systems</li>
                                    <li>Horizontal value-chain integrations</li>
                                    <li>Automation of changeovers</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="/digital-plant#thriving"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`bottom-range ${c1class} short`}
                              style={{ left: `${lowestscore}%` }}
                            >
                              <div className="d-flex align-items-end red">
                                Lowest:
                                <span> {lowestscore}%</span>
                              </div>
                            </div>
                            {/*bttom-range 1 end*/}
                            <div
                              className={`bottom-range ${c2class}`}
                              style={{ left: `${avgscore}%` }}
                            >
                              <div className="d-flex align-items-end">
                                Average:
                                <span> {avgscore}%</span>
                              </div>
                            </div>
                            {/*bttom-range 2 end*/}
                            <div
                              className={`bottom-range ${c3class} long`}
                              style={{ left: `${bestscore}%` }}
                            >
                              <div className="d-flex align-items-end">
                                Highest:
                                <span> {bestscore}%</span>
                              </div>
                            </div>
                            {/*bttom-range 3 end*/}
                            <div
                              className={`show-progress ${iclass}`}
                              style={{
                                left: `calc(${indexscore}% - calc(0.06vw * 76))`,
                              }}
                            >
                              <p className="mb-0">
                                Your Company&nbsp;<span>{indexscore}%</span>
                              </p>
                              <div className="img-circle" />
                            </div>
                            {/* show -progress end */}
                          </div>
                          {/* d-flex end */}
                        </div>
                        {/* nes  chart content end */}
                      </div>
                    </div>
                  </div>
                  {/*  gloabl chart tab content end*/}
                </div>
                {/* flex  div end*/}
              </div>
              {/* sec 1 end */}
              <div className="sec1 allshow">
                <div
                  className="d-flex sec-1-row flex-column"
                  id="main_graph_div"
                >
                  <div className="row no-gutters px-0 align-items-center">
                    <div className="col">
                      <h2 className="res-h2 mb-0">Index Per Dimension</h2>
                    </div>
                    {/* col end */}
                    <div
                      className={`col px-0 res-tab-content2 ${
                        graphTab === 0 ? " active" : ""
                      }`}
                      id="seeBetweenCompany-line"
                    >
                      <div className="d-flex align-items-center">
                        <div className="col px-0">
                          <span className="best-calss">
                            Company Best in Class
                          </span>
                        </div>
                        {/* col end */}
                        <div className="col px-0">
                          <span className="global-avg comp">
                            Company Average
                          </span>
                        </div>
                        {/* col end */}
                      </div>
                      {/* d flex end */}
                    </div>
                    {/* col end */}
                    <div
                      className={`col px-0 res-tab-content2 ${
                        graphTab === 1 ? " active" : ""
                      }`}
                      id="seeBetweenGlobal-line"
                    >
                      <div className="d-flex align-items-center">
                        <div className="col px-0">
                          <span className="best-calss gbest-calss">
                            Industry Best in Class
                          </span>
                        </div>
                        {/* col end */}
                        <div className="col px-0">
                          <span className="global-avg">Industry Average</span>
                        </div>
                        {/* col end */}
                      </div>
                      {/* d flex end */}
                    </div>
                    {/* col end */}
                    <div
                      className="col text-right dual-tab "
                      id="highlightTab2"
                    >
                      <button
                        className={`res-tab-btn2 ${
                          graphTab === 1 ? " active" : ""
                        }`}
                        onClick={() => HandleGraphtTab(1)}
                        data-target="#seeBetweenGlobal"
                      >
                        Industry Benchmarking
                      </button>
                      <button
                        className={`res-tab-btn2 ${
                          graphTab === 0 ? " active" : ""
                        }`}
                        data-target="#seeBetweenCompany"
                        onClick={() => {
                          if (1) {
                            HandleGraphtTab(0);
                          } else {
                            if (isUpgradePopup == false) {
                              HandleGraphtTab(1);
                            } else {
                              HandleGraphtTab(0);
                            }

                            HandleUpdgradePopup();
                          }
                        }}
                      >
                        Company Benchmarking
                      </button>
                    </div>
                    {/* col end */}
                  </div>
                  {/* row end */}

                  <div
                    className="res-tab-content2 active"
                    id="seeBetweenCompany"
                  >
                    <div
                      className="row no-gutters res-mt-10 px-0"
                      style={{ backgroundColor: "white" }}
                    >
                      <div id="bchart" className="rs-slide-4 bchart w-100 px-3">
                        {/*
                         */}
                      </div>
                    </div>
                  </div>
                  {/*  nestle chart tab content end*/}
                  <div
                    className="res-tab-content2 active"
                    id="seeBetweenGlobal"
                  >
                    <div
                      className="row no-gutters res-mt-10 px-0"
                      style={{ backgroundColor: "white" }}
                    >
                      <div id="gchart" className="rs-slide-4 bchart w-100 px-3">
                        {/*
                         */}
                      </div>
                    </div>
                  </div>
                  {/*  gloabl chart tab content end*/}
                </div>
                {/* flex  div end*/}
              </div>
              {/* sec 1 end */}
              <div ref={messagesEndRef} />
              {/* start here from graph data */}
              {resultsDataItem.dimensions.map((item, index) => {
                return (
                  <div
                    data-sticky-container
                    className={`row res-chart-popups assess-modal assess-ddata-${item.group_id}`}
                    style={{ display: "none" }}
                  >
                    {/* Start overlay for Unlock full result feature  */}
                    <div className="result-unlock-overlay-master">
                      <a
                        href="#"
                        className="result_call_book_btn r_unlock"
                        onClick={() => {
                          FirePopup(
                            "https://calendly.com/smarterchains/1h-free-industry-4-0-consultation-call",
                            {
                              minWidth: "320px",
                              height: "630px",
                            }
                          );
                        }}
                      >
                        <img
                          src={
                            require("../../assets/img/limited-result/unlock.svg")
                              .default
                          }
                          className="result_call_book_icon"
                          alt
                        />
                        Unlock Full Results
                      </a>
                      <div className="result-unlock-overlay-call-btn-wrap">
                        <p className="result-unlock-overlay-head">
                          Contact us to get access to the full results
                        </p>
                        <a
                          href="#"
                          className="result_call_book_btn free_call"
                          onClick={() => {
                            FirePopup(
                              "https://calendly.com/smarterchains/1h-free-industry-4-0-consultation-call",
                              {
                                minWidth: "320px",
                                height: "630px",
                              }
                            );
                          }}
                        >
                          <img
                            src={
                              require("../../assets/img/limited-result/call.svg")
                                .default
                            }
                            className="result_call_book_free_icon"
                            alt
                          />
                          Book 1h Free Consultation Call
                        </a>
                      </div>
                    </div>
                    {/* End overlay for Unlock full result feature  */}
                    <div className="col-2-res sticky lr_blur">
                      <div className="s-result-cir-piechart">
                        <svg viewBox="0 0 36 36">
                          <path
                            className="circle-bg"
                            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                            stroke="#F0F0F0"
                            strokeWidth="3.4"
                            fill="none"
                          />
                          <path
                            className="circle"
                            strokeDasharray={`${Math.round(
                              item.score_value
                            )}, 100`}
                            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                            stroke="
            #118ACB"
                            strokeWidth="3.4"
                            fill="none"
                          />
                        </svg>
                        <div className="s-result-cir-div-txt">
                          <p>
                            {resultsDataItem.PlantName.charAt(0).toUpperCase() +
                              resultsDataItem.PlantName.slice(1)}
                          </p>
                          <h1>{Math.round(item.score_value)}%</h1>
                        </div>
                      </div>

                      <div className="s-result-cir-piechart">
                        <svg viewBox="0 0 36 36">
                          <path
                            className="circle-bg"
                            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                            stroke="#F0F0F0"
                            strokeWidth="3.4"
                            fill="none"
                          />
                          <path
                            className="circle"
                            strokeDasharray={`${Math.round(
                              resultsDataItem.avgdimensions[index].score_value
                            )}, 100`}
                            d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                            stroke="#FFAB84"
                            strokeWidth="3.4"
                            fill="none"
                          />
                        </svg>
                        <div className="s-result-cir-div-txt">
                          <p>Industry Average</p>
                          <h1>
                            {Math.round(
                              resultsDataItem.avgdimensions[index].score_value
                            )}
                            %
                          </h1>
                        </div>
                      </div>

                      {
                        <div className="s-result-cir-piechart">
                          <svg viewBox="0 0 36 36">
                            <path
                              className="circle-bg"
                              d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                              stroke="#F0F0F0"
                              strokeWidth="3.4"
                              fill="none"
                            />
                            <path
                              className="circle"
                              strokeDasharray={`${Math.round(
                                resultsDataItem.bestdimensions[index]
                                  .score_value
                              )}, 100`}
                              d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                              stroke="#7FCDBB"
                              strokeWidth="3.4"
                              fill="none"
                            />
                          </svg>
                          <div className="s-result-cir-div-txt">
                            <p>Industry BIC</p>
                            <h1>
                              {Math.round(
                                resultsDataItem.bestdimensions[index]
                                  .score_value
                              )}
                              %
                            </h1>
                          </div>
                        </div>
                      }
                      {/* /Industry BIC */}
                    </div>
                    <div className="col-10-res lr_blur">
                      <div className="res-popup-header">
                        <div className="d-flex align-items-center">
                          <div className="col px-0">
                            <h2 className="res-h2" id="highlight3h1">
                              {item.group_name}
                            </h2>
                          </div>
                          {/* col end*/}
                          <div className="col-1 px-0 text-right">
                            <a
                              className="assess-close"
                              href="javascript:void(0)"
                            >
                              <img
                                src={
                                  require("../../assets/img/result-tour/res-popup-close.svg")
                                    .default
                                }
                                className="res-popup-close-icon"
                                alt
                              />
                            </a>
                          </div>
                          {/* col end */}
                        </div>
                        {/* d-flex end */}
                        <div className="d-flex align-items-center">
                          <div className="col px-0">
                            <p className="res-popup-para"></p>
                          </div>
                        </div>
                      </div>
                      {/* header end*/}

                      <div className="res-popup-body">
                        <div className="col-12-res">
                          {`${count}` && !isEmptyObject(truetheme) && (
                            <>
                              <div className="s-result-tab-content mb-44">
                                <div className="s-result-row">
                                  {/* s-result-col-sm end */}
                                  <div className="s-result-col-rgt rwd-100">
                                    <div className="s-result-tab">
                                      <div
                                        id="strengthHighlight"
                                        className="s-result-link green active"
                                        data-target="#sResultTab1"
                                      >
                                        STRENGTH
                                      </div>
                                    </div>
                                  </div>
                                  {/* s-result-col-lg end */}
                                </div>
                              </div>
                              {`${temp}` &&
                                truetheme.map((item, index) => {
                                  const gadpopt = item.industry_adoption;
                                  const topadpopt = item.bic_adoption;
                                  const cadpopt = item.adoption;
                                  var class1 = "";
                                  var class2 = "";
                                  var class3 = "";
                                  if (cadpopt < 25) {
                                    class1 = "red";
                                  } else if (cadpopt > 24 && cadpopt < 50) {
                                    class1 = "orange";
                                  } else if (cadpopt > 49 && cadpopt < 75) {
                                    class1 = "yellow";
                                  } else {
                                    class1 = "green";
                                  }
                                  if (gadpopt < 25) {
                                    class2 = "red";
                                  } else if (gadpopt > 24 && gadpopt < 50) {
                                    class2 = "orange";
                                  } else if (gadpopt > 49 && gadpopt < 75) {
                                    class2 = "yellow";
                                  } else {
                                    class2 = "green";
                                  }
                                  if (topadpopt < 25) {
                                    class3 = "red";
                                  } else if (topadpopt > 24 && topadpopt < 50) {
                                    class3 = "orange";
                                  } else if (topadpopt > 49 && topadpopt < 75) {
                                    class3 = "yellow";
                                  } else {
                                    class3 = "green";
                                  }

                                  return (
                                    <div
                                      className="s-result-tab-content hold-data"
                                      id="sResultTab1"
                                      style={{ display: "block" }}
                                    >
                                      <div className="s-result-row mb-30">
                                        {/* s-result-col-sm end */}
                                        <div className="s-result-col-rgt rwd-100">
                                          <div className="s-result-card">
                                            <div className="s-result-card-lft">
                                              <div className="d-flex flex-column h-100">
                                                <div className="s-result-left-content hideContent">
                                                  <h1 className="s-result-card-h1">
                                                    {item.common_theme
                                                      ? item.common_theme
                                                      : "Enterprise-wide Industry 4.0 strategy"}
                                                    {/* Enterprise-wide Industry 4.0 strategy */}
                                                  </h1>
                                                  {
                                                    ((parser = new DOMParser()),
                                                    (xmlDoc =
                                                      parser.parseFromString(
                                                        item.description,
                                                        "text/html"
                                                      )),
                                                    (
                                                      <div
                                                        className={`t_des_r ${
                                                          readmoreObj[
                                                            `strn${index}`
                                                          ]
                                                            ? "active"
                                                            : ""
                                                        }`}
                                                        dangerouslySetInnerHTML={{
                                                          __html: `${
                                                            xmlDoc.getElementsByTagName(
                                                              "body"
                                                            )[0].innerHTML
                                                          }`,
                                                        }}
                                                      ></div>
                                                    ))
                                                  }

                                                  <div className="col px-0 s-result-learn-more">
                                                    <button
                                                      className
                                                      data-id={temp}
                                                      onClick={() =>
                                                        setReadmoreObj(
                                                          (prv) => {
                                                            return {
                                                              ...prv,
                                                              [`strn${index}`]:
                                                                !readmoreObj[
                                                                  `strn${index}`
                                                                ],
                                                            };
                                                          }
                                                        )
                                                      }
                                                    >
                                                      {readmoreObj[
                                                        `strn${index}`
                                                      ]
                                                        ? "Read less"
                                                        : "Read more"}
                                                    </button>
                                                  </div>
                                                </div>
                                                <div
                                                  className={`ln-dis-wrap ln-${temp}`}
                                                >
                                                  <div className="px-0 s-learn-more">
                                                    <a
                                                      id={12}
                                                      href="https://ngversion.karamalegkos.com/training/courseDetails/1"
                                                      target="_blank"
                                                    >
                                                      Learn more
                                                    </a>
                                                  </div>
                                                  <div className="px-0 s-result-vendors-link">
                                                    <a
                                                      href={`https://ngversion.karamalegkos.com/vendor?vid=12`}
                                                      className="float-right"
                                                      target="_blank"
                                                    >
                                                      Discover Vendors
                                                    </a>
                                                  </div>
                                                </div>
                                                {/* left-content end */}
                                                {/* s-result-left-footer end */}
                                              </div>
                                            </div>
                                            {/*s-result-card-lft end  */}
                                            <div className="s-result-card-rgt">
                                              <div className="s-result-progress-container">
                                                <div className="s-res-progress-percent">
                                                  {cadpopt
                                                    ? ` ${cadpopt}%`
                                                    : "N/A"}
                                                </div>
                                                <div className="s-res-progress-title">
                                                  Company Adoption
                                                </div>
                                                <div
                                                  className={`s-res-progress-bar ${class1}`}
                                                >
                                                  <div
                                                    className="s-res-progress-status"
                                                    style={{
                                                      width: cadpopt
                                                        ? ` ${cadpopt}%`
                                                        : "0%",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {/* s-result-progress-container end 1 */}
                                              <div className="s-result-progress-container">
                                                <div className="s-res-progress-percent">
                                                  {gadpopt
                                                    ? ` ${gadpopt}%`
                                                    : "51%"}
                                                </div>
                                                <div className="s-res-progress-title">
                                                  Industry Adoption
                                                </div>
                                                <div
                                                  className={`s-res-progress-bar ${class2}`}
                                                >
                                                  <div
                                                    className="s-res-progress-status"
                                                    style={{
                                                      width: gadpopt
                                                        ? ` ${gadpopt}%`
                                                        : "51%",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {/* s-result-progress-container end 2 */}
                                              <div className="s-result-progress-container">
                                                <div className="s-res-progress-percent">
                                                  {topadpopt
                                                    ? ` ${topadpopt}%`
                                                    : "100%"}
                                                </div>
                                                <div className="s-res-progress-title">
                                                  Best in Class Adoption
                                                </div>
                                                <div
                                                  className={`s-res-progress-bar ${class3}`}
                                                >
                                                  <div
                                                    className="s-res-progress-status full"
                                                    style={{
                                                      width: topadpopt
                                                        ? ` ${topadpopt}%`
                                                        : "100%",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {/* s-result-progress-container end 3*/}

                                              <div
                                                className={`ln-dis-wrap rn-${temp}`}
                                                style={{ display: "none" }}
                                              >
                                                <div className="px-0 s-learn-more">
                                                  <a
                                                    id={12}
                                                    href="https://ngversion.karamalegkos.com/training/courseDetails/1"
                                                    target="_blank"
                                                  >
                                                    Learn more
                                                  </a>
                                                </div>
                                                <div className="px-0 s-result-vendors-link">
                                                  <a
                                                    href="https://ngversion.karamalegkos.com/vendor?vid=12"
                                                    className="float-right"
                                                    target="_blank"
                                                  >
                                                    Discover Vendors
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                            {/*s-result-card-rgt end  */}
                                          </div>
                                        </div>
                                        {/* s-result-col-lg end */}
                                      </div>
                                    </div>
                                  );
                                })}{" "}
                            </>
                          )}

                          {!isEmptyObject(flasetheme) && (
                            <>
                              <div className="s-result-tab-content mb-44">
                                <div className="s-result-row">
                                  {/* s-result-col-sm end */}
                                  <div className="s-result-col-rgt">
                                    <div className="s-result-tab">
                                      <div
                                        className="s-result-link blue active"
                                        data-target="#sResultTab1"
                                        id="strengthHighlight"
                                      >
                                        OPPORTUNITY
                                      </div>
                                    </div>
                                  </div>
                                  {/* s-result-col-lg end */}
                                </div>
                              </div>
                              {flasetheme.map((item, index) => {
                                const gadpopt = item.industry_adoption;
                                const topadpopt = item.bic_adoption;
                                const cadpopt = item.adoption;
                                var class1 = "";
                                var class2 = "";
                                var class3 = "";
                                if (cadpopt < 25) {
                                  class1 = "red";
                                } else if (cadpopt > 24 && cadpopt < 50) {
                                  class1 = "orange";
                                } else if (cadpopt > 49 && cadpopt < 75) {
                                  class1 = "yellow";
                                } else {
                                  class1 = "green";
                                }
                                if (gadpopt < 25) {
                                  class2 = "red";
                                } else if (gadpopt > 24 && gadpopt < 50) {
                                  class2 = "orange";
                                } else if (gadpopt > 49 && gadpopt < 75) {
                                  class2 = "yellow";
                                } else {
                                  class2 = "green";
                                }
                                if (topadpopt < 25) {
                                  class3 = "red";
                                } else if (topadpopt > 24 && topadpopt < 50) {
                                  class3 = "orange";
                                } else if (topadpopt > 49 && topadpopt < 75) {
                                  class3 = "yellow";
                                } else {
                                  class3 = "green";
                                }
                                return (
                                  <div
                                    className="s-result-tab-content hold-data"
                                    id="sResultTab1"
                                    style={{ display: "block" }}
                                  >
                                    <div className="s-result-row mb-30">
                                      {/* s-result-col-sm end */}
                                      <div className="s-result-col-rgt rwd-100">
                                        <div className="s-result-card blue">
                                          <div className="s-result-card-lft">
                                            <div className="d-flex flex-column h-100">
                                              <div
                                                className="
                                    s-result-left-content
                                    hideContent
                                  "
                                              >
                                                <h1 className="s-result-card-h1">
                                                  {item.common_theme
                                                    ? item.common_theme
                                                    : "Industry 4.0 Awareness"}
                                                  {/* Industry 4.0 Awareness */}
                                                </h1>
                                                {
                                                  ((parser = new DOMParser()),
                                                  (xmlDoc =
                                                    parser.parseFromString(
                                                      item.description,
                                                      "text/html"
                                                    )),
                                                  (
                                                    <div
                                                      className={`t_des_r ${
                                                        readmoreObj[
                                                          `opp${index}`
                                                        ]
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                      dangerouslySetInnerHTML={{
                                                        __html: `${
                                                          xmlDoc.getElementsByTagName(
                                                            "body"
                                                          )[0].innerHTML
                                                        }`,
                                                      }}
                                                    ></div>
                                                  ))
                                                }

                                                <div className="col px-0 s-result-learn-more">
                                                  <button
                                                    className
                                                    data-id={temp}
                                                    onClick={() =>
                                                      setReadmoreObj((prv) => {
                                                        return {
                                                          ...prv,
                                                          [`opp${index}`]:
                                                            !readmoreObj[
                                                              `opp${index}`
                                                            ],
                                                        };
                                                      })
                                                    }
                                                  >
                                                    {readmoreObj[`opp${index}`]
                                                      ? "Read less"
                                                      : "Read more"}
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="ln-dis-wrap ln-100">
                                                <div className="px-0 s-learn-more">
                                                  <a
                                                    id={12}
                                                    href="https://ngversion.karamalegkos.com/training/courseDetails/1"
                                                    target="_blank"
                                                  >
                                                    Learn more
                                                  </a>
                                                </div>
                                                <div className="px-0 s-result-vendors-link">
                                                  <a
                                                    href="https://ngversion.karamalegkos.com/vendor?vid=12"
                                                    className="float-right"
                                                    target="_blank"
                                                  >
                                                    Discover Vendors
                                                  </a>
                                                </div>
                                              </div>
                                              {/* left-content end */}
                                              {/* s-result-left-footer end */}
                                            </div>
                                          </div>
                                          {/*s-result-card-lft end  */}
                                          <div className="s-result-card-rgt">
                                            <div className="s-result-progress-container">
                                              <div className="s-res-progress-percent">
                                                {cadpopt
                                                  ? `${cadpopt}%`
                                                  : "N/A"}
                                              </div>
                                              <div className="s-res-progress-title">
                                                Company Adoption
                                              </div>
                                              <div
                                                className={`s-res-progress-bar ${class1}`}
                                              >
                                                <div
                                                  className="s-res-progress-status"
                                                  style={{
                                                    width: cadpopt
                                                      ? `${cadpopt}%`
                                                      : "0%",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {/* s-result-progress-container end 1 */}
                                            <div className="s-result-progress-container">
                                              <div className="s-res-progress-percent">
                                                {gadpopt
                                                  ? `${gadpopt}%`
                                                  : "38%"}
                                              </div>
                                              <div className="s-res-progress-title">
                                                Industry Adoption
                                              </div>
                                              <div
                                                className={`s-res-progress-bar ${class2}`}
                                              >
                                                <div
                                                  className="s-res-progress-status"
                                                  style={{
                                                    width: gadpopt
                                                      ? `${gadpopt}%`
                                                      : "38%",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {/* s-result-progress-container end 2 */}
                                            <div className="s-result-progress-container">
                                              <div className="s-res-progress-percent">
                                                {topadpopt
                                                  ? `${topadpopt}%`
                                                  : "100%"}
                                              </div>
                                              <div className="s-res-progress-title">
                                                Best in Class Adoption
                                              </div>
                                              <div
                                                className={`s-res-progress-bar ${class3}`}
                                              >
                                                <div
                                                  className="s-res-progress-status full"
                                                  style={{
                                                    width: topadpopt
                                                      ? `${topadpopt}%`
                                                      : "100%",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            {/* s-result-progress-container end 3*/}
                                            <div
                                              className="ln-dis-wrap rn-100"
                                              style={{ display: "none" }}
                                            >
                                              <div className="px-0 s-learn-more">
                                                <a
                                                  id={12}
                                                  href="https://ngversion.karamalegkos.com/training/courseDetails/1"
                                                  target="_blank"
                                                >
                                                  Learn more
                                                </a>
                                              </div>
                                              <div className="px-0 s-result-vendors-link">
                                                <a
                                                  href="https://ngversion.karamalegkos.com/vendor?vid=12"
                                                  className="float-right"
                                                  target="_blank"
                                                >
                                                  Discover Vendors
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          {/*s-result-card-rgt end  */}
                                        </div>
                                      </div>
                                      {/* s-result-col-lg end */}
                                    </div>
                                  </div>
                                );
                              })}{" "}
                            </>
                          )}
                        </div>
                      </div>
                      {/* end themes	 */}
                    </div>
                  </div>
                );
              })}

              {/* end here of graph data */}
            </div>
            {/*asst-abt-left end*/}
          </div>
          {/* row end */}
        </div>
        {/* asst content body end*/}
      </div>

      <Modal
        open={isUpgradePopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="home-index-upgrade-popup"
      >
        <div className="upgrade-popup-content">
          {/* this section has
              close button
              upgrd-popup-img
              upgrd-popup-body
          */}
          <button
            className="close-btn"
            onClick={() => {
              HandleUpdgradePopup();
            }}
          >
            <img
              src={
                require("../../assets/img/common-img/close-icon-blue.svg")
                  .default
              }
              alt="close"
            />
          </button>
          <div className="upgrd-popup-img">
            <img
              src={
                require("../../assets/img/home-index/digital-maturity.gif")
                  .default
              }
              alt="digital_maturity"
            />
          </div>
          {/* this section has
              heading
              text
              link
          */}
          <div className="upgrd-popup-body">
            <h2 className="h1">
              Buy full Digital Maturity Assessment to Unlock Plants
            </h2>
            <p>
              Upgrade to the plan that best matches your profile and start the
              Assessment as early as today.
            </p>
            <Link
              to="/plans"
              className="btn-upgrade-now"
              onClick={HandleUpdgradePopup}
            >
              Upgrade Now{" "}
            </Link>
          </div>
        </div>
      </Modal>

      {(isOpen && resultsDataItem.isResult != 1) ||
      resultsDataItem.isResult1 != 1 ? (
        <Modal open={isOpen} className="result-walkthrough-modal">
          <div
            className={`result-wlakthrough-content ${
              activeSlide === 2 && " change-posion"
            } ${activeSlide === 1 && "content-1"}`}
          >
            {/* card has
            close button
            div-img
            div-txt
        */}
            <div className={`card ${activeSlide === 0 && " active"}`}>
              <Link
                className="clsoe-btn"
                onClick={() => {
                  handelCloseWalkThrough(activeSlide);
                }}
              >
                <img
                  src={
                    require("../../assets/img/common-img/close-icon-gray.svg")
                      .default
                  }
                  alt="X"
                />
              </Link>
              <div className="div-img">
                <img
                  src={
                    require("../../assets/img/result-walkthrough/step1.gif")
                      .default
                  }
                  alt="true"
                />
              </div>
              {/* div-img end */}
              {/* this section has 
              card-content
              card-footer
          */}
              <div className="div-txt">
                {/* this section has 
                heading
                page number
                text
            */}
                <div className="card-content">
                  <h3 className="dull-text">1 of 3</h3>
                  <h2 className="heading-h1">Assessment Results</h2>
                  <p className="para-p">These are your plant’s results.</p>
                  <p className="para-p">
                    Here you can see your plant’s Index, archetype and benchmark
                    between your company and industry.
                  </p>
                </div>
                {/* this section has 
                dots-sec
                buttons
                for navigation 
            */}
                <div className="card-footer">
                  <div className="dots-sec">
                    <button
                      onClick={() => HandleSlides(0)}
                      disabled={activeSlide === 0 ? true : false}
                    ></button>
                    <button
                      onClick={() => HandleSlides(1)}
                      disabled={activeSlide === 1 ? true : false}
                    ></button>
                    <button
                      onClick={() => HandleSlides(2)}
                      disabled={activeSlide === 2 ? true : false}
                    ></button>
                  </div>
                  <button
                    onClick={() => {
                      handelCloseWalkThrough(activeSlide);
                    }}
                    className="btn-prv"
                    to="/view-result"
                  >
                    Exit walkthrough
                  </button>
                  <button className="btn-nxt" onClick={() => HandleSlides(1)}>
                    Next
                  </button>
                </div>
              </div>
              {/* div-img end */}
            </div>
            {/* step 1 end */}
            <div className={`card top ${activeSlide === 1 && " active"}`}>
              <button
                onClick={() => {
                  handelCloseWalkThrough(activeSlide);
                }}
                className="clsoe-btn"
              >
                <img
                  src={
                    require("../../assets/img/common-img/close-icon-gray.svg")
                      .default
                  }
                  alt="X"
                />
              </button>
              <div className="div-img">
                <img
                  src={
                    require("../../assets/img/result-walkthrough/step2.gif")
                      .default
                  }
                  alt="true"
                />
              </div>
              {/* div-img end */}
              <div className="div-txt">
                <div className="card-content">
                  <h3 className="dull-text">2 of 3</h3>
                  <h2 className="heading-h1">Index per Dimension</h2>
                  <p className="para-p">
                    Here you can see your Index analysed in our 10 dimensions
                    framework and benchmark between your company and industry.
                  </p>
                </div>
                <div className="card-footer">
                  <div className="dots-sec">
                    <button
                      onClick={() => HandleSlides(0)}
                      disabled={activeSlide === 0 ? true : false}
                    ></button>
                    <button
                      onClick={() => HandleSlides(1)}
                      disabled={activeSlide === 1 ? true : false}
                    ></button>
                    <button
                      onClick={() => HandleSlides(2)}
                      disabled={activeSlide === 2 ? true : false}
                    ></button>
                  </div>
                  <button className="btn-prv" onClick={() => HandleSlides(0)}>
                    Previous
                  </button>
                  <button className="btn-nxt" onClick={() => HandleSlides(2)}>
                    Next
                  </button>
                </div>
              </div>
              {/* div-img end */}
            </div>
            {/* step 2 end */}
            <div className={`card ${activeSlide === 2 && " active"}`}>
              <button
                onClick={() => {
                  handelCloseWalkThrough(activeSlide);
                }}
                className="clsoe-btn"
              >
                <img
                  src={
                    require("../../assets/img/common-img/close-icon-gray.svg")
                      .default
                  }
                  alt="X"
                />
              </button>
              <div className="div-img">
                <img
                  src={
                    require("../../assets/img/result-walkthrough/step3.gif")
                      .default
                  }
                  alt="true"
                />
              </div>
              {/* div-img end */}
              <div className="div-txt">
                <div className="card-content">
                  <h3 className="dull-text">3 of 3</h3>
                  <h2 className="heading-h1">Insights</h2>
                  <p className="para-p">
                    For each Dimension you gain insights about the adoption
                    rates of technologies, organizational constructs or
                    leadership attributes within your company and industry.
                  </p>
                </div>
                <div className="card-footer">
                  <div className="dots-sec">
                    <button
                      onClick={() => HandleSlides(0)}
                      disabled={activeSlide === 0 ? true : false}
                    ></button>
                    <button
                      onClick={() => HandleSlides(1)}
                      disabled={activeSlide === 1 ? true : false}
                    ></button>
                    <button
                      onClick={() => HandleSlides(2)}
                      disabled={activeSlide === 2 ? true : false}
                    ></button>
                  </div>
                  <button className="btn-prv" onClick={() => HandleSlides(0)}>
                    Restart
                  </button>
                  <button
                    onClick={() => {
                      handelCloseWalkThrough(activeSlide);
                    }}
                    className="btn-nxt"
                  >
                    Close
                  </button>
                </div>
              </div>
              {/* div-img end */}
            </div>
            {/* step 2 end */}
          </div>
        </Modal>
      ) : null}
    </>
  );
};
//progress circle component
const SvgCircle = (props) => {
  const { value, color, title } = props;
  return (
    <div className="s-result-cir-piechart">
      <svg viewBox="0 0 36 36">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke="#F0F0F0"
          strokeWidth="3.4"
          fill="none"
        />
        <path
          className="circle"
          strokeDasharray={`${value},100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={
            color === "blue"
              ? "#118ACB"
              : color === "orange"
              ? "#FFAB84"
              : "#7FCDBB"
          }
          strokeWidth="3.4"
          fill="none"
        />
      </svg>
      <div className="s-result-cir-div-txt">
        <p>{title}</p>
        <h1>{value}%</h1>
      </div>
    </div>
  );
};
