/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * LossAnalysisSetupPageView ===> custom view component
  * Helmet ===> for add specific title & CSS for the page.

**/
import React from "react";
import { LossAnalysisSetupPageView } from "./loss-analysis-setup-page-view";
import {Helmet} from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
//LossAnalysisSetupPageController starts
export const LossAnalysisSetupPageController = (props) => {
  return (
    <>
      {/* css for styling */}
      <Helmet>
        <link rel="stylesheet" href={require('./loss-analysis-setup-page.scss').default} />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="#">IR4.0 Loss Analysis</BreadcrumbsItem>
      {/* custom view component */}
      <LossAnalysisSetupPageView {...props} />    
    </>
  );
};