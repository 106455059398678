/** imported files

# serviceProvider ===>from axios for api call  
# SolutionView ===> custom component

**/


import React from 'react';
import serviceProvider from '../../services/axios'
import { useEffect, useState } from 'react';
import { SolutionView } from "./solution-view";
export const SolutionController=(props)=>{
  // variable declaration
  const [vresults, setVResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
 
  // useEffect call here
 useEffect(() => {
		    const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=bdashboardsolution&slug=${props.slug}&uid=${usid}&id=${props.id}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setVResults(data);
		};
		search();
	}, []);
   
   return(
          <>
         
            <SolutionView validResult={vresults} {...props}/>
          </>
      )




} 
