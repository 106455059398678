import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { ResultWalkthroughView } from "./view-result-walkthrough-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

export const ViewResultWalkthroughController = (props) => {
	const [results, setResults] = useState([]);
	const [refresh, setRefresh] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
			const apiUrl = `/results?param=result&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, []);

	return (
		<>
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/assessment">Assessment</BreadcrumbsItem>
			<BreadcrumbsItem>Explore Results</BreadcrumbsItem>
			<ResultWalkthroughView resultsData={results} {...props} />
		</>
	);
};
