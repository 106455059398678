/** imported components
 
  * GeneralInformationPageView ===> custom view component

**/
import React from "react";
import { GeneralInformationPageView } from "./general-information-page-view";

//GeneralInformationPagePlantControlle start
export const GeneralInformationPageController = (props) => {
  return (
    <>
      {/* View component call */}
      <GeneralInformationPageView {...props} />  
    </>
  );
};
