/** imported components

  * ./svg => imported for all svg elements  
  * ./styles => imported for using of all created styled components.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Modal for ===> This is popup component
  * AssessmentView, ChapterView, QuestionView for ===> It is view component


**/
import React, { useState } from "react";
import { Redirect, Link, useParams, useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { Helmet } from "react-helmet";
import {
  CarouselProvider,
  Dot,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { AssessmentView } from './assessment-view.js';
import { HomeTourControler } from '../onboarding-home-tour/home-tour-ctrl';
import { QuestionView } from './questions-view.js'
import "./assessment-walkthrough.scss";

export const OnboardingAssessmentTour = (props) => {
  //defining variables here
  const user = JSON.parse(localStorage.getItem("userData"));
  const name = user ? user.name : "";
  const { section } = useParams();
  const firstSlideData = [
    {
      img: "home-step1.gif",
      title: `Hi ${name}, welcome to the Smart Manufacturing Assessment tour!`,
      paragraph: [`The Smart Manufacturing  Technology Assessment is assesing in detail the technological adoption of your IR4.0 systems, tools and work processes across all the plant operational areas.`],
    },
    {
      img: "assess-walk-2.gif",
      title: `Invite and collaborate with your colleagues `,
      paragraph: [`You can self-assess your plant's IR4.0 technological readiness or you can invite all factory colleagues and external partners to provide their input to specific assessment sections.`,],
    },
    {
      img: "assess-walk-4.gif",
      title: `How it works`,
      paragraph: [`Each operational section is being segmented to sub-sections to enable a more details and accurate assessment of digital and automation technologies. `],
    },
    {
      img: "home-step3.svg",
      title: `Complete the smart manufacturing assessment and get access to:`,
      paragraph: [`<b>1. EDUCATE:</b> Free Industry 4.0 skills assessment and access to purchase Industry 4.0 courses certified from the <b>United Nations</b>`,
        `<b>2. PREPARE:</b> Learn about IR4.0 Loss analysis and assess your plant's financial value potential with IR4.0 solutions`,
        `<b>3. DEFINE:</b> Learn about IR4.0 financial business case and define your plant's IR4.0 roadmap strategy and business case`,],
    },

  ];
  const { isOnboarding } = props;
  const TotalFirstSlides = firstSlideData.length;
  const [fistSlideIndex, setFistSlideIndex] = useState(0);
  // function to handle first slide
  const HandleFirstSlide = (ev) => {
    if (ev === "prev" && fistSlideIndex > 0) {
      let tmpVal = fistSlideIndex - 1;
      setFistSlideIndex(tmpVal);
    } else if (ev === "next" && fistSlideIndex < TotalFirstSlides - 1) {
      let tmpVal = fistSlideIndex + 1;
      setFistSlideIndex(tmpVal);
    } else if (ev !== "next" && ev !== "prev") {
      setFistSlideIndex(ev);
    }
  };
  return (
    <>
      <Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1"/>
			</Helmet>
      {fistSlideIndex < 2 ? <AssessmentView />
        : fistSlideIndex == 2 ?
          <QuestionView />
          :
          <HomeTourControler {...props} />
      }
      <Modal open={true} className={`onboarding-modal bg-op-white`}>
        <>
          <div className="first-type-modal">
            {firstSlideData.map((item, index) => (
              /* card has been divided into
                button
                header-1
                content-1
                footer-1
              */
              <div className="card-1" key={index} style={{ display: index !== fistSlideIndex ? "none" : "" }}>
                {/* close button */}

                {/* this section has image */}
                <div className="header-1">
                  <div className="div-img">
                    <img src={require(`../../assets/img/platform-onboarding-tour/${item.img}`).default} alt="true" />
                  </div>
                </div>
                {/* header-1 end */}
                {/*	this section has 
               title
               paragraph 
             */}
                <div className="content-1">
                  <h2 className="title-h1">{item.title}</h2>
                  {item.paragraph.map(p => (<p dangerouslySetInnerHTML={{ __html: p }}></p>))}
                </div>
                {/* content-1 end */}
                {/* footer has
               dots section to navigate
               previous button
               next button
             */}
                <div className="footer-1">
                  <div className="dots-sec">
                    {firstSlideData.map((item, ind) => <button key={ind} onClick={() => HandleFirstSlide(ind)} disabled={fistSlideIndex === ind ? true : false}></button>)}
                  </div>
                  {fistSlideIndex === 0 && <Link to={`/assessment/${section} `} className="btn-blue-link">Skip</Link>}
                  {fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides - 1 && (
                    <button onClick={() => HandleFirstSlide("prev")} className="btn-blue-link">Previous</button>
                  )}
                  {fistSlideIndex === TotalFirstSlides - 1 && <button onClick={() => HandleFirstSlide(0)} className="btn-blue-link">Restart</button>}
                  {fistSlideIndex === 0 && (
                    <button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
                      Take tour
                    </button>
                  )}
                  {fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides - 1 && (
                    <button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
                      Next
                    </button>
                  )}
                  {fistSlideIndex === TotalFirstSlides - 1 && (
                    <Link to={`/assessment/${section} `} className="btn-blue">
                      Exit
                    </Link>
                  )}
                </div>
                {/* footer-1 end */}
              </div>
            ))}
          </div>
          <div className="assessment-onboarding-tour">
            <div className="footer-area">
              <div className="footer-left">
                <ul className="footer-page-link">
                  <li className="page-line-active">Assessment</li>
                  <li></li>
                </ul>
              </div>
              <div className="footer-right">
                <ul className="footer-page-link">
                  <li>
                    <a className="last_t_link" href={`/assessment/${section}`}>Exit this demo tour</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
