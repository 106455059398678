import React, { useEffect, useState } from 'react';
import { NewSignUpView } from './NewSignUpView';
import serviceProvider from '../../services/axios'

export const NewSignUpCtrl = (props) => {
    const [results, setResults] = useState([]);
    
    useEffect(() => {
		const search = async () => {
			const apiUrl = `/user?param=countrylist`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResults(data);
		};
		search();
	}, []);

    if (results.length) {
       
                return (
                    <NewSignUpView countryList={results}  {...props}/>
                )
    }else{
        return (
            <>
                <div></div>
            </>
        );

    }
}

