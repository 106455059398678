import React, { useState, useEffect,useMemo } from 'react';
import { Modal, Collapse, TextField } from '@material-ui/core'
import { Helmet } from 'react-helmet';
import serviceProvider from '../../services/axios'
import { apiEndpoints } from '../../services/api-endpoints'
import {backIcon,hideEye,showEye } from './svg';
import {
    CreateAccountWrapper,
    ViewAccountWrapper,
} from './assessor-styled.js';



export const CreateAccountPopup = (props) => {
    const {openPop,setOpenPop, handleClose, setPopen} = props;
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [viewPassword, setViewPassword] = useState('');
    const [accountSuccess, setAccountSuccess] = useState(false);
    const [createAccount, setCreateAccount] = useState(true);
    const [viewAccountDetails, setViewAccountDetails] = useState(false);


    const formData = ({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        invited_by_siri: props.pid,
    });
    const [errors1, setErrors1] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const validateForm1 = () => {
        let errors = {};
        var passw = /^[A-Za-z]\w{7,14}$/;
        // Validate name field 
        if (!firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!lastName) {
            errors.lastName = 'Last Name is required.';
        }
        // Validate email field 
        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid.';
        }
        // Validate password field 
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 8) {
            errors.password = 'Password must be at least 8 characters.';
        } else if (password.match(passw)) {
            errors.password = 'Must contain at one uppercase, one number and one special character.';
        }
        if (!confirmPassword) {
            errors.confirmPassword = 'Confirm Password is required.';
        } else if (confirmPassword.length < 8) {
            errors.confirmPassword = 'Confirm Password must be at least 8 characters.';
        } else if (confirmPassword.match(passw)) {
            errors.confirmPassword = 'Must contain at one uppercase, one number and one special character.';
        } else if (confirmPassword != password) {
            errors.confirmPassword = 'Passwords must match.';
        }

        // Set the errors and update form validity 
        setErrors1(errors);
    };
    const checkValue = () => {
        setErrors1({
            firstName: '',
            lastName: '',
            email: '',
            job: '',
            password: '',
            confirmPassword: ''
        });
    }

    const handleBack =()=>{
        setOpenPop(false);
        props.setPopen(!props.popen)
        props.setUpDataPage(!props.setuppage);
    }
    const handleAccount=()=>{
        
        
            serviceProvider
                .post("/user?type=createassessoraccount", JSON.stringify(formData))
                .then((response) => {
                    if (response.data.status === 200) {
                       
                        setCreateAccount(false);
                        setAccountSuccess(true);
                        setTimeout(() => { 
                            setAccountSuccess(false);
                        }, 1500);
                        setViewAccountDetails(true);
                       
                    }
                    if (response.data.status === "failed") {
                        let errors = {};
                        setErrors1({
                            firstName: '',
                            lastName: '',
                            email: 'Email already exists',
                            job: '',
                            password: '',
                            confirmPassword: ''
                        });
                      
    
                    }
                });
        
    }
    const isActiveBtn = useMemo(()=>{
        let matchEmail = /\S+@\S+\.\S+/.test(email);
        let condition = (password==confirmPassword && firstName && lastName && matchEmail && password && confirmPassword);
        console.log(matchEmail);
        return condition;
    },[firstName,lastName,email,password,confirmPassword]);
    
    const handlePopClose = ()=>{
        setCreateAccount(false);
        handleClose();
    }

    return(
        <>
        {openPop &&
            <CreateAccountWrapper data-component="CreateAccountWrapper">
                <div className="ms-assign-section-card">
                    <div className="ms-assign-header">
                        <div className="text-wrap">
                            <div className='left-wrap'>
                                <button className='back-btn' onClick={()=>handleBack()}>
                                    <i dangerouslySetInnerHTML={{__html:backIcon}}></i>
                                    <span>Back</span>
                                </button>
                                <span>Create new account & assign</span>
                            </div>
                            <button className="close-btn" onClick={()=>handlePopClose()}>
                                <img src={require('../../assets/img/maturity-dashboard/cancel-dark.svg').default} alt="X" />
                            </button>
                        </div>
                    </div>
                    <div className="ms-assign-content">
                        {createAccount &&
                            <div className="create-account-wrapper">
                                <div className="create-account-form">
                                    <div className='signup_form_col'>
                                        <label className='field_wrapper'>
                                            <span className='label'>First Name</span>
                                            <input
                                                type='text'
                                                name='firstName'
                                                className='input_field'
                                                placeholder='First Name'
                                                onChange={(e) => setfirstName(e.target.value)}
                                                onKeyUp={checkValue}
                                                maxlength="50"
                                            />
                                        </label>
                                        <label class="error">{errors1.firstName}</label>
                                    </div>
                                    <div className='signup_form_col'>
                                        <label className='field_wrapper'>
                                            <span className='label'>Last Name</span>
                                            <input
                                                type='text'
                                                name='lastName'
                                                className='input_field'
                                                placeholder='last Name'
                                                maxlength="50"
                                                onChange={(e) => setlastName(e.target.value)}
                                                onKeyUp={checkValue}
                                            />
                                        </label>
                                        <label class="error">{errors1.lastName}</label>
                                    </div>
                                    <div className='signup_form_col'>
                                        <label className='field_wrapper'>
                                            <span className='label'>Email</span>
                                            <input
                                                type='email'
                                                name='email'
                                                className='input_field'
                                                placeholder='Email Address'
                                                onChange={(e) => setemail(e.target.value)}
                                                maxlength="150"
                                                onKeyUp={checkValue}
                                            />
                                            <label class="error">{errors1.email}</label>
                                        </label>
                                    </div>
                                    <div className='signup_form_col'>
                                        <label className='field_wrapper set-psw'>
                                            <span className='label'>Password</span>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                name='password'
                                                className='input_field pr_30'
                                                placeholder='********'
                                                onChange={(e) => setpassword(e.target.value)}
                                                max-maxLength="100"
                                                onKeyUp={checkValue}
                                            />
                                            <i
                                                dangerouslySetInnerHTML={{ __html: !showPassword ? hideEye : showEye }} onClick={() => setShowPassword(!showPassword)}
                                            />
                                        </label>
                                        <label class="error">{errors1.password}</label>
                                    </div>
                                    <div className='signup_form_col'>
                                        <label className='field_wrapper'>
                                            <input
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                name='confirmPassword'
                                                className='input_field pr_30'
                                                onChange={(e) => setconfirmPassword(e.target.value)}
                                                placeholder='********'
                                                onKeyUp={checkValue}

                                            />
                                            <i dangerouslySetInnerHTML={{ __html: !showConfirmPassword ? hideEye : showEye }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}/>
                                        </label>
                                        <label class="error">{errors1.confirmPassword}</label>
                                    </div>
                                </div>
                                <div className="create-btn-col">
                                    <button className={`create-btn ${isActiveBtn?'active':'disabled'}`} onClick={()=>{validateForm1();handleAccount();}}>Create Account & Assign</button>
                                </div>
                            </div>
                        }
                        {accountSuccess ?
                            <div className='account-success-popup'>
                                <div className='success-img'>
                                    <img src={require('../../assets/img/create-account/create-account.gif').default} />
                                </div>
                                <p>Assigned successfully!</p>
                            </div>
                            :
                            viewAccountDetails &&
                                <ViewAccountWrapper data-component="ViewAccountWrapper">
                                     <p className="note">The account has been created successfully. An invite email with credentials has been sent to you and your colleagues account.</p>
                                     <div className="detail-wrap">
                                         <div className="detail-row">
                                             <span className="label">First Name:</span>
                                             <span className="text">{firstName}</span>
                                         </div>
                                         <div className="detail-row">
                                             <span className="label">Last Name:</span>
                                             <span className="text">{lastName}</span>
                                         </div>
                                         <div className="detail-row">
                                             <span className="label">Email ID:</span>
                                             <span className="text">{email}</span>
                                         </div>
                                         <div className="detail-row">
                                             <span className="label">Password:</span>
                                             <div className='text'>
                                                <div className="psw-field">
                                                    <span className='pasw'>
                                                        {!viewPassword ?'*********':password}
                                                    </span>
                                                    <i
                                                        dangerouslySetInnerHTML={{ __html: !viewPassword ? hideEye : showEye }} onClick={() => setViewPassword(!viewPassword)}
                                                    />
                                                </div>
                                             </div>
                                         </div>
                                     </div>
                                </ViewAccountWrapper>
                            
                        }
                        

                    </div>
                </div> 
            </CreateAccountWrapper>           
        }
        </>
    )
}