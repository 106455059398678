export const arrowIcon =`<svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 7.00001L0.333336 13.6667L0.333335 0.33334L7 7.00001Z" fill="#7D868C"/>
</svg>
`
export const backArrow=`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2246 18.5564C14.9889 18.7992 14.5992 18.7992 14.3635 18.5564L8.40559 12.4179C8.17966 12.1851 8.17966 11.8149 8.40559 11.5821L14.3635 5.4436C14.5992 5.20078 14.9889 5.20078 15.2246 5.4436L15.5944 5.82462C15.8203 6.0574 15.8203 6.4276 15.5944 6.66038L10.8175 11.5821C10.5915 11.8149 10.5915 12.1851 10.8175 12.4179L15.5944 17.3396C15.8203 17.5724 15.8203 17.9426 15.5944 18.1754L15.2246 18.5564Z" fill="#0D2C54"/>
</svg>
`
export const InfoIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<circle cx="8" cy="8" r="7.6" stroke="#A6A7AD" class="circle" stroke-width="0.8"/>
<line x1="8" y1="6.5" x2="8" y2="11.5" stroke="#7D868C" stroke-linecap="round" class="line-i"/>
<path d="M8 4V4.5" stroke="#7D868C" stroke-linecap="round" class="line-i"/>
</svg>
`;