import styled from "styled-components";

export const ModalsWraper = styled.div`
 >div[aria-hidden="true"]{
    background-color: rgba(125, 134, 140, 0.30) !important;
    backdrop-filter: blur(2px);
  }
  .cart__page__wrapper{
    display:block !important;
  }
  .cart__page__wrapper{
    background: #fff;
  }
`

export const ManageSubscriptionWrapper = styled.div`
     width:100%;
     height:100%;
     display:flex;
     padding:1.5rem;
     justify-content:center;
     align-items:center;
     overflow:auto;
     @media(max-width:768px){
        display:block;
    }
`;
export const ManageSubscriptionCard = styled.div`
    border-radius: 2.4rem;
    border: 0.1rem solid #CCD6E8;
    background:#FFF;
    width:68rem;
    min-height:49.9rem;
    display:flex;
    flex-direction:column;
    color:#2C55A0;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 100%; 
    @media(max-width:768px){
        width:100%;
    }
`;
export const ManageSubscriptionCardHeader = styled.div`
   border-bottom:0.1rem solid #CCD6E8;
   padding:1.9rem 4rem 1.9rem 2.4rem;
   display:flex;
   justify-content:space-between;
   align-items:center;
   svg{
        width:3.2rem;
   }
`;
export const ManageSubscriptionCardBody = styled.div`
   padding:4rem 4rem 1rem 4rem;
   flex:1;
   overflow:auto;
   position:relative;
   .success-img-div{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:2.4rem;
        color:#4D4D4F;
        font-size:2.8rem;
        img{
            width:9.3rem;
            min-height:9.3rem;
        }

   }
   .ManageSubscriptionCardFooter{
        display:flex;
        gap:2rem;
        justify-content:flex-end;
        align-items:center;
        padding:0rem 4rem 2rem 4rem;
    };
    .LightBgBtn{
        color:#118ACB;
        font-size: 1.4rem;
        font-weight: 600;
        padding:2.1rem;
        background:#F4F6FC;
        border: 0.1rem solid #129AD5;
        border-radius:0.4rem;
        min-width:9.7rem;
        justify-content:center;
        display:inline-flex;
    }
    .BlueBgBtn{
        color:#fff;
        font-size: 1.4rem;
        font-weight: 600;
        padding:2.1rem;
        background:#129AD5;
        border: 0.1rem solid #129AD5;
        border-radius:0.4rem;
        &:disabled{
            cursor:not-allowed;
            opacity:0.6;
        }
    }
`;

export const InputWrapper = styled.label`
    display:block;
    width:100%;
    position:relative;
    margin-bottom:2.4rem;
    span{
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: -0.273px;
        color:#4D4D4F;
        position:absolute;
        top:0;
        transform:translateY(-50%);
        padding:0.3rem 0.6rem;
        background:#fff;
        left:1.1rem;
    }
    .btn__ddl__arrow{
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        right:2rem;
    }
    input{
        width:100%;
        height:5rem;
        border-radius: 0.5rem;
        border: 0.1rem solid #E9E9E9;
        padding:0.5rem 1.7rem;
        color: #7D868C;
        font-size: 1.5rem;
        font-weight: 400;
        outline:0;
        background:#fff;
        text-align:left;
        &[type="button"]{
            color: #7D868C;
            cursor: pointer;
        }
        &::placeholder{
            color: #7D868C;
            font-weight: 400;
            padding-right:2.5rem;
        }
    }
    .dropdown_list{
        display:block;
        position: absolute;
        top:-1rem;
        transform:translateY(-100%);
        width:100%;
        opacity:0;
        visibility:hidden;
        &.active{
            opacity:1;
            visibility:visible;
        }
        .dropdown__list__card{
            width: 100%;
            max-height: 15rem;
            min-height: 10rem;
            background: #fff;
            border: 0.1rem solid #e9e9e9;
            border-radius: 0.5rem;
            padding: 0 1.6rem;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .dropdown__list__card_header{
            position:relative;
            .search-icon{
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                left:0;
                pointer-events:none;
            }
        }

        .search-input{
            border:0;
            border-bottom:0.1rem solid #e9e9e9;
            height:4rem;
            border-radius:0;
            padding-left:2.5rem;
        }
        ul{
            li{
                background: #fff;
                border-radius: 0.5rem;
                padding: 0.5rem 1rem;
                font-size: 1.5rem;
                line-height: 1.8rem;
                color: #4d4d4f;
                transition: 0.25s ease-in-out;
                cursor: pointer;
                text-transform: capitalize;
                font-weight:400;
                &:hover{
                    background: #f0f0f0;
                    transition: 0.25s ease-in-out;
                }
            }
        }
    }
`;

export const InputGroups = styled.div`
        display:flex;
        gap:2rem;
       @media(max-width:768px){
        flex-wrap:wrap;
        gap:0rem;
       }
        ${InputWrapper}:{
                    width:calc(50% - 1rem);
                    @media(max-width:575px){
                        width:100%;
                    }
        }
`;


