/*
# serviceProvider ===> for api call
# pure-react-carousel==> plugin for carousel 
# ./walk-through.scss for this page css
*/

import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import Modal from "@material-ui/core/Modal";
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext, ButtonFirst } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./walk-through.scss";

// WalkthroughView component start
export const WalkthroughView = (props) => {
	// defining variable
	const user = JSON.parse(localStorage.getItem("userData"));
	const uname = user ? user.name : "";
	const usid = user ? user.uid : 0;
	useEffect(() => {
		serviceProvider.post("/home?type=settraining", JSON.stringify({ uid: usid }), true).then((response) => {
			
		});
	}, []);

	const [isSliderIndex, setIsSliderIndex] = useState(0);
	const handleIndex = (e) => {
		if (e === "add" && isSliderIndex < 4) {
			let tmp = 1 + isSliderIndex;
			setIsSliderIndex(tmp);
			// console.log(isSliderIndex);
		}
		if (e === "subs" && isSliderIndex > 0) {
			let tmp = isSliderIndex - 1;
			setIsSliderIndex(tmp);
			// console.log(isSliderIndex);
		}
		if (e === "reset" && isSliderIndex > 0) {
			let tmp = 0;
			setIsSliderIndex(0);
			// console.log(isSliderIndex);
		}
	};
	return (
		<>
			{/* walkthrought modal started*/}
			<Modal
				open={props.open}
				// onClose={props.handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="walk__through__modal"
			>
				{/*slider wrapper stared*/}
				<div className="modal__slider__wrapper">
					{/*CarouselProvider started*/}
					<CarouselProvider naturalSlideWidth={100} naturalSlideHeight={90} totalSlides={5} dragEnabled={false} isPlaying={false}>
						<Slider>
							{/*Slide 1  started*/}
							<Slide className="slide" index={0}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/common-img/slider-intro-course.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">1 of 5</h4>
										<h2 className="title">Free Intro Course</h2>
										<p>Hi {uname}, welcome to the Training tour!</p>
										<p>
											Learn the SmarterChains way! We want to quickly show you around and it will only take a minute. Get
											started today with our Free Introductory Course. You can skip at any time.
										</p>
									</div>
								</div>
							</Slide>
							{/*
								#Slide 1  ended
								#Slide 2  started
							*/}
							<Slide className="slide" index={1}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/common-img/scale-on-demand.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">2 of 5</h4>
										<h2 className="title">Scale On-Demand</h2>
										<p>
											Gain 360° view of Industry 4.0 and advance your technology specific understanding​ with additional
											trainings. Watch each introduction for Free.
										</p>
									</div>
								</div>
							</Slide>
							{/*
								#Slide 2  ended
								#Slide 3  started
							*/}
							<Slide className="slide" index={2}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/common-img/qna.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">3 of 5</h4>
										<h2 className="title">Q&A</h2>
										<p>
											Ask questions, network with peers and let our in-house experts and the community interact with you. Stay
											connected and come back to participate in the Q&A community and join the discussion.
										</p>
									</div>
								</div>
							</Slide>
							{/*
								#Slide 3  ended
								#Slide 4  started
							*/}
							<Slide className="slide" index={3}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/common-img/integrated-quiz.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">4 of 5</h4>
										<h2 className="title">Integrated Quizzes</h2>
										<p>
											Get practical with our integrated quizzes that foster long-term knowledge retention. We gamified the
											experience so that you don’t only answer questions, but you have fun too.
										</p>
									</div>
								</div>
							</Slide>
							{/*
								#Slide 4  ended
								#Slide 5  started
							*/}
							<Slide className="slide" index={4}>
								<div className="slide__item">
									<header className="slide__item__head">
										<img src={require("../../assets/img/common-img/congrats-completed.gif").default} alt="true" />
									</header>
									<div className="slide__item__body">
										<h4 className="label">5 of 5</h4>
										<h2 className="title">Get Certified</h2>
										<p>
											Receive a digital certification following the successful completion of each Training Course and showcase
											your Industry 4.0 readiness.
										</p>
									</div>
								</div>
							</Slide>
							{/*Slide 5  ended*/}
						</Slider>
						{/*slider footer div start*/}
						<div className="modal__slider__footer">
							<div className="dots__section">
								<Dot slide={0} className="dot"></Dot>
								<Dot slide={1} className="dot"></Dot>
								<Dot slide={2} className="dot"></Dot>
								<Dot slide={3} className="dot"></Dot>
								<Dot slide={4} className="dot"></Dot>
							</div>

							{isSliderIndex < 4 ? (
								<div className="btn__section">
									{isSliderIndex === 0 ? (
										<button className="btn__prv" onClick={props.handleModalClose}>
											Skip
										</button>
									) : (
										<ButtonBack className="btn__prv" onClick={() => handleIndex("subs")}>
											Previous
										</ButtonBack>
									)}
									<ButtonNext className="btn__nxt" onClick={() => handleIndex("add")}>
										Next
									</ButtonNext>
								</div>
							) : (
								<div className="btn__section">
									<ButtonFirst className="btn__prv" onClick={() => handleIndex("reset")}>
										Restart
									</ButtonFirst>
									<button className="btn__nxt" onClick={props.handleModalClose}>
										Exit
									</button>
								</div>
							)}
						</div>
					{/*slider footer div ended*/}
					</CarouselProvider>
					{/*CarouselProvider ended*/}
				</div>
				{/*slider wrapper ended*/}
			</Modal>
			{/* walkthrought modal ended*/}
		</>
	);
};
