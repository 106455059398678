export const crossIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.34353 6.50028C3.90961 6.06636 3.90961 5.36285 4.34353 4.92893C4.77744 4.49502 5.48096 4.49502 5.91488 4.92893L18.4857 17.4997C18.9196 17.9336 18.9196 18.6372 18.4857 19.0711C18.0517 19.505 17.3482 19.505 16.9143 19.0711L4.34353 6.50028Z" fill="#7D868C"/>
<path d="M4.34353 19.0711C3.90961 18.6372 3.90961 17.9336 4.34353 17.4997L16.9143 4.92893C17.3482 4.49502 18.0517 4.49502 18.4857 4.92893C18.9196 5.36285 18.9196 6.06637 18.4857 6.50028L5.91488 19.0711C5.48096 19.505 4.77744 19.505 4.34353 19.0711Z" fill="#7D868C"/>
</svg>
`
export const arrowLeft = `<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 15L0.999999 8L8 1" stroke="#7D868C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
export const arrowRight = `<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L8 8L1 15" stroke="#7D868C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>

`

// modal svg 

export const closeIcon = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="2" fill="#F0F0F0"/>
<path d="M11.1 22.3C10.7134 22.6866 10.0866 22.6866 9.7 22.3C9.3134 21.9134 9.3134 21.2866 9.7 20.9L13.8929 16.7071C14.2834 16.3166 14.2834 15.6834 13.8929 15.2929L9.7 11.1C9.3134 10.7134 9.3134 10.0866 9.7 9.7C10.0866 9.3134 10.7134 9.3134 11.1 9.7L15.2929 13.8929C15.6834 14.2834 16.3166 14.2834 16.7071 13.8929L20.9 9.7C21.2866 9.3134 21.9134 9.3134 22.3 9.7C22.6866 10.0866 22.6866 10.7134 22.3 11.1L18.1071 15.2929C17.7166 15.6834 17.7166 16.3166 18.1071 16.7071L22.3 20.9C22.6866 21.2866 22.6866 21.9134 22.3 22.3C21.9134 22.6866 21.2866 22.6866 20.9 22.3L16.7071 18.1071C16.3166 17.7166 15.6834 17.7166 15.2929 18.1071L11.1 22.3Z" fill="#0D2C54"/>
</svg>
`

export const industryIcon=`<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.2" y="0.2" width="31.6" height="31.6" rx="3.8" fill="#E5F0FF" stroke="#33A4FF" stroke-width="0.4"/>
<path d="M19.1357 11.1914C18.6685 11.1965 18.2219 11.3819 17.8916 11.7078C17.5612 12.0338 17.3733 12.4744 17.3682 12.9354C17.3673 13.1646 17.4124 13.3918 17.5009 13.6037C17.5895 13.8157 17.7196 14.0083 17.8839 14.1704C18.0482 14.3325 18.2434 14.461 18.4582 14.5483C18.6731 14.6356 18.9033 14.6801 19.1357 14.6793C19.6028 14.6742 20.0494 14.4888 20.3798 14.1629C20.7101 13.8369 20.898 13.3963 20.9032 12.9354C20.904 12.7061 20.8589 12.4789 20.7704 12.267C20.6819 12.055 20.5517 11.8624 20.3874 11.7003C20.2231 11.5382 20.0279 11.4098 19.8131 11.3224C19.5983 11.2351 19.368 11.1906 19.1357 11.1914Z" fill="#33A4FF"/>
<path d="M24.319 12.7045L23.6571 12.0514C23.3913 11.8271 23.108 11.624 22.8097 11.4439C21.7359 10.7552 20.4826 10.3887 19.2019 10.3887C17.9212 10.3887 16.6679 10.7552 15.5941 11.4439C15.2958 11.624 15.0125 11.8271 14.7468 12.0514C14.5096 12.2521 14.2883 12.4705 14.0848 12.7045C14.0271 12.7671 13.9951 12.8486 13.9951 12.9331C13.9951 13.0177 14.0271 13.0992 14.0848 13.1617L14.7468 13.8149C14.9986 14.0389 15.2687 14.2421 15.5544 14.4223C15.5649 14.4223 15.575 14.4265 15.5825 14.4338C15.5899 14.4412 15.5941 14.4511 15.5941 14.4615C16.6648 15.1572 17.9199 15.5253 19.2019 15.5196C20.4829 15.5188 21.7362 15.1512 22.8097 14.4615C22.8202 14.4615 22.8303 14.4574 22.8378 14.45C22.8452 14.4427 22.8494 14.4327 22.8494 14.4223C23.1208 14.2329 23.3856 14.0435 23.6571 13.8149C23.8942 13.6141 24.1156 13.3958 24.319 13.1617C24.3579 13.0916 24.3782 13.013 24.3782 12.9331C24.3782 12.8533 24.3579 12.7746 24.319 12.7045ZM23.1672 13.312C22.9355 13.5014 22.6707 13.6908 22.3993 13.8802C22.3888 13.8802 22.3787 13.8843 22.3712 13.8917C22.3638 13.899 22.3596 13.909 22.3596 13.9194C21.4039 14.5435 20.2817 14.8732 19.1357 14.8665C17.9906 14.8666 16.8703 14.5375 15.9118 13.9194C15.9013 13.9194 15.8912 13.9153 15.8838 13.9079C15.8763 13.9006 15.8721 13.8906 15.8721 13.8802C15.5988 13.7148 15.3417 13.5245 15.1042 13.312C14.9463 13.2052 14.8038 13.0777 14.6806 12.9331C14.8132 12.7978 14.9547 12.6713 15.1042 12.5543C15.3359 12.3649 15.6073 12.1755 15.8721 11.9861C16.8222 11.3432 17.9473 10.9992 19.0993 10.9992C20.2513 10.9992 21.3764 11.3432 22.3265 11.9861C22.5998 12.1515 22.8569 12.3417 23.0944 12.5543C23.2516 12.6595 23.3922 12.7872 23.5114 12.9331C23.4285 13.0809 23.3104 13.2064 23.1672 13.2989V13.312Z" fill="#33A4FF"/>
<path d="M15.3362 10.9279C15.5191 10.7984 15.7116 10.6827 15.9121 10.5817C16.9055 10.0184 18.03 9.71905 19.1757 9.71303C20.3222 9.71389 21.448 10.0136 22.4393 10.5817L23.0152 10.9279C23.0759 10.9598 23.1404 10.984 23.2072 10.9998C23.262 11.0011 23.3161 10.9879 23.3638 10.9614C23.4116 10.935 23.4513 10.8963 23.4786 10.8495C23.5049 10.8147 23.5238 10.775 23.534 10.7329C23.5443 10.6907 23.5457 10.6469 23.5383 10.6041C23.5309 10.5613 23.5147 10.5205 23.4908 10.4841C23.4669 10.4477 23.4357 10.4165 23.3992 10.3923C23.2016 10.2544 22.996 10.1279 22.7835 10.0135C21.6807 9.40359 20.4399 9.07787 19.1757 9.06641C17.9011 9.06624 16.6493 9.39981 15.5481 10.0331C15.3346 10.1335 15.1288 10.2492 14.9324 10.3792C14.8961 10.4028 14.865 10.4334 14.8412 10.4692C14.8173 10.5051 14.8012 10.5454 14.7938 10.5876C14.7863 10.6298 14.7877 10.6731 14.7979 10.7148C14.8081 10.7565 14.8269 10.7957 14.853 10.8299C14.8728 10.8732 14.9027 10.9113 14.9403 10.941C14.9779 10.9706 15.0221 10.9911 15.0693 11.0007C15.1164 11.0102 15.1652 11.0086 15.2116 10.996C15.258 10.9833 15.3007 10.96 15.3362 10.9279Z" fill="#33A4FF"/>
<path d="M7.42536 22.4033H9.23257C9.32386 22.4033 9.41142 22.3675 9.47598 22.3038C9.54054 22.2401 9.57681 22.1538 9.57681 22.0637V16.4204C9.57769 16.3752 9.56943 16.3304 9.55253 16.2884C9.53562 16.2465 9.5104 16.2082 9.47834 16.176C9.44629 16.1438 9.40806 16.1182 9.36586 16.1007C9.32366 16.0832 9.27833 16.0742 9.23257 16.0742H7.42536C7.3796 16.0742 7.33427 16.0832 7.29207 16.1007C7.24987 16.1182 7.21164 16.1438 7.17958 16.176C7.14753 16.2082 7.12231 16.2465 7.1054 16.2884C7.0885 16.3304 7.08024 16.3752 7.08112 16.4204V22.0637C7.08112 22.1538 7.11739 22.2401 7.18195 22.3038C7.2465 22.3675 7.33407 22.4033 7.42536 22.4033Z" fill="#33A4FF"/>
<path d="M10.252 12.9803V22.0658C10.252 22.1558 10.2882 22.2422 10.3528 22.3059C10.4173 22.3696 10.5049 22.4054 10.5962 22.4054H12.3968C12.4425 22.4063 12.488 22.3981 12.5306 22.3814C12.5731 22.3648 12.6118 22.3399 12.6445 22.3083C12.6771 22.2766 12.7031 22.2389 12.7208 22.1973C12.7385 22.1556 12.7476 22.1109 12.7476 22.0658V12.9803C12.7476 12.9352 12.7385 12.8905 12.7208 12.8488C12.7031 12.8072 12.6771 12.7695 12.6445 12.7378C12.6118 12.7062 12.5731 12.6813 12.5306 12.6646C12.488 12.648 12.4425 12.6398 12.3968 12.6407H10.5962C10.5049 12.6407 10.4173 12.6765 10.3528 12.7402C10.2882 12.8039 10.252 12.8903 10.252 12.9803Z" fill="#33A4FF"/>
<path d="M13.4365 16.2635V22.0635C13.4365 22.1536 13.4728 22.24 13.5374 22.3037C13.6019 22.3674 13.6895 22.4032 13.7807 22.4032H15.588C15.6793 22.4032 15.7668 22.3674 15.8314 22.3037C15.8959 22.24 15.9322 22.1536 15.9322 22.0635V16.2635C15.9322 16.1734 15.8959 16.087 15.8314 16.0233C15.7668 15.9596 15.6793 15.9238 15.588 15.9238H13.7807C13.6937 15.9358 13.613 15.9754 13.5509 16.0367C13.4888 16.098 13.4486 16.1776 13.4365 16.2635Z" fill="#33A4FF"/>
<path d="M16.667 18.2225V22.063C16.667 22.1531 16.7033 22.2395 16.7678 22.3032C16.8324 22.3669 16.9199 22.4027 17.0112 22.4027H18.8184C18.9097 22.4027 18.9973 22.3669 19.0619 22.3032C19.1264 22.2395 19.1627 22.1531 19.1627 22.063V18.2225C19.1627 18.1324 19.1264 18.046 19.0619 17.9823C18.9973 17.9186 18.9097 17.8828 18.8184 17.8828H17.0112C16.9242 17.8948 16.8435 17.9344 16.7814 17.9957C16.7193 18.057 16.6791 18.1366 16.667 18.2225Z" fill="#33A4FF"/>
</svg>
`

export const lossIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<rect x="0.2" y="0.2" width="31.6" height="31.6" rx="3.8" fill="#E5F0FF" stroke="#33A4FF" stroke-width="0.4"/>
<path d="M12.1079 18.7972H13.6764C13.8333 18.7972 13.964 18.6683 13.964 18.5135V13.5611C13.964 13.4063 13.8333 13.2773 13.6764 13.2773H12.1079C11.951 13.2773 11.8203 13.4063 11.8203 13.5611V18.5135C11.8203 18.6683 11.951 18.7972 12.1079 18.7972Z" fill="#33A4FF"/>
<path d="M14.5654 12.2701V18.5122C14.5654 18.667 14.6961 18.7959 14.853 18.7959H16.4216C16.5784 18.7959 16.7091 18.667 16.7091 18.5122V12.2701C16.7091 12.1153 16.5784 11.9863 16.4216 11.9863H14.853C14.6961 11.9863 14.5654 12.1153 14.5654 12.2701Z" fill="#33A4FF"/>
<path d="M17.127 13.4322V18.5136C17.127 18.6683 17.2577 18.7973 17.4145 18.7973H18.9831C19.1399 18.7973 19.2706 18.6683 19.2706 18.5136V13.4322C19.2706 13.2774 19.1399 13.1484 18.9831 13.1484H17.4145C17.2577 13.1484 17.127 13.2774 17.127 13.4322Z" fill="#33A4FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.645 22.2908C19.4104 22.2908 22.4628 19.2405 22.4628 15.4778C22.4628 11.715 19.4104 8.66469 15.645 8.66469C11.8797 8.66469 8.82726 11.715 8.82726 15.4778C8.82726 19.2405 11.8797 22.2908 15.645 22.2908ZM15.645 22.9555C19.7777 22.9555 23.1279 19.6076 23.1279 15.4778C23.1279 11.3479 19.7777 8 15.645 8C11.5123 8 8.16211 11.3479 8.16211 15.4778C8.16211 19.6076 11.5123 22.9555 15.645 22.9555Z" fill="#33A4FF"/>
<rect width="0.690863" height="4.42463" rx="0.345432" transform="matrix(0.707351 -0.706863 0.707351 0.706863 20.1348 20.8711)" fill="#33A4FF"/>
</svg>`

export const strategiesIcon =`<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<rect x="0.2" y="0.2" width="31.6" height="31.6" rx="3.8" fill="#F9F0ED" stroke="#FDA982" stroke-width="0.4"/>
<path d="M22.4427 17.7337C22.6491 17.7356 22.852 17.6818 23.0283 17.5784C23.2047 17.4751 23.3474 17.3263 23.4405 17.1488C24.6119 15.1854 26.0001 12.6371 26.0001 11.4256C26.0001 10.5171 25.6254 9.64576 24.9582 9.00334C24.2911 8.36091 23.3862 8 22.4427 8C21.4992 8 20.5944 8.36091 19.9272 9.00334C19.2601 9.64576 18.8853 10.5171 18.8853 11.4256C18.8853 12.6371 20.2735 15.1854 21.4449 17.1488C21.538 17.3263 21.6808 17.4751 21.8571 17.5784C22.0334 17.6818 22.2363 17.7356 22.4427 17.7337Z" fill="#F5845E"/>
<path d="M9.55678 16.1035C9.23072 16.1035 8.91199 16.1966 8.64088 16.3711C8.36978 16.5455 8.15847 16.7934 8.03369 17.0835C7.90892 17.3736 7.87627 17.6928 7.93988 18.0007C8.00349 18.3086 8.16051 18.5915 8.39106 18.8135C8.62162 19.0355 8.91536 19.1867 9.23515 19.248C9.55494 19.3092 9.88643 19.2778 10.1877 19.1576C10.4889 19.0375 10.7464 18.834 10.9275 18.573C11.1087 18.3119 11.2053 18.005 11.2053 17.691C11.2053 17.27 11.0317 16.8662 10.7225 16.5685C10.4133 16.2708 9.994 16.1035 9.55678 16.1035ZM9.55678 18.443C9.40233 18.443 9.25136 18.3989 9.12294 18.3162C8.99452 18.2336 8.89442 18.1162 8.83532 17.9787C8.77621 17.8413 8.76075 17.6902 8.79088 17.5443C8.82101 17.3984 8.89539 17.2644 9.0046 17.1593C9.11381 17.0541 9.25295 16.9825 9.40443 16.9535C9.55591 16.9245 9.71292 16.9394 9.85562 16.9963C9.99831 17.0532 10.1203 17.1496 10.2061 17.2732C10.2919 17.3969 10.3377 17.5423 10.3377 17.691C10.3377 17.8904 10.2554 18.0817 10.109 18.2227C9.96251 18.3637 9.76388 18.443 9.55678 18.443Z" fill="#F5845E"/>
<path d="M9.55744 14.2676C8.61512 14.2713 7.71249 14.6334 7.04616 15.275C6.37983 15.9166 6.00381 16.7858 6 17.6932C6 18.9047 7.38827 21.453 8.55962 23.4164C8.66397 23.5847 8.8094 23.726 8.98329 23.8279C9.15717 23.9298 9.35426 23.9893 9.55744 24.0013C9.76286 23.9985 9.96376 23.9429 10.1392 23.84C10.3147 23.7371 10.4584 23.5909 10.5553 23.4164C11.7266 21.453 13.1149 18.9047 13.1149 17.6932C13.1168 17.2428 13.0261 16.7965 12.848 16.3801C12.6699 15.9637 12.4079 15.5853 12.0772 15.2668C11.7465 14.9484 11.3535 14.6961 10.9211 14.5246C10.4886 14.3531 10.0251 14.2658 9.55744 14.2676Z" fill="#F5845E"/>
<path d="M11.2972 17.5875C11.2972 17.2735 11.2005 16.9666 11.0193 16.7055C10.8382 16.4445 10.5807 16.241 10.2795 16.1208C9.97823 16.0007 9.64674 15.9693 9.32695 16.0305C9.00716 16.0918 8.71341 16.243 8.48286 16.465C8.2523 16.687 8.09529 16.9698 8.03167 17.2778C7.96806 17.5857 8.00072 17.9049 8.1255 18.195C8.25028 18.485 8.46158 18.733 8.73269 18.9074C9.00379 19.0818 9.32252 19.1749 9.64858 19.1749C10.0858 19.1749 10.5051 19.0077 10.8143 18.71C11.1235 18.4123 11.2972 18.0085 11.2972 17.5875Z" fill="#F9F0ED"/>
<path d="M22.399 18.8608H17.0194C16.6359 18.8608 16.268 18.7141 15.9968 18.4529C15.7256 18.1918 15.5733 17.8376 15.5733 17.4683C15.5733 17.099 15.7256 16.7448 15.9968 16.4836C16.268 16.2225 16.6359 16.0758 17.0194 16.0758H18.5378C18.5956 16.0788 18.6534 16.07 18.7075 16.0501C18.7615 16.0302 18.8106 15.9995 18.8515 15.9601C18.8925 15.9207 18.9243 15.8734 18.945 15.8213C18.9657 15.7693 18.9748 15.7136 18.9716 15.658C18.9716 15.5472 18.926 15.4409 18.8446 15.3626C18.7632 15.2843 18.6529 15.2402 18.5378 15.2402H17.0194C16.3981 15.2402 15.8022 15.4779 15.3629 15.901C14.9235 16.324 14.6767 16.8978 14.6767 17.4961C14.6767 18.0944 14.9235 18.6682 15.3629 19.0913C15.8022 19.5143 16.3981 19.752 17.0194 19.752H22.3556C22.7391 19.752 23.1069 19.8987 23.3781 20.1599C23.6493 20.421 23.8017 20.7752 23.8017 21.1445C23.8017 21.5138 23.6493 21.868 23.3781 22.1292C23.1069 22.3903 22.7391 22.537 22.3556 22.537H12.7678C12.6528 22.537 12.5424 22.581 12.4611 22.6594C12.3797 22.7377 12.334 22.844 12.334 22.9548C12.334 23.0656 12.3797 23.1718 12.4611 23.2502C12.5424 23.3285 12.6528 23.3725 12.7678 23.3725H22.3556C22.9769 23.3781 23.5751 23.1457 24.0185 22.7266C24.4619 22.3074 24.7142 21.7358 24.72 21.1375C24.7257 20.5393 24.4844 19.9633 24.0491 19.5363C23.6139 19.1093 23.0203 18.8663 22.399 18.8608Z" fill="#F5845E"/>
<path d="M24.097 11.5875C24.097 11.2735 24.0003 10.9666 23.8191 10.7055C23.638 10.4445 23.3805 10.241 23.0793 10.1208C22.778 10.0007 22.4465 9.96925 22.1268 10.0305C21.807 10.0918 21.5132 10.243 21.2827 10.465C21.0521 10.687 20.8951 10.9698 20.8315 11.2778C20.7679 11.5857 20.8005 11.9049 20.9253 12.195C21.0501 12.485 21.2614 12.733 21.5325 12.9074C21.8036 13.0818 22.1223 13.1749 22.4484 13.1749C22.8856 13.1749 23.3049 13.0077 23.6141 12.71C23.9233 12.4123 24.097 12.0085 24.097 11.5875Z" fill="#F9F0ED"/>
</svg>`;

export const proceedArrow=`<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<mask id="mask0_1_205073" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
  <rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_205073)">
  <path d="M5.34984 14.6683L4.1665 13.485L9.64984 8.00163L4.1665 2.51829L5.34984 1.33496L12.0165 8.00163L5.34984 14.6683Z" fill="white"/>
</g>
</svg>` 
export const arrowDown = `<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.5 5.89059L0.25 1.64059L1.24167 0.648926L4.5 3.90726L7.75833 0.648926L8.75 1.64059L4.5 5.89059Z" fill="#7D868C"/>
</svg>

`;
export const backArrowLight = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="4" fill="#E5F0FF"/>
<mask id="mask0_1_210921" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="32" height="32">
<rect x="4" y="4" width="32" height="32" fill="#0D2C54"/>
</mask>
<g mask="url(#mask0_1_210921)">
<path d="M20.0002 30.6663L9.3335 19.9997L20.0002 9.33301L21.9002 11.1997L14.4335 18.6663H30.6668V21.333H14.4335L21.9002 28.7997L20.0002 30.6663Z" fill="#0D2C54"/>
</g>
</svg>

`;