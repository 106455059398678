/** imported components
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Link ===> link from react component to use for as an anchor

**/
import React, { useState, useEffect, useRef, memo } from "react";
import { Link, useHistory } from "react-router-dom";
import "./home-walkthrough.scss"
import { decrypter, crypter } from "../../webutils/webutils";
import { apiEndpoints } from '../../services/api-endpoints';
import serviceProvider from '../../services/axios'
//PrepareHomeView component start
const PrepareHomeView = (props) => {
  
   return (
    <>
      {/* main wrapper */}
      <div className="prepare-home-wrapper">
        {/* page heading */}
        <h1 className="prepare-top-h1 walk-1" style={{display:"none"}}>
          <img
            src={
              require("../../assets/img/prepare-home/my-trainging-h1.svg")
                .default
            }
            alt="true"
          />
          <span>IR4.0 Training Courses</span>
        </h1>
        {/* Added wrapper for manufacturing courses */}
        <div className="manufacturing-course-wraper" style={{display:"none"}}>
          {/* training course heading */}
          {/* <div className="training-course-heading">
            <h2>Manufacturing Courses</h2>
          </div> */}
          {/* row has been divided in col */}
          <div className="prepare-row">
            {/* this section has card */}
            <div className="prepare-col 0">
              {/* card has two sections
                  prp-img-div
                  prp-text-div
              */}
              <a className="prp-card" href="/skill-assessment">
                {/* this section has
                    image
                    status 
                 */}
                <div className="prp-img-div green-bg">
                  <img
                    src={
                      require("../../assets/img/training-card/digital-organization.svg")
                        .default
                    }
                    className="icon-img"
                    alt="Skills Assessment"
                  />
                  <span
                    className= "status todo"
                  >
                    TO DO
                  </span>
                </div>
                <div className="prp-text-div">Digital Organisation</div>
              </a>
            </div>
            {/*-------------------- analitics card ----------------------*/}
          </div>
        </div>
        {/* Added wrapper for new category MY Assets and also divided it into two subcategories */}
        <div className="assets-wrapper">
          {/* Main Category Heading */}
          <h1 class="prepare-top-h1 walk-2">
            <img
              src={require("../../assets/img/prepare-home/measure.svg").default}
            />
            <span>My Plants</span>
          </h1>
          {/* First Subcategory will be Plants wrapper */}
          <div className="plants-wrapper">
            
              <div className="prp-plant-div">
                <div className="prepare-assets-heading">
                  {/* <img
                    src={
                      require("../../assets/img/prepare-home/measure.svg").default
                    }
                    alt="true"
                  /> */}
                  <div className="prp-plant-div">
                    <div className="prepare-row">
                      <div className="prepare-full-col">
                        <div class="plant-name-div">
                          <span class="plant-img">
                            <img src={`http://185.19.30.43/educateprepare/api/assets/img/country/united-states.svg`} alt="" />
                          </span>
                          <span class="plant-txt">Demo Plant</span>
                        </div>
                      </div>
                      <div className="prepare-full-col">
                        <div className="prp-inner-card-row">
                          <div className="prepare-col plants tour">
                            <PlantCard
                              cardType="PREPARE"
                              title="IR4.0 Technology Assessment"
                              status="results-ready"
                              savings="44%"
                              isRoadmapResult="Maturity Index"
                              type="dma"
                              colorClass="blue-bg"
                              
                            />
                          </div>
                          <div className="prepare-col plants tour">
                            <PlantCard
                              cardType="PREPARE"
                              status="results-ready"
                              title="IR4.0 Loss Analysis"
                              isRoadmapResult="Identified Losses"
                              savings="$7.5M"
                              type="loss"
                              colorClass="blue-bg"
                            />
                          </div>
                          <div className="prepare-col plants">
                            <PlantCard
                              cardType="DEFINE"
                              title="IR4.0 Strategy"
                              status="not-started"
                              colorClass="orange-bg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
                {/* prepare-row 1 end */}
              </div>
          </div>
        </div>
        {/* <button className="platform-button">
          <span className="platform-text">Platform Trainings</span>
          <i className='arrow-icon' dangerouslySetInnerHTML={{ __html: arrowSvg }}></i>
        </button> */}
      </div>
    </>
  );
};

export default memo(PrepareHomeView);

const TraingingCard = (props) => {
  const { title, headerImg, url, status, colorClass, otherFunction } = props;
  const callOtherFunction = () => {
    if (otherFunction) {
      otherFunction();
    }
  };
  return (
    <>
      <ConditionalTag url={url} otherFunction={callOtherFunction}>
        <div className={`prp-img-div ${colorClass}`}>
          <img src={headerImg} className="icon-img" alt="Img" />
          {status == 100 ? (
            <span className="status completed">COMPLETED</span>
          ) : status > 0 && status < 100 ? (
            <span className="status inprogress">IN PROGRESS</span>
          ) : status == 0 ? (
            <span className="status todo">To do</span>
          ) : (
            ""
          )}
        </div>
        <div className="prp-text-div">
          {title}
          {/* max-character 49*/}
        </div>
      </ConditionalTag>
    </>
  );
};
//plant card component
const PlantCard = (props) => {
  const {
    title,
    url,
    status,
    customStatus,
    colorClass,
    cardType,
    type,
    progress,
    lossReduction,
    isRoadmapDiveTxt,
    isRoadmapResult,
    savings,
    otherFunction,
  } = props;
  const callOtherFunction = () => {
    if (otherFunction) {
      otherFunction();
    }
  };
  return (
    <>
      <ConditionalTag url={props.gourl} otherFunction={callOtherFunction}>
        <div className={`prp-img-div ${colorClass}`}>
          <div className="prp-card-img">
            <p>{cardType}</p>
            <img
              src={
                require(`../../assets/img/prepare-home/${type == "dma"
                  ? `assessment-icon.svg`
                  : type == "loss"
                    ? "loss-icon.svg"
                    : type == "execute"
                      ? "prepare-execute.svg"
                      : type == "techAdop"
                        ? "tech-adoption-icon.svg"
                        : "business-icon.svg"

                  }`).default
              }
              alt="Img"
            />
          </div>
          <div className="prp-card-porogress-div">
            {/* if roadmap deep dive text  in props*/}
            {isRoadmapDiveTxt && (
              <span className="txt-normal">{isRoadmapDiveTxt}</span>
            )}

            {/* if progress is 0 or greater than 0 in props  */}
            {progress > -1 && progress !== "undefined" ? (
              <span className="txt-normal">Progress</span>
            ) : (
              ""
            )}
            {progress > -1 && <span className="progress-num">{progress}%</span>}

            {/* loss reduction */}
            {/* {lossReduction > -1 && lossReduction !== "undefined" ? (
              <span className="txt-normal">Loss Reduction</span>
            ) : (
              ""
            )}
            {lossReduction > -1 && (
              <span className="progress-num">{lossReduction}%</span>
            )} */}
            {/* loss reduction end */}

            {isRoadmapResult && (
              <>
                <span className="progress-num result">{savings}</span>
                <span className="txt-normal">{isRoadmapResult}</span>
              </>
            )}
          </div>

          {status == 100 ? (
            <span className="status completed">COMPLETED</span>
          ) : status > 0 && status < 100 ? (
            <span className="status inprogress">IN PROGRESS</span>
          ) : status == 0 ? (
            <span className="status todo">To do</span>
          ) : status == "not-started" ? (
            <span className="status not-started">NOT STARTED</span>
          ) : status == "results-ready" ? (
            <span className="status results-ready">RESULTS READY</span>
          ) : (
            ""
          )}
          {/* custom status */}
          {customStatus ? (
            <span className="status completed">{customStatus}</span>
          ) : (
            ""
          )}
        </div>
        <div className="prp-text-div">
          {title}
          {/* max-character 49*/}
        </div>
      </ConditionalTag>
    </>
  );
};
//conditional tag component
const ConditionalTag = (props) => {
  const { url, otherFunction } = props;
  return (
    <>
      {url ? (
        <Link to={url} className="prp-card">
          {props.children}
        </Link>
      ) : (
        <div className="prp-card" onClick={otherFunction}>
          {props.children}
        </div>
      )}
    </>
  );
};

const arrowSvg = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1308_265487" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1308_265487)">
<path d="M7.49298 16.6935C7.33586 16.8554 7.07603 16.8554 6.91891 16.6935L6.27039 16.0253C6.11977 15.8702 6.11977 15.6234 6.27039 15.4682L11.3177 10.2678C11.4683 10.1127 11.4683 9.86586 11.3177 9.71067L6.27039 4.51035C6.11977 4.35516 6.11977 4.10836 6.27039 3.95317L6.91891 3.28499C7.07603 3.12311 7.33586 3.12311 7.49298 3.28499L13.7296 9.71067C13.8802 9.86586 13.8802 10.1127 13.7296 10.2678L7.49298 16.6935Z" fill="white"/>
</g>
</svg>

`
