/** imported components

  * TrainingCardControler ===> component controller.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import { TrainingCardControler } from "../training-card/training-card-ctrl";
//TrainingsView component start
export const TrainingsView = ({ trainingData, props }) => {
	//defining variables and conditions here
	if (!trainingData.length) {
		return null;
	}
	let avaltrainging = 0;
	trainingData.map((items, index) => {
		if (items.has_paid == 0) {
			avaltrainging = avaltrainging + 1;
		}
	});
	return (
		/* this section has
			heading
			main-training-sec
			available-training-sec
		*/ 
		<div className="main-course-page">
			<h1 className="h1">All IR4.0 training courses</h1>
			{/* this section has 
				heading
				train-card-row-container
			*/}
			<section className="main-training-sec">
				{/* this section has row */}
				<div className="train-card-row-continer">
					<div className="training-card-row">
						{trainingData.map((items, index) => {
							if (items.has_paid == 1) {
								return (
									<div className="training-card-col" key={index}>
										<TrainingCardControler
											data={{
												cardTitle: items.course_name,
												cardId: items.id,
												cardSlug: items.course_slug,
												cardImg: require(`../../assets/img/training-card/${items.course_icon}`).default,
												isImd: items.is_imd,
												cardDuration: items.total_sec_dur,
												cardStatus: items.cardStatus,
												isAvailable: false,
												isFirstTraining:items.is_first_training,
											}}
										/>
									</div>
								);
							}
						})}
					</div>
				</div>
			</section>
			{/* main-training-sec end   */}
			<section className="available-training-sec">
				
				<div className="train-card-row-continer">
					<div className="training-card-row">
						{trainingData.map((items, index) => {
							if (items.has_paid == 0) {
								return (
									<div className="training-card-col" key={index}>
										<TrainingCardControler
											data={{
												cardTitle: items.course_name,
												cardId: items.id,
												cardSlug: items.course_slug,
												cardImg: require(`../../assets/img/training-card/${items.course_icon}`).default,
												isImd: items.is_imd,
												cardDuration: items.total_sec_dur,
												cardStatus: items.cardStatus,
												isAvailable: true,
												availableText: items.course_snippet,
												availableTechnology: items.course_technology,
												isFirstTraining:items.is_first_training,
											}}
										/>
									</div>
								);
							}
						})}
					</div>
				</div>
			</section>
			{/*available-training-sec end   */}
		</div>
	);
};
