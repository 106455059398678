import React, { useState, useEffect } from 'react';
import { inviteUserIcon } from './svg';
import { UnAssignIcon } from './svg';
import serviceProvider from '../../services/axios'
import { apiEndpoints } from '../../services/api-endpoints'
import filter from 'lodash/filter';
export const InviteUserList = (props) => {
    const [isUnassign, setIsUnassign] = useState(false);
    const [isOpen, setIsOpen] = useState(props.open);
    const [dataList, setDataList] = useState([])

    const handleUnassignBtn = (val) => {
        setIsUnassign(val);
    }
    const handleUnassignUser = (singleData) => {
        let filterData = dataList.filter((item) => item.email != singleData.email);
        props.setData({
            inviteData: filterData
        })
        setDataList(filterData);
        const result = serviceProvider.get(`/Businesscaseplantloss/removeInvite?user_id=${props.uid}&catagory=${props.catagory}&invited_sub_category=${props.subcatagory}&categName=${props.sectionName}&plant_name=${props.plantName}&plant_id=${props.pid}&email=${singleData.email}`);
    }
    const handleDropdownList = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        //setDataList(props.data);
        serviceProvider.post("/Businesscaseplantloss?type=get_member_invite", JSON.stringify({ uid: props.uid, plants: props.pid }), true).then((response) => {

            let getAllId = [];
            let inviteduserlist = response.data;
            let tmpInviteData;
            if (inviteduserlist && Array.isArray(inviteduserlist)) {


                let tmpArr = { ...dataList };
                if (props.catagory == 103 || props.catagory == 102 || props.catagory == 104 || props.catagory == 105 || props.catagory == 106 || props.catagory == 107) {
                    tmpInviteData = inviteduserlist.filter((item) => item.invited_category == props.catagory);
                } else {
                    tmpInviteData = inviteduserlist.filter((item) => item.invited_sub_category == props.subcatagory);
                }
                setDataList(tmpInviteData);

            }
        });
        document.addEventListener('click', (e) => {
            if (!e.target.closest('.avatar-unassign-div')) {
                setIsUnassign(false);
            }
            if (!e.target.closest('.list__cir-wrapper__ul')) {
                setIsOpen(false);
            }
        })
    }, [, props.data]);
    return (
        <>
            <link rel="stylesheet" href={require('./inviteUserList.scss').require} />
            <div className='invite-list-container'>
                <div className='list__cir-wrapper'>
                    <ul data-title="View Users" className={`list__cir-wrapper__ul ${isOpen ? 'active' : ''}`}>

                        {dataList?.map((li, index) => {
                            let shortName = li.name ?
                                `${li.name[0]}${li.last_name && li.last_name[0]}`
                                :
                                `${li.email[0]}`;
                                let fullName = li.name ? `${li.name ? li.name : ''} ${li.last_name ? li.last_name : ''}` : li.email;
                            return (
                                <>
                                    {index < 2 &&
                                        <li key={li.id} className="list__avatar-container" onClick={(e) => handleDropdownList()}>
                                          <div className={`avatar-box`}>
                                                {!li.profile_pic ?
                                                    <div className={`avatar-box cir-dashed ${li.avcolor}`} >
                                                        {fullName ? shortName : li.email[0]}
                                                    </div>
                                                    :

                                                    <img className="avatar-box"

                                                        src={`${apiEndpoints.baseUrl}/assets/user-profile/${li.profile_pic}`}
                                                        alt={li.profile_pic}
                                                    />

                                                }
                                            </div>
                                        </li>
                                    }
                                </>
                            )
                        })}
                        {dataList.length > 2 &&
                            <li onClick={(e) => handleDropdownList()} className="li--count">
                                {dataList.length - 2}
                            </li>
                        }
                        <div className={`list__assign-user-list ${isOpen ? 'active' : ''}`}>
                            <div className="list__avatar-scroll-box">
                                {dataList?.map((li, index) => {
                                    let shortName = li.name ?
                                        `${li.name[0]}${li.last_name && li.last_name[0]}`
                                        :
                                        `${li.email[0]}`;
                                    let fullName = li.name ? `${li.name ? li.name : ''} ${li.last_name ? li.last_name : ''}` : li.email;
                                    return (
                                        <div key={li.id} className="list__avatar-container">
                                            <div className={`avatar-box`}>
                                                {!li.profile_pic ?
                                                    <div className={`avatar-box cir-dashed  ${li.avcolor}`} >
                                                        {fullName ? shortName : li.email[0]}
                                                    </div>
                                                    :

                                                    <img className="avatar-box"

                                                        src={`${apiEndpoints.baseUrl}/assets/user-profile/${li.profile_pic}`}
                                                        alt={li.profile_pic}
                                                    />

                                                }
                                            </div>
                                            <div className="avatar-detail">
                                                <h1 className='avatar-name'>
                                                    {fullName}
                                                </h1>
                                                {li.job_title &&
                                                    <p className="avatar-role">
                                                        {li.job_title}
                                                    </p>
                                                }
                                            </div>
                                            {props.isEditAllow &&
                                                <div className={`avatar-unassign-div ${isUnassign == li.email ? 'active' : ''}`}>
                                                    <div
                                                        className="avatar-user-unAssign"
                                                        onClick={(e) => { handleUnassignUser(li) }}
                                                    >
                                                        <span className="unassign-text" >Un-Assign</span>
                                                    </div>
                                                    <i className="icon-svg"
                                                        dangerouslySetInnerHTML={{ __html: UnAssignIcon }}
                                                        onClick={(e) => handleUnassignBtn(li.email)}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                                )
                                }
                            </div>
                        </div>
                    </ul>
                    {props.isEditAllow &&
                        <button data-title="Assign Users" className='list__cir-wrapper__btn' onClick={() => props.handlePopup(0)}>
                            <span dangerouslySetInnerHTML={{ __html: inviteUserIcon }}></span>
                        </button>
                    }
                </div>
            </div>
            {/* invite-list-container end */}
        </>
    )
}
