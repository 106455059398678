

/** imported components

    * ./svg => imported for all svg elements  
    * ./styles => imported for using of all created styled components.
    * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React, { useState, useEffect } from 'react';
import {
    WalkthroughContainer, WalkthroughWrapper,
    WalkthroughCard,
    WalkSection,
    WalkTopRow,
    WalkTopLeftCol, WalkTopRightCol, WalkMidRow,
    WalkFooter, WalkFooterLeftCol, WalkFooterRightCol,
    DotsSection, ButtonWrapper

} from './styles';


export const HomePageWalkthroughView = (props) => {
    const { activeSlide, setActiveSlide } = props;
    var highlightarea1 = document.querySelector('.walk-1');
    var highlightarea2 = document.querySelector('.walk-2');
    var highlightarea3 = document.querySelector('.ng-sidebar');
    var highlightarea4 = document.querySelector('.add-plant-svg');
    var highlightarea5 = document.querySelector(".resource-tab");
    const users = JSON.parse(localStorage.getItem("userData"));
    const group_id = users ? users.group_id : ''; /*if 14 it's simple user else 2 or 11 for admin*/
    const UserName = users ? users.name : "First Name";
    // Function to navigate slider from one to next
    const adminData = [
        {
            title: `Welcome ${UserName}`,
            details: `This is your homepage. Here you can easily access your trainings and assets.`
        },

        {
            title: `My Plants`,
            details: `Here you can find all your available assets. Explore the maturity and understand the losses of your plants.`
        },

        // {
        //     title: `Add Plant`,
        //     details: `From here you can add a new plant.`
        // },
        {
            title: `Resource Center`,
            details: `Here you can find all the resources you will need. From case studies and methodology to product trainings.`
        },
    ]
    const userData = [
        {
            title: `Welcome ${UserName}`,
            details: `This is your homepage. Here you can easily access your trainings and assets.`
        },
        {
            title: `My Plants`,
            details: `Here you can find all your available assets. Explore the maturity and understand the losses of your plants.`
        },
        {
            title: `Resource Center`,
            details: `Here you can find all the resources you will need. From case studies and methodology to product trainings.`
        },
    ]
    const finalData = group_id == 14 ? userData : adminData;
    const HandleSlider = (val) => {
        setActiveSlide(val);
    }
    const handleClose = () => {
        setActiveSlide(null);
        highlightarea1?.classList.remove('walkthrough-active');
        highlightarea2?.classList.remove('walkthrough-active');
        document.querySelector('.training-main-wrapper').classList.remove('walkthrough-active');
        highlightarea3?.classList.remove('active');
        highlightarea4?.classList.remove('walkthrough-active');
        highlightarea5?.classList.remove('walkthrough-active');
        props.closeFunction();
    }
    useEffect(() => {
        // main container background
        document.querySelector('.training-main-wrapper').classList.add('walkthrough-active');
        var highlightarea1 = document.querySelector('.walk-1');
        var highlightarea2 = document.querySelector('.walk-2');
        var highlightarea3 = document.querySelector('.ng-sidebar');
        var highlightarea4 = document.querySelector('.add-plant-svg');
        var highlightarea5 = document.querySelector(".resource-tab");
        // code to set position
        var asset = document.querySelector('.assets-wrapper');
        let sidebar_list = document.querySelector('.ng-level1-nav-list-wrapper')?.getBoundingClientRect().top;
        let add_tab = document.querySelector('.add-plant-svg')?.getBoundingClientRect().top;
        let resource_tab = document.querySelector('.resource-tab')?.getBoundingClientRect().top;
        let container1 = document.querySelector('.container-1');
        let container2 = document.querySelector('.container-2');
        let container3 = document.querySelector('.container-3');
        let container4 = document.querySelector('.container-4');
        let training_wrapper = document.querySelector('.training-content');
         
        if(container1 && sidebar_list){
            container1.style.top = sidebar_list - 20 + 'px';
        }
        if(container2 && asset){
            asset.style.minHeight = '100vh';
            training_wrapper.scrollTop = container2?.getBoundingClientRect().height;
            container2.style.top = asset?.getBoundingClientRect().top - 20 + 'px';
        }
        else{
            training_wrapper.scrollTop = 0;
            asset.style.minHeight = 'auto';
        }
        if(container3 && asset){
            asset.style.minHeight = '100vh';
            training_wrapper.scrollTop = container3?.getBoundingClientRect().height;
            container3.style.top = asset?.getBoundingClientRect().top - 20 + 'px';
        }
        if(container4 && resource_tab){
            container4.style.top = resource_tab - 20 + 'px';
        }
        
        
        
        if (activeSlide === 1) {
            highlightarea1?.classList.add('walkthrough-active');
            highlightarea3?.classList.remove('active');
            highlightarea3?.classList.remove('mobile-active');
        }
        else{
            highlightarea1?.classList.remove('walkthrough-active');
            highlightarea3?.classList.remove('active');
            highlightarea3?.classList.remove('mobile-active');
        }
        if (activeSlide === 2) {
            highlightarea2?.classList.add('walkthrough-active');
            highlightarea3?.classList.remove('active');
            highlightarea3?.classList.remove('mobile-active');
        }
        else{
            highlightarea2?.classList.remove('walkthrough-active');
            highlightarea3?.classList.remove('active');
            highlightarea3?.classList.remove('mobile-active');
        }
        // if (activeSlide === 3) {
        //     highlightarea4?.classList.add('walkthrough-active');
        //     highlightarea3?.classList.remove('active');
        //     highlightarea3?.classList.remove('mobile-active');
        // }
        // else{
        //     highlightarea4?.classList.remove('walkthrough-active'); 
        //     highlightarea3?.classList.remove('active');
        // }
        if (activeSlide === 3 || (group_id==14 && activeSlide===3)) {
            highlightarea5?.classList.add('walkthrough-active');
            highlightarea3?.classList.add('active');
            highlightarea3?.classList.add('mobile-active');
        }
        else{
            highlightarea5?.classList.remove('walkthrough-active');
        }
    }, [activeSlide]);


    return (
        <>
            {finalData?.map((item, ind) => (
                <ShowContent active={activeSlide} item={ind + 1} key={ind}>
                    <WalkthroughContainer data-component='WalkthroughContainer' style={{ "display": "block" }}>
                        <WalkthroughWrapper data-component="WalkthroughWrapper"  className={(ind+1==3 && group_id == 14)?'container-3':ind!=0 ? `container-${ind+1}`:''}>
                            <WalkthroughCard data-component="WalkthroughCard" className={ind !=0 ? "tooltip-class":''}>
                                <WalkSection data-component="WalkSection">
                                    <WalkTopRow data-component="WalkTopRow">
                                        <WalkTopLeftCol data-component="WalkTopLeftCol">
                                            <span>{ind + 1} of {finalData.length} </span>
                                        </WalkTopLeftCol>
                                        <WalkTopRightCol data-component="WalkTopRightCol">
                                            <span className="img" onClick={() => handleClose()}>
                                                <i className='close-icon' dangerouslySetInnerHTML={{ __html: crossSvg }}></i>
                                            </span>
                                        </WalkTopRightCol>
                                    </WalkTopRow>
                                    <WalkMidRow data-component="WalkMidRow">
                                        <h2><span>{item.title}</span>,</h2>
                                        <p>{item.details}</p>
                                    </WalkMidRow>
                                    <WalkFooter data-component="WalkFooter">
                                        <WalkFooterLeftCol data-component="WalkFooterLeftCol">
                                            <DotsSection data-component="DotsSection">
                                                {finalData?.map((_, index) => (
                                                    <button className="circle" onClick={() => { HandleSlider(index + 1) }} disabled={activeSlide === index + 1 ? true : false}>
                                                        <i className='close-icon' dangerouslySetInnerHTML={{ __html: circleSvg }}></i>
                                                    </button>))
                                                }
                                            </DotsSection>
                                        </WalkFooterLeftCol>
                                        <WalkFooterRightCol data-component="WalkFooterRightCol">
                                            <ButtonWrapper data-component="ButtonWrapper">
                                                {ind == 0 ?
                                                    <>
                                                        <button className="btn-link" onClick={() => handleClose()}>
                                                            No thanks
                                                        </button>
                                                        <button className="solid-btn-link start" onClick={() => setActiveSlide(activeSlide+1)}>
                                                            Let’s go
                                                        </button>
                                                    </>
                                                    : ind == (finalData.length - 1) ?
                                                        <>
                                                            <button className="btn-link" onClick={() => setActiveSlide(1)}>
                                                                Restart
                                                            </button>
                                                            <button className="solid-btn-link start" onClick={() => handleClose()}>
                                                                Let’s Start
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button className="btn-link" onClick={() => setActiveSlide(activeSlide-1)}>
                                                                Previous
                                                            </button>
                                                            <button className="solid-btn-link start" onClick={() => setActiveSlide(activeSlide+1)}>
                                                                Next
                                                            </button>
                                                        </>
                                                }
                                            </ButtonWrapper>
                                        </WalkFooterRightCol>
                                    </WalkFooter>
                                </WalkSection>
                            </WalkthroughCard>
                        </WalkthroughWrapper>
                    </WalkthroughContainer>
                </ShowContent>))
            }
            {/* MainWrapper ended */}
        </>
    );
}

// Function to show content
const ShowContent = (props) => {
    return (
        <>
            {props.active === props.item &&
                <>
                    {props.children}
                </>
            }
        </>
    )
}

// Walkthrough SVG

const crossSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 7.33333C5.63181 6.96514 5.63181 6.36819 6 6C6.36819 5.63181 6.96514 5.63181 7.33333 6L18 16.6667C18.3682 17.0349 18.3682 17.6318 18 18C17.6318 18.3682 17.0349 18.3682 16.6667 18L6 7.33333Z" fill="#118ACB"/>
<path d="M6 18C5.63181 17.6318 5.63181 17.0349 6 16.6667L16.6667 6C17.0349 5.63181 17.6318 5.63181 18 6C18.3682 6.36819 18.3682 6.96514 18 7.33333L7.33333 18C6.96514 18.3682 6.36819 18.3682 6 18Z" fill="#118ACB"/>
</svg>
`
const circleSvg = `<svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="2" cy="2" r="2" fill="#C4C4C4"/>
</svg>   
`

