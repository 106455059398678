import React, { useEffect, useRef } from "react";
import serviceProvider from "../../services/axios";
import { useState } from "react";
import $, { isEmptyObject } from "jquery";
import { number } from "prop-types";

export const FinancialValidationPageView = (props) => {
  const validData = props.validResult;
  if (!validData.length) {
    return null;
  }

  const validDatanew = validData[0];

  const pid = validDatanew.pid;
  const units = validDatanew.units;
  const [submitShow, setSubmitShow] = useState(false);
  const [submitShow2, setSubmitShow2] = useState(
    validDatanew.count_lock_array_status
  );
  const [submitShow3, setSubmitShow3] = useState(false);
  const [isFillPopup, setIsFillPopup] = useState(null);
  if (validDatanew.count_lock_array < 2) {
    props.Finalsubmit(1);
  }

  const [infoList, setInfoList] = useState([
    {
      name: `Average Cost of Direct Labor`,
      editable: false,
      rule: {
        topText: `Cost of Direct Labor`,
        bottomText: `Total Number of Direct FTEs`,
      },
      isRuleDiv: true,
      isValue: validDatanew.v1,
    },
    {
      name: `Average Quality Labor Cost`,
      editable: false,
      rule: {
        topText: `Cost of Quality Labor`,
        bottomText: `Total Number of Quality FTEs`,
      },
      isRuleDiv: true,
      isValue: validDatanew.v2,
    },
    {
      name: `Average Maintenance Labor Cost`,
      editable: false,
      rule: {
        topText: `Cost of Maintenance Labor`,
        bottomText: `Total Number of Maintenance FTEs`,
      },
      isRuleDiv: true,
      isValue: validDatanew.v3,
    },
    {
      name: `Average IT Labor Cost`,
      editable: false,
      rule: {
        topText: `Cost of IT Labor`,
        bottomText: `Total Number of IT FTEs`,
      },
      isRuleDiv: true,
      isValue: validDatanew.v4,
    },
    {
      name: `Average EHS Labor Cost`,
      editable: false,
      rule: {
        topText: `Cost of EHS Labor`,
        bottomText: `Total Number of EHS FTEs`,
      },
      isRuleDiv: true,
      isValue: validDatanew.v5,
    },
    {
      name: `Average Warehouse & Material Handling Labor Cost`,
      editable: false,
      rule: {
        topText: `Cost of Warehouse Labor`,
        bottomText: `Total Number of Warehouse FTEs`,
      },
      isRuleDiv: true,
      isValue: validDatanew.v6,
    },
    {
      name: `Cost of Conversion incl. Waste (Excluding Depreciation)`,
      editable: false,
      rule: {
        topText: `Cost of Direct Labor + Cost of Manufacturing Overhead + Total Material Waste Cost - Equipment Depreciation`,
        bottomText: `Total Number of Direct FTEs`,
      },
      isRuleDiv: false,
      isValue: validDatanew.v7,
    },
  ]);
  const unlockfin = (e) => {
    props.setOpenFinValidation(false);
    serviceProvider
      .post(
        "/businesscaseplantloss?type=openfvalidation",
        JSON.stringify({ pid: pid }),
        true
      )
      .then((response) => {});
  };
  const HandleFillSec = (val) => {
    if (isFillPopup === val) {
      setIsFillPopup(null);
    } else {
      setIsFillPopup(val);
    }
  };

  return (
    <>
      {submitShow ? (
        <div className="submit-popup">
          <div className="confirmation-form">
            <div id="myModal" className="submit-form">
              <div className="submit-content">
                <button
                  className="close-submit-form"
                  onClick={() => setSubmitShow(false)}
                >
                  <img
                    src={
                      require("../../assets/img/fte-breakdown/clear.svg")
                        .default
                    }
                    alt="fte-breakdown"
                  />
                </button>
                <div className="file">
                  <img
                    src={
                      require("../../assets/img/fte-breakdown/Unsaved.svg")
                        .default
                    }
                    alt="fte-breakdown"
                  />
                </div>
                {/* <p>By submitting the data you will no longer have the opportunity for further editing. </p> */}
                <p>
                  By submitting the data you will no longer have the opportunity
                  for further editing. Please make sure that the data provided
                  are in their most updated form.
                </p>
                <p className="sure-to-do"> Are you sure you want to proceed?</p>
                <div className="what-to-do">
                  {/* <button onClick={() => setSubmitShow(false)}>Cancel</button> */}
                  <button className="prev" onClick={() => setSubmitShow(false)}>
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      setSubmitShow(false);
                      setSubmitShow2(true);
                    }}
                    className="final"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {submitShow2 ? (
        <div className="submit-popup-next ">
          <div className="confirmation-form">
            <div id="myModal" className="submit-form">
              <div className="submit-content">
                <button
                  className="close-submit-form"
                  onClick={() => {
                    setSubmitShow2(false);
                    props.setValidationPopup(false);
                  }}
                >
                  <img
                    src={
                      require("../../assets/img/fte-breakdown/clear.svg")
                        .default
                    }
                    alt="fte-breakdown"
                  />
                </button>
                <div className="file">
                  <img
                    src={
                      require("../../assets/img/fte-breakdown/accuracy.svg")
                        .default
                    }
                    alt="fte-breakdown"
                  />
                  <p>Finance Data Validation</p>
                </div>
                <p className="border-para">
                  By submitting the data you will no longer have the opportunity
                  for further editing.
                </p>
                <p className="sure-to-do">
                  Based on your input, the following metrics have been
                  calculated.
                </p>
                <div className="fill-form">
                  <ul>
                    {infoList.map((items, x) => {
                      return (
                        <li>
                          <button
                            title={items.name}
                            className={`form-icon-button ${
                              isFillPopup === "submitlist" + x + 1 &&
                              "info-popup"
                            }`}
                          >
                            <div
                              className="info"
                              onClick={() =>
                                HandleFillSec("submitlist" + x + 1)
                              }
                            >
                              <svg
                                width="{16}"
                                height="{16}"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z"
                                  fill="#CBCFD2"
                                />
                              </svg>
                            </div>
                            <div className="direct-labor-popup">
                              <div className="right-col">
                                <p className="formula-title">{items.name}</p>
                                <p> =</p>
                              </div>
                              <div className="left-col">
                                {items.isRuleDiv ? (
                                  <>
                                    <p className="top-col">
                                      {items.rule.topText}
                                    </p>
                                    <div className="border-div"></div>
                                    <p className="bottom-col">
                                      {items.rule.bottomText}
                                    </p>
                                  </>
                                ) : (
                                  <p className="top-col">
                                    {items.rule.topText}
                                  </p>
                                )}
                              </div>
                            </div>
                            <p>{`${x + 1}. ${items.name} =`}</p>
                          </button>
                          <input
                            type="text"
                            name="percentage"
                            placeholder="12,000 EUR"
                            value={items.isValue}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <span className="Note">
                    Please note, that by selecting to Re-open all Finance
                    Sections, you will need to re-submit all of them after you
                    are done editing them.
                  </span>
                </div>

                <div className="what-to-do">
                  <button
                    className="prev"
                    onClick={(e) => {
                      setSubmitShow2(false);
                      props.setValidationPopup(false);
                      unlockfin(e);
                    }}
                  >
                    Re-open Finance Sections
                  </button>
                  <button
                    onClick={(e) => {
                      setSubmitShow2(false);
                      setSubmitShow3(true);
                      props.Finalsubmit(e);
                    }}
                    className="final"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {submitShow3 ? (
        <div className="submit-popup-completed">
          <div className="confirmation-form">
            <div id="myModal" className="submit-form">
              <div className="submit-content submit-content-wrapper">
                {/* <button
                  className="close-submit-form"
                  onClick={() => setSubmitShow3(false)}
                >
                  <img
                    src={
                      require("../../assets/img/fte-breakdown/clear.svg")
                        .default
                    }
                    alt="fte-breakdown"
                  />
                </button> */}
                <div className="complete complete-wrapper">
                  <div className="file">
                    <img
                      src={
                        require("../../assets/img/fte-breakdown/submit.svg")
                          .default
                      }
                      alt="fte-breakdown"
                      className="air_icon_submit"
                    />
                  </div>
                  <p className="after_submit_message">
                    Submitting and uploading your data...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
