/** imported components

  * $ for ===> to use jquery for the page
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/


import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import $ from "jquery";

// Single line component for question view
export const SingleLine = (props) => {


	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}

	const quesDataItem = props.quesData[0];

	let tmpObj={};

	const {allRangeValueArr, setAllRangeValueArr} = props;

	
	const getValue = (name, value) => {
		const tmpArr = {
			...allRangeValueArr,
			[name]: value
		}
		setAllRangeValueArr(tmpArr);
	}
	const HandleRightTab = (index) => {
		if (activeRightTab === index) {
			setActiveRightTab(null);
		} else {
			setActiveRightTab(index);
		}
	}

	const HandleClickedCardAction = (index) => {
		if (clickedCardNo === index) {
			setActiveRightTab(null);
			setclickedCardNo(null);

		} else {
			setActiveRightTab(0);
			setclickedCardNo(index);
		}
	}
	useEffect(() => {
		// console.clear();
		//setAllRangeValueArr(tmpObj);
		console.log(allRangeValueArr);
	}, []);
	return (
			<>
				{/* Single line wrapper that is containg one card which is containin question */}
				<div  className={`single-line-wrap ${quesDataItem.savedans.length != 0 &&  'active'}`}>
					<div className="choose-one-card">
						<CardBtnSec getValue={getValue}  onErrorMsg={props.onErrorMsg} dataline={quesDataItem} Itemindex={0} activeBtn={5} {...props} />
					</div>
				</div>
			</>
		);
};

const TabPanel = (props) => {
	const { activeRightTab, itemIdex, children } = props;
	return (
		<div key={itemIdex} style={{ display: activeRightTab === itemIdex ? "" : "none" }}>
			{children}
		</div>
	)
}


const CardBtnSec = (props) => {
	const {dataline} = props;
	const qid =  dataline.question.id
	//  console.log(qid);
	const [totalSelected, setTotalSelected] = useState(0);
	const [activeBtn, setActiveBtn] = useState(props.activeBtn);
	/* variable to check any line is selected or not*/
  const [isChkSelect,setIsChkSelect]=useState(0);
	const name = `ans${props.Itemindex}`;
	const valueAll = dataline.lines;
	const vArr = [];
	valueAll.forEach((value, index) => {
		const valid = value.id;
		vArr.push(valid);
	});
	const HandleBtnActive = (e, ind) => {
		
		/*conditon to click in the card to remove active class from other buttons if their selected lines is 0*/ 
		if(ind==2){
			let divClick=document.querySelectorAll('.multi-tab-ans-wrap');
			divClick?.forEach(div =>{
				let dataIndex=div.getAttribute('data-index');
					if(props.Itemindex!=dataIndex){
						div.click();
					}
			})
		}
		/*end conditon */ 
		if(ind==activeBtn && ind==2){
				setIsChkSelect(!isChkSelect)
		}else{
				setActiveBtn(ind);
		} 
		if (ind == 0) {
			let tmpInputArr = document.getElementsByName(name);
			props.getValue(name, vArr);
			tmpInputArr.forEach((input)=>{
				input.checked=true;
			});
			// setTotalSelected(vArr.length)
			HandleInputGroupValue();
			console.log(e)
		} else if (ind == 1) {
			let tmpInputArr = document.getElementsByName(name);
			props.getValue(name, "");
			tmpInputArr.forEach((input)=>{
				input.checked=false;
			});
			setTotalSelected(0)
			HandleInputGroupValue();
			console.log(e)
		} else if (ind == 2) {
			// 		let tmpInputArr = document.getElementsByName(name);
			// props.getValue(name, "all-lines");
			
			HandleInputGroupValue();
			
		// } else {
			
		
		
			// console.log(e)
			
			if(e!=undefined){
				const ScreenH = window.innerHeight;
			const ElementtopPos = e.clientY;
			if (!e.target.parentElement.classList.contains('active') && document.querySelector('.ddl-wrapper.active')) {
				if (ScreenH < ElementtopPos + 350) {
					document.querySelector('.ddl-wrapper.active').classList.remove('active');
					e.target.parentElement.classList.add('active');
					// document.querySelector('.ddl-btn.active').classList.remove('active');
				}
				else {
					document.querySelector('.ddl-wrapper.active').classList.remove('active');
					// document.querySelector('.ddl-btn.active').classList.remove('active');
					e.target.parentElement.classList.add('active');
				}
			} else {
				if (ScreenH < ElementtopPos + 350) {
				
					e.target.parentElement.classList.toggle('active');
					// e.target.classList.toggle('active');
				}
				else {
					
					e.target.parentElement.classList.toggle('active');
					// e.target.classList.toggle('active');
				}

			}
		}
		/*auto scroll js*/ 
			let scrollDiv=document.querySelector('.single-line-wrap');
			if(scrollDiv){
				scrollDiv.scrollTop=scrollDiv.scrollHeight;
			}

		}
	}
	const HandleInputGroupValue = () => {
		let tmpInputArr = document.getElementsByName(name);
		let tmpArr = [];
		for (let i = 0; i < tmpInputArr.length; i++) {
			if (tmpInputArr[i].checked) {
				tmpArr.push(tmpInputArr[i].value);
			}
		}
		props.getValue(name, tmpArr);
		setTotalSelected(tmpArr.length);
	}
	
	$(document).on('click','.mult-onclick', function(e){
                $(this).parent('.card-btn-sec').parent('.choose-one-card').parent('.single-line-wrap').addClass('active');
				$('.choose-one-card').removeClass('asst-card-error');
               // $('.error-box').text('').hide();
			   props.onErrorMsg("");
                // $(this).addClass('active');
            })
	// var newval;

 /*this useEffect will call first time and when isChkSelect value will be change*/	
					
	useEffect(()=>{
		// console.log(totalSelected);
		if(!totalSelected){
				setActiveBtn(false);
		}		
	},[isChkSelect]);



  /* this useEffect will call every time when any state will change*/
	useEffect(()=>{
		
    // click outside close
	window.onclick=(e)=>{
			if (!e.target.closest('.ddl-wrapper') && document.querySelector('.ddl-wrapper.active')) {
				let tmpClickDiv=document.querySelectorAll('.mult-onclick.active');
				tmpClickDiv?.forEach((div)=>{
					div.parentElement.parentElement.click();
				})
			 }
			}
		

		
		document.querySelectorAll('.single-line-wrap')[props.Itemindex].onclick = (e) => {
		if (!e.target.closest('.ddl-wrapper') && document.querySelector('.ddl-wrapper.active')) {
				e.currentTarget.querySelector('.ddl-wrapper.active')?.classList.remove('active'); 				
				setIsChkSelect(!isChkSelect)
			}
		}
				
	});

  /* this useEffect will call only first time when component will be render*/


	useEffect((e)=>{

		    var val = dataline.savedans;
			var newval1= [];
			if (val.length === 0 || val.length==2){
				    if (val.length==2){
    					setActiveBtn(1)
					}
			}else{
				let newval = JSON.parse(val);
				newval1= newval[0]?.line_id.split(',');
				if(Object.keys(props.allRangeValueArr).length == 0 && newval1.length == 0 ){
					HandleBtnActive(e, 1);
					console.log(e);
				}else if(dataline.lines.length == newval1.length){
					HandleBtnActive(e, 0);
					console.log(e);
				}else{
					HandleBtnActive(e, 2);
					// setTotalSelected(newval1.length)
					// console.log(e);
					// setActiveBtn(2)
			
				}
			}
			
			
			if (document.querySelector('.ddl-wrapper.active')) {
				document.querySelector('.ddl-wrapper.active').classList.remove('active');
				// document.querySelector('.ddl-btn.active').classList.remove('active');
			}
		
		
	},[])
	
	return (
		<div className="card-btn-sec" key={props.Itemindex} style={{pointerEvents:dataline.is_datasubmitted==1?'none':''}}>
			<button id={qid} className={`normal-btn allline mult-onclick ${activeBtn === 0 && 'active'}`} onClick={(e) => {
				HandleBtnActive(e, 0);
				
			}}>
				All Lines
			</button>
			{qid!=618 && (
			<button id={qid} className={`normal-btn noline mult-onclick ${activeBtn === 1 && 'active'}`} onClick={(e) => {
				HandleBtnActive(e, 1);
				
			}}>
				No Lines
			</button>)
			}
			<div id={qid}  className={`ddl-wrapper mult-onclick sameline ${activeBtn === 2 && 'active1', 'active'}`}>
				<button id='someid' className={`normal-btn ddl-btn ${activeBtn === 2 && 'active'}`}
					onClick={(e) => {
						HandleBtnActive(e, 2);
					}}
				>
					<span>
						{totalSelected > 0 ? `${totalSelected} Selected` : 'Some Lines'}
					</span>
					<svg width="1.3rem" height=".7rem" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="1.28096" height="8.83478" rx="0.640482" transform="matrix(0.73034 -0.683084 0.73034 0.683084 0 0.964844)" />
						<rect width="1.28096" height="8.96675" rx="0.640482" transform="matrix(0.730344 0.683079 -0.730344 0.683079 12.0643 0)" />
					</svg>
				</button>
				<div className="ddl-list-wrap">
					<ul>
						{dataline.lines.map((item, index)=>{
							
							
							var val = dataline.savedans;
							var arr = [];
							if(val!=''){
							var newval1= [];
							
							
							if (val.length === 0 || val.length == 2){
    				
							}else{
								newval1 = JSON.parse(val);
								arr = newval1[0]?.line_id;
								
								}
							}
						return(	
						<li>
							<label>
								<input name={name} className={qid} value={item.id} defaultChecked={arr.includes(item.id)? true : false}  type="checkbox" onChange={HandleInputGroupValue} />
								<div className="chk-div">
									<img className="active-icon" src={require('../../assets/img/multi-choice/active-chk.svg').default} />
									<img className="inactive-icon" src={require('../../assets/img/multi-choice/non-active-chk.svg').default} />
								</div>
								<span className="chk-txt">
								{item.line_name}
								</span>
							</label>
						</li>)
						})}
						
					</ul>
				</div>
			</div>
		</div>

	)
}