import styled from "styled-components";

export const RoadmapMainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .strategy-popup-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(125, 134, 140, 0.3);
        display: none;
        z-index: 99;
      
        &.active {
          display: block;
        }
      
        .startegy-pop-card {
          background: #FFFFFF;
          border: 0.2rem solid #118ACB;
          box-shadow: 0px 0px 0.8rem rgba(21, 27, 38, 0.12);
          border-radius: 0.8rem;
          width: 63rem;
          padding: 4.4rem 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
      
          @media(max-width: 740px) {
            width: calc(100% - 20px);
          }
      
          h2 {
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.8rem;
            text-align: center;
            margin-bottom: 1.6rem;
            color: #0D2C54;
      
            @media(max-width: 740px) {
              font-size: 16px;
            }
          }
      
          p {
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            margin-bottom: 4rem;
            color: #4d4d4f;
            padding: 0 6.2rem;
      
            @media(max-width: 740px) {
              font-size: 14px;
            }
          }
      
          button {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.6rem;
            letter-spacing: 0.046rem;
            background: #118ACB;
            border-radius: 0.4rem;
            color: #ffffff;
            border-color: #118acb;
            height: 4.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20rem;
          }
        }
      }
`
export const RoadmapTabContent = styled.div`
    background: #fafcfd;
    flex: 1;
    padding-bottom: 70px;
`
export const RoadmapTabHeader = styled.div`
    background: #F4F6FC;
    padding: 4.8rem 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 740px){
        padding: 16px;
    }
    @media(max-width: 575px){
        gap:16px;
        flex-direction:column;
        align-items:flex-start;
    }
    .h1{
        color: #4D4D4F;
        font-size: 3.2rem;
        font-weight: 700;
        flex: 1;
        line-height: 150%;
        @media(max-width: 740px){
            font-size: 16px;
        }
        span{
            font-weight: 400;
        }
    }
    i{
        display: inline-block;
        width: 15.2rem;
        @media(max-width: 740px){
            display: none;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
`
export const ManuFactureBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    background: linear-gradient(92deg, #118ACB 12.22%, #0473AE 100.41%);
    color: #FFF;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%; /* 24px */
    padding: 0.8rem 1.6rem;
`
export const RoadmapFooter = styled.div`
    background: #fff;
    position: fixed;
    padding: 1.6rem 1.6rem 0 9rem;
    bottom: 0;
    left: 0;
    width: 100%;
    @media(max-width: 740px){
        padding-left: 15px;
    }
    .MuiTabs-root{
        min-height: auto;
        .MuiTab-root{
            margin-right: 0;
            padding: 0 2.4rem;
            border-right: 0.1rem solid #E6E6E6;
            min-height: auto;
            max-width: unset;
            &:last-child{
                border: 0;
            }
            .MuiTab-wrapper{
                .roadmap_tab__btn{
                    span{
                        color: #A6A7AD;
                        font-size: 1.4rem;
                        font-weight: 500;
                        line-height: 12px; /* 85.714% */
                        display: block;
                        padding-bottom: 1.2rem;
                    }
                }
            }
        }
        .MuiTabs-indicator {
            background: #45B097;
        }
    }
    
`
export const RoadmapTransformationWrapper=styled.div`
    padding: 4.8rem 2rem 2rem 7rem;
    @media(max-width: 740px){
        padding: 0 16px 24px 16px;
    }
`
export const TransFormRow = styled.div`
    display: flex;
    padding-top: 7.2rem;
    gap: 3.2rem;
    @media(max-width: 740px){
        padding-top: 24px;
        gap: 24px;
        flex-wrap: wrap;
    }
`
export const TransFormCol = styled.div`
    max-width: 22.5rem;
    @media(max-width: 740px){
        max-width: 100%;
    }
    .transform-title{
        color: #4D4D4F;
        display: block;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 150%; /* 27px */
        padding: 0.4rem 0 1.6rem 0;
        max-width: 12.5rem;
    }
    .num-text-col{
        @media(max-width: 740px){
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 24px;
        }
    }
`
export const NumCol = styled.div`
    color: #4D4D4F;
    display: block;
    font-size: 4.8rem;
    font-weight: 400;
    line-height: 150%; /* 72px */
    position: relative;
    padding-left: 1rem;
    z-index: 2;
    @media(max-width: 740px){
        font-size: 3.2rem;
        padding-left: 0;
        z-index: 0;
    }
    .background-image{
        display: block;
        padding: 0.4rem 0 0 0.4rem;
        height: 10.4rem;
        width: 10.4rem;
        position: absolute;
        bottom: 50%;
        left: 0;
        z-index: -1;
        @media(max-width: 740px){
            position: relative;
            height: 80px;
            width: 80px;
            z-index: -1;
        }
        i{
            display: inline-block;
            svg{
                height: 100%;
                width: 100%;
            }
            @media(max-width: 740px){
                width: 54px !important;
            }
        }
        &.found{
            background: #E5F4F0;
            i{
                width: 7.1rem;
            }
        }
        &.accel{
            background: #FFF3ED;
            i{
                width: 8.1rem;
            }
        }
        &.ampli{
            background: #DEEFF8;
            i{
                width: 8rem;
            }
        }
    }
    .count{
        @media(max-width: 740px){
            position: absolute;
            display: block;
            bottom: -17px;
            left: 20px;
        }
    }
`
export const ContentCol = styled.p`
    color: #4D4D4F;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.1rem; /* 131.25% */
`

// Tab Panel Page

export const RoadTabPanelWrapper = styled.div`
    padding: 4.8rem 2rem 2rem 7rem;
    @media(max-width: 740px){
        padding: 24px 16px;
    }
`
export const RoadTabPanelWrap = styled.div`
    display: flex;
    gap: 3.2rem;
    @media(max-width: 740px){
        flex-wrap: wrap;
    }
`
export const RoadTabPanelHeader = styled.div`
    border-radius: 0.4rem 0.4rem 0px 0px;
    padding: 0.6rem 1.2rem;
    
    .title{
        color: #FFF;
        display: block;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 150%; /* 24px */
    }
`
export const RoadTabPanelBody = styled.div`
    padding: 2.4rem 1.2rem;
    .list{
        padding-left: 0.8rem;
        margin-bottom: 2.4rem;
        border-left: 0.3rem solid transparent;
        .head-title{
            color: #0D2C54;
            display: block;
            padding-bottom: 0.4rem;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 150%; /* 21px */
        }
        ul{
            li{
                display: block;
                color: #455A64;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.8rem; /* 128.571% */
                margin-bottom: 0.4rem;
                position: relative;
                padding-left: 1.2rem;
                &:before{
                    content: '';
                    display: block;
                    background-color: #4d4d4f;
                    border-radius: 50%;
                    height: 0.4rem;
                    width: 0.4rem;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
`
export const RoadtabPanelCard = styled.div`
    max-width: 28rem;
    min-width:280px;
    border-radius: 0.4rem;

    @media(max-width: 740px){
        max-width: 100%;
    }

    @media(max-width:400px){
        min-width:100%;
    }
    &.green{
        background: #F2F9F8;
        ${RoadTabPanelHeader}{
            background: #6AC0AC;
        }
        ${RoadTabPanelBody}{
            .list{
                border-color: #45B097;
            }
        }
    }
    &.orange{
        background: #FFF9F6;
        ${RoadTabPanelHeader}{
            background: #F69D7F;
        }
        ${RoadTabPanelBody}{
            .list{
                border-color: #FDA982;
            }
        }
    }
    &.blue{
        background: #EEF7FB;
        ${RoadTabPanelHeader}{
            background: #41A1D5;
        }
        ${RoadTabPanelBody}{
            .list{
                border-color: #118ACB;
            }
        }
    }
`


// masterplan 

export const MasterPlanWrapper = styled.div`
    padding: 3.2rem 3.2rem 0 3.2rem;
    @media(max-width: 991px){
        display: flex;
    }
    @media(max-width: 740px){
        padding: 1.6rem 1.6rem 0 1.6rem;
    }
`
export const MasterPlanTable = styled.div`
    border-radius: 0.4rem 0.4rem 0px 0px;
    background: #fff;
    box-shadow: 0px 0px 0.4rem 0px rgba(21, 27, 38, 0.12);
    border: 0.1rem solid #E5F0FF;
    @media(max-width: 991px){
        width: 100%;
        overflow-x: auto;
    }
    @media(max-width: 1024px){
        display: none;
    }
`
export const MasterPlanHead = styled.div`
    display: flex;
    background: #E5F0FF;
    @media(max-width: 991px){
        width: 900px;
    }

    .common-col{
        text-align: center;
        padding: 0.8rem 0;
        border-right: 0.4rem solid #fff;
        .title{
            color: #0D2C54;
            display: block;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 2rem; /* 85.714% */
            width: 100%;
        }
        &:last-child{
            border-right: 0;
        }
        &::after {
            content: '';
            flex: 1;
        }
    }
    .sn-col{
        display: flex;
        align-items: center;
        border-radius: 0.4rem 0px 0px 0px;
        width: 8rem;
    }
    .step-col{
        display: flex;
        align-items: center;
        flex: 1;
    }
    .year-col{
        width: 16rem;
        .title{
            padding-bottom: 0.7rem;
        }
        .quarter-row{
            display: flex;
            border-top: 0.4rem solid #fff;
            margin-bottom: -0.8rem;
            .quarter-col{
                border-right: 0.1rem solid #fff;
                color: #0D2C54;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 2rem;
                padding: 0.8rem 0;
                width: 25%;
                &:last-child{
                    border-right: 0;
                }
            }
        }
    }

`
export const MasterPlanBody = styled.div`
    display: flex;
    background: #fFF;
    flex-direction: column;
    @media(max-width: 991px){
        width: 900px;
    }
    .masterplan-row{
        display: flex;
        border-bottom: 0.1rem solid #E5F0FF;
        width: 100%;
        .common-col{
            text-align: left;
            border-right: 0.4rem solid #E5F0FF;
            .sub-title{
                color: #4D4D4F;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 2rem; /* 85.714% */
                width: 100%;
            }
            &:last-child{
                border-right: 0;
            }
            &::after {
                content: '';
                flex: 1;
            }
        }
        .sn-col{
            display: flex;
            align-items: center;
            border-radius: 0.4rem 0px 0px 0px;
            width: 8rem;
            .sub-title{
                text-align: center;
            }
        }
        .step-col{
            display: flex;
            align-items: center;
            padding: 0.2rem 1.2rem;
            flex: 1;
        }
        .year-col{
            width: 16rem;
            .title{
                padding-bottom: 0.7rem;
            }
            .quarter-row{
                display: flex;
                height: 100%;
                .quarter-col{
                    border-right: 0.1rem solid #E5F0FF;
                    color: #0D2C54;
                    font-size: 1.4rem;
                    font-weight: 500;
                    line-height: 2rem;
                    padding: 1.2rem 0;
                    display: flex;
                    width: 25%;
                    align-items: center;
                    justify-content: center;
                    &:last-child{
                        border-right: 0;
                    }
                    .background-div{
                        display: block;
                        border-radius: 0.2rem;
                        background: #45B097;
                        height: 1.6rem;
                        width: 1.6rem;
                    }
                }
            }
        } 
    }
`

export const MasterPlanCard = styled.div`
    display: none;
    @media(max-width: 1024px){
        display: block;
        padding-bottom: 50px;
        width: 100%;
        .masterplan-row{
            display: flex;
            flex-direction: column;
            margin-bottom: 0.8rem;
            .masterplan-card{
                .card-title{
                    border-radius: 0.4rem 0.4rem 0px 0px;
                    border: 0.1rem solid #E5F0FF;
                    background: #E5F0FF;
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                    padding: 1.5rem 0.8rem 1.5rem 1.6rem;
                    span{
                        color: #0D2C54;
                        display: inline-block;
                        font-size: 1.4rem;
                        font-weight: 500;
                        line-height: 20px; /* 85.714% */
                        flex: 1;
                    }
                    i{
                        display: inline-block;
                        height: 2.4rem;
                        width: 2.4rem;
                        &.active{
                            svg{
                                transform: rotate(180deg);
                            }
                        }
                        svg{
                            transition: 0.25s all ease-in-out;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
                .year-card-row{
                    display: flex;
                    flex-wrap: wrap;
                    background: #fff;
                    border: 0.1rem solid #E5F0FF;
                    border-radius: 0 0 0.4rem 0.4rem;
                    margin: 0;
                    padding: 0.8rem;
                    .year-card-col{
                        flex: 0 0 33%;
                        max-width: 33%;
                        padding: 0 0.4rem;
                        margin-bottom: 0.8rem;
                        @media(max-width: 500px){
                            flex: 0 0 50%;
                            max-width: 50%;
                        }
                        @media(max-width: 360px){
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                        &:last-child{
                            margin: 0;
                        }
                        .year-card{
                            background: #E5F0FF;
                            .title{
                                color: #0D2C54;
                                display: block;
                                padding: 0.8rem 0;
                                text-align: center;
                                font-size: 1.4rem;
                                font-weight: 500;
                                line-height: 12px; /* 85.714% */
                                background: #E5F0FF;
                                border-bottom: 0.4rem solid #fff;
                            }
                            .quarter-row{
                                display: flex;
                                height: 100%;
                                &.first{
                                    border-bottom: 0.1rem solid #fff;
                                }
                                .quarter-col{
                                    border-right: 0.1rem solid #FFF;
                                    color: #0D2C54;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 1.4rem;
                                    font-weight: 500;
                                    line-height: 2rem;
                                    min-height: 4rem;
                                    padding: 1.2rem 0.5rem;
                                    width: 25%;
                                    &:last-child{
                                        border-right: 0;
                                    }
                                    .background-div{
                                        display: block;
                                        border-radius: 0.2rem;
                                        background: #45B097;
                                        height: 15px;
                                        width: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const ManuPopWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.30);
    backdrop-filter: blur(2px);
    z-index: 100;
`
export const ManuPopWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 0.6rem;
    border: 0.1rem solid #118ACB;
    background: #FFF; 
    box-shadow: 0px 0px 8px 0px rgba(21, 27, 38, 0.12);
`
