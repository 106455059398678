/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * Helmet ===> for add specific title & CSS for the page.

**/


import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import serviceProvider from '../../services/axios'
import { Helmet } from "react-helmet";
//MLWalkthroughQuesView start
export const MLWalkthroughQuesView = (props) => {
    const { activeSlide, setActiveSlide } = props;
    const group_id = users ? users.group_id:''; /*if 14 it's simple user else 2 or 11 for admin*/
    const users = JSON.parse(localStorage.getItem("userData"));
    const UserName = users ? users.name : "First Name";
    const uid = users ? users.uid : "";
    //function to close walkthrough
    const handleClose = () => {
        var close = document.querySelector('.div-z99');
        var close2 = document.querySelector('.prod-5');
        var findClass = document.querySelector('.maintenance-losses').contains(close);
        var findClass1 = document.querySelector('.training-content').contains(close2);
        var closePop = document.querySelector('.right-sidebar .close-btn');
        if (findClass == true) {
            close.classList.remove('div-z99');
        }
        if (findClass1 == true) {
            close2.classList.remove('prod-5');
        }
        if (findClass == true) {
            close.classList.remove('prod-1');
        }
        serviceProvider
            .post(
                "/home?type=setlineEfftour",
                JSON.stringify({ uid: uid, kpl: 'kpl', sid: props.sid }),
                true
            )
            .then((response) => {
                setActiveSlide(null);
                if (closePop != null) {
                    closePop.click();
                }
            });

    }
    //function to handle slider
    const HandleSlider = (val) => {
        setActiveSlide(val);
    }
    useEffect(() => {
        // var div = document.querySelector('.la-ques-button-group .button-wrap');
        var activeGreen = document.querySelector('.submitted-scnv-2126');
        // var span = document.querySelector('.la-ques-wrapper').contains(div);
        var submitData = document.querySelector('.maintenance-losses').contains(activeGreen);
        var openPop = document.querySelector('.side-slid-icon-button .info');
        var closePop = document.querySelector('.right-sidebar .close-btn');

        if (submitData === true && group_id==11) {
            setActiveSlide(null);
        }
        else {
            if (activeSlide === 1) {
            }
            if (activeSlide === 2) {
                let prod1= document.querySelectorAll('.prod-1');
                   prod1?.forEach((item)=>{
                        item?.classList.add('div-z99');
                   });
            }
            else {
                let prod1= document.querySelectorAll('.prod-1');
                   prod1?.forEach((item)=>{
                        item?.classList.remove('div-z99');
                   });
            }
            if (activeSlide === 3) {
                document.querySelector('.prod-2')?.classList.add('div-z99');
            }
            else {
                document.querySelector('.prod-2')?.classList.remove('div-z99');
            }
            if (activeSlide === 4) {
                document.querySelector('.prod-3')?.classList.add('div-z99');
            }
            else {
                document.querySelector('.prod-3')?.classList.remove('div-z99');
            }
            if (activeSlide === 5) {
                document.querySelector('.prod-4')?.classList.add('div-z99');
            }
            else {
                document.querySelector('.prod-4')?.classList.remove('div-z99');
            }
            if (activeSlide === 6) {
                document.querySelector('.training-content')?.classList.add('prod-5');
                openPop?.click();
            }
            else {
                document.querySelector('.training-content')?.classList.remove('prod-5');
                closePop?.click();
            }
            if (activeSlide === 7) {
                document.querySelector('.prod-6')?.classList.add('div-z99');
                document.querySelector('.submitted-scnv-2126')?.classList.add('div-z99');
            }
            else {
                document.querySelector('.prod-6')?.classList.remove('div-z99');
                document.querySelector('.submitted-scnv-2126')?.classList.remove('div-z99');
            }

        }

    }, [activeSlide]);


    useEffect(()=>{
    var scrollDiv=document.querySelector('.maintenance-losses .maintenance-losses-mid-wrapper');
    if(scrollDiv){
      scrollDiv.style.overflow='hidden';
    }
    return()=>{
      if(scrollDiv){
        scrollDiv.style.overflow='';
      }
    }
  },[]);
    return (
        <>
            {/* link css */}
            <Helmet>
                <link rel="stylesheet" href={require('./maintenance-losses-walkthrough.scss').default} />
            </Helmet>
            {/* link css end*/}
            {/* walkthrough main wrapper */}
            <ShowContent active={activeSlide} item={1}>
                <div className="walkthrough first">
                    <div className="confirmation-form">
                        <div className="submit-form step-1">
                            {/* walkthrough wrapper divided in two parts */}
                            <div className="submit-content">
                                {/* left part is containing gif image related to walkthrough step. */}
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-1.gif').default} alt="Step-1" /></div>
                                {/* right part has 
                                    heading , 
                                    text and  
                                    buttons navigate from one to next, next to previous and close. */}
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>1 of 7</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name name-class"><p >Hi {UserName},</p></div>
                                    <p className="text">Welcome to the Industry 4.0 Loss Analysis.​</p>
                                    <p className="txt"> We want to quickly show you the IR4.0 Loss Analysis data collection.​</p>
                                    <p className="text">You can skip at any time.​​</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button onClick={() => handleClose()} className="prev-link link">No thanks</button>
                                                <button className="let link solid" onClick={() => setActiveSlide(2)}>Let's go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={2}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk1">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-3.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>2 of 7</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Reason Codes</p></div>
                                    <p className="text">Here we can see the most common activities performed by the department employees.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(1)}>Previous</button>
                                                <button className="link solid" onClick={() => setActiveSlide(3)}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={3}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk2">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/maintenance-losses-walkthrough/key-step3.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>3 of 7</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Defining the % Ratios</p></div>
                                    <p className="text">In these cells you can specify the % split in activities of the department employees.</p>
                                    <p className="text">Your input is automatically saved.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(2)}>Previous</button>
                                                <button className="link solid" onClick={() => setActiveSlide(4)}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={4}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk3">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/maintenance-losses-walkthrough/key-step4.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>4 of 7</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Check 100%</p></div>
                                    <p className="text">Your input needs to add to 100%.</p>
                                    <p className="text">If not, you will not be able to submit the data.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <div className="btn-wrap">
                                                    <button className="link" onClick={() => setActiveSlide(3)}>Previous</button>
                                                    <button className="link solid" onClick={() => setActiveSlide(5)}>Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={5}>
                <div className="walkthrough">
                    <div className="confirmation-form">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/maintenance_losses/data-unavailable.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>5 of 7</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Data Not Available</p></div>
                                    <p className="text">If you don't collect data you can declare that from the dedicated interfaces.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <div className="btn-wrap">
                                                    <button className="link" onClick={() => setActiveSlide(4)}>Previous</button>
                                                    <button className="link solid" onClick={() => setActiveSlide(6)}>Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={6}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk4">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/maintenance-losses-walkthrough/key-step6.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>6 of 7</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Help</p></div>
                                    <p className="text">You can click the i icon for more information.​</p>
                                    <p className="text">If that is not enough, you can select the support button.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <div className="btn-wrap">
                                                    <button className="link" onClick={() => setActiveSlide(5)}>Previous</button>
                                                    <button className="link solid" onClick={() => setActiveSlide(7)}>Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={7}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk5">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/maintenance-losses-walkthrough/key-step7.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>7 of 7</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/general-info-walkthrough/close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Submit Data</p></div>
                                    <p className="text">Following the completion of the section, submit your data</p>
                                    <p className="text">After submission you will not be able to edit the section.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <div className="btn-wrap">
                                                    <button className="link" onClick={() => setActiveSlide(6)}>Previous</button>
                                                    <button className="link solid let-start" onClick={() => handleClose()}>Let's Start</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            {/* la-ques-wrapper end */}
        </>
    )

}

//function to show content
const ShowContent = (props) => {
    return (
        <>
            {props.active === props.item &&
                <>
                    {props.children}
                </>
            }
        </>
    )
}