/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  *  * as d3 ==> all components from d3 to create graphs
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Collapse, Modal } from "@material-ui/core";
import { Helmet } from "react-helmet";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import $, { isEmptyObject } from "jquery";
import "./limited-result.scss";
//LimitedResultView component start
export const LimitedResultView = (props) => {
  //defining varaibles here
  const resultsDataItems = props.resultsData;
  if (!resultsDataItems.length) {
    return null;
  }
  const resultsDataItem = props.resultsData[0];
  const fof_score = resultsDataItem.fof_score;
  const [firstTopTab, setFirstTopTab] = useState(1);
  const [graphTab, setGraphTab] = useState(1);
  const [rsPopup, setRsPopup] = useState(null);
  //function to handle first tab
  const HandleFirstTab = (val) => {
    setFirstTopTab(val);
  };
  //function to handle graph tab
  const HandleGraphtTab = (val) => {
    setGraphTab(val);
    //drawChart();
  };

  // var parser = "";
  // var xmlDoc ="";

  // const [count, setCount] = useState(1);
  // const [temp, setTemp] = useState(0);

  // const indexscore = resultsDataItem.indexscore;
  // const lowestscore = resultsDataItem.lowestscore;
  // const avgscore = resultsDataItem.avgscore;
  // const bestscore = resultsDataItem.bestscore;

  const truetheme = resultsDataItem.truethemes;
  const flasetheme = resultsDataItem.falsethemes;
  const [readmoreObj, setReadmoreObj] = useState({});
  const [isUpgradePopup, setIsUpgradePopup] = useState(false);
  // const [isLnrCollapse, setIsLnrCollapse] = useState(true);
  //function to show/hide upgradepopup
  const HandleUpdgradePopup = () => {
    setIsUpgradePopup(!isUpgradePopup);
  };
  // chart code started
  //function for draw chart
  const drawChart = () => {
    var dmwidth = 0;
    var paystatus = 1;
    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }
    function roundedRect(x, y, w, h, r, tl, tr, bl, br) {
      var retval;
      retval = "M" + (x + r) + "," + y;
      retval += "h" + (w - 2 * r);
      if (tr) {
        retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r;
      } else {
        retval += "h" + r;
        retval += "v" + r;
      }
      retval += "v" + (h - 2 * r);
      if (br) {
        retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
      } else {
        retval += "v" + r;
        retval += "h" + -r;
      }
      retval += "h" + (2 * r - w);
      if (bl) {
        retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
      } else {
        retval += "h" + -r;
        retval += "v" + -r;
      }
      retval += "v" + (2 * r - h);
      if (tl) {
        retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
      } else {
        retval += "v" + -r;
        retval += "h" + r;
      }
      retval += "z";
      return retval;
    }
    const sample = [
      {
        tech: "360° Factory Visibility",
        id: "ddata-1",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Automated Material Flow",
        id: "ddata-2",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Data-driven Maintenance",
        id: "ddata-3",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Digital-ready Infrastructure",
        id: "ddata-4",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Intelligent Quality",
        id: "ddata-5",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Sustainability",
        id: "ddata-7",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Tech-augmented Workforce",
        id: "ddata-8",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Zero Touches Production",
        id: "ddata-10",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Digital Organization",
        id: "ddata-11",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Factory of the Future Leadership",
        id: "ddata-12",
        value: fof_score,
        color: "#0B8ED1",
      },
    ];

    const svgContainer = d3
      .select("#bchart")
      .html("")
      .append("svg")
      .attr("class", "svg");
    const margin = 60;
    //const width = 1100 - 2 * 40;
    const width = parseInt(d3.select("#bchart").style("width")) - 2 * 40;
    const height = 400 - 2 * margin;
    var tooltip = d3.select("body").append("div").attr("class", "dtoolTip");
    const chart = svgContainer
      .append("g")
      .attr("transform", `translate(40, 50)`);

    const x = d3
      .scaleBand()
      .range([0, width])
      .paddingInner(0.1)
      .paddingOuter(0.3);

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(sample.map((s) => s.tech))
      .padding(0.4);

    dmwidth = xScale.bandwidth();
    dmwidth = Math.round(dmwidth);

    const yScale = d3.scaleLinear().range([height, 0]).domain([0, 100]);

    const makeYLines = () => d3.axisLeft().scale(yScale);

    const datasetavg = [
      ["360° Factory Visibility", "14"],
      ["Automated Material Flow", "11"],
      ["Data-driven Maintenance", "19"],
      ["Digital-ready Infrastructure", "10"],
      ["Intelligent Quality", "14"],
      ["Sustainability", "16"],
      ["Tech-augmented Workforce", "11"],
      ["Zero Touches Production", "16"],
      ["Digital Organization", "17"],
      ["Factory of the Future Leadership", "32"],
    ];

    const datasetbic = [
      ["360° Factory Visibility", "63"],
      ["Automated Material Flow", "33"],
      ["Data-driven Maintenance", fof_score],
      ["Digital-ready Infrastructure", "44"],
      ["Intelligent Quality", "41"],
      ["Sustainability", "66"],
      ["Tech-augmented Workforce", "44"],
      ["Zero Touches Production", "53"],
      ["Digital Organization", "47"],
      ["Factory of the Future Leadership", "68"],
    ];

    const sgrad = chart
      .append("defs")
      .append("linearGradient")
      .attr("id", "myGradientl")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    sgrad
      .append("stop")
      .attr("offset", "10%")
      .attr("stop-color", "rgba(19, 156, 214, 0)");
    sgrad
      .append("stop")
      .attr("offset", "80%")
      .attr("stop-color", "rgba(19, 156, 214, 0.1)");
    var wrapSize = 90;
    var toppx = 190;
    if (width < 790 && width > 600) {
      wrapSize = 70;
    } else if (width < 600 && width > 450) {
      wrapSize = 50;
    } else if (width > 1365 && width < 2200) {
      wrapSize = 120;
      toppx = 140;
    } else if (width > 2200) {
      toppx = 150;
    }

    chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(xScale))
      .selectAll(".tick text")
      .attr("class", function (d, i) {
        return "xlablel xt" + sample[i].id;
      })
      .call(wrap, wrapSize);

    chart
      .append("g")
      .attr("class", "y axis")
      .call(
        d3
          .axisLeft(yScale)
          .ticks(1)
          .tickFormat((d) => d + "%")
      );

    chart
      .append("g")
      .attr("class", "grid")
      .call(makeYLines().tickSize(-width, 0, 0).tickFormat(""));

    var darea = d3
      .area()
      .x(function (d) {
        return xScale(d[0]) + xScale.bandwidth() / 2;
      })
      .y0(yScale(0))
      .y1(function (d) {
        return yScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    chart
      .append("g")
      .append("path")
      .attr("fill", "url('#myGradientl')")
      .attr("class", "dash-line-area") // Assign a class for styling
      .attr("d", darea(datasetbic)); // 11. Calls the line generator

    const barGroups = chart.selectAll().data(sample).enter().append("g");

    barGroups
      .append("path")
      .attr("class", (g) => "bar ibar " + g.id + " s" + g.id)
      .attr("d", function (g) {
        if (g.value == 0) {
          return roundedRect(
            xScale(g.tech),
            yScale(g.value),
            xScale.bandwidth(),
            height - yScale(g.value),
            0,
            0,
            0
          );
        } else {
          return roundedRect(
            xScale(g.tech),
            yScale(g.value),
            xScale.bandwidth(),
            height - yScale(g.value),
            5,
            5,
            5
          );
        }
      })
      .style("fill", (g) => g.color)
      .on("mouseenter", function (d, i, n) {
        console.log(i);
        if (i.value != 0) {
          console.log(i.value);
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          tooltip
            .style("left", d.screenX - 50 + "px")
            .style("top", d.screenY - toppx + "px")
            .style("display", "inline-block")
            .html("<strong>" + i.tech + "</strong><br>" + "Click for details");
        } else {
          console.log(i.tech);
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          tooltip
            .style("left", d.screenX - 50 + "px")
            .style("top", d.screenY - toppx + "px")
            .style("display", "inline-block")
            .html(
              "Buy the Full Assessment to<br>" + "see the complete results"
            );
        }
      })
      .on("mouseleave", function () {
        d3.selectAll(".ibar").attr("opacity", 1);
        tooltip.style("display", "none");
      })
      .on("click", function (d, i, n) {
        if (paystatus != 3 || d.value != 0.0) {
          d3.selectAll(".ibar").attr("opacity", 1);
          d3.selectAll(".ibar").style("fill", "#129ad5");
          d3.select(".s" + d.id).style("fill", "#0B8ED1");
          d3.select(".g" + d.id).style("fill", "#0B8ED1");
          d3.selectAll(".ival").style("fill", "#B6B6B6");
          d3.selectAll(".xlablel").style("fill", "#7D868C");
          d3.selectAll(".t" + d.id).style("fill", "#000000");
          d3.selectAll(".xt" + d.id).style("fill", "#000000");
          $(".assess-modal").hide();
          $(".assess-" + i.id).slideDown();
        }
      });

    var line = d3
      .line()
      .x(function (d, i) {
        return xScale(d[0]) + xScale.bandwidth() / 2;
      })
      .y(function (d) {
        return yScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    chart
      .append("g")
      .append("path")
      .attr("class", "line") // Assign a class for styling
      .attr("d", line(datasetavg)); // 11. Calls the line generator

    var dline = d3
      .line()
      .x(function (d, i) {
        return xScale(d[0]) + xScale.bandwidth() / 2;
      })
      .y(function (d) {
        return yScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    chart
      .append("g")
      .append("path")
      .attr("class", "dash-line") // Assign a class for styling
      .attr("d", dline(datasetbic)); // 11. Calls the line generator

    const textGroups = chart.selectAll().data(sample).enter().append("g");

    textGroups
      .append("text")
      .attr("class", (a) => "value ival t" + a.id)
      .attr("x", (a) => xScale(a.tech) + xScale.bandwidth() / 2)
      .attr("y", (a) => yScale(a.value) - 10)
      .attr("text-anchor", "middle")
      .text((a) => (a.value == "0" ? `N/A` : `${a.value}%`));

    const svgcont = document.querySelector(".svg");
    const bar = document.querySelector(".bar");

    $(document).on("click", ".assess-close, .res-tab-btn2", function (e) {
      d3.selectAll(".ibar").style("fill", "#129ad5");
      d3.selectAll(".ival").style("fill", "#000000");
      d3.selectAll(".xlablel").style("fill", "#7D868C");
      $(".assess-modal").hide();
    });

    // Global Chart
    const gsample = [
      {
        tech: "360° Factory Visibility",
        id: "ddata-1",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Automated Material Flow",
        id: "ddata-2",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Data-driven Maintenance",
        id: "ddata-3",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Digital-ready Infrastructure",
        id: "ddata-4",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Intelligent Quality",
        id: "ddata-5",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Sustainability",
        id: "ddata-7",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Tech-augmented Workforce",
        id: "ddata-8",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Zero Touches Production",
        id: "ddata-10",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Digital Organization",
        id: "ddata-11",
        value: "0",
        color: "#0B8ED1",
      },
      {
        tech: "Factory of the Future Leadership",
        id: "ddata-12",
        value: fof_score,
        color: "#0B8ED1",
      },
    ];

    const gsvgContainer = d3
      .select("#gchart")
      .html("")
      .append("svg")
      .attr("class", "svg");
    const gmargin = 60;
    //const width = 1100 - 2 * 40;
    const gwidth =
      parseInt(d3.select("#main_graph_div").style("width")) - 2 * 40;

    const gheight = 400 - 2 * gmargin;
    var tooltip = d3.select("#gchart").append("div").attr("class", "dtoolTip");
    const gchart = gsvgContainer
      .append("g")
      .attr("transform", `translate(40, 50)`);

    const gx = d3
      .scaleBand()
      .range([0, gwidth])
      .paddingInner(0.1)
      .paddingOuter(0.3);

    const gxScale = d3
      .scaleBand()
      .range([0, gwidth])
      .domain(gsample.map((s) => s.tech))
      .padding(0.4);

    const gyScale = d3.scaleLinear().range([gheight, 0]).domain([0, 100]);

    const gmakeYLines = () => d3.axisLeft().scale(gyScale);

    const gdatasetavg = [
      ["360° Factory Visibility", "47"],
      ["Automated Material Flow", "25"],
      ["Data-driven Maintenance", "27"],
      ["Digital-ready Infrastructure", "36"],
      ["Intelligent Quality", "32"],
      ["Sustainability", "38"],
      ["Tech-augmented Workforce", "16"],
      ["Zero Touches Production", "36"],
      ["Digital Organization", "34"],
      ["Factory of the Future Leadership", "31"],
    ];

    const gdatasetbic = [
      ["360° Factory Visibility", "67"],
      ["Automated Material Flow", "59"],
      ["Data-driven Maintenance", "72"],
      ["Digital-ready Infrastructure", "68"],
      ["Intelligent Quality", "68"],
      ["Sustainability", "73"],
      ["Tech-augmented Workforce", "46"],
      ["Zero Touches Production", "63"],
      ["Digital Organization", "79"],
      ["Factory of the Future Leadership", "80"],
    ];

    const gsgrad = gchart
      .append("defs")
      .append("linearGradient")
      .attr("id", "myGradientlg")
      .attr("x1", "0%")
      .attr("y1", "100%")
      .attr("x2", "0%")
      .attr("y2", "0%")
      .attr("spreadMethod", "pad");

    gsgrad
      .append("stop")
      .attr("offset", "10%")
      .attr("stop-color", "rgba(63, 222, 116, 0)");
    gsgrad
      .append("stop")
      .attr("offset", "80%")
      .attr("stop-color", "rgba(63, 222, 116, 0.1)");

    var wrapSize = 90;
    var toppx = 70;
    if (gwidth < 790 && gwidth > 600) {
      wrapSize = 70;
    } else if (gwidth < 600 && gwidth > 450) {
      wrapSize = 50;
    } else if (gwidth > 1365) {
      wrapSize = 120;
      toppx = 100;
    }

    gchart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${gheight})`)
      .call(d3.axisBottom(gxScale))
      .selectAll(".tick text")
      .attr("class", function (d, i) {
        return "xlablel xt" + gsample[i].id;
      })
      .call(wrap, wrapSize);

    gchart
      .append("g")
      .attr("class", "y axis")
      .call(
        d3
          .axisLeft(gyScale)
          .ticks(1)
          .tickFormat((d) => d + "%")
      );

    gchart
      .append("g")
      .attr("class", "grid")
      .call(gmakeYLines().tickSize(-gwidth, 0, 0).tickFormat(""));

    var darea = d3
      .area()
      .x(function (d) {
        return gxScale(d[0]) + gxScale.bandwidth() / 2;
      })
      .y0(gyScale(0))
      .y1(function (d) {
        return gyScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    gchart
      .append("g")
      .append("path")
      .attr("fill", "url('#myGradientlg')")
      .attr("class", "dash-line-area") // Assign a class for styling
      .attr("d", darea(gdatasetbic)); // 11. Calls the line generator

    const gbarGroups = gchart.selectAll().data(gsample).enter().append("g");

    gbarGroups
      .append("path")
      .attr("class", (g) => "bar ibar " + g.id + " g" + g.id + " vk-" + g.value)
      .attr("d", function (g) {
        if (g.value == 0) {
          return roundedRect(
            xScale(g.tech),
            yScale(g.value),
            xScale.bandwidth(),
            height - yScale(g.value),
            0,
            0,
            0
          );
        } else {
          return roundedRect(
            xScale(g.tech),
            yScale(g.value),
            xScale.bandwidth(),
            height - yScale(g.value),
            5,
            5,
            5
          );
        }
      })
      .style("fill", (g) => g.color)
      .on("mouseenter", function (d, i, n) {
        let bar21 = d3.select(this).node().getBBox();
        // console.log(bar21,d);
        // console.log('barW',bar21.width,'barH',bar21.height);
        console.log(i);
        if (i.value != 0 || i.id == "ddata-12") {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          tooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html("<strong>" + i.tech + "</strong><br>" + "Click for details");
        } else {
          d3.selectAll(".ibar").attr("opacity", 0.5);
          d3.select(this).attr("opacity", 1);
          tooltip
            .style("left", bar21.x + "px")
            .style("display", "inline-block")
            .html(
              "Buy the Full Assessment to<br>" + "see the complete results"
            );
        }
      })
      .on("mouseleave", function () {
        d3.selectAll(".ibar").attr("opacity", 1);
        tooltip.style("display", "none");
      })
      .on("click", function (d, i, n) {
        if (paystatus != 3 || d.value != 0.0) {
          d3.selectAll(".ibar").attr("opacity", 1);
          d3.selectAll(".ibar").style("fill", "#129ad5");
          d3.select(".s" + d.id).style("fill", "#0B8ED1");
          d3.select(".g" + d.id).style("fill", "#0B8ED1");
          d3.selectAll(".ival").style("fill", "#B6B6B6");
          d3.selectAll(".xlablel").style("fill", "#7D868C");
          d3.selectAll(".t" + d.id).style("fill", "#000000");
          d3.selectAll(".xt" + d.id).style("fill", "#000000");
          $(".assess-modal").hide();
          $(".assess-" + i.id).slideDown();
          $(".training-content").animate(
            {
              scrollTop: $(".assess-" + i.id).offset().top,
            },
            "slow"
          );
        }
      });

    var line = d3
      .line()
      .x(function (d, i) {
        return gxScale(d[0]) + gxScale.bandwidth() / 2;
      })
      .y(function (d) {
        return gyScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    gchart
      .append("g")
      .append("path")
      .attr("class", "line gline") // Assign a class for styling
      .attr("d", line(gdatasetavg)); // 11. Calls the line generator

    var dline = d3
      .line()
      .x(function (d, i) {
        return gxScale(d[0]) + gxScale.bandwidth() / 2;
      })
      .y(function (d) {
        return gyScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    gchart
      .append("g")
      .append("path")
      .attr("class", "gdash-line") // Assign a class for styling
      .attr("d", dline(gdatasetbic)); // 11. Calls the line generator

    const gtextGroups = gchart.selectAll().data(gsample).enter().append("g");

    gtextGroups
      .append("text")
      .attr("class", (a) => "value ival t" + a.id)
      .attr("x", (a) => gxScale(a.tech) + gxScale.bandwidth() / 2)
      .attr("y", (a) => gyScale(a.value) - 10)
      .attr("text-anchor", "middle")
      .text((a) =>
        a.value == "0"
          ? a.id != "ddata-12"
            ? `N/A`
            : `${a.value}%`
          : `${a.value}%`
      );

    const gsvgcont = document.querySelector(".svg");
    const gbar = document.querySelector(".bar");

    // $(document).on('click','.assess-close', function(e){
    //     d3.selectAll('.ibar').style("fill", '#0B8ED1')
    //     d3.selectAll('.ival').style("fill", '#000000')
    //     $('.assess-modal').hide();
    // })

    $(".res-tab-btn2").click(function (e) {
      let tmptarget = e.target.getAttribute("data-target");
      //alert(tmptarget);
      if (tmptarget != "#seeBetweenCompany") {
        $(".res-tab-content2,.res-tab-btn2").removeClass("active");
        $(e.target).addClass("active");
        $(tmptarget).addClass("active");
        $(tmptarget + "-line").addClass("active");
      } else {
      }
    });

    // $(".res-tab-btn2").click(function (e) {
    // 	let tmptarget = e.target.getAttribute("data-target");
    // 	$(".res-tab-content2,.res-tab-btn2").removeClass("active");
    // 	$(e.target).addClass("active");
    // 	$(tmptarget).addClass("active");
    // 	$(tmptarget + "-line").addClass("active");
    // });
    $("#seeBetweenCompany").removeClass("active");
    $(document).on("click", ".bar-prev-btn", function (e) {
      var did = $(this).data("goto");
      console.log(did);
      d3.select(".ddata-" + did).dispatch("click");
    });
    $(document).on("click", ".bar-next-btn", function (e) {
      var did = $(this).data("goto");
      console.log(did);
      d3.select(".ddata-" + did).dispatch("click");
    });
    $(document).on("click", ".open-ct-pop", function (e) {
      var popid = $(this).attr("data-id");
      $(".menu-description").removeClass("des-show");
      $("#ct-pop-" + popid).addClass("des-show");
    });
    $(document).on("click", ".dimension_closebtn", function (e) {
      $(".menu-description").removeClass("des-show");
    });
  };
  // chart code end
  window.onclick = (e) => {
    if (!e.target.closest(".rs-chart-blocks")) {
      setRsPopup(null);
    }
  };
  const [newclass, setNewclass] = useState();
  const [image, setimage] = useState();
  const [iclass, seticlass] = useState();
  const [istyle, setistyle] = useState();
  const [c1class, setc1class] = useState();
  const [c2class, setc2class] = useState();
  const [c3class, setc3class] = useState();
  // console.log(istyle);
  // const newimage = require(`../../assets/img/result-tour/${image}`).default;
  // console.log(newimage);
  var parser = "";
  var xmlDoc = "";
  var p = "";
  var h3 = "";
  var li = "";
  const [count, setCount] = useState(1);
  const [temp, setTemp] = useState(0);
  const indexscore = resultsDataItem.indexscore;
  const lowestscore = resultsDataItem.lowestscore;
  const avgscore = resultsDataItem.avgscore;
  const bestscore = resultsDataItem.bestscore;

  useEffect(() => {
    drawChart();

    // const lowestscore = resultsDataItem.lowestscore;
    // side image and color
    if (indexscore < 25) {
      setimage(require("../../assets/img/result-tour/startup.svg").default);
      setNewclass("red");
    } else if (indexscore > 24 && indexscore < 50) {
      setimage(
        require("../../assets/img/result-tour/transforming.svg").default
      );
      setNewclass("orange");
    } else if (indexscore > 49 && indexscore < 75) {
      setimage(
        require("../../assets/img/result-tour/accelerating.svg").default
      );
      setNewclass("yellow");
    } else {
      setimage(require("../../assets/img/result-tour/thriving.svg").default);
      setNewclass("green");
    }
    // popup box
    if (indexscore <= 5) {
      seticlass("left");
      setistyle("calc(0.06vw * 10)");
    } else if (indexscore >= 95) {
      seticlass("right");
      setistyle("calc(0.06vw * 180)");
    } else {
      seticlass("mid");
      setistyle("calc(0.06vw * 76)");
    }
    // bottom range 1
    if (lowestscore < 25) {
      setc1class("red");
    } else if (lowestscore > 24 && lowestscore < 50) {
      setc1class("orange");
    } else if (lowestscore > 49 && lowestscore < 75) {
      setc1class("yellow");
    } else if (lowestscore > 74 && lowestscore < 85) {
      setc1class("green");
    } else {
      setc1class("green right");
    }
    // bottom range 2
    if (avgscore < 25) {
      setc2class("red");
    } else if (avgscore > 24 && avgscore < 50) {
      setc2class("orange");
    } else if (avgscore > 49 && avgscore < 75) {
      setc2class("yellow");
    } else if (avgscore > 74 && avgscore < 85) {
      setc2class("green");
    } else {
      setc2class("green right");
    }
    // bottom range 3
    if (bestscore < 25) {
      setc3class("red");
    } else if (bestscore > 24 && bestscore < 50) {
      setc3class("orange");
    } else if (bestscore > 49 && bestscore < 75) {
      setc3class("yellow");
    } else if (bestscore > 74 && bestscore < 85) {
      setc3class("green");
    } else {
      setc3class("green right");
    }
  }, []);

  return (
    <>
      {/* page title */}
      <Helmet>
        <title>Result | SmarterChains</title>
        <meta name="description" content="Result" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Assessment</BreadcrumbsItem>
      <BreadcrumbsItem to="/result">Explore Results</BreadcrumbsItem>
      {/*  result main wrapper */}
      <div className="result-2442021 rs-open">
        <div className="asst-abt-content-body result-page-body">
          <div className="row no-gutters px-0">
            <div className="col asst-abt-left">
              <div className="sec1">
                {/* heading */}
                <h1 className="res-h1 mb-0">Your Plant​</h1>
                <div className="d-flex sec-1-row flex-column">
                  {/* row has been divided into col */}
                  <div className="row no-gutters px-0">
                    <div className="col">
                      {/* col has heading */}
                      <h2 className="res-h2">Main Index Score</h2>
                    </div>
                    {/* col end */}
                    {/* col has buttons */}
                    <div className="col text-right">
                      <button
                        className={`res-tab-btn ${
                          firstTopTab === 0 ? " active" : ""
                        }`}
                        onClick={() => {
                          if (isUpgradePopup == false) {
                            HandleFirstTab(1);
                          } else {
                            HandleFirstTab(0);
                          }

                          HandleUpdgradePopup();
                        }}
                      >
                        Company benchmarking
                      </button>
                      <button
                        className={`res-tab-btn ${
                          firstTopTab === 1 ? " active" : ""
                        }`}
                        onClick={() => {
                          HandleFirstTab(1);
                        }}
                      >
                        Industry Benchmarking
                      </button>
                    </div>
                    {/* col end */}
                  </div>
                  {/* row end */}
                  <div
                    className={`res-tab-content ${
                      firstTopTab === 0 ? " active" : ""
                    }`}
                    id="nestChart"
                  >
                    <div className="row no-gutters res-mt-10">
                      <div id="rs-slide-2" className="rs-slide-2 res-indx-div">
                        <div className="res-percent-div gray">
                          <div className="row no-gutters px-0 align-items-center">
                            <div className="col">
                              <sapn className="indx-scr-txt">Index Score​</sapn>
                            </div>
                            <div className="col-1" />
                          </div>
                          {/* <p className="percent-text">N/A</p> */}
                          <p className="percent-text">
                            {indexscore > 0 ? indexscore + "%" : "N/A"}{" "}
                          </p>
                          <p className="percent-para">
                            {/* The Index Score represents the overall Industry 4.0 maturity of your plant.
														<span className="percent-para-blue">
															Your Index Score places your plant in the archetype of:
														</span> */}
                            Complete the Full Assessment to display your Index
                            Score, which represents the overall Industry 4.0
                            maturity of your plant.
                          </p>
                          <p className="percent-para-acc">
                            {/*<img src={require("../../assets/img/result-tour/transforming.svg").default} alt="true" />*/}
                            <Link
                              target="_blank"
                              to="/plans"
                              className="btn-get-asst"
                            >
                              Get your Full Assessment
                            </Link>
                          </p>
                        </div>
                      </div>
                      {/*res-indx-div end*/}
                      <div
                        id="rs-slide-3"
                        className="rs-slide-3 col nes-chart-sec"
                      >
                        <div className="nes-chart-content">
                          <div className="get-your-indx-box">
                            <p className="">Get Your Index Score</p>
                            <p className="blue-txt">
                              <Link
                                to="/plans"
                                target="_blank"
                                className="btn-get-asst"
                              >
                                + Buy Full Assessment
                              </Link>
                            </p>
                          </div>
                          <h2 className="col-12 px-0 nes-chart-title">
                            Company benchmarking
                          </h2>
                          <div className="d-flex position-relative w-100 res-p-13">
                            <div className="bar red">
                              <div className="rs-chart-blocks rs-startup">
                                {/* <a
																	href="#"
																	className={`rs-btn-lnk stratup-clk ${rsPopup === null ? "" : "disabled-click"}`}
																	onClick={() => setRsPopup(0)}
																> */}
                                <a
                                  href="#"
                                  className={`rs-btn-lnk stratup-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(0)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/startup-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop stratup-pop"
                                  style={{
                                    display:
                                      rsPopup === 0 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/startup-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Start Up</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Paper-based processes</li>
                                    <li>Islands of Automation</li>
                                    <li>Simple ERP functionalities</li>
                                    <li>Manual material handling</li>
                                    <li>Integration of key business systems</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#startup"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 1 end */}
                            <div className="bar orange">
                              <div className="rs-chart-blocks rs-transforming">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk transforming-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(1)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/transforming-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop transforming-pop"
                                  style={{
                                    display:
                                      rsPopup === 1 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/transforming-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Transforming</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Mostly automated production</li>
                                    <li>
                                      Vertical integrations of key systems
                                    </li>
                                    <li>
                                      Internet connectivity &amp; standard
                                      cybersecurity
                                    </li>
                                    <li>
                                      Just starting the Industry 4.0 journey
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#transforming"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 2 end */}
                            <div className="bar yellow">
                              <div className="rs-chart-blocks rs-accelerating">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk accelerating-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(2)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/accelerating-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop accelerating-pop"
                                  style={{
                                    display:
                                      rsPopup === 2 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/accelerating-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Accelerating</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Modern, cloud-based systems</li>
                                    <li>Early stages of advanced analytics</li>
                                    <li>Industry 4.0 roadmap in place</li>
                                    <li>
                                      Digital training plans for key roles
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#accelerating"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 3 end */}
                            <div className="bar green">
                              <div className="rs-chart-blocks rs-thriving">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk thriving-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(3)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/thriving-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop thriving-pop"
                                  style={{
                                    display:
                                      rsPopup === 3 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/thriving-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Thriving</span> archetype:
                                  </p>
                                  <ul>
                                    <li>
                                      Modular &amp; adaptive manufacturing
                                    </li>
                                    <li>Autonomous decision-making systems</li>
                                    <li>Horizontal value-chain integrations</li>
                                    <li>Automation of changeovers</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#thriving"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 4 end */}
                            {/* <div className="bottom-range red short" style={{ left: "3vw" }}>
															<div className="d-flex align-items-end red">
																Lowest:
																<span> 12%</span>
															</div>
														</div> */}
                            <div
                              className={`bottom-range ${c1class} short`}
                              style={{
                                left: `${lowestscore > 19 ? lowestscore : 19}%`,
                              }}
                            >
                              <div className="d-flex align-items-end red">
                                Lowest:
                                <span>
                                  {" "}
                                  {lowestscore > 19 ? lowestscore : 19}%
                                </span>
                              </div>
                            </div>
                            {/*bttom-range 1 end*/}
                            <div
                              className={`bottom-range ${c2class}`}
                              style={{
                                left: `${avgscore > 30 ? avgscore : 30}%`,
                              }}
                            >
                              <div className="d-flex align-items-end">
                                Average:
                                <span> {avgscore}%</span>
                              </div>
                            </div>
                            {/* <div className="bottom-range red" style={{ left: "30vw" }}>
															<div className="d-flex align-items-end">
																Average:
																<span> 45%</span>
															</div>
														</div> */}
                            {/*bttom-range 2 end*/}
                            <div
                              className={`bottom-range ${c3class} long`}
                              style={{ left: `${bestscore}%` }}
                            >
                              <div className="d-flex align-items-end">
                                Highest:
                                <span> {bestscore}%</span>
                              </div>
                            </div>
                            {/* <div className="bottom-range yellow long" style={{ left: "52vw" }}>
															<div className="d-flex align-items-end">
																Highest:
																<span> 82%</span>
															</div>
														</div> */}
                            <div
                              className={`show-progress ${iclass}`}
                              style={{
                                left: `calc(${indexscore}% - calc(0.06vw * 76))`,
                              }}
                            >
                              <p className="mb-0">
                                Your Company&nbsp;<span>{indexscore}%</span>
                              </p>
                              <div className="img-circle" />
                            </div>
                          </div>
                          {/* d-flex end */}
                        </div>
                        {/* nes  chart content end */}
                      </div>
                    </div>
                  </div>
                  {/*  nestle chart tab content end*/}
                  <div
                    className={`res-tab-content ${
                      firstTopTab === 1 ? " active" : ""
                    }`}
                    id="globalChart"
                  >
                    <div className="row no-gutters res-mt-10">
                      <div className="res-indx-div">
                        <div className="res-percent-div gray">
                          <div className="row no-gutters px-0 align-items-center">
                            <div className="col">
                              <sapn className="indx-scr-txt">Index Score​</sapn>
                            </div>
                            <div className="col-1" />
                          </div>
                          {/* <p className="percent-text">N/A</p> */}
                          <p className="percent-text">
                            {resultsDataItem.indexscore > 0
                              ? resultsDataItem.indexscore + "%"
                              : "N/A"}{" "}
                          </p>
                          <p className="percent-para">
                            {/* The Index Score represents the overall Industry 4.0 maturity of your plant.
														<span className="percent-para-blue">
															Your Index Score places your plant in the archetype of:
														</span> */}
                            Complete the Full Assessment to display your Index
                            Score, which represents the overall Industry 4.0
                            maturity of your plant.
                          </p>
                          <p className="percent-para-acc">
                            {/* <img src={require("../../assets/img/result-tour/transforming.svg").default} alt="true" /> */}
                            <Link
                              to="/plans"
                              target="_blank"
                              className="btn-get-asst"
                            >
                              Get your Full Assessment
                            </Link>
                          </p>
                        </div>
                      </div>
                      {/*res-indx-div end*/}
                      <div className="col nes-chart-sec">
                        <div className="nes-chart-content">
                          <div className="get-your-indx-box">
                            <p className="">Get Your Index Score</p>
                            <p className="blue-txt">
                              <Link
                                to="/plans"
                                target="_blank"
                                className="btn-get-asst"
                              >
                                + Buy Full Assessment
                              </Link>
                            </p>
                          </div>
                          <h2 className="col-12 px-0 nes-chart-title">
                            Industry Benchmarking
                          </h2>
                          <div className="d-flex position-relative w-100 res-p-13">
                            <div className="bar red">
                              <div className="rs-chart-blocks rs-startup">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk stratup-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(0)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/startup-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop stratup-pop"
                                  style={{
                                    display:
                                      rsPopup === 0 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/startup-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => {
                                        setRsPopup(null);
                                      }}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Start Up</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Paper-based processes</li>
                                    <li>Islands of Automation</li>
                                    <li>Simple ERP functionalities</li>
                                    <li>Manual material handling</li>
                                    <li>Integration of key business systems</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#startup"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 1 end */}
                            <div className="bar orange">
                              <div className="rs-chart-blocks rs-transforming">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk transforming-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(1)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/transforming-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop transforming-pop"
                                  style={{
                                    display:
                                      rsPopup === 1 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/transforming-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Transforming</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Mostly automated production</li>
                                    <li>
                                      Vertical integrations of key systems
                                    </li>
                                    <li>
                                      Internet connectivity &amp; standard
                                      cybersecurity
                                    </li>
                                    <li>
                                      Just starting the Industry 4.0 journey
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#transforming"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 2 end */}
                            <div className="bar yellow">
                              <div className="rs-chart-blocks rs-accelerating">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk accelerating-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(2)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/accelerating-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop accelerating-pop"
                                  style={{
                                    display:
                                      rsPopup === 2 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/accelerating-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Accelerating</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Modern, cloud-based systems</li>
                                    <li>Early stages of advanced analytics</li>
                                    <li>Industry 4.0 roadmap in place</li>
                                    <li>
                                      Digital training plans for key roles
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#accelerating"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 3 end */}
                            <div className="bar green">
                              <div className="rs-chart-blocks rs-thriving">
                                <a
                                  href="#"
                                  className={`rs-btn-lnk thriving-clk ${
                                    rsPopup === null ? "" : ""
                                  }`}
                                  onClick={() => setRsPopup(3)}
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/thriving-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop thriving-pop"
                                  style={{
                                    display:
                                      rsPopup === 3 ? "inline-block" : "none",
                                  }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/thriving-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a
                                      href="#"
                                      className="rs-startup-close"
                                      onClick={() => setRsPopup(null)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span> Thriving</span> archetype:
                                  </p>
                                  <ul>
                                    <li>
                                      Modular &amp; adaptive manufacturing
                                    </li>
                                    <li>Autonomous decision-making systems</li>
                                    <li>Horizontal value-chain integrations</li>
                                    <li>Automation of changeovers</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://b2b.smarterchains.com/digital-plant#thriving"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`bottom-range ${c1class} short`}
                              style={{
                                left: `${lowestscore > 19 ? lowestscore : 19}%`,
                              }}
                            >
                              <div className="d-flex align-items-end red">
                                Lowest:
                                <span>
                                  {" "}
                                  {lowestscore > 19 ? lowestscore : 19}%
                                </span>
                              </div>
                            </div>
                            {/* <div className="bottom-range red short" style={{ left: "19%" }}>
															<div className="d-flex align-items-end red">
																Lowest:
																<span> 19%</span>
															</div>
														</div> */}
                            {/*bttom-range 1 end*/}
                            <div
                              className={`bottom-range ${c2class}`}
                              style={{
                                left: `${avgscore > 33 ? avgscore : 33}%`,
                              }}
                            >
                              <div className="d-flex align-items-end">
                                Average:
                                <span> {avgscore > 33 ? avgscore : 33}%</span>
                              </div>
                            </div>
                            {/*bttom-range 2 end*/}
                            <div
                              className={`bottom-range ${c3class} long`}
                              style={{ left: `${bestscore}%` }}
                            >
                              <div className="d-flex align-items-end">
                                Highest:
                                <span> {bestscore}%</span>
                              </div>
                            </div>
                            {/* <div className="bottom-range yellow long" style={{ left: "67%" }}>
															<div className="d-flex align-items-end">
																Highest:
																<span> 67%</span>
															</div>
														</div> */}
                            {/*bttom-range 3 end*/}
                            {/* <div
															className="show-progress mid"
															style={{
																left: "calc(0% - calc(0.06vw * 76))",
															}}
														>
															<p className="mb-0">
																Your Company&nbsp;<span>0%</span>
															</p>
															<div className="img-circle" />
														</div> */}
                            <div
                              className={`show-progress ${iclass}`}
                              style={{
                                left: `calc(${indexscore}% - calc(0.06vw * 76))`,
                              }}
                            >
                              <p className="mb-0">
                                Your Company&nbsp;<span>{indexscore}%</span>
                              </p>
                              <div className="img-circle" />
                            </div>
                            {/* show -progress end */}
                          </div>
                          {/* d-flex end */}
                        </div>
                        {/* nes  chart content end */}
                      </div>
                    </div>
                  </div>
                  {/*  gloabl chart tab content end*/}
                </div>
                {/* flex  div end*/}
              </div>
              {/* sec 1 end */}
              <div className="sec1 allshow">
                <div
                  className="d-flex sec-1-row flex-column"
                  id="main_graph_div"
                >
                  {/* row has been divided into col */}
                  <div className="row no-gutters px-0 align-items-center">
                    {/* each col has text */}
                    <div className="col">
                      <h2 className="res-h2 mb-0">Index Per Dimension</h2>
                    </div>
                    {/* col end */}
                    <div
                      className={`col px-0 res-tab-content2 ${
                        graphTab === 0 ? " active" : ""
                      }`}
                      id="seeBetweenCompany-line"
                    >
                      <div className="d-flex align-items-center">
                        <div className="col px-0">
                          <span className="best-calss">
                            Company Best in Class
                          </span>
                        </div>
                        {/* col end */}
                        <div className="col px-0">
                          <span className="global-avg comp">
                            Company Average
                          </span>
                        </div>
                        {/* col end */}
                      </div>
                      {/* d flex end */}
                    </div>
                    {/* col end */}
                    <div
                      className={`col px-0 res-tab-content2 ${
                        graphTab === 1 ? " active" : ""
                      }`}
                      id="seeBetweenGlobal-line"
                    >
                      <div className="d-flex align-items-center">
                        <div className="col px-0">
                          <span className="best-calss gbest-calss">
                            Industry Best in Class
                          </span>
                        </div>
                        {/* col end */}
                        <div className="col px-0">
                          <span className="global-avg">Industry Average</span>
                        </div>
                        {/* col end */}
                      </div>
                      {/* d flex end */}
                    </div>
                    {/* col end */}
                    <div className="col text-right dual-tab">
                      <button
                        className={`res-tab-btn2 ${
                          graphTab === 0 ? " active" : ""
                        }`}
                        data-target="#seeBetweenCompany"
                        onClick={() => {
                          if (isUpgradePopup == false) {
                            HandleGraphtTab(1);
                          } else {
                            HandleGraphtTab(0);
                          }

                          HandleUpdgradePopup();
                        }}
                      >
                        See between Company
                      </button>
                      <button
                        className={`res-tab-btn2 ${
                          graphTab === 1 ? " active" : ""
                        }`}
                        onClick={() => HandleGraphtTab(1)}
                        data-target="#seeBetweenGlobal"
                      >
                        See between Industry
                      </button>
                    </div>
                    {/* col end */}
                  </div>
                  {/* row end */}

                  <div
                    className="res-tab-content2 active"
                    id="seeBetweenCompany"
                  >
                    <div
                      className="row no-gutters res-mt-10 px-0"
                      style={{ backgroundColor: "white" }}
                    >
                      <div id="bchart" className="rs-slide-4 bchart w-100 px-3">
                        {/*
                         */}
                      </div>
                    </div>
                  </div>
                  {/*  nestle chart tab content end*/}
                  <div
                    className="res-tab-content2 active"
                    id="seeBetweenGlobal"
                  >
                    <div
                      className="row no-gutters res-mt-10 px-0"
                      style={{ backgroundColor: "white" }}
                    >
                      <div id="gchart" className="rs-slide-4 bchart w-100 px-3">
                        {/*
                         */}
                      </div>
                    </div>
                  </div>
                  {/*  gloabl chart tab content end*/}
                </div>
                {/* flex  div end*/}
              </div>
              {/* sec 1 end */}
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-1"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    {/* this section has
                      col
                      col-1
                    */}
                    <div className="d-flex align-items-center">
                      {/* this section has text */}
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      {/* this section has close button */}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-2"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-3"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-4"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-5"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-6"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-7"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-8"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-9"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-10"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    {/* this section has
                      col
                      col-1
                    */}
                    <div className="d-flex align-items-center">
                      {/* this section has text */}
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      {/* this section has close button */}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-11"
                style={{ display: "none" }}
              >
                <div className="col-10-res">
                  <div className="res-popup-header res-popup-header-wtlink">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="acess-plan-p">
                          <Link target="_blank" to="/plans">
                            Buy the Full Assessment
                          </Link>{" "}
                          to see the complete results
                        </p>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-12"
                style={{ display: "none" }}
              >
                {/* Start overlay for Unlock full result feature  */}
                <div className="result-unlock-overlay-master">
                  <a href="#" className="result_call_book_btn r_unlock">
                    <img
                      src={
                        require("../../assets/img/limited-result/unlock.svg")
                          .default
                      }
                      className="result_call_book_icon"
                      alt
                    />
                    Unlock Full Results
                  </a>
                  <div className="result-unlock-overlay-call-btn-wrap">
                    <p className="result-unlock-overlay-head">
                      Contact us to get access to the full results
                    </p>
                    <a href="#" className="result_call_book_btn free_call">
                      <img
                        src={
                          require("../../assets/img/limited-result/call.svg")
                            .default
                        }
                        className="result_call_book_free_icon"
                        alt
                      />
                      Book 1h Free Consultation Call
                    </a>
                  </div>
                </div>
                {/* End overlay for Unlock full result feature  */}
                <div className="col-2-res sticky lr_blur">
                  <div className="s-result-cir-piechart">
                    <svg viewBox="0 0 36 36">
                      <path
                        class="circle-bg"
                        d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#F0F0F0"
                        stroke-width="3.4"
                        fill="none"
                      ></path>
                      <path
                        class="circle"
                        stroke-dasharray="33, 100"
                        d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="
 #118ACB"
                        stroke-width="3.4"
                        fill="none"
                      ></path>
                    </svg>
                    <div className="s-result-cir-div-txt">
                      <p>Yours</p>
                      <h1></h1>
                    </div>
                  </div>
                  <div className="s-result-cir-piechart">
                    <svg viewBox="0 0 36 36">
                      <path
                        class="circle-bg"
                        d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#F0F0F0"
                        stroke-width="3.4"
                        fill="none"
                      ></path>
                      <path
                        class="circle"
                        stroke-dasharray="38, 100"
                        d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#FFAB84"
                        stroke-width="3.4"
                        fill="none"
                      ></path>
                    </svg>
                    <div className="s-result-cir-div-txt">
                      <p>Industry Average</p>
                      <h1></h1>
                    </div>
                  </div>
                  <div className="s-result-cir-piechart">
                    <svg viewBox="0 0 36 36">
                      <path
                        class="circle-bg"
                        d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#F0F0F0"
                        stroke-width="3.4"
                        fill="none"
                      ></path>
                      <path
                        class="circle"
                        stroke-dasharray="86, 100"
                        d="M18 2.0845
 a 15.9155 15.9155 0 0 1 0 31.831
 a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#7FCDBB"
                        stroke-width="3.4"
                        fill="none"
                      ></path>
                    </svg>
                    <div className="s-result-cir-div-txt">
                      <p>Industry BIC</p>
                      <h1></h1>
                    </div>
                  </div>
                  {/* /Industry BIC */}
                </div>
                <div className="col-10-res lr_blur">
                  <div className="res-popup-header">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <h2 className="res-h2">
                          Factory of the Future Leadership
                        </h2>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                    {/* d-flex end */}
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="res-popup-para">
                          Behaviors and strategies that inspire, support and
                          accelerate the digital transformation across the
                          plant.
                        </p>
                      </div>
                      {/* <div className="col-1 px-0">
												<div className="d-flex res-nav-wrapper justify-content-end">
													<a className="bar-next-btn" data-goto={11} id="res-pre-btn" href="javascript:void(0)">
														<img
															src={require("../../assets/img/result-tour/res-next.svg").default}
															className="res-nav-pre"
															alt
														/>
													</a>
												</div>
											</div> */}
                    </div>
                  </div>
                  {/* header end*/}
                  <div className="res-popup-body">
                    <div className="col-12-res">
                      <div className="s-result-tab-content mb-44">
                        <div className="s-result-row">
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-tab">
                              <div
                                id="strengthHighlight"
                                className="s-result-link green active"
                                data-target="#sResultTab1"
                              >
                                STRENGTH
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div className="s-result-row mb-30">
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div className="s-result-left-content hideContent">
                                    <h1 className="s-result-card-h1">
                                      Industry 4.0 strategy is deployed across
                                      the site
                                    </h1>
                                    <div className="t_des_r ">
                                      <p>
                                        The site leadership team has built the
                                        site-specific strategy upon the
                                        company's strategy with clear
                                        progression steps and has incorporated
                                        it into the plant's vision.
                                      </p>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>
                                          Drive systemic progression and change
                                          management towards Industry 4.0
                                        </li>
                                        <li>
                                          Clear vision of the target set from
                                          the plant
                                        </li>
                                        <li>
                                          Inspiring the employees of the plant
                                          for improved efficiency
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <button data-id={0}>Read more</button>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-0">
                                    <div className="px-0 s-learn-more">
                                      <a href="" target="_blank">
                                        Learn more
                                      </a>
                                    </div>
                                    <div className="px-0 s-result-vendors-link">
                                      <a
                                        href=""
                                        className="float-right"
                                        target="_blank"
                                      >
                                        Discover Vendors
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    {" "}
                                    50%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Company Adoption
                                  </div>
                                  <div className="s-res-progress-bar yellow">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "50%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    {" "}
                                    16%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar red">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "16%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    {" "}
                                    80%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar green">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "80%" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="ln-dis-wrap rn-0"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a href="" target="_blank">
                                      Learn more
                                    </a>
                                  </div>
                                  <div className="px-0 s-result-vendors-link">
                                    <a
                                      href=""
                                      className="float-right"
                                      target="_blank"
                                    >
                                      Discover Vendors
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="s-result-tab-content mb-44">
                        <div className="s-result-row">
                          <div className="s-result-col-rgt">
                            <div className="s-result-tab">
                              <div
                                className="s-result-link blue active"
                                data-target="#sResultTab1"
                                id="strengthHighlight"
                              >
                                OPPORTUNITY
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div className="s-result-row mb-30">
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card blue">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div
                                    className="
                     s-result-left-content
                     hideContent
                     "
                                  >
                                    <h1 className="s-result-card-h1">
                                      Identified Industry 4.0 Opportunities
                                    </h1>
                                    <div className="t_des_r ">
                                      <p>
                                        The site's leadership team has
                                        discovered potential technological and
                                        organizational opportunities, that can
                                        be implemented to the plant, in order to
                                        lead its Industry 4.0 transformation.
                                      </p>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>Productivity improvements</li>
                                        <li>Optimized processes</li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <button data-id={0}>Read more</button>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-100">
                                    <div className="px-0 s-learn-more">
                                      <a href="" target="_blank">
                                        Learn more
                                      </a>
                                    </div>
                                    <div className="px-0 s-result-vendors-link">
                                      <a
                                        href=""
                                        className="float-right"
                                        target="_blank"
                                      >
                                        Discover Vendors
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    0%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Company Adoption
                                  </div>
                                  <div className="s-res-progress-bar red">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "0%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    35%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar orange">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "35%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    100%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar green">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="ln-dis-wrap rn-100"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a href="" target="_blank">
                                      Learn more
                                    </a>
                                  </div>
                                  <div className="px-0 s-result-vendors-link">
                                    <a
                                      href=""
                                      className="float-right"
                                      target="_blank"
                                    >
                                      Discover Vendors
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div className="s-result-row mb-30">
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card blue">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div
                                    className="
                     s-result-left-content
                     hideContent
                     "
                                  >
                                    <h1 className="s-result-card-h1">
                                      Digitization Masterplan
                                    </h1>
                                    <div className="t_des_r ">
                                      <p>
                                        Well-defined roles and responsibilities
                                        are very important for the successful
                                        design and implementation of a digital
                                        organization strategy.
                                      </p>
                                      <p>
                                        In order to exhibit the characteristics
                                        of digitization, time and energy have to
                                        be invested into some action pillars
                                        like:
                                      </p>
                                      <ul>
                                        <li>
                                          The prioritization digitization as a
                                          strategy
                                        </li>
                                        <li>
                                          The strengthening of people's
                                          capabilities and mindset
                                        </li>
                                        <li>
                                          The investment in Industry 4.0
                                          technologies
                                        </li>
                                        <li>Democratization of information</li>
                                      </ul>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>
                                          Reduced time spent managing data by
                                          eliminating redundancies and
                                          shortening communication chains
                                        </li>
                                        <li>
                                          Optimized processes by facilitating
                                          and improving information exchange
                                        </li>
                                        <li>
                                          Enhanced visibility by leveraging
                                          analytics and real-time data
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <button data-id={0}>Read more</button>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-100">
                                    <div className="px-0 s-learn-more">
                                      <a href="" target="_blank">
                                        Learn more
                                      </a>
                                    </div>
                                    <div className="px-0 s-result-vendors-link">
                                      <a
                                        href=""
                                        className="float-right"
                                        target="_blank"
                                      >
                                        Discover Vendors
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    0%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Company Adoption
                                  </div>
                                  <div className="s-res-progress-bar red">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "0%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    16%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar red">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "16%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    89%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar green">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "89%" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="ln-dis-wrap rn-100"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a href="" target="_blank">
                                      Learn more
                                    </a>
                                  </div>
                                  <div className="px-0 s-result-vendors-link">
                                    <a
                                      href=""
                                      className="float-right"
                                      target="_blank"
                                    >
                                      Discover Vendors
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div className="s-result-row mb-30">
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card blue">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div
                                    className="
                     s-result-left-content
                     hideContent
                     "
                                  >
                                    <h1 className="s-result-card-h1">
                                      Automation Masterplan
                                    </h1>
                                    <div className="t_des_r ">
                                      <p>
                                        An automation masterplan is all about
                                        seeing the big picture and realizing
                                        what the entire solution will look like.
                                      </p>
                                      <p>
                                        Automating material and data flow,
                                        material handling and processes, is a
                                        significant productivity increasing
                                        factor.
                                      </p>
                                      <p>
                                        The enabling technologies for automation
                                        could be Cobots, Autonomous Mobile
                                        Robots (AMRs), Automated Guided Vehicles
                                        (AGVs), Manufacturing Execution System
                                        (MES), etc. Advanced plants’ need for
                                        flexibility forces them to explore ways
                                        to increase automation of tasks such as
                                        changeovers, end-of-line packaging, etc.
                                      </p>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>Reduced time </li>
                                        <li>Optimized processes</li>
                                        <li>Better resource allocation</li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <button data-id={0}>Read more</button>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-100">
                                    <div className="px-0 s-learn-more">
                                      <a href="" target="_blank">
                                        Learn more
                                      </a>
                                    </div>
                                    <div className="px-0 s-result-vendors-link">
                                      <a
                                        href=""
                                        className="float-right"
                                        target="_blank"
                                      >
                                        Discover Vendors
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    0%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Company Adoption
                                  </div>
                                  <div className="s-res-progress-bar red">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "0%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    38%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar orange">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "38%" }}
                                    />
                                  </div>
                                </div>
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    100%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar green">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="ln-dis-wrap rn-100"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a href="" target="_blank">
                                      Learn more
                                    </a>
                                  </div>
                                  <div className="px-0 s-result-vendors-link">
                                    <a
                                      href=""
                                      className="float-right"
                                      target="_blank"
                                    >
                                      Discover Vendors
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*asst-abt-left end*/}
          </div>
          {/* row end */}
        </div>
        {/* asst content body end*/}
      </div>

      <Modal
        open={isUpgradePopup}
        // onClose={HandleUpdgradePopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="home-index-upgrade-popup"
      >
        {/* this section has
            close button
            popup image
            popup-body
        */}
        <div className="upgrade-popup-content">
          {/* this section has button */}
          <button
            className="close-btn"
            onClick={() => {
              // if (HandleFirstTab(1) == 1){
              //   HandleFirstTab(1);
              //  }
              //  if(HandleGraphtTab(1) == 1){
              //   HandleGraphtTab(1);
              //   }
              HandleUpdgradePopup();
            }}
          >
            <img
              src={
                require("../../assets/img/common-img/close-icon-blue.svg")
                  .default
              }
              alt="close"
            />
          </button>
          {/* this section has image */}
          <div className="upgrd-popup-img">
            <img
              src={
                require("../../assets/img/home-index/digital-maturity.gif")
                  .default
              }
              alt="digital_maturity"
            />
          </div>
          {/* this section has 
              heading
              text
              button
          */}
          <div className="upgrd-popup-body">
            <h2 className="h1">
              Buy full Digital Maturity Assessment to Unlock Plants
            </h2>
            <p>
              Upgrade to the plan that best matches your profile and start the
              Assessment as early as today.
            </p>
            <Link
              to="/plans"
              className="btn-upgrade-now"
              onClick={HandleUpdgradePopup}
            >
              Upgrade Now{" "}
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
};
//component for progress circle
const SvgCircle = (props) => {
  const { value, color, title } = props;
  return (
    <div className="s-result-cir-piechart">
      <svg viewBox="0 0 36 36">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke="#F0F0F0"
          strokeWidth="3.4"
          fill="none"
        />
        <path
          className="circle"
          strokeDasharray={`${value},100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          stroke={
            color === "blue"
              ? "#118ACB"
              : color === "orange"
              ? "#FFAB84"
              : "#7FCDBB"
          }
          strokeWidth="3.4"
          fill="none"
        />
      </svg>
      <div className="s-result-cir-div-txt">
        <p>{title}</p>
        <h1>{value}%</h1>
      </div>
    </div>
  );
};
