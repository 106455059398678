/** imported components
 
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * CourseDetailView ===> custom view component


**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { CourseDetailView } from "./course-detail-view";
import "./course-detail.scss";
//CourseDetailControler start
export const CourseDetailControler = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const [onTab, setOnTab] = useState(0);
	const hashvalue = window.location.hash;
	useEffect(() => {
		if (hashvalue == "#watch-intro") {
			setOnTab(1);
		}
	}, []);
	useEffect(() => {
		const search = async () => {
			const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const tid = getLastItem(window.location.pathname);
			const apiUrl = `/item?param=trainingdetail&slug=${tid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
		};
		search();
	}, []);
	return <CourseDetailView courseData={results} onTab={onTab} {...props} />;
};
