import React, { useMemo, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams, Link } from "react-router-dom";
import { backArrow, InfoIcon } from "./svg";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import serviceProvider from '../../services/axios';
import { SiriQNAPopView } from "./SiriQNAPopView";
import {
  SiriDimentionWrapper,
  SiriDimentionCard,
  Header,
  NumberWrapper,
  Number,
  NumberTextWrapper,
  Row,
  BodyRow,
  Column1,
  Column2,
  Column3,
  TBodyRow,
  TBodyCol,
  Status,
  ButtonWrapper,
  ValidateBtn,
  ReValidateBtn,
  CapabilityBtn,
  ValidatePopWrapper,
  ValidatePopWrap,
  ValidatePopCard,
  ButtonWrap,
  SiriMobileTitleContainer,
  InfoTooltip,
  ErrorMsgDiv
} from "./siri-dimention-style.js";
import { AssessorNoteCtrl } from "../AssessorNotePop/AssessorNoteCtrl"

export const SiriDimensionView = (props) => {
  const [infoActiveTooltip, setInfoActiveTooltip] = useState(false);
  const handleClickedInfoIcon = (ind) => {
    if (infoActiveTooltip !== ind) {
      setInfoActiveTooltip(ind);
    } else {
      setInfoActiveTooltip(false);
    }
  };
  const handleClickedOutside = (e) => {
    if (
      !e.target.closest(".info-icon-i") &&
      !e.target.closest('[data-component="InfoTooltip"]')
    ) {
      setInfoActiveTooltip(false);
    }
  };
  const cardData = props.siriData[0].results;
  const plant_slug = props.siriData[0].plant_slug;
  const plant_name = props.siriData[0].plant_name;
  const plant_id = props.siriData[0].plant_id;
  const dimension = props.siriData[0].dimension;
  const [arrayHas,setArrayHas] = useState(props.siriData[0].array_has || []);
  const section_name = props.siriData[0].section_name;
  const definition = props.siriData[0].definition;
  const dim_id = props.siriData[0].dim_id;
  const isValidated = props.siriData[0].isValidated;
  const [siriPopOpen, setSiriPopOpen] = useState(false);
  const [viewQnaPopData, setViewQnaPopData] = useState({});
  const user = JSON.parse(localStorage.getItem("userData"));
  const user_id = user ? user.uid : 0;
  const [status, setStatus] = useState(props.siriData[0].status);
  const [isValidatePop, setIsValidatePop] = useState(false);
  const [isReValidatePop, setIsReValidatePop] = useState(false);
  const [hasAssessorNotePopup, setHasAssessorNotePopup] = useState(false);
  const [isSaveBtn, setIsSaveBtn] = useState(props?.siriData[0]?.isSaveBtn || false);
  const [isVailidateSuccessPopup, setIsVailidateSuccessPopup] = useState(false);
  const [data, setData] = useState({
    isValidated: isValidated,
    list: cardData
  }
  )
  const history = useHistory();
  const yesValidateFunction = () => {
    setIsValidatePop(false);
    setData(prv => {
      return {
        ...prv,
        isValidated: 1
      }
    });
    serviceProvider.post("/siri?param=status", { plant_id: plant_id, user_id: user_id, section_id: dim_id, status: 2, cid: 0, has: 0, edited: 0 }, true).then((response) => {
      setStatus('validated');
      setIsVailidateSuccessPopup(true);
      setTimeout(()=>{
        history.push(`/siri-assessment/${plant_slug}`);
      },3000)
    });
  }
  const yesReValidateFunction = () => {
    setIsReValidatePop(false);
    setData(prv => {
      return {
        ...prv,
        isValidated: 0
      }
    });

    serviceProvider.post("/siri?param=status", { plant_id: plant_id, user_id: user_id, section_id: dim_id, status: 1, cid: 0, has: 0, edited: 0 }, true).then((response) => {
      setStatus('inprogress');
    });

  }

  const handleCapability = (item, mainIndex, bandIndex, cid, val, ans, bandata) => { 
    // console.log(mainIndex);
    if(val==1){
      handleHasCapability(mainIndex, bandIndex);
    }
    else{
      let tmpObjStr = JSON.stringify(data);
      let clonedObj = JSON.parse(tmpObjStr);
      let hasBtnNot = document.querySelectorAll(".hasBtnNot:disabled");
      if(hasBtnNot[mainIndex-1]){
        setArrayHas(prv=>{
          let arr = Array.isArray(prv) ? [...prv] : [];
          arr[mainIndex-1] = 0;
          return arr;
        })
        hasBtnNot[mainIndex-1].removeAttribute("disabled");
      }
      clonedObj.list[mainIndex].band_criteria[bandIndex] = { ...item, selected: val, answer: ans };
      setData(prv=>clonedObj);
      serviceProvider.post("/siri?param=status", { plant_id: plant_id, user_id: user_id, section_id: dim_id, status: 1, cid: cid, has: val, edited: 0 }, true).then((response) => {
        setStatus('inprogress');
      }); 
    }
  }
  const handleHasCapability = (mainIndex,bandIndex) => {
    setData(prv=>{
      let clonedObj = {...prv};
      let hasBtn = document.querySelectorAll(".hasBtn");
      let hasBtnNot = document.querySelectorAll(".hasBtnNot");
      for (let i = mainIndex; i >=0; i--) {
        let cid = hasBtn[i].getAttribute("data-cid");
        let item = clonedObj.list[i].band_criteria[bandIndex];
        if(i<mainIndex){
          hasBtnNot[i].setAttribute("disabled",true);
        }
        clonedObj.list[i].band_criteria[bandIndex] = { ...item, selected: 1,};
        serviceProvider.post("/siri?param=status", { plant_id: plant_id, user_id: user_id, section_id: dim_id, status: 1, cid: cid, has: 1, edited: 0 }, true).then((response) => {
        });  
      }
      return clonedObj;
    });
  }
  //function to handle redirection
  const handlePageRedirect = () => {
    history.push(`/siri-assessment/${plant_slug}`);
  };
  // Assessor Note popup
  const handleAssessorNotePopup = () => {
    setHasAssessorNotePopup(true);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickedOutside);
    return () => {
      document.removeEventListener("click", handleClickedOutside);
    };
  }, []);

  return (
    <>
      <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/">{plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem to={`/siri-assessment/${plant_slug}`}>
        Assessment
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">
        {dimension}: {section_name}
      </BreadcrumbsItem>
      <Helmet>
        <title>Dimension | SmarterChains</title>
        <meta name="description" content="Siri Dimension" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <SiriDimentionWrapper data-component="SiriDimentionWrapper">
        <SiriMobileTitleContainer data-component="SiriMobileTitleContainer">
          <button
            className="back-icon"
            dangerouslySetInnerHTML={{ __html: backArrow }}
            onClick={() => handlePageRedirect()}
          ></button>
          <span>{section_name}</span>
        </SiriMobileTitleContainer>
        <SiriDimentionCard data-component="SiriDimentionCard">
          <Header data-component="Header">
            <Row data-component="Row" align="center">
              <Column1 data-component="Column1" align="center">
                <NumberWrapper data-component="NumberWrapper">
                  <Number data-component="Number">{dim_id}</Number>
                  <NumberTextWrapper data-component="NumberTextWrapper">
                    <p className="dull-title">{dimension} </p>
                    <div className="sm-block">
                      <Status
                        status={data.isValidated == 1 ? "validated" : status}
                      >
                        {data.isValidated == 1
                          ? "VALIDATED"
                          : status === "inprogress"
                            ? "In progress"
                            : status === "validated"
                              ? "Validated"
                              : "To Do"}
                      </Status>
                    </div>
                    <p className="title sm-none">{section_name}</p>
                  </NumberTextWrapper>
                </NumberWrapper>
                {/* NumberWrapper end */}
                <div className="title sm-block">
                  <div>
                    {section_name}
                  </div>
                  <p className="plant-def-text">{definition}</p>
                </div>
              </Column1>

              {/* column1 end  */}

              <Column2
                data-component="Column2"
                align="center"
                className="sm-none col-head2"
              >
                <Status data-component="Status" status={data.isValidated == 1 ? "validated" : status}>
                  {data.isValidated == 1
                    ? "VALIDATED"
                    : status === "inprogress"
                      ? "In progress"
                      : status === "validated"
                        ? "Validated"
                        : "To Do"}
                </Status>
                <p className="plant-def-text">{definition}</p>
              </Column2>
              {/* column2 end  */}
              {data.isValidated == 0 ? (
                <ButtonWrapper data-component="ButtonWrapper">
                  <button
                    className="note-btn"
                    onClick={() => handleAssessorNotePopup()}
                  >
                    Assessor’s Note
                  </button>
                  <ValidateBtn
                    data-component="ValidateBtn"
                    onClick={() => setIsValidatePop(true)}
                  >
                    Validate <span>Dimension</span>
                  </ValidateBtn>
                </ButtonWrapper>
              ) : (
                <ReValidateBtn
                  data-component="ReValidateBtn"
                  onClick={() => setIsReValidatePop(true)}
                >
                  Re-validate <span>Dimension</span>
                </ReValidateBtn>
              )}
              <Column3 data-component="Column3" align="center">
                {" "}
              </Column3>
              {/* column3 end  */}
            </Row>
            {/*  Row end */}
          </Header>
          {/* Header end */}

          <Row data-component="Row">
            <Column1
              data-component="Column1"
              borderBottom={true}
              align="center"
            >
              <div className="header-col-title col-m1 md-responsive-text">
                Band
              </div>
              <div className="header-col-title col-m2 md-responsive-text">
                BAND NAME
              </div>
              <div className="header-col-title col-m1 sm-responsive-text">
                BAND & THE MAIN BAND CRITERIA
              </div>
            </Column1>

            {/* column1 end  */}

            <Column2
              data-component="Column2"
              borderBottom={true}
              align="center"
            >
              <div className="header-col-title col-m1 md-responsive-text">
                THE MAIN BAND CRITERIA
              </div>
              <div className="header-col-title col-m2 col-definition">
                DEFINITION
              </div>
              <div className="header-col-title col-m2">CAPABILITY</div>
            </Column2>
            {/* column2 end  */}
            <Column3 data-component="Column3" align="center"></Column3>
            {/* column3 end  */}
          </Row>
          {/*  Row end */}
          {data.list?.map((item, index) => (
            <BodyRow
              data-component="Row"
              key={index}
              className={`${index == 0 ? "column" : ""}`}
            >
              <Column1 data-component="Column1">
                <TBodyCol data-component="TBodyCol">
                  <div className="col-m1">
                    <p className="md-text">{item.band}</p>
                    <p className="sm-text">
                      {item.band}:{item.band_name}
                      <span className="info-tooltip-wrapper">
                        <i
                          className={`info-icon-i ${infoActiveTooltip === index ? "active" : ""
                            }`}
                          dangerouslySetInnerHTML={{ __html: InfoIcon }}
                          onClick={(e) => handleClickedInfoIcon(index)}
                        ></i>
                        <InfoTooltip
                          data-component="InfoTooltip"
                          lastRow={
                            data?.list?.length > 3 &&
                            data?.list?.length - 3 == index - 2
                          }
                          className={
                            infoActiveTooltip === index ? "active" : ""
                          }
                        >
                          {item.description}
                        </InfoTooltip>
                      </span>
                    </p>
                    <div className="col-m2 sm-definition col-definition">
                      {item.definition}
                    </div>
                    <p className="sm-text turncate">
                      {/* <span>{item.band_name}</span> */}
                      <button
                        className="siri-qna-btn"
                        onClick={() => {
                          setSiriPopOpen(true);
                          setViewQnaPopData({
                            ...item,
                            count: index,
                            plant_id,
                          });
                        }}
                      >
                        View Q&A
                      </button>
                    </p>
                  </div>
                  <div className="col-m2">
                    <span>
                      {item.band_name}
                      <span className="info-tooltip-wrapper">
                        <i
                          className={`info-icon-i ${infoActiveTooltip === index ? "active" : ""
                            }`}
                          dangerouslySetInnerHTML={{ __html: InfoIcon }}
                          onClick={(e) => handleClickedInfoIcon(index)}
                        ></i>
                        <InfoTooltip
                          data-component="InfoTooltip"
                          lastRow={
                            data?.list?.length > 3 &&
                            data?.list?.length - 3 == index - 2
                          }
                          className={
                            infoActiveTooltip === index ? "active" : ""
                          }
                        >
                          {item.description}
                        </InfoTooltip>
                      </span>
                    </span>
                    <button
                      className="siri-qna-btn"
                      onClick={() => {
                        setSiriPopOpen(true);
                        setViewQnaPopData({
                          ...item,
                          count: index,
                          plant_id,
                        });
                      }}
                    >
                      View Q&A
                    </button>
                  </div>
                  {item.band_criteria.map((criteria, c_ind) => (
                    <div className="col-m1 sm-responsive-text">
                      <p>{criteria.name}</p>
                      <div className="sm-qna-wrapper">
                        <button
                          className="siri-qna-btn sm-qna-text"
                          onClick={() => {
                            setSiriPopOpen(true);
                            setViewQnaPopData({
                              ...item,
                              count: index,
                              plant_id,
                            });
                          }}
                        >
                          View Q&A
                        </button>
                        {criteria.answer == 1 &&
                          criteria.it_has_default != criteria.selected ? (
                          <i className="sm-edited-txt">
                            Edited - Originally:{" "}
                            {criteria.it_has_default == 0 ? "HAS NOT" : "HAS"}
                          </i>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
                </TBodyCol>
              </Column1>

              {/* column1 end  */}

              <Column2 data-component="Column2">
                <TBodyRow data-component="TBodyRow">
                  {item.band_criteria.map((criteria, c_ind) => (
                    <TBodyCol
                      data-component="TBodyCol"
                      borderBottom={true}
                      key={c_ind}
                    >
                      <div className="col-m1 md-responsive-text">
                        <p>{criteria.name}</p>
                        {criteria.answer == 1 &&
                          criteria.it_has_default != criteria.selected ? (
                          <i className="sm-edited-txt">
                            Edited - Originally:{" "}
                            {criteria.it_has_default == 0 ? "HAS NOT" : "HAS"}
                          </i>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-m2 col-definition">
                        {item.definition}
                      </div>
                      <div className="col-m2">
                        {criteria.answer == 1 &&
                          criteria.it_has_default != criteria.selected ? (
                          <p className="edited-txt">Edited</p>
                        ) : (
                          ""
                        )}
                        <CapabilityBtn
                          disabled={data.isValidated == 1}
                          data-cid={criteria.id}
                          className={
                            `hasBtn ${
                              data.isValidated == 1 && criteria.selected == 1
                              ? "inactive"
                              : criteria.selected == 1
                                ? "active"
                                : ""
                            }`
                          }
                          data-component="CapabilityBtn"
                          onClick={(e) =>
                            {
                              handleCapability(
                                criteria,
                                index,
                                c_ind,
                                criteria.id,
                                1,
                                1,
                                item
                              );
                              
                            }
                          }
                        >
                          Has
                        </CapabilityBtn>
                        <CapabilityBtn
                          disabled={data.isValidated == 1 || arrayHas[index] == 1}
                          className={
                            `hasBtnNot ${data.isValidated == 1 && criteria.selected == 0
                              ? "inactive"
                              : criteria.selected == 0
                                ? "active"
                                : ""}`
                          }
                          data-component="CapabilityBtn"
                          onClick={(e) =>
                            handleCapability(
                              criteria,
                              index,
                              c_ind,
                              criteria.id,
                              0,
                              1,
                              item
                            )
                          }
                        >
                          Has Not
                        </CapabilityBtn>
                        {criteria.answer == 1 &&
                          criteria.it_has_default != criteria.selected ? (
                          <p className="originally-txt">
                            Originally:{" "}
                            {criteria.it_has_default == 0 ? "HAS NOT" : "HAS"}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </TBodyCol>
                  ))}
                </TBodyRow>
              </Column2>
              {/* column2 end  */}
              <Column3 data-component="Column3"></Column3>
              {/* column3 end  */}
            </BodyRow>
          ))}
          {/*  Row end */}
        </SiriDimentionCard>
        {/*SiriDimentionCard end  */}
        {/* Revalidate and validate popups */}
        {isValidatePop ? (
          <ValidatePopWrapper data-component="ValidatePopWrapper">
            <ValidatePopWrap data-component="ValidatePopWrap">
              <ValidatePopCard data-component="ValidatePopCard">
                <h3>Are you sure to validate Dimension?</h3>
                <p>
                  All the capabilities will be updated and can be downloaded
                </p>
                <ButtonWrap data-copmponent="ButtonWrap">
                  <button
                    className="cancel-btn"
                    onClick={() => setIsValidatePop(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="validate-btn"
                    onClick={() => yesValidateFunction()}
                  >
                    Yes, validate
                  </button>
                </ButtonWrap>
              </ValidatePopCard>
            </ValidatePopWrap>
          </ValidatePopWrapper>
        ) : (
          ""
        )}
        {isReValidatePop ? (
          <ValidatePopWrapper data-component="ValidatePopWrapper">
            <ValidatePopWrap data-component="ValidatePopWrap">
              <ValidatePopCard data-component="ValidatePopCard">
                <h3>Are you sure to re-validate Dimension?</h3>
                <p>You can make changes and update capabilities.</p>
                <ButtonWrap data-copmponent="ButtonWrap">
                  <button
                    className="cancel-btn"
                    onClick={() => setIsReValidatePop(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="validate-btn"
                    onClick={() => yesReValidateFunction()}
                  >
                    Yes, re-validate
                  </button>
                </ButtonWrap>
              </ValidatePopCard>
            </ValidatePopWrap>
          </ValidatePopWrapper>
        ) : (
          ""
        )}
        <ErrorMsgDiv dataComponent='ErrorMsgDiv' active={isVailidateSuccessPopup} green={true}>
            <p>Validation has been done successfully</p>
        </ErrorMsgDiv>
      </SiriDimentionWrapper>
      {siriPopOpen && (
        <SiriQNAPopView
          open={siriPopOpen}
          setOpen={setSiriPopOpen}
          dataList={viewQnaPopData}
          dismention_index={dim_id}
        />
      )}
      {/* AssessorNote Popup Changes */}
      {hasAssessorNotePopup && (
        <AssessorNoteCtrl
          isSaveBtn={isSaveBtn}
          setIsSaveBtn={setIsSaveBtn}
          handleMainPopupClose={() => setHasAssessorNotePopup(false)}
          dimensionName={section_name}
          dimensionCount={dimension}
          noteparentdata={props.siriData[0]}
          setUpdate={props.setUpdate}
          user_id={user_id}
          {...props}
        />
      )}
    </>
  );
}