export const inviteUserIcon=`<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="14.5" fill="white" stroke="#118ACB"/>
<path d="M13.5023 14.0904C15.2986 14.0904 16.7535 12.7277 16.7535 11.0452C16.7535 9.36273 15.2986 8 13.5023 8C11.7061 8 10.2512 9.36273 10.2512 11.0452C10.2512 12.7277 11.7061 14.0904 13.5023 14.0904ZM13.5023 15.613C11.3322 15.613 7 16.6332 7 18.6582V20.1808H20.0047V18.6582C20.0047 16.6332 15.6725 15.613 13.5023 15.613Z" fill="#118ACB"/>
<ellipse cx="19.5856" cy="19.5725" rx="3.41372" ry="3.19747" fill="white"/>
<rect x="19.0996" y="17.2881" width="0.975349" height="4.56781" fill="#118ACB"/>
<rect x="22.0254" y="19.1143" width="0.913563" height="4.87675" transform="rotate(90 22.0254 19.1143)" fill="#118ACB"/>
</svg>
`;

 export const UnAssignIcon =`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" class="bg-circle" fill="#7D868C"/>
<circle cx="8" cy="8" r="1" class="dot-circle" fill="white"/>
<circle cx="8" cy="12" r="1" class="dot-circle" fill="white"/>
<circle cx="8" cy="4" r="1"  class="dot-circle" fill="white"/>
</svg>
`;