/** imported components
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * AddPlantPopView ===> custom view component

**/

import React, { useState, useEffect } from "react";
import {AddPlantPopView} from './AddPlantPopView';
import serviceProvider from '../../services/axios'
//AddPlantPopViewCtrl component
export const AddPlantPopViewCtrl=(props)=>{
    const [results, setResults] = useState([]);
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    
    useEffect(() => {
         
          const search = async () => {
              const apiUrl = `/home?param=homedataaddplantpopup&add=1&uid=${usid}`;
              const { data } = await serviceProvider.get(apiUrl,true,{});
              setResults(data);
          };
          search();
      }, []);
      

		
    return(
        <>
            <AddPlantPopView homeData={results}  {...props} />
        </>
    )
}