/** imported components

  * BreadcrumbsItem  ===> component from react to add breadcrumbs on the page.
  * Helmet ===> for add specific title & CSS for the page.
  * AssignSectionPopup  for ===> Popup for assigning users for the chapters.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
//ChapterPlantView component start
import { apiEndpoints } from '../../services/api-endpoints';
const getShortName = (val) => {
  let tmpStrArr = val.split(" ");
  let tmpArrLength = tmpStrArr.length;
  let shortName = tmpArrLength > 1 ? `${tmpStrArr[0].toLowerCase()[0]}${tmpStrArr[tmpArrLength - 1].toLowerCase()[0]}` : tmpStrArr[0].toLowerCase()[0]
  console.log(shortName,tmpStrArr)
  return shortName;
}


export const ChapterView = (props) => {
    // defining variables here
    const sectionDataItems = [
    {
        "uid": "3010",
        "block": "allow",
        "cid": "1",
        "category": {
            "id": "1",
            "category_name": "Industry 4.0 Vision",
            "bg_image": "ind4-icon.svg",
            "category_img": "assets\/img\/ind4-icon-w.svg",
            "category_description": "<p>The section&apos;s objective is to address the influence of Industry 4.0 into the company&apos;s strategy.&nbsp;<\/p> <p>In particular, we will explore Industry 4.0 strategies and initiatives, sustainability practices, structures and policies that provide the agility to design and roll out an effective digital transformation.<\/p> <p>Typically, the person responsible for this section is the Assessment leader.<\/p> <p>Estimated engagement 35 minutes.<\/p>"
        },
        "invitedteam": [],
        "isChaptertour": "1",
        "chapters": [
            {
                "id": "1",
                "category_id": "1",
                "subcategory_name": "Company-wide Capabilities",
                "subcategory_description": "<p>The chapter&apos;s objective is to address the Industry 4.0-related company&apos;s wide capabilities.&nbsp;<\/p> <p>In particular, we will explore your organizational capabilities, which are the unique combination of skills, processes, technologies, and human abilities that differentiate the company and help it execute its Industry 4.0 strategy.<\/p>",
                "is_active": "1",
                "order_by": "1",
                "lastcatprogress": "access",
                "catprogress": 0
            },
            {
                "id": "41",
                "category_id": "1",
                "subcategory_name": "Sustainability Strategy",
                "subcategory_description": "<p>The chapter&apos;s objective is to address the sustainability practices adopted by your organization.<\/p>\n<p>In particular, we will explore your sustainability business strategy, which is the integration of economic, environmental, and social aspects into company's goals, activities, and planning, with the aim of creating long-term value for the company, its stakeholders, and the society.<\/p>",
                "is_active": "1",
                "order_by": "2",
                "lastcatprogress": "noaccess",
                "catprogress": 0
            },
            {
                "id": "2",
                "category_id": "1",
                "subcategory_name": "Organization of the Future",
                "subcategory_description": "<p>The chapter&apos;s objective is to address the existence of structures and policies that help shaping the Organization of the Future.<\/p> <p>In particular, we will explore the structures, policies, behaviors and strategies that inspire, support and accelerate the adoption of the Industry 4.0 strategy ensuring the success of the digital transformation across the organization.<\/p>",
                "is_active": "1",
                "order_by": "3",
                "lastcatprogress": "noaccess",
                "catprogress": 0
            },
            {
                "id": "3",
                "category_id": "1",
                "subcategory_name": "Trainings",
                "subcategory_description": "<p>The chapter&apos;s objective is to address the existing training methods in your organization, the learning opportunities for the employees and the existence of Industry 4.0-related trainings.<\/p>",
                "is_active": "1",
                "order_by": "4",
                "lastcatprogress": "noaccess",
                "catprogress": 0
            }
        ],
        "progress": 0,
        "plant_id": "1947",
        "total_completed": 0,
        "section_on": [
            {
                "id": "24807",
                "ques_id": "464",
                "section_id": "1",
                "plant_id": "1947",
                "question": "Do you have a formal Industry 4.0 strategy as a part of the corporate strategy?",
                "subcategory_name": "Company-wide Capabilities",
                "subcategory_id": "1",
                "is_first": "1",
                "order_by": "1"
            }
        ],
        "save_on": 0,
        "is_datasubmitted": 0,
        "editining": 0,
        "slug": "chacjha",
        "no_line": 0,
        "open_line": 0
    }
];
    if (!sectionDataItems.length) {
        return null;
    }
    const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
    const [dis, setDis] = useState(false);
    const [distext, setDistext] = useState("Submit Results");
    const [discount, setDiscount] = useState(1);
    const [walkthroughOpen, setWalkthroughOpen] = useState(true); 
    const sectionDataItem = sectionDataItems[0];
    const catData = sectionDataItem.category;
    const chapterDataItem = sectionDataItem.chapters;
    const section_on = sectionDataItem.section_on;
    const isChaptertour = sectionDataItem.isChaptertour;
    const block = sectionDataItem.block;
    const plant_id = sectionDataItem.plant_id;
    // variables for right-assessment section start SCNV-1930
    const [isResume, setIsResume] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitPop, setIsSubmitPop] = useState(false);
    const [isUnlockPop, setIsUnlockPop] = useState(false);
    const [isFinalSubmit, setIsFinalSubmit] = useState(false);
    const [isChapter, setIsChapter] = useState(true);
    const totalSection = chapterDataItem.length;
    const [isUserHover,setIsUserHover] = useState(false);
    
    // variables for right-assessment section end SCNV-1930
    if(block == 'block'){
        return <Redirect to="/home" />;
    }
    if (!catData) {
        return null;
    }
    useEffect(()=>{
    
    if(sectionDataItem.progress>=100){
        setIsSubmit(true);
        setIsChapter(false);
    }
    if(sectionDataItem.save_on==1){
        setIsSubmit(false);
        setIsFinalSubmit(true);
    }
    if(sectionDataItem.is_datasubmitted==1){
        
        //setIsFinalSubmit(false);
    }
    
    },[]) 
    const inviteteam = sectionDataItem.invitedteam;
    
    useEffect(() => {
        sectionLock(1)
        return () => {
            sectionLock(0)
        }
    },[])

    // Function for lock the section
    const sectionLock = async (status = 1) => {
        const { sectionData } = props
        if(Array.isArray(sectionData)){
            const { uid, plant_id, category} = sectionData[0]
        const apiUrl = `/sectionlock`;
        await serviceProvider.post(apiUrl, { uid, plant_id, section_id: category.id, type:1 , status },true)
        }
    }
    
    var fullname="1500000000";
    var email="";   
    if (!inviteteam.length) {
        fullname="1500000000";
        email="";
            // return null;
        }else{
              
            //   console.log(cidd);
            fullname =inviteteam[0].full_name;
            email=inviteteam[0].email
          }
    // console.log(email);      
    const uid = sectionDataItem.uid;
    const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/")+1);
    // const slug = getLastItem(window.location.pathname);
    const slug = props.slug;
    // console.log(sectionDataItem.plantslug);
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    const group_id = user ? user.group_id:0;

    const HandleSubmitResult = () => {
        setDis(true);
        setDiscount(discount + 1);
        setDistext("Submitting Results...");
        if (discount == 1) {
            serviceProvider.post("/results?type=calculate", JSON.stringify({ plant_id: uid, user_id: uid }), true).then((response) => {
                //console.log(response);
                window.location = "/result-walkthrough";
            });
        }
    };
    const handleAssignPoup = (e) => {
        setIsAssignSectionPopup(true);
        e.preventDefault();
    }
    
    //Conditions related to assessment right section (more informative right section for chapters)
    
    //Function for final submission
    const handleFinalSubmit = () => {
        setIsFinalSubmit(!isFinalSubmit);
        setIsSubmitPop(!isSubmitPop);
        setIsChapter(isChapter);
        setIsSubmit(!isSubmit);
        serviceProvider
        .post(
        "/assessmentmaturity?type=savechapter",
        JSON.stringify({ user_id:usid, plant_id:plant_id, section_id:catData.id }),
        true
      )
      .then((response) => {
        
      });
    }
    //Function to handle resume
    const handleResume = () => {
        setIsSubmit(!isSubmit);
        setIsChapter(!isChapter);
    }
    //Function to handle Unlock a chapter
    const handleUnlock = () => {
        setIsUnlockPop(!isUnlockPop);
        setIsFinalSubmit(!isFinalSubmit);
        setIsSubmit(!isSubmit);
        serviceProvider
        .post(
        "/assessmentmaturity?type=unlockchapter",
        JSON.stringify({ user_id:usid, plant_id:plant_id, section_id:catData.id }),
        true
      )
      .then((response) => {
        
      });
    }

    //Conditions related to assessment right section end

    // console.log(chapterDataItem);
    const chapterDataItemSections = chapterDataItem.map((chapter, index) => {
        const count = index + 1;
        return (
                // Card wrapper for the chapters
                <Link key={chapter.id} data-index={index} data-id={chapter.id} className="assigned-card" >
                    {/* Left section is containing number for the chapters */}
                    <div className="assigned-card-left">
                        <div className="assigned-card-icon-wrapper">
                            <span className="number-text">{count}</span>
                        </div>
                    </div>
                    {/* card left end */}
                    {/* Right section is containing assigned/unassigned user structure, chapter category name. */}
                    <div className="assigned-card-right">
                        <div className="assigned-rgt-sub-lft">
                            <h1>
                                {chapter.subcategory_name}
                                <img alt="true" className="info-icon" src={require("../../assets/img/assigned/card-i-icon.svg").default} />
                            </h1>
                            <p>
                                <span className="res-text">Responsible:</span>
                                {fullname == 1500000000 ? 
                                <span className="res-text">Unassigned</span>
                                :
                                <div className="scnv-2147-cir-user" onClick={(e) =>{e.stopPropagation(); e.preventDefault()}}>
                                    <ul className="cir-2147-ul">
                                    {inviteteam?.slice(0, 3).map((assinged_user, index) => {
                          let shortName=assinged_user.full_name?`${assinged_user.name[0]}${assinged_user?.last_name?assinged_user?.last_name[0]:''}`:getShortName(assinged_user.email);
                          return(
                                 <li className={`cir-2147-li ${!assinged_user.profile_pic?assinged_user.avcolor:'cir-transparent'}`} key={index}>
                                    {!assinged_user.profile_pic ?
                                <div className={`cir2147 ${assinged_user.avcolor}`}>
                                  {/* {assinged_user.name} */}
                                  {/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
                                        {shortName}
                                </div>
                                :
                                <div className={`cir2147 cir-b-none cir-transparent`}>
                                  <img className="h__profile__img_cir "

                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
                                    alt={assinged_user.profile_pic}
                                  />
                                </div>}
                                    </li>
                            )})}
                                        
                                    </ul>
                                    <div className="cir-2147-ddl">
                                        <ul className="cir-ddl-user">
                                            {inviteteam?.map((assinged_user, index) =>
                          {
                            let shortName=assinged_user.full_name?`${assinged_user.name[0]}${assinged_user?.last_name?assinged_user?.last_name[0]:''}`:getShortName(assinged_user.email);
                            
                            return(
                            // 
                            <li className="cir-ddl2147-li" key={index}>
                              <div className="cir-ddl2147">
                              {!assinged_user.profile_pic ?
                                <div className={`cir2147 ${assinged_user.avcolor}`}>
                                  {/* {assinged_user.name} */}
                                  {shortName}
                                  {/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
                                </div>
                                :
                                <div className={`cir2147 cir-b-none`}>
                                  <img className="h__profile__img_cir "

                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
                                    alt={assinged_user.profile_pic}
                                  />
                                </div>}
                                </div>
                              <div className="txt-s2147">
                                <p className="name-2147">{
                                  assinged_user.full_name?assinged_user.full_name:assinged_user.email
                                }</p>
                                <p className="role-2147">{assinged_user.job_title?assinged_user.job_title:''}</p>
                              </div>
                            </li>
                          )
                        }
                          )
                       }
                                        </ul>
                                    </div>
                                </div>
                                    }
                            </p>
                        </div>
                        <div className="assigned-rgt-sub-rgt">
                            {/* Chapter Progress */}
                            <div className="assigned-card-rgt-border">
                                <div className="assigned-progress-bar assigned-progress" data-percent="0%">
                                    <div>
                                        <SvgCircle value={chapter.catprogress} />
                                    </div>
                                </div>
                                {/* Button to navigate on the next page */}
                                <div className="assigned-card-rgt-angle">
                                    <img alt="true" src={require("../../assets/img/assigned/right-angle.svg").default} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Popup will show on card hover */}
                    <div className={`sellection-card-popup ${index > 2 && "last-popup"}`}>
                        <h2>{chapter.subcategory_name}</h2>
                        <div dangerouslySetInnerHTML={{ __html: chapter.subcategory_description }}></div>
                    </div>
                </Link>
            );
    });

    return (
        <>
            {/* Page Title */}
            <Helmet>
                <title>{catData.category_name} | SmarterChains</title>
                <meta name="description" content={`${catData.category_name} Assessment`} />
                <link href={require("./assessment.scss").default} />
                <link href={require("./chapter.scss").default} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            {/* Breadcrumbs items */}
            <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/${slug}`}>Assessment</BreadcrumbsItem>
            <BreadcrumbsItem to="#">{catData.category_name}</BreadcrumbsItem>
            {/* Chapter main page wrapper */}
            <div className="assigned-main-page">
                {/* Main wrapper that has been divided into two parts */}
                <section className="assigned-content">
                    {/* Left section that contains chapter number, it's progress and right navigation button. */}
                    <section className="assigned-sec">
                        <h2 className="h2">{catData.category_name}</h2>
                        <div className="assigned-card-row-continer">
                            <div className="assigned-card-row">{chapterDataItemSections}</div>
                        </div>
                        {/* {sectionDataItem.progress == 100 && catData.id == "1" && group_id == 2 && (
                            <a disabled={dis} class="btn-submit-result" onClick={HandleSubmitResult}>
                                {distext}
                            </a>
                        )} */}
                    </section>
                    {/* Right Chapter Section */}
                    <div className="right-assessment-section">
                        {/* Top section having progress bar and progress title */}
                            <div className="top-progress-bar">
                                <div className="progress-bar-section">
                                    <div className="header-progress">
                                        <p className="progress-title">Section Progress:</p>
                                        <h2>{sectionDataItem.progress}%</h2>
                                        <div className="header-progress-bar">
                                            <div style={{width:sectionDataItem.progress+'%'}}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section-count">
                                    <span className="heading">Chapters Completed: </span>
                                    {/* "sections submitted" will be replace from "sections completed" when the all sections will be completed */}
                                    <div className="count">
                                        {sectionDataItem.total_completed} / {totalSection}
                                    </div>
                                    {((isFinalSubmit && group_id==14) || (sectionDataItem.save_on==1 && group_id==14)) &&
                                            <div className="submit-text pro">
                                            <img src={require("../../assets/img/assessment-right-section/green-check.svg").default} alt="Green Check" />
                                            <span>Data Submitted</span>
                                    </div>
                                    }
                                    
                                </div>
                            </div>
                            {isChapter && (
                                <div className="assessment-submission-section chapter-section">
                                    {/* Conditional element depend upon chapter progress */}
                                    <div className="top-sec">
                                        {(sectionDataItem.progress == 100 && (group_id == 11 || group_id == 2)) ? (
                                        <span>Action required</span>
                                        ) : (
                                        <span className="progress-text">Assessment In-Progress</span>
                                        )}
                                    </div>

                                    <div className="bottom-section">
                                        
                                        {section_on.length && (
                                        <div className="bottom-content-section">
                                            <div className="num-section">
                                                <div className="number">{section_on[0].order_by}</div>
                                                <span>{section_on[0].subcategory_name}</span>
                                            </div>
                                            <p className="bottom-text">
                                                {section_on[0].question}
                                            </p>
                                        </div>
                                        )}
                                        
                                        {sectionDataItem.progress != 0 ? (
                                            <div className="button-wrap"> 
                                            {/* Conditional buttons */}
                                            <Link className="chapter-btn resume" to={`/assessment/start/${slug}/${section_on[0].subcategory_id}/${section_on[0].ques_id}`} >Resume Section</Link>
                                            </div>
                                        ) : (
                                            <div className="button-wrap">
                                                <Link className="chapter-btn" to={`/assessment/start/${slug}/${section_on[0].subcategory_id}/${section_on[0].ques_id}`}>Start Section</Link>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {isSubmit && (
                                <div className="assessment-submission-section submit-section">
                                    {/* Submission section which will come conditional */}
                                    <div className="top-sec">
                                        {(sectionDataItem.progress == 100) ? (
                                        <span>Action required</span>
                                        ) : (
                                        <span className="progress-text">Assessment In-Progress</span>
                                        )}
                                    </div>

                                    <div className="bottom-section after-submit">
                                        <div className="bottom-content-section">
                                            <p className="bottom-text">
                                                Congratulations, you have completed the section.
                                            </p>
                                            <p>
                                                Please submit your data by clicking the "Submit Data" button.
                                                Note that after submission you can't change your input.
                                            </p>
                                        </div>
                                        <div className="button-wrap">
                                            <button className={`submit-btn ${(sectionDataItem.progress == 100)?'active':''}`} disabled={(sectionDataItem.progress == 100)?false:true} onClick={() => setIsSubmitPop(!isSubmitPop)}>Submit Data</button>
                                        </div>
                                    </div>

                                </div>
                            )}
                            {(isFinalSubmit && (group_id == 11 || group_id == 2)) && (
                                <>
                                    {/* Final submission and unlock chapter section */}
                                    <div className="assessment-submission-section final-submit">
                                        <div className="top-sec">
                                            <span>No further action required</span>
                                        </div>
                                        <div className="bottom-content-section">
                                            <p>Your data have been successfully submitted.</p>
                                        </div>
                                        <div className="button-wrap">
                                            <div className="submit-text">
                                                <img src={require("../../assets/img/assessment-right-section/green-check.svg").default} alt="Green Check" />
                                                <span>Data Submitted</span>
                                            </div>
                                        </div>
                                    </div>
                                    {sectionDataItem.is_datasubmitted==0 && (<div className="assessment-submission-section unlock-section">
                                        <div className="top-sec">
                                            <img src={require("../../assets/img/assessment-right-section/lock.svg").default} alt="Lock" />
                                            <span>Unlock section to change submitted data</span>
                                        </div>
                                        <div className="bottom-content">
                                            <p className="top-txt">All the chapters will be unlocked to allow changes.</p>
                                            <p>Please note, that any change may result in the addition or removal of questions.</p>
                                        </div>
                                        <div className="button-wrap">
                                            <button className="unlock-btn" onClick={() => setIsUnlockPop(!isUnlockPop)}>Unlock & edit section</button>
                                        </div>
                                    </div>)}
                                </>
                            )}

                            {/* Popup Start */}
                            {/* Submit Popup  */}
                            {isSubmitPop && (
                                <div className="popup-box-blue" >
                                    <div className="center-box">
                                        <div className="content-section">
                                            <h2>Are you sure, you would like to submit your data?</h2>
                                            <p>Please note, that you will not be able to make changes after submission.</p>
                                            <div className="button-section">
                                                <button className="cancel" onClick={() => setIsSubmitPop(!isSubmitPop)}>Cancel</button>
                                                <button className="submit" onClick={() => handleFinalSubmit()}>Yes, submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Unlock Popup */}
                            {isUnlockPop && (
                                <div className="popup-box-red">
                                    <div className="center-box">
                                        <div className="content-section">
                                            <h2>Are you sure, you would like to unlock the section?</h2>
                                            <p>Any change may result in the addition or removal of questions.</p>
                                            <div className="button-section">
                                                <button className="cancel" onClick={() => setIsUnlockPop(!isUnlockPop)}>Cancel</button>
                                                <button className="submit" onClick={() => handleUnlock()}>Yes, unlock</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Popup end */}
                    </div>
                    {/* Right Chapter Section end */}
                </section>
                
            </div>
           
        </>
    );
};
// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCircle = (props) => {
    const { value } = props;
    return (
        <svg viewBox="0 0 36 36">
            <text className="percent__text" x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="600">
                {value}
                <tspan fontSize="5">%</tspan>
            </text>
            <path
                className="circle-bg"
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                stroke="#EBF5FA"
                strokeWidth="2.4"
                fill="none"
            ></path>
            <path
                className="circle"
                strokeDasharray={`${value}, 100`}
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                stroke={value < 100 && value > 0 ? "#FFBC1F" : value === 100 ? "#40D30D" : "transparent"}
                strokeWidth="2.4"
                fill="none"
            ></path>
        </svg>
    );
};

// Walkthrough for new right section of chapter page

