/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/

import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import './loss-analysis-walkthrough.scss';
import { Modal } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LossadminPlantView } from "./lossadmin-plant-view";
import PrepareHomeView from "./home-walkthrough-view";
import { HomeTourControler } from '../onboarding-home-tour/home-tour-ctrl';
import {LossanalysisQuesView} from '../onboarding-learning-resource-walkthrough/la-questionnaires-view';
//LossAnalysisTour component start
export const LearningLossAnalysisWalkthrough = (props) => {
    //Defining variables here
    let {activeSlide=1} = props;
    const [slide1Active, setSlide1Active] = useState(0);
    const [slide2Active, setSlide2Active] = useState(0);
    const [isSlide1, setIsSlide1] = useState(activeSlide === 1);
    const [isSlide2, setIsSlide2] = useState(activeSlide === 2);
    const slide2Total = 7;
    const user = JSON.parse(localStorage.getItem("userData"));
    const name = user ? user.name : "";
    const usid = user ? user.uid : 0;
    const handleModal = () => {
        props.handlePopup(false);
    }
    const slide1Data = [
        {
            img: 'loss-step1.svg',
            title: `Let’s continue on  ‘Prepare’ with the IR4.0 Loss Analysis`,
            para: [
                `Assess and understand where the value recovery opportunities for the 
                adoption of IR4.0 lie within your operations and what is potential value you can recover. `
            ],

        },
        {
            img: 'loss-step2.gif',
            title: `Hi ${name}, welcome to the IR4.0 Loss Analysis tour!`,
            para: [
                `The IR 4.0 Loss Analysis is assesing in detail all the manufacturing activities across your operation as detail as operator activities 
                at the production line level to uncover value opportunities wth the integration of IR4.0 solutions.`
            ],

        },
        {
            img: 'loss-step3.gif',
            title: `Invite and collaborate with your colleagues`,
            para: [
                `You can self-assess your plant's  losses that is driven by your manufactung operations or you can invite all factory 
                colleagues and external partners to provide their input to specific assessment sections. `
            ],

        },
        {
            img: 'loss-step4.gif',
            title: `How it works`,
            para: [
                `Each operational section is being segmented to sub-sections to enable a more details and accurate assessment of all plant employee activities that drive the plants conversion cost.​`
            ],

        },
        {
            img: 'loss-step5.gif',
            title: `Complete the Loss Analysis to get the results`,
            para: [
                `All operational sections have easy to answer questions and answers with embedded knowledge of how to answer each one making the experience fast and easy. Once users complete it and the input is submitted you automatically receive the results. ​`
            ],

        },

    ]
    //Function to handle slide 1
    const HandleSlide1 = (val) => {
        setSlide1Active(val);
    }
    //Function to handle slide 2
    const HandleSlide2 = (val) => {
        setSlide2Active(val);
    }
    //Function to handle slide navigation
    const HandleSlide1NextPrev = (e) => {
        if (e === "next" && slide1Active < slide1Data.length - 1) {
            const tmpval = slide1Active + 1;
            setSlide1Active(tmpval);
        } else if (slide1Active > 0) {
            const tmpval = slide1Active - 1;
            setSlide1Active(tmpval);
        }
        else if(e===4){
            setSlide1Active(0);
        }

    }
    //Function to handle slide navigation
    const HandleSlide2NextPrev = (e) => {

        if (e === "next" && slide2Active < slide2Total - 1) {
            const tmpval = slide2Active + 1;
            setSlide2Active(tmpval);
        } else if (slide2Active > 0) {
            const tmpval = slide2Active - 1;
            setSlide2Active(tmpval);
        }

    }
    
    useEffect(() => {
        let objDiv = document.querySelector(`.training-content`);
        if(objDiv){
            objDiv.scrollTop = 0;
        }
        setIsSlide1(activeSlide === 1);
        setIsSlide2(activeSlide === 2);
    }, [activeSlide]);
    return (
        <>
            {(isSlide1 && slide1Active == 0) && <HomeTourControler {...props} />}
            {(isSlide1 && slide1Active != 0 && slide1Active!=4) && <LossadminPlantView />}
            {(isSlide1 &&  slide1Active == 4) && <LossanalysisQuesView/>}
            {/* Main walkthrough wrapper */}
            <div className="lsa-tour-main">
                {/* Backlground color for all walkthrough slides */}
                <div className="lsa-tour-bg-1" style={{
                    display: 'none'
                }}></div>
                <div className="lsa-tour-bg1-of7" style={{
                    display: (!isSlide2 || slide2Active !== 0) && 'none'
                }}></div>
                <div className="lsa-tour-bg2-of7" style={{
                    display: (!isSlide2 || slide2Active !== 1) && 'none'
                }}></div>
                <div className="lsa-tour-bg3-of7" style={{
                    display: (!isSlide2 || slide2Active !== 2) && 'none'
                }}></div>
                <div className="lsa-tour-bg4-of7" style={{
                    display: (!isSlide2 || slide2Active !== 3) && 'none'
                }}></div>
                <div className="lsa-tour-bg5-of7" style={{
                    display: (!isSlide2 || slide2Active !== 4) && 'none'
                }}></div>
                <div className="lsa-tour-bg6-of7" style={{
                    display: (!isSlide2 || slide2Active !== 5) && 'none'
                }}></div>
                <div className="lsa-tour-bg7-of7" style={{
                    display: (!isSlide2 || slide2Active !== 6) && 'none'
                }}></div>
                <Modal open={true} className={`lsa-tour-modal-1 ${isSlide2 && slide2Active > 0}`}>
                    <>
                        {/* Breadcrumbs */}
                        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
                        <BreadcrumbsItem to="/p">IR4.0 Loss Analysis</BreadcrumbsItem>
                        {isSlide2 &&
                        <> 
                            <BreadcrumbsItem to="/p">My Plant</BreadcrumbsItem>
                            <BreadcrumbsItem to="/p2">IR4.0 Loss Analysis</BreadcrumbsItem>
                            <BreadcrumbsItem to="/p1">Result</BreadcrumbsItem>
                        </>
                        }
                        {!isSlide2 ? <>
                            {slide1Data.map((item, index) =>
                                /* slide has three parts
                                    las-header
                                    las-body
                                    las-footer
                                */
                                <div className={`lsa-content-1 ${index == slide1Active && ' active'}`} key={index}>
                                    {/* this section has image */}
                                    <div className="las-header-1">
                                        <img src={require(`../../assets/img/platform-onboarding-tour/${item.img}`).default} alt="true" />
                                    </div>
                                    {/* this section has
                                    number of slide
                                    heading
                                    text
                                */}
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            {index + 1} of {slide1Data.length}
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            {item.title}
                                        </h3>
                                        {item.para.map((item, index2) =>
                                            <p key={index2}>
                                                {item}
                                            </p>)
                                        }

                                    </div>
                                    {/* footer has two parts 
                                    navigation using dots
                                    navigation using button
                                */}
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button onClick={() => {
                                                HandleSlide1(0)
                                            }} disabled={slide1Active === 0 ? true : false}></button>
                                            <button onClick={() => {
                                                HandleSlide1(1)
                                            }} disabled={slide1Active === 1 ? true : false}></button>
                                            <button onClick={() => {
                                                HandleSlide1(2)
                                            }} disabled={slide1Active === 2 ? true : false}></button>
                                            <button onClick={() => {
                                                HandleSlide1(3)
                                            }} disabled={slide1Active === 3 ? true : false}></button>
                                            <button onClick={() => {
                                                HandleSlide1(4)
                                            }} disabled={slide1Active === 4 ? true : false}></button>
                                        </div>
                                        {slide1Active === 0 ?
                                            <Link to="/" className="btn-blue-text">
                                                Exit Tour
                                            </Link>
                                            :
                                            slide1Active === 4 ?
                                            <button className="btn-blue-text" onClick={() => HandleSlide1(0)} >
                                                Restart
                                            </button>
                                            :
                                            <button className="btn-blue-text" onClick={() => HandleSlide1NextPrev()} >
                                                Previous
                                            </button>

                                        }
                                        {slide1Active === 0 ?
                                            <button className="btn-blue-bg" onClick={() => HandleSlide1NextPrev('next')}>
                                                Let’s go
                                            </button>
                                            : slide1Active > 0 && slide1Active < slide1Data.length - 1 ?
                                                <button className="btn-blue-bg" onClick={() => HandleSlide1NextPrev('next')}>
                                                    Next
                                                </button>
                                                :
                                                <button className="btn-blue-bg" onClick={()=>{props.setCurrentTour(props.tourArray[2])}}>
                                                    Next: Loss Analysis Results
                                                </button>
                                        }
                                    </div>
                                </div>
                            )}
                        </>
                            :
                            <>
                                <div className={`lsa-content-1 ${slide2Active === 0 && ' active'}`}>
                                    <div className="las-header-1">
                                        <img src={require(`../../assets/img/platform-onboarding-tour/loss-result.gif`).default} alt="true" />
                                    </div>
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            1 of 7
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            Welcome to the IR4.0 Loss Analysis Results tour!
                                        </h3>
                                        <p>
                                            Following IR4.0 Loss Analysis completion, access the results through an Integrated Power BI environment in SmarterChains platform
                                        </p>
                                        <p>
                                            Loss is  the value  that can be recovered by the application of digital & automation IR4.0 solutions as calculated by SmarterChains client experience, vendor input and secondary research
                                        </p>

                                    </div>
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button disabled={
                                                slide2Active == 0 ? true : false
                                            } onClick={() => HandleSlide2(0)}></button>
                                            <button disabled={
                                                slide2Active == 1 ? true : false
                                            } onClick={() => HandleSlide2(1)}></button>
                                            <button disabled={
                                                slide2Active == 2 ? true : false
                                            } onClick={() => HandleSlide2(2)}></button>
                                            <button disabled={
                                                slide2Active == 3 ? true : false
                                            } onClick={() => HandleSlide2(3)}></button>
                                            <button disabled={
                                                slide2Active == 4 ? true : false
                                            } onClick={() => HandleSlide2(4)}></button>
                                            <button disabled={
                                                slide2Active == 5 ? true : false
                                            } onClick={() => HandleSlide2(5)}></button>
                                            <button disabled={
                                                slide2Active == 6 ? true : false
                                            } onClick={() => HandleSlide2(6)}></button>
                                        </div>
                                        <Link to="/" className="btn-blue-text">
                                            Exit Tour
                                        </Link>
                                        <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                                {/* slide 1 end */}
                                <div className={`lsa-content-1 type-2 ${slide2Active === 1 && ' active'}`} id='LsaType2Content1'>
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            2 of 7
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            Key Plant Figures
                                        </h3>
                                        <p>
                                            Review the results starting with an overview of your key plant figures, such as: production volume, OEE and Digital Maturity Index.
                                        </p>

                                    </div>
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button disabled={
                                                slide2Active == 0 ? true : false
                                            } onClick={() => HandleSlide2(0)}></button>
                                            <button disabled={
                                                slide2Active == 1 ? true : false
                                            } onClick={() => HandleSlide2(1)}></button>
                                            <button disabled={
                                                slide2Active == 2 ? true : false
                                            } onClick={() => HandleSlide2(2)}></button>
                                            <button disabled={
                                                slide2Active == 3 ? true : false
                                            } onClick={() => HandleSlide2(3)}></button>
                                            <button disabled={
                                                slide2Active == 4 ? true : false
                                            } onClick={() => HandleSlide2(4)}></button>
                                            <button disabled={
                                                slide2Active == 5 ? true : false
                                            } onClick={() => HandleSlide2(5)}></button>
                                            <button disabled={
                                                slide2Active == 6 ? true : false
                                            } onClick={() => HandleSlide2(6)}></button>
                                        </div>
                                        <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                            Previous
                                        </button>
                                        <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                                {/* slide 2 end */}
                                <div className={`lsa-content-1 type-2 ${slide2Active === 2 && ' active'}`} id='LsaType2Content2'>
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            3 of 7
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            Filter
                                        </h3>
                                        <p>
                                            Use the filter area for better visualization purposes of your results. You can filter by loss area, production process and loss category.
                                        </p>

                                    </div>
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button disabled={
                                                slide2Active == 0 ? true : false
                                            } onClick={() => HandleSlide2(0)}></button>
                                            <button disabled={
                                                slide2Active == 1 ? true : false
                                            } onClick={() => HandleSlide2(1)}></button>
                                            <button disabled={
                                                slide2Active == 2 ? true : false
                                            } onClick={() => HandleSlide2(2)}></button>
                                            <button disabled={
                                                slide2Active == 3 ? true : false
                                            } onClick={() => HandleSlide2(3)}></button>
                                            <button disabled={
                                                slide2Active == 4 ? true : false
                                            } onClick={() => HandleSlide2(4)}></button>
                                            <button disabled={
                                                slide2Active == 5 ? true : false
                                            } onClick={() => HandleSlide2(5)}></button>
                                            <button disabled={
                                                slide2Active == 6 ? true : false
                                            } onClick={() => HandleSlide2(6)}></button>
                                        </div>
                                        <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                            Previous
                                        </button>
                                        <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                                {/* slide 3 end */}
                                <div className={`lsa-content-1 type-2 ${slide2Active === 3 && ' active'}`} id='LsaType2Content3'>
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            4 of 7
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            Annual Losses by Loss Category
                                        </h3>
                                        <p>
                                            Access this bar chart to view the key loss categories. The Loss is defined by the value that can be recovered by the application of digital & automation technologies to the work processes
                                        </p>

                                    </div>
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button disabled={
                                                slide2Active == 0 ? true : false
                                            } onClick={() => HandleSlide2(0)}></button>
                                            <button disabled={
                                                slide2Active == 1 ? true : false
                                            } onClick={() => HandleSlide2(1)}></button>
                                            <button disabled={
                                                slide2Active == 2 ? true : false
                                            } onClick={() => HandleSlide2(2)}></button>
                                            <button disabled={
                                                slide2Active == 3 ? true : false
                                            } onClick={() => HandleSlide2(3)}></button>
                                            <button disabled={
                                                slide2Active == 4 ? true : false
                                            } onClick={() => HandleSlide2(4)}></button>
                                            <button disabled={
                                                slide2Active == 5 ? true : false
                                            } onClick={() => HandleSlide2(5)}></button>
                                            <button disabled={
                                                slide2Active == 6 ? true : false
                                            } onClick={() => HandleSlide2(6)}></button>
                                        </div>
                                        <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                            Previous
                                        </button>
                                        <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                                {/* slide 4 end */}
                                <div className={`lsa-content-1 type-2 ${slide2Active === 4 && ' active'}`} id='LsaType2Content4'>
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            5 of 7
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            Losses by Production Processes
                                        </h3>
                                        <p>
                                            View the product line process in the stacked bar. Here, you can compare losses based on the different processes being used.
                                        </p>

                                    </div>
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button disabled={
                                                slide2Active == 0 ? true : false
                                            } onClick={() => HandleSlide2(0)}></button>
                                            <button disabled={
                                                slide2Active == 1 ? true : false
                                            } onClick={() => HandleSlide2(1)}></button>
                                            <button disabled={
                                                slide2Active == 2 ? true : false
                                            } onClick={() => HandleSlide2(2)}></button>
                                            <button disabled={
                                                slide2Active == 3 ? true : false
                                            } onClick={() => HandleSlide2(3)}></button>
                                            <button disabled={
                                                slide2Active == 4 ? true : false
                                            } onClick={() => HandleSlide2(4)}></button>
                                            <button disabled={
                                                slide2Active == 5 ? true : false
                                            } onClick={() => HandleSlide2(5)}></button>
                                            <button disabled={
                                                slide2Active == 6 ? true : false
                                            } onClick={() => HandleSlide2(6)}></button>
                                        </div>
                                        <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                            Previous
                                        </button>
                                        <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                                {/* slide 5 end */}
                                <div className={`lsa-content-1 type-2 ${slide2Active === 5 && ' active'}`} id='LsaType2Content5'>
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            6 of 7
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            Losses by Sub Loss Category
                                        </h3>
                                        <p>
                                            In this horizontal bar chart, view a consolidated of the sub loss categories, populated from highest to lowest value.
                                        </p>

                                    </div>
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button disabled={
                                                slide2Active == 0 ? true : false
                                            } onClick={() => HandleSlide2(0)}></button>
                                            <button disabled={
                                                slide2Active == 1 ? true : false
                                            } onClick={() => HandleSlide2(1)}></button>
                                            <button disabled={
                                                slide2Active == 2 ? true : false
                                            } onClick={() => HandleSlide2(2)}></button>
                                            <button disabled={
                                                slide2Active == 3 ? true : false
                                            } onClick={() => HandleSlide2(3)}></button>
                                            <button disabled={
                                                slide2Active == 4 ? true : false
                                            } onClick={() => HandleSlide2(4)}></button>
                                            <button disabled={
                                                slide2Active == 5 ? true : false
                                            } onClick={() => HandleSlide2(5)}></button>
                                            <button disabled={
                                                slide2Active == 6 ? true : false
                                            } onClick={() => HandleSlide2(6)}></button>
                                        </div>
                                        <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                            Previous
                                        </button>
                                        <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                                {/* slide 6 end */}
                                <div className={`lsa-content-1 type-2 update ${slide2Active === 6 && ' active'}`} id='LsaType2Content6'>
                                    <div className="las-body-1">
                                        <h6 className="lsa-dull">
                                            7 of 7
                                        </h6>
                                        <h3 className="lsa-title-h1">
                                            Losses by Loss Type
                                        </h3>
                                        <p>
                                            In the pie chart, view the annual losses by loss type, split by labor activity, line efficiency and budget losses.
                                        </p>

                                    </div>
                                    <div className="las-footer-1">
                                        <div className="dots-sec">
                                            <button disabled={
                                                slide2Active == 0 ? true : false
                                            } onClick={() => HandleSlide2(0)}></button>
                                            <button disabled={
                                                slide2Active == 1 ? true : false
                                            } onClick={() => HandleSlide2(1)}></button>
                                            <button disabled={
                                                slide2Active == 2 ? true : false
                                            } onClick={() => HandleSlide2(2)}></button>
                                            <button disabled={
                                                slide2Active == 3 ? true : false
                                            } onClick={() => HandleSlide2(3)}></button>
                                            <button disabled={
                                                slide2Active == 4 ? true : false
                                            } onClick={() => HandleSlide2(4)}></button>
                                            <button disabled={
                                                slide2Active == 5 ? true : false
                                            } onClick={() => HandleSlide2(5)}></button>
                                            <button disabled={
                                                slide2Active == 6 ? true : false
                                            } onClick={() => HandleSlide2(6)}></button>
                                        </div>
                                        <button className="btn-blue-text" onClick={() => HandleSlide2(0)}>
                                            Restart
                                        </button>
                                        <a className="btn-blue-bg" onClick={()=>{props.setCurrentTour(props.tourArray[3])}}>
                                            Next: Roadmap Configurator
                                        </a>
                                    </div>
                                </div>
                                {/* slide 6 end */}

                            </>
                        }
                        {/* footer has 
                        two buttons
                        one link
                    */}
                    </>
                </Modal>
            </div>
        </>
    );
}

