import styled from 'styled-components';


export const MainWrapper = styled.section`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    `;
export const UpgradePopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 9999;
`
export const UpgradePopWrap = styled.div`
    position: absolute;
    width: 66rem;
    min-height: 54.8rem;
    background: #FFFFFF;
    border: 0.1rem solid #F0F0F0;
    border-radius: 2.4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
`
export const UpgradePopHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 0.06rem solid #CCD6E8;
    align-items: center;
    padding: 2.4rem;
    .head-txt{
        color: #2C55A0;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.8rem;
    }
    .upgrade-right-head{
        display: inline-flex;
        align-items: center;
        .cart-btn{
            display: inline-flex;
            padding: 0.8rem 1.6rem;
            border-radius: 0.4rem;
            background: linear-gradient(132deg, #8686DF 0%, #6464C8 100%);
            color: #FFF;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 1.8rem;
            margin-right: 1.6rem;     
        }
    }
`
export const HowCloseBtn = styled.button`
    display: inline-block;
    border: unset;
    box-shadow: unset;
    height: 3.2rem;
    width: 3.2rem;
    svg{
        width: 100%;
        height: 100%;
    }
`
export const UpgradePopBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 2.2rem 1.2rem 2.4rem 1.2rem;
    p{
        color: #0D2C54;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;
        padding: 0 1.2rem;
        margin-bottom: 2.2rem;
    }
`
export const UpgradePopColWrap = styled.div`
    display: flex;
    align-items: center;
`
export const UpgradePopCard = styled.div`
    border-radius: 0.8rem;
    border: 0.06rem solid #6464C8;
    background: #F3F5FB;
    display: flex;
    flex-direction: column;
`
export const UpgradeCardHeader = styled.div`
    display: inline-flex;
    align-items: center;
    border-bottom: 0.06rem solid #6464C8;
    padding: 1.6rem 1.6rem 1.6rem 0;
    width: 100%;
    .head-icon{
        display: inline-block;
        height: 3.2rem;
        width: 4rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    span{
        display: inline-block;
        color: #6464C8;
        font-size: 1.4rem;
        font-weight: 600;
        margin-left: 0.8rem;
    }

`
export const UpgradeCardBody = styled.div`
    padding: 4.2rem 2rem;
`
export const UpgradeCardFooter = styled.div`
    padding: 0 1.6rem 2.6rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-cart-btn{
        border-radius: 6.2rem;
        background: #6464C8;
        box-shadow: 0px 0px 8px 0px rgba(21, 27, 38, 0.12);
        padding: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3.8rem;
        max-width: 27rem;
        width: 100%;
        .foot-text{
            display: inline-block;
            color: #FFF;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.8rem; 
            padding-right: 1rem;
            border-right: 0.1rem solid #ffffff;
        }
        .price{
            padding-left: 1rem;
            display: inline-block;
            color: #FFF;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.8rem;
        }
    }   
`
export const UpgradeCardRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`
export const UpgradeCol = styled.div`
    padding: 0 1.2rem;
    width: 50%;
    &:first-child{
        margin-bottom: 3.2rem;
    }
    &:nth-child(2){
        margin-bottom: 3.2rem;
    }
`
export const UpgradeCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    .card-txt{
        display: block;
        color: #0D2C54;
        font-size: 1.2rem;
        font-weight: 500;
        padding-top: 0.8rem;
    }
    .card-icon{
        display: block;
        height: 4rem;
        width: 4rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .pop-tooltip{
        border-radius: 0.4rem;
        border: 0.06rem solid #1487E3;
        background: #FFF;
        padding: 0.6rem 0.8rem;
        width: 21.6rem;
        position: absolute;
        left: calc(100% - 2rem);
        top: 0.3rem;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        .pop-txt{
            color: #4D4D4F;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.3rem;
            margin: 0;
            padding: 0;
        }
    }
    &:hover{
        .card-txt{
            color: #1487E3;
        }
        .card-icon{
            svg{
                path{
                    fill: #1487E3;
                }
            }
        }
        .pop-tooltip{
            opacity: 1;
            visibility: visible;
        }
    }
`
export const UpgradePopCol = styled.div`
    padding:  0 1.2rem;
    width: 50%;
    &:nth-child(2){
        ${UpgradeCard}{
            .pop-tooltip{
                left: unset;
                right: calc(100% - 1.5rem);
            }
        }
    }
`