import styled from "styled-components";

export const SiriDimentionWrapper = styled.section`
        width:100%;
        padding:3rem 3rem 0.8rem 3rem;
        min-height: calc(100vh - 7rem);
        display:flex;
        flex-direction:column;
        .md-block{
            display:none;
        }
        .sm-block{
            display:none;
        }
        @media(max-width:740px){
            padding: 65px 16px 5px 16px;
            .sm-block{
                display:block !important;
            }
            .sm-none{
                display:none;
            }
        }
`;
export const SiriDimentionCard = styled.section`
        background: #FFFFFF;
        border: 0.1rem solid #118ACB;
        border-radius: 0.6rem;
        padding:0.8rem 1.6rem 0.8rem 0.8rem;
        flex:1;   
        @media(max-width: 740px){
            border:0;
            background: transparent;
            padding: 0 4px 60px 4px;
        }
`;
export const NumberWrapper = styled.section`
                gap:1.4rem;
                align-items:center;
                display:flex;
`
export const Number = styled.div`
    display:inline-flex;
    justify-content:center;
    align-items:center;
    background: linear-gradient(91.83deg, #118ACB 12.22%, #0473AE 100.41%);
    border-radius: 0.6rem;
    color:#fff;
    font-weight: 500;
    font-size: 1.6rem;
    width:4.8rem;
    height:4.8rem;
    @media(max-width:740px){
        font-size:14px;
        width:48px;
        height:48px;
    }
`
export const NumberTextWrapper = styled.div`
    flex:1;
    .dull-title{
        font-size: 1.2rem;
        color:#7D868C;
        margin-bottom: 0.7rem;
        @media(max-width:740px){
            font-size:12px;
            margin-bottom: 10px;
        }
    }
    .title{
        font-weight: 500;
        font-size: 1.6rem;
        color:#4D4D4F;
        @media(max-width:740px){
            font-size:14px;
        }
    }
`
export const Status = styled.div`
      display:inline-flex;
      padding:0.3rem 1rem;
      font-weight: 600;
      font-size: 1.1rem;
      background: #FFFFFF;
      text-transform:uppercase;
      color:${({ status }) => status === 'inprogress' ? '#EF6C00' : status === 'validated' ? '#4CAF50' : '#F1B1BB'};
      border: 0.1rem solid ${({ status }) => status === 'inprogress' ? 'rgba(239, 108, 0, 0.6)' : status === 'validated' ? 'rgba(76, 175, 80, 0.6)' : '#F1B1BB'};
      border-radius: 2.5rem;
      
      @media(max-width:740px){
        font-size:12px;
      }
`
export const Column1 = styled.section`
  display: flex;
  align-items: ${({ align = "flex-start" }) => align};
  width: 32.9rem;
  padding-right: 1rem;
  border-bottom: 0.1rem solid
    ${({ borderBottom }) => (borderBottom ? "#E6E6E6" : "transparent")};
  .sm-responsive-text {
    display: none;
  }
  @media (max-width: 740px) {
    .md-responsive-text {
      display: none;
    }
    .sm-responsive-text {
      display: flex;
    }
    flex: 1;
  }
  .col-m1 {
    width: 9.6rem;
    @media (max-width: 740px) {
      width: 100%;
    }
    &.sm-responsive-text {
      .sm-qna-wrapper {
        @media(max-width: 740px){
          margin-top: 6px;
        }
      }
    }
  }
  .col-m2 {
    flex: 1;
  }
`;
export const Column2 = styled.section`
  flex: 1;
  border-bottom: 0.1rem solid
    ${({ borderBottom }) => (borderBottom ? "#E6E6E6" : "transparent")};
  align-items: ${({ align = "flex-start" }) => align};
  display: flex;
  
  
  &.col-head2{
    align-items: flex-start;
    gap: 5px; 
    flex-direction: column;
  }
  .plant-def-text {
    font-size: 12px ;
    font-weight: 500;
    padding-right:1rem;
    max-width:70%;
    @media(min-width:1100px){
      max-width:100%;
    }
    @media (max-width: 800px) {
      max-width:60%;
    }
  }
  .sm-responsive-text {
    display: none;
  }
  @media (max-width: 740px) {
    .md-responsive-text {
      display: none;
    }
    .sm-responsive-text {
      display: flex;
    }
    width: 80px;
  }
  .col-m1 {
    flex: 1;
  }
  .col-m2 {
    width: 20rem;
    display: flex;
    gap: 3rem;
    @media (max-width: 740px) {
      gap: 11px;
      width: 70px;
      flex-wrap: wrap;
    }
  }
`;
export const Column3 = styled.section`
             width:18.7rem;
             border-bottom:0.1rem solid ${({ borderBottom }) => borderBottom ? '#E6E6E6' : 'transparent'};
             align-items:${({ align = 'flex-start' }) => align};
             display: flex;
             position:relative;
`
export const Row = styled.section`
  min-height: 3.9rem;
  display: flex;
  position: relative;
  .header-col-title {
    font-size: 1.2rem;
    color: #7d868c;
    text-transform: uppercase;
    font-weight: 600;
    color:#4d4d4f;
    @media (max-width: 740px) {
      font-size: 12px;
    }
    &.col-definition {
      width: 30rem;
      padding-right: 10rem;
      @media (max-width: 740px) {
        display: none;
      }
    }
  }
  @media (max-width: 992px) {
    ${Column3} {
      display: none;
    }
    ${Column1} {
      width: 200px;
      .col-m2 {
        display: none;
      }
    }
  }
  @media (max-width: 740px) {
    ${Column1} {
      flex: 1 1 auto;
      width: calc(100% - 68px);
      align-items: center;
    }
  }
`;
export const ValidateBtn = styled.button`
             background: #43A047;
             border: 0.1rem solid #43A047;
             border-radius: 0.4rem;
             color:#fff;
             font-weight: 500;
             font-size: 1.6rem;
             padding:1rem 1.5rem;
             position:absolute;
             top:50%;
             right:0rem;
             transform:translateY(-50%);
             width:max-content;
             white-space:nowrap;
             box-shadow: 0px 0px 8px rgba(21, 27, 38, 0.12);
             z-index:1;
            @media(max-width:740px){
                font-size:14px;
                padding:10px 16px;
                position:fixed;
                left:50%;
                top:unset;
                transform:translateX(-50%);
                bottom:10px;
                width:calc(100% - 30px);
                min-height: 48px;
                &:after{
                    content: '';
                    bottom: -11px;
                    left: 0;
                    right: 0;
                    position: absolute;
                    background: #fafcfd;
                    width: 100%;
                    height: 10px;
                }
                span{
                    display:none;
                }
            }
`
export const ReValidateBtn = styled.button`
             background: #E5F0FF;
             border: 0.1rem solid #118ACB;
             border-radius: 0.4rem;
             color:#118ACB;
             font-weight: 500;
             font-size: 1.6rem;
             padding:1rem 1.5rem;
             position:absolute;
             top:50%;
             right:0rem;
             transform:translateY(-50%);
             width:max-content;
             white-space:nowrap;
             box-shadow: 0px 0px 8px rgba(21, 27, 38, 0.12);
             z-index:1;
             @media(max-width:740px){
                font-size:14px;
                padding:10px 16px;
                position:fixed;
                left:50%;
                top:unset;
                transform:translateX(-50%);
                bottom:10px;
                width:calc(100% - 20px);
                min-height: 48px;
                &:after{
                    content: '';
                    bottom: -11px;
                    left: 0;
                    right: 0;
                    position: absolute;
                    background: #fafcfd;
                    width: 100%;
                    height: 10px;
                }
                span{
                    display:none;
                }
            }
`
export const TBodyRow = styled.div`
                 width:100%;
                 .col-m1{
                    padding-right:1rem;
                    @media(max-width:740px){
                        padding-right:5px;
                    }
                }
                 
`
export const TBodyCol = styled.div`
  min-height: 5.6rem;
  align-items: flex-start;
  /* align-items: center;
  @media (max-width: 740px) {
    align-items: flex-start;
  } */
  display: flex;
  width: 100%;
  color: #4d4d4f;
  font-size: 1.2rem;
  border-bottom: 0.1rem solid
    ${({ borderBottom }) => (borderBottom ? "#E6E6E6" : "transparent")};

  .col-m2 {
    position: relative;
    span {
      display: flex;
      gap: 0.5rem;
    }
    .info-icon-i {
      display: inline-flex;
      //top: 0.4rem;
      margin-left: 0.4rem;
      cursor: pointer;
      position: relative;
      svg {
        width: 1.6rem;
        * {
          transition: 0.25s;
        }
        .circle {
          fill: #fff;
        }
      }
      &:hover,
      &.active {
        svg {
          .circle {
            fill: #118acb;
          }
          .line-i {
            stroke: #fff;
          }
        }
      }
    }
    &.col-definition {
      padding-right: 10rem;
      width: 30rem;
      &.sm-definition {
        display: none;
      }
      @media (max-width: 740px) {
        display: none;
        &.sm-definition {
          display: block;
          font-style: italic;
          font-weight: 500;
          font-size: 11px;
          padding-bottom:8px;
        }
      }
    }
  }
  .siri-qna-btn {
    color: #7d868c;
    font-size: 1.2rem;
    font-weight: 400;
    border-radius: 0.4rem;
    border: 0.06rem solid #7d868c;
    padding: 0.6rem 1.2rem;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    background: #fff;
    @media (max-width: 740px) {
      font-size: 10px;
      padding: 4px 0;
      width: max-content;
    }
    &:hover {
      color: #fff;
      border-color: #fff;
      background: #118acb;
    }
  }
  .sm-edited-txt {
    display: none;
    font-size: 12px;
    @media (max-width: 992px) {
      display: inline-block;
    }
    @media (max-width: 740px) {
      color: #2c55a0;
      width: calc(100% - 63px);
    }
    @media (max-width: 360px) {
      width: 100%;
    }
  }
  .edited-txt {
    position: absolute;
    top: 50%;
    left: -2rem;
    transform: translateY(-50%) translateX(-100%);
    @media (max-width: 992px) {
      display: none;
    }
  }
  .originally-txt {
    position: absolute;
    top: 50%;
    right: -2rem;
    transform: translateY(-50%) translateX(100%);
    @media (max-width: 992px) {
      display: none;
    }
  }
  @media (max-width: 740px) {
    min-height: 36px;
    font-size: 12px;
    padding: 12px 0px;
    flex-direction: column;
    border-bottom: 0;
    .col-m2 {
      flex-wrap: wrap;
    }
  }

  .sm-text {
    &.turncate {
      @media (max-width: 740px) {
        display: none !important;
      }
    }
  }
  .col-m1 {
    @media (max-width: 992px) {
      width: 17.5rem;
    }
    &.sm-responsive-text {
      @media (max-width: 740px) {
        flex-direction: column;
      }
    }
    .sm-text {
      @media (max-width: 740px) {
        margin-bottom: 6px;
        flex-wrap: wrap;
      }
    }
  }
  .sm-qna-text {
    display: none;
    @media (max-width: 740px) {
      display: inline-flex;
      justify-content: center;
      width: 63px;
      margin: 0;
    }
  }
  .sm-qna-wrapper {
    @media (max-width: 740px) {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 0 0;
    }
    @media (max-width: 360px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }
  .info-tooltip-wrapper {
    position: relative;
  }
`;
export const CapabilityBtn = styled.button`
             padding:0.5rem;
             min-width:8.3rem;
             min-height:3.2rem;
             font-size: 1.2rem;
             border-radius:0.4rem;
             display:inline-flex;
             align-items:center;
             justify-content:center;
             background:#F0F0F0;
             color:#4D4D4F;
             &.active{
                background:linear-gradient(91.83deg, #118ACB 12.22%, #0473AE 100.41%);
                color:#fff;
             }
             &.inactive{
                background:#7D868C;
                color:#fff;
             }
             @media(max-width:740px){
                font-size:12px;
                padding:4px 8px;
                min-width:44px;
                min-height:17px;
             }
`;
export const BodyRow = styled.section`
             min-height:3.9rem;
             display:flex;
             position:relative;
             color: #7D868C;
             &.column{
                margin-top: 2.4rem;
                @media(max-width: 740px){
                    margin-top: 0;
                }
            }
            @media(max-width: 740px){
                ${Column1}{
                    flex: 1 1 auto;
                    width: auto;
                }
                ${Column2}{
                    width: 68px;
                }
                border-bottom: 0.1rem solid #F0F0F0;
           }
             .header-col-title{
                font-size: 1.2rem;
                color:inherit;
                text-transform:uppercase;
                @media(max-width:740px){
                    font-size:12px;
                    width: auto;
                 }
             }
             .sm-text{
                display:none;
             }
             
            @media(max-width:992px){
                ${Column3}{
                    display:none;
                }
                ${Column1}{
                    width:20rem;
                    .md-text,.col-m2{
                        display:none;
                     }
                    .sm-text{
                        display: flex;
                        gap: 0.5rem;
                     }
                }
            }
            @media(max-width:740px){
                ${Column1}{
                    width: calc(100% - 68px);
                    .col-m1{
                        width:inherit;
                     }
                }
            }
`;
export const Header = styled.section`
  ${Row}{
    flex-wrap:wrap;
    ${Column1}{
        @media(max-width:740px){
            padding-right:115px;
            width:100%;
            margin-bottom:8px;
        }
        @media(max-width:740px){
            padding:12px;
            background:#fff;
            border-radius: 8px;
            border: 1px solid #F0F0F0;
            background: #FFF;
            flex-wrap: wrap;
            gap:12px;
            .title {
                color:#4D4D4F;
                font-size: 14px;
                font-weight: 600;
            }
            .plant-def-text {
              padding-top: 5px;
              margin-top: 5px;
              border-top: 1px solid #ccc;
              font-weight: 400;
              font-size: 12px;
              width:100%;
            }
        }
    }
  }
`;
// popup and mobile header css
export const SiriMobileTitleContainer = styled.div`
    display: none;
    padding: 8px 12px;
    align-items: center;
    background: #E5F0FF;
    position: fixed;
    top: 50px;
    height: 48px;
    left: 0;
    width: 100%;
    z-index: 2;
    .back-icon{
        border: 0;
        box-shadow: unset;
        height: 24px;
        width: 24px;
    }
    .back-icon:focus{
        outline: 0;
    }
    span{
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #0D2C54;
    }
    @media(max-width: 740px){
        display: flex;
    }
    @media(max-width: 700px){
        top: 59px;
    }

`
export const ValidatePopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 99;
`
export const ValidatePopWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 740px) {
    width: 100%;
  }
`;
export const ValidatePopCard = styled.div`
  background: #ffffff;
  border: 0.1rem solid #118acb;
  box-shadow: 0px 0px 0.8rem rgba(21, 27, 38, 0.12);
  border-radius: 0.6rem;
  padding: 4.4rem 2.4rem;
  min-width: 63rem;
  &.success-validate-popup{
    min-width:450px;
  }
  @media (max-width: 740px) {
    width: 100%;
    margin: auto;
    border:0;
    border-radius:0;
    min-width: unset !important;
    padding: 24px 12px;
  }
  h3 {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: #0d2c54;
    text-align: center;
    margin-bottom: 1.6rem;
    @media (max-width: 740px) {
      font-size: 16px;
      line-height: 28px;
    }
  }
  p {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #4d4d4f;
    margin-bottom: 4rem;
    text-align: center;
    @media (max-width: 740px) {
      font-size: 16px;
      line-height: 22px;
      font-weight:400;
    }
  }
`;
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;

    .cancel-btn{
        font-weight: 500;
        font-size: 1.5rem;
        min-width: 15rem;
        min-height: 4.4rem;
        background: transparent;
        color: #118acb;
        border-radius: 0.4rem;
        border: 0.1rem solid #118acb;
        margin-right: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 26px;
            min-width: 88px; 
            height:44px;
            margin-right: 16px;
        }
    }
    .validate-btn{
        font-weight: 500;
        font-size: 1.5rem;
        min-width: 15rem;
        min-height: 4.4rem;
        background: #118ACB;
        border-radius: 0.4rem;
        border: 0.1rem solid #118acb;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 26px;
            min-width: 120px; 
            height: 44px;
            font-weight:400;
        }
    }

`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  .note-btn{
    display: inline-block;
    color: #118ACB;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem; /* 118.75% */
    border-radius: 0.4rem;
    border: 0.1rem solid #118ACB;
    background: #E5F0FF;
    padding: 1rem 2rem;
  }
`
export const InfoTooltip = styled.div`
  position: absolute;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: -0.028rem;
  background: #fff;
  z-index: 1;
  width: 30.8rem;
  top: 0;
  right: -1rem;
  transform: translateX(100%);
  filter: drop-shadow(0px 0px 8px rgba(21, 27, 38, 0.12));
  padding: 1.6rem;
  border-radius: 0.8rem;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  max-height: 300px;
  overflow: auto;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  @media (max-width: 740px) {
    max-height: 200px;
    right: unset;
    transform: translateY(${({ lastRow }) => (lastRow ? "-100%" : "30px")});
    left: 0;
  }
  @media (max-width: 575px) {
    width: 250px;
    left: -100px;
  }
`;

export const ErrorMsgDiv = styled.div`
       position:fixed;
       top:100px;
       max-width:100%;
       width:280px;
       height:80px;
       left:50%;
       top:50%;
       box-shadow:0rem 0.168rem 0.672rem rgba(21, 27, 38, 0.1);
       transform:translate(-50%,-50%);
       opacity:${({ active }) => active ? '1' : '0'};
       visibility:${({ active }) => active ? 'visible' : 'hidden'};
       transition:0.25s ease-in;
       z-index:9999;
       background:#fff;
       border-radius:5px;
       padding:15px 20px;
       border:1px solid currentColor;
       color:${({ green }) => green ? '#4caf50' : '#ff8383'};
       font-size:14px;
       font-weight:500;
       display:flex;
       text-align:center;
       align-items:center;
       gap:10px;
       p{
        margin-bottom:0px;
        flex:1;
        color:inherit;
        font:inherit;
        font-weight:inherit;
       }
       @media(max-width:768px){
        max-width:100%;
       }
`;