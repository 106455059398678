export default [
    {
        dimension:1,
        dimension_name:`Dimension 1 - Process - Operations - Vertical Integration`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - Undefined`,
                example:`SOPs are not defined for the resource planning and technical production processes`
            },
            {
                band:1,
                band_name:`Band 1 - Defined`,
                example:`Manufacturing records and quality inspections data are managed through paper-based systems (e.g. logbook) and manually transferred to analogue tools (e.g. excel sheet)`
            },
            {
                band:2,
                band_name:`Band 2 - Digital`,
                example:`OT and IT systems (e.g. Manufacturing Execution System (MES), Enterprise Resource Planning (ERP), Warehouse Management System (WMS), Quality Management System (QMS), etc.) are used to manage and execute resource planning and technical production processes `
            },
            {
                band:3,
                band_name:`Band 3 - Integrated`,
                example:`Resource planning and technical production processes are integrated via a product life cycle management platform, data transfer is seamlessly executed and managed in the product design, development and manufacturing environment`
            },
            {
                band:4,
                band_name:`Band 4 - Automated `,
                example:`Work orders generated from a purchase order are directly managed, verified, planned and transferred to the shop floor for autonomous production `
            },
            {
                band:5,
                band_name:`Band 5 - Intelligent `,
                example:`Assets on the shop floor are embedded through the industrial ethernet, and analytical capabilities to autonomously obtain and analyze insights from operational data`
            },
        ]
    },
    {
        dimension:2,
        dimension_name:`Dimension 2 - Supply Chain - Horizontal Integration`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - Undefined`,
                example:`SOPs (e, g. policy, procedure, etc.) are not defined for the enterprise processes (e.g. procurement, sourcing, etc.)`
            },
            {
                band:1,
                band_name:`Band 1 - Defined`,
                example:`Enterprise processes data (e.g. sourcing, purchasing, supply chain management, logistics, etc.) are managed in analogue tools (e.g. paper-based, excel sheets, etc.)`
            },
            {
                band:2,
                band_name:`Band 2 - Digital`,
                example:`IT systems (e.g. ERP, Supply Chain Management (SCM) System, etc.) are used to manage and execute enterprise processes `
            },
            {
                band:3,
                band_name:`Band 3 - Integrated`,
                example:`Enterprise processes are integrated with external suppliers and customers (i.e. Electronic Data Interchange (EDI))`
            },
            {
                band:4,
                band_name:`Band 4 - Automated `,
                example:`Enterprise processes are connected via standard communication protocol and data transfer is seamlessly executed and managed in the supply chain environment (e.g. Supply Chain Control Network)`
            },
            {
                band:5,
                band_name:`Band 5 - Intelligent `,
                example:`The enterprise processes are managed autonomously through connecting data within factories and enterprises both on the premises and across external supply chain `
            },
        ]
    },
    {
        dimension:3,
        dimension_name:`Dimension 3 - Product Lifecycle - Integrated Product Lifecycle`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - Undefined`,
                example:`Processes along the product lifecycle is managed and executed based on individual knowledge and experience`
            },
            {
                band:1,
                band_name:`Band 1 - Defined`,
                example:`Design and engineering data are documented in analogue tools (e.g. paper-based, excel sheets, etc.)`
            },
            {
                band:2,
                band_name:`Band 2 - Digital`,
                example:`Digital tools (e.g. CAD system, CAM system, etc.) are used to manage and execute processes along the product lifecycle (e.g. R&D, Design, Engineering, Manufacturing, Customer Service, etc.) `
            },
            {
                band:3,
                band_name:`Band 3 - Integrated`,
                example:`Product lifecycle processes are integrated via a product life cycle management platform, data transfer is seamlessly executed and managed in the product design, development and manufacturing environment`
            },
            {
                band:4,
                band_name:`Band 4 - Automated `,
                example:`Product lifecycle management platform automatically manages and executes the changes in product specifications, and govern the workflows with the stakeholders`
            },
            {
                band:5,
                band_name:`Band 5 - Intelligent `,
                example:`Digital twin and big data solutions are used to analyze and optimize within product lifecycle environment`
            },
        ]
    },
    {
        dimension:4,
        dimension_name:`Dimension 4 - Technology - Automation - Shop Floor Automation`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`Repetitive production and support processes are not automated (e.g. manual lathe, manual assembly, etc.)`
            },
            {
                band:1,
                band_name:`Band 1 - Basic`,
                example:`Repetitive production processes are partially automated (e.g. CNC machine) with the assistance of equipment, machinery and computer-based systems`
            },
            {
                band:2,
                band_name:`Band 2 - Advanced`,
                example:`Repetitive production processes are automated with equipment, machinery and computer-based systems (e.g. Surface Mount Technology production line, pick and place robot in the assembly line, automated refinery, etc.)`
            },
            {
                band:3,
                band_name:`Band 3 - Full`,
                example:`Repetitive production processes are fully automated (e.g. fully automated robotics line)`
            },
            {
                band:4,
                band_name:`Band 4 - Flexible`,
                example:`The equipment, machinery and computer-based systems are able to reconfigure autonomously based on the changing requirements`
            },
            {
                band:5,
                band_name:`Band 5 - Converged`,
                example:`The equipment, machinery and computer-based systems are integrated across other computer-based systems with the use of analytics to self-optimize processes based on the changes in the working environment`
            },
        ]
    },
    {
        dimension:5,
        dimension_name:`Dimension 5 - Technology - Automation - Enterprise Automation`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`The enterprise processes are not automated. Analogue tools (e.g. excel spreadsheet, paper-based, ledger, etc.) are used for information management`
            },
            {
                band:1,
                band_name:`Band 1 - Basic`,
                example:`The enterprise processes are partially automated through assistance of basic computer-based systems (e.g. excel). Data entry is input manually`
            },
            {
                band:2,
                band_name:`Band 2 - Advanced`,
                example:`The enterprise processes are automated through workflow-based computer-based systems (e.g. ERP). Data entry is managed by computer-based systems`
            },
            {
                band:3,
                band_name:`Band 3 - Full`,
                example:`The enterprise processes are fully automated through computer-based systems (e.g. Robotic Process Automation (RPA)). Data entry is managed by computer-based systems`
            },
            {
                band:4,
                band_name:`Band 4 - Flexible`,
                example:`The enterprise processes are fully automated through automated computer-based systems and could be re-configured easily to adapt to the new requirements`
            },
            {
                band:5,
                band_name:`Band 5 - Converged`,
                example:`The enterprise systems are integrated across the shop floor and facility systems with the use of analytics to self-optimize processes based on the changes in the working environment`
            },
        ]
    },
    {
        dimension:6,
        dimension_name:`Dimension 6 - Technology - Automation - Facility Automation`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`The facility processes are not automated (e.g. on/off switch, silo-controlled compressor, etc.)`
            },
            {
                band:1,
                band_name:`Band 1 - Basic`,
                example:`The facility processes are partially automated (e.g. Heat, Ventilation and Air-conditioning (HVAC) with the assistance of equipment, machinery and computer-based systems (e.g. programmable logic control (PLC) systems)`
            },
            {
                band:2,
                band_name:`Band 2 - Advanced`,
                example:`The facility processes (e.g. dust collector, pH monitoring, air compressor, heater, water treatment, etc.) are automated via the Building/Facility Management System (BMS)`
            },
            {
                band:3,
                band_name:`Band 3 - Full`,
                example:`The facility processes are fully automated and monitored by equipment, machinery and computer-based systems (e.g. Building management system (BMS))`
            },
            {
                band:4,
                band_name:`Band 4 - Flexible`,
                example:`The facility processes are fully automated using equipment, machinery and computer-based system are able to re-configure to adapt to the new requirements`
            },
            {
                band:5,
                band_name:`Band 5 - Converged`,
                example:`The facility systems are integrated across the shop floor and enterprise systems with the use of analytics to self-optimize processes based on the changes in the working environment `
            },
        ]
    },
    {
        dimension:7,
        dimension_name:`Dimension 7 - Technology - Connectivity - Shop Floor Connectivity`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`Equipment, machinery and computer-based systems are in stand-alone mode and are not connected to the local IT network`
            },
            {
                band:1,
                band_name:`Band 1 - Connected`,
                example:`Equipment, machinery and computer-based systems are connected to the local IT network via a communication protocol (e.g. field bus, VLAN, etc.)`
            },
            {
                band:2,
                band_name:`Band 2 - Interoperable`,
                example:`Equipment, machinery and computer-based systems exchange information with one another using standard communication protocol (e.g., MT connect XML-based standard, OPC UA, etc.)`
            },
            {
                band:3,
                band_name:`Band 3 - Interoperable & Secure`,
                example:` Equipment, machinery and computer-based systems interact and exchange information via a common encrypted network platform (e.g., OPC Unified Architecture, DMZ VLAN etc.) `
            },
            {
                band:4,
                band_name:`Band 4 - Real-time`,
                example:`Equipment, machinery, and computer-based systems exchange information in real-time via wireless communication (WIFI). Information can be accessed remotely (e.g., mobile phones and tablets) using Application Program Interface (API))`
            },
            {
                band:5,
                band_name:`Band 5 - Scalable`,
                example:`The existing network could be configured and accommodate any modification to the equipment, machinery and computer-based systems (e.g. collaborative robot (COBOT) or automated guided vehicle (AGV) plug-and-play into the existing systems)`
            },
        ]
    },
    {
        dimension:8,
        dimension_name:`Dimension 8 - Technology - Connectivity - Enterprise Connectivity `,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`Computer-based systems are in stand-alone mode and are not connected to the local IT network`
            },
            {
                band:1,
                band_name:`Band 1 - Connected`,
                example:`Computer-based systems are connected to the local IT network via a communication protocol (e.g. TCP/IP)`
            },
            {
                band:2,
                band_name:`Band 2 - Interoperable`,
                example:`Computer-based systems interact and exchange data with the enterprise platform (e.g. integrated enterprise platform)`
            },
            {
                band:3,
                band_name:`Band 3 - Interoperable & Secure`,
                example:`Computer-based systems interact and exchange information via a common encrypted network platform (e.g. integrated enterprise platform)`
            },
            {
                band:4,
                band_name:`Band 4 - Real-time`,
                example:`Enterprise systems interact and exchange information in real-time with internal and external stakeholders in real-time state`
            },
            {
                band:5,
                band_name:`Band 5 - Scalable`,
                example:`Fully Integrated end-to-end planning system, comprising real-time information along the entire value chain from sales forecasts to production planning and logistics`
            },
        ]
    },
    {
        dimension:9,
        dimension_name:`Dimension 9 - Technology - Connectivity - Facility Connectivity`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`Equipment, machinery and computer-based systems are in stand-alone mode and are not connected to the local IT network`
            },
            {
                band:1,
                band_name:`Band 1 - Connected`,
                example:`Equipment, machinery and computer-based systems are connected to the local IT network via a communication protocol (e.g. field bus, VLAN, etc.)`
            },
            {
                band:2,
                band_name:`Band 2 - Interoperable`,
                example:`Equipment, machinery and computer-based systems interact and exchange information from central facility management system (e.g., BMS, SCADA, PLC, etc.)`
            },
            {
                band:3,
                band_name:`Band 3 - Interoperable & Secure`,
                example:`Equipment, machinery and computer-based systems interact and exchange information via a common encrypted network platform`
            },
            {
                band:4,
                band_name:`Band 4 - Real-time`,
                example:`Equipment, machinery and computer-based systems can interact and exchange information in real-time via wireless communication (WI-FI). Information can be accessed remotely (e.g., mobile phones and tablets) using Application program interface (API))`
            },
            {
                band:5,
                band_name:`Band 5 - Scalable`,
                example:`Scalable and platform-independent communication interface to exchange information across various systems`
            },
        ]
    },
    {
        dimension:10,
        dimension_name:`Dimension 10 - Technology - Intelligence - Shop Floor Intelligence`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:` Processing and analysis of data are manually performed by humans`
            },
            {
                band:1,
                band_name:`Band 1 - Computerized`,
                example:`OT and IT systems are used for data processing`
            },
            {
                band:2,
                band_name:`Band 2 - Visible`,
                example:`Digital dashboards for production performance visualization and to notify operators of deviations from predefined parameters. (e.g. machine stoppage, poor quality, changeover duration, etc.) based on captured PLC data`
            },
            {
                band:3,
                band_name:`Band 3 - Diagnostic`,
                example:`Implementation of statistical process control (SPC) module to alert the operators in the event of deviation from the required specification and provide the root cause analysis `
            },
            {
                band:4,
                band_name:`Band 4 - Predictive`,
                example:`Equipment, machinery, and computer-based system predict the future state of assets and systems using data analytics. (e.g. Predictive Maintenance, Predictive Planning, etc.)`
            },
            {
                band:5,
                band_name:`Band 5 - Adaptive`,
                example:`Equipment, machinery, and the computer-based system can diagnose problems and predict future states using advanced analytical techniques (e.g. AI, predictive analysis, natural language processing, machine learning etc.) and autonomously execute decisions to adapt to changes in working conditions`
            },
        ]
    },
    {
        dimension:11,
        dimension_name:`Dimension 11 - Technology - Intelligence - Enterprise Intelligence`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`Processing and analysis of data are manually performed by humans`
            },
            {
                band:1,
                band_name:`Band 1 - Computerized`,
                example:`Computer-based systems (e.g. ERP, CRM, etc.) identify deviations and provides root cause analysis (e.g. delay in shipment due to custom clearance)`
            },
            {
                band:2,
                band_name:`Band 2 - Visible`,
                example:`Computer-based systems can generate an alert automatically in the event of a deviation from defined target parameters (i.e. delays in shipment, alert for missing target financial targets, etc.) `
            },
            {
                band:3,
                band_name:`Band 3 - Diagnostic`,
                example:`Computer-based systems predict the future state of assets and systems using data analytics (e.g. market demand, forecasting, supply chain planning, etc.)`
            },
            {
                band:4,
                band_name:`Band 4 - Predictive`,
                example:`Computer-based system diagnose problems, predict future states using advanced analytical techniques (e.g. AI, Big data analysis, etc.) and autonomously execute decisions to adapt to changes in working conditions`
            },
            {
                band:5,
                band_name:`Band 5 - Adaptive`,
                example:`Enterprise computer-based system diagnose problems, predict future states using advanced analytical techniques (e.g. AI, predictive analysis, natural language processing, etc.) and autonomously execute decisions to adapt to changes in working conditions`
            },
        ]
    },
    {
        dimension:12,
        dimension_name:`Dimension 12 - Technology - Intelligence - Facility Intelligence`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`Processing and analysis of facility data are manually performed by humans`
            },
            {
                band:1,
                band_name:`Band 1 - Computerized`,
                example:`IT and OT systems are used for data processing`
            },
            {
                band:2,
                band_name:`Band 2 - Visible`,
                example:`Digital dashboards for production performance visualization and to notify operators of deviations from predefined parameters. (e.g. machine stoppage, overheating, high HEPA count, etc.) based on captured PLC data`
            },
            {
                band:3,
                band_name:`Band 3 - Diagnostic`,
                example:`Implementation of building management system and the application of data mining techniques to alert the relevant personnel in the event of deviation from the required specifications and provide root cause analysis`
            },
            {
                band:4,
                band_name:`Band 4 - Predictive`,
                example:`Advanced digital process control systems (e.g. BMS) with IoT enabled sensors to monitor, analyze and predict energy consumption in a real-time state`
            },
            {
                band:5,
                band_name:`Band 5 - Adaptive`,
                example:`Equipment, machinery, and computer-based systems diagnose problems, predict future states using advanced analytical techniques (e.g. AI, predictive analysis, natural language processing, etc.) and autonomously execute decisions to adapt to changes in working conditions`
            },
        ]
    },
    {
        dimension:13,
        dimension_name:`Dimension 13 - Organization - Talent Readiness - Workforce Learning & Development`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - Informal`,
                example:`There is no formal learning and development curriculum to onboard and train the workforce. The skillsets for the employee are acquired through informal methods (e.g. observation, verbal communication, etc.)`
            },
            {
                band:1,
                band_name:`Band 1 - Structured`,
                example:`There is a formal learning and development curriculum to onboard and train the new employees (e.g. On Job Training)`
            },
            {
                band:2,
                band_name:`Band 2 - Continuous`,
                example:`The company has a structured learning and development curriculum in place for the employees`
            },
            {
                band:3,
                band_name:`Band 3 - Integrated`,
                example:`The company has a continuous learning and development curriculum for the enhancement and expansion of employees’ skillsets`
            },
            {
                band:4,
                band_name:`Band 4 - Adaptive`,
                example:`The company analyses the skill gaps of the employees regularly with formal feedback and engagement plans`
            },
            {
                band:5,
                band_name:`Band 5 - Forward-looking `,
                example:`The learning and development curriculum are being reviewed and revised regularly to incorporate requirements for future skillsets`
            },
        ]
    },
    {
        dimension:14,
        dimension_name:`Dimension 14 - Organization - Talent Readiness - Leadership Competency`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - Unfamiliar`,
                example:`The management team is unfamiliar with the most recent trends and technologies (e.g. Industry 4.0, Big data, Machine Learning (ML), etc.)`
            },
            {
                band:1,
                band_name:`Band 1 - Limited Understanding`,
                example:`Majority of the department heads have limited awareness of the latest technology trends and its potentials`
            },
            {
                band:2,
                band_name:`Band 2 - Informed`,
                example:`The management team understands the concepts of Industry 4.0 and is informed of the technologies (e.g. Digital Twin, Machine Learning, etc.) that can be implemented `
            },
            {
                band:3,
                band_name:`Band 3 - Semi-dependent`,
                example:`The management team is fully informed and has an Industry 4.0 strategy to transform the company. However, they are dependent on external partners on the advisory and implementation of the Industry 4.0 concepts and technologies`
            },
            {
                band:4,
                band_name:`Band 4 - Independent`,
                example:`The management team proactively adapts its organizational transformation framework based on the changing Industry 4.0 trends`
            },
            {
                band:5,
                band_name:`Band 5 - Adaptive`,
                example:`The company is recognized as an Industry 4.0 champion with the latest concepts and requirements`
            },
        ]
    },
    {
        dimension:15,
        dimension_name:`Dimension 15 - Organization - Structure & Management - Inter and Intra-Company Collaboration`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - Informal`,
                example:`Teams only have discussions when there is a problem in the factory. Otherwise, periodic meetings are not conducted`
            },
            {
                band:1,
                band_name:`Band 1 - Communicating`,
                example:`Periodic meetings (e.g. toolbox meeting, quality improvement team meeting, etc.) are scheduled and conducted and discussions are articulated through emails or intra-net forums`
            },
            {
                band:2,
                band_name:`Band 2 - Cooperating`,
                example:`The procurement and sales teams work together to generate a demand forecast for better inventory management `
            },
            {
                band:3,
                band_name:`Band 3 - Coordinating`,
                example:`The teams are coordinating with suppliers in real-time through remote systems, and cost savings and benefits are shared with the suppliers`
            },
            {
                band:4,
                band_name:`Band 4 - Collaborating`,
                example:`Teams collaborate with suppliers to improve the quality of raw materials`
            },
            {
                band:5,
                band_name:`Band 5 - Integrated`,
                example:`Flat hierarchies and quick decision-making amongst different internal stakeholders`
            },
        ]
    },
    {
        dimension:16,
        dimension_name:`Dimension 16 - Strategy and Governance`,
        bandList:[
            {
                band:0,
                band_name:`Band 0 - None`,
                example:`The company doesn’t have any intention to implement Industry 4.0 technologies`
            },
            {
                band:1,
                band_name:`Band 1 - Formalization`,
                example:`The company has an intention to implement certain Industry 4.0 technologies in their factory`
            },
            {
                band:2,
                band_name:`Band 2 - Development`,
                example:`Developing a transparent governance process to steer the development and implementation efforts for a smart factory transformation`
            },
            {
                band:3,
                band_name:`Band 3 - Implementation`,
                example:`The company has started rolling out pilot initiatives in certain functional areas`
            },
            {
                band:4,
                band_name:`Band 4 - Scaling`,
                example:`The company has a dedicated team to oversee the proof of concept, and scaling up to other key areas to maximize the benefits`
            },
            {
                band:5,
                band_name:`Band 5 - Adaptive`,
                example:`A strategy is implemented and regularly reviewed to incorporate the latest advancement in technology `
            },
        ]
    },
]