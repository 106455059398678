import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ScenarioDropdown from "./ScenarioDropdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios';
// images that we have used in page
import {
  angleIcon,
  closeSvg,
  collapseIcon,
  collapseOpenIcon,
  infoIcon,
  digitalSvg,
} from "./svg-step2";
// components that we have used in page
import {
  MainCardWrapper,
  TabCardWrapper,
  TabContentWraaper,
  RightAlignTextWrapper,
  HeadText,
  SubText,
  AddDragBtn,
  LossTopWrap,
  CollapseBtn,
  TabPanelContent,
  LossScrollTableWrap,
  InnerTabWrapper,
  InnerTBRightCol,
  LossTableWrap,
  ScndRgtTabWrapper,
  BlueRecoverText,
  BCPopupWrapper,
  BCPopHead,
  BCPopCard,
  BCPopBody,
  LossLevelWrapper,
  LossLevelWrap,
  LossLevelList,
  CloseTabBtn,
  LossLineList,
  InnerTBLeftCol
} from "./Roadmap-style-step2";
//common components that we have used in page
import {
  MainWrapper,
  SectionWrapper,
  LeftSection,
  RightSection,
  SectionHeader,
  ContentBody,
  ContentFooter,
  HeaderTitle,
  ScenarioSectionWrapper,
  TabButton,
  TBRow,
  TBLeftCol,
  TBRightCol,
  LossWrap,
  LossCol,
  LossRow,
  ProceedPopWrapper,
  ProceedPopCard,
  PopHead,
  PopFooter,
  RecoverablePopWrap,
  RecoverCloseBtn,
  InfoIcon,
  RecoverPopCard,
  ScndTabButton,
  RightAlignTextWrap,

  DefaultScenarioPop,
  DefaultScenarioPopupWrapper,
  LoaderPopupWrap,
  LoaderPop,
} from "./common-roadmap-style";

export const RoadmapStepScndView = (props) => {
  const stepdata = props.stepData;
  const user = JSON.parse(localStorage.getItem("userData"));
  const uid = user ? user.uid : 0;
  const history = useHistory();
  //variable declaration
  const [isRecoverPop, setIsRecoverPop] = useState(true);
  const [closeBCPop, setCloseBCPop] = useState(false);
  const [isActiveCard, setIsActiveCard] = useState(false);
  const [rightLineLevel, setRightLineLevel] = useState(false);
  const [rightFactoryLevel, setRightFactoryLevel] = useState(false);
  const [rightSelectedLine, setRightSelectedLine] = useState(0);
  // Collapse
  const [leftOpen, setLeftOpen] = React.useState(false);;
  const [lossLeftDataOpen, setLossLeftDataOpen] = React.useState(false);

  const [scenarioList, setScenarioList] = useState(stepdata.getAllScenario);
  const [isMakeDefault, setIsMakeDefault] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isDefaultScenarioPopup, setIsDefaultScenarioPopup] = useState(false);
  const [rightSectionLineInput, setRightSectionLineInput] = useState({});
  const [planttotalloss, setplanttotalloss] = useState(stepdata?.planttotalloss);
  const [planttotalrecoverable, setplanttotalrecoverable] = useState(stepdata?.planttotalrecoverable);
  const [planttotallossper, setplanttotallossper] = useState(stepdata?.planttotallossper);
  const [planttotalinvestment, setplanttotalinvestment] = useState(stepdata?.planttotalinvestment);


  const makeDefaultRef = useRef();



  //Right side open popup   
  const [openPopup, setOpenPopup] = useState(false);
  //  Left cards array declaration
  const [leftCards, setLeftCards] = useState(stepdata.usernodes || []);
  // tabs id variables
  const [leftValue, setLeftValue] = React.useState(`l0-0`);
  // Tabs Function
  const handleLeftChange = (event, newValue) => {
    setLeftValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>

      </>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  //   function to handle left tab collapse
  const handleLeftClick = (id, index) => {
    setLeftValue(`l0-${index}`);
    if (leftOpen === id) {
      setLeftOpen(false);
    }
    else {
      setLeftOpen(id);
    }
  };
  //   function to handle left inner tab collapse
  const handleLeftInnerTabClick = (val) => {
    if (lossLeftDataOpen === val) {
      setLossLeftDataOpen(false);
    }
    else {
      setLossLeftDataOpen(val);
    }
  };
  //   Function to handle right popup
  const handleRightCard = (item, count) => {
    if (isActiveCard?.id != item?.id) {
      setCloseBCPop(false);
    }
    let tmpLineLavelData = item?.sublossdata?.filter(i => i.is_line == 1);
    let tmpFactoryLavelData = item?.sublossdata?.filter(i => (i.is_line == 0 && i.subloss));
    setIsActiveCard({ ...item, count });
    setRightLineLevel(tmpLineLavelData || []);
    setRightFactoryLevel(tmpFactoryLavelData || []);
    setRightSelectedLine(stepdata?.allLines[0]);
  }

  const yesMakeDefaultFunction = () => {
    setIsDefaultScenarioPopup(false);
    setIsLoader(true);
    let tmpArr = scenarioList.map(i => {
      let tmpObj = i
      if (isMakeDefault.id === tmpObj.id) {
        tmpObj.is_default = 1;
      }
      else {
        tmpObj.is_default = false;
      }
      return tmpObj;
    });
    const tmp_sid = isMakeDefault.id || stepdata.sid;
    serviceProvider.post("/roadmapcomplete?param=makeDefaultScenario", JSON.stringify({ pid: stepdata.plant_id, sid: tmp_sid })).then((response) => {

      let redirectUrl = '/roadmap-complete/step1/' + stepdata.plant_slug + "/" + tmp_sid;
      // window.location.href = '/roadmap-complete/step1/' + stepdata.plant_slug + "/" + isMakeDefault.id;
      history.push(redirectUrl);

    });
    setScenarioList(tmpArr);
  }

  const saveRoadmap = () => {
    // serviceProvider.post("/roadmapcomplete?param=saveroadmap", JSON.stringify({ data: rightCards, uid: uid, pid: stepdata.plant_id, slug: stepdata.plant_slug })).then((response) => {
    //   window.location.href = '/roadmap-complete/step3/' + stepdata.plant_slug + "/" + response.data;
    // });
    // window.location.href = '/roadmap-complete/step3/' + stepdata.slug + "/" + 1;
    let redirectUrl = '/roadmap-complete/step3/' + stepdata.plant_slug + "/" + stepdata.sid;
    history.push(redirectUrl);
    setOpenPopup(false)
  }

  const handleRightRecoverablePrecent = (e, snid, lid, slcid, mainVal, input_name,total_input_name) => {
    let val = e.target.value || 0;
    // sid,snid,pid,lid,slcid,perc,newrec
    let sid = stepdata.sid;
    let pid = stepdata.pid;
    let perc = val / 100;
    let total = parseFloat(mainVal.replace(/,/g, ''))
    let newrec = perc * total;
    if (!val) {
      setRightSectionLineInput(prv => {
        return {
          ...prv,
          [input_name]: val,
          [total_input_name]:parseInt(newrec) || 0
        }
      })
    }
    document.getElementById(`${input_name}`).value = parseInt(newrec);
    serviceProvider.post("/roadmapcomplete?param=savelinerecoverable", JSON.stringify({ pid: pid, sid: sid, snid: snid, lid: lid, slcid: slcid, perc: perc, newrec: newrec })).then((response) => {
      setplanttotalloss(response.data.planttotalloss);
      setplanttotalrecoverable(response.data.planttotalrecoverable);
      let invst = document.getElementById(`invst-${snid}`);
      let invstt =  document.getElementById(`invstt-${snid}`);
      let invsttp =  document.getElementById(`invsttp-${snid}`);
      if(invst){
        invst.innerHTML = response.data.totalrecoverablenode + 'SR';
      }
      if(invstt){
        invstt.innerHTML = response.data.totalrecoverablenode + 'SR';
      }
      if(invsttp){
        invsttp.innerHTML = response.data.totalrecoverablenodep;
      }     
      setplanttotallossper(response.data.planttotallossper);
      //setplanttotalinvestment(response.planttotalinvestment);


    });

  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/home">Plant name</BreadcrumbsItem>
      <BreadcrumbsItem to={`/roadmap-complete/step1/${stepdata.slug}/${stepdata.sid}`}>IR4.0 Roadmap Solutions</BreadcrumbsItem>
      <BreadcrumbsItem to="#">IR4.0 Savings</BreadcrumbsItem>
      {/* End */}
      <MainWrapper data-component="MainWrapper" className="step-scnd">
        <SectionWrapper data-component="SectionWrapper">
          <LeftSection data-component="LeftSection">
            <SectionHeader data-component="SectionHeader">
              <HeaderTitle data-component="HeaderTitle">
                Roadmap
              </HeaderTitle>
            </SectionHeader>

            <LossTopWrap data-component="LossTopWrap">
              <LossRow data-component="LossRow">
                <LossCol data-component="LossCol">
                  <span className="text">Total Losses</span>
                  <span className="text">Identified:</span>
                  <span className="text orange">{planttotalloss || 0} SR</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">Total Roadmap</span>
                  <span className="text"> Recoverable:</span>
                  <span className="text green">{planttotalrecoverable || 0} SR</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">% of Recoverable</span>
                  <span className="text"> Losses:</span>
                  <span className="text blue">{planttotallossper || 0}%</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">Total Roadmap</span>
                  <span className="text">Investment:</span>
                  <span className="text orange">{planttotalinvestment || 0} SR</span>
                </LossCol>
              </LossRow>
            </LossTopWrap>
            <ContentBody data-component="ContentBody">
              <div>
                {leftCards.map((card, index) => (
                  <div
                    className="card"
                  >
                    <MainCardWrapper
                      data-component="MainCardWrapper"
                    >
                      <TabCardWrapper data-component="TabCardWrapper">
                        <TabButton data-component="TabButton">
                          <TBRow data-component="TBRow">
                            <TBLeftCol data-component="TBLeftCol">
                              <button
                                className="drag-btn blue"
                              >
                                <span>{index + 1}</span>
                              </button>
                              <p className='rmap-txt'>
                                {card.name}
                              </p>
                            </TBLeftCol>
                            <TBRightCol data-component="TBRightCol">
                              <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                <RightAlignTextWrap data-component="RightAlignTextWrap">
                                  <HeadText data-component="HeadText">Recoverable: </HeadText>
                                  <SubText data-component="SubText" id={`invst-${card.id}`} green>{card.totalrecoverable}SR</SubText>
                                </RightAlignTextWrap>
                                <RightAlignTextWrap data-component="RightAlignTextWrap">
                                  <HeadText data-component="HeadText">Investment: </HeadText>
                                  <SubText data-component="SubText" orange>{card.upfrontInvestment}SR</SubText>
                                </RightAlignTextWrap>
                              </RightAlignTextWrapper>
                              <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftClick(card.id, index)} className={`${leftOpen === card.id ? 'active' : ''}`}>
                                {leftOpen === card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                              </CollapseBtn>
                              <AddDragBtn data-component="AddDragBtn" onClick={() => handleRightCard(card, index + 1)}>
                                <i dangerouslySetInnerHTML={{ __html: angleIcon }}></i>
                              </AddDragBtn>
                            </TBRightCol>
                          </TBRow>
                        </TabButton>
                        <Collapse in={leftOpen === card.id} timeout="auto" unmountOnExit>
                          <TabContentWraaper data-component="TabContentWraaper">
                            <Tabs
                              orientation="horizontal"
                              variant="scrollable"
                              value={leftValue}
                              onChange={handleLeftChange}
                              aria-label="Vertical tabs example"

                            >
                              <Tab value={`l0-${index}`} label="Description" {...a11yProps(0)} />
                              <Tab value={`l1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} />
                            </Tabs>

                            <TabPanel value={leftValue} index={`l0-${index}`}>
                              <TabPanelContent data-component="TabPanelContent">
                                <div dangerouslySetInnerHTML={{ __html: card?.description }}></div>
                              </TabPanelContent>
                            </TabPanel>
                            <TabPanel value={leftValue} index={`l1-${index}`}>
                              {/* ----------------------l--------------------- */}
                              {Array.isArray(card?.sublossdata) &&
                                card?.sublossdata?.map((sublossItem, sIndex) => (
                                  <div key={sIndex}>
                                    <InnerTabWrapper data-component="InnerTabWrapper">
                                      <TBRow data-component="TBRow">
                                        <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                          <p className="inner-tab-txt">{sublossItem?.lossCategorytext}</p>
                                        </InnerTBLeftCol>
                                        <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                          <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Total Category Losses: </HeadText>
                                              <SubText data-component="SubText" orange>{sublossItem?.totallosscategory}SR</SubText>
                                            </RightAlignTextWrap>
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Recoverable: </HeadText>
                                              <SubText data-component="SubText" green>{sublossItem?.totalcatrecoverable}SR</SubText>
                                            </RightAlignTextWrap>
                                          </RightAlignTextWrapper>
                                          <p className='recovery-txt'>
                                            <span>{sublossItem?.perlosscatrec == 'nan' ? 0 : sublossItem?.perlosscatrec}% </span>
                                            of category <br />losses Recovered
                                          </p>
                                          <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftInnerTabClick(`loss0-${index}-${sIndex}`)} className={`${lossLeftDataOpen === `loss0-${index}-${sIndex}` ? 'active' : ''}`}>
                                            {lossLeftDataOpen === `loss0-${index}-${sIndex}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                          </CollapseBtn>
                                        </InnerTBRightCol>
                                      </TBRow>
                                    </InnerTabWrapper>
                                    <Collapse in={lossLeftDataOpen === `loss0-${index}-${sIndex}`} timeout="auto">
                                      <LossTableWrap data-component="LossTableWrap">
                                        <LossScrollTableWrap data-component="LossScrollTableWrap">
                                          <table className='responsive-loss-table'>
                                            <thead>
                                              <tr>
                                                <th className='loss-table-col'>Sublosses</th>
                                                <th className='loss-table-col'>
                                                  Identified<br /> Losses
                                                </th>
                                                <th className='loss-table-col'>Recoverable</th>
                                                <th className='loss-table-col'>% of Subloss<br />recoverable </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Array.isArray(sublossItem?.subloss) && sublossItem?.subloss?.map((subloss) => (
                                                <tr>
                                                  <td className='loss-table-col'>{subloss?.subloss_category_text}</td>
                                                  <td className='loss-table-col orange' dangerouslySetInnerHTML={{ __html: subloss?.totalsublossrecoverable + '<span>SR</span>' }}></td>
                                                  <td className='loss-table-col green' dangerouslySetInnerHTML={{ __html: subloss?.totalsubcatrecoverable + '<span>SR</span>' }}></td>
                                                  <td className='loss-table-col blue'>{subloss?.lossrecoverdp}%</td>
                                                </tr>
                                              ))
                                              }

                                              <tr>
                                                <td className='loss-table-col bottom black'>Total</td>
                                                <td className='loss-table-col bottom black'>{sublossItem?.totallosscategory}<span>SR</span></td>
                                                <td className='loss-table-col bottom black'>{sublossItem?.totalcatrecoverable}<span>SR</span></td>
                                                <td className='loss-table-col bottom black'></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </LossScrollTableWrap>
                                      </LossTableWrap>
                                    </Collapse>
                                  </div>
                                ))
                              }


                              <LossWrap data-component="LossWrap">
                                <LossRow data-component="LossRow">
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">Associated</span>
                                    <span className="text">Losses:</span>
                                    <span className="text green">{card.totalloss} SR</span>
                                  </LossCol>
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">Total Technology</span>
                                    <span className="text"> Recoverable:</span>
                                    <span className="text orange">{card.totalrecoverable} SR</span>
                                  </LossCol>
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">% of Total</span>
                                    <span className="text"> Recovered Losses:</span>
                                    <span className="text blue">{card.totallossrecoverdp?.toFixed(2)}%</span>
                                  </LossCol>
                                </LossRow>
                              </LossWrap>

                            </TabPanel>
                          </TabContentWraaper>
                        </Collapse>
                      </TabCardWrapper>
                    </MainCardWrapper>
                  </div>
                ))}
              </div>
            </ContentBody>
          </LeftSection>
          <RightSection data-component="RightSection" smActive={isActiveCard ? true : false}>
            <button className="sm-close-popup-btn" onClick={() => setIsActiveCard(false)}>
              <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
            </button>
            <SectionHeader data-component="SectionHeader">
              <ScenarioSectionWrapper data-component="ScenarioSectionWrapper">
                <ScenarioDropdown
                  options={scenarioList}
                  stepdata={stepdata}
                  setOptions={setScenarioList}
                  setStatus={props.setStatus}
                  callBackOnchange={(obj) => {
                    setIsMakeDefault(obj);
                  }}
                />
              </ScenarioSectionWrapper>
              {stepdata.isDefault == 0 ?
                <label className="default-scenario">
                  <input ref={makeDefaultRef} type="checkbox" onChange={() => setIsDefaultScenarioPopup(true)} />
                  <span>Make this your default scenario to be displayed on Home page</span>
                </label>
                :
                <p>This is your default scenario to be displayed on Home page</p>
              }
            </SectionHeader>

            <ContentBody data-component="ContentBody">
              <h3 className="blue-txt">IR4.0 Savings</h3>
              {/* Recoverable popup */}
              {isRecoverPop &&
                <RecoverablePopWrap data-component="RecoverablePopWrap">
                  <RecoverPopCard data-component="RecoverPopCard">
                    <InfoIcon data-component="InfoIcon">
                      <i dangerouslySetInnerHTML={{ __html: infoIcon }}></i>
                    </InfoIcon>
                    <p>Click on the {`>`} icon to get more information and calibrate the Recoverable for each Roadmap step.</p>
                    <RecoverCloseBtn data-component="RecoverCloseBtn" onClick={() => setIsRecoverPop(false)}>
                      <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                    </RecoverCloseBtn>
                  </RecoverPopCard>
                </RecoverablePopWrap>
              }
              {/* Right side cards */}
              {isActiveCard &&
                <ScndRgtTabWrapper data-component="ScndRgtTabWrapper">
                  <ScndTabButton data-component="ScndTabButton">
                    <TBRow data-component="TBRow">
                      <TBLeftCol data-component="TBLeftCol">
                        <button className="drag-btn blue">
                          <span>{isActiveCard.count}</span>
                        </button>
                        <p className='rmap-txt'>
                          {isActiveCard.name}
                        </p>
                      </TBLeftCol>
                      <TBRightCol data-component="TBRightCol">
                        <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                          <RightAlignTextWrap data-component="RightAlignTextWrap">
                            <HeadText data-component="HeadText">Recoverable: </HeadText>
                            <SubText data-component="SubText" id={`invstt-${isActiveCard.id}`} green>{isActiveCard.totalrecoverable}SR</SubText>
                          </RightAlignTextWrap>
                          <RightAlignTextWrap data-component="RightAlignTextWrap">
                            <HeadText data-component="HeadText">Investment: </HeadText>
                            <SubText data-component="SubText" orange>{isActiveCard.upfrontInvestment}SR</SubText>
                          </RightAlignTextWrap>
                        </RightAlignTextWrapper>
                        <BlueRecoverText data-component="BlueRecoverText">
                          <p className="loss_recov_det"><span id={`invsttp-${isActiveCard.id}`}>{isActiveCard?.totallossrecoverdp || 0}</span><span>%</span> of category <br />losses Recovered</p>
                        </BlueRecoverText>
                        <CloseTabBtn data-component="CloseTabBtn" onClick={() => setIsActiveCard(false)}>
                          <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </CloseTabBtn>
                      </TBRightCol>
                    </TBRow>
                  </ScndTabButton>
                  {rightLineLevel?.length > 0 &&
                    <LossLevelWrapper data-component="LossLevelWrapper">
                      <LossLevelWrap data-component="LossLevelWrap">
                        <h2 ClassName="level-title">Line Level</h2>
                      </LossLevelWrap>
                      <LossLevelList data-component="LossLevelList">
                        <select onChange={(e) => setRightSelectedLine(JSON.parse(e.target.value))}>
                          {Array.isArray(stepdata?.allLines) && stepdata?.allLines?.map((option, ind) =>
                            <option key={ind} value={JSON.stringify(option)} selected={rightSelectedLine?.id == option.id}>{option.line_name}</option>)
                          }
                        </select>
                      </LossLevelList>
                      {isActiveCard?.lossdataLineWise?.find(i => i.line_id == rightSelectedLine?.id)?.lossCategorytext?.filter(i=>i.subloss)?.map((li, li_index) => (
                        <div key={li_index}>
                          <LossLineList data-component="LossLineList">
                            <button
                              className='loss-btn'
                              onClick={() => setCloseBCPop({ ...li, popupType: 'line' })}
                            >
                              <span className='txt'>{li?.name}</span>
                              <i dangerouslySetInnerHTML={{ __html: angleIcon }}></i>
                            </button>
                          </LossLineList>
                        </div>

                      ))

                      }
                      {closeBCPop && closeBCPop?.popupType == 'line' ?
                        <BCPopupWrapper data-component="BCPopupWrapper">
                          <BCPopCard data-component="BCPopCard">
                            <BCPopHead data-component="BCPopHead">
                              <span className='line-name'>{rightSelectedLine?.line_name}</span>
                              <button className='close-pop' onClick={() => setCloseBCPop(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                              </button>
                            </BCPopHead>
                            <BCPopBody data-component="BCPopBody">
                              <table className='bc-table'>
                                <thead>
                                  <tr>
                                    <th scope="col" class="bc_first_col">{closeBCPop?.name}</th>
                                    <th scope="col" class="bc_second_col">Losses <br />Per Year</th>
                                    <th scope="col" class="bc_third_col">% of <br />Recoverable</th>
                                    <th scope="col" class="bc_fourth_col">Recoverable</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(closeBCPop?.subloss) && closeBCPop?.subloss?.map((li, li_index) => (
                                    <tr key={li_index}>
                                      <td>
                                        <span class="bc_first_col sm-col">{closeBCPop?.name}</span>
                                        <ul>
                                          <li>{li?.losssubCattext}</li>
                                        </ul>
                                      </td>
                                      <td>
                                        <span class="bc_second_col sm-col">Losses Per Year</span>
                                        <label className="value-red">
                                          <input type="text" defaultValue={li?.totalsublossrecoverable} className="roadmap-value-input" disabled />
                                          <span className='value-sign'>SR</span>
                                        </label>
                                      </td>
                                      <td>
                                        <span class="bc_third_col sm-col">% of Recoverable</span>
                                        <label className="value-blue">
                                          <input type="text" name={`line_${isActiveCard.id}_${closeBCPop.id}_${li_index}`} value={rightSectionLineInput[`line_${isActiveCard.id}_${closeBCPop.id}_${li_index}`] !== undefined ? rightSectionLineInput[`line_${isActiveCard.id}_${closeBCPop.id}_${li_index}`] : li?.lossrecoverdpercent} className="roadmap-value-input"
                                            onChange={(e) => setRightSectionLineInput(prv => {
                                              return {
                                                ...prv,
                                                [`line_${isActiveCard.id}_${closeBCPop.id}_${li_index}`]: e.target.value
                                              }
                                            })}
                                            onBlur={(e) => handleRightRecoverablePrecent(e, isActiveCard.id, rightSelectedLine.id, li?.subloss_category_id, li?.totalsublossrecoverable, `line_${isActiveCard.id}_${closeBCPop.id}_${li_index}`,`line_total_${isActiveCard.id}_${closeBCPop.id}_${li_index}`)} />
                                          <span className='value-sign'>%</span>
                                        </label>
                                      </td>
                                      <td>
                                        <span class="bc_fourth_col sm-col">Recoverable</span>
                                        <label className="value-green">
                                          <input type="text" 
                                          defaultValue={
                                            rightSectionLineInput[`line_total_${isActiveCard.id}_${closeBCPop.id}_${li_index}`]!==undefined? (rightSectionLineInput[`line_total_${isActiveCard.id}_${closeBCPop.id}_${li_index}`] || 0) : li?.totalsubcatrecoverable} 
                                          id={`line_${isActiveCard.id}_${closeBCPop.id}_${li_index}`} className="roadmap-value-input" disabled />
                                          <span className='value-sign'>SR</span>
                                        </label>
                                      </td>
                                    </tr>
                                  ))
                                  }
                                </tbody>
                              </table>
                            </BCPopBody>
                          </BCPopCard>
                        </BCPopupWrapper>
                        : ''}
                    </LossLevelWrapper>
                  }
                  
                  {rightFactoryLevel?.length > 0 &&
                    <LossLevelWrapper data-component="LossLevelWrapper">
                      <LossLevelWrap data-component="LossLevelWrap">
                        <h2 ClassName="level-title">Factory Level</h2>
                      </LossLevelWrap>
                      
                      {rightFactoryLevel?.map((li, li_index) => (
                        <>
                        <LossLineList data-component="LossLineList" key={li_index}>
                          <button className='loss-btn' onClick={() => setCloseBCPop({ ...li, popupType: 'factory' })}>
                            <span className='txt'>{li?.lossCategorytext}</span>
                            <i dangerouslySetInnerHTML={{ __html: angleIcon }}></i>
                          </button>
                        </LossLineList>
                        </>
                      ))

                      }
                      {closeBCPop && closeBCPop?.popupType == 'factory' ?
                        <BCPopupWrapper data-component="BCPopupWrapper">
                          <BCPopCard data-component="BCPopCard">
                            <BCPopHead data-component="BCPopHead">
                              <span className='line-name'></span>
                              <button className='close-pop' onClick={() => setCloseBCPop(false)}>
                                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                              </button>
                            </BCPopHead>
                            <BCPopBody data-component="BCPopBody">
                              <table className='bc-table'>
                                <thead>
                                  <tr>
                                    <th scope="col" class="bc_first_col">{closeBCPop?.lossCategorytext}</th>
                                    <th scope="col" class="bc_second_col">Losses <br />Per Year</th>
                                    <th scope="col" class="bc_third_col">% of <br />Recoverable</th>
                                    <th scope="col" class="bc_fourth_col">Recoverable</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(closeBCPop?.subloss) && closeBCPop?.subloss?.map((li, li_index) => (
                                    <tr key={li_index}>
                                      <td>
                                        <span class="bc_first_col sm-col">{closeBCPop?.lossCategorytext}</span>
                                        <ul>
                                          <li>{li?.subloss_category_text}</li>
                                        </ul>
                                      </td>
                                      <td>
                                        <span class="bc_second_col sm-col">Losses Per Year</span>
                                        <label className="value-red">
                                          <input type="text" defaultValue={li?.totalsublossrecoverable} className="roadmap-value-input" disabled />
                                          <span className='value-sign'>SR</span>
                                        </label>
                                      </td>
                                      <td>
                                        <span class="bc_third_col sm-col">% of Recoverable</span>
                                        <label className="value-blue">
                                          <input type="text"
                                            name={`factory_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`}
                                            value={rightSectionLineInput[`factory_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`] !== undefined ? rightSectionLineInput[`factory_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`] : li?.lossrecoverdp} className="roadmap-value-input"
                                            onChange={(e) => setRightSectionLineInput(prv => {
                                              return {
                                                ...prv,
                                                [`factory_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`]: e.target.value
                                              }
                                            })}
                                            onBlur={(e) => handleRightRecoverablePrecent(e, isActiveCard.id, 0, li?.subloss_category_id, li?.totalsublossrecoverable, `factory_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`,`factory_total_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`)} />
                                          <span className='value-sign'>%</span>
                                        </label>
                                      </td>
                                      <td>
                                        <span class="bc_fourth_col sm-col">Recoverable</span>
                                        <label className="value-green">
                                          <input
                                            type="text" 
                                            defaultValue={rightSectionLineInput[`factory_total_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`]?(rightSectionLineInput[`factory_total_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`] || 0) : li?.totalsubcatrecoverable}
                                            className="roadmap-value-input"
                                            id={`factory_${isActiveCard.id}_${li.subloss_category_id}_${li_index}`}
                                            onChange={()=>{}}
                                            disabled />
                                          <span className='value-sign'>SR</span>
                                        </label>
                                      </td>
                                    </tr>
                                  ))
                                  }
                                </tbody>
                              </table>
                            </BCPopBody>
                          </BCPopCard>
                        </BCPopupWrapper>
                        : ''}
                    </LossLevelWrapper>
                  }

                </ScndRgtTabWrapper>
              }
            </ContentBody>
            <ContentFooter data-component="ContentFooter">
              <Link to={`/roadmap-complete/step1/${stepdata.slug}/${stepdata.sid}`} className='prev-btn'>Previous</Link>
              <a className='proceed-btn' onClick={() => setOpenPopup(true)}>Proceed</a>
              {/* Proceed popup */}
              {openPopup ?
                <ProceedPopWrapper data-component="ProceedPopWrapper">
                  <ProceedPopCard data-component="ProceedPopCard">
                    <PopHead data-component="PopHead">
                      <span>Caution</span>
                      <button className="close-pop" onClick={() => setOpenPopup(false)}>
                        <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                      </button>
                    </PopHead>
                    <p className='pop-txt'>You have unsaved changes. Please save roadmap before proceeding.</p>
                    <PopFooter data-component="PopFooter">
                      <button className='save-btn solid' onClick={saveRoadmap}>Save & Proceed</button>
                      <a className='leave-btn transparent' onClick={() => setOpenPopup(false)}>Leave</a>
                    </PopFooter>
                  </ProceedPopCard>
                </ProceedPopWrapper> : ''}
            </ContentFooter>
          </RightSection>
        </SectionWrapper>
      </MainWrapper>
      {isDefaultScenarioPopup &&
        <DefaultScenarioPopupWrapper data-component="DefaultScenarioPopupWrapper">
          <DefaultScenarioPop data-component="DefaultScenarioPop">
            <h2>Would you like to make this your Default Scenario?</h2>
            <p>The default scenario’s progress and results will displayed on Homepage</p>
            <div class="button-section">
              <button class="cancel" onClick={() => { setIsDefaultScenarioPopup(false); makeDefaultRef.current.checked = false; }}>Cancel</button>
              <button class="submit yes" id="making-default-scenario" onClick={yesMakeDefaultFunction}>Yes, make this my Default Scenario </button>
            </div>
          </DefaultScenarioPop>
        </DefaultScenarioPopupWrapper>
      }
      {isLoader &&
        <LoaderPopupWrap data-component="LoaderPopupWrap">
          <LoaderPop data-component="LoaderPop">
            <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
            <h1 style={{ marginTop: '15px', textAlign: 'center', visibility: typeof isLoader == 'string' ? '' : 'hidden' }}>{isLoader}</h1>
          </LoaderPop>
        </LoaderPopupWrap>

      }
    </>
  );
};

// export default DragDropContainer;
