
/** imported components

  * serviceProvider for ===> working with api
  * AllquestionsView ===> View file for question component
  * question.scss ===> file for styling component
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import { AllquestionsView } from "./allquestions-view";
import "./question.scss";
import serviceProvider from '../../services/axios'
//Allquestions component start
export const AllquestionsController = (props) => {
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const [onTab, setOnTab] = useState(0);
	useEffect(() => {
		const search = async () => {
			const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const cid = getLastItem(window.location.pathname);
			// id=${cid}
			const apiUrl = `/assessment?param=answered&id=1&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true,{});
			setResults(data);
		};
		search();
	}, []);
	return <AllquestionsView sectionData={results} onTab={onTab} {...props} />;
};
