/** imported components

  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * RangeSlide ===> custom component of range slider (worked as percentage progress bar.)
 
**/

import React, { useState, useEffect } from "react";
import $ from "jquery";
import { RangeSlide } from "../range-slider/range-slider";
// RangeSlider Component start
export const RangeSlider = (props) => {
	const [allRangeValueArr, setAllRangeValueArr] = useState({});
	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}
	const quesDataItem = props.quesData[0];
	
	const getRangeValue = (e) => {
		const name = e.target.id;
		const val = e.target.value;
		setAllRangeValueArr({
			[name]: val,
		});
	};
	var val = 0;
	if (quesDataItem.savedans.length) {
		val = quesDataItem.savedans;
	}
	return (
		<>
			{/* Main single answer wrapper which is containing single progress answer format */}
			<div className="single-progess-ans-wrap">
				<div className="choose-one-card">
					<div className="card-left" style={{pointerEvents:quesDataItem.is_datasubmitted==1?'none':''}}>
						<RangeSlide id="singleRangeSlider" getValue={getRangeValue} value={val} showLabel={true} />
					</div>
				</div>
			</div>
		</>
	);
};
