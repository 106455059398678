/** imported components
 
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Link ===> Component from hook to use as anchor tag
  * .scss ===> styling css file for this page

**/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
//OperatorEffortView component start
export const OperatorEffortView = (props) => {
    //defining varaibles here
    const { value } = props;
    const [isShown, setIsShown] = useState(false);
    const [isAssignDdl, setIsAssignDdl] = useState(false);
    const [isSubmitPopup, setIsSubmitPopup] = useState(false);
    const [isRemovePopup, setIsRemovePopup] = useState(false);
    const [isRightPopup, setIsRightPopup] = useState(null);
    const [isAddCol, setIsAddCol] = useState(false);
    const [delItem, setDelItem] = useState(null);
    const [APDList, setAPDList] = useState([
        {
            name: `Confirming production orders and material consumption`,
            editable: false
        },
        {
            name: `Accessing work assistance instructions (process manuals, SOPs)`,
            editable: false
        },
        {
            name: `Performing product changeovers`,
            editable: false
        },
        {
            name: `Reviewing production information to ensure quality compliant product release`,
            editable: false
        },
        {
            name: `Detecting defects (i.e. Quality Inspections) and entering the data to systems`,
            editable: false
        },
        {
            name: `Feeding materials to production lines`,
            editable: false
        },
        {
            name: `Cleaning, Inspecting, Lubricating assets`,
            editable: false
        },
        {
            name: `My new subcategory added`,
            editable: false
        },

    ]);
    const [productionLine, setProductionLine] = useState(
        [
            'Long production line name',
            'Production Line 2',
            'Production Line 3',
            'Production Line 4',
            'Production Line 5',
            'Production Line 6',
        ]
    )
    //function to handle right section
    const HandleRightSec = (val) => {
        if (isRightPopup === val) {
            setIsRightPopup(null);
        } else {
            setIsRightPopup(val);
        }
    }
    //function for handle delete button
    const HandleDelBtnEvent = (e, item) => {
        e.preventDefault();
        e.stopPropagation() ;
        setDelItem(item);
        setIsRemovePopup(true);
    }
    // Fixed
    useEffect(() => {

        $('.la-ques-bottom-wrapper').scroll(function (event) {
            var scroll = $('.la-ques-bottom-wrapper').scrollTop();
            if (scroll > 0) {
                $('.fixed-row-column').addClass('fixed-top');
            }
            else {
                $('.fixed-row-column').removeClass('fixed-top');
            }
        });


        document.addEventListener('click', (e) => {
            if (!e.target.closest(".la-assign-cir-wrapper")) {
                setIsAssignDdl(false);
            }
        })


    }, [])
    //function to add list
    const AddLsit = (e) => {
        if (e.keyCode === 13) {
            let name = e.target.value;
            let tmpObj = {
                name,
                editable: true
            }
            let tmpArr = [...APDList];
            tmpArr.push(tmpObj);
            setAPDList(tmpArr);
            e.target.value = '';
        }
    }
    //function to remove list
    const RemoveList = () => {
        let index = delItem.index;
        let tmpArr = APDList.filter((item, i) => i !== index);
        console.log(tmpArr);
        setAPDList(tmpArr);
    }
    return (
        <>
             {/* Main wrapper which is divided into three sections
                la-ques-top
                la-ques-bottom-wrapper
                la-ques-button-group
            */}
            <section className="la-ques-wrapper operator">
                {/* this section has row */}
                <div className="la-ques-top">
                     {/* Top section has three parts
                        la-ques-lft-col
                        la-ques-mid-col
                        la-ques-right-col
                    */}
                    <div className="la-ques-row">
                         {/* This section contains
                            back button
                            page title
                            category title
                        */}
                        <div className="la-ques-lft-col">
                            <h1 className="h1-btn">
                                <Link to="/" className="back-link">
                                    <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d)">
                                            <rect x={2} y={2} width={24} height={24} rx={2} fill="white" />
                                        </g>
                                        <path d="M19 7.4303L17.4833 6L9 14L17.4833 22L19 20.5697L12.0334 14L19 7.4303Z" fill="#7D868C" />
                                        <defs>
                                            <filter id="filter0_d" x={0} y={0} width={28} height={28} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset />
                                                <feGaussianBlur stdDeviation={1} />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                            </filter>
                                        </defs>
                                    </svg>
                                </Link>
                                <span>
                                    Operator Losses
                                </span>
                            </h1>
                            <h4 className="h4-dull">
                                Operator Effort Losses
                            </h4>
                            <Progressbar value={10} />
                        </div>
                        {/* end */}
                        {/* This section contains assigned user list*/}
                        <div className="la-ques-mid-col">
                            <div className="la-assign-cir-wrapper">
                                <div className="la-assign-cir-group">
                                    <div className="la-assign-cir-wrapper">
                                        <button className="la-assign" onClick={() => setIsAssignDdl(!isAssignDdl)}>
                                            <div className="img-div">
                                                <img src={require('../../assets/img/operator-effort-loss/assign-icon.svg').default} alt="assign" />
                                            </div>
                                            <span>
                                                Assign
                                            </span>
                                            <div className="la-profile-cir-ul">
                                                <div className="la-profile-cir cir-a">
                                                    <span>Ab</span>
                                                    {/* <img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                </div>
                                                <div className="la-profile-cir cir-c">
                                                    <span>ca</span>
                                                </div>
                                                <div className="la-profile-cir cir-d">
                                                    <span>da</span>
                                                </div>
                                            </div>
                                        </button>
                                        {/* ddl */}
                                        <div className={`assigned-user-popup ${isAssignDdl && 'active'}`}>
                                            <span className="tooltip"></span>
                                            <div className="assignd-user-popup-wrap">
                                                <div className="pop-wrap">
                                                    <div className="img-box">
                                                        <div className="image-div">
                                                            <span className="us-name">AC</span>
                                                        </div>
                                                    </div>
                                                    <div className="user-name">
                                                        <span className="name">Vera Kennedy</span>
                                                        <span className="designation">Plant Engineer</span>
                                                    </div>
                                                </div>
                                                <div className="pop-wrap">
                                                    <div className="img-box">
                                                        <div className="image-div">
                                                            <span className="us-name">AB</span>
                                                        </div>
                                                    </div>
                                                    <div className="user-name">
                                                        <span className="name">Vera Kennedy</span>
                                                        <span className="designation">Plant Engineer</span>
                                                    </div>
                                                </div>
                                                <div className="pop-wrap edit">
                                                    <button className="edit-team">
                                                        <img
                                                            src={
                                                                require("../../assets/img/operator-effort-loss/assign-userl.svg")
                                                                    .default
                                                            }
                                                            alt="Assign User" className="assign-user"
                                                        />
                                                        <span className="name">Edit Team</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="la-upload">
                                            <div className="img-div">
                                                <img src={require('../../assets/img/operator-effort-loss/upload-icon.svg').default} alt="upload" />
                                            </div>
                                            <span>
                                                Upload Excel file
                                            </span>
                                        </button>
                                    </div>
                                    <div>
                                        <button className="la-download">
                                            <div className="img-div">
                                                <img src={require('../../assets/img/operator-effort-loss/download-icon.svg').default} alt="download" />
                                            </div>
                                            <span>
                                                Download to Excel
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end */}
                        {/* this section has two elements
                            popup
                            unlock button
                        */}
                        <div className="la-ques-rgt-col">
                            <div className="image-wrapper" onClick={() => setIsShown(!isShown)}>
                                <img src={require('../../assets/img/operator-effort-loss/bulb.svg').default} alt="Bulb" className="notification-img" />
                            </div>
                            {isShown && (
                                <div className="la-card-wrapper">
                                    <div className="image-wrap">
                                        <img src={require('../../assets/img/operator-effort-loss/bulb.svg').default} alt="Bulb" />
                                    </div>
                                    <div className="content-box">
                                        <p className="content">Please provide the breakdown of the key activities taking place by the direct labor (line operators/technicians) on the production lines including any 3rd party contractors.</p>
                                        <button to="/" className="close-btn"><img src={require('../../assets/img/operator-effort-loss/close.svg').default} alt="close" onClick={() => setIsShown(false)} /></button>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* end */}
                    </div>
                    {/* la-ques-row */}
                </div>
                {/* top section end */}
                <div className="la-ques-bottom-wrapper">
                    {/* this section has row */}
                    <div className="la-ques-bottom">
                        {/* Middle Wrapper divided into two parts 
                             la-bottom-left-col
                             la-bottom-right-col
                        */}
                        <div className="la-ques-bottom-row">
                             {/* This section has been divided into two horizontal parts 
                                fixed-row-column
                                la-common-left-col
                            */}
                            <div className="la-bottom-left-col">
                                <div className="fixed-row-column left">
                                    <div className="operator-text-wrap">
                                        <p className="text">Total FTEs working on Maintenance:<span className="bold-text">25</span><span class="normal-text">FTE</span></p>
                                    </div>
                                    <div className="side-ques-wrap bold-blue">
                                        <span className="ques-label">What % of your Operators/ Technicians time is spent on:</span>
                                    </div>
                                </div>
                                {APDList.map((items, i) => {
                                    return (<div className="la-common-left-col fix">
                                        <button title={items.name} className={`side-subcategory ${isRightPopup === (i + 1) && 'active'}`} onClick={() => HandleRightSec(i + 1)}>
                                            <div className="image-box">
                                                <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                </svg>
                                            </div>
                                            <div className="add-subcat-wrap">
                                                <div className={`input-wrap ${!items.editable && 'w-100'}`}><p className="text">{items.name}</p></div>
                                                {items.editable && <button className="del-btn"
                                                    onClick={(e) => {

                                                        HandleDelBtnEvent(e, {
                                                            name: items.name,
                                                            index: i
                                                        });
                                                    }}>
                                                    <img src={require('../../assets/img/operator-effort-loss/delete-icon.svg').default} alt="Delete" />
                                                </button>}
                                            </div>

                                        </button>
                                    </div>)
                                }
                                )}

                                {isAddCol && <div className="la-common-left-col">
                                    <div className="side-subcategory">
                                        <div className="image-box">
                                            <img src={require('../../assets/img/operator-effort-loss/notification-icon.svg').default} alt="notification" />
                                        </div>
                                        <div className="add-subcat-wrap">
                                            <div className="input-wrap">
                                                <input placeholder="Other - please specify" name="sub-cat" onKeyDown={(e) => AddLsit(e)} className="input" /></div>
                                            <button className="del-btn" onClick={() => setIsAddCol(false)}>
                                                <img src={require('../../assets/img/operator-effort-loss/delete-icon.svg').default} alt="Delete" />
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            {/* This section has been divided into two horizontal parts 
                                fixed-row-column
                                la-ques-table-col
                            */}
                            <div className="la-bottom-right-col">
                                <div className="la-ques-table-wrapper">
                                    <div className="fixed-row-column rgt">
                                        <div className="la-ques-table-row first">
                                            {productionLine.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className="table-heading">
                                                            <h3>{item}</h3>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="la-ques-table-row second">
                                            {productionLine.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className="hour-week-wrap wrong-input-val">
                                                            <input className="input selected-input blue" type="text" placeholder="Hrs/Week" />
                                                            <span class="floating-label">Hrs/Week</span>
                                                            <div className="error-mssg">Invalid Data</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="la-ques-table-row third">
                                            {productionLine.map((item, i) => {
                                                return (
                                                    <div className="la-ques-table-col" key={i}>
                                                        <div className="svg-wrap">
                                                            <div className="svg-wrapper">
                                                                <SvgCir value={0} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    </div>
                                    {APDList.map((items, i1) => <div className={`la-ques-table-row fix tr-row${i1}`} key={i1}>
                                        {productionLine.map((item, i2) => {
                                            return (
                                                <div className="la-ques-table-col" key={i2}>
                                                    <div className="la-ques-input-wrap">
                                                        <input type="text" className="la-ques-input" placeholder="%" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* this section has submit button */}
                <div className="la-ques-button-group">
                    <div className="la-common-left-col">
                        <div className="button-wrap">
                            <button className="btn-link" onClick={() => setIsAddCol(true)}>+ Add new sub category</button>
                        </div>
                        <div className="button-wrap">
                            <button className="btn-link submit disabled active" onClick={() => { setIsSubmitPopup(true) }}>Submit</button>
                        </div>
                    </div>
                </div>
                {/* submit popup starts here */}
                <div className={`submit-pop-wrap ${isSubmitPopup && 'active'}`}>
                    <div className="popup-wrapper submit-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn" onClick={() => { setIsSubmitPopup(false) }}>
                                <img src={require("../../assets/img/operator-effort-loss/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/operator-effort-loss/unsaved.svg").default} alt="Unsaved" className="unsave-img" />
                            </div>
                            <div className="content-box">
                                <p className="text">By submitting the data you will no longer have the opportunity for further editing. Please make sure that the data provided are in their most updated form. </p>
                                <p className="text">Are you sure you want to proceed?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn cancel-button primary" onClick={() => { setIsSubmitPopup(false) }}>Cancel</button>
                                <button type="submit" className="btn submit-btn secondary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Delete popup starts here */}
                <div className={`delete-pop-wrap ${isRemovePopup && 'active'}`}>
                    <div className="popup-wrapper del-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn" onClick={() => { setIsRemovePopup(false) }}>
                                <img src={require("../../assets/img/operator-effort-loss/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/operator-effort-loss/pop-del-icon.svg").default} alt="Delete" className="del-img" />
                            </div>
                            <div className="content-box">
                                <p className="subcat-title text">{delItem?.name}</p>
                                <p className="text">Are you sure you want to remove this subcategory?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn keep-button primary" onClick={() => { setIsRemovePopup(false) }}>No, keep it</button>
                                <button type="submit" className="btn remove-btn secondary" onClick={() => { RemoveList(); setIsRemovePopup(false); }}>Yes, remove</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Right Sidebar Popup starts here */}
                <div className={`right-sidebar ${isRightPopup && 'active'}`}>
                    <div className="pop-heading-wrap">
                        <div className="chat-btn">
                            <img src={require("../../assets/img/operator-effort-loss/info-icon.svg").default} alt="Info" className="info-img" />
                        </div>
                        <button className="close-btn" onClick={() => HandleRightSec(null)}>
                            <img src={require("../../assets/img/operator-effort-loss/close-pop-gray.svg").default} alt="Close" className="close-img" />
                        </button>
                    </div>
                    <div className="popup">
                        <div className="content-box">
                            <h3 className="popup-head">Planned maintenance activities</h3>
                            <p className="text">The % of time a production process or production line is not available for production due to scheduled maintenance activities.</p>
                        </div>
                        <div className="comment-box">
                            <h3 className="cmnt-head">Leave a Comment</h3>
                            <textarea className="text-box">In case needed, please provide additional information about your data input (assumptions, clarifications)</textarea>
                        </div>
                        <div className="button-wrapper">
                            <button type="submit" className="btn submit-btn secondary">Submit</button>
                        </div>
                    </div>
                </div>
            </section>
            {/* la-ques-wrapper end */}
        </>
    )
}
//progressbar component
const Progressbar = ({ value }) => {
    return (
        <div className="la-progress-box">
            <p>Progress:</p>
            <div className="bar-wrapper">
                <span>{`${value}%`}</span>
                <div className="bar blue" style={{ width: `${value}%` }}></div>
            </div>
        </div>
    )
}
//svg circle component
const SvgCir = (props) => {
    const { value } = props;
    return (
        <>
            <svg viewBox="0 0 36 36">
                <text
                    className="percent__text"
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontSize="8"
                    fontWeight="normal"
                >

                    <tspan fontSize="10">
                        {value} %
                    </tspan>
                </text>
                <path
                    className="circle-bg"
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    stroke="#e6e6e6"
                    strokeWidth="4"
                    fill="none"
                ></path>
                <path
                    className="circle"
                    strokeDasharray={`${value}, 100`}
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    stroke={
                        value < 100 && value > 0
                            ? "#FF6666"
                            : value === 100
                                ? "#4CAF50"
                                : "transparent"
                    }
                    strokeWidth="4"
                    fill="none"
                ></path>
            </svg>

        </>
    )
}