import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { isEmptyObject } from "jquery";
import { array } from "prop-types";
export const MaturityOverView = (props) => {

    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    const [demoUserData, setDemoUserData] = useState([])
    const [isAssign, setIsAssign] = useState(true);
    const [overViewDdl, setOverViewDdl] = useState(null);
    const [isFilterClick, setIsFilterClick] = useState(false);
    const [filterTableUsers, setFilterTableUsers] = useState([]);
    const [userFilterData, setUserFilterData] = useState([]);
    const [analysisFilterData, setAnalysisFilterData] = useState([]);
    const [productFilterData, setProductFilterData] = useState([]);
    const [selectfilter, setSelectFilter] = useState({
        productFilterValue: '',
        analysisFilterValue: '',
        userFilterValue: ''
    });

    // console.log(demoUserData);


    useEffect(() => {
        loadUser();
    }, []);
    const loadUser = async () => {
        const result = await serviceProvider.get(`/plants/UserList?user_id=${usid}`,true,{});
        const data = result.data
      //  console.log(data[0].lossuserprogress);
        const datap = data[0].progress;
        const data1 = data[0].uerstatus;
        const lpog = data[0]?.lossuserprogress;
        console.log(lpog)
        if(data1!="" && data1!=undefined){
            const options = data1.map((d, index)  => ({
          "username": d.name+" "+d.last_name,
          "cirName": 'jd',
          "id":d.id,
          "img": 'demo-user.svg',
          "analysis": d.section_name,
          "product": d.invited_for == "0" ? 'Maturity Assessment' : 'Loss Analysis',
          "progress": datap[index]!=undefined ? datap[index] : 0,
          "lossuserprog":lpog?lpog:0,
          "sectionusertype":d.invited_for,

        }))
        // console.log(options)
       
      
       // var array = d.section_name.split(',');
       // console.log("split-",options)
      
        setDemoUserData(options);
        setFilterTableUsers(options);
        setUserFilterData(options);
        setAnalysisFilterData(options);
        setProductFilterData(options);
    }
    };    
    
    //console.log(demoUserDataa);

    // const demoUserData = [
    //     {
    //         username: 'John Doe',
    //         cirName: 'jd',
    //         img: 'demo-user.svg',
    //         analysis: 'Planned Downtime',
    //         product: 'Loss Analysis',
    //         progress: 30,
    //     },
    //     {
    //         username: 'Jghn Doe',
    //         cirName: 'jd',
    //         img: 'demo-user.svg',
    //         analysis: 'Planned Downtime',
    //         product: 'Loss Analysis',
    //         progress: 30,
    //     },
    //     {
    //         username: 'Viktoria Doe',
    //         cirName: 'vd',
    //         analysis: 'Company-wide capabilities',
    //         product: 'Maturity Assessment',
    //         progress: 30,
    //     },
    //     {
    //         username: 'Sam Perry',
    //         cirName: 'sp',
    //         img: 'demo-user.svg',
    //         analysis: 'Organization of the future',
    //         product: 'Maturity Assessment',
    //         progress: 30,
    //     },
    //     {
    //         username: 'Sam Perry',
    //         cirName: 'sp',
    //         img: 'demo-user.svg',
    //         analysis: 'Speed Losses',
    //         product: 'Loss Analysis',
    //         progress: 30,
    //     }

    // ]

//    console.log(userFilterData);

   
    const HandleDdl = (val) => {
        if (val) {
            if (overViewDdl === val) {
                setOverViewDdl(null);
            } else {
                setOverViewDdl(val);
            }
        } else {
            setOverViewDdl(null);
        }
    }
    const HandleProductFilter = (name, val) => {
        HandleFilter(name, val);
        if (val) {
            let productObj = demoUserData.filter((item, index) => {
                return item.product.toLocaleLowerCase().startsWith(val.toLocaleLowerCase());
            });
            setProductFilterData(productObj);
        } else {
            setProductFilterData(demoUserData);
            setIsFilterClick(!isFilterClick);
        }
    }
    const HandleAnalysisFilter = (name, val) => {
        HandleFilter(name, val);
        if (val) {
            let analysisObj = demoUserData.filter((item, index) => {
                return item.analysis.toLocaleLowerCase().startsWith(val.toLocaleLowerCase());
            });
            setAnalysisFilterData(analysisObj);
        } else {
            setAnalysisFilterData(demoUserData);
            setIsFilterClick(!isFilterClick);
        }
    }
    const HandleUserFilter = (name, val) => {
        HandleFilter(name, val);
        if (val) {
            let userObj = demoUserData.filter((item, index) => {
                return item.username.toLocaleLowerCase().startsWith(val.toLocaleLowerCase());
            });
            setUserFilterData(userObj);
        } else {
            setUserFilterData(demoUserData);
            setIsFilterClick(!isFilterClick);
        }
    }
    const HandleFilter = (name, val) => {
        let tmpObj = {
            ...selectfilter,
            [name]: val
        };
        setSelectFilter(tmpObj);
    }

    const HandleTableFilterData = () => {
        const { analysisFilterValue, userFilterValue, productFilterValue } = selectfilter;
        if (analysisFilterValue && userFilterValue && productFilterValue) {
            let tmpObj = demoUserData.filter((item, index) => {
                return item.analysis === analysisFilterValue && item.username === userFilterValue && item.product === productFilterValue
            })
            setFilterTableUsers((prev) => {
                return tmpObj
            });
        }
        else if (analysisFilterValue) {
            let tmpObj = demoUserData.filter((item, index) => {
                return item.analysis === analysisFilterValue
            })
            setFilterTableUsers((prev) => {
                return tmpObj
            });
        } else if (userFilterValue) {
            let tmpObj = demoUserData.filter((item, index) => {
                return item.username === userFilterValue
            })
            setFilterTableUsers((prev) => {
                return tmpObj
            });
        } else if (productFilterValue) {
            let tmpObj = demoUserData.filter((item, index) => {
                return item.product === productFilterValue
            })
            setFilterTableUsers((prev) => {
                return tmpObj
            });
        }else {
            setFilterTableUsers(demoUserData);
        }
    }

    useEffect(() => {
        HandleTableFilterData();
        document.onclick = (e) => {
            if (!e.target.closest(".overview-ddl") && !e.target.closest(".overview-td-ddl")) {
                HandleDdl();
            }
        };
    }, [isFilterClick])
    return (
        <>
            <div className="maturity-overview-wrapper">
                <div className="overview-card">
                    <div className="overview-header">
                        <svg className="head-icon" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5 35.5C27.165 35.5 35 27.665 35 18C35 8.33502 27.165 0.5 17.5 0.5C7.83502 0.5 0 8.33502 0 18C0 27.665 7.83502 35.5 17.5 35.5Z" fill="#399ED4" />
                            <path d="M18.8495 26.1463H11C9.89543 26.1463 9 25.2509 9 24.1463V11.5C9 10.3954 9.89543 9.5 11 9.5H23.4742C24.5788 9.5 25.4742 10.3954 25.4742 11.5V18.7244" stroke="white" />
                            <path d="M11.9795 13.4937H13.8372" stroke="white" strokeLinecap="round" />
                            <path d="M11.9795 17.6289H13.8372" stroke="white" strokeLinecap="round" />
                            <path d="M11.9795 22.1523H13.8372" stroke="white" strokeLinecap="round" />
                            <path d="M15.5 22.1523H17.5875" stroke="white" strokeLinecap="round" />
                            <path d="M15.5 13.5293H22.7051" stroke="white" strokeLinecap="round" />
                            <path d="M15.5 17.6646H22.7051" stroke="white" strokeLinecap="round" />
                            <path d="M24.7285 22.647C24.7285 24.0144 23.6302 25.1158 22.2842 25.1158C20.9381 25.1158 19.8398 24.0144 19.8398 22.647C19.8398 21.2796 20.9381 20.1782 22.2842 20.1782C23.6302 20.1782 24.7285 21.2796 24.7285 22.647Z" stroke="white" />
                            <path d="M24.2832 24.8035L26.0007 26.4999" stroke="white" />
                        </svg>
                        <span>
                            Overview
                        </span>
                    </div>
                    {/* overview-header end */}
                    <div className="overview-content">
                        <div className="overview-table">
                            <div className="overview-thead">
                                <div className="overview-tr">
                                    <div className="overview-td overview-user-td">
                                        <button className="overview-ddl" onClick={() => { HandleDdl(1) }} disabled={!isAssign}>
                                            <span>User name</span>
                                            <span className="btn-ddl">
                                                <img src={require('../../assets/img/maturity-dashboard-adm/ddl-trangle.svg').default} alt="v" />
                                            </span>
                                        </button>
                                        <div className={`overview-td-ddl ${overViewDdl === 1 && 'active'}`}>
                                            <div className="overview-td-field">
                                                <div className="overview-td-field-wrapper">
                                                    <label>
                                                        <img src={require('../../assets/img/maturity-dashboard-adm/td-search-icon.svg').default} alt="o-" />
                                                        <input placeholder="Search"
                                                            value={selectfilter.userFilterValue} onChange={(e) => { HandleUserFilter('userFilterValue', e.target.value) }} autoComplete="off"
                                                        />
                                                    </label>
                                                    <button onClick={(e) => { HandleUserFilter('userFilterValue', ''); }}>
                                                        <img src={require('../../assets/img/maturity-dashboard-adm/clear-overview-search.svg').default} alt="x" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="overview-td-ddl-ul">
                                                {userFilterData.map((items, index) => {
                                                    return (
                                                        <button className="overview-td-ddl-li" key={`user-${index}`}
                                                            onClick={() => {
                                                                HandleFilter('userFilterValue', items.username);
                                                                HandleDdl(null);
                                                                setIsFilterClick(!isFilterClick);
                                                                // HandleTableFilterData()
                                                            }}>
                                                            <div className={`user-cir cir-${items.username[0].toLocaleLowerCase()}`}>
                                                                {items.img &&
                                                                    <img src={require(`../../assets/img/maturity-dashboard-adm/${items.img}`).default} alt="user" />
                                                                }
                                                                <span>
                                                                    {items.cirName}
                                                                </span>
                                                            </div>
                                                            <div className="user-name">
                                                                {items.username}
                                                            </div>
                                                        </button>
                                                    )

                                                })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="overview-td overview-analysis-td">
                                        <button className="overview-ddl" onClick={() => { HandleDdl(2) }} disabled={!isAssign}>
                                            <span>Analysis/Assessment Name</span>
                                            <span className="btn-ddl">
                                                <img src={require('../../assets/img/maturity-dashboard-adm/ddl-trangle.svg').default} alt="v" />
                                            </span>
                                        </button>
                                        <div className={`overview-td-ddl ${overViewDdl === 2 && 'active'}`}>
                                            <div className="overview-td-field">
                                                <div className="overview-td-field-wrapper">
                                                    <label>
                                                        <img src={require('../../assets/img/maturity-dashboard-adm/td-search-icon.svg').default} alt="o-" />
                                                        <input placeholder="Search" autoComplete="off" value={selectfilter.analysisFilterValue} onChange={(e) => { HandleAnalysisFilter('analysisFilterValue', e.target.value) }} />
                                                    </label>
                                                    <button onClick={(e) => { HandleAnalysisFilter('analysisFilterValue', '') }} >
                                                        <img src={require('../../assets/img/maturity-dashboard-adm/clear-overview-search.svg').default} alt="x" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="overview-td-ddl-ul">
                                                {analysisFilterData.map((items, index) => {
                                                    //  {items.analysis.split(', ').map((step, i) => (
                                                       
                                                    //         <Text>
                                                    //           {i > 0 && ", "}
                                                      
                                                    //            {step}
                                                    //         </Text>
                                                     
                                                    //    ))}
                                                    return (
                                                     
                                                        <button className="overview-td-ddl-li font-15" key={`analysis-${index}`}
                                                            onClick={() => {
                                                                HandleFilter('analysisFilterValue', items.analysis); HandleDdl(null);
                                                                setIsFilterClick(!isFilterClick)
                                                                // HandleTableFilterData()
                                                            }}>
                                                            <div className="user-name">
                                                                {items.analysis}
                                                            </div>
                                                        </button>)
                                                })}
                                            </div>
                                        </div>

                                    </div>



                                    <div className="overview-td overview-product-td">
                                        <button className="overview-ddl" onClick={() => { HandleDdl(3) }} disabled={!isAssign}>
                                            <span>Product</span>
                                            <span className="btn-ddl">
                                                <img src={require('../../assets/img/maturity-dashboard-adm/ddl-trangle.svg').default} alt="v" />
                                            </span>
                                        </button>
                                        <div className={`overview-td-ddl ${overViewDdl === 3 && 'active'}`}>
                                            <div className="overview-td-field">
                                                <div className="overview-td-field-wrapper">
                                                    <label>
                                                        <img src={require('../../assets/img/maturity-dashboard-adm/td-search-icon.svg').default} alt="o-" />
                                                        <input placeholder="Search" autoComplete="off" value={selectfilter.productFilterValue} onChange={(e) => { HandleProductFilter('productFilterValue', e.target.value) }} />
                                                    </label>
                                                    <button onClick={(e) => { HandleProductFilter('productFilterValue', '') }} >
                                                        <img src={require('../../assets/img/maturity-dashboard-adm/clear-overview-search.svg').default} alt="x" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="overview-td-ddl-ul">
                                                {productFilterData.map((items, index) => {
                                                    return (
                                                        <button className="overview-td-ddl-li font-15" key={`product-${index}`}
                                                            onClick={() => {
                                                                HandleFilter('productFilterValue', items.product); HandleDdl(null);
                                                                setIsFilterClick(!isFilterClick)
                                                                // HandleTableFilterData()
                                                            }}>
                                                            <div className="user-name">
                                                                {items.product}
                                                            </div>
                                                        </button>)
                                                })}
                                            </div>
                                        </div>

                                    </div>







                                    {/* <div className="overview-td overview-product-td">
                                        <button className="overview-ddl" disabled={!isAssign}>
                                            <span>Product</span>
                                            <span className="btn-ddl">
                                                <img src={require('../../assets/img/maturity-dashboard-adm/ddl-trangle.svg').default} alt="v" />
                                            </span>
                                        </button>

                                    </div> */}
                                    <div className="overview-td overview-progress-td"></div>
                                    <div className="overview-td overview-assign-td" >
                                        {/* <button className="btn-assign" onClick={() => setIsAssign(true)}>Assign</button> */}

                                        {/* <button className="btn-assign" onClick={() => props.openAssignPopup(true)}>
                                                Assign Users
                                        </button> */}

                                    </div>
                                    



                                </div>
                            </div>
                            {/* overview-thead end */}
                            <div className="overview-tbody">
                                {isAssign ?
                                    <>
                                        {filterTableUsers.length > 0 ?
                                            <>
                                           
                                                {filterTableUsers.map((items, index) => {
                                                    
                                                    return (
                                                        <div className="overview-tr" key={`tableRow${index}`}>
                                                            <div className="overview-td overview-user-td">
                                                                <div className={`user-cir cir-${items.username[0].toLocaleLowerCase()}`}>
                                                                    {items.img &&
                                                                        <img src={require(`../../assets/img/maturity-dashboard-adm/${items.img}`).default} alt="user" />
                                                                    }
                                                                    <span>
                                                                        {items.cirName}
                                                                    </span>
                                                                </div>
                                                                <div className="user-name">
                                                                    {items.username}
                                                                </div>
                                                            </div>
                                                            <div className="overview-td overview-analysis-td">
                                                                <span className="turncate-text">{items.analysis}</span>
                                                            </div>
                                                            <div className="overview-td overview-product-td">
                                                                <span className="turncate-text">{items.product}</span>
                                                            </div>
                                                           { (items.sectionusertype=='0')?
                                                            <div className="overview-td overview-progress-td">
                                                                <div className="overview-td-progress">
                                                                    <div className="overview-progress">
                                                                        <div className="bar" style={{ width:   `${items.progress>100? 100:  items.progress}%` }}></div>
                                                                    </div>
                                                                    <div className="overview-progress-percent">{`${items.progress>100? 100:  items.progress}%`}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                           <div className="overview-td overview-progress-td">
                                                            <div className="overview-td-progress">
                                                                <div className="overview-progress">
                                                                    <div className="bar" style={{ width: `${items.lossuserprog}%` }}></div>
                                                                </div>
                                                                <div className="overview-progress-percent">{`${items.lossuserprog}%`}</div>
                                                            </div>
                                                        </div>
                                                }
                                                            <div className="overview-td overview-assign-td">
                                                                {/* <button className="eye-btn">
                                                                    <img src={require('../../assets/img/maturity-dashboard-adm/eye.svg').default} alt="eye" />
                                                                </button> */}
                                                                <button className="minus-btn">
                                                                    <img src={require('../../assets/img/maturity-dashboard-adm/minus.svg').default} alt="-" />
                                                                </button>
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                <div className="overview-no-result-tr">
                                                    No result found.
                                                </div>
                                            </>
                                        }
                                    </>
                                    :
                                    <div className="overview-tr">
                                        <div className="assign-btn-wrapper">
                                            <div>
                                                <img src={require('../../assets/img/maturity-dashboard-adm/overview-assign-img.svg').default} alt="true" />
                                            </div>
                                            <button className="btn-assign" onClick={() => props.openAssignPopup(true)}>
                                                Assign Users
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* overview-body end */}
                        </div>
                        {/* overview-table end */}
                    </div>
                    {/* overview-content end */}
                </div>
                {/* overview-card end */}
            </div>
            {/* maturity-overview-wrapper end*/}
        </>
    )
}