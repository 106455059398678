/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * Collapse ===> collapse from matrial ui component

**/
import React, { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import Moment from "react-moment";

function Row({ data }) {
	const [open, setOpen] = React.useState(false);
	const HandleCollapseState = () => {
		setOpen(!open);
	};
	return (
		<React.Fragment>
			<div className="table__card">
				{/* table-row has been divided into columns */}
				<div className="table__row">
					<div className="table__col__1">
						<div className="text__col_1">
						<p className="b pay_success_b_text">Industry 4.0 Dimensions Training</p>
						<p className="pay_sub_text">1 year access</p>
						{/*
							<ul className="col_big_ul">
									<li><p className="normal__txt">17+ hours of content</p></li>
									<li><p className="normal__txt">Members only Full Access for 1 year</p></li>
									<li><p className="normal__txt">Only Until 31st October 2023</p></li>
							</ul> 
						*/}
							{/* <p>{data.item}</p>
							{data.subTableData && (
								<button className={open ? "ddl__btn active" : "ddl__btn"} onClick={HandleCollapseState}>
									<img src={require("../../assets/img/profile/purchase-ddl-arrow.svg").default} />
								</button>
							)} */}
							<p>

							</p>
						</div>
					</div>
					<div className="table__col">
						<Moment format="DD/MM/YYYY">{data.datePlaced}</Moment>
					</div>
					<div className="table__col">
						{data.totalCost}
					</div>
					<div className="table__col">{data.orderNumber}</div>
					<div className="table__col">
						<a href={data.downloadInvoiceUrl} target="_blank" className="invoice__link">
							Download Invoice
						</a>
					</div>
				</div>
				{data.subTableData && (
					<Collapse in={open} className="inner__table">
						{data.subTableData.map((item, index) => (
							<div className="table__row" key={index}>
								<div className="table__col__1">
									<div className="text__col_1">
										<p>{item.item}</p>
									</div>
								</div>
								<div className="table__col">
									<Moment format="DD/MM/YYYY">{item.datePlaced}</Moment>
								</div>
								<div className="table__col">{item.totalCost}</div>
								<div className="table__col">{item.orderNumber}</div>
								<div className="table__col"></div>
							</div>
						))}
					</Collapse>
				)}
			</div>
			{/* card end */}
		</React.Fragment>
	);
}
//PurchaseTabPanel component
export const PurchaseTabPanel = (props) => {
	//defining variables here
	const profileDataItems = props.profileData;
	if (!profileDataItems.length) {
		return null;
	}
	const profileDataItem = props.profileData[0];
	const purchaseItem = profileDataItem.purchases;
	const uid = profileDataItem.uid;
	const rowsData = [];
	if (purchaseItem.length) {
		purchaseItem.map((data) => {
			if (data.count > 1) {
				let subrowData = [];
				let totalCost = 0;
				let price = 0;
				data.course.map((subdata) => {
					price = subdata.price - ((subdata.price*subdata.discount)/100);
					totalCost = totalCost + parseFloat(price.toFixed(2));
					
					subrowData.push({
						item: subdata.course_name,
						datePlaced: subdata.datecreated,
						totalCost: "$" + price.toFixed(2),
						orderNumber: "SC-" + data.course[0].id,
					});
				});
				rowsData.push({
					item: "Industry 4.0 Dimension training courses",
					datePlaced: data.course[0].datecreated,
					totalCost: "$" + totalCost.toFixed(2),
					orderNumber: "SC-" + data.course[0].id,
					downloadInvoiceUrl: data.invoiceUrl,
					subTableData: subrowData,
				});
			} else {
				rowsData.push({
					item: data.course[0].course_name,
					datePlaced: data.course[0].datecreated,
					totalCost: "$" + (data.course[0].price - ((data.course[0].price*data.course[0].discount)/100)).toFixed(2),
					orderNumber: "SC-" + data.course[0].id,
					downloadInvoiceUrl: data.invoiceUrl,
					subTableData: null,
				});
			}
		});
	}
	return (
		// purchanse tab wrapper
		<div className="purchase__tab">
			{/* table has
				table-header
				table-body 
			 */}
			<div className="purchase__table__main">
				<div className="table__header">
					<div className="table__row">
						<div className="table__col__1">Items</div>
						<div className="table__col">Date placed</div>
						<div className="table__col">Total cost</div>
						<div className="table__col">Order number</div>
						<div className="table__col">Order Details</div>
					</div>
				</div>
				{/*table__header end */}
				<div className="table__body">
					{rowsData.length ? (
						rowsData.map((item, index) => <Row data={item} key={index} />)
					) : (
						<p className="purchase__history__text" style={{ textAlign: "center" }}>
							Currently your Purchase History is empty
						</p>
					)}
				</div>
				{/* table__body end */}
			</div>
		</div>
	);
};
