export const faqData = [
  {
    id: 1,
    cardTitle: `1. What is an operator logbook?`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: true,
  },
  {
    id: 2,
    cardTitle: `2. What do you mean by estimated cycle time?`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: false,
  },
  {
    id: 3,
    cardTitle: `3. What are IoT sensors?`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: false,
  },
  {
    id: 4,
    cardTitle: `4. What are cobots (collaborative robots)?`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: false,
  },
  {
    id: 5,
    cardTitle: `5. What is real-time process data input?`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: false,
  },
  {
    id: 6,
    cardTitle: `6. What is the Item Specification Management?`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: false,
  },
  {
    id: 7,
    cardTitle: `7. With inspection do you mean visual inspection or any type of inspection? (referring to the answer "Inspection between individual steps")`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: false,
  },
  {
    id: 8,
    cardTitle: `8. When you say "our system automatically identifies when & where a defect occurred" does it mean that it translates back to the line?`,
    ques: `Do you employ with your MES any of the following operational efficiency functionalities?` ,
    ans: `An interface that operators can pass information from one shift to the other and leave notes regarding the production process.`,
    show: false,
  },
]
const PlatformData = [
  {
    id: 1,
    path: require("../../assets/img/resource-center/maturity-assessment.svg")
      .default,
    platform: "Assessment",
    "engage-time": "3 minutes engagement",
    "img-class": "",
    platform_type: "AssessmentWalkthrough",
    url: "/learning-assessment-walkthrough",
  },
  {
    id: 2,
    path: require("../../assets/img/resource-center/loss-anylisis.svg").default,
    platform: "IR4.0 Loss Analysis",
    "engage-time": "3 minutes engagement",
    "img-class": "",
    platform_type: "LossWalthrough",
    "engage-class": "loss-text",
    url: "/learning-loss-walkthrough",
  },
  {
    id: 3,
    path: require("../../assets/img/resource-center/configurator-icon.svg")
      .default,
    platform: "IR4.0 Strategy",
    "engage-time": "3 minutes engagement",
    "img-class": "",
    platform_type: "LossWalthrough",
    "engage-class": "loss-text",
    url: "/learning-product-tour",
  },
];
export default PlatformData;
