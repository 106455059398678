/** imported components

  * BreadcrumbsItem  ===> component from react to add breadcrumbs on the page.
  * Helmet ===> for add specific title & CSS for the page.
  * AssignSectionPopup  for ===> Popup for assigning users for the chapters.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { apiEndpoints } from '../../services/api-endpoints'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { AssignSectionPopup } from './assessment-assign-popup';
//ChapterPlantView component start

const getShortName = (val) => {
	let tmpStrArr = val.split(" ");
	let tmpArrLength = tmpStrArr.length;
	let shortName = tmpArrLength > 1 ? `${tmpStrArr[0].toLowerCase()[0]}${tmpStrArr[tmpArrLength - 1].toLowerCase()[0]}` : tmpStrArr[0].toLowerCase()[0]
	console.log(shortName, tmpStrArr)
	return shortName;
}


export const ChapterPlantView = (props) => {
	// defining variables here
	const sectionDataItems = props.sectionData;
	if (!sectionDataItems.length) {
		return null;
	}
	const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
	const [dis, setDis] = useState(false);
	const [distext, setDistext] = useState("Submit Results");
	const [discount, setDiscount] = useState(1);
	const [walkthroughOpen, setWalkthroughOpen] = useState(true);
	const sectionDataItem = props.sectionData[0];
	const catData = sectionDataItem.category;
	const chapterDataItem = sectionDataItem.chapters;
	const section_on = sectionDataItem.section_on;
	const isChaptertour = sectionDataItem.isChaptertour;
	const block = sectionDataItem.block;
	const plant_id = sectionDataItem.plant_id;
	// variables for right-assessment section start SCNV-1930
	const [isResume, setIsResume] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [isSubmitPop, setIsSubmitPop] = useState(false);
	const [isUnlockPop, setIsUnlockPop] = useState(false);
	const [isFinalSubmit, setIsFinalSubmit] = useState(false);
	const [isChapter, setIsChapter] = useState(true);
	const totalSection = chapterDataItem.length;
	const [isUserHover, setIsUserHover] = useState(false);
	const cid = sectionDataItem.cid;
	// variables for right-assessment section end SCNV-1930
	if (block == 'block') {
		return <Redirect to="/home" />;
	}
	if (!catData) {
		return null;
	}
	useEffect(() => {

		if (sectionDataItem.progress >= 100) {
			setIsSubmit(true);
			setIsChapter(false);
		}
		if (sectionDataItem.save_on == 1) {
			setIsSubmit(false);
			setIsFinalSubmit(true);
		}
		if (sectionDataItem.is_datasubmitted == 1) {

			//setIsFinalSubmit(false);
		}

	}, [])
	const inviteteam = sectionDataItem.invitedteam;

	useEffect(() => {
		sectionLock(1)
		return () => {
			sectionLock(0)
		}
	}, [])

	// Function for lock the section
	const sectionLock = async (status = 1) => {
		const { sectionData } = props
		if (Array.isArray(sectionData)) {
			const { uid, plant_id, category } = sectionData[0]
			const apiUrl = `/sectionlock`;
			await serviceProvider.post(apiUrl, { uid, plant_id, section_id: category.id, type: 1, status }, true)
		}
	}

	var fullname = "1500000000";
	var email = "";
	if (!inviteteam.length) {
		fullname = "1500000000";
		email = "";
		// return null;
	} else {

		//   console.log(cidd);
		fullname = inviteteam[0].full_name;
		email = inviteteam[0].email
	}
	// console.log(email);  	
	const uid = sectionDataItem.uid;
	const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	// const slug = getLastItem(window.location.pathname);
	const slug = props.slug;
	// console.log(sectionDataItem.plantslug);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const group_id = user ? user.group_id : 0;

	const HandleSubmitResult = () => {
		setDis(true);
		setDiscount(discount + 1);
		setDistext("Submitting Results...");
		if (discount == 1) {
			serviceProvider.post("/results?type=calculate", JSON.stringify({ plant_id: uid, user_id: uid }), true).then((response) => {
				//console.log(response);
				window.location = "/result-walkthrough";
			});
		}
	};
	const handleAssignPoup = (e) => {
		setIsAssignSectionPopup(true);
		e.preventDefault();
	}

	//Conditions related to assessment right section (more informative right section for chapters)

	//Function for final submission
	const handleFinalSubmit = () => {
		setIsFinalSubmit(!isFinalSubmit);
		setIsSubmitPop(!isSubmitPop);
		setIsChapter(isChapter);
		setIsSubmit(!isSubmit);
		serviceProvider
			.post(
				"/assessmentmaturity?type=savechapter",
				JSON.stringify({ user_id: usid, plant_id: plant_id, group_id:group_id,section_id: catData.id }),
				true
			)
			.then((response) => {
				console.log(response);
				if(group_id == 14){
					var userData = JSON.parse(localStorage.getItem("userData"));
					userData.progress = response.data.progress;  
					localStorage.setItem("userData", JSON.stringify(userData));
				}
			});
	}
	//Function to handle resume
	const handleResume = () => {
		setIsSubmit(!isSubmit);
		setIsChapter(!isChapter);
	}
	//Function to handle Unlock a chapter
	const handleUnlock = () => {
		setIsUnlockPop(!isUnlockPop);
		setIsFinalSubmit(!isFinalSubmit);
		setIsSubmit(!isSubmit);
		serviceProvider
			.post(
				"/assessmentmaturity?type=unlockchapter",
				JSON.stringify({ user_id: usid, plant_id: plant_id, section_id: catData.id }),
				true
			)
			.then((response) => {

			});
	}

	//Conditions related to assessment right section end

	// console.log(chapterDataItem);
	const chapterDataItemSections = chapterDataItem.map((chapter, index) => {
		const count = index + 1;
		if (chapter.lastcatprogress == 'access') {
			return (
				<>
					{/* Card wrapper for the chapters */}
					{(cid != 10 && (chapter.id != 45)) ?
						<Link key={chapter.id} data-index={index} data-id={chapter.id} to={`/assessment/start/${slug}/${chapter.id}`} className="assigned-card access">
							{/* Left section is containing number for the chapters */}
							<div className="assigned-card-left">
								<div className="assigned-card-icon-wrapper">
									<span className="number-text">{count}</span>
								</div>
							</div>
							{/* card left end */}
							{/* Right section is containing assigned/unassigned user structure, chapter category name. */}
							<div className="assigned-card-right">
								<div className="assigned-rgt-sub-lft">
									<h1>
										{chapter.subcategory_name}
										<img alt="true" className="info-icon" src={require("../../assets/img/assigned/card-i-icon.svg").default} />
									</h1>
									<p className="scnv-2147-p">
										<span className="res-text">Responsible:</span>

										{fullname == 1500000000 ?
											<span className="res-text">Unassigned</span>
											:
											<div className="scnv-2147-cir-user" onClick={(e) => { e.stopPropagation(); e.preventDefault() }} onMouseEnter={() => setIsUserHover(true)} onMouseLeave={() => setIsUserHover(false)}>
												<ul className="cir-2147-ul">
													{inviteteam?.slice(0, 2).map((assinged_user, index) => {
														let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);
														return (
															<li className={`cir-2147-li  ${!assinged_user.profile_pic ? assinged_user.avcolor : 'cir-transparent'}`} key={index}>
																<div className="cir-ddl2147">
																	{!assinged_user.profile_pic ?
																		<div className={`${assinged_user.avcolor}`}>
																			{/* {assinged_user.name} */}
																			{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																			{shortName}
																		</div>
																		:
																		<div className={`cir2147 cir-b-none cir-transparent`}>
																			<img className="h__profile__img_cir "

																				src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																				alt={assinged_user.profile_pic}
																			/>
																		</div>}
																</div>
															</li>
														)
													})}
													{inviteteam?.length > 2 && (
														<div className="cir-2147-li circle-num">
															<span>
																{inviteteam?.length - 2}
															</span>
														</div>
													)}

												</ul>
												<div className="cir-2147-ddl chapter-page-ddl">
													<ul className="cir-ddl-user">
														{inviteteam?.map((assinged_user, index) => {
															let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);
															console.log(assinged_user);
															return (
																<li className="cir-ddl2147-li" key={index}>
																	<div className="cir-ddl2147">
																		{!assinged_user.profile_pic ?
																			<div className={`cir2147 ${assinged_user.avcolor}`}>
																				{/* {assinged_user.name} */}
																				{shortName}
																				{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																			</div>
																			:
																			<div className={`cir2147 cir-b-none`}>
																				<img className="h__profile__img_cir "

																					src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																					alt={assinged_user.profile_pic}
																				/>
																			</div>}
																	</div>
																	<div className="txt-s2147">
																		<p className="name-2147">{
																			assinged_user.full_name ? assinged_user.full_name : assinged_user.email
																		}</p>
																		<p className="role-2147">{assinged_user.job_title ? assinged_user.job_title : ''}</p>
																	</div>
																</li>
															)
														}
														)
														}
													</ul>
												</div>
											</div>}
									</p>
								</div>
								{/* Chapter progress */}
								<div className="assigned-rgt-sub-rgt">
									<div className="assigned-card-rgt-border">
										<div className="assigned-progress-bar assigned-progress" data-percent="0%">
											<div>
												<SvgCircle value={chapter.catprogress} />
											</div>
										</div>
										{/* Button to navigate next page */}
										<div className="assigned-card-rgt-angle">
											<img alt="true" src={require("../../assets/img/assigned/right-angle.svg").default} />
										</div>
									</div>
								</div>
							</div>
							{/* Popup will show on card hover */}
							{!isUserHover && <div className={`sellection-card-popup ${index > 2 && "last-popup"}`}>
								<h2>{chapter.subcategory_name}</h2>
								<div dangerouslySetInnerHTML={{ __html: chapter.subcategory_description }}></div>
							</div>}
						</Link>
						: cid == 10 && chapter.id == 44 ?
							<Link key={chapter.id} data-index={index} data-id={chapter.id} to={`/plant-cost/${slug}/${chapter.id}`} className="assigned-card access">
								{/* Left section is containing number for the chapters */}
								<div className="assigned-card-left">
									<div className="assigned-card-icon-wrapper">
										<span className="number-text">{count}</span>
									</div>
								</div>
								{/* card left end */}
								{/* Right section is containing assigned/unassigned user structure, chapter category name. */}
								<div className="assigned-card-right">
									<div className="assigned-rgt-sub-lft">
										<h1>
											{chapter.subcategory_name}
											<img alt="true" className="info-icon" src={require("../../assets/img/assigned/card-i-icon.svg").default} />
										</h1>
										<p className="scnv-2147-p">
											<span className="res-text">Responsible:</span>

											{fullname == 1500000000 ?
												<span className="res-text">Unassigned</span>
												:
												<div className="scnv-2147-cir-user" onClick={(e) => { e.stopPropagation(); e.preventDefault() }} onMouseEnter={() => setIsUserHover(true)} onMouseLeave={() => setIsUserHover(false)}>
													<ul className="cir-2147-ul">
														{inviteteam?.slice(0, 2).map((assinged_user, index) => {
															let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);
															return (
																<li className={`cir-2147-li  ${!assinged_user.profile_pic ? assinged_user.avcolor : 'cir-transparent'}`} key={index}>
																	<div className="cir-ddl2147">
																		{!assinged_user.profile_pic ?
																			<div className={`${assinged_user.avcolor}`}>
																				{/* {assinged_user.name} */}
																				{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																				{shortName}
																			</div>
																			:
																			<div className={`cir2147 cir-b-none cir-transparent`}>
																				<img className="h__profile__img_cir "

																					src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																					alt={assinged_user.profile_pic}
																				/>
																			</div>}
																	</div>
																</li>
															)
														})}
														{inviteteam?.length > 2 && (
															<div className="cir-2147-li circle-num">
																<span>
																	{inviteteam?.length - 2}
																</span>
															</div>
														)}

													</ul>
													<div className="cir-2147-ddl chapter-page-ddl ">
														<ul className="cir-ddl-user">
															{inviteteam?.map((assinged_user, index) => {
																let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);
																console.log(assinged_user);
																return (
																	<li className="cir-ddl2147-li" key={index}>
																		<div className="cir-ddl2147">
																			{!assinged_user.profile_pic ?
																				<div className={`cir2147 ${assinged_user.avcolor}`}>
																					{/* {assinged_user.name} */}
																					{shortName}
																					{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																				</div>
																				:
																				<div className={`cir2147 cir-b-none`}>
																					<img className="h__profile__img_cir "

																						src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																						alt={assinged_user.profile_pic}
																					/>
																				</div>}
																		</div>
																		<div className="txt-s2147">
																			<p className="name-2147">{
																				assinged_user.full_name ? assinged_user.full_name : assinged_user.email
																			}</p>
																			<p className="role-2147">{assinged_user.job_title ? assinged_user.job_title : ''}</p>
																		</div>
																	</li>
																)
															}
															)
															}
														</ul>
													</div>
												</div>}
										</p>
									</div>
									{/* Chapter progress */}
									<div className="assigned-rgt-sub-rgt">
										<div className="assigned-card-rgt-border">
											<div className="assigned-progress-bar assigned-progress" data-percent="0%">
												<div>
													<SvgCircle value={chapter.catprogress} />
												</div>
											</div>
											{/* Button to navigate next page */}
											<div className="assigned-card-rgt-angle">
												<img alt="true" src={require("../../assets/img/assigned/right-angle.svg").default} />
											</div>
										</div>
									</div>
								</div>
								{/* Popup will show on card hover */}
								{!isUserHover && <div className={`sellection-card-popup ${index > 2 && "last-popup"}`}>
									<h2>{chapter.subcategory_name}</h2>
									<div dangerouslySetInnerHTML={{ __html: chapter.subcategory_description }}></div>
								</div>}
							</Link>
							:
							cid == 12 && chapter.id == 45 ?
								<Link key={chapter.id} data-index={index} data-id={chapter.id} to={`/kpi-selection/${slug}/${chapter.id}`} className="assigned-card access">
									{/* Left section is containing number for the chapters */}
									<div className="assigned-card-left">
										<div className="assigned-card-icon-wrapper">
											<span className="number-text">{count}</span>
										</div>
									</div>
									{/* card left end */}
									{/* Right section is containing assigned/unassigned user structure, chapter category name. */}
									<div className="assigned-card-right">
										<div className="assigned-rgt-sub-lft">
											<h1>
												{chapter.subcategory_name}
												<img alt="true" className="info-icon" src={require("../../assets/img/assigned/card-i-icon.svg").default} />
											</h1>
											<p className="scnv-2147-p">
												<span className="res-text">Responsible:</span>

												{fullname == 1500000000 ?
													<span className="res-text">Unassigned</span>
													:
													<div className="scnv-2147-cir-user" onClick={(e) => { e.stopPropagation(); e.preventDefault() }} onMouseEnter={() => setIsUserHover(true)} onMouseLeave={() => setIsUserHover(false)}>
														<ul className="cir-2147-ul">
															{inviteteam?.slice(0, 2).map((assinged_user, index) => {
																let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);
																return (
																	<li className={`cir-2147-li  ${!assinged_user.profile_pic ? assinged_user.avcolor : 'cir-transparent'}`} key={index}>
																		<div className="cir-ddl2147">
																			{!assinged_user.profile_pic ?
																				<div className={`${assinged_user.avcolor}`}>
																					{/* {assinged_user.name} */}
																					{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																					{shortName}
																				</div>
																				:
																				<div className={`cir2147 cir-b-none cir-transparent`}>
																					<img className="h__profile__img_cir "

																						src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																						alt={assinged_user.profile_pic}
																					/>
																				</div>}
																		</div>
																	</li>
																)
															})}
															{inviteteam?.length > 2 && (
																<div className="cir-2147-li circle-num">
																	<span>
																		{inviteteam?.length - 2}
																	</span>
																</div>
															)}

														</ul>
														<div className="cir-2147-ddl chapter-page-ddl ">
															<ul className="cir-ddl-user">
																{inviteteam?.map((assinged_user, index) => {
																	let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);
																	console.log(assinged_user);
																	return (
																		<li className="cir-ddl2147-li" key={index}>
																			<div className="cir-ddl2147">
																				{!assinged_user.profile_pic ?
																					<div className={`cir2147 ${assinged_user.avcolor}`}>
																						{/* {assinged_user.name} */}
																						{shortName}
																						{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																					</div>
																					:
																					<div className={`cir2147 cir-b-none`}>
																						<img className="h__profile__img_cir "

																							src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																							alt={assinged_user.profile_pic}
																						/>
																					</div>}
																			</div>
																			<div className="txt-s2147">
																				<p className="name-2147">{
																					assinged_user.full_name ? assinged_user.full_name : assinged_user.email
																				}</p>
																				<p className="role-2147">{assinged_user.job_title ? assinged_user.job_title : ''}</p>
																			</div>
																		</li>
																	)
																}
																)
																}
															</ul>
														</div>
													</div>}
											</p>
										</div>
										{/* Chapter progress */}
										<div className="assigned-rgt-sub-rgt">
											<div className="assigned-card-rgt-border">
												<div className="assigned-progress-bar assigned-progress" data-percent="0%">
													<div>
														<SvgCircle value={chapter.catprogress} />
													</div>
												</div>
												{/* Button to navigate next page */}
												<div className="assigned-card-rgt-angle">
													<img alt="true" src={require("../../assets/img/assigned/right-angle.svg").default} />
												</div>
											</div>
										</div>
									</div>
									{/* Popup will show on card hover */}
									{!isUserHover && <div className={`sellection-card-popup ${index > 2 && "last-popup"}`}>
										<h2>{chapter.subcategory_name}</h2>
										<div dangerouslySetInnerHTML={{ __html: chapter.subcategory_description }}></div>
									</div>}
								</Link>
								:
								''
					}
				</>
			);
		} else {

			return (
				// Card wrapper for the chapters
				<Link key={chapter.id} data-index={index} data-id={chapter.id}  to={`#`} className="assigned-card noaccess" >
					{/* Left section is containing number for the chapters */}
					<div className="assigned-card-left">
						<div className="assigned-card-icon-wrapper">
							<span className="number-text">{count}</span>
						</div>
					</div>
					{/* card left end */}
					{/* Right section is containing assigned/unassigned user structure, chapter category name. */}
					<div className="assigned-card-right">
						<div className="assigned-rgt-sub-lft">
							<h1>
								{chapter.subcategory_name}
								<img alt="true" className="info-icon" src={require("../../assets/img/assigned/card-i-icon.svg").default} />
							</h1>
							<p>
								<span className="res-text">Responsible:</span>
								{fullname == 1500000000 ?
									<span className="res-text">Unassigned</span>
									:
									<div className="scnv-2147-cir-user" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
										<ul className="cir-2147-ul">
											{inviteteam?.slice(0, 3).map((assinged_user, index) => {
												let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);
												return (
													<li className={`cir-2147-li ${!assinged_user.profile_pic ? assinged_user.avcolor : 'cir-transparent'}`} key={index}>
														{!assinged_user.profile_pic ?
															<div className={`cir2147 ${assinged_user.avcolor}`}>
																{/* {assinged_user.name} */}
																{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																{shortName}
															</div>
															:
															<div className={`cir2147 cir-b-none cir-transparent`}>
																<img className="h__profile__img_cir "

																	src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																	alt={assinged_user.profile_pic}
																/>
															</div>}
													</li>
												)
											})}

										</ul>
										<div className="cir-2147-ddl chapter-page-ddl ">
											<ul className="cir-ddl-user">
												{inviteteam?.map((assinged_user, index) => {
													let shortName = assinged_user.full_name ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);

													return (
														// 
														<li className="cir-ddl2147-li" key={index}>
															<div className="cir-ddl2147">
																{!assinged_user.profile_pic ?
																	<div className={`cir2147 ${assinged_user.avcolor}`}>
																		{/* {assinged_user.name} */}
																		{shortName}
																		{/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
																	</div>
																	:
																	<div className={`cir2147 cir-b-none`}>
																		<img className="h__profile__img_cir "

																			src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
																			alt={assinged_user.profile_pic}
																		/>
																	</div>}
															</div>
															<div className="txt-s2147">
																<p className="name-2147">{
																	assinged_user.full_name ? assinged_user.full_name : assinged_user.email
																}</p>
																<p className="role-2147">{assinged_user.job_title ? assinged_user.job_title : ''}</p>
															</div>
														</li>
													)
												}
												)
												}
											</ul>
										</div>
									</div>
								}
							</p>
						</div>
						<div className="assigned-rgt-sub-rgt">
							{/* Chapter Progress */}
							<div className="assigned-card-rgt-border">
								<div className="assigned-progress-bar assigned-progress" data-percent="0%">
									<div>
										<SvgCircle value={chapter.catprogress} />
									</div>
								</div>
								{/* Button to navigate on the next page */}
								<div className="assigned-card-rgt-angle">
									<img alt="true" src={require("../../assets/img/assigned/right-angle.svg").default} />
								</div>
							</div>
						</div>
					</div>
					{/* Popup will show on card hover */}
					<div className={`sellection-card-popup ${index > 2 && "last-popup"}`}>
						<h2>{chapter.subcategory_name}</h2>
						<div dangerouslySetInnerHTML={{ __html: chapter.subcategory_description }}></div>
					</div>
				</Link>
			);


		}
	});

	return (
		<>
			{/* Page Title */}
			<Helmet>
				<title>{catData.category_name} | SmarterChains</title>
				<meta name="description" content={`${catData.category_name} Assessment`} />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			{/* Breadcrumbs items */}
			<BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/">{sectionDataItem.plant_name}</BreadcrumbsItem>
			<BreadcrumbsItem to={`/assessment/${slug}`}>Assessment</BreadcrumbsItem>
			<BreadcrumbsItem to="#">{catData.category_name}</BreadcrumbsItem>
			{/* Chapter main page wrapper */}
			<div className="assigned-main-page">
				{/* Main wrapper that has been divided into two parts */}
				<section className="assigned-content">
					{/* Left section that contains chapter number, it's progress and right navigation button. */}
					<Link to={`/assessment/${slug}`} className="h2 sm-visible">
						<i dangerouslySetInnerHTML={{__html:arrowSvg}}></i>
						<span>{catData.category_name}</span>
					</Link>
					<section className="assigned-sec">
						<h2 className="h2">{catData.category_name}</h2>
						<div className="assigned-card-row-continer">
							<div className="assigned-card-row">{chapterDataItemSections}</div>
						</div>
						{/* {sectionDataItem.progress == 100 && catData.id == "1" && group_id == 2 && (
							<a disabled={dis} class="btn-submit-result" onClick={HandleSubmitResult}>
								{distext}
							</a>
						)} */}
					</section>
					{/* Right Chapter Section */}
					<div className="right-assessment-section">
						{/* Top section having progress bar and progress title */}
						<div className="top-progress-bar">
							<div className="progress-bar-section">
								<div className="header-progress">
									<p className="progress-title">Section Progress:</p>
									<h2>{sectionDataItem.progress}%</h2>
									<div className="header-progress-bar">
										<div style={{ width: sectionDataItem.progress + '%' }}></div>
									</div>
								</div>
							</div>
							<div className="submit-section-count">
								<span className="heading">Chapters Completed: </span>
								{/* "sections submitted" will be replace from "sections completed" when the all sections will be completed */}
								<div className="count">
									{sectionDataItem.total_completed} / {totalSection}
								</div>
								{((isFinalSubmit && group_id == 14) || (sectionDataItem.save_on == 1 && group_id == 14)) &&
									<div className="submit-text pro">
										<img src={require("../../assets/img/assessment-right-section/green-check.svg").default} alt="Green Check" />
										<span>Data Submitted</span>
									</div>
								}

							</div>
						</div>
						{isChapter && (
							<div className="assessment-submission-section chapter-section">
								{/* Conditional element depend upon chapter progress */}
								<div className="top-sec">
									{(sectionDataItem.progress == 100 && (group_id == 11 || group_id == 2)) ? (
										<span>Action required</span>
									) : (
										<span className="progress-text">Assessment In-Progress</span>
									)}
								</div>

								<div className="bottom-section">

									{section_on.length && (
										<div className="bottom-content-section">
											<div className="num-section">
												<div className="number">{section_on[0].order_by}</div>
												<span>{section_on[0].subcategory_name}</span>
											</div>
											<p className="bottom-text">
												{section_on[0].question}
											</p>
										</div>
									)}

									{sectionDataItem.progress != 0 ? (
										<div className="button-wrap">
											{/* Conditional buttons */}
											<>
												{cid != 10 ?
													<Link className="chapter-btn resume" to={`/assessment/start/${slug}/${section_on[0].subcategory_id}/${section_on[0].ques_id}`} >Resume Section</Link>
													: cid == 10 && section_on[0].subcategory_id == 44 ?
														<Link className="chapter-btn resume" to={`/plant-cost/${slug}/${section_on[0].subcategory_id}`} >Resume Section</Link>
														:
														cid == 12 && section_on[0].subcategory_id == 45 ?
															<Link className="chapter-btn resume" to={`/kpi-selection/${slug}/${section_on[0].subcategory_id}`} >Resume Section</Link>
															:
															''
												}
											</>

										</div>
									) : (
										<div className="button-wrap">
											<>
												{(cid != 10 && cid != 12)?
													<Link className="chapter-btn" to={`/assessment/start/${slug}/${section_on[0].subcategory_id}/${section_on[0].ques_id}`}>Start Section</Link>
													: cid == 10 && section_on[0].subcategory_id == 44 ?
													<Link className="chapter-btn" to={`/plant-cost/${slug}/${section_on[0].subcategory_id}`}>Start Section</Link>
														:
														cid == 12 && section_on[0].subcategory_id == 45 ?
														<Link className="chapter-btn" to={`/kpi-selection/${slug}/${section_on[0].subcategory_id}`}>Start Section</Link>
															:
															''
												}
											</>
											
										</div>
									)}
								</div>
							</div>
						)}
						{isSubmit && (
							<div className="assessment-submission-section submit-section">
								{/* Submission section which will come conditional */}
								<div className="top-sec">
									{(sectionDataItem.progress == 100) ? (
										<span>Action required</span>
									) : (
										<span className="progress-text">Assessment In-Progress</span>
									)}
								</div>

								<div className="bottom-section after-submit">
									<div className="bottom-content-section">
										<p className="bottom-text">
											Congratulations, you have completed the section.
										</p>
										<p>
											Please submit your data by clicking the "Submit Data" button.
											Note that after submission you can't change your input.
										</p>
									</div>
									<div className="button-wrap">
										<button className={`submit-btn ${(sectionDataItem.progress == 100) ? 'active' : ''}`} disabled={(sectionDataItem.progress == 100) ? false : true} onClick={() => handleFinalSubmit()}>Submit Data</button>
									</div>
								</div>

							</div>
						)}
						{(isFinalSubmit && (group_id == 11 || group_id == 2)) && (
							<>
								{/* Final submission and unlock chapter section */}
								<div className="assessment-submission-section final-submit">
									<div className="top-sec">
										<span>No further action required</span>
									</div>
									<div className="bottom-content-section">
										<p>Your data have been successfully submitted.</p>
									</div>
									<div className="button-wrap">
										<div className="submit-text">
											<img src={require("../../assets/img/assessment-right-section/green-check.svg").default} alt="Green Check" />
											<span>Data Submitted</span>
										</div>
									</div>
								</div>
								{sectionDataItem.is_datasubmitted == 0 && (<div className="assessment-submission-section unlock-section">
									<div className="top-sec">
										<img src={require("../../assets/img/assessment-right-section/lock.svg").default} alt="Lock" />
										<span>Unlock section to change submitted data</span>
									</div>
									<div className="bottom-content">
										<p className="top-txt">All the chapters will be unlocked to allow changes.</p>
										<p>Please note, that any change may result in the addition or removal of questions.</p>
									</div>
									<div className="button-wrap">
										<button className="unlock-btn" onClick={() => handleUnlock()}>Unlock & edit section</button>
									</div>
								</div>)}
							</>
						)}
					</div>
					{/* Right Chapter Section end */}
				</section>

			</div>
			{/* Popup Start */}
						{/* Submit Popup  */}
						{/*isSubmitPop && (
							<div className="popup-box-blue" >
								<div className="center-box">
									<div className="content-section">
										<h2>Are you sure, you would like to submit your data?</h2>
										<p>Please note, that you will not be able to make changes after submission.</p>
										<div className="button-section">
											<button className="cancel" onClick={() => setIsSubmitPop(!isSubmitPop)}>Cancel</button>
											<button className="submit" onClick={() => handleFinalSubmit()}>Yes, submit</button>
										</div>
									</div>
								</div>
							</div>
						)} 
						{/* Unlock Popup */}
						{/*isUnlockPop && (
							<div className="popup-box-red">
								<div className="center-box">
									<div className="content-section">
										<h2>Are you sure, you would like to unlock the section?</h2>
										<p>Any change may result in the addition or removal of questions.</p>
										<div className="button-section">
											<button className="cancel" onClick={() => setIsUnlockPop(!isUnlockPop)}>Cancel</button>
											<button className="submit" onClick={() => handleUnlock()}>Yes, unlock</button>
										</div>
									</div>
								</div>
							</div>
						)*/}
						{/* Popup end */}
			{/* Assignpopup component call on the page */}
			<AssignSectionPopup open={isAssignSectionPopup} setOpen={setIsAssignSectionPopup}  {...props} />
			{/* {walkthroughOpen && isChaptertour!=1?
			  <div className="admin-wakthrough-modal">
			    <div className="admin-walkthrough">
			      <div className="bg-admin">
			                  <img src={require(`../../assets/img/admin-walkthrough/admin-bg-${slideIndex}.svg`).default} alt={`admin-bg-${slideIndex}`} />
			              </div> 
			      <div className="carousel-wrapper for-chapter">
			        <button className="modal-close " onClick={()=>handelCloseWalkThrough()}>
			          <img src={require("../../assets/img/admin-walkthrough/close.svg").default} alt="X" />
			        </button>

			        <div className="slide-item">
			          <div className="left-frame">
			            <img src={require(`../../assets/img/admin-walkthrough/chapter.gif`).default} alt="chapter" />
			          </div>
			          <div className="right-frame">
			            <div className="slide-content">
			              <h2 className="slide-h1">Start the Assessment</h2>
			                <p className="slide-p">
			                Welcome to this Section. It is split into Chapters. See more information about each Chapter through the i icon. Navigate and track progress.<b> Click the arrow to start the assessment.</b>
			                </p>
			            </div>
			            <div className="slide-footer">
			              <div className="button-div">
			                  <button className="blue"  onClick={()=>handelCloseWalkThrough()}>
			                    OK
			                  </button>
			              </div>
			            </div>
			          </div>
			        </div>

			        
			      </div>
			    </div>
			  </div>
			  :null} */}
			{/* Walkthorugh compoonent conditionally call for first time user view. */}
			{walkthroughOpen && isChaptertour != 1 ? <AdminWalkthroughView /> : null}
		</>
	);
};
// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCircle = (props) => {
	const { value } = props;
	return (
		<svg viewBox="0 0 36 36">
			<text className="percent__text" x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="600">
				{value}
				<tspan fontSize="5">%</tspan>
			</text>
			<path
				className="circle-bg"
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke="#EBF5FA"
				strokeWidth="2.4"
				fill="none"
			></path>
			<path
				className="circle"
				strokeDasharray={`${value}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke={value < 100 && value > 0 ? "#FFBC1F" : value === 100 ? "#40D30D" : "transparent"}
				strokeWidth="2.4"
				fill="none"
			></path>
		</svg>
	);
};

// Walkthrough for new right section of chapter page

const AdminWalkthroughView = (props) => {
	//Variable declaration
	const users = JSON.parse(localStorage.getItem("userData"));
	const UserName = users ? users.name : "First Name";
	const uid = users ? users.uid : "";
	const [sliderStatus, setSliderStatus] = useState(1);

	// Function to handle walkthrough slider
	const HandleSlider = (val) => {
		setSliderStatus(val);
	}
	//Close button function
	const handleClose = () => {

		serviceProvider

			.post(
				"/home?type=setchapter",
				JSON.stringify({ uid: uid }),
				true
			)
			.then((response) => {
				setSliderStatus(0);
			});
	}
	// Walkthorugh slider conditions
	useEffect(() => {
		if (sliderStatus === 1) {
			document.querySelector('.training-header').classList.add('div-z99');
			document.querySelector('.training-content').classList.add('div-z99');
		}
		else {
			document.querySelector('.training-header').classList.remove('div-z99');
			document.querySelector('.training-content').classList.remove('div-z99');
		}
		if (sliderStatus === 2) {
			document.querySelector('.training-content').classList.add('div-z992');
		}
		else {
			document.querySelector('.training-content').classList.remove('div-z992');
		}
	}, [sliderStatus]);

	return (
		<>
			{/* link css */}
			<Helmet>
				<link rel="stylesheet" href={require('./chapter-walkthrough.scss').default} />
			</Helmet>
			{/* link css end*/}
			<section className="general-information-walkthrough">
				<div className="general-information-top">
					{/*---------------------  Walkthrough Wrapper  ------------------------*/}
					<div className="general-info-walkthrough">
						{sliderStatus === 1 ?
							<div className="g-walkthrough-screen1 gstep">
								{/* Walkthrough first chapter walkthrough screen */}
								<div className="g-walkthrough-wrapper">
									{/* This has been divided into two parts left and right  */}
									<div className="g-walk-row">
										{/* Left section is containg image */}
										<div className="g-walk-col-left">
											<img src={require("../../assets/img/chapter-walkimg/chapter-step1.gif").default} alt="Question Step 1" />
										</div>
										{/* Right section has text and buttons to navigate from one to next and next to previous card */}
										<div className="g-walk-col-right">
											<div className="col-right-card-wrap">
												<div className="card-header">
													<div className="left-text-wrap">
														<p>1 of 2</p>
													</div>
													<div className="close-btn">
														<button className="close" onClick={handleClose}><img src={require("../../assets/img/chapter-walkimg/close-gray.svg").default} alt="digital_maturity" /></button>
													</div>
												</div>
												<div className="card-body">
													<h4 className="heading">Assessment Section</h4>
													<p className="txt">Welcome to this Section and its Chapters.</p>
													<p className="txt">From this interface you can see more information, navigate and track progress.</p>
												</div>
												<div className="card-footer">
													<div className="card-footer-section">
														<div className="card-footer-left">
															<button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
															<button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
														</div>
														<div className="card-footer-right">
															<button className="prev-link link" onClick={handleClose}>No thanks</button>
															<button onClick={() => { HandleSlider(2) }} className="link solid">Let’s go</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							: null}
						{sliderStatus === 2 ?
							<div className="g-walkthrough-screen2 gstep">
								{/* Walkthrough first chapter walkthrough screen */}
								<div className="g-walkthrough-wrapper">
									{/* This has been divided into two parts left and right  */}
									<div className="g-walk-row">
										{/* Left section is containg image */}
										<div className="g-walk-col-left"><img src={require("../../assets/img/chapter-walkimg/chapter-step-2.gif").default} alt="Question Walkthrough" className="close-img" /></div>
										{/* Right section has text and buttons to navigate from one to next and next to previous card */}
										<div className="g-walk-col-right">
											<div className="col-right-card-wrap">
												<div className="card-header">
													<div className="left-text-wrap">
														<p>2 of 2</p>
													</div>
													<div className="close-btn">
														<button className="close" onClick={handleClose}><img src={require("../../assets/img/chapter-walkimg/close-gray.svg").default} alt="digital_maturity" /></button>
													</div>
												</div>
												<div className="card-body">
													<h4 className="heading">Start the Assessment</h4>
													{/*comment2*/}
													<p className="txt">When all chapters are completed, you will need to submit the section through the key actions interface.</p>
													<p className="txt">Click the arrow or start section button to start the assessment.</p>
												</div>
												<div className="card-footer">
													<div className="card-footer-section">
														<div className="card-footer-left">
															<button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
															<button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
														</div>
														<div className="card-footer-right">
															<button onClick={() => { HandleSlider(1) }} className="prev-link link">Restart</button>
															<button onClick={() => handleClose()} className="let link solid">Close</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							: null}
					</div>
				</div>
			</section>
		</>
	);
};
// SCNV 2153

const arrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_1407_41957" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1407_41957)">
  <path d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z" fill="#0D2C54"/>
</g>
</svg>`

