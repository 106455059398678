/** imported components
 
  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * useHistory ===> hook to access the history object.
  * Helmet ===> for add specific title & CSS for the page.
  * GeneralInformationPagePlantView  ===> custom view component

**/
import React from "react";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, Link, Redirect } from "react-router-dom";
import { GeneralInformationPagePlantView } from "./general-information-page-plant-view";

//GeneralInformationPagePlantControlle start
export const GeneralInformationPagePlantController = (props) => {
 //defining variables here
  const segments = window.location.pathname.slice(1).split("/");
  const slug = segments[2];
  const sid = segments[3];
  const ssid=segments[4];
  const [results, setResults] = useState([]);
  const [unlockbutton, setUnlockbutton] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
 const group_id = user ? user.group_id : 0;
  useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
		};
		search();
	}, [unlockbutton]);
 
//Conditions for result  
if (results.length) {
	if(results[0].block == 1){
			var pp = "/not-found";
			return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
		}
	if(results[0].unassign == 1){
			
			return <Redirect to="/user-unassign" />;
			return (
					<>
						<div></div>
					</>
			);
		}
			
	if(results[0].is_locked == 1){
			if(group_id == 14){
				return <Redirect to={`/lossanalysis/section/${slug}`} />;
			}else{
				return <Redirect to={`/loss-analysis-admin/section/${slug}`} />;
				}
			return (
					<>
						<div></div>
					</>
			);
		}	
	if(window.location.hash != '' && (results[0].isLocks == 1 || results[0].has_businesscase == 1)){
		var pp = "/not-found";
		return <Redirect to="/not-found" />;
		return (
				<>
					<div></div>
				</>
		);
	}
	else{
		return (
			<>
				{/* Page Title */}
				<Helmet>
					<title>General Information | SmarterChains</title>
					<meta name="description" content="Loss Analysis" />
				</Helmet>
				<GeneralInformationPagePlantView slug={slug} quesResult={results} setUnlockbutton={setUnlockbutton} {...props} />
			</>
		);
	}		
}
else{
	return (
			<>
				<div></div>
			</>
		);
	}
} 


