
import React, { useState } from "react";
import {
  RoadTabPanelWrapper,
  RoadTabPanelWrap,
  RoadtabPanelCard,
  RoadTabPanelHeader,
  RoadTabPanelBody,
} from "./styled.js"

//ProfileView component start
export const RoadmapTabPanelView = (props) => {
  const data = props.results;
  const [roadTabData,setRoadTabData] = useState(data.roadmap)
	
	return (
		<>
			  <RoadTabPanelWrapper data-component="RoadTabPanelWrapper">
          <RoadTabPanelWrap data-component="RoadTabPanelWrap">
          { roadTabData.map((item,index)=>(
            <RoadtabPanelCard data-component="RoadtabPanelCard" className={item.color===`green`?`green` : item.color===`orange`?`orange` : item.color===`blue`?`blue` : ''}>
              <RoadTabPanelHeader data-component="RoadTabPanelHeader">
                  <span className="title">{item.title}</span>
              </RoadTabPanelHeader>
              <RoadTabPanelBody data-component="RoadTabPanelBody">
               
                  <div className="list">
                    <ul>
                    {item?.tab_col?.col_array?.map((sub_item,sub_index)=>(
                        <li key={index}>{sub_item.text}</li>
                    ))}
                    {item?.tab_col?.col_array?.length==0 &&
                        <li key={index}>N/A</li>
                    }
                    </ul>
                  </div>
               
              </RoadTabPanelBody>
            </RoadtabPanelCard>
          ))
          }
          </RoadTabPanelWrap>
        </RoadTabPanelWrapper>
		</>
	);
};
