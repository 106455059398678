/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page
  * Helmet ===> for add specific title & CSS for the page.
  * AssignSectionPopup for ===> component cakll for assign user.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  *  _ from 'lodash ===> javascript library

**/


import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import $ from "jquery";
import { Modal } from "@material-ui/core";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { array } from "prop-types";
import _ from 'lodash';
import { apiEndpoints } from '../../services/api-endpoints';


//defining svg image
const SubmitSvg = () => <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M29.8002 5.65428L24.3457 0.199752C24.2179 0.0719106 24.0448 0 23.8636 0H2.7273C1.22382 0 0 1.22312 0 2.7273V27.2728C0 28.7769 1.22382 30.0001 2.7273 30.0001H27.2728C28.7762 30.0001 30.0001 28.7769 30.0001 27.2728V6.13637C30 5.95528 29.9281 5.78212 29.8002 5.65428ZM6.81821 1.36362H21.8182V9.54544C21.8182 10.2971 21.207 10.9091 20.4546 10.9091H8.18182C7.42942 10.9091 6.81821 10.2971 6.81821 9.54544V1.36362ZM24.5455 28.6364H5.45453V16.3636H24.5455V28.6364ZM28.6364 27.2728C28.6364 28.0245 28.0251 28.6364 27.2728 28.6364H25.9092V15.6818C25.9092 15.3049 25.6042 15 25.2273 15H4.77275C4.39588 15 4.09091 15.3049 4.09091 15.6818V28.6364H2.7273C1.97489 28.6364 1.36368 28.0245 1.36368 27.2728V2.7273C1.36368 1.97559 1.97495 1.36368 2.7273 1.36368H5.45459V9.5455C5.45453 11.0496 6.67835 12.2727 8.18182 12.2727H20.4546C21.958 12.2727 23.1819 11.0496 23.1819 9.54544V1.36362H23.5814L28.6364 6.4187V27.2728Z" fill="#178ACB" />
  <path d="M17.0456 9.54575H19.7729C20.1498 9.54575 20.4547 9.24078 20.4547 8.86391V3.40938C20.4547 3.0325 20.1498 2.72754 19.7729 2.72754H17.0456C16.6687 2.72754 16.3638 3.0325 16.3638 3.40938V8.86391C16.3638 9.24078 16.6687 9.54575 17.0456 9.54575ZM17.7274 4.09122H19.091V8.18213H17.7274V4.09122Z" fill="#178ACB" />
  <circle cx="31" cy="8" r="7" fill="#178ACB" />
  <rect x="32" y="9" width="2" height="6" rx="1" transform="rotate(-180 32 9)" fill="white" />
  <rect x="32" y="12.333" width="2" height="2" rx="1" transform="rotate(-180 32 12.333)" fill="white" />
</svg>
const getShortName = (val) => {
  let tmpStrArr = val.split(" ");
  let tmpArrLength = tmpStrArr.length;
  let shortName = tmpArrLength > 1 ? `${tmpStrArr[0].toLowerCase()[0]}${tmpStrArr[tmpArrLength - 1].toLowerCase()[0]}` : tmpStrArr[0].toLowerCase()[0];
  return shortName;
}
//AssessmentPlantView starts
export const AssessmentView = (props) => {
  //Defining variables here
  const sectionDataItems = [
    {
      "uid": "3010",
      "is_completed": "0",
      "is_company_plant": 1,
      "noline": 0,
      "inviteteam": [],
      "planid": 0,
      "plantslug": "chacjha",
      "plant_id": "1947",
      "plant_name": "chacjha",
      "category": [
        {
          "id": "0",
          "is_line": "0",
          "category_img": "assets\/img\/plant-cost-and-kpi-profile-w.svg",
          "category_name": "Plant Cost and KPI Profile",
          "category_description": "<p>The section&apos;s objective is to address the influence of Industry 4.0 into the company&apos;s strategy.&nbsp;<\/p> <p>In particular, we will explore Industry 4.0 strategies and initiatives, sustainability practices, structures and policies that provide the agility to design and roll out an effective digital transformation.<\/p> <p>Typically, the person responsible for this section is the Assessment leader.<\/p> <p>Estimated engagement 35 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "1",
          "is_line": "0",
          "category_img": "assets\/img\/ind4-icon-w.svg",
          "category_name": "Industry 4.0 Vision",
          "category_description": "<p>The section&apos;s objective is to address the influence of Industry 4.0 into the company&apos;s strategy.&nbsp;<\/p> <p>In particular, we will explore Industry 4.0 strategies and initiatives, sustainability practices, structures and policies that provide the agility to design and roll out an effective digital transformation.<\/p> <p>Typically, the person responsible for this section is the Assessment leader.<\/p> <p>Estimated engagement 35 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "2",
          "is_line": "0",
          "category_img": "assets\/img\/operational-excellence-w.svg",
          "category_name": "Operational Excellence (OpEx)",
          "category_description": "<p>The section&apos;s objective is to address the Operational Excellence (OpEx) practices of the organization.<\/p> <p>OpEx refers to a workplace philosophy where problem-solving, teamwork, and leadership results in the ongoing improvement of the organization.&nbsp;<\/p> <p>In particular, we will explore the policies and technology solutions which support your organization to achieve the desired results in the continuous improvement journey.<\/p> <p>Typically, the person responsible for this section is the Operational Excellence leader.<\/p> <p>Estimated engagement 20 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "3",
          "is_line": "0",
          "category_img": "assets\/img\/info-icon-w.svg",
          "category_name": "Information Systems",
          "category_description": "<p>The section&apos;s objective is to address the Information Systems of your plant.<\/p> <p>In particular, we will explore technologies used to collect, filter, process, create and distribute data like, Enterprise Resource Planning (ERP) System, Product Lifecycle Management (PLM) Software, etc.&nbsp;<\/p> <p>Typically, the person responsible for this section is the IT Manager.<\/p> <p>Estimated engagement 20 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "4",
          "is_line": "0",
          "category_img": "assets\/img\/cybersecurity-icon-w.svg",
          "category_name": "Cybersecurity & Data Architecture",
          "category_description": "<p>The section&apos;s objective is to address your Cybersecurity and Data Architecture practices and policies.<\/p> <p>Cybersecurity is the practice of defending computers, servers, mobile devices, electronic systems, networks, and data from malicious attacks.&nbsp;<\/p> <p>In particular, we will explore your plant connectivity, cybersecurity, and backup solutions.&nbsp;<\/p> <p>Typically, the person responsible for this section is the IT Manager.<\/p> <p>Estimated engagement 25 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "5",
          "is_line": "0",
          "category_img": "assets\/img\/maintenance-w.svg",
          "category_name": "Maintenance",
          "category_description": "<p>The section&apos;s objective is to address the maintenance process of your plant.<\/p> <p>In particular, we will explore methods and systems utilized to manage maintenance proactively based on data, such as equipment condition metrics and historical events.<\/p> <p>Typically, the person responsible for this section is the Head of Maintenance.&nbsp;<\/p> <p>Estimated engagement 30 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "6",
          "is_line": "0",
          "category_img": "assets\/img\/quality-w.svg",
          "category_name": "Quality",
          "category_description": "<p>The section's objective is to address the methods followed to manage quality processes.<\/p>\n<p>In particular, we will explore the techniques, software and hardware that leverage real-time data to manage quality control and quality assurance in the plant.<\/p> \n<p>Typically, the person responsible for this section is the Quality Manager.<\/p> \n<p>Estimated engagement 30 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "7",
          "is_line": "0",
          "category_img": "assets\/img\/ehs-w.svg",
          "category_name": "Environment, Health & Safety (EHS)",
          "category_description": "<p>The section&apos;s objective is to address the Environmental, Health &amp; Safety (EHS) practices you follow.<\/p> <p>In particular, we will explore management practices and technology solutions, such as EHS software, which support your organization to reduce its environmental footprint and increase safety at work.&nbsp;<\/p> <p>Typically, the person responsible for this section is the EHS Manager.<\/p> <p>Estimated engagement 30 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "8",
          "is_line": "0",
          "category_img": "assets\/img\/warehouse-material-handling-w.svg",
          "category_name": "Warehouse & Material Handling",
          "category_description": "<p>The section&apos;s objective is to address the Warehouse and Material Handling Solutions you leverage.<\/p> <p>In particular, we will explore machinery and systems that automate and optimize material handling processes throughout the manufacturing facility, as well as practices and policies regarding the handling and storage of your products.&nbsp;<\/p> <p>Typically, the person responsible for this section is the Warehouse Leader.<\/p> <p>Estimated engagement 30 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "active",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        },
        {
          "id": "9",
          "is_line": "1",
          "category_img": "assets\/img\/\/production-execution-w.svg",
          "category_name": "Production Execution",
          "category_description": "<p>The section&apos;s objective is to address the execution of production operations.<\/p> <p>In particular, we will explore the hardware, software and methods that eliminate human touches in production planning and execution, as well as the practices applied in production operations management.&nbsp;<\/p> <p>Typically, the person responsible for this section is the Production Manager.<\/p> <p>Estimated engagement 65 minutes.<\/p>",
          "no_line": 0,
          "cardclass": "inactive",
          "cardclass1": "withuser",
          "catprogress": 0,
          "catkey": null
        }
      ],
      "progress": 0,
      "isAssessmenttour": "1",
      "isproductiontour": "0",
      "open_line": 0,
      "is_datasubmitted": 0,
      "locked_sections": 0,
      "locked_sections_array": []
    }
  ];
  if (!sectionDataItems.length) {
    return null;
  }
  const isusers = (localStorage.getItem("group_id"));
  const sectionDataItem = sectionDataItems[0];
  const block = sectionDataItem.block;
  const is_company_plant = sectionDataItem.is_company_plant;
  if (block == 'block') {
    return <Redirect to="/404" />;
  }
  // console.log(sectionDataItems);
  const catData = sectionDataItem.category;
  if (!catData) {
    return null;
  }

  const inviteteam = sectionDataItem.inviteteam;
  const isAssessmenttour = sectionDataItem.isAssessmenttour;
  if (!inviteteam) {
    return null;
  }

  const totalCat = catData.length;
  // const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
  // const slug = getLastItem(window.location.pathname);
  const slug = sectionDataItem.plantslug;

  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
  const [isUpgradePopup, setIsUpgradePopup] = useState(false);
  const [isProductionPopup, setIsProductionPopup] = useState(false);
  const [cate, setCate] = useState();
  const [catename, setCateName] = useState();

  const [tmpAssignUser, setTmpAssignUser] = useState("");
  const [assignedUsers, setAssignedUsers] = useState({});
  const [sectionLockedHover, setSectionLockedHover] = useState(false);
  //variables for assessment right section start 
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [isSubmitPop, setIsSubmitPop] = useState(false);
  const [isPlanSubmit, setIsPlanSubmit] = useState(true);
  const [isUserHover, setIsUserHover] = useState(false);
  //variables for assessment right section end

  useEffect(() => {
    let tmpObj = {};
    let tmpArr = [];
    let uniqArr = [];
    inviteteam?.forEach((item) => {
      tmpArr.push(item.invited_category);
    })
    uniqArr = [...new Set(tmpArr)];
    uniqArr.forEach((item) => {
      let name = `username${item}`
      let tmpEl = inviteteam.filter((innerItem) => innerItem.invited_category == item);
      tmpObj = { ...tmpObj, [name]: tmpEl }
    })


    setAssignedUsers(tmpObj);
    if (sectionDataItem.is_datasubmitted != 0) {
      setIsFinalSubmit(true);
      //setIsSubmitPop(!isSubmitPop);
      setIsPlanSubmit(false);

    }



  }, []);

  // Function for final submit assessment
  const handleFinalSubmit = () => {
    setIsFinalSubmit(!isFinalSubmit);
    setIsSubmitPop(!isSubmitPop);
    setIsPlanSubmit(!isPlanSubmit);
    serviceProvider
      .post(
        "/assessmentmaturity?type=savechapter",
        JSON.stringify({ user_id: sectionDataItem?.uid, plant_id: sectionDataItem?.plant_id, section_id: 0 }),
        true
      )
      .then((response) => {

      });
  }
  //Function to handle modal
  const HandleModal = () => {
    setIsModal(!isModal);
    setSlideIndex(0);
    props.handleGetValue(0);
  };
  //Function for handle slide
  const HandleSlideIndex = (ev) => {
    if (ev === "min" && slideIndex > 0) {
      let tmpVal = slideIndex - 1;
      setSlideIndex(tmpVal);
      props.handleGetValue(tmpVal);
    } else if (!ev && slideIndex < TotalSlides - 1) {

      let tmpVal = slideIndex + 1;
      setSlideIndex(tmpVal);
      //console.log(tmpVal)
      props.handleGetValue(tmpVal);

    }
  };
  const otherScreen = () => {
    let unlockSection = document.querySelector('.unlockbtn');

  }
  //variable declaration for walkthrough
  const [sliderActive, setSliderActive] = useState(0);
  //function to handle walkthrough and class addition
  const handleGetValue = (e = null) => {

    setSliderActive(e);
    let objDiv = document.querySelector('.training-content');
    let headerLink = document.querySelector('.header-page-link-ul');
    let headerProgress = document.querySelector('.header-right-frame');
    let projectNamebox = document.querySelector('.text-box')
    let projectName = document.querySelector('.text-left p')
    let icon = document.querySelector('.text-left img')
    let sideArrow = document.querySelector('.angle-box')
    let unlockSection = document.querySelector('.unlockbtn');
    let blur = document.querySelector('.admin-walkthrough')
    let progressBar = document.querySelector('.header-right-frame')

    if (e === 0) {
      blur.classList.add('blur-effect');
    } else {
      blur.classList.remove('blur-effect');
    }
    if (e === 1) {
      headerLink.classList.add('z-index9999');
      progressBar.classList.add('z-index9999');
    } else {
      progressBar.classList.remove('z-index9999');
      headerLink.classList.remove('z-index9999');
    }
    if (e === 2) {
      projectName.classList.add('z-index9999');
      icon.classList.add('z-index9999');
      projectNamebox.classList.add('z-index999');
    } else {
      projectName.classList.remove('z-index9999');
      icon.classList.remove('z-index9999');
      projectNamebox.classList.remove('z-index999');
    }
    if (e === 3) {
      sideArrow.classList.add('z-index9999');
    } else {
      sideArrow.classList.remove('z-index9999');
    }
    if (e === 4) {
      objDiv.scrollTop = objDiv.scrollHeight;
    } else {
      objDiv.scrollTop = 0;
    }
    if (e === 4 && sectionDataItem.noline == '') {
      unlockSection.classList.add('z-index9999');
    } else {
      if (unlockSection) { //added if to check class exists
        unlockSection.classList.remove('z-index9999');
      }
    }

  }
  //Function call for upgrade popup
  const HandleUpdgradePopup = () => {
    setIsUpgradePopup(!isUpgradePopup);
  };
  //Function for assign popup
  const handleAssignPoup = (e) => {
    e.stopPropagation();
    e.preventDefault();
    var cat = $(e.currentTarget).attr("data-id");
    var catname = $(e.currentTarget).attr("data-name");
    setCate(cat);
    setCateName(catname);
    setIsAssignSectionPopup(true);


  };
  //changes done for ipad
  const inActiveSectionClick = (e) => {
    let unlockSection = document.querySelector('.unlockbtn');
    let assess = document.querySelector('.assessment-main')
    if (!e.target.className.includes('assignee-link-unlock')) {
      /*
       * comented for SCNV-2478
         setIsProductionPopup(true);
      */
      assess.classList.add('active');
      unlockSection.classList.remove('z-index9999');
    }
  }
  //Function for lock section
  const setSectionLocked = async (e, sectionId) => {
    e.preventDefault();

    if (typeof e.target.className.includes !== 'undefined' && !e.target.className.includes('assignee-link-unlock')) {
      try {
        await serviceProvider.post('/sectionlock', { uid: sectionDataItem?.uid, section_id: sectionId, type: 1, status: 1, plant_id: sectionDataItem?.plant_id })
      }
      catch (err) {
        console.log(error)
      }
      props.history.push(`/assessment/chapter/${slug}/${sectionId}`)
    } else {
      props.history.push(`/assessment/chapter/${slug}/${sectionId}`)
    }

  }

  const chapterDataItemSections = catData.map((chapter, index) => {
    const count = index + 1;
    // chapter.cardclass="active";
    return (
      //card wrapper for assessment
      <a
        key={chapter.id}
        data-index={index}
        href={
          chapter.cardclass == "active" && !chapter?.locked_user
            ? `/assessment/chapter/${slug}/${chapter.id}`
            : "#"
        }
        className={`all-sellection-card ${chapter.cardclass} `}
        onClick={(e) =>
          chapter.cardclass1 == "withuser" && chapter.cardclass == "inactive" && index == 8 && ((group_id == 11 || group_id == 2) && is_company_plant == 1)
            ? inActiveSectionClick(e)
            : chapter.cardclass == "active" && !chapter?.locked_user
              ? setSectionLocked(e, chapter.id) : '#'
          // : HandleUpdgradePopup()
        }
      >
        {/* Left box of the card which is containing image */}
        <div className="left-img-box">
          <img
            src={`${apiEndpoints.baseUrl}/${chapter.category_img}`}
            alt={chapter.category_name}
          />
        </div>
        {index == 8 && sectionDataItem.noline == '' && (((isusers == 11 || isusers == 2) && is_company_plant == 1) || sectionDataItem.open_line == 1) &&
          <div className='unlockbtn' onClick={() => { otherScreen(); setIsProductionPopup(true) }}>Unlock this section</div>
        }
        {/* text-box has text ,l assigne/unassigned user */}
        <div className="text-box">
          <div className="text-left">
            <h2 className="h1">
              <span>{chapter.category_name}</span>
              <img src={require("../../assets/img/all-section/card-i-icon.svg").default} alt="i" />
            </h2>

            {/* scnv-2147 start */}
            <div className="responsible-text">
              <p className="scnv-2147-p">
                <span className="res-text">Responsible:</span>

                {!assignedUsers[`username${chapter.id}`] || assignedUsers[`username${chapter.id}`]?.length == 0 ? (<>
                  {((isusers == 11 || isusers == 2) && is_company_plant == 1) ? (
                    <button
                      className={`res-link-2147 res-link ${(isusers == 11 || isusers == 2) ? '' : chapter.cardclass} `}
                      data-id={chapter.id}
                      data-name={chapter.category_name}
                      onClick={(e) => {
                        if ((isusers == 11 || isusers == 2) && is_company_plant == 1) {
                          handleAssignPoup(e);
                          setTmpAssignUser(`username${chapter.id}`);
                        }
                      }}
                    >
                      <span className="svg-span" dangerouslySetInnerHTML={{ __html: assignIcon }}></span>
                      <span>
                        Assign
                      </span>
                    </button>
                  ) :
                    (<span className="res-text">Unassigned</span>)
                  }
                </>) : (
                  <>
                    <div className="scnv-2147-cir-user" onClick={(e) => { e.stopPropagation(); e.preventDefault() }} onMouseEnter={() => setIsUserHover(true)} onMouseLeave={() => setIsUserHover(false)}>
                      <ul className="cir-2147-ul">

                        {assignedUsers[`username${chapter.id}`]?.slice(0, 3).map((assinged_user, index) => {
                          let shortName = (assinged_user.full_name && assinged_user.full_name != 'Not Registered') ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);

                          return (
                            <li className={`cir-2147-li ${!assinged_user.profile_pic ? assinged_user.avcolor : 'cir-transparent'}`} key={index}>
                              <div className="cir-ddl2147">
                                {!assinged_user.profile_pic ?
                                  <div className={`${assinged_user.avcolor}`}>
                                    {/* {assinged_user.name} */}
                                    {shortName}
                                    {/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
                                  </div>
                                  :
                                  <div className={`cir2147 cir-b-none cir-transparent`}>
                                    <img className="h__profile__img_cir"

                                      src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
                                      alt={assinged_user.profile_pic}
                                    />
                                  </div>}
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                      <div className="cir-2147-ddl">
                        <ul className="cir-ddl-user">
                          {assignedUsers[`username${chapter.id}`]?.map((assinged_user, index) => {
                            let shortName = assinged_user.full_name && assinged_user.full_name != 'Not Registered' ? `${assinged_user.name[0]}${assinged_user?.last_name ? assinged_user?.last_name[0] : ''}` : getShortName(assinged_user.email);

                            return (
                              <li className="cir-ddl2147-li" key={index}>
                                {/* <div className="cir-ddl2147">
                                  <span
                                    className={`cir2147 ${assinged_user.avcolor}`}
                                  >
                                    {shortName}

                                  </span>
                                </div> */}
                                <div className="cir-ddl2147">
                                  {!assinged_user.profile_pic ?
                                    <div className={`cir2147 ${assinged_user.avcolor}`}>
                                      {/* {assinged_user.name} */}
                                      {/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
                                      {shortName}
                                    </div>
                                    :
                                    <div className={`cir2147 cir-b-none`}>
                                      <img className="h__profile__img_cir"

                                        src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
                                        alt={assinged_user.profile_pic}
                                      />
                                    </div>}
                                </div>
                                <div className="txt-s2147">
                                  <p className="name-2147">{
                                    assinged_user.full_name && assinged_user.full_name != 'Not Registered' ? assinged_user.full_name : assinged_user.email
                                  }</p>
                                  <p className="role-2147">{assinged_user.job_title ? assinged_user.job_title : ''}</p>
                                </div>
                              </li>
                            )
                          }
                          )
                          }
                        </ul>
                        {((isusers == 11 || isusers == 2) && is_company_plant == 1) && <div className="ft-ddl2147">
                          <button className="edit-tm"
                            data-id={chapter.id}
                            data-name={chapter.category_name}
                            onClick={(e) => {
                              if ((isusers == 11 || isusers == 2) && is_company_plant == 1) {
                                handleAssignPoup(e);
                                setTmpAssignUser(`username${chapter.id}`);
                              }
                            }}
                          >
                            <span className="svg-span" dangerouslySetInnerHTML={{ __html: assignIcon }}></span><span>Edit Team</span>
                          </button>
                        </div>}

                      </div>
                    </div>
                  </>
                )}
              </p>


              {sectionDataItem.locked_sections_array.includes(chapter.id) ?
                (
                  <div className="submit-img-text">
                    <img src={require("../../assets/img/assessment-right-section/check.svg").default} alt="Green Check" />
                    <span>Data Submitted</span>
                  </div>
                ) : (
                  <>

                  </>
                )}
            </div>
            {/* scnv-2147 end */}
          </div>
          {/* Locked Section start*/}
          {chapter?.section_locked && chapter.cardclass == "active" &&
            <div className="assigned-rgt-sub-mid" onMouseEnter={() => setSectionLockedHover(true)} onMouseLeave={() => setSectionLockedHover(false)}>
              <button className="lock-btn">
                <img alt="true" src={require("../../assets/img/assigned/lock.svg").default} />
                <span>Section Locked</span>
              </button>
              <div className="locked-section-popup">
                <p>The section is temporarily locked as it is currently being edited by:</p>
                <div className="pop-footer">
                  <div className={`circle ${chapter?.locked_user_avcolor}`}>{`${chapter?.locked_user_first_name[0]}${chapter?.locked_user_last_name ? chapter?.locked_user_last_name[0] : ''}`}</div>
                  <span className="name">{chapter?.locked_user}</span>
                </div>
              </div>
            </div>}
          {/* Locked Section end */}
          {/* Progress circle */}

          <div style={{ visibility: sectionDataItem.noline == "" && index == 8 && (assignedUsers[`username${chapter.id}`] === undefined) ? '' : '' }} className="text-cir">
            <SvgCircle value={chapter.catprogress} />
          </div>
        </div>
        {/*  Right section for navigate to next page */}
        <div style={{ visibility: sectionDataItem.noline == "" && index == 8 && (assignedUsers[`username${chapter.id}`] === undefined) ? '' : '' }} className="angle-box">
          <img src={require("../../assets/img/all-section/right-angle.svg").default} alt="true" />
        </div>

        {/* popup that will show on card hover */}
        {!isUserHover &&
          <div className={`sellection-card-popup ${index > 6 && "last-popup"} ${sectionLockedHover ? ' sellection-card-popup-hidden' : ''}`}>
            <h2>{chapter.category_name}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: chapter.category_description }}
            ></div>
          </div>
        }
      </a>
    );
    // }
  });

  const user = (localStorage.getItem("group_id"));
  const group_id = user ? user : 0;
  const [distext, setDistext] = useState("Submit your input");
  //Function to handle Plant Admin
  const handleplantAdmin = () => {
    // console.log("hello");
    if (group_id == 11) {
      window.location = "/maturity-dashboard-admin";
    } else {
      window.location = "/maturity-dashboard";
    }
  };

  const uid = sectionDataItem.uid;
  const plantid = sectionDataItem.plant_id;
  //Function to handle result page
  const handleresultpage = () => {
    setDistext("Submitting Input...");
    serviceProvider
      .post(
        "/resultsmaturityassess?type=calculate",
        JSON.stringify({ plant_id: plantid, user_id: uid }, true)
      )
      .then((response) => {
        //console.log(response);
        window.location = `/result/${slug}`;
        setDistext("Submit your input");
      });
    // window.location = "/result";
  };
  useEffect(() => {

    if (props.see == 'open_line') {
      otherScreen(); setIsProductionPopup(true);
      props.setSee(false);
    }


  }, [props.see]);



  return (
    <>
      {/* Page Title */}
      <Helmet>
        <title>Assessment | SmarterChains</title>
        <meta name="description" content={`Assessment`} />
        <link href={require("./assessment.scss").default} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to={`/assessment/${slug}`} onClick={() => setIsProductionPopup(false)}>Assessment</BreadcrumbsItem>
      {isProductionPopup && <BreadcrumbsItem to="#">Production Lines Setup</BreadcrumbsItem>}
      {/* assessment wrapper start ("assessment-main" class added for custom changes)*/}
      <div className="all-sellection-main assessment-main" style={{ display: isProductionPopup ? 'none' : '' }}>
        <div className="asst-plant-details-wrapper"
          style={{ display: sectionDataItem.noline == "" && (group_id == 11 || group_id == 2 || sectionDataItem.open_line == 1) ? "block" : "none" }}
        >
          {/*hide this button for SCNV-2147*/}
          {/*<button
                      className="addPlantLines"
                      onClick={() => setIsProductionPopup(true)}      
                    >
                      <img
                        src={
                          require("../../assets/img/production-line/bg-yellow-i-icon.svg")
                            .default
                        }
                        alt=""
                      />
                      <span>Plant details</span>
                    </button>
            */}
          {/* end hide this button for SCNV-2147*/}
        </div>
        {/* left section of assessment section which has card,responsible-text,assign/unassign user, progress circle and angle button to navigate next page.
        ("asst-card" class replaced from left-assessment-section for right-assessment section) */}
        <div className="left-assessment-section calc-button-wrap">
          {chapterDataItemSections}
          {(sectionDataItem.progress == 100 && ((group_id == 11 || group_id == 2) && is_company_plant == 1)) ? (
            // footer buttons that is come conditional.
            <div className="res-calc-btn-div">
              <button
                disabled={false}
                className="result-calc-btn"
                onClick={handleresultpage}
              >
                {distext}
              </button>
            </div>
          ) : (
            <div className="res-calc-btn-div">
              <button className="result-calc-btn" onClick={handleplantAdmin}>
                Go to plant page
              </button>
            </div>
          )}
        </div>
        {/* Assessment Right section changes start. Section has divided into two horizontal parts.*/}
        <div className="right-assessment-section">
          {/* top section containing progress of assessment */}
          <div className="top-progress-bar">
            <div className="progress-bar-section">
              <div className="header-progress">
                <p className="progress-title">Overall Progress:</p>
                <h2>{sectionDataItem.progress}%</h2>
                <div className="header-progress-bar">
                  <div style={{ width: sectionDataItem.progress + '%' }}></div>
                </div>
              </div>
            </div>
            <div className="submit-section-count">
              <span className="heading">Sections Submitted: </span>
              {/* "sections submitted" wiill be replace from "sections completed" when the all sections will be completed */}
              <div className="count">
                {sectionDataItem.locked_sections} / {totalCat}
              </div>
              {(sectionDataItem.is_datasubmitted == 1 && group_id == 14) && (
                <>
                  <div className="submit-text pro">
                    <img src={require("../../assets/img/assessment-right-section/green-check.svg").default} alt="Green Check" />
                    <span>Data Submitted</span>
                  </div>
                </>
              )}

            </div>
          </div>
          {/* Submission section which will come conditional */}
          {(isPlanSubmit && (group_id == 11 || group_id == 2)) && (
            <div className="assessment-submission-section">
              <div className="top-sec">
                {(sectionDataItem.progress == 100 && (group_id == 11 || group_id == 2)) ? (
                  <span>Action required</span>
                ) : (
                  <span className="progress-text">Assessment In-Progress</span>
                )}
              </div>
              <div className="bottom-content-section">
                <p className="bottom-content">When all sections are complete, click the "Submit Data" button to finalize the assessment.</p>
              </div>
              <div className="button-wrap">
                <button className={`submit-btn ${(sectionDataItem.progress == 100 && (sectionDataItem.locked_sections == totalCat) && (group_id == 11 || group_id == 2)) ? 'active' : ''}`} disabled={(sectionDataItem.progress == 100 && (sectionDataItem.locked_sections == totalCat) && (group_id == 11 || group_id == 2)) ? false : true} onClick={() => setIsSubmitPop(!isSubmitPop)}>Submit Data</button>
              </div>
            </div>
          )}
          {(isFinalSubmit && (group_id == 11 || group_id == 2)) && (
            <>
              <div className="assessment-submission-section final-submit">
                <div className="top-sec">
                  <span>No further action required</span>
                </div>
                <div className="bottom-content-section">
                  <p>Your data have been successfully submitted.</p>
                </div>
                <div className="button-wrap">
                  <div className="submit-text">
                    <img src={require("../../assets/img/assessment-right-section/green-check.svg").default} alt="Green Check" />
                    <span>Data Submitted</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* Submit Popup for assessment right section start*/}
        {isSubmitPop && (
          <div className="popup-box-blue" >
            <div className="center-box">
              <div className="content-section">
                <h2>Are you sure, you would like to submit your data?</h2>
                <p>Please note, that you will not be able to make changes after submission.</p>
                <div className="button-section">
                  <button className="cancel" onClick={() => setIsSubmitPop(!isSubmitPop)}>Cancel</button>
                  <button className="submit" onClick={() => handleFinalSubmit()}>Yes, submit</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* assessment right  section end*/}
      </div>


    </>
  );
};
// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCircle = (props) => {
  const { value } = props;
  return (
    <svg className="percent__svgs" viewBox="0 0 36 36">
      <text
        className="percent__text"
        x="50%"
        y="53%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="10"
        fontWeight="600"
      >
        {value}
        <tspan fontSize="5">%</tspan>
      </text>
      <path
        className="circle-bg"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke="#EBF5FA"
        strokeWidth="2.4"
        fill="none"
      ></path>
      <path
        className="circle"
        strokeDasharray={`${value}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={
          value < 100 && value > 0
            ? "#FFBC1F"
            : value === 100
              ? "#40D30D"
              : "transparent"
        }
        strokeWidth="2.4"
        fill="none"
      ></path>
    </svg>
  );
};







// scnv-2147
const assignIcon = `
<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.33366 7.58329C9.17532 7.58329 10.667 6.09163 10.667 4.24996C10.667 2.40829 9.17532 0.916626 7.33366 0.916626C5.49199 0.916626 4.00033 2.40829 4.00033 4.24996C4.00033 6.09163 5.49199 7.58329 7.33366 7.58329ZM7.33366 9.24996C5.10866 9.24996 0.666992 10.3666 0.666992 12.5833V14.25H14.0003V12.5833C14.0003 10.3666 9.55866 9.24996 7.33366 9.24996Z" fill="#118ACB"/>
<circle cx="14.834" cy="13.5833" r="3.5" fill="white"/>
<rect x="14.334" y="11.0833" width="1" height="5" fill="#118ACB"/>
<rect x="17.334" y="13.0833" width="1" height="5" transform="rotate(90 17.334 13.0833)" fill="#118ACB"/>
</svg>

`