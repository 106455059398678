import React, { useState, useEffect } from 'react';
import {
    MainWrapper,
    HowToUseBtn,
    HowToUsePopWrapper,
    HowToUsePopWrap,
    HowToUsePopHeader,
    HowCloseBtn,
    HowToUsePopBody,
    TabContentWrap,
    TabLeftSection,
    TabRightSection,
    TabBtn,
    TabRightSectionWrap,
    TabImgWrap,
    TabTxtWrap,
    ZoomImageContainer,
    TabHeadWrap,
    LossCatTab,
    LossTab,
    LossMethodTab,
} from './styles';
import {
    closeIcon, zoomSvg, ehsSvg, fteSvg, generalSvg, howIcon, itSvg, maintenaceSvg, manufacturingSvg, materialSvg, minorSvg, operatorSvg, plannedSvg, qualitySvg, speedSvg, unplannedSvg, warehouseSvg,lossPrincipleSvg,lossCalSvg
} from './svg';

export const HowToUseView = (props) => {
    const { sectionName } = props;
    const data = [
        {
            id: 1,
            title: `PLANNED DOWNTIME`,
            img: plannedSvg,
            blue_title: `Planned Downtime reason codes`,
            info_text: `Material availability, electrical breakdowns &  process failures the main reason codes for the 4 lines`
        },
        {
            id: 2,
            title: `UNPLANNED DOWNTIME`,
            img: unplannedSvg,
            blue_title: `Unplanned Downtime reason codes`,
            info_text: `Material availability, electrical breakdowns &  process failures the main reason codes for the 4 lines`
        },
        {
            id: 3,
            title: `MINOR STOPS`,
            img: minorSvg,
            blue_title: `Minor Stops reason codes`,
            info_text: `Minor set ups and unschedule cleanings main reason codes though most lack reason codes information`
        },
        {
            id: 4,
            title: `SPEED LOSSES`,
            img: speedSvg,
            blue_title: `Speed Losses reason codes`,
            info_text: `Parameter set up and tool wear main reason codes for Process 1 while most lack categorization`
        },
        {
            id: 5,
            title: `MATERIAL WASTE`,
            img: materialSvg,
            blue_title: `Material Waste reason codes`,
            info_text: `Material issues from suppliers and material transportation to the shopfloor the main reason codes `
        },
        {
            id: 6,
            title: `OPERATOR LOSSES`,
            img: operatorSvg,
            blue_title: `Operator Effort reason codes`,
            info_text: `Order confirmation, product rework and report preparation main reason codes`
        },
        {
            id: 7,
            title: `MAINTENANCE LOSSES`,
            img: maintenaceSvg,
            blue_title: `Maintenance reason codes `,
            info_text: `Troubleshooting, CIL's  , planning &scheduling and work order the main reason codes `
        },
        {
            id: 8,
            title: `IT LOSSES`,
            img: itSvg,
            blue_title: `IT reason codes`,
            info_text: `Meetings, trainings , resolving IT issues and aggregating data the main activities`
        },
        {
            id: 9,
            title: `WAREHOUSE LOSSES`,
            img: warehouseSvg,
            blue_title: `Warehouse reason codes`,
            info_text: `Moving FG's to warehouse, loading trucks, registering inbound/outbound and performance analysis `
        },
        {
            id: 10,
            title: `EHS LOSSES`,
            img: ehsSvg,
            blue_title: `EHS reason codes`,
            info_text: `Reporting, documentation,compliance , risk assessment and admin work the main reason codes`
        },
        {
            id: 11,
            title: `QUALITY LOSSES`,
            img: qualitySvg,
            blue_title: `Quality reason codes`,
            info_text: `Product release analysis , documentation and lab equipment maintenance main reason codes`
        },
        {
            id: 12,
            title: `GENERAL INFORMATION`,
            img: generalSvg,
            blue_title: `General Information `,
            info_text: `General plant characteristics to assist in further analysis`
        },
        {
            id: 13,
            title: `FTE BREAKDOWN`,
            img: fteSvg,
            blue_title: `FTE Breakdown reason codes`,
            info_text: `A detailed breakdown of full times employees working on the various departments in the plant`
        },
        {
            id: 14,
            title: `MANUFACTURING COST`,
            img: manufacturingSvg,
            blue_title: `Manufacturing Cost`,
            info_text: `All detail financial information for the plants operations by Process cost, Waste , Manufacturing cost`
        },
    ]
    const methodData =[
        {
            id: 1,
            title: `LOSS PRINCIPLES`,
            img: lossPrincipleSvg,
        },
        {
            id: 2,
            title: `LOSS CALCULATIONS`,
            img: lossCalSvg,
        },
    ]
    const [openPop, setOpenPop] = useState(false);
    const [activeData, setActiveData] = useState({});
    const [lossMethodData , setLossMethodData] = (useState(methodData[0]))
    const [zoomImage, setZoomImage] = useState(false);
    const [zoomData,setZoomData] =  useState({});
    const [isActive, setIsActive] = useState(1);

    useEffect(() => {
        if (sectionName) {
            let index = data.findIndex(d => d.title.toLowerCase() === sectionName.toLowerCase());
            let leftSec = document.querySelector('[data-component="TabLeftSection"]');
            let tabBtn = document.querySelectorAll('[data-component="TabBtn"]');
            if (index > -1) {
                let topPos = tabBtn[index]?.offsetTop;
                if (leftSec) {
                    leftSec.scrollTop = topPos;
                }
                setActiveData(data[index]);
            }
        } else {
            setActiveData(data[0]);
        }
    }, [openPop]);

    const handleLossTab = (index) => {
        setIsActive(index);
      };
    return (
        <>
            <MainWrapper data-component="MainWrapper">
                <HowToUseBtn data-component="HowToUseBtn" onClick={() => setOpenPop(true)}>
                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: howIcon }}></i>
                    <span className='how-txt'>How to use</span>
                </HowToUseBtn>
                {openPop ?
                    <HowToUsePopWrapper data-component="HowToUsePopWrapper">
                        
                            <HowToUsePopWrap data-component="HowToUsePopWrap" active={zoomImage===false}>
                                <HowToUsePopHeader data-component="HowToUsePopHeader">
                                    <span className='head-txt'>How to use IR4.0 Loss Analysis</span>
                                    <HowCloseBtn data-component="HowCloseBtn" onClick={() => setOpenPop(false)}>
                                        <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                    </HowCloseBtn>
                                </HowToUsePopHeader>
                                <HowToUsePopBody data-component="HowToUsePopBody">
                                    <TabContentWrap data-component="TabContentWrap">
                                        <TabHeadWrap data-component="TabHeadWrap">
                                            <LossTab
                                                data-component="LossTab"
                                                className={isActive == 1 ? "active" : ""}
                                                onClick={(e) => handleLossTab(1)}
                                            >
                                                Loss Categories
                                            </LossTab>
                                            <LossTab
                                                data-component="LossTab"
                                                className={isActive == 2 ? "active" : ""}
                                                onClick={(e) => handleLossTab(2)}
                                            >
                                                Loss Methodology
                                            </LossTab>
                                        </TabHeadWrap>
                                        <LossCatTab data-component="LossCatTab" open={isActive == 1 ? "open" : ""}>
                                            <TabLeftSection data-component="TabLeftSection">
                                                {data.map((item, index) => (
                                                    <TabBtn
                                                        data-component="TabBtn"
                                                        active={activeData.id === item.id}
                                                        key={index}
                                                        onClick={() => setActiveData(item)}
                                                    >
                                                        {item.title}
                                                    </TabBtn>
                                                ))
                                                }
                                            </TabLeftSection>
                                            <TabRightSection data-component="TabRightSection">
                                                <TabRightSectionWrap data-component="TabRightSectionWrap">
                                                    <TabImgWrap data-component="TabImgWrap">
                                                        <div className="loss-svg" dangerouslySetInnerHTML={{ __html: activeData.img }}></div>
                                                        <button className='zoom-btn' onClick={() => {setZoomImage(true); setZoomData(activeData)}}>
                                                            <i className="icon-svg" dangerouslySetInnerHTML={{ __html: zoomSvg }}></i>
                                                        </button>
                                                    </TabImgWrap>
                                                    <TabTxtWrap data-component="TextWrap">
                                                        <p className='blue-text'>{activeData.blue_title}</p>
                                                        <p className='gray-text'>{activeData.info_text}</p>
                                                    </TabTxtWrap>
                                                </TabRightSectionWrap>
                                            </TabRightSection>
                                        </LossCatTab>
                                        <LossMethodTab data-component="LossMethodTab" open={isActive == 2 ? "open" : ""}>
                                            <TabLeftSection data-component="TabLeftSection">
                                                {methodData.map((item, index) => (
                                                    <TabBtn
                                                        data-component="TabBtn"
                                                        key={index}
                                                        active={lossMethodData.id === item.id}
                                                        onClick={() => setLossMethodData(item) }
                                                    >
                                                       {item.title}
                                                    </TabBtn>
                                                ))
                                                }
                                            </TabLeftSection>
                                            <TabRightSection data-component="TabRightSection">
                                                <TabRightSectionWrap data-component="TabRightSectionWrap">
                                                    <TabImgWrap data-component="TabImgWrap">
                                                        <div className="loss-cat-svg" dangerouslySetInnerHTML={{ __html: lossMethodData.img }}></div>
                                                        <button className='zoom-btn' onClick={() => {setZoomImage(true); setZoomData(lossMethodData)}}>
                                                            <i className="icon-svg" dangerouslySetInnerHTML={{ __html: zoomSvg }}></i>
                                                        </button>
                                                    </TabImgWrap>
                                                    <TabTxtWrap data-component="TextWrap">

                                                    </TabTxtWrap>
                                                </TabRightSectionWrap>
                                            </TabRightSection>
                                        </LossMethodTab>
                                    </TabContentWrap>
                                </HowToUsePopBody>
                            </HowToUsePopWrap>
                            
                            <ZoomImageContainer data-component="ZoomImageContainer" active={zoomImage}>
                                 <HowCloseBtn data-component="HowCloseBtn" onClick={() => setZoomImage(false)}>
                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                </HowCloseBtn>
                                <div className='zoom-image-wrap' dangerouslySetInnerHTML={{ __html: zoomData.img}}></div>
                            </ZoomImageContainer>
                        
                    </HowToUsePopWrapper>
                    : ''}
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

