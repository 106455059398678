import React, { useState, useEffect } from 'react';
import { threeDotsIcon } from './svg';
export const ThreeDotsComponent = (
    {
        open=false,
		lockalldaata=0,
        setOpen=()=>{},
        isAvailable=false,
        setIsAvailable=()=>{},
        isAvailablePopup=false,
        setIsAvailablePopup=()=>{},
        isNotAvailablePopup=false,
        setIsNotAvailablePopup=()=>{},
        setIsAvailableFalse=()=>{},
        setIsAvailableTrue=()=>{},
        resetAvailablePopup=()=>{},
        resetNotAvailablePopup=()=>{},
        handleAvailabilityPopups=()=>{},
		props
    }

    ) => {
   
    // function to toggle menu popup 
    const toggleOpen = () => {
        setOpen(!open);
    }
    useEffect(() => {
        document.querySelector('body').onclick = (e) => {
            if (!e.target.closest('.three-dots-wrapper') && open) {
                setOpen(false);
            }
        }
    })
    return (
        <>
            <link rel="stylesheet" href={require('./three-dots-component.scss').require} />
            {/*-----------------------  three dots menu section start ------------------ */}
           {lockalldaata==0 ?
             <>                   
		    <div className='three-dots-wrapper'>
                <button className={`three-dots__btn ${open ? 'active' : ''}`} onClick={toggleOpen}>
                    <span dangerouslySetInnerHTML={{ __html: threeDotsIcon }}></span>
                </button>
                <div className={`${open ? 'active' : ''} ${isAvailable ? 'data-available-pop-up' : 'data-not-available-pop-up'}`}>

                    <div className="center-box">
                        <div className="content-section">
                            {isAvailable ?
                                <>
                                    <p>If you collect this data, select the "Data Available" button.</p>
                                    <div className="button-section">
                                        <button className="submit" onClick={() => handleAvailabilityPopups(false)}>Data Available</button>
                                    </div>
                                </>
                                :
                                <>
                                    <p>If you don't collect this data, select the "Data Not Available" button.</p>
                                    <div className="button-section">
                                        <button className="submit" onClick={() => handleAvailabilityPopups(true)}>Data Not Available</button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div></>
			:
			 <>
			 </>
			}
            {/*-----------------------  three dots menu section end ------------------ */}

            {/*-------------------------- data avlable popup start ---------------------- */}
            <div className={`data-avalable-selected-popup ${isAvailablePopup ? 'active' : ''}`}>
                <div className="center-box">
                    <div className="content-section">
                        <h2>Do you want to provide data for this section?</h2>
                        <div className="button-section">
                            <button className="cancel" onClick={resetAvailablePopup}>Cancel</button>
                            <button className="submit" onClick={setIsAvailableFalse}>Yes, unlock</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*-------------------------- data avlable popup end ---------------------- */}

            {/* ------------------------- data not available popup start ------------------------*/}
            <div className={`marked-dna-popup ${isNotAvailablePopup ? 'active' : ''}`}>
                <div className="center-box">
                    <div className="content-section">
                        <p>By not submitting any data, you agree that this part will be excluded from the analysis and no related results will be provided.</p>
                        <h2>Are you sure you want to proceed?</h2>
                        <div className="button-section">
                            <button className="cancel" onClick={resetNotAvailablePopup}>Cancel</button>
                            <button className="submit 1" onClick={setIsAvailableTrue}>Agree</button>
                        </div>
                    </div>
                </div>


            </div>
            {/* ------------------------- data not available popup end ------------------------- */}
        </>
    );
}

