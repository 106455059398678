import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import serviceProvider from "../../services/axios";
import { CartContext } from "../../contexts/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { logEvent, Result, ErrorResult } from "../../webutils/util";
/*payment page imports end*/
import { Modal } from "@material-ui/core";
import { ModalsWraper, ManageSubscriptionWrapper, ManageSubscriptionCard, ManageSubscriptionCardHeader, ManageSubscriptionCardBody, ManageSubscriptionCardFooter, InputWrapper, InputGroups, BlueBgBtn, LightBgBtn } from './profile-manage-subscription-style';
import { closeIcon } from './svg';

export const ProfileManageSubscriptionPopup = (props) => {
    const { open, closePopup, type,setRes } = props;
    const [isSuccess, setIsSuccess] = useState(false);


    const saveChanges = () => {
        setIsSuccess(prv => true);
        setTimeout(() => { setIsSuccess(prv => false); closePopup() }, 2000)
        props.setRes(2);
    }

    const payNowFunction = () => {
        setIsSuccess(prv => true);
        setTimeout(() => { setIsSuccess(prv => false); closePopup() }, 2000)
    }


    return (

        <ModalsWraper as={Modal}
            open={open}
            // onClose={HandleUpdgradePopup}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="ModalsWraper"
        >
            <ManageSubscriptionWrapper data-component="ManageSubscriptionWrapper">
                <ManageSubscriptionCard data-component="ManageSubscriptionCard">
                    <ManageSubscriptionCardHeader data-component="ManageSubscriptionCardHeader">
                        <span>Change payment details</span>
                        {!isSuccess &&
                            <button onClick={closePopup}>
                                <i dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                            </button>}
                    </ManageSubscriptionCardHeader>
                    {!isSuccess ?
                        <>
                            <ManageSubscriptionCardBody data-component="ManageSubscriptionCardBody">
                                <CartPaymentView closePopup={closePopup} payNowFunction={payNowFunction} saveChanges={saveChanges} />
                            </ManageSubscriptionCardBody>

                        </>
                        :
                        <ManageSubscriptionCardBody data-component="ManageSubscriptionCardBody">
                            <div className='success-img-div'>
                                <img className="success-img" src={!isSuccess ? '' : require('../../assets/img/profile/success.gif').default} />
                                <p>{type == 'subscription' ?
                                    'Updated Successfully'
                                    :
                                    'Payment Successfully'
                                }</p>
                            </div>
                        </ManageSubscriptionCardBody>
                    }
                </ManageSubscriptionCard>
            </ManageSubscriptionWrapper>
        </ModalsWraper>
    )
}


/** imported components

  * serviceProvider for ===> working with api.
  * CartContext for ==> add item into the cart.
  * React Stripe for ==> for Stripe is a payment service.

**/

// Billing data from local storage
const billingData = JSON.parse(localStorage.getItem("billingData"));
// style
const ELEMENT_OPTIONS = {
    style: {
        base: {
            lineHeight: "45px",
            padding: "17px",
            fontWeight: "normal",
            fontSize: "15px",
            color: "#4d4d4f",
            outline: "0",
            backgroundColor: "#ffffff",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#9e2146",
        },
    },
    showIcon: true,
    iconStyle: "solid",
    iconPosition: "right",
};
//
const cardEv = () => (event) => {
    console.log(event.brand);
};
// Function for Check out
const CheckoutForm = (props) => {
    const elements = useElements();
    const stripe = useStripe();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [name, setName] = useState("");
    const [db, setDb] = useState(true);

    const [postal, setPostal] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const { cartItems, clearCart } = useContext(CartContext);
    const {
        total,
        discount,
        discountPrice,
        itemCount,
        increase,
        decrease,
        removeProduct,
    } = useContext(CartContext);
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        setDb(false);
        const cardElement = elements.getElement(CardNumberElement);
        const res = await stripe.createToken(cardElement);
        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
                name,
                address: {
                    postal_code: postal,
                },
            },
        });

        if (payload.error) {

            if (
                payload.error.message ==
                "You passed an empty string for 'billing_details[name]'. We assume empty values are an attempt to unset a parameter; however 'billing_details[name]' cannot be unset. You should remove 'billing_details[name]' from your request or supply a non-empty value."
            ) {
                setErrorMessage("Please Enter Name on Card.");
                setPaymentMethod(null);
                setDb(true);
            } else {
                setErrorMessage(payload.error.message);
                setPaymentMethod(null);
                setDb(true);
            }
        } else {
           
            
            serviceProvider
                .post(
                    "/cart?type=updatecard",
                    JSON.stringify({
                        user_id: userData.uid,
                        tkp: res.token.id,
                        res: res,
                        email: userData.email,
                        payload: payload.paymentMethod.id,
                    }),
                    true
                )
                .then((response) => {
                    if (response.data.status === 200) {
                        clearCart();
                        props.saveChanges();
                    }
                    if (response.data.status === "failed") {
                        setDb(false);
                        setErrorMessage('Your card seems invalid or something went wrong. Please check with your bank or use another card.');
                    }
                });
            //setPaymentMethod(payload.paymentMethod);

        }
    };

    return (
        <form onSubmit={handleSubmit} className="payment__form">
            {/* Card detail, Billing Info form start */}
            <div className="form__ctrl">
                <label>
                    <span className="form__label">Name on Card *</span>

                    <input
                        id="name"
                        name="name"
                        required
                        placeholder="Jenny Rosen"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                    <input id="postal" name="postal" type="hidden" value="" />
                </label>
                <div className="error__msg"></div>
            </div>
            {/* Payment details form start */}
            <div className="form__ctrl custom_form_ctrl">
                <label>
                    <span className="form__label">Card Number *</span>
                    <CardNumberElement
                        id="cardNumber"
                        onBlur={logEvent("blur")}
                        onChange={cardEv("change")}
                        onFocus={logEvent("focus")}
                        onReady={logEvent("ready")}
                        options={ELEMENT_OPTIONS}
                    />
                </label>
            </div>
            {/* Expiry & CVV form */}
            <div className="form__group__wrapper">
                <div className="form__group__item">
                    <div className="form__ctrl custom_form_ctrl">
                        <label>
                            <span className="form__label">Expiry Date *</span>
                            <CardExpiryElement
                                id="expiry"
                                onBlur={logEvent("blur")}
                                onChange={logEvent("change")}
                                onFocus={logEvent("focus")}
                                onReady={logEvent("ready")}
                                options={ELEMENT_OPTIONS}
                            />
                        </label>
                    </div>
                </div>
                <div className="form__group__item">
                    <div className="form__ctrl custom_form_ctrl">
                        <label>
                            <span className="form__label">CVC / CVV *</span>
                            <CardCvcElement
                                id="cvc"
                                onBlur={logEvent("blur")}
                                onChange={logEvent("change")}
                                onFocus={logEvent("focus")}
                                onReady={logEvent("ready")}
                                options={ELEMENT_OPTIONS}
                            />
                        </label>
                    </div>
                </div>
            </div>
            {/* Pay now button */}
            {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
            {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>}
           
            <div className="btn__next__wrapper ManageSubscriptionCardFooter">
                <button type="button" className="btn__next LightBgBtn" onClick={props.closePopup}>
                    Back
                </button>
                <button type="submit" className="btn__next BlueBgBtn" disabled={!stripe || !db}>
                   Save Changes
                </button>
            </div>
        </form>
    );
};
// funtion for Stripe
const stripePromise = loadStripe("pk_test_yYhf8Q955oL7kPFJCfV3kHXb00PZXm1ZDK");
// CartPaymentView start
const CartPaymentView = (props) => {
    // define variables
    const { cartItems } = useContext(CartContext);
    const {
        total,
        discount,
        discountPrice,
        itemCount,
        increase,
        decrease,
        removeProduct,
    } = useContext(CartContext);

    const [totalPaid, setTotalPaid] = useState(0);
    const user1 = JSON.parse(localStorage.getItem("userData"));
    const usid = user1 ? user1.uid : 0;

    let handleCombo = (combo_data) => {
        let filterArray = [];
        let tmpComboIdArr = [];
        let finalArray = [];
        combo_data.forEach(element => {
            if (element.combo_id !== 0) {
                filterArray.push(element);
                if (!tmpComboIdArr.includes(element.combo_id)) {
                    tmpComboIdArr.push(element.combo_id);
                }
            }
        });
        tmpComboIdArr.forEach((item) => {
            let tmpArr = filterArray.filter(i => item == i.combo_id);
            if (tmpArr.length > 0) {
                finalArray.push(tmpArr);
            }
        });

        return finalArray;
    }

    
    useEffect(() => {
        const search = async () => {
            const apiUrl = `/home?param=paiditem&uid=${usid}`;
            const { data } = await serviceProvider.get(apiUrl, true, {});
            setTotalPaid(data);
        };
        search();
    }, []);
    return (
        <Elements stripe={stripePromise}>
            <div className="cart__page__wrapper">
                <CheckoutForm closePopup={props.closePopup} saveChanges={props.saveChanges} payNowFunction={props.payNowFunction} />
            </div>{" "}
        </Elements>
    );
};
