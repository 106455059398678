/** imported components
  * Modal for ===> component from material ui to work as popup.
  * serviceProvider for ===> working with api
  * useState ===> React hooks (useState is a way to get/set value)

**/

import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import serviceProvider from '../../services/axios'
import { Link } from "react-router-dom";
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext } from "pure-react-carousel";
import './skill-assessment-walkthrough.scss'
//AdminWalkthroughView Component start
export const AssessmentWalkthroughview = (props) => {
	// Defining variables here
	const user = JSON.parse(localStorage.getItem("userData"));
	const name = user ? user.name : "";
	const uid = user ? user.uid : "";
	const [isModal, setIsModal] = useState(true);
	const [slideIndex, setSlideIndex] = useState(0);
	const [walkthroughOpen, setWalkthroughOpen] = useState(true); 
	const TotalSlides = 2;
	// Array variable for walkthrough cards
	const slideData =
	 [
		{
			heading: `Hi ${name}`,
			text: [
				`Welcome to the Skills Assessment.`,
				`We want to quickly show you around. You can skip at any time.`,
			],
		},
		{
			heading: "Select an Assessment",
			text: [`Please note that you can't access the assessments of trainings you have started.`,
							`Click on the arrow to start an Assessment.`,
					],
		},
		
	];
	// Function to handle modal
	const HandleModal = () => {
		setIsModal(!isModal);
		setSlideIndex(0);
	};
	//Function for sliding walkthrough on the basis of index value
	const HandleSlideIndex = (ev) => {
		if (ev === "min" && slideIndex > 0) {
			let tmpVal = slideIndex - 1;
			setSlideIndex(tmpVal);
		} else if (!ev && slideIndex < TotalSlides - 1) {
			let tmpVal = slideIndex + 1;
			setSlideIndex(tmpVal);
		}
	};
	// Function to close walkthrough.
	const handelCloseWalkThrough = () =>{
		props.setIsAsstWalktrough(0)
		setIsModal(false);
		 document.querySelector('.training-main-wrapper')?.classList.remove('quiz-overlay');
		document.querySelectorAll('.z-index9999').forEach((item)=>{
			item?.classList.remove('z-index9999');
		})
		document.querySelectorAll('.visable-button').forEach((item)=>{
			item?.classList.remove('visable-button');
		})		
		serviceProvider.post("/home?type=skill_assessment1", JSON.stringify({ uid: uid }), true).then((response) => { });
	}
	// useEffect
	React.useEffect(()=>{
		 document.querySelector('.training-main-wrapper')?.classList.add('quiz-overlay');
			if(slideIndex==1){
				document.querySelector('.all-sellection-card .angle-box')?.classList.add('z-index9999');
			}else{
				document.querySelector('.all-sellection-card .angle-box')?.classList.remove('z-index9999');
			}
	},[slideIndex])

	// cleanup useEffect
	React.useEffect(()=>{
			return ()=>{
				 document.querySelector('.training-main-wrapper')?.classList.remove('quiz-overlay');
				setIsModal(false);
				document.querySelectorAll('.z-index9999').forEach((item)=>{
					item?.classList.remove('z-index9999');
				})
				document.querySelectorAll('.visable-button').forEach((item)=>{
					item?.classList.remove('visable-button');
				})		
			}

	},[])

	
	return (
		// Wlakthrough modal start
		<Modal open={isModal} className={`skill-asst-modal`}>
			{walkthroughOpen?
			<div className="skill-asst-walkthrough">
				{/* <div className="bg-admin">
                                        <img src={require(`../../assets/img/admin-walkthrough/admin-bg-${slideIndex}.svg`).default} alt={`admin-bg-${slideIndex}`} />
                                </div> */} 
				<div className={`carousel-wrapper ${slideIndex === 3 && " active"}`}>
					{/* Close button that will close the walkthrough */}
					<button className="modal-close" onClick={()=>{handelCloseWalkThrough()}}>
						<img src={require("../../assets/img/admin-walkthrough/close.svg").default} alt="X" />
					</button>
				    {/* walkthrough wrapper divided in two parts */}
					<div className="slide-item">
						 {/* left frame is containing gif image related to walkthrough step. */}
						<div className="left-frame">
							<img src={require(`../../assets/img/admin-walkthrough/slider-img${slideIndex?3:0}.gif`).default} alt="slide1" />
						</div>
						{/* Right frame is containing detailed information of walkthrough */}
						<div className="right-frame">
							{/* Page number info */}
							<div className="slide-head">{slideIndex + 1} of {slideData.length}</div>
							{/* This section has heading and content for walkthrough */}
							<div className="slide-content">
								<h2 className="slide-h1">{slideData[slideIndex].heading}</h2> 
								{slideData[slideIndex].text.map((item, index) => (
									<p className="slide-p" key={index}>
										{item}
									</p>
								))}
							</div>
							{/* Walkthrough footer that is containing dots and button to slide walkthrough from one to next and next to previous */}
							<div className="slide-footer">
								<div className="dots-div">
									<button
										className="dot"
										onClick={() => {
											setSlideIndex(0);
											
										}}
										disabled={slideIndex === 0 && true}
									></button>
									<button
										onClick={() => {
											setSlideIndex(1);
											
										}}
										className="dot"
										disabled={slideIndex === 1 && true}
									></button>
								</div>
								<div className="button-div">
									{/* previous btn condition */}
									{slideIndex === 0 && <button onClick={()=>{handelCloseWalkThrough()}}>No thanks</button>}
									{slideIndex > 0 && slideIndex < slideData.length-1 && <button onClick={() => HandleSlideIndex("min")}>Previous</button>}
									{slideIndex === slideData.length-1 && (
										<button
											onClick={() => {
												setSlideIndex(0);
												
											}}
										>
											Restart
										</button>
									)}
									{/* next btn condition */}
									{slideIndex === 0 && (
										<button className="blue" onClick={() => HandleSlideIndex()}>
											Let’s go
										</button>
									)}
									
									{slideIndex === slideData.length-1  && (
										<button onClick={()=>{handelCloseWalkThrough()}} className="blue">
											Close
										</button>
									)}
								</div>
							</div>
						</div>
					</div>

					{/* slide 1 end */}
				</div>
			</div>
			:null}
		</Modal>
	);
};
