import styled from "styled-components";

export const ContactPopupWrapper = styled.div`
    background: rgba(125, 134, 140, 0.30);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    @media(max-width:740px){
        z-index:999;
    }
`
export const ContactPopupCard = styled.div`
    border-radius: 2.4rem;
    border: 0.1rem solid #CCD6E8;
    background: #FFF;
    display: flex;
    flex-direction: column;
    min-height: 49.7rem;
    width: 110.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    @media(max-width: 991px){
        height: calc(100% - 100px);
        width: calc(100% - 30px);
    }
    @media(max-width: 740px){
        border-radius: 0;
        height: calc(100% - 50px);
        width: 100%;
        left: 0;
        bottom: 0;
        top: auto;
        transform: unset;
    }
`
export const ContactPopupHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid #CCD6E8;
    padding: 1.9rem 2.4rem 2.4rem 2.4rem;
    @media(max-width: 740px){
        border-bottom: 0;
        padding: 16px;
    }
    .contact{
        color: #2C55A0;
        display: inline-block;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.8rem;
        @media(max-width: 740px){
            color: #4D4D4F;
            font-size: 28px;
            font-weight: 700;
            line-height: 38px; /* 135.714% */
        }
    }
    .contact-close-btn{
        display: inline-block;
        height: 3.2rem;
        width: 3.2rem;
        i{
            display: inline-block;
            height: 100%;
            width: 100%;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
`
export const ContactPopupBody = styled.div`
  overflow-y: auto;
  padding: 3.2rem 2.4rem 2.4rem 2.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 740px) {
    padding: 16px;
  }
  .how-can-hlep-txt{
    color: #4D4D4F;
    font-size: 1.4rem;
    font-weight: 500;
    margin:1.2rem 0rem;
  }
  h2 {
    color: #4d4d4f;
    font-size: 2.2rem;
    margin-bottom: 1.6rem;
    font-weight: 600;
    line-height: normal;
    @media (max-width: 740px) {
      font-size: 20px;
      margin-bottom: 16px;
    }
  }
  textarea {
    //height: 208px;
    height: auto;
    flex: 1;
    width: 100%;
    border-radius: 0.8rem;
    border: 0.1rem solid #ccd6e8;
    background: #fff;
    padding: 1.6rem;
    overflow-y: auto;
    color: #7d868c;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Inter";
    line-height: normal;
    resize: none;
    @media (max-width: 740px) {
      //height: 740px;
    }
    &:focus-visible {
      outline: 0;
    }
  }
`;
export const ContactPopupFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 2.4rem 2.4rem 2.4rem;
    @media(max-width: 740px){
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 16px;
    }
    .submit-btn{
        color: #FFF;
        font-size: 2rem;
        font-weight: 500;
        line-height: normal;
        display: inline-block;
        padding: 1.2rem 2.4rem;
        border-radius: 0.4rem;
        background: #CBCFD2;
        pointer-events: none;
        @media(max-width: 740px){
            font-size: 16px;
            font-weight: 600;
            padding: 12px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        &.active{
            background: #118acb;
            color: #ffffff;
            pointer-events: auto;
        }
    }
    
`
export const LeftFooter = styled.div`
    @media(max-width: 740px){
        width: 100%;
    }
    .special-label{
        color: #4D4D4F;
        display: block;
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 1.2rem;
        line-height: normal;
        position: static;
        padding-left: 0;
        background: transparent !important;
    }
    .contact__phone__input{
        padding: 12px 14px 12px 58px;
        width: 100%;
        background: #fafcfd;
        border: 0.1rem solid #CCD6E8;
        box-sizing: border-box;
        border-radius: 0.5rem;
        font-size: 1.5rem;
        color: #4d4d4f;
        outline: 0;
        -webkit-appearance: none;
        background: transparent;
    }
    .react-tel-input .selected-flag .flag{
        margin: 0;
    }
    .react-tel-input .form-control:hover{
        border-color: #CCD6E8;
    }
    .error{
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: right;
        letter-spacing: -0.01em;
        color: #fc6444;
        text-align: right;
        padding-top: 0.3rem;
        visibility: hidden;
    }
    .react-tel-input .country-list{
        bottom: 40px;
    }
`
export const ThankYouPop = styled.div`
    padding: 6rem 2.4rem 5.6rem 2.4rem;
    @media(max-width: 740px){
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 50px 16px 16px 16px;
    }
`
export const TopSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    padding-bottom: 2.5rem;
    @media(max-width: 740px){
        flex: 1;
    }
    img{
        display: block;
        height: 16rem;
        width: 16rem;
    }
    .bold-text{
        color: #4D4D4F;
        text-align: center;
        font-size: 2.2rem;
        font-weight: 600;
        line-height: normal;
    }
    .normal-text{
        color: #4D4D4F;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: normal;
    }
`
export const BottomSection = styled.div`
    display: flex;
    justify-content: flex-end;
    .ok-btn{
        color: #FFF;
        font-size: 2rem;
        display: block;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.4rem;
        background: #118ACB;
        padding: 1.2rem 2.4rem;
        @media(max-width: 740px){
            font-size: 16px;
            font-weight: 600;
            padding: 12px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
`