/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * TrainingsView ===> custom view component.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * .scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { Redirect } from "react-router-dom";
import { TrainingsView } from "./trainings-view";
import { QuickStart } from "../quickstart/quickstart-ctrl";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import "./trainings.scss";
// TrainingsControler component start
export const TrainingsControler = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const gid = user ? user.group_id : 0;
	const progress = user ? user.progress : 0;
	const plant_slug = user ? user.plant_slug : 0;
	if (progress < 100 && (gid == 14 || gid == 2)) {
		  return <Redirect to={`/assessment/${plant_slug}`} />;
	}
	useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
			const apiUrl = `/item?param=training&id=0&gid=${gid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, []);

	return (
		<>
			{/* Page title */}
			<Helmet>
				<title>IR4.0 Training Courses | SmarterChains</title>
				<meta name="description" content="IR4.0 Training Courses" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			{/* Braedcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/trainings">IR4.0 Training Courses</BreadcrumbsItem>
			<TrainingsView trainingData={results} {...props} />
			
		</>
	);
};
