import styled from "styled-components";


export const MainWrapper = styled.div`
    padding:3rem 3rem 0 3rem;
    width: 100%; 
    @media(max-width: 740px){
        padding: 15px;
    }  
`;
export const ContentWrapper = styled.div`
  display: flex;
  gap: 7rem;
  align-items: flex-start;
  @media (max-width: 740px) {
    gap: 30px;
    flex-direction: column;
  }
  .bold-text {
    min-width: 22rem;
    color: #0d2c54;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: normal;
  }
  .dot-svg {
    display: inline-flex;
    margin-right: 0.8rem;
    position: relative;
    padding-left: 0.6rem;
    &:before {
      content: "";
      height: 0.6rem;
      width: 0.6rem;
      background: #4d4d4f;
      display: block;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: -0.3rem;
    }
    svg {
      display: none;
    }
  }
`;
export const LeftWrapper = styled.div`
    width: 70%;
    @media(max-width: 740px){
        width: 100%;
    }
`
export const RightWrapper = styled.div`
    height:calc(100vh - 10rem);
    width: 30%;
    position: relative;
    @media(max-width: 740px){
        height: max-content;
        width: 100%;
    }
`
export const RightSectionWrapper = styled.div`
    width: 100%;
    max-width: 30%;
    height: inherit;
    display:flex;
    flex-direction:column;
    position: fixed;
    bottom:1px;
    right: 3rem;
    border:0.1rem solid #CBCFD2;
    border-top-left-radius:0.5rem;
    border-top-right-radius:0.5rem;
    box-shadow:0px 4px 8px rgba(36, 37, 39, 0.1);
    overflow:hidden;
    @media(max-width: 740px){
        position: static;
        max-width: 100%;
    }
`
export const TopWrapper = styled.div`
    border-bottom: 0.1rem solid #D3E6FF;
    .top-row{
        display: flex;
        align-items: center;
        gap: 1.6rem;
        padding-bottom: 1.6rem;
        .normal-text{
            color: #4D4D4F;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 120%;
        }
    }
`
export const CostWrapper = styled.div`
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #D3E6FF;  
    .bold-text{
        padding-bottom: 0.8rem;
    }  
    .cost-text{
        color: #4D4D4F;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 0.8rem;
    }
    .cost-row{
        display: flex;
        align-items: center;
        padding: 0.4rem 0;
        border-bottom: 0.1rem solid #CBCFD2;
        max-width: 33.6rem;
        @media(max-width: 740px){
            width: 100%;
        }
        &:last-child{
            border: 0;
        }
        .p-bold{
            display: flex;
            align-items: center;
            border-right: 0.06rem solid #CBCFD2;
            flex: 1;
            .p-text{
                width: auto;
                color: #4D4D4F;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: normal;
                display: inline-block;
                padding-right: 0.8rem;
            }
        }
        .p-normal{
            color: #4D4D4F;
            display: inline-block;
            font-size: 1.4rem;
            font-weight: 400;
            padding-left: 0.8rem;
            line-height: 1.2rem; /* 85.714% */
            min-width: 4.5rem;
        }
    }
`
export const KPIWrapper = styled.div`
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #D3E6FF;
    .bold-text{
        padding-bottom: 0.8rem;
    }
    .kpi-list{
        max-width: 33.6rem;
        li{
            display: flex;
            align-items: center;
            padding: 0.4rem;
            border-bottom: 0.1rem solid #CBCFD2;
            span{
                color: #4D4D4F;
                dispaly: inline-block;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: normal;
            }
            &:last-child{
                border: 0;
            }
        }
    }
`
export const PlanWrapper = styled.div`
    padding: 1.6rem 0;
    border-bottom: 0.1rem solid #D3E6FF;
    .bold-text{
        padding-bottom: 0.8rem;
    }
    .normal-text{
        color: #4D4D4F;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: normal;
    }
`
export const PointWrapper = styled.div`
    padding: 1.6rem 0;
    .bold-text{
        padding-bottom: 0.8rem;
    }
    .point-row{
        display: flex;
        align-items: center;
        padding: 0.4rem 0;
        border-bottom: 0.1rem solid #CBCFD2;
        &:last-child{
            border: 0;
        }
        .p-bold{
            display: flex;
            align-items: center;
            border-right: 0.06rem solid #CBCFD2;
            .p-text{
                min-width: 20rem;
                color: #4D4D4F;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: normal;
                display: inline-block;
                padding-right: 0.8rem;
                @media(max-width: 740px){
                    min-width: 175px;
                }
            }
        }
        .p-normal{
            color: #4D4D4F;
            display: inline-block;
            font-size: 1.4rem;
            font-weight: 400;
            padding-left: 0.8rem;
            line-height: 1.2rem; /* 85.714% */
            @media(max-width: 740px){
                line-height: 1.6rem;
                word-break: break-all;
            }
        }
    }
`
export const CompanyRightHeader = styled.div`
      padding:0px 1.6rem;
      
       .header-h1{
            color:#0D2C54;
            font-size: 1.4rem;
            font-weight: 600;
            border-bottom:0.06rem solid #0D2C54;
            padding:1.6rem 0px;
       }
`;
export const CompanyRightBody = styled.div`
      flex:1;
      overflow-y:auto;
      padding:0rem 1.6rem;
`;

export const CompanyRightCard = styled.div`
    padding:1.3rem 0rem;
    border-bottom:0.06rem solid #0D2C54;
    &:last-child{
        border: 0;
    }
      
    .card-h1{
        color:#455A64;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom:0.5rem;
        display:flex;
        align-items: baseline;
        gap:0.5rem;
        .card-title{
            display:block;
            flex:1;
        }
        .band-wrapper{   
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 1rem;
        }
        .status-wrapper{   
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .status{
                text-transform: uppercase;
                min-height: 2.2rem;
                display: inline-flex;
                align-items: center;
                padding: 0.2rem 1rem;
                background: #ffffff;
                border: 0.1rem solid transparent;
                box-sizing: border-box;
                border-radius: 2.5rem;
                font-weight: 600;
                font-size: 1.1rem;
                line-height: 1.5rem;
                letter-spacing: 0.02em;
                &.inprogress{
                    color: #F58B00;
                    border-color: #F7B885;
                }
                &.todo{
                    color: #DB324D;
                    border-color: #F1B1BB;
                }
                &.validated{
                    color: #4CAF50;
                    border-color: rgba(76, 175, 80, 0.60);
                }
            }
        }
    }
    .card-h2{
        color:#455A64;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom:1rem;
    }
    .card-h3{
        color:#455A64;
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom:1rem;
    }
    .card-description{
        color: #546E7A;
        font-size: 1.2rem;
        font-weight: 400;
        padding:0.8rem;
        border:0.06rem solid #CBCFD2;
        border-radius:0.4rem;
    }
    .view-btn{
        color: #118ACB;
        font-size: 1.2rem;
        font-weight: 400;
        padding:0.5rem 1rem;
        background:#E5F0FF;
        border-radius:0.2rem;
        text-align: center;
        margin-left: auto;
    }
`
export const BlankSvgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5rem;
    flex: 1;
    gap: 4rem;
    i{
        display: inline-block;
        height: 16rem;
        width: 21.6rem;
        background: transparent;
        &:before{
            content: unset;
        }
        svg{
            display: block;
            height: 100%;
            width: 100%;
        }
    }
    span{
        display: block;
        color: #000;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.2rem; /* 85.714% */
    }
`
// Manufactouring Scss
export const ManuFacturingHeader = styled.div`
    padding: 1.6rem;
    .header{
        color: #0D2C54;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.8rem;
    }
`

export const AssignedUser = styled.div`
   display:flex;
   align-items:center;
    i{
        display:inline-flex;
        position:relative;
        align-items: center;
        justify-content:center;
        color: #FFFFFF;
        background:#118ACB;
        font-weight: 500;
        font-size: 1rem;
        line-height:2rem;
        font-style: normal;
        width:2.2rem;
        height:2.2rem;
        border-radius: 50%;
        text-transform:uppercase;
        overflow: hidden;
        .taskcenter_assigned__img_cir{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &.null{
            background-color: #f0f0f0;
             color: #7D868C;
             border: 0.1rem dashed #7D868C;
        }
    }
    span{
        padding-left:1rem;
        width:calc(100% - 2rem);
    }
`
export const ManuFacturingBody = styled(CompanyRightBody)`
    padding: 0 1.6rem;
    @media(max-width: 740px){
        padding: 0 10px;
    }
   
    .main-row{
        display: flex;
        align-items: center;
        padding-bottom: 1.2rem;
        gap: 0.8rem;
        border-bottom: 0.1rem solid #7D868C;
        span{
            color: #455A64;
            display: inline-block;
            font-size: 1.2rem;
            font-weight: 600;
        }
        .main-col{
            flex: 1;
        }
        .status-col{
            width: 7rem;
        }
        .assign-col{
            width: 9.7rem;
        }
        &.sm-hide{
            @media(max-width: 740px){
                display: none;
            }
        }
    }
    .bottom-row{
        display: flex;
        align-items: center;
        padding: 1.2rem 0;
        border-top: 0.1rem solid #E6E6E6;
        gap: 1rem;
        @media(max-width: 740px){
            gap: 5px;
            flex-wrap: wrap;
        }
        &:first-child{
            border-bottom: 0;
        }
        .main-col{
            flex: 1;
            span{
                color: #455A64;
                display: inline-block;
                font-size: 1.2rem;
                font-weight: 400;
            }
            @media(max-width: 740px){
                flex: unset;
                margin-bottom: 10px;
                width: 100%;
            }
        }
        .status-col{
            width: 7rem;
            .status{
                display: inline-block;
                border-radius: 0.2rem;
                background: #DB324D;
                padding: 0.4rem 0.8rem;
                color: #FFF;
                font-size: 1rem;
                font-weight: 500;
                &.inprogress{
                    background: #F58B00;
                }
                &.completed{
                    background: #43A047;
                }
            }
            @media(max-width: 740px){
                width: calc(50% - 2.5px);
            }
        }
        .assign-col{
            width: 9.7rem;
            @media(max-width: 740px){
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: calc(50% - 2.5px);
            }
            .sm-title{
                @media(max-width: 740px){
                    width: auto;
                }
            }
            .assigned-to-cir-wrapper{
                display: inline-flex;
                position: relative;
                width: 100%;
                @media(max-width: 740px){
                    width: auto;
                }
                p{
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 150%;
                    color: #4D4D4F;
                }
                ${AssignedUser}{
                    cursor: pointer;
                    &:not(:first-child){
                        margin-left: -1.2rem;
                    }
                }
                .assigned-to-count{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 2.2rem;
                    width: 2.2rem;
                    border: 0.1rem solid #118ACB;
                    background: #ffffff;
                    font-weight: 600;
                    font-size: 1rem;
                    border-radius: 50%;
                    color: #118acb;
                    cursor: pointer;
                    margin-left: -1.2rem;
                    z-index: 1;
                }
                .tooltip{
                    color: #118acb;
                    display: inline-block;
                    position: absolute;
                    bottom: -3.5rem;
                    font-weight: 500;
                    font-size: 1.2rem;
                    white-space: nowrap;
                    pointer-events: none;
                    border: 0.06rem solid #118ACB;
                    border-radius: 0.2rem;
                    background: #ffffff;
                    z-index: 2;
                    padding: 0.4rem 0.8rem;
                    left: 50%;
                    transform: translateX(-50%);
                    visibility: hidden;
                    opacity: 0;
                    @media(max-width: 740px){
                        left: auto;
                        right: 0;
                        transform: unset;
                    }
                }
                .tooltip.active{
                    display: none;
                    opacity: 0;
                    visibility: hidden;
                }
                &:hover{
                    .tooltip{
                        opacity: 1;
                        visibility: visible;
                    }
                }
    
            }
        }
    }
    .reminder-row{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-bottom: 1.2rem;
        .reminder-btn{
            padding: 0.4rem 0.8rem;
            display: flex;
            align-items: center;
            border-radius: 0.2rem;
            justify-content: center;
            &:hover{
                background: #E5F0FF;
            }
            span{
                color: #118ACB;
                display: inline-block;
                font-size: 1rem;
                font-weight: 500;
                margin-right: 0.6rem;
                line-height: normal;
            }
            i{
                display: flex;
                margin-right: 0;
                height: 0.8rem;
                width: 0.8rem;
                svg{
                    height: 100%;
                    width: 100%;
                }
            }
            &.green{
                span{
                    color: #43A047;
                    margin-right: 0;
                }
                i{
                    margin-right: 0.6rem;
                }
                &:hover{
                    background: transparent;
                    cursor: none;
                }
            }
        }
    }
    .sm-title{
        display: none !important;
        @media(max-width: 740px){
            color: #455A64 !important;
            display: block !important;
            font-size: 1.2rem !important;
            font-weight: 600 !important;
            margin-bottom: 5px;
            width: 100%;
        }
    }
`
export const DropUserCard = styled.div`
    display: flex;
    opacity: ${({active})=>active?'1':'0'};
    background: #FFFFFF;
    box-shadow: 0px 0px 0.8rem rgba(21, 27, 38, 0.12);
    border-radius: 0.6rem;
    padding: 2rem 1.2rem;
    position: absolute;
    visibility: ${({active})=>active?'visible':'hidden'};
    z-index: 5;
    top: 3rem;
    right: 0;
    width: calc(0.06vw * 350);
    max-width: 28rem;
    @media(max-width: 740px){
        width: 200px;
    }
    &:after{
        content: "";
        position: absolute;
        bottom: 100%;
        right: 28%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
        @media(max-width: 740px){
            content: unset;
        }
    }
    .max-height-container{
        display: flex;
        width: 100%;
        max-height: 21.6rem;
        overflow: hidden;
        ${AssignedUser}{
            &:not(:first-child){
                margin-left: 0 !important;
            }
        }
    }
    .user-listwrap{
        position: relative;
        overflow-y: auto;
        width: 100%;
        z-index: 99;
        
        ${AssignedUser}{
            cursor: auto;
            margin-bottom: 2rem;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            &:last-child{
                margin: 0;
            }
            i{  
                height: 3rem !important;
                width: 3rem !important;
            }
        }
        .txt-wrap{
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex: 1;
            .head{
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                display: block;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 1.7rem;
                color: #455A64;
                white-space: nowrap;
            }
            .sub-txt{
                display: block;
                font-weight: 400;
                font-size: 1.1rem;
                line-height: 1.3rem;
                color: #455A64;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
            }
        }
    }
`

export const ReminderPopup=styled.div`
    min-height: 20rem;
    padding: 2.4rem 4.3rem 1.6rem 4.3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    border: 0.1rem solid #8CDCD2;
    border-radius: 1.6rem;
    filter: drop-shadow(0px 0px 18px rgba(77, 77, 79, 0.25));
    width: 24rem;
    z-index:999;
    .reminder-success-img{
      height: 12rem;
      width: 12rem;
      img{
        height: 100%;
        width: 100%;
      }
    }
    p{
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #0D2C54;
      text-align: center;
    }
  `

export const TabHeadWrap = styled.div`
    display: flex;
    margin-bottom: 1rem;
    padding: 2rem 0 0 1.5rem;
`

export const ManufactorTab = styled.div`
    cursor: pointer;
    background: #F0F0F0;
    border-radius: 0.4rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #0D2C54;
    letter-spacing: 0.02em;
    padding: 0.8rem 1.6rem;
    margin-right: 1.6rem;
    width: max-content;
    &.active{
        background: #2C55A0;
        color: #ffffff;
    }
`

export const ManufactorTabContent = styled.div`
    display: ${({ open }) => (open ? "flex" : "none")};
    flex-direction: column;
    overflow: hidden;
`

export const DimensionTabContent = styled.div`
   display: ${({ open }) => (open ? "flex" : "none")};
   flex-direction: column;
   overflow: hidden;
`
