/** imported components

  * makeStyles for ===> styling material-ui component.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * TicketsView for ==> custom component.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.

**/
import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { TicketsView } from "./tickets-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import "./tickets.scss";
//  TicketsController start
export const TicketsController = (props) => {
  // define variable
  const [results, setResults] = useState([]);
  const [submitAns, setSubmitAns] = useState(0);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  useEffect(() => {
    props.handleExpandFal();
    const search = async () => {
      const apiUrl = `/ticket?param=mytickets&uid=${usid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults(data);
    };
    search();
  }, [submitAns]);

  return (
    <>
      {/* Page title */}
      <Helmet>
        <title>Support Center | SmarterChains</title>
        <meta name="description" content="Support Center" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Support Center</BreadcrumbsItem>
      {/* View component start */}
      <TicketsView ticketData={results} onSubmitAns={setSubmitAns} {...props} />
    </>
  );
};
