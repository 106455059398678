/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React, { useState } from "react";
import{
  foundationSvg,
  amplificationSvg,
  accelarationSvg,
} from "./svg"
import{
  RoadmapTransformationWrapper,
  TransFormRow,
  TransFormCol,
  NumCol,
  ContentCol,
} from "./styled.js"
//Roadmap Transformation component start
export const RoadmapTransformationView = (props) => {
  const [transformData, setTransformData] = useState([
    {
      id: 1,
      title: `Foundation Stage`,
      content: `Providing the fundamental infrastructure and organizational structures required for the transformation.`,
      image: foundationSvg,
      class: `found`,
    },
    {
      id: 2,
      title: `Acceleration Stage`,
      content: `Facilitating new data acquisition systems and cloud-based solutions that provide a 360° view of the operations status.`,
      image: accelarationSvg,
      class: `accel`,
    },
    {
      id: 3,
      title: `Amplification Stage`,
      content: `Advanced analytics and automation drive data-driven decision making and reduce manual touches on the conversion process`,
      image: amplificationSvg,
      class: `ampli`,
    },
  ])
  
  return (
    <>
        <RoadmapTransformationWrapper data-component="RoadmapTransformationWrapper">
            <TransFormRow data-component="TransFormRow">
              {
                transformData.map((item,index)=>(
                  <TransFormCol data-component="TransFormCol" key={index}>
                    <div className="num-text-col">
                      <NumCol data-component="NumCol">
                        <span className="count">{index+1}</span>
                        <span className={`background-image ${item.class===`found` ?`found` : item.class===`accel`?`accel` : item.class===`ampli`?`ampli`:``}`}>
                          <i dangerouslySetInnerHTML={{ __html: item.image}}></i>
                        </span>
                      </NumCol>
                      <span className="transform-title">{item.title}</span>
                    </div>
                    <ContentCol data-component="ContentCol">
                      {item.content}
                    </ContentCol>
                  </TransFormCol>
                ))
              }
            </TransFormRow>
        </RoadmapTransformationWrapper>
    </>
  );
};

