/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api 

**/
import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Redirect, Link } from "react-router-dom";
import "./auth.scss";
import { Helmet } from "react-helmet";
import serviceProvider from "../../services/axios";

export class VerificationLinkSent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      msg: "",
      isLoading: false,
      redirect: false,
      isUpdate: false,
      errMsgEmail: "",
      errMsg: "",
    };
  }
  // function to change value onChange event 
  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

   // function to Signin form 
  onSignInHandler = () => {
    this.setState({ isLoading: true });
    serviceProvider
      .post(
        "/user?type=forgotpassword",
        JSON.stringify({
          email: this.state.email,
        })
      )
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data.status == 200) {
          // localStorage.setItem("isLoggedIn", false);
          // this.setState({
          //msg: response.data.message,
          //redirect: true,
          // });
          this.setState({ isUpdate: true });
        }
        if (
          response.data.status == "failed" &&
          response.data.success == "undefined"
        ) {
          this.setState({
            errMsgEmail: response.data.validation_error.email,
          });
          setTimeout(() => {
            this.setState({ errMsgEmail: "" });
          }, 5000);
        } else if (
          response.data.status === "failed" &&
          response.data.success === false
        ) {
          this.setState({
            errMsg: response.data.message,
          });
          setTimeout(() => {
            this.setState({ errMsg: "" });
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    const login = localStorage.getItem("isLoggedIn");
    if (login) {
      return <Redirect to="/home" />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Forgot Password | SmarterChains</title>
          <meta name="description" content="Forgot Password" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div className="auth__form--wrapp">
          {/* 
          Main container. Here we placed Sign in form with logo.
          Devide container in 2 parts left & right section.
          Logo & Hero are in left side part section.
          Sign in form fields are in right side section.
        */}
          {/* Logo + hero image */}
          <Grid item xs={12} sm={4} md={4} className="auth__form--left">
            <div className="auth__form--logo">
              <img src={require("../../assets/img/auth/sc-logo.svg").default} />
            </div>
          </Grid>
          {/* right section  */}
          <Grid item xs={12} sm={8} md={8} className="auth__form--right">
            <section>
              <div className="auth__form-sm--logo">
                <img
                  src={require("../../assets/img/auth/sc-logo.svg").default}
                />
              </div>
              <div className="wrapper sign-in">
                {/* Start email submission msg div*/}
                <div className="col-6 Signup-form forgot__pass forgot__pass__msg confirm__msg0621">
                  {/* massage send text div started */}
                  <div className="message-sent">
                    <figure>
                      <img
                        src={
                          require("../../assets/img/auth/sent-email.svg").default
                        }
                      />
                    </figure>
                    <h2>Verification link sent</h2>
                    <p>
                      We sent you a verification link to your{" "}
                      <span className="b"> email address.</span>
                      <br /> Please verify it.
                    </p>
                    <p className="mt-26">Didn’t get a verification email?</p>
                    <p>
                      Check your spam folder or{" "}
                      <Link to="" className="blue-link">
                        {" "}
                        Send again{" "}
                      </Link>
                    </p>
                  </div>
                  {/* massage send text div ended */}

                </div>
                {/* End email submission msg div*/}
              </div>
            </section>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
