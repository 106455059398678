import React from 'react';
import { Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { useEffect, useState } from "react";
import { TaskCenterView } from './TaskCenterView';
import { TaskCenterViewAssesor } from './TaskCenterViewAssesor';
export const TaskCenterController = (props) => {


	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const gid = user ? user.group_id : 0;
	const urlParams = new URLSearchParams(window.location.search);
	const [filter, setFilter] = useState(urlParams.get('filter'));
	const progress = user ? user.progress : 0;
	const plant_slug = user ? user.plant_slug : 0;
	if (progress < 100 && (gid == 14 || gid == 2)) {
		//  return <Redirect to={`/assessment/${plant_slug}`} />;
	}

	useEffect(() => {
		const search = async () => {
			const apiUrl = `/taskcenters?param=task&uid=${usid}&gid=${gid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
		};
		search();
	}, []);
	return (
		<>
			{gid == 16 ?
				<TaskCenterViewAssesor result={results} onfilter={filter} setFilter={setFilter}  {...props} />
				:
				<TaskCenterView result={results} onfilter={filter} setFilter={setFilter}  {...props} />
			}

		</>
	);
}

