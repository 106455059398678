/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect,useContext ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * Modal ===> component of material Ui for popup.
  * NotificationController ===> component call for notifications.
  * Link ===> component from react use as anchor


**/

import React, { useState, useContext, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { apiEndpoints } from '../../services/api-endpoints'
import { Collapse, Modal } from "@material-ui/core";
import { Link, Redirect, NavLink } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { NotificationController } from "./notification-ctrl";
import { connection } from '../../webutils/webutils'
import { UpgradeView } from '../upgrade-demo/UpgradeView';
import { UpgradeCouponPopupView } from '../upgrade-coupon-popup/UpgradeCouponPopupView';
import { SiriContactView } from './SiriContactView';
//HeaderView component start
export const HeaderView = (props) => {
	//Defining variables here
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const gid = user ? user.group_id : 0;
	const avatarColor = user ? user.avcolor : '';
	const progress = user ? user.progress : 0;
	const paidItem = user ? user.paidItem : 0;
	const login = localStorage.getItem("isLoggedIn");
	if (!login) {
		return <Redirect to="/sign-in" />;
	}
	
	const [navigate, setNavigate] = useState(false);
	const [isNotificationPopup, setIsNotificationPopup] = useState(false);
	const [hasNewNotification, setHasNewNotification] = useState(localStorage.getItem("noti"));
	const [showUpgradePlant, setShowUpgradePlant] = useState(false);
	const [showOfferUpgradePopup, setShowOfferUpgradePopup] = useState(false);
	const [showContactPopup, setShowContactPopup] = useState(false);
	//Function to handle notification popup
	const HandleNotificationPopup = () => {
		setIsNotificationPopup(!isNotificationPopup);
	};
	const { itemCount, clearCart } = useContext(CartContext);
	// Function to handle logout
	const onLogoutHandler = () => {
		clearCart();
		localStorage.clear();
		setNavigate(true);
	};

	const handleUpgrade = () => {
		setShowUpgradePlant(!showUpgradePlant);
	}

	const handleUpgradeOffer = () => {
		// vivek sir
		setShowOfferUpgradePopup(!showOfferUpgradePopup);
	}
	// Contact us popup

	const handleContactPopup = () => {
		setShowContactPopup(!showContactPopup);
	}

	window.onclick = (e) => {
		if (!e.target.closest(".header-icons-li.isddl")) {
			props.HandleDropdown();
		}
		if (!e.target.closest(".noti-icon-link button") && !e.target.closest(".upgrade-popup-content")) {
			setIsNotificationPopup(false);
		}
	};
	// Function for notification icon.
	const NotificationIcon = (props) => {

		return (
			<li className="header-icons-li noti-icon-link">
				<button
					className={`header-icons-link notification ${hasNewNotification > 0 && "is_has"}`}
					onClick={HandleNotificationPopup}
					alt="true"
				>
					<svg className="notification_icon" width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M3 7C3 3.68629 5.68629 1 9 1V1C12.3137 1 15 3.68629 15 7V9.57143C15 10.4987 15.3008 11.401 15.8571 12.1429L17.1277 13.8369C17.6138 14.4851 17.4717 15.3727 16.7279 15.6937C15.3892 16.2714 12.9232 17 9 17C5.07682 17 2.6108 16.2714 1.27215 15.6937C0.528272 15.3727 0.386203 14.4851 0.872314 13.8369L2.14286 12.1429C2.69924 11.401 3 10.4987 3 9.57143V7Z"
							stroke="#7D868C"
							stroke-linejoin="round"
						/>
						<mask id="path-2-inside-1" fill="white">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.53125 18.396C6.72105 19.5885 7.75411 20.5 9.00006 20.5C10.246 20.5 11.2791 19.5885 11.4689 18.396C10.7196 18.4618 9.898 18.5 9.00004 18.5C8.10209 18.5 7.28048 18.4618 6.53125 18.396Z"
							/>
						</mask>
						<path
							d="M6.53125 18.396L6.61873 17.3998L5.34227 17.2877L5.54368 18.5532L6.53125 18.396ZM11.4689 18.396L12.4564 18.5532L12.6578 17.2877L11.3814 17.3998L11.4689 18.396ZM9.00006 19.5C8.25344 19.5 7.63257 18.9535 7.51882 18.2388L5.54368 18.5532C5.80954 20.2235 7.25479 21.5 9.00006 21.5V19.5ZM10.4813 18.2388C10.3675 18.9535 9.74667 19.5 9.00006 19.5V21.5C10.7453 21.5 12.1906 20.2235 12.4564 18.5532L10.4813 18.2388ZM11.3814 17.3998C10.6621 17.463 9.86964 17.5 9.00004 17.5V19.5C9.92636 19.5 10.7772 19.4606 11.5563 19.3922L11.3814 17.3998ZM9.00004 17.5C8.13045 17.5 7.33804 17.463 6.61873 17.3998L6.44377 19.3922C7.22292 19.4606 8.07373 19.5 9.00004 19.5V17.5Z"
							fill="#7D868C"
							mask="url(#path-2-inside-1)"
						/>
					</svg>
				</button>
			</li>
		);
	};

	const check = () => {
		serviceProvider.get(`/notification?type=unread&uid=${usid}`, true, {}).then(function (response) {
			setHasNewNotification(response.data);
		});
	}

	useEffect(() => {
		if (login) {
			//setInterval(check, 45000);
		}
	}, []);

	useEffect(() => {
		let clickBody = document.querySelector('.training-content');
		clickBody.onmousedown = (e) => {
			if (!e.target.closest(".header-icons-li.isddl")) {
				props.HandleDropdown();
			}
		};
	})

	return (
		<>

			{/* Header main wrapper divided into two parts
		header-left-frame
		header-right-frame
		 */}
			<section className="header-frame-wrapper">
				{/* Left frame contains list of breadcrumbs and current page title */}
				<div className="header-left-frame">
					{/* 
				<ul className="header-page-link-ul">
					<li className="header-page-link-li">
						<Link to="/" className="header-page-link active">
							Home
						</Link>
					</li>
				</ul> */}
					<Breadcrumbs
						item={Link}
						container="ul"
						containerProps={{
							className:"header-page-link-ul"
						}}
						
						compare={(a, b) => a.weight - b.weight}
						finalItem={"b"}
						finalProps={{
							className: "active",
						}}
					/>
				</div>
				{/* end */}
				{/* Header right frame contains
				progress bar
				icons list (cart icon, notification icon, account list)
			*/}
				<div className="header-right-frame">
					<ul className="header-icons-ul">
						{props.isHeaderProgress && (
							<li className="header-icons-li">
							
							</li>
						)}
						{(gid != 16 && gid != 15) &&
							<>
								

								<li className="header-icons-li upgrade" style={{ display: "none" }}>
									<button onClick={() => handleUpgradeOffer()} className="upgrade-btn">Upgrade Now</button>
								</li>
								{props.results<99 && (
								<li className="header-icons-li upgrade" style={{ display: "none" }}>
								<button onClick={() => handleContactPopup()} className="contact-btn">Upgrade Now</button>
								</li>
								)}
								{props.results>=99 && (
								<li className="header-icons-li upgrade">
									<button onClick={() => handleContactPopup()} className="contact-btn">Upgrade Now</button>
								</li>
								)}
								<li className="header-icons-li cart-icon_link" style={{ display: 'none' }}>
									<Link to="/cart" className="header-icons-link">
										<svg className="header-cart-icon" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H3.3078C3.53092 0.5 3.72703 0.647829 3.78847 0.862322L4.94498 4.9H19C19.1544 4.9 19.3002 4.97137 19.3949 5.09336C19.4896 5.21535 19.5227 5.37426 19.4844 5.52388L17.5408 13.1239C17.4842 13.3452 17.2848 13.5 17.0564 13.5H6.74494C6.52182 13.5 6.32571 13.3522 6.26427 13.1377L2.93091 1.5H1C0.723858 1.5 0.5 1.27614 0.5 1ZM5.2314 5.9L7.12183 12.5H16.6682L18.356 5.9H5.2314Z" fill="#7D868C" />
											<path fill-rule="evenodd" clip-rule="evenodd" d="M9.10193 18C9.72325 18 10.2269 17.4963 10.2269 16.875C10.2269 16.2537 9.72325 15.75 9.10193 15.75C8.48061 15.75 7.97693 16.2537 7.97693 16.875C7.97693 17.4963 8.48061 18 9.10193 18ZM9.10193 19C10.2755 19 11.2269 18.0486 11.2269 16.875C11.2269 15.7014 10.2755 14.75 9.10193 14.75C7.92832 14.75 6.97693 15.7014 6.97693 16.875C6.97693 18.0486 7.92832 19 9.10193 19Z" fill="#7D868C" />
											<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0519 18C15.6733 18 16.1769 17.4963 16.1769 16.875C16.1769 16.2537 15.6733 15.75 15.0519 15.75C14.4306 15.75 13.9269 16.2537 13.9269 16.875C13.9269 17.4963 14.4306 18 15.0519 18ZM15.0519 19C16.2255 19 17.1769 18.0486 17.1769 16.875C17.1769 15.7014 16.2255 14.75 15.0519 14.75C13.8783 14.75 12.9269 15.7014 12.9269 16.875C12.9269 18.0486 13.8783 19 15.0519 19Z" fill="#7D868C" />
										</svg>
										<span className="header-cart-icon-count">{itemCount}</span>
									</Link>
								</li>
							</>
						}
						{/* Task center icon */}
						<li className="header-icons-li task-center-icon" style={{ display: 'none' }}>
							<NavLink to="/task-center" className={`header-icons-link ${({ isActive }) => isActive ? 'active' : ''}`} >
								<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clip-path="url(#clip0_627_28761)">
										<path class="fill-svg" d="M8.13839 22.2904C5.9533 19.4021 5.9533 15.4206 8.13839 12.5323L13.0214 17.4114L8.13839 22.2904Z" stroke="#7D868C" />
										<path class="fill-svg" d="M17.5567 17.4293L17.7016 17.2831V17.0772V9.43978C21.0358 10.1895 23.5 13.08 23.5 16.5918C23.5 20.6838 20.1842 24 16.0367 24C14.6479 24 13.3351 23.5597 12.2004 22.8314L17.5567 17.4293Z" stroke="#7D868C" />
										<path d="M9.2971 9.09907C10.7919 7.99512 12.5613 7.26182 14.4989 7.04512V14.3017L9.2971 9.09907Z" stroke="#7D868C" />
									</g>
									<defs>
										<clipPath id="clip0_627_28761">
											<rect width="18" height="18" fill="white" transform="translate(6 6.5)" />
										</clipPath>
									</defs>
								</svg>
							</NavLink>
						</li>
						<NotificationIcon />
						<li className="header-icons-li isddl">
							<button
								className="header-icons-link"
								onClick={() => {
									props.HandleDropdown(0);
								}}
							>
								{!user.profile_pic ?
									<div className={`header-profile-cir ${avatarColor}`}>
										{`${user.name && user.name[0]}${user.surname && user.surname[0]}`}
									</div>
									:
									<div className={`header-profile-cir cir-transparent`}>
										<img className='h__profile__img'

											src={`${apiEndpoints.baseUrl}/assets/user-profile/${user.profile_pic}`}
											alt={user.profile_pic}
										/>
									</div>}
							</button>

							<div className={props.dropdownIndex === 0 ? "ng-profile-dropdown active" : "ng-profile-dropdown"} dataindex={0}>
								<div className="ng-profile-card">
									<div className="ng-profile-card-content">
										<ul>
											<li>
												<Link
													to="/profile"
													onClick={() => {
														props.HandleDropdown(0);
													}}
													className="profile-card-link"
												>
													Profile Settings
												</Link>
											</li>
											{(gid != 16 && gid != 14) &&
												<li>
													<Link
														to="/purchase-history"
														onClick={() => {
															props.HandleDropdown(0);
														}}
														className="profile-card-link"
													>
														Purchase History
													</Link>
												</li>
											}
											{(gid == 2) &&
												<>

													<li>
														<Link
															to="/subscription"
															onClick={() => {
																props.HandleDropdown(0);
															}}
															className="profile-card-link"
														>
															Manage Subscription
														</Link>
													</li>


													<li>
														<Link
															to="/certificates"
															onClick={() => {
																props.HandleDropdown(0);
															}}
															className="profile-card-link"
														>
															Certificates
														</Link>
													</li>
												</>
											}
										</ul>
									</div>
									<div className="ng-profile-card-bottom">
										<ul>
											<li>
												<button className="profile-card-link" onClick={onLogoutHandler}>
													Logout
												</button>
											</li>
										</ul>
									</div>
								</div>
								{/* ng-profile-card end */}
							</div>
							{/* ng-profile-dropdown end */}
						</li>
					</ul>
				</div>
				{/* end */}
				{/* popup for notifications */}
				<Modal
					open={isNotificationPopup}
					onClose={HandleNotificationPopup}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					className="home-index-notification-popup"
				>
					<div className="upgrade-popup-content">
						<NotificationController hasNewNotification={hasNewNotification} setHasNewNotification={setHasNewNotification} />
					</div>
				</Modal>
			</section>
			{/*  header-frame-wrapper */}
			{showUpgradePlant && <UpgradeView openPop={showUpgradePlant} setOpenPop={setShowUpgradePlant} />}
			{showOfferUpgradePopup && <UpgradeCouponPopupView isMainPopup={showOfferUpgradePopup} setIsMainPopup={setShowOfferUpgradePopup} />}
			{showContactPopup && <SiriContactView isContactPopup={showContactPopup} email={user.email}  user_id={usid} first_name={user.name} last_name={user.surname} setIsContactPopup={setShowContactPopup} />}
		</>
	);
};
// Function for header progress bar
const HeaderProgressBar = (props) => {
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const groupid = (localStorage.getItem("group_id"));
	const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	const cid = getLastItem(window.location.pathname);
	const [results, setResults] = useState(0);
	const [text, setText] = useState('');
	const [ishide, setIshide] = useState(0);
	const segments = window.location.pathname.slice(1).split("/");

	const url = segments[0];
	const slug = segments[1];
	useEffect(() => {
		switch (slug) {
			case "chapter":
				serviceProvider
					.get("/assessmentmaturity?param=progress", true,

						{
							slug: segments[2],
							id: segments[3],
							uid: usid,
						},
					)
					.then(function (response) {

						setResults(response.data[0].progress);
						setText('Section Progress');
					});
				break;
			case "start":
				serviceProvider
					.get("/assessmentmaturity?param=progress", true, {

						slug: segments[2],
						sid: segments[3],
						uid: usid,
						typr: 'start',

					})
					.then(function (response) {

						if (response.data[0].is_datasubmitted == 1 || response.data[0].is_result == 1) {
							setIshide(1);
							setText('');
						} else {
							setText('Chapter Progress');
							setResults(response.data[0].progress);
						}

					});
				break;
			case "questions":
				serviceProvider
					.get("/assessmentmaturity?param=progress", true, {

						slug: segments[2],
						id: segments[3],
						uid: usid,

					})
					.then(function (response) {

						setResults(response.data[0].progress);
						setText('Section Progress');
					});
				break;
			default:
				serviceProvider
					.get("/assessmentmaturity?param=progress", true, {

						slug: slug,
						id: 0,
						uid: usid,

					})
					.then(function (response) {
						setResults(response.data[0].progress);
						setText('Overall Progress');
					});
				break;
		}
	}, [, segments[2], segments[3], slug, segments[4]])

	if (ishide == 0 && text != '') {
		return (
			<div className="header-progress">
				<p className="progress-title">{text}:</p>
				<h2>{results}%</h2>
				<div className="header-progress-bar">
					<div style={{ width: `${results}%` }}></div>
				</div>
			</div>
		);
	} else {
		return (
			<>
				<div></div>
			</>
		);

	}
};
