import styled from 'styled-components'

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(125, 134, 140, 0.3);
  z-index: 999;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 740px) {
    align-items: end;
  }
`;

export const PopupCard = styled.div`
  width: 115.2rem;
  max-width: 100%;
  background: #fff;
  height: 80rem;
  max-height: 90%;
  border-radius: 2.4rem;
  border: 1px solid #e5f0ff;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2rem;
  &.walkthrough-active{
    &:after{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      background: rgba(125,134,140,0.3);
      border-radius: 2.4rem;
      width: 100%;
      z-index: 99;
    }
  }
  @media (max-width: 740px) {
    height: calc(100% - 50px);
    bottom: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    top: auto;
    transform: unset;
  }
`;
export const PopupCardHeader = styled.div`
  border-bottom: 0.1rem solid #7db9ff;
  padding: 2.8rem 2.4rem;
  display: flex;
  align-items: center;
  position:relative;
  @media (max-width: 740px) {
    flex-wrap: wrap;
    position: relative;
    padding: 12px;
    gap: 10px;
  }
`;
export const PopupCardBody = styled.div`
  flex:1;
  overflow:auto;
  padding:2.4rem;
  position:relative;
  @media(max-width: 740px){
    padding: 12px;
  }
`
export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  @media (max-width: 740px) {
    flex-wrap: wrap;
  }
`;
export const PopupCloseButton = styled.button`
  line-height: 0;
  i {
    display: inline-block;
  }
  @media (max-width: 740px) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
export const EditFiguresButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid #118ACB;
  background:#118ACB; 
  color:#fff;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  padding:0.75rem 1.6rem;
  &.highlight-area{
    position: relative;
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: -0.4rem;
      left: -0.4rem;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border: 0.4rem solid #fff;
      border-radius: 0.4rem;
    }
  }
`
export const ResetDefaultButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid #118ACB;
  background:#E5F0FF; 
  color:#118ACB;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  padding:0.75rem 1.6rem;
  &.highlight-area{
    position: relative;
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: -0.4rem;
      left: -0.4rem;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border: 0.4rem solid #fff;
      border-radius: 0.4rem;
    }
  }
`
export const HeaderH1 = styled.div`
  color: #0d2c54;
  font-size: 1.8rem;
  font-weight: 600;
  flex: 1;
  display: flex;
  @media (max-width: 740px) {
    padding-right: 20px;
    font-size: 16px;
    flex: unset;
    flex-wrap: wrap;
  }
`;
export const QuestionsCardRow = styled.div`
  display:flex;
  width:100%;   
  &.highlight-area{
    position: relative;
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
    }
  }
`
export const QuestionsCardCol1 = styled.div`
  flex: 1;
  padding: 1.3rem 1.6rem;
  border-right: 0.1rem solid #7db9ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 740px) {
    min-width: 250px;
    width: 250px;
    flex: auto;
  }
  .ques-info-col{
    display: flex;
    gap: 1rem;
    p{
      flex: 1;
    }
    .info-btn{
      display: block;
      height: 1.6rem;
      width: 1.6rem;
      i{
        display: block;
        height: 100%;
        width: 100%;
        svg{
          height: 100%;
          width: 100%;
        }
      }
      &.active{
        i{
          svg{
            path{
              fill: #118acb;
            }
          }
        }
      }
    }
  }
  .side-pop-btn{
    display: flex;
    gap: 0.8rem; 
    padding: 0.8rem 1.6rem;
    align-items: center;
    border-radius: 0.4rem;
    background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
    &:hover{
      background: linear-gradient(92deg, #118ACB 12.22%, #0473AE 100.41%);    
    }
    i{
      display: block;
      height: 1.6rem;
      width: 1.2rem;
      svg{
        height: 100%;
        width: 100%;
      }
    }
    span{
      display: block;
      color: #FFF;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
export const QuestionsCardCol2 = styled.div`
  width: 15rem;
  padding: 1.3rem 1.4rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.highlight-area{
    position: relative;
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
    }
  }
  &:not(:last-child) {
    border-right: 0.1rem solid #7db9ff;
  }
  @media (max-width: 740px) {
    width: 100px;
  }
`;
export const QuestionsCard = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid #7db9ff;
  overflow: hidden;
  @media (max-width: 740px) {
    width: 500px;
    margin-bottom: 35px;
  }
  @media(max-width: 710px){
    margin-bottom: 20px;
  }
`;
export const QuestionsCardHeader = styled.div`
  border-radius: 0.8rem 0.8rem 0px 0px;
  border-bottom: 0.1rem solid #7DB9FF;
  background:#E5F0FF;
  color:#0D2C54;
  font-size: 1.2rem;
  font-weight: 600;
  &.highlight-area{
    position: relative;
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: -1;
    }
  }
  ${QuestionsCardRow}{
    ${QuestionsCardCol2}{
      &.highlight-area{
        &:after{
          background: #E5F0FF;
        }
      }
    }
  }
`
export const QuestionsCardBody = styled.div`
  border-radius:0px 0px 0.8rem 0.8rem;
  color:#0D2C54;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 143%;
  ${QuestionsCardRow}{
    &:not(:last-child){
      border-bottom: 0.1rem solid #CBCFD2;
    }
  }
`
export const LossInput = styled.input`
  border-radius:0px 0px 0.8rem 0.8rem;
  color:#0D2C54;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 0.4rem;
  border: 0.1rem solid #607D8B;
  background: #FBFBFB;
  width:100%;
  height:4rem;
  text-align:center;
  padding:0.5rem 1rem;
  outline:0;
`
export const ViewButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid #118acb;
  background: #118acb;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.75rem 1.6rem;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  i {
    display: inline-block;
    line-height: 0;
    @media (max-width: 740px) {
      display: none;
    }
  }
`;
export const SaveChangesButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid #118ACB;
  background:#118ACB; 
  color:#fff;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding:0.75rem 1.6rem;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  position:absolute;
  bottom:3rem;
  left:50%;
  transform:translate(-50%,0%);
  min-width: 18.5rem;
  min-height: 4.8rem;
  &[disabled] {
    opacity:0.8;
    cursor:not-allowed;
  }
  &.highlight-area{
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: -0.4rem;
      left: -0.4rem;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border: 0.4rem solid #fff;
      border-radius: 0.4rem;
    }
  }
`
export const ChangesSavedButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid #43A047;
  background:#fff; 
  color:#43A047;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding:0.75rem 2.4rem;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  position:absolute;
  bottom:3rem;
  left:50%;
  gap:0.8rem;
  transform:translate(-50%,0%);
  box-shadow: 0px 4px 4px 0px rgba(77, 77, 79, 0.25);

  i{
      line-height:0;
      display:inline-block;
  }
  &:disabled{
      pointer-events:none;
  }
    
`
export const CenteredPopupCard = styled.div`
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  bacground:#fff;
  width:63rem;
  max-width:calc(100% - 3rem);
  z-index:1;
  border-radius: 0.6rem;
  border: 0.1rem solid ${({isDelete})=> isDelete ? 'rgba(219, 50, 77, 1)':'#118ACB'};
  background: #FFF;
  box-shadow: 0px 0px 0.8rem 0px rgba(21, 27, 38, 0.12);
  padding:2.4rem;
  text-align:center;
  .centered-popup-h2{
      font-size: 1.8rem;
      font-weight: 500;
      color:#0D2C54;
      margin-bottom:1.8rem;
  }
  .centered-popup-p{
      font-size: 1.6rem;
      font-weight: 500;
      color:#4D4D4F;
  }
`
export const CenteredPopupFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 3.8rem 0rem 2rem 0rem;
  @media (max-width: 740px) {
    flex-wrap: wrap;
  }
`;
export const CenteredPopupCancelButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid ${({isDelete})=> isDelete? 'rgba(219, 50, 77, 1)':'#118ACB'};
  background:'#fff'; 
  color:${({isDelete})=> isDelete ? 'rgba(219, 50, 77, 1)':'#118ACB'};
  font-size: 1.5rem;
  font-weight: 500;
  padding:1rem 2.5rem;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  min-width:15rem;
  &:hover{
      background:${({isDelete})=> isDelete? 'rgba(250, 226, 230, 1)':'rgba(17, 138, 203, 0.1)'};
  }
`
export const CenteredPopupProceedlButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid ${({isDelete})=> isDelete? 'rgba(219, 50, 77, 1)':'#118ACB'};
  background:${({isDelete})=> isDelete? 'rgba(219, 50, 77, 1)':'#118ACB'}; 
  color:#fff;
  font-size: 1.5rem;
  font-weight: 500;
  padding:1rem 2.5rem;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  min-width:15rem;
  &:hover{
      background:background:${({isDelete})=> isDelete? 'rgba(194, 25, 52, 1)':'linear-gradient(92deg, #118ACB 12.22%, #0473AE 100.41%)'};
  }
`
// Reason code Popup Chnages
export const BackBtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  .back-btn{
      display: flex;
      align-items: center;
      border-radius: 0.4rem;
      background: #E5F0FF;
      padding: 4px 8px 4px 2px;
      i{
          display: inline-block;
          height: 2.4rem;
          width: 2.4rem;
          svg{
              height: 100%;
              width: 100%;
          }
      }
      span{
          display: inline-block;
          color: #2C55A0;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem; /* 128.571% */
      }
  }
`
export const ReasonCodePopup = styled.div`
  min-width:100%;
  @media(max-width: 740px){
    overflow-x: auto;
  }
`
export const ReasonCodePopHead = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1.6rem;
  span{
      color: #0D2C54;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
  }
  p{
      color: #0D2C54;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
  }
`
export const ReasonCodePopBody = styled.div`
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  border: 0.04rem solid #A6A7AD;
  background: #FFF;
  box-shadow: 0px 0px 0.8rem 0px rgba(21, 27, 38, 0.12);
  @media(max-width: 740px){
    width: 650px;
  }
`
export const ReasonCodeTableHead = styled.div`
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid #A6A7AD;
`
export const ReasonCodeTableRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  position:relative;
  .reason-head{
      color: #0D2C54;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2rem; /* 142.857% */
  }
`
export const ReasonCodeTableLeftCol = styled.div`
  flex: 1 1;
`
export const ReasonCodeTableMidCol = styled.div`
  text-align: center;
  width: 12.6rem;
  
  &.highlight-area{
    position: relative;
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
      display: inline-block;
      padding: 0.5rem;
      border-radius: 0.4rem;
    }
  }
.tech-desc-btn{
  color: #118ACB;
  display: block;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.05rem;
  &.active{
    color: #6464C8;
    border-bottom: 0.1rem solid #6464C8; 
  }
}
`
export const ReasonCodeTableRightCol = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 1.6rem;
  width: 16.5rem;
  &.highlight-area{
    position: relative;
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
      display: inline-block;
      padding: 0.5rem;
      border-radius: 0.4rem;
    }
  }
  .reason-head{
      text-align: center;
      width: 100%;
  }
  .view-btn{
      color: #FFF;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0.4rem 1.6rem;
      border-radius: 0.4rem;
      background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
      min-height: 3.2rem;
  }
`
export const ReasonCodeTableRightDelCol = styled.div`
  width: 10rem;
  .del-btn{
      color: #DB324D;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 0.4rem 1.6rem;
      border-radius: 0.4rem;
      border: 0.06rem solid #DB324D;
      background: #FAE2E6;
      min-height: 3.2rem;
  }
`
// export const ReasonCodeTableBody = styled.div`
//   ${ReasonCodeTableRow}{
//       border-top: 0.1rem solid #E6E6E6;
//       padding: 1.4rem 0;
//       .reason-text{
//           color: #0D2C54;
//           font-size: 1.4rem;
//           font-weight: 400;
//           flex: 1;
//           line-height: 2rem; /* 142.857% */
//       }
//       .reason-input{
//           color: #0D2C54;
//           text-align: center;
//           font-size: 1.4rem;
//           font-weight: 400;
//           line-height: normal;
//           min-height: 3.2rem;
//           width: 100%;
//       }
//       &:first-child{
//           border-top: 0;
//       }
//   }
//   .add-reason-btn{
//       color: #1487E3;
//       font-size: 1.2rem;
//       font-weight: 500;
//       line-height: 2rem; /* 166.667% */
//       margin-bottom: 1.2rem;
//       &.highlight-area{
//         position: relative;
//         background: transparent;
//         z-index: 999;
//         &:after{
//           content: '';
//           position: absolute;
//           top: -0.5rem;
//           left: -0.5rem;
//           right: 0;
//           bottom: 0;
//           width: 100%;
//           height: 100%;
//           z-index: -1;
//           display: inline-block;
//           padding: 0.5rem;
//           background: #fff;
//         }
//       }
//   }
// `
export const ReasonCodeTableBody = styled.div`
    ${ReasonCodeTableRow}{
        border-top: 0.1rem solid #E6E6E6;
        padding: 1.4rem 0;
        .reason-text{
            color: #0D2C54;
            font-size: 1.4rem;
            font-weight: 400;
            flex: 1;
            line-height: 2rem;
            display:block;
            border-width:0.1rem;
        }
        .reason-input{
            color: #0D2C54;
            text-align: center;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: normal;
            min-height: 3.2rem;
            width: 100%;
            border-width: 0.1rem;
            outline:0;
            &.error{
              border-color:#DB324D;
            }
        }
        &:first-child{
            border-top: 0;
        }
        .include-checkbox {
          display:inline-flex;
          align-items:center;
          margin-top:0.5rem;
          gap:10px;
          font-size: 1.2rem;
          margin-left: 1.5rem;
          input{
            accent-color: #7D868C;
          }
        }
    }
    .add-reason-btn{
        color: #1487E3;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 2rem; /* 166.667% */
        margin-bottom: 1.2rem;
        &.highlight-area{
          position: relative;
          background: transparent;
          z-index: 999;
          &:after{
            content: '';
            position: absolute;
            top: -0.5rem;
            left: -0.5rem;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            display: inline-block;
            padding: 0.5rem;
            background: #fff;
          }
        }
    }
`;
export const ReasonCodeTableFooter = styled.div`
  padding: 1.6rem 0;
  border-top: 0.1rem solid #A6A7AD;
  .reason-total{
      color: #0D2C54;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem; /* 142.857% */
      text-transform: uppercase;
      display: inline-block;
  }
  .reason-text{
      color: #0D2C54;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem; /* 142.857% */
      flex: 1;
  }
  .reason-input{
      color: #0D2C54;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: normal;
      min-height: 3.2rem;
      width: 100%;
      border-width: 0.1rem;
      outline:0;
      &.error{
        border-color:#DB324D;
      }
  }
`
export const AddReasonBox = styled.div`
.add-reason-input-label{
    position:relative;
    width:100%;
    display:block;
    .add-reason-count-text{
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        left:0.5rem;
        color:#A6A7AD;
        font-size: 1.4rem;
        font-weight: 400;
        line-height:0;
    }
}
.add-reason-text-input{
    width:100%;
    height:4.4rem;
    border-radius: 8px;
    border: 0.06rem solid #7DB9FF;
    padding:1rem 7rem 1rem 3rem;
    display: flex;
    align-items: center;
    outline:0;
    transition:0.25s;
    &.active{
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &::placeholder{
        color:#A6A7AD;
        font-size: 1.4rem;
        font-weight: 400;
    }
}
.save-reason-button{
    color: #FFF;
    font-size: 1.2rem;
    font-weight: 600;
    padding:0.5rem 1.6rem;
    border-radius:0.4rem;
    background:#118ACB;
    margin-bottom:2.4rem;
    position:absolute;
    right:1rem;
    top:50%;
    transform:translateY(-50%);
    &:disabled{
        cursor:not-allowed;
        background:#CBCFD2;
    }
}
`;

// Individual Code
export const IndividualQuestionsCard = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid rgb(125, 185, 255);
  overflow: hidden;
  ${QuestionsCardCol1} {
    flex: unset;
    width: 80rem;
    @media (max-width: 740px) {
      min-width: 250px;
      width: 250px;
      flex: auto;
    }
  }
  ${QuestionsCardCol2} {
    text-transform: uppercase;
  }
`;
export const IndividualQuestionCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  overflow: auto;
  ${QuestionsCardRow}{
      width: max-content;
  }
  ${QuestionsCardHeader}{
      width: max-content;
  }
`
export const IndividualPopupCardBody = styled.div`
  flex:1;
  display: flex;
  flex-direction: column;
  overflow:hidden;
  padding:2.4rem;
  position:relative;
  ${ReasonCodePopup}{
    overflow:auto;
  }
`
export const SetupDropDown = styled.div`
  .setup-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    @media (max-width: 740px) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
    }
    .text {
      color: #0d2c54;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: normal;
    }
    button {
      display: flex;
      color: #4d4d4f;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: normal;
      padding: 0.8rem;
      border-radius: 0.4rem;
      align-items: center;
      border: 0.06rem solid #a6a7ad;
      min-width: 28rem;
      min-height: 3.2rem;
      justify-content: space-between;
      background: #fff;
      position: relative;
      i {
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    ul {
      position: absolute;
      border-radius: 0.4rem;
      border: 0.06rem solid rgb(166, 167, 173);
      background: rgb(255, 255, 255);
      box-shadow: rgba(0, 0, 0, 0.12) 2px 2px 8px 0px;
      left: 0px;
      top: 4.1rem;
      width: 100%;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      z-index: 2;
      li {
        color: #4d4d4f;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: normal;
        padding: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          opacity: 0;
          visibility: hidden;
        }
        &.active {
          background: #e5f0ff;
          i {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
`;
export const SetUpCard = styled.div`
  h2{
      color: #0D2C54;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
  }
  p{
      color: #0D2C54;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2rem;
      margin-bottom: 3rem;
  }
`
export const SetUpCardRow = styled.div`
  display: flex;
  gap: 3.2rem;
  flex-direction: column;
  @media (max-width: 740px) {
    flex-wrap: wrap;
  }
`;
export const SetUpCardCol = styled.div`
  display: flex;
   
`
export const SetUpImageWrap = styled.div`
  width: 12rem;
  height: 12rem;
  border-radius: 1.6rem;
  background: #F0F0F0;
  display: flex;
  align-items: center;
  justify-content: center;
  i{
      display: inline-block;
      width: 6.5rem;
      svg{
          height: auto;
          width: 100%;
      }
  }
`
export const SetUpCardWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  .text{
    margin-top: 1.6rem;
    color: #0D2C54;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: normal;
    max-width: 22.4rem;
  }
  &:hover{
    .text{
        color: #118acb;
    }
    ${SetUpImageWrap}{
        background: #E5F0FF;
    }
  }
`
export const LossNotificationPopup = styled.div`
  position: absolute;
  top: 8.8rem;
  right: 2.4rem;
  width: 46rem;
  z-index: 9;
  @media(max-width: 740px){
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
  }
  &.active{
    @media(max-width: 740px){
      opacity: 1;
      visibility: visible;
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(125, 134, 140, 0.14);
      backdrop-filter: blur(2px);
      z-index: -1;
    }
  }
`
export const LossNotificationPopupCard = styled.div`
  border-radius: 1.6rem;
  border: 0.1rem solid #D3E6FF;
  background: url(${require(`../../assets/img/loss-assumption-img/inactive-bg.png`).default});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 1.6rem;
  @media(max-width: 740px){
    border-radius: 0;
  }
  .loss-text{
    display: block;
    color: #2C55A0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.2rem;
  }
  &.active{
    background: url(${require(`../../assets/img/loss-assumption-img/gradient-img.png`).default});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
`
export const LossNotificationPopupHead = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1.6rem;
  i{
    display: block;
    height: 4rem;
    width: 4rem;
    svg{
      height: 100%;
      width: 100%;
    }
  }
  span{
    display: block;
    color: #2C55A0;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: normal;
    flex: 1;
  }
`
export const LossNotificationPopupList = styled.div`
  margin-bottom: 3rem;
  .list-item{
    color: #2C55A0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
  }
`
export const LossNotificationPopupFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  .got-btn{
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: normal;
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
    background: #0D2C54;
    display: block;
  }
`
export const ScenarioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding-bottom: 2.4rem;
  .label{
    color: #4D4D4F;
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.8rem; /* 112.5% */
    @media(max-width: 740px){
        font-size: 14px;
    }
  }
  
`

export const ScenarioHead = styled.div`
  border-radius: 0.4rem;
  border: 0.1rem solid #CBCFD2;
  min-width: 57.6rem;
  padding: 0.9rem 1.2rem;
  @media(max-width: 768px){
    flex: 1;
    min-width: unset;
  }
  @media(max-width: 740px){
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px;
  }
  .scenario-name{
    color: #4D4D4F;
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.8rem; /* 112.5% */
    @media(max-width: 740px){
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
  }
`



export const ThingsToKeepPopupContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 10;
`
export const ThingsToKeepPopupCard = styled.div`
  background: #FFF;
  box-shadow: -0.5rem 0.5rem 1.6rem 0px rgba(196, 196, 196, 0.30);
  height: 100%;
  border-top-right-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  width: 36.4rem;
`
export const ThingsToKeepHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #E7EBF8;
  padding: 1.6rem;
  .close-btn{
    display: block;
    height: 3rem;
    width: 3rem;
    i{
      display: block;
      height: 3rem;
      width: 3rem;
      svg{
        height: 100%;
        width: 100%;
      }
    }
  }
`
export const ThingsToKeepPopBody = styled.div`
  padding: 1.6rem;
  p{
    color: #4D4D4F;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3rem;
  }
  .list{
    .list-item{
      color: #4D4D4F;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: normal;
    }
  }
`
export const ThingsPopLeftSec = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  i{
    display: block;
    height: 3rem;
    width: 2.2rem;
    svg{
      height: 100%;
      width: 100%;
    }
  }
  span{
    color: #118acb;
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.08px;
  }
`
export const TechDescPopupContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 10;
`
export const TechDescPopupCard = styled.div`
  background: #FFF;
  box-shadow: -0.5rem 0.5rem 1.6rem 0px rgba(196, 196, 196, 0.30);
  height: 100%;
  border-top-right-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;
  width: 36.4rem;
  display: flex;
  flex-direction: column;
`
export const TechDescPopBody = styled.div`
  padding: 1.6rem;
  overflow: auto;
 .tech-body-card{
  .tech-body-card{
    color: #4d4d4f;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.9rem;
    margin-bottom: 2rem;
  }
 }
  p {
    color: #4d4d4f;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.9rem;
    margin-bottom: 2rem;
  }
  ul {
    margin-bottom: 2rem;
    li {
      color: #4d4d4f;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.5rem;
      padding-bottom: 1rem;
      &:last-child {
        padding-bottom: 0;
      }
    }
    .list-item-head {
      color: #4d4d4f;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: normal;
    }
  }
  .image-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.6rem;
    i {
      display: block;
      height: 4rem;
      width: 4rem;
      svg {
        height: 100%;
        width: 100%;
      }
    }
    .text {
      display: block;
      color: #4f4f4f;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }
`;
export const TechDescHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid #E7EBF8;
  padding: 1.6rem;
  .close-btn{
    display: block;
    height: 3rem;
    width: 3rem;
    i{
      display: block;
      height: 3rem;
      width: 3rem;
      svg{
        height: 100%;
        width: 100%;
      }
    }
  }
`
export const TechDescPopLeftSec = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  i{
    display: block;
    height: 3rem;
    width: 3rem;
    svg{
      height: 100%;
      width: 100%;
    }
  }
  span{
    color: #6464C8;
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.08px;
  }
`

export const LossThemeDescPopupContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 10;
  .desc-head{
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: -0.005em;
    color: #0d2c54;
    padding-bottom: 1rem;
  }
  p{
    font-size: 1.4rem;
    letter-spacing: -0.005em;
    color: #0d2c54;
    padding-bottom: 1rem;
  }
`

// dropdown list

export const DropdownBox = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 15rem;
    transform: translateY(100%);
    z-index: 9;
    border-radius: 0px 0px 8px 8px;
    border: 0.06rem solid #7DB9FF;
    border-top: 0;
    padding:0.5rem;
    transition:0.25s;
    overflow:auto;
    opacity:${({active})=>active?1:0};
    visibility:${({active})=>active?'visible':'hidden'};
`;
export const DropdownOption = styled.button`
    font-size: 1.4rem;
    font-weight: 400;
    display:flex;
    padding:0.8rem;
    transition:0.25s;
    background:#fff;
    color:#4D4D4F;
    width:100%;
    &:hover{
      background:#E5F0FF;
    }
`;

export const InfoMsg = styled.div`
    background: #E5F0FF;
    padding:1.2rem 2.4rem;
    color: #2C55A0;
    font-size: 1.4rem;
    line-height: 150%;
`