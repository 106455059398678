/** imported components
 
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * $ from jquery ===> component from jquery to use jquery for the page.

**/
import React from "react";
import $ from "jquery";
import { useEffect } from "react";

// MultiCheckbox Component
export const MultiCheckbox = (props) => {
	//variable declaration
	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}
	const quesDataItem = props.quesData[0];
	const changeValue = (type) => {
		if (type == 1) {
			$(".chksss[data-type='0']").prop("checked", false);
		} else {
			$(".chksss[data-type='1']").prop("checked", false);
		}
	};
	const {qlength, setQlenth} = props;
	const {activeRightTab, setActiveRightTab} = props;
	// console.log(document.querySelector('.multi-chk-ans-wrap')?.offsetHeight)
	const checklist = quesDataItem.answers.map((item, index) => {
		
		var active = 0;
		if (quesDataItem.savedans.length) {
			var ansarr = quesDataItem.savedans.split(",");
			if (ansarr.indexOf(item.id) != -1) {
				active = 1;
			}
		}
		return (
			// Card inside main wrapper for check-box answer format
			<div className="check-box-card" key={index}>
				<input
					className="chk-input chksss"
					name="multibox"
					id={`chk${item.id}`}
					defaultChecked={active ? "checked" : ""}
					defaultValue={item.id}
					data-type={item.is_none}
					type="checkbox"
					onClick={() => changeValue(item.is_none)}
				/>
				<label htmlFor={`chk${item.id}`} className="card-left" style={{pointerEvents:quesDataItem.is_datasubmitted==1?'none':''}}>
					<img className="chk inactive" src={require("../../assets/img/multi-choice/non-active-chk.svg").default} alt="true" />
					<img className="chk active" src={require("../../assets/img/multi-choice/active-chk.svg").default} alt="true" />
					<p dangerouslySetInnerHTML={{ __html: item.option_name }}></p>
				</label>
				{/* This right part is conditional. */}
				{item.answer_description && (
					<div className={props.clickedCardNo === index ?  props.activeRightTab == 0 ? "card-right active" :"card-right" : "card-right"}>
						<img
							className="inactive"
							src={require("../../assets/img/multi-choice/non-active-info.svg").default}
							alt="true"
							onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)}
						/>
						<img
							src={require("../../assets/img/multi-choice/active-info.svg").default}
							onClick={() => props.HandleClickedCardAction(index, item.answer_description, item.option_name)}
							className="active"
							alt="true"
						/>
					</div>
				)}
			</div>
		);
	});

	useEffect(() => {
		
		
		let sch = document.querySelector('.multi-chk-ans-wrap')?.scrollHeight;
		let cch = document.querySelector('.multi-chk-ans-wrap')?.clientHeight;
if(sch>cch){
	// console.log("t")
		$('.multi-chk-ans-wrap').scroll(function (event) {
            var scroll = $('.multi-chk-ans-wrap').scrollTop();
			// console.log(scroll?.scrollHeight > scroll?.clientHeight)
            if (scroll > 0) {
                // $('.multi-chk-ans-wrap').addClass('fixed-top');
				// console.log("hello");
				setQlenth('active');
            } else {
				// console.log("hello1")
				setQlenth('');
                // $('.multi-chk-ans-wrap').removeClass('fixed-top');
            }
        });
	}
	}, [])
	return (
		<>
			{/* Main wrapper for multicheckboxes answer format. */}
			<div className="multi-chk-ans-wrap">{checklist}</div>
		</>
	);
};
