export const dotSvg =`<?xml version="1.0" encoding="iso-8859-1"?>
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 31.955 31.955" xml:space="preserve">
<g>
	<path style="fill:#030104;" d="M27.25,4.655C20.996-1.571,10.88-1.546,4.656,4.706C-1.571,10.96-1.548,21.076,4.705,27.3
		c6.256,6.226,16.374,6.203,22.597-0.051C33.526,20.995,33.505,10.878,27.25,4.655z"/>
	<path style="fill:#030104;" d="M13.288,23.896l-1.768,5.207c2.567,0.829,5.331,0.886,7.926,0.17l-0.665-5.416
		C17.01,24.487,15.067,24.5,13.288,23.896z M8.12,13.122l-5.645-0.859c-0.741,2.666-0.666,5.514,0.225,8.143l5.491-1.375
		C7.452,17.138,7.426,15.029,8.12,13.122z M28.763,11.333l-4.965,1.675c0.798,2.106,0.716,4.468-0.247,6.522l5.351,0.672
		C29.827,17.319,29.78,14.193,28.763,11.333z M11.394,2.883l1.018,5.528c2.027-0.954,4.356-1.05,6.442-0.288l1.583-5.137
		C17.523,1.94,14.328,1.906,11.394,2.883z"/>
	<circle style="fill:#030104;" cx="15.979" cy="15.977" r="6.117"/>
</g>
</svg>`

export const blank_svg = `<svg xmlns="http://www.w3.org/2000/svg" width="216" height="160" viewBox="0 0 216 160" fill="none">
<path d="M216 140.605H0V140.713H216V140.605Z" fill="#EBEBEB"/>
<path d="M102.385 121.449H18.9702C18.3167 121.448 17.6904 121.189 17.2288 120.73C16.7671 120.27 16.5078 119.647 16.5078 118.997V2.43071C16.5135 1.78482 16.7753 1.16725 17.2364 0.712128C17.6974 0.25701 18.3205 0.00110644 18.9702 0H102.385C103.039 0 103.667 0.258354 104.129 0.718227C104.592 1.1781 104.852 1.80182 104.852 2.45218V118.997C104.852 119.648 104.592 120.271 104.129 120.731C103.667 121.191 103.039 121.449 102.385 121.449ZM18.9702 0.085891C18.3454 0.0870286 17.7466 0.334572 17.3051 0.774187C16.8637 1.2138 16.6158 1.80957 16.6158 2.43071V118.997C16.6158 119.618 16.8637 120.214 17.3051 120.654C17.7466 121.093 18.3454 121.341 18.9702 121.342H102.385C103.01 121.341 103.61 121.094 104.052 120.654C104.494 120.215 104.743 119.619 104.744 118.997V2.43071C104.743 1.80917 104.494 1.21342 104.052 0.773925C103.61 0.334433 103.01 0.0870262 102.385 0.085891H18.9702Z" fill="#EBEBEB"/>
<path d="M195.831 121.449H112.412C111.758 121.448 111.131 121.19 110.669 120.73C110.207 120.27 109.946 119.647 109.945 118.997V2.43071C109.952 1.78443 110.215 1.16686 110.677 0.711859C111.138 0.25686 111.762 0.00109437 112.412 0H195.831C196.48 0.00223509 197.102 0.258638 197.562 0.713635C198.022 1.16863 198.284 1.78556 198.289 2.43071V118.997C198.289 119.646 198.031 120.269 197.57 120.728C197.109 121.188 196.484 121.447 195.831 121.449ZM112.412 0.085891C111.787 0.0870262 111.188 0.334433 110.745 0.773925C110.303 1.21342 110.054 1.80917 110.053 2.43071V118.997C110.054 119.619 110.303 120.215 110.745 120.654C111.188 121.094 111.787 121.341 112.412 121.342H195.831C196.456 121.341 197.056 121.094 197.498 120.654C197.94 120.215 198.189 119.619 198.19 118.997V2.43071C198.189 1.80917 197.94 1.21342 197.498 0.773925C197.056 0.334433 196.456 0.0870262 195.831 0.085891H112.412Z" fill="#EBEBEB"/>
<path d="M37.0069 147.516H22.6602V147.624H37.0069V147.516Z" fill="#EBEBEB"/>
<path d="M86.586 147.086H63.2148V147.194H86.586V147.086Z" fill="#EBEBEB"/>
<path d="M45.6969 143.527H37.4414V143.635H45.6969V143.527Z" fill="#EBEBEB"/>
<path d="M194.354 144.25H187.969V144.358H194.354V144.25Z" fill="#EBEBEB"/>
<path d="M184.125 144.25H169.117V144.358H184.125V144.25Z" fill="#EBEBEB"/>
<path d="M135.123 146.059H115.488V146.167H135.123V146.059Z" fill="#EBEBEB"/>
<path d="M109.105 85.6758H110.124C110.353 85.6758 110.539 85.4912 110.539 85.2635V85.242C110.539 85.0143 110.353 84.8298 110.124 84.8298H109.105C108.876 84.8298 108.69 85.0143 108.69 85.242V85.2635C108.69 85.4912 108.876 85.6758 109.105 85.6758Z" fill="#E0E0E0"/>
<path d="M123.984 64.8493L115.344 70.8616C114.542 69.7226 114.228 68.3136 114.471 66.9444C114.714 65.5753 115.494 64.3582 116.64 63.5609C117.786 62.7636 119.203 62.4515 120.58 62.6931C121.958 62.9347 123.182 63.7103 123.984 64.8493Z" fill="#E6E6E6"/>
<path d="M113.633 63.9322L115.996 67.269L120.346 64.2242L117.983 60.8873C117.577 60.3141 116.958 59.9246 116.263 59.8046C115.568 59.6847 114.854 59.8439 114.277 60.2474C113.7 60.6514 113.308 61.2664 113.188 61.9573C113.067 62.6483 113.227 63.3586 113.633 63.9322Z" fill="#E6E6E6"/>
<path d="M107.124 85.7988L108.047 86.0586L115.344 60.4397L114.421 60.18L107.124 85.7988Z" fill="#E6E6E6"/>
<path d="M113.613 58.9727C113.328 59.1321 113.097 59.3718 112.949 59.6617C112.801 59.9516 112.742 60.2786 112.781 60.6015C112.82 60.9243 112.954 61.2285 113.167 61.4756C113.379 61.7227 113.661 61.9016 113.976 61.9898C114.29 62.0779 114.624 62.0713 114.936 61.9708C115.247 61.8703 115.521 61.6804 115.723 61.4251C115.926 61.1698 116.048 60.8606 116.073 60.5364C116.099 60.2123 116.028 59.8878 115.868 59.604C115.762 59.4152 115.62 59.249 115.449 59.1151C115.278 58.9811 115.082 58.8819 114.873 58.8233C114.663 58.7647 114.444 58.7477 114.228 58.7733C114.012 58.799 113.803 58.8667 113.613 58.9727Z" fill="#E6E6E6"/>
<path d="M103.426 85.2461H111.228L111.837 88.3253H102.812L103.426 85.2461Z" fill="#E6E6E6"/>
<path d="M29.2945 96.0195L120.516 96.0195V88.3194L29.2945 88.3194V96.0195Z" fill="#EBEBEB"/>
<path d="M30.3915 95.1328H63.9492V89.2149H30.3915V95.1328Z" fill="#F5F5F5"/>
<path d="M65.1415 95.1328H98.6992V89.2149H65.1415V95.1328Z" fill="#F5F5F5"/>
<path d="M101.998 140.601H104.012L99.7045 96.0195H97.6914L101.998 140.601Z" fill="#F5F5F5"/>
<path d="M119.741 140.601H121.758L117.451 96.0195H115.434L119.741 140.601Z" fill="#F5F5F5"/>
<path d="M47.8061 140.601H45.793L50.0957 96.0195H52.1131L47.8061 140.601Z" fill="#F5F5F5"/>
<path d="M30.0643 140.601H28.0469L32.3539 96.0195H34.3714L30.0643 140.601Z" fill="#F5F5F5"/>
<path d="M76.5 71.8594H83.5546L86.4922 88.3246H79.4376L76.5 71.8594Z" fill="#E0E0E0"/>
<path d="M62.3275 58.1133H101.786L98.6933 81.9694H59.2344L62.3275 58.1133Z" fill="#E6E6E6"/>
<path d="M63.1923 58.6758H100.776L97.8301 81.4067H60.2461L63.1923 58.6758Z" fill="#FAFAFA"/>
<path d="M172.082 81.8086H176.328V59.6832H172.082V81.8086Z" fill="#F0F0F0"/>
<path d="M172.763 76.3867H175.645V61.3859H172.763V76.3867Z" fill="#FAFAFA"/>
<path d="M172.076 59.6836H163.004V81.809H172.076V59.6836Z" fill="#E0E0E0"/>
<path d="M166.949 81.8086H171.195V59.6832H166.949V81.8086Z" fill="#F0F0F0"/>
<path d="M167.622 76.3867H170.504V61.3859H167.622V76.3867Z" fill="#FAFAFA"/>
<path d="M166.939 59.6836H157.867V81.809H166.939V59.6836Z" fill="#E0E0E0"/>
<path d="M160.989 140.605H162.527V51.0773H160.989V140.605Z" fill="#EBEBEB"/>
<path d="M186.767 140.605H188.305V51.0773H186.767V140.605Z" fill="#EBEBEB"/>
<path d="M154.364 140.605H155.902V51.0773H154.364V140.605Z" fill="#EBEBEB"/>
<path d="M180.142 140.605H181.68V51.0773H180.142V140.605Z" fill="#EBEBEB"/>
<path d="M179.183 114.738H183.43V92.6128H179.183V114.738Z" fill="#F0F0F0"/>
<path d="M179.869 109.32H182.75V94.3195H179.869V109.32Z" fill="#FAFAFA"/>
<path d="M179.185 92.6172H170.113V114.743H179.185V92.6172Z" fill="#E0E0E0"/>
<path d="M174.05 114.738H178.297V92.6128H174.05V114.738Z" fill="#F0F0F0"/>
<path d="M174.732 109.32H177.613V94.3195H174.732V109.32Z" fill="#FAFAFA"/>
<path d="M174.045 92.6172H164.973V114.743H174.045V92.6172Z" fill="#E0E0E0"/>
<path d="M154.375 51.0781L189.125 51.0781V48.8836L154.375 48.8836V51.0781Z" fill="#F0F0F0"/>
<path d="M154.375 84H189.125V81.8055H154.375V84Z" fill="#F0F0F0"/>
<path d="M154.375 116.93H189.125V114.735H154.375V116.93Z" fill="#F0F0F0"/>
<path d="M135.275 8.19922H48.9531V51.0716H135.275V8.19922Z" fill="#EBEBEB"/>
<path d="M135.278 51.0703H135.797V8.19798H135.278V51.0703Z" fill="#E0E0E0"/>
<path d="M133.109 48.9216V10.3438L51.1071 10.3438V48.9216L133.109 48.9216Z" fill="white"/>
<path d="M51.5391 48.9216V10.3438H51.1114V48.9216H51.5391Z" fill="#E0E0E0"/>
<path d="M126.287 14.5078H112.281V34.0737H126.287V14.5078Z" fill="#F5F5F5"/>
<path d="M84.1929 14.5078H70.1875V34.0737H84.1929V14.5078Z" fill="#F5F5F5"/>
<path d="M116.599 23H102.594V42.5659H116.599V23Z" fill="#EBEBEB"/>
<path d="M97.391 23.8594H91.9219V29.2963H97.391V23.8594Z" fill="#F5F5F5"/>
<path d="M62.9847 37.9375H57.5156V43.3744H62.9847V37.9375Z" fill="#F5F5F5"/>
<path d="M85.723 31.3477H80.2539V36.7845H85.723V31.3477Z" fill="#F0F0F0"/>
<path d="M174.781 35.0563C181.002 32.4949 183.955 25.4056 181.379 19.2218C178.802 13.0381 171.671 10.1016 165.451 12.663C159.23 15.2244 156.276 22.3137 158.853 28.4974C161.429 34.6812 168.561 37.6177 174.781 35.0563Z" fill="#EBEBEB"/>
<path d="M170.11 24.2816C170.041 24.2833 169.972 24.267 169.911 24.2344L164.753 21.6018C164.702 21.5763 164.657 21.5411 164.62 21.4981C164.583 21.4551 164.555 21.4052 164.537 21.3513C164.519 21.2974 164.512 21.2405 164.517 21.184C164.522 21.1275 164.537 21.0725 164.563 21.022C164.589 20.9718 164.625 20.9271 164.669 20.8906C164.712 20.8541 164.762 20.8265 164.816 20.8093C164.871 20.7922 164.928 20.7858 164.984 20.7906C165.041 20.7954 165.096 20.8113 165.147 20.8374L169.467 23.0491L167.505 14.6232C167.486 14.5665 167.48 14.5065 167.485 14.447C167.491 14.3876 167.509 14.33 167.539 14.2779C167.568 14.2258 167.608 14.1804 167.656 14.1445C167.704 14.1087 167.759 14.0832 167.818 14.0697C167.876 14.0562 167.937 14.055 167.996 14.0661C168.055 14.0773 168.111 14.1006 168.16 14.1345C168.21 14.1684 168.252 14.2122 168.283 14.263C168.315 14.3139 168.335 14.3708 168.343 14.4299L170.503 23.7577C170.518 23.8214 170.517 23.8875 170.502 23.9511C170.487 24.0147 170.458 24.074 170.416 24.1245C170.374 24.175 170.322 24.2154 170.262 24.2427C170.202 24.2699 170.137 24.2832 170.071 24.2816H170.11Z" fill="white"/>
<path d="M107.995 160C154.254 160 191.755 157.824 191.755 155.139C191.755 152.454 154.254 150.277 107.995 150.277C61.7352 150.277 24.2344 152.454 24.2344 155.139C24.2344 157.824 61.7352 160 107.995 160Z" fill="#F5F5F5"/>
<path d="M104.41 152.212L101.188 151.349L103.11 143.73L106.333 144.594L104.41 152.212Z" fill="#FF8B7B"/>
<path d="M101.152 150.467L104.755 151.644C104.816 151.662 104.868 151.703 104.899 151.759C104.929 151.814 104.937 151.879 104.919 151.94L104.159 154.83C104.111 154.979 104.005 155.103 103.865 155.175C103.725 155.246 103.562 155.26 103.412 155.213C102.163 154.783 101.589 154.513 100.003 153.997C99.0313 153.68 96.6769 152.958 95.3291 152.52C93.9812 152.082 94.4651 150.772 95.0699 150.837C97.8044 151.133 99.3899 150.897 100.535 150.484C100.733 150.413 100.95 150.407 101.152 150.467Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M106.334 144.594L103.111 143.73L102.117 147.656L105.344 148.519L106.334 144.594Z" fill="black"/>
<path d="M114.483 66.6523C114.483 66.6523 109.904 93.9613 108.552 103.499C107.075 113.918 101.523 145.294 101.523 145.294L107.006 147.355C107.006 147.355 115.723 116.499 118.134 106.36C120.752 95.3527 126.515 66.6523 126.515 66.6523H114.483Z" fill="#33A4FF"/>
<path opacity="0.3" d="M114.483 66.6523C114.483 66.6523 109.904 93.9613 108.552 103.499C107.075 113.918 101.523 145.294 101.523 145.294L107.006 147.355C107.006 147.355 115.723 116.499 118.134 106.36C120.752 95.3527 126.515 66.6523 126.515 66.6523H114.483Z" fill="black"/>
<path opacity="0.3" d="M121.774 77.8512C119.441 83.6402 119.182 93.479 120.123 97.5072C121.553 90.8894 123.195 82.9703 124.443 76.8119C124.003 75.2315 123.186 74.3297 121.774 77.8512Z" fill="black"/>
<path d="M107.236 148.119L100.895 146.229L101.076 143.648L108.623 145.843L107.236 148.119Z" fill="#4D4D4F"/>
<path d="M99.8344 150.653C100.124 150.741 100.422 150.798 100.724 150.82C100.74 150.821 100.755 150.817 100.769 150.809C100.783 150.802 100.794 150.791 100.802 150.777C100.809 150.765 100.813 150.751 100.813 150.736C100.813 150.722 100.809 150.708 100.802 150.696C100.724 150.588 100.02 149.678 99.5795 149.704C99.5288 149.705 99.4794 149.72 99.4361 149.746C99.3929 149.772 99.3574 149.81 99.3332 149.854C99.2892 149.916 99.2625 149.988 99.2556 150.063C99.2488 150.139 99.2622 150.215 99.2944 150.283C99.4221 150.469 99.6145 150.6 99.8344 150.653ZM100.556 150.653C99.9424 150.58 99.5276 150.425 99.4283 150.223C99.4069 150.178 99.3988 150.127 99.4049 150.077C99.411 150.027 99.4311 149.979 99.4628 149.94C99.4736 149.918 99.4901 149.899 99.5106 149.885C99.5311 149.872 99.5549 149.864 99.5795 149.863C99.8171 149.845 100.262 150.309 100.556 150.644V150.653Z" fill="#33A4FF"/>
<path d="M100.708 150.813H100.747C100.759 150.81 100.771 150.805 100.781 150.796C100.79 150.788 100.798 150.778 100.803 150.766C100.803 150.727 101.196 149.834 100.976 149.417C100.947 149.363 100.907 149.317 100.857 149.281C100.808 149.245 100.751 149.221 100.69 149.211C100.626 149.192 100.557 149.196 100.494 149.221C100.431 149.246 100.379 149.291 100.345 149.349C100.159 149.671 100.414 150.491 100.673 150.787L100.708 150.813ZM100.708 149.379C100.738 149.387 100.767 149.401 100.792 149.421C100.816 149.441 100.836 149.466 100.85 149.495C100.924 149.859 100.88 150.238 100.725 150.577C100.503 150.238 100.421 149.827 100.496 149.43C100.496 149.387 100.561 149.344 100.673 149.37L100.708 149.379Z" fill="#33A4FF"/>
<path d="M120.333 41.3769C120.112 41.7592 119.935 42.0426 119.732 42.369L119.132 43.2966C118.726 43.9121 118.307 44.5148 117.875 45.1046C117.001 46.2979 116.066 47.4447 115.071 48.5402C114.052 49.6752 112.944 50.7278 111.758 51.6881C110.493 52.7305 109.092 53.5973 107.593 54.2648L107.533 54.2906L107.334 54.3636C106.953 54.4816 106.553 54.5282 106.154 54.501C105.898 54.4833 105.645 54.4387 105.398 54.3679C105.087 54.2747 104.788 54.148 104.504 53.99C104.13 53.7758 103.774 53.5302 103.442 53.2556C102.917 52.8201 102.422 52.351 101.96 51.8513C101.528 51.3789 101.117 50.9065 100.729 50.4255C100.34 49.9445 99.9769 49.4721 99.6054 48.9396C99.3173 48.5277 99.1903 48.0256 99.248 47.5273C99.3057 47.029 99.5442 46.5687 99.9188 46.2326C100.293 45.8965 100.778 45.7078 101.283 45.7018C101.787 45.6957 102.277 45.8727 102.66 46.1997L102.72 46.2555C103.118 46.5948 103.55 46.9813 103.969 47.3292C104.388 47.677 104.833 48.0335 105.23 48.3556C105.612 48.6583 106.011 48.9379 106.427 49.193C106.562 49.2764 106.707 49.3428 106.859 49.3905C106.885 49.3905 106.889 49.3905 106.772 49.3347C106.667 49.3002 106.558 49.2786 106.448 49.2703C106.196 49.2554 105.943 49.286 105.701 49.3605L105.442 49.4635C106.454 48.942 107.393 48.2893 108.232 47.5224C109.159 46.6911 110.025 45.7956 110.824 44.8426C111.645 43.8764 112.414 42.8371 113.149 41.772C113.52 41.2395 113.877 40.6998 114.22 40.153L114.725 39.3284C114.881 39.0622 115.062 38.7573 115.179 38.5383C115.363 38.1982 115.613 37.8975 115.915 37.6535C116.216 37.4095 116.562 37.2268 116.935 37.1159C117.307 37.0051 117.698 36.9682 118.084 37.0074C118.471 37.0466 118.846 37.1611 119.188 37.3444C119.53 37.5277 119.832 37.7761 120.078 38.0756C120.323 38.3751 120.507 38.7197 120.619 39.0898C120.73 39.4598 120.767 39.8481 120.728 40.2325C120.688 40.6168 120.573 40.9896 120.389 41.3297C120.369 41.3435 120.35 41.3593 120.333 41.3769Z" fill="#4D4D4F"/>
<path opacity="0.3" d="M112.35 46.8073C114.121 43.1827 117.153 43.3974 118.367 44.514C118.255 44.6815 118.143 44.849 118.026 45.0122C117.199 46.1771 116.308 47.2954 115.356 48.3619C114.385 49.4591 113.332 50.4826 112.207 51.4239C111.849 51.716 111.486 51.9994 111.105 52.2828C111.159 50.3947 111.582 48.535 112.35 46.8073Z" fill="black"/>
<path d="M98.3111 42.7684L96.9547 40.6211L95.0625 43.1978C95.0625 43.1978 96.0345 45.1261 98.0649 45.3451L98.4278 44.3187C98.5262 44.068 98.5668 43.7987 98.5466 43.5304C98.5264 43.2621 98.4459 43.0017 98.3111 42.7684Z" fill="#FF8B7B"/>
<path d="M97.6367 45.0905L99.7967 48.0967C100.7 48.5262 102.436 47.3581 101.849 46.3789C100.494 45.2664 99.2128 44.0686 98.0126 42.793L97.6367 45.0905Z" fill="#FF8B7B"/>
<path d="M99.8623 44.4306C99.7759 44.7227 99.3223 44.6153 98.8471 44.1944C98.3719 43.7736 98.0608 43.1938 98.1472 42.9061C98.2336 42.6183 98.6872 42.7171 99.1624 43.1423C99.6376 43.5674 99.9487 44.1386 99.8623 44.4306Z" fill="#4D4D4F"/>
<path d="M98.0707 46.0983L97.4961 45.3983C98.5631 44.5393 98.5502 43.7191 98.5502 43.6804L99.4574 43.6289C99.4617 43.689 99.5222 44.9302 98.0707 46.0983Z" fill="#4D4D4F"/>
<path d="M94.6408 40.0195L93.5781 42.2527L95.0685 43.2061L96.9564 40.6122L94.6408 40.0195Z" fill="#FF8B7B"/>
<path d="M133.151 151.812H129.777L128.969 144.043H132.347L133.151 151.812Z" fill="#FF8B7B"/>
<path d="M129.48 151.426H133.273C133.337 151.424 133.399 151.446 133.446 151.489C133.492 151.531 133.521 151.591 133.524 151.654L133.705 154.638C133.706 154.716 133.691 154.793 133.662 154.865C133.632 154.938 133.589 155.003 133.534 155.059C133.479 155.114 133.413 155.158 133.341 155.188C133.269 155.219 133.192 155.235 133.114 155.235C131.796 155.209 131.165 155.132 129.498 155.132C128.47 155.132 125.644 155.239 124.227 155.239C122.81 155.239 122.853 153.848 123.454 153.719C126.145 153.157 127.947 152.375 128.897 151.632C129.063 151.501 129.268 151.429 129.48 151.426Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M132.347 144.047H128.969L129.388 148.049H132.762L132.347 144.047Z" fill="black"/>
<path d="M121.055 66.6523C121.055 66.6523 121.344 95.7993 121.893 105.406C122.493 115.924 128.001 147.089 128.001 147.089H134.183C134.183 147.089 131.492 116.774 131.863 106.36C132.265 95.0564 132.511 66.6523 132.511 66.6523H121.055Z" fill="#33A4FF"/>
<path opacity="0.3" d="M121.055 66.6523C121.055 66.6523 121.344 95.7993 121.893 105.406C122.493 115.924 128.001 147.089 128.001 147.089H134.183C134.183 147.089 131.492 116.774 131.863 106.36C132.265 95.0564 132.511 66.6523 132.511 66.6523H121.055Z" fill="black"/>
<path d="M134.433 147.086H126.895L126.523 144.754L134.753 145.119L134.433 147.086Z" fill="#4D4D4F"/>
<path d="M128.307 152.025C128.61 152.017 128.911 151.977 129.205 151.905C129.22 151.902 129.234 151.895 129.245 151.884C129.255 151.873 129.263 151.86 129.266 151.845C129.267 151.83 129.265 151.815 129.26 151.801C129.255 151.787 129.247 151.774 129.236 151.763C129.128 151.69 128.169 151.042 127.758 151.201C127.711 151.219 127.67 151.25 127.638 151.289C127.606 151.328 127.585 151.374 127.577 151.424C127.552 151.498 127.548 151.576 127.565 151.652C127.582 151.727 127.618 151.797 127.672 151.854C127.856 151.985 128.081 152.046 128.307 152.025ZM128.989 151.793C128.389 151.909 127.944 151.892 127.784 151.738C127.751 151.7 127.728 151.655 127.718 151.606C127.708 151.556 127.712 151.506 127.728 151.458C127.732 151.434 127.742 151.411 127.757 151.392C127.772 151.372 127.792 151.357 127.814 151.347C128.017 151.248 128.579 151.531 128.968 151.781L128.989 151.793Z" fill="#33A4FF"/>
<path d="M129.17 151.893C129.183 151.897 129.197 151.897 129.209 151.893C129.221 151.888 129.23 151.879 129.237 151.869C129.244 151.858 129.248 151.846 129.248 151.833C129.248 151.79 129.33 150.828 128.989 150.502C128.946 150.459 128.894 150.427 128.836 150.407C128.778 150.388 128.717 150.382 128.656 150.39C128.589 150.393 128.525 150.417 128.473 150.46C128.421 150.503 128.386 150.561 128.371 150.627C128.293 150.996 128.803 151.696 129.132 151.893C129.144 151.897 129.158 151.897 129.17 151.893ZM128.708 150.536C128.74 150.536 128.772 150.541 128.802 150.553C128.831 150.565 128.858 150.583 128.881 150.605C129.067 150.928 129.144 151.301 129.101 151.67C128.808 151.425 128.471 150.893 128.522 150.652C128.522 150.605 128.557 150.554 128.674 150.536H128.708Z" fill="#33A4FF"/>
<path d="M115.882 39.3964C116.056 38.8346 116.379 38.3299 116.817 37.9353C117.254 37.5407 117.791 37.271 118.371 37.1546C119.203 36.9585 120.047 36.815 120.898 36.7252C123.453 36.5812 126.015 36.5812 128.57 36.7252C129.91 36.7896 131.275 36.9528 132.363 37.1117H132.402C132.686 37.1549 132.958 37.2539 133.203 37.4028C133.447 37.5518 133.66 37.7478 133.828 37.9793C133.995 38.2107 134.115 38.4731 134.18 38.7511C134.244 39.0291 134.252 39.3171 134.204 39.5982C133.355 44.0913 132.801 48.6345 132.545 53.199C132.281 59.8383 132.64 65.0519 132.51 66.6538H114.483C113.364 50.4505 114.781 43.0854 115.882 39.3964Z" fill="#F5F5F5"/>
<path d="M121.965 39.6456L122.397 40.7279C122.437 40.8269 122.505 40.9127 122.592 40.9757C122.679 41.0387 122.782 41.0763 122.889 41.0843L124.211 41.1917C124.306 41.2005 124.401 41.1859 124.488 41.1491C124.576 41.1124 124.653 41.0547 124.712 40.9812L125.62 39.8733L123.926 38.8125L121.965 39.6456Z" fill="#33A4FF"/>
<path d="M122.989 40.7266C122.989 40.7266 121.335 49.1825 120.648 52.3003C120.579 54.585 121.607 57.6513 121.607 57.6513C121.607 57.6513 123.27 53.7605 123.27 52.1028C123.27 49.0966 123.935 40.7266 123.935 40.7266H122.989Z" fill="#33A4FF"/>
<path d="M128.052 36.4023C126.636 42.1313 125.331 56.6511 125.344 68.0015H132.977C133.111 66.3095 132.731 60.8125 133.008 53.8296C133.219 48.4958 134.178 42.7454 134.761 39.4901C134.814 39.193 134.807 38.8883 134.739 38.5941C134.671 38.2999 134.545 38.0222 134.367 37.7775C134.19 37.5328 133.964 37.3261 133.705 37.1695C133.445 37.013 133.157 36.9098 132.856 36.8662H132.817C131.668 36.6987 130.225 36.5269 128.817 36.4582L128.052 36.4023Z" fill="#4D4D4F"/>
<path opacity="0.3" d="M130.625 45.2826C130.751 41.4776 133.576 40.0218 134.371 41.0439C134.407 41.085 134.439 41.1296 134.466 41.177C133.969 44.1102 133.364 48.1471 133.101 52.1238C131.761 51.3551 130.504 48.8986 130.625 45.2826Z" fill="black"/>
<path d="M114.955 39.4695C115.148 38.8415 115.503 38.2741 115.983 37.8226C116.463 37.371 117.052 37.0507 117.693 36.8928C118.896 36.7053 120.107 36.582 121.322 36.5234C119.875 41.4965 117.041 56.6648 117.866 67.9852H113.071C111.935 51.0132 113.883 42.5787 114.955 39.4695Z" fill="#4D4D4F"/>
<path d="M125.953 45.9746L114.016 60.7344L132.27 75.3248L144.208 60.565L125.953 45.9746Z" fill="#33A4FF"/>
<path opacity="0.2" d="M116.445 57.7395L114.02 60.7383L132.274 75.3287L134.699 72.3299L116.445 57.7395Z" fill="black"/>
<path d="M121.605 62.5265L119.832 64.7188L128.922 71.9844L130.695 69.7922L121.605 62.5265Z" fill="white"/>
<path d="M119.803 61.6073C119.958 61.731 120.074 61.8977 120.134 62.0865C120.194 62.2753 120.197 62.4776 120.141 62.6678C120.086 62.858 119.975 63.0275 119.822 63.155C119.67 63.2824 119.483 63.362 119.285 63.3837C119.087 63.4054 118.887 63.3681 118.71 63.2767C118.533 63.1853 118.388 63.0438 118.292 62.8702C118.196 62.6965 118.154 62.4985 118.172 62.3012C118.189 62.1039 118.265 61.9163 118.39 61.7619C118.473 61.6593 118.574 61.5738 118.69 61.5105C118.806 61.4471 118.933 61.4071 119.064 61.3927C119.195 61.3783 119.328 61.3899 119.455 61.4267C119.582 61.4636 119.7 61.525 119.803 61.6073Z" fill="#4D4D4F"/>
<path d="M119.169 63.554C118.864 63.5615 118.568 63.4507 118.345 63.2451C118.121 63.0395 117.987 62.7553 117.97 62.4529C117.954 62.1504 118.057 61.8536 118.257 61.6254C118.458 61.3971 118.74 61.2556 119.043 61.2307C119.197 61.2125 119.352 61.2252 119.501 61.268C119.649 61.3108 119.787 61.3828 119.907 61.4798C120.095 61.6318 120.231 61.838 120.297 62.0701C120.363 62.3021 120.354 62.5486 120.273 62.7758C120.193 63.0029 120.043 63.1997 119.845 63.339C119.647 63.4783 119.411 63.5534 119.169 63.554ZM119.169 61.5571H119.078C118.968 61.5677 118.862 61.6002 118.766 61.6525C118.669 61.7049 118.584 61.7762 118.516 61.862C118.412 61.9904 118.349 62.1467 118.334 62.3111C118.319 62.4754 118.354 62.6403 118.434 62.785C118.514 62.9296 118.635 63.0475 118.782 63.1237C118.929 63.1998 119.096 63.2308 119.261 63.2127C119.426 63.1947 119.582 63.1283 119.709 63.0221C119.835 62.9159 119.928 62.7746 119.974 62.6161C120.02 62.4577 120.018 62.2891 119.967 62.132C119.917 61.9748 119.821 61.836 119.691 61.7331C119.545 61.6144 119.362 61.5492 119.173 61.5485L119.169 61.5571Z" fill="white"/>
<path d="M121.266 36.6926C123.495 36.1687 123.888 34.7944 123.737 33.2914C123.7 32.9131 123.641 32.5374 123.56 32.1662L126.355 29.86L128.407 28.168C127.837 30.6244 127.197 35.0993 128.865 36.757C128.865 36.757 127.482 39.0203 123.681 38.8141C120.441 38.6252 121.266 36.6926 121.266 36.6926Z" fill="#FF8B7B"/>
<path d="M125.579 40.8224C125.244 40.0068 124.673 39.3087 123.938 38.8169C125.645 38.2559 127.198 37.3075 128.474 36.0469L129.644 36.4763C129.644 36.4763 129.627 39.4567 125.579 40.8224Z" fill="white"/>
<path d="M121.647 40.5523C121.647 40.5523 121.971 39.5904 123.932 38.8345C122.809 38.5167 122.368 36.2578 122.368 36.2578L121.275 36.4854C121.275 36.4854 118.826 39.6462 121.647 40.5523Z" fill="white"/>
<path opacity="0.2" d="M123.555 32.1655C123.636 32.5368 123.695 32.9125 123.732 33.2907C124.773 33.0803 126.168 31.9766 126.324 30.9158C126.376 30.5662 126.389 30.2119 126.363 29.8594L123.555 32.1655Z" fill="black"/>
<path d="M121.471 20.2407L120.261 20.5585C119.815 21.0976 119.524 21.7468 119.419 22.4372C119.314 23.1276 119.399 23.8333 119.665 24.4794C120.041 25.6261 120.646 25.6132 120.348 27.374C121.933 28.3617 122.395 26.7469 122.395 26.7469C122.395 26.7469 126.015 19.8242 121.471 20.2407Z" fill="#4D4D4F"/>
<path d="M128.849 23.2537C128.905 26.5905 129.104 28.5359 127.553 30.4255C127.058 31.0447 126.401 31.5175 125.655 31.7928C124.909 32.068 124.102 32.1351 123.32 31.987C122.538 31.8388 121.812 31.481 121.221 30.9522C120.629 30.4234 120.194 29.7438 119.962 28.9869C119.016 26.088 119.098 21.115 122.235 19.4315C122.908 19.0489 123.67 18.8489 124.445 18.8516C125.22 18.8543 125.98 19.0595 126.65 19.4467C127.32 19.8339 127.876 20.3895 128.262 21.0577C128.648 21.7259 128.85 22.4832 128.849 23.2537Z" fill="#FF8B7B"/>
<path d="M128.248 21.7819C132.136 20.0641 129.462 16.7917 126.183 17.7666C121.902 19.0549 120.433 15.5849 118.623 17.7666C117.815 18.7414 119.465 20.2874 117.789 21.4856C118.403 21.812 119.448 21.5543 119.616 20.2703C120.675 21.7132 122.576 21.4942 123.431 21.2924C125.643 20.7684 126.731 22.439 128.248 21.7819Z" fill="#4D4D4F"/>
<path d="M128.801 20.317C128.801 20.317 131.034 19.7587 131.134 17.8477C131.764 18.6851 131.73 20.0808 128.801 20.317Z" fill="#4D4D4F"/>
<path d="M126.91 17.6411C125.934 17.7957 124.085 17.9976 121.484 16.4258C122.595 17.3663 124.508 18.7405 126.91 17.6411Z" fill="#4D4D4F"/>
<path d="M128.955 19.5859L127.706 19.6933C127.173 20.1525 126.774 20.7466 126.553 21.4127C126.331 22.0788 126.296 22.7921 126.449 23.4768C126.626 24.6707 127.222 24.7651 126.626 26.4443C128.021 27.6811 128.752 26.1695 128.752 26.1695C128.752 26.1695 134.657 20.3203 128.955 19.5859Z" fill="#4D4D4F"/>
<path d="M129.943 25.7064C129.701 26.3887 129.23 26.9669 128.608 27.3426C127.813 27.772 127.334 27.0849 127.485 26.1702C127.623 25.3499 128.202 24.1217 129.036 24.0229C129.869 23.9241 130.25 24.8475 129.943 25.7064Z" fill="#FF8B7B"/>
<path d="M123.919 24.8026C123.919 25.0732 123.815 25.3094 123.638 25.3266C123.461 25.3437 123.301 25.1419 123.28 24.8971C123.258 24.6523 123.383 24.3904 123.561 24.3689C123.738 24.3474 123.898 24.5321 123.919 24.8026Z" fill="#4D4D4F"/>
<path d="M120.861 24.9993C120.882 25.2699 120.757 25.5061 120.58 25.5276C120.403 25.549 120.243 25.3429 120.221 25.0723C120.2 24.8018 120.325 24.5699 120.502 24.5484C120.679 24.5269 120.839 24.7331 120.861 24.9993Z" fill="#4D4D4F"/>
<path d="M121.825 25.1094C121.567 25.9731 121.192 26.7979 120.711 27.5616C120.907 27.6708 121.124 27.7362 121.348 27.7533C121.572 27.7704 121.797 27.7386 122.007 27.6603L121.825 25.1094Z" fill="#FF5652"/>
<path d="M123.294 28.2532C122.998 28.1441 122.682 28.0962 122.367 28.1125C122.051 28.1287 121.742 28.2088 121.458 28.3478C121.44 28.358 121.427 28.3751 121.421 28.3953C121.415 28.4154 121.418 28.437 121.428 28.4553C121.438 28.4735 121.456 28.4869 121.476 28.4926C121.496 28.4982 121.518 28.4956 121.536 28.4854C121.847 28.34 122.186 28.2676 122.529 28.2735C122.872 28.2795 123.21 28.3637 123.515 28.5198C123.534 28.5276 123.556 28.528 123.576 28.5208C123.596 28.5137 123.613 28.4996 123.623 28.4811C123.632 28.4616 123.633 28.4395 123.625 28.4194C123.618 28.3993 123.603 28.3829 123.584 28.3736C123.484 28.3392 123.389 28.2876 123.294 28.2532Z" fill="#4D4D4F"/>
<path d="M123.729 22.4253C123.702 22.4294 123.677 22.4432 123.659 22.464C123.63 22.4937 123.613 22.5338 123.613 22.5756C123.613 22.6174 123.63 22.6575 123.659 22.6873C123.822 22.8786 124.028 23.0276 124.262 23.1211C124.495 23.2147 124.748 23.2501 124.999 23.2241C125.041 23.2167 125.078 23.1933 125.103 23.1588C125.128 23.1243 125.139 23.0815 125.133 23.0394C125.129 23.0188 125.121 22.9991 125.11 22.9815C125.099 22.9639 125.084 22.9486 125.067 22.9367C125.049 22.9247 125.03 22.9162 125.009 22.9118C124.989 22.9073 124.967 22.9069 124.947 22.9106C124.75 22.9291 124.553 22.8997 124.37 22.8248C124.188 22.75 124.027 22.632 123.901 22.4811C123.882 22.455 123.855 22.4356 123.824 22.4255C123.793 22.4155 123.76 22.4154 123.729 22.4253Z" fill="#4D4D4F"/>
<path d="M120.84 23.7639C120.817 23.7522 120.791 23.7461 120.765 23.7461C120.738 23.7461 120.713 23.7522 120.689 23.7639C120.516 23.8723 120.32 23.9402 120.116 23.9625C119.913 23.9849 119.707 23.9611 119.514 23.8929C119.495 23.8836 119.475 23.8782 119.453 23.8771C119.432 23.876 119.411 23.8792 119.391 23.8864C119.372 23.8936 119.354 23.9048 119.338 23.9192C119.323 23.9337 119.311 23.951 119.302 23.9703C119.286 24.0087 119.285 24.0519 119.299 24.0911C119.314 24.1302 119.343 24.1624 119.38 24.181C119.619 24.2721 119.876 24.3067 120.131 24.2821C120.386 24.2575 120.632 24.1743 120.849 24.0391C120.885 24.0168 120.911 23.9816 120.921 23.9407C120.932 23.8998 120.926 23.8565 120.905 23.8198C120.888 23.7967 120.866 23.7776 120.84 23.7639Z" fill="#4D4D4F"/>
<path d="M134.933 38.2179L135.158 38.5271L135.326 38.7761C135.43 38.9393 135.529 39.0939 135.624 39.2528C135.819 39.5663 135.983 39.8798 136.143 40.1976C136.463 40.8289 136.739 41.486 137.007 42.1087C137.49 43.3891 137.845 44.714 138.065 46.064C138.289 47.3934 138.393 48.7401 138.376 50.0879C138.365 51.4185 138.242 52.7458 138.009 54.0561C137.895 54.8001 137.711 55.5319 137.46 56.242C137.199 56.9422 136.872 57.6165 136.484 58.2562C135.765 59.4253 134.908 60.5051 133.931 61.4728C133.01 62.3816 132.02 63.2173 130.968 63.9722C129.933 64.7257 128.839 65.3964 127.697 65.9777C127.383 66.1354 127.022 66.1761 126.68 66.0927C126.338 66.0092 126.037 65.8071 125.832 65.5226C125.626 65.2382 125.53 64.8902 125.56 64.5413C125.59 64.1925 125.744 63.8657 125.995 63.62L126.064 63.5556C126.842 62.7998 127.676 61.9623 128.423 61.1292C129.168 60.3113 129.865 59.4509 130.51 58.5525C131.113 57.73 131.63 56.8478 132.052 55.9199C132.237 55.5128 132.382 55.0884 132.484 54.653C132.578 54.229 132.637 53.7982 132.661 53.3647C132.838 51.2806 132.713 49.182 132.289 47.1333C132.078 46.1404 131.789 45.1655 131.425 44.2173C131.251 43.7547 131.052 43.3016 130.829 42.8602C130.721 42.6455 130.605 42.4308 130.497 42.2332L130.328 41.9455L130.255 41.821L130.22 41.7694C129.781 41.1461 129.602 40.3779 129.721 39.6262C129.84 38.8744 130.247 38.1977 130.857 37.738C131.467 37.2784 132.232 37.0716 132.992 37.161C133.752 37.2505 134.448 37.6293 134.933 38.2179Z" fill="#4D4D4F"/>
<path d="M124.555 64.6299L122.801 65.2999L126.257 67.7435C126.257 67.7435 128.883 66.1674 127.955 64.8876L127.389 64.66C126.481 64.2959 125.47 64.2851 124.555 64.6299Z" fill="#FF8B7B"/>
<path d="M121.936 67.6284L124.074 69.6211C124.141 69.6836 124.221 69.7309 124.308 69.7599C124.395 69.7889 124.488 69.7989 124.579 69.7891C124.671 69.7793 124.759 69.75 124.838 69.7033C124.917 69.6565 124.985 69.5933 125.037 69.518L126.273 67.7444L122.817 65.3008L121.828 66.8253C121.749 66.9508 121.716 67.0996 121.736 67.2462C121.756 67.3928 121.826 67.528 121.936 67.6284Z" fill="#FF8B7B"/>
<path d="M164.155 91.5027H45.7089C45.4225 91.5027 45.1478 91.3896 44.9452 91.1883C44.7427 90.9869 44.6289 90.7138 44.6289 90.4291C44.6289 90.1444 44.7427 89.8713 44.9452 89.6699C45.1478 89.4686 45.4225 89.3555 45.7089 89.3555H164.155C164.441 89.3555 164.716 89.4686 164.918 89.6699C165.121 89.8713 165.235 90.1444 165.235 90.4291C165.235 90.7138 165.121 90.9869 164.918 91.1883C164.716 91.3896 164.441 91.5027 164.155 91.5027Z" fill="#33A4FF"/>
<path opacity="0.2" d="M164.155 91.5027H151.627C151.34 91.5027 151.066 91.3896 150.863 91.1883C150.661 90.9869 150.547 90.7138 150.547 90.4291C150.547 90.1444 150.661 89.8713 150.863 89.6699C151.066 89.4686 151.34 89.3555 151.627 89.3555H164.155C164.441 89.3555 164.716 89.4686 164.919 89.6699C165.121 89.8713 165.235 90.1444 165.235 90.4291C165.235 90.7138 165.121 90.9869 164.919 91.1883C164.716 91.3896 164.441 91.5027 164.155 91.5027Z" fill="black"/>
<path d="M147.492 155.124H148.399L157.895 91.5H156.988L147.492 155.124Z" fill="#4D4D4F"/>
<path d="M167.391 155.124H166.484L156.988 91.5H157.895L167.391 155.124Z" fill="#4D4D4F"/>
<path d="M41.5625 155.124H42.4697L51.9651 91.5H51.0579L41.5625 155.124Z" fill="#4D4D4F"/>
<path d="M61.4612 155.124H60.554L51.0586 91.5H51.9658L61.4612 155.124Z" fill="#4D4D4F"/>
<g opacity="0.1">
  <path d="M138.848 47.1914L66.5312 89.3938H126.372L146.71 55.0075L138.848 47.1914Z" fill="#33A4FF"/>
  <path opacity="0.4" d="M138.848 47.1914L66.5312 89.3938H126.372L146.71 55.0075L138.848 47.1914Z" fill="white"/>
</g>
<path d="M112.198 85.0129H76.1646L73.5898 62.1445H109.619L112.198 85.0129Z" fill="#33A4FF"/>
<path d="M111.65 85.0129H75.6216L73.0469 62.1445H109.076L111.65 85.0129Z" fill="#4D4D4F"/>
<path d="M95.3992 85.0124H92.0469L95.7189 76.9688L95.3992 85.0124Z" fill="black"/>
<path d="M84.6523 89.3542H91.4779L95.7202 76.9688H88.8989L84.6523 89.3542Z" fill="#33A4FF"/>
<path d="M98.2521 88.4375H81.875V89.3522H99.7684L98.2521 88.4375Z" fill="#4D4D4F"/>
<path d="M84.3242 89.3542H91.1498L95.3921 76.9688H88.5665L84.3242 89.3542Z" fill="#4D4D4F"/>
<path d="M73.6145 82.0586H50.0273V89.355H73.6145V82.0586Z" fill="#33A4FF"/>
<path opacity="0.6" d="M73.6145 82.0586H50.0273V89.355H73.6145V82.0586Z" fill="white"/>
<path opacity="0.6" d="M68.2275 82.0586H50.0273V89.355H68.2275V82.0586Z" fill="white"/>
<path opacity="0.2" d="M68.2275 82.0586H50.0273V82.8068H67.8171L68.2275 82.0586Z" fill="#33A4FF"/>
<path opacity="0.2" d="M73.6145 84.623L50.0273 84.3693V84.1758H73.6145V84.623Z" fill="#33A4FF"/>
<path opacity="0.2" d="M50.0273 85.3724L73.6145 86.6908V86.4933L50.0273 84.9258V85.3724Z" fill="#33A4FF"/>
<path opacity="0.2" d="M50.0273 88.2661L73.6145 87.7286V87.5352L50.0273 87.819V88.2661Z" fill="#33A4FF"/>
<path d="M72.0872 76.3945H48.5V82.059H72.0872V76.3945Z" fill="#33A4FF"/>
<path opacity="0.6" d="M72.0872 76.3945H48.5V82.059H72.0872V76.3945Z" fill="white"/>
<path opacity="0.6" d="M67.2963 76.3945H48.5V82.059H67.2963V76.3945Z" fill="white"/>
<path opacity="0.2" d="M66.5274 76.3945H48.5V76.9922H66.1213L66.5274 76.3945Z" fill="#33A4FF"/>
<path opacity="0.2" d="M72.0915 80.4062L48.5 80.6255V80.7975H72.0915V80.4062Z" fill="#33A4FF"/>
<path opacity="0.2" d="M48.5 79.7517L72.0915 78.6094V78.7812L48.5 80.1425V79.7517Z" fill="#33A4FF"/>
<path opacity="0.2" d="M48.5 77.2383L72.0915 77.707V77.8747L48.5 77.6296V77.2383Z" fill="#33A4FF"/>
<path d="M71.1575 72.5859H47.5703V76.3909H71.1575V72.5859Z" fill="#33A4FF"/>
<path opacity="0.6" d="M71.1575 72.5859H47.5703V76.3909H71.1575V72.5859Z" fill="white"/>
<path opacity="0.6" d="M65.7705 72.5859H47.5703V76.3909H65.7705V72.5859Z" fill="white"/>
<path opacity="0.2" d="M71.1575 73.9318L47.5703 73.7985V73.6953H71.1575V73.9318Z" fill="#33A4FF"/>
<path opacity="0.2" d="M47.5703 74.3218L71.1575 75.0089V74.9058L47.5703 74.0898V74.3218Z" fill="#33A4FF"/>
<path opacity="0.2" d="M71.1582 72.5859H48.9102L49.4026 73.0546H71.1582V72.5859Z" fill="#33A4FF"/>
<path opacity="0.2" d="M47.5703 75.828L71.1575 75.5442V75.4453L47.5703 75.5915V75.828Z" fill="#33A4FF"/>
<path d="M72.4974 69.6367H48.9102V72.5914H72.4974V69.6367Z" fill="#33A4FF"/>
<path opacity="0.6" d="M72.4974 69.6367H48.9102V72.5914H72.4974V69.6367Z" fill="white"/>
<path opacity="0.6" d="M67.7065 69.6367H48.9102V72.5914H67.7065V69.6367Z" fill="white"/>
<path opacity="0.2" d="M72.4974 71.7227L48.9102 71.8388V71.9248H72.4974V71.7227Z" fill="#33A4FF"/>
<path opacity="0.2" d="M48.9102 71.3899L72.4974 70.793V70.8832L48.9102 71.5918V71.3899Z" fill="#33A4FF"/>
<path opacity="0.2" d="M48.9102 70.0781L72.4974 70.3189V70.4092L48.9102 70.2802V70.0781Z" fill="#33A4FF"/>
<path d="M142.138 86.4219H118.551V89.3765H142.138V86.4219Z" fill="#33A4FF"/>
<path opacity="0.6" d="M142.138 86.4219H118.551V89.3765H142.138V86.4219Z" fill="white"/>
<path opacity="0.6" d="M137.347 86.4219H118.551V89.3765H137.347V86.4219Z" fill="white"/>
<path opacity="0.2" d="M142.138 88.5156L118.551 88.6274V88.7177H142.138V88.5156Z" fill="#33A4FF"/>
<path opacity="0.2" d="M118.551 88.1708L142.138 87.5781V87.664L118.551 88.3769V88.1708Z" fill="#33A4FF"/>
<path opacity="0.2" d="M118.551 86.8633L142.138 87.1084V87.1944L118.551 87.0654V86.8633Z" fill="#33A4FF"/>
<path d="M146.71 55.0144L138.848 47.1984L146.771 43.5137C146.969 43.4632 147.178 43.4704 147.372 43.5343C147.567 43.5983 147.739 43.7163 147.868 43.8744L150.058 46.0474C150.217 46.1771 150.335 46.3488 150.399 46.5427C150.463 46.7366 150.471 46.9446 150.421 47.1425L146.71 55.0144Z" fill="#33A4FF"/>
<path d="M151.162 43.1826L150.747 42.7696C150.3 42.3251 149.575 42.3251 149.128 42.7696L145.918 45.9611C145.47 46.4056 145.47 47.1262 145.918 47.5706L146.333 47.9836C146.78 48.428 147.505 48.428 147.952 47.9836L151.162 44.792C151.61 44.3476 151.61 43.627 151.162 43.1826Z" fill="#33A4FF"/>
<path d="M155.642 88.1211H144.22L143.27 89.3536H156.592L155.642 88.1211Z" fill="#33A4FF"/>
<path d="M149.931 88.3438C149.887 88.3436 149.843 88.3302 149.806 88.3052C149.782 88.2892 149.761 88.2686 149.745 88.2445C149.729 88.2205 149.718 88.1936 149.712 88.1652C149.706 88.1369 149.706 88.1077 149.712 88.0794C149.718 88.0511 149.729 88.0242 149.746 88.0003L164.952 65.8834L148.368 45.5187C148.344 45.4969 148.326 45.4704 148.314 45.4409C148.302 45.4115 148.296 45.3798 148.297 45.348C148.298 45.3162 148.306 45.285 148.32 45.2565C148.335 45.228 148.355 45.2029 148.38 45.1828C148.405 45.1627 148.434 45.1482 148.465 45.1401C148.496 45.132 148.528 45.1306 148.559 45.136C148.591 45.1414 148.621 45.1534 148.648 45.1713C148.674 45.1891 148.696 45.2124 148.713 45.2396L165.401 65.7331C165.431 65.7713 165.447 65.8181 165.447 65.8662C165.447 65.9144 165.431 65.9611 165.401 65.9994L150.117 88.2493C150.096 88.2791 150.069 88.3033 150.036 88.3198C150.004 88.3363 149.968 88.3446 149.931 88.3438Z" fill="#33A4FF"/>
<path d="M165.229 66.9957C165.852 66.9957 166.357 66.4938 166.357 65.8748C166.357 65.2557 165.852 64.7539 165.229 64.7539C164.606 64.7539 164.102 65.2557 164.102 65.8748C164.102 66.4938 164.606 66.9957 165.229 66.9957Z" fill="#33A4FF"/>
</svg>`
export const reminder_icon = `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
<path d="M0 8V5L3.36842 4L0 3V0L8 4L0 8Z" fill="#118ACB"/>
</svg>`
export const tickSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
<path d="M2.66667 6.5L0 3.94681L0.933333 3.05319L2.66667 4.71277L7.06667 0.5L8 1.39362L2.66667 6.5Z" fill="#43A047"/>
</svg>`

