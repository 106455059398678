/** imported components

  * serviceProvider for ===> working with api.
  * CartContext for ==> add item into the cart.
  * React Stripe for ==> for Stripe is a payment service.

**/
import React, { useContext } from "react";
import { Redirect, Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import { apiEndpoints } from '../../services/api-endpoints';
// PaymentSuccessView start
export const PaymentSuccessView = (props) => {
  // defining variables here
  const { cartItems } = useContext(CartContext);
  const {
    total,
    discount,
    discountPrice,
    itemCount,
    increase,
    decrease,
    removeProduct,
    clearCart,
  } = useContext(CartContext);


  var d = JSON.parse(localStorage.getItem("userData"));
  d.paidItem = 11;

  localStorage.setItem("userData", JSON.stringify(d));

  let handleCombo = (combo_data) => {
    let filterArray = [];
    let tmpComboIdArr = [];
    let finalArray = [];
    combo_data.forEach(element => {
      if (element[0] !== 0) {
        filterArray.push(element);
        if (!tmpComboIdArr.includes(element[0])) {
          tmpComboIdArr.push(element[0]);
        }
      }
    });
    tmpComboIdArr.forEach((item) => {
      let tmpArr = filterArray.filter(i => item == i[0]);
      if (tmpArr.length > 0) {
        finalArray.push(tmpArr);
      }
    });
    // console.log(finalArray);
    return finalArray;
  }

  return (
    <div className="cart__page__wrapper">
      {/* 
	  	Container there are 2 section
	   	1. Payment successful message box
		2. Order Summary detail 
	  */}
      <div className="payment__success">
        {/* 1. Payment successful start */}
        <div className="payment__col1">
          <div className="payment__s__card">
            <div className="payment__s__card__body">
              <img
                src={
                  require("../../assets/img/cart-page/payment_success_icon.gif")
                    .default
                }
                className="chk__success"
                alt="true"
              />

              <h2 className="h1">Payment Successful!</h2>
              <p className="p">Transaction Number: {props.tansection_no}</p>
            </div>
            {/* payment__s__card__body end */}
            <div className="payment__s__card__footer">
              <div className="content">
                <div className="f_lft">
                  <p>Amount Paid:</p>
                </div>
                <div className="f_rgt">
                  <p>${props.paid_ammount}</p>
                </div>
              </div>
            </div>
            {/* payment__s__card__footer end */}
          </div>
          {/* payment__s__card end */}
          <div className="payment-success-bottom">
            <img
              src={
                require("../../assets/img/cart-page/payment-success-bottom.svg")
                  .default
              }
              alt="payment-success-bottom img"
            />
          </div>
        </div>
        {/* Order Summary start */}
        <div className="payment__col2">
          <h2 className="h1">Order Summary</h2>
          <div className="order__sam__list__wrapp">
            <div className="order__sam__list">
              <p className="b pay_success_b_text"><span>Launch Offer Bundle</span><span>$500</span></p>
              <p>1 year access</p>
            </div>

            {props.isFirstBuy == 15 && (
              <>
                <div className="order__sam__list">
                  <p className="b">Digital Maturity Assessment - Limited</p>
                  <p>Assessment of Industry 4.0 Vision</p>
                </div>
                <div className="order__sam__list">
                  <p className="b">
                    Results: Digital Maturity Assessment - Limited
                  </p>
                  <p>Results of Factory of the Future Leadership</p>
                </div>
              </>
            )}
          </div>
          <div className="pay_btn_sec">
            <Link to="/training-courses" className="send__btn pay_goto_btn">
              Go to IR4.0 training
            </Link>
            <a
              href={`${apiEndpoints.baseUrl}/certificate/invoicetxn/${props.uid}/${props.tansection_no}`}
              target="_blank"
              className="send__btn pay_down_btn"
            >
              Download Invoice
            </a>
          </div>
        </div>
      </div>
      {/*payment__success end*/}
    </div>
  );
};
