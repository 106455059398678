/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * yupResolver for ===> React hooks for form validation 
  * useForm for ===> React hooks for form validation 
  * yup for ===> JavaScript schema builder for value parsing and validation.

**/

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Redirect, Link } from "react-router-dom";
import "./auth.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios'

export const Resetpassword = (props) => {
	const login = localStorage.getItem("isLoggedIn");
	const [isShow, setIsShow] = useState(false);
	const handleIsShowVal = () => {
		let tmpVal = !isShow;
		setIsShow(tmpVal);
	};
	if (login) {
		return <Redirect to="/home" />;
	}
	const [passwordShown, setPasswordShown] = useState(false);

	// function to toggle password visibility
	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};
	const [passwordShown1, setPasswordShown1] = useState(false);
	// function to toggle confirm password visibility 
	const togglePasswordVisiblity1 = () => {
		setPasswordShown1(passwordShown1 ? false : true);
	};
	// form validation rules
	const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must contain at least 8 characters, One Uppercase, one number and one special character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

	// functions to build form returned by useForm() hook
	const { register, handleSubmit, reset, errors, setError } = useForm({
		resolver: yupResolver(validationSchema),
	});

	// function will be on submit form
	const onSubmit = (data) => {
		const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
		const invi_code = getLastItem(window.location.pathname);
		data["invi_code"] = [];
		data.invi_code = invi_code;
		//console.log(invi_code);
		serviceProvider.post("/user?type=resetpass", JSON.stringify(data)).then((response) => {
			if (response.data.status === 200) {
				setIsShow(true);
			}
			if (response.data.status === "failed") {
			}
		});
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>Reset Password | SmarterChains</title>
				<meta name="description" content="Reset Password" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			<div className="auth__form--wrapp">
				{/*started left section image + logo */}
				<Grid item xs={12} sm={4} md={4} className="auth__form--left">
					<div className="auth__form--logo">
						<img src={require("../../assets/img/auth/sc-logo.svg").default} />
					</div>
				</Grid>
				{/*ended left section image + logo */}
				{/*started right section and form layout */}
				<Grid item xs={12} sm={8} md={8} className="auth__form--right">
					<section>
						<div className="auth__form-sm--logo">
						<img src={require("../../assets/img/auth/sc-logo.svg").default} />
						</div>
						{/* form main wrapper started */}
						<div className="wrapper sign-in">
							{/* signup form main wrapper's inner div started*/}
							<div className="col-6 Signup-form" style={{ display: isShow ? "none" : "block" }}>
								<h1>Create new password</h1>
								<p>Setup a new password here</p>
								{/* form started */}
								<form className="signup-box" onSubmit={handleSubmit(onSubmit)}>
									<div className="form-group">
										<div className="col-12">
											<label>Password</label>
											<input
												name="password"
												placeholder="Password"
												type={passwordShown ? "text" : "password"}
												ref={register}
												className={`form-control ${errors.password ? "is-invalid" : ""}`}
											/>

											<span
												onClick={togglePasswordVisiblity}
												className={`form-control ${
													passwordShown
														? "fa fa-fw field-icon toggle-password fa-eye"
														: "fa fa-fw field-icon toggle-password fa-eye-slash"
												}`}
											/>
											<label className="notes">{errors.password?.message}</label>
										</div>
									</div>
									<div className="form-group">
										<div className="col-12">
											<label>Confirm Password</label>
											<input
												name="confirmPassword"
												placeholder="Confirm Password"
												type={passwordShown1 ? "text" : "password"}
												ref={register}
												className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
											/>
											<span
												onClick={togglePasswordVisiblity1}
												className={`form-control ${
													passwordShown1
														? "fa fa-fw field-icon toggle-password fa-eye"
														: "fa fa-fw field-icon toggle-password fa-eye-slash"
												}`}
											/>
											<label className="notes">{errors.confirmPassword?.message}</label>
										</div>
									</div>
									<button type="submit" className="signup">
										Reset Password
									</button>
								</form>
								{/* form ended */}
							</div>
							{/* signup form main wrapper's inner div ended*/}

						</div>
						{/* form main wrapper ended */}

						{/* after sucessfully reset password text div  started */}
						<div className="train__reset-password-msg" style={{ display: isShow ? "block" : "none" }}>
							<img className="train__check-icon" src={require("../../assets/img/auth/resetpassword-check-icon.svg").default} />
							<p className="train__msg1">Reset password successfully !!</p>
							<p className="train__msg1">
								Please login to continue{" "}
								<a href="/sign-in" className="train__msg-lnk">
									Login
								</a>
							</p>
						</div>
						{/* after sucessfully reset password text div started */}
					</section>
				</Grid>
				{/*ended right section and form layout */}
			
			</div>
		</React.Fragment>
	);
};

export default Resetpassword;
