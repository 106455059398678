import React, { useState, useEffect } from 'react';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
    MainWrapper,
    H1,
    Row,
    P,
    InputWrapper,
    InfoTooltip,
    SubmitButton,
    AfterSubmitButton,
    ErrorMsgDiv

} from './styles';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { InfoIcon, tickSvg } from './svg';
import serviceProvider from '../../services/axios';
import { Helmet } from "react-helmet";
export const PlantCostPercentValueView = (props) => {
    const history = useHistory();
    const dataReturn = props.results[0];
    const plant_name = dataReturn.plant_name;
    const plant_slug = dataReturn.plant_slug;
    const plant_id = dataReturn.plant_id;
    const chapter_id = dataReturn.chapter_id;
    const user = JSON.parse(localStorage.getItem("userData"));
    const uid = user ? user.uid : 0;
    const user_results = dataReturn.user_results;
    const is_datasubmitted = dataReturn.is_datasubmitted;
    const allFields = [
        {
            id: 2895,
            title: 'Aftermarket/ Services',
            info: `Expenses that the company expects to or has already incurred for the repairor replacement of goods that it has sold. The total expense is limited by thewarranty period that the company provides.`
        },
        {
            id: 2896,
            title: 'Depreciation',
            info: `A non-cash expense representing the portion of all fixed assets owned by the company that has been considered consumed over an accounting or financial period.`
        },
        {
            id: 2897,
            title: 'Labour',
            info: `The sum of all wages paid to employees, as well as the cost of employee benefits and payroll taxes paid by an employer. This includes employees who are directly involved in the maintenance and production processes, and support teams that ensure the smooth running of the entire facility`
        },
        {
            id: 2898,
            title: 'Maintenance & Repai',
            info: `All expenses required to bring capital assets – such as building, infrastructure, equipment, and machinery – back to good working order, or to keep them operating at optimal condition. This includes fixing broken assets and routine servicing`
        },
        {
            id: 2899,
            title: 'Raw Materials & Consumables',
            info: `Raw materials and consumables costs include both direct materials, which are incorporated into the final product, and indirect materials that are consumed during the production process but not incorporated into the final product.`
        },
        {
            id: 2900,
            title: 'Rental & Operating Lease',
            info: `Costs associated with the use of assets which the company does not own. These include but are not limited to property, plant, and equipment.`
        },
        {
            id: 2901,
            title: 'Research & Development',
            info: `All expenses relating to activities for the development or improvement of products or processes. Such activities can include product design improvement and production process enhancement.`
        },
        {
            id: 2902,
            title: 'Selling, General & Administrative Expense (“SG&A”)',
            info: `All operating expenses which are not directly tied to the cost of making a product or providing a service. It includes corporate, accounting, legal, sales, and marketing expenses.`
        },
        {
            id: 2903,
            title: 'Transportation & Distribution',
            info: `All expenses relating to the transportation of goods from one location to another. It includes the cost of transporting goods from suppliers and to customers via methods such as trucking, shipping, and freight (land, air, and sea).`
        },
        {
            id: 2904,
            title: 'Utilities',
            info: `Cost of electricity, heat (gas/fuel), sewer, and water used by a factory or plant to ensure the smooth running of both the direct manufacturing process and its surrounding environmental conditions.`
        },
    ]
    const [infoActiveTooltip, setInfoActiveTooltip] = useState(false);
    const [isAfterSubmit, setIsAfterSubmit] = useState(false);
    const [inputsValue, setInputsValue] = useState({ ...user_results });
    const [submitMsg, setSubmitMsg] = useState(false);
    const handleClickedInfoIcon = (ind) => {
        if (infoActiveTooltip !== ind) {
            setInfoActiveTooltip(ind);
        } else {
            setInfoActiveTooltip(false);
        }
    }
    const handleClickedOutside = (e) => {
        if (!e.target.closest('.info-icon-i') && !e.target.closest('[data-component="InfoTooltip"]')) {
            setInfoActiveTooltip(false);
        }
    }
    const handleAllInputsValue = (val, id) => {
        if (!val && val !== 0) {
            let tmpObj = { ...inputsValue };
            delete tmpObj[id];
            setInputsValue(tmpObj);

        } else {
            setInputsValue(prv => { console.log('prv', prv); return { ...prv, [id]: val } });
        }
    }
    const handleSubmit = () => {
        serviceProvider
            .post(
                "/assessmentmaturity?type=saveplantcost",
                JSON.stringify({
                    question_id: 733,
                    user_id: uid,
                    plant_id: plant_id,
                    option_id: inputsValue,
                }),
                true
            )
            .then((response) => {
                console.log(response);
                setSubmitMsg('Your data has been saved successfully');
            });




        const button = document.getElementById('submit-btn');
        console.log(inputsValue);
        if (button.hasAttribute('disabled')) {
            setIsAfterSubmit(false);
        } else {
            setIsAfterSubmit(true);
        }
        setTimeout(() => {
            setIsAfterSubmit(prv => false);
            history.push(`/assessment/chapter/${plant_slug}/10`);
        }, 3000);

    }

    useEffect(() => {
        document.addEventListener('click', handleClickedOutside);
        return () => {
            document.removeEventListener("click", handleClickedOutside);
        }
    }, []);

    return (
        <MainWrapper data-component="MainWrapper">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{plant_name}</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/${plant_slug}`}>Assessment</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/chapter/${plant_slug}/10`}>Plant Cost Profile</BreadcrumbsItem>
            <BreadcrumbsItem to="#">Plant Cost</BreadcrumbsItem>
            <H1 data-component="H1">
                PLANT COST
            </H1>
            {/* H1 end */}
            <ErrorMsgDiv dataComponent='ErrorMsgDiv' active={submitMsg ? true : false} green={true}>
                <p>{submitMsg}</p>
            </ErrorMsgDiv>
            {
                allFields.map((item, ind) => (
                    <Row data-component="Row" key={ind}>
                        <P data-component="P">
                            {item.title}
                            <i className={`info-icon-i ${infoActiveTooltip === ind ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: InfoIcon }} onClick={(e) => handleClickedInfoIcon(ind)}></i>
                            <InfoTooltip data-component="InfoTooltip" lastRow={(allFields.length>3 && allFields.length-3 == ind-2)} className={infoActiveTooltip === ind ? 'active' : ''}>
                                {item.info}
                            </InfoTooltip>
                        </P>
                        <InputWrapper data-component="InputWrapper">
                            <PercentInput placeholder='%' defaultValue={user_results[item.id] || ''} getValue={(val) => handleAllInputsValue(val, item.id)} disabled={is_datasubmitted ? true : false} />
                        </InputWrapper>
                    </Row>
                )
                )
            }
            {!isAfterSubmit && !is_datasubmitted ?
                <SubmitButton id="submit-btn" as={'button'} onClick={() => handleSubmit()} data-component="SubmitButton" disabled={Object.values(inputsValue).length === allFields.length ? false : true}>
                    Submit
                </SubmitButton>
                :
                <AfterSubmitButton data-component="AfterSubmitButton">
                    <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>
                    <span>Submitted Successfully</span>
                </AfterSubmitButton>
            }

        </MainWrapper>
    );
}


const PercentInput = (props) => {
    const { getValue = () => { } } = props;
    const [inputValue, setInputValue] = useState(props.defaultValue);

    const handleInputChange = (event) => {
        // Use regular expression to remove non-numeric characters
        const numericValue = event.target.value.replace(/\D/g, '');
        if (numericValue <= 100) {
            setInputValue(numericValue);
        }
    };

    const handleBlur = () => {
        if (inputValue !== '') {
            setInputValue(inputValue + '%');
            getValue(inputValue + '%');
        } else {
            getValue(inputValue);
        }
    };

    return (
        <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onFocus={() => setInputValue(inputValue.replace(/\D/g, ''))}
            {...props}
            name='ans[]'
        />
    );
};
