import styled from "styled-components";

export const TopSectionBtn = styled.button`
  background: ${({ disabled }) => (disabled ? "#cbcfd2" : "#0789cf")};
  color: ${({ disabled }) => (disabled ? "#7d868c " : "#fff")};
  pointer-events: ${({ disabled }) => (disabled ? "none " : "all")};
  border: 0.1rem solid ${({ disabled }) => (disabled ? "#cbcfd2" : "#0789cf")};
  padding: 1.5rem 2rem;
  border-radius: 0.6rem;
  font-size: 1.6rem;
  display: inline-block;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 3rem;
`;

export const HeaderFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterWrapper = styled.div`
  position: relative;
`;

export const FilterButton = styled.button`
  transition: 0.25s ease-in;
  background: ${({ active }) => (active ? "#fff" : "transparent")};
  /* box-shadow: ${({ active }) =>
    active ? "0px 0px 1rem rgba(0, 0, 0, 0.25)" : "none"} !important; */
  border: ${({ active }) =>
    active ? "1px solid rgba(0, 0, 0, 0.25)" : "none"} !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  border-radius: 0.4rem;
  transition: 0.25s ease-in;
  line-height: 0;
  i {
    display: inline-block;
  }
  svg {
    height: 1.5rem;
    width: 2.5rem;
  }
`;

export const FilterBox = styled.div`
  width: 50rem;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 1rem; */
  border: 0.1rem solid rgba(0, 0, 0, 0.15);
  max-height: 70vh;
  border-radius: 0.7rem;
  position: absolute;
  z-index: 9;
  right: 0px;
  bottom: -1.5rem;
  transform: translateY(100%);
  transition: 0.25s ease-in;
  background: #fff;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  display: flex;
  flex-direction: column;
`;
export const FilterCloseButton = styled.button`
  i {
    display: inline-block;
    svg {
      height: 2.2rem;
      width: 2.2rem;
      path {
        fill: #4d4d4f;
      }
    }
  }
`;
export const FilterBoxContent = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 0 1.6rem 0;
`;
export const FilterBoxContentTitle = styled.h2`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.2rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  color: #4d4d4f;
`;
export const FilterBoxLable = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 1.5rem;
  width: 30%;
  span {
    color: #7d868c;
    display: inline-block;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2.2rem;
    margin-left: 0.5rem;
  }
  input[type="checkbox"],
  input[type="radio"] {
    accent-color: #118acb;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
  }
  .icon {
    display: flex;
    height: 2.2rem;
    width: 2rem;
    margin: 0;
    align-items: center;
    i {
      display: flex;
      height: 2.2rem;
      width: 2rem;
      align-items: center;
      svg {
        height: 75%;
        width: 75%;
      }
    }
  }
  input[type="checkbox"] + .icon,
  input[type="radio"] + .icon {
    i {
      svg {
        path {
          fill: #ffffff;
          stroke: #118acb;
        }
        circle {
          &.first-circle {
            stroke: #ffffff;
            fill: #ffffff;
          }
        }
      }
    }
  }
  input[type="checkbox"]:checked + .icon,
  input[type="radio"]:checked + .icon {
    i {
      svg {
        path {
          fill: #118acb;
          stroke: #118acb;
        }
        circle {
          &.first-circle {
            stroke: #118acb;
            fill: #118acb;
          }
        }
      }
    }
  }
`;
export const FilterCheckBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &.halfWidth {
    ${FilterBoxLable} {
      margin-bottom: 0.5rem;
      width: 50%;
    }
  }
`;
export const FilterBoxTitle = styled.div``;
export const SaveRoadmapButton = styled.button`
  display: inline-flex;
  padding: 1.3rem 1.6rem;
  min-width: 20rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #118acb;
  justify-content: center;
  font-size: 1.4rem;
  color: #118acb;
  font-weight: 500;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const FilterButtonWrapper = styled.div`
  background: #ffffff;
  /* box-shadow: -0.4rem 0px 0.6rem rgba(0, 0, 0, 0.12); */
  border-radius: 0px 0px 1.6rem 1.6rem;
  border-top: 0.1rem solid #ccc;
  padding: 1.5rem 1.5rem;
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
`;
export const ResetFilterButton = styled.button`
  width: 15.7rem;
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 500;
  color: #4d4d4f;
  height: 4rem;
  font-size: 1.4rem;
  border: 0;
  background: 0 0;
`;
export const ApplyFilterButton = styled.button`
  background: #fff;
  border: 0.1rem solid #118acb;
  border-radius: 0.4rem;
  outline: 0;
  height: 4rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: #118acb;
  width: 15.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.25s ease-in-out;
  &:hover {
    background: linear-gradient(97.6deg, #139bd6 12.22%, #078ad0 100.41%);
    color: #fff;
  }
`;
//Left Card Wrapper Deepika
export const MainCardWrapper = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  * {
    user-select: none;
  }
`;
export const TabCardWrapper = styled.div`
  margin-bottom: 1rem;
`;
export const TabContentWraaper = styled.div`
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #7d868c;
  position: relative;
  padding: 1.5rem;
  // Css for tabs
  .MuiBox-root {
    padding: 2.4rem 0 0 2.4rem !important;
  }
  .MuiTabs-root {
    align-items: center;
    position: relative;
    .MuiButtonBase-root {
      min-width: 15rem;
      width: max-content;
      .MuiTab-wrapper {
        font-size: 1.4rem !important;
        text-transform: capitalize;
      }
    }
    .PrivateTabIndicator-root-1 {
      background-color: #118acb !important;
      padding: 0.2rem;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
    .MuiButtonBase-root {
      &.MuiTabScrollButton-root {
        &.MuiTabs-scrollButtons {
          &.MuiTabs-scrollButtonsDesktop {
            height: 2.4rem !important;
            width: 2.4rem !important;
            min-width: unset;
            background: #fff;
            .MuiSvgIcon-root {
              &.MuiSvgIcon-fontSizeSmall {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .MuiTabs-scroller {
    border-bottom: 0.1rem solid #dee2e6;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;
export const NAButton = styled.div`
  padding-right: 1rem;
  background: ${({ status }) =>
    status === "has"
      ? "#e2f8db"
      : status === "hasnot"
      ? "#f7dcdd"
      : status === "partially"
      ? "#fff6e1"
      : "#dcedf7"};
  color: ${({ status }) =>
    status === "has"
      ? "#27ae60"
      : status === "hasnot"
      ? "#db324d"
      : status === "partially"
      ? "#f2994a"
      : "#118acb"};
  display: inline-block;
  padding: 0.6rem 2rem;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 1.2rem;
  font-weight: 500;
  height: fit-content;
  text-transform: uppercase;
  width: max-content;
`;
export const RightAlignTextWrapper = styled.div`
  padding-right: 1rem;
`;
export const RightAlignTextWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  padding-bottom: 0.5rem;
  align-items: center;
`;
export const HeadText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: #cbcfd2;
`;
export const SubText = styled.span`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.5rem;
  color: ${({ green, orange }) =>
    green ? "#27ae60" : orange ? "#f2994a" : ""};
`;
export const AddDragBtn = styled.button`
  border-right: 0.15rem solid #ebf5fa;
  border-left: 0.15rem solid #ebf5fa;
  padding: 0.5rem 2rem;
  i {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    svg {
      height: 100%;
      width: 100%;
    }
  }
`;
export const CollapseBtn = styled.button`
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  transition: unset !important;
  padding: 2rem;
  i {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  &.active {
    background: #118acb;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
`;
export const RightTabCardWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const TabPanelContent = styled.div`
  p {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  ul {
    padding-left: 3.5rem;
    margin-bottom: 1rem;
    li {
      font-size: 1.3rem;
      list-style-type: disc;
    }
  }
  .bc_leadership {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    i {
      display: inline-block;
      height: 2.4rem;
      width: 2.4rem;
      margin-right: 1rem;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
`;
export const VendorWrap = styled.div``;
export const VendorRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const VendorCol = styled.div`
  padding-right: 3rem;
  padding-bottom: 3rem;
  &:nth-child(4) {
    padding-bottom: 0;
  }
  &:nth-child(5) {
    padding-bottom: 0;
  }
`;
export const VendorCard = styled.div`
  height: 16rem;
  width: 16rem;
  box-shadow: 0px 0px 0.6rem rgba(0, 0, 0, 0.12);
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 14rem;
  }
`;
export const VendorText = styled.span`
  display: block;
  color: #4d4d4f;
  margin-top: 1rem;
  font-size: 1.4rem;
`;
//Left Card Wrapper Deepika
export const InnerTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 0px 3px 12px rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  height: 6rem;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;
export const InnerTBLeftCol = styled.div`
  display: inline-flex;
  align-items: center;
  width: 40%;
  .inner-tab-txt {
    color: #7d868c;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding-left: 2.5rem;
    padding-right: 2rem;
  }
`;
export const InnerTBRightCol = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  .recovery-txt {
    border-left: 0.15rem solid #ebf5fa;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: #cbcfd2;
    span {
      color: #118acb;
      display: inline-block;
      margin-right: 0.4rem;
    }
  }
`;
export const LossTableWrap = styled.div`
  border: 0.1rem solid #ebf5fa;
  -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
  border-radius: 0.8rem;
  padding: 1.5rem;
  display: block;
  margin-bottom: 2rem;
`;
export const LossScrollTableWrap = styled.div`
  overflow-x: auto;
  width: 100%;
  .responsive-loss-table {
    border-collapse: collapse;
    // width: 55rem;
    thead {
      th.loss-table-col {
        color: #118acb;
        border-bottom: 0 !important;
      }
    }
    .loss-table-col {
      color: #828282;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 1rem 1.5rem;
      text-align: left;
      border-bottom: 0.1rem solid #f0f0f0;
      border-right: 0.1rem solid #f0f0f0;
      &:last-child {
        border-right: 0;
      }
      &.bottom {
        background: #ebf5fa;
        color: #4d4d4f;
        border-bottom: 0;
      }
      &.orange {
        margin-top: 1rem;
        color: #eb5757;
      }
      &.green {
        margin-top: 1rem;
        color: #27ae60;
      }
      &.blue {
        margin-top: 1rem;
        color: #118acb;
      }
    }
  }
`;
export const VendorClickImg = styled.div``;
export const VendorModalWrap = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 66vh;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 1.5vw rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 1.5vw rgba(0, 0, 0, 0.12);
  border-radius: 0.6rem;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  width: 52rem;
  z-index: 999;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
`;
export const VendorModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  .vendor-name {
    font-size: 1.6rem;
    font-weight: 400;
    color: #1184cb;
  }
`;
export const VendorCloseBtn = styled.div`
  cursor: pointer;
  i {
    display: inline-block;
    height: 2.6rem;
    width: 2.6rem;
    svg {
      height: 100%;
      width: 100%;
    }
  }
`;
export const VendorTabWrap = styled.div``;
export const VendorTabs = styled.div`
  display: flex;
  border-bottom: 0.1rem solid #ccc;
`;
export const VendorTab = styled.span`
  color: #4d4d4f;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 400;
  margin-right: 1rem;
  padding: 0.6rem 2.5rem;
  border-bottom: 0.3rem solid transparent;
  margin-bottom: -0.1rem;
  width: max-content;
  display: inline-block;
  &.active {
    color: #118acb;
    border-color: #118acb;
  }
`;
export const VendorTabPanel = styled.div`
  padding: 1.5rem 0;
`;
export const AccordImg = styled.div`
  i {
    display: inline-block;
    height: 1rem;
    width: 0.6rem;
    transition: 0.5s ease-in-out;
    svg {
      height: 100%;
      width: 100%;
    }
  }
`;
export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  .accord-txt {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 1rem;
  }
`;
export const AccordionTabWrapper = styled.div`
  .MuiAccordion-root {
    &::before {
      content: unset;
    }
    &.Mui-expanded {
      margin: 0;
    }
  }
  .MuiAccordionSummary-root {
    min-height: unset !important;
    padding: 0;
    .MuiAccordionSummary-content {
      justify-content: space-between;
      margin: 0;
      padding-bottom: 1rem;
      .vendor-hidden-text {
        color: #118acb;
        font-size: 1.1rem;
        font-weight: 400;
        opacity: 0;
        visibility: hidden;
      }
      &.Mui-expanded {
        .vendor-hidden-text {
          visibility: visible;
          opacity: 1;
        }
        .accord-txt {
          color: #118acb;
        }
        ${AccordImg} {
          display: inline-flex;
          i {
            transform: rotate(90deg);
            svg {
              path {
                fill: #118acb;
              }
            }
          }
        }
      }
    }
    .MuiAccordionSummary-expandIcon {
      display: none !important;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0 1.5rem;
  }
`;
export const MaintainWrap = styled.div`
  padding-top: 1rem;
  width: 100%;
`;
export const MaintainRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  &::last-child {
    margin-bottom: 0;
  }
`;
export const LeftMainCol = styled.div`
  display: flex;
  align-items: center;
  .accord-txt {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 1rem;
  }
`;
export const RightMainCol = styled.div`
  .main-val {
    display: flex;
    background: #f0f0f0;
    margin-bottom: 0px;
    text-align: center;
    width: 8rem;
    height: 2.5rem;
    color: #7d868c;
    font-size: 1.2rem;
    font-weight: 500;
    border: 0.1rem solid #7fcdbb;
    border-radius: 0.4rem;
    align-items: center;
    justify-content: center;
  }
`;
export const ListInvestWrap = styled.div`
  border: 0.1rem solid #f0f0f0;
  padding: 1.5rem;
  border-radius: 0.6rem;
`;
export const ListRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: center;
  .blue-txt {
    color: #118acb;
    font-size: 1.4rem;
  }
`;
export const UpfrontListRow = styled.div`
  border-top: 0.1rem solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  .black-txt {
    color: #4d4d4f;
    font-size: 1.4rem;
  }
`;
export const ListValWrap = styled.div`
  background: transparent;
  color: #f2994a;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  width: 12rem;
  text-align: right;
  .money-txt {
    display: inline-block;
    margin-right: 0.5rem;
  }
`;
export const GoListWrap = styled.div`
  border: 0.1rem solid #f0f0f0;
  border-radius: 0.6rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  .blue-txt {
    color: #118acb;
    font-size: 1.4rem;
  }
`;
export const UserWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  .user-txt {
    color: #4d4d4f;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 400;
    margin-right: 1rem;
  }
  .user-num {
    color: #4d4d4f;
    text-align: center;
    width: 8rem;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 600;
    /* border: 0.1rem solid #e3e3e3; */
    border-radius: 0.6rem;
    padding: 0.4rem 0;
  }
`;
export const TechnologyPopWrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(125, 134, 140, 0.3);
  backdrop-filter: 0.4rem;
  z-index: 99;
`;
export const TechnologyPopWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 8px rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: column;
  width: 90%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 90%;
`;
export const TechnologyPopHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  h3 {
    font-size: 2rem;
    color: #118acb;
  }
  .close-popup {
    display: inline-block;
    height: 3rem;
    width: 3rem;
    svg {
      height: 100%;
      width: 100%;
    }
  }
`;
export const TechnologyPopBody = styled.div`
  padding: 1.6rem;
  display: flex;
  flex: 1;
  overflow: auto;
`;
export const TechnologyPopBodyWrap = styled.div`
  flex: 1;
  overflow: auto;
  background: #fafcfd;
  padding: 0 !important;
  border: 0.1rem solid #d3e6ff;
  border-radius: 1.2rem !important;
  .table-wrap {
    border-collapse: collapse;
    width: 240rem;
    border-width: 0 0.1rem 0 0 !important;
    th,
    td {
      border: 0.1rem solid #d3e6ff;
      color: #118acb;
      font-size: 1.4rem;
      font-weight: 600;
      padding: 0.5rem;
      text-align: left;
    }
    td {
      color: #4d4d4f;
      font-weight: 500;
      .status {
        padding: 0.3rem 2rem;
        text-align: center;
        border-radius: 0.3rem;
        font-weight: 500;
        width: max-content;
        text-transform: uppercase;
        font-size: 1.3rem;
        &.has-not {
          background: #f7dcdd;
          color: #db324d;
        }
        &.partial {
          background: #fff6e1;
          color: #f2994a;
        }
        &.na {
          background: #dcedf7;
          color: #118acb;
        }
        &.has {
          background: #e2f8db;
          color: #27ae60;
        }
      }
    }
  }
`;

// Upgrade Popup
export const UpgradePopWrapper = styled.div`
  border-radius: 0.8rem;
  border: 0.06rem solid #DB324D; 
  background: #FCF1F3;
`
export const UpgradePopCard = styled.div`
  padding: 1.6rem;
  position: relative;
  .upsell-title{
    color: #DF586E;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: normal;
  }
  .upsell-text{
    color: #4D4D4F;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.8rem;
    width: 44rem;
  }
  .upgrade-close-btn{
    height: 2rem;
    width: 2rem;
    display: block;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    i{
      display: block;
      height: 100%;
      width: 100%;
      svg{
        height: 100%;
        width: 100%;
      }
    }
  }
`
export const UpgradePopRow = styled.div`
  display: flex;
  margin: 2.4rem -1.2rem 2.4rem -1.2rem;
`
export const UpgradePopCol = styled.div`
  padding: 0 1.2rem;
  width: 33.33%;
`
export const UpgradeInnerCard = styled.div`
  .upgrade-txt{
    color: #4D4D4F;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    b{
      font-weight: 700;
    }
  }
`
export const UpgradeInnerHead = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  i{
    display: inline-block;
    height: 2.4rem;
    width: 2.4rem;
    svg{
      height: 100%;
      width: 100%;
    }
  }
  .head-text{
    color: #DF586E;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: capitalize;
    margin-left: 0.8rem;
  }
`
export const UpgradeFooter = styled.div`
  padding-bottom: 1.2rem;
  .main-cart-btn{
    border-radius: 0.4rem;
    background: rgba(219, 50, 77, 0.90);
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 1.6rem;
    min-width: 16.7rem;
    .cart-txt{
      color: #FFF;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.8rem;
      padding-right: 1rem;
      border-right: 0.1rem solid #fff;
    }
    .cart-price{
      color: #FFF;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.8rem;
      padding-left: 1rem;
    }
  }

`
export const UpgradeLock = styled.div`
position: relative;
.upgrade-tooltip{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  span{
    display: block;
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 0.4rem;
    padding: 0.8rem 1.6rem;
    background: #2C55A0;
    width: max-content;
  }
}
.upgrade-lock-icon{
  display: block;
  height: 100%;
  width: 100%;
  svg{
    height: 100%;
    width: 100%;
  }
}
&:hover{
  .upgrade-tooltip{
    opacity: 1;
    visibility: visible;
  }
}
`
export const UpgradeInfoWrapper = styled.div`
  background: #E5F0FF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.7rem;
  .upgrade-info-icon{
    display: inline-block;
    height: 2rem;
    width: 2rem;
    svg{
      height: 100%;
      width: 100%;
    }
  }
  .info-upgrade-txt{
    color: #0D2C54;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2rem;
    padding: 0 1.6rem;
  } 
  .upgrade-info-btn{
    color: #118ACB;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.8rem;
    min-width: 33.5rem;
    border-radius: 0.4rem;
    display: inline-block;
    border: 0.1rem solid #118ACB;
    background: #FFF;
    min-height: 3.4rem;
  }
  .upgrade-info-close-btn{
    display: block;
    position: absolute;
    height: 2.4rem;
    width: 2.4rem;
    top: 1.2rem;
    right: 1.2rem;
    i{
      display: block;
      height: 100%;
      width: 100%;
      svg{
        height: 100;
        width: 100%;
      }
    }
  }
`