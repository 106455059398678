import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Link } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { arrowIcon, backArrow } from "./svg";

import {
  SiriMainWrapper,
  SiriPageTitle,
  SiriAssessementWrapper,
  SiriAsmtLeftWrap,
  SiriAsmtRightWrap,
  SiriCardWrap,
  SiriCard,
  CardLeftSec,
  TextWrap,
  StatusTab,
  TopProgressBar,
  ProgressBarSection,
  HeaderProgress,
  HeaderProgressBar,
  SubmitSection,
  AssessmentSubmissionSection,
  AsmtTopSec,
  AsmtBottomSec,
  ButtonSec,
  CardRightSec,
  ProgressBar,
  SiriMobileTitleContainer,
} from "./styles";


export const SiriMainView = (props) => {
  const siriData = props.siriData;
  if (!siriData.length) {
    return null;
  }
  const cardData = props.siriData[0].results;
  const slug = props.siriData[0].slug;
  
  const progress = props.siriData[0].progress;
  const plant_id = props.siriData[0].plant_id;
  const plant_name = props.siriData[0].plant_name;
  const completed = props.siriData[0].completed;
  const user = JSON.parse(localStorage.getItem("userData"));
	const uid = user ? user.uid : 0;


  const history = useHistory();
  //function to handle redirection
  const handlePageRedirect = () => {
    history.push('/home');
  };
  const downloaDdata = () => {

    serviceProvider.post("/siri?param=status_download",JSON.stringify({user_id: uid,plant_id: plant_id}),true).then((response) => {
        
      window.location = `https://apimimlive.smarterchains.com/export/downloadTable/${slug}`;
         
    });
    
  };
  return (
    <>

      <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/">{plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Assessment</BreadcrumbsItem>
      <Helmet>20
        <title>Assessment | SmarterChains</title>
        <meta name="description" content="Siri Assessment" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <SiriMainWrapper data-component="SiriMainWrapper">
        <SiriPageTitle data-component="SiriPageTitle">
          <span>Assessment</span>
          <Link to={`/company-detail/${slug}`} className="company-profile-link">Company profile</Link>
        </SiriPageTitle>
        <SiriMobileTitleContainer data-component="SiriMobileTitleContainer">
          <button className="back-icon" dangerouslySetInnerHTML={{ __html: backArrow }} onClick={() => handlePageRedirect()}></button>
          <span>Assessment</span>
        </SiriMobileTitleContainer>
        <SiriAssessementWrapper data-component="SiriAssessementWrapper">
          <SiriAsmtLeftWrap data-component="SiriAsmtLeftWrap">
            <SiriCardWrap data-component="SiriCardWrap">
              {cardData.map((item, index) => (
                <SiriCard as={Link} data-component="SiriCard" to={`/siri-dimension/${slug}/${item.slug}`}>
                  <CardLeftSec data-component="CardLeftSec">
                    <span className="count">{index + 1}</span>
                    <TextWrap data-component="TextWrap">
                      <span class="dimension-count">{item.dimension}</span>
                      <div className="sm-text">
                        <StatusTab data-component="StatusTab" status={item.status}>
                          {item.status === 'todo' ? 'To Do' : item.status === 'inprogress' ? 'IN PROGRESS' : item.status === 'validated' ? 'VALIDATED' : ''}
                        </StatusTab>
                      </div>
                      <span className="dimension-name md-text">{item.section_name}</span>
                    </TextWrap>
                  </CardLeftSec>
                  <CardRightSec data-component="CardRightSec">
                    <StatusTab data-component="StatusTab" status={item.status} className="md-text">
                      {item.status === 'todo' ? 'To Do' : item.status === 'inprogress' ? 'IN PROGRESS' : item.status === 'validated' ? 'VALIDATED' : ''}
                    </StatusTab>
                    <div className="sm-text">
                      <span className="dimension-name">{item.section_name}</span>
                    </div>
                    <button className="icon-svg md-text" dangerouslySetInnerHTML={{ __html: arrowIcon }}></button>
                  </CardRightSec>
                </SiriCard>
              ))}
            </SiriCardWrap>
          </SiriAsmtLeftWrap>
          <SiriAsmtRightWrap data-component="SiriAsmtRightWrap">
            <Link to={`/company-detail/${slug}`} className="company-profile-link">Company profile</Link>
            <TopProgressBar data-component="TopProgressBar">
              <ProgressBarSection data-component="ProgressBarSection">
                <HeaderProgress data-component="HeaderProgress">
                  <p class="progress-title">Overall Progress:</p>
                  <h2>{progress}%</h2>
                  <HeaderProgressBar data-component="HeaderProgressBar">
                    <ProgressBar data-component="ProgressBar" value={progress}></ProgressBar>
                  </HeaderProgressBar>
                </HeaderProgress>
              </ProgressBarSection>
              <SubmitSection data-component="SubmitSection">
                <span class="heading">Dimensions Validated: </span>
                <div class="count">{completed} / 16</div>
              </SubmitSection>
            </TopProgressBar>
            {completed==16 ?
              <AssessmentSubmissionSection data-component="AssessmentSubmissionSection" active>
                <AsmtTopSec data-component="AsmtTopSec">
                  <span class="progress-text">Download</span>
                </AsmtTopSec>
                <AsmtBottomSec data-component="AsmtBottomSec">
                  <p>All the dimensions are validated, click the "Download Validate Table" button to download the validation in excel format.</p>
                </AsmtBottomSec>
                <ButtonSec data-component="ButtonSec">
                  <button class="download-btn" onClick={downloaDdata}>Download Validate Table</button>
                </ButtonSec>
              </AssessmentSubmissionSection>
              :
              ''
            }
          </SiriAsmtRightWrap>
        </SiriAssessementWrapper>
      </SiriMainWrapper>
    </>
  );
};


