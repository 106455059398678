import styled from 'styled-components'

export const PopupContainer = styled.div`
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    right:0;
    z-index:999;
    display:flex;
    align-items:center;
    justify-content:center;

`

export const PopupCard = styled.div`
    width: 36.8rem;
    max-width: 36.8rem;
    background:#fff;
    height: 100%;
    border-radius: 0 2.4rem 2.4rem 0;
    padding-bottom: 2rem;
    border: 1px solid #E5F0FF;
    display:flex;
    flex-direction:column;
    position: relative;
`
export const PopupCardHeader = styled.div`
    padding: 1.6rem 1.6rem 1.8rem 1.6rem;
    display: flex;
    flex-direction: column;
`
export const PopupCardBody = styled.div`
    flex: 1;
    overflow: auto;
    padding: 1.6rem;
    position: relative;
    scroll-behavior: smooth;
`
export const HeaderButtonsWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.4rem;
`
export const PopupCloseButton = styled.button`
    line-height:0;
    i{
        display:inline-block;
    } 
`
export const HeaderH1 = styled.div`
    color:#0D2C54;
    font-size: 1.8rem;
    font-weight: 600;
    flex:1;
    dispaly:flex;
`
export const ReasonCodeBox = styled.div`
    border-radius: 0.8rem;
    background: #E5F0FF;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.8rem;
    .heading{
        color: #0D2C54;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: normal;
    }
    .title{
        color: #0D2C54;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2rem; /* 166.667% */
    }
`
export const CommentContainer = styled.div``
export const CommentWrap = styled.div`
    padding-bottom: 2rem;
`
export const CommentRow = styled.div`
    display: flex;
    gap: 0.8rem;
`
export const CommentNameCol = styled.div`
    .user-name{
        display: flex;
        height: 2.4rem;
        width: 2.4rem;
        color: #FFF;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        line-height: 0; /* 200% */
        background-color: #118acb;
        text-transform: uppercase;
        border-radius: 50%;
    }
`
export const CommentCol = styled.div`
    flex: 1;
    .text{
        border-radius: 0px 0.8rem 0.8rem 0.8rem;
        background: #F7F7F7;
        padding: 0.8rem;
        p{
            color: #4D4D4F;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 2rem; /* 166.667% */
        }
    }
`
export const PopCardFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1.6rem;
    .cmnt-btn{
        border-radius: 0.4rem;
        background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
        box-shadow: 0px 0.4rem 0.4rem 0px rgba(77, 77, 79, 0.25);
        padding: 1.2rem 2.4rem;
        color: #FFF;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
    }
`

export const TextAreaWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.8rem;
    width: 100%;
    textarea{
        border-radius: 0.4rem;
        border: 0.06rem solid #A6A7AD;
        color: #4d4d4f;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: normal;
        padding: 0.8rem;
        min-height: 5.6rem;
        font-family: Inter;
        resize: none;
        width: 100%;
        &:placeholder{
            color: #A6A7AD;
            font-size: 1.4rem;
        }
    }
    .post-btn{
        border-radius: 0.4rem;
        background: #E6E6E6;
        color: #A6A7AD;
        font-size: 1.4rem;
        font-weight: 500;
        min-height: 2.5rem;
        min-width: 6.3rem;
        padding: 0 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active{
            background: #E5F0FF;
            color: #118acb;
        }
    }
`
export const RightCommentWrap = styled.div`
    padding-bottom: 2rem;
    ${CommentCol}{
        .text{
            border-radius: 0.8rem 0px 0.8rem 0.8rem;
        }
    }
`
export const EditDelWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.8rem;
    margin-top: 0.4rem;
    button{
        color: #4D4D4F;
        display: block;
        border: 0;
        background: transparent;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 2rem; /* 166.667% */
    }
    span{
        display: block;
        height: 0.4rem;
        border-radius: 50%;
        width: 0.4rem;
        background: #7D868C; 
    }
`
export const DeletePopupWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const DeletePopCard = styled.div`
    border-radius: 0.6rem;
    border: 0.1rem solid #118ACB;
    background: #FFF;
    padding: 3.2rem 1.5rem;
    width: calc(100% - 1.6rem);
    box-shadow: 0 0 1.8rem rgba(77, 77, 79, 0.25);

    h2{
        color: #2C55A0;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.8rem;
        margin-bottom: 2rem;
    }
    p{
        color: #4D4D4f;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
        margin-bottom: 2.8rem;
    }
`

export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    .cancel-btn{
        display: block;
        min-width: 11.6rem;
        border-radius: 0.4rem;
        border: 0.1rem solid #DB324D;
        background: transparent;
        color: #DB324D;
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.046rem;
        min-height: 4.4rem;
    }
    .yes-btn{
        color: #fff;
        display: block;
        min-width: 12.4rem;
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.046rem;
        border-radius: 0.4rem;
        background: #DB324D;
        min-height: 4.4rem;
    }
`
