export const InfoIcon =`
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<circle cx="8" cy="8" r="7.6" stroke="#A6A7AD" class="circle" stroke-width="0.8"/>
<line x1="8" y1="6.5" x2="8" y2="11.5" stroke="#7D868C" stroke-linecap="round" class="line-i"/>
<path d="M8 4V4.5" stroke="#7D868C" stroke-linecap="round" class="line-i"/>
</svg>
`

export const tickSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_117_21199" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_117_21199)">
  <path d="M9.54961 18L3.84961 12.3L5.27461 10.875L9.54961 15.15L18.7246 5.97498L20.1496 7.39998L9.54961 18Z" fill="#43A047"/>
</g>
</svg>`