import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainWrapper = styled.section`
  padding: 0rem 3rem 0.5rem 3rem;
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  background: #fafcfd;
  @media screen and (max-width: 1200px) {
    padding: 0px 30px 5px 30px;
  }
  @media (max-width: 740px) {
    padding: 0px 15px 5px 15px;
  }
`;
export const LearningMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CenterHeader = styled.div`
  align-items: center;
  display: flex;
  height: 10rem;
  border-bottom: 0.1rem solid #e6e6e6;
  padding: 3rem 0;
  @media screen and (min-width: 1915px) {
      height: 13.5rem;
      padding: 4rem 0;
  }
  @media screen and (max-width: 1200px) {
      height: 59px;
  }

`;

export const LHeading = styled.h1`
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.9rem;
  color: #0d2c54;
  @media screen and (min-width: 1915px) {
    font-size: 4.2rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 22px;
  }
 
`;

export const LearningContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  @media screen and (max-width: 743px) {
    flex-direction: column;
  }
`;

export const LeftLearningWrapper = styled.div`
  width: 100%;
  height: 100%;
  width: calc(100% - 45rem);
  padding-right: 3rem;
  @media screen and (min-width: 1915px) {
    width: calc(100% - 51rem);
  }
  @media screen and (max-width: 1200px) {
    width: calc(100% - 279px);
    padding-right: 29px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 19px;
    width: calc(100% - 244px);
  }
  @media screen and (max-width: 743px) {
    width: 100%;
  }
`;

export const LeftHeader = styled.header`
  padding-top: 3rem;
  border-bottom: 0.1rem solid #E5F0FF;
  padding-bottom: 1.2rem;
  display: flex;
  @media screen and (min-width: 1915px) {
      padding-top: 4rem;
      padding-bottom: 1.7rem;
      height: 8.3rem;
  }
  @media screen and (max-width: 1500px) {
    flex-wrap: wrap;
    gap:20px;
    justify-content: flex-start !important;
  }
  @media screen and (max-width: 1200px) {
    padding-bottom: 12px;
    padding-top: 30px;
  }

  @media screen and (max-width: 1023px) {
    position:relative;
    isolation:isolate;
    &:after{
      content:'';
      display:block;
      position:absolute;
      width:100%;
      border-bottom: 0.1rem solid #E5F0FF;
      top:61px;
      left:0px;
      z-index:-1;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 768px){
    display: flex;
    justify-content: space-between;
  }
`;

export const ArticleTab = styled.button`
  margin-right: 2.2rem;
  @media screen and (min-width: 1915px) {
    margin-right: 3.4rem;
   }
  @media screen and (max-width: 1200px) {
    margin-right: 15px;
  }
  @media screen and (max-width: 1024px) {
    margin-right: 23px;
    flex-wrap: wrap;
  } 
  @media screen and (max-width: 900px) {
    margin-right: 9px;
  }
  &:last-child{
    margin-right: 0px;
  }
  span {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.9rem;
    color: #7D868C;
    position: relative;
    padding: 0 1rem 1.2rem 1rem;
    @media screen and (min-width: 1400px){
      font-size: 1.2rem;
    }
    @media screen and (min-width: 1915px) {
      font-size: 1.6rem;
      padding-bottom: 1.7rem;
    }
    @media screen and (max-width: 1208px) {
      font-size: 12px;
      padding: 0 5px 12px 5px;
      line-height: 19px;
    }
    @media screen and (max-width: 1128px) {
      font-size: 13px;
      padding: 0 3px 12px 3px;
    }
    @media screen and (max-width: 1100px){
      font-size: 12px;
      padding-bottom: 14px;
    }
   @media screen and (max-width: 446px) {
      font-size: 10px;
      padding-bottom: 16px;
   }
 }

  &.active {
    cursor: default;
    span {
      color: #0D2C54;
      &::after {
        content: " ";
        width: 100%;
        height: 0.1rem;
        top: 100%;
        left: 0;
        background-color:  #0D2C54;
        position: absolute;
      }
    }
    &:hover{
      span{
        color: #0d2c54;
        &::after {
          background-color: #0d2c54;
        }
      }
     
    }
  }
  &:hover {
      span {
        color: #118acb;
        &::after {
          content: " ";
        width: 100%;
        height: 0.1rem;
        top: 100%;
        left: 0;
        background-color: #118acb;
        position: absolute;
        }
      }
    }
`;
export const MethologyTab = styled.div`
  display: ${({ open }) => (open ? "" : "none")};
`;

export const IndustryStudiesTab = styled.div`
  display: ${({ open }) => (open ? "" : "none")};
`;

export const WebcastTab = styled.div`
  display: ${({ open }) => (open ? "" : "none")};
`;
export const UnitarTab = styled.div`
  display: ${({ open }) => (open ? "" : "none")};
`
export const InfographicsTab = styled.div`
  display: ${({ open }) => (open ? "" : "none")};
`

export const LearnCardContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  @media screen and (max-width: 1200px) {
    margin-top: 10px;
    margin-left: -15px;
    margin-right: -15px;
  }
`;

// export const LearnCardCol = styled.div`
//   width: 50%;
//   padding: 1.5rem;
//   @media screen and (max-width: 1200px) {
//     padding: 10px 15px;
//   }
//   @media screen and (max-width: 1125px) {
//     width: 100%;
//     padding: 10px 40px 10px 15px;
//   }
//   @media screen and (max-width: 740px) {
//     padding: 10px 15px;
//   }

// `;
export const LearnCardCol = styled.div`
  width: 50%;
  padding: 1.5rem;
  @media screen and (max-width: 1200px) {
    padding: 10px 15px;
  }
  @media screen and (max-width: 1125px) {
    width: 100%;
  }
  .learn-card{
    background: #ffffff;
    border: 0.06rem solid #f0f0f0;
    border-radius: 0.6rem;
    width: 100%;
    min-height: 8.9rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    transition: 0.25s ease-in;
    &:hover {
      background: #ffffff;
      border-color: #3D90DD; 
      box-shadow: 0 0 1.8rem rgba(77, 77, 79, 0.25);
      transition: 0.25s ease-in;
    }
    .card_img {
        width: 82px;
        height: 64px;
        @media screen and (min-width: 1915px) {
          width: 12.8rem;
          height: 10rem;
      }
        @media screen and (max-width: 1200px) {
          width: 60px;
          height: 46px;

        }
        i{
          display: inline-block;
          height: 100%;
          width: 100%;
          @media screen and (min-width: 1915px) {
            width: 12.8rem;
            height: 10rem;
          }
          @media screen and (max-width: 1200px) {
            width: 60px;
            height: 46px;
  
          }
          svg{
            height: 100%;
            width: 100%;
          }
        }
      img {
        @media screen and (min-width: 1915px) {
          width: 12.8rem;
          height: 10rem;
        }
        @media screen and (max-width: 1200px) {
          width: 60px;
          height: 46px;
        }
      }
    }
    @media screen and (min-width: 1915px) {
        min-height: 12rem;
    }
    @media screen and (max-width: 1200px) {
      min-height: 67px;
      padding: 10px ;
    }
  }
`;
export const WebcastButton = styled.button`
   background: #ffffff;
  border: 0.1rem solid #f0f0f0;
  border-radius: 0.6rem;
  width: 100%;
  min-height: 8.9rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  transition: 0.25s ease-in;
  &:hover {
    background: #ffffff;
    border: 0.1rem solid #118acb;
    border-radius: 0.6rem;
    box-shadow: 0rem 0rem 1.8rem rgba(77, 77, 79, 0.25);
    transition: 0.25s ease-in;
  }
  .card_img {
      width: 82px;
      height: 64px;
      @media screen and (min-width: 1915px) {
        width: 12.8rem;
        height: 10rem;
     }
      @media screen and (max-width: 1200px) {
        width: 60px;
        height: 46px;

      }
    img {
      @media screen and (min-width: 1915px) {
        width: 12.8rem;
        height: 10rem;
     }
      @media screen and (max-width: 1200px) {
        width: 60px;
        height: 46px;

      }
     
    }
  }
  @media screen and (min-width: 1915px) {
      min-height: 12rem;
  }
  @media screen and (max-width: 1200px) {
     min-height: 67px;
     padding: 10px ;
  }

`;
export const LearnCard = styled(Link)`
  background: #ffffff;
  border: 0.1rem solid #f0f0f0;
  border-radius: 0.6rem;
  width: 100%;
  min-height: 8.9rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  transition: 0.25s ease-in;
  &:hover {
    background: #ffffff;
    border: 0.1rem solid #118acb;
    border-radius: 0.6rem;
    box-shadow: 0rem 0rem 1.8rem rgba(77, 77, 79, 0.25);
    transition: 0.25s ease-in;
  }
  .card_img {
      width: 82px;
      height: 64px;
      @media screen and (min-width: 1915px) {
        width: 12.8rem;
        height: 10rem;
     }
      @media screen and (max-width: 1200px) {
        width: 60px;
        height: 46px;

      }
    img {
      @media screen and (min-width: 1915px) {
        width: 12.8rem;
        height: 10rem;
     }
      @media screen and (max-width: 1200px) {
        width: 60px;
        height: 46px;

      }
     
    }
  }
  @media screen and (min-width: 1915px) {
      min-height: 12rem;
  }
  @media screen and (max-width: 1200px) {
     min-height: 67px;
     padding: 10px ;
  }
`;

export const LCardDetails = styled.div`
  padding-left: 2rem;
  @media screen and (min-width: 1915px) {
    padding-left: 2.6rem;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 10px;
    }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 130%;
    color: #4d4d4f;
    padding-bottom: 0.8rem;
    @media screen and (min-width: 1915px) {
      font-size: 2rem;
      padding-bottom: 1.3rem;
   }
    @media screen and (max-width: 1200px) {
      font-size: 12px;
      padding-bottom: 6px;
      font-weight: 500;
    }
  }
  p {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 130%;
    color: #4d4d4f;
    &.cpg-txt {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.2rem;
      color: #4d4d4f;
      text-align: left;
      @media screen and (min-width: 1915px) {
      font-size: 1.8rem;
      line-height: 2.4rem;
   }
      @media screen and (max-width: 1200px) {
      font-size: 12px;
      }
    }
    @media screen and (min-width: 1915px) {
      font-size: 1.6rem;
   }
    @media screen and (max-width: 1200px) {
      font-size: 10px;
    }
  }
`;

export const RightLearningWrapper = styled.div`
  width: 45rem;
  border-left: 0.1rem solid #e6e6e6;
  padding-left: 3rem;
  @media screen and (min-width: 1915px) {
    width: 60rem;
    padding-left: 5rem;
  }
  @media screen and (max-width: 1200px) {
    width: 348px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    width: 279px;
  }
  @media screen and (max-width: 743px) {
    border-left: none;
    width: 100%;
    padding-left: 0px;
  }
`;
export const RightHeader = styled.div`
  padding-top: 3rem;
  border-bottom: 0.1rem solid #E5F0FF;
  padding-bottom: 1.2rem;
  cursor: default;
  @media screen and (min-width: 1915px) {
      padding-top: 4rem;
      padding-bottom: 1.7rem;
      height: 8.3rem;
  }
  @media screen and (max-width: 1200px) {
    padding-top: 30px;
    padding-bottom: 12px;
  }
  span {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #0d2c54;
    position: relative;
    padding: 0 1rem 1.2rem 1rem;
    @media screen and (min-width: 1915px) {
      font-size: 2.1rem;
      padding-bottom: 1.7rem;
  }
    @media screen and (max-width: 1200px) {
      font-size: 16px;
      padding: 0 10px 12px 10px;
      line-height: 19px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    &::after {
      content: " ";
      width: 100%;
      height: 0.1rem;
      top: 100%;
      left: 0;
      background-color: #0d2c54;
      position: absolute;
      @media screen and (max-width: 1024px) {
        height: 1px;
    }
    }
  }
  &.active{
    span {
      color: #118acb;
      &::after {
        background-color: #118acb;
      }
    }
  }
  /* &:hover {
    span {
      color: #118acb;
      &::after {
        background-color: #118acb;
      }
    }
  } */
  
`;

export const RightCardContainer = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1915px) {
    padding-top: 5rem;
  }
  @media screen and (max-width: 1200px) {
    padding-top: 30px;
  }
`;
export const PlatformCardCol = styled.div`
  padding-bottom: 3rem;
  margin-right: -1.5rem;
  @media screen and (min-width: 1915px) {
      padding-bottom: 5rem;
  }
  @media screen and (max-width: 1200px) {
    padding-bottom: 30px;
   margin-right: -15px;
  }
`;

export const PlatformDetail = styled.div`
  padding-left: 2rem;
  max-width: 100%;
  @media screen and (min-width: 1915px) {
    padding-left: 3rem;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 10px;
    }
  h1 {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #4d4d4f;
    margin-bottom: 1rem;
    display: inline-block;
    border-bottom: 0.1rem solid transparent;
    &.loss-text{
      margin-right: 20px;
      @media screen and (min-width: 1915px) {
        margin-right: 30px;
    }
    }
    @media screen and (min-width: 1915px) {
      font-size: 2.1rem;
      margin-bottom: 1.3rem;
    }
    @media screen and (max-width: 1200px) {
      font-size: 14px;
      margin-bottom: 6px ;
      line-height: 20px;
    }
  }
  p {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 130%;
    color: #7d868c;
    text-align: left;
    @media screen and (min-width: 1915px) {
      font-size: 1.6rem;
    }
    @media screen and (max-width: 1200px) {
      font-size: 10px;
    }
   
  }
`;

export const PlatformCard = styled(Link)`
  display: flex;
  align-items: center;
  //padding: 1rem;
  border-radius: 0.6rem;
  width: 100%;
  .pcard-img {
    @media screen and (min-width: 1915px) {
          width: 10rem;
          height: 10rem;
      }
    img {
      border-radius: 50%;
      border: 0.1rem solid transparent;
      width: 80px;
        height: 80px;
        @media screen and (min-width: 1915px) {
          width: 10rem;
          height: 10rem;
      }
      @media screen and (max-width: 1200px) {
        width: 60px;
        height: 60px;
      }
      @media screen and (max-width: 1024px) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &:hover {
    background: #fafcfd;
    border-radius: 0.6rem;
    .pcard-img {
      img {
        background: #e5f5f1;
        border: 0.1rem solid #118ACB;
        transition: 0.25s ease-in;
        &.smart-img{
          border: 0.1rem solid #8686DF;
        }
        &.training-img{
          border: 0.1rem solid #45B097;
        }
      }
    }
    ${PlatformDetail} {
      h1 {
        color: #118acb;
       border-bottom: 0.1rem solid #118ACB;
        transition: 0.25s ease-in;
      }
      p {
        color: #118acb;
      }
    }
  }
`;

/*---------- Free Consulation Button for Educate Training ---------------*/
export const LeftLearningHeaderScroll = styled.div`
   
`
export const FreeCounsulationButton = styled.button`
    background: linear-gradient(91.83deg, #139BD6 12.22%, #0789CF 100.41%);
    border-radius: 3rem;
    width: 20rem;
    height: 4.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    display: none;
    @media screen and (max-width: 1200px) {
        width: 164px;
        height: 36px;
      }
    span{
      font-weight: 600;
      font-size: 1.4rem;
     // line-height: 150%;
      color: #FFFFFF;
      @media screen and (max-width: 1200px) {
        font-size: 12px;
      }
     
    }
`;
export const CaseStudyRow = styled.section`
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
`;
export const CaseStudyImg = styled.div`
 background: #E5F0FF;
 border-radius: 0.4rem;
 margin-bottom: 1.6rem;
 position: relative;
 height: 10rem;
 display: flex;
 align-items: center;
 justify-content: center;
 img{
    width: 100%;
    max-height: 80%;
    display: block;
 }
`;
export const CaseStudyCol = styled.section`
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    width: 21.5rem;
    &:last-child{
      
     }
`;
export const CaseStudyText = styled.p`
    color: #4D4D4F;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
`;
export const CaseStudyCard = styled.section`
    cursor: pointer;
    padding: 0.6rem 0.6rem 1.6rem 0.6rem;
    &:hover{
        border-radius: 0.4rem;
        background: #ffffff;
        ${CaseStudyText}{
            color: #118ACB;
        }
    }
`;
export const CaseStudyTab = styled.section`
  display: ${({ open }) => (open ? "" : "none")};
`;
export const PlayBtn = styled.section`
    position: absolute;
    bottom: 0.8rem;
    left: 0.8rem;
    width: 4rem;
    height: 4rem;
    .play-btn{
        display: block;
        height: 100%;
        width: 100%;
        filter: drop-shadow(1px 0px 2px rgba(0, 0, 0, 0.25));
        svg{
            height: 100%;
            width: 100%;      
        }
    }
`;
export const PlatformTrainingsTab = styled.section`
  display: ${({ open }) => (open ? "" : "none")};
`;


export const CaseStudyVideoPopup = styled.div`
.case-study-vid-popup-wrapper {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(125, 134, 140, 0.3);
	backdrop-filter: blur(2px);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: 0.25s ease-in;
  
	&.active {
	  visibility: visible;
	  opacity: 1;
	  transition: 0.25s ease-in;
	}
  
	.case-study-popup-card {
	  width: 75.6rem;
	  background: #fff;
	  min-height: 30.8rem;
	  border: 0.1rem solid #e6e6e6;
	  box-sizing: border-box;
	  border-radius: 0.5rem;
	  padding: 1.4rem 2rem 2.6rem 2rem;
	  position: relative;
	  display: flex;
	  flex-direction: column;
    @media(max-width: 740px){
      padding: 10px;
    }
  
	  .case-study-popup-header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 1.4rem;
  
		.div-no {
		  width: 4.2rem;
		  height: 4rem;
		  background: rgba(17, 138, 203, 0.05);
		  border-radius: 0.5rem;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  font-weight: 500;
		  font-size: 2rem;
		  color: #118acb;
		  margin-right: 1.6rem;
		}
  
		.div-share-btn {
		  height: 4rem;
		  flex: 1;
		  border-radius: 0px 3rem 3rem 0px;
		  background: linear-gradient(
			260deg,
			rgba(17, 138, 203, 1) 0%,
			rgba(17, 138, 203, 0.03) 95%
		  );
		  display: flex;
		  align-items: center;
		  justify-content: space-between;
		  padding: 0rem 0.6rem 0rem 1.4rem;
		  font-weight: 600;
		  font-size: 1.6rem;
		  line-height: 2.2rem;
		  color:#0D2C54;
		  position: relative;
		  z-index: 0;
      @media(max-width: 740px){
        font-size: 12px;
      }
		  .popup-title {
			flex: 1;
			padding-right: 1.5rem;
			position: relative;
			z-index: 1;
		  }
  
		  .btn-share-vid {
			background: #118acb;
			border-radius: 5rem;
			font-weight: 500;
			color: #fff;
			font-size: 1.4rem;
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			min-width: 9.6rem;
			min-height: 2.8rem;
			padding: 0.5rem 1rem 0.5rem 1.2rem;
			transition: 0.25s ease-in;
			position: relative;
			z-index: 1;
			&:hover {
			  transition: 0.25s ease-in;
			  box-shadow: inset 0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
			}
		  }
		  &::before {
			position: absolute;
			content: "";
			z-index: 0;
			height: calc(100% - 0.2rem);
			width: calc(100% - 0.2rem);
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			background: #fff;
			pointer-events: none;
			border-radius: inherit;
		  }
		  &::after {
			position: absolute;
			content: "";
			z-index: 0;
			height: calc(100% - 0.2rem);
			width: calc(100% - 0.2rem);
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			background:linear-gradient( 270deg, rgba(17,138,203,0.14) 100%, rgba(17,138,203,0.03) 100% );
			pointer-events: none;
			border-radius: inherit;
		  }
		}
  
		.btn-close-popup {
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  width: 4rem;
		  height: 4rem;
		  border-radius: 50%;
		  background: rgba(17, 138, 203, 0.05);
		  margin-left: 1.6rem;
		  border: 0.1rem solid #118acb;
		  transition: 0.25s ease-in;
		  line-height: 0;
      background: rgba(17, 138, 203, 0.05);
		  svg {
			width: 1.6rem;
  
			path {
			  transition: 0.25s ease-in;
			}
		  }
  
		  &:hover {
			border-color: #118acb;
			transition: 0.25s ease-in;
  
			svg path {
			  transition: 0.25s ease-in;
			  fill: #118acb;
			}
		  }
		}
	  }
  
	  .case-study-popup-content {
		flex: 1;
		.plyr--pip-supported [data-plyr="pip"] {
		  display: none !important;
		}
		//changes related to remove border
		.plyr__video-wrapper{
		  background: #ffffff !important;
		}
		.plyr--stopped{
		  .plyr__video-wrapper{
			background: #000 !important;
		  }
		}
	   
	  }
  
	  .case-study-popup-footer {
		display: flex;
		justify-content: space-between;
		padding-top: 2.6rem;
  
		.popup-footer-btn {
		  min-width: 12.1rem;
		  min-height: 3.9rem;
		  padding: 1rem;
		  border-radius: 0.5rem;
		  font-weight: 600;
		  font-size: 1.6rem;
		  color: #118acb;
		  background: #e5f0ff;
		  transition: 0.25s ease-in;
  
		  &:hover {
			transition: 0.25s ease-in;
			background: #d3e6ff;
		  }
  
		  &.skip {
			margin-left: auto;
		  }
		}
	  }
  
	  .popup-share-all {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(210, 210, 210, 0.34);
		border: 0.1rem solid #e6e6e6;
		box-sizing: border-box;
		backdrop-filter: blur(6px);
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		visibility: hidden;
		opacity: 0;
		transition: 0.25s ease-in;
  
		&.active {
		  visibility: visible;
		  opacity: 1;
		  transition: 0.25s ease-in;
		}
  
		.popup-share-card {
		  padding: 2.3rem 2.9rem 2.3rem 2.3rem;
		  background: #fff;
		  border-radius: 0.5rem;
		  width: 55.8rem;
		  min-height: 10rem;
  
		  .popup-share-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: 600;
			font-size: 1.8rem;
			color: #0d2c54;
  
			.close-share-popup {
			  svg {
				width: 1.8rem;
				height: 1.8rem;
			  }
			}
		  }
  
		  .popup-share-content {
			padding: 2.5rem 0;
  
			.social-share-btn {
			  margin: 0 1rem;
  
			  img {
				width: 6.4rem;
			  }
			}
		  }
  
		  .popup-share-footer {
			position: relative;
  
			.copy-link-btn {
			  display: flex;
			  width: 100%;
			  padding: 1.3rem 2.1rem;
			  background: #f9f9f9;
			  border: 0.1rem solid #e7ebf8;
			  box-sizing: border-box;
			  border-radius: 0.4rem;
  
			  span {
				text-transform: uppercase;
				color: #2c55a0;
				margin-left: 1.6rem;
				font-weight: 600;
				font-size: 1.6rem;
			  }
  
			  .txt-copy-link {
				border: 0;
				outline: 0;
				flex: 1;
				font-weight: 400;
				font-size: 1.6rem;
				color: #0d2c54;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				background: transparent;
				pointer-events: none;
			  }
  
			  &.copied {
				~ .copied-text {
				  transform: translateY(-100%) scale(1);
				  visibility: visible;
				  opacity: 1;
				  top: -2rem;
				  transition: 0.2s ease-in;
				}
			  }
			}
  
			.copied-text {
			  position: absolute;
			  color: #118acb;
			  font-weight: 500;
			  right: 0;
			  font-size: 1.4rem;
			  display: inline-block;
			  box-shadow: 0px 0.4rem 1.8rem rgba(21, 27, 38, 0.12);
			  padding: 0.8rem 1rem;
			  background: #fff;
			  border-radius: 0.4rem;
			  transform: translateY(-100%);
			  transform: scale(0.7);
			  visibility: hidden;
			  opacity: 0;
			  top: -1rem;
			  transition: 0.2s ease-in;
  
			  &:after {
				content: "";
				display: block;
				position: absolute;
				width: 0rem;
				height: 0rem;
				background: #fff;
				bottom: 0;
				border: 8px solid transparent;
				border-bottom-color: #fff;
				border-left-color: #fff;
				left: 50%;
				transform: translate(-50%, 10%) rotate(-45deg);
				z-index: -1;
			  }
			}
		  }
		}
	  }
	}
  }
`
// Case Study FAQ

export const FaqHead = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  gap:1.6rem;
  margin-top: 1.6rem;
 >h2{
    font-weight: 500;
    font-size: 1.6rem;
    color: #4D4D4F;
    flex:1;
    @media(max-width: 740px){
      flex: unset;
    }
  }
`;

export const FaqSearchWrapper = styled.div`
  width:30.9rem;
  position:relative;
  @media(max-width: 740px){
    flex: 1;
  }
`;

export const FaqSearchInputWrapper = styled.div`
  width: 30.9rem;
  position:relative;
  @media(max-width: 740px){
    width: 100%;
  }
  input{
    width:100%;
    height:4rem;
    border-radius:0.4rem;
    background: #FFFFFF;
    border: 0.1rem solid ${({ active }) => active ? '#118ACB' : '#F0F0F0'};
    padding:0.5rem 2.5rem 0.5rem 3.6rem;
    font-size: 1.4rem;
    outline:0;
    color:#4D4D4F;
    &::placeholder:{
        color: #7D868C;
    }
  }
  .serach-icon{
    display:inline-block;
    line-height:0;
    position:absolute;
    top:50%;
    left:1rem;
    transform:translateY(-50%);
    svg{
        width:2rem;
        height:2rem;
    }
  }
  .close-icon{
    position:absolute;
    display:inline-block;
    top:50%;
    right:1rem;
    transform:translateY(-50%);
    cursor:pointer;
    visibility:${({ active }) => active ? 'visible' : 'hidden'};
    svg path{
      transition: 0.2s ease-in;
    }
    &:hover{
      svg path{
        fill:#4d4d4f;
      }
    }
  }
`;

export const FaqSearchResult = styled.div`
    position:absolute;
    z-index:2;
    bottom:-0.5rem;
    transform:translateY(100%);
    width:100%;
    border:0.1rem solid #ccc;
    background: #FFFFFF;
    border: 0.1rem solid #118ACB;
    border-radius: 4px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #4D4D4F;
    padding:0.2rem;
    visibility:${({ active }) => active ? 'visible' : 'hidden'};
    .result-list-wrapper{
        overflow-Y:scroll;
        max-height:30.9rem;
        padding:1rem 1.2rem 0rem 1.2rem;
    }
    p{
        margin-bottom:1.6rem;
    }
    .no_result_found{
        font-size: 1.4rem;
        margin-bottom:1.6rem;
        color:#7D868C;
    }
`;

export const FilterWrapper = styled.div`
    position:relative;
    .filter-btn{
         height:4rem;
         width:4rem;
         background: #F0F0F0;
         border: 0.1rem solid #F0F0F0;
         border-radius: 0.4rem;
         display:inline-flex;
         align-items:center;
         justify-content:center;
         transition:0.25s ease-in;
        i{
            display:inline-block;
            svg{
                width:1.8rem;
                height:1.2rem;
                path{
                    fill:#7D868C;
                    transition:0.25s ease-in;
                }
            }
        }
        &:hover{
            background: #E5F0FF;
                path{
                    fill:#118ACB !important;
                }
        }
    }
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  width:44.9rem;
  //height: calc(100vh - 31rem);
  position:absolute;
  background: #FFFFFF;
  box-shadow: 0px 0px 0.8rem rgba(21, 27, 38, 0.12);
  border-radius: 2rem 0px 0px 2rem;
  right: -3rem;
  top: -1.6rem;
  padding: 2.4rem 0;
  z-index:9;
  visibility:${({ active }) => active ? 'visible' : 'hidden'};
  opacity:${({ active }) => active ? 1 : 0};
  @media(max-width: 575px){
    border-radius: 0;
    width: 300px;
  }
`;

export const FilterBoxHeader = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  gap:2.4rem;
  padding: 0 2.4rem;
  @media(max-width: 575px){
    padding: 0 10px;
  }
  h3{
      font-weight: 600;
      font-size: 2rem;
      color: #4D4D4F;
      flex:1;
      @media(max-width: 575px){
        font-size: 16px;
      }
  }
  .clear-all{
      font-size: 1.6rem;
      color: #4D4D4F;
      @media(max-width: 575px){
        font-size: 12px;
      }
      &:hover{
          color:#118ACB;
      }
  }
  .close-btn{
      width:4rem;
      height:4rem;
      border-radius:4rem;
      background:#F0F0F0;
      display:inline-flex;
      align-items:center;
      justify-content:center;
      @media(max-width: 575px){
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
      i{
          display:inline-block;
          line-height:0;
          path{
              fill:#7D868C;
          }
      }
      &:hover{
          background:#E5F0FF;
          i path{
              fill:#118ACB;
          }
      }

  }
`;
export const FilterBoxBody = styled.div`
  margin-top: 1rem;
  padding: 0 2.4rem;
  flex: 1;
  overflow-y: scroll;
  @media(max-width: 575px){
    padding: 0 10px;
  }
`;
export const FilterCollapseTitle = styled.button`
    margin-top:1rem;
    font-weight: 600;
    font-size: 1.6rem;
    color: #4D4D4F;
    display:inline-flex;
    padding-right:2.5rem;
    position:relative;
    margin-bottom:1.6rem;
    .collapse-icon{
            position:absolute;
            display:inline-block;
            right:0;
            top:50%;
            transform:translateY(-50%);
            line-height:0;
            svg{
                width:1rem;
                height:0.6rem;
                rotate:${({ active }) => active ? '180deg' : '0deg'};
                transition:0.25s ease-in;
            }
            path{
                fill:#4D4D4F
            }
    }
    &:hover{
        color:#118ACB;
        .collapse-icon path{
            fill:#118ACB;
        }
    }
`
export const Checkboxlabel = styled.label`
  position:relative;
  cursor:pointer;
  display:inline-flex;
  gap:1.2rem;
  user-select:none;
  margin-bottom:1.4rem;
  rect,path{
      fill:transparent;
      transition:0.25s ease-in;
  }
  input{
      visibility:hidden;
      opacity:0;
      position:absolute;
      &:checked{
              ~ i {
                  rect{
                      fill:#7D868C;
                  }
                  path{
                      fill:#fff;
                  }
          }
      }
  }
  span{
      font-weight: 400;
      font-size: 1.4rem;
      color:#7D868C;
  }
`
// FAQ button

export const FaqBody = styled.div`
    padding-top: 2.4rem;
`
export const FaqWrap = styled.div`
    margin-bottom: 0.8rem;
`
export const LftQuesWrap = styled.p`
    color: #4d4d4f;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding-right:1rem;
`
export const QuesBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    background: #ffffff;
    border: 0.1rem solid #E5F0FF;
    border-radius: 0.8rem;
    &.active{
        background: rgba(17, 138, 203, 1);
        border-color: transparent;
        ${LftQuesWrap}{
            color: #ffffff;
        }
    }
`
export const CollapseBtn = styled.div`
    cursor: pointer;
    line-height: 0;
    svg path{
      transition:0.25s ease-in;
    }
    &:hover{
      svg.open-icon path{
        fill:#118acb;
      }
    }
`
export const QuesDetailWrap = styled.div`
    background: #FFFFFF;
    border: 0.06rem solid #118ACB;
    border-radius: 0.8rem;
    margin-top: 0.8rem;
    padding: 1.6rem;
`
export const QuesWrap = styled.div`
    padding-bottom: 1.6rem;
    .case-head{
        color: #4d4d4f;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .case-text{
        color: #4d4d4f;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
`
export const AnsWrap = styled.div`
    padding-bottom: 1.6rem;
    .case-head{
        color: #4d4d4f;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .case-text{
        color: #4d4d4f;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    &::last-child{
        padding-bottom: 0;
    }
`
export const CaseStudyFAQTab = styled.div`
  display: ${({ open }) => (open ? "" : "none")};
`
