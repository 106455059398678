/** imported components

  * $ from jquery ===> component from jquery to use jquery for the page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import serviceProvider from '../../services/axios'
//UserWalkthroughView component start
export const UserWalkthroughView = (props) => {
  // Defining varaibles here
  const users = JSON.parse(localStorage.getItem("userData"));
  const UserName = users ? users.name : "First Name";
  const uid = users ? users.uid : "";
  const [sliderStatus, setSliderStatus] = useState(1);
  //function to handle slider navigation
  const HandleSlider = (val) => {
    setSliderStatus(val);
  }
  //Function to close walkthorugh
  const handleClose = () => {
    let mainDiv=document.querySelector('.training-main-wrapper');
    let contentDiv=document.querySelector('.training-main-wrapper .training-content');
    if(mainDiv){
       mainDiv.classList.remove('walkthrough--working');
    }
    if(contentDiv){
       contentDiv.classList.remove('content--no-scroll');
    }
    serviceProvider
		.post(
		"/home?type=setlossusertour",
        JSON.stringify({ uid: uid, kpl:'kpl' }),true
      )
      .then((response) => {
        setSliderStatus(0);
      });
  }
  //condition to add and remove classes for walkthrough as per requirement
  useEffect(() => {
    if (sliderStatus === 1) {
      let walkStep1 = document.querySelectorAll('.walk-step-1');
      walkStep1.forEach((item) => {
          item.classList.add('div-z99');
      })
    }
    else {
      let walkStep1 = document.querySelectorAll('.walk-step-1');
      walkStep1.forEach((item) => {
          item.classList.remove('div-z99');
      })
    }
  }, [sliderStatus]);

  useEffect(() => {
      let mainDiv=document.querySelector('.training-main-wrapper');
      let contentDiv=document.querySelector('.training-main-wrapper .training-content');
      if(mainDiv){
        mainDiv.classList.add('walkthrough--working');
      }
      if(contentDiv){
        contentDiv.classList.add('content--no-scroll');
      }
      return ()=>{
                   if(mainDiv){
                      mainDiv.classList.remove('walkthrough--working');
                    }
                    if(contentDiv){
                      contentDiv.classList.remove('content--no-scroll');
                    }
              }
  },[])

  return (
    <>
      {/* link css */}
      <link rel="stylesheet" href={require('./user-walkthrough.scss').default} />
      {/* link css end*/}
      <section className="general-information-walkthrough">
        <div className="general-information-top">
          {/*---------------------  Walkthrough Wrapper  ------------------------*/}
          <div className="general-info-walkthrough">
            {sliderStatus === 1 ?
              <div className="g-walkthrough-screen1 gstep">
                {/* walkthrough wrapper start */}
                <div className="g-walkthrough-wrapper">
                  {/* walkthrough wrapper divided in two parts */}
                  <div className="g-walk-row">
                    {/* left part is containing gif image related to walkthrough step. */}
                    <div className="g-walk-col-left">
                      <img src={require("../../assets/img/loss-analysis-setup-walkthrough/loss-setup-step1.gif").default} alt="Question Step 1" />
                    </div>
                    {/* right part has 
                        heading , 
                        text and  
                        buttons navigate from one to next, next to previous and close. */}
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <h4 className="heading">Hi {UserName},</h4>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/la-admin/walk-close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <p className="txt">This is the IR4.0 Loss Analysis home page.</p>
                          <p className="txt">Here you can see all Sections, have an overview of the tasks assigned to you and monitor the overall progress.​</p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-right">
                            <button className="got-btn" onClick={handleClose}>Got it</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
          </div>
        </div>
      </section>
    </>
  );
};


