import styled from "styled-components";


export const NewSignInWrapper = styled.section`
  :root {
    @media screen and (min-width: 575px) and (max-width: 1100px) {
      --default-size: 10px;
    }
  }
  .auth__form--wrapp {
    display: flex;
    height: 100vh;
    align-items: center;
    background: #f4f6fc;
    section {
      width: 100%;
    }
    .train__reset-password-msg {
      text-align: center;

      .train__check-icon {
        max-width: 13.4rem;
        display: inline-block;
        margin-bottom: 3rem;
      }

      .train__msg1 {
        font-size: 2.5rem;
        line-height: 3.6rem;
        font-weight: 700;
        color: #4d4d4f;
        text-align: center;
      }

      .train__msg2 {
        font-weight: 500;
        color: #4d4d4f;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin-top: 2rem;

        .train__msg-lnk {
          color: #178acb;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          text-decoration-line: underline;
          margin-left: 0.3rem;
          border: none;
          background: none;
          cursor: pointer;
        }
      }
    }

    &.signup2021-06 {
      .sign-in .Signup-form .signup-box p {
        @media (min-height: 630px) {
          margin-top: 3.7rem;
          margin-bottom: 2.7rem;
        }
      }
    }
  }

  .auth__form--left {
    background-image: url(${require("../../assets/img/auth/landing-img.svg")
      .default});
    background-position: center center;
    background-size: 70%;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;

    .auth__form--logo {
      position: absolute;
      top: 4.8rem;
      left: 6.5rem;

      &.mb {
        position: relative;
      }
    }

    @media screen and (max-width: 1100px) {
      width: 0;
      flex-basis: 0% !important;
    }
  }

  .auth__form--right {
    background: #fff;
    display: flex;
    justify-content: center;
    height: 100%;
    &.scroll {
      overflow-y: auto;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }

  // new sign-up page css starts
  .signup_form_container {
    max-width: 49.2rem;
    margin: 0 auto;
    padding: 13rem 0;
    &.proceed-container {
      margin-top: -6.2rem;
    }

    .signup_form_header {
      h1 {
        color: #4d4d4f;
        text-align: center;
        font-size: 3rem;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 2rem;
      }

      p {
        color: #7d868c;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.25rem;
      }
    }

    .signup_form {
      padding-top: 3.2rem;

      .signup_tab_header {
        .signup_form_step {
          display: flex;
          align-items: center;
          padding-bottom: 1.2rem;

          .back_svg {
            cursor: pointer;
            margin-right: 1.9rem;
          }

          .step {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.6rem;
            letter-spacing: 0.02em;
            padding-right: 1.6rem;
            color: #4d4d4f;

            span {
              color: #118acb;
            }
          }

          span {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.6rem;
            letter-spacing: 0.02em;
            color: #4d4d4f;
          }
        }

        .step_progress {
          background: #e5f0ff;
          border-radius: 2rem;
          height: 0.4rem;
          width: 100%;
          position: relative;

          .step_progress_bar {
            width: 50%;
            border-radius: 2rem;
            background: #118acb;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
          }
        }
      }

      .siri_signup_box {
        padding: 3.2rem 3.2rem 0 3.2rem;

        .field_wrapper {
          position: relative;
          display: block;

          .label {
            position: absolute;
            padding: 0.3rem 0.5rem;
            left: 1.2rem;
            top: -0.8rem;
            background: #fff;
            border-radius: 0.2rem;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.5rem;
            color: #0a2265;
          }

          .input_field {
            height: 5rem;
            width: 100%;
            border: 0.1rem solid #e9e9e9;
            border-radius: 0.5rem;
            padding: 0.5rem 1.7rem;
            outline: 0;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: #4d4d4f;

            ::placeholder {
              color: #7d868c;
            }

            &.pr_30 {
              padding-right: 4rem;
            }
            &.number {
              padding-right: 3rem;
            }
          }
          .percent-label {
            position: absolute;
            top: 2rem;
            right: 1rem;
            font-weight: 500;
            font-size: 1.2rem;
          }

          svg {
            position: absolute;
            top: 50%;
            right: 2.1rem;
            transform: translateY(-50%);
            cursor: pointer;
          }
          &.site-wrapper {
            input {
              padding-right: 16rem;
            }
            .right-email-text {
              color: #4d4d4f;
              display: inline-flex;
              font-size: 1.5rem;
              font-weight: 400;
              line-height: normal;
              bottom: 1.5rem;
              position: absolute;
              right: 1.6rem;
              .alert-wrap {
                display: inline-block;
                padding-left: 0.8rem;
                .alert-icon {
                  display: inline-block;
                  height: 2rem;
                  width: 2rem;
                  svg {
                    height: 100%;
                    position: unset;
                    transform: unset;
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        .signup_form_row {
          display: flex;
          flex-wrap: wrap;
          gap: 2rem;
          /* Remove the default spinner arrows */
          input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
            appearance: textfield; /* Other browsers */
          }

          /* Remove the increment and decrement buttons in Firefox */
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Remove the increment and decrement buttons in Chrome */
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            display: none;
          }

          .signup_form_col {
            flex: 1;
            padding-bottom: 2.7rem;
            position: relative;

            .error {
              position: absolute;
              font-size: 1.2rem;
              line-height: 1.5rem;
              text-align: right;
              letter-spacing: -0.01em;
              color: #fc6444;
              display: block;
              background: transparent;
              width: 100%;
            }
            .msg {
              font-size: 1.2rem;
              line-height: 1.5rem;
              color: #606060;
            }
            .checkbox-label {
              display: flex;
              align-items: center;
              padding-top: 2rem;
              cursor: pointer;
              input {
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 0.4rem;
                border: 0.1rem solid #0d2c54;
              }
              span {
                color: #0d2c54;
                display: inline-block;
                font-size: 1.4rem;
                font-weight: 400;
                line-height: normal;
                padding-left: 1rem;
              }
            }
          }
        }

        .terms_privacy_text {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 150%;
          text-align: center;
          color: #606060;
          margin-top: 4rem;

          .siri_term {
            text-decoration: underline;
          }
        }

        .siri_form_button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 6rem;
          border-radius: 0.5rem;
          font-weight: 600;
          font-size: 1.4rem;
          color: #ffffff;
          margin-top: 2.7rem;
          background: #cbcfd2;
          &.login_btn {
            background: rgba(18, 154, 213, 1);
          }
          &.proceed-btn {
            background: rgba(18, 154, 213, 1);
          }
          &.disabled {
            background: #cbcfd2;
          }
        }
        .dont-text {
          color: #606060;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: normal;
          padding-top: 2.4rem;
          text-align: center;
          .signup-link {
            color: #178acb;
            text-decoration: underline;
          }
        }
        .forgot-psw {
          padding-top: 2.4rem;
          text-align: center;
          .forgot-link {
            color: #178acb;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
          }
        }
      }
    }
  }

  .dropdown_btn {
    position: relative;
    height: 5rem;
    width: 100%;
    border: 0.1rem solid #e9e9e9;
    border-radius: 0.5rem;
    padding: 0.5rem 1.7rem;
    outline: 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #4d4d4f;
    text-align: left;
    background: #ffffff;

    .placeholder_text {
      color: #7d868c;
    }

    .label {
      position: absolute;
      padding: 0.3rem 0.5rem;
      left: 1.2rem;
      top: -0.8rem;
      background: #fff;
      border-radius: 0.2rem;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #0a2265;
    }
    .icon_arrow_down {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .dropdown_container {
    position: relative;

    &.active {
      .dropdown_btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.top {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .dropdown_content {
        opacity: 1;
        visibility: visible;
      }
    }

    .dropdown_content {
      position: absolute;
      z-index: 2;
      background: #fff;
      width: 100%;
      max-height: 20rem;
      overflow-y: auto;
      border: 0.1rem solid #e9e9e9;
      border-top: 0;
      opacity: 0;
      visibility: hidden;

      &.top {
        top: 0;
        transform: translateY(-100%);
        border-top: 0.1rem solid #e9e9e9;
        border-bottom: 0;
      }

      li {
        cursor: pointer;
        padding: 1.1rem 1.6rem;
        font-size: 1.4rem;
        color: #4d4d4f;

        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
`;