/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * { YesNo }, { SingleSelect }, { MultiCheckbox }, { RangeSlider },{ SingleLine } ===> These all are the custom view components for answer format.
    { IncrementDecrement }, { MultiChoiceLines }, { MultiRange }

**/
import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import $ from "jquery";
import serviceProvider from '../../services/axios'
import { apiEndpoints } from '../../services/api-endpoints'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { YesNo } from "./view-yesNo";
import { SingleSelect } from "./view-singleSelect";
import { MultiCheckbox } from "./view-multiCheckbox";
import { RangeSlider } from "./view-rangeSlider";
import { IncrementDecrement } from "./view-incrementDecrement";
import { SingleLine } from "./view-singleLine";
import { MultiChoiceLines } from "./view-multiChoiceLines";
import { MultiRange } from "./view-multiRange";

// QuestionView component start
export const QuestionView = (props) => {
	const [activeRightTab, setActiveRightTab] = useState(null);
	const [clickedCardNo, setclickedCardNo] = useState(null);
	const [clickedCardText, setclickedCardText] = useState(null);
	const [errorMsg, setErrorMsg] = useState("");
	const [errorState, setErrorState] = useState(0);
	

	const quesDataItems = props.quesData;
	let history = useHistory();
	if (!quesDataItems.length) {
		return null;
	}
	const quesDataItem = props.quesData[0];
	const catData = quesDataItem.category;
	const subcatData = quesDataItem.subcategory;
	const question = quesDataItem.question;
	if (!question) {
		return null;
	}
	
	const HandleRightTab = (index) => {
		if (activeRightTab === index) {
			setActiveRightTab(null);
		} else {
			setActiveRightTab(index);
		}
	};




	const HandleClickedCardAction = (index, text) => {
		if (clickedCardNo === index) {
			setActiveRightTab(null);
			setclickedCardNo(null);
			setclickedCardText(null);
		} else if(index === 100000){	
			setActiveRightTab(null);
			setclickedCardNo(null);
			setclickedCardText(null);
		} else {
			setActiveRightTab(0);
			setclickedCardNo(index);
			setclickedCardText(text);
		}
	};
	const TabPanel = (props) => {
		const { activeRightTab, itemIdex, children } = props;
		return (
			<div key={itemIdex} style={{ display: activeRightTab === itemIdex ? "" : "none" }}>
				{children}
			</div>
		);
	};
	

	const prevAction = (e) => {
		var href = $(e.target).attr("data-href");
		var scid = $(e.target).attr("data-scid");
		var pqid = $(e.target).attr("data-pqid");
		HandleClickedCardAction(100000,'');
		history.push(href);
		props.onScid(scid);
		props.onQid(pqid);
	};
	const nextAction = (e) => {
		// console.log("nextAction");
		
		e.preventDefault();
		$(".asst-next-btn").attr("disabled", "disabled");
		setErrorMsg("");
		var haserror = 0,
			ans = "";
		var qid = $(e.target).attr("data-qid"),
			scid = $(e.target).attr("data-scid"),
			nchid = $(e.target).attr("data-nchid"),
			layout = $(e.target).attr("data-layout"),
			url = $(e.target).attr("data-href"),
			chapurl = $(e.target).attr("data-chapurl");

		// var new_url=scid+"/"+qid;
		//window.history.pushState("data","Title",new_url);

		var uid = quesDataItem.uid;
		var pid = quesDataItem.uid;
		if (layout == 1) {
			if (!$(".optselyn").hasClass("activeY")) {
				setErrorMsg("Please select an option");
				setErrorState(1);
				haserror = 1;
			} else {
				setErrorState(0);
				haserror = 0;
				ans = $(".activeY").data("val");
			}
		} else if (layout == 4) {
			if ($('input[name="singlebox"]:checked').length == 0) {
				setErrorMsg("Please select an option");
				setErrorState(1);
				haserror = 1;
			} else {
				setErrorState(0);
				haserror = 0;
				var getans = "";
				$('input[name="singlebox"]:checked').each(function () {
					var val = $(this).val();
					getans = getans + val + ",";
				});
				ans = getans.slice(0, -1);
			}
		} else if (layout == 8) {
			if ($('input[name="multibox"]:checked').length == 0) {
				setErrorMsg("Please select an option");
				setErrorState(1);
				haserror = 1;
			} else {
				setErrorState(0);
				haserror = 0;
				var getans = "";
				$('input[name="multibox"]:checked').each(function () {
					var val = $(this).val();
					getans = getans + val + ",";
				});
				ans = getans.slice(0, -1);
			}
		} else if (layout == 12) {
			setErrorState(0);
			haserror = 0;
			ans = $('input[name="range"]').val();
		} else if (layout == 15) {
		} else if (layout == 15) {
			setErrorState(0);
			haserror = 0;
			var getans = [];
			$('input[name="line_value[]"]').each(function () {
				var lineid = $(this).data("id");
				var val = $(this).val();
				getans.push({
					option_id: lineid,
					line_id: val,
				});
			});
			ans = JSON.stringify(getans);
			if (layout == 15 && qid == 622) {
				setErrorState(0);
				haserror = 0;
				var getans = [];
				$('input[name="line_value[]"]').each(function () {
					var val = parseInt($(this).val());
					//alert(val);
					var oldval = parseInt($(this).attr("old-value"));
					//alert(oldval);
					if (val > oldval) {
						setErrorMsg("You can't be in a higher OpEx level than company's maximum");
						setErrorState(1);
						haserror = 1;
					}
				});
			}
		} else if (layout == 17) {
			var totclass = 1;
			var inclass = 1;
			$(".asst-sel-card").each(function () {
				// console.log("1");
				totclass = totclass + 1;
				if (!$(this).hasClass("active")) {
					setErrorMsg("Please select an option");
					$(this).children(".asst-abt-card").addClass("asst-card-error");
				} else {
					inclass = inclass + 1;
				}
			});
			$(".ddl-mult-chk-wrapper-short.active").each(function () {
				if ($(this).find(".multi-select_field").hasClass("is-empty")) {
					inclass = inclass - 1;
					setErrorMsg("Please select Line(s)");
					$(this).parent(".multi-chk-btns-wrapper").parent(".asst-sel-card").children(".asst-abt-card").addClass("asst-card-error");
				}
			});
			if (totclass == inclass) {
				setErrorState(0);
				haserror = 0;
			} else {
				setErrorState(1);
				haserror = 1;
			}
			// console.log(totclass);
			// console.log(inclass);
			// console.log(haserror);
			if (haserror == 0) {
				var getans = [];
				$(".asst-sel-card.active").each(function () {
					var lineid = $(this).data("id");
					var val = $(this).data("val");
					getans.push({
						option_id: lineid,
						line_id: val,
					});
				});
				ans = JSON.stringify(getans);
				// console.log("yes");
			}
			// if(!$('.asst-sel-card').hasClass('active')){
			//     $('.error-box').text('Please select an option');
			//     setErrorState(1);haserror = 1;
			// }else{
			//     setErrorState(0);
			haserror = 0;
			//     var getans = [];

			// }
		} else if (layout == 18) {
			setErrorState(0);
			haserror = 0;
			var getans = [];
			$('input[name="range"]').each(function () {
				var lineid = $(this).data("id");
				var val = $(this).val();
				getans.push({
					option_id: lineid,
					line_id: val,
				});
			});
			ans = JSON.stringify(getans);
		} else {
			var totclass = 1;
			var inclass = 1;
			$(".asst-sel-card").each(function () {
				// console.log("1");
				totclass = totclass + 1;
				if (!$(this).hasClass("active")) {
					setErrorMsg("Please select an option");
					$(this).children(".asst-abt-card").addClass("asst-card-error");
				} else {
					inclass = inclass + 1;
				}
			});
			$(".ddl-mult-chk-wrapper-long.active").each(function () {
				// console.log("2");
				if ($(this).find(".multi-select_field").hasClass("is-empty")) {
					inclass = inclass - 1;
					setErrorMsg("Please select Line(s)");
					$(this).parent(".multi-chk-btns-wrapper").parent(".asst-sel-card").children(".asst-abt-card").addClass("asst-card-error");
				}
			});
			if (totclass == inclass) {
				setErrorState(0);
				haserror = 0;
			} else {
				setErrorState(1);
				haserror = 1;
			}
			// console.log(totclass);
			// console.log(inclass);
			// console.log(haserror);
			if (haserror == 0) {
				var getans = [];
				$(".asst-sel-card.active").each(function () {
					var lineid = $(this).data("id");
					var val = $(this).data("val");
					getans.push({
						option_id: lineid,
						line_id: val,
					});
				});
				ans = JSON.stringify(getans);
				// console.log("yes");
			}
		}
		// console.log(layout);
		// console.log(ans);
		// console.log(errorState);
		
		if (haserror == 1) {
			$(".asst-next-btn").removeAttr("disabled");
		}
		
		if (haserror == 0) {
			serviceProvider
				.post(
					"/assessment?type=saveanswer",
					JSON.stringify({ section_id: scid, question_id: qid, user_id: uid, plant_id: pid, option_id: ans, nchid: nchid }),
					true
				)
				.then((response) => {
					var obj = response.data;
					HandleClickedCardAction(100000,'');
					if (obj.error == "error") {
						setErrorMsg("Answer cannot be saved. Please try again.");
					} else {
						if (obj.nqid == 0 && obj.nchid == 0) {
							window.location.href = chapurl;
						} else {
							if (obj.nqid == 0) {
								props.onScid(obj.nchid);
								props.onQid($.trim(obj.ncq));
								var purl = "/assessmentlim/start/" + obj.nchid;
								var pnurl = "/assessmentlim/start/" + obj.nchid + "/" + $.trim(obj.ncq);
							} else {
								props.onScid(scid);
								props.onQid($.trim(obj.nqid));
								var purl = "/assessmentlim/start/" + scid + "/" + $.trim(obj.nqid);
								var pnurl = purl;
							}
							history.push(pnurl);
						}
					}
				});
		}
	};
	let prevarr = [];
	if (quesDataItem.getPrevChapterId.length) {
		prevarr = quesDataItem.getPrevChapterId.split("@");
	}

	// 1 - Yes/No
	// 4 - Single Select
	// 8 - Multi Checkbox
	// 12 - Range Slider
	// 15 - Increment/Decrement
	// 16 - Lines
	// 17 - Multi Choice Lines
	// 18 - Multi Range
	// http:///assessment/start/4/506 - 1
	// http:///assessment/start/4/665 - 4
	// http:///assessment/start/4/508 - 8
	// http:///assessment/start/4/621 - 15
	// http:///assessment/start/4/518 - 12
	// http:///assessment/start/35/618 - 16
	// http:///assessment/start/39/615 - 17
	// http:///assessment/start/20/673 - 18
	// onErrorMsg={setErrorMsg} onErrorState={setErrorState}
	
	let view = "";
	if (question.question_layout == 1) {
		view = (
			<YesNo
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	} else if (question.question_layout == 4) {
		view = (
			<SingleSelect
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	} else if (question.question_layout == 8) {
		view = (
			<MultiCheckbox
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	} else if (question.question_layout == 12) {
		view = (
			<RangeSlider
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	} else if (question.question_layout == 15) {
		view = (
			<IncrementDecrement
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	} else if (question.question_layout == 16) {
		view = (
			<SingleLine
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	} else if (question.question_layout == 17) {
		view = (
			<MultiChoiceLines
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	} else if (question.question_layout == 18) {
		view = (
			<MultiRange
				clickedCardNo={clickedCardNo}
				HandleClickedCardAction={HandleClickedCardAction}
				onErrorMsg={setErrorMsg}
				onErrorState={setErrorState}
				{...props}
			/>
		);
	}
	
	
	return (
		<>
			{/* Page Title */}
			<Helmet>
				<title>{quesDataItem.title} | SmarterChains</title>
				<meta name="description" content={quesDataItem.title} />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/assessment">Assessment</BreadcrumbsItem>
			<BreadcrumbsItem to={`/assessment/chapter/${catData.id}`}>{catData.category_name}</BreadcrumbsItem>
			<BreadcrumbsItem to="#">{subcatData.subcategory_name}</BreadcrumbsItem>
			{/* Question view page wrapper has been started */}
			<div className="qna-main-wrapper">
				{/* Another wrapper for question view page. Inside this wrapper we have divided page into two parts */}
				<div className="multi-frame-wrapper">
					{/* Left part is having two horizontal divisions. top-content and bottom-content. */}
					<div className="multi-left-frame">
						<div className="choose-one-content">
							{/* top-content has question and answer */}
							<div className="top-content">
								<div class="error-box" style={{ display: errorState ? "block" : "none" }}>
									{errorMsg}
								</div>
								<div className="choose-one-content">
									<div className="choose-one-question">
										<div className="cir-img-div">
											<img src={`${apiEndpoints.baseUrl}/${question.question_profile_pic}`} alt="true" />
										</div>
										<div className="text-div">{question.question}</div>
									</div>
									{view}
								</div>
							</div>
							{/* bottom-content has buttons.*/}
							<div className="bottom-content">
								{quesDataItem.pqid ? (
									<button
										data-qid={question.id}
										data-scid={quesDataItem.scid}
										data-pqid={quesDataItem.pqid}
										data-layout={question.question_layout}
										data-href={`/assessmentlim/start/${quesDataItem.scid}/${quesDataItem.pqid}`}
										class="btn-back asst-prev-btn 1"
										onClick={(e) => prevAction(e)}
									>
										Back
									</button>
								) : prevarr.length ? (
									// "<?=base_url()?>assess/quesajax/<?=$slug?>/<?=$prevarr[0]?>/<?=$prevarr[1]?>"
									<a
										data-qid={question.id}
										data-scid={prevarr[0]}
										data-pqid={prevarr[1]}
										data-layout={question.question_layout}
										data-href={`/assessmentlim/start/${prevarr[0]}/${prevarr[1]}`}
										class="btn-back asst-prev-btn 2"
										onClick={(e) => prevAction(e)}
									>
										Back
									</a>
								) : (
									<a data-href="javascript:void(0)" class="btn-back disabled">
										Back
									</a>
								)}
								<Link className="btn-all-qns" to={`/assessment/questions/${catData.id}`}>
									All Questions
								</Link>
								<button
									data-qid={question.id}
									data-scid={quesDataItem.scid}
									data-nchid={quesDataItem.getNextChapterId}
									data-layout={question.question_layout}
									data-href={`/assessment/chapter`}
									data-chapurl={`/assessment/chapter/${catData.id}`}
									className="btn-next"
									onClick={(e) => nextAction(e)}
								>
									Next
								</button>
							</div>
							{/* bottom-content end */}
						</div>
					</div>
					{/* Right part is having question and their explaination */}
					<div className="multi-right-frame">
						<div className="muti-right-card">
							<div className="card-header" style={{ visibility: activeRightTab === null ? "hidden" : "" }}>
								<button className={activeRightTab == 0 ? "btn-tab active" : "btn-tab"}>Description</button>
								{/* <button className={activeRightTab == 1 ? "btn-tab active" : "btn-tab"} onClick={() => HandleRightTab(1)}>
									Pictures
								</button>
								<button className={activeRightTab == 2 ? "btn-tab active" : "btn-tab"} onClick={() => HandleRightTab(2)}>
									Videos
								</button>
								<button className={activeRightTab == 3 ? "btn-tab active" : "btn-tab"} onClick={() => HandleRightTab(3)}>
									Vendors
								</button> */}
							</div>
							{/* card-header end */}
							<div className={activeRightTab === null ? "card-content mr0" : "card-content"}>
								<TabPanel itemIdex={null} activeRightTab={activeRightTab}>
									<div className="default-tab">
										<p>
											If you have any questions, please press the icon info
											<img
												src={require("../../assets/img/multi-choice/non-active-info.svg").default}
												className="i-icon"
												alt="true"
											/>
										</p>
										<img className="bg" src={require("../../assets/img/multi-choice/tab-default.svg").default} />
										<div className="border-linedd"></div>
									</div>
								</TabPanel>
								<TabPanel itemIdex={0} activeRightTab={activeRightTab}>
									<div className="desc-content">
										
											<p dangerouslySetInnerHTML={{ __html: clickedCardText?.replace('<br>',`<span class='q-br'></span>`) }}></p>
										
									</div>
								</TabPanel>
							</div>
							{/* card-content end */}
							
							{/* card-footer end */}
						</div>
						{/* muti-right-card end */}
					</div>
				</div>
			</div>
		</>
	);
};
