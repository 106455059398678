import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { useEffect } from "react";
import serviceProvider from '../../services/axios'
import { ModalContent, ModalCard, ModalCardHeader, ModalCardBody, PlantHeadText, TitleText, SubTitleText, PlantCardRow, PlantCardCol, TextType, PlantWithText, PlantTechCard, PlantCardColFooter, PlantTechCardWrapper, PlantCardFooterText, PlantFooterTextWrapper, PlantFooterButton, ProceedButton, InputFieldWrapper, PlantCardCol2, DropdownContainer, PopupTopText } from './addplant-style'
import { closeIcon, industryIcon, lossIcon, strategiesIcon, proceedArrow, arrowDown, backArrowLight } from "./svg";
export const AddPlantPopView = (props) => {


  const homeDataItems = props.homeData;
  if (!homeDataItems.length) {
     return null;
  }
  const homeDataItem = props.homeData[0];

  const {showAddPlantPopup, setShowAddPlantPopup} = props;
  const [selectedCombo, setSelectedCombo] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({
    plantName: '',
    country: ''
  })
  const [plantCardsArr, setPlantCardsArr] = useState(homeDataItem.comboData);
  const plantIcons={industryIcon, lossIcon, strategiesIcon};
  const selectPlantFunction = (index) => {
    let tmpArr = [...plantCardsArr];
    tmpArr?.forEach((item, ind) => {
      if (index === ind) {
        tmpArr[ind].selected = true;
      } else {
        tmpArr[ind].selected = false;
      }
    });
    setPlantCardsArr(tmpArr);
  }
  const ProceedNextFunction = () => {
    let tmpSelectedItem = plantCardsArr?.findIndex(item => item.selected === true);
    if (tmpSelectedItem > -1) {
      setSelectedCombo({
        type: tmpSelectedItem + 1,
        ...plantCardsArr[tmpSelectedItem]
      });
    }
  }
  const handleFormData = (val, name) => {
    setFormData(prv => {
      return {
        ...prv,
        [name]: val
      }
    });
  }
  // toggle select dropdown
  const toggleDropdown = (val) => {
    if (val === dropdownOpen) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(val);
    }
  }
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  const usname = user ? user.name : 0;
  const addPlantFunction = () => {
    let tmpSelectedPlant = {
      plantName: formData.plantName,
      country: formData.country,
      combo_id:selectedCombo.combo_id
    };
    let tmpArr = [...plantCardsArr];
    tmpArr?.forEach((item, ind) => {
      tmpArr[ind].selected = false;
    });
   
    serviceProvider.post(`/plants?type=addnewplant&uid=${usid}&username=${usname}`, JSON.stringify(tmpSelectedPlant),true).then((response) => {
      setPlantCardsArr(tmpArr);
      setShowAddPlantPopup(false);
      setSelectedCombo(false);
      setFormData({
        plantName: '',
        country: ''
      })
      window.location = "/home";
  });
    
    
  }
  return (
    <>
        
      <Modal
        open={showAddPlantPopup}
        // onClose={HandleUpdgradePopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="home-index-upgrade-popup"
      >
        <>
          {!selectedCombo ?
            <ModalContent data-component="MoadalContent">
              <ModalCard data-component="MoadalCard">
                <ModalCardHeader data-component="ModalCardHeader">
                  <span>Add Plant</span>
                  <button onClick={() => setShowAddPlantPopup(false)}>
                    <i dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                  </button>
                </ModalCardHeader>
                {/* ModalCardHeader end */}
                <ModalCardBody data-component="ModalCardBody">
                  <TitleText data-component="TitleText">Select the type of plant</TitleText>
                  <SubTitleText data-component="SubTitleText">You had made combo’s at the time of purchase. The same is being showcased here along with Platform Tools you had selected</SubTitleText>
                  <PlantCardRow data-component="PlantCardRow">
                    {plantCardsArr?.map((item, index) => (
                      <PlantCardCol data-component="PlantCardCol" key={index} selected={item.selected} disabled={item.available===0}>
                        <PlantHeadText data-component="PlantHeadText">
                          <TextType data-component="TextType">Type {index + 1}</TextType>
                          <PlantCardFooterText data-component="PlantCardFooterText">
                              <span className='border-text'>Available</span>
                              <b>{item.available}</b>
                          </PlantCardFooterText>
                        </PlantHeadText>
                        <PlantWithText data-component="PlantWithText">Plants with:</PlantWithText>
                        <PlantTechCardWrapper data-component="PlantTechCardWrapper">
                          {item?.plants?.map((plant, plantIndex) => (
                            <PlantTechCard data-component="PlantTechCard" key={plantIndex}>
                              <i dangerouslySetInnerHTML={{ __html: plantIcons[plant.icon] }}></i>
                              <p>{plant.name}</p>
                            </PlantTechCard>
                          ))
                          }
                        </PlantTechCardWrapper>
                        <PlantCardColFooter data-component="PlantCardColFooter">
                          <PlantFooterTextWrapper data-component="PlantFooterTextWrapper">
                            <PlantCardFooterText data-component="PlantCardFooterText">
                              <span className='border-text'>Utilised</span>
                              <b>{item.utilised}</b>
                            </PlantCardFooterText>
                            <PlantCardFooterText data-component="PlantCardFooterText">
                              <span className='border-text'>Purchased</span>
                              <b>{item.purchased}</b>
                            </PlantCardFooterText>
                          </PlantFooterTextWrapper>
                          {/* PlantFooterTextWrapper end */}
                          <PlantFooterButton data-component="PlantFooterButton" onClick={() => selectPlantFunction(index)} selected={item.selected} disabled={item.available===0}>
                            {item.selected===true?`Selected`:`Select`}
                          </PlantFooterButton>
                          {/* PlantFooterButton end */}
                        </PlantCardColFooter>
                      </PlantCardCol>
                    ))

                    }

                  </PlantCardRow>
                  {/*  palnt card row end */}
                </ModalCardBody>
                {/* ModalCardBody end */}
                {plantCardsArr?.findIndex(i => i.selected === true) >= 0 &&
                  <ProceedButton data-component="ProceedButton" onClick={ProceedNextFunction}>
                    <span>Proceed</span>
                    <i dangerouslySetInnerHTML={{ __html: proceedArrow }}></i></ProceedButton>
                }
              </ModalCard>
              {/* ModalCard end */}
            </ModalContent>
            :
            <ModalContent data-component="MoadalContent">
              <ModalCard data-component="MoadalCard">
                <ModalCardHeader data-component="ModalCardHeader">
                  <span>Add Plant</span>
                  <button onClick={() => setShowAddPlantPopup(false)}>
                    <i dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                  </button>
                </ModalCardHeader>
                {/* ModalCardHeader end */}
                <ModalCardBody data-component="ModalCardBody">
                  <PopupTopText data-component="PopupTopText">
                    <button className="back_btn" onClick={() => setSelectedCombo(false)}>
                      <i dangerouslySetInnerHTML={{ __html: backArrowLight }}></i>
                    </button>
                    <div>
                      <TitleText data-component="TitleText">Enter Plant Details</TitleText>
                      <SubTitleText data-component="SubTitleText" marginBottom={'0px'}>
                        Please enter the plant mame and it’s country
                      </SubTitleText>
                    </div>
                  </PopupTopText>
                  <PlantCardRow data-component="PlantCardRow">
                    <div>
                      <SubTitleText data-component="SubTitleText" marginBottom={'2.5rem'}><b>Plant type selectedy</b></SubTitleText>
                      <PlantCardCol data-component="PlantCardCol">
                        <TextType data-component="TextType">Type {selectedCombo.type}</TextType>
                        <PlantWithText data-component="PlantWithText">Plants with:</PlantWithText>
                        <PlantTechCardWrapper data-component="PlantTechCardWrapper">
                          {selectedCombo?.plants?.map((plant, plantIndex) => (
                            <PlantTechCard data-component="PlantTechCard" key={plantIndex}>
                              <i dangerouslySetInnerHTML={{ __html:  plantIcons[plant.icon] }}></i>
                              <p>{plant.name}</p>
                            </PlantTechCard>
                          ))
                          }
                        </PlantTechCardWrapper>
                        <PlantCardColFooter data-component="PlantCardColFooter">
                          <PlantFooterTextWrapper data-component="PlantFooterTextWrapper">
                            <PlantCardFooterText data-component="PlantCardFooterText">
                              <span className='border-text'>Utilised</span>
                              <b>{selectedCombo.utilised}</b>
                            </PlantCardFooterText>
                            <PlantCardFooterText data-component="PlantCardFooterText">
                              <span className='border-text'>Purchased</span>
                              <b>{selectedCombo.purchased}</b>
                            </PlantCardFooterText>
                          </PlantFooterTextWrapper>
                          {/* PlantFooterTextWrapper end */}
                        </PlantCardColFooter>
                      </PlantCardCol>
                      {/* PlantCardCol end */}
                    </div>
                    <PlantCardCol2>
                      <SubTitleText data-component="SubTitleText" marginBottom={'2.5rem'}><b>Plant type selectedy</b></SubTitleText>
                      <InputFieldWrapper as='label' data-component="InputFieldWrapper">
                        <span className='label'>Plant Name</span>
                        <input
                          type='text'
                          name='firstName'
                          className='input_field'
                          placeholder='Enter plant name'
                          value={formData.plantName}
                          onChange={(e) => handleFormData(e.target.value, 'plantName')}
                        />
                      </InputFieldWrapper>
                      {/*  InputFieldWrapper end */}
                      <Dropdown optionList={homeDataItem.countries}
                        title={'Country'}
                        label={'Select country'}
                        index={1}
                        value={formData.country}
                        isOpen={dropdownOpen}
                        onChange={(val) => handleFormData(val, 'country')}
                        toggleDropdown={toggleDropdown}
                      />
                    </PlantCardCol2>
                    {/* PlantCardCol2 end */}
                  </PlantCardRow>
                  {/*  palnt card row end */}
                </ModalCardBody>
                {/* ModalCardBody end */}
                {plantCardsArr?.findIndex(i => i.selected === true) >= 0 &&
                  <ProceedButton data-component="ProceedButton" disabled={!(formData.country && formData.plantName)} onClick={addPlantFunction}>
                    <span>Create plant</span>
                    <i dangerouslySetInnerHTML={{ __html: proceedArrow }}></i></ProceedButton>
                }
              </ModalCard>
              {/* ModalCard end */}
            </ModalContent>
          }
        </>
      </Modal>
    
    </>
  );
}


const Dropdown = ({ title, label, optionList, onChange, isOpen, value, index, toggleDropdown, align = 'bottom' }) => {
  const selectedOption = optionList?.find(i => i.value === value);
  const handleSelectedList = (val) => {
    toggleDropdown(false);
    onChange(val);
  }

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('[data-component="DropdownContainer"]')) {
        toggleDropdown(false);
      }
    });
  }, []);
  return (
    <DropdownContainer data-component="DropdownContainer" className={`${isOpen === index ? 'active' : ''}`}>
      <button className={`dropdown_btn ${align}`} onClick={() => toggleDropdown(index)}>
        <span className='label'>{label}</span>
        {selectedOption?.label ?
          <p>{selectedOption?.label}</p>
          :
          <p className='placeholder_text'>{title}</p>
        }
        <i className='icon_arrow_down' dangerouslySetInnerHTML={{ __html: arrowDown }} />
      </button>
      <ul className={`dropdown_content ${align}`}>
        {optionList?.map((option, ind) => (
          <li key={ind} onClick={() => handleSelectedList(option.value)}>{option.label}</li>
        ))}
      </ul>


    </DropdownContainer>
  )
}


