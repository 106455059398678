import React, { useEffect, useState } from 'react';
// import  {style} from './style.scss';
import { backArrow, hideEye, showEye, arrowDown, alertIcon } from './svg'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import {NewSignInWrapper} from './style'

export const NewSignInView = () => {
    const [step, setStep] = useState(1);
    const [percentage, setPercentage] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        job: '',
        password: '',
        confirmPassword: '',
    });

    const handleFormData = (val, name) => {
        setFormData(prv => {
            return {
                ...prv,
                [name]: val
            }
        });
    }
    // toggle select dropdown
    const toggleDropdown = (val) => {
        if (val === dropdownOpen) {
            setDropdownOpen(false);
        } else {
            setDropdownOpen(val);
        }
    }

    const checkMailValid = (email) => {
        const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailPattern.test(email);
    }
    const handlePercentageChange = (e) => {
        const value = e.target.value;
        if (value === '' || (parseFloat(value) >= 0 && parseFloat(value) <= 100)) {
          setPercentage(value);
          setIsValid(true);
        } else {
          setIsValid(false);
        }    
      };
    return (
        <>
            <NewSignInWrapper>
                <Helmet>
                    <title>Sign Up | SmarterChains</title>
                    <meta name="description" content="Sign In" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                </Helmet>
                {/* 
        */}
                <div className="auth__form--wrapp">
                    {/* Logo + hero image */}
                    <Grid item xs={12} sm={4} md={4} className="auth__form--left">
                        <div className="auth__form--logo">
                            <img src={require("../../assets/img/auth/sc-logo.svg").default} />
                        </div>
                    </Grid>
                    {/* Sign in form  */}
                    <Grid item xs={12} sm={12} md={8} className="auth__form--right scroll">
                        <section>
                            <div className='signup_form_container' style={{ display: step===3 ? 'block' : 'none' }}>
                                <div className='signup_form_header'>
                                    <h1>Welcome back</h1>
                                    <p>Set your Custom URL (dummy text)</p>
                                </div>
                                <div className='signup_form step_1'>
                                    <div className='siri_signup_box'>
                                        {/* signup_form_row div 1 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Email</span>
                                                    <input
                                                        type='email'
                                                        name='email'
                                                        className='input_field'
                                                        placeholder='Email Address'
                                                        value={formData.email}
                                                        onChange={(e) => handleFormData(e.target.value, 'email')}
                                                        onBlur={(e) => { e.target.style.borderColor = !checkMailValid(e.target.value) ? 'red' : 'green' }}
                                                    />
                                                    <label class="error">Email Address is required</label>
                                                </label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 2 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper site-wrapper red'>
                                                    <span className='label'>Your Site</span>
                                                    <input
                                                        type='text'
                                                        name='yoursite'
                                                        className='input_field'
                                                        placeholder=''
                                                        value={formData.yoursite}
                                                        onChange={(e) => handleFormData(e.target.value, 'yoursite')}
                                                    />
                                                    <span className="right-email-text">
                                                        .smarterchains.com
                                                        <span className='alert-wrap'>
                                                            <i className='alert-icon' dangerouslySetInnerHTML={{ __html: alertIcon }}></i>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label class="error">Your Site is required</label>
                                                <label className='checkbox-label' for="check-form">
                                                    <input type="checkbox" id="check-form" />
                                                    <span className='check-span'>Transfer data and from Demo Account</span>
                                                </label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 4 */}
                                        <button className='siri_form_button' onClick={() => setStep(4)}>Agree</button>
                                        <p className="terms_privacy_text">By clicking on Login, you agree to SmarterChains’ <a className='siri_term'>Terms of service</a>, <a className='siri_term'>Terms of use</a> and <a className='siri_term'>Privacy Policy</a>.</p>
                                    </div>
                                </div>
                                {/* step_1 div end */}
                            </div>
                            <div className='signup_form_container' style={{ display: step === 1 ? 'block' : 'none' }}>
                                <div className='signup_form_header'>
                                    <h1>Welcome back</h1>
                                    <p>Log in with the data you entered during your registration.</p>
                                </div>
                                <div className='signup_form step_1'>
                                    <div className='siri_signup_box'>
                                        {/* signup_form_row div 1 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Email</span>
                                                    <input
                                                        type='email'
                                                        name='email'
                                                        className='input_field'
                                                        placeholder='Email Address'
                                                        value={formData.email}
                                                        onChange={(e) => handleFormData(e.target.value, 'email')}
                                                        onBlur={(e) => { e.target.style.borderColor = !checkMailValid(e.target.value) ? 'red' : 'green' }}
                                                    />
                                                    <label class="error">Email Address is required</label>
                                                </label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 2 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Password</span>
                                                    <input
                                                        type='password'
                                                        name='password'
                                                        className='input_field'
                                                        placeholder='*******'
                                                        value={formData.yoursite}
                                                        onChange={(e) => handleFormData(e.target.value, 'password')}
                                                    />
                                                </label>
                                                <label class="error">Password is required</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 4 */}
                                        <button className='siri_form_button login_btn' onClick={() => setStep(2)}>Login</button>
                                        <div className='dont-text'>
                                            Don’t have an account?
                                             <a href="#" className='signup-link'>Sign up</a>
                                        </div>
                                        <div className='forgot-psw'>
                                            <a href="#" className='forgot-link'>Forgot password?</a>
                                        </div>
                                        <p className="terms_privacy_text">By clicking on Login, you agree to SmarterChains’ <a className='siri_term'>Terms of service</a>, <a className='siri_term'>Terms of use</a> and <a className='siri_term'>Privacy Policy</a>.</p>
                                    </div>
                                </div>
                                {/* step_1 div end */}
                            </div>
                            {/*  */}
                            <div className='signup_form_container proceed-container' style={{ display: step === 2 ? 'block' : 'none' }}>
                                <div className='signup_form step_1'>
                                    <div className='signup_form_header'>
                                        <h1>Tell us a bit about yourself</h1>
                                        <p>This helps us personalise your experience</p>
                                    </div>
                                    {/* signup_tab_header div end */}
                                    <div className='siri_signup_box'>
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Manufacturer Name</span>
                                                    <input
                                                        type='text'
                                                        name='manufacturerName'
                                                        className='input_field'
                                                        placeholder='Enter name'
                                                        value={formData.manufacturerName}
                                                        onChange={(e) => handleFormData(e.target.value, 'manufacturerName')}
                                                    />
                                                </label>
                                                <label class="error">Manufacturer Name is required</label>
                                            </div>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Company Registration</span>
                                                    <input
                                                        type='text'
                                                        name='companyRegistration'
                                                        className='input_field'
                                                        placeholder='Enter company name'
                                                        value={formData.companyRegistration}
                                                        onChange={(e) => handleFormData(e.target.value, 'companyRegistration')}
                                                    />
                                                </label>
                                                <label class="error">Company Registration is required</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 1 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <Dropdown optionList={[
                                                    {
                                                        label: 'Industry1',
                                                        value: 1
                                                    },
                                                    {
                                                        label: 'Industry2',
                                                        value: 2
                                                    },
                                                    {
                                                        label: 'Industry3',
                                                        value: 3
                                                    },
                                                    {
                                                        label: 'Industry4',
                                                        value: 4
                                                    },
                                                ]}
                                                    title={'Select Sector'}
                                                    label={'Industry Sector'}
                                                    index={1}
                                                    value={formData.industrySector}
                                                    isOpen={dropdownOpen}
                                                    onChange={(val) => handleFormData(val, 'industrySector')}
                                                    toggleDropdown={(val) => toggleDropdown(val)}
                                                />
                                                <label class="error">Industry Sector is required</label>
                                            </div>

                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Products</span>
                                                    <input
                                                        type='text'
                                                        name='products'
                                                        className='input_field'
                                                        placeholder='Enter Products'
                                                        value={formData.products}
                                                        onChange={(e) => handleFormData(e.target.value, 'products')}
                                                    />
                                                </label>
                                                <label class="error">Products is required</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 2 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Address</span>
                                                    <input
                                                        type='text'
                                                        name='address'
                                                        className='input_field'
                                                        placeholder='Enter registered office address'
                                                        value={formData.address}
                                                        onChange={(e) => handleFormData(e.target.value, 'address')}
                                                    />
                                                </label>
                                                <label class="error">Address is required</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 3 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <Dropdown optionList={[
                                                    {
                                                        label: 'Industry1',
                                                        value: 1
                                                    },
                                                    {
                                                        label: 'Industry2',
                                                        value: 2
                                                    },
                                                    {
                                                        label: 'Industry3',
                                                        value: 3
                                                    },
                                                    {
                                                        label: 'Industry4',
                                                        value: 4
                                                    },
                                                ]}
                                                    title={'Select revenue'}
                                                    label={'Revenue Size'}
                                                    index={2}
                                                    value={formData.revenueSize}
                                                    isOpen={dropdownOpen}
                                                    onChange={(val) => handleFormData(val, 'revenueSize')}
                                                    toggleDropdown={(val) => toggleDropdown(val)}
                                                />
                                                <label class="error">Revenue Size is required</label>
                                            </div>

                                            <div className='signup_form_col'>
                                                <label className='field_wrapper' for="percentageInput">
                                                    <span className='label'>% revenue on exports</span>
                                                    <input
                                                        type="number"
                                                        id="percentageInput"
                                                        value={percentage}
                                                        onChange={handlePercentageChange}
                                                        min={0}
                                                        max={100}
                                                        className='input_field number'
                                                    />
                                                    <span className='percent-label'>%</span>
                                                </label>
                                                <label class="error">% revenue on exports is required</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 4 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <Dropdown optionList={[
                                                    {
                                                        label: 'Industry1',
                                                        value: 1
                                                    },
                                                    {
                                                        label: 'Industry2',
                                                        value: 2
                                                    },
                                                    {
                                                        label: 'Industry3',
                                                        value: 3
                                                    },
                                                    {
                                                        label: 'Industry4',
                                                        value: 4
                                                    },
                                                ]}
                                                    title={'Enter number'}
                                                    label={'Number of Plants '}
                                                    index={3}
                                                    value={formData.numberOfPlants}
                                                    isOpen={dropdownOpen}
                                                    onChange={(val) => handleFormData(val, 'numberOfPlants')}
                                                    toggleDropdown={(val) => toggleDropdown(val)}
                                                    align={"top"}
                                                />
                                                <label class="error">Number of Plants is required</label>
                                            </div>

                                            <div className='signup_form_col'>
                                                <Dropdown optionList={[
                                                    {
                                                        label: 'Industry1',
                                                        value: 1
                                                    },
                                                    {
                                                        label: 'Industry2',
                                                        value: 2
                                                    },
                                                    {
                                                        label: 'Industry3',
                                                        value: 3
                                                    },
                                                    {
                                                        label: 'Industry4',
                                                        value: 4
                                                    },
                                                ]}
                                                    title={'Select'}
                                                    label={'Number of Employees'}
                                                    index={4}
                                                    value={formData.numberOfEmployees}
                                                    isOpen={dropdownOpen}
                                                    onChange={(val) => handleFormData(val, 'numberOfEmployees')}
                                                    toggleDropdown={(val) => toggleDropdown(val)}
                                                    align={"top"}
                                                />
                                                <label class="error">Number of Employees is required</label>
                                            </div>

                                        </div>
                                        {/* signup_form_row div 5 */}
                                        <button className='siri_form_button proceed-btn' onClick={() => setStep(3)}>Proceed</button>
                                    </div>
                                </div>
                            </div>
                            {/* signup_form_container div end  */}
                            <div className='signup_form_container' style={{ display: step === 4 ? 'block' : 'none' }}>
                                <div className='signup_form step_2'>
                                    <div className='signup_form_header'>
                                        <h1>Set password</h1>
                                        <p>Setup a new password here</p>
                                    </div>
                                    <div className='siri_signup_box'>
                                        {/* signup_form_row div 1 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper pre-fill-input'>
                                                    <span className='label'>Email</span>
                                                    <input
                                                        type='email'
                                                        name='email'
                                                        className='input_field'
                                                        placeholder='Email Address'
                                                        value={formData.email}
                                                        onChange={(e) => handleFormData(e.target.value, 'email')}
                                                        onBlur={(e) => { e.target.style.borderColor = !checkMailValid(e.target.value) ? 'red' : 'green' }}
                                                    />
                                                    {/* <label class="error">Email Address is required</label> */}
                                                </label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 2 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Password</span>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        name='password'
                                                        className='input_field pr_30'
                                                        placeholder='********'
                                                        value={formData.password}
                                                        onChange={(e) => handleFormData(e.target.value, 'password')}
                                                    />
                                                    <i dangerouslySetInnerHTML={{ __html: !showPassword ? hideEye : showEye }} onClick={() => setShowPassword(!showPassword)} />
                                                </label>
                                                <label class="msg">Must be at least 8 characters</label>
                                            </div>
                                        </div>
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Confirm Password</span>
                                                    <input
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        name='confirmPassword'
                                                        className='input_field pr_30'
                                                        placeholder='********'
                                                        value={formData.confirmPassword}
                                                        onChange={(e) => handleFormData(e.target.value, 'confirmPassword')}
                                                    />
                                                    <i
                                                        dangerouslySetInnerHTML={{ __html: !showConfirmPassword ? hideEye : showEye }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    />
                                                </label>
                                                <label class="msg">Both password must match.</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 4 */}
                                        <button className='siri_form_button proceed-btn'>Proceed</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Grid>
                </div>
            </NewSignInWrapper>
        </>

    )
}

const Dropdown = ({ title, label, optionList, onChange, isOpen, value, index, toggleDropdown, align = 'bottom' }) => {
    const selectedOption = optionList?.find(i => i.value === value);
    const handleSelectedList = (val) => {
        toggleDropdown(false);
        onChange(val);
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('.dropdown_container')) {
                toggleDropdown(false);
            }
        });
    }, []);
    return (
        <div className={`dropdown_container ${isOpen === index ? 'active' : ''}`}>
            <button className={`dropdown_btn ${align}`} onClick={() => toggleDropdown(index)}>
                <span className='label'>{label}</span>
                {selectedOption?.label ?
                    <p>{selectedOption?.label}</p>
                    :
                    <p className='placeholder_text'>{title}</p>
                }
                <i className='icon_arrow_down' dangerouslySetInnerHTML={{ __html: arrowDown }} />
            </button>
            <ul className={`dropdown_content ${align}`}>
                {optionList?.map((option, ind) => (
                    <li key={ind} onClick={() => handleSelectedList(option.value)}>{option.label}</li>
                ))}
            </ul>


        </div>
    )
}