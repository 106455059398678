/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
**/

import React, { Component, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Redirect, Link } from "react-router-dom";
import "./auth.scss";
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios'

export class ThankYouEamilConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: false,
    };
  }
  componentWillMount() {
    const usid = this.props.match.params.id;
    serviceProvider
      .get("/user",true, {
        params: { id: usid, param: "emailverification" },
      })
      .then((response) => {
        if (response.data != 1) {
          this.setState({ redirect: true });
        }
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    return (
      <React.Fragment>
        <Helmet>
          <title>Confirm Email | SmarterChains</title>
          <meta name="description" content="Confirm Email" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div className="auth__form--wrapp">
         {/*started left section image + logo */}
          <Grid item xs={12} sm={4} md={4} className="auth__form--left">
            <div className="auth__form--logo">
              <img src={require("../../assets/img/auth/sc-logo.svg").default} />
            </div>
          </Grid>
           {/*ended left section image + logo */}

          {/*started right section */}
          <Grid item xs={12} sm={8} md={8} className="auth__form--right">
          <section>
              <div className="auth__form-sm--logo">
                <img
                  src={require("../../assets/img/auth/sc-logo.svg").default}
                />
              </div>
            {/* submission msg main wrapper started */}
            <div className="wrapper sign-in">
              {/* Start email submission msg */}
              <div className="col-6 Signup-form forgot__pass forgot__pass__msg thank__you__msg0621">

                {/* message sent text div started */}
                <div className="message-sent">
                  <figure>
                    <img
                      src={
                        require("../../assets/img/auth/thanku-confirm.svg")
                          .default
                      }
                    />
                  </figure>
                  <h2>
                    Thank you for confirming your
                    <br /> email address
                  </h2>
                  <p>
                    You will now be redirected to SmarterChains platform.
                    <br />
                    <Link
                      to="/"
                      className="blue-link"
                    >
                      {" "}
                      Click here{" "}
                    </Link>
                    to redirect manually.
                  </p>
                </div>
                {/* message sent text div ended */}

              </div>
              {/* End email submission msg */}
            </div>
            {/* submission msg main wrapper ended */}
            </section>

          </Grid>
          {/*ended right section */}

        </div>
      </React.Fragment>
    );
  }
}
