import React, { useEffect } from "react";
import * as d3 from "d3";
import styled from "styled-components";
export const PieChart = (props) => {
    const demoData = [{
        "label": "completed",
        "value": 10,
        "color": "#4CAF50",
        "innerData":[
            {
                "label": "todo",
                "value": 2,
                "color": "#2C55A0",
            },
            {
                "label": "inprogress",
                "value": 5,
                "color": "#1487E3",
            },
            {
                "label": "completed",
                "value": 3,
                "color": "#55D6BE",
            }
        ]
    }, {
        "label": "inprogress",
        "value": 10,
        "color": "#FFBC1F"
    }, {
        "label": "todo",
        "value": 25,
        "color": "#FF8383"
    }]

   
    const chartData = props.chartData ? props.chartData : demoData;
   // const chartData = demoData;
    let outerData = chartData?.find(i => i.label == 'completed')?.innerData || false;
    let filterdata = chartData?.filter(i => i.label !== 'completed').map(i=>{return {...i,disabled:true}});
    let outer_Data = [];
    if(outerData){
        outer_Data = [
            ...outerData,
            ...filterdata
        ]
    }
    // console.log({outer_Data,filterdata});
    let drawPieChart = () => {
        // Define size & radius of donut pie chart
        let donutChartDiv = document.querySelector(`#${props.id}`);
        let labelDiv = donutChartDiv?.querySelector('.inner-lable');
        let width = donutChartDiv?.offsetWidth || 0;
        let height = donutChartDiv?.offsetHeight || 0;
        let radius = Math.min(width, height) / 2;
        let innerCircleRadius = labelDiv?.offsetWidth - 30;
        let innerArcInnerCircleRadius = innerCircleRadius/1.5; 
        let defultScale = 'scale(1)';
        let hoverScale = 'scale(1)';
        // Determine size of arcs
        var arc = d3.arc()
            .innerRadius(radius - innerCircleRadius)
            .outerRadius(radius - 5);

        var inner_arc = d3.arc()
            .innerRadius(radius - innerCircleRadius + innerArcInnerCircleRadius)
            .outerRadius(radius - 5);
        // Create the donut pie chart layout
        var pie = d3.pie()
            .value(function (d) { return d["value"]; })
            .sort(null);

        // Append SVG attributes and append g to the SVG
        var svg = d3.select(`#${props.id} .main-chart-div`)
            .html('')
            .append('svg')
            .attr("viewBox", `0 0 ${width} ${height}`)
            .attr("xmlns", "http://www.w3.org/2000/svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + radius + "," + radius + ")");

        // svg.append("circle")
        // .attr("cx", 0)
        // .attr("cy", 0)
        // .attr("r", innerCircleRadius+5)
        // .attr("fill", "#F0F0F0");

        // Calculate SVG paths and fill in the colours
        var g = svg.selectAll(".arc")
            .data(pie(chartData))
            .enter().append("g")
            .attr("class", (d, i) => {
                return `arc ${props.hoverCardPrefix}g-${d.data.label}`
            })
            .on('mouseenter', function (ev, d) {
                this.style.transform = hoverScale;
                if (props.hoverCard) {
                    let tmpClass = `.${props.hoverCardPrefix + d.data.label}`;
                    document.querySelector(tmpClass).classList.add('active');
                }
            })
            .on('mouseleave', function (ev, d) {
                this.style.transform = defultScale;
                if (props.hoverCard) {
                    let tmpClass = `.${props.hoverCardPrefix + d.data.label}`;
                    document.querySelector(tmpClass).classList.remove('active');
                }
            })
            .on('click', function (ev, d) {
                this.style.transform = defultScale;
                if (props.hoverCard) {
                    let tmpClass = `.${props.hoverCardPrefix + d.data.label}`;
                    let tmpTarget = document.querySelector(tmpClass);
                    if (tmpTarget) {
                        tmpTarget.click();
                    }
                }
            })
        // Append the path to each g
        g.append("path")
            .attr("d", arc)
            .attr("fill", function (d, i) {
                return d.data.color;
            });

        // 
        
        var outer_g = svg.selectAll(".inner_arc")
            .data(pie(outer_Data))
            .enter()
            .append("g")
            .attr("class", (d, i) => {
                return `inner_arc ${props.hoverCardPrefix}g-${d.data.label} ${d.data.disabled?'pointer-none':''}`
            }) 
            .on('mouseenter', function (ev, d) {
                this.style.transform = hoverScale;
                if (props.hoverCard) {
                    let tmpClass = `.${props.hoverCardPrefix + d.data.label + '-inner'}`;
                    document.querySelector(tmpClass)?.classList?.add('active');
                }
            })
            .on('mouseleave', function (ev, d) {
                this.style.transform = defultScale;
                if (props.hoverCard) {
                    let tmpClass = `.${props.hoverCardPrefix + d.data.label + '-inner'}`;
                    document.querySelector(tmpClass)?.classList?.remove('active');
                }
            });
        // Append the path to each g
        outer_g.append("path")
            .attr("d", inner_arc)
            .attr("fill", function (d, i) {
                return d.data.color;
            });

        svg.append("circle")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("r", innerCircleRadius)
            .attr("fill", "#fff");
    }
    useEffect(() => {
        drawPieChart();
    }, [props.chartData]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            drawPieChart()
        });
        return () => {
            window.removeEventListener('resize', () => { drawPieChart() });
        }
    }, [])

    return (
        <>
            <DonutChartConatainer data-component="DonutChartConatainer" id={props.id} className="donut-chart">
                <InnerLabelBox data-component="InnerLabelBox" className="inner-lable">
                    <span>{props.number} {props.text}</span>
                </InnerLabelBox>
                <div className="main-chart-div"></div>
            </DonutChartConatainer>

        </>
    )
}


const DonutChartConatainer = styled.div`
                position: relative;
                width: 16rem;
                height: 16rem;
                .pointer-none{
                    pointer-events:none;
                }
                .arc,
                .inner_arc{
                    transform: scale(1) !important;
                    transition: 0.25s;
                    cursor: pointer;
                }
                @media(max-width:575px){
                    width:80px;
                    height:80px;
                }
`

const InnerLabelBox = styled.div`
                    position: absolute;
                    width: 8rem;
                    height:8rem;
                    top:50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #0D2C54;
                    font-size: 1.8rem;
                    font-weight: 500;
                    pointer-events: none;
                    text-align: center;
                    @media(max-width:575px){
                        font-size:14px;
                        width:50px;
                        text-align:center;
                    }

`