/** imported components
  * .scss for ===> styling css file for this page.
  * AdminWalkthroughview ===> view file of this component
  * useState ===> React hooks (useState is a way to get/set value)

**/


import React ,{useState} from 'react';
import {AdminWalkthroughview} from './admin-walkthrough-view';
import './admin-walkthrough.scss';
//AdminWalkthrough Component start
export const AdminWalkthroughController =(props)=>{
    return(
             <AdminWalkthroughview {...props}/>
    )
}