/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/

import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import './loss-analysis-tour.scss';
import { Modal } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
//LossAnalysisTour component start
export const LossAnalysisTour = () => {
    //Defining variables here
    const [slide1Active, setSlide1Active] = useState(0);
    const [slide2Active, setSlide2Active] = useState(0);
    const [isSlide2, setIsSlide2] = useState(false);
    const slide2Total = 7;
    const user = JSON.parse(localStorage.getItem("userData"));
    const name = user ? user.name : "";
	const usid = user ? user.uid : 0;
	useEffect(() => {
		serviceProvider.post("/home?type=setbusiness", JSON.stringify({ uid: usid }),true).then((response) => {
			// console.log(response);
		});
	}, []);
    const slide1Data = [
        {
            img: 'step-1-of-4.gif',
            title: `Hi ${name}, welcome to the Loss Analysis tour!`,
            para: [
                `You are one step closer to start your transformation journey.`,
                `We want to quickly show you around and it will only take a minute. Reach the end for the Power BI Loss Analysis results illustration.`
            ],

        },
        {
            img: 'step-2-of-4.gif',
            title: `Improve productivity and visualize loss impacts`,
            para: [
                `SmarterChains Loss Analysis covers a broad spectrum of losses and provides you with the necessary data and insights to identify opportunities and drive value-driven initiatives in your manufacturing operations.`
            ],

        },
        {
            img: 'step-3-of-4.gif',
            title: `How it works`,
            para: [
                `Execute the whole process fast and remotely. Collaborate and share intelligence enterprise-wide for visibility across all your manufacturing network.​`
            ],

        },
        {
            img: 'step-4-of-4.gif',
            title: `Complete the Loss Analysis to get the results`,
            para: [
                `Finalize the Loss Analysis data collection to get access to an interactive Power BI dashboard. Replicate the exercise to track the progression of your operational losses through time to drive efficiency and reduce cost.​`
            ],

        },

    ]
    //Function to handle slide 1
    const HandleSlide1 = (val) => {
        setSlide1Active(val);
    }
     //Function to handle slide 2
    const HandleSlide2 = (val) => {
        setSlide2Active(val);
    }
    //Function to handle slide navigation
    const HandleSlide1NextPrev = (e) => {
        if (e === "next" && slide1Active < slide1Data.length - 1) {
            const tmpval = slide1Active + 1;
            setSlide1Active(tmpval);
        } else if (slide1Active > 0) {
            const tmpval = slide1Active - 1;
            setSlide1Active(tmpval);
        }

    }
    //Function to handle slide navigation
    const HandleSlide2NextPrev = (e) => {

        if (e === "next" && slide2Active < slide2Total - 1) {
            const tmpval = slide2Active + 1;
            setSlide2Active(tmpval);
        } else if (slide2Active > 0) {
            const tmpval = slide2Active - 1;
            setSlide2Active(tmpval);
        }

    }

    return (
        <>
        {/* Breadcrumbs */}
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
		<BreadcrumbsItem to="/p">Loss Analysis</BreadcrumbsItem>
		{isSlide2&&<BreadcrumbsItem to="/p1">Plant Results</BreadcrumbsItem>}
        {/* Main walkthrough wrapper */}
        <div className="lsa-tour-main">
            {/* Backlground color for all walkthrough slides */}
            <div className="lsa-tour-bg-1" style={{
                display: isSlide2 && 'none'
            }}></div>
            <div className="lsa-tour-bg1-of7" style={{
                display: !isSlide2 || slide2Active !== 0 && 'none'
            }}></div>
            <div className="lsa-tour-bg2-of7" style={{
                display: !isSlide2 || slide2Active !== 1 && 'none'
            }}></div>
            <div className="lsa-tour-bg3-of7" style={{
                display: !isSlide2 || slide2Active !== 2 && 'none'
            }}></div>
            <div className="lsa-tour-bg4-of7" style={{
                display: !isSlide2 || slide2Active !== 3 && 'none'
            }}></div>
            <div className="lsa-tour-bg5-of7" style={{
                display: !isSlide2 || slide2Active !== 4 && 'none'
            }}></div>
            <div className="lsa-tour-bg6-of7" style={{
                display: !isSlide2 || slide2Active !== 5 && 'none'
            }}></div>
            <div className="lsa-tour-bg7-of7" style={{
                display: !isSlide2 || slide2Active !== 6 && 'none'
            }}></div>
            <Modal open={true} className={`lsa-tour-modal-1 ${isSlide2 && slide2Active > 0}`}>
                <>
                    {!isSlide2 ? <>
                        {slide1Data.map((item, index) =>
                            /* slide has three parts
                                las-header
                                las-body
                                las-footer
                            */ 
                            <div className={`lsa-content-1 ${index == slide1Active && ' active'}`} key={index}>
                                {/* this section has image */}
                                <div className="las-header-1">
                                    <img src={require(`../../assets/img/loss-analysis-tour/${item.img}`).default} alt="true" />
                                </div>
                                {/* this section has
                                    number of slide
                                    heading
                                    text
                                */}
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        {index + 1} of {slide1Data.length}
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        {item.title}
                                    </h3>
                                    {item.para.map((item, index2) =>
                                        <p key={index2}>
                                            {item}
                                        </p>)
                                    }

                                </div>
                                {/* footer has two parts 
                                    navigation using dots
                                    navigation using button
                                */}
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button onClick={() => {
                                            HandleSlide1(0)
                                        }} disabled={slide1Active === 0 ? true : false}></button>
                                        <button onClick={() => {
                                            HandleSlide1(1)
                                        }} disabled={slide1Active === 1 ? true : false}></button>
                                        <button onClick={() => {
                                            HandleSlide1(2)
                                        }} disabled={slide1Active === 2 ? true : false}></button>
                                        <button onClick={() => {
                                            HandleSlide1(3)
                                        }} disabled={slide1Active === 3 ? true : false}></button>
                                    </div>
                                    {slide1Active === 0 ?
                                        <Link to="/quickstartopen" className="btn-blue-text">
                                            Exit Tour
                                    </Link>
                                        :
                                        <button className="btn-blue-text" onClick={() => HandleSlide1NextPrev()} >
                                            Previous
                                    </button>

                                    }
                                    {slide1Active === 0 ?
                                        <button className="btn-blue-bg" onClick={() => HandleSlide1NextPrev('next')}>
                                            Let’s go
                                    </button>
                                        : slide1Active > 0 && slide1Active < slide1Data.length - 1 ?
                                            <button className="btn-blue-bg" onClick={() => HandleSlide1NextPrev('next')}>
                                                Next
                                        </button>
                                            :
                                            <button className="btn-blue-bg" onClick={() => setIsSlide2(true)}>
                                                Next: Explore Results
                                        </button>
                                    }
                                </div>
                            </div>
                        )}
                    </>
                        :
                        <>
                            <div className={`lsa-content-1 ${slide2Active === 0 && ' active'}`}>
                                <div className="las-header-1">
                                    <img src={require(`../../assets/img/loss-analysis-tour/step-1-of-7.gif`).default} alt="true" />
                                </div>
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        1 of 7
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        Welcome to the Loss Analysis Results tour!
                                    </h3>
                                    <p>
                                        Following Loss Analysis completion, access the results through an Integrated Power BI environment in SmarterChains platform to identify and visualize the main loss drivers of your manufacturing operations.
                                    </p>

                                </div>
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button disabled={
                                            slide2Active == 0 ? true : false
                                        } onClick={() => HandleSlide2(0)}></button>
                                        <button disabled={
                                            slide2Active == 1 ? true : false
                                        } onClick={() => HandleSlide2(1)}></button>
                                        <button disabled={
                                            slide2Active == 2 ? true : false
                                        } onClick={() => HandleSlide2(2)}></button>
                                        <button disabled={
                                            slide2Active == 3 ? true : false
                                        } onClick={() => HandleSlide2(3)}></button>
                                        <button disabled={
                                            slide2Active == 4 ? true : false
                                        } onClick={() => HandleSlide2(4)}></button>
                                        <button disabled={
                                            slide2Active == 5 ? true : false
                                        } onClick={() => HandleSlide2(5)}></button>
                                        <button disabled={
                                            slide2Active == 6 ? true : false
                                        } onClick={() => HandleSlide2(6)}></button>
                                    </div>
                                    <Link to="/quickstartopen" className="btn-blue-text">
                                        Exit Tour
                                    </Link>
                                    <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                        Next
                                    </button>
                                </div>
                            </div>
                            {/* slide 1 end */}
                            <div className={`lsa-content-1 type-2 ${slide2Active === 1 && ' active'}`} id='LsaType2Content1'>
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        2 of 7
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        Key Plant Figures
                                    </h3>
                                    <p>
                                        Review the results starting with an overview of your key plant figures, such as: production volume, OEE and Digital Maturity Index.
                                    </p>

                                </div>
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button disabled={
                                            slide2Active == 0 ? true : false
                                        } onClick={() => HandleSlide2(0)}></button>
                                        <button disabled={
                                            slide2Active == 1 ? true : false
                                        } onClick={() => HandleSlide2(1)}></button>
                                        <button disabled={
                                            slide2Active == 2 ? true : false
                                        } onClick={() => HandleSlide2(2)}></button>
                                        <button disabled={
                                            slide2Active == 3 ? true : false
                                        } onClick={() => HandleSlide2(3)}></button>
                                        <button disabled={
                                            slide2Active == 4 ? true : false
                                        } onClick={() => HandleSlide2(4)}></button>
                                        <button disabled={
                                            slide2Active == 5 ? true : false
                                        } onClick={() => HandleSlide2(5)}></button>
                                        <button disabled={
                                            slide2Active == 6 ? true : false
                                        } onClick={() => HandleSlide2(6)}></button>
                                    </div>
                                    <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                        Previous
                                    </button>
                                    <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                        Next
                                    </button>
                                </div>
                            </div>
                            {/* slide 2 end */}
                            <div className={`lsa-content-1 type-2 ${slide2Active === 2 && ' active'}`} id='LsaType2Content2'>
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        3 of 7
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        Filter
                                    </h3>
                                    <p>
                                        Use the filter area for better visualization purposes of your results. You can filter by loss area, production process and loss category.
                                    </p>

                                </div>
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button disabled={
                                            slide2Active == 0 ? true : false
                                        } onClick={() => HandleSlide2(0)}></button>
                                        <button disabled={
                                            slide2Active == 1 ? true : false
                                        } onClick={() => HandleSlide2(1)}></button>
                                        <button disabled={
                                            slide2Active == 2 ? true : false
                                        } onClick={() => HandleSlide2(2)}></button>
                                        <button disabled={
                                            slide2Active == 3 ? true : false
                                        } onClick={() => HandleSlide2(3)}></button>
                                        <button disabled={
                                            slide2Active == 4 ? true : false
                                        } onClick={() => HandleSlide2(4)}></button>
                                        <button disabled={
                                            slide2Active == 5 ? true : false
                                        } onClick={() => HandleSlide2(5)}></button>
                                        <button disabled={
                                            slide2Active == 6 ? true : false
                                        } onClick={() => HandleSlide2(6)}></button>
                                    </div>
                                    <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                        Previous
                                    </button>
                                    <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                        Next
                                    </button>
                                </div>
                            </div>
                            {/* slide 3 end */}
                            <div className={`lsa-content-1 type-2 ${slide2Active === 3 && ' active'}`} id='LsaType2Content3'>
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        4 of 7
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        Annual Losses by Loss Category
                                    </h3>
                                    <p>
                                        Access this bar chart to view the key loss categories. These represent the core loss buckets on an annual basis.
                                    </p>

                                </div>
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button disabled={
                                            slide2Active == 0 ? true : false
                                        } onClick={() => HandleSlide2(0)}></button>
                                        <button disabled={
                                            slide2Active == 1 ? true : false
                                        } onClick={() => HandleSlide2(1)}></button>
                                        <button disabled={
                                            slide2Active == 2 ? true : false
                                        } onClick={() => HandleSlide2(2)}></button>
                                        <button disabled={
                                            slide2Active == 3 ? true : false
                                        } onClick={() => HandleSlide2(3)}></button>
                                        <button disabled={
                                            slide2Active == 4 ? true : false
                                        } onClick={() => HandleSlide2(4)}></button>
                                        <button disabled={
                                            slide2Active == 5 ? true : false
                                        } onClick={() => HandleSlide2(5)}></button>
                                        <button disabled={
                                            slide2Active == 6 ? true : false
                                        } onClick={() => HandleSlide2(6)}></button>
                                    </div>
                                    <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                        Previous
                                    </button>
                                    <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                        Next
                                    </button>
                                </div>
                            </div>
                            {/* slide 4 end */}
                            <div className={`lsa-content-1 type-2 ${slide2Active === 4 && ' active'}`} id='LsaType2Content4'>
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        5 of 7
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        Losses by Production Processes
                                    </h3>
                                    <p>
                                        View the product line process in the stacked bar. Here, you can compare losses based on the different processes being used.
                                    </p>

                                </div>
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button disabled={
                                            slide2Active == 0 ? true : false
                                        } onClick={() => HandleSlide2(0)}></button>
                                        <button disabled={
                                            slide2Active == 1 ? true : false
                                        } onClick={() => HandleSlide2(1)}></button>
                                        <button disabled={
                                            slide2Active == 2 ? true : false
                                        } onClick={() => HandleSlide2(2)}></button>
                                        <button disabled={
                                            slide2Active == 3 ? true : false
                                        } onClick={() => HandleSlide2(3)}></button>
                                        <button disabled={
                                            slide2Active == 4 ? true : false
                                        } onClick={() => HandleSlide2(4)}></button>
                                        <button disabled={
                                            slide2Active == 5 ? true : false
                                        } onClick={() => HandleSlide2(5)}></button>
                                        <button disabled={
                                            slide2Active == 6 ? true : false
                                        } onClick={() => HandleSlide2(6)}></button>
                                    </div>
                                    <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                        Previous
                                    </button>
                                    <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                        Next
                                    </button>
                                </div>
                            </div>
                            {/* slide 5 end */}
                            <div className={`lsa-content-1 type-2 ${slide2Active === 5 && ' active'}`} id='LsaType2Content5'>
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        6 of 7
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        Losses by Sub Loss Category
                                    </h3>
                                    <p>
                                        In this horizontal bar chart, view a consolidated of the sub loss categories, populated from highest to lowest value.
                                    </p>

                                </div>
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button disabled={
                                            slide2Active == 0 ? true : false
                                        } onClick={() => HandleSlide2(0)}></button>
                                        <button disabled={
                                            slide2Active == 1 ? true : false
                                        } onClick={() => HandleSlide2(1)}></button>
                                        <button disabled={
                                            slide2Active == 2 ? true : false
                                        } onClick={() => HandleSlide2(2)}></button>
                                        <button disabled={
                                            slide2Active == 3 ? true : false
                                        } onClick={() => HandleSlide2(3)}></button>
                                        <button disabled={
                                            slide2Active == 4 ? true : false
                                        } onClick={() => HandleSlide2(4)}></button>
                                        <button disabled={
                                            slide2Active == 5 ? true : false
                                        } onClick={() => HandleSlide2(5)}></button>
                                        <button disabled={
                                            slide2Active == 6 ? true : false
                                        } onClick={() => HandleSlide2(6)}></button>
                                    </div>
                                    <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                        Previous
                                    </button>
                                    <button className="btn-blue-bg" onClick={() => HandleSlide2NextPrev('next')}>
                                        Next
                                    </button>
                                </div>
                            </div>
                            {/* slide 6 end */}
                            <div className={`lsa-content-1 type-2 ${slide2Active === 6 && ' active'}`} id='LsaType2Content6'>
                                <div className="las-body-1">
                                    <h6 className="lsa-dull">
                                        7 of 7
                                    </h6>
                                    <h3 className="lsa-title-h1">
                                        Losses by Loss Type
                                    </h3>
                                    <p>
                                        In the pie chart, view the annual losses by loss type, split by labor activity, line efficiency and budget losses.
                                    </p>

                                </div>
                                <div className="las-footer-1">
                                    <div className="dots-sec">
                                        <button disabled={
                                            slide2Active == 0 ? true : false
                                        } onClick={() => HandleSlide2(0)}></button>
                                        <button disabled={
                                            slide2Active == 1 ? true : false
                                        } onClick={() => HandleSlide2(1)}></button>
                                        <button disabled={
                                            slide2Active == 2 ? true : false
                                        } onClick={() => HandleSlide2(2)}></button>
                                        <button disabled={
                                            slide2Active == 3 ? true : false
                                        } onClick={() => HandleSlide2(3)}></button>
                                        <button disabled={
                                            slide2Active == 4 ? true : false
                                        } onClick={() => HandleSlide2(4)}></button>
                                        <button disabled={
                                            slide2Active == 5 ? true : false
                                        } onClick={() => HandleSlide2(5)}></button>
                                        <button disabled={
                                            slide2Active == 6 ? true : false
                                        } onClick={() => HandleSlide2(6)}></button>
                                    </div>
                                    <button className="btn-blue-text" onClick={() => HandleSlide2NextPrev()}>
                                        Previous
                                    </button>
                                    <Link to="/quickstartopen" className="btn-blue-bg">
                                        Exit tour
                                    </Link>
                                </div>
                            </div>
                            {/* slide 6 end */}

                        </>
                    }
                    {/* footer has 
                        two buttons
                        one link
                    */}
                    <div className="blue-footer">
                        <button className={`tabs ${!isSlide2 && 'active'}`} onClick={() => {setIsSlide2(false); HandleSlide2(0)} }>
                            Loss Analysis
                        </button>
                        <button className={`tabs ${isSlide2 && 'active'}`} onClick={() => {setIsSlide2(true); HandleSlide1(0)} }>
                            Loss Analysis Results
                        </button>
                        <Link to="/" className="exit-link">Exit this demo tour</Link>
                    </div>
                </>
            </Modal>
        </div>
        </>
    );
}

