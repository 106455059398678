/** imported components

  * CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext, ButtonFirst ===> component from react for carousel
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { Redirect, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Modal from "@material-ui/core/Modal";
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext, ButtonFirst } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./configurator-walkthrough.scss";
//TrainingWalkthrough component start
export const ConfiguratorWalkthroughView = (props) => {
	//defining variables here
	const {slideIndex, setSlideIndex} = props;
	const TotalSlides = 6;
	const user = JSON.parse(localStorage.getItem("userData"));
	const uname = user ? user.name : "";
	const usid = user ? user.uid : 0;
	useEffect(() => {
		serviceProvider.post("/home?type=settraining", JSON.stringify({ uid: usid }), true).then((response) => {
			// console.log(response);
		});
	}, []);

	const login = localStorage.getItem("isLoggedIn");
	if (!login) {
		return <Redirect to="/sign-in" />;
	}
	
	//function to handle index
	const handleIndex = (e) => {
		if (e === "add" && slideIndex < 5) {
			let tmp = 1 + slideIndex;
			setSlideIndex(tmp);
		}
		if (e === "subs" && slideIndex > 0) {
			let tmp = slideIndex - 1;
			setSlideIndex(tmp);
		}
		if (e === "reset" && slideIndex == 5) {
			let tmp = 4;
			setSlideIndex(tmp);
			console.log(slideIndex);
		}
		
	};
	const handleModal=()=>{
		props.handlePopup(false);
	}
	return (
		<>
		 {/* {(slideIndex==0 || slideIndex==2 || slideIndex==4)}
		 {slideIndex==1}
		 {slideIndex==3 && <QuizViewReport/>} */}
		 <Helmet>
			<meta name="viewport" content="width=device-width, initial-scale=1" />
		</Helmet>
			<Modal
				open={true}
				// onClose={props.handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="walk__through__modal1 bg-transparent"
			>
				<>
					{/* this section has
						image
						username
					*/}
					{/* <div className="walk-through-bg" style={{display:'none'}}>
						<img src={require(`../../assets/img/common-img/training-tour${slideIndex}.svg`).default} />
						<div>
							<p>{user.name}</p>
						</div>
					</div> */}
					<div className="modal__slider__wrapper">
						<CarouselProvider
							naturalSlideWidth={100}
							naturalSlideHeight={90}
							totalSlides={TotalSlides}
							dragEnabled={false}
							isPlaying={false}
						>
							<Slider>
								<Slide className="slide" index={0}>
									{/* this section has
										header
										slide_item_body
									*/}
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/learning-resource-product-tour/step-1.gif").default} alt="true" />
										</header>
										{/* this section has 
											label
											title
											text
										*/}
										<div className="slide__item__body">
											<h4 className="label">1 of 6</h4>
											<p className="title">Hi {uname}, welcome to the IR4.0 Strategy tour!​​</p>
											<p>
												The IR4.0 Strategy enables users to create the plant's Industry 4.0 Roadmap, building on its IR4.0 Technology Assessment & IR4.0 Loss Analysis results.
											</p>
										</div>
									</div>
								</Slide>

								<Slide className="slide" index={1}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/learning-resource-product-tour/step-2.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">2 of 6</h4>
											<h2 className="title">IR4.0 Strategy​</h2>
											<p>
												In the first step of the IR4.0 Strategy users can make the initial selection 
												of the roadmap steps based on the capabilities the plant lacks and their possible returns.
											</p>
										</div>
									</div>
								</Slide>
								<Slide className="slide" index={2}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/learning-resource-product-tour/step-3.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">3 of 6</h4>
											<h2 className="title">Recoverable Calibrator​</h2>
											<p>
												In the second step called Recoverable Calibrator, 
												users can adjust and optimize recoverable loss estimations, if needed.
											</p>
											<p>
												Recoverable data is calculated based on plant’s input in the IR4.0 Loss Analysis.
											</p>
										</div>
									</div>
								</Slide>
								<Slide className="slide" index={3}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/learning-resource-product-tour/step-4.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">4 of 6</h4>
											<h2 className="title">Investment Calibrator</h2>
											<p>
												In the third step called Investment Calibrator, users can adjust 
												and optimize the investment costs, if needed.
											</p>
											<p>
												​Investment data is calculated based on plant’s input in the IR4.0 Loss Analysis.
											</p>
										</div>
									</div>
								</Slide>
								<Slide className="slide" index={4}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/learning-resource-product-tour/step-5.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">5 of 6</h4>
											<h2 className="title">Roadmap Finalization​</h2>
											<p>
												In the final step called Roadmap Finalization, 
												users can change the order of the roadmap steps and decide on the implementation timelines.
											</p>
										</div>
									</div>
								</Slide>
								<Slide className="slide" index={5}>
									<div className="slide__item">
										<header className="slide__item__head">
											<img src={require("../../assets/img/learning-resource-product-tour/step-6.gif").default} alt="true" />
										</header>
										<div className="slide__item__body">
											<h4 className="label">6 of 6</h4>
											<h2 className="title">Business Case Results</h2>
											<p>
												The Business Case Results consist of multiple 
												Power BI interfaces that provide a comprehensive performance analysis of the created roadmap.​
											</p>
										</div>
									</div>
								</Slide>
							</Slider>
							{/* this section has 
								dots__section
								btn__section
							*/}
							<div className="modal__slider__footer">
								{/* section for navigation */}
								<div className="dots__section">
									<Dot slide={0} className="dot" onClick={()=>setSlideIndex(0)}></Dot>
									<Dot slide={1} className="dot" onClick={()=>setSlideIndex(1)}></Dot>
									<Dot slide={2} className="dot" onClick={()=>setSlideIndex(2)}></Dot>
									<Dot slide={3} className="dot" onClick={()=>setSlideIndex(3)}></Dot>
									<Dot slide={4} className="dot" onClick={()=>setSlideIndex(4)}></Dot>
									<Dot slide={5} className="dot" onClick={()=>setSlideIndex(5)}></Dot>
								</div>
								<div className="btn__section">
									{slideIndex <= 5 ? (
										<div className="btn__section">
											{slideIndex === 0 ? (
												<Link className="btn__prv" to="/learning-center">
													Exit Tour
												</Link>
											) : (
												<ButtonBack className="btn__prv" onClick={() => handleIndex("subs")}>
													Previous
												</ButtonBack>
											)}
											{slideIndex === 5 ? (
												<Link className="btn__nxt" to="/learning-center">
													Exit Tour
												</Link>	
											):(
												<ButtonNext className="btn__nxt" onClick={() => handleIndex("add")}>
													{slideIndex === 0 ?"Let’s go":"Next"}
												</ButtonNext>
											)}
										</div>
									) :'' }
								</div>
							</div>
						</CarouselProvider>
					</div>
					{/* this section has two parts
						footer-left
						footer-right
					*/}
					<div className="footer-area">
						<div className="footer-left">
							<Link to="" className="roadmap-btn">IR4.0 Strategy</Link>
						</div>
						{/* this section has link */}
						<div className="footer-right">
							<ul className="footer-page-link">
								<li>
									<Link  to="/learning-center">Exit this demo tour</Link>
								</li>
							</ul>
						</div>
					</div>
				</>
			</Modal>
		</>
	);
};
