import React from "react";
import { Helmet } from 'react-helmet';
import { Link, useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic"
export const Page404 = (props) => {
	let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
	return (
		<>
			<Helmet>
				<link href={require('./page-404.scss').default}/>
			</Helmet>
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to='#'>Page not found</BreadcrumbsItem>
			<div className="page-404-wrapper">
				<div className="page-404-img" dangerouslySetInnerHTML={{ __html: svg404 }}></div>
				<div>
					<Link  className="page-404-back-link" onClick={goToPreviousPath}>
						<span className="svg-span" dangerouslySetInnerHTML={{ __html: backSvg }}></span>
						<span className="txt-back">Go back</span>
					</Link>
				</div>
			</div>
		</>
	);
};

const backSvg = `<svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.292892 8.20711C-0.0976315 7.81658 -0.0976315 7.18342 0.292892 6.79289L6.65685 0.428932C7.04738 0.0384078 7.68054 0.0384078 8.07107 0.428932C8.46159 0.819457 8.46159 1.45262 8.07107 1.84315L2.41421 7.5L8.07107 13.1569C8.46159 13.5474 8.46159 14.1805 8.07107 14.5711C7.68054 14.9616 7.04738 14.9616 6.65685 14.5711L0.292892 8.20711ZM17 8.5H1V6.5H17V8.5Z" fill="#118ACB"/>
</svg>
`

const svg404 = `<svg width="400" height="312" viewBox="0 0 400 312" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M400 275.902H0V276.102H400V275.902Z" fill="#F0F0F0"/>
<path d="M189.6 240.254H35.128C33.9179 240.251 32.7581 239.77 31.9031 238.914C31.0482 238.058 30.568 236.899 30.568 235.69V18.7351C30.5785 17.5329 31.0634 16.3835 31.9172 15.5364C32.771 14.6894 33.9248 14.2131 35.128 14.211H189.6C190.812 14.211 191.973 14.6919 192.83 15.5478C193.687 16.4037 194.168 17.5646 194.168 18.775V235.69C194.168 236.9 193.687 238.061 192.83 238.917C191.973 239.773 190.812 240.254 189.6 240.254V240.254ZM35.128 14.3709C33.9709 14.373 32.862 14.8337 32.0445 15.6519C31.2271 16.4702 30.768 17.579 30.768 18.7351V235.69C30.768 236.846 31.2271 237.955 32.0445 238.773C32.862 239.591 33.9709 240.052 35.128 240.054H189.6C190.758 240.052 191.868 239.591 192.686 238.773C193.505 237.955 193.966 236.846 193.968 235.69V18.7351C193.966 17.5783 193.505 16.4694 192.686 15.6514C191.868 14.8335 190.758 14.373 189.6 14.3709H35.128Z" fill="#F0F0F0"/>
<path d="M362.648 240.254H208.168C206.957 240.251 205.796 239.77 204.94 238.915C204.084 238.059 203.602 236.899 203.6 235.69V18.7351C203.613 17.5322 204.099 16.3828 204.954 15.5359C205.809 14.6891 206.964 14.2131 208.168 14.211H362.648C363.85 14.2152 365.002 14.6924 365.854 15.5392C366.706 16.3861 367.189 17.5343 367.2 18.7351V235.69C367.2 236.897 366.721 238.056 365.868 238.911C365.015 239.767 363.857 240.249 362.648 240.254V240.254ZM208.168 14.3709C207.01 14.373 205.9 14.8335 205.082 15.6514C204.263 16.4694 203.802 17.5783 203.8 18.7351V235.69C203.802 236.846 204.263 237.955 205.082 238.773C205.9 239.591 207.01 240.052 208.168 240.054H362.648C363.806 240.052 364.916 239.591 365.734 238.773C366.553 237.955 367.014 236.846 367.016 235.69V18.7351C367.014 17.5783 366.553 16.4694 365.734 15.6514C364.916 14.8335 363.806 14.373 362.648 14.3709H208.168Z" fill="#F0F0F0"/>
<path d="M359.92 283.512H334.664V283.712H359.92V283.512Z" fill="#F0F0F0"/>
<path d="M268.272 281.346H241.544V281.546H268.272V281.346Z" fill="#F0F0F0"/>
<path d="M367.216 290.938H317.272V291.138H367.216V290.938Z" fill="#F0F0F0"/>
<path d="M57.352 289.595H41.968V289.795H57.352V289.595Z" fill="#F0F0F0"/>
<path d="M88.712 289.595H64.48V289.795H88.712V289.595Z" fill="#F0F0F0"/>
<path d="M195.312 286.222H151.664V286.422H195.312V286.222Z" fill="#F0F0F0"/>
<path d="M217.808 64.6709L172.224 110.223V261.923H332.216V64.6709H217.808Z" fill="white"/>
<path d="M332.216 262.354H172.216C172.101 262.354 171.992 262.309 171.911 262.228C171.83 262.147 171.784 262.037 171.784 261.923V110.223C171.784 110.167 171.795 110.11 171.817 110.058C171.839 110.006 171.871 109.959 171.912 109.919L217.512 64.3592C217.594 64.2801 217.702 64.2345 217.816 64.2313H332.216C332.273 64.2313 332.329 64.2425 332.381 64.2641C332.434 64.2858 332.481 64.3176 332.521 64.3577C332.562 64.3978 332.593 64.4454 332.615 64.4977C332.637 64.5501 332.648 64.6062 332.648 64.6629V261.923C332.648 261.979 332.637 262.035 332.615 262.088C332.593 262.14 332.562 262.188 332.521 262.228C332.481 262.268 332.434 262.3 332.381 262.321C332.329 262.343 332.273 262.354 332.216 262.354V262.354ZM172.656 261.499H331.784V65.1025H217.992L172.656 110.399V261.499Z" fill="#E6E6E6"/>
<path d="M172.224 110.223L220.056 116.386L217.808 64.6709L172.224 110.223Z" fill="#E6E6E6"/>
<path d="M172.224 110.223H217.808V64.6709L172.224 110.223Z" fill="white"/>
<path d="M217.808 110.647H172.208C172.123 110.647 172.039 110.623 171.968 110.576C171.897 110.529 171.841 110.462 171.808 110.383C171.775 110.305 171.766 110.219 171.783 110.136C171.801 110.054 171.843 109.978 171.904 109.92L217.504 64.3594C217.565 64.3002 217.642 64.2597 217.726 64.2427C217.81 64.2257 217.896 64.2329 217.976 64.2635C218.054 64.2975 218.12 64.3534 218.167 64.4243C218.214 64.4952 218.239 64.5782 218.24 64.6632V110.223C218.238 110.336 218.191 110.444 218.111 110.523C218.03 110.603 217.921 110.647 217.808 110.647V110.647ZM173.264 109.792H217.384V65.7103L173.264 109.792Z" fill="#E6E6E6"/>
<path d="M261.472 175.822L288.672 148.646C289.28 148.038 289.762 147.317 290.091 146.524C290.42 145.731 290.589 144.88 290.589 144.022C290.589 143.163 290.42 142.312 290.091 141.519C289.762 140.726 289.28 140.005 288.672 139.398C288.064 138.79 287.343 138.309 286.549 137.98C285.755 137.651 284.904 137.482 284.044 137.482C283.185 137.482 282.333 137.651 281.539 137.98C280.745 138.309 280.024 138.79 279.416 139.398L252.216 166.574L225.016 139.398C223.789 138.171 222.124 137.482 220.388 137.482C218.652 137.482 216.987 138.171 215.76 139.398C214.533 140.624 213.843 142.287 213.843 144.022C213.843 145.756 214.533 147.419 215.76 148.646L242.96 175.822L215.76 202.998C214.533 204.224 213.843 205.888 213.843 207.622C213.843 209.356 214.533 211.02 215.76 212.246C216.987 213.472 218.652 214.161 220.388 214.161C222.124 214.161 223.789 213.472 225.016 212.246L252.216 185.07L279.416 212.246C280.643 213.472 282.308 214.161 284.044 214.161C285.78 214.161 287.445 213.472 288.672 212.246C289.899 211.02 290.589 209.356 290.589 207.622C290.589 205.888 289.899 204.224 288.672 202.998L261.472 175.822Z" fill="#F0F0F0"/>
<path d="M178.4 153.29C176.865 154.821 174.785 155.682 172.616 155.682C170.447 155.682 168.367 154.821 166.832 153.29L140.584 127.056L152.152 115.499L178.4 141.764C179.923 143.295 180.778 145.367 180.778 147.527C180.778 149.686 179.923 151.758 178.4 153.29V153.29Z" fill="#F0F0F0"/>
<path d="M140.58 127.108L152.148 115.549L153.218 116.618C154.326 117.725 154.949 119.228 154.949 120.794C154.949 122.361 154.326 123.863 153.218 124.971L150.01 128.176C148.901 129.284 147.398 129.906 145.83 129.906C144.262 129.906 142.758 129.284 141.649 128.176L140.58 127.108V127.108Z" fill="#E6E6E6"/>
<path d="M67.768 42.7539C59.8301 50.6929 54.4262 60.8049 52.2392 71.8119C50.0522 82.8188 51.1804 94.2265 55.4812 104.593C59.7819 114.959 67.0621 123.819 76.4016 130.052C85.7411 136.286 96.7205 139.613 107.952 139.613C119.184 139.613 130.163 136.286 139.502 130.052C148.842 123.819 156.122 114.959 160.423 104.593C164.724 94.2265 165.852 82.8188 163.665 71.8119C161.478 60.8049 156.074 50.6929 148.136 42.7539C142.861 37.478 136.597 33.2927 129.702 30.4371C122.807 27.5816 115.416 26.1118 107.952 26.1118C100.488 26.1118 93.0973 27.5816 86.2023 30.4371C79.3073 33.2927 73.0431 37.478 67.768 42.7539V42.7539ZM144.856 119.775C137.558 127.066 128.26 132.032 118.137 134.044C108.014 136.055 97.5218 135.023 87.9865 131.076C78.4512 127.13 70.3012 120.447 64.5672 111.873C58.8332 103.299 55.7727 93.2188 55.7727 82.9068C55.7727 72.5949 58.8332 62.5145 64.5672 53.9404C70.3012 45.3662 78.4512 38.6835 87.9865 34.7373C97.5218 30.791 108.014 29.7584 118.137 31.7701C128.26 33.7818 137.558 38.7474 144.856 46.039C149.702 50.8806 153.546 56.6283 156.168 62.9541C158.791 69.2799 160.141 76.0598 160.141 82.9068C160.141 89.7539 158.791 96.5338 156.168 102.86C153.546 109.185 149.702 114.933 144.856 119.775V119.775Z" fill="#E6E6E6"/>
<path d="M137.808 114.644C137.432 114.643 137.065 114.531 136.753 114.322C136.44 114.112 136.197 113.816 136.053 113.469C135.91 113.122 135.872 112.74 135.945 112.371C136.018 112.003 136.199 111.664 136.464 111.398C144.013 103.835 148.252 93.589 148.252 82.9073C148.252 72.2256 144.013 61.9798 136.464 54.4162C136.27 54.244 136.113 54.034 136.004 53.7991C135.894 53.5642 135.833 53.3093 135.826 53.0502C135.818 52.7911 135.863 52.5331 135.959 52.2922C136.055 52.0512 136.199 51.8324 136.382 51.6491C136.566 51.4658 136.785 51.3218 137.026 51.2262C137.267 51.1305 137.525 51.0851 137.785 51.0927C138.044 51.1003 138.299 51.1608 138.534 51.2705C138.769 51.3802 138.98 51.5367 139.152 51.7305C147.414 60.0049 152.054 71.2155 152.054 82.9033C152.054 94.5911 147.414 105.802 139.152 114.076C138.976 114.254 138.767 114.396 138.537 114.494C138.306 114.591 138.058 114.642 137.808 114.644V114.644Z" fill="#E6E6E6"/>
<path d="M66.864 94.5649C66.4359 94.5654 66.0203 94.421 65.6848 94.1555C65.3492 93.8899 65.1135 93.5187 65.016 93.1022C63.6322 87.2473 63.4577 81.172 64.5031 75.2475C65.5485 69.3229 67.7918 63.6735 71.096 58.6443C71.3739 58.2203 71.8091 57.924 72.3057 57.8206C72.8023 57.7172 73.3196 57.8151 73.744 58.0928C74.1683 58.3705 74.4649 58.8052 74.5684 59.3014C74.6719 59.7976 74.5739 60.3145 74.296 60.7385C71.2707 65.3326 69.214 70.4943 68.2512 75.9087C67.2884 81.3232 67.4397 86.8769 68.696 92.231C68.7554 92.4735 68.766 92.7254 68.7272 92.9721C68.6884 93.2187 68.601 93.4552 68.47 93.6679C68.339 93.8805 68.1671 94.0651 67.9642 94.2108C67.7613 94.3566 67.5315 94.4607 67.288 94.517C67.1486 94.5472 67.0066 94.5633 66.864 94.5649V94.5649Z" fill="#E6E6E6"/>
<path d="M78.096 114.643C77.5915 114.642 77.1081 114.441 76.752 114.084C73.3251 110.673 70.4867 106.719 68.352 102.382C68.2417 102.159 68.1765 101.915 68.1603 101.667C68.144 101.418 68.1769 101.168 68.2571 100.932C68.3374 100.696 68.4633 100.478 68.6279 100.291C68.7924 100.104 68.9922 99.9505 69.216 99.8403C69.4398 99.7301 69.6831 99.665 69.932 99.6488C70.181 99.6325 70.4307 99.6654 70.667 99.7455C70.9032 99.8257 71.1213 99.9516 71.3089 100.116C71.4964 100.28 71.6497 100.48 71.76 100.704C73.7172 104.664 76.3117 108.277 79.44 111.398C79.7957 111.755 79.9954 112.238 79.9954 112.741C79.9954 113.244 79.7957 113.727 79.44 114.084C79.0824 114.439 78.6 114.64 78.096 114.643V114.643Z" fill="#E6E6E6"/>
<path d="M168.826 226.35L176.305 233.822L158.938 251.173C158.05 252.061 156.845 252.559 155.589 252.559C154.333 252.559 153.129 252.061 152.24 251.173L151.454 250.388C150.566 249.5 150.067 248.297 150.067 247.042C150.067 245.787 150.566 244.583 151.454 243.696L168.821 226.345L168.826 226.35Z" fill="#F0F0F0"/>
<path d="M168.838 226.366L176.305 233.827L175.948 234.183C175.143 234.988 174.05 235.44 172.911 235.44C171.771 235.44 170.679 234.988 169.873 234.183L168.47 232.781C167.664 231.976 167.212 230.884 167.212 229.746C167.212 228.608 167.664 227.516 168.47 226.711L168.826 226.355L168.838 226.366Z" fill="#E6E6E6"/>
<path d="M227.48 185.821L209.96 203.318C208.015 205.4 206.956 208.155 207.006 211.003C207.057 213.85 208.212 216.567 210.228 218.58C212.245 220.592 214.965 221.744 217.815 221.791C220.665 221.838 223.422 220.777 225.504 218.832L232.592 211.758C234.084 202.779 232.268 193.564 227.48 185.821Z" fill="#F0F0F0"/>
<path d="M234.08 202.703C233.578 195.457 230.937 188.522 226.49 182.776C222.044 177.03 215.992 172.731 209.099 170.422C202.207 168.113 194.784 167.898 187.769 169.805C180.754 171.711 174.463 175.653 169.691 181.132C164.919 186.611 161.88 193.381 160.96 200.586C160.039 207.791 161.277 215.106 164.518 221.608C167.759 228.109 172.857 233.504 179.167 237.111C185.477 240.717 192.716 242.373 199.968 241.868C209.689 241.192 218.743 236.687 225.14 229.342C231.537 221.998 234.753 212.415 234.08 202.703ZM163.752 207.594C163.289 200.942 164.81 194.302 168.124 188.514C171.438 182.725 176.395 178.049 182.369 175.076C188.343 172.102 195.065 170.966 201.686 171.81C208.306 172.654 214.528 175.441 219.563 179.818C224.598 184.195 228.221 189.965 229.974 196.399C231.727 202.834 231.531 209.643 229.41 215.966C227.289 222.288 223.34 227.841 218.061 231.921C212.782 236.001 206.41 238.425 199.752 238.887C190.825 239.506 182.017 236.558 175.266 230.689C168.515 224.821 164.373 216.513 163.752 207.594Z" fill="#E6E6E6"/>
<path d="M178.704 226.754C175.873 224.302 173.554 221.315 171.88 217.966C170.207 214.616 169.212 210.969 168.952 207.235C168.934 207.07 168.949 206.903 168.997 206.744C169.045 206.585 169.124 206.437 169.231 206.309C169.337 206.182 169.468 206.077 169.617 206.002C169.765 205.926 169.926 205.882 170.092 205.87C170.258 205.859 170.425 205.881 170.582 205.936C170.739 205.99 170.883 206.076 171.006 206.188C171.129 206.3 171.228 206.435 171.297 206.586C171.366 206.737 171.404 206.901 171.408 207.067C171.898 213.95 175.098 220.359 180.307 224.89C185.515 229.422 192.309 231.707 199.2 231.246C199.525 231.224 199.845 231.331 200.09 231.545C200.335 231.758 200.486 232.061 200.508 232.385C200.53 232.709 200.423 233.029 200.209 233.274C199.995 233.519 199.693 233.669 199.368 233.692C195.636 233.951 191.89 233.471 188.344 232.281C184.798 231.09 181.522 229.212 178.704 226.754V226.754Z" fill="#E6E6E6"/>
<path d="M222.912 198.85C222.73 198.692 222.599 198.483 222.536 198.25C221.573 194.829 219.922 191.64 217.684 188.878C215.446 186.116 212.668 183.838 209.52 182.184C209.245 182.026 209.041 181.768 208.952 181.463C208.863 181.158 208.895 180.831 209.041 180.55C209.188 180.268 209.438 180.054 209.739 179.952C210.04 179.85 210.368 179.868 210.656 180.002C214.102 181.813 217.143 184.306 219.592 187.329C222.042 190.352 223.849 193.843 224.904 197.587C224.948 197.742 224.96 197.905 224.941 198.065C224.921 198.225 224.87 198.38 224.791 198.52C224.711 198.661 224.605 198.784 224.477 198.883C224.35 198.982 224.204 199.055 224.048 199.098C223.853 199.152 223.647 199.157 223.449 199.114C223.251 199.071 223.067 198.98 222.912 198.85V198.85Z" fill="#E6E6E6"/>
<path d="M194.712 178.972C194.468 178.754 194.318 178.45 194.296 178.124C194.274 177.8 194.381 177.481 194.594 177.235C194.807 176.99 195.108 176.838 195.432 176.813C198.552 176.597 201.687 176.891 204.712 177.685C204.87 177.726 205.017 177.798 205.147 177.897C205.277 177.995 205.386 178.118 205.468 178.259C205.55 178.4 205.604 178.555 205.626 178.716C205.648 178.878 205.638 179.042 205.596 179.199C205.555 179.357 205.482 179.505 205.384 179.634C205.285 179.764 205.162 179.873 205.021 179.955C204.88 180.037 204.725 180.09 204.563 180.112C204.402 180.134 204.238 180.124 204.08 180.083C201.319 179.353 198.457 179.083 195.608 179.283C195.28 179.3 194.959 179.188 194.712 178.972V178.972Z" fill="#E6E6E6"/>
<path d="M200 312C285.666 312 355.112 307.949 355.112 302.952C355.112 297.955 285.666 293.904 200 293.904C114.334 293.904 44.888 297.955 44.888 302.952C44.888 307.949 114.334 312 200 312Z" fill="#F5F5F5"/>
<path d="M110.256 86.8527C103.808 93.3351 114.768 103.398 107.528 108.018C113.44 110.984 134.16 114.748 139.528 103.087C135.616 100.049 138.336 92.5837 134.728 85.414C129.328 74.7833 103.808 79.4911 110.256 86.8527Z" fill="#4D4D4F"/>
<path d="M110.84 86.6849C108.832 86.3492 102.04 89.7222 107.096 98.7783C111.168 106.1 107.776 106.619 107.776 106.619C107.776 106.619 111.776 106.731 109.376 101.288C106.832 95.4772 105.64 89.4505 110.84 86.6849Z" fill="#4D4D4F"/>
<path d="M158.4 144.187C158.153 143.734 157.737 143.397 157.244 143.247C156.75 143.098 156.217 143.148 155.76 143.387L155.288 143.635C155.136 143.715 154.848 143.907 154.616 144.043C154.384 144.179 154.112 144.33 153.856 144.458C153.352 144.712 152.83 144.931 152.296 145.114C151.307 145.472 150.267 145.669 149.216 145.697C148.25 145.708 147.29 145.554 146.376 145.242C145.796 145.026 145.236 144.759 144.704 144.442C144.016 142.924 143.36 141.389 142.8 139.806C142.12 137.96 141.52 136.074 140.992 134.163C140.464 132.253 140 130.319 139.616 128.376C139.416 127.409 139.24 126.434 139.08 125.467L138.864 124.02C138.792 123.557 138.728 123.029 138.688 122.661V122.501C138.582 121.442 138.059 120.467 137.234 119.793C136.408 119.118 135.349 118.799 134.288 118.905C133.227 119.011 132.252 119.533 131.577 120.358C130.902 121.182 130.582 122.241 130.688 123.301C130.744 123.98 130.792 124.5 130.864 125.067C130.936 125.635 131.016 126.178 131.096 126.73C131.272 127.833 131.472 128.92 131.696 130.007C132.141 132.167 132.702 134.302 133.376 136.401C133.688 137.456 134.072 138.495 134.432 139.543C134.792 140.59 135.232 141.613 135.688 142.628C136.561 144.679 137.552 146.678 138.656 148.615L138.752 148.79C139.039 149.311 139.469 149.738 139.992 150.021C141.504 150.845 143.138 151.422 144.832 151.732C146.572 152.039 148.355 152.001 150.08 151.62C152.515 151.056 154.751 149.843 156.552 148.111C156.848 147.839 157.128 147.56 157.352 147.264C157.576 146.968 157.848 146.688 158.152 146.273C158.387 145.984 158.534 145.635 158.578 145.265C158.622 144.896 158.56 144.522 158.4 144.187Z" fill="#FDA982"/>
<path d="M163.72 136.706H163.488C162.722 136.689 161.963 136.85 161.269 137.174C160.576 137.498 159.966 137.978 159.488 138.576L155.624 143.42V146.729C158.728 147.96 162.664 144.643 162.664 144.643L164.656 144.139C165.326 143.972 165.911 143.562 166.297 142.99C166.682 142.417 166.842 141.721 166.744 141.038L166.456 139.096C166.362 138.436 166.034 137.832 165.532 137.393C165.03 136.954 164.387 136.711 163.72 136.706V136.706Z" fill="#FDA982"/>
<path d="M138.584 118.026C141.168 120.679 141.328 128.6 141.328 128.6L130.992 136.913C130.992 136.913 126.744 125.163 129.096 121.015C131.088 117.498 135.288 114.645 138.584 118.026Z" fill="#1487E3"/>
<g opacity="0.2">
<path opacity="0.2" d="M138.584 118.025C141.168 120.679 141.328 128.6 141.328 128.6L130.992 136.913C130.992 136.913 126.744 125.163 129.096 121.015C131.088 117.498 135.288 114.644 138.584 118.025Z" fill="white"/>
</g>
<path d="M121.48 296.102L127.192 296.726L127.976 281.219L122.264 280.588L121.48 296.102Z" fill="#FDA982"/>
<path d="M128.128 293H121.416C121.281 292.996 121.149 293.044 121.049 293.136C120.949 293.227 120.888 293.353 120.88 293.488L120.432 299.826C120.432 300.158 120.562 300.477 120.793 300.714C121.025 300.951 121.34 301.089 121.672 301.097C124.504 301.097 124.536 301.097 128.072 301.097H137.6C140.528 301.097 140.536 298.132 139.272 297.86C133.584 296.661 131.368 294.999 129.36 293.416C129.007 293.144 128.574 292.998 128.128 293V293Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M127.976 281.227L127.568 289.22H121.824L122.264 280.595L127.976 281.227Z" fill="#0D2C54"/>
<path d="M131.056 157.503C131.056 157.503 136 198.851 136 217.235C132.104 242.813 128.8 287.702 128.8 287.702L120.896 287.494C120.896 287.494 117.304 238.608 120.632 217.499C116.368 198.587 111.104 154.945 111.104 154.945L131.056 157.503Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M115.816 169.013C114.874 169.427 113.96 169.902 113.08 170.435C114.168 178.596 115.6 188.867 117.12 198.347C119.712 190.546 118.312 173.393 115.816 169.013Z" fill="#0D2C54"/>
<path d="M59.624 281.707L64.336 284.992L72.464 271.755L67.752 268.47L59.624 281.707Z" fill="#FDA982"/>
<path d="M65.528 283.257L61.528 277.878C61.4518 277.768 61.3354 277.692 61.2038 277.666C61.0721 277.641 60.9358 277.668 60.824 277.742L55.464 281.179C55.1966 281.376 55.0173 281.67 54.965 281.997C54.9126 282.325 54.9913 282.66 55.184 282.93C56.856 285.208 56.888 285.224 58.992 288.053C60.296 289.788 62.896 293.281 64.688 295.686C66.48 298.092 68.824 296.27 68.28 295.087C65.88 289.82 65.88 287.094 65.928 284.504C65.9362 284.056 65.7955 283.617 65.528 283.257V283.257Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M72.456 271.755L68.272 278.581L63.224 275.832L67.752 268.47L72.456 271.755Z" fill="#0D2C54"/>
<path d="M88.528 287.254C84.44 287.254 75.928 287.254 75.784 281.243C75.672 276.527 81.512 274.569 86.552 274.329C87.1104 274.302 87.6687 274.385 88.1948 274.574C88.721 274.763 89.2048 275.053 89.6186 275.429C90.0324 275.804 90.3681 276.258 90.6065 276.763C90.8449 277.268 90.9813 277.815 91.008 278.373V278.693C96.72 278.421 100.416 277.174 101.768 275.048C104.816 270.252 98.976 257.871 93.336 245.906C86.696 231.83 79.176 215.884 79.032 200.609C79.0267 200.051 79.1316 199.497 79.3406 198.979C79.5496 198.461 79.8587 197.989 80.2502 197.591C80.6417 197.192 81.108 196.874 81.6223 196.656C82.1367 196.437 82.6891 196.322 83.248 196.317V196.317C84.3692 196.319 85.4446 196.762 86.2411 197.551C87.0377 198.339 87.4914 199.409 87.504 200.529C87.632 213.942 94.704 228.992 100.992 242.277C108.192 257.607 114.472 270.852 108.912 279.604C105.688 284.752 99.008 287.254 88.528 287.254Z" fill="#1487E3"/>
<path d="M124.728 156.688C124.728 156.688 111.08 203.943 104.416 221.08C91.504 243.508 70.08 277.83 70.08 277.83L63.24 273.834C63.24 273.834 79.24 234.188 90 215.724C92.888 196.541 103.808 153.97 103.808 153.97L124.728 156.688Z" fill="#4D4D4F"/>
<path d="M66.76 287.118C67.0314 287.567 67.4497 287.908 67.944 288.085C68.0887 288.12 68.2402 288.113 68.3816 288.067C68.5229 288.02 68.6484 287.935 68.744 287.821C68.8105 287.76 68.8613 287.684 68.8919 287.599C68.9226 287.514 68.9322 287.423 68.92 287.334C68.824 286.375 66.344 285.128 65.848 284.888C65.8191 284.876 65.7869 284.875 65.7574 284.886C65.7279 284.896 65.7032 284.917 65.688 284.944C65.6687 284.974 65.6584 285.008 65.6584 285.044C65.6584 285.079 65.6687 285.114 65.688 285.144C65.9818 285.834 66.3407 286.495 66.76 287.118V287.118ZM68.464 287.118C68.5344 287.206 68.5812 287.31 68.6 287.422C68.6038 287.453 68.6 287.484 68.5889 287.513C68.5777 287.543 68.5596 287.569 68.536 287.589C68.4715 287.663 68.3886 287.718 68.2958 287.749C68.203 287.78 68.1037 287.785 68.008 287.765C67.48 287.661 66.784 286.806 66.112 285.455C66.9833 285.861 67.7787 286.412 68.464 287.086V287.118Z" fill="#1487E3"/>
<path d="M65.648 285.176L65.696 285.216C66.496 285.655 68.576 286.015 69.032 285.351C69.1085 285.23 69.1458 285.089 69.1386 284.945C69.1315 284.802 69.0803 284.665 68.992 284.552C68.192 283.137 65.792 284.8 65.688 284.872C65.6641 284.891 65.6452 284.915 65.6327 284.943C65.6201 284.971 65.6144 285.001 65.616 285.032C65.6128 285.082 65.6239 285.132 65.648 285.176ZM68.688 284.712C68.7113 284.74 68.7327 284.769 68.752 284.8C68.84 284.952 68.856 285.064 68.808 285.128C68.544 285.511 67.008 285.375 66.112 285.024C66.712 284.664 68.112 283.937 68.688 284.712Z" fill="#1487E3"/>
<path d="M132 294.319C132.524 294.367 133.049 294.235 133.488 293.944C133.599 293.846 133.681 293.72 133.725 293.579C133.769 293.438 133.773 293.288 133.736 293.144C133.725 293.054 133.693 292.968 133.643 292.891C133.593 292.815 133.526 292.751 133.448 292.705C132.648 292.217 130.144 293.456 129.656 293.712C129.629 293.727 129.607 293.751 129.595 293.78C129.584 293.81 129.583 293.842 129.592 293.872C129.604 293.905 129.624 293.935 129.651 293.959C129.677 293.983 129.709 293.999 129.744 294.008C130.483 294.188 131.239 294.293 132 294.319V294.319ZM132.992 292.937C133.106 292.929 133.22 292.954 133.32 293.009C133.36 293.009 133.4 293.065 133.416 293.16C133.437 293.256 133.432 293.356 133.401 293.448C133.37 293.541 133.314 293.624 133.24 293.688C132.848 294.048 131.744 294.096 130.256 293.832C131.097 293.382 132.009 293.079 132.952 292.937H132.992Z" fill="#1487E3"/>
<path d="M129.744 294.048H129.8C130.6 293.68 132.16 292.218 131.904 291.458C131.851 291.333 131.762 291.226 131.648 291.15C131.534 291.075 131.401 291.035 131.264 291.035C129.664 290.851 129.552 293.744 129.544 293.864C129.544 293.896 129.552 293.927 129.568 293.955C129.583 293.983 129.605 294.007 129.632 294.024C129.666 294.043 129.705 294.052 129.744 294.048V294.048ZM131.184 291.33H131.296C131.464 291.33 131.568 291.402 131.592 291.482C131.744 291.922 130.72 293.081 129.896 293.584C129.968 292.889 130.224 291.33 131.2 291.33H131.184Z" fill="#1487E3"/>
<path d="M103.808 154.01L131.056 157.495C137.2 136.825 139.224 126.842 139.768 122.038C139.912 120.831 139.629 119.611 138.969 118.589C138.309 117.568 137.313 116.809 136.152 116.443C134.552 115.931 132.488 115.324 130.44 114.844C129.744 114.66 129.032 114.493 128.336 114.333C124.257 113.506 120.124 112.972 115.968 112.734C115.352 112.678 114.744 112.646 114.128 112.614C112.92 112.55 111.728 112.534 110.64 112.534C109.823 112.533 109.016 112.702 108.268 113.03C107.52 113.359 106.849 113.839 106.298 114.44C105.746 115.042 105.326 115.752 105.064 116.524C104.802 117.297 104.704 118.116 104.776 118.929C104.992 121.327 105.16 123.557 105.288 125.603C105.592 130.399 105.688 134.235 105.616 137.592C105.452 143.104 104.848 148.594 103.808 154.01V154.01Z" fill="#1487E3"/>
<g opacity="0.3">
<path opacity="0.3" d="M103.808 154.01L131.056 157.495C137.2 136.825 139.224 126.842 139.768 122.038C139.912 120.83 139.629 119.611 138.969 118.589C138.309 117.568 137.313 116.808 136.152 116.443C134.552 115.931 132.488 115.324 130.44 114.844C129.744 114.66 129.032 114.492 128.336 114.333C124.257 113.506 120.124 112.971 115.968 112.734C115.352 112.678 114.744 112.646 114.128 112.614C112.92 112.55 111.728 112.534 110.64 112.534C109.823 112.533 109.016 112.702 108.268 113.03C107.52 113.358 106.849 113.839 106.298 114.44C105.746 115.042 105.326 115.751 105.064 116.524C104.802 117.297 104.704 118.116 104.776 118.929C104.992 121.326 105.16 123.556 105.288 125.603C105.592 130.399 105.688 134.235 105.616 137.592C105.452 143.104 104.848 148.594 103.808 154.01V154.01Z" fill="white"/>
</g>
<path opacity="0.3" d="M105.6 137.56C109.2 132.908 110.504 127.585 109.336 124.652L105.272 125.563C105.6 130.343 105.688 134.195 105.6 137.56Z" fill="#1487E3"/>
<path d="M130.44 114.796C129.736 114.613 129.032 114.445 128.336 114.285C124.253 113.462 120.118 112.928 115.96 112.686C115.344 112.638 114.736 112.606 114.128 112.574L113.064 112.526L113.864 113.925C113.976 114.117 116.696 118.561 123.464 123.341L124.912 124.348L126.304 123.285C127.645 122.324 128.74 121.062 129.501 119.6C130.263 118.137 130.67 116.517 130.688 114.868L130.44 114.796Z" fill="white"/>
<path d="M118.968 104.469C118.712 107.834 117.92 111.263 115.96 112.702C115.96 112.702 118.456 116.85 124.92 121.382C128.968 118.289 128.336 114.285 128.336 114.285C124.032 112.686 124.336 109.705 126.152 104.949L122.792 103.142C122.502 102.823 122.125 102.596 121.707 102.49C121.289 102.383 120.849 102.402 120.441 102.543C120.034 102.685 119.677 102.942 119.415 103.285C119.153 103.627 118.998 104.039 118.968 104.469V104.469Z" fill="#FDA982"/>
<path opacity="0.2" d="M122.792 103.142C122.52 102.842 122.17 102.623 121.78 102.508C121.391 102.394 120.978 102.39 120.586 102.496C120.195 102.602 119.84 102.814 119.562 103.108C119.283 103.403 119.091 103.769 119.008 104.166C120.284 106.737 122.343 108.838 124.888 110.168C124.979 108.366 125.405 106.596 126.144 104.949L122.792 103.142Z" fill="#0D2C54"/>
<path d="M113.736 93.9184C115.408 100.417 115.976 103.198 119.952 105.908C125.928 110.016 133.552 106.547 133.848 99.6894C134.128 93.5108 131.352 83.9192 124.408 82.5444C122.875 82.2313 121.287 82.3157 119.796 82.7896C118.305 83.2635 116.961 84.1112 115.891 85.2516C114.821 86.3921 114.061 87.7875 113.684 89.3046C113.306 90.8218 113.324 92.4102 113.736 93.9184V93.9184Z" fill="#FDA982"/>
<path d="M130.288 83.8869C126.371 87.366 121.724 89.9251 116.688 91.3763C116.688 91.3763 121.656 101.935 112.36 99.2734C109.664 90.2813 112.36 85.6134 114.76 83.4153C122.152 81.0174 127.352 80.2181 130.288 83.8869Z" fill="#4D4D4F"/>
<path d="M113.424 100.321C113.969 101.498 114.959 102.412 116.176 102.863C117.776 103.438 118.728 102.063 118.384 100.465C118.072 99.034 116.856 97.0038 115.184 97.1077C114.822 97.1385 114.474 97.2583 114.17 97.4563C113.866 97.6543 113.616 97.9244 113.441 98.2425C113.267 98.5607 113.174 98.9169 113.171 99.2795C113.168 99.6422 113.255 99.9999 113.424 100.321V100.321Z" fill="#FDA982"/>
<path d="M103.608 152.364L102.064 154.642C101.944 154.817 102.144 155.041 102.456 155.081L131.032 158.742C131.28 158.742 131.496 158.678 131.536 158.526L132.096 156.128C132.096 155.96 131.936 155.793 131.672 155.753L104.072 152.22C103.989 152.2 103.902 152.203 103.82 152.229C103.739 152.254 103.666 152.301 103.608 152.364V152.364Z" fill="#1487E3"/>
<path d="M106.912 155.92L106.176 155.824C106.032 155.824 105.928 155.712 105.952 155.624L106.704 152.499C106.733 152.447 106.778 152.405 106.834 152.382C106.889 152.359 106.95 152.355 107.008 152.371L107.752 152.467C107.896 152.467 108 152.571 107.976 152.667L107.224 155.784C107.2 155.88 107.064 155.936 106.912 155.92Z" fill="#4D4D4F"/>
<path d="M128.92 158.734L128.184 158.646C128.04 158.646 127.936 158.534 127.96 158.438L128.712 155.321C128.712 155.233 128.872 155.169 129.016 155.185L129.76 155.281C129.904 155.281 130.008 155.393 129.984 155.481L129.232 158.606C129.208 158.694 129.072 158.758 128.92 158.734Z" fill="#4D4D4F"/>
<path d="M117.92 157.327L117.184 157.231C117.032 157.231 116.928 157.127 116.952 157.031L117.704 153.914C117.704 153.818 117.864 153.762 118.016 153.778L118.752 153.874C118.896 153.874 119 153.986 118.984 154.074L118.224 157.199C118.208 157.287 118.064 157.343 117.92 157.327Z" fill="#4D4D4F"/>
<path d="M124.88 93.6386C124.976 94.1742 125.344 94.5578 125.68 94.4859C126.016 94.4139 126.232 93.9344 126.128 93.3988C126.024 92.8633 125.656 92.4796 125.328 92.5516C125 92.6235 124.8 93.1031 124.88 93.6386Z" fill="#4D4D4F"/>
<path d="M130.928 92.48C131.032 93.0156 131.4 93.3912 131.728 93.3273C132.056 93.2633 132.28 92.7678 132.176 92.2322C132.072 91.6967 131.704 91.321 131.376 91.385C131.048 91.4489 130.832 91.9445 130.928 92.48Z" fill="#4D4D4F"/>
<path d="M131.2 91.4492L132.416 90.8252C132.416 90.8252 132 91.9612 131.2 91.4492Z" fill="#4D4D4F"/>
<path d="M129.328 93.6791C130.043 94.8051 130.907 95.8295 131.896 96.7244C131.662 96.9733 131.375 97.1675 131.057 97.2932C130.739 97.4189 130.397 97.4731 130.056 97.4518L129.328 93.6791Z" fill="#DC8056"/>
<path d="M125.552 99.6099C125.528 99.6378 125.514 99.6733 125.513 99.7102C125.512 99.7471 125.524 99.7832 125.547 99.8124C125.569 99.8416 125.601 99.8621 125.637 99.8704C125.673 99.8787 125.711 99.8742 125.744 99.8579C126.348 99.5495 127.017 99.3888 127.696 99.3888C128.375 99.3888 129.044 99.5495 129.648 99.8579C129.684 99.8785 129.727 99.8842 129.767 99.8737C129.808 99.8633 129.842 99.8375 129.864 99.8019C129.886 99.7654 129.893 99.722 129.884 99.6804C129.875 99.6389 129.851 99.6022 129.816 99.5779C129.164 99.2437 128.441 99.0694 127.708 99.0694C126.975 99.0694 126.252 99.2437 125.6 99.5779C125.6 99.5779 125.6 99.5939 125.552 99.6099Z" fill="#4D4D4F"/>
<path d="M122.792 91.9922C122.841 91.9618 122.88 91.9173 122.904 91.8643C123.071 91.5 123.322 91.1804 123.636 90.9317C123.95 90.683 124.319 90.5123 124.712 90.4336C124.754 90.4287 124.795 90.4154 124.832 90.3946C124.869 90.3738 124.901 90.3458 124.927 90.3123C124.953 90.2788 124.973 90.2405 124.984 90.1996C124.995 90.1587 124.998 90.1159 124.992 90.0739C124.988 90.0321 124.976 89.9916 124.956 89.9547C124.936 89.9178 124.908 89.8853 124.876 89.8592C124.843 89.833 124.805 89.8138 124.764 89.8026C124.724 89.7915 124.682 89.7886 124.64 89.7941C124.14 89.8875 123.669 90.0986 123.267 90.41C122.865 90.7214 122.543 91.124 122.328 91.5846C122.297 91.6476 122.288 91.7192 122.301 91.7881C122.315 91.857 122.351 91.9195 122.404 91.9659C122.457 92.0123 122.523 92.04 122.593 92.0448C122.664 92.0495 122.733 92.0311 122.792 91.9922V91.9922Z" fill="#4D4D4F"/>
<path d="M132.728 87.9398L132.768 87.8999C132.792 87.8653 132.81 87.8262 132.819 87.785C132.828 87.7437 132.829 87.701 132.822 87.6594C132.814 87.6177 132.798 87.578 132.776 87.5424C132.753 87.5069 132.723 87.4762 132.688 87.4522C132.274 87.1553 131.796 86.9612 131.292 86.8861C130.789 86.811 130.274 86.8571 129.792 87.0206C129.715 87.0542 129.654 87.116 129.621 87.1933C129.588 87.2707 129.586 87.3576 129.616 87.4363C129.648 87.5146 129.71 87.5771 129.788 87.61C129.866 87.643 129.953 87.6437 130.032 87.6121C130.415 87.4867 130.823 87.4546 131.221 87.5186C131.619 87.5825 131.996 87.7407 132.32 87.9798C132.381 88.0235 132.456 88.0435 132.531 88.0362C132.606 88.0288 132.676 87.9946 132.728 87.9398V87.9398Z" fill="#4D4D4F"/>
<path d="M125.176 92.6081L126.392 91.9921C126.392 91.9921 125.92 93.1201 125.176 92.6081Z" fill="#4D4D4F"/>
<path d="M83.288 204.821C82.1661 204.821 81.0896 204.379 80.2926 203.59C79.4956 202.801 79.0425 201.73 79.032 200.609C78.92 189.259 82.704 179.675 89.976 172.897C98.928 164.553 112.888 160.676 129.304 161.971C140.816 162.882 148.2 160.084 156.744 156.831C158.152 156.304 159.568 155.76 161.024 155.233C162.085 154.847 163.256 154.898 164.279 155.375C165.302 155.851 166.094 156.715 166.48 157.774C166.866 158.834 166.815 160.004 166.338 161.027C165.861 162.049 164.997 162.84 163.936 163.226C162.52 163.737 161.144 164.265 159.776 164.784C151.064 168.093 142.064 171.506 128.632 170.451C114.632 169.34 102.968 172.418 95.784 179.116C90.184 184.295 87.456 191.497 87.544 200.529C87.5493 201.088 87.4444 201.642 87.2354 202.159C87.0264 202.677 86.7173 203.149 86.3258 203.548C85.9343 203.946 85.4681 204.264 84.9537 204.482C84.4393 204.701 83.8869 204.816 83.328 204.821H83.288Z" fill="#1487E3"/>
<path d="M181.716 169.23L164.597 175.496C163.168 176.019 161.59 175.954 160.21 175.314C158.83 174.675 157.761 173.514 157.238 172.087L150.168 152.806C149.645 151.378 149.71 149.802 150.35 148.423C150.99 147.044 152.152 145.976 153.581 145.453L170.7 139.187L181.708 169.208L181.716 169.23Z" fill="#1487E3"/>
<path opacity="0.7" d="M181.716 169.23L164.597 175.496C163.168 176.019 161.59 175.954 160.21 175.314C158.83 174.675 157.761 173.514 157.238 172.087L150.168 152.806C149.645 151.378 149.71 149.802 150.35 148.423C150.99 147.044 152.152 145.976 153.581 145.453L170.7 139.187L181.708 169.208L181.716 169.23Z" fill="white"/>
<path opacity="0.2" d="M163.072 141.973L170.712 139.183L181.72 169.221L174.08 172.019C173.571 171.527 173.183 170.926 172.944 170.26L163.344 144.051C163.093 143.389 163 142.677 163.072 141.973V141.973Z" fill="#1487E3"/>
<path d="M187.869 146.665L187.118 146.94L188.95 151.938L189.702 151.663L187.869 146.665Z" fill="#1487E3"/>
<path d="M185.96 143.827L183.264 136.457C182.802 135.208 181.967 134.129 180.873 133.368C179.779 132.606 178.477 132.198 177.144 132.197C176.385 132.195 175.632 132.327 174.92 132.589L170.056 134.371L169.776 133.62L174.648 131.837C175.447 131.544 176.293 131.396 177.144 131.398C178.642 131.401 180.104 131.863 181.332 132.72C182.56 133.577 183.497 134.789 184.016 136.193L186.712 143.563L185.96 143.827Z" fill="#1487E3"/>
<path d="M181.849 171.383L188.993 168.801C191.327 167.958 192.535 165.384 191.691 163.051L181.994 136.266C181.15 133.934 178.573 132.727 176.239 133.571L169.095 136.153C166.761 136.996 165.553 139.57 166.397 141.902L176.094 168.688C176.938 171.02 179.515 172.227 181.849 171.383Z" fill="#1487E3"/>
<path d="M177.76 144.394C177.923 144.84 177.95 145.324 177.838 145.785C177.726 146.246 177.479 146.663 177.129 146.984C176.779 147.305 176.342 147.515 175.872 147.588C175.403 147.66 174.923 147.592 174.492 147.391C174.062 147.191 173.7 146.867 173.454 146.461C173.208 146.055 173.087 145.586 173.108 145.112C173.129 144.638 173.291 144.18 173.572 143.798C173.853 143.416 174.241 143.125 174.688 142.963C175.285 142.747 175.944 142.776 176.52 143.044C177.096 143.313 177.542 143.798 177.76 144.394Z" fill="#4D4D4F"/>
<path d="M183.736 160.692C183.898 161.137 183.925 161.621 183.813 162.082C183.7 162.542 183.453 162.959 183.104 163.28C182.754 163.6 182.317 163.81 181.848 163.883C181.379 163.955 180.9 163.887 180.469 163.687C180.039 163.488 179.678 163.165 179.431 162.76C179.184 162.355 179.063 161.886 179.083 161.412C179.103 160.938 179.263 160.481 179.543 160.098C179.823 159.716 180.21 159.424 180.656 159.261C180.952 159.153 181.267 159.104 181.582 159.117C181.897 159.131 182.207 159.206 182.493 159.339C182.779 159.472 183.036 159.659 183.249 159.892C183.463 160.124 183.628 160.396 183.736 160.692V160.692Z" fill="#4D4D4F"/>
<path d="M113.6 158.766L113.536 158.694C112.288 157.447 110.968 156.089 109.776 154.698C108.584 153.307 107.416 151.916 106.352 150.477C105.309 149.074 104.36 147.603 103.512 146.073C102.905 144.98 102.389 143.838 101.968 142.66C102.448 141.062 102.96 139.463 103.568 137.928C104.264 136.082 105.048 134.268 105.904 132.477C106.76 130.687 107.672 128.928 108.656 127.21C109.136 126.347 109.64 125.491 110.16 124.652L110.96 123.421C111.208 123.021 111.504 122.622 111.712 122.278L111.8 122.15C112.369 121.284 112.579 120.231 112.386 119.214C112.192 118.196 111.611 117.293 110.765 116.695C109.918 116.097 108.873 115.85 107.848 116.008C106.823 116.165 105.9 116.714 105.272 117.538C104.872 118.082 104.568 118.513 104.248 118.985C103.928 119.457 103.64 119.928 103.336 120.4C102.752 121.343 102.184 122.294 101.64 123.261C100.558 125.191 99.5778 127.176 98.704 129.208C98.256 130.215 97.904 131.246 97.448 132.277C96.992 133.308 96.688 134.356 96.368 135.427C95.6889 137.549 95.1307 139.708 94.696 141.893V142.077C94.5718 142.66 94.6134 143.266 94.816 143.827C95.5912 145.877 96.6205 147.821 97.88 149.614C99.066 151.316 100.388 152.92 101.832 154.41C103.239 155.845 104.732 157.193 106.304 158.446C107.875 159.698 109.524 160.848 111.24 161.891C111.657 162.135 112.148 162.218 112.622 162.126C113.096 162.033 113.52 161.771 113.814 161.389C114.109 161.007 114.253 160.53 114.221 160.049C114.189 159.567 113.983 159.114 113.64 158.774L113.6 158.766Z" fill="#FDA982"/>
<path d="M122.896 162.738L122.792 162.53C122.46 161.838 121.971 161.232 121.365 160.76C120.758 160.288 120.05 159.964 119.296 159.813L113.224 158.582L110.256 160.061C110.576 163.386 115.328 165.376 115.328 165.376L116.68 166.919C117.136 167.436 117.767 167.767 118.452 167.848C119.137 167.929 119.828 167.755 120.392 167.358L121.992 166.215C122.53 165.836 122.916 165.279 123.081 164.642C123.247 164.006 123.181 163.331 122.896 162.738V162.738Z" fill="#F4845F"/>
<path d="M108.152 113.046C104.784 114.588 101.2 119.928 101.2 119.928L108.4 133.396C108.4 133.396 116.584 123.972 115.896 119.256C115.336 115.252 112.44 111.079 108.152 113.046Z" fill="#1487E3"/>
<g opacity="0.3">
<path opacity="0.3" d="M108.152 113.045C104.784 114.588 101.2 119.927 101.2 119.927L108.4 133.396C108.4 133.396 116.584 123.972 115.896 119.256C115.336 115.252 112.44 111.079 108.152 113.045Z" fill="white"/>
</g>
<path d="M338.632 289.515C331.584 289.515 324.656 288.068 321.296 283.688C305.392 262.906 305.536 221.103 305.672 180.65C305.752 158.869 307.472 138.287 305.048 122.301C303.624 112.877 298.648 106.411 293.624 103.07C286.168 98.1459 275.928 100.792 271.552 101.919C271.007 102.071 270.437 102.113 269.875 102.042C269.314 101.972 268.772 101.79 268.282 101.507C267.791 101.225 267.362 100.848 267.02 100.398C266.677 99.9478 266.427 99.434 266.286 98.8865C266.144 98.3389 266.114 97.7687 266.195 97.2092C266.277 96.6496 266.47 96.112 266.762 95.6277C267.054 95.1434 267.44 94.7223 267.897 94.3888C268.354 94.0554 268.873 93.8164 269.424 93.6858C274.888 92.271 287.704 88.9699 298.312 95.9718C305.472 100.696 311.664 109.128 313.464 121.03C315.984 137.655 314.264 158.541 314.184 180.65C314.048 219.68 313.912 260.037 328.056 278.492C329.2 279.979 333.656 281.314 341.056 280.93C341.712 280.93 342.352 280.858 342.984 280.802C342.698 280.223 342.55 279.586 342.552 278.94C342.552 277.812 343 276.731 343.799 275.933C344.597 275.136 345.679 274.688 346.808 274.688C356.112 274.688 361.008 275.487 361.952 279.739C362.224 280.962 362.368 284.032 357.872 286.134C354.512 287.708 346.496 289.515 338.632 289.515Z" fill="#1487E3"/>
<path d="M245.496 293.184L236.816 289.771L241.392 274.736L250.08 278.141L245.496 293.184Z" fill="#FDA982"/>
<path d="M236.608 288.852L246.4 292.688C246.524 292.739 246.627 292.829 246.694 292.945C246.761 293.061 246.787 293.195 246.768 293.328L245.808 299.418C245.783 299.566 245.725 299.705 245.639 299.827C245.553 299.949 245.44 300.05 245.31 300.123C245.179 300.196 245.034 300.239 244.885 300.248C244.736 300.258 244.587 300.234 244.448 300.178C241.992 299.163 238.144 297.484 235.032 296.261C233.12 295.51 229.48 294.311 226.832 293.272C224.184 292.233 224.872 289.483 226.032 289.675C231.304 290.522 233.32 289.619 235.424 288.836C235.806 288.689 236.23 288.695 236.608 288.852V288.852Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M250.08 278.149L241.392 274.736L239.032 282.489L247.72 285.902L250.08 278.149Z" fill="#0D2C54"/>
<path d="M260.936 161.771C260.936 161.771 253.08 200.569 250.08 219.704C246.704 241.149 237.784 280.323 237.784 280.323L249.328 284.855C249.328 284.855 265.816 244.746 268.112 225.323C275.032 209.537 288.792 163.081 288.792 163.081L260.936 161.771Z" fill="#1487E3"/>
<path d="M237.224 280.387L249.896 285.366L252.064 281.53L237.896 275.663L237.224 280.387Z" fill="#4D4D4F"/>
<path d="M234.12 289.019C233.678 288.882 233.295 288.6 233.032 288.22C232.959 288.079 232.921 287.923 232.921 287.765C232.921 287.606 232.959 287.45 233.032 287.309C233.064 287.222 233.121 287.146 233.194 287.09C233.268 287.033 233.356 286.998 233.448 286.989C234.288 286.901 235.848 288.947 236 289.187C236.017 289.213 236.026 289.244 236.026 289.275C236.026 289.306 236.017 289.337 236 289.363C235.983 289.389 235.959 289.41 235.931 289.423C235.902 289.436 235.871 289.44 235.84 289.435C235.253 289.359 234.677 289.22 234.12 289.019V289.019ZM233.696 287.349C233.632 287.317 233.56 287.303 233.488 287.309C233.44 287.309 233.384 287.309 233.328 287.445C233.275 287.54 233.247 287.647 233.247 287.757C233.247 287.866 233.275 287.973 233.328 288.068C233.56 288.492 234.36 288.868 235.52 289.059C235.033 288.373 234.413 287.791 233.696 287.349V287.349Z" fill="#1487E3"/>
<path d="M235.824 289.427C235.798 289.418 235.776 289.401 235.76 289.379C235.248 288.771 234.528 286.893 234.96 286.246C235.027 286.145 235.126 286.071 235.241 286.034C235.356 285.998 235.48 286.002 235.592 286.046C235.711 286.076 235.822 286.131 235.919 286.207C236.015 286.282 236.094 286.377 236.152 286.485C236.648 287.373 236.088 289.243 236.064 289.323C236.055 289.351 236.038 289.377 236.015 289.396C235.993 289.416 235.965 289.429 235.936 289.435C235.918 289.444 235.899 289.447 235.879 289.446C235.859 289.445 235.84 289.438 235.824 289.427V289.427ZM235.552 286.374H235.488C235.28 286.326 235.232 286.374 235.208 286.446C234.968 286.829 235.36 288.188 235.816 288.931C236.044 288.192 236.044 287.401 235.816 286.661C235.765 286.537 235.672 286.435 235.552 286.374V286.374Z" fill="#1487E3"/>
<path opacity="0.2" d="M277.872 185.262C273.52 190.649 271.8 204.381 271.816 215.827C274.752 207.746 278.12 197.483 281.08 188.163L277.872 185.262Z" fill="#0D2C54"/>
<path d="M297.736 294.63H288.408L287.168 278.964H296.496L297.736 294.63Z" fill="#FDA982"/>
<path d="M287.88 293.847H298.4C298.535 293.846 298.666 293.892 298.771 293.976C298.876 294.061 298.948 294.179 298.976 294.311L300.312 300.322C300.343 300.468 300.341 300.62 300.305 300.766C300.27 300.912 300.202 301.048 300.107 301.164C300.011 301.279 299.891 301.372 299.755 301.436C299.619 301.499 299.47 301.531 299.32 301.528C296.664 301.48 292.48 301.321 289.136 301.321C287.072 301.321 283.248 301.536 280.408 301.536C277.568 301.536 277.208 298.731 278.352 298.475C283.576 297.332 285.128 295.765 286.792 294.263C287.092 293.997 287.479 293.849 287.88 293.847V293.847Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M296.496 278.964H287.16L287.808 287.045H297.144L296.496 278.964Z" fill="#0D2C54"/>
<path d="M272.232 162.258C272.232 162.258 277.4 205.492 278.488 221.055C280 242.716 285.848 285.478 285.848 285.478H298.248C298.248 285.478 299.576 241.804 297.336 222.373C300.424 205.42 299.448 163.561 299.448 163.561L272.232 162.258Z" fill="#1487E3"/>
<path d="M285.352 285.742H298.968L299.584 281.386L284.248 281.099L285.352 285.742Z" fill="#4D4D4F"/>
<path d="M285.6 294.91C285.139 294.945 284.68 294.824 284.296 294.566C284.177 294.462 284.084 294.331 284.026 294.183C283.967 294.036 283.945 293.877 283.96 293.719C283.96 293.625 283.986 293.532 284.036 293.452C284.085 293.371 284.156 293.306 284.24 293.264C284.992 292.88 287.176 294.223 287.44 294.375C287.468 294.39 287.49 294.414 287.504 294.442C287.519 294.471 287.524 294.503 287.52 294.534C287.513 294.566 287.497 294.596 287.475 294.62C287.452 294.644 287.424 294.661 287.392 294.67C286.806 294.818 286.204 294.898 285.6 294.91ZM284.592 293.511C284.52 293.507 284.449 293.521 284.384 293.551C284.344 293.551 284.296 293.615 284.288 293.735C284.271 293.843 284.282 293.953 284.32 294.055C284.358 294.157 284.421 294.248 284.504 294.319C284.872 294.63 285.76 294.67 286.904 294.438C286.208 293.985 285.431 293.671 284.616 293.511H284.592Z" fill="#1487E3"/>
<path d="M287.36 294.671H287.28C286.584 294.287 285.224 292.808 285.368 292.049C285.392 291.931 285.456 291.826 285.55 291.75C285.643 291.674 285.76 291.633 285.88 291.633C286.001 291.616 286.124 291.625 286.242 291.66C286.359 291.694 286.468 291.753 286.56 291.833C287.36 292.481 287.512 294.431 287.52 294.511C287.522 294.54 287.516 294.569 287.503 294.596C287.491 294.623 287.472 294.645 287.448 294.663C287.42 294.673 287.39 294.675 287.36 294.671V294.671ZM285.992 291.929H285.92C285.712 291.929 285.696 292.057 285.688 292.097C285.6 292.537 286.488 293.696 287.16 294.191C287.111 293.419 286.833 292.678 286.36 292.065C286.256 291.98 286.126 291.932 285.992 291.929V291.929Z" fill="#1487E3"/>
<path d="M276.512 102.638L269.04 102.542C266.528 102.542 264.032 102.462 261.56 102.422C260.808 102.422 260.072 102.374 259.328 102.342C259.376 102.342 259.408 102.255 259.448 102.215C259.976 101.655 260.504 101.08 261.048 100.528C261.32 100.24 261.587 99.9605 261.848 99.6888C262.096 99.417 262.416 99.1133 262.584 98.8895L263.264 98.0262C263.8 97.3431 264.107 96.5087 264.142 95.6413C264.176 94.774 263.937 93.9178 263.457 93.1942C262.977 92.4706 262.281 91.9163 261.468 91.6101C260.655 91.3038 259.767 91.2611 258.928 91.4879L257.448 91.8876C256.992 92.0235 256.536 92.1753 256.08 92.3272C255.184 92.6389 254.304 92.9826 253.432 93.3583C251.675 94.1335 249.977 95.0365 248.352 96.0599C246.669 97.1347 245.077 98.3457 243.592 99.6808C242.02 101.108 240.597 102.691 239.344 104.405C239.028 104.832 238.759 105.293 238.544 105.779C237.801 107.481 237.764 109.407 238.443 111.135C239.121 112.863 240.458 114.252 242.16 114.995L242.888 115.315C244.532 116.022 246.234 116.583 247.976 116.994C249.496 117.385 250.936 117.641 252.352 117.881C253.768 118.121 255.152 118.313 256.528 118.48C257.904 118.648 259.264 118.776 260.624 118.888C263.336 119.128 266.024 119.248 268.704 119.328C271.384 119.408 274.008 119.408 276.792 119.28C278.963 119.187 281.012 118.251 282.502 116.672C283.993 115.092 284.807 112.993 284.771 110.823C284.736 108.652 283.854 106.581 282.312 105.051C280.771 103.52 278.693 102.652 276.52 102.63L276.512 102.638Z" fill="#FDA982"/>
<path d="M280.864 117.226C274.408 124.579 258.872 121.83 258.872 121.83L259.728 103.51C265.053 102.339 270.484 101.709 275.936 101.631C285.056 101.615 286.032 111.327 280.864 117.226Z" fill="#4D4D4F"/>
<path d="M263.632 111.59C263.248 114.548 262.832 118.528 262.584 123.884C259.216 140.909 257.96 152.97 260.936 161.771C263.288 164.672 293.936 164.113 299.448 163.553C302.648 155.64 302.984 132.924 303.36 113.828C303.407 111.197 302.509 108.636 300.829 106.609C299.148 104.583 296.796 103.225 294.2 102.782C287.303 101.561 280.268 101.324 273.304 102.079C270.86 102.356 268.579 103.444 266.826 105.168C265.073 106.892 263.949 109.153 263.632 111.59V111.59Z" fill="#4D4D4F"/>
<path opacity="0.2" d="M293.368 123.212C293.472 129.031 297.696 138.327 302.448 143.043C302.736 138.127 302.92 132.796 303.064 127.416L293.368 123.212Z" fill="#0D2C54"/>
<path d="M320.072 134.523L319.8 133.883C318.682 131.217 317.374 128.634 315.888 126.154C314.464 123.756 312.928 121.494 311.336 119.312C309.744 117.13 308.056 115.044 306.328 113.013C305.448 112.006 304.576 110.999 303.656 110.024L302.272 108.569L300.8 107.083C299.258 105.561 297.184 104.7 295.017 104.681C292.85 104.661 290.761 105.486 289.192 106.979C287.624 108.473 286.699 110.518 286.613 112.682C286.528 114.845 287.29 116.956 288.736 118.569L288.976 118.832L293.672 124.052C295.272 125.826 296.8 127.633 298.32 129.431C299.056 130.334 299.816 131.237 300.504 132.157C301.192 133.076 301.92 133.963 302.608 134.874C303.6 136.257 304.56 137.632 305.416 138.983C305.285 139.665 305.119 140.34 304.92 141.005C304.345 142.941 303.67 144.846 302.896 146.712C302.096 148.686 301.248 150.708 300.368 152.699C299.488 154.689 298.544 156.767 297.704 158.765L297.624 158.941C297.255 159.825 297.199 160.807 297.465 161.726C297.731 162.646 298.303 163.447 299.087 163.998C299.87 164.548 300.818 164.815 301.774 164.755C302.73 164.695 303.636 164.31 304.344 163.665C306.109 162.031 307.783 160.302 309.36 158.486C310.96 156.69 312.461 154.798 313.864 152.811C314.576 151.811 315.256 150.772 315.928 149.693C316.626 148.596 317.264 147.462 317.84 146.296C318.488 145.025 319.049 143.711 319.52 142.364C320.134 140.639 320.479 138.83 320.544 137C320.565 136.15 320.404 135.306 320.072 134.523V134.523Z" fill="#FDA982"/>
<path d="M294.744 102.886C305.536 104.732 312.568 119.328 312.568 119.328L299.56 133.307C299.56 133.307 292.064 125.682 287.56 118.616C282.704 110.927 287.016 101.575 294.744 102.886Z" fill="#4D4D4F"/>
<path d="M275.032 79.8741C273.224 76.8447 272.632 72.6803 275.944 68.436C275.299 68.5169 274.644 68.4089 274.059 68.1251C273.474 67.8413 272.984 67.394 272.648 66.8374C276.275 63.8729 280.835 62.2872 285.52 62.3614C295.2 62.3614 295.92 66.0941 295.592 68.6439C302.216 69.0675 307.048 86.2765 290.256 89.3218C280.648 79.2106 275.032 79.8741 275.032 79.8741Z" fill="#4D4D4F"/>
<path d="M294.984 68.9556C294.984 68.9556 297.68 66.206 294.328 62.8809C295.079 63.0868 295.765 63.4836 296.317 64.0327C296.869 64.5817 297.27 65.2644 297.48 66.0141C297.952 67.9484 296.648 69.4991 294.984 68.9556Z" fill="#4D4D4F"/>
<path d="M291.272 87.2195C291.016 92.5189 290.536 98.5456 291.424 102.774C290.048 104.94 286.528 107.434 280.224 107.993C277.984 106.722 275.024 103.613 276.056 102.015C278.736 100.296 279.872 95.0926 279.104 91.2L291.272 87.2195Z" fill="#FDA982"/>
<path opacity="0.2" d="M288.2 88.2186L279.088 91.2C279.398 92.8577 279.411 94.5572 279.128 96.2196C281.848 96.1317 285.616 94.0855 287.032 92.1672C287.493 91.6278 287.836 90.9975 288.037 90.3172C288.238 89.6369 288.294 88.9218 288.2 88.2186Z" fill="#0D2C54"/>
<path d="M293.928 78.8273C292.24 85.3256 291.584 89.2182 287.6 91.9438C281.608 96.0442 274.072 91.4482 273.776 84.5822C273.52 78.3956 276.32 68.804 283.28 67.4372C284.813 67.1301 286.398 67.2196 287.886 67.6972C289.375 68.1748 290.716 69.0246 291.783 70.1658C292.85 71.3071 293.607 72.702 293.982 74.2179C294.358 75.7339 294.339 77.3206 293.928 78.8273V78.8273Z" fill="#FDA982"/>
<path d="M292.584 69.523C289.728 71.5532 286.184 80.1537 293.384 83.6067C299.848 77.9317 298.176 69.523 292.584 69.523Z" fill="#4D4D4F"/>
<path d="M294.232 70.5542C295.456 64.8951 281.16 61.9697 276.632 68.5799C275.096 72.5604 293.368 76.0773 294.232 70.5542Z" fill="#4D4D4F"/>
<path d="M283.568 85.7248C282.083 85.0538 280.49 84.6506 278.864 84.5338C278.841 84.5338 278.819 84.5384 278.798 84.5473C278.777 84.5563 278.758 84.5693 278.742 84.5858C278.727 84.6022 278.714 84.6217 278.706 84.643C278.698 84.6643 278.695 84.687 278.696 84.7097C278.705 84.7614 278.731 84.8085 278.77 84.8433C278.81 84.8782 278.86 84.8987 278.912 84.9015C280.471 85.0141 281.998 85.3982 283.424 86.0365C283.469 86.0562 283.519 86.058 283.565 86.0417C283.612 86.0253 283.65 85.9921 283.672 85.9486C283.681 85.9272 283.685 85.9041 283.685 85.881C283.684 85.8578 283.679 85.835 283.669 85.8139C283.659 85.7929 283.645 85.7741 283.628 85.7588C283.61 85.7435 283.59 85.7319 283.568 85.7248V85.7248Z" fill="#4D4D4F"/>
<path d="M295.488 85.933C294.329 87.1625 292.73 87.8837 291.04 87.9392C288.8 87.9872 288.232 85.8531 289.312 84.0307C290.288 82.4321 292.624 80.3858 294.656 81.2011C296.688 82.0164 296.896 84.4063 295.488 85.933Z" fill="#FDA982"/>
<path d="M283.584 78.7151C283.48 79.2507 283.112 79.6343 282.784 79.5624C282.456 79.4904 282.24 79.0029 282.344 78.4673C282.448 77.9318 282.816 77.5561 283.144 77.668C283.472 77.7799 283.688 78.1556 283.584 78.7151Z" fill="#4D4D4F"/>
<path d="M277.528 77.5401C277.424 78.0757 277.056 78.4513 276.728 78.3874C276.4 78.3234 276.176 77.8279 276.28 77.2923C276.384 76.7568 276.752 76.3732 277.08 76.4451C277.408 76.517 277.6 77.0046 277.528 77.5401Z" fill="#4D4D4F"/>
<path d="M279.4 78.1556C278.44 79.6684 277.277 81.0427 275.944 82.2401C276.744 83.3031 278.512 83.0953 278.512 83.0953L279.4 78.1556Z" fill="#DC8056"/>
<path d="M282.712 75.6058C282.769 75.5933 282.828 75.5955 282.884 75.6123C282.94 75.6291 282.991 75.6598 283.032 75.7017C283.289 76.0119 283.616 76.2569 283.986 76.4166C284.355 76.5764 284.758 76.6464 285.16 76.6209C285.245 76.6093 285.331 76.6313 285.399 76.682C285.468 76.7328 285.514 76.8084 285.528 76.8926C285.535 76.9342 285.533 76.9767 285.523 77.0176C285.513 77.0585 285.494 77.097 285.469 77.1306C285.444 77.1642 285.412 77.1923 285.375 77.2132C285.338 77.2342 285.298 77.2475 285.256 77.2523C284.748 77.2932 284.238 77.2122 283.767 77.0162C283.297 76.8201 282.88 76.5147 282.552 76.1253C282.522 76.0962 282.498 76.0615 282.482 76.023C282.466 75.9846 282.457 75.9432 282.457 75.9015C282.457 75.8597 282.466 75.8184 282.482 75.78C282.498 75.7415 282.522 75.7067 282.552 75.6777C282.599 75.6424 282.654 75.6179 282.712 75.6058V75.6058Z" fill="#4D4D4F"/>
<path d="M275.656 73.8315C275.589 73.8177 275.53 73.7809 275.488 73.7276C275.461 73.6943 275.441 73.6559 275.429 73.6147C275.418 73.5736 275.414 73.5304 275.42 73.488C275.425 73.4455 275.438 73.4045 275.46 73.3674C275.481 73.3303 275.51 73.2978 275.544 73.272C275.937 72.9502 276.401 72.7266 276.898 72.6196C277.394 72.5126 277.909 72.5252 278.4 72.6565C278.481 72.6839 278.548 72.7419 278.587 72.8181C278.626 72.8942 278.633 72.9825 278.608 73.0642C278.576 73.1413 278.517 73.2042 278.442 73.241C278.367 73.2777 278.281 73.2859 278.2 73.264V73.264C277.809 73.1644 277.4 73.1588 277.006 73.2477C276.613 73.3366 276.246 73.5175 275.936 73.7756C275.899 73.8091 275.853 73.832 275.804 73.8419C275.754 73.8517 275.703 73.8481 275.656 73.8315V73.8315Z" fill="#4D4D4F"/>
<path d="M301.48 158.533L294.608 158.485L298.16 166.366C298.16 166.366 304.024 165.112 303.704 161.93L301.48 158.533Z" fill="#FDA982"/>
<path d="M291.112 163.897L292.24 166.119C292.543 166.71 293.057 167.168 293.679 167.401C294.302 167.635 294.99 167.628 295.608 167.382L298.16 166.366L294.608 158.485L291.88 160.564C291.38 160.94 291.03 161.481 290.889 162.091C290.749 162.7 290.828 163.339 291.112 163.897Z" fill="#FDA982"/>
<path d="M249.168 152.73C248.338 152.722 247.528 152.471 246.839 152.009C246.149 151.548 245.609 150.895 245.285 150.131C244.962 149.367 244.868 148.525 245.017 147.709C245.165 146.893 245.548 146.138 246.12 145.536C249.176 142.387 251.936 139.941 254.368 137.807C260.24 132.636 262.264 130.613 262.072 126.737C261.785 124.345 261.276 121.985 260.552 119.687C258.8 113.253 256.816 105.971 260.216 100.128C262.04 96.9868 265.136 94.8366 269.424 93.7336C270.508 93.4765 271.65 93.6545 272.604 94.2293C273.558 94.8041 274.249 95.7298 274.528 96.8077C274.806 97.8856 274.651 99.0297 274.094 99.9942C273.538 100.959 272.625 101.667 271.552 101.966C268.536 102.766 267.808 103.989 267.552 104.404C265.952 107.162 267.496 112.869 268.744 117.449C269.648 120.337 270.254 123.31 270.552 126.321C270.952 134.514 266.112 138.782 259.976 144.186C257.664 146.232 255.032 148.55 252.2 151.459C251.805 151.862 251.333 152.183 250.813 152.401C250.292 152.619 249.733 152.731 249.168 152.73V152.73Z" fill="#1487E3"/>
<path d="M258.504 91.6637L265.16 89.9612L263.608 98.4658C263.608 98.4658 257.616 98.6496 257.168 95.4924L258.504 91.6637Z" fill="#FDA982"/>
<path d="M269.832 94.4693L269.32 96.6913C269.163 97.3701 268.757 97.9655 268.183 98.3604C267.608 98.7554 266.907 98.9213 266.216 98.8255L263.608 98.4658L265.16 89.9612L268.216 91.2801C268.816 91.5385 269.307 91.9993 269.602 92.5821C269.898 93.1649 269.979 93.8328 269.832 94.4693Z" fill="#FDA982"/>
<path d="M246.788 138.745L246.607 138.564C245.798 137.756 244.486 137.756 243.677 138.564L220.087 162.143C219.278 162.951 219.278 164.262 220.087 165.07L220.268 165.251C221.077 166.059 222.389 166.059 223.198 165.251L246.789 141.672C247.598 140.864 247.598 139.553 246.788 138.745Z" fill="#4D4D4F"/>
<path d="M259.084 151.013L258.903 150.832C258.093 150.024 256.781 150.024 255.972 150.832L232.378 174.406C231.568 175.215 231.568 176.526 232.378 177.334L232.559 177.515C233.368 178.323 234.68 178.323 235.489 177.515L259.084 153.941C259.893 153.132 259.893 151.822 259.084 151.013Z" fill="#4D4D4F"/>
<path d="M227.53 147.521L244.155 130.91C244.688 130.378 245.32 129.956 246.016 129.668C246.712 129.38 247.458 129.232 248.211 129.232C248.965 129.232 249.71 129.38 250.406 129.668C251.102 129.956 251.735 130.378 252.267 130.91L266.8 145.43C267.332 145.962 267.755 146.594 268.043 147.289C268.331 147.985 268.48 148.73 268.48 149.483C268.48 150.235 268.331 150.98 268.043 151.676C267.755 152.371 267.332 153.003 266.8 153.535L250.174 170.146L227.53 147.521Z" fill="#1487E3"/>
<path opacity="0.7" d="M227.53 147.521L244.155 130.91C244.688 130.378 245.32 129.956 246.016 129.668C246.712 129.38 247.458 129.232 248.211 129.232C248.965 129.232 249.71 129.38 250.406 129.668C251.102 129.956 251.735 130.378 252.267 130.91L266.8 145.43C267.332 145.962 267.755 146.594 268.043 147.289C268.331 147.985 268.48 148.73 268.48 149.483C268.48 150.235 268.331 150.98 268.043 151.676C267.755 152.371 267.332 153.003 266.8 153.535L250.174 170.146L227.53 147.521Z" fill="white"/>
<path opacity="0.2" d="M233.264 141.748L227.504 147.495L250.152 170.123L255.904 164.368C255.675 163.699 255.295 163.092 254.792 162.594L235.04 142.899C234.546 142.382 233.938 141.988 233.264 141.748V141.748Z" fill="#1487E3"/>
<path d="M226.533 144.597L226.12 145.009C224.364 146.764 224.364 149.608 226.12 151.362L246.287 171.511C248.042 173.266 250.889 173.266 252.645 171.511L253.058 171.099C254.814 169.344 254.814 166.5 253.058 164.746L232.891 144.597C231.135 142.843 228.289 142.843 226.533 144.597Z" fill="#1487E3"/>
<path d="M289.216 0.591766H110.784C107.064 0.591766 104.048 3.60495 104.048 7.32189V39.8375C104.048 43.5544 107.064 46.5676 110.784 46.5676H289.216C292.936 46.5676 295.952 43.5544 295.952 39.8375V7.32189C295.952 3.60495 292.936 0.591766 289.216 0.591766Z" fill="white"/>
<path d="M289.216 47.1588H110.816C108.873 47.1567 107.01 46.3846 105.637 45.012C104.263 43.6394 103.49 41.7784 103.488 39.8372V7.32161C103.49 5.38045 104.263 3.51939 105.637 2.14679C107.01 0.774176 108.873 0.00211571 110.816 0H289.216C291.159 0.00211571 293.022 0.774176 294.395 2.14679C295.769 3.51939 296.542 5.38045 296.544 7.32161V39.8372C296.542 41.7784 295.769 43.6394 294.395 45.012C293.022 46.3846 291.159 47.1567 289.216 47.1588ZM110.816 1.18297C109.187 1.18508 107.626 1.83251 106.474 2.98327C105.322 4.13403 104.674 5.69419 104.672 7.32161V39.8372C104.674 41.4646 105.322 43.0248 106.474 44.1755C107.626 45.3263 109.187 45.9737 110.816 45.9759H289.216C290.845 45.9737 292.406 45.3263 293.558 44.1755C294.71 43.0248 295.358 41.4646 295.36 39.8372V7.32161C295.358 5.69419 294.71 4.13403 293.558 2.98327C292.406 1.83251 290.845 1.18508 289.216 1.18297H110.816Z" fill="#4D4D4F"/>
<g opacity="0.4">
<path opacity="0.4" d="M119.824 31.573V14.2762H126.352C127.235 14.2596 128.118 14.313 128.992 14.436C129.753 14.5458 130.482 14.8184 131.128 15.2353C131.727 15.6622 132.204 16.2381 132.512 16.9059C132.979 17.8969 133.144 19.003 132.988 20.0871C132.832 21.1712 132.36 22.1856 131.632 23.0045C130.699 24.017 129.008 24.5259 126.56 24.5312H122.112V31.5651L119.824 31.573ZM122.112 22.501H126.592C127.704 22.6146 128.821 22.3315 129.744 21.7017C130.062 21.4084 130.31 21.0484 130.472 20.6476C130.634 20.2468 130.705 19.8152 130.68 19.3837C130.699 18.7201 130.505 18.0678 130.128 17.5213C129.78 17.0218 129.267 16.6598 128.68 16.4982C127.975 16.373 127.259 16.3221 126.544 16.3463H122.112V22.501Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M143.872 30.0307C143.197 30.6236 142.43 31.1015 141.6 31.4454C140.85 31.7209 140.056 31.859 139.256 31.8531C138.1 31.9335 136.957 31.5736 136.056 30.846C135.696 30.523 135.41 30.1254 135.22 29.6809C135.029 29.2363 134.938 28.7556 134.952 28.2722C134.944 27.6838 135.087 27.1031 135.368 26.5857C135.644 26.1119 136.024 25.7073 136.48 25.4027C136.952 25.0916 137.471 24.8569 138.016 24.7073C138.639 24.5643 139.269 24.4575 139.904 24.3876C141.189 24.2707 142.459 24.0271 143.696 23.6602C143.696 23.3725 143.696 23.1807 143.696 23.1087C143.732 22.7786 143.696 22.4446 143.593 22.129C143.49 21.8135 143.32 21.5235 143.096 21.2783C142.409 20.7565 141.557 20.501 140.696 20.5589C139.922 20.5029 139.149 20.6841 138.48 21.0785C137.929 21.5637 137.557 22.2195 137.424 22.9409L135.344 22.6531C135.487 21.8743 135.808 21.1388 136.28 20.503C136.775 19.9138 137.427 19.4761 138.16 19.2401C139.077 18.9298 140.04 18.781 141.008 18.8005C141.893 18.7702 142.776 18.8974 143.616 19.1762C144.163 19.3315 144.655 19.6396 145.033 20.0644C145.412 20.4891 145.661 21.0128 145.752 21.5741C145.841 22.2228 145.875 22.8778 145.856 23.5324V26.3619C145.825 27.6131 145.87 28.865 145.992 30.1106C146.089 30.637 146.27 31.1444 146.528 31.6133H144.312C144.069 31.1175 143.92 30.5809 143.872 30.0307ZM143.696 25.2828C142.574 25.6848 141.409 25.953 140.224 26.0821C139.59 26.1501 138.967 26.295 138.368 26.5137C138.025 26.6528 137.732 26.8925 137.528 27.2012C137.292 27.5775 137.198 28.0255 137.263 28.4648C137.327 28.9041 137.546 29.3062 137.88 29.5991C138.414 30.0149 139.085 30.2171 139.76 30.1666C140.522 30.1812 141.275 29.9966 141.944 29.631C142.556 29.2999 143.048 28.7855 143.352 28.1603C143.614 27.4861 143.731 26.7645 143.696 26.0422V25.2828Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M148.752 32.5963L150.816 32.9C150.834 33.1688 150.908 33.4309 151.031 33.6701C151.155 33.9094 151.327 34.1206 151.536 34.2908C152.172 34.7193 152.931 34.9271 153.696 34.8823C154.5 34.9348 155.299 34.7274 155.976 34.2908C156.519 33.8849 156.904 33.3027 157.064 32.6443C157.207 31.7432 157.258 30.83 157.216 29.9186C156.796 30.4307 156.266 30.8407 155.664 31.1176C155.063 31.3946 154.406 31.5312 153.744 31.5172C152.976 31.5552 152.21 31.4055 151.513 31.0809C150.816 30.7564 150.209 30.2669 149.744 29.6549C148.781 28.364 148.28 26.788 148.32 25.1788C148.308 24.0423 148.531 22.9156 148.976 21.8697C149.358 20.9212 150.012 20.1065 150.856 19.5277C151.721 18.9745 152.733 18.6959 153.76 18.7284C154.467 18.719 155.167 18.8763 155.802 19.1873C156.437 19.4984 156.99 19.9545 157.416 20.5188V19.0082H159.376V29.8707C159.463 31.2793 159.261 32.6908 158.784 34.0191C158.372 34.8477 157.709 35.5248 156.888 35.9534C155.899 36.4562 154.797 36.6983 153.688 36.6568C152.401 36.7179 151.128 36.3649 150.056 35.6496C149.596 35.2942 149.235 34.8269 149.006 34.2925C148.778 33.758 148.691 33.1741 148.752 32.5963V32.5963ZM150.504 25.0669C150.394 26.3445 150.743 27.6194 151.488 28.6637C151.785 29.0259 152.16 29.3161 152.586 29.5127C153.011 29.7093 153.475 29.8071 153.944 29.7987C154.411 29.8052 154.875 29.7073 155.299 29.5124C155.724 29.3174 156.1 29.0302 156.4 28.6717C157.146 27.6522 157.499 26.3975 157.392 25.1388C157.487 23.8962 157.123 22.662 156.368 21.6698C156.073 21.3032 155.699 21.0078 155.274 20.8057C154.848 20.6037 154.383 20.5001 153.912 20.5029C153.451 20.5014 152.996 20.6042 152.58 20.8035C152.164 21.0029 151.799 21.2936 151.512 21.6539C150.772 22.6373 150.414 23.8556 150.504 25.0829V25.0669Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M171.2 27.5365L173.4 27.8082C173.112 28.9928 172.432 30.0458 171.472 30.7976C170.424 31.5353 169.161 31.9062 167.88 31.8527C167.074 31.9013 166.266 31.779 165.511 31.4939C164.755 31.2087 164.069 30.7671 163.496 30.1981C162.359 28.8841 161.784 27.1759 161.896 25.4423C161.777 23.6616 162.35 21.9036 163.496 20.5346C164.035 19.9601 164.691 19.5074 165.419 19.2068C166.148 18.9063 166.932 18.7649 167.72 18.7921C168.488 18.7655 169.253 18.9045 169.963 19.1997C170.672 19.4948 171.31 19.9392 171.832 20.5026C172.964 21.8353 173.538 23.5535 173.432 25.2984C173.432 25.4263 173.432 25.6181 173.432 25.8659H164.08C164.065 27.0385 164.481 28.1759 165.248 29.0631C165.584 29.4196 165.991 29.7013 166.443 29.89C166.895 30.0786 167.382 30.17 167.872 30.1582C168.585 30.1761 169.285 29.9599 169.864 29.5427C170.503 29.0251 170.969 28.3253 171.2 27.5365V27.5365ZM164.224 24.1075H171.2C171.182 23.2458 170.903 22.4098 170.4 21.7096C170.085 21.3141 169.683 20.9971 169.225 20.7835C168.767 20.5699 168.265 20.4655 167.76 20.4786C167.305 20.4674 166.853 20.5488 166.431 20.7178C166.009 20.8869 165.625 21.14 165.304 21.4618C164.605 22.1661 164.209 23.1155 164.2 24.1075H164.224Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M182.76 31.5732V19.0082H184.672V20.7906C185.1 20.1277 185.695 19.5885 186.397 19.2267C187.099 18.8649 187.883 18.6931 188.672 18.7284C189.389 18.7198 190.099 18.8585 190.76 19.1361C191.324 19.3527 191.816 19.7206 192.184 20.1992C192.514 20.68 192.74 21.2245 192.848 21.7978C192.94 22.4811 192.977 23.1707 192.96 23.86V31.5652H190.84V23.9479C190.866 23.2912 190.783 22.6347 190.592 22.0056C190.42 21.5769 190.11 21.2172 189.712 20.9825C189.261 20.7196 188.746 20.5868 188.224 20.5988C187.362 20.5829 186.525 20.891 185.88 21.4621C185.224 22.0376 184.896 23.1246 184.896 24.7312V31.5732H182.76Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M195.424 25.3067C195.424 22.9887 196.069 21.2702 197.36 20.1512C198.458 19.217 199.863 18.7216 201.304 18.7604C202.088 18.7316 202.868 18.8676 203.596 19.1595C204.324 19.4514 204.982 19.8927 205.528 20.4549C206.105 21.0835 206.55 21.8211 206.837 22.6241C207.124 23.4271 207.247 24.2794 207.2 25.1308C207.259 26.4385 207.01 27.7419 206.472 28.9355C205.998 29.846 205.262 30.5946 204.36 31.0856C203.435 31.6086 202.391 31.8839 201.328 31.8849C200.536 31.9227 199.744 31.7918 199.007 31.5011C198.269 31.2103 197.601 30.7663 197.048 30.1984C196.469 29.5296 196.027 28.7533 195.749 27.914C195.47 27.0746 195.36 26.1886 195.424 25.3067V25.3067ZM197.608 25.3067C197.511 26.5952 197.883 27.8753 198.656 28.9115C198.98 29.2958 199.386 29.6029 199.844 29.8104C200.302 30.0178 200.801 30.1203 201.304 30.1105C201.805 30.1204 202.303 30.017 202.758 29.808C203.214 29.599 203.617 29.2898 203.936 28.9035C204.72 27.8484 205.096 26.545 204.992 25.2347C205.085 23.9712 204.706 22.718 203.928 21.7178C203.599 21.3426 203.194 21.0419 202.739 20.836C202.285 20.63 201.791 20.5234 201.292 20.5234C200.793 20.5234 200.299 20.63 199.845 20.836C199.39 21.0419 198.985 21.3426 198.656 21.7178C197.881 22.7471 197.506 24.0221 197.6 25.3067H197.608Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M214.304 29.671L214.616 31.5493C214.091 31.6683 213.555 31.7326 213.016 31.7412C212.397 31.7716 211.781 31.6451 211.224 31.3735C210.829 31.1575 210.512 30.8216 210.32 30.4143C210.099 29.597 210.013 28.7494 210.064 27.9045V20.7108H208.504V19.0083H210.064V15.939L212.176 14.6601V19.0083H214.312V20.6628H212.176V27.9924C212.153 28.3849 212.191 28.7785 212.288 29.1594C212.365 29.3302 212.494 29.4726 212.656 29.5671C212.879 29.6859 213.131 29.7412 213.384 29.7269C213.692 29.7288 213.999 29.7101 214.304 29.671Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M223.616 31.5732V20.6947H221.736V19.0081H223.616V17.7053C223.584 17.0658 223.66 16.4255 223.84 15.8109C224.042 15.2567 224.422 14.7846 224.92 14.4681C225.577 14.0895 226.331 13.9117 227.088 13.9565C227.754 13.9649 228.418 14.0345 229.072 14.1644L228.752 16.0187C228.338 15.9411 227.917 15.901 227.496 15.8988C227.003 15.8528 226.512 16.0021 226.128 16.3145C225.812 16.7632 225.67 17.3115 225.728 17.8571V19.0081H228.176V20.6627H225.728V31.5732H223.616Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M229.032 25.3067C229.032 22.9887 229.677 21.2702 230.968 20.1512C232.069 19.2171 233.476 18.7219 234.92 18.7604C235.704 18.7316 236.484 18.8676 237.212 19.1595C237.94 19.4514 238.598 19.8927 239.144 20.4549C239.718 21.0848 240.16 21.8229 240.444 22.6259C240.729 23.4289 240.85 24.2805 240.8 25.1308C240.862 26.4379 240.615 27.7411 240.08 28.9355C239.605 29.8483 238.866 30.5974 237.96 31.0856C237.038 31.6086 235.996 31.884 234.936 31.8849C234.144 31.9196 233.354 31.7874 232.616 31.4968C231.879 31.2062 231.211 30.7638 230.656 30.1984C230.077 29.5296 229.635 28.7533 229.357 27.914C229.078 27.0746 228.968 26.1886 229.032 25.3067V25.3067ZM231.224 25.3067C231.123 26.5956 231.495 27.8772 232.272 28.9115C232.6 29.2892 233.006 29.5921 233.461 29.7997C233.917 30.0072 234.411 30.1147 234.912 30.1147C235.413 30.1147 235.907 30.0072 236.363 29.7997C236.818 29.5921 237.224 29.2892 237.552 28.9115C238.33 27.8537 238.702 26.5516 238.6 25.2427C238.692 23.9807 238.316 22.7289 237.544 21.7258C237.215 21.3506 236.81 21.0499 236.355 20.844C235.901 20.638 235.407 20.5314 234.908 20.5314C234.409 20.5314 233.915 20.638 233.461 20.844C233.006 21.0499 232.601 21.3506 232.272 21.7258C231.49 22.749 231.109 24.0224 231.2 25.3067H231.224Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M251.496 31.5729V29.7345C251.07 30.4033 250.477 30.95 249.776 31.3213C249.075 31.6926 248.289 31.8757 247.496 31.8526C246.783 31.8614 246.076 31.7169 245.424 31.429C244.863 31.2071 244.372 30.8402 244 30.3659C243.669 29.8861 243.446 29.341 243.344 28.7673C243.24 28.1119 243.197 27.4483 243.216 26.7851V19.0078H245.344V25.9618C245.318 26.7101 245.361 27.4593 245.472 28.1998C245.586 28.7272 245.887 29.1956 246.32 29.5187C246.787 29.8484 247.349 30.0168 247.92 29.9982C248.551 29.9985 249.171 29.8272 249.712 29.5027C250.249 29.2049 250.669 28.7344 250.904 28.1678C251.17 27.381 251.286 26.5515 251.248 25.722V19.0078H253.376V31.5729H251.496Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M256.728 31.5732V19.0082H258.64V20.7906C259.068 20.1277 259.663 19.5885 260.365 19.2267C261.067 18.8649 261.851 18.6931 262.64 18.7284C263.357 18.7198 264.067 18.8585 264.728 19.1361C265.292 19.3527 265.784 19.7206 266.152 20.1992C266.482 20.68 266.708 21.2245 266.816 21.7978C266.908 22.4811 266.945 23.1707 266.928 23.86V31.5652H264.8V23.9479C264.826 23.2912 264.743 22.6347 264.552 22.0056C264.38 21.5769 264.07 21.2172 263.672 20.9825C263.221 20.7196 262.706 20.5868 262.184 20.5988C261.322 20.5845 260.486 20.8924 259.84 21.4621C259.184 22.0376 258.856 23.1246 258.856 24.7312V31.5732H256.728Z" fill="#4D4D4F"/>
<path opacity="0.4" d="M278.32 31.5736V29.975C277.956 30.5719 277.438 31.0598 276.82 31.3875C276.202 31.7152 275.507 31.8706 274.808 31.8374C273.831 31.8512 272.873 31.5728 272.056 31.0381C271.2 30.4677 270.523 29.6668 270.104 28.7281C269.629 27.6596 269.394 26.5 269.416 25.3311C269.399 24.1662 269.611 23.0094 270.04 21.926C270.403 20.9661 271.061 20.1461 271.92 19.5841C272.753 19.0464 273.728 18.7682 274.72 18.7848C275.422 18.7725 276.115 18.9375 276.736 19.2643C277.307 19.5588 277.8 19.9832 278.176 20.5033V14.2767H280.288V31.5736H278.32ZM271.6 25.3231C271.503 26.6042 271.863 27.8783 272.616 28.9199C272.9 29.2867 273.264 29.5844 273.679 29.7906C274.095 29.9969 274.552 30.1064 275.016 30.1109C275.477 30.1156 275.933 30.0151 276.348 29.817C276.764 29.6189 277.13 29.3285 277.416 28.9679C278.139 27.9623 278.482 26.7332 278.384 25.4989C278.494 24.1626 278.144 22.8289 277.392 21.7182C277.106 21.3428 276.737 21.0391 276.314 20.831C275.89 20.6229 275.424 20.5161 274.952 20.5193C274.49 20.5099 274.032 20.6092 273.615 20.8091C273.198 21.009 272.834 21.304 272.552 21.6702C271.828 22.7442 271.492 24.0327 271.6 25.3231V25.3231Z" fill="#4D4D4F"/>
</g>
<path d="M200 70.1867C208.191 70.1867 214.832 63.552 214.832 55.3676C214.832 47.1833 208.191 40.5486 200 40.5486C191.809 40.5486 185.168 47.1833 185.168 55.3676C185.168 63.552 191.809 70.1867 200 70.1867Z" fill="#4D4D4F"/>
<path d="M200 67.9806C206.972 67.9806 212.624 62.3336 212.624 55.3676C212.624 48.4017 206.972 42.7547 200 42.7547C193.028 42.7547 187.376 48.4017 187.376 55.3676C187.376 62.3336 193.028 67.9806 200 67.9806Z" fill="white"/>
<path d="M208.536 52.65H202.616L207.584 37.9668L191.464 58.0853H197.384L192.416 72.7684L208.536 52.65Z" fill="#1487E3"/>
<path d="M209.042 66.1603L210.807 64.3969L190.957 44.5643L189.193 46.3277L209.042 66.1603Z" fill="#4D4D4F"/>
</svg>
`