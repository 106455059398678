/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * AssignSectionPopup ===> popup component
  * MNCostWalkthroughQuesView ===> walkthrough component
  * LossSupportView ===> loss support view component

**/

import React, { useEffect } from "react";
import "./manufacturing-cost-page.scss";
import $, { isEmptyObject } from "jquery";
import serviceProvider from '../../services/axios'
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { AssignSectionPopup } from "../loss-analysis-admin-page/loss-assing-popup";
import { FinancialValidationPageController } from "../financial-validation/financial-validation-page-ctrl";
import { MNCostWalkthroughQuesView } from './manufacturing-cost-walkthrough'
// how to use popup component
import { HowToUseCtrl } from '../lossanalysis-how-to-use-popup/HowToUseCtrl'
// scnv-2157 start
import { LossSupportView } from '../la-support/la-support-view';
// end
import { InviteUserList } from '../invite-user-list-component/InviteUserList'
//ManufacturingCostPagePlantView component start
export const ManufacturingCostPagePlantView = (props) => {
   // defining variables here
   const quesDatas = props.quesResult;
   if (!quesDatas.length) {
      return null;
   }
   const quesData = props.quesResult[0];
   const pid = quesData.pid;
   const allQues = quesData.questions;
   const progress = quesData.Secprogress;
   var islock = parseInt(quesData.isLocks);
   const year = quesData.customData.fiscal_year;
   const units = quesData.customData.currency;
   const currency = quesData.customData.currency;
   const materialList = allQues.slice(3, 6);
   const tmcCostList = allQues.slice(7, 9);
   const manufacturingCostList = allQues.slice(10, 12);
   const laborCostList = allQues.slice(13, 19);
   const repairCostList = allQues.slice(21, 23);

   const [allqueobj, setAllqueobj] = useState({});
   const answer = quesData.ans;
   const [ans, setans] = useState([]);
   const [subbtnDisable, setSubbtnDisable] = useState(true);
   const [openfinvalidation, setOpenFinValidation] = useState(quesData.open_fin_validation); 
   const [dropScreenFirst, setDropScreenFirst] = useState(true);
   const [dropScreenSec, setDropScreenSec] = useState(true);
   const [dropScreenThree, setDropScreenThree] = useState(true);
   const [dropScreenFour, setDropScreenFour] = useState(true);
   const [dropScreenFive, setDropScreenFive] = useState(true);
   // scnv-2157 start
   const [isLossSupport, setIsLossSupport] = useState(false);
   const [isTicket, setIsTicket] = useState(true);
   // scnv-2157 end
   const SctionFirst = (e) => {
      e.preventDefault();
      setDropScreenSec(!dropScreenSec);
   }
   const SctionSec = (e) => {
      e.preventDefault();
      setDropScreenFirst(!dropScreenFirst);
   }
   const SctionThree = (e) => {
      e.preventDefault();
      setDropScreenThree(!dropScreenThree);
   }
   const SctionFour = (e) => {
      e.preventDefault();
      setDropScreenFour(!dropScreenFour);
   }
   const SctionFive = (e) => {
      e.preventDefault();
      setDropScreenFive(!dropScreenFive);
   }

   const materialListAns = ans.slice(3, 6);
   const tmcCostListAns = ans.slice(7, 9);
   const manufacturingCostListAns = ans.slice(10, 12);
   const laborCostListAns = ans.slice(13, 19);
   const repairCostListAns = ans.slice(21, 23);
   const [isSubmitPopup, setIsSubmitPopup] = useState(false);
   const [messge, setMessge] = useState('');
   let [tmArr, settmArr] = useState([]);
   let [tmtotalval, settmTotalVal] = useState('');
   let [mantotalval, setmanTotalVal] = useState('');
   let [labtotalval, setlabTotalVal] = useState('');
   let [reptotalval, setrepTotalVal] = useState('');
   let [totallinevalue, setTotalLineValue] = useState('');
   let [tmcpersum, setTmcperSum] = useState('');
   // scnv-2126 start
   const [isUnlockPopup, setIsUnlockPopup] = useState(false);
   // scnv-2126 end

   let finaltmctotal = tmtotalval;
   let finaltotal = totallinevalue;
   const segments = window.location.pathname.slice(1).split("/");
   const slug = segments[2];
   const sid = segments[3];
   const ssid = segments[4];
   const groupid = (localStorage.getItem("group_id"));
   const user = JSON.parse(localStorage.getItem("userData"));
   const usid = user ? user.uid : 0;
   const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
   const [tmpAssignUser, setTmpAssignUser] = useState("");
   const [assignedUsers, setAssignedUsers] = useState({
      inviteData: [],
  });
   const [cate, setCate] = useState();
   const [secname, setSecName] = useState();
   const [validationpopup, setValidationPopup] = useState(false); // for migration
   const invitedmeblist = quesData.sectioninvitedmeber;
   //variable for walkthrough start
   const [activeSlide, setActiveSlide] = useState(1);
   //variable for walkthrough end
   // Popup Functions variables start
   // var to show or hide delete popup 
   const [delPop, setDelPop] = useState(false);
   //variable for edit comment
   const [isEditComment, setIsEditComment] = useState(null);
   //variable for store temproary data
   const [tmpCmntData, setTmpCmntData] = useState({});
   //variable for submit temproary data
   const [txtCmntInput, setTxtCmntInput] = useState('');
   // functions and variables for comment start
   const [isCommentCicked, setIsCommentCicked] = useState(false);
   const [sendquestion, setSendquestion] = useState(1);
   const [comment, setComment] = useState([
      {
         id: 1,
         uid: 'c1',
         cirName: 'ci',
         comment: 'lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem',
         editedDate: '09/11/21',
         editedTime: '10:00 am',
         commentedDate: '10/11/21',
         commentedTime: '12:00 am',
         isEdited: 0,

      },


   ])
   // Popup Functions variables end
   const [isQuesType, setIsQuesType] = useState(1);
   const [tmcCostListVal, settmcCostListVal] = useState(false)
   const [tmcCostListVal1, settmcCostListVal1] = useState(false)
   const [condition, setCondition] = useState(false);
   const [newVAlue, setnewVAlue] = useState('');
   const [newVAlue1, setnewVAlue1] = useState('');

   // map for invited member start
   let s1 = [];
   let s1name = [];
   let s1AavatarColor = [];
   invitedmeblist.map((item, index) => {
      if (item.invited_sub_category == quesData.subsection.id) {
         if (item.name != null) {
            s1.push(`${item.name[0]}${item.last_name[0]}`);
            s1name.push(`${item.name} ${item.last_name}`);
         } else {
            s1.push(`${item.email[0]}`);
            s1name.push(`${item.email}`);
         }
         s1AavatarColor.push(item.avcolor);
      }

   })
   //function for submit button display
   const submitbtnDis = () => {

      let tmval = [];
      tmcCostList.forEach((item, index) => {
         let emval = document.querySelector(`.ques${item.id}`);
         let neweval = emval.value.split(quesData.customData.currency);
         let newval1 = neweval[0].replace(/,/g, "");
         setnewVAlue(newval1);
         if (newval1 > 0 && newval1 != '') {
            tmval.push(true);
         }

      })
      let ques142 = document.querySelector(`#ques142`);
      let neweval2 = ques142.value.split(quesData.customData.currency);

      let newval3 = neweval2[0].replace(/,/g, "");
      setnewVAlue1(newval3);
      if (tmcCostList.length == tmval.length) {
         settmcCostListVal(true);
      } else {
         settmcCostListVal(false);
      }
      // console.log(newval3);
      if (newval3 > 0) {
         settmcCostListVal1(true);
      } else {
         settmcCostListVal1(false);
      }

      submitfinalbtn();
      // console.log(tmval);

      console.log(quesData.customData.fiscal_year);

   }
   //function for final submit
   const submitfinalbtn = () => {
      if (finaltotal > 0 && tmcCostListVal == true && tmcCostListVal1 == true && finaltmctotal > 0 && mantotalval > 0 && labtotalval > 0 && reptotalval > 0 && totallinevalue > 0 && tmcpersum > 0 && tmcpersum <= 100) {
         setCondition(true);
		  serviceProvider
                .post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:100 }),
                    true
                )
                .then((response) => {
                });
      } else {
         setCondition(false);
		  serviceProvider
                .post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:50 }),
                    true
                )
                .then((response) => {
                });
      }
   }
   //Function for edit
   const edithandle = (e) => {
      setIsAssignSectionPopup(true);
      setCate(sid);
      setSecName(props.pname);
   }
   const i = () => { }
   const [shown, setShown] = useState(false);
   const [isRightPopup, setIsRightPopup] = useState(0);
   const [isRightPopup2, setIsRightPopup2] = useState(null);
   const [rightdata, setRightData] = useState({});
   const [hashvalue, setHashvalue] = useState(window.location.hash);
   const linesdata = quesData.bcaselines;
   var linesans = quesData.linsans;
   const[lastlineObj1, SetLastlineObj]= useState([]);

   useEffect(() => {
		loadquestions();
	  }, []);
	
	const loadquestions = async () => {
		const result = await serviceProvider.get("/plants/questions",true,{});
		const data = result.data
		 if (data && Array.isArray(data)) {
			const options = data.map(d => ({
			  "id": d.id,
			  "question": d.question,
           "question_description":d.question_description
			}))
			SetLastlineObj(options);
		}
	};

   // let lastline_name = ;

   // let defultline = `The cost of units scrapped in this specific production process leading to material waste.<br/><br/>The value should be based on the scheduled production time for the last full fiscal year.`
  
   let lastlineObj = [{
      id: lastlineObj1?.filter(d=>d.id==206)[0]?.id,
      line_name:lastlineObj1?.filter(d=>d.id==206)[0]?.question,
      question_description:lastlineObj1?.filter(d=>d.id==206)[0]?.question_description},
      {
      id: '002',
      line_name: `Production Areas/Lines`,
      question_description: 'Production processes/lines that were provided by the plant during the set-up process.'
      }
]
   // console.log(lastlineObj)

   const sumofPercen1 = (Arr1) => {
      // console.log(Arr1);
      let neArray = Object.values(Arr1);
      let valAc = answer.length;

      let sum = 0;
      for (let j = 2; j < valAc; j++) {

         if (neArray[j] != "") {

            let nnew1 = neArray[j];
            let nnew = nnew1[0].slice(0, -1);
            if (isNaN(parseInt(nnew))) {
               nnew = 0;
            }
            sum = sum + parseInt(nnew);
         }

      }
   }
   //function for on key value
   const onKeyVal = (e) => {
      var ch = String.fromCharCode(e.which);
      if (!(/[0-9]/.test(ch))) {
         e.preventDefault();

      }
   }
   // function to change value
   const changeval = (e) => {

      let val = e.target.value;
      let val1 = val.split('%');

      if (val1[0]) {
         e.target.value = val1[0];
      }

   }
   //funaction to change value
   const changevalusd = (e) => {

      let val = e.target.value;
      let p = val.replace(/\D/g, "");
      e.target.value = p;


   }
   //Function to submit answer
   const submitAnsplus = (e) => {
      if (e.type == 'blur') {
         let val1 = parseInt(e.target.value);
         let val;

         if (val1 || val1 == 0) {
            val = $(e.target).val(val1 + '%');

         }
         else if (isNaN(val1)) {
            val = $(e.target).val();
         }
         submitAns(e);
      }
   }
   //function to submit answer format2
   const submitAnsformat2 = (e, sigid, ql) => {
      e.preventDefault();
      var uuom = '';
      uuom = quesData?.customData.currency;

      if (e.target.value != '') {
         var vv = e.target.value.replace(/\D/g, "");
         document.querySelector('input[name=' + e.target.name + ']').value = Number(vv).toLocaleString('en-US') + ' ' + uuom;
      }
   }
   // function to submit answer format
   const submitAnsformat = (e, sigid, ql) => {
      e.preventDefault();
      var uuom = '';

      if (e.target.name == 'ques175') {
         uuom = '%';
         if (e.target.value > 100) {
            e.target.value = 100;
         } else {
            e.target.value;
         }

      } else {
         uuom = quesData?.customData.currency;
      }

      if (e.target.value.includes(' ' + uuom)) {
         document.querySelector('input[name=' + e.target.name + ']').value = e.target.value;
      }
      else {
         if (e.target.value != '') {
            if (e.target.name == 'ques175') {
               document.querySelector('input[name=' + e.target.name + ']').value = Number(e.target.value).toLocaleString('en-US') + '' + uuom;
            } else {
               document.querySelector('input[name=' + e.target.name + ']').value = Number(e.target.value).toLocaleString('en-US') + ' ' + uuom;
            }

         }
      }


      let tempObj = {};
      let temArray1 = [];

      allQues.map((item, index) => {

         let name = `ques` + item.id;


         const f1 = document.getElementById("manufacturingCost");

         const data = new FormData(f1).getAll(name);
         tempObj = { ...tempObj, [name]: data }
      })
      // console.log(tempObj)
      setAllqueobj(tempObj);
      if (e.target.name != 'ques175') {
         sumofPercen1(tempObj);
      }



   }
   //function for submit answer
   const submitAns = (e, sigid, ql) => {
      e.preventDefault();

      let tempObj = {};
      let temArray1 = [];

      allQues.map((item, index) => {

         let name = `ques` + item.id;
         // console.log(name);

         const f1 = document.getElementById("manufacturingCost");

         const data = new FormData(f1).getAll(name);
         // console.log(data);
         tempObj = { ...tempObj, [name]: data }


      })

      setAllqueobj(tempObj);
      if (e.target.name != 'ques175') {
         sumofPercen1(tempObj);
      }
      // sumofPercen1(tempObj);




   }

   useEffect(() => {
      submitbtnDis();
   }, [newVAlue, newVAlue1, finaltotal, finaltmctotal, mantotalval, labtotalval, reptotalval, totallinevalue, tmcpersum, props.unlockbutton])

   useEffect(() => {
      document.addEventListener("click", (e) => {
         if (!e.target.closest('.conected_to_popup')) {
            setShown(false);
         }
      });
   });
   // let messge='';
   const commentmess = (e) => {
      setMessge(e.target.value);
   }

   const commentsub = (e) => {

      serviceProvider
         .post(
            "/businesscaseplantloss?type=savecomment",
            JSON.stringify({ uid: usid, pid: quesData.pid, qid: rightdata?.qid, message: messge }),
            true
         )
         .then((response) => {
            //  console.log(response);

         });
      setMessge('')

   }
   //Function for final submit
   const Finalsubmit = (e) => {

      serviceProvider
         .post(
            "/businesscaseplantloss?type=lockanswer",
            JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, allqueobj }),
            true
         )
         .then((response) => {
            location.reload();

         });

   }
   //Function to handle right section
   const HandleRightSec = async (e, val, type, item = 0) => {
      e.preventDefault();
      setComment([]);
      if (item?.id != undefined) {

         const apiUrl = `/businesscaseplantloss?param=commentshow&qid=${item?.id}&uid=${quesData.uid}&pid=${quesData.pid}`;
         const { data } = await serviceProvider.get(apiUrl, true, {});

         if (!isEmptyObject(data)) {
            let tempDataArr = []

            data.forEach((item) => {
               let fullDateCreate = new Date(item.datecreated);
               let fullDateEdit = new Date(item.dateupdated);
               let tmpObjArrD = {
                  id: item.id,
                  uid: item.user_id,
                  cirName: item.usersn,
                  comment: item.comment,
                  editedDate: `${fullDateEdit.getDate()}/${fullDateEdit.getMonth()}/${fullDateEdit.getFullYear().toString().substr(-2)}`,
                  editedTime: fullDateEdit.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                  commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth()}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
                  commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                  isEdited: item.is_edited,
               }
               tempDataArr.push(tmpObjArrD);
            })

            setComment(tempDataArr);
         }
      } else {
         const apiUrl = `/businesscaseplantloss?param=commentshow&qid='002'&uid=${quesData.uid}&pid=${quesData.pid}`;
         const { data } = await serviceProvider.get(apiUrl, true, {});

         if (!isEmptyObject(data)) {
            let tempDataArr = []

            data.forEach((item) => {
               let fullDateCreate = new Date(item.datecreated);
               let fullDateEdit = new Date(item.dateupdated);
               let tmpObjArrD = {
                  id: item.id,
                  uid: item.user_id,
                  cirName: item.usersn,
                  comment: item.comment,
                  editedDate: `${fullDateEdit.getDate()}/${fullDateEdit.getMonth()}/${fullDateEdit.getFullYear().toString().substr(-2)}`,
                  editedTime: fullDateEdit.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                  commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth()}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
                  commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                  isEdited: item.is_edited,
               }
               tempDataArr.push(tmpObjArrD);
            })

            setComment(tempDataArr);
         }
      }
      setIsQuesType(type);
      if (isRightPopup === val) {
         setIsRightPopup(null);
      } else {


         if (item?.question) {
            setRightData({ name: item?.question, des: item?.question_description, qid: item?.id })
            setIsRightPopup(val);
            setSendquestion(item?.question);
            setIsRightPopup2(item.id);

         } else {
            setRightData({ name: item?.line_name, des: item?.question_description, qid: item?.id })
            setIsRightPopup2(item.id);
            setIsRightPopup(val);
            setSendquestion(item?.line_name);

         }
      }
   }
   //function to handle right section close
   const HandleRightSecClose = (e, val) => {
      e.preventDefault();
      setIsRightPopup(null);
      setIsRightPopup2(null);
   }
   //delete button event function
   const HandleDelBtnEvent = (e, item) => {
      e.preventDefault();
      e.stopPropagation();
      setDelItem(item);
      setIsRemovePopup(true);
   }

   useEffect(() => {

      settmTotalVal(quesData.tmc);
      setmanTotalVal(quesData.cmo);
      setlabTotalVal(quesData.tilc);
      setrepTotalVal(quesData.rmc);
      setTotalLineValue(quesData.tmwc);
      setTmcperSum(quesData.tmcpersum);


      // Get the modal
      var modal = document.getElementById("myModal");
      // Get the image and insert it inside the modal - use its "alt" text as a caption
      var img = document.getElementById("myImg");
      var modalImg = document.getElementById("img01");
      var captionText = document.getElementById("caption");

      // When the user scroll on div, open the shadow
      $('.manufacturing-cost-mid').scroll(
         () => {
            var scroll = $('.manufacturing-cost-mid').scrollTop();
            if (scroll > 0) {
               $(".outside_card").addClass("active");
            }
            else {
               $(".outside_card").removeClass("active");
            }
         }
      );

      $('.listree1  input').on('click', function () {
         $('.listree1').addClass('change');
      })
      $('.listree2 input').on('click', function () {
         $('.listree2').addClass('change');
      })


      function showParents() {
         $(".color-changing").removeClass("xyz");
         var len = $("input.selected")
            .parents(".color-changing")
            .addClass("xyz")
            .length;
      }
      $(" input").click(function () {
         $(this).toggleClass("selected");
         showParents();
      });


      $(function () {
         $('.tage1 input,textarea').focus(function () {
            $(this).data('placeholder', $(this).attr('placeholder'))
               .attr('placeholder', '');
         }).blur(function () {
            $(this).attr('placeholder', $(this).data('placeholder'));
         });
      });

      /* $('.alllines').blur(function(){
         var fval=$(this).val();
         fval=fval.split(" ");
         fval=fval[0];
         fval=fval.toLocaleString()+' '+quesData?.customData.currency;
         $(this).val(fval);
       });*/

      $(document).on('change', '.ques-num', function (event) {
         var inputval = parseInt(this.value);
         if ($(this).hasClass('percentage1') && inputval > 100) {
            inputval = 100;
            $(this).val(inputval);
         }
         var qtype = $(this).attr('data-qtype');
         if (qtype == 1) {
         } else {
            if (inputval) {
               $(this).val(inputval + '%');
            } else if (inputval == 0) {
               $(this).val(inputval + '%');
            }
         }


      })


      let tempval = [];


      answer.map((item, index) => {

         if (item.length != 0) {
            if (item.answer[0] != "") {
               if (item.answer != '[]' && item.answer != '[""]') {
                  tempval.push(JSON.parse(item.answer));
               } else {
                  tempval.push('');
               }
            } else {
               tempval.push('');
            }
         } else {
            tempval.push('');
         }
      })


      if (tempval.length > 0) {
         setans(tempval);
      }

      if (islock > 0) {
         setSubbtnDisable(true);
      } else {
         setSubbtnDisable(false);
      }

      return async () => {
         try {
            if (Array.isArray(props.quesResult) && props.quesResult.length) {

               const { uid, pid: plant_id, has_sub_section, subsection, section } = props.quesResult[0];
               let data = { uid, section_id: section['id'], type: 2, status: 0, plant_id }

               if (section['has_sub_section'] === "1") {
                  data['sub_section_id'] = subsection['id'];
               }
               else {
                  data['sub_section_id'] = 0
               }
               await serviceProvider.post('/sectionlock', data, true);
            }
         } catch (err) {
            console.error(err)
         }
      }

   }, [])

   useEffect(() => {


      if (!isEmptyObject(allqueobj)) {

         var lines = {};
         var nm = '';
         var valP = "";
         var iaval1 = 0;
         var iaval = 0;
         $(".alllines").each(function () {
            nm = $(this).attr("name");
            var arrnm = nm.split("-");
            valP = $(this).val();
            var vv = valP.replace(/\D/g, "");
            lines[arrnm[1]] = vv;
            iaval1 = iaval1 + parseInt(vv ? vv : 0);
         });
         var t = $("#ques206").val();
         var tp = t.replace(/\D/g, "");
         var iaval2 = parseInt(tp ? tp : 0);
         iaval = iaval1 + iaval2;
         setTotalLineValue(iaval);

         serviceProvider
            .post(
               "/businesscaseplantloss?type=saveuseranswer",
               JSON.stringify({ slug: slug, uid: usid, currency: quesData?.customData.currency, pid: quesData.pid, sid: sid, ssid: ssid, lines: lines, allqueobj }),
               true
            )
            .then((res) => {
				
               settmTotalVal(res.data[0].tmc);
               setmanTotalVal(res.data[0].cmo);
               setlabTotalVal(res.data[0].tilc);
               setrepTotalVal(res.data[0].rmc);
               setTmcperSum(res.data[0].tmcpersum);
				serviceProvider
            .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
            .then((res) => {


               //settmTotalVal(res.data[0].tmc);
               //setmanTotalVal(res.data[0].cmo);
               //setlabTotalVal(res.data[0].tilc);
               //setrepTotalVal(res.data[0].rmc);

            });

            });
         



      }

   }, [allqueobj])
   //popup changes start

   const HandleNotePop = () => {
      let temp = document.querySelector('.la-card-wrapper');
      if (temp.classList.contains('active')) {
         temp.classList.remove('active');
      }
      else {
         temp.classList.add('active');
      }
   }
   const removeNotePop = () => {
      let tempr = document.querySelector('.la-card-wrapper');
      tempr.classList.remove('active');
      if (window.innerWidth < 1201) {
         tempr.classList.add('active');
      }
   }
   // Popup Functionality Start 10-11-2021
   //popup show
   const ShowDelPopup = (data, ind) => {
      setTmpCmntData({
         ...data, index: ind
      })
      setDelPop(!delPop);
   }
   //delete functionality start 
   const deletData = () => {
      const tmpArr = comment.filter((item, index) => item.id !== tmpCmntData.id);
      serviceProvider
         .post(
            "/businesscaseplantloss?type=deletecomment",
            JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, qid: rightdata?.qid }),
            true
         )
         .then((response) => {
            //console.log(response);

         });

      setComment(tmpArr);
      setDelPop(false);
      setTmpCmntData({});
   }
   //delete functionality end
   //edit functionality start
   const editComntData = (data, ind) => {
      setTmpCmntData({
         ...data, index: ind
      });
      let tmpTextArea = document.getElementById(`comment-editTxtarea-${data.id}`);
      tmpTextArea.value = data.comment;
      setIsEditComment(data.id);

   }
   //edit functionaliy end
   //Handle Comment textarea on change
   const handleTextAreaOnChange = (e) => {
      let val = e.target.value;
      setTxtCmntInput(val);
   }
   // Save submit data
   const saveCmntData = () => {

      let tmpTextArea = document.getElementById('addComment');
      let tmpCmntArr = [...comment];
      let fullDateCreate = new Date();
      // console.log(fullDateCreate.getMonth()+1);
      let tmpObj = {
         id: comment.length + 1,
         uid: usid,
         cirName: user.sn,
         comment: txtCmntInput,
         editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
         editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
         commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
         commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
         isEdited: 0

      }
      serviceProvider
         .post(
            "/businesscaseplantloss?type=savecomment",
            JSON.stringify({ uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: txtCmntInput }),
            true
         )
         .then((response) => {
            // console.log(response);

         });
      tmpCmntArr.unshift(tmpObj);
      setComment(tmpCmntArr);
      setTxtCmntInput('');
      tmpTextArea.style.height = 30 + 'px';
      setIsCommentCicked(false);
   }
   // Save Edit Data Functionality
   const saveEditData = (index) => {
      let tmpcmntArr = [...comment];
      let tmpTextArea = document.getElementById(`comment-editTxtarea-${tmpCmntData.id}`);
      let fullDateCreate = new Date();
      let tmpObj = {
         ...tmpCmntData,
         comment: tmpTextArea.value,
         editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
         editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
         isEdited: 1
      };
      serviceProvider
         .post(
            "/businesscaseplantloss?type=saveeditcomment",
            JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: tmpTextArea.value }),
            true
         )
         .then((response) => {
            // console.log(response);

         });

      tmpcmntArr[index] = tmpObj;
      setComment(tmpcmntArr);
      setIsEditComment('');
      // console.log(tmpcmntArr);
   }
   // Save Edit Data Functionality End
   // Popup Functionality End 10-11-2021
   useEffect(() => {
      let resizeTextArea = document.querySelectorAll('.resize-cmnt-area');
      resizeTextArea.forEach((item) => {
         item.addEventListener('input', () => {
            item.style.height = 'auto';
            let itemHeight = item.scrollHeight;
            if (itemHeight > 30) {
               item.style.height = itemHeight + 'px';
            }
            if (itemHeight > 136) {
               item.classList.remove('txtScrollHide');
            }
            else {
               item.classList.add('txtScrollHide');
            }

         })
      })
   }, [])

   React.useEffect(() => {
      if (hashvalue && hashvalue != null) {
         var abc = hashvalue.replace("#", "");
         var myArray = abc.split("-");
         if (myArray[0] == 1) {
            $('body').find('#idfc001').click();
         } else if (myArray[0] == 2) {
            $('body').find('#idfc002').click();

         } else {
            $('body').find('#idfc' + myArray[0]).click();
         }
         setIsLossSupport(false)
      }
      if(Array.isArray(invitedmeblist)){
         // setinvite-data
         let tmpArr={...assignedUsers};
          let tmpInviteData= invitedmeblist.filter((item) => item.invited_sub_category == quesData.subsection.id);
          tmpArr['inviteData']=tmpInviteData;
          setAssignedUsers(tmpArr);
      }
   }, [])

   return (
      <>
         {/* breadcrumbs */}
         <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{quesData.plant_name}</BreadcrumbsItem>
         <BreadcrumbsItem to={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>IR4.0 Loss Analysis</BreadcrumbsItem>
         <BreadcrumbsItem to="#">Manufacturing Cost</BreadcrumbsItem>
         {/* wrapper has three parts
             manufacturing-cost-top
             manufacturing-cost-top-two
             manufacturing-cost-mid
             manufacturing-cost-bottom

         */}
         <section className="manufacturing-cost prod-3">
            {/* this section has row */}
            <div className="manufacturing-cost-top">
               {/* row has been divided into three parts
                  manufacturing-cost-lft-col
                 manufacturing-cost-mid-col
                  la-ques-rgt-col
               */}

               <div className="manufacturing-cost-row">
                  {/* this section has 
                        back button
                        page title
                        category text
                  */}
                  <div className="manufacturing-cost-lft-col">
                     <h1 className="h1-btn">
                        {/* <a className="back-link" href={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>
                           <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><rect x={2} y={2} width={24} height={24} rx={2} fill="white" /></g><path d="M19 7.4303L17.4833 6L9 14L17.4833 22L19 20.5697L12.0334 14L19 7.4303Z" fill="#7D868C" /><defs><filter id="filter0_d" x={0} y={0} width={28} height={28} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity={0} result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset /><feGaussianBlur stdDeviation={1} /><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" /><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" /><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" /></filter></defs></svg>
                        </a> */}
                        Manufacturing Cost
                     </h1>
                     <h4 className="h4-dull">
                        <span className="text">Finance Data</span>
                        <span className="mid-border"></span>
                        <div className="text total">{`${year} Actual`}</div>
                     </h4>
                     <div className="value_of_progress" style={{ display: "none" }}>
                        <p>Progress:</p>
                        <div className="bar-wrapper">
                           <span>{progress}%</span>
                           <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{ width: progress }} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* this section has assigned user list */}



                  {/* it will be remove after implement */}
                  <div style={{/* display: groupid == 14 ? 'none' : '' */}}
                     className="manufacturing-cost-mid-col">
                     <div className="Assign">
                        <InviteUserList
                            data={assignedUsers.inviteData}
                            setData={setAssignedUsers}
                            handlePopup={edithandle}
							open={isAssignSectionPopup}
                            isEditAllow={groupid != 14 ? true : false}
                            catagory={sid}
                            subcatagory={quesData.subsection.id}
                            pid={pid}
                            uid={quesData.uid}
                            sectionName={quesData.subsection.sub_section_name}
                            plantName={quesData.plant_name}
							 {...props}
                        />
                        <div style={{ visibility: 'hidden' }} className="direction_arrows">
                           <div className="upward">
                              <img src={require("../../assets/img/manufacturing-cost/upward.svg").default} alt="digital_maturity" />
                              <p>Upload Excel file</p>
                           </div>
                           <div className="downword">
                              <img src={require("../../assets/img/manufacturing-cost/downword.svg").default} alt="digital_maturity" />
                              <p>Download to Excel</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* it will be remove after implement */}
                  {/* this section has
                        popup
                        unlock button/ Data Submitted Button
                     */}
                  <div className="manufacturing-rgt-col" style={{ position: 'relative' }}>

                     {/* <div className={`la-card-wrapper ${islock == 1 ? '' : 'active'}`}>
                        <button className="image-wrap" style={{ display: `${islock == 1 ? 'none' : ''}` }} onClick={() => HandleNotePop()}> */}
                     <div className={`la-card-wrapper active`}>
                        <button className="image-wrap" onClick={() => HandleNotePop()}>
                           <img src={require('../../assets/img/general-information/bulb.svg').default} alt="Bulb" />
                        </button>
                        <div className="content-box">
                           <p className="content">Provide the financial values based on the selected fiscal year.</p>
                           <button to="/" className="close-btn"><img src={require('../../assets/img/general-information/close.svg').default} alt="close" onClick={() => removeNotePop()} /></button>
                        </div>
                     </div>
                     {islock == 1 &&
                        <>
                           {/* <div className="submitted-data active">
                              <img src={require('../../assets/img/la-questionnaires/done.svg').default}/>
                              Data Submitted
                           </div>  */}
                           {/* scnv-2126 start */}
                           <div className='scnv2126-btn-wrapper'>
                              <div>
                                 <div className="submitted-scnv-2126">
                                    <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: dataSubmitsvg }}></span>
                                    <span>Data Submitted</span>
                                 </div>
                              </div>
                              <div style={{ display: (groupid == 2 || groupid == 11) && quesData.lockall == 0 ? '' : 'none' }}>
                                 <button className="submitted-scnv-2126 btn-unclock" onClick={() => setIsUnlockPopup(true)}>
                                    <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: lockSvg }}></span>
                                    <span>Unlock Section</span>
                                 </button>
                              </div>
                           </div>
                           {/* scnv-2126 end */}
                        </>
                     }
                     <div className="submit-page">
                        {/* { groupid==1555?
                        <button disabled={true} className={`btn-link submit disabled active`} >Submit</button>
                        : */}
                        {islock == 0 &&
                           <div className="prod-4"><button disabled={subbtnDisable} className={`btn-link submit ${(condition == true) ? '' : 'disabled'} active`} onClick={() => { if (condition == true) { if(openfinvalidation == false){ setIsSubmitPopup(true) }else{ setValidationPopup(true)   } } }}>Submit Data</button></div>
                        }
                     </div>
                  </div>
                  {/* la-ques-right-col end */}
               </div>
            </div>
            {/* this section has one card */}
            {/* <div className="manufacturing-cost-top-two">
               <div className="outside_card">
                  <div className="work_space">
                     <div className="what_percentage">
                        <div className="total">Plant Data:</div>
                     </div>
                     <div className="what_percentage actual-position">
                        <div className="total">{`${year} Actual`}</div>
                     </div>
                  </div>
                  <div className="empty" />
               </div>
            </div> */}
            {/* this section has another wrapper */}
            <div className="manufacturing-cost-mid prod-2">
               {/* main wrapper of the list */}
               <div className="white_space prod-1">
                  <form id={"manufacturingCost"}>
                     {/* inside this card there are
                        one main list
                        inside main list has one or more than one sublists
                     */}
                     <div className="inside_cards">
                        <div class="listree1">
                           <ul>
                              <li className="listree">
                                 <div className="listree-menu-heading ">
                                    <div className="work-type listing-input">
                                       <div className="total-number">
                                          <div className={`info  ${isRightPopup === ('h1' + i + 1) && 'active'}`} id={`idfc${allQues[0].id}`} onClick={(e) => HandleRightSec(e, 'h1' + i + 1, 1, allQues[0])}>
                                             <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                             </svg>
                                          </div>
                                          <p onClick={(e) => HandleRightSec(e, 'h1' + i + 1, 1, allQues[0])}>TOTAL MATERIAL WASTE COST:</p>
                                       </div>
                                       <div className="input">
                                          <input type="text" disabled={subbtnDisable} name={`ques205`} value={(finaltotal != "" && finaltotal != undefined && !isNaN(finaltotal) ? finaltotal.toLocaleString('en-US') + ' ' + quesData?.customData.currency : "")} placeholder={quesData?.customData.currency} placeholder={quesData?.customData.currency} disabled />
                                       </div>
                                    </div>
                                 </div>
                                 <ul className="listree-submenu">
                                    <li className="submenu">
                                       <div className="listree-submenu-heading color-changing">
                                          <div className="work-type listing-input">
                                             <div className={`total-number`}>
                                                <div className={`info  ${isRightPopup === (lastlineObj[1].id) && 'active'}`} id={`idfc${lastlineObj[1].id}`} onClick={(e) => HandleRightSec(e, lastlineObj[1].id, 1, lastlineObj[1])}>
                                                   <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                   </svg>
                                                </div>
                                                <p onClick={(e) => HandleRightSec(e, lastlineObj[1].id, 1, lastlineObj[1])}>Production Areas/Lines:</p>
                                                <img className={dropScreenFirst ? `active` : null} onClick={(e) => { SctionSec(e) }} src={require("../../assets/img/manufacturing-cost/arrow-down.svg").default} alt="manufacturing-cost" />
                                             </div>

                                          </div>
                                          <div className={dropScreenFirst ? `active` : null}>
                                             <ul className="listree-submenu-items hide">
                                                {linesdata.map((items, x) => {

                                                   return (

                                                      <li className="work-type listing-input">
                                                         <div title={items.line_name} className="side-slid-icon-button">
                                                            <div className={`info ${isRightPopup === (items.id) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, 3, items)}>
                                                               <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                               </svg>
                                                            </div>
                                                            <p onClick={(e) => HandleRightSec(e, items.id, 3, items)}>{items.line_name}</p>
                                                         </div>
                                                         <div className="input">
                                                            <input type="text" onKeyPress={(e) => { onKeyVal(e) }} className="alllines" disabled={subbtnDisable} name={`lnis-` + linesdata[x].id} defaultValue={(linesans != "" && linesans[x] != undefined && linesans[x].answer != '' ? Number(linesans[x].answer).toLocaleString('en-US') + ' ' + quesData?.customData.currency : "")} onChange={(e) => { submitAns(e) }} onBlur={(e) => { submitAnsformat2(e) }} onFocus={(e) => { changevalusd(e) }} placeholder={quesData?.customData.currency} />
                                                         </div>
                                                      </li>
                                                   )
                                                }
                                                )
                                                }

                                                <li className="work-type listing-input">
                                                   <div title={lastlineObj[0]?.line_name} className={"side-slid-icon-button"}>
                                                      <div className={`info ${isRightPopup === (lastlineObj[0].id) && 'active'}`} id={`idfc${lastlineObj[0].id}`} onClick={(e) => HandleRightSec(e, lastlineObj[0].id, 1, lastlineObj[0])}>
                                                         <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                         </svg>
                                                      </div>
                                                      <p onClick={(e) => HandleRightSec(e, lastlineObj[0].id, 1, lastlineObj[0])}>{lastlineObj[0]?.line_name}</p>
                                                   </div>
                                                   <div className="input">
                                                      <input disabled={subbtnDisable} type="text" onKeyPress={(e) => { onKeyVal(e) }} className={"otlines"} id={"ques206"} name={`ques206`} defaultValue={ans[1] != "" && ans[1] != undefined ? Number(ans[1]).toLocaleString('en-US') + ' ' + quesData?.customData.currency : ""} onChange={(e) => { submitAns(e) }} onBlur={(e) => { submitAnsformat(e) }} onFocus={(e) => { changevalusd(e) }} placeholder={quesData?.customData.currency} />
                                                   </div>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                    </li>
                                    <li className="submenu">
                                       <div className="listree-submenu-heading color-changing collapsible before bold exepend">
                                          <div className="work-type listing-input after">
                                             <div className="total-number">
                                                <div className={`info  ${isRightPopup === ('subh2' + i + 1) && 'active'}`} id={`idfc${allQues[2].id}`} onClick={(e) => HandleRightSec(e, 'subh2' + i + 1, 1, allQues[2])}>
                                                   <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                   </svg>
                                                </div>
                                                <p onClick={(e) => HandleRightSec(e, 'subh2' + i + 1, 1, allQues[2])}>{allQues[2].question}</p>
                                                <img className={dropScreenSec ? `active` : null} onClick={(e) => { SctionFirst(e) }} src={require("../../assets/img/manufacturing-cost/arrow-down.svg").default} alt="manufacturing-cost" />
                                             </div>
                                             <div>
                                                <div className="input">
                                                   <input type="text" name={`ques` + allQues[2].id} value={tmcpersum != "" && tmcpersum != undefined ? `${tmcpersum}%` : ''} placeholder={'%'} disabled />
                                                   <div className='tmcerror' style={{ visibility: tmcpersum > 100 ? '' : 'hidden' }} >Has to add up to 100%</div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className={dropScreenSec ? `active` : null} >
                                             <ul className="listree-submenu-items hide">
                                                {!isEmptyObject(answer) && materialList.map((items, x) => {
                                                   return (

                                                      <li className="work-type listing-input">
                                                         <div title={items.question} className="side-slid-icon-button">
                                                            <div className={`info ${isRightPopup === ('mlist' + x + 1) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, 'mlist' + x + 1, 1, items)}>
                                                               <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                               </svg>
                                                            </div>
                                                            <p onClick={(e) => HandleRightSec(e, 'mlist' + x + 1, 1, items)}>{items.question}</p>
                                                         </div>
                                                         <div className="input">
                                                            <input type="text" disabled={subbtnDisable} name={`ques` + materialList[x].id} defaultValue={materialListAns[x] != "" ? materialListAns[x] : ""} placeholder='%' onBlur={(e) => { submitAnsplus(e) }} onFocus={(e) => { changeval(e) }} onKeyPress={(e) => { onKeyVal(e) }} className="ques-num percentage1" maxLength="3" />
                                                         </div>
                                                      </li>
                                                   )
                                                }
                                                )
                                                }
                                             </ul>
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </li>
                           </ul>
                        </div>
                        <div class="listree2">
                           <ul>
                              <li className="listree">
                                 <div className="listree-menu-heading collapsed before ">
                                    <div className="work-type listing-input after">
                                       <div className="total-number">
                                          <div className={`info  ${isRightPopup === ('h2' + i + 1) && 'active'}`} id={`idfc${allQues[6].id}`} onClick={(e) => HandleRightSec(e, 'h2' + i + 1, 1, allQues[6])}>
                                             <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                             </svg>
                                          </div>
                                          <p onClick={(e) => HandleRightSec(e, 'h2' + i + 1, 1, allQues[6])}>{allQues[6].question}</p>
                                       </div>
                                       <div className="input">
                                          <input type="text" disabled={subbtnDisable} name={`ques145`} value={(finaltmctotal != "" && finaltmctotal != undefined && !isNaN(finaltmctotal) ? finaltmctotal.toLocaleString('en-US') + ' ' + quesData?.customData.currency : "")} placeholder={quesData?.customData.currency} disabled />
                                       </div>
                                    </div>
                                 </div>
                                 <ul className="listree-submenu">
                                    {!isEmptyObject(answer) && tmcCostList.map((items, x) => {
                                       return (
                                          <li className="work-type listing-input">
                                             <div title={items.question} className="side-slid-icon-button">
                                                <div className={`info ${isRightPopup === ('tmclist' + x + 1) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, 'tmclist' + x + 1, 1, items)}>
                                                   <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                   </svg>
                                                </div>
                                                <p onClick={(e) => HandleRightSec(e, 'tmclist' + x + 1, 1, items)}>{items.question}</p>
                                             </div>
                                             <div className="input">
                                                <input disabled={subbtnDisable} type="text" onKeyPress={(e) => { onKeyVal(e) }} className={`ques` + tmcCostList[x].id} name={`ques` + tmcCostList[x].id} defaultValue={tmcCostListAns[x] != "" && tmcCostListAns[x] != undefined ? Number(tmcCostListAns[x]).toLocaleString('en-US') + ' ' + quesData?.customData.currency : ""} onChange={(e) => { submitAns(e); submitbtnDis() }} onBlur={(e) => { submitAnsformat(e) }} onFocus={(e) => { changevalusd(e) }} placeholder={quesData?.customData.currency} />
                                             </div>
                                          </li>
                                       )
                                    }
                                    )
                                    }
                                    <li className="submenu">
                                       <div className="listree-submenu-heading color-changing">
                                          <div className="work-type listing-input after">
                                             <div className="total-number">
                                                <div className={`info  ${isRightPopup === ('subh3' + i + 1) && 'active'}`} id={`idfc${allQues[9].id}`} onClick={(e) => HandleRightSec(e, 'subh3' + i + 1, 1, allQues[9])}>
                                                   <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                   </svg>
                                                </div>
                                                <p onClick={(e) => HandleRightSec(e, 'subh3' + i + 1, 1, allQues[9])}>{allQues[9].question}</p>
                                                <img className={dropScreenThree ? `active` : null} onClick={(e) => { SctionThree(e) }} src={require("../../assets/img/manufacturing-cost/arrow-down.svg").default} alt="manufacturing-cost" />
                                             </div>
                                             <div className="input">
                                                <input disabled={subbtnDisable} type="text" className="2" name={`ques130`} value={(mantotalval != "" && mantotalval != undefined ? mantotalval.toLocaleString('en-US') + ' ' + quesData?.customData.currency : "")} placeholder={quesData?.customData.currency} disabled />
                                             </div>
                                          </div>
                                          <div className={dropScreenThree ? `active` : null} >
                                             <ul className="listree-submenu-items hide">
                                                {!isEmptyObject(answer) && manufacturingCostList.map((items, x) => {
                                                   var uuom = manufacturingCostList[x].id == 175 ? "%" : quesData?.customData.currency;
                                                   var manval = manufacturingCostList[x].id == 175 ? manufacturingCostListAns[x] : Number(manufacturingCostListAns[x]).toLocaleString('en-US')
                                                   return (
                                                      <li className="work-type listing-input">
                                                         <div title={items.question} className="side-slid-icon-button">
                                                            <div className={`info ${isRightPopup === ('mOverlist' + x + 1) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, 'mOverlist' + x + 1, 1, items)}>
                                                               <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                               </svg>
                                                            </div>
                                                            <p onClick={(e) => HandleRightSec(e, 'mOverlist' + x + 1, 1, items)}>{items.question}</p>
                                                         </div>
                                                         <div className="input">
                                                            <input type="text" disabled={subbtnDisable} maxLength={manufacturingCostList[x].id == 175 ? '3' : ''} name={`ques` + manufacturingCostList[x].id} defaultValue={manufacturingCostListAns[x] != "" && manufacturingCostListAns[x] != undefined ? manufacturingCostList[x].id == 175 ? manval : manval + ' ' + uuom : ""} placeholder={manufacturingCostList[x].id == 175 ? "%" : quesData?.customData.currency} onChange={(e) => { submitAns(e) }} onKeyPress={(e) => { onKeyVal(e) }} onBlur={(e) => { submitAnsformat(e) }} onFocus={(e) => { changevalusd(e) }} />
                                                         </div>
                                                      </li>
                                                   )
                                                }
                                                )
                                                }
                                                <li className="submenu">
                                                   <div className="listree-submenu-heading color-changing">
                                                      <div className="work-type listing-input after">
                                                         <div className="total-number">
                                                            <div className={`info  ${isRightPopup === ('subh4' + i + 1) && 'active'}`} id={`idfc${allQues[12].id}`} onClick={(e) => HandleRightSec(e, 'subh4' + i + 1, 1, allQues[12])}>
                                                               <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                               </svg>
                                                            </div>
                                                            <p onClick={(e) => HandleRightSec(e, 'subh4' + i + 1, 1, allQues[12])}>{allQues[12].question}</p>
                                                            <img className={dropScreenFour ? `active` : null} onClick={(e) => { SctionFour(e) }} src={require("../../assets/img/manufacturing-cost/arrow-down.svg").default} alt="manufacturing-cost" />
                                                         </div>
                                                         <div className="input">
                                                            <input disabled={subbtnDisable} type="text" name={`ques135`} value={(labtotalval != "" && labtotalval != undefined ? labtotalval.toLocaleString('en-US') + ' ' + quesData?.customData.currency : "")} onKeyPress={(e) => { onKeyVal(e) }} placeholder={quesData?.customData.currency} disabled />
                                                         </div>
                                                      </div>
                                                      <div className={dropScreenFour ? `active-inner` : null} >
                                                         <ul className="listree-submenu-items hide-inner">
                                                            {!isEmptyObject(answer) && laborCostList.map((items, x) => {
                                                               return (

                                                                  <li className="work-type listing-input">
                                                                     <div title={items.question} className="side-slid-icon-button">
                                                                        <div className={`info ${isRightPopup === ('lcostlist' + x + 1) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, 'lcostlist' + x + 1, 1, items)}>
                                                                           <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                                           </svg>
                                                                        </div>
                                                                        <p onClick={(e) => HandleRightSec(e, 'lcostlist' + x + 1, 1, items)}>{items.question}</p>
                                                                     </div>
                                                                     <div className="input">
                                                                        <input disabled={subbtnDisable} type="text" name={`ques` + laborCostList[x].id} defaultValue={laborCostListAns[x] != "" && laborCostListAns[x] != undefined ? Number(laborCostListAns[x]).toLocaleString('en-US') + ' ' + quesData?.customData.currency : ""} onKeyPress={(e) => { onKeyVal(e) }} onChange={(e) => { submitAns(e) }} onBlur={(e) => { submitAnsformat(e) }} onFocus={(e) => { changevalusd(e) }} placeholder={quesData?.customData.currency} />
                                                                     </div>

                                                                  </li>
                                                               )
                                                            }
                                                            )
                                                            }
                                                         </ul>
                                                      </div>
                                                   </div>
                                                </li>
                                                <li className="listree-submenu-heading before  tage1">
                                                   <div className="work-type listing-input after">
                                                      <div className="total-number">
                                                         <div className={`info  ${isRightPopup === ('subh5' + i + 1) && 'active'}`} id={`idfc${allQues[19].id}`} onClick={(e) => HandleRightSec(e, 'subh5' + i + 1, 1, allQues[19])}>
                                                            <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                               <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                            </svg>
                                                         </div>
                                                         <p onClick={(e) => HandleRightSec(e, 'subh5' + i + 1, 1, allQues[19])}>{allQues[19].question}</p>
                                                      </div>
                                                      <div className="input">
                                                         <input disabled={subbtnDisable} type="text" id={"ques142"} onKeyPress={(e) => { onKeyVal(e) }} name={`ques142`} defaultValue={ans[19] != "" && ans[19] != undefined ? Number(ans[19]).toLocaleString('en-US') + ' ' + quesData?.customData.currency : ""} onChange={(e) => { submitAns(e), submitbtnDis() }} onBlur={(e) => { submitAnsformat(e) }} onFocus={(e) => { changevalusd(e) }} placeholder={quesData?.customData.currency} />
                                                      </div>
                                                   </div>
                                                </li>
                                                <li className="submenu">
                                                   <div className="listree-submenu-heading collapsible before bold exepend color-changing">
                                                      <div className="work-type listing-input after">
                                                         <div className="total-number">
                                                            <div className={`info  ${isRightPopup === ('subh6' + i + 1) && 'active'}`} id={`idfc${allQues[20].id}`} onClick={(e) => HandleRightSec(e, 'subh6' + i + 1, 1, allQues[20])}>
                                                               <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                               </svg>
                                                            </div>
                                                            <p onClick={(e) => HandleRightSec(e, 'subh6' + i + 1, 1, allQues[20])}>{allQues[20].question}</p>
                                                            <img className={dropScreenFive ? `active` : null} onClick={(e) => { SctionFive(e) }} src={require("../../assets/img/manufacturing-cost/arrow-down.svg").default} alt="manufacturing-cost" />
                                                         </div>
                                                         <div className="input">
                                                            <input type="text" disabled={subbtnDisable} name={`ques154`} value={(reptotalval != "" && reptotalval != undefined ? reptotalval.toLocaleString('en-US') + ' ' + quesData?.customData.currency : "")} placeholder={quesData?.customData.currency} disabled />
                                                         </div>
                                                      </div>
                                                      <div className={dropScreenFive ? `active-inner` : null} >
                                                         <ul className="listree-submenu-items hide-inner" >
                                                            {!isEmptyObject(answer) && repairCostList.map((items, x) => {
                                                               return (
                                                                  <li className="work-type listing-input">
                                                                     <div title={items.question} className="side-slid-icon-button">
                                                                        <div className={`info ${isRightPopup === ('rlist' + x + 1) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, 'rlist' + x + 1, 1, items)}>
                                                                           <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                                           </svg>
                                                                        </div>
                                                                        <p onClick={(e) => HandleRightSec(e, 'rlist' + x + 1, 1, items)}>{items.question}</p>
                                                                     </div>
                                                                     <div className="input">
                                                                        <input disabled={subbtnDisable} type="text" onKeyPress={(e) => { onKeyVal(e) }} name={`ques` + repairCostList[x].id} defaultValue={repairCostListAns[x] != "" && repairCostListAns[x] != undefined ? Number(repairCostListAns[x]).toLocaleString('en-US') + ' ' + quesData?.customData.currency : ""} onChange={(e) => { submitAns(e) }} onBlur={(e) => { submitAnsformat(e) }} onFocus={(e) => { changevalusd(e) }} placeholder={quesData?.customData.currency} />
                                                                     </div>

                                                                  </li>
                                                               )
                                                            }
                                                            )
                                                            }
                                                         </ul>
                                                      </div>
                                                   </div>
                                                </li>
                                                <li className="listree-submenu-heading  ">
                                                   <div className="work-type listing-input after">
                                                      <div className="total-number">
                                                         <div className={`info  ${isRightPopup === ('subh7' + i + 1) && 'active'}`} id={`idfc${allQues[23].id}`} onClick={(e) => HandleRightSec(e, 'subh7' + i + 1, 1, allQues[23])}>
                                                            <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                               <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                            </svg>
                                                         </div>
                                                         <p onClick={(e) => HandleRightSec(e, 'subh7' + i + 1, 1, allQues[23])}>{allQues[23].question}</p>
                                                      </div>
                                                      <div className="input">
                                                         <input disabled={subbtnDisable} type="text" onKeyPress={(e) => { onKeyVal(e) }} id={"ques157"} name={`ques157`} defaultValue={ans[23] != "" && ans[23] != undefined ? Number(ans[23]).toLocaleString('en-US') + ' ' + quesData?.customData.currency : ""} onChange={(e) => { submitAns(e) }} onBlur={(e) => { submitAnsformat(e) }} onFocus={(e) => { changevalusd(e) }} placeholder={quesData?.customData.currency} />
                                                      </div>
                                                   </div>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            {/* this section has submit button */}
            <div className="manufacturing-cost-bottom" >
               {/* <div className="submit-page">
                   { groupid==1555?
                  <button disabled={true} className={`btn-link submit disabled active`} >Submit</button>
                  : 
                  {islock == 0 &&
                     <div className="prod-4"><button disabled={subbtnDisable} className={`btn-link submit ${(condition == true) ? '' : 'disabled'} active`} onClick={() => { if (condition == true) { setIsSubmitPopup(true) } }}>Submit</button></div>
                  }
               </div>*/}
            </div>


            {/* submit popup starts here */}
            <div className={`submit-pop-wrap ${isSubmitPopup && 'active'}`}>
               {/* wrapper has two sections
                  close-btn-wrap
                  popup
               */}
               <div className="popup-wrapper submit-popup">
                  <div className="close-btn-wrap">
                     <button className="close-btn" onClick={() => { setIsSubmitPopup(false) }}>
                        <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
                     </button>
                  </div>
                  {/* popup has 
                      img-box
                      content-box
                      button-wrapper
                  */}
                  <div className="popup">
                     <div className="img-box">
                        <img src={require("../../assets/img/la-questionnaires/unsaved.svg").default} alt="Unsaved" className="unsave-img" />
                     </div>
                     <div className="content-box">
                        <p className="text">By submitting the data you will no longer have the opportunity for further editing.</p>
                        <p className="text">Are you sure you want to proceed?</p>
                     </div>
                     <div className="button-wrapper">
                        <button type="submit" className="btn cancel-button primary" onClick={() => { setIsSubmitPopup(false) }}>Cancel</button>
                        <button onClick={(e) => { setIsSubmitPopup(false); setValidationPopup(true); }} type="submit" className="btn submit-btn secondary">Submit</button>
                     </div>
                  </div>
               </div>
            </div>
            {/* Right Sidebar Popup starts here */}
            <div className={`right-sidebar ${isRightPopup && 'active'}`}>

               {isRightPopup != 0 && (<LossSupportView
                  popupHeadTxt={rightdata.name}
                  des={rightdata.des}
                  isLossSupport={isLossSupport}
                  HandleRightSecClose={HandleRightSecClose}
                  close={setIsLossSupport}
                  isTicket={isTicket}
                  setIsTicket={setIsTicket}
                  pid={pid}
                  sid={108}
                  ssid={7}
                  qid={isRightPopup2}
                  uid={quesData.uid}
                  type={isQuesType}
                  question={sendquestion}
                  islock={islock}
				  isAvailable={false}
                  {...props}
               />)}
            </div>
         </section>
         {/* Assign popup component */}
         <AssignSectionPopup
            assignedUsers={assignedUsers}
            setAssignedUsers={setAssignedUsers}
            catagory={cate}
            subcatagory={7}
            pid={pid}
            sectionName={quesData.subsection.sub_section_name}
            plantName={quesData.plant_name}
            tmpAssignUser={'inviteData'}
            open={isAssignSectionPopup}
            setOpen={setIsAssignSectionPopup}
            {...props}
         />
         {/* validation popup component */}
         {validationpopup &&
            <FinancialValidationPageController pid={pid} setOpenFinValidation={setOpenFinValidation} setValidationPopup={setValidationPopup} units={currency} sid={108} ssid={6} Finalsubmit={Finalsubmit}  {...props} />
         }
         {/* walkthrough component */}
         {activeSlide && quesData.Manucosttour != 1 &&
            <MNCostWalkthroughQuesView activeSlide={activeSlide} setActiveSlide={setActiveSlide}  {...props} />
         }
         {/* confirmation unlock popup component */}
         <ConfirmationUnlockPopup isOpen={isUnlockPopup} setOpen={setIsUnlockPopup} setUnlockbutton={props.setUnlockbutton} pid={pid} sid={108} ssid={7} />
         {/* How to use popup */}
         <div className="how-to-use-ctrl-wrap">
           <HowToUseCtrl sectionName={`Manufacturing Cost`}/>
         </div>
      </>
   );
};

// defining svg here
let dataSubmitsvg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11" cy="11" r="10.5" fill="#CAE4CB" stroke="#43A047"/>
<path d="M8.83863 14.0039L5.83524 11.0005L4.8125 12.0161L8.83863 16.0422L17.4815 7.39932L16.466 6.38379L8.83863 14.0039Z" fill="#43A047"/>
</svg>`

const lockSvg = `<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.7142 7.16667H12.7618V5.2619C12.7618 2.63333 10.6285 0.5 7.99991 0.5C5.37134 0.5 3.238 2.63333 3.238 5.2619V7.16667H2.28562C1.238 7.16667 0.380859 8.02381 0.380859 9.07143V18.5952C0.380859 19.6429 1.238 20.5 2.28562 20.5H13.7142C14.7618 20.5 15.619 19.6429 15.619 18.5952V9.07143C15.619 8.02381 14.7618 7.16667 13.7142 7.16667ZM7.99991 15.7381C6.95229 15.7381 6.09515 14.881 6.09515 13.8333C6.09515 12.7857 6.95229 11.9286 7.99991 11.9286C9.04753 11.9286 9.90467 12.7857 9.90467 13.8333C9.90467 14.881 9.04753 15.7381 7.99991 15.7381ZM10.9523 7.16667H5.04753V5.2619C5.04753 3.63333 6.37134 2.30952 7.99991 2.30952C9.62848 2.30952 10.9523 3.63333 10.9523 5.2619V7.16667Z" fill="#118ACB"/>
</svg>
`
//function to call confirmation unlock popup
const ConfirmationUnlockPopup = (props) => {
   const finalUnlock = (id) => {
      props.setOpen(false);
      serviceProvider
         .post(
            "/businesscaseplantloss?type=unlocksection",
            JSON.stringify({ pid: props.pid, sid: props.sid, ssid: props.ssid }),
            true
         )
         .then((response) => {
            location.reload();
         });
   }
   return (
      <>
         {/* wrapper has popup-content which has two parts
       body-popup
       popup-btn
   */}
         <div className='unlock-popup-wrapper2126' style={{ visibility: props.isOpen ? '' : 'hidden' }}>
            <div className='popup-content2126'>
               <div className='body-popup2126'>
                  <p>Are you sure, you would like to unlock the section?</p>
               </div>
               <div className='popup-btn-wrapper2126'>
                  <button className='p-2126-btn cancel' onClick={() => props.setOpen(false)}>Cancel</button>
                  <button className='p-2126-btn yes' onClick={() => { finalUnlock(props.ssid) }}>Yes, unlock</button>
               </div>
            </div>
         </div>
      </>

   )
}
// scnv-2126