import React from 'react';
import serviceProvider from '../../services/axios'
import { useEffect, useState } from 'react';
import { FinancialValidationPageView } from "./financial-validation-page-view";
export const FinancialValidationPageController=(props)=>{
     
  
  const [vresults, setVResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
 
 useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=fvalidation&pid=${props.pid}&units=${props.units}&uid=${usid}&sid=${props.sid}&ssid=${props.ssid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setVResults(data);
		};
		search();
	}, []);
   
   return(
          <>
         
            <FinancialValidationPageView validResult={vresults} {...props}/>
          </>
      )




} 
