/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * CourseDetailView ===> custom view component
  * .scss for ===> styling css file for this page.

**/
import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router'
import serviceProvider from '../../services/axios'
import { CourseOverviewView } from "./course-overview-view";
import { CourseDetailView } from "./course-detail-view";
import "./course-detail.scss";
import { WalkthroughView } from "./walkthrough-view";
import "./course-overview.scss";
//CourseOverviewControler start
export const CourseOverviewControler = (props) => {
	//defining variables here
	const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	const tid = getLastItem(window.location.pathname);
	const [results, setResults] = useState([]);
	const [completed, setCompleted] = useState(0);
	const [allCompleted, setAllCompleted] = useState(0);
	const [submitQues, setSubmitQues] = useState(0);
	const [submitAns, setSubmitAns] = useState(0);
	const [onTab, setOnTab] = useState(0);
	const [showVideo, setShowVideo] = useState(false);
	const hashvalue = window.location.hash;
	// console.log(hashvalue);
	// const [modalOpen, setmodalOpen] = useState(hashvalue == "#walkthrough" ? true : false);
	useEffect(() => {
		if (hashvalue == "#q&a") {
			setOnTab(1);
		}
		// if (hashvalue == "#watch-intro") {
		// 	setShowVideo(true);
		// }
		props.handleExpandFal();
	}, []);
	// const handleModalClose = () => {
	// 	window.location.hash = "";
	// 	setmodalOpen(false);
	// };
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		const search = async () => {
			const apiUrl = `/item?param=mytraining&slug=${tid}&uid=${usid}&current=${completed}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
		};
		search();
	}, [tid, completed, allCompleted, submitQues, submitAns]);
	
	if (!results.length) {
		return null;
	} 
	else if (results[0].block == 1) {
		    return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
	} 
	else if (results[0].has_paid == 0) {
		return <CourseDetailView showVideo={showVideo} courseData={results} {...props} />;
	} 
	else {	
		return <Redirect to={`/training-courses/${results[0].course_slug}/${results[0].current.slug}`} />;
	}
};
