import { style } from "d3";
import styled from "styled-components";

export const SiriQNAPopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(125, 134, 140, 0.30);
    backdrop-filter: blur(0.2rem);
    height: 100%;
    width: 100%;
    z-index: 100;
`
export const SiriQNAPopWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 110rem;
    max-height: 65.2rem;
    border-radius: 2.4rem;
    border: 1px solid #CCD6E8;
    background: #FFF;
    display: flex;
    flex-direction: column;
    @media(max-height: 660px){
        height: calc(100vh - 3rem);
    }
    @media(max-width: 991px){
        min-width: auto;
        height: 90vh;
        width: calc(100% - 30px);
    }
    @media(max-width: 740px){
        height: calc(100% - 40px);
        width: 100%;
        transform: translateX(-50%);
        top: auto;
        bottom: 0;
        border-radius: unset;
        max-height: unset;
    }
`
export const SiriQNAHead = styled.div`
    border-bottom: 0.1rem solid #CCD6E8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.4rem;
    @media(max-width: 740px){
        padding: 16px;
    }
`
export const RightSection = styled.div`
    display: flex;
    align-items: center;
    gap: 1.6rem;
    .close-btn{
        display: inline-block;
        height: 3.2rem;
        width: 3.2rem;
        i{
            display: inline-block;
            height: 3.2rem;
            width: 3.2rem;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
    .edit-btn{
        padding: 0.8rem 1.6rem;
        color: #2C55A0;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.4rem; /* 24px */
        border-radius: 0.4rem;
        background: #E7EBF8;
        @media(max-width:740px){
            width: calc(100% - 3.2rem);
            left: 1.6rem;
            position:fixed;
            z-index:1;
            bottom:1.6rem;
            padding: 1.2rem 2.4rem;
        }
    }
`
export const Title = styled.h1`
    color: #2C55A0;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.8rem; /* 100% */
    span{
        font-weight: 500;
    }
    @media(max-width: 740px){
        font-size: 16px;
        line-height: normal;
    }
`
export const SiriQNABody = styled.div`
    padding: 1.6rem 0 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    margin-bottom: 1.5rem;
    @media(max-width: 740px){
        padding-left: 16px;
    }
`

export const TextAreaWrapper = styled.div`
    padding-right: 2.4rem;
    padding-top: 1.6rem;
    height: 29.9rem;
    @media(max-width: 740px){
        height: 332px;
        padding-right: 16px;
    }
    .tab-text-panel{
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        textarea{
            border-radius: 0.8rem;
            border: 0.1rem solid #CCD6E8;
            background: #FFF;
            padding: 1.6rem;
            color: #4D4D4F;
            font-family: 'Inter';
            font-size: 1.6rem;
            font-weight: 400;
            line-height: normal;
            height: calc(100% - 37px);
            resize: none;
            width: 100%;
            flex: 1;
            &:focus-visible{
                outline: 0;
            }
            @media(max-width: 575px){
                order: 2;
                margin-top: 15px;
            }
        }
        .text-span{
            color: #4D4D4F;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: normal;
            display: block;
            margin-top: 1.2rem;
            @media(max-width: 575px){
                order: 1;
                margin-top: 0;
                font-size: 14px;
                position: absolute;
                top: -9px;
                left: 0;
            }
        }
    }
`

export const ImageWrapper = styled.div`
    padding-top: 2.4rem;
    .image-heading{
        color: #2C55A0;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 1.6rem;
        span{
            font-weight: 400;
        }
    }

`
export const FooterWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 1.6rem;
    align-items: center;
    .save-btn-wrap{
        @media(max-width: 740px){
            width: 0;
        }
        .save-btn{
            color: #FFF;
            font-size: 2rem;
            font-weight: 500;
            line-height: normal;
            display: inline-block;
            padding: 1.2rem 2.4rem;
            border-radius: 0.4rem;
            background: #CBCFD2;
            position: fixed;
            bottom: 4rem;
            right: 2.4rem;
            @media(max-width: 740px){
                bottom: 1.6rem;
                right: auto;
                width: calc(100% - 3.2rem);
                left: 1.6rem;
            }
            &.active{
                background: #2C55A0;
            }
        }
    }
    
    .image-upload-wrap{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;
        gap: 1.6rem;
        width: calc(100% - 33rem); 
        @media(max-width: 740px){
            width: 100%;
            margin-bottom:70px;
        }
        .attachment-input-wrap {
            cursor: pointer;
            display: inline-block;
            height: 8rem;
            width: 8rem;
            @media(max-width: 740px){
                height: 65px;
                width: 65px;
            }
            .attachment-input{
                height: 100%;
                width: 100%;
                i{
                    display: block;
                    height: 100%;
                    width: 100%;
                    svg{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
        .uploaded-file-content{
            position: relative;
            height: 8rem;
            width: 8rem;
            @media(max-width: 740px){
                height: 65px;
                width: 65px;
            }
            .file-img-wrap{
                border-radius: 0.8rem;
                cursor: pointer;
                height: 100%;
                width: 100%;
                img{
                    object-fit: cover;
                    border-radius: 0.8rem;
                    height: 100%;
                    width: 100%;
                }
            }
            button{
                display: inline-block;
                height: 2.4rem;
                width: 2.4rem;
                position: absolute;
                bottom: 0.8rem;
                right: 0.8rem;
                @media(max-width: 740px){
                    bottom: 0.4rem;
                    right: 0.4rem;
                }
                i{
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                    svg{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
`

export const ZoomImageContainer = styled.div`
    background: rgba(125, 134, 140, 0.30);
    backdrop-filter: blur(2px); 
    height: 100%;
    overflow-y: auto;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    z-index: 99;
    @media(max-width: 740px){
        background: #4d4d4f;
    }
    .zoom-image-wrap{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        max-width: calc(100% - 3rem);
        border-radius: 0.8rem;
        width: 100%;
        @media(max-width: 740px){
            border-radius: 0;
            height: auto; 
            max-width: 100%;
            width: 100%;
        }
        img{
            height: auto;
            width: 100%;
            border-radius: 0.8rem;
            @media(max-width: 740px){
                border-radius: 0;
            }
        }
    }
    .zoom-close-icon{
        display: inline-block;
        position: fixed;
        right: 1.6rem;
        top: 1.6rem;
        height: 3.2rem;
        width: 3.2rem;
        z-index: 100;
        
        i{
            display: inline-block;
            height: 100%;
            width: 100%;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
    
`