/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Moment ===> component for the moment date library.
  * useReactToPrint ===> For functional components, use the useReactToPrint hook
  * FacebookShareButton, LinkedinShareButton, TwitterShareButton ===> component from react-share

**/

import React, { useState, useRef } from "react";
import { Collapse } from "@material-ui/core";
import {collapseIcon} from "./svg.js";
import{
  MasterPlanWrapper,
  MasterPlanTable,
  MasterPlanHead,
  MasterPlanBody,
  MasterPlanCard,
} from "./styled.js"

export const RoadmapMasterplanView = (props) => {
  const data_all = props.results;
  const [mobileCollapse, setMobileCollapse] = useState([]);
  //defining variables here
  const [data,setData] = useState(data_all.timeline)

  const handleMobileCollapse =(index)=>{
    if(mobileCollapse.includes(index)){
      let tmpArray = mobileCollapse.filter(i=>i!=index);
      setMobileCollapse(tmpArray);
    }
    else{
      let tmpArray = [...mobileCollapse];
      tmpArray.push(index);
      setMobileCollapse(tmpArray);
    }
  }

  return (
    <>
      <MasterPlanWrapper data-component="MasterPlanWrapper">
        <MasterPlanTable data-component="MasterPlanTable">
          <MasterPlanHead data-component="MasterPlanHead">
            <div className="sn-col common-col"><span className="title">S. No.</span></div>
            <div className="step-col common-col"><span className="title">Roadmap Step</span></div>
            <div className="year-col common-col">
              <span className="title">Year 1</span>
              <div className="quarter-row">
                <div className="quarter-col">Q1</div>
                <div className="quarter-col">Q2</div>
                <div className="quarter-col">Q3</div>
                <div className="quarter-col">Q4</div>
              </div>
            </div>
            <div className="year-col common-col">
              <span className="title">Year 2</span>
              <div className="quarter-row">
                <div className="quarter-col">Q1</div>
                <div className="quarter-col">Q2</div>
                <div className="quarter-col">Q3</div>
                <div className="quarter-col">Q4</div>
              </div>
            </div>
            <div className="year-col common-col">
              <span className="title">Year 3</span>
              <div className="quarter-row">
                <div className="quarter-col">Q1</div>
                <div className="quarter-col">Q2</div>
                <div className="quarter-col">Q3</div>
                <div className="quarter-col">Q4</div>
              </div>
            </div>
            <div className="year-col common-col">
              <span className="title">Year 4</span>
              <div className="quarter-row">
                <div className="quarter-col">Q1</div>
                <div className="quarter-col">Q2</div>
                <div className="quarter-col">Q3</div>
                <div className="quarter-col">Q4</div>
              </div>
            </div>
            <div className="year-col common-col">
              <span className="title">Year 5</span>
              <div className="quarter-row">
                <div className="quarter-col">Q1</div>
                <div className="quarter-col">Q2</div>
                <div className="quarter-col">Q3</div>
                <div className="quarter-col">Q4</div>
              </div>
            </div>
          </MasterPlanHead>
          <MasterPlanBody data-component="MasterPlanBody">
              {data.map((item,index)=>(
                <>
                  <div className="masterplan-row" key={index}>
                      <div className="sn-col common-col"><span className="sub-title">{index+1}</span></div>
                      <div className="step-col common-col"><span className="sub-title">{item.step}</span></div>
                      <div className="year-col common-col">
                        <div className="quarter-row">
                          <div className="quarter-col">
                            {
                              item.year1.q1 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year1.q2 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year1.q3 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year1.q4 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="year-col common-col">
                        <div className="quarter-row">
                          <div className="quarter-col">
                            {
                              item.year2.q1 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year2.q2 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year2.q3 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year2.q4 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="year-col common-col">
                        <div className="quarter-row">
                          <div className="quarter-col">
                            {
                              item.year3.q1 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year3.q2 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year3.q3 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year3.q4 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="year-col common-col">
                        <div className="quarter-row">
                          <div className="quarter-col">
                            {
                              item.year4.q1 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year4.q2 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year4.q3 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year4.q4 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="year-col common-col">
                        <div className="quarter-row">
                          <div className="quarter-col">
                            {
                              item.year5.q1 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year5.q2 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year5.q3 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                          <div className="quarter-col">
                            {
                              item.year5.q4 === true &&
                              <span className="background-div"></span>
                            }
                          </div>
                        </div>
                      </div>
                  </div>
                </>
              ))
              }
          </MasterPlanBody>
        </MasterPlanTable>
        
        <MasterPlanCard data-component="MasterPlanCard">
          {
            data.map((item,index)=>(
              <div className="masterplan-row"  key={index}>
                <div className="masterplan-card">
                  <div className="card-title">
                    <span>{index+1}.{item.step}</span>
                    <i className={mobileCollapse.includes(index)?'active':''} dangerouslySetInnerHTML={{__html: collapseIcon}} onClick={()=>handleMobileCollapse(index)}></i>
                  </div>
                  <Collapse in={mobileCollapse.includes(index)}>
                    <div className="year-card-row">
                      <div className="year-card-col">
                        <div className="year-card">
                          <span className="title">Year 1</span>
                          <div className="quarter-row first">
                            <div className="quarter-col">Q1</div>
                            <div className="quarter-col">Q2</div>
                            <div className="quarter-col">Q3</div>
                            <div className="quarter-col">Q4</div>
                          </div>
                          <div className="quarter-row">
                              <div className="quarter-col">
                                {
                                  item.year1.q1 === true &&
                                  <span className="background-div"></span>
                                }
                              </div>
                              <div className="quarter-col">
                                {
                                  item.year1.q2 === true &&
                                  <span className="background-div"></span>
                                }
                              </div>
                              <div className="quarter-col">
                                {
                                  item.year1.q3 === true &&
                                  <span className="background-div"></span>
                                }
                              </div>
                              <div className="quarter-col">
                                {
                                  item.year1.q4 === true &&
                                  <span className="background-div"></span>
                                }
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className="year-card-col">
                        <div className="year-card">
                          <span className="title">Year 2</span>
                          <div className="quarter-row first">
                            <div className="quarter-col">Q1</div>
                            <div className="quarter-col">Q2</div>
                            <div className="quarter-col">Q3</div>
                            <div className="quarter-col">Q4</div>
                          </div>
                          <div className="quarter-row">
                            <div className="quarter-col">
                              {
                                item.year2.q1 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year2.q2 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year2.q3 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year2.q4 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="year-card-col">
                        <div className="year-card">
                          <span className="title">Year 3</span>
                          <div className="quarter-row first">
                            <div className="quarter-col">Q1</div>
                            <div className="quarter-col">Q2</div>
                            <div className="quarter-col">Q3</div>
                            <div className="quarter-col">Q4</div>
                          </div>
                          <div className="quarter-row">
                            <div className="quarter-col">
                              {
                                item.year3.q1 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year3.q2 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year3.q3 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year3.q4 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="year-card-col">
                        <div className="year-card">
                          <span className="title">Year 4</span>
                          <div className="quarter-row first">
                            <div className="quarter-col">Q1</div>
                            <div className="quarter-col">Q2</div>
                            <div className="quarter-col">Q3</div>
                            <div className="quarter-col">Q4</div>
                          </div>
                          <div className="quarter-row">
                            <div className="quarter-col">
                              {
                                item.year4.q1 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year4.q2 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year4.q3 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year4.q4 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="year-card-col">
                        <div className="year-card">
                          <span className="title">Year 5</span>
                          <div className="quarter-row first">
                            <div className="quarter-col">Q1</div>
                            <div className="quarter-col">Q2</div>
                            <div className="quarter-col">Q3</div>
                            <div className="quarter-col">Q4</div>
                          </div>
                          <div className="quarter-row">
                            <div className="quarter-col">
                              {
                                item.year5.q1 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year5.q2 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year5.q3 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                            <div className="quarter-col">
                              {
                                item.year5.q4 === true &&
                                <span className="background-div"></span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            ))
          }
        </MasterPlanCard>
      </MasterPlanWrapper>
    </>
  );
};


