/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Link ===> link from react component to use for as an anchor

**/
import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { decrypter, crypter } from "../../webutils/webutils";
import { apiEndpoints } from "../../services/api-endpoints";
import { HomePageWalkthroughView } from "./HomePageWalkthroughView";
import { MyTaskFilter } from './MyTaskFilter-siri';
import { Collapse } from '@material-ui/core';
import serviceProvider from "../../services/axios";
import { AddPlantPopViewCtrl } from '../upgrade-add-plant-popup/AddPlantPopView-ctrl.js';
import { PieChart } from './PieChart';
import { tickSvg, pencilIcon, assignSvg, editUserIcon, downloadBtn, downloadHoverBtn, ddlArrow, filterCheckboxSvg, siriArrowSvg, radioBtnSvg, filterBtnSvg, uploadIcon } from './svg.js';
import { AssessorAssignPopup } from "./assessor-assign-popup.js";
import { UploadFilePopView } from './UploadFilePopView';

import {
  SiriDimensionFilterPopup,
  FilterWrapper,
  FilterAllDimentionButton,
  FilterDimensionBox,
  HeaderTitle,
  FilterBoxHeader,
  FilterBoxContent,
  FilterCloseButton,
  FilterBoxContentWrap,
  FilterBoxLable,
  FilterSiriTabContentWrap,
  FilterButtonWrapper,
  ResetFilterButton,
  ApplyFilterButton,

} from "./siri-filter-styled.js";

import { stepdata } from "./demo-step-data.js";

//PrepareHomeView component start
export const PrepareHomeViewSiri = (props) => {
  const history = useHistory();
  //defining variables here
  const homeDataItems = props.homeData;
  if (!homeDataItems.length) {
    return null;
  }

  const defaultFilterList = props.defaultData[0];
  const homeDataItem = props.homeData[0];
  const taskdata = props.resultstask[0];
  const isHomeTour = homeDataItem.home_tour;
  const isPlatformTrainingBtn = homeDataItem.home_plateform_training_button;
  const uid = homeDataItem.uid;
  const groupid = localStorage.getItem("group_id");
  //walkthrough variable
  const statusArr = [
    {
      id: 1,
      status: `Ready to validate`,
    },
    {
      id: 2,
      status: `Validated`,
    },
  ]
  const [activeSlide, setActiveSlide] = useState(1);

  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState({});
  const [tmpAssignUser, setTmpAssignUser] = useState("");
  const [allPlants, setAllPlants] = useState(defaultFilterList.all_plants_without_filter);
  const [filterAllPlants, setFilterAllPlants] = useState(homeDataItem.all_plants);
  const [filterPlant, setFilterPlant] = useState([]);
  const [isFilterDdl, setIsFilterDdl] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState([]);
  const [filterSearchInput, setFilterSearchInput] = useState("");
  const [appliedFilter, setAppliedFilter] = useState([]);
  const [selectedCR, setSelectedCR] = useState([]);
  const [filterCRSearchInput, setFilterCRSearchInput] = useState("");
  const [cRFilterPlant, setCRFilterPlant] = useState([]);
  const [appliedCRFilter, setAppliedCRFilter] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [filterStatusSearchInput, setFilterStatusSearchInput] = useState("");
  const [statusFilterPlant, setStatusFilterPlant] = useState([]);
  const [appliedStatusFilter, setAppliedStatusFilter] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [filterCitySearchInput, setFilterCitySearchInput] = useState("");
  const [cityFilterPlant, setCityFilterPlant] = useState([]);
  const [appliedCityFilter, setAppliedCityFilter] = useState([]);
  const [countryArr, setCountryArr] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [filterCountrySearchInput, setFilterCountrySearchInput] = useState("");
  const [countryFilterPlant, setCountryFilterPlant] = useState([]);
  const [appliedCountryFilter, setAppliedCountryFilter] = useState([]);
  // email filter related all variables
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [emailArr, setEmailArr] = useState(homeDataItem?.email_list);
  const [filterEmailSearchInput, setFilterEmailSearchInput] = useState("");
  const [emailFilterPlant, setEmailFilterPlant] = useState([]);
  const [appliedEmailFilter, setAppliedEmailFilter] = useState([]);
  // email filter related all variables end
  const [isPopup, setIsPopup] = useState(false);
  const [ismPopup, setIsmPopup] = useState(false);
  const [islPopup, setIslPopup] = useState(false);
  const [addPlantMsgPopup, setAddPlantMsgPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState('Assign an Assessor to [Plant name]');
  const [plantsend, setPlantsend] = useState({});
  //Upgrade Add Plant Popup
  const [showComponent, setShowComponent] = useState(false);
  // variable to open popup according to the upgrade status
  const [upgradeStatus, setUpgradeStatus] = useState(homeDataItem.upgraded);
  // Collapse Array
  const [collapseArray, setCollapseArray] = useState(homeDataItem.collapseArray);
  // variables for notification popup
  const [isStrComPopup, setIsStrComPopup] = useState(false);
  const [isStrLossPopup, setIsStrLossPopup] = useState(false);
  const [isStrAsmtPopup, setIsStrAsmtPopup] = useState(false);
  const [isStrSiriPopup, setIsStrSiriPopup] = useState(false);
  const [isDownloadPopup, setIsDownloadPopup] = useState(false);
  // end
  const [isAllCourse, setIsAllCourse] = useState(false);
  const [deletePopupId, setDeletePopupId] = useState(false);
  const [editPopupId, setEditPopupId] = useState(false);
  const [editPopupInput, setEditPopupInput] = useState('');
  // Siri Dimension Filter
  const [siriInputsData, setSiriInputsData] = useState({});
  const [dimensionCheckedArray, setDimensionCheckedArray] = useState(stepdata?.filter || []);
  const [dimensionCheckedApplyArray, setDimensionCheckedApplyArray] = useState(stepdata?.filter || []);
  const [siriDimension, setSiriDimension] = useState(stepdata.siriDimension);
  const [dimensionFilterCollapse, setDimensionFilterCollapse] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [filterSiriPlant, setFilterSiriPlant] = useState([]);
  const [dimSelctedFilter, setDimSelctedFilter] = useState({});
  const [isUploadReportPopup, setIsUploadReportPopup] = useState(false);
  const [bands, setBands] = useState(
    [
      {
        id: 1,
        name: `Band 0`,
      },
      {
        id: 2,
        name: `Band 1`,
      },
      {
        id: 3,
        name: `Band 2`,
      },
      {
        id: 4,
        name: `Band 3`,
      },
      {
        id: 5,
        name: `Band 4`,
      },
      {
        id: 6,
        name: `Band 5`,
      },
      {
        id: 0,
        name: `No Bands`,
      },
    ])


  //New variables for logistic training
  const [showPlatformTrainingBtn, setShowPlatformTrainingBtn] = useState(true);
  const [selectedDistributionPlant, setSelectedDistributionPlant] = useState(
    []
  );
  const [isAllLogisticCourse, setIsAllLogisticCourse] = useState(false);
  // loading variable
  const [isLoading, setIsLoading] = useState(false);
  const [currentPlantPagination, setCurrentPlantPagination] = useState(1);
  const [activePlantDdl, setActivePlantDdl] = useState(0);

  //New variables for logistic training  end
  // Pie chart
  const myPlantChartData = [
    {
      "label": "completed",
      "value": taskdata?.completed,
      "color": "#4CAF50",
      "innerData": [
        {
          "label": "todo",
          "value": taskdata?.todo_st,
          "color": "#2C55A0",
        },
        {
          "label": "inprogress",
          "value": taskdata?.inprogress_st,
          "color": "#1487E3",
        },
        {
          "label": "completed",
          "value": taskdata?.completed_st,
          "color": "#55D6BE",
        }
      ]
    },
    {
      "label": "inprogress",
      "value": taskdata?.inprogress,
      "color": "#FFBC1F"
    },
    {
      "label": "todo",
      "value": taskdata?.todo,
      "color": "#FF8383"
    }
  ];


  //New variables for logistic training  end
  const mouseEnterPie = (targetElement) => {
    let tmpTargetElement = document.querySelector(targetElement);
    if (tmpTargetElement) {
      tmpTargetElement.style.transform = 'scale(1)';
    }
  }
  const mouseLeavePie = (targetElement) => {
    let tmpTargetElement = document.querySelector(targetElement);
    tmpTargetElement.style.transform = 'scale(0.9)';
  }
  //function for view all course
  const HandleAllCourseView = () => {
    setIsAllCourse(!isAllCourse);
  };
  // Open Add plant popup
  const openSidebarAddPlantPopup = () => {
    let sidebarAddPopupBtn = document.querySelector(
      ".ng-sidebar-wrapper .ed_addpl_btn"
    );

    sidebarAddPopupBtn?.click();
  };
  //Function for view all courses in logistics training
  const HandleAllLogisticCourseView = () => {
    setIsAllLogisticCourse(!isAllLogisticCourse);
  };
  //Function for filter SCNv-2283
  const handleFilterDdl = (val) => {
    if (val == isFilterDdl) {
      setIsFilterDdl(null);
    } else {
      setIsFilterDdl(val);
    }
  };
  // function to clear all selected filters
  const clearAllFilter = () => {
    setSelectedPlant([]);
  };
  // SCNV-2283 Distribution cemter filter changes
  const clearAllDistributionFilter = () => {
    setSelectedDistributionPlant([]);
  };
  //Function to handle popup
  const HandlePopup = () => {
    setIsPopup(true);
  };
  //function to handle popup
  const HandlePopupm = () => {
    setIsmPopup(true);
  };
  //function to handle popup
  const HandlePopuNo = () => {
    return true;
  };
  //function to handle popup
  const HandlePopupl = () => {
    setIslPopup(true);
  };
  //function to handle loss popup
  const HandleLossPopupStr = () => {
    setIsStrLossPopup(true);
    // history.push('/roadmap/step1');
  };
  //function to handle Assessment popup
  const HandleAsmtPopupStr = () => {
    setIsStrAsmtPopup(true);
    // history.push('/roadmap/step1');
  };
  //function to handle Assessment & loss common popup
  const HandleAsmtLossPopupStr = () => {
    setIsStrComPopup(true);
    // history.push('/roadmap/step1');
  };
  // Function to handle collapse content for plants CDP-481
  const handleCollapse = (id) => {
    let filterArray = collapseArray.filter(i => i !== id);
    if (collapseArray.includes(id)) {
      setCollapseArray(filterArray);

      serviceProvider.post("/home?type=statusopenclose", JSON.stringify({ id: id, is_open: 0 }), true).then((response) => { });

    }
    else {
      filterArray.push(id);
      setCollapseArray(filterArray);
      serviceProvider.post("/home?type=statusopenclose", JSON.stringify({ id: id, is_open: 1 }), true).then((response) => { });
    }

  }
  //function to handle input
  const handleInput = (val) => {
    if (val == "all" && selectedPlant.length < allPlants.length) {
      let tmpArr = [];
      allPlants.forEach((el) => {
        tmpArr.push(el.id);
      });
      setSelectedPlant(tmpArr);
    } else if (val == "all" && selectedPlant.length === allPlants.length) {
      setSelectedPlant([]);
    } else if (selectedPlant.indexOf(val) === -1) {
      let tmpArr = [...selectedPlant];
      tmpArr.push(val);
      setSelectedPlant(tmpArr);
    } else if (selectedPlant.indexOf(val) > -1) {
      let tmpArr = selectedPlant.filter((item) => item !== val);
      setSelectedPlant(tmpArr);
    }
  };

  //function to apply filter
  const applyFilter = () => {
    setFilterSearchInput("");
    props.onFilter(selectedPlant);
    localStorage.setItem("selectedPlant", selectedPlant);
    setAppliedFilter(selectedPlant);
    setIsFilterDdl(false);
  };
  //function to handle filter search
  const handleFilterSearch = (e) => {
    let val = e.target.value;
    setFilterSearchInput(val);
    if (val.length > 0) {
      let tmpFilterArr = allPlants.filter((item) => {
        let tmpStr = item.plant_name.toLocaleLowerCase();
        let tmpVal = val.toLocaleLowerCase();
        return tmpStr.startsWith(tmpVal);
      });
      setFilterPlant(tmpFilterArr);
    } else {
      setFilterPlant(allPlants);
    }
  };
  //function to clear search filter
  const clearSerachFilter = () => {
    setFilterSearchInput("");
  };
  // SCNV-2283 Distribution center filter changes for logistics training

  //search filter changes for logistics training

  const RedirectToResourcePage = () => {
    serviceProvider
      .post(
        "/home?type=sethomeplateformtrainingbtn",
        JSON.stringify({ uid: uid }),
        true
      )
      .then((response) => { });
    setShowPlatformTrainingBtn(false);
    history.push("learning-center/5");
  };
  // SCNV-2283 Distribution cemter filter changes end
  // home tour close function
  const HandleHomeTourWalhthrough = () => {
    serviceProvider
      .post("/home?type=sethometour", JSON.stringify({ uid: uid }), true)
      .then((response) => { });
  };

  const handleShowDeleteModal = (id) => {
    setDeletePopupId(id);
  };
  const handleShowEditModal = (id, name) => {
    setEditPopupId(id);
    setEditPopupInput(name);
  };
  const deletePlantFunction = () => {
    let tmpFilterPlant = allPlants.filter(
      (item) => item.id !== deletePopupId
    );
    setAllPlants(tmpFilterPlant);

    let tmpFilterPlant1 = selectedPlant.filter(
      (item) => item !== deletePopupId
    );
    setSelectedPlant(tmpFilterPlant1);



    let id = deletePopupId;
    serviceProvider
      .post("/home?type=deleteplant", JSON.stringify({ id: id }), true)
      .then((response) => { props.setOnrefresh(!props.onrefresh); });
    setDeletePopupId(false);

  };



  // close edit plant popup
  const closeEditPopup = () => {
    setEditPopupId(false);
    setEditPopupInput('');
  }
  // rename plant name function
  const renamePlantNameFunction = () => {
    let tmpArr = [...filterAllPlants];
    let ind = filterAllPlants.findIndex(item => item.id === editPopupId)
    let updatedObj = {
      ...tmpArr[ind],
      plant_name: editPopupInput.trim()
    }
    tmpArr[ind] = updatedObj;
    //update in filter
    let tmpArrupdate = [...allPlants];
    let indupdate = allPlants.findIndex(item => item.id === editPopupId)
    let objupdateid = {
      ...tmpArrupdate[indupdate],
      plant_name: editPopupInput.trim()
    }
    tmpArrupdate[indupdate] = objupdateid;
    setAllPlants(tmpArrupdate);
    //update in filter

    serviceProvider
      .post("/plants?type=updateplant", JSON.stringify({ updatedObj }), true)
      .then((response) => {
        setFilterAllPlants(tmpArr);
        props.setOnrefresh(!props.onrefresh);
        //window.location.reload(false);
      });
    closeEditPopup();
  }
  // to check plant name is valid or not
  function validateName(str) {
    /* regular expression that matches a string that does not start with 
    a space and does not contain one space after another space:*/
    const pattern = /^(?! )(?!.*  )[^\s].*$/;
    return pattern.test(str);
  }
  // Function to scroll page for adding new plant CDP-444
  const highlightNewPlant = () => {
    let tempArr = [...filterAllPlants];
    // tempArr[tempArr.length-1]={...tempArr[tempArr.length-1],new:true};
    // setFilterAllPlants(tempArr);
    let training_content_div = document.querySelector('.training-content');
    let new_plant = document.querySelector('.highlight-new-plant');
    if (new_plant && filterAllPlants) {
      let plant_id = tempArr[tempArr.length - 1].id;
      setTimeout(() => {
        training_content_div.scrollTop = new_plant.getBoundingClientRect().top;
        // code to remove highlight after 10sec
        setTimeout(() => {
          new_plant.classList.remove('highlight-new-plant');
          serviceProvider.post("/home?type=hidenew", JSON.stringify({ plant_id: plant_id }), true).then((response) => { });
        }, 10000)
        // end code to remove highlight after 10sec
      }, 1000);
    }
  }
  // Function to handle upgrade popup 
  // Handle upgrade add plant
  const handleAddPlant = () => {
    setShowComponent(!showComponent);
  }
  const handleSiriPopup = (p) => {
    if (p) {
      setIsStrSiriPopup(true)
    }
  }

  // New filter code
  //function to handle input
  const handleCRInput = (val) => {
    if (val == "all" && selectedCR.length < allPlants.length) {
      let tmpArr = [];
      allPlants.forEach((el) => {
        tmpArr.push(el.id);
      });
      setSelectedCR(tmpArr);
    } else if (val == "all" && selectedCR.length === selectedCR.length) {
      setSelectedCR([]);
    } else if (selectedCR.indexOf(val) === -1) {
      let tmpArr = [...selectedCR];
      tmpArr.push(val);
      setSelectedCR(tmpArr);
    } else if (selectedCR.indexOf(val) > -1) {
      let tmpArr = selectedCR.filter((item) => item !== val);
      setSelectedCR(tmpArr);
    }
  };

  //function to apply filter
  const applyCRFilter = () => {
    setFilterCRSearchInput("");
    props.oncrFilter(selectedCR);
    localStorage.setItem("selectedCR", selectedCR);
    setAppliedCRFilter(selectedCR);
    setIsFilterDdl(false);
  };
  //function to handle filter search
  const handleCRFilterSearch = (e) => {
    let val = e.target.value;
    setFilterCRSearchInput(val);
    if (val.length > 0) {
      let tmpFilterArr = allPlants.filter((item) => {
        let tmpStr = item.companyRegistration.toLocaleLowerCase();
        let tmpVal = val.toLocaleLowerCase();
        return tmpStr.startsWith(tmpVal);
      });
      setCRFilterPlant(tmpFilterArr);
    } else {
      setCRFilterPlant(allPlants);
    }
  };
  //function to clear search filter
  const clearCRSerachFilter = () => {
    setFilterCRSearchInput("");
  };
  const clearAllCRFilter = () => {
    setSelectedCR([]);
  };

  // status filter functions

  //function to handle input
  const handleStatusInput = (val) => {
    if (val == "all" && selectedStatus.length < statusArr.length) {
      let tmpArr = [];
      statusArr.forEach((el) => {
        tmpArr.push(el.id);
      });
      setSelectedStatus(tmpArr);
    } else if (val == "all" && selectedStatus.length === selectedStatus.length) {
      setSelectedStatus([]);
    } else if (selectedStatus.indexOf(val) === -1) {
      let tmpArr = [...selectedStatus];
      tmpArr.push(val);
      setSelectedStatus(tmpArr);
    } else if (selectedStatus.indexOf(val) > -1) {
      let tmpArr = selectedStatus.filter((item) => item !== val);
      setSelectedStatus(tmpArr);
    }
  };
  //function to apply filter
  const applyStatusFilter = () => {
    setFilterStatusSearchInput("");
    props.onvalidateFilter(selectedStatus);
    localStorage.setItem("selectedStatus", selectedStatus);
    setAppliedStatusFilter(selectedStatus);
    setIsFilterDdl(false);
  };
  //function to handle filter search
  const handleStatusFilterSearch = (e) => {
    let val = e.target.value;
    setFilterStatusSearchInput(val);
    if (val.length > 0) {
      let tmpFilterArr = statusArr.filter((item) => {
        let tmpStr = item.status.toLocaleLowerCase();
        let tmpVal = val.toLocaleLowerCase();
        return tmpStr.startsWith(tmpVal);
      });
      setStatusFilterPlant(tmpFilterArr);
    } else {
      setStatusFilterPlant(allPlants);
    }
  };
  //function to clear search filter
  const clearStatusSerachFilter = () => {
    setFilterStatusSearchInput("");
  };
  const clearAllStatusFilter = () => {
    setSelectedStatus([]);
  };
  //function to handle input
  const handleCityInput = (val) => {
    if (val == "all" && selectedCity.length < cityArr.length) {
      let tmpArr = [];
      cityArr.forEach((el) => {
        tmpArr.push(el.id);
      });
      setSelectedCity(tmpArr);
    } else if (val == "all" && selectedCity.length === selectedCity.length) {
      setSelectedCity([]);
    } else if (selectedCity.indexOf(val) === -1) {
      let tmpArr = [...selectedCity];
      tmpArr.push(val);
      setSelectedCity(tmpArr);
    } else if (selectedCity.indexOf(val) > -1) {
      let tmpArr = selectedCity.filter((item) => item !== val);
      setSelectedCity(tmpArr);
    }
  };
  //function to apply filter
  const applyCityFilter = () => {
    setFilterCitySearchInput("");
    props.onlocationFilter(selectedCity);
    localStorage.setItem("selectedCity", selectedCity);
    setAppliedCityFilter(selectedCity);
    setIsFilterDdl(false);
  };
  //function to handle filter search
  const handleCityFilterSearch = (e) => {
    let val = e.target.value;
    setFilterCitySearchInput(val);
    if (val.length > 0) {
      let tmpFilterArr = cityArr.filter((item) => {
        let tmpStr = item.name.toLocaleLowerCase();
        let tmpVal = val.toLocaleLowerCase();
        return tmpStr.startsWith(tmpVal);
      });
      setCityFilterPlant(tmpFilterArr);
    } else {
      setCityFilterPlant(cityArr);
    }
  };
  //function to clear search filter
  const clearCitySerachFilter = () => {
    setFilterCitySearchInput("");
  };
  const clearAllCityFilter = () => {
    setSelectedCity([]);
  };
  // country filter functions
  const handleCountryInput = (val) => {
    if (val == "all" && selectedCountry.length < countryArr.length) {
      let tmpArr = [];
      countryArr.forEach((el) => {
        tmpArr.push(el.id);
      });
      setSelectedCountry(tmpArr);
    } else if (val == "all" && selectedCountry.length === selectedCountry.length) {
      setSelectedCountry([]);
    } else if (selectedCountry.indexOf(val) === -1) {
      let tmpArr = [...selectedCountry];
      tmpArr.push(val);
      setSelectedCountry(tmpArr);
    } else if (selectedCountry.indexOf(val) > -1) {
      let tmpArr = selectedCountry.filter((item) => item !== val);
      setSelectedCountry(tmpArr);
    }
  };
  //function to apply filter
  const applyCountryFilter = () => {
    setFilterCountrySearchInput("");
    props.onlocationcountryFilter(selectedCountry);
    localStorage.setItem("selectedCountry", selectedCountry);
    setAppliedCountryFilter(selectedCountry);
    setIsFilterDdl(false);
  };
  //function to handle filter search
  const handleCountryFilterSearch = (e) => {
    let val = e.target.value;
    setFilterCountrySearchInput(val);
    if (val.length > 0) {
      let tmpFilterArr = countryArr.filter((item) => {
        let tmpStr = item.name.toLocaleLowerCase();
        let tmpVal = val.toLocaleLowerCase();
        return tmpStr.startsWith(tmpVal);
      });
      setCountryFilterPlant(tmpFilterArr);
    } else {
      setCountryFilterPlant(countryArr);
    }
  };
  //function to clear search filter
  const clearCountrySerachFilter = () => {
    setFilterCountrySearchInput("");
  };
  const clearAllCountryFilter = () => {
    setSelectedCountry([]);
  };

  // all funtion related to by email filter
  const handleEmailInput = (val) => {
    if (val == "all" && selectedEmail.length < emailArr.length) {
      let tmpArr = [];
      emailArr.forEach((el) => {
        tmpArr.push(el.id);
      });
      setSelectedEmail(tmpArr);
    } else if (val == "all" && selectedEmail.length === selectedEmail.length) {
      setSelectedEmail([]);
    } else if (selectedEmail.indexOf(val) === -1) {
      let tmpArr = [...selectedEmail];
      tmpArr.push(val);
      setSelectedEmail(tmpArr);
    } else if (selectedEmail.indexOf(val) > -1) {
      let tmpArr = selectedEmail.filter((item) => item !== val);
      setSelectedEmail(tmpArr);
    }
  };

  //function to apply filter
  const applyEmailFilter = () => {
    setFilterEmailSearchInput("");
    props.onemailfilter(selectedEmail);
    localStorage.setItem("selectedEmail", selectedEmail);
    setAppliedEmailFilter(selectedEmail);
    setIsFilterDdl(false);
  };

  //function to handle filter search
  const handleEmailFilterSearch = (e) => {
    let val = e.target.value;
    setFilterEmailSearchInput(val);
    if (val.length > 0) {
      let tmpFilterArr = emailArr.filter((item) => {
        let tmpStr = item.name.toLocaleLowerCase();
        let tmpVal = val.toLocaleLowerCase();
        return tmpStr.startsWith(tmpVal);
      });
      setEmailFilterPlant(tmpFilterArr);
    } else {
      setEmailFilterPlant(emailArr);
    }
  };

  //function to clear search filter
  const clearEmailSerachFilter = () => {
    setFilterEmailSearchInput("");
  };

  const clearAllEmailFilter = () => {
    setSelectedEmail([]);
  };
  // all funtion related to by email filter end
  // Load more
  const loadMoreFunction = (e) => {
    let divElement = e.target;
    if (divElement.scrollTop + 100 >= divElement.scrollHeight - divElement.clientHeight && homeDataItem.total_plant != filterAllPlants?.slice(0, 10 * currentPlantPagination)?.length) {
      setIsLoading(true);
      setCurrentPlantPagination(prv => {
        return prv + 1;
      });
      setIsLoading(false);
    }
  }

  const getUniqCityArr = () => {
    let tmpAllPlants = defaultFilterList?.all_plants_without_filter || [];
    let tmpArr = [];
    tmpAllPlants?.forEach((item, ind) => {
      let findItem = tmpArr.find((i) => item.city_id == i.id);
      if (!findItem) {
        tmpArr.push({
          id: item.city_id,
          name: item.city_name
        })
      };
    });
    return tmpArr;
  }

  const getUniqCountryArr = () => {
    let tmpAllPlants = defaultFilterList?.all_plants_without_filter || [];
    let tmpArr = [];
    tmpAllPlants?.forEach((item, ind) => {
      let findItem = tmpArr.find((i) => item.country_id == i.id);
      if (!findItem) {
        tmpArr.push({
          id: item.country_id,
          name: item.country
        })
      };
    });
    return tmpArr;
  }
  // Assign Popup
  const handleAssignPoup = (e, plant) => {
    e.stopPropagation();
    e.preventDefault();
    setIsAssignSectionPopup(true);
    setActivePlantDdl(false);
    setPlantsend(plant);
  };
  const handleAssignTitle = () => {
    setPopupTitle('Assign an Assessor to [Plant name]');
  }

  const handleEditTitle = () => {
    setPopupTitle('Edit Assessor of [Plant name]');
  }

  // Siri Filter Functions
  const handleDimensionCheckBoxApplyFilter = (callApi = true) => {
    let tmpFilterObj = { ...siriInputsData };
    let tmpPlantData = { ...isActive };
    dimensionCheckedArray.forEach(i => {
      let tmpVal = document.querySelector(`[name="dim-band${i}"]:checked`);
      if (tmpVal) {
        tmpFilterObj[i] = tmpVal.value;
      }
    });
    let tmpFilterSiriDimesion = stepdata?.siriDimension?.filter(i => dimensionCheckedArray?.includes(i.id));
    setSiriInputsData(tmpFilterObj);
    if (dimensionCheckedArray?.length) {
      setSiriDimension(tmpFilterSiriDimesion);
      // props.setfilter(Object.values(tmpFilterObj));
    } else {
      setSiriDimension(stepdata?.siriDimension);
      // props.setfilter(0);
    }
    setDimensionCheckedApplyArray(dimensionCheckedArray);
    setFilterSiriPlant(prv => {
      let tmpArr = prv;
      tmpArr.push(tmpPlantData.id);
      return tmpArr;
    })
    setIsActive(false);
    if (callApi) {
      serviceProvider.post("/roadmap?param=addfilter", JSON.stringify({ pid: tmpPlantData.id, sid: tmpPlantData.sid, filter: dimensionCheckedArray })).then((response) => { })
    };
  }

  const handleDimensionCheckBox = (e, val) => {
    let tmpTarget = e.target;
    if (val == "all") {
      if (stepdata?.siriDimension?.length === dimensionCheckedArray?.length) {
        setDimensionCheckedArray([]);
      } else {
        let tmpArr = stepdata?.siriDimension?.map(i => i.id);
        setDimensionCheckedArray(tmpArr);
      }
    } else {
      if (dimensionCheckedArray?.includes(val)) {
        let tmpFilterArr = dimensionCheckedArray?.filter(i => i != val);
        setDimensionCheckedArray(tmpFilterArr);
        setDimSelctedFilter(prv => {
          return {
            ...prv,
            [isActive.id]: tmpFilterArr
          }
        })
      } else {
        let tmpArr = [...dimensionCheckedArray];
        tmpArr.push(val);
        setDimensionCheckedArray(tmpArr);
        setDimSelctedFilter(prv => {
          return {
            ...prv,
            [isActive.id]: tmpArr
          }
        })
      }
    }
  }

  const closeDimensionFilter = () => {
    let tmpData = { ...isActive };
    setDimensionCheckedArray(dimensionCheckedApplyArray);
    setDimSelctedFilter(prv => {
      return {
        ...prv,
        [tmpData.id]: dimensionCheckedApplyArray
      }
    });
    setIsActive(false);
    //closeFilter();
  };

  const handleSiriFilter = (id, sid, name, filter = []) => {
    setIsActive({ id, sid, name });
    setDimensionCheckedArray(prv => {
      let tmpSelectedArr = dimSelctedFilter[id] || [...filter];
      return [...tmpSelectedArr]
    });
  }
  // Function to handle siri tab wrapper
  const handleFilterOnchangeData = (dimension_id, band_id) => {
    let tmpData = { ...siriInputsData };
    tmpData[dimension_id] = band_id;
    setSiriInputsData(tmpData);
  }
  const getNextBandsAccordingToSelectedBands = (id, array = [...bands]) => {
    let tmpIndex = array?.findIndex(i => i.id === id);
    if (tmpIndex > -1) {
      let tmpFilterArray = array?.filter((item, index) => index > tmpIndex);
      return tmpFilterArray;
    }
    else {
      return array;
    }
  }
  const handleDimensionFilterCollapse = (id) => {
    // console.log(id);
    if (dimensionFilterCollapse?.includes(id)) {
      let tmpFilter = dimensionFilterCollapse?.filter(i => i != id);
      setDimensionFilterCollapse(tmpFilter);
    }
    else {
      let tmpArr = [...dimensionFilterCollapse]
      tmpArr.push(id);
      setDimensionFilterCollapse(tmpArr);
    }
  }
  // Function to handle Upload report
  const handleUploadReportPopup = (e, val) => {
    e.preventDefault();

    setIsUploadReportPopup(val);
  }
  const handleCloseUploadReportPopup = () => {
    setIsUploadReportPopup(false);
  }
  // end
  const handleResetFilter = () => {
    localStorage.removeItem("selectedPlant");
    localStorage.removeItem("selectedCity");
    localStorage.removeItem("selectedCountry");
    localStorage.removeItem("selectedStatus");
    localStorage.removeItem("selectedCR");
    props.onFilter("");
    props.oncrFilter("");
    props.onvalidateFilter("");
    props.onlocationFilter("");
    props.onlocationcountryFilter("");
    setAppliedCountryFilter([]);
    setSelectedCountry([]);

    setSelectedPlant([]);
    setAppliedFilter([]);

    setAppliedCRFilter([]);
    setAppliedCRFilter([]);

    setSelectedStatus([]);
    setAppliedStatusFilter([]);

    setSelectedCity([]);
    setAppliedCityFilter([]);

    setSelectedEmail([]);
    setAppliedEmailFilter([]);
  }

  const handleSelectedFilteredArrayFirstTime = () => {
    let defaultArr = defaultFilterList?.all_plants_without_filter?.length || 0;
    let appPlantArr = defaultFilterList?.all_plants?.length || 0;
    if (appPlantArr != defaultArr) {
      const local_filterPlants = localStorage.getItem("selectedPlant")?.toString();
      const local_crfilter = localStorage.getItem("selectedCR")?.toString();
      const local_locationfilter = localStorage.getItem("selectedCity")?.toString();
      const local_locationCountryfilter = localStorage.getItem("selectedCountry")?.toString();
      const local_validatefilter = localStorage.getItem("selectedStatus")?.toString();
      const local_emailfilter = localStorage.getItem("selectedEmail")?.toString();
      if (local_filterPlants) {
        const selectedArr = local_filterPlants.split(',');
        setSelectedPlant(selectedArr || []);
        setAppliedFilter(selectedArr || []);
      }

      if (local_crfilter) {
        const selectedArr = local_crfilter.split(',');
        setAppliedCRFilter(selectedArr || []);
        setAppliedCRFilter(selectedArr || []);
      }

      if (local_locationfilter) {
        const selectedArr = local_locationfilter.split(',');
        setSelectedCity(selectedArr || []);
        setAppliedCityFilter(selectedArr || []);
      }

      if (local_locationCountryfilter) {
        const selectedArr = local_locationCountryfilter.split(',');
        setAppliedCountryFilter(selectedArr || []);
        setSelectedCountry(selectedArr || []);
      }
      if (local_validatefilter) {
        const selectedArr = local_validatefilter.split(',')?.map(i => Number(i));
        setSelectedStatus(selectedArr || []);
        setAppliedStatusFilter(selectedArr || []);
      }
      if (local_emailfilter) {
        const selectedArr = local_emailfilter.split(',')?.map(i => i);
        setSelectedEmail(selectedArr || []);
        setAppliedEmailFilter(selectedArr || []);
      }
    }
  }

  useEffect(() => {
    setSelectedPlant(appliedFilter);
    //SCNV-2283 Filter changes
    setSelectedCR(appliedCRFilter);
    setSelectedStatus(appliedStatusFilter);
    setSelectedCity(appliedCityFilter);
    setSelectedCountry(appliedCountryFilter);
    setSelectedEmail(appliedEmailFilter);
  }, [isFilterDdl]);

  useEffect(() => {
    setFilterAllPlants(homeDataItem.all_plants);
    document.onclick = (e) => {
      if (!e.target.closest(".prp-filter-wrapper")) {
        handleFilterDdl(false);
      }
    };
  }, [props.homeData]);
  // Single time useEffect CDP-444
  useEffect(() => {
    highlightNewPlant();
    localStorage.removeItem(`upgradeSection`);
    localStorage.removeItem(`plantName`);
    localStorage.removeItem(`plantId`);
    localStorage.removeItem(`comboIdget`);
    setCityArr(getUniqCityArr());
    setCountryArr(getUniqCountryArr());
    setEmailArr(homeDataItem?.email_list);
    handleSelectedFilteredArrayFirstTime();
    // Function to add lazy loading for plants
    let training_content = document.querySelector('.training-content');
    if (homeDataItem.total_plant > 10) {
      training_content.addEventListener(`scroll`, loadMoreFunction);
    }
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.plant-assign-cir-wrapper')) {
        setActivePlantDdl(0);
      }
    })
    return () => {
      training_content.removeEventListener(`scroll`, loadMoreFunction);
      document.removeEventListener('click', (e) => {
        if (!e.target.closest('.plant-assign-cir-wrapper')) {
          setActivePlantDdl(0);
        }
      })
    }
    // end

  }, []);

  return (
    <>
      {/* main wrapper */}
      <div className="prepare-home-wrapper">
        {/* My Task changes div */}
        <div className="my-task-wrapper">
          <div className="heading-div">
            <p>Task Status</p>
            <div className="my-task-filter-div">
              <MyTaskFilter allPlants={homeDataItem.all_plants_without_filter} taskdata={taskdata} onFilterPlantsTask={props.onFilterPlantsTask} {...props} />
            </div>
          </div>
          <div className="my-task-row">
            <div className="my-task-left-div">
              <PieChart id='plantChart' number={taskdata?.total} text={taskdata?.total > 1 ? `Plants` : `Plant`} hoverCard={true} hoverCardPrefix={'card-'} chartData={myPlantChartData} />
            </div>
            <div className="my-task-right-wrap">
              <div className="my-task-inner-div">
                <h2 className="my-task-title">ASSESSMENT</h2>
                <div className="my-task-right-row">
                  <Link to="/task-center?filter=todo" className="MultiPlantCard card-todo red"
                    onMouseEnter={() => mouseEnterPie('.card-g-todo')}
                    onMouseLeave={() => mouseLeavePie('.card-g-todo')}
                  >
                    <div className="StatusOval" red>
                      <i className='' dangerouslySetInnerHTML={{ __html: StatusOvalSvg }}></i>
                    </div>
                    <div className="StatusTitle">To Do</div>
                    <div className="MultiPlantFooter">
                      <div className="LeftPlantFooter">
                        {taskdata?.todo} {taskdata?.todo > 1 ? `Plants` : "Plant"}
                      </div>
                      <span className="border"></span>
                      <div className="RightPlantFooter">
                        <span className="percent">{taskdata?.todo_per}%</span>
                        <span className="img">
                          <img src={require("../../assets/img/prepare-home/right-angle.svg").default} alt="Flag Image" />
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/task-center?filter=inprogress" className="MultiPlantCard card-inprogress yellow"
                    onMouseEnter={() => mouseEnterPie('.card-g-inprogress')}
                    onMouseLeave={() => mouseLeavePie('.card-g-inprogress')}
                  >
                    <div className="StatusOval" red>
                      <i className='' dangerouslySetInnerHTML={{ __html: StatusOvalSvg }}></i>
                    </div>
                    <div className="StatusTitle">Inprogress</div>
                    <div className="MultiPlantFooter">
                      <div className="LeftPlantFooter">
                        {taskdata?.inprogress} {taskdata?.inprogress > 1 ? `Plants` : "Plant"}
                      </div>
                      <span className="border"></span>
                      <div className="RightPlantFooter">
                        <span className="percent">{taskdata?.inprogress_per}%</span>
                        <span className="img">
                          <img src={require("../../assets/img/prepare-home/right-angle.svg").default} alt="Flag Image" />
                        </span>
                      </div>
                    </div>
                  </Link>
                  <Link to="/task-center?filter=completed" className="MultiPlantCard card-completed green"
                    onMouseEnter={() => mouseEnterPie('.card-g-completed')}
                    onMouseLeave={() => mouseLeavePie('.card-g-completed')}
                  >
                    <div className="StatusOval" red>
                      <i className='' dangerouslySetInnerHTML={{ __html: StatusOvalSvg }}></i>
                    </div>
                    <div className="StatusTitle">Completed</div>
                    <div className="MultiPlantFooter">
                      <div className="LeftPlantFooter">
                        {taskdata?.completed} {taskdata?.completed > 1 ? `Plants` : "Plant"}
                      </div>
                      <span className="border"></span>
                      <div className="RightPlantFooter">
                        <span className="percent">{taskdata?.completed_per}%</span>
                        <span className="img">
                          <img src={require("../../assets/img/prepare-home/right-angle.svg").default} alt="Flag Image" />
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="my-task-inner-div">
                <h2 className="my-task-title">STRATEGY ROADMAP</h2>
                <div className="my-task-right-row bottom">
                  <div className="MultiPlantCard card-todo card-todo-inner purple">
                    <div className="StatusOval" red>
                      <i className='' dangerouslySetInnerHTML={{ __html: StatusOvalSvg }}></i>
                    </div>
                    <div className="StatusTitle">To Do</div>
                    <div className="MultiPlantFooter">
                      <div className="LeftPlantFooter">
                        {taskdata?.todo_st} {taskdata?.todo_st > 1 ? `Plants` : "Plant"}
                      </div>
                      <span className="border"></span>
                      <div className="RightPlantFooter">
                        <span className="percent">{taskdata?.todo_per_st}%</span>
                        <span className="img">
                          <img src={require("../../assets/img/prepare-home/right-angle.svg").default} alt="Flag Image" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="MultiPlantCard card-inprogress card-inprogress-inner blue">
                    <div className="StatusOval" red>
                      <i className='' dangerouslySetInnerHTML={{ __html: StatusOvalSvg }}></i>
                    </div>
                    <div className="StatusTitle">Inprogress</div>
                    <div className="MultiPlantFooter">
                      <div className="LeftPlantFooter">
                        {taskdata?.inprogress_st} {taskdata?.inprogress_st > 1 ? `Plants` : "Plant"}
                      </div>
                      <span className="border"></span>
                      <div className="RightPlantFooter">
                        <span className="percent">{taskdata?.inprogress_per_st}%</span>
                        <span className="img">
                          <img src={require("../../assets/img/prepare-home/right-angle.svg").default} alt="Flag Image" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="MultiPlantCard card-completed card-completed-inner seaGreen">
                    <div className="StatusOval" red>
                      <i className='' dangerouslySetInnerHTML={{ __html: StatusOvalSvg }}></i>
                    </div>
                    <div className="StatusTitle">Completed</div>
                    <div className="MultiPlantFooter">
                      <div className="LeftPlantFooter">
                        {taskdata?.completed_st} {taskdata?.completed_st > 1 ? `Plants` : "Plant"}
                      </div>
                      <span className="border"></span>
                      <div className="RightPlantFooter">
                        <span className="percent">{taskdata?.completed_per_st}%</span>
                        <span className="img">
                          <img src={require("../../assets/img/prepare-home/right-angle.svg").default} alt="Flag Image" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="MultiPlantCard blue-text">
                    <div className="StatusOval">
                      <i className='' dangerouslySetInnerHTML={{ __html: StatusOvalSvg }}></i>
                    </div>
                    <div className="StatusTitle">Results Downloaded</div>
                    <div className="MultiPlantFooter">
                      <div className="LeftPlantFooter">
                        {taskdata?.download_st} {taskdata?.download_st > 1 ? `Plants` : "Plant"}
                      </div>
                      <span className="border"></span>
                      <div className="RightPlantFooter">
                        <span className="percent">{taskdata?.download_per_st}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Added wrapper for new category MY Assets and also divided it into two subcategories */}
        <div className="assets-wrapper">
          {/* Main Category Heading */}
          <h1 className="prepare-top-h1 walk-2">
            <img
              src={require("../../assets/img/prepare-home/measure.svg").default}
            />
            <span className="myplant-h1">My Plants</span>


            <div className="prepare-assets-heading">

              {/* all plants filter */}
              {(homeDataItem.total_plant >= 1) && (
                <div className="prp-filter-wrapper">
                  <button
                    className={`prp-fltr-btn ${isFilterDdl == 1
                      ? "active"
                      : selectedPlant.length > 0
                        ? "default"
                        : ""
                      }`}
                    onClick={() => handleFilterDdl(1)}
                  >
                    <span>
                      {selectedPlant.length > 0
                        ? `Plant Selected (${selectedPlant.length})`
                        : "All Plants"}
                    </span>
                    <span className="down-arrow-icon down">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z"
                          fill="#455A64"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={`prp-filter-box ${isFilterDdl == 1 ? "active" : ""
                      }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="header-top">
                      <div
                        className={`prp-search-box ${filterSearchInput.length > 0 ? "active" : ""
                          }`}
                      >
                        <label>
                          <svg
                            className="srch-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.45341 0C2.89291 0 0 2.88889 0 6.44445C0 10 2.89291 12.8889 6.45341 12.8889C7.98887 12.8889 9.41307 12.3556 10.5035 11.4444L14.8651 15.8C15.1321 16.0667 15.5549 16.0667 15.7997 15.8C16.0668 15.5333 16.0668 15.1333 15.7997 14.8667L11.4381 10.5111C12.3505 9.4 12.8846 7.97778 12.8846 6.44445C12.9068 2.88889 10.0139 0 6.45341 0ZM6.45341 1.33333C9.27955 1.33333 11.5716 3.62222 11.5716 6.44445C11.5716 9.26667 9.27955 11.5556 6.45341 11.5556C3.62726 11.5556 1.33519 9.26667 1.33519 6.44445C1.33519 3.6 3.62726 1.33333 6.45341 1.33333Z"
                              fill="#A6A7AD"
                            />
                          </svg>

                          <input
                            type="text"
                            placeholder="Start typing to search"
                            value={filterSearchInput}
                            onChange={(e) => handleFilterSearch(e)}
                          />
                          <button
                            className="clear-all-btn"
                            onClick={clearSerachFilter}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy={8} r={8} fill="#7D868C" />
                              <path
                                d="M11.7172 5.08856C11.8734 4.93235 11.8734 4.67908 11.7172 4.52287L11.4771 4.28284C11.3209 4.12663 11.0677 4.12663 10.9114 4.28284L8.28284 6.91144C8.12663 7.06765 7.87337 7.06765 7.71716 6.91144L5.08856 4.28284C4.93235 4.12663 4.67908 4.12663 4.52287 4.28284L4.28284 4.52287C4.12663 4.67908 4.12663 4.93235 4.28284 5.08856L6.91144 7.71716C7.06765 7.87337 7.06765 8.12663 6.91144 8.28284L4.28284 10.9114C4.12663 11.0677 4.12663 11.3209 4.28284 11.4771L4.52287 11.7172C4.67908 11.8734 4.93235 11.8734 5.08856 11.7172L7.71716 9.08856C7.87337 8.93235 8.12663 8.93235 8.28284 9.08856L10.9114 11.7172C11.0677 11.8734 11.3209 11.8734 11.4771 11.7172L11.7172 11.4771C11.8734 11.3209 11.8734 11.0677 11.7172 10.9114L9.08856 8.28284C8.93235 8.12663 8.93235 7.87337 9.08856 7.71716L11.7172 5.08856Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="body-list">
                      {filterSearchInput.length < 1 ? (
                        <>
                          {/* selected plant list map */}
                          {allPlants.map((item, i) => (
                            <div key={i}>
                              {selectedPlant.length === allPlants.length &&
                                i === 0 &&
                                filterSearchInput.length < 1 && (
                                  <label className="chk-label">
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onClick={() => handleInput("all")}
                                    />
                                    <span className="svg-icon">
                                      <img
                                        src={
                                          require("../../assets/img/prepare-home/chk-white.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <span className="plant-chk-txt 1">
                                      All Plants
                                    </span>
                                  </label>
                                )}
                              {selectedPlant.indexOf(item.id) > -1 &&
                                selectedPlant.length > 0 &&
                                selectedPlant.length < allPlants.length &&
                                i === 0 && (
                                  <span className="plant-bold-txt">
                                    SELECTED PLANTS
                                  </span>
                                )}
                              {selectedPlant.indexOf(item.id) > -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    value={item}
                                    onClick={() => handleInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 2">
                                    {item.plant_name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* selected plant list map end*/}
                          {/* all plants list map and unselected list map*/}
                          {allPlants.map((item, i) => (
                            <div key={i}>
                              {selectedPlant.length == 0 && i === 0 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleInput("all")}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 3">
                                    All Plants
                                  </span>
                                </label>
                              )}

                              {selectedPlant.length > 0 &&
                                selectedPlant.length < allPlants.length &&
                                i == 0 && (
                                  <span className="plant-bold-txt">
                                    OTHER PLANTS
                                  </span>
                                )}

                              {selectedPlant.indexOf(item.id) === -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    id={`plantInput${i}`}
                                    onChange={() => handleInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 4">
                                    {item.plant_name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* all plants list map and unselected list map end*/}
                        </>
                      ) : (
                        <>
                          {filterPlant.map((item, i) => (
                            <label className="chk-label">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  selectedPlant.indexOf(item.id) > -1
                                }
                                value={item.id}
                                onChange={() => handleInput(item.id)}
                              />
                              <span className="svg-icon">
                                <img
                                  src={
                                    require("../../assets/img/prepare-home/chk-white.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="plant-chk-txt 5">
                                {item.plant_name}
                              </span>
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                    {/* body-list end */}
                    <div className="footer-bottom">
                      <button className="btn-ft clear" onClick={clearAllFilter}>
                        Clear all
                      </button>
                      <button className="btn-ft apply" onClick={applyFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* prp-filter-box end */}
                </div>
              )}
              {/* all plants filter end*/}

              {/* Cr Number filter  start*/}
              {(homeDataItem.total_plant >= 1) && (
                <div className="prp-filter-wrapper">
                  <button
                    className={`prp-fltr-btn ${isFilterDdl == 2
                      ? "active"
                      : selectedPlant.length > 0
                        ? "default"
                        : ""
                      }`}
                    onClick={() => handleFilterDdl(2)}
                  >
                    <span>
                      By CR Number
                    </span>
                    <span className="down-arrow-icon down">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z"
                          fill="#455A64"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={`prp-filter-box ${isFilterDdl == 2 ? "active" : ""
                      }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="header-top">
                      <div
                        className={`prp-search-box ${filterCRSearchInput.length > 0 ? "active" : ""
                          }`}
                      >
                        <label>
                          <svg
                            className="srch-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.45341 0C2.89291 0 0 2.88889 0 6.44445C0 10 2.89291 12.8889 6.45341 12.8889C7.98887 12.8889 9.41307 12.3556 10.5035 11.4444L14.8651 15.8C15.1321 16.0667 15.5549 16.0667 15.7997 15.8C16.0668 15.5333 16.0668 15.1333 15.7997 14.8667L11.4381 10.5111C12.3505 9.4 12.8846 7.97778 12.8846 6.44445C12.9068 2.88889 10.0139 0 6.45341 0ZM6.45341 1.33333C9.27955 1.33333 11.5716 3.62222 11.5716 6.44445C11.5716 9.26667 9.27955 11.5556 6.45341 11.5556C3.62726 11.5556 1.33519 9.26667 1.33519 6.44445C1.33519 3.6 3.62726 1.33333 6.45341 1.33333Z"
                              fill="#A6A7AD"
                            />
                          </svg>

                          <input
                            type="text"
                            placeholder="Start typing to search"
                            value={filterCRSearchInput}
                            onChange={(e) => handleCRFilterSearch(e)}
                          />
                          <button
                            className="clear-all-btn"
                            onClick={clearCRSerachFilter}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy={8} r={8} fill="#7D868C" />
                              <path
                                d="M11.7172 5.08856C11.8734 4.93235 11.8734 4.67908 11.7172 4.52287L11.4771 4.28284C11.3209 4.12663 11.0677 4.12663 10.9114 4.28284L8.28284 6.91144C8.12663 7.06765 7.87337 7.06765 7.71716 6.91144L5.08856 4.28284C4.93235 4.12663 4.67908 4.12663 4.52287 4.28284L4.28284 4.52287C4.12663 4.67908 4.12663 4.93235 4.28284 5.08856L6.91144 7.71716C7.06765 7.87337 7.06765 8.12663 6.91144 8.28284L4.28284 10.9114C4.12663 11.0677 4.12663 11.3209 4.28284 11.4771L4.52287 11.7172C4.67908 11.8734 4.93235 11.8734 5.08856 11.7172L7.71716 9.08856C7.87337 8.93235 8.12663 8.93235 8.28284 9.08856L10.9114 11.7172C11.0677 11.8734 11.3209 11.8734 11.4771 11.7172L11.7172 11.4771C11.8734 11.3209 11.8734 11.0677 11.7172 10.9114L9.08856 8.28284C8.93235 8.12663 8.93235 7.87337 9.08856 7.71716L11.7172 5.08856Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="body-list">
                      {filterCRSearchInput.length < 1 ? (
                        <>
                          {/* selected plant list map */}
                          {allPlants.map((item, i) => (
                            <div key={i}>
                              {selectedCR.length === allPlants.length &&
                                i === 0 &&
                                filterCRSearchInput.length < 1 && (
                                  <label className="chk-label">
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onClick={() => handleCRInput("all")}
                                    />
                                    <span className="svg-icon">
                                      <img
                                        src={
                                          require("../../assets/img/prepare-home/chk-white.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <span className="plant-chk-txt 1">
                                      All CR Number
                                    </span>
                                  </label>
                                )}
                              {selectedCR.indexOf(item.id) > -1 &&
                                selectedCR.length > 0 &&
                                selectedCR.length < allPlants.length &&
                                i === 0 && (
                                  <span className="plant-bold-txt">
                                    SELECTED CR NUMBERS
                                  </span>
                                )}
                              {selectedCR.indexOf(item.id) > -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    value={item}
                                    onClick={() => handleCRInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 2">
                                    {item.companyRegistration}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* selected plant list map end*/}
                          {/* all plants list map and unselected list map*/}
                          {allPlants.map((item, i) => (
                            <div key={i}>
                              {selectedCR.length == 0 && i === 0 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleCRInput("all")}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 3">
                                    All CR Number
                                  </span>
                                </label>
                              )}

                              {selectedCR.length > 0 &&
                                selectedCR.length < allPlants.length &&
                                i == 0 && (
                                  <span className="plant-bold-txt">
                                    OTHER CR NUMBERS
                                  </span>
                                )}

                              {selectedCR.indexOf(item.id) === -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    id={`crPlantInput${i}`}
                                    onChange={() => handleCRInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 4">
                                    {item.companyRegistration}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* all plants list map and unselected list map end*/}
                        </>
                      ) : (
                        <>
                          {cRFilterPlant.map((item, i) => (
                            <label className="chk-label">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  selectedCR.indexOf(item.id) > -1
                                }
                                value={item.id}
                                onChange={() => handleCRInput(item.id)}
                              />
                              <span className="svg-icon">
                                <img
                                  src={
                                    require("../../assets/img/prepare-home/chk-white.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="plant-chk-txt 5">
                                {item.companyRegistration}
                              </span>
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                    {/* body-list end */}
                    <div className="footer-bottom">
                      <button className="btn-ft clear" onClick={clearAllCRFilter}>
                        Clear all
                      </button>
                      <button className="btn-ft apply" onClick={applyCRFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* prp-filter-box end */}
                </div>
              )}
              {/* CR number filter end */}
              {/* Status filter  start*/}
              {(homeDataItem.total_plant >= 1) && (
                <div className="prp-filter-wrapper">
                  <button
                    className={`prp-fltr-btn ${isFilterDdl == 3
                      ? "active"
                      : selectedStatus.length > 0
                        ? "default"
                        : ""
                      }`}
                    onClick={() => handleFilterDdl(3)}
                  >
                    <span>
                      By Status
                    </span>
                    <span className="down-arrow-icon down">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z"
                          fill="#455A64"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={`prp-filter-box ${isFilterDdl == 3 ? "active" : ""
                      }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="header-top">
                      <div
                        className={`prp-search-box ${filterStatusSearchInput.length > 0 ? "active" : ""
                          }`}
                      >
                        <label>
                          <svg
                            className="srch-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.45341 0C2.89291 0 0 2.88889 0 6.44445C0 10 2.89291 12.8889 6.45341 12.8889C7.98887 12.8889 9.41307 12.3556 10.5035 11.4444L14.8651 15.8C15.1321 16.0667 15.5549 16.0667 15.7997 15.8C16.0668 15.5333 16.0668 15.1333 15.7997 14.8667L11.4381 10.5111C12.3505 9.4 12.8846 7.97778 12.8846 6.44445C12.9068 2.88889 10.0139 0 6.45341 0ZM6.45341 1.33333C9.27955 1.33333 11.5716 3.62222 11.5716 6.44445C11.5716 9.26667 9.27955 11.5556 6.45341 11.5556C3.62726 11.5556 1.33519 9.26667 1.33519 6.44445C1.33519 3.6 3.62726 1.33333 6.45341 1.33333Z"
                              fill="#A6A7AD"
                            />
                          </svg>

                          <input
                            type="text"
                            placeholder="Start typing to search"
                            value={filterStatusSearchInput}
                            onChange={(e) => handleStatusFilterSearch(e)}
                          />
                          <button
                            className="clear-all-btn"
                            onClick={clearStatusSerachFilter}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy={8} r={8} fill="#7D868C" />
                              <path
                                d="M11.7172 5.08856C11.8734 4.93235 11.8734 4.67908 11.7172 4.52287L11.4771 4.28284C11.3209 4.12663 11.0677 4.12663 10.9114 4.28284L8.28284 6.91144C8.12663 7.06765 7.87337 7.06765 7.71716 6.91144L5.08856 4.28284C4.93235 4.12663 4.67908 4.12663 4.52287 4.28284L4.28284 4.52287C4.12663 4.67908 4.12663 4.93235 4.28284 5.08856L6.91144 7.71716C7.06765 7.87337 7.06765 8.12663 6.91144 8.28284L4.28284 10.9114C4.12663 11.0677 4.12663 11.3209 4.28284 11.4771L4.52287 11.7172C4.67908 11.8734 4.93235 11.8734 5.08856 11.7172L7.71716 9.08856C7.87337 8.93235 8.12663 8.93235 8.28284 9.08856L10.9114 11.7172C11.0677 11.8734 11.3209 11.8734 11.4771 11.7172L11.7172 11.4771C11.8734 11.3209 11.8734 11.0677 11.7172 10.9114L9.08856 8.28284C8.93235 8.12663 8.93235 7.87337 9.08856 7.71716L11.7172 5.08856Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="body-list">
                      {filterStatusSearchInput.length < 1 ? (
                        <>
                          {/* selected plant list map */}
                          {statusArr.map((item, i) => (
                            <div key={i}>
                              {selectedStatus.length === statusArr.length &&
                                i === 0 &&
                                filterStatusSearchInput.length < 1 && (
                                  <label className="chk-label">
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onClick={() => handleStatusInput("all")}
                                    />
                                    <span className="svg-icon">
                                      <img
                                        src={
                                          require("../../assets/img/prepare-home/chk-white.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <span className="plant-chk-txt 1">
                                      All Status
                                    </span>
                                  </label>
                                )}
                              {selectedStatus.indexOf(item.id) > -1 &&
                                selectedStatus.length > 0 &&
                                selectedStatus.length < statusArr.length &&
                                i === 0 && (
                                  <span className="plant-bold-txt">
                                    SELECTED STATUS
                                  </span>
                                )}
                              {selectedStatus.indexOf(item.id) > -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    value={item}
                                    onClick={() => handleStatusInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 2">
                                    {item.status}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* selected plant list map end*/}
                          {/* all plants list map and unselected list map*/}
                          {statusArr.map((item, i) => (
                            <div key={i}>
                              {selectedStatus.length == 0 && i === 0 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleStatusInput("all")}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 3">
                                    All Status
                                  </span>
                                </label>
                              )}

                              {selectedStatus.length > 0 &&
                                selectedStatus.length < statusArr.length &&
                                i == 0 && (
                                  <span className="plant-bold-txt">
                                    OTHER STATUS
                                  </span>
                                )}

                              {selectedStatus.indexOf(item.id) === -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    id={`statusPlantInput${i}`}
                                    onChange={() => handleStatusInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 4">
                                    {item.status}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* all plants list map and unselected list map end*/}
                        </>
                      ) : (
                        <>
                          {statusFilterPlant.map((item, i) => (
                            <label className="chk-label">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  selectedStatus.indexOf(item.id) > -1
                                }
                                value={item.id}
                                onChange={() => handleStatusInput(item.id)}
                              />
                              <span className="svg-icon">
                                <img
                                  src={
                                    require("../../assets/img/prepare-home/chk-white.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="plant-chk-txt 5">
                                {item.status}
                              </span>
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                    {/* body-list end */}
                    <div className="footer-bottom">
                      <button className="btn-ft clear" onClick={clearAllStatusFilter}>
                        Clear all
                      </button>
                      <button className="btn-ft apply" onClick={applyStatusFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* prp-filter-box end */}
                </div>
              )}
              {/* Status filter end */}
              {/* City filter  start*/}
              {(homeDataItem.total_plant >= 1) && (
                <div className="prp-filter-wrapper">
                  <button
                    className={`prp-fltr-btn ${isFilterDdl == 4
                      ? "active"
                      : selectedCity.length > 0
                        ? "default"
                        : ""
                      }`}
                    onClick={() => handleFilterDdl(4)}
                  >
                    <span>
                      By City
                    </span>
                    <span className="down-arrow-icon down">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z"
                          fill="#455A64"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={`prp-filter-box ${isFilterDdl == 4 ? "active" : ""
                      }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="header-top">
                      <div
                        className={`prp-search-box ${filterCitySearchInput.length > 0 ? "active" : ""
                          }`}
                      >
                        <label>
                          <svg
                            className="srch-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.45341 0C2.89291 0 0 2.88889 0 6.44445C0 10 2.89291 12.8889 6.45341 12.8889C7.98887 12.8889 9.41307 12.3556 10.5035 11.4444L14.8651 15.8C15.1321 16.0667 15.5549 16.0667 15.7997 15.8C16.0668 15.5333 16.0668 15.1333 15.7997 14.8667L11.4381 10.5111C12.3505 9.4 12.8846 7.97778 12.8846 6.44445C12.9068 2.88889 10.0139 0 6.45341 0ZM6.45341 1.33333C9.27955 1.33333 11.5716 3.62222 11.5716 6.44445C11.5716 9.26667 9.27955 11.5556 6.45341 11.5556C3.62726 11.5556 1.33519 9.26667 1.33519 6.44445C1.33519 3.6 3.62726 1.33333 6.45341 1.33333Z"
                              fill="#A6A7AD"
                            />
                          </svg>

                          <input
                            type="text"
                            placeholder="Start typing to search"
                            value={filterCitySearchInput}
                            onChange={(e) => handleCityFilterSearch(e)}
                          />
                          <button
                            className="clear-all-btn"
                            onClick={clearCitySerachFilter}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy={8} r={8} fill="#7D868C" />
                              <path
                                d="M11.7172 5.08856C11.8734 4.93235 11.8734 4.67908 11.7172 4.52287L11.4771 4.28284C11.3209 4.12663 11.0677 4.12663 10.9114 4.28284L8.28284 6.91144C8.12663 7.06765 7.87337 7.06765 7.71716 6.91144L5.08856 4.28284C4.93235 4.12663 4.67908 4.12663 4.52287 4.28284L4.28284 4.52287C4.12663 4.67908 4.12663 4.93235 4.28284 5.08856L6.91144 7.71716C7.06765 7.87337 7.06765 8.12663 6.91144 8.28284L4.28284 10.9114C4.12663 11.0677 4.12663 11.3209 4.28284 11.4771L4.52287 11.7172C4.67908 11.8734 4.93235 11.8734 5.08856 11.7172L7.71716 9.08856C7.87337 8.93235 8.12663 8.93235 8.28284 9.08856L10.9114 11.7172C11.0677 11.8734 11.3209 11.8734 11.4771 11.7172L11.7172 11.4771C11.8734 11.3209 11.8734 11.0677 11.7172 10.9114L9.08856 8.28284C8.93235 8.12663 8.93235 7.87337 9.08856 7.71716L11.7172 5.08856Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="body-list">
                      {filterCitySearchInput.length < 1 ? (
                        <>
                          {/* selected plant list map */}
                          {cityArr.map((item, i) => (
                            <div key={i}>
                              {selectedCity.length === cityArr.length &&
                                i === 0 &&
                                filterCitySearchInput.length < 1 && (
                                  <label className="chk-label">
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onClick={() => handleCityInput("all")}
                                    />
                                    <span className="svg-icon">
                                      <img
                                        src={
                                          require("../../assets/img/prepare-home/chk-white.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <span className="plant-chk-txt 1">
                                      All City
                                    </span>
                                  </label>
                                )}
                              {selectedCity.indexOf(item.id) > -1 &&
                                selectedCity.length > 0 &&
                                selectedCity.length < cityArr.length &&
                                i === 0 && (
                                  <span className="plant-bold-txt">
                                    SELECTED CITY
                                  </span>
                                )}
                              {selectedCity.indexOf(item.id) > -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    value={item}
                                    onClick={() => handleCityInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 2">
                                    {item.name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* selected plant list map end*/}
                          {/* all plants list map and unselected list map*/}
                          {cityArr.map((item, i) => (
                            <div key={i}>
                              {selectedCity.length == 0 && i === 0 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleCityInput("all")}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 3">
                                    All City
                                  </span>
                                </label>
                              )}

                              {selectedCity.length > 0 &&
                                selectedCity.length < cityArr.length &&
                                i == 0 && (
                                  <span className="plant-bold-txt">
                                    OTHER CITY
                                  </span>
                                )}

                              {selectedCity.indexOf(item.id) === -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    id={`cityPlantInput${i}`}
                                    onChange={() => handleCityInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 4">
                                    {item.name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* all plants list map and unselected list map end*/}
                        </>
                      ) : (
                        <>
                          {cityFilterPlant.map((item, i) => (
                            <label className="chk-label">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  selectedCity.indexOf(item.id) > -1
                                }
                                value={item.id}
                                onChange={() => handleCityInput(item.id)}
                              />
                              <span className="svg-icon">
                                <img
                                  src={
                                    require("../../assets/img/prepare-home/chk-white.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="plant-chk-txt 5">
                                {item.name}
                              </span>
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                    {/* body-list end */}
                    <div className="footer-bottom">
                      <button className="btn-ft clear" onClick={clearAllCityFilter}>
                        Clear all
                      </button>
                      <button className="btn-ft apply" onClick={applyCityFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* prp-filter-box end */}
                </div>
              )}
              {/* City filter end */}
              {(homeDataItem.total_plant >= 1) && (
                <div className="prp-filter-wrapper">
                  <button
                    className={`prp-fltr-btn ${isFilterDdl == 5
                      ? "active"
                      : selectedCountry.length > 0
                        ? "default"
                        : ""
                      }`}
                    onClick={() => handleFilterDdl(5)}
                  >
                    <span>
                      By Country
                    </span>
                    <span className="down-arrow-icon down">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z"
                          fill="#455A64"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={`prp-filter-box ${isFilterDdl == 5 ? "active" : ""
                      }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="header-top">
                      <div
                        className={`prp-search-box ${filterCountrySearchInput.length > 0 ? "active" : ""
                          }`}
                      >
                        <label>
                          <svg
                            className="srch-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.45341 0C2.89291 0 0 2.88889 0 6.44445C0 10 2.89291 12.8889 6.45341 12.8889C7.98887 12.8889 9.41307 12.3556 10.5035 11.4444L14.8651 15.8C15.1321 16.0667 15.5549 16.0667 15.7997 15.8C16.0668 15.5333 16.0668 15.1333 15.7997 14.8667L11.4381 10.5111C12.3505 9.4 12.8846 7.97778 12.8846 6.44445C12.9068 2.88889 10.0139 0 6.45341 0ZM6.45341 1.33333C9.27955 1.33333 11.5716 3.62222 11.5716 6.44445C11.5716 9.26667 9.27955 11.5556 6.45341 11.5556C3.62726 11.5556 1.33519 9.26667 1.33519 6.44445C1.33519 3.6 3.62726 1.33333 6.45341 1.33333Z"
                              fill="#A6A7AD"
                            />
                          </svg>

                          <input
                            type="text"
                            placeholder="Start typing to search"
                            value={filterCountrySearchInput}
                            onChange={(e) => handleCountryFilterSearch(e)}
                          />
                          <button
                            className="clear-all-btn"
                            onClick={clearCountrySerachFilter}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy={8} r={8} fill="#7D868C" />
                              <path
                                d="M11.7172 5.08856C11.8734 4.93235 11.8734 4.67908 11.7172 4.52287L11.4771 4.28284C11.3209 4.12663 11.0677 4.12663 10.9114 4.28284L8.28284 6.91144C8.12663 7.06765 7.87337 7.06765 7.71716 6.91144L5.08856 4.28284C4.93235 4.12663 4.67908 4.12663 4.52287 4.28284L4.28284 4.52287C4.12663 4.67908 4.12663 4.93235 4.28284 5.08856L6.91144 7.71716C7.06765 7.87337 7.06765 8.12663 6.91144 8.28284L4.28284 10.9114C4.12663 11.0677 4.12663 11.3209 4.28284 11.4771L4.52287 11.7172C4.67908 11.8734 4.93235 11.8734 5.08856 11.7172L7.71716 9.08856C7.87337 8.93235 8.12663 8.93235 8.28284 9.08856L10.9114 11.7172C11.0677 11.8734 11.3209 11.8734 11.4771 11.7172L11.7172 11.4771C11.8734 11.3209 11.8734 11.0677 11.7172 10.9114L9.08856 8.28284C8.93235 8.12663 8.93235 7.87337 9.08856 7.71716L11.7172 5.08856Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="body-list">
                      {filterCountrySearchInput.length < 1 ? (
                        <>
                          {/* selected plant list map */}
                          {countryArr.map((item, i) => (
                            <div key={i}>
                              {selectedCountry.length === countryArr.length &&
                                i === 0 &&
                                filterCountrySearchInput.length < 1 && (
                                  <label className="chk-label">
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onClick={() => handleCountryInput("all")}
                                    />
                                    <span className="svg-icon">
                                      <img
                                        src={
                                          require("../../assets/img/prepare-home/chk-white.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <span className="plant-chk-txt 1">
                                      All Country
                                    </span>
                                  </label>
                                )}
                              {selectedCountry.indexOf(item.id) > -1 &&
                                selectedCountry.length > 0 &&
                                selectedCountry.length < countryArr.length &&
                                i === 0 && (
                                  <span className="plant-bold-txt">
                                    SELECTED COUNTRY
                                  </span>
                                )}
                              {selectedCountry.indexOf(item.id) > -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    value={item}
                                    onClick={() => handleCountryInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 2">
                                    {item.name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* selected plant list map end*/}
                          {/* all plants list map and unselected list map*/}
                          {countryArr.map((item, i) => (
                            <div key={i}>
                              {selectedCountry.length == 0 && i === 0 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleCountryInput("all")}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 3">
                                    All Country
                                  </span>
                                </label>
                              )}

                              {selectedCountry.length > 0 &&
                                selectedCountry.length < countryArr.length &&
                                i == 0 && (
                                  <span className="plant-bold-txt">
                                    OTHER COUNTRY
                                  </span>
                                )}

                              {selectedCountry.indexOf(item.id) === -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    id={`cityPlantInput${i}`}
                                    onChange={() => handleCountryInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 4">
                                    {item.name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* all plants list map and unselected list map end*/}
                        </>
                      ) : (
                        <>
                          {countryFilterPlant.map((item, i) => (
                            <label className="chk-label">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  selectedCountry.indexOf(item.id) > -1
                                }
                                value={item.id}
                                onChange={() => handleCountryInput(item.id)}
                              />
                              <span className="svg-icon">
                                <img
                                  src={
                                    require("../../assets/img/prepare-home/chk-white.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="plant-chk-txt 5">
                                {item.name}
                              </span>
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                    {/* body-list end */}
                    <div className="footer-bottom">
                      <button className="btn-ft clear" onClick={clearAllCountryFilter}>
                        Clear all
                      </button>
                      <button className="btn-ft apply" onClick={applyCountryFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* prp-filter-box end */}
                </div>
              )}
              {/* Country filter end */}
              {(homeDataItem.total_plant >= 1) && (
                <div className="prp-filter-wrapper">
                  <button
                    className={`prp-fltr-btn ${isFilterDdl == 6
                      ? "active"
                      : selectedEmail.length > 0
                        ? "default"
                        : ""
                      }`}
                    onClick={() => handleFilterDdl(6)}
                  >
                    <span>
                      By Email
                    </span>
                    <span className="down-arrow-icon down">
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z"
                          fill="#455A64"
                        />
                      </svg>
                    </span>
                  </button>
                  <div
                    className={`prp-filter-box ${isFilterDdl == 6 ? "active" : ""
                      }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="header-top">
                      <div
                        className={`prp-search-box ${filterEmailSearchInput.length > 0 ? "active" : ""
                          }`}
                      >
                        <label>
                          <svg
                            className="srch-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.45341 0C2.89291 0 0 2.88889 0 6.44445C0 10 2.89291 12.8889 6.45341 12.8889C7.98887 12.8889 9.41307 12.3556 10.5035 11.4444L14.8651 15.8C15.1321 16.0667 15.5549 16.0667 15.7997 15.8C16.0668 15.5333 16.0668 15.1333 15.7997 14.8667L11.4381 10.5111C12.3505 9.4 12.8846 7.97778 12.8846 6.44445C12.9068 2.88889 10.0139 0 6.45341 0ZM6.45341 1.33333C9.27955 1.33333 11.5716 3.62222 11.5716 6.44445C11.5716 9.26667 9.27955 11.5556 6.45341 11.5556C3.62726 11.5556 1.33519 9.26667 1.33519 6.44445C1.33519 3.6 3.62726 1.33333 6.45341 1.33333Z"
                              fill="#A6A7AD"
                            />
                          </svg>

                          <input
                            type="text"
                            placeholder="Start typing to search"
                            value={filterEmailSearchInput}
                            onChange={(e) => handleEmailFilterSearch(e)}
                          />
                          <button
                            className="clear-all-btn"
                            onClick={clearEmailSerachFilter}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy={8} r={8} fill="#7D868C" />
                              <path
                                d="M11.7172 5.08856C11.8734 4.93235 11.8734 4.67908 11.7172 4.52287L11.4771 4.28284C11.3209 4.12663 11.0677 4.12663 10.9114 4.28284L8.28284 6.91144C8.12663 7.06765 7.87337 7.06765 7.71716 6.91144L5.08856 4.28284C4.93235 4.12663 4.67908 4.12663 4.52287 4.28284L4.28284 4.52287C4.12663 4.67908 4.12663 4.93235 4.28284 5.08856L6.91144 7.71716C7.06765 7.87337 7.06765 8.12663 6.91144 8.28284L4.28284 10.9114C4.12663 11.0677 4.12663 11.3209 4.28284 11.4771L4.52287 11.7172C4.67908 11.8734 4.93235 11.8734 5.08856 11.7172L7.71716 9.08856C7.87337 8.93235 8.12663 8.93235 8.28284 9.08856L10.9114 11.7172C11.0677 11.8734 11.3209 11.8734 11.4771 11.7172L11.7172 11.4771C11.8734 11.3209 11.8734 11.0677 11.7172 10.9114L9.08856 8.28284C8.93235 8.12663 8.93235 7.87337 9.08856 7.71716L11.7172 5.08856Z"
                                fill="white"
                              />
                            </svg>
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="body-list">
                      {filterEmailSearchInput.length < 1 ? (
                        <>
                          {/* selected plant list map */}
                          {emailArr.map((item, i) => (
                            <div key={i}>
                              {selectedEmail.length === emailArr.length &&
                                i === 0 &&
                                filterEmailSearchInput.length < 1 && (
                                  <label className="chk-label">
                                    <input
                                      type="checkbox"
                                      checked={true}
                                      onClick={() => handleEmailInput("all")}
                                    />
                                    <span className="svg-icon">
                                      <img
                                        src={
                                          require("../../assets/img/prepare-home/chk-white.svg")
                                            .default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <span className="plant-chk-txt 1">
                                      All Email
                                    </span>
                                  </label>
                                )}
                              {selectedEmail.indexOf(item.id) > -1 &&
                                selectedEmail.length > 0 &&
                                selectedEmail.length < emailArr.length &&
                                i === 0 && (
                                  <span className="plant-bold-txt">
                                    SELECTED EMAIL
                                  </span>
                                )}
                              {selectedEmail.indexOf(item.id) > -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    checked={true}
                                    value={item}
                                    onClick={() => handleEmailInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 2">
                                    {item.name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* selected plant list map end*/}
                          {/* all plants list map and unselected list map*/}
                          {emailArr.map((item, i) => (
                            <div key={i}>
                              {selectedEmail.length == 0 && i === 0 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    onChange={() => handleEmailInput("all")}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 3">
                                   All Email
                                  </span>
                                </label>
                              )}

                              {selectedEmail.length > 0 &&
                                selectedEmail.length < emailArr.length &&
                                i == 0 && (
                                  <span className="plant-bold-txt">
                                    OTHER EMAIL
                                  </span>
                                )}

                              {selectedEmail.indexOf(item.id) === -1 && (
                                <label className="chk-label">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    id={`cityPlantInput${i}`}
                                    onChange={() => handleEmailInput(item.id)}
                                  />
                                  <span className="svg-icon">
                                    <img
                                      src={
                                        require("../../assets/img/prepare-home/chk-white.svg")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <span className="plant-chk-txt 4">
                                    {item.name}
                                  </span>
                                </label>
                              )}
                            </div>
                          ))}
                          {/* all plants list map and unselected list map end*/}
                        </>
                      ) : (
                        <>
                          {emailFilterPlant.map((item, i) => (
                            <label className="chk-label">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  selectedEmail.indexOf(item.id) > -1
                                }
                                value={item.id}
                                onChange={() => handleEmailInput(item.id)}
                              />
                              <span className="svg-icon">
                                <img
                                  src={
                                    require("../../assets/img/prepare-home/chk-white.svg")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span className="plant-chk-txt 5">
                                {item.name}
                              </span>
                            </label>
                          ))}
                        </>
                      )}
                    </div>
                    {/* body-list end */}
                    <div className="footer-bottom">
                      <button className="btn-ft clear" onClick={clearAllEmailFilter}>
                        Clear all
                      </button>
                      <button className="btn-ft apply" onClick={applyEmailFilter}>
                        Apply
                      </button>
                    </div>
                  </div>
                  {/* prp-filter-box end */}
                </div>
              )}
              {/* Email filter end */}
              <div className="prp-filter-wrapper rest-filter-btn-wrapper">
                <button
                  className={`prp-fltr-btn rest-filter-btn`}
                  onClick={() => handleResetFilter()}
                >
                  <span>
                    Reset Filter
                  </span>
                </button>

              </div>
            </div>
          </h1>
          {/* First Subcategory will be Plants wrapper */}
          <div className="plants-wrapper">
            {homeDataItem.total_plant > 0 && (
              <div className="prp-plant-div">
                {filterAllPlants?.slice(0, 10 * currentPlantPagination)?.map((plant, repIndex) => (
                  <>
                    {/* Plase add class {className="prepare-row highlight-new-plant"} to highlight new plant CDP-444 */}
                    <div className="prepare-row">
                      {/* I have added one class named 'plant-name-row' */}
                      <div className="prepare-full-col plant-name-row">
                        <div className="left-plant-wrap">
                          <div className="plant-wrap">
                            <div className="plant-name-div">
                              <span className="plant-img">
                                <img
                                  src={`${apiEndpoints.baseUrl}/assets/img/country/${plant.slug}.svg`}
                                  alt=""
                                />
                              </span>
                              <span className="plant-txt">
                                <span>{plant.companyRegistration}-{plant.manufacturerName}-{plant.plant_name} </span>
                                {groupid == 2 && (
                                  <i
                                    className="renameIcon"
                                    dangerouslySetInnerHTML={{ __html: plantEditIcon }}
                                    data-title="Rename"
                                    onClick={() => handleShowEditModal(plant.id, plant.plant_name)}
                                  ></i>
                                )}
                                {groupid == 2 && (
                                  <i
                                    className="delIcon"
                                    dangerouslySetInnerHTML={{ __html: plantDelIcon }}
                                    data-title="Delete"
                                    onClick={() => handleShowDeleteModal(plant.id)}
                                  ></i>
                                )}

                              </span>
                            </div>
                            {plant?.assessor && plant?.assessor_has && (uid == '692' || uid == '597' || uid == '771') ?
                              <div className="plant-assign-cir-wrapper">
                                <button className={`plant-assign-cir ${plant?.assessor?.avcolor}`} onClick={() => setActivePlantDdl(plant.id)}>
                                  {plant?.assessor?.sname}
                                  {/* <img src=""/> */}
                                </button>

                                <div className={`plant-assign-cir-ddl ${activePlantDdl === plant.id ? 'active' : ''}`}>
                                  <div className="plant-assign-inner-cir-wrapper">
                                    <div>
                                      <span className={`plant-inner-cir ${plant?.assessor?.avcolor}`}>{plant?.assessor?.sname}</span>
                                    </div>
                                    <div className="plant-inner-cir-name">
                                      <p className="inner-cir-name text-capitalize">{plant?.assessor?.name}</p>
                                      <p className="inner-cir-role">Assessor</p>
                                    </div>
                                  </div>
                                  <button onClick={(e) => { handleAssignPoup(e, plant); handleEditTitle(e) }}>
                                    <i dangerouslySetInnerHTML={{ __html: editUserIcon }}></i>
                                    <span>Edit User</span>
                                  </button>
                                </div>
                              </div>
                              : (uid == '692' || uid == '597' || uid == '771') ?
                                <button className="assign-wrap" onClick={(e) => { handleAssignPoup(e, plant); handleAssignTitle() }}>
                                  <i dangerouslySetInnerHTML={{ __html: assignSvg }}></i>
                                  <span>Assign</span>
                                </button>
                                :
                                ''
                            }

                          </div>
                          <Link to={`/company-detail/${plant.plant_slug}`} className="view-company-link" >View Company Details</Link>
                        </div>

                        {/* Collapse button CDP-444 */}
                        <button className={`collapse-btn ${collapseArray.includes(plant.id) ? 'active' : ''}`} onClick={() => handleCollapse(plant.id)}>
                          <i className='angle-icon' dangerouslySetInnerHTML={{ __html: collapseArrow }}></i>
                        </button>
                      </div>
                      {/* Collapse content CDP-444 */}
                      <Collapse in={collapseArray.includes(plant.id)}>
                        <div className="prepare-full-col">
                          <div className="text-time-wrap">
                            Signed up on: {plant.signup_date} GMT
                          </div>
                          <div className="prp-inner-card-row">

                            {/* Siri card Col start */}
                            <div className={`prepare-col plants`}>
                              <PlantCard
                                cardType="PREPARE"
                                title="Assessment"
                                status={plant.is_siricompleted}
                                type={'siri'}
                                colorClass="blue-bg"
                                isRoadmapDiveTxt={"Assessment"}
                                gourl={plant.is_completed != 1
                                  ? `/siri-assessment/${plant.plant_slug}`
                                  : `/siri-assessment/${plant.plant_slug}`
                                }
                                plantId={plant.id}
                                upgradeSection='strategy'
                                plantName={plant.plant_name}
                                comboIdget={plant.combo_id_get}
                                uploadReport={true}
                                isUploadReportEdit={plant.siri_files}
                                handleUploadReportPopup={(e) => handleUploadReportPopup(e, plant)}
                              />
                            </div>
                            {/* Siri card Col end */}

                            {(plant.is_siricompleted == 'validated' || plant.is_siricompleted == 'review-ready') ?
                              <div className={`prepare-col plants`}>
                                <PlantCard
                                  cardType="DEFINE"
                                  title="IR4.0 Strategy"
                                  status={plant.is_roadmapcompleted == 1 ? 100 : plant.is_roadmapcompleted == 2 ? 55 : 0}
                                  colorClass="orange-bg"
                                  gourl={plant.is_roadmapcompleted == 1 ? `roadmap-result/${plant.plant_slug}/${plant.sid}` : `/roadmap/step1/${plant.plant_slug}/${plant.sid}`}
                                  callibratorButton={plant.is_roadmapcompleted == 1 ? true : false}
                                  callibratorLink={`/roadmap/step1/${plant.plant_slug}/${plant.sid}`}
                                  downloadPdfBtn={plant.is_roadmapcompleted == 1 ? true : false}
                                  blockDownloadBtn={plant.dsmapping != 1}
                                  downloadPdfUrl={`${homeDataItem.base_url}certificate/downloadResults/${plant.plant_slug}/${plant?.sid}/${plant.id}`}
                                  isFilterButton={true}
                                  isFilterFunction={() => { handleSiriFilter(plant.id, plant.sid, plant.plant_name, plant.filter_d) }}
                                  isSiriDimestionFilter={filterSiriPlant?.includes(plant.id) || plant?.filter_d?.length > 0}
                                  downloadPdfClickFunction={() => setIsDownloadPopup(prv => `/dimension-and-solutions-mapping/${plant.plant_slug}`)}
                                />
                              </div>
                              : ''
                            }

                            <div className={`prepare-col plants`}>
                              <PlantCard
                                cardType="DEFINE"
                                title="Dimension & Solutions Mapping"
                                isRoadmapDiveTxt="Dimensions and Band setup"
                                type='dimensionsBand'
                                status={plant.dsmapping == 1 ? 100 : plant.dsmapping == 2 ? 55 : 0}
                                colorClass="parple-bg"
                                gourl={`/dimension-and-solutions-mapping/${plant.plant_slug}`}
                              />
                            </div>

                            <div className="border-bottom-1"></div>
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </>
                ))}
                {/* prepare-row 1 end */}
              </div>
            )}
            {/* No Plant Div */}
            {(filterAllPlants.length == 0 || homeDataItem.total_plant == 0) && (
              <div className="no-plant">
                <div className="no-plant-img" dangerouslySetInnerHTML={{ __html: noPlantSvg }}></div>
                <h2>No plant found</h2>
              </div>
            )}
          </div>
          {/* Second Subcategory will be Distribution Center wrapper */}
          {isLoading &&
            <div className="loading-text">
              <img src={require(`../../assets/img/prepare-home/loading.gif`).default} />
              <span>Loading..</span>
            </div>
          }
        </div>

        {activeSlide && isHomeTour == 0 && (
          <HomePageWalkthroughView
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            closeFunction={HandleHomeTourWalhthrough}
            {...props}
          />
        )}
        {showPlatformTrainingBtn && isPlatformTrainingBtn == 0 && (
          <button className="platform-button" onClick={RedirectToResourcePage}>
            <span className="platform-text">Platform Trainings</span>
            <i
              className="arrow-icon"
              dangerouslySetInnerHTML={{ __html: arrowSvg }}
            ></i>
          </button>
        )}
      </div>
      {/* business case popup component */}
      <BussinessCasePopup isPopup={isPopup} setIsPopup={setIsPopup} />
      {/* MatCasePopup component */}
      <MatCasePopup ismPopup={ismPopup} setIsmPopup={setIsmPopup} />
      {/* Loss case popup component */}
      <LossCasePopup islPopup={islPopup} setIslPopup={setIslPopup} />

      {/* delete Plant popup */}
      <div className={`delete-plant-popup ${deletePopupId && "active"}`}>
        <div className="delete-popup-card">
          <h2>Are you sure, you would like delete this Plant?</h2>
          <p>
            All the progress made will be lost permanently and can not be
            retrieved.
          </p>
          <div className="delete-popup-button-wrapper">
            <button
              className="btn-cancel"
              onClick={() => handleShowDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn-yes-del" onClick={deletePlantFunction}>
              Yes, delete
            </button>
          </div>
        </div>
      </div>
      {/* edit popup*/}
      <div className={`edit-plant-popup ${editPopupId && "active"}`}>
        <div className="edit-plant-card">
          <div className="edit-plant-popup-header">
            <h2>Rename Plant</h2>
            <button className="close_btn" onClick={() => { closeEditPopup() }}>
              <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
            </button>
          </div>
          {/* header end */}

          <div className="edit-plant-popup-body">
            <label className="edit-plant-input-wrapper">
              <span className="input-label">Plant Name</span>
              <input value={editPopupInput} placeholder="Plant Name"
                onChange={(e) => setEditPopupInput(e.target.value)}
              />
            </label>
            <p className={`plant-name-error ${(!validateName(editPopupInput) && editPopupInput.length > 0) && 'active'}`}>
              The plant name does not allow multiple consecutive spaces and can not start with a space.
            </p>
          </div>
          {/* body end */}
          <div className="edit-plant-popup-footer">
            <button className={`btn_save ${validateName(editPopupInput) && 'active'}`} onClick={renamePlantNameFunction}>
              Save Changes
            </button>
          </div>
          {/*  footer end */}
        </div>
        {/*  card end */}
      </div>
      {/* edit popup edit*/}
      {/* Notification Popup */}
      {/* Popup when assessment and loss data are not submitted */}
      <StartegyPopup isStrPopup={isStrComPopup} setIsStrPopup={setIsStrComPopup} title={`Please submit your IR4.0 Technology Assessment & IR4.0 Loss Analysis to access IR 4.0 Strategy tool`} />
      {/* Popup when loss data is not submitted */}
      <StartegyPopup isStrPopup={isStrLossPopup} setIsStrPopup={setIsStrLossPopup} title={`Please submit your IR4.0 Loss Analysis to access IR 4.0 Strategy tool`} />
      {/* Popup when assessment is not submitted */}
      <StartegyPopup isStrPopup={isStrAsmtPopup} setIsStrPopup={setIsStrAsmtPopup} title={`Please submit your IR4.0 Technology Assessment input to access IR 4.0 Strategy tool`} />

      <StartegyPopup isStrPopup={isStrSiriPopup} setIsStrPopup={setIsStrSiriPopup} title={`Once manufacturer submit the assessment successfully, then validation will be available.`} heading={`Validation is not available`} />

      <StartegyPopup isStrPopup={isDownloadPopup} setIsStrPopup={setIsDownloadPopup} title={`Please complete the dimensions and solutions mapping. You can directly access from <a href="${isDownloadPopup}"><b>here</b></a>`} heading={'na'} />
      {/* Upgrade Popup */}

      {showComponent && <AddPlantPopViewCtrl showAddPlantPopup={showComponent} setShowAddPlantPopup={setShowComponent} />}
      {/* Home Upgrade Add Plant Popup */}
      {addPlantMsgPopup && <UpgradeAddPopup openUpgradePopup={addPlantMsgPopup} setOpenUpgradePopup={setAddPlantMsgPopup} />}
      {/* Assign user popup component call */}
      <AssessorAssignPopup
        assignedUsers={assignedUsers}
        alldata={homeDataItem}
        plant={plantsend}
        setAssignedUsers={setAssignedUsers}
        catagory={`cate`}
        catagoryName={`catename`}
        plant_id={`plantid`}
        plant_name={`sectionDataItem.plant_name`}
        setUpDataPage={props.setsetuppage}
        setuppage={props.setuppage}
        tmpAssignUser={tmpAssignUser}
        popen={isAssignSectionPopup}
        setPopen={setIsAssignSectionPopup}
        popupTitle={popupTitle}
        setPopupTitle={setPopupTitle}
        {...props}
      />
      {/* SiriDimension Filter Popup */}
      <SiriDimensionFilterPopup data-component="SiriDimensionFilterPopup" open={isActive ? "open" : ""}>
        <FilterWrapper data-component="FilterWrapper">
          <FilterDimensionBox data-component="FilterDimensionBox" show={isActive}>
            <FilterBoxHeader data-component="FilterBoxHeader">
              <HeaderTitle data-component="HeaderTitle">
                <div>
                  {isActive?.name}
                </div>
              </HeaderTitle>

              <FilterCloseButton
                data-component="HeaderTitle"
                onClick={closeDimensionFilter}
              >
                <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
              </FilterCloseButton>
            </FilterBoxHeader>
            <FilterBoxContent data-component="FilterBoxContent">
              <div class="select-dimension">
                <HeaderTitle data-component="HeaderTitle">
                  <small>Select Dimensions:</small>
                </HeaderTitle>
              </div>
              <FilterBoxContentWrap data-component="FilterBoxContentWrap">
                <FilterSiriTabContentWrap data-component="FilterSiriTabContentWrap">
                  <div className="dimension-wrapper">
                    <div className="dimension-card">
                      <div className="top-wrap">
                        <label>
                          <input className="filter-checkbox" type="checkbox" checked={stepdata?.siriDimension?.length === dimensionCheckedArray?.length} onChange={(e) => handleDimensionCheckBox(e, 'all')} />
                          <i dangerouslySetInnerHTML={{ __html: filterCheckboxSvg }}></i>
                        </label>
                        <div className="content-wrap">
                          <p className="band-title">All Dimensions</p>
                        </div>
                      </div>
                    </div>
                    {
                      stepdata?.siriDimension?.map((item, index) => (
                        <div className="dimension-card" key={index}>
                          <div className="top-wrap">
                            <label>
                              <input className="filter-checkbox" type="checkbox" checked={dimensionCheckedArray?.includes(item.id)} onChange={(e) => handleDimensionCheckBox(e, item.id)} />
                              <i dangerouslySetInnerHTML={{ __html: filterCheckboxSvg }}></i>
                            </label>
                            <span className="count">{index + 1}</span>
                            <div className="content-wrap">
                              <p className="band-title">{item.dimension_name}</p>
                              <span className="current-title">
                                Current Status: {item?.selected_band?.name}
                              </span>
                            </div>
                            <i className={`dimension-btn ${dimensionFilterCollapse?.includes(item.id) ? `active` : ''}`} dangerouslySetInnerHTML={{ __html: siriArrowSvg }} onClick={() => handleDimensionFilterCollapse(item.id)}></i>
                          </div>
                          <Collapse in={dimensionFilterCollapse?.includes(item.id)}>
                            <div className="bottom-wrap">
                              {getNextBandsAccordingToSelectedBands(item?.selected_band?.id, bands)?.map((band, band_index) => (
                                <FilterBoxLable data-component="FilterBoxLable" key={band_index}>
                                  <input
                                    value={band.id}
                                    name={`dim-band${item.id}`}
                                    type="radio"
                                    checked={siriInputsData[item.id] == band.id}
                                    onChange={() => handleFilterOnchangeData(item.id, band.id)}
                                  />
                                  <span className="icon">
                                    <i dangerouslySetInnerHTML={{ __html: radioBtnSvg }}></i>
                                  </span>
                                  <span className="filter-name">{band.name}</span>
                                </FilterBoxLable>
                              ))
                              }
                            </div>
                          </Collapse>
                        </div>
                      ))
                    }
                  </div>
                </FilterSiriTabContentWrap>
              </FilterBoxContentWrap>
            </FilterBoxContent>
            <FilterButtonWrapper data-component="FilterButtonWrapper">
              <ApplyFilterButton onClick={handleDimensionCheckBoxApplyFilter} data-component="ApplyFilterButton">
                Apply Filters
              </ApplyFilterButton>
            </FilterButtonWrapper>
          </FilterDimensionBox>
        </FilterWrapper>
      </SiriDimensionFilterPopup>
      {isUploadReportPopup && <UploadFilePopView base_url={homeDataItem.base_url} plant={isUploadReportPopup} handleClosePopup={handleCloseUploadReportPopup} {...props} />}
    </>

  );
};

const TraingingCard = (props) => {
  const { title, headerImg, url, status, colorClass, otherFunction } = props;
  const callOtherFunction = () => {
    if (otherFunction) {
      otherFunction();
    }
  };
  return (
    <>
      <ConditionalTag url={url} otherFunction={callOtherFunction}>
        <div className={`prp-img-div ${colorClass}`}>
          <img src={headerImg} className="icon-img" alt="Img" />
          {status == 100 ? (
            <span className="status completed">COMPLETED</span>
          ) : status > 0 && status < 100 ? (
            <span className="status inprogress">IN PROGRESS</span>
          ) : status == 0 ? (
            <span className="status todo">To do</span>
          ) : (
            ""
          )}
        </div>
        <div className="prp-text-div">
          {title}
          {/* max-character 49*/}
        </div>
      </ConditionalTag>
    </>
  );
};
//plant card component
const PlantCard = (props) => {
  const {
    title,
    url,
    directurl,
    status,
    customStatus,
    colorClass,
    cardType,
    type,
    progress,
    lossReduction,
    isRoadmapDiveTxt,
    isRoadmapResult,
    savings,
    otherFunction,
    callibratorButton,
    uploadReport,
    isUploadReportEdit,
    handleUploadReportPopup = () => { },
    getAcessButton,
    downloadPdfBtn,
    downloadPdfUrl,
    blockDownloadBtn,
    downloadPdfClickFunction,
    isFilterButton,
    isFilterFunction = () => { },
    isSiriDimestionFilter,
    callibratorLink,
    getaccessLink,
    plantId,
    upgradeSection,
    plantName,
    comboIdget
  } = props;
  const callOtherFunction = () => {
    if (otherFunction) {
      otherFunction();
    }
  };
  let gid = localStorage.getItem("group_id");
  return (
    <>
      <ConditionalTag
        url={props.gourl}
        directurl={directurl}
        otherFunction={callOtherFunction}
      >
        <div className={`prp-img-div ${colorClass}`}>
          <div className="prp-card-img">
            <p>{cardType}</p>
            <img
              src={
                require(`../../assets/img/prepare-home/${type == "siri"
                  ? `siri-assessment.svg`
                  : type == "dma"
                    ? `assessment-icon.svg`
                    : type == "loss"
                      ? "loss-icon.svg"
                      : type == "execute"
                        ? "prepare-execute.svg"
                        : type == "techAdop"
                          ? "tech-adoption-icon.svg"
                          : type == "dimensionsBand"
                            ? "dimension_band.svg"
                            : "business-icon.svg"
                  }`).default
              }
              alt="Img"
            />
          </div>
          <div className="prp-card-porogress-div">
            {/* if roadmap deep dive text  in props*/}
            {isRoadmapDiveTxt && (
              <span className="txt-normal">{isRoadmapDiveTxt}</span>
            )}

            {/* if progress is 0 or greater than 0 in props  */}
            {progress > -1 && progress !== "undefined" ? (
              <span className="txt-normal">Progress</span>
            ) : (
              ""
            )}
            {progress > -1 && <span className="progress-num">{progress}%</span>}

            {/* loss reduction */}
            {/* {lossReduction > -1 && lossReduction !== "undefined" ? (
              <span className="txt-normal">Loss Reduction</span>
            ) : (
              ""
            )}
            {lossReduction > -1 && (
              <span className="progress-num">{lossReduction}%</span>
            )} */}
            {/* loss reduction end */}

            {isRoadmapResult && (
              <>
                <span className="progress-num result">{savings}</span>
                <span className="txt-normal">{isRoadmapResult}</span>
              </>
            )}
          </div>

          <div className="status-wrap">
            {
              isFilterButton ?
                <button className={`siri-filter-btn ${isSiriDimestionFilter ? 'active' : ''}`} onClick={(e) => { e.stopPropagation(); e.preventDefault(); isFilterFunction(); }}>
                  <i dangerouslySetInnerHTML={{ __html: filterBtnSvg }}></i>
                  <span className="download-tooltip">Filter Dimensions</span>
                </button>
                :
                ''
            }
            {status == 'upgrade' ?
              (<span className="status upgrade">UPGRADE</span>)
              :
              status == 100 ? (
                <>
                  <span className="status completed">COMPLETED</span>

                  {blockDownloadBtn ?
                    <a href='#' onClick={(e) => { e.stopPropagation(); e.preventDefault(); downloadPdfClickFunction(); }}>
                      <i dangerouslySetInnerHTML={{ __html: downloadHoverBtn }}></i>
                      <span className="download-tooltip">Download Results</span>
                    </a>
                    : downloadPdfBtn ?

                      <a href={downloadPdfUrl} download onClick={(e) => { e.stopPropagation(); }}>
                        <i dangerouslySetInnerHTML={{ __html: downloadHoverBtn }}></i>
                        <span className="download-tooltip">Download Results</span>
                      </a>
                      :
                      ''
                  }
                </>

              ) : status > 0 && status < 100 ? (
                <span className="status inprogress">IN PROGRESS</span>
              ) : status == 0 ? (
                <span className="status todo">To do</span>
              ) : status == "not-started" ? (
                <span className="status not-started">NOT STARTED</span>
              ) : status == "results-ready" ? (
                <span className="status results-ready">RESULTS READY</span>
              ) : status == "review-ready" ? (
                <span className="status results-ready">REVIEW READY</span>
              ) : status == "validated" ? (
                <span className="status results-ready">Validated</span>
              ) : (
                ""
              )}
          </div>
          {/* custom status */}
          {customStatus ? (
            <span className="status completed">{customStatus}</span>
          ) : (
            ""
          )}
        </div>
        <div className="prp-text-div">
          {title}
          {callibratorButton && gid != 14 && (
            <Link
              to={callibratorLink}
              className="recalibrate-btn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="re-text">RE-CALIBRATE</span>{" "}
              <span
                className="re-img"
                dangerouslySetInnerHTML={{ __html: calibratorSvg }}
              ></span>
            </Link>
          )}
          {uploadReport && gid != 14 && (
            <>
              {isUploadReportEdit ?
                <div className="after-upload-wrapper">
                  <div className="upload-text">
                    <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>
                    <span>Uploaded</span>
                  </div>
                  <button className="edit-btn" onClick={(e) => handleUploadReportPopup(e)}>
                    <span>EDIT UPLOAD</span>
                    <i dangerouslySetInnerHTML={{ __html: pencilIcon }}></i>
                  </button>
                </div>
                :
                <button className="recalibrate-btn upload-report-btn" onClick={(e) => handleUploadReportPopup(e)}>
                  <span className="re-text">UPLOAD REPORT</span>{" "}
                  <span className="re-img" dangerouslySetInnerHTML={{ __html: uploadIcon }}></span>
                </button>
              }
            </>
          )}
          {/* max-character 49*/}
          {getAcessButton && gid != 14 && (
            <Link
              to={getaccessLink}
              className="getaccess-btn"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                localStorage.setItem(`upgradeSection`, upgradeSection);
                // Condition for Upgrade Input Check
                localStorage.setItem(`plantName`, plantName);
                localStorage.setItem(`plantId`, plantId);
                localStorage.setItem(`comboIdget`, JSON.stringify(comboIdget));
                document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
              }}
            >
              <span className="re-text">GET ACCESS</span>{" "}
              <span
                className="re-img"
                dangerouslySetInnerHTML={{ __html: upgradeSvg }}
              ></span>
            </Link>
          )}
        </div>
      </ConditionalTag>
    </>
  );
};
//conditional tag component
const ConditionalTag = (props) => {
  const { url, otherFunction, directurl } = props;
  return (
    <>
      {directurl ? (
        <a href={directurl} className="prp-card">
          {props.children}
        </a>
      ) : url ? (
        <Link to={url} className="prp-card">
          {props.children}
        </Link>
      ) : (
        <div className="prp-card" onClick={otherFunction}>
          {props.children}
        </div>
      )}
    </>
  );
};
//Popup components
const BussinessCasePopup = (props) => {
  const { isPopup, setIsPopup } = props;
  //function to close
  const handleClose = () => {
    setIsPopup(false);
  };
  return (
    <div className={`prp-businesscase-popup-wrap ${isPopup ? "active" : ""}`}>
      {/* wrapper has
          close-btn
          pop-body-wrap
      */}
      <div className="prp-businesscase-popup">
        <button className="close-btn" onClick={handleClose}>
          <img
            src={
              require("../../assets/img/prepare-home/close-popup.svg").default
            }
            alt="Close Button"
          />
        </button>
        {/* pop-body-wrap has three sections
            img-box
            heading
            text 
        */}
        <div className="pop-body-wrap">
          <div className="img-box">
            <img
              src={
                require("../../assets/img/prepare-home/businesscase-pop.svg")
                  .default
              }
              alt="Businesscase"
            />
          </div>
          <h1 className="heading">
            Your Business case results are not ready yet.
          </h1>
          <p className="text">
            We will notify you when they will be available.
          </p>
          <button className="ok-btn" onClick={handleClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
const MatCasePopup = (props) => {
  const { ismPopup, setIsmPopup } = props;
  const handleClosem = () => {
    setIsmPopup(false);
  };
  return (
    <div className={`prp-businesscase-popup-wrap ${ismPopup ? "active" : ""}`}>
      {/* wrapper has
          close-btn
          pop-body-wrap
      */}
      <div className="prp-businesscase-popup">
        <button className="close-btn" onClick={handleClosem}>
          <img
            src={
              require("../../assets/img/prepare-home/close-popup.svg").default
            }
            alt="Close Button"
          />
        </button>
        {/* pop-body-wrap has three sections
            img-box
            heading
            text 
        */}
        <div className="pop-body-wrap">
          <div className="img-box">
            <img
              src={
                require("../../assets/img/prepare-home/businesscase-pop.svg")
                  .default
              }
              alt="Businesscase"
            />
          </div>
          <h1 className="heading">
            Your Maturity Assessment results are not ready yet.
          </h1>
          <p className="text">
            We will notify you when they will be available.
          </p>
          <button className="ok-btn" onClick={handleClosem}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
const LossCasePopup = (props) => {
  const { islPopup, setIslPopup } = props;
  const handleClosel = () => {
    setIslPopup(false);
  };
  return (
    <div className={`prp-businesscase-popup-wrap ${islPopup ? "active" : ""}`}>
      {/* wrapper has
          close-btn
          pop-body-wrap
      */}
      <div className="prp-businesscase-popup">
        {/* this section has image */}
        <button className="close-btn" onClick={handleClosel}>
          <img
            src={
              require("../../assets/img/prepare-home/close-popup.svg").default
            }
            alt="Close Button"
          />
        </button>
        {/* pop-body-wrap has three sections
            img-box
            heading
            text 
        */}
        <div className="pop-body-wrap">
          <div className="img-box">
            <img
              src={
                require("../../assets/img/prepare-home/businesscase-pop.svg")
                  .default
              }
              alt="Businesscase"
            />
          </div>
          <h1 className="heading">
            Your Loss Analysis results are not ready yet.
          </h1>
          <p className="text">
            We will notify you when they will be available.
          </p>
          <button className="ok-btn" onClick={handleClosel}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
// Disabled Roadmap notification popup
const StartegyPopup = (props) => {
  const { isStrPopup, setIsStrPopup, title } = props;
  const handleCloseStr = () => {
    setIsStrPopup(false);
  };
  return (
    <>
      <div className={`strategy-popup-wrapper ${isStrPopup ? "active" : ""}`}>
        <div className="startegy-pop-card">
          {props.heading !== 'na' ?
            <h2>{props.heading || `IR4.0 Strategy tool not available`}</h2>
            : <></>
          }
          <p dangerouslySetInnerHTML={{ __html: props.title }}></p>
          <button onClick={handleCloseStr}>Okay</button>
        </div>
      </div>
    </>
  );
};
// Upgrade Add Plant popup
const UpgradeAddPopup = (props) => {
  const { openUpgradePopup, setOpenUpgradePopup } = props;
  const handleCloseUpgrade = () => {
    setOpenUpgradePopup(false);
  }
  const handleUpgradePopupClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
  }
  return (
    <>
      <div className="upgrade-popup-wrapper">
        <div className="upgrade-pop-card">
          <div className="upgrade-plant-head">
            <span className="upgrade-home-text">Add Plant</span>
            <button className="upgrade-close-btn" onClick={() => handleCloseUpgrade()}>
              <i dangerouslySetInnerHTML={{ __html: upgradeCloseSvg }}></i>
            </button>
          </div>
          <div className="upgrade-pop-body">
            <p>You don’t have any credits to add plant.<br /> Explore our plans and upgrade now</p>
            <button className="upgrade-now" onClick={(e) => { handleUpgradePopupClick(e); handleCloseUpgrade(e); }}>Upgrade Now</button>
          </div>
        </div>
      </div>
    </>
  );
}
// SVGs
const StatusOvalSvg = `
      <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="8" rx="4" fill="#FF8383"></rect>
      </svg>
`;
const arrowSvg = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1308_265487" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1308_265487)">
<path d="M7.49298 16.6935C7.33586 16.8554 7.07603 16.8554 6.91891 16.6935L6.27039 16.0253C6.11977 15.8702 6.11977 15.6234 6.27039 15.4682L11.3177 10.2678C11.4683 10.1127 11.4683 9.86586 11.3177 9.71067L6.27039 4.51035C6.11977 4.35516 6.11977 4.10836 6.27039 3.95317L6.91891 3.28499C7.07603 3.12311 7.33586 3.12311 7.49298 3.28499L13.7296 9.71067C13.8802 9.86586 13.8802 10.1127 13.7296 10.2678L7.49298 16.6935Z" fill="white"/>
</g>
</svg>`;

const calibratorSvg = ` <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#607D8B" />
                    <path
                      d="M9 11L8.3 10.275L10.075 8.5H4V7.5H10.075L8.3 5.725L9 5L12 8L9 11Z"
                      fill="white"
                    />
                  </svg>`;
const upgradeSvg = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#2C55A0"/>
<path d="M9 11L8.3 10.275L10.075 8.5H4V7.5H10.075L8.3 5.725L9 5L12 8L9 11Z" fill="white"/>
</svg>`

const plantEditIcon = `<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 16V14H7H14.5534V16H0ZM1.45534 11.634V8.90522L8.04075 2.31981L10.7695 5.04857L4.1841 11.634H1.45534ZM2.91068 10.1786H3.56558L8.73203 5.04857L8.04075 4.35729L2.91068 9.52374V10.1786ZM11.5881 4.24814L8.85938 1.51937L10.1692 0.209569C10.3026 0.0640349 10.4724 -0.00570008 10.6786 0.000363835C10.8847 0.00642775 11.0545 0.0761628 11.1879 0.209569L12.8979 1.91959C13.0313 2.053 13.0981 2.21976 13.0981 2.41987C13.0981 2.61997 13.0313 2.7928 12.8979 2.93833L11.5881 4.24814Z" fill="#90A4AE"/>
</svg>
` ;
const plantDelIcon = `<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1553_277180" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="23">
<rect y="0.5" width="22" height="22" fill="#90A4AE"/>
</mask>
<g mask="url(#mask0_1553_277180)">
<path d="M6.4165 19.75C5.91234 19.75 5.48074 19.5705 5.12171 19.2115C4.76268 18.8524 4.58317 18.4208 4.58317 17.9167V6H3.6665V4.16667H8.24984V3.25H13.7498V4.16667H18.3332V6H17.4165V17.9167C17.4165 18.4208 17.237 18.8524 16.878 19.2115C16.5189 19.5705 16.0873 19.75 15.5832 19.75H6.4165ZM15.5832 6H6.4165V17.9167H15.5832V6ZM8.24984 16.0833H10.0832V7.83333H8.24984V16.0833ZM11.9165 16.0833H13.7498V7.83333H11.9165V16.0833Z" fill="#90A4AE"/>
</g>
</svg>
`;
const angleIcon = `<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 8.37461L0 2.37461L1.4 0.974609L6 5.57461L10.6 0.974609L12 2.37461L6 8.37461Z" fill="#455A64"/>
</svg>
`;

const closeSvg = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.295 2.115C13.6844 1.72564 13.6844 1.09436 13.295 0.705C12.9056 0.315639 12.2744 0.315639 11.885 0.705L7.70711 4.88289C7.31658 5.27342 6.68342 5.27342 6.29289 4.88289L2.115 0.705C1.72564 0.315639 1.09436 0.315639 0.705 0.705C0.315639 1.09436 0.315639 1.72564 0.705 2.115L4.88289 6.29289C5.27342 6.68342 5.27342 7.31658 4.88289 7.70711L0.705 11.885C0.315639 12.2744 0.315639 12.9056 0.705 13.295C1.09436 13.6844 1.72564 13.6844 2.115 13.295L6.29289 9.11711C6.68342 8.72658 7.31658 8.72658 7.70711 9.11711L11.885 13.295C12.2744 13.6844 12.9056 13.6844 13.295 13.295C13.6844 12.9056 13.6844 12.2744 13.295 11.885L9.11711 7.70711C8.72658 7.31658 8.72658 6.68342 9.11711 6.29289L13.295 2.115Z" fill="#4D4D4F"/>
</svg>
`;
const upgradeCloseSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.63593 7.05025C5.24541 6.65973 5.24541 6.02656 5.63593 5.63604C6.02646 5.24552 6.65962 5.24551 7.05015 5.63604L18.3639 16.9497C18.7544 17.3403 18.7544 17.9734 18.3639 18.364C17.9733 18.7545 17.3402 18.7545 16.9496 18.364L5.63593 7.05025Z" fill="#4D4D4F"/>
<path d="M5.63593 18.364C5.24541 17.9734 5.24541 17.3403 5.63593 16.9497L16.9496 5.63604C17.3402 5.24552 17.9733 5.24552 18.3639 5.63604C18.7544 6.02656 18.7544 6.65973 18.3639 7.05025L7.05015 18.364C6.65962 18.7545 6.02646 18.7545 5.63593 18.364Z" fill="#4D4D4F"/>
</svg>`
const noPlantSvg = `<svg width="201" height="150" viewBox="0 0 201 150" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M200.5 131.818H0.5V131.918H200.5V131.818Z" fill="#EBEBEB"/>
<path d="M66.3359 138.027H53.0879V138.127H66.3359V138.027Z" fill="#EBEBEB"/>
<path d="M104.036 136.936H100.56V137.036H104.036V136.936Z" fill="#EBEBEB"/>
<path d="M29.1599 135.236H21.4839V135.336H29.1599V135.236Z" fill="#EBEBEB"/>
<path d="M166.812 134.559H156.38V134.659H166.812V134.559Z" fill="#EBEBEB"/>
<path d="M152.816 134.559H143.44V134.659H152.816V134.559Z" fill="#EBEBEB"/>
<path d="M180.464 139.393H142.992V139.493H180.464V139.393Z" fill="#EBEBEB"/>
<path d="M95.3002 113.861H18.0642C17.4591 113.86 16.8792 113.617 16.4517 113.186C16.0243 112.755 15.7842 112.171 15.7842 111.562V2.27883C15.7894 1.6733 16.0319 1.09431 16.4588 0.667633C16.8857 0.240952 17.4626 0.00103731 18.0642 0H95.3002C95.9059 0 96.4869 0.242212 96.9152 0.673351C97.3435 1.10449 97.5842 1.68924 97.5842 2.29896V111.562C97.5842 112.172 97.3435 112.757 96.9152 113.188C96.4869 113.619 95.9059 113.861 95.3002 113.861ZM18.0642 0.0805244C17.4856 0.081591 16.9312 0.313668 16.5224 0.725815C16.1137 1.13796 15.8842 1.6965 15.8842 2.27883V111.562C15.8842 112.145 16.1137 112.703 16.5224 113.115C16.9312 113.527 17.4856 113.759 18.0642 113.76H95.3002C95.8791 113.759 96.434 113.527 96.8433 113.115C97.2527 112.703 97.4831 112.145 97.4842 111.562V2.27883C97.4831 1.69613 97.2527 1.1376 96.8433 0.725569C96.434 0.313537 95.8791 0.0815887 95.3002 0.0805244H18.0642Z" fill="#EBEBEB"/>
<path d="M181.824 113.861H104.584C103.978 113.86 103.398 113.618 102.97 113.187C102.542 112.756 102.301 112.172 102.3 111.562V2.27883C102.306 1.67293 102.549 1.09395 102.977 0.667381C103.404 0.240811 103.982 0.00102599 104.584 0H181.824C182.425 0.00209544 183.001 0.242478 183.427 0.669046C183.853 1.09561 184.095 1.674 184.1 2.27883V111.562C184.1 112.171 183.86 112.754 183.434 113.185C183.007 113.616 182.428 113.859 181.824 113.861ZM104.584 0.0805244C104.005 0.0815887 103.45 0.313537 103.041 0.725569C102.631 1.1376 102.401 1.69613 102.4 2.27883V111.562C102.401 112.145 102.631 112.703 103.041 113.115C103.45 113.527 104.005 113.759 104.584 113.76H181.824C182.403 113.759 182.958 113.527 183.367 113.115C183.776 112.703 184.007 112.145 184.008 111.562V2.27883C184.007 1.69613 183.776 1.1376 183.367 0.725569C182.958 0.313537 182.403 0.0815887 181.824 0.0805244H104.584Z" fill="#EBEBEB"/>
<path d="M127.94 130.617H137.36V89.1311H127.94V130.617Z" fill="#F0F0F0"/>
<path d="M94.0797 131.818H135.556V130.619H94.0797V131.818Z" fill="#F0F0F0"/>
<path d="M127.94 89.1328H92.2681V130.619H127.94V89.1328Z" fill="#F5F5F5"/>
<path d="M124.236 91.459H95.9722V108.949H124.236V91.459Z" fill="#F0F0F0"/>
<path d="M124.236 110.801H95.9722V128.291H124.236V110.801Z" fill="#F0F0F0"/>
<path d="M117.432 94.5071H102.78C102.322 94.5076 101.868 94.417 101.445 94.2404C101.021 94.0638 100.637 93.8047 100.314 93.478C99.9903 93.1514 99.7342 92.7636 99.5602 92.337C99.3862 91.9104 99.2977 91.4533 99.2998 90.9922H120.9C120.902 91.4523 120.814 91.9084 120.641 92.3342C120.467 92.76 120.212 93.1473 119.89 93.4738C119.568 93.8003 119.185 94.0596 118.763 94.2369C118.341 94.4142 117.889 94.506 117.432 94.5071Z" fill="#F5F5F5"/>
<path d="M117.432 113.85H102.78C101.858 113.85 100.974 113.482 100.322 112.826C99.6699 112.169 99.3037 111.279 99.3037 110.352H120.904C120.904 111.279 120.538 112.168 119.887 112.824C119.236 113.48 118.353 113.849 117.432 113.85Z" fill="#F5F5F5"/>
<path d="M166.412 130.617H175.832V89.1311H166.412V130.617Z" fill="#F0F0F0"/>
<path d="M132.548 131.818H174.024V130.619H132.548V131.818Z" fill="#F0F0F0"/>
<path d="M166.412 89.1328H130.74V130.619H166.412V89.1328Z" fill="#F5F5F5"/>
<path d="M162.708 91.459H134.444V108.949H162.708V91.459Z" fill="#F0F0F0"/>
<path d="M162.708 110.801H134.444V128.291H162.708V110.801Z" fill="#F0F0F0"/>
<path d="M155.9 94.5066H141.248C140.326 94.5066 139.442 94.138 138.79 93.4818C138.138 92.8257 137.772 91.9357 137.772 91.0078H159.372C159.372 91.935 159.006 92.8244 158.355 93.4804C157.704 94.1364 156.821 94.5055 155.9 94.5066Z" fill="#F5F5F5"/>
<path d="M155.9 113.85H141.248C140.326 113.85 139.442 113.482 138.79 112.826C138.138 112.169 137.772 111.279 137.772 110.352H159.372C159.372 111.279 159.006 112.168 158.355 112.824C157.704 113.48 156.821 113.849 155.9 113.85Z" fill="#F5F5F5"/>
<path d="M45.0681 33.3926H61.2041V14.6184L45.0681 14.6184V33.3926Z" fill="#E6E6E6"/>
<path d="M43.228 33.3926H60.772V14.6184L43.228 14.6184V33.3926Z" fill="#F5F5F5"/>
<path d="M59.2998 31.9165V16.1016H44.6958V31.9165H59.2998Z" fill="white"/>
<path d="M55.7637 28.0826V27.5069C55.7627 25.4987 54.9696 23.573 53.5588 22.1529C52.1481 20.7329 50.2349 19.9347 48.2397 19.9336V20.5093C48.2397 22.5179 49.0325 24.4442 50.4435 25.8645C51.8545 27.2847 53.7683 28.0826 55.7637 28.0826Z" fill="#F5F5F5"/>
<path d="M115.156 68.8262L175.836 68.8262V11.6943L115.156 11.6943V68.8262Z" fill="#E6E6E6"/>
<path d="M113.276 68.8262L174.956 68.8262V11.6943L113.276 11.6943V68.8262Z" fill="#F0F0F0"/>
<path d="M115.156 70.8652L175.836 70.8652V68.828L115.156 68.828V70.8652Z" fill="#E6E6E6"/>
<path d="M110.14 70.8652L171.82 70.8652V68.828L110.14 68.828V70.8652Z" fill="#F0F0F0"/>
<path d="M172.32 65.7596V14.7637L115.904 14.7637V65.7596H172.32Z" fill="#FAFAFA"/>
<path d="M156.324 65.7636L146.928 14.7637H131.448L140.844 65.7636H156.324Z" fill="white"/>
<path d="M171.104 48.4598C171.157 48.4557 171.207 48.4306 171.242 48.3899C171.277 48.3492 171.295 48.2962 171.292 48.2424V16.7615C171.292 16.7113 171.272 16.6632 171.237 16.6277C171.202 16.5922 171.154 16.5723 171.104 16.5723C171.054 16.5723 171.006 16.5922 170.971 16.6277C170.936 16.6632 170.916 16.7113 170.916 16.7615V48.2424C170.913 48.2962 170.93 48.3492 170.966 48.3899C171.001 48.4306 171.05 48.4557 171.104 48.4598Z" fill="#F0F0F0"/>
<path d="M137.516 65.7636L128.124 14.7637H122.088L131.48 65.7636H137.516Z" fill="white"/>
<path d="M116.24 65.7596V14.7637H115.904V65.7596H116.24Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 18.6888H173.328L173.572 15.959H112.892L112.652 18.6888Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 21.8354H173.328L173.572 19.1016H112.892L112.652 21.8354Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 24.9739H173.328L173.572 22.2441H112.892L112.652 24.9739Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 28.1205H173.328L173.572 25.3867H112.892L112.652 28.1205Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 31.2591H173.328L173.572 28.5293H112.892L112.652 31.2591Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 34.4057H173.328L173.572 31.6719H112.892L112.652 34.4057Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 37.5442H173.328L173.572 34.8145H112.892L112.652 37.5442Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 40.6889H173.328L173.572 37.9551H112.892L112.652 40.6889Z" fill="#E6E6E6"/>
<path opacity="0.4" d="M112.652 43.8294H173.328L173.572 41.0996H112.892L112.652 43.8294Z" fill="#E6E6E6"/>
<path d="M33.6278 64.0195H46.2158V49.4809H33.6278V64.0195Z" fill="#E6E6E6"/>
<path d="M32.204 64.0195H45.792V49.4809H32.204V64.0195Z" fill="#F5F5F5"/>
<path d="M44.6558 62.8747V50.627H33.3438V62.8747H44.6558Z" fill="white"/>
<path d="M41.9121 56.9756V56.5287C41.9121 56.1435 41.8366 55.762 41.6901 55.4061C41.5435 55.0503 41.3286 54.727 41.0578 54.4547C40.787 54.1825 40.4655 53.9667 40.1117 53.8196C39.7579 53.6726 39.3788 53.5971 38.9961 53.5977C38.2266 53.6019 37.49 53.9126 36.9473 54.4618C36.4047 55.0111 36.1001 55.7541 36.1001 56.5287V56.9756C36.1001 57.753 36.4069 58.4985 36.953 59.0482C37.4991 59.5979 38.2398 59.9067 39.0121 59.9067C39.7823 59.9035 40.5199 59.5933 41.0634 59.044C41.607 58.4946 41.9121 57.7509 41.9121 56.9756Z" fill="#F5F5F5"/>
<path d="M65.0241 58.8105H82.3081V38.7681H65.0241V58.8105Z" fill="#E6E6E6"/>
<path d="M63.064 58.8105H81.792V38.7681H63.064V58.8105Z" fill="#F5F5F5"/>
<path d="M80.2241 57.2354V40.3535H64.6321V57.2354H80.2241Z" fill="white"/>
<path d="M73.0801 45.6609L76.0401 50.8225C76.1061 50.9377 76.1409 51.0683 76.1408 51.2012C76.1408 51.3341 76.106 51.4647 76.0399 51.5798C75.9738 51.6949 75.8788 51.7904 75.7643 51.8568C75.6499 51.9232 75.5201 51.9581 75.3881 51.9579H69.4681C69.336 51.9581 69.2062 51.9232 69.0918 51.8568C68.9774 51.7904 68.8824 51.6949 68.8163 51.5798C68.7502 51.4647 68.7154 51.3341 68.7153 51.2012C68.7153 51.0683 68.75 50.9377 68.8161 50.8225L71.7761 45.6609C71.8428 45.5465 71.938 45.4517 72.0524 45.3858C72.1668 45.3198 72.2963 45.2852 72.4281 45.2852C72.5599 45.2852 72.6894 45.3198 72.8037 45.3858C72.9181 45.4517 73.0134 45.5465 73.0801 45.6609Z" fill="#F5F5F5"/>
<path d="M36.5078 131.812H46.0078L46.0078 101.664H36.5078V131.812Z" fill="#E6E6E6"/>
<path d="M38.3118 131.818H36.5078V126.652H40.2038L38.3118 131.818Z" fill="#F0F0F0"/>
<path d="M72.0078 131.812H81.5078V101.664H72.0078V131.812Z" fill="#E6E6E6"/>
<path d="M36.512 128.646H73.584V101.667H36.512V128.646Z" fill="#F0F0F0"/>
<path d="M37.9082 103.361V122.191H54.4402V103.361H37.9082Z" fill="#E6E6E6"/>
<path d="M55.6519 103.361V122.191H72.1838V103.361H55.6519Z" fill="#E6E6E6"/>
<path d="M62.2838 104.806H65.5518C66.2537 104.808 66.9486 104.666 67.5944 104.389C68.2401 104.113 68.8231 103.706 69.3078 103.195H58.5078C58.9946 103.709 59.5807 104.117 60.2302 104.394C60.8796 104.671 61.5785 104.811 62.2838 104.806Z" fill="#F0F0F0"/>
<path d="M44.3721 104.806H47.6441C48.3459 104.808 49.0408 104.666 49.6865 104.389C50.3322 104.112 50.9152 103.706 51.4001 103.195H40.6001C41.0867 103.708 41.6723 104.116 42.3209 104.393C42.9695 104.669 43.6675 104.81 44.3721 104.806Z" fill="#F0F0F0"/>
<path d="M71.7802 131.818H73.5842V126.652H69.8882L71.7802 131.818Z" fill="#F0F0F0"/>
<path d="M62 97.6261C61.9992 98.0364 62.1194 98.4377 62.3452 98.7792C62.5711 99.1207 62.8926 99.3871 63.2689 99.5446C63.6452 99.7022 64.0596 99.7438 64.4594 99.6643C64.8593 99.5847 65.2267 99.3876 65.5152 99.0977C65.8037 98.8079 66.0003 98.4385 66.0801 98.0361C66.1599 97.6338 66.1194 97.2167 65.9636 96.8376C65.8078 96.4585 65.5437 96.1344 65.2049 95.9064C64.866 95.6783 64.4676 95.5566 64.06 95.5566C63.5147 95.5577 62.9919 95.7759 62.6059 96.1637C62.22 96.5515 62.0021 97.0772 62 97.6261Z" fill="#E0E0E0"/>
<path d="M64.992 98.3518C66.1275 98.3518 67.048 97.4253 67.048 96.2824C67.048 95.1394 66.1275 94.2129 64.992 94.2129C63.8565 94.2129 62.936 95.1394 62.936 96.2824C62.936 97.4253 63.8565 98.3518 64.992 98.3518Z" fill="#E0E0E0"/>
<path d="M51.7002 97.962C51.7002 98.3713 51.8208 98.7715 52.0467 99.1118C52.2726 99.4521 52.5937 99.7174 52.9694 99.874C53.3451 100.031 53.7585 100.072 54.1573 99.9917C54.5561 99.9119 54.9225 99.7148 55.21 99.4254C55.4975 99.136 55.6934 98.7672 55.7727 98.3658C55.852 97.9643 55.8113 97.5482 55.6557 97.1701C55.5001 96.7919 55.2366 96.4687 54.8984 96.2413C54.5603 96.0139 54.1628 95.8926 53.7562 95.8926C53.2109 95.8926 52.688 96.1106 52.3024 96.4987C51.9168 96.8868 51.7002 97.4132 51.7002 97.962Z" fill="#E0E0E0"/>
<path d="M67.7442 99.1631C69.1382 99.1631 70.2682 98.0257 70.2682 96.6226C70.2682 95.2195 69.1382 94.082 67.7442 94.082C66.3502 94.082 65.2202 95.2195 65.2202 96.6226C65.2202 98.0257 66.3502 99.1631 67.7442 99.1631Z" fill="#E0E0E0"/>
<path d="M60.2202 94.2826C60.2194 94.7853 60.3668 95.2768 60.6436 95.6952C60.9205 96.1135 61.3144 96.4397 61.7755 96.6326C62.2367 96.8255 62.7443 96.8764 63.2342 96.7788C63.724 96.6813 64.1741 96.4396 64.5275 96.0845C64.8809 95.7293 65.1217 95.2767 65.2194 94.7837C65.3171 94.2908 65.2674 93.7798 65.0765 93.3153C64.8856 92.8509 64.562 92.4539 64.1469 92.1745C63.7317 91.8952 63.2436 91.7461 62.7442 91.7461C62.0758 91.7472 61.4351 92.0146 60.9621 92.4899C60.4891 92.9653 60.2223 93.6098 60.2202 94.2826Z" fill="#E0E0E0"/>
<path d="M56.2197 94.931C56.2189 95.4337 56.3663 95.9253 56.6432 96.3436C56.92 96.7619 57.3139 97.0881 57.7751 97.2811C58.2362 97.474 58.7438 97.5249 59.2337 97.4273C59.7236 97.3297 60.1737 97.0881 60.527 96.7329C60.8804 96.3778 61.1212 95.9251 61.2189 95.4322C61.3167 94.9392 61.2669 94.4282 61.076 93.9638C60.8851 93.4993 60.5616 93.1023 60.1464 92.823C59.7312 92.5436 59.2431 92.3945 58.7437 92.3945C58.075 92.3945 57.4336 92.6616 56.9604 93.1372C56.4872 93.6128 56.2208 94.2579 56.2197 94.931Z" fill="#E0E0E0"/>
<path d="M57.9121 96.9782C57.9121 97.456 58.0529 97.9231 58.3166 98.3203C58.5803 98.7176 58.9551 99.0272 59.3937 99.2101C59.8322 99.3929 60.3148 99.4407 60.7803 99.3475C61.2459 99.2543 61.6735 99.0242 62.0092 98.6864C62.3448 98.3486 62.5734 97.9181 62.666 97.4495C62.7586 96.9809 62.7111 96.4952 62.5294 96.0538C62.3478 95.6124 62.0402 95.2351 61.6455 94.9696C61.2508 94.7042 60.7868 94.5625 60.3121 94.5625C59.6756 94.5625 59.0651 94.817 58.6151 95.27C58.165 95.7231 57.9121 96.3375 57.9121 96.9782Z" fill="#E0E0E0"/>
<path d="M53.7358 96.8219C53.7358 97.3379 53.8879 97.8423 54.1727 98.2714C54.4575 98.7004 54.8623 99.0348 55.3359 99.2323C55.8096 99.4297 56.3307 99.4814 56.8335 99.3807C57.3363 99.2801 57.7982 99.0316 58.1607 98.6667C58.5232 98.3018 58.77 97.8369 58.87 97.3309C58.9701 96.8248 58.9187 96.3002 58.7225 95.8235C58.5264 95.3467 58.1941 94.9393 57.7679 94.6526C57.3416 94.3659 56.8405 94.2129 56.3278 94.2129C55.6404 94.2129 54.9811 94.4878 54.495 94.977C54.0089 95.4663 53.7358 96.1299 53.7358 96.8219Z" fill="#E0E0E0"/>
<path d="M71.7679 97.627C71.7679 97.627 71.4878 101.653 60.6598 101.653C49.8318 101.653 49.5479 97.627 49.5479 97.627H71.7679Z" fill="#F0F0F0"/>
<path d="M100.5 149.998C143.333 149.998 178.056 147.958 178.056 145.44C178.056 142.923 143.333 140.883 100.5 140.883C57.6673 140.883 22.9443 142.923 22.9443 145.44C22.9443 147.958 57.6673 149.998 100.5 149.998Z" fill="#F5F5F5"/>
<path d="M123.608 26.1303C123.283 25.0484 122.851 24.0018 122.32 23.006L123.12 20.3688L117.948 15.1589L115.328 15.9641C114.338 15.4294 113.299 14.9951 112.224 14.6677L110.936 12.252H103.616L102.332 14.6677C101.257 14.9943 100.217 15.4286 99.2278 15.9641L96.6078 15.1589L91.4318 20.3688L92.2318 23.006C91.7036 24.0027 91.2735 25.0492 90.9478 26.1303L88.5278 27.4267V34.7947L90.9478 36.0871C91.2727 37.1685 91.7028 38.215 92.2318 39.2114L91.4318 41.8486L96.6078 47.0585L99.2278 46.2533C100.218 46.7858 101.257 47.2187 102.332 47.5457L103.616 49.9614H110.936L112.224 47.5457C113.298 47.2178 114.338 46.785 115.328 46.2533L117.948 47.0585L123.124 41.8486L122.324 39.2114C122.856 38.2159 123.287 37.1693 123.612 36.0871L126.032 34.7947V27.4267L123.608 26.1303ZM107.276 41.5748C105.219 41.5748 103.208 40.9609 101.498 39.8106C99.7876 38.6604 98.4546 37.0255 97.6675 35.1127C96.8803 33.1999 96.6744 31.0951 97.0757 29.0644C97.4769 27.0338 98.4675 25.1686 99.9219 23.7046C101.376 22.2406 103.229 21.2436 105.247 20.8397C107.264 20.4358 109.355 20.6431 111.256 21.4354C113.156 22.2277 114.78 23.5694 115.923 25.2909C117.066 27.0124 117.676 29.0363 117.676 31.1067C117.676 33.883 116.58 36.5456 114.63 38.5088C112.679 40.4719 110.034 41.5748 107.276 41.5748Z" fill="#1487E3"/>
<path opacity="0.2" d="M123.608 26.1303C123.283 25.0484 122.851 24.0018 122.32 23.006L123.12 20.3688L117.948 15.1589L115.328 15.9641C114.338 15.4294 113.299 14.9951 112.224 14.6677L110.936 12.252H103.616L102.332 14.6677C101.257 14.9943 100.217 15.4286 99.2278 15.9641L96.6078 15.1589L91.4318 20.3688L92.2318 23.006C91.7036 24.0027 91.2735 25.0492 90.9478 26.1303L88.5278 27.4267V34.7947L90.9478 36.0871C91.2727 37.1685 91.7028 38.215 92.2318 39.2114L91.4318 41.8486L96.6078 47.0585L99.2278 46.2533C100.218 46.7858 101.257 47.2187 102.332 47.5457L103.616 49.9614H110.936L112.224 47.5457C113.298 47.2178 114.338 46.785 115.328 46.2533L117.948 47.0585L123.124 41.8486L122.324 39.2114C122.856 38.2159 123.287 37.1693 123.612 36.0871L126.032 34.7947V27.4267L123.608 26.1303ZM107.276 41.5748C105.219 41.5748 103.208 40.9609 101.498 39.8106C99.7876 38.6604 98.4546 37.0255 97.6675 35.1127C96.8803 33.1999 96.6744 31.0951 97.0757 29.0644C97.4769 27.0338 98.4675 25.1686 99.9219 23.7046C101.376 22.2406 103.229 21.2436 105.247 20.8397C107.264 20.4358 109.355 20.6431 111.256 21.4354C113.156 22.2277 114.78 23.5694 115.923 25.2909C117.066 27.0124 117.676 29.0363 117.676 31.1067C117.676 33.883 116.58 36.5456 114.63 38.5088C112.679 40.4719 110.034 41.5748 107.276 41.5748Z" fill="white"/>
<path d="M107.276 27.6387C106.594 27.6387 105.928 27.8422 105.361 28.2236C104.793 28.6049 104.352 29.147 104.091 29.7811C103.83 30.4153 103.761 31.1131 103.894 31.7863C104.027 32.4596 104.356 33.078 104.838 33.5633C105.32 34.0487 105.935 34.3793 106.603 34.5132C107.272 34.6471 107.966 34.5783 108.596 34.3157C109.226 34.053 109.764 33.6082 110.143 33.0374C110.522 32.4667 110.724 31.7957 110.724 31.1093C110.724 30.1888 110.361 29.306 109.714 28.6552C109.068 28.0043 108.191 27.6387 107.276 27.6387Z" fill="#1487E3"/>
<path d="M175.7 37.8179C175.235 36.2712 174.62 34.7743 173.864 33.3489L175.008 29.5803L167.62 22.1439L163.872 23.2995C162.461 22.5296 160.974 21.9089 159.436 21.4474L157.6 17.9688H147.14L145.3 21.4474C143.762 21.9104 142.276 22.531 140.864 23.2995L137.12 22.1439L129.724 29.5884L130.868 33.3569C130.108 34.7808 129.493 36.278 129.032 37.826L125.572 39.674V50.2026L129.028 52.0546C129.488 53.6016 130.104 55.0976 130.864 56.5197L129.72 60.2842L137.116 67.7286L140.86 66.5771C142.273 67.3432 143.761 67.9611 145.3 68.4212L147.14 71.9038H157.6L159.436 68.4252C160.973 67.9602 162.459 67.3396 163.872 66.5731L167.62 67.7246L175.016 60.2802L173.872 56.5116C174.626 55.0876 175.238 53.592 175.7 52.0466L179.156 50.1945V39.666L175.7 37.8179ZM152.368 64.0165C148.617 64.0165 144.951 62.897 141.832 60.7995C138.713 58.7021 136.283 55.7209 134.847 52.233C133.412 48.7451 133.036 44.9071 133.768 41.2043C134.5 37.5015 136.306 34.1003 138.958 31.4308C141.61 28.7613 144.989 26.9433 148.668 26.2068C152.347 25.4702 156.16 25.8482 159.625 27.293C163.09 28.7377 166.052 31.1843 168.136 34.3234C170.22 37.4624 171.332 41.1529 171.332 44.9282C171.332 47.4349 170.841 49.9171 169.888 52.233C168.935 54.5489 167.538 56.6532 165.777 58.4257C164.016 60.1982 161.926 61.6042 159.625 62.5635C157.324 63.5227 154.858 64.0165 152.368 64.0165Z" fill="#1487E3"/>
<path d="M152.367 28.5176C149.142 28.5184 145.99 29.4818 143.308 31.2859C140.627 33.0901 138.537 35.654 137.304 38.6535C136.07 41.653 135.748 44.9534 136.377 48.1373C137.007 51.3212 138.561 54.2456 140.841 56.5409C143.122 58.8362 146.028 60.3991 149.191 61.0322C152.355 61.6652 155.634 61.3399 158.613 60.0974C161.593 58.8549 164.14 56.7509 165.931 54.0516C167.723 51.3523 168.679 48.1788 168.679 44.9324C168.674 40.5799 166.954 36.4072 163.896 33.3298C160.838 30.2525 156.692 28.5218 152.367 28.5176ZM152.367 59.161C149.571 59.1618 146.838 58.328 144.513 56.765C142.188 55.2021 140.375 52.9801 139.305 50.3803C138.234 47.7804 137.954 44.9194 138.499 42.159C139.044 39.3987 140.39 36.863 142.366 34.8727C144.343 32.8824 146.862 31.5268 149.604 30.9775C152.346 30.4282 155.189 30.7097 157.772 31.7865C160.355 32.8633 162.563 34.687 164.117 37.027C165.67 39.367 166.499 42.1181 166.499 44.9324C166.495 48.7041 165.005 52.3201 162.356 54.9874C159.707 57.6548 156.115 59.1557 152.367 59.161Z" fill="#1487E3"/>
<path opacity="0.2" d="M152.367 28.5176C149.142 28.5184 145.99 29.4818 143.308 31.2859C140.627 33.0901 138.537 35.654 137.304 38.6535C136.07 41.653 135.748 44.9534 136.377 48.1373C137.007 51.3212 138.561 54.2456 140.841 56.5409C143.122 58.8362 146.028 60.3991 149.191 61.0322C152.355 61.6652 155.634 61.3399 158.613 60.0974C161.593 58.8549 164.14 56.7509 165.931 54.0516C167.723 51.3523 168.679 48.1788 168.679 44.9324C168.674 40.5799 166.954 36.4072 163.896 33.3298C160.838 30.2525 156.692 28.5218 152.367 28.5176ZM152.367 59.161C149.571 59.1618 146.838 58.328 144.513 56.765C142.188 55.2021 140.375 52.9801 139.305 50.3803C138.234 47.7804 137.954 44.9194 138.499 42.159C139.044 39.3987 140.39 36.863 142.366 34.8727C144.343 32.8824 146.862 31.5268 149.604 30.9775C152.346 30.4282 155.189 30.7097 157.772 31.7865C160.355 32.8633 162.563 34.687 164.117 37.027C165.67 39.367 166.499 42.1181 166.499 44.9324C166.495 48.7041 165.005 52.3201 162.356 54.9874C159.707 57.6548 156.115 59.1557 152.367 59.161Z" fill="black"/>
<path d="M114.9 72.9386C114.794 72.9386 114.692 72.8962 114.617 72.8207C114.542 72.7452 114.5 72.6428 114.5 72.536V71.7307C114.5 71.624 114.542 71.5216 114.617 71.446C114.692 71.3705 114.794 71.3281 114.9 71.3281C115.006 71.3281 115.108 71.3705 115.183 71.446C115.258 71.5216 115.3 71.624 115.3 71.7307V72.536C115.3 72.6428 115.258 72.7452 115.183 72.8207C115.108 72.8962 115.006 72.9386 114.9 72.9386Z" fill="#1487E3"/>
<path d="M108.672 62.5352C108.304 62.2976 108.017 61.9537 107.848 61.5488C107.806 61.4505 107.727 61.3731 107.628 61.3334C107.529 61.2938 107.419 61.2952 107.322 61.3374C107.224 61.3796 107.147 61.459 107.108 61.5583C107.068 61.6576 107.07 61.7686 107.112 61.8668C107.347 62.4167 107.738 62.8845 108.236 63.2116C108.3 63.2534 108.375 63.2758 108.452 63.276C108.538 63.2767 108.623 63.249 108.692 63.1972C108.762 63.1454 108.813 63.0722 108.838 62.9887C108.863 62.9052 108.86 62.8158 108.83 62.734C108.8 62.6523 108.744 62.5825 108.672 62.5352Z" fill="#1487E3"/>
<path d="M107.276 57.5074C107.382 57.5074 107.484 57.465 107.559 57.3895C107.634 57.314 107.676 57.2116 107.676 57.1048V55.5628C107.676 55.456 107.634 55.3536 107.559 55.2781C107.484 55.2026 107.382 55.1602 107.276 55.1602C107.17 55.1602 107.068 55.2026 106.993 55.2781C106.918 55.3536 106.876 55.456 106.876 55.5628V57.1048C106.876 57.2116 106.918 57.314 106.993 57.3895C107.068 57.465 107.17 57.5074 107.276 57.5074Z" fill="#1487E3"/>
<path d="M111.44 62.8906H109.904C109.798 62.8906 109.696 62.933 109.621 63.0085C109.546 63.0841 109.504 63.1865 109.504 63.2932C109.504 63.4 109.546 63.5024 109.621 63.5779C109.696 63.6534 109.798 63.6959 109.904 63.6959H111.44C111.546 63.6959 111.648 63.6534 111.723 63.5779C111.798 63.5024 111.84 63.4 111.84 63.2932C111.84 63.1865 111.798 63.0841 111.723 63.0085C111.648 62.933 111.546 62.8906 111.44 62.8906Z" fill="#1487E3"/>
<path d="M107.676 60.1908V58.6487C107.676 58.5419 107.634 58.4395 107.559 58.364C107.484 58.2885 107.382 58.2461 107.276 58.2461C107.17 58.2461 107.068 58.2885 106.993 58.364C106.918 58.4395 106.876 58.5419 106.876 58.6487V60.1908C106.876 60.2975 106.918 60.3999 106.993 60.4754C107.068 60.551 107.17 60.5934 107.276 60.5934C107.382 60.5934 107.484 60.551 107.559 60.4754C107.634 60.3999 107.676 60.2975 107.676 60.1908Z" fill="#1487E3"/>
<path d="M115.276 65.5059C115.262 65.4002 115.207 65.3044 115.123 65.2395C115.039 65.1746 114.933 65.1459 114.828 65.1596C114.776 65.1684 114.726 65.1874 114.681 65.2157C114.636 65.244 114.598 65.2809 114.567 65.3243C114.537 65.3677 114.515 65.4168 114.503 65.4687C114.492 65.5206 114.491 65.5743 114.5 65.6266C114.513 65.72 114.519 65.8142 114.52 65.9085V67.1163C114.52 67.2231 114.562 67.3255 114.637 67.401C114.712 67.4765 114.814 67.519 114.92 67.519C115.026 67.519 115.128 67.4765 115.203 67.401C115.278 67.3255 115.32 67.2231 115.32 67.1163V65.9085C115.314 65.7734 115.299 65.639 115.276 65.5059Z" fill="#1487E3"/>
<path d="M114.9 68.2422C114.794 68.2422 114.692 68.2846 114.617 68.3601C114.542 68.4356 114.5 68.538 114.5 68.6448V70.1909C114.5 70.2977 114.542 70.4001 114.617 70.4756C114.692 70.5511 114.794 70.5935 114.9 70.5935C115.006 70.5935 115.108 70.5511 115.183 70.4756C115.258 70.4001 115.3 70.2977 115.3 70.1909V68.6448C115.3 68.538 115.258 68.4356 115.183 68.3601C115.108 68.2846 115.006 68.2422 114.9 68.2422Z" fill="#1487E3"/>
<path d="M114.552 63.9074C114.16 63.4572 113.643 63.136 113.068 62.9854C113.017 62.9722 112.964 62.9692 112.912 62.9766C112.86 62.984 112.81 63.0017 112.764 63.0286C112.719 63.0556 112.679 63.0912 112.648 63.1335C112.616 63.1758 112.593 63.224 112.58 63.2753C112.567 63.3266 112.564 63.38 112.571 63.4324C112.579 63.4849 112.596 63.5353 112.623 63.5809C112.65 63.6266 112.685 63.6664 112.727 63.6983C112.769 63.7301 112.817 63.7533 112.868 63.7665C113.288 63.8757 113.666 64.11 113.952 64.4389C113.99 64.4818 114.036 64.5162 114.087 64.5398C114.139 64.5635 114.195 64.5757 114.252 64.5758C114.329 64.5757 114.404 64.5533 114.469 64.5113C114.533 64.4693 114.584 64.4094 114.616 64.3389C114.648 64.2684 114.659 64.1902 114.647 64.1137C114.636 64.0372 114.603 63.9655 114.552 63.9074Z" fill="#1487E3"/>
<path d="M107.276 54.4015C107.17 54.4015 107.068 54.3591 106.993 54.2836C106.918 54.2081 106.876 54.1057 106.876 53.9989V53.1936C106.876 53.0869 106.918 52.9844 106.993 52.9089C107.068 52.8334 107.17 52.791 107.276 52.791C107.382 52.791 107.484 52.8334 107.559 52.9089C107.634 52.9844 107.676 53.0869 107.676 53.1936V53.9989C107.676 54.1057 107.634 54.2081 107.559 54.2836C107.484 54.3591 107.382 54.4015 107.276 54.4015Z" fill="#1487E3"/>
<path d="M133.848 93.2109H41.2319V94.7047H133.848V93.2109Z" fill="#1487E3"/>
<path d="M133.848 94.7031H153.944V93.2094H133.848V94.7031Z" fill="#1487E3"/>
<path opacity="0.2" d="M133.848 94.7031H153.944V93.2094H133.848V94.7031Z" fill="black"/>
<path d="M147.232 96.3015H47.944L44.624 94.7031H150.552L147.232 96.3015Z" fill="#1487E3"/>
<path opacity="0.6" d="M147.232 96.3015H47.944L44.624 94.7031H150.552L147.232 96.3015Z" fill="white"/>
<path d="M50.9438 96.3027V143.949H52.2158L53.8158 96.3027H50.9438Z" fill="#1487E3"/>
<path d="M65.96 96.3027V143.949H67.232L68.832 96.3027H65.96Z" fill="#1487E3"/>
<path d="M147.232 96.3027V143.949H145.964L144.36 96.3027H147.232Z" fill="#1487E3"/>
<path d="M132.22 96.3027V143.949H130.948L129.348 96.3027H132.22Z" fill="#1487E3"/>
<path d="M101.996 75.125L97.8162 92.2646L97.7002 93.2107H126.532L126.652 92.2646L130.828 75.125H101.996Z" fill="#1487E3"/>
<path opacity="0.3" d="M101.996 75.125L97.8162 92.2646L97.7002 93.2107H126.532L126.652 92.2646L130.828 75.125H101.996Z" fill="black"/>
<path d="M129.944 75.125H101.108L96.9321 92.2646H125.764L129.944 75.125Z" fill="#1487E3"/>
<path d="M125.152 91.4795L128.952 75.9102H101.72L97.9238 91.4795H125.152Z" fill="white"/>
<path d="M118.252 92.2637L118.132 93.2098H86.3838L86.4998 92.2637H118.252Z" fill="#1487E3"/>
<path opacity="0.6" d="M118.252 92.2637L118.132 93.2098H86.3838L86.4998 92.2637H118.252Z" fill="white"/>
<path d="M58.2757 90.095C58.2556 89.4817 58.017 88.8962 57.6036 88.4453C57.1901 87.9944 56.6294 87.7082 56.0237 87.639V87.176C56.0232 87.1492 56.0174 87.1228 56.0066 87.0983C55.9958 87.0738 55.9803 87.0517 55.9609 87.0333C55.9415 87.0149 55.9187 87.0006 55.8938 86.9913C55.8688 86.9819 55.8423 86.9776 55.8157 86.9787H51.4957C51.4689 86.9776 51.4422 86.9819 51.417 86.9912C51.3918 87.0005 51.3687 87.0148 51.3491 87.0331C51.3294 87.0514 51.3135 87.0735 51.3022 87.098C51.291 87.1225 51.2847 87.149 51.2837 87.176V93.2153H56.0237V92.555C56.6304 92.4864 57.1921 92.1999 57.6058 91.748C58.0195 91.2961 58.2574 90.7092 58.2757 90.095ZM56.0237 91.7739V88.4201C56.4234 88.4794 56.7887 88.6814 57.0528 88.9892C57.3168 89.2971 57.4621 89.6903 57.4621 90.097C57.4621 90.5037 57.3168 90.8969 57.0528 91.2047C56.7887 91.5126 56.4234 91.7146 56.0237 91.7739Z" fill="#1487E3"/>
<path d="M139.124 93.2111H143.14L141.952 84.3574H139.124V93.2111Z" fill="#263238"/>
<path d="M133.236 93.2111H140.844L142.256 82.8477H134.652L133.236 93.2111Z" fill="#1487E3"/>
<path opacity="0.3" d="M133.236 93.2111H140.844L142.256 82.8477H134.652L133.236 93.2111Z" fill="black"/>
<path d="M132.22 93.2111H140.136L141.552 82.8477H133.636L132.22 93.2111Z" fill="#1487E3"/>
<path opacity="0.4" d="M139.436 92.3929L140.628 83.6641H134.336L133.144 92.3929H139.436Z" fill="white"/>
<path d="M79.516 77.9107L79.188 78.265L78.836 78.6676C78.6 78.9414 78.364 79.2192 78.14 79.505C77.692 80.0768 77.26 80.6606 76.856 81.2605C76.0522 82.4207 75.3812 83.6687 74.856 84.9807C74.6113 85.6043 74.4213 86.2483 74.288 86.9052C74.224 87.2756 74.184 87.4367 74.164 87.5937C74.1418 87.7189 74.1418 87.847 74.164 87.9721C74.2311 88.169 74.3363 88.3504 74.4737 88.5059C74.611 88.6614 74.7777 88.7879 74.964 88.878C75.234 89.0456 75.5177 89.1897 75.812 89.3089C75.9667 89.3733 76.1267 89.4337 76.292 89.49C76.444 89.5464 76.636 89.6028 76.756 89.6511V91.8695C76.476 91.9098 76.272 91.942 76.036 91.9581C75.8 91.9742 75.572 91.9782 75.336 91.9581C74.8508 91.9483 74.3681 91.8862 73.896 91.7729C73.3641 91.6471 72.8543 91.4408 72.384 91.1609C71.831 90.8352 71.3558 90.3913 70.992 89.8604C70.6174 89.2954 70.3691 88.6552 70.264 87.9842C70.2372 87.8271 70.2199 87.6684 70.212 87.5091C70.2041 87.3562 70.2041 87.203 70.212 87.0501V86.8247C70.2075 86.7644 70.2075 86.7038 70.212 86.6435L70.244 86.2892C70.3463 85.369 70.5393 84.4613 70.82 83.5796C71.3617 81.8858 72.1244 80.272 73.088 78.7803C73.551 78.0479 74.0544 77.3422 74.596 76.6666C74.864 76.3284 75.14 75.9982 75.428 75.6721C75.568 75.507 75.716 75.346 75.868 75.1849C76.02 75.0239 76.16 74.883 76.368 74.6816L79.516 77.9107Z" fill="#B55B52"/>
<path d="M75.96 89.6016L77.824 88.2246L78.884 92.0817C78.884 92.0817 77.232 92.6977 75.972 91.8683L75.96 89.6016Z" fill="#B55B52"/>
<path d="M80.4122 87.2988L81.1402 90.5279L78.8842 92.082L77.8242 88.2249L80.4122 87.2988Z" fill="#B55B52"/>
<path d="M92.2001 63.1105C92.1161 63.348 92.1761 63.5856 92.3321 63.6379C92.4881 63.6902 92.6801 63.5453 92.7601 63.3077C92.8401 63.0702 92.7841 62.8286 92.6321 62.7763C92.4801 62.7239 92.2841 62.8689 92.2001 63.1105Z" fill="#263238"/>
<path d="M92.1558 63.5762C92.187 64.4053 92.331 65.2263 92.5838 66.0161C92.3823 66.0742 92.1708 66.0877 91.9636 66.0557C91.7565 66.0238 91.5587 65.947 91.3838 65.8308L92.1558 63.5762Z" fill="#A02724"/>
<path d="M91.8161 61.492C91.846 61.5021 91.8783 61.5021 91.9081 61.492C92.0836 61.4331 92.2705 61.4169 92.4534 61.4449C92.6363 61.4729 92.81 61.5443 92.9601 61.6531C92.9902 61.675 93.0274 61.6848 93.0643 61.6804C93.1013 61.676 93.1352 61.6578 93.1594 61.6294C93.1836 61.601 93.1963 61.5645 93.1949 61.5271C93.1936 61.4897 93.1783 61.4542 93.1521 61.4276C92.9644 61.2867 92.7458 61.1932 92.5148 61.1546C92.2838 61.1161 92.047 61.1338 91.8241 61.2062C91.7949 61.2162 91.7696 61.2352 91.7517 61.2605C91.7338 61.2857 91.7241 61.316 91.7241 61.3471C91.7241 61.3781 91.7338 61.4084 91.7517 61.4337C91.7696 61.4589 91.7949 61.4779 91.8241 61.488L91.8161 61.492Z" fill="#263238"/>
<path d="M83.428 63.9863C83.876 66.2772 84.32 70.4766 82.728 72.0065C82.728 72.0065 83.352 74.3297 87.584 74.3297C92.236 74.3297 89.808 72.0065 89.808 72.0065C87.268 71.3946 87.332 69.4982 87.776 67.7186L83.428 63.9863Z" fill="#B55B52"/>
<path d="M82.1197 72.8042C81.7837 72.0674 81.4197 71.2259 82.1757 70.9642C83.0117 70.6744 87.5877 70.3764 89.1637 70.92C89.3721 70.977 89.5671 71.0753 89.7372 71.2092C89.9074 71.343 90.0493 71.5096 90.1547 71.6993C90.2602 71.889 90.3269 72.098 90.3511 72.314C90.3753 72.53 90.3565 72.7486 90.2958 72.9572L82.1197 72.8042Z" fill="#263238"/>
<path d="M98.1002 76.7344C99.0802 78.5099 100.132 80.3378 101.196 82.0933C102.26 83.8487 103.376 85.5961 104.568 87.1904C104.864 87.5931 105.172 87.9755 105.476 88.358C105.628 88.5553 105.788 88.7164 105.944 88.8975L106.176 89.1633L106.236 89.2317L106.264 89.2639L106.292 89.3002C106.479 89.4816 106.703 89.6191 106.948 89.7028C107.326 89.8352 107.716 89.9309 108.112 89.9887C110.057 90.1862 112.017 90.1633 113.956 89.9202L114.564 92.0541C114.024 92.2957 113.52 92.4567 112.984 92.658C112.448 92.8593 111.92 92.9922 111.384 93.1291C110.248 93.416 109.083 93.5726 107.912 93.5961C107.255 93.6068 106.598 93.5406 105.956 93.3989C105.168 93.2413 104.424 92.9084 103.78 92.4245L103.512 92.2031L103.448 92.1467L103.408 92.1064L103.332 92.0299L103.02 91.7199C102.816 91.5146 102.592 91.3173 102.408 91.0999C102.008 90.6771 101.632 90.2544 101.284 89.8195C99.8867 88.1197 98.5974 86.3325 97.4242 84.4687C96.8402 83.5507 96.2775 82.6234 95.7362 81.6866C95.1922 80.7405 94.6682 79.8144 94.1602 78.8159L98.1002 76.7344Z" fill="#B55B52"/>
<path d="M77.1561 72.7169C74.2361 73.3893 70.8921 81.3974 70.8921 81.3974L76.7521 85.4236C78.2096 83.3199 79.5028 81.1055 80.6201 78.8005C82.3521 75.1528 80.1401 72.0284 77.1561 72.7169Z" fill="#1487E3"/>
<path opacity="0.2" d="M79.2441 77.8203L76.4761 85.2446L76.7481 85.4379C76.7481 85.4379 77.9481 83.7429 79.2441 81.456V77.8203Z" fill="black"/>
<path d="M94.5958 72.8743C94.5958 72.8743 97.9078 76.6428 94.7798 99.9546H78.1278C78.0158 97.3818 79.6198 84.8362 77.1558 72.7133C78.996 72.3566 80.8572 72.1199 82.7278 72.0046C85.0857 71.8656 87.4498 71.8656 89.8078 72.0046C91.4239 72.1684 93.0247 72.4592 94.5958 72.8743Z" fill="#1487E3"/>
<path opacity="0.2" d="M92.7441 79.6172C93.1786 82.2631 94.3145 84.7418 96.0321 86.7919C96.1001 85.2901 96.1281 83.9413 96.1241 82.7375L92.7441 79.6172Z" fill="black"/>
<path d="M91.3001 75.4192C90.9001 78.3865 96.7001 85.9639 96.7001 85.9639L102.536 81.9376C102.536 81.9376 100.916 77.7625 98.192 75.0246C95.088 71.8842 91.7561 72.1902 91.3001 75.4192Z" fill="#1487E3"/>
<path d="M113.3 89.9394L115.676 88.957L118.12 90.8171C118.12 90.8171 115.788 92.8947 113.668 92.0854L113.3 89.9394Z" fill="#B55B52"/>
<path d="M117.244 88.8613L119.036 90.1497L118.108 90.8181L115.664 88.958L117.244 88.8613Z" fill="#B55B52"/>
<path d="M84.4639 59.8319C83.6319 62.8274 82.9919 64.5386 83.9439 66.5557C85.3719 69.5955 89.4319 69.5109 91.1439 66.8295C92.6799 64.4138 93.7839 59.9205 91.3359 57.7584C90.7984 57.2779 90.1478 56.9437 89.4459 56.7875C88.7439 56.6313 88.014 56.6584 87.3254 56.8662C86.6368 57.074 86.0124 57.4555 85.5116 57.9746C85.0107 58.4937 84.6501 59.133 84.4639 59.8319Z" fill="#B55B52"/>
<path d="M90.8001 59.8646C89.6001 63.303 88.1881 67.0796 84.7401 66.3629C82.5161 65.8999 82.3401 62.2965 81.5401 60.6578C80.8321 59.2204 80.8761 56.3296 82.3401 56.2934C81.6841 55.3351 85.0001 51.8283 87.2641 53.6804C87.8441 52.8751 92.0241 50.5037 95.1201 54.2682C98.0361 57.8274 96.6081 60.7947 90.8001 59.8646Z" fill="#263238"/>
<path d="M88.0998 62.0635C88.0197 62.8315 88.1911 63.6048 88.5878 64.2658C89.1198 65.1274 89.8998 64.7973 90.1878 63.9155C90.4438 63.1103 90.4718 61.7212 89.7318 61.1616C88.9918 60.6019 88.2118 61.1374 88.0998 62.0635Z" fill="#B55B52"/>
<path d="M113.652 141.674C114.052 141.674 114.452 141.617 114.568 141.436C114.601 141.388 114.619 141.331 114.619 141.273C114.619 141.215 114.601 141.158 114.568 141.11C114.515 141.025 114.432 140.963 114.336 140.937C113.816 140.796 112.736 141.42 112.684 141.448C112.671 141.455 112.66 141.466 112.653 141.48C112.647 141.494 112.645 141.51 112.648 141.525C112.651 141.539 112.657 141.552 112.667 141.563C112.677 141.574 112.69 141.581 112.704 141.585C113.017 141.642 113.334 141.672 113.652 141.674ZM114.148 141.066C114.195 141.059 114.242 141.059 114.288 141.066C114.318 141.071 114.347 141.084 114.372 141.102C114.397 141.12 114.417 141.143 114.432 141.17C114.484 141.263 114.468 141.307 114.432 141.331C114.288 141.537 113.5 141.541 112.92 141.456C113.3 141.247 113.718 141.114 114.148 141.066Z" fill="#1487E3"/>
<path d="M112.712 141.583H112.744C113.12 141.414 113.864 140.738 113.804 140.375C113.804 140.291 113.732 140.19 113.532 140.166C113.46 140.16 113.388 140.168 113.319 140.19C113.25 140.212 113.187 140.248 113.132 140.295C112.732 140.621 112.652 141.467 112.648 141.503C112.646 141.516 112.648 141.529 112.653 141.541C112.658 141.553 112.666 141.563 112.676 141.571C112.687 141.578 112.699 141.582 112.712 141.583ZM113.472 140.315H113.516C113.648 140.315 113.656 140.384 113.66 140.4C113.696 140.613 113.188 141.144 112.804 141.374C112.836 141.019 112.976 140.683 113.204 140.412C113.279 140.348 113.374 140.313 113.472 140.315Z" fill="#1487E3"/>
<path d="M109.092 141.511H112.204L112.912 134.252H109.8L109.092 141.511Z" fill="#B55B52"/>
<path d="M112.38 141.148H108.984C108.921 141.149 108.861 141.17 108.812 141.21C108.764 141.249 108.73 141.304 108.716 141.366L108.1 144.136C108.084 144.203 108.084 144.274 108.099 144.341C108.114 144.409 108.145 144.472 108.188 144.526C108.231 144.58 108.286 144.623 108.348 144.653C108.411 144.682 108.479 144.697 108.548 144.696C109.772 144.675 111.544 144.603 113.084 144.603C114.888 144.603 116.444 144.7 118.556 144.7C119.836 144.7 120.192 143.399 119.656 143.282C117.22 142.747 115.232 142.69 113.128 141.382C112.905 141.236 112.646 141.155 112.38 141.148Z" fill="#263238"/>
<path opacity="0.2" d="M112.908 134.256L112.548 137.996H109.432L109.796 134.256H112.908Z" fill="black"/>
<path d="M101.176 141.67C101.308 141.687 101.442 141.678 101.571 141.643C101.699 141.607 101.819 141.547 101.924 141.464C101.961 141.414 101.981 141.352 101.981 141.289C101.981 141.226 101.961 141.165 101.924 141.114C101.9 141.07 101.867 141.032 101.827 141.002C101.787 140.972 101.741 140.951 101.692 140.941C101.236 140.832 100.376 141.424 100.34 141.448C100.329 141.457 100.32 141.469 100.314 141.482C100.309 141.496 100.307 141.51 100.308 141.525C100.311 141.539 100.318 141.551 100.328 141.561C100.338 141.572 100.35 141.578 100.364 141.581C100.631 141.64 100.903 141.669 101.176 141.67ZM101.576 141.074C101.605 141.07 101.635 141.07 101.664 141.074C101.693 141.078 101.72 141.089 101.744 141.106C101.767 141.123 101.787 141.145 101.8 141.171C101.868 141.295 101.828 141.344 101.8 141.364C101.668 141.537 101.032 141.541 100.564 141.464C100.865 141.259 101.208 141.126 101.568 141.074H101.576Z" fill="#1487E3"/>
<path d="M100.384 141.584H100.416C100.74 141.407 101.364 140.722 101.296 140.376C101.286 140.321 101.257 140.271 101.214 140.234C101.17 140.198 101.116 140.179 101.06 140.179C101.004 140.172 100.948 140.177 100.894 140.194C100.84 140.21 100.79 140.238 100.748 140.275C100.388 140.573 100.312 141.483 100.308 141.515C100.308 141.529 100.311 141.542 100.317 141.554C100.324 141.566 100.333 141.576 100.344 141.584C100.357 141.588 100.371 141.588 100.384 141.584ZM101.012 140.316H101.044C101.14 140.316 101.148 140.372 101.152 140.392C101.192 140.597 100.792 141.117 100.472 141.362C100.494 141.005 100.622 140.663 100.84 140.38C100.888 140.339 100.949 140.316 101.012 140.316Z" fill="#1487E3"/>
<path d="M96.8159 141.511H99.9279L101.492 134.252H98.3799L96.8159 141.511Z" fill="#B55B52"/>
<path d="M100.148 141.149H96.6519C96.5898 141.149 96.5298 141.171 96.4818 141.21C96.4338 141.25 96.4006 141.305 96.3879 141.366L95.7719 144.136C95.7574 144.204 95.7584 144.274 95.7747 144.342C95.791 144.41 95.8222 144.473 95.866 144.526C95.9099 144.58 95.9651 144.623 96.0278 144.653C96.0904 144.682 96.1588 144.697 96.2279 144.696C97.4479 144.676 98.0439 144.603 99.5799 144.603C100.528 144.603 101.74 144.7 103.048 144.7C104.356 144.7 104.524 143.399 103.988 143.283C101.588 142.755 101.424 142.026 100.656 141.33C100.514 141.209 100.333 141.145 100.148 141.149Z" fill="#263238"/>
<g opacity="0.2">
<path d="M98.3798 134.256H101.492L100.684 137.996H97.5718L98.3798 134.256Z" fill="black"/>
</g>
<path d="M94.7798 99.957C94.7798 99.957 118.1 102.441 117.844 114.415C117.679 121.935 116.242 129.372 113.596 136.406H108.972C108.972 136.406 109.208 128.809 109.052 116.549C109.004 112.873 101.632 111.661 94.4278 111.234C91.3772 111.041 88.4408 109.994 85.9501 108.21C83.4594 106.427 81.5135 103.978 80.3318 101.141L79.8398 99.957H94.7798Z" fill="#263238"/>
<path d="M114.728 135.158C114.752 135.158 114.428 137.361 114.428 137.361H108.028L107.852 135.396L114.728 135.158Z" fill="#1487E3"/>
<path d="M89.788 99.957C89.788 99.957 106.692 105.594 107.12 112.583C107.92 125.443 101.636 136.406 101.636 136.406H96.968C96.968 136.406 98.74 130.971 99.368 119.319C100.168 104.684 73.036 119.295 78.12 99.957H89.788Z" fill="#263238"/>
<path d="M103.048 135.158C103.072 135.158 102.46 137.361 102.46 137.361H96.0601L96.1761 135.396L103.048 135.158Z" fill="#1487E3"/>
<path d="M101.98 112.286H75.98C75.98 111.494 76.2927 110.734 76.8493 110.174C77.4059 109.614 78.1608 109.299 78.948 109.299H99.012C99.7991 109.299 100.554 109.614 101.111 110.174C101.667 110.734 101.98 111.494 101.98 112.286Z" fill="#1487E3"/>
<path d="M77.1842 112.281L73.2642 145.361H74.1042L79.0762 112.281H77.1842Z" fill="#1487E3"/>
<path opacity="0.4" d="M77.1842 112.281L73.2642 145.361H74.1042L79.0762 112.281H77.1842Z" fill="black"/>
<path d="M82.4281 112.281L82.3521 145.361H83.188L84.32 112.281H82.4281Z" fill="#1487E3"/>
<path opacity="0.4" d="M82.4281 112.281L82.3521 145.361H83.188L84.32 112.281H82.4281Z" fill="black"/>
<path d="M100.776 112.281L104.696 145.361H103.856L98.8877 112.281H100.776Z" fill="#1487E3"/>
<path opacity="0.4" d="M100.776 112.281L104.696 145.361H103.856L98.8877 112.281H100.776Z" fill="black"/>
<path d="M95.5321 112.281L95.6081 145.361H94.7721L93.6401 112.281H95.5321Z" fill="#1487E3"/>
<path opacity="0.4" d="M95.5321 112.281L95.6081 145.361H94.7721L93.6401 112.281H95.5321Z" fill="black"/>
</svg>
`
const collapseArrow = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2427 14.5942C16.01 14.8202 15.6398 14.8204 15.4069 14.5946L12.4177 11.6964C12.1849 11.4708 11.8151 11.4708 11.5823 11.6964L8.59311 14.5946C8.3602 14.8204 7.98999 14.8202 7.75733 14.5942L7.44287 14.2886C7.20037 14.053 7.20037 13.6636 7.44287 13.428L11.5819 9.40627C11.8147 9.18003 12.1853 9.18003 12.4181 9.40627L16.5571 13.428C16.7996 13.6636 16.7996 14.053 16.5571 14.2886L16.2427 14.5942Z" fill="#0D2C54"/>
</svg>
`
const skillArrow = `<svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.48069 4.93045C5.6472 6.98458 7.65298 8.90813 9.69094 10.8053C9.85168 10.9359 10.1477 10.9032 10.7202 11.0112C10.0263 9.90129 9.54452 8.98472 8.93398 8.17362C7.30154 6.04918 5.63044 4.00886 3.93362 1.93715C3.72795 1.68604 3.55447 1.40856 3.2844 1.21018C2.455 0.525886 1.67634 0.691622 1.29584 1.73752C1.12168 2.24728 1.00543 2.76207 0.947082 3.28188C0.726651 5.24561 0.564117 7.21436 0.401588 9.18311C0.362688 9.52965 0.497491 9.89126 0.580655 10.4525C2.2739 9.17055 1.41342 7.20055 2.41832 5.80058C2.58532 6.13582 2.75881 6.4133 2.87439 6.68576C7.43366 17.1121 14.607 25.3449 23.815 31.8589C24.7602 32.5532 25.8085 33.1107 26.8245 33.6945C27.3454 34.0021 28.0081 34.0888 28.3497 33.3894C28.717 32.7214 28.3571 32.282 27.7204 31.9643C20.543 28.455 15.3685 22.7283 10.5288 16.6224C7.9901 13.4571 5.8247 10.0909 4.07133 6.43966C3.85939 5.98389 3.67966 5.50174 3.48069 4.93045Z" fill="#1487E3"/>
</svg>
`