/** imported components

  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * Link for ==> for render anchor.
  * Modal for ==> for popup.

**/
import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { Link } from "react-router-dom";
import { Modal } from "@material-ui/core";
// QuizWalkthrough Start
export const SkillQuizWalkthrough = (props) => {

  const [isModal,setIsModal]=useState(true);
  const user = JSON.parse(localStorage.getItem("userData"));
  const urlElements = window.location.href.split("#");
  const hashvalue = urlElements[1];

  const uid = user ? user.uid : 0;
  const HandleClose=()=>{
    setIsModal(false);
    props.setISWalkthrough(0);
    serviceProvider.post("/home?type=skill_assessment2", JSON.stringify({ uid: uid }), true).then((response) => { });
  }
  useEffect(() => {
        document.querySelector('.training-main-wrapper')?.classList.add('quiz-overlay');

        return()=>{
            document.querySelector('.training-main-wrapper')?.classList.remove('quiz-overlay');
        }
      }, []);
  return (
    <>
      <div
        className="course-wt-bg"
        style={{
          background: `url(${
            require("../../assets/img/common-img/quiz-walkthrough-bg.svg")
              .default
          })`,
        }}
      >
        {/* single-walkthrough-modal start */}
        <Modal open={isModal} className="single-walkthrough-modal skill-walkthrough-modal">
          <div className="single-wlakthrough-content">
            <button onClick={()=>HandleClose()} className="clsoe-btn">
              <img
                src={
                  require("../../assets/img/common-img/close-icon-gray.svg")
                    .default
                }
                alt="X"
              />
            </button>
            <div className="card">
              <div className="div-img">
                <img
                  src={
                    require("../../assets/img/common-img/quiz-wlk-img.svg")
                      .default
                  }
                  alt="true"
                />
              </div>
              {/* div-img end */}
              <div className="div-txt">
                <div className="card-content">
                  <h2 className="heading-h1">Welcome to the Assessment</h2>
                  <p className="para-p">
                   Successfully complete the assessment by answering the questionnaire to get your score. 
                  </p>
                </div>
                <div className="card-footer">
                  <button
                    className="btn-got-it"
                    onClick={()=>HandleClose()}
                  >
                    Got it
                  </button>
                </div>
              </div>
              {/* div-img end */}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
