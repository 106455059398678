/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * QuickStartView ===>  custom view component

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { QuickStartView } from "./quickstart-view";
//QuickStart component start 
export const QuickStart = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const [tourComplete, setTourComplete] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		const search = async () => {
			const apiUrl = `/home?param=homedata&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, [tourComplete]);

	return (
		<>
			{/* custom view compoent */}
			<QuickStartView homeData={results} onTourComplete={setTourComplete} {...props} />
		</>
	);
};
