import styled from "styled-components";

export const SiriMainWrapper = styled.div`
    padding: 3rem;
   
    .md-block{
        display:none;
    }
    .sm-block{
        display:none;
    }
    @media(max-width: 740px){
        padding: 65px 16px 75px 16px;
        .md-block{
            display:block;
        }
        .md-none{
            display:none;
        }
    }
    @media(max-width:575px){
        padding: 65px 16px 75px 16px;
        .sm-block{
            display:block !important;
        }
        .sm-none{
            display:none !important;
        }
    }
`
export const SiriPageTitle = styled.h1`
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #4D4D4F;
    margin-bottom: 3.2rem;
    display:flex;
    align-items:center;
    .company-profile-link{
        margin-left:auto;
        font-size:1.4rem;
        border-radius:0.5rem;
        padding:0.8rem 1.6rem;
        background: linear-gradient(91.83deg,#139BD6 12.22%,#0789CF 100.41%);
        color:#fff;
        display:inline-flex;
        align-items:center;
        justify-content:center;
    }
    @media(max-width: 740px){
        display: none;
    }
`
export const SiriAssessementWrapper = styled.div`
    display: flex;
    gap: 3rem;
    @media(max-width: 740px){
        flex-direction: column;
        padding-top: 0;
    }
`
export const SiriAsmtLeftWrap = styled.div`
    width: calc(60% - 1.5rem);
    @media(max-width: 740px){
        order: 2;
        width: 100%;
    }
`
export const SiriAsmtRightWrap = styled.div`
    width: calc(40% - 1.5rem);
    .company-profile-link{
        margin-left:auto;
        font-size:1.4rem;
        border-radius:0.5rem;
        padding:0.8rem 1.6rem;
        background: linear-gradient(91.83deg,#139BD6 12.22%,#0789CF 100.41%);
        color:#fff;
        display:none;
        align-items:center;
        justify-content:center;
        @media(max-width:740px){
            display:inline-flex;
            margin-bottom:15px;
        }
    }
    @media(min-width:740px){
        margin-bottom:auto;
        position:sticky;
        top:0px;
    }
    @media(max-width: 740px){
        order: 1;
        max-width:545px;
        width:auto;
        position:static;
    }
`
export const SiriCardWrap = styled.div`
    width: 100%;
`
export const SiriCard = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 0.4rem rgba(0, 0, 0, 0.12);
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1.6rem 0.8rem 0.8rem;
    margin-bottom: 1.6rem;
    .icon-svg{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 3.2rem;
        width: 3.2rem;
        @media(max-width: 740px){
            height: 24px;
            width: 24px;
        }
    }
    @media(max-width: 740px){
        padding: 8px;
    }
    @media(max-width: 575px){
       flex-wrap:wrap;
       gap:12px;
       color: #4d4d4f;
       .dimension-name {
        font-size:14px;
        font-weight:600;
       }
    }

`
export const CardLeftSec = styled.div`
    display: flex;
    width: 65%;
    padding-right: 1rem;
    .count{
        background: linear-gradient(91.83deg, #139BD6 12.22%, #0789CF 100.41%);
        border-radius: 0.6rem;
        min-width: 4.8rem;
        height: 4.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 1.6rem;
        color: #FFFFFF;
        margin-right: 1.5rem;
        @media(max-width: 740px){
            height: 48px;
            width: 48px;
            font-size: 14px;
        }
       
    }
    @media(max-width: 740px){
        width: 65%;
    }
    @media(max-width: 575px){
        width: 100%;
    }
`
export const TextWrap = styled.div`
    .dimension-count{
        display: block;
        margin-bottom: 0.7rem;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #7D868C;
        @media(max-width: 740px){
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
    .dimension-name{
        display: block;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #4D4D4F;
        @media(max-width: 740px){
            font-size: 14px;
            line-height: 17px;
        }
    }
    .sm-text{
        display: none;
    }
    @media(max-width: 575px){
        .md-text{
            display: none;
        }
        .sm-text{
            display: block;
        }
    }
`
export const CardRightSec = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    button{
        border: 0;
        background: transparent;
    }
    button:focus{
        outline: none;
    }
    .sm-text{
        display: none;
    }
    @media(max-width: 575px){
        width: 100%;
        .md-text{
            display: none;
        }
        .sm-text{
            display: flex;
        }
    }
`
export const StatusTab = styled.div`
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: ${({ status }) => `${status === 'todo' ? '#DB324D' 
                            : status === 'inprogress' ? '#EF6C00'
                            : status === 'validated' ? 'rgba(76, 175, 80, 1)'
                            : 'DB324D' 
                            }`};
    background: #FFFFFF;
    border: 0.1rem solid;
    border-color: ${({ status }) => `${status === 'todo' ? '#F1B1BB' 
                                    : status === 'inprogress' ? 'rgba(239, 108, 0, 0.6)'
                                    : status === 'validated' ? 'rgba(76, 175, 80, 0.6)'
                                    : '#F1B1BB' 
                                    }`};
    border-radius: 1.5rem;
    padding: 0.3rem 1rem;
    max-height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    @media(max-width: 740px){
        font-size: 12px;
    }
`
export const TopProgressBar = styled.div`
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(77, 77, 79, 0.03);
    border-radius: 0.8rem;
    margin-bottom: 3rem;
    @media(max-width: 740px){
        padding: 14px 12px;
        margin-bottom: 24px;
    }
    @media(max-width: 575px){
        margin-bottom: 0px;
    }
`
export const ProgressBarSection = styled.div``
export const HeaderProgress = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    .progress-title{
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #0d2c54;
        margin-right: 0.5rem;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 15px;
        }
    }
    h2{
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 0;
        text-align: right;
        color: #4d4d4f;
        width: 7.5rem;
        padding-right: 0.4rem;
        white-space: nowrap;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 15px;
            width: 40px;
        }
    }
`
export const HeaderProgressBar = styled.div`
    flex: 1 1;
    border: 0.1rem solid #cbcfd2;
    position: relative;
    background: #fff;
    height: 1.3rem;
    border-radius: 2rem;
    @media(max-width: 740px){
        height: 8px;
        border-radius: 20px;
    }
`
export const ProgressBar = styled.div`
    display: block;
    position: absolute;
    border-radius: 2rem;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(91.83deg, #139bd6 12.22%, #0789cf 100.41%);
    width: ${({ value }) => `${value}%`};
    @media(max-width: 740px){
        border-radius: 20px;
    }
`
export const SubmitSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-top: 1.537rem;
    align-items: center;
    .heading{
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #0d2c54;
        padding-right: 1rem;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 15px;
        }
    }
    .count{
        color: #4d4d4f;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 15px;
        }
    }
`
export const AssessmentSubmissionSection = styled.div`
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(77, 77, 79, 0.03);
    border-radius: 0.8;
    padding: 2rem 2.4rem 4rem 2.4rem;
    display:${({active})=>active?'block':'none'};
    @media(max-width: 740px){
        padding: 14px 12px;
    }
`
export const AsmtTopSec = styled.div`
    border-bottom: 0.04rem solid #cbcfd2;
    padding-bottom: 1.4rem;
    .progress-text{
        color: #0d2c54;
        display: block;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.9rem;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 15px;
        }
    }
    @media(max-width: 740px){
        padding-bottom: 14px;
    }
`
export const AsmtBottomSec = styled.div`
    padding-bottom: 2.4rem;
    padding-top: 1.6rem;
    p{
        font-weight: normal;
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: #4d4d4f;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 15px;
        }
    }
    @media(max-width: 740px){
        padding-top: 14px;
        padding-bottom: 14px;
    }
`
export const ButtonSec = styled.div`
    text-align: right;
    .download-btn{
        background: #118ACB;
        border: 0.1rem solid #118ACB;
        border-radius: 0.4rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.9rem;
        color: #ffffff;
        padding: 1rem 2rem;
        min-width: 22.9rem;
        &:hover{
            background: linear-gradient(91.83deg, #118ACB 12.22%, #0473AE 100.41%);
        }
        @media(max-width: 740px){
            min-height: 31px;
            min-width: 166px;
            font-size: 12px;
            padding: 6px 12px;
        }
    }
`
export const ValidatePopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 99;
`
export const ValidatePopWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    @media(max-width: 740px){
        width: calc(100% - 16px);
    }

`
export const ValidatePopCard = styled.div`
    background: #FFFFFF;
    border: 0.1rem solid #118ACB;
    box-shadow: 0px 0px 0.8rem rgba(21, 27, 38, 0.12);
    border-radius: 0.6rem;
    padding: 4.4rem 2.4rem;
    min-width: 63rem;
    @media(max-width: 740px){
        min-width: unset;
        width: 100%;
        padding: 24px 12px;
    }
    h3{
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: #0D2C54;
        text-align: center;
        margin-bottom: 1.6rem;
        @media(max-width: 740px){
            font-size: 14px;
            line-height: 28px;
        }
    }
    p{
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #4D4D4F;
        margin-bottom: 4rem;
        text-align: center;
        @media(max-width: 740px){
            font-size: 14px;
            line-height: 22px;
        }
    }
`
export const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;

    .cancel-btn{
        font-weight: 500;
        font-size: 1.5rem;
        min-width: 15rem;
        min-height: 4.4rem;
        background: transparent;
        color: #118acb;
        border-radius: 0.4rem;
        border: 0.1rem solid #118acb;
        margin-right: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 26px;
            min-width: 88px; 
            height:44px;
            margin-right: 16px;
        }
    }
    .validate-btn{
        font-weight: 500;
        font-size: 1.5rem;
        min-width: 15rem;
        min-height: 4.4rem;
        background: #118ACB;
        border-radius: 0.4rem;
        border: 0.1rem solid #118acb;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 740px){
            font-size: 12px;
            line-height: 26px;
            min-width: 120px; 
            height: 44px;
        }
    }

`
export const SiriMobileTitleContainer = styled.div`
    display: none;
    padding: 8px 12px;
    align-items: center;
    background: #E5F0FF;
    position: fixed;
    top: 50px;
    height: 48px;
    left: 0;
    width: 100%;
    z-index: 2;
    .back-icon{
        border: 0;
        box-shadow: unset;
        height: 24px;
        width: 24px;
    }
    .back-icon:focus{
        outline: 0;
    }
    span{
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #0D2C54;
    }
    @media(max-width: 740px){
        display: flex;
    }
    @media(max-width: 700px){
        top: 59px;
    }

`