/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * TrainingWalkthrough ===> custom view component

**/
import React, { useState, useEffect } from "react";
import { TrainingWalkthrough } from "./training-walkthrough-view";
//TrainingWalkthroughController start
export const TrainingWalkthroughController = (props) => {
	//defining variables here
	const [open, setOpen] = useState(true);
	//function to handle open
	const handleOpen = () => {
		setOpen(true);
	};
	//function to handle close
	const handleClose = () => {
		setOpen(false);
	};
	return <TrainingWalkthrough open={open} handleOpen={handleOpen} handleClose={handleClose} {...props} />;
};
