/** imported components

  * makeStyles for ===> styling material-ui component.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * D3 for ==> using D3.
  * models for ==> To create data model.
  * PowerBIEmbed for ==> Power BI React component.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.

**/
import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Collapse, Modal } from "@material-ui/core";
import { Helmet } from "react-helmet";
import * as d3 from "d3";
import { event as currentEvent } from "d3";
import $, { isEmptyObject } from "jquery";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./powerbi.scss";
// PowerBiView start
export const PowerBiView = (props) => {
  const resultsDataItems = props.resultsData;
  if (!resultsDataItems.token) {
    return null;
  }

  return (
    <>
      {/* Page title */}
      <Helmet>
        <title>PowerBI | SmarterChains</title>
        <meta name="description" content="PowerBI" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{resultsDataItems.name}</BreadcrumbsItem>
      <BreadcrumbsItem to={`/loss-analysis-admin/section/${props.plant_slug}`}>IR4.0 {resultsDataItems.title}</BreadcrumbsItem>
      <BreadcrumbsItem to="/#">Result</BreadcrumbsItem>
      {/* View component start */}
      <div>

      </div>
      <div id="pbi_embed0120">
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: resultsDataItems.report_id,
            embedUrl: resultsDataItems.embedUrl,
            accessToken: resultsDataItems.token,
          }}
        />
      </div>
    </>
  );
};
