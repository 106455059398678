/** imported components

  * ManufacturingCostPageView ===> custom view component

**/
import React from "react";
import { ManufacturingCostPageView } from "./manufacturing-cost-page-view";
//ManufacturingCostPageController start
export const ManufacturingCostPageController = (props) => {
  return (
    <>
      {/* custom view component */}
      <ManufacturingCostPageView {...props} />   
    </>
  );
};



