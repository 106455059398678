/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * ProfileView ===> custom view component

**/
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ProfileView } from "./profile-view";
import serviceProvider from '../../services/axios'
import "./profile.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
//CertificateController start
export const SubscriptionController = (props) => {
	//defining variables here
	const [onTab, setOnTab] = useState(2);
	const [results, setResults] = useState([]);
	const [res, setRes] = useState(0);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const progress = user ? user.progress : 0;
    const plant_slug = user ? user.plant_slug : 0;
    const gid = user ? user.group_id : 0;
	if (progress<100 && (gid == 14 || gid == 2)) {
         return <Redirect to={`/assessment/${plant_slug}`} />;
    }
	useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
			const apiUrl = `/profile?param=subscription&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, [res]);
	return (
		<>
			{/* Page title */}
			<Helmet>
				<title>Subscription | SmarterChains</title>
				<meta name="description" content="Certificates" />
				<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/purchase-history">Purchase History</BreadcrumbsItem>
			{/* custom view component */}
			<ProfileView onTab={onTab} setRes={setRes} subscriptionData={results} {...props} />
		</>
	);
};
