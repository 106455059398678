/** imported components
 
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * AdminWalkthroughController ===> call of wlakthrough component for this page.
  * fileName.scss for ===> styling css file for the page.
 
**/

import React, { Component, useState,useEffect } from "react";
import "./all-sections.scss";
import { AdminWalkthroughController } from '../admin-walkthrough/admin-walkthrough-ctrl'

export const AllSectionsView = () => {
   // variable declaration for walkthrough on the page
   const [sliderActive,setSliderActive] = useState(0);

    // walkthrough display conditions start
        const handleGetValue=(e=null)=>{
            setSliderActive(e);
            let objDiv = document.querySelector('.training-content');
            let headerLink = document.querySelector('.header-page-link-ul');
            let headerProgress = document.querySelector('.header-right-frame');
            if(e===1){
                headerLink.classList.add('z-index9999');
                headerProgress.classList.add('z-index9999');
            }else{
                headerLink.classList.remove('z-index9999');
                headerProgress.classList.remove('z-index9999');
            }
            if(e===4){
                objDiv.scrollTop = objDiv.scrollHeight;
            }else{
            objDiv.scrollTop=0;
            }
        }

        useEffect(()=>{
            return () => {
                const z99= document.querySelectorAll('.z-index9999');
                for(let i=0; i<z99.length; i++ ){
                    z99[i].classList.remove('z-index9999');
                }
            }   
            },[]);
    // walkthrough display conditions end
    
    return (
        // { main wrapper that is containing the whole elements of the page }
        <div className="all-sellection-main">
            {/* Cards has been divided in three parts. Left part has image, text-box has text and angle-box has image. */}
            <div className="all-sellection-card">
                <div className="left-img-box">
                    <img src={require('../../assets/img/all-section/info-system.svg').default} alt="true" />
                </div>
                <div className="text-box">
                    <div className="text-left">
                        <h2 className="h1">
                            <span>Information Systems</span>
                            <img src={require('../../assets/img/all-section/card-i-icon.svg').default} alt="i" className={sliderActive===2?"z-index9999":''}/>
                        </h2>
                        <p className={sliderActive===2?"z-index9999":''}>
                            <span className="res-text">Responsible:</span>
                            <span className="res-name">Serhii Polyvanyi</span>
                        </p>
                    </div>
                    <div className="text-cir">
                        <SvgCircle value={0} />
                    </div>
                </div>
                <div className={`angle-box ${sliderActive===3?'z-index9999':''}`}>
                    <img src={require('../../assets/img/all-section/right-angle.svg').default} alt="true" />
                </div>
            </div>
            {/* card 1 end */}
            <div className="all-sellection-card">
                <div className="left-img-box">
                    <img src={require('../../assets/img/all-section/cybersecurity-data-architecture.svg').default} alt="true" />
                </div>
                <div className="text-box">
                    <div className="text-left">
                        <h2 className="h1">
                            <span>Cybersecurity & Data Architecture</span>
                            <img src={require('../../assets/img/all-section/card-i-icon.svg').default} alt="i" />
                        </h2>
                        <p>
                            <span className="res-text">Responsible:</span>
                            <span className="res-link">Unassigned+</span>
                        </p>
                    </div>
                    <div className="text-cir">
                        <SvgCircle value={0} />
                    </div>
                </div>
                <div className="angle-box">
                    <img src={require('../../assets/img/all-section/right-angle.svg').default} alt="true" />
                </div>
            </div>
            {/* card 2 end */}
            <div className="all-sellection-card">
                <div className="left-img-box">
                    <img src={require('../../assets/img/all-section/maintenance.svg').default} alt="true" />
                </div>
                <div className="text-box">
                    <div className="text-left">
                        <h2 className="h1">
                            <span>Maintenance</span>
                            <img src={require('../../assets/img/all-section/card-i-icon.svg').default} alt="i" />
                        </h2>
                        <p>
                            <span className="res-text">Responsible:</span>
                            <span className="res-link">Unassigned+</span>
                        </p>
                    </div>
                    <div className="text-cir">
                        <SvgCircle value={0} />
                    </div>
                </div>
                <div className="angle-box">
                    <img src={require('../../assets/img/all-section/right-angle.svg').default} alt="true" />
                </div>
            </div>
            {/* card 3 end */}
            <div className="all-sellection-card">
                <div className="left-img-box">
                    <img src={require('../../assets/img/all-section/quality.svg').default} alt="true" />
                </div>
                <div className="text-box">
                    <div className="text-left">
                        <h2 className="h1">
                            <span>Quality</span>
                            <img src={require('../../assets/img/all-section/card-i-icon.svg').default} alt="i" />
                        </h2>
                        <p>
                            <span className="res-text">Responsible:</span>
                            <span className="res-link">Unassigned+</span>
                        </p>
                    </div>
                    <div className="text-cir">
                        <SvgCircle value={0} />
                    </div>
                </div>
                <div className="angle-box">
                    <img src={require('../../assets/img/all-section/right-angle.svg').default} alt="true" />
                </div>
            </div>
            {/* card 4 end */}
            <div className="all-sellection-card">
                <div className="left-img-box">
                    <img src={require('../../assets/img/all-section/ehs.svg').default} alt="true" />
                </div>
                <div className="text-box">
                    <div className="text-left">
                        <h2 className="h1">
                            <span>EHS</span>
                            <img src={require('../../assets/img/all-section/card-i-icon.svg').default} alt="i" />
                        </h2>
                        <p>
                            <span className="res-text">Responsible:</span>
                            <span className="res-link">Unassigned+</span>
                        </p>
                    </div>
                    <div className="text-cir">
                        <SvgCircle value={0} />
                    </div>
                </div>
                <div className="angle-box">
                    <img src={require('../../assets/img/all-section/right-angle.svg').default} alt="true" />
                </div>
            </div>
            {/* card 5 end */}
            <div className="all-sellection-card">
                <div className="left-img-box">
                    <img src={require('../../assets/img/all-section/warehouse-material-handling.svg').default} alt="true" />
                </div>
                <div className="text-box">
                    <div className="text-left">
                        <h2 className="h1">
                            <span>Warehouse & Material Handling</span>
                            <img src={require('../../assets/img/all-section/card-i-icon.svg').default} alt="i" />
                        </h2>
                        <p>
                            <span className="res-text">Responsible:</span>
                            <span className="res-link">Unassigned+</span>
                        </p>
                    </div>
                    <div className="text-cir">
                        <SvgCircle value={0} />
                    </div>
                </div>
                <div className="angle-box">
                    <img src={require('../../assets/img/all-section/right-angle.svg').default} alt="true" />
                </div>
            </div>
            {/* card 6 end */}
            <div className="all-sellection-card inactive">
                <div className="left-img-box">
                    <img src={require('../../assets/img/all-section/production-execution.svg').default} alt="true" />
                </div>
                <div className="text-box">
                    <div className="text-left">
                        <h2 className="h1">
                            <span>Production Execution</span>
                            <img src={require('../../assets/img/all-section/card-i-icon.svg').default} alt="i" />
                        </h2>
                        <p>
                            <span className="res-text">Responsible:</span>
                            <span className="res-link">Unassigned+</span>
                        </p>
                    </div>
                    <div className="text-cir">
                        <SvgCircle value={0} />
                    </div>
                </div>
                <div className="angle-box">
                    <img src={require('../../assets/img/all-section/right-angle.svg').default} alt="true" />
                </div>
                <div className="unlock-btn-sec">
                    <button className={sliderActive===4?"z-index9999":''}>Unlock this section</button>
                </div>
            </div>
            {/* card 7 end */}

            {/* Component call to show walkthrough on the page  */}
            <AdminWalkthroughController handleGetValue={handleGetValue}/>
        </div>
    );
}

// svgcircle has been defined here that we are using for the percentage.

const SvgCircle = (props) => {
    const { value } = props;
    return (
        <svg viewBox="0 0 36 36">
            <text
                className="percent__text"
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="8"
                fontWeight="bold"
            >
                {value}
                <tspan fontSize="5">
                    %
                </tspan>
            </text>
            <path
                className="circle-bg"
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                stroke="#EBF5FA"
                strokeWidth="2.4"
                fill="none"
            ></path>
            <path
                className="circle"
                strokeDasharray={`${value}, 100`}
                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                stroke={
                    value < 100 && value > 0
                        ? "#FFBC1F"
                        : value === 100
                            ? "#40D30D"
                            : "transparent"
                }
                strokeWidth="2.4"
                fill="none"
            ></path>
        </svg>
    );
};
