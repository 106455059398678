/** imported components

  * makeStyles for ===> styling material-ui component.
  * TrainingCardView for ==> for custom component.
  * scss for ===> styling css file for this page.

**/
import React, { useState } from "react";
import "./training-card.scss";
import { TrainingCardView } from "./training-card-view";
// TrainingCardControler start
export const TrainingCardControler = (props) => {
  const [isHover, setIsHover] = useState(false);
  const HandleHoverState = () => {
    setIsHover(!isHover);
  };
  return (
    // View component start
    <TrainingCardView
      isHover={isHover}
      HandleHoverState={HandleHoverState}
      {...props}
    />
  );
};
