import React, { useState, useEffect } from 'react';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { MainWrapper, H1, Row, P, InfoTooltip, KPICard, KPICardTitle, KPICardText, Column, SubmitButton, AfterSubmitButton, ErrorMsgDiv } from './styles';
import { InfoIcon, tickSvg } from './svg';
import serviceProvider from '../../services/axios';
import { Helmet } from "react-helmet";
export const KPIView = (props) => {
    const history = useHistory();
    const dataReturn = props.results[0];
    const plant_name = dataReturn.plant_name;
    const plant_slug = dataReturn.plant_slug;
    const plant_id = dataReturn.plant_id;
    const chapter_id = dataReturn.chapter_id;
    const user_results = dataReturn.user_results;
    const is_datasubmitted = dataReturn.is_datasubmitted;
    const user = JSON.parse(localStorage.getItem("userData"));
    const uid = user ? user.uid : 0;

    const allFields = [
        {
            id: 2915,
            title: 'Asset & Equipment Efficiency',
            info: `KPIs under this category measure the company’s ability to maximize the utility of assets and equipment that are used for production within its factory or plant. Parameters used by manufacturers within this category include overall equipment efficiency (“OEE”), frequency of unplanned downtime, duration of assets’ lifespan and unit throughput. Improvements to asset and equipment efficiency will increase a manufacturing facility’s production volume, leading to revenue gains and reduced excess capital spent on repairs and replacements.`
        },
        {
            id: 2916,
            title: 'Workforce Efficiency',
            info: `KPIs under this category assess both the direct and indirect labour productivity in the plants or factories. Companies with strong workforce efficiency will generally require less man-hours spent per task. Depending on the companies’ Human Resources policies, employee turnover and training effectiveness could also be additional parameters in both influencing and determining workforce efficiency. Improvements in workforce efficiency not only result in greater revenue contributions per employee, but also enhance intangible elements like workforce morale.`
        },
        {
            id: 2917,
            title: 'Utilities Efficiency',
            info: `KPIs under this category take into consideration the amount of energy and water consumed, as well as emissions and wastewater produced by a company’s manufacturing operations. If utilities costs make up a significant portion of a company’s production expenses, improvements in utilities efficiency will enable it to produce at a lower cost or operate with a higher profit margin. In addition, given the growing attention on climate change and sustainability, companies operating at a higher utilities’ efficiency level may also be perceived as more environmentally responsible. This could be a significant consideration for companies in brand-conscious, consumer-facing industries.            `
        },
        {
            id: 2918,
            title: 'Inventory Efficiency',
            info: `KPIs under this category aim to guide manufacturers to optimize and/or minimize the average volume of inventory required. Storing excess inventory creates unnecessary burdens and costs, particularly for plants and factories that have limited land or storage space. In addition to locking up working capital, manufacturing facilities with large volumes of unutilized inventory can experience increased costs resulting from higher land and/or building rentals, extra manpower to manage the inventory and greater wastage due to expiring inventory. In extreme cases, excess inventory can even cannibalize space that could have been designated for new manufacturing lines or synergistic functions such as product design or testing.`
        },
        {
            id: 2919,
            title: 'Material Efficiency',
            info: `KPIs under this category aim to measure the company’s efforts in optimizing the usage of raw materials in its production process. Success in this category can be in the form of using less inputs for the same amount of output or recycling a larger amount of unused/unprocessed inputs for subsequent production. Achieving higher levels of material efficiency can yield not only economic benefits such as savings in raw materials, but also contribute towards environmental stewardship by reinforcing the sustainability imperative of the company.            `
        },
        {
            id: 2920,
            title: 'Process Quality',
            info: `KPIs under this category evaluate the performance and resilience of a company’s ability to uphold the integrity of the core and support processes within the factory/plant, by minimizing the deviations from the intended parameters and conditions. Success in this KPI category is characterized by dependable business operations and it could enable a manufacturer to increase production output, lower the rate of defects and reduce the amount of material wastage. `
        },
        {
            id: 2921,
            title: 'Product Quality',
            info: `KPIs in this category emphasize the company’s ability in producing a low percentage of defective products – both work-in-process and finished goods – and that all products are manufactured as closely to the target specifications as possible. Achieving this will reduce the defect rate of finished goods and the likelihood of after-sale failures and product rejections. This will enable the company to sell more products per batch and reduce the costs associated with product repairs, replacements and warranties.`
        },
        {
            id: 2922,
            title: 'Safety',
            info: `KPIs in this category measure the number of workplace safety incidents in the company’s facilities. Having low or no safety issues within the working environment will help to ensure the company’s operations continue running as they should be. Additionally, keeping employees safe reduces regulatory and compensation costs, and more importantly, helps to raise the morale and confidence of employees. In the long run, this can also create positive spin-off effects in the areas of employer branding and customer confidence.`
        },
        {
            id: 2923,
            title: 'Security',
            info: `KPIs in this category measure the number of security (physical and digital) breaches in the company’s facilities. In recent times, the increasing digitalization of manufacturing facilities and growing interconnectivity of assets has led to a heightened level of vulnerability in production systems and networks. This results in a greater need for a more robust and resilient cyber-physical security plan to lower the risk of cyber-physical threats that may disrupt the smooth running of manufacturing facilities. Such threats include the introduction of viruses and malware that may shutdown/reconfigure ongoing production progresses, the exposure of sensitive information to non-authorized personnel and more Flexibility.`
        },
        {
            id: 2924,
            title: 'Planning & Scheduling Effectiveness',
            info: `KPIs in this category focus on the manufacturer’s level of proficiency and adaptability in its planning and scheduling functions. Companies that excel in this area generally have strong predictive analytic capabilities, coupled with a wide network of reliable partners (suppliers and customers). This allows them to effectively handle the volatilities of market demand and supply, take on and fulfil orders on short notice, and balance demand-supply volumes without significant disruption to their manufacturing and supply-chain operations. In addition to driving top-line growth through an enhanced ability to take on more businesses, success in this area will also foster a strong reputation of reliability and adaptability around the company.`
        },
        {
            id: 2925,
            title: 'Production Flexibility',
            info: `KPIs in this category measure a manufacturer’s ability to augment its production processes through a plug-and-play approach. Flexible production is where equipment, machinery and computer-based systems can be modified, reconfigured and re-tasked quickly and easily when needed. This enables a manufacturer to manage various permutations of product mix and volume. Thus, the manufacturer can promptly achieve a high number of stocks keeping units (“SKU”) in accordance with changing customer needs and market demands, while incurring relatively low cost.`
        },
        {
            id: 2926,
            title: 'Workforce Flexibility',
            info: `KPIs in this category assess the ability of a company’s workforce to perform a variety of different job functions. Building a strong team of multi-skilled workers across different levels of the organization allows a manufacturer more options in potentially redeploying employees during periods of volatility – such as when market demands fluctuate. This enables the company to achieve stability more quickly and effectively. Having a flexible workforce also enables a company to better cope with sudden attrition, thus strengthening the company’s business continuity.`
        },
        {
            id: 2927,
            title: 'Time to Market (”TTM”)',
            info: `KPIs within this category measure the length of time it takes for a company to conceive a new product, or augment an existing one, and launch it in the market. The digitalization of industrial sectors has led to an increase in information flow and data exchange across the entire product value chain and allowed companies today to have greater access to customer feedback. This reduces the amount of time needed to augment or develop products that can better support the changing market needs. Having a short TTM will allow companies to capitalise on emerging business trends, especially those with a narrow window of opportunity. By ensuring its products hit the markets ahead of its competitors, companies with a shorter TTM can capture larger market shares through their first-mover advantage.`
        },
        {
            id: 2928,
            title: 'Time to Delivery',
            info: `KPIs in this category measure the length of time it takes for a company to produce and deliver the finished products to the end-customers from the initiation of the project or contract. For many industries, many product orders are subjected to some level of time-sensitivity, and a delayed delivery could translate to substantial losses in future business opportunities. Companies that can deliver outcomes more quickly, and reliably, will be able to establish themselves as the premier provider in the industry – and possibly even command a service premium for urgent orders.`
        },
    ]
    const [infoActiveTooltip, setInfoActiveTooltip] = useState(false);
    const [isAfterSubmit, setIsAfterSubmit] = useState(false);
    const [alertError, setAlertError] = useState(false);
    const [submitMsg, setSubmitMsg] = useState(false);
    const [inputCheck, setInputCheck] = useState({ ...user_results });
    const handleClickedInfoIcon = (ind) => {
        if (infoActiveTooltip !== ind) {
            setInfoActiveTooltip(ind);
        } else {
            setInfoActiveTooltip(false);
        }
    }
    const handleClickedOutside = (e) => {
        if (!e.target.closest('.info-icon-i') && !e.target.closest('[data-component="InfoTooltip"]')) {
            setInfoActiveTooltip(false);
        }
    }
    const handleInput = (e, id) => {
        let targetAllInputs = document.querySelectorAll('.input-check');
        if (targetAllInputs) {
            let tmpObj = {};
            targetAllInputs.forEach((item, index) => {
                let key = item.id;
                let value = item.checked == true;
                if (value) {
                    tmpObj = { ...tmpObj, [key]: value };
                }
            });
            if (Object.values(tmpObj).length > 5) {
                // alert('You can select up to 5 KPIs');
                setAlertError('You have to choose minimum and maximum 5 KPIs');
                setTimeout(() => {
                    setAlertError(prv => false);
                }, 2000);
            } else {
                setInputCheck(tmpObj);
                setAlertError(false);
            }

        }
    }
    const handleSubmit = () => {
        setAlertError(prv => false);
        const button = document.getElementById('submit-btn');

        serviceProvider
            .post(
                "/assessmentmaturity?type=savekpi",
                JSON.stringify({
                    question_id: 734,
                    user_id: uid,
                    plant_id: plant_id,
                    option_id: inputCheck,
                }),
                true
            )
            .then((response) => {
                console.log(response);
                setSubmitMsg('Your data has been saved successfully');
            });
        if (button.hasAttribute('disabled')) {
            setIsAfterSubmit(false);
        } else {
            setIsAfterSubmit(true);
        }
        setTimeout(() => {
            setIsAfterSubmit(prv => false);
            history.push(`/assessment/chapter/${plant_slug}/12`);
        }, 3000);

    }
    useEffect(() => {
        document.addEventListener('click', handleClickedOutside);
        return () => {
            document.removeEventListener("click", handleClickedOutside);
        }
    }, []);
    return (
        <MainWrapper data-component="MainWrapper">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{plant_name}</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/${plant_slug}`}>Assessment</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/chapter/${plant_slug}/12`}>KPI Selection & PLanning Horizon</BreadcrumbsItem>
            <BreadcrumbsItem to="#">KPI  Selection</BreadcrumbsItem>

            <ErrorMsgDiv dataComponent='ErrorMsgDiv' active={(alertError || submitMsg) ? true : false} green={submitMsg ? true : false}>
                {alertError ?
                    <p>{alertError}</p>
                    :
                    submitMsg ?
                        <p>{submitMsg}</p>
                        :
                        ''
                }
            </ErrorMsgDiv>
            <H1 data-component="H1">
                KPI
            </H1>
            <p className='sub-text'>Key Performance Indicator (“KPIs”) are measures used to evaluate a company’s success or effectiveness in achieving
                its key business objectives and strategic directives. Therefore,
                the KPI Factor of a Dimension reflects the level of impact that the
                Dimension can have on a company’s essential business objectives.
            </p>
            <KPICard data-component="KPICard">
                <KPICardTitle data-component="KPICardTitle">Choose the KPI’s your Company wants to Focus on</KPICardTitle>
                <KPICardText data-component="KPICardText">Please choose 5 of the 14 KPIs. Choose what you believe are the most important. The description of each KPI is stated above, please read them/</KPICardText>
                <Row data-component="Row">
                    {
                        allFields.map((item, ind) => (
                            <Column data-component="Column" key={ind}>
                                <P data-component="P">
                                    <label for={`${item.id}`}>
                                        <input className="input-check" type="checkbox" defaultChecked={user_results[item.id] || false} name="kpiIndex[]" value={ind} id={`${item.id}`} checked={inputCheck[item.id] || false} onChange={(e) => handleInput(e, item.id)} disabled={is_datasubmitted ? true : false} />
                                        <span>KPI {ind + 1}: {item.title}</span>
                                    </label>
                                    <div className={`info-wrapper ${infoActiveTooltip === ind ? 'active' : ''}`}>
                                        <i className={`info-icon-i ${infoActiveTooltip === ind ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: InfoIcon }} onClick={(e) => handleClickedInfoIcon(ind)}></i>
                                        <InfoTooltip data-component="InfoTooltip" className={infoActiveTooltip === ind ? 'active' : ''}>
                                            <p className='tooltip-text'>{item.info}</p>
                                        </InfoTooltip>
                                    </div>
                                </P>
                            </Column>
                        )
                        )
                    }
                </Row>
            </KPICard>
            {!isAfterSubmit && !is_datasubmitted ?
                <SubmitButton id="submit-btn" as={'button'} onClick={() => handleSubmit()} data-component="SubmitButton" disabled={Object.values(inputCheck || {}).length < 5 || Object.values(inputCheck).every(value => !value) }>
                    Submit
                </SubmitButton>
                :
                <AfterSubmitButton data-component="AfterSubmitButton">
                    <i dangerouslySetInnerHTML={{ __html: tickSvg }}></i>
                    <span>Submitted Successfully</span>
                </AfterSubmitButton>
            }
        </MainWrapper>
    );
}

