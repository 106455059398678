/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Plyr ===> third party plugin.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/

import React, { useState, Fragment, useEffect } from "react";
import { Collapse, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import serviceProvider from "../../services/axios";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import "./maturity-dashboard-admin-page.scss";
import { apiEndpoints } from "../../services/api-endpoints";
import {
  MaturityPlantSetupPopup,
  MaturitySubmitDataPopup,
  AssignSectionPopup,
  MaturityAddUserPopup,
} from "./maturity-admin-popup";

// SidebarView component start
export const SidebarView = (props) => {
  //defining varibles here
  const curl = window.location.pathname.split("/");
  const firstSeg = curl[1];
  const inHomeArr = ["educate-prepare-home", "home", "home-tour"];
  const homeClass = inHomeArr.indexOf(firstSeg) != -1 ? "active" : "";
  const f1Class = firstSeg == "framework" ? "active" : "";
  const f2Class = firstSeg == "industry-40-dimensions" ? "active" : "";
  const f3Class = firstSeg == "digital-plant" ? "active" : "";
  const f4Class = firstSeg == "cpgstudy" ? "active" : "";
  const myTaskClass = firstSeg == "task-center" ? "active" : "";
  const inCourseArr = [
    "training-courses",
    "course_overview",
    "course_details",
    "course_quiz",
  ];
  const courseClass = inCourseArr.indexOf(firstSeg) != -1 ? "active" : "";
  const inLearnArr = ["learning-center"];
  const LearningClass = inLearnArr.indexOf(firstSeg) != -1 ? "active" : "";
  const [isUpgradePopup, setIsUpgradePopup] = useState(false);
  const [isLnrCollapse, setIsLnrCollapse] = useState(true);
  const [groupid, setGroupId] = useState("");
  const [isvideoPopup, setIsvideoPopup] = useState(false);
  const [isAddUserPopup, setIsAddUserPopup] = useState(false);
  const [isAddPlantPopup, setIsAddPlantPopup] = useState(false);
  const [isSubmitDataPopup, setIsSubmitDataPopup] = useState(false);
  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
  const [isPlantClicked, setIsPlantClicked] = useState(false);
  const [asspopup, setAssPopup] = useState([]);
  const [isAccessNotGrantedPopup, setIsAccessNotGrantedPopup] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(0);
  //function to handle upgrade popup
  const HandleUpdgradePopup = () => {
    setIsUpgradePopup(!isUpgradePopup);
  };
  const user = JSON.parse(localStorage.getItem("userData"));
  const gid = user ? user.group_id : 0;
  const usid = user ? user.uid : 0;
  const progress = user ? user.progress : 0;
  const login = localStorage.getItem("isLoggedIn");
  const openAssPopup = (e) => {
    const getVal = $(e.target).attr("data-id");
    setAssPopup(getVal);

    setIsAssignSectionPopup(true);
  };
  useEffect(() => {
    serviceProvider
      .post("/plants?type=usergroupid", JSON.stringify({ uid: usid }), true)
      .then((response) => {
        const data = response.data;
        setGroupId(data);
        if (login) {
          localStorage.setItem("group_id", data);
        }
      });
  }, []);
  useEffect(() => {
    const { showSidebarPopUp, isQuizPage, history } = props;
    if (isPlantClicked && showSidebarPopUp && isQuizPage) {
      history.push("/");
    }
  }, [props.showSidebarPopUp]);
  //function to handle plant on click
  const handlePlantClick = () => {
    if (props.isQuizPage) {
      setIsPlantClicked(true);
    } else {
      setIsAddUserPopup(true);
    }
  };
  //function to handle other clicks
  const handleOtherClicks = () => {
    setIsPlantClicked(false);
  };
  //function to handle video popup
  const HandleVideoPopup = () => {
    setIsvideoPopup(!isvideoPopup);
  };
  // Function to handle mobile sidebar
  const handleMobileSidebar = (e) => {
    let windowWidth = window.innerWidth;
    if (windowWidth <= 740) {
      e?.preventDefault();
      props.handleChange();
    }
  } 
  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (props.expanded && !e.target.closest('.ng-sidebar')) {
        handleMobileSidebar();
      }
    })
  }, [props.expanded])
 
  return (
    <Fragment>
      {/* Added mobile-active class */}
      <aside
        className={
          props.expanded
            ? props.isSidebar
              ? "ng-sidebar active mobile-active"
              : "ng-sidebar"
            : "ng-sidebar"
        }
      >
        {/* section has
					ng-sidebar-header
					nav
					ng-toggle-icon
				*/}
        <div className="ng-sidebar-wrapper">
          {/* this section has link */}
          <div className="ng-sidebar-header">
            <Link to="/" onClick={(e) => { handleMobileSidebar(e); handleOtherClicks() }}>
              <img
                src={
                  require("../../assets/img/sidebar-img/team-header-logo.svg")
                    .default
                }
                className="logo-expend"
                alt="true"
              />
              <img
                src={
                  require("../../assets/img/sidebar-img/team-header-logo-min.svg")
                    .default
                }
                className="logo-collapse"
                alt="true"
              />
            </Link>
          </div>
          {/* this section has
						nav-list-wrapper
						ng_card
					*/}
          <nav className={props.isSidebar ? "" : "ng-hide-pay"}>
            <ul className="ng-level1-nav-list-wrapper">
              <li className="ng-level1-li">
                {/* link has
									ng-nav-img
									mg-nav-txt
								*/}
                <Link
                  to={groupid == 14 ? "/home" : groupid == 11 ? "/home" : "/"}
                  className={`ng-level1-a ${homeClass} ${progress < 100 && (gid == 14 || gid == 2)?'disabled-tooltip':''}`}
                  onClick={handleOtherClicks}
                  data-title={progress < 100 && (gid == 14 || gid == 2)?'Access will be granted once assessment is submitted':''}
                >
                  <span className="ng-nav-img">
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/home-inactive.svg`)
                          .default
                      }
                      className="in-active"
                      alt="true"
                    />
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/home-active.svg`)
                          .default
                      }
                      className="active"
                      alt="true"
                    />
                  </span>
                  <div className="ng-nav-txt side-collapse">
                    {/*<span>My Actions</span>*/}
                    <span>Home</span>
                  </div>
                </Link>
                {/* my task link */}
                <Link
                  to="/task-center"
                  className={`ng-level1-a ${myTaskClass}`}
                  onClick={handleOtherClicks}
                >
                  <span className="ng-nav-img">
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/task-center.svg`)
                          .default
                      }
                      className="in-active"
                      alt="true"
                    />
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/task-center-active.svg`)
                          .default
                      }
                      className="active"
                      alt="true"
                    />
                  </span>
                  <div className="ng-nav-txt side-collapse">
                    <span>My Tasks</span>
                  </div>
                </Link>

                {/* Please don't remove this Add plant button. It's using in other pages */}
                {groupid != 14 ? (
                  <Link
                    to="#"
                    className="ng-level1-a cursor-pointer"
                    onClick={handlePlantClick}
                    style={{ display: "none" }}
                  >
                    <span className="ng-nav-img">
                      <img
                        src={
                          require(`../../assets/img/sidebar-img/exclude.svg`)
                            .default
                        }
                        className="in-active"
                        alt="true"
                      />
                      <img
                        src={
                          require(`../../assets/img/sidebar-img/exclude-active.svg`)
                            .default
                        }
                        className="active"
                        alt="true"
                      />
                    </span>

                    <div className="ng-nav-txt plant-add-svg side-collapse">
                      <span>My Plants</span>

                      <img
                        src={
                          require("../../assets/img/sidebar-img/plant-add.svg")
                            .default
                        }
                        alt="Add"
                        className="ed_addpl_btn"
                      />
                    </div>
                  </Link>
                ) : (
                  ""
                )}
                {(gid != 16 && gid != 14) && <Link
                  to="/training-courses"
                  className={`ng-level1-a ${courseClass} ${progress < 100 && (gid == 14 || gid == 2)?'disabled-tooltip':''}`}
                  onClick={handleOtherClicks}
                  data-title={progress < 100 && (gid == 14 || gid == 2)?'Access will be granted once assessment is submitted':''}
                >
                  <span className="ng-nav-img">
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/training.svg`)
                          .default
                      }
                      className="in-active"
                      alt="true"
                    />
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/training-active.svg`)
                          .default
                      }
                      className="active"
                      alt="true"
                    />
                  </span>
                  <div className="ng-nav-txt side-collapse">
                    <span>My Trainings</span>
                  </div>
                </Link>
                }
                {/* Learning center start */}
                <Link
                  to="/learning-center"
                  className={`ng-level1-a resource-tab ${LearningClass} ${progress < 100 && (gid == 14 || gid == 2)?'disabled-tooltip':''}`}
                  onClick={handleOtherClicks}
                  data-title={progress < 100 && (gid == 14 || gid == 2)?'Access will be granted once assessment is submitted':''}
                >
                  <span className="ng-nav-img">
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/learning.svg`)
                          .default
                      }
                      className="in-active"
                      alt="true"
                    />
                    <img
                      src={
                        require(`../../assets/img/sidebar-img/learning-active.svg`)
                          .default
                      }
                      className="active"
                      alt="true"
                    />
                  </span>
                  <div className="ng-nav-txt side-collapse">
                    <span className="">Resource Center</span>
                  </div>
                </Link>
                {/* Learning center end */}
                {/* ---------------------Learning center old start--------------- */}
                {/*<span className="ng-level1-a cursor-pointer">
									<span
										className="ng-nav-img lnr-img"
										onClick={() => {
											props.handleExpand();
										}}
									>
										<img src={require(`../../assets/img/sidebar-img/learning.svg`).default} className="in-active" alt="true" />
										<img
											src={require(`../../assets/img/sidebar-img/learning-active.svg`).default}
											className="active"
											alt="true"
										/>
									</span>
									<div className="ng-nav-txt side-collapse">
										<span className="ed_lrn_rsc" onClick={() => setIsLnrCollapse(!isLnrCollapse)}>
											Learning and Resources
										</span>
										<img
											src={require("../../assets/img/sidebar-img/nav-ddl-arrow.svg").default}
											alt="true"
											onClick={() => setIsLnrCollapse(!isLnrCollapse)}
											style={{ trnsition: ".25s", transform: `rotate(${isLnrCollapse ? "0deg" : "-90deg"})` }}
										/>
									</div>
								</span>*/}
                {/* dropdown list wrapper */}
                {/*<div className="lar-ddl-wrapper">
								<Collapse in={isLnrCollapse}>
									<ul className="ng-level2-nav-list-wrapper slidedown">
										<li className="ng-level2-li">
											<Link to="/framework" onClick={handleOtherClicks} className={`ng-level2-a ${f1Class}`}>
												<span>Framework</span>
											</Link>
										</li>
										<li className="ng-level2-li">
											<Link to="/industry-40-dimensions" onClick={handleOtherClicks} className={`ng-level2-a ${f2Class}`}>
												<span>Industry 4.0 Dimensions</span>
											</Link>
										</li>
										<li className="ng-level2-li">
											<Link to="/digital-plant" onClick={handleOtherClicks} className={`ng-level2-a ${f3Class}`}>
												<span>Digital Plant Archetypes</span>
											</Link>
										</li>
										<li className="ng-level2-li">
											<Link to="/cpgstudy" onClick={handleOtherClicks} className={`ng-level2-a ${f4Class}`}>
												<span>CPG Industry Study</span>
											</Link>
										</li>
									</ul>
								</Collapse>
								</div>*/}
                {/* ---------------------Learning center old end--------------- */}
              </li>
            </ul>
            {/* card has
							header
							button
							text-wrap
						*/}
            <div className="ng_card" style={{ display: "none" }}>
              <div className="ng_card_head">
                <span className="ed_webcst-ttl">webcast</span>
              </div>
              <button className="lai40-btn" onClick={HandleVideoPopup}>
                <img
                  src={
                    require("../../assets/img/home-index/quikstart-2.svg")
                      .default
                  }
                  alt="true"
                  style={{ maxWidth: "100%" }}
                />
              </button>
              <div className="text-wrap">
                <h2 className="ng_card_heading">
                  Start your Industry 4.0 Journey
                </h2>
                <p className="ng_card_text">
                  Learn more about one of the world’s top Frameworks for
                  Industry 4.0 Transformation
                </p>
              </div>
            </div>
          </nav>
        </div>
        {/* this section has wrapper */}
        <div className="ng-toggle-icon">
          {/* wrapper has images for toggle button */}
          <span
            className="ng-toggle-wrap"
            onClick={() => {
              props.handleChange();
            }}
            style={{ cursor: "pointer" }}
          >
            <span
              className={
                props.expanded ? "ng-slide-img active" : "ng-slide-img"
              }
              dangerouslySetInnerHTML={{ __html: toggleSvg }}
            ></span>
          </span>
        </div>
      </aside>
      {/* maturity plant setup popup component */}
      <MaturityPlantSetupPopup
        open={isAddPlantPopup}
        setOpen={setIsAddPlantPopup}
        {...props}
      />
      {/* maturoty data submit popup component */}
      <MaturitySubmitDataPopup
        open={isSubmitDataPopup}
        setOpen={setIsSubmitDataPopup}
        {...props}
      />
      {/* assign section popup component */}
      <AssignSectionPopup
        open={isAssignSectionPopup}
        setOpen={setIsAssignSectionPopup}
        assessmentPopup={asspopup}
        {...props}
      />
      {/* maturity add user popup component */}
      <MaturityAddUserPopup
        open={isAddUserPopup}
        setOpen={setIsAddUserPopup}
        setAddPlantPopup={setIsAddPlantPopup}
        {...props}
      />
      <Modal
        open={isUpgradePopup}
        // onClose={HandleUpdgradePopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="home-index-upgrade-popup"
      >
        {/* this section has
					close-btn
					upgrd-popup-img
					upgrd-popup-body
				*/}
        <div className="upgrade-popup-content">
          <button className="close-btn" onClick={HandleUpdgradePopup}>
            <img
              src={
                require("../../assets/img/common-img/close-icon-blue.svg")
                  .default
              }
              alt="close"
            />
          </button>
          <div className="upgrd-popup-img">
            <img
              src={
                require("../../assets/img/home-index/digital-maturity.gif")
                  .default
              }
              alt="digital_maturity"
            />
          </div>
          {/* this section has
						heading
						subtext
						link
					*/}
          <div className="upgrd-popup-body">
            <h2 className="h1">
              Buy full Digital Maturity Assessment to Unlock Plants
            </h2>
            <p>
              Upgrade to the plan that best matches your profile and start the
              Assessment as early as today.
            </p>
            <Link
              to="/plans"
              className="btn-upgrade-now"
              onClick={HandleUpdgradePopup}
            >
              Upgrade Now{" "}
            </Link>
          </div>
        </div>
      </Modal>

      {/*home index main-course-page end*/}
      <Modal
        open={isvideoPopup}
        onClose={HandleVideoPopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="quick-start-vid-popup"
      >
        <div className="vid__wrapper custom_vid__wrapper">
          <Plyr
            source={{
              type: "video",
              sources: [
                {
                  src: `${apiEndpoints.baseUrl}/assets/videos/intro.mp4`,
                  type: "video/mp4",
                },
              ],
            }}
            options={{
              //controlshidden: true,
              controls: [
                "play-large",
                "rewind",
                "fast-forward",
                "play",
                "progress",
                "current-time",
                "duration",
                "mute",
                "volume",
                "settings",
                "fullscreen",
              ],
            }}
          />
        </div>
      </Modal>
      {/*quick-start-vid-popup end*/}
     
    </Fragment>
  );

};

const toggleSvg = `<svg class='inner-image' width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.474716 11.0297C0.218535 10.7924 0.218314 10.3873 0.474237 10.1498L4.47095 6.43974C4.72668 6.20235 4.72668 5.79765 4.47095 5.56026L0.474237 1.85022C0.218314 1.61266 0.218535 1.20758 0.474716 0.970294L1.11455 0.377652C1.3446 0.164566 1.69994 0.164566 1.92999 0.377652L7.52477 5.55981C7.78114 5.79728 7.78114 6.20272 7.52477 6.44019L1.92999 11.6223C1.69994 11.8354 1.3446 11.8354 1.11455 11.6223L0.474716 11.0297Z" fill="#7D868C"/>
</svg>`;
