/** imported components
 
  * useState ===> React hooks (useState is a way to get/set value)
  * Collapse ===> component from material Ui has been used to work as accordion.

**/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
//DigitalPlanView Component start
export const DigitalPlanView = (props) => {
	//Function call for startup plan
	const HandleStartUpDiv = () => {
		props.setOnTab(1);
	};
	//Function call for transforming plan
	const HandleTransformingDiv = () => {
		props.setOnTab(2);
	};
	//Function call for Accelerating
	const HandleAcceleratingDiv = () => {
		props.setOnTab(3);
	};
	//Function call for Thriving
	const HandleThrivingDiv = () => {
		props.setOnTab(4);
	};

	return (
		// digital plant main wrapper start 
		<div className="digital-plant-main-wrapper">
			{/* Top content wrapper inside main wrapper which has heading and text */}
			<div className="digital-plant-top-content">
				<h2 className="h1">Industry 4.0 Plant Archetypes</h2>
				<p>Plant archetypes classify your manufacturing operations based on the results of the IR4.0 Assessment.</p>
			</div>
			{/* ROW after top content divided into two columns */}
			<div className="digital-plant-top-row">
				{/* Left column is containing heading and text */}
				<div className="digital-plant-top-col1">
					<h3 className="h3">Industry 4.0 Adoption</h3>
					<p>By Plant Archetypes</p>
				</div>
				{/* Right column has image, heading and text with link  */}
				<div className="digital-plant-top-col2">
					<div className="digital-plant-blue-box">
						<div className="img-div">
							<img src={require("../../assets/img/digital-plan/queston-mark-sc.svg").default} alt="true" />
						</div>
						<h3 className="h3">Where would your plant fit in?</h3>
						<p>
							<Link to="/plans"><span className="b">Buy Assessment</span></Link> to learn how your plant scores.
						</p>
					</div>
				</div>
			</div>
			{/*digital-plant-top-row end*/}
			{/* card wrapper for digital plant start */}
			<div className="digital-plant-card-wrapper">
				{/* Button for startup */}
				<button onClick={HandleStartUpDiv} id="startup" className={props.onTab === 1 ? "digital-plant-card active" : "digital-plant-card"}>
					{/* Card header has image and title */}
					<div className="card-header">
						<div className="img-div">
							<img src={require("../../assets/img/digital-plan/startup.svg").default} alt="true" />
						</div>
						<p className="img-title">Start Up</p>
					</div>
					{/* this section has list */}
					<div className="card-ul">
						<p className="card-li">Paper-based processes</p>
						<p className="card-li">Islands of Automation</p>
						<p className="card-li">Simple ERP functionalities</p>
						<p className="card-li">Manual material handling</p>
						<p className="card-li">Integration of key business systems</p>
					</div>
					{/* This section has learn more button */}
					<div className="learn-more-wrapper">
						<button>+ Learn more</button>
					</div>
				</button>
				{/* end*/}
				{/* Button for Trasnforming start */}
				<button onClick={HandleTransformingDiv} id="transforming" className={props.onTab === 2 ? "digital-plant-card active" : "digital-plant-card"}>
					{/* Card header has image and title */}
					<div className="card-header">
						<div className="img-div">
							<img src={require("../../assets/img/digital-plan/transforming.svg").default} alt="true" />
						</div>
						<p className="img-title">Transforming</p>
					</div>
					{/* this section has list */}
					<div className="card-ul">
						<p className="card-li">Mostly automated production</p>
						<p className="card-li">Vertical integrations of key systems</p>
						<p className="card-li">Internet connectivity & basic cybersecurity</p>
						<p className="card-li">Just starting the Industry 4.0 Journey</p>
					</div>
					{/* This section has learn more button */}
					<div className="learn-more-wrapper">
						<button>+ Learn more</button>
					</div>
				</button>
				{/*end*/}
				{/* Button for Accelerating start*/}
				<button onClick={HandleAcceleratingDiv} id="accelerating" className={props.onTab === 3 ? "digital-plant-card active" : "digital-plant-card"}>
					{/* Card header has image and title */}
					<div className="card-header">
						<div className="img-div">
							<img src={require("../../assets/img/digital-plan/accelerating.svg").default} alt="true" />
						</div>
						<p className="img-title">Accelerating</p>
					</div>
					{/* this section has list */}
					<div className="card-ul">
						<p className="card-li">Modern, cloud-based ERP</p>
						<p className="card-li">Automating material handling</p>
						<p className="card-li">Industry 4.0 roadmap in place</p>
						<p className="card-li">Digital training plans for key roles</p>
					</div>
					{/* This section has learn more button */}
					<div className="learn-more-wrapper">
						<button>+ Learn more</button>
					</div>
				</button>
				{/*end*/}
				{/* Button for thriving start */}
				<button onClick={HandleThrivingDiv} id="thriving" className={props.onTab === 4 ? "digital-plant-card active" : "digital-plant-card"}>
					{/* Card header has image and title */}
					<div className="card-header">
						<div className="img-div">
							<img src={require("../../assets/img/digital-plan/thriving.svg").default} alt="true" />
						</div>
						<p className="img-title">Thriving</p>
					</div>
					{/* this section has list */}
					<div className="card-ul">
						<p className="card-li">Modular and adaptive manufacturing</p>
						<p className="card-li">Autonomous decision-making systems</p>
						<p className="card-li">Horizontal value-chain integration</p>
						<p className="card-li">Automation of changeovers</p>
					</div>
					{/* This section has learn more button */}
					<div className="learn-more-wrapper">
						<button>+ Learn more</button>
					</div>
				</button>
				{/*end*/}
			</div>
			<Collapse in={props.onTab == 1 ? true : false}>
				{/* Wrapper for learn more */}
				<div className="learn-more-collapse-div">
					{/* Title for plant */}
					<p className="title">
						What does a <span className="b">Start up</span> plant look like?
					</p>
					{/* This row contains learn more cards and every card has commone structure
						img-div ===> It has iamge
						content ===> It has text and image
						hover-div ===> It has content and image
					*/}
					<div className="learn-more-card-row">
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Some operational excellence initiatives focused mostly on standardizing and ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Some operational excellence initiatives focused mostly on standardizing and documenting procedures.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>ERP system with standard capabilities for production & inventory management.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>ERP system with standard capabilities for production & inventory management.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Internet or local network connectivity in most areas of the plant.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Internet or local network connectivity in most areas of the plant.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Forklifts & pallet jacks for material movements across the plant & warehouse.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Forklifts & pallet jacks for material movements across the plant & warehouse.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>No MES. Production Management in paper forms or Excel.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>No MES. Production Management in paper forms or Excel.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Several manual elements in the production & packing process.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Several manual elements in the production & packing process.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>No integrations between business systems and operational technologies.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>No integrations between business systems and operational technologies.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-8.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Maintenance is mostly run-to-failure or time-based.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-8.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Maintenance is mostly run-to-failure or time-based.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
					</div>
					{/* row end */}
				</div>
			</Collapse>
			<Collapse in={props.onTab == 2 ? true : false}>
				{/* Wrapper for learn more */}
				<div className="learn-more-collapse-div">
					{/* Title for plant */}
					<p className="title">
						What does a <span className="b">Transforming</span> plant look like?
					</p>
					{/* This row contains learn more cards and every card has commone structure
						img-div ===> It has iamge
						content ===> It has text and image
						hover-div ===> It has content and image
					*/}
					<div className="learn-more-card-row">
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Established operational excellence culture but little awareness of Industry 4.0...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Established operational excellence culture but little awareness of Industry 4.0 possibilities.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>ERP system with standard capabilities for production & inventory management.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>ERP system with standard capabilities for production & inventory management.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Internet connectivity across the plant. Firewalls & VPNs for cybersecurity.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Internet connectivity across the plant. Firewalls & VPNs for cybersecurity.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Forklifts & pallet jacks for material movements across plants & warehouse.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Forklifts & pallet jacks for material movements across plants & warehouse.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Automated production with some manual elements, SCADA & PLCs on all...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Automated production with some manual elements, SCADA & PLCs on all machines, but low capability regarding
										MES.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Leadership is eager to learn. Just starting the Industry 4.0 transformation journey.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Leadership is eager to learn. Just starting the Industry 4.0 transformation journey.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Basic integrations between business systems and operational technologies.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Basic integrations between business systems and operational technologies.</p>
								</div>
							</div>
						</div>
					</div>
					{/* row end */}
				</div>
			</Collapse>
			<Collapse in={props.onTab == 3 ? true : false}>
				{/* Wrapper for learn more */}
				<div className="learn-more-collapse-div">
					{/* Title for plant */}
					<p className="title">
						What does an <span className="b">Accelerating</span> plant look like?
					</p>
					{/* This row contains learn more cards and every card has commone structure
						img-div ===> It has iamge
						content ===> It has text and image
						hover-div ===> It has content and image
					*/}
					<div className="learn-more-card-row">
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Industry 4.0 roadmap in place. Established Centers of Excellence. Building digit....</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Industry 4.0 roadmap in place. Established Centers of Excellence. Building digital training paths for their
										workforce.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Implemented a modern, cloud-based ERP that offers increased visibility & ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Implemented a modern, cloud-based ERP that offers increased visibility & flexibility.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Industrial-grade connectivity everywhere. Industrial cybersecurity platform ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Industrial-grade connectivity everywhere. Industrial cybersecurity platform & a 24/7 emergency response team
										protecting from threats.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>High-traffic material movements have been automated. Warehouse M...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										High-traffic material movements have been automated. Warehouse Management System coordinates plant’s material
										flows.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Highly automated production lines supported by an operator centric MES ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Highly automated production lines supported by an operator centric MES enabling simplified work processes,
										real-time dashboards & process data historization.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Leadership is highly engaged with Industry 4.0. Roles, budgets & responsibilities ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Leadership is highly engaged with Industry 4.0. Roles, budgets & responsibilities are clear across the
										company.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>All systems stream data to a central data repository, making them readily ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										All systems stream data to a central data repository, making them readily available for advanced analytics
										tools.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-8.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Condition monitoring sensors on all critical machines. Early stage implementation ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-8.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Condition monitoring sensors on all critical machines. Early stage implementation of predictive maintenance.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
					</div>
					{/* Row end */}
				</div>
			</Collapse>
			<Collapse in={props.onTab == 4 ? true : false}>
				{/* Wrapper for learn more */}
				<div className="learn-more-collapse-div">
					{/* Title for plant */}
					<p className="title">
						What does a <span className="b">Thriving</span> plant look like?
					</p>
					{/* This row contains learn more cards and every card has commone structure
						img-div ===> It has iamge
						content ===> It has text and image
						hover-div ===> It has content and image
					*/}
					<div className="learn-more-card-row">
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Operational Excellence projects managed digitally through a global platform....</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-1.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Operational Excellence projects managed digitally through a global platform, offering visibility in operations
										& real-time Key Performance Indicators.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>State-of-the-art ERP with cloud computing capabilities, offering flexible zero-cod...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-2.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>State-of-the-art ERP with cloud computing capabilities, offering flexible zero-code customization.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Private cellular network installed, able to safely connect thousands of de...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-3.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Private cellular network installed, able to safely connect thousands of devices with low latency & high
										reliability.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Automated & synchronized material flow. Autonomous Mobile Robots moving ite...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-4.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Automated & synchronized material flow. Autonomous Mobile Robots moving items seamlessly in the warehouse &
										production areas.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Lights-out production. Making, packing & palletizing are completely automa...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-5.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Lights-out production. Making, packing & palletizing are completely automated and synchronized.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Fully integrated Supply Chain Management systems, communicating upstre...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-6.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>
										Fully integrated Supply Chain Management systems, communicating upstream with suppliers, downstream with
										customers and horizontally with the rest of the Organization.
									</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Easy access to advanced Business Intelligence tools combining data from all ...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-7.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Easy access to advanced Business Intelligence tools combining data from all operations across the plant.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-8.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Predictive analytics provide early alerts for quality deficiencies & critical eq...</span>
									<img
										className="ddl"
										src={require("../../assets/img/digital-plan/learn-more-card-ddl.svg").default}
										alt="read-more"
									/>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-8.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Predictive analytics provide early alerts for quality deficiencies & critical equipment maintenance.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-9.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Digital Twins simulate material flows and production status in 3D models.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-9.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Digital Twins simulate material flows and production status in 3D models.</p>
								</div>
							</div>
						</div>
						{/*learn-more-card end*/}
						<div className="learn-more-card">
							<div className="img-div">
								<img src={require("../../assets/img/digital-plan/learn-more-card-10.svg").default} alt="true" />
							</div>
							<div className="content">
								<p>
									<span>Quality control checks performed on the production line with IoT sensors.</span>
								</p>
							</div>
							<div className="hover-div">
								<div className="img-div">
									<img src={require("../../assets/img/digital-plan/learn-more-card-10.svg").default} alt="true" />
								</div>
								<div className="content">
									<p>Quality control checks performed on the production line with IoT sensors.</p>
								</div>
							</div>
						</div>
					</div>
					{/* Row End */}
				</div>
			</Collapse>
		</div>
	);
};
