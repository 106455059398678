import React, { useState,useContext } from "react";
import { useHistory, Link } from 'react-router-dom';
import {closeIcon, couponSvg, angleIcon} from './svg'
import { CartContext } from "../../contexts/CartContext";
import {
MainPopupBothSectionWraper, 
MainPopupBothSectionHeader,
MainPopupBothSectionBody,
MainPopupBothSectionFooter,
UpgradeCouponCard,
UpgradeCouponRow,
UpgradeCouponModal,
UpgradeCouponModalPop,
 } from './styles'

export const UpgradeCouponPopupView = (props) => {
    const {isMainPopup, setIsMainPopup} = props;
    const history = useHistory();
    const { addProduct} = useContext(CartContext);
    const comboData = [
        {   
            id: 1,
            ref_id: 0,
            img:'',
            course_name: `Introduction to Industry 4.0`,
            value: `1`,
            combo_id: 0,
            type: ``,
            price: 0,
            count: 1,
            info: ``,
        },
        {   
            id: 2,
            ref_id: 0,
            img:'',
            course_name: `Digital-Ready Infrastructure`,
            value: `1`,
            combo_id: 0,
            type: ``,
            price: 50,
            count: 1,
            info: `Systems that safeguard and enable the plant’s operations, ensure reliable and secure data streaming acting as a key enabler of Industry 4.0 transformation.`,
        },
        {
            id: 3,
            ref_id: 0,
            img:'',
            course_name: `Tech-Augmented Workforce`,
            combo_id: 0,
            type: ``,
            value: `2`,
            price: 50,
            count: 1,
            info: `Technologies that assist employees in their tasks by augmenting their skillset and optimizing workflows.These technologies provide access to information and expertise and are opening new horizons in productivity, efficiency and innovation, democratizing value creation.`,
        },
        {
            id: 4,
            ref_id: 0,
            img:'',
            course_name: `Factory of the Future Leadership`,
            combo_id: 0,
            type: ``,
            value: `3`,
            price: 50,
            count: 1,
            info: `Leadership behaviours and strategies that inspire, support and offer guidance in order to accelerate and guarantee the digital transformation’s success across the organization.`,
        },
        {
            id: 5,
            ref_id: 0,
            img:'',
            course_name: `Digital Organization`,
            combo_id: 0,
            type: ``,
            value: `4`,
            price: 50,
            count: 1,
            info: `Structures and policies that provide the agility to design the interventions required to organizational culture, development of employee’s skills and new talent acquisition.`,
        },
        {
            id: 6,
            ref_id: 0,
            img:'',
            course_name: `Zero-Touches Production`,
            combo_id: 0,
            type: ``,
            value: `5`,
            price: 50,
            count: 1,
            info: `Hardware, software, and practices, that eliminate human effort in production execution and streamline the production system towards lights-off operations.`,
        },
        {
            id: 7,
            ref_id: 0,
            img:'',
            course_name: `Data-Driven Maintenance`,
            combo_id: 0,
            type: ``,
            value: `6`,
            price: 50,
            count: 1,
            info: `The implementation of methods and systems to proactively manage maintenance based on data, such as equipment condition metrics and historical events.`,
        },
        {
            id: 8,
            ref_id: 0,
            img:'',
            course_name: `Automated Material Flow`,
            combo_id: 0,
            type: ``,
            value: `7`,
            price: 50,
            count: 1,
            info: `Implementation of machinery & systems that automate & optimize material handling processes throughout the manufacturing facility.`,
        },
        {
            id: 9,
            ref_id: 0,
            img:'',
            course_name: `360ᵒ Factory Visibility`,
            combo_id: 0,
            type: ``,
            price: 50,
            count: 1,
            value: `8`,
            info: `Systems that combine data from various areas of the value chain, eliminating silos & accelerating decision-making. They include software that covers several departments, such as ERP and Plant Manager Dashboards and integrations between these systems.`,
        },
        {
            id: 10,
            ref_id: 0,
            img:'',
            course_name: `Intelligent Quality`,
            combo_id: 0,
            type: ``,
            price: 50,
            count: 1,
            value: `9`,
            info: `Effective integration of sensors, software, and techniques that leverage real-time data to manage quality control and quality assurance.`,
        },
        {
            id: 11,
            ref_id: 0,
            img:'',
            course_name: `Sustainability`,
            combo_id: 0,
            type: ``,
            value: `10`,
            price: 50,
            count: 1,
            info: `Methods, systems, behaviours and Industry 4.0 technologies that help create products and solutions that address the needs of the present without compromising the ability of future generations to meet their needs.Typical technologies in this area are Energy Management Software (EMS) and Environmental, Health & Safety (EHS) Software..`,
        },
    ];
   
    const handleCart = ()=>{
        comboData?.forEach((item)=>{
            addProduct(item);
       });
        setIsMainPopup(false);
        history.push('/cart');
    }
   
    const closePopup=()=>{
      setIsMainPopup(false);
    }
    
    return (
      <>
        {isMainPopup && (
          <UpgradeCouponModal data-component="UpgradeCouponModal">
            <UpgradeCouponModalPop data-component="UpgradeCouponModalPop">
              <MainPopupBothSectionWraper data-component="MainPopupBothSectionWraper">
                <MainPopupBothSectionHeader data-component="MainPopupBothSectionHeader">
                  <div className="section-head-left">
                    <i dangerouslySetInnerHTML={{ __html: couponSvg }}></i>
                    <span>Launch Offer Bundle</span>
                  </div>
                  <button
                    className="close-icon"
                    onClick={() => closePopup()}
                  >
                    <i dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                  </button>
                </MainPopupBothSectionHeader>
                <MainPopupBothSectionBody data-component="MainPopupBothSectionBody">
                  <UpgradeCouponCard data-component="UpgradeCouponCard">
                    <UpgradeCouponRow data-component="UpgradeCouponRow">
                      <div className="text-col">
                        <Link to="/training-courses" className="bold-text" onClick={() => closePopup()}>
                          Industry 4.0 Dimensions Training
                        </Link>
                        <span className="sub-text">17+ Hours of Content</span>
                      </div>
                    </UpgradeCouponRow>
                    <UpgradeCouponRow data-component="UpgradeCouponRow">
                      <div className="text-col">
                        <span className="bold-text">
                          UNITAR & SmarterChains Certification
                        </span>
                        <span className="sub-text">
                          Upon successful completion
                        </span>
                      </div>
                      <div className="old-price-col">
                        <span>$2000</span>
                      </div>
                      <div className="new-price-col">
                        <span>$500</span>
                      </div>
                    </UpgradeCouponRow>
                    <UpgradeCouponRow data-component="UpgradeCouponRow">
                      <div className="text-col">
                        <span className="bold-text">
                          Members only Full Access for 1 year
                        </span>
                        <span className="sub-text">
                          Only Until 31st December 2023
                        </span>
                      </div>
                      <div className="old-price-col">
                        <span>$990</span>
                      </div>
                      <div className="new-price-col">
                        <span>FREE</span>
                      </div>
                    </UpgradeCouponRow>
                    <UpgradeCouponRow data-component="UpgradeCouponRow">
                      <div className="text-col total">
                        <span className="bold-text">TOTAL</span>
                      </div>
                      <div className="old-price-col">
                        <span>$2990</span>
                      </div>
                      <div className="colored-price-col">
                        <span>$500</span>
                      </div>
                    </UpgradeCouponRow>
                  </UpgradeCouponCard>
                  <MainPopupBothSectionFooter data-component="MainPopupBothSectionFooter">
                    <button
                      className="footer-cart-btn"
                      onClick={() => handleCart()}
                    >
                      <span className="text">Upgrade</span>
                      <i dangerouslySetInnerHTML={{ __html: angleIcon }}></i>
                    </button>
                  </MainPopupBothSectionFooter>
                </MainPopupBothSectionBody>
              </MainPopupBothSectionWraper>
              {/* MainPopupBothSectionWraper end */}
            </UpgradeCouponModalPop>
          </UpgradeCouponModal>
        )}
      </>
    );
}