/** imported components

  * serviceProvider for ===> working with api
  * SkillsAssessmentPageView ===> cusotm view component

**/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import serviceProvider from '../../services/axios'
import {SkillsAssessmentPageView } from "./skills-assessment-page-view";
//SkillsAssessmentPageController start
export const SkillsAssessmentPageController = (props) => {
	
	//defining variables here
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	
	useEffect(() => {
		const search = async () => {
			const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const tid = getLastItem(window.location.pathname);
			const apiUrl = `/skills?param=training&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResults(data);
		};
		search();
	}, []);
	
  return (
    <>
	<SkillsAssessmentPageView
			quizData={results}
			{...props}
		/>
      
    </>
  );
  
  
  
};


