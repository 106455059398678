/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * useForm for ==> React hooks for form validation.
  * CartContext for ==> add item into the cart.
  * yupResolver for ==> React hooks for form validation.

**/
import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import serviceProvider from "../../services/axios";
import { CartContext } from "../../contexts/CartContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// component of Cart bill
export const CartBillViewMaturity = (props) => {
  const validationSchema = yup.object().shape({
    billing_name: yup.string().required("Required"),
    address: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    zip: yup.string().min(5, "Invalid").required("Required"),
    country: yup.string().required("Required"),
  });

  // create Validation schema using yup plugin
  const { register, handleSubmit, reset, errors, setError, formState } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(validationSchema),
    });
  //function will call on Submit and save data into billingData variable in local storage
  const onSubmit = (data) => {
    localStorage.setItem("billingData", JSON.stringify(data));
    //history.push(`/payment`);
    // props.redirect=true;
  };
  // function to set isValid value conditionally
  const setS = () => {
    formState.isValid = true;
  };
  // function to update address
  const updateAddress = () => {
    let formData = new FormData(document.getElementById("addressForm"));
    serviceProvider
      .post("/profile?type=updateBillData", formData, true)
      .then((response) => {
        console.log(response.data);
        props.RedirectClick();
      });
  };

  // const billingData = JSON.parse(localStorage.getItem("billingData1"));
  const [user, setUser] = useState();
  const [billingData, setBillingData] = useState();
  const { cartItems } = useContext(CartContext);
  const {
    total,
    discount,
    discountPrice,
    itemCount,
    increase,
    decrease,
    removeProduct,
  } = useContext(CartContext);
  const profileDataItem = props.profileData ? props.profileData[0] : [];

  const [totalPaid, setTotalPaid] = useState(0);
  const user1 = JSON.parse(localStorage.getItem("userData"));
  const usid = user1 ? user1.uid : 0;
  useEffect(() => {
    const search = async () => {
      const apiUrl = `/home?param=paiditem&uid=${usid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setTotalPaid(data);
    };
    search();
  }, []);

  return (
    <div className="cart__page__wrapper">
      {/* 
	  		left side section start
			Here is cart time line & 
			My cart detail section
	  	*/}
      <div className="cart__page__left">
        <div className="cart__time__line">
          <div className="time__line__item complete">
            {" "}
            {/*add complete class when completed*/}
            <span>My cart</span>
          </div>
          <div className="time__line__item inprogress">
            <span>Billing Info</span>
          </div>
          <div className="time__line__item">
            <span>Payment</span>
          </div>
        </div>
        {/* cart__time__line end */}
        <div className="cart__left__content">
          <div className="billing__info__items">
            <h2 className="h1">
              <span>Billing Info</span>
              <Link
                to={"/cartmat-" + cartItems[0].course_slug}
                className="cart__back__link"
              >
                <img
                  src={
                    require("../../assets/img/cart-page/back-arrow.svg").default
                  }
                  alt="true"
                />
                Back
              </Link>
            </h2>
            <form
              className="billing__form"
              id="addressForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                type="hidden"
                name="uid"
                defaultValue={profileDataItem && profileDataItem.id}
              />
              <div className="form__ctrl">
                <label>
                  <span className="form__label">Billing Name *</span>
                  <input
                    type="text"
                    name="billing_name"
                    placeholder="e.g. Ellen Baines"
                    defaultValue={
                      profileDataItem && profileDataItem.billing_name
                    }
                    ref={register}
                    onChange={(e) =>
                      setUser({ ...user, billing_name: e.target.value })
                    }
                  />
                </label>
                <div className="error__msg">{errors.billing_name?.message}</div>
              </div>
              {/* form__ctrl end */}

              <div className="form__ctrl">
                <label>
                  <span className="form__label">Address Line *</span>
                  <input
                    type="text"
                    name="address"
                    placeholder="e.g. 12 Main St"
                    defaultValue={profileDataItem && profileDataItem.address}
                    ref={register}
                    onChange={(e) =>
                      setUser({ ...user, address: e.target.value })
                    }
                  />
                </label>
                <div className="error__msg">{errors.address?.message}</div>
              </div>
              {/* form__ctrl end */}

              <div className="form__group__wrapper">
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <label>
                      <span className="form__label">City *</span>
                      <input
                        type="text"
                        name="city"
                        placeholder="e.g. Geneva"
                        defaultValue={profileDataItem && profileDataItem.city}
                        ref={register}
                        onChange={(e) =>
                          setUser({ ...user, city: e.target.value })
                        }
                      />
                    </label>
                    <div className="error__msg">{errors.city?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <label>
                      <span className="form__label">
                        State, province or region *
                      </span>
                      <input
                        type="text"
                        name="state"
                        placeholder="e.g. NY"
                        defaultValue={
                          profileDataItem && profileDataItem.state_id
                        }
                        ref={register}
                        onChange={(e) =>
                          setUser({ ...user, state: e.target.value })
                        }
                      />
                    </label>
                    <div className="error__msg">{errors.state?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
              </div>
              {/* form__group__wrapper end */}

              <div className="form__group__wrapper">
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <label>
                      <span className="form__label">ZIP code *</span>
                      <input
                        type="text"
                        name="zip"
                        className={`form-control ${errors.zip ? "error" : ""}`}
                        placeholder="e.g. 10024"
                        defaultValue={
                          profileDataItem && profileDataItem.zip_code
                        }
                        ref={register}
                        onChange={(e) =>
                          setUser({ ...user, zip: e.target.value })
                        }
                      />
                    </label>
                    <div className="error__msg">{errors.zip?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <div className="label">
                      <span className="form__label">Country *</span>
                      <input
                        type="hidden"
                        name="country"
                        ref={register}
                        value={props.billCountry}
                      />
                      <button
                        type="button"
                        id="billDdl"
                        className={
                          props.billDdl ? "ddl__btn active" : "ddl__btn"
                        }
                        onClick={props.HandleBillDdlOnClick}
                      >
                        {props.billCountry ? (
                          <span>{props.billCountry}</span>
                        ) : (
                          "Pick a country"
                        )}
                        <span className="btn__ddl__arrow">
                          <img
                            src={
                              require("../../assets/img/common-img/ddl-blue.svg")
                                .default
                            }
                            alt="true"
                          />
                        </span>
                      </button>
                      <div className="ddl__list__wrapper">
                        <div className="ddl__list__card">
                          <header>
                            <label>
                              <img
                                src={
                                  require("../../assets/img/common-img/search-icon.svg")
                                    .default
                                }
                                alt="true"
                              />
                              <input
                                onChange={props.HandleOnSearch}
                                type="text"
                                placeholder="Search"
                              />
                            </label>
                          </header>
                          <ul>
                            {props.contryList.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  onClick={() => {
                                    props.HandleBillDdlOnClick();
                                    props.HandleBillDdlValueOnClick(item.name);
                                    setS();
                                  }}
                                >
                                  {item.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="error__msg">{errors.country?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
              </div>
              {/* form__group__wrapper end */}

              <div className="btn__next__wrapper">
                {formState.isValid == false && props.billCountry == "" && (
                  <button disabled type="submit" className="btn__next">
                    Next
                  </button>
                )}
                {props.billCountry != "" && (
                  <button className="btn__next" onClick={updateAddress}>
                    Next
                  </button>
                )}
                {/* {formState.isValid == true && (
									<button className="btn__next" onClick={updateAddress}>
										Next
									</button>
								)} */}
              </div>
            </form>
          </div>
          {/* cart__page__items end */}
        </div>
      </div>
      {/* 
	  		cart__page__right start 
			cart summary with discount
			Checkout button
	    */}
      <div className="cart__page__right">
        <div className="cart__page__box">
          {/* checkout section start */}
          <div className="train--cart-sec train__checkout--wrapp">
            <div className="card__rgt--header train-container">
              <div className="train-row cart__head--wrapp cart__checkout--wrapp">
                <div className="train-col-lg-6 cart__sub--head cart__top--head cart__top--head-item">
                  Items
                </div>
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                  <Link
                    to={"/cartmat-" + cartItems[0].course_slug}
                    className="cart__sub--head-edit"
                  >
                    Edit cart
                  </Link>
                </div>
              </div>
            </div>
            <div className="card__rgt--body train-container">
              {cartItems.map((product) => (
                <div className="train-row cart__head--wrapp train__course-cartdetail">
                  <div className="train-col-lg-10 cart__sub--head cart__top--head">
                    {product.course_name}
                    {/* <span>Training Course</span> */}
                  </div>

                  <div
                    className={
                      discount > 0
                        ? "train-col-lg-2 cart__sub--head cart__sub--head-rgt "
                        : "train-col-lg-2 cart__sub--head cart__sub--head-rgt price-wto-dis"
                    }
                  >
                    €{((product.price * (100 - discount)) / 100).toFixed(2)}
                    {discount > 0 && (
                      <span className="cart__discount--value">
                        €{product.price}
                      </span>
                    )}
                  </div>
                </div>
              ))}

              {/* 	
			  		{totalPaid <= 0 && (
						<div className="train-row cart__head--wrapp train__course-cartdetail">
							<div className="train-col-lg-10 cart__sub--head cart__top--head">
								Digital Maturity Assessment - Limited
								<span>Assessment of Industry 4.0 Vision</span>
							</div>
							<div className="train-col-lg-2 cart__sub--head cart__sub--head-rgt">
								<span className="cart__discount--value train__value--free">FREE</span>
							</div>
						</div>
					)}
					{totalPaid <= 0 && (
						<div className="train-row cart__head--wrapp">
							<div className="train-col-lg-10 cart__sub--head cart__top--head">
								Results: Digital Maturity Assessment - Limited
								<span>Results of Factory of the Future Leadership</span>
							</div>
							<div className="train-col-lg-2 cart__sub--head cart__sub--head-rgt">
								<span className="cart__discount--value train__value--free">FREE</span>
							</div>
						</div>
					)} 
				*/}
            </div>
            <div className="card__rgt--footer train-container">
              <div className="train-row cart__head--wrapp cart__head--wrapp-total">
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-lgt">
                  Total:
                </div>
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                  €{discountPrice}
                  {discount > 0 && (
                    <span className="cart__discount--value train__value--free">
                      €{total}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
