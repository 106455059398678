/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page
  * Helmet ===> for add specific title & CSS for the page.
  * AssignSectionPopup for ===> component cakll for assign user.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  *  _ from 'lodash ===> javascript library

**/

import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { apiEndpoints } from "../../services/api-endpoints";
import $ from "jquery";
import { Modal } from "@material-ui/core";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { AdminWalkthroughController } from "../admin-walkthrough/admin-walkthrough-ctrl";
import { AssignSectionPopup } from "./assessment-assign-popup";
import { array } from "prop-types";
import _ from "lodash";
import { Preloader } from "../Preloader/Preloader";
import { UpgradePopView } from "./UpgradeView";
import {HowToUseView} from "../HowToUse/HowToUseView";


//defining svg image
const SubmitSvg = () => (
  <svg
    width="38"
    height="30"
    viewBox="0 0 38 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8002 5.65428L24.3457 0.199752C24.2179 0.0719106 24.0448 0 23.8636 0H2.7273C1.22382 0 0 1.22312 0 2.7273V27.2728C0 28.7769 1.22382 30.0001 2.7273 30.0001H27.2728C28.7762 30.0001 30.0001 28.7769 30.0001 27.2728V6.13637C30 5.95528 29.9281 5.78212 29.8002 5.65428ZM6.81821 1.36362H21.8182V9.54544C21.8182 10.2971 21.207 10.9091 20.4546 10.9091H8.18182C7.42942 10.9091 6.81821 10.2971 6.81821 9.54544V1.36362ZM24.5455 28.6364H5.45453V16.3636H24.5455V28.6364ZM28.6364 27.2728C28.6364 28.0245 28.0251 28.6364 27.2728 28.6364H25.9092V15.6818C25.9092 15.3049 25.6042 15 25.2273 15H4.77275C4.39588 15 4.09091 15.3049 4.09091 15.6818V28.6364H2.7273C1.97489 28.6364 1.36368 28.0245 1.36368 27.2728V2.7273C1.36368 1.97559 1.97495 1.36368 2.7273 1.36368H5.45459V9.5455C5.45453 11.0496 6.67835 12.2727 8.18182 12.2727H20.4546C21.958 12.2727 23.1819 11.0496 23.1819 9.54544V1.36362H23.5814L28.6364 6.4187V27.2728Z"
      fill="#178ACB"
    />
    <path
      d="M17.0456 9.54575H19.7729C20.1498 9.54575 20.4547 9.24078 20.4547 8.86391V3.40938C20.4547 3.0325 20.1498 2.72754 19.7729 2.72754H17.0456C16.6687 2.72754 16.3638 3.0325 16.3638 3.40938V8.86391C16.3638 9.24078 16.6687 9.54575 17.0456 9.54575ZM17.7274 4.09122H19.091V8.18213H17.7274V4.09122Z"
      fill="#178ACB"
    />
    <circle cx="31" cy="8" r="7" fill="#178ACB" />
    <rect
      x="32"
      y="9"
      width="2"
      height="6"
      rx="1"
      transform="rotate(-180 32 9)"
      fill="white"
    />
    <rect
      x="32"
      y="12.333"
      width="2"
      height="2"
      rx="1"
      transform="rotate(-180 32 12.333)"
      fill="white"
    />
  </svg>
);
const getShortName = (val) => {
  let tmpStrArr = val.split(" ");
  let tmpArrLength = tmpStrArr.length;
  let shortName =
    tmpArrLength > 1
      ? `${tmpStrArr[0].toLowerCase()[0]}${tmpStrArr[tmpArrLength - 1].toLowerCase()[0]
      }`
      : tmpStrArr[0].toLowerCase()[0];
  return shortName;
};

//AssessmentPlantView starts
export const AssessmentPlantView = (props) => {
  //Defining variables here
  const sectionDataItems = props.sectionData;
  if (!sectionDataItems.length) {
    return null;
  }
  const isusers = localStorage.getItem("group_id");
  const sectionDataItem = props.sectionData[0];
  const block = sectionDataItem.block;
  if (block == "block") {
    return <Redirect to="/404" />;
  }
  // console.log(sectionDataItems);
  const catData = sectionDataItem.category;
  if (!catData) {
    return null;
  }

  const inviteteam = sectionDataItem.inviteteam;
  const isAssessmenttour = sectionDataItem.isAssessmenttour;
  if (!inviteteam) {
    return null;
  }

  const totalCat = catData.length;
  // const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
  // const slug = getLastItem(window.location.pathname);
  const slug = sectionDataItem.plantslug;

  const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
  const [isUpgradePopup, setIsUpgradePopup] = useState(false);
  const [isProductionPopup, setIsProductionPopup] = useState(false);
  const [cate, setCate] = useState();
  const [catename, setCateName] = useState();

  const [tmpAssignUser, setTmpAssignUser] = useState("");
  const [assignedUsers, setAssignedUsers] = useState({});
  const [sectionLockedHover, setSectionLockedHover] = useState(false);
  //variables for assessment right section start
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [isSubmitPop, setIsSubmitPop] = useState(false);
  const [isPlanSubmit, setIsPlanSubmit] = useState(true);
  const [isUserHover, setIsUserHover] = useState(false);
  const [isUnlockPop, setIsUnlockPop] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [lockarray, setIsLockarray] = useState(sectionDataItem.locked_sections_array);
  // learn more popup
  //const [isLearnMorePopup, setIsLearnMorePopup] = useState(true);
  //variables for assessment right section end
  //   pre-loader variable
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    let tmpObj = {};
    let tmpArr = [];
    let uniqArr = [];
    inviteteam?.forEach((item) => {
      tmpArr.push(item.invited_category);
    });
    uniqArr = [...new Set(tmpArr)];
    uniqArr.forEach((item) => {
      let name = `username${item}`;
      let tmpEl = inviteteam.filter(
        (innerItem) => innerItem.invited_category == item
      );
      tmpObj = { ...tmpObj, [name]: tmpEl };
    });

    setAssignedUsers(tmpObj);
    if (sectionDataItem.is_datasubmitted != 0) {
      setIsFinalSubmit(true);
      //setIsSubmitPop(!isSubmitPop);
      setIsPlanSubmit(false);
    }
  }, []);

  // Function for final submit assessment
  const handleFinalSubmit = () => {
    // add loader here
    setShowPreloader(true);
    setIsFinalSubmit(!isFinalSubmit);
    setIsSubmitPop(!isSubmitPop);
    setIsPlanSubmit(!isPlanSubmit);
    serviceProvider
      .post(
        "/assessmentmaturity?type=savechapter",
        JSON.stringify({
          user_id: sectionDataItem?.uid,
          plant_id: sectionDataItem?.plant_id,
          section_id: 0,
        }),
        true
      )
      .then((response) => {
        serviceProvider
          .post(
            "/resultsmaturityassess?type=calculate",
            JSON.stringify(
              {
                plant_id: sectionDataItem?.plant_id,
                user_id: sectionDataItem?.uid,
              },
              true
            )
          )
          .then((response) => {
           
            var userData = JSON.parse(localStorage.getItem("userData"));
            userData.progress = 100;  
            localStorage.setItem("userData", JSON.stringify(userData));
            window.location = `/home`;
            
          });
      });
  };
  //
  const handleUpgradePopupClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    localStorage.setItem(`upgradeSection`, `assessment`);
    localStorage.setItem(`plantName`, sectionDataItem.plant_name);
    localStorage.setItem(`plantId`, sectionDataItem.plant_id);
    localStorage.setItem(`comboIdget`, JSON.stringify(sectionDataItem.combo_id_get));
    document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
  }
  //Function to handle Unlock a chapter
  const handleUnlock = () => {
    setIsUnlockPop(!isUnlockPop);
    setIsFinalSubmit(!isFinalSubmit);
    setIsSubmit(!isSubmit);
    setIsPlanSubmit(!isPlanSubmit);
    setIsLockarray(['a']);
    serviceProvider
      .post(
        "/assessmentmaturity?type=unlockchapter",
        JSON.stringify({
          user_id: sectionDataItem?.uid,
          plant_id: sectionDataItem?.plant_id,
          section_id: 0,
        }),
        true
      )
      .then((response) => { });
  };
  //Function to handle modal
  const HandleModal = () => {
    setIsModal(!isModal);
    setSlideIndex(0);
    props.handleGetValue(0);
  };
  //Function for handle slide
  const HandleSlideIndex = (ev) => {
    if (ev === "min" && slideIndex > 0) {
      let tmpVal = slideIndex - 1;
      setSlideIndex(tmpVal);
      props.handleGetValue(tmpVal);
    } else if (!ev && slideIndex < TotalSlides - 1) {
      let tmpVal = slideIndex + 1;
      setSlideIndex(tmpVal);
      //console.log(tmpVal)
      props.handleGetValue(tmpVal);
    }
  };
  const otherScreen = () => {
    let unlockSection = document.querySelector(".unlockbtn");
  };
  //variable declaration for walkthrough
  const [sliderActive, setSliderActive] = useState(0);
  //function to handle walkthrough and class addition
  const handleGetValue = (e = null) => {
    setSliderActive(e);
    let objDiv = document.querySelector(".training-content");
    let headerLink = document.querySelector(".header-page-link-ul");
    let headerProgress = document.querySelector(".header-right-frame");
    let projectNamebox = document.querySelector(".text-box");
    let projectName = document.querySelector(".text-left p");
    let icon = document.querySelector(".text-left img");
    let sideArrow = document.querySelector(".angle-box");
    let unlockSection = document.querySelector(".unlockbtn");
    let blur = document.querySelector(".admin-walkthrough");
    let progressBar = document.querySelector(".header-right-frame");

    if (e === 0) {
      blur.classList.add("blur-effect");
    } else {
      blur.classList.remove("blur-effect");
    }
    if (e === 1) {
      headerLink.classList.add("z-index9999");
      progressBar.classList.add("z-index9999");
    } else {
      progressBar.classList.remove("z-index9999");
      headerLink.classList.remove("z-index9999");
    }
    if (e === 2) {
      projectName.classList.add("z-index9999");
      icon.classList.add("z-index9999");
      projectNamebox.classList.add("z-index999");
    } else {
      projectName.classList.remove("z-index9999");
      icon.classList.remove("z-index9999");
      projectNamebox.classList.remove("z-index999");
    }
    if (e === 3) {
      sideArrow.classList.add("z-index9999");
    } else {
      sideArrow.classList.remove("z-index9999");
    }
    if (e === 4) {
      objDiv.scrollTop = objDiv.scrollHeight;
    } else {
      objDiv.scrollTop = 0;
    }
    if (e === 4 && sectionDataItem.noline == "") {
      unlockSection.classList.add("z-index9999");
    } else {
      if (unlockSection) {
        //added if to check class exists
        unlockSection.classList.remove("z-index9999");
      }
    }
  };
  //Function call for upgrade popup
  const HandleUpdgradePopup = () => {
    setIsUpgradePopup(!isUpgradePopup);
  };
  //Function for assign popup
  const handleAssignPoup = (e) => {
    e.stopPropagation();
    e.preventDefault();
    var cat = $(e.currentTarget).attr("data-id");
    var catname = $(e.currentTarget).attr("data-name");
    setCate(cat);
    setCateName(catname);
    setIsAssignSectionPopup(true);
  };
  //changes done for ipad
  const inActiveSectionClick = (e) => {
    let unlockSection = document.querySelector(".unlockbtn");
    let assess = document.querySelector(".assessment-main");
    if (typeof e.target.className === 'string' && !e.target.className.includes("assignee-link-unlock")) {
      /*
       * comented for SCNV-2478
         setIsProductionPopup(true);
      */
      assess.classList.add("active");
      unlockSection.classList.remove("z-index9999");
    }
  };
  //Function for lock section
  const setSectionLocked = async (e, sectionId) => {
    e.preventDefault();

    if (
      typeof e.target.className.includes !== "undefined" &&
      !e.target.className.includes("assignee-link-unlock")
    ) {
      try {
        await serviceProvider.post("/sectionlock", {
          uid: sectionDataItem?.uid,
          section_id: sectionId,
          type: 1,
          status: 1,
          plant_id: sectionDataItem?.plant_id,
        });
      } catch (err) {
        console.log(error);
      }
      props.history.push(`/assessment/chapter/${slug}/${sectionId}`);
    } else {
      props.history.push(`/assessment/chapter/${slug}/${sectionId}`);
    }
  };

  const chapterDataItemSections = catData.map((chapter, index) => {
    const count = index + 1;
    // chapter.cardclass="active";
    return (
      //card wrapper for assessment
      <a
        key={chapter.id}
        data-index={index}
        href={
          chapter.cardclass == "active" && !chapter?.locked_user
            ? `/assessment/chapter/${slug}/${chapter.id}`
            : "#"
        }
        className={`all-sellection-card ${sectionDataItem?.combo_id_get && !sectionDataItem?.combo_id_get?.includes('2001') ? 'upgrade-lock' : ''} ${chapter.cardclass} `}
        onClick={
          (e) =>
            chapter.cardclass1 == "withuser" &&
              chapter.cardclass == "inactive" &&
              index == 10 &&
              (group_id == 11 || group_id == 2)
              ? inActiveSectionClick(e)
              : chapter.cardclass == "active" && !chapter?.locked_user
                ? setSectionLocked(e, chapter.id)
                : "#"
          // : HandleUpdgradePopup()
        }
      >
        {/* Left box of the card which is containing image */}
        <div className="left-img-box">
          <img
            src={`${apiEndpoints.baseUrl}/${chapter.category_img}`}
            alt={chapter.category_name}
          />
        </div>
        {index == 10 &&
          sectionDataItem.noline == "" &&
          (isusers == 11 || isusers == 2 || sectionDataItem.open_line == 1) && (
            <div
              className="unlockbtn"
              onClick={() => {
                otherScreen();
                setIsProductionPopup(true);
              }}
            >
              Unlock this section
            </div>
          )}
        {/* text-box has text ,l assigne/unassigned user */}
        <div className="text-box">
          <div className="text-left">
            <h2 className="h1">
              <span>{chapter.category_name}</span>
              <img
                src={
                  require("../../assets/img/all-section/card-i-icon.svg")
                    .default
                }
                alt="i"
              />
            </h2>

            {/* scnv-2147 start */}
            <div className="responsible-text">
              <p className="scnv-2147-p">
                <span className="res-text">Responsible:</span>

                {!assignedUsers[`username${chapter.id}`] ||
                  assignedUsers[`username${chapter.id}`]?.length == 0 ? (
                  <>
                    {isusers == 11 || isusers == 2 ? (
                      <button
                        className={`res-link-2147 res-link ${isusers == 11 || isusers == 2 ? "" : chapter.cardclass
                          } `}
                        data-id={chapter.id}
                        data-name={chapter.category_name}
                        onClick={(e) => {
                          if (isusers == 11 || isusers == 2) {
                            handleAssignPoup(e);
                            setTmpAssignUser(`username${chapter.id}`);
                          }
                        }}
                      >
                        <span
                          className="svg-span"
                          dangerouslySetInnerHTML={{ __html: assignIcon }}
                        ></span>
                        <span>Assign</span>
                      </button>
                    ) : (
                      <span className="res-text">Unassigned</span>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      className="scnv-2147-cir-user"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onMouseEnter={() => setIsUserHover(true)}
                      onMouseLeave={() => setIsUserHover(false)}
                    >
                      <ul className="cir-2147-ul">
                        {assignedUsers[`username${chapter.id}`]
                          ?.slice(0, 2)
                          .map((assinged_user, index) => {
                            let shortName =
                              assinged_user.full_name &&
                                assinged_user.full_name != "Not Registered"
                                ? `${assinged_user.name[0]}${assinged_user?.last_name
                                  ? assinged_user?.last_name[0]
                                  : ""
                                }`
                                : getShortName(assinged_user.email);

                            return (
                              <li
                                className={`cir-2147-li ${!assinged_user.profile_pic
                                  ? assinged_user.avcolor
                                  : "cir-transparent"
                                  }`}
                                key={index}
                              >
                                <div className="cir-ddl2147">
                                  {!assinged_user.profile_pic ? (
                                    <div className={`${assinged_user.avcolor}`}>
                                      {/* {assinged_user.name} */}
                                      {shortName}
                                      {/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
                                    </div>
                                  ) : (
                                    <div
                                      className={`cir2147 cir-b-none cir-transparent`}
                                    >
                                      <img
                                        className="h__profile__img_cir"
                                        src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
                                        alt={assinged_user.profile_pic}
                                      />
                                    </div>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        {assignedUsers[`username${chapter.id}`]?.length > 2 && (
                          <div className="cir-2147-li circle-num">
                            <span>
                              {assignedUsers[`username${chapter.id}`]?.length - 2}
                            </span>
                          </div>
                        )}
                      </ul>
                      <div className="cir-2147-ddl">
                        <ul className="cir-ddl-user">
                          {assignedUsers[`username${chapter.id}`]?.map(
                            (assinged_user, index) => {
                              let shortName =
                                assinged_user.full_name &&
                                  assinged_user.full_name != "Not Registered"
                                  ? `${assinged_user.name[0]}${assinged_user?.last_name
                                    ? assinged_user?.last_name[0]
                                    : ""
                                  }`
                                  : getShortName(assinged_user.email);

                              return (
                                <li className="cir-ddl2147-li" key={index}>
                                  {/* <div className="cir-ddl2147">
                                  <span
                                    className={`cir2147 ${assinged_user.avcolor}`}
                                  >
                                    {shortName}

                                  </span>
                                </div> */}
                                  <div className="cir-ddl2147">
                                    {!assinged_user.profile_pic ? (
                                      <div
                                        className={`cir2147 ${assinged_user.avcolor}`}
                                      >
                                        {/* {assinged_user.name} */}
                                        {/* {`${assinged_user.name && assinged_user.name[0]}${assinged_user.last_name && assinged_user.last_name[0]}`} */}
                                        {shortName}
                                      </div>
                                    ) : (
                                      <div className={`cir2147 cir-b-none`}>
                                        <img
                                          className="h__profile__img_cir"
                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${assinged_user.profile_pic}`}
                                          alt={assinged_user.profile_pic}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="txt-s2147">
                                    <p className="name-2147">
                                      {assinged_user.full_name &&
                                        assinged_user.full_name !=
                                        "Not Registered"
                                        ? assinged_user.full_name
                                        : assinged_user.email}
                                    </p>
                                    <p className="role-2147">
                                      {assinged_user.job_title
                                        ? assinged_user.job_title
                                        : ""}
                                    </p>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                        {(isusers == 11 || isusers == 2) && (
                          <div className="ft-ddl2147">
                            <button
                              className="edit-tm"
                              data-id={chapter.id}
                              data-name={chapter.category_name}
                              onClick={(e) => {
                                if (isusers == 11 || isusers == 2) {
                                  handleAssignPoup(e);
                                  setTmpAssignUser(`username${chapter.id}`);
                                }
                              }}
                            >
                              <span
                                className="svg-span"
                                dangerouslySetInnerHTML={{ __html: assignIcon }}
                              ></span>
                              <span>Edit Team</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </p>

              {lockarray?.includes(chapter.id) ? (
                <div className="submit-img-text">
                  <img
                    src={
                      require("../../assets/img/assessment-right-section/check.svg")
                        .default
                    }
                    alt="Green Check"
                  />
                  <span>Data Submitted</span>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* scnv-2147 end */}
          </div>
          {/* Locked Section start*/}
          {chapter?.section_locked && chapter.cardclass == "active" && (
            <div
              className="assigned-rgt-sub-mid"
              onMouseEnter={() => setSectionLockedHover(true)}
              onMouseLeave={() => setSectionLockedHover(false)}
            >
              <button className="lock-btn">
                <img
                  alt="true"
                  src={require("../../assets/img/assigned/lock.svg").default}
                />
                <span>Section Locked</span>
              </button>
              <div className="locked-section-popup">
                <p>
                  The section is temporarily locked as it is currently being
                  edited by:
                </p>
                <div className="pop-footer">
                  <div className={`circle ${chapter?.locked_user_avcolor}`}>{`${chapter?.locked_user_first_name[0]
                    }${chapter?.locked_user_last_name
                      ? chapter?.locked_user_last_name[0]
                      : ""
                    }`}</div>
                  <span className="name">{chapter?.locked_user}</span>
                </div>
              </div>
            </div>
          )}
          {/* Locked Section end */}
          {/* Progress circle */}

          <div
            style={{
              visibility:
                sectionDataItem.noline == "" &&
                  index == 10 &&
                  assignedUsers[`username${chapter.id}`] === undefined
                  ? ""
                  : "",
            }}
            className="text-cir"
          >
            {sectionDataItem?.combo_id_get && !sectionDataItem?.combo_id_get?.includes('2001') &&
              <div className="upgrade-locked-wrapper" onClick={(e) => handleUpgradePopupClick(e)}>
                <i className="upgrade-lock-icon" dangerouslySetInnerHTML={{ __html: upgradeLockIcon }}></i>
                <div className="upgrade-tooltip">
                  <span>Upgrade & Unlock</span>
                </div>
              </div>
            }
            <SvgCircle value={chapter.catprogress} />
          </div>
        </div>
        {/*  Right section for navigate to next page */}
        <div
          style={{
            visibility:
              sectionDataItem.noline == "" &&
                index == 10 &&
                assignedUsers[`username${chapter.id}`] === undefined
                ? ""
                : "",
          }}
          className="angle-box"
        >
          <img
            src={
              require("../../assets/img/all-section/right-angle.svg").default
            }
            alt="true"
          />
        </div>

        {/* popup that will show on card hover */}
        {!isUserHover && (
          <div
            className={`sellection-card-popup ${index > 6 && "last-popup"} ${sectionLockedHover ? " sellection-card-popup-hidden" : ""
              }`}
          >
            <h2>{chapter.category_name}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: chapter.category_description }}
            ></div>
          </div>
        )}
      </a>
    );
    // }
  });

  const user = localStorage.getItem("group_id");
  const group_id = user ? user : 0;
  const [distext, setDistext] = useState("Submit your input");
  //Function to handle Plant Admin
  const handleplantAdmin = () => {
    // console.log("hello");
    if (group_id == 11) {
      window.location = "/maturity-dashboard-admin";
    } else {
      window.location = "/maturity-dashboard";
    }
  };

  const uid = sectionDataItem.uid;
  const plantid = sectionDataItem.plant_id;
  //Function to handle result page
  const handleresultpage = () => {
    setDistext("Submitting Input...");
    serviceProvider
      .post(
        "/resultsmaturityassess?type=calculate",
        JSON.stringify({ plant_id: plantid, user_id: uid }, true)
      )
      .then((response) => {
        //console.log(response);
        window.location = `/result/${slug}`;
        setDistext("Submit your input");
      });
    // window.location = "/result";
  };
  useEffect(() => {
    if (props.see == "open_line") {
      otherScreen();
      setIsProductionPopup(true);
      props.setSee(false);
    }
  }, [props.see]);

  // useEffect for condition upgrade
  useEffect(() => {
    // Condition for Upgrade Input Check
    
    return () => {
      localStorage.removeItem(`upgradeSection`);
      localStorage.removeItem(`plantName`);
      localStorage.removeItem(`plantId`);
      localStorage.removeItem(`comboIdget`);
    }
  }, []);

  return (
    <>
      {/* Page Title */}
      <Helmet>
        <title>Assessment | SmarterChains</title>
        <meta name="description" content={`Assessment`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/">{sectionDataItem.plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem
        to={`/assessment/${slug}`}
        onClick={() => setIsProductionPopup(false)}
      >
       Assessment
      </BreadcrumbsItem>
      {isProductionPopup && (
        <BreadcrumbsItem to="#">Production Lines Setup</BreadcrumbsItem>
      )}
      {/* assessment wrapper start ("assessment-main" class added for custom changes)*/}
      <div
        className="all-sellection-main assessment-main"
        style={{ display: isProductionPopup ? "none" : "" }}
      >
        <div
          className="asst-plant-details-wrapper"
          style={{
            display:
              sectionDataItem.noline == "" &&
                (group_id == 11 ||
                  group_id == 2 ||
                  sectionDataItem.open_line == 1)
                ? "block"
                : "none",
          }}
        >
          {/*hide this button for SCNV-2147*/}
          {/*<button
                      className="addPlantLines"
                      onClick={() => setIsProductionPopup(true)}      
                    >
                      <img
                        src={
                          require("../../assets/img/production-line/bg-yellow-i-icon.svg")
                            .default
                        }
                        alt=""
                      />
                      <span>Plant details</span>
                    </button>
            */}
          {/* end hide this button for SCNV-2147*/}
        </div>
        {/* left section of assessment section which has card,responsible-text,assign/unassign user, progress circle and angle button to navigate next page.
        ("asst-card" class replaced from left-assessment-section for right-assessment section) */}
        <div className="left-assessment-section calc-button-wrap">
          {chapterDataItemSections}
          {sectionDataItem.progress == 100 &&
            (group_id == 11 || group_id == 2) ? (
            // footer buttons that is come conditional.
            <div className="res-calc-btn-div"></div>
          ) : (
            <></>
            // <div className="res-calc-btn-div">
            //   <button className="result-calc-btn" onClick={handleplantAdmin}>
            //     Go to plant page
            //   </button>
            // </div>
          )}
        </div>
        {/* Assessment Right section changes start. Section has divided into two horizontal parts.*/}
        <div className="right-assessment-section">
          {/* top section containing progress of assessment */}
          <div className="top-progress-bar">
            <div className="progress-bar-section">
              <div className="header-progress">
                <p className="progress-title">Overall Progress:</p>
                <h2>{sectionDataItem.progress}%</h2>
                <div className="header-progress-bar">
                  <div style={{ width: sectionDataItem.progress + "%" }}></div>
                </div>
              </div>
            </div>
            <div className="submit-section-count">
              <span className="heading">Sections Submitted: </span>
              {/* "sections submitted" wiill be replace from "sections completed" when the all sections will be completed */}
              <div className="count">
                {sectionDataItem.locked_sections} / {totalCat}
              </div>
              {sectionDataItem.is_datasubmitted == 1 && group_id == 14 && (
                <>
                  <div className="submit-text pro">
                    <img
                      src={
                        require("../../assets/img/assessment-right-section/green-check.svg")
                          .default
                      }
                      alt="Green Check"
                    />
                    <span>Data Submitted</span>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Submission section which will come conditional */}
          {isPlanSubmit && (group_id == 11 || group_id == 2) && (
            <div className="assessment-submission-section">
              <div className="top-sec">
                {sectionDataItem.progress == 100 &&
                  (group_id == 11 || group_id == 2) ? (
                  <span>Action required</span>
                ) : (
                  <span className="progress-text">Assessment In-Progress</span>
                )}
              </div>
              <div className="bottom-content-section">
                <p className="bottom-content">
                  When all sections are complete, click the "Submit Data" button
                  to finalize the assessment.
                </p>
              </div>
              <div className="button-wrap">
                <button
                  className={`submit-btn ${sectionDataItem.progress == 100 &&
                    (group_id == 11 || group_id == 2)
                    ? "active"
                    : ""
                    }`}
                  disabled={
                    sectionDataItem.progress == 100 &&
                      (group_id == 11 || group_id == 2)
                      ? false
                      : true
                  }
                  onClick={() => setIsSubmitPop(!isSubmitPop)}
                >
                  Submit Data
                </button>
              </div>
            </div>
          )}

          {isFinalSubmit && (group_id == 11 || group_id == 2) && (
            <>
              <div className="assessment-submission-section final-submit">
                <div className="top-sec">
                  <span>No further action required</span>
                </div>
                <div className="bottom-content-section">
                  <p>Your data have been successfully submitted.</p>
                </div>
                <div className="button-wrap">
                  <div className="submit-text">
                    <img
                      src={
                        require("../../assets/img/assessment-right-section/green-check.svg")
                          .default
                      }
                      alt="Green Check"
                    />
                    <span>Data Submitted</span>
                  </div>
                </div>
              </div>


            </>
          )}
          {isFinalSubmit && sectionDataItem.maturity_result_counter < 2 && (group_id == 11 || group_id == 2) && (
            <>
              <div className="assessment-submission-section unlock-section">
                <div className="top-sec">
                  <img
                    src={
                      require("../../assets/img/assessment-right-section/lock.svg")
                        .default
                    }
                    alt="Lock"
                  />
                  <span>Unlock section to change submitted data</span>
                </div>
                <div className="bottom-content">
                  <p className="top-txt">
                    All the sections will be unlocked to allow changes.
                  </p>
                  <p>
                    Please note, that any change may result in the addition or
                    removal of questions.
                  </p>
                </div>
                <div className="button-wrap">
                  <button
                    className="unlock-btn"
                    onClick={() => setIsUnlockPop(!isUnlockPop)}
                  >
                    Unlock & edit data
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {/* Submit Popup for assessment right section start*/}
        {isSubmitPop && (
          <div className="popup-box-blue">
            <div className="center-box">
              <div className="content-section">
                <h2>Are you sure, you would like to submit your data?</h2>
                <p>
                  Please note, that you will not be able to make changes after
                  submission.
                </p>
                <div className="button-section">
                  <button
                    className="cancel"
                    onClick={() => setIsSubmitPop(!isSubmitPop)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit"
                    onClick={() => handleFinalSubmit()}
                  >
                    Yes, submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Unlock Popup */}
        {isUnlockPop && (
          <div className="popup-box-red">
            <div className="center-box">
              <div className="content-section">
                <h2>Are you sure, you would like to unlock all the sections?</h2>
                <p>
                  Any change may result in the addition or removal of questions.
                </p>
                <p>
                  <b>Notice :</b> You can recalibrate the maturity assessment only one time.
                </p>
                <div className="button-section">
                  <button
                    className="cancel"
                    onClick={() => setIsUnlockPop(!isUnlockPop)}
                  >
                    Cancel
                  </button>
                  <button className="submit" onClick={() => handleUnlock()}>
                    Yes, unlock
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* assessment right  section end*/}
      </div>
      {/* UPGRADE POPUP start */}
      <Modal
        open={isUpgradePopup}
        // onClose={HandleUpdgradePopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="home-index-upgrade-popup"
      >
        <div className="upgrade-popup-content">
          {/* Close button to close popup */}
          <button className="close-btn" onClick={HandleUpdgradePopup}>
            <img
              src={
                require("../../assets/img/common-img/close-icon-blue.svg")
                  .default
              }
              alt="close"
            />
          </button>
          {/* section that is containing image */}
          <div className="upgrd-popup-img">
            <img
              src={
                require("../../assets/img/home-index/digital-maturity.gif")
                  .default
              }
              alt="digital_maturity"
            />
          </div>
          {/* Section that is containing heading and text */}
          <div className="upgrd-popup-body">
            <h2 className="h1">
              Buy full IR4.0 Technology Assessment to Unlock Plants
            </h2>
            <p>
              Upgrade to the plan that best matches your profile and start the
              Assessment as early as today.
            </p>
            <Link
              to="/plans"
              className="btn-upgrade-now"
              onClick={HandleUpdgradePopup}
            >
              Upgrade Now{" "}
            </Link>
          </div>
        </div>
      </Modal>
      {/* {isAssessmenttour != 1 &&
      <AdminWalkthroughController handleGetValue={handleGetValue} isusers={isusers} />

       } */}
      {/* Assessment walkthrough component call conditionally for first time login user/admin */}
      {isAssessmenttour != 1 && !isProductionPopup && (
        <AssessmentWalkthroughView
          handleGetValue={handleGetValue}
          isusers={isusers}
        />
      )}
      {/* Production Line popup has started */}
      <ProductionLinePop
        open={isProductionPopup}
        datasec={sectionDataItem}
        setOpen={setIsProductionPopup}
        uid={uid}
        slug={slug}
        getAssessmentDetails={props.getAssessmentDetails}
      />
      {/* Assign user popup component call */}
      <AssignSectionPopup
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        catagory={cate}
        catagoryName={catename}
        plant_id={plantid}
        plant_name={sectionDataItem.plant_name}
        setUpDataPage={props.setUpData}
        tmpAssignUser={tmpAssignUser}
        open={isAssignSectionPopup}
        setOpen={setIsAssignSectionPopup}
        {...props}
      />
      {showPreloader && (
        <Preloader preLoaderMsg="Give us a moment while we generate your IR4.0 results for your factory" whiteBG={true} />
       )} 
      {/* Learn More popup */}
      {/* <LearnMorePopup isPopOpen={isLearnMorePopup} counter={sectionDataItem.maturity_training} setPopOpen={setIsLearnMorePopup} /> */}
      {/* Upgrade Popup */}
      {sectionDataItem?.combo_id_get && !sectionDataItem?.combo_id_get?.includes('2001') && <UpgradePopView />}
      {/* How to use popup */}
      <HowToUseView/>
    </>
  );
};
// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCircle = (props) => {
  const { value } = props;
  return (
    <svg className="percent__svgs" viewBox="0 0 36 36">
      <text
        className="percent__text"
        x="50%"
        y="53%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="10"
        fontWeight="600"
      >
        {value}
        <tspan fontSize="5">%</tspan>
      </text>
      <path
        className="circle-bg"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke="#EBF5FA"
        strokeWidth="2.4"
        fill="none"
      ></path>
      <path
        className="circle"
        strokeDasharray={`${value}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={
          value < 100 && value > 0
            ? "#FFBC1F"
            : value === 100
              ? "#40D30D"
              : "transparent"
        }
        strokeWidth="2.4"
        fill="none"
      ></path>
    </svg>
  );
};
//Function for production line popup
const ProductionLinePop = (props) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  //   const [inputvalue, setInputvalue] = useState([]);
  const [planCardArr, setPlanCardArr] = useState([0, 1, 2]);

  const [lastshowpop, setlastshowpop] = useState("hide-class");
  const [planCardData, setPlanCardData] = useState([]);
  const [isBtnDisaled, setIsBtnDisaled] = useState(true);

  useEffect(() => {
    let plant_id = props.datasec.plant_id;
    let user_id = props.datasec.uid;
    const apiUrl = `/assessmentmaturity?param=autosave&plantid=${plant_id}&uid=${user_id}`;
    serviceProvider
      .get(apiUrl, true, {})
      .then((response) => {
        const { data: data_lines } = response;
        if (data_lines.length) {
          setPlanCardData(data_lines);
          setPlanCardArr([...Array(data_lines.length).keys()]);
          let tmp = 0;
          $("input[name='productionLines[]']").each(function () {
            if ($(this).val() == "") {
              tmp = 1;
            }
          });
          if (tmp == 0) {
            setIsBtnDisaled(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleAdd = () => {
    let tmpArr = [...planCardArr];
    tmpArr.push(tmpArr.length);
    setPlanCardArr(tmpArr);
    let count = 1;
    let hascount = 0;
    $("input[name='productionLines[]']").each(function () {
      if ($(this).val() != "") {
        hascount++;
      }
      count++;
    });

    if (count == hascount) {
      setIsBtnDisaled(false);
    } else {
      setIsBtnDisaled(true);
    }
  };

  const removeInput = (e, index) => {
    let targetIndex = index;
    let tmpArr = [...planCardArr];

    tmpArr.splice(index, 1);
    setPlanCardArr(tmpArr);

    let tempData = [...planCardData];
    tempData.splice(index, 1);

    setPlanCardData(tempData);
    let count = -1;
    let hascount = 0;
    $("input[name='productionLines[]']").each(function () {
      if ($(this).val() != "") {
        hascount++;
      }
      count++;
    });
    if (count == 0) {
      setIsBtnDisaled(true);
    }
  };

  const validation = (e) => {
    e.preventDefault();
    var error = false;

    $("input[name='productionLines[]']").each(function (index, value) {
      var id = "lError" + $(this).attr("id").substr(8);

      // console.log($(this).val());
      if ($(this).val().length == 0) {
        $("#" + id).text("Line name required.");
        error = true;
        // return;
      } else {
        $("#" + id).text("");
        error = false;
      }
    });
    $("input[name='productionLines[]']").each(function (index, value) {
      // var error = false;
      var validLineName = $(this).val().replace(/\s+/g, " ").trim();
      if (validLineName.trim() != "") {
        if (checkLineName(validLineName.trim()) > 1) {
          $("#plantLineError").text(
            "The Production Lines name must be unique."
          );
          error = true;
          return false;
        } else {
          $("#plantLineError").text("");
        }
      } else {
        error = true;
        return false;
      }
    });
    if (!error) {
      setlastshowpop("");
    }
  };
  function checkLineName(lineName) {
    var counter = 0;
    $("input[name='productionLines[]']").each(function (index, value) {
      var validLineName2 = $(this).val().replace(/\s+/g, " ").trim();
      if (validLineName2.trim().toLowerCase() == lineName.toLowerCase()) {
        counter++;
      }
    });
    return counter;
  }

  const saveData = (e) => {
    e.preventDefault();
    var plantid = $(e.target).attr("data-plantid");
    var userid = $(e.target).attr("data-userid");

    var values = [];
    $("input[name='productionLines[]']").each(function () {
      var validLineName2 = $(this).val().replace(/\s+/g, " ").trim();
      values.push(validLineName2.trim());
    });
    serviceProvider
      .post(
        "/assessmentmaturity?type=plantline",
        JSON.stringify({
          plant_id: plantid,
          user_id: userid,
          line_name: values,
        }),
        true
      )
      .then(async (response) => {
        if (response.data == 0) {
          window.location = "/user-unassign";
        }
        window.location = `/assessment/chapter/${props.slug}/9`;
        await props.getAssessmentDetails();
        props.setOpen(false);
      })
      .catch((error) => {
        return error;
      });
  };

  const [walkthroughClose, setWalkthroughClose] = useState(true);

  const handelCloseWalkThrough = () => {
    document.querySelectorAll(".highlightnew").forEach((item) => {
      item.classList.remove("highlightnew");
    });
    document.querySelectorAll(".change-col-length").forEach((item) => {
      item.classList.remove("change-col-length");
    });
    document
      .querySelector(".plant-details-popup")
      .classList.remove("highlighttxt");
    serviceProvider
      .post(
        "/home?type=setproductionline",
        JSON.stringify({ uid: props.uid }),
        true
      )
      .then((response) => {
        setWalkthroughClose(false);
      });
  };
  useEffect(() => {
    if (props.datasec.isproductiontour != 1) {
      document
        .querySelector(".plant-details-popup")
        .classList.add("highlighttxt");
      document
        .querySelector(".plant-detail-left .text-right")
        .classList.add("highlightnew");
      document
        .querySelector(".plant-detail-left .text-right")
        .classList.add("ex-border");
      document
        .querySelector(".plant-one-card-wrapper")
        .classList.add("highlightnew");
      document
        .querySelector(".pl-d-add-more-btn")
        .classList.add("highlightnew");
      document.querySelectorAll(".col-7").forEach((item) => {
        item.classList.add("change-col-length");
      });
    } else {
    }
  }, []);

  const handleChange = (e, index, action = "edit") => {
    let tempData = [];
    $("input[name='productionLines[]']").each(function () {
      tempData.push({ line_name: $(this).val() });
    });
    setPlanCardData(tempData);
  };

  const autoSave = _.debounce(function () {
    var values = [];
    let count = 0;
    let hascount = 0;
    $("input[name='productionLines[]']").each(function () {
      var validLineName = $(this).val();
      if (validLineName.replace(/\s/g, "").length) {
        values.push($(this).val());
      }
      if ($(this).val() != "") {
        hascount++;
      }
      count++;
    });
    if (count == hascount && values.length != 0) {
      setIsBtnDisaled(false);
    } else {
      setIsBtnDisaled(true);
    }

    serviceProvider
      .post(
        "/assessmentmaturity?type=plantline_autosave",
        JSON.stringify({
          plant_id: props.datasec.plant_id,
          user_id: props.datasec.uid,
          line_name: values,
        }),
        true
      )
      .then(async (response) => {
        if (response.data == 0) {
          window.location = "/user-unassign";
        }
      })
      .catch((error) => {
        console.error();
      });
  }, 300);

  return (
    <>
      <div
        style={{ display: props.open ? "block" : "none" }}
        className="production-line-popup"
      >
        <div className="production-line-wrap">
          <div
            className={`plant-details-popup  ${!!lastshowpop ? "" : "production-line-popup-active"
              }`}
            id="plantDetailsPopup"
            style={{}}
          >
            <div className="asst-abt-content-body plant-dtails-body">
              <div className="row no-gutters px-0">
                <div className="col asst-abt-left">
                  <div className="main-flex-asstdiv">
                    <div className="asstdiv-top">
                      <div className="d-flex ml-auto admin-page-empty-wrapper no-assesed-wrapper px-0">
                        <div className="col-7 admin-page-empty-left">
                          <div className="plant-detail-left">
                            <h2 className="asst-h1">Production Lines Setup</h2>
                            <p className="plant-details-para">
                              Please list the production lines that you would
                              like to assess below.
                            </p>
                            <p className="plant-details-para">
                              You can enter as many lines as you want, but we
                              recommend to list up to 10 lines for a better
                              assessment experience.
                            </p>
                            <p className="plant-details-para">
                              Please note that after you submit your lines, you
                              will not have the option to change them.
                            </p>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="pl-right-div d-flex align-items-center">
                            <div className="col-1 px-0">
                              <img
                                src={
                                  require("../../assets/img/production-line/carbon_idea.svg")
                                    .default
                                }
                                alt=""
                                className="w-100"
                              />
                            </div>
                            <div className="col-11 py-0 pl-d-pl-30">
                              A good practice is to group production lines with
                              identical technology stacks and process flows into
                              single items for the assessment.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*asstdiv-top end*/}
                    <div className="asstdiv-list">
                      <div className="d-flex ml-auto admin-page-empty-wrapper no-assesed-wrapper px-0 pb-0 pt-0">
                        <div className="col-7 admin-page-empty-left">
                          <div className="plant-detail-left">
                            <div className="plant-detail-card-wrapper">
                              {/* {inputvalue.map((item, index)=>{
                              return( */}
                              {planCardArr.map((item, index) => (
                                <div
                                  className="plant-one-card-wrapper"
                                  id={`plantCard${index}`}
                                  key={`plantCard${index}`}
                                >
                                  <div className="plant-details-card">
                                    <div className="pl-d-card-left">
                                      {index + 1}
                                    </div>
                                    <div className="pl-d-content">
                                      <div className="content-lft">
                                        {/* Changes related to SCNV-2245 */}
                                        <input
                                          type="text"
                                          name="productionLines[]"
                                          id={`prodline${index + 1}`}
                                          placeholder="Line Name"
                                          maxlength
                                          className="w-100 pl-card-txt border-0"
                                          onChange={(e) => {
                                            handleChange(e, index);
                                            autoSave();
                                          }}
                                          value={
                                            planCardData[index]
                                              ? planCardData[index].line_name
                                              : ""
                                          }
                                        />
                                        {/* Changes related to SCNV-2245 */}
                                      </div>
                                      <a
                                        className="content-rgt"
                                        onClick={(e) => {
                                          removeInput(e, index);
                                          autoSave();
                                        }}
                                      >
                                        <img
                                          src={
                                            require("../../assets/img/production-line/plant-del-icon.svg")
                                              .default
                                          }
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <sapn
                                    id={`lError${index + 1}`}
                                    className="error-txt d-block"
                                    style={{ display: "block" }}
                                  >
                                    {" "}
                                  </sapn>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-5"></div>
                      </div>
                      <div className="d-flex ml-auto admin-page-empty-wrapper no-assesed-wrapper px-0 pt-0">
                        <div className="col-7 admin-page-empty-left">
                          <div className="plant-detail-left">
                            <a
                              href="javascript:void(0)"
                              className="pl-d-add-more-btn"
                              onClick={(e) => {
                                handleAdd();
                                autoSave();
                              }}
                            >
                              {" "}
                              <span>+</span>Add More
                            </a>
                            <br />
                            <small
                              id="plantLineError"
                              style={{ color: "red", fontSize: 10 }}
                            >
                              {" "}
                            </small>
                            <div className="text-right">
                              {/* <button
                                onClick={handleClose}
                                className="pl-d-save-line-btn close-popup-btn"
                                data-target="plantDetailsPopup"
                              >
                                Cancel
                              </button> */}
                              <button
                                className="pl-d-save-line-btn saveConfirm"
                                data-plantid={props.datasec.plant_id}
                                data-userid={props.datasec.uid}
                                disabled={isBtnDisaled}
                                onClick={(e) => {
                                  validation(e);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*asstdiv-list end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {walkthroughClose && props.datasec.isproductiontour != 1 ? (
          <div className="admin-wakthrough-modal">
            <div className="admin-walkthrough">
              {/* <div className="bg-admin">
                            <img src={require(`../../assets/img/admin-walkthrough/admin-bg-${slideIndex}.svg`).default} alt={`admin-bg-${slideIndex}`} />
                        </div> */}
              <div className="carousel-wrapper left">
                <button
                  className="modal-close "
                  onClick={() => {
                    handelCloseWalkThrough();
                  }}
                >
                  <img
                    src={
                      require("../../assets/img/admin-walkthrough/close.svg")
                        .default
                    }
                    alt="X"
                  />
                </button>

                <div className="slide-item">
                  <div className="left-frame">
                    <img
                      src={
                        require(`../../assets/img/admin-walkthrough/production.gif`)
                          .default
                      }
                      alt="fiftyfive"
                    />
                  </div>
                  <div className="right-frame">
                    <div className="slide-content">
                      <h2 className="slide-h1">Production Lines Setup</h2>
                      <p className="slide-p">
                        Insert the name of the production lines you would like
                        to assess and submit them when ready.
                      </p>
                      <p className="slide-p">
                        Remember that you can not change your input after
                        submission.
                      </p>
                    </div>
                    <div className="slide-footer">
                      <div className="button-div">
                        <button
                          className="blue"
                          onClick={() => {
                            handelCloseWalkThrough();
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* slide 1 end */}
              </div>
            </div>
          </div>
        ) : null}
        {/* Submit pop up start */}
        <div className={`submit-popup-ast active ${lastshowpop}`}>
          <div className="popup-wrapper submitdata-popup">
            <div className="close-btn-wrap">
              <button
                className="close-btn"
                onClick={() => {
                  setlastshowpop("hide-class");
                }}
              >
                <img
                  src={
                    require("../../assets/img/la-admin/close-pop.svg").default
                  }
                  alt="Close"
                  className="close-img"
                />
              </button>
            </div>
            <div className="popup">
              <div className="content-box">
                <SubmitSvg />
                <p className="text">
                  By submitting the data you will no longer have the opportunity
                  for futher editing.
                </p>
                <p className="text">Are you sure you want to proceed</p>
              </div>
              <div className="button-wrapper">
                <button
                  type="submit"
                  className="btn cancel-button primary"
                  onClick={() => {
                    setlastshowpop("hide-class");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn submit-btn secondary"
                  data-plantid={props.datasec.plant_id}
                  data-userid={props.datasec.uid}
                  onClick={(e) => {
                    saveData(e);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Submit pop up end */}
      </div>
      {/* Assessment Walkthrough */}
    </>
  );
};

// Assessment Page Walkthrough

const AssessmentWalkthroughView = (props) => {
  //Variable Declaration
  const users = JSON.parse(localStorage.getItem("userData"));
  const UserName = users ? users.name : "First Name";
  const uid = users ? users.uid : "";
  const user = localStorage.getItem("group_id");
  const group_id = user ? user : 0;
  const [sliderStatus, setSliderStatus] = useState(1);
  //Function to slide wlakthrough from one to next
  const HandleSlider = (val) => {
    setSliderStatus(val);
  };
  //Function to close the slider
  const handleClose = () => {
    serviceProvider
      .post("/home?type=setassessment", JSON.stringify({ uid: uid }), true)
      .then((response) => {
        setSliderStatus(0);
      });
    let mainDiv = document.querySelector(".training-main-wrapper");
    let contentDiv = document.querySelector(
      ".training-main-wrapper .training-content"
    );
    if (mainDiv) {
      mainDiv.classList.remove("walkthrough--working");
    }
    if (contentDiv) {
      contentDiv.classList.remove("content--no-scroll");
    }
  };
  //Conditions to add and remove dynamic classes as per requirement for walkthrough
  useEffect(() => {
    if (sliderStatus === 2) {
      document.querySelector(".training-header").classList.add("div-z99");
      document.querySelector(".ng-sidebar").classList.add("div-zremove");
      document.querySelector(".training-content").classList.add("div-z99");
    } else {
      document.querySelector(".training-header").classList.remove("div-z99");
      document.querySelector(".ng-sidebar").classList.remove("div-zremove");
      document.querySelector(".training-content").classList.remove("div-z99");
    }
    if (sliderStatus === 3) {
      document.querySelector(".training-content").classList.add("div-z992");
    } else {
      document.querySelector(".training-content").classList.remove("div-z992");
    }
    if (sliderStatus === 4) {
      document.querySelector(".training-content").classList.add("div-z993");
    } else {
      document.querySelector(".training-content").classList.remove("div-z993");
    }
    if (sliderStatus === 5) {
      document.querySelector(".training-content").classList.add("div-z994");
      let contentDiv = document.querySelector(
        ".training-main-wrapper .training-content"
      );
      if (contentDiv) {
        contentDiv.scrollTop = contentDiv.scrollHeight;
      }
      // window.scrollBy(0,50);
    } else {
      document.querySelector(".training-content").classList.remove("div-z994");
      let contentDiv = document.querySelector(
        ".training-main-wrapper .training-content"
      );
      if (contentDiv) {
        contentDiv.scrollTop = 0;
      }
    }
    if (sliderStatus === 6) {
      document.querySelector(".training-content").classList.add("div-z995");
    } else {
      document.querySelector(".training-content").classList.remove("div-z995");
    }
  }, [sliderStatus]);

  useEffect(() => {
    let mainDiv = document.querySelector(".training-main-wrapper");
    let contentDiv = document.querySelector(
      ".training-main-wrapper .training-content"
    );
    if (mainDiv) {
      mainDiv.classList.add("walkthrough--working");
    }
    if (contentDiv) {
      contentDiv.classList.add("content--no-scroll");
    }

    return () => {
      if (mainDiv) {
        mainDiv.classList.remove("walkthrough--working");
      }
      if (contentDiv) {
        contentDiv.classList.remove("content--no-scroll");
      }
    };
  }, []);

  return (
    <>
      {/* link css */}
      <Helmet>
        <link
          rel="stylesheet"
          href={require("./assess-walkthrough.scss").default}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* link css end*/}
      {/* Condition for assessment walkthrough for admin and user */}
      {group_id == 11 || group_id == 2 ? (
        <>
          {/* Assessment walkthrough for admin */}
          <section className="general-information-walkthrough">
            <div className="general-information-top">
              {/*---------------------  Walkthrough Wrapper  ------------------------*/}
              <div className="general-info-walkthrough">
                {sliderStatus === 1 ? (
                  <div className="g-walkthrough-screen1 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step1.gif")
                                .default
                            }
                            alt="Question Step 1"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>1 of 6</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">
                                Hi <span>{UserName}</span>
                              </h4>
                              <p className="txt">
                                Welcome to the Smart Manufacturing Assessment.
                              </p>
                              <p className="txt">
                                We want to quickly show you around. You can skip
                                at any time.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(5);
                                    }}
                                    disabled={sliderStatus === 5 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(6);
                                    }}
                                    disabled={sliderStatus === 6 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    className="prev-link link"
                                    onClick={handleClose}
                                  >
                                    No thanks
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    className="link solid"
                                  >
                                    Let’s go
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 2 ? (
                  <div className="g-walkthrough-screen2 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step2.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>2 of 6</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">Assessment Basics</h4>
                              {/*comment2*/}
                              <p className="txt">
                                This is the Assessment's main interface.
                              </p>
                              <p className="txt">
                                Track the overall and each section's progress.
                                Use the top navigation to move around.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(5);
                                    }}
                                    disabled={sliderStatus === 5 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(6);
                                    }}
                                    disabled={sliderStatus === 6 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    className="prev-link link"
                                  >
                                    Previous
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    className="let link solid"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 3 ? (
                  <div className="g-walkthrough-screen3 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step3.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>3 of 6</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">
                                Assessment Collaboration
                              </h4>
                              {/*comment2*/}
                              <p className="txt">
                                Learn more information about each section.
                              </p>
                              <p className="txt">
                                Invite your colleagues to answer them using
                                their email address.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(5);
                                    }}
                                    disabled={sliderStatus === 5 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(6);
                                    }}
                                    disabled={sliderStatus === 6 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    className="prev-link link"
                                  >
                                    Previous
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    className="let link solid"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 4 ? (
                  <div className="g-walkthrough-screen3 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step4.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>4 of 6</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">Select a Section</h4>
                              {/*comment2*/}
                              <p className="txt">
                                Click on the card to access the section and
                                view its chapters.
                              </p>
                              <p className="txt">
                                Navigate through and complete the
                                questionnaire(s) you have been assigned to.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(5);
                                    }}
                                    disabled={sliderStatus === 5 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(6);
                                    }}
                                    disabled={sliderStatus === 6 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    className="prev-link link"
                                  >
                                    Previous
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(5);
                                    }}
                                    className="let link solid"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 5 ? (
                  <div className="g-walkthrough-screen4 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step5.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>5 of 6</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">
                                Unlock Production Execution
                              </h4>
                              {/*comment2*/}
                              <p className="txt">
                                In order to unlock the section, you or another
                                team member, need to set up the production
                                lines.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(5);
                                    }}
                                    disabled={sliderStatus === 5 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(6);
                                    }}
                                    disabled={sliderStatus === 6 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    className="prev-link link"
                                  >
                                    Previous
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(6);
                                    }}
                                    className="let link solid"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 6 ? (
                  <div className="g-walkthrough-screen5 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step6.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>6 of 6</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">Submit Data</h4>
                              {/*comment2*/}
                              <p className="txt">
                                When all Sections are submitted, you will need
                                to finalize the submission using the key actions
                                interface.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(5);
                                    }}
                                    disabled={sliderStatus === 5 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(6);
                                    }}
                                    disabled={sliderStatus === 6 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    className="prev-link link"
                                  >
                                    Restart
                                  </button>
                                  <button
                                    onClick={() => handleClose()}
                                    className="let link solid"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {/* Assessment walkthrough for user */}
          <section className="general-information-walkthrough">
            <div className="general-information-top">
              {/*---------------------  Walkthrough Wrapper  ------------------------*/}
              <div className="general-info-walkthrough">
                {sliderStatus === 1 ? (
                  <div className="g-walkthrough-screen1 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step1.gif")
                                .default
                            }
                            alt="Question Step 1"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>1 of 4</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">
                                Hi <span>{UserName}</span>
                              </h4>
                              <p className="txt">
                                Welcome to the Industry 4.0 Maturity
                                Assessment.
                              </p>
                              <p className="txt">
                                We want to quickly show you around. You can skip
                                at any time.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    className="prev-link link"
                                    onClick={handleClose}
                                  >
                                    No thanks
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    className="link solid"
                                  >
                                    Let’s go
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 2 ? (
                  <div className="g-walkthrough-screen2 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step2.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>2 of 4</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">Assessment Basics</h4>
                              {/*comment2*/}
                              <p className="txt">
                                This is the Assessment's main interface.
                              </p>
                              <p className="txt">
                                Track the overall and each section's progress.
                                Use the top navigation to move around.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    className="prev-link link"
                                  >
                                    Previous
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    className="let link solid"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 3 ? (
                  <div className="g-walkthrough-screen3 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step3.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>3 of 4</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">
                                Assessment Collaboration
                              </h4>
                              {/*comment2*/}
                              <p className="txt">
                                Learn more information about each section.
                              </p>
                              <p className="txt">
                                See who is responsible to complete the section.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    className="prev-link link"
                                  >
                                    Previous
                                  </button>
                                  <button
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    className="let link solid"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {sliderStatus === 4 ? (
                  <div className="g-walkthrough-screen3 gstep">
                    {/* walkthrough wrapper start */}
                    <div className="g-walkthrough-wrapper">
                      {/* walkthrough wrapper divided in two parts */}
                      <div className="g-walk-row">
                        {/* left part is containing gif image related to walkthrough step. */}
                        <div className="g-walk-col-left">
                          <img
                            src={
                              require("../../assets/img/assessment-walkthrough/asmt-step4.gif")
                                .default
                            }
                            alt="Question Walkthrough"
                            className="close-img"
                          />
                        </div>
                        {/* right part has heading, text and buttons navigate from one to next, next to previous and close. */}
                        <div className="g-walk-col-right">
                          <div className="col-right-card-wrap">
                            <div className="card-header">
                              <div className="left-text-wrap">
                                <p>4 of 4</p>
                              </div>
                              <div className="close-btn">
                                <button className="close" onClick={handleClose}>
                                  <img
                                    src={
                                      require("../../assets/img/chapter-walkimg/close-gray.svg")
                                        .default
                                    }
                                    alt="digital_maturity"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="card-body">
                              <h4 className="heading">Select a Section</h4>
                              {/*comment2*/}
                              <p className="txt">
                                Click on the card to access the section and
                                view its chapters.
                              </p>
                              <p className="txt">
                                Navigate through and complete the
                                questionnaire(s) you have been assigned to.
                              </p>
                            </div>
                            <div className="card-footer">
                              <div className="card-footer-section">
                                <div className="card-footer-left">
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    disabled={sliderStatus === 1 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(2);
                                    }}
                                    disabled={sliderStatus === 2 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(3);
                                    }}
                                    disabled={sliderStatus === 3 ? true : false}
                                  ></button>
                                  <button
                                    className="circle"
                                    onClick={() => {
                                      HandleSlider(4);
                                    }}
                                    disabled={sliderStatus === 4 ? true : false}
                                  ></button>
                                </div>
                                <div className="card-footer-right">
                                  <button
                                    onClick={() => {
                                      HandleSlider(1);
                                    }}
                                    className="prev-link link"
                                  >
                                    Restart
                                  </button>
                                  <button
                                    onClick={() => handleClose()}
                                    className="let link solid"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
// Learn More Popup
// const LearnMorePopup = (props) => {
//   return (
//     <>
//       {props.isPopOpen && props.counter < 4 &&
//         <div className="learn-pop-wrapper">
//           <div className="learn-pop-wrap">
//             <button className="close-btn" onClick={() => props.setPopOpen(false)}>
//               <i className="close-icon" dangerouslySetInnerHTML={{ __html: crossSvg }}></i>
//             </button>
//             <Link className="learn-pop-card" to="/product-training/maturity-assessment/introduction">
//               <div className="learn-img-wrap">
//                 <i className="video-icon" dangerouslySetInnerHTML={{ __html: videoSvg }}></i>
//               </div>
//               <div className="learn-text-wrap">
//                 <h2>Maturity Assessment Training</h2>
//                 <p>Learn more by watching training video </p>
//               </div>
//             </Link>
//           </div>
//         </div>
//       }

//     </>
//   );
// };

// scnv-2147
const assignIcon = `
<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.33366 7.58329C9.17532 7.58329 10.667 6.09163 10.667 4.24996C10.667 2.40829 9.17532 0.916626 7.33366 0.916626C5.49199 0.916626 4.00033 2.40829 4.00033 4.24996C4.00033 6.09163 5.49199 7.58329 7.33366 7.58329ZM7.33366 9.24996C5.10866 9.24996 0.666992 10.3666 0.666992 12.5833V14.25H14.0003V12.5833C14.0003 10.3666 9.55866 9.24996 7.33366 9.24996Z" fill="#118ACB"/>
<circle cx="14.834" cy="13.5833" r="3.5" fill="white"/>
<rect x="14.334" y="11.0833" width="1" height="5" fill="#118ACB"/>
<rect x="17.334" y="13.0833" width="1" height="5" transform="rotate(90 17.334 13.0833)" fill="#118ACB"/>
</svg>

`;
// svg images for learn more popup
const videoSvg = `
<svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="68" height="68" rx="2" fill="#E5F0FF"/>
<rect width="68" height="68" rx="2" fill="url(#paint0_linear_5325_73994)" fill-opacity="0.24"/>
<path d="M43.0585 21.7325C41.8161 21.7462 40.6285 22.2465 39.7499 23.1263C38.8714 24.0061 38.3717 25.1955 38.358 26.4396C38.3557 27.0584 38.4757 27.6715 38.7111 28.2437C38.9465 28.8158 39.2926 29.3357 39.7296 29.7732C40.1665 30.2108 40.6856 30.5574 41.2569 30.7931C41.8283 31.0289 42.4406 31.149 43.0585 31.1467C44.3009 31.1329 45.4885 30.6326 46.367 29.7528C47.2456 28.873 47.7452 27.6837 47.7589 26.4396C47.7613 25.8208 47.6413 25.2077 47.4059 24.6355C47.1705 24.0634 46.8243 23.5435 46.3874 23.1059C45.9504 22.6684 45.4314 22.3218 44.86 22.086C44.2887 21.8503 43.6764 21.7301 43.0585 21.7325Z" fill="#4D4D4F"/>
<path d="M56.8426 25.8192L55.0822 24.0562C54.3754 23.451 53.6219 22.9027 52.8287 22.4167C49.973 20.5578 46.6401 19.5684 43.2341 19.5684C39.8281 19.5684 36.4953 20.5578 33.6396 22.4167C32.8464 22.9027 32.0929 23.451 31.3862 24.0562C30.7554 24.5981 30.1668 25.1875 29.6257 25.8192C29.4723 25.988 29.3873 26.208 29.3873 26.4362C29.3873 26.6645 29.4723 26.8845 29.6257 27.0533L31.3862 28.8162C32.056 29.4208 32.7743 29.9692 33.534 30.4558C33.562 30.4558 33.5888 30.4669 33.6086 30.4868C33.6284 30.5066 33.6396 30.5335 33.6396 30.5616C36.487 32.4392 39.8248 33.4328 43.2341 33.4175C46.6409 33.4153 49.9739 32.4232 52.8287 30.5616C52.8568 30.5616 52.8836 30.5504 52.9034 30.5306C52.9232 30.5107 52.9344 30.4838 52.9344 30.4558C53.6562 29.9445 54.3604 29.4333 55.0822 28.8162C55.713 28.2743 56.3015 27.685 56.8426 27.0533C56.9459 26.864 57 26.6519 57 26.4362C57 26.2206 56.9459 26.0084 56.8426 25.8192ZM53.7794 27.4587C53.1633 27.97 52.4591 28.4813 51.7373 28.9925C51.7092 28.9925 51.6824 29.0036 51.6626 29.0235C51.6428 29.0433 51.6316 29.0703 51.6316 29.0983C49.0901 30.7829 46.1059 31.6726 43.0581 31.6546C40.013 31.6549 37.0336 30.7666 34.4846 29.0983C34.4566 29.0983 34.4297 29.0872 34.4099 29.0673C34.3901 29.0475 34.379 29.0206 34.379 28.9925C33.6521 28.546 32.9684 28.0325 32.3368 27.4587C31.9168 27.1704 31.5378 26.8265 31.2101 26.4362C31.5629 26.0711 31.9393 25.7294 32.3368 25.4137C32.953 24.9024 33.6748 24.3912 34.379 23.8799C36.9056 22.1448 39.8976 21.2162 42.9613 21.2162C46.025 21.2162 49.0169 22.1448 51.5436 23.8799C52.2704 24.3265 52.9541 24.84 53.5857 25.4137C54.0039 25.6977 54.3777 26.0423 54.6948 26.4362C54.4744 26.835 54.1602 27.1738 53.7794 27.4235V27.4587Z" fill="#4D4D4F"/>
<path d="M31.3731 21.5181C31.9431 21.1342 32.5431 20.7912 33.1681 20.4919C36.2641 18.822 39.7689 17.9347 43.3398 17.9168C46.9129 17.9194 50.4219 18.8077 53.5115 20.4919L55.3065 21.5181C55.4955 21.6127 55.6966 21.6843 55.9049 21.7311C56.0755 21.7352 56.2442 21.6959 56.393 21.6175C56.5419 21.5391 56.6655 21.4245 56.7508 21.2858C56.8327 21.1827 56.8915 21.065 56.9234 20.94C56.9554 20.8149 56.96 20.685 56.9368 20.5583C56.9136 20.4315 56.8633 20.3105 56.7888 20.2026C56.7142 20.0946 56.617 20.0021 56.5032 19.9305C55.8873 19.5216 55.2466 19.1466 54.5844 18.8075C51.1472 16.9995 47.2799 16.034 43.3398 16C39.3672 15.9995 35.4656 16.9883 32.0333 18.8656C31.3679 19.1633 30.7266 19.5062 30.1146 19.8917C30.0013 19.9615 29.9045 20.0523 29.8302 20.1585C29.7559 20.2648 29.7056 20.3842 29.6824 20.5094C29.6592 20.6346 29.6636 20.7629 29.6953 20.8865C29.7271 21.01 29.7855 21.1262 29.867 21.2277C29.9287 21.3561 30.0219 21.4689 30.1391 21.5568C30.2563 21.6448 30.394 21.7055 30.541 21.7338C30.688 21.7621 30.8401 21.7574 30.9847 21.7199C31.1294 21.6824 31.2624 21.6132 31.3731 21.5181Z" fill="#4D4D4F"/>
<path d="M11.9157 51.9989H16.7217C16.9645 51.9989 17.1974 51.9023 17.3691 51.7304C17.5407 51.5585 17.6372 51.3253 17.6372 51.0822V35.8502C17.6395 35.7283 17.6176 35.6072 17.5726 35.494C17.5277 35.3807 17.4606 35.2775 17.3753 35.1905C17.2901 35.1035 17.1884 35.0344 17.0762 34.9873C16.964 34.9401 16.8434 34.9158 16.7217 34.9158H11.9157C11.7939 34.9158 11.6734 34.9401 11.5612 34.9873C11.4489 35.0344 11.3473 35.1035 11.262 35.1905C11.1768 35.2775 11.1097 35.3807 11.0647 35.494C11.0198 35.6072 10.9978 35.7283 11.0002 35.8502V51.0822C11.0002 51.3253 11.0966 51.5585 11.2683 51.7304C11.44 51.9023 11.6729 51.9989 11.9157 51.9989Z" fill="#4D4D4F"/>
<path d="M19.4322 26.5603V51.0831C19.4322 51.3263 19.5286 51.5594 19.7003 51.7313C19.872 51.9032 20.1048 51.9998 20.3476 51.9998H25.1361C25.2578 52.0022 25.3787 51.9802 25.4919 51.9352C25.605 51.8902 25.7079 51.823 25.7948 51.7377C25.8817 51.6523 25.9507 51.5504 25.9978 51.438C26.0449 51.3257 26.0692 51.205 26.0691 51.0831V26.5603C26.0692 26.4384 26.0449 26.3177 25.9978 26.2053C25.9507 26.0929 25.8817 25.9911 25.7948 25.9058C25.7079 25.8204 25.605 25.7532 25.4919 25.7082C25.3787 25.6632 25.2578 25.6412 25.1361 25.6435H20.3476C20.1048 25.6435 19.872 25.7401 19.7003 25.9121C19.5286 26.084 19.4322 26.3172 19.4322 26.5603Z" fill="#4D4D4F"/>
<path d="M27.9015 35.427V51.0821C27.9015 51.3252 27.998 51.5584 28.1696 51.7303C28.3413 51.9022 28.5741 51.9988 28.8169 51.9988H33.623C33.8658 51.9988 34.0987 51.9022 34.2704 51.7303C34.4421 51.5584 34.5385 51.3252 34.5385 51.0821V35.427C34.5385 35.1838 34.4421 34.9506 34.2704 34.7787C34.0987 34.6068 33.8658 34.5102 33.623 34.5102H28.8169C28.5855 34.5425 28.3709 34.6495 28.2057 34.8149C28.0405 34.9804 27.9337 35.1952 27.9015 35.427Z" fill="#4D4D4F"/>
<path d="M36.4928 40.7155V51.0818C36.4928 51.3249 36.5893 51.558 36.761 51.73C36.9327 51.9019 37.1655 51.9985 37.4083 51.9985H42.2144C42.4572 51.9985 42.69 51.9019 42.8617 51.73C43.0334 51.558 43.1298 51.3249 43.1298 51.0818V40.7155C43.1298 40.4724 43.0334 40.2392 42.8617 40.0673C42.69 39.8954 42.4572 39.7988 42.2144 39.7988H37.4083C37.1769 39.831 36.9623 39.9381 36.7971 40.1035C36.6319 40.2689 36.5251 40.4838 36.4928 40.7155Z" fill="#4D4D4F"/>
<g filter="url(#filter0_d_5325_73994)">
<mask id="mask0_5325_73994" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="40" y="40" width="24" height="24">
<rect x="40" y="40" width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_5325_73994)">
<path d="M49.5 56.5L56.5 52L49.5 47.5V56.5ZM52 62C50.6167 62 49.3167 61.7375 48.1 61.2125C46.8833 60.6875 45.825 59.975 44.925 59.075C44.025 58.175 43.3125 57.1167 42.7875 55.9C42.2625 54.6833 42 53.3833 42 52C42 50.6167 42.2625 49.3167 42.7875 48.1C43.3125 46.8833 44.025 45.825 44.925 44.925C45.825 44.025 46.8833 43.3125 48.1 42.7875C49.3167 42.2625 50.6167 42 52 42C53.3833 42 54.6833 42.2625 55.9 42.7875C57.1167 43.3125 58.175 44.025 59.075 44.925C59.975 45.825 60.6875 46.8833 61.2125 48.1C61.7375 49.3167 62 50.6167 62 52C62 53.3833 61.7375 54.6833 61.2125 55.9C60.6875 57.1167 59.975 58.175 59.075 59.075C58.175 59.975 57.1167 60.6875 55.9 61.2125C54.6833 61.7375 53.3833 62 52 62Z" fill="white"/>
</g>
</g>
<defs>
<filter id="filter0_d_5325_73994" x="39" y="39" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="1" dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5325_73994"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5325_73994" result="shape"/>
</filter>
<linearGradient id="paint0_linear_5325_73994" x1="9" y1="10" x2="50" y2="52" gradientUnits="userSpaceOnUse">
<stop stop-color="#4C4C4E" stop-opacity="0.3"/>
<stop offset="1" stop-color="#4C4C4E"/>
</linearGradient>
</defs>
</svg>
  `
const crossSvg = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_4495_385134" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
  </mask>
  <g mask="url(#mask0_4495_385134)">
  <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#4D4D4F"/>
  </g>
  </svg>
  `
const upgradeLockIcon = `<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b_1_36481)">
  <rect width="82" height="82" rx="8" fill="white" fill-opacity="0.8"/>
  <rect x="0.2" y="0.2" width="81.6" height="81.6" rx="7.8" stroke="#118ACB" stroke-width="0.4"/>
</g>
<mask id="mask0_1_36481" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="29" y="29" width="24" height="24">
  <rect x="29" y="29" width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_36481)">
  <path d="M35 51C34.45 51 33.9792 50.8042 33.5875 50.4125C33.1958 50.0208 33 49.55 33 49V39C33 38.45 33.1958 37.9792 33.5875 37.5875C33.9792 37.1958 34.45 37 35 37H36V35C36 33.6167 36.4875 32.4375 37.4625 31.4625C38.4375 30.4875 39.6167 30 41 30C42.3833 30 43.5625 30.4875 44.5375 31.4625C45.5125 32.4375 46 33.6167 46 35V37H47C47.55 37 48.0208 37.1958 48.4125 37.5875C48.8042 37.9792 49 38.45 49 39V49C49 49.55 48.8042 50.0208 48.4125 50.4125C48.0208 50.8042 47.55 51 47 51H35ZM41 46C41.55 46 42.0208 45.8042 42.4125 45.4125C42.8042 45.0208 43 44.55 43 44C43 43.45 42.8042 42.9792 42.4125 42.5875C42.0208 42.1958 41.55 42 41 42C40.45 42 39.9792 42.1958 39.5875 42.5875C39.1958 42.9792 39 43.45 39 44C39 44.55 39.1958 45.0208 39.5875 45.4125C39.9792 45.8042 40.45 46 41 46ZM38 37H44V35C44 34.1667 43.7083 33.4583 43.125 32.875C42.5417 32.2917 41.8333 32 41 32C40.1667 32 39.4583 32.2917 38.875 32.875C38.2917 33.4583 38 34.1667 38 35V37Z" fill="#118ACB"/>
</g>
<defs>
  <filter id="filter0_b_1_36481" x="-4" y="-4" width="90" height="90" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_36481"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1_36481" result="shape"/>
  </filter>
</defs>
</svg>`