/** imported components

  * CartContext ===> custom component
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  *  Modal ===> component from UI used to work as popup

**/
import React, { useContext, useState, Fragment } from "react";
import { Collapse, Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
//CourseDetailRight component start
export const CourseDetailRight = (props) => {
    //defining variables here
    const courseDataItems = props.courseData;
    if (!courseDataItems.length) {
        return null;
    }
    const secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);

        var hDisplay = h == 0 ? "0h " : h + "h ";
        var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
        // var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
        var sDisplay = "";
        return hDisplay + mDisplay + sDisplay;
    };
    const courseDataItem = props.courseData[0];
    let totalTime = 0;
    courseDataItem.sections.map((section, index) => {
        totalTime = totalTime + parseInt(section.section_duration);
    });
    const { addProduct, cartItems, increase } = useContext(CartContext);
    const isInCart = (props) => {
        return !!cartItems.find((item) => item.id === courseDataItem.id);
    };
    const [isUpgradePopup, setIsUpgradePopup] = useState(false);
    //function to handle upgrade popup
    const HandleUpdgradePopup = () => {
        setIsUpgradePopup(!isUpgradePopup);
    };

    const handleUpgradePopupClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let upgradeBtn = document.querySelector('.header-icons-li.upgrade .upgrade-btn');
        if(upgradeBtn){
            upgradeBtn.setAttribute('data-active-tab','0');
            upgradeBtn.click();
        }
    }
    return (
        <React.Fragment>
            {/* card has one wrapper */}
            <div className="courseRgt-card">
                {/* this wrapper has
                    heading
                    course-detail-list
                    buy-course-card
                */}
                <div className="courseRgt-list-wrapper">
                    <h2 className="txt-course-detail">Course Details</h2>
                    {/* txt-course-detail end */}
                    <ul className="course-detail-ul">
                        {/* this section has
                            icon
                            course-name
                            ptrice
                        */}
                        <li className="course-detail-li">
                            <div className="course-li-icon">
                                <img
                                    src={
                                        require("../../assets/img/course-detail/eur.svg")
                                            .default
                                    }
                                    alt="true"
                                />
                            </div>
                            <div className="course-li-p">
                                <p className="li-p-bold">
                                    {/* {courseDataItem.price} EUR */}
                                    Undisclosed
                                </p>
                                <p className="li-p-small">Price</p>
                            </div>
                        </li>
                        <li className="course-detail-li">
                            <div className="course-li-icon">
                                <img
                                    src={
                                        require("../../assets/img/course-detail/time.svg")
                                            .default
                                    }
                                    alt="true"
                                />
                            </div>
                            <div className="course-li-p">
                                <p className="li-p-bold">
                                    {secondsToHms(totalTime)}
                                </p>
                                <p className="li-p-small">Duration</p>
                            </div>
                        </li>
                        <li className="course-detail-li">
                            <div className="course-li-icon">
                                <img
                                    src={
                                        require("../../assets/img/course-detail/certificate.svg")
                                            .default
                                    }
                                    alt="true"
                                />
                            </div>
                            <div className="course-li-p">
                                <p className="li-p-bold">Upon completion</p>
                                <p className="li-p-small">Certificate</p>
                            </div>
                        </li>
                        <li className="course-detail-li">
                            <div className="course-li-icon">
                                <img
                                    src={
                                        require("../../assets/img/course-detail/screen-person.svg")
                                            .default
                                    }
                                    alt="true"
                                />
                            </div>
                            <div className="course-li-p">
                                <p className="li-p-bold">
                                    100% online, self-paced
                                </p>
                                <p className="li-p-small">Course type</p>
                            </div>
                        </li>
                        <li className="course-detail-li">
                            <div className="course-li-icon">
                                <img
                                    src={
                                        require("../../assets/img/course-detail/folder-search.svg")
                                            .default
                                    }
                                    alt="true"
                                />
                            </div>
                            <div className="course-li-p">
                                <p className="li-p-bold">
                                    Introduction to Industry 4.0 Course
                                </p>
                                <p className="li-p-small">Prerequisites</p>
                            </div>
                        </li>
                        <li className="course-detail-li">
                            <div className="course-li-icon">
                                <img
                                    src={
                                        require("../../assets/img/course-detail/conversation-bubbles.svg")
                                            .default
                                    }
                                    alt="true"
                                />
                            </div>
                            <div className="course-li-p">
                                <p className="li-p-bold">English</p>
                                <p className="li-p-small">Language</p>
                            </div>
                        </li>
                    </ul>
                    {/* this section has
                        buy-course-top-img
                        buttons that come conditional
                    */}
                    <div className="buy-course-card">
                        <div className="buy-course-top-img">
                            <img
                                src={
                                    require("../../assets/img/course-detail/buy-course.svg")
                                        .default
                                }
                                alt="true"
                            />
                        </div>
                        {/* buy-course-top-img end */}
                        {!isInCart(courseDataItem) && (<p className="buy-course-txt">
                            <span className="b"> Unlock this course </span>
                            by purchasing individually or as part of our
                            Dimension Trainings Program.
                        </p>
                        )}
                        {isInCart(courseDataItem) && (<p className="buy-course-txt">
                            Course Added to Cart!
                        </p>
                        )}
                        {isInCart(courseDataItem) && (

                            <Link to="/cart" className="buy-course-btn add-green-btn">
                                Go to My Cart
                            </Link>
                        )}

                        {!isInCart(courseDataItem) && (
                            <>
                                <button className="buy-course-btn" onClick={(e) => { handleUpgradePopupClick(e); }}>
                                    Add to Cart
                                </button>
                            </>
                        )}
                    </div>
                    {/* course added */}
                    <div className="buy-course-card" style={{ display: "none" }}>
                        <div className="buy-course-top-img">
                            <img
                                src={
                                    require("../../assets/img/course-detail/buy-course.svg")
                                        .default
                                }
                                alt="true"
                            />
                        </div>
                        <p className="buy-course-txt">
                            Course Added to Cart!
                        </p>
                        {isInCart(courseDataItem) && (
                            <button className="buy-course-btn">
                                Course Added
                            </button>
                        )}

                        {!isInCart(courseDataItem) && (
                            <button
                                className="buy-course-btn add-green-btn"
                                onClick={() => addProduct(courseDataItem)}
                            >
                                Go to My Cart
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {/* courseRgt-card end */}
            <Modal
                open={isUpgradePopup}

                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="home-index-upgrade-popup add-course-success"
            >
                {/* this section has
                    close button
                    upgrd-popup-body
                */}
                <div className="upgrade-popup-content updated210423">
                    <button className="close-btn" onClick={HandleUpdgradePopup}>
                        <img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="close" />
                    </button>
                    {/* this section has
                        heading
                        text
                        button
                    */}
                    <div className="upgrd-popup-body">
                        <h2 className="h1">Course Added</h2>
                        <p>The Course has been added to your Cart.</p>
                        <div className="handle-btn-wrap">
                            <Link to="/training-courses" className="btn-upgrade-now blue-line-btn" onClick={HandleUpdgradePopup}>
                                See all IR4.0 trainings{" "}
                            </Link>
                            <Link to="/cart" className="btn-upgrade-now" onClick={HandleUpdgradePopup}>
                                Checkout{" "}
                            </Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};
