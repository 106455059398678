/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * QuickBetaPopupView ===> cutom view component
  *  Modal ===> component from UI used to work as popup

**/

import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import {Modal} from '@material-ui/core'
import {QuickBetaPopupView} from './quick-beta-popup-view'
import {Helmet} from 'react-helmet'
//QuickBetaPopupController start
export const QuickBetaPopupController=(props)=>{     
//defining variables here
    const [courseCirData, setCourseCirData] = useState(
    {
        introductionToIndustry4: {
            image: 'industry-introduction.svg',
            key: 0,
            status: `free`,
            title: `Introduction to Industry 4.0`,
            about: `A smooth and experiential induction to Industry 4.0 disruption and its effect in modern manufacturing.`,
            whatGet: `The course exposes the participant to the characteristics of Industry 4.0, showcases the benefits of SmarterChains Framework and prepares teams for the digital transformation journey.`,
            technology: ``
        },
        factoryOfTheFutureLeadership: {
            image: 'factory-future-leadership.svg',
            key: 1,
            status: `lock`,
            title: `Factory of the Future Leadership`,
            about: `Behaviors and strategies that inspire, support and accelerate the digital transformation across the plant.`,
            whatGet: `This training focuses into setting the transformative mindset for their organizations' journey to Industry 4.0.`,
            technology: ``
        },
        digitalOrganization: {
            image: 'digital-organisation.svg',
            key: 2,
            status: `lock`,
            title: `Digital Organization`,
            about: `Structures & policies that provide the agility to design and roll out an effective digital strategy.`,
            whatGet: `Emphasis will be given in IT/OT Integration, in Industry 4.0 capability building, as well as organizational models.`,
            technology: ``
        },
        dataDrivenMaintenance: {
            image: 'data-driven-maintenance.svg',
            key: 3,
            status: `lock`,
            title: `Data-Driven Maintenance`,
            about: `Methods & systems to manage maintenance proactively based on data, such as equipment condition metrics and historical events.                `,
            whatGet: `Participants will learn how they can modernize their maintenance processes.`,
            technology: `CMMS, 3D Printing, Automatic Lubrication Systems, Condition Monitoring, Predictive Maintenance`
        },
        sustainability: {
            image: 'sustainability.svg',
            key: 4,
            status: `lock`,
            title: `Sustainability`,
            about: `Methods, systems, behaviours and Industry 4.0 technologies that help create products and solutions that address the needs of the present without compromising the future.`,
            whatGet: `Participants will understand how Sustainability is connected with Industry 4.0 technologies in order to satisfy economic, environmental and social objectives.`,
            technology: `EHS, Energy Management Software`
        },
        automatedMaterialFlow: {
            image: 'automated-material-flow.svg',
            key: 5,
            status: `lock`,
            title: `Automated Material Flow`,
            about: `Implementation of machinery & systems that automate & optimize material handling processes throughout the manufacturing facility.`,
            whatGet: `The technologies we will examine in this training optimize material handling processes throughout the manufacturing facility.`,
            technology: `Automated Guided Vehicles, Autonomous Mobile Robots, UAV / Drones, YMS, WMS, ASRS`
        },
        dgitalReadyInfrastructure: {
            image: 'digital-ready-infrastructure.svg',
            key: 6,
            status: `lock`,
            title: `Digital-Ready Infrastructure`,
            about: `Systems that safeguard and enable the plant's operations, such as communication networks & cybersecurity software.`,
            whatGet: `This training highlights the importance of ensuring reliable and secure data streaming in order to safeguard and enable plants' operations.`,
            technology: `Edge Computing, Cloud Computing, Network Intrusion Detection Systems, Industrial Communication Systems, Cybersecurity, 5G`
        },
        factoryVisibility: {
            image: 'factory-visibility.svg',
            key: 7,
            status: `lock`,
            title: `360° Factory Visibility`,
            about: `Systems that combine data from various areas of the value chain, eliminating silos & accelerating decision-making.`,
            whatGet: `Participants will learn more about the use of central repositories and effective integrations to ensure data availability and transparency.`,
            technology: `Blockchain, IIoT, ERP, PLM, Data Visualization, Data Lake, Data Warehouse, RPA`
        },
        intelligentQuality: {
            image: 'intelligent-quality.svg',
            key: 8,
            status: `lock`,
            title: `Intelligent Quality`,
            about: `Sensors, software & techniques that leverage real-time data to manage quality control and quality assurance.`,
            whatGet: `Participants will learn how they can improve their quality processes.`,
            technology: `Foreign Object Detection Systems, LIMS, Machine Vision, Quality Management Software, Predictive Quality`
        },
        techAugmentedWorkforce: {
            image: 'tech-augmented-workforce.svg',
            key: 9,
            status: `lock`,
            title: `Tech-Augmented Workforce`,
            about: `Technologies that assist employees in their tasks by augmenting their skillsets and optimizing workflows.`,
            whatGet: `Participants will explore technological product solutions with a goal to augment workforce's capabilities.`,
            technology: `Machine Vision, AR, Industrial Tablets / Smartphones, Industrial Wearables, Connected Workforce Platform, VR`
        },
        zeroTouchesProduction: {
            image: 'zero-touches-production.svg',
            key: 10,
            status: `lock`,
            title: `Zero Touches Production`,
            about: `Hardware, software, and practices that eliminate human effort in production planning and execution.`,
            whatGet: `Participants will learn how they can improve their production processes.`,
            technology: `Soft Robotics, Digital Twin, Cobots, End of arm tooling, Machine Vision, MES`
        },
    }
);
	const registeredUser = [
        {
            id: 0,
            userEmail: 'dolores.chambers@example.com',
            experties: 'Management'
        }
    ];
	
	
    const [results, setResults] = useState([]);
    const [invited, setInvited] = useState("");
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    useEffect(() => {
            const search = async () => {
                const apiUrl = `/user?param=inviteddata&uid=${usid}`;
                const { data } = await serviceProvider.get(apiUrl,true,{});
                setResults(data);
            };
            search();
    }, [invited,props.open]);
			
			
			
			
    return(
        <>
            {/* css for styling */}
            <Helmet>
                    <link rel="stylesheet" href={require('./quick-beta-popup.scss').default} />
            </Helmet>
            {/* custom view component */}
            <QuickBetaPopupView registerUser={registeredUser} courseData={courseCirData} homeData={results} onInvited={setInvited} {...props}/>
        </>
    )
}