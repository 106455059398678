import React, { useState, memo, useEffect, useMemo, useRef } from 'react';
import serviceProvider from '../../services/axios';
import { dimentionsSelctedIcon, dimentionsTabDdlIcon, savedChangesIcon, selectedChk, unSelectedChk } from './svg';
import { ContentContainer, ContentFooter, DimensionHead, DimentionCol1, DimentionCol2, DimentionRow, DimentionRowWrapper, DropdownComponent, Headding, HeaddingText, PageWrapper } from './styles';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
// import { BrowserRouter, Route, Switch, Link } from "react-router-dom";


export const DimensionAndSolutionsMappingView = (props) => {
    const { handleDiscard,dataResults,uid } = props;
    const datainput = dataResults;
    const demoData = datainput?.dimension;
    const plant_id = datainput?.plant_id;

    const [dimensionArr, setDimentionArr] = useState(demoData || []);
    const [includesPdfArr, setIncludesPdfArr] = useState([]);
    const [isModifyMode, setIsModifyMode] = useState(datainput?.modifi_dim);
    const [isSaved, setIsSaved] = useState(false);
    const savedData = useRef({});
    const isStatusCompleted = false;
    const handleValueOnChange = (key, curent_band, future_band,is_included) => {
        let tmpObj = { ...savedData.current };
        tmpObj[key] = [curent_band, future_band,is_included];
        savedData.current = tmpObj;
        // console.log(tmpObj);
    }
    const handleSaveChanges = () => {
        setIsSaved(true);
        setTimeout(() => {
            setIsSaved(prv => false);
            setIsModifyMode(prv => false);
        }, 2000);
        serviceProvider.post("/Roadmap?param=updateglobalband", JSON.stringify({ uid: uid, plant_id: plant_id,dataall:savedData.current }), true).then((response) => { });
    }
    const handleIncludesPdf = (val) => {
        let tmpArr = [...includesPdfArr];
        let tmpObj = {...savedData.current};
        let currentObj = tmpObj[val];
        if (tmpArr.includes(val) && isModifyMode) {
            handleValueOnChange(val,currentObj[0],currentObj[1],0);
            let filterData = tmpArr?.filter(i => i != val);
            setIncludesPdfArr(filterData);
        } else if(isModifyMode) {
            handleValueOnChange(val,currentObj[0],currentObj[1],1);
            tmpArr.push(val);
            setIncludesPdfArr(tmpArr);
        }
        
    }

    useEffect(()=>{
        let tmpObj = {}
        let tmpArr = [];
        datainput?.dimension?.forEach(i=>{
            tmpObj[i.id]=[i.current_band,i.future_band,i.is_included];
            if(i.is_included==1){
                tmpArr.push(i.id);
            }
        });
        savedData.current = tmpObj;
        setIncludesPdfArr(tmpArr);
    },[]);

    return (
        <>
            <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/#">Dimension & Solutions Mapping</BreadcrumbsItem>
            <PageWrapper data-component="PageWrapper">
                <ContentContainer data-component="ContentContainer">
                    <Headding data-component="Headding">
                        Dimensions
                    </Headding>
                    <HeaddingText data-component="HeaddingText">
                        Manage current and future bands for Dimensions
                    </HeaddingText>
                    <DimentionRowWrapper data-component="DimentionRowWrapper">
                        <DimensionHead>
                            <DimentionRow data-component="DimentionRow">
                                <DimentionCol1 data-component="DimentionCol1">
                                    Dimension Name
                                </DimentionCol1>
                                <DimentionCol2 data-component="DimentionCol2">
                                    Current Band
                                </DimentionCol2>
                                <DimentionCol2 data-component="DimentionCol2">
                                    Future Band
                                </DimentionCol2>
                                <DimentionCol2 data-component="DimentionCol2">

                                </DimentionCol2>
                            </DimentionRow>
                        </DimensionHead>
                        {dimensionArr?.map((i, ind) => {
                            let selected_currentband = i?.band?.find(band => band.id == i.current_band) || false;
                            let selected_futureband = i?.band?.find(band => band.id == (i.future_band)) || false;
                            const align = (ind >= dimensionArr?.map?.length - 4 && ind >= 5) ? 'top' : false;
                            return (
                                <DimentionRow data-component="DimentionRow" key={i.id}>
                                    <DimentionCol1 data-component="DimentionCol1">
                                        <div className='wrapper'>
                                            <div className='number-box'>{ind + 1}</div>
                                            <div className='text-box'>{i.name}</div>
                                        </div>
                                    </DimentionCol1>
                                    <DimentionCol2 data-component="DimentionCol2">
                                        <Dropdown
                                            options={i?.band || []}
                                            defaultSelection={selected_currentband}
                                            getValueOnChange={(val) => {
                                                handleValueOnChange(i.id, val.id, savedData.current[i.id] ? savedData.current[i.id][1] : selected_futureband.id)
                                            }}
                                            disabled={!isModifyMode}
                                            align={align}
                                        />
                                    </DimentionCol2>
                                    <DimentionCol2 data-component="DimentionCol2">
                                        <Dropdown
                                            options={i?.band || []}
                                            defaultSelection={selected_futureband}
                                            getValueOnChange={(val) => {
                                                handleValueOnChange(i.id, savedData.current[i.id] ? savedData.current[i.id][0] : selected_futureband.id, val.id)
                                            }}
                                            disabled={!isModifyMode}
                                            align={align}
                                        />
                                    </DimentionCol2>
                                    <DimentionCol2 data-component="DimentionCol2" className='flex'>
                                        <div className={`includes-pdf-div ${!isModifyMode?'disabled':''}`} onClick={() => handleIncludesPdf(i.id)}>
                                            <i dangerouslySetInnerHTML={{ __html: includesPdfArr.includes(i.id) ? selectedChk : unSelectedChk }}></i>
                                            <span>Include in PDF</span>
                                        </div>
                                    </DimentionCol2>
                                </DimentionRow>
                            )
                        })
                        }
                    </DimentionRowWrapper>
                </ContentContainer>

                <ContentFooter data-component="ContentFooter">
                    <button className='btn-modify' disabled={isModifyMode} onClick={() => setIsModifyMode(prv => true)}>MODIFY BANDS</button>
                    {!isSaved ?
                        <>
                            <button className='btn-discard' disabled={!isModifyMode} onClick={() => handleDiscard(prv => !prv)} style={{visibility:isStatusCompleted?'hidden':''}}>DISCARD</button>
                            <button className='btn-modify' disabled={!isModifyMode} onClick={handleSaveChanges}>{!isStatusCompleted? `SAVE CHANGES`:`SUBMIT DIMENSIONS`}</button>
                        </>
                        :
                        <div className='changes-saved'>
                            <i dangerouslySetInnerHTML={{ __html: savedChangesIcon }}></i>
                            <span>{!isStatusCompleted? `Changes Saved`:`Submitted Successfully`}</span>
                        </div>
                    }
                </ContentFooter>
            </PageWrapper>
        </>
    )
}


const Dropdown = memo((props) => {
    const { options, defaultSelection = false, getValueOnChange = () => { }, disabled, align } = props || {}
    const [selected, setSelected] = useState(false);
    const handleList = (e) => {
        let targetElement = e?.target;
        let mainParent = targetElement?.parentElement;
        let optionsList = mainParent.querySelector('.selection-options');
        let singleOptions = optionsList.querySelectorAll('.selection-option');
        let input = mainParent.querySelector('.store-value-input');
        let openOptionList = document.querySelector('.dropdown-wrapper.show-options');
        if (!mainParent.classList.contains('show-options')) {
            openOptionList?.classList?.remove('show-options');
        }
        mainParent?.classList?.toggle('show-options');
        singleOptions?.forEach(li => {
            li.addEventListener('click', function (e) {
                input.setAttribute("value", e.target.dataset.value);
                let tmpObj = {
                    id: e.target.dataset.value,
                    name: e.target.dataset.name
                };
                setSelected(prv => tmpObj);
                getValueOnChange(tmpObj);
                mainParent.classList.remove('show-options');
            })
        });
    }
    useEffect(() => {
        setSelected(defaultSelection);
        document.addEventListener('click', function (e) {
            if (!e.target.closest('.dropdown-wrapper')) {
                let openOptionList = document.querySelector('.dropdown-wrapper.show-options');
                openOptionList?.classList?.remove('show-options');
            }
        });
    }, []);

    return (
        <DropdownComponent data-component="DropdownComponent">
            <div className={`dropdown-wrapper ${disabled ? 'disabled' : ''} ${align || ''}`}>
                <input type="hidden" name="" value="select-1" className="store-value-input" />
                <i className="ddl-icon" dangerouslySetInnerHTML={{ __html: dimentionsTabDdlIcon }}></i>
                <button className="option-toggle-btn" onClick={handleList}>{selected?.name || 'Select'} </button>
                <ul className="selection-options">
                    {options?.map((item, index) => (
                        item.id ?
                            <li key={index} data-value={item.id} data-name={item.name} className={`selection-option ${selected?.id == item.id ? 'selected' : ''}`}>
                                {item.name}
                                {selected?.id == item.id ? <i dangerouslySetInnerHTML={{ __html: dimentionsSelctedIcon }}></i> : <></>}
                            </li>
                            :
                            <>
                            </>
                    ))
                    }
                </ul>
            </div >
        </DropdownComponent>
    )
});