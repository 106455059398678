import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ProfileView } from "./profile-view";
import serviceProvider from '../../services/axios'
import "./profile.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";



export const ProfileController = (props) => {
	const [results, setResults] = useState([]);
	const [defaultCountryList, setDefaultCountryList] = useState([]);
	const [onTab, setOnTab] = useState(0);
	const [formvalue, setFormvalue] = useState(0);
	
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const progress = user ? user.progress : 0;
    const plant_slug = user ? user.plant_slug : 0;
    const gid = user ? user.group_id : 0;
	if (progress<100 && (gid == 14 || gid == 2)) {
         //return <Redirect to={`/assessment/${plant_slug}`} />;
    }
	useEffect(() => {
		loadCountries();
	  }, []);
	
	  
	const loadCountries = async () => {
		const result = await serviceProvider.get("/plants/countries",true,{});
		const data = result.data
		 if (data && Array.isArray(data)) {
			const options = data.map(d => ({
			  "value": d.id,
			  "name": d.country
			}))
			setDefaultCountryList(options);
		}
	};

	useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
			const apiUrl = `/profile?param=profile&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
		loadCountries();
	}, [formvalue]);
	// funcion to filter country on search
	
	return (
		<>
			<Helmet>
				<title>Profile Settings | SmarterChains</title>
				<meta name="description" content="My Cart" />
				<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
			</Helmet>
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/profile">Account Settings</BreadcrumbsItem>
			<ProfileView
				
				defaultCountryList={defaultCountryList}
				onTab={onTab}
				onSubmitForm={setFormvalue}
				profileData={results}
				{...props}
			/>
		</>
	);
};
