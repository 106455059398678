import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router'
import serviceProvider from '../../services/axios'
import { SkillAssessmentAnalyticsView } from './SkillAssessmentAnalyticsView'
export const SkillAssessmentAnalyticsCtrl = (props) => {

  const [results, setResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');


	useEffect(() => {
		const search = async () => {
			const apiUrl = `/skills?param=analytics&timezone=${timezone}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResults(data);
		};
		search();
	}, []);



 
  return (
    <>
      <SkillAssessmentAnalyticsView analytics={results} {...props} />
    </>
  );
}
