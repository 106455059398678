import React, { useEffect, useState } from 'react';
import { backArrow, hideEye, showEye, arrowDown } from './svg'
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { NewSignUpWrapper } from './style';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import {HowToUseView} from "../HowToUse/HowToUseView";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import serviceProvider from "../../services/axios";

export const NewSignUpView = (props) => {
    const [step, setStep] = useState(1);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [job, setjob] = useState('');
    const [password, setpassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [manufacturerName, setmanufacturerName] = useState('');
    const [companyRegistration, setcompanyRegistration] = useState('');
    const [industrySector, setindustrySector] = useState('');
    const [products, setproducts] = useState('');
    const [address, setaddress] = useState('');
    const [revenueSize, setrevenueSize] = useState('');
    const [revenueOnExports, setrevenueOnExports] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [companyAge, setCompanyAge] = useState('');
    const [numberOfPlants, setnumberOfPlants] = useState('');
    const [numberOfEmployees, setnumberOfEmployees] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [plantName, setplantName] = useState('');
    const [plantCountry, setplantCountry] = useState('');
    const [plantCity, setplantCity] = useState('');
    const [resultscity, setresultscity] = useState([]);

    const formData = ({
        firstName: firstName,
        lastName: lastName,
        email: email,
        job: job,
        password: password,
        confirmPassword: confirmPassword,
        manufacturerName: manufacturerName,
        companyRegistration: companyRegistration,
        industrySector: industrySector,
        products: products,
        address: address,
        revenueSize: revenueSize,
        revenueOnExports: revenueOnExports,
        postalCode: postalCode,
        companyAge: companyAge,
        numberOfPlants: numberOfPlants,
        numberOfEmployees: numberOfEmployees,
        phoneNumber: phoneNumber,
        plantName: plantName,
        plantCountry: plantCountry,
        plantCity: plantCity,
    });
    const [errors1, setErrors1] = useState({
        firstName: '',
        lastName: '',
        email: '',
        job: '',
        password: '',
        confirmPassword: ''
    });
    const [errors2, setErrors2] = useState({
        manufacturerName: '',
        companyRegistration: '',
        industrySector: '',
        products: '',
        address: '',
        revenueSize: '',
        revenueOnExports: '',
        postalCode: '',
        companyAge:'',
        numberOfPlants: '',
        numberOfEmployees: '',
        phoneNumber: '',
    });
    const [errors3, setErrors3] = useState({
        plantName: '',
        plantCountry: '',
        plantCity: '',
    })
    const checkValue = () => {

        setErrors1({
            firstName: '',
            lastName: '',
            email: '',
            job: '',
            password: '',
            confirmPassword: ''
        });
        setErrors2({
            manufacturerName: '',
            companyRegistration: '',
            industrySector: '',
            products: '',
            address: '',
            revenueSize: '',
            revenueOnExports: '',
            postalCode: '',
            companyAge: '',
            numberOfPlants: '',
            numberOfEmployees: '',
            phoneNumber: '',
        });
        setErrors3({
            plantName: '',
            plantCountry: '',
            plantCity:'',
        })

    }
    const toggleDropdown = (val) => {
        if (val === dropdownOpen) {
            setDropdownOpen(false);
        } else {
            setDropdownOpen(val);
        }
    }
    

    const updateCityList = (val) => {
            const apiUrl = `/user?param=citylist&country_id=${val}`;
            serviceProvider.get(apiUrl)
                .then(function (response) {
                    setresultscity(response.data);
                });
                
    }
    const onSubmit = () => {
        serviceProvider
            .post("/user?type=signupnew", JSON.stringify(formData))
            .then((response) => {
                if (response.data.status === 200) {
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("userData", JSON.stringify(response.data.data));
                    localStorage.setItem(
                        "group_id",
                        JSON.stringify(response.data.data.group_id)
                    );
                    localStorage.setItem(
                        "access_token",
                        JSON.stringify(response.data.data.access_token)
                    );
                    localStorage.setItem(
                        "gettimezone",
                        JSON.stringify(response.data.data.gettimezone)
                    );
                    localStorage.setItem(
                        "refresh_token",
                        JSON.stringify(response.data.data.refresh_token)
                    );
                    localStorage.setItem(
                        "noti",
                        JSON.stringify(response.data.data.noti)
                    );
                    // window.location = "/assessment/" + response.data.data.slug;
                    window.location = "/onboarding-assessment-tour/" + response.data.data.slug;
                }
                if (response.data.status === "failed") {
                    let errors = {};
                    setErrors1({
                        firstName: '',
                        lastName: '',
                        email: 'Email already exists',
                        job: '',
                        password: '',
                        confirmPassword: ''
                    });
                    setStep(1)

                }
            });
    };
    const validateForm1 = () => {
        let errors = {};
        var passw = /^[A-Za-z]\w{7,14}$/;
        // Validate name field 
        if (!firstName) {
            errors.firstName = 'First Name is required.';
        }
        if (!lastName) {
            errors.lastName = 'Last Name is required.';
        }
        // Validate email field 
        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid.';
        }
        if (!job) {
            errors.job = 'Job is required.';
        }
        // Validate password field 
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 8) {
            errors.password = 'Password must be at least 8 characters.';
        } else if (password.match(passw)) {
            errors.password = 'Must contain at one uppercase, one number and one special character.';
        }
        if (!confirmPassword) {
            errors.confirmPassword = 'Confirm Password is required.';
        } else if (confirmPassword.length < 8) {
            errors.confirmPassword = 'Confirm Password must be at least 8 characters.';
        } else if (confirmPassword.match(passw)) {
            errors.confirmPassword = 'Must contain at one uppercase, one number and one special character.';
        } else if (confirmPassword != password) {
            errors.confirmPassword = 'Passwords must match.';
        }

        // Set the errors and update form validity 
        setErrors1(errors);
        if (Object.keys(errors).length === 0) {
            setStep(2)
        }
    };

    const validateForm2 = () => {
        let errors = {};

        if (!manufacturerName) {
            errors.manufacturerName = 'Manufacturer Name is required.';
        }
        if (!companyRegistration) {
            errors.companyRegistration = 'companyRegistration is required.';
        }
        if (!industrySector) {
            errors.industrySector = 'Industry Sector is required.';
        }
        if (!products) {
            errors.products = 'Products is required.';
        }
        if (!address) {
            errors.address = 'Address is required.';
        }
        if (!revenueSize) {
            errors.revenueSize = 'Revenue Size is required.';
        }
        if (!revenueOnExports) {
            errors.revenueOnExports = 'Revenue On Exports is required.';
        }
        if (!postalCode) {
            errors.postalCode = 'Postal Code is required.';
        }
        if (!companyAge) {
            errors.companyAge = 'Company Age is required.';
        }
        if (!numberOfPlants) {
            errors.numberOfPlants = 'Number Of Plants is required.';
        }
        if (!numberOfEmployees) {
            errors.numberOfEmployees = 'Number Of Employees is required.';
        }
        if (!phoneNumber) {
            errors.phoneNumber = 'Phone number is required.';
        }
        // Set the errors and update form validity 
        setErrors2(errors);
        if (Object.keys(errors).length === 0) {
            setStep(3);
        }
    };


    const validateForm3 = () => {
        let errors = {};


        if (!plantName) {
            errors.plantName = 'Plant Name is required.';
        }
        if (!plantCountry) {
            errors.plantCountry = 'Plant Country is required.';
        }
        if (!plantCity) {
            errors.plantCity = 'Plant City is required.';
        }
        
        // Set the errors and update form validity 
        setErrors3(errors);
        if (Object.keys(errors).length === 0) {
            onSubmit();

        }
    };

    const handlePhoneChange = (e) => {
        setPhoneNumber(e);
    }



    return (
        <>
            <NewSignUpWrapper>
                <Helmet>
                    <title>Sign Up | SmarterChains</title>
                    <meta name="description" content="Sign In" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                </Helmet>
                {/* 
        */}
                <div className="auth__form--wrapp">
                    {/* Logo + hero image */}
                    <Grid item xs={12} sm={4} md={4} className="auth__form--left">
                        <div className="auth__form--logo">
                            <img src={require("../../assets/img/auth/sc-logo.svg").default} />
                        </div>
                    </Grid>
                    {/* Sign in form  */}
                    <Grid item xs={12} sm={12} md={8} className="auth__form--right scroll">
                        <section>
                            <div className="auth__form-sm--logo">
                                <img src={require("../../assets/img/auth/sc-logo.svg").default} />
                            </div>
                            <div className='signup_form_container'>
                                <div className='signup_form_header'>
                                    <h1>Start your Industry 4.0 journey today</h1>
                                    {/* <p>Get Started for Free and upgrade any time. Select any additional course and claim your Digital Maturity Assessment for 1 Dimension and its Results.</p> */}
                                </div>
                                <div className='signup_form step_1' style={{ display: step === 2 || step === 3 ? 'none' : '' }}>
                                    <div className='signup_tab_header'>
                                        <div className='signup_form_step'>
                                            <div className='step'>
                                                <span>Step 1</span> of 3:
                                            </div>
                                            <span>Account Information</span>
                                        </div>
                                        <div className='step_progress'>
                                            <div className='step_progress_bar'></div>
                                        </div>
                                    </div>
                                    {/* signup_tab_header div end */}
                                    <div className='siri_signup_box'>
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>First Name</span>
                                                    <input
                                                        type='text'
                                                        name='firstName'
                                                        className='input_field'
                                                        placeholder='First Name'
                                                        onChange={(e) => setfirstName(e.target.value)}
                                                        onKeyUp={checkValue}
                                                        maxlength="50"
                                                    />
                                                </label>
                                                <label class="error">{errors1.firstName}</label>
                                            </div>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Last Name</span>
                                                    <input
                                                        type='text'
                                                        name='lastName'
                                                        className='input_field'
                                                        placeholder='last Name'
                                                        maxlength="50"
                                                        onChange={(e) => setlastName(e.target.value)}
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors1.lastName}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 1 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Email</span>
                                                    <input
                                                        type='email'
                                                        name='email'
                                                        className='input_field'
                                                        placeholder='Email Address'
                                                        onChange={(e) => setemail(e.target.value)}
                                                        maxlength="150"
                                                        onKeyUp={checkValue}
                                                    />
                                                    <label class="error">{errors1.email}</label>
                                                </label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 2 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Job Title</span>
                                                    <input
                                                        type='text'
                                                        name='job'
                                                        className='input_field'
                                                        placeholder='Job Title'
                                                        onChange={(e) => setjob(e.target.value)}
                                                        maxlength="100"
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors1.job}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 3 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Password</span>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        name='password'
                                                        className='input_field pr_30'
                                                        placeholder='********'
                                                        onChange={(e) => setpassword(e.target.value)}
                                                        max-maxLength="100"
                                                        onKeyUp={checkValue}
                                                    />
                                                    <i
                                                        dangerouslySetInnerHTML={{ __html: !showPassword ? hideEye : showEye }} onClick={() => setShowPassword(!showPassword)}
                                                    />
                                                </label>
                                                <label class="error">{errors1.password}</label>
                                            </div>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Confirm Password</span>
                                                    <input
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        name='confirmPassword'
                                                        className='input_field pr_30'
                                                        onChange={(e) => setconfirmPassword(e.target.value)}
                                                        placeholder='********'
                                                        onKeyUp={checkValue}

                                                    />
                                                    <i
                                                        dangerouslySetInnerHTML={{ __html: !showConfirmPassword ? hideEye : showEye }} onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    />
                                                </label>
                                                <label class="error">{errors1.confirmPassword}</label>
                                            </div>
                                        </div>
                                        <button className='siri_form_button' onClick={validateForm1}  >Next</button>
                                        <span className="account-login">
                                            Have an account?
                                            <Link type="button" to="/sign-in">
                                                Log In
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                {/* step_1 div end */}

                                <div className='signup_form step_2' style={{ display: step === 1 || step === 3 ? 'none' : '' }}>
                                    <div className='signup_tab_header'>
                                        <div className='signup_form_step'>
                                            <i className='back_svg' dangerouslySetInnerHTML={{ __html: backArrow }} onClick={() => setStep(1)}></i>
                                            <div className='step'>
                                                <span>Step 2</span> of 3:
                                            </div>
                                            <span>Company Information</span>
                                        </div>
                                        <div className='step_progress'>
                                            <div className='step_progress_bar' style={{ width: '100%' }}></div>
                                        </div>
                                    </div>
                                    {/* signup_tab_header div end */}
                                    <div className='siri_signup_box'>
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Manufacturer Name</span>
                                                    <input
                                                        type='text'
                                                        name='manufacturerName'
                                                        className='input_field'
                                                        placeholder='Enter name'
                                                        maxlength="150"
                                                        onChange={(e) => setmanufacturerName(e.target.value)}
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors2.manufacturerName}</label>
                                            </div>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Commercial Registration</span>
                                                    <input
                                                        type='text'
                                                        name='companyRegistration'
                                                        className='input_field'
                                                        placeholder='Enter CR number'
                                                        onChange={(e) => setcompanyRegistration(e.target.value)}
                                                        maxlength="150"
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors2.companyRegistration}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 1 */}
                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>

                                                <Dropdown optionList={props.countryList} 
                                                   
                                                    title={'Select Sector'}
                                                    label={'Industry Sector'}
                                                    index={1}
                                                    value={industrySector}
                                                    isOpen={dropdownOpen}
                                                    onChange={(val) => setindustrySector(val)}
                                                    toggleDropdown={(val) => toggleDropdown(val)}
                                                />
                                                <label class="error">{errors2.industrySector}</label>
                                            </div>

                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Products</span>
                                                    <input
                                                        type='text'
                                                        name='products'
                                                        className='input_field'
                                                        placeholder='Comma Separated'
                                                        onChange={(e) => setproducts(e.target.value)}
                                                        maxlength="300"
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors2.products}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 2 */}

                                        <div className='signup_form_row'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Address</span>
                                                    <input
                                                        type='text'
                                                        name='address'
                                                        className='input_field'
                                                        placeholder='Enter registered office address'
                                                        onChange={(e) => setaddress(e.target.value)}
                                                        maxlength="400"
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors2.address}</label>
                                            </div>
                                        </div>
                                        {/* Postal Code Row */}
                                        <div className='signup_form_row'>


                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Postal Code</span>
                                                    <input
                                                        type='text'
                                                        name='postalCode'
                                                        className='input_field'
                                                        placeholder='Enter postal code'
                                                        onChange={(e) => setPostalCode(e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''))}
                                                        maxlength="8"
                                                        onKeyUp={checkValue}
                                                        value={postalCode}
                                                    />
                                                </label>
                                                <label class="error">{errors2.postalCode}</label>
                                            </div>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Company Age profile (in years)</span>
                                                    <input
                                                        type='text'
                                                        name='compnayAge'
                                                        className='input_field'
                                                        placeholder='Enter year'
                                                        onChange={(e) => setCompanyAge(e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''))}
                                                        value={companyAge}
                                                        onKeyUp={checkValue}
                                                        maxlength="3"
                                                    />
                                                </label>
                                                <label class="error">{errors2.companyAge}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 3 */}
                                        <div className='signup_form_row'>


                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Revenue Size(in million)</span>
                                                    <input
                                                        type='text'
                                                        name='revenueSize'
                                                        className='input_field'
                                                        placeholder='Enter Revenue'
                                                        onChange={(e) => setrevenueSize(e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''))}
                                                        maxlength="10"
                                                        onKeyUp={checkValue}
                                                        value={revenueSize}
                                                    />
                                                </label>
                                                <label class="error">{errors2.revenueSize}</label>
                                            </div>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>% revenue on exports</span>
                                                    <input
                                                        type='text'
                                                        name='revenueOnExports'
                                                        className='input_field'
                                                        placeholder='Enter percent'
                                                        onChange={(e) => setrevenueOnExports(e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''))}
                                                        maxlength="3"
                                                        value={revenueOnExports}
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors2.revenueOnExports}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 4 */}

                                        <div className='signup_form_row'>


                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Number of Plants</span>
                                                    <input
                                                        type='text'
                                                        name='numberOfPlants'
                                                        className='input_field'
                                                        placeholder='Enter plants'
                                                        onChange={(e) => setnumberOfPlants(e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''))}
                                                        maxlength="8"
                                                        value={numberOfPlants}
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors2.numberOfPlants}</label>
                                            </div>



                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Number of Employees</span>
                                                    <input
                                                        type='text'
                                                        name='numberOfEmployees'
                                                        className='input_field'
                                                        placeholder='Enter employees'
                                                        onChange={(e) => setnumberOfEmployees(e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''))}
                                                        maxlength="20"
                                                        value={numberOfEmployees}
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors2.numberOfEmployees}</label>
                                            </div>

                                        </div>
                                        <div className="signup_form_row">
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Phone Number</span>
                                                    <PhoneInput
                                                        inputClass="contact__phone__input"
                                                        country={"sa"}
                                                        value={phoneNumber}
                                                        inputProps={{ name: "phoneNo" }}
                                                        maxlength="15"
                                                        onChange={(e) => {checkValue(); handlePhoneChange(e);}}
                                                        />
                                                </label>
                                                <label class="error">{errors2.phoneNumber}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 5 */}
                                        <button className='siri_form_button' onClick={validateForm2}>Next</button>
                                        <span className="account-login">
                                            Have an account?
                                            <Link type="button" to="/sign-in">
                                                Log In
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                                {/* step_2 */}
                                <div className='signup_form step_3' style={{ display: step === 2 || step === 1 ? 'none' : '' }}>
                                    <div className='signup_tab_header'>
                                        <div className='signup_form_step'>
                                            <i className='back_svg' dangerouslySetInnerHTML={{ __html: backArrow }} onClick={() => setStep(2)}></i>
                                            <div className='step'>
                                                <span>Step 3</span> of 3:
                                            </div>
                                            <span>Plant Information</span>
                                        </div>
                                        <div className='step_progress'>
                                            <div className='step_progress_bar' style={{ width: '100%' }}></div>
                                        </div>
                                    </div>
                                    {/* signup_tab_header div end */}
                                    <div className='siri_signup_box'>
                                        <div className='signup_form_row gap-0'>
                                            <div className='signup_form_col'>
                                                <label className='field_wrapper'>
                                                    <span className='label'>Plant Name</span>
                                                    <input
                                                        type='text'
                                                        name='plantName'
                                                        className='input_field'
                                                        placeholder='Enter name'
                                                        maxlength="150"
                                                        onChange={(e) => setplantName(e.target.value)}
                                                        onKeyUp={checkValue}
                                                    />
                                                </label>
                                                <label class="error">{errors3.plantName}</label>
                                            </div>
                                            <div className='signup_form_col'>

                                                <Dropdown optionList={[
                                                    {
                                                        label: 'Bahrain',
                                                        value: 48
                                                    },
                                                    {
                                                        label: 'Saudi Arabia',
                                                        value: 682
                                                    }]}
                                                    title={'Select Country'}
                                                    label={'Country'}
                                                    index={2}
                                                    value={plantCountry}
                                                    type={1}
                                                    isOpen={dropdownOpen}
                                                    onChange={(val) => {setplantCountry(val); updateCityList(val);}}
                                                    toggleDropdown={(val) => toggleDropdown(val)}
                                                />
                                                <label class="error">{errors3.plantCountry}</label>
                                            </div>
                                            <div className='signup_form_col'>
                                                <Dropdown optionList={resultscity}
                                                    title={'Select City'}
                                                    label={'City'}
                                                    index={3}
                                                    type={2}
                                                    value={plantCity}
                                                    isOpen={dropdownOpen}
                                                    onChange={(val) => setplantCity(val)}
                                                    toggleDropdown={(val) => toggleDropdown(val)}
                                                />
                                                <label class="error">{errors3.plantCity}</label>
                                            </div>
                                        </div>
                                        {/* signup_form_row div 5 */}


                                        <p className="terms_privacy_text">By clicking on Sign Up, you agree to SmarterChains’ <a className='siri_term' href="//www.smarterchains.com/terms-of-service">Terms of service</a>, <a className='siri_term' href="//www.smarterchains.com/website-terms-of-use">Terms of use</a> and <a className='siri_term' href="//www.smarterchains.com/privacy-policy">Privacy Policy</a>.</p>
                                        <button className='siri_form_button' onClick={validateForm3}>Sign Up</button>
                                        <span className="account-login">
                                            Have an account?
                                            <Link type="button" to="/sign-in">
                                                Log In
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* signup_form_container div end  */}
                        </section>
                    </Grid>
                </div>
            </NewSignUpWrapper>
            <HowToUseView signUp={`sign-up`} englishUrl={require(`../../assets/video/IIoT-Solutions-Platform-Sign-Up-Process-SIRI-Assessments.mp4`).default}/>
        </>

    )
}

const Dropdown = ({ title, label, optionList, onChange, isOpen, value,type,index, toggleDropdown, align = 'bottom', isSearch = true }) => {
    const selectedOption = optionList?.find(i => i.value === value);
    const [filterList, setfilterList] = useState(optionList);
    const handleSelectedList = (val) => {
        toggleDropdown(false);
        onChange(val);
        
    }

    const handleSearch = (e) => {
        let val = e.target.value;
        // let tmpArr = optionList.filter(i => i.label.toLowerCase().startsWith(val.toLowerCase()));
        let tmpArr = optionList.filter(i => i.label.toLowerCase().includes(val.toLowerCase()));
        if (val) {
            setfilterList(tmpArr);
        } else {
            setfilterList(optionList);
        }
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('.dropdown_container')) {
                setfilterList(optionList);
                toggleDropdown(false);
            }
        });
    }, []);
    useEffect(() => {
        setfilterList(optionList);
    }, [optionList]);
    return (
        <div className={`dropdown_container ${isOpen === index ? 'active' : ''}`}>
            <button className={`dropdown_btn ${align}`} onClick={() => toggleDropdown(index)}>
                <span className='label'>{label}</span>
                {selectedOption?.label ?
                    <p>{selectedOption?.label}</p>
                    :
                    <p className='placeholder_text'>{title}</p>
                }
                <i className='icon_arrow_down' dangerouslySetInnerHTML={{ __html: arrowDown }} />
            </button>
            {isOpen &&
                <div className={`dropdown_content ${align}`}>
                    <input placeholder='Search...' onChange={handleSearch} className='signup-ddl-search' />
                    <ul>
                        {filterList?.map((option, ind) => (
                            <li key={ind} onClick={() => handleSelectedList(option.value)}>{option.label}</li>
                        ))}
                       {filterList?.length == 0 && <li style={{pointerEvents:'none'}} >Not found</li>}
                    </ul>
                </div>
            }
        </div>
    )
}