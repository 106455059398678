/**
 # WhitePaperView ==> view part of this component 
 # Helmet ==> for add dynamic title of the page and specific files for this component only  
 
*/

import React from 'react';
import { WhitePaperView } from './white-paper-view'
import { Helmet } from 'react-helmet';
// import { QuickStart } from "../quickstart/quickstart-ctrl";
export const WhitePaperController = (props) => {
    return (
        <>
            <Helmet>
                <link href={require('./white-paper.scss').default} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <WhitePaperView {...props} />
            {/* <QuickStart /> */}
        </>
    )
}