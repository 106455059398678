/** imported components

  * Modal for ===> component from material-ui to work as popups.
  * BreadcrumbsItem for ===> component from react to use breadcrumbs.
  * Helmet ===> for add specific title & CSS for the page.
  * useState ===> React hooks (useState is a way to get/set value)

**/
import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { apiEndpoints } from '../../services/api-endpoints';
// AssessmentView component start
export const AssessmentView = (props) => {
	const [isUpgradePopup, setIsUpgradePopup] = useState(false);
	const HandleUpdgradePopup = () => {
		setIsUpgradePopup(!isUpgradePopup);
	};
	const sectionDataItems = props.sectionData;
	if (!sectionDataItems.length) {
		return null;
	}
	const sectionDataItem = props.sectionData[0];
	const catData = sectionDataItem.category;
	if (!catData) {
		return null;
	}
	
	
	const chapterDataItemSections = catData.map((chapter, index) => {
		const count = index + 1;
		if (index == 0) {
			return (
				// card wrapper which is containing three horizontal sections.
				<Link
					key={chapter.id}
					data-index={index}
					to={`/assessment/chapter/${chapter.id}`}
					className={`all-sellection-card ${chapter.cardclass}`}
				>
					{/* In this section category image */}
					<div className="left-img-box">
						<img src={`${apiEndpoints.baseUrl}/${chapter.category_img}`} alt={chapter.category_name} />
					</div>
					{/* In this section content related to the category */}
					<div className="text-box">
						<div className="text-left">
							<h2 className="h1">
								<span>{chapter.category_name}</span>
								<img src={require("../../assets/img/all-section/card-i-icon.svg").default} alt="i" />
							</h2>
							{/* <p>
							<span className="res-text">Responsible:</span>
							<span className="res-link">Unassigned+</span>
						</p> */}
						</div>
						<div className="text-cir">
							<SvgCircle value={chapter.catprogress} />
						</div>
					</div>
					{/* In this section there is an angle image. When we click on it , it will redirect to next page. */}
					<div className="angle-box">
						<img src={require("../../assets/img/all-section/right-angle.svg").default} alt="true" />
					</div>
					{/* assessment card popup start. When we hover on the card popup will show */}
					<div className="sellection-card-popup">
						<h2>{chapter.category_name}</h2>
						<p>The section's objective is to address the influence of Industry 4.0 into the company's strategy.</p> 
						<p>In particular, we will explore Industry 4.0 strategies and initiatives, sustainability practices, structures and policies that provide the agility to design and roll out an effective digital transformation.</p>
						<p>Typically, the person responsible for this section is the Assessment leader.</p>
						<p>Estimated engagement 35 minutes.</p>
						{/* <p>This section aims to examine the influence of Industry 4.0 into the company's strategy.</p>
						<p>
							Industry 4.0 vision refers to Industry 4.0 strategies and initiatives, sustainability practices, structures and policies
							that provide the agility to design and roll out an effective digital transformation.
						</p>
						<p>This section is typically addressed by the Assessment leader.</p>
						<p>Estimated engagement 35 minutes.</p> */}
					</div>
				</Link>
			);
		} else {
			return (
				// card wrapper which is containing three horizontal sections.
				<a key={chapter.id} data-index={index} className={`all-sellection-card ${chapter.cardclass}`} onClick={HandleUpdgradePopup}>
					{/* In this section category image */}
					<div className="left-img-box">
						<img src={`${apiEndpoints.baseUrl}/${chapter.category_img}`} alt={chapter.category_name} />
					</div>
					{/* In this section content related to the category */}
					<div className="text-box">
						<div className="text-left">
							<h2 className="h1">
								<span>{chapter.category_name}</span>
								<img src={require("../../assets/img/all-section/card-i-icon.svg").default} alt="i" />
							</h2>
							{/* <p>
							<span className="res-text">Responsible:</span>
							<span className="res-link">Unassigned+</span>
						</p> */}
						</div>
						<div className="text-cir">
							<SvgCircle value={chapter.catprogress} />
						</div>
					</div>
					{/* In this section there is an angle image. When we click on it , it will redirect to next page. */}
					<div className="angle-box">
						<img src={require("../../assets/img/all-section/right-angle.svg").default} alt="true" />
					</div>
					{/* assessment card popup start. When we hover on the card popup will show */}
					<div className={`sellection-card-popup ${index > 6 && "last-popup"}`}>
						<h2>{chapter.category_name}</h2>
						<div dangerouslySetInnerHTML={{ __html: chapter.category_description }}></div>
					</div>
				</a>
			);
		}
	});
	return (
		<>
			{/* Pgae Title */}
			<Helmet>
				<title>Assessment | SmarterChains</title>
				<meta name="description" content={`Assessment`} />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="#">Assessment</BreadcrumbsItem>
			<div className="all-sellection-main">{chapterDataItemSections}</div>
			{/* Upgrade Popoup */}
			<Modal
				open={isUpgradePopup}
				// onClose={HandleUpdgradePopup}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				className="home-index-upgrade-popup"
			>
				{/* Upgrade Popoup Wrapper */}
				<div className="upgrade-popup-content">
					{/* Close button to close the popup */}
					<button className="close-btn" onClick={HandleUpdgradePopup}>
						<img src={require("../../assets/img/common-img/close-icon-blue.svg").default} alt="close" />
					</button>
					{/* This section is containing image. */}
					<div className="upgrd-popup-img">
						<img src={require("../../assets/img/home-index/digital-maturity.gif").default} alt="digital_maturity" />
					</div>
					{/* Rest of the content of the upgrade popup in this section */}
					<div className="upgrd-popup-body">
						<h2 className="h1">Buy full Digital Maturity Assessment to Unlock Plants</h2>
						<p>Upgrade to the plan that best matches your profile and start the Assessment as early as today.</p>
						<Link to="/plans" className="btn-upgrade-now" onClick={HandleUpdgradePopup}>
							Upgrade Now{" "}
						</Link>
					</div>
				</div>
			</Modal>
		</>
	);
};

// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCircle = (props) => {
	const { value } = props;
	return (
		<svg viewBox="0 0 36 36">
			<text className="percent__text" x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="600">
				{value}
				<tspan fontSize="5">%</tspan>
			</text>
			<path
				className="circle-bg"
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke="#EBF5FA"
				strokeWidth="2.4"
				fill="none"
			></path>
			<path
				className="circle"
				strokeDasharray={`${value}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke={value < 100 && value > 0 ? "#FFBC1F" : value === 100 ? "#40D30D" : "transparent"}
				strokeWidth="2.4"
				fill="none"
			></path>
		</svg>
	);
};
