/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * AssignSectionPopup for ===> Popup component call for assign user 
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { AssignSectionPopup } from './assessment-assign-popup';

//ChapterView Component starts
export const ChapterView = (props) => {
	//variable declaration
	const sectionDataItems = props.sectionData;
	if (!sectionDataItems.length) {
		return null;
	}
	const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
	const [dis, setDis] = useState(false);
	const [distext, setDistext] = useState("Submit Results");
	const [discount, setDiscount] = useState(1);
	const sectionDataItem = props.sectionData[0];
	const catData = sectionDataItem.category;
	const chapterDataItem = sectionDataItem.chapters;
	if (!catData) {
		return null;
	}
	const inviteteam = sectionDataItem.invitedteam;
	console.log(sectionDataItem);
  	var fullname="";
	var email="";	
	if (!inviteteam.length) {
    	fullname=0;
		email=0;
			// return null;
  		}else{
			fullname =inviteteam[0].full_name;
			email=inviteteam[0].email
		  }
	// console.log(sectionDataItem);  	
	const uid = sectionDataItem.uid;
	const HandleSubmitResult = () => {
		setDis(true);
		setDiscount(discount + 1);
		setDistext("Submitting Results...");
		if (discount == 1) {
			serviceProvider.post("/results?type=calculate", JSON.stringify({ plant_id: uid, user_id: uid }), true).then((response) => {
				//console.log(response);
				window.location = "/result-walkthrough";
			});
		}
	};
	const handleAssignPoup = (e) => {
		setIsAssignSectionPopup(true);
		e.preventDefault();
	}
	const chapterDataItemSections = chapterDataItem.map((chapter, index) => {
	const count = index + 1;
		if (chapter.lastcatprogress == 'access') {
			return (
				// Card wrapper for chapters
				<Link key={chapter.id} data-index={index} data-id={chapter.id} to={`/assessmentlim/start/${chapter.id}`} className="assigned-card access">
					{/* Left section of card has number of the chapter */}
					<div className="assigned-card-left">
						<div className="assigned-card-icon-wrapper">
							<span className="number-text">{count}</span>
						</div>
					</div>
					{/* card left end */}
					{/* right section of the card has heading and assigned/unassigned user information */}
					<div className="assigned-card-right">
						<div className="assigned-rgt-sub-lft">
							<h1>
								{chapter.subcategory_name}
								<img alt="true" className="info-icon" src={require("../../assets/img/assigned/info.svg").default} />
							</h1>
							<p>
								<span className="res-text">Responsible:</span>
								{/* <span className="res-link" onClick={(e) => handleAssignPoup(e)}>Unassigned+</span> */}
								{fullname == undefined || fullname == 0 ? 
								<span className="res-text">{email? email:"Unassigned"}</span>
								// <span className="res-text">{"Unassigned"}</span>
								:
								<span className="res-text">{fullname ? fullname:"Unassigned"}</span>
								// <span className="res-text">{"Unassigned"}</span>
									}
							</p>
						</div>
						{/* Chapter Progress */}
						<div className="assigned-rgt-sub-rgt">
							<div className="assigned-card-rgt-border">
								<div className="assigned-progress-bar assigned-progress" data-percent="0%">
									<div>
										<SvgCircle value={chapter.catprogress} />
									</div>
								</div>
								{/* Button to navigate next page */}
								<div className="assigned-card-rgt-angle">
									<img alt="true" src={require("../../assets/img/assigned/right-angle.svg").default} />
								</div>
							</div>
						</div>
					</div>
					{/* Popup will show on card hover */}
					<div className={`sellection-card-popup ${index > 2 && "last-popup"}`}>
						<h2>{chapter.subcategory_name}</h2>
						<div dangerouslySetInnerHTML={{ __html: chapter.subcategory_description }}></div>
					</div>
				</Link>
			);
		} 
		else {
			return (
				// Card wrapper for chapters
				<Link key={chapter.id} data-index={index} data-id={chapter.id} className="assigned-card noaccess" >
					{/* Left section of card has number of the chapter */}
					<div className="assigned-card-left">
						<div className="assigned-card-icon-wrapper">
							<span className="number-text">{count}</span>
						</div>
					</div>
					{/* card left end */}
					{/* right section of the card has heading and assigned/unassigned user information */}
					<div className="assigned-card-right">
						<div className="assigned-rgt-sub-lft">
							<h1>
								{chapter.subcategory_name}
								<img alt="true" className="info-icon" src={require("../../assets/img/assigned/info.svg").default} />
							</h1>
							<p>
								<span className="res-text">Responsible:</span>
								{fullname == undefined || fullname ==0 ? 
								<span className="res-text">{email ? email:"Unassigned"}</span>
								:
								<span className="res-text">{fullname? fullname:"Unassigned"}</span>
									}
							</p>
						</div>
						{/* Chapter Progress */}
						<div className="assigned-rgt-sub-rgt">
							<div className="assigned-card-rgt-border">
								<div className="assigned-progress-bar assigned-progress" data-percent="0%">
									<div>
										<SvgCircle value={chapter.catprogress} />
									</div>
								</div>
								{/* Button to navigate next page */}
								<div className="assigned-card-rgt-angle">
									<img alt="true" src={require("../../assets/img/assigned/right-angle.svg").default} />
								</div>
							</div>
						</div>
					</div>
					{/* Popup will show on card hover */}
					<div className={`sellection-card-popup ${index > 2 && "last-popup"}`}>
						<h2>{chapter.subcategory_name}</h2>
						<div dangerouslySetInnerHTML={{ __html: chapter.subcategory_description }}></div>
					</div>
				</Link>
			);


		}
	});

	return (
		<>
			{/* Page Title */}
			<Helmet>
				<title>{catData.category_name} | SmarterChains</title>
				<meta name="description" content={`${catData.category_name} Assessment`} />
			</Helmet>
			{/* Breadcrumbs */}
			<BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/assessment">Assessment</BreadcrumbsItem>
			<BreadcrumbsItem to="#">{catData.category_name}</BreadcrumbsItem>
			{/* Assessment main wrapper  */}
			<div className="assigned-main-page">
				<section className="assigned-content">
					{/* assigned section that is containing heading and result */}
					<section className="assigned-sec">
						<h2 className="h2">{catData.category_name}</h2>
						<div className="assigned-card-row-continer">
							<div className="assigned-card-row">{chapterDataItemSections}</div>
						</div>
						{sectionDataItem.progress == 100 && catData.id == "1" && sectionDataItem.uid !== sectionDataItem.planId && (
							<a disabled={dis} class="btn-submit-result" onClick={HandleSubmitResult}>
								{distext}
							</a>
						)}
					</section>
				</section>
			</div>
			{/* AssignPopup Component call on the page */}
			<AssignSectionPopup open={isAssignSectionPopup} setOpen={setIsAssignSectionPopup}  {...props} />
		</>
	);
};

// svgcircle component which is used for progress circle on assessment and chapter pages
const SvgCircle = (props) => {
	const { value } = props;
	return (
		<svg viewBox="0 0 36 36">
			<text className="percent__text" x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" fontSize="10" fontWeight="600">
				{value}
				<tspan fontSize="5">%</tspan>
			</text>
			<path
				className="circle-bg"
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831
                                            a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke="#EBF5FA"
				strokeWidth="2.4"
				fill="none"
			></path>
			<path
				className="circle"
				strokeDasharray={`${value}, 100`}
				d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
				stroke={value < 100 && value > 0 ? "#FFBC1F" : value === 100 ? "#40D30D" : "transparent"}
				strokeWidth="2.4"
				fill="none"
			></path>
		</svg>
	);
};
