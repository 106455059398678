import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import {
    closeSvg, imageUploadIcon, crossIcon, zoomCloseIcon,
} from './assessor-svg.js';
import serviceProvider from '../../services/axios';
import $ from "jquery";
import {
    SiriQNAPopWrapper,
    SiriQNAPopWrap,
    SiriQNAHead,
    Title,
    SiriQNABody,
    TextAreaWrapper,
    ImageWrapper,
    FooterWrap,
    ZoomImageContainer,
    RightSection,
} from './assessor-style.js';



export const AssessorNoteView = (props) => {
    const noteparentdata= props.noteparentdata;
    const [value, setValue] = React.useState(0);
    const [open, setOpen]= useState(true);
    const { dimensionCount, dimensionName,isSaveBtn,setIsSaveBtn=()=>{ }, handleMainPopupClose=()=>{}} = props;
    const [text, setText] = useState(noteparentdata?.note);
    const [isSaving, setIsSaving] = useState(false);
    const isFirstTime = useRef(true);
    const [fileAttachmentArr, setFileAttachmentArr] = useState([]);
    const [lightBoxImage, setLightBoxImage] = useState(false);
    const [zoomImage, setZoomImage] = useState(false);
    const [isEditBtn, setIsEditBtn] = useState(false);
    const [saveImage, setSaveImage] = useState(noteparentdata?.note_files)
    
    const handleTextChange = (event) => {
        const newText = event.target.value;
        isFirstTime.current = false;
        setText(newText);
    };

    // Function to handle image
    const handleFileAttachment = (e) => {
        const files = e.target.files;
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const tmpFilesArr = [...fileAttachmentArr];
        for (let i in files) {
            let file = files[i];
            if (i !== 'item' && i !== 'length') {
                if (allowedTypes.includes(file.type)) {
                    tmpFilesArr.push(file);
                }
                else {
                    alert('Only image files in JPEG, PNG format are allowed.');
                }

            }
        }
        setFileAttachmentArr(tmpFilesArr);
    }

    const removeFileAttachment = (ind, e) => {
        e.preventDefault();
        e.stopPropagation();
        let tmpArr = fileAttachmentArr.filter((item, index) => index != ind);
        setFileAttachmentArr(tmpArr);
    }
    const removeEditFileAttachment = (item,e)=>{
        e.preventDefault();
        e.stopPropagation();
        let tmpFilterArr = saveImage?.filter((i)=> i.id!= item.id);
        setSaveImage(tmpFilterArr);
        let dbid = item.id;
        serviceProvider.post("/siri?param=deleteimage", {dbid:dbid}, true).then((response) => {
                            if (response.data.status == 200) {
                                if(response.data.data==1){
                                  // props.setUpdate(pre=>!pre);
                                }
                            } 
       });
        
    }
    const handleZoomImage = (url) => {
        console.log(url);
        setLightBoxImage(url);
        setZoomImage(true);
        setOpen(false);
    }
    const handleZoomClose = () => {
        setZoomImage(false);
        setOpen(true);
    }
    const handleSaveBtn = () => {
        
                const formData = new FormData();
                formData.append('plant_id', noteparentdata?.plant_id);
                formData.append('user_id', props?.user_id);
                formData.append('dim_id', noteparentdata?.dim_id);
                formData.append('note', text);
               
                
                for (let i = 0; i < fileAttachmentArr.length; i++) {
                    formData.append("file[]", fileAttachmentArr[i]);
                  }
            
                    serviceProvider
                        .post("/siri?param=createnote", formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then((response) => {
                            if (response.data.status == 200) {
                                if(response.data.data==1){
                                   props.setUpdate(pre=>!pre);
                                }
                            }
                           
                         setOpen(false);
                });
            
               setIsSaveBtn(true);   
               setOpen(false);  
               handleMainPopupClose();  
    
    }
    const handleEdit=()=>{
        setIsEditBtn(true);
    }

    useEffect(() => {
        let tmpSetTimeOut = '';
        let tmpTimeOut = setTimeout(() => {
            if (!isFirstTime.current) {
                //setIsSaving(true);
                tmpSetTimeOut = setTimeout(() => {
                    setIsSaving(false);
                   // console.log('aq');
                }, 1000)
            }
        }, 1000);
        return () => {
            clearTimeout(tmpTimeOut);
            clearTimeout(tmpSetTimeOut);
        }
    }, [text])
  
    return (
        <>
            {open &&
                <SiriQNAPopWrapper data-component="SiriQNAPopWrapper">
                    <SiriQNAPopWrap data-component="SiriQNAPopWrap">
                        <SiriQNAHead data-component="SiriQNAHead">
                            {/* <Title data-component="Title">{dataList.band} - {dataList.band_name}: Question and Answers</Title> */}
                            <Title data-component="Title"><span>Assessor’s Note for</span>  {dimensionCount}: {dimensionName || `Dimension Name`}</Title>
                            <RightSection data-components="RightSection">
                                {isSaveBtn && 
                                    <button className='edit-btn' onClick={()=>handleEdit()} style={{visibility:!isEditBtn?'':'hidden'}}>
                                        Edit Comment
                                    </button>
                                }
                                <button className='close-btn' onClick={() => handleMainPopupClose()}>
                                    <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                                </button>
                            </RightSection>
                        </SiriQNAHead>
                        <SiriQNABody data-component="SiriQNABody">
                            <TextAreaWrapper data-component="TextAreaWrapper">
                                <div value={value} index={2} className="tab-text-panel">
                                    <textarea
                                        value={text}
                                        onChange={handleTextChange}
                                        placeholder="Type your text here..."
                                        readOnly = {!(isEditBtn || !isSaveBtn)}
                                    />
                                    {isSaving && <span className='text-span'>Saving text changes...</span>}
                                </div>
                            </TextAreaWrapper>
                            <ImageWrapper data-component="ImageWrappe">
                                <h2 className='image-heading'>IMAGE <span>(upload image in jpg and png)</span></h2>
                                <FooterWrap data-component="FooterWrap">
                                    <div className={`image-upload-wrap ${(isEditBtn || !isSaveBtn)?'width':''}`}>
                                    { (isEditBtn || !isSaveBtn) &&
                                        <label className={`attachment-input-wrap ${fileAttachmentArr.length < 5 ? '' : 'inactive'}`}>
                                            <div className="attachment-input">
                                                <i dangerouslySetInnerHTML={{ __html: imageUploadIcon }}></i>
                                                <input className="file" name="my_file" multiple placeholder="Add up to 5 files" type="file"  style={{ width: '0px', height: '0px', transform: 'scale(0)', overflow: 'hidden' }} onChange={(e) => handleFileAttachment(e)} />
                                            </div>
                                        </label>
                                    }
                                    {saveImage?.length>0 &&
                                        <>
                                            {saveImage.map((file, ind) => (
                                                <div className="uploaded-file-content" key={ind} onClick={() => handleZoomImage(file.url)}>
                                                    { isEditBtn &&
                                                        <button className="close-file-icon" onClick={(e) => removeEditFileAttachment(file,e)}>
                                                            <i dangerouslySetInnerHTML={{ __html: crossIcon }}></i>
                                                        </button>
                                                    }
                                                    <div className="file-img-wrap" >
                                                        <img src={file.url}/>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    }
                                    {fileAttachmentArr.map((file, ind) => (
                                        <div className="uploaded-file-content" key={ind} onClick={() => handleZoomImage(URL.createObjectURL(file))}>
                                            <button className="close-file-icon" onClick={(e) => removeFileAttachment(ind, e)}>
                                                <i dangerouslySetInnerHTML={{ __html: crossIcon }}></i>
                                            </button>
                                            <div className="file-img-wrap" >
                                                <img src={URL.createObjectURL(file)}/>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                    { (isEditBtn || !isSaveBtn) &&
                                        <div className='save-btn-wrap'>
                                            <button className={`save-btn ${text.length > 0 ? 'active' : ''}`} onClick={() => handleSaveBtn()}>Save Changes</button>
                                        </div>
                                    }
                                </FooterWrap>
                            </ImageWrapper>
                        </SiriQNABody>
                    </SiriQNAPopWrap>
                </SiriQNAPopWrapper>
            }
            {zoomImage &&
                <ZoomImageContainer data-component="ZoomImageContainer">
                    <div className='zoom-image-wrap'>
                        <img src={lightBoxImage} />
                        <button className='zoom-close-icon sm-hide' onClick={() => handleZoomClose()}>
                            <i dangerouslySetInnerHTML={{ __html: zoomCloseIcon }}></i>
                        </button>
                    </div>
                    <button className='zoom-close-icon sm-visible' onClick={() => handleZoomClose()}>
                        <i dangerouslySetInnerHTML={{ __html: zoomCloseIcon }}></i>
                    </button>
                </ZoomImageContainer>
            }
        </>
    )
}