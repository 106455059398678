/** imported components

  * ResultsPowerBiView ===> for custom component.

**/
import React from "react";
import { ResultsPowerBiView } from "./results-powerbi-view";
// ResultsPowerBiController start
export const ResultsPowerBiController = (props) => {
  return (
    <>
      <ResultsPowerBiView {...props} />
    </>
  );
};
