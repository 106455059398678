import styled from 'styled-components'
import { Link } from 'react-router-dom';


export const MainWrapper = styled.section`
    padding: 3rem;
    display: flex;
    flex-direction: column;
    `;

export const HowToUseBtn = styled.button`
    padding: 1.1rem 1.6rem;
    background: linear-gradient(131.53deg, #8686DF 14.79%, #6464C8 85.39%);
    box-shadow: 0px 0px 8px rgba(21, 27, 38, 0.12);
    border-radius: 7.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    transiton:0.25s ease-out;
    z-index:9;
    scale:1;
    position:relative;
    overflow:hidden;
    gap:0.8rem;
    .how-txt{
        display: inline-block;
        font-weight: 400;
        font-size: 1.6rem;
        color: #FFFFFF;
    }
    i{
        display: inline-block;
        height: 2.4rem;
        width: 2.4rem;
        svg{
            height: 100%;
            width: 100%
        }
    }
    &:after{
        content:"";
        display:block;
        background:#6464C8;
        z-index:-1;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        border-radius:inherit;
        transiton:0.25s ease-out;
        transform:translateY(5rem) translateX(-100%);
    }
    &:hover{
        scale:1.05;
        &:after{
            transform:translateY(0rem) translateX(0%);
        }
    }
`
export const HowToUsePopWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 9999;
`
export const HowToUsePopWrap = styled.div`
    position: absolute;
    width: ${({active})=>active? '99.2rem': '0px'};
    max-height:${({active})=>active? 'calc(100vh - 15rem)': '0px'};
    overflow:${({active})=>active? '': 'hidden'};
    background: #FFFFFF;
    border: 0.1rem solid #F0F0F0;
    border-radius: 2.4rem;
    padding: 2.4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    @media(max-height:567px){
        max-height:${({active})=>active? 'calc(100vh - 1rem);': '0px'};
    }
    @media(max-width:1024px){
        width: ${({active})=>active? '700px': '0px'};
        max-height:${({active})=>active? '500px': '0px'};
    }
`
export const HowToUsePopHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 0.06rem solid #CBCFD2;
    align-items: center;
    .head-txt{
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #0D2C54;
    }
`
export const HowCloseBtn = styled.button`
    display: inline-block;
    border: unset;
    box-shadow: unset;
    height: 3.2rem;
    width: 3.2rem;
    svg{
        width: 100%;
        height: 100%;
    }
`
export const HowToUsePopBody = styled.div`
    padding-top: 2.4rem;
    padding-bottom: 0.5rem;
    display: flex;
    flex: 1;
    overflow: hidden;
`
export const TabContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`
export const TabLeftSection = styled.div`
    padding-right: 0.8rem;
    overflow-y: auto;
    scrollbar-width: thisn;
    width: 27.5rem;
    /*positon is used for js please don't remove it*/
    position:relative;
`
export const TabRightSection = styled.div`
    padding-left: 2.4rem;
    flex: 1;
    display:flex;
    flex-direction:column;
`
export const TabRightSectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`
export const TabImgWrap = styled.div`
    background: #ffffff;
    border: 0.06rem solid #118ACB;
    border-radius: 0.8rem;
    margin-bottom: 1rem;
    position: relative;
    .loss-svg{
        padding: 0.5rem;
        width: 100%;
        height: 29rem;
        @media(max-height:700px){
            height:27rem;
        }
        @media(max-height:567px){
            height: 24rem;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .loss-cat-svg{
        padding: 0.5rem;
        width: 100%;
        height: 39.5rem;
        @media(max-height:700px){
            height: 35.5rem;
        }
        @media(max-height:567px){
            height: 32.5rem;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .zoom-btn{
        position: absolute;
        height: 4rem;
        width: 4rem;
        right: 2rem;
        bottom: 2rem;
        background: #f1f1f1;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        i{
            line-height: 0;
            display: inline-block;
            width: 100%;
            svg{
                width: 100%;
            }
        }
    }
    &:hover{
        .zoom-btn{
            opacity: 1;
            visibility: visible;
        }
    }
`
export const TabTxtWrap = styled.div`
    border-left: 0.6rem solid #118acb;
    padding-left: 1.2rem;
    margin-top: auto;
    .blue-text{
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.8rem;
        letter-spacing: 0.02em;
        color: #118ACB;
        margin-bottom: 0.8rem;
    }
    .gray-text{
        font-weight: 400;
        font-size: 1.4rem;
        line-height; 1.8rem;
        color: #607D8B;
        margin: 0;
    }

`
export const TabBtn = styled.button`
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: ${({active})=>active? '#118acb': '#4D4D4F'};
    padding: 1.6rem 1.4rem;
    background: ${({active})=>active? '#F4F6FC !important': '#F6F6F6'};
    border-radius: 0.8rem;
    text-transform: uppercase;
    text-align: left;
    border: 0.06rem solid ${({active})=>active? '#118ACB !important': 'transparent'};
    border-radius: 0.8rem;
    margin-bottom: 1.6rem;
    width: 100%;
    &:hover{
        background: #F6F6F6;
        border-color:#7D868C;
    }
`
export const ZoomImageContainer = styled.div`
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    display: ${({active})=>active? 'block': 'none'};
    .zoom-image-wrap{
        padding: 2rem 0;
        display: flex;
        justify-content: center;
        svg{
            width: 90% !important;
            height: auto !important;
        }
    }
    ${HowCloseBtn}{
        position: fixed;
        right: 2rem;
        top: 2rem;
        z-index: 999;
        .icon-svg{
            svg{
                rect{
                    fill: #000000;
                }
                path{
                    fill: #ffffff;
                }
            }
        }
    }
`
export const LossCatTab = styled.div`
    display: ${({ open }) => (open ? "flex" : "none")};
    height: 100%;
    overflow: hidden;
`;
export const LossMethodTab = styled.div`
    display: ${({ open }) => (open ? "flex" : "none")};
    height: 100%;
    overflow: hidden;
    ${TabImgWrap}{
        background: #FAFCFD;
    }
`;
export const LossTab = styled.div`
    cursor: pointer;
    background: #F0F0F0;
    border-radius: 0.4rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #0D2C54;
    letter-spacing: 0.02em;
    padding: 0.8rem 1.6rem;
    margin-right: 1.6rem;
    width: max-content;
    &.active{
        background: #2C55A0;
        color: #ffffff;
    }
`;
export const TabHeadWrap = styled.div`
    display: flex;
    margin-bottom: 3.4rem;
`;