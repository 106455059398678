import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "@material-ui/core";
export const HomeTour = (props) => {
	const secondSlideData = [
		{
			title: `My Trainings`,
			paragraph: `Start your Free Introductory course and have a look at all our available courses to help you augment your Industry 4.0 understanding.`,
		},
		{
			title: `My Assessment`,
			paragraph: `Unlock the Free limited Digital Maturity Assessment for Factory of the Future Leadership and see how your plant scores.`,
		},
		{
			title: `Home`,
			paragraph: `This is your home page. See the plants you are associated with or add more. Access the training section and continue learning.`,
		},
		{
			title: `Learning & Resources`,
			paragraph: `Industry 4.0 is all about learning. Discover our Factory of the Future framework, get an in-depth analysis of our methodology and explore data-driven insights from our latest CPG industry benchmarking study.`,
		},
		{
			title: `Upgrade your plan`,
			paragraph: `Learn more about how to upscale your current plan, see our enterprise plans and start building your path across the Industry 4.0 world.`,
		},
		{
			title: `SmarterChains Quickstart`,
			paragraph: `Complete our quickstart guide to explore the full potential of SmarterChains platform. Learn more about Industry 4.0 and envision how to leverage our platform to prepare, define and execute your Industry 4.0 journey.`,
		},
	];
	const { HandleOnboadering, isOnboarding } = props;
	const TotalSecondSlides = secondSlideData.length;
	const [secondSlideIndex, setSecondSlideIndex] = useState(0);
	// function to handle
	const HandleSecondSlide = (ev) => {
		const scrollDiv = document.querySelector(".training-content");
		console.log(ev)
		if (ev === "prev" && secondSlideIndex > 0) {
			let tmpVal = secondSlideIndex - 1;
			if (tmpVal == 1) {
				scrollDiv.scrollTop = 50;
			} else {
				scrollDiv.scrollTop = 0;
			}
			
			if (tmpVal === 5) {
				HandleClick(".quick-start-btn");
			}
			if(tmpVal===1 || tmpVal===2 || tmpVal===3 || tmpVal===4 ){
				HandleClick(".home-index-right .close-btn");
			}
			setSecondSlideIndex(tmpVal);
		} else if (ev === "next" && secondSlideIndex < TotalSecondSlides - 1) {
			let tmpVal = secondSlideIndex + 1;
			if (tmpVal == 1) {
				scrollDiv.scrollTop = 50;
			} else {
				scrollDiv.scrollTop = 0;
			}
			if (tmpVal === 5) {
				HandleClick(".quick-start-btn");
			} 
			if(tmpVal===1 || tmpVal===2 || tmpVal===3 || tmpVal===4 ){
				HandleClick(".home-index-right .close-btn");
			}
			setSecondSlideIndex(tmpVal);
		} else if (ev !== "next" && ev !== "prev") {
			if(ev===1 || ev===2 || ev===3 || ev===4 ){
				HandleClick(".home-index-right .close-btn");
			}
			if (ev === 5) {
				HandleClick(".quick-start-btn");
			}
			setSecondSlideIndex(ev);
		}
	};
	const HandleClose = () => {
		HandleOnboadering();
	};
	const HandleClick = (e) => {
		if (document.querySelector(e)) {
			document.querySelector(e).click();
		}
	};

	useEffect(() => {
		HandleClick(".ng-toggle-wrap img");
	}, []);

	return (
		<Modal open={isOnboarding} className="onboarding-modal bg-hidden">
			<>
				{secondSlideData.map((item, index) => (
					<div
						key={index}
						className={`second-type-modal`}
						id={`s2-modal-${index + 1}`}
						style={{ display: index !== secondSlideIndex ? "none" : "" }}
					>
						<div className="card-2">
							<Link to="/" className="close-btn">
								<img src={require(`../../assets/img/common-img/close-icon-blue.svg`).default} alt="X" />
							</Link>
							<div className="header-2">{`${secondSlideIndex + 1} of 6`}</div>
							{/* header-2 end */}
							<div className="content-2">
								<h2 className="title-h1">{item.title}</h2>
								<p>{item.paragraph}</p>
							</div>
							{/* content-2 end */}
							<div className="footer-2">
								<div className="dots-sec">
									<button onClick={() => HandleSecondSlide(0)} disabled={secondSlideIndex === 0 ? true : false}></button>
									<button onClick={() => HandleSecondSlide(1)} disabled={secondSlideIndex === 1 ? true : false}></button>
									<button onClick={() => HandleSecondSlide(2)} disabled={secondSlideIndex === 2 ? true : false}></button>
									<button onClick={() => HandleSecondSlide(3)} disabled={secondSlideIndex === 3 ? true : false}></button>
									<button onClick={() => HandleSecondSlide(4)} disabled={secondSlideIndex === 4 ? true : false}></button>
									<button onClick={() => HandleSecondSlide(5)} disabled={secondSlideIndex === 5 ? true : false}></button>
								</div>
								{secondSlideIndex != 0 && secondSlideIndex < TotalSecondSlides - 1 && (
									<button onClick={() => HandleSecondSlide("prev")}>Previous</button>
								)}
								{secondSlideIndex < TotalSecondSlides - 1 ? (
									<button className="btn-blue" onClick={() => HandleSecondSlide("next")}>
										Next
									</button>
								) : (
									<Link to="/quickstartopen" className="btn-blue" onClick={() => HandleSecondSlide(5)}>
										Start exploring
									</Link>
								
								)}
							</div>
							{/* footer-2 end */}
						</div>
					</div>
				))}
			</>
		</Modal>
	);
};
