
export const TabAddBtnIcon = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z" fill="#4D4D4F"/>
</svg>
`

export const reviewSvg = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_5859_62285" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_5859_62285)">
<path d="M9.99999 18.3335C8.84721 18.3335 7.76388 18.1147 6.74999 17.6772C5.7361 17.2397 4.85416 16.6425 4.10416 15.8856C3.35416 15.1286 2.76041 14.2432 2.32291 13.2293C1.88541 12.2154 1.66666 11.1391 1.66666 10.0002C1.66666 7.81961 2.38888 5.94461 3.83332 4.37516C5.27777 2.80572 7.05554 1.91683 9.16666 1.7085V4.2085C7.7361 4.40294 6.54513 5.0453 5.59374 6.13558C4.64235 7.22586 4.16666 8.51405 4.16666 10.0002C4.16666 11.6113 4.7361 12.9863 5.87499 14.1252C7.01388 15.2641 8.38888 15.8335 9.99999 15.8335C10.9167 15.8335 11.7743 15.6391 12.5729 15.2502C13.3715 14.8613 14.0417 14.3335 14.5833 13.6668L16.75 14.9168C16 15.9585 15.0347 16.7884 13.8542 17.4064C12.6736 18.0245 11.3889 18.3335 9.99999 18.3335ZM9.16666 13.3335V10.8335H6.66666V9.16683H9.16666V6.66683H10.8333V9.16683H13.3333V10.8335H10.8333V13.3335H9.16666ZM17.625 13.3752L15.4583 12.1252C15.5833 11.7918 15.6771 11.4481 15.7396 11.0939C15.8021 10.7397 15.8333 10.3752 15.8333 10.0002C15.8333 8.51405 15.3576 7.22586 14.4062 6.13558C13.4549 5.0453 12.2639 4.40294 10.8333 4.2085V1.7085C12.9444 1.91683 14.7222 2.80572 16.1667 4.37516C17.6111 5.94461 18.3333 7.81961 18.3333 10.0002C18.3333 10.6113 18.2778 11.2016 18.1667 11.771C18.0555 12.3404 17.875 12.8752 17.625 13.3752Z" fill="white"/>
</g>
</svg>
`

export const incompleteSvg = `<svg width="320" height="238" viewBox="0 0 320 238" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M320 208.9H0V209.06H320V208.9Z" fill="#EBEBEB"/>
<path d="M202.029 213.245H185.734V213.405H202.029V213.245Z" fill="#EBEBEB"/>
<path d="M291.482 216.697H252.87V216.857H291.482V216.697Z" fill="#EBEBEB"/>
<path d="M182.266 213.322H142.374V213.482H182.266V213.322Z" fill="#EBEBEB"/>
<path d="M116.506 218.094H108.16V218.254H116.506V218.094Z" fill="#EBEBEB"/>
<path d="M162.618 218.094H124.614V218.254H162.618V218.094Z" fill="#EBEBEB"/>
<path d="M58.1825 215.401H33.5745V215.561H58.1825V215.401Z" fill="#EBEBEB"/>
<path d="M151.68 180.442H28.1025C27.1344 180.441 26.2065 180.056 25.5226 179.373C24.8386 178.69 24.4545 177.764 24.4545 176.799V3.6114C24.4629 2.65178 24.8508 1.73422 25.5338 1.05804C26.2168 0.38185 27.1399 0.00164388 28.1025 0H151.68C152.649 0 153.579 0.383847 154.264 1.0671C154.949 1.75035 155.334 2.67704 155.334 3.6433V176.799C155.334 177.765 154.949 178.692 154.264 179.375C153.579 180.059 152.649 180.442 151.68 180.442ZM28.1025 0.127612C27.1768 0.129302 26.2896 0.497087 25.6357 1.15024C24.9817 1.8034 24.6145 2.68855 24.6145 3.6114V176.799C24.6145 177.722 24.9817 178.607 25.6357 179.26C26.2896 179.913 27.1768 180.281 28.1025 180.283H151.68C152.606 180.281 153.494 179.914 154.149 179.261C154.804 178.608 155.173 177.722 155.174 176.799V3.6114C155.173 2.68796 154.804 1.80282 154.149 1.14985C153.494 0.49688 152.606 0.129298 151.68 0.127612H28.1025Z" fill="#EBEBEB"/>
<path d="M290.118 180.442H166.534C165.566 180.441 164.637 180.056 163.952 179.373C163.267 178.691 162.882 177.765 162.88 176.799V3.6114C162.89 2.6512 163.279 1.73365 163.963 1.05764C164.648 0.381627 165.571 0.00162595 166.534 0H290.118C291.08 0.00332076 292.001 0.384269 292.683 1.06028C293.365 1.73628 293.752 2.65288 293.76 3.6114V176.799C293.76 177.763 293.377 178.688 292.694 179.371C292.012 180.054 291.085 180.439 290.118 180.442ZM166.534 0.127612C165.608 0.129298 164.72 0.49688 164.065 1.14985C163.41 1.80282 163.042 2.68796 163.04 3.6114V176.799C163.042 177.722 163.41 178.608 164.065 179.261C164.72 179.914 165.608 180.281 166.534 180.283H290.118C291.045 180.281 291.932 179.914 292.587 179.261C293.242 178.608 293.611 177.722 293.613 176.799V3.6114C293.611 2.68796 293.242 1.80282 292.587 1.14985C291.932 0.49688 291.045 0.129298 290.118 0.127612H166.534Z" fill="#EBEBEB"/>
<path d="M171.488 17.7188H56.7424V57.5654H171.488V17.7188Z" fill="#F0F0F0"/>
<path d="M169.306 55.3896V19.8945L58.9249 19.8945V55.3896L169.306 55.3896Z" fill="#E6E6E6"/>
<path d="M96.4224 18.1655H94.24V57.1125H96.4224V18.1655Z" fill="#F0F0F0"/>
<path d="M82.1504 55.3833H94.24V19.8882H82.1504V55.3833Z" fill="#EFEFEF"/>
<path d="M119.635 55.3833H131.725V19.8882H119.635V55.3833Z" fill="#EFEFEF"/>
<path d="M157.216 55.3833H169.306V19.8882H157.216V55.3833Z" fill="#EFEFEF"/>
<path d="M133.914 18.1655H131.731V57.1125H133.914V18.1655Z" fill="#F0F0F0"/>
<path d="M45.8752 57.5591H56.7424L56.7424 17.7125H45.8752L45.8752 57.5591Z" fill="#E6E6E6"/>
<path d="M86.7456 55.3898H66.624L71.3472 25.1204H91.4688L86.7456 55.3898Z" fill="#EBEBEB"/>
<path d="M126.067 55.3898H105.946L109.44 25.1204H129.562L126.067 55.3898Z" fill="#F5F5F5"/>
<path d="M116.454 55.3896H98.1504L105.85 27.8574H124.154L116.454 55.3896Z" fill="#EFEFEF"/>
<path d="M79.0464 22.6765H58.9248V55.3897H79.0464V22.6765Z" fill="#F5F5F5"/>
<path d="M164.896 22.6765H144.774V55.3897H164.896V22.6765Z" fill="#F5F5F5"/>
<path d="M150.65 55.3896H135.059L140.48 27.8574H156.07L150.65 55.3896Z" fill="#EFEFEF"/>
<path d="M142.886 55.3895H133.914V29.8545H151.462L142.886 55.3895Z" fill="#F5F5F5"/>
<path d="M164.896 22.6765H160.314V55.3897H164.896V22.6765Z" fill="#FAFAFA"/>
<path d="M73.248 52.9588C73.0884 52.9588 72.9354 52.8956 72.8226 52.7831C72.7098 52.6706 72.6464 52.5181 72.6464 52.359V25.7265C72.6456 25.6472 72.6605 25.5685 72.6903 25.495C72.7202 25.4215 72.7644 25.3547 72.8203 25.2983C72.8763 25.2419 72.9429 25.1972 73.0163 25.1666C73.0897 25.1361 73.1684 25.1204 73.248 25.1204C73.3307 25.1151 73.4136 25.1269 73.4915 25.1551C73.5695 25.1832 73.6407 25.2272 73.7008 25.2841C73.7609 25.341 73.8085 25.4097 73.8407 25.4858C73.873 25.5619 73.889 25.6439 73.888 25.7265V52.3207C73.8936 52.406 73.881 52.4914 73.8508 52.5714C73.8206 52.6514 73.7737 52.724 73.7131 52.7844C73.6525 52.8448 73.5797 52.8916 73.4994 52.9217C73.4192 52.9518 73.3335 52.9644 73.248 52.9588Z" fill="#F0F0F0"/>
<path d="M209.062 57.5652H94.3168V97.4118H209.062V57.5652Z" fill="#F0F0F0"/>
<path d="M206.886 95.2363V59.7412L96.5057 59.7412V95.2363H206.886Z" fill="#E6E6E6"/>
<path d="M133.997 58.0122H131.814V96.9592H133.997V58.0122Z" fill="#F0F0F0"/>
<path d="M119.725 95.23H131.814V59.7349H119.725V95.23Z" fill="#EFEFEF"/>
<path d="M157.21 95.23H169.299V59.7349H157.21V95.23Z" fill="#EFEFEF"/>
<path d="M194.791 95.23H206.88V59.7349H194.791V95.23Z" fill="#EFEFEF"/>
<path d="M171.494 58.0122H169.312V96.9592H171.494V58.0122Z" fill="#F0F0F0"/>
<path d="M83.4496 97.4058H94.3168V57.5591H83.4496V97.4058Z" fill="#E6E6E6"/>
<path d="M161.734 95.2298H141.613L146.342 64.9604H166.458L161.734 95.2298Z" fill="#EBEBEB"/>
<path d="M201.222 95.2298H181.101L184.595 64.9604H204.717L201.222 95.2298Z" fill="#F5F5F5"/>
<path d="M191.603 95.2299H173.306L181.005 67.6978H199.309L191.603 95.2299Z" fill="#EFEFEF"/>
<path d="M154.035 62.5168H133.914V95.23H154.035V62.5168Z" fill="#F5F5F5"/>
<path d="M127.482 62.5168H107.36V95.23H127.482V62.5168Z" fill="#F5F5F5"/>
<path d="M113.235 95.2299H97.6384L103.059 67.6978H118.656L113.235 95.2299Z" fill="#EFEFEF"/>
<path d="M105.472 95.23H96.4993V69.7014H114.042L105.472 95.23Z" fill="#F5F5F5"/>
<path d="M127.482 62.5168H122.899V95.23H127.482V62.5168Z" fill="#FAFAFA"/>
<path d="M148.243 92.7861C148.163 92.7869 148.084 92.7719 148.009 92.7417C147.935 92.7115 147.868 92.6669 147.811 92.6104C147.755 92.554 147.71 92.4868 147.68 92.4129C147.649 92.339 147.634 92.2598 147.635 92.1799V65.5666C147.643 65.4083 147.709 65.2586 147.822 65.1466C147.934 65.0345 148.084 64.9682 148.243 64.9604C148.401 64.9697 148.549 65.0367 148.66 65.1486C148.772 65.2605 148.837 65.4094 148.845 65.5666V92.1799C148.846 92.2592 148.831 92.3379 148.801 92.4114C148.771 92.4849 148.727 92.5518 148.671 92.6082C148.615 92.6645 148.548 92.7093 148.475 92.7398C148.401 92.7704 148.323 92.7861 148.243 92.7861Z" fill="#F0F0F0"/>
<path d="M179.686 40.3379V48.4922C179.702 50.8962 180.667 53.1971 182.373 54.8958C184.079 56.5944 186.389 57.5537 188.8 57.5654C191.217 57.5621 193.534 56.6065 195.247 54.907C196.961 53.2074 197.93 50.9017 197.946 48.4922V40.3379H179.686Z" fill="#EBEBEB"/>
<path d="M122.413 148.271H65.3568V154.901H122.413V148.271Z" fill="#E6E6E6"/>
<path d="M122.413 154.901H69.1455V198.321H122.413V154.901Z" fill="#EBEBEB"/>
<path d="M122.413 198.321L250.854 198.321V154.901H122.413V198.321Z" fill="#F5F5F5"/>
<path d="M69.1455 157.357L250.854 157.357V154.907L69.1455 154.907V157.357Z" fill="#EFEFEF"/>
<path d="M122.413 154.901L254.643 154.901V148.271L122.413 148.271V154.901Z" fill="#F0F0F0"/>
<path d="M69.1455 198.321V208.9L79.7567 198.321H69.1455Z" fill="#EBEBEB"/>
<path d="M122.413 198.321V208.9L133.024 198.321H122.413Z" fill="#F5F5F5"/>
<path d="M250.854 198.321V208.9L240.243 198.321H250.854Z" fill="#F5F5F5"/>
<path d="M122.413 198.321V208.9L111.802 198.321H122.413Z" fill="#EBEBEB"/>
<path d="M197.587 198.321V208.9L186.976 198.321H197.587Z" fill="#EFEFEF"/>
<path d="M197.587 198.321V208.9L208.198 198.321H197.587Z" fill="#EFEFEF"/>
<path d="M156.858 124.759L152.986 146.663H136.237L135.642 148.271H152.698H163.13H191.462L195.622 124.759H156.858Z" fill="#EFEFEF"/>
<path d="M272.8 30.3716H227.514V105.024H272.8V30.3716Z" fill="#EFEFEF"/>
<path d="M274.189 30.3716H228.902V105.024H274.189V30.3716Z" fill="#F0F0F0"/>
<path d="M251.686 83.177H247.642L251.398 52.2249H255.443L251.686 83.177Z" fill="#FAFAFA"/>
<path d="M262.387 83.177H258.343L262.099 52.2249H266.144L262.387 83.177Z" fill="#FAFAFA"/>
<path d="M240.992 83.177H236.947L240.704 52.2249H244.749L240.992 83.177Z" fill="#FAFAFA"/>
<path d="M160 237.714C228.533 237.714 284.09 234.481 284.09 230.492C284.09 226.503 228.533 223.269 160 223.269C91.4672 223.269 35.9104 226.503 35.9104 230.492C35.9104 234.481 91.4672 237.714 160 237.714Z" fill="white"/>
<path d="M183.776 54.0562H180.787V54.6942H183.776V54.0562Z" fill="#7DB9FF"/>
<path d="M178.125 54.0562H165.805V54.6942H178.125V54.0562Z" fill="#7DB9FF"/>
<path d="M204.397 168.211H127.014C125.308 168.181 123.674 167.52 122.428 166.358C121.182 165.196 120.413 163.615 120.269 161.92L113.421 62.5806C113.355 61.7744 113.458 60.9634 113.724 60.1993C113.99 59.4353 114.413 58.7349 114.966 58.1429C115.519 57.5509 116.19 57.0804 116.935 56.7612C117.681 56.4421 118.485 56.2814 119.296 56.2893H196.672C198.379 56.3168 200.014 56.9759 201.261 58.1385C202.508 59.3012 203.276 60.8845 203.417 62.5806L210.265 161.92C210.333 162.726 210.231 163.537 209.966 164.301C209.701 165.066 209.279 165.767 208.727 166.359C208.174 166.951 207.503 167.422 206.758 167.741C206.012 168.06 205.208 168.22 204.397 168.211Z" fill="#7DB9FF"/>
<path d="M126.867 166.112C125.732 166.092 124.644 165.651 123.816 164.877C122.988 164.102 122.479 163.048 122.387 161.92L115.52 62.5806C115.474 62.0429 115.542 61.5016 115.719 60.9916C115.896 60.4816 116.178 60.0142 116.547 59.6193C116.916 59.2245 117.363 58.911 117.861 58.699C118.359 58.487 118.895 58.3813 119.437 58.3885H196.819C197.954 58.41 199.041 58.8506 199.868 59.6251C200.696 60.3996 201.206 61.4527 201.299 62.5806L208.153 161.92C208.199 162.457 208.131 162.999 207.954 163.509C207.778 164.019 207.496 164.486 207.127 164.881C206.758 165.276 206.31 165.589 205.812 165.801C205.315 166.013 204.778 166.119 204.237 166.112H126.867Z" fill="white"/>
<g opacity="0.2">
<path d="M200.006 107.564H129.651L127.008 69.1589H197.357L200.006 107.564ZM130.144 107.04H199.443L196.883 69.6821H127.565L130.144 107.04Z" fill="#7DB9FF"/>
<path d="M197.504 75.093H127.68L127.641 74.5698H197.465L197.504 75.093Z" fill="#7DB9FF"/>
<path d="M197.875 80.5034H128.051L128.013 79.9802H197.843L197.875 80.5034Z" fill="#7DB9FF"/>
<path d="M198.253 85.9207H128.422L128.384 85.3911H198.214L198.253 85.9207Z" fill="#7DB9FF"/>
<path d="M198.624 91.3316H128.8L128.761 90.802H198.585L198.624 91.3316Z" fill="#7DB9FF"/>
<path d="M198.995 96.7422H129.171L129.133 96.219H198.963L198.995 96.7422Z" fill="#7DB9FF"/>
<path d="M199.373 102.153H129.542L129.504 101.63H199.334L199.373 102.153Z" fill="#7DB9FF"/>
<path d="M126.003 72.3875H120.602L120.563 71.8643H125.965L126.003 72.3875Z" fill="#7DB9FF"/>
<path d="M126.374 77.7983H120.973L120.934 77.2751H126.342L126.374 77.7983Z" fill="#7DB9FF"/>
<path d="M126.745 83.209H121.344L121.312 82.6858H126.713L126.745 83.209Z" fill="#7DB9FF"/>
<path d="M127.123 88.626H121.722L121.683 88.0964H127.085L127.123 88.626Z" fill="#7DB9FF"/>
<path d="M127.494 94.0369H122.093L122.054 93.5137H127.462L127.494 94.0369Z" fill="#7DB9FF"/>
<path d="M127.865 99.4475H122.464L122.432 98.9243H127.833L127.865 99.4475Z" fill="#7DB9FF"/>
<path d="M128.243 104.858H122.841L122.803 104.335H128.205L128.243 104.858Z" fill="#7DB9FF"/>
</g>
<path d="M130.048 107.525L129.754 107.072L138.08 102.051L140.659 99.4794L146.342 94.4834L152.224 96.6719L158.182 86.7565L166.406 88.9068L176.96 81.9647L182.24 84.1852L196.083 77.3005L196.346 77.7727L182.291 84.7594L177.043 82.5581L166.528 89.4747L158.458 87.3563L152.474 97.3164L146.483 95.0832L141.03 99.875L138.4 102.491L130.048 107.525Z" fill="#EFEFEF"/>
<path d="M130.099 107.48L129.696 107.123L137.741 98.0053L140.038 92.8116L147.782 97.2588L151.258 88.7918L157.984 82.6282L166.547 87.4391L176.666 77.83L183.885 82.1113L195.578 70.8879L195.955 71.2772L183.987 82.7622L176.768 78.4808L166.656 88.0899L158.086 83.2726L151.667 89.1491L148.064 98.0053L140.301 93.5517L138.176 98.3371L130.099 107.48Z" fill="#7DB9FF"/>
<path d="M151.04 135.089L156.8 115.692C160.901 117.148 164.494 119.752 167.148 123.193C169.802 126.633 171.405 130.764 171.763 135.089C172.16 139.821 170.769 144.529 167.863 148.291C164.958 152.053 160.747 154.597 156.058 155.424L151.04 135.089Z" fill="#EFEFEF"/>
<path d="M151.04 135.089L156.038 155.424C154.857 155.631 153.66 155.736 152.461 155.737C141.107 155.737 131.117 146.415 130.336 135.089C130.127 132.446 130.472 129.788 131.348 127.285C132.224 124.781 133.612 122.486 135.425 120.546C137.237 118.605 139.434 117.061 141.876 116.011C144.319 114.962 146.953 114.429 149.613 114.448C152.056 114.452 154.48 114.873 156.781 115.692L151.04 135.089Z" fill="#7DB9FF"/>
<path d="M185.946 133.386H179.802L181.344 155.737H187.488L185.946 133.386Z" fill="#EFEFEF"/>
<path d="M185.946 133.386H179.802L178.861 119.763H185.005L185.946 133.386Z" fill="#7DB9FF"/>
<path d="M196.678 137.457H190.534L191.795 155.737H197.939L196.678 137.457Z" fill="#EFEFEF"/>
<path d="M196.678 137.457H190.534L189.958 129.079H196.102L196.678 137.457Z" fill="#7DB9FF"/>
<path d="M231.917 65.7644C231.277 68.8909 230.688 74.627 232.883 76.7134C231.105 79.5874 228.456 81.8222 225.318 83.094C221.12 80.4716 223.13 76.7134 223.13 76.7134C226.63 75.8776 226.541 73.2935 225.926 70.8625L231.917 65.7644Z" fill="#FF8B7B"/>
<path d="M234.01 78.5638C236.295 76.9942 236.461 74.0145 236.461 74.0145C236.461 74.0145 228.141 71.8897 223.61 73.1659C222.221 74.225 220.007 76.1966 220.007 76.1966C220.007 76.1966 220.57 77.4727 223.13 78.2767C225.69 79.0806 234.01 78.5638 234.01 78.5638Z" fill="#263238"/>
<path d="M243.68 80.9822C245.242 83.2281 246.643 85.4486 247.974 87.803C249.335 90.1392 250.566 92.5483 251.661 95.0194C251.93 95.6575 252.205 96.2955 252.448 96.9336L252.634 97.4185L252.73 97.661L252.774 97.7822L252.851 98.0055C253.042 98.592 253.122 99.2085 253.088 99.824C253.017 100.778 252.756 101.709 252.32 102.561C251.972 103.265 251.556 103.934 251.078 104.558C249.339 106.772 247.251 108.69 244.896 110.237L243.155 108.208C244.784 106.355 246.234 104.353 247.488 102.229C247.738 101.764 247.952 101.279 248.128 100.781C248.26 100.446 248.317 100.087 248.294 99.7283C248.294 99.6326 248.256 99.5943 248.237 99.607L248.192 99.505L248.09 99.2944L247.898 98.8669C247.642 98.3054 247.347 97.7503 247.072 97.1888C246.505 96.0658 245.901 94.9514 245.261 93.8454C244.621 92.7352 243.981 91.6313 243.341 90.5403C242.003 88.3645 240.608 86.1568 239.226 84.064L243.68 80.9822Z" fill="#FF8B7B"/>
<path d="M244.077 107.659L238.381 107.232L241.53 111.577C241.53 111.577 244.902 111.162 245.696 109.12L244.077 107.659Z" fill="#FF8B7B"/>
<path d="M234.682 109.695L237.523 113.191L242.374 111.411L238.381 107.232L234.682 109.695Z" fill="#FF8B7B"/>
<path d="M241.587 77.8428L237.965 82.7686L238.227 88.8492L244.378 94.4131C244.378 94.4131 247.533 92.1735 250.278 89.9084C246.835 82.0412 243.725 78.4808 241.587 77.8428Z" fill="#7DB9FF"/>
<path opacity="0.2" d="M240.275 82.5134L238.355 88.9323L240.422 90.8018C240.121 86.3099 240.275 82.5134 240.275 82.5134Z" fill="black"/>
<path d="M213.568 81.5946C213.485 85.423 214.022 93.3668 216.717 109.892H238.195C237.837 101.177 237.408 95.7148 241.587 77.8237C238.717 77.241 235.808 76.8699 232.883 76.7135C229.636 76.4835 226.377 76.4835 223.13 76.7135C221.03 76.9049 218.784 77.2431 217.075 77.5366C216.109 77.701 215.23 78.1962 214.59 78.9368C213.949 79.6774 213.588 80.6172 213.568 81.5946Z" fill="#7DB9FF"/>
<path opacity="0.2" d="M217.766 83.0813L213.926 89.2896C214.125 91.4973 214.419 94.1452 214.835 97.3099L217.965 95.4596C219.257 86.0674 217.766 83.0813 217.766 83.0813Z" fill="black"/>
<path d="M218.394 84.7404C217.53 87.7074 216.608 90.6552 215.654 93.5903C214.701 96.5253 213.734 99.4668 212.742 102.389L212.55 102.938L212.243 103.652C212.037 104.091 211.804 104.518 211.546 104.929C211.043 105.718 210.455 106.45 209.792 107.111C208.541 108.347 207.105 109.382 205.536 110.18C202.574 111.66 199.362 112.58 196.064 112.891L195.622 110.256C197.017 109.83 198.383 109.317 199.712 108.719C201.019 108.146 202.27 107.454 203.45 106.651C204.564 105.926 205.567 105.043 206.426 104.029C206.821 103.568 207.16 103.063 207.437 102.523C207.575 102.272 207.695 102.012 207.795 101.745L207.923 101.387L208.077 100.839C208.87 97.8716 209.69 94.9238 210.56 91.9824C211.43 89.0409 212.275 86.1059 213.216 83.1899L218.394 84.7404Z" fill="#FF8B7B"/>
<path d="M219.046 81.8308C218.144 79.6742 215.302 78.2769 215.302 78.2769C212.25 80.2612 210.989 82.3349 208.96 92.4162C212.211 93.871 217.05 93.8582 217.05 93.8582L219.686 87.0757C219.686 87.0757 219.955 83.9875 219.046 81.8308Z" fill="#7DB9FF"/>
<path d="M196.723 109.975L191.386 109.892L194.784 114.046C194.784 114.046 198.125 113.408 198.797 111.347L196.723 109.975Z" fill="#FF8B7B"/>
<path d="M187.84 112.566L190.874 115.89L194.784 114.046L191.386 109.892L187.84 112.566Z" fill="#FF8B7B"/>
<path d="M220.742 59.2434C220.672 59.6581 220.384 59.958 220.102 59.9133C219.821 59.8687 219.667 59.4858 219.744 59.0647C219.821 58.6436 220.102 58.3437 220.384 58.4267C220.666 58.5096 220.8 58.8223 220.742 59.2434Z" fill="#263238"/>
<path d="M220.499 58.4397L219.539 57.9739C219.539 57.9739 219.917 58.848 220.499 58.4397Z" fill="#263238"/>
<path d="M220.563 56.621C220.518 56.6021 220.479 56.5702 220.452 56.5293C220.424 56.4884 220.41 56.4404 220.41 56.3913C220.41 56.3421 220.424 56.2941 220.452 56.2532C220.479 56.2123 220.518 56.1804 220.563 56.1615C220.918 55.9797 221.31 55.8848 221.709 55.8848C222.107 55.8848 222.5 55.9797 222.854 56.1615C222.91 56.1971 222.95 56.2528 222.966 56.3171C222.981 56.3813 222.971 56.4491 222.938 56.5061C222.922 56.5347 222.9 56.5597 222.874 56.5798C222.848 56.5998 222.819 56.6145 222.787 56.6228C222.755 56.6312 222.722 56.633 222.69 56.6283C222.658 56.6236 222.626 56.6124 222.598 56.5954C222.318 56.4516 222.008 56.3766 221.693 56.3766C221.378 56.3766 221.067 56.4516 220.787 56.5954C220.755 56.6179 220.718 56.632 220.678 56.6365C220.639 56.6409 220.6 56.6356 220.563 56.621Z" fill="#263238"/>
<path d="M220.71 60.6152C220.168 61.5057 219.504 62.3173 218.739 63.0271C218.926 63.2172 219.152 63.3642 219.402 63.4579C219.652 63.5516 219.919 63.5897 220.186 63.5694L220.71 60.6152Z" fill="#FF5652"/>
<path d="M235.226 60.0219C233.997 65.1264 233.587 67.2894 230.509 69.4716C225.882 72.7512 219.885 70.1096 219.565 64.7755C219.283 59.9581 221.338 52.4482 226.752 51.2933C227.945 51.0331 229.184 51.0811 230.353 51.4327C231.521 51.7844 232.581 52.428 233.43 53.3026C234.279 54.1771 234.889 55.2535 235.204 56.4295C235.518 57.6056 235.525 58.8422 235.226 60.0219Z" fill="#FF8B7B"/>
<path d="M240.108 62.4288C240.605 58.1193 237.504 54.224 233.182 53.7285C228.859 53.2329 224.952 56.3247 224.455 60.6342C223.958 64.9437 227.059 68.839 231.382 69.3346C235.704 69.8302 239.611 66.7383 240.108 62.4288Z" fill="#263238"/>
<path d="M226.912 60.2453C232.672 56.468 241.504 54.4135 239.584 48.5433C238.035 43.8153 230.95 43.592 225.504 45.8061C218.035 48.8177 221.158 64.0162 226.912 60.2453Z" fill="#263238"/>
<path d="M234.24 51.8613C234.19 51.8616 234.14 51.8496 234.095 51.8262C234.051 51.8028 234.012 51.7689 233.984 51.7273L227.917 43.3113C227.88 43.2419 227.87 43.1615 227.888 43.0851C227.906 43.0087 227.951 42.9414 228.015 42.8955C228.079 42.8496 228.157 42.8284 228.236 42.8356C228.314 42.8429 228.387 42.8781 228.442 42.9349L234.502 51.3572C234.551 51.4252 234.57 51.5094 234.557 51.5917C234.544 51.6739 234.499 51.7478 234.432 51.7975C234.377 51.8402 234.309 51.8628 234.24 51.8613Z" fill="#7DB9FF"/>
<path d="M237.946 53.9858C237.88 53.9855 237.815 53.9655 237.76 53.9284C237.726 53.9042 237.696 53.8735 237.673 53.8379C237.651 53.8023 237.636 53.7626 237.628 53.7211C237.621 53.6795 237.622 53.637 237.632 53.5959C237.641 53.5549 237.659 53.5161 237.683 53.4818L243.744 45.0658C243.793 44.9964 243.868 44.9494 243.952 44.935C244.036 44.9207 244.123 44.9402 244.192 44.9892C244.262 45.0383 244.309 45.1129 244.323 45.1967C244.338 45.2805 244.318 45.3665 244.269 45.4359L238.202 53.8582C238.172 53.8982 238.134 53.9307 238.089 53.9528C238.045 53.975 237.996 53.9864 237.946 53.9858Z" fill="#7DB9FF"/>
<path d="M241.057 62.8527C245.344 60.1966 247.079 55.2506 244.931 51.8057C242.784 48.3608 237.568 47.7214 233.281 50.3776C228.994 53.0338 227.259 57.9797 229.407 61.4246C231.554 64.8695 236.77 65.5089 241.057 62.8527Z" fill="#263238"/>
<path d="M216.717 109.892C216.717 109.892 218.528 149.599 218.88 165.678C219.238 182.407 219.398 221.469 219.398 221.469H223.635C223.635 221.469 230.387 189.005 228.39 165.678C231.411 147.767 233.453 109.892 233.453 109.892H216.717Z" fill="#FF8B7B"/>
<path d="M222.33 109.892C222.33 109.892 232.416 151.474 236.544 167.024C240.877 183.352 246.726 220.257 246.784 221.469H250.445C250.445 221.469 251.597 178.834 245.805 164.338C245.805 146.172 238.221 109.892 238.221 109.892H222.33Z" fill="#FF8B7B"/>
<path d="M248.006 220.448C251.13 219.932 251.123 221.45 251.386 223.084C251.574 224.064 251.428 225.08 250.97 225.968C249.926 227.792 250.97 230.491 247.443 230.491C243.917 230.491 243.571 229.726 244.467 228.092C245.301 226.444 245.867 224.673 246.144 222.848C246.323 221.903 246.618 220.678 248.006 220.448Z" fill="#263238"/>
<path d="M251.194 223.524C251.27 225.63 251.475 230.491 251.475 230.491H250.918C250.918 230.491 250.278 225.31 249.901 223.913L251.194 223.524Z" fill="#263238"/>
<path d="M226.266 61.8275C225.873 62.7581 225.128 63.4965 224.192 63.882C222.912 64.3861 222.17 63.3078 222.394 62.0572C222.592 60.9278 223.488 59.3135 224.8 59.3454C225.086 59.3594 225.364 59.4441 225.61 59.5919C225.855 59.7397 226.059 59.946 226.205 60.1922C226.35 60.4383 226.432 60.7167 226.442 61.0021C226.453 61.2876 226.392 61.5712 226.266 61.8275Z" fill="#FF8B7B"/>
<g opacity="0.2">
<path d="M218.835 163.706C218.835 164.395 218.867 165.052 218.88 165.678C218.938 168.23 218.989 171.376 219.034 174.796L228.736 172.499C228.691 170.17 228.576 167.879 228.39 165.678C228.582 164.523 228.774 163.285 228.966 161.977L218.835 163.706Z" fill="black"/>
<path d="M245.76 164.338C245.76 162.736 245.696 160.994 245.587 159.144L234.944 160.956C235.533 163.24 236.064 165.295 236.525 167.024C236.806 168.083 237.092 169.223 237.382 170.444L246.982 168.173C246.654 166.871 246.246 165.59 245.76 164.338Z" fill="black"/>
</g>
<path d="M216.717 109.892L217.357 173.124L247.437 166.743C247.91 130.661 245.088 118.181 238.208 109.892H216.717Z" fill="#263238"/>
<path opacity="0.2" d="M236.973 125.499C236.973 125.499 231.123 130.348 223.955 131.956C231.424 131.389 235.213 129.296 236.973 125.499Z" fill="black"/>
<path d="M215.904 105.917C215.967 107.278 216.102 108.635 216.307 109.982C216.346 110.365 216.454 113.287 216.576 113.951C216.698 114.614 241.261 114.225 240.774 113.21C240.288 112.196 238.707 110.122 238.707 109.676C238.707 109.229 238.541 105.93 238.336 105.656C238.131 105.381 216.026 104.884 215.904 105.917Z" fill="#7DB9FF"/>
<path opacity="0.2" d="M215.904 105.917C215.967 107.278 216.102 108.635 216.307 109.982C216.346 110.365 216.454 113.287 216.576 113.951C216.698 114.614 241.261 114.225 240.774 113.21C240.288 112.196 238.707 110.122 238.707 109.676C238.707 109.229 238.541 105.93 238.336 105.656C238.131 105.381 216.026 104.884 215.904 105.917Z" fill="white"/>
<path d="M219.379 219.932C218.278 223.09 215.091 228.718 215.091 228.718L221.139 227.052L224.109 222.401L223.469 221.176L219.379 219.932Z" fill="#FF8B7B"/>
<path d="M224.371 220.825C224.629 221.364 224.752 221.957 224.73 222.554C224.691 224.717 224.448 230.466 224.448 230.466H223.942C223.945 228.697 223.855 226.929 223.674 225.17C223.418 223.741 222.797 226.261 221.837 227.678C220.877 229.094 218.995 230.389 215.68 230.466C211.802 230.562 210.234 230.389 211.36 229.451C212.486 228.513 215.04 227.914 215.04 227.914C216.093 227.886 217.12 227.583 218.018 227.036C218.916 226.488 219.655 225.715 220.16 224.794C221.312 222.72 222.72 220.659 223.674 220.174C223.833 220.221 223.979 220.304 224.1 220.417C224.221 220.53 224.314 220.67 224.371 220.825Z" fill="#263238"/>
</svg>
`
export const incompleteBtnIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<mask id="mask0_6206_165335" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
  <rect y="0.714355" width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_6206_165335)">
  <path d="M9.99996 19.0476C8.84718 19.0476 7.76385 18.8289 6.74996 18.3914C5.73607 17.9539 4.85413 17.3566 4.10413 16.5997C3.35413 15.8427 2.76038 14.9573 2.32288 13.9434C1.88538 12.9296 1.66663 11.8532 1.66663 10.7143C1.66663 8.53372 2.38885 6.65872 3.83329 5.08927C5.27774 3.51983 7.05551 2.63094 9.16663 2.42261V4.92261C7.73607 5.11705 6.5451 5.75941 5.59371 6.84969C4.64232 7.93997 4.16663 9.22816 4.16663 10.7143C4.16663 12.3254 4.73607 13.7004 5.87496 14.8393C7.01385 15.9782 8.38885 16.5476 9.99996 16.5476C10.9166 16.5476 11.7743 16.3532 12.5729 15.9643C13.3715 15.5754 14.0416 15.0476 14.5833 14.3809L16.75 15.6309C16 16.6726 15.0347 17.5025 13.8541 18.1205C12.6736 18.7386 11.3888 19.0476 9.99996 19.0476ZM9.16663 14.0476V11.5476H6.66663V9.88094H9.16663V7.38094H10.8333V9.88094H13.3333V11.5476H10.8333V14.0476H9.16663ZM17.625 14.0893L15.4583 12.8393C15.5833 12.5059 15.677 12.1622 15.7395 11.808C15.802 11.4539 15.8333 11.0893 15.8333 10.7143C15.8333 9.22816 15.3576 7.93997 14.4062 6.84969C13.4548 5.75941 12.2638 5.11705 10.8333 4.92261V2.42261C12.9444 2.63094 14.7222 3.51983 16.1666 5.08927C17.6111 6.65872 18.3333 8.53372 18.3333 10.7143C18.3333 11.3254 18.2777 11.9157 18.1666 12.4851C18.0555 13.0546 17.875 13.5893 17.625 14.0893Z" fill="white"/>
</g>
</svg>`

export const editIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="10" fill="#E5F0FF"/>
<circle cx="10" cy="10" r="1" fill="#0D2C54"/>
<circle cx="10" cy="14" r="1" fill="#0D2C54"/>
<circle cx="10" cy="6" r="1" fill="#0D2C54"/>
</svg>
`