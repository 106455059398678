/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * ManufacturingCostPagePlantView  ===> custom view component.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api

**/

import React from "react";
import serviceProvider from '../../services/axios';
import { Helmet } from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useEffect, useState } from 'react';
import { useHistory, Link, Redirect } from "react-router-dom";
import { ManufacturingCostPagePlantView } from "./manufacturing-cost-page-plant-view";
//ManufacturingCostPagePlantController starts
export const ManufacturingCostPagePlantController = (props) => {
  //defining varibales here
  const segments = window.location.pathname.slice(1).split("/");
  const [unlockbutton, setUnlockbutton] = useState(false);
  const slug = segments[2];
  const sid = segments[3];
  const ssid=segments[4];
  const [results, setResults] = useState([]);
  const [refadd, setRefadd] = useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
 const group_id = user ? user.group_id : 0;
  useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setResults(data);
      // setRefadd(false);
		};
		search();
	}, [unlockbutton]);
  

	if (results.length) {
		if(results[0].block == 1){
			var pp = "/not-found";
			return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		if(results[0].unassign == 1){
			
			return <Redirect to="/user-unassign" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		
		if(results[0].is_locked == 1){
			if(group_id == 14){
				return <Redirect to={`/lossanalysis/section/${slug}`} />;
			}else{
				return <Redirect to={`/loss-analysis-admin/section/${slug}`} />;
				}
			return (
					<>
						<div></div>
					</>
			);
		}
		if(window.location.hash != '' && (results[0].isLocks == 1 || results[0].has_businesscase == 1)){
			var pp = "/not-found";
			return <Redirect to="/not-found" />;
			return (
				<>
					<div></div>
				</>
			);
		}
		else{
			return(
				<>
					{/* Page Title */}
					<Helmet>
						<title>Manufacturing Cost | SmarterChains</title>
						<meta name="description" content="Loss Analysis" />
					</Helmet>
					<ManufacturingCostPagePlantView setRefadd={setRefadd}  slug={slug} quesResult={results} unlockbutton={unlockbutton} setUnlockbutton={setUnlockbutton} {...props} />
				</>
			);
			
		}	
	}
	else{
		return (
			<>
				<div></div>
			</>
		);
		
	}

} 




