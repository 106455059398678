import React, { useState, useEffect, useRef, useMemo } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Tabs, MenuItem, Popover } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import serviceProvider from '../../services/axios';
import { LossResultSetupPopCtrl } from '../LossResultSetup/LossResultSetupPopCtrl'
import { Helmet } from "react-helmet";
import {
    PowerBiWrapper,
    PowerBiHeader,
    PowerBiHeaderBox,
    PowerBiContents,
    PwbCardRow,
    PwbCard,
    PwbLeft,
    PwbLeftRow,
    PwbMid,
    PwbMidCard,
    PwbRow,
    PwbRight,
    PwbRightCard,
    ToolTipLossCat,
    ChartDivWrapper,
    ChartDiv,
    EndMid,
    ChartHeading,
    BottomTabWrapper,
    TabsSection,
    TabAddBtn,
    IncompleteSetupWrapper,
    IncompleteSetupCard,
    DropDownWrap,
    DeletePopWrap,
    DeletePopCard,
    DeletePopFooter
} from './powerbi-styles'
import * as d3 from "d3";
import { TabAddBtnIcon, incompleteBtnIcon, incompleteSvg, editIcon } from './svg';
import { DragableBtn } from './DragableBtn';
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export const LossPoweBiResultView = (props) => {
    const dataResults = props.dataResults[0];
    const headdata = dataResults?.generic_data[0];
    const bycatdata = dataResults?.loss_categary_data;
    const bysubcatdata = dataResults?.sub_loss_categary_data;
    const bylosstypedata = dataResults?.loss_type_data;
    const bylineedata = dataResults?.bylineedata;
    const all_lines = dataResults?.all_lines;
    const lossareafilter = dataResults?.lossareafilter;
    const loss_cate_filter = dataResults?.loss_cate_filter;
    const total_loss = dataResults?.total_loss;
    const pid = dataResults?.pid;
    const plant_name = dataResults?.plant_name;
    const slug = dataResults?.slug;
    const [data, setData] = useState(bycatdata);
    const [lossCatData, setLossCatData] = useState(bysubcatdata);
    const [donutData, setDonutData] = useState(bylosstypedata);
    const [line_data, setline_data] = useState(bylineedata);
    const [donutVal, setDonutVal] = useState(0);
    const [donutDataType, setdonutDataType] = useState(false);
    const [donutDim, setDonutDim] = useState('');
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [allChartLabels, setAllChartLabels] = useState(
        {
            loss_cat_chart: dataResults?.allChartLabels,
            loss_sub_cat_chart: dataResults?.allChartLabels,
            loss_type_chart: dataResults?.allChartLabels,
        }
    );
    const tabFilter = useRef({});
    const [nullPage, setNullPage] = useState(false);
    let chartDiv = useRef();

    const headerBox = [
        {
            p: 'Plant Name',
            h2: headdata.Plant_Name
        },
        {
            p: 'Production Volume',
            h2: headdata.Production_Volume
        },
        {
            p: 'Employees',
            h2: headdata.Employees
        },
        {
            p: 'Conversion Cost incl. Waste',
            h2: headdata.costOfConversion
        },
        {
            p: 'SKUs',
            h2: headdata.SKUs
        },
        {
            p: 'OEE',
            h2: headdata.OEE + '%'
        },
        {
            p: 'Digital Maturity Index',
            h2: headdata.Digital_Maturity_Index + '%'
        },
    ];

    // tabs start here
    const defultActiveScenario = (props.scidfilter || '1');

    const [scenarioArr, setScenarioArr] = useState(dataResults?.seclist)

    const [appliedFilter, setAppliedFilter] = useState({
        lossareafilter: null,
        productionProcessFilter: null,
        lossCategoryFilter: []
    })
    const [tabsValue, setTabsValue] = React.useState(defultActiveScenario);
    // Scenario Popup Variables
    const [isSelectScenarioPopOpen, setIsSelectScenarioPopOpen] = useState(false);
    const [reviewScenario, setReviewScenario] = useState(dataResults?.seclist_default[0]);
    // Rename/Delete Popup
    const [deletePop, setDeletePop] = useState(false);
    const [renameScenario, setRenameScenario] = useState(false);
    const [activeEditBtn, setActiveEditBtn] = useState(false);
    const [totalFilter, setTotalFilter] = useState(false);

    function extractNumbers(inputString) {
        return inputString.replace(/\D/g, "");
    };

    const handleResetDefaultState = () => {
        setDonutVal(0);
        setDonutDim('');
        setdonutDataType(false);
    };
    const handleClick = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setActiveEditBtn(item);
        let dropdown = document.querySelector(`#dropdownwrap-${activeEditBtn.id}`);
        dropdown.style.left = event.clientX + `px`;
    };
    const handleDeletePop = (item) => {
        setActiveEditBtn(false);
        setDeletePop(item);
    }
    const handleDeleteFunction = () => {
        let tmpIndex = 0;
        let tmpFilterArr = scenarioArr.filter((i, index) => {
            if (i.id != deletePop.id) {
                tmpIndex = index;
            }
            return i.id != deletePop.id
        });
        setScenarioArr(tmpFilterArr);

        serviceProvider.post("/businesscaseplantloss?type=deletescenarioloss", JSON.stringify({ scid: deletePop.id })).then((response) => { });


        setTimeout(() => {
            let tmpTab = document.querySelector(`[data-component="TabsSection"] button`);
            if (tmpTab) {
                tmpTab.click();
            }
            setDeletePop(false);
        }, 100)
    }
    const handleRenameFunction = (e, type) => {
        if (e.keyCode === 13 || type === `blur`) {
            let tmpScenario = scenarioArr.map(i => {
                if (i.id === renameScenario.id) {
                    return {
                        name: renameScenario.name, id: i.id, status: i.status
                    }
                }
                else {
                    return i;
                }
            })
            setScenarioArr(tmpScenario);
            serviceProvider.post("/businesscaseplantloss?type=editscenarioloss", JSON.stringify({ scid: renameScenario.id, name: renameScenario.name })).then((response) => { });
            setRenameScenario(false);
        }
    }
    // end

    const handleChange = (event, newValue) => {
        setTabsValue(newValue);
    };
    // tabs end here
    const filter = {
        lossareafilter: lossareafilter,
        productionProcessFilter: all_lines,
        lossCategoryFilter: loss_cate_filter,
    };

    const deepCopyObj = (obj) => {
        let tmpObj = JSON.stringify(obj);
        return JSON.parse(tmpObj);
    }

    const handleFilter = (key, value) => {
        let tmpObj = deepCopyObj(appliedFilter);
        if (key == 'lossCategoryFilter') {
            let isValueExist = tmpObj?.lossCategoryFilter.includes(value);
            let tmpArr = [...tmpObj.lossCategoryFilter];
            if (isValueExist) {
                tmpArr.splice(tmpArr.indexOf(value), 1);
            } else {
                tmpArr.push(value);
            }
            tmpObj.lossCategoryFilter = tmpArr;
            props.setLossFilter(tmpArr.join(","));
            props.setRunfilter(!props.runfilter);

        } else if (key == 'productionProcessFilter') {
            if (tmpObj[key] === value) {
                tmpObj[key] = 0;
            } else {
                tmpObj[key] = value;
            }
            props.setLineFilter(tmpObj[key]);
            props.setRunfilter(!props.runfilter);
        } else {
            if (tmpObj[key] === value) {
                tmpObj[key] = 0;
            } else {
                tmpObj[key] = value;
            }
            props.setFactoryLineFilter(tmpObj[key]);
            props.setRunfilter(!props.runfilter);

        }
        setAppliedFilter(tmpObj);
        handleResetDefaultState();
    }

    const handleReviewFunction = () => {
        setIsSelectScenarioPopOpen('review');
    }

    function formatNumber(num, max_value = 1) {
        let val = num;
        let status = false;
        if (max_value > 999999) {
            val = (num / 1000000).toFixed(1) + 'M';
            status = true;
        } else if (max_value > 999) {
            val = (num / 1000).toFixed(1) + 'K';
            status = true;
        } else {
            val = parseFloat(num).toFixed(1);
        }
        return {
            status,
            val
        };
    }

    const handleScreen = (item) => {
        let tmpFilterObj = deepCopyObj(appliedFilter);
        tabFilter.current = { ...tabFilter.current, [tabsValue]: tmpFilterObj };
        let tmpSetApplyFilter = tabFilter.current[item.id] || { lossareafilter: null, productionProcessFilter: null, lossCategoryFilter: [] }
        let tmpLossCategoryFilter = tmpSetApplyFilter?.lossCategoryFilter.join(",") || [];
        let tmpLineFilter = tmpSetApplyFilter.lossareafilter || 0;
        let tmpProductionProcessFilter = tmpSetApplyFilter.productionProcessFilter || 0;

        if (item.status == '0') {
            setNullPage(item);
            setReviewScenario(false);
        } else {
            setReviewScenario(item);
            setNullPage(false);
            props.setScidfilter(item.id);
            setAppliedFilter(prv => tmpSetApplyFilter);
            props.setFactoryLineFilter(tmpLineFilter);
            props.setLineFilter(tmpProductionProcessFilter);
            props.setLossFilter(tmpLossCategoryFilter);
            props.setRunfilter(!props.runfilter);
        }
        handleResetDefaultState();
    }
    // chart function start here
    function drawBarChart(data, dim, percentValue, NumberValue, selfCall) {
        // console.warn(dim, value);
        let tooltip = document.querySelector('#tooltip');
        let percent_val_total_loss = tooltip?.querySelector('.percent_total_loss');
        let percent_total = tooltip?.querySelector('.percent_total');
        let spend_bucket = tooltip?.querySelector('.spend_bucket');
        let annual_loss = tooltip?.querySelector('.annual_loss');
        let loss_cat = tooltip?.querySelector('.loss_cat');
        let highlighted_row = tooltip?.querySelector('.highlighted-row');
        let highlighted = highlighted_row?.querySelector('.highlighted');
        let DeviceWidth = window.innerWidth;
        let DeviceHeight = window.innerHeight;
        const colors = {
            green: '#69b59f'
        }

        const margin = {
            left: 60,
            right: 10,
            top: 40,
            bottom: 50,
        };

        const width = chartDiv.current.offsetWidth;
        const height = chartDiv.current.offsetHeight;
        const graphAreaH = height - (margin.top + margin.bottom);
        const graphAreaW = width - (margin.left + margin.right);
        let barGroupPadding = 10;
        let barPadding = DeviceWidth > 1200 ? 20 : 10;
        let barW = (graphAreaW / data.length) - barPadding;
        if (barW > 100) {
            barW = 100
        }
        let valueArr = data.map(i => i.value);
        let maxValue = Math.max(...valueArr.map(i => Number(i)))
        let value = percentValue ? percentValue : NumberValue ? 100 * NumberValue / maxValue : 0;
        let totalY = 3;
        let foreignObjectW = barW + (barPadding / 2);
        let foreignObjectH = margin.bottom - 5;
        let hightligtedTmpVal = 0;
        let y_axis_Arr = () => {
            let tmpArr = [];
            let loopStart = maxValue || 1;
            let addCont = (maxValue || 1) / totalY;
            for (let i = loopStart; i >= 0; i -= addCont) {
                tmpArr?.push(`${i}`);
            }
            tmpArr?.push(0);

            return tmpArr;
        }

        const getHeight = (value, g_height) => {
            let final_per = (value * 100 / (maxValue || 1));
            let bar_h = g_height * final_per / 100;
            return bar_h;
        }
        // function declaration
        function handleTooltipShow(e, item, isClose = false) {
            let pos_X = e?.clientX;
            let pos_Y = e?.clientY;
            if (isClose && tooltip) {
                tooltip.classList.remove('show');
            }
            else if (tooltip) {
                tooltip.classList.add('show');
                let tooltipWidth = tooltip.offsetWidth;
                let tooltipHeight = tooltip.scrollHeight;
                let windowHeight = window.innerHeight;
                let windowWidth = window.innerWidth;
                /* Position the tooltip */
                if (windowWidth >= (tooltipWidth + pos_X)) {
                    tooltip.style.left = pos_X + 'px';
                    tooltip.classList.remove('right');
                    tooltip.classList.add('left');
                } else {
                    tooltip.style.left = `${pos_X - tooltipWidth}px`;
                    tooltip.classList.remove('left');
                    tooltip.classList.add('right');
                }
                if (windowHeight >= (tooltipHeight + pos_Y)) {
                    tooltip.style.top = pos_Y + 'px';
                    tooltip.classList.remove('bottom');
                    tooltip.classList.add('top');
                } else {
                    tooltip.style.top = `${pos_Y - tooltipHeight}px`;
                    tooltip.classList.remove('top');
                    tooltip.classList.add('bottom');
                }
                if (percent_val_total_loss) percent_val_total_loss.innerHTML = item.percent_val;
                if (spend_bucket) spend_bucket.innerHTML = item.spend_bucket;
                if (annual_loss) annual_loss.innerHTML = item.annual_loss;
                if (loss_cat) loss_cat.innerHTML = item.section_name;
                if (percent_total) percent_total.innerHTML = item.annual_loss;
                if (dim == item.section_name && selfCall) {
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = item.annual_loss;
                }
                else if (dim == item.section_name && !selfCall) {
                    let per = hightligtedTmpVal * 100 / extractNumbers(item.annual_loss);
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = 'SR ' + Math.round(extractNumbers(item.annual_loss) * per / 100).toLocaleString();
                }
                else if (item.spend_bucket == dim) {
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = item.annual_loss;
                }
                else {
                    highlighted_row.style.display = 'none';
                }
            }
        }

        let graphArea = d3.select(chartDiv.current)
            .html('')
            .append('svg')
            .attr('viewBox', `0 0 ${width} ${height}`)
            .attr('height', height)
            .attr('width', width)
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);
        // ---------------xAxis---------
        let xAxis = graphArea.append('g').attr('class', 'x_axis');

        xAxis.append('line')
            .attr('x1', '0')
            .attr('y1', graphAreaH)
            .attr('x2', graphAreaW)
            .attr('y2', graphAreaH)
            .attr("stroke-dasharray", "3,3")
            .style('stroke', '#D4DCF5');
        // ---------------Yaxis---------

        let yAxis = graphArea.append('g').attr('class', 'y_axis');
        yAxis.append('line')
            .attr('x1', '6')
            .attr('y1', '0')
            .attr('x2', '6')
            .attr('y2', graphAreaH)
            .style('stroke', 'transparent');

        /*creating tickes*/
        let tickes = graphArea.append('g')

        // create lines. 
        tickes.attr('class', 'tickes_group')
            .selectAll('.big-tickes_group')
            .data(y_axis_Arr)
            .enter()
            .append('line')
            .attr('class', (d, i) => `big-tickes tick-${i}`)
            .attr('x1', '0')
            .attr('y1', d => getHeight(d, graphAreaH))
            .attr('x2', graphAreaW)
            .attr('y2', d => getHeight(d, graphAreaH))
            .attr("stroke-dasharray", "3,3")
            .style('stroke', '#F0F0F0')
            .attr('class', (d, i) => {
                let returnClass = d == 30 ? 'stroke-dasharray' : ''
                return returnClass;
            });

        // yAxis text
        tickes.append('g')
            .attr('transform', `translate(0,0)`)
            .selectAll('text')
            .data(y_axis_Arr)
            .enter()
            .append('text')
            .attr('x', '0')
            .attr('y', (d) => graphAreaH - getHeight(d, graphAreaH))
            .attr('text-anchor', 'end')
            .attr('alignment-baseline', 'middle')
            .attr('color', '#0D2C54')
            .attr('class', (d, i) => {
                return `y-axis-percent-txt t-${i}`
            })
            .text((d) => {
                return `SR ${formatNumber(d, maxValue).val}`
            });



        /* ----------------------------------- create bars ----------------------------------- */
        /*function to carete "d" attribute value of path for bar*/
        let drawBar = (index = 0, barWidth = 0, value = 0, radius = 0, g_height = 0) => {
            let bar_h = getHeight(value, g_height);
            return `
            M0,${g_height - bar_h}
            a${radius},${radius} 0 0 1 ${radius},${-radius}
            h${barWidth - 2 * radius}
            a${radius},${radius} 0 0 1 ${radius},${radius}
            v${bar_h}
            h${-barWidth}Z
        `}

        let barsGroupWrapper = graphArea.append('g')
            .attr('class', 'bar-group-wrapper')
            .attr('transform', (d, i) => `translate(${barGroupPadding},0)`);

        let barGroup = barsGroupWrapper.selectAll('.bar-group')
            .data(data).enter();

        let barGroup_g = barGroup.append('g');
        let percentText = barGroup_g.append('switch');
        let sectionText = barGroup_g.append('switch');
        // create bars						
        barGroup_g
            .attr('transform', (d, i) => `translate(${(barW + barPadding) * i},0)`)
            .append('path')
            .on('click', (e, d) => {
                if (d.value !== 'NA') {
                    highlighted_row.style.display = 'none';
                    let filteredBars = document.querySelectorAll('.filtered-bars');
                    let tmpRemoveClass = d?.section_name?.split(' ').join('');
                    let removeClassTargets = document.querySelectorAll(`.${tmpRemoveClass}`);
                    let filterGroups = document.querySelectorAll('svg .filter-group');
                    let isAcitveCalss = e.currentTarget.classList.contains('active');
                    let donutLayer = document.querySelector('.bar_wrapper_g.dim');
                    let stackChartLabel = document.querySelectorAll('.section-val.without-dim');
                    let filter_path = document.querySelectorAll('.filter-path');
                    filter_path?.forEach(i => i.style.opacity = 0);
                    stackChartLabel?.forEach(i => i.style.opacity = 1);
                    if (donutLayer) {
                        donutLayer.style.display = `none`;
                    }
                    if (isAcitveCalss) {
                        e.currentTarget.classList.remove('active');
                        filterGroups?.forEach(i => i.classList.remove('inactive'));
                        handleResetDefaultState();
                        // self call the function to re-create the  chart in default state.
                        drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data);
                        drawBarChart(props.dataResults[0]?.loss_categary_data)
                        drawBarStackChart(props.dataResults[0]?.bylineedata);
                        filteredBars?.forEach(i => {
                            i.style.display = 'none'
                        })
                        setTotalFilter(false);
                    } else {
                        e.currentTarget.classList.add('active');
                        filterGroups?.forEach(i => i.classList.add('inactive'));
                        drawBarChart(props.dataResults[0]?.loss_categary_data, d?.section_name, false, d.value, true);
                        drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data, d?.section_name, d.value);
                        drawBarStackChart(props.dataResults[0]?.bylineedata, d?.section_name, false);
                        setDonutDim(prv => d?.spend_bucket);
                        setDonutVal(prv => d.value);
                        setdonutDataType(prv => 'value')
                        filteredBars?.forEach(i => {
                            i.style.display = 'none'
                        })
                        setTotalFilter(d.value);
                    }
                    removeClassTargets?.forEach(i => i.classList.remove('inactive'));
                }
            })
            .on('mousemove', function (event, d) { handleTooltipShow(event, d) })
            .on('mouseleave', function (event, d) { handleTooltipShow(event, d, true) })
            .attr("d", (d, index) => drawBar(index, barW, d.value == 'NA' ? 0 : d.value, 0, graphAreaH))
            .attr("fill", (d, index) => { return d.color })
            .attr('class', d => `ract-bars ${d.value}-value filter-group ${d?.section_name == dim && selfCall ? 'active' : ''} ${dim && d?.spend_bucket == dim ? '' : dim && d?.section_name == dim && selfCall ? '' : dim ? 'inactive' : ''} ${d?.section_name?.split(' ').join('')} ${d?.spend_bucket?.split(' ').join('')}`)
        // create bars

        if (dim && !selfCall) {
            let val = data?.reduce((a, c) => {
                return (c?.section_name == dim || c?.spend_bucket == dim) ? a + Number(c.value) : a + 0;
            }, 0);
            if (percentValue) {
                hightligtedTmpVal = val * percentValue / 100;
                setTotalFilter(hightligtedTmpVal);
            } else {
                hightligtedTmpVal = NumberValue;
                setTotalFilter(NumberValue);
            }

            barGroup_g
                .attr('transform', (d, i) => `translate(${(barW + barPadding) * i},0)`)
                .append('path')
                .style('pointer-events', 'none')
                .attr("d", (d, index) => drawBar(index, barW, d.value == 'NA' ? 0 : (d.value * value / 100), 0, graphAreaH))
                .attr("fill", (d, index) => { return d?.section_name == dim ? d.color : 'transparent' })
                .attr('class', d => `filtered-bars ract-bars ${d.value}-value}`);
        } else {
            setTotalFilter(false);
        }

        // create bars
        percentText.append('foreignObject')
            .attr('transform', (d, i) => `translate(-${foreignObjectW / 2},${(graphAreaH - getHeight(d.value == 'NA' ? 0 : d.value, graphAreaH)) - 25})`)
            .attr('x', barW / 2)
            .attr('y', 0)
            .attr('width', foreignObjectW)
            .attr('height', 50)
            .append('xhtml:span')
            .attr('class', 'percent-text')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .on('click', (e, d) => {
                if (d.value !== 'NA') {
                    highlighted_row.style.display = 'none';
                    let filteredBars = document.querySelectorAll('.filtered-bars');
                    let tmpRemoveClass = d?.section_name?.split(' ').join('');
                    let removeClassTargets = document.querySelectorAll(`.${tmpRemoveClass}`);
                    let filterGroups = document.querySelectorAll('svg .filter-group');
                    let isAcitveCalss = e.currentTarget.classList.contains('active');
                    let donutLayer = document.querySelector('.bar_wrapper_g.dim');
                    let stackChartLabel = document.querySelectorAll('.section-val.without-dim');
                    let filter_path = document.querySelectorAll('.filter-path');
                    filter_path?.forEach(i => i.style.opacity = 0);
                    stackChartLabel?.forEach(i => i.style.opacity = 1);
                    if (donutLayer) {
                        donutLayer.style.display = `none`;
                    }
                    if (isAcitveCalss) {
                        e.currentTarget.classList.remove('active');
                        filterGroups?.forEach(i => i.classList.remove('inactive'));
                        handleResetDefaultState();
                        drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data);
                        filteredBars?.forEach(i => {
                            i.style.display = 'none'
                        })
                        setTotalFilter(false);
                    } else {
                        e.currentTarget.classList.add('active');
                        filterGroups?.forEach(i => i.classList.add('inactive'));
                        drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data, d?.section_name, d.value);
                        setDonutDim(prv => d?.spend_bucket);
                        setDonutVal(prv => d.value);
                        setdonutDataType(prv => 'value')
                        filteredBars?.forEach(i => {
                            i.style.display = 'none'
                        })
                        setTotalFilter(d.value);
                    }
                    removeClassTargets?.forEach(i => i.classList.remove('inactive'));
                }
            })
            .on('mousemove', function (event, d) { handleTooltipShow(event, d) })
            .on('mouseleave', function (event, d) { handleTooltipShow(event, d, true) })
            .text(d => {
                return d.value == 'NA' ? d.value : `SR ${formatNumber(d.value, maxValue).val}`
            });
        sectionText.append('foreignObject')
            .attr('transform', (d, i) => `translate(-${foreignObjectW / 2},${graphAreaH})`)
            .attr('x', barW / 2)
            .attr('y', 0)
            .attr('width', foreignObjectW)
            .attr('height', foreignObjectH)
            .append('xhtml:div')
            .attr('title', d => `${d.section_name}`)
            .attr('class', 'section-text-div')
            .selectAll('.section-text-div')
            .data(d => d.section_name.split(' '))
            .enter()
            .append('xhtml:span')
            .attr('class', 'section-text')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .text(d => `${d}`);
    }

    // function to draw loss category chart   
    function drawLossCatChart(data, dim, value, percentValue, selfCall) {
        let tooltip = document.querySelector('#lossCatTooltip');
        let highlighted_row = tooltip?.querySelector('.highlighted-row');
        let highlighted = highlighted_row?.querySelector('.highlighted');
        let sub_loss_cat = tooltip?.querySelector('.sub_loss_cat');
        let spend_bucket = tooltip?.querySelector('.spend_bucket');
        let annual_loss = tooltip?.querySelector('.annual_loss');
        let loss_cat = tooltip?.querySelector('.loss_cat');
        // function declaration
        function handleTooltipShow(e, item, isClose = false) {
            let pos_X = e?.clientX;
            let pos_Y = e?.clientY;
            if (isClose && tooltip) {
                tooltip.classList.remove('show');
            }
            else if (tooltip) {
                tooltip.classList.add('show');
                let tooltipWidth = tooltip.offsetWidth;
                let tooltipHeight = tooltip.scrollHeight;
                let windowHeight = window.innerHeight;
                let windowWidth = window.innerWidth;
                /* Position the tooltip */
                if (windowWidth >= (tooltipWidth + pos_X)) {
                    tooltip.style.left = pos_X + 'px';
                    tooltip.classList.remove('right');
                    tooltip.classList.add('left');
                } else {
                    tooltip.style.left = `${pos_X - tooltipWidth}px`;
                    tooltip.classList.remove('left');
                    tooltip.classList.add('right');
                }
                if (windowHeight >= (tooltipHeight + pos_Y)) {
                    tooltip.style.top = pos_Y + 'px';
                    tooltip.classList.remove('bottom');
                    tooltip.classList.add('top');
                } else {
                    tooltip.style.top = `${pos_Y - tooltipHeight}px`;
                    tooltip.classList.remove('top');
                    tooltip.classList.add('bottom');
                }

                if (dim == item.loss_cat && percentValue) {
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = 'SR ' + Math.round(extractNumbers(item.annual_loss) * value / 100).toLocaleString();
                }
                else if (item.spend_bucket == dim || dim == item.loss_cat || (dim && selfCall && dim === item.title)) {
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = item.annual_loss;
                }
                else {
                    highlighted_row.style.display = 'none';
                }
                if (sub_loss_cat) sub_loss_cat.innerHTML = item.title;
                if (sub_loss_cat) spend_bucket.innerHTML = item.spend_bucket;
                if (annual_loss) annual_loss.innerHTML = item.annual_loss;
                if (loss_cat) loss_cat.innerHTML = item.loss_cat;
            }
        }
        // calculation variables 
        let maxValue = Math.max(...data?.map(i => i.value));
        let minValue = Math.min(...data?.map(i => i.value));
        let y_axis_total_lines = 5;
        let bottomTextWidth = 60;
        let bottomTextHeight = 100;
        const startValue = 0.0;
        const increment = (maxValue + 0.5) / y_axis_total_lines;
        const numberOfElements = Math.ceil((maxValue + 0.5 - startValue) / increment) + 1;
        // Create an array with the specified number of elements, starting from 0
        const valuesArray = Array.from({ length: numberOfElements }, (_, index) => (startValue + increment * index).toFixed(1));
        let container_target = `#lossSubChart`;
        let container = document.querySelector(container_target);
        let width = container.scrollWidth;
        let bar_height = 15;
        let margin = {
            left: 250,
            right: 50,
            top: 20,
            bottom: 30,
        }
        let gap = 5;
        let height = (data?.length * (bar_height + gap) + margin.top) > container.offsetHeight ? (data?.length * (bar_height + gap) + margin.top) : container.offsetHeight;
        let g_area_width = width - (margin.left + margin.right);
        let getwidth = (g_area_width, bar_h, value) => {
            let final_per = (value * 100 / (maxValue + 0.5));
            let bar_w = (g_area_width * final_per) / 100;
            return bar_w;
        }

        function drawBarsPath(index, g_area_width, bar_h, value) {
            let bar_w = getwidth(g_area_width, bar_h, value);
            return `
            M 0 ${(bar_h + gap) * index}
            h ${bar_w}
            v ${bar_height}
            h ${-bar_w} Z
            `
        }
        // d
        // to remove previous chart
        // create SVG element in target div and set its attributes
        let svg = d3.select(container_target).html('').append('svg')
            // .attr('viewBox', `0 0 ${width} ${height}`)
            .attr('width', width).attr('height', height + margin.bottom);
        let main_g = svg.append('g');
        let y_axis_g = main_g.append('g').attr('transform', `translate(${margin.left}, ${margin.top / 2})`)
            .attr('class', 'y-axis_line')
            .selectAll('line')
            .data(valuesArray)
            .enter();
        y_axis_g
            .append('line')
            .attr('x1', (d, index) => (g_area_width / y_axis_total_lines * index))
            .attr('y1', 0)
            .attr('x2', (d, index) => (g_area_width / y_axis_total_lines * index))
            .attr('y2', height)
            .attr('stroke-width', 0.5)
            .attr('stroke', '#C8C6C4')
            .attr('stroke-dasharray', 5);
        // bars js
        let bar_g =
            main_g.append('g')
                .attr('class', 'bar_wrapper_g')
                .attr('transform', `translate(${margin.left}, ${margin.top / margin.top})`)
                .selectAll(`path`)
                .data(data)
                .enter()
                .append('g');

        bar_g
            .append('path')
            .attr('class', (d, ind) => `filter-group loss-sub loss-sub-${ind} ${dim === d.title ? 'active': ''} ${dim && (d?.spend_bucket == dim || (d?.loss_cat == dim && !percentValue)) ? '' : (dim !== d.title && selfCall && dim || dim && !selfCall) ? 'inactive' : ''} ${d?.loss_cat?.split(' ').join('')} ${d?.spend_bucket?.split(' ').join('')}`)
            .attr('fill', (d, index) => d.color)
            .attr('data-index', (d, i) => i)
            .on('mousemove', function (event, d) { handleTooltipShow(event, d) })
            .on('mouseleave', function (event, d) { handleTooltipShow(event, d, true) })
            .on('click', (e, d) => {
                if (d.value !== 'NA') {
                    // console.warn('Annual Losses by Sub Loss Category', d)
                    let tmpIndex = e.currentTarget.dataset.index;
                    highlighted_row.style.display = 'none';
                    if (d.value !== 'NA') {
                        let tmpRemoveClass = d?.loss_cat?.split(' ').join('');
                        let removeClassTargets = document.querySelectorAll(`.${tmpRemoveClass}`);
                        let filterGroups = document.querySelectorAll('svg .filter-group');
                        let isAcitveCalss = e.currentTarget.classList.contains('active');
                        let filter_path = document.querySelectorAll('.filter-path');
                        filter_path?.forEach(i => i.style.opacity = 0);
                        if (isAcitveCalss) {
                            e.currentTarget.classList.remove('active');
                            filterGroups?.forEach(i => i.classList.remove('inactive'));
                            drawBarChart(props.dataResults[0]?.loss_categary_data);
                            drawBarStackChart(props.dataResults[0]?.bylineedata);
                            drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data);
                            handleResetDefaultState();
                        } else {
                            e.currentTarget.classList.add('active');
                            filterGroups?.forEach(i => i.classList.add('inactive'));
                            drawBarChart(props.dataResults[0]?.loss_categary_data, d?.loss_cat, false, d.value);
                            drawBarStackChart(props.dataResults[0]?.bylineedata, d?.loss_cat, d.line_data_all);
                            drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data,d.title,false,false,true);
                            setDonutDim(prv => d.spend_bucket);
                            setDonutVal(prv => d.value);
                            setdonutDataType(prv => 'value')
                        }
                        removeClassTargets?.forEach(i => {
                            if (!i.classList.contains(`loss-sub`)) {
                                i.classList.remove('inactive')
                            } else if (i.classList.contains(`loss-sub-${tmpIndex}`)) {
                                i.classList.remove('inactive');
                            }

                        });
                    }
                }
            })
            .attr('d', (d, index) => drawBarsPath(index, g_area_width, bar_height, d.value));

        if (dim && percentValue) {
            bar_g
                .append('path')
                .attr('class', 'filter-path')
                .style('pointer-events', 'none')
                .attr('d', (d, index) => drawBarsPath(index, g_area_width, bar_height, d.value * value / 100))
                .attr("fill", (d, index) => { return d?.loss_cat == dim ? d.color : 'transparent' });
        }

        bar_g.append('switch')
            .append('foreignObject')
            .attr('x', -margin.left)
            .attr('y', (d, index) => (bar_height + gap) * index)
            .attr('width', margin.left)
            .attr('height', bar_height)
            .append('xhtml:div')
            .attr('class', 'section-text')
            .append('xhtml:span')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .attr('title', (d, index) => `${d.title}`)
            .text((d, index) => `${d.title}`);

        bar_g.append('switch')
            .append('foreignObject')
            .style('pointer-events', d => (d.value < maxValue / 5) ? 'all' : 'none')
            .attr('x', d => (d.value < maxValue / 5) ? getwidth(g_area_width, bar_height, d.value) : '0')
            .attr('y', (d, index) => (bar_height + gap) * index)
            .attr('width', d => (d.value < maxValue / 5) ? `100px` : getwidth(g_area_width, bar_height, d.value))
            .attr('height', bar_height)
            .append('xhtml:div')
            .attr('class', d => (d.value < maxValue / 5) ? 'section-val black-color' : 'section-val')
            .append('xhtml:span')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .attr('class', (d, ind) => `filter-group loss-sub loss-sub-${ind} ${dim === d.title ? 'active': ''} ${dim !== d.title && selfCall ? 'inactive': ''} ${d?.loss_cat?.split(' ').join('')} ${d?.spend_bucket?.split(' ').join('')}`)
            .on('click', (e, d) => {
                if (d.value !== 'NA') {
                    // console.warn('Annual Losses by Sub Loss Category', d)
                    let tmpIndex = e.currentTarget.dataset.index;
                    highlighted_row.style.display = 'none';
                    if (d.value !== 'NA') {
                        let tmpRemoveClass = d?.loss_cat?.split(' ').join('');
                        let removeClassTargets = document.querySelectorAll(`.${tmpRemoveClass}`);
                        let filterGroups = document.querySelectorAll('svg .filter-group');
                        let isAcitveCalss = e.currentTarget.classList.contains('active');
                        let filter_path = document.querySelectorAll('.filter-path');
                        filter_path?.forEach(i => i.style.opacity = 0);
                        if (isAcitveCalss) {
                            e.currentTarget.classList.remove('active');
                            filterGroups?.forEach(i => i.classList.remove('inactive'));
                            drawBarChart(props.dataResults[0]?.loss_categary_data);
                            drawBarStackChart(props.dataResults[0]?.bylineedata);
                            drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data);
                            handleResetDefaultState();
                        } else {
                            e.currentTarget.classList.add('active');
                            filterGroups?.forEach(i => i.classList.add('inactive'));
                            drawBarChart(props.dataResults[0]?.loss_categary_data, d?.loss_cat, false, d.value);
                            drawBarStackChart(props.dataResults[0]?.bylineedata, d?.loss_cat, d.line_data_all);
                            // drawLossCatChart(data, dim, value, percentValue)
                            drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data,d.title,false,false,true);
                            setDonutDim(prv => d.spend_bucket);
                            setDonutVal(prv => d.value);
                            setdonutDataType(prv => 'value')
                        }
                        removeClassTargets?.forEach(i => {
                            if (!i.classList.contains(`loss-sub`)) {
                                i.classList.remove('inactive')
                            } else if (i.classList.contains(`loss-sub-${tmpIndex}`)) {
                                i.classList.remove('inactive');
                            }

                        });
                    }
                }
            })
            .on('mousemove', function (event, d) { handleTooltipShow(event, d) })
            .on('mouseleave', function (event, d) { handleTooltipShow(event, d, true) })
            .text((d, index) => `SR ${formatNumber(d.value, maxValue).val}`);

        // to create extra svg

        let bottomSvg =
            d3.select('#lossSubCatBottomDiv')
                .html('')
                .append('svg')
                .attr('width', width)
                .attr('height', 25);

        let main_g2 = bottomSvg.append('g');

        let y_axis_g2 = main_g2.append('g').attr('transform', `translate(${margin.left}, ${margin.top / 2})`)
            .attr('class', 'y-axis_line')
            .selectAll('line')
            .data(valuesArray)
            .enter();

        y_axis_g2
            .append('switch')
            .append('foreignObject')
            .attr('class', 'bottom-text')
            .attr('width', 70)
            .attr('height', 25)
            .attr('x', (d, index) => (g_area_width / y_axis_total_lines * index) - bottomTextWidth / 2)
            .attr('y', 0)
            .append('xhtml:span')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .attr('title', (d, index) => `SR ${formatNumber(d, maxValue).val}`)
            .text((d, index) => `SR ${formatNumber(d, maxValue).val}`);
    }

    // function to bar stack chart
    function drawBarStackChart(data, dim, value,selfCall=false) {
        let DeviceWidth = window.innerWidth;
        let tmpDim = '';
        let tooltip = document.querySelector('#lossCatChartTooltip');
        let sub_loss_cat = tooltip?.querySelector('.sub_loss_cat');
        let production_process = tooltip?.querySelector('.production_process');
        let annual_loss = tooltip?.querySelector('.annual_loss');
        let loss_cat = tooltip?.querySelector('.loss_cat');
        let highlighted_row = tooltip?.querySelector('.highlighted-row');
        let highlighted = highlighted_row?.querySelector('.highlighted');
        // function declaration
        function handleTooltipShow(e, item, isClose = false,highlightedData) {
            let pos_X = e?.clientX;
            let pos_Y = e?.clientY;
            if (isClose && tooltip) {
                tooltip.classList.remove('show');
            }
            else if (tooltip) {
                tooltip.classList.add('show');
                highlighted_row.style.display = 'none';
                let tooltipWidth = tooltip.offsetWidth;
                let tooltipHeight = tooltip.scrollHeight;
                let windowHeight = window.innerHeight;
                let windowWidth = window.innerWidth;
                /* Position the tooltip */
                if (windowWidth >= (tooltipWidth + pos_X)) {
                    tooltip.style.left = pos_X + 'px';
                    tooltip.classList.remove('right');
                    tooltip.classList.add('left');
                } else {
                    tooltip.style.left = `${pos_X - tooltipWidth}px`;
                    tooltip.classList.remove('left');
                    tooltip.classList.add('right');
                }
                if (windowHeight >= (tooltipHeight + pos_Y)) {
                    tooltip.style.top = pos_Y + 'px';
                    tooltip.classList.remove('bottom');
                    tooltip.classList.add('top');
                } else {
                    tooltip.style.top = `${pos_Y - tooltipHeight}px`;
                    tooltip.classList.remove('top');
                    tooltip.classList.add('bottom');
                }

                // highlightes show
                if (dim && dim == item.loss_cat && !selfCall && value) {
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = `SR ${Math.round(highlightedData.layer_val).toLocaleString()} (${Math.round(highlightedData.final_percent)}%)`;
                }
                else if (dim && item.spend_bucket == dim && !selfCall || dim && !value && !selfCall){
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = `${item.annual_loss} (${item.value}%)`;
                }
                else if(dim == item.loss_cat && selfCall == item.title && selfCall) {
                    highlighted_row.style.display = 'flex';
                    highlighted.innerHTML = `${item.annual_loss} (${item.value}%)`;
                }
                else {
                    highlighted_row.style.display = 'none';
                }
                if (production_process) production_process.innerHTML = item.title;
                if (annual_loss) annual_loss.innerHTML = `${item.annual_loss} (${item.value}%)`;
                if (loss_cat) loss_cat.innerHTML = item.loss_cat;

            }
        }
        // calculation variables 
        let maxValue = 100;
        let minValue = 0;
        let y_axis_total_lines = 2;
        let bottomTextWidth = 60;
        let bottomTextHeight = 100;
        const valuesArray = ['0%', '50%', '100%'];
        let container_target = `#lossCatChartDiv`;
        let container = document.querySelector(container_target);
        let width = container.scrollWidth;
        let svg_h = container.offsetHeight;
        let height = container.offsetHeight - 20;
        let gap = DeviceWidth > 1300 ? 5 : 2;
        let bar_height_by_data = (height / data?.length) - gap;
        let bar_height = bar_height_by_data < 8? 8 : bar_height_by_data;
        let margin = {
            left: DeviceWidth > 1300 ? 150 : DeviceWidth > 1200 ? 120 : 100,
            right: 10,
            top: 20,
            bottom: 20,
        }
        let g_area_width = width - (margin.left + margin.right);
        let transform_y = height < container.offsetHeight ? (container.offsetHeight - height)/2 : 0; 
        let getwidth = (g_area_width, bar_h, value) => {
            let final_per = (value * 100 / maxValue);
            let bar_w = (g_area_width * final_per) / 100;
            return bar_w;
        }
        let previousVal = 0;
        function drawBarsPath(index, g_area_width, bar_h, value, overlay, overlayValue) {
            let bar_w = getwidth(g_area_width, bar_h, value);
            let prv_bar_w = getwidth(g_area_width, bar_h, index > 0 ? previousVal : 0);
            previousVal = index > 0 ? previousVal + (overlay ? overlayValue : value) : (overlay ? overlayValue : value);
            return `
                M ${prv_bar_w} 0
                h ${bar_w}
                v ${bar_height}
                h -${bar_w} Z
                `;
        }
        // create SVG element in target div and set its attributes
        let svg = d3.select(container_target).html('').append('svg')
            .attr('viewBox', `0 0 ${width} ${svg_h}`).attr('width', width);
        let main_g = svg.append('g');
        let y_axis_g = main_g.append('g').attr('transform', `translate(${margin.left}, ${transform_y/2})`)
            .attr('class', 'y-axis_line')
            .selectAll('line')
            .data(valuesArray)
            .enter();
        y_axis_g
            .append('line')
            .attr('x1', (d, index) => (g_area_width / y_axis_total_lines * index))
            .attr('y1', 0)
            .attr('x2', (d, index) => (g_area_width / y_axis_total_lines * index))
            .attr('y2', height)
            .attr('stroke-width', 0.5)
            .attr('stroke', '#C8C6C4')
            .attr('stroke-dasharray', 5)
        // bars js
        let bar_g = main_g.append('g')
            .attr('class', 'bar_wrapper_g')
            .attr('transform', `translate(${margin.left}, ${transform_y})`)
            .selectAll(`path`)
            .data(data)
            .enter()
            .append('g');

        let nested_bar_g =
            main_g.append('g')
                .attr('class', 'bar_wrapper_g')
                .attr('transform', `translate(${margin.left}, ${transform_y})`)
                .selectAll(`path`)
                .data(data)
                .enter()
                .append('g')
                .attr('class', 'bars_g')
                .attr('transform', (d, index) => `translate(0, ${(bar_height + gap) * index})`)
                .selectAll(`.bars_g`)
                .data(d => d)
                .enter()
                .append('g');
        nested_bar_g
            .append('path')
            .attr('class', d => {
                return `bar_path filter-group ${(selfCall== d.title && dim == d?.loss_cat)  && selfCall ? 'active' : ''} 
                ${(selfCall && selfCall==d?.title && d?.loss_cat == dim || dim && (dim == d.spending_bucket || dim == d?.loss_cat) && !value && !selfCall) ? '' :  dim ? 'inactive' : ''} ${d?.loss_cat?.split(' ').join('')} ${d?.spending_bucket?.split(' ').join('')}`})
            .attr('d', (d, index) => {
                return drawBarsPath(index, g_area_width, bar_height, d.value)
            })
            .attr('fill', (d, index) => d.color)
            .attr('data-index',(d, index) => index)
            .on('click', (e, d) => {
                highlighted_row.style.display = 'none';
                if (d.value !== 'NA') {
                    let activDiv = document.querySelector('svg .filter-group.active');
                    let tmpRemoveClass = d?.loss_cat?.split(' ').join('');
                    let removeClassTargets = document.querySelectorAll(`.${tmpRemoveClass}`);
                    let filterGroups = document.querySelectorAll('svg .filter-group');
                    let g = e.target.parentElement.parentElement;
                    let innerPath = g.querySelectorAll('.filter-group');
                    let donutLayer = document.querySelector('.bar_wrapper_g.dim');
                    let stackChartLabel = document.querySelectorAll('.section-val.without-dim');
                    stackChartLabel?.forEach(i => i.style.opacity = 1)
                    innerPath.forEach(i => i.classList.add('inside-parent'));
                    if (donutLayer) {
                        donutLayer.style.display = `none`;
                    }
                    if (e.currentTarget.classList.contains('active')) {
                        filterGroups?.forEach(i => i.classList.remove('inactive', 'inside-parent', 'active'));
                        drawBarChart(props.dataResults[0]?.loss_categary_data);
                        drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data);
                        drawBarStackChart(props.dataResults[0]?.bylineedata);
                        handleResetDefaultState();
                    } else {
                        activDiv?.classList?.remove('active');
                        e.currentTarget.classList.add('active');
                        filterGroups?.forEach(i => i.classList.add('inactive'));
                        e.currentTarget.classList.remove('inactive');
                        removeClassTargets?.forEach(i => {
                            if (!i.classList.contains('inside-parent')) {
                                i.classList.remove('inactive');
                            }
                        });
                        drawBarStackChart(props.dataResults[0]?.bylineedata,d?.loss_cat,false,d.title);
                        drawBarChart(props.dataResults[0]?.loss_categary_data, d?.loss_cat, d.value);
                        drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data, d?.loss_cat, d.value, true);
                        setDonutDim(prv => d?.spending_bucket);
                        setDonutVal(prv => d.value);
                        setdonutDataType(prv => false);
                    }
                }
            })
            .on('mousemove', function (event, d) {
                if(dim && value){
                    let index = event.target.getAttribute('data-index');
                    let tmpObj = {
                        layer_valueArr: value,
                        value: d.value,
                        layer_val: value[index],
                        annual_loss: extractNumbers(d.annual_loss),
                        per_val: (value[index] / extractNumbers(d.annual_loss)) * 100,
                        final_percent: d.value * (value[index] / extractNumbers(d.annual_loss)) * 100 / 100
                    };
                    handleTooltipShow(event, d,false,tmpObj) 
                }else{
                    handleTooltipShow(event, d,false)
                }
                }
                )
            .on('mouseleave', function (event, d) { handleTooltipShow(event, d, true) });

        if (dim && value) {
            let nested_bar_g2 =
                main_g.append('g')
                    .attr('class', 'bar_wrapper_g dim')
                    .attr('transform', `translate(${margin.left}, ${transform_y})`)
                    .selectAll(`path`)
                    .data(data)
                    .enter()
                    .append('g')
                    .attr('class', 'bars_g')
                    .attr('transform', (d, index) => `translate(0, ${(bar_height + gap) * index})`)
                    .selectAll(`.bars_g`)
                    .data(d => d)
                    .enter()
                    .append('g');
            nested_bar_g2
                .append('path')
                .style('pointer-events', 'none')
                .attr('class', d => `bar_path filter-group`)
                .attr('d', (d, index) => {
                    if (d?.loss_cat == dim) {
                        let tmpObj = {
                            layer_valueArr: value,
                            value: d.value,
                            layer_val: value[index],
                            annual_loss: extractNumbers(d.annual_loss),
                            per_val: (value[index] / extractNumbers(d.annual_loss)) * 100,
                            final_percent: d.value * (value[index] / extractNumbers(d.annual_loss)) * 100 / 100
                        }
                        return drawBarsPath(index, g_area_width, bar_height, value ? tmpObj.final_percent : d.value, 'overlay', d.value);
                    }
                })
                .attr('fill', (d, index) => d.color);
            nested_bar_g2.append('switch')
                .append('foreignObject')
                .attr('pointer-events', 'none')
                .attr('x', (d, i, e) => { return e[i].parentElement.parentElement.getBBox().x; })
                .attr('y', 0)
                .attr('width', d => getwidth(g_area_width, bar_height, d.value))
                .attr('height', bar_height)
                .append('xhtml:div')
                .attr('class', 'section-val')
                .style('justify-content', 'flex-start')
                .style('padding', `0px 5px`)
                .append('xhtml:span')
                .attr('xmlns', "http://www.w3.org/1999/xhtml")
                .text((d, index) => {
                    let tmpObj = {
                        layer_valueArr: value,
                        value: d.value,
                        layer_val: value ? value[index] : d.value,
                        annual_loss: extractNumbers(d.annual_loss),
                        per_val: (value[index] / extractNumbers(d.annual_loss)) * 100,
                        final_percent: d.value * (value[index] / extractNumbers(d.annual_loss)) * 100 / 100
                    }
                    return (dim === d?.loss_cat && tmpObj.final_percent > 5) ? `${Math.round(tmpObj.final_percent)}%` : dim === d?.loss_cat ? '...' : ''
                });
        }

        nested_bar_g.append('switch')
            .append('foreignObject')
            .attr('pointer-events', 'none')
            .attr('x', (d, i, e) => { return e[i].parentElement.parentElement.getBBox().x; })
            .attr('y', 0)
            .attr('width', d => getwidth(g_area_width, bar_height, d.value))
            .attr('height', bar_height)
            .append('xhtml:div')
            .attr('class', 'section-val without-dim')
            .style('opacity', dim && !value && !selfCall ? '' :dim && !selfCall ? '0' : '')
            .append('xhtml:span')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .text((d, index) => d.value > 15 ? `${d.value}%` : '...');

        bar_g.append('switch')
            .append('foreignObject')
            .attr('x', -margin.left)
            .attr('y', (d, index) => (bar_height + gap) * index)
            .attr('width', margin.left)
            .attr('height', bar_height)
            .append('xhtml:div')
            .attr('class', 'section-text')
            .append('xhtml:span')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .attr('title', (d, index) => `${d[0].loss_cat}`)
            .text((d, index) => `${d[0].loss_cat}`);

        // bottom svg
        let bottomSvg = d3.select('#stackBarBottomSvg').html('').append('svg').attr('width', width).attr('height', 25);
        let main_g2 = bottomSvg.append('g');
        let y_axis_g2 = main_g2.append('g').attr('transform', `translate(${margin.left}, ${0})`)
            .attr('class', 'y-axis_line')
            .selectAll('line')
            .data(valuesArray)
            .enter();
        y_axis_g2
            .append('switch')
            .append('foreignObject')
            .attr('class', 'bottom-text')
            .attr('width', 50)
            .attr('height', DeviceWidth > 1300 ? 25 : 20)
            .attr('x', (d, index) => (g_area_width / y_axis_total_lines * index) - bottomTextWidth / 2)
            .attr('y', 0)
            .append('xhtml:span')
            .attr('xmlns', "http://www.w3.org/1999/xhtml")
            .attr('title', (d, index) => `${d}`)
            .text((d, index) => `${d}`);
    }

    useEffect(() => {
        drawBarChart(props.dataResults[0]?.loss_categary_data)
        drawLossCatChart(props.dataResults[0]?.sub_loss_categary_data);
        drawBarStackChart(props.dataResults[0]?.bylineedata);
        setDonutData(props.dataResults[0]?.loss_type_data);
        setAllChartLabels({
            loss_cat_chart: dataResults?.allChartLabels,
            loss_sub_cat_chart: dataResults?.allChartLabels,
            loss_type_chart: dataResults?.allChartLabels
        })
    }, [props.dataResults]);


    useEffect(() => {
        document.addEventListener('click', function (e) {
            if (!e.target.closest('.edit-btn') && !e.target.closest('.DropDownWrap')) {
                setActiveEditBtn(false);
            }
        })

    }, [])

    return (
        <>
            <Helmet>
                {deviceWidth <= 900 &&
                    <meta name="viewport" content='' />
                }
            </Helmet>

            <PowerBiWrapper data-component="PowerBiWrapper">
                <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
                <BreadcrumbsItem to="/">{plant_name || 'Plant name'}</BreadcrumbsItem>
                <BreadcrumbsItem to="#">Result</BreadcrumbsItem>

                <div style={{ display: !nullPage ? '' : 'none' }}>
                    <PowerBiHeader data-component="PowerBiHeader">
                        {headerBox.map((item, index) => (
                            <PowerBiHeaderBox data-component="PowerBiHeaderBox" className={`PowerBiHeaderBox-${index}`} key={index}>
                                <p>{item.p}</p>
                                <h2>{item.h2}</h2>
                            </PowerBiHeaderBox>
                        ))}
                    </PowerBiHeader>
                    {/* header end */}

                    <PowerBiContents data-component="PowerBiContents">
                        <PwbRow data-component="PwbRow" className='h-350 h-sm-350'>
                            <PwbLeft data-component="PwbLeft">
                                <PwbLeftRow data-component="PwbLeftRow" className="h-30">
                                    <h2 style={{ textAlign: 'center' }}> Loss Area Filter </h2>
                                    <PwbCardRow data-component="PwbCardRow">
                                        {filter.lossareafilter.map((item, index) => (

                                            <PwbCard data-component="PwbCard" key={index} onClick={() => handleFilter('lossareafilter', item.id)} className={`${appliedFilter.lossareafilter === item.id ? 'active' : ''}`}>

                                                <p>
                                                    {item.title}
                                                </p>
                                            </PwbCard>
                                        ))}
                                    </PwbCardRow>
                                </PwbLeftRow>
                                {/*pwb -card1  row end */}
                                <PwbLeftRow data-component="PwbLeftRow" className='h-70'>
                                    <h2>Production Process Filter</h2>
                                    <PwbCardRow data-component="PwbCardRow">
                                        {filter.productionProcessFilter.map((item, index) => (
                                            <PwbCard data-component="PwbCard" key={index} onClick={() => handleFilter('productionProcessFilter', item.id)} className={`${appliedFilter.productionProcessFilter === item.id ? 'active' : ''}`}>
                                                <p>
                                                    {item.title}
                                                </p>
                                            </PwbCard>
                                        ))}
                                    </PwbCardRow>
                                </PwbLeftRow>
                                {/*pwb -card1  row end */}

                            </PwbLeft>
                            <PwbMid data-component="PwbMid">
                                <PwbMidCard data-component="PwbMidCard">
                                    <ChartHeading>Annual Losses by Loss Category</ChartHeading>
                                    <ChartDivWrapper data-component="ChartDivWrapper">
                                        <ChartDiv ref={chartDiv} data-component="ChartDiv" className="resultChart-1"></ChartDiv>
                                        <ToolTipLossCat data-component="ToolTipLossCat" id="tooltip">
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Loss Category
                                                </div>
                                                <div className="tooltip-value-col loss_cat"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Spending Bucket
                                                </div>
                                                <div className="tooltip-value-col spend_bucket"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Annual Losses
                                                </div>
                                                <div className="tooltip-value-col annual_loss"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row  highlighted-row" style={{ display: 'none' }}>
                                                <div className="tooltip-label-col">
                                                    Highlighted
                                                </div>
                                                <div className="tooltip-value-col highlighted"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    % of Total Losses
                                                </div>
                                                <div className="tooltip-value-col percent_total_loss"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Total
                                                </div>
                                                <div className="tooltip-value-col percent_total"></div>
                                            </div>
                                        </ToolTipLossCat>
                                    </ChartDivWrapper>
                                    <div className="type-bottom-text justify-center">
                                        <div className='bold-text'>Loss Type</div>
                                        {allChartLabels?.loss_cat_chart?.map((item, ind) =>
                                        (
                                            <div key={ind} className='bottom-cir-text' style={{ color: item.color }}>
                                                <span>{item.label}</span>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    <div className='total-identified-loss-wrap'>
                                        <h3 className='title'>Total Losses Identified</h3>
                                        <span className='max-val'>SR {totalFilter ? formatNumber(totalFilter, totalFilter).val : formatNumber(total_loss, total_loss).val}</span>
                                    </div>
                                </PwbMidCard>
                            </PwbMid>
                            <EndMid data-component="EndMid">
                                <PwbMidCard data-component="PwbMidCard">
                                    <ChartHeading>Annual Losses by Production Process</ChartHeading>
                                    <div class="loss-sub-cat-chart-wrapper">
                                        <div id="lossCatChartDiv" className="loss-sub-chart resultChart-2"></div>
                                        <ToolTipLossCat data-component="ToolTipLossCat" id="lossCatChartTooltip">
                                            <div class="tootip-loss-cat-row">
                                                <div class="tooltip-label-col">
                                                    Loss Category
                                                </div>
                                                <div class="tooltip-value-col loss_cat"></div>
                                            </div>
                                            <div class="tootip-loss-cat-row">
                                                <div class="tooltip-label-col">
                                                    Production Process
                                                </div>
                                                <div class="tooltip-value-col production_process"></div>
                                            </div>
                                            <div class="tootip-loss-cat-row">
                                                <div class="tooltip-label-col">
                                                    Annual Losses
                                                </div>
                                                <div class="tooltip-value-col annual_loss"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row  highlighted-row" style={{ display: 'none' }}>
                                                <div className="tooltip-label-col">
                                                    Highlighted
                                                </div>
                                                <div className="tooltip-value-col highlighted"></div>
                                            </div>
                                        </ToolTipLossCat>
                                    </div>
                                    <div id='stackBarBottomSvg'></div>
                                    <div className="type-bottom-text justify-center">
                                        <div className='bold-text'>Production Process</div>
                                        {filter.productionProcessFilter.map((item, index) => (
                                            <div className='bottom-cir-text' style={{ color: `${item.color}` }}>
                                                <span>{item.title}</span>
                                            </div>
                                        ))}
                                    </div>
                                </PwbMidCard>
                            </EndMid>
                        </PwbRow>
                    </PowerBiContents>
                    {/* powerbi-contents end */}

                    <PowerBiContents data-component="PowerBiContents">
                        <PwbRow data-component="PwbRow" className='h-350'>
                            <PwbLeft data-component="PwbLeft">
                                <PwbLeftRow data-component="PwbLeftRow" className='min-height-100'>
                                    <h2> Loss Category Filter </h2>
                                    <PwbCardRow data-component="PwbCardRow">
                                        {filter.lossCategoryFilter.map((item, index) => (
                                            <PwbCard data-component="PwbCard" key={index} onClick={() => handleFilter('lossCategoryFilter', item.id)} className={`${appliedFilter.lossCategoryFilter.includes(item.id) ? 'active' : ''}`}>
                                                <p>
                                                    {item.title}
                                                </p>
                                            </PwbCard>
                                        ))}
                                    </PwbCardRow>
                                </PwbLeftRow>
                                {/*pwb -card1  row end */}
                            </PwbLeft>
                            <PwbMid data-component="PwbMid">
                                <PwbMidCard data-component="PwbMidCard">
                                    <ChartHeading>Annual Losses by Sub Loss Category</ChartHeading>
                                    <div className="loss-sub-cat-chart-wrapper">
                                        <div className="loss-sub-chart resultChart-3" id="lossSubChart"></div>
                                        <ToolTipLossCat data-component="ToolTipLossCat" id="lossCatTooltip">
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Sub Loss Category
                                                </div>
                                                <div className="tooltip-value-col sub_loss_cat"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Spending Bucket
                                                </div>
                                                <div className="tooltip-value-col spend_bucket"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Annual Losses
                                                </div>
                                                <div className="tooltip-value-col annual_loss"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row  highlighted-row" style={{ display: 'none' }}>
                                                <div className="tooltip-label-col">
                                                    Highlighted
                                                </div>
                                                <div className="tooltip-value-col highlighted"></div>
                                            </div>
                                            <div className="tootip-loss-cat-row">
                                                <div className="tooltip-label-col">
                                                    Loss Category
                                                </div>
                                                <div className="tooltip-value-col loss_cat"></div>
                                            </div>
                                        </ToolTipLossCat>
                                    </div>
                                    <div id="lossSubCatBottomDiv"></div>
                                    <div className="type-bottom-text justify-center">
                                        <div className='bold-text'>Loss Type</div>
                                        {allChartLabels?.loss_sub_cat_chart?.map((item, ind) =>
                                        (
                                            <div key={ind} className='bottom-cir-text' style={{ color: item.color }}>
                                                <span>{item.label}</span>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </PwbMidCard>
                            </PwbMid>
                            <PwbRight data-component="PwbRight">
                                <PwbRightCard data-component="PwbRightCard">
                                    <ChartHeading>Annual Losses by Loss Type</ChartHeading>
                                    <div className="donut_chart_container">
                                        <DonutChart
                                            data={donutData}
                                            width={300}
                                            height={300}
                                            value={donutVal}
                                            dim={donutDim}
                                            setDim={setDonutDim}
                                            setValue={setDonutVal}
                                            donutDataType={donutDataType}
                                            setdonutDataType={setdonutDataType}
                                            total_loss={total_loss}
                                            drawBarChartData={props.dataResults[0]?.loss_categary_data}
                                            drawLossCatChartData={props.dataResults[0]?.sub_loss_categary_data}
                                            drawBarStackChartData={props.dataResults[0]?.bylineedata}
                                            drawBarChart={drawBarChart}
                                            drawBarStackChart={drawBarStackChart}
                                            drawLossCatChart={drawLossCatChart}
                                        />


                                    </div>
                                    <div className="type-bottom-text justify-center">
                                        <div className='bold-text'>Loss Type</div>
                                        {allChartLabels?.loss_type_chart?.map((item, ind) =>
                                        (
                                            <div key={ind} className='bottom-cir-text' style={{ color: item.color }}>
                                                <span>{item.label}</span>
                                            </div>
                                        ))
                                        }
                                    </div>
                                </PwbRightCard>
                            </PwbRight>
                        </PwbRow>
                    </PowerBiContents>
                    {/* powerbi-contents end */}
                </div>

                <IncompleteSetupWrapper data-component="IncompleteSetupWrapper" style={{ display: nullPage ? '' : 'none' }}>
                    <IncompleteSetupCard data-component="IncompleteSetupCard">
                        <h2 className='heading'>Incomplete Setup!</h2>
                        <i className='main_img' dangerouslySetInnerHTML={{ __html: incompleteSvg }}></i>
                        <p className='text'>Complete your Loss Elimination Setup to generate report.</p>
                        <button className='loss-elimination-btn' onClick={() => setIsSelectScenarioPopOpen('incomplete-setup')}>
                            <i dangerouslySetInnerHTML={{ __html: incompleteBtnIcon }}></i>
                            <span>Loss elimination Setup</span>
                        </button>
                    </IncompleteSetupCard>
                </IncompleteSetupWrapper>

                <BottomTabWrapper data-component="BottomTabWrapper">
                    <TabsSection data-component="TabsSection">
                        <Tabs
                            value={tabsValue}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="scrollable auto tabs"
                        >
                            {scenarioArr?.map((item, ind) => (

                                <Tab
                                    key={ind}
                                    label={
                                        renameScenario.id === item.id ?
                                            <input className='rename-input' value={renameScenario.name} onChange={(e) => setRenameScenario(prv => { return { ...prv, name: e.target.value } })} autoFocus={item.id === renameScenario.id} onKeyUp={(e) => handleRenameFunction(e)} onBlur={(e) => handleRenameFunction(e, `blur`)} onClick={(e) => e.stopPropagation()} />
                                            :
                                            <span>{item.name}</span>
                                    }
                                    value={item.id}
                                    {...a11yProps(ind)}
                                    onClick={() => handleScreen(item)}
                                    icon={
                                        <>
                                            {ind > 0 &&
                                                <button className={`edit-btn ${activeEditBtn.id === item.id ? `active` : ``}`} onClick={(e) => handleClick(e, item)}>
                                                    <i className="edit-icon" dangerouslySetInnerHTML={{ __html: editIcon }}></i>
                                                </button>
                                            }
                                        </>

                                    }
                                />

                            ))
                            }

                            <DropDownWrap data-component="DropDownWrap" id={`dropdownwrap-${activeEditBtn.id}`} className={activeEditBtn ? `active DropDownWrap` : 'DropDownWrap'}>
                                <MenuItem onClick={() => { setRenameScenario(activeEditBtn); setActiveEditBtn(false); }}>Rename</MenuItem>
                                <MenuItem onClick={() => handleDeletePop(activeEditBtn)}>Delete</MenuItem>
                            </DropDownWrap>

                        </Tabs>
                    </TabsSection>
                    <TabAddBtn data-component="TabAddBtn" onClick={() => setIsSelectScenarioPopOpen('add')}>
                        <span dangerouslySetInnerHTML={{ __html: TabAddBtnIcon }}></span>
                    </TabAddBtn>
                </BottomTabWrapper>
                {!nullPage &&
                    <DragableBtn onClick={() => handleReviewFunction()} />
                }
            </PowerBiWrapper>
            {/* Incomplete Setup Wrapper */}

            {isSelectScenarioPopOpen &&
                <LossResultSetupPopCtrl
                    pid={pid}
                    plant_name={plant_name}
                    slug={slug}
                    setScenarioArr={setScenarioArr}
                    scenarioArr={scenarioArr}
                    closeFunction={(val) => { setIsSelectScenarioPopOpen(val) }}
                    isSelectScenarioPopOpen={isSelectScenarioPopOpen}
                    setScidfilter={props.setScidfilter}
                    nullPage={nullPage}
                    reviewScenario={reviewScenario}
                />
            }
            {deletePop &&
                <DeletePopWrap data-component="DeletePopWrap">
                    <DeletePopCard data-component="DeletePopCard">
                        <h2>Delete Scenario</h2>
                        <p>Are you sure you would like to delete this scenario permanently?</p>
                        <DeletePopFooter data-component="DeletePopFooter">
                            <button className="btn cancel-btn" onClick={() => setDeletePop(false)}>Cancel</button>
                            <button className="btn yes-btn" onClick={() => handleDeleteFunction()}>Yes, Delete</button>
                        </DeletePopFooter>
                    </DeletePopCard>
                </DeletePopWrap>
            }
        </>
    );
}

export const DonutChart = (props) => {
    const { width, height, data, value = 100, dim, setDim, setValue, donutDataType, setdonutDataType } = props;
    const MARGIN_X = 25;
    const MARGIN_Y = 25;
    function extractNumbers(inputString) {
        return inputString.replace(/\D/g, "");
    };
    const colors = [
        "#e0ac2b",
        "#e85252",
        "#6689c6",
        "#9a6fb0",
        "#a53253",
        "#69b3a2",
    ];

    const radius = Math.min(width - 2 * MARGIN_X, height - 2 * MARGIN_Y) / 2;
    const innerRadius = radius / 2;

    const pie = useMemo(() => {
        const pieGenerator = d3.pie().value((d) => d.value);
        return pieGenerator(data);
    }, [data]);

    const arcGenerator = d3.arc();

    const clickFunction = (e, d) => {
        if (d.value !== 'NA') {
            if (d.value !== 'NA') {
                let tmpRemoveClass = d?.spend_bucket?.split(' ').join('');
                let removeClassTargets = document.querySelectorAll(`.${tmpRemoveClass}`);
                let filterGroups = document.querySelectorAll('svg .filter-group');
                let isAcitveCalss = e.currentTarget.classList.contains('active');
                let donutLayer = document.querySelector('.bar_wrapper_g.dim');
                let stackChartLabel = document.querySelectorAll('.section-val.without-dim');
                stackChartLabel?.forEach(i => i.style.opacity = 1)
                if (donutLayer) {
                    donutLayer.style.display = `none`;
                }
                if (isAcitveCalss) {
                    e.currentTarget.classList.remove('active');
                    filterGroups?.forEach(i => i.classList.remove('inactive'));
                    props.drawBarChart(props.drawBarChartData);
                    props.drawLossCatChart(props.drawLossCatChartData);
                    props.drawBarStackChart(props.drawBarStackChartData);
                    setDim(prv => '');
                    setValue(prv => 100);
                    setdonutDataType(false);
                } else {
                    e.currentTarget.classList.add('active');
                    filterGroups?.forEach(i => i.classList.add('inactive'));
                    props.drawBarChart(props.drawBarChartData, d?.spend_bucket, false, d.value);
                    props.drawLossCatChart(props.drawLossCatChartData, d?.spend_bucket, false, d.value);
                    props.drawBarStackChart(props.drawBarStackChartData,d?.spend_bucket);
                    setDim(prv => d?.spend_bucket);
                    setValue(prv => 100);
                    setdonutDataType(false);
                }
                removeClassTargets?.forEach(i => i.classList.remove('inactive'));
            }

        }
    }

    const shapes = pie.map((grp, i) => {
        // First arc is for the donut
        let tmpvalue = donutDataType ? (value * 100) / extractNumbers(grp.data.annual_loss) : value;
        const sliceInfo = {
            innerRadius,
            outerRadius: radius,
            startAngle: grp.startAngle,
            endAngle: grp.endAngle,
        };
        const filterSliceInfo = {
            innerRadius: innerRadius + (innerRadius * tmpvalue / 100),
            outerRadius: radius,
            startAngle: grp.startAngle,
            endAngle: grp.endAngle,
        };
        const slicePath = arcGenerator(sliceInfo);
        const filterSlicePath = arcGenerator(filterSliceInfo);

        return (
            <g key={i}>
                <g>
                    <path
                        d={slicePath}
                        fill={grp.data.color}
                        className={`donut-chart__slice_${i} ${grp?.data?.spend_bucket?.split(' ').join('')}`}
                        onMouseMove={(e) => handleTooltipShow(e, grp.data)}
                        onMouseLeave={(e) => handleTooltipShow(e, grp.data, true)}
                        onClick={(e) => clickFunction(e, grp.data)}
                    />
                </g>
                {(dim && grp?.data?.spend_bucket !== dim) ?
                    <g>
                        <path
                            d={slicePath}
                            fill='rgba(255,255,255,0.5)'
                            style={{ pointerEvents: 'none' }}
                            className={`donut-chart__slice_${i}`}
                        />
                    </g>
                    :
                    dim &&
                    <g>
                        <path
                            d={filterSlicePath}
                            fill='rgba(255,255,255,0.5)'
                            style={{ pointerEvents: 'none' }}
                            className={`donut-chart__slice_${i}`}
                        />
                    </g>
                }
            </g>
        );
    });

    const chartLabel = pie.map((grp, i) => {
        // First arc is for the donut

        return (
            <div key={i} className='label-row'>
                <div className='label-circle' style={{ background: grp.data.color }}></div>
                <div>{grp.data.title}</div>
            </div>

        );
    });

    function handleTooltipShow(e, item, isClose = false) {
        let tooltip = document.querySelector('#donutTooltip');
        let pos_X = e?.clientX;
        let pos_Y = e?.clientY;
        if (isClose && tooltip) {
            tooltip.classList.remove('show');
        }
        else if (tooltip) {
            tooltip.classList.add('show');
            let tooltipWidth = tooltip.offsetWidth;
            let tooltipHeight = tooltip.scrollHeight;
            let windowHeight = window.innerHeight;
            let windowWidth = window.innerWidth;
            /* Position the tooltip */
            if (windowWidth >= (tooltipWidth + pos_X)) {
                tooltip.style.left = pos_X + 'px';
                tooltip.classList.remove('right');
                tooltip.classList.add('left');
            } else {
                tooltip.style.left = `${pos_X - tooltipWidth}px`;
                tooltip.classList.remove('left');
                tooltip.classList.add('right');
            }
            if (windowHeight >= (tooltipHeight + pos_Y)) {
                tooltip.style.top = pos_Y + 'px';
                tooltip.classList.remove('bottom');
                tooltip.classList.add('top');
            } else {
                tooltip.style.top = `${pos_Y - tooltipHeight}px`;
                tooltip.classList.remove('top');
                tooltip.classList.add('bottom');
            }

            let spend_bucket = tooltip?.querySelector('.spend_bucket');
            let annual_loss = tooltip?.querySelector('.annual_loss');
            let highlighted_row = tooltip?.querySelector('.highlighted-row');
            let highlighted = highlighted_row?.querySelector('.highlighted');
            if (spend_bucket) spend_bucket.innerHTML = item.spend_bucket;
            if (annual_loss) annual_loss.innerHTML = item.annual_loss;
            if (dim == item.spend_bucket) {
                let per = donutDataType ? (value * 100) / extractNumbers(item.annual_loss) : value;
                highlighted_row.style.display = 'flex';
                highlighted.innerHTML = 'SR ' + Math.round(extractNumbers(item.annual_loss) * per / 100).toLocaleString();
            } else {
                highlighted_row.style.display = 'none';
            }
        }
    }
    return (
        <>
            <div className='donut_chart_label-container'>{chartLabel}</div>

            <svg className='svg-donut' style={{ display: "inline-block" }} viewBox={`0 0 ${width} ${height}`}>
                <g transform={`translate(${width / 2}, ${height / 2})`}>{shapes}</g>
            </svg>



            <ToolTipLossCat data-component="ToolTipLossCat" id="donutTooltip">
                <div className="tootip-loss-cat-row">
                    <div className="tooltip-label-col">
                        Spending Bucket
                    </div>
                    <div className="tooltip-value-col spend_bucket"></div>
                </div>
                <div className="tootip-loss-cat-row">
                    <div className="tooltip-label-col">
                        Annual Losses
                    </div>
                    <div className="tooltip-value-col annual_loss"></div>
                </div>
                <div className="tootip-loss-cat-row  highlighted-row" style={{ display: 'none' }}>
                    <div className="tooltip-label-col">
                        Highlighted
                    </div>
                    <div className="tooltip-value-col highlighted"></div>
                </div>

            </ToolTipLossCat>
        </>

    );
};

