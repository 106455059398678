/** imported components

  * makeStyles for ===> styling material-ui component.
  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * auth.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * useForm for ==> React hooks for form validation.
  * CartContext for ==> add item into the cart.
  * yupResolver for ==> React hooks for form validation.

**/
import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import serviceProvider from "../../services/axios";
import { CartContext } from "../../contexts/CartContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// component of Cart bill detail
export const CartBillView = (props) => {
  const validationSchema = yup.object().shape({
    billing_name: yup.string().required("Required").matches(/^[a-zA-Z0-9\s]+$/, 'Enter valid name'),
    address: yup.string().required("Required").matches(/^[0-9a-zA-Z(\-/_,.\s)]+$/, 'Enter valid address'),
    city: yup.string().required("Required").matches(/^[a-zA-Z\s]+$/, 'Enter valid city name'),
    state: yup.string().required("Required").matches(/^[a-zA-Z\s]+$/, 'Enter valid state name'),
    zip: yup.string().required("Required").matches(/^[0-9]+$/, 'Enter valid zip code'),
    country: yup.string().required("Required"),
  });

  // create Validation schema using yup plugin
  const { register, handleSubmit, reset, errors, setError, formState } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(validationSchema),
    });

  //function will call on Submit and save data into billingData variable in local storage
  const onSubmit = (data) => {
    localStorage.setItem("billingData", JSON.stringify(data));
  };



  // function to update address
  const updateAddress = () => {

    let formData = new FormData(document.getElementById("addressForm"));
    serviceProvider
      .post("/profile?type=updateBillData", formData, true)
      .then((response) => {
        props.RedirectClick();
      });
  };

  // const billingData = JSON.parse(localStorage.getItem("billingData1"));
  const [user, setUser] = useState();
  const [billingData, setBillingData] = useState();
  const { cartItems } = useContext(CartContext);
  const {
    total,
    discount,
    discountPrice,
    itemCount,
    increase,
    decrease,
    removeProduct,
  } = useContext(CartContext);
  const profileDataItem = props.profileData ? props.profileData[0] : [];
  const [btnState, setBtnState] = useState(props.profileData ? true : false);
  const [totalPaid, setTotalPaid] = useState(0);
  const [setfs, setFs] = useState(false);
  const user1 = JSON.parse(localStorage.getItem("userData"));
  const usid = user1 ? user1.uid : 0;
  if (setfs) {
    formState.isValid = true;
  }

  useEffect(() => {
    const search = async () => {
      const apiUrl = `/home?param=paiditemwithbilling&uid=${usid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});

      setTotalPaid(data[0].paid);

      if (data[0].valid == 1) {
        setFs(true);
      }
    };
    search();
  }, []);


  // function to set isValid value conditionally
  const setS = (country = "") => {
    // setBtnState(true);
    var bName = document.getElementById('billing_name').value;
    var regex = /^[a-zA-Z\s]+$/; //this will admit letters and space
    if (bName && bName.match(regex)) {
      var address = document.getElementById('address').value;
      var regex2 = /^[0-9a-zA-Z(\-/_,.\s)]+$/;
      if (address && address.match(regex2)) {
        var city = document.getElementById('city').value;
        var regex3 = /^[a-zA-Z\s]+$/;
        if (city && city.match(regex3)) {
          var state = document.getElementById('state').value;
          var regex4 = /^[a-zA-Z\s]+$/;
          if (state && state.match(regex4)) {
            var zipcode = document.getElementById('zip').value;
            var regex5 = /^[0-9]+$/;
            if (zipcode && zipcode.match(regex5)) {
              console.log(country);
              if (country != "" && country != null) {
                formState.isValid = true;
              } else {
                var country = document.getElementById('country').value;
                if (country != "" && country != null) {
                  formState.isValid = true;
                } else {
                  formState.isValid = false;
                  setFs(prev => false);
                  return false;
                }
              }
            } else {
              formState.isValid = false;
              setFs(prev => false);
              return false;
            }
          } else {
            formState.isValid = false;
            setFs(prev => false);
            return false;
          }
        } else {
          formState.isValid = false;
          setFs(prev => false);
          return false;
        }
      } else {
        formState.isValid = false;
        setFs(prev => false);
        return false;
      }
    } else {
      formState.isValid = false;
      setFs(prev => false);
      return false;
    }
  };

  let handleCombo = (combo_data) => {
    let filterArray = [];
    let tmpComboIdArr = [];
    let finalArray = [];
    combo_data.forEach(element => {
      if (element.combo_id !== 0) {
        filterArray.push(element);
        if (!tmpComboIdArr.includes(element.combo_id)) {
          tmpComboIdArr.push(element.combo_id);
        }
      }
    });
    tmpComboIdArr.forEach((item) => {
      let tmpArr = filterArray.filter(i => item == i.combo_id);
      if (tmpArr.length > 0) {
        finalArray.push(tmpArr);
      }
    });

    return finalArray;
  }

  let handlePriceSum = (arr) => {
    let price = 0;
    arr.forEach(i => {
      price += i.price
    });

    return price;
  }


  return (
    <div className="cart__page__wrapper">
      {/* 
	  	  left side section start
		    Here is cart time line & 
		    My cart detail section
	    */}
      <div className="cart__page__left">
        <div className="cart__time__line">
          <div className="time__line__item complete">
            {/*add complete class when completed*/}
            <span>My cart</span>
          </div>
          <div className="time__line__item inprogress">
            <span>Billing Info</span>
          </div>
          <div className="time__line__item">
            <span>Payment</span>
          </div>
        </div>
        {/* cart__time__line end */}
        <div className="cart__left__content">
          <div className="billing__info__items">
            <h2 className="h1">
              <span>Billing Info</span>
              <Link to="/cart" className="cart__back__link">
                <img
                  src={
                    require("../../assets/img/cart-page/back-arrow.svg").default
                  }
                  alt="true"
                />
                Back
              </Link>
            </h2>
            <form
              className="billing__form"
              name="adform"
              id="addressForm"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                type="hidden"
                name="uid"
                defaultValue={profileDataItem && profileDataItem.id}
              />
              <div className="form__ctrl">
                <label>
                  <span className="form__label">Billing Name *</span>
                  <input
                    type="text"
                    name="billing_name"
                    id="billing_name"
                    placeholder="e.g. Ellen Baines"
                    defaultValue={
                      profileDataItem && profileDataItem.billing_name
                    }
                    ref={register}
                    onChange={(e) => {
                      setUser({ ...user, billing_name: e.target.value });
                      setS()
                    }
                    }
                  />
                </label>
                <div className="error__msg">{errors.billing_name?.message}</div>
              </div>
              {/* form__ctrl end */}

              <div className="form__ctrl">
                <label>
                  <span className="form__label">Address Line *</span>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="e.g. 12 Main St"
                    defaultValue={profileDataItem && profileDataItem.address}
                    ref={register}
                    onChange={(e) => {
                      setS();
                      setUser({ ...user, address: e.target.value });
                    }
                    }
                  />
                </label>
                <div className="error__msg">{errors.address?.message}</div>
              </div>
              {/* form__ctrl end */}

              <div className="form__group__wrapper">
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <label>
                      <span className="form__label">City *</span>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="e.g. Geneva"
                        defaultValue={profileDataItem && profileDataItem.city}
                        ref={register}
                        onChange={(e) => {
                          setUser({ ...user, city: e.target.value });
                          setS()
                        }
                        }
                      />
                    </label>
                    <div className="error__msg">{errors.city?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <label>
                      <span className="form__label">
                        State, province or region *
                      </span>
                      <input
                        type="text"
                        name="state"
                        id="state"
                        placeholder="e.g. NY"
                        defaultValue={
                          profileDataItem && profileDataItem.state_id
                        }
                        ref={register}
                        onChange={(e) => {
                          setUser({ ...user, state: e.target.value });
                          setS()
                        }
                        }
                      />
                    </label>
                    <div className="error__msg">{errors.state?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
              </div>
              {/* form__group__wrapper end */}

              <div className="form__group__wrapper">
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <label>
                      <span className="form__label">ZIP code *</span>
                      <input
                        type="text"
                        name="zip"
                        id="zip"
                        className={`form-control ${errors.zip ? "" : ""}`}
                        placeholder="e.g. 10024"
                        defaultValue={
                          profileDataItem && profileDataItem.zip_code
                        }
                        ref={register}
                        onChange={(e) => {
                          setUser({ ...user, zip: e.target.value });
                          setS()
                        }
                        }
                      />
                    </label>
                    <div className="error__msg">{errors.zip?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
                <div className="form__group__item">
                  <div className="form__ctrl">
                    <div className="label">
                      <span className="form__label">Country *</span>
                      <input
                        type="hidden"
                        name="country"
                        id="country"
                        ref={register}
                        value={props.billCountry}
                        onChange={(e) => {
                          setUser({ ...user, country: e.target.value });
                          setS()
                        }
                        }
                      />
                      <button
                        type="button"
                        id="billDdl"
                        className={
                          props.billDdl ? "ddl__btn active" : "ddl__btn"
                        }
                        onClick={props.HandleBillDdlOnClick}
                      >
                        {props.billCountry ? (
                          <span>{props.billCountry}</span>
                        ) : (
                          "Pick a country"
                        )}
                        <span className="btn__ddl__arrow">
                          <img
                            src={
                              require("../../assets/img/common-img/ddl-blue.svg")
                                .default
                            }
                            alt="true"
                          />
                        </span>
                      </button>
                      <div className="ddl__list__wrapper">
                        <div className="ddl__list__card">
                          <header>
                            <label>
                              <img
                                src={
                                  require("../../assets/img/common-img/search-icon.svg")
                                    .default
                                }
                                alt="true"
                              />
                              <input
                                onChange={props.HandleOnSearch}
                                type="text"
                                placeholder="Search"
                              />
                            </label>
                          </header>
                          <ul>
                            {props.contryList.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  onClick={() => {
                                    props.HandleBillDdlOnClick();
                                    props.HandleBillDdlValueOnClick(item.name);
                                    setS(item.name);
                                  }}
                                >
                                  {item.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="error__msg">{errors.country?.message}</div>
                  </div>
                  {/* form__ctrl end */}
                </div>
                {/* form__group__item end */}
              </div>
              {/* form__group__wrapper end */}

              {/* <div className="btn__next__wrapper">
								{btnState ? (
									<button  id="nextbtn" className="btn__next" onClick={updateAddress}>
										Next
									</button>
								) : (
									<button disabled type="submit" className="btn__next">
										Next
									</button>
								)}
							</div> */}
              <div className="btn__next__wrapper">
                {formState.isValid == false && (
                  <button disabled type="submit" className="btn__next">
                    Next
                  </button>
                )}
                {formState.isValid == true && (
                  <button id="nextbtn" className="btn__next" onClick={updateAddress}>
                    Next
                  </button>
                )}
              </div>
            </form>
          </div>
          {/* cart__page__items end */}
        </div>
      </div>
      {/* 
	  	  cart__page__right start 
		    cart summary with discount
		    Checkout button
	    */}
      <div className="cart__page__right">
        <div className="cart__page__box">
          {/* checkout section start */}
          <div className="train--cart-sec train__checkout--wrapp">
            <div className="card__rgt--header train-container flex-unset">
              <div className="train-row cart__head--wrapp cart__checkout--wrapp">
                <div className="train-col-lg-6 cart__sub--head cart__top--head cart__top--head-item">
                  Items
                </div>
              </div>
            </div>
            <div className="card__rgt--body train-container">
              <div className="train-row cart__head--wrapp train__course-cartdetail">
                <div className="train-col-lg-10 cart__sub--head cart__top--head">
                  Launch Offer Bundle
                  <span>1 year access</span>
                  <ul className="col_big_ul">
														<li><p className="normal__txt">17+ hours of content</p></li>
														<li><p className="normal__txt">Members only Full Access for 1 year</p></li>
														<li><p className="normal__txt">Only Until 31st December 2023</p></li>
									</ul>
                </div>
                <div className="train-col-lg-2 cart__sub--head cart__sub--head-rgt price-wto-dis"
                >
                  ${total}
                </div>
              </div>

            </div>
            <div className="card__rgt--footer train-container border-t-0">
              <div className="discount-row train-row">
                <div className="train-col-lg-6 discount-lft-text">Discount:</div>
                <div className="train-col-lg-6 discount-rgt-text">$2490</div>
              </div>
              <div className="train-row cart__head--wrapp cart__head--wrapp-total">
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-lgt">
                  Total:
                </div>
                <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                  ${total}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
