/** imported components

  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api

**/

import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import serviceProvider from '../../services/axios'
import $ from 'jquery';

//QuestionWalkthrough Component start
export const QuesWalkthroughView = (props) => {

  //defining variables here
  const user = JSON.parse(localStorage.getItem("userData"));
  const uid = user ? user.uid : "";
  const [sliderStatus, setSliderStatus] = useState(1);
  // Conditions for slider display
  const HandleSlider = (val) => {
    setSliderStatus(val);
  }
  //Function for close the button
  const handleClose = () => {
    serviceProvider
      .post(
        "/home?type=setquestion",
        JSON.stringify({ uid: uid }),
        true
      )
      .then((response) => {
        setSliderStatus(0);

      });
      let mainDiv=document.querySelector('.training-main-wrapper');
      let contentDiv=document.querySelector('.training-main-wrapper .training-content');
      if(mainDiv){
                      mainDiv.classList.remove('walkthrough--working');
                 }
      if(contentDiv){
                      contentDiv.classList.remove('content--no-scroll');
                    }
    //Close function
    var close = document.querySelector(".header-frame-wrapper");
    var close1 = document.querySelector(".walk-step-1");
    var close2 = document.querySelector(".walk-step-44");
    var close3 = document.querySelector(".div-z99");
    close?.classList.remove('walk-step-ul');
    close1?.classList.remove('walk-step-1');
    close2?.classList.remove('walk-step-44');
    close3?.classList.remove('div-z99');
  }
  // Conditions for walkthrough slider for navigate to previous to next , next to previous and class addition.
  useEffect(() => {
    var div = document.querySelector(".card-right")
    var span = document.querySelector(".multi-left-frame").contains(div);

    if (sliderStatus === 1) {
      document.querySelector('.header-frame-wrapper').classList.add('walk-step-ul');
      document.querySelector('.chapter-progress-wrapper').classList.add('walk-step-1');
    }
    else {
      document.querySelector('.header-frame-wrapper').classList.remove('walk-step-ul');
      document.querySelector('.chapter-progress-wrapper').classList.remove('walk-step-1');
    }
    if (sliderStatus === 2) {
      document.querySelector('.walk-step-2').classList.add('div-z99');
    }
    else {
      document.querySelector('.walk-step-2').classList.remove('div-z99');
    }
    if (sliderStatus === 3) {
      document.querySelector('.walk-step-3').classList.add('div-z99');
    }
    else {
      document.querySelector('.walk-step-3').classList.remove('div-z99');
    }
    if (sliderStatus === 4) {
      document.querySelector('.walk-step-4').classList.add('div-z99');
      if(div!=null){
        document.querySelector('.card-right').classList.add('walk-step-44');
      }
    }
    else {
      document.querySelector('.walk-step-4').classList.remove('div-z99');
      if(div!=null){
        document.querySelector('.card-right').classList.remove('walk-step-44');
      }
    }
    if (sliderStatus === 5) {
      document.querySelector('.walk-step-5').classList.add('div-z99');
    }
    else {
      document.querySelector('.walk-step-5').classList.remove('div-z99');
    }

  }, [sliderStatus]);

  //walkthrough js end here

  useEffect(() => {
      let mainDiv=document.querySelector('.training-main-wrapper');
      let contentDiv=document.querySelector('.training-main-wrapper .training-content');
      let headerFrame=document.querySelector('.header-frame-wrapper');
      if(mainDiv){
        mainDiv.classList.add('walkthrough--working');
      }
      if(contentDiv){
        contentDiv.classList.add('content--no-scroll');
      }
      return ()=>{
                   if(mainDiv){
                      mainDiv.classList.remove('walkthrough--working');
                    }
                    if(contentDiv){
                      contentDiv.classList.remove('content--no-scroll');
                    }
                    if(headerFrame){
                      headerFrame.classList.remove('walk-step-ul');
                    }
              }
  },[]);
  return (
    <>
      {/* link css */}
      <link rel="stylesheet" href={require('./ques-walkthrough.scss').default} />
      {/* link css end*/}
      <section className="general-information-walkthrough">
        <div className="general-information-top">
          {/*---------------------  Walkthrough Wrapper  ------------------------*/}
          <div className="general-info-walkthrough">
            {sliderStatus === 1 ?
              <div className="g-walkthrough-screen1 gstep">
                {/* walkthrough slider for questionniare page */}
                <div className="g-walkthrough-wrapper">
                  {/* This has been divided into two parts */}
                  <div className="g-walk-row">
                    {/* Left part is having image */}
                    <div className="g-walk-col-left">
                      <img src={require("../../assets/img/ques-walk-img/que-step1.gif").default} alt="Question Step 1" />
                    </div>
                    {/*  Right part is having text , buttons for navigation */}
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>1 of 5</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/ques-walk-img/close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Assessment Questionnaire​</h4>
                          <p className="text-top">Welcome to the questionnaire.</p>
                          <p className="text" style={{margin: 0}}> Use the top navigation to move around and track your progress from the top right interface.</p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(4) }} disabled={sliderStatus === 4 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(5) }} disabled={sliderStatus === 5 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button className="prev-link link" onClick={handleClose}>Exit Walkthrough</button>
                              <button onClick={() => { HandleSlider(2) }} className="let-link link solid">Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {sliderStatus === 2 ?
              <div className="g-walkthrough-screen2 gstep">
                {/* walkthrough slider for questionniare page */}
                <div className="g-walkthrough-wrapper">
                  {/* This has been divided into two parts */}
                  <div className="g-walk-row">
                    {/* Left part is having image */}
                    <div className="g-walk-col-left"><img src={require("../../assets/img/ques-walk-img/que-step2.gif").default} alt="Question Walkthrough" className="close-img" /></div>
                    {/*  Right part is having text , buttons for navigation */}
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>2 of 5</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/ques-walk-img/close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Assessment Question</h4>
                          {/*comment2*/}
                          <p className="text-top">You will need to answer different combinations of questions and answers.</p>
                          <p className="text">Mind that in some cases you can select multiple answers.</p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(4) }} disabled={sliderStatus === 4 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(5) }} disabled={sliderStatus === 5 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button onClick={() => { HandleSlider(1) }} className="prev-link link">Previous</button>
                              <button onClick={() => { HandleSlider(3) }} className="nxt-link link solid">Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {sliderStatus === 3 ?
              <div className="g-walkthrough-screen3 gstep">
                {/* walkthrough slider for questionniare page */}
                <div className="g-walkthrough-wrapper">
                  {/* This has been divided into two parts */}
                  <div className="g-walk-row">
                    {/* Left part is having image */}  
                    <div className="g-walk-col-left"><img src={require("../../assets/img/ques-walk-img/que-step3.gif").default} alt="Question Walkthrough" className="close-img" /></div>
                    {/*  Right part is having text , buttons for navigation */}
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>3 of 5</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/ques-walk-img/close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Scroll Bar</h4>
                          <p className="text">Please note that in some cases you need to scroll down to see all the available answers.</p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(4) }} disabled={sliderStatus === 4 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(5) }} disabled={sliderStatus === 5 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button onClick={() => { HandleSlider(2) }} className="prev-link link">Previous</button>
                              <button onClick={() => { HandleSlider(4) }} className="nxt-link link solid">Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {sliderStatus === 4 ?
              <div className="g-walkthrough-screen4 gstep">
                {/* walkthrough slider for questionniare page */}
                <div className="g-walkthrough-wrapper">
                  {/* This has been divided into two parts */}
                  <div className="g-walk-row">
                    {/* Left part is having image */} 
                    <div className="g-walk-col-left"><img src={require("../../assets/img/ques-walk-img/que-step4.gif").default} alt="Question Walkthrough" className="close-img" /></div>
                    {/*  Right part is having text , buttons for navigation */}
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>4 of 5</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/ques-walk-img/close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Help</h4>
                          <p className="text-top">You can click the i icon for more information.</p>
                          <p className="text">If that is not enough, help is one click away; just click the support button.</p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(4) }} disabled={sliderStatus === 4 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(5) }} disabled={sliderStatus === 5 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button onClick={() => { HandleSlider(3) }} className="prev-link link">Previous</button>
                              <button onClick={() => { HandleSlider(5) }} className="nxt-link link solid">Next</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            {sliderStatus === 5 ?
              <div className="g-walkthrough-screen5 gstep">
                {/* walkthrough slider for questionniare page */}
                <div className="g-walkthrough-wrapper">
                  {/* This has been divided into two parts */}
                  <div className="g-walk-row">
                    {/* Left part is having image */} 
                    <div className="g-walk-col-left"><img src={require("../../assets/img/ques-walk-img/que-step5.gif").default} alt="Question Walkthrough" className="close-img" /></div>
                    {/*  Right part is having text , buttons for navigation */}
                    <div className="g-walk-col-right">
                      <div className="col-right-card-wrap">
                        <div className="card-header">
                          <div className="left-text-wrap">
                            <p>5 of 5</p>
                          </div>
                          <div className="close-btn">
                            <button className="close" onClick={handleClose}><img src={require("../../assets/img/ques-walk-img/close.svg").default} alt="digital_maturity" /></button>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="heading">Navigate Between Questions</h4>
                          <p className="text-top">Use this interface to navigate through the questionnaire. Your answers are saved only by clicking the Next button.</p>
                          <p className="text"><b>You are ready to start now!</b></p>
                        </div>
                        <div className="card-footer">
                          <div className="card-footer-section">
                            <div className="card-footer-left">
                              <button className="circle" onClick={() => { HandleSlider(1) }} disabled={sliderStatus === 1 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(2) }} disabled={sliderStatus === 2 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(3) }} disabled={sliderStatus === 3 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(4) }} disabled={sliderStatus === 4 ? true : false}></button>
                              <button className="circle" onClick={() => { HandleSlider(5) }} disabled={sliderStatus === 5 ? true : false}></button>
                            </div>
                            <div className="card-footer-right">
                              <button onClick={() => { HandleSlider(1) }} className="prev-link link">Restart</button>
                              <button onClick={handleClose} className="nxt-link link solid">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : null}
          </div>
        </div>
      </section>
    </>
  );
};


