
/** imported components

  * QuestionPlantView ===> view  component for this page.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * question.scss ===> for styling of the page.
  * serviceProvider for ===> working with api

**/

import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { useHistory, Link, Redirect } from "react-router-dom";
import $ from "jquery";
import { QuestionPlantView } from "./question-plant-view";
import "./question.scss";

//QuestionPlant component start
export const QuestionsPlantController = (props) => {
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const segments = window.location.pathname.slice(1).split("/");
	const usid = user ? user.uid : 0;
	const [hashvalue, setHashvalue] = useState(window.location.hash);
	const slug = props.match.params.slug;
	const [scid, setScid] = useState(segments[3]);
	// console.log(scid);
	const [qid, setQid] = useState(typeof segments[4] !== "undefined" ? segments[4] : 0);
	useEffect(() => {
		const search = async () => {
			const apiUrl = `/assessmentmaturity?param=start&slug=${slug}&scid=${scid}&qid=${qid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
			
		};
		search();
		
		return () => {
			setResults([]);
		};
	}, [qid]);
	if (results.length) {
		
		if(results[0].noques == 0){
           		        var pp = `/assessment/start/${slug}/` + results[0].getNextChapterId;
				        window.location.href = pp;
						return (
								<>
									<div></div>
								</>
						);

		}else{
			
			if((hashvalue != '' && hashvalue != null) && (results[0].is_datasubmitted == 1 || results[0].is_result==1 || results[0].is_validated==0 || results[0].is_locked==1)){
				var pp = "/not-found";
				        return <Redirect to="/not-found" />;
						return (
								<>
									<div></div>
								</>
						);
			}else{
				  if(results[0].block == 'block'){
					  
					  return <Redirect to="/user-unassign" />;
						return (
								<>
									<div></div>
								</>
						);
				}else if(results[0].block == 'invalid_plant'){			
					return <Redirect to="/404" />;
						return (
								<>
									<div></div>
								</>
						);				
						
						
					  }else{
						return <QuestionPlantView slug={slug} hashvalue={hashvalue} setHashvalue={setHashvalue} quesData={results} onScid={setScid} onQid={setQid} {...props} />;
				  }
			}
			
			}
	}else{
		return (
		<>
			<div></div>
		</>
	);
		
		}
    
	
	
	
	
};
