import React, { useState, useEffect } from "react";
import { RoadmapStep1View } from './RoadmapStep1View'
import { Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios';

export const RoadmapStep1Ctrl = (props) => {

    const [results, setResults] = useState(false);
    const [status, setStatus] = useState(false);
    const [filter, setfilter] = useState(0);
    const user = JSON.parse(localStorage.getItem("userData"));
    const login = localStorage.getItem("isLoggedIn");
    const uid = user ? user.uid : 0;
    const progress = user ? user.progress : 0;
    const gid = user ? user.group_id : 0;
    if (!login) {
        return <Redirect to="/sign-in" />;
    }
    if (progress<100 && (gid == 14 || gid == 2)) {
         return <Redirect to={`/assessment/${plant_slug}`} />;
    }
    const segments = window.location.pathname.slice(1).split("/");
    const plant_slug = (typeof segments[2] !== "undefined" ? segments[2] : 0);
    const chapter_id = (typeof segments[3] !== "undefined" ? segments[3] : 0);


    useEffect(() => {
      const search = async () => {
          const apiUrl = `/roadmap?param=step1&uid=${uid}&slug=${plant_slug}&filter=${filter}&sid=${chapter_id}`;
          const { data } = await serviceProvider.get(apiUrl, true, {});
          setResults(data);
      };
      search();
  }, [status,filter]);

  if(results) {
          return (
            <>
              <RoadmapStep1View setStatus={setStatus} setfilter={setfilter} stepData={results} {...props} />
            </>
          );
   }else{
        return (
            <>
            <div></div>
            </>
        );
   }
  
}
