import React, { useState, useEffect } from 'react';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios'
import { 
    MainWrapper,
    ContentWrapper,
    LeftWrapper,
    RightWrapper,
    RightSectionWrapper,
    TopWrapper,
    CostWrapper,
    KPIWrapper,
    PlanWrapper,
    PointWrapper,
    CompanyRightHeader, 
    CompanyRightBody, 
    CompanyRightCard,
    BlankSvgWrapper,
    ManuFacturingHeader,
    ManuFacturingBody,
    AssignedUser,
    DropUserCard,
    ReminderPopup,
    TabHeadWrap,
    ManufactorTab,
    ManufactorTabContent,
    DimensionTabContent
} from './styles';
import {dotSvg,blank_svg,reminder_icon,tickSvg} from'./svg'
import { Link } from 'react-router-dom/cjs/react-router-dom';
export const CompanyDetailView = (props) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const datacompany = props.results[0];
    const costField = datacompany.costField;
    const kpi_selection = datacompany.kpi;
    const rightListArry = datacompany.banding;
    const plant_id = datacompany.plant_id;
    const plant_name = datacompany.plant_name;
    const company_id = datacompany.company_id;
    const plant_slug = datacompany.plant_slug;
    const comp_age_prof = datacompany.comp_age_prof;
    const industry_name =  datacompany.industry_name
    const products =  datacompany.products
    const postalcode = datacompany.postalcode;
    const reminded_name = user ? user.name : 0;
    const invited_by = user ? user.uid : 0;
    const phone_number = datacompany.company_phone_number;
    // Variables for manufactouring
    const manufactorArray = datacompany.assessment;
    const [activeDropdown, setActiveDropdown] = useState(false);
    const [isRemiderPopup, setIsRemiderPopup] = useState(false);
    const [isSuccess, setIsSuccess] = useState({});
    const [isManufactouring, setIsManufactouring] = useState(1);
    const [isActive, setIsActive] = useState(1);
    const handleAssignPopup =(index)=>{
        console.log(`index`,index);
        if(activeDropdown===index){
            setActiveDropdown(false);
        }
        else{
            setActiveDropdown(index);
        }
    }
    const handleReminder = (item) => {
        
        setIsRemiderPopup(true);
        setTimeout(() => {
            setIsRemiderPopup(false);
        }, 2000);
        setIsSuccess(prv=>{
            return{
                ...prv,
                [item.id]:true
            }
        });
        const result = serviceProvider.get(`/plants/mntaskreminder?plant_id=${plant_id}&plant_slug=${plant_slug}&plant_name=${plant_name}&section_id=${item.id}&user_id=${company_id}&section_name=${item.name}&reminded_name=${reminded_name}&invited_by=${invited_by}`);
    }
    // To handle right section tabs
    const handleManufactorTab = (index) => {
      setIsActive(index);
    };
    useEffect(()=>{
        document.addEventListener(`click`,(e)=>{
            if(!e.target.closest(`.assigned-to-cir-wrapper`)){
                setActiveDropdown(false);
            }
        })
        return()=>{
            document.removeEventListener(`click`,(e)=>{
                if(!e.target.closest(`.assigned-to-cir-wrapper`)){
                    setActiveDropdown(false);
                }
            })
        }
    },[])
    return (
      <MainWrapper data-component="MainWrapper">
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
        <BreadcrumbsItem to="#">
          Company Details - {datacompany.factory_name}
        </BreadcrumbsItem>
        <Helmet>
          <title>Company Detail | SmarterChains</title>
          <meta name="description" content="Company Detail" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <ContentWrapper data-component="ContentWrapper">
          <LeftWrapper data-component="LeftWrapper">
            <TopWrapper data-component="TopWrapper">
              <div className="top-row">
                <span className="bold-text">Factory Name</span>
                <span className="normal-text">{datacompany.factory_name}</span>
              </div>
              <div className="top-row">
                <span className="bold-text">CR Number</span>
                <span className="normal-text">{datacompany.crnumber}</span>
              </div>
              <div className="top-row">
                <span className="bold-text">Annual revenue(in millions)</span>
                <span className="normal-text">
                  $ {datacompany.annual_revenue}M
                </span>
              </div>
              <div className="top-row">
                <span className="bold-text">Number of Employees</span>
                <span className="normal-text">{datacompany.num_of_emp}</span>
              </div>
              <div className="top-row">
                <span className="bold-text">
                  Company Age profile (in years)
                </span>
                <span className="normal-text">
                  {comp_age_prof ? comp_age_prof : "NA"}
                </span>
              </div>
              <div className="top-row">
                <span className="bold-text">
                   Industry Sector
                </span>
                <span className="normal-text">
                  {industry_name ? industry_name : "NA"}
                </span>
              </div>
              <div className="top-row">
                <span className="bold-text">
                Products  
                </span>
                <span className="normal-text">
                  {products ? products : "NA"}
                </span>
              </div>
            </TopWrapper>
            <CostWrapper data-component="CostWrapper">
              <h2 className="bold-text">
                Cost Profile (Cost Profile for Assessment)
              </h2>
              <p className="cost-text">
                The Cost Profile helps to Analyse how distinct Industry 4.0
                areas affect profits and identify those that can generate the
                greatest financial return. The Cost profile is critical to the
                 assessment score and must be completed.
              </p>
              {costField.map((item, index) => (
                <div className="cost-row" key={index}>
                  <div className="p-bold">
                    <i
                      dangerouslySetInnerHTML={{ __html: dotSvg }}
                      className="dot-svg"
                    ></i>
                    <span className="p-text">{item.cost}</span>
                  </div>
                  <span className="p-normal">{item.percent_val}</span>
                </div>
              ))}
            </CostWrapper>
            <KPIWrapper data-component="KPIWrapper">
              <h2 className="bold-text">KPIs Selected</h2>
              <ul className="kpi-list">
                {kpi_selection?.map((item, index) => (
                  <li key={index}>
                    <i
                      dangerouslySetInnerHTML={{ __html: dotSvg }}
                      className="dot-svg"
                    ></i>
                    <span>{item.name}</span>
                  </li>
                ))}
              </ul>
            </KPIWrapper>
            <PlanWrapper data-component="PlanWrapper">
              <h2 className="bold-text">Planning horizon</h2>
              <span className="normal-text">
                {datacompany.planning_horizon}
              </span>
            </PlanWrapper>
            <PointWrapper data-component="PointWrapper">
              <h2 className="bold-text">Point of contact details</h2>
              <div className="point-row">
                <div className="p-bold">
                  <i
                    dangerouslySetInnerHTML={{ __html: dotSvg }}
                    className="dot-svg"
                  ></i>
                  <span className="p-text">Name</span>
                </div>
                <span className="p-normal">{datacompany.name}</span>
              </div>
              <div className="point-row">
                <div className="p-bold">
                  <i
                    dangerouslySetInnerHTML={{ __html: dotSvg }}
                    className="dot-svg"
                  ></i>
                  <span className="p-text">Job Title</span>
                </div>
                <span className="p-normal">{datacompany.job_title}</span>
              </div>
              <div className="point-row">
                <div className="p-bold">
                  <i
                    dangerouslySetInnerHTML={{ __html: dotSvg }}
                    className="dot-svg"
                  ></i>
                  <span className="p-text">Email ID</span>
                </div>
                <span className="p-normal">{datacompany.email}</span>
              </div>
              <div className="point-row">
                <div className="p-bold">
                  <i
                    dangerouslySetInnerHTML={{ __html: dotSvg }}
                    className="dot-svg"
                  ></i>
                  <span className="p-text">Company Address</span>
                </div>
                <span className="p-normal">{datacompany.address}</span>
              </div>
              <div className="point-row">
                <div className="p-bold">
                  <i
                    dangerouslySetInnerHTML={{ __html: dotSvg }}
                    className="dot-svg"
                  ></i>
                  <span className="p-text">Postal Code</span>
                </div>
                <span className="p-normal">
                  {postalcode ? postalcode : "NA"}
                </span>
              </div>
              <div className="point-row">
                <div className="p-bold">
                  <i
                    dangerouslySetInnerHTML={{ __html: dotSvg }}
                    className="dot-svg"
                  ></i>
                  <span className="p-text">Company Phone Number</span>
                </div>
                <span className="p-normal">
                  {phone_number ? phone_number : "NA"}
                </span>
              </div>
            </PointWrapper>
          </LeftWrapper>
          <RightWrapper data-component="RightWrapper">
            <RightSectionWrapper data-component="RightSectionWrapper">
              <TabHeadWrap data-component="TabHeadWrap">
                  <ManufactorTab
                      data-component="ManufactorTab"
                      className={isActive == 1 ? "active" : ""}
                      onClick={(e) => handleManufactorTab(1)}
                  >
                      Manufacturer Tasks
                  </ManufactorTab>
                  <ManufactorTab
                      data-component="ManufactorTab"
                      className={isActive == 2 ? "active" : ""}
                      onClick={(e) => handleManufactorTab(2)}
                  >
                      Banding of 16 dimension
                  </ManufactorTab>
              </TabHeadWrap>
              <DimensionTabContent data-component="DimensionTabContent" open={isActive == 2 ? "open" : ""}>
                
                <CompanyRightBody data-component="CompanyRightBody">
                  {rightListArry.map((item, ind) => (
                    <CompanyRightCard
                      data-component="CompanyRightCard"
                      key={ind}
                    >
                      <h2 className="card-h1">
                        <div className="band-wrapper">
                          <span className="card-title">
                            <i
                              className="dim-count"
                              style={{ fontStyle: "normal" }}
                            >
                              {ind + 1}.
                            </i>{" "}
                            {item.title}
                          </span>
                          <h3 className="card-h2">
                            {item.band}:{item.band_name}
                          </h3>
                        </div>
                        <div className="status-wrapper">
                          <span
                            className={`status ${
                              item.status == 1
                                ? `inprogress`
                                : item.status == 2
                                ? `validated`
                                : `todo`
                            }`}
                          >
                            {item.status == 1
                              ? `In Progress`
                              : item.status == 2
                              ? `Validated`
                              : `To Do`}
                          </span>
                          <Link
                            to={`/siri-dimension/${item.link}/${item.slug}`}
                            className="view-btn"
                          >
                            View
                          </Link>
                        </div>
                      </h2>

                      {item.description && (
                        <>
                          <h4 className="card-h3">Assessors Note:</h4>
                          <div className="card-description">
                            {item.description}
                          </div>
                        </>
                      )}
                    </CompanyRightCard>
                  ))}
                </CompanyRightBody>
              </DimensionTabContent>
              <ManufactorTabContent data-component="ManufactorTabContent" open={isActive == 1 ? "open" : ""}>
                  {/* Code for Manufactouring Task Center */}
                 
                  <ManuFacturingBody data-component="ManuFacturingBody">
                    <div className="main-row sm-hide">
                      <div className="main-col">
                        <span>Assessment Sections</span>
                      </div>
                      <div className="status-col">
                        <span>Status</span>
                      </div>
                      <div className="assign-col">
                        <span>Assigned To</span>
                      </div>
                    </div>
                    {manufactorArray.map((item, index) => (
                      <>
                        <div className="bottom-row" key={index}>
                          <div className="main-col">
                            <span className="sm-title">
                              Assessment Sections
                            </span>
                            <span>
                              {index + 1}. {item.name}
                            </span>
                          </div>
                          <div className="status-col">
                            <span className="sm-title">Status</span>
                            <span
                              className={`status ${
                                item.status === `completed`
                                  ? `completed`
                                  : item.status === `inprogress`
                                  ? `inprogress`
                                  : `todo`
                              }`}
                            >
                              {item.status === `completed`
                                ? `Completed`
                                : item.status === `inprogress`
                                ? `In Progress`
                                : `To Do`}
                            </span>
                          </div>
                          <div className="assign-col">
                            <span className="sm-title">Assigned To</span>
                            {item?.invited_list?.length > 0 ? (
                              <div className="assigned-to-cir-wrapper">
                                {item?.invited_list
                                  ?.slice(0, 2)
                                  ?.map((invt_list, index) => (
                                    <AssignedUser
                                      key={index}
                                      data-component="AssignedUser"
                                      onClick={() => handleAssignPopup(item.id)}
                                    >
                                      <i
                                        className={`${
                                          invt_list?.profile_pic
                                            ? "cir-transparent"
                                            : invt_list?.avcolor
                                        }`}
                                      >
                                        {!invt_list.profile_pic ? (
                                          <div
                                            className={`taskcenter_assigned_sname`}
                                          >
                                            {invt_list.surname
                                              ? invt_list.name[0] +
                                                invt_list.surname[0]
                                              : invt_list.email[0]}
                                          </div>
                                        ) : (
                                          <img
                                            className="taskcenter_assigned__img_cir"
                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${invt_list.profile_pic}`}
                                            alt={invt_list.profile_pic}
                                          />
                                        )}
                                      </i>
                                    </AssignedUser>
                                  ))}
                                {item?.invited_list?.length > 2 && (
                                  <span
                                    className="assigned-to-count"
                                    onClick={() => handleAssignPopup(item.id)}
                                  >
                                    +
                                    {
                                      item?.invited_list?.slice(
                                        2,
                                        item?.invited_list?.length
                                      )?.length
                                    }
                                  </span>
                                )}
                                <span
                                  className={`tooltip ${
                                    activeDropdown === item.id ? "active" : ""
                                  }`}
                                >
                                  View users
                                </span>

                                <DropUserCard
                                  data-component="DropUserCard"
                                  active={activeDropdown === item.id}
                                >
                                  <div className="max-height-container">
                                    <div className="user-listwrap">
                                      {/* user1 */}
                                      {item?.invited_list?.map(
                                        (invt_list, index) => (
                                          <AssignedUser
                                            data-component="AssignedUser"
                                            key={index}
                                          >
                                            <i
                                              className={`${
                                                invt_list?.profile_pic
                                                  ? "cir-transparent"
                                                  : invt_list?.avcolor
                                              }`}
                                            >
                                              {!invt_list.profile_pic ? (
                                                <div
                                                  className={`taskcenter_assigned_sname`}
                                                >
                                                  {invt_list.surname
                                                    ? invt_list.name[0] +
                                                      invt_list.surname[0]
                                                    : invt_list.email[0]}
                                                </div>
                                              ) : (
                                                <img
                                                  className="taskcenter_assigned__img_cir"
                                                  src={`${apiEndpoints.baseUrl}/assets/user-profile/${invt_list.profile_pic}`}
                                                  alt={invt_list.profile_pic}
                                                />
                                              )}
                                            </i>

                                            <div className="txt-wrap">
                                              <span className="head">
                                                {invt_list.surname
                                                  ? invt_list.name
                                                  : invt_list.email}
                                              </span>
                                              <span className="sub-txt">
                                                {invt_list.role}{" "}
                                              </span>
                                            </div>
                                          </AssignedUser>
                                        )
                                      )}
                                      {/* user1 */}
                                    </div>
                                  </div>
                                </DropUserCard>
                              </div>
                            ) : (
                              <div className="assigned-to-cir-wrapper">
                                <p>Not Yet</p>
                              </div>
                            )}
                          </div>
                        </div>
                        {(item.status === `todo` ||
                          item.status === `inprogress`) && (
                          <div className="reminder-row">
                            {!isSuccess[item.id] ? (
                              <button
                                className="reminder-btn"
                                onClick={() => handleReminder(item)}
                              >
                                <span>Send Reminder</span>
                                <i
                                  dangerouslySetInnerHTML={{
                                    __html: reminder_icon,
                                  }}
                                ></i>
                              </button>
                            ) : (
                              <button className="reminder-btn green">
                                <i
                                  dangerouslySetInnerHTML={{ __html: tickSvg }}
                                ></i>
                                <span>Reminder Sent</span>
                              </button>
                            )}
                          </div>
                        )}
                      </>
                    ))}
                  </ManuFacturingBody>
                  {/* End */}
              </ManufactorTabContent>
            </RightSectionWrapper>
          </RightWrapper>
        </ContentWrapper>
        {/* Reminder Popup */}
        {isRemiderPopup && (
          <ReminderPopup data-component="ReminderPopup">
            <div className="reminder-success-img">
              <img
                src={
                  require("../../assets/img/skillsassessment/reminder-success.gif")
                    .default
                }
              />
            </div>
            <p>Email reminder sent successfully</p>
          </ReminderPopup>
        )}
      </MainWrapper>
    );
}