/** imported components

  * serviceProvider for ===> working with api.
  * CartContext for ==> add item into the cart.
  * React Stripe for ==> for Stripe is a payment service.

**/
import React, { useContext, useMemo, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import serviceProvider from "../../services/axios";
import { CartContext } from "../../contexts/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { logEvent, Result, ErrorResult } from "../../webutils/util";
// Billing data from local storage
const billingData = JSON.parse(localStorage.getItem("billingData"));
// Styling
const CARD_ELEMENT_OPTIONS = {
  placeholder: "CVC / CVV",
  style: {
    base: {
      lineHeight: "45px",
      padding: "17px",
      fontWeight: "normal",
      fontSize: "15px",
      color: "#4d4d4f",
      outline: "0",
      backgroundColor: "#ffffff",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
    showIcon: true,
    iconStyle: "solid",
    iconPosition: "right",
  },
};
const ELEMENT_OPTIONS = {
  style: {
    base: {
      lineHeight: "45px",
      padding: "17px",
      fontWeight: "normal",
      fontSize: "15px",
      color: "#4d4d4f",
      outline: "0",
      backgroundColor: "#ffffff",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
  showIcon: true,
  iconStyle: "solid",
  iconPosition: "right",
};
const cardEv = () => (event) => {
  console.log(event.brand);
};
// Function for Check out
const CheckoutForm = () => {
  const elements = useElements();
  const stripe = useStripe();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [name, setName] = useState("");
  const [db, setDb] = useState(true);

  const [postal, setPostal] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { cartItems, clearCart } = useContext(CartContext);
  const {
    total,
    discount,
    discountPrice,
    itemCount,
    increase,
    decrease,
    removeProduct,
  } = useContext(CartContext);
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setDb(false);
    const cardElement = elements.getElement(CardNumberElement);
    const res = await stripe.createToken(cardElement);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name,
        address: {
          postal_code: postal,
        },
      },
    });

    if (payload.error) {
      console.log("[error]", payload.error);
      setErrorMessage(payload.error.message);
      setPaymentMethod(null);
      setDb(true);
    } else {
      serviceProvider
        .post(
          "/cart?type=maturityassesbuy",
          JSON.stringify({
            cart: localStorage.getItem("cart"),
            billingData: localStorage.getItem("billingData"),
            user_id: userData.uid,
            tkp: res.token.id,
            email: userData.email,
            payload: payload.paymentMethod.id,
            total: { total },
            discount: { discount },
            discountPrice: { discountPrice },
          }),
          true
        )
        .then((response) => {
          if (response.data.status === 200) {
            clearCart();
            localStorage.setItem("payData", JSON.stringify(response.data.data));
            window.location = "/paymentmat_success";
          }
          if (response.data.status === "failed") {
          }
        });
      //setPaymentMethod(payload.paymentMethod);
      setErrorMessage(null);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment__form">
      {/* Card detail, Billing Info form start */}
      <div className="form__ctrl">
        <label>
          <span className="form__label">Name on Card *</span>

          <input
            id="name"
            name="name"
            required
            placeholder="Jenny Rosen"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <input id="postal" name="postal" type="hidden" value="" />
        </label>
        <div className="error__msg"></div>
      </div>
      {/* Payment details form start */}
      <div className="form__ctrl custom_form_ctrl">
        <label>
          <span className="form__label">Card Number *</span>
          <CardNumberElement
            id="cardNumber"
            onBlur={logEvent("blur")}
            onChange={cardEv("change")}
            onFocus={logEvent("focus")}
            onReady={logEvent("ready")}
            options={ELEMENT_OPTIONS}
          />
        </label>
      </div>
      {/* Expiry & CVV form */}
      <div className="form__group__wrapper">
        <div className="form__group__item">
          <div className="form__ctrl custom_form_ctrl">
            <label>
              <span className="form__label">Expiry Date *</span>
              <CardExpiryElement
                id="expiry"
                onBlur={logEvent("blur")}
                onChange={logEvent("change")}
                onFocus={logEvent("focus")}
                onReady={logEvent("ready")}
                options={ELEMENT_OPTIONS}
              />
            </label>
          </div>
        </div>
        <div className="form__group__item">
          <div className="form__ctrl custom_form_ctrl">
            <label>
              <span className="form__label">CVC / CVV *</span>
              <CardCvcElement
                id="cvc"
                onBlur={logEvent("blur")}
                onChange={logEvent("change")}
                onFocus={logEvent("focus")}
                onReady={logEvent("ready")}
                options={CARD_ELEMENT_OPTIONS}
              />
            </label>
          </div>
        </div>
      </div>
      {/* Pay now button */}
      {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
      {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>}
      <div className="btn__next__wrapper">
        <button type="submit" className="btn__next" disabled={!stripe || !db}>
          Pay Now
        </button>
      </div>
    </form>
  );
};
// loading stripe
const stripePromise = loadStripe("pk_test_yYhf8Q955oL7kPFJCfV3kHXb00PZXm1ZDK");
// CartPaymentViewMaturity start
export const CartPaymentViewMaturity = (props) => {
  const { cartItems } = useContext(CartContext);
  const {
    total,
    discount,
    discountPrice,
    itemCount,
    increase,
    decrease,
    removeProduct,
  } = useContext(CartContext);

  const [totalPaid, setTotalPaid] = useState(0);
  const user1 = JSON.parse(localStorage.getItem("userData"));
  const usid = user1 ? user1.uid : 0;
  useEffect(() => {
    const search = async () => {
      const apiUrl = `/home?param=paiditem&uid=${usid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setTotalPaid(data);
    };
    search();
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <div className="cart__page__wrapper">
        <div className="cart__page__left">
          <div className="cart__time__line">
            <div className="time__line__item complete">
              {" "}
              {/*add complete class when completed*/}
              <span>My cart</span>
            </div>
            <div className="time__line__item complete">
              <span>Billing Info</span>
            </div>
            <div className="time__line__item inprogress">
              <span>Payment</span>
            </div>
          </div>
          {/* cart__time__line end */}
          <div className="cart__left__content">
            <div className="payment__items">
              <h2 className="h1">
                <span>Payment details</span>
                <Link to="/billingmaturity" className="cart__back__link">
                  <img
                    src={
                      require("../../assets/img/cart-page/back-arrow.svg")
                        .default
                    }
                    alt="true"
                  />
                  Back
                </Link>
              </h2>

              <CheckoutForm />
            </div>
            {/*payment__items end */}
          </div>
        </div>
        {/* cart__page__left end */}
        <div className="cart__page__right">
          <div className="cart__page__box">
            {/* checkout section start */}
            <div className="train--cart-sec train__checkout--wrapp">
              <div className="card__rgt--header train-container">
                <div className="train-row cart__head--wrapp cart__checkout--wrapp">
                  <div className="train-col-lg-6 cart__sub--head cart__top--head cart__top--head-item">
                    Items
                  </div>
                  <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                    <Link to="/cart" className="cart__sub--head-edit">
                      Edit cart
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card__rgt--body train-container">
                {cartItems.map((product) => (
                  <div className="train-row cart__head--wrapp train__course-cartdetail">
                    <div className="train-col-lg-10 cart__sub--head cart__top--head">
                      {product.course_name}
                      {/* <span>Training Course</span> */}
                    </div>
                    <div
                      className={
                        discount > 0
                          ? "train-col-lg-2 cart__sub--head cart__sub--head-rgt "
                          : "train-col-lg-2 cart__sub--head cart__sub--head-rgt price-wto-dis"
                      }
                    >
                      €{((product.price * (100 - discount)) / 100).toFixed(2)}
                      {discount > 0 && (
                        <span className="cart__discount--value">
                          €{product.price}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                {/* {totalPaid<=0 &&  (
                                <div className="train-row cart__head--wrapp train__course-cartdetail">
                                    <div className="train-col-lg-10 cart__sub--head cart__top--head">
                                       Digital Maturity Assessment - Limited
										<span>Assessment of Industry 4.0 Vision</span>
                                    </div>
                                    <div className="train-col-lg-2 cart__sub--head cart__sub--head-rgt">
                                        <span className="cart__discount--value train__value--free">
                                            FREE
                                        </span>
                                    </div>
                                </div>
								)}
								{totalPaid<=0 &&  (
                                <div className="train-row cart__head--wrapp">
                                    <div className="train-col-lg-10 cart__sub--head cart__top--head">
                                       Results: Digital Maturity Assessment - Limited
										<span>Results of Factory of the Future Leadership</span>
                                    </div>
                                    <div className="train-col-lg-2 cart__sub--head cart__sub--head-rgt">
                                        <span className="cart__discount--value train__value--free">
                                            FREE
                                        </span>
                                    </div>
                                </div>
								)} */}
              </div>
              <div className="card__rgt--footer train-container">
                <div className="train-row cart__head--wrapp cart__head--wrapp-total">
                  <div className="train-col-lg-6 cart__sub--head cart__sub--head-lgt">
                    Total:
                  </div>
                  <div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">
                    €{discountPrice}
                    {discount > 0 && (
                      <span className="cart__discount--value train__value--free">
                        €{total}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* cart__page__right end */}
      </div>{" "}
    </Elements>
  );
};
