/** imported components

  * TrainingCardControler ===> component controller.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/
import React, { useState, useEffect } from "react";
import { TrainingCardControler } from "../training-card/training-card-ctrl";
//TrainingsView component start
export const TrainingsView = ({ props }) => {
	//defining variables and conditions here
	// if (!trainingData.length) {
	// 	return null;
	// }
	let trainingData=  [
            {
                "id": "11",
                "certificate_ref_id": "1",
                "course_name": "Introduction to Industry 4.0",
                "course_slug": "introduction-to-industry-40",
                "course_icon": "course-symb.svg",
                "course_snippet": "Our introductory course intends to make participants become aware of the philosophy evolution and prepare teams for the digital transformation journey, creating excitement and a pull approach.",
                "course_technology": "",
                "course_description": "An introduction to Industry 4.0 disruption and its effect in modern manufacturing.<br\/><br\/>Our introductory course intends to make participants become aware of the philosophy evolution and prepare teams for the digital transformation journey, creating excitement and a pull approach.",
                "course_image": "Introduction-to-Industry-40-Training.png",
                "course_instructor": "1",
                "course_order": "1",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/introduction-to-industry-40",
                "price": "0",
                "meta_desc": "Industry 4.0 introductory course intends to make you become aware of the philosophy evolution and prepare teams for the digital transformation journey, creating excitement and a pull approach.​",
                "meta_img": "Introduction-to-Industry-40-Training.png\n",
                "is_imd": "1",
                "is_active": "1",
                "total_sec_dur": "3142",
                "firstVideo": "11\/44.mp4",
                "totalseccount": 12,
                "totalcompseccount": "0",
                "cardStatus": "",
                "has_paid": 1
            },
            {
                "id": "1",
                "certificate_ref_id": "2",
                "course_name": "Factory of the Future Leadership",
                "course_slug": "factory-of-the-future-leadership",
                "course_icon": "fof-leadership.svg",
                "course_snippet": "Participants will learn various aspects that enable effective execution, such as being visionary and inspiring, strategic, and agile while always focusing to an end to end approach to digital transformation.",
                "course_technology": "",
                "course_description": "Leadership behaviours and strategies that inspire, support and offer guidance in order to accelerate and guarantee the digital transformation’s success across the organization.<br\/><br\/>For effective execution, leaders need to master various aspects such as being visionary and inspiring, strategic, and agile while always focus to an end to end approach to digital transformation.",
                "course_image": "Factory-of-the-Future-Leadership-Training.png",
                "course_instructor": "1",
                "course_order": "2",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/factory-of-the-future-leadership",
                "price": "100",
                "meta_desc": "For effective execution, leaders need to master various aspects such as being visionary and inspiring, strategic, and agile while always focus to an end-to-end approach to digital transformation.​",
                "meta_img": "Factory-of-the-Future-Leadership-Training.png",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "4188",
                "firstVideo": "1\/55.mp4",
                "totalseccount": 11,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "2",
                "certificate_ref_id": "3",
                "course_name": "Digital Organization",
                "course_slug": "digital-organization",
                "course_icon": "digital-organization.svg",
                "course_snippet": "Participants will learn about structures and policies that act as enablers to roll out an effective digital strategy and are crucial to successfully embracing the opportunities of Industry 4.0.",
                "course_technology": "",
                "course_description": "Structures and policies that provide the agility to design the interventions required to organizational culture, development of employee’s skills and new talent acquisition.<br\/><br\/>All the above act as enablers to roll out an effective digital strategy and are crucial to successfully embracing the opportunities of Industry 4.0.",
                "course_image": "Digital-Organization-Training.png",
                "course_instructor": "1",
                "course_order": "3",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/digital-organization",
                "price": "100",
                "meta_desc": "Learn about structures and policies that act as enablers to roll out an effective digital strategy and are crucial to successfully embracing the opportunities of Industry 4.0.​",
                "meta_img": "Digital-Organization-Training.png",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "5019",
                "firstVideo": "2\/68.mp4",
                "totalseccount": 9,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "3",
                "certificate_ref_id": "4",
                "course_name": "Data-Driven Maintenance",
                "course_slug": "data-driven-maintenance",
                "course_icon": "data-driven-maintenance.svg",
                "course_snippet": "Participants will learn how Data-Driven Maintenance practices are key to driving operational efficiency and reducing downtime.",
                "course_technology": "Automatic Lubrication Systems, Condition Monitoring, CMMS, Predictive Maintenance, 3D Printing​.",
                "course_description": "The implementation of methods and systems to proactively manage maintenance based on data, such as equipment condition metrics and historical events.<br\/><br\/>Data-Driven Maintenance practices are key to driving operational efficiency and reducing downtime.",
                "course_image": "Data-driven-Maintenance-Training.png",
                "course_instructor": "5",
                "course_order": "4",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/data-driven-maintenance",
                "price": "100",
                "meta_desc": "Learn how Data-Driven Maintenance practices are key to driving operational efficiency and reducing downtime.​",
                "meta_img": "Data-driven-Maintenance-Training.png",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "5811",
                "firstVideo": "3\/75.mp4",
                "totalseccount": 9,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "4",
                "certificate_ref_id": "5",
                "course_name": "Sustainability",
                "course_slug": "sustainability",
                "course_icon": "sustainability.svg",
                "course_snippet": "Participants will understand how Sustainability is connected with Industry 4.0 technologies in order to satisfy economic, environmental and social objectives.",
                "course_technology": "EHS, Energy Management Software.",
                "course_description": "Methods, systems, behaviours and Industry 4.0 technologies that help create products and solutions that address the needs of the present without compromising the ability of future generations to meet their needs.<br\/><br\/>Typical technologies in this area are Energy Management Software (EMS) and Environmental, Health & Safety (EHS) Software.",
                "course_image": "Sustainability-Training-Course.png",
                "course_instructor": "2",
                "course_order": "5",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/sustainability",
                "price": "100",
                "meta_desc": "Understand how Sustainability is connected with Industry 4.0 technologies in order to satisfy economic, environmental and social objectives.​",
                "meta_img": "Sustainability-Training-Course.png",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "5335",
                "firstVideo": "4\/121.mp4",
                "totalseccount": 10,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "5",
                "certificate_ref_id": "6",
                "course_name": "Automated Material Flow",
                "course_slug": "automated-material-flow",
                "course_icon": "automated-material-flow.svg",
                "course_snippet": "Participants will learn how to transform the traditional material flows into highly automated ones and enable productivity, safety, accuracy and on time material delivery promoting the Factory of the Future.",
                "course_technology": "AGV, AMR, UAV \/ Drones, ASRS, WMS, YMS​.",
                "course_description": "Implementation of machinery & systems that automate & optimize material handling processes throughout the manufacturing facility.<br\/><br\/>This is critical to enable productivity, safety, accuracy and on time material delivery which is key in driving efficiencies and synchronizing manufacturing with the extended supply chain.",
                "course_image": "Automated-Material-Flow-Training.png",
                "course_instructor": "1",
                "course_order": "6",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/automated-material-flow",
                "price": "100",
                "meta_desc": "Learn how to transform the traditional material flows into highly automated ones and enable productivity, safety, accuracy and on time material delivery promoting the Factory of the Future.​",
                "meta_img": "Automated-Material-Flow-Training.png",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "5977",
                "firstVideo": "5\/103.mp4",
                "totalseccount": 10,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "6",
                "certificate_ref_id": "7",
                "course_name": "Digital-Ready Infrastructure",
                "course_slug": "digital-ready-infrastructure",
                "course_icon": "digital-ready-infrastructure.svg",
                "course_snippet": "​Participants will understand the systems that support the foundational framework to manage current and future data streams that will be available as a result of the Industry 4.0 journey.",
                "course_technology": "Cloud Computing, Edge Computing, Industrial Communication Systems, 5G, Cybersecurity, Network Intrusion Detection Systems​.",
                "course_description": "Systems that safeguard and enable the plant’s operations, ensure reliable and secure data streaming acting as a key enabler of Industry 4.0 transformation.<br\/><br\/>​They support the foundational framework to manage current and future data streams that will be available as a result of the Industry 4.0 journey.",
                "course_image": "Digital-Ready-Infrastructure-Training.png",
                "course_instructor": "3",
                "course_order": "7",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/digital-ready-infrastructure",
                "price": "100",
                "meta_desc": "​Understand the systems that support the foundational framework to manage current and future data streams that will be available as a result of the Industry 4.0 journey.​",
                "meta_img": "Digital-Ready-Infrastructure-Training.png",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "6240",
                "firstVideo": "6\/24.mp4",
                "totalseccount": 10,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "7",
                "certificate_ref_id": "8",
                "course_name": "360° Factory Visibility",
                "course_slug": "360-factory-visibility",
                "course_icon": "360-factory-visibility.svg",
                "course_snippet": "Participants will learn more about the use of central repositories and effective integrations to ensure data availability and transparency that enable data-sharing with suppliers and customers.",
                "course_technology": "ERP, PLM, IIoT, Data Lake, Data Warehouse, Data Visualization, Blockchain, RPA​.",
                "course_description": "Systems that combine data from various areas of the value chain, eliminating silos & accelerating decision-making. They include software that covers several departments, such as ERP and Plant Manager Dashboards and integrations between these systems.<br\/><br\/>They offer a unified view of all operations and enable data-sharing with suppliers and customers, improving decision-making, reducing human errors, and driving process efficiencies.",
                "course_image": "360-Factory-Visibility-Training.png",
                "course_instructor": "3",
                "course_order": "8",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/360-factory-visibility",
                "price": "100",
                "meta_desc": "Learn more about the use of central repositories and effective integrations to ensure data availability and transparency that enable data-sharing with suppliers and customers.​",
                "meta_img": "360-Factory-Visibility-Training.png\n",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "5440",
                "firstVideo": "7\/84.mp4",
                "totalseccount": 11,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "8",
                "certificate_ref_id": "9",
                "course_name": "Intelligent Quality",
                "course_slug": "intelligent-quality",
                "course_icon": "intelligent-quality.svg",
                "course_snippet": "Participants will learn how to leverage real time data to manage quality control and assurance and enhance quality by simultaneously increase operational advantages.",
                "course_technology": "QMS, Foreign Object Detection Systems, LIMS, Machine Vision, Predictive Quality​.",
                "course_description": "Effective integration of sensors, software, and techniques that leverage real-time data to manage quality control and quality assurance.<br\/><br\/>All these technologies and techniques are delivering enhanced quality and operational advantages.",
                "course_image": "Intelligent-Quality-Training.png",
                "course_instructor": "5",
                "course_order": "9",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/intelligent-quality",
                "price": "100",
                "meta_desc": "Learn how to leverage real time data to manage quality control and assurance and enhance quality by simultaneously increase operational advantages.​",
                "meta_img": "Intelligent-Quality-Training.png",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "5830",
                "firstVideo": "8\/35.mp4",
                "totalseccount": 9,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "9",
                "certificate_ref_id": "10",
                "course_name": "Tech-Augmented Workforce",
                "course_slug": "tech-augmented-workforce",
                "course_icon": "tech-augmented-workforce.svg",
                "course_snippet": "Participants will understand why an augmented worker is an essential part of the Industry 4.0 ecosystem. They will also get exposed to technologies that open new horizons in productivity, democratizing value creation.",
                "course_technology": "Machine Vision, AR, VR, Industrial Tablets \/ Smartphones, Industrial Wearables, Connected Workforce Platform​.",
                "course_description": "Technologies that assist employees in their tasks by augmenting their skillset and optimizing workflows.<br\/><br\/>These technologies provide access to information and expertise and are opening new horizons in productivity, efficiency and innovation, democratizing value creation.",
                "course_image": "Tech-Augmented-Workforce-Training.png",
                "course_instructor": "5",
                "course_order": "10",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/tech-augmented-workforce",
                "price": "100",
                "meta_desc": "Understand why an augmented worker is an essential part of the Industry 4.0 ecosystem. They will also get exposed to technologies that open new horizons in productivity, democratizing value creation.​",
                "meta_img": "Tech-Augmented-Workforce-Training.png\n",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "4338",
                "firstVideo": "9\/15.mp4",
                "totalseccount": 9,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            },
            {
                "id": "10",
                "certificate_ref_id": "11",
                "course_name": "Zero-Touches Production",
                "course_slug": "zero-touches-production",
                "course_icon": "zero-touches-production.svg",
                "course_snippet": "Participants will learn about combining hardware, software and practices to eliminate human effort in production. These technologies enable agility, flexibility and throughput improvements to daily production cycles.",
                "course_technology": "Cobots, End of arm tooling, Soft Robotics, Machine Vision, MES, Digital Twin.",
                "course_description": "Hardware, software, and practices, that eliminate human effort in production execution and streamline the production system towards lights-off operations.<br\/><br\/>These technologies and practices enable agility, flexibility and throughput improvements to daily production cycles.",
                "course_image": "Zero-Touches-Production-Training.png",
                "course_instructor": "4",
                "course_order": "11",
                "social_url": "https:\/\/www.smarterchains.com\/industry-40-training-courses\/zero-touches-production",
                "price": "100",
                "meta_desc": "Learn about combining hardware, software and practices to eliminate human effort in production. These technologies enable agility, flexibility and throughput improvements to daily production cycles.​",
                "meta_img": "Zero-Touches-Production-Training.png\n",
                "is_imd": "0",
                "is_active": "1",
                "total_sec_dur": "5417",
                "firstVideo": "10\/93.mp4",
                "totalseccount": 10,
                "totalcompseccount": null,
                "cardStatus": "",
                "has_paid": 0
            }
        ]
	let avaltrainging = 0;
	trainingData.map((items, index) => {
		if (items.has_paid == 0) {
			avaltrainging = avaltrainging + 1;
		}
	});
	useEffect(()=>{
			let trainingScroll=document.querySelector('.training-content');
         trainingScroll.scrollTop = 0;
	},[])
	return (
		/* this section has
			heading
			main-training-sec
			available-training-sec
		*/ 
		<div className="main-course-page">
			<h1 className="h1">All IR4.0 training courses</h1>
			{/* this section has 
				heading
				train-card-row-container
			*/}
			<section className="main-training-sec">
				<h2 className="h2">My trainings</h2>
				{/* this section has row */}
				<div className="train-card-row-continer">
					<div className="training-card-row">
						{trainingData.map((items, index) => {
							if (items.has_paid == 1) {
								return (
									<div className="training-card-col" key={index}>
										<TrainingCardControler
											data={{
												cardTitle: items.course_name,
												cardId: items.id,
												cardSlug: items.course_slug,
												cardImg: require(`../../assets/img/training-card/${items.course_icon}`).default,
												isImd: items.is_imd,
												cardDuration: items.total_sec_dur,
												cardStatus: items.cardStatus,
												isAvailable: false,
												isFirstTraining:items.is_first_training,
											}}
										/>
									</div>
								);
							}
						})}
					</div>
				</div>
			</section>
			{/* main-training-sec end   */}
			<section className="available-training-sec">
				{avaltrainging > 0 ? <h2 className="h2">Available trainings</h2> : <></>}
				<div className="train-card-row-continer">
					<div className="training-card-row">
						{trainingData.map((items, index) => {
							if (items.has_paid == 0) {
								return (
									<div className="training-card-col" key={index}>
										<TrainingCardControler
											data={{
												cardTitle: items.course_name,
												cardId: items.id,
												cardSlug: items.course_slug,
												cardImg: require(`../../assets/img/training-card/${items.course_icon}`).default,
												isImd: items.is_imd,
												cardDuration: items.total_sec_dur,
												cardStatus: items.cardStatus,
												isAvailable: true,
												availableText: items.course_snippet,
												availableTechnology: items.course_technology,
												isFirstTraining:items.is_first_training,
											}}
										/>
									</div>
								);
							}
						})}
					</div>
				</div>
			</section>
			{/*available-training-sec end   */}
		</div>
	);
};
