/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * makeStyles for ===> styling material-ui component.
  * Tabs ===> matrial ui component for tabs.

**/

import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import { RoadmapTabPanelView } from "./RoadmapTabPanelView.js";
import { RoadmapTransformationView } from "./RoadmapTransformationView.js";
import { RoadmapMasterplanView } from "./RoadmapMasterplanView.js";
import { headIcon } from "./svg.js"
import {
	RoadmapMainWrapper,
	RoadmapTabHeader,
	RoadmapTabContent,
	ManuFactureBtn,
	RoadmapFooter,
	ManuPopWrapper,
	ManuPopWrap,
} from "./styled.js"
//styling component
const useStyles = makeStyles((theme) => ({
	customtab: {
		"& .MuiTabs-indicator": {
			backgroundColor: "#118ACB",
			height: ".4rem",
		},
		"& .MuiTab-root": {
			minWidth: "auto",
			opacity: 1,
			padding: 0,
			marginRight: "5rem",
			textTransform: "unset",

			"& .MuiTouchRipple-root": {
				display: "none",
			},
			"& .act": {
				display: "none",
			},
			"& .inact": {
				display: "inline-block",
			},
			"&.Mui-selected": {
				"& .act": {
					display: "inline-block",
				},
				"& .inact": {
					display: "none",
				},
			},
		},
	},
}));
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <>{children}</>}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
//RoadmapTabView component start
export const RoadmapTabView = (props) => {
	const dataResults = props.dataResults;
	const classes = useStyles();
	const [value, setValue] = React.useState(props.onTab);
	const [isManuPop, setIsManuPop] = useState(false);
	const [isDownloadPopup, setIsDownloadPopup] = useState(false);
	//function to handle change
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<>
			{/* main wrapper has
				roadmapheader
				roadmaptab_content
			*/}
			{/* page title */}
			<Helmet>
				<title>Roadmap Result | SmarterChains</title>
				<meta name="description" content="Purchase History" />
				<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
			</Helmet>

			{/* breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/home">{dataResults.plant_name}</BreadcrumbsItem>
			{props.gid == 16 &&
				<>
					<BreadcrumbsItem to={`/roadmap/step1/${dataResults.plant_slug}/${dataResults.sid}`}>IR4.0 Roadmap Solutions</BreadcrumbsItem>
					<BreadcrumbsItem to={`/roadmap/final-step/${dataResults.plant_slug}/${dataResults.sid}`}>IR4.0 Timeline Implementation</BreadcrumbsItem>
				</>
			}
			<BreadcrumbsItem to="#">IR4.0 Strategy Results</BreadcrumbsItem>
			<RoadmapMainWrapper data-component="RoadmapMainWrapper">
				{/* this section has 
					row 
					tabs
				*/}
				<RoadmapTabHeader data-component="RoadmapTabHeader">
					<h2 className="h1">{props.onTab == 1 ? "IR4.0 Roadmap" : props.onTab == 2 ? "IR4.0 Roadmap Masterplan" : "IR4.0 Roadmap Transformation Stages"} <span>for {dataResults.plant_name} plant</span></h2>
					{/* <i dangerouslySetInnerHTML={{__html: headIcon}}></i> */}
					{dataResults.is_download ?
						<ManuFactureBtn
							as="a"
							href={`${dataResults.urldownload}`}
							data-component="ManuFactureBtn">
							Download Result
						</ManuFactureBtn>
						:
						<ManuFactureBtn
							data-component="ManuFactureBtn"
							onClick={() => setIsDownloadPopup(prv => `/dimension-and-solutions-mapping/${dataResults.plant_slug}`)}
						>
							Download Result
						</ManuFactureBtn>}
					<ManuFactureBtn data-component="ManuFactureBtn" style={{ display: "none" }}>Publish to Manufacturer</ManuFactureBtn>
				</RoadmapTabHeader>

				<RoadmapTabContent data-component="RoadmapTabContent">
					{/* tab pannel wrapper */}
					<TabPanel value={value} index={0}>
						<RoadmapTransformationView results={dataResults} {...props} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<RoadmapTabPanelView results={dataResults} {...props} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<RoadmapMasterplanView results={dataResults} {...props} />
					</TabPanel>
				</RoadmapTabContent>
				<RoadmapFooter data-component="RoadmapFooter">
					{/* roadmap_head__row end */}
					<Tabs className={classes.customtab} value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
						<Tab
							{...a11yProps(0)}
							component={Link}
							to={`/roadmap-result/${dataResults.plant_slug}/${dataResults.sid}/0`}
							icon={
								<div className="roadmap_tab__btn">
									<span>IR4.0 Roadmap Transformation Stages</span>
								</div>
							}
						/>

						<Tab
							{...a11yProps(1)}
							component={Link}
							to={`/roadmap-result/${dataResults.plant_slug}/${dataResults.sid}/1`}
							icon={
								<div className="roadmap_tab__btn">
									<span>IR4.0 Roadmap</span>
								</div>
							}
						/>
						<Tab
							{...a11yProps(2)}
							component={Link}
							to={`/roadmap-result/${dataResults.plant_slug}/${dataResults.sid}/2`}
							icon={
								<div className="roadmap_tab__btn">
									<span>IR4.0 Roadmap Masterplan</span>
								</div>
							}
						/>
					</Tabs>
				</RoadmapFooter>
			</RoadmapMainWrapper>
			<StartegyPopup isStrPopup={isDownloadPopup} setIsStrPopup={setIsDownloadPopup} title={`Please complete the dimensions and solutions mapping. You can directly access from <a href="${isDownloadPopup}"><b>here</b></a>.`} heading={'na'} />
			{isManuPop &&
				<ManuPopWrapper data-component="ManuPopWrapper">
					<ManuPopWrap data-component="ManuPopWrap">
						<h2>Are you sure to publish?</h2>
						<p>The results will be published and will be visible to the Manufacturer’s</p>
						<ButtonWrap data-component="ButtonWrap">
							<button className="btn cancel">Cancel</button>
							<button className="btn publish-btn">Yes, publish</button>
						</ButtonWrap>
					</ManuPopWrap>
				</ManuPopWrapper>
			}
		</>
	);
};


// Disabled Roadmap notification popup
const StartegyPopup = (props) => {
	const { isStrPopup, setIsStrPopup, title } = props;
	const handleCloseStr = () => {
	  setIsStrPopup(false);
	};
	return (
	  <>
		<div className={`strategy-popup-wrapper ${isStrPopup ? "active" : ""}`}>
		  <div className="startegy-pop-card">
			{props.heading !== 'na' ?
			  <h2>{props.heading || `IR4.0 Strategy tool not available`}</h2>
			  : <></>
			}
			<p dangerouslySetInnerHTML={{ __html: props.title }}></p>
			<button onClick={handleCloseStr}>Okay</button>
		  </div>
		</div>
	  </>
	);
  };