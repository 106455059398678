/** imported components
	
  * HeaderView ===> custom view component.
  * header.scss for ===> styling css file for this page.
  * useState ===> React hooks (useState is a way to get/set value)

**/
import React, { useState,useEffect } from "react";
import "./header.scss";
import { HeaderView } from "./header-view";
import serviceProvider from '../../services/axios'

export const HeaderControler = (props) => {
	//Defining variables here
	const [dropdownIndex, setDropdownIndex] = useState(null);
	//Function to handle dropdown
	const HandleDropdown = (ddlIndex) => {
		if (ddlIndex === dropdownIndex) {
			setDropdownIndex(null);
		} else {
			setDropdownIndex(ddlIndex);
		}
	};
	
	const [results, setResults] = useState(null);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	
	useEffect(() => {
		const search = async () => {
			const apiUrl = `/home?param=progress&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, []);

	return <HeaderView dropdownIndex={dropdownIndex} results={results}  HandleDropdown={HandleDropdown} userShortname="SC" {...props} />;
};
