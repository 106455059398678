import styled from "styled-components";

export const UpgradeCouponModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.30);
    backdrop-filter: blur(2px);
    z-index: 999;
`
export const UpgradeCouponModalPop = styled.div`
    max-height: calc(100vh - 10rem);
    max-width: 100rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2.3rem;
    display:flex;
    align-items:center;
    justify-content:center;
    height: 100%;
    width:100%;
    @media(max-width: 991px){
        min-width: auto;
        width: calc(100% - 20px);
    }
    @media(max-width: 575px){
        max-height: calc(100% - 10px);
    }
`
export const UpgradeButton = styled.button`
    display: flex;
    color: #FFF;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 0.4rem;
    background: linear-gradient(132deg, #8686DF 14.79%, #6464C8 85.39%); 
    padding: 1.2rem 1.6rem;
    line-height: 1.8rem;
    min-width: 12.5rem;
    align-items: center;
    justify-content: center;
    margin: 20px;
`
export const MainPopupBothSectionWraper =  styled.div`
    background:#fff;
    border-radius: 2.3rem;
    display:flex;
    flex-direction: column;
    position: relative;
    padding: 0 0 1rem 0;
    height: 100%;
    width: 100%;
    @media(max-width: 768px){
        flex-wrap: wrap;
        overflow: auto;
    }
    .close-icon{
        display: inline-block;
        height: 3.2rem;
        width: 3.2rem;
        @media(max-width: 700px){
            height: 20px;
            width: 20px;
        }
        i{
            display: inline-block;
            height: 100%;
            width: 100%;
            svg{
                height: 100%;
                width: 100%;
            }
        }
    }
`
export const MainPopupBothSectionHeader =  styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 2.1rem 2.4rem 1.9rem 3.2rem;
   border-radius: 2.3rem 2.3rem 0px 0px;
   background: linear-gradient(90deg, #097EBC 0%, #118ACB 100%); 
   width: 100%;
   @media(max-width: 700px){
    align-items: flex-start;
    padding: 15px 20px 0 20px;
   }
   .section-head-left{
        display: flex;
        align-items: center;
        gap: 2.4rem;
        @media(max-width: 700px){
            align-items: flex-start;
            gap: 15px;
        }
        i{
            display: inline-block;
            height: 5.9rem;
            width: 4.7rem;
            margin-bottom: -2.4rem;
            @media(max-width: 700px){
                height: 50px;
                margin-bottom: -15px;
                width: 30px;
            }
            svg{
                height: auto;
                width: 100%;
            }
        }
        span{
            color: #FFF;
            display: inline-flex;
            font-size: 2.4rem;
            font-weight: 600;
            line-height: 1.8rem;
            align-items: center;
            @media(max-width: 700px){
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
            }
        }
    }
`;
export const MainPopupBothSectionBody = styled.div`
    border-radius: 2.3rem;
    flex: 1;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 4.8rem 3.2rem 4rem 3.2rem;
    margin-right: 0.5rem;
    overflow-y: auto;
    @media(max-width: 700px){
        padding: 20px 18px 18px 18px;
    }
    @media(max-width: 700px){
        padding: 20px 10px 18px 10px;
    }
    
`;
export const UpgradeCouponCard = styled.div`
    max-width: 87.4rem;
    flex: 1;
    @media(max-width: 991px){
        width: 100%;
    }
`
export const UpgradeCouponRow = styled.div`
    display: flex;
    gap: 4rem;
    border-bottom: 0.1rem solid #C5DDF5;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    align-items: center;
    @media(max-width: 575px){
        gap: 10px;
    }
    &:first-child{
        border-top: 0.1rem solid #C5DDF5;
        border-bottom: 0;
        padding-bottom: 0;
    }
    &:last-child{
        border-bottom: 0;
        @media(max-width: 575px){
            padding-right: 10px;
        }
    }
    .text-col{
        flex: 1;
        &.total{
            display: flex;
            justify-content: flex-end;
        }
        .bold-text{
            color: #4D4D4F;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: normal;
            display: block;
            @media(max-width: 700px){
                font-size: 14px;
            }
            @media(max-width: 575px){
                font-size: 12px;
            }
        }
        .sub-text{
            color: #4D4D4F;
            font-size: 1.8rem;
            font-weight: 400;
            font-style: italic;
            line-height: normal;
            display: block;
            @media(max-width: 700px){
                font-size: 14px;
            }
            @media(max-width: 575px){
                font-size: 12px;
            }
        }
    }
    .old-price-col{
        width: 8.6rem;
        @media(max-width: 700px){
            width: 70px;
        }
        @media(max-width: 575px){
            width: 50px;
        }
        span{
            color: #4D4D4F;
            font-size: 1.8rem;
            font-weight: 300;
            text-decoration: line-through;
            line-height: normal;
            @media(max-width: 700px){
                font-size: 14px;
            }
            @media(max-width: 575px){
                font-size: 12px;
            }
        }
    }
    .new-price-col{
        width: 8.6rem;
        @media(max-width: 700px){
            width: 70px;
        }
        @media(max-width: 575px){
            width: 50px;
        }
        span{
            color: #4D4D4F;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: normal;
            @media(max-width: 700px){
                font-size: 14px;
            }
            @media(max-width: 575px){
                font-size: 12px;
            }
        }
    }
    .colored-price-col{
        width: 8.6rem;
        @media(max-width: 700px){
            width: 70px;
        }
        @media(max-width: 575px){
            width: 50px;
        }
        span{
            border-radius: 0.8rem;
            background: #C5DDF5;
            color: #2C55A0;
            display: inline-flex;
            font-size: 1.8rem;
            font-weight: 600;
            line-height: normal;
            width: 100%;
            padding: 1.2rem 1.6rem;
            @media(max-width: 700px){
                font-size: 12px;
                padding: 8px 0;
                text-align: center;
                justify-content: center;
            }
        }
    }
`

// Right Section
export const MainPopupBothSectionFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 0 3.2rem;
  width: 100%;
  @media (max-width: 575px) {
    padding: 0;
  }
  .footer-cart-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    //background: #43A047;
    background: linear-gradient(92deg, #139bd6 12.22%, #0789cf 100.41%);
    min-width: 31.5rem;
    min-height: 6rem;
    @media (max-width: 700px) {
      min-width: 260px;
      min-height: 40px;
    }
    .text {
      color: #fff;
      display: inline-block;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.8rem; /* 90% */
      @media (max-width: 700px) {
        font-size: 16px;
      }
    }
    i {
      dispaly: inline-block;
      height: 2rem;
      width: 2rem;
      @media (max-width: 700px) {
        height: 15px;
        width: 15px;
      }
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
`;
