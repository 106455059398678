import styled from "styled-components";


export const NewSignUpWrapper = styled.section`
  .auth__form--wrapp {
    display: flex;
    height: 100vh;
    align-items: flex-start;
    background: #f4f6fc;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      padding: 0px !important;
    }
    .train__reset-password-msg {
      text-align: center;

      .train__check-icon {
        max-width: 13.4rem;
        display: inline-block;
        margin-bottom: 3rem;
      }

      .train__msg1 {
        font-size: 2.5rem;
        line-height: 3.6rem;
        font-weight: 700;
        color: #4d4d4f;
        text-align: center;
      }

      .train__msg2 {
        font-weight: 500;
        color: #4d4d4f;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin-top: 2rem;

        .train__msg-lnk {
          color: #178acb;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          text-decoration-line: underline;
          margin-left: 0.3rem;
          border: none;
          background: none;
          cursor: pointer;
        }
      }
    }

    .auth__form--right {
      align-items: flex-start;
    }
    &.signup2021-06 {
      .sign-in .Signup-form .signup-box p {
        @media (min-height: 630px) {
          margin-top: 3.7rem;
          margin-bottom: 2.7rem;
        }
      }
    }
  }
  .auth__form-sm--logo {
    display: none;
    @media (max-width: 1100px) {
      display: flex;
      margin-top: 20px;
      align-items: center;
      justify-content: center;
    }
  }

  .auth__form--left {
    background-image: url(${require("../../assets/img/auth/landing-img.svg").default});
    background-position: center center;
    background-size: 70%;
    background-repeat: no-repeat;
    height: 100%;
    position: relative;

    .auth__form--logo {
      position: absolute;
      top: 4.8rem;
      left: 6.5rem;
      @media (max-width: 991px) {
        position: static !important;
      }
      &.mb {
        position: relative;
      }
    }

    @media screen and (max-width: 1100px) {
      width: 0;
      flex-basis: 0% !important;
      display: none;
    }
  }

  .auth__form--right {
    background: #fff;
    display: flex;
    justify-content: center;
    height: 100%;
    &.scroll {
      overflow-y: auto;
    }
    @media screen and (max-width: 1100px) {
      width: 100%;
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }

  // new sign-up page css starts
  .signup_form_container {
    max-width: 49.2rem;
    margin: 0 auto;
    padding: 4rem 0px;
    @media (max-width: 991px) {
      margin-top: 20px;
      padding: 15px;
    }
    .signup_form_header {
      border-bottom: 0.1 solid #e6e6e6;
      padding-bottom: 3.2rem;

      h1 {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: #4d4d4f;
        padding-bottom: 1.6rem;
        @media(max-width: 575px){
          font-size: 16px;
        }
      }

      p {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: #7d868c;
      }
    }

    .signup_form {
      //padding-top: 3.2rem;
      padding-top: 0;
      border-top: 0.1rem solid #e6e6e6;
      &.step_3{
        .signup_form_row{
          flex-direction: column;
          .signup_form_col{
            padding-bottom: 2rem;
          }
        }
      }
      .terms_privacy_text{
        padding: 0 1.5rem;
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: center;
        color: #606060;
        margin-bottom: 3rem;
        a{
          color: #606060;
        }
      }
      .account-login{
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #606060;
        text-align: center;
        margin: 1.5rem auto 0 auto;
        display: block;
        a{
          display: inline-block;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #178acb;
          margin-left: 0.3rem;
          border-bottom: 0.17rem solid #178acb;
          background: none;
          cursor: pointer
          margin-top: 1.4rem;
        }
      }

      .signup_tab_header {
        .signup_form_step {
          display: flex;
          align-items: center;
          padding-bottom: 1.2rem;
          padding-top: 1.2rem;
          min-height: 5rem;

          .back_svg {
            cursor: pointer;
            margin-right: 1.9rem;
          }

          .step {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.6rem;
            letter-spacing: 0.02em;
            padding-right: 1.6rem;
            color: #4d4d4f;
            @media(max-width: 575px){
              font-size: 14px;
              padding-right: 8px;
            }
            span {
              color: #118acb;
            }
          }

          span {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.6rem;
            letter-spacing: 0.02em;
            color: #4d4d4f;
            @media(max-width: 575px){
              font-size: 14px;
            }
          }
        }

        .step_progress {
          background: #e5f0ff;
          border-radius: 2rem;
          height: 0.4rem;
          width: 100%;
          position: relative;

          .step_progress_bar {
            width: 50%;
            border-radius: 2rem;
            background: #118acb;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
          }
        }
      }

      .siri_signup_box {
        padding: 3.2rem 3.2rem 0 3.2rem;
        @media (max-width: 991px) {
          padding: 3.2rem 0rem 0rem 0rem;
        }
        .terms_privacy_text{
          padding: 0 1.5rem;
          margin-top: 1rem;
          font-size: 1.2rem;
          line-height: 1.5rem;
          text-align: center;
          color: #606060;
          margin-bottom: 3rem;
          a{
            color: #606060;
          }
        }
        .account-login{
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #606060;
          text-align: center;
          margin: 1.5rem auto 0 auto;
          display: block;
          a{
            display: inline-block;
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: #178acb;
            margin-left: 0.3rem;
            border-bottom: 0.17rem solid #178acb;
            background: none;
            cursor: pointer;
            margin-top: 1.4rem;
          }
        }
        .field_wrapper {
          position: relative;
          display: block;
          .react-tel-input{
            .special-label{
              display: none;
            }
            .country-list{
              bottom: 40px;
              z-index: 9;
            }
            .form-control{
              font-size: 1.4rem;
              background: #FFFFFF;
              border: 0.1rem solid #e9e9e9;
              border-radius: 5px;
              width: 100%;
              outline: none;
              padding: 0.5rem 1.7rem 0.5rem 7rem;
              transition: box-shadow ease .25s,border-color ease .25s;
              height: 5rem;
              color: #4d4d4f;
              &:hover{
                border-color: #e9e9e9;
              }
              &:focus{
                box-shadow: none;
                border-color: #e9e9e9;
              }
            }
          }

          .label {
            position: absolute;
            padding: 0.3rem 0.5rem;
            left: 1.2rem;
            top: -0.8rem;
            background: #fff;
            border-radius: 0.2rem;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.5rem;
            z-index: 1;
            color: #0a2265;
          }

          .input_field {
            height: 5rem;
            width: 100%;
            border: 0.1rem solid #e9e9e9;
            border-radius: 0.5rem;
            padding: 0.5rem 1.7rem;
            outline: 0;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: #4d4d4f;

            ::placeholder {
              color: #7d868c;
            }

            &.pr_30 {
              padding-right: 4rem;
            }
          }

          svg {
            position: absolute;
            top: 50%;
            right: 2.1rem;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }

        .signup_form_row {
          display: flex;
          flex-wrap: wrap;
          gap: 2rem;
          &.gap-0{
            gap:0px;
          }
          @media (max-width: 991px) {
            display: block;
          }
          .signup_form_col {
            flex: 1;
            padding-bottom: 2.7rem;
            position: relative;
            @media (max-width: 768px) {
              flex: unset;
              width: 100%;
            }
            .error {
              position: absolute;
              font-size: 1.2rem;
              line-height: 1.5rem;
              text-align: right;
              letter-spacing: -0.01em;
              color: #fc6444;
              background: transparent;
              width: 100%;
              display: block;
            }
            .ddl__list__wrapper {
              position: relative;
              border-top: 0;
              visibility: hidden;
              opacity: 0;
              transition: 0.25s linear;
              z-index: 1;

              .ddl__list__card {
                position: absolute;
                width: 100%;
                max-height: 15rem;
                min-height: 10rem;
                background: #fff;
                top: -0.1.5rem;
                border: 0.1rem solid #e9e9e9;
                border-radius: 0.5rem;
                padding: 0 1.6rem;
                overflow-x: hidden;
                overflow-y: auto;

                header {
                  border-bottom: 0.1rem solid #cbcfd2;
                  position: relative;

                  label {
                    display: flex;
                    align-items: center;

                    input {
                      width: calc(100% - 2.5rem);
                      border: 0;
                      height: 4rem;
                      font-size: 1.5rem;
                      text-transform: capitalize;
                      color: #7d868c;
                      outline: 0;
                      padding-left: 1rem;

                      &::placeholder {
                        color: #7d868c;
                      }
                    }
                  }
                }

                ul {
                  list-style: none;
                  margin-top: 0.5rem;

                  li {
                    background: #fff;
                    border-radius: 0.5rem;
                    padding: 0.5rem 1rem;
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    color: #4d4d4f;
                    transition: 0.25s ease-in-out;
                    cursor: pointer;
                    text-transform: capitalize;

                    &:hover {
                      background: #f0f0f0;
                      transition: 0.25s ease-in-out;
                    }
                  }
                }
              }
            }
            .profile__form__controller {
              min-height: 8.3rem;
  
              .plant-label {
                display: block;
                position: relative;
  
                &.active {
                  .btn__ddl__arrow {
                    transform: translateY(-50%) rotate(180deg);
                    transition: 0.25s ease-in-out;
                  }
  
                  .ddl__list__wrapper {
                    visibility: visible;
                    opacity: 1;
                    transition: 0.25s linear;
                  }
                }
  
                .label__text {
                  display: inline-block;
                  padding: 0 0.6rem;
                  background: #fafcfd;
                  font-weight: 500;
                  font-size: 1.2rem;
                  line-height: 1.5rem;
                  letter-spacing: -0.0272727rem;
                  color: #4d4d4f;
                  white-space: nowrap;
                  position: absolute;
                  top: -0.6rem;
                  left: 1.1rem;
                }
  
                .form__field {
                  width: 100%;
                  background: #fafcfd;
                  border: 0.1rem solid #e9e9e9;
                  box-sizing: border-box;
                  border-radius: 0.5rem;
                  padding: 1.7rem;
                  font-size: 1.5rem;
                  color: #4d4d4f;
                  outline: 0;
                  -webkit-appearance: none;
                  &::placeholder {
                    color: #7d868c;
                  }
                  &:not(:read-only) {
                    &:focus {
                      border: 0.1rem solid #7d868c;
                    }
                  }
                }
  
                &.phone-no {
                  .prifix {
                    display: inline-flex;
                    position: absolute;
                    align-items: center;
                    height: 100%;
                    left: 1.7rem;
                    font-size: 1.4rem;
                    color: #7d868c;
  
                    .flag__img {
                      width: 1.9rem;
                      height: 1.7rem;
                      // margin-right:.7rem;
                    }
  
                    .error__flag {
                      width: 1.9rem;
                      height: 1.7rem;
                      display: none;
                    }
                  }
  
                  .form__field {
                    padding-left: 4rem;
                  }
                }
  
                &.password {
                  .btn__type__handle {
                    position: absolute;
                    padding: 0;
                    right: 0rem;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 100%;
                    width: 6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
  
                  .form__field {
                    padding-right: 6rem;
                  }
                }
              }
  
              .error {
                font-size: 1.2rem;
                line-height: 1.5rem;
                text-align: right;
                letter-spacing: -0.01em;
                color: #fc6444;
                text-align: right;
                padding-top: 0.3rem;
                visibility: hidden;
              }
  
              &.show-error {
                .error {
                  visibility: visible;
                }
  
                .form__field {
                  color: #fc6444;
                }
  
                &.phone-no {
                  .prifix {
                    .flag__img {
                      display: none;
                    }
  
                    .error__flag {
                      display: inline-block;
                    }
                  }
                }
              }
  
              &.profile__form__disabled {
                .label {
                  .form__field {
                    background: #f0f0f0;
                    cursor: no-drop;
                  }
                }
              }
            }
            .button {
              width: 100%;
              background: #fafcfd;
              border: 0.1rem solid #e9e9e9;
              box-sizing: border-box;
              border-radius: 0.5rem;
              padding: 1.7rem;
              font-size: 1.5rem;
              color: #7d868c;
              outline: 0;
              text-align: left;

              .btn__ddl__arrow {
                position: absolute;
                right: 1.85rem;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                transition: 0.25s ease-in-out;
              }

              &.selected {
                color: #4d4d4f;
              }
            }


          }
        }

        .terms_privacy_text {
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 150%;
          text-align: center;
          color: #606060;

          .siri_term {
            text-decoration: underline;
          }
        }

        .siri_form_button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 6rem;
          border-radius: 0.5rem;
          font-weight: 600;
          font-size: 1.4rem;
          color: #ffffff;
          margin-top: 2.1rem;
          background: linear-gradient(
            91.83deg,
            #139bd6 12.22%,
            #0789cf 100.41%
          );

          &.disabled {
            background: #cbcfd2;
          }
        }
      }
    }
  }

  .dropdown_btn {
    position: relative;
    height: 5rem;
    width: 100%;
    border: 0.1rem solid #e9e9e9;
    border-radius: 0.5rem;
    padding: 0.5rem 1.7rem;
    outline: 0;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #4d4d4f;
    text-align: left;
    background: #ffffff;

    .placeholder_text {
      color: #7d868c;
    }

    .label {
      position: absolute;
      padding: 0.3rem 0.5rem;
      left: 1.2rem;
      top: -0.8rem;
      background: #fff;
      border-radius: 0.2rem;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #0a2265;
    }

    .icon_arrow_down {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .dropdown_container {
    position: relative;

    &.active {
      .dropdown_btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &.top {
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .dropdown_content {
        opacity: 1;
        visibility: visible;
      }
    }

    .dropdown_content {
      position: absolute;
      z-index: 2;
      background: #fff;
      width: 100%;
      max-height: 20rem;
      overflow-y: auto;
      border: 0.1rem solid #e9e9e9;
      border-top: 0;
      opacity: 0;
      visibility: hidden;
      display:flex;
      flex-direction:column;
      &.top {
        top: 0;
        transform: translateY(-100%);
        border-top: 0.1rem solid #e9e9e9;
        border-bottom: 0;
      }
      ul{
        flex:1;
        overflow:auto;
      }
      .signup-ddl-search{
        width:100%;
        outline:0;
        border:0;
        border-bottom:1px solid #e9e9e9;
        color:#4d4d4f;
        padding: 1.2rem 1.6rem;
        font-size: 1.4rem;
      }

      li {
        cursor: pointer;
        padding: 1.1rem 1.6rem;
        font-size: 1.4rem;
        color: #4d4d4f;

        &:hover {
          background: #f5f5f5;
        }
      }
    }
  }
 
`;