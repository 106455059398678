/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * LossanalysisPlantQuesView ===> custom view component.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api

**/

import React from 'react';
import { Helmet } from 'react-helmet';
import serviceProvider from '../../services/axios'
import { useEffect, useState } from 'react';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, Link, Redirect } from "react-router-dom";
import {LossanalysisPlantQuesView} from './la-questionnaires-plant-view'

//LossanalysisPlantQuesController start
export const LossanalysisPlantQuesController=(props)=>{
	//defining variables here
	const segments = window.location.pathname.slice(1).split("/");
	const [unlockbutton, setUnlockbutton] = useState(false);
	const slug = segments[2];
	const sid = segments[3];
	const ssid=segments[4];
	var linepagename;
	if(ssid==1){
	linepagename ="Planned Downtime";
	}else if(ssid==2){
	linepagename ="Unplanned Downtime";
	}else if(ssid==3){
	linepagename ="Minor Stops";
	}else if(ssid==4){
	linepagename ="Speed Losses";
	}else {
	linepagename ="Material Waste";
	} 
	const [results, setResults] = useState([]);
	const [refadd, setRefadd] = useState(false);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const group_id = user ? user.group_id : 0;
 
  useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
      setRefadd(false);
		};
		search();
	}, [refadd,unlockbutton]);
    
	if (results.length) {
		if(results[0].block == 1){
			
			return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		if(results[0].block == 5){
			
			return <Redirect to="/404" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		if(results[0].unassign == 1){
			
			return <Redirect to="/user-unassign" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		
		if(results[0].is_locked == 1){
			if(group_id == 14){
				return <Redirect to={`/lossanalysis/section/${slug}`} />;
			}else{
				return <Redirect to={`/loss-analysis-admin/section/${slug}`} />;
				}
			return (
					<>
						<div></div>
					</>
			);
		}
		if(window.location.hash != '' && (results[0].isLocks == 1 || results[0].has_businesscase == 1)){
			
			return <Redirect to="/not-found" />;
			return (
					<>
						<div></div>
					</>
			);
		}
		
		else{
			return (
				<>
					{/* Page Title */}
					<Helmet>
						<title>{linepagename} | SmarterChains</title>
						<meta name="description" content="Loss Analysis" />
					</Helmet>
					<LossanalysisPlantQuesView slug={slug}  setRefadd={setRefadd} setUnlockbutton={setUnlockbutton} pname={linepagename} quesResult={results} {...props}/>
				</>
			);
		}		
	}
		
	else{
		return (
				<>
					<div></div>
				</>
			);
		
		}

} 