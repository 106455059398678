import React from 'react';

export const HandlePageOnClick = (target,props) =>{
   props.history.push(target);
   window.screenTop = 0;
}

export const decrypter = (encoded) => {
   const salt = "salt"
   const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
   const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
   return encoded
   .match(/.{1,2}/g)
   .map((hex) => parseInt(hex, 16))
   .map(applySaltToChar)
   .map((charCode) => String.fromCharCode(charCode))
   .join("");  
}
export const crypt = (text) => {
  const salt = "salt"
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
}
export const crypter = (text) => {
  const salt = "salt"
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
}