import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./loss-analysis-page.scss";
import { UserWalkthroughView } from './user-walkthrough'
import serviceProvider from '../../services/axios'
import { apiEndpoints } from '../../services/api-endpoints'
import { HowToUseCtrl } from '../lossanalysis-how-to-use-popup/HowToUseCtrl'
export const LossAnalysisPagePlantView = (props) => {
  const sectionDatas = props.resultcat;

  if (!sectionDatas.length) {
    return null;
  }

  const slug = props.slug;

  const sectiondata = props.resultcat[0];
  const locked_sections = sectiondata.locked_sections; //lock mechanism
  const inviteduserlist = sectiondata.sectioninvitedmeber; //invited user list
  const sectionid = sectiondata.sections;
  const subsectionid = sectiondata.subsection;
  const sectdata = sectiondata.assingdetails;
  const globalsectdata = sectiondata.globalassingdetails;
  const [status, setStatus] = useState(false);
  const [categ, setCateg] = useState();
  const [subcat, setSubcat] = useState();
  const [pageprog, setPageProgress] = useState();
  const [losspit, setLosspit] = useState(0);
  const [cate, setCate] = useState(0);
  const [subcate, setSubcate] = useState(0);
  const [isShowUserList, setIsShowUserList] = useState(false)
  const [isCardLockedHover, setIsCardLockedHover] = useState(''); //lock mechanism
  const [comArr, setComArr] = useState(0);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;

  //lock mechanism start

  const handleSectionClick = async (path, section_id = 0, sub_section_id = 0) => {

    const { history } = props;
    const { uid, plant_id } = sectiondata;
    try {

      let data = { uid, section_id, type: 2, status: 1, plant_id }
      if (sub_section_id)
        data['sub_section_id'] = sub_section_id;

      await serviceProvider.post('/sectionlock', data, true);
    } catch (err) {
      console.error(err)
    }

    history.push(path)
  }
  //SCNV-2692
  const [isCardHover, setIsCardHover] = useState(null);
  //Function to handle active status on hover
  const HoverActiveHandle = (e, val) => {
    if (!e.target.closest('.right-section') && !e.target.closest('.lock-section')) {
      setIsCardHover(val)
      setIsShowUserList(false)
    } else {
      setIsCardHover(null);
    }
  }
  //lock mechanism end

  let tempListAssing1 = {};
  if (sectdata.length != 0) {
    sectdata.map((item, index) => {
      // console.log(item);
      let catArr = [];
      let nameId;
      catArr.push(item.invited_category);
      if (
        catArr.includes(item.invited_category) &&
        item.invited_sub_category == 0
      ) {
        nameId = item.invited_category;
      } else {
        nameId = item.invited_sub_category;
      }

      tempListAssing1 = { ...tempListAssing1, [nameId]: item };
    });
  }

  let tempListAssing2 = {};
  if (inviteduserlist.length != 0) {
    inviteduserlist.map((item, index) => {
      // console.log(item);
      let catArr = [];
      let nameId;
      catArr.push(item.invited_category);
      if (
        catArr.includes(item.invited_category) &&
        item.invited_sub_category == 0
      ) {
        nameId = item.invited_category;
      } else {
        nameId = item.invited_sub_category;
      }

      tempListAssing2 = { ...tempListAssing2, [nameId]: item };
    });
  }

  let tempListAssing = {};
  if (globalsectdata.length != 0) {
    globalsectdata.map((item, index) => {
      // console.log(item);
      let catArr = [];
      let nameId;
      catArr.push(item.invited_category);
      if (
        catArr.includes(item.invited_category) &&
        item.invited_sub_category == 0
      ) {
        nameId = item.invited_category;
      } else {
        nameId = item.invited_sub_category;
      }

      tempListAssing = { ...tempListAssing, [nameId]: item };
    });
  }

  const loadCalProg = () => {
    if (globalsectdata) {

      let progArr = [];
      let temp = 0;
      globalsectdata.map((item, index) => {
        if (item.progress > 0 && item.progress <= 99) {
          progArr.push(50);
        } else if (item.progress >= 100) {
          progArr.push(100);
          temp++;

        } else {
          progArr.push(0);
        }
      });
      setComArr(temp);
      let totol_na_progress = sectiondata.na_progress_data.length;
      let totalna = sectiondata.na_data.length;
      progArr.push(totalna * 100);
      progArr.push(totol_na_progress * 50);

      let avgProg = progArr.reduce((a, b = 0) => {
        return a + b;
      }, 0);
      let losspit1 = Math.round(avgProg / 14);
      if (losspit1 > 100) {
        losspit1 = 100;
      }
      setLosspit(losspit1);
    }
  };

  // pop up hovering start
  const handlePopup = (e, cid, sid = 0) => {
    // console.log("hello")
    setIsShowUserList(!isShowUserList);
    setSubcate(sid);
    setCate(cid);
  }
  //popup hovering end




  // console.log(tempListAssing1[101]);
  // console.log(tempListAssing1[1]);
  const loadAssingeSection = () => {
    // console.log("hello");
    const ncatArr1 = [];
    const ncatArr2 = [];
    const ncatArr3 = [];
    sectdata.map((item) => {
      // item.invited_category
      // console.log(item.invited_category);
      let pval = "0";
      if (item.progress != null) {
        pval = item.progress;
      } else {
        pval;
      }
      ncatArr1.push(item.invited_category);
      ncatArr2.push(item.invited_sub_category);
      ncatArr3.push(pval);
    });
    // console.log(ncatArr);

    setCateg(ncatArr1);
    setSubcat(ncatArr2);
    setPageProgress(ncatArr3);
  };

  const HandleHoverMouseEnter = (e) => {
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const divposX = e.target.getBoundingClientRect().left;
    const divposY = e.target.getBoundingClientRect().top;
    if (screenW <= divposX + 500) {
      e.target.classList.add("right-hover");
    }
    if (screenH <= divposY + 200) {
      e.target.classList.add("bottom-hover");
    }
  };

  // userName list start Here
  let s1 = [];
  let s1name = [];
  let s11 = [];
  let s11name = [];
  let s12 = [];
  let s12name = [];
  let s13 = [];
  let s13name = [];
  let s14 = [];
  let s14name = [];
  let s15 = [];
  let s15name = [];
  let s2 = [];
  let s2name = [];
  let s3 = [];
  let s3name = [];
  let s4 = [];
  let s4name = [];
  let s5 = [];
  let s5name = [];
  let s6 = [];
  let s6name = [];
  let s7 = [];
  let s7name = [];
  let s8 = [];
  let s8name = [];
  let s16 = [];
  let s16name = [];
  let s17 = [];
  let s17name = [];
  let s18 = [];
  let s18name = [];
  let s111 = [];
  //combiend array

  // let s5=[];
  // let s5name=[];
  // if(inviteduserlist.length!=0){
  inviteduserlist.map((item, index) => {


    if (item.invited_sub_category == 1) {
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ["profile_pic"]: item.profile_pic,
        ['noName']: item.name,
      };
      s11.push(tempobj);

      // s11.push(`${item.name[0]}${item.last_name[0]}`);
      // s11name.push((`${item.name} ${item.last_name}`))
    } else if (item.invited_sub_category == 2) {
      // s12.push(`${item.name[0]}${item.last_name[0]}`);
      // s12name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ["profile_pic"]: item.profile_pic,
        ['noName']: item.name,
      };
      s12.push(tempobj);
    }
    else if (item.invited_sub_category == 3) {
      // s13.push(`${item.name[0]}${item.last_name[0]}`);
      // s13name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s13.push(tempobj);
    }
    else if (item.invited_sub_category == 4) {
      // s14.push(`${item.name[0]}${item.last_name[0]}`);
      // s14name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s14.push(tempobj);
    } else if (item.invited_sub_category == 5) {
      // s15.push(`${item.name[0]}${item.last_name[0]}`);
      // s15name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s15.push(tempobj);
    }
    else if (item.invited_sub_category == 6) {
      // s16.push(`${item.name[0]}${item.last_name[0]}`);
      // s16name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s16.push(tempobj);
    }
    else if (item.invited_sub_category == 7) {
      // s17.push(`${item.name[0]}${item.last_name[0]}`);
      // s17name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s17.push(tempobj);
    }
    else if (item.invited_sub_category == 8) {
      // s18.push(`${item.name[0]}${item.last_name[0]}`);
      // s18name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s18.push(tempobj);
    }
    else if (item.invited_category == 103) {
      // s2.push(`${item.name[0]}${item.last_name[0]}`);
      // s2name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s2.push(tempobj);
    } else if (item.invited_category == 107) {
      // s3.push(`${item.name[0]}${item.last_name[0]}`);
      // s3name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s3.push(tempobj);
    } else if (item.invited_category == 102) {
      // s5.push(`${item.name[0]}${item.last_name[0]}`);
      // s5name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s5.push(tempobj);
    } else if (item.invited_category == 104) {
      // s6.push(`${item.name[0]}${item.last_name[0]}`);
      // s6name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s6.push(tempobj);
    } else if (item.invited_category == 105) {
      // s7.push(`${item.name[0]}${item.last_name[0]}`);
      // s7name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s7.push(tempobj);
    } else if (item.invited_category == 106) {
      // s8.push(`${item.name[0]}${item.last_name[0]}`);
      // s8name.push(`${item.name} ${item.last_name}`);
      let tempobj = {};
      tempobj = {
        ...tempobj,
        ['Shortname']: item.name ? `${item.name[0]}${item.last_name ? item.last_name[0] : ''}` : item.email[0],
        ['fullname']: item.name ? `${item.name} ${item.last_name}` : item.email,
        ["profile"]: item.job_title ? `${item.job_title}` : '',
        ["avcolor"]: item.avcolor,
        ['noName']: item.name,
        ["profile_pic"]: item.profile_pic,
      };
      s8.push(tempobj);
    }
  })

  let dataSubmitsvg = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="14" cy="14" r="13.5" fill="#43A047" stroke="#CBCFD2"/>
<path d="M11.2501 17.8225L7.42764 14L6.12598 15.2925L11.2501 20.4167L22.2501 9.41668L20.9576 8.12418L11.2501 17.8225Z" fill="white"/>
</svg>`

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!e.target.closest(".new-assign-user") && !e.target.closest('.assigned-user-popup')) {
        setIsShowUserList(false);
      }
    })
  })

  useEffect(() => {
    loadAssingeSection();
    loadCalProg();
  }, []);

  //SCNV-2692
  // let mouseLeaveTimeOut = '';
  // let cardRightContainer = document.querySelectorAll('.loss-cat-card .right-section');
  // cardRightContainer.forEach((item) => {
  //   item.onmouseleave = () => {
  //     mouseLeaveTimeOut = setTimeout(() => {
  //       setIsShowUserList(false);
  //     }, 4000)
  //   }
  //   item.onmouseenter = () => {
  //     clearTimeout(mouseLeaveTimeOut);
  //   }
  // });

  return (
    <>
      {/* Loss Analysis Screen Start */}
      <div className="loss-analysis-category-wapper user">
        <div className="loss-analysis-category-row">
          <div className="section-btn-wrap">
            <div className="section-progress-wrap walk-step-1">
              <div className="overall-progress-bar">
                <div className="progress-text">
                  <span className="text">Overall Progress:</span>
                  <span className="percent-value">
                    {losspit ? losspit : 0}%
                  </span>
                </div>
                <div className="progress-wrap">
                  <div className="progress">
                    <div
                      className="bar blue"
                      style={{ width: `${losspit ? losspit : 0}%` }}
                    ></div>
                  </div>
                </div>
              </div>
              <span className="mid-border"></span>
              <div className="submit-section">
                <span className="section-progress"></span>
                <span className="text">Sections Submitted:</span>
                <p className="number">
                  <span className="incomplete-no">{sectiondata.submit_data.length + sectiondata.na_data.length}</span>
                  <span className="border">/</span>
                  <span className="complete-no">14</span>
                </p>
              </div>
            </div>
            <div className="button-wrap wrp-flex2126">
              <button className="view-btn user-btn" onClick={() => { window.location = `/loss-analysis-setup/${slug}` }}>View Setup Page</button>
              {sectiondata.lockall == 1 && (
                <button className="submitted-btn btn ">
                  <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: dataSubmitsvg }}></span>
                  <span>Data Submitted</span>
                </button>
              )}
            </div>
          </div>
          <div className="loss-analysis-category-col md-col-12 border-bottom padding-left-0 padding-r0">
            <div className="loss-category-progress-wrap">
              <div className="loss-analysis-category-button-wrap blue">
                <div className="img-wrap">
                  <img
                    src={
                      require("../../assets/img/loss-analysis-page-img/production.svg")
                        .default
                    }
                    alt="production"
                  />
                </div>
                <span className="text">Production Process Level</span>
                <div class="note">
                  <img
                    src={
                      require("../../assets/img/loss-analysis-page-img/notify.svg")
                        .default
                    }
                    alt="Notes"
                  />
                </div>
                <div className="card-note-popup">
                  <div className="card-note-tooltip-wrap dark-blue">
                    <div className="card-note-tooltip">
                      <h2 className="heading">Production Process Level</h2>
                      <p className="content">
                        Contains Loss Categories that are analyzed based on the
                        different production processes existing in the plant
                        operations.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="loss-analysis-category-col md-col-8 walk-step-1">
            <div className="loss-analysis-category-button-wrap disabled gray">
              <span className="text">Line Efficiency Losses</span>
              <div class="note">
                <img
                  src={
                    require("../../assets/img/loss-analysis-page-img/disabled.svg")
                      .default
                  }
                  alt="Disabled"
                />
              </div>
              <div className="card-note-popup">
                <div className="card-note-tooltip-wrap dark-gray">
                  <div className="card-note-tooltip">
                    <h2 className="heading">Line Efficiency Losses</h2>
                    <p className="content">
                      Consists of the various Loss Categories affecting the
                      efficiency of a production process, following the OEE
                      calculation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="loss-cat-card-row line left-cat-row">
              <div
                className={`loss-cat-card-col mb-1 padding-left-0 ${tempListAssing1[1] || tempListAssing1[101] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[1] || tempListAssing1[101] ? "" : "isidnone"
                  } `}
              >

                <div onClick={locked_sections[101] && locked_sections[101][1] ? () => { } : () => handleSectionClick(`/lossanalysisques/questionaire/${slug}/101/1`, 101, 1)} className={`${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 1 ? 'hide-loss-card-body' : ''} ${isCardHover == 1 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 1)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/plan.svg")
                              .default
                          }
                          alt="Planned"
                        />
                      </div>
                      <div className="text-wrap">
                        <span className="txt">Planned Downtime</span>
                        <a
                          href={`/lossanalysisques/questionaire/${slug}/101/1`}
                          className="btn-link"
                        >
                          {tempListAssing[101]?.progress == null ||
                            tempListAssing[101]?.progress == 0
                            ? "+ Start section"
                            : tempListAssing[101]?.progress > 0 &&
                              tempListAssing[101]?.progress < 100
                              ? "+ Continue Section"
                              : "+ View Section"}
                        </a>
                      </div>
                    </div>
                    {locked_sections[101] && locked_sections[101][1] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 1 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[101][1]?.avcolor}`}>{locked_sections[101][1]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[101][1]?.profile_pic ? 'cir-transparent' : locked_sections[101][1]?.avcolor}`}>
                              {locked_sections[101][1]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[101][1]?.profile_pic}`}
                                  alt={locked_sections[101][1]?.profile_pic} />
                                :
                                locked_sections[101][1]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[101][1]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/lossanalysisques/questionaire/${slug}/101/1`}
                          className={`bottom-btn-link ${tempListAssing[1]?.invited_category == 101 &&
                            tempListAssing[1]?.invited_sub_category == 1
                            ? tempListAssing[1]?.progress == null ||
                              tempListAssing[1]?.progress == 0
                              ? (sectiondata.na_data.includes(1) ? 'not-available' : (sectiondata.na_progress_data.includes(1) ? "orange" : "red"))
                              : tempListAssing[1]?.progress > 0 &&
                                tempListAssing[1]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {(tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0) && tempListAssing[101]?.subsection_id==1 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 && tempListAssing[101]?.subsection_id==1 ?'In Progress':'completed' } */}
                          {tempListAssing[1]?.invited_category == 101 &&
                            tempListAssing[1]?.invited_sub_category == 1
                            ? tempListAssing[1]?.progress == null ||
                              tempListAssing[1]?.progress == 0
                              ? (sectiondata.na_data.includes(1) ? "Data N/A" : (sectiondata.na_progress_data.includes(1) ? "In Progress" : "To Do"))
                              : tempListAssing[1]?.invited_category == 101 &&
                                tempListAssing[1]?.invited_sub_category == 1 &&
                                tempListAssing[1]?.progress > 0 &&
                                tempListAssing[1]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                        {/* <Link to="/" className="bottom-btn-link green completed">COMPLETED</Link>
                                            <Link to="/" className="bottom-btn-link green review">IN REVIEW</Link> */}
                      </div>

                      <div className="right-section use-rgt22" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                        {tempListAssing2[1] ||
                          (tempListAssing2[101] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[0].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 1); }}>
                              <div className="la-profile-cir-ul">
                                {s11.slice(0, 2).map((item, index) =>

                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>

                                )}


                                {s11.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s11.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup ${subsectionid[0].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip  user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s11.map((item, index) => {
                              return (
                                <>
                                  <div className="pop-wrap">
                                    <div className="img-box">

                                      <span className="us-name">
                                        {!item.profile_pic ?
                                          <div className={`image-div ${item.avcolor}`} >
                                            {item.Shortname.toUpperCase()}
                                          </div>
                                          :
                                          <div className={`image-div cir-transparent`} >
                                            <img className="h__profile__img_cir"

                                              src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                              alt={item.profile_pic}
                                            />
                                          </div>
                                        }
                                      </span>

                                    </div>
                                    <div className="user-name">
                                      <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                      <span className="designation">{item.profile}</span>
                                    </div>
                                  </div>
                                </>
                              )
                            })}

                          </div>
                        </div>
                      </div>
                      <div className="card-note-popup blue">
                        <div className="card-note-tooltip-wrap">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Planned Downtime</h2>
                            <p className="content">
                              The time a production process or line is not
                              operating due to planned non-production
                              activities that need to take place.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col mb-1 ${tempListAssing1[2] || tempListAssing1[101] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[2] || tempListAssing1[101] ? "" : "isidnone"
                  }`}
              >
                <div onClick={locked_sections[101] && locked_sections[101][2] ? '' : () => handleSectionClick(`/lossanalysisques/questionaire/${slug}/101/2`, 101, 2)}
                  className={`${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 2 ? 'hide-loss-card-body' : ''} ${isCardHover == 2 && 'hover-active'}`}
                  onMouseMove={(e) => HoverActiveHandle(e, 2)}
                  onMouseLeave={() => setIsCardHover(null)}             >
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/unplanned-downtime.svg")
                              .default
                          }
                          alt="UnPlanned"
                        />
                      </div>
                      <div className="text-wrap">
                        <span className="txt">Unplanned Downtime</span>
                      </div>
                    </div>
                    {locked_sections[101] && locked_sections[101][2] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 2 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[101][2]?.avcolor}`}>{locked_sections[101][2]?.shortname}</div> */}

                            <div
                              className={`circle ${locked_sections[101][2]?.profile_pic ? 'cir-transparent' : locked_sections[101][2]?.avcolor}`}>
                              {locked_sections[101][2]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[101][2]?.profile_pic}`}
                                  alt={locked_sections[101][2]?.profile_pic} />
                                :
                                locked_sections[101][2]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[101][2]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/lossanalysisques/questionaire/${slug}/101/2`}
                          className={`bottom-btn-link ${tempListAssing[2]?.invited_category == 101 &&
                            tempListAssing[2]?.invited_sub_category == 2
                            ? tempListAssing[2]?.progress == null ||
                              tempListAssing[2]?.progress == 0
                              ? (sectiondata.na_data.includes(2) ? 'not-available' : (sectiondata.na_progress_data.includes(2) ? "orange" : "red"))
                              : tempListAssing[2]?.progress > 0 &&
                                tempListAssing[2]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {(tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0) &&  tempListAssing[101]?.subsection_id==2  ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 &&  tempListAssing[101]?.subsection_id==2 ?'In Progress':'completed' } */}
                          {tempListAssing[2]?.invited_category == 101 &&
                            tempListAssing[2]?.invited_sub_category == 2
                            ? tempListAssing[2]?.progress == null ||
                              tempListAssing[2]?.progress == 0
                              ? (sectiondata.na_data.includes(2) ? "Data N/A" : (sectiondata.na_progress_data.includes(2) ? "In Progress" : "To Do"))
                              : tempListAssing[2]?.invited_category == 101 &&
                                tempListAssing[2]?.invited_sub_category == 2 &&
                                tempListAssing[2]?.progress > 0 &&
                                tempListAssing[2]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[2] ||
                          (tempListAssing2[101] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[1].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 2); }}>
                              <div className="la-profile-cir-ul">
                                {s12.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s12.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s12.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup ${subsectionid[1].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s12.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>
                      <div className="card-note-popup blue">
                        <div className="card-note-tooltip-wrap">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Unplanned Downtime</h2>
                            <p className="content">
                              The time a production process or line is not
                              operating due to unplanned events.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col mb-1 padding-r0 ${tempListAssing1[3] || tempListAssing1[101] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[3] || tempListAssing1[101] ? "" : "isidnone"
                  }`}
              >
                <div onClick={locked_sections[101] && locked_sections[101][3] ? '' : () => handleSectionClick(`/lossanalysisques/questionaire/${slug}/101/3`, 101, 3)} className={`${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 3 ? 'hide-loss-card-body' : ''} ${isCardHover == 3 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 3)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/minor-stops.svg")
                              .default
                          }
                          alt="Minor"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">Minor Stops</span>
                      </div>
                    </div>
                    {locked_sections[101] && locked_sections[101][3] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 3 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            <div
                              className={`circle ${locked_sections[101][3]?.profile_pic ? 'cir-transparent' : locked_sections[101][3]?.avcolor}`}>
                              {locked_sections[101][3]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[101][3]?.profile_pic}`}
                                  alt={locked_sections[101][3]?.profile_pic} />
                                :
                                locked_sections[101][3]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[101][3]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/lossanalysisques/questionaire/${slug}/101/3`}
                          className={`bottom-btn-link ${tempListAssing[3]?.invited_category == 101 &&
                            tempListAssing[3]?.invited_sub_category == 3
                            ? tempListAssing[3]?.progress == null ||
                              tempListAssing[3]?.progress == 0
                              ? (sectiondata.na_data.includes(3) ? 'not-available' : (sectiondata.na_progress_data.includes(3) ? "orange" : "red"))
                              : tempListAssing[3]?.progress > 0 &&
                                tempListAssing[3]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 ?'In Progress':'completed' } */}
                          {tempListAssing[3]?.invited_category == 101 &&
                            tempListAssing[3]?.invited_sub_category == 3
                            ? tempListAssing[3]?.progress == null ||
                              tempListAssing[3]?.progress == 0
                              ? (sectiondata.na_data.includes(3) ? "Data N/A" : (sectiondata.na_progress_data.includes(3) ? "In Progress" : "To Do"))
                              : tempListAssing[3]?.invited_category == 101 &&
                                tempListAssing[3]?.invited_sub_category == 3 &&
                                tempListAssing[3]?.progress > 0 &&
                                tempListAssing[3]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[3] ||
                          (tempListAssing2[101] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[2].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 3); }}>
                              <div className="la-profile-cir-ul">
                                {s13.slice(0, 2).map((item, index) =>

                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s13.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s13.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          ""
                        )}
                        <div className={`assigned-user-popup ${subsectionid[2].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s13.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>
                      <div className="card-note-popup blue">
                        <div className="card-note-tooltip-wrap">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Minor Stops</h2>
                            <p className="content">
                              The time the production process or line is not
                              operating due to small stops (usually with a
                              duration of less than 5 minutes).
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col padding-left-0 ${tempListAssing1[4] || tempListAssing1[101] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[4] || tempListAssing1[101] ? "" : "isidnone"
                  }`}
              >
                <div onClick={locked_sections[101] && locked_sections[101][4] ? '' : () => handleSectionClick(`/lossanalysisques/questionaire/${slug}/101/4`, 101, 4)} className={`${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 4 ? 'hide-loss-card-body' : ''} ${isCardHover == 4 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 4)}
                  onMouseLeave={() => setIsCardHover(null)} >
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/speed-losses.svg")
                              .default
                          }
                          alt="Speed Losses"
                        />
                        {/* <div className="card-note-popup blue">
                          <div className="card-note-tooltip-wrap">
                            <div className="card-note-tooltip">
                              <h2 className="heading">Planned Downtime</h2>
                              <p className="content">
                                The time a production process or line is not
                                operating due to planned non-production
                                activities that need to take place.
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="text-wrap">
                        <span className="txt">Speed Losses</span>
                      </div>
                    </div>
                    {locked_sections[101] && locked_sections[101][4] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 4 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[101][4]?.avcolor}`}>{locked_sections[101][4]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[101][4]?.profile_pic ? 'cir-transparent' : locked_sections[101][4]?.avcolor}`}>
                              {locked_sections[101][4]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[101][4]?.profile_pic}`}
                                  alt={locked_sections[101][4]?.profile_pic} />
                                :
                                locked_sections[101][4]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[101][4]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/lossanalysisques/questionaire/${slug}/101/4`}
                          className={`bottom-btn-link ${tempListAssing[4]?.invited_category == 101 &&
                            tempListAssing[4]?.invited_sub_category == 4
                            ? tempListAssing[4]?.progress == null ||
                              tempListAssing[4]?.progress == 0
                              ? (sectiondata.na_data.includes(4) ? 'not-available' : (sectiondata.na_progress_data.includes(4) ? "orange" : "red"))
                              : tempListAssing[4]?.progress > 0 &&
                                tempListAssing[4]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 ?'In Progress':'completed' } */}
                          {tempListAssing[4]?.invited_category == 101 &&
                            tempListAssing[4]?.invited_sub_category == 4
                            ? tempListAssing[4]?.progress == null ||
                              tempListAssing[4]?.progress == 0
                              ? (sectiondata.na_data.includes(4) ? "Data N/A" : (sectiondata.na_progress_data.includes(4) ? "In Progress" : "To Do"))
                              : tempListAssing[4]?.invited_category == 101 &&
                                tempListAssing[4]?.invited_sub_category == 4 &&
                                tempListAssing[4]?.progress > 0 &&
                                tempListAssing[4]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[4] ||
                          (tempListAssing2[101] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[3].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 4); }}>
                              <div className="la-profile-cir-ul">
                                {s14.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s14.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s14.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          ""
                        )}
                        <div className={`assigned-user-popup ${subsectionid[3].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s14.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>
                      <div className="card-note-popup blue">
                        <div className="card-note-tooltip-wrap">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Speed Losses</h2>
                            <p className="content">
                              The time the production process or line is running
                              slower than the Ideal Cycle Time (e.g. less than
                              design speed).
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col ${tempListAssing1[5] || tempListAssing1[101] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[5] || tempListAssing1[101] ? "" : "isidnone"
                  }`}
              >
                <div onClick={locked_sections[101] && locked_sections[101][5] ? '' : () => handleSectionClick(`/lossanalysisques/questionaire/${slug}/101/5`, 101, 5)} className={`${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 5 ? 'hide-loss-card-body' : ''} ${isCardHover == 5 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 5)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/material-waste.svg")
                              .default
                          }
                          alt="Material Waste"
                        />
                      </div>
                      <div className="text-wrap">
                        <span className="txt">Material Waste</span>
                        <Link
                          to={`/lossanalysisques/questionaire/${slug}/101/5`}
                          className="btn-link"
                        >
                          {sectdata[categ?.indexOf("101")]?.progress == null
                            ? "+ Start section"
                            : sectdata[categ?.indexOf("101")]?.progress > 0 &&
                              sectdata[categ?.indexOf("101")]?.progress < 100
                              ? "+ Continue Section"
                              : "+ View Section"}
                        </Link>
                      </div>
                    </div>
                    {locked_sections[101] && locked_sections[101][5] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 5 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[101][5]?.avcolor}`}>{locked_sections[101][5]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[101][5]?.profile_pic ? 'cir-transparent' : locked_sections[101][5]?.avcolor}`}>
                              {locked_sections[101][5]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[101][5]?.profile_pic}`}
                                  alt={locked_sections[101][5]?.profile_pic} />
                                :
                                locked_sections[101][5]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[101][5]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/lossanalysisques/questionaire/${slug}/101/5`}
                          className={`bottom-btn-link ${tempListAssing[5]?.invited_category == 101 &&
                            tempListAssing[5]?.invited_sub_category == 5
                            ? tempListAssing[5]?.progress == null ||
                              tempListAssing[5]?.progress == 0
                              ? (sectiondata.na_data.includes(5) ? 'not-available' : (sectiondata.na_progress_data.includes(5) ? "orange" : "red"))
                              : tempListAssing[5]?.progress > 0 &&
                                tempListAssing[5]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 ?'In Progress':'completed' } */}
                          {tempListAssing[5]?.invited_category == 101 &&
                            tempListAssing[5]?.invited_sub_category == 5
                            ? tempListAssing[5]?.progress == null ||
                              tempListAssing[5]?.progress == 0
                              ? (sectiondata.na_data.includes(5) ? "Data N/A" : (sectiondata.na_progress_data.includes(5) ? "In Progress" : "To Do"))
                              : tempListAssing[5]?.invited_category == 101 &&
                                tempListAssing[5]?.invited_sub_category == 5 &&
                                tempListAssing[5]?.progress > 0 &&
                                tempListAssing[5]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[5] ||
                          (tempListAssing2[101] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[4].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 5); }}>
                              <div className="la-profile-cir-ul">
                                {s15.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s15.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s15.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup ${subsectionid[4].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s15.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup blue">
                        <div className="card-note-tooltip-wrap">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Material Waste</h2>
                            <p className="content">
                              The cost of units scrapped during the production
                              process leading to material waste.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="loss-analysis-category-col md-col-4">
            <div className="loss-analysis-category-button-wrap disabled gray">
              <span className="text">Operator Effort Losses</span>
              <div class="note">
                <img
                  src={
                    require("../../assets/img/loss-analysis-page-img/disabled.svg")
                      .default
                  }
                  alt="Disabled"
                />
              </div>
              <div className="card-note-popup">
                <div className="card-note-tooltip-wrap dark-gray">
                  <div className="card-note-tooltip">
                    <h2 className="heading">Operator Effort Losses</h2>
                    <p className="content">
                      The key routine activities taking place by the direct
                      labor (line operators/technicians) on the production lines
                      including any 3rd party contractors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="loss-cat-card-row operator right-col line">
              <div
                className={`loss-cat-card-col padding-left-0 ${tempListAssing1[103] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[103] ? "" : "isidnone"}`}
              >
                <div onClick={locked_sections[103] && locked_sections[103][0] ? '' : () => handleSectionClick(`/operator-effort-losses/questionaire/${slug}/103`, 103, 0)} className={`${isCardLockedHover['section'] === 103 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 6 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 6)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  {isCardLockedHover[103] === 0}
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/operator-losses.svg")
                              .default
                          }
                          alt="Operator -Losses"
                        />
                      </div>
                      <div className="text-wrap">
                        <span className="txt">Operator Losses</span>
                        <a
                          href={`/operator-effort-losses/questionaire/${slug}/103`}
                          className="btn-link continue"
                        >
                          {/* + View Section */}
                          {/* { sectdata[categ?.indexOf('103')]?.progress==null  ? "+ Start section" : sectdata[categ?.indexOf('103')]?.progress>0 && sectdata[categ?.indexOf('103')]?.progress<100?'+ Continue Section':'+ View Section' } */}
                          {tempListAssing[103]?.progress == null ||
                            tempListAssing[103]?.progress == 0
                            ? "+ Start section"
                            : tempListAssing[103]?.progress > 0 &&
                              tempListAssing[103]?.progress < 100
                              ? "+ Continue Section"
                              : "+ View Section"}
                        </a>
                      </div>
                    </div>
                    {locked_sections[103] && locked_sections[103][0] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 103, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[103][0]?.avcolor}`}>{locked_sections[103][0]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[103][0]?.profile_pic ? 'cir-transparent' : locked_sections[103][0]?.avcolor}`}>
                              {locked_sections[103][0]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[103][0]?.profile_pic}`}
                                  alt={locked_sections[103][0]?.profile_pic} />
                                :
                                locked_sections[103][0]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[103][0]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/operator-effort-losses/questionaire/${slug}/103`}
                          className={`bottom-btn-link ${tempListAssing[103]?.progress == null ||
                            tempListAssing[103]?.progress == 0
                            ? (sectiondata.na_data.includes(103) ? 'not-available' : (sectiondata.na_progress_data.includes(103) ? "orange" : "red"))
                            : tempListAssing[103]?.progress > 0 &&
                              tempListAssing[103]?.progress < 100
                              ? "orange"
                              : "green"
                            }`}
                        >
                          {tempListAssing[103]?.progress == null ||
                            tempListAssing[103]?.progress == 0
                            ? (sectiondata.na_data.includes(103) ? "Data N/A" : (sectiondata.na_progress_data.includes(103) ? "In Progress" : "To Do"))
                            : tempListAssing[103]?.progress > 0 &&
                              tempListAssing[103]?.progress < 100
                              ? "In Progress"
                              : "completed"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[103] ||
                          (tempListAssing2[103] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${sectionid[3].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 103, 0); }}>
                              <div className="la-profile-cir-ul">
                                {s2.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s2.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s2.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup ${sectionid[3].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s2.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup blue">
                        <div className="card-note-tooltip-wrap">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Operator Losses</h2>
                            <p className="content">
                              The key routine activities taking place by the
                              direct labor (line operators/technicians) on the
                              production lines including any 3rd party
                              contractors.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loss-analysis-category-row">
          <div className="loss-analysis-category-col md-col-8">
            <div className="loss-analysis-category-col border-bottom margin-left">
              <div className="loss-analysis-category-button-wrap green">
                <div className="img-wrap">
                  <img
                    src={
                      require("../../assets/img/loss-analysis-page-img/key-plant.svg")
                        .default
                    }
                    alt="production"
                  />
                </div>
                <span className="text">Key Plant Functions</span>
                <div class="note">
                  <img
                    src={
                      require("../../assets/img/loss-analysis-page-img/note-green.svg")
                        .default
                    }
                    alt="Notes"
                  />
                </div>
                <div className="card-note-popup">
                  <div className="card-note-tooltip-wrap dark-green">
                    <div className="card-note-tooltip">
                      <h2 className="heading">Key Plant Functions</h2>
                      <p className="content">
                        Consists of the key activities taking place by the main
                        supporting functions of the plant operations.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="loss-cat-card-row bottom left-cat-row">
              <div
                className={`loss-cat-card-col mb-1 padding-left-0 ${tempListAssing1[107] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[107] ? "" : "isidnone"}`}
              >
                <div onClick={locked_sections[107] && locked_sections[107][0] ? '' : () => handleSectionClick(`/keyplant/maintenance-losses/questionaire/${slug}/107`, 107, 0)} className={`${isCardLockedHover['section'] === 107 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 7 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 7)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/maintenance-losses.svg")
                              .default
                          }
                          alt="Maintenance Losses"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">Maintenance Losses</span>
                        <a
                          href={`/keyplant/maintenance-losses/questionaire/${slug}/107`}
                          className="btn-link"
                        >
                          {tempListAssing[107]?.progress == null ||
                            tempListAssing[107]?.progress == 0
                            ? "+ Start section"
                            : tempListAssing[107]?.progress > 0 &&
                              tempListAssing[107]?.progress < 100
                              ? "+ Continue Section"
                              : "+ View Section"}
                        </a>
                      </div>
                    </div>
                    {locked_sections[107] && locked_sections[107][0] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 107, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[107][0]?.avcolor}`}>{locked_sections[107][0]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[107][0]?.profile_pic ? 'cir-transparent' : locked_sections[107][0]?.avcolor}`}>
                              {locked_sections[107][0]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[107][0]?.profile_pic}`}
                                  alt={locked_sections[107][0]?.profile_pic} />
                                :
                                locked_sections[107][0]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[107][0]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/keyplant/maintenance-losses/questionaire/${slug}/107`}
                          className={`bottom-btn-link ${tempListAssing[107]?.progress == null ||
                            tempListAssing[107]?.progress == 0
                            ? (sectiondata.na_data.includes(107) ? 'not-available' : "red")
                            : tempListAssing[107]?.progress > 0 &&
                              tempListAssing[107]?.progress < 100
                              ? "orange"
                              : "green"
                            }`}
                        >
                          {tempListAssing[107]?.progress == null ||
                            tempListAssing[107]?.progress == 0
                            ? (sectiondata.na_data.includes(107) ? "Data N/A" : "To Do")
                            : tempListAssing[107]?.progress > 0 &&
                              tempListAssing[107]?.progress < 100
                              ? "In Progress"
                              : "completed"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {(tempListAssing2[107] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${sectionid[7].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 107, 0); }}>
                              <div className="la-profile-cir-ul">
                                {s3.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s3.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s3.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${sectionid[7].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s3.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup">
                        <div className="card-note-tooltip-wrap green">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Maintenance Losses</h2>
                            <p className="content">
                              The key routine activities taking place by the
                              Maintenance or Engineering function of the
                              plant.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col mb-1 ${tempListAssing1[106] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[106] ? "" : "isidnone"}`}
              >
                <div onClick={locked_sections[106] && locked_sections[106][0] ? '' : () => handleSectionClick(`/keyplant/maintenance-losses/questionaire/${slug}/106`, 106, 0)} className={`${isCardLockedHover['section'] === 106 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 8 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 8)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/it-losses.svg")
                              .default
                          }
                          alt="IT Losses"
                        />
                      </div>
                      <div className="text-wrap">
                        <span className="txt">IT Losses</span>
                      </div>
                    </div>
                    {locked_sections[106] && locked_sections[106][0] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 106, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[106][0]?.avcolor}`}>{locked_sections[106][0]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[106][0]?.profile_pic ? 'cir-transparent' : locked_sections[106][0]?.avcolor}`}>
                              {locked_sections[106][0]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[106][0]?.profile_pic}`}
                                  alt={locked_sections[106][0]?.profile_pic} />
                                :
                                locked_sections[106][0]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[106][0]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/keyplant/it-losses/questionaire/${slug}/106`}
                          className={`bottom-btn-link ${tempListAssing[106]?.progress == null ||
                            tempListAssing[106]?.progress == 0
                            ? (sectiondata.na_data.includes(106) ? 'not-available' : "red")
                            : tempListAssing[106]?.progress > 0 &&
                              tempListAssing[106]?.progress < 100
                              ? "orange"
                              : "green"
                            }`}
                        >
                          {tempListAssing[106]?.progress == null ||
                            tempListAssing[106]?.progress == 0
                            ? (sectiondata.na_data.includes(106) ? "Data N/A" : "To Do")
                            : tempListAssing[106]?.progress > 0 &&
                              tempListAssing[106]?.progress < 100
                              ? "In Progress"
                              : "completed"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {(tempListAssing2[106] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${sectionid[6].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 106, 0); }}>
                              <div className="la-profile-cir-ul">
                                {s8.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s8.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s8.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${sectionid[6].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s8.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup">
                        <div className="card-note-tooltip-wrap green">
                          <div className="card-note-tooltip">
                            <h2 className="heading">IT Losses</h2>
                            <p className="content">
                              The key routine activities taking place by the
                              IT function of the plant.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col mb-1 padding-r0 ${tempListAssing1[102] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[102] ? "" : "isidnone"}`}
              >
                <div onClick={locked_sections[102] && locked_sections[102][0] ? '' : () => handleSectionClick(`/keyplant/warehouse-losses/questionaire/${slug}/102`, 102, 0)} className={`${isCardLockedHover['section'] === 102 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 9 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 9)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/warehouse-losses.svg")
                              .default
                          }
                          alt="Warehouse-Losses"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">Warehouse Losses</span>
                      </div>
                    </div>
                    {locked_sections[102] && locked_sections[102][0] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 102, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[102][0]?.avcolor}`}>{locked_sections[102][0]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[102][0]?.profile_pic ? 'cir-transparent' : locked_sections[102][0]?.avcolor}`}>
                              {locked_sections[102][0]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[102][0]?.profile_pic}`}
                                  alt={locked_sections[102][0]?.profile_pic} />
                                :
                                locked_sections[102][0]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[102][0]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/keyplant/warehouse-losses/questionaire/${slug}/102`}
                          className={`bottom-btn-link ${tempListAssing[102]?.progress == null ||
                            tempListAssing[102]?.progress == 0
                            ? (sectiondata.na_data.includes(102) ? 'not-available' : "red")
                            : tempListAssing[102]?.progress > 0 &&
                              tempListAssing[102]?.progress < 100
                              ? "orange"
                              : "green"
                            }`}
                        >
                          {tempListAssing[102]?.progress == null ||
                            tempListAssing[102]?.progress == 0
                            ? (sectiondata.na_data.includes(102) ? "Data N/A" : "To Do")
                            : tempListAssing[102]?.progress > 0 &&
                              tempListAssing[102]?.progress < 100
                              ? "In Progress"
                              : "completed"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {(tempListAssing2[102] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${sectionid[2].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 102, 0); }}>
                              <div className="la-profile-cir-ul">
                                {s5.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s5.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s5.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${sectionid[2].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s5.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup">
                        <div className="card-note-tooltip-wrap green">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Warehouse Losses</h2>
                            <p className="content">
                              The key routine activities taking place by the
                              Warehouse or Material Handling function of the
                              plant.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col padding-left-0 ${tempListAssing1[104] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[104] ? "" : "isidnone"}`}
              >
                <div onClick={locked_sections[104] && locked_sections[104][0] ? '' : () => handleSectionClick(`/keyplant/ehs-losses/questionaire/${slug}/104`, 104, 0)} className={`${isCardLockedHover['section'] === 104 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 10 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 10)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/EHS-losses.svg")
                              .default
                          }
                          alt="EHS Losses"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">EHS Losses</span>
                      </div>
                    </div>
                    {locked_sections[104] && locked_sections[104][0] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 104, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[104][0]?.avcolor}`}>{locked_sections[104][0]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[104][0]?.profile_pic ? 'cir-transparent' : locked_sections[104][0]?.avcolor}`}>
                              {locked_sections[104][0]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[104][0]?.profile_pic}`}
                                  alt={locked_sections[104][0]?.profile_pic} />
                                :
                                locked_sections[104][0]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[104][0]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/keyplant/ehs-losses/questionaire/${slug}/104`}
                          className={`bottom-btn-link ${tempListAssing[104]?.progress == null ||
                            tempListAssing[104]?.progress == 0
                            ? (sectiondata.na_data.includes(104) ? 'not-available' : "red")
                            : tempListAssing[104]?.progress > 0 &&
                              tempListAssing[104]?.progress < 100
                              ? "orange"
                              : "green"
                            }`}
                        >
                          {tempListAssing[104]?.progress == null ||
                            tempListAssing[104]?.progress == 0
                            ? (sectiondata.na_data.includes(104) ? "Data N/A" : "To Do")
                            : tempListAssing[104]?.progress > 0 &&
                              tempListAssing[104]?.progress < 100
                              ? "In Progress"
                              : "completed"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {(tempListAssing2[104] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${sectionid[4].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 104, 0); }}>
                              <div className="la-profile-cir-ul">
                                {s6.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s6.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s6.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${sectionid[4].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s6.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup">
                        <div className="card-note-tooltip-wrap green">
                          <div className="card-note-tooltip">
                            <h2 className="heading">EHS Losses</h2>
                            <p className="content">
                              The key routine activities taking place by the
                              EHS function of the plant.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col ${tempListAssing1[105] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[105] ? "" : "isidnone"}`}
              >
                <div onClick={locked_sections[105] && locked_sections[105][0] ? '' : () => handleSectionClick(`/keyplant/quality-losses/questionaire/${slug}/105`, 105, 0)} className={`${isCardLockedHover['section'] === 105 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 11 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 11)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/quality-losses.svg")
                              .default
                          }
                          alt="Quality"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">Quality Losses</span>
                      </div>
                    </div>
                    {locked_sections[105] && locked_sections[105][0] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 105, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[105][0]?.avcolor}`}>{locked_sections[105][0]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[105][0]?.profile_pic ? 'cir-transparent' : locked_sections[105][0]?.avcolor}`}>
                              {locked_sections[105][0]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[105][0]?.profile_pic}`}
                                  alt={locked_sections[105][0]?.profile_pic} />
                                :
                                locked_sections[105][0]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[105][0]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/keyplant/quality-losses/questionaire/${slug}/105`}
                          className={`bottom-btn-link ${tempListAssing[105]?.progress == null ||
                            tempListAssing[105]?.progress == 0
                            ? (sectiondata.na_data.includes(105) ? 'not-available' : "red")
                            : tempListAssing[105]?.progress > 0 &&
                              tempListAssing[105]?.progress < 100
                              ? "orange"
                              : "green"
                            }`}
                        >
                          {tempListAssing[105]?.progress == null ||
                            tempListAssing[105]?.progress == 0
                            ? (sectiondata.na_data.includes(105) ? "Data N/A" : "To Do")
                            : tempListAssing[105]?.progress > 0 &&
                              tempListAssing[105]?.progress < 100
                              ? "In Progress"
                              : "completed"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {(tempListAssing2[105] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${sectionid[5].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 105, 0); }}>
                              <div className="la-profile-cir-ul">
                                {s7.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s7.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s7.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${sectionid[5].id == cate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s7.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup">
                        <div className="card-note-tooltip-wrap green">
                          <div className="card-note-tooltip">
                            <h2 className="heading">Quality Losses</h2>
                            <p className="content">
                              The key routine activities taking place by the
                              Quality function of the plant.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="loss-analysis-category-col md-col-4">
            <div className="loss-analysis-category-col border-bottom margin-left">
              <div className="loss-analysis-category-button-wrap orange">
                <div className="img-wrap">
                  <img
                    src={
                      require("../../assets/img/loss-analysis-page-img/finance-data.svg")
                        .default
                    }
                    alt="finance-data"
                  />
                </div>
                <span className="text">Finance Data</span>
                <div class="note">
                  <img
                    src={
                      require("../../assets/img/loss-analysis-page-img/note-orange.svg")
                        .default
                    }
                    alt="Notes"
                  />
                </div>
                <div className="card-note-popup">
                  <div className="card-note-tooltip-wrap dark-orange">
                    <div className="card-note-tooltip">
                      <h2 className="heading">Finance Data</h2>
                      <p className="content">
                        Contains the breakdown of Manufacturing Cost, FTEs
                        per function/department and general plant information.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="loss-cat-card-row bottom right-col">
              <div
                className={`loss-cat-card-col mb-1 padding-left-0 ${tempListAssing1[108] || tempListAssing1[6] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[108] || tempListAssing1[6] ? "" : "isidnone"
                  }`}
              >
                <div onClick={locked_sections[108] && locked_sections[108][6] ? '' : () => handleSectionClick(`/general-information/questionaire/${slug}/108/6`, 108, 6)} className={`${isCardLockedHover['section'] === 108 && isCardLockedHover['subsection'] === 6 ? 'hide-loss-card-body' : ''} ${isCardHover == 12 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 12)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/general-information.svg")
                              .default
                          }
                          alt="general-information"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">General Information</span>
                      </div>
                    </div>
                    {locked_sections[108] && locked_sections[108][6] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 108, subsection: 6 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[108][6]?.avcolor}`}>{locked_sections[108][6]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[108][6]?.profile_pic ? 'cir-transparent' : locked_sections[108][6]?.avcolor}`}>
                              {locked_sections[108][6]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[108][6]?.profile_pic}`}
                                  alt={locked_sections[108][6]?.profile_pic} />
                                :
                                locked_sections[108][6]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[108][6]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/general-information/questionaire/${slug}/108/6`}
                          className={`bottom-btn-link ${tempListAssing[6]?.invited_category == 108 &&
                            tempListAssing[6]?.invited_sub_category == 6
                            ? tempListAssing[6]?.progress == null ||
                              tempListAssing[6]?.progress == 0
                              ? "red"
                              : tempListAssing[6]?.progress > 0 &&
                                tempListAssing[6]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {tempListAssing[108]?.progress==null || tempListAssing[108]?.progress==0 ? "To Do" : tempListAssing[108]?.progress>0 && tempListAssing[108]?.progress<100 ?'In Progress':'completed' } */}
                          {tempListAssing[6]?.invited_category == 108 &&
                            tempListAssing[6]?.invited_sub_category == 6
                            ? tempListAssing[6]?.progress == null ||
                              tempListAssing[6]?.progress == 0
                              ? "To Do"
                              : tempListAssing[6]?.invited_category == 108 &&
                                tempListAssing[6]?.invited_sub_category == 6 &&
                                tempListAssing[6]?.progress > 0 &&
                                tempListAssing[6]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[6] || (tempListAssing2[108] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[5].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 6); }}>
                              <div className="la-profile-cir-ul">
                                {s16.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s16.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s16.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${subsectionid[5].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s16.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup">
                        <div className="card-note-tooltip-wrap orange">
                          <div className="card-note-tooltip">
                            <h2 className="heading">General Information</h2>
                            <p className="content">
                              Key plant data consisting of KPIs, plant
                              operational time and volume output.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col mb-1 padding-r0  ${tempListAssing1[108] || tempListAssing1[8] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[108] || tempListAssing1[8] ? "" : "isidnone"
                  }`}
              >
                <div onClick={locked_sections[108] && locked_sections[108][8] ? '' : () => handleSectionClick(`/fte-breakdown/questionaire/${slug}/108/8`, 108, 8)} className={`${isCardLockedHover['section'] === 108 && isCardLockedHover['subsection'] === 8 ? 'hide-loss-card-body' : ''} ${isCardHover == 13 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 13)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/FTE-breakdown.svg")
                              .default
                          }
                          alt="FTE-breakdown"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">FTE Breakdown</span>
                      </div>
                    </div>
                    {locked_sections[108] && locked_sections[108][8] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 108, subsection: 8 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[108][8]?.avcolor}`}>{locked_sections[108][8]?.shortname}</div> */}
                            <div
                              className={`circle ${locked_sections[108][8]?.profile_pic ? 'cir-transparent' : locked_sections[108][8]?.avcolor}`}>
                              {locked_sections[108][8]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[108][8]?.profile_pic}`}
                                  alt={locked_sections[108][8]?.profile_pic} />
                                :
                                locked_sections[108][8]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[108][8]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/fte-breakdown/questionaire/${slug}/108/8`}
                          className={`bottom-btn-link ${tempListAssing[8]?.invited_category == 108 &&
                            tempListAssing[8]?.invited_sub_category == 8
                            ? tempListAssing[8]?.progress == null ||
                              tempListAssing[8]?.progress == 0
                              ? "red"
                              : tempListAssing[8]?.progress > 0 &&
                                tempListAssing[8]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {tempListAssing[108]?.progress==null || tempListAssing[108]?.progress==0 ? "To Do" : tempListAssing[108]?.progress>0 && tempListAssing[108]?.progress<100 ?'In Progress':'completed' } */}
                          {tempListAssing[8]?.invited_category == 108 &&
                            tempListAssing[8]?.invited_sub_category == 8
                            ? tempListAssing[8]?.progress == null ||
                              tempListAssing[8]?.progress == 0
                              ? "To Do"
                              : tempListAssing[8]?.invited_category == 108 &&
                                tempListAssing[8]?.invited_sub_category == 8 &&
                                tempListAssing[8]?.progress > 0 &&
                                tempListAssing[8]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[8] || (tempListAssing2[108] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[7].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 8); }}>
                              <div className="la-profile-cir-ul">
                                {s18.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s18.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s18.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${subsectionid[7].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          <div className="assignd-user-popup-wrap">
                            {s18.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup middle">
                        <div className="card-note-tooltip-wrap orange">
                          <div className="card-note-tooltip">
                            <h2 className="heading">FTE Breakdown</h2>
                            <p className="content">
                              A breakdown of the employees working under the
                              main functions of the plant operations.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`loss-cat-card-col padding-left-0  ${tempListAssing1[108] || tempListAssing1[7] ? "" : "userlist loss-cat-card-disabled"
                  }`}
                id={`${tempListAssing1[108] || tempListAssing1[7] ? "" : "isidnone"
                  }`}
              >
                <div onClick={locked_sections[108] && locked_sections[108][7] ? '' : () => handleSectionClick(`/manufacturing-cost/questionaire/${slug}/108/7`, 108, 7)} className={`${isCardLockedHover['section'] === 108 && isCardLockedHover['subsection'] === 7 ? 'hide-loss-card-body' : ''} ${isCardHover == 14 && 'hover-active'}`} onMouseMove={(e) => HoverActiveHandle(e, 14)}
                  onMouseLeave={() => setIsCardHover(null)}>
                  <div className="loss-cat-card">
                    <div className="loss-cat-card-body">
                      <div className="image">
                        <img
                          src={
                            require("../../assets/img/loss-analysis-page-img/manufacturing-cost.svg")
                              .default
                          }
                          alt="Manufacturing Cost"
                        />

                      </div>
                      <div className="text-wrap">
                        <span className="txt">Manufacturing Cost</span>
                      </div>
                    </div>
                    {locked_sections[108] && locked_sections[108][7] &&
                      <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 108, subsection: 7 })} onMouseLeave={() => setIsCardLockedHover({})}>
                        <button className="lock-btn">
                          <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                          Section Locked
                        </button>
                        <div className="lock-sec-popup">
                          <p> The section is temporarily locked as it is currently being edited by:</p>
                          <div className="pop-footer">
                            {/* <div className={`circle ${locked_sections[108][7]?.avcolor}`}>{locked_sections[108][7]?.shortname}</div> */}

                            <div
                              className={`circle ${locked_sections[108][7]?.profile_pic ? 'cir-transparent' : locked_sections[108][7]?.avcolor}`}>
                              {locked_sections[108][7]?.profile_pic ?
                                <img src={`${apiEndpoints.baseUrl}/assets/user-profile/${locked_sections[108][7]?.profile_pic}`}
                                  alt={locked_sections[108][7]?.profile_pic} />
                                :
                                locked_sections[108][7]?.shortname
                              }
                            </div>
                            <span className="name">{locked_sections[108][7]?.user}</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="loss-cat-card-footer">
                      <div className="left-section">
                        <a
                          href={`/manufacturing-cost/questionaire/${slug}/108/7`}
                          className={`bottom-btn-link ${tempListAssing[7]?.invited_category == 108 &&
                            tempListAssing[7]?.invited_sub_category == 7
                            ? tempListAssing[7]?.progress == null ||
                              tempListAssing[7]?.progress == 0
                              ? "red"
                              : tempListAssing[7]?.progress > 0 &&
                                tempListAssing[7]?.progress < 100
                                ? "orange"
                                : "green"
                            : "red"
                            }`}
                        >
                          {/* {tempListAssing[108]?.progress==null || tempListAssing[108]?.progress==0 ? "To Do" : tempListAssing[108]?.progress>0 && tempListAssing[108]?.progress<100 ?'In Progress':'completed' } */}
                          {tempListAssing[7]?.invited_category == 108 &&
                            tempListAssing[7]?.invited_sub_category == 7
                            ? tempListAssing[7]?.progress == null ||
                              tempListAssing[7]?.progress == 0
                              ? "To Do"
                              : tempListAssing[7]?.invited_category == 108 &&
                                tempListAssing[7]?.invited_sub_category == 7 &&
                                tempListAssing[7]?.progress > 0 &&
                                tempListAssing[7]?.progress < 100
                                ? "In Progress"
                                : "Completed"
                            : "To Do"}
                        </a>
                      </div>
                      <div className="right-section use-rgt22" onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                        {tempListAssing2[7] || (tempListAssing2[108] && user.sn) ? (
                          <>
                            <div className={`new-assign-user ${subsectionid[6].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}
                              data-title="View Users" onClick={(e) => { handlePopup(e, 0, 7); }}>
                              <div className="la-profile-cir-ul">
                                {s17.slice(0, 2).map((item, index) =>
                                  <span className="us-name">
                                    {!item.profile_pic ?
                                      <div className={`image-div ${item.avcolor}`} >
                                        {item.Shortname.toUpperCase()}
                                      </div>
                                      :
                                      <div className={`image-div cir-transparent`} >
                                        <img className="h__profile__img_cir"

                                          src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                          alt={item.profile_pic}
                                        />
                                      </div>
                                    }
                                  </span>
                                )}
                                {s17.length > 2 &&
                                  <div className="image-div circle-num">
                                    <span>{s17.length - 2}</span>
                                    {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                  </div>}
                              </div>
                            </div>
                            {/* new assign user */}
                          </>
                        ) : (
                          // <img
                          //         src={
                          //             require("../../assets/img/la-admin/assign-icon.svg")
                          //                 .default
                          //         }
                          //         alt="One user"
                          //     />
                          ""
                        )}
                        <div className={`assigned-user-popup direction-top ${subsectionid[6].id == subcate ? `${isShowUserList && !isCardHover && 'active'}` : 'false'}`}>
                          <span className="tooltip user"></span>
                          {console.log(s17)}
                          <div className="assignd-user-popup-wrap">
                            {s17.map((item, index) => {
                              return (
                                <div className="pop-wrap">
                                  <div className="img-box">
                                    <span className="us-name">
                                      {!item.profile_pic ?
                                        <div className={`image-div ${item.avcolor}`} >
                                          {item.Shortname.toUpperCase()}
                                        </div>
                                        :
                                        <div className={`image-div cir-transparent`} >
                                          <img className="h__profile__img_cir"

                                            src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                            alt={item.profile_pic}
                                          />
                                        </div>
                                      }
                                    </span>
                                  </div>
                                  <div className="user-name">
                                    <span className={`name ${item.noName ? '' : 'text-unset'}`}>{item.fullname}</span>
                                    <span className="designation">{item.profile}</span>
                                  </div>
                                </div>
                              )
                            })}

                          </div>
                        </div>
                      </div>{" "}
                      <div className="card-note-popup">
                        <div className="card-note-tooltip-wrap orange">
                          <div className="card-note-tooltip">
                            <h2 className="heading">
                              Manufacturing Cost
                            </h2>
                            <p className="content">
                              The key financial figures that constitute the
                              Manufacturing Cost of the Plant.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-to-use-ctrl-wrap">
        <HowToUseCtrl />
      </div>
      {sectiondata.isUserlosstour != 1 &&
        <UserWalkthroughView />
      }
    </>
  );
};
