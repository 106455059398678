import styled from 'styled-components'

export const AssignAccountPop = styled.div`
.replace-assessor-btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 0.4rem;
    padding: 1rem 1.2rem;
    font-size: 1.4rem;
    font=-weight: 500;
    line-height: normal;
    background: linear-gradient(92deg, rgba(19, 155, 214, 0.40) 12.22%, rgba(7, 137, 207, 0.40) 100.41%);
    &.active{
        background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
    }
}
&.new-assessment-assign-popup {
    div[aria-hidden="true"] {
      background-color: rgba(125, 134, 140, 0.3) !important;
  
      @-moz-document url-prefix() {
        background-color: rgba(125, 134, 140, 0.5) !important;
      }
    }
  
    // ms-assign-section-popup start
    .ms-assign-section-popup {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      outline: 0;
      @media (max-width: 740px){
        align-items: flex-end;
      }
      .ms-assign-section-card {
        position: relative;
        width: 62rem;
        height: 49.5rem;
        border-radius: 1.6rem;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 1.8rem 1.6rem 0rem 1.6rem;
        @media (max-width: 740px){
          border-radius: 0;
          height: 52.8rem;
          padding: 10px 10px 0 10px;
          width: 100%;
        }
        .ms-assign-header {
          display: flex;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #0d2c54;
          position: relative;
          .text-wrap {
            border-bottom: 0.1rem solid #e7ebf8;
            padding-bottom: 1.8rem;
            width: 100%;
            @media (max-width: 740px){
              padding-right: 30px;
            }
          }
  
          .close-btn {
            position: absolute;
            line-height: 0;
            right: 0;
            top: 1rem;
            transform: translateY(-50%);
            border: 0;
            @media (max-width: 740px){
              border: 1px solid #7D868C;
              border-radius: 100%;
            }
            img {
              width: 2.4rem;
            }
          }
        }
        .ms-assign-scroll-div {
          flex: 1;
          border: 0.1rem dashed #cbcfd2;
          border-radius: 0.8rem;
          padding: 1.6rem 0.5rem 1.6rem 1.6rem;
          overflow-y: auto;
          overflow-x: hidden;
          .note-text{
            display: block;
            color: #4D4D4F;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.9rem;
            width: 100%;
            .label{
                display: block;
                font-weight: 500;
            }
          }
          .edit-list-wrap{
            flex: 1;
          }
          .ms-cir-list-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            .count-member {
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #4d4d4f;
              display: inline-block;
              padding-bottom: 1rem;
            }
            .ms-remove-list {
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #e67083;
              padding-left: 1.4rem;
              @media (max-width: 740px){
                font-size: 1.2rem;
                width: 100%;
                text-align: left;
                padding-left: 10px;
              }
            }
            &.selected {
              display: none;
            }
          }
        }
        .ms-assign-content {
          flex: 1;
          display: flex;
          overflow: auto;
  
          .ms-assign-search-box {
            padding-top: 0.4rem;
            background: #ffffff;
            transition: 0.025s;
            cursor:text;
            //margin-bottom: 2rem;
            //border-bottom: 0.1rem solid #e7ebf8;
            .input-functional {
              display: flex;
              height: 100%;
              margin-bottom: 2rem;
            }
  
            &.active {
              //background: #e5f0ff;
              transition: 0.025s;
            }
  
            .ms-assign-search {
              align-items: baseline;
              position: relative;
              display: flex;
              flex-wrap: wrap;
              max-height: 12.5rem;
              overflow-y: auto;
              width: 100%;
              padding-bottom: 0.4rem;
              flex-direction: row;
  
              span {
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.7rem;
                color: #455a64;
                position: absolute;
                top: 1.28rem;
              }
  
              input {
                display: inline-block;
                outline: 0;
                height: 100%;
                font-size: 1.4rem;
                line-height: 1.7rem;
                color: #4d4d4f;
                border: 0;
                font-family: "Inter";
                padding-left: 1.2rem;
                margin-bottom: 1.2rem;
                &::placeholder {
                  color: #a6a7ad;
                  font-weight: 400;
                }
                &:disabled{
                  opacity:0.3;
                  background:#fff;
                }
              }
              input.active::placeholder {
                color: #ccc;
              }
            }
          }
  
          .ms-invite-div {
            border-bottom: 0.05rem solid #e6e6e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 2rem;
            padding-left: 2rem;
  
            p {
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 1.7rem;
              letter-spacing: 0.0005rem;
              color: #546e7a;
            }
  
            .btn-invite {
              font-weight: 500;
              font-size: 1.4rem;
              line-height: 2.6rem;
              letter-spacing: 0.046rem;
              color: #118acb;
              padding: 0.45rem 2rem;
              border-radius: 0.4rem;
              width: 12.2rem;
              min-height: 3.5rem;
              background: #e5f0ff;
              margin-top: 0.8rem;
              margin-bottom: 0.6rem;
              transition: 0.25s;
  
              &:hover {
                transition: 0.25s;
                background: #d3e6ff;
              }
  
              &:active {
                transition: 0.25s;
                box-shadow: inset 0px 0.2rem 0.6rem rgba(0, 0, 0, 0.2);
              }
            }
          }
  
          .contact-empty-div {
            font-size: 1.5rem;
            line-height: 150%;
            color: #546e7a;
            padding: 2.4rem 2rem;
  
            p {
              margin-bottom: 1.4rem;
  
              .blue-link {
                display: inline-block;
                // border-bottom:.1rem solid #546E7A;
                text-decoration: underline;
                color: #546e7a;
  
                &:hover {
                  color: #118acb;
                  cursor: default;
                }
              }
            }
          }
  
          .ms-cir-list-wrapper {
            .asmt-invite-li{
              padding-bottom: 0.6rem;
              @media (max-width: 740px){
                padding-bottom: 1.5rem;
              }
              .reminder-row{
                display: flex;
                align-items: center;
                padding-left: 4.4rem;
                @media (max-width: 740px){
                  padding-left: 0;
                }
                .remind-txt{
                  display: inline-block;
                  font-weight: 500;
                  font-size: 1rem;
                  line-height: 1.2rem;
                  letter-spacing: 0.02em;
                  color: #78909C; 
                }
                .send-reminder{
                  margin-left: 0.8rem;
                  font-weight: 500;
                  font-size: 1rem;
                  line-height: 1.2rem;
                  letter-spacing: 0.02em;
                  border: unset;
                  padding-left: 0.8rem;
                  border-left: 0.06rem solid #CBCFD2;
                  color: #118acb;
                  background-color: transparent;
                }
              }
            }
            .ms-cir-li {
              display: flex;
              padding: 0.5rem 0rem 0.6rem 0;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              gap: 1rem;
              background: #fff;
              transition: 0.25s;
              @media (max-width: 740px){
                flex-wrap: wrap;
              }
              &:hover {
                background: transparent;
                transition: 0.25s;
              }
              &:last-child {
                margin-bottom: 1rem !important;
              }
  
              &.active {
                background: #e5f0ff;
                transition: 0.25s;
  
                .selected-icon {
                  visibility: visible;
                }
              }
              .first-cir-row,
              .scnd-cir-row{
                display: flex;
                align-items: center;
              }
              .ms-cir-div {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                width: 3rem;
                height: 3rem;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.7rem;
                letter-spacing: 0.005rem;
                color: #ffffff;
                border-radius: 50%;
                text-transform: uppercase;
                background-color: #0789cf;
                // scnv 2824
                @media (min-width: 1920px){
                  padding-bottom: 0.1rem;
                }
                // scnv 2824 end
                &.cir-a {
                  background-color: #ffbc1f !important;
                }
  
                &.cir-b {
                  background-color: #ff6666 !important;
                }
  
                &.cir-c {
                  background-color: #70b9e0 !important;
                }
  
                &.cir-d {
                  background-color: #4a59e1 !important;
                }
  
                &.cir-e {
                  background-color: #1487e3 !important;
                }
  
                &.cir-f {
                  background-color: #8cdcd2 !important;
                }
  
                &.cir-g {
                  background-color: #6464c8 !important;
                }
  
                &.cir-h {
                  background-color: #f58b00 !important;
                }
  
                &.cir-i {
                  background-color: #3fde74 !important;
                }
  
                &.cir-j {
                  background-color: #d36678 !important;
                }
  
                &.cir-k {
                  background-color: #45b097 !important;
                }
  
                &.cir-l {
                  background-color: #455a64 !important;
                }
  
                &.cir-m {
                  background-color: #8c6bb1 !important;
                }
  
                &.cir-n {
                  background-color: #2c55a0 !important;
                }
  
                &.cir-o {
                  background-color: #8686df !important;
                }
  
                &.cir-p {
                  background-color: #dc8056 !important;
                }
  
                &.cir-q {
                  background-color: #33a4ff !important;
                }
  
                &.cir-r {
                  background-color: #4caf50 !important;
                }
  
                &.cir-s {
                  background-color: #607d8b !important;
                }
  
                &.cir-t {
                  background-color: #f4845f !important;
                }
  
                &.cir-u {
                  background-color: #00c853 !important;
                }
  
                &.cir-v {
                  background-color: #fda982 !important;
                }
  
                &.cir-w {
                  background-color: #43a047 !important;
                }
  
                &.cir-x {
                  background-color: #db324d !important;
                }
  
                &.cir-y {
                  background-color: #0d2c54 !important;
                }
  
                &.cir-z {
                  background-color: #7db9ff !important;
                }
              }
  
              .ms-cir-name {
                font-size: 1.4rem;
                line-height: 1.7rem;
                color: #4d4d4f;
                padding-left: 1.4rem;
                padding-right: 1.4rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                border-right: 1px solid #cbcfd2;
                @media (max-width: 740px){
                  font-size: 1.2rem;
                  border-right: 0;
                  white-space: nowrap;
                  max-width: calc(100% - 3rem);
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .ms-cir-email {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.7rem;
                color: #78909c;
                padding-left: 1.4rem;
                padding-right: 1.4rem;
                white-space: nowrap;
                max-width: 32.8rem;
                overflow: hidden;
                text-overflow: ellipsis;
                @media (max-width: 740px){
                  font-size: 1.2rem;
                  padding-left: 0;
                  border-right: 0;
                }
              }
              .ms-cir-psw {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.7rem;
                color: #78909c;
                padding-left: 4rem;
                padding-right: 1.4rem;
                border-right: 1px solid #cbcfd2;
                max-width: 32.8rem;
                display: flex;
                align-items: center;
                gap: 0.8rem;
                flex: 1;
                span{
                  display: inline-block;
                  font-size: 1.4rem;
                  font-weight: 400;
                  line-height: 1.7rem;
                  color: #78909c;  
                }
                i{
                  cursor: pointer;
                  display: inline-block;
                  height: 1.4rem;
                  width: 1.4rem;
                  svg{
                    height: 100%;
                    width: 100%;
                  }
                }
              }
  
              .selected-icon {
                width: 1.6rem;
                visibility: hidden;
                display:none;
              }
            }
            .not-registered {
              .ms-cir-name {
                font-size: 1.4rem;
                line-height: 1.7rem;
                color: rgba(84, 110, 122, 0.5);
                padding-left: 1.4rem;
                padding-right: 1.4rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                border-right: 1px solid #cbcfd2;
                @media (max-width: 740px){
                  font-size: 1.2rem;
                  border-right: 0;
                  white-space: nowrap;
                  max-width: calc(100% - 3rem);
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .ms-cir-email {
                font-size: 1.4rem;
                font-weight: 400;
                line-height: 1.7rem;
                color: rgba(120, 144, 156, 0.5);
                padding-left: 1.4rem;
                padding-right: 1.4rem;
                border-right: 1px solid #cbcfd2;
                @media (max-width: 740px){
                  font-size: 1.2rem;
                  padding-left: 0;
                  border-right: 0;
                  max-width: 200px;
                }
              }
  
              .selected-icon {
                width: 1.6rem;
                visibility: hidden;
              }
  
              .ms-cir-div {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                width: 3rem;
                height: 3rem;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.7rem;
                letter-spacing: 0.005rem;
                color:#7D868C !important;
                background-color: #f0f0f0 !important;
                border-radius: 50%;
                text-transform: uppercase;
                border: 1px dashed #7d868c;
                // scnv 2824 
                @media ( min-width: 1920px){
                  padding-bottom: 0.1rem;
                }
                // scnv 2824 end
                &.cir-not {
                  background-color: #f0f0f0 !important;
                }
              }
            }
          }
  
          .selected-member-ul {
            padding: 1.3rem 0rem 0rem 1.2rem;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            flex-direction: row;
            span {
              position: absolute;
              top: 2rem !important;
              left: 0;
            }
            input{
              &.active{
                display: none;
              }
            }
            .selected-member-li {
              display: inline-block;
              max-width: 100%;
              position: relative;
              padding: 0.6rem 4rem 0.6rem 1.2rem;
              background: #f0f0f0;
              border-radius: 2rem;
              font-size: 1.4rem;
              line-height: 1.7rem;
              color: #4d4d4f;
              margin-left: 1.4rem;
              margin-bottom: 1.2rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
  
              span {
                position: unset;
              }
  
              .remove-list {
                display: inline-block;
                line-height: 0;
                position: absolute;
                right: 0.8rem;
                top: 50%;
                transform: translateY(-50%);
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                background: #fff;
  
                img {
                  width: 0.9rem;
                }
              }
            }
          }
  
          .ms-select-owner-div {
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow: auto;
          }
  
          .select-owner-text {
            border-bottom: 0.05rem solid #e6e6e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 2rem;
            padding-left: 2rem;
            padding-bottom: 1.8rem;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.7rem;
            letter-spacing: 0.0005rem;
            color: #546e7a;
          }
  
          .ms-invite-member-div {
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow: auto;
  
            .ms-invite-div {
              height: 4.9rem;
            }
          }
  
          // ----
          .email-input-wrapper {
            padding: 2.5rem 2rem 0 2rem;
            .label {
              position: relative;
              display: block;
  
              .input-title {
                font-weight: 500;
                font-size: 1.2rem;
                line-height: 1.5rem;
                letter-spacing: -0.0272727rem;
                color: #4d4d4f;
                display: inline-block;
                background: #ffffff;
                padding: 0.2rem 0.6rem;
                left: 1.1rem;
                position: absolute;
                top: -0.9rem;
                z-index: 1;
              }
  
              .input-field {
                border: 0.1rem solid #e9e9e9;
                box-sizing: border-box;
                border-radius: 0.5rem;
                display: block;
                width: 100%;
                padding: 1.52rem 1.7rem;
                font-size: 1.5rem;
                color: #4d4d4f;
                outline: 0;
                text-align: left;
                transition: 0.25s;
  
                &::placeholder {
                  color: #7d868c;
                  font-weight: 400;
                }
              }
            }
          }
          // ----
        }
  
        .ms-assign-footer {
          margin: 1.6rem -1.6rem 0rem -1.6rem;
          padding: 2rem;
          display: flex;
          align-items: center;
          bottom: 0;
          left: 0;
          background: #fff;
          //z-index: 999999;
          //position: absolute;
          border-radius: 0 0 1.6rem 1.6rem;
          box-shadow: 0px -4px 6px 0px #0000000f;
          @media (max-width: 740px){
            flex-direction: column;
            gap: 10px;
            padding: 10px;
          }
          .btn-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @media(max-width: 740px){
             flex-direction: column;
             gap: 15px; 
            }
            .create-btn{
              cursor: pointer;
              display: inline-block;
              padding: 1rem 1.2rem;
              color: #FFF;
              text-align: center;
              font-size: 1.4rem;
              font-weight: 500;
              line-height: normal;
              border-radius: 0.4rem;
              background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
              @media(max-width: 740px){
                width: 100%; 
              }
            }
            .right-btn-wrap{
              display: flex;
              @media(max-width: 740px){
                justify-content: space-between;
                width: 100%;
                gap: 15px; 
              }
            }
  
          }
          .ms-assign-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 1.4rem;
            letter-spacing: 0.046rem;
            border-radius: 0.4rem;
            padding: 1rem 1.2rem;
            min-width: 8rem;
            transition: 0.25s;
            @media (max-width: 740px){
              width: 100%;
            }
            &.cancel {
              background: #f0f0f0;
              color: #4d4d4f;
              margin-right: 1.4rem;
              @media (max-width: 740px){
                margin-right: 0;
              }
              &:hover {
                background: #e5f0ff;
                transition: 0.25s;
              }
  
              &:active {
                transition: 0.25s;
                box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.2);
                @media (max-width: 740px){
                  width: 100%;
                }
              }
            }
  
            &.blue {
              color: #ffffff;
              background: linear-gradient(
                91.83deg,
                #139bd6 12.22%,
                #0789cf 100.41%
              );
  
              &:hover {
                background: linear-gradient(
                  91.83deg,
                  #118acb 12.22%,
                  #0473ae 100.41%
                );
                transition: 0.25s;
              }
  
              &:active {
                transition: 0.25s;
                box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.2);
              }
            }
            &.disabled {
              cursor: not-allowed;
              background: linear-gradient(
                91.83deg,
                rgba(19, 155, 214, 0.4) 12.22%,
                rgba(7, 137, 207, 0.4) 100.41%
              ) !important;
            }
          }
        }
      }
      .invite-successful {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.4rem;
        color: #4d4d4f;
        border-radius: 0.5rem;
        img {
          width: 10.2rem;
        }
        p {
          margin-top: 6rem;
        }
        &.assign {
          img {
            width: 22.6rem;
          }
        }
      }
    }
  
    // ms-assign-section-popup end
  
    /*-----------------------------------------*/
    //Assign Popup
    .userlist {
      background: #fafcfd;
      border: 1px solid #adc8fe;
      box-shadow: 0px 0px 8px rgba(21, 27, 38, 0.12);
      min-width: 58.8rem;
      padding: 0.8rem 1.6rem 1.8rem 0.6rem;
      margin-bottom: -0.8rem;
      position: absolute;
      left: 1.6rem;
      top: 11rem;
      max-height: 19.8rem;
      overflow: hidden auto;
      @media (max-width: 740px){
        min-width: calc(100% - 3.2rem);
        right: 1.6rem;
      }
      .ms-cir-li {
        cursor: pointer;
        display: flex;
        /*margin-bottom: 2.6rem;*/
        align-items: center;
        padding-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @media (max-width: 740px){
          flex-wrap: wrap;
        }
        &:hover {
          background-color: #ffffff;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .ms-cir-div {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          width: 4rem;
          height: 4rem;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.7rem;
          letter-spacing: 0.005rem;
          color: #ffffff;
          border-radius: 50%;
          text-transform: uppercase;
          background-color: #45b097;
          @media (max-width: 740px){
            width: 2.5rem;
            height: 2.5rem;
            font-size: 1rem;
          }
        }
        .ms-cir-name {
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #455a64;
          padding-left: 1.4rem;
          padding-right: 1.4rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          border-right: 1px solid #cbcfd2;
          font-weight: 500;
          @media (max-width: 740px){
            font-size: 1.2rem;
            border-right: 0;
            white-space: nowrap;
            max-width: calc(100% - 3rem);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ms-cir-email {
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 1.7rem;
          color: #607d8b;
          padding-left: 1.4rem;
          padding-right: 1.4rem;
          @media (max-width: 740px){
            font-size: 1.2rem;
            padding-left: 0;
            border-right: 0;
          }
        }
      }
      .not-registered {
        .ms-cir-name {
          color: rgba(84, 110, 122, 0.5);
          white-space: nowrap;
          border-right: 1px solid #cbcfd2;
          @media (max-width: 740px){
            font-size: 1.2rem;
            border-right: 0;
            white-space: nowrap;
            max-width: calc(100% - 3rem);
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .ms-cir-email {
          color: rgba(120, 144, 156, 0.5);
          @media (max-width: 740px){
            font-size: 1.2rem;
            padding-left: 0;
            border-right: 0;
          }
        }
  
        .selected-icon {
          width: 1.6rem;
          visibility: hidden;
        }
  
        .ms-cir-div {
          color:#7D868C !important;
          background-color: #f0f0f0 !important;
          text-transform: uppercase;
          border: 1px dashed #7d868c;
  
        }
      }
    }
  
    .wrap:hover {
      background: transparent;
      color: #118acb;
      border-radius: 0.4rem;
    }
  
    //First Time Invite User
    .first-invite-popup {
      flex: 1 1;
      padding: 1.6rem;
      background: #ffffff;
      border: 0.1rem dashed #cbcfd2;
      border-radius: 0.8rem;
      overflow: auto;
      padding-right: 0;
  
      .first-invite-popwrap {
        height: 100%;
        overflow: auto;
        //padding-bottom: 5rem;
      }
      span {
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #4d4d4f;
      }
      .image-wrapper {
        padding-top: 2rem;
        text-align: center;
        img {
          width: 20rem;
        }
        p {
          margin-top: 2rem;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #0d2c54;
        }
      }
    }
    /*-----------------------------------------*/
  }
  // Reminder popup css
  
  .reminder-success-popup{
    min-height: 20rem;
    padding: 2.4rem 4.3rem 1.6rem 4.3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    border: 0.1rem solid #8CDCD2;
    border-radius: 1.6rem;
    filter: drop-shadow(0px 0px 18px rgba(77, 77, 79, 0.25));
    width: 24rem;
    .reminder-success-img{
      height: 12rem;
      width: 12rem;
      img{
        height: 100%;
        width: 100%;
      }
    }
    p{
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #0D2C54;
      text-align: center;
    }
  }
  
  

`
export const CreateAccountWrapper = styled.div`
@media(max-width: 740px){
  width: 100%;
}
  .text-wrap{
    .left-wrap{
        display: flex;
        align-items: center;
        gap: 1.6rem;
        .back-btn{
            display: flex;
            border-radius: 0.4rem;
            border: 0.06rem solid #7D868C;
            align-items: center;
            gap: 0.4rem;
            padding: 0.8rem 0.4rem;
            i{
                display: inline-flex;
                height: 1.6rem;
                width: 1.6rem;
                svg{
                    height: 100%;
                    width: 100%;
                }
            }
            span{
                display: inline-block;
                color: #4D4D4F;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
  }
  .create-account-wrapper{
    display: flex;
    gap: 3.2rem;
    padding: 2.4rem 1.6rem;
    width: 100%;
    @media(max-width: 740px){
      flex-wrap: wrap;
    }
    .create-account-form{
        width: 50%;
        @media(max-width: 740px){
          width: 100%;
        }
        .signup_form_col{
            &:last-child{
                .field_wrapper{ 
                    padding-top: 0 !important;
                    .input_field{
                        margin-top: 0.8rem;
                    } 
                }
            }
            &:first-child{
                .field_wrapper{ 
                    padding-top: 0 !important; 
                }
            }
            .field_wrapper{
                position: relative;
                padding-top: 2.4rem;
                display: block;
                .label{
                    color: #0D2C54;
                    display: block;
                    padding-bottom: 0.8rem;
                    font-size: 1.2rem;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: -0.0273rem;
                }
                .input_field{
                    display: flex;
                    align-items: center;
                    border-radius: 0.4rem;
                    border: 0.04rem solid #7D868C;
                    padding: 0.4rem 0.8rem;
                    color: #0D2C54;
                    font-size: 1.4rem;
                    font-weight: 400;
                    line-height: normal;
                    height: 4rem;
                    width: 100%;
                    &::placeholder{
                        color: #A6A7AD;
                    }
                }
                i{
                    svg{
                        position: absolute;
                        top: 50%;
                        right: 2.1rem;
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }
                &.set-psw{
                    i{
                        svg{
                            top: 75%;
                        }
                    }
                }
            }
        }
        .error{
            position: absolute;
            font-size: 1.2rem;
            line-height: 1.5rem;
            text-align: right;
            letter-spacing: -0.01em;
            color: #fc6444;
            background: transparent;
            display: block;
        }
    }
    .create-btn-col{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        @media(max-width: 740px){
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
        }
        .create-btn{
            display: flex;
            border-radius: 0.6rem;
            background: #F0F0F0;
            padding: 1.6rem 3.2rem;
            color: #A6A7AD;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: normal;
            height: 5.1rem;
            @media(max-width: 740px){
              justify-content: center;
              width: 100%;
            }
            &.active{
                background: linear-gradient(92deg, #118ACB 12.22%, #0473AE 100.41%);
                color: #fff;
            }
            &.disabled{
                pointer-events: none;
            }
        }
    }
  }
  .account-success-popup{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 99;
    gap: 2.8rem;
    .success-img{
        display: block;
        height: 16rem;
        width: 16rem;
        img{
            height: auto;
            width: 100%;
        }
    }
    p{
        color: #0D2C54;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
    }
  }
`
export const ViewAccountWrapper = styled.div`
  padding: 1.6rem;
  .note{
    display: block;
    color: #4D4D4F;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem; /* 135.714% */
    padding-bottom: 2.4rem;
  }
  .detail-wrap{
    .detail-row{
        display: flex;
        gap: 0.8rem;
        margin-bottom: 0.8rem;
        align-items: center;
        .label{
            min-width: 9rem;
            color: #4D4D4F;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: normal;
        }
        .text{
            color: #4D4D4F;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: normal;
            .psw-field{
              display: flex;
              gap: 0.8rem;
              align-items: center;
              i{
                cursor: pointer;
                display: inline-block;
                height:1.4rem;
                width: 1.4rem;
                svg{
                  height: 100%;
                  width: 100%;
                }
              }
            }
        }
    }
  }
`