import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import {
    MainWrapper,
    UpgradePopWrap,
    UpgradePop,
    UpgradePopHead,
    UpgradePopBody,
    BodyTextWrap,
    UpgradePopFooter,
    ButtonWrap,    
} from './Upgradestyles';

import{
    asmtHeadIcon,crossIcon,bookMark,insertIcon,benchIcon,dataIcon,triangleIcon
} from './upgradesvg';

export const UpgradePopView = () => {
    const [openPopup,setOpenPopup] = useState(true);
    const asmtData = [
        {
            id: 1,
            img: bookMark,
            title: `Plant Team Engagement `,
            text: `Engage all plant stakeholders in the loss analysis tool and get their plant activities input in a structured and scalable manner`,
        },
        {
            id: 2,
            img: dataIcon,
            title: `Automated Data Analysis`,
            text: `SmarterChains collects and ranks more than 1500 data points on plant’s capabilities across the 10 Industry 4.0 Dimensions.`,
        },
        {
            id: 3,
            img: insertIcon,
            title: `IR4.0 Index & Benchmarking`,
            text: `SmarterChains Index  helps you understand your maturity and how you benchmark externally and internally across the 10 Industry 4.0 Dimensions`,
        },
        {
            id: 4,
            img: benchIcon,
            title: `IR4.0 Insights & Benchmarking`,
            text: `For each Industry 4.0 Dimension, we provide data-driven Insights that unveil strength & opportunity areas.`,
        },
    ]
    const handleUpgradePopupClick = (e)=>{
		e.stopPropagation();
		e.preventDefault();
		document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
	}
    return (
        <>
            <MainWrapper data-component="MainWrapper">
            {openPopup && 
               <UpgradePopWrap data-component="UpgradePopWrap">
                    <UpgradePop data-component="UpgradePop">
                        <UpgradePopHead data-component="UpgradePopHead">
                            <i className='asmt-img' dangerouslySetInnerHTML={{ __html: asmtHeadIcon }}></i>
                            <span className='head-txt'>IR4.0 Technology Assessment </span>
                            <button onClick={()=>setOpenPopup(false)}>
                                <i className='close-img' dangerouslySetInnerHTML={{ __html: crossIcon }}></i>
                            </button>
                        </UpgradePopHead>
                        <UpgradePopBody data-component="UpgradePopBody">
                            <p className='main-text'>The most effective way to measure Technological & Organizational capabilities at scale while engaging all the organisation</p>
                            {asmtData.map((item,index)=>(
                                <BodyTextWrap data-component="BodyTextWrap" key={index}>
                                    <div className='upper-wrap'>
                                        <i className='side-icon' dangerouslySetInnerHTML={{ __html: item.img }}></i>
                                        <span>{item.title}</span>
                                    </div>
                                    <p>{item.text}</p>
                                    <i className='triangle-icon' dangerouslySetInnerHTML={{ __html: triangleIcon }}></i>
                                </BodyTextWrap>
                            ))}
                            <ButtonWrap data-component="ButtonWrap">
                                <button className='get-btn' onClick={(e) => handleUpgradePopupClick(e)}>Get IR4.0 Technology Assessment</button>
                            </ButtonWrap>
                        </UpgradePopBody>
                        <UpgradePopFooter data-component="UpgradePopFooter">
                            <Link to='/learning-assessment-walkthrough/upgrade_asmt_tour_link'>View Product Tour</Link>
                        </UpgradePopFooter>
                    </UpgradePop>
               </UpgradePopWrap>
               }
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

