/** imported components

  * $ from jquery ===> component from jquery to use jquery for the page.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.

**/

import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import serviceProvider from '../../services/axios'
import { Helmet } from "react-helmet";

//OperatorWalkthroughQuesView component start
export const OperatorWalkthroughQuesView = (props) => {
    //defining variables here
    const { activeSlide, setActiveSlide } = props;
    const users = JSON.parse(localStorage.getItem("userData"));
    const UserName = users ? users.name : "First Name";
    const group_id=users ? users.group_id:''; /*if 14 it's simple user else 2 or 11 for admin*/
    const uid = users ? users.uid : "";
    //function to close slider
    const handleClose = () => {
        var close = document.querySelector('.div-z99');
        var close1 = document.querySelector('.div-z999');
        var close2 = document.querySelector('.div-z993');
        var findClass = document.querySelector('.la-ques-wrapper').contains(close);
        var closePop = document.querySelector('.right-sidebar .close-btn');
        if (findClass == true) {
            close.classList.remove('div-z99');
        }
        close1?.classList.remove('div-z999');
        close2?.classList.remove('div-z993');
        serviceProvider
            .post(
                "/home?type=setlineEfftour",
                JSON.stringify({ uid: uid, opreator: 'oprat' }),
                true
            )
            .then((response) => {
                setActiveSlide(null);
                if (closePop != null) {
                    closePop.click();
                }
            });


    }
    //function to handle slider
    const HandleSlider = (val) => {
        setActiveSlide(val);
    }
    //condition to add/remove classes as per walkthrough rewuirement
    useEffect(() => {
        var div = document.querySelector('.la-ques-button-group .button-wrap');
        var activeGreen = document.querySelector('.submitted-scnv-2126');
        var span = document.querySelector('.la-ques-wrapper').contains(div);
        var submitData = document.querySelector('.la-ques-wrapper').contains(activeGreen);
        var openPop = document.querySelector('.side-subcategory .image-box');
        var closePop = document.querySelector('.right-sidebar .close-btn');

        if (submitData === true && group_id!=14) {
            setActiveSlide(null);
        }

        if (activeSlide === 1) {
        }
        if (activeSlide === 2) {
            document.querySelector('.prod-1').classList.add('div-z99');
        }
        else {
            document.querySelector('.prod-1').classList.remove('div-z99');
        }
        if (activeSlide === 3) {
            document.querySelector('.prod-2').classList.add('div-z99');
        }
        else {
            document.querySelector('.prod-2').classList.remove('div-z99');
        }
        if (activeSlide === 4) {
            document.querySelector('.prod-3').classList.add('div-z99');
        }
        else {
            document.querySelector('.prod-3').classList.remove('div-z99');
        }
        if (activeSlide === 5) {
            document.querySelector('.prod-4').classList.add('div-z99');
        }
        else {
            document.querySelector('.prod-4').classList.remove('div-z99');
        }
        if (activeSlide === 6) {
            document.querySelector('.training-content').classList.add('div-z993');
        }
        else {
            if (activeSlide != 6 && span === true) {
                document.querySelector('.training-content').classList.remove('div-z993');
            }
            document.querySelector('.training-content').classList.remove('div-z993');
        }
        if (activeSlide === 7) {
            document.querySelector('.prod-6').classList.add('div-z99');
            openPop?.click();
        }
        else {
            document.querySelector('.prod-6').classList.remove('div-z99');
            closePop?.click();
        }
        if (activeSlide === 8) {
            document.querySelector('.la-ques-button-group').classList.add('div-z999');
            document.querySelector('.submitted-scnv-2126')?.classList.add('div-z99');
        }
        else {
            if (activeSlide != 8 && span === true) {
                document.querySelector('.la-ques-button-group').classList.remove('div-z999');
            }
            document.querySelector('.submitted-scnv-2126')?.classList.remove('div-z99');
        }
    }, [activeSlide]);

    useEffect(()=>{
        var scrollDiv=document.querySelector('.la-ques-wrapper .la-ques-bottom-wrapper');
        if(scrollDiv){
          scrollDiv.classList.add('scollBlock');  
          scrollDiv.style.overflow='hidden';
        }
        return()=>{
          if(scrollDiv){
                scrollDiv.classList.remove('scollBlock');
                scrollDiv.style.overflow='';
              }
            }
        let allZindexClass= ['div-z99','div-z999','div-z993'];
        allZindexClass.forEach((item)=>{
            item?.classList.remove(allZindexClass);
        })
    },[]);

    return (
        <>
          {/* link css */}
            <Helmet>
            <link rel="stylesheet" href={require('./operator-effort-walkthrough.scss').default} />
            </Helmet>
            {/* link css end*/}
             {/* walkthrough wrapper start */}
            <ShowContent active={activeSlide} item={1}>
                <div className="walkthrough first">
                    <div className="confirmation-form">
                        {/* wrapper has one card */}
                        <div className="submit-form step-1">
                            {/* section has two subsections
                                col-left
                                col-right
                            */}
                            <div className="submit-content">
                                {/* left part is containing gif image related to walkthrough step. */}
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-1.gif').default} alt="Step-1" /></div>
                                    {/* right part has 
                                    heading , 
                                    text and  
                                    buttons navigate from one to next, next to previous and close. */}
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>1 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name name-class"><p >Hi {UserName},</p></div>
                                    <p className="text">Welcome to the Industry 4.0 Loss Analysis.</p>
                                    <p className="txt">​We want to quickly show you the Loss Analysis data collection.​</p>
                                    <p className="text"> You can skip at any time.​</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button onClick={() => handleClose()} className="prev-link link">No thanks</button>
                                                <button className="let solid link" onClick={() => setActiveSlide(2)}>Let's go</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={2}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk1">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-3.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>2 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Reason Codes</p></div>
                                    <p className="text">Here we can see the most common activities performed by operators and technicians in the production lines.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(1)}>Previous</button>
                                                <button className="solid link" onClick={() => setActiveSlide(3)}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={3}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk2">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-4.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>3 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Production Areas</p></div>
                                    <p className="text">Here you can see the production areas that you need to provide data for.</p>
                                    <p className="text">Input is coming automatically from the Finance Sections.​​​​</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(2)}>Previous</button>
                                                <button className="solid link" onClick={() => setActiveSlide(4)}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={4}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk3">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-5.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>4 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Defining the % ratios</p></div>
                                    <p className="text">In these % cells you can specify how each reason code contributes to the average hours per production area.</p>
                                    <p className="text">Your input is automatically saved.​​</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(3)}>Previous</button>
                                                <button className="solid link" onClick={() => setActiveSlide(5)}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={5}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk4">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-6.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>5 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Check 100%</p></div>
                                    <p className="text">Your input needs to add to 100% on every column.</p>
                                    <p className="text">If not, you will not be able to submit the data.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(4)}>Previous</button>
                                                <button className="solid link" onClick={() => setActiveSlide(6)}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={6}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-data-walk">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires/data-unavailable.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>6 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Data Not Available</p></div>
                                    <p className="text">If you don't collect data either for the full section or for a specific line you can declare that from the dedicated interfaces.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(5)}>Previous</button>
                                                <button className="solid link" onClick={() => setActiveSlide(7)}>Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>                
            <ShowContent active={activeSlide} item={7}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk5">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-7.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>7 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Help</p></div>
                                    <p className="text">You can click the i icon for more information.​</p>
                                    <p className="text">If that is not enough, you can select the support button.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(6)}>Previous</button>
                                                <button className="solid link" onClick={() => setActiveSlide(8)}>Next</button>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            <ShowContent active={activeSlide} item={8}>
                <div className="walkthrough">
                    <div className="confirmation-form loss-walk6">
                        <div className="submit-form">
                            <div className="submit-content">
                                <div className="col-left"><img src={require('../../assets/img/la-questionnaires-walkthrough/loss-analysis-step-9.gif').default} alt="Step-1" /></div>
                                <div className="col-right">
                                    <div className="col-right-header">
                                        <span>8 of 8</span>
                                        <button className="close-submit-form" onClick={handleClose}>
                                            <img src={require("../../assets/img/la-questionnaires-walkthrough/walkthrough-close.svg").default} alt="Close" className="close-img" />
                                        </button>
                                    </div>
                                    <div className="name"><p>Submit Data</p></div>
                                    <p className="text">Following the completion of the section, submit your data.</p>
                                    <p className="text">After submission you will not be able to edit the section.</p>
                                    <div className="card-footer">
                                        <div className="card-footer-section">
                                            <div className="card-footer-left">
                                                <button className="circle" onClick={() => { HandleSlider(1) }} disabled={activeSlide === 1 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(2) }} disabled={activeSlide === 2 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(3) }} disabled={activeSlide === 3 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(4) }} disabled={activeSlide === 4 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(5) }} disabled={activeSlide === 5 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(6) }} disabled={activeSlide === 6 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(7) }} disabled={activeSlide === 7 ? true : false}></button>
                                                <button className="circle" onClick={() => { HandleSlider(8) }} disabled={activeSlide === 8 ? true : false}></button>
                                            </div>
                                            <div className="card-footer-right">
                                                <button className="link" onClick={() => setActiveSlide(7)}>Previous</button>
                                                <button className="solid link let-start" onClick={() => handleClose()}>Let's Start</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ShowContent>
            {/* la-ques-wrapper end */}
        </>
    )
}

//function to show content
const ShowContent = (props) => {
    return (
        <>
            {props.active === props.item &&
                <>
                    {props.children}
                </>
            }
        </>
    )
}