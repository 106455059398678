import React from "react";
import { LossAssumptionCommentPopupView } from "./LossAssumptionCommentPopupView";
import { useEffect, useState } from 'react';
import { useHistory, Link, Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios'

export const LossAssumptionCommentPopupCtrl = (props) => {
    
    const [resultscom, setResultscom] = useState([]);

    const user = JSON.parse(localStorage.getItem("userData"));
    const uid = user ? user.uid : 0;
    const pid = props.data.pid || 0;
    const scid = props.data.scid || 0;
    const node_id = props.data.item.node_id || 0;
    const reason_id = props.data.item.id || 0;

    useEffect(() => {
        const search = async () => {
             setResultscom(prv => [])
             const apiUrl = `/Businesscaseplantloss?param=getcomments&pid=${pid}&uid=${uid}&scid=${scid}&node_id=${node_id}&reason_id=${reason_id}`;
             const { data } = await serviceProvider.get(apiUrl, true, {});
             setResultscom(data);
             //setResultscom(demodata);
        };
        search();
    }, []);

    if (1) {
        return (
            <>
                <LossAssumptionCommentPopupView
                    sn={user.sn}
                    avcolor={user.avcolor}
                    resultscom={resultscom}
                    uid={uid}
                    pid={pid}
                    scid={scid}
                    reason_id={reason_id}
                    node_id={node_id}
                    count={props.count}
                    {...props}
                />

            </>
        );


    } else {

        return (
            <>
                <div id="loader-assumption"></div>
            </>
        );
    }
}
