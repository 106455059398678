/** imported components
  * Modal for ===> component from material ui to work as popup.
  * serviceProvider for ===> working with api
  * useState ===> React hooks (useState is a way to get/set value)

**/

import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import serviceProvider from '../../services/axios'
import { Link } from "react-router-dom";
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext } from "pure-react-carousel";

//AdminWalkthroughView Component start
export const AdminWalkthroughview = (props) => {
	// Defining variables here
	const user = JSON.parse(localStorage.getItem("userData"));
	const name = user ? user.name : "";
	const uid = user ? user.uid : "";
	const [isModal, setIsModal] = useState(true);
	const [slideIndex, setSlideIndex] = useState(0);
	const [walkthroughOpen, setWalkthroughOpen] = useState(true); 
	const TotalSlides = 5;
	// Array variable for walkthrough cards
	const slideData =props.isusers==14? [
		{
			heading: `Hi ${name}`,
			text: [
				`Welcome to the Industry 4.0 Maturity Assessment.`,
				`We want to quickly show you around how the Assessment works. You can skip at any time.`,
			],
		},
		{
			heading: "	Assessment Basics",
			text: [	`This is the Assessment's main interface. Use the top navigation to move around.`,
					`Track the overall and each section's progress as your team completes the process.`,
			],
		},
		{
			heading: "Assessment collaboration",
			text: [`Learn more information about each section and have an overview of who is responsible to answer it.`],
		},
		{
			heading: "Select a Section",
			text: [`Click on the arrow to access the section and view its subsections. Navigate through and complete the questionnaire(s) you have been assigned to.`,
			],
		}
	]
	:
	 [
		{
			heading: `Hi ${name}`,
			text: [
				`Welcome to the Industry 4.0 Maturity Assessment.`,
				`We want to quickly show you around how the Assessment works. You can skip at any time.`,
			],
		},
		{
			heading: "	Assessment Basics",
			text: [	`This is the Assessment's main interface. Use the top navigation to move around.`,`Track the overall and each section's progress as your team completes the process.`,
			],
		},
		{
			heading: "Assessment collaboration",
			text: [`Learn more information about each section and invite your colleagues to answer them using their email address.`],
		},
		{
			heading: "Select a Section",
			text: [`Click on the arrow to access the section and view its subsections. Navigate through and complete the questionnaire(s) you have been assigned to.`,
			],
		},
		{
			heading: "Unlock a Section", 
			text: [	`In order to procced with the Production Execution section, either you or your colleague that is assigned to it, need to input information about the production lines.`,
			],
		},
	];
	// Function to handle modal
	const HandleModal = () => {
		setIsModal(!isModal);
		setSlideIndex(0);
		props.handleGetValue(0);
	};
	//Function for sliding walkthrough on the basis of index value
	const HandleSlideIndex = (ev) => {
		if (ev === "min" && slideIndex > 0) {
			let tmpVal = slideIndex - 1;
			setSlideIndex(tmpVal);
			props.handleGetValue(tmpVal);
		} else if (!ev && slideIndex < TotalSlides - 1) {
			let tmpVal = slideIndex + 1;
			setSlideIndex(tmpVal);
			props.handleGetValue(tmpVal);
		}
	};
	// Function to close walkthrough.
	const handelCloseWalkThrough = () =>{
		document.querySelectorAll('.z-index9999').forEach((item)=>{
			item.classList.remove('z-index9999');
		})
		document.querySelectorAll('.visable-button').forEach((item)=>{
			item.classList.remove('visable-button');
		})
		serviceProvider
		.post(
		"/home?type=setassessment",
        JSON.stringify({ uid: uid }),
		true
      )
      .then((response) => {
		setIsModal(false);
		
      });
		
		
	}
	
	return (
		// Wlakthrough modal start
		<Modal open={isModal} className={`admin-wakthrough-modal ${slideIndex === 0 && "background-change"}`}>
			{walkthroughOpen?
			<div className="admin-walkthrough">
				{/* <div className="bg-admin">
                                        <img src={require(`../../assets/img/admin-walkthrough/admin-bg-${slideIndex}.svg`).default} alt={`admin-bg-${slideIndex}`} />
                                </div> */} 
				<div className={`carousel-wrapper ${slideIndex === 3 && " active"}`}>
					{/* Close button that will close the walkthrough */}
					<button className="modal-close" onClick={()=>{handelCloseWalkThrough()}}>
						<img src={require("../../assets/img/admin-walkthrough/close.svg").default} alt="X" />
					</button>
				    {/* walkthrough wrapper divided in two parts */}
					<div className="slide-item">
						 {/* left frame is containing gif image related to walkthrough step. */}
						<div className="left-frame">
							<img src={require(`../../assets/img/admin-walkthrough/slider-img${slideIndex}.gif`).default} alt="slide1" />
						</div>
						{/* Right frame is containing detailed information of walkthrough */}
						<div className="right-frame">
							{/* Page number info */}
							<div className="slide-head">{slideIndex + 1} of {slideData.length}</div>
							{/* This section has heading and content for walkthrough */}
							<div className="slide-content">
								<h2 className="slide-h1">{slideData[slideIndex].heading}</h2> 
								{slideData[slideIndex].text.map((item, index) => (
									<p className="slide-p" key={index}>
										{item}
									</p>
								))}
							</div>
							{/* Walkthrough footer that is containing dots and button to slide walkthrough from one to next and next to previous */}
							<div className="slide-footer">
								<div className="dots-div">
									<button
										className="dot"
										onClick={() => {
											setSlideIndex(0);
											props.handleGetValue(0);
										}}
										disabled={slideIndex === 0 && true}
									></button>
									<button
										onClick={() => {
											setSlideIndex(1);
											props.handleGetValue(1);
										}}
										className="dot"
										disabled={slideIndex === 1 && true}
									></button>
									<button
										onClick={() => {
											setSlideIndex(2);
											props.handleGetValue(2);
										}}
										className="dot"
										disabled={slideIndex === 2 && true}
									></button>
									<button
										onClick={() => {
											setSlideIndex(3);
											props.handleGetValue(3);
										}}
										className="dot"
										disabled={slideIndex === 3 && true}
									></button>
									{props.isusers!=14&&<button
										onClick={() => {
											setSlideIndex(4);
											props.handleGetValue(4);
										}}
										className="dot"
										disabled={slideIndex === 4 && true}
									></button>}
								</div>
								<div className="button-div">
									{/* previous btn condition */}
									{slideIndex === 0 && <button onClick={()=>{handelCloseWalkThrough()}}>No thanks</button>}
									{slideIndex > 0 && slideIndex < slideData.length-1 && <button onClick={() => HandleSlideIndex("min")}>Previous</button>}
									{slideIndex === slideData.length-1 && (
										<button
											onClick={() => {
												setSlideIndex(0);
												props.handleGetValue(0);
											}}
										>
											Restart
										</button>
									)}
									{/* next btn condition */}
									{slideIndex === 0 && (
										<button className="blue" onClick={() => HandleSlideIndex()}>
											Let’s go
										</button>
									)}
									{slideIndex < slideData.length-1 && slideIndex > 0 && (
										<button className="blue" onClick={() => HandleSlideIndex()}>
											Next
										</button>
									)}

									{slideIndex === slideData.length-1  && (
										<button onClick={()=>{handelCloseWalkThrough()}} className="blue">
											Close
										</button>
									)}
								</div>
							</div>
						</div>
					</div>

					{/* slide 1 end */}
				</div>
			</div>
			:null}
		</Modal>
	);
};
