/** imported components

    * Helmet ===> for add specific title & CSS for the page.  
    * BreadcrumbsItem for ==> for dynamic breadcrumb.  
    * ./svg => imported for all svg elements  
    * ./styles => imported for using of all created styled components.
    * useState, useEffect ===> React hooks (useState is a way to get/set value)
    * data for ===> It is data component

**/
import React from "react";
import { Modal } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import { useState, useRef } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { Collapse } from '@material-ui/core';
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import data, { faqData } from "./data";
import { apiEndpoints } from "../../services/api-endpoints";
import img1 from "./dimension-pdf/What-is-a-Factory-of-the-future-Infographic.pdf";
import img2 from "./dimension-pdf/Factory-of-the-Future-Leadership.pdf";
import img3 from "./dimension-pdf/Digital-Organization.pdf";
import img4 from "./dimension-pdf/Data-Driven-Maintenance.pdf";
import img5 from "./dimension-pdf/Automated-Data-Flow.pdf";
import img6 from "./dimension-pdf/Automated-Material-Flow.pdf";
import img7 from "./dimension-pdf/Digital-Ready-Infrastructure.pdf";
import img8 from "./dimension-pdf/360-Factory-Visibility.pdf";
import img9 from "./dimension-pdf/Intelligent-Quality.pdf";
import img10 from "./dimension-pdf/Tech-Augmented-Workforce.pdf";
import img11 from "./dimension-pdf/Zero-Touch-Production.pdf";
import abuPdf from "./dimension-pdf/AbuDhabiDeclaration.pdf";
import limaPdf from "./dimension-pdf/LimaDeclaration.PDF";
import susatainableGoalsPdf from "./dimension-pdf/TheSustainableDevelopmentGoalsReport2022.pdf";
import unidoAgendaPdf from "./dimension-pdf/UNIDOAndThe2030AgendaForSustainableDevelopment.pdf";
import unidoGenderPdf from "./dimension-pdf/UNIDOGenderEqualityAndEmpowermentOfWomen.pdf";
import {
  searchIcon, searchCloseIcon, filterIcon, filterCollapseIcon, filterCheckIcon, collapseCloseIcon,
  collapseOpenIcon, pdfIcon, webIcon, exploreSvg,infographicsImg
} from './svg.js'
import {
  MainWrapper,
  LearningMainWrapper,
  CenterHeader,
  LHeading,
  LearningContainer,
  LeftLearningWrapper,
  RightLearningWrapper,
  LeftHeader,
  ArticleTab,
  LearnCardContainer,
  LearnCardCol,
  LearnCard,
  LCardDetails,
  MethologyTab,
  IndustryStudiesTab,
  WebcastTab,
  CaseStudyTab,
  WebcastButton,
  RightHeader,
  RightCardContainer,
  PlatformCardCol,
  PlatformCard,
  PlatformDetail,
  FreeCounsulationButton,
  CaseStudyVideoPopup,
  CaseStudyRow,
  CaseStudyCol,
  CaseStudyCard,
  CaseStudyImg,
  CaseStudyText,
  PlayBtn,
  PlatformTrainingsTab,
  LeftLearningHeaderScroll,
  CaseStudyFAQTab, FaqHead, FaqSearchWrapper, FaqSearchInputWrapper, FaqSearchResult, FilterWrapper, FilterBox, FilterBoxHeader, FilterBoxBody, FilterCollapseTitle, Checkboxlabel,
  FaqBody, FaqWrap, QuesBtnWrap, CollapseBtn, LftQuesWrap, QuesDetailWrap, QuesWrap, AnsWrap,UnitarTab,InfographicsTab
} from "./styles";

export const LearningCenterView = (props) => {


  const homeDataItems = props.faqData;
  if (!homeDataItems.length) {
    return null;
  }
  const defaultFilterList = props.defaultData[0];
  const homeDataItem = props.faqData[0];
  const all_faq = homeDataItem.all_faq;
  const { tab_number } = useParams();
  const [isActive, setIsActive] = useState(tab_number || 7);
  const [isTrainingWalkthrough, setIsTrainingWalkthrough] = useState(false);
  const [isAssessmentWalkthrough, setIsAssessmentWalkthrough] = useState(false);
  const [isLossAnalysisWalkthrough, setIsLossAnalysisWalkthrough] =
    useState(false);
  const [isResultTourWalkthrough, setResultTourWalkthrough] = useState(false);
  const [isWatchNow, setIsWatchNow] = useState({});
  const [isShareAutoPlay, setIsShareAutoPlay] = useState(true);
  // Case Study Faq changes start
  const [searchValue, setSearchValue] = useState('');
  const [filterToggle, setFilterToggle] = useState(false);
  const [leftOpen, setLeftOpen] = React.useState(false);
  // Video popup
  const [isvideoPopup, setIsvideoPopup] = useState(false);
  const [isManuVideoPopup, setIsManuVideoPopup] = useState(false);
  const [filterCollapse, setFilterCollapse] = useState({
    platformTools: true,
    section: false,
    plant: false,
  })
  const searchInput = useRef();
  const filterButton = useRef();
  function highlightText(element, searchText) {
    const text = element.textContent;
    const regex = new RegExp(searchText.toString(), 'gi');
    const highlightedText = text.replace(regex, `<mark>$&</mark>`);

    if (highlightedText === text) {
      // search text not found
      element.innerHTML = text;
    } else {
      // search text found and highlighted
      element.innerHTML = highlightedText;
    }
  }

  const searchFunction = (e) => {
    let val = e.target.value;
    setSearchValue(val);
    // let allSearchP = document.querySelectorAll("[data-component='FaqSearchResult'] p");
    // let noResultP = document.querySelector("[data-component='FaqSearchResult'] .no_result_found");
    // let tmpArr = [];
    // allSearchP?.forEach(item => {
    //   const text = item.textContent;
    //   if (text.toLowerCase().startsWith(val.toLowerCase())) {
    //     item.style.display = 'block';
    //     tmpArr.push(1);
    //     highlightText(item, val);
    //   } else {
    //     item.style.display = 'none';
    //   }
    // });
    // if (tmpArr.length === 0) {
    //   noResultP.style.display = 'block'
    // } else {
    //   noResultP.style.display = 'none'
    // }
  }
  const closeSearch = (e) => {
    setSearchValue('');
    searchInput.current.focus();
  }

  const toggleFilterCollapse = (name) => {
    setFilterCollapse(prv => {
      return {
        ...prv,
        [name]: !filterCollapse[name]
      }
    })
  }
  const handleFilterCheck = () => {
    let tmpArr = [];
    let targetAllInputs = document.querySelectorAll("[data-component='FilterBoxBody'] input");
    targetAllInputs?.forEach(item => {
      if (item.checked === true) {
        tmpArr.push(item.value);
      }
    })
    var str = tmpArr.join(',');
    props.onFilter(str);
  }
  const clearAllFilter = () => {
    let tmpArr = [];
    let targetAllInputs = document.querySelectorAll("[data-component='FilterBoxBody'] input");
    targetAllInputs?.forEach(item => {
      item.checked = false
    });
    var str = tmpArr.join(',');
    props.onFilter(str);
  }
  const handleLeftClick = (val) => {
    if (val === leftOpen) {
      setLeftOpen(false);
    }
    else {
      setLeftOpen(val);
    }

  };
  // CSF end
  const [caseStudyCards, setCaseStudyCards] = useState([
    {
      id: "1",
      case_name: "Assessment & IR4.0 Loss Analysis",
      case_slug: "IR4.0 Technology Assessment & IR4.0 Loss Analysis",
      case_img: "case_study_1.svg",
      case_snippet:
        "Participants will learn various aspects that enable effective execution, such as being visionary and inspiring, strategic, and agile while always focusing to an end to end approach to digital transformation.",
      course_order: "1",
      video_url: `${apiEndpoints.baseUrl}/assets/videos/1.mp4`,
      total_time: "177",
      is_active: "1",
    },
    {
      id: "2",
      case_name: "Plant Industry 4.0 Roadmap",
      case_slug: "Plant Industry 4.0 Roadmap",
      case_img: "case_study_2.svg",
      case_snippet:
        "Watch how a plant conducted the digital transformation roadmap without any engagement. The size of the prize, the loss & recoverable potential, and the cash-flow overview are among the most important results.",
      course_order: "1",
      video_url: `${apiEndpoints.baseUrl}/assets/videos/2.mp4`,
      total_time: "182",
      is_active: "1",
    },
    {
      id: "3",
      case_name: "Enterprise Industry 4.0 Roadmap",
      case_slug: "Enterprise Industry 4.0 Roadmap",
      case_img: "case_study_3.svg",
      case_snippet:
        "Discover the roadmap built for a whole enterprise with a minimum engagement based on the companys different business needs and priorities.",
      course_order: "1",
      video_url: `${apiEndpoints.baseUrl}/assets/videos/3.mp4`,
      total_time: "177",
      is_active: "1",
    },
    {
      id: "4",
      case_name: "Execute phase for Enterprise",
      case_slug: "Execute phase for Enterprise",
      case_img: "case_study_4.svg",
      case_snippet:
        "Watch the execution of the Industry 4.0 Strategy for a whole enterprise. Find out how all personas are engaged in value creation, the enterprise architecture, the vendor selection process, and the strategy deployment.",
      course_order: "1",
      video_url: `${apiEndpoints.baseUrl}/assets/videos/4.mp4`,
      total_time: "183",
      is_active: "1",
    },
  ]);
  const plyrRef = React.useRef();
  const handleArticleTab = (index) => {
    setIsActive(index);
    // setIsActive((isActive) => !isActive);
  };
  const handleClickGlobalWebcastButton = ()=>{
    setIsvideoPopup(!isvideoPopup);
  }
  const handleClickManufacturingWebcastButton = () => {
    setIsManuVideoPopup(!isManuVideoPopup);
  };
  const handleClickWebcastButton = () => {
    // let webcastbtn = document.querySelector('[data-component="WebcastButton"]');

    let getWebcast = document.querySelector(".lai40-btn");
    getWebcast.click();
  };
  const handleLearnWalkthroughClick = (type) => {
    if (type == "TrainingWalkthrough") {
      return <Redirect to="/learning-training-walkthrough" />;
      //setIsTrainingWalkthrough(true);
      // console.log(pData.platform_type === TrainingWalkthrough)
    } else if (type == "AssessmentWalkthrough") {
      setIsAssessmentWalkthrough(true);
    } else {
      setIsLossAnalysisWalkthrough(true);
    }
  };
  React.useEffect(() => {
    props.handleExpandFal();
  }, []);

  // Video popup related code
  /*next fuction*/
  const handlePopupNextPrv = (action) => {
    let currentId = isWatchNow.id;
    let currentIndex = caseStudyCards.findIndex((item) => item.id == currentId);
    switch (action) {
      case "prv":
        {
          if (currentIndex > 0) {
            setIsWatchNow({ ...caseStudyCards[currentIndex - 1] });
            setIsShareAutoPlay(true);
          }
        }
        break;
      case "next":
        {
          if (currentIndex < caseStudyCards.length - 1) {
            setIsWatchNow({ ...caseStudyCards[currentIndex + 1] });
            setIsShareAutoPlay(true);
          }
        }
        break;
    }
  };

  React.useEffect(() => {

  }, [isWatchNow]);
  // end
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Resource Center | SmarterChains</title>
        <meta name="description" content="Resource Center" />
      </Helmet>
      {!isLossAnalysisWalkthrough && (
        <>
          <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
          <BreadcrumbsItem to="/p">Resource Center</BreadcrumbsItem>
        </>
      )}

      <MainWrapper
        data-component="MainWrapper"
        style={{ display: isLossAnalysisWalkthrough ? "none" : "" }}
      >
        <LearningMainWrapper
          data-component="LearningMainWrapper"
          style={{ display: isResultTourWalkthrough ? "none" : "" }}
        >
          <CenterHeader data-component="CenterHeader">
            <LHeading data-component="Heading">Resource Center</LHeading>
            <FreeCounsulationButton data-component="FreeCounsulationButton">
              <span>FREE Consultation Call</span>
            </FreeCounsulationButton>
          </CenterHeader>
          <LearningContainer data-component="LearningContainer">
            <LeftLearningWrapper data-component="LeftLearningWrapper">
              <LeftLearningHeaderScroll data-component="LeftLearningHeaderScroll">
                <LeftHeader data-component="LeftHeader">
                  <ArticleTab
                    data-component="ArticleTab"
                    className={isActive == 7 ? "active" : ""}
                    onClick={(e) => handleArticleTab(7)}
                  >
                    {" "}
                    <span>Unitar</span>{" "}
                  </ArticleTab>
                  <ArticleTab
                    data-component="ArticleTab"
                    className={isActive == 1 ? "active" : ""}
                    onClick={(e) => handleArticleTab(1)}
                  >
                    {" "}
                    <span>Methodology</span>{" "}
                  </ArticleTab>
                  <ArticleTab
                    data-component="ArticleTab "
                    className={isActive == 2 ? "active" : ""}
                    onClick={(e) => handleArticleTab(2)}
                  >
                    {" "}
                    <span> Industry 4.0 Studies</span>{" "}
                  </ArticleTab>
                  <ArticleTab
                    data-component="ArticleTab "
                    className={isActive == 3 ? "active" : ""}
                    onClick={(e) => handleArticleTab(3)}
                  >
                    {" "}
                    <span>Webcasts</span>{" "}
                  </ArticleTab>
                  <ArticleTab
                    data-component="ArticleTab "
                    className={isActive == 4 ? "active" : ""}
                    onClick={(e) => handleArticleTab(4)}
                  >
                    {" "}
                    <span>Case Studies</span>{" "}
                  </ArticleTab>
                  <ArticleTab
                    data-component="ArticleTab "
                    className={isActive == 6 ? "active" : ""}
                    onClick={(e) => handleArticleTab(6)}
                  >
                    {" "}
                    <span>FAQ's</span>{" "}
                  </ArticleTab>
                  <ArticleTab
                    data-component="ArticleTab "
                    className={isActive == 5 ? "active" : ""}
                    onClick={(e) => handleArticleTab(5)}
                  >
                    {" "}
                    <span>Platform Trainings</span>{" "}
                  </ArticleTab>
                  <ArticleTab
                    data-component="ArticleTab"
                    className={isActive == 8 ? "active" : ""}
                    onClick={(e) => handleArticleTab(8)}
                  >
                    {" "}
                    <span>Infographics</span>{" "}
                  </ArticleTab>
                </LeftHeader>
              </LeftLearningHeaderScroll>
              <MethologyTab
                data-component="MethologyTab"
                open={isActive == 1 ? "open" : ""}
              >
                <LearnCardContainer data-component="LearnCardContainer">
                  <LearnCardCol data-component="LearnCardCol">
                    <LearnCard data-component="LearnCard" to="/framework">
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/methology/factory-future.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Factory of the Future Framework</h1>
                        <p>The 4 step methodology of SmarterChains.</p>
                      </LCardDetails>
                    </LearnCard>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <LearnCard
                      data-component="LearnCard"
                      to="/industry-40-dimensions"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/methology/industry-dimension.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Industry 4.0 Dimensions</h1>
                        <p>The cornerstone of SmarterChains Framework.</p>
                      </LCardDetails>
                    </LearnCard>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <LearnCard data-component="LearnCard" to="/digital-plant">
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/methology/plant-archetypes.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Industry 4.0 Plant Archetypes</h1>
                        <p>A 4 way classification of manufacturing plants.</p>
                      </LCardDetails>
                    </LearnCard>
                  </LearnCardCol>
                </LearnCardContainer>
              </MethologyTab>
              <IndustryStudiesTab
                data-component="IndustryStudiesTab"
                open={isActive == 2}
              >
                <LearnCardContainer data-component="LearnCardContainer">
                  <LearnCardCol data-component="LearnCardCol">
                    <LearnCard data-component="LearnCard" to="/cpgstudy">
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/industry-4.0-studies/cpg-study.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>CPG Industry Study</h1>
                        <p>A benchmarking study for the CPG Industry.</p>
                      </LCardDetails>
                    </LearnCard>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    {
                      <LearnCard data-component="LearnCard" to="/whitepaper">
                        <div className="card_img">
                          <img
                            src={
                              require("../../assets/img/resource-center/industry-4.0-studies/cpg-factory.svg")
                                .default
                            }
                            alt=""
                          />
                        </div>
                        <LCardDetails data-component="LCardDetails">
                          <p className="cpg-txt">
                            An Industry 4.0 Framework to Create Smart and
                            Sustainable Factories
                          </p>
                        </LCardDetails>
                      </LearnCard>
                    }
                  </LearnCardCol>
                </LearnCardContainer>
              </IndustryStudiesTab>
              <WebcastTab data-component="WebcastTab" open={isActive == 3}>
                <LearnCardContainer data-component="LearnCardContainer">
                  <LearnCardCol data-component="LearnCardCol">
                    <WebcastButton
                      data-component="WebcastButton"
                      onClick={handleClickWebcastButton}
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/webcast/smarterChains-webcast.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        {/* <h1></h1> */}
                        <p className="cpg-txt">
                          4 steps for Industry 4.0 transformation
                        </p>
                      </LCardDetails>
                    </WebcastButton>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <WebcastButton
                      data-component="WebcastButton"
                      onClick={handleClickGlobalWebcastButton}
                      className="unitar-webcast-btn"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/webcast/global-webcast.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        {/* <h1></h1> */}
                        <p className="cpg-txt">
                          Harmonizing Global Skills for Industry 4.0
                        </p>
                      </LCardDetails>
                    </WebcastButton>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <WebcastButton
                      data-component="WebcastButton"
                      onClick={handleClickManufacturingWebcastButton}
                      className="unitar-webcast-btn"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/webcast/smart-manufacturing-webcast.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        {/* <h1></h1> */}
                        <p className="cpg-txt">
                          Smart Manufacturing: Revolutionizing the Industry with
                          AI and Data Analytics
                        </p>
                      </LCardDetails>
                    </WebcastButton>
                  </LearnCardCol>
                </LearnCardContainer>
              </WebcastTab>
              <CaseStudyTab data-component="CaseStudyTab" open={isActive == 4}>
                <LearnCardContainer data-component="LearnCardContainer">
                  {caseStudyCards?.map((item, index) => (
                    <CaseStudyCol key={index} data-component="CaseStudyCol">
                      <CaseStudyCard
                        data-component="CaseStudyCard"
                        onClick={() => setIsWatchNow(item)}
                      >
                        <CaseStudyImg data-component="CaseStudyImg">
                          <img
                            src={
                              require(`../../assets/img/case-study-img/${item.case_img}`)
                                .default
                            }
                            alt="CaseImg"
                          />
                          <PlayBtn data-component="PlayBtn">
                            <i
                              className="play-btn"
                              dangerouslySetInnerHTML={{ __html: playBtn }}
                            ></i>
                          </PlayBtn>
                        </CaseStudyImg>
                        <CaseStudyText data-component="CaseStudyText">
                          {item.case_name}
                        </CaseStudyText>
                      </CaseStudyCard>
                    </CaseStudyCol>
                  ))}
                </LearnCardContainer>
              </CaseStudyTab>
              <CaseStudyFAQTab
                data-component="CaseStudyFAQTab"
                open={isActive == 6}
              >
                <FaqHead data-component="FaqHead">
                  <h2>FAQ's</h2>
                  <FaqSearchWrapper data-component="FaqSearchWrapper">
                    <FaqSearchInputWrapper
                      data-component="FaqSearchInputWrapper"
                      active={searchValue.length > 0}
                    >
                      <i
                        className="serach-icon"
                        dangerouslySetInnerHTML={{ __html: searchIcon }}
                      ></i>
                      <input
                        placeholder="Search FAQ’s"
                        ref={searchInput}
                        value={searchValue}
                        onChange={searchFunction}
                      />
                      <i
                        className="close-icon"
                        dangerouslySetInnerHTML={{ __html: searchCloseIcon }}
                        onClick={closeSearch}
                      ></i>
                    </FaqSearchInputWrapper>
                    {/* FaqSearchInputWrapper end */}

                    {/* 
                        <FaqSearchResult data-component='FaqSearchResult' active={ searchValue.length > 0}>
                            <div className='result-list-wrapper'>
                            {all_faq?.map((item,index)=>(
                                <p key={index}>
                                  {item.question}
                                </p>
                            ))} 
                                <h5 className='no_result_found' style={{display:'none'}}> No Result Found</h5>
                            </div>
                        </FaqSearchResult> 
                      */}
                    {/* FaqSearchResult end */}
                  </FaqSearchWrapper>
                  {/* filter search wrapper end */}
                  <FilterWrapper data-component="FilterWrapper">
                    <button
                      className="filter-btn"
                      onClick={() => setFilterToggle(!filterToggle)}
                    >
                      <i dangerouslySetInnerHTML={{ __html: filterIcon }}></i>
                    </button>
                    <FilterBox data-component="FilterBox" active={filterToggle}>
                      <FilterBoxHeader data-component="FilterBoxHeader">
                        <h3>Filter FAQs</h3>
                        <button className="clear-all" onClick={clearAllFilter}>
                          Clear All
                        </button>
                        <button
                          className="close-btn"
                          onClick={() => setFilterToggle(!filterToggle)}
                        >
                          <i
                            dangerouslySetInnerHTML={{
                              __html: searchCloseIcon,
                            }}
                          ></i>
                        </button>
                      </FilterBoxHeader>
                      {/* FilterBoxHeader end */}

                      <FilterBoxBody data-component="FilterBoxBody">
                        <section>
                          <FilterCollapseTitle
                            data-component="FilterCollapseTitle"
                            active={filterCollapse.platformTools}
                            onClick={() =>
                              toggleFilterCollapse("platformTools")
                            }
                          >
                            <span>Platform Tools</span>
                            <i
                              className="collapse-icon"
                              dangerouslySetInnerHTML={{
                                __html: filterCollapseIcon,
                              }}
                            ></i>
                          </FilterCollapseTitle>
                          <Collapse in={filterCollapse.platformTools}>
                            <div>
                              <Checkboxlabel data-component="Checkboxlabel">
                                <input
                                  type="checkbox"
                                  value="1"
                                  onChange={handleFilterCheck}
                                />
                                <i
                                  dangerouslySetInnerHTML={{
                                    __html: filterCheckIcon,
                                  }}
                                ></i>
                                <span>Assessment</span>
                              </Checkboxlabel>
                            </div>
                            <div>
                              <Checkboxlabel data-component="Checkboxlabel">
                                <input
                                  type="checkbox"
                                  value="2"
                                  onChange={handleFilterCheck}
                                />
                                <i
                                  dangerouslySetInnerHTML={{
                                    __html: filterCheckIcon,
                                  }}
                                ></i>
                                <span>IR4.0 Assessment Results</span>
                              </Checkboxlabel>
                            </div>
                            <div>
                              <Checkboxlabel data-component="Checkboxlabel">
                                <input
                                  type="checkbox"
                                  value="3"
                                  onChange={handleFilterCheck}
                                />
                                <i
                                  dangerouslySetInnerHTML={{
                                    __html: filterCheckIcon,
                                  }}
                                ></i>
                                <span>IR4.0 Loss Analysis Assessment</span>
                              </Checkboxlabel>
                            </div>
                            <div>
                              <Checkboxlabel data-component="Checkboxlabel">
                                <input
                                  type="checkbox"
                                  value="4"
                                  onChange={handleFilterCheck}
                                />
                                <i
                                  dangerouslySetInnerHTML={{
                                    __html: filterCheckIcon,
                                  }}
                                ></i>
                                <span>IR4.0 Loss Analysis Results</span>
                              </Checkboxlabel>
                            </div>
                            <div>
                              <Checkboxlabel data-component="Checkboxlabel">
                                <input
                                  type="checkbox"
                                  value="5"
                                  onChange={handleFilterCheck}
                                />
                                <i
                                  dangerouslySetInnerHTML={{
                                    __html: filterCheckIcon,
                                  }}
                                ></i>
                                <span>IR4.0 Business Case Results</span>
                              </Checkboxlabel>
                            </div>
                          </Collapse>
                        </section>
                        {/* Platform Tools seciton end */}

                        {/* <section>
                                  <FilterCollapseTitle data-component='FilterCollapseTitle'
                                      active={filterCollapse.section}
                                      onClick={() => toggleFilterCollapse('section')}>
                                      <span>Sections</span>
                                      <i className='collapse-icon' dangerouslySetInnerHTML={{ __html: filterCollapseIcon }}></i>
                                  </FilterCollapseTitle>
                                  <Collapse in={filterCollapse.section}>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' value='s1' onChange={handleFilterCheck}/>
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>IR4.0 Training Courses</span>
                                          </Checkboxlabel>
                                      </div>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' value='s2' onChange={handleFilterCheck}/>
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>IR4.0 Technology Assessment</span>
                                          </Checkboxlabel>
                                      </div>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' value='s3' onChange={handleFilterCheck}/>
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>IR4.0 Loss Analysis</span>
                                          </Checkboxlabel>
                                      </div>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' value='s4' onChange={handleFilterCheck}/>
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>IR4.0 Strategy</span>
                                          </Checkboxlabel>
                                      </div>
                                  </Collapse>
                              </section> */}
                        {/* Sections seciton end */}

                        {/* <section>
                                  <FilterCollapseTitle data-component='FilterCollapseTitle'
                                      active={filterCollapse.plant}
                                      onClick={() => toggleFilterCollapse('plant')}>
                                      <span>Plants</span>
                                      <i className='collapse-icon' dangerouslySetInnerHTML={{ __html: filterCollapseIcon }}></i>
                                  </FilterCollapseTitle>
                                  <Collapse in={filterCollapse.plant}>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' onChange={handleFilterCheck}/>
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>All plants</span>
                                          </Checkboxlabel>
                                      </div>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' value='plant1' onChange={handleFilterCheck}/>
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>Plant1</span>
                                          </Checkboxlabel>
                                      </div>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' value='plant2' onChange={handleFilterCheck} />
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>Plant2</span>
                                          </Checkboxlabel>
                                      </div>
                                      <div>
                                          <Checkboxlabel data-component="Checkboxlabel">
                                              <input type='checkbox' value='plant3' onChange={handleFilterCheck}/>
                                              <i dangerouslySetInnerHTML={{ __html: filterCheckIcon }}></i>
                                              <span>Plant3</span>
                                          </Checkboxlabel>
                                      </div>
                                  </Collapse>
                              </section> */}
                        {/* Plants seciton end */}
                      </FilterBoxBody>
                      {/* FilterBoxBody end */}
                    </FilterBox>
                    {/* FilterBox end */}
                  </FilterWrapper>
                  {/* FilterWrapper end */}
                </FaqHead>
                <FaqBody data-component="FaqBody">
                  {all_faq?.map((item, index) => (
                    <FaqWrap
                      data-component="FaqWrap"
                      key={index}
                      style={{
                        display:
                          searchValue &&
                          !item.question
                            .toLowerCase()
                            .startsWith(searchValue.toLowerCase())
                            ? "none"
                            : "",
                      }}
                    >
                      <QuesBtnWrap
                        data-component="QuesBtnWrap"
                        className={`${leftOpen === index ? "active" : ""}`}
                        onClick={() => handleLeftClick(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <LftQuesWrap data-component="LftQuesWrap">
                          {item.question}
                        </LftQuesWrap>
                        <CollapseBtn
                          data-component="CollapseBtn"
                          className={`${leftOpen === index ? "active" : ""}`}
                        >
                          {leftOpen === index ? (
                            <i
                              dangerouslySetInnerHTML={{
                                __html: collapseCloseIcon,
                              }}
                            ></i>
                          ) : (
                            <i
                              dangerouslySetInnerHTML={{
                                __html: collapseOpenIcon,
                              }}
                            ></i>
                          )}
                        </CollapseBtn>
                      </QuesBtnWrap>
                      <Collapse
                        in={leftOpen === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <QuesDetailWrap
                          data-component="QuesDetailWrap"
                          style={{ visibility: item.is_active ? "" : "hidden" }}
                        >
                          <AnsWrap data-component="QuesWrap">
                            <p className="case-text">{item.answer}</p>
                          </AnsWrap>
                        </QuesDetailWrap>
                      </Collapse>
                    </FaqWrap>
                  ))}
                </FaqBody>
              </CaseStudyFAQTab>
              <PlatformTrainingsTab
                data-component="PlatformTrainingsTab"
                open={isActive == 5 ? "open" : ""}
              >
                <LearnCardContainer data-component="LearnCardContainer">
                  <LearnCardCol data-component="LearnCardCol">
                    <LearnCard
                      data-component="LearnCard"
                      to="/product-training/maturity-assessment/introduction"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/platform-trainings/assessment-icon.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Assessment</h1>
                        <p>SmarterChains Product Course</p>
                      </LCardDetails>
                    </LearnCard>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <LearnCard
                      data-component="LearnCard"
                      to="/product-training/loss-analysis/introduction"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/platform-trainings/loss-icon.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>IR4.0 Loss Analysis</h1>
                        <p>SmarterChains Product Course</p>
                      </LCardDetails>
                    </LearnCard>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <LearnCard
                      data-component="LearnCard"
                      to="/product-training/industry-4-0-roadmap/introduction"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/platform-trainings/industry-icon.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>IR4.0 Strategy</h1>
                        <p>SmarterChains Product Course</p>
                      </LCardDetails>
                    </LearnCard>
                  </LearnCardCol>
                </LearnCardContainer>
              </PlatformTrainingsTab>
              <UnitarTab
                data-component="MethologyTab"
                open={isActive == 7 ? "open" : ""}
              >
                <LearnCardContainer data-component="LearnCardContainer">
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={abuPdf}
                      download="AbuDhabiDeclaration.pdf"
                    >
                      <div className="card_img">
                        <i dangerouslySetInnerHTML={{ __html: pdfIcon }}></i>
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Abu Dhabi Declaration</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={limaPdf}
                      download="LimaDeclaration.pdf"
                    >
                      <div className="card_img">
                        <i dangerouslySetInnerHTML={{ __html: pdfIcon }}></i>
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Lima Declaration</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={susatainableGoalsPdf}
                      download="TheSustainableDevelopmentGoalsReport2022.pdf"
                    >
                      <div className="card_img">
                        <i dangerouslySetInnerHTML={{ __html: pdfIcon }}></i>
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>The Sustainable Development Goals Report 2022</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={unidoAgendaPdf}
                      download="UNIDOAndThe2030AgendaForSustainableDevelopment.pdf"
                    >
                      <div className="card_img">
                        <i dangerouslySetInnerHTML={{ __html: pdfIcon }}></i>
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>
                          UNIDO and the 2030 agenda for Sustainable Development
                        </h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={unidoGenderPdf}
                      download="UNIDOGenderEqualityAndEmpowermentOfWomen.pdf"
                    >
                      <div className="card_img">
                        <i dangerouslySetInnerHTML={{ __html: pdfIcon }}></i>
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>UNIDO gender equality and empowerment of women</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      target="_blank"
                      href="https://sdgs.un.org/goals/goal9"
                    >
                      <div className="card_img">
                        <i dangerouslySetInnerHTML={{ __html: webIcon }}></i>
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Sustainable Development Goals</h1>
                        <p>
                          Explore
                          <i
                            dangerouslySetInnerHTML={{ __html: exploreSvg }}
                          ></i>
                        </p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      target="_blank"
                      href="https://sdgs.un.org/goals/goal9"
                    >
                      <div className="card_img">
                        <i dangerouslySetInnerHTML={{ __html: webIcon }}></i>
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>UNIDO gender equality and empowerment of women</h1>
                        <p>
                          Explore
                          <i
                            dangerouslySetInnerHTML={{ __html: exploreSvg }}
                          ></i>
                        </p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                </LearnCardContainer>
              </UnitarTab>
              <InfographicsTab
                data-component="MethologyTab"
                open={isActive == 8 ? "open" : ""}
              >
                <LearnCardContainer data-component="LearnCardContainer">
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img1}
                      download="What-is-a-Factory-of-the-future-Infographic.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/What-is-a-Factory-of-the-future-Infographic.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>What is the Factory of the Future ?</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img2}
                      download="Factory-of-the-Future-Leadership.pdf"
                      target="_blank"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Factory-of-the-Future-Leadership.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Factory of the Future Leadership</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img3}
                      download="Digital-Organization.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Digital-Organization.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Digital Organization</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img4}
                      download="Data-Driven-Maintenance.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Data-Driven-Maintenance.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Data-Driven Maintenance</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img5}
                      download="Automated-Data-Flow.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Automated-Data-Flow.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Automated Data Flow</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img6}
                      download="Automated-Material-Flow.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Automated-Material-Flow.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Automated Material Flow</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img7}
                      download="Digital-Ready-Infrastructure.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Digital-Ready-Infrastructure.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Digital-Ready Infrastructure</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img8}
                      download="360-Factory-Visibility.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/360-Factory-Visibility.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>360˚ Factory Visibility</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img9}
                      download="Intelligent-Quality.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Intelligent-Quality.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Intelligent Quality</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img10}
                      download="Tech-Augmented-Workforce.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Tech-Augmented-Workforce.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Tech-Augmented Workforce</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                  <LearnCardCol data-component="LearnCardCol">
                    <a
                      className="learn-card"
                      href={img11}
                      download="Zero-Touch-Production.pdf"
                    >
                      <div className="card_img">
                        <img
                          src={
                            require("../../assets/img/resource-center/infographics/Zero-Touch-Production.svg")
                              .default
                          }
                          alt=""
                        />
                      </div>
                      <LCardDetails data-component="LCardDetails">
                        <h1>Zero-Touches Production</h1>
                        <p>Download</p>
                      </LCardDetails>
                    </a>
                  </LearnCardCol>
                </LearnCardContainer>
              </InfographicsTab>
            </LeftLearningWrapper>
            <RightLearningWrapper data-component="RightLearningWrapper">
              <RightHeader data-component="RightHeader">
                <span>Platform Tools Tours</span>
              </RightHeader>
              <RightCardContainer data-component="RightCardContainer">
                {data.map((pData) => (
                  <PlatformCardCol data-component="PlatformCardCol">
                    <PlatformCard
                      as={!pData.url ? "button" : ""}
                      data-component="PlatformCard"
                      to={pData.url}
                    >
                      <div className="pcard-img">
                        <img
                          src={pData.path}
                          alt=""
                          className={pData["img-class"]}
                        />
                      </div>
                      <PlatformDetail data-component="PlatformDetail">
                        <h1 className={pData["engage-class"]}>
                          {pData.platform}
                        </h1>
                        <p>{pData["engage-time"]}</p>
                      </PlatformDetail>
                    </PlatformCard>
                  </PlatformCardCol>
                ))}
              </RightCardContainer>
            </RightLearningWrapper>
          </LearningContainer>
        </LearningMainWrapper>

        <CaseStudyVideoPopup data-component="CaseStudyVideoPopup">
          <div
            className={`case-study-vid-popup-wrapper ${
              isWatchNow.id ? "active" : ""
            }`}
          >
            <div className="case-study-popup-card">
              <div className="case-study-popup-header">
                <div className="div-share-btn">
                  <p className="popup-title">{isWatchNow?.case_name}</p>
                </div>
                <button
                  className="btn-close-popup"
                  onClick={() => setIsWatchNow({})}
                >
                  <span dangerouslySetInnerHTML={{ __html: closeSvg }}></span>
                </button>
              </div>
              {/* header div of the card ended */}
              <div className="case-study-popup-content">
                <Plyr
                  ref={plyrRef}
                  source={{
                    type: "video",
                    sources: [
                      {
                        src: isWatchNow?.video_url,
                        type: "video/mp4",
                      },
                    ],
                    autoplay: isShareAutoPlay,
                  }}
                  options={{
                    controlshidden: true,
                  }}
                />
              </div>
            </div>
            {/* case-study-popup-card" end */}
          </div>
        </CaseStudyVideoPopup>
        {/*
              <TrainingWalkthrough
                  open={isTrainingWalkthrough}
                  handlePopup={setIsTrainingWalkthrough}
                />
                
                <AssessmentWalkthrough
                  open={isAssessmentWalkthrough}
                  handlePopup={setIsAssessmentWalkthrough}
                  activeResult={setResultTourWalkthrough}
                />
                <AssessmentResultWalkthrough
                  open={isResultTourWalkthrough}
                  handlePopup={setResultTourWalkthrough}
                  activeResult={setIsAssessmentWalkthrough}
                />
          */}
      </MainWrapper>
      {/*<LossAnalysisWalkthrough
                open={isLossAnalysisWalkthrough}
                handlePopup={setIsLossAnalysisWalkthrough}
              />*/}
      <Modal
        open={isvideoPopup}
        onClose={handleClickGlobalWebcastButton}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="quick-start-vid-popup"
      >
        <div className="vid__wrapper custom_vid__wrapper">
          <Plyr
            source={{
              type: "video",
              sources: [
                {
                  src: require(`../../assets/video/Harmonizing-Global-Skills-for-Industry-4-0.mp4`)
                    .default,
                  type: "video/mp4",
                },
              ],
            }}
            options={{
              //controlshidden: true,
              controls: [
                "play-large",
                "rewind",
                "fast-forward",
                "play",
                "progress",
                "current-time",
                "duration",
                "mute",
                "volume",
                "settings",
                "fullscreen",
              ],
            }}
          />
        </div>
      </Modal>
      <Modal
        open={isManuVideoPopup}
        onClose={handleClickManufacturingWebcastButton}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="quick-start-vid-popup"
      >
        <div className="vid__wrapper custom_vid__wrapper">
          <Plyr
            source={{
              type: "video",
              sources: [
                {
                  src: require(`../../assets/video/Smart-Manufacturing-Revolutionizing-the-Industry-with-AI-and-Data-Analytics.mp4`)
                    .default,
                  type: "video/mp4",
                },
              ],
            }}
            options={{
              //controlshidden: true,
              controls: [
                "play-large",
                "rewind",
                "fast-forward",
                "play",
                "progress",
                "current-time",
                "duration",
                "mute",
                "volume",
                "settings",
                "fullscreen",
              ],
            }}
          />
        </div>
      </Modal>
    </>
  );
};

const closeSvg = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.518597 15.4817C0.232352 15.1954 0.232352 14.7313 0.518597 14.4451L14.4451 0.518562C14.7314 0.232316 15.1955 0.232317 15.4817 0.518562C15.768 0.804806 15.768 1.2689 15.4817 1.55514L1.55518 15.4817C1.26894 15.7679 0.804841 15.7679 0.518597 15.4817Z" fill="#78909C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.518451 0.518414C0.804696 0.232169 1.26879 0.232169 1.55503 0.518414L15.4816 14.4449C15.7678 14.7312 15.7678 15.1953 15.4816 15.4815C15.1953 15.7678 14.7312 15.7678 14.445 15.4815L0.518451 1.555C0.232207 1.26875 0.232207 0.804658 0.518451 0.518414Z" fill="#78909C"/>
</svg>
`;
const playBtn = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#1487E3"/>
<path d="M15 28V12L27.3871 19.7419L15 28Z" fill="#E5F0FF"/>
</svg>
`;
