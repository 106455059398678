/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * ResultPlantView ===> custom view component

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { Link, Redirect } from "react-router-dom";
import { ResultPlantView } from "./resultplant-view";
import { ResultPlantLimitedView } from "./resultplantlimited-view";
//ResultPlantController start
export const ResultPlantController = (props) => {
	//defining variables here
	const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	const slug = getLastItem(window.location.pathname);
	const [results, setResults] = useState([]);
	const [refresh, setRefresh] = useState("");
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const gid = (g)=>{
		setnewGroupid(g)
	}
	const [newgroupid, setnewGroupid] = useState();
	
	useEffect(() => {
		props.handleExpandFal();
		serviceProvider.post("/home?type=setfirstresultseen", JSON.stringify({ uid: usid }),true).then((response) => {
		});
		if(newgroupid){
			const search = async () => {
				const apiUrl = `/resultsmaturityassess?param=result&uid=${usid}&slug=${slug}&gid=${newgroupid}`;
				const { data } = await serviceProvider.get(apiUrl,true,{});
				setResults(data);
				
			};
			search();
	}else{
		const search = async () => {
			const apiUrl = `/resultsmaturityassess?param=result&uid=${usid}&slug=${slug}&gid=12`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}

	}, [newgroupid]);
	
	
	if (results.length) {
		
		if(results[0].is_limitedresult == 1){
			return <ResultPlantLimitedView groupid={gid}  resultsData={results} {...props} />;
		}else{
			return <ResultPlantView groupid={gid} slug={slug} resultsData={results} {...props} />;
		}		
	}else{
		return (
				<>
					<div></div>
				</>
			);
		
		}
};
