/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * SidebarView ===> custom view component

**/
import React, { useState, useEffect } from "react";
import { Redirect } from 'react-router';
import { SidebarView } from "./sidebar-view";
import "./sidebar.scss";
//SidebarControler component start
export const SidebarControler = (props) => {
  const login = localStorage.getItem("isLoggedIn");
    
  if (!login) {
  return <Redirect to="/sign-in" />;
}
	
	return <SidebarView  expanded={props.expanded} handleExpand={props.handleExpand} handleChange={props.handleChange} {...props} />;
};
