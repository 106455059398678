import React, { useState, useEffect } from "react";
import { RoadmapStepFull1View } from './RoadmapStepFull1View'
import { Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios';
import {
  LoaderPopupWrap,
  LoaderPop,
} from "./common-roadmap-style";
export const RoadmapStepFull1Ctrl = (props) => {

  const [results, setResults] = useState(false);
  const [status, setStatus] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [filtercall, setfiltercall] = useState(0);
  const [filter, setfilter] = useState(0);
  const [filter1, setfilter1] = useState(0);
  const [filter2, setfilter2] = useState(0);
  const [filter3, setfilter3] = useState(0);
  const [filter4, setfilter4] = useState(0);
  const [filter5, setfilter5] = useState(0);
  const user = JSON.parse(localStorage.getItem("userData"));
  const login = localStorage.getItem("isLoggedIn");
  const uid = user ? user.uid : 0;
  const progress = user ? user.progress : 0;
  const gid = user ? user.group_id : 0;
  if (!login) {
    return <Redirect to="/sign-in" />;
  }
  if (progress < 100 && (gid == 14 || gid == 2)) {
    return <Redirect to={`/assessment/${plant_slug}`} />;
  }
  const segments = window.location.pathname.slice(1).split("/");
  const plant_slug = (typeof segments[2] !== "undefined" ? segments[2] : 0);
  const chapter_id = (typeof segments[3] !== "undefined" ? segments[3] : 0);


  useEffect(() => {
    const search = async () => {
      setResults(prv=>false);
      const apiUrl = `/roadmapcomplete?param=step1&uid=${uid}&slug=${plant_slug}&filter=${filter}&filter1=${filter1}&filter2=${filter2}&filter3=${filter3}&filter4=${filter4}&filter5=${filter5}&sid=${chapter_id}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults(data);
    };
    search();
  }, [status,filtercall,updateData]);

  if (results) {
    return (
      <>
        <RoadmapStepFull1View filter={filter} filter1={filter1} filter2={filter2} filter3={filter3} filter4={filter4} filter5={filter5} setStatus={setStatus} filtercall={filtercall} setfiltercall={setfiltercall} setfilter={setfilter} setfilter1={setfilter1} setfilter2={setfilter2} setfilter3={setfilter3} setfilter4={setfilter4} setfilter5={setfilter5} stepData={results} setUpdateData={setUpdateData} {...props} />
      </>
    );
  } else {
    return (
      <>
        <div>
          <LoaderPopupWrap data-component="LoaderPopupWrap">
            <LoaderPop data-component="LoaderPop">
              <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
            </LoaderPop>
          </LoaderPopupWrap>
        </div>
      </>
    );
  }

}
