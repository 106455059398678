/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  * Plyr ===> third party plugin
  * FacebookShareButton, LinkedinShareButton, TwitterShareButton ===> component from react-share 

**/
import React, { Component, useState, useEffect, useRef } from "react";
import { Redirect } from "react-router";
import { useHistory } from "react-router-dom";
import { HandlePageOnClick } from "../../webutils/webutils";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import serviceProvider from "../../services/axios";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import { apiEndpoints } from '../../services/api-endpoints';
import {UpgradePopView} from './UpgradeView';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import $ from "jquery";

// training progressbar
const TrainProgressBar = (props) => {
  return (
    <>
      {/* this section has
				t__progressbar__left
				t__progressbar__right
				t__progressbar__space
			*/}
      <div className="t__progressbar__wrapper">
        {/* this section has text */}
        <div className="t__progressbar__left">
          <p>Progress:</p>
        </div>
        {/* this section has progressbar */}
        <div className="t__progressbar__right">
          <div className="t__progress__relative">
            <div
              className={
                props.value < 100 && props.value > 0
                  ? "t__progress__status__wrapper progress"
                  : props.value === 100
                    ? "t__progress__status__wrapper completed"
                    : "t__progress__status__wrapper not__start"
              }
            >
              <div
                className="t__progress__status"
                style={{ width: `${props.value}%` }}
              ></div>
            </div>
            <div className="t__progress__txt">{props.value}%</div>
          </div>
        </div>
        {/* this section is for spacing */}
        <div className="t__progressbar__space"></div>
        {/* this section has text */}
        <div className="t__progressbar__left">
          <p>
            Done:<span className="b">{props.doneTime}</span>
          </p>
        </div>
        {/* this section has time */}
        <div className="t__progressbar__right">
          <p>
            Left:<span className="b">{props.leftTime}</span>
          </p>
        </div>
      </div>
    </>
  );
};

// content list component
const ContentList = (props) => {
  return (
    <a
      href={
        props.status !== "none"
          ? `/product-training/${props.course_slug}/${props.section_slug}`
          : "javascript:void(0)"
      }
      onClick={props.callBackFunction}
    >
      {/* this section has
				course icon
				course text
			*/}
      <li className="course__inner__li">
        <div className="course__inner__li__icon">
          <div className="course__inner__li__icon">
            {props.status === "play" ? (
              <img
                src={
                  require("../../assets/img/course-overview/vid_play.svg")
                    .default
                }
                className="vid__play"
                alt="vid_play_img"
              />
            ) : props.status === "complete" ? (
              <img
                src={
                  require("../../assets/img/course-overview/vid_completed.svg")
                    .default
                }
                className="vid-completed"
                alt="vid_completed_img"
              />
            ) : props.status === "unlock" ? (
              <img
                src={
                  require("../../assets/img/course-overview/vid_unlock.svg")
                    .default
                }
                className="vid-completed"
                alt="vid_completed_img"
              />
            ) : (
              <img
                src={
                  require("../../assets/img/course-overview/vid_lock.svg")
                    .default
                }
                className="vid_lock"
                alt="vid_lock_img"
              />
            )}
          </div>
        </div>
        {/* this section has 
					title 
					time
				*/}
        <div className="course__inner__li__txt">
          <p className="h1__title">{props.listTitle}</p>
          <p className="time__text">{props.listTime}</p>
        </div>
      </li>
    </a>
  );
};

// TrainTabPane component
//progressbar circle component
const SvgCircle = (props) => {
  const { value } = props;
  return (
    <svg viewBox="0 0 36 36">
      <text
        className="percent__text"
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="10"
      >
        {value}
        <tspan fontSize="5">%</tspan>
      </text>
      <path
        className="circle-bg"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke="#EBF5FA"
        strokeWidth="2.4"
        fill="none"
      ></path>
      <path
        className="circle"
        strokeDasharray={`${value}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={
          value < 80 && value > 0
            ? "#FFBC1F"
            : value > 79
              ? "#40D30D"
              : "transparent"
        }
        strokeWidth="2.4"
        fill="none"
      ></path>
    </svg>
  );
};
// CourseOverviewSingleView component start here
let isFirstTime=1;
export const ProductTrainingSingleView = (props) => {
  //defining variables here
  const [isLockedVidPopup, setIsLockedVidPopup] = useState(false);
  const [isStateChange, setIsStateChange] = useState(false);
  const [isCongratulationsPopup, setIsCongratulationsPopup] = useState(false);
  const [vidHeight, setVidHeight] = useState(400);
  const [vidLoader, setvidLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [playStatus, setplayStatus] = useState(1);
  const [isEdgeCase, setIsEdgeCase] = useState(false);
  const[open,setOpen] = useState(true)
  let history = useHistory();
  const vidDiv = useRef();
  const courseDataItems = props.courseData;
  if (!courseDataItems.length) {
    return null;
  }
  const courseDataItem = props.courseData[0];
  const quizDataItem = props.quizData[0];
  const courseCurrentItem = courseDataItem.current;
  const uid = courseDataItem.uid;
  const sid = courseCurrentItem.id;
  const [timeWatch, settimeWatch] = useState(courseDataItem.total_time_watch);
  const [currtimeWatch, setcurrtimeWatch] = useState(
    courseCurrentItem.time_watched
  );
  const [activeTabIndex, setActiveTabIndex] = useState(props.onTab);

  const handleOpenContactUSPopup=(value)=>{
    localStorage.setItem(`upgradeSection`, value);
    document.querySelector('.header-icons-li.upgrade .contact-btn')?.click();
  }

  useEffect(() => {
    let timer;
    const player = new Plyr("#player", {
      controls: [
        "play-large",
        "rewind",
        "fast-forward",
        "play",
        "progress",
        "current-time",
        "duration",
        "mute",
        "volume",
        "settings",
        "fullscreen",
      ],
    });
    window.player = player;
    if (count) {
      if (
        courseCurrentItem.section_duration <= courseCurrentItem.time_watched
      ) {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=0,10000`,
              type: "video/mp4",
            },
          ],
          autoplay: true,
        };
      } else {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=${courseCurrentItem.time_watched},10000`,
              type: "video/mp4",
            },
          ],
          autoplay: true,
        };
      }
    } else {
      if (
        courseCurrentItem.section_duration <= courseCurrentItem.time_watched &&
        !+courseCurrentItem.has_seen
      ) {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=0,10000`,
              type: "video/mp4",
            },
          ],
          autoplay: false,
        };
      } else {
        player.source = {
          type: "video",
          sources: [
            {
              src: `${apiEndpoints.baseUrl}/assets/videos/${courseCurrentItem.video_url}#t=${courseCurrentItem.time_watched},10000`,
              type: "video/mp4",
            },
          ],
          autoplay: false,
        };
      }
    }
    player.once("canplay", (event) => {
      const currentTime = courseCurrentItem.time_watched
        ? courseCurrentItem.time_watched
        : 0;
      player.currentTime = currentTime;
    });
    if (courseCurrentItem.has_seen == null || courseCurrentItem.has_seen == 0) {
      serviceProvider.post(
        "/item?type=sethasseen",
        JSON.stringify({ uid: uid, sid: sid }),
        true
      );
    }
    player.on("play", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }
      var time = player.currentTime;
      setplayStatus(1);
      /**
       * SCNV-2669
       * This condition has been implemented for removing flickering image issue on Safari and other browser
       * Issue was coming because of video play status and it's always coming when we were playing the video.
       */
      // if(playStatus != 1){
      //   setplayStatus(1);
      // }
      // end SCNV-2669
      props.setHashvalue(null);
      if (
        parseInt(courseCurrentItem.section_duration) >
        parseInt(courseCurrentItem.time_watched)
      ) {
        serviceProvider.post(
          "/Producttrainings?type=settime",
          JSON.stringify({ uid: uid, sid: secid, time: time }),
          true
        );
      }
    });
    player.on("pause", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }
      var time = player.currentTime;
      setplayStatus(0);
      if (
        parseInt(courseCurrentItem.section_duration) >
        parseInt(courseCurrentItem.time_watched)
      ) {
        serviceProvider.post(
          "/Producttrainings?type=settime",
          JSON.stringify({ uid: uid, sid: secid, time: time }),
          true
        );
      }
    });
    player.on("timeupdate", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }

      var currtimeWatch = 0;
      if (document.getElementById("currtimeWatch")) {
        var currtimeWatch = document.getElementById("currtimeWatch").value;
      }
      var playStatus = 0;
      if (document.getElementById("playStatus")) {
        var playStatus = document.getElementById("playStatus").value;
      }

      var time = player.currentTime;
      if (time > currtimeWatch) {
        if (
          parseInt(courseCurrentItem.section_duration) >
          parseInt(courseCurrentItem.time_watched)
        ) {
          //settimeWatch(courseDataItem.total_time_watch+time-courseCurrentItem.time_watched);
          setcurrtimeWatch(time);
        }
      }
      if (playStatus == 0) {
        player.pause();
      }
      serviceProvider.post(
        "/Producttrainings?type=settime",
        JSON.stringify({ uid: uid, sid: secid, time: time }),
        true
      );
      serviceProvider
        .post(
          "/Producttrainings?type=gettotaltimewatch",
          JSON.stringify({ uid: uid, course_id: props.courseData[0].id }),
          true
        )
        .then((response) => {
          if (response) {
            var time = parseInt(response.data);
            settimeWatch(time);
          }
        });
    });
    player.on("enterfullscreen", (event) => {
      document
        .querySelector(".plyr__controls__item.plyr__control")
        .classList.add("plyr__control--pressed");
    });
    player.on("exitfullscreen", (event) => {
      serviceProvider
        .post(
          "/Producttrainings?type=gettotaltimewatch",
          JSON.stringify({ uid: uid, course_id: props.courseData[0].id }),
          true
        )
        .then((response) => {
          if (response) {
            var time = parseInt(response.data);
            settimeWatch(time);
          }
        });
      setVidHeight(event.detail.plyr.media.clientHeight);
    });

    player.on("playing", (event) => {
      var secid = 0;
      if (document.getElementById("sectionid")) {
        var secid = document.getElementById("sectionid").value;
      }
      var time = player.currentTime;
      setplayStatus(1);
      /**
       * SCNV-2669
       * This condition has been implemented for removing flickering image issue on Safari and other browser
       * Issue was coming because of video play status and it's always coming when we were playing the video.
       */
      // if(playStatus != 1){
      //   setplayStatus(1);
      // }
      // end SCNV-2669
      if (
        parseInt(courseCurrentItem.section_duration) >
        parseInt(courseCurrentItem.time_watched)
      ) {
        serviceProvider.post(
          "/Producttrainings?type=settime",
          JSON.stringify({ uid: uid, sid: secid, time: time }),
          true
        );
      }
    });
    player.on("ended", (event) => {
      var secid = document.getElementById("sectionid").value;
      var time = player.currentTime;
      var timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');
      setcurrtimeWatch(0);
      serviceProvider.post(
        "/Producttrainings?type=settime",
        JSON.stringify({ uid: uid, sid: secid, time: time }),
        true
      );
      serviceProvider
        .post(
          "/Producttrainings?type=sethascomplete",
          JSON.stringify({ uid: uid, sid: secid, timezone: timezone }),
          true
        )
        .then((response) => {
          serviceProvider
            .post(
              "/Producttrainings?type=gettotaltimewatch",
              JSON.stringify({ uid: uid, course_id: props.courseData[0].id }),
              true
            )
            .then((response) => {
              if (response) {
                var time = parseInt(response.data);
                settimeWatch(time);
              }
            });

          if (response.data.islast == 1) {
            if (response.data.watched == 0) {
              
            }
          } else {
            const vurl = `${apiEndpoints.baseUrl}/assets/videos/${response.data.video_url}`;
            player.source = {
              type: "video",
              sources: [{ src: vurl, type: "video/mp4" }],
              autoplay: true,
            };
            props.onComplete(response.data.nsid);
            history.push(response.data.redirect);
          }
        });
    });

    //setVidHeight(player.media.clientHeight+(player.media.clientHeight/100)*12);
    player.on("loadeddata", (event) => {
      setVidHeight(event.detail.plyr.media.clientHeight);

      if (props.hashvalue && props.hashvalue != null) {
        let targetCard = document.querySelectorAll(".qna__tabpane__card__li");
        let tmpInd = false;
        targetCard.forEach((item, index1) => {
          if (item.getAttribute("id") === props.hashvalue) {
            isFirstTime == 1 && document.querySelector(`#${props.hashvalue}`).click();
            tmpInd = true;
            const weburl = window.location.hash;
            const replyUrlSplit = weburl.split('-');
            const replyId = replyUrlSplit[replyUrlSplit.length - 1];
            const ansRplyCard = document.querySelector(`#allQnaback-566-${replyId}`);
            const ansSubRplyCard = document.querySelector(`#allQnabackRply-566-${replyId}`);
            if (weburl.includes('allQnaback') && !ansRplyCard && !ansSubRplyCard && isFirstTime == 1) {
              setIsEdgeCase(true);
              document.querySelector('.back-to-q button.h2')?.click();
              isFirstTime += 1;
            }
            $(".training-content").animate(
              {
                scrollTop:
                  150 + document.querySelector(".course__inner__top__sec").offsetHeight,
              },
              "slow"
            );
          }
        });
        if(!tmpInd){
            setIsEdgeCase(true);
            $(".training-content").animate(
              {
                scrollTop:
                   document.querySelector(".training-content").scrollHeight,
              },
              "slow"
            );
          }
      }
    });
   
    return () => {
      setCount(1);
      if (isStateChange) {
        player.destroy();
        setIsStateChange(false);
      }
    };
  }, [sid, props.tid, courseCurrentItem.id, isStateChange]);
  //Function to handle play and previous
  const HandlePlayPrevious = (index) => {
    serviceProvider
      .post(
        "/Producttrainings?type=getreqvid",
        JSON.stringify({ uid: uid, sid: index }),
        true
      )
      .then((response) => {
        const vurl = `${apiEndpoints.baseUrl}/assets/videos/${response.data.video_url}`;
        player.source = {
          type: "video",
          sources: [{ src: vurl, type: "video/mp4" }],
          autoplay: true,
        };
      });
    props.onComplete(index);
    setIsStateChange(true);
  };
  //SCNV-2669
  const TrainTabPane = (props) => {
    return <>{props.item == activeTabIndex && <>{props.children}</>} </>;
  };

  
  //Function to handle play previous popup
  const HandlePlayPreviousPopup = (index) => {
    props.onComplete(-1);
    props.onAllComplete(1);
   
  };
  //function to handle lock video popup
  const HandleLockVidPopup = () => {
    setIsLockedVidPopup(!isLockedVidPopup);
    return false;
  };
  //fucntion to handle congratulation popup
  const HandleCongratulationsPopup = () => {
    props.onAllComplete(1);
   
  };
  const HandleValueOnChange = (e) => {
    return true;
  };
  
 
  //function to sort submit answer
  const submitSortBy = (event) => {
    props.onSortBy(event);
  };
  //function to filter submit answer
  const submitFilterBy = (event) => {
    props.onFilterBy(event);
  };
  //function to open search
  const searchOpen = (event) => {
    if (event.key === "Enter") {
      props.onSearchOpen(event.target.value);
    }
  };
  //function to close searach
  const searchClose = (event) => {
    props.onSearchOpen("");
  };
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    if (d == 0) {
      return "0m";
    }
    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    return hDisplay + mDisplay + sDisplay;
  };
  const secondsToHmOnly = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    if (d == 0) {
      return "0m";
    }
    var hDisplay = h == 0 ? "0h " : h + "h ";
    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
    var sDisplay = "";
    return hDisplay + mDisplay + sDisplay;
  };
  let totalTime = 0;
  let doneTime = 0;
  const courseDataItemSections = courseDataItem.sections.map(
    (section, index) => {
      totalTime = totalTime + parseInt(section.section_duration);

      if (section.has_complete == 1) {
        doneTime = doneTime + parseInt(section.section_duration);
      } else {
        doneTime = doneTime + parseInt(section.time_watched);
      }

      if (section.id == sid) {
        var status =
          section.id == courseCurrentItem.id && courseDataItem.currentsid != 0
            ? "play"
            : courseDataItem.allcomplete == 1
              ? "complete"
              : "play";
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status={status}
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
          />
        );
      } else if (section.has_seen == 1 && section.has_complete == 0) {
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status="unlock"
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
            callBackFunction={() => HandlePlayPrevious(section.id)}
          />
        );
      } else if (section.has_seen == 1 && section.has_complete == 1) {
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status="complete"
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
            callBackFunction={() => HandlePlayPrevious(section.id)}
          />
        );
      } else {
        return (
          <ContentList
            key={section.id}
            course_slug={section.course_slug}
            section_slug={section.section_slug}
            status="none"
            listTitle={section.section_name}
            listTime={secondsToHms(section.section_duration)}
            callBackFunction={HandleLockVidPopup}
          />
        );
      }
    }
  );
  const courseDataItemDetails = courseDataItem.details.map((detail) => {
    return (
      <div key={detail.id} className="wyl__col">
        <p className="wyl__chk__text">
          <span className="wyl__chk__icon">
            <img
              src={
                require("../../assets/img/course-detail/checkmark.svg").default
              }
              alt="true"
            />
          </span>
          <span>{detail.detail}</span>
        </p>
      </div>
    );
  });

  let leftTime = totalTime - timeWatch;
  let completePerc = 0;
  if (timeWatch) {
    totalTime = parseInt(totalTime);
    if (parseInt(totalTime) <= parseInt(timeWatch)) {
      leftTime = 0;
      completePerc = 100;
    } else {
      completePerc = Math.round((timeWatch / totalTime) * 100);
    }
  }

  return (
    <React.Fragment>
      {/* page title */}
      <Helmet>
        <title>{courseDataItem.course_name} Course | SmarterChains</title>
        <meta name="description" content={courseDataItem.meta_desc} />
        <meta
          name="og:image"
          content={`${apiEndpoints.baseUrl}/assets/img/meta/${courseDataItem.meta_img}`}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* braedcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/learning-center/5">Resource Center</BreadcrumbsItem>
      <BreadcrumbsItem to="#">{courseDataItem.course_name}</BreadcrumbsItem>
      {/* this wrapper has
				course__overview__head
				course__overview__wrapper
			*/}
      <div className="master_wrapper course__overview">
        {/* this section has
					head__left__frame
					head__right__frame
				*/}
        <div className="course__overview__head">
          {/* this section has
						heading\subheading
					*/}
          <div className="head__left__frame">
            <h1 className="course__overView__h1">
             
              <span className={`${props.sid}-${courseCurrentItem.id}-${sid}`}>
                {courseDataItem.course_name}
                
              </span>
            </h1>
            <h2 className="course__overView__h1_small">
              {courseDataItem.id == 11
                ? "SMARTERCHAINS PRODUCT COURSE"
                : "SMARTERCHAINS PRODUCT COURSE"}
            </h2>
          </div>
          {/* this section has progress bar */}
          <div className="head__right__frame">
            <TrainProgressBar
              value={completePerc}
              doneTime={secondsToHms(timeWatch)}
              leftTime={secondsToHms(leftTime)}
            />
          </div>
        </div>
        {/* this section has
					course__inner__top__sec
					course__inner__tab__sec
				*/}
        <div className="course__inner__wrapper">
          {/* this section has
						course__inner__top__left
						course__inner__top__right
					*/}
          <div className="course__inner__top__sec">
            {/* this section has list */}
            <div
              className="course__inner__top__left"
              style={{
                maxHeight: `${screen.width > 768 ? vidHeight : "400"}px`,
              }}
            >
              <div className="course__inner__ul__wrapper">
                <div className="course__inner__ul__head">
                  <div className="course__inner__ul__head__h1">
                    <div className="head__h1__icon">
                      <img
                        src={
                          require("../../assets/img/course-overview/content_head.svg")
                            .default
                        }
                        alt="true"
                      />
                    </div>
                    <div className="head__h1__text">
                      <span>Contents</span>
                    </div>
                  </div>
                </div>

                <ul className="course__inner__ul">{courseDataItemSections}</ul>
                {/* vid-loader  */}
                {/* <div className="vid-loader"></div> */}
              </div>
            </div>
            {/* this section has
							inputs 
							video
						*/}
            <div className="course__inner__top__right" ref={vidDiv}>
              {/* <VideoPlayer videoSrc={videoSrc} /> */}
              <input
                id="sectionid"
                value={sid}
                readOnly
                style={{ display: "none" }}
              />
              <input
                id="playStatus"
                value={playStatus}
                readOnly
                style={{ display: "none" }}
              />
              <input
                id="isStateChange"
                value={isStateChange}
                readOnly
                style={{ display: "none" }}
              />
              <input
                id="currtimeWatch"
                value={currtimeWatch}
                readOnly
                style={{ display: "none" }}
              />
              <video id="player" controls crossorigin playsInline></video>
            </div>

            {/* {vidLoader && <div className="vid-loader"></div>} */}
          </div>

          
        </div>
        {courseCurrentItem.id==11?
        <button className="get-access-btn" onClick={()=>handleOpenContactUSPopup(`loss`)}>
            <span>Get access to IR4.0 Loss Analysis</span>
            <i dangerouslySetInnerHTML={{__html:arrowSvg}}></i>
        </button>
        :
        props.slug == 'industry-4-0-roadmap' &&
        <button className="get-access-btn" onClick={()=>handleOpenContactUSPopup(`strategy`)}>
          <span>Get access to IR4.0 Strategy</span>
          <i dangerouslySetInnerHTML={{__html:arrowSvg}}></i>  
        </button>
        }
      </div>

     
      <div
        className={`locked__video__parent ${isLockedVidPopup ? "visible active" : ""
          }`}
      >
        <div className="locked__video__popup">
          {/* this section has
						close button
						image
						text
						ok button
					*/}
          <div className="popup__content">
            <button className="close__popup" onClick={HandleLockVidPopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-blue.svg")
                    .default
                }
              />
            </button>
            <img
              src={
                require("../../assets/img/course-overview/vid_lock.svg").default
              }
              className="popup__top__img"
              alt="true"
            />
            <p>
              Please complete the previous course section to be able to access
              this video
            </p>
            <button className="popup__btn" onClick={HandleLockVidPopup}>
              OK
            </button>
          </div>
        </div>
      </div>
      {/* congratulation popup start */}
      <div
        className={`congratulation__popup__parent ${isCongratulationsPopup ? "visible active" : ""
          }`}
      >
        <div className="congratulation__popup">
          {/* this section has
						close button
						image
						div
					*/}
          <div className="congratulation__content">
            <button className="close__popup" onClick={HandlePlayPreviousPopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-blue.svg")
                    .default
                }
              />
            </button>
            <img
              className="congrats_img"
              src={
                require("../../assets/img/course-overview/congrats__top__img.gif")
                  .default
              }
              alt="true"
            />
            {/* this section has
							txt
							btn-wrapper
						*/}
            <div>
              <p className="congratulation__blue">Congratulations!</p>
              <p>You've successfully completed the Course:</p>
              <p className="b">{courseDataItem.course_name}</p>
              <div className="cong__btn__wrapper">
                <div className="cong__btn__col">
                  <button
                    className="cong__btn__blue__border"
                    onClick={HandlePlayPreviousPopup}
                  >
                    I'll do the Quiz later
                  </button>
                </div>
                <div className="cong__btn__col">
                  <Link
                    to={
                      courseDataItem.is_first_quiz == 1
                        ? `/quiz-walkthrough#${courseDataItem.course_slug}`
                        : `/training-quiz/${courseDataItem.course_slug}`
                    }
                    className="cong__btn__blue"
                  >
                    Take the Quiz
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log(props.slug)}
      {open && (props.slug == 'loss-analysis' || props.slug == 'industry-4-0-roadmap') ?<UpgradePopView openPopup={open} setOpenPopup={setOpen} slug={props.slug}/>:''}
    </React.Fragment>
    
  );
};
const arrowSvg=`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_677_37717" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_677_37717)">
  <path d="M16.175 13H4V11H16.175L10.575 5.4L12 4L20 12L12 20L10.575 18.6L16.175 13Z" fill="white"/>
</g>
</svg>`