import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import {
    MainWrapper,
    UpgradePopWrapOne,
    UpgradePopSTep,
    UpgradePopHead,
    UpgradePopBody,
    BodyTextWrap,
    UpgradePopFooter,
    ButtonWrap,    
} from './Upgradestyles';

import{
    asmtHeadIcon,crossIcon,bookMark,triangleIcon, automatedIconLoss, operationaLoss, insightLoss,
} from './upgradesvg';

export const UpgradePopView = () => {
    const [openPopup,setOpenPopup] = useState(true);
    const asmtData = [
        {
            id: 1,
            img: bookMark,
            title: `Plant Team Engagement `,
            text: `Engage all plant stakeholders in the loss analysis tool and get their plant activities input in a structured and scalable manner`,
        },
        {
            id: 2,
            img: automatedIconLoss,
            title: `Automated Data Analysis`,
            text: `Analyse all your operational activities and understand the activity costs that goes into your operations automatically`,
        },
        {
            id: 3,
            img: operationaLoss,
            title: `Operational Insights`,
            text: `Undestand your loss recovery opportunities that IR4.0 can provide as granular as operator activity/ production line level `,
        },
        {
            id: 4,
            img: insightLoss,
            title: `Financial Insights`,
            text: `Understand the actual cost of your Losses on an annual basis and drive clear initiatives prioritisation.`,
        },
    ]
    const handleUpgradePopupClick = (e)=>{
		e.stopPropagation();
		e.preventDefault();
		document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
	}
    return (
        <>
            <MainWrapper data-component="MainWrapper">
                {openPopup&&
                    <UpgradePopWrapOne data-component="UpgradePopWrapOne">
                            <UpgradePopSTep data-component="UpgradePopSTep">
                                <UpgradePopHead data-component="UpgradePopHead">
                                    <i className='asmt-img' dangerouslySetInnerHTML={{ __html: asmtHeadIcon }}></i>
                                    <span className='head-txt'>IR4.0 Loss Analysis</span>
                                    <button onClick={()=>setOpenPopup(false)}>
                                        <i className='close-img' dangerouslySetInnerHTML={{ __html: crossIcon }}></i>
                                    </button>
                                </UpgradePopHead>
                                <UpgradePopBody data-component="UpgradePopBody">
                                    <p className='main-text'>Most effective way to identify all the loss drivers of your manufacturing operations and engage the organisation at scale</p>
                                    {asmtData.map((item,index)=>(
                                        <BodyTextWrap data-component="BodyTextWrap" key={index}>
                                            <div className='upper-wrap'>
                                                <i className='side-icon' dangerouslySetInnerHTML={{ __html: item.img }}></i>
                                                <span>{item.title}</span>
                                            </div>
                                            <p>{item.text}</p>
                                            <i className='triangle-icon' dangerouslySetInnerHTML={{ __html: triangleIcon }}></i>
                                        </BodyTextWrap>
                                    ))}
                                    <ButtonWrap data-component="ButtonWrap">
                                        <button className='get-btn' onClick={(e) => handleUpgradePopupClick(e)}>Get IR4.0 Loss Analysis</button>
                                    </ButtonWrap>
                                </UpgradePopBody>
                                <UpgradePopFooter data-component="UpgradePopFooter">
                                    <Link to="/learning-loss-walkthrough/upgrade_loss_tour_link">View Product Tour</Link>
                                </UpgradePopFooter>
                            </UpgradePopSTep>
                    </UpgradePopWrapOne>
                }
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

