import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import serviceProvider from "../../services/axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { apiEndpoints } from "../../services/api-endpoints";
export const ProfileTabPanel = (props) => {
  const profileDataItems = props.profileData;
  if (!profileDataItems.length) {
    return null;
  }
  const profileDataItem = props.profileData[0];
  const defaultIndustry = profileDataItem.industries;
  
  const gid = localStorage.getItem("group_id");
  const uid = profileDataItem.id;
  const [isCirDdl, setIsCirDdl] = useState(false);
  const [perBtnState, setPerBtnState] = useState("btn__disabled");
  const [compBtnState, setCompBtnState] = useState("btn__disabled");
  const [addBtnState, setAddBtnState] = useState("btn__disabled");
  const [passBtnState, setPassBtnState] = useState("btn__disabled");
  const [pFormError, setPFormError] = useState(0);
  const [compPhoneFormError, setCompPhoneFormError] = useState(0);
  const [uFormError, setUFormError] = useState(0);
  const [passFormError, setPassFormError] = useState(0);
  const [passButtonText, setPassButtonText] = useState("Update");
  const [profileDdl, setProfileDdl] = useState(false);
  const [profileIndustryDdl, setProfileIndustryDdl] = useState(false);
  const [profileCountry, setProfileCountry] = useState(
    profileDataItem.country_id
  );
  const [profileIndustry, setProfileIndustry] = useState(
    profileDataItem.industory_id
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProfilePhotoPopup, setIsProfilePhotoPopup] = useState(false);
  const [isProfileImgUpdload, setIsProfileImgUpdload] = useState(
    profileDataItem.profile_pic
  );
  const [isProfileImgUpdloaded, setIsProfileImgUpdloaded] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);

  const user = JSON.parse(localStorage.getItem("userData"));
  const avatarColor = user ? user.avcolor : "";
  var profileInfoUpdateAction = true;
  const HandleOnSearch = (e) => {
    const val = e.target.value.toLowerCase();
    const filterData = props.defaultCountryList?.filter((item) => {
      return item.name.toLowerCase().startsWith(val);
    });
    if (val) {
      setCountryList(filterData);
    } else {
      setCountryList(props.defaultCountryList);
    }
  };

  const HandleOnIndustrySearch = (e) => {
    const val = e.target.value.toLowerCase();
    const filterData = defaultIndustry?.filter((item) => {
      return item.name.toLowerCase().startsWith(val);
    });
    if (val) {
      setIndustryList(filterData);
    } else {
      setIndustryList(defaultIndustry);
    }
  };

  // function to handle country dropdown list
  const handleProfileInfoChangesChange = (event) => {
    var element = document.getElementById("firstName");
    const value = document.getElementById("first_name").value;
    const regex = /^[a-zA-Z0-9(\s)]+$/; //this will admit letters and space
    if (value && value.match(regex)) {
      element.classList.remove("show-error");
      profileInfoUpdateAction = true;
      setPerBtnState("");
      var element1 = document.getElementById("lastName");
      const value1 = document.getElementById("last_name").value;
      const regex = /^[a-zA-Z0-9(\s)]+$/; //this will admit letters and space
      if (value1 && value1.match(regex)) {
        element1.classList.remove("show-error");
        profileInfoUpdateAction = true;
        setPerBtnState("");
      } else {
        element1.classList.add("show-error");
        setPerBtnState("btn__disabled");
        profileInfoUpdateAction = false;
      }
    } else {
      element.classList.add("show-error");
      setPerBtnState("btn__disabled");
      profileInfoUpdateAction = false;
      element.focus();
    }
  };

  const handleCompProfileInfoChangesChange = (event) => {
    var element = document.getElementById("firstName");
    const value = document.getElementById("first_name").value;
    const regex = /^[a-zA-Z0-9(\s)]+$/; //this will admit letters and space
    if (value && value.match(regex)) {
      element.classList.remove("show-error");
      profileInfoUpdateAction = true;
      setCompBtnState("");
      var element1 = document.getElementById("lastName");
      const value1 = document.getElementById("last_name").value;
      const regex = /^[a-zA-Z0-9(\s)]+$/; //this will admit letters and space
      if (value1 && value1.match(regex)) {
        element1.classList.remove("show-error");
        profileInfoUpdateAction = true;
        setCompBtnState("");
      } else {
        element1.classList.add("show-error");
        setCompBtnState("btn__disabled");
        profileInfoUpdateAction = false;
      }
    } else {
      element.classList.add("show-error");
      setCompBtnState("btn__disabled");
      profileInfoUpdateAction = false;
      element.focus();
    }
  };

  const handleCompanyPhoneChange = (phone) => {
    var element = document.getElementById("companyPhoneNo");
    console.log(phone);
    if (phone) {
      element.classList.remove("show-error");
      if (compPhoneFormError) {
        setCompPhoneFormError(compPhoneFormError - 1);
      }
    } else {
      element.classList.add("show-error");
      setCompPhoneFormError(compPhoneFormError + 1);
    }
    setCompBtnState("");
  };

  const handleAddressChange = (countryName = "") => {
    var element = document.getElementById("address");
    const value = document.getElementById("address_name").value;
    const regex = /^[0-9a-zA-Z(\-/_,.\s)]+$/; //this will admit letters, numbers and space, dash , underscore
    if (value && value.match(regex)) {
      element.classList.remove("show-error");
      var element1 = document.getElementById("city");
      const value1 = document.getElementById("city_name").value;
      const regex = /^[a-zA-Z(\s)]+$/; //this will admit letters, space
      if (value1 && value1.match(regex)) {
        element1.classList.remove("show-error");
        var element2 = document.getElementById("state");
        const value2 = document.getElementById("state_name").value;
        const regex = /^[a-zA-Z(\s)]+$/; //this will admit letters, and space
        if (value2 && value2.match(regex)) {
          element2.classList.remove("show-error");
          var element3 = document.getElementById("zipCode");
          const value3 = document.getElementById("zip_code").value;
          const regex = /^[0-9]+$/; //this will admit numbers
          if (value3 && value3.match(regex)) {
            element3.classList.remove("show-error");
            if (countryName != "" && countryName != null) {
              console.log("GGT");
              setAddBtnState("");
            } else {
              var countryName = document.getElementById("countryval").value;
              if (countryName != "" && countryName != null) {
                setAddBtnState("");
              } else {
                setAddBtnState("btn__disabled");
              }
            }
          } else {
            element3.classList.add("show-error");
            setAddBtnState("btn__disabled");
          }
        } else {
          element2.classList.add("show-error");
          setAddBtnState("btn__disabled");
        }
      } else {
        element1.classList.add("show-error");
        setAddBtnState("btn__disabled");
      }
    } else {
      element.classList.add("show-error");
      setAddBtnState("btn__disabled");
    }
  };

  const handlePerChange = () => {
    setPerBtnState("");
  };
  const handleAddChange = () => {
    setAddBtnState("");
  };
  const handlePhoneChange = (phone) => {
    var element = document.getElementById("phoneNo");
    console.log(phone);
    if (phone) {
      element.classList.remove("show-error");
      if (pFormError) {
        setPFormError(pFormError - 1);
      }
    } else {
      element.classList.add("show-error");
      setPFormError(pFormError + 1);
    }
    setPerBtnState("");
  };
  

  const handleOldPassChange = (event) => {
    var element = document.getElementById("oldPassSec");
    element.classList.remove("show-error");
    setPassBtnState("");
  };
  const handleNewPassChange = (event) => {
    var element = document.getElementById("newPassSec");
    var newReg =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (event.target.value.match(newReg)) {
      element.classList.remove("show-error");
      document.getElementById("addpassbtn").disabled = false;
      if (passFormError) {
        setPassFormError(passFormError - 1);
      }
    } else {
      document.getElementById("newPassErr").innerHTML =
        "Must contain at least 8 characters, one uppercase, one number and one special character.";
      element.classList.add("show-error");
      document.getElementById("addpassbtn").disabled = true;
      setPassFormError(passFormError + 1);
    }
    setPassBtnState("");
  };
  const HandleCountryDdl = () => {
    setProfileIndustryDdl(false);
    setProfileDdl(!profileDdl);
  };
  const HandleIndustryDdl = () => {
    setProfileDdl(false);
    setProfileIndustryDdl(!profileIndustryDdl);
  };
  // handle value of profilecountry
  const HandleCountryDdlValueOnClick = (val) => {
    document.getElementById("countryval").value = val;
    setProfileCountry(val);
    //setAddBtnState("");
  };
  const HandleIndustryDdlValueOnClick = (val) => {
    document.getElementById("industryval").value = val;
    setProfileIndustry(val);
    setCompBtnState('');
  };
  const HandleCirDdl = (action) => {
    if (action == "close") {
      setIsCirDdl(false);
    } else {
      let tmpVal = !isCirDdl;
      setIsCirDdl(tmpVal);
    }
  };
  const HandleProfilePopup = () => {
    setIsProfileImgUpdloaded(false);
    setSelectedImage(null);
    setIsProfilePhotoPopup(!isProfilePhotoPopup);
  };
  const HandleProfileImg = (e) => {
    const val = e.target.value;
    setIsProfileImgUpdloaded(val);
    setSelectedFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setSelectedImage(objectUrl);
  };
  const updateProfilePic = () => {
    const formData = new FormData();
    formData.append("uid", profileDataItem.id);
    formData.append("profilePic", selectedFile, selectedFile.name);

    serviceProvider
      .post("/profile?type=updateProfilePic", formData, true)
      .then((response) => {
        console.log(response.data);
        let tmpUserData = { ...user, profile_pic: response.data };
        localStorage.setItem("userData", JSON.stringify(tmpUserData));

        setIsProfileImgUpdload(true);
        setIsProfilePhotoPopup(!isProfilePhotoPopup);
        setIsProfileImgUpdload(response.data);
        props.onSubmitForm(response.data);
        // localStorage.setItem("userData", JSON.stringify(response.data.data.profile_pic));
      });
  };
  const removeProfilePic = () => {
    const formData = new FormData();
    formData.append("uid", profileDataItem.id);
    serviceProvider
      .post("/profile?type=removeProfilePic", formData, true)
      .then((response) => {
        console.log(response.data);
        HandleCirDdl("close");
        setIsProfileImgUpdloaded(false);
        setSelectedFile(null);
        setSelectedImage(null);
        props.onSubmitForm(response.data);
        let tmpUserData = { ...user, profile_pic: "" };
        localStorage.setItem("userData", JSON.stringify(tmpUserData));
        setIsProfileImgUpdload(null);
        window.location.reload(false);
      });
  };
  const updatePersonalData = () => {
    let formData = new FormData(document.getElementById("personalDataForm"));
    console.log(pFormError);
    if (pFormError == 0) {
      serviceProvider
        .post("/profile?type=updatePersonalData", formData, true)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status == 200) {
            localStorage.setItem(
              "userData",
              JSON.stringify(response.data.data)
            );
          }
          props.onSubmitForm(response.data.rand);
          setPerBtnState("btn__disabled");
          window.location.reload(false);
        });
    }
  };
  const updateCompanyData = () => {
    let formData = new FormData(document.getElementById("companyDataForm"));

    if (compPhoneFormError == 0) {
      serviceProvider
        .post("/profile?type=companyDataForm", formData, true)
        .then((response) => {
          if (response.data.status == 200) {

          }
          props.onSubmitForm(response.data.rand);
          setCompBtnState("btn__disabled");
          window.location.reload(false);
        });
    }
  };
  const updateAddress = () => {
    let formData = new FormData(document.getElementById("addressForm"));
    console.log(uFormError);
    if (uFormError == 0) {
      serviceProvider
        .post("/profile?type=updateAddress", formData, true)
        .then((response) => {
          // console.log(response.data);
          props.onSubmitForm(response.data);
          setAddBtnState("btn__disabled");
        });
    }
  };
  const updatePassword = () => {
    setPassButtonText("Updating...");
    let formData = new FormData(document.getElementById("passwordForm"));
    serviceProvider
      .post("/profile?type=updatePassword", formData, true)
      .then((response) => {
        if (response.data == 0) {
          var element = document.getElementById("oldPassSec");
          element.classList.add("show-error");
          setPassButtonText("Update");
          setAddBtnState("btn__disabled");

          var element1 = document.getElementById("newPassSec");
          element1.classList.remove("show-error");
        } else if (response.data == 2) {
          // not empty
          var element = document.getElementById("newPassSec");
          document.getElementById("newPassErr").innerHTML =
            "Must contain at least 8 characters,one uppercase, one number and one special character.";
          element.classList.add("show-error");
          setPassButtonText("Update");
          setAddBtnState("btn__disabled");
        } else if (response.data == 3) {
          // not equal to same
          var element = document.getElementById("newPassSec");
          document.getElementById("newPassErr").innerHTML =
            "The new password can't be the same as the old password.";
          element.classList.add("show-error");
          setPassButtonText("Update");
          setAddBtnState("btn__disabled");
        } else {
          document.getElementById("passwordForm").reset();
          setPassButtonText("Updated Successfully");
          setTimeout(() => {
            setPassButtonText("Update");
          }, 3000);
        }
      });
  };

  document.querySelector("body").onclick = (e) => {
    if (!e.target.closest(".profile__img__div")) {
      HandleCirDdl("close");
    }
  };
  useEffect(() => {
    let headImgSec = document.querySelector(
      ".header-frame-wrapper .header-profile-cir"
    );

    let tmpUser = JSON.parse(localStorage.getItem("userData"));
    console.log(tmpUser);
    let imgUrl = `${apiEndpoints.baseUrl}/assets/user-profile/${tmpUser.profile_pic}`;
    let usershort_name = `${tmpUser.name && tmpUser.name[0]}${tmpUser.surname && tmpUser.surname[0]
      }`;

    if (tmpUser.profile_pic) {
      headImgSec.className = "header-profile-cir";
      headImgSec.classList.add("cir-transparent");
      headImgSec.innerHTML = `<img class="h__profile__img" src=${imgUrl}>`;
    }
    // else{
    // 	headImgSec.classList.remove(`cir-transparent`);
    // 	headImgSec.classList.add(`${tmpUser.avcolor}`);
    // 	headImgSec.innerHTML = usershort_name;
    // }
  }, [isProfileImgUpdload]);

  useEffect(() => {
    setCountryList(props.defaultCountryList);
    setIndustryList(defaultIndustry);
  }, [props.defaultCountryList]);
  return (
    <>
      <div className="profile__tab__1">
        <div className="left__sec">
          <div className="profile__imgcir__container">
            <div className={`profile__img__div ${isCirDdl && "active"}`}>
              <div
                className={`cir__div ${
                  isProfileImgUpdload || selectedImage
                    ? "cir-transparent"
                    : avatarColor
                }`}
              >
                {!isProfileImgUpdload ? (
                  <span>{profileDataItem.short_name}</span>
                ) : selectedImage ? (
                  <img
                    className="profile__img 1"
                    src={selectedImage}
                    alt={isProfileImgUpdload}
                  />
                ) : (
                  <img
                    className="profile__img 2 "
                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${isProfileImgUpdload}`}
                    alt={isProfileImgUpdload}
                  />
                )}
                <button className="cam__icon__btn" onClick={HandleCirDdl}>
                  <img
                    src={
                      require("../../assets/img/profile/cam-icon.svg").default
                    }
                    alt="cam__btn"
                  />
                </button>
              </div>
              <div className="cir__ddl">
                <ul>
                  {!isProfileImgUpdload ? (
                    <li>
                      <button
                        className="btn__link"
                        onClick={() => {
                          HandleCirDdl("close");
                          HandleProfilePopup();
                        }}
                      >
                        Add profile photo
                      </button>
                    </li>
                  ) : (
                    <>
                      <li>
                        <button
                          className="btn__link"
                          onClick={() => {
                            HandleCirDdl("close");
                            HandleProfilePopup();
                          }}
                        >
                          Change profile photo
                        </button>
                      </li>
                      <li>
                        <button
                          className="btn__link"
                          onClick={removeProfilePic}
                        >
                          Remove
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {/* profile__imgcir__container end */}
          <ul className="profile__detail__ul">
            <li>
              <label className="label">First Name</label>
              <p className="label__text">{profileDataItem.name}</p>
            </li>
            <li>
              <label className="label">Last Name</label>
              <p className="label__text">{profileDataItem.last_name}</p>
            </li>
            <li>
              <label className="label">Email</label>
              <p className="label__text">{profileDataItem.email}</p>
            </li>
          </ul>
        </div>
        {/* left__sec end */}
        <div className="right__sec">
          <div className="form-card">
            <form id="personalDataForm">
              <h2 className="form-title">Personal Information</h2>
              <input
                type="hidden"
                name="uid"
                defaultValue={profileDataItem.id}
              />
              <div id="firstName" className="profile__form__controller">
                <label className="label">
                  <span className="label__text">First Name</span>
                  <input
                    type="text"
                    defaultValue={profileDataItem.name}
                    className="form__field"
                    name="firstName"
                    id="first_name"
                    // readOnly
                    placeholder="First Name"
                    maxlength="255"
                    onChange={handleProfileInfoChangesChange}
                  />
                  <p id="" className="error">
                    Please enter a valid First Name
                  </p>
                </label>
              </div>
              <div id="lastName" className="profile__form__controller">
                <label className="label">
                  <span className="label__text">Last Name</span>
                  <input
                    type="text"
                    defaultValue={profileDataItem.last_name}
                    name="lastName"
                    id="last_name"
                    // readOnly
                    className="form__field"
                    placeholder="Last Name"
                    maxlength="200"
                    onChange={handleProfileInfoChangesChange}
                  />
                  <p className="error">Please enter a valid Last Name</p>
                </label>
              </div>
              <div
                id="email"
                className="profile__form__controller profile__form__disabled"
              >
                <label className="label">
                  <span className="label__text">Email</span>
                  <input
                    type="text"
                    defaultValue={profileDataItem.email}
                    name="email"
                    className="form__field"
                    readOnly
                    placeholder="Email"
                  />
                </label>
                <p className="error">Please enter a valid Email</p>
              </div>
              {/*  show-error */}

              <div id="companyName" className="profile__form__controller">
                <label className="label">
                  <span className="label__text">Company</span>
                  <input
                    type="text"
                    defaultValue={profileDataItem.company_name}
                    name="companyName"
                    id="company_name"
                    className="form__field"
                    placeholder="Company Name"
                    maxlength="255"
                    onChange={handleProfileInfoChangesChange}
                  />
                  <p className="error">Enter valid Company Name</p>
                </label>
              </div>
              <div id="jobTitle" className="profile__form__controller">
                <label className="label">
                  <span className="label__text">Job Title</span>
                  <input
                    type="text"
                    defaultValue={profileDataItem.job_title}
                    name="jobTitle"
                    id="job_title"
                    className="form__field"
                    placeholder="Job Title"
                    maxlength="200"
                    onChange={handleProfileInfoChangesChange}
                  />
                  <p className="error">Enter valid Job Title</p>
                </label>
              </div>
              <div id="phoneNo" className="profile__form__controller ">
                <PhoneInput
                  specialLabel="Phone number"
                  inputClass="profile__phone__input"
                  country={"sa"}
                  value={profileDataItem.phone_number}
                  inputProps={{ name: "phoneNo" }}
                  onChange={(phone) => handlePhoneChange(phone)}
                />
                <p className="error">Enter valid Phone Number</p>
              </div>
            </form>
            <button
              onClick={updatePersonalData}
              className={`btn__updated ${perBtnState}`}
            >
              Update
            </button>
          </div>
          {/* form-card end */}
          {gid == 2 && (
            <div className="form-card">
              <form id="companyDataForm">
                <h2 className="form-title">Company Information</h2>
                <input
                  type="hidden"
                  name="uid"
                  defaultValue={profileDataItem.id}
                />
                <div
                  id="manufacturerName"
                  className="profile__form__controller"
                >
                  <label className="label">
                    <span className="label__text">Manufacturer Name</span>
                    <input
                      type="text"
                      defaultValue={profileDataItem.manufacturer_name}
                      name="manufacturerName"
                      id="manufacturer_name"
                      className="form__field"
                      placeholder="Enter name"
                      maxlength="255"
                      onChange={handleCompProfileInfoChangesChange}
                    />
                    <p className="error">Enter valid Manufacturer Name</p>
                  </label>
                </div>
                <div className="form__row">
                  <div className="form__col">
                    <div
                      id="companyRegistration"
                      className="profile__form__controller"
                    >
                      <label className="label">
                        <span className="label__text">
                          Company Registration
                        </span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.company_registration}
                          name="companyRegistration"
                          id="company_registration"
                          className="form__field"
                          placeholder="Company Registration"
                          maxlength="255"
                          onChange={handleCompProfileInfoChangesChange}
                        />
                        <p className="error">
                          Enter valid Company Registration
                        </p>
                      </label>
                    </div>
                  </div>
                  {/* Industry end */}
                  <div className="form__col">
                    <div className="profile__form__controller">
                      <div
                        className={
                          profileIndustryDdl ? "label active" : "label"
                        }
                      >
                        <span className="label__text">Industry</span>
                        <input
                          type="hidden"
                          id="industryval"
                          name="industry"
                          defaultValue={profileIndustry}
                        />
                        <button
                          className={
                            profileIndustry ? "button selected" : "button"
                          }
                          onClick={HandleIndustryDdl}
                          type="button"
                        >
                          {profileIndustry
                            ? defaultIndustry.find(
                                (i) => i.id == profileIndustry
                              )?.name
                            : "Pick a Industry"}
                          <span className="btn__ddl__arrow">
                            <img
                              src={
                                require("../../assets/img/common-img/ddl-blue.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </span>
                        </button>
                        <div className="ddl__list__wrapper">
                          <div className="ddl__list__card">
                            <header>
                              <label>
                                <img
                                  src={
                                    require("../../assets/img/common-img/search-icon.svg")
                                      .default
                                  }
                                  alt="true"
                                />
                                <input
                                  onChange={HandleOnIndustrySearch}
                                  type="text"
                                  placeholder="Search"
                                  autoComplete="off"
                                />
                              </label>
                            </header>
                            <ul>
                              {industryList?.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      HandleIndustryDdl();
                                      HandleIndustryDdlValueOnClick(item.id);
                                    }}
                                  >
                                    {item.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <p className="error">Enter valid Country code</p>
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  {/* Industry end */}
                  <div className="form__col">
                    <div id="products" className="profile__form__controller">
                      <label className="label">
                        <span className="label__text">Products</span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.products}
                          name="products"
                          id="company_registration"
                          className="form__field"
                          placeholder="Company Registration"
                          maxlength="255"
                          onChange={handleCompProfileInfoChangesChange}
                        />
                        <p className="error">Enter valid products</p>
                      </label>
                    </div>
                  </div>

                  <div className="form__col">
                    <div
                      id="companyAddress"
                      className="profile__form__controller"
                    >
                      <label className="label">
                        <span className="label__text">Address</span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.company_address}
                          name="companyAddress"
                          id="company_address"
                          className="form__field"
                          placeholder="Address"
                          maxlength="255"
                          onChange={handleCompProfileInfoChangesChange}
                        />
                        <p className="error">Enter valid Company Address</p>
                      </label>
                    </div>
                  </div>
                  {/* Industry end */}
                  {/* products end */}
                </div>

                <div className="form__row">
                  <div className="form__col">
                    <div id="postalCode" className="profile__form__controller">
                      <label className="label">
                        <span className="label__text">Postal Code</span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.pin_code}
                          name="postalCode"
                          id="postal_code"
                          className="form__field"
                          placeholder="Enter postal code"
                          maxlength="8"
                          onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''); handleCompProfileInfoChangesChange(e)}}
                        />
                        <p className="error">Enter valid postal code</p>
                      </label>
                    </div>
                  </div>
                  {/* products end */}
                  <div className="form__col">
                    <div id="companyAge" className="profile__form__controller">
                      <label className="label">
                        <span className="label__text">
                          Company Age profile (in years)
                        </span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.company_age}
                          name="companyAge"
                          id="company_age"
                          className="form__field"
                          placeholder="Enter year"
                          maxlength="3"
                          onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''); handleCompProfileInfoChangesChange(e)}}
                        />
                        <p className="error">Enter valid Company Age</p>
                      </label>
                    </div>
                  </div>
                  {/* Industry end */}
                </div>

                <div className="form__row">
                  <div className="form__col">
                    <div id="revenueSize" className="profile__form__controller">
                      <label className="label">
                        <span className="label__text">Revenue Size(in million)</span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.revenue_size}
                          name="revenueSize"
                          id="revenue_size"
                          className="form__field"
                          placeholder="Revenue Size"
                          maxlength="255"
                          onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''); handleCompProfileInfoChangesChange(e)}}
                        />
                        <p className="error">Enter valid revenue size</p>
                      </label>
                    </div>
                  </div>
                  {/* products end */}
                  <div className="form__col">
                    <div
                      id="revenueOnExports"
                      className="profile__form__controller"
                    >
                      <label className="label">
                        <span className="label__text">% revenue on exports</span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.revenue_on_exports}
                          name="revenueOnExports"
                          id="revenue_on_exports"
                          className="form__field"
                          placeholder="Revenue On Exports"
                          maxlength="255"
                          onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''); handleCompProfileInfoChangesChange(e)}}
                        />
                        <p className="error">
                          Enter valid Company Revenue On Exports
                        </p>
                      </label>
                    </div>
                  </div>
                  {/* Industry end */}
                </div>

                <div className="form__row">
                  <div className="form__col">
                    <div
                      id="numberOfPlants"
                      className="profile__form__controller"
                    >
                      <label className="label">
                        <span className="label__text">Number Of Plants</span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.number_of_plants}
                          name="numberOfPlants"
                          id="number_of_plants"
                          className="form__field"
                          placeholder="Number Of Plants"
                          maxlength="255"
                          onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''); handleCompProfileInfoChangesChange(e)}}
                        />
                        <p className="error">Enter valid revenue size</p>
                      </label>
                    </div>
                  </div>
                  <div className="form__col">
                    <div
                      id="numberOfEmployees"
                      className="profile__form__controller"
                    >
                      <label className="label">
                        <span className="label__text">Number Of Employees</span>
                        <input
                          type="text"
                          defaultValue={profileDataItem.number_of_employees}
                          name="numberOfEmployees"
                          id="number_of_employees"
                          className="form__field"
                          placeholder="Number Of Employees"
                          maxlength="255"
                          onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''); handleCompProfileInfoChangesChange(e)}}
                        />
                        <p className="error">Enter valid revenue size</p>
                      </label>
                    </div>
                  </div>
                  {/* products end */}
                </div>
                {/* products end */}
                
                <div className="profile__form__controller" id="companyPhoneNo">
                  <PhoneInput
                    specialLabel="Phone number"
                    inputClass="profile__phone__input"
                    country={"sa"}
                    value={profileDataItem.company_phone_number}
                    inputProps={{ name: "companyPhoneNo" }}
                    onChange={(phone) => handleCompanyPhoneChange(phone)}
                  />
                  <p className="error">Enter valid Phone Number</p>
                </div>
              </form>
              
              <button
                onClick={updateCompanyData}
                className={`btn__updated ${compBtnState}`}
              >
                Update
              </button>
            </div>
          )}
          {/* form-card end */}
          <div className="form-card">
            <h2 className="form-title">Billing Address</h2>
            <form id="addressForm">
              <input
                type="hidden"
                name="uid"
                defaultValue={profileDataItem.id}
              />
              <div id="address" className="profile__form__controller">
                <label className="label">
                  <span className="label__text">Address Line </span>
                  <input
                    type="text"
                    name="address"
                    id="address_name"
                    defaultValue={profileDataItem.address}
                    className="form__field"
                    placeholder="e.g. 12 Main St"
                    maxlength="100"
                    onChange={(e) => {
                      handleAddressChange();
                    }}
                  />
                  <p className="error">Please enter a valid Address</p>
                </label>
              </div>
              <div className="form__row">
                <div className="form__col">
                  <div id="city" className="profile__form__controller">
                    <label className="label">
                      <span className="label__text">City</span>
                      <input
                        type="text"
                        name="city"
                        id="city_name"
                        defaultValue={profileDataItem.city}
                        className="form__field"
                        placeholder="e.g. Geneva"
                        maxlength="50"
                        onChange={(e) => {
                          handleAddressChange();
                        }}
                      />
                      <p className="error">Enter valid City</p>
                    </label>
                  </div>
                </div>
                <div className="form__col">
                  <div id="state" className="profile__form__controller">
                    <label className="label">
                      <span className="label__text">
                        State, province or region
                      </span>
                      <input
                        type="text"
                        name="state"
                        id="state_name"
                        defaultValue={profileDataItem.state_id}
                        className="form__field"
                        placeholder="e.g. NY"
                        maxlength="50"
                        onChange={(e) => {
                          handleAddressChange();
                        }}
                      />
                      <p className="error">Enter valid State</p>
                    </label>
                  </div>
                </div>
              </div>
              <div className="form__row">
                <div className="form__col">
                  <div id="zipCode" className="profile__form__controller">
                    <label className="label">
                      <span className="label__text">ZIP code</span>
                      <input
                        type="text"
                        name="zipCode"
                        id="zip_code"
                        defaultValue={profileDataItem.zip_code}
                        className="form__field"
                        placeholder="e.g. 10024"
                        onChange={(e)=>{e.target.value = e.target.value.replace(/[^0-9.-]|(?<=\..*)\.|(?<=^-\d*)-+|_/g, ''); handleAddressChange(e);}}
                      />
                      <p className="error">Enter valid Zip code</p>
                    </label>
                  </div>
                </div>
                <div className="form__col">
                  <div className="profile__form__controller">
                    <div className={profileDdl ? "label active" : "label"}>
                      <span className="label__text">Country</span>
                      <input
                        type="hidden"
                        id="countryval"
                        name="country"
                        defaultValue={profileCountry}
                      />
                      <button
                        className={
                          profileCountry ? "button selected" : "button"
                        }
                        onClick={HandleCountryDdl}
                        type="button"
                      >
                        {profileCountry ? profileCountry : "Pick a country"}
                        <span className="btn__ddl__arrow">
                          <img
                            src={
                              require("../../assets/img/common-img/ddl-blue.svg")
                                .default
                            }
                            alt="true"
                          />
                        </span>
                      </button>
                      <div className="ddl__list__wrapper">
                        <div className="ddl__list__card">
                          <header>
                            <label>
                              <img
                                src={
                                  require("../../assets/img/common-img/search-icon.svg")
                                    .default
                                }
                                alt="true"
                              />
                              <input
                                onChange={HandleOnSearch}
                                type="text"
                                placeholder="Search"
                                autoComplete="off"
                              />
                            </label>
                          </header>
                          <ul>
                            {countryList?.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  onClick={() => {
                                    HandleCountryDdl();
                                    HandleCountryDdlValueOnClick(item.name);
                                    handleAddressChange(item.name);
                                  }}
                                >
                                  {item.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <p className="error">Enter valid Country code</p>
                  </div>
                </div>
              </div>
            </form>
            <button
              onClick={updateAddress}
              className={`btn__updated ${addBtnState}`}
            >
              Update
            </button>
          </div>
          {/* form-card end */}
          <div className="form-card">
            <h2 className="form-title">Change your password</h2>
            <form id="passwordForm">
              <input
                type="hidden"
                name="uid"
                defaultValue={profileDataItem.id}
              />
              <div className="profile__form__controller" id="oldPassSec">
                <PasswordField
                  id="password"
                  name="old_password"
                  label="Current Password"
                  placeholder="Enter current password"
                  onChangeHandler={handleOldPassChange}
                />
                <p id="oldPassErr" className="error">
                  Wrong Password
                </p>
              </div>
              <div className="profile__form__controller" id="newPassSec">
                <PasswordField
                  id="newPassword"
                  name="newpassword"
                  label="New Password "
                  placeholder="Enter new password"
                  onChangeHandler={handleNewPassChange}
                />
                <p id="newPassErr" className="error">
                  Must contain at least 8 characters,one uppercase, one number
                  and one special character.
                </p>
              </div>
            </form>
            <button
              onClick={updatePassword}
              id="addpassbtn"
              className={`btn__updated ${passBtnState}`}
            >
              {passButtonText}
            </button>
          </div>
          {/* form-card end */}
        </div>
        {/* right__sec end */}
      </div>

      {/* profile photo-popup*/}
      <Modal
        open={isProfilePhotoPopup}
        onClose={HandleProfilePopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="profile__pic__popup"
      >
        <div className="pic__popup__content">
          <div className="card">
            <header className="card-header">
              <span>Change profile photo</span>
              <button className="close_btn" onClick={HandleProfilePopup}>
                <img
                  src={
                    require("../../assets/img/common-img/close-icon-blue.svg")
                      .default
                  }
                  alt="close"
                />
              </button>
            </header>
            <div className="card-body">
              {!isProfileImgUpdloaded ? (
                <div className="without__upload">
                  <label className="uplaod__img__cir">
                    <div className="cloud__div">
                      <img
                        src={
                          require("../../assets/img/common-img/cloud_upload.svg")
                            .default
                        }
                        alt="upload"
                      />
                      <p>Drag and drop your images here</p>
                    </div>
                    <input
                      type="file"
                      vlaue={isProfileImgUpdload}
                      onChange={(e) => HandleProfileImg(e)}
                      className="input-file"
                      id="uploadProfileImg"
                    />
                  </label>
                  <p>or</p>
                  <label className="uploadpic_btn" htmlFor="uploadProfileImg">
                    Upload a photo
                  </label>
                </div>
              ) : (
                <div className="with__upload">
                  <div className="with__upload__row">
                    <div className="image__div">
                      {selectedImage && <img src={selectedImage} alt="true" />}
                    </div>
                    <p>{selectedFile ? selectedFile.name : ""}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer">
              <button
                className="upload"
                onClick={updateProfilePic}
                disabled={isProfileImgUpdloaded ? false : true}
              >
                Upload
              </button>
              <button onClick={HandleProfilePopup} className="cancel">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* profile photo-popup end*/}
    </>
  );
};

const PasswordField = (props) => {
  const [isPass, setIsPass] = useState(true);
  return (
    <label className="label password">
      <span className="label__text">{props.label} </span>
      <input
        type={isPass ? "password" : "text"}
        id={props.id}
        name={props.name}
        className="form__field"
        placeholder={props.placeholder}
        onChange={props.onChangeHandler}
      />
      <button
        className="btn__type__handle"
        type="button"
        onClick={() => {
          setIsPass(!isPass);
        }}
      >
        {isPass ? (
          <img
            src={require("../../assets/img/common-img/hidden-pass.svg").default}
            alt="hidden"
          />
        ) : (
          <img
            src={require("../../assets/img/common-img/show-pass.svg").default}
            alt="show"
          />
        )}
      </button>
    </label>
  );
};
