/** imported components

  * ./svg => imported for all svg elements  
  * ./styles => imported for using of all created styled components.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Modal for ===> This is popup component
  * AssessmentView, ChapterView, QuestionView for ===> It is view component


**/
import React, { useState } from "react";
import { Redirect, Link, useParams, useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import {
  CarouselProvider,
  Dot,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { AssessmentView } from './assessment-view.js';
import { ChapterView } from './chapter-view.js';
import { QuestionView } from './questions-view.js';


import "./assessment-walkthrough.scss";

export const LearningAssessmentWalkthrough = (props) => {
  const TotalSlides = 4;
  const [slideIndex, setSlideIndex] = useState(0);
  const user = JSON.parse(localStorage.getItem("userData"));
  const login = localStorage.getItem("isLoggedIn");
  const { section } = useParams();
  const navigate = useHistory();
  const goBack = (e) => {
    if (section === `upgrade_asmt_tour_link`) {
      // e.stopPropagation();
      // e.preventDefault();
      navigate.goBack();
    }
  }
  if (!login) {
    return <Redirect to="/sign-in" />;
  }

  const HandleSlideIndex = (ev) => {

    if (ev === "min" && slideIndex > 0) {
      let tmpMinVal = slideIndex - 1;
      setSlideIndex(tmpMinVal);
    } else if (slideIndex < TotalSlides) {
      let tmpAddVal = slideIndex + 1;
      setSlideIndex(tmpAddVal);
    }
    //alert(slideIndex);
  };
  const handleModal = () => {

  }
  return (
    <>
      {(slideIndex == 0 || slideIndex == 1) && <AssessmentView />}
      {slideIndex == 2 && <ChapterView />}
      {slideIndex == 3 && <QuestionView />}
      <Modal
        open={true}
        // onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="walk__through__modal1 assessment-wlk-modal"
      >
        <>
          <div className="walk-through-bg" style={{ display: 'none' }}>
            <img
              src={
                require(`../../assets/img/common-img/product-tour${slideIndex}.svg`)
                  .default
              }
            />
            <div>
              <p>{user.name}</p></div>
          </div>
          <div className="modal__slider__wrapper">
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={90}
              totalSlides={TotalSlides}
              dragEnabled={false}
              isPlaying={false}
              currentSlide={slideIndex}
            >
              <Slider>
                <Slide className="slide" index={0}>
                  <div className="slide__item">
                    <header className="slide__item__head">
                      <img
                        src={
                          require("../../assets/img/walk-through/assess-walk-1.gif")
                            .default
                        }
                        alt="true"
                      />
                    </header>
                    <div className="slide__item__body">
                      <h4 className="label">1 of 4</h4>
                      <h2 className="title">
                        Hi {user.name}, welcome to the Assessment tour!
                      </h2>
                      <p>
                        You are one step closer to start your transformation
                        journey.
                      </p>
                      <p>
                        We want to quickly show you around and it will only take
                        a minute. Reach the end for the insightful Assessment
                        results.
                      </p>
                    </div>
                  </div>
                </Slide>

                <Slide className="slide" index={1}>
                  <div className="slide__item">
                    <header className="slide__item__head">
                      <img
                        src={
                          require("../../assets/img/walk-through/assess-walk-2.gif")
                            .default
                        }
                        alt="true"
                      />
                    </header>
                    <div className="slide__item__body">
                      <h4 className="label">2 of 4</h4>
                      <h2 className="title">
                        Benchmark your Industry 4.0 capabilities
                      </h2>
                      <p>
                        SmarterChains Assessment identifies the capabilities of
                        the plant on more than 500 unique technological &
                        organizational elements across all departments.
                      </p>
                    </div>
                  </div>
                </Slide>
                <Slide className="slide" index={2}>
                  <div className="slide__item">
                    <header className="slide__item__head">
                      <img
                        src={
                          require("../../assets/img/walk-through/assess-walk-3.gif")
                            .default
                        }
                        alt="true"
                      />
                    </header>
                    <div className="slide__item__body">
                      <h4 className="label">3 of 4</h4>
                      <h2 className="title">How it works</h2>
                      <p>
                        Start now with zero preparation and discover where your
                        plant stands in a few hours - remotely. No more
                        exhausting plant walks.
                      </p>
                    </div>
                  </div>
                </Slide>
                <Slide className="slide" index={3}>
                  <div className="slide__item">
                    <header className="slide__item__head">
                      <img
                        src={
                          require("../../assets/img/walk-through/assess-walk-4.gif")
                            .default
                        }
                        alt="true"
                      />
                    </header>
                    <div className="slide__item__body">
                      <h4 className="label">4 of 4</h4>
                      <h2 className="title">
                        Complete the Assessment to get the results
                      </h2>
                      <p>
                        Collaborate with your colleagues and complete a series
                        of questions. Reach the end to identify your digital
                        readiness. Replicate the process for your enterprise
                        network and strategize at scale.
                      </p>
                    </div>
                  </div>
                </Slide>
              </Slider>
              <div className="modal__slider__footer">
                <div className="dots__section">
                  <button onClick={() => setSlideIndex(0)} disabled={slideIndex === 0 ? true : false} className="dot"></button>
                  <button onClick={() => setSlideIndex(1)} disabled={slideIndex === 1 ? true : false} className="dot"></button>
                  <button onClick={() => setSlideIndex(2)} disabled={slideIndex === 2 ? true : false} className="dot"></button>
                  <button onClick={() => setSlideIndex(3)} disabled={slideIndex === 3 ? true : false} className="dot"></button>
                </div>
                <div className="btn__section">
                  {slideIndex === 0 ? (
                    <>
                      {
                        section === `upgrade_asmt_tour_link` ?
                          <a className="wht_link" onClick={(e) => goBack(e)}>
                            Exit Tour
                          </a>
                          :
                          section ?
                            <Link to={`/assessment/${section}`}
                              className="wht_link"
                            >
                              Exit Tour
                            </Link>
                            :
                            <Link to="/learning-center"
                              className="wht_link"
                            >
                              Exit Tour
                            </Link>
                      }
                    </>

                  ) : (

                    <ButtonBack
                      className="btn__prv"
                      onClick={() => {
                        HandleSlideIndex("min");
                      }}
                    >
                      Previous
                    </ButtonBack>
                  )}
                  {slideIndex === 3 ? (
                    <>
                      {
                        section === `upgrade_asmt_tour_link` ?
                          <Link to="/learning-assessment-result-walkthrough/upgrade_asmt_tour_link"
                            className="last_t_link"
                          >
                            Exit Tour
                          </Link>
                          :
                          section ?
                            <Link to={`/assessment/${section}`}
                              className="last_t_link"
                            >
                              Exit Tour
                            </Link>
                            :
                            <Link to="/learning-center"
                              className="last_t_link"
                            >
                              Exit Tour
                            </Link>

                      }
                    </>

                  ) : (

                    <ButtonNext
                      className="btn__nxt"
                      onClick={() => {
                        HandleSlideIndex();
                      }}
                    >
                      {slideIndex === 0
                        ? "Let's go"
                        : slideIndex === 3
                          ? "Next: Explore Results"
                          : "Next"}
                    </ButtonNext>
                  )}

                </div>
              </div>
            </CarouselProvider>


          </div>
          <div className="footer-area">
            <div className="footer-left">
              <ul className="footer-page-link">
                <li className="page-line-active">
                   Assessment
                </li>

                <li>
                  {/* {section===`upgrade_asmt_tour_link`?
                  <Link to="/learning-assessment-result-walkthrough/upgrade_asmt_tour_link">Assessment Results</Link>
                  :
                  <Link to="/learning-assessment-result-walkthrough">Assessment Results</Link>
                } */}
                </li>
              </ul>
            </div>
            <div className="footer-right">
              <ul className="footer-page-link">
                <li>
                  {/* <Link to="/">Exit this demo tour</Link> */}
                  {section === `upgrade_asmt_tour_link` ?
                    <button onClick={(e) => goBack(e)}>Exit this demo tour </button>
                    :
                    section ?
                      <Link to={`/assessment/${section}`}
                        className="last_t_link"
                      >Exit this demo tour </Link>
                      :
                      <Link to="/learning-center">Exit this demo tour </Link>
                  }
                </li>
              </ul>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
