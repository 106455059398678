/** imported components

  * serviceProvider for ==> working with api.
  * scss for ==> styling css file for this page.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.
  * Helmet ==> for add specific title & CSS for the page.
  * PaymentMaturitySuccessView ==> for customm component.

**/
import React, { Component } from "react";
import { number } from "yup";
import { PaymentMaturitySuccessView } from "./paymentmaturity-success-view";
import "../cart.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
// PaymentSuccessControler start
export class PaymentMatSuccessControler extends Component {
  orderSummary = [
    {
      title: "Factory of the Future Leadership",
      course_cat: "Training Course",
    },
    {
      title: "Digital Organization",
      course_cat: "Training Course",
    },
    {
      title: "Digital Maturity Assessment - Limited",
      course_cat: "Training Course",
    },
  ];

  render() {
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    const paid_ammount = JSON.parse(localStorage.getItem("payData"));
    //console.log("payData",paid_ammount)
    return (
      <>
        {/* Page title */}
        <Helmet>
          <title>Order Summary | SmarterChains</title>
          <meta name="description" content="Order Summary" />
        </Helmet>
        {/* Breadcrumbs */}
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
        <BreadcrumbsItem to="/paymentmat_success">
          Order Summary
        </BreadcrumbsItem>
        {/* View component start */}
        <PaymentMaturitySuccessView
          tansection_no={paid_ammount.txn_id}
          paid_ammount={paid_ammount.total_payment}
          orderSummary={paid_ammount.Items}
          uid={usid}
          {...this.props}
        />
      </>
    );
  }
}
