import React, { useState, useEffect, memo, useRef } from 'react';
import { ddlArrow, delIcon, editIcon, addIcon, closeSvg } from './svg-step1';
import {
    ScenarioContainer,
    ScenarioButton,
    ScenarioDropdownBox,
    ScenarioList,
    ScenarioModal,
    ScenarionModalCard,
    ScenarioCardHeader,
    ScenarioCardClose,
    ScenarioCardContent,
    ScenarioInput,
    ScenarioLabel,
    HeaderTitle,
    SaveScenarioButton
} from './common-roadmap-style';

const ScenarioDropdown = (props) => {
    //variable declaration
    const { options, setOptions } = props;
    const [showDropdown, setShowDropdown] = useState(false);
    const [showScenatioPopup, setShowScenarionPopup] = useState(false);
    const [showDeletePopupId, setShowDeletePopupId] = useState(false);
    const [selectedScenario, setSelectedScenario] = useState({});
    const [scenarioName, setScenarioName] = useState('');
    const [isSameName, setIsSameName] = useState(false);
    let modalType = useRef(false);

    const handleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    function validateName(str) {
        /* regular expression that matches a string that does not start with 
        a space and does not contain one space after another space:*/
        const pattern = /^(?! )(?!.*  )[^\s].*$/;
        return pattern.test(str);
    }

    const handleName = (val) => {
        // to check the same name in the Options.
        let tmpIndex = options?.findIndex(item => item.label?.toLowerCase() === val?.toLowerCase());
        if (tmpIndex > -1) {
            setIsSameName(true);
        } else {
            setIsSameName(false);
        }
        if (validateName(val)) {
            // end code  to check the same name in the Options. 
            setScenarioName(val);
        } else if (!val) {
            setScenarioName(val);
        }
    }

    const changeScenario = (obj) => {
        setSelectedScenario(obj);
        setShowDropdown(false);
    }

    const openScenarioModal = () => {
        modalType.current = false;
        setShowScenarionPopup(true);
        setShowDropdown(false);
        setIsSameName(false);
    }

    const closeModal = () => {
        setShowScenarionPopup(false);
        setScenarioName('');
       
    }

    const saveScenario = () => {
        let tmpArr = [...options];
        tmpArr.push({
            id: tmpArr.length + 1,
            label: scenarioName,
            value: 4
        });
        setOptions(tmpArr);
        closeModal();
    }

    const updateScenario = () => {
        let tmpArr = [...options];
        let tmpIndex = options.findIndex(item => item.id == modalType.current.id);
        tmpArr[tmpIndex] = {
            ...tmpArr[tmpIndex],
            label: scenarioName
        }
        if (selectedScenario.id == modalType.current.id) {
            setSelectedScenario(tmpArr[tmpIndex]);
        }
        setOptions(tmpArr);
        closeModal();
    }

    // this function will be call when the rename button will be clcik.
    const renameFunction = (e, obj) => {
        e.stopPropagation();
        modalType.current = obj;
        setScenarioName(obj.label);
        setShowDropdown(false);
        setShowScenarionPopup(true);
    }

    const openDeletePopup = (e, id) => {
        e.stopPropagation();
        setShowDropdown(false);
        setShowDeletePopupId(id);
    }

    const deleteFunction = (e, id) => {
        e.stopPropagation();
        let tmpArr = options.filter(item => item.id != id);
        if (selectedScenario.id == id) {
            setSelectedScenario({});
        }
        setOptions(tmpArr);
        setShowDeletePopupId(false);
    }



    // useEffct 
    useEffect(() => {
        document.addEventListener('click', (e) => {
            let target = e.target;
            // close dropdown on click outside
            if (!target.closest('[data-component="ScenarioContainer"]')) {
                setShowDropdown(false);
            }
        })
    });

    return (
        <>
            <ScenarioContainer data-component="ScenarioContainer">
                <ScenarioButton data-component="ScenarioButton" active={showDropdown} onClick={handleDropdown}>
                    <span>{selectedScenario.label || 'Scenario'}</span>
                    <i dangerouslySetInnerHTML={{ __html: ddlArrow }}></i>
                </ScenarioButton>
                <ScenarioDropdownBox data-component="ScenarioDropdownBox" show={showDropdown}>
                    {options.map((option, index) => (
                        <ScenarioList key={index} onClick={() => changeScenario(option)} data-id={option.id}>
                            <span>{option.label}</span>
                            <i dangerouslySetInnerHTML={{ __html: editIcon }} data-title="Rename" onClick={(e) => renameFunction(e, option)}></i>
                            <i dangerouslySetInnerHTML={{ __html: delIcon }} data-title="Delete" onClick={(e) => openDeletePopup(e, option.id)}></i>
                        </ScenarioList>
                    )
                    )
                    }

                    <ScenarioList noTitle onClick={openScenarioModal}>
                        <i dangerouslySetInnerHTML={{ __html: addIcon }}></i>
                        <span>Add Scenario</span>
                    </ScenarioList>
                </ScenarioDropdownBox>
            </ScenarioContainer>

            {/* ScenarioModal Add and Rename start */}
            <ScenarioModal data-component="ScenarioModal" show={showScenatioPopup === 0 || showScenatioPopup > 0}>
                <ScenarionModalCard data-component="ScenarionModalCard">
                    <ScenarioCardHeader data-component="ScenarioCardHeader">
                        <HeaderTitle data-component="HeaderTitle">{!modalType.current ? 'Add Scenario' : 'Rename Scenario'}</HeaderTitle>
                        <ScenarioCardClose data-component="ScenarioCardClose" dangerouslySetInnerHTML={{ __html: closeSvg }} onClick={closeModal} ></ScenarioCardClose>
                    </ScenarioCardHeader>
                    {/* ScenarioCardHeader end */}
                    <ScenarioCardContent data-component="ScenarioCardContent">
                        <ScenarioLabel data-component="ScenarioLabel">
                            <span>Scenario Name</span>
                            <ScenarioInput data-component="ScenarioInput"
                                placeholder="Default Scenario"
                                value={scenarioName}
                                onChange={(e) => handleName(e.target.value)}
                            />
                        </ScenarioLabel>

                        {!modalType.current ?
                            <SaveScenarioButton data-component="SaveScenarioButton" disabled={!scenarioName || isSameName ? true : false} onClick={saveScenario} >
                                Save Scenario
                            </SaveScenarioButton>
                            :
                            <SaveScenarioButton data-component="SaveScenarioButton" disabled={!scenarioName || isSameName ? true : false} onClick={updateScenario} >
                                Rename Scenario
                            </SaveScenarioButton>}
                        <p className='error-show'>
                            {isSameName ? 'A Scenario with this name already exists. Please try again with a different name' : ''}
                        </p>
                    </ScenarioCardContent>
                </ScenarionModalCard>
            </ScenarioModal>

            {/* ScenarioModal Delete Start*/}
            <ScenarioModal data-component="ScenarioModal" show={showDeletePopupId}>
                <ScenarionModalCard data-component="ScenarionModalCard">
                    <ScenarioCardHeader data-component="ScenarioCardHeader">
                        <HeaderTitle data-component="HeaderTitle">Caution</HeaderTitle>
                    </ScenarioCardHeader>
                    {/* ScenarioCardHeader end */}
                    <ScenarioCardContent data-component="ScenarioCardContent">
                        <p>
                            You are about to delete Scenario 1. Deleting a scenario would lead to removal of all saved progress including removal of saved nodes, Recoverable Calibrator & Investment Calibrator. Do you still want to continue?
                        </p>

                        <div className='btn-wrapper-container'>
                            <SaveScenarioButton data-component="SaveScenarioButton" onClick={() => setShowDeletePopupId(false)} >
                                No
                            </SaveScenarioButton>

                            <SaveScenarioButton data-component="SaveScenarioButton" borderBtn={true} onClick={(e) => deleteFunction(e, showDeletePopupId)} >
                                Yes, delete
                            </SaveScenarioButton>
                        </div>
                    </ScenarioCardContent>
                </ScenarionModalCard>
            </ScenarioModal>
        </>
    );
}
export default memo(ScenarioDropdown);