export const InfoIcon =`
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<circle cx="8" cy="8" r="7.6" stroke="#A6A7AD" class="circle" stroke-width="0.8"/>
<line x1="8" y1="6.5" x2="8" y2="11.5" stroke="#7D868C" stroke-linecap="round" class="line-i"/>
<path d="M8 4V4.5" stroke="#7D868C" stroke-linecap="round" class="line-i"/>
</svg>
`

export const unsaveSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="30" viewBox="0 0 38 30" fill="none">
<path d="M29.8002 5.65428L24.3457 0.199752C24.2179 0.0719106 24.0448 0 23.8636 0H2.7273C1.22382 0 0 1.22312 0 2.7273V27.2728C0 28.7769 1.22382 30.0001 2.7273 30.0001H27.2728C28.7762 30.0001 30.0001 28.7769 30.0001 27.2728V6.13637C30 5.95528 29.9281 5.78212 29.8002 5.65428ZM6.81821 1.36362H21.8182V9.54544C21.8182 10.2971 21.207 10.9091 20.4546 10.9091H8.18182C7.42942 10.9091 6.81821 10.2971 6.81821 9.54544V1.36362ZM24.5455 28.6364H5.45453V16.3636H24.5455V28.6364ZM28.6364 27.2728C28.6364 28.0245 28.0251 28.6364 27.2728 28.6364H25.9092V15.6818C25.9092 15.3049 25.6042 15 25.2273 15H4.77275C4.39588 15 4.09091 15.3049 4.09091 15.6818V28.6364H2.7273C1.97489 28.6364 1.36368 28.0245 1.36368 27.2728V2.7273C1.36368 1.97559 1.97495 1.36368 2.7273 1.36368H5.45459V9.5455C5.45453 11.0496 6.67835 12.2727 8.18182 12.2727H20.4546C21.958 12.2727 23.1819 11.0496 23.1819 9.54544V1.36362H23.5814L28.6364 6.4187V27.2728Z" fill="#178ACB"/>
<path d="M17.0456 9.54526H19.7729C20.1498 9.54526 20.4547 9.24029 20.4547 8.86342V3.40889C20.4547 3.03202 20.1498 2.72705 19.7729 2.72705H17.0456C16.6687 2.72705 16.3638 3.03202 16.3638 3.40889V8.86342C16.3638 9.24029 16.6687 9.54526 17.0456 9.54526ZM17.7274 4.09073H19.091V8.18164H17.7274V4.09073Z" fill="#178ACB"/>
<circle cx="31" cy="8" r="7" fill="#178ACB"/>
<rect x="32" y="9" width="2" height="6" rx="1" transform="rotate(-180 32 9)" fill="white"/>
<rect x="32" y="12.3335" width="2" height="2" rx="1" transform="rotate(-180 32 12.3335)" fill="white"/>
</svg>`

export const cancelSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
<path d="M6.36396 7.77828C5.97344 7.38776 5.97344 6.75459 6.36396 6.36407C6.75448 5.97354 7.38765 5.97354 7.77817 6.36407L19.0919 17.6778C19.4824 18.0683 19.4824 18.7015 19.0919 19.092C18.7014 19.4825 18.0682 19.4825 17.6777 19.092L6.36396 7.77828Z" fill="#118ACB"/>
<path d="M6.36396 19.092C5.97344 18.7015 5.97344 18.0683 6.36396 17.6778L17.6777 6.36407C18.0682 5.97354 18.7014 5.97354 19.0919 6.36407C19.4824 6.75459 19.4824 7.38776 19.0919 7.77828L7.77817 19.092C7.38765 19.4825 6.75448 19.4825 6.36396 19.092Z" fill="#118ACB"/>
</svg>`

export const tickSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_117_21199" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_117_21199)">
  <path d="M9.54961 18L3.84961 12.3L5.27461 10.875L9.54961 15.15L18.7246 5.97498L20.1496 7.39998L9.54961 18Z" fill="#43A047"/>
</g>
</svg>`