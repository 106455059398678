/** imported components

  * makeStyles for ===> styling material-ui component.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * PlansPageView for ==> include custom component.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.

**/

import React, { useState, useEffect } from "react";
import { PlansPageView } from "./plans-page-view";
import "./plans-page.scss";
import serviceProvider from "../../services/axios";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
// PlansPageController start
export const PlansPageController = (props) => {
  // Define variables
  const tid1 = "Full-Maturity-Assessment";
  const tid2 = "Loss-Analysis";
  const tid3 = "Maturity-and-Loss-Analysis-with-Business-Case";
  const [completed, setCompleted] = useState(0);
  const [allCompleted, setAllCompleted] = useState(0);
  const user = JSON.parse(localStorage.getItem("userData"));

  const [results, setResults] = useState([]);
  const [results1, setResults1] = useState([]);
  const [results2, setResults2] = useState([]);
  const usid = user ? user.uid : 0;

  useEffect(() => {
    props.handleExpandFal();
    const search = async () => {
      const apiUrl = `/item?param=myplans&slug=${tid1}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults(data);
      console.log("data1", data);
    };
    search();
  }, [tid1, completed, allCompleted]);
  useEffect(() => {
    props.handleExpandFal();
    const search = async () => {
      const apiUrl = `/item?param=myplans&slug=${tid2}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults1(data);
      console.log("data2", data);
    };
    search();
  }, [tid2, completed, allCompleted]);

  useEffect(() => {
    props.handleExpandFal();
    const search = async () => {
      const apiUrl = `/item?param=myplans&slug=${tid3}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults2(data);
      console.log("data3", data);
    };
    search();
  }, [tid3, completed, allCompleted]);

  return (
    <>
      {/* Page title */}
      <Helmet>
        <title>Plans | SmarterChains</title>
        <meta name="description" content="Home" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/plans">Plans</BreadcrumbsItem>
      {/* View component start */}
      <PlansPageView
        {...props}
        pldata={results}
        pllossdata={results1}
        buybothdata={results2}
      />
    </>
  );
};
