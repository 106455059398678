/** imported components

  * Grid for ===> component from material-ui to creat grid.
  * Helmet ===> for add specific title & CSS for the page.
  * scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api.
  * Link for ==> for render anchor.
  * Modal for ==> for popup.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.

**/
import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { Link } from "react-router-dom";
import { Modal } from "@material-ui/core";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {CourseOverviewSingleControler} from '../training-courses/course-overview-single-ctrl';
import {CourseOverviewView} from './training-course-walkthrough-view.js'
// TrainingCourseWalkthrough Start
export const TrainingCourseWalkthrough = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
  useEffect(() => {
    serviceProvider
      .post(
        "/home?type=setfirsttrainingseen",
        JSON.stringify({ uid: usid }),
        true
      )
      .then((response) => {
        // console.log(response);
      });

  }, []);

  useEffect(() => {
    let mainScrollDiv= document.querySelector('.training-content');
    let mainDiv=document.querySelector('.training-main-wrapper');
    mainDiv.classList.add('overlay-gray');
    mainScrollDiv.style.overflow='hidden';
    mainScrollDiv.scrollTop=0;
    return()=>{
        mainDiv.classList.remove('overlay-gray');
        mainScrollDiv.style.overflow='';
        }
    
  }, []);
  return (
    <>
      <link rel="stylesheet" href={require('./quiz-walktrhough.scss').require} />
      {/* Page title */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/training-course">Training Courses</BreadcrumbsItem>
      <BreadcrumbsItem to="/training-courses/introduction-to-industry-40">
        Introduction to Industry 4.0
      </BreadcrumbsItem>
      <div
        className="course-walkthrough2022"
      >
       <CourseOverviewView {...props}/> 
        {/* single-walkthrough-modal start */}
        <Modal open={isOpen} className="single-walkthrough-modal edited">
          <>
            <div className="single-wlakthrough-content">
              <Link
                to="/training-courses/introduction-to-industry-40"
                className="clsoe-btn"
                style={{zIndex:999}}
              >
                <img
                  src={
                    require("../../assets/img/common-img/close-icon-gray.svg")
                      .default
                  }
                  alt="X"
                />
              </Link>
              <div className="card">
                <div className="div-img">
                  <img
                    src={
                      require("../../assets/img/common-img/tc-wlk-img.svg")
                        .default
                    }
                    alt="true"
                  />
                </div>
                {/* div-img end */}
                <div className="div-txt">
                  <div className="card-content">
                    <h2 className="heading-h1">Your Training Course</h2>
                    <p className="para-p">
                      Check your overall and by section progress. Use the bottom
                      menu to navigate between course info, Q&A and the Quiz.
                    </p>
                  </div>
                  <div className="card-footer">
                    <Link
                      to="/training-courses/introduction-to-industry-40"
                      className="btn-got-it"
                    >
                      Got it
                    </Link>
                  </div>
                </div>
                {/* div-img end */}
              </div>
            </div>
          </>
        </Modal>
      </div>
    </>
  );
};
