/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * serviceProvider for ===> working with api
  * Modal ===> component from UI used to work as popup

**/
import React, { useState } from "react";
import serviceProvider from '../../services/axios'
import { Modal, Collapse } from '@material-ui/core'
//QuickBetaPopupView component start
export const QuickBetaPopupView = (props) => {
    //defining variables here	
	if(!props.homeData.data){
		return null;
	}
	const courseCirData = props.courseData;
	const registerUser = props.homeData.data.resistered_list;
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
    const [isAddUserBtn,setIsAddUserBtn] = useState(false);
    const [allreadyUser,setAllreadyUser] = useState(false);
    const { open, setOpen } = props;
    // to show right side detail box 
    const [isRightClick, setIsRightClick] = React.useState();
    // to var to store object of left side card  
    const [isRightCardObj, setIsRightCardObj] = React.useState(null);
    // var to show hover card on left side card
    const [btnHoverVal, setBtnHoverVal] = React.useState(null);
    // var to active slides 
    const [currentSlide, setCurrentSlide] = React.useState(1);
    // var to show or hide experties dropdown list
    const [isDdlLeft, setIsDdlLeft] = React.useState(false);
    // var to set value fo  experties dropdown list 
    const [ddlLeftVal, setDdlLeftVal] = React.useState(false);
    // register user array
   // const [registerUser, setRegisterUser] = React.useState([]);
    /* registerUser array element showl be object {id:invitedUser.length,userEmail: userEmailVal,experties: ddlLeftVal}*/
    // invited user array
    const [invitedUser, setInvitedUser] = React.useState( props.homeData.data.invited_list);
    // user is inviting first time?
    const [isFirstTimeUser, setIsFirstTimeUser] = React.useState(false); // update from table
    // single user email name
    const [userEmailVal, setUserEmailVal] = React.useState();
    // popups var
    const [isRightRemovePopup, setIsRightRemovePopup] = React.useState(false);
    const [isRightInvitePopup, setIsRightInvitePopup] = React.useState(false);
    const [isMainInvitePopup, setIsMainInvitePopup] = React.useState(false);
    // collapse var
    const [isInviteUserCollapse, setIsInviteUserCollapse] = React.useState(true);
    const [isRegisterUserCollapse, setIsRegisterUserCollapse] = React.useState(true);
    const [tempInviteUserId, setTempInviteUserId] = React.useState(null);
    // experties list var

    const expertiesList = ["Management", "Manufacturing", "Operational Excellence", "Information Technology", "Maintenance", "Quality", "Health & Safety", "Warehouse", "Production", "Engineering", "Human Resources", "Consulting", "Other"]
    // popup show functions
    const showRightInvitePopup = () => {
        setIsRightInvitePopup(true);
        setTimeout(() => setIsRightInvitePopup(false), 3000);
    }
    const showMainInvitePopup = () => {
        // setIsMainInvitePopup(true);
        // setTimeout(() => setIsMainInvitePopup(false), 3000);
        setIsFirstTimeUser(true);
        handleClose(false);
        //setRegisterUser(demoRegisterUser); // update list here
    }
    //function to remove invite user
    const RemoveInvitedUser = () => {
        let tmpArr = [...invitedUser];
        let tmpFilterArr = tmpArr.filter((item, index) => item.email !== tempInviteUserId);
        console.log(tmpFilterArr,tempInviteUserId);
        setInvitedUser(tmpFilterArr);
        serviceProvider.post("/user?type=removereferral", {removeId:tempInviteUserId},true).then((response) => {
            
        });
        setIsRightRemovePopup(false);
    }
    //funtion to handle open
    const handleOpen = () => {
        setOpen(true);
    };
    //function to handle close
    const handleClose = () => {
        setOpen(false);
    };
    //function to handle left section
    const HandleLeftSec = (currentVal, Obj) => {
        if (currentVal === isRightClick) {
            setIsRightClick(null);
            setIsRightCardObj(null);
        } else {
            setIsRightClick(currentVal);
            setIsRightCardObj(Obj);
        }
    }
    //function to add dropdown list
    const AddDdlValue = (val) => {
        setDdlLeftVal(val);
        setIsDdlLeft(false);
    }
    //function to add invite user
    const AddInvitedUser = () => {
        let tmpObj = {
            id: invitedUser.length,
            email: userEmailVal,
            experties: ddlLeftVal
        }
        
		serviceProvider.post("/user?type=invitereferral", { email: userEmailVal, uid: usid,experties:ddlLeftVal },true).then((response) => {
			var error = response.data.error;
			if(error == 2){
				setAllreadyUser(2);
			}else if(error==1){
				setAllreadyUser(1);
				
				}else{
						let tmpArr = [...invitedUser];
					    tmpArr.push(tmpObj);
					    setInvitedUser(tmpArr);
				}
		});
		
		
        setUserEmailVal('');
        setDdlLeftVal(null);
    }
    //function for email validation
    const EmailValidation=()=>{
        const emailRegx =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
           
        if (emailRegx.test(String(userEmailVal).toLowerCase()) && ddlLeftVal  && (invitedUser.length + registerUser.length)<10) {
            setIsAddUserBtn(false);
        }else{
            setIsAddUserBtn(true);
        }
    }
    React.useEffect(() => {
        document.onclick = (e) => {
            if (!e.target.closest('.qlft-input-wrapper.ddl')) {
                setIsDdlLeft(false);
            }
        }
    }, [])
    // this useEffect will call first time and when userEmailVal,ddlLeftVal will be change
    React.useEffect(() => {
        EmailValidation();
    }, [userEmailVal,ddlLeftVal])
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                className="quick-beta-modal"
            >
                {/* main wrapper start */}
                <div className="quick-beta-main">
                    {!isMainInvitePopup &&
                        <>
                            <ShowSlideItem index={1} activeIndex={currentSlide}>
                                {/* card has two sections
                                    card-body
                                    card-footer
                                */}
                                <div className="quick-beta-card">
                                    {/* q-card-body has two sections 
                                        lft-frame
                                        rgt-frame
                                    */}
                                    <div className="q-card-body">
                                        {/* this frame has two sections
                                            qlft-head
                                            qlft-content
                                        */}
                                        <div className="frame lft-frame">
                                            {/* this section has
                                                heading
                                                text
                                            */}
                                            <div className="qlft-head">
                                                <h1 className="qlft-h1">
                                                    <span className="blue">Invite </span>
                                                    your colleagues and save up to
                                                    <span className="blue"> 500€</span>
                                                </h1>
                                                <p className="qlft-p">
                                                    <span className="blue">Refer </span>
                                                    your friends or colleagues, get
                                                    <span className="blue"> rewards </span>
                                                    as you go and get <span className="blue">Industry 4.0 certified</span>.
                                                </p>
                                            </div>
                                            {/* lft-head end */}
                                            {/* this section has two parts which will come conditionally 
                                                list
                                                qlft-detail-box
                                            */} 
                                            <div className={`qlft-content ${isRightCardObj?.status}`
                                                /*classes according to condition
                                                free,lock,unlock
                                                */

                                            }>
                                                {!isRightClick &&
                                                    <>
                                                        <p className="qlft-p">
                                                            Access our training courses for free and gain:
                                                        </p>
                                                        <ul className="lft-icon-ul">
                                                            <li className="lft-icon-li">
                                                                <span className="span-icon">
                                                                    <img src={require('../../assets/img/quick-beta-pop-img/hour-1.svg').default} />
                                                                </span>
                                                                <span>
                                                                    1 hour free Industry 4.0 introductory course
                                                                </span>
                                                            </li>
                                                            <li className="lft-icon-li">
                                                                <span className="span-icon">
                                                                    <img src={require('../../assets/img/quick-beta-pop-img/q-clock.svg').default} />
                                                                </span>
                                                                <span>
                                                                    16 hours of additional content worth <b>500€</b>
                                                                </span>
                                                            </li>
                                                            <li className="lft-icon-li">
                                                                <span className="span-icon">
                                                                    <img src={require('../../assets/img/quick-beta-pop-img/q-over.svg').default} />
                                                                </span>
                                                                <span>
                                                                    Over <b> 40 technologies </b> around Industry 4.0
                                                                </span>
                                                            </li>
                                                            <li className="lft-icon-li">
                                                                <span className="span-icon">
                                                                    <img src={require('../../assets/img/quick-beta-pop-img/q-maturity.svg').default} />
                                                                </span>
                                                                <span>
                                                                    Our limited <b>maturity assessment</b>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <p className="qlft-p">
                                                            Click the courses on the right to explore more. See what you will learn on each course.
                                                        </p>

                                                    </>
                                                }
                                                {/* when right course button clicked */}
                                                {isRightClick &&
                                                    <div className="qlft-detail-box">
                                                        <div className="qlft-detail-body">

                                                            <div style={{ display: btnHoverVal !== 1 ? '' : 'none' }}>
                                                                <h3 className="h3">
                                                                    {isRightCardObj?.title}
                                                                </h3>
                                                                <p>
                                                                    {isRightCardObj.about}
                                                                </p>
                                                                <h4 className="h4">
                                                                    What you get?
                                                                </h4>
                                                                <p>
                                                                    {isRightCardObj.whatGet}
                                                                </p>
                                                                {isRightCardObj.technology&&
                                                                <>
                                                                    <h4 className="h4">
                                                                        Some of Technologies you learn
                                                                    </h4>
                                                                    <p className="italic">
                                                                        {isRightCardObj.technology}
                                                                    </p>
                                                                </>
                                                                }
                                                            </div>
                                                            <div style={{ display: btnHoverVal !== 1 ? 'none' : '' }}>
                                                                <p className="q-complete-text">
                                                                    Complete our award-winning Industry 4.0 Training Courses and showcase online your Industry 4.0 readiness with our digital certification.
                                                                </p>
                                                                <div className="q-complete-img">
                                                                    <img src={require('../../assets/img/quick-beta-pop-img/q-completed-img.svg').default} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="qlft-detail-footer">
                                                            <button className="certificat-btn"
                                                                onMouseEnter={() => setBtnHoverVal(1)}
                                                                onMouseLeave={() => setBtnHoverVal(null)}>
                                                                Certification upon completion
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {/* lft-content end */}
                                        </div>
                                        {/* lft-frame end */}
                                        <div className="frame rgt-frame">
                                            {/* this section has two parts
                                                frame-header
                                                frame-body
                                            */}
                                            <div className="invite-friend-wrap">
                                                {/* this section has 
                                                    frame-heading 
                                                    close-btn
                                                */}
                                                <div className="frame-header">
                                                    <h2 className="frame-heading">Industry 4.0 Training Courses</h2>
                                                    <button className="close-btn" onClick={handleClose}>
                                                        <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx={19} cy={19} r={19} fill="white" />
                                                            <path d="M12 12.5L19 19.5M26 26.5L19 19.5M19 19.5L26 12.5M19 19.5L12 26.5" stroke="#118ACB" strokeWidth={2} strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                {/* this section has button */}
                                                <div className="frame-body">
                                                    <>
                                                        {/*------- Please Add className="unlocked" for unlock on div className="img-wrap" -------*/}
                                                        {/*------- Please Add className="selected-lock" for lock div className="img-wrap" -------*/}
                                                        {/*------- Please Add className="selected-unlock" for unlock div className="img-wrap" -------*/}
                                                       
                                                        {Object.keys(courseCirData).map((key, index) => {
                                                            const item = courseCirData[key];
                                                            const isUnLock= index > 0 ? props.homeData.data.unlock.includes(item.key) : false;
															
                                                            return (
                                                                <button className="rgt-frame-btn btn" onClick={() => HandleLeftSec(index + 1, item)}>
                                                                    {!isUnLock?
                                                                        <div
                                                                            className={`img-wrap ${item.status == 'free' ? 'free' : item.status == 'lock' ? 'locked lock-unlock-bg' : item.status === 'unlock' ? 'unlocked lock-unlock-bg' : ''}`}>
                                                                            <img src={require(`../../assets/img/quick-beta-pop-img/${item.image}`).default} alt="industry-introduction" />
                                                                            <span className="bg-circle"></span>
                                                                        </div>
                                                                    :
                                                                        <div
                                                                            className={`img-wrap unlocked lock-unlock-bg`}>
                                                                            <img src={require(`../../assets/img/quick-beta-pop-img/${item.image}`).default} alt="industry-introduction" />
                                                                            <span className="bg-circle"></span>
                                                                        </div>
                                                                     }
                                                                    <span className="text">{item.title}</span>
                                                                </button>)
                                                        })
                                                        }
                                                        <div className="rgt-frame-btn btn"></div>
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* q-card-body end 1*/}
                                    {/* this section has three parts 
                                        q-footer-left
                                        q-footer-middle
                                        q-footer-right
                                    */}
                                    <div className="q-card-footer">
                                        {/* this section has titles */}
                                        <div className="q-footer-left" style={{visibility:'hidden'}}>
                                            <div className="q-lfooter-text-box">
                                                <div className="q-fl-title-div qf-mb-8">
                                                    You have unlocked
                                                </div>
                                                <div className="q-fl-title-txt-div qf-mb-8">
                                                    10 Training Courses
                                                </div>
                                                <div className="q-fl-title-div">
                                                    You saved
                                                </div>
                                                <div className="q-fl-title-txt-div">
                                                    500€
                                                </div>
                                            </div>
                                        </div>
                                        {/* this section has number */}
                                        <div className="q-footer-middle">
                                            1 of 2
                                        </div>
                                        {/* this section has link to navigate next section */}
                                        <div className="q-footer-right qf-d-flex j-content-end">
                                            <button className="qf-action-link next" onClick={() => setCurrentSlide(2)}>
                                                <span>
                                                    Next
                                                </span>
                                                <span>
                                                    <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.5 1.5L6.35302 6.7942C6.71479 7.18887 6.70155 7.79845 6.32297 8.17703L1.5 13" stroke="#118ACB" strokeWidth={2} strokeLinecap="round" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    {/* q-card-footer end */}
                                </div>
                            </ShowSlideItem>
                            <ShowSlideItem index={2} activeIndex={currentSlide}>
                                {/* this section has two parts
                                    q-card-body
                                    q-card-footer
                                */}
                                <div className="quick-beta-card">
                                    {/* q-card-body has
                                        lft-frame
                                        rgt-frame
                                    */}
                                    <div className="q-card-body">
                                        {/* this section has
                                            qlft-head
                                            qlft-content
                                        */}
                                        <div className="frame lft-frame">
                                            {/* qlft-head has
                                                heading
                                                text 
                                            */}
                                            <div className="qlft-head">
                                                <h1 className="qlft-h1 qf-mb-13">
                                                    Invite your colleagues
                                                </h1>
                                                <p className="qlft-p">
                                                    <span className="blue">Refer </span>
                                                    your friends or colleagues, get
                                                    <span className="blue"> rewards </span>
                                                    as you go and get <span className="blue">Industry 4.0 certified</span>.
                                                </p>
                                            </div>
                                            {/* lft-head end */}

                                            {/* this section has 
                                                text
                                                form
                                                error-box
                                            */}
                                            <div className={`qlft-content pb-52`}>
                                                <p className="qlft-p">
                                                    Add the email of your colleagues to invite them in SmarterChains platform. For each invitee that registers, you gain access to one Training Course for Free. You can unlock up to 5 courses.
                                                </p>
                                                {/* please add invited10-error or error class with "qlft-form-div" for show error */}
                                                {/* form has 
                                                    one input
                                                    one dropdown list
                                                    action button
                                                */}
                                                <div className={`qlft-form-div ${invitedUser.length==10? 'invited10-error': allreadyUser && 'error'}`}>
                                                    <div className="qlft-input-wrapper">
                                                        <input className="qlft-input" value={userEmailVal} placeholder="Enter email ID here" onChange={(e) =>{ setUserEmailVal(e.target.value); setAllreadyUser(false)}} />
                                                    </div>
                                                    {/* qlft-input-wrapper end */}
                                                    <div className="qlft-input-wrapper ddl">
                                                        <button className="qlf-ddl" onClick={() => setIsDdlLeft(true)}>
                                                            <span>{ddlLeftVal ? ddlLeftVal : 'Expertise'}</span>
                                                            <svg width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z" fill="#7D868C" />
                                                            </svg>
                                                        </button>
                                                        <div className={`qlf-ddl-list ${isDdlLeft && 'active'}`}>
                                                            <div className="qlf-ddl-btn" onClick={() => setIsDdlLeft(false)}>
                                                                <span>{ddlLeftVal ? ddlLeftVal : 'Expertise'}</span>
                                                                <svg width={12} height={8} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z" fill="#7D868C" />
                                                                </svg>
                                                            </div>
                                                            <ul>
                                                                {expertiesList.map((item, index) => <li key={index} onClick={() => AddDdlValue(item)}>{item}</li>)

                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {/* qlft-input-wrapper  ddl end */}
                                                    <div className="qlft-form-btn">
                                                        <div>
                                                            <button className="btn-add-user"
                                                                onClick={() => AddInvitedUser()}
                                                                disabled={isAddUserBtn}
                                                            >Invite</button>
                                                        </div>
                                                        <div>
                                                            {(invitedUser?.length + registerUser?.length) > 0 ? parseInt(invitedUser?.length)+parseInt(registerUser?.length) : '0'} out of 10 invited
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="error-box">
                                                    <div className="error-red">
                                                         {allreadyUser===1 &&
                                                            <p>This user has already been invited.</p>
                                                         }
 
                                                        {allreadyUser===2 &&
                                                         <p>Email ID already registered in platform!</p>
                                                        }
                                                    </div>
                                                    <div className="error-green">
                                                        <p>*You have already invited 10 users!</p>
                                                        {/*<p>You can remove users from your invite list to add new.</p>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* lft-content end */}
                                        </div>
                                        {/* lft-frame end */}
                                        {/* this frame has one wrapper */}
                                        <div className="frame rgt-frame">
                                            {/* this section is divided into
                                                frame-headet
                                                fram-text
                                                invite-card-img-wrap
                                                invited-registered wrapper
                                                remove-popup
                                                invite-popup
                                            */}
                                            <div className="invite-colleague-wrap">
                                                {/* header has
                                                    heading 
                                                    button
                                                */}
                                                <div className="frame-header">
                                                    <h2 className="frame-heading">Invite your friends and colleagues</h2>
                                                    <button className="close-btn" onClick={handleClose}>
                                                        <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx={19} cy={19} r={19} fill="white" />
                                                            <path d="M12 12.5L19 19.5M26 26.5L19 19.5M19 19.5L26 12.5M19 19.5L12 26.5" stroke="#118ACB" strokeWidth={2} strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <p className="frame-text">You can invite up to 10 friends or colleagues.</p>
                                                <div className="invite-card-img-wrap" style={{ display: registerUser?.length > 0 || invitedUser?.length > 0 ? 'none' : '' }}>
                                                    <img src={require("../../assets/img/quick-beta-pop-img/invite-img-wrap.svg").default} alt="Resend" />
                                                </div>
                                                {/* this section has
                                                    registered user
                                                    invited user
                                                */}
                                                <div className="invited-registered-wrapper">
                                                    {/* register user */}
                                                    {registerUser?.length > 0 && <>
                                                        <div className={`tab-head invite ${!isRegisterUserCollapse && 'collapse'}`} onClick={() => setIsRegisterUserCollapse(!isRegisterUserCollapse)}>
                                                            <p className="heading">REGISTERED USERS (<span className="user-num">{registerUser?.length}</span>/10)</p>
                                                            <img src={require("../../assets/img/quick-beta-pop-img/open-tab-icon.svg").default} alt="Open Tab" />
                                                        </div>
                                                        <div className="invite-user-wrapper">
                                                            <Collapse in={isRegisterUserCollapse}>
                                                                <div className="invite-card-wrap register">
                                                                    {registerUser.map((item, index) => (<div className="card-wrap">
                                                                        <span className="num">{index + 1}</span>
                                                                        <div className="card-box">
                                                                            <div className="email-box">
                                                                                <div className="text-wrap"><span class="email">{item.email}</span></div>
                                                                                {/*<button className="resend-btn">
                                                                                    <img src={require("../../assets/img/quick-beta-pop-img/invite.svg").default} alt="Resend" className="hover-btn" />
                                                                                    <img src={require("../../assets/img/quick-beta-pop-img/hover-resend-btn.svg").default} alt="Reinvite" className="reinvite-btn" />
                                                                                    <span className="reinvite-tooltip">Re-invite</span>
                                                                                </button>*/}
                                                                            </div>
                                                                            {/*<button className="remove-btn">
                                                                                <img src={require("../../assets/img/quick-beta-pop-img/resend.svg").default} alt="Resend" />
                                                                                <img src={require("../../assets/img/quick-beta-pop-img/hover-remove-btn.svg").default} alt="Resend" className="hover-btn" />
                                                                                <span className="remove-tooltip">Remove</span>
                                                                            </button>*/}
                                                                        </div>
                                                                    </div>))}
                                                                </div>
                                                            </Collapse>


                                                        </div>
                                                    </>}
                                                    {/* invited user */}
                                                    {invitedUser?.length > 0 && <>
                                                        <div className={`tab-head invite ${!isInviteUserCollapse && 'collapse'}`} onClick={() => setIsInviteUserCollapse(!isInviteUserCollapse)}>
                                                            <p className="heading">INVITED USERS (<span className="user-num">{invitedUser?.length > 0 ? invitedUser?.length : '0'}</span>/10)</p>
                                                            <img src={require("../../assets/img/quick-beta-pop-img/open-tab-icon.svg").default} alt="Open Tab" />
                                                        </div>
                                                        <div className="invite-user-wrapper">
                                                            <Collapse in={isInviteUserCollapse}>
                                                                <div className={`invite-card-wrap ${!isFirstTimeUser && 'first-time'}`}>
                                                                    {invitedUser.map((item, index) => (<div className="card-wrap">
                                                                        <span className="num">{index + 1}</span>
                                                                        <div className="card-box">
                                                                            <div className="email-box">
                                                                                <div className="text-wrap"><span class="email">{item.email}</span></div>
                                                                                {/*<button className="resend-btn"
                                                                                    onClick={showRightInvitePopup}>
                                                                                    <img src={require("../../assets/img/quick-beta-pop-img/invite.svg").default} alt="Resend" className="hover-btn" />
                                                                                    <img src={require("../../assets/img/quick-beta-pop-img/hover-resend-btn.svg").default} alt="Reinvite" className="reinvite-btn" />
                                                                                    <span className="reinvite-tooltip">Re-invite</span>
                                                                                </button>*/}
                                                                            </div>
                                                                            {/*<button className="remove-btn" onClick={() => { setIsRightRemovePopup(true); setTempInviteUserId(item.email); }}>
                                                                                <img src={require("../../assets/img/quick-beta-pop-img/resend.svg").default} alt="Resend" />
                                                                                <img src={require("../../assets/img/quick-beta-pop-img/hover-remove-btn.svg").default} alt="Resend" className="hover-btn" />
                                                                                <span className="remove-tooltip">Remove</span>
                                                                            </button>*/}
                                                                        </div>
                                                                    </div>))}
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                                {/* Remove popup wrap */}
                                                <div className={`remove-popup-wrap ${isRightRemovePopup && 'active'}`}>
                                                    <div className="remove-popup">
                                                        <p className="text">Do you want to remove this user? You will be able to invite a new one instead.</p>
                                                        <div className="btn-wrap">
                                                            <button className="btn cancel-btn" onClick={() => setIsRightRemovePopup(false)}>Cancel</button>
                                                            <button className="btn removebtn" onClick={RemoveInvitedUser}>Remove</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Right invite popup */}
                                                <div className={`right-invite-wrap ${isRightInvitePopup && 'active'}`}>
                                                    <div className="invite-popup">
                                                        <div className="img-wrap"><img src={require("../../assets/img/quick-beta-pop-img/check.gif").default} alt="Sent Invite" /></div>                                                <div className="btn-wrap">
                                                            <p className="text">An invitation email has been sent successfully!</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    {/* q-card-body end 1*/}
                                    <div className="q-card-footer">
                                        <div className="q-footer-left">
                                            <button className="qf-action-link prv" onClick={() => setCurrentSlide(1)}>
                                                <span>
                                                    <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.5 13L1.64698 7.7058C1.28521 7.31113 1.29845 6.70155 1.67703 6.32297L6.5 1.5" stroke="#118ACB" strokeWidth={2} strokeLinecap="round" />
                                                    </svg>
                                                </span>
                                                <span>
                                                    Back
                                                </span>
                                            </button>
                                        </div>
                                        <div className="q-footer-middle">
                                            2 of 2
                                        </div>
                                        <div className="q-footer-right qf-d-flex j-content-end">
                                            {/*{isFirstTimeUser ?
                                                <button className="qf-blue-btn" onClick={()=>handleClose(false)}>
                                                    Done
                                                </button>
                                                :
                                                <button className="qf-blue-btn" disabled={invitedUser?.length > 0 ? false : true} onClick={()=>{showMainInvitePopup(); handleClose(false)}}>
                                                    Invite
                                                </button>
                                            }*/}
                                            <button className="qf-blue-btn" disabled={invitedUser?.length > 0 ? false : true} onClick={()=>{showMainInvitePopup();}}>
                                                    Done
                                            </button>
                                        </div>
                                    </div>
                                    {/* q-card-footer end */}
                                </div>
                            </ShowSlideItem>
                        </>
                    }

                    {/*---------------- Invitation Popup ---------------*/}
                    <div className={`background-blur-wrapper ${isMainInvitePopup && 'active'}`}>
                        <div className="main-invitation-popup-wrap">
                            {/* pop-wrap has three sections 
                                img-wrap
                                heading
                                text
                            */}
                            <div className="pop-wrap">
                                <div className="img-wrap"><img src={require("../../assets/img/quick-beta-pop-img/check.gif").default} alt="Sent Invite" /></div>
                                <h2 className="heading">Invitation Sent</h2>
                                <p className="text">Thank you! New invitees have received an email.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

//function to show slide item
const ShowSlideItem = (props) => {
    const { index, activeIndex, children } = props;
    if (index === activeIndex) {
        return (
            <>
                {children}
            </>
        )
    } else return null;
}