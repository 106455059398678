/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * ProfileView ===> custom view component
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.

**/

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { ProfileView } from "./profile-view";
import serviceProvider from '../../services/axios'
import "./profile.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
//PurchaseController start
export const PurchaseController = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const [onTab, setOnTab] = useState(1);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const progress = user ? user.progress : 0;
    const plant_slug = user ? user.plant_slug : 0;
    const gid = user ? user.group_id : 0;
	if (progress<100 && (gid == 14 || gid == 2)) {
         return <Redirect to={`/assessment/${plant_slug}`} />;
    }
	useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
			const apiUrl = `/profile?param=purchase&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, []);
	return (
		<>
			{/* page title */}
			<Helmet>
				<title>Purchase History | SmarterChains</title>
				<meta name="description" content="Purchase History" />
				<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
			</Helmet>
			{/* breadcrumbs */}
			<BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/profile">Account Settings</BreadcrumbsItem>
			{/* custom view component */}
			<ProfileView onTab={onTab} profileData={results} {...props} />
		</>
	);
};
