import { React, useState, useEffect, useRef } from "react";
import serviceProvider from '../../services/axios'
import {
    PopupContainer,
    PopupCard,
    PopupCardHeader,
    PopupCardBody,
    HeaderH1,
    PopupCloseButton,
    HeaderButtonsWrap,
    ReasonCodeBox,
    CommentContainer,
    CommentWrap,
    CommentRow,
    CommentCol,
    CommentNameCol,
    PopCardFooter,
    TextAreaWrap,
    RightCommentWrap,
    EditDelWrap,
    DeletePopupWrap,
    DeletePopCard,
    ButtonWrap,
} from './loss-comment-pop-styles'
import { closeSvg } from "./svg";
export const LossAssumptionCommentPopupView = (props) => {
    const { item, count } = props.data;
    const { commnetsCount, setCommentCounts, id } = props;
    const { closeFunction } = props;
    const reason_id = props.reason_id;
    const [isDeletePop, setIsDeletePop] = useState(false);
    const [postText, setPostText] = useState(``);
    const [editText, setEditText] = useState(``);
    const [comments, setComments] = useState([]);
    const [isEditTextArea, setIsEditTextArea] = useState(false);
    const [isNewCommentTextArea, setIsNewCommentTextArea] = useState(false);
    const uid = props.uid;
    const u_sortName = props.sn;
    const cir_color = props.avcolor;
    const handleComment = () => {
        setPostText(true);
    }
    // Function to edit Comment
    const handleEditCommentText = (comment) => {
        setIsEditTextArea(comment);
        setEditText(comment.text);
        setPostText(``);
        setIsNewCommentTextArea(false);
    }
    // Function to save edit comment
    const saveEditedData = (comment) => {
        let tmpArray = [...comments];
        let tmpIndex = comments.findIndex(i => i.id == comment.id);
        tmpArray.splice(tmpIndex, 1, { ...comment, text: editText });
        setComments(tmpArray);
        setIsEditTextArea(false);

        serviceProvider.post("/businesscaseplantloss?type=editcommentreasoncode",
            JSON.stringify({ comment_id: comment.id, comment: editText })).then((response) => { });
    }
    // Function to post comment
    const postNewComment = () => {
        let tmpCount = Number(commnetsCount[id] || item.comments);
        serviceProvider.post("/businesscaseplantloss?type=savecommentreasoncode",
            JSON.stringify({
                uid: props.uid, pid: props.pid, scid: props.scid, reason_id: props.reason_id, node_id: props.node_id, comment: postText,
                sort_name: u_sortName, cir_color: cir_color
            })).then((response) => {

                let tmpId = new Date().getTime();
                let tmpComment = {
                    user_id: uid,
                    id: response.data,
                    sort_name: u_sortName,
                    text: postText,
                    cir_color: cir_color,
                };
                let tmpArray = [...comments];
                tmpArray.push(tmpComment);
                setCommentCounts(prv => {
                    return {
                        ...prv,
                        [id]:tmpCount + 1
                    }
                });
                setComments(tmpArray);

            });
        setPostText(``);
        setIsNewCommentTextArea(false);
        let scrollDiv = document.getElementById('comment-scroll-div');
        setTimeout(() => {
            let scrollDivHeight = scrollDiv.scrollHeight;
            scrollDiv.scrollTop = scrollDivHeight;
        }, 100)

    }
    // Function To delete comment
    const handleCommentDelete = (comment_id) => {
        let tmpFilterData = comments?.filter(i => i.id != comment_id);
        let tmpCount = Number(commnetsCount[id] || item.comments);
        setComments(tmpFilterData);
        setIsDeletePop(false);
        serviceProvider.post("/businesscaseplantloss?type=deletecommentreasoncode",
            JSON.stringify({ comment_id: comment_id })).then((response) => {
                setCommentCounts(prv => {
                    return {
                        ...prv,
                        [id]:tmpCount - 1
                    }
                });
             });
    }
    // Function to call First time data

    useEffect(() => {
        setComments(props.resultscom[0]?.comments);
    }, [props.resultscom])

    return (
        <PopupContainer data-component="PopupContainer" id="commentPopup">
            <PopupCard data-component="PopupCard">
                <PopupCardHeader data-component="PopupCardHeader">
                    <HeaderButtonsWrap data-component="HeaderButtonsWrap">
                        <HeaderH1 data-component="HeaderH1">
                            Comments
                        </HeaderH1>
                        <PopupCloseButton data-component="PopupCloseButton" onClick={closeFunction}>
                            <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                        </PopupCloseButton>
                    </HeaderButtonsWrap>
                    <ReasonCodeBox data-component="ReasonCodeBox">
                        <span className="heading">REASON CODE</span>
                        <span className="title">{count}. {item.reason_title}</span>
                    </ReasonCodeBox>
                </PopupCardHeader>
                <PopupCardBody data-component="PopupCardBody" id='comment-scroll-div'>
                    <CommentContainer data-component="CommentContainer">
                        {comments?.map((comment, commentIndex) => (
                            <div key={commentIndex}>
                                {comment.user_id != uid ?
                                    <CommentWrap data-component="CommentWrap">
                                        <CommentRow data-component="CommentRow">
                                            <CommentNameCol data-component="CommentNameCol">
                                                <span className={`user-name ${comment.cir_color}`}>{comment.sort_name}</span>
                                            </CommentNameCol>
                                            <CommentCol data-comment="CommentCol">
                                                <div className="text">
                                                    <p>{comment.text}</p>
                                                </div>
                                            </CommentCol>
                                        </CommentRow>
                                    </CommentWrap>
                                    :
                                    <RightCommentWrap data-component="RightCommentWrap">
                                        <CommentRow data-component="CommentRow">
                                            <CommentCol data-comment="CommentCol">
                                                <div className="text">
                                                    <p>{comment.text}</p>
                                                </div>
                                                <EditDelWrap data-component="EditDelWrap">
                                                    <button className="edit-btn" onClick={() => handleEditCommentText(comment)}>Edit</button>
                                                    <span></span>
                                                    <button className="del-btn" onClick={() => setIsDeletePop(comment.id)}>Delete</button>
                                                </EditDelWrap>
                                            </CommentCol>
                                            <CommentNameCol data-component="CommentNameCol">
                                                <span className={`user-name ${comment.cir_color}`}>{comment.sort_name}</span>
                                            </CommentNameCol>
                                        </CommentRow>
                                    </RightCommentWrap>
                                }
                            </div>

                        ))}
                    </CommentContainer>
                </PopupCardBody>
                <PopCardFooter data-component="PopCardFooter">
                    {isEditTextArea ?
                        <TextAreaWrap data-component="TextAreaWrap">
                            <textarea value={editText} onChange={(e) => setEditText(e.target.value)}></textarea>
                            <button className={`post-btn ${editText ? `active` : ''}`} onClick={() => saveEditedData(isEditTextArea)}>Save Changes</button>
                        </TextAreaWrap>
                        :
                        isNewCommentTextArea ?
                            <TextAreaWrap data-component="TextAreaWrap">
                                <textarea value={postText} onChange={(e) => setPostText(e.target.value)} placeholder="Type a comment" autoFocus></textarea>
                                <button className={`post-btn ${postText ? `active` : ''}`} onClick={() => postNewComment()}>Post</button>
                            </TextAreaWrap>
                            :
                            <button className="cmnt-btn" onClick={() => setIsNewCommentTextArea(true)} autoFocus>New Comment</button>
                    }
                </PopCardFooter>
                {isDeletePop &&
                    <DeletePopupWrap data-component="DeletePopupWrap">
                        <DeletePopCard data-component="DeletePopCard">
                            <h2>Delete Comment</h2>
                            <p>Are you sure to delete this comment?</p>
                            <ButtonWrap data-component="ButtonWrap">
                                <button className="cancel-btn" onClick={() => setIsDeletePop(false)}>Cancel</button>
                                <button className="yes-btn" onClick={() => handleCommentDelete(isDeletePop)}>Yes, delete</button>
                            </ButtonWrap>
                        </DeletePopCard>
                    </DeletePopupWrap>
                }
            </PopupCard>
        </PopupContainer>
    )
}
