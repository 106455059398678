
import React, { useState, useEffect, useRef, memo } from "react";

const StepFirstView = (props) => {
  return (
    <>
      <div className="container-fluid px-0">
        <div className="row outer-row no-gutters px-0">
          <div className="add-team-member-poup-outer" id="select_plant_name" style={{ display: 'none' }}>
          </div>
          <section className="col asst-right-sec">
            {/* content header end  */}
            <div className="asst-scroll">
              <div className="asst-abt-content-body">
                <div className="row no-gutters px-0">
                  <div className="col asst-abt-left">
                    <div className="d-flex admin-page-empty-wrapper no-assesed-wrapper bc-master-wrapper">
                      <div className="col admin-page-empty-left">
                        <div className="admin-page-card">
                          <div className="row adm-card-header d-flex position-relative">
                            <div className="col-6 adm-col justify-content-start adm_col_lft_wrap">
                              <div className="d-flex rmap-header-right-wrapper align-items-center">
                                <div className="col-8 px-0">
                                  Available Capabilities
                                </div>
                                <div className="col-4 pr-2">
                                  <div className="bc-filter-sec">
                                    <div className="d-flex justify-content-end">
                                      <button className="rmap-btn open-filter" data-target="#filpopup">
                                        <img src={require("../../assets/img/roadmap-img/filter-btn.svg").default} className="btn-save-img" />
                                        {/* <span>Filters</span> */}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* col end */}
                            <div className="col-6 adm-col justify-content-center border-0 adm_col_rgt_wrap overflow-visible">
                              <div className="d-flex rmap-header-right-wrapper align-items-center">
                                <div className="col-9 px-0">
                                  <div className="bc-scenario-box " id="scenarioBox1">
                                    <button className="bc-scenario-box-header btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                                      <span className="scid-285">Scenario 1</span>
                                      <svg className="ddl-arrow" width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.175 0L5 3.7085L8.825 0L10 1.1417L5 6L0 1.1417L1.175 0Z" fill="#4D4D4F" />
                                      </svg>
                                    </button>
                                  </div>
                                  {/* active-scenario-box end */}
                                </div>
                                {/* col-4 end  */}
                                <div className="col-3 px-0">
                                  <div className="d-flex justify-content-end">
                                    <button data-redirect={1} className="save-rmap-btn save-roadmap">
                                      <span>Save roadmap</span>
                                    </button>
                                    {/* btn save raodmap */}
                                  </div>
                                  {/* flex end */}
                                </div>
                                {/* col-8 end */}
                              </div>
                              {/* rmap header right wrapper */}
                            </div>
                            {/* col end */}
                          </div>
                          {/*card header end */}
                          <div className="adm-card-body pb-0 p-0 position-relative border-0">
                            <div className="d-flex h-100">
                              <div className="col-6 ramp-col-p bc_lft-cont_wrap">
                                <div className="accordion" id="rmapl">
                                  <div id="node-17" attr-name="3D Printing for spare parts" attr-id={17} attr-investment={0} attr-recoverable="18946.944" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          3D Printing for spare parts </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>18,947</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={17} attr-nodeid="node-17" attr-investment={0} attr-recoverable="18946.944" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-17" aria-expanded="false" aria-controls="nodemodal-17">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-1" attr-name="Advanced Manufacturing Analytics" attr-id={1} attr-investment={0} attr-recoverable="22364.064104269" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Advanced Manufacturing Analytics </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>22,364</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={1} attr-nodeid="node-1" attr-investment={0} attr-recoverable="22364.064104269" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-1" aria-expanded="false" aria-controls="nodemodal-1">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-36" attr-name="Augmented Reality (AR) Remote Support" attr-id={36} attr-investment={0} attr-recoverable="28199.070363878" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Augmented Reality (AR) Remote Support </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>28,199</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={36} attr-nodeid="node-36" attr-investment={0} attr-recoverable="28199.070363878" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-36" aria-expanded="false" aria-controls="nodemodal-36">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-10" attr-name="Automated Guided Vehicles (AGVs)" attr-id={10} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Automated Guided Vehicles (AGVs) </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={10} attr-nodeid="node-10" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-10" aria-expanded="false" aria-controls="nodemodal-10">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-11" attr-name="Automated Storage & Retrieval System (AS/RS)" attr-id={11} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Automated Storage &amp; Retrieval System
                                          (AS/RS) </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={11} attr-nodeid="node-11" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-11" aria-expanded="false" aria-controls="nodemodal-11">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-45" attr-name="Automatic Truck Loading System (ATLS)" attr-id={45} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Automatic Truck Loading System (ATLS) </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={45} attr-nodeid="node-45" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-45" aria-expanded="false" aria-controls="nodemodal-45">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-12" attr-name="Autonomous Mobile Robots (AMRs)" attr-id={12} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Autonomous Mobile Robots (AMRs) </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={12} attr-nodeid="node-12" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-12" aria-expanded="false" aria-controls="nodemodal-12">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-6" attr-name="Blockchain" attr-id={6} attr-investment={0} attr-recoverable="4512.464" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Blockchain </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>4,512</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={6} attr-nodeid="node-6" attr-investment={0} attr-recoverable="4512.464" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-6" aria-expanded="false" aria-controls="nodemodal-6">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-22" attr-name="Cloud Infrastructure & Data Architecture" attr-id={22} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Cloud Infrastructure &amp; Data Architecture
                                        </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={22} attr-nodeid="node-22" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-22" aria-expanded="false" aria-controls="nodemodal-22">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-40" attr-name="Collaborative Robots" attr-id={40} attr-investment={0} attr-recoverable="7448.6292123326" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Collaborative Robots </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>7,449</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={40} attr-nodeid="node-40" attr-investment={0} attr-recoverable="7448.6292123326" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-40" aria-expanded="false" aria-controls="nodemodal-40">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-18" attr-name="Computerized Maintenance Management System (CMMS)" attr-id={18} attr-investment={0} attr-recoverable="8478.0503643922" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Computerized Maintenance Management System
                                          (CMMS) </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>8,478</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={18} attr-nodeid="node-18" attr-investment={0} attr-recoverable="8478.0503643922" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-18" aria-expanded="false" aria-controls="nodemodal-18">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-19" attr-name="Condition Monitoring Systems" attr-id={19} attr-investment={0} attr-recoverable="10213.99124148" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Condition Monitoring Systems </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>10,214</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={19} attr-nodeid="node-19" attr-investment={0} attr-recoverable="10213.99124148" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-19" aria-expanded="false" aria-controls="nodemodal-19">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-37" attr-name="Connected Workforce Platform" attr-id={37} attr-investment={0} attr-recoverable="39832.025053812" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Connected Workforce Platform </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>39,832</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={37} attr-nodeid="node-37" attr-investment={0} attr-recoverable="39832.025053812" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-37" aria-expanded="false" aria-controls="nodemodal-37">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-7" attr-name="Data Lake" attr-id={7} attr-investment={0} attr-recoverable={1352} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Data Lake </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>1,352</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={7} attr-nodeid="node-7" attr-investment={0} attr-recoverable={1352} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-7" aria-expanded="false" aria-controls="nodemodal-7">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-8" attr-name="Data Warehouse" attr-id={8} attr-investment={0} attr-recoverable={1352} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">

                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Data Warehouse </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>1,352</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={8} attr-nodeid="node-8" attr-investment={0} attr-recoverable={1352} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-8" aria-expanded="false" aria-controls="nodemodal-8">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-31" attr-name="Digital Cultural Mindset" attr-id={31} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">

                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Digital Cultural Mindset </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={31} attr-nodeid="node-31" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-31" aria-expanded="false" aria-controls="nodemodal-31">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-24" attr-name="Digital Skills Cultivation" attr-id={24} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type2 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Digital Skills Cultivation </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={24} attr-nodeid="node-24" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-24" aria-expanded="false" aria-controls="nodemodal-24">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-2" attr-name="Digital Twin" attr-id={2} attr-investment={0} attr-recoverable="20695.06777957" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Digital Twin </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>20,695</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={2} attr-nodeid="node-2" attr-investment={0} attr-recoverable="20695.06777957" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-2" aria-expanded="false" aria-controls="nodemodal-2">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-38" attr-name="e-IWS" attr-id={38} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          e-IWS </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={38} attr-nodeid="node-38" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-38" aria-expanded="false" aria-controls="nodemodal-38">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-27" attr-name="Edge Computing & Edge Gateways/Devices" attr-id={27} attr-investment={0} attr-recoverable="225.33333333333" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Edge Computing &amp; Edge Gateways/Devices
                                        </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>225</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={27} attr-nodeid="node-27" attr-investment={0} attr-recoverable="225.33333333333" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-27" aria-expanded="false" aria-controls="nodemodal-27">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-4" attr-name="Energy Management Software (EMS)" attr-id={4} attr-investment={0} attr-recoverable="5720.0770082192" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="false">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Energy Management Software (EMS) </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>5,720</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={4} attr-nodeid="node-4" attr-investment={0} attr-recoverable="5720.0770082192" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-4" aria-expanded="false" aria-controls="nodemodal-4">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-20" attr-name="Enterprise Asset Management (EAM)" attr-id={20} attr-investment={0} attr-recoverable="8389.3637965644" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Enterprise Asset Management (EAM) </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>8,389</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={20} attr-nodeid="node-20" attr-investment={0} attr-recoverable="8389.3637965644" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-20" aria-expanded="false" aria-controls="nodemodal-20">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-3" attr-name="Environmental, Health & Safety (EHS) Software" attr-id={3} attr-investment={0} attr-recoverable="1552.5488748858" attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Environmental, Health &amp; Safety (EHS)
                                          Software </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green">
                                                <span>1,553</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={3} attr-nodeid="node-3" attr-investment={0} attr-recoverable="1552.5488748858" attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-3" aria-expanded="false" aria-controls="nodemodal-3">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                  <div id="node-28" attr-name="Industrial Connectivity Infrastructure" attr-id={28} attr-investment={0} attr-recoverable={0} attr-loss={0} data-filter="type1 priority1" className="rmap-parent-card node-parent card" draggable="true">
                                    <div className="rmap-card card-header">
                                      <div className="rmap-card-content">
                                        <a href="javascript:void(0)" className="rmap-card-left drag-icon">
                                          <img src={require("../../assets/img/roadmap-img/drag-icon.svg").default} />
                                        </a>
                                        <p className="rmap-txt">
                                          Industrial Connectivity Infrastructure </p>
                                      </div>
                                      <div className="add_expand_wrapper devide_and_rule">
                                        <div className="designer-buttons">
                                          <a href="#" style={{ cursor: 'default' }} className="na-change modal-button">NA</a>
                                        </div>
                                        <div className="bc_pro_data">
                                          <ul className="list-unstyled">
                                            <li className="recoverable">Recoverable:
                                              <cdiv className="green"><span>0</span>
                                                <cspan>$</cspan>
                                              </cdiv>
                                            </li>
                                            <li className="investment">Investment: <cdiv className="orange"><span>0</span>
                                              <cspan>$</cspan>
                                            </cdiv>
                                            </li>
                                            {/* <li class="investment">Loss: <span>0</span><cspan>$</cspan></li> */}
                                          </ul>
                                        </div>
                                        <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id={28} attr-nodeid="node-28" attr-investment={0} attr-recoverable={0} attr-loss={0}>
                                          <img src={require("../../assets/img/roadmap-img/add-darg.svg").default} />
                                        </a>
                                        <a href="javascript:void(0)" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#nodemodal-28" aria-expanded="false" aria-controls="nodemodal-28">
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                          <img src={require("../../assets/img/roadmap-img/bc-kebab-icon-down.svg").default} className="bc_open" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  {/* add scroll div end */}
                                </div>
                              </div>
                              {/* col 6 */}
                              <div className="col-6 ramp-col-p bc_rgt-cont_wrap">
                                <div id="dropzonearea" className="droparea" data-drop-target="true">
                                  <div className="rc-data-sec">
                                    <p className="bc_mn_head">Roadmap</p>
                                    <div className="row loss_total_val">
                                      <div className="col-3">
                                        Total Losses<br />Identified:
                                        <div className="bc_tot_spa red"><span id="totalloss" data-totalloss="1257708.1521084013">1,257,708</span>
                                          <cspan>$</cspan>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        Total Roadmap<br />Recoverable:
                                        <div className="bc_tot_spa green"><span id="totalrecoverable" data-totalrecoverable={123862}>123,862</span>
                                          <cspan>$</cspan>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        % of Recoverable<br />Losses:
                                        <div className="bc_tot_spa blue"><span id="totallossper" data-totallossper="9.8482318630398">10</span><span>%</span>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        Total Roadmap<br />Investment:
                                        <div className="bc_tot_spa orange"><span id="totalinvestment" data-totalinvestment={0}>0</span>
                                          <cspan>$</cspan>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-0 add-scroll">
                                    <div className="accordion" id="roadmap-nodes">
                                      <div className="appendNodes" />
                                      <div className="card card_disabled">
                                        <div className="card-header" id="rmaphead1">
                                          <div className="rmap-card-content">
                                            <a href="javascript:void(0)" className="rmap-card-left rm_count_badge">
                                              <span id="static-node" className="rmap-rght-no">4</span>
                                            </a>
                                          </div>
                                          <div className="add_expand_wrapper">
                                            <a href="javascript:void(0)" className="card-border-left add-drag add-btn add-node" attr-id attr-nodeid>
                                              <img src={require("../../assets/img/roadmap-img/bc-rmap-minus.svg").default} />
                                            </a>
                                            <a href="#" className="btn btn-header-link collapsed">
                                              <img src={require("../../assets/img/roadmap-img/bc-kebab-icon.svg").default} className="bc_close" />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="bc_proceed" className="bc_proceed_btn_wrap">
                                      <a href="javascript:void(0)" className="btn all_btn_white proceed-btn proceed-btn-pop" style={{ display: 'none' }}>Proceed</a>
                                      <a href="automation/demo-plant/step2/285" className="btn all_btn_white proceed-btn proceed-btn-direct">Proceed</a>
                                    </div>
                                    {/* rmap card end 2 */}
                                  </div>
                                </div>
                                {/* rmap card details popup rgt  */}
                              </div>
                              {/* col 6  */}
                            </div>
                            {/* d felx end */}
                          </div>
                          {/* card body end */}
                        </div>
                        {/* admin page card end */}
                      </div>
                      {/* left sec */}
                    </div>
                    {/*admin-page-empty-wrapper end */}
                  </div>
                  {/*asst-abt-left end*/}
                </div>
                {/* row end */}
              </div>
              {/* asst content body end*/}
            </div>
            {/* asst-scroll end */}
          </section>
          {/* right sec end */}
        </div>
        {/* outer row end */}
      </div>
    </>
  );
};
export default memo(StepFirstView);
