/** imported components

  * LossadminPlantView ===> custom view component
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React from "react";
import {useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LossadminPlantView } from "./lossadmin-plant-view";
import {Helmet} from 'react-helmet';

//LossadminPlantController start
export const LossadminPlantController = (props) => {
  //Defining variables here
  const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
  const slug = getLastItem(window.location.pathname);
  const [results, setResults] = useState([]);
  const [progressresult, setProgressResult]= useState([]);
  const [lockbutton, setLockbutton]= useState(false);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;
 
  useEffect(() => {
		const search = async () => {
			const apiUrl = `/businesscaseplantloss?param=section&slug=${slug}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
			setResults(data);
		};
		search();
	}, [lockbutton]);



 


if (results.length) {
		
		
			
			if(results[0].has_businesscase == 11){
						var pp = "/not-found";
				        return <Redirect to="/not-found" />;
						return (
								<>
									<div></div>
								</>
						);
			}else if(results[0].block == 1){
						var pp = "/404";
				        return <Redirect to="/404" />;
						return (
								<>
									<div></div>
								</>
						);			
			}else{
					
					 return (
							<>
							{/* Page Title */}
							<Helmet>
								<title>Loss Analysis | SmarterChains</title>
								<meta name="description" content="Loss Analysis" />
							</Helmet>
							{/* Breadcrumbs */}
							<BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
      						<BreadcrumbsItem to="/">{results[0].plant_name}</BreadcrumbsItem>
								<BreadcrumbsItem to="#">IR4.0 Loss Analysis</BreadcrumbsItem>
								{/* Upgrade Changes */}
							  <LossadminPlantView slug={slug} plantName={results[0].plant_name} progressresult={progressresult} setLockbutton={setLockbutton}  sectionData={results} {...props} />    
							</>
  						);
			}
			
			
	}else{
		return (
		<>
			<div></div>
		</>
	);
		
		}
	
};
