import styled from 'styled-components'
import { Link } from 'react-router-dom';

const singleColWidth = 100 / 12;

export const MainWrapper = styled.section`
    padding:0rem 3rem 0.4rem 3rem;
    display: flex;
    flex-direction: column;
    height:100%;
    .sm-visible{
        display: none;
        &.hide-row{
            display:none !important;
        }
    }
    @media(max-width: 740px){
        padding: 10px 10px 0.4px 10px;
        height: auto;
        .sm-hide{
            display: none;
        }
        .sm-visible{
            display: flex;
            &.heading-wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;
                span{
                    display: block;
                    color: #4D4D4F;
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 38px; /* 135.714% */
                }
                button{
                    display: inline-block;
                    height: 38px;
                    width: 38px;
                    i{
                        display: inline-block;
                        height: 100%;
                        width: 100%;
                        svg{
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
            .sm-main-wrap{
                background: #ffffff;
                padding: 0 0 0 30px;
                width: 100%;
                @media(max-width: 740px){
                    padding-left: 12px;
                }
                .sm-main-row{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 8px 8px 0;
                    border-bottom: 1px solid #E5F0FF;
                    width: 100%;
                    .title{
                        display: inline-block;
                        color: #607D8B;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        flex:1; 
                    }
                    .status-col{
                        display: inline-block;
                        margin-left: 8px;
                        width: 89px;
                        padding-right: 15px;
                    }
                    button{
                        display: inline-block;
                        height: 21px;
                        width: 21px;
                        i{
                            display: inline-block;
                            height: 100%;
                            width: 100%;
                            svg{
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const Header = styled.section`
   height: 10.2rem;
   border-bottom: 0.1rem solid #E6E6E6;
   display: flex;
   align-items: center;
   justify-content: space-between;
   @media(max-width: 1100px){
    flex-wrap: wrap;
    height: 225px;
   }
   @media(max-width: 740px){
    flex-wrap: wrap;
    height: 270px;
    padding-bottom: 20px;
   }
`;

export const Heading = styled.h1`
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.9rem;
    color: #0D2C54;
    width:22.6rem;
    @media(max-width:1100px){
        font-size: 2.8rem;
        width: 100%;
    }
    @media(max-width: 740px){
        font-size: 24px;
        width: 100%;
    }
`
export const HeaderRight = styled.aside`
    max-width: calc(100% - 22.6rem);
    display: flex;
    gap:1.5rem;
    @media(max-width: 1100px){
        flex-wrap: wrap;
        max-width: 100%;
        gap: 10px;
    }
    @media(max-width: 740px){
        flex-wrap: wrap;
        gap: 10px;
        max-width: 100%;
    }
    &.walk-3{
    &.walkthrough-active{
        position: relative;
        z-index: 999;
        &::after{    
            content: '';
            position: absolute;
            top: -1rem;
            left: -1rem;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: #ffffff;
            border-radius: 0.8rem;
            z-index: -1;
            padding: 1rem;
        }
    }
}  
`
export const HeaderRightButton = styled.button`
       padding: 1rem 2rem;
       border-radius:0.4rem;
       font-weight: 400;
       font-size: 1.6rem;
       display: inline-flex;
       align-items: center;
       color: #0D2C54;
       border:0.1rem solid transparent;
       border-color:${({ count }) => count ? '' : '#E6E6E6'};
       background-color:${({ count }) => count ? '' : '#fff'};
       outline:0;
       cursor: pointer;
       transition:0.25s ease-in;
       .count{
        font-weight:500;
        margin-left:0.5rem;
        @media(max-width: 740px){
            font-size: 14px;
            margin:0;
        }

       }
       >.refresh svg{
            width: 1.2rem;
            margin-left:1.5rem;
            transition:0.25s ease-in;
            @media(max-width: 740px){

            }
            path{
                transition:0.25s ease-in;
                fill:#7D868C;
            }
       }
       >.oval svg{
          width: 1.6rem;
          margin-right:1rem;
          path{
            fill:${({ red, green, yellow }) => red ? '#DB324D' : yellow ? '#FFBC1F' : green ? '#4CAF50' : ''};
          }
       }
       :hover{
            background: #E5F0FF;
            border-color:#118ACB;
            >.refresh svg path{
                fill:#118ACB;
            }
       }
       &.active{
            background: #E5F0FF;
            border-color:#E5F0FF;
            >.refresh svg path{
                fill:#118ACB;
            }
       }
       @media(max-width:1100px){
            padding: 10px;
            width: 40%;

       }
       @media(max-width: 740px){
        font-size: 14px;
        border-radius: 8px;
        padding: 6px;
        align-items: flex-start;
        flex-direction: column;
        width: 98px;
        &:last-child{
            margin-right: 0;
        }
       }
       &:first-child{
        @media(max-width: 740px){
            border-radius: 4px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            font-size: 16px;
            flex-direction: row;
            padding: 14px 16px;
            width: 100%;
            i{
                display: inline-block;
                height: 1.6rem;
                width: 1.6rem;
                svg{
                    height: 100%;
                    width: 100%;
                }
            }
        }
       }
`
export const HeaderFilterButton = styled(HeaderRightButton)`
        width:28.2rem;
        display:flex;
        justify-content: space-between;
        @media(max-width:1100px){
            width: 100%;
        }
`
export const HeaderFilterCard = styled.div`
        max-height:48rem;
        width:100%;
        bottom:0;
        left:0;
        transform:translateY(100%);
        position: absolute;
        background: #FFFFFF;
        border: 0.1rem solid #E5F0FF;
        border-radius: 0px 0px 0.4rem 0.4rem;
        transition: 0.25s ease-in;
        visibility:hidden;
        opacity:0;
        z-index:2;
        display: flex;
        flex-direction: column;
`
export const CardHeader = styled.div`
   padding: 1.2rem;
`
export const HeaderFilterDropdown = styled.div`
        position: relative;
        box-shadow:${({ open }) => open ? '0px 0px 0.8rem rgba(21, 27, 38, 0.12)' : ''};
        ${({ open }) => open && HeaderFilterButton}{
             border-bottom-right-radius: 0;
             border-bottom-left-radius: 0;
             >.refresh svg{
                transform: rotate(180deg);
            }
        } 
        ${({ open }) => open && HeaderFilterCard}{
             visibility: visible;
             opacity: 1;
             box-shadow:${({ open }) => open ? '0px 0px 0.8rem rgba(21, 27, 38, 0.12)' : ''};
        }
    @media(max-width: 1100px){
        width: 100%;
    }
    ${HeaderFilterButton}{
        @media(max-width: 740px){
            font-size: 14px;
            padding: 12px;
            .refresh{
                height: 16px;
                width: 16px;
                display: inline-block;
                svg{
                    margin-left: 0;
                    height: 100%;
                    width: 100%;
                    transform: rotateZ(270deg);
                }
            }
            &.active{
                .refresh{
                    svg{
                        transform: rotateZ(90deg);
                    }
                }
            }
        }
    }
        
`
export const SearchBox = styled.div`
 position: relative;
 input{
    width:100%;
    height:3.4rem;
    display: flex;
    background: #FFFFFF;
    border: 0.1rem solid #CBCFD2;
    border-radius: 0.4rem;
    font-weight: 400;
    font-size: 1.4rem;
    padding:0.5rem 3.4rem;
    color:#4D4D4F;
    outline:0;
    ::placeholder{
        color:#7D868C;
    }
 }
 .search--icon{
        position:absolute;
        top:50%;
        left:0.95rem;
        transform: translateY(-50%);
        line-height:0;
        svg{
            width:1.6rem;
        }
  }
 .search--clear{
        position:absolute;
        top:50%;
        right:0.95rem;
        transform: translateY(-50%);
        line-height:0;
        cursor: pointer;
        svg{ 
            width:1.6rem;
            *{
                transition:0.25s ease-in;
            }
        }
        :hover{
            svg{
                circle{
                    fill:#E5F0FF;
                    stroke:#118ACB;
                }
                path{
                    fill:#118ACB;
                }
            } 
        }
  }
`

export const CardBody = styled.div`
        flex:1;
        overflow-y:auto;
        overflow-x: hidden;
        padding-left:1.2rem;
        padding-right:1.2rem;
`;
export const FilterList = styled.label`
     display:flex;
     align-items: center;
     padding:0.7rem 0.6rem;
     border-radius:0.2rem;
     cursor: pointer;
    i{
        width:1.4rem;
        line-height:0;
        svg{
            width:1.4rem;
            rect,path{
                fill:transparent;
            }
        }
    }
    span{
        max-width: calc(100% - 1.5rem);
        padding-left:1rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #4D4D4F;
    }
    input{
        height:0;
        width:0;
        opacity:0;
        &:checked{
           ~ i{
                
                svg{
                    
                    rect{
                        fill:#118ACB;
                    }
                    
                    path{
                        fill:#fff;
                    }
                }
            }
        }
    }
    &:hover{
        background:  #E5F0FF;
    }
`

export const CardFooter = styled.div`
        background: #FFFFFF;
        box-shadow: 0px -0.2rem 0.8rem rgba(0, 0, 0, 0.08);
        border-radius: 0px 0px 0.4rem 0.4rem;
        height:7.6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding:0.5rem 2rem;
`
export const ApplyFilterButton = styled.div`
        font-weight: 400;
        font-size: 1.4rem;
        color:#fff;
        background: linear-gradient(91.83deg, #139BD6 12.22%, #0789CF 100.41%);
        border-radius: 0.4rem;
        padding:1rem 2rem;
        display:inline-flex;
        cursor: pointer;
        transition: 0.25s ease-in;
        :hover{
            background:linear-gradient(91.83deg, #118ACB 12.22%, #0473AE 100.41%);
        }
`

export const Container = styled.div`
    padding-top:3rem;
    margin-right:-2.6rem;
    padding-right:2.6rem;
    overflow: auto;
    flex:1;
    >.loading-h3{
        display: block;
        position: relative;
        text-indent:99999999rem;
        overflow: hidden;
        height: 3rem;
        &:before {
            content: '';
            width: 2rem;
            height: 2rem;
            position: absolute;
            display: inline-block;
            top: 50%;
            border-radius: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border: 0.3rem solid rgba(0,0,0,.3);
        }
        &:after{
            content: '';
            width: 2rem;
            height: 2rem;
            position: absolute;
            display: inline-block;
            top: 50%;
            border-radius: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border: 0.3rem solid #118acb;
            border-left-color:transparent;
            animation:loader 0.3s linear infinite;
        }
        @keyframes loader {
          from {transform:translate(-50%,-50%) rotate(0deg)}
          to {transform:translate(-50%,-50%) rotate(360deg);}
        }

    }
`
export const ScrollWrapper=styled.div`
    min-width:1100px;
    @media(max-width: 740px){
        min-width: auto;
        width: 100%;
    }
`
export const TableCard = styled.div`
    margin-bottom:5rem;
    &.walk-1{
        &.walkthrough-active{
            position: relative;
            z-index: 999;
            &::after{    
                content: '';
                position: absolute;
                top: -0.5rem;
                left: 0;
                bottom: -0.5rem;
                width: 100%;
                height: 100%;
                background: #ffffff;
                border-radius: 0.8rem;
                z-index: -1;
                padding: 1rem;
            }
        }
    }
    &.walk-2{
        &.walkthrough-active{
            position: relative;
            z-index: 999;
            &::after{    
                content: '';
                position: absolute;
                top: -0.5rem;
                left: 0;
                bottom: -0.5rem;
                width: 100%;
                height: 100%;
                background: #ffffff;
                border-radius: 0.8rem;
                z-index: -1;
                padding: 1rem;
            }
        }
    }
    &.empty-walk{
        &.walkthrough-active{
            position: relative;
            z-index: 999;
            &::after{    
                content: '';
                position: absolute;
                top: -0.5rem;
                left: 0;
                bottom: -0.5rem;
                width: 100%;
                height: 100%;
                background: #ffffff;
                border-radius: 0.8rem;
                z-index: -1;
                padding: 1rem;
            }
        }
    }
    &.with-assigned-to-col{
        width:100% !important;
        ${PlatFormToolCol}{
            width:${singleColWidth * 1.5}%;
        }
        ${NameCol}{
            width:${singleColWidth * 2.5}%;
        }
        ${UpdatedCol}{
            width:${singleColWidth * 1}%;
        }
        ${AssignedCol}{
            width:${singleColWidth * 2}%;
        }
        ${StatusCol}{
            width:${singleColWidth * 1.5}%;
        }
        ${ActionCol}{
            width:${singleColWidth * 2}%;
        }
    }
    .collapse-btn{
        .angle-icon{
            svg{
                transition: 0.15s ease-in-out;
                rotate: 180deg;
            }
        }
        &.active{
            .angle-icon{
                svg{
                    rotate: 0deg;
                }
            }
        }
    }
`
export const TableCardeHeading = styled.h1`
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1.5rem;
        margin-bottom:2rem;
        padding-left: 0.5rem;
        i{
            margin-right:1.2rem;
            line-height: 0;
            svg{
                width: 2.4rem;
            }
        }
        span{
            color:${({learning,plant})=>learning?'#389F87':plant?'#118ACB':''};
        }
`
export const TableCardHeader = styled.div`
    border-bottom: 0.1rem solid #E5F0FF;
    display: flex;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #4D4D4F;
    padding-bottom: 1.3rem;
    margin-bottom:1.3rem;

`
export const TablePlantName = styled.div`
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: 2rem;
        gap: 1rem;
        cursor: pointer;
        @media(max-width: 740px){
            position: relative;
        }
        .flag-img{
            display: inline-block;
            width: 2rem;
            
            img{
                width: 2rem;
            }
        }
        .angle-icon{
            display: inline-block;
            @media(max-width: 740px){
                position: absolute;
                top: 0.8rem;
                right: 0.8rem;
            }
            svg{
                transition: 0.15s ease-in-out;
                rotate:${({active})=>active?'0deg':'180deg'};
            }
            &.sm-hide{
                @media(max-width: 740px){
                    display: none;
                }
            }
            &.sm-visible{
                display: none;
                @media(max-width: 740px){
                    display: inline-block;
                    height: 2.4rem;
                    width: 2.4rem;
                    svg{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
`
export const TableCardBody = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 150%;
    color: #4D4D4F;
    padding-top:0rem;
    align-items: center;
    &:not(:last-of-type){
        padding-bottom: 1.4rem;
    }
    @media(max-width: 740px){
        display: none;
    }
    &.active{
        @media(max-width: 740px){
            display: block;
            position: fixed;
            background: #ffffff;
            height: calc(100% - 48px);
            top: 48px;
            padding: 16px;
            left: 0;
            z-index: 101;
            overflow-y: auto;
            padding-bottom: 180px;
            width: 100%;
            .sm-visible{
                &.h2{
                    color: #4D4D4F;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: normal;
                    display: block;
                    margin-bottom: 6px;
                }
            }
            ${TablePlantName}{
                &.mobile-active{
                    display: flex;
                    align-items: center;
                    padding-bottom: 16px;
                    background: transparent;
                    padding: 0 0 16px 0;
                    .flag-img{
                        width: 22px;
                        img{
                            width: 100%;
                        }
                    }
                    p{
                        span{
                            font-size: 18px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }
    }
`
export const TableCardRow = styled.div`
    padding-left:3.6rem;
    &:not(:first-of-type){
            margin-top:4rem;
    }
    .collapse-content-wrapper{
        margin-bottom: 4rem;
    }
    @media(max-width: 740px){
        padding-left: 8px;
        margin-bottom: 12px;
    }
    ${TablePlantName}{
        @media(max-width: 740px){
            padding: 10px 12px;
            border-radius: 4px 4px 0px 0px;
            background: #E5F0FF;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            .flag-img{
                line-height: 0;
            }
        }
    }
`

export const StausLabel = styled.div`
    display:inline-block;
    padding:0.5rem 1rem;
    border-radius:0.2rem;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 1.4rem;
    text-transform:capitalize;
    background:${({red,green,yellow})=>red?'#DB324D':green?'#4CAF50':yellow?'#F58B00':''};
    @media(max-width: 740px){
        font-size: 10px;
        padding: 3px 6px;
    }
`
export const TableDefaultCol = styled.div`
        padding-right: 1.5rem;
`
export const PlatFormToolCol = styled(TableDefaultCol)`
        width:${singleColWidth * 2}%;
        @media(max-width: 740px){
            font-size: 14px;
            font-weight: 400;
            padding-top: 16px;
            padding-bottom: 16px;
            border-top: 1px solid #F0F0F0;
            width: 100%;
        }
`
export const NameCol = styled(TableDefaultCol)`
        width:${singleColWidth * 3}%; 
        @media(max-width: 740px){
            font-size: 14px;
            font-weight: 400;
            padding-top: 16px;
            padding-bottom: 16px;
            border-top: 1px solid #F0F0F0;
            width: 100%;
        }    
        .no-task-available{
            font-size: 1.4rem;
            display:block;
            margin-bottom:1.4rem;
        }   
`
export const UpdatedCol = styled(TableDefaultCol)`
        width:${singleColWidth * 1}%;
        @media(max-width: 740px){
            font-size: 14px;
            font-weight: 400;
            padding-top: 16px;
            padding-bottom: 16px;
            border-top: 1px solid #F0F0F0;
            width: 100%;
        }
`
export const AssignedCol = styled(TableDefaultCol)`
        width:${singleColWidth * 2}%;
        @media(max-width: 740px){
            font-size: 14px;
            font-weight: 400;
            padding-top: 16px;
            padding-bottom: 16px;
            border-top: 1px solid #F0F0F0;
            width: 100%;
        }
`
export const StatusCol = styled(TableDefaultCol)`
        width:${singleColWidth * 2}%;
        @media(max-width: 740px){
            font-size: 14px;
            font-weight: 400;
            padding-top: 16px;
            padding-bottom: 16px;
            border-top: 1px solid #F0F0F0;
            width: 100%;
            ${StausLabel}{
                font-size: 14px;
                border-radius: 2px;
                padding: 5px 10px;
            }
        }
`
export const ActionCol = styled.div`
        width:${singleColWidth * 2.5}%;
        display: flex;
        gap: 10px;
        @media(max-width: 740px){
            position: fixed;
            font-size: 16px;
            font-weight: 600;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            margin: 0;
            padding: 16px;
            background: #fff;
            z-index: 1;
            &.active{
                z-index: 10;
            }

        }
        @media(max-width:575px){
            flex-direction: column;
        }
`
export const AssignedUser = styled.div`
   display:flex;
   align-items:center;
    i{
        display:inline-flex;
        position:relative;
        align-items: center;
        justify-content:center;
        color: #FFFFFF;
        background:#118ACB;
        font-weight: 500;
        font-size: 1rem;
        line-height:2rem;
        font-style: normal;
        width:2.2rem;
        height:2.2rem;
        border-radius: 50%;
        text-transform:uppercase;
        overflow: hidden;
        .taskcenter_assigned__img_cir{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &.null{
            background-color: #f0f0f0;
             color: #7D868C;
             border: 0.1rem dashed #7D868C;
        }
    }
    span{
        padding-left:1rem;
        width:calc(100% - 2rem);
    }
`
export const AssignedToCol = styled(TableDefaultCol)`
        width:${singleColWidth * 1.5}%;
        @media(max-width: 740px){
            border-top: 1px solid #F0F0F0;
            padding-top: 16px;
            padding-bottom: 16px;
            font-size: 14px;
            width: 100%;
        }
        .assigned-to-cir-wrapper{
            display: inline-flex;
            position: relative;
            ${AssignedUser}{
                cursor: pointer;
                &:not(:first-child){
                    margin-left: -0.4rem;
                }
            }
            .assigned-to-count{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 2.2rem;
                width: 2.2rem;
                border: 0.1rem solid #118ACB;
                background: #ffffff;
                font-weight: 600;
                font-size: 1rem;
                border-radius: 50%;
                color: #118acb;
                cursor: pointer;
                margin-left: -0.4rem;
                z-index: 1;
            }
            .tooltip{
                color: #118acb;
                display: inline-block;
                position: absolute;
                bottom: -3.5rem;
                font-weight: 500;
                font-size: 1.2rem;
                white-space: nowrap;
                pointer-events: none;
                border: 0.06rem solid #118ACB;
                border-radius: 0.2rem;
                background: #ffffff;
                z-index: 2;
                padding: 0.4rem 0.8rem;
                left: 50%;
                transform: translateX(-50%);
                visibility: hidden;
                opacity: 0;
            }
            .tooltip.active{
                display: none;
                opacity: 0;
                visibility: hidden;
            }
            &:hover{
                .tooltip{
                    opacity: 1;
                    visibility: visible;
                }
            }

        }
        
`
export const DropUserCard = styled.div`
    display: flex;
    width: 26.5rem;
    opacity: ${({active})=>active?'1':'0'};
    background: #FFFFFF;
    box-shadow: 0px 0px 0.8rem rgba(21, 27, 38, 0.12);
    border-radius: 0.6rem;
    padding: 2rem 1.2rem;
    position: absolute;
    visibility: ${({active})=>active?'visible':'hidden'};
    z-index: 5;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    &:after{
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
    }
    .max-height-container{
        display: flex;
        width: 100%;
        max-height: 21.6rem;
        overflow: hidden;
        ${AssignedUser}{
            &:not(:first-child){
                margin-left: 0 !important;
            }
        }
    }
    .user-listwrap{
        position: relative;
        overflow-y: auto;
        width: 100%;
        z-index: 99;
        
        ${AssignedUser}{
            cursor: auto;
            margin-bottom: 2rem;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            &:last-child{
                margin: 0;
            }
            i{  
                height: 3rem !important;
                width: 3rem !important;
            }
        }
        .txt-wrap{
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex: 1;
            .head{
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                display: block;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 1.7rem;
                color: #455A64;
                white-space: nowrap;
            }
            .sub-txt{
                display: block;
                font-weight: 400;
                font-size: 1.1rem;
                line-height: 1.3rem;
                color: #455A64;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
            }
        }
    }
`


export const ActionNeededLink = styled(Link)`
  display: flex;
  padding: 0.5rem 1rem;
  background: #e5f0ff;
  box-shadow: 0px 0px 0.2rem rgba(21, 27, 38, 0.12);
  border-radius: 0.4rem;
  align-items: center;
  justify-content: center;
  width: max-content;
  transition: 0.25s ease-in;
  color: #118acb;
  position: relative;
  overflow: hidden;
  @media (max-width: 740px) {
    width: 100%;
    padding: 1.4rem 1rem;
    background: #118acb;
    color: #ffffff;
  }
  :before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(91.83deg, #118acb 12.22%, #0473ae 100.41%);
    z-index: 0;
    clip-path: circle(0);
    transition: 0.25s ease-in;
  }
  i {
    display: inline-block;
    margin-left: 1rem;
    height: 1.2rem;
    width: 1.2rem;
    position: relative;
    line-height: 0;
    svg path {
      transition: 0.25s;
    }
  }

  span {
    display: inline-block;
    font-weight: 500;
    font-size: 1.2rem;
    position: relative;
    @media (max-width: 740px) {
        font-size: 1.6rem;
    }
  }
  :hover {
    background: transparent;
    color: #fff;
    &::before {
      clip-path: circle(100%);
    }
    i svg path {
      fill: #fff;
    }
  }
`;


export const FlagLabel = styled.span`
    font-weight: ${({head,country})=>head?'600':country?'400':''};
    font-size: ${({head,country})=>head?'1.4rem':country?'1.2rem':''};
    line-height: 1.2rem;
    letter-spacing: 0.02em;
    color: #0D2C54;
`
// Empty Plant Section
export const PlantTableBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .plant-svg{
        margin-bottom: 2.4rem;
        width: 20rem;
        svg{
            width: 100%;
        }
    }
    .plant-head{
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.8rem;
        letter-spacing: 0.02em;
        margin-bottom: 1.2rem;
        text-align: left;
    }
    .plant-txt{
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.8rem;
        letter-spacing: 0.02em;
        text-align: left;
        margin-bottom: 4rem;
    }
`
export const PlantBtnWrap = styled.div``
export const AddBtn = styled.button`
    background: #118ACB;
    border-radius: 0.4rem;
    border-color: transparent;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.8rem;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 1.2rem 2.4rem;
`



export const ReminderPopup=styled.div`
    min-height: 20rem;
    padding: 2.4rem 4.3rem 1.6rem 4.3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    border: 0.1rem solid #8CDCD2;
    border-radius: 1.6rem;
    filter: drop-shadow(0px 0px 18px rgba(77, 77, 79, 0.25));
    width: 24rem;
    z-index:999;
    .reminder-success-img{
      height: 12rem;
      width: 12rem;
      img{
        height: 100%;
        width: 100%;
      }
    }
    p{
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #0D2C54;
      text-align: center;
    }
  `