import React, { useState, useEffect } from 'react';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MainWrapper, H1, H4, H5, P, P500, PBlue, I, NextButton } from './styles';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import serviceProvider from '../../services/axios';
import { Helmet } from "react-helmet";
export const PlantCostView = (props) => {

    const dataReturn = props.results[0];
    const plant_name = dataReturn.plant_name;
    const plant_slug = dataReturn.plant_slug;
    const plant_id = dataReturn.plant_id;
    const chapter_id = dataReturn.chapter_id;





    return (
        <MainWrapper data-component="MainWrapper">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{plant_name}</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/${plant_slug}`}>Assessment</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/chapter/${plant_slug}/10`}>Plant Cost Profile</BreadcrumbsItem>
            <BreadcrumbsItem to="#">Plant Cost</BreadcrumbsItem>
            <H1 data-component="H1">
                PLANT COST
            </H1>
            {/* H1 end */}
            <H4 data-component="H4">
                Cost Profile for Assessment
            </H4>
            {/* H4 end */}

            <P data-component="P" className='p1'>
                The Cost Profile helps to Analyse how distinct Industry 4.0 areas affect profits and identify those that can generate the greatest financial return. The Cost profile is critical to the assessment score and must be completed
            </P>
            {/* P end */}
            <P data-component="P">
                The calculation is very simple:
            </P>
            {/* P end */}
            <I data-component="I">
                (Cost Category Expense/ Total Revenue)*100 = Cost Profile for the Cost Category Chosen
            </I>
            {/*I end */}
            <P data-component="P">
                Note: Their 10 Cost Categories, this calculation needs to be done for each category, on the next page all the Cost Categories with their description is available.
            </P>
            {/* P end */}
            <H5 data-component="H5">
                Data from the last 12 months should be used to calculate the cost profiles
            </H5>
            {/* H5 end */}
            <P500 data-component="P500">
                Example:
            </P500>
            {/* P500 end */}
            <PBlue data-component="PBlue">
                The Cost Profile of a company refers to the breakdown of a company’s costs as percentages of its overall revenue. For instance, if a company has an annual revenue of $100 million and spends $15 million on maintenance and repairs, then maintenance and repair costs can be represented as 15 per cent of the company’s overall revenue.
            </PBlue>
            {/* PBlue end */}
            <PBlue data-component="PBlue">
                Calculation:
            </PBlue>
            {/* PBlue end */}
            <PBlue data-component="PBlue">
                (15,000,000/100,000,000)* 100= 15% for maintenance and repair costs profile
            </PBlue>
            {/* PBlue end */}

            <P data-component="P" className='bottom-p'>
                Note: We do not need the exact amount of any cost categories, all we need is the the <b>% of the cost category with regards to the overall revenue.</b> This is hopefully a very easy exercise, because all these cost categories are tracked in your organization
            </P>
            {/* P end */}

            <NextButton as={Link} to={`/plant-cost-percent-value/${plant_slug}/${chapter_id}`} data-component="NextButton">
                Next
            </NextButton>

        </MainWrapper>
    );
}