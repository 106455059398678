import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { SiriMainView } from './SiriMainView';
import serviceProvider from '../../services/axios'
export const SiriMainCtrl = (props) => {
  
  const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	const slug = getLastItem(window.location.pathname);
  const [results, setResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;

  
useEffect(() => {
  const search = async () => {
    const apiUrl = `/siri?param=section&slug=${slug}&uid=${usid}`;
    const { data } = await serviceProvider.get(apiUrl, true, {});
    setResults(data);
  };
  search();
},[]);


      if (results.length) {
                return (
                  <>
                    <SiriMainView siriData={results} {...props}/>
                  </>
                );
      }else{
              return (
                <>
                  <div></div>
                </>
              );

      }
}




