import React, { useState, useEffect, useRef } from 'react';
import{HomeTourControler} from'../onboarding-home-tour/home-tour-ctrl';
import { LearningLossAnalysisWalkthrough} from "../onboarding-learning-resource-walkthrough/loss-analysis-walkthrough.js";
import { LearningProductTourView } from "../onboarding-learning-resource-product-tour/learning-product-tour-view.js";
import {
    TabButton,BlueFooter,ActionLink,FooterLeft,

} from './styled';
import { useHistory } from 'react-router';
import { Helmet } from "react-helmet";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

export const PlatformOnboradingTour = (props) => {
    // tabs code
    const [value, setValue] = React.useState(0);
    const history = useHistory();
    const tourArray = ['homeTour','lossTour','lossResultTour','roadmapTour']
    const [currentTour,setCurrentTour]=useState(tourArray[0]);
    const handleClose =()=>{
        history.push('/');
    }
    // Tabs
    const handleChange = (event,newValue) => {
        setValue(newValue);
     };
     function a11yProps(index) {
         return {
           id: `simple-tab-${index}`,
           'aria-controls': `simple-tabpanel-${index}`,
         };
       }
    return(
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>

            {currentTour==tourArray[0]?
            <HomeTourControler isTour={true} handleClose={handleClose} tourArray={tourArray} setCurrentTour={setCurrentTour}/>
            :currentTour===tourArray[1]?
            <LearningLossAnalysisWalkthrough activeSlide={1} handleClose={handleClose} tourArray={tourArray} setCurrentTour={setCurrentTour}/>
            :currentTour===tourArray[2]?
            <LearningLossAnalysisWalkthrough activeSlide={2} handleClose={handleClose} tourArray={tourArray} setCurrentTour={setCurrentTour}/>
            :currentTour===tourArray[3]?
            <LearningProductTourView handleClose={handleClose} tourArray={tourArray} setCurrentTour={setCurrentTour}/>
            :''
        }
        
        <BlueFooter data-component="BlueFooter" style={{visibility:currentTour===tourArray[0]?'hidden':''}}>
            <FooterLeft data-component="FooterLeft">
                <Tabs
                        orientation="horizontal"
                        variant="scrollable"
                        // value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        
                        >
                        {/* <Tab className={`tab-btn ${currentTour===tourArray[1]?'active':''}`} label="IR4.0 Training Courses" {...a11yProps(0)} onClick={()=>setCurrentTour(tourArray[1])}/> */}
                        {/* <Tab className={`tab-btn ${currentTour===tourArray[1]?'active':''}`} label="IR4.0 Technology Assessment" {...a11yProps(0)} onClick={()=>setCurrentTour(tourArray[1])}/>                                        
                        <Tab className={`tab-btn ${currentTour===tourArray[2]?'active':''}`} label="IR4.0 Technology Assessment Results" {...a11yProps(1)} onClick={()=>setCurrentTour(tourArray[2])}/> */}
                        <Tab className={`tab-btn ${currentTour===tourArray[1]?'active':''}`} label="IR4.0 Loss Analysis" {...a11yProps(0)} onClick={()=>setCurrentTour(tourArray[1])}/>
                        <Tab className={`tab-btn ${currentTour===tourArray[2]?'active':''}`} label="IR4.0 Loss Analysis Results" {...a11yProps(1)} onClick={()=>setCurrentTour(tourArray[2])}/>
                        <Tab className={`tab-btn ${currentTour===tourArray[3]?'active':''}`} label="IR4.0 Strategy" {...a11yProps(2)} onClick={()=>setCurrentTour(tourArray[3])}/>
                </Tabs>
            </FooterLeft>
            <ActionLink to="/" className="exit-link">Exit this demo tour</ActionLink>
        </BlueFooter>
        </>
    );
}

