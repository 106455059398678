/** imported components
 
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * AllquestionsPlantView ===> view file of the page.
  * fileName.scss for ===> styling css file for the page.
  * serviceProvider for ===> working with api
  
**/

import React, { useState, useEffect } from "react";
import { AllquestionsPlantView } from "./allquestions-plant-view";
import "./question.scss";
import serviceProvider from '../../services/axios'

// All questions component has been started
export const AllquestionsPlantController = (props) => {
	const [results, setResults] = useState([]);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const [onTab, setOnTab] = useState(0);
	const segments = window.location.pathname.slice(1).split("/");
	const slug = segments[2];
	const cid = segments[3];
	useEffect(() => {
		const search = async () => {
			const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const cid = getLastItem(window.location.pathname);
			// id=${cid}
			const apiUrl = `/assessmentmaturity?param=answered&slug=${slug}&id=${cid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
		};
		search();
	}, []);
	return <AllquestionsPlantView slug={slug} sectionData={results} onTab={onTab} {...props} />;
};
