import styled from "styled-components";


export const MainWrapper = styled.div`
        padding:3rem 3rem 3rem 3rem;
        max-width:101.7rem;
        @media(max-width:740px){
            padding-bottom:100px;
        }
        *{
            line-height:150%;
        }
`;

export const H1 = styled.h1`
    color:#4D4D4F;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 150%;
    margin-bottom:2rem;
`;

export const H4 = styled.h4`
    color:#4D4D4F;
    font-size: 1.6rem;
    font-weight: 500;
    line-height:normal;
    margin-bottom:0.8rem;
`
export const H5 = styled.h5`
    color:#4D4D4F;
    font-size: 1.4rem;
    font-weight: 600;
    line-height:normal;
    margin-bottom:1.8rem;
`
export const P = styled.p`
    color:#4D4D4F;
    font-size: 1.4rem;
    font-weight: 400;
    line-height:normal;
    margin-bottom:0.8rem;
    b{
        font-weight:600;
    }
    &.p1{
        margin-bottom:2.4rem;
    }
    &.bottom-p{
        margin-top:2.4rem;
    }
`
export const P500 = styled.p`
    color:#4D4D4F;
    font-size: 1.4rem;
    font-weight: 500;
    line-height:normal;
    margin-bottom:0.8rem;
`
export const PBlue = styled.p`
    color:#118ACB;
    font-size: 1.4rem;
    font-weight: 500;
`
export const I = styled.i`
    color:#DB324D;
    font-size: 1.4rem;
    font-weight: 500;
    line-height:normal;
    margin-bottom:0.8rem;
    display:block;
`
export const NextButton = styled.button`
    display:inline-flex;
    align-items:center;
    justify-content:center;
    color: #FFF;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    position:fixed;
    bottom:7rem;
    right:3rem;
    min-width:29.7rem;
    min-height:6rem;
    padding:0.5rem;
    background:#118ACB;
    border-radius:0.5rem;
    z-index:10;
    @media(max-width:768px){
        bottom: 0px;
        width: calc(100% - 30px);
        left: 50%;
        transform: translateX(-50%);
    }

    @media(max-width:575px){
        min-width:18rem;
    }
`

