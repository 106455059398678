export const closeIcon = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="2" fill="#F0F0F0"/>
<path d="M11.1 22.3C10.7134 22.6866 10.0866 22.6866 9.7 22.3C9.3134 21.9134 9.3134 21.2866 9.7 20.9L13.8929 16.7071C14.2834 16.3166 14.2834 15.6834 13.8929 15.2929L9.7 11.1C9.3134 10.7134 9.3134 10.0866 9.7 9.7C10.0866 9.3134 10.7134 9.3134 11.1 9.7L15.2929 13.8929C15.6834 14.2834 16.3166 14.2834 16.7071 13.8929L20.9 9.7C21.2866 9.3134 21.9134 9.3134 22.3 9.7C22.6866 10.0866 22.6866 10.7134 22.3 11.1L18.1071 15.2929C17.7166 15.6834 17.7166 16.3166 18.1071 16.7071L22.3 20.9C22.6866 21.2866 22.6866 21.9134 22.3 22.3C21.9134 22.6866 21.2866 22.6866 20.9 22.3L16.7071 18.1071C16.3166 17.7166 15.6834 17.7166 15.2929 18.1071L11.1 22.3Z" fill="#0D2C54"/>
</svg>
`;

export const dropdownIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M10.9497 6.47485L6.47484 10.9497L1.99997 6.47485" stroke="#026EC2" stroke-width="1.5" stroke-linecap="round"/>
</svg>`;

export const searchIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.53022 8.46966C8.82311 8.17677 9.29798 8.17677 9.59088 8.46966L12.5302 11.409C12.8231 11.7019 12.8231 12.1768 12.5302 12.4697C12.2373 12.7626 11.7624 12.7626 11.4696 12.4697L8.53022 9.53032C8.23732 9.23743 8.23732 8.76255 8.53022 8.46966Z" fill="#7D868C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5ZM5.5 11C8.53757 11 11 8.53757 11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11Z" fill="#7D868C"/>
</svg>`