import React from "react";
import { LossAssumptionResultSetupView } from "./LossAssumptionResultSetupView";
import { useEffect, useState } from 'react';
import { useHistory, Link, Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios'
import {
    PopupContainer,
    PopupCard,
    PopupCardHeader,
    PopupCardBody,
    HeaderH1,
    HeaderButtons ,
    PopupCloseButton,
    BackBtnWrap,
} from './styles'
import {
    closeSvg,
    backBtnSvg,
} from "./svg";
export const LossAssumptionResultSetupCtrl = (props) => {

    const objectdata = props.lossAssumptionPopupData;
    if (Object.keys(objectdata || {}).length == 0) {
        return null;
    }
    // const tmpSetUpType = (objectdata.sid != 101 && objectdata.sid != 103) ? 1 : 0;
    const tmpSetUpType = false;
    const tmpScenario = props.tmpScenario;
    const [resultssetup, setResultssetup] = useState([]);
    const user = JSON.parse(localStorage.getItem("userData"));
    const [isSetUpType, setIsSetupType] = useState(tmpSetUpType);
    const usid = user ? user.uid : 0;
    const group_id = user ? user.group_id : 0;

    useEffect(() => {
        const search = async () => {
            if (Object.keys(objectdata || {}).length > 0) {
                setResultssetup(prv=>false);
                const apiUrl = `/businesscaseplantloss?param=losssetup&slug=${props.pid}&uid=${usid}&sid=${objectdata.sid}&ssid=${objectdata.ssid}&scid=${tmpScenario.id}`;
                const { data } = await serviceProvider.get(apiUrl, true, {});
                setResultssetup(data);
            }
        };
        search();
    }, [props.lossAssumptionPopupData]);


    if (resultssetup.length) {
        return (
            <>
                <LossAssumptionResultSetupView uid={usid} pid={props.pid} sid={objectdata.sid} ssid={objectdata.ssid} closeAssumptionPopup={props.closeAssumptionPopup} plantName={props.plantName} resultssetup={resultssetup} isSetUpType={isSetUpType} setIsSetupType={setIsSetupType} {...props} />
            </>
        );


    } else {

        return (
            <>
                <PopupContainer data-component="PopupContainer">
                    <PopupCard data-component="PopupCard">
                        <PopupCardHeader data-component="PopupCardHeader">
                            <HeaderH1 data-component="HeaderH1">
                                <BackBtnWrap data-component="BackBtnWrap">
                                    <button className="back-btn">
                                        <i dangerouslySetInnerHTML={{ __html: backBtnSvg }}></i>
                                        <span>Back</span>
                                    </button>
                                    <p>{props.plantName}: Loss Elimination Set Up</p>
                                </BackBtnWrap>
                            </HeaderH1>
                            <HeaderButtons data-component="HeaderButtons">
                                <PopupCloseButton data-component="PopupCloseButton">
                                    <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                                </PopupCloseButton>
                            </HeaderButtons>
                        </PopupCardHeader>
                        <PopupCardBody data-component="PopupCardBody">
                        </PopupCardBody>
                    </PopupCard>
                </PopupContainer>
            </>
        );
    }
}