import React, { useState, useEffect } from "react";
import { SidebarControler } from "../components/sidebar/sidebar-ctrl";
import { HeaderControler } from "../components/header/header-ctrl";
import "./animated.css";
import { makeStyles } from "@material-ui/core/styles";
import { VideoPopupControler } from "../components/video-popup/video-popup-ctrl";
import { Helmet } from "react-helmet";
const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 99,
  },
  colorPrimary: {
    backgroundColor: "#DCEDF7",
  },
  barColorPrimary: {
    backgroundColor: "#178ACB",
  },
});
export const SinglePageLayout = (props) => {
  const [isPopup, setIsPopup] = useState(false);
  const [isInitialScale, setIsinitialScale] = useState(0);
  const [showSidebarPopUp, setshowSidebarPopUp ] = useState(props.quiz_page? false: true);
  const HandlePopup = () => {
    setIsPopup(!isPopup);
  };
  console.log("fromsinglepagelayout",'tp');
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(true);
  };
  const handleExpandFal = () => {
    setExpanded(false);
  };
  const handleChange = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsinitialScale(1);
    }
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content={`${isInitialScale?`width=device-width, initial-scale=1`:''}`}
        />
      </Helmet>
      <div className="training-main-wrapper">
        <SidebarControler
          handleChange={handleChange}
          handleExpand={handleExpand}
          handleExpandFal={handleExpandFal}
          expanded={expanded}
		   showSidebarPopUp={showSidebarPopUp}
          isQuizPage={props.quiz_page}
          {...props}
        />
        <div className="training-layout-wrapper">
          <header className="training-header">
            <HeaderControler
              isHeaderProgress={props.isHeaderProgress}
              {...props}
            />
          </header>
          {/* training-header end */}
          <section className="training-content">
            <props.children
              handleChange={handleChange}
              handleExpand={handleExpand}
              handleExpandFal={handleExpandFal}
              expanded={expanded}
			  showSideBar={setshowSidebarPopUp}
              HandlePopup={HandlePopup}
              {...props}
            />
          </section>
        </div>
        {/*  */}
      </div>
      {/* training-main-wrapper end */}
      
     
    </React.Fragment>
  );
};

