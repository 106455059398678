
import React, { useState, useEffect } from "react";
import { MaturityDashboardPageView } from "./maturity-dashboard-page-view";
import serviceProvider from '../../services/axios'
import { Link, Redirect } from "react-router-dom";
export const MaturityDashboardPageController = (props) => {

  const [results, setResults] = useState([]);
  const [tourComplete, setTourComplete] = useState("");
  
  const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
if (1) {
    // window.location = "/cartmat-Full-Maturity-Assessment";
    return <Redirect to="/home" />;
  }
  useEffect(() => {
		props.handleExpandFal();
		const search = async () => {
  
     

			const apiUrl = `/home?param=homedata&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true,{});
      console.log(data);
			setResults(data);
		};
		search();
	}, [tourComplete]);

//   useEffect(() => {
//   const loadCountries = async () => {
//    
//     const data = result.data
//     // const options = data.map(d => ({
//     //   "value": d.id,
//     //   "name": d.country
//     // }))
//     console.log("sectionid-",data)
//     setMaturityData(options);
//   };
// }, []);
  return (
    <>
      {/* <MaturityDashboardPageView {...props} /> */}
      <MaturityDashboardPageView homeData={results} onTourComplete={setTourComplete} {...props} />
    </>
  );
};
