/** imported components

  * serviceProvider for ==> working with api.
  * scss for ==> styling css file for this page.
  * BreadcrumbsItem for ==> for dynamic breadcrumb.
  * Helmet ==> for add specific title & CSS for the page.
  * CartPaymentViewMaturity ==> for customm component.

**/
import React, { Component } from "react";
import { number } from "yup";
import serviceProvider from "../../services/axios";
import { CartPaymentViewMaturity } from "./paymentmaturity-view";
import "../cart.scss";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
// Define variables
const defaultCountryList = [
  {
    id: 1,
    code: 202,
    name: "Afghanistan",
  },
  {
    id: 2,
    code: 202,
    name: "Albania",
  },
  {
    id: 3,
    code: 202,
    name: " Algeria",
  },
  {
    id: 4,
    code: 202,
    name: "Andorra",
  },
  {
    id: 5,
    code: 202,
    name: "United Kingdom",
  },
];
// CartPaymentControler start
export class CartMatPaymentControler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contryList: defaultCountryList,
      paymentCountry: "",
      paymentDdl: false,
      redirect: false,
      paidItem: 0,
    };
  }

  // funtion to open and close dropdown list
  HandlePaymentDdlOnClick = (e) => {
    if (e === "close") {
      this.setState({
        paymentDdl: false,
      });
    } else {
      let val = !this.state.paymentDdl;
      this.setState({
        paymentDdl: val,
      });
    }
  };
  // funtion to select  payment form country value
  HandlePaymentDdlValueOnClick = (val) => {
    this.setState({
      paymentCountry: val,
    });
  };

  // funcion to filter country on search
  HandleOnSearch = (e) => {
    const val = e.target.value.toLowerCase();
    const filterData = this.state.contryList.filter((item) => {
      let rgx = new RegExp(val);
      return rgx.test(item.name.toLowerCase());
    });
    if (val) {
      this.setState({
        contryList: filterData,
      });
    } else {
      this.setState({
        contryList: defaultCountryList,
      });
    }
  };

  // HandleValueOnChange function for payment page input page change

  render() {
    if (this.state.redirect) {
      return <Redirect to="/paymentmat_success" />;
    }
    const user = JSON.parse(localStorage.getItem("userData"));
    return (
      <>
        {/* Page title */}
        <Helmet>
          <title>My Cart | SmarterChains</title>
          <meta name="description" content="My Cart" />
        </Helmet>
        {/* Breadcrumbs */}
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
        <BreadcrumbsItem to="/cart">My Cart</BreadcrumbsItem>
        {/* View component start */}
        <CartPaymentViewMaturity
          paymentDdl={this.state.paymentDdl}
          HandlePaymentDdlOnClick={this.HandlePaymentDdlOnClick}
          paymentCountry={this.state.paymentCountry}
          HandlePaymentDdlValueOnClick={this.HandlePaymentDdlValueOnClick}
          HandleOnSearch={this.HandleOnSearch}
          contryList={this.state.contryList}
          redirect={this.state.redirect}
          paidItem={this.state.paidItem}
          {...this.props}
        />
      </>
    );
  }
}
