import styled from 'styled-components';

export const PopupContainer = styled.div`
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(125,134,140,.3);
  z-index:999;
  backdrop-filter: blur(2px);
  display:flex;
  align-items:center;
  justify-content:center;
  @media(max-width: 740px){
    align-items: flex-end;
  }
`
export const PopupCard = styled.div`
    width:115.2rem;
    max-width:115.2rem;
    background:#fff;
    height:80rem;
    max-height:90%;
    border-radius: 2.4rem;
    border: 1px solid #E5F0FF;
    display:flex;
    flex-direction:column;
    position:relative;
    padding-bottom:2rem;
    @media(max-width: 740px){
        max-width: 100%;
        width: 100%;
        height: 90%;
        border-radius: 0;
    }
`
export const PopupCardHeader = styled.div`
    border-bottom:0.1rem solid #7DB9FF;
    padding:2.4rem;
    display:flex;
    align-items:center;
    @media(max-width: 740px){
        padding: 12px;
    }
`
export const PopupCardBody = styled.div`
    flex:1;
    overflow:auto;
    padding:2.4rem;
    position:relative;
    @media(max-width: 740px){
        overflow-x: hidden;
        overflow-y: auto;
        padding: 12px;
    }
`
export const HeaderButtons = styled.div`
    display:flex;
    align-items:center;
    gap:1.6rem;
`
export const PopupCloseButton = styled.button`
    line-height:0;
    i{
        display:inline-block;
    } 
`
export const HeaderH1 = styled.div`
    color:#0D2C54;
    font-size: 1.8rem;
    font-weight: 600;
    flex:1;
    dispaly:flex;
    @media(max-width: 740px){
        font-size: 15px;
    }
`
export const ToolTipCard = styled.div`
    width: 34.5rem;
    min-height: 15.5rem;
    position: relative;
    border-radius: 0.8rem;
    padding: 2.2rem 2.2rem 1.9rem 2.4rem ;
    &:after{
        content: "";
        position: absolute;
        top: 2rem;
        left: -2.6rem;
        margin-top: -0.5rem;
        border-width: 1.4rem;
        border-style: solid;
        transform: scaleY(0.6);
    }
    .title{
        color: #FFF;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1.5rem;
    }
    .text{
        color: #FFF;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 150%; /* 21px */
    }
`
export const TooltipWrap = styled.div`
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -1rem;
    left: calc(100% + 1.5rem);
    z-index: 9;
`
export const CenteredPopupCard = styled.div`
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    bacground:#fff;
    width:63rem;
    max-width:calc(100% - 3rem);
    z-index:1;
    border-radius: 0.6rem;
    border: 0.1rem solid ${({isDelete})=> isDelete ? 'rgba(219, 50, 77, 1)':'#118ACB'};
    background: #FFF;
    box-shadow: 0px 0px 0.8rem 0px rgba(21, 27, 38, 0.12);
    padding:2.4rem;
    text-align:center;
    .centered-popup-h2{
        font-size: 1.8rem;
        font-weight: 500;
        color:#0D2C54;
        margin-bottom:1.8rem;
    }
    .centered-popup-p{
        font-size: 1.6rem;
        font-weight: 500;
        color:#4D4D4F;
    }
`
export const CenteredPopupFooter = styled.div`
    display:flex;
    justify-content:center;
    gap:1.5rem;
    margin:3.8rem 0rem 2rem 0rem;
`
export const CenteredPopupCancelButton = styled.button`
    border-radius: 0.4rem;
    border: 0.1rem solid ${({isDelete})=> isDelete? 'rgba(219, 50, 77, 1)':'#118ACB'};
    background:'#fff'; 
    color:${({isDelete})=> isDelete ? 'rgba(219, 50, 77, 1)':'#118ACB'};
    font-size: 1.5rem;
    font-weight: 500;
    padding:1rem 2.5rem;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    min-width:15rem;
    &:hover{
        background:${({isDelete})=> isDelete? 'rgba(250, 226, 230, 1)':'rgba(17, 138, 203, 0.1)'};
    }
`
export const CenteredPopupProceedlButton = styled.button`
    border-radius: 0.4rem;
    border: 0.1rem solid ${({isDelete})=> isDelete? 'rgba(219, 50, 77, 1)':'#118ACB'};
    background:${({isDelete})=> isDelete? 'rgba(219, 50, 77, 1)':'#118ACB'}; 
    color:#fff;
    font-size: 1.5rem;
    font-weight: 500;
    padding:1rem 2.5rem;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    min-width:15rem;
    &:hover{
        background:background:${({isDelete})=> isDelete? 'rgba(194, 25, 52, 1)':'linear-gradient(92deg, #118ACB 12.22%, #0473AE 100.41%)'};
    }
`
// Reason code Popup Chnages
export const BackBtnWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 2.4rem;
    .back-btn{
        display: flex;
        align-items: center;
        border-radius: 0.4rem;
        background: #E5F0FF;
        padding: 4px 8px 4px 2px;
        i{
            display: inline-block;
            height: 2.4rem;
            width: 2.4rem;
            svg{
                height: 100%;
                width: 100%;
            }
        }
        span{
            display: inline-block;
            color: #2C55A0;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.8rem; /* 128.571% */
        }
    }
`
export const SelectScenarioPopupWrapper = styled.div`
    padding: 0;
    .update-loss-text{
        display: block;
        color: #4D4D4F;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
        padding-left: 1.6rem;
        position: relative;
        margin-bottom: 1.6rem;
        max-width: 71rem;
        .bold-text{
            font-weight: 700;
        }
        &:before{
            content: '';
            background: linear-gradient(132deg, #8686DF 14.79%, #6464C8 85.39%);
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 0.6rem;
        }
      }
`
export const SelectScenarioRow = styled.div`
    display: flex;
    align-items: center;
    gap: 3.2rem;
    @media(max-width: 740px){
        flex-direction: column;
    }
`
export const SelectScenarioCol = styled.div`
    max-width: 34.4rem;
    @media(max-width: 740px){
        max-width: 100%;
        width: 100%;
    }
`
export const SelectScenarioCard = styled.div`
    border-radius: 0.8rem;
    border: 0.06rem solid #6464C8;
    background: linear-gradient(180deg, #D7E0FF 0%, #F2F5FF 100%);
    padding: 0.8rem 1.6rem 1.6rem 0.8rem;
    min-height: 29.2rem;
    display: flex;
    flex-direction: column;
    .text-wrap{
        flex: 1;
        .text{
            color: #2C55A0;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2rem; /* 142.857% */
            margin-bottom: 1.2rem;
        }
    }
`
export const SeclectScenarioHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #ffffff;
    margin-bottom: 2rem;
    i{
        display: block;
        height: 6.4rem;
        width: 6.4rem;
        @media(max-width: 740px){
            height: 45px;
            width: 45px;
        }
        svg{
            height: 100%;
            width: 100%;
        }
    }
    span{
        color: #2C55A0;
        display; block;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.8rem; /* 112.5% */
        @media(max-width: 740px){
            font-size: 14px;
        }
    }
`
export const SelectScenarioFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    .select-btn{
        border-radius: 0.4rem;;
        background: linear-gradient(132deg, #8686DF 14.79%, #6464C8 85.39%);
        box-shadow: 0px 0px 0.8rem 0px rgba(21, 27, 38, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 9.5rem;
        min-height: 3.6rem;
        text-transform: uppercase;
        color: #fff;
    }
`
export const AddScenarioPopupWrapper = styled.div``

export const AddScenarioCard = styled.div`
    .card-head{
        color: #4D4D4F;
        display: block;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: normal;
    }

`

export const AddInputWrap = styled.div`
    input{
        display: block;
        border-radius: 0.5rem;
        border: 0.1rem solid #E9E9E9;
        padding: 1.4rem;
        color: #4d4d4f;
        font-size: 1.5rem;
        font-weight: 400;
        line-height:normal;
        width: 100%;
        outline:0;
        &.input-error{
            border-color:red;
        }
        &:placeholder{
            color: #7D868C;
        }
    }
    .error{
        visibility:hidden;
        min-height:25px;
        font-size:1.2rem;
        color:red;
        font-weight:500;
        &.show{
            visibility:visible;
        }
    }
`

export const AddScenarioFooter = styled.div`
    margin-top: 4rem;
    display: flex;
    justify-content: flex-end;
    gap: 1.6rem;
    .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 4rem;
        min-width: 13.7rem;
        border-radius: 4px;
        border: 0.03rem solid #7D868C;
        font-size: 1.2rem;
        font-weight: 700;
        &.discard-btn{
            background: #ffffff;
            color: #7D868C;
            text-transform: uppercase;
        }
        &.vendor-btn{
            color: #ffffff;
            border-color: transparent;
            background-color: #CBCFD2;
            cursor: not-allowed;
            &.active{
                background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%);
                cursor: pointer;
            }
        }
    }
`
export const LossCatPopupWrapper = styled.div`
    @media(max-width: 740px){
        width: 100%;
    }
`
export const LossCatPopupCard = styled.div`
    h2{
        color: #0D2C54;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2rem; /* 125% */  
    }
    p{
        color: #0D2C54;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem; /* 125% */  
    }
`
export const LossCatPopup = styled.div`
`
export const LossCatgoryImageLabel = styled.div`
    border-radius: 0.4rem 0.4rem 0px 0px;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 0.8rem 1.6rem;
    width: max-content;
    position: relative;
    cursor: pointer;
    ${TooltipWrap}{
        position: absolute;
    }
    &:hover{
        ${TooltipWrap}{
            opacity: 1;
            visibility: visible;
        }
    }
    &.blue-class{
        background: rgba(211, 230, 255, 0.60);
        ${ToolTipCard}{
            background: #33A4FF;
            &:after{
                border-color: transparent #33A4FF transparent transparent;
            }
        }
    }
    &.green-class{
        border-radius: 0.5rem 0.5rem 0px 0px;
        background: #E5F5F1;
        ${ToolTipCard}{
            background: #45B097;
            &:after{
                border-color: transparent #45B097 transparent transparent;
            }
        }
        svg{
            path{
                fill: #45B097;
            }
        }
    }
    .loss-icon{
        display: inline-block;
        width: 1.6rem;
        svg{
            width: 100%;
        }
    }
    .info-icon{
        display: inline-block;
        height: 1.4rem;
        width: 1.4rem;
        padding: 0;
        position: unset;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .label{
        display: block;
        color: #4D4D4F;
        padding-left: 0.8rem;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 150%; /* 21px */
    }

`
export const LossMainRow = styled.div`
    border-radius: 0px 0px 0.4rem 0.4rem;
    border: 0.1rem solid;
    display: flex;
    gap: 4rem;
    width: 100%;
    padding: 1.6rem;
    @media(max-width: 740px){
        flex-wrap: wrap;
    }
    &.blue-class{
        border-color: #E5F0FF;
    }
    &.green-class{
        border-color: #E5F5F1;
    }
`
export const LossSubCol = styled.div`
    min-width: 22.5rem;
    @media(max-width: 740px){
        min-width: unset;
        padding: 5px;
        width: 50%;
    }
    @media(max-width: 710px){
        width: 100%;
    }
`
export const LossMainCol = styled.div`
    &:first-child{
        flex: 1;
        @media(max-width: 710px){
            width: 100%;
        }
    }
    &:nth-of-type(2){
        width: 23rem;
        @media(max-width: 740px){
            width: 150px;
        }
        @media(max-width: 710px){
            width: 100%;
        }
        ${LossSubCol}{
            @media(max-width: 740px){
                width: 100%;
            }
        }
    }
    .loss-main-label{
        color: #0D2C54;
        display: block;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2.1rem; /* 21px */
        margin-bottom: 0.8rem;
    }
`
export const LossMainCard = styled.div``
export const LossSubRow = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    @media(max-width: 740px){
        gap: 0;
        margin: 0 -5px;
    }
`
export const LossSubCard = styled.div`
    padding: 1.2rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    border: 0.1rem solid #F0F0F0;
    background: #FFF;
    gap: 1rem;
    position: relative;
    cursor: pointer;
    ${TooltipWrap}{
        position: absolute;
        ${ToolTipCard}{
            background: #E3EFFE;
            &:after{
                border-color: transparent #E3EFFE transparent transparent;
            }
            .title,.text{
                color: #4d4d4f;
            }
        }
        &.right-tooltip{
            left: auto;
            right: calc(100% + 1.6rem);
            ${ToolTipCard}{    
                &:after{
                    border-color: transparent transparent transparent rgb(227, 239, 254);
                    right: -2.7rem;
                    left: auto;
                }
            }
        }   
    }
    &:hover{
        ${TooltipWrap}{
            opacity: 1;
            visibility: visible;
        }
    }
    i{
        display: block;
        width: 3rem;
        svg{
            width: 100%;
        }
    }
    .loss-text{
        display: block;
        color: #4D4D4F;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 100%; /* 14px */
    }
`
export const TopLossCategorySection = styled.div`
    margin-top: 2.4rem;
`
export const ScenarioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding-bottom: 2.4rem;
  .label{
    color: #4D4D4F;
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.8rem; /* 112.5% */
    @media(max-width: 740px){
        font-size: 14px;
    }
  }
  
`

export const ScenarioHead = styled.div`
  border-radius: 0.4rem;
  border: 0.1rem solid #CBCFD2;
  min-width: 57.6rem;
  padding: 0.9rem 1.2rem;
  @media(max-width: 768px){
    flex: 1;
    min-width: unset;
  }
  @media(max-width: 740px){
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 10px;
  }
  .scenario-name{
    color: #4D4D4F;
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.8rem; /* 112.5% */
    @media(max-width: 740px){
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
  }
`

export const GeneratReportButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid #118ACB;
  box-shadow: 0px 0.4rem 0.4rem 0px rgba(77, 77, 79, 0.25);
  background: linear-gradient(92deg, #139BD6 12.22%, #0789CF 100.41%); 
  color:#fff;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 1.2rem 2.4rem;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  position:absolute;
  bottom:3rem;
  left:50%;
  transform:translate(-50%,0%);
  min-width: 20.1rem;
  min-height: 4.8rem;
  &:hover{
    background: linear-gradient(92deg, #118ACB 12.22%, #0473AE 100.41%);
  }
  &.highlight-area{
    z-index: 999;
    &:after{
      content: '';
      position: absolute;
      top: -0.4rem;
      left: -0.4rem;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border: 0.4rem solid #fff;
      border-radius: 0.4rem;
    }
  }
`

export const ChangesSavedButton = styled.button`
  border-radius: 0.4rem;
  border: 0.1rem solid #43A047;
  background:#fff; 
  color:#43A047;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  padding:0.75rem 2.4rem;
  display:inline-flex;
  justify-content:center;
  align-items:center;
  position:absolute;
  bottom:3rem;
  left:50%;
  gap:0.8rem;
  transform:translate(-50%,0%);
  box-shadow: 0px 4px 4px 0px rgba(77, 77, 79, 0.25);

  i{
      line-height:0;
      display:inline-block;
  }
  &:disabled{
      pointer-events:none;
  }
    
`

export const LoaderPopupWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  border-radius:inherit;
  &:before{
    content: '';
    display: block;
    background: #ffffff;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius:inherit;
  }
`
export  const LoaderPop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display:flex;
  flex-direction:column;
  align-items:center;
`