import React, { useRef, useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from "react-router-dom";
import { apiEndpoints } from "../../services/api-endpoints";
import serviceProvider from "../../services/axios";
import {
    MytaskFilterContainer, MytaskFilterButton, TaskDropdownBox, TaskDropdownTitle, TotalTaskButton, TotalTaskButtonWrap, AllPlantButton, AllPlantCollapseBox, SearchInputWrapper, SearchInput, PlantCheckBoxWrapper, PlantCheckBox
} from './styles';
import { Collapse } from '@material-ui/core';

export const MyTaskFilter = (props) => {
    const [isDrag, setIsDarg] = useState(false);
    const [taskCollapse, setTaskCollapse] = useState(true);
    const [allPlantCollapse, setAllPlantCollapse] = useState(false);
    const [allPlantSearchInput, setAllPlantSearchInput] = useState('');
    const [filterPlant, setFilterPlant] = useState([]);
    const [selctedPlants, setSelectedPlant] = useState([]);
    const draggableDiv = useRef();

    const handleSearch = (e) => {
        let val = e.target.value;
        setAllPlantSearchInput(val);
    }

    const handleAllCheck = () => {
        let tmpArritem = [];
        let allCheck = document.querySelector(`[data-component="PlantCheckBox"] .all-check`);
        let plantsCheckBox = document.querySelectorAll(`[data-component="PlantCheckBox"] .plant-check`);
        if (allCheck.checked === true) {
            plantsCheckBox.forEach(i => {
                i.checked = true
                tmpArritem.push(i.value);
            });
            setSelectedPlant(props.allPlants);
        } else {
            tmpArritem = [];
            setSelectedPlant([]);
            plantsCheckBox.forEach(i => { i.checked = false });
        }
        localStorage.setItem("taskfilter",JSON.stringify(tmpArritem));
        let filter = tmpArritem.toString();
        
        props.onFilterPlantsTask(filter);
    }

    const handlePlantCheck = () => {
        let tmpArr = []; let tmpArritem = [];
        let allCheck = document.querySelector(`[data-component="PlantCheckBox"] .all-check`);
        let plantsCheckBox = document.querySelectorAll(`[data-component="PlantCheckBox"] .plant-check`);
        plantsCheckBox.forEach(i => {
            if (i.checked === true) {
                tmpArr.push(i);
                tmpArritem.push(i.value);
            }
        });
        setSelectedPlant(tmpArr);
        if (tmpArr.length === plantsCheckBox.length) {
            allCheck.checked = true;
        } else {
            allCheck.checked = false;
        }
        localStorage.setItem("taskfilter",JSON.stringify(tmpArritem));
        let filter = tmpArritem.toString();
        
        props.onFilterPlantsTask(filter);
    }

    const dragFunction = (event) => {
        let container = document.querySelector('.training-content');
        let sidebar = document.querySelector('.ng-sidebar');
        if (event.clientY > container.offsetTop && (event.clientY + draggableDiv.current.offsetHeight) < window.innerHeight) {
            draggableDiv.current.style.top = event.clientY + 'px';
        }

        if ((event.clientX > sidebar.offsetWidth && (event.clientX + draggableDiv.current.offsetWidth) < window.innerWidth)) {
            draggableDiv.current.style.left = event.clientX + 'px';
        } else {
            setIsDarg(false);
        }
    }

    useEffect(() => {
        setFilterPlant(props.allPlants);
    }, [props.allPlants]);

    useEffect(() => {
        draggableDiv.current.style.top = '100px';
        draggableDiv.current.style.left = `${window.innerWidth - (draggableDiv.current.scrollWidth + 30)}px`;
    }, []);
    return (
        <MytaskFilterContainer
            data-component="MytaskFilterContainer"
            draggable={isDrag}
            ref={draggableDiv}>
            <MytaskFilterButton data-component="MytaskFilterButton" active={taskCollapse}>
                <i
                    className='drag-icon'
                    dangerouslySetInnerHTML={{ __html: dragSvg }}
                    onMouseDown={() => setIsDarg(true)}
                    onDrag={dragFunction}
                    onDragEnd={(e) => { setIsDarg(false); }}
                    draggable={isDrag}
                ></i>
                <span>My Tasks</span>
                <i className='ddl-angle'
                    onClick={() => setTaskCollapse(!taskCollapse)}
                    dangerouslySetInnerHTML={{ __html: angleSvg }}
                ></i>
            </MytaskFilterButton>
            {/* MytaskFilterButton end  */}
            <Collapse in={taskCollapse}>
                <TaskDropdownBox data-component="TaskDropdownBox">
                    {filterPlant?.length > 1 &&
                        <>
                            <TaskDropdownTitle data-component="TaskDropdownTitle">
                                Total Plants&emsp;<b>{filterPlant?.length}</b>
                            </TaskDropdownTitle>

                            <AllPlantButton
                                active={allPlantCollapse}
                                className="AllPlantButton"
                                onClick={() => {
                                    setAllPlantCollapse(!allPlantCollapse);
                                    setAllPlantSearchInput('');
                                }}>
                                <span>
                                    {selctedPlants.length > 1
                                        ? `Plants Selected:${selctedPlants.length}`
                                        :
                                        selctedPlants.length === 1 ? `Plant Selected:${selctedPlants.length}` :
                                            'All Plants'
                                    }
                                </span>
                                <i className='ddl-angle'
                                    dangerouslySetInnerHTML={{ __html: angleSvg }}
                                ></i>
                            </AllPlantButton>
                            <Collapse in={allPlantCollapse}>
                                <AllPlantCollapseBox data-component="AllPlantCollapseBox">
                                    <SearchInputWrapper data-component="SearchInputWrapper">
                                        <i dangerouslySetInnerHTML={{ __html: searchIcon }}></i>
                                        <SearchInput
                                            placeholder='Search Plants'
                                            onChange={(e) => handleSearch(e)}
                                            value={allPlantSearchInput}
                                        />
                                    </SearchInputWrapper>
                                    <PlantCheckBoxWrapper data-component="PlantCheckBoxWrapper">
                                        <PlantCheckBox
                                            data-component="PlantCheckBox"
                                            style={{ display: !allPlantSearchInput ? '' : 'none' }}
                                        >
                                            <input type="checkbox" className='all-check' onChange={handleAllCheck} />
                                            <i className='chk-icon' dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                            <span>All Plants</span>
                                        </PlantCheckBox>
                                        {/*  end */}

                                        {filterPlant?.map((item, index) => (
                                            <PlantCheckBox
                                                data-component="PlantCheckBox"
                                                key={index}
                                                style={
                                                    {
                                                        display: (allPlantSearchInput && !item.plant_name.toLowerCase().startsWith(allPlantSearchInput)) ? 'none' : ''
                                                    }
                                                }                                    >
                                                <input type="checkbox" value={item.id} className='plant-check' onChange={handlePlantCheck} />
                                                <i className='chk-icon' dangerouslySetInnerHTML={{ __html: checkboxSvg }}></i>
                                                <span>{item.plant_name}</span>
                                            </PlantCheckBox>
                                        ))

                                        }


                                    </PlantCheckBoxWrapper>
                                </AllPlantCollapseBox>

                            </Collapse>
                        </>
                    }
                    {/* All plant checkbox collapse component end */}
                    {!allPlantCollapse && props.taskdata?.total &&
                        <>
                           {filterPlant?.length > 1 && <div className='divider' />}
                            <TaskDropdownTitle data-component="TaskDropdownTitle">
                                Total Tasks&emsp;<b>{props.taskdata.total}</b>
                            </TaskDropdownTitle>

                            <TotalTaskButtonWrap data-component="TotalTaskButtonWrapp">
                                <TotalTaskButton data-component="TotalTaskButton" as={Link} to="/task-center?filter=todo">
                                    <span>To Do</span>
                                    <span className='text-align-right'>{props.taskdata.todo}</span>
                                </TotalTaskButton>
                                {/* button end */}
                                <TotalTaskButton data-component="TotalTaskButton" as={Link} to="/task-center?filter=inprogress">
                                    <span>In Progress</span>
                                    <span className='text-align-right'>{props.taskdata.inprogress}</span>
                                </TotalTaskButton>
                                {/* button end */}
                                <TotalTaskButton data-component="TotalTaskButton" as={Link} to="/task-center?filter=completed">
                                    <span>Completed</span>
                                    <span className='text-align-right'>{props.taskdata.completed}</span>
                                </TotalTaskButton>
                                {/* button end */}
                            </TotalTaskButtonWrap>
                        </>}
                </TaskDropdownBox>
                {/* Taskdropdown box end */}

            </Collapse >
            {/* Main collapse component end */}
        </MytaskFilterContainer >
    )
}


const dragSvg = `<svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="32" rx="4" fill="white"/>
<circle cx="7" cy="10.5" r="1.5" fill="#2C55A0"/>
<circle cx="7" cy="16.5" r="1.5" fill="#2C55A0"/>
<circle cx="7" cy="22.5" r="1.5" fill="#2C55A0"/>
<circle cx="13" cy="10.5" r="1.5" fill="#2C55A0"/>
<circle cx="13" cy="16.5" r="1.5" fill="#2C55A0"/>
<circle cx="13" cy="22.5" r="1.5" fill="#2C55A0"/>
</svg>
`;

const angleSvg = `<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.28284 6.52836C5.12663 6.68457 4.87337 6.68457 4.71716 6.52836L0.282843 2.09404C0.126633 1.93783 0.126633 1.68456 0.282843 1.52836L0.883824 0.927374C1.04003 0.771164 1.2933 0.771164 1.44951 0.927374L4.71716 4.19502C4.87337 4.35123 5.12663 4.35123 5.28284 4.19502L8.55049 0.927375C8.7067 0.771166 8.95997 0.771166 9.11618 0.927376L9.71716 1.52836C9.87337 1.68457 9.87337 1.93783 9.71716 2.09404L5.28284 6.52836Z" fill="#118ACB"/>
</svg>
`;

const searchIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.2583 14.075L12.425 11.25C13.3392 10.0854 13.8352 8.64722 13.8333 7.16667C13.8333 5.84813 13.4423 4.5592 12.7098 3.46287C11.9773 2.36654 10.9361 1.51206 9.71789 1.00747C8.49972 0.502889 7.15927 0.370866 5.86607 0.628101C4.57286 0.885336 3.38497 1.52027 2.45262 2.45262C1.52027 3.38497 0.885336 4.57286 0.628101 5.86607C0.370866 7.15927 0.502889 8.49972 1.00747 9.71789C1.51206 10.9361 2.36654 11.9773 3.46287 12.7098C4.5592 13.4423 5.84813 13.8333 7.16667 13.8333C8.64722 13.8352 10.0854 13.3392 11.25 12.425L14.075 15.2583C14.1525 15.3364 14.2446 15.3984 14.3462 15.4407C14.4477 15.4831 14.5567 15.5048 14.6667 15.5048C14.7767 15.5048 14.8856 15.4831 14.9872 15.4407C15.0887 15.3984 15.1809 15.3364 15.2583 15.2583C15.3364 15.1809 15.3984 15.0887 15.4407 14.9872C15.4831 14.8856 15.5048 14.7767 15.5048 14.6667C15.5048 14.5567 15.4831 14.4477 15.4407 14.3462C15.3984 14.2446 15.3364 14.1525 15.2583 14.075V14.075ZM2.16667 7.16667C2.16667 6.17776 2.45991 5.21106 3.00932 4.38882C3.55873 3.56657 4.33962 2.92571 5.25325 2.54727C6.16688 2.16883 7.17222 2.06982 8.14212 2.26274C9.11203 2.45567 10.0029 2.93187 10.7022 3.63114C11.4015 4.3304 11.8777 5.22131 12.0706 6.19122C12.2635 7.16112 12.1645 8.16646 11.7861 9.08009C11.4076 9.99372 10.7668 10.7746 9.94452 11.324C9.12228 11.8734 8.15558 12.1667 7.16667 12.1667C5.84059 12.1667 4.56882 11.6399 3.63114 10.7022C2.69345 9.76452 2.16667 8.49275 2.16667 7.16667Z" fill="#7D868C"/>
</svg>
`

const checkboxSvg = `
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.3" y="0.3" width="13.4" height="13.4" rx="1.7" fill="#118ACB" stroke="#118ACB" stroke-width="0.6"/>
<path d="M5.82287 8.45807C5.66719 8.61109 5.41758 8.61103 5.26197 8.45794L3.92535 7.14301C3.77008 6.99025 3.52113 6.98981 3.36532 7.14202L3.29188 7.21376C3.13163 7.3703 3.13117 7.62793 3.29087 7.78504L5.26183 9.72403C5.4175 9.87717 5.66721 9.87717 5.82287 9.72403L10.7102 4.91602C10.8695 4.7593 10.8695 4.50245 10.7102 4.34573L10.6391 4.27584C10.4835 4.12275 10.2339 4.12269 10.0782 4.27571L5.82287 8.45807Z" fill="white"/>
</svg>
`;