import styled from "styled-components";

export const MainWrapper = styled.div`
   padding:2.9rem;
`

export const ChartDivWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(21, 27, 38, 0.03);
    border-radius: 8px;
    padding:20px 24px;
    margin-bottom:54px;
`;
export const ChartTitle = styled.h2`
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: #4D4D4F;
    margin-bottom:2rem;
    padding-left:2.4rem;
`
export const ChartDiv = styled.div`
.percent-text{
    display:block;
    text-align:center;
  }
  .ract-bars{
    cursor:pointer;
  }
  .section-text{
    display:block;
    text-align:center;
    font-size:11px;
  }
  .inactive{
    opacity:0.5;
  }
  .stroke-dasharray{
    stroke-dasharray: 5,5;
    stroke:#1487E3 !important;
  }
  .y-axis-percent-txt{
    opacity:0;
  }
  .y-axis-percent-txt.t-3,.y-axis-percent-txt.t-0,.y-axis-percent-txt.t-10{
    opacity:1 !important;
  }
  
`
export const PopupDiv = styled.div`
  position:relative;
  user-select:none;
`;
export const PopupTitle = styled.h2`
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: #4D4D4F;
        margin-bottom:0.6rem;
`;

export const PoupDetail = styled.p`
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #7D868C;
`;

export const ScoreRow = styled.div`
       display:flex;
       padding-left:24px;
       font-weight: 400;
       font-size: 18px;
       line-height: 22px;
       color: #4D4D4F;
       .score_title{
         padding:14px 0px;
         width:300px;
         align-items:center;
         display:inline-flex;
         gap:8px;
         &:before{
            content:'';
            width:4px;
            height:22px; 
            background:#E25B71;
            display:inline-block;
        }
        &.green:before{
           background:#45B097;
        }
       }
       .score_text{
        padding:14px 0px;
        width:100px;
        display:inline-flex;
        align-items:center;
       }
       &:not(:last-child){
            span{
                border-bottom:1px solid #F0F0F0;
            }
        }
`;

export const ActionDiv = styled.div`
      position: absolute;
      top:0;
      right:0;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      gap:12px;
      display:none;
      i{
        display:inline-block;
        cursor:pointer;
        svg:hover{
            path{
                stroke:#139BD6;

            }
        }
      }

      .action-row{
        display:flex;
        gap:15px;
      }
`

//upsell modal style

export const ModalContent = styled.div`
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
`
export const ModalCard = styled.div`
     width: 110.2rem;
     height: 65.2rem;
     border-radius: 2.4rem;
     border: 1px solid #CCD6E8;
     background:#FFF;
     display:flex;
     flex-direction:column;
     padding-bottom:2.4rem;
     position: relative;
     @media(max-width:768px){
      width:calc(100% - 30px);
     }
     @media(max-width:575px){
       height:auto;
       max-height:90vh;
     }
`
export const ModalCardHeader = styled.div`
     border-bottom:0.1rem solid #CCD6E8;
     font-size: 1.8rem;
     font-style: normal;
     font-weight: 600;
     color:#2C55A0;
     padding:2.4rem;
     display:flex;
     align-items:center;
     justify-content:space-between;
`
export const ModalCardBody = styled.div`
      padding:2.4rem 2.4rem 0rem 2.4rem;
      color:#4D4D4F;
      overflow:auto;
      position:relative;
      @media(max-width:575px){
        padding-bottom:20px;
      }
`
export const TitleText = styled.h5`
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600;
      margin-bottom:1.2rem;
`
export const SubTitleText = styled.p`
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom:${({marginBottom})=> marginBottom || '1.2rem'};
      b{
          font-weight:500;
      }
`
export const PlantCardRow = styled.div`
       display:flex;
       gap:2.4rem;
       flex-wrap:wrap;
`
export const PlantCardCol = styled.div`
        width: 29rem;
        height: 30.6rem;
        border-radius: 0.8rem;
        border: 0.1rem solid  ${({ selected,disabled }) => selected ? "#43A047" : disabled?"#E5F0FF" :"#7DB9FF"};
        background: ${({ selected }) => !selected ? "#FAFCFD" : "#EFF9F7"};
        padding:1.6rem;
        display:flex;
        flex-direction:column;  
        @media(max-content: 740px){
          height: max-content;
        }
`
export const PlantCardColFooter = styled.div`
         display:flex;
         justify-content:space-between;
         align-items:center;
`
export const PlantCardFooterText = styled.div`
       color:#2C55A0;
       font-size: 1.4rem;
       font-style: normal;
       font-weight: 400;
       margin-bottom:0.8rem;
       .border-text{
          border-right:0.1rem solid #ADC8FE;
          display:inline-block;
          padding-right:0.8rem;
          margin-right:0.8rem;
          min-width:8rem
       }
       b{
        font-weight:600;
       }
`
export const PlantFooterTextWrapper = styled.div`
    display:inline-block;
`
export const PlantFooterButton = styled.button`
    display:inline-flex;
    background:${({ selected,disabled }) => selected ? "#43A047" : disabled?"#CBCFD2" : "#118ACB"};
    width:8.1rem;
    color:#fff;
    font-weight:
    font-size: 1.6rem;
    font-weight: 500;
    padding:0.8rem 1.6rem;
    align-items:center;
    justify-content:center;
    border-radius:0.4rem;
    pointer-events:${({ disabled }) => disabled?"none" : "all"};
`
export const TextType = styled.h5`
        color:#455A64;
        font-size: 1.4rem;
        font-weight: 400;
        // margin-bottom:1.6rem;
`
export const PlantWithText = styled.h5`
        color: #2C55A0;
        font-family: Inter;
        font-size: 1.6rem;
        font-weight: 400;
        margin-bottom:1.2rem;
        margin-top: 1.6rem;
`
export const PlantTechCardWrapper = styled.div`
       flex:1; 
       margin-bottom: 2.4rem;
`
export const PlantTechCard = styled.div`
        display:flex;
        gap:0.8rem;
        align-items:center;
        color: #0D2C54;
        font-size: 1.4rem;
        font-weight: 400;
        &:not(:last-child){
          margin-bottom:0.8rem;
        }
        i{
          line-height:0;
        }
`
export const ProceedButton = styled.button`
    display:inline-flex;
    background:${({ disabled }) => !disabled ? "#118ACB" : "#E6E6E6"};
    cursor:${({ disabled }) => !disabled ? "pointer" : "not-allowed"};
    color:#fff;
    font-size: 1.8rem;
    font-weight: 500;
    padding:2.1rem 4rem;
    align-items:center;
    justify-content:center;
    border-radius:0.4rem;
    position:absolute;
    bottom:3.2rem;
    right:2.4rem;
    gap:0.8rem;
    i{
      line-height:0;
    }

    @media(max-width:575px){
      position:static;
      width:calc(100% - 20px);
    }
`;
export const InputFieldWrapper = styled.div`
  position: relative;
  display: block;
  margin-bottom:2.5rem;
  .label {
      position: absolute;
      padding: 0.3rem 0.5rem;
      left: 1.2rem;
      top: -0.8rem;
      background: #fff;
      border-radius: 0.2rem;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #0A2265;

  }

  .input_field {
      height: 5rem;
      width: 100%;
      border: 0.1rem solid #E9E9E9;
      border-radius: 0.5rem;
      padding: 0.5rem 1.7rem;
      outline: 0;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: #4D4D4F;

      ::placeholder {
          color: #7D868C;
      }

      &.pr_30 {
          padding-right: 4rem;
      }

  }

  svg {
      position: absolute;
      top: 50%;
      right: 2.1rem;
      transform: translateY(-50%);
      cursor: pointer;
  }
`
export const PlantCardCol2 = styled.div`
       width:48rem;
`;

export const DropdownContainer = styled.div`
    position: relative;
    .dropdown_btn{
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      &.bottom{
        height: 5rem;
        width: 100%;
        border: 0.1rem solid #E9E9E9;
        border-radius: 0.5rem;
        padding: 0.5rem 1.7rem;
        outline: 0;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: #4D4D4F;
        .label{
          position: absolute;
          padding: 0.3rem 0.5rem;
          left: 1.2rem;
          top: -0.8rem;
          background: #fff;
          border-radius: 0.2rem;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.5rem;
          color: #0A2265;
        }
      }
    }
    &.active {
        .dropdown_btn {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &.top {
                border-bottom-left-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        .dropdown_content {
            opacity: 1;
            visibility: visible;
        }
    }

    .dropdown_content {
        position: absolute;
        z-index: 2;
        background: #fff;
        width: 100%;
        max-height: 20rem;
        overflow-y: auto;
        border: 0.1rem solid #E9E9E9;
        border-top: 0;
        opacity: 0;
        visibility: hidden;

        &.top {
            top: 0;
            transform: translateY(-100%);
            border-top: 0.1rem solid #E9E9E9;
            border-bottom: 0;
        }

        li {
            cursor: pointer;
            padding: 1.1rem 1.6rem;
            font-size: 1.4rem;
            color: #4D4D4F;

            &:hover {
                background: #F5F5F5;
            }
        }
    }
`
export const PopupTopText = styled.div`
   display:flex;
   align-items:center;
   gap:1.6rem;
   margin-bottom:5rem;
   .back_btn{
    line-height:0;
    i{
      display:inline-block;
    }
   }
`;
export const PlantHeadText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${PlantCardFooterText}{
    border-radius: 0.4rem;
    border: 0.04rem solid #D3E6FF;
    background: #FFF;
    padding: 0.6rem 0.8rem;
    margin-bottom: 0;
  }
`