export const couponSvg=`<svg xmlns="http://www.w3.org/2000/svg" width="52" height="59" viewBox="0 0 52 59" fill="none">
<path d="M2.50267 0C2.10458 0.000831737 1.72319 0.0935481 1.4417 0.257929C1.1602 0.42231 1.00142 0.645019 1 0.877487V59H4.00535V0.877487C4.00392 0.645019 3.84515 0.42231 3.56365 0.257929C3.28215 0.0935481 2.90077 0.000831737 2.50267 0Z" fill="white"/>
<path d="M37 16C37.8136 14.3585 23.6978 6.06281 29 2C29 2 23.5514 2.76258 23 6.85125C23.2044 11.3837 34.738 18.7683 31.6647 21" fill="#407BFF"/>
<g filter="url(#filter0_d_1489_65550)">
  <path d="M26.3492 5.13419C25.139 3.37842 27.3318 2.77636 29 2.00017C31.0207 1.37341 35.6206 9.74982 47.9995 9.81623C45.9824 13.1119 44.0816 13.9047 44.0816 13.9047C41.5484 14.4651 31.5646 12.6802 26.3492 5.13419Z" fill="#369CFF"/>
  <path opacity="0.2" d="M36.9692 15.4694C37.7247 13.9143 24.2757 5.84898 29.1991 2C29.1991 2 24.512 2.72245 24 6.59592C24.1898 10.8898 34.8996 17.8857 32.0458 20" fill="#007BED"/>
  <path d="M4 13.277C7.88994 16.2776 23.0449 21.9992 32.0749 20.8496C34.9404 20.0994 37 16 37 16C25.1189 16.3107 8.14067 9.18448 4 4V13.277Z" fill="#369CFF"/>
</g>
<defs>
  <filter id="filter0_d_1489_65550" x="0" y="1.96667" width="51.9995" height="27.0333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1489_65550"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1489_65550" result="shape"/>
  </filter>
</defs>
</svg>`

export const closeIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<rect x="0.5" y="0.5" width="31" height="31" rx="1.5" stroke="white"/>
<path d="M11.1 22.3C10.7134 22.6866 10.0866 22.6866 9.7 22.3C9.3134 21.9134 9.3134 21.2866 9.7 20.9L13.8929 16.7071C14.2834 16.3166 14.2834 15.6834 13.8929 15.2929L9.7 11.1C9.3134 10.7134 9.3134 10.0866 9.7 9.7C10.0866 9.3134 10.7134 9.3134 11.1 9.7L15.2929 13.8929C15.6834 14.2834 16.3166 14.2834 16.7071 13.8929L20.9 9.7C21.2866 9.3134 21.9134 9.3134 22.3 9.7C22.6866 10.0866 22.6866 10.7134 22.3 11.1L18.1071 15.2929C17.7166 15.6834 17.7166 16.3166 18.1071 16.7071L22.3 20.9C22.6866 21.2866 22.6866 21.9134 22.3 22.3C21.9134 22.6866 21.2866 22.6866 20.9 22.3L16.7071 18.1071C16.3166 17.7166 15.6834 17.7166 15.2929 18.1071L11.1 22.3Z" fill="white"/>
</svg>`

export const angleIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<mask id="mask0_1489_65520" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
  <rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1489_65520)">
  <path d="M6.68913 18.3354L5.20996 16.8562L12.0641 10.002L5.20996 3.14787L6.68913 1.6687L15.0225 10.002L6.68913 18.3354Z" fill="white"/>
</g>
</svg>`