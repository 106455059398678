/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * useHistory ===> hook to access the history object.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * Collapse ==> component from material-ui used to work as accordion.
  * {SupportTilteImg, CrossImg, CheckBox, ThreeDots, ViewCollapseIcon, SqureChk, SmarterChainsLogo} ===> svg images that we are using from svg.js.  
  * { YesNo }, { SingleSelect }, { MultiCheckbox }, { RangeSlider },{ SingleLine } ===> These all are the custom view components for answer format.
    { IncrementDecrement }, { MultiChoiceLines }, { MultiRange }
  * uesWalkthroughView for ===> view component of walkthrough for the page.
  * question-media-screen.scss ===> scss for styling component.

**/




import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import $ from "jquery";
import serviceProvider from '../../services/axios'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import { MultiCheckbox } from "./view-multiCheckbox";
import { apiEndpoints } from '../../services/api-endpoints';
import { Collapse } from '@material-ui/core';
import {
    SupportTilteImg,
    CrossImg,
    CheckBox,
    ThreeDots,
    ViewCollapseIcon,
    SqureChk,
    SmarterChainsLogo,
} from './svg';
//import './question-media-screen.scss'

//Questionplantview component start
export const QuestionView = (props) => {

    //Defining variables here
    const quesDataItems = [
    {
        "block": "allow",
        "uid": "3010",
        "pid": "1947",
        "scid": "1",
        "no_lines": 0,
        "category": {
            "id": "1",
            "cluster_id": "1",
            "category_name": "Industry 4.0 Vision",
            "category_description": "<p>The section&apos;s objective is to address the influence of Industry 4.0 into the company&apos;s strategy.&nbsp;<\/p> <p>In particular, we will explore Industry 4.0 strategies and initiatives, sustainability practices, structures and policies that provide the agility to design and roll out an effective digital transformation.<\/p> <p>Typically, the person responsible for this section is the Assessment leader.<\/p> <p>Estimated engagement 35 minutes.<\/p>",
            "bg_image": "ind4-icon.svg",
            "category_img": "assets\/img\/ind4-icon-w.svg",
            "maturity_img": "md8-industry -4.0-vision.svg",
            "is_active": "1",
            "order_by": "1",
            "color": "",
            "is_line": "0",
            "approx_time": "9"
        },
        "subcategory": {
            "id": "1",
            "category_id": "1",
            "subcategory_name": "Company-wide Capabilities",
            "subcategory_description": "<p>The chapter&apos;s objective is to address the Industry 4.0-related company&apos;s wide capabilities.&nbsp;<\/p> <p>In particular, we will explore your organizational capabilities, which are the unique combination of skills, processes, technologies, and human abilities that differentiate the company and help it execute its Industry 4.0 strategy.<\/p>",
            "bg_image": "",
            "section_id": "1",
            "is_active": "1",
            "order_by": "1",
            "subtext": "",
            "color": "1"
        },
        "title": "Company-wide Capabilities Assessment",
        "quesIds": [
            {
                "id": "464"
            },
            {
                "id": "626"
            },
            {
                "id": "627"
            },
            {
                "id": "465"
            },
            {
                "id": "466"
            },
            {
                "id": "487"
            },
            {
                "id": "628"
            },
            {
                "id": "629"
            },
            {
                "id": "474"
            }
        ],
        "noques": 1,
        "question": {
            "id": "464",
            "question": "Do you have a formal Industry 4.0 strategy as a part of the corporate strategy?",
            "subcategory_id": "1",
            "question_description": "",
            "question_code": "noa1a",
            "question_layout": "8",
            "question_profile_pic": "assets\/img\/manos-pic.jpg"
        },
        "pqid": 0,
        "lines": [],
        "answers": [
            {
                "id": "1398",
                "question_id": "464",
                "option_code": "noa1a1",
                "option_name": "We have an enterprise-wide Industry 4.0 strategy",
                "answer_description": "The company leadership has a clearly defined plan to drive digitization based on the needs of the business and has designed the elements to enable the execution of the strategy on the plant.",
                "use_case": null,
                "is_none": "0"
            },
            {
                "id": "1399",
                "question_id": "464",
                "option_code": "noa1a2",
                "option_name": "We have a plant-specific Industry 4.0 strategy",
                "answer_description": "The plant leadership team has built the plant-specific strategy upon the company's strategy with clear progression steps and has incorporated it into the plant's vision.",
                "use_case": null,
                "is_none": "0"
            },
            {
                "id": "2246",
                "question_id": "464",
                "option_code": "noa1a4",
                "option_name": "We have dedicated resources working on designing our Industry 4.0 enterprise-wide strategy",
                "answer_description": "The company has specifically assigned resources that focus on building the Industry 4.0 vision and strategy to drive digitization across the enterprise.",
                "use_case": null,
                "is_none": "0"
            },
            {
                "id": "2247",
                "question_id": "464",
                "option_code": "noa1a5",
                "option_name": "We are on the start of our journey to explore Industry 4.0 possibilities",
                "answer_description": "The company leadership is aware of the Industry 4.0 concept and is currently in the process of discovering what an Industry 4.0 strategy can offer to the enterprise.",
                "use_case": null,
                "is_none": "0"
            },
            {
                "id": "1499",
                "question_id": "464",
                "option_code": "noa1a3",
                "option_name": "We do not have a formal Industry 4.0 strategy as a part of the corporate strategy",
                "answer_description": "An Industry 4.0 strategy is not formally in the enterprise strategy yet, as there are other plans that needed to be prioritized.",
                "use_case": null,
                "is_none": "1"
            }
        ],
        "savedans": [],
        "getPrevChapterId": 0,
        "getNextChapterId": "41",
        "progress": 0,
        "isQuestion": "1",
        "total_completed": "0 \/ 4",
        "is_validated": 1,
        "is_datasubmitted": 0,
        "is_result": 0,
        "is_locked": 0
    }
];
    if (!quesDataItems.length) {
        return null;
    }
    const quesDataItem = quesDataItems[0];
    const block = quesDataItem.block;
    if (block == 'block') {
        return <Redirect to="/home" />;
    }
    const [isAssessmentSupport, setIsAssessmentSupport] = useState(false)
    const [isTicket, setIsTicket] = useState(true);
    const catData = quesDataItem.category;
    const subcatData = quesDataItem.subcategory;
    const question = quesDataItem.question;
    const isQuestion = quesDataItem.isQuestion;

    if (!question) {
        return null;

    }
    const [activeRightTab, setActiveRightTab] = useState(null);
    const [clickedCardNo, setclickedCardNo] = useState(null);
    const [clickedCardText, setclickedCardText] = useState(null);
    const [clickedCardTitle, setclickedCardTitle] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorState, setErrorState] = useState(0);
    const [allRangeValueArr, setAllRangeValueArr] = useState({});
    const [qlength, setQlenth] = useState('');
    // const [qlinelength, setQlinelenth] = useState(null);

    const HandleRightTab = (index) => {
        if (activeRightTab === index) {
            setActiveRightTab(null);
        } else {
            setActiveRightTab(index);
        }
    };
    useEffect(() => {
        if (props.hashvalue && props.hashvalue != null) {
            setIsAssessmentSupport(true);

        }
        sectionLock(1)

        return () => {
            sectionLock(0)
        }
    }, [])


    const sectionLock = async (status = 1) => {
        const { category, uid, pid } = quesDataItem;
        const apiUrl = `/sectionlock`;
        await serviceProvider.post(apiUrl, { uid, plant_id: pid, section_id: category.id, type: 1, status }, true)
    }
    const handleSupportAst = () => {
        setIsAssessmentSupport(true);
        setActiveRightTab(null);
        setclickedCardNo(null);
        setclickedCardText(null);
        setclickedCardTitle(null);
    }
    const HandleClickedCardAction = (index, text, items) => {
        setIsAssessmentSupport(false);
        if (clickedCardNo === index) {
            setActiveRightTab(null);
            setclickedCardNo(null);
            setclickedCardText(null);
            setclickedCardTitle(null);
        } else {
            setActiveRightTab(0);
            setclickedCardNo(index);
            setclickedCardText(text);
            setclickedCardTitle(items);

        }
    };
    const TabPanel = (props) => {
        const { activeRightTab, itemIdex, children } = props;
        return (
            <div key={itemIdex} style={{ display: activeRightTab === itemIdex ? "" : "none" }}>
                {children}
            </div>
        );
    };
    let history = useHistory();

    const slug = props.slug;
    // console.log(activeRightTab);
    const prevAction = (e) => {
        setIsAssessmentSupport(false);
        props.setHashvalue(null);
        var href = $(e.target).attr("data-href");
        var scid = $(e.target).attr("data-scid");
        var pqid = $(e.target).attr("data-pqid");
        history.push(href);
        props.onScid(scid);
        props.onQid(pqid);
    };
    const nextAction = (e) => {
    setIsAssessmentSupport(false);
    props.setHashvalue(null);
        e.preventDefault();

        $(".asst-next-btn").attr("disabled", "disabled");
        setErrorMsg("");
        var haserror = 0,
            ans = "";
        var qid = $(e.target).attr("data-qid"),
            scid = $(e.target).attr("data-scid"),
            nchid = $(e.target).attr("data-nchid"),
            layout = $(e.target).attr("data-layout"),
            url = $(e.target).attr("data-href"),
            chapurl = $(e.target).attr("data-chapurl");

        // var new_url=scid+"/"+qid;
        //window.history.pushState("data","Title",new_url);

        var uid = quesDataItem.uid;
        var pid = quesDataItem.pid;
        if (layout == 1) {
            if (!$(".optselyn").hasClass("activeY")) {
                setErrorMsg("Please select an option");
                setErrorState(1);
                haserror = 1;
            } else {
                setErrorState(0);
                haserror = 0;
                ans = $(".activeY").data("val");
            }
        } else if (layout == 4) {
            if ($('input[name="singlebox"]:checked').length == 0) {
                setErrorMsg("Please select an option");
                setErrorState(1);
                haserror = 1;
            } else {
                setErrorState(0);
                haserror = 0;
                var getans = "";
                $('input[name="singlebox"]:checked').each(function () {
                    var val = $(this).val();
                    getans = getans + val + ",";
                });
                ans = getans.slice(0, -1);
            }
        } else if (layout == 8) {
            if ($('input[name="multibox"]:checked').length == 0) {
                setErrorMsg("Please select an option");
                setErrorState(1);
                haserror = 1;
            } else {
                setErrorState(0);
                haserror = 0;
                var getans = "";
                $('input[name="multibox"]:checked').each(function () {
                    var val = $(this).val();
                    getans = getans + val + ",";
                });
                ans = getans.slice(0, -1);
            }
        } else if (layout == 12) {
            setErrorState(0);
            haserror = 0;
            ans = $('input[name="range"]').val();
        }
        else if (layout == 15) {
            setErrorState(0);
            haserror = 0;
            var getans = [];
            $('input[name="line_value[]"]').each(function () {
                var lineid = $(this).data("id");
                var val = $(this).val();
                getans.push({
                    option_id: lineid,
                    line_id: val,
                });
            });
            ans = JSON.stringify(getans);
            if (layout == 15 && qid == 622) {
                setErrorState(0);
                haserror = 0;
                var getans = [];
                $('input[name="line_value[]"]').each(function () {
                    var val = parseInt($(this).val());

                    var oldval = parseInt($(this).attr("old-value"));

                    if (val > oldval) {
                        setErrorMsg("You can't be in a higher OpEx level than company's maximum");
                        setErrorState(1);
                        haserror = 1;
                    }
                });
            }
        }

        else if (layout == 17) {
            var totclass = 1;
            var inclass = 1;
            $(".multi-tab-ans-wrap").each(function () {
                totclass = totclass + 1;
                if (!$(this).hasClass("active")) {
                    setErrorMsg("Please select an option");
                    $(this).children(".choose-one-card").addClass("asst-card-error");
                } else {
                    inclass = parseInt(inclass) + 1;
                }
            });


            $(".ddl-wrapper.active1").each(function () {
                var id = $(this).attr('id');

                if ($(this).find("." + id).is(':checked')) {


                } else {
                    inclass = parseInt(inclass) + 1;
                    setErrorMsg("Please select Line(s)");
                    $("." + id).parent().parent().parent().parent().parent().parent(".card-btn-sec").parent(".choose-one-card").addClass("asst-card-error");
                }

            });


            if (totclass == inclass) {
                setErrorState(0);
                haserror = 0;
            } else {
                setErrorState(1);
                haserror = 1;
            }

            if (haserror == 0) {

                var getans = [];

                for (const [key, value] of Object.entries(allRangeValueArr)) {
                    // console.log(key);
                    var a = value.toString();
                    getans.push({
                        option_id: key,
                        // option_id: qid,
                        line_id: a,
                    });
                }

                ans = JSON.stringify(getans);

            }

        } else if (layout == 18) {
            setErrorState(0);
            haserror = 0;
            var getans = [];
            $('input[name="range"]').each(function () {
                var lineid = $(this).attr("id");
                console.log($(this));
                var val = $(this).val();
                getans.push({
                    option_id: lineid,
                    line_id: val,
                });
            });
            ans = JSON.stringify(getans);
        } else {
            var totclass = 1;
            var inclass = 1;

            $(".single-line-wrap").each(function () {
                if (!$(this).hasClass("active")) {
                    setErrorMsg("Please select an option");
                    inclass = inclass + 1;

                }
            });


            var temp1 = 0;
            $(".ddl-wrapper").each(function () {

                var id = $(this).attr('id');

                if ($(this).find("." + id).is(':checked')) {
                    temp1 = 1;
                }

            });

            if (temp1 == 0 && (!$(".allline").hasClass('active') && !$(".noline ").hasClass('active'))) {
                inclass = parseInt(inclass) + 1;
                setErrorMsg("Please select Line(s)");

            }




            if (totclass == inclass) {
                setErrorState(0);
                haserror = 0;
            } else {
                setErrorState(1);
                haserror = 1;
            }

            if (haserror == 0) {

                var getans = [];

                for (const [key, value] of Object.entries(allRangeValueArr)) {

                    var a = value.toString();
                    if (a != '') {

                        getans.push({
                            option_id: qid,
                            line_id: a,
                        });
                    }
                }

                ans = JSON.stringify(getans);
            }
        }

        if (haserror == 1) {
            $(".asst-next-btn").removeAttr("disabled");
        }
        if (haserror == 0) {
            serviceProvider
                .post(
                    "/assessmentmaturity?type=saveanswer",
                    JSON.stringify({ section_id: scid, question_id: qid, user_id: uid, plant_id: pid, option_id: ans, nchid: nchid, layout: layout }),
                    true
                )
                .then((response) => {
                    var obj = response.data;
                    console.log(obj);
                    if (obj.error == "error") {
                        setErrorMsg("Answer cannot be saved. Please try again.");
                    } else {
                        if (obj.nqid == 0 && obj.nchid == 0) {
                            window.location.href = chapurl;
                        } else {
                            if (obj.nqid == 0) {
                                props.onScid(obj.nchid);
                                props.onQid($.trim(obj.ncq));
                                var purl = `/assessment/start/${slug}/` + obj.nchid;
                                var pnurl = `/assessment/start/${slug}/` + obj.nchid + "/" + $.trim(obj.ncq);
                            } else {
                                props.onScid(scid);
                                props.onQid($.trim(obj.nqid));
                                var purl = `/assessment/start/${slug}/` + scid + "/" + $.trim(obj.nqid);
                                var pnurl = purl;
                            }
                            history.push(pnurl);
                        }
                    }
                });
        }
    };
    let prevarr = [];
    if (quesDataItem.getPrevChapterId.length) {
        prevarr = quesDataItem.getPrevChapterId.split("@");
    }

    // 1 - Yes/No
    // 4 - Single Select
    // 8 - Multi Checkbox
    // 12 - Range Slider
    // 15 - Increment/Decrement
    // 16 - Lines
    // 17 - Multi Choice Lines
    // 18 - Multi Range
    // http:///assessment/start/4/506 - 1
    // http:///assessment/start/4/665 - 4
    // http:///assessment/start/4/508 - 8
    // http:///assessment/start/4/621 - 15
    // http:///assessment/start/4/518 - 12
    // http:///assessment/start/35/618 - 16
    // http:///assessment/start/39/615 - 17
    // http:///assessment/start/20/673 - 18
    // onErrorMsg={setErrorMsg} onErrorState={setErrorState}
    let view = (
            <MultiCheckbox
                clickedCardNo={clickedCardNo}
                HandleClickedCardAction={HandleClickedCardAction}
                onErrorMsg={setErrorMsg}
                onErrorState={setErrorState}
                setQlenth={setQlenth}
                qlength={qlength}
                setActiveRightTab={setActiveRightTab}
                activeRightTab={activeRightTab}
                quesData={quesDataItems}
                {...props}
            />
        );
    
    return (
        <>
            {/* Page Title */}
            <Helmet>
                <title>{subcatData.subcategory_name} | SmarterChains</title>
                <meta name="description" content={quesDataItem.title} />
                <link href={require("./question.scss").default} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            {/* Breadcrumbs */}
            <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/${slug}`}>Assessment</BreadcrumbsItem>
            <BreadcrumbsItem to={`/assessment/chapter/${slug}/${catData.id}`}>{catData.category_name}</BreadcrumbsItem>
            <BreadcrumbsItem to="#">{subcatData.subcategory_name}</BreadcrumbsItem>
            {/* Question and Answer page main wrapper starts here */}
            <div className="qna-main-wrapper">
                {/* Another wrapper for this page which has been divided into two parts.*/}
                <div className="multi-frame-wrapper walk-step-4">
                    {/* Left part is having two horizontal divisions. top-content and bottom-content. */}
                    <div className="multi-left-frame">
                        <div className="choose-one-content walk-step-2">
                            {/* top-content has question and answer */}
                            <div className="top-content">
                                <div class="error-box" style={{ display: errorState ? "block" : "none" }}>
                                    {errorMsg}
                                </div>
                                <div className="choose-one-content">
                                    <div className={`choose-one-question ${qlength}`}>
                                        {/* <div className="cir-img-div">
                                            <img src={`${apiEndpoints.baseUrl}/${question.question_profile_pic}`} alt="true" />
                                        </div> */}
                                        <div className="text-wrap">
                                        <div className="text-div" dangerouslySetInnerHTML={{ __html: question.question }}></div>

                                        {question.question_description && <div className={clickedCardNo === 'des' ? activeRightTab == 0 ? "qns-rgt card-right active" : "qns-rgt card-right" : "qns-rgt card-right"}>

                                            <img
                                                className="inactive"
                                                src={require("../../assets/img/multi-choice/non-active-info.svg").default}
                                                alt="true"
                                                onClick={() => HandleClickedCardAction('des', question.question_description, question.question)}
                                            />
                                            <img
                                                src={require("../../assets/img/multi-choice/active-info.svg").default}
                                                onClick={() => HandleClickedCardAction('des', question.question_description, question.question)}
                                                className="active"
                                                alt="true"
                                            />
                                        </div>}
                                        </div>
                                    </div>
                                    {view}
                                </div>
                            </div>
                            {/* bottom-content has buttons.*/}
                            <div className="bottom-content walk-step-5">
                                {quesDataItem.pqid ? (
                                    <button
                                        data-qid={question.id}
                                        data-scid={quesDataItem.scid}
                                        data-pqid={quesDataItem.pqid}
                                        data-layout={question.question_layout}
                                        data-href={`/assessment/start/${slug}/${quesDataItem.scid}/${quesDataItem.pqid}`}
                                        class="btn-back asst-prev-btn 1"
                                        onClick={(e) => prevAction(e)}
                                    >
                                        Back
                                    </button>
                                ) : prevarr.length ? (
                                    // "<?=base_url()?>assess/quesajax/<?=$slug?>/<?=$prevarr[0]?>/<?=$prevarr[1]?>"
                                    <a
                                        data-qid={question.id}
                                        data-scid={prevarr[0]}
                                        data-pqid={prevarr[1]}
                                        data-layout={question.question_layout}
                                        data-href={`/assessment/start/${slug}/${prevarr[0]}/${prevarr[1]}`}
                                        class="btn-back asst-prev-btn 2"
                                        onClick={(e) => prevAction(e)}
                                    >
                                        Back
                                    </a>
                                ) : (
                                    <a data-href="javascript:void(0)" class="btn-back disabled">
                                        Back
                                    </a>
                                )}
                                <Link className="btn-all-qns" to={`/assessment/questions/${slug}/${catData.id}`}>
                                    All Questions
                                </Link>
                                <button
                                    data-qid={question.id}
                                    data-scid={quesDataItem.scid}
                                    data-nchid={quesDataItem.getNextChapterId}
                                    data-layout={question.question_layout}
                                    data-href={`/assessment/chapter/${slug}`}
                                    data-chapurl={`/assessment/chapter/${slug}/${catData.id}`}
                                    className="btn-next"
                                    onClick={(e) => nextAction(e)}
                                >
                                    Next
                                </button>
                            </div>
                            {/* bottom-content end */}
                        </div>
                    </div>
                    {/* Right part is having more information about the question and answer part. It's also contain support feature in it's bottom */}
                    <div className="multi-right-frame">
                        {/* New section added for chapter progress */}
                        <div className="chapter-progress-wrapper">
                            <div className="chapter-progress-wrap">
                                <div className="header-progress-wrap">
                                <div className="header-progress" >
                                    <p className="progress-title">Chapter Progress:</p>
                                    <h2>{quesDataItem.progress}%</h2>
                                    <div className="header-progress-bar">
                                        <div style={{ width: `${quesDataItem.progress?quesDataItem.progress:0}%` }}></div>
                                    </div>
                                </div></div>
                                <div className="submit-chapter-wrap">
                                    <div className="submit-chapter-count">
                                        <span className="heading">Chapters Completed: </span>
                                        {/* "sections submitted" will be replace from "sections completed" when the all sections will be completed */}
                                        <div className="count">
                                            {quesDataItem.total_completed}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* this div display conditionally when walkthrough has been call on the page. */}
                        <div className="walk-step-3">
                            <img src={require("../../assets/img/ques-walk-img/progress.svg").default} />
                        </div>
                        
                        <div className="muti-right-card">
                            {/* More information header. This header comes conditionally when we click on the i icon of question or answer. */}
                            <div className="card-header" style={{ visibility: activeRightTab === null ? "hidden" : "" }}>
                                {/* <button className={activeRightTab == 0 ? "btn-tab active" : "btn-tab"}>Description</button> */}
                                <div className={activeRightTab == 0 ? "more-information-wrapper active" : "more-information-wrapper"}>
                                    <div className="info-wrapper">
                                        <img
                                            className="info-img"
                                            src={require("../../assets/img/multi-choice/info-icon.svg").default}
                                            alt="true"
                                        />
                                        <span className="info-txt">More Information</span>
                                    </div>
                                    {/* button to close the more information */}
                                    <button className="close-btn" onClick={() => setActiveRightTab(null)}>
                                        <img
                                            className="info-img"
                                            src={require("../../assets/img/multi-choice/info-close.svg").default}
                                            alt="true"
                                        />
                                    </button>
                                </div>
                                {/* <button className={activeRightTab == 1 ? "btn-tab active" : "btn-tab"} onClick={() => HandleRightTab(1)}>
                                    Pictures
                                </button>
                                <button className={activeRightTab == 2 ? "btn-tab active" : "btn-tab"} onClick={() => HandleRightTab(2)}>
                                    Videos
                                </button>
                                <button className={activeRightTab == 3 ? "btn-tab active" : "btn-tab"} onClick={() => HandleRightTab(3)}>
                                    Vendors
                                </button> */}
                            </div>
                            {/* card-header end */}

                            {/* In this div the conditional content for the more information */}
                            <div className={activeRightTab === null ? "card-content mr0" : "card-content"}>
                                <TabPanel itemIdex={null} activeRightTab={activeRightTab}>
                                    <div className="default-tab">
                                        <p>
                                            If you have any questions, please press the info icon
                                            <img
                                                src={require("../../assets/img/multi-choice/non-active-info.svg").default}
                                                className="i-icon"
                                                alt="true"
                                            />
                                        </p>
                                        <div className="ques-bg">
                                            <img className="bg" src={require("../../assets/img/multi-choice/tab-default.svg").default} />
                                        </div>
                                        <div className="border-linedd"></div>
                                    </div>
                                </TabPanel>
                                <TabPanel itemIdex={0} activeRightTab={activeRightTab}>
                                    <div className="tab-question-content">{clickedCardTitle?.replace('<br>', '')}</div>
                                    <div className="desc-content">
                                        <p>
                                            <div dangerouslySetInnerHTML={{ __html: clickedCardText?.replace('<br>', `<span class='q-br'></span>`) }}></div>
                                        </p>
                                    </div>
                                </TabPanel>
                            </div>
                            {/* card-content end */}

                            
                            {/* card-footer end */}
                        </div>
                        {/* muti-right-card end */}

                        {/* Assessment support component call */}
                        {isAssessmentSupport && <AssessmentSupport
                            close={setIsAssessmentSupport}
                            isTicket={isTicket}
                            setIsTicket={setIsTicket}
                            hashvalue={props.hashvalue}
                            setHashvalue={props.setHashvalue}
                            {...props}
                        />
                        }

                        {/* Assessment support component end*/}
                    </div>
                </div>
            </div>
            
        </>
    );
};

// Supportoptions array has been defined here
const supportOptions = [
    {
        key: 1,
        value: 'The question is not clear',
        hasSub: false,
        commentRequired: false
    }, {
        key: 2,
        value: 'An answer is not clear',
        hasSub: true,
        commentRequired: false
    },
    {
        key: 3,
        value: 'An answer is missing',
        hasSub: false,
        commentRequired: true
    },
    {
        key: 4,
        value: 'I would like to report a visual bug',
        hasSub: false,
        commentRequired: true
    },
    {
        key: 5,
        value: 'Other',
        hasSub: false,
        commentRequired: true
    }

]


// Assessment Support component starts here
const AssessmentSupport = (props) => {
    //variable declaration for support feature
    const { close, isTicket, setIsTicket, quesData } = props;
    const [chat, setChat] = useState('');
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true)
    const [tempDelid, setTempDelid] = useState(0)
    const timezone = localStorage.getItem("gettimezone").replace(/^"(.*)"$/, '$1');
    const [tempCmntDelid, setTempCmntDelid] = useState(0)
    //scnv-2251 end
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState('');
    const [commentRequired, setCommentRequired] = useState(false);
    const [payload, setPayload] = useState({ ques_id: 0, user_id: 0, plant_id: 0, feedon: '', feedans: [], comment: false, fbdbid: 0, status: 1 });
    const [isSupActDdl, setIsSupActDdl] = useState(false)
    const [isCollapse, setIsCollapse] = useState(false);
    const [isSubAns, setIsSubAns] = useState(false);
    const [isDelPopup, setIsDelPopup] = useState(false);
    const [clickedTicketDetail, setClickedTicketDetail] = useState('');
    const [loadSupportOptions, setLoadSupportOptions] = useState([...supportOptions]);
    let questionItem;
    let categoryName;
    let assessmentQuestion;
    let assessmentAnswers = [];

    if (quesData.length) {
        questionItem = quesData[0];
        const { category, question, answers } = questionItem;
        if (category) {
            categoryName = category.category_name;
        }
        if (question)
            assessmentQuestion = question.question;
        if (Array.isArray(answers))
            assessmentAnswers = answers.filter(a => +a.is_none !== 1);
    }


    // Function to get feedbacks    
    const getFeedbacks = async () => {
        const { question, uid, pid } = questionItem;
        const response = await serviceProvider.post('/support', { qid: +question?.id, pid: +pid, uid: +uid }, true)
        const { data: supportDetails } = response;
        if (supportDetails) {
            const { feedbacks } = supportDetails;
            if (Array.isArray(feedbacks));
            setFeedbacks(feedbacks);
            if (feedbacks.length) setIsTicket(false);
            setLoading(false);
        }
        setIsEdit(false)
    }
    // Function to get all ticket details
    const getTicketDetails = async (item) => {
        const { question, uid, pid } = questionItem;
        const response = await serviceProvider.post('/support/getcommentsforticket', { qid: +question?.id, pid: +pid, uid: +uid, ticket_id: item.id }, true)
        const { data } = response;
        setClickedTicketDetail({ ...item, comments: data });
    }
    // Function for adding feedback comment
    const addFeedbackComment = async () => {
        try {
            const { question, uid, pid } = questionItem;
            const { id } = clickedTicketDetail;
            await serviceProvider.post('/support/savefeedbackcomment', { qid: +question?.id, pid: +pid, uid: +uid, ticket_id: id, msg: chat,timezone:timezone }, true)
            const response = await serviceProvider.post('/support/getcommentsforticket', { qid: +question?.id, pid: +pid, uid: +uid, ticket_id: +id }, true)
            const { data: comments } = response;
            setClickedTicketDetail({ ...clickedTicketDetail, comments });
            setChat('');
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        const { question, uid, pid } = questionItem;
        if (question) {
            const question_layout = +question?.question_layout;
            payload['ques_id'] = +question?.id;
            payload['user_id'] = +uid;
            payload['plant_id'] = +pid;
            let opts = [];
            if (question_layout === 12 || question_layout === 1 || question_layout === 15) {
                opts = supportOptions.filter(option => option.key !== 2 && option.key !== 3)
                setLoadSupportOptions(opts);
            }
            else if (question_layout === 16 || !assessmentAnswers.length) {
                opts = supportOptions.filter(option => option.key !== 2);
                setLoadSupportOptions(opts);
            }
            setPayload(payload);
            getFeedbacks();
        }

    }, [])

    // Function for edit/delete dropdown
    const HandleDdl = (val) => {

        if (val === isSupActDdl) {
            setIsSupActDdl(false)
        } else {
            setIsSupActDdl(val)
        }
    }
    // Function for cleanup
    const cleanUpPayload = () => setPayload({ ...payload, feedon: '', feedans: [], comment: false, fbdbid: 0, status: 1 });
    // Function for new ticket entry
    const newTicket = () => {
        setIsSubAns(false);
        setIsTicket(true)
        cleanUpPayload();
    }
    //Function for save feedback
    const saveFeedback = async () => {
        try {
            payload['comment'] = payload['comment'] ? payload['comment'] : '';
            payload['timezone'] = timezone;
            const response = await serviceProvider.post('/support/savefeedback', payload, true);
            const { data } = response;
            if (data === 1) {
                setError('This ticket already exists.')
            } else {
                cleanUpPayload();
                getFeedbacks()
            }
        } catch (err) {
            console.error(err)
        }
    }
    // Delete function for comment and reply.
    const deleteFb = async () => {
        try {
            if (tempDelid) {
                await serviceProvider.post('/support/deletefb', { 'ticket_id': tempDelid }, true);
                setIsDelPopup(false);
                setClickedTicketDetail('');
                cleanUpPayload();
                getFeedbacks();

            } else if (tempCmntDelid) {
                await serviceProvider.post('/support/deletefbc', { 'ticket_id': tempCmntDelid }, true);
                getTicketDetails(clickedTicketDetail);
                setIsDelPopup(false);
                // setClickedTicketDetail('');
                // cleanUpPayload();
                // getFeedbacks();

            }

        } catch (err) {
            console.error(err)
        }
    }

    // Function for submit value
    const handleSubmit = () => {
        const { feedans, comment } = payload;
        let hasErrors = false;
        let errorCase = 'feedans';
        setError('');
        setCommentRequired(false);
        switch (+isSubAns) {
            case 2:
                if (!feedans.length) {
                    hasErrors = true;
                }
                break;
            case 3:
            case 4:
            case 5:
                if (!comment) {
                    hasErrors = true;
                    errorCase = 'comment';
                }
                break;
            default:
        }
        if (payload['feedon'] === "") {
            hasErrors = true;
            errorCase = 'feedon';
        }
        if (!hasErrors) {
            saveFeedback();
        } else {
            if (errorCase === 'comment') setCommentRequired(true);
            else if (errorCase === 'feedans') setError('Please select an answer');
            else setError('Please select at least one option');
        }
        //setIsTicket(false);
    }

    // Edit function for the comment
    const HandleEdit = (id) => {
        setError('');
        const feedback = feedbacks.find(feed => feed.id === id)
        setPayload({ ...payload, feedon: feedback.feedon, feedans: feedback.feedans, comment: feedback.comment, fbdbid: +id })
        supportOptions.forEach(option => {
            if (option.value === feedback.feedon)
                setIsSubAns(option.key);
        })
        setIsSupActDdl(false);
        setIsTicket(true)
        setIsEdit(true);
        setClickedTicketDetail('');
    }
    //Function for open delete popup
    const HandleDelete = (val,type) => {
        // scnv-2251
        if(type=='cmnt'){
            setTempCmntDelid(+val)
            setIsSupActDdl(false);
            setIsDelPopup(true);
        }
        // scnv-2251
        else{
            setTempDelid(+val)
            setIsSupActDdl(false);
            setIsDelPopup(true);
        }
    }
    //Function for collapse details
    const HandleDetailsCollapse = (id) => {
        let tmpObj = { ...isCollapse };
        let name = [`collapse${id}`];
        let val = !tmpObj[name];
        setIsCollapse({
            ...tmpObj,
            [name]: val
        })
    }
    //Function to handle sub answers
    const HandleSubAnsList = (e) => {
        let val = e.target.value;
        
        const option = supportOptions.find(opt => opt.key === +val);
        if (option) {
            let latestPayload = { ...payload, feedon: option.value };
            if (+val !== 2) latestPayload = { ...latestPayload, feedans: [] };
            setPayload(latestPayload);
        }
        setIsSubAns(val);
        setError('');
        setCommentRequired(false);
    }
    //Functions to handle sub answers checkbos list
    const HandleSubAnsCheckboxList = (answer) => {

        let feedans = payload['feedans'];
        if (feedans.includes(answer)) {
            feedans = feedans.filter(ans => ans !== answer);
        } else {
            feedans.push(answer)
        }
        setPayload({ ...payload, feedans });
    }
    // Function to handle helpfull div with options yes/no
    const HandleHelpfull = async (e, id, type) => {
        let targetTmp = e.currentTarget;
        targetTmp.parentElement.style.display = "none"
        await serviceProvider.post('/support/helpfull', { 'ticket_id': id, 'type': type }, true);
    }
    // Function to check item status {inprogress/new/answered}
    const itemStatus = {
        "1": "orange",
        "2": "yellow",
        "3": "green",
        "4": "blue"
    }

    React.useEffect(() => {
        document.querySelector('body').onclick = (e) => {
            if (!e.target.closest('.ddl-action')) {
                setIsSupActDdl(false); 
            }
        }
    }, [])
    // function to add comment
    const addComment = (e) => {
        setPayload({ ...payload, comment: e.target.value })
    }
    // resize function for textarea
    let resize = (text) => {
        text.style.height = '5.8rem';
        text.style.height = text.scrollHeight + 'px';
    }
    
    const addChat = (e) => {
        setChat(e.target.value);
        if (e.target.scrollHeight > 58) { setTimeout(() => { resize(e.target) }, 0); }
    }
    const HandleTextResize=(e)=>{
        if (e.scrollHeight > 58) { setTimeout(() => { resize(e) }, 0); }
    }
    // resize function for textarea end
    // Function to handle reply for edit
    const handleEditRplyTxt = (id, action, msg) => {
        let replyticket = document.getElementById(id);
        switch (action) {
            case 'open':
                replyticket.classList.add('edit')
                replyticket.querySelector('.sup-reply-div textarea').value = msg;
                HandleTextResize(replyticket.querySelector('.sup-reply-div textarea'));
                break;

            case 'close': replyticket.classList.remove('edit')
                break;
            default: '';
        }
        setIsSupActDdl(false)
    }
    // Function to save reply after edit
    const saveEditRply = async (cardId, cmnt, cmntIndx) => {
        let replyticket = document.getElementById(cardId);
        let txtVal = replyticket.querySelector('.sup-reply-div textarea').value;
        try {
            if (txtVal) {
                const { question, uid, pid } = questionItem;
                const { id } = clickedTicketDetail;
                await serviceProvider.post('/support/editfeedbackcomment', { qid: +question?.id, pid: +pid, uid: +uid, ticket_id: cmnt.id, msg: txtVal }, true)
                const response = await serviceProvider.post('/support/getcommentsforticket', { qid: +question?.id, pid: +pid, uid: +uid, ticket_id: +id }, true)
                const { data: comments } = response;
                setClickedTicketDetail({ ...clickedTicketDetail, comments });
                setChat('');
                replyticket.classList.remove('edit');
            }
        } catch (err) {
            console.error(err);
        }
    }
    // scnv-2251 end


    if (props.hashvalue && props.hashvalue != null) {
        var abc = props.hashvalue.replace("#", "");
        $("document").ready(function () {
            $("#" + abc).trigger("click");
        });
    }
    React.useEffect(() => {
        document.onclick = (e) => {
            if (e.target.closest('.refreshReply')) {
                var hrefs = e.target.toString().match(/(((https?:\/\/)|(www\.))[^\s]+)/g);
                if(hrefs.length){
                    var href = hrefs[hrefs.length-1];
                    if(window.location.href == href){
                        location.reload();
                    }
                }
            }
        }
    }, [])
    return (
        <>
            <div className={`asst-support-wrapper ${isDelPopup?'firefox-blur':''}`}>
                {!clickedTicketDetail ?
                    <>
                        {/* support card that will come conditionally */}
                        <div className="asst-sup-card">
                            {/* support card header starts here which is containing heading,title and close button */}
                            <div className="sup-card-header">
                                <div className="sup-h1-heading">
                                    <span className="sup-heading-icon" dangerouslySetInnerHTML={{ __html: SupportTilteImg }}></span>
                                    <span>Support</span>
                                    <button className="sup-close-icon" onClick={() => close(false)}>
                                        <span dangerouslySetInnerHTML={{ __html: CrossImg }}></span>
                                    </button>
                                </div>
                            </div>
                            {/* sup-card-header end */}

                            {/* support-card body starts here which is containing category name and assessment question and their multichoice options.
                                also this part is having textarea for extra comment.
                            */}
                            <div className="sup-card-body">
                                <h2 className="sup-body-title">{categoryName}</h2>
                                <p className="sup-body-p"> {assessmentQuestion}</p>
                                {isTicket && !loading ?
                                    <>

                                        <p className="sup-body-h2">
                                            {!!error && <><span className="sup-error-msg">{error}</span> <br /></>}
                                            How can we help you?
                                        </p>
                                        {loadSupportOptions.map(option => (
                                            <div>
                                                <label className="sup-checkbox">
                                                    <div className="svg-icon-div">
                                                        <input type="radio" name="ansRad" value={option.key} checked={option.value === payload.feedon} onChange={(e) => HandleSubAnsList(e)} />
                                                        <div className="svg-icon" dangerouslySetInnerHTML={{ __html: CheckBox }}></div>
                                                    </div>
                                                    <div className="svg-text">
                                                        <span>
                                                            {option.value}
                                                        </span>
                                                    </div>
                                                </label>
                                                {option.hasSub && (
                                                    <Collapse in={isSubAns == option.key ? true : false}>
                                                        <div className="sub-list-ans">
                                                            {assessmentAnswers.map(answer => (
                                                                <div>
                                                                    <label className="sup-sub-checkbox">
                                                                        <div className="svg-icon-div">
                                                                            <input type="checkbox" checked={payload.feedans.includes(answer.option_name?.replace('<br>', ''))} onClick={() => HandleSubAnsCheckboxList(answer.option_name?.replace('<br>', ''))} />
                                                                            <div className="svg-icon" dangerouslySetInnerHTML={{ __html: SqureChk }}></div>
                                                                        </div>
                                                                        <div className="svg-text">
                                                                            <span>
                                                                                {answer.option_name?.replace('<br>', '')}
                                                                            </span>
                                                                        </div>
                                                                    </label>
                                                                </div>))
                                                            }
                                                        </div>
                                                    </Collapse>
                                                )
                                                }
                                            </div>
                                        ))}
                                        <div className="additional-cmnt-div">
                                            <h2 className="cmt-h2">Additional Comments</h2>
                                            <div className={`cmnt-txtarea-wrapper-div ${commentRequired ? 'show-error' : ''}`}>
                                                <textarea className="cmnt-txtarea" onChange={addComment}>{payload.comment ? payload.comment : ''}</textarea>
                                                {commentRequired && <span className="error-msg">This field is required</span>}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="sup-mragin-min">
                                        {feedbacks.map((item, ind) => (
                                            <div className="s1-ticket-card bg-updt" onClick={() => getTicketDetails(item)} style={{ cursor: "pointer" }}>
                                                <div className="header-ticket">
                                                    <div className="lft-sec">
                                                        <button className="ticket-url" id={`${item.id}`}>
                                                            #{item.id}
                                                        </button>
                                                        <div className={`ddl-action ${isSupActDdl === item.id ? 'active' : ''}`} onClick={(e) => { e.stopPropagation() }}>

                                                            {(item.is_replyed == 0 && itemStatus[item.status] !== 'green')&& (<button className="three-ddl" onClick={() => HandleDdl(item.id)}>
                                                                <span dangerouslySetInnerHTML={{ __html: ThreeDots }}></span>
                                                            </button>)}
                                                            <div className="ddl-list">
                                                                <button className="btn-act" onClick={() => HandleEdit(item.id)}>Edit</button>
                                                                <button className="btn-act" onClick={() => HandleDelete(item.id)}>Delete</button>
                                                            </div>

                                                        </div>
                                                        {+item.is_edited === 1 ? <span className="edited">Edited</span> : ''}
                                                    </div>
                                                    <div className="rgt-sec">
                                                        <span className={`status-tag ${(item.is_replyed == 1 && item.status != 3) ? itemStatus[4] : itemStatus[item.status]}`}>{itemStatus[item.status] === 'green' ? 'RESOLVED'
                                                            : (item.is_replyed == 0 && itemStatus[item.status] === 'yellow') ? 'IN PROGRESS'
                                                                : (item.is_replyed == 1 && itemStatus[4] === 'blue') ? 'ANSWERED' : 'NEW'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="body-ticket">
                                                    <p className="body-ticket-type">
                                                        {item.feedon}
                                                    </p>

                                                    {!!item.feedans.length && <p className="body-ticket-title">
                                                        {item.feedans.map(answer => (<>- {answer}<br /></>))}
                                                    </p>}
                                                    {item.comment && <Collapse in={isCollapse[`collapse${item.id}`]}>
                                                        <div className="body-ticket-detail">
                                                            <p style={{ whiteSpace: 'pre-wrap' }}>
                                                                {item.comment}
                                                            </p>
                                                        </div>
                                                    </Collapse>}

                                                    {item.comment &&
                                                        <div className="view-details-div">
                                                            <button className={isCollapse[`collapse${item.id}`] ? 'view-collapse collapsed' : 'view-collapse'} onClick={(e) => { HandleDetailsCollapse(item.id); e.stopPropagation(); }}>
                                                                {isCollapse[`collapse${item.id}`] ? 'View Less' : 'View Details'}
                                                                <span className="ddl-ico" dangerouslySetInnerHTML={{ __html: ViewCollapseIcon }}></span>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            {/* sup-card-body end */}
                            {/* Support card footer is containing two buttons which comes conditionally. */}
                            <div className="sup-card-footer">
                                {!isEdit ?
                                    <>
                                        {isTicket ?
                                            <button className="btn-sup-sub" onClick={handleSubmit}>
                                                Submit
                                            </button>
                                            :
                                            <button className="btn-sup-sub" onClick={newTicket}>
                                                + New Ticket
                                            </button>
                                        }
                                    </>
                                    :
                                    <>
                                        <button className="btn-sup-cancel" onClick={() => { setIsEdit(false); setIsTicket(false); cleanUpPayload(); }}>
                                            Cancel
                                        </button>
                                        <button className="btn-sup-sub" onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </>
                                }
                            </div>
                            {/* sup-card-footer end */}
                        </div>
                        {/* asst-sup-card 1 end*/}
                    </>
                    :
                    <>
                        {/* second conditional wrapper of assessment support card */}
                        <div className="asst-sup-card">
                            {/* header is containg ticket id and back button. we can view the full ticket after click on the card */}
                            <div className="sup-card-header pl-less">
                                <div className="sup-h1-heading">
                                    <button className="sup-d-back" onClick={() => { setClickedTicketDetail(''); props.setHashvalue(null); setIsSupActDdl(false) }} >
                                        <img src={require('../../assets/img/assessment-support/back-btn.svg').default} alt="" />
                                    </button>
                                    <span>#{clickedTicketDetail.id}</span>
                                </div>
                            </div>
                            {/* sup-card-header end */}
                            {/* Support card body having reply and comments with edit and delete functionalities. */}
                            <div className="sup-card-body pb-2rem">
                                <h2 className="sup-body-title">{categoryName}</h2>
                                <p className="sup-body-p">
                                    {assessmentQuestion}
                                </p>
                                <div className="sup-mragin-min">
                                    <div className="s1-ticket-card">
                                        <div className="header-ticket">
                                            <div className="lft-sec">
                                                <span className="ticket-url">
                                                    #{clickedTicketDetail.id}
                                                </span>
                                                <div className={`ddl-action ${isSupActDdl === `d${clickedTicketDetail.id}` ? 'active' : ''}`}>
                                                    {(clickedTicketDetail.is_replyed == 0 && itemStatus[clickedTicketDetail.status] !== 'green') && (<button className="three-ddl" onClick={() => HandleDdl(`d${clickedTicketDetail.id}`)}>
                                                        <span dangerouslySetInnerHTML={{ __html: ThreeDots }}></span>
                                                    </button>
                                                    )}
                                                    <div className="ddl-list">
                                                        <button className="btn-act" onClick={() => HandleEdit(clickedTicketDetail.id)}>Edit</button>
                                                        <button className="btn-act" onClick={() => HandleDelete(clickedTicketDetail.id)}>Delete</button>
                                                    </div>
                                                </div>
                                                {clickedTicketDetail.is_edited==1 ? <span className="edited">Edited</span> : ''}
                                                {console.log(clickedTicketDetail.is_edited)}
                                            </div>
                                            <div className="rgt-sec">

                                                <span className={`status-tag ${(clickedTicketDetail.is_replyed == 1 && clickedTicketDetail.status != 3) ? itemStatus[4] : itemStatus[clickedTicketDetail.status]}`}>{itemStatus[clickedTicketDetail.status] === 'green' ? 'RESOLVED'
                                                    : (clickedTicketDetail.is_replyed == 0 && itemStatus[clickedTicketDetail.status] === 'yellow') ? 'IN PROGRESS'
                                                        : (clickedTicketDetail.is_replyed == 1 && itemStatus[4] === 'blue') ? 'ANSWERED' : 'NEW'}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="body-ticket">
                                            <p className="body-ticket-type">
                                                {clickedTicketDetail.feedon}
                                            </p>
                                            <p className="body-ticket-title">


                                                {clickedTicketDetail.feedans.map(answer => (<>- {answer}<br /></>))}
                                            </p>
                                            {clickedTicketDetail.comment && <Collapse in={isCollapse[`collapse_click${clickedTicketDetail.id}`]}>
                                                <div className="body-ticket-detail">
                                                    <p>
                                                        {clickedTicketDetail.comment}
                                                    </p>
                                                </div>
                                            </Collapse>}

                                            {clickedTicketDetail.comment && <div className="view-details-div">
                                                <button className={isCollapse[`collapse_click${clickedTicketDetail.id}`] ? 'view-collapse collapsed' : 'view-collapse'} onClick={() => HandleDetailsCollapse(`_click${clickedTicketDetail.id}`)}>
                                                    {isCollapse[`collapse_click${clickedTicketDetail.id}`] ? 'View Less' : 'View Details'}
                                                    <span className="ddl-ico" dangerouslySetInnerHTML={{ __html: ViewCollapseIcon }}></span>
                                                </button>
                                            </div>}
                                        </div>
                                    </div>
                                    {clickedTicketDetail.comments.length > 0 && <div className="s1-ticket-card">
                                        <div className="body-ticket">
                                            {!!clickedTicketDetail.comments.length && (
                                                <div className="wrap-reply">
                                                    {clickedTicketDetail.comments.map((comment, cmntIndx) => <>

                                                        {!!+comment.is_admin && <>
                                                            <div className="sa-reply-logo">
                                                                {comment.is_admin && (<img src={require('../../assets/img/assessment-support/logo-smarterchains.svg').default} alt="SmarterChains" />)}
                                                            </div>
                                                            <p className="reply-sa-txt a">
                                                                {comment.msg}
                                                            </p>
                                                            {(comment.is_helpful == 0) && <div className="was-helpfull" >
                                                                <span>Was this answer helpful? </span>
                                                                <button className="btn-usefull" onClick={(e) => HandleHelpfull(e, comment.id, 1)}>
                                                                    Yes
                                                                </button>
                                                                <button className="btn-usefull" onClick={(e) => HandleHelpfull(e, comment.id, 2)}>
                                                                    No
                                                                </button>
                                                            </div>}
                                                        </>
                                                        }
                                                        {!+comment.is_admin && (
                                                            <div className="replied-ticket" id={`cmnt-${cmntIndx}`}>
                                                                <div className="user-title-cir">
                                                                    {!!comment.profile_pic &&(<>
                                                                        <div className="sa-reply-logo">
                                                                        <img className="img_cir_aqa" src={`${apiEndpoints.baseUrl}/assets/user-profile/${comment.profile_pic}`} alt={comment.name + ' ' + comment.surname} />
                                                                            
                                                                        </div>
                                                                        <span className="user-n">{comment.name + ' ' + comment.surname}</span>
                                                                        </>
                                                                    )}
                                                                    {!comment.profile_pic && (
                                                                        <>
                                                                            <div className={`cir-user ${comment.avcolor}`}>
                                                                                {comment.name.charAt(0)+comment.surname.charAt(0)}
                                                                            </div>
                                                                            <span className="user-n">{comment.name + ' ' + comment.surname}</span>
                                                                        </>
                                                                    )}
                                                                    <div className={`ddl-action ${isSupActDdl === comment.id ? 'active' : ''}`}>

                                                                        {comment.reply_id==0&&<button className="three-ddl" onClick={() => HandleDdl(comment.id)}>
                                                                            <span dangerouslySetInnerHTML={{ __html: ThreeDots }}></span>
                                                                        </button>}
                                                                        <div className="ddl-list">
                                                                            <button className="btn-act" onClick={() => handleEditRplyTxt(`cmnt-${cmntIndx}`, 'open', comment.msg)}>Edit</button>
                                                                            <button className="btn-act" onClick={() => HandleDelete(comment.id,'cmnt')}>Delete</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="rp-text-div">
                                                                    <div className="sup-reply-div">
                                                                        <textarea className="txt-sup-reply" type="text" placeholder="Reply" onChange={(e)=>HandleTextResize(e.target)}></textarea>
                                                                    </div>
                                                                    <div className="rply-btn-wrp">
                                                                        <button className="btn-edt edt-s" onClick={() => saveEditRply(`cmnt-${cmntIndx}`, comment, cmntIndx)}>Save</button>
                                                                        <button className="btn-edt edt-c"
                                                                            onClick={() => handleEditRplyTxt(`cmnt-${cmntIndx}`, 'close')}
                                                                        >Cancel</button>
                                                                    </div>
                                                                </div>
                                                                <p className="reply-sa-txt">
                                                                    {comment.msg}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </>
                                                    )}
                                                </div>
                                            )}

                                            {clickedTicketDetail.comments.length && <div className="sup-reply-div">
                                                <textarea className="txt-sup-reply" type="text" placeholder="Reply" value={chat} onChange={(e) => addChat(e)}></textarea>
                                                <button className="send-rep-btn" onClick={addFeedbackComment}>
                                                    <img src={require('../../assets/img/assessment-support/send-reply.svg').default} />
                                                </button>
                                            </div>}

                                        </div>
                                    </div>}
                                </div>
                            </div>
                            {/* sup-card-body end */}
                        </div>
                        {/* asst-sup-card 2 end */}
                    </>
                }

            </div>
            {/* asst-support-wrapper end */}
            {/* Delete popup starts here */}
            {isDelPopup &&
                <div className="sup-ticket-bgpopup">
                    <div className="sup-ticket-delpopup">
                        <p className="txt-popup">
                            Are you sure you would like to delete this ticket permanently?
                        </p>
                        <div className="popup-btn-div-flex">
                            <button className="btn-act" onClick={() => { setTempDelid(0); setIsDelPopup(false); }}>
                                Cancel
                            </button>
                            <button className="btn-act del" onClick={deleteFb}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            }
            {/* 
                ---- dynaimic classes ---- 
                "show-error"
            */}
        </>
    )
}


const HeaderProgressBar = (props) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const usid = user ? user.uid : 0;
    const groupid = (localStorage.getItem("group_id"));
    const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
    const cid = getLastItem(window.location.pathname);
    const [results, setResults] = useState(0);
    const [text, setText] = useState('');
    const [ishide, setIshide] = useState(0);
    const segments = window.location.pathname.slice(1).split("/");
    
    const url = segments[0];
    const slug = segments[1];
    useEffect(() => {
        switch (slug) {
            case "chapter":
                serviceProvider
            .get("/assessmentmaturity?param=progress", true,
                
                {   slug:segments[2],
                    id: segments[3],
                    uid: usid,
                },
            )
            .then(function (response) {
                
                setResults(response.data[0].progress);
                setText('Section Progress');
            });
                break;
            case "start":
                serviceProvider
            .get("/assessmentmaturity?param=progress", true,{
                
                    slug:segments[2],
                    sid:segments[3],
                    uid: usid,
                    typr: 'start',
                
            })
            .then(function (response) {
                
                if(response.data[0].is_datasubmitted == 1 || response.data[0].is_result==1){
                    setIshide(1);
                    setText('');
                }else{
                    setText('Chapter Progress');
                    setResults(response.data[0].progress);
                }
                
            });
                break;
            case "questions":
                serviceProvider
            .get("/assessmentmaturity?param=progress", true,{
                
                    slug:segments[2],
                    id: segments[3],
                    uid: usid,
                
            })
            .then(function (response) {
                
                setResults(response.data[0].progress);
                setText('Section Progress');
            });
                break;
            default:
                serviceProvider
            .get("/assessmentmaturity?param=progress",true, {
                
                    slug:slug,
                    id: 0,
                    uid: usid,
                
            })
            .then(function (response) {
                setResults(response.data[0].progress);
                setText('Overall Progress');
            });
                break;
        }
    }, [, segments[2], segments[3], slug,segments[4]])
    
    
    return (
        <div className="header-progress" >
            <p className="progress-title">Chapter Progress:</p>
            <h2>{results}%</h2>
            <div className="header-progress-bar">
                <div style={{ width: `${results?results:0}%` }}></div>
            </div>
        </div>
    );
    
};
