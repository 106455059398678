import styled from "styled-components";

export const TopSectionBtn = styled.button`
    background:${({ disabled }) => disabled ? '#cbcfd2' : '#0789cf'};
    color:${({ disabled }) => disabled ? '#7d868c ' : '#fff'};
    pointer-events:${({ disabled }) => disabled ? 'none ' : 'all'};
    border:0.1rem solid ${({ disabled }) => disabled ? '#cbcfd2' : '#0789cf'};
    padding:1.5rem 2rem;
    border-radius:0.6rem;
    font-size:1.6rem;
    display:inline-block;
`;

export const ButtonWrapper = styled.div`
    display:flex;
    gap:2rem;
    margin-bottom:3rem;
`;

export const HeaderFilterWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
`;
//Left Card Wrapper Deepika
export const MainCardWrapper = styled.div`
    pointer-events:${({disabled})=>disabled?'none':'all'};
    opacity:${({disabled})=>disabled?0.5:1};
    *{
        user-select: none;
    }
        
`
export const TabCardWrapper = styled.div`
    margin-bottom: 1rem;
`
export const TabContentWraaper = styled.div`
    border: 0.1rem solid #ebf5fa;
    -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
    box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
    border-radius: 0.8rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #7d868c;
    position: relative;
    padding: 1.5rem;
    // Css for tabs
    .MuiBox-root{
        padding: 2.4rem 0 0 2.4rem !important;
        @media(max-width: 740px){
            padding: 18px 0 0 0 !important;
        }
    }
    .MuiTabs-root{
        align-items: center;
        position: relative;
        .MuiButtonBase-root{
            min-width: 15rem;
            width: max-content;
            .MuiTab-wrapper{
                font-size: 1.4rem !important;
                text-transform: capitalize;
            }
        }
        .PrivateTabIndicator-root-1{
            background-color: #118acb !important;
            padding: 0.2rem;
            border-bottom-left-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
        }
        .MuiButtonBase-root{
            &.MuiTabScrollButton-root{
                &.MuiTabs-scrollButtons{
                    &.MuiTabs-scrollButtonsDesktop{
                        height: 2.4rem !important;
                        width: 2.4rem !important;
                        min-width: unset;
                        background: #fff;
                        .MuiSvgIcon-root{
                            &.MuiSvgIcon-fontSizeSmall{
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                } 
            }
        } 
    }
    .MuiTabs-scroller{
        border-bottom: 0.1rem solid #dee2e6;
        border-bottom-left-radius: 0.4rem;
        border-bottom-right-radius: 0.4rem;
    }
`
export const RightAlignTextWrapper = styled.div`
    padding-right: 1rem;
    @media(max-width: 740px){
        padding: 10px 10px 0 5px;
        width: 100%;
    }
`
export const HeadText = styled.div`
    font-size: 1.2rem;
    font-weight: 400;
    color: #cbcfd2;
`
export const SubText = styled.span`
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 400;
    margin-left: 0.5rem;
    color: ${({ green, orange, red }) => green ? '#27ae60' : orange ? '#f2994a' : red ? '#eb5757' : ''};
`
export const AddDragBtn = styled.button`
    border-right: 0.15rem solid #ebf5fa;
    border-left: 0.15rem solid #ebf5fa;
    padding: 0.5rem 2rem;
    i{
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
`
export const CollapseBtn = styled.button`
    transition: unset !important;
    padding: 2rem;
    @media(max-width: 740px){
        width: max-content;
    }
    i{
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    &.active{
        background: #118acb;
    }
`
export const RightTabCardWrapper = styled.div`
    margin-bottom: 2rem;
`

export const TabPanelContent = styled.div`
    p{
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
    ul{
        padding-left: 3.5rem;
        margin-bottom: 1rem;
        li{
            font-size: 1.3rem;
            list-style-type: disc;
        }
    }
    .bc_leadership {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        gap:10px;
        i {
          display: inline-block;
          height: 2.4rem;
          width: 2.4rem;
          margin-right: 1rem;
          svg {
            height: 100%;
            width: 100%;
          }
        }
      }`
export const LossTopWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    border-radius: 0.4rem;
    padding: 1.2rem 0.5rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    margin: 0 4rem 3rem 4rem;
    @media(max-width: 976px){
        margin: 0 10px 20px 10px;
    }
`

export const VendorText = styled.span`
    display: block;
    color: #4d4d4f;
    margin-top: 1rem;
    font-size: 1.4rem;
`
//Left Card Wrapper Deepika


export const InnerTabWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.1rem solid #ebf5fa;
    -webkit-box-shadow: 0px 3px 12px rgba(21, 27, 38, 0.03);
    border-radius: 0.8rem;
    height: 6rem;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    position: relative;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    @media(max-width: 976px){
        height: auto;
    }
`
export const InnerTBRightCol = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    @media(max-width: 740px){
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .recovery-txt{
        border-left: 0.15rem solid #ebf5fa;
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: #cbcfd2;
        @media(max-width: 740px){
            flex: 1;
        }
        span{
            color: #118acb;
            display: inline-block;
            margin-right: 0.4rem;
        }
    }
`
export const LossTableWrap = styled.div`
    border: 0.1rem solid #ebf5fa;
    -webkit-box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
    box-shadow: 0px 0.3rem 0.4rem rgba(21, 27, 38, 0.03);
    border-radius: 0.8rem;
    padding: 1.5rem;
    display: block;
    margin-bottom: 2rem;
`
export const LossScrollTableWrap = styled.div`
    overflow-x: auto;
    width: 100%;
    .responsive-loss-table{
        border-collapse: collapse;
        min-width:100%;
        // width: 55rem;
        thead{
            th.loss-table-col{
                color: #118acb;
                border-bottom: 0 !important;
            }
        }
        .loss-table-col{
            color: #828282;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 1rem 1.5rem;
            text-align: left;
            border-bottom: 0.1rem solid #f0f0f0;
            border-right: 0.1rem solid #f0f0f0;
            &:last-child{
                border-right: 0;
            }
            &.bottom{
                background: #ebf5fa;
                color: #4d4d4f;
                border-bottom: 0;
            }
            &.orange{
                margin-top: 1rem;
                color: #eb5757;
            }
            &.green{
                margin-top: 1rem;
                color: #27ae60;
            }
            &.blue{
                margin-top: 1rem;
                color: #118acb;
            }
        }
    }
`
export const MaintainWrap = styled.div`
    padding-top: 1rem;
    width: 100%;
`
export const MaintainRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    &::last-child{
        margin-bottom: 0;
    }
`
export const LeftMainCol = styled.div`
    display: flex;
    align-items: center;
    .accord-txt{
        display: inline-block;
        font-size: 1.2rem;
        font-weight: 400;
        margin-left: 1rem;
    }
`
export const RightMainCol = styled.div`
    .main-val {
        display: flex;
        background: #f0f0f0;
        margin-bottom: 0px;
        text-align: center;
        width: 8rem;
        height: 2.5rem;
        color: #7d868c;
        font-size: 1.2rem;
        font-weight: 500;
        border: 0.1rem solid #7fcdbb;
        border-radius: 0.4rem;
        align-items: center;
        justify-content: center;
    }
`
export const ListInvestWrap = styled.div`
    border: 0.1rem solid #f0f0f0;
    padding: 1.5rem;
    border-radius: 0.6rem;
`
export const ListRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    align-items: center;
    .blue-txt{
        color: #118acb;
        font-size: 1.4rem;
    }
`
export const UpfrontListRow = styled.div`
    border-top: 0.1rem solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    .black-txt{
        color: #4d4d4f;
        font-size: 1.4rem;
    }
`
export const ListValWrap = styled.div`
    background: #f0f0f0;
    color: #f2994a;
    font-weight: 400;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    width: 12rem;
    text-align: center;
    .money-txt{
        display: inline-block;
        margin-right: 0.5rem;
    }
`
export const GoListWrap = styled.div`
    border: 0.1rem solid #f0f0f0;
    border-radius: 0.6rem;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    .blue-txt{
        color: #118acb;
        font-size: 1.4rem;
    }
`
export const UserWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    .user-txt{
        color: #4d4d4f;
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 400;
        margin-right: 1rem;
    }
    .user-num{
        color: #4d4d4f;
        text-align: center;
        width: 8rem;
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 400;
        border: 0.1rem solid #e3e3e3;
        border-radius: 0.6rem;
        padding: 0.4rem 0;
    }
`

export const ScndRgtTabWrapper = styled.div`
    margin-bottom: 2rem;
`


export const BlueRecoverText = styled.div`
    border-left: 0.15rem solid #ebf5fa;
    border-right: 0.15rem solid #ebf5fa;
    .loss_recov_det{
        font-size: 1.2rem;
        font-weight: 400;
        color: #cbcfd2;
        padding: 0 1rem;
        text-align: right;
        min-width: 12rem;
        span{
            color: #118acb;
        }
    }
`
export const BCPopupWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index:1;
    background: #ffffff;
    border: 0.1rem solid #f0f0f0;
    border-radius: 0.6rem;
    padding: 2rem;
    min-height:100%;
    @media(max-width: 976px){
        width: 100%;
    }
`
export const BCPopCard = styled.div`
    

`
export const BCPopHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .line-name{
        font-size: 1.6rem;
        font-weight: 600;
        color: #4d4d4f;
    }
    .close-pop{
        height: 2.5rem;
        width: 2.5rem;
        i{
            display; inline-block;
            width: 100%;
            height: 100%;
            svg{
                height: 100%;
                width: 100%;
                path{
                    fill: #4d4d4f;
                }
            }
        }
    }
`
export const BCPopBody = styled.div`
    .bc-table{
        margin-top: 2rem;
        table-layout: fixed;
        width: 100%;
        @media(max-width: 976px){
            display: block;
        }
        thead{
            @media(max-width: 976px){
                display: none;
            }
            th{
                font-weight: 600;
                vertical-align: middle;
                border-bottom: 0;
                font-size: 1.2rem;
                padding-bottom: 1rem;
                line-height: 1.5rem;
                text-align: center;
            }
            .bc_first_col{
                text-align: left;
                font-size: 1.6rem;
                color: #0789cf;
            }
            .bc_second_col{
                color: #ce1212;
            }
            .bc_third_col{
                color: #0789cf;
            }
            .bc_fourth_col{
                color: #27ae60;
            }
        }
        tbody{
            td{
                font-size: 1.2rem;
                padding: 0.5rem;
                width: 1%;
                .sm-col{
                    display: none;
                }
                @media(max-width: 976px){
                    display: block;
                    margin-bottom: 10px;
                    width: 100%;
                    .sm-col{
                        font-weight: 600;
                        vertical-align: middle;
                        border-bottom: 0;
                        font-size: 1.2rem;
                        padding-bottom: 1rem;
                        line-height: 1.5rem;
                        text-align: left;
                        display: block;
                        margin-bottom: 5px;
                        @media(max-width: 976px){
                            padding-bottom: 0;
                            line-height: unset;
                        }
                        &.bc_first_col{
                            font-size: 1.6rem;
                            color: #0789cf;
                        }
                        &.bc_second_col{
                            color: #ce1212;
                        }
                        &.bc_third_col{
                            color: #0789cf;
                        }
                        &.bc_fourth_col{
                            color: #27ae60;
                        }
                    }       
                }
                &:first-child{
                    color: #7d868c;
                    position: relative;
                    padding-left: 1rem;
                    ul *{
                        list-style:disc;
                    }
                }
                .roadmap-value-input{
                    width:100%;
                    height:100%;
                    padding: 1rem 0.5rem 1rem 1rem;
                    border:0px !important;
                    box-shadow:none !important;
                    background:transparent;
                    outline:0 !important;
                }
                .value-blue{
                    display: flex;
                    justify-content: space-between;
                    padding-right:1rem;
                    align-items:center;
                    background: #ffffff;
                    border: 0.4rem solid #118acb;
                    position:relative;
                    @media(max-width: 976px){
                        justify-content: unset;
                        gap: 2px;
                        width: max-content;
                    }
                }
                .value-red{
                    display: flex;
                    justify-content: space-between;
                    background: #f0f0f0;
                    border: 0.4rem solid #fc6444;
                    position:relative;
                    padding-right:1rem;
                    align-items:center;
                    @media(max-width: 976px){
                        justify-content: unset;
                        gap: 2px;
                        width: max-content;
                        justify-content: center;
                        min-width: 90px;
                    }
                }
                .value-green{
                    display: flex;
                    justify-content: space-between;
                    background: #f0f0f0;
                    border: 0.4rem solid #3fde74;
                    position:relative;
                    padding-right:1rem;
                    align-items:center;
                    @media(max-width: 976px){
                        justify-content: unset;
                        gap: 2px;
                        width: max-content;
                        justify-content: center;
                        min-width: 90px;
                    }
                }
            }
        }
    }
`
export const LossLevelWrapper = styled.div`
    border: 0.1rem solid #f0f0f0;
    border-radius: 0.6rem;
    padding: 2rem;
    margin-top: 2rem;
    position: relative;
`
export const LossLevelWrap = styled.div`
    padding-bottom: 2rem;
    .level-title{
        font-size: 1.6rem;
        font-weight: 600;
    }
`
export const LossLevelList = styled.div`
    display: flex;
    align-items: center;
    border: 0.1rem solid #7d868c;
    border-radius: 0.4rem;
    font-size: 1.4rem;
    min-height: 4.5rem;
    padding: 0 0.5rem;
    width: 100%;
    margin-bottom:1.5rem;
    select{
        min-height: 4.5rem;
        border: 0;
        padding: 0 0.5rem;
        width: 100%;
        &:focus{
            outline: 0;
        }
    }
    .line-txt{
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;    
    }
    ul{
        display: none;
    }
`
export const CloseTabBtn = styled.button`
    outline: none;
    border: 0;
    background: transparent;
    padding: 0 1.5rem;
    i{
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
        svg{
            height: 100%;
            width: 100%;
        }
    }
    @media(max-width: 976px){
        display: none;
    }
`
export const LossLineList= styled.div`
    .loss-btn{
        display: flex;
        align-items: center;
        transition: 0.3s all ease-in-out;
        padding: 1.5rem 0;
        .txt{
            font-size: 1.6rem;
            display: inline-block;
            color: #4d4d4f;
            padding-right: 1rem;
        }
        i{
            display: inline-block;
            height: 1.4rem;
            width: 1.4rem;
            line-height:0;
            svg{
                height: 100%;
                width: 100%;
            }
        }
        &:hover{
            .txt{
                color: #118acb;
            }
            i{
                svg{
                    path{
                        fill: #118acb;
                    }
                }
            }
        }
    }
`

export const InnerTBLeftCol = styled.div`
  display: inline-flex;
  align-items: center;
  width: 40%;
  .inner-tab-txt {
    color: #7d868c;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    padding-left: 2.5rem;
    padding-right: 2rem;
  }
  @media(max-width:600px){
    width:100%;
    padding: 12px 0px 12px 10px;
  }
`;


