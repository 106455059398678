 
// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { Root } from "./roots/roots";
import ProductsContextProvider from "./contexts/ProductsContext";
import CartContextProvider from "./contexts/CartContext";
import { ThroughProvider } from "react-through";

//import "pace-js";
//import "pace-js/themes/yellow/pace-theme.css";
ReactDOM.render(
	<React.StrictMode>
		<ProductsContextProvider>
			<CartContextProvider>
				<ThroughProvider>
					<Root />
				</ThroughProvider>
			</CartContextProvider>
		</ProductsContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

