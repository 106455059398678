import React, { useEffect, useRef } from "react";
import serviceProvider from '../../services/axios'
import { useState } from 'react';
import $, { isEmptyObject } from 'jquery';
import { number } from "prop-types";



export const SolutionView = (props) => {

	 const validData = props.validResult;
	  if (!validData.length) {
		return null;
	  }
	  const validDatanew = validData[0];
	  const solutions = validDatanew.solution[0];
	  const [activePopTab1, setActivePopTab1] = useState(1);
     
 
   return (
      <>
		
                    <div className="businesscase-popup-wrap">
                        <div className="businesscase-popup">
                            <div className="busc-head">
                                <div className="text-wrapper">
                                    {/* Please add red , green and blue as per requirement <span className="num-col green">1</span> */}
                                    <span className={`num-col ${solutions.node_type == 1 ? 'green' : (solutions.node_type == 2 ? 'red' : (solutions.node_type == 3 ? 'blue' : '')) }`}>{validDatanew.number}</span>
                                    <span className="title">{solutions.name}</span>
                                </div>
                                <button className="close-btn" onClick={() => props.setActivePopUp(!props.activePopUp)}>
                                    <img src={require("../../assets/img/business-case-img/close.svg").default} alt="Polygon" />
                                </button>
                            </div>
                            <div className="tab-title">
                                <button className={`btn ${activePopTab1 == 1 ? 'active' : ''}`} onClick={() => setActivePopTab1(1)}>Description</button>
                                <button className={`btn ${activePopTab1 == 2 ? 'active' : ''}`} onClick={() => setActivePopTab1(2)}>Benefits</button>
                                <button className={`btn ${activePopTab1 == 3 ? 'active' : ''}`} onClick={() => setActivePopTab1(3)}>Vendors</button>
                            </div>
                            <div className="busc-tab-sec">
                                <TabPane2 index={1} activeIndex={activePopTab1} {...props}>
                                    <div className="tab-detail-wrap solutionpopup">
										   <div dangerouslySetInnerHTML={{ __html: solutions.description }}></div>
                                    </div>
                                    <div className="busc-footer">
                                        <div className="cost-row">
                                            <img src={require("../../assets/img/business-case-img/coin_blue.svg").default} alt="Polygon" />
                                            <p className="foot-txt">Investment / Start-up Cost</p>
                                            <div className="cost-scnd-col">
                                                <span className="num-txt">$ {solutions.start_cost} K</span>
                                            </div>
                                        </div>
                                        <div className="cost-row">
                                            <img src={require("../../assets/img/business-case-img/coin_blue.svg").default} alt="Polygon" />
                                            <p className="foot-txt">Annual / Ongoing Cost</p>
                                            <div className="cost-scnd-col">
                                                <span className="num-txt">$ {solutions.ongoing_cost} K</span>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane2>
                                <TabPane2 index={2} activeIndex={activePopTab1} {...props}>
                                    <div className="tab-detail-wrap">
                                        <div className="list-items">
										{solutions.benefits.length>0 && solutions.benefits.map((items, i) => {	
										return (
                                            <p><div className="circle"><span className="filled"></span></div><span className="pop-txt">{items.text}</span></p>
											)
										})
										}
                                        </div>
                                    </div>
                                </TabPane2>
                                <TabPane2 index={3} activeIndex={activePopTab1} {...props}>
                                    <div className="tab-detail-wrap">
                                        <div className="vendors-container">
                                            <div className="vendors-row">
											{solutions.vendors.length>0 && solutions.vendors.map((items, i) => {	
										return (
                                                <div className="vendors-col">
                                                    <div className="vendors-card">
                                                        <div className="vendors-imgbox">
                                                            <img src={require(`../../assets/img/business-case-img/vendors/${items.name}.png`).default} alt="Polygon" />
                                                        </div>
                                                        <p className="vendors-name">
                                                            {items.name}
                                                        </p>
                                                    </div>
                                                </div>
												)
										})
										}
												
                                            </div>
                                        </div>
                                        {/*vendors-container*/}
                                    </div>
                                </TabPane2>
                            </div>
                        </div>
                    </div>    

      </>
   );
};
const TabPane2 = (props) => {
    const { index, activeIndex, children } = props;
    return (
        <>
            {index === activeIndex && <>{children} </>}
        </>
    )
}
