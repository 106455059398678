export const asmtHeadIcon = `<svg width="72" height="88" viewBox="0 0 72 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1_48943)">
  <g filter="url(#filter1_i_1_48943)">
    <path d="M4 0H68V67.1772C68 68.0149 67.4779 68.7639 66.6918 69.0537L37.6708 79.7527C37.237 79.9126 36.7613 79.9173 36.3245 79.766L5.3455 69.0374C4.54013 68.7585 4 67.9998 4 67.1475V0Z" fill="#5677B3"/>
  </g>
  <path d="M28.099 45.9502H31.3185C31.6404 45.9502 31.9087 45.6761 31.9087 45.3472V34.8218C31.9087 34.4928 31.6404 34.2188 31.3185 34.2188H28.099C27.7771 34.2188 27.5088 34.4928 27.5088 34.8218V45.3472C27.5088 45.6761 27.7771 45.9502 28.099 45.9502Z" fill="white"/>
  <path d="M33.1436 32.0796V45.346C33.1436 45.6749 33.4118 45.949 33.7338 45.949H36.9532C37.2752 45.949 37.5435 45.6749 37.5435 45.346V32.0796C37.5435 31.7507 37.2752 31.4766 36.9532 31.4766H33.7338C33.4118 31.4766 33.1436 31.7507 33.1436 32.0796Z" fill="white"/>
  <path d="M38.4004 34.5483V45.3478C38.4004 45.6767 38.6687 45.9508 38.9906 45.9508H42.2101C42.532 45.9508 42.8003 45.6767 42.8003 45.3478V34.5483C42.8003 34.2194 42.532 33.9453 42.2101 33.9453H38.9906C38.6687 33.9453 38.4004 34.2194 38.4004 34.5483Z" fill="white"/>
  <rect width="1.44334" height="9.24387" rx="0.72167" transform="matrix(0.694927 -0.71908 0.694927 0.71908 44.5732 50.3516)" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M35.3586 53.3722C43.087 53.3722 49.352 46.8894 49.352 38.8925C49.352 30.8955 43.087 24.4127 35.3586 24.4127C27.6303 24.4127 21.3652 30.8955 21.3652 38.8925C21.3652 46.8894 27.6303 53.3722 35.3586 53.3722ZM35.3586 54.7849C43.8409 54.7849 50.7172 47.6696 50.7172 38.8925C50.7172 30.1153 43.8409 23 35.3586 23C26.8763 23 20 30.1153 20 38.8925C20 47.6696 26.8763 54.7849 35.3586 54.7849Z" fill="white"/>
</g>
<defs>
  <filter id="filter0_d_1_48943" x="0" y="0" width="72" height="87.876" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_48943"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_48943" result="shape"/>
  </filter>
  <filter id="filter1_i_1_48943" x="4" y="0" width="64" height="80.876" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_48943"/>
  </filter>
</defs>
</svg>
`
export const crossIcon = `<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_344_16892)">
<mask id="mask0_344_16892" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
<rect width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_344_16892)">
<path d="M5.61552 15.5557C5.45932 15.7119 5.20605 15.7119 5.04984 15.5557L4.44886 14.9547C4.29265 14.7985 4.29265 14.5452 4.44886 14.389L8.54984 10.2881C8.70605 10.1318 8.70605 9.87858 8.54984 9.72237L4.44886 5.62138C4.29265 5.46517 4.29265 5.21191 4.44886 5.0557L5.04984 4.45472C5.20605 4.29851 5.45932 4.29851 5.61553 4.45472L9.71651 8.5557C9.87272 8.71191 10.126 8.71191 10.2822 8.5557L14.3832 4.45472C14.5394 4.29851 14.7926 4.29851 14.9489 4.45472L15.5498 5.0557C15.706 5.21191 15.706 5.46517 15.5498 5.62138L11.4489 9.72237C11.2926 9.87858 11.2926 10.1318 11.4489 10.2881L15.5498 14.389C15.706 14.5452 15.706 14.7985 15.5498 14.9547L14.9489 15.5557C14.7926 15.7119 14.5394 15.7119 14.3832 15.5557L10.2822 11.4547C10.126 11.2985 9.87272 11.2985 9.71651 11.4547L5.61552 15.5557Z" fill="white"/>
</g>
</g>
<defs>
<filter id="filter0_d_344_16892" x="0.331787" y="4.33789" width="19.3352" height="19.335" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_344_16892"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_344_16892" result="shape"/>
</filter>
</defs>
</svg>
`
export const bookMark = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1463" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1463)">
<path d="M9.33325 14.667V13.2503L12.7666 9.80033L14.1999 11.2003L10.7499 14.667H9.33325ZM14.6833 10.7337L13.2666 9.31699L13.7333 8.85033C13.8666 8.71699 14.0277 8.65033 14.2166 8.65033C14.4055 8.65033 14.561 8.71699 14.6833 8.85033L15.1499 9.33366C15.2721 9.46699 15.3333 9.62533 15.3333 9.80866C15.3333 9.99199 15.2721 10.1448 15.1499 10.267L14.6833 10.7337ZM2.66659 13.3337C2.29992 13.3337 1.98603 13.2031 1.72492 12.942C1.46381 12.6809 1.33325 12.367 1.33325 12.0003V4.00033C1.33325 3.63366 1.46381 3.31977 1.72492 3.05866C1.98603 2.79755 2.29992 2.66699 2.66659 2.66699H6.66659L7.99992 4.00033H13.3333C13.6999 4.00033 14.0138 4.13088 14.2749 4.39199C14.536 4.6531 14.6666 4.96699 14.6666 5.33366V7.28366C14.3221 7.28366 13.9833 7.32533 13.6499 7.40866C13.3166 7.49199 13.0277 7.65588 12.7833 7.90033L7.38325 13.3337H2.66659Z" fill="white"/>
</g>
</svg>
`
export const dataIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1469" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1469)">
<path d="M4.66658 9.33333C4.85547 9.33333 5.01381 9.26944 5.14159 9.14167C5.26936 9.01389 5.33325 8.85556 5.33325 8.66667C5.33325 8.47778 5.26936 8.31944 5.14159 8.19167C5.01381 8.06389 4.85547 8 4.66658 8C4.4777 8 4.31936 8.06389 4.19159 8.19167C4.06381 8.31944 3.99992 8.47778 3.99992 8.66667C3.99992 8.85556 4.06381 9.01389 4.19159 9.14167C4.31936 9.26944 4.4777 9.33333 4.66658 9.33333ZM4.66658 6.66667C4.85547 6.66667 5.01381 6.60278 5.14159 6.475C5.26936 6.34722 5.33325 6.18889 5.33325 6C5.33325 5.81111 5.26936 5.65278 5.14159 5.525C5.01381 5.39722 4.85547 5.33333 4.66658 5.33333C4.4777 5.33333 4.31936 5.39722 4.19159 5.525C4.06381 5.65278 3.99992 5.81111 3.99992 6C3.99992 6.18889 4.06381 6.34722 4.19159 6.475C4.31936 6.60278 4.4777 6.66667 4.66658 6.66667ZM5.99992 9.33333H11.9999V8H5.99992V9.33333ZM5.99992 6.66667H11.9999V5.33333H5.99992V6.66667ZM5.33325 14V12.6667H2.66659C2.29992 12.6667 1.98603 12.5361 1.72492 12.275C1.46381 12.0139 1.33325 11.7 1.33325 11.3333V3.33333C1.33325 2.96667 1.46381 2.65278 1.72492 2.39167C1.98603 2.13056 2.29992 2 2.66659 2H13.3333C13.6999 2 14.0138 2.13056 14.2749 2.39167C14.536 2.65278 14.6666 2.96667 14.6666 3.33333V11.3333C14.6666 11.7 14.536 12.0139 14.2749 12.275C14.0138 12.5361 13.6999 12.6667 13.3333 12.6667H10.6666V14H5.33325Z" fill="white"/>
</g>
</svg>
`
export const insertIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1481" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1481)">
<path d="M4.66667 11.3333H6V6.66667H4.66667V11.3333ZM7.33333 11.3333H8.66667V4.66667H7.33333V11.3333ZM10 11.3333H11.3333V8.66667H10V11.3333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333Z" fill="white"/>
</g>
</svg>
`
export const benchIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_7_1475" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_7_1475)">
<path d="M1.33325 4.00033V0.666992H4.66658V2.00033H2.66659V4.00033H1.33325ZM13.3333 4.00033V2.00033H11.3333V0.666992H14.6666V4.00033H13.3333ZM1.33325 15.3337V12.0003H2.66659V14.0003H4.66658V15.3337H1.33325ZM11.3333 15.3337V14.0003H13.3333V12.0003H14.6666V15.3337H11.3333ZM4.66658 13.3337C4.29992 13.3337 3.98603 13.2031 3.72492 12.942C3.46381 12.6809 3.33325 12.367 3.33325 12.0003V4.00033C3.33325 3.63366 3.46381 3.31977 3.72492 3.05866C3.98603 2.79755 4.29992 2.66699 4.66658 2.66699H11.3333C11.6999 2.66699 12.0138 2.79755 12.2749 3.05866C12.536 3.31977 12.6666 3.63366 12.6666 4.00033V12.0003C12.6666 12.367 12.536 12.6809 12.2749 12.942C12.0138 13.2031 11.6999 13.3337 11.3333 13.3337H4.66658ZM5.99992 6.66699H9.99992V5.33366H5.99992V6.66699ZM5.99992 8.66699H9.99992V7.33366H5.99992V8.66699ZM5.99992 10.667H9.99992V9.33366H5.99992V10.667Z" fill="white"/>
</g>
</svg>
`
export const bgIcon=`<svg width="163" height="180" viewBox="0 0 163 180" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="143.87" y="0.140067" width="135.466" height="179.62" rx="11.9" transform="rotate(52.935 143.87 0.140067)" stroke="#E7EBF8" stroke-width="0.2"/>
<rect x="140.586" y="75.4202" width="75.4" height="75.4" rx="11.7" transform="rotate(52.935 140.586 75.4202)" stroke="#E7EBF8" stroke-width="0.6"/>
<rect x="138.961" y="40.2801" width="111.675" height="121.365" rx="11.8" transform="rotate(52.935 138.961 40.2801)" stroke="#E7EBF8" stroke-width="0.4"/>
</svg>
`
export const triangleIcon=`<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0L6 8L0 -5.24537e-07L12 0Z" fill="#5677B3"/>
</svg>`

export const automatedIconLoss= `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_48914" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
  <rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_48914)">
  <path d="M10.0002 14V12H7.3335V5.33333H6.00016V7.33333H1.3335V2H6.00016V4H10.0002V2H14.6668V7.33333H10.0002V5.33333H8.66683V10.6667H10.0002V8.66667H14.6668V14H10.0002Z" fill="white"/>
</g>
</svg>`
export const operationaLoss=`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_48920" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
  <rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_48920)">
  <path d="M1.3335 4.00033V0.666992H4.66683V2.00033H2.66683V4.00033H1.3335ZM13.3335 4.00033V2.00033H11.3335V0.666992H14.6668V4.00033H13.3335ZM1.3335 15.3337V12.0003H2.66683V14.0003H4.66683V15.3337H1.3335ZM11.3335 15.3337V14.0003H13.3335V12.0003H14.6668V15.3337H11.3335ZM4.66683 13.3337C4.30016 13.3337 3.98627 13.2031 3.72516 12.942C3.46405 12.6809 3.3335 12.367 3.3335 12.0003V4.00033C3.3335 3.63366 3.46405 3.31977 3.72516 3.05866C3.98627 2.79755 4.30016 2.66699 4.66683 2.66699H11.3335C11.7002 2.66699 12.0141 2.79755 12.2752 3.05866C12.5363 3.31977 12.6668 3.63366 12.6668 4.00033V12.0003C12.6668 12.367 12.5363 12.6809 12.2752 12.942C12.0141 13.2031 11.7002 13.3337 11.3335 13.3337H4.66683ZM6.00016 6.66699H10.0002V5.33366H6.00016V6.66699ZM6.00016 8.66699H10.0002V7.33366H6.00016V8.66699ZM6.00016 10.667H10.0002V9.33366H6.00016V10.667Z" fill="white"/>
</g>
</svg>`
export const insightLoss=`<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1_48926" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
  <rect width="16" height="16" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_1_48926)">
  <path d="M4.66667 11.3333H6V6.66667H4.66667V11.3333ZM7.33333 11.3333H8.66667V4.66667H7.33333V11.3333ZM10 11.3333H11.3333V8.66667H10V11.3333ZM3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H12.6667C13.0333 2 13.3472 2.13056 13.6083 2.39167C13.8694 2.65278 14 2.96667 14 3.33333V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333Z" fill="white"/>
</g>
</svg>`