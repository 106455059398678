/** imported components

  * makeStyles for ===> styling material-ui component.
  * serviceProvider for ===> working with api.
  * PowerBIEmbed for ==> Power BI React component.

**/
import React, { useState, useEffect } from "react";
import serviceProvider from "../../services/axios";
import { PowerBiBresultsView } from "./powerbi-bresults-view";
// PowerBiController start
export const PowerBiBresultsController = (props) => {
  // define variables
  const segments = window.location.pathname.slice(1).split("/");
  const bi_type = segments[1];
  const plant_slug = segments[2];
  const sid = segments[3];
  const [results, setResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const usid = user ? user.uid : 0;

  useEffect(() => {
    props.handleExpandFal();
    const search = async () => {
      const apiUrl = `/powerbi?param=${bi_type}&uid=${usid}&slug=${plant_slug}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      setResults(data);
    };
    search();
  }, []);

  return <PowerBiBresultsView bi_type={bi_type} sid={sid} plant_slug={plant_slug} resultsData={results} {...props} />;
};
