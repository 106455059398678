import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import { Collapse } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ScenarioDropdown from "./ScenarioDropdown";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import serviceProvider from '../../services/axios';

// images that we have used in page

import {
  angleIcon,
  closeSvg,
  collapseIcon,
  collapseOpenIcon,
  infoIcon,
  digitalSvg,
  addTimelineIcon,
  dropArrow,
  deleteIcon,
  upgradeLockIcon,
  loaderSvg,
} from "./svg-step4";

// components that we have used in page

import {
  MainCardWrapper,
  TabCardWrapper,
  TabContentWraaper,
  RightAlignTextWrapper,
  HeadText,
  SubText,
  AddDragBtn,
  CollapseBtn,
  TabPanelContent,
  LossScrollTableWrap,
  InnerTabWrapper,
  InnerTBRightCol,
  LossTableWrap,
  ScndRgtTabWrapper,
  RightHeadingWrap,
  RightCloseBtn,
  TimeLineWrap,
  TimeLineCard,
  ImplementationWrap,
  AddTimeLine,
  LossTopWrap,
  TransFormWrap,
  UpgradeLock,
  InnerTBLeftCol 
} from "./Roadmap-style-step4";

// common components that we have used in page

import {
  MainWrapper,
  SectionWrapper,
  LeftSection,
  RightSection,
  SectionHeader,
  ContentBody,
  ContentFooter,
  HeaderTitle,
  ScenarioSectionWrapper,
  TabButton,
  TBRow,
  TBLeftCol,
  TBRightCol,
  LossWrap,
  LossCol,
  LossRow,
  ProceedPopWrapper,
  ProceedPopCard,
  PopHead,
  PopFooter,
  RecoverablePopWrap,
  RecoverCloseBtn,
  InfoIcon,
  RecoverPopCard,
  ScndTabButton,
  RightAlignTextWrap,
  DefaultScenarioPop,
  DefaultScenarioPopupWrapper,
  LoaderPopupWrap,
  LoaderPop,
} from "./common-roadmap-style";

export const RoadmapStepFullFourView = (props) => {
  const history = useHistory();
  const stepdata = props.stepData;
  //variable declaration
  const [isRecoverPop, setIsRecoverPop] = useState(true);
  const [scenarioList, setScenarioList] = useState(stepdata.getAllScenario);
  const [isActiveCard, setIsActiveCard] = useState(false);
  // Collapse variable
  const [leftOpen, setLeftOpen] = React.useState(false);;
  const [lossLeftDataOpen, setLossLeftDataOpen] = React.useState(false);
  // Popup
  const [openPopup, setOpenPopup] = useState(false);
  // array declaration
  const [leftCards, setLeftCards] = useState(stepdata.nodes);
  const [leftValue, setLeftValue] = React.useState(`l0-0`);
  // Timeline popup variables
  const [addTimeLine, setAddTimeLine] = useState({});
  // Tabs Function
  const handleLeftChange = (event, newValue) => {
    setLeftValue(newValue);
  };
  //Drodown start
  const data = [
    {
      id: 1,
      label: "Foundation Stage"
    },
    {
      id: 2,
      label: "Acceleration Stage"
    },
    {
      id: 3,
      label: "Amplification Stage"
    },
  ];

  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const [selectedItem, setSelectedItem] = useState({});
  const [isDefaultScenarioPopup, setIsDefaultScenarioPopup] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isMakeDefault, setIsMakeDefault] = useState(false);
  const [timelineStartEndObj, setTimelineStartEndObj] = useState({})
  const toggleDropdown = () => setOpen(!isOpen);
  const handleItemClick = (satage_id, nid) => {
    console.log('handleItemClick', { satage_id, nid })
    setSelectedItem(prv=>{
      return {
        ...prv,
        [nid]:satage_id
      }
    });
    setOpen(false);
    serviceProvider.post("/roadmapcomplete?param=updateNodeStage", JSON.stringify({ satage_id: satage_id, nid: nid, pid: stepdata.plant_id, sid: stepdata.sid })).then((response) => {

    });
  }
  //End 
  //  Tabs start
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>

      </>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  // Tabs end
  // Function to handle collapse
  const handleLeftClick = (id, index) => {
    setLeftValue(`l0-${index}`);
    if (leftOpen === id) {
      setLeftOpen(false);
    }
    else {
      setLeftOpen(id);
    }
  };
  // Function to handle inner collapse
  const handleLeftInnerTabClick = (val) => {
    if (lossLeftDataOpen === val) {
      setLossLeftDataOpen(false);
    }
    else {
      setLossLeftDataOpen(val);
    }
  };
  // Function to handle popup
  const handleRightCard = (item) => {
    setIsActiveCard(item);
  }
  // Function to handle timeline
  const handleAddTimeLine = (nid) => {
    console.log('handleAddTimeLine', { nid });
    let timestamp = new Date().getTime();
    let tmpArr = [...addTimeLine[nid]];

    serviceProvider.post("/roadmapcomplete?param=addNodeTimeline", JSON.stringify({ pid: stepdata.plant_id, sid: stepdata.sid, nid: nid })).then((response) => {

      tmpArr.push({ id: response.data, start_of_implementation: `1`, end_of_implementation: `1` })
      setAddTimeLine(prv => {
        return {
          ...prv,
          [nid]: tmpArr
        }
      })

    });
  }

  const handleRemoveTimeLine = (nid, timeline_id) => {
    console.log('handleRemoveTimeLine', { nid, timeline_id });
    let tmpFilteredArray = addTimeLine[nid]?.filter(item => item.id != timeline_id);
    setAddTimeLine(prv => {
      return {
        ...prv,
        [nid]: tmpFilteredArray
      }
    });
    serviceProvider.post("/roadmapcomplete?param=deletenodeTimeline", JSON.stringify({ tid: timeline_id })).then((response) => {
    });

  }

  const yesMakeDefaultFunction = () => {
    setIsDefaultScenarioPopup(false);
    setIsLoader(true);
    let tmpArr = scenarioList.map(i => {
      let tmpObj = i
      if (isMakeDefault.id === tmpObj.id) {
        tmpObj.is_default = 1;
      }
      else {
        tmpObj.is_default = false;
      }
      return tmpObj;
    });
    const tmp_sid = isMakeDefault.id || stepdata.sid;
    serviceProvider.post("/roadmapcomplete?param=makeDefaultScenario", JSON.stringify({ pid: stepdata.plant_id, sid: tmp_sid })).then((response) => {

      let redirectUrl = '/roadmap-complete/step1/' + stepdata.plant_slug + "/" + tmp_sid;
      // window.location.href = '/roadmap-complete/step1/' + stepdata.plant_slug + "/" + isMakeDefault.id;
      history.push(redirectUrl);

    });
    setScenarioList(tmpArr);
  }

  const handleTimeLineChange = (key, nid, timlineCard_id, value, type) => {
    console.log('handleTimeLineChange', { nid, timlineCard_id, value });
    setTimelineStartEndObj(prv => {
      return {
        ...prv,
        [key]: {
          ...prv[key],
          [type]: value,
        }
      }
    })


    serviceProvider.post("/roadmapcomplete?param=updateNodeTimeline", JSON.stringify({ tid: timlineCard_id, value: value, type: type })).then((response) => {



    });
  }
  const saveRoadmap = () => {
    setOpenPopup(false);
    setIsLoader(true);
    serviceProvider.post("/roadmapcomplete?param=results",JSON.stringify({slug:stepdata.plant_slug,sid:stepdata.sid})).then((response) => {

     history.push('/powerbi/sceneriobc/'+stepdata.plant_slug+'/'+stepdata.sid+'');
     
    });
   
  }

  useEffect(() => {
    let getTimelineData = {};
    stepdata?.nodes?.forEach(i => { getTimelineData[i.id] = i.timeline });
    // console.log(getTimelineData);
    setAddTimeLine(getTimelineData);
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.dropdown')) {
        setOpen(false);
      }
    });
    return () => {
      document.removeEventListener('click', (e) => {
        if (!e.target.closest('.dropdown')) {
          setOpen(false);
        }
      });
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/home">{stepdata.plant_name}</BreadcrumbsItem>
      <BreadcrumbsItem to={`/roadmap-complete/step1/${stepdata.plant_slug}/${stepdata.sid}`}>IR4.0 Roadmap Solutions</BreadcrumbsItem>
      <BreadcrumbsItem to={`/roadmap-complete/step2/${stepdata.plant_slug}/${stepdata.sid}`}>IR4.0 Savings</BreadcrumbsItem>
      <BreadcrumbsItem to={`/roadmap-complete/step3/${stepdata.plant_slug}/${stepdata.sid}`}>IR4.0 Investment</BreadcrumbsItem>
      <BreadcrumbsItem to="#">IR4.0 Timeline Implementation</BreadcrumbsItem>
      {/* End */}
      <MainWrapper data-component="MainWrapper" className="step-four">
        <SectionWrapper data-component="SectionWrapper">
          <LeftSection data-component="LeftSection">
            <SectionHeader data-component="SectionHeader">
              <HeaderTitle data-component="HeaderTitle">
                Roadmap
              </HeaderTitle>
            </SectionHeader>
            <LossTopWrap data-component="LossWrap">
              <LossRow data-component="LossRow">
                <LossCol data-component="LossCol">
                  <span className="text">Total Losses</span>
                  <span className="text">Identified:</span>
                  <span className="text orange">{stepdata?.planttotalloss || 0} SR</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">Total Roadmap</span>
                  <span className="text"> Recoverable:</span>
                  <span className="text green">{stepdata?.planttotalrecoverable || 0} SR</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">% of Recoverable</span>
                  <span className="text"> Losses:</span>
                  <span className="text blue">{stepdata?.planttotallossper || 0}%</span>
                </LossCol>
                <LossCol data-component="LossCol">
                  <span className="text">Total Roadmap</span>
                  <span className="text">Investment:</span>
                  <span className="text orange">{stepdata?.planttotalinvestment || 0} SR</span>
                </LossCol>
              </LossRow>
            </LossTopWrap>
            <ContentBody data-component="ContentBody">
              <div>
                {leftCards?.map((card, index) => (
                  <div
                    className="card" key={index}
                  >
                    <MainCardWrapper
                      data-component="MainCardWrapper"
                    >
                      <TabCardWrapper data-component="TabCardWrapper">
                        <TabButton data-component="TabButton">
                          <TBRow data-component="TBRow">
                            <TBLeftCol data-component="TBLeftCol">
                              <button
                                className="drag-btn blue"
                              >
                                <span>{index + 1}</span>
                              </button>
                              <p className='rmap-txt'>
                                {card.name}
                              </p>
                            </TBLeftCol>
                            <TBRightCol data-component="TBRightCol">
                              <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                <RightAlignTextWrap data-component="RightAlignTextWrap">
                                  <HeadText data-component="HeadText">Recoverable: </HeadText>
                                  <SubText data-component="SubText" green>{card.totalrecoverable}SR</SubText>
                                </RightAlignTextWrap>
                                <RightAlignTextWrap data-component="RightAlignTextWrap">
                                  <HeadText data-component="HeadText">Investment: </HeadText>
                                  <SubText data-component="SubText" orange>{card.upfrontInvestment}SR</SubText>
                                </RightAlignTextWrap>
                              </RightAlignTextWrapper>
                              <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftClick(card.id, index)} className={`${leftOpen === card.id ? 'active' : ''}`}>
                                {leftOpen === card.id ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                              </CollapseBtn>
                              <AddDragBtn data-component="AddDragBtn" onClick={() => handleRightCard(card)}>
                                <i dangerouslySetInnerHTML={{ __html: angleIcon }}></i>
                              </AddDragBtn>
                            </TBRightCol>
                          </TBRow>
                        </TabButton>
                        <Collapse in={leftOpen === card.id} timeout="auto" unmountOnExit>
                          <TabContentWraaper data-component="TabContentWraaper">
                            <Tabs
                              orientation="horizontal"
                              variant="scrollable"
                              value={leftValue}
                              onChange={handleLeftChange}
                              aria-label="Vertical tabs example"

                            >
                              <Tab value={`l0-${index}`} label="Description" {...a11yProps(0)} />
                              <Tab value={`l1-${index}`} label="Losses & Recoverables" {...a11yProps(1)} /> 
                            </Tabs>

                            <TabPanel value={leftValue} index={`l0-${index}`}>
                              <TabPanelContent data-component="TabPanelContent">
                                {card.description ?
                                  <div dangerouslySetInnerHTML={{ __html: card.description }} />
                                  :
                                  <h1>Not available</h1>
                                }
                              </TabPanelContent>
                            </TabPanel>
                            <TabPanel value={leftValue} index={`l1-${index}`}>
                              {Array.isArray(card?.sublossdata) &&
                                card?.sublossdata?.map((sublossItem, sIndex) => (
                                  <div key={sIndex}>
                                    <InnerTabWrapper data-component="InnerTabWrapper">
                                      <TBRow data-component="TBRow">
                                        <InnerTBLeftCol data-cmponent="InnerTBLeftCol">
                                          <p className="inner-tab-txt">{sublossItem?.lossCategorytext}</p>
                                        </InnerTBLeftCol>
                                        <InnerTBRightCol data-cmponent="InnerTBRightCol">
                                          <RightAlignTextWrapper data-component="RightAlignTextWrapper">
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Total Category Losses: </HeadText>
                                              <SubText data-component="SubText" orange>{sublossItem?.totallosscategory}SR</SubText>
                                            </RightAlignTextWrap>
                                            <RightAlignTextWrap data-component="RightAlignTextWrap">
                                              <HeadText data-component="HeadText">Recoverable: </HeadText>
                                              <SubText data-component="SubText" green>{sublossItem?.totalcatrecoverable}SR</SubText>
                                            </RightAlignTextWrap>
                                          </RightAlignTextWrapper>
                                          <p className='recovery-txt'>
                                            <span>{sublossItem?.perlosscatrec == 'nan' ? 0 : sublossItem?.perlosscatrec}% </span>
                                            of category <br />losses Recovered
                                          </p>
                                          <CollapseBtn data-component="CollapseBtn" onClick={() => handleLeftInnerTabClick(`loss0-${index}-${sIndex}`)} className={`${lossLeftDataOpen === `loss0-${index}-${sIndex}` ? 'active' : ''}`}>
                                            {lossLeftDataOpen === `loss0-${index}-${sIndex}` ? <i dangerouslySetInnerHTML={{ __html: collapseOpenIcon }}></i> : <i dangerouslySetInnerHTML={{ __html: collapseIcon }}></i>}
                                          </CollapseBtn>
                                        </InnerTBRightCol>
                                      </TBRow>
                                    </InnerTabWrapper>
                                    <Collapse in={lossLeftDataOpen === `loss0-${index}-${sIndex}`} timeout="auto">
                                      <LossTableWrap data-component="LossTableWrap">
                                        <LossScrollTableWrap data-component="LossScrollTableWrap">
                                          <table className='responsive-loss-table'>
                                            <thead>
                                              <tr>
                                                <th className='loss-table-col'>Sublosses</th>
                                                <th className='loss-table-col'>
                                                  Identified<br /> Losses
                                                </th>
                                                <th className='loss-table-col'>Recoverable</th>
                                                <th className='loss-table-col'>% of Subloss<br />recoverable </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Array.isArray(sublossItem?.subloss) && sublossItem?.subloss?.map((subloss) => (
                                                <tr>
                                                  <td className='loss-table-col'>{subloss?.subloss_category_text}</td>
                                                  <td className='loss-table-col orange' dangerouslySetInnerHTML={{ __html: subloss?.totalsublossrecoverable + '<span>SR</span>' }}></td>
                                                  <td className='loss-table-col green' dangerouslySetInnerHTML={{ __html: subloss?.totalsubcatrecoverable + '<span>SR</span>' }}></td>
                                                  <td className='loss-table-col blue'>{subloss?.lossrecoverdp}%</td>
                                                </tr>
                                              ))
                                              }

                                              <tr>
                                                <td className='loss-table-col bottom black'>Total</td>
                                                <td className='loss-table-col bottom black'>{sublossItem?.totallosscategory}<span>SR</span></td>
                                                <td className='loss-table-col bottom black'>{sublossItem?.totalcatrecoverable}<span>SR</span></td>
                                                <td className='loss-table-col bottom black'></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </LossScrollTableWrap>
                                      </LossTableWrap>
                                    </Collapse>
                                  </div>
                                ))
                              }



                              <LossWrap data-component="LossWrap">
                                <LossRow data-component="LossRow">
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">Associated</span>
                                    <span className="text">Losses:</span>
                                    <span className="text green">{card.totalloss} SR</span>
                                  </LossCol>
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">Total Technology</span>
                                    <span className="text"> Recoverable:</span>
                                    <span className="text orange">{card.totalrecoverable} SR</span>
                                  </LossCol>
                                  <LossCol data-component="LossCol" width={33.33}>
                                    <span className="text">% of Total</span>
                                    <span className="text"> Recovered Losses:</span>
                                    <span className="text blue">{card.totallossrecoverdp?.toFixed(2)}%</span>
                                  </LossCol>
                                </LossRow>
                              </LossWrap>
                            </TabPanel>
                          </TabContentWraaper>
                        </Collapse>
                      </TabCardWrapper>
                    </MainCardWrapper>
                  </div>
                ))}
              </div>
            </ContentBody>
          </LeftSection>
          <RightSection data-component="RightSection" smActive={isActiveCard ? true : false}>
            <button className="sm-close-popup-btn" onClick={() => setIsActiveCard(false)}>
              <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
            </button>
            <SectionHeader data-component="SectionHeader">
              <ScenarioSectionWrapper data-component="ScenarioSectionWrapper">
                <ScenarioDropdown
                  options={scenarioList}
                  stepdata={stepdata}
                  setOptions={setScenarioList}
                  setStatus={props.setStatus}
                  callBackOnchange={(obj) => {
                    setIsMakeDefault(obj);
                  }}
                />
              </ScenarioSectionWrapper>
              {stepdata.isDefault == 0 ?
                <label className="default-scenario">
                  <input type="checkbox" onChange={() => setIsDefaultScenarioPopup(true)} />
                  <span>Make this your default scenario to be displayed on Home page</span>
                </label>
                :
                <p>This is your default scenario to be displayed on Home page</p>
              }
            </SectionHeader>
            <ContentBody data-component="ContentBody">
              <h3 className="blue-txt">IR4.0 Timeline Implementation</h3>
              {/* Recoverable Popup */}
              {isRecoverPop &&
                <RecoverablePopWrap data-component="RecoverablePopWrap">
                  <RecoverPopCard data-component="RecoverPopCard">
                    <InfoIcon data-component="InfoIcon">
                      <i dangerouslySetInnerHTML={{ __html: infoIcon }}></i>
                    </InfoIcon>
                    <p>Click on the {`>`} icon to add timelines and transformation stage for each Roadmap step.</p>
                    <RecoverCloseBtn data-component="RecoverCloseBtn" onClick={() => setIsRecoverPop(false)}>
                      <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                    </RecoverCloseBtn>
                  </RecoverPopCard>
                </RecoverablePopWrap>
              }
              {/* Right side popup */}
              {isActiveCard &&
                <ScndRgtTabWrapper data-component="ScndRgtTabWrapper">
                  <RightHeadingWrap data-component="RightHeadingWrap">
                    <h3>{isActiveCard.name}</h3>
                    <RightCloseBtn data-component="RightCloseBtn" onClick={() => setIsActiveCard(false)}>
                      <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                    </RightCloseBtn>
                  </RightHeadingWrap>
                  <ImplementationWrap data-component="ImplementationWrap">
                    <h5>Implementation Timeline</h5>
                    <p>Reccommended implementation period: <span>3 quarters</span></p>
                  </ImplementationWrap>
                  {addTimeLine[isActiveCard?.id]?.map((timlineCard, index) => (
                    <TimeLineWrap data-component="TimeLineWrap" key={index}>
                      <TimeLineCard data-component="TimeLineCard">
                        <div className="text-wrap">
                          <h6>Timeline {index + 1}</h6>
                          {index !== 0 &&
                            <button className="del-btn" onClick={() => handleRemoveTimeLine(isActiveCard?.id, timlineCard.id)}>
                              <i dangerouslySetInnerHTML={{ __html: deleteIcon }}></i>
                            </button>
                          }
                        </div>

                        <div className="text-wrap">
                          <span>Start of Implementation</span>
                          
                          <select data-nid={isActiveCard.id}
                            data-tlb={timlineCard.tlb}
                            autocomplete="off"
                            value={timelineStartEndObj[`${isActiveCard.id}${timlineCard.id}`]?.starts || timlineCard.starts}
                            onChange={(e) => handleTimeLineChange(`${isActiveCard.id}${timlineCard.id}`, isActiveCard.id, timlineCard.id, e.target.value, 'starts')}>
                            <option value="11">Year 1 - Q1</option>
                            <option value="12">Year 1 - Q2</option>
                            <option value="13">Year 1 - Q3</option>
                            <option value="14">Year 1 - Q4</option>
                            <option value="21">Year 2 - Q1</option>
                            <option value="22">Year 2 - Q2</option>
                            <option value="23">Year 2 - Q3</option>
                            <option value="24">Year 2 - Q4</option>
                            <option value="31">Year 3 - Q1</option>
                            <option value="32">Year 3 - Q2</option>
                            <option value="33">Year 3 - Q3</option>
                            <option value="34">Year 3 - Q4</option>
                            <option value="41">Year 4 - Q1</option>
                            <option value="42">Year 4 - Q2</option>
                            <option value="43">Year 4 - Q3</option>
                            <option value="44">Year 4 - Q4</option>
                            <option value="51">Year 5 - Q1</option>
                            <option value="52">Year 5 - Q2</option>
                            <option value="53">Year 5 - Q3</option>
                            <option value="54">Year 5 - Q4</option>
                          </select>
                        </div>
                        <div className="text-wrap">
                          <span>End of Implementation</span>
                          <select data-nid={isActiveCard.id}
                            data-tlb={timlineCard.id}
                            autocomplete="off"
                            onChange={(e) => handleTimeLineChange(`${isActiveCard.id}${timlineCard.id}`, isActiveCard.id, timlineCard.id, e.target.value, 'ends')}
                            value={timelineStartEndObj[`${isActiveCard.id}${timlineCard.id}`]?.ends || timlineCard.ends}>
                            <option value="11">Year 1 - Q1</option>
                            <option value="12">Year 1 - Q2</option>
                            <option value="13">Year 1 - Q3</option>
                            <option value="14">Year 1 - Q4</option>
                            <option value="21">Year 2 - Q1</option>
                            <option value="22">Year 2 - Q2</option>
                            <option value="23">Year 2 - Q3</option>
                            <option value="24">Year 2 - Q4</option>
                            <option value="31">Year 3 - Q1</option>
                            <option value="32">Year 3 - Q2</option>
                            <option value="33">Year 3 - Q3</option>
                            <option value="34">Year 3 - Q4</option>
                            <option value="41">Year 4 - Q1</option>
                            <option value="42">Year 4 - Q2</option>
                            <option value="43">Year 4 - Q3</option>
                            <option value="44">Year 4 - Q4</option>
                            <option value="51">Year 5 - Q1</option>
                            <option value="52">Year 5 - Q2</option>
                            <option value="53">Year 5 - Q3</option>
                            <option value="54">Year 5 - Q4</option>
                          </select>
                        </div>
                      </TimeLineCard>
                    </TimeLineWrap>
                  ))}
                  <AddTimeLine data-component="AddTimeLine" onClick={() => handleAddTimeLine(isActiveCard.id)}>
                    <i dangerouslySetInnerHTML={{ __html: addTimelineIcon }}></i>
                    <span>Add Timeline</span>
                  </AddTimeLine>
                  <TransFormWrap data-component="TransFormWrap">
                    <h6>Transformation Stage</h6>
                    <div className='dropdown'>
                      <div className='dropdown-header' data-nid={isActiveCard.id} onClick={toggleDropdown}>
                        {selectedItem[isActiveCard.id] ? items.find(item => item.id == selectedItem[isActiveCard.id]).label : items.find(i => i.id == isActiveCard.stage_id)?.label || "Select option"}
                        <i className={`icon-svg ${isOpen && "open"}`} dangerouslySetInnerHTML={{ __html: dropArrow }}></i>
                      </div>
                      <div className={`dropdown-body ${isOpen && 'open'}`}>
                        {items?.map(item => (
                          <div data-value={item.id} className={`dropdown-item ${(selectedItem[isActiveCard.id] == item.id || isActiveCard.stage_id == item.id) && 'active'}`} onClick={e => handleItemClick(e.target.id, isActiveCard.id)} id={item.id}>
                            {item.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </TransFormWrap>
                </ScndRgtTabWrapper>
              }
            </ContentBody>
            <ContentFooter data-component="ContentFooter">
              <Link to={`/roadmap-complete/step3/${stepdata.plant_slug}/${stepdata.sid}`} className='prev-btn'>Previous</Link>
              <button className='proceed-btn' onClick={() => setOpenPopup(true)}>Proceed</button>
              {/* Proceed Popup */}
              {openPopup ?
                <ProceedPopWrapper data-component="ProceedPopWrapper">
                  <ProceedPopCard data-component="ProceedPopCard">
                    <PopHead data-component="PopHead">
                      <span>Caution</span>
                      <button className="close-pop" onClick={() => setOpenPopup(false)}>
                        <i dangerouslySetInnerHTML={{ __html: closeSvg }}></i>
                      </button>
                    </PopHead>
                    <p className='pop-txt'>You have unsaved changes. Please save roadmap before proceeding.</p>
                    <PopFooter data-component="PopFooter">
                      <button className='save-btn solid' onClick={saveRoadmap}>Save & Proceed</button>
                      <button className='leave-btn transparent' onClick={() => setOpenPopup(false)}>Leave</button>
                    </PopFooter>
                  </ProceedPopCard>
                </ProceedPopWrapper> : ''}
            </ContentFooter>
          </RightSection>
        </SectionWrapper>
      </MainWrapper>
      {isDefaultScenarioPopup &&
        <DefaultScenarioPopupWrapper data-component="DefaultScenarioPopupWrapper">
          <DefaultScenarioPop data-component="DefaultScenarioPop">
            <h2>Would you like to make this your Default Scenario?</h2>
            <p>The default scenario’s progress and results will displayed on Homepage</p>
            <div class="button-section">
              <button class="cancel" onClick={() => setIsDefaultScenarioPopup(false)}>Cancel</button>
              <button class="submit yes" id="making-default-scenario" onClick={yesMakeDefaultFunction}>Yes, make this my Default Scenario </button>
            </div>
          </DefaultScenarioPop>
        </DefaultScenarioPopupWrapper>
      }
      {isLoader &&
        <LoaderPopupWrap data-component="LoaderPopupWrap">
          <LoaderPop data-component="LoaderPop">
            <img src={require('../../assets/img/roadmap-img/roadmap-loader.gif').default} />
          </LoaderPop>
        </LoaderPopupWrap>

      }
    </>
  );
};

// export default DragDropContainer;
