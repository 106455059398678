/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.

**/

import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
// import "./result.scss";
import "./educate-result.scss";
//ResulttourView component start
export const ResulttourView = (props) => {
  //defining variables here
  const [currentSlide, setCurrentSlide] = useState(0);
  //function handle slide
  const HandleSlide = (index) => {
    setCurrentSlide(index);
    let objDiv = document.querySelector(".training-content");
    // let tabbtn=document.querySelectorAll('.res-tab-btn');
    if (index === 1) {
      document.querySelector("#rs-slide-2").classList.add("z-9999");
    } else {
      document.querySelector("#rs-slide-2").classList.remove("z-9999");
    }
    if (index === 2) {
      document.querySelector("#rs-slide-3").classList.add("z-9999");
    } else {
      document.querySelector("#rs-slide-3").classList.remove("z-9999");
    }
    if (index === 3) {
      let targetDiv = document.querySelector("#rs-slide-4");
      objDiv.scrollTop = 570;
      targetDiv.classList.add("z-9999");
    } else if (index === 4) {
      let targetDiv = document.querySelector("#rs-slide-5");
      objDiv.scrollTop = 1200;
      targetDiv.classList.remove("z-9999");
    } else {
      objDiv.scrollTop = 0;
    }
  };
  //function to handle next previous in slide
  const HandleSlideNextPrev = (ev) => {
    if (ev === "min" && currentSlide > 0) {
      let tmpval = currentSlide - 1;
      HandleSlide(tmpval);
    } else if (currentSlide < 4) {
      let tmpval = currentSlide + 1;
      HandleSlide(tmpval);
    }
  };
  //function to handle chart
  const HandleChart = (e, tabId) => {
    document
      .querySelector(".res-tab-content.active")
      .classList.remove("active");
    document.querySelector(tabId).classList.add("active");
    document.querySelector(".res-tab-btn.active").classList.remove("active");
    e.target.classList.add("active");
  };
  useEffect(() => {
    document.querySelector(".training-content").classList.add("hideOverflow");
    return () => {
      document
        .querySelector(".training-content")
        .classList.remove("hideOverflow");
    };
  });
  // componentwillUnmount
  // useEffect(() => {
  //   return () => {
  //     document.querySelector('.training-content').classList.remove('hideOverflow');
  //   };
  // });
  return (
    <>
      {/* Page Title */}
      <Helmet>
        <title>Result Tour | SmarterChains</title>
        <meta name="description" content="Result" />
      </Helmet>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/#">My Plants</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Assessment Results</BreadcrumbsItem>
      {/* section has three parts
          bg-popup
          popup-slide
          asst-abt-content-body
      */}
      <div className="resulttour-main-wrapper result-2442021 rs-open">
        <div className={`bg-popup-res-tour ${currentSlide > 2 && " bg-trans"}`}></div>
        {/* slide popup 1*/}
        {/* this section has
            header
            label
            title
            modal_slider_footer
        */}
        <div className={`rs-pop-slide-1  slide__mark-1 ${
            currentSlide === 0 ? "" : " rs-d-none"
          }`}>
            {/* this section has image */}
          <header className="slide__item__head">
            <img
              className="slide__item__head_img"
              src={
                require("../../assets/img/result-tour/ass-result1.gif").default
              }
              alt="true"
            />
          </header>
          <h4 className="label">1 of 5</h4>
          <h2 className="title">Welcome to the Assessment Results tour!</h2>
          <p>
            Following Assessment completion, access the results to identify
            where you stand versus the industry and plan your transformation.
            Uncover Insights that pinpoint opportunities across all plant
            operational functions.
          </p>
          {/* footer has
              dots-section
              btn-section
              for navigation
          */}
          <div className="modal__slider__footer">
            <div className="dots__section">
              <button
                aria-label="slide dot"
                type="button"
                className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                onClick={() => HandleSlide(0)}
                disabled={currentSlide === 0 ? true : false}
              />
              <button
                aria-label="slide dot"
                type="button"
                className="
                dot___3c3SI
                carousel__dot
                carousel__dot--0
                carousel__dot--selected
                dot
              "
                onClick={() => HandleSlide(1)}
                disabled={currentSlide === 1 ? true : false}
              />
              <button
                aria-label="slide dot"
                type="button"
                className="
                dot___3c3SI
                carousel__dot carousel__dot--1
                dot
              "
                onClick={() => HandleSlide(2)}
                disabled={currentSlide === 2 ? true : false}
              />
              <button
                aria-label="slide dot"
                type="button"
                className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                onClick={() => HandleSlide(3)}
                disabled={currentSlide === 3 ? true : false}
              />
              <button
                aria-label="slide dot"
                type="button"
                className="
                dot___3c3SI
                carousel__dot carousel__dot--3
                dot
              "
                onClick={() => HandleSlide(4)}
                disabled={currentSlide === 4 ? true : false}
              />
            </div>
            <div className="btn__section">
              <Link to="/quickstartopen" className="wht_link">
                Exit Tour
              </Link>
              <button
                type="button"
                aria-label="next"
                className="
                buttonNext___2mOCa
                carousel__next-button
                btn__nxt
              "
                onClick={() => HandleSlideNextPrev()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        {/* /slide popup 1*/}
        {/* this section has row */}
        <div className="asst-abt-content-body result-page-body">
          {/* this section has col */}
          <div className="row no-gutters px-0">
            {/* col has been divided into three sections
                sec1
                sec1
                chart row
            */}
            <div className="col asst-abt-left">
              {/* this section has 
                  heading 
                  sec-1-row    
              */}
              <div className="sec1">
                <h1 className="res-h1 mb-0">Beddington Processing</h1>
                {/* this section has
                    row
                    res-tab-content
                    res-tab-content
                */}
                <div className="d-flex sec-1-row flex-column">
                  {/* row has two columns */}
                  <div className="row no-gutters px-0">
                    {/* this column has heading */}
                    <div className="col">
                      <h2 className="res-h2">Main Index Score</h2>
                    </div>
                    {/* col end */}
                    {/* this column has button */}
                    <div className="col text-right">
                      <button
                        className="res-tab-btn active"
                        data-target="#nestChart"
                        onClick={(e) => HandleChart(e, "#nestChart")}
                      >
                        Smarterchains Benchmarking
                      </button>
                      <button
                        className="res-tab-btn"
                        data-target="#globalChart"
                        onClick={(e) => HandleChart(e, "#globalChart")}
                      >
                        Industry Benchmarking
                      </button>
                    </div>
                    {/* col end */}
                  </div>
                  {/* row end */}
                  <div className="res-tab-content active" id="nestChart">
                    <div className="row no-gutters res-mt-10">
                      <div id="rs-slide-2" className="rs-slide-2 res-indx-div">
                        <div className="res-percent-div orange">
                          <div className="row no-gutters px-0 align-items-center">
                            <div className="col">
                              <sapn className="indx-scr-txt">
                                Selected Plants
                                <br />
                                Index score
                              </sapn>
                            </div>
                            <div className="col-1" />
                          </div>
                          <p className="percent-text">67%</p>
                          <p className="percent-para">
                            The Index Score represents the overall Industry 4.0
                            maturity of your plant.
                            <span className="percent-para-blue">
                              Your Index Score places your plant in the
                              archetype of:
                            </span>
                          </p>
                          <p className="percent-para-acc">
                            <img
                              src={
                                require("../../assets/img/result-tour/accelerating.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </p>
                        </div>
                        {/* slide popup 2*/}
                        <div
                          className={`rs-pop-slide-2 slide__mark-1 ${
                            currentSlide === 1 ? "" : " rs-d-none"
                          }`}
                        >
                          <h4 className="label">2 of 5</h4>
                          <h2 className="title">Index score</h2>
                          <p>
                            Review the results starting with your Index Score, a
                            benchmarking standard that measures technological
                            &amp; organizational capabilities based on our 10
                            Industry 4.0 Dimensions Framework covering all
                            operational functions.
                          </p>
                          <div className="modal__slider__footer">
                            <div className="dots__section">
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                                onClick={() => HandleSlide(0)}
                                disabled={currentSlide === 0 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot
                carousel__dot--0
                carousel__dot--selected
                dot
              "
                                onClick={() => HandleSlide(1)}
                                disabled={currentSlide === 1 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--1
                dot
              "
                                onClick={() => HandleSlide(2)}
                                disabled={currentSlide === 2 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                                onClick={() => HandleSlide(3)}
                                disabled={currentSlide === 3 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--3
                dot
              "
                                onClick={() => HandleSlide(4)}
                                disabled={currentSlide === 4 ? true : false}
                              />
                            </div>
                            <div className="btn__section">
                              <button
                                className="btn__prv"
                                onClick={() => HandleSlideNextPrev("min")}
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                aria-label="next"
                                className="
                                buttonNext___2mOCa
                                carousel__next-button
                                btn__nxt
                              "
                                onClick={() => HandleSlideNextPrev()}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* /slide popup 2*/}
                      </div>
                      {/*res-indx-div end*/}
                      <div
                        id="rs-slide-3"
                        className="rs-slide-3 col nes-chart-sec"
                      >
                        {/* slide popup 3*/}
                        <div
                          className={`rs-pop-slide-3 slide__mark-1 ${
                            currentSlide === 2 ? "" : " rs-d-none"
                          }`}
                        >
                          <h4 className="label">3 of 5</h4>
                          <h2 className="title">SmarterChains Benchmarking</h2>
                          <p>
                            We use Jung's approach to archetypes, to classify
                            your manufacturing operations. You can benchmark
                            within your company or with the whole industry to
                            expand the comparison.
                          </p>
                          <div className="modal__slider__footer">
                            <div className="dots__section">
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                                onClick={() => HandleSlide(0)}
                                disabled={currentSlide === 0 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot
                carousel__dot--0
                carousel__dot--selected
                dot
              "
                                onClick={() => HandleSlide(1)}
                                disabled={currentSlide === 1 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--1
                dot
              "
                                onClick={() => HandleSlide(2)}
                                disabled={currentSlide === 2 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                                onClick={() => HandleSlide(3)}
                                disabled={currentSlide === 3 ? true : false}
                              />
                              <button
                                aria-label="slide dot"
                                type="button"
                                className="
                dot___3c3SI
                carousel__dot carousel__dot--3
                dot
              "
                                onClick={() => HandleSlide(4)}
                                disabled={currentSlide === 4 ? true : false}
                              />
                            </div>
                            <div className="btn__section">
                              <button
                                className="btn__prv"
                                onClick={() => HandleSlideNextPrev("min")}
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                aria-label="next"
                                className="
                                buttonNext___2mOCa
                                carousel__next-button
                                btn__nxt
                              "
                                onClick={() => HandleSlideNextPrev()}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* /slide popup 3*/}
                        <div className="nes-chart-content">
                          <h2 className="col-12 px-0 nes-chart-title">
                            Smarterchains Benchmarking
                          </h2>
                          <div className="d-flex position-relative w-100 res-p-13">
                            <div className="bar red">
                              <div className="rs-chart-blocks rs-startup">
                                <a href="#" className="rs-btn-lnk stratup-clk">
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/startup-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop stratup-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/startup-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Start Up</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Paper-based processes</li>
                                    <li>Islands of Automation</li>
                                    <li>Simple ERP functionalities</li>
                                    <li>Manual material handling</li>
                                    <li>Integration of key business systems</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#startup"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 1 end */}
                            <div className="bar orange">
                              <div className="rs-chart-blocks rs-transforming">
                                <a
                                  href="#"
                                  className="rs-btn-lnk transforming-clk"
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/transforming-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop transforming-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/transforming-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Transforming</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Mostly automated production</li>
                                    <li>
                                      Vertical integrations of key systems
                                    </li>
                                    <li>
                                      Internet connectivity &amp; standard
                                      cybersecurity
                                    </li>
                                    <li>
                                      Just starting the Industry 4.0 journey
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#transforming"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 2 end */}
                            <div className="bar yellow">
                              <div className="rs-chart-blocks rs-accelerating">
                                <a
                                  href="#"
                                  className="rs-btn-lnk accelerating-clk"
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/accelerating-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop accelerating-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/accelerating-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Accelerating</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Modern, cloud-based systems</li>
                                    <li>Early stages of advanced analytics</li>
                                    <li>Industry 4.0 roadmap in place</li>
                                    <li>
                                      Digital training plans for key roles
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#accelerating"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 3 end */}
                            <div className="bar green">
                              <div className="rs-chart-blocks rs-thriving">
                                <a href="#" className="rs-btn-lnk thriving-clk">
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/thriving-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop thriving-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/thriving-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Thriving</span> archetype:
                                  </p>
                                  <ul>
                                    <li>
                                      Modular &amp; adaptive manufacturing
                                    </li>
                                    <li>Autonomous decision-making systems</li>
                                    <li>Horizontal value-chain integrations</li>
                                    <li>Automation of changeovers</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#thriving"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 4 end */}
                            <div
                              className="bottom-range red short"
                              style={{ left: "6%" }}
                            >
                              <div className="d-flex align-items-end red">
                                Lowest:
                                <span> 6%</span>
                              </div>
                            </div>
                            {/*bttom-range 1 end*/}
                            <div
                              className="bottom-range red"
                              style={{ left: "45%" }}
                            >
                              <div className="d-flex align-items-end">
                                Average:
                                <span> 45%</span>
                              </div>
                            </div>
                            {/*bttom-range 2 end*/}
                            <div
                              className="bottom-range yellow long"
                              style={{ left: "70%" }}
                            >
                              <div className="d-flex align-items-end">
                                Highest:
                                <span> 70%</span>
                              </div>
                            </div>
                            <div
                              className="show-progress mid"
                              style={{ left: "calc(71% - calc(0.06vw * 75))" }}
                            >
                              <p className="mb-0">
                                Selected Plants<span>71%</span>
                              </p>
                              <div className="img-circle" />
                            </div>
                          </div>
                          {/* d-flex end */}
                        </div>
                        {/* nes  chart content end */}
                      </div>
                    </div>
                  </div>
                  {/*  nestle chart tab content end*/}
                  <div className="res-tab-content" id="globalChart">
                    <div className="row no-gutters res-mt-10">
                      <div className="res-indx-div">
                        <div className="res-percent-div orange">
                          <div className="row no-gutters px-0 align-items-center">
                            <div className="col">
                              <sapn className="indx-scr-txt">
                                Index Score Indore
                              </sapn>
                            </div>
                            <div className="col-1" />
                          </div>
                          <p className="percent-text">36%</p>
                          <p className="percent-para">
                            The Index Score represents the overall Industry 4.0
                            maturity of your plant.
                            <span className="percent-para-blue">
                              Your Index Score places your plant in the
                              archetype of:
                            </span>
                          </p>
                          <p className="percent-para-acc">
                            <img
                              src={
                                require("../../assets/img/result-tour/transforming.svg")
                                  .default
                              }
                              alt="true"
                            />
                          </p>
                        </div>
                      </div>
                      {/*res-indx-div end*/}
                      <div className="col nes-chart-sec">
                        <div className="nes-chart-content">
                          <h2 className="col-12 px-0 nes-chart-title">
                            Industry Benchmarking
                          </h2>
                          <div className="d-flex position-relative w-100 res-p-13">
                            <div className="bar red">
                              <div className="rs-chart-blocks rs-startup">
                                <a href="#" className="rs-btn-lnk stratup-clk">
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/startup-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop stratup-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/startup-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Start Up</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Paper-based processes</li>
                                    <li>Islands of Automation</li>
                                    <li>Simple ERP functionalities</li>
                                    <li>Manual material handling</li>
                                    <li>Integration of key business systems</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#startup"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 1 end */}
                            <div className="bar orange">
                              <div className="rs-chart-blocks rs-transforming">
                                <a
                                  href="#"
                                  className="rs-btn-lnk transforming-clk"
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/transforming-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop transforming-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/transforming-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Transforming</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Mostly automated production</li>
                                    <li>
                                      Vertical integrations of key systems
                                    </li>
                                    <li>
                                      Internet connectivity &amp; standard
                                      cybersecurity
                                    </li>
                                    <li>
                                      Just starting the Industry 4.0 journey
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#transforming"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 2 end */}
                            <div className="bar yellow">
                              <div className="rs-chart-blocks rs-accelerating">
                                <a
                                  href="#"
                                  className="rs-btn-lnk accelerating-clk"
                                >
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/accelerating-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop accelerating-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/accelerating-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Accelerating</span> archetype:
                                  </p>
                                  <ul>
                                    <li>Modern, cloud-based systems</li>
                                    <li>Early stages of advanced analytics</li>
                                    <li>Industry 4.0 roadmap in place</li>
                                    <li>
                                      Digital training plans for key roles
                                    </li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#accelerating"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* bar 3 end */}
                            <div className="bar green">
                              <div className="rs-chart-blocks rs-thriving">
                                <a href="#" className="rs-btn-lnk thriving-clk">
                                  <img
                                    src={
                                      require("../../assets/img/result-tour/thriving-btn.svg")
                                        .default
                                    }
                                    alt="true"
                                  />
                                </a>
                                <div
                                  className="rs-startup-pop thriving-pop"
                                  style={{ display: "none" }}
                                >
                                  <div className="rs-startup-header">
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/thriving-pop-label.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                    <a href="#" className="rs-startup-close">
                                      <img
                                        src={
                                          require("../../assets/img/result-tour/index-pop-close.svg")
                                            .default
                                        }
                                        alt="true"
                                      />
                                    </a>
                                  </div>
                                  <p>
                                    Key elements of the
                                    <span>Thriving</span> archetype:
                                  </p>
                                  <ul>
                                    <li>
                                      Modular &amp; adaptive manufacturing
                                    </li>
                                    <li>Autonomous decision-making systems</li>
                                    <li>Horizontal value-chain integrations</li>
                                    <li>Automation of changeovers</li>
                                  </ul>
                                  <a
                                    target="_blank"
                                    href="https://ngversion.karamalegkos.com/plant/moredetail/indore#thriving"
                                    className="more-det-btn"
                                  >
                                    <img
                                      src={
                                        require("../../assets/img/result-tour/more-detail-img.svg")
                                          .default
                                      }
                                      alt="true"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              className="bottom-range red long"
                              style={{ left: "0%" }}
                            >
                              <div className="d-flex align-items-end red">
                                Lowest:
                                <span> 0%</span>
                              </div>
                            </div>
                            {/*bttom-range 1 end*/}
                            <div
                              className="bottom-range orange"
                              style={{ left: "33%" }}
                            >
                              <div className="d-flex align-items-end">
                                Average:
                                <span> 33%</span>
                              </div>
                            </div>
                            {/*bttom-range 2 end*/}
                            <div
                              className="bottom-range yellow short"
                              style={{ left: "67%" }}
                            >
                              <div className="d-flex align-items-end">
                                Highest:
                                <span> 67%</span>
                              </div>
                            </div>
                            {/*bttom-range 3 end*/}
                            <div
                              className="show-progress mid"
                              style={{
                                left: "calc(36% - calc(0.06vw * 76))",
                              }}
                            >
                              <p className="mb-0">
                                Indore&nbsp;<span>36%</span>
                              </p>
                              <div className="img-circle" />
                            </div>
                            {/* show -progress end */}
                          </div>
                          {/* d-flex end */}
                        </div>
                        {/* nes  chart content end */}
                      </div>
                    </div>
                  </div>
                  {/*  gloabl chart tab content end*/}
                </div>
                {/* flex  div end*/}
              </div>
              {/* sec 1 end */}
              <div className="sec1 allshow">
                <div className="d-flex sec-1-row flex-column">
                  <div className="row no-gutters px-0 align-items-center">
                    <div className="col">
                      <h2 className="res-h2 mb-0">Index Per Dimension</h2>
                    </div>
                    {/* col end */}
                    <div
                      className="col px-0 res-tab-content2 active"
                      id="seeBetweenCompany-line"
                    >
                      <div className="d-flex align-items-center">
                        <div className="col px-0">
                          <span className="best-calss">
                            Smarterchains Best in Class
                          </span>
                        </div>
                        {/* col end */}
                        <div className="col px-0">
                          <span className="global-avg comp">
                            Smarterchains Average
                          </span>
                        </div>
                        {/* col end */}
                      </div>
                      {/* d flex end */}
                    </div>
                    {/* col end */}
                    <div
                      className="col px-0 res-tab-content2"
                      id="seeBetweenGlobal-line"
                    >
                      <div className="d-flex align-items-center">
                        <div className="col px-0">
                          <span className="best-calss gbest-calss">
                            Industry Best in Class
                          </span>
                        </div>
                        {/* col end */}
                        <div className="col px-0">
                          <span className="global-avg">Industry Average</span>
                        </div>
                        {/* col end */}
                      </div>
                      {/* d flex end */}
                    </div>
                    {/* col end */}
                    <div className="col text-right dual-tab">
                      <button
                        className="res-tab-btn2"
                        data-target="#seeBetweenCompany"
                      >
                        See between Smarterchains
                      </button>
                      <button
                        className="res-tab-btn2 active"
                        data-target="#seeBetweenGlobal"
                      >
                        See between Industry
                      </button>
                    </div>
                    {/* col end */}
                  </div>
                  {/* row end */}
                  <div id="rs-slide-4" className="rs-slide-4 bchart w-100 px-3">
                    {/* slide popup 4*/}
                    <div
                      className={`rs-pop-slide-4 slide__mark-1 ${
                        currentSlide === 3 ? "" : " rs-d-none"
                      }`}
                    >
                      <h4 className="label">4 of 5</h4>
                      <h2 className="title">Index Score per Dimension</h2>
                      <p>
                        The Index is further analysed into 10 Dimensions - in
                        accordance with our Framework - covering all operational
                        functions of the factory. Each Dimension is benchmarked
                        within your company or with the whole Industry.
                      </p>
                      <div className="modal__slider__footer">
                        <div className="dots__section">
                          <button
                            aria-label="slide dot"
                            type="button"
                            className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                            onClick={() => HandleSlide(0)}
                            disabled={currentSlide === 0 ? true : false}
                          />
                          <button
                            aria-label="slide dot"
                            type="button"
                            className="
                dot___3c3SI
                carousel__dot
                carousel__dot--0
                carousel__dot--selected
                dot
              "
                            onClick={() => HandleSlide(1)}
                            disabled={currentSlide === 1 ? true : false}
                          />
                          <button
                            aria-label="slide dot"
                            type="button"
                            className="
                dot___3c3SI
                carousel__dot carousel__dot--1
                dot
              "
                            onClick={() => HandleSlide(2)}
                            disabled={currentSlide === 2 ? true : false}
                          />
                          <button
                            aria-label="slide dot"
                            type="button"
                            className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                            onClick={() => HandleSlide(3)}
                            disabled={currentSlide === 3 ? true : false}
                          />
                          <button
                            aria-label="slide dot"
                            type="button"
                            className="
                dot___3c3SI
                carousel__dot carousel__dot--3
                dot
              "
                            onClick={() => HandleSlide(4)}
                            disabled={currentSlide === 4 ? true : false}
                          />
                        </div>
                        <div className="btn__section">
                          <button
                            className="btn__prv"
                            onClick={() => HandleSlideNextPrev("min")}
                          >
                            Previous
                          </button>
                          <button
                            type="button"
                            aria-label="next"
                            className="
                                buttonNext___2mOCa
                                carousel__next-button
                                btn__nxt
                              "
                            onClick={() => HandleSlideNextPrev()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* /slide popup 4*/}
                    <svg className="svg svg_reslt">
                      <g transform="translate(40, 50)">
                        <defs>
                          <linearGradient
                            id="myGradientl"
                            x1="0%"
                            y1="100%"
                            x2="0%"
                            y2="0%"
                            spreadMethod="pad"
                          >
                            <stop
                              offset="10%"
                              stopColor="rgba(19, 156, 214, 0)"
                            />
                            <stop
                              offset="80%"
                              stopColor="rgba(19, 156, 214, 0.1)"
                            />
                          </linearGradient>
                        </defs>
                        <g
                          className="x axis"
                          transform="translate(0, 280)"
                          fill="none"
                          fontSize={10}
                          fontFamily="sans-serif"
                          textAnchor="middle"
                        >
                          <path
                            className="domain"
                            stroke="#000"
                            d="M0.5,6V0.5H1288.5V6"
                          />
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(86.69230769230771,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-1"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                360° Factory
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Visibility
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(210.53846153846155,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-2"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Automated
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Material Flow
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(334.38461538461536,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-3"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Data-driven
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Maintenance
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(458.2307692307692,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-4"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Digital-ready
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Infrastructure
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(582.0769230769231,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-5"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Intelligent
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Quality
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(705.9230769230769,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-7"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Sustainability
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(829.7692307692308,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-8"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Tech-augmented
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Workforce
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(953.6153846153846,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-10"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Zero Touches
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Production
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(1077.4615384615386,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-11"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Digital
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Organization
                              </tspan>
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(1201.3076923076924,0)"
                          >
                            <line stroke="#000" y2={6} />
                            <text
                              fill="#000"
                              y={9}
                              dy="0.71em"
                              className="xlablel xtddata-12"
                            >
                              <tspan x={0} y={9} dy="0.71em">
                                Factory of the
                              </tspan>
                              <tspan x={0} y={9} dy="1.81em">
                                Future
                              </tspan>
                              <tspan x={0} y={9} dy="2.91em">
                                Leadership
                              </tspan>
                            </text>
                          </g>
                        </g>
                        <g
                          className="y axis"
                          fill="none"
                          fontSize={10}
                          fontFamily="sans-serif"
                          textAnchor="end"
                        >
                          <path
                            className="domain"
                            stroke="#000"
                            d="M-6,280.5H0.5V0.5H-6"
                          />
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,280.5)"
                          >
                            <line stroke="#000" x2={-6} />
                            <text fill="#000" x={-9} dy="0.32em">
                              0%
                            </text>
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,0.5)"
                          >
                            <line stroke="#000" x2={-6} />
                            <text fill="#000" x={-9} dy="0.32em">
                              100%
                            </text>
                          </g>
                        </g>
                        <g
                          className="grid"
                          fill="none"
                          fontSize={10}
                          fontFamily="sans-serif"
                          textAnchor="end"
                        >
                          <path
                            className="domain"
                            stroke="#000"
                            d="M1288,280.5H0.5V0.5H1288"
                          />
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,280.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,252.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,224.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,196.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,168.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,140.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,112.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,84.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,56.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,28.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                          <g
                            className="tick"
                            opacity={1}
                            transform="translate(0,0.5)"
                          >
                            <line stroke="#000" x2={1288} />
                            <text fill="#000" x={-3} dy="0.32em" />
                          </g>
                        </g>
                        <g>
                          <path
                            fill="url('#myGradientl')"
                            className="dash-line-area"
                            d="M86.69230769230771,103.6C127.97435897435898,145.6,169.25641025641028,187.6,210.53846153846155,187.6C251.82051282051282,187.6,293.1025641025641,145.6,334.38461538461536,145.6C375.66666666666663,145.6,416.94871794871796,153.53333333333336,458.2307692307692,156.8C499.5128205128205,160.06666666666666,540.7948717948718,165.2,582.0769230769231,165.2C623.3589743589744,165.2,664.6410256410256,95.19999999999999,705.9230769230769,95.19999999999999C747.2051282051282,95.19999999999999,788.4871794871796,156.8,829.7692307692308,156.8C871.0512820512821,156.8,912.3333333333334,131.6,953.6153846153846,131.6C994.8974358974359,131.6,1036.1794871794873,148.4,1077.4615384615386,148.4C1118.7435897435898,148.4,1160.025641025641,119,1201.3076923076924,89.6L1201.3076923076924,280C1160.025641025641,280,1118.7435897435898,280,1077.4615384615386,280C1036.1794871794873,280,994.8974358974359,280,953.6153846153846,280C912.3333333333334,280,871.0512820512821,280,829.7692307692308,280C788.4871794871796,280,747.2051282051282,280,705.9230769230769,280C664.6410256410256,280,623.3589743589744,280,582.0769230769231,280C540.7948717948718,280,499.5128205128205,280,458.2307692307692,280C416.94871794871796,280,375.66666666666663,280,334.38461538461536,280C293.1025641025641,280,251.82051282051282,280,210.53846153846155,280C169.25641025641028,280,127.97435897435898,280,86.69230769230771,280Z"
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-1 sddata-1"
                            d="M54.53846153846155,240.8h64.3076923076923a5,5 0 0 1 5,5v29.19999999999999v5h-5h-64.3076923076923h-5v-5v-29.19999999999999a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-2 sddata-2"
                            d="M178.3846153846154,249.2h64.3076923076923a5,5 0 0 1 5,5v20.80000000000001v5h-5h-64.3076923076923h-5v-5v-20.80000000000001a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-3 sddata-3"
                            d="M302.2307692307692,226.8h64.3076923076923a5,5 0 0 1 5,5v43.19999999999999v5h-5h-64.3076923076923h-5v-5v-43.19999999999999a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-4 sddata-4"
                            d="M426.0769230769231,252h64.3076923076923a5,5 0 0 1 5,5v18v5h-5h-64.3076923076923h-5v-5v-18a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-5 sddata-5"
                            d="M549.9230769230769,240.8h64.3076923076923a5,5 0 0 1 5,5v29.19999999999999v5h-5h-64.3076923076923h-5v-5v-29.19999999999999a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-7 sddata-7"
                            d="M673.7692307692307,235.2h64.3076923076923a5,5 0 0 1 5,5v34.80000000000001v5h-5h-64.3076923076923h-5v-5v-34.80000000000001a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-8 sddata-8"
                            d="M797.6153846153846,249.2h64.3076923076923a5,5 0 0 1 5,5v20.80000000000001v5h-5h-64.3076923076923h-5v-5v-20.80000000000001a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-10 sddata-10"
                            d="M921.4615384615385,235.2h64.3076923076923a5,5 0 0 1 5,5v34.80000000000001v5h-5h-64.3076923076923h-5v-5v-34.80000000000001a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-11 sddata-11"
                            d="M1045.3076923076924,232.4h64.3076923076923a5,5 0 0 1 5,5v37.599999999999994v5h-5h-64.3076923076923h-5v-5v-37.599999999999994a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="bar ibar ddata-12 sddata-12"
                            d="M1169.1538461538462,190.39999999999998h64.3076923076923a5,5 0 0 1 5,5v79.60000000000002v5h-5h-64.3076923076923h-5v-5v-79.60000000000002a5,5 0 0 1 5,-5z"
                            style={{ fill: "rgb(11, 142, 209)" }}
                            opacity={1}
                          />
                        </g>
                        <g>
                          <path
                            className="line"
                            d="M86.69230769230771,240.8C127.97435897435898,245,169.25641025641028,249.2,210.53846153846155,249.2C251.82051282051282,249.2,293.1025641025641,226.8,334.38461538461536,226.8C375.66666666666663,226.8,416.94871794871796,252,458.2307692307692,252C499.5128205128205,252,540.7948717948718,243.60000000000002,582.0769230769231,240.8C623.3589743589744,238,664.6410256410256,235.2,705.9230769230769,235.2C747.2051282051282,235.2,788.4871794871796,249.2,829.7692307692308,249.2C871.0512820512821,249.2,912.3333333333334,237.06666666666663,953.6153846153846,235.2C994.8974358974359,233.33333333333334,1036.1794871794873,234.26666666666665,1077.4615384615386,232.4C1118.7435897435898,230.53333333333336,1160.025641025641,210.46666666666667,1201.3076923076924,190.39999999999998"
                          />
                        </g>
                        <g>
                          <path
                            className="dash-line"
                            d="M86.69230769230771,103.6C127.97435897435898,145.6,169.25641025641028,187.6,210.53846153846155,187.6C251.82051282051282,187.6,293.1025641025641,145.6,334.38461538461536,145.6C375.66666666666663,145.6,416.94871794871796,153.53333333333336,458.2307692307692,156.8C499.5128205128205,160.06666666666666,540.7948717948718,165.2,582.0769230769231,165.2C623.3589743589744,165.2,664.6410256410256,95.19999999999999,705.9230769230769,95.19999999999999C747.2051282051282,95.19999999999999,788.4871794871796,156.8,829.7692307692308,156.8C871.0512820512821,156.8,912.3333333333334,131.6,953.6153846153846,131.6C994.8974358974359,131.6,1036.1794871794873,148.4,1077.4615384615386,148.4C1118.7435897435898,148.4,1160.025641025641,119,1201.3076923076924,89.6"
                          />
                        </g>
                        <g>
                          <text
                            className="value ival tddata-1"
                            x="86.69230769230771"
                            y="230.8"
                            textAnchor="middle"
                          >
                            14%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-2"
                            x="210.53846153846155"
                            y="239.2"
                            textAnchor="middle"
                          >
                            11%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-3"
                            x="334.38461538461536"
                            y="216.8"
                            textAnchor="middle"
                          >
                            19%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-4"
                            x="458.2307692307692"
                            y={242}
                            textAnchor="middle"
                          >
                            10%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-5"
                            x="582.0769230769231"
                            y="230.8"
                            textAnchor="middle"
                          >
                            14%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-7"
                            x="705.9230769230769"
                            y="225.2"
                            textAnchor="middle"
                          >
                            16%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-8"
                            x="829.7692307692308"
                            y="239.2"
                            textAnchor="middle"
                          >
                            11%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-10"
                            x="953.6153846153846"
                            y="225.2"
                            textAnchor="middle"
                          >
                            16%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-11"
                            x="1077.4615384615386"
                            y="222.4"
                            textAnchor="middle"
                          >
                            17%
                          </text>
                        </g>
                        <g>
                          <text
                            className="value ival tddata-12"
                            x="1201.3076923076924"
                            y="180.39999999999998"
                            textAnchor="middle"
                          >
                            32%
                          </text>
                        </g>
                      </g>
                    </svg>
                    <div
                      className="dtoolTip with-arrow"
                      // style={{ left: "51.0385px", display: "inline-block" }}
                    >
                      Click this Dimension to
                      <br /> explore more
                    </div>
                  </div>

                  <div
                    className="res-tab-content2 active"
                    id="seeBetweenCompany"
                  >
                    <div
                      className="row no-gutters res-mt-10 px-0"
                      style={{ backgroundColor: "white" }}
                    ></div>
                  </div>
                  {/*  nestle chart tab content end*/}
                  <div
                    className="res-tab-content2 active"
                    id="seeBetweenGlobal"
                  >
                    <div
                      className="row no-gutters res-mt-10 px-0"
                      style={{ backgroundColor: "white" }}
                    >
                      <div id="gchart" className="bchart w-100 px-3" />
                    </div>
                  </div>
                  {/*  gloabl chart tab content end*/}
                </div>
                {/* flex  div end*/}
              </div>
              {/* sec 1 end */}
              {/* ------working----- */}
              <div
                data-sticky-container
                className="row res-chart-popups assess-modal assess-ddata-3"
              >
                <div className="col-2-res sticky">
                  <div className="s-result-cir-piechart">
                    <svg viewBox="0 0 36 36">
                      <path
                        className="circle-bg"
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#F0F0F0"
                        strokeWidth="3.4"
                        fill="none"
                      />
                      <path
                        className="circle"
                        strokeDasharray="4, 100"
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="
            #118ACB"
                        strokeWidth="3.4"
                        fill="none"
                      />
                    </svg>
                    <div className="s-result-cir-div-txt">
                      <p>Indore</p>
                      <h1>4%</h1>
                    </div>
                  </div>
                  <div className="s-result-cir-piechart">
                    <svg viewBox="0 0 36 36">
                      <path
                        className="circle-bg"
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#F0F0F0"
                        strokeWidth="3.4"
                        fill="none"
                      />
                      <path
                        className="circle"
                        strokeDasharray="27, 100"
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="
            #FFAB84"
                        strokeWidth="3.4"
                        fill="none"
                      />
                    </svg>
                    <div className="s-result-cir-div-txt">
                      <p>Industry Average</p>
                      <h1>27%</h1>
                    </div>
                  </div>
                  <div className="s-result-cir-piechart">
                    <svg viewBox="0 0 36 36">
                      <path
                        className="circle-bg"
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#F0F0F0"
                        strokeWidth="3.4"
                        fill="none"
                      />
                      <path
                        className="circle"
                        strokeDasharray="72, 100"
                        d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                        stroke="#7FCDBB"
                        strokeWidth="3.4"
                        fill="none"
                      />
                    </svg>
                    <div className="s-result-cir-div-txt">
                      <p>Industry BIC</p>
                      <h1>72%</h1>
                    </div>
                  </div>
                  {/* /Industry BIC */}
                </div>
                <div className="col-10-res">
                  <div className="res-popup-header">
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <h2 className="res-h2">Data-driven Maintenance</h2>
                      </div>
                      {/* col end*/}
                      <div className="col-1 px-0 text-right">
                        <a className="assess-close" href="javascript:void(0)">
                          <img
                            src={
                              require("../../assets/img/result-tour/res-popup-close.svg")
                                .default
                            }
                            className="res-popup-close-icon"
                            alt
                          />
                        </a>
                      </div>
                      {/* col end */}
                    </div>
                    {/* d-flex end */}
                    <div className="d-flex align-items-center">
                      <div className="col px-0">
                        <p className="res-popup-para">
                          Methods &amp; systems to manage maintenance
                          proactively based on data, such as equipment condition
                          metrics and historical events.
                        </p>
                      </div>
                      <div className="col-1 px-0">
                        <div className="d-flex res-nav-wrapper justify-content-end">
                          <a
                            className="bar-next-btn"
                            data-goto={2}
                            id="res-pre-btn"
                            href="javascript:void(0)"
                          >
                            <img
                              src={
                                require("../../assets/img/result-tour/res-next.svg")
                                  .default
                              }
                              className="res-nav-pre"
                              alt
                            />
                          </a>
                          <a
                            className="bar-next-btn"
                            data-goto={4}
                            id="res-next-btn"
                            href="javascript:void(0)"
                          >
                            <img
                              src={
                                require("../../assets/img/result-tour/res-next.svg")
                                  .default
                              }
                              className="res-nav-next"
                              alt
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* header end*/}
                  <div className="res-popup-body">
                    <div className="col-12-res">
                      <div className="s-result-tab-content mb-44">
                        <div className="s-result-row">
                          {/* s-result-col-sm end */}
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-tab">
                              <div
                                className="s-result-link green active"
                                data-target="#sResultTab1"
                              >
                                OPPORTUNITY
                              </div>
                            </div>
                          </div>
                          {/* s-result-col-lg end */}
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div
                          id="rs-slide-5"
                          className="rs-slide-5 s-result-row mb-30"
                        >
                          {/* slide popup 5*/}
                          <div
                            className={`rs-pop-slide-5 slide__mark-1 ${
                              currentSlide === 4 ? "" : " rs-d-none"
                            }`}
                          >
                            <h4 className="label">5 of 5</h4>
                            <h2 className="title">Dimension Analysis​</h2>
                            <p>
                              Access each Dimension to gain insights about the
                              adoption rates of relevant Industry 4.0
                              technologies, organizational constructs or
                              leadership attributes within your company or the
                              whole Industry. Understand your gaps against best
                              in class.
                            </p>
                            <div className="modal__slider__footer">
                              <div className="dots__section">
                                <button
                                  aria-label="slide dot"
                                  type="button"
                                  className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                                  onClick={() => HandleSlide(0)}
                                  disabled={currentSlide === 0 ? true : false}
                                />
                                <button
                                  aria-label="slide dot"
                                  type="button"
                                  className="
                dot___3c3SI
                carousel__dot
                carousel__dot--0
                carousel__dot--selected
                dot
              "
                                  onClick={() => HandleSlide(1)}
                                  disabled={currentSlide === 1 ? true : false}
                                />
                                <button
                                  aria-label="slide dot"
                                  type="button"
                                  className="
                dot___3c3SI
                carousel__dot carousel__dot--1
                dot
              "
                                  onClick={() => HandleSlide(2)}
                                  disabled={currentSlide === 2 ? true : false}
                                />
                                <button
                                  aria-label="slide dot"
                                  type="button"
                                  className="
                dot___3c3SI
                carousel__dot carousel__dot--2
                dot
              "
                                  onClick={() => HandleSlide(3)}
                                  disabled={currentSlide === 3 ? true : false}
                                />
                                <button
                                  aria-label="slide dot"
                                  type="button"
                                  className="
                dot___3c3SI
                carousel__dot carousel__dot--3
                dot
              "
                                  onClick={() => HandleSlide(4)}
                                  disabled={currentSlide === 4 ? true : false}
                                />
                              </div>
                              <div className="btn__section">
                                <button
                                  className="btn__prv"
                                  onClick={() => HandleSlideNextPrev("min")}
                                >
                                  Previous
                                </button>
                                <Link to="/quickstartopen" className="last_t_link">
                                  Exit Tour
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* /slide popup 5*/}
                          {/* s-result-col-sm end */}
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div
                                    className="
                                    s-result-left-content
                                    hideContent
                                  "
                                  >
                                    <h1 className="s-result-card-h1">
                                      Automatic transfer of Cleaning,
                                      Inspecting, Lubricating (CIL) data
                                    </h1>
                                    <div className="t_des_r">
                                      <p>
                                        Data from machinery sensors are
                                        automatically streamed to the CIL
                                        Management software or through an
                                        integration to Manufacturing Execution
                                        System (MES).
                                      </p>
                                      <p>
                                        The software aims to streamline and
                                        improve the lubrication process,
                                        eliminate human error, and simplify
                                        maintenance activities. When the levels
                                        are low, the system can generate
                                        notifications to add oil.
                                      </p>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>
                                          Streamlined data ensure visibility
                                        </li>
                                        <li>
                                          Elimination of errors from manual
                                          entry
                                        </li>
                                        <li>Improved lubrication process</li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <a href="#" className data-id={0}>
                                        Read more
                                      </a>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-0">
                                    <div className="px-0 s-learn-more">
                                      <a
                                        id={1}
                                        href="https://ngversion.karamalegkos.com/training/courseDetails/7"
                                        target="_blank"
                                      >
                                        Selected Plants Adoption
                                      </a>
                                    </div>
                                  </div>
                                  {/* left-content end */}
                                  {/* s-result-left-footer end */}
                                </div>
                              </div>
                              {/*s-result-card-lft end  */}
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    38%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Smarterchains Adoption
                                  </div>
                                  <div className="s-res-progress-bar orange">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "38%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 1 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    19%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar red">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "19%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 2 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    68%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar yellow">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "68%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 3*/}
                                <div
                                  className="ln-dis-wrap rn-0"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a
                                      id={1}
                                      href="https://ngversion.karamalegkos.com/training/courseDetails/7"
                                      target="_blank"
                                    >
                                      Selected Plants Adoption
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {/*s-result-card-rgt end  */}
                            </div>
                          </div>
                          {/* s-result-col-lg end */}
                        </div>
                      </div>
                      <div className="s-result-tab-content mb-44">
                        <div className="s-result-row">
                          {/* s-result-col-sm end */}
                          <div className="s-result-col-rgt">
                            <div className="s-result-tab">
                              <div
                                className="s-result-link blue active"
                                data-target="#sResultTab1"
                              >
                                STRENGTH
                              </div>
                            </div>
                          </div>
                          {/* s-result-col-lg end */}
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div className="s-result-row mb-30">
                          {/* s-result-col-sm end */}
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card blue">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div
                                    className="
                                    s-result-left-content
                                    hideContent
                                  "
                                  >
                                    <h1 className="s-result-card-h1">
                                      Computerized Maintenance Management System
                                      (CMMS)
                                    </h1>
                                    <div className="t_des_r">
                                      <p>
                                        CMMS centralizes maintenance tasks, like
                                        planning, tracking and implementation of
                                        such processes. Asset availability and
                                        utilization is very important for
                                        streamlined production and low costs and
                                        CMMS can help ensure this.
                                      </p>
                                      <p>
                                        The core functions of every CMMS are:
                                      </p>
                                      <ul>
                                        <li>Work order management</li>
                                        <li>
                                          Preventive maintenance scheduling
                                        </li>
                                        <li>
                                          Asset performance data collection and
                                          reporting
                                        </li>
                                        <li>Auditing and report generation</li>
                                        <li>
                                          Materials and spare part inventory
                                          management
                                        </li>
                                        <li>Resource and labor allocation</li>
                                        <li>
                                          Best of breed CMMS have mobile app
                                          versions, which push notifications to
                                          the technicians’ smartphones or
                                          tablets, allow them to access work
                                          orders and share relevant information
                                          in real-time.
                                        </li>
                                        <li>
                                          CMMS combined with Advanced Analytics
                                          has the capability of predicting
                                          upcoming break downs, avoid them and
                                          finally boost asset efficiency.
                                        </li>
                                      </ul>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>
                                          Reduce time required to schedule
                                          maintenance tasks
                                        </li>
                                        <li>
                                          Optimize Spare Part procurement
                                          process
                                        </li>
                                        <li>Extend asset lifecycle</li>
                                        <li>Lower total maintenance costs</li>
                                        <li>
                                          Increase productivity of your
                                          maintenance teams
                                        </li>
                                        <li>
                                          Reduce Spare Parts inventory costs
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <a href="#" className data-id={100}>
                                        Read more
                                      </a>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-100">
                                    <div className="px-0 s-learn-more">
                                      <a
                                        id={3}
                                        href="https://ngversion.karamalegkos.com/training/courseDetails/3"
                                        target="_blank"
                                      >
                                        Selected Plants Adoption
                                      </a>
                                    </div>
                                  </div>
                                  {/* left-content end */}
                                  {/* s-result-left-footer end */}
                                </div>
                              </div>
                              {/*s-result-card-lft end  */}
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    38%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Smarterchains Adoption
                                  </div>
                                  <div className="s-res-progress-bar orange">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "38%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 1 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    74%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar yellow">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "74%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 2 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    100%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar green">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 3*/}
                                <div
                                  className="ln-dis-wrap rn-100"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a
                                      id={3}
                                      href="https://ngversion.karamalegkos.com/training/courseDetails/3"
                                      target="_blank"
                                    >
                                      Selected Plants Adoption
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {/*s-result-card-rgt end  */}
                            </div>
                          </div>
                          {/* s-result-col-lg end */}
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div className="s-result-row mb-30">
                          {/* s-result-col-sm end */}
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card blue">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div
                                    className="
                                    s-result-left-content
                                    hideContent
                                  "
                                  >
                                    <h1 className="s-result-card-h1">
                                      Equipment Monitoring Sensors
                                    </h1>
                                    <div className="t_des_r">
                                      <p>
                                        Sensors for equipment monitoring play a
                                        key role in modern industrial plant
                                        operations.
                                      </p>
                                      <p>
                                        Extracting information from the raw data
                                        generated by the sensors is a
                                        complicated but helpful task that assist
                                        the operators to react prior to
                                        undesired events.
                                      </p>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>Reduced downtime</li>
                                        <li>Reduced time to action</li>
                                        <li>Predictive approach</li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <a href="#" className data-id={101}>
                                        Read more
                                      </a>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-101">
                                    <div className="px-0 s-learn-more">
                                      <a
                                        id={3}
                                        href="https://ngversion.karamalegkos.com/training/courseDetails/3"
                                        target="_blank"
                                      >
                                        Selected Plants Adoption
                                      </a>
                                    </div>
                                  </div>
                                  {/* left-content end */}
                                  {/* s-result-left-footer end */}
                                </div>
                              </div>
                              {/*s-result-card-lft end  */}
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    38%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Smarterchains Adoption
                                  </div>
                                  <div className="s-res-progress-bar orange">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "38%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 1 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    42%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar orange">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "42%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 2 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    100%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar green">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 3*/}
                                <div
                                  className="ln-dis-wrap rn-101"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a
                                      id={3}
                                      href="https://ngversion.karamalegkos.com/training/courseDetails/3"
                                      target="_blank"
                                    >
                                      Selected Plants Adoption
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {/*s-result-card-rgt end  */}
                            </div>
                          </div>
                          {/* s-result-col-lg end */}
                        </div>
                      </div>
                      <div
                        className="s-result-tab-content hold-data"
                        id="sResultTab1"
                        style={{ display: "block" }}
                      >
                        <div className="s-result-row mb-30">
                          {/* s-result-col-sm end */}
                          <div className="s-result-col-rgt rwd-100">
                            <div className="s-result-card blue">
                              <div className="s-result-card-lft">
                                <div className="d-flex flex-column h-100">
                                  <div
                                    className="
                                    s-result-left-content
                                    hideContent
                                  "
                                  >
                                    <h1 className="s-result-card-h1">
                                      Condition-based Maintenance
                                    </h1>
                                    <div className="t_des_r">
                                      <p>
                                        Condition-based maintenance is a
                                        data-driven maintenance strategy, that
                                        aims to detect machine failures at an
                                        early stage and react timely.
                                      </p>
                                      <p>
                                        This strategy relies on sensory systems
                                        streaming real-time data that are
                                        constantly analyzed and compared with
                                        historical performance.
                                      </p>
                                      <p>
                                        When a machine's condition falls below
                                        predefined standards, alerts are
                                        triggered to notify maintenance
                                        operators to intervene.
                                      </p>
                                      <h3 className="tab-title">Benefits</h3>
                                      <ul>
                                        <li>
                                          Eliminated redundant maintenance
                                        </li>
                                        <li>
                                          Lower risk of harmful malfunction
                                          incidents
                                        </li>
                                        <li>Increased asset life expectancy</li>
                                        <li>Lower spare parts consumption</li>
                                        <li>Reduced downtime</li>
                                      </ul>
                                    </div>
                                    <div className="col px-0 s-result-learn-more">
                                      <a href="#" className data-id={102}>
                                        Read more
                                      </a>
                                    </div>
                                  </div>
                                  <div className="ln-dis-wrap ln-102">
                                    <div className="px-0 s-learn-more">
                                      <a
                                        id={3}
                                        href="https://ngversion.karamalegkos.com/training/courseDetails/3"
                                        target="_blank"
                                      >
                                        Selected Plants Adoption
                                      </a>
                                    </div>
                                  </div>
                                  {/* left-content end */}
                                  {/* s-result-left-footer end */}
                                </div>
                              </div>
                              {/*s-result-card-lft end  */}
                              <div className="s-result-card-rgt">
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    25%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Smarterchains Adoption
                                  </div>
                                  <div className="s-res-progress-bar orange">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "25%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 1 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    11%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Industry Adoption
                                  </div>
                                  <div className="s-res-progress-bar red">
                                    <div
                                      className="s-res-progress-status"
                                      style={{ width: "11%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 2 */}
                                <div className="s-result-progress-container">
                                  <div className="s-res-progress-percent">
                                    60%
                                  </div>
                                  <div className="s-res-progress-title">
                                    Best in Class Adoption
                                  </div>
                                  <div className="s-res-progress-bar yellow">
                                    <div
                                      className="s-res-progress-status full"
                                      style={{ width: "60%" }}
                                    />
                                  </div>
                                </div>
                                {/* s-result-progress-container end 3*/}
                                <div
                                  className="ln-dis-wrap rn-102"
                                  style={{ display: "none" }}
                                >
                                  <div className="px-0 s-learn-more">
                                    <a
                                      id={3}
                                      href="https://ngversion.karamalegkos.com/training/courseDetails/3"
                                      target="_blank"
                                    >
                                      Learn more
                                    </a>
                                  </div>
                                  <div className="px-0 s-result-vendors-link">
                                    <a
                                      href="https://ngversion.karamalegkos.com/vendor?vid=3"
                                      className="float-right"
                                      target="_blank"
                                    >
                                      Discover Vendors
                                    </a>
                                  </div>
                                </div>
                              </div>
                              {/*s-result-card-rgt end  */}
                            </div>
                          </div>
                          {/* s-result-col-lg end */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*------working------ */}
            </div>
            {/*asst-abt-left end*/}
          </div>
          {/* row end */}
        </div>
        {/* asst content body end*/}
      </div>
      {/*------footer----*/}
      <div className="walktrough-footer-blue">
        {/* section has two parts
          footer-left
          footer-right
        */}
        <div className="footer-area">
          {/* this section has link */}
          <div className="footer-left">
            <ul className="footer-page-link">
              <li>
                <Link to="/assessment-tour">Digital Maturity Assessment</Link>
              </li>
              <li className="page-line-active">Assessment Results</li>
            </ul>
          </div>
          {/* this section has link */}
          <div className="footer-right">
            <ul className="footer-page-link">
              <li>
                <Link to="/">Exit this demo tour </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
