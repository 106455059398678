import React, { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MainWrapper, ChartDiv, ChartDivWrapper, ChartTitle, PopupDiv, PopupTitle, PoupDetail, ScoreRow, ActionDiv,ScoreTextDiv,CartTitleWrap, ChartSubTtile } from './analytics-style'
import { crossIcon, arrowLeft, arrowRight } from "./svg";
import { Helmet } from "react-helmet";
export const SkillAssessmentAnalyticsView = (props) => {
  const analytic = props.analytics;
  if (!analytic.length) {
    return null;
  }
  const analytic_data = props.analytics[0];

  const chartDiv = useRef();
  const [popupData, setPopupData] = useState(false);
  const [data, setData] = useState(analytic_data.analytics);
  const AVG_BEFORE =  analytic_data.AVG_BEFORE;
  const AVG_AFTER =  analytic_data.AVG_AFTER;
  const [data1, setData1] = useState([
    {
      section_name: 'Introduction to Industry 4.0',
      value: 0,
      score_before: 78,
      score_after: 90,
    },
    {
      section_name: 'Factory of the Future Leadership',
      value: 0,
      score_before: 78,
      score_after: 90,
    },
    {
      section_name: 'Digital Organization',
      value: 'NA',
      score_before: 92,
      score_after: 90,
    },
    {
      section_name: 'Sustainability',
      value: 92,
      score_before: 92,
      score_after: 90,
    },
    {
      section_name: 'Automated Material Flow',
      value: 68,
      score_before: 68,
      score_after: 90,
    },
    {
      section_name: 'Tech-Augmented Workforce',
      value: 88,
      score_before: 88,
      score_after: 90,
    },
    {
      section_name: 'Data-Driven Maintenance',
      value: 88,
      score_before: 88,
      score_after: 90,
    },
    {
      section_name: 'Digital-Ready Infrastructure',
      value: 88,
      score_before: 88,
      score_after: 90,
    },
    {
      section_name: '360° Factory Visibility',
      value: 58,
      score_before: 58,
      score_after: 90,
    },
    {
      section_name: 'Intelligent Quality',
      value: 58,
      score_before: 58,
      score_after: 90,
    },
    {
      section_name: 'Zero Touches Production',
      value: 58,
      score_before: 58,
      score_after: '',
    },
  ]);

  // funtion to closePopup
  const closePopup = () => {
    let bars = document.querySelectorAll('.ract-bars');
    bars?.forEach(i => i.classList.remove('inactive'));
    setPopupData(false);
  }

  // funtion to higlight previous bar and it's data
  const prvFunction = () => {
    let bars = document.querySelectorAll('.ract-bars');
    let tmpIndex = data?.findIndex(i => i.section_name === popupData.section_name);

    if (!!tmpIndex && tmpIndex > 0) {
      setPopupData(data[tmpIndex - 1]);
      bars?.forEach(i => i.classList.add('inactive'));
      bars[tmpIndex - 1].classList.remove('inactive');
    }

  }
  // funtion to higlight next bar and it's data 
  const nextFunction = () => {
    let bars = document.querySelectorAll('.ract-bars');
    let tmpIndex = data?.findIndex(i => i.section_name === popupData.section_name);

    if ((tmpIndex || tmpIndex == 0) && tmpIndex < (data?.length - 1)) {
      setPopupData(data[tmpIndex + 1]);
      bars?.forEach(i => i.classList.add('inactive'));
      bars[tmpIndex + 1].classList.remove('inactive');
    }
  }

  // funtion to draw bar chart
  function drawBarChart() {
    let DeviceWidth = window.innerWidth;
    const colors = {
      green: '#45B097',
      red: '#E25B71'
    }

    const margin = {
      left: 45,
      right: 29,
      top: 40,
      bottom: 90,
    };

    const width = chartDiv.current.scrollWidth;
    const height = 368;
    const graphAreaH = height - (margin.top + margin.bottom);
    const graphAreaW = width - (margin.left + margin.right);
    const border_Radius = 2;
    let barGroupPadding = 23;
    let barPadding = DeviceWidth > 1200 ? 70 : DeviceWidth > 1000 ? 40 : 10;
    let barW = ((graphAreaW - margin.left) / data.length) - barPadding;
    let totalY = 10;
    let foreignObjectW = barW + (barPadding / 2);
    let foreignObjectH = margin.bottom - 10;
    let y_axis_Arr = () => {
      let tmpArr = [];
      let addCont = 100 / totalY;
      for (let i = 100; i >= 0; i -= addCont) {
        tmpArr.push(`${i}`);
      }
      return tmpArr;
    }
    let graphArea = d3.select(chartDiv.current)
      .html('')
      .append('svg')
      .attr('height', height)
      .attr('width', width)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);
    // ---------------xAxis---------
    let xAxis = graphArea.append('g').attr('class', 'x_axis');

    xAxis.append('line')
      .attr('x1', '0')
      .attr('y1', graphAreaH)
      .attr('x2', graphAreaW)
      .attr('y2', graphAreaH)
      .style('stroke', '#D4DCF5');
    // ---------------Yaxis---------

    let yAxis = graphArea.append('g').attr('class', 'y_axis');
    yAxis.append('line')
      .attr('x1', '6')
      .attr('y1', '0')
      .attr('x2', '6')
      .attr('y2', graphAreaH)
      .style('stroke', 'transparent');

    /*creating tickes*/
    let tickes = graphArea.append('g')

    // create lines. 
    tickes.attr('class', 'tickes_group')
      .selectAll('.big-tickes_group')
      .data(y_axis_Arr)
      .enter()
      .append('line')
      .attr('class', (d, i) => `big-tickes tick-${i}`)
      .attr('x1', '0')
      .attr('y1', d => { return (graphAreaH * d / 100) })
      .attr('x2', graphAreaW)
      .attr('y2', d => { return (graphAreaH * d / 100) })
      .style('stroke', '#F0F0F0')
      .attr('class', (d, i) => {
        let returnClass = d == 30 ? 'stroke-dasharray' : ''
        return returnClass;
      });

    tickes.selectAll('.dark-tickes')
      .data(y_axis_Arr)
      .enter()
      .append('line')
      .attr('class', (d, i) => `big-tickes tick-${i}`)
      .attr('x1', '0')
      .attr('y1', d => { return (graphAreaH * d / 100) })
      .attr('x2', '6')
      .attr('y2', d => { return (graphAreaH * d / 100) })
      .style('stroke', d => 'transparent');


    // yAxis text
    tickes.append('g')
      .attr('transform', `translate(20,0)`)
      .selectAll('text')
      .data(y_axis_Arr)
      .enter()
      .append('text')
      .attr('x', '-30')
      .attr('y', d => { return graphAreaH - (graphAreaH * d / 100) })
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .attr('color', '#0D2C54')
      .attr('class', (d, i) => {
        return `y-axis-percent-txt t-${i}`
      })
      .text((d) => {
        return `${d}%`
      });



    /* ----------------------------------- create bars ----------------------------------- */

    /*function to carete "d" attribute value of path for bar*/
    let drawBar = (index = 0, barWidth = 0, value = 0, radius = 0, g_height = 0) => {

      return `
            M0,${g_height - (g_height * value / 100)}
            a${radius},${radius} 0 0 1 ${radius},${-radius}
            h${barWidth - 2 * radius}
            a${radius},${radius} 0 0 1 ${radius},${radius}
            v${g_height * value / 100}
            h${-barWidth}Z
        `}

    let barsGroupWrapper = graphArea.append('g')
      .attr('class', 'bar-group-wrapper')
      .attr('transform', (d, i) => `translate(${barGroupPadding},0)`);

    let barGroup = barsGroupWrapper.selectAll('.bar-group')
      .data(data).enter();

    let barGroup_g = barGroup.append('g');
    let percentText = barGroup_g.append('switch');
    let sectionText = barGroup_g.append('switch');
    // create bars						
    barGroup_g
      .attr('transform', (d, i) => `translate(${(barW + barPadding) * i},0)`)
      .append('path')
      .attr("d", (d, index) => drawBar(index, barW, d.value == 'NA' ? 0 : d.value, 4, graphAreaH))
      .attr("fill", (d, index) => d.value >= 70 ? colors.green : colors.red)
      .attr('class', d => `ract-bars ${d.value}-value`)
      .on('click', (e, d) => {
        if (d.value !== 'NA') {
          let bars = document.querySelectorAll('.ract-bars');
          bars?.forEach(i => i.classList.add('inactive'));
          e.target.classList.remove('inactive');
          setPopupData(d);
        }
        // console.log('cliked',e,d)
      });
    // create bars

    percentText.append('foreignObject')
      .attr('transform', (d, i) => `translate(-${foreignObjectW / 2},${(graphAreaH - graphAreaH * ((d.value == 'NA' ? 0 : d.value) / 100)) - 25})`)
      .attr('x', barW / 2)
      .attr('y', 0)
      .attr('width', foreignObjectW)
      .attr('height', 50)
      .append('xhtml:span')
      .attr('class', 'percent-text')
      .attr('xmlns', "http://www.w3.org/1999/xhtml")
      .text(d => `${d.value == 'NA' ? d.value : d.value + '%'}`);

    sectionText.append('foreignObject')
      .attr('transform', (d, i) => `translate(-${foreignObjectW / 2},${graphAreaH + 15})`)
      .attr('x', barW / 2)
      .attr('y', 0)
      .attr('width', foreignObjectW)
      .attr('height', foreignObjectH)
      .append('xhtml:span')
      .attr('class', 'section-text')
      .attr('xmlns', "http://www.w3.org/1999/xhtml")
      .text(d => `${d.section_name}`);
  }

  useEffect(() => {
    drawBarChart();
  }, [data]);

  return (
    <MainWrapper data-component='MainWrapper'>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Skill Assessment Analytics</BreadcrumbsItem>
      <CartTitleWrap data-component="CartTitleWrap">
      <ChartTitle data-component="ChartTitle">Score Per Dimension</ChartTitle>
      <ChartSubTtile data-component="ChartSubTtile">Pass score is 70% per dimension</ChartSubTtile>
      </CartTitleWrap>
      <ChartDivWrapper data-component="ChartDivWrapper">
        <ChartDiv ref={chartDiv} data-component="ChartDiv"></ChartDiv>
      </ChartDivWrapper>
      {/* ChartDivWrapper end*/}
      {popupData &&
        <PopupDiv data-component="PopupDiv">
          <ScoreTextDiv data-component="ScoreTextDiv">
            <p>Average marks scored by users in Skills Assessment before Training: {popupData.AVG_BEFORE}%</p>
            <p>Average marks scored by users in Skills Assessment after Training: {popupData.AVG_AFTER}%</p>
          </ScoreTextDiv>
          <ActionDiv data-component="ActionDiv">
            <i className="close-popup-div" dangerouslySetInnerHTML={{ __html: crossIcon }} onClick={() => closePopup()}></i>
            <div className="action-row">
              <i className="prv-popup-div" dangerouslySetInnerHTML={{ __html: arrowLeft }} onClick={() => prvFunction()}></i>
              <i className="next-popup-div" dangerouslySetInnerHTML={{ __html: arrowRight }} onClick={() => nextFunction()}></i>
            </div>
          </ActionDiv>
          <PopupTitle data-component="ChartTitle">{popupData.section_name}</PopupTitle>
          <ScoreRow data-component="ScoreRow">
            <span className="score_title">Scored in Skills Assessment</span>
            <span className="score_text">
              {
                (popupData.score_before != 'NA') ? `${popupData.score_before}%` : 'NA'
              }
            </span>
          </ScoreRow>
          <ScoreRow data-component="ScoreRow">
            <span className="green score_title">Scored after Trainings Course</span>
            <span className="score_text">
              {
                popupData.score_after ? `${popupData.score_after}%` : 'NA'
              }
            </span>
          </ScoreRow>
        </PopupDiv>
      }
    </MainWrapper >
  );
};


