/** imported components

  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * SkillView ===> custom view component
  * SkillViewReport ===> custom view component

**/

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { SkillView } from "./skill-view.js";
import { SkillViewReport } from "./skill-view-report.js";
import { Helmet } from "react-helmet";
//SkillController start
export const SkillController = (props) => {
	//defining variables here
	const [results, setResults] = useState([]);
	const [quizScore, setQuizScore] = useState(-1);
	const [isAnsPoup, setIsAnsPopup] = useState(false);
	const [isCompletePopup, setIsCompletePopup] = useState(false);
	//function to handle unsaved answer popup
	const HandleUnsavedAnsPopup = () => {
		let tmpVal = !isAnsPoup;
		setIsAnsPopup(tmpVal);
	};
	//Function to handle complete popup
	const HandleCompletePopup = () => {
		let tmpVal = !isCompletePopup;
		setIsCompletePopup(tmpVal);
	};
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	useEffect(() => {
		const search = async () => {
			const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
			const tid = getLastItem(window.location.pathname);
			const apiUrl = `/skills?param=trainingquiz&slug=${tid}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl,true, {});
			setQuizScore(data[0].score);
			setResults(data);
		};
		search();
	}, [quizScore]);
	
	return (
		<>
		{/* css for styling page */}
		<Helmet>
			<link src={require('./skill.scss').default} />
		</Helmet>
		{quizScore < 0 ? (
		<SkillView
			quizData={results}
			onSubmitQuiz={setQuizScore}
			onSubmitQuizShow={setIsCompletePopup}
			isAnsPoup={isAnsPoup}
			HandleUnsavedAnsPopup={HandleUnsavedAnsPopup}
			{...props}
		/>
	) : (
		<SkillViewReport
			quizData={results}
			onSubmitQuiz={setQuizScore}
			isCompletePopup={isCompletePopup}
			HandleCompletePopup={HandleCompletePopup}
			{...props}
		/>
	)}
	</>
	)
};
