import styled from 'styled-components';

export const PowerBiWrapper = styled.div`
  padding: 1rem 3rem 3rem 3rem;
  margin: 0 auto;
  color: #4d4d4f;
  margin-left: auto;
  margin-right: auto;
  .filter-group {
    cursor: pointer;
    &.inactive {
      opacity: 0.5;
    }
  }
 
  @media (max-height: 800px) {
    padding-bottom: 2.5rem;
    padding: 0.5rem 1.5rem 2rem 2rem;
  }
  @media (max-height: 600px) {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  }
  @media (max-height: 500px) {
    //transform: scaleX(0.85)  scaleY(0.9);
  }
  .resultChart-1,
  .resultChart-2,
  .resultChart-3 {
    svg {
      width: 100%;
    }
  }
`;

export const PowerBiContents = styled.div``

export const PowerBiHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  @media(max-height:600px){
    gap: 0.5rem;
  }
`;
export const PowerBiHeaderBox = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  display: inline-flex;
  background: #FFFFFF;
  border: 0.1rem solid #EDEEEE;
  border-radius: 0.4rem;
  flex-direction: column;
  flex: 1;
  min-height: 6.4rem;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
  &.PowerBiHeaderBox-0{
    max-width: 28rem;
    h2{
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.PowerBiHeaderBox-3{
    flex:unset;
    width:25rem;
  }
@media(max-height:600px){
  min-height: unset;
}
  p {
      margin-bottom: 1rem;
      @media(max-height:600px){
        margin-bottom: 0.5rem;
      }
  }

  h2 {
      font-weight: 700;
      font-size: 1.8rem;
  }
`;
export const PwbCard = styled.button`
  text-align: center;
  min-height: 2.8rem;
  font-size: 0.9rem;
  background: #FAFCFD;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 0.5rem);
  padding:0.5rem;
  @media(max-height:600px){
    font-size:1.1vh;
    min-height: 3.5vh;
  }
  &:hover{
    background-color:#ebebeb;
  }
  &.active{
      background-color: #4d4d4f;
      color: #fff;
  }
`;
export const PwbCardRow = styled.div`
  display: flex;
  gap: 0.3rem;
  width: 100%;
  flex-wrap: wrap;
  flex:1;
  @media(max-width:600px){
    gap: 0.3rem;
  }
`;
export const PwbRow = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 0.5rem;
  &.h-350{
    height:calc(50vh - 11rem);
    @media screen and (max-height:1200px) and (max-width:1200px){
      max-height: 300px;
    }
    @media (max-height: 800px) {
      height:calc(50vh - 10rem);
    }
    @media(max-height:600px){
      gap: 0.5rem;
      padding-top: 0.5rem;
      &.h-sm-350{
        height:calc(50vh - 9rem);
      }
    }
  }
`;
export const PwbLeft = styled.div`
  width: 24rem;
  display: flex;
  flex-direction: column;
  gap:0.5rem;
  h2 {
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  @media(max-height:600px){
    width:18rem;
    gap:0.5rem;
  }
`;
export const PwbMid = styled.div`
  flex:1;
`;
export const EndMid = styled.div`
  width: calc(45% - 13rem);
  .bottom-text {
    font-size: 1.1rem;
    display: block;
    overflow: hidden;
    font-weight: 500;
    text-align:center;
    span {
        display: block;
        text-align: center;
    }
  }
`
export const PwbRight = styled.div`
  width: 25%;
  position:relative;
  .type-bottom-text{
    position:absolute;
    left:0;
    right:0;
    bottom:0.5rem;
    display:flex;
    gap:0.5rem;
    font-size:1.2rem;
    color:#4d4d4f;
    &.justify-center{
      justify-content:center;
    }
    .bold-text{
      font-weight:500;
    }
  }
  .bottom-cir-text{
    position:relative;
    padding-left:1.2rem;
    overflow:hidden;
    width:20%;
    span{
      color:#4d4d4f;
      width:100%;
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      display:inline-block;
    }
    &:before{
        content:'';
        display:inline-block;
        width:0.8rem;
        height:0.8rem;
        border-radius:50%;
        background-color:#ff3e3e;
        position:absolute;
        left:0;
        top:50%;
        transform:translateY(-50%);
        background:currentColor;
    }
  }
`
export const PwbMidCard = styled.div`
  background: #FFFFFF;
  border: 0.1rem solid #EDEEEE;
  border-radius: 0.4rem;
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  height: 100%;
  position:relative;
  .total-identified-loss-wrap{
    max-width: 23rem;
    padding: 1rem 1.2rem;
    border: 0.1rem solid #c4c4c4;
    border-radius: 0.4rem;
    text-align: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: #fff;
    @media(max-height:600px){
      padding: 0.2rem;
    }
    .title{
      color: #4d4d4f;
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 0.8rem;
      @media(max-height:600px){
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
    }
    .max-val{
      color: #118acb;
      display: block;
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 0rem;
      @media(max-height:600px){
        font-size: 1.4rem;
        font-weight: 700;
      }
    }
  }
  .bottom-text{
    text-align:center;
  }
  .loss-sub-cat-chart-wrapper {
    overflow:hidden auto;
    width: 100%;
    height: calc(100% - 70px);
    .loss-sub-chart {
      width: 100%;
      height: calc(100% - 5px);
      cursor: default;
      .bottom-text {
        font-size: 1.1rem;
        display: block;
        overflow: hidden;
        font-weight: 500;
        text-align:center;
        span {
            display: block;
            text-align: center;
        }
      }
      .section-text {
        font-size: 1rem;
        text-align: right;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        justify-content: flex-end;
        font-weight:bold;
        span {
          min-width: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 1rem;
          padding-left: 0.5rem;
          overflow: hidden;
        }
      }
      .section-val {
        font-size: 1rem;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        justify-content: center;
        font-weight: 600;
        color: #fff;
        &.black-color{
          color:#4d4d4f;
          justify-content:flex-start;
          padding-left:5px;
        }
      }
    }
  }
  .type-bottom-text{
    display:flex;
    gap:0.5rem;
    font-size:1.2rem;
    color:#4d4d4f;
    margin-top:0.5rem;
    &.justify-center{
      justify-content:center;
    }
    .bold-text{
      font-weight:500;
      white-space:nowrap;
    }
  }
  .bottom-cir-text{
    position:relative;
    padding-left:1.2rem;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
    span{
      color:#4d4d4f;
    }
    &:before{
        content:'';
        display:inline-block;
        width:0.8rem;
        height:0.8rem;
        border-radius:50%;
        background-color:#ff3e3e;
        position:absolute;
        left:0;
        top:50%;
        transform:translateY(-50%);
        background:currentColor;
    }
  }
`
export const PwbRightCard = styled.div`
  background: #FFFFFF;
  border: 0.1rem solid #EDEEEE;
  border-radius: 0.4rem;
  padding: 1.5rem;
  height: 100%;
  
  .donut_chart_container{
    display:flex;
    flex-direction:column;
    align-item:center;
    position:relative;
    justify-content:center;
    height:calc(100% - 4rem);
    .svg-donut{
      margin:auto;
    }
    .donut_chart_label-container{
      display:flex;
      gap:0.5rem;
      flex-direction:column;
      position:absolute;
      justify-content:center;
      top:0;
      left:0;
      font-size:1.1rem;
      .label-row{
        display:flex;
        gap:5px;
        align-items:center;
      }
      .label-circle{
         height:1.2rem;
         width:1.2rem;
         border-radius:2px;
      }
    }
    .svg-donut{
      
    }
}

`;
export const PwbLeftRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background: #FFFFFF;
  border: 0.1rem solid #EDEEEE;
  border-radius: 0.4rem;
  padding: 0.5rem;
 &.flex-1{
   flex:1;
 }
  &.margin-15rem {
      margin-bottom: 1.5rem;
  }
  &.min-height-100{
    min-height:100%;
    overflow:auto;
  }
  &.h-30{
    height:9rem;
  }
  &.h-70{
    flex:1;
    overflow:auto;
  }
  @media(max-height:600px){
    padding: 0.5rem 0.1rem 0.5rem 0.5rem;
  }
`
export const ToolTipLossCat = styled.div`
  background: rgba(17, 138, 203, 0.9);
  padding: 1.5rem;
  display: flex;
  gap: 5px;
  flex-direction: column;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  width: 30rem;
  transition: opacity 0.25s, visibility 0.25s;
  transition-delay: 0.25s;
  z-index: 2;
  /* &:before {
    content: "";
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 12px solid rgba(17, 138, 203, 0.9);
    left: -12px;
    top: 0;
  } */

  &.right {
    transform: translateX(-25px);
    &:before {
      content: "";
      position: absolute;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 12px solid rgba(17, 138, 203, 0.9);
      right: -11px;
      top: 0;
    }
  }
  &.left {
    transform: translateX(25px);
    &:before {
      content: "";
      position: absolute;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 12px solid rgba(17, 138, 203, 0.9);
      left: -11px;
      top: 0;
    }
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
  .tootip-loss-cat-row {
    display: flex;
    gap: 1rem;
    color: #fff;
    font-size: 1.1rem;
    .tooltip-label-col {
      text-align: right;
      width: 12rem;
    }

    .tooltip-value-col {
      flex: 1;
      font-weight: 600;
    }
  }
`;
export const ChartDivWrapper = styled.div`
  background: #FFFFFF;
  height:calc(100% - 40px);
  max-width:100%;
  overflow:hidden;
`
export const ChartDiv = styled.div`
  height:100%;
  cursor:default;
  .section-text-div{
    padding-top:0.5rem;
  }
.percent-text{
    display:block;
    text-align:center;
    font-weight:700;
  }
  .ract-bars{
    cursor:pointer;
    &.NA-value{
      cursor:not-allowed;
      fill:#7D868C !important;
    }
  }
  .section-text{
    display:block;
    text-align:center;
    font-size:1rem;
    max-width:100%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    font-weight:600;
  }
  .inactive{
    opacity:0.5;
  }
  .stroke-dasharray{
    stroke-dasharray: 5,5;
    stroke:#1487E3 !important;
  }
  .y-axis-percent-txt.t-3,.y-axis-percent-txt.t-0,.y-axis-percent-txt.t-10{
    opacity:1 !important;
  }
  svg{
    height:100%;
    width:100%;
  }
`
export const ChartHeading = styled.h2`
    font-weight: 500;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom:1rem;
`
export const BottomTabWrapper = styled.div`
     position:absolute;
     bottom:0px;
     width:100%;
     left:0;
     z-index:1;
     background:#f5f5f5;
     padding-bottom:0.6rem;
     display:flex;
     align-items:center;
`
export const TabsSection = styled.div`
    display:inline-block;
    max-width:calc(100% - 10rem);
    .MuiTouchRipple-root{
      display: none !important;
    }
    .rename-input{
      font-size: 1.1rem;
      font-weight: 500;
      color: #4d4d4f;
      font-family: 'Inter';
      border: 1px solid #d1d3d5;
      padding: 0.5rem 0.5rem;
      border-radius: 0.2rem;
    }
    .MuiTabs-root{
      min-height: 4rem;
      .MuiButtonBase-root{
        &.MuiTab-root{
          &.MuiTab-textColorPrimary{
            min-height: 4rem;
          }
        }
      }
      .MuiTab-labelIcon{
        &:first-child{
          .MuiTab-wrapper{
            flex-direction: row;
            justify-content: center;
          }
        }
      }
      .MuiTab-wrapper{
        flex-direction: row-reverse;
        gap: 1.5rem;
        justify-content: space-between;
        .edit-btn{
          display: block;
          height: 2rem;
          width: 2rem;
          margin-bottom: 0 !important;
          .edit-icon{
              display: block;
              width: 100%;
              height: 100%;
              svg{
                  width: 100%;
                  height: 100%;
                  circle:first-child{
                      fill: transparent;
                  }
                  circle:nth-of-type(2){
                      fill: #4d4d4f;
                  }
                  circle:nth-of-type(3){
                      fill: #4d4d4f;
                  }
                  circle:nth-of-type(4){
                      fill: #4d4d4f;
                  }
              }
          }
          &.active{
            .edit-icon{
              svg{
                  circle:first-child{
                      fill: #E5F0FF;
                  }
                  circle:nth-of-type(2){
                      fill: #0D2C54;
                  }
                  circle:nth-of-type(3){
                      fill: #0D2C54;
                  }
                  circle:nth-of-type(4){
                      fill: #0D2C54;
                  }
              }
            }
          }
          &:hover{
            .edit-icon{
              svg{
                  circle:first-child{
                      fill: #E5F0FF;
                  }
                  circle:nth-of-type(2){
                      fill: #0D2C54;
                  }
                  circle:nth-of-type(3){
                      fill: #0D2C54;
                  }
                  circle:nth-of-type(4){
                      fill: #0D2C54;
                  }
              }
            }
          }
        }
      }
    }
    .MuiTab-root{
       font-size:1.1rem;
       color:#4d4d4f;
       text-transform: unset;
       background:#fff;
       border-left:0.1px solid transparent;
       border-right:0.1px solid transparent;
       border-color:rgba(204, 204, 204,0.2);
       min-height: 4rem;
       &.Mui-selected{
        color:#4d4d4f;
      }
    }
    
    .MuiTabs-indicator{
      background-color:#007C66;
      height:0.5rem;
    }
    .MuiTabScrollButton-root{
      .MuiSvgIcon-root{
        font-size: 2.25rem;
      }
    }
`
export const TabAddBtn = styled.button`
    padding:1.3rem;
    line-height:0;
`

// dragable button  styles

export const DragableBtnContainer = styled.div`
       width:25rem;
       background: #FFFFFF;
       border-radius: 0.8rem;
       overflow:hidden;
       position:fixed;
       z-index:5;
      
   `;
export const DragableButton = styled.button`
       width:100%;
       padding:1rem 1.5rem;
       display:flex;
       align-items:center;
       gap:1rem;
       font-weight: 600;
       font-size: 1.2rem;
       color:#fff;
       transition:0.15s ease-in;
       text-align:left;
       background:linear-gradient(132deg, #8686DF 14.79%, #6464C8 85.39%);
       span{
        flex:1;
       }
       i{
         display:inline-block;
            &.ddl-angle{
                cursor:pointer;
                svg{
                    width:2rem;
                    height:2rem;
                    transition:0.15s ease-in;
                    path{
                        fill:#fff;
                    }
                }
            }
            &.drag-icon{
                cursor: grab;
                line-height:0;
                user-select:none;
                transition:0.15s ease-in;
                svg{
                    width:2rem;
                    height:3.2rem;
                    rect{
                        fill:transparent;
                    }
                    circle{
                        fill:#fff;
                    }
                }
                &:hover{
                    svg{
                        rect{
                            fill:#fff;
                        }
                        circle{
                            fill:#2C55A0;
                        }
                    }
                }
        }
       }
       
       
   `;
// Incomplete Wrapper


export const IncompleteSetupWrapper = styled.div`

`

export const IncompleteSetupCard = styled.div`
  min-width: 32rem;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 175px);
  .heading{
    color: #0D2C54;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2rem; /* 75% */
    margin-bottom: 1.6rem;
  }
  .main_img{
    display: block;
    width: 32rem;
    svg{
      width: 100%;
    }
  }
  .text{
    color: #4D4D4F;
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem; /* 142.857% */
  }
  .loss-elimination-btn{
    border-radius: 0.4rem;
    background: linear-gradient(132deg, #8686DF 14.79%, #6464C8 85.39%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    height: 2.9rem;   
    width: 19.3rem;
    margin-top: 1.6rem;
    i{
      display: block;
      height: 2rem;
      width: 2rem;
      svg{
        height: 100%;
        width: 100%;
      }
    }
    span{
      display: block;
      color: #FFF;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`
export const DropDownWrap = styled.div`
border-radius: 0.6rem;
background: #fff;
position:fixed;
z-index: 9;
width: 11.6rem;
visibility: hidden;
opacity: 0;
transition: 0.3s all ese-in-out;
bottom: 45px;
box-shadow: 0px 0px 8px 0px rgba(21, 27, 38, 0.12);
border: 0.1rem solid rgba(229, 240, 255, 1);
&.active{
  opacity: 1;
  visibility: visible;
}
.MuiListItem-button{
  display: block;
  font-size: 1.1rem;
  font-weight: 500;
  color: #4d4d4f;
  text-align: center;
  padding: 0.8rem 0;
  width: 100%;
  &:hover{
    background-color: #E5F0FF;
    color: #0D2C54;
  }
}
`

export const DeletePopWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(125, 134, 140, 0.3);
    z-index: 99;
`
export const DeletePopCard = styled.div`
    border: 0.1rem solid #DB324D;
    box-shadow: 0px 0px 8px 0px rgba(21, 27, 38, 0.12);
    padding: 2.4rem 2rem;
    background-color: #fff;
    border-radius: 0.6rem;
    width: 63rem;
    h2{
        color: #0D2C54;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.8rem;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 2rem;
    }
    p{
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 4rem;
        color: #4d4d4f;
    }
`

export const DeletePopFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
    .btn{
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2.6rem;
        letter-spacing: 0.046rem;
        min-width: 15rem;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.4rem;
        border: 0.1rem solid #DB324D;
        &.cancel-btn{
            color: #ffffff;
            background-color: #DB324D;
        }
        &.yes-btn{
            background-color: #FAE2E6;
            color: #DB324D;
        }
    }
`