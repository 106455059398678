/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * makeStyles for ===> styling material-ui component.
  * Tabs ===> matrial ui component for tabs.
  * ProfileTabPanel,PurchaseTabPanel,CertificatesTabPanel ===> custom tab component

**/

import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ProfileTabPanel } from "./profile-tabpanel.js";
import { PurchaseTabPanel } from "./purchase-tabpanel.js";
import { CertificatesTabPanel } from "./certificates-tabpanel.js";
import { SubscriptionTabPanel } from "./subscription-tabpanel.js";
//styling component
const useStyles = makeStyles((theme) => ({
	customtab: {
		"& .MuiTabs-indicator": {
			backgroundColor: "#118ACB",
			height: ".4rem",
		},
		"& .MuiTab-root": {
			minWidth: "auto",
			opacity: 1,
			padding: 0,
			marginRight: "5rem",
			textTransform: "unset",

			"& .MuiTouchRipple-root": {
				display: "none",
			},
			"& .act": {
				display: "none",
			},
			"& .inact": {
				display: "inline-block",
			},
			"&.Mui-selected": {
				"& .act": {
					display: "inline-block",
				},
				"& .inact": {
					display: "none",
				},
			},
		},
	},
}));
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <>{children}</>}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
//ProfileView component start
export const ProfileView = (props) => {
	const gid = localStorage.getItem("group_id");
	const classes = useStyles();
	const [value, setValue] = React.useState(props.onTab);
	//function to handle change
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<>
			{/* main wrapper has
				profile_header
				profile_tab_content
			*/}
			<div className="profile__page__main">
				{/* this section has 
					row 
					tabs
				*/}
				<div className="profile__header">
					{/* this section has
						profile_col
						profile_col_right 
					 */}
					<div className="profile__head__row">
						<div className="profile__col">
							<h2 className="h1">{props.onTab == 1 ? "Purchase History" : props.onTab == 3 ? "Certificate": props.onTab == 2 ? "Manage Subscription" : "Profile Settings"}</h2>
						</div>
						<div className="profile__col right">
							<img src={require("../../assets/img/profile/dots.svg").default} alt="true" />
						</div>
					</div>
					{/* profile__head__row end */}
					<Tabs className={classes.customtab} value={value} onChange={handleChange}>
						<Tab
							{...a11yProps(0)}
							component={Link}
							to="/profile"
							icon={
								<div className="profile__tab__btn">
									<img src={require("../../assets/img/profile/tab-profile-inact.svg").default} className="inact" alt="profile" />
									<img src={require("../../assets/img/profile/tab-profile-act.svg").default} className="act" alt="profile" />
									<span>Profile Settings</span>
								</div>
							}
						/>

						<Tab
							{...a11yProps(1)}
							component={Link}
							to="/purchase-history"
							style={{display:(gid != 16 && gid != 14)?'' : 'none'}}
							icon={
								<div className="profile__tab__btn">
									<img
										src={require("../../assets/img/profile/purchase-profile-inact.svg").default}
										className="inact"
										alt="profile"
									/>
									<img src={require("../../assets/img/profile/purchase-profile-act.svg").default} className="act" alt="profile" />
									<span>Purchase History</span>
								</div>
							}
						/>
						<Tab
							{...a11yProps(2)}
							component={Link}
							style={{display:gid ==2?'' : 'none'}}
							to="/subscription"
							icon={
								<div className="profile__tab__btn">
									<img
										src={require("../../assets/img/profile/subscription_inact.svg").default}
										className="inact"
										alt="subscription"
									/>
									<img src={require("../../assets/img/profile/subscription_act.svg").default} className="act" alt="subscription" />
									<span>Manage Subscription</span>
								</div>
							}
						/>
						<Tab
							{...a11yProps(3)}
							component={Link}
							style={{display:gid ==2?'' : 'none'}}
							to="/certificates"
							icon={
								<div className="profile__tab__btn">
									<img
										src={require("../../assets/img/profile/certificates-profile-inact.svg").default}
										className="inact"
										alt="profile"
									/>
									<img
										src={require("../../assets/img/profile/certificates-profile-act.svg").default}
										className="act"
										alt="profile"
									/>
									<span>Certificate</span>
								</div>
							}
						/>
					</Tabs>
				</div>
				{/*profile header end*/}
				<div className="profile__tab__content">
					{/* tab pannel wrapper */}
					<TabPanel value={value} index={0}>
						<ProfileTabPanel {...props} />
					</TabPanel>
					<TabPanel value={value} index={1}>
						<PurchaseTabPanel {...props} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<SubscriptionTabPanel {...props} />
					</TabPanel>
					<TabPanel value={value} index={3}>
						<CertificatesTabPanel {...props} />
					</TabPanel>
				</div>
			</div>
		</>
	);
};
