/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * MaintenanceLossesPageView ===> custom view component

**/

import React from "react";
import { MaintenanceLossesPageView } from "./maintenance-losses-page-view";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
//MaintenanceLossesPageController start
export const MaintenanceLossesPageController = (props) => {
  return (
    <>
      {/* Breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
			<BreadcrumbsItem to="/loss-analysis-admin">Loss Analysis</BreadcrumbsItem>
      <BreadcrumbsItem to="#">Maintenance Losses</BreadcrumbsItem>
      {/* custom view component */}
      <MaintenanceLossesPageView {...props} />
      
    </>
  );
};
