import React, { useState, useEffect } from "react";
import {
    MainWrapper,
    UpgradePopWrapper,
    UpgradePopWrap,
    UpgradePopHeader,
    HowCloseBtn,
    UpgradePopBody,
    UpgradePopColWrap,
    UpgradePopCol,
    UpgradePopCard,
    UpgradeCardHeader,
    UpgradeCardBody,
    UpgradeCardRow,
    UpgradeCol,
    UpgradeCard,
    UpgradeCardFooter,
} from "./roadmapstyles";
import {
    closeIcon, dataIcon, rankIcon, insightIcon, insertIcon,
    methodIcon, deliverIcon, goalIcon , asmtIcon, startegyIcon,lossIcon,lossCalIcon,
    plantDataInputIcon, dataAggregationIcon, dataCalibrationIcon,
} from "./roadmapsvg";

export const RoadmapUpgradeView = (props) => {
    const [openPop, setOpenPop] = useState(true);
    const Data = [
        {
            main_img: lossIcon ,
            main_title: `IR4.0 Loss Analysis `,
            price: `€ 1500`,
            inside_card: [
                {
                    img: plantDataInputIcon,
                    title: `Plant Data Input`,
                    pop_txt: `Each participant is assigned to an associated Loss section in the platform.`,
                },
                {
                    img: dataAggregationIcon,
                    title: `Data Aggregation`,
                    pop_txt: `Aggregation of data takes place 100% in the SmarterChains platform.`,
                },
                {
                    img: dataCalibrationIcon,
                    title: `Data Calibration`,
                    pop_txt: `Calibration and consolidation of data to ensure integrity and consistency.`,
                },
                {
                    img: lossCalIcon,
                    title: `Loss Calculation`,
                    pop_txt: `The calculation of the actual cost of Losses on an annual basis.`,
                },
            ],
        },
        {
            main_img: startegyIcon ,
            main_title: `IR4.0 Strategies`,
            price: `€ 1500`,
            inside_card: [
                {
                    img: goalIcon,
                    title: `Goal`,
                    pop_txt: `Identify key loss opportunities and define solutions to drive efficiency improvements`,
                },
                {
                    img: methodIcon,
                    title: `Methodology`,
                    pop_txt: `Loss and financial analysis, solution selection and cost-benefit analysis of the selected solutions `,
                },
                {
                    img: deliverIcon,
                    title: `Deliverables`,
                    pop_txt: `Industry 4.0 Roadmap with technology and organizational solutions, their cost-benefit analysis and 3 vendors for each solution`,
                },
            ],
        },
    ]
    return (
        <>
            <MainWrapper data-component="MainWrapper">
            {openPop &&
                <UpgradePopWrapper data-component="UpgradePopWrapper">
                    <UpgradePopWrap data-component="UpgradePopWrap">
                        <UpgradePopHeader data-component="UpgradePopHeader">
                            <span className="head-txt">Upgrade Now</span>
                            <div className="upgrade-right-head">
                                <HowCloseBtn data-component="HowCloseBtn" onClick={() => setOpenPop(false)}>
                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: closeIcon }}></i>
                                </HowCloseBtn>
                            </div>
                        </UpgradePopHeader>
                        <UpgradePopBody data-component="UpgradePopBody">
                            <p>
                                Hey there! Upgrade and set structured steps towards digital transformation by launching your Industry 4.0 Roadmap
                            </p>
                            <UpgradePopColWrap data-component="UpgradePopColWrap">
                                {Data.map((item,index)=>(
                                    <UpgradePopCol data-component="UpgradePopCol" key={index}>
                                        <UpgradePopCard data-component="UpgradePopCard">
                                            <UpgradeCardHeader data-component="UpgradeCardHeader">
                                                <i className="head-icon" dangerouslySetInnerHTML={{ __html: item.main_img }}></i>
                                                <span>{item.main_title}</span>
                                            </UpgradeCardHeader>
                                            <UpgradeCardBody data-component="UpgradeCardBody">
                                                <UpgradeCardRow data-component="UpgradeCardRow">
                                                    {item?.inside_card?.map((card_item,cardIndex)=>(    
                                                        <UpgradeCol data-component="UpgradeCol">
                                                            <UpgradeCard data-component="UpgradeCard" key={cardIndex}>
                                                                <i className="card-icon" dangerouslySetInnerHTML={{ __html: card_item.img }}></i>  
                                                                <span className="card-txt">{card_item.title}</span> 
                                                                <div className="pop-tooltip">
                                                                    <p className="pop-txt">{card_item.pop_txt}</p>
                                                                </div>
                                                            </UpgradeCard>
                                                    </UpgradeCol>
                                                    ))}
                                                </UpgradeCardRow>
                                            </UpgradeCardBody>
                                        </UpgradePopCard>
                                    </UpgradePopCol>
                                ))}
                            </UpgradePopColWrap>
                        </UpgradePopBody>
                        <UpgradeCardFooter data-component="UpgradeCardFooter">
                            <button className="footer-cart-btn">
                                    <span className="foot-text">Upgrade</span>
                                    <span className="price">€ 3000</span>
                            </button>
                        </UpgradeCardFooter>
                    </UpgradePopWrap>
                </UpgradePopWrapper>
            }
            </MainWrapper>
            {/* MainWrapper ended */}
        </>
    );
}

