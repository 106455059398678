import React, { useState, useEffect, useRef, memo } from 'react';
import {
    DropdownMainWrapper,
    DropdownButton,
    Icon,
    DropdownListWrapper,
    DropdownListButton,
    DropdownInput
} from './Count-dropdown-style.js';

const CountDropdown = (props) => {
    const {
        onChange = () => { },
        defaultValue = 1,
        valueFromMain
    } = props;
    const [value, setValue] = useState(valueFromMain || defaultValue);
    const options = ['1', '2', '3', '4', '5', '6', '7', '8','9', '10+'];
    const [isInput, setIsInput] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const inputRef = useRef();
    const handleVlaue = (val) => {
        onChange(val);
        setOpenDropdown(false);
        if (val == '10+') {
            setIsInput(true);
        } else if (val) {
            setValue(val);
        }
    }


    useEffect(() => {
        if (isInput && inputRef?.current) {
            const EndPoint = inputRef?.current?.value?.length;
            inputRef?.current?.focus();
            inputRef.current.value = value;
        }
    }, [isInput]);

    // useEffect(() => {
    //     onChange(value);
    // }, [value]);

    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('[data-component="DropdownMainWrapper"]')) {
                setOpenDropdown(false);
                setIsInput(false);
            }
        });
    });
    return (
        <DropdownMainWrapper data-component="DropdownMainWrapper">
            {!isInput ?
                <DropdownButton data-component="DropdownButton" title={valueFromMain || value} onClick={() => setOpenDropdown(true)} className={openDropdown ? 'inactive' : ''}>
                    <span>{valueFromMain || value}</span>
                    <Icon dangerouslySetInnerHTML={{ __html: DropdownIcon }}></Icon>
                </DropdownButton>
                :
                <DropdownInput
                    defaultValue={valueFromMain || value}
                    type="text" ref={inputRef}
                    onBlur={(e) => {
                        handleVlaue(e.target.value);
                    }}
                />
            }
            <DropdownListWrapper data-component="DropdownListWrapper" className={openDropdown ? 'active' : ''}>
                <DropdownButton data-component="DropdownButton" title={valueFromMain || value} onClick={() => setOpenDropdown(false)}>
                    <span>{valueFromMain || value}</span>
                    <Icon dangerouslySetInnerHTML={{ __html: DropdownIcon }}></Icon>
                </DropdownButton>
                {
                    options.map((li, ind) =>
                        <DropdownListButton
                            key={ind}
                            data-component="DropdownListButton"
                            onClick={() => handleVlaue(li)}
                            className={((li == (valueFromMain || value) && (valueFromMain || value) < 10) || (li === '10+' && (valueFromMain || value) >= 10)) ? 'active' : ''}
                        >
                            {li}
                        </DropdownListButton>
                    )
                }
            </DropdownListWrapper>
        </DropdownMainWrapper>
    );
}




export default memo(CountDropdown);

export const DropdownIcon = `<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.2675 3.70711L5.01478 6.95983C4.85857 7.11604 4.6053 7.11604 4.44909 6.95983L1.1964 3.70714" stroke="#7D868C" stroke-linecap="round"/>
</svg>
`