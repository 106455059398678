/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.

**/

import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Helmet } from "react-helmet";
import {SkillQuizWalkthrough} from './skill-quiz-walkthrough.js'
//SkillView component start
export const SkillView = (props) => {
  //defining variables here
  const [iSWalkthrough,setISWalkthrough]=useState(1);

  const [disabled, setDisabled] = useState(true);
  var totSelected = [];
  useEffect(() => {
    var element = document.getElementById("quizForm");
    if (typeof element != "undefined" && element != null) {
      totSelected = [];
      element.reset();
    }
  }, []);
  const quizDataItems = props.quizData;
  if (!quizDataItems.length) {
    return null;
  }
  const quizDataItem = props.quizData[0];
  const group_id = localStorage.getItem("group_id");
  if (quizDataItem.block == 1 || (group_id==17)) {
    //window.location = "/";
    return <Redirect to="/404" />;
  }
  if (quizDataItem.has_paid == 0) {
    window.location = "/";
    <Redirect to={`/training-courses/${quizDataItem.course_slug}`} />;
  }
  const saveForm = () => {
    let formData = new FormData(document.getElementById("quizForm"));
    serviceProvider
      .post("/skills?type=savequiz", formData, true)
      .then((response) => {
        // console.log(response.data);
      });
  };
  //function to submit form
  const submitForm = () => {
    let formData = new FormData(document.getElementById("quizForm"));
    serviceProvider
      .post("/skills?type=submitquiz", formData, true)
      .then((response) => {
        props.onSubmitQuiz(response.data);
        props.onSubmitQuizShow(true);
      });
  };
  
  const submitFormSingle = () => {
    let formData = new FormData(document.getElementById("quizForm"));
    serviceProvider
      .post("/skills?type=savequiz", formData, true)
      .then((response) => {
        // console.log(response.data);
      });
  };
  
  
  quizDataItem.questions.map((ques) => {
    if (ques.has_answered == 1) {
      totSelected.push(ques.id);
    }
  });
  //function to change value
  const changeValue = (qid) => {

    var countchecked = document.querySelectorAll(
      "input.inp-" + qid + ":checked"
    ).length;
    var element = document.getElementById("quiz__sec__" + qid);
    var aindex = totSelected.indexOf(qid);
    if (countchecked > 0) {
      if (aindex == -1) {
        totSelected.push(qid);
      }
      element.classList.add("selected");
    } else {
      if (aindex > -1) {
        totSelected.splice(aindex, 1);
      }
      element.classList.remove("selected");
    }
	var countchecked = document.getElementsByClassName("selected").length;
    if(countchecked == quizDataItem.questions.length) {
      setDisabled(false);
    }else{
		 setDisabled(true);
		}
  };
  const Quizes = quizDataItem.questions.map((ques, index) => {
    return (
      <div
        key={ques.id}
        id={`quiz__sec__${ques.id}`}
        className={
          ques.has_answered == 1
            ? "quiz__body__card selected"
            : "quiz__body__card"
        }
      >
        <header>
          {/* this div has
              index
              image
          */}
          <div className="no__div">
            <span>{index + 1}</span>
            <img
              className="green"
              src={require("../../assets/img/quiz/done-green.svg").default}
              alt="true"
            />
            <img
              className="red"
              src={require("../../assets/img/quiz/cancel-red.svg").default}
              alt="true"
            />
          </div>
          {/* no__div end */}

          <div className="middle__div">
            <p className="b__text">{ques.question}</p>
          </div>
          {/* middle__div end */}
          <div className="last__div">1 point</div>
          {/* last__div end */}
        </header>
        {/* this wrapper has answer format */}
        <div className="ans__div" onClick={() => changeValue(ques.id)}>
          {ques.ques_type == 1 ? (
            ques.answers.length ? (
              ques.answers.map((ans) => (
                <div key={ans.id}>
                  <label className="quiz__radio">
                    {/* defaultChecked */}
                    <input
                      type="radio"
                      className={`inp-${ques.id}`}
                      name={`ques-${ques.id}[]`}
                      value={ans.id}
                      defaultChecked={ans.is_checked ? "checked" : ""}
					  onClick={submitFormSingle}
                    />
                    <span className="icon"></span>
                    <span className="wrong__icon">
                      <img
                        src={
                          require("../../assets/img/quiz/wrong-icon.svg")
                            .default
                        }
                        alt="true"
                      />
                    </span>
                    <span className="labe__text">{ans.option_name}</span>
                  </label>
                </div>
              ))
            ) : (
              <></>
            )
          ) : ques.answers.length ? (
            ques.answers.map((ans) => (
              <div key={ans.id}>
                <label className="quiz__chkbox">
                  <input
                    type="checkbox"
                    className={`inp-${ques.id}`}
                    name={`ques-${ques.id}[]`}
                    value={ans.id}
                    defaultChecked={ans.is_checked ? "checked" : ""}
					onClick={submitFormSingle}
                  />
                  <span className="icon">
                    <img
                      src={
                        require("../../assets/img/quiz/chk-white.svg").default
                      }
                      alt="true"
                    />
                  </span>
                  <span className="correct__icon">
                    <img
                      src={
                        require("../../assets/img/quiz/chk-white.svg").default
                      }
                      alt="true"
                    />
                  </span>
                  <span className="wrong__icon">
                    <img
                      src={
                        require("../../assets/img/quiz/wrong-icon.svg").default
                      }
                      alt="true"
                    />
                  </span>
                  <span className="labe__text">{ans.option_name}</span>
                </label>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  });
  return (
    <React.Fragment>
      {/* this section has page title */}
      <Helmet>
        <title>{quizDataItem.course_name} Assessment | SmarterChains</title>
        <meta name="description" content="Training Courses" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {/* breadcrumbs */}
      <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
      <BreadcrumbsItem to="/skill-assessment">
        Skills Assessment
      </BreadcrumbsItem>
      <BreadcrumbsItem to={`/training-courses/${quizDataItem.course_slug}`}>
        {quizDataItem.course_name} Assessment
      </BreadcrumbsItem>
      {/* <BreadcrumbsItem to="#">Quiz</BreadcrumbsItem> */}
      {/* this section has

      */}
      <div className="skill__main__wrapper">
        {/* this section has
            quiz__header
            form
            quiz__footer
        */}
        <div className="quiz__left__wrapp">
          {/* this section has
              quiz__header__content
              quiz__header__img
          */}
          <div className="quiz__header">
            {/* this section has
                heading 
                large text
                small text
            */}
            <div className="quiz__header__content">
              <h2 className="h1">
                {/* <Link to={`/training-courses/${quizDataItem.course_slug}`} className="back__link">
									<img src={require("../../assets/img/quiz/back-arrow.svg").default} alt="true" />
								</Link> */}
                <span>{quizDataItem.course_name} Assessment</span>
              </h2>
              <p className="skill_large">
              The assessment consists of 10 questions and each one rewards you with 1 point. 
							</p>
              <p className="small">
                Total points: <span>{quizDataItem.questions.length}</span>
              </p>
            </div>
            <div className="quiz__header__img">
              {/* {"img-quiz-id",quizDataItem.id} */}
              <img
                src={
                  require(`../../assets/img/training-quiz/tqicon-${quizDataItem.id}.svg`)
                    .default
                }
                alt={quizDataItem.course_name}
              />
            </div>
          </div>
          {/* quiz__header end */}
          {/* form has inputs */}
          <form id="quizForm">
            <input type="hidden" name="uid" value={quizDataItem.uid} />
            <input type="hidden" name="cid" value={quizDataItem.id} />
            <div className="quiz__body">{Quizes}</div>
          </form>
          {/* this section has buttons */}
          <div className="quiz__footer">
            {/* <button onClick={saveForm} className="btn__save">
							Save
						</button> */}
            {totSelected.length == quizDataItem.questions.length ? (
              <button
                onClick={submitForm}
                id="btn__quiz_submit__btn"
                className="btn__submit"
              >
                Submit
              </button>
            ) : (
              <button
                onClick={submitForm}
                id="btn__quiz_submit__btn"
                className="btn__submit"
                disabled={disabled}
              >
                Submit
              </button>
            )}
          </div>
        </div>
        {/* quiz__left__wrapp  end */}
        {/* this section has card */}
        <div className="quiz__right__wrapp" style={{ display: "none" }}>
          {/* this section has
              progress circle
              Retake Quiz
          */}
          <div className="card">
            <div className="svg__cir__wrapp">
              <SvgCircle value={60} />
            </div>

            <button className="btn__retake__quiz">Retake Quiz</button>
          </div>
        </div>
        {/* quiz__right__wrapp end */}
      </div>
      {/* skill__main__wrapper end */}

      {/* unsaved file popup start */}
      {props.isAnsPoup && (
        <div className="skill__unsaved__ans__popup">
          {/* this section has
              close-btn
              popup-text
              btn__wrapper
          */}
          <div className="popup__content">
            <button className="close_btn" onClick={props.HandleUnsavedAnsPopup}>
              <img
                src={
                  require("../../assets/img/common-img/close-icon-blue.svg")
                    .default
                }
                alt="true"
              />
            </button>
            <p className="popup__text">
              <img
                src={require("../../assets/img/quiz/unsaved-file.svg").default}
                alt="true"
              />
              <span>
                You have unsaved answers. Do you want to Save your progress?
              </span>
            </p>
            <div className="btn__wrapper">
              <button className="no__thanks__btn">No thanks</button>
              <button className="save__ans__btn">Save my answers</button>
            </div>
          </div>
        </div>
      )}
      {/* unsaved file popup end */}

      {(iSWalkthrough && quizDataItem.skill_assessment2==0)&&<SkillQuizWalkthrough setISWalkthrough={setISWalkthrough}/>}
    </React.Fragment>
  );
};
//progress circle component
const SvgCircle = (props) => {
  const { value } = props;
  return (
    <svg viewBox="0 0 36 36">
      <text
        className="percent__text"
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="10"
        fontWeight="700"
      >
        {value}
        <tspan fontSize="5">%</tspan>
      </text>
      <path
        className="circle-bg"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke="#EBF5FA"
        strokeWidth="2.4"
        fill="none"
      ></path>
      <path
        className="circle"
        strokeDasharray={`${value}, 100`}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={
          value < 80 && value > 0
            ? "#FFBC1F"
            : value > 79
              ? "#40D30D"
              : "transparent"
        }
        strokeWidth="2.4"
        fill="none"
      ></path>
    </svg>
  );
};




