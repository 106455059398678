/** imported files

# serviceProvider ===>from axios for api call  
# motion ===> for animate component
# CartContext ===> custom component

**/


import React, { useContext, useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import serviceProvider from '../../services/axios'
import { CartContext } from "../../contexts/CartContext";
import CountDropdown from "./CountDropdown";
import { substractSvg, checkIcon, cancelIcon, percentIcon } from "./svg";
import { motion } from "framer-motion";

export const CartView = (props) => {
	// variable declaration 
	const { cartItems } = useContext(CartContext);
	const [isNotCouponApply, setIsNotCouponApply] = useState(false);
	const [isRemoveIcon, setIsRemoveIcon] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const { total, editProduct, itemCount, increase, decrease, removeProduct } = useContext(CartContext);
	const list = {
		visible: { opacity: 1 },
		hidden: { opacity: 0 },
	};

	const item = {
		visible: { opacity: 1, x: 0 },
		hidden: { opacity: 0, x: 100 },
	};
	const [totalPaid, setTotalPaid] = useState(0);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	const [isFirstTime, setFirstTime] = useState(true);
	const handleUpgradePopupClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		document.querySelector('.header-icons-li.upgrade .upgrade-btn')?.click();
	}

	let handleCombo = (combo_data) => {
		let filterArray = [];
		let tmpComboIdArr = [];
		let finalArray = [];
		combo_data.forEach(element => {
			if (element.combo_id !== 0) {
				filterArray.push(element);
				if (!tmpComboIdArr.includes(element.combo_id)) {
					tmpComboIdArr.push(element.combo_id);
				}
			}
		});
		tmpComboIdArr.forEach((item) => {
			let tmpArr = filterArray.filter(i => item == i.combo_id);
			if (tmpArr.length > 0) {
				finalArray.push(tmpArr);
			}
		});
		return finalArray;
	}

	let handlePriceSum = (arr) => {
		let price = 0;
		arr.forEach(i => {
			price += i.price
		});

		return price;
	}


	let addCount = (tmpProduct, tmpChangedCount) => {
		if (!isFirstTime) {
			let newcount = parseInt(tmpChangedCount);
			tmpProduct.count = newcount;
			editProduct(tmpProduct);

		} else {
			setFirstTime(false);
		}
	}
	let handleUpdateCount = (arr, val) => {
		arr.forEach(i => {
			addCount(i, val)
		});
	}
	let handleRemoveCombo = (arr) => {
		arr.forEach(i => {
			removeProduct(i);
		});
	}
	// Discount Function
	const handleCouponApply = () => {
		setIsNotCouponApply(true);
	}
	const handleInputChange = (e) => {
		const value = e.target.value;
		setInputValue(value);
	}
	const handleCustomCouponApply = () => {
		setIsNotCouponApply(false);
		setIsRemoveIcon(false);
		setInputValue('');
	}

	//useEffect call here
	useEffect(() => {
		const search = async () => {
			const apiUrl = `/home?param=paiditem&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setTotalPaid(data);
		};
		search();
	}, []);



	return (
		<>
			<div className="cart__page__wrapper">
				{/* CART PAGE LEFT SECTION DIV STARTED*/}
				<div className="cart__page__left">
					<div className="cart__time__line">
						<div className="time__line__item inprogress">
							{" "}
							{/*add complete class when completed*/}
							<span>My cart</span>
						</div>
						<div className="time__line__item">
							<span>Billing Info</span>
						</div>
						<div className="time__line__item">
							<span>Payment</span>
						</div>
					</div>
					{/* cart__time__line end */}
					<div className="cart__left__content">
						<div className="cart__page__items">
							<h2 className="h1">My Cart</h2>
							<div className="cart__page__table">
								{itemCount >= 1 && (
									<header>
										<div className="col__big">
											<span>Item</span>
										</div>
										<div className="col__2">
											<span>Price</span>
										</div>
										{/* 
										<div className="col__3">Quantity</div> 
										<div className="col__4"></div>
										*/}
									</header>
								)}
								{itemCount == 0 && <p className="empty__msg">Your cart is empty</p>}

								{itemCount != 0 &&
									<section className="cart__items">
										<div className="col__big">
											<p className="b">Industry 4.0 Dimensions Training</p>
											<p className="normal__txt">1 year access</p>
											<ul className="col_big_ul">
												<li><p className="normal__txt">17+ hours of content</p></li>
												<li><p className="normal__txt">Members only Full Access for 1 year</p></li>
												<li><p className="normal__txt">Only Until 31st December 2023</p></li>
											</ul>
										</div>
										<div className="col__2">
											<p className="b text-uppercase">$2990</p>
										</div>
									</section>
								}
								{/* header end */}
								{/* {cartItems.map((product) => (
									<>
										{product.combo_id === 0 &&
											<section className="cart__items">
												<div className="col__big">
													<p className="b">{product.course_name}</p>
													<p className="normal__txt">Industry 4.0 Dimension Training Course</p>
												</div>
												<div className="col__2">
													<p className="b text-uppercase">{ product.price==0?'Free': `$ ${product.price}`}</p>
												</div>
												<div className="col__3">
													<CountDropdown onChange={(val) => addCount(product,val)} defaultValue={product.count} />
												</div>
												<div className="col__4">
													<button className="remove__btn upgrade" onClick={() => removeProduct(product)}>
														<span>Remove</span>
													</button>
												</div> 
												
											</section>
										}
									</>
								))} */}
								{handleCombo(cartItems)?.map((product, index) => (
									<>
										{<section className="cart__items">
											<div className="col__big">
												<p className="b">Combo {index + 1}</p>
												<p className="normal__txt flex-div">
													<span>{product[0].count > 1 ? `${product[0].count} Plants` : `${product[0].count} Plants`}</span>
													{product?.map((item) => (
														<span className="dot-cir">{item.course_name}</span>
													))
													}
												</p>
											</div>
											<div className="col__2">
												<p className="b text-uppercase">${handlePriceSum(product)}</p>
											</div>
											<div className="col__3">
												<CountDropdown onChange={(val) => handleUpdateCount(product, val)} defaultValue={product[0].count} />
											</div>
											<div className="col__4">
												<button className="remove__btn upgrade" onClick={() => handleRemoveCombo(product)}>
													<span>Remove</span>
												</button>
											</div>
										</section>
										}
									</>
								))}



								{/* <div className="add__another__course">
									{itemCount >= 1 && itemCount <= 9 && (
										<Link onClick={(e) => handleUpgradePopupClick(e)} className="btn__add__another">
											+ Explore more products
										</Link>
									)}
									{itemCount == 0 && (
										// <Link to="/training-courses" className="btn-Checkout">
										// 	+ Explore more products
										// </Link>
										<Link className="btn__add__another" onClick={(e) => handleUpgradePopupClick(e)}>
											+ Explore more products
										</Link>
									)}
								</div> */}
							</div>
							{/* cart__page__table end */}
						</div>
						{/* cart__page__items end */}

						{/* cart__page__items end */}

						{/*payment__items end */}
					</div>
				</div>
				{/* #CART PAGE LEFT SECTION DIV ENDED
			#CART PAGE RIGHT SECTION DIV STARTED
		*/}
				<div className="cart__page__right">
					<div className="cart__page__box">
						{/* cart section start */}
						<motion.div initial="hidden" animate="visible" variants={list} className="train--cart-sec">
							<motion.div variants={item} className="card__rgt--header">
								<h2 className="h2">Summary</h2>
								<motion.div variants={item} className="train-row cart__head--wrapp">
									<div className="train-col-lg-6 cart__sub--head">Subtotal:</div>
									<div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">{itemCount == 0 ? '$0' : '$2990'}</div>
								</motion.div>
								{itemCount != 0 &&
									<>
										<div className="discount-wrapper">
											<div className="discount-wrap-box">

												{isNotCouponApply ?
													<>
														<input className="discount-input" value={inputValue} placeholder="Enter Coupon Code" onChange={(e) => handleInputChange(e)} />
														<div className="right-section">
															<i dangerouslySetInnerHTML={{ __html: cancelIcon }} className="cancel-icon" style={{ "display": "none" }}></i>
															{isRemoveIcon ? '' :
																<button className={`apply-text ${inputValue.length >= 1 ? 'active' : ''}`} onClick={() => setIsRemoveIcon(true)}>APPLY</button>
															}
															{isRemoveIcon &&
																<button className="cart-cancel-btn" onClick={() => handleCustomCouponApply()}>
																	<i dangerouslySetInnerHTML={{ __html: checkIcon }}></i>
																	<span>REMOVE</span>
																</button>
															}
														</div>
													</>
													:
													<>
														<span className="discount-code">DISCOUNT83</span>
														<div className="cart-cancel-btn" onClick={() => {/*handleCouponApply()*/}}>
															<i dangerouslySetInnerHTML={{ __html: checkIcon }}></i>
															<span>REMOVE</span>
														</div>
													</>
												}
											</div>
										</div>
										<div className="discount-coupon-wrap">
											<div className="left-coupon-wrap">
												<div className="percent-coupon-text">
													<i dangerouslySetInnerHTML={{ __html: substractSvg }}></i>
													83%
												</div>
												<span className="discount">DISCOUNT</span>
											</div>
											<div className="right-coupon-wrap">
												<div className="top-box">
													<div className="coupon-text">
														<span className="bold-text">DISCOUNT83</span>
														<span className="sub-text">Save $2490 on this purchase</span>
													</div>
													<button className="apply-text" onClick={() => setIsNotCouponApply(false)}>{isNotCouponApply ? 'APPLY' : 'APPLIED'}</button>
												</div>
												<span className="bottom-text">Launch Offer Bundle - 83% off on this order </span>
											</div>
										</div>
									</>
								}
							</motion.div>
							{/*card header section ended*/}

							{/*card body section ended*/}
							<motion.div variants={item} className="card__rgt--footer train-container">

								{itemCount != 0 &&
									<div className="discount-row train-row">
										<div className="train-col-lg-6 discount-lft-text">Discount:</div>
										<div className="train-col-lg-6 discount-rgt-text">$2490</div>
									</div>
								}
								<div className="train-row cart__head--wrapp cart__head--wrapp-total">
									<div className="train-col-lg-6 cart__sub--head cart__sub--head-lgt">Total:</div>
									<div className="train-col-lg-6 cart__sub--head cart__sub--head-rgt">${total}</div>
									{
										itemCount != 0 &&
										<div className="train-col-lg-12 cart__sub--head">
											<Link to="/billinginfo" className="btn-Checkout">
												Checkout
											</Link>
											{/* {itemCount >= 1 && (
											<Link to="/billinginfo" className="btn-Checkout">
												Checkout
											</Link>
										)}
										{itemCount == 0 && (
											// <Link to="/training-courses" className="btn-Checkout">
											// 	+ Explore more products
											// </Link>
											<Link className="btn-Checkout" onClick={(e) => handleUpgradePopupClick(e)}>
												+ Explore more products
											</Link>
										)} */}
										</div>
									}
								</div>
							</motion.div>
							{/*card footer section ended*/}
						</motion.div>
						{/* checkout section start */}
					</div>
				</div>
				{/* CART PAGE RIGHT SECTION DIV ENDED */}
			</div>
		</>
	);
};
