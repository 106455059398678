/** imported components
 
  * AssessmentPlantView ===> view file of assessment component.
  * assessment.scss for ===> styling css file for this page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)

**/

import React, { useState, useEffect } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { AssessmentPlantView } from "./assessmentplant-view";
import "./assessment.scss";
import serviceProvider from '../../services/axios'
//Assessment Component start
export const AssessmentPlantController = (props) => {
	 const getLastItem = (thePath) => thePath.substring(thePath.lastIndexOf("/") + 1);
	 const slug = getLastItem(window.location.pathname);
	 const [see,setSee] =  useState(false);
	 let seurlori = window.location.search;
     let seurlresult = seurlori.replace("?", "");
	 
	const [results, setResults] = useState([]);
	const [up, setUp] = useState(0);
	const user = JSON.parse(localStorage.getItem("userData"));
	const usid = user ? user.uid : 0;
	
	const search = async () => {
			const apiUrl = `/assessmentmaturity?param=section&slug=${slug}&uid=${usid}`;
			const { data } = await serviceProvider.get(apiUrl, true, {});
			setResults(data);
			if(seurlresult == 'open_line'){
		 setSee(seurlresult);
	}
	};
	
	useEffect(() => {
		search();
	}, [up]);
	

if (results.length) {
		
		
			
			if(results[0].is_completed == 3){
						var pp = "/not-found";
				        return <Redirect to="/404" />;
						return (
								<>
									<div></div>
								</>
						);
			}else{
					
					return <AssessmentPlantView setSee={setSee} see={see} getAssessmentDetails={search} setUpData={setUp} sectionData={results} {...props} />;
			}
			
			
	}else{
		return (
		<>
			<div></div>
		</>
	);
		
		}
    
	
	
	
	
};
