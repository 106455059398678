/** imported components

  * CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext, ButtonFirst ===> component from react for carousel
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Helmet ===> for add specific title & CSS for the page.
  * serviceProvider for ===> working with api
  * .scss for ===> styling css file for this page.
  *  Modal ===> component from UI used to work as popup

**/
import React, { useState, useEffect } from "react";
import serviceProvider from '../../services/axios'
import { Redirect, Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { CarouselProvider, Dot, Slide, Slider, ButtonBack, ButtonNext, ButtonFirst } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./configurator-walkthrough.scss";
//TrainingWalkthrough component start
export const ConfiguratorWalkthroughView = (props) => {

	//defining variables here
	const user = JSON.parse(localStorage.getItem("userData"));
	const name = user ? user.name : "";
	
	const firstSlideData = [
		{

			img: "roadmap-step1.svg",
			title: `Let’s explore ‘Define ’ phase of IR4.0 plant roadmap and business case strategy definition`,
			paragraph: [`Define your plants IR4.0 roadmap and business case while simulating value recovery and investment assumptions to create the roadmap your team is aligned to execute.`],
		},
		{
			img: "roadmap-step2.gif",
			title: `Hi ${name}, welcome to the IR4.0 strategy tour!​ ​`,
			paragraph: [`The IR4.0 Strategy tool enables users to create the plant’s Industry 4.0 Roadmap and strategy.`,
			`The tool feeds data from the IR4.0 Technology Assessment and IR4.0 Loss Analysis in order to help drive your plant's IR4.0 solutions strategy decisions.`,
			`The IR4.0 Strategy tool also helps you create multiple strategy scenarios while simulating your own assumptions`,
		],

		},
		{
			img: "roadmap-step3.gif",
			title: `Select your IR4.0 roadmap solutions ​`,
			paragraph: [`In the first step of the IR4.0 strategy tool you can select IR4.0 solutions and make multiple scenarios  based on a variety of factors such as`,  
			`1. Technological or Organisational  Needs`,`2. Cost reduction`,`3. Investment affordability`]
		},
		{
			img: "roadmap-step4.gif",
			title: `Define the savings value each selected IR4.0 solution can recover​`,
			paragraph: [`In the second step you either go along with the automated savings suggestions per 
			IR4.0 solution you selected  of the tool or you can integrate your own savings 
			assumptions on the impact of your selected IR4.0 solutions on your operations work processes.`,],
		},
		{
			img: "roadmap-step5.gif",
			title: `Define the investment value each selected IR4.0 solution will require​`,
			paragraph: [`In the third step you either go along with the automated investment suggestions per IR4.0 
			solution you selected of the tool
			or you can integrate your own investment assumptions of your selected IR4.0 solutions.`,],
		},
		{
			img: "roadmap-step6.gif",
			title: `Define your IR4.0 plant roadmap execution timeline of each IR4.0 solution​`,
			paragraph: [`In the final step you define the timeline of execution of each IR4.0 solution you selected. 
			The timeline defines also the financial modelling of the roadmap as well and its financial attractiveness.`,],
		},
		{
			img: "roadmap-step7.gif",
			title: `IR4.0 Strategy Results​`,
			paragraph: [`The IR4.0 Strategy Results consist of multiple Power BI interfaces 
			that provide a comprehensive performance analysis of the created roadmap.`,
			`1. IR4.0 roadmap and timeline`,  
			`2. IR4.0 Size of the price analysis`,
			`3. Loss recoverable overview`,
			`4. Loss recovery by IR4.0 technology`,
			`5. Investment by IR4.0 technology`,
			`6. IR4.0 Cost Benefit Analysis`,
			,],
		},
		
	];
	// const { isOnboarding } = props;
	const TotalFirstSlides = firstSlideData.length;
	const {fistSlideIndex, setFistSlideIndex} = props;
	// function to handle first slide
	const HandleFirstSlide = (ev) => {
		if (ev === "prev" && fistSlideIndex > 0) {
			let tmpVal = fistSlideIndex - 1;
			setFistSlideIndex(tmpVal);
		} else if (ev === "next" && fistSlideIndex < TotalFirstSlides - 1) {
			let tmpVal = fistSlideIndex + 1;
			setFistSlideIndex(tmpVal);
		} else if (ev !== "next" && ev !== "prev") {
			setFistSlideIndex(ev);
		}
	};
	//function to close
	const HandleClose = () => {
		props.handleClose();
	};
	return (
		<Modal open={true} className={`onboarding-modal bg-op-white`}>
			<>
				<div className="first-type-modal">
					{firstSlideData.map((item, index) => (
						/* card has been divided into
							button
							header-1
							content-1
							footer-1
						*/
						<div className="card-1" key={index} style={{ display: index !== fistSlideIndex ? "none" : "",paddingBottom:"2rem" }}>
							
						{/* this section has image */}
							<div className="header-1">
								<div className="div-img" style={{position:'relative',top: '-0.5rem'}}>
									<img src={require(`../../assets/img/platform-onboarding-tour/${item.img}`).default} alt="true" />
								</div>
							</div>
							{/* header-1 end */}
							{/*	this section has 
								title
								paragraph 
							*/}
							<div className="content-1" style={{paddingTop:"1.5rem",paddingBottom:"1.5rem"}}>
								<p className='r-count'>{fistSlideIndex+1} of 7</p>
								<h2 className="title-h1">{item.title}</h2>
								{item.paragraph.map(p=>( <p dangerouslySetInnerHTML={{__html:p}}></p>))}
							</div>
							{/* content-1 end */}
							{/* footer has
								dots section to navigate
								previous button
								next button
							*/}
							<div className="footer-1">
								<div className="dots-sec">
									<button onClick={() => HandleFirstSlide(0)} disabled={fistSlideIndex === 0 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(1)} disabled={fistSlideIndex === 1 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(2)} disabled={fistSlideIndex === 2 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(3)} disabled={fistSlideIndex === 3 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(4)} disabled={fistSlideIndex === 4 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(5)} disabled={fistSlideIndex === 5 ? true : false}></button>
									<button onClick={() => HandleFirstSlide(6)} disabled={fistSlideIndex === 6 ? true : false}></button>
								</div>
								{fistSlideIndex === 0 && <button className="btn-blue-link" onClick={HandleClose}>Exit Tour</button>}
								{fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides && (
									<button onClick={() => HandleFirstSlide("prev")} className="btn-blue-link">Previous</button>
								)}
	
								{fistSlideIndex === 0 && (
									<button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
										Let’s go
									</button>
								)}
								{fistSlideIndex > 0 && fistSlideIndex < TotalFirstSlides - 1 && (
									<button className="btn-blue" onClick={() => HandleFirstSlide("next")}>
										Next
									</button>
								)}
								{fistSlideIndex === TotalFirstSlides - 1 && (
									<button className="btn-blue" onClick={HandleClose}>
										Exit Tour
									</button>
								)}
							</div>
							{/* footer-1 end */}
						</div>
					))}
				</div>
			</>
		</Modal>
	);
};
