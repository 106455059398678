

//   * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
//   * $,{ isEmptyObject } from jquery ===> component from jquery to use jquery for the page.
//   * useHistory ===> hook to access the history object.
//   * useState, useEffect ===> React hooks (useState is a way to get/set value)
//   * Helmet ===> for add specific title & CSS for the page.
//   * serviceProvider for ===> working with api
//   * FinancialValidationPageController ===> Component call for this page
//   * FTEWalkthroughQuesView ===> Component call for walkthrough
//   * .scss for ===> styling css file for this page.
//   * AssignSectionPopup ===> popup for assigning user
//   * LossSupportView ===> Support component of loss



import React, { useEffect, useRef } from "react";
import "./fte-breakdown-page.scss";
import serviceProvider from '../../services/axios'
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import $, { isEmptyObject } from 'jquery';
import { AssignSectionPopup } from "../loss-analysis-admin-page/loss-assing-popup";
import { FinancialValidationPageController } from "../financial-validation/financial-validation-page-ctrl";
import { number } from "prop-types";
import { FTEWalkthroughQuesView } from './fte-breakdown-walkthrough'
// Loss support component
import { LossSupportView } from '../la-support/la-support-view';
import { InviteUserList } from '../invite-user-list-component/InviteUserList'
// How to use popup
import { HowToUseCtrl } from '../lossanalysis-how-to-use-popup/HowToUseCtrl'

// end
//FteBreakdownPageView component start
export const FteBreakdownPageView = (props) => {
   //Defining variables here
   const quesDatas = props.quesResult;
   if (!quesDatas.length) {
      return null;
   }
   const quesData = props.quesResult[0];
   
   const pid = quesData.pid;
   const quesDataList = props.quesResult[0].questions;
   const bcaselines = props.quesResult[0].bcaselines;
   var linesans = quesData.linsans;
   const year = quesData?.customData.fiscal_year;
   const currency = quesData.customData.currency;
   const progress = quesData?.Secprogress;
   const islock = quesData?.isLocks;
   const [isSubmitPopup, setIsSubmitPopup] = useState(false);
   const [activeSlide, setActiveSlide] = useState(1);
   const [isLossSupport, setIsLossSupport] = useState(false);
   const [isTicket, setIsTicket] = useState(true);
   const [openfinvalidation, setOpenFinValidation] = useState(quesData.open_fin_validation); 
   const cost = () => { }
   const [shown, setShown] = useState(false);
   const [laborCost, setLaborCost] = useState(false);
   const [isRightPopup, setIsRightPopup] = useState(0);
   const [isFillPopup, setIsFillPopup] = useState(null);
   const [value, setValue] = useState();
   const [dropScreen, setDropScreen] = useState(true);
   const [dropScreenSec, setDropScreenSec] = useState(true);
   const [validationpopup, setValidationPopup] = useState(false); // for migration
   //Function for class toggle
   const toggleClass = (e) => {
      e.preventDefault();
      setDropScreenSec(!dropScreenSec);
   }
   //Function for section toggle
   const togglesection = (e) => {
      e.preventDefault();
      setDropScreen(!dropScreen);
   }
   //Array declaration for information list
   const [infoList, setInfoList] = useState([
      {
         name: `Average Cost of Direct Labor`,
         editable: false,
         rule: {
            topText: `Cost of Direct Labor`,
            bottomText: `Total Number of Direct FTEs`
         },
         isRuleDiv: true,
      },
      {
         name: `Average Quality Labor Cost`,
         editable: false,
         rule: {
            topText: `Cost of Direct Labor`,
            bottomText: `Total Number of Direct FTEs`
         },
         isRuleDiv: true,

      },
      {
         name: `Average Maintenance Labor Cost`,
         editable: false,
         rule: {
            topText: `Cost of Direct Labor`,
            bottomText: `Total Number of Direct FTEs`
         },
         isRuleDiv: true,
      },
      {
         name: `Average IT Labor Cost`,
         editable: false,
         rule: {
            topText: `Cost of Direct Labor`,
            bottomText: `Total Number of Direct FTEs`
         },
         isRuleDiv: true,
      },
      {
         name: `Average EHS Labor Cost`,
         editable: false,
         rule: {
            topText: `Cost of Direct Labor`,
            bottomText: `Total Number of Direct FTEs`
         },
         isRuleDiv: true,
      },
      {
         name: `Average Warehouse & Material Handling Labor Cost`,
         editable: false,
         rule: {
            topText: `Cost of Direct Labor`,
            bottomText: `Total Number of Direct FTEs`
         },
         isRuleDiv: true,
      },
      {
         name: `Cost of Conversion incl. Waste (Excluding Depreciation)`,
         editable: false,
         rule: {
            topText: `Cost of Direct Labor + Cost of Manufacturing Overhead + Total Material Waste Cost - Equipment Depreciation`,
            bottomText: `Total Number of Direct FTEs`
         },
         isRuleDiv: false,
      },


   ]);
   // Function to handle fill sextion
   const HandleFillSec = (val) => {
      if (isFillPopup === val) {
         setIsFillPopup(null);
      } else {
         setIsFillPopup(val);
      }
   }
   const i = () => { }
   const a = () => { }
   const b = () => { }
   const x = () => { }
   const indirectList = quesDataList;
   const segments = window.location.pathname.slice(1).split("/");
   // console.log(segments);
   const slug = segments[2];
   const sid = segments[3];
   const ssid = segments[4];
   const groupid = (localStorage.getItem("group_id"));
   const user = JSON.parse(localStorage.getItem("userData"));
   const usid = user ? user.uid : 0;
   const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
   const [tmpAssignUser, setTmpAssignUser] = useState("");
   const [assignedUsers, setAssignedUsers] = useState({
      inviteData: [],
   });
   const [cate, setCate] = useState();
   const [secname, setSecName] = useState();
   const [hashvalue, setHashvalue] = useState(window.location.hash);
   const [isUnlockPopup, setIsUnlockPopup] = useState(false);
   const invitedmeblist = quesData.sectioninvitedmeber;
   const [delPop, setDelPop] = useState(false);
   const [isEditComment, setIsEditComment] = useState(null);
   const [tmpCmntData, setTmpCmntData] = useState({});
   const [txtCmntInput, setTxtCmntInput] = useState('');
   const [isCommentCicked, setIsCommentCicked] = useState(false);
   const [comment, setComment] = useState([]);
   const [sendquestion, setSendquestion] = useState(1);
   const [isQuesType, setIsQuesType] = useState(1);
   let s1 = [];
   let s1name = [];
   let s1AavatarColor = [];
   // invite member listing
   invitedmeblist.map((item, index) => {
      if (item.invited_sub_category == quesData.subsection.id) {
         if (item.name != null) {
            s1.push(`${item.name[0]}${item.last_name[0]}`);
            s1name.push(`${item.name} ${item.last_name}`);
         } else {
            s1.push(`${item.email[0]}`);
            s1name.push(`${item.email}`);
         }
         s1AavatarColor.push(item.avcolor);
      }

   })
   //Function for handle edit functionality
   const edithandle = (e) => {
      setIsAssignSectionPopup(true);
      setCate(sid);
      setSecName(props.pname);
   }
   const directList = bcaselines;
   const answer = quesData.ans;
   const [ans, setans] = useState([]);
   const [rightdata, setRightData] = useState({});
   const[lastlineObj1, SetLastlineObj]= useState([]);
   let ques =lastlineObj1.filter(d=>d.id==186)
   useEffect(() => {
		loadquestions();
	  }, []);
	
	  
	const loadquestions = async () => {
		const result = await serviceProvider.get("/plants/questions",true,{});
		const data = result.data
		 if (data && Array.isArray(data)) {
			const options = data.map(d => ({
			  "id": d.id,
			  "question": d.question,
           "question_description":d.question_description
			}))
			SetLastlineObj(options);
		}
	};

   let lastlineObj = {
      id: Number(directList[directList.length - 1].id) + 1,
      line_name:lastlineObj1?.filter(d=>d.id==186)[0]?.question,
      question_description:lastlineObj1?.filter(d=>d.id==186)[0]?.question_description
   }

   const [messge, setMessge] = useState('');
   const commentmess = (e) => {
      setMessge(e.target.value);
   }
   // Function for submit answers
   const submitAnsformat2 = (e, sigid, ql) => {
      e.preventDefault();
      var uuom = 'FTE';
      if (e.target.value != '') {
         var vv = e.target.value.replace(/\D/g, "");
         document.querySelector('input[name=' + e.target.name + ']').value = Number(vv).toLocaleString('en-US') + ' ' + uuom;
      }


      let tempObj = {};
      let temArray1 = [];
      indirectList.map((item, index) => {
         let name = item.id == 161 ? 'ques47' : `ques` + item.id;
         e.preventDefault()
         const f1 = document.getElementById("f1");
         const data = new FormData(f1).getAll(name);
         tempObj = { ...tempObj, [name]: data }

      })
      setAllqueobj(tempObj);

   }

   const commentsub = (e) => {
      serviceProvider
         .post(
            "/businesscaseplantloss?type=savecomment",
            JSON.stringify({ uid: usid, pid: quesData.pid, qid: rightdata?.qid, message: messge }),
            true
         )
         .then((response) => {


         });

      setMessge('');

   }
   // Function for final submit
   const Finalsubmit = (e) => {
      serviceProvider
         .post(
            "/businesscaseplantloss?type=lockanswer",
            JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, allqueobj }),
            true
         )
         .then((response) => {

            location.reload();

         });
   }
   // Function for handle right section
   const HandleRightSec = async (e, val, type, item = 0) => {
      e.preventDefault();
      setComment([]);
      const apiUrl = `/businesscaseplantloss?param=commentshow&qid=${item?.id}&uid=${quesData.uid}&pid=${quesData.pid}`;
      const { data } = await serviceProvider.get(apiUrl, true, {});
      if (!isEmptyObject(data)) {
         let tempDataArr = []
         data.forEach((item) => {
            let fullDateCreate = new Date(item.datecreated);
            let fullDateEdit = new Date(item.dateupdated);
            let tmpObjArrD = {
               id: item.id,
               uid: item.user_id,
               cirName: item.usersn,
               comment: item.comment,
               editedDate: `${fullDateEdit.getDate()}/${fullDateEdit.getMonth()}/${fullDateEdit.getFullYear().toString().substr(-2)}`,
               editedTime: fullDateEdit.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
               commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth()}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
               commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
               isEdited: item.is_edited,
            }
            tempDataArr.push(tmpObjArrD);
         })

         setComment(tempDataArr);
      }
      setIsQuesType(type);

      if (isRightPopup === val) {
         setIsRightPopup(null);
      } else {
         if (item?.question) {
            setRightData({ name: item?.question, des: item?.question_description, qid: item?.id })
            setIsRightPopup(val);
            setSendquestion(item?.question);

         } else {
            setRightData({ name: item?.line_name, des: item?.question_description, qid: item?.id })
            setIsRightPopup(val);
            setSendquestion(item?.line_name);

         }


      }
   }
   // Function to change value
   const changevalusd = (e) => {

      let val = e.target.value;
      let p = val.replace(/\D/g, "");
      e.target.value = p;


   }
   // Function for right section close
   const HandleRightSecClose = (e, val) => {
      e.preventDefault();
      setIsRightPopup(null);
   }
   // Function for delete button
   const HandleDelBtnEvent = (e, item) => {
      e.preventDefault();
      e.stopPropagation();
      setDelItem(item);
      setIsRemovePopup(true);
   }

   let [tmArr, settmArr] = useState([]);
   let [totalval, setTotalVal] = useState(0);
   let [tmArrdir, settmArrDirect] = useState([]);
   let [totalvaldir, setTotalValDirect] = useState(0);
   let [totalvallinesd, setTotalvallinesd] = useState('');

   const finalTotal = totalval + totalvallinesd;
   //Function for FTE calculation
   const fteCalulateAdd = (index, value) => {

      let ninp = value !== "" ? parseInt(value) : parseInt(0);
      let inpv = parseInt(ninp);

      let cind = index;

      let temArr = [];

      if (cind > -1) {

         let tmpArraS = [...tmArr];
         tmpArraS[cind - 2] = inpv;
         var total = tmpArraS.reduce((a, b) => { return a + b }, 0);
         settmArr(tmpArraS);
         setTotalVal(total);
      } else {
         temArr.push(...tmArr, inpv);

      }
   }
   // Function declaration for FTE
   const fteDirect = (index, value) => {
      let ninp = value !== "" ? parseInt(value) : parseInt(0);
      let inpv = parseInt(ninp);

      let cind = index;

      let temArr = [];

      if (cind > -1) {

         let tmpArraS = [...tmArrdir];
         tmpArraS[cind] = inpv;
         var total = tmpArraS.reduce((a, b) => { return a + b }, 0);
         settmArrDirect(tmpArraS);


         setTotalValDirect(total);
      } else {
         temArr.push(...tmArr, inpv);

      }
   }

   const onKeyVal = (e) => {
      var ch = String.fromCharCode(e.which);
      if (!(/[0-9]/.test(ch))) {
         e.preventDefault();
      }
   }
   const [allqueobj, setAllqueobj] = useState({});
   // Function for submit answer
   const submitAns = (e, ind, type) => {
      e.preventDefault();
      serviceProvider
         .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
         .then((res) => {

         })


      let tempObj = {};
      let temArray1 = [];
      indirectList.map((item, index) => {

         if (type == "indirect") {
            if (ind == index) {
               let fval = e.target.value;
               fteCalulateAdd(index, fval);
            }
         }
         let name = item.id == 161 ? 'ques47' : `ques` + item.id;
         e.preventDefault()
         const f1 = document.getElementById("f1");
         const data = new FormData(f1).getAll(name);
         tempObj = { ...tempObj, [name]: data }

      })
      setAllqueobj(tempObj);

      directList.map((item, index1) => {

         if (type == "direct") {
            if (ind == index1) {
               let fval1 = e.target.value;
               fteDirect(index1, fval1);
            }
         }
      })

   }

   useEffect(() => {

      var modal = document.getElementById("myModal");

      var img = document.getElementById("myImg");
      var modalImg = document.getElementById("img01");
      var captionText = document.getElementById("caption");

      // When the user scroll on div, open the shadow
      $('.fte-breakdown-mid').scroll(
         () => {
            var scroll = $('.fte-breakdown-mid').scrollTop();
            if (scroll > 0) {
               $(".outside_card").addClass("active");
            }
            else {
               $(".outside_card").removeClass("active");
            }
         }
      );

      $('.listree1  input').on('click', function () {
         $('.listree1').addClass('change');
      })


      function showParents() {
         $(".color-changing").removeClass("xyz");
         var len = $("input.selected")
            .parents(".color-changing")
            .addClass("xyz")
            .length;
      }
      $(" input").click(function () {
         $(this).toggleClass("selected");
         showParents();
      });


      $(function () {
         $('.tage1 input,textarea').focus(function () {
            $(this).data('placeholder', $(this).attr('placeholder'))
               .attr('placeholder', '');
         }).blur(function () {
            $(this).attr('placeholder', $(this).data('placeholder'));
         });
      });

      let tempval = [];
      answer.map((item, index) => {


         if (item.length != 0) {
            tempval.push(JSON.parse(item.answer));
         }


      })

      if (tempval.length > 0) {
         setans(tempval);



         tempval.map((item, index) => {

            if (index == 8) {

               let tmArr2 = [];
               item.map((item, index) => {
                  if (item !== "") {
                     if (isNaN(parseInt(item))) {
                        tmArr2.push(0)
                     } else {
                        tmArr2.push(parseInt(item))
                     }

                  }
               })
               var totalFte = tmArr2.reduce((a, b = 0) => { return a + b }, 0);


               setTotalValDirect(totalFte);
               settmArrDirect(tmArr2);

            }
            else {
               var vv = tempval.slice(2);
               let newar = vv.slice(0, 6);

               let tmArr1 = [];
               newar.map((item, index) => {
                  if (item !== "") {
                     if (isNaN(parseInt(item))) {
                        tmArr1.push(0)
                     } else {
                        tmArr1.push(parseInt(item))
                     }


                  }

               })
               var total = tmArr1.reduce((a, b = 0) => { return a + b }, 0);


               setTotalVal(total);
               settmArr(tmArr1);
            }
         })
      }
      document.addEventListener("click", (e) => {
         if (!e.target.closest('.conected_to_popup')) {
            setShown(false);
         }
      });
      return async () => {
         try {
            if (Array.isArray(props.quesResult) && props.quesResult.length) {

               const { uid, pid: plant_id, has_sub_section, subsection, section } = props.quesResult[0];
               let data = { uid, section_id: section['id'], type: 2, status: 0, plant_id }

               if (section['has_sub_section'] === "1") {
                  data['sub_section_id'] = subsection['id'];
               }
               else {
                  data['sub_section_id'] = 0
               }
               await serviceProvider.post('/sectionlock', data, true);
            }
         } catch (err) {
            console.error(err)
         }
      }
   }, [])

   useEffect(() => {


      if (!isEmptyObject(allqueobj)) {

         var nm = "";
         var lines = {};
         var valP = "";
         var iaval1 = 0;
         var iaval = 0;
         $(".alllines").each(function () {
            nm = $(this).attr("name");
            var arrnm = nm.split("-");
            valP = $(this).val();
            var vv = valP.replace(/\D/g, "");
            lines[arrnm[1]] = vv;
            iaval1 = iaval1 + parseInt(vv ? vv : 0);
         });
         setTotalvallinesd(iaval1);
		 if(totalval > 0 && totalvallinesd > 0){
		  serviceProvider
                .post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:100 }),
                    true
                )
                .then((response) => {
                });
		  }else{
			   serviceProvider
                .post(
                    "/businesscaseplantloss?type=savecolor",
                    JSON.stringify({ pid: quesData.pid, sid: sid, ssid: ssid,color:50 }),
                    true
                )
                .then((response) => {
                });
			  
			  
			  }
         serviceProvider
            .post(
               "/businesscaseplantloss?type=saveuseranswer",
               JSON.stringify({ slug: slug, uid: usid, pid: quesData.pid, sid: sid, ssid: ssid, from: 'fte', lines: lines, allqueobj })
            )
            .then((response) => {
					serviceProvider
            .get(`/businesscaseplantloss?param=questionaire&slug=${slug}&uid=${usid}&sid=${sid}&ssid=${ssid}`, true, {})
            .then((res) => {

            })
            });
         
      }
   }, [allqueobj])

   // Function for handle Note popup
   const HandleNotePop = () => {
      let temp = document.querySelector('.la-card-wrapper');
      if (temp.classList.contains('active')) {
         temp.classList.remove('active');
      }
      else {
         temp.classList.add('active');
      }
   }
   // Function to remove Note Popup
   const removeNotePop = () => {
      let tempr = document.querySelector('.la-card-wrapper');
      tempr.classList.remove('active');
      if (window.innerWidth < 1201) {
         tempr.classList.add('active');
      }
   }
   //Delete popup 
   const ShowDelPopup = (data, ind) => {
      setTmpCmntData({
         ...data, index: ind
      })
      setDelPop(!delPop);
   }
   //functionality for delete start 
   const deletData = () => {
      const tmpArr = comment.filter((item, index) => item.id !== tmpCmntData.id);
      serviceProvider
         .post(
            "/businesscaseplantloss?type=deletecomment",
            JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, qid: rightdata?.qid }), true
         )
         .then((response) => {
            //  console.log(response);

         });

      setComment(tmpArr);
      setDelPop(false);
      setTmpCmntData({});
   }
   //end
   //functionality for edit start
   const editComntData = (data, ind) => {
      setTmpCmntData({
         ...data, index: ind
      });
      let tmpTextArea = document.getElementById(`comment-editTxtarea-${data.id}`);
      tmpTextArea.value = data.comment;
      setIsEditComment(data.id);

   }
   // end
   //Handle Comment textarea on change
   const handleTextAreaOnChange = (e) => {
      let val = e.target.value;
      setTxtCmntInput(val);
   }
   // Functionality for save submit data
   const saveCmntData = () => {
      let tmpTextArea = document.getElementById('addComment');
      let tmpCmntArr = [...comment];
      let fullDateCreate = new Date();
      // console.log(fullDateCreate.getMonth()+1);
      let tmpObj = {
         id: comment.length + 1,
         uid: usid,
         cirName: user.sn,
         comment: txtCmntInput,
         editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
         editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
         commentedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
         commentedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
         isEdited: 0

      }
      serviceProvider
         .post(
            "/businesscaseplantloss?type=savecomment",
            JSON.stringify({ uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: txtCmntInput }),
            true
         )
         .then((response) => {
            //   console.log(response);

         });
      tmpCmntArr.unshift(tmpObj);
      setComment(tmpCmntArr);
      setTxtCmntInput('');
      tmpTextArea.style.height = 30 + 'px';
      setIsCommentCicked(false);
   }
   // Save Edit Data Functionality
   const saveEditData = (index) => {
      let tmpcmntArr = [...comment];
      let tmpTextArea = document.getElementById(`comment-editTxtarea-${tmpCmntData.id}`);
      let fullDateCreate = new Date();
      let tmpObj = {
         ...tmpCmntData,
         comment: tmpTextArea.value,
         editedDate: `${fullDateCreate.getDate()}/${fullDateCreate.getMonth() + 1}/${fullDateCreate.getFullYear().toString().substr(-2)}`,
         editedTime: fullDateCreate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
         isEdited: 1
      };
      serviceProvider
         .post(
            "/businesscaseplantloss?type=saveeditcomment",
            JSON.stringify({ id: tmpCmntData.id, uid: usid, pid: quesData.pid, cirName: user.sn, qid: rightdata?.qid, message: tmpTextArea.value }),
            true
         )
         .then((response) => {
            //   console.log(response);

         });

      tmpcmntArr[index] = tmpObj;
      setComment(tmpcmntArr);
      setIsEditComment('');
      // console.log(tmpcmntArr);
   }
   // Save Edit Data Functionality End
   // Popup Functionality End 
   useEffect(() => {
      setTotalvallinesd(quesData.linestotalVAl)

      let resizeTextArea = document.querySelectorAll('.resize-cmnt-area');
      resizeTextArea.forEach((item) => {
         item.addEventListener('input', () => {
            item.style.height = 'auto';
            let itemHeight = item.scrollHeight;
            if (itemHeight > 30) {
               item.style.height = itemHeight + 'px';
            }
            if (itemHeight > 136) {
               item.classList.remove('txtScrollHide');
            }
            else {
               item.classList.add('txtScrollHide');
            }

         })
      })
   }, [])

   React.useEffect(() => {
      if (hashvalue && hashvalue != null) {
         var abc = hashvalue.replace("#", "");
         var myArray = abc.split("-");
         $('body').find('#idfc' + myArray[0]).click();
         setIsLossSupport(false)
      }
      if (Array.isArray(invitedmeblist)) {
         // setinvite-data
         let tmpArr = { ...assignedUsers };
         let tmpInviteData = invitedmeblist.filter((item) => item.invited_sub_category == quesData.subsection.id);
         tmpArr['inviteData'] = tmpInviteData;
         setAssignedUsers(tmpArr);
      }
   }, [])
   return (
      <>
         {/* Breadcrumbs */}
         <BreadcrumbsItem to="/home">Home</BreadcrumbsItem>
            <BreadcrumbsItem to="/">{quesData.plant_name}</BreadcrumbsItem>
         <BreadcrumbsItem to={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>IR4.0 Loss Analysis</BreadcrumbsItem>
         <BreadcrumbsItem to="#">FTE Breakdown</BreadcrumbsItem>
         {/* Css For styling */}
         <Helmet>
            <link rel="stylesheet" href={require('./fte-breakdown-page.scss').default} />
         </Helmet>
         {/* FTE Breakdown Main wrapper which contains four section 
            fte-breakdown-top
            fte-breakdown-top-two
            fte-breakdown-mid
            fte-breakdown-bottom
         */}
         <section className="fte-breakdown prod-3">
            {/* Top section has also three sections 
                First section has page title
                Second section has assigned users list and popup
                Third section has unlock button and popup
            */}
            <div className="fte-breakdown-top">
               <div className="fte-breakdown-row">
                  <div className="fte-breakdown-lft-col">
                     <h1 className="h1-btn">
                        {/* <a className="back-link" href={groupid == 14 ? `/lossanalysis/section/${slug}` : `/loss-analysis-admin/section/${slug}`}>
                           <svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d)"><rect x={2} y={2} width={24} height={24} rx={2} fill="white" /></g><path d="M19 7.4303L17.4833 6L9 14L17.4833 22L19 20.5697L12.0334 14L19 7.4303Z" fill="#7D868C" /><defs><filter id="filter0_d" x={0} y={0} width={28} height={28} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity={0} result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset /><feGaussianBlur stdDeviation={1} /><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" /><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" /><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" /></filter></defs></svg>
                        </a> */}
                        FTE Breakdown
                     </h1>
                     <h4 className="h4-dull">
                        <span className="text">Finance Data</span>
                        <span className="mid-border"></span>
                        <div className="text total">{`${year} Actual`}</div>
                     </h4>
                     <div className="value_of_progress" style={{ display: "none" }}>
                        <p>Progress:</p>
                        <div className="bar-wrapper">
                           <span>{progress}%</span>
                           <div className="progress">
                              <div className="progress-bar" role="progressbar" style={{ width: progress }} aria-valuenow={progress} aria-valuemin={0} aria-valuemax={100} />
                           </div>
                        </div>
                     </div>
                  </div>



                  <div style={{/* display: groupid == 14 ? 'none' : '' */}}
                     className="fte-breakdown-mid-col">
                     <div className="Assign">
                        <InviteUserList
                            data={assignedUsers.inviteData}
                            setData={setAssignedUsers}
                            handlePopup={edithandle}
							open={isAssignSectionPopup}
                            isEditAllow={groupid != 14 ? true : false}
                            catagory={sid}
                            subcatagory={quesData.subsection.id}
                            pid={pid}
                            uid={quesData.uid}
                            sectionName={quesData.subsection.sub_section_name}
                            plantName={quesData.plant_name}
							 {...props}
                        />
                        <div style={{ visibility: 'hidden' }} className="direction_arrows">
                           <div className="upward">
                              <img src={require("../../assets/img/fte-breakdown/upward.svg").default} alt="fte-breakdown" />
                              <p>Upload Excel file</p>
                           </div>
                           <div className="downword">
                              <img src={require("../../assets/img/fte-breakdown/downword.svg").default} alt="fte-breakdown" />
                              <p>Download to Excel</p>
                           </div>
                        </div>
                     </div>
                  </div>

                  {/* la-ques-mid-col end */}
                  <div className="fte-rgt-col" style={{ position: 'relative' }}>
                     <div className={`la-card-wrapper active`}>
                        <button className="image-wrap" onClick={() => HandleNotePop()}>
                           <img src={require('../../assets/img/fte-breakdown/bulb.svg').default} alt="Bulb" />
                        </button>
                        <div className="content-box">
                           <p className="content">{quesData.subsection.description}</p>
                           <button to="/" className="close-btn"><img src={require('../../assets/img/fte-breakdown/close.svg').default} alt="close" onClick={() => removeNotePop()} /></button>
                        </div>
                     </div>
                     {islock == 1 &&
                        <>
                           {/* scnv-2126 start */}
                           <div className="right-btn-wrap">
                              <div className='scnv2126-btn-wrapper'>
                                 <div>
                                    <div className="submitted-scnv-2126">
                                       <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: dataSubmitsvg }}></span>
                                       <span>Data Submitted</span>
                                    </div>
                                 </div>
                                 <div style={{ display: (groupid == 2 || groupid == 11) && quesData.lockall == 0 ? '' : 'none' }}>
                                    <button className="submitted-scnv-2126 btn-unclock" onClick={() => setIsUnlockPopup(true)}>
                                       <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: lockSvg }}></span>
                                       <span>Unlock Section</span>
                                    </button>
                                 </div>
                              </div>
                           </div>
                           {/* scnv-2126 end */}
                        </>
                     }
                     <div className="submit-page">
                        {islock == 0 &&
                           <div className="prod-4"><button disabled={parseInt(islock) > 0 ? true : false} onClick={(e) => { if (totalval > 0 && totalvallinesd > 0) {  if(openfinvalidation == false){ setIsSubmitPopup(true); setValidationPopup(false) }else{ setIsSubmitPopup(false); setValidationPopup(true) } } }} className={`btn-link submit active ${islock > 0 ? 'disabled' : ''} ${totalval > 0 && totalvallinesd > 0 ? '' : `disabled`}`}>Submit Data</button></div>
                        }
                     </div>
                  </div>
                  {/* la-ques-right-col end */}
               </div>
            </div>
            {/* This sections has two subsections and it is fixed when scroll
               left section has heading
               right section has year
            */}
            {/* <div className="fte-breakdown-top-two">
               <div className="outside_card">
                  <div className="work_space">
                     <div className="what_percentage">
                        <div className="total">Plant Data:</div>
                     </div>
                     <div className="what_percentage actual-position">
                        <div className="total">{`${year} Actual`}</div>
                     </div>
                  </div>
                  <div className="empty" />
               </div>
            </div> */}
            {/* In this section there are two lists
                Main list
                Sublist work as dropdown
            */}
            <div className="fte-breakdown-mid prod-2">
               <form id={"f1"}>
                  <div className="white_space prod-1">
                     <div className="inside_cards">
                        <div class="listree">
                           <ul>
                              <li>
                                 <div className="listree-menu-heading ">
                                    <div className="work-type listing-input">
                                       <div className={`total-number`}>
                                          <div className={`info ${isRightPopup === (quesDataList[0].id) && 'active'}`} id={`idfc${quesDataList[0].id}`} onClick={(e) => HandleRightSec(e, quesDataList[0].id, 1, quesDataList[0])}>
                                             <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                             </svg>
                                          </div>
                                          <p onClick={(e) => HandleRightSec(e, quesDataList[0].id, 1, quesDataList[0])}>{quesDataList[0].question}</p>
                                       </div>
                                       <div className="input">
                                          <input type="text" name={'ques-' + quesDataList[0].id + '[]'} data-qid={quesDataList[0].id} placeholder="FTE" disabled value={Number(finalTotal).toLocaleString('en-US') + ' FTE'} />
                                       </div>
                                    </div>
                                 </div>
                                 <ul className="listree-submenu">
                                    <li className="submenu">
                                       <div className="listree-submenu-heading color-changing listing-input ">
                                          <div className="work-type after">
                                             <div className={`total-number listing-input`}>
                                                <div className={`info ${isRightPopup === (quesDataList[1]?.id) && 'active'}`} id={`idfc${quesDataList[1].id}`} onClick={(e) => HandleRightSec(e, quesDataList[1]?.id, 1, quesDataList[1])}>
                                                   <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                   </svg>
                                                </div>
                                                <p onClick={(e) => HandleRightSec(e, quesDataList[1]?.id, 1, quesDataList[1])}>{quesDataList[1].question}</p>
                                                <img className={dropScreen ? `active` : null} onClick={(e) => { togglesection(e) }} src={require("../../assets/img/fte-breakdown/arrow-down.svg").default} alt="fte-breakdown" />
                                             </div>
                                             <div className="input">
                                                <input type="text" name={'ques' + quesDataList[1].id} value={Number(totalval).toLocaleString('en-US') + ' FTE'} data-qid={quesDataList[1].id} placeholder="FTE" disabled />
                                             </div>
                                          </div>
                                          <div className={dropScreen ? `active` : null}>
                                             <ul className="listree-submenu-items hide" >
                                                {indirectList.filter(i => i.id > 162).map((items, i) => {
                                                   return (

                                                      <li className="work-type listing-input">
                                                         <div title={items.question} className={`side-slid-icon-button`}>
                                                            <div className={`info ${isRightPopup === (items.id) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, 1, items)}>
                                                               <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                               </svg>
                                                            </div>
                                                            <p onClick={(e) => HandleRightSec(e, items.id, 1, items)}>{items.question}</p>
                                                         </div>
                                                         <div className="input">
                                                            <input type="text" disabled={parseInt(islock) > 0 ? true : false} name={`ques` + indirectList[i + 2].id} data-qid={items.id} defaultValue={ans.length > 0 ? Number(ans[i + 2]).toLocaleString('en-US') + ' FTE' : ""} placeholder="FTE" onKeyPress={(e) => { onKeyVal(e) }} onBlur={(e) => { submitAns(e, i + 2, 'indirect'), submitAnsformat2(e) }} onFocus={(e) => { changevalusd(e) }} onChange={(e) => { submitAns(e, i + 2, 'indirect') }} />

                                                         </div>
                                                      </li>
                                                   )
                                                }
                                                )
                                                }
                                             </ul></div>
                                       </div>
                                    </li>
                                    <li className="submenu">
                                       <div className="listree-submenu-heading color-changing ">
                                          <div className="work-type listing-input after">
                                             <div className={`total-number`}>
                                                <div className={`info ${isRightPopup === quesDataList[8].id && 'active'}`} id={`idfc${quesDataList[8].id}`} onClick={(e) => HandleRightSec(e, quesDataList[8].id, 1, quesDataList[8])}>
                                                   <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                   </svg>
                                                </div>
                                                <p onClick={(e) => HandleRightSec(e, quesDataList[8].id, 1, quesDataList[8])}>{quesDataList[8].question}</p>
                                                <img className={dropScreenSec ? `active` : null} onClick={(e) => { toggleClass(e) }} src={require("../../assets/img/fte-breakdown/arrow-down.svg").default} alt="fte-breakdown" />
                                             </div>
                                             <div className="input">
                                                <input type="text" name="percentage" placeholder="FTE" disabled value={Number(totalvallinesd).toLocaleString('en-US') + ' FTE'} />
                                             </div>
                                          </div>
                                          <div className={dropScreenSec ? `active` : null} >
                                             <ul className="listree-submenu-items hide">
                                                {!isEmptyObject(answer) && directList.map((items, x) => {


                                                   return (

                                                      <li className="work-type listing-input">
                                                         <div title={items.line_name} className={`side-slid-icon-button`}>
                                                            <div className={`info ${isRightPopup === (items.id) && 'active'}`} id={`idfc${items.id}`} onClick={(e) => HandleRightSec(e, items.id, 3, items)}>
                                                               <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                               </svg>
                                                            </div>
                                                            <p onClick={(e) => HandleRightSec(e, items.id, 3, items)}>{items.line_name}</p>
                                                         </div>
                                                         <div className="input">
                                                            <input type="text" disabled={parseInt(islock) > 0 ? true : false} className="alllines" name={`lnis-` + items.id} data-qid={items.id} defaultValue={linesans != 0 ? linesans[x].answer + ' FTE' : ""} placeholder="FTE" onKeyPress={(e) => { onKeyVal(e) }} onFocus={(e) => { changevalusd(e) }} onBlur={(e) => { submitAnsformat2(e) }} onChange={(e) => { submitAns(e, x, 'direct') }} />
                                                         </div>
                                                      </li>

                                                   )
                                                }



                                                )
                                                }
                                                <li className="work-type listing-input 1">
                                                   <div title={lastlineObj.line_name} className={"side-slid-icon-button"}>
                                                      <div className={`info ${isRightPopup === (lastlineObj.id) && 'active'}`} id={`idfc${lastlineObj.id}`} onClick={(e) => HandleRightSec(e, lastlineObj.id, 3, lastlineObj)}>
                                                         <svg width="{16}" height="{16}" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 0C3.6 0 0 3.6 0 8.00001C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8.00001C16 3.6 12.4 0 8 0ZM8.8 12H7.2V7.20001H8.8V12ZM8.8 5.60001H7.2V4H8.8V5.60001Z" fill="#CBCFD2" />
                                                         </svg>
                                                      </div>
                                                      <p onClick={(e) => HandleRightSec(e, lastlineObj.id, 3, lastlineObj)}>{lastlineObj?.line_name}</p>
                                                   </div>
                                                   <div className="input">
                                                      <input disabled={parseInt(islock) > 0 ? true : false} type="text" className="alllines" id={`lnis-` + lastlineObj.id} name={`lnis-` + lastlineObj.id} defaultValue={linesans != 0 && linesans[linesans.length - 1].answer != undefined ? linesans[linesans.length - 1].answer + ' FTE' : ""} onBlur={(e) => { submitAnsformat2(e) }} onChange={(e) => { submitAns(e) }} onFocus={(e) => { changevalusd(e) }} placeholder="FTE" />
                                                   </div>
                                                </li>

                                             </ul></div>
                                       </div>
                                    </li>
                                 </ul>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
            {/* This section has submit button */}
            {/* <div className="fte-breakdown-bottom" >
               <div className="submit-page">
                  {islock == 0 &&
                     <div className="prod-4"><button disabled={parseInt(islock) > 0 ? true : false} onClick={(e) => { if (totalval > 0 && totalvallinesd > 0) { setIsSubmitPopup(true); setValidationPopup(false) } }} className={`btn-link submit active ${islock > 0 ? 'disabled' : ''} ${totalval > 0 && totalvallinesd > 0 ? '' : `disabled`}`}>Submit</button></div>
                  }
               </div>
            </div> */}
            {/* submit popup starts here */}
            <div className={`submit-pop-wrap ${isSubmitPopup && 'active'}`}>
               <div className="popup-wrapper submit-popup">
                  {/* Close button to close the popup */}
                  <div className="close-btn-wrap">
                     <button className="close-btn" onClick={() => { setIsSubmitPopup(false) }}>
                        <img src={require("../../assets/img/la-questionnaires/close-pop.svg").default} alt="Close" className="close-img" />
                     </button>
                  </div>
                  <div className="popup">
                     {/* this section has image */}
                     <div className="img-box">
                        <img src={require("../../assets/img/la-questionnaires/unsaved.svg").default} alt="Unsaved" className="unsave-img" />
                     </div>
                     {/* This section has text */}
                     <div className="content-box">
                        <p className="text">By submitting the data you will no longer have the opportunity for further editing.  </p>
                        <p className="text">Are you sure you want to proceed?</p>
                     </div>
                     {/* This section has buttons */}
                     <div className="button-wrapper">
                        <button type="submit" className="btn cancel-button primary" onClick={() => { setIsSubmitPopup(false) }}>Cancel</button>
                        <button onClick={(e) => { setIsSubmitPopup(false); setValidationPopup(true) }} type="submit" className="btn submit-btn secondary">Submit</button>
                     </div>
                  </div>
               </div>
            </div>
            {/* Right Sidebar Popup starts here */}
            <div className={`right-sidebar ${isRightPopup && 'active'}`}>
               {/* Right sidebar popup which will on click of info icon and text */}
               {isRightPopup != 0 && (<LossSupportView
                  popupHeadTxt={rightdata.name}
                  des={rightdata.des}
                  isLossSupport={isLossSupport}
                  HandleRightSecClose={HandleRightSecClose}
                  close={setIsLossSupport}
                  isTicket={isTicket}
                  setIsTicket={setIsTicket}
                  pid={pid}
                  sid={108}
                  ssid={8}
                  qid={isRightPopup}
                  uid={quesData.uid}
                  type={isQuesType}
                  question={sendquestion}
                  islock={islock}
				  isAvailable={false}
                  {...props}
               />)}
            </div>
         </section>
         {/* Assign popup call for assign user */}
         <AssignSectionPopup
            assignedUsers={assignedUsers}
            setAssignedUsers={setAssignedUsers}
            catagory={cate}
            subcatagory={8}
            pid={pid}
            sectionName={quesData.subsection.sub_section_name}
            plantName={quesData.plant_name}
            tmpAssignUser={'inviteData'}
            open={isAssignSectionPopup}
            setOpen={setIsAssignSectionPopup}
            {...props}
         />
         {/* Validation popup call conditionally */}
         {validationpopup &&
            <FinancialValidationPageController pid={pid} setOpenFinValidation={setOpenFinValidation} setValidationPopup={setValidationPopup} units={currency} sid={108} ssid={8} Finalsubmit={Finalsubmit} {...props} />
         }
         {/* Fte Breakdown Page Wlakthrough */}
         {activeSlide && quesData.ftetour != 1 &&
            <FTEWalkthroughQuesView activeSlide={activeSlide} setActiveSlide={setActiveSlide}  {...props} />
         }
         {/* scnv-2126 */}
         <ConfirmationUnlockPopup isOpen={isUnlockPopup} setOpen={setIsUnlockPopup} setUnlockbutton={props.setUnlockbutton} pid={pid} sid={108} ssid={8} />
         {/* How to use popup */}
         <div className="how-to-use-ctrl-wrap">
            <HowToUseCtrl sectionName={`FTE Breakdown`}/>
         </div>
      </>
   );
};

// svg images define here
let dataSubmitsvg = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11" cy="11" r="10.5" fill="#CAE4CB" stroke="#43A047"/>
<path d="M8.83863 14.0039L5.83524 11.0005L4.8125 12.0161L8.83863 16.0422L17.4815 7.39932L16.466 6.38379L8.83863 14.0039Z" fill="#43A047"/>
</svg>`

const lockSvg = `<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.7142 7.16667H12.7618V5.2619C12.7618 2.63333 10.6285 0.5 7.99991 0.5C5.37134 0.5 3.238 2.63333 3.238 5.2619V7.16667H2.28562C1.238 7.16667 0.380859 8.02381 0.380859 9.07143V18.5952C0.380859 19.6429 1.238 20.5 2.28562 20.5H13.7142C14.7618 20.5 15.619 19.6429 15.619 18.5952V9.07143C15.619 8.02381 14.7618 7.16667 13.7142 7.16667ZM7.99991 15.7381C6.95229 15.7381 6.09515 14.881 6.09515 13.8333C6.09515 12.7857 6.95229 11.9286 7.99991 11.9286C9.04753 11.9286 9.90467 12.7857 9.90467 13.8333C9.90467 14.881 9.04753 15.7381 7.99991 15.7381ZM10.9523 7.16667H5.04753V5.2619C5.04753 3.63333 6.37134 2.30952 7.99991 2.30952C9.62848 2.30952 10.9523 3.63333 10.9523 5.2619V7.16667Z" fill="#118ACB"/>
</svg>
`
// Function to show/hide confirmation unlock popup
const ConfirmationUnlockPopup = (props) => {
   const finalUnlock = (id) => {
      props.setOpen(false);
      serviceProvider
         .post(
            "/businesscaseplantloss?type=unlocksection",
            JSON.stringify({ pid: props.pid, sid: props.sid, ssid: props.ssid }),
            true
         )
         .then((response) => {
            location.reload();
         });
   }
   return (
      <>
         {/* Unlock popup wrapper starts here */}
         <div className='unlock-popup-wrapper2126' style={{ visibility: props.isOpen ? '' : 'hidden' }}>
            <div className='popup-content2126'>
               {/* this section has text */}
               <div className='body-popup2126'>
                  <p>Are you sure, you would like to unlock the section?</p>
               </div>
               {/* this section has buttons */}
               <div className='popup-btn-wrapper2126'>
                  <button className='p-2126-btn cancel' onClick={() => props.setOpen(false)}>Cancel</button>
                  <button className='p-2126-btn yes' onClick={() => { finalUnlock(props.ssid) }}>Yes, unlock</button>
               </div>
            </div>
         </div>
      </>

   )
}
// scnv-2126