/** imported components

	* $ from jquery ===> component from jquery to use jquery for the page.

**/

import React from "react";
import $ from "jquery";
// yesno answer component start
export const YesNo = (props) => {
	const quesDataItems = props.quesData;
	if (!quesDataItems.length) {
		return null;
	}
	const quesDataItem = props.quesData[0];
	const changeValue = (e) => {
		props.onErrorMsg("");
		props.onErrorState(0);
		$(".optselyn").removeClass("activeY");
		$(e.target).addClass("activeY");
	};
	const checklist = quesDataItem.answers.map((item, index) => {
		var active = "";
		if (quesDataItem.savedans.length) {
			var ansarr = quesDataItem.savedans.split(",");
			if (ansarr.indexOf(item.id) != -1) {
				active = " activeY";
			}
		}
		return (
			<div key={index} style={{pointerEvents:quesDataItem.is_datasubmitted==1?'none':''}}>
				<button
					className={`optselyn${active} btn-${item.option_name.toLowerCase()} opt-${item.id}`}
					data-val={item.id}
					onClick={(e) => changeValue(e)}
				>
					{item.option_name}
				</button>
			</div>
		);
	});
	return (
		<>
			{/* Answer wrapper that is containg yes no answer format. */}
			<div className="yes-no-btn-wrapper">{checklist}</div>
		</>
	);
};
