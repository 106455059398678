export const headIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="153" height="35" viewBox="0 0 153 35" fill="none">
<path d="M2.49965 4.90652C3.82759 4.90652 4.99932 3.7986 4.99932 2.45326C4.99932 1.10793 3.90571 0 2.49965 0C1.1717 0 0 1.10793 0 2.45326C0 3.7986 1.1717 4.90652 2.49965 4.90652Z" fill="#118ACB"/>
<path d="M2.49965 19.7033C3.82759 19.7033 4.99932 18.5954 4.99932 17.2501C4.99932 15.9048 3.90571 14.7969 2.49965 14.7969C1.1717 14.7969 0 15.9048 0 17.2501C0.0781144 18.5954 1.1717 19.7033 2.49965 19.7033Z" fill="#118ACB"/>
<path d="M2.49965 34.5823C3.82759 34.5823 4.99932 33.4744 4.99932 32.129C4.99932 30.7837 3.90571 29.6758 2.49965 29.6758C1.1717 29.6758 0 30.7837 0 32.129C0.0781144 33.4744 1.1717 34.5823 2.49965 34.5823Z" fill="#7FCDBB"/>
<path d="M32.027 4.90652C33.355 4.90652 34.5267 3.7986 34.5267 2.45326C34.5267 1.10793 33.4331 0 32.027 0C30.6991 0 29.5273 1.10793 29.5273 2.45326C29.5273 3.7986 30.6991 4.90652 32.027 4.90652Z" fill="#118ACB"/>
<path d="M32.027 19.7033C33.355 19.7033 34.5267 18.5954 34.5267 17.2501C34.5267 15.9048 33.4331 14.7969 32.027 14.7969C30.6991 14.7969 29.5273 15.9048 29.5273 17.2501C29.6055 18.5954 30.6991 19.7033 32.027 19.7033Z" fill="#7FCDBB"/>
<path d="M32.027 34.5823C33.355 34.5823 34.5267 33.4744 34.5267 32.129C34.5267 30.7837 33.4331 29.6758 32.027 29.6758C30.6991 29.6758 29.5273 30.7837 29.5273 32.129C29.6055 33.4744 30.6991 34.5823 32.027 34.5823Z" fill="#4D4D4F"/>
<path d="M17.2614 4.90652C18.5893 4.90652 19.761 3.7986 19.761 2.45326C19.761 1.10793 18.6674 0 17.2614 0C15.9334 0 14.7617 1.10793 14.7617 2.45326C14.7617 3.7986 15.9334 4.90652 17.2614 4.90652Z" fill="#7FCDBB"/>
<path d="M17.2614 19.7033C18.5893 19.7033 19.761 18.5954 19.761 17.2501C19.761 15.9048 18.6674 14.7969 17.2614 14.7969C15.9334 14.7969 14.7617 15.9048 14.7617 17.2501C14.8398 18.5954 15.9334 19.7033 17.2614 19.7033Z" fill="#4D4D4F"/>
<path d="M17.2614 34.5823C18.5893 34.5823 19.761 33.4744 19.761 32.129C19.761 30.7837 18.6674 29.6758 17.2614 29.6758C15.9334 29.6758 14.7617 30.7837 14.7617 32.129C14.8398 33.4744 15.9334 34.5823 17.2614 34.5823Z" fill="#118ACB"/>
<path d="M61.6325 4.90652C62.9604 4.90652 64.1321 3.7986 64.1321 2.45326C64.1321 1.10793 63.0385 0 61.6325 0C60.3045 0 59.1328 1.10793 59.1328 2.45326C59.1328 3.7986 60.3045 4.90652 61.6325 4.90652Z" fill="#118ACB"/>
<path d="M61.6325 19.7033C62.9604 19.7033 64.1321 18.5954 64.1321 17.2501C64.1321 15.9048 63.0385 14.7969 61.6325 14.7969C60.3045 14.7969 59.1328 15.9048 59.1328 17.2501C59.1328 18.5954 60.3045 19.7033 61.6325 19.7033Z" fill="#4D4D4F"/>
<path d="M61.6325 34.5823C62.9604 34.5823 64.1321 33.4744 64.1321 32.129C64.1321 30.7837 63.0385 29.6758 61.6325 29.6758C60.3045 29.6758 59.1328 30.7837 59.1328 32.129C59.1328 33.4744 60.3045 34.5823 61.6325 34.5823Z" fill="#7FCDBB"/>
<path d="M46.7887 4.90652C48.1167 4.90652 49.2884 3.7986 49.2884 2.45326C49.2884 1.10793 48.1948 0 46.7887 0C45.4608 0 44.2891 1.10793 44.2891 2.45326C44.2891 3.7986 45.4608 4.90652 46.7887 4.90652Z" fill="#4D4D4F"/>
<path d="M46.7887 19.7033C48.1167 19.7033 49.2884 18.5954 49.2884 17.2501C49.2884 15.9048 48.1948 14.7969 46.7887 14.7969C45.4608 14.7969 44.2891 15.9048 44.2891 17.2501C44.3672 18.5954 45.4608 19.7033 46.7887 19.7033Z" fill="#118ACB"/>
<path d="M46.7887 34.5823C48.1167 34.5823 49.2884 33.4744 49.2884 32.129C49.2884 30.7837 48.1948 29.6758 46.7887 29.6758C45.4608 29.6758 44.2891 30.7837 44.2891 32.129C44.3672 33.4744 45.4608 34.5823 46.7887 34.5823Z" fill="#118ACB"/>
<path d="M91.1598 4.90652C92.4877 4.90652 93.6594 3.7986 93.6594 2.45326C93.6594 1.10793 92.5658 0 91.1598 0C89.8319 0 88.6602 1.10793 88.6602 2.45326C88.6602 3.7986 89.8319 4.90652 91.1598 4.90652Z" fill="#118ACB"/>
<path d="M91.1598 19.7033C92.4877 19.7033 93.6594 18.5954 93.6594 17.2501C93.6594 15.9048 92.5658 14.7969 91.1598 14.7969C89.8319 14.7969 88.6602 15.9048 88.6602 17.2501C88.6602 18.5954 89.8319 19.7033 91.1598 19.7033Z" fill="#7FCDBB"/>
<path d="M91.1598 34.5823C92.4877 34.5823 93.6594 33.4744 93.6594 32.129C93.6594 30.7837 92.5658 29.6758 91.1598 29.6758C89.8319 29.6758 88.6602 30.7837 88.6602 32.129C88.6602 33.4744 89.8319 34.5823 91.1598 34.5823Z" fill="#118ACB"/>
<path d="M76.3942 4.90652C77.7221 4.90652 78.8938 3.7986 78.8938 2.45326C78.8938 1.10793 77.8002 0 76.3942 0C75.0663 0 73.8945 1.10793 73.8945 2.45326C73.8945 3.7986 75.0663 4.90652 76.3942 4.90652Z" fill="#7FCDBB"/>
<path d="M76.3942 19.7033C77.7221 19.7033 78.8938 18.5954 78.8938 17.2501C78.8938 15.9048 77.8002 14.7969 76.3942 14.7969C75.0663 14.7969 73.8945 15.9048 73.8945 17.2501C73.8945 18.5954 75.0663 19.7033 76.3942 19.7033Z" fill="#118ACB"/>
<path d="M76.3942 34.5823C77.7221 34.5823 78.8938 33.4744 78.8938 32.129C78.8938 30.7837 77.8002 29.6758 76.3942 29.6758C75.0663 29.6758 73.8945 30.7837 73.8945 32.129C73.8945 33.4744 75.0663 34.5823 76.3942 34.5823Z" fill="#4D4D4F"/>
<path d="M120.687 4.90652C122.015 4.90652 123.187 3.7986 123.187 2.45326C123.187 1.10793 122.093 0 120.687 0C119.359 0 118.188 1.10793 118.188 2.45326C118.188 3.7986 119.359 4.90652 120.687 4.90652Z" fill="#118ACB"/>
<path d="M120.687 19.7033C122.015 19.7033 123.187 18.5954 123.187 17.2501C123.187 15.9048 122.093 14.7969 120.687 14.7969C119.359 14.7969 118.188 15.9048 118.188 17.2501C118.188 18.5954 119.359 19.7033 120.687 19.7033Z" fill="#118ACB"/>
<path d="M120.687 34.5823C122.015 34.5823 123.187 33.4744 123.187 32.129C123.187 30.7837 122.093 29.6758 120.687 29.6758C119.359 29.6758 118.188 30.7837 118.188 32.129C118.188 33.4744 119.359 34.5823 120.687 34.5823Z" fill="#7FCDBB"/>
<path d="M105.922 4.90652C107.249 4.90652 108.421 3.7986 108.421 2.45326C108.421 1.10793 107.328 0 105.922 0C104.594 0 103.422 1.10793 103.422 2.45326C103.422 3.7986 104.594 4.90652 105.922 4.90652Z" fill="#7FCDBB"/>
<path d="M105.922 19.7033C107.249 19.7033 108.421 18.5954 108.421 17.2501C108.421 15.9048 107.328 14.7969 105.922 14.7969C104.594 14.7969 103.422 15.9048 103.422 17.2501C103.422 18.5954 104.594 19.7033 105.922 19.7033Z" fill="#4D4D4F"/>
<path d="M105.922 34.5823C107.249 34.5823 108.421 33.4744 108.421 32.129C108.421 30.7837 107.328 29.6758 105.922 29.6758C104.594 29.6758 103.422 30.7837 103.422 32.129C103.422 33.4744 104.594 34.5823 105.922 34.5823Z" fill="#4D4D4F"/>
<path d="M150.293 4.90652C151.621 4.90652 152.792 3.7986 152.792 2.45326C152.792 1.10793 151.699 0 150.293 0C148.965 0 147.793 1.10793 147.793 2.45326C147.793 3.7986 148.887 4.90652 150.293 4.90652Z" fill="#4D4D4F"/>
<path d="M150.293 19.7033C151.621 19.7033 152.792 18.5954 152.792 17.2501C152.792 15.9048 151.699 14.7969 150.293 14.7969C148.965 14.7969 147.793 15.9048 147.793 17.2501C147.793 18.5954 148.887 19.7033 150.293 19.7033Z" fill="#118ACB"/>
<path d="M150.293 34.5823C151.621 34.5823 152.792 33.4744 152.792 32.129C152.792 30.7837 151.699 29.6758 150.293 29.6758C148.965 29.6758 147.793 30.7837 147.793 32.129C147.793 33.4744 148.887 34.5823 150.293 34.5823Z" fill="#7FCDBB"/>
<path d="M135.527 4.90652C136.855 4.90652 138.027 3.7986 138.027 2.45326C138.027 1.10793 136.933 0 135.527 0C134.199 0 133.027 1.10793 133.027 2.45326C133.027 3.7986 134.121 4.90652 135.527 4.90652Z" fill="#7FCDBB"/>
<path d="M135.527 19.7033C136.855 19.7033 138.027 18.5954 138.027 17.2501C138.027 15.9048 136.933 14.7969 135.527 14.7969C134.121 14.7969 133.027 15.9048 133.027 17.2501C133.027 18.5954 134.121 19.7033 135.527 19.7033Z" fill="#4D4D4F"/>
<path d="M135.527 34.5823C136.855 34.5823 138.027 33.4744 138.027 32.129C138.027 30.7837 136.933 29.6758 135.527 29.6758C134.121 29.6758 133.027 30.7837 133.027 32.129C133.027 33.4744 134.121 34.5823 135.527 34.5823Z" fill="#7FCDBB"/>
</svg>`

export const foundationSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="74" height="65" viewBox="0 0 74 65" fill="none">
<path d="M31.0627 64.3002C29.4992 61.9012 22.8247 51.8139 20.8716 51.5441C18.9683 51.2811 12.2045 61.4809 10.375 64.3002H31.0627Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39.6211 5.35547V28.6174" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M42.3633 5.97656V28.6141" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.3711 18.3711V40.2978" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48.5547 5.97656V40.291" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M51.7383 5.97656V29.2385" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.2344 8.47266H51.8276" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.2344 13.6445H53.0599" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.9688 18.3711H54.5672" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.7031 23.6289H54.5676" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.0664 29.332H53.5973" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M43.6016 34.8594H52.4454" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.3203 7.56641L23.4422 10.0294" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.5625 4.96875L27.6489 6.61018" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.1289 1L33.0797 4.00958" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.9394 39.6764H44.7907V27.3389H37.8055V5.48828C34.8617 5.51757 31.9748 6.30957 29.4223 7.78812C26.8699 9.26668 24.7378 11.3821 23.231 13.931C21.7241 16.4799 20.8932 19.3766 20.8181 22.3426C20.743 25.3087 21.4262 28.2443 22.8021 30.8673C23.3616 32.1801 24.6762 35.3955 25.9926 39.5536C27.2094 43.3917 27.8444 47.2505 27.8444 47.8334C27.8444 47.8905 27.8444 47.9493 27.8444 48.0064C27.8384 48.1799 27.8672 48.3528 27.9289 48.5149C27.9906 48.677 28.0841 48.8249 28.2037 48.9498C28.3233 49.0747 28.4667 49.1741 28.6252 49.242C28.7837 49.3099 28.9542 49.345 29.1265 49.3451H46.8467C47.0189 49.3447 47.1893 49.3095 47.3477 49.2415C47.5061 49.1735 47.6494 49.0741 47.769 48.9493C47.8885 48.8244 47.982 48.6766 48.0438 48.5146C48.1056 48.3526 48.1345 48.1798 48.1288 48.0064C48.1288 47.9441 48.1288 47.8853 48.1288 47.8334C48.1253 47.2574 48.7483 43.473 49.9394 39.6764Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.9883 8.27385C32.4849 8.04818 32.9944 7.85239 33.514 7.6875" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.2734 12.6565C26.2734 12.6565 27.6722 10.408 30.746 8.89453" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.6406 37.5508C27.2774 39.6471 28.12 42.5512 28.4118 44.1736C28.9061 46.9099 28.5834 48.3247 29.6475 48.5686" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.8633 35.0508C25.8633 35.0508 26.0349 35.6129 26.3112 36.4743" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.8992 49.172V32.6366C36.8992 32.6366 37.5857 28.3125 35.3546 28.3125C33.1235 28.3125 33.2951 30.561 35.8695 32.9825C38.4438 35.404 42.3912 33.1555 42.9061 31.2529C43.421 29.3503 42.048 28.4855 41.1898 30.0421C40.3317 31.5988 41.1898 49.2412 41.1898 49.2412" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.2935 49.2422H28.7734V53.5732H47.2935V49.2422Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.2935 54.6016H28.7734V58.9326H47.2935V54.6016Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.2935 53.5742H28.7734V54.6051H47.2935V53.5742Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.2935 59.9688H28.7734V64.2998H47.2935V59.9688Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.3555 52.9961H46.7319" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.5039 52.9961H44.6961" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.7891 58.4414H46.2256" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.25 58.4414H41.2757" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.7734 58.4414H31.5372" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.2935 58.9375H28.7734V59.9684H47.2935V58.9375Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.5273 60.918H31.1875V64.2441H37.5273V60.918Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M45.7773 60.918H39.4375V64.2441H45.7773V60.918Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M51.4799 64.3C50.2648 63.2259 45.0765 58.7098 43.5662 58.5921C42.0868 58.4728 36.8351 63.0408 35.4141 64.3H51.4799Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M71.5117 64.3008H73.0014" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.31641 64.3008H70.0201" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 64.3008H2.15847" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M68.5123 54.4297H64.0586V60.7256H65.9036H68.5123H70.3589V57.5776L68.5123 54.4297Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70.3609 60.7227H68.3203V62.1531H70.3609V60.7227Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M60.7501 60.6542C60.7501 60.6542 62.9812 59.4435 62.2089 53.3897L59.3771 51.833L54.4858 51.0547L50.1094 51.2277V53.7356C50.1094 53.7356 52.5979 55.8977 52.8554 56.5895C53.0168 57.0631 53.1037 57.5591 53.1128 58.0597V60.4812L60.7501 60.6542Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M62.2088 53.3867L60.75 60.6512" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M59.3765 51.832L57.7461 60.6532" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M54.4865 51.0547L53.2852 57.6273" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M50.2812 53.3867C50.2812 53.3867 53.1131 54.5975 54.2286 55.3758C55.3442 56.1541 55.1726 57.7973 55.1726 57.7973" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M64.099 60.7695H50.793V61.8938H64.099V60.7695Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M66.7578 57.8874H69.5364L68.045 55.293H66.7578V57.8874Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M66.3264 55.293H65.125V57.8874H66.3264V55.293Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M58.964 60.7227H50.8633V62.672H58.964V60.7227Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M66.6385 64.2664C67.5647 64.0978 68.1799 63.2044 68.0126 62.2709C67.8453 61.3375 66.9588 60.7174 66.0326 60.8861C65.1063 61.0547 64.4911 61.9481 64.6584 62.8816C64.8258 63.815 65.7123 64.4351 66.6385 64.2664Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M66.3336 63.585C66.8824 63.585 67.3273 63.1366 67.3273 62.5835C67.3273 62.0304 66.8824 61.582 66.3336 61.582C65.7847 61.582 65.3398 62.0304 65.3398 62.5835C65.3398 63.1366 65.7847 63.585 66.3336 63.585Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M53.5028 64.2705C54.4296 64.1052 55.0479 63.2139 54.8838 62.2799C54.7198 61.3458 53.8354 60.7227 52.9086 60.8881C51.9818 61.0534 51.3635 61.9447 51.5276 62.8787C51.6917 63.8128 52.576 64.4359 53.5028 64.2705Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M53.2046 63.585C53.7535 63.585 54.1984 63.1366 54.1984 62.5835C54.1984 62.0304 53.7535 61.582 53.2046 61.582C52.6558 61.582 52.2109 62.0304 52.2109 62.5835C52.2109 63.1366 52.6558 63.585 53.2046 63.585Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M58.4708 63.8372C59.1641 63.1957 59.21 62.1092 58.5734 61.4105C57.9368 60.7118 56.8587 60.6655 56.1654 61.3071C55.4722 61.9487 55.4262 63.0352 56.0628 63.7338C56.6994 64.4325 57.7775 64.4788 58.4708 63.8372Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M57.3179 63.585C57.8667 63.585 58.3116 63.1366 58.3116 62.5835C58.3116 62.0304 57.8667 61.582 57.3179 61.582C56.7691 61.582 56.3242 62.0304 56.3242 62.5835C56.3242 63.1366 56.7691 63.585 57.3179 63.585Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M56.7586 57.7148H52.4679C52.1599 57.7148 51.9102 57.9665 51.9102 58.277V60.0931C51.9102 60.4036 52.1599 60.6552 52.4679 60.6552H56.7586C57.0666 60.6552 57.3163 60.4036 57.3163 60.0931V58.277C57.3163 57.9665 57.0666 57.7148 56.7586 57.7148Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.7656 60.6546V52.1016H51.4819V60.6546" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.7773 53.2383H51.4627" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.7773 54.5703H51.4627" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.7773 55.9062H51.4627" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.7773 57.2383H51.4627" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.7773 58.5781H51.4627" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.7773 59.9102H51.4627" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.4369 64.467H12.3477V10.1562L15.4369 13.7764V64.467Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 53.0502H15.4081L12.3789 50.0977H15.4081L12.3789 53.0502Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 50.0228H15.4081L12.3789 47.0703H15.4081L12.3789 50.0228Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 46.9994H15.4081L12.3789 44.0469H15.4081L12.3789 46.9994Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 43.972H15.4081L12.3789 41.0195H15.4081L12.3789 43.972Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 40.9447H15.4081L12.3789 37.9922H15.4081L12.3789 40.9447Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 37.9134H15.4081L12.3789 34.9609H15.4081L12.3789 37.9134Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 34.89H15.4081L12.3789 31.9375H15.4081L12.3789 34.89Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 31.8627H15.4081L12.3789 28.9102H15.4081L12.3789 31.8627Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 28.8353H15.4081L12.3789 25.8828H15.4081L12.3789 28.8353Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 25.8119H15.4081L12.3789 22.8594H15.4081L12.3789 25.8119Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 22.7845H15.4081L12.3789 19.832H15.4081L12.3789 22.7845Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 19.7572H15.4081L12.3789 16.8047H15.4081L12.3789 19.7572Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3789 16.7259H15.4081L12.3789 13.7734H15.4081L12.3789 16.7259Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.7819 16.8129L28.2853 15.8633H17.6051L16.3008 16.8129H29.7819Z" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.0284 16.7305H3.94141V17.4223H30.0284V16.7305Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.283 15.8606L12.3477 10.1562L17.6028 15.8606" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.351 10.1562L3.94141 16.7289" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.6703 18.2031H11.2344V22.3543H17.6703V18.2031Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.3277 19.0664H12.5742V20.2772H16.3277V19.0664Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.0273 17.4219V24.8801" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.0083 27.4297H29.1016V29.6073H31.0083V27.4297Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.1016 27.424L30.0558 24.7344L31.01 27.424" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.8845 53.7038L29.0035 60.266L30.0332 59.5067L27.0023 53.3976C26.8661 53.123 26.6567 52.8921 26.3975 52.7308C26.1383 52.5694 25.8396 52.4841 25.535 52.4844C25.3951 52.487 25.2585 52.527 25.1391 52.6005C25.0197 52.6739 24.9219 52.7781 24.8556 52.9022C24.7894 53.0264 24.7572 53.1661 24.7622 53.3069C24.7673 53.4478 24.8095 53.5848 24.8845 53.7038Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.671 58.6523L25.4336 61.6896C25.4336 61.6896 25.8112 63.3016 28.2585 63.2065C30.7059 63.1114 30.6132 60.5498 30.6132 60.5498L29.671 58.6523Z" fill="#45B097" stroke="white" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.9023 56.6634L25.5774 53.1782C25.8305 53.0634 26.115 53.0394 26.3835 53.1102C26.6521 53.181 26.8885 53.3423 27.0534 53.5673C27.1497 53.6987 27.219 53.8482 27.257 54.0071C27.2951 54.1659 27.3012 54.3308 27.275 54.492C27.2487 54.6533 27.1907 54.8076 27.1043 54.9458C27.0179 55.0841 26.9049 55.2035 26.7719 55.297L18.3743 61.221L17.9023 56.6634Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.7735 60.9336H8.86328V62.1668H16.7735V60.9336Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9087 57.4219H6.03516V60.2689H12.9087V57.4219Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.0166 54.1914H17.8518C17.9884 54.1914 18.1193 54.2461 18.2159 54.3434C18.3125 54.4407 18.3667 54.5727 18.3667 54.7103V60.2625H11.5V54.7103C11.5 54.642 11.5134 54.5744 11.5394 54.5113C11.5653 54.4483 11.6034 54.391 11.6514 54.3428C11.6994 54.2946 11.7564 54.2564 11.819 54.2304C11.8817 54.2044 11.9488 54.1912 12.0166 54.1914Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.96539 64.3011H17.2188C17.5743 64.3011 17.9153 64.1588 18.1666 63.9054C18.418 63.6521 18.5592 63.3085 18.5592 62.9502C18.5592 62.5918 18.4181 62.248 18.1667 61.9944C17.9154 61.7408 17.5745 61.5981 17.2188 61.5977H5.96539C5.60974 61.5981 5.26881 61.7408 5.01749 61.9944C4.76616 62.248 4.625 62.5918 4.625 62.9502C4.625 63.3085 4.76622 63.6521 5.01759 63.9054C5.26896 64.1588 5.6099 64.3011 5.96539 64.3011Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.66242 64.3011H18.9141C19.2698 64.3011 19.6109 64.1588 19.8625 63.9055C20.1142 63.6523 20.2558 63.3087 20.2562 62.9502C20.2562 62.5915 20.1148 62.2475 19.8632 61.9938C19.6115 61.7402 19.2701 61.5977 18.9141 61.5977H7.66242C7.30647 61.5977 6.9651 61.7402 6.71341 61.9938C6.46171 62.2475 6.32031 62.5915 6.32031 62.9502C6.32077 63.3087 6.46237 63.6523 6.71401 63.9055C6.96566 64.1588 7.30677 64.3011 7.66242 64.3011Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3698 60.2695H6.03516V61.2191H18.3698V60.2695Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.58237 62.9482C8.58237 63.1172 8.53265 63.2824 8.43949 63.4229C8.34633 63.5634 8.21391 63.6729 8.05899 63.7376C7.90407 63.8023 7.73361 63.8192 7.56914 63.7862C7.40468 63.7533 7.25361 63.6719 7.13504 63.5524C7.01647 63.4329 6.93572 63.2806 6.90301 63.1149C6.8703 62.9491 6.88709 62.7773 6.95126 62.6212C7.01543 62.4651 7.12409 62.3316 7.26352 62.2378C7.40294 62.1439 7.56686 62.0938 7.73455 62.0938C7.9594 62.0938 8.17505 62.1838 8.33405 62.344C8.49305 62.5043 8.58237 62.7216 8.58237 62.9482Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.99236 63.8026C10.4606 63.8026 10.8402 63.4201 10.8402 62.9482C10.8402 62.4763 10.4606 62.0938 9.99236 62.0938C9.52412 62.0938 9.14453 62.4763 9.14453 62.9482C9.14453 63.4201 9.52412 63.8026 9.99236 63.8026Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2502 63.8026C12.7184 63.8026 13.098 63.4201 13.098 62.9482C13.098 62.4763 12.7184 62.0938 12.2502 62.0938C11.7819 62.0938 11.4023 62.4763 11.4023 62.9482C11.4023 63.4201 11.7819 63.8026 12.2502 63.8026Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.3597 62.9482C15.3597 63.1172 15.31 63.2824 15.2168 63.4229C15.1237 63.5634 14.9913 63.6729 14.8363 63.7376C14.6814 63.8023 14.5109 63.8192 14.3465 63.7862C14.182 63.7533 14.031 63.6719 13.9124 63.5524C13.7938 63.4329 13.7131 63.2806 13.6804 63.1149C13.6476 62.9491 13.6644 62.7773 13.7286 62.6212C13.7928 62.4651 13.9014 62.3316 14.0409 62.2378C14.1803 62.1439 14.3442 62.0938 14.5119 62.0938C14.7367 62.0938 14.9524 62.1838 15.1114 62.344C15.2704 62.5043 15.3597 62.7216 15.3597 62.9482Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.6214 62.9482C17.6214 63.1172 17.5717 63.2824 17.4785 63.4229C17.3854 63.5634 17.253 63.6729 17.0981 63.7376C16.9431 63.8023 16.7727 63.8192 16.6082 63.7862C16.4437 63.7533 16.2927 63.6719 16.1741 63.5524C16.0555 63.4329 15.9748 63.2806 15.9421 63.1149C15.9094 62.9491 15.9261 62.7773 15.9903 62.6212C16.0545 62.4651 16.1632 62.3316 16.3026 62.2378C16.442 62.1439 16.6059 62.0938 16.7736 62.0938C16.9985 62.0938 17.2141 62.1838 17.3731 62.344C17.5321 62.5043 17.6214 62.7216 17.6214 62.9482Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.8792 62.9482C19.8792 63.1173 19.8295 63.2825 19.7362 63.4231C19.643 63.5636 19.5105 63.6731 19.3555 63.7378C19.2005 63.8024 19.0299 63.8192 18.8654 63.7861C18.7009 63.753 18.5498 63.6714 18.4313 63.5518C18.3128 63.4321 18.2322 63.2797 18.1997 63.1138C18.1672 62.948 18.1842 62.7761 18.2486 62.62C18.3131 62.4639 18.422 62.3306 18.5616 62.2369C18.7013 62.1432 18.8654 62.0934 19.0331 62.0938C19.1444 62.0938 19.2546 62.1159 19.3573 62.1588C19.4601 62.2018 19.5534 62.2648 19.632 62.3441C19.7106 62.4235 19.7729 62.5177 19.8153 62.6213C19.8578 62.725 19.8795 62.8361 19.8792 62.9482Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M26.4713 54.1944C26.4713 54.1003 26.4436 54.0082 26.3916 53.93C26.3397 53.8517 26.2659 53.7907 26.1795 53.7548C26.0932 53.7188 25.9982 53.7095 25.9066 53.728C25.815 53.7465 25.7309 53.792 25.665 53.8587C25.599 53.9254 25.5542 54.0103 25.5362 54.1027C25.5182 54.1951 25.5278 54.2907 25.5638 54.3776C25.5998 54.4645 25.6605 54.5387 25.7384 54.5907C25.8162 54.6428 25.9076 54.6704 26.001 54.6701C26.0629 54.6701 26.1242 54.6577 26.1814 54.6338C26.2385 54.6099 26.2905 54.5748 26.3342 54.5306C26.3778 54.4864 26.4124 54.434 26.436 54.3763C26.4595 54.3186 26.4715 54.2568 26.4713 54.1944Z" fill="#45B097" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.3385 54.9531H12.3477V57.9904H17.3385V54.9531Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.336 54.9531H14.6055V57.9904H17.336V54.9531Z" fill="white" stroke="#45B097" stroke-width="0.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

export const accelarationSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="81" height="64" viewBox="0 0 81 64" fill="none">
<path d="M80.6375 63.9309H0.0742188V0.224664L80.6876 0.0742188L80.6375 63.9309Z" fill="#FAFAFA"/>
<path d="M80.6385 63.9319C80.6385 63.3373 80.6385 38.3778 80.6027 0.0752221L80.6887 0.143281L0.200594 0.308054H0.0752221L0.175518 0.225668C0.175518 23.5303 0.175518 45.3842 0.175518 63.9319L0.0895506 63.8603L80.6528 63.9319L0.0895506 64H0V63.9284C0 45.3806 0 23.5267 0 0.222085V0.143281H0.225668L80.7137 0H80.7997V0.0716407C80.7101 38.3778 80.6421 63.3373 80.6385 63.9319Z" fill="#F0F0F0"/>
<path d="M75.2236 57.9507H74.9477H74.1346L71.0075 57.9721L59.5092 58.008L21.5684 58.0402H5.20935H5.10547V57.9363C5.10547 47.0971 5.13413 37.7767 5.14487 31.1535C5.14487 27.8545 5.16636 25.2253 5.17353 23.4164C5.17353 22.5209 5.17353 21.8295 5.17353 21.3531C5.17353 21.1239 5.17353 20.9448 5.17353 20.8194C5.17353 20.694 5.17353 20.6367 5.17353 20.6367C5.1767 20.6976 5.1767 20.7585 5.17353 20.8194C5.17353 20.9448 5.17353 21.1239 5.17353 21.3531C5.17353 21.8295 5.17353 22.5209 5.17353 23.4164C5.17353 25.2253 5.19144 27.8545 5.20218 31.1535C5.20218 37.7767 5.22726 47.0971 5.24158 57.9363L5.13771 57.8324H21.4968L59.4376 57.8647L70.9359 57.9005L74.063 57.922H74.8761L75.2236 57.9507Z" fill="#F0F0F0"/>
<path d="M17.9693 48.7383H10.8984V55.8092H17.9693V48.7383Z" fill="#FDA982"/>
<path d="M28.3365 43.6797H21.2656V55.8084H28.3365V43.6797Z" fill="#FDA982"/>
<path d="M38.7467 37.9375H31.6758V55.8082H38.7467V37.9375Z" fill="#FDA982"/>
<path d="M48.8404 31.543H41.7695V55.8076H48.8404V31.543Z" fill="#FDA982"/>
<path d="M59.1686 23.3555H52.0977V55.8086H59.1686V23.3555Z" fill="#FDA982"/>
<path d="M69.4147 17.1133H62.3438V55.4732H69.4147V17.1133Z" fill="#FDA982"/>
<g opacity="0.5">
  <path d="M28.3365 43.6797H21.2656V55.8084H28.3365V43.6797Z" fill="white"/>
</g>
<g opacity="0.5">
  <path d="M48.8404 31.543H41.7695V55.8076H48.8404V31.543Z" fill="white"/>
</g>
<g opacity="0.5">
  <path d="M69.4147 17.1094H62.3438V55.4693H69.4147V17.1094Z" fill="white"/>
</g>
<path d="M10.8984 41.4838C10.927 41.4579 10.9582 41.4351 10.9916 41.4157L11.2674 41.2259L12.342 40.5095L16.3968 37.8409L30.4312 28.6673L30.5924 28.5599V28.7533C30.5924 29.7563 30.5924 30.8273 30.5924 31.9198L30.424 31.8303L35.9547 28.1229L55.4731 15.0843L61.4014 11.1441L63.0205 10.0695L63.4431 9.80081C63.4899 9.76667 63.5404 9.73783 63.5936 9.71484C63.5506 9.75246 63.5051 9.78717 63.4575 9.81872L63.0455 10.1089L61.4479 11.1835L55.5627 15.2061L36.0836 28.2984L30.5637 32.0022L30.3954 32.1168V31.9126C30.3954 30.838 30.3954 29.7634 30.3954 28.7461L30.5566 28.8357L16.4685 37.9519L12.3814 40.574L11.2853 41.2653L10.9987 41.4408C10.9343 41.4695 10.902 41.4874 10.8984 41.4838Z" fill="#F0F0F0"/>
<path d="M63.5963 12.7195C63.5329 12.434 63.5076 12.1413 63.5211 11.8491C63.4996 11.3082 63.4889 10.556 63.4853 9.6999L63.5963 9.81094H63.3241C62.4179 9.81094 61.5976 9.81094 61.0065 9.7787C60.6834 9.78985 60.36 9.76341 60.043 9.6999C60.3626 9.64824 60.687 9.63262 61.0101 9.65333C61.6011 9.63184 62.4214 9.62109 63.3277 9.62109H63.7074V9.72855C63.7074 10.5739 63.7074 11.3261 63.6716 11.8778C63.6792 12.1603 63.6539 12.4428 63.5963 12.7195Z" fill="#F0F0F0"/>
<path d="M12.0597 18.4102C12.0597 18.4102 11.2179 26.219 12.2926 30.4959L22.727 30.0374C22.727 30.0374 20.4918 22.3361 21.8637 18.9331L12.0597 18.4102Z" fill="#EBEBEB"/>
<path d="M12.1055 18.1602C12.1055 18.1602 12.1771 26.5922 12.6034 29.1641L23.1811 28.6627C23.1811 28.6627 22.2498 20.3738 22.608 18.1602H12.1055Z" fill="#FDA982"/>
<path d="M21.014 21.7034C21.014 21.7392 19.4307 21.8681 17.4713 21.9863C15.512 22.1046 13.9216 22.169 13.918 22.1296C13.9144 22.0902 15.5012 21.9613 17.4606 21.8431C19.42 21.7249 21.0104 21.664 21.014 21.7034Z" fill="white"/>
<path d="M21.014 23.4881C21.014 23.5275 19.4307 23.6529 17.4713 23.7711C15.512 23.8893 13.918 23.9538 13.918 23.9144C13.918 23.875 15.5012 23.7496 17.4606 23.6314C19.42 23.5132 21.0104 23.4487 21.014 23.4881Z" fill="white"/>
<path d="M19.2196 25.2419C19.0276 25.2922 18.8305 25.3199 18.6321 25.3243C18.2739 25.3601 17.7617 25.3995 17.1993 25.4318C16.6369 25.464 16.1247 25.4891 15.7665 25.4962C15.5691 25.522 15.3692 25.522 15.1719 25.4962C15.3651 25.4462 15.5634 25.4185 15.7629 25.4138C16.1211 25.378 16.6333 25.3386 17.1957 25.3064C17.7581 25.2741 18.2703 25.2491 18.6285 25.2419C18.8247 25.2162 19.0234 25.2162 19.2196 25.2419Z" fill="white"/>
<path d="M38.5018 8.54464C38.5018 8.60553 32.3157 8.6521 24.686 8.6521C17.0563 8.6521 10.8594 8.60553 10.8594 8.54464C10.8594 8.48374 17.0419 8.43359 24.6717 8.43359C32.3014 8.43359 38.5018 8.48374 38.5018 8.54464Z" fill="#F0F0F0"/>
<path d="M21.019 11.0603C21.019 11.1212 18.6979 11.1677 15.8394 11.1677C12.981 11.1677 10.6562 11.1212 10.6562 11.0603C10.6562 10.9994 12.9774 10.9492 15.8394 10.9492C18.7015 10.9492 21.019 10.9994 21.019 11.0603Z" fill="#F0F0F0"/>
<path d="M26.5216 11.0603C26.5216 11.1212 25.6297 11.1677 24.5264 11.1677C23.4232 11.1677 22.5312 11.1212 22.5312 11.0603C22.5312 10.9994 23.4232 10.9492 24.5264 10.9492C25.6297 10.9492 26.5216 10.9994 26.5216 11.0603Z" fill="#F0F0F0"/>
</svg>`

export const amplificationSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 80 60" fill="none">
<path d="M71.9828 55.3984H66.6836V55.4388H71.9828V55.3984Z" fill="#EBEBEB"/>
<path d="M52.9959 55.8398H51.6055V55.8802H52.9959V55.8398Z" fill="#EBEBEB"/>
<path d="M66.5235 53.9023H63.4531V53.9427H66.5235V53.9023Z" fill="#EBEBEB"/>
<path d="M15.3049 54.1758H8.39453V54.2161H15.3049V54.1758Z" fill="#EBEBEB"/>
<path d="M17.7433 54.1758H16.7305V54.2161H17.7433V54.1758Z" fill="#EBEBEB"/>
<path d="M36.024 54.8555H21.0352V54.8958H36.024V54.8555Z" fill="#EBEBEB"/>
<path d="M38.1455 45.6117H7.24954C7.00724 45.6117 6.77486 45.5146 6.60352 45.3419C6.43219 45.1692 6.33594 44.935 6.33594 44.6907V0.912879C6.33805 0.67003 6.43523 0.437851 6.60634 0.266881C6.77744 0.0959107 7.00862 -9.31038e-06 7.24954 6.77812e-10H38.1455C38.2655 6.77813e-10 38.3843 0.0238207 38.4952 0.0701024C38.606 0.116384 38.7067 0.184221 38.7916 0.269738C38.8764 0.355255 38.9437 0.45678 38.9896 0.568514C39.0355 0.680248 39.0591 0.800003 39.0591 0.920943V44.6907C39.0591 44.8117 39.0355 44.9314 38.9896 45.0432C38.9437 45.1549 38.8764 45.2564 38.7916 45.3419C38.7067 45.4275 38.606 45.4953 38.4952 45.5416C38.3843 45.5879 38.2655 45.6117 38.1455 45.6117ZM7.24954 0.0322573C7.01797 0.0326837 6.79602 0.1256 6.63228 0.290656C6.46854 0.455712 6.37636 0.679454 6.37594 0.912879V44.6907C6.37636 44.9242 6.46854 45.1479 6.63228 45.313C6.79602 45.478 7.01797 45.5709 7.24954 45.5714H38.1455C38.3771 45.5709 38.5991 45.478 38.7628 45.313C38.9265 45.1479 39.0187 44.9242 39.0191 44.6907V0.912879C39.0187 0.679454 38.9265 0.455712 38.7628 0.290656C38.5991 0.1256 38.3771 0.0326837 38.1455 0.0322573H7.24954Z" fill="#EBEBEB"/>
<path d="M72.751 45.6117H41.855C41.6127 45.6117 41.3803 45.5146 41.209 45.3419C41.0377 45.1692 40.9414 44.935 40.9414 44.6907V0.912879C40.9435 0.67003 41.0407 0.437851 41.2118 0.266881C41.3829 0.0959107 41.6141 -9.31038e-06 41.855 6.77812e-10H72.751C72.9933 6.77814e-10 73.2257 0.0970277 73.397 0.269738C73.5684 0.442448 73.6646 0.676694 73.6646 0.920943V44.6907C73.6646 44.935 73.5684 45.1692 73.397 45.3419C73.2257 45.5146 72.9933 45.6117 72.751 45.6117ZM41.855 0.0322573C41.6234 0.0326837 41.4015 0.1256 41.2377 0.290656C41.074 0.455712 40.9818 0.679454 40.9814 0.912879V44.6907C40.9818 44.9242 41.074 45.1479 41.2377 45.313C41.4015 45.478 41.6234 45.5709 41.855 45.5714H72.751C72.9826 45.5709 73.2045 45.478 73.3683 45.313C73.532 45.1479 73.6242 44.9242 73.6246 44.6907V0.912879C73.6242 0.679454 73.532 0.455712 73.3683 0.290656C73.2045 0.1256 72.9826 0.0326837 72.751 0.0322573H41.855Z" fill="#EBEBEB"/>
<path d="M80 52.8047H0V52.845H80V52.8047Z" fill="#EBEBEB"/>
<path d="M27.1373 11.1758H15.5117V26.0254H27.1373V11.1758Z" fill="#F0F0F0"/>
<path d="M25.9823 12.6523H16.6719V24.5456H25.9823V12.6523Z" fill="#FAFAFA"/>
<path d="M14.6125 26.0234H15.5117L15.5117 11.1738H14.6125L14.6125 26.0234Z" fill="#E0E0E0"/>
<path d="M22.6787 18.7832C23.1784 19.2869 23.4591 19.9701 23.4591 20.6824C23.4591 21.3947 23.1784 22.0778 22.6787 22.5815C22.179 23.0852 21.5013 23.3682 20.7947 23.3682C20.0881 23.3682 19.4104 23.0852 18.9107 22.5815C17.8691 21.5316 21.6371 17.7333 22.6787 18.7832Z" fill="#F5F5F5"/>
<path d="M19.8992 18.5724C19.7045 18.3762 19.5501 18.1432 19.4447 17.8868C19.3394 17.6304 19.2852 17.3556 19.2852 17.0781C19.2852 16.8006 19.3394 16.5258 19.4447 16.2694C19.5501 16.013 19.7045 15.78 19.8992 15.5838C20.0939 15.3876 20.325 15.2319 20.5793 15.1257C20.8337 15.0195 21.1063 14.9648 21.3816 14.9648C21.6569 14.9648 21.9295 15.0195 22.1839 15.1257C22.4382 15.2319 22.6693 15.3876 22.864 15.5838C23.6816 16.4096 20.7184 19.3982 19.8992 18.5724Z" fill="#EBEBEB"/>
<path d="M62.4785 32.0508H61.0977V52.9018H62.4785V32.0508Z" fill="#F0F0F0"/>
<path d="M63.0766 32.0508H61.875V52.9018H63.0766V32.0508Z" fill="#E6E6E6"/>
<path d="M48.5566 32.0508H47.1758V52.9018H48.5566V32.0508Z" fill="#F0F0F0"/>
<path d="M49.1586 32.0508H47.957V52.9018H49.1586V32.0508Z" fill="#E6E6E6"/>
<path d="M67.7128 32.0508H66.332V52.9018H67.7128V32.0508Z" fill="#F0F0F0"/>
<path d="M68.311 32.0508H67.1094V52.9018H68.311V32.0508Z" fill="#E6E6E6"/>
<path d="M53.791 32.0508H52.4102V52.9018H53.791V32.0508Z" fill="#F0F0F0"/>
<path d="M68.3134 30.7109H47.1758V32.3819H68.3134V30.7109Z" fill="#F0F0F0"/>
<path d="M53.1902 30.7109H47.1758V32.3819H53.1902V30.7109Z" fill="#E0E0E0"/>
<path d="M54.393 32.0508H53.1914V52.9018H54.393V32.0508Z" fill="#E6E6E6"/>
<path d="M19.6111 30.8203H10.7695V52.9036H19.6111V30.8203Z" fill="#E6E6E6"/>
<path d="M36.1485 30.8203H17.6797V52.9036H36.1485V30.8203Z" fill="#F5F5F5"/>
<path d="M29.8577 32.2109H32.8737C33.1889 32.2109 33.501 32.2735 33.7922 32.3951C34.0834 32.5167 34.3479 32.6949 34.5708 32.9195C34.7937 33.1442 34.9704 33.4109 35.091 33.7044C35.2117 33.9979 35.2737 34.3125 35.2737 34.6302V51.804H27.4609V34.627C27.4618 33.9865 27.7146 33.3724 28.1639 32.9195C28.6132 32.4666 29.2223 32.2118 29.8577 32.2109Z" fill="#EBEBEB"/>
<path d="M21.1351 32.2109H24.1591C24.4743 32.2109 24.7863 32.2735 25.0775 32.3951C25.3687 32.5167 25.6333 32.6949 25.8561 32.9195C26.079 33.1442 26.2558 33.4109 26.3764 33.7044C26.497 33.9979 26.5591 34.3125 26.5591 34.6302V51.804H18.7383V34.627C18.7391 33.9865 18.9919 33.3724 19.4412 32.9195C19.8905 32.4666 20.4997 32.2118 21.1351 32.2109Z" fill="#EBEBEB"/>
<path d="M25.8474 41.8619C25.838 42.0142 25.7847 42.1604 25.6939 42.2825C25.6032 42.4046 25.479 42.4973 25.3366 42.5492C25.1942 42.601 25.0399 42.6098 24.8927 42.5743C24.7455 42.5389 24.6117 42.4609 24.508 42.3498C24.4042 42.2387 24.3349 42.0994 24.3086 41.9492C24.2824 41.7989 24.3003 41.6442 24.3602 41.504C24.4201 41.3639 24.5193 41.2445 24.6457 41.1605C24.772 41.0765 24.92 41.0315 25.0714 41.0312C25.1765 41.0344 25.2799 41.0584 25.3758 41.1018C25.4716 41.1452 25.5581 41.2073 25.6302 41.2845C25.7022 41.3616 25.7585 41.4523 25.7958 41.5514C25.833 41.6504 25.8506 41.756 25.8474 41.8619Z" fill="#E0E0E0"/>
<path d="M29.8435 41.8619C29.8341 42.0142 29.7807 42.1605 29.6899 42.2827C29.5991 42.4048 29.4748 42.4975 29.3323 42.5493C29.1899 42.6011 29.0355 42.6098 28.8882 42.5742C28.7409 42.5386 28.6072 42.4604 28.5035 42.3492C28.3998 42.238 28.3306 42.0986 28.3046 41.9482C28.2785 41.7978 28.2966 41.643 28.3568 41.5029C28.4169 41.3628 28.5164 41.2435 28.6429 41.1597C28.7695 41.0759 28.9176 41.0312 29.0691 41.0313C29.281 41.038 29.4816 41.1293 29.6268 41.285C29.772 41.4408 29.8499 41.6482 29.8435 41.8619Z" fill="#E0E0E0"/>
<path d="M39.999 59.9992C57.1322 59.9992 71.0214 59.1818 71.0214 58.1734C71.0214 57.1651 57.1322 56.3477 39.999 56.3477C22.8658 56.3477 8.97656 57.1651 8.97656 58.1734C8.97656 59.1818 22.8658 59.9992 39.999 59.9992Z" fill="#F5F5F5"/>
<path d="M46.8009 53.7682C46.7865 51.4602 46.1065 50.0441 45.5369 49.2634C45.214 48.7903 44.7903 48.3957 44.2969 48.1086L44.3689 47.957C44.3945 47.9699 46.9401 49.2473 46.9689 53.7633L46.8009 53.7682Z" fill="#407BFF"/>
<path d="M47.4646 53.3595L47.3046 53.345C47.9606 45.8549 42.9846 43.2227 42.9414 43.1969L43.0166 43.0469C43.0614 43.0727 48.1318 45.7565 47.4646 53.3595Z" fill="#407BFF"/>
<path d="M48.026 53.6382C47.8484 49.3722 48.2964 44.6304 48.3012 44.582L48.4612 44.5982C48.4612 44.6465 48.01 49.3771 48.1876 53.6302L48.026 53.6382Z" fill="#407BFF"/>
<path d="M48.3578 53.8863C48.6474 52.0669 49.3562 51.0331 49.8986 50.4863C50.2112 50.1523 50.5938 49.8927 51.0186 49.7267L50.9802 49.5977C50.9578 49.5977 48.7962 50.2976 48.2266 53.8653L48.3578 53.8863Z" fill="#407BFF"/>
<g opacity="0.3">
  <path d="M46.8048 53.7682C46.7904 51.4602 46.1104 50.0441 45.5408 49.2634C45.2179 48.7903 44.7942 48.3957 44.3008 48.1086L44.3728 47.957C44.3984 47.9699 46.944 49.2473 46.9728 53.7633L46.8048 53.7682Z" fill="white"/>
  <path d="M47.4646 53.3595L47.3046 53.345C47.9606 45.8549 42.9846 43.2227 42.9414 43.1969L43.0166 43.0469C43.0614 43.0727 48.1318 45.7565 47.4646 53.3595Z" fill="white"/>
  <path d="M48.026 53.6382C47.8484 49.3722 48.2964 44.6304 48.3012 44.582L48.4612 44.5982C48.4612 44.6465 48.01 49.3771 48.1876 53.6302L48.026 53.6382Z" fill="white"/>
  <path d="M48.3578 53.8863C48.6474 52.0669 49.3562 51.0331 49.8986 50.4863C50.2112 50.1523 50.5938 49.8927 51.0186 49.7267L50.9802 49.5977C50.9578 49.5977 48.7962 50.2976 48.2266 53.8653L48.3578 53.8863Z" fill="white"/>
</g>
<path d="M43.4941 43.3832C43.4941 43.3832 42.3885 40.5462 40.7997 42.6542C39.5101 44.3654 40.7997 46.2396 40.7997 46.2396C40.7997 46.2396 47.7165 46.4154 46.4797 43.7542C45.7133 42.109 43.4941 43.3832 43.4941 43.3832Z" fill="#407BFF"/>
<g opacity="0.5">
  <path d="M43.2685 43.5111C43.2685 43.5111 41.2301 43.1886 41.2189 43.3498C41.2077 43.5111 43.0061 43.5837 42.8381 43.8079C42.6701 44.0321 40.9373 44.2256 40.9181 44.4401C40.8989 44.6547 42.5421 44.1321 42.4685 44.3224C42.3949 44.5127 41.2973 45.4014 41.2797 45.6369C41.2621 45.8724 42.3101 44.6337 42.4349 44.774C42.5597 44.9143 42.6237 46.0369 42.7293 45.7788C42.8349 45.5208 42.7069 44.3885 42.9597 44.166C43.2125 43.9434 44.6957 45.3579 45.0317 45.1675C45.3677 44.9772 43.3869 44.1885 43.2685 43.5111Z" fill="white"/>
  <path d="M44.3359 43.625C44.3359 43.625 46.0319 43.6476 46.0831 44.1089C46.1343 44.5701 45.7615 44.0814 44.3359 43.625Z" fill="white"/>
</g>
<path d="M44.0952 53.6252L44.8824 57.9364C44.9225 58.1563 45.0377 58.3552 45.2081 58.4984C45.3786 58.6416 45.5934 58.7201 45.8152 58.7202H48.8792C49.1011 58.7201 49.3159 58.6416 49.4864 58.4984C49.6568 58.3552 49.772 58.1563 49.812 57.9364L50.6008 53.6252C50.6154 53.545 50.6123 53.4626 50.5917 53.3838C50.5712 53.305 50.5337 53.2317 50.482 53.1691C50.4302 53.1065 50.3655 53.0561 50.2923 53.0214C50.2191 52.9868 50.1393 52.9688 50.0584 52.9688H44.6392C44.5582 52.9683 44.478 52.986 44.4045 53.0204C44.331 53.0548 44.2659 53.1052 44.2139 53.1679C44.162 53.2306 44.1244 53.3041 44.1039 53.3832C44.0833 53.4622 44.0804 53.5449 44.0952 53.6252Z" fill="#407BFF"/>
<path d="M43.6182 48.359C43.6182 48.359 41.7174 46.7348 41.3782 48.8896C41.1046 50.638 42.7606 51.4928 42.7606 51.4928C42.7606 51.4928 47.8502 48.9122 45.9222 47.4606C44.7382 46.5671 43.6182 48.359 43.6182 48.359Z" fill="#407BFF"/>
<path opacity="0.2" d="M43.6182 48.359C43.6182 48.359 41.7174 46.7348 41.3782 48.8896C41.1046 50.638 42.7606 51.4928 42.7606 51.4928C42.7606 51.4928 47.8502 48.9122 45.9222 47.4606C44.7382 46.5671 43.6182 48.359 43.6182 48.359Z" fill="black"/>
<g opacity="0.5">
  <path d="M43.507 48.5469C43.507 48.5469 41.907 49.1162 41.9582 49.2372C42.0094 49.3581 43.3454 48.7049 43.3102 48.934C43.275 49.163 42.0926 49.9839 42.163 50.1468C42.2334 50.3097 43.2222 49.2856 43.243 49.4517C43.2638 49.6178 42.8094 50.6952 42.8878 50.8742C42.9662 51.0533 43.2478 49.7452 43.3918 49.7936C43.5358 49.842 44.019 50.6355 43.995 50.4065C43.971 50.1775 43.4398 49.4065 43.539 49.1469C43.6382 48.8872 45.2606 49.3307 45.4302 49.0598C45.5998 48.7888 43.8526 48.9872 43.507 48.5469Z" fill="white"/>
  <path d="M44.3203 48.2095C44.3203 48.2095 45.5603 47.5643 45.7747 47.8724C45.9891 48.1805 45.5363 47.9805 44.3203 48.2095Z" fill="white"/>
</g>
<path d="M50.1551 43.9652C50.1551 43.9652 52.3615 42.0782 52.7551 44.583C53.0751 46.6152 51.1471 47.6071 51.1471 47.6071C51.1471 47.6071 45.2271 44.5991 47.4671 42.9169C48.8527 41.883 50.1551 43.9652 50.1551 43.9652Z" fill="#407BFF"/>
<path opacity="0.3" d="M50.1551 43.9652C50.1551 43.9652 52.3615 42.0782 52.7551 44.583C53.0751 46.6152 51.1471 47.6071 51.1471 47.6071C51.1471 47.6071 45.2271 44.5991 47.4671 42.9169C48.8527 41.883 50.1551 43.9652 50.1551 43.9652Z" fill="black"/>
<g opacity="0.5">
  <path d="M50.2895 44.1914C50.2895 44.1914 52.1519 44.8543 52.0895 44.9978C52.0271 45.1414 50.4767 44.3785 50.5167 44.6446C50.5567 44.9107 51.9327 45.8639 51.8511 46.0543C51.7695 46.2446 50.6207 45.0527 50.5967 45.2478C50.5727 45.443 51.0991 46.6994 51.0079 46.901C50.9167 47.1026 50.5903 45.5833 50.4223 45.6446C50.2543 45.7059 49.6943 46.6236 49.7215 46.3575C49.7487 46.0914 50.3615 45.1946 50.2511 44.893C50.1407 44.5914 48.2511 45.1075 48.0527 44.7914C47.8543 44.4753 49.8863 44.6946 50.2895 44.1914Z" fill="white"/>
  <path d="M49.3371 43.7905C49.3371 43.7905 47.8971 43.0357 47.6459 43.4002C47.3947 43.7647 47.9275 43.5244 49.3371 43.7905Z" fill="white"/>
</g>
<path d="M51.452 49.6743C51.452 49.6743 52.94 48.1357 53.4008 49.9437C53.7736 51.4114 52.4296 52.2742 52.4296 52.2742C52.4296 52.2742 47.8888 50.4807 49.4152 49.0872C50.3544 48.2324 51.452 49.6743 51.452 49.6743Z" fill="#407BFF"/>
<path opacity="0.4" d="M51.452 49.6743C51.452 49.6743 52.94 48.1357 53.4008 49.9437C53.7736 51.4114 52.4296 52.2742 52.4296 52.2742C52.4296 52.2742 47.8888 50.4807 49.4152 49.0872C50.3544 48.2324 51.452 49.6743 51.452 49.6743Z" fill="black"/>
<g opacity="0.5">
  <path d="M51.5604 49.8203C51.5604 49.8203 52.97 50.1751 52.9348 50.2832C52.8996 50.3913 51.7108 49.9413 51.7572 50.1348C51.8036 50.3284 52.8772 50.93 52.8324 51.0735C52.7876 51.2171 51.8612 50.4284 51.858 50.5687C51.8548 50.709 52.3252 51.5945 52.2724 51.7525C52.2196 51.9106 51.8756 50.8155 51.7572 50.8719C51.6388 50.9284 51.29 51.6412 51.2916 51.4445C51.2932 51.2477 51.6852 50.5461 51.5812 50.3332C51.4772 50.1203 50.1284 50.63 49.962 50.4122C49.7956 50.1945 51.2996 50.2235 51.5604 49.8203Z" fill="white"/>
  <path d="M50.8356 49.5991C50.8356 49.5991 49.7284 49.1459 49.57 49.4379C49.4116 49.7298 49.786 49.5024 50.8356 49.5991Z" fill="white"/>
</g>
<path d="M61.0362 33.1246H49.6266C49.4961 33.127 49.3665 33.1013 49.2466 33.0493C49.1267 32.9973 49.0192 32.9201 48.9313 32.8228C48.8433 32.7256 48.7769 32.6106 48.7365 32.4855C48.6961 32.3604 48.6826 32.228 48.697 32.0972L49.3114 25.0135C49.3395 24.735 49.4678 24.4766 49.6721 24.287C49.8764 24.0974 50.1425 23.9898 50.4202 23.9845H61.8298C61.9605 23.9821 62.0902 24.0078 62.2103 24.06C62.3304 24.1121 62.4381 24.1894 62.5262 24.2868C62.6143 24.3841 62.6808 24.4993 62.7213 24.6246C62.7618 24.75 62.7753 24.8825 62.761 25.0135L62.145 32.0956C62.1172 32.3742 61.989 32.6329 61.7846 32.8225C61.5802 33.0122 61.3139 33.1197 61.0362 33.1246Z" fill="#263238"/>
<path d="M54.3122 27.9822C54.261 28.4904 54.0267 28.962 53.6536 29.3077C53.2806 29.6534 52.7948 29.8492 52.2882 29.858C52.0499 29.8624 51.8133 29.8156 51.5944 29.7206C51.3755 29.6256 51.1791 29.4847 51.0185 29.3072C50.8578 29.1297 50.7366 28.9197 50.6628 28.6912C50.589 28.4628 50.5644 28.2211 50.5906 27.9822C50.6442 27.4752 50.8792 27.0052 51.2518 26.6602C51.6243 26.3152 52.1088 26.119 52.6146 26.1081L52.6978 25.1484C51.9325 25.1622 51.1988 25.4582 50.6354 25.9803C50.072 26.5025 49.7179 27.2147 49.6402 27.9822C49.6009 28.3427 49.6381 28.7074 49.7493 29.0522C49.8606 29.3971 50.0434 29.7141 50.2855 29.9822C50.5276 30.2504 50.8235 30.4636 51.1537 30.6076C51.4838 30.7517 51.8405 30.8232 52.2002 30.8176C52.9657 30.8038 53.6996 30.5077 54.263 29.9851C54.8265 29.4626 55.1804 28.75 55.2578 27.9822H54.3122Z" fill="#407BFF"/>
<path d="M55.5963 27.5701C55.6356 27.2097 55.5984 26.8449 55.4872 26.5001C55.3759 26.1553 55.1932 25.8382 54.951 25.5701C54.7089 25.3019 54.413 25.0887 54.0829 24.9447C53.7527 24.8007 53.396 24.7291 53.0363 24.7347L52.9531 25.7024C53.1914 25.6982 53.4278 25.7452 53.6466 25.8403C53.8654 25.9354 54.0616 26.0763 54.2222 26.2538C54.3828 26.4312 54.5041 26.6411 54.578 26.8694C54.6519 27.0978 54.6767 27.3394 54.6507 27.5782L55.5963 27.5701Z" fill="#407BFF"/>
<path opacity="0.7" d="M55.5963 27.5701C55.6356 27.2097 55.5984 26.8449 55.4872 26.5001C55.3759 26.1553 55.1932 25.8382 54.951 25.5701C54.7089 25.3019 54.413 25.0887 54.0829 24.9447C53.7527 24.8007 53.396 24.7291 53.0363 24.7347L52.9531 25.7024C53.1914 25.6982 53.4278 25.7452 53.6466 25.8403C53.8654 25.9354 54.0616 26.0763 54.2222 26.2538C54.3828 26.4312 54.5041 26.6411 54.578 26.8694C54.6519 27.0978 54.6767 27.3394 54.6507 27.5782L55.5963 27.5701Z" fill="white"/>
<path d="M53.2701 27.984C53.2467 28.2154 53.14 28.4301 52.9702 28.5876C52.8003 28.7451 52.5792 28.8345 52.3485 28.8388C52.24 28.8406 52.1323 28.8191 52.0327 28.7757C51.9331 28.7324 51.8438 28.6681 51.7707 28.5873C51.6976 28.5064 51.6424 28.4108 51.6088 28.3068C51.5751 28.2028 51.5639 28.0927 51.5757 27.984C51.5991 27.7527 51.7059 27.5382 51.8757 27.381C52.0456 27.2237 52.2667 27.1347 52.4973 27.1308L52.5341 26.6953C52.1859 26.7018 51.8521 26.8367 51.5959 27.0744C51.3397 27.3122 51.1788 27.6364 51.1437 27.9856C51.1257 28.1498 51.1426 28.3159 51.1933 28.4729C51.244 28.63 51.3273 28.7744 51.4376 28.8964C51.548 29.0185 51.6829 29.1155 51.8333 29.1809C51.9837 29.2463 52.1463 29.2787 52.3101 29.2759C52.6585 29.2698 52.9927 29.1351 53.2492 28.8973C53.5058 28.6595 53.6669 28.3351 53.7021 27.9856L53.2701 27.984Z" fill="#407BFF"/>
<path opacity="0.7" d="M53.2701 27.984C53.2467 28.2154 53.14 28.4301 52.9702 28.5876C52.8003 28.7451 52.5792 28.8345 52.3485 28.8388C52.24 28.8406 52.1323 28.8191 52.0327 28.7757C51.9331 28.7324 51.8438 28.6681 51.7707 28.5873C51.6976 28.5064 51.6424 28.4108 51.6088 28.3068C51.5751 28.2028 51.5639 28.0927 51.5757 27.984C51.5991 27.7527 51.7059 27.5382 51.8757 27.381C52.0456 27.2237 52.2667 27.1347 52.4973 27.1308L52.5341 26.6953C52.1859 26.7018 51.8521 26.8367 51.5959 27.0744C51.3397 27.3122 51.1788 27.6364 51.1437 27.9856C51.1257 28.1498 51.1426 28.3159 51.1933 28.4729C51.244 28.63 51.3273 28.7744 51.4376 28.8964C51.548 29.0185 51.6829 29.1155 51.8333 29.1809C51.9837 29.2463 52.1463 29.2787 52.3101 29.2759C52.6585 29.2698 52.9927 29.1351 53.2492 28.8973C53.5058 28.6595 53.6669 28.3351 53.7021 27.9856L53.2701 27.984Z" fill="white"/>
<path d="M53.8518 27.7944C53.8698 27.6301 53.8529 27.4638 53.8021 27.3067C53.7513 27.1495 53.6679 27.005 53.5573 26.8829C53.4468 26.7608 53.3117 26.6639 53.1611 26.5986C53.0105 26.5332 52.8478 26.501 52.6838 26.5041L52.6406 26.9347C52.749 26.933 52.8565 26.9544 52.956 26.9977C53.0555 27.041 53.1447 27.1051 53.2178 27.1858C53.2909 27.2664 53.3461 27.3618 53.3798 27.4657C53.4135 27.5695 53.425 27.6793 53.4134 27.7879L53.8518 27.7944Z" fill="#407BFF"/>
<path d="M59.3384 25.1487L59.2552 26.1084C59.4934 26.104 59.7297 26.1508 59.9486 26.2457C60.1674 26.3405 60.3636 26.4813 60.5243 26.6587C60.6849 26.836 60.8062 27.0458 60.8801 27.274C60.954 27.5023 60.9788 27.7438 60.9528 27.9825C60.9016 28.4904 60.6676 28.9618 60.2949 29.3074C59.9222 29.6531 59.4368 29.8491 58.9304 29.8583C58.692 29.863 58.4554 29.8163 58.2363 29.7214C58.0172 29.6266 57.8208 29.4856 57.6601 29.3081C57.4994 29.1305 57.3781 28.9204 57.3045 28.6918C57.2308 28.4632 57.2063 28.2214 57.2328 27.9825C57.2812 27.4733 57.5162 27.0006 57.892 26.6568L57.2792 25.9794C56.7108 26.4986 56.3548 27.2129 56.2808 27.9825C56.2415 28.343 56.2787 28.7077 56.39 29.0525C56.5012 29.3974 56.684 29.7144 56.9261 29.9825C57.1682 30.2507 57.4642 30.4639 57.7943 30.6079C58.1244 30.752 58.4811 30.8235 58.8408 30.8179C59.6062 30.8038 60.3399 30.5075 60.9033 29.985C61.4667 29.4626 61.8207 28.7502 61.8984 27.9825C61.9375 27.6222 61.9001 27.2577 61.7887 26.913C61.6773 26.5684 61.4946 26.2516 61.2525 25.9836C61.0104 25.7156 60.7145 25.5026 60.3845 25.3587C60.0545 25.2147 59.6979 25.1432 59.3384 25.1487Z" fill="#407BFF"/>
<path opacity="0.7" d="M59.3384 25.1487L59.2552 26.1084C59.4934 26.104 59.7297 26.1508 59.9486 26.2457C60.1674 26.3405 60.3636 26.4813 60.5243 26.6587C60.6849 26.836 60.8062 27.0458 60.8801 27.274C60.954 27.5023 60.9788 27.7438 60.9528 27.9825C60.9016 28.4904 60.6676 28.9618 60.2949 29.3074C59.9222 29.6531 59.4368 29.8491 58.9304 29.8583C58.692 29.863 58.4554 29.8163 58.2363 29.7214C58.0172 29.6266 57.8208 29.4856 57.6601 29.3081C57.4994 29.1305 57.3781 28.9204 57.3045 28.6918C57.2308 28.4632 57.2063 28.2214 57.2328 27.9825C57.2812 27.4733 57.5162 27.0006 57.892 26.6568L57.2792 25.9794C56.7108 26.4986 56.3548 27.2129 56.2808 27.9825C56.2415 28.343 56.2787 28.7077 56.39 29.0525C56.5012 29.3974 56.684 29.7144 56.9261 29.9825C57.1682 30.2507 57.4642 30.4639 57.7943 30.6079C58.1244 30.752 58.4811 30.8235 58.8408 30.8179C59.6062 30.8038 60.3399 30.5075 60.9033 29.985C61.4667 29.4626 61.8207 28.7502 61.8984 27.9825C61.9375 27.6222 61.9001 27.2577 61.7887 26.913C61.6773 26.5684 61.4946 26.2516 61.2525 25.9836C61.0104 25.7156 60.7145 25.5026 60.3845 25.3587C60.0545 25.2147 59.6979 25.1432 59.3384 25.1487Z" fill="white"/>
<path d="M57.0938 25.454L57.7082 26.133C58.0779 25.7836 58.5646 25.5873 59.0714 25.583L59.1546 24.625C58.3889 24.6315 57.6535 24.9273 57.0938 25.454Z" fill="#407BFF"/>
<path opacity="0.3" d="M57.0938 25.454L57.7082 26.133C58.0779 25.7836 58.5646 25.5873 59.0714 25.583L59.1546 24.625C58.3889 24.6315 57.6535 24.9273 57.0938 25.454Z" fill="black"/>
<path d="M59.2101 26.6137L59.1701 27.0717C59.2841 27.0694 59.3973 27.0916 59.502 27.137C59.6068 27.1823 59.7007 27.2497 59.7775 27.3346C59.8543 27.4195 59.9123 27.52 59.9474 27.6293C59.9826 27.7386 59.9941 27.8543 59.9813 27.9685C59.9569 28.2099 59.8458 28.434 59.6691 28.5988C59.4924 28.7636 59.262 28.8578 59.0213 28.8636C58.9075 28.8659 58.7945 28.8437 58.6899 28.7984C58.5852 28.7532 58.4914 28.686 58.4146 28.6012C58.3378 28.5165 58.2799 28.4162 58.2446 28.3071C58.2093 28.198 58.1976 28.0825 58.2101 27.9685C58.2341 27.7242 58.3483 27.4979 58.5301 27.3346L58.2373 27.012C57.9645 27.2599 57.7933 27.6016 57.7573 27.9701C57.7384 28.1424 57.7562 28.3169 57.8095 28.4818C57.8627 28.6467 57.9503 28.7982 58.0663 28.9263C58.1823 29.0544 58.3241 29.156 58.4821 29.2245C58.6402 29.2929 58.8109 29.3266 58.9829 29.3233C59.3485 29.3169 59.699 29.1756 59.9682 28.9263C60.2374 28.6769 60.4066 28.3367 60.4437 27.9701C60.463 27.7968 60.4452 27.6214 60.3916 27.4557C60.3379 27.2899 60.2497 27.1377 60.1329 27.0092C60.016 26.8806 59.8732 26.7789 59.714 26.7106C59.5548 26.6424 59.383 26.6093 59.2101 26.6137Z" fill="#407BFF"/>
<path d="M58.1406 26.7499L58.4334 27.0725C58.6111 26.9062 58.8438 26.8125 59.0862 26.8096L59.1246 26.3516C58.7587 26.3556 58.4076 26.4977 58.1406 26.7499Z" fill="#407BFF"/>
<path opacity="0.3" d="M58.1406 26.7499L58.4334 27.0725C58.6111 26.9062 58.8438 26.8125 59.0862 26.8096L59.1246 26.3516C58.7587 26.3556 58.4076 26.4977 58.1406 26.7499Z" fill="black"/>
<path d="M50.908 32.0603C50.8696 32.061 50.8316 32.0534 50.7964 32.0381C50.7611 32.0229 50.7295 32.0002 50.7037 31.9717C50.6778 31.9432 50.6582 31.9094 50.6463 31.8727C50.6343 31.836 50.6303 31.7971 50.6344 31.7587C50.6424 31.6779 50.6793 31.6028 50.7382 31.5473C50.797 31.4918 50.8738 31.4598 50.9544 31.4571C50.9927 31.4564 51.0307 31.4639 51.066 31.4792C51.1012 31.4945 51.1328 31.5171 51.1586 31.5457C51.1845 31.5742 51.2041 31.6079 51.216 31.6447C51.228 31.6814 51.232 31.7202 51.228 31.7587C51.2195 31.8393 51.1826 31.9143 51.1238 31.9697C51.065 32.0251 50.9884 32.0572 50.908 32.0603Z" fill="white"/>
<path d="M53.9778 31.9084H51.7378C51.7183 31.909 51.6988 31.9053 51.6808 31.8976C51.6627 31.89 51.6466 31.8785 51.6334 31.8639C51.6201 31.8494 51.6102 31.8321 51.6042 31.8134C51.5982 31.7946 51.5963 31.7747 51.5986 31.7551C51.5986 31.7124 51.6155 31.6713 51.6455 31.6411C51.6755 31.6108 51.7162 31.5939 51.7586 31.5939H53.9986C54.0189 31.593 54.0392 31.5969 54.0579 31.605C54.0766 31.6131 54.0932 31.6254 54.1066 31.6409C54.1199 31.6563 54.1297 31.6746 54.1351 31.6944C54.1405 31.7142 54.1414 31.735 54.1378 31.7551C54.1358 31.7965 54.118 31.8355 54.0882 31.864C54.0584 31.8925 54.0189 31.9084 53.9778 31.9084Z" fill="white"/>
<path d="M53.3615 32.514H51.6879C51.6677 32.5145 51.6476 32.5105 51.629 32.5023C51.6105 32.4941 51.594 32.4818 51.5807 32.4664C51.5674 32.451 51.5576 32.4328 51.5521 32.4132C51.5466 32.3935 51.5454 32.3729 51.5487 32.3527C51.551 32.3107 51.5686 32.271 51.5981 32.2413C51.6277 32.2115 51.667 32.1938 51.7087 32.1914H53.3839C53.4042 32.1909 53.4243 32.1949 53.4428 32.2032C53.4614 32.2114 53.4779 32.2237 53.4912 32.2391C53.5045 32.2545 53.5142 32.2727 53.5198 32.2923C53.5253 32.312 53.5264 32.3326 53.5231 32.3527C53.5204 32.3949 53.5025 32.4346 53.4727 32.4643C53.4429 32.4941 53.4034 32.5117 53.3615 32.514Z" fill="white"/>
<path d="M57.3978 32.0603C57.3594 32.0612 57.3212 32.0538 57.2857 32.0386C57.2503 32.0235 57.2185 32.0009 57.1925 31.9723C57.1664 31.9438 57.1467 31.9099 57.1347 31.8731C57.1226 31.8363 57.1185 31.7972 57.1226 31.7587C57.1307 31.6779 57.1676 31.6028 57.2265 31.5473C57.2853 31.4918 57.3621 31.4598 57.4426 31.4571C57.481 31.4564 57.519 31.4639 57.5542 31.4792C57.5895 31.4945 57.6211 31.5171 57.6469 31.5457C57.6728 31.5742 57.6924 31.6079 57.7043 31.6447C57.7163 31.6814 57.7203 31.7202 57.7162 31.7587C57.7079 31.8391 57.6711 31.9138 57.6127 31.9691C57.5542 32.0245 57.478 32.0569 57.3978 32.0603Z" fill="white"/>
<path d="M60.4659 31.9084H58.2259C58.2064 31.9087 58.1871 31.9049 58.1692 31.8971C58.1513 31.8893 58.1352 31.8778 58.1221 31.8634C58.1089 31.8489 58.0989 31.8317 58.0928 31.8131C58.0868 31.7944 58.0847 31.7747 58.0867 31.7551C58.089 31.7131 58.1066 31.6734 58.1361 31.6437C58.1656 31.6139 58.205 31.5962 58.2467 31.5939H60.4867C60.507 31.593 60.5273 31.5969 60.546 31.605C60.5647 31.6131 60.5813 31.6254 60.5947 31.6409C60.608 31.6563 60.6178 31.6746 60.6232 31.6944C60.6286 31.7142 60.6295 31.735 60.6259 31.7551C60.6238 31.7965 60.6061 31.8355 60.5763 31.864C60.5465 31.8925 60.507 31.9084 60.4659 31.9084Z" fill="white"/>
<path d="M59.8498 32.514H58.1762C58.1559 32.5145 58.1358 32.5105 58.1173 32.5023C58.0988 32.4941 58.0822 32.4818 58.0689 32.4664C58.0556 32.451 58.0459 32.4328 58.0404 32.4132C58.0348 32.3935 58.0337 32.3729 58.037 32.3527C58.0393 32.3107 58.0569 32.271 58.0864 32.2413C58.1159 32.2115 58.1553 32.1938 58.197 32.1914H59.8722C59.8925 32.1909 59.9126 32.1949 59.9311 32.2032C59.9496 32.2114 59.9662 32.2237 59.9795 32.2391C59.9928 32.2545 60.0025 32.2727 60.008 32.2923C60.0136 32.312 60.0147 32.3326 60.0114 32.3527C60.0087 32.3949 59.9908 32.4346 59.961 32.4643C59.9312 32.4941 59.8916 32.5117 59.8498 32.514Z" fill="white"/>
<path d="M46.3067 36.9874H29.2139C29.0834 36.9897 28.9538 36.9641 28.8338 36.9121C28.7138 36.86 28.6062 36.7829 28.5181 36.6857C28.4301 36.5885 28.3635 36.4735 28.3229 36.3484C28.2823 36.2233 28.2686 36.0909 28.2827 35.96L29.2331 25.0135C29.2612 24.735 29.3896 24.4766 29.5939 24.287C29.7982 24.0974 30.0643 23.9898 30.3419 23.9845H47.4363C47.5669 23.9823 47.6965 24.0082 47.8164 24.0604C47.9363 24.1127 48.0438 24.19 48.1317 24.2873C48.2196 24.3847 48.286 24.4998 48.3264 24.625C48.3668 24.7502 48.3803 24.8826 48.3659 25.0135L47.4171 35.9664C47.3872 36.2437 47.2578 36.5005 47.0533 36.6885C46.8489 36.8764 46.5834 36.9827 46.3067 36.9874Z" fill="#407BFF"/>
<path opacity="0.7" d="M46.3067 36.9874H29.2139C29.0834 36.9897 28.9538 36.9641 28.8338 36.9121C28.7138 36.86 28.6062 36.7829 28.5181 36.6857C28.4301 36.5885 28.3635 36.4735 28.3229 36.3484C28.2823 36.2233 28.2686 36.0909 28.2827 35.96L29.2331 25.0135C29.2612 24.735 29.3896 24.4766 29.5939 24.287C29.7982 24.0974 30.0643 23.9898 30.3419 23.9845H47.4363C47.5669 23.9823 47.6965 24.0082 47.8164 24.0604C47.9363 24.1127 48.0438 24.19 48.1317 24.2873C48.2196 24.3847 48.286 24.4998 48.3264 24.625C48.3668 24.7502 48.3803 24.8826 48.3659 25.0135L47.4171 35.9664C47.3872 36.2437 47.2578 36.5005 47.0533 36.6885C46.8489 36.8764 46.5834 36.9827 46.3067 36.9874Z" fill="white"/>
<path d="M29.9258 33.7136H29.4922L30.073 27.0234H30.5066L29.9258 33.7136Z" fill="#407BFF"/>
<path d="M30.5446 33.7138H30.1094L30.5366 28.793H30.9718L30.5446 33.7138Z" fill="#263238"/>
<path d="M31.5195 33.716H31.0859L31.3643 30.5H31.7979L31.5195 33.716Z" fill="#407BFF"/>
<path d="M32.1328 33.7143H31.6992L32.032 29.8789H32.4656L32.1328 33.7143Z" fill="#263238"/>
<path d="M33.111 33.716H32.6758L33.1718 28H33.607L33.111 33.716Z" fill="#407BFF"/>
<path d="M33.7243 33.7138H33.2891L33.7179 28.793H34.1515L33.7243 33.7138Z" fill="#263238"/>
<path d="M34.7031 33.7138H34.2695L34.6967 28.793H35.1303L34.7031 33.7138Z" fill="#407BFF"/>
<path d="M35.3164 33.7158H34.8828L35.4172 27.5547H35.8508L35.3164 33.7158Z" fill="#263238"/>
<path d="M36.2946 33.7162H35.8594L36.1506 30.3711H36.5842L36.2946 33.7162Z" fill="#407BFF"/>
<path d="M36.9102 33.7138H36.4766L36.9038 28.793H37.3374L36.9102 33.7138Z" fill="#263238"/>
<path d="M37.8828 33.716H37.4492L37.9452 28H38.3788L37.8828 33.716Z" fill="#407BFF"/>
<path d="M38.5016 33.7165H38.0664L38.668 26.7812H39.1032L38.5016 33.7165Z" fill="#263238"/>
<path d="M39.4766 33.7155H39.043L39.1998 31.8945H39.635L39.4766 33.7155Z" fill="#407BFF"/>
<path d="M40.0898 33.7146H39.6562L39.949 30.3438H40.3826L40.0898 33.7146Z" fill="#263238"/>
<path d="M41.0664 33.716H40.6328L40.9128 30.5H41.3464L41.0664 33.716Z" fill="#407BFF"/>
<path d="M41.6813 33.7144H41.2461L41.7949 27.3984H42.2285L41.6813 33.7144Z" fill="#263238"/>
<path d="M42.6602 33.7154H42.2266L42.5866 29.5703H43.0202L42.6602 33.7154Z" fill="#407BFF"/>
<path d="M43.2734 33.7161H42.8398L43.5358 25.6953H43.9694L43.2734 33.7161Z" fill="#263238"/>
<path d="M44.2516 33.7135H43.8164L44.2836 28.3281H44.7188L44.2516 33.7135Z" fill="#407BFF"/>
<path d="M44.8633 33.7138H44.4297L44.8569 28.793H45.2905L44.8633 33.7138Z" fill="#263238"/>
<path d="M45.8398 33.7141H45.4062L45.995 26.9336H46.4286L45.8398 33.7141Z" fill="#407BFF"/>
<path d="M46.457 33.7129H46.0234L46.3706 29.7227H46.8042L46.457 33.7129Z" fill="#263238"/>
<path d="M29.7599 35.5394C29.7052 35.5407 29.6509 35.5302 29.6006 35.5085C29.5503 35.4869 29.5052 35.4547 29.4682 35.4141C29.4312 35.3735 29.4033 35.3254 29.3863 35.273C29.3693 35.2207 29.3635 35.1652 29.3695 35.1104C29.3822 34.9943 29.4362 34.8867 29.5214 34.8075C29.6067 34.7283 29.7176 34.683 29.8335 34.6798C29.8883 34.6785 29.9427 34.6891 29.9931 34.7108C30.0436 34.7325 30.0888 34.7648 30.1258 34.8056C30.1628 34.8464 30.1906 34.8948 30.2076 34.9473C30.2245 34.9999 30.23 35.0555 30.2239 35.1104C30.2108 35.2263 30.1566 35.3335 30.0714 35.4123C29.9861 35.4912 29.8755 35.5363 29.7599 35.5394Z" fill="#263238"/>
<path d="M34.128 35.3277H30.9456C30.9179 35.3283 30.8903 35.323 30.8648 35.312C30.8392 35.3011 30.8163 35.2847 30.7975 35.2641C30.7788 35.2435 30.7646 35.2191 30.7559 35.1925C30.7472 35.166 30.7442 35.1378 30.7472 35.11C30.7534 35.0506 30.7809 34.9956 30.8245 34.9552C30.8681 34.9148 30.9248 34.8919 30.984 34.8906H34.1664C34.1942 34.8903 34.2218 34.8958 34.2474 34.907C34.2729 34.9181 34.2958 34.9346 34.3145 34.9553C34.3333 34.9761 34.3475 35.0006 34.3561 35.0272C34.3648 35.0539 34.3678 35.0821 34.3648 35.11C34.3586 35.1692 34.331 35.224 34.2874 35.2641C34.2438 35.3043 34.1871 35.3269 34.128 35.3277Z" fill="#263238"/>
<path d="M33.2546 36.1897H30.8706C30.8424 36.1902 30.8144 36.1845 30.7885 36.1731C30.7627 36.1616 30.7396 36.1446 30.7209 36.1233C30.7022 36.102 30.6883 36.0769 30.6802 36.0496C30.672 36.0224 30.6698 35.9937 30.6738 35.9655C30.6793 35.9061 30.7063 35.8509 30.7498 35.8105C30.7932 35.77 30.8499 35.7471 30.909 35.7461H33.2914C33.3193 35.7455 33.347 35.7509 33.3727 35.762C33.3983 35.773 33.4213 35.7895 33.4401 35.8103C33.4589 35.8311 33.4731 35.8557 33.4817 35.8825C33.4902 35.9092 33.493 35.9375 33.4898 35.9655C33.485 36.0256 33.4584 36.0818 33.4149 36.1232C33.3715 36.1646 33.3144 36.1883 33.2546 36.1897Z" fill="#263238"/>
<path opacity="0.3" d="M34.128 35.3277H30.9456C30.9179 35.3283 30.8903 35.323 30.8648 35.312C30.8392 35.3011 30.8163 35.2847 30.7975 35.2641C30.7788 35.2435 30.7646 35.2191 30.7559 35.1925C30.7472 35.166 30.7442 35.1378 30.7472 35.11C30.7534 35.0506 30.7809 34.9956 30.8245 34.9552C30.8681 34.9148 30.9248 34.8919 30.984 34.8906H34.1664C34.1942 34.8903 34.2218 34.8958 34.2474 34.907C34.2729 34.9181 34.2958 34.9346 34.3145 34.9553C34.3333 34.9761 34.3475 35.0006 34.3561 35.0272C34.3648 35.0539 34.3678 35.0821 34.3648 35.11C34.3586 35.1692 34.331 35.224 34.2874 35.2641C34.2438 35.3043 34.1871 35.3269 34.128 35.3277Z" fill="white"/>
<path opacity="0.3" d="M33.2546 36.1897H30.8706C30.8424 36.1902 30.8144 36.1845 30.7885 36.1731C30.7627 36.1616 30.7396 36.1446 30.7209 36.1233C30.7022 36.102 30.6883 36.0769 30.6802 36.0496C30.672 36.0224 30.6698 35.9937 30.6738 35.9655C30.6793 35.9061 30.7063 35.8509 30.7498 35.8105C30.7932 35.77 30.8499 35.7471 30.909 35.7461H33.2914C33.3193 35.7455 33.347 35.7509 33.3727 35.762C33.3983 35.773 33.4213 35.7895 33.4401 35.8103C33.4589 35.8311 33.4731 35.8557 33.4817 35.8825C33.4902 35.9092 33.493 35.9375 33.4898 35.9655C33.485 36.0256 33.4584 36.0818 33.4149 36.1232C33.3715 36.1646 33.3144 36.1883 33.2546 36.1897Z" fill="white"/>
<path d="M36.0565 35.5394C36.002 35.5404 35.9478 35.5297 35.8976 35.508C35.8474 35.4863 35.8024 35.4541 35.7655 35.4136C35.7286 35.373 35.7007 35.325 35.6835 35.2728C35.6664 35.2205 35.6605 35.1652 35.6661 35.1104C35.6789 34.9943 35.7328 34.8867 35.8181 34.8075C35.9034 34.7283 36.0143 34.683 36.1302 34.6798C36.185 34.6785 36.2394 34.6891 36.2898 34.7108C36.3403 34.7325 36.3855 34.7648 36.4225 34.8056C36.4594 34.8464 36.4873 34.8948 36.5043 34.9473C36.5212 34.9999 36.5267 35.0555 36.5206 35.1104C36.5075 35.2263 36.4533 35.3335 36.3681 35.4123C36.2828 35.4912 36.1722 35.5363 36.0565 35.5394Z" fill="#407BFF"/>
<path opacity="0.3" d="M36.0565 35.5394C36.002 35.5404 35.9478 35.5297 35.8976 35.508C35.8474 35.4863 35.8024 35.4541 35.7655 35.4136C35.7286 35.373 35.7007 35.325 35.6835 35.2728C35.6664 35.2205 35.6605 35.1652 35.6661 35.1104C35.6789 34.9943 35.7328 34.8867 35.8181 34.8075C35.9034 34.7283 36.0143 34.683 36.1302 34.6798C36.185 34.6785 36.2394 34.6891 36.2898 34.7108C36.3403 34.7325 36.3855 34.7648 36.4225 34.8056C36.4594 34.8464 36.4873 34.8948 36.5043 34.9473C36.5212 34.9999 36.5267 35.0555 36.5206 35.1104C36.5075 35.2263 36.4533 35.3335 36.3681 35.4123C36.2828 35.4912 36.1722 35.5363 36.0565 35.5394Z" fill="black"/>
<path d="M40.421 35.3277H37.2386C37.2108 35.3283 37.1833 35.323 37.1577 35.312C37.1322 35.3011 37.1093 35.2847 37.0905 35.2641C37.0717 35.2435 37.0575 35.2191 37.0488 35.1925C37.0402 35.166 37.0372 35.1378 37.0402 35.11C37.0464 35.0506 37.0739 34.9956 37.1175 34.9552C37.1611 34.9148 37.2178 34.8919 37.277 34.8906H40.4594C40.4872 34.8903 40.5148 34.8958 40.5403 34.907C40.5659 34.9181 40.5888 34.9346 40.6075 34.9553C40.6263 34.9761 40.6404 35.0006 40.6491 35.0272C40.6578 35.0539 40.6607 35.0821 40.6578 35.11C40.6515 35.1692 40.624 35.224 40.5804 35.2641C40.5367 35.3043 40.48 35.3269 40.421 35.3277Z" fill="#263238"/>
<path d="M39.5485 36.1897H37.1661C37.1373 36.1912 37.1086 36.1862 37.082 36.1752C37.0553 36.1641 37.0315 36.1473 37.012 36.1259C36.9926 36.1045 36.9781 36.079 36.9696 36.0512C36.9611 36.0235 36.9588 35.9942 36.9629 35.9655C36.9684 35.9061 36.9954 35.8509 37.0388 35.8105C37.0823 35.77 37.139 35.7471 37.1981 35.7461H39.5805C39.6084 35.7455 39.6361 35.7509 39.6617 35.762C39.6874 35.773 39.7104 35.7895 39.7292 35.8103C39.748 35.8311 39.7622 35.8557 39.7707 35.8825C39.7793 35.9092 39.7821 35.9375 39.7789 35.9655C39.7746 36.0249 39.7487 36.0806 39.7062 36.122C39.6637 36.1634 39.6075 36.1875 39.5485 36.1897Z" fill="#263238"/>
<path opacity="0.3" d="M40.421 35.3277H37.2386C37.2108 35.3283 37.1833 35.323 37.1577 35.312C37.1322 35.3011 37.1093 35.2847 37.0905 35.2641C37.0717 35.2435 37.0575 35.2191 37.0488 35.1925C37.0402 35.166 37.0372 35.1378 37.0402 35.11C37.0464 35.0506 37.0739 34.9956 37.1175 34.9552C37.1611 34.9148 37.2178 34.8919 37.277 34.8906H40.4594C40.4872 34.8903 40.5148 34.8958 40.5403 34.907C40.5659 34.9181 40.5888 34.9346 40.6075 34.9553C40.6263 34.9761 40.6404 35.0006 40.6491 35.0272C40.6578 35.0539 40.6607 35.0821 40.6578 35.11C40.6515 35.1692 40.624 35.224 40.5804 35.2641C40.5367 35.3043 40.48 35.3269 40.421 35.3277Z" fill="white"/>
<path opacity="0.3" d="M39.5485 36.1897H37.1661C37.1373 36.1912 37.1086 36.1862 37.082 36.1752C37.0553 36.1641 37.0315 36.1473 37.012 36.1259C36.9926 36.1045 36.9781 36.079 36.9696 36.0512C36.9611 36.0235 36.9588 35.9942 36.9629 35.9655C36.9684 35.9061 36.9954 35.8509 37.0388 35.8105C37.0823 35.77 37.139 35.7471 37.1981 35.7461H39.5805C39.6084 35.7455 39.6361 35.7509 39.6617 35.762C39.6874 35.773 39.7104 35.7895 39.7292 35.8103C39.748 35.8311 39.7622 35.8557 39.7707 35.8825C39.7793 35.9092 39.7821 35.9375 39.7789 35.9655C39.7746 36.0249 39.7487 36.0806 39.7062 36.122C39.6637 36.1634 39.6075 36.1875 39.5485 36.1897Z" fill="white"/>
<path d="M42.3481 35.5394C42.2936 35.5404 42.2395 35.5297 42.1894 35.508C42.1392 35.4863 42.0943 35.4541 42.0575 35.4135C42.0207 35.3729 41.993 35.3249 41.976 35.2726C41.9591 35.2203 41.9534 35.165 41.9593 35.1104C41.972 34.9942 42.026 34.8866 42.1113 34.8075C42.1966 34.7283 42.3074 34.6829 42.4233 34.6798C42.478 34.6787 42.5323 34.6895 42.5825 34.7113C42.6327 34.7331 42.6777 34.7655 42.7145 34.8062C42.7513 34.847 42.7791 34.8952 42.7959 34.9477C42.8127 35.0001 42.8183 35.0556 42.8121 35.1104C42.799 35.2263 42.7449 35.3335 42.6596 35.4123C42.5744 35.4911 42.4637 35.5363 42.3481 35.5394Z" fill="#407BFF"/>
<path d="M46.7205 35.3277H43.5301C43.5024 35.3284 43.4749 35.323 43.4495 35.3121C43.424 35.3011 43.4012 35.2847 43.3825 35.2641C43.3639 35.2434 43.3498 35.219 43.3413 35.1924C43.3329 35.1658 43.3301 35.1377 43.3333 35.11C43.3388 35.0506 43.3659 34.9954 43.4093 34.955C43.4527 34.9145 43.5094 34.8916 43.5685 34.8906H46.7509C46.7788 34.8903 46.8064 34.8958 46.8319 34.907C46.8574 34.9181 46.8803 34.9346 46.8991 34.9553C46.9178 34.9761 46.932 35.0006 46.9407 35.0272C46.9493 35.0539 46.9523 35.0821 46.9493 35.11C46.9433 35.1679 46.9169 35.2216 46.8749 35.2616C46.833 35.3015 46.7782 35.325 46.7205 35.3277Z" fill="#263238"/>
<path d="M45.8399 36.1889H43.4575C43.4296 36.1895 43.4019 36.1842 43.3762 36.1731C43.3506 36.162 43.3276 36.1455 43.3088 36.1248C43.29 36.104 43.2758 36.0794 43.2672 36.0526C43.2587 36.0258 43.2559 35.9975 43.2591 35.9695C43.2646 35.9102 43.2917 35.855 43.3351 35.8145C43.3785 35.774 43.4352 35.7511 43.4943 35.7502H45.8783C45.9062 35.7489 45.934 35.7537 45.9598 35.7642C45.9857 35.7746 46.009 35.7906 46.0283 35.8109C46.0475 35.8312 46.0623 35.8554 46.0714 35.882C46.0806 35.9085 46.0841 35.9367 46.0815 35.9647C46.077 36.026 46.0496 36.0833 46.0047 36.125C45.9598 36.1666 45.9009 36.1895 45.8399 36.1889Z" fill="#263238"/>
<path opacity="0.3" d="M46.7205 35.3277H43.5301C43.5024 35.3284 43.4749 35.323 43.4495 35.3121C43.424 35.3011 43.4012 35.2847 43.3825 35.2641C43.3639 35.2434 43.3498 35.219 43.3413 35.1924C43.3329 35.1658 43.3301 35.1377 43.3333 35.11C43.3388 35.0506 43.3659 34.9954 43.4093 34.955C43.4527 34.9145 43.5094 34.8916 43.5685 34.8906H46.7509C46.7788 34.8903 46.8064 34.8958 46.8319 34.907C46.8574 34.9181 46.8803 34.9346 46.8991 34.9553C46.9178 34.9761 46.932 35.0006 46.9407 35.0272C46.9493 35.0539 46.9523 35.0821 46.9493 35.11C46.9433 35.1679 46.9169 35.2216 46.8749 35.2616C46.833 35.3015 46.7782 35.325 46.7205 35.3277Z" fill="white"/>
<path opacity="0.3" d="M45.8399 36.1889H43.4575C43.4296 36.1895 43.4019 36.1842 43.3762 36.1731C43.3506 36.162 43.3276 36.1455 43.3088 36.1248C43.29 36.104 43.2758 36.0794 43.2672 36.0526C43.2587 36.0258 43.2559 35.9975 43.2591 35.9695C43.2646 35.9102 43.2917 35.855 43.3351 35.8145C43.3785 35.774 43.4352 35.7511 43.4943 35.7502H45.8783C45.9062 35.7489 45.934 35.7537 45.9598 35.7642C45.9857 35.7746 46.009 35.7906 46.0283 35.8109C46.0475 35.8312 46.0623 35.8554 46.0714 35.882C46.0806 35.9085 46.0841 35.9367 46.0815 35.9647C46.077 36.026 46.0496 36.0833 46.0047 36.125C45.9598 36.1666 45.9009 36.1895 45.8399 36.1889Z" fill="white"/>
<path d="M63.6414 22.9567H53.7022C53.5716 22.959 53.4421 22.9334 53.3221 22.8813C53.2021 22.8293 53.0945 22.7521 53.0064 22.655C52.9183 22.5578 52.8518 22.4428 52.8112 22.3177C52.7706 22.1925 52.7569 22.0601 52.771 21.9293L53.299 15.8439C53.3271 15.5656 53.4555 15.3073 53.6599 15.118C53.8642 14.9287 54.1303 14.8214 54.4078 14.8166H64.347C64.4776 14.8142 64.6072 14.8399 64.7271 14.8919C64.8471 14.9439 64.9547 15.0211 65.0428 15.1183C65.1309 15.2154 65.1974 15.3304 65.238 15.4555C65.2786 15.5807 65.2923 15.7131 65.2782 15.8439L64.7502 21.9357C64.7206 22.2129 64.5916 22.4696 64.3874 22.6576C64.1832 22.8456 63.9179 22.952 63.6414 22.9567Z" fill="#407BFF"/>
<path d="M57.9992 18.4957C57.9481 18.9974 57.7167 19.4631 57.3486 19.8047C56.9805 20.1464 56.5011 20.3405 56.0008 20.3505C55.7652 20.3546 55.5315 20.3082 55.3151 20.2142C55.0987 20.1202 54.9047 19.9808 54.7459 19.8053C54.5871 19.6299 54.4672 19.4223 54.3942 19.1965C54.3212 18.9707 54.2967 18.7318 54.3224 18.4957C54.3728 17.9936 54.604 17.5276 54.9723 17.1858C55.3405 16.844 55.8203 16.6502 56.3208 16.6409C56.5564 16.6367 56.7901 16.6832 57.0065 16.7772C57.2229 16.8712 57.417 17.0105 57.5757 17.186C57.7345 17.3615 57.8544 17.569 57.9274 17.7949C58.0004 18.0207 58.0249 18.2596 57.9992 18.4957Z" fill="#407BFF"/>
<path opacity="0.7" d="M57.9992 18.4957C57.9481 18.9974 57.7167 19.4631 57.3486 19.8047C56.9805 20.1464 56.5011 20.3405 56.0008 20.3505C55.7652 20.3546 55.5315 20.3082 55.3151 20.2142C55.0987 20.1202 54.9047 19.9808 54.7459 19.8053C54.5871 19.6299 54.4672 19.4223 54.3942 19.1965C54.3212 18.9707 54.2967 18.7318 54.3224 18.4957C54.3728 17.9936 54.604 17.5276 54.9723 17.1858C55.3405 16.844 55.8203 16.6502 56.3208 16.6409C56.5564 16.6367 56.7901 16.6832 57.0065 16.7772C57.2229 16.8712 57.417 17.0105 57.5757 17.186C57.7345 17.3615 57.8544 17.569 57.9274 17.7949C58.0004 18.0207 58.0249 18.2596 57.9992 18.4957Z" fill="white"/>
<path d="M56.7166 18.1652C56.6814 18.5716 56.5278 18.9006 56.1246 18.9006C55.7214 18.9006 55.6238 18.578 55.659 18.1652C55.6942 17.7523 55.8494 17.4297 56.2526 17.4297C56.6558 17.4297 56.7518 17.7587 56.7166 18.1652Z" fill="#263238"/>
<path d="M56.1027 19.1328C55.2595 19.1328 54.8019 19.3215 54.5859 19.5989C54.7394 19.8339 54.9492 20.0261 55.1958 20.1577C55.4425 20.2894 55.7181 20.3562 55.9971 20.3521C56.2933 20.3482 56.5849 20.2787 56.8515 20.1485C57.118 20.0184 57.3529 19.8307 57.5395 19.5989C57.3715 19.3215 56.9475 19.1328 56.1027 19.1328Z" fill="#263238"/>
<path d="M62.3323 16.4238H59.3451C59.3127 16.4244 59.2806 16.4181 59.2508 16.4052C59.2211 16.3924 59.1944 16.3732 59.1726 16.3491C59.1507 16.3249 59.1343 16.2964 59.1243 16.2653C59.1143 16.2342 59.1111 16.2014 59.1147 16.1689C59.1218 16.0999 59.1536 16.0358 59.2044 15.9889C59.2551 15.9419 59.3211 15.9153 59.3899 15.9141H62.3771C62.4095 15.9134 62.4417 15.9198 62.4714 15.9326C62.5012 15.9455 62.5279 15.9647 62.5497 15.9888C62.5715 16.0129 62.588 16.0415 62.5979 16.0726C62.6079 16.1036 62.6112 16.1365 62.6075 16.1689C62.6008 16.2381 62.5691 16.3024 62.5183 16.3494C62.4675 16.3964 62.4013 16.4229 62.3323 16.4238Z" fill="white"/>
<path d="M63.1283 18.3082H59.1811C59.1487 18.3088 59.1165 18.3025 59.0868 18.2896C59.057 18.2768 59.0303 18.2576 59.0085 18.2335C58.9867 18.2093 58.9702 18.1808 58.9603 18.1497C58.9503 18.1186 58.947 18.0858 58.9507 18.0533C58.9577 17.9841 58.9895 17.9199 59.0402 17.8727C59.0908 17.8254 59.1569 17.7985 59.2259 17.7969H63.1731C63.2055 17.7965 63.2377 17.803 63.2675 17.8161C63.2972 17.8291 63.3239 17.8484 63.3457 17.8727C63.3675 17.8969 63.3839 17.9256 63.3939 17.9567C63.4038 17.9879 63.4071 18.0208 63.4035 18.0533C63.3968 18.1225 63.365 18.1868 63.3142 18.2338C63.2634 18.2808 63.1972 18.3073 63.1283 18.3082Z" fill="white"/>
<path d="M63.8462 19.248H59.099C59.0667 19.2487 59.0345 19.2423 59.0047 19.2295C58.975 19.2166 58.9483 19.1974 58.9265 19.1733C58.9046 19.1492 58.8882 19.1206 58.8782 19.0895C58.8683 19.0585 58.865 19.0256 58.8686 18.9932C58.8757 18.9241 58.9076 18.8601 58.9583 18.8131C59.009 18.7661 59.075 18.7395 59.1438 18.7383H63.891C63.9234 18.7377 63.9556 18.744 63.9853 18.7569C64.0151 18.7698 64.0418 18.7889 64.0636 18.813C64.0854 18.8372 64.1019 18.8657 64.1119 18.8968C64.1218 18.9279 64.1251 18.9607 64.1214 18.9932C64.1147 19.0623 64.083 19.1266 64.0322 19.1736C63.9814 19.2206 63.9152 19.2471 63.8462 19.248Z" fill="white"/>
<path d="M63.4442 20.1871H59.017C58.9845 20.1875 58.9524 20.181 58.9226 20.1679C58.8928 20.1549 58.8662 20.1356 58.8444 20.1113C58.8226 20.0871 58.8062 20.0584 58.7962 20.0273C58.7862 19.9961 58.783 19.9632 58.7866 19.9307C58.794 19.8617 58.8259 19.7979 58.8766 19.751C58.9272 19.7041 58.9931 19.6774 59.0618 19.6758H63.489C63.5214 19.6752 63.5535 19.6815 63.5833 19.6944C63.6131 19.7073 63.6398 19.7264 63.6616 19.7505C63.6834 19.7747 63.6998 19.8032 63.7098 19.8343C63.7198 19.8654 63.7231 19.8982 63.7194 19.9307C63.7127 20 63.681 20.0644 63.6303 20.1117C63.5795 20.159 63.5133 20.1858 63.4442 20.1871Z" fill="white"/>
<path d="M62.8813 21.1285H58.9389C58.9065 21.1292 58.8743 21.1228 58.8446 21.1099C58.8148 21.0971 58.7881 21.0779 58.7663 21.0538C58.7445 21.0296 58.728 21.0011 58.7181 20.97C58.7081 20.939 58.7048 20.9061 58.7085 20.8737C58.7151 20.8043 58.7469 20.7399 58.7976 20.6926C58.8484 20.6453 58.9146 20.6185 58.9837 20.6172H62.9309C62.9633 20.6168 62.9955 20.6233 63.0253 20.6364C63.0551 20.6494 63.0817 20.6687 63.1035 20.693C63.1253 20.7173 63.1417 20.7459 63.1517 20.7771C63.1616 20.8082 63.1649 20.8411 63.1613 20.8737C63.1542 20.9435 63.1216 21.0082 63.0699 21.0553C63.0182 21.1024 62.951 21.1285 62.8813 21.1285Z" fill="white"/>
<path d="M63.284 22.0683H58.8569C58.8245 22.069 58.7923 22.0627 58.7625 22.0498C58.7328 22.0369 58.7061 22.0177 58.6843 21.9936C58.6625 21.9695 58.646 21.9409 58.636 21.9098C58.6261 21.8788 58.6228 21.8459 58.6265 21.8135C58.6331 21.7443 58.6649 21.68 58.7157 21.633C58.7665 21.586 58.8327 21.5595 58.9017 21.5586H63.3288C63.3612 21.558 63.3934 21.5643 63.4232 21.5772C63.4529 21.5901 63.4796 21.6092 63.5014 21.6333C63.5232 21.6575 63.5397 21.686 63.5497 21.7171C63.5596 21.7482 63.5629 21.781 63.5592 21.8135C63.5519 21.8824 63.5199 21.9462 63.4693 21.9931C63.4186 22.04 63.3528 22.0667 63.284 22.0683Z" fill="white"/>
<path d="M50.7201 22.9562H33.6241C33.4935 22.9585 33.3639 22.9328 33.244 22.8808C33.124 22.8288 33.0164 22.7516 32.9283 22.6545C32.8402 22.5573 32.7737 22.4423 32.7331 22.3172C32.6925 22.192 32.6788 22.0596 32.6929 21.9288L33.6433 10.9807C33.6714 10.7023 33.7998 10.4441 34.0041 10.2547C34.2085 10.0654 34.4746 9.95815 34.7521 9.95328H51.8401C51.9706 9.95088 52.1001 9.97655 52.22 10.0286C52.3399 10.0806 52.4474 10.1578 52.5354 10.255C52.6234 10.3523 52.6898 10.4673 52.7302 10.5924C52.7706 10.7175 52.784 10.8499 52.7697 10.9807L51.8273 21.9352C51.7974 22.212 51.6685 22.4683 51.4647 22.6562C51.2609 22.8441 50.9962 22.9507 50.7201 22.9562Z" fill="#407BFF"/>
<path opacity="0.7" d="M50.7201 22.9562H33.6241C33.4935 22.9585 33.3639 22.9328 33.244 22.8808C33.124 22.8288 33.0164 22.7516 32.9283 22.6545C32.8402 22.5573 32.7737 22.4423 32.7331 22.3172C32.6925 22.192 32.6788 22.0596 32.6929 21.9288L33.6433 10.9807C33.6714 10.7023 33.7998 10.4441 34.0041 10.2547C34.2085 10.0654 34.4746 9.95815 34.7521 9.95328H51.8401C51.9706 9.95088 52.1001 9.97655 52.22 10.0286C52.3399 10.0806 52.4474 10.1578 52.5354 10.255C52.6234 10.3523 52.6898 10.4673 52.7302 10.5924C52.7706 10.7175 52.784 10.8499 52.7697 10.9807L51.8273 21.9352C51.7974 22.212 51.6685 22.4683 51.4647 22.6562C51.2609 22.8441 50.9962 22.9507 50.7201 22.9562Z" fill="white"/>
<path d="M52.4172 15.1169C52.086 13.9121 51.5628 12.7057 50.8012 12.7057C49.3612 12.7057 48.662 14.9766 47.9212 17.383C47.2684 19.4958 46.5932 21.6796 45.5404 21.6796C44.2044 21.6796 43.838 18.9781 43.4844 16.3653C43.0876 13.4379 42.7132 10.6719 40.9916 10.6719C39.27 10.6719 38.4172 13.4379 37.5116 16.3653C36.7036 18.9781 35.87 21.6796 34.5324 21.6796C33.9084 21.6796 33.3276 19.8184 33.022 18.1636L32.8828 19.7765C33.2156 20.9716 33.7356 22.1603 34.4908 22.1603C36.2124 22.1603 37.0668 19.3942 37.9708 16.4669C38.7708 13.8557 39.614 11.1525 40.95 11.1525C42.286 11.1525 42.6524 13.8557 43.006 16.4749C43.4028 19.4023 43.7772 22.1683 45.4988 22.1683C46.9388 22.1683 47.638 19.8974 48.3788 17.491C49.0316 15.3782 49.7068 13.1944 50.7612 13.1944C51.39 13.1944 51.9756 15.0814 52.278 16.7427L52.4172 15.1169Z" fill="#407BFF"/>
<path d="M41.5127 11.3121C41.8013 11.0337 41.8301 10.592 41.5771 10.3257C41.3241 10.0593 40.8852 10.069 40.5966 10.3474C40.3081 10.6259 40.2793 11.0675 40.5323 11.3339C40.7853 11.6003 41.2242 11.5905 41.5127 11.3121Z" fill="#263238"/>
<path d="M41.2812 11.066C41.4241 10.928 41.438 10.7087 41.3121 10.5762C41.1862 10.4436 40.9682 10.4481 40.8253 10.5861C40.6823 10.7241 40.6685 10.9434 40.7944 11.0759C40.9203 11.2084 41.1382 11.204 41.2812 11.066Z" fill="#407BFF"/>
<path d="M42.5096 7.87925H40.1096C39.9841 7.88169 39.8638 7.93023 39.7713 8.01576C39.6788 8.10129 39.6205 8.21792 39.6072 8.34376L39.5688 8.78568C39.5625 8.84483 39.5687 8.90467 39.587 8.96122C39.6053 9.01777 39.6354 9.06974 39.6752 9.11368C39.715 9.15761 39.7636 9.19251 39.8179 9.21604C39.8721 9.23958 39.9306 9.25122 39.9896 9.25018H40.7896L41.028 9.61308C41.0438 9.63443 41.0643 9.65177 41.0879 9.66372C41.1116 9.67567 41.1376 9.68189 41.164 9.68189C41.1905 9.68189 41.2165 9.67567 41.2401 9.66372C41.2637 9.65177 41.2842 9.63443 41.3 9.61308L41.6008 9.25018H42.4008C42.5262 9.2477 42.6463 9.1991 42.7385 9.11354C42.8308 9.02798 42.8888 8.91137 42.9016 8.78568L42.94 8.34376C42.9465 8.28377 42.9401 8.22308 42.9212 8.16583C42.9023 8.10857 42.8714 8.05611 42.8305 8.01201C42.7896 7.96791 42.7398 7.93322 42.6844 7.91031C42.6291 7.8874 42.5694 7.87681 42.5096 7.87925Z" fill="#407BFF"/>
<path d="M42.3216 8.46365H40.1408C40.1255 8.464 40.1102 8.46101 40.0961 8.45489C40.082 8.44878 40.0694 8.43967 40.059 8.4282C40.0487 8.41673 40.041 8.40316 40.0363 8.38841C40.0316 8.37365 40.0302 8.35806 40.032 8.34269C40.0365 8.31129 40.0518 8.28247 40.0751 8.26125C40.0985 8.24003 40.1286 8.22775 40.16 8.22656H42.3392C42.3545 8.22648 42.3696 8.22965 42.3836 8.23585C42.3976 8.24205 42.4101 8.25116 42.4204 8.26257C42.4307 8.27398 42.4384 8.28744 42.4432 8.30208C42.4479 8.31672 42.4496 8.33221 42.448 8.34753C42.4442 8.37892 42.4294 8.4079 42.4062 8.42921C42.383 8.45051 42.353 8.46273 42.3216 8.46365Z" fill="#263238"/>
<path d="M41.1 8.90211H40.1032C40.0878 8.90242 40.0724 8.89943 40.0583 8.89333C40.0441 8.88722 40.0314 8.87815 40.0209 8.86671C40.0105 8.85528 40.0026 8.84173 39.9977 8.82698C39.9929 8.81223 39.9912 8.79661 39.9928 8.78115C39.9959 8.7482 40.0111 8.7176 40.0353 8.69526C40.0596 8.67293 40.0911 8.66043 40.124 8.66018H41.1192C41.1345 8.65984 41.1498 8.66283 41.1639 8.66895C41.178 8.67506 41.1906 8.68416 41.201 8.69563C41.2113 8.7071 41.219 8.72068 41.2237 8.73543C41.2283 8.75018 41.2298 8.76577 41.228 8.78115C41.2245 8.81342 41.2097 8.84339 41.1863 8.86559C41.1628 8.88779 41.1321 8.90075 41.1 8.90211Z" fill="#263238"/>
<path d="M45.8917 22.0934C46.1802 21.815 46.209 21.3733 45.956 21.1069C45.703 20.8405 45.2641 20.8503 44.9755 21.1287C44.687 21.4071 44.6582 21.8488 44.9112 22.1152C45.1642 22.3815 45.6031 22.3718 45.8917 22.0934Z" fill="#263238"/>
<path d="M45.7769 21.6127C45.7675 21.7064 45.7244 21.7934 45.6557 21.8573C45.587 21.9212 45.4975 21.9575 45.4041 21.9594C45.3595 21.9614 45.3149 21.9535 45.2736 21.9364C45.2323 21.9193 45.1952 21.8934 45.1647 21.8605C45.1343 21.8275 45.1113 21.7883 45.0974 21.7455C45.0835 21.7027 45.0789 21.6574 45.0841 21.6127C45.0934 21.5189 45.1365 21.4319 45.2052 21.368C45.2739 21.3041 45.3634 21.2678 45.4569 21.2659C45.5015 21.264 45.546 21.2718 45.5873 21.2889C45.6287 21.306 45.6658 21.3319 45.6962 21.3649C45.7266 21.3979 45.7496 21.4371 45.7635 21.4799C45.7775 21.5226 45.782 21.568 45.7769 21.6127Z" fill="#407BFF"/>
<path d="M46.891 18.6602H44.491C44.3657 18.6627 44.2456 18.7113 44.1534 18.7968C44.0611 18.8824 44.0031 18.999 43.9902 19.1247L43.9518 19.565C43.9452 19.6243 43.9512 19.6843 43.9695 19.741C43.9877 19.7978 44.0177 19.85 44.0575 19.8941C44.0974 19.9382 44.1461 19.9732 44.2004 19.9969C44.2547 20.0205 44.3135 20.0322 44.3726 20.0311H45.1646L45.403 20.394C45.4192 20.4151 45.4399 20.4322 45.4636 20.4439C45.4873 20.4557 45.5134 20.4618 45.5398 20.4618C45.5662 20.4618 45.5923 20.4557 45.616 20.4439C45.6398 20.4322 45.6605 20.4151 45.6766 20.394L45.9774 20.0311H46.7694C46.8953 20.029 47.016 19.9803 47.1086 19.8944C47.2012 19.8085 47.2593 19.6913 47.2718 19.565L47.3102 19.1247C47.3166 19.0657 47.3104 19.006 47.2922 18.9495C47.2739 18.8931 47.244 18.8412 47.2044 18.7973C47.1647 18.7534 47.1163 18.7185 47.0623 18.6948C47.0083 18.6712 46.9499 18.6594 46.891 18.6602Z" fill="#263238"/>
<path d="M46.7005 19.2419H44.5197C44.5044 19.2423 44.4891 19.2393 44.475 19.2332C44.4609 19.2271 44.4483 19.218 44.4379 19.2065C44.4276 19.195 44.4199 19.1814 44.4152 19.1667C44.4106 19.1519 44.4091 19.1363 44.4109 19.121C44.4141 19.0883 44.429 19.0579 44.4529 19.0356C44.4768 19.0133 44.508 19.0006 44.5405 19H46.7181C46.7334 18.9999 46.7485 19.0031 46.7625 19.0093C46.7765 19.0155 46.789 19.0246 46.7993 19.036C46.8096 19.0474 46.8173 19.0609 46.8221 19.0755C46.8269 19.0902 46.8285 19.1056 46.8269 19.121C46.8242 19.1532 46.8098 19.1834 46.7865 19.2057C46.7632 19.228 46.7326 19.2409 46.7005 19.2419Z" fill="white"/>
<path d="M45.4823 19.6755H44.4807C44.4653 19.6759 44.4501 19.6729 44.4359 19.6668C44.4218 19.6607 44.4092 19.6516 44.3989 19.6401C44.3886 19.6286 44.3808 19.615 44.3761 19.6003C44.3715 19.5855 44.37 19.57 44.3719 19.5546C44.3753 19.522 44.3904 19.4919 44.4142 19.4696C44.438 19.4474 44.469 19.4346 44.5015 19.4336H45.4999C45.5153 19.4333 45.5306 19.4363 45.5448 19.4424C45.5589 19.4485 45.5717 19.4576 45.5821 19.469C45.5926 19.4805 45.6005 19.494 45.6053 19.5087C45.6102 19.5235 45.6119 19.5391 45.6103 19.5546C45.6072 19.587 45.5925 19.6171 45.5689 19.6394C45.5453 19.6617 45.5145 19.6745 45.4823 19.6755Z" fill="white"/>
<path d="M51.4816 12.9039C51.4628 13.0929 51.3759 13.2684 51.2373 13.3972C51.0986 13.5259 50.918 13.5989 50.7296 13.6023C50.6408 13.604 50.5528 13.5866 50.4712 13.5512C50.3897 13.5159 50.3166 13.4634 50.2568 13.3973C50.1969 13.3312 50.1518 13.253 50.1243 13.1679C50.0969 13.0828 50.0878 12.9928 50.0976 12.9039C50.117 12.7153 50.2042 12.5404 50.3427 12.412C50.4812 12.2837 50.6615 12.2108 50.8496 12.2071C50.9382 12.2055 51.0261 12.2228 51.1075 12.2581C51.1889 12.2934 51.2619 12.3457 51.3217 12.4116C51.3815 12.4775 51.4267 12.5555 51.4543 12.6404C51.4818 12.7253 51.4911 12.8151 51.4816 12.9039Z" fill="#263238"/>
<path d="M51.1323 12.904C51.123 12.9978 51.0799 13.0848 51.0112 13.1487C50.9425 13.2126 50.853 13.2489 50.7595 13.2508C50.7149 13.2527 50.6704 13.2449 50.6291 13.2278C50.5878 13.2107 50.5506 13.1848 50.5202 13.1518C50.4898 13.1188 50.4668 13.0796 50.4529 13.0368C50.439 12.9941 50.4344 12.9487 50.4395 12.904C50.4493 12.8106 50.4925 12.7239 50.5612 12.6604C50.6298 12.5968 50.7191 12.5607 50.8123 12.5589C50.8568 12.557 50.9012 12.5648 50.9424 12.5818C50.9836 12.5988 51.0206 12.6245 51.051 12.6573C51.0814 12.6901 51.1044 12.7291 51.1185 12.7717C51.1325 12.8143 51.1372 12.8594 51.1323 12.904Z" fill="#407BFF"/>
<path d="M52.244 9.95326H49.844C49.7184 9.95535 49.5979 10.0038 49.5053 10.0894C49.4128 10.175 49.3545 10.2918 49.3416 10.4178L49.3032 10.8597C49.2968 10.9188 49.303 10.9787 49.3214 11.0352C49.3397 11.0918 49.3698 11.1437 49.4096 11.1877C49.4494 11.2316 49.498 11.2665 49.5522 11.29C49.6064 11.3136 49.665 11.3252 49.724 11.3242H50.524L50.7624 11.6871C50.7782 11.7084 50.7987 11.7258 50.8223 11.7377C50.8459 11.7497 50.872 11.7559 50.8984 11.7559C50.9248 11.7559 50.9509 11.7497 50.9745 11.7377C50.9981 11.7258 51.0186 11.7084 51.0344 11.6871L51.3352 11.3242H52.1272C52.2528 11.3221 52.3733 11.2737 52.4659 11.1881C52.5585 11.1025 52.6167 10.9856 52.6296 10.8597L52.668 10.4178C52.6744 10.3583 52.6681 10.2982 52.6496 10.2414C52.6311 10.1846 52.6007 10.1325 52.5606 10.0885C52.5204 10.0445 52.4714 10.0097 52.4168 9.98638C52.3622 9.96305 52.3033 9.95176 52.244 9.95326Z" fill="#407BFF"/>
<path d="M52.056 10.5388H49.8752C49.8598 10.5391 49.8446 10.5362 49.8305 10.53C49.8164 10.5239 49.8037 10.5148 49.7934 10.5034C49.7831 10.4919 49.7753 10.4783 49.7707 10.4636C49.766 10.4488 49.7646 10.4332 49.7664 10.4178C49.7695 10.3852 49.7845 10.3548 49.8084 10.3325C49.8323 10.3102 49.8634 10.2975 49.896 10.2969H52.0768C52.0921 10.2968 52.1072 10.3 52.1212 10.3062C52.1352 10.3124 52.1477 10.3215 52.158 10.3329C52.1682 10.3443 52.176 10.3578 52.1808 10.3724C52.1855 10.387 52.1872 10.4025 52.1856 10.4178C52.1828 10.4506 52.168 10.4812 52.144 10.5036C52.12 10.526 52.0887 10.5385 52.056 10.5388Z" fill="#263238"/>
<path d="M50.8382 10.9763H49.8398C49.8246 10.9764 49.8094 10.9732 49.7955 10.967C49.7815 10.9608 49.7689 10.9517 49.7587 10.9403C49.7484 10.9289 49.7406 10.9154 49.7359 10.9008C49.7311 10.8862 49.7295 10.8707 49.731 10.8554C49.7342 10.8224 49.7494 10.7918 49.7736 10.7695C49.7978 10.7471 49.8294 10.7346 49.8622 10.7344H50.859C50.8745 10.7341 50.8898 10.7371 50.9039 10.7432C50.9181 10.7493 50.9309 10.7584 50.9413 10.7698C50.9517 10.7812 50.9596 10.7948 50.9645 10.8095C50.9694 10.8243 50.971 10.8399 50.9694 10.8554C50.9659 10.8882 50.9507 10.9186 50.9265 10.9408C50.9024 10.9631 50.871 10.9757 50.8382 10.9763Z" fill="#263238"/>
<path d="M35.3978 21.6906C35.3786 21.8794 35.2915 22.0545 35.1529 22.1829C35.0144 22.3113 34.8339 22.384 34.6458 22.3874C34.5573 22.3888 34.4696 22.3713 34.3883 22.3359C34.3071 22.3006 34.2343 22.2482 34.1747 22.1823C34.1151 22.1164 34.07 22.0385 34.0426 21.9538C34.0151 21.869 34.0058 21.7793 34.0154 21.6906C34.0341 21.5016 34.1211 21.3261 34.2597 21.1974C34.3983 21.0686 34.5789 20.9957 34.7674 20.9923C34.856 20.9908 34.9439 21.0084 35.0252 21.0438C35.1065 21.0793 35.1795 21.1318 35.2391 21.1979C35.2987 21.2639 35.3437 21.3421 35.3711 21.427C35.3984 21.512 35.4075 21.6019 35.3978 21.6906Z" fill="#263238"/>
<path d="M35.0515 21.6907C35.0418 21.7844 34.9983 21.8713 34.9293 21.9349C34.8603 21.9985 34.7706 22.0344 34.6771 22.0359C34.6327 22.0375 34.5885 22.0295 34.5474 22.0124C34.5064 21.9953 34.4694 21.9696 34.4391 21.9368C34.4088 21.9041 34.3857 21.8652 34.3716 21.8228C34.3575 21.7803 34.3525 21.7353 34.3571 21.6907C34.3669 21.5969 34.4103 21.5098 34.4793 21.4459C34.5482 21.3821 34.6379 21.3458 34.7315 21.344C34.7761 21.3423 34.8205 21.3503 34.8617 21.3675C34.9029 21.3847 34.9399 21.4106 34.9703 21.4435C35.0006 21.4764 35.0236 21.5155 35.0376 21.5582C35.0517 21.6009 35.0564 21.6461 35.0515 21.6907Z" fill="#407BFF"/>
<path d="M36.1606 18.7389H33.7606C33.6351 18.7413 33.5148 18.7898 33.4223 18.8754C33.3297 18.9609 33.2714 19.0775 33.2582 19.2034L33.2214 19.6453C33.2147 19.7045 33.2207 19.7644 33.239 19.8211C33.2572 19.8778 33.2873 19.9299 33.3271 19.9739C33.367 20.0178 33.4157 20.0527 33.4701 20.0762C33.5244 20.0996 33.5831 20.1111 33.6422 20.1098H34.4422L34.6806 20.4727C34.6964 20.494 34.7169 20.5114 34.7405 20.5233C34.7641 20.5353 34.7901 20.5415 34.8166 20.5415C34.843 20.5415 34.869 20.5353 34.8927 20.5233C34.9163 20.5114 34.9368 20.494 34.9526 20.4727L35.255 20.1098H36.055C36.1805 20.1074 36.3008 20.0588 36.3933 19.9733C36.4858 19.8878 36.5441 19.7711 36.5574 19.6453L36.5942 19.2034C36.601 19.143 36.5947 19.082 36.5757 19.0243C36.5567 18.9667 36.5255 18.914 36.4842 18.8697C36.443 18.8255 36.3926 18.7909 36.3367 18.7683C36.2808 18.7457 36.2207 18.7357 36.1606 18.7389Z" fill="#263238"/>
<path d="M35.9738 19.324H33.7946C33.7792 19.3243 33.7639 19.3213 33.7497 19.3152C33.7355 19.3091 33.7228 19.3 33.7123 19.2886C33.7019 19.2771 33.694 19.2636 33.6891 19.2489C33.6843 19.2341 33.6826 19.2185 33.6842 19.203C33.6873 19.1701 33.7025 19.1395 33.7267 19.1171C33.751 19.0948 33.7825 19.0823 33.8154 19.0821H35.9994C36.0148 19.0817 36.0301 19.0847 36.0443 19.0908C36.0585 19.0969 36.0712 19.106 36.0816 19.1175C36.0921 19.1289 36.1 19.1424 36.1048 19.1572C36.1097 19.1719 36.1114 19.1876 36.1098 19.203C36.1062 19.2367 36.0902 19.2677 36.0651 19.2901C36.0399 19.3125 36.0073 19.3246 35.9738 19.324Z" fill="white"/>
<path d="M34.7569 19.7615H33.7617C33.7464 19.7616 33.7313 19.7584 33.7173 19.7522C33.7033 19.746 33.6908 19.7369 33.6805 19.7255C33.6703 19.7141 33.6625 19.7006 33.6578 19.686C33.653 19.6713 33.6513 19.6558 33.6529 19.6405C33.6557 19.6077 33.6705 19.5771 33.6945 19.5548C33.7185 19.5324 33.7499 19.5198 33.7825 19.5196H34.7809C34.7963 19.5192 34.8115 19.5222 34.8256 19.5283C34.8397 19.5344 34.8524 19.5435 34.8627 19.555C34.873 19.5665 34.8808 19.58 34.8854 19.5948C34.8901 19.6096 34.8916 19.6251 34.8897 19.6405C34.8865 19.6737 34.8711 19.7046 34.8466 19.7269C34.822 19.7493 34.79 19.7616 34.7569 19.7615Z" fill="white"/>
<path d="M23.7518 18.9481L23.291 19.7545L22.8382 20.5819C22.5406 21.1351 22.2558 21.6916 21.9966 22.2529C21.867 22.5335 21.7438 22.8125 21.6318 23.0948L21.611 23.1448C21.6115 23.1491 21.6115 23.1534 21.611 23.1577C21.6051 23.1644 21.6002 23.172 21.5966 23.1803C21.5881 23.1945 21.5811 23.2097 21.5758 23.2254C21.5639 23.2862 21.5706 23.3492 21.595 23.4061C21.6836 23.6261 21.8147 23.8262 21.9806 23.9947C22.1763 24.2068 22.3847 24.4066 22.6046 24.5931L22.2302 25.3995C22.0539 25.3505 21.8812 25.2891 21.7134 25.2157C21.5447 25.1426 21.3811 25.058 21.2238 24.9625C20.8646 24.7529 20.5567 24.4646 20.323 24.1189C20.1758 23.899 20.0771 23.6499 20.0334 23.3883C20.0149 23.2427 20.0149 23.0953 20.0334 22.9496C20.0407 22.8773 20.053 22.8057 20.0702 22.7351L20.0958 22.6303L20.1166 22.5674C20.219 22.2367 20.3262 21.9109 20.451 21.5997C20.6923 20.9729 20.9646 20.3587 21.267 19.7594C21.4158 19.461 21.571 19.1658 21.7342 18.8771C21.8974 18.5884 22.0542 18.303 22.2446 18.0078L23.7518 18.9481Z" fill="#FFC3BD"/>
<path d="M22.1946 24.5061L23.0362 24.2852L23.0842 25.6545C23.0842 25.6545 22.4266 25.7529 22.0234 25.3464L22.1946 24.5061Z" fill="#FFC3BD"/>
<path d="M24.2256 24.0547L24.0368 25.2563L23.0832 25.6546L23.0352 24.2853L24.2256 24.0547Z" fill="#FFC3BD"/>
<path d="M27.7071 12.4986C27.7247 12.5921 27.7903 12.6599 27.8511 12.6453C27.9119 12.6308 27.9455 12.547 27.9263 12.4534C27.9071 12.3599 27.8431 12.2921 27.7823 12.3066C27.7215 12.3212 27.6815 12.405 27.7071 12.4986Z" fill="#263238"/>
<path d="M27.7773 12.6602C27.9465 12.9234 28.1505 13.1622 28.3837 13.3698C28.3295 13.4287 28.2632 13.475 28.1894 13.5053C28.1156 13.5357 28.0362 13.5494 27.9565 13.5456L27.7773 12.6602Z" fill="#ED847E"/>
<path d="M27.2614 12.0499C27.2473 12.05 27.2336 12.0448 27.223 12.0354C27.2128 12.0248 27.207 12.0107 27.207 11.9959C27.207 11.9811 27.2128 11.9669 27.223 11.9564C27.282 11.8883 27.3566 11.8358 27.4402 11.8032C27.5238 11.7706 27.614 11.759 27.703 11.7693C27.7177 11.7718 27.7307 11.7802 27.7393 11.7924C27.7478 11.8047 27.7512 11.8198 27.7486 11.8346C27.7461 11.8494 27.7378 11.8625 27.7257 11.8711C27.7135 11.8797 27.6985 11.8831 27.6838 11.8806C27.6142 11.8742 27.5441 11.8846 27.4793 11.9109C27.4144 11.9373 27.3568 11.9789 27.311 12.0322C27.3048 12.0389 27.297 12.044 27.2884 12.0471C27.2797 12.0502 27.2705 12.0511 27.2614 12.0499Z" fill="#263238"/>
<path d="M25.0586 13.8164C25.2186 14.6825 25.3946 16.2712 24.793 16.8502C24.793 16.8502 25.0282 17.7292 26.6266 17.7292C28.3866 17.7292 27.465 16.8502 27.465 16.8502C26.505 16.6196 26.5322 15.9018 26.6986 15.2277L25.0586 13.8164Z" fill="#FFC3BD"/>
<path d="M24.4456 17.0095C24.3176 16.7692 24.1768 16.4934 24.4696 16.4079C24.7896 16.3128 26.8584 16.137 27.4664 16.3144C27.2792 16.6498 27.596 17.0611 27.596 17.0611L24.4456 17.0095Z" fill="#263238"/>
<path d="M19.2411 57.4191C19.2885 57.4362 19.3388 57.4435 19.3891 57.4408C19.4393 57.438 19.4886 57.4252 19.5338 57.403C19.5514 57.3868 19.5634 57.3655 19.5683 57.3421C19.5732 57.3187 19.5706 57.2943 19.5611 57.2724C19.5559 57.2541 19.5467 57.2373 19.5343 57.223C19.5218 57.2088 19.5064 57.1975 19.489 57.1901C19.329 57.1127 18.9659 57.2627 18.9499 57.2691C18.9451 57.2715 18.9411 57.2752 18.9382 57.2798C18.9354 57.2843 18.9339 57.2896 18.9338 57.2949C18.9331 57.3002 18.9341 57.3057 18.9367 57.3103C18.9393 57.315 18.9434 57.3187 18.9483 57.3208C19.0421 57.3638 19.1403 57.3968 19.2411 57.4191ZM19.433 57.2288L19.4651 57.2401C19.4754 57.2444 19.4846 57.251 19.4921 57.2594C19.4995 57.2678 19.5051 57.2777 19.5082 57.2885C19.5227 57.3385 19.5083 57.3546 19.4971 57.3595C19.4347 57.4143 19.1995 57.3595 19.0347 57.2966C19.1612 57.2461 19.2972 57.224 19.433 57.232V57.2288Z" fill="#407BFF"/>
<path d="M18.9544 57.3235H18.9688C19.1016 57.2848 19.3864 57.08 19.3896 56.9461C19.3897 56.925 19.3826 56.9045 19.3695 56.8881C19.3564 56.8716 19.3381 56.8602 19.3176 56.8558C19.2975 56.8491 19.2762 56.8465 19.255 56.8481C19.2339 56.8498 19.2133 56.8557 19.1944 56.8655C19.0344 56.9461 18.9384 57.2751 18.9336 57.288C18.931 57.2921 18.9297 57.2969 18.9297 57.3017C18.9297 57.3066 18.931 57.3113 18.9336 57.3154C18.9361 57.3185 18.9393 57.3209 18.9429 57.3223C18.9466 57.3237 18.9505 57.3241 18.9544 57.3235ZM19.2872 56.9042H19.3C19.3336 56.9155 19.3336 56.9332 19.3336 56.9396C19.3336 57.0187 19.1432 57.1784 19.0136 57.2445C19.0505 57.1142 19.1252 56.9979 19.228 56.9106C19.2466 56.9025 19.2673 56.9003 19.2872 56.9042Z" fill="#407BFF"/>
<path d="M27.9186 57.6212C28.069 57.6212 28.2146 57.6002 28.2642 57.5325C28.2771 57.5144 28.2841 57.4926 28.2841 57.4704C28.2841 57.4481 28.2771 57.4264 28.2642 57.4083C28.2547 57.3921 28.242 57.378 28.2268 57.3669C28.2117 57.3558 28.1945 57.3479 28.1762 57.3438C27.981 57.2905 27.5698 57.526 27.5522 57.5357C27.5475 57.5388 27.5438 57.5431 27.5415 57.5483C27.5393 57.5534 27.5385 57.5592 27.5394 57.5647C27.5404 57.5704 27.543 57.5756 27.547 57.5796C27.5511 57.5837 27.5562 57.5864 27.5618 57.5873C27.6795 57.6096 27.7989 57.621 27.9186 57.6212ZM28.1058 57.3905C28.1234 57.3888 28.1411 57.3888 28.1586 57.3905C28.1813 57.3963 28.2008 57.4108 28.213 57.4309C28.2338 57.4647 28.2258 57.4825 28.213 57.4921C28.157 57.5696 27.861 57.5712 27.6418 57.5389C27.7858 57.4602 27.9432 57.4099 28.1058 57.3905Z" fill="#407BFF"/>
<path d="M27.5651 57.5892H27.5763C27.7187 57.5246 28.0003 57.2666 27.9779 57.1343C27.9779 57.1037 27.9507 57.065 27.8739 57.0569C27.8454 57.0525 27.8162 57.0546 27.7885 57.063C27.7608 57.0714 27.7354 57.0858 27.7139 57.1053C27.5635 57.2279 27.5331 57.5472 27.5315 57.5617C27.5308 57.5664 27.5315 57.5712 27.5335 57.5755C27.5355 57.5798 27.5387 57.5835 27.5427 57.5859C27.5496 57.5896 27.5575 57.5907 27.5651 57.5892ZM27.8515 57.1053H27.8691C27.9187 57.1053 27.9219 57.1311 27.9235 57.1376C27.9363 57.2182 27.7443 57.4198 27.6035 57.5069C27.6181 57.3711 27.674 57.2432 27.7635 57.1408C27.7885 57.1206 27.8195 57.1092 27.8515 57.1085V57.1053Z" fill="#407BFF"/>
<path d="M26.2734 57.5599H27.4494L27.5662 54.8164H26.3902L26.2734 57.5599Z" fill="#FFC3BD"/>
<path d="M17.6445 57.0118L18.7933 57.2618L19.9469 54.707L18.7981 54.457L17.6445 57.0118Z" fill="#FFC3BD"/>
<path d="M18.9037 57.1429L17.6141 56.8623C17.5912 56.8566 17.567 56.8593 17.5459 56.8699C17.5248 56.8806 17.5081 56.8985 17.4989 56.9204L17.0509 57.8994C17.04 57.9233 17.0347 57.9494 17.0352 57.9757C17.0357 58.002 17.0422 58.0278 17.054 58.0512C17.0659 58.0747 17.0828 58.0951 17.1036 58.111C17.1244 58.1268 17.1485 58.1378 17.1741 58.1429C17.6269 58.2332 17.8509 58.2542 18.4189 58.3784C18.7677 58.4542 19.5565 58.6477 20.0381 58.7526C20.5197 58.8574 20.6877 58.3913 20.4989 58.3042C19.6557 57.9155 19.3037 57.5736 19.0749 57.2558C19.0337 57.1981 18.9727 57.1579 18.9037 57.1429Z" fill="#263238"/>
<path d="M27.4414 57.418H26.1614C26.138 57.4181 26.1154 57.4261 26.0971 57.4407C26.0788 57.4553 26.066 57.4757 26.0606 57.4986L25.8286 58.5518C25.8228 58.5773 25.8228 58.6039 25.8286 58.6294C25.8345 58.6549 25.846 58.6787 25.8623 58.6991C25.8787 58.7195 25.8994 58.7358 25.9229 58.7469C25.9465 58.758 25.9722 58.7635 25.9982 58.7631C26.4606 58.755 27.1294 58.7276 27.7118 58.7276C28.3918 58.7276 28.9806 58.7647 29.779 58.7647C30.259 58.7647 30.395 58.2728 30.1934 58.2292C29.2734 58.026 28.523 58.0051 27.7278 57.5099C27.6426 57.4531 27.5435 57.4212 27.4414 57.418Z" fill="#263238"/>
<path d="M22.6909 17.1182C21.5885 17.373 20.6797 20.2068 20.6797 20.2068L22.8925 21.9067C23.4656 21.2924 23.8698 20.538 24.0653 19.7181C24.4445 18.2262 23.8173 16.8569 22.6909 17.1182Z" fill="#407BFF"/>
<path opacity="0.2" d="M23.633 19.2031C23.0346 19.7999 22.833 20.887 22.793 21.8321L22.8906 21.9079C23.4637 21.2927 23.8683 20.5379 24.065 19.7176C24.0874 19.6289 24.1066 19.5402 24.1226 19.4531L23.633 19.2031Z" fill="black"/>
<path d="M29.2818 17.1803C29.2818 17.1803 30.1842 18.0706 29.8434 22.8043C29.7538 24.0559 29.2786 25.5768 29.0322 27.4203H23.5394C23.4946 26.4526 23.621 21.7027 22.6914 17.119C23.3884 16.9838 24.0933 16.8944 24.8018 16.8512C25.6917 16.7996 26.5839 16.7996 27.4738 16.8512C28.0841 16.9132 28.6886 17.0232 29.2818 17.1803Z" fill="#263238"/>
<path opacity="0.6" d="M29.9088 21.1438C29.8048 20.4567 29.6944 19.8535 29.5888 19.3374L29.5536 19.3164L27.832 20.0438C27.9152 21.1228 29.0464 22.2115 29.8336 22.8486C29.8336 22.8341 29.8336 22.8179 29.8336 22.805C29.8784 22.1873 29.9008 21.6373 29.9088 21.1438Z" fill="black"/>
<path opacity="0.2" d="M27.5649 54.8164L27.5041 56.2309H26.3281L26.3889 54.8164H27.5649Z" fill="black"/>
<path opacity="0.2" d="M18.7983 54.457L19.9471 54.707L19.3519 56.0247L18.2031 55.7747L18.7983 54.457Z" fill="black"/>
<path d="M24.5674 12.9364C24.8698 14.0654 24.9882 14.7525 25.6874 15.2251C26.7338 15.9347 28.0346 15.1202 28.0762 13.9154C28.113 12.8316 27.6122 11.1558 26.4042 10.9284C26.1376 10.8757 25.862 10.8927 25.6037 10.9779C25.3455 11.063 25.1132 11.2135 24.929 11.4148C24.7448 11.6162 24.6149 11.8618 24.5516 12.1281C24.4883 12.3945 24.4937 12.6728 24.5674 12.9364Z" fill="#FFC3BD"/>
<path d="M27.1118 27.418C27.1118 27.418 25.7774 36.4742 25.0462 39.866C24.2462 43.5756 19.9438 55.872 19.9438 55.872L17.8398 55.4124C17.8398 55.4124 20.8462 45.3965 21.5198 40.2031C21.819 37.8887 21.8622 33.9581 22.2478 31.0646C22.4301 29.7743 22.8681 28.5341 23.5358 27.418H27.1118Z" fill="#263238"/>
<path d="M20.4475 55.1591L20.0555 55.9252L17.6875 55.4091L17.8875 54.6914L20.4475 55.1591Z" fill="#407BFF"/>
<path opacity="0.6" d="M26.2707 30.6455C24.7283 30.9504 24.8915 36.7486 25.0771 39.7308C25.5219 37.6228 26.1731 33.5664 26.6179 30.6875C26.5103 30.634 26.3878 30.6192 26.2707 30.6455Z" fill="black"/>
<path d="M29.0221 27.418C29.0221 27.418 29.5021 36.2774 29.3757 39.8902C29.2429 43.6498 27.9357 55.9543 27.9357 55.9543H25.9501C25.9501 55.9543 25.8765 43.8691 25.7901 40.1773C25.6925 36.1532 25.2109 27.418 25.2109 27.418H29.0221Z" fill="#263238"/>
<path d="M28.3202 55.1602C28.3282 55.1602 28.2066 55.9924 28.2066 55.9924H25.7906L25.7266 55.2489L28.3202 55.1602Z" fill="#407BFF"/>
<path d="M29.8538 18.2617C30.0346 18.5456 30.2394 18.8633 30.4458 19.1585C30.6522 19.4536 30.865 19.752 31.0858 20.0375C31.5039 20.6031 31.9702 21.1309 32.4794 21.6149C32.6026 21.7294 32.729 21.8358 32.857 21.9374L32.953 22.01C32.9355 22.0006 32.9166 21.9941 32.897 21.9906C32.8554 21.9826 32.8346 21.9906 32.8458 21.9906C32.9401 21.9976 33.0345 21.9792 33.1194 21.9374C33.2592 21.8769 33.3945 21.8063 33.5242 21.7261C34.1201 21.3301 34.685 20.8885 35.2138 20.4052L35.9002 20.9649C35.4839 21.6691 34.9657 22.3067 34.3626 22.8568C34.1915 23.0089 34.0073 23.1455 33.8122 23.2648C33.5659 23.4194 33.291 23.522 33.0042 23.5664C32.6836 23.6126 32.3571 23.5429 32.0826 23.3696L31.9434 23.2809C31.761 23.1616 31.5866 23.0358 31.4202 22.9035C30.7869 22.3848 30.2093 21.8004 29.697 21.16C29.4458 20.8471 29.2042 20.531 28.9786 20.2036C28.753 19.8762 28.5354 19.5585 28.3242 19.1843L29.8538 18.2617Z" fill="#FFC3BD"/>
<path d="M27.5459 18.9725C27.1699 20.5612 30.0211 22.116 30.0211 22.116L31.8723 19.9289C31.3165 19.102 30.6832 18.3307 29.9811 17.6258C28.6851 16.3726 27.8307 17.7628 27.5459 18.9725Z" fill="#407BFF"/>
<path d="M35.0234 20.727L35.4602 19.3125L36.6106 19.8899C36.6106 19.8899 36.4426 20.9221 35.6058 21.1012L35.0234 20.727Z" fill="#FFC3BD"/>
<path d="M35.9217 18.9219L36.9601 19.209L36.6113 19.8912L35.4609 19.3138L35.9217 18.9219Z" fill="#FFC3BD"/>
<path d="M25.7586 14.6087C26.0572 14.5415 26.3305 14.3894 26.546 14.1705C26.7616 13.9515 26.9103 13.6748 26.9746 13.3732C26.9746 13.3732 26.7186 12.6926 27.1986 12.2297C27.6786 11.7668 27.4418 11.262 26.9618 11.1668C26.4818 11.0717 25.7458 11.6507 25.7458 11.6507L25.1602 12.7087L25.3986 13.7764L25.7586 14.6087Z" fill="#263238"/>
<path opacity="0.3" d="M25.7586 14.6087C26.0572 14.5415 26.3305 14.3894 26.546 14.1705C26.7616 13.9515 26.9103 13.6748 26.9746 13.3732C26.9746 13.3732 26.7186 12.6926 27.1986 12.2297C27.6786 11.7668 27.4418 11.262 26.9618 11.1668C26.4818 11.0717 25.7458 11.6507 25.7458 11.6507L25.1602 12.7087L25.3986 13.7764L25.7586 14.6087Z" fill="white"/>
<path d="M26.4838 12.9809C26.5616 13.2639 26.7305 13.5129 26.9638 13.6889C27.2694 13.9179 27.4982 13.6889 27.4758 13.3438C27.4566 13.0212 27.2742 12.5373 26.9366 12.4373C26.599 12.3373 26.4006 12.6406 26.4838 12.9809Z" fill="#FFC3BD"/>
<path d="M25.9179 14.2942C25.6315 13.6055 25.1035 11.8233 26.9691 11.1685C26.9691 11.1685 26.8331 9.44915 27.7403 10.1491C27.7403 10.1491 26.8731 9.03303 26.7531 10.1749C26.7531 10.1749 26.5403 9.78624 26.8603 9.52979C26.8603 9.52979 25.1531 8.95239 25.1931 10.2556C25.146 10.1215 25.1324 9.97789 25.1533 9.83727C25.1742 9.69665 25.2291 9.56337 25.3131 9.44915C25.3131 9.44915 24.9419 9.42657 24.7947 10.0169C24.6475 10.6072 23.1979 10.4846 23.3307 11.8281C23.3307 11.8281 22.0507 13.4507 25.1243 14.8361C25.1243 14.8361 24.7195 15.0877 24.9499 15.4893C24.9918 15.3484 25.062 15.2177 25.1562 15.1054C25.2503 14.993 25.3664 14.9013 25.4971 14.8361C25.4971 14.8361 25.2971 15.2958 25.7243 15.4312C25.6817 15.3125 25.671 15.1844 25.6932 15.0601C25.7154 14.9358 25.7697 14.8196 25.8507 14.7232C25.9014 14.6656 25.9346 14.5946 25.9465 14.5186C25.9584 14.4426 25.9485 14.3647 25.9179 14.2942Z" fill="#263238"/>
<path opacity="0.1" d="M27.0192 15.0011C26.6128 15.0204 26.6368 15.4204 26.6432 15.4849C26.9955 15.4504 27.3254 15.2949 27.5776 15.0446C27.4304 15.0704 27.2736 14.9898 27.0192 15.0011Z" fill="black"/>
<path d="M26.8125 10.9961C26.8125 10.9961 27.0221 10.2171 27.4173 10.3719C27.8125 10.5268 27.5325 11.1574 27.7373 11.4525C27.6424 11.4118 27.5597 11.3468 27.4974 11.2639C27.4351 11.181 27.3953 11.0831 27.3821 10.98C27.3245 10.6122 26.9661 11.1687 26.9661 11.1687L26.8125 10.9961Z" fill="#263238"/>
<path d="M26.9683 14.1162L27.3491 13.6759L27.2035 13.5469H26.7315L26.5859 13.6759L26.9683 14.1162Z" fill="#407BFF"/>
<path opacity="0.2" d="M26.9155 13.5469H26.7315L26.5859 13.6759L26.9683 14.1162L26.7699 13.6759L26.9155 13.5469Z" fill="white"/>
</svg>`

export const collapseIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_639_32230" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
  <rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_639_32230)">
  <path d="M12 15L7 10L17 10L12 15Z" fill="#1C1B1F"/>
</g>
</svg>`