/** imported components

  * BreadcrumbsItem for ===> dynamic breadcrumb component of react.
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * Moment ===> component for the moment date library.
  * useReactToPrint ===> For functional components, use the useReactToPrint hook
  * FacebookShareButton, LinkedinShareButton, TwitterShareButton ===> component from react-share

**/

import React, { useState } from "react";
import {ProfileManageSubscriptionPopup} from './ProfileManageSubscriptionPopup';
//SubscriptionTabPanel component start
export const SubscriptionTabPanel = (props) => {
  //defining variables here
  const subscriptionData = props.subscriptionData;
    if (!subscriptionData.length) {
        return null;
    }
  const cardData = props.subscriptionData[0];  
  const [subscritionValue,setSubscriptionValue] = useState(cardData.subscritionValue);
  const [isManagePopup,setIsManagePopup] = useState(false);
  const [popupType,setPopupType]=useState('subscription'); // it can be subscription and payment
  
  const openMangaePopup=(type='subscription')=>{
        setPopupType(type);
        setIsManagePopup(prv=>true);
  }
  
  const closeMangaePopup=()=>{
    setIsManagePopup(false);
    setPopupType('subscription')
  }
  return (
    <>
      {/* tab wrapper has  */}
      <div className="manage-subscription-wrapper">
        {subscritionValue == 1 ?
        <div className="manage-subscription-card">
            <div className="manage-subscription-card-head">
                <div className="img-left">
                    <img src={require("../../assets/img/profile/manage_subscription.svg").default}/>
                </div>
                <div className="text-right">
                    <span className="bold-text">Manage subscription</span>
                    <p className="sub-text">
                        <span>Next charge on {cardData.plan_period_end}</span>
                        <span>-</span>
                        <span>$500.00</span>
                    </p>

                </div>
            </div>
            <div className="manage-subscription-card-body">
                <div className="subscription-row">
                    <div className="subscription-col">
                        <div className="heading-text">
                            <span>Auto-renew</span>
                            <span className="on-text">ON</span>
                        </div>
                    </div>
                    <div className="subscription-col">
                        <span>Payment method</span>
                    </div>
                </div>
                <div className="subscription-row">
                    <div className="subscription-col">
                        <span className="bottom-text">$500.00 every one year</span>
                    </div>
                    <div className="subscription-col">
                        <div className="visa-text">
                            <img src={require("../../assets/img/profile/col-subscription.svg").default}/>
                            <span>{cardData.brand}**{cardData.last4}</span>
                            <button className="change-btn" onClick={()=>openMangaePopup('subscription')}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
            <button className="subscription-renew-btn subscription-btn">
               <div className="left-section">
                    <img src={require("../../assets/img/profile/renew-icon.svg").default}/> 
                    <span>Turn off auto-renew</span>
               </div> 
                <img className="right-icon" src={require("../../assets/img/profile/subs-angle-icon.svg").default}/>
            </button>
            <button className="subscription-cancel-btn subscription-btn">
                <div className="left-section">
                    <img src={require("../../assets/img/profile/subs-close-icon.svg").default}/> 
                    <span>Turn off auto-renew</span>
               </div> 
                <img className="right-icon" src={require("../../assets/img/profile/subs-angle-icon.svg").default}/>
            </button>
        </div>
        :
        <div className="manage-blank-subscription">
            <img src={require("../../assets/img/profile/blank-subscription-svg.svg").default} />
            <p>You don’t have any active subscription to manage</p>
        </div>
        }
      </div>

      <div className="turn-off-popup-wrapper">
        <div className="turn-off-popup-wrap">
            <div className="turn-off-pophead">
                <span>Turn off auto-renew</span>
                <button className="close-btn">
                    <img src={require("../../assets/img/profile/turn-off-close.svg").default}/>
                </button>
            </div>
            <div className="turn-off-popbody">
                <p>You will not have access to course if the subscription is not renewed on 23rd January, 2025. Would you like to proceed?</p>
            </div>
            <div className="turn-off-popfooter">
                <button className="cancel-btn">Cancel</button>
                <button className="ok-btn">Okay, proceed</button>
            </div>
        </div>
      </div>
      
      <ProfileManageSubscriptionPopup open={isManagePopup} setRes={props.setRes} closePopup={closeMangaePopup} type={popupType}/>
    </>
  );
};

