/** imported components


**/
import React, { useState, useEffect, useRef } from "react";
import PrepareHomeView from "./home-walkthrough-view";
import PoweBiChartView from './powerBI-chart-view';
import StepFirstView from './step1-view';
import StepScndView from './step2-view';
import StepThirdView from './step3-view';
import StepFourView from './step4-view';
import { ConfiguratorWalkthroughView } from "./configurator-walkthrough-view";
import { Helmet } from "react-helmet";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
export const LearningProductTourView = (props) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [uploadCss, setUploadCss] = useState(false);


  useEffect(() => {
    setUploadCss(true);
    return () => {
      setUploadCss(false)
    }
  }, [])
  return (
    <>
      <Helmet>
        {uploadCss && <link href={require("./roadmap-css/bootstrap.min.css").default} rel="stylesheet" />}
        {uploadCss && <link href={require("./roadmap-css/bc-automation-custom.css").default} rel="stylesheet" />}
        {uploadCss && <link href={require("./roadmap-css/bc-automation-v1.css").default} rel="stylesheet" />}
        {uploadCss && <link href={require("./roadmap-css/custom.css").default} rel="stylesheet" />}
        {uploadCss && <link href={require("./roadmap-css/plant-admin.css").default} rel="stylesheet" />}
        {uploadCss && <link href={require("./roadmap-css/sm-admin-custom.css").default} rel="stylesheet" />}
        {uploadCss && <link href={require("./roadmap-css/styles.css").default} rel="stylesheet" />}
        {uploadCss && <link href={require("./roadmap-css/team.css").default} />}
      </Helmet>
      {
        slideIndex == 0 ?
          <BreadcrumbsItem to="#1">Home</BreadcrumbsItem>
          : slideIndex == 1 ?
            <>
               <BreadcrumbsItem to="#1">Home</BreadcrumbsItem>
               <BreadcrumbsItem to="#2">Demo Plant</BreadcrumbsItem>
               <BreadcrumbsItem to="#">IR4.0 Strategy</BreadcrumbsItem>
            </>
          : slideIndex == 2 ?
            <>
               <BreadcrumbsItem to="#1">Home</BreadcrumbsItem>
               <BreadcrumbsItem to="#2">Demo Plant</BreadcrumbsItem>
               <BreadcrumbsItem to="#3">IR4.0 Strategy</BreadcrumbsItem>
               <BreadcrumbsItem to="#4">IR4.0 Savings</BreadcrumbsItem>
            </>
          : slideIndex == 3 ?
            <>
               <BreadcrumbsItem to="#1">Home</BreadcrumbsItem>
               <BreadcrumbsItem to="#2">Demo Plant</BreadcrumbsItem>
               <BreadcrumbsItem to="#3">IR4.0 Strategy</BreadcrumbsItem>
               <BreadcrumbsItem to="#4">IR4.0 Savings</BreadcrumbsItem>
               <BreadcrumbsItem to="#">IR4.0 Investment​</BreadcrumbsItem>
            </>
          : slideIndex == 4 ?
            <>
               <BreadcrumbsItem to="#1">Home</BreadcrumbsItem>
               <BreadcrumbsItem to="#2">Demo Plant</BreadcrumbsItem>
               <BreadcrumbsItem to="#3">IR4.0 Strategy</BreadcrumbsItem>
               <BreadcrumbsItem to="#4">IR4.0 Savings</BreadcrumbsItem>
               <BreadcrumbsItem to="#5">IR4.0 Investment​</BreadcrumbsItem>
               <BreadcrumbsItem to="#">IR4.0 Timeline Implementation​</BreadcrumbsItem>
            </>
          : slideIndex == 5 ?
            <>
                <BreadcrumbsItem to="#1">Home</BreadcrumbsItem>
               <BreadcrumbsItem to="#2">Demo Plant</BreadcrumbsItem>
               <BreadcrumbsItem to="#3">IR4.0 Strategy</BreadcrumbsItem>
               <BreadcrumbsItem to="#4">IR4.0 Savings</BreadcrumbsItem>
               <BreadcrumbsItem to="#5">IR4.0 Investment​</BreadcrumbsItem>
               <BreadcrumbsItem to="#6">IR4.0 Timeline Implementation​</BreadcrumbsItem>
               <BreadcrumbsItem to="#">IR4.0 Strategy Results​</BreadcrumbsItem>
            </>
            :
            ''
      }
      <div style={{ display: slideIndex == 0 ? 'block' : 'none' }}>
        <PrepareHomeView />
      </div>
      <div style={{ display: slideIndex == 1 ? 'block' : 'none' }}>
        <StepFirstView />
      </div>
      <div style={{ display: slideIndex == 2 ? 'block' : 'none' }}>
        <StepScndView />
      </div>
      <div style={{ display: slideIndex == 3 ? 'block' : 'none' }}>
        <StepThirdView />
      </div>
      <div style={{ display: slideIndex == 4 ? 'block' : 'none' }}>
        <StepFourView />
      </div>
      <div style={{ display: slideIndex == 5 ? 'block' : 'none' }}>
        <PoweBiChartView />
      </div>

      <ConfiguratorWalkthroughView slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
    </>
  );
};
