/** imported components

  * Helmet ===> for add specific title & CSS for the page.
  * $ from jquery ===> component from jquery to use jquery for the page.
  * serviceProvider for ===> working with api
  * useState, useEffect ===> React hooks (useState is a way to get/set value)
  * AssignSectionPopup  ===> Popup component to assign users 
  * AdminWalkthroughView ===> Walkthrough component for admin page
  
**/
import React from "react";
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import $, { isEmptyObject } from "jquery";
import serviceProvider from '../../services/axios'
import { newCirIcon, lockSectionIcon, unassignDotIcon } from "./svg";
import filter from 'lodash/filter';
import { apiEndpoints } from '../../services/api-endpoints';
//LossadminPlantView component start
export const LossadminPlantView = (props) => {
    //defining variables here
    const sectionItems = [
    {
        "uid": "3009",
        "is_comapny": 1,
        "block": 0,
        "plant_name": "demo plant",
        "title": "Start a business case",
        "sections": [
            {
                "id": "109",
                "section_name": "Budget Losses",
                "description": null,
                "section_slug": "budget-losses",
                "section_img": "",
                "has_sub_section": "1",
                "question_layout": "0",
                "is_active": "5",
                "order_by": "0"
            },
            {
                "id": "101",
                "section_name": "Line Efficiency Losses",
                "description": null,
                "section_slug": "lineefficiency-losses",
                "section_img": "ls-line-efficiency-losses.svg",
                "has_sub_section": "1",
                "question_layout": "1",
                "is_active": "1",
                "order_by": "1"
            },
            {
                "id": "102",
                "section_name": "Warehouse Losses",
                "description": "Provide the breakdown of the activities performed by the Warehouse or Material Handling function employees.",
                "section_slug": "warehouse-losses",
                "section_img": "ls-warehouse-losses.svg",
                "has_sub_section": "0",
                "question_layout": "2",
                "is_active": "1",
                "order_by": "2"
            },
            {
                "id": "103",
                "section_name": "Operator Effort Losses",
                "description": "Provide the breakdown of the production line activities performed by the direct labor (operators, technicians, third party contractors).",
                "section_slug": "operatoreffort-losses",
                "section_img": "ls-operator-effort-losses.svg",
                "has_sub_section": "0",
                "question_layout": "1",
                "is_active": "1",
                "order_by": "3"
            },
            {
                "id": "104",
                "section_name": "EHS Losses",
                "description": "Provide the breakdown of the activities performed by the EHS function employees.",
                "section_slug": "ehs-losses",
                "section_img": "ls-ehs-losses.svg",
                "has_sub_section": "0",
                "question_layout": "2",
                "is_active": "1",
                "order_by": "4"
            },
            {
                "id": "105",
                "section_name": "Quality Losses",
                "description": "Provide the breakdown of the activities performed by the Quality function employees.",
                "section_slug": "quality-losses",
                "section_img": "ls-quality-losses.svg",
                "has_sub_section": "0",
                "question_layout": "2",
                "is_active": "1",
                "order_by": "5"
            },
            {
                "id": "106",
                "section_name": "IT Losses",
                "description": "Provide the breakdown of the activities performed by the IT function employees.",
                "section_slug": "it-losses",
                "section_img": "ls-it-losses.svg",
                "has_sub_section": "0",
                "question_layout": "2",
                "is_active": "1",
                "order_by": "6"
            },
            {
                "id": "107",
                "section_name": "Maintenance Losses",
                "description": "Provide the breakdown of the activities performed by the Maintenance or Engineering function employees.",
                "section_slug": "maintenance-losses",
                "section_img": "ls-maintenance-losses.svg",
                "has_sub_section": "0",
                "question_layout": "2",
                "is_active": "1",
                "order_by": "7"
            },
            {
                "id": "108",
                "section_name": "Finance Data",
                "description": null,
                "section_slug": "finance-data",
                "section_img": "ls-finance-data.svg",
                "has_sub_section": "1",
                "question_layout": "3",
                "is_active": "1",
                "order_by": "8"
            }
        ],
        "Plant_id": "2143",
        "bcaselines": [
            {
                "id": "4627",
                "plant_id": "2143",
                "line_name": "Production line 1",
                "module": "1",
                "datecreated": "2022-10-18 07:32:30",
                "p": 0,
                "inputClass": "",
                "inputClassOne": ""
            },
            {
                "id": "4628",
                "plant_id": "2143",
                "line_name": "Production line 2",
                "module": "2",
                "datecreated": "2022-10-18 07:32:30",
                "p": 0,
                "inputClass": "",
                "inputClassOne": ""
            },
            {
                "id": "4629",
                "plant_id": "2143",
                "line_name": "Production line 3",
                "module": "3",
                "datecreated": "2022-10-18 07:32:30",
                "p": 0,
                "inputClass": "",
                "inputClassOne": ""
            }
        ],
        "customData": {
            "id": "1046",
            "plant_id": "2143",
            "unit": "Tons",
            "currency": "EUR",
            "fiscal_year": "2022",
            "isset": "1",
            "islocked": "0",
            "hasimport": "0",
            "datecreated": "2022-10-18 07:32:30",
            "dateimported": null
        },
        "bcaselines_auto": [
            {
                "id": "71572",
                "line_name": "Production line 1",
                "module": "1",
                "company_user_id": "3009",
                "plant_id": "2143"
            },
            {
                "id": "71573",
                "line_name": "Production line 2",
                "module": "2",
                "company_user_id": "3009",
                "plant_id": "2143"
            },
            {
                "id": "71574",
                "line_name": "Production line 3",
                "module": "3",
                "company_user_id": "3009",
                "plant_id": "2143"
            }
        ],
        "customData_auto": {
            "id": "20772",
            "plant_id": "2143",
            "unit": "Tons",
            "currency": "EUR",
            "fiscal_year": "2022"
        },
        "sectioninvitedmeber": [],
        "locked_sections": [],
        "subsection": [
            {
                "id": "1",
                "section_id": "101",
                "sub_section_name": "Planned Downtime",
                "description": "Provide the hours of planned downtime per week as an average of the grouped lines.",
                "is_active": "1",
                "order_by": "1"
            },
            {
                "id": "2",
                "section_id": "101",
                "sub_section_name": "Unplanned Downtime",
                "description": "Provide the hours of unplanned downtime per week as an average of the grouped lines.",
                "is_active": "1",
                "order_by": "2"
            },
            {
                "id": "3",
                "section_id": "101",
                "sub_section_name": "Minor Stops",
                "description": "Provide the hours of minor stops per week as an average of the grouped lines.",
                "is_active": "1",
                "order_by": "3"
            },
            {
                "id": "4",
                "section_id": "101",
                "sub_section_name": "Speed Losses",
                "description": "Provide the hours of speed losses per week as an average of the grouped lines.",
                "is_active": "1",
                "order_by": "4"
            },
            {
                "id": "5",
                "section_id": "101",
                "sub_section_name": "Material Waste",
                "description": "Provide the breakdown of the material waste reason codes as a % of the  production process\/line cost per week.",
                "is_active": "1",
                "order_by": "5"
            },
            {
                "id": "6",
                "section_id": "108",
                "sub_section_name": "General Information",
                "description": "Provide the operational data based on the production schedule during the selected fiscal year.",
                "is_active": "1",
                "order_by": "1"
            },
            {
                "id": "7",
                "section_id": "108",
                "sub_section_name": "Manufacturing Cost",
                "description": "Provide the financial values based on the selected fiscal year.",
                "is_active": "1",
                "order_by": "3"
            },
            {
                "id": "8",
                "section_id": "108",
                "sub_section_name": "FTE Breakdown\n",
                "description": "Provide the FTE Breakdown based on the selected fiscal year.",
                "is_active": "1",
                "order_by": "2"
            }
        ],
        "owners": [],
        "finalprogress": 0,
        "assingdetails": [],
        "isAdminlosstour": "1",
        "loss_setup1": "1",
        "loss_setup2": "1",
        "loss_setup3": "1",
        "lockall": 0,
        "has_businesscase": 0,
        "na_data": [],
        "na_progress_data": [],
        "submit_data": []
    }
];
    if (!sectionItems.length) {
        return null;
    }
    const sectionItem = sectionItems[0];
    const pid = sectionItem.Plant_id;
    const usid = sectionItem.uid;
    const assinlist = sectionItem.assingdetails;
    const sectionid = sectionItem.sections;
    const subsectionid = sectionItem.subsection;
    if (typeof assinlist === 'undefined') {
        return null;
    }
    const inviteduserlist = sectionItem.sectioninvitedmeber; //invited user list
    //SCNV 2012
    const [isCardLockedHover, setIsCardLockedHover] = useState('');
    const locked_sections = sectionItem.locked_sections;
    //SCNV 2012
    const [listofsec, setLIstofsec] = useState({});
    const [overallprogress, setOverAllProgress] = useState(0);
    let prog = assinlist;
    let progArr = [];
    let comArr = 0;
    prog.map((item, index) => {
        if (item.progress > 0 && item.progress < 99) {
            progArr.push(50);
        } else if (item.progress >= 100) {
            progArr.push(100);
            comArr++;
        } else {
            progArr.push(0);
        }
    })
    let totol_na_progress = sectionItem.na_progress_data.length;
    let totalna = sectionItem.na_data.length;
    progArr.push(totalna * 100);
    progArr.push(totol_na_progress * 50);
    let avgProg = progArr.reduce((a, b = 0) => { return a + b }, 0);
    let avgProgtotal = Math.round(avgProg / 14);
    if (avgProgtotal > 100) {
        avgProgtotal = 100;
    }

    let tempListAssing = {};
    if (assinlist.length != 0) {

        assinlist.map((item, index) => {
            // console.log(item);
            let catArr = [];
            let nameId;
            catArr.push(item.invited_category);
            if (catArr.includes(item.invited_category) && item.invited_sub_category == 0) {
                nameId = item.invited_category;
            } else {
                nameId = item.invited_sub_category;
            }

            tempListAssing = { ...tempListAssing, [nameId]: item }
        })

    }


    let s1 = [];
    let s1name = [];

    let s4 = [];
    let s4name = [];



    inviteduserlist.map((item, index) => {

        if (item.invited_category == 101 && item.group_assign == 1) {
            if (item.name != null) {
                s1.push(`${item.name[0]}${item.last_name[0]}`);
                s1name.push(`${item.name} ${item.last_name}`);
            } else {
                s1.push(`${item.email[0]}`);
                s1name.push(`${item.email}`);
            }
        } else if (item.invited_category == 108 && item.group_assign == 1) {
            if (item.name != null) {
                s4.push(`${item.name[0]}${item.last_name[0]}`);
                s4name.push(`${item.name} ${item.last_name}`);
            } else {
                s1.push(`${item.email[0]}`);
                s1name.push(`${item.email}`);
            }
        }


    })



    const slug = props.slug;

    const [isAssignSectionPopup, setIsAssignSectionPopup] = useState(false);
    const [isUpgradePopup, setIsUpgradePopup] = useState(false);
    const [isProductionPopup, setIsProductionPopup] = useState(false);
    const [cate, setCate] = useState();
    const [subcate, setSubcate] = useState(0);
    const [secname, setSecName] = useState();
    const [tmpAssignUser, setTmpAssignUser] = useState("");
    const [assignedUsers, setAssignedUsers] = useState({
        planned_downtime: [],
        unplanned_downtime: [],
        minor_stops: [],
        speed_lossess: [],
        material_waste: [],
        operator_lossess: [],
        maintenance_losses: [],
        it_losses: [],
        warehouse_losses: [],
        ehs_losses: [],
        quality_losses: [],
        general_information: [],
        fte_breakdown: [],
        manufacturing_cost: [],
    });
    const [isActiveDddl, setIsActiveDddl] = useState(false);
    {/* scnv-2126 */ }
    const [isSubmitDataPopup, setIsSubmitDataPopup] = useState(false);
    {/* scnv-2126 */ }

    // scnv-1885
    const [isCardHover, setIsCardHover] = useState(null);
    //Function to handle active status on hover
    const HoverActiveHandle = (e, val) => {
        if (!e.target.closest('.right-section') && !e.target.closest('.lock-section')) {
            setIsCardHover(val)
            setIsShown(false)
        } else {
            setIsCardHover(null);
        }
    }
    //Function for edit
    const edithandle = (e, cid, sid = 0, secname = '') => {
        setIsShown(false);
        if (cid == 101 || cid == 108) {
            setIsAssignSectionPopup(true);
            setCate(cid);
            setSubcate(sid);
            setSecName(secname);
        } else if (sid != 0) {
            setIsAssignSectionPopup(true);
            setCate(cid);
            setSubcate(sid);
            setSecName(secname);
        } else {
            setIsAssignSectionPopup(true);
            setCate(cid);
            setSecName(secname);
        }
        return false;
    }
    // Function to handle click on section
    const handleSectionClick = async (e, path, section_id = 0, sub_section_id = 0) => {

        const { sectionData, history } = props;
        e.preventDefault();
        if (!e.target.closest('.edit')) {
            try {
                let uid = 0;
                let plant_id = 0;

                if (sectionData.length && sectionData[0].uid) {
                    uid = sectionData[0]?.uid;
                    plant_id = sectionData[0]?.Plant_id
                }
                let data = { uid, section_id, type: 2, status: 1, plant_id }
                if (sub_section_id)
                    data['sub_section_id'] = sub_section_id;

                await serviceProvider.post('/sectionlock', data, true);
            } catch (err) {
                console.error(err)
            }

            history.push(path)
        }
    }
    // Function to handle popup
    const handlePopup = (e, cid, secname, sid = 0) => {

        if (s1.length != 0 && cid == 101 && sid == 0) {
            setIsShown(!isShown);
            setIsShown(true)
            setCate(cid);
            setSubcate(sid)
            setSecName(secname);

        } else if (s4.length != 0 && cid == 108 && sid == 0) {
            setIsShown(!isShown);
            setIsShown(true)
            setCate(cid);
            setSubcate(sid)
            setSecName(secname);

        } else {
            setIsShown(!isShown);
            setCate(cid);
            setSecName(secname);
            setSubcate(sid)

        }
    }
    /*scnv-2511*/

    const handleUnassign = (e) => {
        let active = document.querySelector('.user-unassign-div.active');
        if (active && !e.currentTarget.parentElement.classList.contains('active')) {
            active?.classList.remove('active');
        }
        e.currentTarget.parentElement?.classList.toggle('active');

    }

    const handleUnassignUser = (e, email, key) => {
        // e.currentTarget.parentElement.parentElement.style.display = "none";
        let tmpFullObj = { ...assignedUsers };
        let tmpFilterData = filter(tmpFullObj[key], (obj) => obj.email != email);
        setAssignedUsers({
            ...tmpFullObj,
            [key]: tmpFilterData
        })
        let active = document.querySelector('.user-unassign-div.active');
        active?.classList.remove('active');

        const result = serviceProvider.get(`/Businesscaseplantloss/removeInvite?user_id=${usid}&catagory=${cate}&invited_sub_category=${subcate}&categName=${secname}&plant_name=${sectionItem.plant_name}&plant_id=${pid}&email=${email}`);


    }

    /*scnv-2511-*/
    //variables declaration
    const [isShown, setIsShown] = useState(false);
    useEffect(() => {
        document.addEventListener('click', (e) => {
            if (!e.target.closest(".new-assign-user") && !e.target.closest('.assigned-user-popup')) {
                setIsShown(false);

            }

            /*SCNV-2511*/

            if (!e.target.closest('.user-unassign-div')) {
                let active = document.querySelector('.user-unassign-div.active');
                active?.classList.remove('active');
            }
            /*SCNV-2511*/
        })

        let activeUserPopup = document.querySelector('.assigned-user-popup.active');
        if (activeUserPopup) {
            activeUserPopup.onclick = (e) => {
                if (!e.target.closest('.user-unassign-div')) {
                    let active = document.querySelector('.user-unassign-div.active');
                    active?.classList.remove('active');
                }
            }
        }



    })
    // useEffect to set invitedata;
    useEffect(() => {

        let getAllId = [];
        if (inviteduserlist && Array.isArray(inviteduserlist)) {
            let tmpAssingUserArr =
            {
                planned_downtime: filter(inviteduserlist, { invited_sub_category: '1' }),
                unplanned_downtime: filter(inviteduserlist, { invited_sub_category: '2' }),
                minor_stops: filter(inviteduserlist, { invited_sub_category: '3' }),
                speed_lossess: filter(inviteduserlist, { invited_sub_category: '4' }),
                material_waste: filter(inviteduserlist, { invited_sub_category: '5' }),
                operator_lossess: filter(inviteduserlist, { invited_category: '103' }),
                maintenance_losses: filter(inviteduserlist, { invited_category: '107' }),
                it_losses: filter(inviteduserlist, { invited_category: '106' }),
                warehouse_losses: filter(inviteduserlist, { invited_category: '102' }),
                ehs_losses: filter(inviteduserlist, { invited_category: '104' }),
                quality_losses: filter(inviteduserlist, { invited_category: '105' }),
                general_information: filter(inviteduserlist, { invited_sub_category: '6' }),
                fte_breakdown: filter(inviteduserlist, { invited_sub_category: '8' }),
                manufacturing_cost: filter(inviteduserlist, { invited_sub_category: '7', invited_category: '108' }),
            }
            setAssignedUsers(tmpAssingUserArr);
        }

    }, [])

    return (
        <>
            {/* css for styling page */}
            <Helmet>
                <link href={require("./loss-analysis-admin-page.scss").default} />
            </Helmet>
            {/* Loss Analysis main wrapper Start */}
            <div className="loss-analysis-category-wapper admin">
                {/* row has been divided into four parts
                    heading-btn-wrap
                    md-col-12
                    md-col-8
                    md-col-4
                */}
                <div className="loss-analysis-category-row">
                    {/* this wrapper has two elements
                        page-heading
                        button-wrap
                    */}
                    <div className="section-btn-wrap">
                        <div className="section-progress-wrap walk-step-2">
                            <div className="overall-progress-bar">
                                <div className="progress-text">
                                    <span className="text">Overall Progress:</span>
                                    <span className="percent-value">{avgProgtotal ? avgProgtotal : 0}%</span>
                                </div>
                                <div className="progress-wrap">
                                    <div className="progress">
                                        <div className="bar blue" style={{ width: `${avgProgtotal ? avgProgtotal : 0}%` }}></div>
                                    </div>
                                </div>
                            </div>
                            <span className="mid-border"></span>
                            <div className="submit-section">
                                <span className="section-progress"></span>
                                <span className="text">Sections Submitted:</span>
                                <p className="number">
                                    <span className="incomplete-no">{sectionItem.submit_data.length + sectionItem.na_data.length}</span>
                                    <span className="border">/</span>
                                    <span className="complete-no">14</span>
                                </p>
                            </div>
                        </div>
                        <div className="button-wrap wrp-flex2126">
                            <button className="view-btn btn" onClick={() => { window.location = `/loss-analysis-setup/${slug}` }}>View Setup Page</button>
                            {/* remove schecule button for SCNV-2126 "start" */}
                            {(avgProgtotal >= 100 && sectionItem.lockall == 0) && (<div class="walk-step3">
                                <button className="schedule-btn btn" disabled={false} onClick={() => setIsSubmitDataPopup(true)}>Submit Data</button>
                            </div>
                            )}
                            {avgProgtotal < 100 && (<div class="walk-step3">
                                <button className="schedule-btn btn" disabled={true} onClick={() => setIsSubmitDataPopup(true)}>Submit Data</button>
                            </div>
                            )}
                            {/* remove schecule button for SCNV-2126 "end" */}
                            {sectionItem.lockall == 1 && (<button className="submitted-btn btn ">
                                <span className="d-sub-svg" dangerouslySetInnerHTML={{ __html: dataSubmitsvg }}></span>
                                <span>Data Submitted</span>
                            </button>
                            )}
                        </div>
                    </div>
                    {/* in this section has 
                        button 
                        popup
                        progressbar
                    */}
                    <div className="loss-analysis-category-col md-col-12 border-bottom padding-left-0 padding-r0">
                        <div className="loss-category-progress-wrap">
                            <div className="loss-analysis-category-button-wrap blue">
                                <div className="img-wrap">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/production.svg")
                                                .default
                                        }
                                        alt="production"
                                    />
                                </div>
                                <span className="text">Production Process Level</span>
                                <div class="note">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/notify.svg")
                                                .default
                                        }
                                        alt="Notes"
                                    />
                                </div>
                                <div className="card-note-popup">
                                    <div className="card-note-tooltip-wrap dark-blue">
                                        <div className="card-note-tooltip">
                                            <h2>Production Process Level</h2>
                                            <p>Contains Loss Categories that are analyzed based on the different production processes existing in the plant operations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* This section has two parts 
                        loss-analysis-category-button-wrap 
                        loss-cat-card-row
                    */}
                    <div className="loss-analysis-category-col md-col-8 walk-step-1">
                        <div className="loss-analysis-category-button-wrap disabled-btn gray">
                            <div className="left-text-wrap">
                                <span className="text">Line Efficiency Losses</span>
                                <div class="note">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/disabled.svg")
                                                .default
                                        }
                                        alt="Disabled"
                                    />
                                    <div className="card-note-popup">
                                        <div className="card-note-tooltip-wrap dark-gray">
                                            <div className="card-note-tooltip">
                                                <h2>Line Efficiency Losses</h2>
                                                <p>Consists of the various Loss Categories affecting the efficiency of a production process, following the OEE calculation.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="la-assign-cir-wrapper wrap">
                                <div className="new-assign-group">
                                    <button className="new-assign" onClick={(e) => { edithandle(e, 101, 0, 'Line Efficiency Losses'); setTmpAssignUser(['planned_downtime', 'unplanned_downtime', 'minor_stops', 'speed_lossess', 'material_waste']); }}>
                                        <div className="img-div">
                                            <img src={require('../../assets/img/la-admin/assign-icon.svg').default} alt="assign" />
                                        </div>
                                        <div className="assign-text 1">
                                            <span>Assign</span>
                                        </div>
                                    </button>
                                </div>
                                {/* new-assign-group end */}
                            </div>
                        </div>
                        {/* divided further into columns */}
                        <div className="loss-cat-card-row line left-cat-row">
                            <div className="loss-cat-card-col mb-1 padding-left-0">
                                {/* This section has three parts
                                    header
                                    card-body
                                    card-footer
                                    common structure for all loss-cat-card cards
                                */}
                                <div
                                    onClick={locked_sections[101] && locked_sections[101][1] ? () => { } : (e) => handleSectionClick(e, `/lossanalysisques/questionaire/${slug}/101/1`, 101, 1)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 1 ? ' loss-cat-card hide-loss-card-body' : 'loss-cat-card'} ${isCardHover == 1 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 1)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/plan.svg")
                                                        .default
                                                }
                                                alt="Planned"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Planned Downtime</span>
                                            <a href={`/lossanalysisques/questionaire/${slug}/101/1`} className="btn-link">
                                                {tempListAssing[101]?.progress == null || tempListAssing[101]?.progress == 0 ? "+ Start section" : tempListAssing[101]?.progress > 0 && tempListAssing[101]?.progress < 100 ? '+ Continue Section' : '+ View Section'}
                                            </a>
                                        </div>
                                    </div>
                                    {locked_sections[101] && locked_sections[101][1] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 1 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[101][1]?.avcolor}`}>{locked_sections[101][1]?.shortname}</div>
                                                    <span className="name">{locked_sections[101][1]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section  walk-step-2">
                                            <a href={`/lossanalysisques/questionaire/${slug}/101/1`}
                                                className={`bottom-btn-link ${(tempListAssing[1]?.invited_category == 101 && tempListAssing[1]?.invited_sub_category == 1) ? tempListAssing[1]?.progress == null || tempListAssing[1]?.progress == 0 ? (sectionItem.na_data.includes(1) ? 'not-available' : (sectionItem.na_progress_data.includes(1) ? "orange" : "red")) : tempListAssing[1]?.progress > 0 && tempListAssing[1]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {(tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0) && tempListAssing[101]?.subsection_id==1 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 && tempListAssing[101]?.subsection_id==1 ?'In Progress':'completed' } */}
                                                {(tempListAssing[1]?.invited_category == 101 && tempListAssing[1]?.invited_sub_category == 1) ?
                                                    (tempListAssing[1]?.progress == null || tempListAssing[1]?.progress == 0) ?
                                                        (sectionItem.na_data.includes(1) ? "Data N/A" : (sectionItem.na_progress_data.includes(1) ? "In Progress" : "To Do")) : (tempListAssing[1]?.invited_category == 101 && tempListAssing[1]?.invited_sub_category == 1) && (tempListAssing[1]?.progress > 0 && tempListAssing[1]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                            {/* <Link to="/" className="bottom-btn-link green completed">COMPLETED</Link>
                                            <Link to="/" className="bottom-btn-link green review">IN REVIEW</Link> */}
                                        </div>
                                        <div className="right-section right-section1 rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[0].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 101, 'Planned Downtime', 1); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.planned_downtime.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                            {!item.profile_pic ?
                                                                <div className={`image-div ${item.avcolor}`} >
                                                                    {item.name ?
                                                                        `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                        :
                                                                        `${item.email[0]}`
                                                                    }
                                                                </div>
                                                                :
                                                                <div className={`image-div cir-b-none`} >
                                                                    <img className="h__profile__img_cir"

                                                                        src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                        alt={item.profile_pic}
                                                                    />
                                                                </div>
                                                            }
                                                        </span>

                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.planned_downtime.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.planned_downtime.length - 2}</span>
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 101, 1, 'Planned Downtime'); setTmpAssignUser('planned_downtime'); }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup ${subsectionid[0].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">

                                                    {assignedUsers.planned_downtime.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                    <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>

                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'planned_downtime') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0
                                            && <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Planned Downtime</h2>
                                                        <p>The time a production process or line is not operating due to planned non-production activities that need to take place.</p>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1">
                                <div
                                    onClick={locked_sections[101] && locked_sections[101][2] ? '' : (e) => handleSectionClick(e, `/lossanalysisques/questionaire/${slug}/101/2`, 101, 2)}
                                    className={`loss-cat-card  ${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 2 ? 'hide-loss-card-body' : ''} ${isCardHover == 2 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 2)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/unplanned-downtime.svg")
                                                        .default
                                                }
                                                alt="UnPlanned"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Unplanned Downtime</span>
                                        </div>
                                    </div>
                                    {locked_sections[101] && locked_sections[101][2] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 2 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[101][2]?.avcolor}`}>{locked_sections[101][2]?.shortname}</div>
                                                    <span className="name">{locked_sections[101][2]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/lossanalysisques/questionaire/${slug}/101/2`} className={`bottom-btn-link ${(tempListAssing[2]?.invited_category == 101 && tempListAssing[2]?.invited_sub_category == 2) ? tempListAssing[2]?.progress == null || tempListAssing[2]?.progress == 0 ? (sectionItem.na_data.includes(2) ? 'not-available' : (sectionItem.na_progress_data.includes(2) ? "orange" : "red")) : tempListAssing[2]?.progress > 0 && tempListAssing[2]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {(tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0) &&  tempListAssing[101]?.subsection_id==2  ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 &&  tempListAssing[101]?.subsection_id==2 ?'In Progress':'completed' } */}
                                                {(tempListAssing[2]?.invited_category == 101 && tempListAssing[2]?.invited_sub_category == 2) ?
                                                    (tempListAssing[2]?.progress == null || tempListAssing[2]?.progress == 0) ?
                                                        (sectionItem.na_data.includes(2) ? "Data N/A" : (sectionItem.na_progress_data.includes(2) ? "In Progress" : "To Do")) : (tempListAssing[2]?.invited_category == 101 && tempListAssing[2]?.invited_sub_category == 2) && (tempListAssing[2]?.progress > 0 && tempListAssing[2]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[1].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 101, 'Unplanned Downtime', 2); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.unplanned_downtime.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.unplanned_downtime.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.unplanned_downtime.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 101, 2, 'Unplanned Downtime'); setTmpAssignUser('unplanned_downtime'); }}>

                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup ${subsectionid[1].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.unplanned_downtime.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'unplanned_downtime') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Unplanned Downtime</h2>
                                                        <p>The time a production process or line is not operating due to unplanned events.</p>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1 padding-r0">
                                <div
                                    onClick={locked_sections[101] && locked_sections[101][3] ? '' : (e) => handleSectionClick(e, `/lossanalysisques/questionaire/${slug}/101/3`, 101, 3)}
                                    className={` loss-cat-card ${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 3 ? 'hide-loss-card-body' : ''} ${isCardHover == 3 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 3)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/minor-stops.svg")
                                                        .default
                                                }
                                                alt="Minor"
                                            />

                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Minor Stops</span>
                                        </div>
                                    </div>
                                    {locked_sections[101] && locked_sections[101][3] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 3 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[101][3]?.avcolor}`}>{locked_sections[101][3]?.shortname}</div>
                                                    <span className="name">{locked_sections[101][3]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/lossanalysisques/questionaire/${slug}/101/3`} className={`bottom-btn-link ${(tempListAssing[3]?.invited_category == 101 && tempListAssing[3]?.invited_sub_category == 3) ? tempListAssing[3]?.progress == null || tempListAssing[3]?.progress == 0 ? (sectionItem.na_data.includes(3) ? 'not-available' : (sectionItem.na_progress_data.includes(3) ? "orange" : "red")) : tempListAssing[3]?.progress > 0 && tempListAssing[3]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 ?'In Progress':'completed' } */}
                                                {(tempListAssing[3]?.invited_category == 101 && tempListAssing[3]?.invited_sub_category == 3) ?
                                                    (tempListAssing[3]?.progress == null || tempListAssing[3]?.progress == 0) ?
                                                        (sectionItem.na_data.includes(3) ? "Data N/A" : (sectionItem.na_progress_data.includes(3) ? "In Progress" : "To Do")) : (tempListAssing[3]?.invited_category == 101 && tempListAssing[3]?.invited_sub_category == 3) && (tempListAssing[3]?.progress > 0 && tempListAssing[3]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[2].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 101, 'Minor Stops', 3); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.minor_stops.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.minor_stops.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.minor_stops.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className="assigned-user-div" data-title="Assign Users" style={{ cursor: 'pointer' }} onClick={(e) => { edithandle(e, 101, 3, 'Minor Stops'); setTmpAssignUser('minor_stops'); }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>

                                            </div>
                                            <div className={`assigned-user-popup ${subsectionid[2].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.minor_stops.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'minor_stops') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Minor Stops</h2>
                                                        <p>The time the production process or line is not operating due to small stops (usually with a duration of less than 5 minutes).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col padding-left-0">
                                <div
                                    onClick={locked_sections[101] && locked_sections[101][4] ? '' : (e) => handleSectionClick(e, `/lossanalysisques/questionaire/${slug}/101/4`, 101, 4)}
                                    className={` loss-cat-card ${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 4 ? 'hide-loss-card-body' : ''} ${isCardHover == 4 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 4)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/speed-losses.svg")
                                                        .default
                                                }
                                                alt="Speed Losses"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Speed Losses</span>
                                        </div>
                                    </div>
                                    {locked_sections[101] && locked_sections[101][4] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 4 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[101][4]?.avcolor}`}>{locked_sections[101][4]?.shortname}</div>
                                                    <span className="name">{locked_sections[101][4]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/lossanalysisques/questionaire/${slug}/101/4`} className={`bottom-btn-link ${(tempListAssing[4]?.invited_category == 101 && tempListAssing[4]?.invited_sub_category == 4) ? tempListAssing[4]?.progress == null || tempListAssing[4]?.progress == 0 ? (sectionItem.na_data.includes(4) ? 'not-available' : (sectionItem.na_progress_data.includes(4) ? "orange" : "red")) : tempListAssing[4]?.progress > 0 && tempListAssing[4]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 ?'In Progress':'completed' } */}
                                                {(tempListAssing[4]?.invited_category == 101 && tempListAssing[4]?.invited_sub_category == 4) ?
                                                    (tempListAssing[4]?.progress == null || tempListAssing[4]?.progress == 0) ?
                                                        (sectionItem.na_data.includes(4) ? "Data N/A" : (sectionItem.na_progress_data.includes(4) ? "In Progress" : "To Do")) : (tempListAssing[4]?.invited_category == 101 && tempListAssing[4]?.invited_sub_category == 4) && (tempListAssing[4]?.progress > 0 && tempListAssing[4]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[3].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 101, 'Speed Losses', 4); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.speed_lossess.slice(0, 2).map((item, index) => 
                                                    <div >
                                                    <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none `} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.speed_lossess.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.speed_lossess.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 101, 4, 'Speed Losses'); setTmpAssignUser('speed_lossess'); }} style={{ cursor: 'pointer' }}>

                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>

                                            </div>
                                            <div className={`assigned-user-popup ${subsectionid[3].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.speed_lossess.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                    {/* <div className={`image-div ${item.avcolor}`}>
                                                                        <span className="us-name">{item.name ?
                                                                            `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                            :
                                                                            `${item.email[0]}`
                                                                        }</span>
                                                                    </div> */}
                                                                    <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'speed_lossess') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Speed Losses</h2>
                                                        <p>The time the production process or line is running slower than the Ideal Cycle Time (e.g. less than design speed).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col">
                                <div
                                    onClick={locked_sections[101] && locked_sections[101][5] ? '' : (e) => handleSectionClick(e, `/lossanalysisques/questionaire/${slug}/101/5`, 101, 5)}
                                    className={`loss-cat-card  ${isCardLockedHover['section'] === 101 && isCardLockedHover['subsection'] === 5 ? 'hide-loss-card-body' : ''} ${isCardHover == 5 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 5)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/material-waste.svg")
                                                        .default
                                                }
                                                alt="Material Waste"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Material Waste</span>
                                            <a href={`/lossanalysisques/questionaire/${slug}/101/5`} className="btn-link">+ Start Section</a>
                                        </div>
                                    </div>
                                    {locked_sections[101] && locked_sections[101][5] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 101, subsection: 5 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[101][5]?.avcolor}`}>{locked_sections[101][5]?.shortname}</div>
                                                    <span className="name">{locked_sections[101][5]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/lossanalysisques/questionaire/${slug}/101/5`} className={`bottom-btn-link ${(tempListAssing[5]?.invited_category == 101 && tempListAssing[5]?.invited_sub_category == 5) ? tempListAssing[5]?.progress == null || tempListAssing[5]?.progress == 0 ? (sectionItem.na_data.includes(5) ? 'not-available' : (sectionItem.na_progress_data.includes(5) ? "orange" : "red")) : tempListAssing[5]?.progress > 0 && tempListAssing[5]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {tempListAssing[101]?.progress==null || tempListAssing[101]?.progress==0 ? "To Do" : tempListAssing[101]?.progress>0 && tempListAssing[101]?.progress<100 ?'In Progress':'completed' } */}
                                                {(tempListAssing[5]?.invited_category == 101 && tempListAssing[5]?.invited_sub_category == 5) ?
                                                    (tempListAssing[5]?.progress == null || tempListAssing[5]?.progress == 0) ?
                                                        (sectionItem.na_data.includes(5) ? "Data N/A" : (sectionItem.na_progress_data.includes(5) ? "In Progress" : "To Do")) : (tempListAssing[5]?.invited_category == 101 && tempListAssing[5]?.invited_sub_category == 5) && (tempListAssing[5]?.progress > 0 && tempListAssing[5]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[4].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 101, 'Material Waste', 5); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.material_waste.slice(0, 2).map((item, index) => <div >
                                                    <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.material_waste.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.material_waste.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 101, 5, 'Material Waste'); setTmpAssignUser('material_waste'); }} style={{ cursor: 'pointer' }}>

                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>

                                            </div>
                                            {/* assigned user div */}
                                            <div className={`assigned-user-popup ${subsectionid[4].id == subcate && sectionid[1].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.material_waste.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'material_waste') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup blue">
                                                <div className="card-note-tooltip-wrap">
                                                    <div className="card-note-tooltip">
                                                        <h2>Material Waste</h2>
                                                        <p>The cost of units scrapped during the production process leading to material waste.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* This section has two parts 
                        loss-analysis-category-button-wrap 
                        loss-cat-card-row
                    */}
                    <div className="loss-analysis-category-col md-col-4 padding-r0">
                        <div className="loss-analysis-category-button-wrap disabled-btn gray padding-r0">
                            <div className="left-text-wrap">
                                <span className="text">Operator Effort Losses</span>
                                <div class="note">
                                    <img
                                        src={
                                            require("../../assets/img/la-admin/disabled.svg")
                                                .default
                                        }
                                        alt="Disabled"
                                    />
                                    {Object.keys(isCardLockedHover).length == 0 &&
                                        <div className="card-note-popup">
                                            <div className="card-note-tooltip-wrap dark-gray">
                                                <div className="card-note-tooltip">
                                                    <h2>Operator Effort Losses</h2>
                                                    <p>The key routine activities taking place by the direct labor (line operators/technicians) on the production lines including any 3rd party contractors.</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="la-assign-cir-wrapper wrap">
                                <div className="new-assign-group" style={{ display: 'none' }}>
                                    <button className="new-assign" onClick={(e) => { e.preventDefault(); edithandle(e, 103, 0, 'Operator Losses') }}>
                                        <div className="img-div">
                                            <img src={require('../../assets/img/la-admin/assign-icon.svg').default} alt="assign" />
                                        </div>
                                        <div className="assign-text">
                                            <span>Assign</span>
                                        </div>
                                    </button>
                                </div>
                                {/* new-assign-group end */}
                            </div>

                        </div>
                        {/* divided further into columns */}
                        <div className="loss-cat-card-row operator row-padding right-col line">
                            <div className="loss-cat-card-col padding-left-0">
                                {/* This section has three parts
                                    header
                                    card-body
                                    card-footer
                                    common structure for all loss-cat-card cards
                                */}
                                <div
                                    onClick={locked_sections[103] && locked_sections[103][0] ? '' : (e) => handleSectionClick(e, `/operator-effort-losses/questionaire/${slug}/103`, 103, 0)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 103 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 6 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 6)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/operator-losses.svg")
                                                        .default
                                                }
                                                alt="Operator -Losses"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Operator Losses</span>
                                            <a href={`/operator-effort-losses/questionaire/${slug}/103`} className="btn-link continue">
                                                {/* + View Section */}
                                                {/* { sectdata[categ?.indexOf('103')]?.progress==null  ? "+ Start section" : sectdata[categ?.indexOf('103')]?.progress>0 && sectdata[categ?.indexOf('103')]?.progress<100?'+ Continue Section':'+ View Section' } */}
                                                {tempListAssing[103]?.progress == null || tempListAssing[103]?.progress == 0 ? "+ Start section" : tempListAssing[103]?.progress > 0 && tempListAssing[103]?.progress < 100 ? '+ Continue Section' : '+ View Section'}

                                            </a>
                                        </div>
                                    </div>
                                    {locked_sections[103] && locked_sections[103][0] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 103, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[103][0]?.avcolor}`}>{locked_sections[103][0]?.shortname}</div>
                                                    <span className="name">{locked_sections[103][0]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/operator-effort-losses/questionaire/${slug}/103`} className={`bottom-btn-link ${tempListAssing[103]?.progress == null || tempListAssing[103]?.progress == 0 ? (sectionItem.na_data.includes(103) ? 'not-available' : (sectionItem.na_progress_data.includes(103) ? "orange" : "red")) : tempListAssing[103]?.progress > 0 && tempListAssing[103]?.progress < 100 ? 'orange' : 'green'}`}>
                                                {tempListAssing[103]?.progress == null || tempListAssing[103]?.progress == 0 ? (sectionItem.na_data.includes(103) ? "Data N/A" : (sectionItem.na_progress_data.includes(103) ? "In Progress" : "To Do")) : tempListAssing[103]?.progress > 0 && tempListAssing[103]?.progress < 100 ? 'In Progress' : 'completed'}
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${sectionid[3].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 103, 'Operator Effort Losses'); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.operator_lossess.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.operator_lossess.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.operator_lossess.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 103, 0, 'Operator Losses'); setTmpAssignUser('operator_lossess'); }} style={{ cursor: 'pointer' }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup ${sectionid[3].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.operator_lossess.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'operator_lossess') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-note-popup blue">
                                            <div className="card-note-tooltip-wrap">
                                                <div className="card-note-tooltip">
                                                    <h2>Operator Losses</h2>
                                                    <p>The key routine activities taking place by the direct labor (line operators/technicians) on the production lines including any 3rd party contractors.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Second Row starts */}
                <div className="loss-analysis-category-row">
                    <div className="loss-analysis-category-col md-col-8">
                        {/* This section has two parts 
                            loss-analysis-assign-wrap 
                            loss-cat-card-row
                        */}
                        <div className="loss-analysis-category-col border-bottom margin-left">
                            <div className="loss-analysis-assign-wrap">
                                <div className="loss-analysis-category-button-wrap green">
                                    <div className="img-wrap">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/key-plant.svg")
                                                    .default
                                            }
                                            alt="production"
                                        />
                                    </div>
                                    <span className="text">Key Plant Functions</span>
                                    <div class="note">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/note-green.svg")
                                                    .default
                                            }
                                            alt="Notes"
                                        />
                                    </div>
                                    <div className="card-note-popup">
                                        <div className="card-note-tooltip-wrap dark-green">
                                            <div className="card-note-tooltip">
                                                <h2>Key Plant Functions</h2>
                                                <p>Consists of the key activities taking place by the main supporting functions of the plant operations.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="la-assign-cir-wrapper wrap">
                                    <div className="new-assign-group" style={{ display: 'none' }}>
                                        <button className="new-assign" onClick={(e) => { e.preventDefault(); edithandle(e, 103, 0, 'Operator Losses') }}>
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-admin/assign-icon.svg').default} alt="assign" />
                                            </div>
                                            <div className="assign-text">
                                                <span>Assign</span>
                                            </div>
                                        </button>
                                    </div>
                                    {/* new-assign-group end*/}
                                </div>
                            </div>
                        </div>
                        {/* divided further into columns */}
                        <div className="loss-cat-card-row bottom left-cat-row">
                            <div className="loss-cat-card-col mb-1 padding-left-0">
                                {/* This section has three parts
                                    header
                                    card-body
                                    card-footer
                                    common structure for all loss-cat-card cards
                                */}
                                <div
                                    onClick={locked_sections[107] && locked_sections[107][0] ? '' : (e) => handleSectionClick(e, `/keyplant/maintenance-losses/questionaire/${slug}/107`, 107, 0)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 107 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 7 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 7)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/maintenance-losses.svg")
                                                        .default
                                                }
                                                alt="Maintenance Losses"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Maintenance Losses</span>
                                            <a href={`/keyplant/maintenance-losses/questionaire/${slug}/107`} className="btn-link">
                                                {tempListAssing[107]?.progress == null || tempListAssing[107]?.progress == 0 ? "+ Start section" : tempListAssing[107]?.progress > 0 && tempListAssing[107]?.progress < 100 ? '+ Continue Section' : '+ View Section'}
                                            </a>
                                        </div>
                                    </div>
                                    {locked_sections[107] && locked_sections[107][0] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 107, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[107][0]?.avcolor}`}>{locked_sections[107][0]?.shortname}</div>
                                                    <span className="name">{locked_sections[107][0]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/keyplant/maintenance-losses/questionaire/${slug}/107`} className={`bottom-btn-link ${tempListAssing[107]?.progress == null || tempListAssing[107]?.progress == 0 ? (sectionItem.na_data.includes(107) ? 'not-available' : (sectionItem.na_progress_data.includes(107) ? "orange" : "red")) : tempListAssing[107]?.progress > 0 && tempListAssing[107]?.progress < 100 ? 'orange' : 'green'}`}>
                                                {tempListAssing[107]?.progress == null || tempListAssing[107]?.progress == 0 ? (sectionItem.na_data.includes(107) ? "Data N/A" : (sectionItem.na_progress_data.includes(107) ? "In Progress" : "To Do")) : tempListAssing[107]?.progress > 0 && tempListAssing[107]?.progress < 100 ? 'In Progress' : 'completed'}
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${sectionid[7].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 107, 'Maintenance Losses'); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.maintenance_losses.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.maintenance_losses.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.maintenance_losses.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 107, 0, 'Maintenance Losses'); setTmpAssignUser('maintenance_losses'); }} style={{ cursor: 'pointer' }} >
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top ${sectionid[7].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.maintenance_losses.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'maintenance_losses') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>Maintenance Losses</h2>
                                                        <p>The key routine activities taking place by the Maintenance or Engineering function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1">
                                <div
                                    onClick={locked_sections[106] && locked_sections[106][0] ? '' : (e) => handleSectionClick(e, `/keyplant/it-losses/questionaire/${slug}/106`, 106, 0)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 106 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 8 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 8)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/it-losses.svg")
                                                        .default
                                                }
                                                alt="IT Losses"

                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">IT Losses</span>
                                        </div>
                                    </div>
                                    {locked_sections[106] && locked_sections[106][0] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 106, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[106][0]?.avcolor}`}>{locked_sections[106][0]?.shortname}</div>
                                                    <span className="name">{locked_sections[106][0]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/keyplant/it-losses/questionaire/${slug}/106`} className={`bottom-btn-link ${tempListAssing[106]?.progress == null || tempListAssing[106]?.progress == 0 ? (sectionItem.na_data.includes(106) ? 'not-available' : (sectionItem.na_progress_data.includes(106) ? "orange" : "red")) : tempListAssing[106]?.progress > 0 && tempListAssing[106]?.progress < 100 ? 'orange' : 'green'}`}>
                                                {tempListAssing[106]?.progress == null || tempListAssing[106]?.progress == 0 ? (sectionItem.na_data.includes(106) ? "Data N/A" : (sectionItem.na_progress_data.includes(106) ? "In Progress" : "To Do")) : tempListAssing[106]?.progress > 0 && tempListAssing[106]?.progress < 100 ? 'In Progress' : 'completed'}
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${sectionid[6].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 106, 'IT Losses'); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.it_losses.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.it_losses.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.it_losses.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" onClick={(e) => { e.preventDefault(); edithandle(e, 106, 0, 'IT Losses'); setTmpAssignUser('it_losses'); }} data-title="Assign Users" style={{ cursor: 'pointer' }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top  ${sectionid[6].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.it_losses.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'it_losses') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>IT Losses</h2>
                                                        <p>The key routine activities taking place by the IT function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1 padding-r0">

                                <div
                                    onClick={locked_sections[102] && locked_sections[102][0] ? '' : (e) => handleSectionClick(e, `/keyplant/it-losses/questionaire/${slug}/102`, 102, 0)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 102 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 9 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 9)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >

                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/warehouse-losses.svg")
                                                        .default
                                                }
                                                alt="Warehouse-Losses"
                                            />

                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Warehouse Losses</span>
                                        </div>
                                    </div>
                                    {locked_sections[102] && locked_sections[102][0] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 102, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[102][0]?.avcolor}`}>{locked_sections[102][0]?.shortname}</div>
                                                    <span className="name">{locked_sections[102][0]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/keyplant/warehouse-losses/questionaire/${slug}/102`} className={`bottom-btn-link ${tempListAssing[102]?.progress == null || tempListAssing[102]?.progress == 0 ? (sectionItem.na_data.includes(102) ? 'not-available' : (sectionItem.na_progress_data.includes(102) ? "orange" : "red")) : tempListAssing[102]?.progress > 0 && tempListAssing[102]?.progress < 100 ? 'orange' : 'green'}`}>
                                                {tempListAssing[102]?.progress == null || tempListAssing[102]?.progress == 0 ? (sectionItem.na_data.includes(102) ? "Data N/A" : (sectionItem.na_progress_data.includes(102) ? "In Progress" : "To Do")) : tempListAssing[102]?.progress > 0 && tempListAssing[102]?.progress < 100 ? 'In Progress' : 'completed'}
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${sectionid[2].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 102, 'Warehouse Losses'); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.warehouse_losses.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.warehouse_losses.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.warehouse_losses.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>

                                            </div>

                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 102, 0, 'Warehouse Losses'); setTmpAssignUser('warehouse_losses'); }} style={{ cursor: 'pointer' }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top  ${sectionid[2].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.warehouse_losses.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'warehouse_losses') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>Warehouse Losses</h2>
                                                        <p>The key routine activities taking place by the Warehouse or Material Handling function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col padding-left-0">
                                <div
                                    onClick={locked_sections[104] && locked_sections[104][0] ? '' : (e) => handleSectionClick(e, `/keyplant/ehs-losses/questionaire/${slug}/104`, 104, 0)}
                                    className={` loss-cat-card ${isCardLockedHover['section'] === 104 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 10 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 10)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/EHS-losses.svg")
                                                        .default
                                                }
                                                alt="EHS Losses"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">EHS Losses</span>
                                        </div>
                                    </div>
                                    {locked_sections[104] && locked_sections[104][0] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 104, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[104][0]?.avcolor}`}>{locked_sections[104][0]?.shortname}</div>
                                                    <span className="name">{locked_sections[104][0]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/keyplant/ehs-losses/questionaire/${slug}/104`} className={`bottom-btn-link ${tempListAssing[104]?.progress == null || tempListAssing[104]?.progress == 0 ? (sectionItem.na_data.includes(104) ? 'not-available' : (sectionItem.na_progress_data.includes(104) ? "orange" : "red")) : tempListAssing[104]?.progress > 0 && tempListAssing[104]?.progress < 100 ? 'orange' : 'green'}`}>
                                                {tempListAssing[104]?.progress == null || tempListAssing[104]?.progress == 0 ? (sectionItem.na_data.includes(104) ? "Data N/A" : (sectionItem.na_progress_data.includes(104) ? "In Progress" : "To Do")) : tempListAssing[104]?.progress > 0 && tempListAssing[104]?.progress < 100 ? 'In Progress' : 'completed'}
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${sectionid[4].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 104, 'EHS Losses'); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.ehs_losses.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.ehs_losses.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.ehs_losses.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 104, 0, 'EHS Losses'); setTmpAssignUser('ehs_losses'); }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top ${sectionid[4].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.ehs_losses.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'ehs_losses') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>EHS Losses</h2>
                                                        <p>The key routine activities taking place by the EHS function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col">
                                <div
                                    onClick={locked_sections[105] && locked_sections[105][0] ? '' : (e) => handleSectionClick(e, `/keyplant/quality-losses/questionaire/${slug}/105`, 105, 0)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 105 && isCardLockedHover['subsection'] === 0 ? 'hide-loss-card-body' : ''} ${isCardHover == 11 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 11)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/quality-losses.svg")
                                                        .default
                                                }
                                                alt="Quality"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Quality Losses</span>
                                        </div>
                                    </div>
                                    {locked_sections[105] && locked_sections[105][0] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 105, subsection: 0 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[105][0]?.avcolor}`}>{locked_sections[105][0]?.shortname}</div>
                                                    <span className="name">{locked_sections[105][0]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/keyplant/quality-losses/questionaire/${slug}/105`} className={`bottom-btn-link ${tempListAssing[105]?.progress == null || tempListAssing[105]?.progress == 0 ? (sectionItem.na_data.includes(105) ? 'not-available' : (sectionItem.na_progress_data.includes(105) ? "orange" : "red")) : tempListAssing[105]?.progress > 0 && tempListAssing[105]?.progress < 100 ? 'orange' : 'green'}`}>
                                                {tempListAssing[105]?.progress == null || tempListAssing[105]?.progress == 0 ? (sectionItem.na_data.includes(105) ? "Data N/A" : (sectionItem.na_progress_data.includes(105) ? "In Progress" : "To Do")) : tempListAssing[105]?.progress > 0 && tempListAssing[105]?.progress < 100 ? 'In Progress' : 'completed'}
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${sectionid[5].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 105, 'Quality Losses'); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.quality_losses.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.quality_losses.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.quality_losses.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 105, 0, 'Quality Losses'); setTmpAssignUser('quality_losses'); }} style={{ cursor: 'pointer' }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top ${sectionid[5].id == cate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.quality_losses.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'quality_losses') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap green">
                                                    <div className="card-note-tooltip">
                                                        <h2>Quality Losses</h2>
                                                        <p>The key routine activities taking place by the Quality function of the plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Same structure as upper column */}
                    <div className="loss-analysis-category-col md-col-4">
                        <div className="loss-analysis-category-col border-bottom margin-left">
                            <div className="loss-analysis-assign-wrap">
                                <div className="loss-analysis-category-button-wrap orange">
                                    <div className="img-wrap">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/finance-data.svg")
                                                    .default
                                            }
                                            alt="finance-data"
                                        />
                                    </div>
                                    <span className="text">Finance Data</span>
                                    <div class="note">
                                        <img
                                            src={
                                                require("../../assets/img/la-admin/note-orange.svg")
                                                    .default
                                            }
                                            alt="Notes"
                                        />
                                    </div>
                                    <div className="card-note-popup">
                                        <div className="card-note-tooltip-wrap dark-orange">
                                            <div className="card-note-tooltip">
                                                <h2>Finance Data</h2>
                                                <p>Contains the breakdown of Manufacturing Cost, FTEs per function/department and general plant information.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="la-assign-cir-wrapper wrap">
                                    <div className="new-assign-group">
                                        <button className="new-assign" onClick={(e) => { edithandle(e, 108, 0, 'Finance Data'); setTmpAssignUser(['general_information', 'fte_breakdown', 'manufacturing_cost']); }}>
                                            <div className="img-div">
                                                <img src={require('../../assets/img/la-admin/assign-icon.svg').default} alt="assign" />
                                            </div>
                                            <div className="assign-text">
                                                <span>Assign</span>
                                            </div>
                                        </button>
                                    </div>
                                    {/* new-assign-group end*/}
                                </div>
                            </div>
                        </div>
                        <div className="loss-cat-card-row bottom row-padding right-col">
                            <div className="loss-cat-card-col mb-1 padding-left-0">
                                <div
                                    onClick={locked_sections[108] && locked_sections[108][6] ? '' : (e) => handleSectionClick(e, `/general-information/questionaire/${slug}/108/6`, 108, 6)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 108 && isCardLockedHover['subsection'] === 6 ? 'hide-loss-card-body' : ''} ${isCardHover == 12 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 12)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/general-information.svg")
                                                        .default
                                                }
                                                alt="general-information"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">General Information</span>
                                        </div>
                                    </div>
                                    {locked_sections[108] && locked_sections[108][6] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 108, subsection: 6 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[108][6]?.avcolor}`}>{locked_sections[108][6]?.shortname}</div>
                                                    <span className="name">{locked_sections[108][6]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/general-information/questionaire/${slug}/108/6`} className={`bottom-btn-link ${(tempListAssing[6]?.invited_category == 108 && tempListAssing[6]?.invited_sub_category == 6) ? tempListAssing[6]?.progress == null || tempListAssing[6]?.progress == 0 ? 'red' : tempListAssing[6]?.progress > 0 && tempListAssing[6]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {tempListAssing[108]?.progress==null || tempListAssing[108]?.progress==0 ? "To Do" : tempListAssing[108]?.progress>0 && tempListAssing[108]?.progress<100 ?'In Progress':'completed' } */}
                                                {(tempListAssing[6]?.invited_category == 108 && tempListAssing[6]?.invited_sub_category == 6) ?
                                                    (tempListAssing[6]?.progress == null || tempListAssing[6]?.progress == 0) ?
                                                        "To Do" : (tempListAssing[6]?.invited_category == 108 && tempListAssing[6]?.invited_sub_category == 6) && (tempListAssing[6]?.progress > 0 && tempListAssing[6]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[5].id == subcate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 108, 'General Information', 6); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.general_information.slice(0, 2).map((item, index) => <div>
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.general_information.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.general_information.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 108, 6, 'General Information'); setTmpAssignUser('general_information'); }} style={{ cursor: 'pointer' }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top ${subsectionid[5].id == subcate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.general_information.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'general_information') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap orange">
                                                    <div className="card-note-tooltip">
                                                        <h2>General Information</h2>
                                                        <p>Key plant data consisting of KPIs, plant operational time and volume output.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col mb-1 padding-r0">
                                <div
                                    onClick={locked_sections[108] && locked_sections[108][8] ? '' : (e) => handleSectionClick(e, `/fte-breakdown/questionaire/${slug}/108/8`, 108, 8)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 108 && isCardLockedHover['subsection'] === 8 ? 'hide-loss-card-body' : ''} ${isCardHover == 13 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 13)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/FTE-breakdown.svg")
                                                        .default
                                                }
                                                alt="FTE-breakdown"

                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">FTE Breakdown</span>
                                        </div>
                                    </div>
                                    {locked_sections[108] && locked_sections[108][8] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 108, subsection: 8 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[108][8]?.avcolor}`}>{locked_sections[108][8]?.shortname}</div>
                                                    <span className="name">{locked_sections[108][8]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/fte-breakdown/questionaire/${slug}/108/8`} className={`bottom-btn-link ${(tempListAssing[8]?.invited_category == 108 && tempListAssing[8]?.invited_sub_category == 8) ? tempListAssing[8]?.progress == null || tempListAssing[8]?.progress == 0 ? 'red' : tempListAssing[8]?.progress > 0 && tempListAssing[8]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {tempListAssing[108]?.progress==null || tempListAssing[108]?.progress==0 ? "To Do" : tempListAssing[108]?.progress>0 && tempListAssing[108]?.progress<100 ?'In Progress':'completed' } */}
                                                {(tempListAssing[8]?.invited_category == 108 && tempListAssing[8]?.invited_sub_category == 8) ?
                                                    (tempListAssing[8]?.progress == null || tempListAssing[8]?.progress == 0) ?
                                                        "To Do" : (tempListAssing[8]?.invited_category == 108 && tempListAssing[8]?.invited_sub_category == 8) && (tempListAssing[8]?.progress > 0 && tempListAssing[8]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[7].id == subcate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 108, 'FTE breakdown', 8); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.fte_breakdown.slice(0, 2).map((item, index) => <div >
                                                       <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.fte_breakdown.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.fte_breakdown.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 108, 8, 'FTE Breakdown'); setTmpAssignUser('fte_breakdown'); }} style={{ cursor: 'pointer' }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top ${subsectionid[7].id == subcate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.fte_breakdown.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'fte_breakdown') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup middle">
                                                <div className="card-note-tooltip-wrap orange">
                                                    <div className="card-note-tooltip">
                                                        <h2>FTE Breakdown</h2>
                                                        <p>A breakdown of the employees working under the main functions of the plant operations.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="loss-cat-card-col padding-left-0">
                                <div
                                    onClick={locked_sections[108] && locked_sections[108][7] ? '' : (e) => handleSectionClick(e, `/manufacturing-cost/questionaire/${slug}/108/7`, 108, 7)}
                                    className={`loss-cat-card ${isCardLockedHover['section'] === 108 && isCardLockedHover['subsection'] === 7 ? 'hide-loss-card-body' : ''} ${isCardHover == 14 && 'hover-active'}`}
                                    onMouseMove={(e) => HoverActiveHandle(e, 14)}
                                    onMouseLeave={() => setIsCardHover(null)}
                                >
                                    <div className="loss-cat-card-body">
                                        <div className="image">
                                            <img
                                                src={
                                                    require("../../assets/img/la-admin/manufacturing-cost.svg")
                                                        .default
                                                }
                                                alt="Manufacturing Cost"
                                            />
                                        </div>
                                        <div className="text-wrap">
                                            <span className="txt">Manufacturing Cost</span>
                                        </div>
                                    </div>
                                    {locked_sections[108] && locked_sections[108][7] &&
                                        <div className="lock-section" onMouseEnter={() => setIsCardLockedHover({ section: 108, subsection: 7 })} onMouseLeave={() => setIsCardLockedHover({})}>
                                            <button className="lock-btn">
                                                <img src={require("../../assets/img/loss-analysis-page-img/lock.svg").default} alt="Lock Mechanism" />
                                                Section Locked
                                            </button>
                                            <div className="lock-sec-popup">
                                                <p> The section is temporarily locked as it is currently being edited by:</p>
                                                <div className="pop-footer">
                                                    <div className={`circle ${locked_sections[108][7]?.avcolor}`}>{locked_sections[108][7]?.shortname}</div>
                                                    <span className="name">{locked_sections[108][7]?.user}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loss-cat-card-footer">
                                        <div className="left-section">
                                            <a href={`/manufacturing-cost/questionaire/${slug}/108/7`} className={`bottom-btn-link ${(tempListAssing[7]?.invited_category == 108 && tempListAssing[7]?.invited_sub_category == 7) ? tempListAssing[7]?.progress == null || tempListAssing[7]?.progress == 0 ? 'red' : tempListAssing[7]?.progress > 0 && tempListAssing[7]?.progress < 100 ? 'orange' : 'green' : 'red'}`}>
                                                {/* {tempListAssing[108]?.progress==null || tempListAssing[108]?.progress==0 ? "To Do" : tempListAssing[108]?.progress>0 && tempListAssing[108]?.progress<100 ?'In Progress':'completed' } */}
                                                {(tempListAssing[7]?.invited_category == 108 && tempListAssing[7]?.invited_sub_category == 7) ?
                                                    (tempListAssing[7]?.progress == null || tempListAssing[7]?.progress == 0) ?
                                                        "To Do" : (tempListAssing[7]?.invited_category == 108 && tempListAssing[7]?.invited_sub_category == 7) && (tempListAssing[7]?.progress > 0 && tempListAssing[7]?.progress < 100) ? 'In Progress' : 'Completed'
                                                    : "To Do"
                                                }
                                            </a>
                                        </div>
                                        <div className="right-section rgt22-sec" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                            <div className={`new-assign-user ${subsectionid[6].id == subcate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}
                                                data-title="View Users" onClick={(e) => { handlePopup(e, 108, 'Manufacturing Cost', 7); }}>
                                                <div className="la-profile-cir-ul">
                                                    {assignedUsers.manufacturing_cost.slice(0, 2).map((item, index) => <div >
                                                        <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                        {/*<img src={require('../../assets/img/la-admin/demo-user.svg').default} /> */}
                                                    </div>)}
                                                    {assignedUsers.manufacturing_cost.length > 2 &&
                                                        <div className="image-div circle-num">
                                                            <span>{assignedUsers.manufacturing_cost.length - 2}</span>
                                                            {/*<img src={require('../../assets/img/maturity-dashboard-adm/demo-user.svg').default}/> */}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* new assign user */}
                                            <div className="assigned-user-div" data-title="Assign Users" onClick={(e) => { e.preventDefault(); edithandle(e, 108, 7, 'Manufacturing Cost'); setTmpAssignUser('manufacturing_cost'); }} style={{ cursor: 'pointer' }}>
                                                <div className="assign-cir-icon">
                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: newCirIcon }}></i>
                                                </div>
                                            </div>
                                            <div className={`assigned-user-popup direction-top ${subsectionid[6].id == subcate ? `${isShown && !isCardHover && 'active'}` : 'false'}`}>
                                                <span className="tooltip"></span>
                                                <div className="assignd-user-popup-wrap">
                                                    {assignedUsers.manufacturing_cost.map((item, index) => {
                                                        return (

                                                            <div className="pop-wrap">
                                                                <div className="img-box">
                                                                <span className="us-name">
                                                                        {!item.profile_pic ?
                                                                            <div className={`image-div ${item.avcolor}`} >
                                                                                {item.name ?
                                                                                    `${item.name[0]}${item.last_name && item.last_name[0]}`
                                                                                    :
                                                                                    `${item.email[0]}`
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className={`image-div cir-b-none`} >
                                                                                <img className="h__profile__img_cir"

                                                                                    src={`${apiEndpoints.baseUrl}/assets/user-profile/${item.profile_pic}`}
                                                                                    alt={item.profile_pic}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className={`user-name ${!item.name && 'text-unset'}`}>
                                                                    <span className="name">
                                                                        {item.name ?
                                                                            `${item.name} ${item.last_name && item.last_name}`
                                                                            :
                                                                            `${item.email}`
                                                                        }
                                                                    </span>
                                                                    <span className="designation">{item.job_title}</span>
                                                                </div>
                                                                <div className="user-unassign-div">
                                                                    <div className="user-unAssign" onClick={(e) => { handleUnassignUser(e, item.email, 'manufacturing_cost') }}>
                                                                        <span className="unassign-text">Un-Assign</span>
                                                                    </div>
                                                                    <i className="icon-svg" dangerouslySetInnerHTML={{ __html: unassignDotIcon }} onClick={(e) => handleUnassign(e)}></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {Object.keys(isCardLockedHover).length == 0 &&
                                            <div className="card-note-popup">
                                                <div className="card-note-tooltip-wrap orange">
                                                    <div className="card-note-tooltip">
                                                        <h2>Manufacturing Cost</h2>
                                                        <p>The key financial figures that constitute the Manufacturing Cost of the Plant.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*POPUP HTML starts here*/}
                {/* Congrats popup starts here */}
                <div className="congrats-popup active hide-class">
                    <div className="popup-wrapper congrats">
                        <div className="close-btn-wrap">
                            <button className="close-btn">
                                <img src={require("../../assets/img/la-admin/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-admin/congrats.gif").default} alt="Congrats" className="congrats" />
                            </div>
                            <div className="content-box">
                                <h2 className="heading">Congratulations!</h2>
                                <p className="text">All Loss Analysis Sections have been successfully completed. Now you can Submit your Data to SmarterChains.</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn review-button primary">I’ll review first</button>
                                <button type="submit" className="btn submit-data secondary">Submit Data</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Submit popup starts here */}
                <div className="submit-popup active hide-class">
                    <div className="popup-wrapper submitdata-popup">
                        <div className="close-btn-wrap">
                            <button className="close-btn">
                                <img src={require("../../assets/img/la-admin/close-pop.svg").default} alt="Close" className="close-img" />
                            </button>
                        </div>
                        <div className="popup">
                            <div className="content-box">
                                <h2 className="heading">Submiting Data</h2>
                                <p className="text">Upon submitting, you will not be able to edit the Plant's Loss data.</p>
                                <p className="text">Do you want to proceed and submit your data?</p>
                            </div>
                            <div className="button-wrapper">
                                <button type="submit" className="btn cancel-button primary">Cancel</button>
                                <button type="submit" className="btn submit-btn secondary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Final submit popup */}
                <div className="final-submit-popup active hide-class">
                    <div className="popup-wrapper final-submit">
                        <div className="popup">
                            <div className="img-box">
                                <img src={require("../../assets/img/la-admin/submit-pop-img.gif").default} alt="Congrats" className="congrats" />
                            </div>
                            <div className="content-box">
                                <p className="text">Submitting and uploading your data...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </>
    );
};

// svg image definition
let dataSubmitsvg = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="14" cy="14" r="13.5" fill="#43A047" stroke="#CBCFD2"/>
<path d="M11.2501 17.8225L7.42764 14L6.12598 15.2925L11.2501 20.4167L22.2501 9.41668L20.9576 8.12418L11.2501 17.8225Z" fill="white"/>
</svg>`
//Submit data popup component
const SubmitDataPopup = (props) => {
    const finalUnlock = () => {
        props.setOpen(false)
        serviceProvider
            .post(
                "/businesscaseplantloss?type=lockallsection",
                JSON.stringify({ pid: props.pid }),
                true
            )
            .then((response) => {
                props.setLockbutton(true);
            });
    }


    return (
        <>
            {/* Wrapper has two sections
                close-btn-wrap
                popup
            */}
            <div className="submit-popup active submit-popup2126" style={{ visibility: props.open ? '' : 'hidden' }}>
                <div className="popup-wrapper submitdata-popup">
                    <div className="close-btn-wrap">
                        <button className="close-btn" onClick={() => props.setOpen(false)}>
                            <img src={require("../../assets/img/la-admin/close-pop.svg").default} alt="Close" className="close-img" />
                        </button>
                    </div>
                    {/* popup has two elements
                        content-box
                        button-wrapper
                    */}
                    <div className="popup">
                        <div className="content-box">
                            <h2 className="heading"><svg width="{38}" height="{30}" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.8002 5.65428L24.3457 0.199752C24.2179 0.0719106 24.0448 0 23.8636 0H2.7273C1.22382 0 0 1.22312 0 2.7273V27.2728C0 28.7769 1.22382 30.0001 2.7273 30.0001H27.2728C28.7762 30.0001 30.0001 28.7769 30.0001 27.2728V6.13637C30 5.95528 29.9281 5.78212 29.8002 5.65428ZM6.81821 1.36362H21.8182V9.54544C21.8182 10.2971 21.207 10.9091 20.4546 10.9091H8.18182C7.42942 10.9091 6.81821 10.2971 6.81821 9.54544V1.36362ZM24.5455 28.6364H5.45453V16.3636H24.5455V28.6364ZM28.6364 27.2728C28.6364 28.0245 28.0251 28.6364 27.2728 28.6364H25.9092V15.6818C25.9092 15.3049 25.6042 15 25.2273 15H4.77275C4.39588 15 4.09091 15.3049 4.09091 15.6818V28.6364H2.7273C1.97489 28.6364 1.36368 28.0245 1.36368 27.2728V2.7273C1.36368 1.97559 1.97495 1.36368 2.7273 1.36368H5.45459V9.5455C5.45453 11.0496 6.67835 12.2727 8.18182 12.2727H20.4546C21.958 12.2727 23.1819 11.0496 23.1819 9.54544V1.36362H23.5814L28.6364 6.4187V27.2728Z" fill="#178ACB" />
                                <path d="M17.0456 9.54544H19.7729C20.1498 9.54544 20.4547 9.24048 20.4547 8.8636V3.40907C20.4547 3.0322 20.1498 2.72723 19.7729 2.72723H17.0456C16.6687 2.72723 16.3638 3.0322 16.3638 3.40907V8.8636C16.3638 9.24048 16.6687 9.54544 17.0456 9.54544ZM17.7274 4.09091H19.091V8.18183H17.7274V4.09091Z" fill="#178ACB" />
                                <circle cx="{31}" cy="{8}" r="{7}" fill="#178ACB" />
                                <rect x="{32}" y="{9}" width="{2}" height="{6}" rx="{1}" transform="rotate(-180 32 9)" fill="white" />
                                <rect x="{32}" y="12.3333" width="{2}" height="{2}" rx="{1}" transform="rotate(-180 32 12.3333)" fill="white" />
                            </svg>
                            </h2>
                            <p className="text-p">By submitting the data you will no longer have the opportunity for further editing. </p>
                            <p className="text-p b"> Are you sure you want to proceed?</p>
                        </div>
                        <div className="button-wrapper">
                            <button type="submit" className="btn cancel-button primary" onClick={() => props.setOpen(false)}>Cancel</button>
                            <button type="submit" className="btn submit-btn secondary" onClick={() => finalUnlock()}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}